import * as actionTypes from './entitlementActionTypes';
import * as checks from './checks/index';
import { isBooleanTrue, isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../objectUtils';

const allowStaff = (auth) => {
    return auth.entityType === 'STAFF';
};

const allowStaffAdmin = (auth) => {
    return isBooleanTrue(auth.isAdmin);
};

const allowStaffVP = (auth) => {
    return isBooleanTrue(auth.isAdmin) || isBooleanTrue(auth.isVP);
};

const isAuthenticated = (auth) => {
    return isBooleanTrue(auth.isAuthenticated);
};

const checkAccountRoles = (auth, roles) => {
    let hasRole = false;
    if (isObjectNotEmpty(auth.accountUser) && isListNotEmpty(auth.accountUser.accountRoles) && isListNotEmpty(roles)) {
        roles.forEach((role) => {
            if (auth.accountUser.accountRoles.includes(role)) {
                hasRole = true;
            }
        });
    }

    return hasRole;
};

const isAccountUserVerified = (accountUser) => {
    if (isObjectNotEmpty(accountUser) && isBooleanTrue(accountUser.isVerified)) {
        return true;
    }

    return false;
};

const isAccountActive = (account) => {
    if (isObjectNotEmpty(account) && isBooleanTrue(account.isActive)) {
        return true;
    }

    return false;
};

const isAccountVerified = (account) => {
    if (isObjectNotEmpty(account) && isBooleanTrue(account.isVerified)) {
        return true;
    }

    return false;
};

const isAccountUser = (auth, accountId) => {
    if (isObjectNotEmpty(auth.accountUser) && isStringNotEmpty(accountId) && auth.accountUser.accountId === accountId && isAccountUserVerified(auth.accountUser) && isAccountActive(auth.account)) {
        return true;
    }

    return false;
};

const isAccountUserWithRoles = (auth, accountId, roles) => {
    if (isAccountUser(auth, accountId) && checkAccountRoles(auth, roles)) {
        return true;
    }

    return false;
};

const isAccountAdmin = (auth, accountId) => {
    if (isAccountUser(auth, accountId) && checkAccountRoles(auth, ['ADMIN'])) {
        return true;
    }

    return false;
};

const isAccountOperationsAdmin = (auth, accountId) => {
    if (isAccountUser(auth, accountId) && checkAccountRoles(auth, ['OPERATIONS_ADMIN', 'ADMIN'])) {
        return true;
    }

    return false;
};

const isDriver = (auth) => {
    if (isObjectNotEmpty(auth.accountUser) && isBooleanTrue(auth.accountUser.isDriver)) {
        return true;
    }

    return false;
};

const isLoadOwner = (load, auth) => {
    if (auth.entityType === 'STAFF') {
        return true;
    } else if (isStringNotEmpty(load.shipperId) && auth.entityType === "SHIPPER" && load.shipperId === auth.entityId && load.createdByEntityType === 'SHIPPER') {
        return true;
    } else if (isStringNotEmpty(load.assignedCarrierId) && auth.entityType === "CARRIER" && load.assignedCarrierId === auth.entityId && load.createdByEntityType === 'CARRIER') {
        return true;
    } else if (isStringNotEmpty(load.brokerId) && auth.entityType === "BROKER" && load.brokerId === auth.entityId && load.createdByEntityType === 'BROKER') {
        return true;
    } else {
        return false;
    }
};

const isLoadDriver = (load, auth) => {
    if (isListNotEmpty(load.driverIds) && isStringNotEmpty(auth.userId) && load.driverIds.includes(auth.userId) && isDriver(auth)) {
        return true;
    } else {
        return false;
    }
};

const isLoadShipper = (load, auth) => {
    if (isStringNotEmpty(load.shipperId) && auth.entityType === "SHIPPER" && load.shipperId === auth.entityId) {
        return true;
    } else {
        return false;
    }
};

const isLoadCarrier = (load, auth) => {
    if (isStringNotEmpty(load.assignedCarrierId) && auth.entityType === "CARRIER" && load.assignedCarrierId === auth.entityId) {
        return true;
    } else {
        return false;
    }
};

const isLoadBroker = (load, auth) => {
    if (isStringNotEmpty(load.brokerId) && auth.entityType === "BROKER" && load.brokerId === auth.entityId) {
        return true;
    } else {
        return false;
    }
};

const isLoadTMS = (load) => {
    if (isObjectNotEmpty(load) && load.serviceType === 'TMS') {
        return true;
    }

    return false;
};

const isLoadBrokerage = (load) => {
    if (isObjectNotEmpty(load) && load.serviceType === 'BROKERAGE') {
        return true;
    }

    return false;
};

const canViewLoad = (load, auth, entityTypes) => {
    if (isObjectNotEmpty(load)) {
        if (isListNotEmpty(entityTypes) && entityTypes.includes('STAFF') && auth.entityType === 'STAFF') {
            return true;
        } else if (isLoadShipper(load, auth) && isListNotEmpty(entityTypes) && entityTypes.includes('SHIPPER')) {
            return true;
        } else if (isLoadCarrier(load, auth) && isListNotEmpty(entityTypes) && entityTypes.includes('CARRIER')) {
            return true;
        } else if (isLoadDriver(load, auth) && isListNotEmpty(entityTypes) && entityTypes.includes('DRIVER')) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};

const canView = (auth, entityTypes) => {
    if (isListNotEmpty(entityTypes) && entityTypes.includes('STAFF') && auth.entityType === 'STAFF') {
        return true;
    } else if (isListNotEmpty(entityTypes) && entityTypes.includes('CARRIER') && isObjectNotEmpty(auth.accountUser) && (isListNotEmpty(auth.accountUser.accountRoles) || !isDriver(auth)) && isObjectNotEmpty(auth.accountUser.account) && isBooleanTrue(auth.accountUser.account.entityTypes.includes("CARRIER"))) {
        // handles if the user is a carrier user and has at least one role for the account meaning that they arent just a driver
        return true;
    } else if (isListNotEmpty(entityTypes) && entityTypes.includes('SHIPPER') && isObjectNotEmpty(auth.accountUser) && isObjectNotEmpty(auth.accountUser.account) && isBooleanTrue(auth.accountUser.account.entityTypes.includes("SHIPPER"))) {
        return true;
    } else if (isListNotEmpty(entityTypes) && entityTypes.includes('PRODUCER') && isObjectNotEmpty(auth.accountUser) && isObjectNotEmpty(auth.accountUser.account) && isBooleanTrue(auth.accountUser.account.entityTypes.includes("PRODUCER"))) {
        return true;
    } else if (isListNotEmpty(entityTypes) && entityTypes.includes('RECEIVER') && isObjectNotEmpty(auth.accountUser) && isObjectNotEmpty(auth.accountUser.account) && isBooleanTrue(auth.accountUser.account.entityTypes.includes("RECEIVER"))) {
        return true;
    } else if (isListNotEmpty(entityTypes) && entityTypes.includes('DRIVER') && isObjectNotEmpty(auth.accountUser) && isObjectNotEmpty(auth.accountUser.account) && isBooleanTrue(auth.accountUser.account.entityTypes.includes("CARRIER")) && isDriver(auth)) {
        return true;
    } else {
        return false;
    }
};

const isAccountUserSelf = (auth, accountUser) => {
    if (isObjectNotEmpty(accountUser) && isObjectNotEmpty(auth.accountUser) && accountUser.id === auth.accountUser.id) {
        return true;
    }

    return false;
};

const isUserSelf = (auth, user) => {
    // console.log(user);
    if (isObjectNotEmpty(user) && isStringNotEmpty(user.user_id) && isStringNotEmpty(auth.userId) && user.user_id === auth.userId) {
        return true;
    }

    return false;
};

const checkEntitlements = (entityAction, entityModel, entityObject = null, auth, accountId = null) => {
    const entitlementActionType = entityAction.toUpperCase() + "_" + entityModel.toUpperCase();

    switch (entitlementActionType) {
        case actionTypes.CREATE_ACCOUNT: return checks.canCreateAccount(auth);
        case actionTypes.READ_ACCOUNT: return checks.canReadAccount(auth, entityObject);
        case actionTypes.READ_LIST_ACCOUNT: return checks.canReadAccountList(auth);
        case actionTypes.UPDATE_ACCOUNT: return checks.canUpdateAccount(auth, entityObject);
        case actionTypes.VERIFY_ACCOUNT: return checks.canVerifyAccount(auth);
        case actionTypes.DELETE_ACCOUNT: return checks.canDeleteAccount(auth);
        case actionTypes.READ_SHIPPER: return checks.canReadShipper(auth, entityObject);
        case actionTypes.READ_LIST_SHIPPER: return checks.canReadShipperList(auth);
        case actionTypes.READ_CARRIER: return checks.canReadCarrier(auth, entityObject);
        case actionTypes.READ_LIST_CARRIER: return checks.canReadCarrierList(auth);
        case actionTypes.READ_PRODUCER: return checks.canReadProducer(auth, entityObject);
        case actionTypes.READ_LIST_PRODUCER: return checks.canReadProducerList(auth);
        case actionTypes.READ_RECEIVER: return checks.canReadReceiver(auth, entityObject);
        case actionTypes.READ_LIST_RECEIVER: return checks.canReadReceiverList(auth);
        case actionTypes.READ_BROKER: return checks.canReadBroker(auth, entityObject);
        case actionTypes.READ_LIST_BROKER: return checks.canReadBrokerList(auth);
        case actionTypes.READ_FACTORING: return checks.canReadFactoring(auth, entityObject);
        case actionTypes.READ_LIST_FACTORING: return checks.canReadFactoringList(auth);

        case actionTypes.CREATE_ACCOUNT_REGISTRATION: return checks.canCreateAccountRegistration(auth);
        case actionTypes.READ_ACCOUNT_REGISTRATION: return checks.canReadAccountRegistration(auth);
        case actionTypes.READ_LIST_ACCOUNT_REGISTRATION: return checks.canReadAccountRegistrationList(auth);
        case actionTypes.UPDATE_ACCOUNT_REGISTRATION: return checks.canUpdateAccountRegistration(auth);
        case actionTypes.DELETE_ACCOUNT_REGISTRATION: return checks.canDeleteAccountRegistration(auth);

        case actionTypes.CREATE_ACCOUNT_USER: return checks.canCreateAccountUser(auth, accountId);
        case actionTypes.READ_ACCOUNT_USER: return checks.canReadAccountUser(auth, accountId, entityObject);
        case actionTypes.READ_LIST_ACCOUNT_USER: return checks.canReadAccountUserList(auth, accountId);
        case actionTypes.UPDATE_ACCOUNT_USER: return checks.canUpdateAccountUser(auth, accountId, entityObject);
        case actionTypes.VERIFY_ACCOUNT_USER: return checks.canVerifyAccountUser(auth, accountId);
        case actionTypes.DELETE_ACCOUNT_USER: return checks.canDeleteAccountUser(auth, accountId);

        case actionTypes.CREATE_ASSET: return checks.canCreateAsset(auth, accountId);
        case actionTypes.READ_ASSET: return checks.canReadAsset(auth, accountId);
        case actionTypes.READ_LIST_ASSET: return checks.canReadAssetList(auth, accountId);
        case actionTypes.UPDATE_ASSET: return checks.canUpdateAsset(auth, accountId);
        case actionTypes.DELETE_ASSET: return checks.canDeleteAsset(auth, accountId);

        case actionTypes.CREATE_CARRIER_REJECTION: return checks.canCreateCarrierRejection(auth);
        case actionTypes.READ_CARRIER_REJECTION: return checks.canReadCarrierRejection(auth);
        case actionTypes.READ_LIST_CARRIER_REJECTION: return checks.canReadCarrierRejectionList(auth);
        case actionTypes.UPDATE_CARRIER_REJECTION: return checks.canUpdateCarrierRejection(auth);
        case actionTypes.DELETE_CARRIER_REJECTION: return checks.canDeleteCarrierRejection(auth);

        case actionTypes.CREATE_CLAIM: return checks.canCreateClaim(auth);
        case actionTypes.READ_CLAIM: return checks.canReadClaim(auth);
        case actionTypes.READ_LIST_CLAIM: return checks.canReadClaimList(auth);
        case actionTypes.UPDATE_CLAIM: return checks.canUpdateClaim(auth);
        case actionTypes.DELETE_CLAIM: return checks.canDeleteClaim(auth);

        case actionTypes.CREATE_COMMODITY: return checks.canCreateCommodity(auth);
        case actionTypes.READ_COMMODITY: return checks.canReadCommodity(auth);
        case actionTypes.READ_LIST_COMMODITY: return checks.canReadCommodityList(auth);
        case actionTypes.UPDATE_COMMODITY: return checks.canUpdateCommodity(auth);
        case actionTypes.DELETE_COMMODITY: return checks.canDeleteCommodity(auth);

        case actionTypes.CREATE_CONFIGURATION: return checks.canCreateConfiguration(auth);
        case actionTypes.READ_CONFIGURATION: return checks.canReadConfiguration(auth);
        case actionTypes.READ_LIST_CONFIGURATION: return checks.canReadConfigurationList(auth);
        case actionTypes.UPDATE_CONFIGURATION: return checks.canUpdateConfiguration(auth);
        case actionTypes.DELETE_CONFIGURATION: return checks.canDeleteConfiguration(auth);

        case actionTypes.CREATE_CONTENT: return checks.canCreateContent(auth);
        case actionTypes.READ_CONTENT: return checks.canReadContent(auth);
        case actionTypes.READ_LIST_CONTENT: return checks.canReadContentList(auth);
        case actionTypes.UPDATE_CONTENT: return checks.canUpdateContent(auth);
        case actionTypes.DELETE_CONTENT: return checks.canDeleteContent(auth);

        case actionTypes.CREATE_DOCUMENT: return checks.canCreateDocument(auth);
        case actionTypes.READ_DOCUMENT: return checks.canReadDocument(auth);
        case actionTypes.READ_LIST_DOCUMENT: return checks.canReadDocumentList(auth);
        case actionTypes.UPDATE_DOCUMENT: return checks.canUpdateDocument(auth);
        case actionTypes.DELETE_DOCUMENT: return checks.canDeleteDocument(auth);

        case actionTypes.CREATE_FEATURE: return checks.canCreateFeature(auth);
        case actionTypes.READ_FEATURE: return checks.canReadFeature(auth);
        case actionTypes.READ_LIST_FEATURE: return checks.canReadFeatureList(auth);
        case actionTypes.UPDATE_FEATURE: return checks.canUpdateFeature(auth);
        case actionTypes.DELETE_FEATURE: return checks.canDeleteFeature(auth);

        case actionTypes.CREATE_INVOICE_LINE_ITEM: return checks.canCreateInvoiceLineItem(auth);
        case actionTypes.READ_INVOICE_LINE_ITEM: return checks.canReadInvoiceLineItem(auth);
        case actionTypes.READ_LIST_INVOICE_LINE_ITEM: return checks.canReadInvoiceLineItemList(auth);
        case actionTypes.UPDATE_INVOICE_LINE_ITEM: return checks.canUpdateInvoiceLineItem(auth);
        case actionTypes.DELETE_INVOICE_LINE_ITEM: return checks.canDeleteInvoiceLineItem(auth);

        case actionTypes.CREATE_INVOICE: return checks.canCreateInvoice(auth);
        case actionTypes.READ_INVOICE: return checks.canReadInvoice(auth);
        case actionTypes.READ_LIST_INVOICE: return checks.canReadInvoiceList(auth);
        case actionTypes.UPDATE_INVOICE: return checks.canUpdateInvoice(auth);
        case actionTypes.DELETE_INVOICE: return checks.canDeleteInvoice(auth);

        case actionTypes.CREATE_LANE: return checks.canCreateLane(auth);
        case actionTypes.READ_LANE: return checks.canReadLane(auth);
        case actionTypes.READ_LIST_LANE: return checks.canReadLaneList(auth);
        case actionTypes.UPDATE_LANE: return checks.canUpdateLane(auth);
        case actionTypes.DELETE_LANE: return checks.canDeleteLane(auth);

        case actionTypes.CREATE_LINE_ITEM_TYPE: return checks.canCreateLineItemType(auth);
        case actionTypes.READ_LINE_ITEM_TYPE: return checks.canReadLineItemType(auth);
        case actionTypes.READ_LIST_LINE_ITEM_TYPE: return checks.canReadLineItemTypeList(auth);
        case actionTypes.UPDATE_LINE_ITEM_TYPE: return checks.canUpdateLineItemType(auth);
        case actionTypes.DELETE_LINE_ITEM_TYPE: return checks.canDeleteLineItemType(auth);

        case actionTypes.CREATE_LINKED_ACCOUNT: return checks.canCreateLinkedAccount(auth, accountId);
        case actionTypes.READ_LINKED_ACCOUNT: return checks.canReadLinkedAccount(auth, accountId);
        case actionTypes.READ_LIST_LINKED_ACCOUNT: return checks.canReadLinkedAccountList(auth, accountId);
        case actionTypes.UPDATE_LINKED_ACCOUNT: return checks.canUpdateLinkedAccount(auth, accountId);
        case actionTypes.DELETE_LINKED_ACCOUNT: return checks.canDeleteLinkedAccount(auth);

        case actionTypes.CREATE_LINKED_LOAD_LANE: return checks.canCreateLinkedLoadLane(auth, accountId);
        case actionTypes.READ_LINKED_LOAD_LANE: return checks.canReadLinkedLoadLane(auth, accountId);
        case actionTypes.READ_LIST_LINKED_LOAD_LANE: return checks.canReadLinkedLoadLaneList(auth, accountId);
        case actionTypes.UPDATE_LINKED_LOAD_LANE: return checks.canUpdateLinkedLoadLane(auth, accountId);
        case actionTypes.DELETE_LINKED_LOAD_LANE: return checks.canDeleteLinkedLoadLane(auth);

        case actionTypes.CREATE_LOAD_ASSET: return checks.canCreateLoadAsset(auth, entityObject);
        case actionTypes.READ_LOAD_ASSET: return checks.canReadLoadAsset(auth, entityObject);
        case actionTypes.READ_LIST_LOAD_ASSET: return checks.canReadLoadAssetList(auth, entityObject);
        case actionTypes.UPDATE_LOAD_ASSET: return checks.canUpdateLoadAsset(auth, entityObject);
        case actionTypes.DELETE_LOAD_ASSET: return checks.canDeleteLoadAsset(auth, entityObject);

        case actionTypes.CREATE_LOAD_CANCELLATION: return checks.canCreateLoadCancellation(auth, entityObject);
        case actionTypes.READ_LOAD_CANCELLATION: return checks.canReadLoadCancellation(auth, entityObject);
        case actionTypes.READ_LIST_LOAD_CANCELLATION: return checks.canReadLoadCancellationList(auth, entityObject);
        case actionTypes.UPDATE_LOAD_CANCELLATION: return checks.canUpdateLoadCancellation(auth, entityObject);
        case actionTypes.DELETE_LOAD_CANCELLATION: return checks.canDeleteLoadCancellation(auth, entityObject);

        case actionTypes.CREATE_LOAD_CARRIER: return checks.canCreateLoadCarrier(auth, entityObject);
        case actionTypes.READ_LOAD_CARRIER: return checks.canReadLoadCarrier(auth, entityObject);
        case actionTypes.READ_LIST_LOAD_CARRIER: return checks.canReadLoadCarrierList(auth, entityObject);
        case actionTypes.UPDATE_LOAD_CARRIER: return checks.canUpdateLoadCarrier(auth, entityObject);
        case actionTypes.DELETE_LOAD_CARRIER: return checks.canDeleteLoadCarrier(auth, entityObject);

        case actionTypes.CREATE_LOAD_CARRIER_INVOICE: return checks.canCreateLoadCarrierInvoice(auth, entityObject);
        case actionTypes.READ_LOAD_CARRIER_INVOICE: return checks.canReadLoadCarrierInvoice(auth, entityObject);
        case actionTypes.READ_LIST_LOAD_CARRIER_INVOICE: return checks.canReadLoadCarrierInvoiceList(auth, entityObject);
        case actionTypes.UPDATE_LOAD_CARRIER_INVOICE: return checks.canUpdateLoadCarrierInvoice(auth, entityObject);
        case actionTypes.DELETE_LOAD_CARRIER_INVOICE: return checks.canDeleteLoadCarrierInvoice(auth, entityObject);

        case actionTypes.READ_LOAD_COMPLETION_CHECKLIST: return checks.canReadLoadCompletionChecklist(auth, entityObject);
        case actionTypes.UPDATE_LOAD_COMPLETION_CHECKLIST: return checks.canUpdateLoadCompletionChecklist(auth, entityObject);

        case actionTypes.CREATE_LOAD_DRIVER: return checks.canCreateLoadDriver(auth, entityObject);
        case actionTypes.READ_LOAD_DRIVER: return checks.canReadLoadDriver(auth, entityObject);
        case actionTypes.READ_LIST_LOAD_DRIVER: return checks.canReadLoadDriverList(auth, entityObject);
        case actionTypes.UPDATE_LOAD_DRIVER: return checks.canUpdateLoadDriver(auth, entityObject);
        case actionTypes.DELETE_LOAD_DRIVER: return checks.canDeleteLoadDriver(auth, entityObject);

        case actionTypes.CREATE_LOAD_EVENT: return checks.canCreateLoadEvent(auth, entityObject);
        case actionTypes.READ_LOAD_EVENT: return checks.canReadLoadEvent(auth, entityObject);
        case actionTypes.READ_LIST_LOAD_EVENT: return checks.canReadLoadEventList(auth, entityObject);
        case actionTypes.UPDATE_LOAD_EVENT: return checks.canUpdateLoadEvent(auth);
        case actionTypes.DELETE_LOAD_EVENT: return checks.canDeleteLoadEvent(auth);

        case actionTypes.CREATE_LOAD_INVOICE: return checks.canCreateLoadInvoice(auth, entityObject);
        case actionTypes.READ_LOAD_INVOICE: return checks.canReadLoadInvoice(auth, entityObject);
        case actionTypes.READ_LIST_LOAD_INVOICE: return checks.canReadLoadInvoiceList(auth, entityObject);
        case actionTypes.UPDATE_LOAD_INVOICE: return checks.canUpdateLoadInvoice(auth, entityObject);
        case actionTypes.DELETE_LOAD_INVOICE: return checks.canDeleteLoadInvoice(auth, entityObject);

        case actionTypes.CREATE_LOAD_INVOICE_LINE_ITEM: return checks.canCreateLoadInvoiceLineItem(auth, entityObject);
        case actionTypes.READ_LOAD_INVOICE_LINE_ITEM: return checks.canReadLoadInvoiceLineItem(auth, entityObject);
        case actionTypes.READ_LIST_LOAD_INVOICE_LINE_ITEM: return checks.canReadLoadInvoiceLineItemList(auth, entityObject);
        case actionTypes.UPDATE_LOAD_INVOICE_LINE_ITEM: return checks.canUpdateLoadInvoiceLineItem(auth, entityObject);
        case actionTypes.DELETE_LOAD_INVOICE_LINE_ITEM: return checks.canDeleteLoadInvoiceLineItem(auth, entityObject);

        case actionTypes.CREATE_LOAD_LANE: return checks.canCreateLoadLane(auth, accountId);
        case actionTypes.READ_LOAD_LANE: return checks.canReadLoadLane(auth, accountId);
        case actionTypes.READ_LIST_LOAD_LANE: return checks.canReadLoadLaneList(auth, accountId);
        case actionTypes.UPDATE_LOAD_LANE: return checks.canUpdateLoadLane(auth, accountId);
        case actionTypes.DELETE_LOAD_LANE: return checks.canDeleteLoadLane(auth);

        case actionTypes.CREATE_LOAD_LOCATION: return checks.canCreateLoadLocation(auth, entityObject);
        case actionTypes.READ_LOAD_LOCATION: return checks.canReadLoadLocation(auth);
        case actionTypes.READ_LIST_LOAD_LOCATION: return checks.canReadLoadLocationList(auth);
        case actionTypes.UPDATE_LOAD_LOCATION: return checks.canUpdateLoadLocation(auth);
        case actionTypes.DELETE_LOAD_LOCATION: return checks.canDeleteLoadLocation(auth);

        case actionTypes.CREATE_LOAD_MESSAGE: return checks.canCreateLoadMessage(auth, entityObject);
        case actionTypes.READ_LOAD_MESSAGE: return checks.canReadLoadMessage(auth);
        case actionTypes.READ_LIST_LOAD_MESSAGE: return checks.canReadLoadMessageList(auth);
        case actionTypes.UPDATE_LOAD_MESSAGE: return checks.canUpdateLoadMessage(auth);
        case actionTypes.DELETE_LOAD_MESSAGE: return checks.canDeleteLoadMessage(auth);

        case actionTypes.CREATE_LOAD_NOTIFICATION: return checks.canCreateLoadNotification(auth, entityObject);
        case actionTypes.READ_LOAD_NOTIFICATION: return checks.canReadLoadNotification(auth);
        case actionTypes.READ_LIST_LOAD_NOTIFICATION: return checks.canReadLoadNotificationList(auth);
        case actionTypes.UPDATE_LOAD_NOTIFICATION: return checks.canUpdateLoadNotification(auth);
        case actionTypes.DELETE_LOAD_NOTIFICATION: return checks.canDeleteLoadNotification(auth);

        case actionTypes.CREATE_LOAD_PRICE_CONFIRMATION: return checks.canCreateLoadPriceConfirmation(auth, entityObject);
        case actionTypes.READ_LOAD_PRICE_CONFIRMATION: return checks.canReadLoadPriceConfirmation(auth, entityObject);
        case actionTypes.READ_LIST_LOAD_PRICE_CONFIRMATION: return checks.canReadLoadPriceConfirmationList(auth, entityObject);
        case actionTypes.UPDATE_LOAD_PRICE_CONFIRMATION: return checks.canUpdateLoadRateConfirmation(auth, entityObject);
        case actionTypes.DELETE_LOAD_PRICE_CONFIRMATION: return checks.canDeleteLoadPriceConfirmation(auth, entityObject);

        case actionTypes.CREATE_LOAD_RATE_CONFIRMATION: return checks.canCreateLoadRateConfirmation(auth, entityObject);
        case actionTypes.READ_LOAD_RATE_CONFIRMATION: return checks.canReadLoadRateConfirmation(auth, entityObject);
        case actionTypes.READ_LIST_LOAD_RATE_CONFIRMATION: return checks.canReadLoadRateConfirmationList(auth, entityObject);
        case actionTypes.UPDATE_LOAD_RATE_CONFIRMATION: return checks.canUpdateLoadRateConfirmation(auth, entityObject);
        case actionTypes.DELETE_LOAD_RATE_CONFIRMATION: return checks.canDeleteLoadRateConfirmation(auth, entityObject);

        case actionTypes.CREATE_LOAD_SHIPPER: return checks.canCreateLoadShipper(auth, entityObject);
        case actionTypes.READ_LOAD_SHIPPER: return checks.canReadLoadShipper(auth, entityObject);
        case actionTypes.READ_LIST_LOAD_SHIPPER: return checks.canReadLoadShipperList(auth, entityObject);
        case actionTypes.UPDATE_LOAD_SHIPPER: return checks.canUpdateLoadShipper(auth, entityObject);
        case actionTypes.DELETE_LOAD_SHIPPER: return checks.canDeleteLoadShipper(auth, entityObject);

        case actionTypes.CREATE_LOAD_SHIPPER_INVOICE: return checks.canCreateLoadShipperInvoice(auth, entityObject);
        case actionTypes.READ_LOAD_SHIPPER_INVOICE: return checks.canReadLoadShipperInvoice(auth, entityObject);
        case actionTypes.READ_LIST_LOAD_SHIPPER_INVOICE: return checks.canReadLoadShipperInvoiceList(auth, entityObject);
        case actionTypes.UPDATE_LOAD_SHIPPER_INVOICE: return checks.canUpdateLoadShipperInvoice(auth, entityObject);
        case actionTypes.DELETE_LOAD_SHIPPER_INVOICE: return checks.canDeleteLoadShipperInvoice(auth, entityObject);

        case actionTypes.CREATE_LOAD: return checks.canCreateLoad(auth);
        case actionTypes.READ_LOAD: return checks.canReadLoad(auth, entityObject);
        case actionTypes.READ_LIST_LOAD: return checks.canReadLoadList(auth);
        case actionTypes.UPDATE_LOAD: return checks.canUpdateLoad(auth, entityObject);
        case actionTypes.DELETE_LOAD: return checks.canDeleteLoad(auth);
        case actionTypes.DUPLICATE_LOAD: return checks.canDuplicateLoad(auth, entityObject);

        case actionTypes.CREATE_LOAD_STOP: return checks.canCreateLoadStop(auth, entityObject);
        case actionTypes.READ_LOAD_STOP: return checks.canReadLoadStop(auth, entityObject);
        case actionTypes.READ_LIST_LOAD_STOP: return checks.canReadLoadStopList(auth, entityObject);
        case actionTypes.UPDATE_LOAD_STOP: return checks.canUpdateLoadStop(auth, entityObject);
        case actionTypes.DELETE_LOAD_STOP: return checks.canDeleteLoadStop(auth, entityObject);

        case actionTypes.CREATE_LOAD_TRANSACTION: return checks.canCreateLoadTransaction(auth, entityObject);
        case actionTypes.READ_LOAD_TRANSACTION: return checks.canReadLoadTransaction(auth, entityObject);
        case actionTypes.READ_LIST_LOAD_TRANSACTION: return checks.canReadLoadTransactionList(auth, entityObject);
        case actionTypes.UPDATE_LOAD_TRANSACTION: return checks.canUpdateLoadTransaction(auth, entityObject);
        case actionTypes.DELETE_LOAD_TRANSACTION: return checks.canDeleteLoadTransaction(auth, entityObject);

        case actionTypes.CREATE_LOCATION: return checks.canCreateLocation(auth, accountId);
        case actionTypes.READ_LOCATION: return checks.canReadLocation(auth, accountId);
        case actionTypes.READ_LIST_LOCATION: return checks.canReadLocationList(auth, accountId);
        case actionTypes.UPDATE_LOCATION: return checks.canUpdateLocation(auth, accountId);
        case actionTypes.DELETE_LOCATION: return checks.canDeleteLocation(auth);

        case actionTypes.CREATE_NOTE: return checks.canCreateNote(auth);
        case actionTypes.READ_NOTE: return checks.canReadNote(auth, entityObject);
        case actionTypes.READ_LIST_NOTE: return checks.canReadNoteList(auth);
        case actionTypes.UPDATE_NOTE: return checks.canUpdateNote(auth, entityObject);
        case actionTypes.DELETE_NOTE: return checks.canDeleteNote(auth);

        case actionTypes.CREATE_PREFERRED_LANE: return checks.canCreatePreferredLane(auth, accountId);
        case actionTypes.READ_PREFERRED_LANE: return checks.canReadPreferredLane(auth, accountId);
        case actionTypes.READ_LIST_PREFERRED_LANE: return checks.canReadPreferredLaneList(auth, accountId);
        case actionTypes.UPDATE_PREFERRED_LANE: return checks.canUpdatePreferredLane(auth, accountId);
        case actionTypes.DELETE_PREFERRED_LANE: return checks.canDeletePreferredLane(auth);

        case actionTypes.CREATE_PRODUCT: return checks.canCreateProduct(auth, accountId);
        case actionTypes.READ_PRODUCT: return checks.canReadProduct(auth, accountId);
        case actionTypes.READ_LIST_PRODUCT: return checks.canReadProductList(auth, accountId);
        case actionTypes.UPDATE_PRODUCT: return checks.canUpdateProduct(auth, accountId);
        case actionTypes.DELETE_PRODUCT: return checks.canDeleteProduct(auth);

        case actionTypes.CREATE_SCHEDULED_SHIFT: return checks.canCreateScheduledShift(auth, accountId);
        case actionTypes.READ_SCHEDULED_SHIFT: return checks.canReadScheduledShift(auth, accountId);
        case actionTypes.READ_LIST_SCHEDULED_SHIFT: return checks.canReadScheduledShiftList(auth, accountId);
        case actionTypes.UPDATE_SCHEDULED_SHIFT: return checks.canUpdateScheduledShift(auth, accountId);
        case actionTypes.DELETE_SCHEDULED_SHIFT: return checks.canDeleteScheduledShift(auth);

        case actionTypes.CREATE_SERVICE: return checks.canCreateService(auth);
        case actionTypes.READ_SERVICE: return checks.canReadService(auth);
        case actionTypes.READ_LIST_SERVICE: return checks.canReadServiceList(auth);
        case actionTypes.UPDATE_SERVICE: return checks.canUpdateService(auth);
        case actionTypes.DELETE_SERVICE: return checks.canDeleteService(auth);

        case actionTypes.CREATE_SHIFT: return checks.canCreateShift(auth, accountId);
        case actionTypes.READ_SHIFT: return checks.canReadShift(auth, accountId);
        case actionTypes.READ_LIST_SHIFT: return checks.canReadShiftList(auth, accountId);
        case actionTypes.UPDATE_SHIFT: return checks.canUpdateShift(auth, accountId);
        case actionTypes.DELETE_SHIFT: return checks.canDeleteShift(auth);

        case actionTypes.CREATE_STATE: return checks.canCreateState(auth);
        case actionTypes.READ_STATE: return checks.canReadState(auth);
        case actionTypes.READ_LIST_STATE: return checks.canReadStateList(auth);
        case actionTypes.UPDATE_STATE: return checks.canUpdateState(auth);
        case actionTypes.DELETE_STATE: return checks.canDeleteState(auth);

        case actionTypes.CREATE_TRAILER_TYPE: return checks.canCreateTrailerType(auth);
        case actionTypes.READ_TRAILER_TYPE: return checks.canReadTrailerType(auth);
        case actionTypes.READ_LIST_TRAILER_TYPE: return checks.canReadTrailerTypeList(auth);
        case actionTypes.UPDATE_TRAILER_TYPE: return checks.canUpdateTrailerType(auth);
        case actionTypes.DELETE_TRAILER_TYPE: return checks.canDeleteTrailerType(auth);

        case actionTypes.CREATE_TRANSACTION: return checks.canCreateTransaction(auth);
        case actionTypes.READ_TRANSACTION: return checks.canReadTransaction(auth);
        case actionTypes.READ_LIST_TRANSACTION: return checks.canReadTransactionList(auth);
        case actionTypes.UPDATE_TRANSACTION: return checks.canUpdateTransaction(auth);
        case actionTypes.DELETE_TRANSACTION: return checks.canDeleteTransaction(auth);

        case actionTypes.CREATE_USER: return checks.canCreateUser(auth);
        case actionTypes.READ_USER: return checks.canReadUser(auth, entityObject);
        case actionTypes.READ_LIST_USER: return checks.canReadUserList(auth);
        case actionTypes.UPDATE_USER: return checks.canUpdateUser(auth, entityObject);

        default:
            return false;
    }
};

export default {
    allowStaff,
    allowStaffAdmin,
    allowStaffVP,
    isAuthenticated,
    isAccountUserVerified,
    isAccountActive,
    isAccountVerified,
    isAccountUser,
    isAccountUserWithRoles,
    isAccountAdmin,
    isAccountOperationsAdmin,
    isDriver,
    isLoadOwner,
    isLoadDriver,
    isLoadShipper,
    isLoadCarrier,
    isLoadBroker,
    canViewLoad,
    isAccountUserSelf,
    isUserSelf,
    checkEntitlements,
    canView,
    isLoadTMS,
    isLoadBrokerage
};