import { isNullOrUndefined, isNumberNotEmpty } from "../../shared/objectUtils";
import EntityUtils from "../entityUtils";

export const getProductDTO = (product, accounts = [], commodities = []) => {
    // Get Account Info
    product.account = EntityUtils.getAccountInfo(product.accountId, accounts);

    // Get Commodities Info
    product.commodity = EntityUtils.getCommodityInfo(product.commodityId, commodities);

    return product;
};

export const getAddProductRequestDTO = (payload) => {
    if (isNumberNotEmpty(payload.unitPrice)) {
        payload.unitPrice = Number(payload.unitPrice);
        if (isNullOrUndefined(payload.unitPriceUnit)) {
            payload.unitPriceUnit = 'USD';
        }
    }

    if (isNumberNotEmpty(payload.unitWeight)) {
        payload.unitWeight = Number(payload.unitWeight);
        if (isNullOrUndefined(payload.unitWeightUnit)) {
            payload.unitWeightUnit = 'LB';
        }
    }

    return payload;
};

export const getUpdateProductRequestDTO = (payload) => {
    if (isNumberNotEmpty(payload.unitPrice)) {
        payload.unitPrice = Number(payload.unitPrice);
        if (isNullOrUndefined(payload.unitPriceUnit)) {
            payload.unitPriceUnit = 'USD';
        }
    } else if (payload.unitPrice === null) {
        payload.unitPrice = null;
        payload.unitPriceUnit = null;
    }

    if (isNumberNotEmpty(payload.unitWeight)) {
        payload.unitWeight = Number(payload.unitWeight);
        if (isNullOrUndefined(payload.unitWeightUnit)) {
            payload.unitWeightUnit = 'LB';
        }
    } else if (payload.unitWeight === null) {
        payload.unitWeight = null;
        payload.unitWeightUnit = null;
    }

    return payload;
};