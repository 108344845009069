import React, { useMemo } from 'react';
import { Alert, Button, Spin } from "antd";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import { isListNotEmpty } from '../../shared/objectUtils';
import * as Data from '../../api/data';

const EditLoadStatusCompleted = ({ load, cancel, currentStatus, stops }) => {
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loadEvents.isLoadEventUpdateLoading);
    const error = useSelector(state => state.loadEvents.updateLoadEventError);

    //#endregion
    //#region onSubmit and onCancel

    const completeStops = async () => {
        if (isListNotEmpty(stops)) {
            await Data.completeLoadStops(load.id, stops)

            dispatch(actionCreators.sendLoadEvent({
                loadId: load.id,
                eventType: 'LOAD_COMPLETED'
            }));
        }
    };

    const onSubmit = () => {
        dispatch(actionCreators.sendLoadEvent({
            loadId: load.id,
            eventType: 'LOAD_COMPLETED'
        }));
    };

    const onCancel = () => {
        dispatch(actionCreators.updateLoadEventComplete());
        cancel();
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLoadEventErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    console.log(currentStatus)

    //DROP_OFF_COMPLETED

    //#endregion

    if (currentStatus !== 'DROP_OFF_COMPLETED') {
        return (
            <Form onSubmit={methods.handleSubmit(completeStops)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Fieldset legend="Complete Stops">
                        <b>It appears that one or more Stops have not been marked as Completed. By pressing the Complete Stops and Load button below, all stops will be marked as Completed. It will be your responsibility to upload documents such as BOLS, POD, and scale tickets separately.</b>
                    </Fieldset>
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} disabled={(isLoading === true && error === null)} submitText="Complete Stops and Load" />
            </Form>
        );
    } else {
        return (
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Fieldset legend="Complete the Load">
                        <b>Make sure all the stops are complete and all stop documents such as BOLs and receipts have been uploaded.</b>
                    </Fieldset>
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} disabled={(isLoading === true && error === null)} submitText="Complete the Load" />
            </Form>
        );
    }
};

export default EditLoadStatusCompleted;