import React, { useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { Button, Drawer, Modal, Row, Col, Spin, Select, Empty, Tabs, Checkbox, Switch, Alert, Space } from 'antd';
import EditScheduledShift from "../../components/EditScheduledShift/EditScheduledShift";
import NewScheduledShift from "../../components/NewScheduledShift/NewScheduledShift";
import { useLocation, withRouter } from 'react-router-dom';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import ComplexCard from '../../components/ComplexCard/ComplexCard';
import classes from './ScheduledShifts.module.scss';
import Header from '../../components/Header/Header';
import { isBooleanTrue, isListEmpty, isListNotEmpty, isNotNullOrUndefined, isObjectNotEmpty, isStringEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { Can, CanDo, CanSee } from '../../shared/entitlements/entitlements';
import { selectListError, selectListIsLoading, selectListRecords } from '../../store/utility';
import Shifts from '../Shifts/Shifts';
import { CalendarOutlined } from '@ant-design/icons';
import { ClipPath } from '@react-pdf/renderer';

const { Option } = Select;
const { TabPane } = Tabs;

const ScheduledShifts = ({ accountId, ...props }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;
    const singularEntityName = "Scheduled Shift";

    //#endregion
    //#region useLocation

    const location = useLocation();

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);
    const scheduledShifts = useSelector(state => selectListRecords(state.scheduledShifts.lists, isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')));
    const isLoading = useSelector(state => selectListIsLoading(state.scheduledShifts.lists, isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')));
    const error = useSelector(state => selectListError(state.scheduledShifts.lists, isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')));
    const shifts = useSelector(state => selectListRecords(state.shifts.lists, isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')));
    const isLoadingShifts = useSelector(state => selectListIsLoading(state.shifts.lists, isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')));
    const accountUsers = useSelector(state => selectListRecords(state.accountUsers.lists, isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')));
    const isLoadingAccountUsers = useSelector(state => selectListIsLoading(state.accountUsers.lists, isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')));
    const accounts = useSelector(state => state.orchestrator.accounts);

    //#endregion
    //#region useRefs

    const calendarRef = useRef(null);
    const schedulerRef = useRef(null);

    //#endregion
    //#region useStates

    const [tabKey, setTabKey] = useState('calendar');
    const [selectedRecord, setSelectedRecord] = useState({});
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showRemoveEntity, setShowRemoveEntity] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [resourceMap, setResourceMap] = useState([]);
    const [selectedShiftIds, setSelectedShiftIds] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedAccountUserId, setSelectedAccountUserId] = useState(null);
    const [selectedAccountUserIds, setSelectedAccountUserIds] = useState([]);
    const [selectedAccountId, setSelectedAccountId] = useState(null);
    const [driversOnly, setDriversOnly] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState(null);

    //#endregion
    //#region tab methods

    const onTabChange = (key) => {
        props.history.replace({ ...props.location, hash: `#${key}` });
    };

    //#endregion
    //#region toggles

    const closeNewEntity = () => {
        setShowNewEntity(false);
        setSelectedAccountUserId(null);
    };

    const closeEditEntity = () => {
        setShowEditEntity(false);
        setSelectedAccountUserId(null);
    };

    const closeRemoveEntity = () => {
        setShowRemoveEntity(false);
        setSelectedAccountUserId(null);
    };

    const toggleShowDetails = () => {
        setShowDetails(!showDetails);
    };

    //#endregion
    //#region fetch methods

    const getScheduledShifts = () => {
        if (isStringNotEmpty(selectedAccountId)) {
            let searchParams = {
                page: 1,
                size: 1000000,
                sort: 'startDateTime',
                order: 'asc',
                isDeleted: false,
                accountId: selectedAccountId
            };

            if (isListNotEmpty(selectedAccountUserIds)) {
                searchParams.accountUserIds = selectedAccountUserIds;
            } else if (isListNotEmpty(accountUsers) && driversOnly === true) {
                searchParams.accountUserIds = accountUsers.filter(i => i.isDriver === true).map(i => i.id);
            }

            if (isListNotEmpty(selectedShiftIds)) {
                searchParams.shiftId = selectedShiftIds;
            }

            dispatch(actionCreators.fetchScheduledShiftList(selectedAccountId, searchParams));
        }
    };

    //#endregion
    //#region calendar methods

    const handleDateSelect = (selectInfo) => {
        let calendarApi = selectInfo.view.calendar;

        calendarApi.unselect(); // clear date selection

        let selectedDateValue = moment(selectInfo.startStr).format('MM/DD/YYYY');
        setSelectedDate(selectedDateValue);

        setShowNewEntity(true);
    };

    const handleEmployeeDateSelect = (selectInfo) => {
        // console.log(selectInfo);
        let calendarApi = selectInfo.view.calendar;

        calendarApi.unselect(); // clear date selection

        let selectedDateValue = moment(selectInfo.startStr).format('MM/DD/YYYY');
        setSelectedDate(selectedDateValue);
        if (isObjectNotEmpty(selectInfo.resource) && isStringNotEmpty(selectInfo.resource.id)) {
            setSelectedAccountUserId(selectInfo.resource.id);
        }

        setShowNewEntity(true);
    };

    const handleEventClick = (clickInfo) => {
        clickInfo.jsEvent.preventDefault(); // don't let the browser navigate
        setSelectedRecord(clickInfo.event.extendedProps);
        let selectedDateValue = moment(clickInfo.event.startStr).format('MM/DD/YYYY');
        setSelectedDate(selectedDateValue);
        setShowDetails(true);
    };

    const handleEmployeeEventClick = (clickInfo) => {
        clickInfo.jsEvent.preventDefault(); // don't let the browser navigate
        // console.log(clickInfo);
        // console.log(clickInfo.event);
        // console.log(clickInfo.event._def.resourceIds);
        // console.log(clickInfo.jsEvent.path)
        let pathsArray = clickInfo.jsEvent.path;
        let node = pathsArray.find(i => i.className === 'fc-timeline-lane fc-resource');
        let resourceId = node.getAttribute('data-resource-id');

        setSelectedRecord(clickInfo.event.extendedProps);

        let selectedDateValue = moment(clickInfo.event.startStr).format('MM/DD/YYYY');
        setSelectedDate(selectedDateValue);

        if (isStringNotEmpty(resourceId)) {
            setSelectedAccountUserId(resourceId);
        }

        setShowDetails(true);
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        dispatch(actionCreators.getAccounts());
    }, []);

    useMemo(() => {
        if (isStringNotEmpty(location.hash)) {
            setTabKey(location.hash.replace('#', ''));
        } else {
            setTabKey('scheduler');
            props.history.replace({ ...location, hash: `#scheduler` });
        }
    }, [location]);

    useMemo(() => {
        if (isStringNotEmpty(selectedAccountId)) {
            dispatch(actionCreators.fetchAccountUserList(selectedAccountId, { page: 1, size: 1000000, accountId: selectedAccountId, isDeleted: false }));
            dispatch(actionCreators.fetchShiftList(selectedAccountId, { page: 1, size: 1000000, accountId: selectedAccountId, isDeleted: false }));
        }
    }, [selectedAccountId]);

    useMemo(() => {
        let resourceArray = [];
        if (isListNotEmpty(accountUsers)) {
            if (driversOnly === true) {
                if (isListNotEmpty(selectedAccountUserIds)) {
                    accountUsers.filter(i => selectedAccountUserIds.includes(i.id) && i.isDriver === true).forEach((accountUser) => {
                        // let resourceScheduledShifts = scheduledShifts.filter(i => i.extendedProps.accountUserIds && i.extendedProps.accountUserIds.length > 0 && i.extendedProps.accountUserIds.includes(accountUser.id));
                        // if (selectedShiftIds !== undefined && selectedShiftIds !== null && selectedShiftIds.length > 0) {
                        //     let isInShift = false;
                        //     selectedShiftIds.forEach((selectedShiftId) => {
                        //         if (resourceScheduledShifts && resourceScheduledShifts.length > 0) {
                        //             if (resourceScheduledShifts.filter(i => i.extendedProps.shiftId === selectedShiftId).length > 0) {
                        //                 isInShift = true;
                        //             }
                        //         }
                        //     });

                        //     if (isInShift === true) {
                        //         resourceArray.push({
                        //             ...accountUser,
                        //             title: `${accountUser.firstName} ${accountUser.lastName}${accountUser.title ? ' (' + accountUser.title + ')' : ''}`,
                        //             scheduledShifts: resourceScheduledShifts
                        //         });
                        //     }
                        // } else {
                        resourceArray.push({
                            ...accountUser,
                            title: `${accountUser.firstName} ${accountUser.lastName}${accountUser.title ? ' (' + accountUser.title + ')' : ''}`,
                            // scheduledShifts: resourceScheduledShifts
                        });
                        // }
                    });
                } else {
                    accountUsers.filter(i => i.isDriver === true).forEach((accountUser) => {
                        // let resourceScheduledShifts = scheduledShifts.filter(i => i.extendedProps.accountUserIds && i.extendedProps.accountUserIds.length > 0 && i.extendedProps.accountUserIds.includes(accountUser.id));
                        // if (selectedShiftIds !== undefined && selectedShiftIds !== null && selectedShiftIds.length > 0) {
                        //     let isInShift = false;
                        //     selectedShiftIds.forEach((selectedShiftId) => {
                        //         if (resourceScheduledShifts && resourceScheduledShifts.length > 0) {
                        //             if (resourceScheduledShifts.filter(i => i.extendedProps.shiftId === selectedShiftId).length > 0) {
                        //                 isInShift = true;
                        //             }
                        //         }
                        //     });
                        //     console.log(isInShift)

                        //     if (isInShift === true) {
                        //         resourceArray.push({
                        //             ...accountUser,
                        //             title: `${accountUser.firstName} ${accountUser.lastName}${accountUser.title ? ' (' + accountUser.title + ')' : ''}`,
                        //             scheduledShifts: resourceScheduledShifts
                        //         });
                        //     }
                        // } else {
                        resourceArray.push({
                            ...accountUser,
                            title: `${accountUser.firstName} ${accountUser.lastName}${accountUser.title ? ' (' + accountUser.title + ')' : ''}`,
                            // scheduledShifts: resourceScheduledShifts
                        });
                        // }
                    });
                }
            } else {
                if (isListNotEmpty(selectedAccountUserIds)) {
                    accountUsers.filter(i => selectedAccountUserIds.includes(i.id)).forEach((accountUser) => {
                        // let resourceScheduledShifts = scheduledShifts.filter(i => i.extendedProps.accountUserIds && i.extendedProps.accountUserIds.length > 0 && i.extendedProps.accountUserIds.includes(accountUser.id));
                        // if (selectedShiftIds !== undefined && selectedShiftIds !== null && selectedShiftIds.length > 0) {
                        //     let isInShift = false;
                        //     selectedShiftIds.forEach((selectedShiftId) => {
                        //         if (resourceScheduledShifts && resourceScheduledShifts.length > 0) {
                        //             if (resourceScheduledShifts.filter(i => i.extendedProps.shiftId === selectedShiftId).length > 0) {
                        //                 isInShift = true;
                        //             }
                        //         }
                        //     });

                        //     if (isInShift === true) {
                        //         resourceArray.push({
                        //             ...accountUser,
                        //             title: `${accountUser.firstName} ${accountUser.lastName}${accountUser.title ? ' (' + accountUser.title + ')' : ''}`,
                        //             scheduledShifts: resourceScheduledShifts
                        //         });
                        //     }
                        // } else {
                        resourceArray.push({
                            ...accountUser,
                            title: `${accountUser.firstName} ${accountUser.lastName}${accountUser.title ? ' (' + accountUser.title + ')' : ''}`,
                            // scheduledShifts: resourceScheduledShifts
                        });
                        // }
                    });
                } else {
                    accountUsers.forEach((accountUser) => {
                        // let resourceScheduledShifts = scheduledShifts.filter(i => i.extendedProps.accountUserIds && i.extendedProps.accountUserIds.length > 0 && i.extendedProps.accountUserIds.includes(accountUser.id));
                        // if (selectedShiftIds !== undefined && selectedShiftIds !== null && selectedShiftIds.length > 0) {
                        //     let isInShift = false;
                        //     selectedShiftIds.forEach((selectedShiftId) => {
                        //         if (resourceScheduledShifts && resourceScheduledShifts.length > 0) {
                        //             if (resourceScheduledShifts.filter(i => i.extendedProps.shiftId === selectedShiftId).length > 0) {
                        //                 isInShift = true;
                        //             }
                        //         }
                        //     });
                        //     console.log(isInShift)

                        //     if (isInShift === true) {
                        //         resourceArray.push({
                        //             ...accountUser,
                        //             title: `${accountUser.firstName} ${accountUser.lastName}${accountUser.title ? ' (' + accountUser.title + ')' : ''}`,
                        //             scheduledShifts: resourceScheduledShifts
                        //         });
                        //     }
                        // } else {
                        resourceArray.push({
                            ...accountUser,
                            title: `${accountUser.firstName} ${accountUser.lastName}${accountUser.title ? ' (' + accountUser.title + ')' : ''}`,
                            // scheduledShifts: resourceScheduledShifts
                        });
                        // }
                    });
                }
            }
        }

        setResourceMap(resourceArray);
    }, [accountUsers, selectedAccountUserIds, driversOnly]);

    useMemo(() => {
        if (isListNotEmpty(accounts)) {
            if (isStringNotEmpty(accountId)) {
                setSelectedAccountId(accountId);
                let account = accounts.find(i => i.id === accountId);
                setSelectedAccount(account);
            } else if (entityType !== 'STAFF' && isStringNotEmpty(entityId)) {
                setSelectedAccountId(entityId);
                let account = accounts.find(i => i.id === entityId);
                setSelectedAccount(account);
            }
        } else {
            setSelectedAccount(null);
        }
    }, [accountId, entityType, entityId, accounts]);

    useMemo(() => {
        if (isStringNotEmpty(selectedAccountId)) {
            getScheduledShifts();
        }
    }, [selectedAccountId, selectedAccountUserIds, selectedShiftIds, driversOnly, accountUsers]);

    //#endregion
    //#region displays

    const newEntityComponents = (
        <Drawer
            title={"New " + singularEntityName}
            onClose={closeNewEntity}
            visible={showNewEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewScheduledShift
                cancel={closeNewEntity}
                accountId={selectedAccountId}
                selectedDate={selectedDate}
                selectedAccountUserId={selectedAccountUserId ? selectedAccountUserId : null}
                accountUsers={accountUsers}
                shifts={shifts}
            />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName}
            onClose={closeEditEntity}
            visible={showEditEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditScheduledShift
                cancel={closeEditEntity}
                scheduledShift={selectedRecord}
                selectedDate={selectedDate}
                selectedAccountUserId={selectedAccountUserId ? selectedAccountUserId : null}
                accountUsers={accountUsers}
                shifts={shifts}
                isRemove={false}
            />
        </Drawer>
    );

    const removeEntityComponents = (
        <Drawer
            title={"Remove Employee from " + singularEntityName}
            onClose={closeRemoveEntity}
            visible={showRemoveEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditScheduledShift
                cancel={closeRemoveEntity}
                scheduledShift={selectedRecord}
                selectedDate={selectedDate}
                selectedAccountUserId={selectedAccountUserId ? selectedAccountUserId : null}
                accountUsers={accountUsers}
                shifts={shifts}
                isRemove={true}
            />
        </Drawer>
    );

    const getAccountUser = (acctUserId) => {
        if (isStringNotEmpty(acctUserId) && isListNotEmpty(accountUsers)) {
            let accountUser = accountUsers.find(i => i.id === acctUserId);
            if (isObjectNotEmpty(accountUser)) {
                return (
                    <div>{accountUser.firstName} {accountUser.lastName}{accountUser.title ? ' (' + accountUser.title + ')' : ''}</div>
                );
            }
        }

        return null;
    };

    const eventDetailsComponents = (scheduledShift) => {
        // console.log(`scheduledShift: ${JSON.stringify(scheduledShift)}`);

        if (isObjectNotEmpty(scheduledShift)) {
            let shift = scheduledShift.shift;
            let title = isObjectNotEmpty(shift) && isStringNotEmpty(shift.name) ? shift.name : '';
            let time = `${isStringNotEmpty(selectedDate) ? selectedDate + ', ' : ''}${isObjectNotEmpty(shift) && isStringNotEmpty(shift.startTime) ? shift.startTime : ''} - ${isObjectNotEmpty(shift) && isStringNotEmpty(shift.endTime) ? shift.endTime : ''}`;

            let footerButtons = [
                <Button onClick={(e) => { toggleShowDetails(); }}>Close</Button>
            ];
            if (isStringNotEmpty(selectedAccountUserId)) {
                footerButtons.push(<Button type="primary" onClick={(e) => { toggleShowDetails(); setShowEditEntity(true); }}>Swap Shifts</Button>);
                footerButtons.push(<Button type="primary" onClick={(e) => { toggleShowDetails(); setShowRemoveEntity(true); }}>Remove</Button>);
            } else {
                footerButtons.push(<Button type="primary" onClick={(e) => { toggleShowDetails(); setShowEditEntity(true); }}>Edit</Button>);
            }

            return (
                <Modal
                    title={`${title}: ${time}`}
                    visible={showDetails === true}
                    onCancel={(e) => { toggleShowDetails(); }}
                    onOk={(e) => { toggleShowDetails(); }}
                    footer={footerButtons}
                >
                    <div>
                        <div>
                            <span><strong>{isStringNotEmpty(scheduledShift.title) ? scheduledShift.title : ''}</strong></span>
                        </div>
                        {isListNotEmpty(scheduledShift.accountUsers) && isStringEmpty(selectedAccountUserId) ? scheduledShift.accountUsers.map((accountUser) => {
                            return (
                                <div>{accountUser.firstName} {accountUser.lastName}{isStringNotEmpty(accountUser.title) ? ' (' + accountUser.title + ')' : ''}</div>
                            );
                        }) : (isStringNotEmpty(selectedAccountUserId) ? (getAccountUser(selectedAccountUserId)) : null)}
                    </div>
                </Modal>
            );
        } else {
            return null;
        }
    };

    const renderEventContent = (eventInfo) => {
        let scheduledShift = isObjectNotEmpty(eventInfo) && isObjectNotEmpty(eventInfo.event) && isObjectNotEmpty(eventInfo.event.extendedProps) ? eventInfo.event.extendedProps : null;
        let shift = isObjectNotEmpty(scheduledShift) && isObjectNotEmpty(scheduledShift.shift) ? scheduledShift.shift : null;
        if (isObjectNotEmpty(scheduledShift) && isObjectNotEmpty(shift)) {
            return (
                <div style={{ backgroundColor: eventInfo.backgroundColor, color: eventInfo.textColor, width: '100%', textOverflow: 'ellipsis', overflow: 'hidden', overflowWrap: 'normal', wordWrap: 'normal' }}>
                    <b>{shift.startTime} - {shift.endTime}</b> <i>{shift.name}</i>

                    {/* {scheduledShift.accountUsers ? scheduledShift.accountUsers.map((accountUser) => {
                        return (
                            <div>{accountUser.firstName} {accountUser.lastName}{accountUser.title ? ' (' + accountUser.title + ')' : ''}</div>
                        );
                    }) : null} */}
                </div>
            );
        } else {
            return null;
        }
    };

    const renderEmployeeEventContent = (eventInfo) => {
        let scheduledShift = isObjectNotEmpty(eventInfo) && isObjectNotEmpty(eventInfo.event) && isObjectNotEmpty(eventInfo.event.extendedProps) ? eventInfo.event.extendedProps : null;
        let shift = isObjectNotEmpty(scheduledShift) && isObjectNotEmpty(scheduledShift.shift) ? scheduledShift.shift : null;
        if (isObjectNotEmpty(scheduledShift) && isObjectNotEmpty(shift)) {
            return (
                <div style={{ backgroundColor: eventInfo.backgroundColor, color: eventInfo.textColor, width: '100%' }}>
                    <div>
                        <b>{shift.startTime} - {shift.endTime}</b> <i>{shift.name}</i>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };

    //#endregion

    if (isNotNullOrUndefined(scheduledShifts)) {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true && error === null) || isLoadingAccountUsers === true || isLoadingShifts === true}>
                <Tabs activeKey={tabKey} onChange={onTabChange} destroyInactiveTabPane={true} tabBarStyle={{ backgroundColor: '#ffffff', paddingRight: 24, paddingLeft: 24, margin: 0 }}>
                    <TabPane tab="Shifts" key="shifts">
                        <div style={{ padding: 24, backgroundColor: '#ffffff' }}>
                            <Shifts accountId={isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')} />
                        </div>
                    </TabPane>
                    {/* <TabPane tab="Calendar" key="calendar">
                        <div style={{ padding: 24 }}>
                            <Row gutter={[24, 24]}>
                                <Col span={24}>
                                    <Header
                                        title="Calendar"
                                        footer={
                                            <div><span style={{ fontSize: 16, fontWeight: 500 }}>To setup a shift, click on a cell in the calendar to add a shift for that date and then choose which Employees you want to assign to that shift.</span></div>
                                        }
                                    />
                                </Col>
                                <Col span={24}>
                                    <Row>
                                        <Col span={24}>
                                            <span><strong>Filters: </strong></span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {isStringEmpty(accountId) ? (
                                            <CanSee staffOnly={true}>
                                                <Col span={8}>
                                                    <Select
                                                        placeholder="Filter by Account"
                                                        allowClear={true}
                                                        style={{ width: '100%' }}
                                                        virtual={false}
                                                        onChange={(selected) => { setSelectedAccountId(selected); }}
                                                        value={selectedAccountId}
                                                        showSearch={true}
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Accounts for you to choose from." />}
                                                    >
                                                        {accounts.map(i => <Option value={i.id} key={i.id}>{i.name}</Option>)}
                                                    </Select>
                                                </Col>
                                            </CanSee>
                                        ) : null}
                                        <Col span={8}>
                                            <Select
                                                placeholder="Filter by Shift"
                                                mode="multiple"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onChange={(selected) => { setSelectedShiftIds(selected); }}
                                                value={selectedShiftIds}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Shifts for you to choose from." />}
                                            >
                                                {shifts.map(i => <Option value={i.id} key={i.id}>{i.name}: {i.startTime} - {i.endTime}</Option>)}
                                            </Select>
                                        </Col>
                                        <Col span={8}>
                                            <Select
                                                placeholder="Filter by Employee"
                                                mode="multiple"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onChange={(selected) => { setSelectedAccountUserIds(selected); }}
                                                value={selectedAccountUserIds}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Employees for you to choose from." />}
                                            >
                                                {driversOnly === true ? accountUsers.filter(i => i.isDriver === true).map(i => <Option value={i.id} key={i.id}>{i.firstName} {i.lastName}{i.title ? ' (' + i.title + ')' : ''}</Option>) : accountUsers.map(i => <Option value={i.id} key={i.id}>{i.firstName} {i.lastName}{i.title ? ' (' + i.title + ')' : ''}</Option>)}
                                            </Select>
                                            {(CanDo({ entityTypes: ['CARRIER'] }) || (isObjectNotEmpty(selectedAccount) && isBooleanTrue(selectedAccount.entityTypes.includes("CARRIER")))) ? (
                                                <div style={{ padding: 2 }}>
                                                    <Switch
                                                        checkedChildren="Yes"
                                                        unCheckedChildren="No"
                                                        onChange={(checked, event) => { setDriversOnly(checked); return checked; }}
                                                        checked={driversOnly}
                                                        name={'driversOnly'}
                                                    />
                                                    <span style={{ marginLeft: 8 }}>Drivers Only</span>
                                                </div>
                                            ) : null}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <ComplexCard>
                                <CanSee entityAction="READ_LIST" entityModel="SCHEDULED_SHIFT" accountId={selectedAccountId}>
                                    <FullCalendar
                                        ref={calendarRef}
                                        plugins={[dayGridPlugin, interactionPlugin]}
                                        headerToolbar={{
                                            left: 'today prev,next',
                                            center: 'title',
                                            right: 'dayGridMonth,dayGridWeek'
                                        }}
                                        views={{
                                            dayGridMonth: { buttonText: 'month', slotDuration: { days: 1 }, eventContent: renderEventContent, select: handleDateSelect, eventClick: handleEventClick },
                                            dayGridWeek: { buttonText: 'week', slotDuration: { days: 1 }, eventContent: renderEventContent, select: handleDateSelect, eventClick: handleEventClick },
                                        }}
                                        titleFormat={{ year: 'numeric', month: 'long', day: 'numeric' }}
                                        eventTimeFormat={{ hour: '2-digit', minute: '2-digit', hour12: false }}
                                        initialView='dayGridMonth'
                                        editable={false}
                                        selectable={true}
                                        dayMaxEvents={false}
                                        dayMaxEventRows={false}
                                        nextDayThreshold={'06:00:00'}
                                        weekends={true}
                                        events={scheduledShifts}
                                    />
                                </CanSee>
                            </ComplexCard>
                        </div>
                    </TabPane> */}
                    <TabPane tab="Scheduler" key="scheduler">
                        <div style={{ padding: 24, backgroundColor: '#ffffff' }}>
                            <Row gutter={[24, 24]} style={{ marginBottom: 24 }}>
                                <Col span={24}>
                                    <Header
                                        title="Scheduler"
                                        style={{ padding: '8px 0px' }}
                                        footer={
                                            <div><span style={{ fontSize: 16, fontWeight: 500 }}>To schedule an Employee on a shift, click on the appropriate cell in the table below.</span></div>
                                        }
                                    />
                                </Col>
                                {isListEmpty(shifts) && isLoadingShifts === false ? (
                                    <Col span={24}>
                                        <Alert
                                            style={{ marginBottom: 0 }}
                                            type="error"
                                            showIcon
                                            message="No shifts have been setup yet. You will have to setup shifts first before you are able to use the Scheduler."
                                            action={<Space><Button size="small" type="ghost" onClick={(e) => { onTabChange('shifts'); }}>Fix</Button></Space>}
                                        />
                                    </Col>
                                ) : null}
                                <Col span={24}>
                                    <span><strong>Filters: </strong></span>
                                </Col>
                                {isStringEmpty(accountId) ? (
                                    <CanSee staffOnly={true}>
                                        <Col span={8}>
                                            <Select
                                                placeholder="Filter by Account"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onChange={(selected) => { setSelectedAccountId(selected); }}
                                                value={selectedAccountId}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Accounts for you to choose from." />}
                                            >
                                                {accounts.map(i => <Option value={i.id} key={i.id}>{i.name}</Option>)}
                                            </Select>
                                        </Col>
                                    </CanSee>
                                ) : null}
                                <Col span={8}>
                                    <Select
                                        placeholder="Filter by Shift"
                                        mode="multiple"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onChange={(selected) => { setSelectedShiftIds(selected); }}
                                        value={selectedShiftIds}
                                        showSearch={true}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Shifts for you to choose from." />}
                                    >
                                        {shifts.map(i => <Option value={i.id} key={i.id}>{i.name}: {i.startTime} - {i.endTime}</Option>)}
                                    </Select>
                                </Col>
                                <Col span={8}>
                                    <Select
                                        placeholder="Filter by Employee"
                                        mode="multiple"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onChange={(selected) => { setSelectedAccountUserIds(selected); }}
                                        value={selectedAccountUserIds}
                                        showSearch={true}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Employees for you to choose from." />}
                                    >
                                        {driversOnly === true ? accountUsers.filter(i => i.isDriver === true).map(i => <Option value={i.id} key={i.id}>{i.firstName} {i.lastName}{i.title ? ' (' + i.title + ')' : ''}</Option>) : accountUsers.map(i => <Option value={i.id} key={i.id}>{i.firstName} {i.lastName}{i.title ? ' (' + i.title + ')' : ''}</Option>)}
                                    </Select>
                                </Col>
                                {(CanDo({ entityTypes: ['CARRIER'] }) || (isObjectNotEmpty(selectedAccount) && isBooleanTrue(selectedAccount.entityTypes.includes("CARRIER")))) ? (
                                    <Col span={8}>
                                        <Switch
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            onChange={(checked, event) => { setDriversOnly(checked); return checked; }}
                                            checked={driversOnly}
                                            name={'driversOnly'}
                                        />
                                        <span style={{ marginLeft: 8 }}>Show Drivers Only</span>
                                    </Col>
                                ) : null}
                            </Row>
                            <ComplexCard>
                                <CanSee entityAction="READ_LIST" entityModel="SCHEDULED_SHIFT" accountId={selectedAccountId}>
                                    <FullCalendar
                                        ref={schedulerRef}
                                        plugins={[dayGridPlugin, interactionPlugin, resourceTimelinePlugin]}
                                        headerToolbar={{
                                            left: 'today prev,next',
                                            center: 'title',
                                            right: 'resourceTimelineWeek,resourceTimelineTenDay,resourceTimelineMonth,resourceTimelineYear'
                                        }}
                                        titleFormat={{ year: 'numeric', month: 'long', day: 'numeric' }}
                                        views={{
                                            resourceTimelineWeek: {
                                                type: 'resourceTimeline',
                                                duration: { days: 7 },
                                                slotDuration: { days: 1 },
                                                buttonText: 'week'
                                            },
                                            resourceTimelineTenDay: {
                                                type: 'resourceTimeline',
                                                duration: { days: 10 },
                                                slotDuration: { days: 1 },
                                                buttonText: '10 days'
                                            }
                                        }}
                                        slotLabelFormat={[
                                            { month: '2-digit', year: 'numeric', day: '2-digit' }, // top level of text
                                            { weekday: 'short' } // lower level of text
                                        ]}
                                        resourceAreaHeaderContent="Employees"
                                        initialView='resourceTimelineWeek'
                                        resources={resourceMap}
                                        resourceOrder={'firstName,LastName'}
                                        editable={false}
                                        selectable={isListNotEmpty(shifts) ? true : false}
                                        allDaySlot={true}
                                        dayMaxEventRows={false}
                                        dayMaxEvents={false}
                                        nextDayThreshold={'06:00:00'}
                                        slotMinWidth={100}
                                        weekends={true}
                                        expandRows={false}
                                        contentHeight={"auto"}
                                        // eventsSet={(events) => { console.log(events) }}
                                        // resourcesSet={(resources) => { console.log(resources) }}
                                        events={scheduledShifts}
                                        select={handleEmployeeDateSelect}
                                        eventContent={renderEmployeeEventContent}
                                        eventClick={handleEmployeeEventClick}
                                    />
                                </CanSee>
                            </ComplexCard>
                        </div>
                    </TabPane>
                    <TabPane tab="Agenda" key="agenda">
                        <div style={{ padding: 24, backgroundColor: '#ffffff' }}>
                            <Row gutter={[24, 24]} style={{ marginBottom: 24 }}>
                                <Col span={24}>
                                    <Header
                                        title="Agenda"
                                        style={{ padding: '8px 0px' }}
                                        footer={
                                            <div><span style={{ fontSize: 16, fontWeight: 500 }}>The Agenda view is used to view the shifts and edit shifts. You cannot add shifts from this view.</span></div>
                                        }
                                    />
                                </Col>
                                <Col span={24}>
                                    <span><strong>Filters: </strong></span>
                                </Col>
                                {isStringEmpty(accountId) ? (
                                    <CanSee staffOnly={true}>
                                        <Col span={entityType === 'STAFF' ? 6 : 8}>
                                            <Select
                                                placeholder="Filter by Account"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onChange={(selected) => { setSelectedAccountId(selected); }}
                                                value={selectedAccountId}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Accounts for you to choose from." />}
                                            >
                                                {accounts.map(i => <Option value={i.id} key={i.id}>{i.name}</Option>)}
                                            </Select>
                                        </Col>
                                    </CanSee>
                                ) : null}
                                <Col span={isStringEmpty(accountId) ? 6 : 8}>
                                    <Select
                                        placeholder="Filter by Shift"
                                        mode="multiple"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onChange={(selected) => { setSelectedShiftIds(selected); }}
                                        value={selectedShiftIds}
                                        showSearch={true}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Shifts for you to choose from." />}
                                    >
                                        {shifts.map(i => <Option value={i.id} key={i.id}>{i.name}: {i.startTime} - {i.endTime}</Option>)}
                                    </Select>
                                </Col>
                                <Col span={isStringEmpty(accountId) ? 6 : 8}>
                                    <Select
                                        placeholder="Filter by Employee"
                                        mode="multiple"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onChange={(selected) => { setSelectedAccountUserIds(selected); }}
                                        value={selectedAccountUserIds}
                                        showSearch={true}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Employees for you to choose from." />}
                                    >
                                        {driversOnly === true ? accountUsers.filter(i => i.isDriver === true).map(i => <Option value={i.id} key={i.id}>{i.firstName} {i.lastName}{i.title ? ' (' + i.title + ')' : ''}</Option>) : accountUsers.map(i => <Option value={i.id} key={i.id}>{i.firstName} {i.lastName}{i.title ? ' (' + i.title + ')' : ''}</Option>)}
                                    </Select>
                                </Col>
                                {(CanDo({ entityTypes: ['CARRIER'] }) || (isObjectNotEmpty(selectedAccount) && isBooleanTrue(selectedAccount.entityTypes.includes("CARRIER")))) ? (
                                    <Col span={isStringEmpty(accountId) ? 6 : 8}>
                                        <Switch
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            onChange={(checked, event) => { setDriversOnly(checked); return checked; }}
                                            checked={driversOnly}
                                            name={'driversOnly'}
                                        />
                                        <span style={{ marginLeft: 8 }}>Show Drivers Only</span>
                                    </Col>
                                ) : null}
                            </Row>
                            <ComplexCard>
                                <CanSee entityAction="READ_LIST" entityModel="SCHEDULED_SHIFT" accountId={selectedAccountId}>
                                    <FullCalendar
                                        ref={calendarRef}
                                        plugins={[interactionPlugin, listPlugin]}
                                        headerToolbar={{
                                            left: 'today prev,next',
                                            center: 'title',
                                            right: 'listMonth,listWeek'
                                        }}
                                        views={{
                                            listWeek: { buttonText: 'weekly', slotDuration: { days: 1 }, selectable: false, eventClick: handleEventClick },
                                            listMonth: { buttonText: 'monthly', slotDuration: { days: 1 }, selectable: false, eventClick: handleEventClick },
                                        }}
                                        titleFormat={{ year: 'numeric', month: 'long', day: 'numeric' }}
                                        eventTimeFormat={{ hour: '2-digit', minute: '2-digit', hour12: false }}
                                        initialView='listMonth'
                                        editable={false}
                                        dayMaxEvents={false}
                                        dayMaxEventRows={false}
                                        nextDayThreshold={'06:00:00'}
                                        weekends={true}
                                        events={scheduledShifts}
                                        contentHeight={"auto"}
                                    />
                                </CanSee>
                            </ComplexCard>
                        </div>
                    </TabPane>
                </Tabs>
                {newEntityComponents}
                {editEntityComponents}
                {removeEntityComponents}
                {eventDetailsComponents(selectedRecord)}
            </Spin>
        );
    } else {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true && error === null) || isLoadingAccountUsers === true || isLoadingShifts === true} />
        );
    }
};

export default withRouter(ScheduledShifts);