import axiosAuthenticated from "../../api/axiosAuthenticated";
import { isNotNullOrUndefined } from "../../shared/objectUtils";
import * as DTO from "../dtos/index";

const lineItemTypesPath = '/lineItemTypes';

//#region Line Item Types Methods

export const getAllLineItemTypes = async (trailerTypes = []) => {
    const lineItemTypesRes = await axiosAuthenticated.get(lineItemTypesPath, { params: { page: 1, size: 1000000, isDeleted: false } });
    if (lineItemTypesRes && lineItemTypesRes.status === 200) {
        const lineItemTypes = lineItemTypesRes.data.data;

        const transformedLineItemTypes = lineItemTypes.map((lineItemType) => { return DTO.getLineItemTypeDTO(lineItemType, trailerTypes); });

        return transformedLineItemTypes;
    }

    return [];
};

export const getLineItemTypes = async (searchParams = {}, pagination = {}, trailerTypes = []) => {
    const lineItemTypesRes = await axiosAuthenticated.get(lineItemTypesPath, { params: { ...searchParams } });
    if (lineItemTypesRes && lineItemTypesRes.status === 200) {
        const lineItemTypes = lineItemTypesRes.data.data;
        const otherData = lineItemTypesRes.data;

        const transformedLineItemTypes = lineItemTypes.map((lineItemType) => { return DTO.getLineItemTypeDTO(lineItemType, trailerTypes); });

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;

        return { data: transformedLineItemTypes, searchParams: searchParams, pagination: pagination };
    }

    return { data: [], searchParams: searchParams, pagination: pagination };
};

export const getLineItemType = async (id, trailerTypes = []) => {
    const lineItemTypesRes = await axiosAuthenticated.get(lineItemTypesPath + `/${id}`);
    if (lineItemTypesRes && lineItemTypesRes.status === 200) {
        let lineItemType = lineItemTypesRes.data;

        return DTO.getLineItemTypeDTO(lineItemType, trailerTypes);
    }

    return null;
};

export const addLineItemType = async (payload, trailerTypes = []) => {
    const lineItemTypePayload = DTO.getAddLineItemTypeRequestDTO(payload);

    const lineItemTypeRes = await axiosAuthenticated.post(lineItemTypesPath, { ...lineItemTypePayload });
    if (lineItemTypeRes && lineItemTypeRes.status === 201) {
        let newLineItemType = lineItemTypeRes.data;

        return DTO.getLineItemTypeDTO(newLineItemType, trailerTypes);
    }

    return null;
};

export const updateLineItemType = async (id, payload, trailerTypes = []) => {
    const lineItemTypePayload = DTO.getUpdateLineItemTypeRequestDTO(payload);

    const lineItemTypeRes = await axiosAuthenticated.put(lineItemTypesPath + `/${id}`, { ...lineItemTypePayload });
    if (lineItemTypeRes && lineItemTypeRes.status === 200) {
        let updatedLineItemType = lineItemTypeRes.data;

        return DTO.getLineItemTypeDTO(updatedLineItemType, trailerTypes);
    }

    return null;
};

export const removeLineItemType = async (id) => {
    const lineItemTypeRes = await axiosAuthenticated.put(lineItemTypesPath + `/${id}`, { isDeleted: true });
    if (lineItemTypeRes && lineItemTypeRes.status === 200) {
        return lineItemTypeRes.data;
    }

    return null;
};

//#endregion