import axiosAuthenticated from "../../api/axiosAuthenticated";
import { isNotNullOrUndefined } from "../../shared/objectUtils";

const contentsPath = '/contents';

//#region Contents Methods

export const getContents = async (searchParams = {}, pagination = {}) => {
    const contentsRes = await axiosAuthenticated.get(contentsPath, { params: { ...searchParams } });
    if (contentsRes && contentsRes.status === 200) {
        const contents = contentsRes.data.data;
        const otherData = contentsRes.data;

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;

        return { data: contents, searchParams: searchParams, pagination: pagination };
    }

    return { data: [], searchParams: searchParams, pagination: pagination };
};

export const getContent = async (id) => {
    const contentsRes = await axiosAuthenticated.get(contentsPath + `/${id}`);
    if (contentsRes && contentsRes.status === 200) {
        return contentsRes.data;
    }

    return null;
};

export const addContent = async (payload) => {
    const contentsRes = await axiosAuthenticated.post(contentsPath, { ...payload });
    if (contentsRes && contentsRes.status === 201) {
        return contentsRes.data;
    }

    return null;
};

export const updateContent = async (id, payload) => {
    const contentsRes = await axiosAuthenticated.put(contentsPath + `/${id}`, { ...payload });
    if (contentsRes && contentsRes.status === 200) {
        return contentsRes.data;
    }

    return null;
};

export const removeContent = async (id) => {
    const contentsRes = await axiosAuthenticated.put(contentsPath + `/${id}`, { isDeleted: true });
    if (contentsRes && contentsRes.status === 200) {
        return contentsRes.data;
    }

    return null;
};

//#endregion