import * as actionTypes from "../actions/actionTypes";
import {
    addRecordStart,
    addRecordSuccess,
    addRecordFail,
    clearAddRecordError,
    clearAddRecordLoading,
    updateRecordFail,
    updateRecordStart,
    updateRecordSuccess,
    clearUpdateRecordError,
    clearUpdateRecordLoading,
    cancelAddRecord,
    cancelUpdateRecord,
    addRecordToBottom,
    updateRecord,
    removeRecord,
    fetchRecordStart,
    fetchRecordSuccess,
    fetchRecordFail,
    clearRecord,
    updateSingleRecord,
    fetchListStart,
    fetchListSuccess,
    fetchListFail,
    clearList,
    clearAllLists,
    addToBottomOfList,
    updateInList,
    removeFromList,
} from "../utility";

const initialState = {
    lists: {},
    record: null,
    isRecordLoading: false,
    isRecordAddLoading: null,
    isRecordUpdateLoading: null,
    recordError: null,
    addRecordError: null,
    updateRecordError: null,
    addRecordId: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PRODUCT_LIST_START: return fetchListStart(state, action.payload);
        case actionTypes.FETCH_PRODUCT_LIST_SUCCESS: return fetchListSuccess(state, action.payload);
        case actionTypes.FETCH_PRODUCT_LIST_FAIL: return fetchListFail(state, action.payload);
        case actionTypes.CLEAR_PRODUCT_LIST: return clearList(state, action.payload);
        case actionTypes.CLEAR_PRODUCT_LISTS: return clearAllLists(state);
        case actionTypes.ADD_TO_PRODUCT_LIST: return addToBottomOfList(state, action.payload);
        case actionTypes.UPDATE_IN_PRODUCT_LIST: return updateInList(state, action.payload);
        case actionTypes.REMOVE_FROM_PRODUCT_LIST: return removeFromList(state, action.payload);
        case actionTypes.FETCH_PRODUCT_START: return fetchRecordStart(state);
        case actionTypes.FETCH_PRODUCT_SUCCESS: return fetchRecordSuccess(state, action.payload);
        case actionTypes.FETCH_PRODUCT_FAIL: return fetchRecordFail(state, action.payload);
        case actionTypes.CLEAR_PRODUCT: return clearRecord(state);
        case actionTypes.ADD_PRODUCT: return addRecordToBottom(state, action.payload);
        case actionTypes.ADD_PRODUCT_START: return addRecordStart(state);
        case actionTypes.ADD_PRODUCT_SUCCESS: return addRecordSuccess(state);
        case actionTypes.ADD_PRODUCT_FAIL: return addRecordFail(state, action.payload);
        case actionTypes.ADD_PRODUCT_LOADING_CLEAR: return clearAddRecordLoading(state);
        case actionTypes.ADD_PRODUCT_ERROR_CLEAR: return clearAddRecordError(state);
        case actionTypes.ADD_PRODUCT_CANCEL: return cancelAddRecord(state);
        case actionTypes.UPDATE_PRODUCT: return updateRecord(state, action.payload);
        case actionTypes.UPDATE_SINGLE_PRODUCT: return updateSingleRecord(state, action.payload);
        case actionTypes.UPDATE_PRODUCT_START: return updateRecordStart(state);
        case actionTypes.UPDATE_PRODUCT_SUCCESS: return updateRecordSuccess(state);
        case actionTypes.UPDATE_PRODUCT_FAIL: return updateRecordFail(state, action.payload);
        case actionTypes.UPDATE_PRODUCT_LOADING_CLEAR: return clearUpdateRecordLoading(state);
        case actionTypes.UPDATE_PRODUCT_ERROR_CLEAR: return clearUpdateRecordError(state);
        case actionTypes.UPDATE_PRODUCT_CANCEL: return cancelUpdateRecord(state);
        case actionTypes.REMOVE_PRODUCT: return removeRecord(state, action.payload);
        default:
            return state;
    }
};

export default reducer;