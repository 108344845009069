import React, { useMemo, useState } from 'react';
import classes from './InvoiceTable.module.scss';
import InvoiceHeaderRow from '../InvoiceHeaderRow/InvoiceHeaderRow';
import InvoiceRows from '../InvoiceRows/InvoiceRows';
import ValueFormatting from '../../shared/ValueFormatting';
import { isBooleanTrue, isListNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';

const InvoiceTable = ({ isPriceConfirmation = false, isRateConfirmation = false, totalTitle = 'Total', totalTitleStyle = null, totalStyle = null, data: invoiceLineItems, entityType, columns }) => {
    const [lineItems, setLineItems] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0.00);

    useMemo(() => {
        if (isListNotEmpty(invoiceLineItems) && isStringNotEmpty(entityType)) {
            let lineItemsList = [];
            let irisFees = 0.00;
            let irisBaseFees = 0.00;
            let irisQuantity = 0;
            let total = 0.00;

            if (isBooleanTrue(isPriceConfirmation)) {
                invoiceLineItems.forEach((invoiceLineItem) => {
                    if (((invoiceLineItem.isIrisFee === true && invoiceLineItem.quantityUnit === 'PER_MILE') || invoiceLineItem.quantityUnit === 'PER_MILE') && invoiceLineItem.status !== 'CANCELLED') {
                        if (invoiceLineItem.fromEntityType === entityType) {
                            irisBaseFees += invoiceLineItem.baseAmount;
                            irisFees += invoiceLineItem.totalAmount;
                            irisQuantity = invoiceLineItem.quantity;
                        } else if (invoiceLineItem.toEntityType === entityType) {
                            irisBaseFees -= invoiceLineItem.baseAmount;
                            irisFees -= invoiceLineItem.totalAmount;
                            irisQuantity = invoiceLineItem.quantity;
                        }
                    } else {
                        lineItemsList.push({
                            description: invoiceLineItem.description,
                            baseAmount: invoiceLineItem.fromEntityType === entityType ? invoiceLineItem.baseAmount : -1.0 * invoiceLineItem.baseAmount,
                            quantity: invoiceLineItem.quantity,
                            totalAmount: invoiceLineItem.fromEntityType === entityType ? invoiceLineItem.totalAmount : -1.0 * invoiceLineItem.totalAmount,
                            isCancelled: invoiceLineItem.status === 'CANCELLED'
                        });
                    }

                    if (invoiceLineItem.status !== 'CANCELLED') {
                        if (invoiceLineItem.fromEntityType === entityType) {
                            total += invoiceLineItem.totalAmount;
                        } else if (invoiceLineItem.toEntityType === entityType) {
                            total -= invoiceLineItem.totalAmount;
                        }
                    }
                });
            } else if (isBooleanTrue(isRateConfirmation)) {
                invoiceLineItems.forEach((invoiceLineItem) => {
                    if (((invoiceLineItem.isIrisFee === true && invoiceLineItem.quantityUnit === 'PER_MILE') || invoiceLineItem.quantityUnit === 'PER_MILE') && invoiceLineItem.status !== 'CANCELLED') {
                        if (invoiceLineItem.fromEntityType === entityType) {
                            irisBaseFees -= invoiceLineItem.baseAmount;
                            irisFees -= invoiceLineItem.totalAmount;
                            irisQuantity = invoiceLineItem.quantity;
                        } else if (invoiceLineItem.toEntityType === entityType) {
                            irisBaseFees += invoiceLineItem.baseAmount;
                            irisFees += invoiceLineItem.totalAmount;
                            irisQuantity = invoiceLineItem.quantity;
                        }
                    } else {
                        lineItemsList.push({
                            description: invoiceLineItem.description,
                            baseAmount: invoiceLineItem.toEntityType === entityType ? invoiceLineItem.baseAmount : -1.0 * invoiceLineItem.baseAmount,
                            quantity: invoiceLineItem.quantity,
                            totalAmount: invoiceLineItem.toEntityType === entityType ? invoiceLineItem.totalAmount : -1.0 * invoiceLineItem.totalAmount,
                            isCancelled: invoiceLineItem.status === 'CANCELLED'
                        });
                    }

                    if (invoiceLineItem.status !== 'CANCELLED') {
                        if (invoiceLineItem.fromEntityType === entityType) {
                            total -= invoiceLineItem.totalAmount;
                        } else if (invoiceLineItem.toEntityType === entityType) {
                            total += invoiceLineItem.totalAmount;
                        }
                    }
                });
            } else {
                invoiceLineItems.forEach((invoiceLineItem) => {
                    if (((invoiceLineItem.isIrisFee === true && invoiceLineItem.quantityUnit === 'PER_MILE') || invoiceLineItem.quantityUnit === 'PER_MILE') && invoiceLineItem.status !== 'CANCELLED') {
                        if (invoiceLineItem.fromEntityType === entityType) {
                            irisBaseFees += invoiceLineItem.baseAmount;
                            irisFees += invoiceLineItem.totalAmount;
                            irisQuantity = invoiceLineItem.quantity;
                        } else if (invoiceLineItem.toEntityType === entityType) {
                            irisBaseFees -= invoiceLineItem.baseAmount;
                            irisFees -= invoiceLineItem.totalAmount;
                            irisQuantity = invoiceLineItem.quantity;
                        }
                    } else {
                        lineItemsList.push({
                            description: invoiceLineItem.description,
                            baseAmount: invoiceLineItem.fromEntityType === entityType ? invoiceLineItem.baseAmount : -1.0 * invoiceLineItem.baseAmount,
                            quantity: invoiceLineItem.quantity,
                            totalAmount: invoiceLineItem.fromEntityType === entityType ? invoiceLineItem.totalAmount : -1.0 * invoiceLineItem.totalAmount,
                            isCancelled: invoiceLineItem.status === 'CANCELLED'
                        });
                    }

                    if (invoiceLineItem.status !== 'CANCELLED') {
                        if (invoiceLineItem.fromEntityType === entityType) {
                            total += invoiceLineItem.totalAmount;
                        } else if (invoiceLineItem.toEntityType === entityType) {
                            total -= invoiceLineItem.totalAmount;
                        }
                    }
                });
            }

            if (irisFees > 0) {
                lineItemsList.push({ description: "IRIS Fees Per Mile", baseAmount: irisBaseFees > 0 ? irisBaseFees : -1.0 * irisBaseFees, quantity: irisQuantity, totalAmount: irisFees > 0 ? irisFees : -1.0 * irisFees });
            } else if (irisFees < 0) {
                lineItemsList.push({ description: "IRIS Rate Per Mile", baseAmount: irisBaseFees > 0 ? irisBaseFees : -1.0 * irisBaseFees, quantity: irisQuantity, totalAmount: irisFees > 0 ? irisFees : -1.0 * irisFees });
            }

            setTotalAmount(total);
            setLineItems(lineItemsList);
        }
    }, [invoiceLineItems, isPriceConfirmation, isRateConfirmation, entityType]);

    return (
        <table className={classes.table}>
            <InvoiceHeaderRow columns={columns} />
            <tbody>
                <InvoiceRows data={lineItems} columns={columns} />
            </tbody>
            <tfoot>
                <tr>
                    <td className={classes.thickLine} colSpan={3} style={totalTitleStyle}><strong>{totalTitle}</strong></td>
                    <td className={[classes.textRight, classes.thickLine].join(' ')} style={totalStyle}><ValueFormatting.Money value={totalAmount} /></td>
                </tr>
            </tfoot>
        </table>
    );
};

export default InvoiceTable;