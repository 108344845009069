import Checks from '../entitlementUtils';

export const canCreateNote = (auth) => {
    return Checks.isAuthenticated(auth);
};

export const canReadNote = (auth, note) => {
    return Checks.isAuthenticated(auth);
};

export const canReadNoteList = (auth) => {
    return Checks.isAuthenticated(auth);
};

export const canUpdateNote = (auth, note) => {
    return Checks.isAuthenticated(auth);
};

export const canDeleteNote = (auth) => {
    return Checks.allowStaffAdmin(auth);
};