import React, { useState, useMemo } from 'react';
import { Button, Drawer, Empty, message, Select, Spin } from "antd";
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import { isListNotEmpty, isNumberNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { selectListRecords } from '../../store/utility';
import FormItem from '../FormItem/FormItem';
import NewEmployee from '../NewEmployee/NewEmployee';
import { sendTemplatedEmail } from '../../api/emailClient';
import StringFormatter from '../../shared/stringFormatter';

const stringFormatter = new StringFormatter();
const { Option } = Select;

const LoadSendLoadFinalizationEmail = ({ load, carrierId, stops, cancel }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    //#endregion
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const employees = useSelector(state => selectListRecords(state.orchestrator.employees, carrierId));

    //#endregion
    //#region useStates

    const [showNewEmployee, setShowNewEmployee] = useState(false);
    const [isSending, setIsSending] = useState(false);

    //#endregion
    //#region toggles

    const toggleNewEmployee = () => {
        setShowNewEmployee(!showNewEmployee);
    };

    //#endregion
    //#region onSubmit and onCancel

    const onCancel = () => {
        cancel();
    };

    const onSubmit = async (data) => {
        if (isListNotEmpty(employees) && isListNotEmpty(data.accountUserIds) && isObjectNotEmpty(load)) {
            setIsSending(true);

            let emailsWereSent = false;
            let toEmails = [];
            for (let i = 0; i < data.accountUserIds.length; i++) {
                const accountUserId = data.accountUserIds[i];
                const accountUser = employees.find(a => a.id === accountUserId);
                if (isObjectNotEmpty(accountUser)) {
                    toEmails.push({ email: accountUser.email, name: accountUser.firstName + ' ' + accountUser.lastName });
                }
            }

            // send emails to recipients
            if (isListNotEmpty(toEmails)) {
                let emailPayload = {
                    fromEmail: 'ap@irisfreight.com',
                    toEmails: toEmails,
                    subject: 'Load ' + load.irisId + ' needs to be Finalized on IRIS Freight',
                    trailerDetails: isObjectNotEmpty(load.equipmentNeeds) && isObjectNotEmpty(load.equipmentNeeds.trailerType) && isStringNotEmpty(load.equipmentNeeds.trailerType.description) && isNumberNotEmpty(load.equipmentNeeds.trailerLength) ? load.equipmentNeeds.trailerType.description + ', ' + stringFormatter.toFormattedString("Length", load.equipmentNeeds.trailerLength, load.equipmentNeeds.trailerLengthUnit) : '',
                    numberOfPickUpStops: stops.filter(s => s.stopType === 'PICK_UP').length.toString(),
                    numberOfDropOffStops: stops.filter(s => s.stopType === 'DROP_OFF').length.toString(),
                    loadId: load.id,
                    loadIrisId: load.irisId,
                    originState: isObjectNotEmpty(load.origin) && isObjectNotEmpty(load.origin.stopLocation) && isObjectNotEmpty(load.origin.stopLocation.address) && isStringNotEmpty(load.origin.stopLocation.address.state) ? load.origin.stopLocation.address.state : '',
                    originCity: isObjectNotEmpty(load.origin) && isObjectNotEmpty(load.origin.stopLocation) && isObjectNotEmpty(load.origin.stopLocation.address) && isStringNotEmpty(load.origin.stopLocation.address.city) ? load.origin.stopLocation.address.city : '',
                    destinationState: isObjectNotEmpty(load.destination) && isObjectNotEmpty(load.destination.stopLocation) && isObjectNotEmpty(load.destination.stopLocation.address) && isStringNotEmpty(load.destination.stopLocation.address.state) ? load.destination.stopLocation.address.state : '',
                    destinationCity: isObjectNotEmpty(load.destination) && isObjectNotEmpty(load.destination.stopLocation) && isObjectNotEmpty(load.destination.stopLocation.address) && isStringNotEmpty(load.destination.stopLocation.address.city) ? load.destination.stopLocation.address.city : '',
                    practicalDistance: isNumberNotEmpty(load.practicalDistance) ? stringFormatter.toFormattedString("Distance", load.practicalDistance, load.practicalDistanceUnit) : '',
                    rate: isNumberNotEmpty(load.carrierAmount) ? load.carrierAmount.toString() : ''
                };
                emailsWereSent = await sendTemplatedEmail("LOAD_COMPLETED_TO_CARRIER", emailPayload);
            }

            if (emailsWereSent === true) {
                message.success('Email(s) have been sent.');
                onCancel();
            } else {
                message.error('Failed to send email(s)');
            }
            setIsSending(false);
        }
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        if (isStringNotEmpty(carrierId)) {
            dispatch(actionCreators.getEmployees(carrierId, false));
        }
    }, [carrierId]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    //#endregion

    return (
        <>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isSending === true}>
                        <Fieldset legend="Would you like the Re-Send the Load Finalization Email to the carrier you have assigned to this load?">
                            <FormItem {...formItemLayout} label="Choose Employee(s) to send Load Finalization Email to" required
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please Search and Select Employee(s)"
                                        mode="multiple"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        showSearch={true}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Employees for you to choose from." />}
                                        ref={ref}
                                    >
                                        {employees.map(d => <Option value={d.id} key={d.id}>{d.firstName + ' ' + d.lastName} - Title: {d.title} - Email: {d.email}</Option>)}
                                    </Select>
                                )}
                                rules={{ required: 'Required Field' }}
                                name="accountUserIds"
                            />
                            <br />
                            <b>Employee Doesn't Exist Yet?</b>
                            <br />
                            <Button type='primary' onClick={toggleNewEmployee}>Add New Employee to Carrier Account</Button>
                        </Fieldset>
                    </Spin>
                    <FormButtons containerStyle={{ position: 'relative' }} cancel={onCancel} disabled={isSending === true} submitText="Re-Send the Load Finalization Email" />
                </Form>
            </FormProvider>
            <Drawer
                title={'Add New Employee'}
                width={fullWidth > 720 ? fullWidth / 2 : 360}
                onClose={toggleNewEmployee}
                visible={showNewEmployee}
                bodyStyle={{ paddingBottom: 80 }}
                style={{ zIndex: 1000 }}
                destroyOnClose={true}
                closable={false}
                maskClosable={false}
            >
                <NewEmployee cancel={toggleNewEmployee} accountId={carrierId} />
            </Drawer>
        </>
    );
};

export default LoadSendLoadFinalizationEmail;