import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import { Button, Menu, Dropdown, Drawer, Row, Col } from 'antd';
import classes from './LinkedAccounts.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools, faTruck } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import DataTable from '../../components/DataTable/DataTable';
import EditLinkedAccount from '../../components/EditLinkedAccount/EditLinkedAccount';
import { isNotNullOrUndefined, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { selectListIsLoading, selectListPagination, selectListRecords } from '../../store/utility';
import { compareByAlph } from '../../shared/tableUtils';

const LinkedAccounts = ({ accountId, entityTypes = [], title = "", actions = null, ...props }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const entityType = useSelector(state => state.auth.entityType);
    const entityId = useSelector(state => state.auth.entityId);
    const records = useSelector(state => selectListRecords(state.linkedAccounts.lists, `${isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')}_ACCOUNTS`));
    const isLoading = useSelector(state => selectListIsLoading(state.linkedAccounts.lists, `${isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')}_ACCOUNTS`));
    const pagination = useSelector(state => selectListPagination(state.linkedAccounts.lists, `${isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')}_ACCOUNTS`));

    //#endregion
    //#region useStates

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showEditEntity, setShowEditEntity] = useState(false);

    //#endregion
    //#region toggles

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    //#endregion
    //#region goTos

    const goToLinkedAccount = (record) => {
        if (isObjectNotEmpty(record)) {
            props.history.push({ pathname: `/linkedAccounts/${record.accountId}/${record.linkedAccountId}` }, {
                previousPageTitle: isStringNotEmpty(title) ? title : 'Accounts',
                previousPageLocation: props.location,
                previousBreadcrumbs: props.breadcrumbs,
            });
        }
    };

    //#endregion
    //#region table methods

    const refreshTable = () => {
        let searchParams = {
            page: 1,
            size: 10,
            sort: 'name',
            order: 'asc',
            entityTypes: entityTypes.join('|'),
            isDeleted: false
        };

        if (isStringNotEmpty(accountId)) {
            searchParams["links.accountId"] = accountId;
        } else if (isStringNotEmpty(entityId) && entityType !== 'STAFF') {
            searchParams["links.accountId"] = entityId;
        }

        dispatch(actionCreators.fetchLinkedAccountList(`${isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')}_ALL`, searchParams, isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : null)));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchLinkedAccountListSuccess(`${isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')}_ALL`, { params: { pagination: pager } }));

        const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';

        let searchParams = {
            page: pagination.current,
            size: pagination.pageSize,
            sort: sorter.field,
            order: sortOrder,
            entityTypes: entityTypes.join('|'),
            isDeleted: false
        };

        if (isStringNotEmpty(accountId)) {
            searchParams["links.accountId"] = accountId;
        } else if (isStringNotEmpty(entityId) && entityType !== 'STAFF') {
            searchParams["links.accountId"] = entityId;
        }

        dispatch(actionCreators.fetchLinkedAccountList(`${isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')}_ALL`, searchParams, isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : null)));
    };

    //#endregion
    //#region table displays

    const menu = (record) => {
        return (
            <Menu>
                <Menu.Item key="editLinkedAccount" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); toggleEditEntity(); }}>
                    <FontAwesomeIcon className="anticon" icon={faTruck} style={{ marginRight: 8 }} />
                    <span>Edit Account Details</span>
                </Menu.Item>
                <Menu.Item key="goToDashboard" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); goToLinkedAccount(record); }}>
                    <span>Go to Account Dashboard</span>
                </Menu.Item>
            </Menu>
        );
    };

    const columns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            className: classes.columnNoBreak,
            width: 50,
            render: (text, record) => {
                return (
                    <Dropdown overlay={menu(record)} trigger={['click']}>
                        <Button>Actions <DownOutlined /></Button>
                    </Dropdown>
                );
            },
            align: 'center',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => compareByAlph(a.name, b.name),
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Customer Id',
            dataIndex: 'customerId',
            key: 'customerId',
        }
    ];

    //#endregion
    //#region displays

    const editEntityComponents = (
        <Drawer
            title={"Edit Account Details"}
            onClose={toggleEditEntity}
            visible={showEditEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditLinkedAccount
                cancel={toggleEditEntity}
                linkedAccount={selectedRecord}
            />
        </Drawer>
    );

    //#endregion
    //#region useEffects

    useEffect(() => {
        refreshTable();
    }, [entityType, entityId]);

    //#endregion

    return (
        <DataTable
            dataSource={records}
            columns={columns}
            pagination={pagination}
            loading={isLoading === true}
            onChange={handleTableChange}
            title={() => (isStringNotEmpty(title) ? (isNotNullOrUndefined(actions) ? (
                <Row gutter={[4, 4]} style={{ display: 'flex', alignItems: 'center' }}>
                    <Col xs={12} sm={16} md={18} lg={20}><b>{title}</b></Col>
                    <Col xs={12} sm={8} md={6} lg={4} style={{ textAlign: 'right' }}>
                        {actions}
                    </Col>
                </Row>
            ) : (
                <Row gutter={[4, 4]} style={{ display: 'flex', alignItems: 'center' }}>
                    <Col span={24}><b>{title}</b></Col>
                </Row>
            )) : null)}
            rowKey={record => record.id}
            style={{ backgroundColor: '#ffffff' }}
        >
            {editEntityComponents}
        </DataTable>
    );
};

export default withRouter(LinkedAccounts);