import React, { useMemo, useEffect, useState } from 'react';
import { Button, Card, Col, message, Row, Popconfirm, Collapse, Alert, Spin, Switch, InputNumber } from "antd";
import { withRouter } from "react-router";
import { sortBy } from "lodash";
import { useForm, FormProvider } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import DataRow from '../../components/DataRow/DataRow';
import DataListRow from '../../components/DataListRow/DataListRow';
import DataCommodityListRow from '../../components/DataCommodityListRow/DataCommodityListRow';
import classes from './NewLoadAndRFB.module.scss';
import MomentDate from '../../shared/dateFormatter';
import Form from '../../components/Form/Form';
import { isBooleanTrue, isListEmpty, isListNotEmpty, isNotNullOrUndefined, isNullOrUndefined, isNumberGreaterThanZero, isNumberNotEmpty, isObjectEmpty, isObjectNotEmpty, isStringEmpty, isStringNotEmpty, isMomentDate, removeEmpty } from '../../shared/objectUtils';
import Enums from '../../shared/enums';
import StringFormatter from '../../shared/stringFormatter';
import logger from '../../shared/logger';
import * as Data from '../../api/data';
import LoadStopUtils from '../../api/utils/loadStopUtils';
import { CanDo, CanSee } from '../../shared/entitlements/entitlements';
import Fieldset from '../FormFieldset/FormFieldset';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import FormItem from '../FormItem/FormItem';
import DatePicker from '../DatePickerAutoAccept/DatePickerAutoAccept';
import DataTable from '../DataTable/DataTable';
import { selectListRecords } from '../../store/utility';
import moment from 'moment';
import Pricing from '../../shared/pricing';
import InvoiceUtils from '../../api/utils/invoiceUtils';
import MapWrapper from '../Map/Map';

const { Panel } = Collapse;
const stringFormatter = new StringFormatter();
const momentDate = new MomentDate();

const NewLoadAndRFB = ({ shipperId, loadLaneId, cancel, ...props }) => {
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loads.isRecordAddLoading);
    const error = useSelector(state => state.loads.addRecordError);
    const addRecordId = useSelector(state => state.loads.addRecordId);
    const accounts = useSelector(state => state.orchestrator.accounts);
    const trailerTypes = useSelector(state => state.orchestrator.trailerTypes);
    const lineItemTypes = useSelector(state => state.orchestrator.lineItemTypes);
    const entityType = useSelector(state => state.auth.entityType);
    const entityId = useSelector(state => state.auth.entityId);
    const yourAccount = useSelector(state => state.yourAccounts.record);
    const services = useSelector(state => state.orchestrator.services);
    const linkedAccounts = useSelector(state => selectListRecords(state.orchestrator.linkedAccounts, entityType === 'STAFF' ? shipperId : entityId));

    //#endregion
    //#region useStates

    const [isStepsLoading, setIsStepsLoading] = useState(false);
    const [shipperData, setShipperData] = useState({});
    const [equipmentData, setEquipmentData] = useState({});
    const [pickUpStops, setPickUpStops] = useState([]);
    const [dropOffStops, setDropOffStops] = useState([]);
    const [servicesData, setServicesData] = useState({});
    const [invoiceLineItems, setInvoiceLineItems] = useState([]);
    const [practicalRouteMiles, setPracticalRouteMiles] = useState(null);
    const [carrier, setCarrier] = useState(null);
    const [loadLane, setLoadLane] = useState({});
    const [duplicateCountDisabled, setDuplicateCountDisabled] = useState(true);
    const [parentNameDisabled, setParentNameDisabled] = useState(true);
    const [duplicateCount, setDuplicateCount] = useState(1);
    const [selectedAccountIds, setSelectedAccountIds] = useState([]);

    //#endregion
    //#region fetch async methods

    const getLoadLane = async (loadLaneId) => {
        let existingLoadLane = null;
        try {
            existingLoadLane = await Data.getLoadLane(loadLaneId, accounts, trailerTypes, shipperId);
        } catch (err) {
            logger.logDebugEvent('NewLoad.js', err.message, true);
        }

        return existingLoadLane;
    };

    //#endregion
    //#region submits, cancels

    const onCancel = () => {
        dispatch(actionCreators.addLoadCancel());
        cancel();
    };

    const done = (newLoadId = null) => {
        if (entityType === 'STAFF') {
            if (isStringNotEmpty(newLoadId) && duplicateCount === 1) {
                props.history.push({ pathname: "/loads/" + newLoadId });
            } else {
                cancel();
            }
        } else if (entityType === 'SHIPPER' || entityType === 'BROKER') {
            if (isStringNotEmpty(newLoadId) && duplicateCount === 1) {
                props.history.push({ pathname: "/loads/" + newLoadId });
            } else {
                cancel();
            }
        } else if (entityType === 'CARRIER') {
            if (isStringNotEmpty(newLoadId) && duplicateCount === 1) {
                props.history.push({ pathname: "/loads/" + newLoadId });
            } else if (isStringNotEmpty(newLoadId) && duplicateCount > 1) {
                props.history.push({ pathname: "/loadBundle/" + newLoadId });
            } else {
                props.history.push({ pathname: "/dispatch" });
            }
        } else {
            cancel();
        }

        message.success('Successfully Created Load!');
        dispatch(actionCreators.addLoadCancel());
    };

    const generateTMSInvoiceLineItem = (accountEntityId, accountEntityType) => {
        let newInvoiceLineItem = null;
        // if the serviceType is TMS then add the service fees
        if (isListNotEmpty(lineItemTypes) && isListNotEmpty(services) && isListNotEmpty(accounts) && isNumberNotEmpty(practicalRouteMiles)) {
            let lineItemType = lineItemTypes.find(j => j.name === 'TMS_SERVICE_FEE');
            let tmsService = services.find(j => j.serviceType === 'LOAD' && j.loadServiceType === 'TMS'); // get the default tms service
            let account = accounts.find(j => j.id === accountEntityId);
            if (isObjectNotEmpty(lineItemType) && isObjectNotEmpty(tmsService) && isObjectNotEmpty(account)) {
                newInvoiceLineItem = {
                    description: lineItemType.description,
                    itemType: 'TMS_SERVICE_FEE',
                    isIrisFee: true,
                    fromEntityType: accountEntityType,
                    fromEntityId: accountEntityId,
                    toEntityType: "STAFF",
                    status: "PENDING",
                    approvalStatus: "APPROVED",
                    isOpen: true,
                    isDeleted: false,
                };

                if (tmsService.billingFrequency === 'PER_LOAD') {
                    newInvoiceLineItem.quantity = Number(1);
                    newInvoiceLineItem.quantityUnit = 'PER_LOAD';
                    newInvoiceLineItem.baseAmount = Number(tmsService.priceAmount);
                    newInvoiceLineItem.baseAmountUnit = tmsService.priceAmountUnit;
                    newInvoiceLineItem.totalAmount = Number(tmsService.priceAmount);
                    newInvoiceLineItem.totalAmountUnit = tmsService.priceAmountUnit;
                } else if (tmsService.billingFrequency === 'PER_MILE') {
                    let baseAmount = Number(tmsService.priceAmount);
                    let quantity = Number(practicalRouteMiles);
                    let totalAmount = baseAmount * quantity;

                    newInvoiceLineItem.baseAmount = Number(baseAmount);
                    newInvoiceLineItem.baseAmountUnit = tmsService.priceAmountUnit;
                    newInvoiceLineItem.quantity = quantity;
                    newInvoiceLineItem.quantityUnit = 'PER_MILE';
                    newInvoiceLineItem.totalAmount = totalAmount;
                    newInvoiceLineItem.totalAmountUnit = tmsService.priceAmountUnit;
                } else {
                    newInvoiceLineItem.baseAmount = Number(0);
                    newInvoiceLineItem.baseAmountUnit = tmsService.priceAmountUnit;
                    newInvoiceLineItem.quantity = Number(1);
                    newInvoiceLineItem.quantityUnit = 'PER_LOAD';
                    newInvoiceLineItem.totalAmount = Number(0);
                    newInvoiceLineItem.totalAmountUnit = tmsService.priceAmountUnit;
                }

                // check to see if pricing for service should be over-riden
                let accountTMSService = null;
                if (isListNotEmpty(account.accountServices)) {
                    accountTMSService = account.accountServices.find(j => j.serviceName === tmsService.name);
                    if (isObjectNotEmpty(accountTMSService)) {
                        if (accountTMSService.isPriceOverride === true) {
                            if (accountTMSService.billingFrequency === 'PER_LOAD') {
                                newInvoiceLineItem.quantity = Number(1);
                                newInvoiceLineItem.quantityUnit = 'PER_LOAD';
                                newInvoiceLineItem.baseAmount = Number(accountTMSService.priceAmount);
                                newInvoiceLineItem.baseAmountUnit = accountTMSService.priceAmountUnit;
                                newInvoiceLineItem.totalAmount = Number(accountTMSService.priceAmount);
                                newInvoiceLineItem.totalAmountUnit = accountTMSService.priceAmountUnit;
                            } else if (accountTMSService.billingFrequency === 'PER_MILE') {
                                let baseAmount = Number(accountTMSService.priceAmount);
                                let quantity = Number(practicalRouteMiles);
                                let totalAmount = baseAmount * quantity;

                                newInvoiceLineItem.baseAmount = Number(baseAmount);
                                newInvoiceLineItem.baseAmountUnit = accountTMSService.priceAmountUnit;
                                newInvoiceLineItem.quantity = quantity;
                                newInvoiceLineItem.quantityUnit = 'PER_MILE';
                                newInvoiceLineItem.totalAmount = totalAmount;
                                newInvoiceLineItem.totalAmountUnit = accountTMSService.priceAmountUnit;
                            } else {
                                newInvoiceLineItem.baseAmount = Number(0);
                                newInvoiceLineItem.baseAmountUnit = accountTMSService.priceAmountUnit;
                                newInvoiceLineItem.quantity = Number(1);
                                newInvoiceLineItem.quantityUnit = 'PER_LOAD';
                                newInvoiceLineItem.totalAmount = Number(0);
                                newInvoiceLineItem.totalAmountUnit = accountTMSService.priceAmountUnit;
                            }
                        }
                    }
                }
            }
        }

        return newInvoiceLineItem;
    };

    const generateStopFeesInvoiceLineItems = () => {
        // create invoiceLineItems
        let newInvoiceLineItems = [];
        if (isListNotEmpty(lineItemTypes)) {
            if (isListNotEmpty(pickUpStops)) {
                pickUpStops.forEach((stop, stopIndex, arr) => {
                    if (stop.hasDriverAssist === true) {
                        let lineItemType = lineItemTypes.find(j => j.name === 'DRIVER_ASSIST');
                        if (isObjectNotEmpty(lineItemType)) {
                            let baseAmount = 0;
                            if (isNumberGreaterThanZero(stop.driverAssist)) {
                                baseAmount = parseFloat(stop.driverAssist);
                            }
                            let quantity = 1;
                            let totalAmount = baseAmount * quantity;
                            let newInvoiceLineItem = {
                                description: lineItemType.description + ' for ' + stop.stopLocation.name,
                                stopId: stop.id,
                                itemType: 'DRIVER_ASSIST',
                                isIrisFee: false,
                                baseAmount: baseAmount.toFixed(2),
                                baseAmountUnit: 'USD',
                                quantity: quantity,
                                quantityUnit: 'PER_STOP',
                                totalAmount: totalAmount.toFixed(2),
                                totalAmountUnit: 'USD',
                                status: 'PENDING',
                                approvalStatus: 'PENDING',
                                isOpen: true,
                                isDeleted: false,
                            };

                            if (entityType === 'STAFF') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'STAFF' });
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER' });
                            } else if (entityType === 'CARRIER') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                            } else if (entityType === 'SHIPPER') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                            }
                        }
                    }

                    if (stop.hasLumperFee === true) {
                        let lineItemType = lineItemTypes.find(j => j.name === 'LUMPER_FEE');
                        if (isObjectNotEmpty(lineItemType)) {
                            let baseAmount = 0;
                            if (isNumberGreaterThanZero(stop.lumperFee)) {
                                baseAmount = parseFloat(stop.lumperFee);
                            }
                            let quantity = 1;
                            let totalAmount = baseAmount * quantity;
                            let newInvoiceLineItem = {
                                description: lineItemType.description + ' for ' + stop.stopLocation.name,
                                stopId: stop.id,
                                itemType: 'LUMPER_FEE',
                                isIrisFee: false,
                                baseAmount: baseAmount.toFixed(2),
                                baseAmountUnit: 'USD',
                                quantity: quantity,
                                quantityUnit: 'PER_STOP',
                                totalAmount: totalAmount.toFixed(2),
                                totalAmountUnit: 'USD',
                                status: 'PENDING',
                                approvalStatus: 'PENDING',
                                isOpen: true,
                                isDeleted: false,
                            }

                            if (entityType === 'STAFF') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'STAFF' });
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER' });
                            } else if (entityType === 'CARRIER') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                            } else if (entityType === 'SHIPPER') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                            }
                        }
                    }
                });
            }

            if (isListNotEmpty(dropOffStops)) {
                dropOffStops.forEach((stop, stopIndex, arr) => {
                    if (stop.hasDriverAssist === true) {
                        let lineItemType = lineItemTypes.find(j => j.name === 'DRIVER_ASSIST');
                        if (isObjectNotEmpty(lineItemType)) {
                            let baseAmount = 0;
                            if (isNumberGreaterThanZero(stop.driverAssist)) {
                                baseAmount = parseFloat(stop.driverAssist);
                            }
                            let quantity = 1;
                            let totalAmount = baseAmount * quantity;
                            let newInvoiceLineItem = {
                                description: lineItemType.description + ' for ' + stop.stopLocation.name,
                                stopId: stop.id,
                                itemType: 'DRIVER_ASSIST',
                                isIrisFee: false,
                                baseAmount: baseAmount.toFixed(2),
                                baseAmountUnit: 'USD',
                                quantity: quantity,
                                quantityUnit: 'PER_STOP',
                                totalAmount: totalAmount.toFixed(2),
                                totalAmountUnit: 'USD',
                                status: 'PENDING',
                                approvalStatus: 'PENDING',
                                isOpen: true,
                                isDeleted: false,
                            };

                            if (entityType === 'STAFF') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'STAFF' });
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER' });
                            } else if (entityType === 'CARRIER') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                            } else if (entityType === 'SHIPPER') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                            }
                        }
                    }

                    if (stop.hasLumperFee === true) {
                        let lineItemType = lineItemTypes.find(j => j.name === 'LUMPER_FEE');
                        if (isObjectNotEmpty(lineItemType)) {
                            let baseAmount = 0;
                            if (isNumberGreaterThanZero(stop.lumperFee)) {
                                baseAmount = parseFloat(stop.lumperFee);
                            }
                            let quantity = 1;
                            let totalAmount = baseAmount * quantity;
                            let newInvoiceLineItem = {
                                description: lineItemType.description + ' for ' + stop.stopLocation.name,
                                stopId: stop.id,
                                itemType: 'LUMPER_FEE',
                                isIrisFee: false,
                                baseAmount: baseAmount.toFixed(2),
                                baseAmountUnit: 'USD',
                                quantity: quantity,
                                quantityUnit: 'PER_STOP',
                                totalAmount: totalAmount.toFixed(2),
                                totalAmountUnit: 'USD',
                                status: 'PENDING',
                                approvalStatus: 'PENDING',
                                isOpen: true,
                                isDeleted: false,
                            }

                            if (entityType === 'STAFF') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'STAFF' });
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER' });
                            } else if (entityType === 'CARRIER') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                            } else if (entityType === 'SHIPPER') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                            }
                        }
                    }
                });
            }

            setInvoiceLineItems([...newInvoiceLineItems]);
        }
    };

    const onSubmit = () => {
        let loadPayload = {
            unitOfMeasure: "ENGLISH",
            equipmentNeeds: {}
        };
        let stopsPayload = [];
        let invoiceLineItemsPayload = [];

        if (isNumberNotEmpty(practicalRouteMiles)) {
            loadPayload.practicalDistance = practicalRouteMiles;
            loadPayload.practicalDistanceUnit = "MI";
        }

        if (isObjectNotEmpty(shipperData) && isStringNotEmpty(shipperData.shipperId)) {
            loadPayload.shipperId = shipperData.shipperId;
        }

        if (entityType === 'BROKER') {
            loadPayload.brokerId = entityId;
        } else if (entityType === 'STAFF' && isStringNotEmpty(servicesData.serviceType) && servicesData.serviceType === 'BROKERAGE') {
            loadPayload.brokerId = InvoiceUtils.irisAccountId;
        }

        loadPayload.createdByEntityType = entityType;

        if (entityType === 'CARRIER' && isObjectNotEmpty(carrier)) {
            loadPayload.assignedCarrierId = carrier.id;
        } else if (isObjectNotEmpty(servicesData) && isStringNotEmpty(servicesData.assignedCarrierId)) {
            loadPayload.assignedCarrierId = servicesData.assignedCarrierId;
        }

        // assign the staff member as the default account rep for the load if being created by staff
        if (entityType === 'STAFF') {
            loadPayload.assignedAccountRepUserIds = [entityId];
        }

        if (duplicateCount > 1 && entityType === 'CARRIER') {
            loadPayload.childLoadInitCount = duplicateCount - 1;
        }

        let stopsArray = [];
        if (isListNotEmpty(pickUpStops) && isListNotEmpty(dropOffStops)) {
            pickUpStops.forEach((stop) => {
                stopsArray.push({ ...stop });
            });

            dropOffStops.forEach((stop) => {
                stopsArray.push({ ...stop });
            });
        }

        if (isListNotEmpty(stopsArray) && stopsArray.length > 1) {
            methods.clearErrors(['pickUpStops', 'dropOffStops']);
            let origin = stopsArray.find(stop => stop.sequence === 0 && stop.stopType === "PICK_UP");
            if (isObjectNotEmpty(origin)) {
                if (isMomentDate(origin.requestedDateTime)) {
                    let momentDateTime = origin.requestedDateTime;
                    let timeZone = origin.stopLocation.timeZone;
                    loadPayload.pickUpDateTime = momentDate.asTimeZoneUtcISOString(momentDateTime, timeZone);
                }
            } else {
                methods.setError('pickUpStops', { type: 'required', message: 'Cannot create a load without an origin stop.' });
                return;
            }
        } else {
            methods.setError('dropOffStops', { type: 'required', message: 'Cannot create a load without at least 2 stops.' });
            return;
        }

        if (isObjectNotEmpty(equipmentData)) {
            if (isStringNotEmpty(equipmentData.trailerTypeId)) {
                loadPayload.equipmentNeeds.trailerTypeId = equipmentData.trailerTypeId;
            }

            if (isNumberNotEmpty(equipmentData.trailerLength)) {
                loadPayload.equipmentNeeds.trailerLength = Number(equipmentData.trailerLength);
                loadPayload.equipmentNeeds.trailerLengthUnit = "FT";
            }

            if (isNumberNotEmpty(equipmentData.trailerWeightCapacity)) {
                loadPayload.equipmentNeeds.trailerWeightCapacity = Number(equipmentData.trailerWeightCapacity);
                loadPayload.equipmentNeeds.trailerWeightCapacityUnit = "LB";
            }

            if (isNumberNotEmpty(equipmentData.preCoolReefer)) {
                loadPayload.equipmentNeeds.preCoolReefer = Number(equipmentData.preCoolReefer);
                loadPayload.equipmentNeeds.preCoolReeferUnit = "F";
            }

            if (isNumberNotEmpty(equipmentData.minRunTemp)) {
                loadPayload.equipmentNeeds.minRunTemp = Number(equipmentData.minRunTemp);
                loadPayload.equipmentNeeds.minRunTempUnit = "F";
            }

            if (isNumberNotEmpty(equipmentData.maxRunTemp)) {
                loadPayload.equipmentNeeds.maxRunTemp = Number(equipmentData.maxRunTemp);
                loadPayload.equipmentNeeds.maxRunTempUnit = "F";
            }

            if (isListNotEmpty(equipmentData.driverRequirements)) {
                loadPayload.driverRequirements = equipmentData.driverRequirements;
            }

            if (isListNotEmpty(equipmentData.equipmentSpecifics)) {
                loadPayload.equipmentSpecifics = equipmentData.equipmentSpecifics;
            }
        }

        if (isObjectNotEmpty(servicesData)) {
            if (isStringNotEmpty(servicesData.customerOrderNumber)) {
                loadPayload.customerOrderNumber = servicesData.customerOrderNumber;
            }

            if (isStringNotEmpty(servicesData.parentName)) {
                loadPayload.parentName = servicesData.parentName;
            }

            if (isStringNotEmpty(servicesData.serviceType)) {
                loadPayload.serviceType = servicesData.serviceType;

                if (servicesData.serviceType === 'TMS') {
                    let tmsInvoiceLineItem = generateTMSInvoiceLineItem(entityId, entityType);
                    if (isObjectNotEmpty(tmsInvoiceLineItem)) {
                        invoiceLineItemsPayload.push(tmsInvoiceLineItem);
                    }
                }
            } else {
                loadPayload.serviceType = 'TMS';

                let tmsInvoiceLineItem = generateTMSInvoiceLineItem(entityId, entityType);
                if (isObjectNotEmpty(tmsInvoiceLineItem)) {
                    invoiceLineItemsPayload.push(tmsInvoiceLineItem);
                }
            }

            if (isNumberNotEmpty(servicesData.shipperRatePerMile)) {
                loadPayload.shipperRatePerMile = servicesData.shipperRatePerMile;
                loadPayload.shipperRatePerMileUnit = 'USD';
            }
            if (isNumberNotEmpty(servicesData.shipperAmount)) {
                loadPayload.shipperAmount = servicesData.shipperAmount;
                loadPayload.shipperAmountUnit = 'USD';
                loadPayload.shipperBalance = servicesData.shipperAmount;
                loadPayload.shipperBalanceUnit = 'USD';
            }
            if (isNumberNotEmpty(servicesData.carrierRatePerMile)) {
                loadPayload.carrierRatePerMile = servicesData.carrierRatePerMile;
                loadPayload.carrierRatePerMileUnit = 'USD';
            }
            if (isNumberNotEmpty(servicesData.carrierAmount)) {
                loadPayload.carrierAmount = servicesData.carrierAmount;
                loadPayload.carrierAmountUnit = 'USD';
                loadPayload.carrierBalance = servicesData.carrierAmount;
                loadPayload.carrierBalanceUnit = 'USD';
            }
        }

        if (isListNotEmpty(stopsArray)) {
            stopsArray.forEach((stop, index) => {
                if (isObjectNotEmpty(stop.stopLocation)) {
                    let newStop = {
                        unitOfMeasure: "ENGLISH",
                        latitude: stop.stopLocation.latitude,
                        longitude: stop.stopLocation.longitude,
                        timeZone: stop.stopLocation.timeZone,
                        stopType: stop.stopType,
                        sequence: index,
                        stopLocationId: stop.stopLocationId,
                        hasDriverAssist: stop.hasDriverAssist,
                        hasLumperFee: stop.hasLumperFee,
                        bolNumbers: stop.bolNumbers,
                        apptType: stop.apptType,
                        loadingType: stop.loadingType
                    };

                    let timeZone = stop.stopLocation.timeZone;

                    if (stop.hasLumperFee === true && isNumberGreaterThanZero(stop.lumperFee)) {
                        newStop.lumperFee = Number(stop.lumperFee);
                        newStop.lumperFeeUnit = 'USD';
                    } else {
                        newStop.lumperFee = 0;
                        newStop.lumperFeeUnit = 'USD';
                    }

                    if (stop.hasDriverAssist === true && isNumberGreaterThanZero(stop.driverAssist)) {
                        newStop.driverAssist = Number(stop.driverAssist);
                        newStop.driverAssistUnit = 'USD';
                    } else {
                        newStop.driverAssist = 0;
                        newStop.driverAssistUnit = 'USD';
                    }

                    if (isStringNotEmpty(stop.purchaseOrderNumber)) {
                        newStop.purchaseOrderNumber = stop.purchaseOrderNumber;
                    }

                    if (isStringNotEmpty(stop.pickUpNumber)) {
                        newStop.pickUpNumber = stop.pickUpNumber;
                    }

                    if (isStringNotEmpty(stop.dropOffNumber)) {
                        newStop.dropOffNumber = stop.dropOffNumber;
                    }

                    if (isStringNotEmpty(stop.specialInstructions)) {
                        newStop.specialInstructions = stop.specialInstructions;
                    }

                    if (isStringNotEmpty(stop.apptInstructions)) {
                        newStop.apptInstructions = stop.apptInstructions;
                    }

                    if (isStringNotEmpty(stop.apptNumber)) {
                        newStop.apptNumber = stop.apptNumber;
                    }

                    if (isMomentDate(stop.apptWindowStartDateTime)) {
                        newStop.apptWindowStartDateTime = momentDate.asTimeZoneUtcISOString(stop.apptWindowStartDateTime, timeZone);
                    }

                    if (isMomentDate(stop.apptWindowEndDateTime)) {
                        newStop.apptWindowEndDateTime = momentDate.asTimeZoneUtcISOString(stop.apptWindowEndDateTime, timeZone);
                    }

                    if (isMomentDate(stop.requestedDateTime)) {
                        newStop.requestedDateTime = momentDate.asTimeZoneUtcISOString(stop.requestedDateTime, timeZone);
                    }

                    if (isMomentDate(stop.apptCallAheadDateTime)) {
                        newStop.apptCallAheadDateTime = momentDate.asTimeZoneUtcISOString(stop.apptCallAheadDateTime, timeZone);
                    }

                    if (isMomentDate(stop.apptDateTime)) {
                        newStop.apptDateTime = momentDate.asTimeZoneUtcISOString(stop.apptDateTime, timeZone);
                    }

                    if (isObjectNotEmpty(stop.apptPointOfContact)) {
                        newStop.apptPointOfContact = stop.apptPointOfContact;
                    }

                    if (isListNotEmpty(stop.commodities)) {
                        let newCommodities = [];
                        stop.commodities.forEach((commodity) => {
                            let newCommodity = {
                                commodityId: commodity.commodityId,
                                name: commodity.name,
                                unitPackaging: commodity.unitPackaging,
                                unitPackagingInitCount: Number(commodity.unitPackagingInitCount)
                            };

                            if (isStringNotEmpty(commodity.bulkPackaging)) {
                                newCommodity.bulkPackaging = commodity.bulkPackaging;
                                newCommodity.bulkPackagingInitCount = Number(commodity.bulkPackagingInitCount);
                            }

                            newCommodities.push(newCommodity);
                        });

                        if (isListNotEmpty(newCommodities)) {
                            newStop.commodities = newCommodities;
                        }
                    }

                    stopsPayload.push({ ...newStop });
                }
            });
        }

        if (isListNotEmpty(invoiceLineItems)) {
            invoiceLineItems.forEach((invoiceLineItem) => {
                let newInvoiceLineItem = {
                    description: invoiceLineItem.description,
                    itemType: invoiceLineItem.itemType,
                    isIrisFee: invoiceLineItem.isIrisFee
                };

                if (isStringNotEmpty(invoiceLineItem.stopId)) {
                    newInvoiceLineItem.stopId = invoiceLineItem.stopId;
                }

                if (isStringNotEmpty(invoiceLineItem.fromEntityType)) {
                    newInvoiceLineItem.fromEntityType = invoiceLineItem.fromEntityType;
                    if (invoiceLineItem.fromEntityType === 'SHIPPER' && isStringNotEmpty(loadPayload.shipperId)) {
                        newInvoiceLineItem.fromEntityId = loadPayload.shipperId;
                    } else if (invoiceLineItem.fromEntityType === 'CARRIER' && isStringNotEmpty(loadPayload.assignedCarrierId)) {
                        newInvoiceLineItem.fromEntityId = loadPayload.assignedCarrierId;
                    }
                }

                if (isStringNotEmpty(invoiceLineItem.toEntityType)) {
                    newInvoiceLineItem.toEntityType = invoiceLineItem.toEntityType;
                    if (invoiceLineItem.toEntityType === 'SHIPPER' && isStringNotEmpty(loadPayload.shipperId)) {
                        newInvoiceLineItem.toEntityId = loadPayload.shipperId;
                    } else if (invoiceLineItem.toEntityType === 'CARRIER' && isStringNotEmpty(loadPayload.assignedCarrierId)) {
                        newInvoiceLineItem.toEntityId = loadPayload.assignedCarrierId;
                    }
                }

                if (isNumberNotEmpty(invoiceLineItem.baseAmount)) {
                    newInvoiceLineItem.baseAmount = Number(invoiceLineItem.baseAmount);
                }

                if (isStringNotEmpty(invoiceLineItem.baseAmountUnit)) {
                    newInvoiceLineItem.baseAmountUnit = invoiceLineItem.baseAmountUnit;
                }

                if (isNumberNotEmpty(invoiceLineItem.totalAmount)) {
                    newInvoiceLineItem.totalAmount = Number(invoiceLineItem.totalAmount);
                }

                if (isStringNotEmpty(invoiceLineItem.totalAmountUnit)) {
                    newInvoiceLineItem.totalAmountUnit = invoiceLineItem.totalAmountUnit;
                }

                if (isNumberNotEmpty(invoiceLineItem.quantity)) {
                    newInvoiceLineItem.quantity = Number(invoiceLineItem.quantity);
                }

                if (isStringNotEmpty(invoiceLineItem.quantityUnit)) {
                    newInvoiceLineItem.quantityUnit = invoiceLineItem.quantityUnit;
                }

                if (isStringNotEmpty(invoiceLineItem.status)) {
                    newInvoiceLineItem.status = invoiceLineItem.status;
                }

                if (isStringNotEmpty(invoiceLineItem.approvalStatus)) {
                    newInvoiceLineItem.approvalStatus = invoiceLineItem.approvalStatus;
                }

                if (isNotNullOrUndefined(invoiceLineItem.isOpen)) {
                    newInvoiceLineItem.isOpen = invoiceLineItem.isOpen;
                }

                invoiceLineItemsPayload.push(newInvoiceLineItem);
            });
        }

        let newLoadPayload = removeEmpty(loadPayload);
        let newStopsPayload = removeEmpty(stopsPayload);

        if ((duplicateCount > 1 && entityType === 'CARRIER') || duplicateCount === 1) {
            dispatch(actionCreators.addLoad(newLoadPayload, newStopsPayload, invoiceLineItemsPayload, null));
        } else if (duplicateCount > 1) {
            dispatch(actionCreators.addDuplicateLoads(newLoadPayload, newStopsPayload, invoiceLineItemsPayload, duplicateCount));
        }
    };

    //#endregion
    //#region useEffects and useMemos

    useMemo(() => {
        // clear any previous errors if this is a new load creation
        dispatch(actionCreators.addLoadLoadingClear());
        dispatch(actionCreators.addLoadErrorClear());
        dispatch(actionCreators.getAccounts());
        dispatch(actionCreators.getLineItemTypes());
        dispatch(actionCreators.getTrailerTypes());
        dispatch(actionCreators.getServices());
    }, []);

    useMemo(() => {
        if (entityType === 'STAFF' && isStringNotEmpty(shipperId)) {
            dispatch(actionCreators.getLinkedAccounts(shipperId));
        } else {
            dispatch(actionCreators.getLinkedAccounts(entityId));
        }
    }, [shipperId, entityType, entityId]);

    useMemo(() => {
        if (entityType === 'CARRIER' && isObjectNotEmpty(yourAccount)) {
            setCarrier(yourAccount);
        } else {
            setCarrier(null);
        }
    }, [yourAccount, entityType]);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null && isStringNotEmpty(addRecordId)) {
            done(addRecordId);
        }
    }, [isLoading, error, addRecordId]);

    useMemo(() => {
        // console.log(`The Shipper ID is ${shipperId}`);
        // console.log(`The Load Lane ID is ${loadLaneId}`);
        if (isListNotEmpty(trailerTypes) && isListNotEmpty(lineItemTypes) && isListNotEmpty(accounts) && isStringNotEmpty(loadLaneId) && isStringNotEmpty(shipperId)) {
            if (isObjectEmpty(shipperData)) {
                let selectedShipperAccount = accounts.find(s => s.id === shipperId);
                if (isObjectNotEmpty(selectedShipperAccount)) {
                    const shipperDataObj = {
                        shipperId: shipperId,
                        shipperName: selectedShipperAccount.name,
                        shipper: selectedShipperAccount
                    };
                    setShipperData(shipperDataObj);
                }
            }

            if (isStringNotEmpty(loadLaneId)) {
                if (isListEmpty(pickUpStops) && isListEmpty(dropOffStops)) {
                    setIsStepsLoading(true);
                    getLoadLane(loadLaneId).then((loadLane) => {
                        // console.log(loadLane);

                        setLoadLane(loadLane);

                        let servicesDataObj = {};
                        if (isObjectEmpty(servicesData) && isStringNotEmpty(loadLane.customerLaneId)) {
                            if (duplicateCount === 1) {
                                servicesDataObj.parentName = loadLane.customerLaneId;
                            } else if (duplicateCount > 1) {
                                servicesDataObj.parentName = loadLane.customerLaneId;
                            }

                            setServicesData(servicesDataObj);
                        }

                        let equipmentDataObj = {};
                        if (isObjectNotEmpty(loadLane.equipmentNeeds)) {
                            equipmentDataObj.trailerType = loadLane.equipmentNeeds.trailerType;
                            equipmentDataObj.trailerTypeId = loadLane.equipmentNeeds.trailerTypeId;
                            equipmentDataObj.trailerLength = loadLane.equipmentNeeds.trailerLength;
                            equipmentDataObj.trailerLengthUnit = loadLane.equipmentNeeds.trailerLengthUnit;
                            equipmentDataObj.trailerWeightCapacity = loadLane.equipmentNeeds.trailerWeightCapacity;
                            equipmentDataObj.trailerWeightCapacityUnit = loadLane.equipmentNeeds.trailerWeightCapacityUnit;
                            equipmentDataObj.preCoolReefer = loadLane.equipmentNeeds.preCoolReefer;
                            equipmentDataObj.preCoolReeferUnit = loadLane.equipmentNeeds.preCoolReeferUnit;
                            equipmentDataObj.minRunTemp = loadLane.equipmentNeeds.minRunTemp;
                            equipmentDataObj.minRunTempUnit = loadLane.equipmentNeeds.minRunTempUnit;
                            equipmentDataObj.maxRunTemp = loadLane.equipmentNeeds.maxRunTemp;
                            equipmentDataObj.maxRunTempUnit = loadLane.equipmentNeeds.maxRunTempUnit;
                        }

                        if (isListNotEmpty(loadLane.equipmentSpecifics)) {
                            equipmentDataObj.equipmentSpecifics = loadLane.equipmentSpecifics;
                        }
                        if (isListNotEmpty(loadLane.driverRequirements)) {
                            equipmentDataObj.driverRequirements = loadLane.driverRequirements;
                        }

                        setEquipmentData({ ...equipmentDataObj });

                        let pickUpStopsArray = [];
                        let dropOffStopsArray = [];
                        if (isListNotEmpty(loadLane.locations)) {
                            let pickUps = loadLane.locations.filter(s => s.stopType === 'PICK_UP');
                            let dropOffs = loadLane.locations.filter(s => s.stopType === 'DROP_OFF');
                            if (isListNotEmpty(pickUps)) {
                                let orderedPickUpLocations = sortBy(pickUps, [function (o) { return o.sequence; }]);
                                // console.log(orderedPickUpLocations);
                                orderedPickUpLocations.forEach((laneLocation, laneLocationIndex, arr) => {
                                    let newStop = {
                                        sequence: laneLocationIndex,
                                        stopType: laneLocation.stopType,
                                        stopTypeSequence: laneLocationIndex,
                                        id: laneLocationIndex,
                                        stopLocation: laneLocation.location,
                                        stopLocationId: laneLocation.locationId,
                                        hasDriverAssist: false,
                                        apptType: 'NEED_APPOINTMENT'
                                    };

                                    if (isObjectNotEmpty(laneLocation.location)) {
                                        if (isStringNotEmpty(laneLocation.location.specialInstructions)) {
                                            newStop.specialInstructions = laneLocation.location.specialInstructions;
                                        }

                                        if (isNumberGreaterThanZero(laneLocation.location.lumperFee)) {
                                            newStop.hasLumperFee = true;
                                            newStop.lumperFee = laneLocation.location.lumperFee;
                                        } else {
                                            newStop.hasLumperFee = false;
                                        }

                                        if (isObjectNotEmpty(laneLocation.location.pointOfContact)) {
                                            newStop.apptPointOfContact = laneLocation.location.pointOfContact;
                                        }

                                        if (isStringNotEmpty(laneLocation.location.timeZone)) {
                                            newStop.timeZone = laneLocation.location.timeZone;
                                        }
                                    }

                                    if (isStringNotEmpty(laneLocation.loadingType)) {
                                        newStop.loadingType = laneLocation.loadingType;
                                    }

                                    if (isListNotEmpty(laneLocation.commodities)) {
                                        newStop.commodities = laneLocation.commodities;
                                    }

                                    pickUpStopsArray.push(newStop);
                                });

                                setPickUpStops(pickUpStopsArray);
                            }
                            if (isListNotEmpty(dropOffs)) {
                                let orderedDropOffStops = sortBy(dropOffs, [function (o) { return o.sequence; }]);
                                // console.log(orderedDropOffStops);
                                orderedDropOffStops.forEach((laneLocation, laneLocationIndex, arr) => {
                                    let newStop = {
                                        sequence: laneLocationIndex + pickUpStopsArray.length,
                                        stopType: laneLocation.stopType,
                                        stopTypeSequence: laneLocationIndex,
                                        id: laneLocationIndex + pickUpStopsArray.length,
                                        stopLocation: laneLocation.location,
                                        stopLocationId: laneLocation.locationId,
                                        hasDriverAssist: false,
                                        apptType: 'NEED_APPOINTMENT'
                                    };

                                    if (isObjectNotEmpty(laneLocation.location)) {
                                        if (isStringNotEmpty(laneLocation.location.specialInstructions)) {
                                            newStop.specialInstructions = laneLocation.location.specialInstructions;
                                        }

                                        if (isNumberGreaterThanZero(laneLocation.location.lumperFee)) {
                                            newStop.hasLumperFee = true;
                                            newStop.lumperFee = laneLocation.location.lumperFee;
                                        } else {
                                            newStop.hasLumperFee = false;
                                        }

                                        if (isObjectNotEmpty(laneLocation.location.pointOfContact)) {
                                            newStop.apptPointOfContact = laneLocation.location.pointOfContact;
                                        }

                                        if (isStringNotEmpty(laneLocation.location.timeZone)) {
                                            newStop.timeZone = laneLocation.location.timeZone;
                                        }
                                    }

                                    if (isStringNotEmpty(laneLocation.loadingType)) {
                                        newStop.loadingType = laneLocation.loadingType;
                                    }

                                    if (isListNotEmpty(laneLocation.commodities)) {
                                        newStop.commodities = laneLocation.commodities;
                                    }

                                    dropOffStopsArray.push(newStop);
                                });

                                setDropOffStops(dropOffStopsArray);
                            }
                        }

                        setIsStepsLoading(false);
                    }).catch(err => {
                        logger.logDebugEvent('NewLoadAndRFB.js', err.message, true);
                        setIsStepsLoading(false);
                    });
                }
            }
        }
    }, [loadLaneId, shipperId, lineItemTypes, trailerTypes, accounts]);

    useEffect(() => {
        if (isListNotEmpty(lineItemTypes) && isListNotEmpty(pickUpStops) && isListNotEmpty(dropOffStops) && isStringNotEmpty(entityType)) {
            generateStopFeesInvoiceLineItems();
        }
    }, [lineItemTypes, pickUpStops, dropOffStops, entityType]);

    useEffect(() => {
        // console.log(invoiceLineItemList);
        let shipperContractAmount = 0.0;
        let carrierContractAmount = 0.0;
        let summary = Pricing.newLoadPricingSummary(
            0,
            0,
            shipperContractAmount,
            carrierContractAmount,
            practicalRouteMiles,
            invoiceLineItems
        );

        // console.log(summary);

        setServicesData({
            ...servicesData,
            totalFeePerMile: summary.feePerMileAmount,
            shipperRatePerMile: summary.shipperRatePerMileAmount,
            carrierRatePerMile: summary.carrierRatePerMileAmount,
            commissionPercentage: summary.IRISPercentage,
            totalAddOnCharge: summary.addOnChargeAmount,
            totalAddOnCost: summary.addOnCostAmount,
            shipperAmount: summary.shipperAmount,
            carrierAmount: summary.carrierAmount,
            IRISProfit: summary.IRISAmount
        });
    }, [invoiceLineItems, practicalRouteMiles]);

    //#endregion
    //#region table methods

    const getRowClassName = (record) => {
        if (isListNotEmpty(selectedAccountIds) && selectedAccountIds.includes(record.id)) {
            return classes.readyToDispatchRow;
        } else {
            return classes.dataTableRow;
        }
    };

    const rowSelectionCarriers = {
        hideSelectAll: false,
        columnTitle: 'Select',
        columnWidth: 50,
        type: 'checkbox',
        selectedRowKeys: isListNotEmpty(selectedAccountIds) ? [...selectedAccountIds] : [],
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            console.log('selectedRowKeys changed: ', selectedRowKeys);
            if (isListNotEmpty(selectedRowKeys)) {
                setSelectedAccountIds(selectedRowKeys);
            } else {
                setSelectedAccountIds([]);
            }
        },
        renderCell: (checked, record, index, originNode) => {
            // console.log(originNode);
            return {
                props: {
                    className: getRowClassName(record)
                },
                children: originNode
            };
        },
        getCheckboxProps: record => ({
            name: record.id,
        }),
    };

    const carrierColumns = [
        {
            title: 'Carrier',
            dataIndex: 'name',
            key: 'name',
            textAlign: 'left',
            render: (text, record) => {
                return {
                    props: {
                        className: [getRowClassName(record), classes.cell]
                    },
                    children: text,
                };
            },
        },
    ];

    //#endregion
    //#region displays

    const getEquipmentData = () => {
        if (isObjectNotEmpty(equipmentData)) {
            let trailerLength = stringFormatter.toFormattedString('Length', equipmentData.trailerLength, equipmentData.trailerLengthUnit);
            let trailerWeightCapacity = stringFormatter.toFormattedString('Weight', equipmentData.trailerWeightCapacity, equipmentData.trailerWeightCapacityUnit);

            return (
                <Row gutter={[12, 12]}>
                    {(isObjectNotEmpty(equipmentData.trailerType) && isStringNotEmpty(equipmentData.trailerType.description)) ? (
                        <Col span={24}>
                            <div style={{ marginBottom: 12 }}><span style={{ fontSize: 14, color: '#000000' }}>Trailer: {equipmentData.trailerType.description} &#8212; {trailerLength}, {trailerWeightCapacity}</span></div>
                            {isNumberNotEmpty(equipmentData.preCoolReefer) ? (
                                <div style={{ paddingLeft: 8 }}><span style={{ fontSize: 14, color: '#A9A9A9' }}>Pre-Cool Temp: {stringFormatter.toFormattedString('Temperature', equipmentData.preCoolReefer, equipmentData.preCoolReeferUnit)}</span></div>
                            ) : null}
                            {(isNumberNotEmpty(equipmentData.minRunTemp) && isNumberNotEmpty(equipmentData.maxRunTemp)) ? (
                                <div style={{ paddingLeft: 8 }}><span style={{ fontSize: 14, color: '#A9A9A9' }}>Run Temp Range: {stringFormatter.toFormattedString('Temperature', equipmentData.minRunTemp, equipmentData.minRunTempUnit)} - {stringFormatter.toFormattedString('Temperature', equipmentData.maxRunTemp, equipmentData.maxRunTempUnit)}</span></div>
                            ) : null}
                        </Col>
                    ) : null}
                    {isListNotEmpty(equipmentData.equipmentSpecifics) ? (
                        <Col span={24}>
                            <div style={{ marginBottom: 12 }}><span style={{ fontSize: 14, color: '#000000' }}>Additional Equipment and Services</span></div>
                            <DataListRow style={{ paddingLeft: 8 }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} data={equipmentData.equipmentSpecifics} dataType="EquipmentSpecific" />
                        </Col>
                    ) : null}
                    {isListNotEmpty(equipmentData.driverRequirements) ? (
                        <Col span={24}>
                            <div style={{ marginBottom: 12 }}><span style={{ fontSize: 14, color: '#000000' }}>Driver Requirements</span></div>
                            <DataListRow style={{ paddingLeft: 8 }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} data={equipmentData.driverRequirements} dataType="DriverRequirement" />
                        </Col>
                    ) : null}
                </Row>
            );
        } else {
            return null;
        }
    };

    const getStopsData = () => {
        if (isListNotEmpty(pickUpStops) && isListNotEmpty(dropOffStops)) {
            return (
                <>
                    {pickUpStops.map((stop, index, arr) => {
                        return (
                            <div key={`pick-up-stop-details-${index}`}>
                                <Row gutter={[12, 12]}>
                                    <Col span={24}>
                                        <div style={{ marginBottom: 12 }}><span style={{ fontSize: 14, color: '#000000' }}>{`Details for Pick-Up${arr.length > 1 ? ' ' + (stop.stopTypeSequence + 1) : ''}`}</span></div>

                                        {isListNotEmpty(stop.bolNumbers) ? <DataListRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} oneLine={true} title="BOL Number(s)" data={stop.bolNumbers} dataType="String" /> : null}
                                        {isStringNotEmpty(stop.pickUpNumber) ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Pick-Up Number" value={stop.pickUpNumber} dataType="String" /> : null}
                                        {isStringNotEmpty(stop.loadingType) ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Loading Type" value={stop.loadingType} dataType="LoadingType" /> : null}
                                        {isBooleanTrue(stop.hasLumperFee) ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Lumper Fee" value={`Yes, ${stringFormatter.toFormattedMoney(stop.lumperFee)}`} dataType="String" /> : null}
                                        {isBooleanTrue(stop.hasDriverAssist) ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Driver Assist" value={`Yes, ${stringFormatter.toFormattedMoney(stop.driverAssist)}`} dataType="String" /> : null}
                                        {isStringNotEmpty(stop.specialInstructions) ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Special Instructions" value={stop.specialInstructions} dataType="String" /> : null}
                                    </Col>
                                    {isListNotEmpty(stop.commodities) ? (
                                        <Col span={24}>
                                            <div style={{ marginBottom: 12 }}><span style={{ fontSize: 14, color: '#000000' }}>{`Commodities for Pick-Up${arr.length > 1 ? ' ' + (stop.stopTypeSequence + 1) : ''}`}</span></div>
                                            <DataCommodityListRow style={{ paddingLeft: 8 }} nameStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} data={stop.commodities} />
                                        </Col>
                                    ) : null}
                                </Row>
                            </div>
                        );
                    })}
                    {dropOffStops.map((stop, index, arr) => {
                        return (
                            <div key={`drop-off-stop-details-${index}`}>
                                <Row gutter={[12, 12]}>
                                    <Col span={24}>
                                        <div style={{ marginBottom: 12 }}><span style={{ fontSize: 14, color: '#000000' }}>{`Details for Drop-Off${arr.length > 1 ? ' ' + (stop.stopTypeSequence + 1) : ''}`}</span></div>

                                        {isListNotEmpty(stop.bolNumbers) ? <DataListRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} oneLine={true} title="BOL Number(s)" data={stop.bolNumbers} dataType="String" /> : null}
                                        {isStringNotEmpty(stop.dropOffNumber) ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Drop-Off Number" value={stop.dropOffNumber} dataType="String" /> : null}
                                        {isStringNotEmpty(stop.purchaseOrderNumber) ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Customer PO Number" value={stop.purchaseOrderNumber} dataType="String" /> : null}
                                        {isStringNotEmpty(stop.loadingType) ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Unloading Type" value={stop.loadingType} dataType="UnloadingType" /> : null}
                                        {isBooleanTrue(stop.hasLumperFee) ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Lumper Fee" value={`Yes, ${stringFormatter.toFormattedMoney(stop.lumperFee)}`} dataType="String" /> : null}
                                        {isBooleanTrue(stop.hasDriverAssist) ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Driver Assist" value={`Yes, ${stringFormatter.toFormattedMoney(stop.driverAssist)}`} dataType="String" /> : null}
                                        {isStringNotEmpty(stop.specialInstructions) ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Special Instructions" value={stop.specialInstructions} dataType="String" /> : null}
                                    </Col>
                                    {isListNotEmpty(stop.commodities) ? (
                                        <Col span={24}>
                                            <div style={{ marginBottom: 12 }}><span style={{ fontSize: 14, color: '#000000' }}>{`Commodities for Drop-Off${arr.length > 1 ? ' ' + (stop.stopTypeSequence + 1) : ''}`}</span></div>
                                            <DataCommodityListRow style={{ paddingLeft: 8 }} nameStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} data={stop.commodities} />
                                        </Col>
                                    ) : null}
                                </Row>
                            </div>
                        );
                    })}
                </>
            );
        } else {
            return null;
        }
    };

    const getPricingData = () => {
        if (isObjectNotEmpty(servicesData)) {
            return (
                <div>
                    {isStringNotEmpty(servicesData.customerOrderNumber) || (isStringNotEmpty(servicesData.serviceType) && CanDo({ staffOnly: true })) ? (
                        <>
                            <div style={{ marginBottom: 12 }}><span style={{ fontSize: 14, color: '#000000' }}>Load Information</span></div>

                            {isStringNotEmpty(servicesData.customerOrderNumber) ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Customer Order Number" value={servicesData.customerOrderNumber} dataType="String" /> : null}
                            {isStringNotEmpty(servicesData.serviceType) && CanDo({ staffOnly: true }) ? (
                                <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Service Type" value={servicesData.serviceType} dataType="LoadServiceType" />
                            ) : null}
                        </>
                    ) : null}

                    {invoiceLineItems.filter(i => i.fromEntityType === 'SHIPPER' && Enums.StopFees.doesNameExist(i.itemType)).length > 0 || isNumberNotEmpty(servicesData.totalAddOnCharge) ? (
                        <>
                            <div style={{ marginBottom: 12, marginTop: 12 }}><span style={{ fontSize: 14, color: '#000000' }}>Stop Fees</span></div>

                            {invoiceLineItems.filter(i => i.fromEntityType === 'SHIPPER' && Enums.StopFees.doesNameExist(i.itemType)).map((lineItem, index) => {
                                return (
                                    <DataRow key={`subtotal-lineitem-${index}`} style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title={lineItem.description} value={lineItem.totalAmount} dataType="Money" />
                                );
                            })}
                            {isNumberNotEmpty(servicesData.totalAddOnCharge) ? (
                                <div style={{ paddingLeft: 8, marginBottom: 12 }}><span style={{ fontSize: 14, color: '#000000' }}><i>Subtotal: {stringFormatter.toFormattedMoney(servicesData.totalAddOnCharge)}</i></span></div>
                            ) : null}
                        </>
                    ) : null}

                    {isNumberGreaterThanZero(servicesData.shipperAmount) ? (
                        <CanSee entityTypes={['STAFF', 'CARRIER', 'BROKER']}>
                            <div style={{ marginBottom: 12 }}><span style={{ fontWeight: 500, fontSize: 14, color: '#000000' }}>Total Charge to the Shipper: {stringFormatter.toFormattedMoney(servicesData.shipperAmount)}</span></div>
                        </CanSee>
                    ) : null}
                    {isNumberGreaterThanZero(servicesData.carrierAmount) ? (
                        <CanSee entityTypes={['STAFF', 'SHIPPER', 'BROKER']}>
                            <div style={{ marginBottom: 12 }}><span style={{ fontWeight: 500, fontSize: 14, color: '#000000' }}>Total Pay to the Carrier: {stringFormatter.toFormattedMoney(servicesData.carrierAmount)}</span></div>
                        </CanSee>
                    ) : null}
                    {isNumberGreaterThanZero(servicesData.IRISProfit) ? (
                        <CanSee staffOnly={true}>
                            <div style={{ marginBottom: 12 }}><span style={{ fontWeight: 500, fontSize: 14, color: '#000000' }}>IRIS Profit: {stringFormatter.toFormattedMoney(servicesData.IRISProfit)}</span></div>
                        </CanSee>
                    ) : null}
                    {isNumberGreaterThanZero(servicesData.commissionPercentage) ? (
                        <CanSee staffOnly={true}>
                            <div style={{ marginBottom: 12 }}><span style={{ fontWeight: 500, fontSize: 14, color: '#000000' }}>Commission: {stringFormatter.toFormattedPercentage(servicesData.commissionPercentage)}</span></div>
                        </CanSee>
                    ) : null}
                </div>
            );
        } else {
            return null;
        }
    };

    const renderStop = (stop) => {
        if (isObjectNotEmpty(stop) && isObjectNotEmpty(loadLane)) {
            const stopAddress = LoadStopUtils.getStopAddressDisplay(stop);
            const stopIcon = LoadStopUtils.getStopMarkerIcon(stop);
            const stopLocation = LoadStopUtils.getStopLocationDisplay(stop, shipperId, entityId, entityType, entityId);

            return (
                <div style={{ marginBottom: 24 }} key={`stop-details-${stop.sequence}`}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ width: 30, marginRight: 12 }}>
                            {stopIcon && (<img src={stopIcon} alt={Enums.StopTypes.getValueByName(stop.stopType)} style={{ width: 30, height: 30 }} />)}
                        </div>
                        <div style={{ flex: 1 }}>
                            <div style={{ fontWeight: 'bold' }}>{stopLocation}</div>
                            <div>{stopAddress}</div>
                            {isStringNotEmpty(stop.loadingType) ? (<DataRow title={stop.stopType === 'PICK_UP' ? 'Loading Type' : 'Unloading Type'} value={stop.loadingType} dataType={stop.stopType === 'PICK_UP' ? 'LoadingType' : 'UnloadingType'} />) : null}
                            {isBooleanTrue(stop.hasLumperFee) ? <DataRow title="Lumper Fee" value={`Yes, ${stringFormatter.toFormattedMoney(stop.lumperFee)}`} dataType="String" /> : null}
                            {isBooleanTrue(stop.hasDriverAssist) ? <DataRow title="Driver Assist" value={`Yes, ${stringFormatter.toFormattedMoney(stop.driverAssist)}`} dataType="String" /> : null}
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };

    //#endregion
    //#region onChanges

    const validateStops = (pickUps, dropOffs, isSubmitting = false) => {
        let hasErrors = false;
        methods.clearErrors();

        // console.log(pickUps);
        // console.log(dropOffs);

        if (isListNotEmpty(pickUps) && isListNotEmpty(dropOffs)) {
            pickUps.forEach((stop, stopIndex, arr) => {
                let errors = {};
                if (isNullOrUndefined(stop.requestedDateTime)) {
                    if (isSubmitting === true) {
                        errors.missingRequestedDateTime = `Pick-Up${arr.length > 1 ? ' ' + (stopIndex + 1) : ''} is missing a Pick-Up Date.`;
                    }
                    hasErrors = true;
                }

                // validate date to make sure stops are in order
                if (stopIndex > 0) {
                    let stop1 = { ...arr[stopIndex - 1] };
                    let stop2 = { ...stop };
                    if (isNotNullOrUndefined(stop1.requestedDateTime) && isNotNullOrUndefined(stop2.requestedDateTime)) {
                        if (stop2.requestedDateTime < stop1.requestedDateTime) {
                            if (stop1.apptType === 'NEED_APPOINTMENT' || stop2.apptType === 'NEED_APPOINTMENT') {
                                // if appointment type is need appointment then just make sure the requestedDateTime is the same day or later, time doesnt matter
                                let stop1DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop1.requestedDateTime, stop1.timeZone);
                                let stop2DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop2.requestedDateTime, stop2.timeZone);
                                if (moment(stop2DateString) < moment(stop1DateString)) {
                                    errors.validate = `The date for Pick-Up ${stopIndex + 1} cannot be before the date for Pick-Up ${stopIndex}.`;
                                    hasErrors = true;
                                }
                            } else {
                                errors.validate = `The date for Pick-Up ${stopIndex + 1} cannot be before the date for Pick-Up ${stopIndex}.`;
                                hasErrors = true;
                            }
                        }
                    }
                }

                if (isObjectNotEmpty(errors)) {
                    methods.setError(`stops.${stop.sequence}.requestedDateTime`, { types: { ...errors } });
                }
            });

            dropOffs.forEach((stop, stopIndex, arr) => {
                let errors = {};
                if (isNullOrUndefined(stop.requestedDateTime)) {
                    if (isSubmitting === true) {
                        errors.missingRequestedDateTime = `Drop-Off${arr.length > 1 ? ' ' + (stopIndex + 1) : ''} is missing a Drop-Off Date.`;
                    }
                    hasErrors = true;
                }

                // validate date to make sure stops are in order
                if (stopIndex > 0) {
                    let stop1 = { ...arr[stopIndex - 1] };
                    let stop2 = { ...stop };
                    if (isNotNullOrUndefined(stop1.requestedDateTime) && isNotNullOrUndefined(stop2.requestedDateTime)) {
                        if (stop2.requestedDateTime < stop1.requestedDateTime) {
                            if (stop1.apptType === 'NEED_APPOINTMENT' || stop2.apptType === 'NEED_APPOINTMENT') {
                                // if appointment type is need appointment then just make sure the requestedDateTime is the same day or later, time doesnt matter
                                let stop1DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop1.requestedDateTime, stop1.timeZone);
                                let stop2DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop2.requestedDateTime, stop2.timeZone);
                                if (moment(stop2DateString) < moment(stop1DateString)) {
                                    errors.validate = `The date for Drop-Off ${stopIndex + 1} cannot be before the date for Drop-Off ${stopIndex}.`;
                                    hasErrors = true;
                                }
                            } else {
                                errors.validate = `The date for Drop-Off ${stopIndex + 1} cannot be before the date for Drop-Off ${stopIndex}.`;
                                hasErrors = true;
                            }
                        }
                    }
                } else if (stopIndex === 0) {
                    let stop1 = { ...pickUps[pickUps.length - 1] };
                    let stop2 = { ...stop };
                    if (isNotNullOrUndefined(stop1.requestedDateTime) && isNotNullOrUndefined(stop2.requestedDateTime)) {
                        if (stop2.requestedDateTime < stop1.requestedDateTime) {
                            if (stop1.apptType === 'NEED_APPOINTMENT' || stop2.apptType === 'NEED_APPOINTMENT') {
                                // if appointment type is need appointment then just make sure the requestedDateTime is the same day or later, time doesnt matter
                                let stop1DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop1.requestedDateTime, stop1.timeZone);
                                let stop2DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop2.requestedDateTime, stop2.timeZone);
                                // console.log(stop1DateString);
                                // console.log(stop2DateString);
                                if (moment(stop2DateString) < moment(stop1DateString)) {
                                    errors.validate = `The date for Drop-Off${dropOffs.length > 1 ? ' ' + (stopIndex + 1) : ''} cannot be before the date for Pick-Up${pickUps.length > 1 ? ' ' + (pickUps.length) : ''}.`;
                                    hasErrors = true;
                                }
                            } else {
                                errors.validate = `The date for Drop-Off${dropOffs.length > 1 ? ' ' + (stopIndex + 1) : ''} cannot be before the date for Pick-Up${pickUps.length > 1 ? ' ' + (pickUps.length) : ''}.`;
                                hasErrors = true;
                            }
                        }
                    }
                }

                if (isObjectNotEmpty(errors)) {
                    methods.setError(`stops.${stop.sequence}.requestedDateTime`, { types: { ...errors } });
                }
            });
        } else {
            if (isListEmpty(pickUps)) {
                if (isSubmitting === true) {
                    methods.setError('pickUpStops', { type: 'required', message: 'There must be at least 1 Pick-Up.' });
                }
                hasErrors = true;
            }
            if (isListEmpty(dropOffs)) {
                if (isSubmitting === true) {
                    methods.setError('dropOffStops', { type: 'required', message: 'There must be at least 1 Drop-Off.' });
                }
                hasErrors = true;
            }
        }

        // console.log(`hasErrors: ${hasErrors}`);

        return !hasErrors;
    };

    const saveStops = (pickUps, dropOffs) => {
        let updatedPickUps = [];
        let updatedDropOffs = [];
        if (isListNotEmpty(pickUps)) {
            pickUps.forEach((stop, stopIndex, arr) => {
                updatedPickUps.push({
                    ...stop,
                    sequence: stopIndex,
                    id: stopIndex,
                    stopTypeSequence: stopIndex
                });
            });
        }

        if (isListNotEmpty(dropOffs)) {
            dropOffs.forEach((stop, stopIndex, arr) => {
                updatedDropOffs.push({
                    ...stop,
                    sequence: stopIndex + pickUps.length,
                    id: stopIndex + pickUps.length,
                    stopTypeSequence: stopIndex
                });
            });
        }

        setPickUpStops(updatedPickUps);
        setDropOffStops(updatedDropOffs);
    };

    const onChangeParentName = (value) => {
        setServicesData({ ...servicesData, parentName: value });
    };

    const onChangeRequestedDateTime = (stop, requestedDateTime) => {
        console.log(stop);
        console.log(requestedDateTime);

        const updatedValues = {
            requestedDateTime: requestedDateTime
        };

        if (isObjectNotEmpty(stop)) {
            if (stop.stopType === 'PICK_UP') {
                let existingList = [...pickUpStops];
                const index = existingList.findIndex(obj => obj.id === stop.id);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        ...updatedValues
                    };

                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    saveStops(updatedRecords, dropOffStops);
                    validateStops(updatedRecords, dropOffStops);
                }
            } else if (stop.stopType === 'DROP_OFF') {
                let existingList = [...dropOffStops];
                const index = existingList.findIndex(obj => obj.id === stop.id);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        ...updatedValues
                    };
                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    saveStops(pickUpStops, updatedRecords);
                    validateStops(pickUpStops, updatedRecords);
                }
            }
        }
    };

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    const formItemLayoutDouble = {
        labelCol1: { span: 24 },
        wrapperCol1: { span: 8 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 16 },
    };

    //#endregion

    return (
        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true && error === null) || isStepsLoading === true}>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Row gutter={[12, 12]}>
                        <Col xs={{ span: 24 }} md={{ span: 16 }} xl={{ span: 16 }}>
                            <Card
                                title="Request For Quotes"
                                style={{ height: '100%', border: '1px solid #d8d8d8' }}
                                headStyle={{ backgroundColor: '#cccccc', borderBottom: '1px solid #d8d8d8', fontSize: 14, textAlign: 'center' }}
                            >
                                <Fieldset legend="Load Count & Name">
                                    <Row gutter={[24, 24]}>
                                        <Col span={12}>
                                            <FormItemDouble {...formItemLayoutDouble} label1="Do you want to create multiple loads?" format="vertical" noColon1
                                                render1={({ onChange, onBlur, value, name, ref }) => (
                                                    <Switch
                                                        checkedChildren="Yes"
                                                        unCheckedChildren="No"
                                                        onBlur={onBlur}
                                                        onChange={(checked, event) => { onChange(checked); setDuplicateCountDisabled(!checked); }}
                                                        checked={value}
                                                        name={name}
                                                        ref={ref}
                                                    />
                                                )}
                                                rules1={{ required: false }}
                                                name1="hasDuplicateCount"
                                                defaultValue1={false}
                                                render2={({ onChange, onBlur, value, name, ref }) => (
                                                    <InputNumber
                                                        precision={0}
                                                        min={1}
                                                        placeholder="# of Loads to create"
                                                        style={{ width: '100%' }}
                                                        onBlur={onBlur}
                                                        onChange={e => { setDuplicateCount(e); onChange(e); }}
                                                        value={value}
                                                        name={name}
                                                        disabled={duplicateCountDisabled === true}
                                                        ref={ref}
                                                    />
                                                )}
                                                rules2={{ required: false }}
                                                name2="duplicateCount"
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <FormItemDouble {...formItemLayoutDouble} label1="Do you want to name or reference these Load(s)?" format="vertical" noColon1
                                                render1={({ onChange, onBlur, value, name, ref }) => (
                                                    <Switch
                                                        checkedChildren="Yes"
                                                        unCheckedChildren="No"
                                                        onBlur={onBlur}
                                                        onChange={(checked, event) => { onChange(checked); setParentNameDisabled(!checked); }}
                                                        checked={value}
                                                        name={name}
                                                        ref={ref}
                                                    />
                                                )}
                                                rules1={{ required: false }}
                                                name1="hasParentName"
                                                defaultValue1={false}
                                                render2={({ onChange, onBlur, value, name, ref }) => (
                                                    <InputNumber
                                                        precision={0}
                                                        min={1}
                                                        placeholder={`"Name of Load(s)${entityType === 'CARRIER' ? ' or Bundle' : ''}`}
                                                        style={{ width: '100%' }}
                                                        onBlur={onBlur}
                                                        onChange={e => { onChangeParentName(e.target.value); onChange(e); }}
                                                        value={value}
                                                        name={name}
                                                        disabled={parentNameDisabled === true}
                                                        ref={ref}
                                                    />
                                                )}
                                                rules2={{ required: false }}
                                                help={
                                                    <div>
                                                        <span style={{ color: '#A9A9A9' }}>This field provides you the option to add an alias or nickname to display for these load(s) instead of the Iris Freight pre-assigned Load References. It should be unique but doesn't have to be.</span>
                                                    </div>
                                                }
                                                name2="parentName"
                                                defaultValue2=""
                                            />
                                        </Col>
                                    </Row>
                                </Fieldset>
                                <Fieldset legend="Stops & Dates">
                                    <Row gutter={[24, 24]}>
                                        {pickUpStops.map((stop) => {
                                            return (
                                                <>
                                                    <Col span={12}>
                                                        {renderStop(stop)}
                                                    </Col>
                                                    <Col span={12}>
                                                        <FormItem {...formItemLayout} label={stop.stopType === "PICK_UP" ? "Requested Pick-Up Date" : "Requested Drop-Off Date"} required format="vertical"
                                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                                <DatePicker
                                                                    allowClear={true}
                                                                    placeholder="Requested Date"
                                                                    format="YYYY-MM-DD"
                                                                    style={{ width: '100%' }}
                                                                    onBlur={onBlur}
                                                                    onChange={(date, dateString) => { onChangeRequestedDateTime(stop, date); onChange(date); }}
                                                                    value={value}
                                                                    name={name}
                                                                    onSelect={(date) => { onChangeRequestedDateTime(stop, date); onChange(date); }}
                                                                    ref={ref}
                                                                />
                                                            )}
                                                            rules={{ required: 'Required Field' }}
                                                            name={`stops.${stop.sequence}.requestedDateTime`}
                                                        />
                                                    </Col>
                                                </>
                                            );
                                        })}
                                        {dropOffStops.map((stop) => {
                                            return (
                                                <>
                                                    <Col span={12}>
                                                        {renderStop(stop)}
                                                    </Col>
                                                    <Col span={12}>
                                                        <FormItem {...formItemLayout} label={stop.stopType === "PICK_UP" ? "Requested Pick-Up Date" : "Requested Drop-Off Date"} required format="vertical"
                                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                                <DatePicker
                                                                    allowClear={true}
                                                                    placeholder="Requested Date"
                                                                    format="YYYY-MM-DD"
                                                                    style={{ width: '100%' }}
                                                                    onBlur={onBlur}
                                                                    onChange={(date, dateString) => { onChangeRequestedDateTime(stop, date); onChange(date); }}
                                                                    value={value}
                                                                    name={name}
                                                                    onSelect={(date) => { onChangeRequestedDateTime(stop, date); onChange(date); }}
                                                                    ref={ref}
                                                                />
                                                            )}
                                                            rules={{ required: 'Required Field' }}
                                                            name={`stops.${stop.sequence}.requestedDateTime`}
                                                        />
                                                    </Col>
                                                </>
                                            );
                                        })}
                                        <Col span={24}>
                                            <DataRow title="Total Stops" valueStyle={{ fontWeight: 'bold' }} value={pickUpStops.length + dropOffStops.length} />
                                            {isNumberNotEmpty(practicalRouteMiles) ? <DataRow title="Trip Miles" valueStyle={{ fontWeight: 'bold' }} value={practicalRouteMiles} units="MI" dataType="Distance" /> : null}
                                        </Col>
                                    </Row>
                                </Fieldset>
                                <Fieldset legend="Carriers">
                                    <DataTable
                                        bordered={false}
                                        title={() => (<span>Which Carriers do you want quotes from?</span>)}
                                        rowSelection={rowSelectionCarriers}
                                        dataSource={linkedAccounts.filter(a => a.entityTypes.includes('CARRIER') || a.entityTypes.includes('BROKER'))}
                                        columns={carrierColumns}
                                        hidePaging={true}
                                        style={{ backgroundColor: '#ffffff' }}
                                        scroll={{ y: 240 }}
                                        rowKey={record => record.id}
                                    />
                                    <FormItem {...formItemLayout} label="Get a quote from IRIS Freight?" format="vertical" noColon
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Switch
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                onBlur={onBlur}
                                                onChange={(checked, event) => { onChange(checked); }}
                                                checked={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        rules={{ required: false }}
                                        name="requestToIRIS"
                                        defaultValue={true}
                                    />
                                    <FormItem {...formItemLayout} label="Do you have a target rate you want carriers to quote at? (Optional)" format="vertical" noColon
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <InputNumber
                                                precision={2}
                                                min={0}
                                                placeholder="Target Rate"
                                                style={{ width: '100%' }}
                                                onBlur={onBlur}
                                                onChange={e => { onChange(e); }}
                                                value={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        rules={{ required: false }}
                                        name="targetRate"
                                    />
                                </Fieldset>
                                <ErrorMessage
                                    errors={methods.errors}
                                    name="pickUpStops"
                                    render={({ message, messages }) => {
                                        if (isNotNullOrUndefined(messages)) {
                                            return Object.entries(messages).map(([type, message]) => (<Alert key={`pickUpStops-${type}`} message={message} type="error" />));
                                        } else if (isStringNotEmpty(message)) {
                                            return <Alert message={message} type="error" />;
                                        } else {
                                            return null;
                                        }
                                    }}
                                />
                                <ErrorMessage
                                    errors={methods.errors}
                                    name="dropOffStops"
                                    render={({ message, messages }) => {
                                        if (isNotNullOrUndefined(messages)) {
                                            return Object.entries(messages).map(([type, message]) => (<Alert key={`dropOffStops-${type}`} message={message} type="error" />));
                                        } else if (isStringNotEmpty(message)) {
                                            return <Alert message={message} type="error" />;
                                        } else {
                                            return null;
                                        }
                                    }}
                                />
                                {error && <Alert message={`${error}`} type="error" />}
                                <Row gutter={[12, 12]}>
                                    <Col xs={{ span: 24, order: 2 }} xl={{ span: 12, order: 1 }}>
                                        <Button type="text" danger block onClick={() => { onCancel(); }}>Cancel and go back</Button>
                                    </Col>
                                    <Col xs={{ span: 24, order: 1 }} xl={{ span: 12, order: 2 }}>
                                        <Button type="primary" block htmlType="submit">Create Load(s) and Send Quote Requests</Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 8 }} xl={{ span: 8 }}>
                            <Card
                                title={`Lane Summary (${loadLane.irisId})`}
                                style={{ height: '100%', border: '1px solid #d8d8d8' }}
                                headStyle={{ backgroundColor: '#cccccc', borderBottom: '1px solid #d8d8d8', fontSize: 14, textAlign: 'center' }}
                            >
                                <MapWrapper
                                    height={'300px'}
                                    key={`load-creation-map-key`}
                                    loadId={'new-load'}
                                    stops={isListNotEmpty(pickUpStops) && isListNotEmpty(dropOffStops) ? [...pickUpStops, ...dropOffStops] : []}
                                    containerName={`load-creation-map`}
                                    style={{ width: "100%", height: "300px", margin: '0px auto' }}
                                    setRouteDistance={setPracticalRouteMiles}
                                />

                                <Collapse expandIconPosition={'right'} style={{ marginLeft: '-12px', marginRight: '-12px', marginTop: 24, marginBottom: 24, borderLeft: 0, borderRight: 0 }}>
                                    <Panel style={{ background: '#ffffff' }} header={<span style={{ fontWeight: 'bold', fontSize: 14 }}>Equipment Needs & Services</span>} key="equipment">
                                        {getEquipmentData()}
                                    </Panel>
                                    <Panel style={{ background: '#ffffff' }} header={<span style={{ fontWeight: 'bold', fontSize: 14 }}>Stop Details</span>} key="stops">
                                        {getStopsData()}
                                    </Panel>
                                    <Panel style={{ background: '#ffffff' }} header={<span style={{ fontWeight: 'bold', fontSize: 14 }}>Additional Details</span>} key="additionalDetails">
                                        {getPricingData()}
                                    </Panel>
                                </Collapse>

                            </Card>
                        </Col>
                    </Row>
                </Form>
            </FormProvider>
        </Spin >
    );
};

export default withRouter(NewLoadAndRFB);