import React, { useMemo } from 'react';
import { Alert, Spin } from "antd";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { without } from 'lodash';
import { driverUnAssignedEmailTemplate } from "../../shared/emailTemplates";
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import FormItemDisplay from '../FormItemDisplay/FormItemDisplay';
import { isBooleanTrue, isObjectNotEmpty } from '../../shared/objectUtils';
import { sendEmail } from '../../api/emailClient';
import * as Data from '../../api/data';
import logger from '../../shared/logger';
import LoadUtils from '../../api/utils/loadUtils';

const RemoveLoadDriver = ({ driver, load, isBundledLoad = false, cancel }) => {
    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loads.isRecordUpdateLoading);
    const error = useSelector(state => state.loads.updateRecordError);

    const sendEmailToOldDriver = () => {
        if (isObjectNotEmpty(driver) && isObjectNotEmpty(driver.driver)) {
            if (driver.driver.isApproved === undefined || driver.driver.isApproved === null || driver.driver.isApproved === false) {
                const { body, subject } = driverUnAssignedEmailTemplate(driver.firstName, load.irisId)
                sendEmail(subject, body, driver.email).then(res => {
                    // console.log(res);
                }).catch(err => {
                    logger.logDebugEvent('RemoveLoadDriver.js', err.message, true);
                });
            }
        }
    };

    const sendNotificationToOldDriver = () => {
        if (isObjectNotEmpty(driver) && isObjectNotEmpty(driver.driver)) {
            let newNotification = {
                message: `Hi ${driver.firstName}, you are no longer assigned to load: ${load.irisId}.`,
                loadId: load.id,
                loadIrisId: load.irisId,
                saveOnly: false,
                subject: `${load.irisId} You have been unassigned from a load`,
                severity: "CRITICAL",
                userIds: [driver.userId],
                loadStatus: load.loadStatus,
                eventType: 'DRIVER_UNASSIGNED'
            };
            Data.sendLoadNotification(newNotification).then(res => {
                // console.log(res);
            }).catch(err => {
                logger.logDebugEvent('RemoveLoadDriver.js', err.message, true);
            });
        }
    };

    const onSubmit = () => {
        const updatedDriverIds = without(load.driverIds, driver.id);
        // console.log(updatedDriverIds);

        let payload = {
            driverIds: updatedDriverIds
        };
        // console.log(payload);

        if (isBooleanTrue(isBundledLoad)) {
            dispatch(actionCreators.removeDriverFromBundledLoad(load.id, payload, load, driver));
        } else {
            dispatch(actionCreators.removeDriverFromLoad(load.id, payload, driver));
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.updateLoadCancel());
        cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLoadErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null && driver !== undefined && driver !== null && load !== undefined && load !== null) {
            sendNotificationToOldDriver();
            sendEmailToOldDriver();

            if (LoadUtils.loadStatusIs(load.loadStatus, ['IN_TRANSIT', 'AT_STOP'])) {
                dispatch(actionCreators.markDriverAvailable(driver.id));
            }

            onCancel();
        }
    }, [isLoading, error, driver, load]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                <Fieldset legend="Remove Driver from this Load">
                    {isObjectNotEmpty(driver) ? (
                        <FormItemDisplay {...formItemLayout} label="Remove Driver">
                            <span>Are you sure you want to remove this {driver.firstName} {driver.lastName} from the Load?</span>
                        </FormItemDisplay>
                    ) : null}
                </Fieldset>
                {error && <Alert message={`${error}`} type="error" />}
            </Spin>
            <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Remove Driver from Load" />
        </Form>
    );
};

export default RemoveLoadDriver;