import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import classes from './States.module.scss';
import DataTable from '../../components/DataTable/DataTable';
import { isStringNotEmpty } from '../../shared/objectUtils';
import { Can } from '../../shared/entitlements/entitlements';
import { compareByAlph } from '../../shared/tableUtils';

const States = (props) => {
    //#region constants

    const singularEntityName = "State";
    const pluralEntityName = "States";

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.states.isLoading);
    const records = useSelector(state => state.states.records);
    const pagination = useSelector(state => state.states.pagination);

    //#endregion
    //#region table methods

    const refreshTable = () => {
        let searchParams = {
            page: 1,
            size: 5,
            sort: 'stateName',
            order: 'asc',
        };

        dispatch(actionCreators.fetchStates(searchParams));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchStatesSuccess({ pagination: pager }));

        let searchParams = {
            page: pagination.current,
            size: pagination.pageSize
        };

        if (isStringNotEmpty(sorter.order)) {
            const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
            searchParams.order = sortOrder;
        }
        if (isStringNotEmpty(sorter.field)) {
            searchParams.sort = sorter.field;
        }

        dispatch(actionCreators.fetchStates(searchParams));
    };

    //#endregion
    //#region table displays

    const columns = [
        {
            title: 'State',
            dataIndex: 'stateName',
            key: 'stateName',
            sorter: (a, b) => compareByAlph(a.stateName, b.stateName),
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'ascend',
        },
        {
            title: 'State Abbr',
            dataIndex: 'stateAbbr',
            key: 'stateAbbr',
        },
        {
            title: 'Country',
            dataIndex: 'countryName',
            key: 'countryName',
        },
        {
            title: 'Country Abbr',
            dataIndex: 'countryAbbr',
            key: 'countryAbbr',
        }
    ];

    //#endregion
    //#region useEffects

    useEffect(() => {
        refreshTable();
    }, []);

    //#endregion

    return (
        <Can entityAction="READ_LIST" entityModel="STATE">
            <DataTable
                dataSource={records}
                columns={columns}
                pagination={pagination}
                onChange={handleTableChange}
                loading={isLoading === true}
                rowClassName={classes.dataTableRow}
                singularEntityName={singularEntityName}
                pluralEntityName={pluralEntityName}
                newEntityAction={null}
                rowKey={record => record.id}
                style={{ backgroundColor: '#ffffff' }}
            />
        </Can>
    );
};

export default withRouter(States);