import { isNumberNotEmpty } from "../../shared/objectUtils";

export const getInvoiceDTO = (invoice) => {
    return invoice;
};

export const getAddInvoiceRequestDTO = (payload) => {
    if (isNumberNotEmpty(payload.amountDue)) {
        payload.amountDue = Number(payload.amountDue);
    }

    if (isNumberNotEmpty(payload.balanceDue)) {
        payload.balanceDue = Number(payload.balanceDue);
    }

    return payload;
};

export const getUpdateInvoiceRequestDTO = (payload) => {
    if (isNumberNotEmpty(payload.amountDue)) {
        payload.amountDue = Number(payload.amountDue);
    }

    if (isNumberNotEmpty(payload.balanceDue)) {
        payload.balanceDue = Number(payload.balanceDue);
    }

    return payload;
};