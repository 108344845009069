import Checks from '../entitlementUtils';

export const canCreatePreferredLane = (auth, accountId) => {
    return Checks.allowStaff(auth) || Checks.isAccountOperationsAdmin(auth, accountId);
};

export const canReadPreferredLane = (auth, accountId) => {
    return Checks.allowStaff(auth) || Checks.isAccountOperationsAdmin(auth, accountId);
};

export const canReadPreferredLaneList = (auth, accountId) => {
    return Checks.allowStaff(auth) || Checks.isAccountOperationsAdmin(auth, accountId);
};

export const canUpdatePreferredLane = (auth, accountId) => {
    return Checks.allowStaff(auth) || Checks.isAccountOperationsAdmin(auth, accountId);
};

export const canDeletePreferredLane = (auth) => {
    return Checks.allowStaffAdmin(auth);
};