import React, { useMemo } from 'react';
import { Select, Alert, Spin, Checkbox, InputNumber, Input, Radio, Row, Col, Typography } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Enums from '../../shared/enums';
import FormItem from '../FormItem/FormItem';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import { isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import FormItemAddress from '../FormItemAddress/FormItemAddress';
import FormItemPointOfContact from '../FormItemPointOfContact/FormItemPointOfContact';

const { Paragraph } = Typography;

const EditAccountRegistration = ({ cancel, accountRegistration }) => {

    const accountRegistrationReviewStatusOptions = Enums.AccountRegistrationReviewStatuses.selectListOptions();

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.accountRegistrations.isRecordUpdateLoading);
    const error = useSelector(state => state.accountRegistrations.updateRecordError);

    const onSubmit = (data) => {
        if (isObjectNotEmpty(data)) {
            if (isStringNotEmpty(data.reviewStatus) && data.reviewStatus !== 'PENDING') {
                data.isReviewed = true;
            }

            dispatch(actionCreators.updateAccountRegistration(accountRegistration.id, data));
        } else {
            onCancel();
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.updateAccountRegistrationCancel());
        cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateAccountRegistrationErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const formItemLayoutHorizontal = {
        labelCol: { span: 20 },
        wrapperCol: { span: 4 },
    };
    const formItemLayoutDoubleHorizontal = {
        labelCol1: { span: 12 },
        wrapperCol1: { span: 1 },
        labelCol2: { span: 6 },
        wrapperCol2: { span: 5 },
    };

    if (isObjectNotEmpty(accountRegistration)) {
        if (accountRegistration.entityTypes.includes("CARRIER") === true) {
            return (
                <FormProvider {...methods}>
                    <Form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                            <Fieldset legend="Company Contact Information (* indicates a required field)">
                                <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <FormItem {...formItemLayout} label="Company Name" required format="vertical"
                                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Company Name" ref={ref} />}
                                            rules={{ required: 'Required Field' }}
                                            name="name"
                                            defaultValue={accountRegistration.name ? accountRegistration.name : ''}
                                        />
                                        <FormItemPointOfContact
                                            format="vertical"
                                            required={true}
                                            name="pointOfContact"
                                            defaultValue={accountRegistration.pointOfContact}
                                            nameFieldLabel="Contact Name"
                                            showName={true}
                                            showEmail={false}
                                            showPhone={false}
                                        />
                                        <FormItemPointOfContact
                                            format="vertical"
                                            required={true}
                                            defaultValue={accountRegistration}
                                            emailFieldLabel="Company Email"
                                            showName={false}
                                            showEmail={true}
                                            showPhone={false}
                                        />
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <FormItem {...formItemLayout} label="Federal Id/EIN" required format="vertical"
                                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="EIN" ref={ref} />}
                                            rules={{ required: 'Required Field' }}
                                            name="ein"
                                            defaultValue={accountRegistration.ein ? accountRegistration.ein : ''}
                                        />
                                        <FormItemPointOfContact
                                            format="vertical"
                                            required={true}
                                            defaultValue={accountRegistration}
                                            phoneFieldLabel="Day Time Phone #"
                                            showPhone={true}
                                            showEmail={false}
                                            showName={false}
                                        />
                                        <FormItemPointOfContact
                                            format="vertical"
                                            required={true}
                                            defaultValue={accountRegistration}
                                            phoneFieldLabel="After Hours Phone #"
                                            showPhone={true}
                                            showEmail={false}
                                            showName={false}
                                            phoneFieldName="afterHoursPhone"
                                            phoneExtFieldName="afterHoursPhoneExt"
                                        />
                                        <FormItem {...formItemLayout} label="Fax #" format="vertical"
                                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Fax #" ref={ref} />}
                                            rules={{ required: false }}
                                            name="fax"
                                            defaultValue={accountRegistration.fax ? accountRegistration.fax : ''}
                                        />
                                    </Col>
                                </Row>
                            </Fieldset>
                            <Fieldset legend="Company Address (* indicates a required field)">
                                <FormItemAddress
                                    format="vertical"
                                    required={true}
                                    name="companyAddress"
                                    defaultValue={accountRegistration.companyAddress}
                                    isPostalAddress={true}
                                />
                            </Fieldset>
                            <Fieldset legend="Carrier Information (* indicates a required field)">
                                <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <FormItem {...formItemLayout} label="US Docket # (MC, FF, MX)" required format="vertical"
                                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="MC Number" ref={ref} />}
                                            rules={{
                                                required: 'Required Field',
                                                pattern: {
                                                    value: /^\d{1,8}$/,
                                                    message: "Please enter a valid number with no special characters"
                                                },
                                                maxLength: { value: 8, message: 'MC Number cannot be more than 8 characters long' },
                                                minLength: { value: 6, message: 'MC Number must be at least 6 characters long' },
                                            }}
                                            name="mcNum"
                                            defaultValue={accountRegistration.mcNum ? accountRegistration.mcNum : ''}
                                        />
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <FormItem {...formItemLayout} label="US DOT #" required format="vertical"
                                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="DOT #" ref={ref} />}
                                            rules={{
                                                required: 'Required Field',
                                                pattern: {
                                                    value: /^\d{1,8}$/,
                                                    message: "Please enter a valid number with no special characters"
                                                },
                                                maxLength: { value: 8, message: 'DOT Number cannot be more than 8 characters long' },
                                                minLength: { value: 6, message: 'DOT Number must be at least 6 characters long' },
                                            }}
                                            name="dotNum"
                                            defaultValue={accountRegistration.dotNum ? accountRegistration.dotNum : ''}
                                        />
                                    </Col>
                                    <Col xxl={{ span: 4 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <FormItem {...formItemLayout} label="# of Power Units" format="vertical"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            rules={{ required: false }}
                                            name="assetNeeds.numPowerUnits"
                                            defaultValue={accountRegistration.assetNeeds.numPowerUnits ? accountRegistration.assetNeeds.numPowerUnits : ''}
                                        />
                                    </Col>
                                    <Col xxl={{ span: 4 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <FormItem {...formItemLayout} label="# of Trailers" format="vertical"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            rules={{ required: false }}
                                            name="assetNeeds.numTrailers"
                                            defaultValue={accountRegistration.assetNeeds.numTrailers ? accountRegistration.assetNeeds.numTrailers : ''}
                                        />
                                    </Col>
                                    <Col xxl={{ span: 4 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <FormItem {...formItemLayout} label="# of Drivers" format="vertical"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            rules={{ required: false }}
                                            name="assetNeeds.numDrivers"
                                            defaultValue={accountRegistration.assetNeeds.numDrivers ? accountRegistration.assetNeeds.numDrivers : ''}
                                        />
                                    </Col>
                                    <Col xxl={{ span: 4 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <FormItem {...formItemLayout} label="# of HazMat Drivers" format="vertical"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            rules={{ required: false }}
                                            name="assetNeeds.numHazMatDrivers"
                                            defaultValue={accountRegistration.assetNeeds.numHazMatDrivers ? accountRegistration.assetNeeds.numHazMatDrivers : ''}
                                        />
                                    </Col>
                                    <Col xxl={{ span: 4 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <FormItem {...formItemLayout} label="# of Teams" format="vertical"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            rules={{ required: false }}
                                            name="assetNeeds.numTeams"
                                            defaultValue={accountRegistration.assetNeeds.numTeams ? accountRegistration.assetNeeds.numTeams : ''}
                                        />
                                    </Col>
                                </Row>
                            </Fieldset>
                            <Fieldset legend="Accounts Receivable (* indicates a required field)">
                                <Row gutter={[8, 8]}>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <FormItem {...formItemLayout} label="Preferred Payment Method" format="vertical" required
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Radio.Group onBlur={onBlur} onChange={e => onChange(e.target.value)} value={value} name={name} buttonStyle="solid" ref={ref}>
                                                    <Radio.Button value="DIRECT_DEPOSIT">Direct Deposit</Radio.Button>
                                                    <Radio.Button value="QUICK_PAY">Quick Pay</Radio.Button>
                                                    <Radio.Button value="FACTORING">Factoring</Radio.Button>
                                                </Radio.Group>
                                            )}
                                            rules={{ required: 'Required Field' }}
                                            name="preferredPaymentMethod"
                                            defaultValue={accountRegistration.preferredPaymentMethod ? accountRegistration.preferredPaymentMethod : undefined}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <Paragraph>If your accounts receivable department is different than your physical address, please complete below:</Paragraph>
                                    </Col>
                                </Row>
                                <FormItemPointOfContact
                                    format="vertical"
                                    required={false}
                                    name="accountsReceivable"
                                    defaultValue={accountRegistration.accountsReceivable}
                                    nameFieldLabel="A/R Contact"
                                />
                                <FormItemAddress
                                    format="vertical"
                                    required={false}
                                    name="accountsReceivableAddress"
                                    defaultValue={accountRegistration.accountsReceivableAddress}
                                    isPostalAddress={true}
                                />
                            </Fieldset>
                            <Fieldset legend="Trailer Types">
                                <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <h4 style={{ textAlign: 'center' }}>Reefers</h4>
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 48' Reefers?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name1="assetNeeds.has48Reefer"
                                            defaultValue1={accountRegistration.assetNeeds.has48Reefer ? accountRegistration.assetNeeds.has48Reefer : false}
                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name2="assetNeeds.num48Reefer"
                                            defaultValue2={accountRegistration.assetNeeds.num48Reefer ? accountRegistration.assetNeeds.num48Reefer : ''}
                                        />
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 53' Reefers?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name1="assetNeeds.has53Reefer"
                                            defaultValue1={accountRegistration.assetNeeds.has53Reefer ? accountRegistration.assetNeeds.has53Reefer : false}
                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name2="assetNeeds.num53Reefer"
                                            defaultValue2={accountRegistration.assetNeeds.num53Reefer ? accountRegistration.assetNeeds.num53Reefer : ''}
                                        />
                                        <FormItem {...formItemLayoutHorizontal} label="Do you haul frozen?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name="assetNeeds.hasReeferFrozen"
                                            defaultValue={accountRegistration.assetNeeds.hasReeferFrozen ? accountRegistration.assetNeeds.hasReeferFrozen : false}
                                        />
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <h4 style={{ textAlign: 'center' }}>Dry Vans</h4>
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 48' Dry Vans?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name1="assetNeeds.has48DryVan"
                                            defaultValue1={accountRegistration.assetNeeds.has48DryVan ? accountRegistration.assetNeeds.has48DryVan : false}
                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name2="assetNeeds.num48DryVan"
                                            defaultValue2={accountRegistration.assetNeeds.num48DryVan ? accountRegistration.assetNeeds.num48DryVan : ''}
                                        />
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 53' Dry Vans?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name1="assetNeeds.has53DryVan"
                                            defaultValue1={accountRegistration.assetNeeds.has53DryVan ? accountRegistration.assetNeeds.has53DryVan : false}
                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name2="assetNeeds.num53DryVan"
                                            defaultValue2={accountRegistration.assetNeeds.num53DryVan ? accountRegistration.assetNeeds.num53DryVan : ''}
                                        />
                                        <FormItem {...formItemLayoutHorizontal} label="Do you have lift gates for the dry vans?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name="assetNeeds.hasDryVanLiftGates"
                                            defaultValue={accountRegistration.assetNeeds.hasDryVanLiftGates ? accountRegistration.assetNeeds.hasDryVanLiftGates : false}
                                        />
                                        <FormItem {...formItemLayoutHorizontal} label="Do you have roller beds for the dry vans?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name="assetNeeds.hasDryVanRollerBed"
                                            defaultValue={accountRegistration.assetNeeds.hasDryVanRollerBed ? accountRegistration.assetNeeds.hasDryVanRollerBed : false}
                                        />
                                        <h4 style={{ textAlign: 'center' }}>Over-Sized Loads</h4>
                                        <FormItem {...formItemLayoutHorizontal} label="Do you haul over dimensional/overweight loads?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name="assetNeeds.hasOverSized"
                                            defaultValue={accountRegistration.assetNeeds.hasOverSized ? accountRegistration.assetNeeds.hasOverSized : false}
                                        />
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <h4 style={{ textAlign: 'center' }}>Flatbeds</h4>
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 48' Flatbeds?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name1="assetNeeds.has48Flatbed"
                                            defaultValue1={accountRegistration.assetNeeds.has48Flatbed ? accountRegistration.assetNeeds.has48Flatbed : false}
                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name2="assetNeeds.num48Flatbed"
                                            defaultValue2={accountRegistration.assetNeeds.num48Flatbed ? accountRegistration.assetNeeds.num48Flatbed : ''}
                                        />
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 53' Flatbeds?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name1="assetNeeds.has53Flatbed"
                                            defaultValue1={accountRegistration.assetNeeds.has53Flatbed ? accountRegistration.assetNeeds.has53Flatbed : false}
                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name2="assetNeeds.num53Flatbed"
                                            defaultValue2={accountRegistration.assetNeeds.num53Flatbed ? accountRegistration.assetNeeds.num53Flatbed : ''}
                                        />
                                        <h4 style={{ textAlign: 'center' }}>Low Boys</h4>
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Low Boys?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name1="assetNeeds.hasLowBoy"
                                            defaultValue1={accountRegistration.assetNeeds.hasLowBoy ? accountRegistration.assetNeeds.hasLowBoy : false}
                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name2="assetNeeds.numLowBoy"
                                            defaultValue2={accountRegistration.assetNeeds.numLowBoy ? accountRegistration.assetNeeds.numLowBoy : ''}
                                        />
                                        <h4 style={{ textAlign: 'center' }}>RGNs</h4>
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have RGNs?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name1="assetNeeds.hasRGN"
                                            defaultValue1={accountRegistration.assetNeeds.hasRGN ? accountRegistration.assetNeeds.hasRGN : false}
                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name2="assetNeeds.numRGN"
                                            defaultValue2={accountRegistration.assetNeeds.numRGN ? accountRegistration.assetNeeds.numRGN : ''}
                                        />
                                        <h4 style={{ textAlign: 'center' }}>Step Decks</h4>
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Step Decks with Ramps?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name1="assetNeeds.hasStepDeckRamps"
                                            defaultValue1={accountRegistration.assetNeeds.hasStepDeckRamps ? accountRegistration.assetNeeds.hasStepDeckRamps : false}
                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name2="assetNeeds.numStepDeckRamps"
                                            defaultValue2={accountRegistration.assetNeeds.numStepDeckRamps ? accountRegistration.assetNeeds.numStepDeckRamps : ''}
                                        />
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Step Decks without Ramps?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name1="assetNeeds.hasStepDeckNoRamps"
                                            defaultValue1={accountRegistration.assetNeeds.hasStepDeckNoRamps ? accountRegistration.assetNeeds.hasStepDeckNoRamps : false}
                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name2="assetNeeds.numStepDeckNoRamps"
                                            defaultValue2={accountRegistration.assetNeeds.numStepDeckNoRamps ? accountRegistration.assetNeeds.numStepDeckNoRamps : ''}
                                        />
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <h4 style={{ textAlign: 'center' }}>Tankers</h4>
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Single Compartment Tankers?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name1="assetNeeds.hasTankersSingleCompartment"
                                            defaultValue1={accountRegistration.assetNeeds.hasTankersSingleCompartment ? accountRegistration.assetNeeds.hasTankersSingleCompartment : false}
                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name2="assetNeeds.numTankersSingleCompartment"
                                            defaultValue2={accountRegistration.assetNeeds.numTankersSingleCompartment ? accountRegistration.assetNeeds.numTankersSingleCompartment : ''}
                                        />
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Multi Compartment Tankers?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name1="assetNeeds.hasTankersMultiCompartment"
                                            defaultValue1={accountRegistration.assetNeeds.hasTankersMultiCompartment ? accountRegistration.assetNeeds.hasTankersMultiCompartment : false}
                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name2="assetNeeds.numTankersMultiCompartment"
                                            defaultValue2={accountRegistration.assetNeeds.numTankersMultiCompartment ? accountRegistration.assetNeeds.numTankersMultiCompartment : ''}
                                        />
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Fiber Glass Tankers?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name1="assetNeeds.hasTankersFiberGlass"
                                            defaultValue1={accountRegistration.assetNeeds.hasTankersFiberGlass ? accountRegistration.assetNeeds.hasTankersFiberGlass : false}
                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name2="assetNeeds.numTankersFiberGlass"
                                            defaultValue2={accountRegistration.assetNeeds.numTankersFiberGlass ? accountRegistration.assetNeeds.numTankersFiberGlass : ''}
                                        />
                                        <h4 style={{ textAlign: 'center' }}>Straight Trucks</h4>
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Cubed Straight Trucks?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name1="assetNeeds.hasCubedStraightTruck"
                                            defaultValue1={accountRegistration.assetNeeds.hasCubedStraightTruck ? accountRegistration.assetNeeds.hasCubedStraightTruck : false}
                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name2="assetNeeds.numCubedStraightTruck"
                                            defaultValue2={accountRegistration.assetNeeds.numCubedStraightTruck ? accountRegistration.assetNeeds.numCubedStraightTruck : ''}
                                        />
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 26' Straight Trucks?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name1="assetNeeds.has26StraightTruck"
                                            defaultValue1={accountRegistration.assetNeeds.has26StraightTruck ? accountRegistration.assetNeeds.has26StraightTruck : false}
                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name2="assetNeeds.num26StraightTruck"
                                            defaultValue2={accountRegistration.assetNeeds.num26StraightTruck ? accountRegistration.assetNeeds.num26StraightTruck : ''}
                                        />
                                        <FormItem {...formItemLayoutHorizontal} label="Do you have lift gates for the straight trucks?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name="assetNeeds.hasStraightTruckLiftGates"
                                            defaultValue={accountRegistration.assetNeeds.hasStraightTruckLiftGates ? accountRegistration.assetNeeds.hasStraightTruckLiftGates : false}
                                        />
                                        <h4 style={{ textAlign: 'center' }}>Sprinter Vans</h4>
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Sprinter Vans?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name1="assetNeeds.hasSprinterVan"
                                            defaultValue1={accountRegistration.assetNeeds.hasSprinterVan ? accountRegistration.assetNeeds.hasSprinterVan : false}
                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name2="assetNeeds.numSprinterVan"
                                            defaultValue2={accountRegistration.assetNeeds.numSprinterVan ? accountRegistration.assetNeeds.numSprinterVan : ''}
                                        />
                                    </Col>
                                </Row>
                            </Fieldset>
                            <Fieldset legend="Update Account Application Review Status">
                                <FormItem {...formItemLayout} label="Review Status" format="vertical" required
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Select
                                            placeholder="Please select a Review Status"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChange(selected); }}
                                            value={value}
                                            name={name}
                                            showSearch={true}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            ref={ref}
                                        >
                                            {accountRegistrationReviewStatusOptions}
                                        </Select>
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="reviewStatus"
                                    defaultValue={accountRegistration.reviewStatus}
                                />
                            </Fieldset>
                            {error && <Alert message={error} type="error" />}
                        </Spin>
                        <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Update Account Application" />
                    </Form>
                </FormProvider>
            );
        } else if (accountRegistration.entityTypes.includes("SHIPPER") === true) {
            return (
                <FormProvider {...methods}>
                    <Form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                            <Fieldset legend="Company Contact Information (* indicates a required field)">
                                <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <FormItem {...formItemLayout} label="Company Name" required format="vertical"
                                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Company Name" ref={ref} />}
                                            rules={{ required: 'Required Field' }}
                                            name="name"
                                            defaultValue={accountRegistration.name ? accountRegistration.name : ''}
                                        />
                                        <FormItemPointOfContact
                                            format="vertical"
                                            required={true}
                                            name="pointOfContact"
                                            defaultValue={accountRegistration.pointOfContact}
                                            nameFieldLabel="Contact Name"
                                            showName={true}
                                            showEmail={false}
                                            showPhone={false}
                                        />
                                        <FormItemPointOfContact
                                            format="vertical"
                                            required={true}
                                            defaultValue={accountRegistration}
                                            emailFieldLabel="Company Email"
                                            showName={false}
                                            showEmail={true}
                                            showPhone={false}
                                        />
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <FormItem {...formItemLayout} label="Federal Id/EIN" required format="vertical"
                                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="EIN" ref={ref} />}
                                            rules={{
                                                required: 'Required Field',
                                                pattern: {
                                                    value: /^\d{9}$/,
                                                    message: "Please enter a valid 9 digit EIN with no special characters"
                                                },
                                                minLength: { value: 9, message: 'EIN must be at least 9 characters long' },
                                                maxLength: { value: 9, message: 'EIN cannot be more than 9 characters long' },
                                            }}
                                            name="ein"
                                            defaultValue={accountRegistration.ein ? accountRegistration.ein : ''}
                                        />
                                        <FormItemPointOfContact
                                            format="vertical"
                                            required={true}
                                            defaultValue={accountRegistration}
                                            phoneFieldLabel="Day Time Phone #"
                                            showPhone={true}
                                            showEmail={false}
                                            showName={false}
                                        />
                                        <FormItemPointOfContact
                                            format="vertical"
                                            required={true}
                                            defaultValue={accountRegistration}
                                            phoneFieldLabel="After Hours Phone #"
                                            showPhone={true}
                                            showEmail={false}
                                            showName={false}
                                            phoneFieldName="afterHoursPhone"
                                            phoneExtFieldName="afterHoursPhoneExt"
                                        />
                                        <FormItem {...formItemLayout} label="Fax #" format="vertical"
                                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Fax #" ref={ref} />}
                                            rules={{ required: false }}
                                            name="fax"
                                            defaultValue={accountRegistration.fax ? accountRegistration.fax : ''}
                                        />
                                    </Col>
                                </Row>
                            </Fieldset>
                            <Fieldset legend="Company Address (* indicates a required field)">
                                <FormItemAddress
                                    format="vertical"
                                    required={true}
                                    name="companyAddress"
                                    defaultValue={accountRegistration.companyAddress}
                                    isPostalAddress={true}
                                />
                            </Fieldset>
                            <Fieldset legend="What are your equipment needs based on the commodities you ship?">
                                <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <h4 style={{ textAlign: 'center' }}>Reefers</h4>
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need 48' Reefers?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name="assetNeeds.has48Reefer"
                                            defaultValue={accountRegistration.assetNeeds.has48Reefer ? accountRegistration.assetNeeds.has48Reefer : false}
                                        />
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need 53' Reefers?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name="assetNeeds.has53Reefer"
                                            defaultValue={accountRegistration.assetNeeds.has53Reefer ? accountRegistration.assetNeeds.has53Reefer : false}
                                        />
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need to ship frozen?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name="assetNeeds.hasReeferFrozen"
                                            defaultValue={accountRegistration.assetNeeds.hasReeferFrozen ? accountRegistration.assetNeeds.hasReeferFrozen : false}
                                        />
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <h4 style={{ textAlign: 'center' }}>Dry Vans</h4>
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need 48' Dry Vans?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name="assetNeeds.has48DryVan"
                                            defaultValue={accountRegistration.assetNeeds.has48DryVan ? accountRegistration.assetNeeds.has48DryVan : false}
                                        />
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need 53' Dry Vans?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name="assetNeeds.has53DryVan"
                                            defaultValue={accountRegistration.assetNeeds.has53DryVan ? accountRegistration.assetNeeds.has53DryVan : false}
                                        />
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need lift gates for the dry vans?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name="assetNeeds.hasDryVanLiftGates"
                                            defaultValue={accountRegistration.assetNeeds.hasDryVanLiftGates ? accountRegistration.assetNeeds.hasDryVanLiftGates : false}
                                        />
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need roller beds for the dry vans?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name="assetNeeds.hasDryVanRollerBed"
                                            defaultValue={accountRegistration.assetNeeds.hasDryVanRollerBed ? accountRegistration.assetNeeds.hasDryVanRollerBed : false}
                                        />
                                        <h4 style={{ textAlign: 'center' }}>Over-Sized Loads</h4>
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need to ship over dimensional/overweight loads?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name="assetNeeds.hasOverSized"
                                            defaultValue={accountRegistration.assetNeeds.hasOverSized ? accountRegistration.assetNeeds.hasOverSized : false}
                                        />
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <h4 style={{ textAlign: 'center' }}>Flatbeds</h4>
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need 48' Flatbeds?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name="assetNeeds.has48Flatbed"
                                            defaultValue={accountRegistration.assetNeeds.has48Flatbed ? accountRegistration.assetNeeds.has48Flatbed : false}
                                        />
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need 53' Flatbeds?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name="assetNeeds.has53Flatbed"
                                            defaultValue={accountRegistration.assetNeeds.has53Flatbed ? accountRegistration.assetNeeds.has53Flatbed : false}
                                        />
                                        <h4 style={{ textAlign: 'center' }}>Low Boys</h4>
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need Low Boys?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name="assetNeeds.hasLowBoy"
                                            defaultValue={accountRegistration.assetNeeds.hasLowBoy ? accountRegistration.assetNeeds.hasLowBoy : false}
                                        />
                                        <h4 style={{ textAlign: 'center' }}>RGNs</h4>
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need RGNs?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name="assetNeeds.hasRGN"
                                            defaultValue={accountRegistration.assetNeeds.hasRGN ? accountRegistration.assetNeeds.hasRGN : false}
                                        />
                                        <h4 style={{ textAlign: 'center' }}>Step Decks</h4>
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need Step Decks with Ramps?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name="assetNeeds.hasStepDeckRamps"
                                            defaultValue={accountRegistration.assetNeeds.hasStepDeckRamps ? accountRegistration.assetNeeds.hasStepDeckRamps : false}
                                        />
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need Step Decks without Ramps?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name="assetNeeds.hasStepDeckNoRamps"
                                            defaultValue={accountRegistration.assetNeeds.hasStepDeckNoRamps ? accountRegistration.assetNeeds.hasStepDeckNoRamps : false}
                                        />
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <h4 style={{ textAlign: 'center' }}>Tankers</h4>
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need Single Compartment Tankers?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name="assetNeeds.hasTankersSingleCompartment"
                                            defaultValue={accountRegistration.assetNeeds.hasTankersSingleCompartment ? accountRegistration.assetNeeds.hasTankersSingleCompartment : false}
                                        />
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need Multi Compartment Tankers?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name="assetNeeds.hasTankersMultiCompartment"
                                            defaultValue={accountRegistration.assetNeeds.hasTankersMultiCompartment ? accountRegistration.assetNeeds.hasTankersMultiCompartment : false}
                                        />
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need Fiber Glass Tankers?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name="assetNeeds.hasTankersFiberGlass"
                                            defaultValue={accountRegistration.assetNeeds.hasTankersFiberGlass ? accountRegistration.assetNeeds.hasTankersFiberGlass : false}
                                        />
                                        <h4 style={{ textAlign: 'center' }}>Straight Trucks</h4>
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need Cubed Straight Trucks?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name="assetNeeds.hasCubedStraightTruck"
                                            defaultValue={accountRegistration.assetNeeds.hasCubedStraightTruck ? accountRegistration.assetNeeds.hasCubedStraightTruck : false}
                                        />
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need 26' Straight Trucks?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name="assetNeeds.has26StraightTruck"
                                            defaultValue={accountRegistration.assetNeeds.has26StraightTruck ? accountRegistration.assetNeeds.has26StraightTruck : false}
                                        />
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need lift gates for the straight trucks?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name="assetNeeds.hasStraightTruckLiftGates"
                                            defaultValue={accountRegistration.assetNeeds.hasStraightTruckLiftGates ? accountRegistration.assetNeeds.hasStraightTruckLiftGates : false}
                                        />
                                        <h4 style={{ textAlign: 'center' }}>Sprinter Vans</h4>
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need Sprinter Vans?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name="assetNeeds.hasSprinterVan"
                                            defaultValue={accountRegistration.assetNeeds.hasSprinterVan ? accountRegistration.assetNeeds.hasSprinterVan : false}
                                        />
                                    </Col>
                                </Row>
                            </Fieldset>
                            <Fieldset legend="Update Account Application Review Status">
                                <FormItem {...formItemLayout} label="Review Status" format="vertical" required
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Select
                                            placeholder="Please select a Review Status"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChange(selected); }}
                                            value={value}
                                            name={name}
                                            showSearch={true}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            ref={ref}
                                        >
                                            {accountRegistrationReviewStatusOptions}
                                        </Select>
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="reviewStatus"
                                    defaultValue={accountRegistration.reviewStatus ? accountRegistration.reviewStatus : undefined}
                                />
                            </Fieldset>
                            {error && <Alert message={error} type="error" />}
                        </Spin>
                        <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Update Account Application" />
                    </Form>
                </FormProvider>
            );
        } else {
            return null;
        }
    } else {
        return null;
    }
};

export default EditAccountRegistration;