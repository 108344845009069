import moment from 'moment';
import { isMomentDate, isNumberNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';

export const getAddTransactionRequestDTO = (payload) => {
    if (isNumberNotEmpty(payload.amount)) {
        payload.amount = Number(payload.amount);
        payload.amountUnit = 'USD';
    }

    if (isNumberNotEmpty(payload.transactionFee)) {
        payload.transactionFee = Number(payload.transactionFee);
        payload.transactionFeeUnit = 'USD';
    }

    if (isNumberNotEmpty(payload.netAmount)) {
        payload.netAmount = Number(payload.netAmount);
        payload.netAmountUnit = 'USD';
    }

    if (isMomentDate(payload.paidAt)) {
        payload.paidAt = moment(payload.paidAt).utc().toISOString(true);
    } else if (isStringNotEmpty(payload.paidAt)) {
        // paidAt is already an iso utc string
        payload.paidAt = payload.paidAt;
    }

    return payload;
};

export const getUpdateTransactionRequestDTO = (payload) => {
    if (isNumberNotEmpty(payload.amount)) {
        payload.amount = Number(payload.amount);
        payload.amountUnit = 'USD';
    } else if (payload.amount === null) {
        payload.amount = null;
        payload.amountUnit = null;
    }

    if (isNumberNotEmpty(payload.transactionFee)) {
        payload.transactionFee = Number(payload.transactionFee);
        payload.transactionFeeUnit = 'USD';
    } else if (payload.transactionFee === null) {
        payload.transactionFee = null;
        payload.transactionFeeUnit = null;
    }

    if (isNumberNotEmpty(payload.netAmount)) {
        payload.netAmount = Number(payload.netAmount);
        payload.netAmountUnit = 'USD';
    } else if (payload.netAmount === null) {
        payload.netAmount = null;
        payload.netAmountUnit = null;
    }

    if (isMomentDate(payload.paidAt)) {
        payload.paidAt = moment(payload.paidAt).utc().toISOString(true);
    } else if (isStringNotEmpty(payload.paidAt)) {
        // paidAt is already an iso utc string
        payload.paidAt = payload.paidAt;
    } else if (payload.paidAt === null) {
        payload.paidAt = null;
    }

    return payload;
};