import React from 'react';
import { CanSee } from '../../shared/entitlements/entitlements';
import StringFormatter from '../../shared/stringFormatter';
import DataTable from '../DataTable/DataTable';

const stringFormatter = new StringFormatter();

const LoadCarrierRejectionList = ({ carrierRejections }) => {
    //#region constants

    const singularEntityName = "Carrier Rejection";
    const pluralEntityName = "Carrier Rejections";

    //#endregion
    //#region table displays

    const columns = [
        {
            title: 'Reason',
            dataIndex: 'rejectedReason',
            key: 'rejectedReason',
        },
        {
            title: 'Rejected By Carrier',
            dataIndex: ['rejectedByCarrier', 'name'],
            key: 'rejectedByCarrier.name',
        },
        {
            title: 'Rejected At',
            dataIndex: 'rejectedAt',
            key: 'rejectedAt',
            render: (text, record) => { return stringFormatter.toFormattedString("MomentDateTime", text, null); },
        },
    ];

    //#endregion

    return (
        <CanSee entityAction="READ" entityModel="CARRIER_REJECTION">
            <DataTable
                dataSource={carrierRejections}
                columns={columns}
                hidePaging={true}
                footer={false}
                singularEntityName={singularEntityName}
                pluralEntityName={pluralEntityName}
                newEntityAction={null}
                rowKey={record => record.id}
                style={{ backgroundColor: '#ffffff' }}
            />
        </CanSee>
    );
};

export default LoadCarrierRejectionList;