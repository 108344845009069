import React, { useState, useMemo } from 'react';
import { Alert, Button, Drawer, Empty, message, Select, Spin } from "antd";
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import InvoiceUtils from '../../api/utils/invoiceUtils';
import moment from 'moment';
import { isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { selectListRecords } from '../../store/utility';
import FormItem from '../FormItem/FormItem';
import NewEmployee from '../NewEmployee/NewEmployee';
import Invoice from '../Invoice/Invoice';
import { CanSee } from '../../shared/entitlements/entitlements';

const { Option } = Select;

const LoadSendPriceConfirmation = ({ load, loadId, invoice, shipperId, stops, cancel, loading = false }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    //#endregion
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const userId = useSelector(state => state.auth.userId);
    const employees = useSelector(state => selectListRecords(state.orchestrator.employees, shipperId));
    const isLoadingUpdateInvoice = useSelector(state => state.invoices.isRecordUpdateLoading);
    const errorUpdateInvoice = useSelector(state => state.invoices.updateRecordError);

    //#endregion
    //#region useStates

    const [showNewEmployee, setShowNewEmployee] = useState(false);
    const [isSending, setIsSending] = useState(false);

    //#endregion
    //#region toggles

    const toggleNewEmployee = () => {
        setShowNewEmployee(!showNewEmployee);
    };

    //#endregion
    //#region onSubmit and onCancel

    const onSubmitSendInvoice = async (data) => {
        if (isObjectNotEmpty(invoice) && isStringNotEmpty(invoice.id) && isListNotEmpty(employees) && isListNotEmpty(data.accountUserIds) && isObjectNotEmpty(load) && isStringNotEmpty(loadId) && isStringNotEmpty(userId)) {
            setIsSending(true);
            if (invoice.status !== 'COMPLETED') {
                // save the pdf document to s3
                let newDocument = await InvoiceUtils.saveInvoiceAsDocument('PRICE_CONFIRMATION', invoice, invoice.invoiceLineItems, load, stops, true);

                // send price confirmation emails to recipients
                let emailsWereSent = false;
                let toEmails = [];
                for (let i = 0; i < data.accountUserIds.length; i++) {
                    const accountUserId = data.accountUserIds[i];
                    const accountUser = employees.find(a => a.id === accountUserId);
                    if (isObjectNotEmpty(accountUser)) {
                        toEmails.push({ email: accountUser.email, name: accountUser.firstName + ' ' + accountUser.lastName });
                    }
                }

                emailsWereSent = await InvoiceUtils.sendPriceConfirmationEmail('info@irisfreight.com', toEmails, invoice, load, stops, newDocument);
                if (emailsWereSent === true) {
                    message.success('Email(s) have been sent.');
                    dispatch(actionCreators.updateInvoice(invoice.id, { status: 'SENT', sentAt: moment(), sentBy: userId }, invoice, true));
                    onCancel();
                } else {
                    message.error('Failed to send email(s)');
                }
            }
            setIsSending(false);
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.updateInvoiceCancel());
        cancel();
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        dispatch(actionCreators.updateInvoiceErrorClear());
    }, []);

    useMemo(() => {
        if (isStringNotEmpty(shipperId)) {
            dispatch(actionCreators.getEmployees(shipperId, false));
        }
    }, [shipperId]);

    useMemo(() => {
        if (isLoadingUpdateInvoice !== null && isLoadingUpdateInvoice === false && errorUpdateInvoice === null) {
            onCancel();
        }
    }, [isLoadingUpdateInvoice, errorUpdateInvoice]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    //#endregion

    if (isObjectNotEmpty(invoice)) {
        return (
            <CanSee entityAction="READ" entityModel="LOAD_PRICE_CONFIRMATION" entityObject={load}>
                <Invoice invoiceId={invoice.id} invoice={invoice} load={load} loadId={loadId} stops={stops} />

                {invoice.status !== 'COMPLETED' && invoice.status !== 'CANCELLED' ? (
                    <>
                        <FormProvider {...methods}>
                            <Form onSubmit={methods.handleSubmit(onSubmitSendInvoice)}>
                                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isSending === true || (isLoadingUpdateInvoice === true && errorUpdateInvoice === null) || loading === true}>
                                    <Fieldset legend="Would you like the send the load tender to the shipper you have assigned to this load?">
                                        <FormItem {...formItemLayout} label="Choose Employee(s) to send Load Tender to" required
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Select
                                                    placeholder="Please Search and Select Employee(s)"
                                                    mode="multiple"
                                                    allowClear={true}
                                                    style={{ width: '100%' }}
                                                    virtual={false}
                                                    onBlur={onBlur}
                                                    onChange={(selected) => { onChange(selected); }}
                                                    value={value}
                                                    name={name}
                                                    showSearch={true}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Employees for you to choose from." />}
                                                    ref={ref}
                                                >
                                                    {employees.map(d => <Option value={d.id} key={d.id}>{d.firstName + ' ' + d.lastName} - Title: {d.title} - Email: {d.email}</Option>)}
                                                </Select>
                                            )}
                                            rules={{ required: 'Required Field' }}
                                            name="accountUserIds"
                                        />
                                        <br />
                                        <b>Employee Doesn't Exist Yet?</b>
                                        <br />
                                        <Button type='primary' onClick={toggleNewEmployee}>Add New Employee to Shipper Account</Button>
                                    </Fieldset>
                                </Spin>
                                {errorUpdateInvoice && <Alert message={`${errorUpdateInvoice}`} type="error" />}
                                <FormButtons cancel={onCancel} disabled={isSending === true || (isLoadingUpdateInvoice === true && errorUpdateInvoice === null)} submitText="Send the Load Tender" />
                            </Form>
                        </FormProvider>
                        <Drawer
                            title={'Add New Employee'}
                            width={fullWidth > 720 ? fullWidth / 2 : 360}
                            onClose={toggleNewEmployee}
                            visible={showNewEmployee}
                            bodyStyle={{ paddingBottom: 80 }}
                            style={{ zIndex: 1000 }}
                            destroyOnClose={true}
                            closable={false}
                            maskClosable={false}
                        >
                            <NewEmployee cancel={toggleNewEmployee} accountId={shipperId} />
                        </Drawer>
                    </>
                ) : null}
            </CanSee>
        );
    } else {
        return null;
    }
};

export default LoadSendPriceConfirmation;