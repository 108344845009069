import React, { useMemo, useState, useEffect } from 'react';
import { Col, Input, Row, Select, Button, Radio, Alert, Spin, InputNumber, Switch, Modal, Drawer } from "antd";
import DatePicker from '../DatePickerAutoAccept/DatePickerAutoAccept';
import UploadFile from "../UploadFile/UploadFile";
import { useForm, FormProvider } from 'react-hook-form';
import Enums from '../../shared/enums';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { orderBy } from "lodash";
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import FormItemFile from '../FormItemFile/FormItemFile';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import DataListRow from '../DataListRow/DataListRow';
import StringFormatter from '../../shared/stringFormatter';
import { ErrorMessage } from '@hookform/error-message';
import { DeleteOutlined, PlusOutlined, DownOutlined } from '@ant-design/icons';
import DataCollapsableRow from '../DataCollapsableRow/DataCollapsableRow';
import MomentDate from '../../shared/dateFormatter';
import moment from 'moment';
import { isListEmpty, isListNotEmpty, isNotNullOrUndefined, isNullOrUndefined, isNumberEmpty, isNumberGreaterThanZero, isNumberNotEmpty, isObjectNotEmpty, isStringEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import NewLocation from '../NewLocation/NewLocation';
import LoadStopUtils from '../../api/utils/loadStopUtils';
import { selectListRecords } from '../../store/utility';
import EntityUtils from '../../api/entityUtils';
import DataRow from '../DataRow/DataRow';
import DataContactInfoRow from '../DataContactInfoRow/DataContactInfoRow';
import FormItemPointOfContact from '../FormItemPointOfContact/FormItemPointOfContact';

const { Option } = Select;
const { TextArea } = Input;
const stringFormatter = new StringFormatter();
const momentDate = new MomentDate();

const NewLoadStop = ({ load, stops, shipperId, carrierId, stopType, sequence, stopTypeSequence, cancel }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    //#endregion
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const methods2 = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            existingBOLNumber: null,
            newBOLNumber: ''
        }
    });
    const methods3 = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            loadingType: null,
            hasLumperFee: false,
            lumperFee: null,
            hasDriverAssist: false,
            driverAssist: null,
            specialInstructions: ''
        }
    });
    const methods4 = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            commodityId: null,
            unitPackagingInitCount: null,
            unitPackaging: null,
            bulkPackagingInitCount: null,
            bulkPackaging: null
        }
    });
    const methods5 = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            locationId: null,
            stopType: null,
            stopNumber: null
        }
    });
    const methods6 = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            apptType: null,
            'apptPointOfContact.firstName': '',
            'apptPointOfContact.lastName': '',
            'apptPointOfContact.email': '',
            'apptPointOfContact.phone': '',
            'apptPointOfContact.phoneExt': '',
            apptInstructions: '',
            apptWindowStartDateTime: null,
            apptWindowEndDateTime: null,
            requestedDateTime: null,
            apptDateTime: null,
            apptNumber: '',
            apptCallAheadDateTime: null,
            pickUpNumber: '',
            purchaseOrderNumber: '',
            dropOffNumber: ''
        }
    });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const entityType = useSelector(state => state.auth.entityType);
    const entityId = useSelector(state => state.auth.entityId);
    const commodities = useSelector(state => state.orchestrator.commodities);
    const locations = useSelector(state => selectListRecords(state.orchestrator.locations, entityType === 'STAFF' ? shipperId : entityId));
    const isLoading = useSelector(state => state.loadStops.isRecordAddLoading);
    const error = useSelector(state => state.loadStops.addRecordError);

    //#endregion
    //#region Enums

    const appointmentTypeOptions = Enums.AppointmentTypes.selectListOptions();
    const unitPackagingOptions = Enums.UnitPackaging.selectListOptions();
    const bulkPackagingOptions = Enums.BulkPackaging.selectListOptions();

    //#endregion
    //#region useStates

    const [loadStop, setLoadStop] = useState({
        id: sequence,
        sequence: sequence,
        stopTypeSequence: stopTypeSequence,
        stopType: stopType
    });
    const [pickUpStops, setPickUpStops] = useState([]);
    const [dropOffStops, setDropOffStops] = useState([]);

    const [pickUpStopsCount, setPickUpStopsCount] = useState(0);
    const [dropOffStopsCount, setDropOffStopsCount] = useState(0);
    const [locationsList, setLocationsList] = useState([]);
    const [showEditStopAppointment, setShowEditStopAppointment] = useState(false);
    const [showEditStopLocation, setShowEditStopLocation] = useState(false);
    const [showAddStopBOL, setShowAddStopBOL] = useState(false);
    const [showEditStopLoadingSpecifics, setShowEditStopLoadingSpecifics] = useState(false);
    const [showAddStopCommodity, setShowAddStopCommodity] = useState(false);
    const [bolNumberList, setBOLNumberList] = useState([]);
    const [bolDocFiles, setBOLDocFiles] = useState([]);
    const [showNewBOLNumber, setShowNewBOLNumber] = useState(false);
    const [docFiles, setDocFiles] = useState([]);
    const [showFirstComeFirstServeDetails, setShowFirstComeFirstServeDetails] = useState(false);
    const [showHaveAppointmentDetails, setShowHaveAppointmentDetails] = useState(false);
    const [showNeedAppointmentDetails, setShowNeedAppointmentDetails] = useState(false);
    const [lumperFeeDisabled, setLumperFeeDisabled] = useState(true);
    const [driverAssistDisabled, setDriverAssistDisabled] = useState(true);
    const [dontRequireLoadSpecificInformation, setDontRequireLoadSpecificInformation] = useState(null); // use null as 3rd/initial state so we know if the value has been set yet
    const [showNewLocation, setShowNewLocation] = useState(false);

    //#endregion
    //#region toggles

    const toggleAddStopBOL = () => {
        // reset form
        setBOLDocFiles([]);
        setShowNewBOLNumber(false);
        methods2.setValue(`existingBOLNumber`, null);
        methods2.setValue(`newBOLNumber`, '');
        methods2.clearErrors();

        setShowAddStopBOL(!showAddStopBOL);
    };

    const toggleEditStopLoadingSpecifics = () => {
        setDriverAssistDisabled(true);
        setLumperFeeDisabled(true);

        // reset form
        methods3.setValue('loadingType', null);
        methods3.setValue('hasLumperFee', false);
        methods3.setValue('lumperFee', null);
        methods3.setValue('hasDriverAssist', false);
        methods3.setValue('driverAssist', null);
        methods3.setValue('specialInstructions', '');
        methods3.clearErrors();

        setShowEditStopLoadingSpecifics(!showEditStopLoadingSpecifics);
    };

    const toggleAddStopCommodity = () => {
        // reset form
        methods4.setValue('commodityId', null);
        methods4.setValue('unitPackagingInitCount', null);
        methods4.setValue('unitPackaging', null);
        methods4.setValue('bulkPackagingInitCount', null);
        methods4.setValue('bulkPackaging', null);
        methods4.clearErrors();

        setShowAddStopCommodity(!showAddStopCommodity);
    };

    const toggleEditStopAppointment = () => {
        setShowFirstComeFirstServeDetails(false);
        setShowHaveAppointmentDetails(false);
        setShowNeedAppointmentDetails(false);

        // reset form
        methods6.setValue('apptType', null);
        methods6.setValue('apptPointOfContact.firstName', '');
        methods6.setValue('apptPointOfContact.lastName', '');
        methods6.setValue('apptPointOfContact.email', '');
        methods6.setValue('apptPointOfContact.phone', '');
        methods6.setValue('apptPointOfContact.phoneExt', '');
        methods6.setValue('apptInstructions', '');
        methods6.setValue('apptWindowStartDateTime', null);
        methods6.setValue('apptWindowEndDateTime', null);
        methods6.setValue('requestedDateTime', null);
        methods6.setValue('apptDateTime', null);
        methods6.setValue('apptNumber', '');
        methods6.setValue('apptCallAheadDateTime', null);
        methods6.setValue('pickUpNumber', '');
        methods6.setValue('purchaseOrderNumber', '');
        methods6.setValue('dropOffNumber', '');
        methods6.clearErrors();

        setShowEditStopAppointment(!showEditStopAppointment);
    };

    const toggleEditStopLocation = () => {
        // reset form
        methods5.setValue('locationId', null);
        methods5.setValue('stopType', null);
        methods5.setValue('stopNumber', null);
        methods5.clearErrors();

        setShowEditStopLocation(!showEditStopLocation);
    };

    const toggleNewLocation = () => {
        setShowNewLocation(!showNewLocation);
    };

    //#endregion
    //#region submits and validation

    const onEditStopLocation = (data) => {
        const stopLocationId = data.locationId;

        let stopLocation = EntityUtils.getLocationInfo(stopLocationId, locationsList);
        if (isObjectNotEmpty(stopLocation)) {
            const updatedValues = {
                stopLocation: stopLocation,
                stopLocationId: stopLocationId,
                timeZone: stopLocation.timeZone
            };

            if (isNumberGreaterThanZero(stopLocation.lumperFee)) {
                updatedValues.lumperFee = stopLocation.lumperFee;
                updatedValues.hasLumperFee = true;
            } else {
                updatedValues.hasLumperFee = false;
            }
            if (isStringNotEmpty(stopLocation.specialInstructions)) {
                updatedValues.specialInstructions = stopLocation.specialInstructions;
            }
            if (isObjectNotEmpty(stopLocation.pointOfContact)) {
                updatedValues.apptPointOfContact = stopLocation.pointOfContact;
            }

            const updatedRecord = {
                ...loadStop,
                ...updatedValues
            };

            setLoadStop({ ...updatedRecord });

            toggleEditStopLocation();
        } else {
            return;
        }
    };

    const onEditStopAppointment = (data) => {
        const apptType = data.apptType;
        const apptPointOfContact = data.apptPointOfContact;
        const apptInstructions = data.apptInstructions;
        const apptWindowStartDateTime = data.apptWindowStartDateTime;
        const apptWindowEndDateTime = data.apptWindowEndDateTime;
        const apptDateTime = data.apptDateTime;
        const apptNumber = data.apptNumber;
        const requestedDateTime = data.requestedDateTime;
        const apptCallAheadDateTime = data.apptCallAheadDateTime;
        const pickUpNumber = data.pickUpNumber;
        const purchaseOrderNumber = data.purchaseOrderNumber;
        const dropOffNumber = data.dropOffNumber;

        const updatedValues = {
            apptType: apptType,
            apptPointOfContact: apptPointOfContact
        };

        if (isStringNotEmpty(pickUpNumber)) {
            updatedValues.pickUpNumber = pickUpNumber;
        }
        if (isStringNotEmpty(purchaseOrderNumber)) {
            updatedValues.purchaseOrderNumber = purchaseOrderNumber;
        }
        if (isStringNotEmpty(dropOffNumber)) {
            updatedValues.dropOffNumber = dropOffNumber;
        }

        if (isStringNotEmpty(apptInstructions)) {
            updatedValues.apptInstructions = apptInstructions;
        }

        if (apptType === 'FIRST_COME_FIRST_SERVE') {
            if (apptWindowEndDateTime < apptWindowStartDateTime) {
                // console.log(apptWindowEndDateTime);
                // console.log(apptWindowStartDateTime);
                methods6.setError("apptWindowEndDateTime", { type: "validate", message: "The End Date/Time cannot be before the Start Date/Time." });

                return;
            } else {
                methods6.clearErrors('apptWindowEndDateTime');
            }
            updatedValues.apptWindowStartDateTime = apptWindowStartDateTime;
            updatedValues.apptWindowEndDateTime = apptWindowEndDateTime;
            updatedValues.requestedDateTime = apptWindowStartDateTime;
            updatedValues.apptDateTime = undefined;
            updatedValues.apptNumber = undefined;
            updatedValues.apptCallAheadDateTime = undefined;
        } else if (apptType === 'HAVE_APPOINTMENT') {
            updatedValues.apptDateTime = apptDateTime;
            if (isStringNotEmpty(apptNumber)) {
                updatedValues.apptNumber = apptNumber;
            }
            updatedValues.requestedDateTime = apptDateTime;
            updatedValues.apptWindowStartDateTime = undefined;
            updatedValues.apptWindowEndDateTime = undefined;
            updatedValues.apptCallAheadDateTime = undefined;
        } else if (apptType === 'NEED_APPOINTMENT') {
            updatedValues.apptCallAheadDateTime = apptCallAheadDateTime;
            updatedValues.requestedDateTime = requestedDateTime;
            updatedValues.apptDateTime = undefined;
            updatedValues.apptNumber = undefined;
            updatedValues.apptWindowStartDateTime = undefined;
            updatedValues.apptWindowEndDateTime = undefined;
        }

        const updatedRecord = {
            ...loadStop,
            ...updatedValues
        };

        setLoadStop({ ...updatedRecord });

        toggleEditStopAppointment();
    };

    const onAddStopBOL = (data) => {
        const newBOLNumber = data.newBOLNumber;
        const existingBOLNumber = data.existingBOLNumber;

        methods2.clearErrors();

        let bolNumber = null;
        if (isStringNotEmpty(existingBOLNumber) && existingBOLNumber !== 'NEW') {
            bolNumber = existingBOLNumber;
        } else if (isStringNotEmpty(newBOLNumber)) {
            bolNumber = newBOLNumber;
        }

        if (isStringNotEmpty(bolNumber)) {
            if (isListNotEmpty(bolNumberList)) {
                if (bolNumberList.length > dropOffStopsCount) {
                    methods2.setError('existingBOLNumber', { type: 'validateBOLCount', message: 'You cannot add more BOL Numbers than the total number of Drop-Offs for this Load.' });
                }
            }

            let stopBOLNumberList = [];
            if (isListNotEmpty(loadStop.bolNumbers)) {
                stopBOLNumberList = [...loadStop.bolNumbers];
                if (stopBOLNumberList.find(b => b === bolNumber) === undefined) {
                    stopBOLNumberList.push(bolNumber);
                } else {
                    methods2.setError('newBOLNumber', { type: "duplicate", message: 'This BOL Number has already been added to this location.' });
                    return;
                }
            } else {
                stopBOLNumberList.push(bolNumber);
            }

            if (isListNotEmpty(bolDocFiles)) {
                let newFiles = [];
                bolDocFiles.forEach((file) => {
                    // console.log('file:');
                    // console.log(file);
                    let fileJson = { bolNumber: bolNumber, file: file };
                    newFiles.push(fileJson);
                });
                setDocFiles(oldFiles => [...oldFiles, ...newFiles]);
                setBOLDocFiles([]);
            }

            const updatedValues = {
                bolNumbers: [...stopBOLNumberList]
            };

            const updatedRecord = {
                ...loadStop,
                ...updatedValues
            };

            setLoadStop({ ...updatedRecord });

            toggleAddStopBOL();
        } else {
            methods2.setError('existingBOLNumber', { type: 'required', message: 'Required Field' });
            return;
        }
    };

    const onRemoveStopBOL = (bolNumber, index) => {
        let stopBOLNumberList = [];
        if (isListNotEmpty(loadStop.bolNumbers)) {
            stopBOLNumberList = [...loadStop.bolNumbers];
            stopBOLNumberList.splice(index, 1);

            // also remove the BOL Files for this BOL Number for this stop
            let filteredArray = docFiles.filter(d => d.bolNumber !== bolNumber);
            setDocFiles([...filteredArray]);
        }

        const updatedRecord = {
            ...loadStop,
            bolNumbers: [...stopBOLNumberList]
        };

        setLoadStop({ ...updatedRecord });
    };

    const onEditStopLoadingSpecifics = (data) => {
        const loadingType = data.loadingType;
        const specialInstructions = data.specialInstructions;
        const hasLumperFee = data.hasLumperFee;
        const lumperFee = data.lumperFee;
        const hasDriverAssist = data.hasDriverAssist;
        const driverAssist = data.driverAssist;

        const updatedValues = {
            loadingType: loadingType,
            hasLumperFee: hasLumperFee,
            lumperFee: lumperFee,
            hasDriverAssist: hasDriverAssist,
            driverAssist: driverAssist,
            specialInstructions: specialInstructions
        };

        const updatedRecord = {
            ...loadStop,
            ...updatedValues
        };

        setLoadStop({ ...updatedRecord });

        toggleEditStopLoadingSpecifics();
    };

    const onAddStopCommodity = (data) => {
        const commodityId = data.commodityId;
        const unitPackagingInitCount = data.unitPackagingInitCount;
        const unitPackaging = data.unitPackaging;
        const bulkPackagingInitCount = data.bulkPackagingInitCount;
        const bulkPackaging = data.bulkPackaging;

        methods4.clearErrors();

        let name = null;
        if (isListNotEmpty(commodities)) {
            let commodity = commodities.find(c => c.id === commodityId);
            if (isObjectNotEmpty(commodity)) {
                name = commodity.name;
            }
        }

        let newStopCommodity = {
            commodityId: commodityId,
            name: name
        };

        if (isNumberGreaterThanZero(unitPackagingInitCount)) {
            if (isStringEmpty(unitPackaging)) {
                methods4.setError('unitPackaging', { type: "validate", message: 'You must provide a Unit of Measure/Unit Packaging if you are going to provide a Unit Quantity for this Commodity.' });
                return;
            } else {
                newStopCommodity.unitPackagingInitCount = unitPackagingInitCount;
                newStopCommodity.unitPackaging = unitPackaging;
            }
        } else if ((isNumberEmpty(unitPackagingInitCount) || unitPackagingInitCount === 0) && isStringNotEmpty(unitPackaging)) {
            methods4.setError('unitPackagingInitCount', { type: "validate", message: 'You must provide a Unit Quantity greater than 0 if you are going to provide a Unit of Measure/Unit Packaging for this Commodity.' });
            return;
        }

        if (isNumberGreaterThanZero(bulkPackagingInitCount)) {
            if (isStringEmpty(bulkPackaging)) {
                methods4.setError('bulkPackaging', { type: "validate", message: 'You must provide Bulk Packaging if you are going to provide a Bulk Quantity for this Commodity.' });
                return;
            } else {
                newStopCommodity.bulkPackagingInitCount = bulkPackagingInitCount;
                newStopCommodity.bulkPackaging = bulkPackaging;
            }
        } else if ((isNumberEmpty(bulkPackagingInitCount) || bulkPackagingInitCount === 0) && isStringNotEmpty(bulkPackaging)) {
            methods4.setError('bulkPackagingInitCount', { type: "validate", message: 'You must provide a Bulk Quantity greater than 0 if you are going to provide Bulk Packaging for this Commodity.' });
            return;
        }

        //console.log(newStopCommodity);

        let stopCommodities = [];
        if (isListNotEmpty(loadStop.commodities)) {
            stopCommodities = [...loadStop.commodities];
            if (stopCommodities.find(c => c.commodityId === commodityId && c.bulkPackaging === bulkPackaging && c.unitPackaging === unitPackaging) === undefined) {
                stopCommodities.push(newStopCommodity);
            } else {
                methods4.setError('commodityId', { type: "validate", message: 'This commodity has already been added to this location with the unit and bulk packaging you provided.' });
                return;
            }
        } else {
            stopCommodities.push(newStopCommodity);
        }

        //console.log(stopCommodities);

        const updatedRecord = {
            ...loadStop,
            commodities: [...stopCommodities]
        };

        setLoadStop({ ...updatedRecord });

        toggleAddStopCommodity();
    };

    const onRemoveStopCommodity = (index) => {
        let stopCommodities = [];
        if (isListNotEmpty(loadStop.commodities)) {
            stopCommodities = [...loadStop.commodities];
            stopCommodities.splice(index, 1);
        }

        const updatedRecord = {
            ...loadStop,
            commodities: [...stopCommodities]
        };

        setLoadStop({ ...updatedRecord });
    };

    const validateStop = (newStop, pickUps, dropOffs) => {
        let hasErrors = false;
        methods.clearErrors();

        let errors = {};
        if (isObjectNotEmpty(newStop)) {
            if (isStringEmpty(newStop.stopLocationId)) {
                errors.missingStopLocationId = `Stop is missing Location.`;
                hasErrors = true;
            }

            if (isStringEmpty(newStop.apptType)) {
                errors.missingApptType = `Stop is missing Appointment Details.`;
                hasErrors = true;
            }

            if (isListEmpty(newStop.bolNumbers) && dontRequireLoadSpecificInformation === false) {
                errors.missingBOLNumber = `Stop is missing a BOL Number.`;
                hasErrors = true;
            }

            if (isStringEmpty(newStop.stopType === undefined)) {
                errors.missingStopType = `Stop is missing a Stop Type.`;
                hasErrors = true;
            } else if (newStop.stopType === 'PICK_UP') {
                if (isNullOrUndefined(newStop.requestedDateTime === undefined)) {
                    errors.missingRequestedDateTime = `Stop is missing a Pick-Up Date.`;
                    hasErrors = true;
                }

                // check to make sure new stop is in order with the other pick-ups if there are any and the drop-offs
                if (isListNotEmpty(pickUps)) {
                    pickUps.splice(newStop.stopTypeSequence, 0, newStop);
                    // re-index the pick-ups
                    let reIndexedUpdatedPickUps = pickUps.map((stop, stopIndex) => {
                        return {
                            ...stop,
                            sequence: stopIndex,
                            id: stopIndex,
                            stopTypeSequence: stopIndex
                        }
                    });

                    // validate date to make sure pick-ups are in order
                    if (newStop.stopTypeSequence > 0) {
                        // make sure new stop's date is after the stop before it
                        let stop1 = isObjectNotEmpty(reIndexedUpdatedPickUps[newStop.stopTypeSequence - 1]) ? { ...reIndexedUpdatedPickUps[newStop.stopTypeSequence - 1] } : null;
                        let stop2 = { ...newStop };
                        if (isObjectNotEmpty(stop1) && moment(stop2.requestedDateTime) < moment(stop1.requestedDateTime)) {
                            if (stop1.apptType === 'NEED_APPOINTMENT' || stop2.apptType === 'NEED_APPOINTMENT') {
                                let stop1DateString = momentDate.fromUtcToTimeZoneDateOnly(stop1.requestedDateTime, stop1.timeZone);
                                let stop2DateString = momentDate.fromUtcToTimeZoneDateOnly(stop2.requestedDateTime, stop2.timeZone);
                                if (moment(stop2DateString) < moment(stop1DateString)) {
                                    errors.validateRequestedDateTime = `The date for this stop cannot be before the date for Pick-Up ${newStop.stopTypeSequence}.`;
                                    hasErrors = true;
                                }
                            } else {
                                errors.validateRequestedDateTime = `The date for this stop cannot be before the date for Pick-Up ${newStop.stopTypeSequence}.`;
                                hasErrors = true;
                            }
                        }

                        // if there is a pick-up after the new stop, make sure the new stop's date is before it
                        if (newStop.stopTypeSequence < (reIndexedUpdatedPickUps.length - 1)) {
                            let stop1 = { ...newStop };
                            let stop2 = isObjectNotEmpty(reIndexedUpdatedPickUps[newStop.stopTypeSequence + 1]) ? { ...reIndexedUpdatedPickUps[newStop.stopTypeSequence + 1] } : null;
                            if (isObjectNotEmpty(stop2) && moment(stop1.requestedDateTime) > moment(stop2.requestedDateTime)) {
                                if (stop1.apptType === 'NEED_APPOINTMENT' || stop2.apptType === 'NEED_APPOINTMENT') {
                                    let stop1DateString = momentDate.fromUtcToTimeZoneDateOnly(stop1.requestedDateTime, stop1.timeZone);
                                    let stop2DateString = momentDate.fromUtcToTimeZoneDateOnly(stop2.requestedDateTime, stop2.timeZone);
                                    if (moment(stop2DateString) < moment(stop1DateString)) {
                                        errors.validateRequestedDateTime = `The date for this stop cannot be after the date for Pick-Up ${newStop.stopTypeSequence + 2}.`;
                                        hasErrors = true;
                                    }
                                } else {
                                    errors.validateRequestedDateTime = `The date for this stop cannot be after the date for Pick-Up ${newStop.stopTypeSequence + 2}.`;
                                    hasErrors = true;
                                }
                            }
                        }
                    } else if (reIndexedUpdatedPickUps.length > 1) {
                        // new stop is the first pick-up
                        let stop1 = { ...newStop };
                        let stop2 = isObjectNotEmpty(reIndexedUpdatedPickUps[newStop.stopTypeSequence + 1]) ? { ...reIndexedUpdatedPickUps[newStop.stopTypeSequence + 1] } : null;
                        if (isObjectNotEmpty(stop2) && moment(stop1.requestedDateTime) > moment(stop2.requestedDateTime)) {
                            if (stop1.apptType === 'NEED_APPOINTMENT' || stop2.apptType === 'NEED_APPOINTMENT') {
                                let stop1DateString = momentDate.fromUtcToTimeZoneDateOnly(stop1.requestedDateTime, stop1.timeZone);
                                let stop2DateString = momentDate.fromUtcToTimeZoneDateOnly(stop2.requestedDateTime, stop2.timeZone);
                                if (moment(stop2DateString) < moment(stop1DateString)) {
                                    errors.validateRequestedDateTime = `The date for this stop cannot be after the date for Pick-Up ${newStop.stopTypeSequence + 2}.`;
                                    hasErrors = true;
                                }
                            } else {
                                errors.validateRequestedDateTime = `The date for this stop cannot be after the date for Pick-Up ${newStop.stopTypeSequence + 2}.`;
                                hasErrors = true;
                            }
                        }
                    }

                    // if new stop is the last stop in the pick-ups then also make sure it is before the first drop-off stop
                    if (newStop.stopTypeSequence === (reIndexedUpdatedPickUps.length - 1)) {
                        if (isListNotEmpty(dropOffs)) {
                            let stop1 = { ...newStop };
                            let stop2 = { ...dropOffs[0] };
                            if (moment(stop1.requestedDateTime) > moment(stop2.requestedDateTime)) {
                                if (stop1.apptType === 'NEED_APPOINTMENT' || stop2.apptType === 'NEED_APPOINTMENT') {
                                    let stop1DateString = momentDate.fromUtcToTimeZoneDateOnly(stop1.requestedDateTime, stop1.timeZone);
                                    let stop2DateString = momentDate.fromUtcToTimeZoneDateOnly(stop2.requestedDateTime, stop2.timeZone);
                                    if (moment(stop2DateString) < moment(stop1DateString)) {
                                        if (dropOffs.length > 1) {
                                            errors.validateRequestedDateTimeWithDropOff = `The date for this stop cannot be after the date for Drop-Off 1.`;
                                            hasErrors = true;
                                        } else {
                                            errors.validateRequestedDateTimeWithDropOff = `The date for this stop cannot be after the date for Drop-Off.`;
                                            hasErrors = true;
                                        }
                                    }
                                } else {
                                    if (dropOffs.length > 1) {
                                        errors.validateRequestedDateTimeWithDropOff = `The date for this stop cannot be after the date for Drop-Off 1.`;
                                        hasErrors = true;
                                    } else {
                                        errors.validateRequestedDateTimeWithDropOff = `The date for this stop cannot be after the date for Drop-Off.`;
                                        hasErrors = true;
                                    }
                                }
                            }
                        }
                    }
                } else {
                    // new stop is the only pick-up so compare it to the drop-offs
                    if (isListNotEmpty(dropOffs)) {
                        let stop1 = { ...newStop };
                        let stop2 = { ...dropOffs[0] };
                        if (moment(stop1.requestedDateTime) > moment(stop2.requestedDateTime)) {
                            if (stop1.apptType === 'NEED_APPOINTMENT' || stop2.apptType === 'NEED_APPOINTMENT') {
                                let stop1DateString = momentDate.fromUtcToTimeZoneDateOnly(stop1.requestedDateTime, stop1.timeZone);
                                let stop2DateString = momentDate.fromUtcToTimeZoneDateOnly(stop2.requestedDateTime, stop2.timeZone);
                                if (moment(stop2DateString) < moment(stop1DateString)) {
                                    if (dropOffs.length > 1) {
                                        errors.validateRequestedDateTimeWithDropOff = `The date for this stop cannot be after the date for Drop-Off 1.`;
                                        hasErrors = true;
                                    } else {
                                        errors.validateRequestedDateTimeWithDropOff = `The date for this stop cannot be after the date for Drop-Off.`;
                                        hasErrors = true;
                                    }
                                }
                            } else {
                                if (dropOffs.length > 1) {
                                    errors.validateRequestedDateTimeWithDropOff = `The date for this stop cannot be after the date for Drop-Off 1.`;
                                    hasErrors = true;
                                } else {
                                    errors.validateRequestedDateTimeWithDropOff = `The date for this stop cannot be after the date for Drop-Off.`;
                                    hasErrors = true;
                                }
                            }
                        }
                    }
                }

                if (isStringEmpty(newStop.loadingType)) {
                    errors.missingLoadingType = `Stop is missing a Loading Type.`;
                    hasErrors = true;
                }

                if (isStringEmpty(newStop.pickUpNumber) && dontRequireLoadSpecificInformation === false) {
                    errors.missingPickUpNumber = `Stop is missing a Pick-Up Number.`;
                    hasErrors = true;
                }
            } else if (newStop.stopType === 'DROP_OFF') {
                if (isNullOrUndefined(newStop.requestedDateTime)) {
                    errors.missingRequestedDateTime = `Stop is missing a Drop-Off Date.`;
                    hasErrors = true;
                }

                // check to make sure new stop is in order with the other drop-offs if there are any and the pick-ups
                if (isListNotEmpty(dropOffs)) {
                    dropOffs.splice(newStop.stopTypeSequence, 0, newStop);
                    // re-index the drop-offs
                    let reIndexedUpdatedDropOffs = dropOffs.map((stop, stopIndex) => {
                        return {
                            ...stop,
                            sequence: stopIndex + pickUps.length,
                            id: stopIndex + pickUps.length,
                            stopTypeSequence: stopIndex
                        }
                    });

                    // validate date to make sure drop-offs are in order
                    if (newStop.stopTypeSequence > 0) {
                        // make sure new stop's date is after the stop before it
                        let stop1 = isObjectNotEmpty(reIndexedUpdatedDropOffs[newStop.stopTypeSequence - 1]) ? { ...reIndexedUpdatedDropOffs[newStop.stopTypeSequence - 1] } : null;
                        let stop2 = { ...newStop };
                        if (isObjectNotEmpty(stop1) && moment(stop2.requestedDateTime) < moment(stop1.requestedDateTime)) {
                            if (stop1.apptType === 'NEED_APPOINTMENT' || stop2.apptType === 'NEED_APPOINTMENT') {
                                let stop1DateString = momentDate.fromUtcToTimeZoneDateOnly(stop1.requestedDateTime, stop1.timeZone);
                                let stop2DateString = momentDate.fromUtcToTimeZoneDateOnly(stop2.requestedDateTime, stop2.timeZone);
                                if (moment(stop2DateString) < moment(stop1DateString)) {
                                    errors.validateRequestedDateTime = `The date for this stop cannot be before the date for Drop-Off ${newStop.stopTypeSequence}.`;
                                    hasErrors = true;
                                }
                            } else {
                                errors.validateRequestedDateTime = `The date for this stop cannot be before the date for Drop-Off ${newStop.stopTypeSequence}.`;
                                hasErrors = true;
                            }
                        }

                        // if there is a drop-off after the new stop, make sure the new stop's date is before it
                        if (newStop.stopTypeSequence < (reIndexedUpdatedDropOffs.length - 1)) {
                            let stop1 = { ...newStop };
                            let stop2 = isObjectNotEmpty(reIndexedUpdatedDropOffs[newStop.stopTypeSequence + 1]) ? { ...reIndexedUpdatedDropOffs[newStop.stopTypeSequence + 1] } : null;
                            if (isObjectNotEmpty(stop2) && moment(stop1.requestedDateTime) > moment(stop2.requestedDateTime)) {
                                if (stop1.apptType === 'NEED_APPOINTMENT' || stop2.apptType === 'NEED_APPOINTMENT') {
                                    let stop1DateString = momentDate.fromUtcToTimeZoneDateOnly(stop1.requestedDateTime, stop1.timeZone);
                                    let stop2DateString = momentDate.fromUtcToTimeZoneDateOnly(stop2.requestedDateTime, stop2.timeZone);
                                    if (moment(stop2DateString) < moment(stop1DateString)) {
                                        errors.validateRequestedDateTime = `The date for this stop cannot be after the date for Drop-Off ${newStop.stopTypeSequence + 2}.`;
                                        hasErrors = true;
                                    }
                                } else {
                                    errors.validateRequestedDateTime = `The date for this stop cannot be after the date for Drop-Off ${newStop.stopTypeSequence + 2}.`;
                                    hasErrors = true;
                                }
                            }
                        }
                    } else if (reIndexedUpdatedDropOffs.length > 1) {
                        // new stop is the first drop-off
                        let stop1 = { ...newStop };
                        let stop2 = isObjectNotEmpty(reIndexedUpdatedDropOffs[newStop.stopTypeSequence + 1]) ? { ...reIndexedUpdatedDropOffs[newStop.stopTypeSequence + 1] } : null;
                        if (isObjectNotEmpty(stop2) && moment(stop1.requestedDateTime) > moment(stop2.requestedDateTime)) {
                            if (stop1.apptType === 'NEED_APPOINTMENT' || stop2.apptType === 'NEED_APPOINTMENT') {
                                let stop1DateString = momentDate.fromUtcToTimeZoneDateOnly(stop1.requestedDateTime, stop1.timeZone);
                                let stop2DateString = momentDate.fromUtcToTimeZoneDateOnly(stop2.requestedDateTime, stop2.timeZone);
                                if (moment(stop2DateString) < moment(stop1DateString)) {
                                    errors.validateRequestedDateTime = `The date for this stop cannot be after the date for Drop-Off ${newStop.stopTypeSequence + 2}.`;
                                    hasErrors = true;
                                }
                            } else {
                                errors.validateRequestedDateTime = `The date for this stop cannot be after the date for Drop-Off ${newStop.stopTypeSequence + 2}.`;
                                hasErrors = true;
                            }
                        }
                    }

                    // if new stop is the first stop in the drop-offs then also make sure it is after the last pick-up stop
                    if (newStop.stopTypeSequence === 0) {
                        if (isListNotEmpty(pickUps)) {
                            let stop1 = isObjectNotEmpty(pickUps[pickUps.length - 1]) ? { ...pickUps[pickUps.length - 1] } : null;
                            let stop2 = { ...newStop };
                            if (isObjectNotEmpty(stop1) && moment(stop2.requestedDateTime) < moment(stop1.requestedDateTime)) {
                                if (stop1.apptType === 'NEED_APPOINTMENT' || stop2.apptType === 'NEED_APPOINTMENT') {
                                    let stop1DateString = momentDate.fromUtcToTimeZoneDateOnly(stop1.requestedDateTime, stop1.timeZone);
                                    let stop2DateString = momentDate.fromUtcToTimeZoneDateOnly(stop2.requestedDateTime, stop2.timeZone);
                                    if (moment(stop2DateString) < moment(stop1DateString)) {
                                        if (pickUps.length > 1) {
                                            errors.validateRequestedDateTimeWithPickUp = `The date for this stop cannot be before the date for Pick-Up ${pickUps.length}.`;
                                            hasErrors = true;
                                        } else {
                                            errors.validateRequestedDateTimeWithPickUp = `The date for this stop cannot be before the date for Pick-Up.`;
                                            hasErrors = true;
                                        }
                                    }
                                } else {
                                    if (pickUps.length > 1) {
                                        errors.validateRequestedDateTimeWithPickUp = `The date for this stop cannot be before the date for Pick-Up ${pickUps.length}.`;
                                        hasErrors = true;
                                    } else {
                                        errors.validateRequestedDateTimeWithPickUp = `The date for this stop cannot be before the date for Pick-Up.`;
                                        hasErrors = true;
                                    }
                                }
                            }
                        }
                    }
                } else {
                    // new stop is the only drop-off so compare it to the pick-ups
                    if (isListNotEmpty(pickUps)) {
                        let stop1 = isObjectNotEmpty(pickUps[pickUps.length - 1]) ? { ...pickUps[pickUps.length - 1] } : null;
                        let stop2 = { ...newStop };
                        if (isObjectNotEmpty(stop1) && moment(stop2.requestedDateTime) < moment(stop1.requestedDateTime)) {
                            if (stop1.apptType === 'NEED_APPOINTMENT' || stop2.apptType === 'NEED_APPOINTMENT') {
                                let stop1DateString = momentDate.fromUtcToTimeZoneDateOnly(stop1.requestedDateTime, stop1.timeZone);
                                let stop2DateString = momentDate.fromUtcToTimeZoneDateOnly(stop2.requestedDateTime, stop2.timeZone);
                                if (moment(stop2DateString) < moment(stop1DateString)) {
                                    if (pickUps.length > 1) {
                                        errors.validateRequestedDateTimeWithPickUp = `The date for this stop cannot be before the date for Pick-Up ${pickUps.length}.`;
                                        hasErrors = true;
                                    } else {
                                        errors.validateRequestedDateTimeWithPickUp = `The date for this stop cannot be before the date for Pick-Up.`;
                                        hasErrors = true;
                                    }
                                }
                            } else {
                                if (pickUps.length > 1) {
                                    errors.validateRequestedDateTimeWithPickUp = `The date for this stop cannot be before the date for Pick-Up ${pickUps.length}.`;
                                    hasErrors = true;
                                } else {
                                    errors.validateRequestedDateTimeWithPickUp = `The date for this stop cannot be before the date for Pick-Up.`;
                                    hasErrors = true;
                                }
                            }
                        }
                    }
                }

                if (isStringEmpty(newStop.loadingType)) {
                    errors.missingLoadingType = `Stop is missing an Unloading Type.`;
                    hasErrors = true;
                }

                if (isStringEmpty(newStop.dropOffNumber) && dontRequireLoadSpecificInformation === false) {
                    errors.missingDropOffNumber = `Stop is missing a Drop-Off Number.`;
                    hasErrors = true;
                }

                if (isStringEmpty(newStop.purchaseOrderNumber) && dontRequireLoadSpecificInformation === false) {
                    errors.missingPurchaseOrderNumber = `Stop is missing a Customer Purchase Order Number.`;
                    hasErrors = true;
                }
            }

            // validate BOL Numbers
            // the Add BOL functionality will force the numbr of BOL Numbers to match the number of drop-off stops
            if (bolNumberList.length !== dropOffStopsCount && dontRequireLoadSpecificInformation === false) {
                errors.badBOLNumberCount = 'There does not appear to be a unique BOL Number for each Drop-Off. Please resolve the issue and try again.';
                hasErrors = true;
            }
        }

        if (isObjectNotEmpty(errors)) {
            methods.setError(`loadStop`, { types: { ...errors } });
        }

        return !hasErrors;
    };

    const onSubmit = () => {
        if (validateStop(loadStop, pickUpStops, dropOffStops)) {
            let payload = { ...loadStop };
            dispatch(actionCreators.addLoadStop(payload, docFiles, load));
        } else {
            return;
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.addLoadStopCancel());
        cancel();
    };

    //#endregion
    //#region useEffects and useMemos

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.addLoadStopErrorClear());
        dispatch(actionCreators.getCommodities());
    }, []);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (showEditStopAppointment === true && isObjectNotEmpty(loadStop)) {
                if (isStringNotEmpty(loadStop.apptType)) {
                    methods6.setValue('apptType', loadStop.apptType);

                    if (isStringNotEmpty(loadStop.stopType)) {
                        if (loadStop.stopType === "PICK_UP") {
                            if (isStringNotEmpty(loadStop.pickUpNumber)) {
                                methods6.setValue('pickUpNumber', loadStop.pickUpNumber);
                            }
                        } else if (loadStop.stopType === "DROP_OFF") {
                            if (isStringNotEmpty(loadStop.purchaseOrderNumber)) {
                                methods6.setValue('purchaseOrderNumber', loadStop.purchaseOrderNumber);
                            }
                            if (isStringNotEmpty(loadStop.dropOffNumber)) {
                                methods6.setValue('dropOffNumber', loadStop.dropOffNumber);
                            }
                        }
                    }

                    if (loadStop.apptType === "FIRST_COME_FIRST_SERVE") {
                        setShowFirstComeFirstServeDetails(true);
                        setShowHaveAppointmentDetails(false);
                        setShowNeedAppointmentDetails(false);
                    } else if (loadStop.apptType === "HAVE_APPOINTMENT") {
                        setShowFirstComeFirstServeDetails(false);
                        setShowHaveAppointmentDetails(true);
                        setShowNeedAppointmentDetails(false);
                    } else if (loadStop.apptType === "NEED_APPOINTMENT") {
                        setShowFirstComeFirstServeDetails(false);
                        setShowHaveAppointmentDetails(false);
                        setShowNeedAppointmentDetails(true);
                    } else {
                        setShowFirstComeFirstServeDetails(false);
                        setShowHaveAppointmentDetails(false);
                        setShowNeedAppointmentDetails(false);
                    }
                }
                if (isObjectNotEmpty(loadStop.apptPointOfContact)) {
                    if (isStringNotEmpty(loadStop.apptPointOfContact.name)) {
                        methods6.setValue('name', loadStop.apptPointOfContact.name);
                    }
                    if (isStringNotEmpty(loadStop.apptPointOfContact.email)) {
                        methods6.setValue('email', loadStop.apptPointOfContact.email);
                    }
                    if (isStringNotEmpty(loadStop.apptPointOfContact.phone)) {
                        methods6.setValue('phone', loadStop.apptPointOfContact.phone);
                    }
                    if (isStringNotEmpty(loadStop.apptPointOfContact.phoneExt)) {
                        methods6.setValue('phoneExt', loadStop.apptPointOfContact.phoneExt);
                    }
                }
                if (isStringNotEmpty(loadStop.apptInstructions)) {
                    methods6.setValue('apptInstructions', loadStop.apptInstructions);
                }
            }
        }

        return () => isSubscribed = false;
    }, [showEditStopAppointment, loadStop]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (showFirstComeFirstServeDetails === true && isObjectNotEmpty(loadStop)) {
                if (isStringNotEmpty(loadStop.apptType) && loadStop.apptType === "FIRST_COME_FIRST_SERVE") {
                    if (isNotNullOrUndefined(loadStop.apptWindowStartDateTime)) {
                        methods6.setValue(`apptWindowStartDateTime`, loadStop.apptWindowStartDateTime);
                    }
                    if (isNotNullOrUndefined(loadStop.apptWindowEndDateTime)) {
                        methods6.setValue(`apptWindowEndDateTime`, loadStop.apptWindowEndDateTime);
                    }
                }
            }
        }

        return () => isSubscribed = false;
    }, [showFirstComeFirstServeDetails, loadStop]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (showHaveAppointmentDetails === true && isObjectNotEmpty(loadStop)) {
                if (isStringNotEmpty(loadStop.apptType) && loadStop.apptType === "HAVE_APPOINTMENT") {
                    if (isStringNotEmpty(loadStop.apptNumber)) {
                        methods6.setValue(`apptNumber`, loadStop.apptNumber);
                    }
                    if (isNotNullOrUndefined(loadStop.apptDateTime)) {
                        methods6.setValue(`apptDateTime`, loadStop.apptDateTime);
                    }
                }
            }
        }

        return () => isSubscribed = false;
    }, [showHaveAppointmentDetails, loadStop]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (showNeedAppointmentDetails === true && isObjectNotEmpty(loadStop)) {
                if (isStringNotEmpty(loadStop.apptType) && loadStop.apptType === "NEED_APPOINTMENT") {
                    if (isNotNullOrUndefined(loadStop.apptCallAheadDateTime)) {
                        methods6.setValue(`apptCallAheadDateTime`, loadStop.apptCallAheadDateTime);
                    }
                    if (isNotNullOrUndefined(loadStop.requestedDateTime)) {
                        methods6.setValue(`requestedDateTime`, loadStop.requestedDateTime);
                    }
                }
            }
        }

        return () => isSubscribed = false;
    }, [showNeedAppointmentDetails, loadStop]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (showEditStopLocation === true && isObjectNotEmpty(loadStop)) {
                if (isStringNotEmpty(loadStop.stopLocationId)) {
                    methods5.setValue('locationId', loadStop.stopLocationId);
                }
                if (isStringNotEmpty(loadStop.stopType)) {
                    methods5.setValue('stopType', loadStop.stopType);
                }
                if (isStringNotEmpty(loadStop.stopNumber)) {
                    methods5.setValue('stopNumber', loadStop.stopNumber);
                }
            }
        }

        return () => isSubscribed = false;
    }, [showEditStopLocation, loadStop]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (showEditStopLoadingSpecifics === true && isObjectNotEmpty(loadStop)) {
                if (isStringNotEmpty(loadStop.loadingType)) {
                    methods3.setValue(`loadingType`, loadStop.loadingType);
                }

                if (loadStop.hasLumperFee === true) {
                    methods3.setValue(`hasLumperFee`, true);
                    setLumperFeeDisabled(false);
                    if (loadStop.lumperFee !== undefined) {
                        methods3.setValue(`lumperFee`, loadStop.lumperFee);
                    } else if (isObjectNotEmpty(loadStop.stopLocation) && isNumberGreaterThanZero(loadStop.stopLocation.lumperFee)) {
                        methods3.setValue(`lumperFee`, loadStop.stopLocation.lumperFee);
                    }
                } else {
                    setLumperFeeDisabled(true);
                    methods3.setValue(`hasLumperFee`, false);
                }

                if (loadStop.hasDriverAssist === true) {
                    methods3.setValue(`hasDriverAssist`, true);
                    setDriverAssistDisabled(false);
                    if (loadStop.driverAssist !== undefined) {
                        methods3.setValue(`driverAssist`, loadStop.driverAssist);
                    } else if (entityType === 'STAFF') {
                        methods3.setValue(`driverAssist`, 50);
                    }
                } else {
                    setDriverAssistDisabled(true);
                    methods3.setValue(`hasDriverAssist`, false);
                }

                if (loadStop.specialInstructions !== undefined) {
                    methods3.setValue(`specialInstructions`, loadStop.specialInstructions);
                } else if (isObjectNotEmpty(loadStop.stopLocation) && isStringNotEmpty(loadStop.stopLocation.specialInstructions)) {
                    methods3.setValue(`specialInstructions`, loadStop.stopLocation.specialInstructions);
                }
            }
        }

        return () => isSubscribed = false;
    }, [showEditStopLoadingSpecifics, loadStop]);

    useMemo(() => {
        if (isStringNotEmpty(shipperId)) {
            if (entityType === 'STAFF') {
                dispatch(actionCreators.getLocations(shipperId, false));
            } else if (entityType === 'SHIPPER' || entityType === 'CARRIER' || entityType === 'PRODUCER' || entityType === 'RECEIVER' || entityType === 'BROKER') {
                dispatch(actionCreators.getLocations(entityId, false));
            }
        }
    }, [shipperId, entityType, entityId]);

    useMemo(() => {
        if (isNotNullOrUndefined(locations) && isStringNotEmpty(shipperId)) {
            let transformedLocations = [];
            if (isListNotEmpty(locations)) {
                locations.filter(l => l.linkedAccountId === undefined || l.linkedAccountId === null).forEach((item) => {
                    if (transformedLocations.find(l => l.id === item.id) === undefined) {
                        transformedLocations.push({
                            ...item,
                            accountName: isObjectNotEmpty(item.account) && isStringNotEmpty(item.account.name) ? item.account.name : ''
                        });
                    }
                });

                locations.filter(l => l.linkedAccountId !== undefined && l.linkedAccountId !== null).forEach((item) => {
                    if (transformedLocations.find(l => l.id === item.id) === undefined) {
                        if (isObjectNotEmpty(item.linkedAccount) && (item.linkedAccount.entityTypes.includes("SHIPPER") === false || (item.linkedAccount.entityTypes.includes("SHIPPER") === true && item.linkedAccount.id === shipperId))) {
                            transformedLocations.push({
                                ...item,
                                accountName: isObjectNotEmpty(item.linkedAccount) && isStringNotEmpty(item.linkedAccount.name) ? item.linkedAccount.name : ''
                            });
                        }
                    }
                });
            }

            setLocationsList(transformedLocations);
        }
    }, [locations, shipperId]);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    useMemo(() => {
        if (isListNotEmpty(stops)) {
            let pickUpStopsList = [];
            let dropOffStopsList = [];
            stops.forEach((stop, stopIndex) => {
                if (stop.stopType === 'PICK_UP') {
                    pickUpStopsList.push(stop);
                } else if (stop.stopType === 'DROP_OFF') {
                    dropOffStopsList.push(stop);
                }
            });

            setPickUpStops(pickUpStopsList);
            setDropOffStops(dropOffStopsList);
            setPickUpStopsCount(pickUpStopsList.length + (stopType === 'PICK_UP' ? 1 : 0));
            setDropOffStopsCount(dropOffStopsList.length + (stopType === 'DROP_OFF' ? 1 : 0));
        }
    }, [stops, stopType]);

    useMemo(() => {
        let loadBOLNumberList = [];
        if (isListNotEmpty(stops)) {
            stops.forEach((stop, stopIndex) => {
                if (isListNotEmpty(stop.bolNumbers)) {
                    stop.bolNumbers.forEach((bolNumber) => {
                        if (loadBOLNumberList.find(b => b === bolNumber) === undefined) {
                            loadBOLNumberList.push(bolNumber);
                        }
                    });
                }
            });
        }

        if (isObjectNotEmpty(loadStop)) {
            if (isListNotEmpty(loadStop.bolNumbers)) {
                loadStop.bolNumbers.forEach((bolNumber) => {
                    if (loadBOLNumberList.find(b => b === bolNumber) === undefined) {
                        loadBOLNumberList.push(bolNumber);
                    }
                });
            }
        }

        setBOLNumberList([...loadBOLNumberList]);
    }, [stops, loadStop]);

    useMemo(() => {
        if (entityType === 'CARRIER' || entityType === 'STAFF' || entityType === 'BROKER') {
            setDontRequireLoadSpecificInformation(true);
        } else {
            setDontRequireLoadSpecificInformation(false);
        }
    }, [entityType]);

    //#endregion
    //#region onChanges

    const onChangeApptType = (value) => {
        if (isStringNotEmpty(value)) {
            //console.log(`selected ${value}`);
            if (value === "FIRST_COME_FIRST_SERVE") {
                setShowFirstComeFirstServeDetails(true);
                setShowHaveAppointmentDetails(false);
                setShowNeedAppointmentDetails(false);
            } else if (value === "HAVE_APPOINTMENT") {
                setShowFirstComeFirstServeDetails(false);
                setShowHaveAppointmentDetails(true);
                setShowNeedAppointmentDetails(false);
            } else if (value === "NEED_APPOINTMENT") {
                setShowFirstComeFirstServeDetails(false);
                setShowHaveAppointmentDetails(false);
                setShowNeedAppointmentDetails(true);
            } else {
                setShowFirstComeFirstServeDetails(false);
                setShowHaveAppointmentDetails(false);
                setShowNeedAppointmentDetails(false);
            }
        } else {
            setShowFirstComeFirstServeDetails(false);
            setShowHaveAppointmentDetails(false);
            setShowNeedAppointmentDetails(false);
        }
    };

    const onChangeBOLNumber = (value) => {
        // console.log(value);
        if (isStringNotEmpty(value)) {
            if (value === 'NEW') {
                setShowNewBOLNumber(true);
            } else {
                setShowNewBOLNumber(false);
            }
        } else {
            setShowNewBOLNumber(false);
        }
    };

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    const formItemLayoutDouble = {
        labelCol1: { span: 24 },
        wrapperCol1: { span: 12 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 12 },
    };

    const formItemLayoutDouble2 = {
        labelCol1: { span: 12 },
        wrapperCol1: { span: 12 },
        labelCol2: { span: 12 },
        wrapperCol2: { span: 12 },
    };

    const formItemLayoutDouble3 = {
        labelCol1: { span: 24 },
        wrapperCol1: { span: 4 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 20 },
    };

    const radioStyle = {
        display: 'block',
        height: '60px',
        lineHeight: '30px',
    };

    //#endregion
    //#region displays

    const getLocationOptions = (stop = null) => {
        let options = [];
        if (isListNotEmpty(locationsList)) {
            // Don't filter out already used locations since some loads can have the same location 
            // for pick-up and drop-off in the bulk space where the sites are very large
            let orderedLocationsList = orderBy(locationsList, ['accountName', 'name', 'streetAddress1'], ['asc', 'asc', 'asc']);

            orderedLocationsList.forEach((item) => {
                options.push(<Option value={item.id} key={item.key}>{`${isStringNotEmpty(item.accountName) ? item.accountName + ' - ' : ''}${isStringNotEmpty(item.name) ? item.name : ''}${isObjectNotEmpty(item.address) ? ' - ' + stringFormatter.toFormattedAddress(item.address) : ''}`}</Option>);
            });
        }

        return options;
    };

    const getLoadStopLocationDisplay = (stop, stopIndex) => {
        if (isObjectNotEmpty(stop)) {
            const stopName = LoadStopUtils.getStopLocationNameDisplay(stop, shipperId, carrierId, entityType, entityId);
            const stopAddress = LoadStopUtils.getStopAddressDisplay(stop);
            const stopType = LoadStopUtils.getStopType(stop);
            const apptType = LoadStopUtils.getAppointmentType(stop);

            return (
                <DataCollapsableRow
                    key={`stop-display-${stop.stopType}-${stopIndex}`}
                    isCollapsed={isStringNotEmpty(stop.stopLocationId) || isStringEmpty(apptType) || isNullOrUndefined(stop.requestedDateTime) ? false : true}
                    title={isStringNotEmpty(stop.stopLocationId) ? (isStringNotEmpty(stopName) ? stopName : 'N/A') : (<Button style={{ paddingLeft: 0, fontSize: 18, fontWeight: 500, color: '#000000' }} type='link' onClick={() => { toggleEditStopLocation(); }}>Select a Location <DownOutlined /></Button>)}
                    titleStyle={{ paddingLeft: 0, fontSize: 18, fontWeight: 500, color: '#000000' }}
                    titleHasAction={isStringEmpty(stop.stopLocationId)}
                    subtitle={isObjectNotEmpty(stopAddress) ? (<span style={{ fontSize: 14 }}>{stopAddress}</span>) : null}
                    header={isStringNotEmpty(apptType) && isNotNullOrUndefined(stop.requestedDateTime) ? (
                        <>
                            <span style={{ fontSize: 14, textTransform: 'uppercase', color: '#A9A9A9' }}>{(isStringNotEmpty(stopType) ? `${LoadStopUtils.getStopTypeDisplay(stop)}${stopType === 'PICK_UP' && pickUpStops.length > 1 ? ' ' + (stopIndex + 1) : ''}${stopType === 'DROP_OFF' && dropOffStops.length > 1 ? ' ' + (stopIndex + 1) : ''}` : '')}  </span>
                            <span style={{ fontSize: 14, textTransform: 'uppercase', color: '#A9A9A9' }}>{LoadStopUtils.getAppointmentDateTimeDisplay(stop)}</span>
                        </>
                    ) : (isStringNotEmpty(stopType) ? <span style={{ fontSize: 14, textTransform: 'uppercase', color: '#A9A9A9' }}>{`${LoadStopUtils.getStopTypeDisplay(stop)}${stopType === 'PICK_UP' && pickUpStops.length > 1 ? ' ' + (stopIndex + 1) : ''}${stopType === 'DROP_OFF' && dropOffStops.length > 1 ? ' ' + (stopIndex + 1) : ''}`}</span> : null)}
                    extra={isStringNotEmpty(stop.stopLocationId) ? (<Button style={{ fontWeight: 'bold' }} type='link' onClick={() => { toggleEditStopLocation(); }}>edit</Button>) : null}
                >
                    <div style={{ marginTop: 18 }}>
                        {isStringNotEmpty(apptType) && isNotNullOrUndefined(stop.requestedDateTime) ? (
                            <Fieldset legend={
                                <div>
                                    <span style={{ fontWeight: 'bold' }}>Date, Appointment, & {Enums.StopTypes.getValueByName(stop.stopType)} Details *</span>
                                    <Button style={{ fontWeight: 'bold' }} type="link" onClick={() => { toggleEditStopAppointment(); }}>edit</Button>
                                </div>
                            }>
                                <DataRow title="Appointment Type" value={apptType} dataType="AppointmentType" />
                                <DataRow title={`Requested ${LoadStopUtils.getStopTypeDisplay(stop)} Date & Appointment`} value={LoadStopUtils.getAppointmentDateTimeDisplay(stop)} dataType="String" />

                                {apptType === "HAVE_APPOINTMENT" ? (
                                    <DataRow title="Appointment Number" value={stop.apptNumber} dataType="String" />
                                ) : null}
                                {stopType === 'PICK_UP' ? (
                                    <DataRow title="Pick-Up Number" value={stop.pickUpNumber} dataType="String" />
                                ) : null}
                                {stopType === 'DROP_OFF' ? (
                                    <>
                                        <DataRow title="Drop-Off Number" value={stop.dropOffNumber} dataType="String" />
                                        <DataRow title="Customer PO Number Number" value={stop.purchaseOrderNumber} dataType="String" />
                                    </>
                                ) : null}
                                {apptType === "NEED_APPOINTMENT" ? (
                                    <DataRow title="Appointment Call Ahead Date/Time" value={isNotNullOrUndefined(stop.apptCallAheadDateTime) ? momentDate.fromTimeZonelessToTimeZoneAsTimeZoneDateTimeString(stop.apptCallAheadDateTime, isObjectNotEmpty(stop.stopLocation) && isStringNotEmpty(stop.stopLocation.timeZone) ? stop.stopLocation.timeZone : null) : null} dataType="String" />
                                ) : null}
                                <DataContactInfoRow separateLines={false} title={`${Enums.StopTypes.getValueByName(stop.stopType)} Contact`} contact={stop.apptPointOfContact} />
                                <DataRow title="Additional Appointment Instructions" value={stop.apptInstructions} dataType="String" />
                            </Fieldset>
                        ) : (isStringEmpty(stop.apptType) || isNullOrUndefined(stop.requestedDateTime) ? (
                            <Row gutter={[4, 4]} style={{ marginTop: 18, marginBottom: 18 }}>
                                <Col span={24}>
                                    <Button style={{ marginLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} disabled={isStringEmpty(stop.stopLocationId)} onClick={() => { toggleEditStopAppointment(); }}>{`Date, Appointment, & ${LoadStopUtils.getStopTypeDisplay(stop)} Details *`}</Button>
                                </Col>
                            </Row>
                        ) : null)}
                        {isListNotEmpty(stop.bolNumbers) ? (
                            <>
                                <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                                    <Col span={24}>
                                        <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>BOL Number</span></div>
                                        {stop.bolNumbers.map((stopBOLNumber, stopBOLNumberIndex) => {
                                            return (
                                                <Row gutter={[4, 4]} style={{ marginTop: 12, marginBottom: 12 }} key={`bolNumber-${stopBOLNumberIndex}`}>
                                                    <Col span={18}>
                                                        <div><span style={{ fontSize: 16 }}>{stopBOLNumber}</span></div>
                                                        {docFiles.filter(d => d.stopId === stop.id && d.bolNumber === stopBOLNumber).length > 0 ? (
                                                            <DataListRow data={docFiles.filter(d => d.stopId === stop.id && d.bolNumber === stopBOLNumber).map((docFile) => { if (docFile.file !== undefined && docFile.file !== null) { return docFile.file.name; } else { return ''; } })} />
                                                        ) : null}
                                                    </Col>
                                                    <Col span={6}>
                                                        {(stop.stopType === 'PICK_UP' || (stop.stopType === 'DROP_OFF' && dropOffStopsCount > 1)) ? (
                                                            <Button type="default" icon={<DeleteOutlined />} onClick={() => { onRemoveStopBOL(stopBOLNumber, stopBOLNumberIndex); }} />
                                                        ) : null}
                                                    </Col>
                                                </Row>
                                            );
                                        })}
                                    </Col>
                                </Row>
                                {(stop.stopType === 'PICK_UP' && dropOffStopsCount > stop.bolNumbers.length) ? (
                                    <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                                        <Col span={24}>
                                            <Button style={{ marginLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} onClick={() => { toggleAddStopBOL(); }}>Add Another BOL Number</Button>
                                        </Col>
                                    </Row>
                                ) : null}
                            </>
                        ) : (
                            <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                                <Col span={24}>
                                    <Button style={{ marginLeft: 0, fontWeight: 'bold' }} type="link" disabled={isStringEmpty(stop.apptType)} icon={<PlusOutlined />} onClick={() => { toggleAddStopBOL(); }}>{`BOL Number${dontRequireLoadSpecificInformation === false ? ' *' : ''}`}</Button>
                                </Col>
                            </Row>
                        )}
                        {isListNotEmpty(stop.commodities) ? (
                            <>
                                <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                                    <Col span={24}>
                                        <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>Commodities</span></div>
                                        {stop.commodities.map((stopCommodity, index) => {
                                            //console.log(stopCommodity);

                                            return (
                                                <Row gutter={[4, 4]} style={{ marginTop: 12, marginBottom: 12 }} key={`stopCommodity-${index}`}>
                                                    <Col span={18}>
                                                        <span style={{ fontSize: 16 }}>{stopCommodity.name}: {stopCommodity.unitPackagingInitCount ? `${stopCommodity.unitPackagingInitCount} ${Enums.UnitPackaging.getValueByName(stopCommodity.unitPackaging)}` : ''}{stopCommodity.bulkPackagingInitCount ? `, ${stopCommodity.bulkPackagingInitCount} ${Enums.BulkPackaging.getValueByName(stopCommodity.bulkPackaging)}` : ''}</span>
                                                    </Col>
                                                    <Col span={6}>
                                                        {((stop.stopType === 'PICK_UP') || (stop.stopType === 'DROP_OFF' && dropOffStopsCount > 1)) ? (
                                                            <Button type="default" icon={<DeleteOutlined />} onClick={() => { onRemoveStopCommodity(index); }} />
                                                        ) : null}
                                                    </Col>
                                                </Row>
                                            );
                                        })}
                                    </Col>
                                </Row>

                                {((stop.stopType === 'PICK_UP') || (stop.stopType === 'DROP_OFF' && dropOffStopsCount > 1)) ? (
                                    <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                                        <Col span={24}>
                                            <Button style={{ marginLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} onClick={() => { toggleAddStopCommodity(); }}>Additional Commodity</Button>
                                        </Col>
                                    </Row>
                                ) : null}
                            </>
                        ) : (((stop.stopType === 'PICK_UP') || (stop.stopType === 'DROP_OFF' && dropOffStopsCount > 1)) ? (
                            <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                                <Col span={24}>
                                    <Button style={{ marginLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} disabled={!((isListNotEmpty(stop.bolNumbers) && dontRequireLoadSpecificInformation === false) || dontRequireLoadSpecificInformation === true) || isStringEmpty(stop.apptType)} onClick={() => { toggleAddStopCommodity(); }}>Commodities</Button>
                                </Col>
                            </Row>
                        ) : null)}
                        {isStringNotEmpty(stop.loadingType) ? (
                            <>
                                <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                                    <Col span={24}>
                                        <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>{stop.stopType === 'PICK_UP' ? 'Loading Type' : 'Unloading Type'} *</span></div>
                                        <div><span style={{ fontSize: 16 }}>{stringFormatter.toFormattedString(stop.stopType === 'PICK_UP' ? 'LoadingType' : 'UnloadingType', stop.loadingType, null)}</span><Button style={{ fontWeight: 'bold' }} type="link" onClick={() => { toggleEditStopLoadingSpecifics(); }}>edit</Button></div>
                                    </Col>
                                </Row>
                                <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                                    <Col span={12}>
                                        <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>Lumper Fee</span></div>
                                        {stop.hasLumperFee === true ? (
                                            <div><span style={{ fontSize: 16 }}>{`Yes, ${stringFormatter.toFormattedMoney(stop.lumperFee)}`}</span><Button style={{ fontWeight: 'bold' }} type="link" onClick={() => { toggleEditStopLoadingSpecifics(); }}>edit</Button></div>
                                        ) : (
                                            <div><Button style={{ fontWeight: 'bold' }} type="link" onClick={() => { toggleEditStopLoadingSpecifics(); }}>add</Button></div>
                                        )}
                                    </Col>
                                    <Col span={12}>
                                        <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>Driver Assist</span></div>
                                        {stop.hasDriverAssist === true ? (
                                            <div><span style={{ fontSize: 16 }}>{`Yes, ${stringFormatter.toFormattedMoney(stop.driverAssist)}`}</span><Button style={{ fontWeight: 'bold' }} type="link" onClick={() => { toggleEditStopLoadingSpecifics(); }}>edit</Button></div>
                                        ) : (
                                            <div><Button style={{ fontWeight: 'bold' }} type="link" onClick={() => { toggleEditStopLoadingSpecifics(); }}>add</Button></div>
                                        )}
                                    </Col>
                                </Row>
                                <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                                    <Col span={24}>
                                        <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>Special Instructions</span></div>
                                        {stop.specialInstructions ? (
                                            <div><span style={{ fontSize: 16 }}>{stop.specialInstructions}</span><Button style={{ fontWeight: 'bold' }} type="link" onClick={() => { toggleEditStopLoadingSpecifics(); }}>edit</Button></div>
                                        ) : (
                                            <div><Button style={{ fontWeight: 'bold' }} type="link" onClick={() => { toggleEditStopLoadingSpecifics(); }}>add</Button></div>
                                        )}
                                    </Col>
                                </Row>
                            </>
                        ) : (
                            <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                                <Col span={24}>
                                    <Button style={{ marginLeft: 0, fontWeight: 'bold' }} type="link" disabled={!((isListNotEmpty(stop.bolNumbers) && dontRequireLoadSpecificInformation === false) || dontRequireLoadSpecificInformation === true) || isStringEmpty(stop.apptType)} icon={<PlusOutlined />} onClick={() => { toggleEditStopLoadingSpecifics(); }}>{stop.stopType === 'PICK_UP' ? 'Loading Specifics *' : (stop.stopType === 'DROP_OFF' ? 'Unloading Specifics *' : 'Loading/Unloading Specifics *')}</Button>
                                </Col>
                            </Row>
                        )}
                    </div>
                </DataCollapsableRow>
            );
        } else {
            return null;
        }
    };

    const getStopDetails = (stop) => {
        if (isObjectNotEmpty(stop)) {
            const stopIcon = LoadStopUtils.getStopMarkerIcon(stop);

            return (
                <div>
                    <Row style={{ marginTop: 36, marginBottom: 36 }}>
                        <Col span={12}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: 30, marginRight: 12 }}>
                                    {isNotNullOrUndefined(stopIcon) ? (<img src={stopIcon} alt={LoadStopUtils.getStopTypeDisplay(stop)} style={{ width: 30, height: 30 }} />) : null}
                                </div>
                                <div style={{ flex: 1 }}>
                                    <span style={{ fontWeight: 'bold' }}>{LoadStopUtils.getStopLocationNameDisplay(stop, shipperId, carrierId, entityType, entityId)}</span><br />
                                    <span>{LoadStopUtils.getStopLocationCityStateDisplay(stop)}</span>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <span style={{ fontWeight: 'bold' }}>Appointment</span><br />
                            {LoadStopUtils.getAppointmentDateTimeDisplay(stop)}
                        </Col>
                    </Row>
                </div>
            );
        } else {
            return null;
        }
    };

    const getCommodityOptions = (stop) => {
        let options = [];
        if (isObjectNotEmpty(stop) && isListNotEmpty(commodities)) {
            let availableCommodities = [];
            // filter the commodities by what is set on the location if any are set
            if (isObjectNotEmpty(stop.stopLocation) && isListNotEmpty(stop.stopLocation.commodityIds)) {
                let availableStopCommodities = commodities.filter(c => c.isDeleted === false && stop.stopLocation.commodityIds.includes(c.id));
                if (isListNotEmpty(availableStopCommodities)) {
                    availableCommodities = availableStopCommodities;
                } else {
                    availableCommodities = commodities;
                }
            } else {
                availableCommodities = commodities;
            }
            if (stop.stopType === 'PICK_UP') {
                // if pick-up, don't show commodities that have already been added to this stop
                let usedCommodityIds = [];
                if (isListNotEmpty(stop.commodities)) {
                    stop.commodities.forEach((stopCommodity) => {
                        usedCommodityIds.push(stopCommodity.commodityId);
                    });
                }

                availableCommodities.forEach((commodity) => {
                    if (!usedCommodityIds.includes(commodity.id)) {
                        options.push(<Option value={commodity.id} key={commodity.id}>{commodity.name}</Option>);
                    }
                });
            } else if (stop.stopType === 'DROP_OFF') {
                let usedCommodityIds = [];
                let usedStopCommodityIds = [];

                // don't show commodities that have already been added to this stop
                if (isListNotEmpty(stop.commodities)) {
                    stop.commodities.forEach((stopCommodity) => {
                        usedStopCommodityIds.push(stopCommodity.commodityId);
                    });
                }

                // only show commodities that the pick-up stops have
                pickUpStops.forEach((pickUpStop) => {
                    if (isListNotEmpty(pickUpStop.commodities)) {
                        pickUpStop.commodities.forEach((stopCommodity) => {
                            if (!usedCommodityIds.includes(stopCommodity.commodityId)) {
                                usedCommodityIds.push(stopCommodity.commodityId);
                            }
                        });
                    }
                });

                availableCommodities.forEach((commodity) => {
                    if (usedCommodityIds.includes(commodity.id) && !usedStopCommodityIds.includes(commodity.id)) {
                        options.push(<Option value={commodity.id} key={commodity.id}>{commodity.name}</Option>);
                    }
                });
            }
        }

        return options;
    };

    const getBOLNumberOptions = (stop = null) => {
        let options = [];
        if (isListNotEmpty(bolNumberList) && isObjectNotEmpty(stop)) {
            let usedBOLNumbers = [];

            if (isListNotEmpty(stop.bolNumbers)) {
                stop.bolNumbers.forEach((stopBOLNumber) => {
                    usedBOLNumbers.push(stopBOLNumber);
                });
            }

            bolNumberList.forEach((bolNumber) => {
                if (!usedBOLNumbers.includes(bolNumber)) {
                    options.push({ key: bolNumber, value: bolNumber, label: bolNumber });
                }
            });

            if (isListNotEmpty(options) && (stop.stopType === 'PICK_UP' || (stop.stopType === 'DROP_OFF' && dropOffStopsCount > bolNumberList.length))) {
                options.push({ key: 'NEW', value: 'NEW', label: 'Add a New BOL Number' });
            }
        }

        return options;
    };

    const getError = (errors, name) => {
        if (name !== undefined) {
            let obj = errors;

            name = name.split('.');
            let len = name.length;
            for (let i = 0; i < len - 1; i++) {
                if (obj[name[i]] !== undefined) {
                    //console.log(obj[name[i]]);
                    obj = obj[name[i]];
                }
            }

            return obj[name[len - 1]];
        } else {
            return undefined;
        }
    };

    //#endregion

    return (
        <>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                        {getLoadStopLocationDisplay(loadStop, stopTypeSequence)}
                        {getError(methods.errors, "loadStop") !== undefined && (
                            <ErrorMessage
                                errors={methods.errors}
                                name="loadStop"
                                render={({ message, messages }) => {
                                    if (messages !== undefined && messages !== null) {
                                        return Object.entries(messages).map(([type, message]) => (<Alert key={`${type}`} message={message} type="error" />));
                                    } else if (message !== undefined && message !== null) {
                                        return <Alert message={message} type="error" />;
                                    } else {
                                        return null;
                                    }
                                }}
                            />
                        )}
                        {error && <Alert message={`${error}`} type="error" />}
                    </Spin>
                    <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Add Stop" />
                </Form>
            </FormProvider>
            <Modal
                title={isObjectNotEmpty(loadStop) ? (loadStop.stopType === "PICK_UP" ? "Pick-Up Location" : (loadStop.stopType === "DROP_OFF" ? "Drop-Off Location" : "Stop Location")) : 'Stop Location'}
                visible={showEditStopLocation}
                width="60%"
                style={{ top: 0 }}
                onCancel={toggleEditStopLocation}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                maskClosable={false}
                forceRender={true}
            >
                <FormProvider {...methods5}>
                    <Form onSubmit={methods5.handleSubmit(onEditStopLocation)}>
                        <FormItem {...formItemLayout} label="Location" required format="vertical"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select a Location"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    ref={ref}
                                >
                                    {getLocationOptions()}
                                </Select>
                            )}
                            rules={{ required: 'Required Field' }}
                            name="locationId"
                            defaultValue={null}
                        />
                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                borderTop: '1px solid #e9e9e9',
                                padding: '10px 0px',
                                background: '#fff',
                                textAlign: 'right',
                            }}
                        >
                            <Button onClick={() => { toggleEditStopLocation(); }} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary" htmlType="submit">Save Location</Button>
                        </div>
                    </Form>
                </FormProvider>
            </Modal>
            <Modal
                title={isObjectNotEmpty(loadStop) ? (loadStop.stopType === "PICK_UP" ? "Date, Appointment, & Pick-Up Details" : "Date, Appointment, & Drop-Off Details") : 'Date, Appointment, & Stop Details'}
                visible={showEditStopAppointment === true}
                width="60%"
                style={{ top: 0 }}
                onCancel={toggleEditStopAppointment}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                maskClosable={false}
                forceRender={true}
            >
                <FormProvider {...methods6}>
                    <Form onSubmit={methods6.handleSubmit(onEditStopAppointment)}>
                        <FormItem {...formItemLayout} label="Appointment Type" required format="vertical"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select an Appointment Type"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChangeApptType(selected); onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    ref={ref}
                                >
                                    {appointmentTypeOptions}
                                </Select>
                            )}
                            rules={{ required: 'Required Field' }}
                            name="apptType"
                            defaultValue={null}
                        />
                        {(showFirstComeFirstServeDetails === true || (loadStop && loadStop.apptType === 'FIRST_COME_FIRST_SERVE')) ? (
                            <>
                                <FormItemDouble {...formItemLayoutDouble} label1={isObjectNotEmpty(loadStop) ? (loadStop.stopType === "PICK_UP" ? "Requested Pick-Up Date & Appointment Window" : "Requested Drop-Off Date & Appointment Window") : "Requested Date & Appointment Window"} required1 required2 format="vertical"
                                    render1={({ onChange, onBlur, value, name, ref }) => (
                                        <DatePicker
                                            allowClear={true}
                                            placeholder="Start Date/Time"
                                            showTime={{ format: 'HH:mm', minuteStep: 15 }}
                                            format="YYYY-MM-DD HH:mm"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={(date, dateString) => { onChange(date); }}
                                            value={value}
                                            name={name}
                                            onSelect={(date) => { onChange(date); }}
                                            ref={ref}
                                        />
                                    )}
                                    rules1={{ required: 'Required Field' }}
                                    name1="apptWindowStartDateTime"
                                    defaultValue1={null}
                                    render2={({ onChange, onBlur, value, name, ref }) => (
                                        <DatePicker
                                            allowClear={true}
                                            placeholder="End Date/Time"
                                            showTime={{ format: 'HH:mm', minuteStep: 15 }}
                                            format="YYYY-MM-DD HH:mm"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={(date, dateString) => { onChange(date); }}
                                            value={value}
                                            name={name}
                                            onSelect={(date) => { onChange(date); }}
                                            ref={ref}
                                        />
                                    )}
                                    rules2={{ required: 'Required Field' }}
                                    name2="apptWindowEndDateTime"
                                    defaultValue2={null}
                                />
                            </>
                        ) : null}
                        {(showHaveAppointmentDetails === true || (loadStop && loadStop.apptType === 'HAVE_APPOINTMENT')) ? (
                            <>
                                <FormItem {...formItemLayout} label="Appointment #" required={dontRequireLoadSpecificInformation === false} format="vertical"
                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Appointment #" ref={ref} />}
                                    rules={{ required: dontRequireLoadSpecificInformation === false ? 'Required Field' : false }}
                                    name="apptNumber"
                                    defaultValue={''}
                                />
                                <FormItem {...formItemLayout} label={isObjectNotEmpty(loadStop) ? (loadStop.stopType === "PICK_UP" ? "Requested Pick-Up Date & Appointment" : "Requested Drop-Off Date & Appointment") : "Requested Date & Appointment"} required format="vertical"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <DatePicker
                                            allowClear={true}
                                            placeholder="Appointment Date/Time"
                                            showTime={{ format: 'HH:mm', minuteStep: 15 }}
                                            format="YYYY-MM-DD HH:mm"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={(date, dateString) => { onChange(date); }}
                                            value={value}
                                            name={name}
                                            onSelect={(date) => { onChange(date); }}
                                            ref={ref}
                                        />
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="apptDateTime"
                                    defaultValue={null}
                                />
                            </>
                        ) : null}
                        {(showNeedAppointmentDetails === true || (loadStop && loadStop.apptType === 'NEED_APPOINTMENT')) ? (
                            <>
                                <FormItem {...formItemLayout} label={isObjectNotEmpty(loadStop) ? (loadStop.stopType === "PICK_UP" ? "Requested Pick-Up Date" : "Requested Drop-Off Date") : 'Requested Date'} required format="vertical"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <DatePicker
                                            allowClear={true}
                                            placeholder="Requested Date"
                                            format="YYYY-MM-DD"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={(date, dateString) => { onChange(date); }}
                                            value={value}
                                            name={name}
                                            onSelect={(date) => { onChange(date); }}
                                            ref={ref}
                                        />
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="requestedDateTime"
                                    defaultValue={null}
                                />
                                <FormItem {...formItemLayout} label="Appointment Call Ahead Date and Time" format="vertical"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <DatePicker
                                            allowClear={true}
                                            placeholder="Call Ahead Date/Time"
                                            showTime={{ format: 'HH:mm', minuteStep: 15 }}
                                            format="YYYY-MM-DD HH:mm"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={(date, dateString) => { onChange(date); }}
                                            value={value}
                                            name={name}
                                            onSelect={(date) => { onChange(date); }}
                                            ref={ref}
                                        />
                                    )}
                                    rules={{ required: false }}
                                    name="apptCallAheadDateTime"
                                    defaultValue={null}
                                />
                            </>
                        ) : null}
                        {(loadStop && loadStop.stopType === 'PICK_UP') ? (
                            <FormItem {...formItemLayout} label="Pick-Up #" required={dontRequireLoadSpecificInformation === false} format="vertical"
                                render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Pick-Up #" ref={ref} />}
                                rules={{ required: dontRequireLoadSpecificInformation === false ? 'Required Field' : false }}
                                name="pickUpNumber"
                            />
                        ) : null}
                        {(loadStop && loadStop.stopType === 'DROP_OFF') ? (
                            <>
                                <FormItem {...formItemLayout} label="Drop-Off #" required={dontRequireLoadSpecificInformation === false} format="vertical"
                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Drop-Off #" ref={ref} />}
                                    rules={{ required: dontRequireLoadSpecificInformation === false ? 'Required Field' : false }}
                                    name="dropOffNumber"
                                />
                                <FormItem {...formItemLayout} label="Customer PO #" required={dontRequireLoadSpecificInformation === false} format="vertical"
                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Customer PO #" ref={ref} />}
                                    rules={{ required: dontRequireLoadSpecificInformation === false ? 'Required Field' : false }}
                                    name="purchaseOrderNumber"
                                />
                            </>
                        ) : null}
                        <FormItemPointOfContact
                            format="penta"
                            required={false}
                            name="apptPointOfContact"
                            nameFieldLabel={isObjectNotEmpty(loadStop) ? (loadStop.stopType === "PICK_UP" ? "Pick-Up Contact" : "Drop-Off Contact") : 'Appointment Contact'}
                        />
                        <FormItem {...formItemLayout} label="Additional Appointment Instructions" format="vertical"
                            render={({ onChange, onBlur, value, name, ref }) => <TextArea onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} autoSize={{ minRows: 4 }} ref={ref} />}
                            rules={{ required: false }}
                            name="apptInstructions"
                            defaultValue={''}
                        />
                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                borderTop: '1px solid #e9e9e9',
                                padding: '10px 0px',
                                background: '#fff',
                                textAlign: 'right',
                            }}
                        >
                            <Button onClick={() => { toggleEditStopAppointment(); }} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary" htmlType="submit">{(loadStop && loadStop.requestedDateTime) ? 'Save Date, Appointment, and Stop Details' : 'Add Date, Appointment, and Stop Details'}</Button>
                        </div>
                    </Form>
                </FormProvider>
            </Modal>
            <Modal
                title={isObjectNotEmpty(loadStop) ? (loadStop.stopType === "PICK_UP" ? "Pick-Up BOL Information" : "Drop-Off BOL Information") : 'Stop BOL Information'}
                visible={showAddStopBOL}
                width="60%"
                style={{ top: 0 }}
                onCancel={toggleAddStopBOL}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                maskClosable={false}
                forceRender={true}
            >
                <FormProvider {...methods2}>
                    <Form onSubmit={methods2.handleSubmit(onAddStopBOL)}>
                        {(getBOLNumberOptions(loadStop).length > 0) ? (
                            <>
                                <FormItem {...formItemLayout} label="Choose an existing BOL Number" format="vertical"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Radio.Group
                                            onBlur={onBlur}
                                            options={getBOLNumberOptions(loadStop)}
                                            onChange={e => { onChangeBOLNumber(e.target.value); onChange(e.target.value); }}
                                            value={value}
                                            name={name}
                                            ref={ref}
                                        />
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="existingBOLNumber"
                                />
                                {showNewBOLNumber === true ? (
                                    <FormItem {...formItemLayout} label="BOL Number" required format="vertical"
                                        render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="BOL Number" ref={ref} />}
                                        rules={{ required: 'Required Field' }}
                                        name="newBOLNumber"
                                    />
                                ) : null}
                            </>
                        ) : (
                            <FormItem {...formItemLayout} label="BOL Number" required format="vertical"
                                render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="BOL Number" ref={ref} />}
                                rules={{ required: 'Required Field' }}
                                name="newBOLNumber"
                            />
                        )}
                        <FormItemFile {...formItemLayout} label="BOL File(s)" name="docFiles" format="vertical">
                            <UploadFile
                                beforeUpload={(file, fileList) => {
                                    if (isNotNullOrUndefined(file)) {
                                        setBOLDocFiles(oldFiles => [...oldFiles, file]);
                                    }

                                    return false;
                                }}
                                onRemove={(file) => {
                                    let updatedBOLDocFiles = [...bolDocFiles];
                                    let fileToRemoveIndex = updatedBOLDocFiles.findIndex(d => d.uid === file.uid);
                                    if (fileToRemoveIndex !== -1) {
                                        updatedBOLDocFiles.splice(fileToRemoveIndex, 1);
                                    }
                                    // console.log(updatedDocFiles);
                                    setBOLDocFiles([...updatedBOLDocFiles]);
                                }}
                                message="Please upload a photo or pdf of the BOL."
                                multiple={true}
                            />
                        </FormItemFile>
                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                borderTop: '1px solid #e9e9e9',
                                padding: '10px 0px',
                                background: '#fff',
                                textAlign: 'right',
                            }}
                        >
                            <Button onClick={() => { toggleAddStopBOL(); }} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary" htmlType="submit">Add BOL Information</Button>
                        </div>
                    </Form>
                </FormProvider>
            </Modal>
            <Modal
                title={isObjectNotEmpty(loadStop) ? (loadStop.stopType === "PICK_UP" ? "Loading Specifics" : "Unloading Specifics") : 'Loading/Unloading Specifics'}
                visible={showEditStopLoadingSpecifics === true}
                width="60%"
                style={{ top: 0 }}
                onCancel={toggleEditStopLoadingSpecifics}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                maskClosable={false}
                forceRender={true}
            >
                <FormProvider {...methods3}>
                    <Form onSubmit={methods3.handleSubmit(onEditStopLoadingSpecifics)}>
                        {(loadStop && loadStop.stopType === 'PICK_UP') ? (
                            <Fieldset legend="* Loading Type Options">
                                <div>How will the trailer be loaded?</div>
                                <FormItem {...formItemLayout} format="vertical"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Radio.Group onBlur={onBlur} onChange={e => onChange(e.target.value)} value={value} name={name} ref={ref}>
                                            <Radio style={radioStyle} key="LIVE" value="LIVE">
                                                <span style={{ fontWeight: 'bold', fontSize: 16 }}>Live Load Trailer</span><br /><span style={{ fontSize: 14, paddingLeft: 24 }}>Trailer will be loaded upon arrival.</span>
                                            </Radio>
                                            <Radio style={radioStyle} key="DROP" value="DROP">
                                                <span style={{ fontWeight: 'bold', fontSize: 16 }}>Drop Trailer</span><br /><span style={{ fontSize: 14, paddingLeft: 24 }}>Drop and hook.</span>
                                            </Radio>
                                        </Radio.Group>
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="loadingType"
                                />
                            </Fieldset>
                        ) : null}
                        {(loadStop && loadStop.stopType === 'DROP_OFF') ? (
                            <Fieldset legend="* Unloading Type Options">
                                <div>How will the trailer be unloaded?</div>
                                <FormItem {...formItemLayout} format="vertical"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Radio.Group onBlur={onBlur} onChange={e => onChange(e.target.value)} value={value} name={name} ref={ref}>
                                            <Radio style={radioStyle} key="LIVE" value="LIVE">
                                                <span style={{ fontWeight: 'bold', fontSize: 16 }}>Live Unload Trailer</span><br /><span style={{ fontSize: 14, paddingLeft: 24 }}>Trailer will be unloaded upon arrival.</span>
                                            </Radio>
                                            <Radio style={radioStyle} key="DROP" value="DROP">
                                                <span style={{ fontWeight: 'bold', fontSize: 16 }}>Drop Trailer</span><br /><span style={{ fontSize: 14, paddingLeft: 24 }}>Drop and hook.</span>
                                            </Radio>
                                        </Radio.Group>
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="loadingType"
                                />
                            </Fieldset>
                        ) : null}
                        <Fieldset legend="Additional Fees">
                            <div>Are there any additional fees?</div>
                            <FormItemDouble {...formItemLayoutDouble3} label1="Lumper Fee" format="vertical"
                                render1={({ onChange, onBlur, value, name, ref }) => (
                                    <Switch
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        onBlur={onBlur}
                                        onChange={(checked, event) => { onChange(checked); setLumperFeeDisabled(!checked); }}
                                        checked={value}
                                        name={name}
                                        ref={ref}
                                    />
                                )}
                                rules1={{ required: false }}
                                name1="hasLumperFee"
                                render2={({ onChange, onBlur, value, name, ref }) => (
                                    <InputNumber
                                        precision={2}
                                        min={0}
                                        placeholder="Lumper Fee"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                        disabled={lumperFeeDisabled === true}
                                        ref={ref}
                                    />
                                )}
                                rules2={{ required: false }}
                                name2="lumperFee"
                            />
                            <FormItemDouble {...formItemLayoutDouble3} label1="Driver Assist" format="vertical"
                                render1={({ onChange, onBlur, value, name, ref }) => (
                                    <Switch
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        onBlur={onBlur}
                                        onChange={(checked, event) => { onChange(checked); setDriverAssistDisabled(!checked); }}
                                        checked={value}
                                        name={name}
                                        ref={ref}
                                    />
                                )}
                                rules1={{ required: false }}
                                name1="hasDriverAssist"
                                render2={({ onChange, onBlur, value, name, ref }) => (
                                    <InputNumber
                                        precision={2}
                                        min={0}
                                        placeholder="Driver Assist Fee"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                        disabled={driverAssistDisabled === true}
                                        ref={ref}
                                    />
                                )}
                                rules2={{ required: false }}
                                name2="driverAssist"
                            />
                        </Fieldset>
                        <Fieldset legend="Special Instructions">
                            <FormItem {...formItemLayout} label="Special Instructions" format="vertical"
                                render={({ onChange, onBlur, value, name, ref }) => <TextArea onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} autoSize={{ minRows: 4 }} ref={ref} />}
                                rules={{ required: false }}
                                name="specialInstructions"
                            />
                        </Fieldset>
                        <div
                            style={{
                                position: 'relative',
                                right: 0,
                                width: '100%',
                                borderTop: '1px solid #e9e9e9',
                                padding: '10px 0px',
                                background: '#fff',
                                textAlign: 'right',
                                marginTop: '0px',
                            }}
                        >
                            <Button onClick={() => { toggleEditStopLoadingSpecifics(); }} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary" htmlType="submit">{isObjectNotEmpty(loadStop) ? (loadStop.stopType === "PICK_UP" ? "Update Loading Specifics" : "Update Unloading Specifics") : 'Update Loading/Unloading Specifics'}</Button>
                        </div>
                    </Form>
                </FormProvider>
            </Modal>
            <Modal
                title={isObjectNotEmpty(loadStop) ? (loadStop.stopType === "PICK_UP" ? "Pick-Up Commodity" : "Drop-Off Commodity") : 'Stop Commodity'}
                visible={showAddStopCommodity === true}
                width="60%"
                style={{ top: 0 }}
                onCancel={toggleAddStopCommodity}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                maskClosable={false}
                forceRender={true}
            >
                <FormProvider {...methods4}>
                    <Form onSubmit={methods4.handleSubmit(onAddStopCommodity)}>
                        <Fieldset legend={isObjectNotEmpty(loadStop) ? (loadStop.stopType === "PICK_UP" ? "Pick-Up Location" : "Drop-Off Location") : 'Stop Location'}>
                            {getStopDetails(loadStop)}
                        </Fieldset>
                        <Fieldset legend="Commodity Details">
                            <FormItem {...formItemLayout} label="Name" required format="vertical"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please Search and Select a Commodity to Add"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        showSearch={true}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        ref={ref}
                                    >
                                        {getCommodityOptions(loadStop)}
                                    </Select>
                                )}
                                rules={{ required: 'Required Field' }}
                                name="commodityId"
                            />
                            <FormItemDouble {...formItemLayoutDouble2} label1="Unit Quantity" label2="Unit of Measure/Unit Packaging" required1 required2 format="vertical"
                                render1={({ onChange, onBlur, value, name, ref }) => (
                                    <InputNumber
                                        precision={0}
                                        min={0}
                                        placeholder="Unit Quantity"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    />
                                )}
                                rules1={{ required: 'Required Field' }}
                                name1="unitPackagingInitCount"
                                render2={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please Select a Unit of Measure/Unit Packaging"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        showSearch={true}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        ref={ref}
                                    >
                                        {unitPackagingOptions}
                                    </Select>
                                )}
                                rules2={{ required: 'Required Field' }}
                                name2="unitPackaging"
                            />
                            <FormItemDouble {...formItemLayoutDouble2} label1="Bulk Quantity" label2="Bulk Packaging" format="vertical"
                                render1={({ onChange, onBlur, value, name, ref }) => (
                                    <InputNumber
                                        precision={0}
                                        min={0}
                                        placeholder="Bulk Quantity"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    />
                                )}
                                rules1={{ required: false }}
                                name1="bulkPackagingInitCount"
                                render2={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please Select a Bulk Packaging"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        showSearch={true}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        ref={ref}
                                    >
                                        {bulkPackagingOptions}
                                    </Select>
                                )}
                                rules2={{ required: false }}
                                name2="bulkPackaging"
                            />
                        </Fieldset>
                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                borderTop: '1px solid #e9e9e9',
                                padding: '10px 0px',
                                background: '#fff',
                                textAlign: 'right',
                            }}
                        >
                            <Button onClick={() => { toggleAddStopCommodity(); }} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary" htmlType="submit">Add Commodity</Button>
                        </div>
                    </Form>
                </FormProvider>
            </Modal>
            <Drawer
                title={'Add New Location'}
                visible={showNewLocation === true}
                onClose={toggleNewLocation}
                bodyStyle={{ paddingBottom: 80 }}
                style={{ zIndex: 1000 }}
                width={fullWidth > 720 ? fullWidth / 2 : 360}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                closable={false}
                maskClosable={false}
            >
                <NewLocation cancel={toggleNewLocation} accountId={shipperId} />
            </Drawer>
        </>
    );
};

export default NewLoadStop;