import Checks from '../entitlementUtils';

export const canCreateService = (auth) => {
    return Checks.allowStaffAdmin(auth);
};

export const canReadService = (auth) => {
    return Checks.allowStaffAdmin(auth);
};

export const canReadServiceList = (auth) => {
    return Checks.allowStaffAdmin(auth);
};

export const canUpdateService = (auth) => {
    return Checks.allowStaffAdmin(auth);
};

export const canDeleteService = (auth) => {
    return Checks.allowStaffAdmin(auth);
};