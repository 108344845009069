import React from 'react';
import { useSelector } from 'react-redux';
import { CanSee } from '../../shared/entitlements/entitlements';
import StringFormatter from '../../shared/stringFormatter';
import DataTable from '../DataTable/DataTable';

const stringFormatter = new StringFormatter();

const LoadCancellationList = ({ loadCancellations }) => {
    const singularEntityName = "Load Cancellation";
    const pluralEntityName = "Load Cancellations";

    //#region useSelectors

    const entityType = useSelector(state => state.auth.entityType);

    //#endregion

    const columns = [
        {
            title: 'Reason',
            dataIndex: 'reason',
            key: 'reason',
            render: (text, record) => { return stringFormatter.toFormattedString("CancellationReason", text, null); },
        },
        {
            title: 'Details',
            dataIndex: 'reasonDetail',
            key: 'reasonDetail',
        },
        {
            title: 'Cancelled By',
            dataIndex: 'entityType',
            key: 'entityType',
            render: (text, record) => { return stringFormatter.toFormattedString("CancellationEntityType", text, null); },
        },
        {
            title: 'Cancelled By',
            dataIndex: 'cancelledByUser',
            key: 'cancelledByUser',
            render: (text, record) => {
                return stringFormatter.toFirstNameLastName(text) + (record.cancelledByAccount && record.cancelledByAccount.name ? ' (' + record.cancelledByAccount.name + ')' : '');
            },
        },
        {
            title: 'Cancelled At',
            dataIndex: 'cancelledAt',
            key: 'cancelledAt',
            render: (text, record) => { return stringFormatter.toFormattedString("MomentDateTime", text, null); },
        },
    ];

    return (
        <CanSee staffOnly={true}>
            <DataTable
                dataSource={loadCancellations}
                columns={columns}
                hidePaging={true}
                footer={false}
                singularEntityName={singularEntityName}
                pluralEntityName={pluralEntityName}
                newEntityAction={null}
                rowKey={record => record.id}
                style={{ backgroundColor: '#ffffff' }}
            />
        </CanSee>
    );
};

export default LoadCancellationList;