import { isListNotEmpty, isNotNullOrUndefined, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import EntityUtils from '../entityUtils';

export const getAccountDTO = (account, accountDocuments = []) => {
    if (isListNotEmpty(accountDocuments)) {
        let filteredAccountDocuments = accountDocuments.filter(document => document.entityId === account.id);
        account.documents = filteredAccountDocuments;

        let { logoFileId, insuranceFileId, w9FileId, insuranceExpiryDate, w9ExpiryDate } = EntityUtils.getAccountFileIdsAndDates(filteredAccountDocuments);

        account.insuranceFileId = isStringNotEmpty(insuranceFileId) ? insuranceFileId : null;
        account.insuranceExpiryDate = isNotNullOrUndefined(insuranceExpiryDate) ? insuranceExpiryDate : null;
        account.w9FileId = isStringNotEmpty(w9FileId) ? w9FileId : null;
        account.w9ExpiryDate = isNotNullOrUndefined(w9ExpiryDate) ? w9ExpiryDate : null;
        account.logoFileId = isStringNotEmpty(logoFileId) ? logoFileId : null;
    }

    return account;
};