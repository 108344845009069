import React, { useState, useMemo } from 'react';
import { LockOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Spin, Typography, Result, Card, message } from "antd";
import { withRouter } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from "../../store/actions/index";
import Form from '../../components/Form/Form';
import FormItemDisplay from '../../components/FormItemDisplay/FormItemDisplay';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import FormItem from '../../components/FormItem/FormItem';

const { Title } = Typography;

const ChangePassword = (props) => {
    //#region regex

    const uppercaseRegex = RegExp(/[A-Z]/);
    const lowercaseRegex = RegExp(/[a-z]/);
    const numericRegex = RegExp(/\d/);
    const specialCharacterRegex = RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/);

    //#endregion
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.auth.isLoading);
    const error = useSelector(state => state.auth.error);

    //#endregion
    //#region useStates

    const [isChangeCompleted, setIsChangeCompleted] = useState(false);
    const [isChangeSubmitted, setIsChangeSubmitted] = useState(false);

    //#endregion
    //#region onSubmit

    const onSubmit = (data) => {
        setIsChangeSubmitted(true);
        dispatch(actionCreators.changePassword(data.password, data.confirmPassword, data.oldPassword));
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        if (isLoading === false && error === null && isChangeSubmitted === true) {
            message.success('Your password has been updated successfully.');
            setIsChangeCompleted(true);
            setIsChangeSubmitted(false);
        } else if (error !== null && isChangeSubmitted === true) {
            setIsChangeCompleted(false);
            setIsChangeSubmitted(false);
        }
    }, [isLoading, error, isChangeSubmitted]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            md: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            md: { span: 16 },
        },
    };

    //#endregion

    if (isChangeCompleted === true) {
        return (
            <Card>
                <Result
                    status="success"
                    title="Your Password has been Successfully Changed!"
                />
            </Card>
        );
    } else {
        return (
            <Card>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Row gutter={[4, 4]}>
                        <Col span={24}>
                            <Title level={2} style={{ textAlign: 'center' }}>Change Password</Title>
                        </Col>
                        <Col span={24}>
                            <FormProvider {...methods}>
                                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                                    <FormItem {...formItemLayout} label="Old Password" required format="horizontal"
                                        render={({ onChange, onBlur, value, name, ref }) => <Input.Password onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Old Password" ref={ref} />}
                                        rules={{ required: 'Required Field' }}
                                        name="oldPassword"
                                    />
                                    <FormItem {...formItemLayout} label="New Password" required format="horizontal"
                                        render={({ onChange, onBlur, value, name, ref }) => <Input.Password onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="New Password" ref={ref} />}
                                        rules={{
                                            required: 'Required Field',
                                            minLength: { value: 8, message: 'Password must be at least 8 characters long.' },
                                            validate: {
                                                uppercase: value => uppercaseRegex.test(value) || 'Password must contain at least 1 uppercase letter.',
                                                lowercase: value => lowercaseRegex.test(value) || 'Password must contain at least 1 lowercase letter.',
                                                numeric: value => numericRegex.test(value) || 'Password must contain at least 1 number.',
                                                special: value => specialCharacterRegex.test(value) || 'Password must contain at least 1 special character.'
                                            }
                                        }}
                                        name="password"
                                        help={
                                            <div>
                                                <b>Password Requirements:</b>
                                                <ul>
                                                    <li>Minimum of 8 characters</li>
                                                    <li>At least 1 uppercase letter (A-Z)</li>
                                                    <li>At least 1 lowercase letter (a-z)</li>
                                                    <li>At least 1 number (0-9)</li>
                                                    <li>At least 1 special character (~`!@#$%^&*()+=_-{ }[]\|:;”’?/&lt;>,.)</li>
                                                </ul>
                                            </div>
                                        }
                                    />
                                    <FormItem {...formItemLayout} label="Confirm Password" required format="horizontal"
                                        render={({ onChange, onBlur, value, name, ref }) => <Input.Password onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Confirm Password" ref={ref} />}
                                        rules={{
                                            required: 'Required Field',
                                            validate: {
                                                comparePasswords: value => value === methods.watch('password') || 'Passwords must match' // value is from cpassword and watch will return value from password
                                            }
                                        }}
                                        name="confirmPassword"
                                    />
                                    {error && <ErrorMessage error={error} />}
                                    <FormItemDisplay style={{ textAlign: 'right', marginTop: 20 }}>
                                        <Button type="primary" htmlType="submit" disabled={isLoading === true && isChangeSubmitted === true && error === null}>
                                            Change Password
                                        </Button>
                                    </FormItemDisplay>
                                </Form>
                            </FormProvider>
                        </Col>
                    </Row>
                </Spin>
            </Card>
        );
    }
};

export default withRouter(ChangePassword);