import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { Alert, Input, Spin, Select, Switch } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions";
import UploadFile from "../UploadFile/UploadFile";
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import FormItemFile from '../FormItemFile/FormItemFile';
import FormButtons from '../FormButtons/FormButtons';
import { isNotNullOrUndefined, isObjectEmpty, isObjectNotEmpty, isStringNotEmpty, removeEmpty } from '../../shared/objectUtils';
import { checkEmailAddressReducer, checkPhoneNumberReducer, checkEmailAddress, checkPhoneNumber, isValidEmailAddress } from '../../shared/formUtils';
import Fieldset from '../FormFieldset/FormFieldset';
import axios from 'axios';
import { LoadingOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';

const { Option } = Select;
const { TextArea } = Input;

const NewUser = ({ cancel }) => {
    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.users.isRecordAddLoading);
    const error = useSelector(state => state.users.addRecordError);
    const states = useSelector(state => state.orchestrator.states);
    const isAdmin = useSelector(state => state.auth.isAdmin);
    const isVP = useSelector(state => state.auth.isVP);

    const [{ emailAddressExists, checkEmailAddressHasError, isCheckEmailAddressLoading, checkEmailAddressError }, checkEmailAddressDispatch] = useReducer(checkEmailAddressReducer, {
        emailAddressExists: null,
        isCheckEmailAddressLoading: false,
        checkEmailAddressHasError: false,
        checkEmailAddressError: null,
    });

    const [{ phoneNumberExists, checkPhoneNumberHasError, isCheckPhoneNumberLoading, checkPhoneNumberError }, checkPhoneNumberDispatch] = useReducer(checkPhoneNumberReducer, {
        phoneNumberExists: null,
        isCheckPhoneNumberLoading: false,
        checkPhoneNumberHasError: false,
        checkPhoneNumberError: null,
    });

    const [profileImage, setProfileImage] = useState(null);
    const [emailAddress, setEmailAddress] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [hasValidEmailAddress, setHasValidEmailAddres] = useState(false);
    const [hasValidPhoneNumber, setHasValidPhoneNumber] = useState(false);

    useEffect(() => {
        // console.log(`emailAddress: ${emailAddress}`);
        const { cancel, token } = axios.CancelToken.source();
        const timeOutId = setTimeout(() => {
            if (isStringNotEmpty(emailAddress) && emailAddress.length >= 4) {
                if (isValidEmailAddress(emailAddress)) {
                    checkEmailAddress(emailAddress, null, checkEmailAddressDispatch, token);
                }
            }
        }, 500);
        return () => cancel("Searching...") || clearTimeout(timeOutId);
    }, [emailAddress]);

    useEffect(() => {
        // console.log(`phoneNumber: ${phoneNumber}`);
        const { cancel, token } = axios.CancelToken.source();
        const timeOutId = setTimeout(() => {
            if (isStringNotEmpty(phoneNumber) && phoneNumber.length === 10) {
                checkPhoneNumber(phoneNumber, null, checkPhoneNumberDispatch, token);
            }
        }, 500);
        return () => cancel("Searching...") || clearTimeout(timeOutId);
    }, [phoneNumber]);

    const onSubmit = (data) => {
        let cleanedData = removeEmpty(data);

        if (isObjectNotEmpty(cleanedData)) {
            let emailAndOrPhoneAreValid = false;
            if (isStringNotEmpty(cleanedData.email)) {
                if (hasValidEmailAddress === true) {
                    emailAndOrPhoneAreValid = true;
                } else {
                    emailAndOrPhoneAreValid = false;
                }
            }
            if (isStringNotEmpty(cleanedData.phone)) {
                if (hasValidPhoneNumber === true) {
                    emailAndOrPhoneAreValid = true;
                } else {
                    emailAndOrPhoneAreValid = false;
                }
            }

            if (isNotNullOrUndefined(profileImage)) {
                cleanedData.profileImage = profileImage;
            }

            if (emailAndOrPhoneAreValid === true) {
                cleanedData.isActive = true;
                dispatch(actionCreators.addUser(cleanedData));
            }
        } else {
            onCancel();
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.addUserCancel());
        cancel();
    };

    const onEmailAddressChange = (value) => {
        if (isStringNotEmpty(value) && value.length >= 4) {
            if (isObjectNotEmpty(methods.errors.email)) {
                checkEmailAddressDispatch({ type: "FETCH_FAILURE", payload: { error: 'You must first enter a valid email address' } });
            }
            // console.log(value);
            setEmailAddress(value);
        } else {
            checkEmailAddressDispatch({ type: "FETCH_CLEAR" });
        }
    };

    const onPhoneNumberChange = (value) => {
        if (isStringNotEmpty(value) && value.length === 10) {
            if (isObjectNotEmpty(methods.errors.phone)) {
                checkPhoneNumberDispatch({ type: "FETCH_FAILURE", payload: { error: 'You must first enter a valid phone number' } });
            }
            // console.log(value);
            setPhoneNumber(value);
        } else {
            checkPhoneNumberDispatch({ type: "FETCH_CLEAR" });
        }
    };

    useEffect(() => {
        // console.log(emailAddress);
        // console.log(methods.errors.email);
        // console.log(emailAddressExists);
        if (isStringNotEmpty(emailAddress)) {
            if (isObjectEmpty(methods.errors.email)) {
                if (emailAddressExists !== null && emailAddressExists === false) {
                    setHasValidEmailAddres(true);
                    // console.log('Has a valid email address');
                } else {
                    setHasValidEmailAddres(false);
                }
            } else {
                setHasValidEmailAddres(false);
                checkEmailAddressDispatch({ type: "FETCH_CLEAR" });
            }
        } else {
            setHasValidEmailAddres(false);
            checkEmailAddressDispatch({ type: "FETCH_CLEAR" });
        }
    }, [methods.errors, emailAddress, emailAddressExists]);

    useEffect(() => {
        // console.log(phoneNumber);
        // console.log(methods.errors.phone);
        // console.log(phoneNumberExists);
        if (isStringNotEmpty(phoneNumber)) {
            if (isObjectEmpty(methods.errors.phone)) {
                if (phoneNumberExists !== null && phoneNumberExists === false) {
                    setHasValidPhoneNumber(true);
                    // console.log('Has a valid phone number');
                } else {
                    setHasValidPhoneNumber(false);
                }
            } else {
                setHasValidPhoneNumber(false);
                checkPhoneNumberDispatch({ type: "FETCH_CLEAR" });
            }
        } else {
            setHasValidPhoneNumber(false);
            checkPhoneNumberDispatch({ type: "FETCH_CLEAR" });
        }
    }, [methods.errors, phoneNumber, phoneNumberExists]);

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.addUserErrorClear());
        dispatch(actionCreators.getStates());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Fieldset legend="Email or Phone Number are Required">
                        <FormItem {...formItemLayout} label="Email Address"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Input
                                    placeholder="Email Address"
                                    addonAfter={isCheckEmailAddressLoading ? <LoadingOutlined /> : <MailOutlined />}
                                    onBlur={onBlur}
                                    onChange={e => { onEmailAddressChange(e.target.value); onChange(e.target.value); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            rules={{
                                required: false,
                                minLength: { value: 4, message: 'Email Address must be at least 4 characters long' },
                                pattern: {
                                    value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                    message: "Please enter a valid email address"
                                }
                            }}
                            name="email"
                        />
                        {checkEmailAddressHasError && <Alert message={checkEmailAddressError} type="error" />}
                        <FormItem {...formItemLayout} label="Phone #"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Input
                                    placeholder="Phone #"
                                    addonAfter={isCheckPhoneNumberLoading ? <LoadingOutlined /> : <PhoneOutlined />}
                                    onBlur={onBlur}
                                    onChange={e => { onPhoneNumberChange(e.target.value); onChange(e.target.value); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            rules={{
                                required: false,
                                pattern: {
                                    value: /^\d{10}$/,
                                    message: "Please enter a valid 10 digit phone number with no special characters"
                                }
                            }}
                            name="phone"
                            defaultValue={''}
                        />
                        {checkPhoneNumberHasError && <Alert message={checkPhoneNumberError} type="error" />}
                    </Fieldset>
                    {(hasValidEmailAddress === true || hasValidPhoneNumber === true) ? (
                        <>
                            <Fieldset legend="Additional User Information">
                                <FormItem {...formItemLayout} label="First Name" required
                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="First Name" ref={ref} />}
                                    rules={{ required: "Required Field" }}
                                    name="firstName"
                                />
                                <FormItem {...formItemLayout} label="Middle Name"
                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Middle Name" ref={ref} />}
                                    rules={{ required: false }}
                                    name="middleName"
                                />
                                <FormItem {...formItemLayout} label="Last Name" required
                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Last Name" ref={ref} />}
                                    rules={{ required: "Required Field" }}
                                    name="lastName"
                                />
                                <FormItemFile {...formItemLayout} label="Profile Image" name="profileImage">
                                    <UploadFile
                                        beforeUpload={(file) => {
                                            setProfileImage(file);
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            setProfileImage(null);
                                        }}
                                        message="Please upload your profile photo."
                                    />
                                </FormItemFile>
                                <FormItem {...formItemLayout} label="Bio"
                                    render={({ onChange, onBlur, value, name, ref }) => <TextArea onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} autoSize={{ minRows: 4 }} ref={ref} />}
                                    rules={{ required: false }}
                                    name="bio"
                                />
                            </Fieldset>
                            <Fieldset legend="Personal Address" isCollapsable={true}>
                                <FormItem {...formItemLayout} label="Street Address 1"
                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 1" ref={ref} />}
                                    rules={{ required: false }}
                                    name="streetAddress1"
                                />
                                <FormItem {...formItemLayout} label="Street Address 2"
                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 2" ref={ref} />}
                                    rules={{ required: false }}
                                    name="streetAddress2"
                                />
                                <FormItem {...formItemLayout} label="City"
                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="City" ref={ref} />}
                                    rules={{ required: false }}
                                    name="city"
                                />
                                <FormItem {...formItemLayout} label="State"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Select
                                            placeholder="Please Select a State"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChange(selected); }}
                                            value={value}
                                            name={name}
                                            showSearch={true}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            ref={ref}
                                        >
                                            {states.map(i => <Option value={i.stateAbbr} key={i.stateAbbr}>{i.stateName}</Option>)}
                                        </Select>
                                    )}
                                    rules={{ required: false }}
                                    name="state"
                                />
                                <FormItem {...formItemLayout} label="Postal Code"
                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Postal Code" ref={ref} />}
                                    rules={{ required: false }}
                                    name="postalCode"
                                />
                                <FormItem {...formItemLayout} label="Country"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Select
                                            placeholder="Please Select a Country"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChange(selected); }}
                                            value={value}
                                            name={name}
                                            showSearch={true}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            ref={ref}
                                        >
                                            <Option value="USA" key="USA">United States</Option>
                                        </Select>
                                    )}
                                    rules={{ required: false }}
                                    name="country"
                                    defaultValue={'USA'}
                                />
                            </Fieldset>
                            {(isAdmin === true || isVP === true) && (
                                <Fieldset legend="Roles">
                                    {(isAdmin === true) ? (
                                        <FormItem {...formItemLayout} label="Is Admin"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Switch
                                                    checkedChildren="Yes"
                                                    unCheckedChildren="No"
                                                    onBlur={onBlur}
                                                    onChange={(checked, event) => { onChange(checked); }}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name="isAdmin"
                                            defaultValue={false}
                                        />
                                    ) : null}
                                    {(isAdmin === true) ? (
                                        <FormItem {...formItemLayout} label="Is VP"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Switch
                                                    checkedChildren="Yes"
                                                    unCheckedChildren="No"
                                                    onBlur={onBlur}
                                                    onChange={(checked, event) => { onChange(checked); }}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name="isVP"
                                            defaultValue={false}
                                        />
                                    ) : null}
                                    {(isAdmin === true || isVP === true) ? (
                                        <FormItem {...formItemLayout} label="Is Director"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Switch
                                                    checkedChildren="Yes"
                                                    unCheckedChildren="No"
                                                    onBlur={onBlur}
                                                    onChange={(checked, event) => { onChange(checked); }}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name="isDirector"
                                            defaultValue={false}
                                        />
                                    ) : null}
                                    {(isAdmin === true || isVP === true) ? (
                                        <FormItem {...formItemLayout} label="Is Manager"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Switch
                                                    checkedChildren="Yes"
                                                    unCheckedChildren="No"
                                                    onBlur={onBlur}
                                                    onChange={(checked, event) => { onChange(checked); }}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name="isManager"
                                            defaultValue={false}
                                        />
                                    ) : null}
                                    {(isAdmin === true || isVP === true) ? (
                                        <FormItem {...formItemLayout} label="Is Staff"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Switch
                                                    checkedChildren="Yes"
                                                    unCheckedChildren="No"
                                                    onBlur={onBlur}
                                                    onChange={(checked, event) => { onChange(checked); }}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name="isStaff"
                                            defaultValue={false}
                                        />
                                    ) : null}
                                </Fieldset>
                            )}
                        </>
                    ) : null}
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} cancelDisabled={(isLoading === true && error === null)} submitDisabled={(isLoading === true && error === null) || (hasValidEmailAddress === false && hasValidPhoneNumber === false)} submitText="Create User" />
            </Form>
        </FormProvider>
    );
};

export default NewUser;