import React, { useMemo } from 'react';
import { Alert, Input, Spin, Select } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Enums from '../../shared/enums';
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import { isObjectNotEmpty } from '../../shared/objectUtils';

const { TextArea } = Input;

const EditNote = ({ note, cancel }) => {
    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.notes.isRecordUpdateLoading);
    const error = useSelector(state => state.notes.updateRecordError);

    const noteVisibilityOptions = Enums.NoteVisibilities.selectListOptions();

    const onSubmit = (data) => {
        data.entityId = note.entityId;
        data.entityType = note.entityType;

        let payload = {};
        let objectToUpdate = { ...note };
        for (let [key, value] of Object.entries(data)) {
            let oldValue = objectToUpdate[key] !== undefined && objectToUpdate[key] !== null ? objectToUpdate[key] : '';
            if (value !== oldValue) {
                //console.log(`${key}: new value - ${value}, old value - ${oldValue}`);
                payload[key] = value;
            }
        }

        if (isObjectNotEmpty(payload)) {
            dispatch(actionCreators.updateNote(note.id, payload));
        } else {
            onCancel();
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.updateNoteCancel());
        cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateNoteErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <FormItem {...formItemLayout} label="Note" required
                        render={({ onChange, onBlur, value, name, ref }) => <TextArea onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} autoSize={{ minRows: 8 }} ref={ref} />}
                        rules={{ required: 'Required Field' }}
                        name="body"
                        defaultValue={note.body}
                    />
                    <FormItem {...formItemLayout} label="Who can see this note?" required
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Select
                                placeholder="Please Select the Visibility Level"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                                ref={ref}
                            >
                                {noteVisibilityOptions}
                            </Select>
                        )}
                        rules={{ required: 'Required Field' }}
                        name="visibleTo"
                        defaultValue={note.visibleTo}
                    />
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Update Note" />
            </Form>
        </FormProvider>
    );
};

export default EditNote;