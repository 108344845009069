import Checks from '../entitlementUtils';

export const canCreateDocument = (auth) => {
    return Checks.isAuthenticated(auth);
};

export const canReadDocument = (auth) => {
    return Checks.isAuthenticated(auth);
};

export const canReadDocumentList = (auth) => {
    return Checks.isAuthenticated(auth);
};

export const canUpdateDocument = (auth) => {
    return Checks.isAuthenticated(auth);
};

export const canDeleteDocument = (auth) => {
    return Checks.allowStaffAdmin(auth);
};