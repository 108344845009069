import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { CheckOutlined, ControlOutlined, DeleteOutlined, DownOutlined, EditOutlined } from '@ant-design/icons';
import { Drawer, Button, Dropdown, Menu } from 'antd';
import Note from "../../components/Note/Note";
import EditNote from "../../components/EditNote/EditNote";
import NewNote from "../../components/NewNote/NewNote";
import ResolveNote from "../../components/ResolveNote/ResolveNote";
import RemoveNote from "../../components/RemoveNote/RemoveNote";
import classes from './NoteList.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools } from '@fortawesome/free-solid-svg-icons';
import DataTableTitle from '../../components/DataTableTitle/DataTableTitle';
import StringFormatter from '../../shared/stringFormatter';
import DataTable from '../DataTable/DataTable';
import { isStringEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import NoteUtils from '../../api/utils/noteUtils';
import { selectListIsLoading, selectListRecords } from '../../store/utility';

const stringFormatter = new StringFormatter();

const NoteList = ({ noteEntityType, noteEntityId, tableTitle, style = {} }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    const singularEntityName = "Note";
    const pluralEntityName = "Notes";

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isAdmin = useSelector(state => state.auth.isAdmin);
    const userEntityType = useSelector(state => state.auth.entityType);
    const entityNotes = useSelector(state => selectListRecords(state.notes.lists, noteEntityId));
    const isLoading = useSelector(state => selectListIsLoading(state.notes.lists, noteEntityId));

    //#endregion
    //#region useStates

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showResolveEntity, setShowResolveEntity] = useState(false);
    const [showRemoveEntity, setShowRemoveEntity] = useState(false);
    const [visibleNotes, setVisibleNotes] = useState([]);

    //#endregion
    //#region table displays

    const menu = (record) => {
        return (
            <Menu>
                <Menu.Item key="resolveNote" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); toggleResolveEntity(); }}>
                    <CheckOutlined style={{ marginRight: 8 }} />
                    <span>Resolve {singularEntityName}</span>
                </Menu.Item>
                <Menu.Item key="editNote" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); toggleEditEntity(); }}>
                    <EditOutlined style={{ marginRight: 8 }} />
                    <span>Edit {singularEntityName} Details</span>
                </Menu.Item>
                {(isAdmin === true) ? (
                    <Menu.Item key="deleteNote" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); toggleRemoveEntity(); }}>
                        <DeleteOutlined style={{ marginRight: 8 }} />
                        <span>Delete {singularEntityName}</span>
                    </Menu.Item>
                ) : null}
            </Menu>
        );
    };

    const columns = [
        {
            title: 'Note',
            dataIndex: 'body',
            key: 'body',
        }
    ];

    if (userEntityType === "STAFF") {
        columns.unshift({
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            width: 100,
            render: (text, record) => {
                return (
                    <Dropdown overlay={menu(record)} trigger={['click']}>
                        <Button onClick={(e) => { e.stopPropagation(); }}><ControlOutlined /> <DownOutlined /></Button>
                    </Dropdown>
                );
            },
            align: 'center',
        });

        columns.push({
            title: 'Is Resolved',
            dataIndex: 'isResolved',
            key: 'isResolved',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        });
        columns.push({
            title: 'Resolved At',
            dataIndex: 'resolvedAt',
            key: 'resolvedAt',
            render: (text, record) => { return stringFormatter.toFormattedString("MomentDateTime", text, null); },
        });
        columns.push({
            title: 'Resolved By',
            dataIndex: 'resolvedBy',
            key: 'resolvedBy',
        });
    }

    if (isStringEmpty(noteEntityType)) {
        columns.push({
            title: 'Entity Type',
            dataIndex: 'entityType',
            key: 'entityType',
            render: (text, record) => { return stringFormatter.toFormattedString("NoteEntityType", text, null); },
        });
        columns.push({
            title: 'Entity Id',
            dataIndex: 'entityId',
            key: 'entityId',
        });
    }

    //#endregion
    //#region toggles

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleResolveEntity = () => {
        setShowResolveEntity(!showResolveEntity);
    };

    const toggleRemoveEntity = () => {
        setShowRemoveEntity(!showRemoveEntity);
    };

    //#endregion
    //#region displays

    const newEntityComponents = (
        <Drawer
            title={"New " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleNewEntity}
            visible={showNewEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewNote
                cancel={toggleNewEntity}
                noteEntityType={noteEntityType}
                noteEntityId={noteEntityId}
            />
        </Drawer>
    );

    const resolveEntityComponents = (
        <Drawer
            title={"Resolve " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleResolveEntity}
            visible={showResolveEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <ResolveNote
                cancel={toggleResolveEntity}
                note={selectedRecord}
            />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName + " Details"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditEntity}
            visible={showEditEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditNote
                cancel={toggleEditEntity}
                note={selectedRecord}
            />
        </Drawer>
    );

    const removeEntityComponents = (
        <Drawer
            title={"Delete " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleRemoveEntity}
            visible={showRemoveEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <RemoveNote
                cancel={toggleRemoveEntity}
                note={selectedRecord}
            />
        </Drawer>
    );

    //#endregion
    //#region useMemos and useEffects

    useMemo(() => {
        let listName = "ALL";
        if (isStringNotEmpty(noteEntityId)) {
            listName = noteEntityId;
        }

        dispatch(actionCreators.fetchBulkNoteList(listName, noteEntityId));
    }, [noteEntityId]);

    useEffect(() => {
        //console.log(`entityNotes: ${JSON.stringify(entityNotes)}`);
        let filteredNotes = NoteUtils.getVisibleNotes(entityNotes, userEntityType, isAdmin);
        setVisibleNotes(filteredNotes);

        return () => {
        };
    }, [entityNotes, userEntityType, isAdmin]);

    //#endregion

    return (
        <DataTable
            bordered={true}
            dataSource={visibleNotes}
            columns={columns}
            loading={isLoading === true}
            pagination={{ pageSize: 10 }}
            rowClassName={classes.dataTableRow}
            expandedRowRender={record => (<Note note={record} />)}
            expandRowByClick={true}
            title={() => (<DataTableTitle tableTitle={tableTitle} singularEntityName={singularEntityName} pluralEntityName={pluralEntityName} action={isStringNotEmpty(noteEntityType) ? toggleNewEntity : null} />)}
            footer={false}
            style={{ backgroundColor: '#ffffff', ...style }}
            rowKey={record => record.id}
        >
            {newEntityComponents}
            {editEntityComponents}
            {resolveEntityComponents}
            {removeEntityComponents}
        </DataTable>
    );
};

export default NoteList;