import React from 'react';
import classes from './TitleBlockWithIcon.module.scss';

const TitleBlockWithIcon = ({ icon = null, title, style = null }) => {
    return (
        <div className={classes.titleBlockWithIcon} style={style}>
            <div className={classes.container}>
                {icon ? (
                    <div className={classes.iconBlock}>
                        {icon}
                    </div>
                ) : null}
                <div className={classes.titleBlock}>
                    {title}
                </div>
            </div>
        </div>
    );
};

export default TitleBlockWithIcon;