import React, { useMemo, useState } from 'react';
import { DollarOutlined, DeliveredProcedureOutlined, FileDoneOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Row, Col, Button, Drawer, Steps, Alert, Space, Input, Radio, Spin, Modal, Tooltip } from 'antd';
import StringFormatter from '../../shared/stringFormatter';
import Enums from '../../shared/enums';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from "../../store/actions/index";
import { isListNotEmpty, isNotNullOrUndefined, isObjectNotEmpty, isStringNotEmpty, isStringEmpty, isListEmpty, isObjectEmpty, isNumberNotEmpty, isNullOrUndefined, isBooleanTrue } from '../../shared/objectUtils';
import { CanSee } from '../../shared/entitlements/entitlements';
import Header from '../Header/Header';
import InvoiceTable from '../InvoiceTable/InvoiceTable';
import EditMissingPedigreeInformation from '../EditMissingPedigreeInformation/EditMissingPedigreeInformation';
import DocumentUtils from '../../api/utils/documentUtils';
import { orderBy } from 'lodash';
import EditMissingDocument from '../EditMissingDocument/EditMissingDocument';
import ReviewDocument from '../ReviewDocument/ReviewDocument';
import Document from '../Document/Document';
import FormItemDisplay from '../FormItemDisplay/FormItemDisplay';
import Fieldset from '../FormFieldset/FormFieldset';
import { FormProvider, useForm } from 'react-hook-form';
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import moment from 'moment';
import DataLinkRow from '../DataLinkRow/DataLinkRow';
import business from 'moment-business';
import { selectListIsLoading, selectListRecords } from '../../store/utility';
import InvoiceUtils from '../../api/utils/invoiceUtils';
import LoadUtils from '../../api/utils/loadUtils';
import * as Data from '../../api/data';
import Invoice from '../Invoice/Invoice';
import FormItemAddress from '../FormItemAddress/FormItemAddress';
import FormItemPointOfContact from '../FormItemPointOfContact/FormItemPointOfContact';
import DataContactInfoRow from '../DataContactInfoRow/DataContactInfoRow';
import DataAddressRow from '../DataAddressRow/DataAddressRow';
import NewLinkedFactoring from '../NewLinkedFactoring/NewLinkedFactoring';
import DataRow from '../DataRow/DataRow';

const stringFormatter = new StringFormatter();

const LoadCompletionCarrier = ({ load, stops, invoices, invoiceLineItems, transactions, claims, loadId, shipperId, carrierId, serviceType, onTabChange }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    //#endregion
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: false });
    const methods2 = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: false });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const documentLists = useSelector(state => state.documents.lists);
    const isLoadingDocuments = useSelector(state => selectListIsLoading(state.documents.lists, loadId));
    const isAdmin = useSelector(state => state.auth.isAdmin);
    const entityType = useSelector(state => state.auth.entityType);
    const userId = useSelector(state => state.auth.userId);
    const lineItemTypes = useSelector(state => state.orchestrator.lineItemTypes);
    const accounts = useSelector(state => state.orchestrator.accounts);
    const linkedAccounts = useSelector(state => selectListRecords(state.orchestrator.linkedAccounts, carrierId));
    const isLoadingAddInvoice = useSelector(state => state.invoices.isRecordAddLoading);
    const errorAddInvoice = useSelector(state => state.invoices.addRecordError);
    const noteLists = useSelector(state => state.notes.lists);
    const isLoadingNotes = useSelector(state => selectListIsLoading(state.notes.lists, loadId));

    //#endregion
    //#region useStates

    const [currentStep, setCurrentStep] = useState(0);
    const [documents, setDocuments] = useState([]);
    const [pendingApprovalDocuments, setPendingApprovalDocuments] = useState([]);
    const [missingDocuments, setMissingDocuments] = useState([]);
    const [missingPedigreeInformation, setMissingPedigreeInformation] = useState([]);
    const [showEditMissingPedigreeInformation, setShowEditMissingPedigreeInformation] = useState(false);
    const [selectedMissingPedigreeInformation, setSelectedMissingPedigreeInformation] = useState(null);
    const [showEditMissingDocument, setShowEditMissingDocument] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [showReviewDocument, setShowReviewDocument] = useState(false);
    const [showViewDocument, setShowViewDocument] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [remittanceMethod, setRemittanceMethod] = useState(null);
    const [newInvoiceLineItemList, setNewInvoiceLineItemList] = useState([]);
    const [rateConfirmationData, setRateConfirmationData] = useState(null);
    const [carrierInvoiceDocument, setCarrierInvoiceDocument] = useState({});
    const [bankAccountDocument, setBankAccountDocument] = useState({});
    const [factoringNOADocument, setFactoringNOADocument] = useState({});
    const [rateConfirmationDocument, setRateConfirmationDocument] = useState({});
    const [rateConfirmation, setRateConfirmation] = useState({});
    const [shipperAccount, setShipperAccount] = useState(null);
    const [invoice, setInvoice] = useState(null);
    const [carrierAccount, setCarrierAccount] = useState(null);
    const [selectedInvoice, setSelectedInvoice] = useState({});
    const [showViewInvoiceModal, setShowViewInvoiceModal] = useState(false);
    const [showNewLinkedFactoring, setShowNewLinkedFactoring] = useState(false);

    //#endregion
    //#region step methods

    const nextStep = () => {
        onChangeStep(currentStep + 1);
    };

    const prevStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const onChangeStep = (current) => {
        setCurrentStep(current);
    };

    //#endregion
    //#region linked account methods

    const selectFactoring = (linkedAccount) => {
        if (isObjectNotEmpty(linkedAccount)) {
            if (isStringNotEmpty(linkedAccount.name)) {
                methods2.setValue('remitName', linkedAccount.name);
            }

            if (isObjectNotEmpty(linkedAccount.accountsReceivable)) {
                if (isStringNotEmpty(linkedAccount.accountsReceivable.firstName)) {
                    methods2.setValue('remitPointOfContact.firstName', linkedAccount.accountsReceivable.firstName);
                }
                if (isStringNotEmpty(linkedAccount.accountsReceivable.lastName)) {
                    methods2.setValue('remitPointOfContact.lastName', linkedAccount.accountsReceivable.lastName);
                }
                if (isStringNotEmpty(linkedAccount.accountsReceivable.email)) {
                    methods2.setValue('remitPointOfContact.email', linkedAccount.accountsReceivable.email);
                }
                if (isStringNotEmpty(linkedAccount.accountsReceivable.phone)) {
                    methods2.setValue('remitPointOfContact.phone', linkedAccount.accountsReceivable.phone);
                }
                if (isStringNotEmpty(linkedAccount.accountsReceivable.phoneExt)) {
                    methods2.setValue('remitPointOfContact.phoneExt', linkedAccount.accountsReceivable.phoneExt);
                }
            }

            if (isObjectNotEmpty(linkedAccount.accountsReceivableAddress)) {
                if (isStringNotEmpty(linkedAccount.accountsReceivableAddress.streetAddress1)) {
                    methods2.setValue('remitAddress.streetAddress1', linkedAccount.accountsReceivableAddress.streetAddress1);
                }
                if (isStringNotEmpty(linkedAccount.accountsReceivableAddress.streetAddress2)) {
                    methods2.setValue('remitAddress.streetAddress2', linkedAccount.accountsReceivableAddress.streetAddress2);
                }
                if (isStringNotEmpty(linkedAccount.accountsReceivableAddress.city)) {
                    methods2.setValue('remitAddress.city', linkedAccount.accountsReceivableAddress.city);
                }
                if (isStringNotEmpty(linkedAccount.accountsReceivableAddress.state)) {
                    methods2.setValue('remitAddress.state', linkedAccount.accountsReceivableAddress.state);
                }
                if (isStringNotEmpty(linkedAccount.accountsReceivableAddress.postalCode)) {
                    methods2.setValue('remitAddress.postalCode', linkedAccount.accountsReceivableAddress.postalCode);
                }
                if (isStringNotEmpty(linkedAccount.accountsReceivableAddress.country)) {
                    methods2.setValue('remitAddress.country', linkedAccount.accountsReceivableAddress.country);
                }
            }
        }
    };

    //#endregion
    //#region invoiceLineItem methods

    const generateQuickPayInvoiceLineItems = () => {
        // create invoiceLineItems
        let newInvoiceLineItems = [];
        if (isListNotEmpty(lineItemTypes) && isStringNotEmpty(serviceType)) {

            let lineItemType = lineItemTypes.find(j => j.name === 'QUICK_PAY');
            if (isObjectNotEmpty(lineItemType)) {
                const { totalAmount, lineItems, invoiceLineItemList } = InvoiceUtils.getInvoiceLineItemsAndTotal('CARRIER_INVOICE', invoiceLineItems, serviceType === "TMS" ? "SHIPPER" : "STAFF", "CARRIER");

                let lineItemBaseAmount = 0.05 * totalAmount;
                let quantity = 1;
                let lineItemTotalAmount = lineItemBaseAmount * quantity;
                let newInvoiceLineItem = {
                    description: lineItemType.description + ' (5% fee)',
                    loadId: load.id,
                    itemType: 'QUICK_PAY',
                    isIrisFee: false,
                    baseAmount: lineItemBaseAmount.toFixed(2),
                    baseAmountUnit: 'USD',
                    quantity: quantity,
                    quantityUnit: 'PER_LOAD',
                    totalAmount: lineItemTotalAmount.toFixed(2),
                    totalAmountUnit: 'USD',
                    status: 'PENDING',
                    approvalStatus: 'APPROVED',
                    isOpen: true,
                    isDeleted: false,
                    fromEntityType: 'CARRIER',
                    fromEntityId: load.assignedCarrierId,
                    toEntityType: 'STAFF'
                };

                newInvoiceLineItems.push(newInvoiceLineItem);
            }

            setNewInvoiceLineItemList([...newInvoiceLineItems]);
        }
    };

    //#endregion
    //#region onSubmit

    const onSubmitRateConfirmationStep = (data) => {
        console.log(data);
        setRateConfirmationData(data);
        onChangeStep(2);
    };

    const onSubmitCarrierInvoice = async (data) => {
        if (isObjectNotEmpty(load) && isObjectNotEmpty(rateConfirmationData) && isObjectNotEmpty(data) && isObjectNotEmpty(shipperAccount) && isObjectNotEmpty(carrierAccount) && isStringNotEmpty(serviceType) && isStringNotEmpty(paymentMethod)) {
            let paymentTerms = 30;
            let paymentDueDate = moment().add(30, 'days');
            if (paymentMethod === 'QUICK_PAY') {
                paymentTerms = 3;
                paymentDueDate = business.addWeekDays(moment(), 3)
            }

            let billToName = null;
            let billToAddress = {};
            let billToPointOfContact = {};
            if (serviceType === "TMS") {
                billToName = isStringNotEmpty(shipperAccount.name) ? shipperAccount.name : null;
                billToAddress = isObjectNotEmpty(shipperAccount.accountsPayableAddress) ? shipperAccount.accountsPayableAddress : {};
                billToPointOfContact = isObjectNotEmpty(shipperAccount.accountsPayable) ? shipperAccount.accountsPayable : {};

                let link = isListNotEmpty(shipperAccount.links) ? shipperAccount.links.find(l => l.accountId === carrierAccount.id) : null;
                if (isObjectNotEmpty(link)) {
                    if (isStringNotEmpty(link.name)) {
                        billToName = link.name;
                    }
                    if (isObjectNotEmpty(link.accountsPayableAddress)) {
                        billToAddress = link.accountsPayableAddress;
                    }
                    if (isObjectNotEmpty(link.accountsPayable)) {
                        billToPointOfContact = link.accountsPayable;
                    }
                }
            } else {
                billToName = InvoiceUtils.irisCompanyName;
                billToAddress = InvoiceUtils.irisPaymentAddress;
                billToPointOfContact = InvoiceUtils.irisPointOfContact;
            }

            let invoiceLineItemIds = [];
            let allInvoiceLineItems = [];
            invoiceLineItems.forEach((invoiceLineItem) => {
                invoiceLineItemIds.push(invoiceLineItem.id);
                allInvoiceLineItems.push(invoiceLineItem);
            });

            // if quick pay is chosen, create an invoice line item for it and add it to the invoiceLineItemIds
            if (isListNotEmpty(newInvoiceLineItemList)) {
                let newInvoiceLineItems = await Data.addInvoiceLineItems(newInvoiceLineItemList, invoiceLineItems, transactions, claims, loadId);
                if (isListNotEmpty(newInvoiceLineItems)) {
                    newInvoiceLineItems.forEach((invoiceLineItem) => {
                        invoiceLineItemIds.push(invoiceLineItem.id);
                        allInvoiceLineItems.push(invoiceLineItem);
                    });
                }
            }

            const { totalAmount, lineItems, invoiceLineItemList } = InvoiceUtils.getInvoiceLineItemsAndTotal('CARRIER_INVOICE', allInvoiceLineItems, serviceType === "TMS" ? "SHIPPER" : "STAFF", "CARRIER");

            let newInvoice = {
                customerInvoiceNumber: rateConfirmationData.customerInvoiceNumber,
                accountNumber: isObjectNotEmpty(carrierAccount) && isStringNotEmpty(carrierAccount.irisId) ? carrierAccount.irisId : null,
                loadId: load.id,
                invoiceDate: moment(),
                invoiceNotes: data.invoiceNotes,
                fromEntityType: 'CARRIER',
                fromEntityId: carrierAccount.id,
                fromName: carrierAccount.name,
                fromAddress: carrierAccount.companyAddress,
                fromPointOfContact: { phone: carrierAccount.phone, phoneExt: carrierAccount.phoneExt, email: carrierAccount.email },
                remitName: data.remitName,
                remitAddress: data.remitAddress,
                remitPointOfContact: data.remitPointOfContact,
                toEntityType: serviceType === 'BROKERAGE' ? 'STAFF' : 'SHIPPER',
                toEntityId: serviceType === 'BROKERAGE' ? null : shipperId,
                toName: billToName,
                toAddress: billToAddress,
                toPointOfContact: billToPointOfContact,
                paymentMethod: paymentMethod,
                paymentTerms: paymentTerms,
                paymentDueDate: paymentDueDate,
                paymentStatus: 'PENDING',
                status: 'SENT',
                amountDue: totalAmount,
                amountDueUnit: 'USD',
                balanceDue: totalAmount,
                balanceDueUnit: 'USD',
                invoiceLineItemIds: invoiceLineItemList.map(i => i.id),
                invoiceType: 'CARRIER_INVOICE',
                sentAt: moment(),
                sentBy: userId
            };
            console.log(newInvoice);

            // create carrier invoice
            dispatch(actionCreators.addInvoice(newInvoice, rateConfirmationData.invoiceCorrections, true));
        }
    };

    //#endregion
    //#region table displays

    const rateConfirmationColumns = [
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            textAlign: 'left',
        },
        {
            title: 'Units',
            dataIndex: 'quantity',
            key: 'quantity',
            textAlign: 'center',
            dataType: 'Decimal',
        },
        {
            title: 'Per',
            dataIndex: 'baseAmount',
            key: 'baseAmount',
            textAlign: 'center',
            dataType: 'Money',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
        },
        {
            title: 'Amount',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            textAlign: 'right',
            dataType: 'Money',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
        },
    ];

    //#endregion
    //#region toggles

    const toggleEditMissingPedigreeInformation = () => {
        setShowEditMissingPedigreeInformation(!showEditMissingPedigreeInformation);
    };

    const toggleEditMissingDocument = () => {
        setShowEditMissingDocument(!showEditMissingDocument);
    };

    const toggleReviewDocument = () => {
        setShowReviewDocument(!showReviewDocument);
    };

    const toggleViewDocument = () => {
        setShowViewDocument(!showViewDocument);
    };

    const toggleViewInvoice = () => {
        setShowViewInvoiceModal(!showViewInvoiceModal);
    };

    const toggleNewLinkedFactoring = () => {
        setShowNewLinkedFactoring(!showNewLinkedFactoring);
    };

    //#endregion
    //#region useMemos and useEffects

    useMemo(() => {
        dispatch(actionCreators.getAccounts());
        dispatch(actionCreators.getLineItemTypes());
    }, []);

    useMemo(() => {
        if (isStringNotEmpty(loadId) && isListNotEmpty(invoices)) {
            let carrierInvoiceObj = invoices.find(i => i.invoiceType === 'CARRIER_INVOICE' && i.isDeleted === false);
            if (isObjectNotEmpty(carrierInvoiceObj)) {
                setInvoice(carrierInvoiceObj);
            }

            let rateConfirmationObj = invoices.find(i => i.invoiceType === 'RATE_CONFIRMATION' && i.isDeleted === false);
            if (isObjectNotEmpty(rateConfirmationObj)) {
                setRateConfirmation(rateConfirmationObj);
            }

            let ids = [];
            invoices.forEach((invoice) => {
                ids.push(invoice.id);
            });

            dispatch(actionCreators.fetchBulkNoteLists(loadId, ids));
        }
    }, [loadId, invoices]);

    useMemo(() => {
        if (isObjectNotEmpty(load)) {
            let ids = [];
            ids.push(load.id);
            if (isStringNotEmpty(load.assignedCarrierId)) {
                ids.push(load.assignedCarrierId);
            }

            dispatch(actionCreators.fetchBulkDocumentLists(load.id, ids));
        }
    }, [load]);

    useMemo(() => {
        if (isStringNotEmpty(shipperId) && isListNotEmpty(accounts)) {
            let shipperAccountObj = accounts.find(i => i.id === load.shipperId);
            if (isObjectNotEmpty(shipperAccountObj)) {
                // console.log(shipperAccount);
                setShipperAccount({ ...shipperAccountObj });
            }
        }
    }, [shipperId, accounts]);

    useMemo(() => {
        if (isStringNotEmpty(carrierId) && isListNotEmpty(accounts)) {
            let carrierAccountObj = accounts.find(i => i.id === carrierId);
            if (isObjectNotEmpty(carrierAccountObj)) {
                // console.log(carrierAccountObj);
                setCarrierAccount({ ...carrierAccountObj });
            }

            dispatch(actionCreators.getLinkedAccounts(carrierId, false));
        }
    }, [carrierId, accounts]);

    useMemo(() => {
        if (isObjectNotEmpty(load) && isListNotEmpty(stops) && (entityType === 'STAFF' || (entityType === 'SHIPPER' && load.serviceType === 'TMS' && load.createdByEntityType === 'SHIPPER') || (entityType === 'CARRIER' && load.serviceType === 'TMS' && load.createdByEntityType === 'CARRIER'))) {
            let missingPedigreeInformationList = LoadUtils.getMissingPedigreeInformation(load, stops);
            setMissingPedigreeInformation(missingPedigreeInformationList);
        }
    }, [load, stops, entityType]);

    useMemo(() => {
        if (currentStep === 2 && isObjectNotEmpty(load) && isStringEmpty(paymentMethod) && isObjectNotEmpty(carrierAccount) && isStringNotEmpty(carrierAccount.preferredPaymentMethod)) {
            if (carrierAccount.preferredPaymentMethod === 'QUICK_PAY' && load.serviceType === 'BROKERAGE') {
                setPaymentMethod('QUICK_PAY');
                methods2.setValue('paymentMethod', 'QUICK_PAY');
            }
        }
    }, [carrierAccount, currentStep, load]);

    useMemo(() => {
        if (currentStep === 2 && isStringEmpty(remittanceMethod) && isObjectNotEmpty(carrierAccount) && isStringNotEmpty(carrierAccount.preferredPaymentMethod)) {
            if (carrierAccount.preferredPaymentMethod === 'QUICK_PAY' || carrierAccount.preferredPaymentMethod === 'DIRECT_DEPOSIT' || carrierAccount.preferredPaymentMethod === 'CHECK') {
                setRemittanceMethod('DIRECT');
                methods2.setValue('remittanceMethod', 'DIRECT');
            } else if (carrierAccount.preferredPaymentMethod === 'FACTORING') {
                setRemittanceMethod('FACTORING');
                methods2.setValue('remittanceMethod', 'FACTORING');
            } else {
                setRemittanceMethod(null);
                methods2.setValue('remittanceMethod', null);
            }
        } else {
            setRemittanceMethod(null);
            methods2.setValue('remittanceMethod', null);
        }
    }, [carrierAccount, currentStep]);

    useMemo(() => {
        if (currentStep === 2 && isStringNotEmpty(remittanceMethod) && isStringNotEmpty(serviceType) && isStringEmpty(paymentMethod) && isObjectNotEmpty(carrierAccount) && isStringNotEmpty(carrierAccount.preferredPaymentMethod) && isObjectNotEmpty(shipperAccount)) {
            if (remittanceMethod === 'DIRECT') {
                if (carrierAccount.preferredPaymentMethod === 'QUICK_PAY' && serviceType === 'BROKERAGE' && shipperAccount.isCreditApproved === true) {
                    setPaymentMethod('QUICK_PAY');
                    methods2.setValue('paymentMethod', 'QUICK_PAY');
                } else if (carrierAccount.preferredPaymentMethod === 'DIRECT_DEPOSIT' && serviceType === 'BROKERAGE') {
                    setPaymentMethod('DIRECT_DEPOSIT');
                    methods2.setValue('paymentMethod', 'DIRECT_DEPOSIT');
                } else if (carrierAccount.preferredPaymentMethod === 'CHECK') {
                    setPaymentMethod('CHECK');
                    methods2.setValue('paymentMethod', 'CHECK');
                } else {
                    setPaymentMethod(null);
                    methods2.setValue('paymentMethod', null);
                }
            } else if (remittanceMethod === 'FACTORING') {
                setPaymentMethod('FACTORING');
                methods2.setValue('paymentMethod', 'FACTORING');
            } else {
                setPaymentMethod(null);
                methods2.setValue('paymentMethod', null);
            }
        }
    }, [carrierAccount, serviceType, shipperAccount, remittanceMethod, currentStep]);

    useMemo(() => {
        if (currentStep === 2) {
            if (remittanceMethod === 'DIRECT') {
                setPaymentMethod(null);
                methods2.setValue('paymentMethod', null);
            } else if (remittanceMethod === 'FACTORING') {
                setPaymentMethod('FACTORING');
                methods2.setValue('paymentMethod', 'FACTORING');
            } else {
                setPaymentMethod(null);
                methods2.setValue('paymentMethod', null);
            }
        }
    }, [remittanceMethod, currentStep]);

    useMemo(() => {
        // console.log(`paymentMethod: ${paymentMethod}`);
        if (isStringNotEmpty(paymentMethod)) {
            if (paymentMethod === 'QUICK_PAY') {
                generateQuickPayInvoiceLineItems();
            } else {
                setNewInvoiceLineItemList([]);
            }
        }
    }, [paymentMethod]);

    useMemo(() => {
        if (isObjectNotEmpty(documentLists) && isObjectNotEmpty(load) && isListNotEmpty(stops)) {
            let pendingApprovalDocumentsList = DocumentUtils.selectListPendingApprovalDocumentsByLoadIdAndStops(documentLists, load.id, load, stops, 'CARRIER', isAdmin);
            let missingDocumentsList = DocumentUtils.selectListMissingDocumentsByLoadIdAndStops(documentLists, load.id, load, stops, 'CARRIER', isAdmin);
            let visibleDocumentsList = DocumentUtils.selectListVisibleDocumentsByLoadIdAndStops(documentLists, load.id, load, stops, 'CARRIER', isAdmin);

            let carrierDocuments = isStringNotEmpty(load.assignedCarrierId) && isNotNullOrUndefined(documentLists[load.assignedCarrierId]) && isListNotEmpty(documentLists[load.assignedCarrierId].records) ? [...documentLists[load.assignedCarrierId].records] : null;
            let visibleCarrierDocuments = DocumentUtils.getVisibleDocuments(carrierDocuments, 'CARRIER', isAdmin);
            let bankAccountDocumentObj = visibleCarrierDocuments.find(doc => doc.documentType === 'BANK_INFORMATION' && doc.isDeleted === false);
            if (isObjectNotEmpty(bankAccountDocumentObj)) {
                setBankAccountDocument(bankAccountDocumentObj);
            } else {
                setBankAccountDocument({});
            }

            let factoringNOADocumentObj = visibleCarrierDocuments.find(doc => doc.documentType === 'FACTORING_NOA' && doc.isDeleted === false);
            if (isObjectNotEmpty(factoringNOADocumentObj)) {
                setFactoringNOADocument(factoringNOADocumentObj);
            } else {
                setFactoringNOADocument({});
            }

            let carrierInvoiceDocumentObj = visibleDocumentsList.find(doc => doc.documentType === 'CARRIER_INVOICE' && doc.isDeleted === false);
            if (isObjectNotEmpty(carrierInvoiceDocumentObj)) {
                setCarrierInvoiceDocument(carrierInvoiceDocumentObj);
            } else {
                setCarrierInvoiceDocument({});
            }

            let rateConfirmationDocumentObj = visibleDocumentsList.find(doc => doc.documentType === 'RATE_CONFIRMATION' && doc.isDeleted === false);
            if (isObjectNotEmpty(rateConfirmationDocumentObj)) {
                setRateConfirmationDocument(rateConfirmationDocumentObj);
            } else {
                setRateConfirmationDocument({});
            }

            setDocuments(visibleDocumentsList);
            setPendingApprovalDocuments(pendingApprovalDocumentsList);
            setMissingDocuments(missingDocumentsList);
        }
    }, [documentLists, load, stops, entityType, isAdmin]);

    useMemo(() => {
        if (isLoadingAddInvoice !== null && isLoadingAddInvoice === false && errorAddInvoice === null) {
            if (isNotNullOrUndefined(onTabChange)) {
                onTabChange('financials');
            }
        }
    }, [isLoadingAddInvoice, errorAddInvoice]);

    //#endregion
    //#region displays

    const createMissingDocumentList = () => {
        if (isListNotEmpty(missingDocuments) && isLoadingDocuments === false) {
            return missingDocuments.map((missingDocument, index, arr) => {
                return (
                    <Alert
                        key={`missing-document-${index}`}
                        style={{ marginBottom: 8 }}
                        type="error"
                        message={`${missingDocument.entityName} is missing ${missingDocument.documentDisplayName}`}
                        showIcon={true}
                        action={<Space><Button size="small" type="ghost" onClick={(e) => { setSelectedDocument(missingDocument); toggleEditMissingDocument(); }}>Upload</Button></Space>}
                    />
                );
            });
        } else {
            return null;
        }
    };

    const createPendingApprovalList = () => {
        if (isListNotEmpty(pendingApprovalDocuments) && isLoadingDocuments === false) {
            return pendingApprovalDocuments.map((pendingApprovalDocument, index, arr) => {
                return (
                    <Alert
                        key={`pending-approval-document-${index}`}
                        style={{ marginBottom: 8 }}
                        type="warning"
                        message={`${pendingApprovalDocument.documentDisplayName} is pending document review`}
                        showIcon={true}
                        action={<Space><Button size="small" type="ghost" onClick={(e) => { setSelectedDocument(pendingApprovalDocument); toggleReviewDocument(); }}>Review</Button></Space>}
                    />
                );
            });
        } else {
            return null;
        }
    };

    const createVisibleDocumentsList = () => {
        if (isListNotEmpty(documents) && isLoadingDocuments === false) {
            return documents.filter(doc => doc.documentType !== 'CARRIER_INVOICE' && doc.documentType !== 'SHIPPER_INVOICE' && doc.documentType !== 'FACTORING_INVOICE' && doc.documentType !== 'INVOICE').map((document, index, arr) => {
                return (
                    <Alert
                        key={`visible-document-${index}`}
                        style={{ marginBottom: 8 }}
                        type="info"
                        message={document.documentDisplayName}
                        showIcon={true}
                        action={<Space><Button size="small" type="ghost" onClick={(e) => { setSelectedDocument(document); toggleViewDocument(); }}>View</Button></Space>}
                    />
                );
            });
        } else {
            return null;
        }
    };

    const editMissingPedigreeInformationComponents = (
        <Drawer
            title={"Edit Missing Details for " + (selectedMissingPedigreeInformation ? selectedMissingPedigreeInformation.entityName : "")}
            onClose={toggleEditMissingPedigreeInformation}
            visible={showEditMissingPedigreeInformation === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditMissingPedigreeInformation
                cancel={toggleEditMissingPedigreeInformation}
                missingPedigreeInformation={selectedMissingPedigreeInformation}
                load={load}
            />
        </Drawer>
    );

    const editMissingDocumentComponents = (
        <Drawer
            title={isObjectNotEmpty(selectedDocument) ? `Upload ${selectedDocument.documentDisplayName}` : ""}
            onClose={toggleEditMissingDocument}
            visible={showEditMissingDocument === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditMissingDocument
                cancel={toggleEditMissingDocument}
                missingDocument={selectedDocument}
                load={load}
                loadId={loadId}
            />
        </Drawer>
    );

    const reviewDocumentComponents = (
        <Drawer
            title={isObjectNotEmpty(selectedDocument) ? `Review ${selectedDocument.documentDisplayName}` : ""}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleReviewDocument}
            visible={showReviewDocument === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <ReviewDocument
                cancel={toggleReviewDocument}
                document={selectedDocument}
                documentType={isObjectNotEmpty(selectedDocument) && isStringNotEmpty(selectedDocument.documentType) ? selectedDocument.documentType : null}
                documentEntitySubType={isObjectNotEmpty(selectedDocument) && isStringNotEmpty(selectedDocument.entitySubType) ? selectedDocument.entitySubType : null}
                loadId={isObjectNotEmpty(load) ? load.id : null}
                fileId={isObjectNotEmpty(selectedDocument) && isStringNotEmpty(selectedDocument.id) ? selectedDocument.id : null}
            />
        </Drawer>
    );

    const viewDocumentComponents = (
        <Drawer
            title={isObjectNotEmpty(selectedDocument) && isStringNotEmpty(selectedDocument.documentDisplayName) ? selectedDocument.documentDisplayName : ""}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleViewDocument}
            visible={showViewDocument === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={true}
            maskClosable={false}
        >
            <Document
                displayName={isObjectNotEmpty(selectedDocument) && isStringNotEmpty(selectedDocument.documentDisplayName) ? selectedDocument.documentDisplayName : null}
                fileId={isObjectNotEmpty(selectedDocument) && isStringNotEmpty(selectedDocument.id) ? selectedDocument.id : null}
            />
        </Drawer>
    );

    const viewInvoiceModal = (
        <Modal
            title={isObjectNotEmpty(selectedInvoice) ? Enums.InvoiceTypes.getValueByName(selectedInvoice.invoiceType) : ''}
            visible={showViewInvoiceModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={() => { toggleViewInvoice(); setSelectedInvoice(null); }}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            {isObjectNotEmpty(selectedInvoice) ? (
                <Invoice
                    invoiceId={isObjectNotEmpty(selectedInvoice) && isStringNotEmpty(selectedInvoice.id) ? selectedInvoice.id : null}
                    invoice={isObjectNotEmpty(selectedInvoice) ? selectedInvoice : null}
                    load={load}
                    loadId={loadId}
                    stops={stops}
                    displayManualInstructions={false}
                />
            ) : null}
        </Modal>
    );

    const rateConfirmationDocumentComponents = () => {
        if (isObjectNotEmpty(rateConfirmationDocument)) {
            return (
                <DataLinkRow title="Rate Confirmation Document" fileId={rateConfirmationDocument.id} />
            );
        } else {
            return null;
        }
    };

    const rateConfirmationInvoiceComponents = () => {
        if (isObjectNotEmpty(rateConfirmation)) {
            return (
                <>
                    <span style={{ fontWeight: 'bold' }}><PaperClipOutlined style={{ marginRight: 8 }} />Digital Rate Confirmation:</span><Button type="link" onClick={(e) => { setSelectedInvoice(rateConfirmation); toggleViewInvoice(); }}>view</Button>
                </>
            );
        } else {
            return null;
        }
    };

    const bankAccountDocumentComponents = () => {
        if (paymentMethod === 'DIRECT_DEPOSIT' && load.serviceType === 'BROKERAGE') {
            if (isObjectNotEmpty(bankAccountDocument)) {
                return (
                    <FormItemDisplay {...formItemLayout} label="If you chose Direct Deposit and we don't already have this information, please provide us with your bank account information" format="vertical">
                        <DataLinkRow title="Bank Account Information" fileId={bankAccountDocument.id} />
                    </FormItemDisplay>
                );
            } else {
                return (
                    <FormItemDisplay {...formItemLayout} label="If you chose Direct Deposit and we don't already have this information, please provide us with your bank account information" format="vertical">
                        <Button type="default" onClick={(e) => { setSelectedDocument({ entityType: 'ACCOUNT', entityId: carrierAccount.id, entity: carrierAccount, entitySubType: null, entityName: carrierAccount.name, documentType: 'BANK_INFORMATION', documentTypeName: 'Bank Account Information', documentDisplayName: 'Bank Account Information', visibleTo: 'STAFF' }); toggleEditMissingDocument(); }}>Upload Bank Account Information</Button>
                    </FormItemDisplay>
                );
            }
        } else {
            return null;
        }
    };

    const factoringNOADocumentComponents = () => {
        if (paymentMethod === 'FACTORING') {
            if (isObjectNotEmpty(factoringNOADocument)) {
                return (
                    <FormItemDisplay {...formItemLayout} label="If you chose Factoring and we don't already have the Notice of Assignment for your factoring company, please provide us with the Notice of Assignment" format="vertical">
                        <DataLinkRow title="Factoring Notice of Assignment" fileId={factoringNOADocument.id} />
                    </FormItemDisplay>
                );
            } else {
                return (
                    <FormItemDisplay {...formItemLayout} label="If you chose Factoring and we don't already have the Notice of Assignment for your factoring company, please provide us with the Notice of Assignment" format="vertical">
                        <Button type="default" onClick={(e) => { setSelectedDocument({ entityType: 'ACCOUNT', entityId: carrierAccount.id, entity: carrierAccount, entitySubType: null, entityName: carrierAccount.name, documentType: 'FACTORING_NOA', documentTypeName: 'Factoring Notice of Assignment', documentDisplayName: 'Factoring Notice of Assignment', visibleTo: 'STAFF' }); toggleEditMissingDocument(); }}>Upload Factoring Notice of Assignment</Button>
                    </FormItemDisplay>
                );
            }
        } else {
            return null;
        }
    };

    const carrierInvoiceDocumentComponents = () => {
        if (isObjectNotEmpty(carrierInvoiceDocument)) {
            return (
                <DataLinkRow title="Carrier Invoice" fileId={carrierInvoiceDocument.id} />
            );
        } else {
            return (
                <Button size="small" type="ghost" onClick={(e) => { setSelectedDocument({ entityType: 'LOAD', entityId: load.id, entity: load, entitySubType: null, entityName: isStringNotEmpty(load.parentName) ? load.parentName : (isStringNotEmpty(load.name) ? load.name : load.irisId), documentType: 'CARRIER_INVOICE', documentTypeName: 'Carrier Invoice', documentDisplayName: 'Carrier Invoice', visibleTo: 'CARRIER' }); toggleEditMissingDocument(); }}>Upload Invoice</Button>
            );
        }
    };

    const getInvoiceDocuments = () => {
        if (isListNotEmpty(documents) && isLoadingDocuments === false) {
            return (
                <Row>
                    <Col span={24}>
                        <h3 style={{ paddingLeft: 10, paddingRight: 10 }}>Attached Documents:</h3>

                        <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                            {documents.filter(doc => doc.documentType !== 'CARRIER_INVOICE' && doc.documentType !== 'SHIPPER_INVOICE' && doc.documentType !== 'FACTORING_INVOICE' && doc.documentType !== 'INVOICE').map((document, index, arr) => {
                                return (
                                    <DataLinkRow key={`visible-invoice-document-${index}`} style={{ marginBottom: 8 }} title={document.documentDisplayName} fileId={document.id} />
                                );
                            })}
                        </div>
                    </Col>
                </Row>
            );
        } else {
            return null;
        }
    };

    const missingPedigreeInformationComponents = () => {
        if (isListNotEmpty(missingPedigreeInformation) && isObjectNotEmpty(load) && LoadUtils.loadStatusIsNot(load.loadStatus, ['CLOSED', 'CANCELLED'])) {
            return (
                <Row gutter={[8, 8]} style={{ marginBottom: 8 }}>
                    <Col span={24}>
                        {missingPedigreeInformation.map((missingInfo, index, arr) => {
                            if (missingInfo.entityType === 'STOP') {
                                return (
                                    <Alert
                                        key={`missing-pedigree-information-${index}`}
                                        style={index < arr.length - 1 ? { marginBottom: 8 } : null}
                                        type="error"
                                        message={`${missingInfo.entityName} is missing ${missingInfo.missingFields.map((missingField) => missingField.missingFieldName).join(', ')}`}
                                        showIcon={true}
                                        action={<Space><Button size="small" type="ghost" onClick={(e) => { setSelectedMissingPedigreeInformation(missingInfo); toggleEditMissingPedigreeInformation(); }}>Fix</Button></Space>}
                                    />
                                );
                            } else {
                                return (
                                    <Alert
                                        key={`missing-pedigree-information-${index}`}
                                        style={index < arr.length - 1 ? { marginBottom: 8 } : null}
                                        type="error"
                                        message={`${missingInfo.entityName} is missing ${missingInfo.missingFieldName}`}
                                        showIcon={true}
                                        action={<Space><Button size="small" type="ghost" onClick={(e) => { setSelectedMissingPedigreeInformation(missingInfo); toggleEditMissingPedigreeInformation(); }}>Fix</Button></Space>}
                                    />
                                );
                            }
                        })}
                    </Col>
                </Row>
            );
        } else {
            return null;
        }
    };

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    //#endregion

    if (isObjectNotEmpty(load) && LoadUtils.loadStatusIs(load.loadStatus, ['COMPLETED', 'CLOSED', 'REOPENED']) && isListNotEmpty(stops) && isLoadingDocuments === false && isLoadingNotes === false) {
        return (
            <CanSee entityModel="LOAD" entityObject={load} entityTypes={['CARRIER', 'STAFF']}>
                {isObjectNotEmpty(invoice) ? null : (
                    <>
                        <Header
                            title="Load Finalization"
                            footer={
                                <Steps current={currentStep}>
                                    <Steps.Step title="Proof of Delivery" icon={<DeliveredProcedureOutlined />} />
                                    <Steps.Step title="Invoice" icon={<FileDoneOutlined />} />
                                    <Steps.Step title="Remittance" icon={<DollarOutlined />} />
                                </Steps>
                            }
                        />

                        <div style={{ minHeight: 200, marginTop: 16, padding: 24, backgroundColor: '#fafafa', border: '1px dashed #e9e9e9', borderRadius: '2px' }}>
                            {currentStep === 0 ? (
                                <div>
                                    <Header
                                        title="Proof of Delivery and Load Documents"
                                        titleStyle={{ paddingLeft: 0, color: '#000000', fontWeight: 500 }}
                                        style={{ paddingLeft: 0, paddingRight: 0 }}
                                        icon={<DeliveredProcedureOutlined />}
                                        iconStyle={{ background: 'transparent' }}
                                        title2={`Please review the documents below and upload any missing BOLs, receipts, etc.${load.createdByEntityType === "CARRIER" && load.serviceType === "TMS" ? " If there are any documents pending review, please review and approve them." : ""}${missingPedigreeInformation.length > 0 ? " Please also provide the missing information requested below." : ""} If everything has been provided, you can continue to the next step.`}
                                        title2Style={{ whiteSpace: 'normal' }}
                                    />
                                    {missingPedigreeInformationComponents()}
                                    {createMissingDocumentList()}
                                    {createVisibleDocumentsList()}
                                    {load.createdByEntityType === "CARRIER" && load.serviceType === "TMS" ? createPendingApprovalList() : null}
                                    <div style={{ marginTop: 24, textAlign: 'right' }}>
                                        <Button type="primary" onClick={() => nextStep()} disabled={missingDocuments.length > 0 || missingPedigreeInformation.length > 0 || (load.createdByEntityType === "CARRIER" && load.serviceType === "TMS" && pendingApprovalDocuments.length > 0)}>
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            ) : null}
                            {currentStep === 1 ? (
                                <div>
                                    <FormProvider {...methods}>
                                        <Form onSubmit={methods.handleSubmit(onSubmitRateConfirmationStep)}>
                                            <Header
                                                title="Invoice"
                                                titleStyle={{ paddingLeft: 0, color: '#000000', fontWeight: 500 }}
                                                style={{ paddingLeft: 0, paddingRight: 0 }}
                                                icon={<FileDoneOutlined />}
                                                iconStyle={{ background: 'transparent' }}
                                                title2={`Please review the Rate Confirmation below. If anything is incorrect, please provide details below, otherwise confirm that everything is correct. If you have an invoice for us, you can upload it below. Once you are done reviewing the rate confirmation, you can continue to the next step.`}
                                                title2Style={{ whiteSpace: 'normal' }}
                                            />

                                            <Fieldset legend="Review Final Rate Confirmation">
                                                <InvoiceTable
                                                    entityType="CARRIER"
                                                    isRateConfirmation={true}
                                                    data={[...invoiceLineItems.filter(item => (item.fromEntityType === 'CARRIER' || item.toEntityType === 'CARRIER') && item.isDeleted === false), ...newInvoiceLineItemList]}
                                                    columns={rateConfirmationColumns}
                                                />
                                                {rateConfirmationDocumentComponents()}
                                                {rateConfirmationInvoiceComponents()}
                                                <FormItem {...formItemLayout} label="Issues, claims, etc." format="vertical"
                                                    render={({ onChange, onBlur, value, name, ref }) => (
                                                        <Input.TextArea
                                                            placeholder="Have any issues, claims, corrections, or questions regarding the rate confirmation above? Please provide details here."
                                                            autoSize={{ minRows: 4 }}
                                                            onBlur={onBlur}
                                                            onChange={e => onChange(e.target.value)}
                                                            value={value}
                                                            name={name}
                                                            ref={ref}
                                                        />
                                                    )}
                                                    rules={{ required: false }}
                                                    name="invoiceCorrections"
                                                />
                                            </Fieldset>

                                            <Fieldset legend="Do you have an invoice for us? If so, you can upload it here. Please provide us with an invoice number as well so that we can reference it in our system.">
                                                <FormItemDisplay {...formItemLayout} label="Do you have an invoice for us?" format="vertical">
                                                    {carrierInvoiceDocumentComponents()}
                                                </FormItemDisplay>

                                                <FormItem {...formItemLayout} label="Invoice Number (do you have an internal reference number for this invoice?)"
                                                    render={({ onChange, onBlur, value, name, ref }) => (
                                                        <Input
                                                            onBlur={onBlur}
                                                            onChange={e => { onChange(e.target.value); }}
                                                            value={value}
                                                            name={name}
                                                            placeholder="Invoice Reference Number"
                                                            ref={ref}
                                                        />
                                                    )}
                                                    rules={{ required: false }}
                                                    name="customerInvoiceNumber"
                                                    defaultValue={''}
                                                />
                                            </Fieldset>

                                            <div style={{ marginTop: 24, textAlign: 'right' }}>
                                                <Button style={{ margin: '0 8px' }} onClick={() => prevStep()}>
                                                    Previous Step
                                                </Button>
                                                <Button type="primary" htmlType="submit">
                                                    Save and Continue
                                                </Button>
                                            </div>
                                        </Form>
                                    </FormProvider>
                                </div>
                            ) : null}
                            {currentStep === 2 ? (
                                <div>
                                    <FormProvider {...methods2}>
                                        <Form onSubmit={methods2.handleSubmit(onSubmitCarrierInvoice)}>
                                            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoadingAddInvoice === true && errorAddInvoice === null}>
                                                <Header
                                                    title="Remittance"
                                                    titleStyle={{ paddingLeft: 0, color: '#000000', fontWeight: 500 }}
                                                    style={{ paddingLeft: 0, paddingRight: 0 }}
                                                    icon={<DollarOutlined />}
                                                    iconStyle={{ background: 'transparent' }}
                                                    title2={`Please review or update the Remittance Information below so we know how to pay you for this load. Once you are done, you can Submit your invoice.`}
                                                    title2Style={{ whiteSpace: 'normal' }}
                                                />
                                                <Fieldset legend="Remittance Method">
                                                    <FormItem {...formItemLayout} label="Remittance Method" required
                                                        render={({ onChange, onBlur, value, name, ref }) => (
                                                            <Radio.Group
                                                                onBlur={onBlur}
                                                                onChange={e => { setRemittanceMethod(e.target.value); onChange(e.target.value); }}
                                                                value={value}
                                                                name={name}
                                                                buttonStyle="solid"
                                                                ref={ref}
                                                            >
                                                                <Radio.Button value="DIRECT">I want to be Paid Directly</Radio.Button>
                                                                <Radio.Button value="FACTORING">I am Assigning this Invoice to a Factoring Company</Radio.Button>
                                                            </Radio.Group>
                                                        )}
                                                        rules={{ required: "Required Field" }}
                                                        name="remittanceMethod"
                                                    />
                                                    {remittanceMethod === 'DIRECT' ? (
                                                        <>
                                                            <FormItem {...formItemLayout} label="Payment Method" required
                                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                                    <Radio.Group
                                                                        onBlur={onBlur}
                                                                        onChange={e => { setPaymentMethod(e.target.value); onChange(e.target.value); }}
                                                                        value={value}
                                                                        name={name}
                                                                        buttonStyle="solid"
                                                                        ref={ref}
                                                                    >
                                                                        <Radio.Button value="CHECK">Paper Check</Radio.Button>
                                                                        {serviceType === 'BROKERAGE' && <Radio.Button value="DIRECT_DEPOSIT">Direct Deposit</Radio.Button>}
                                                                        {(serviceType === 'BROKERAGE' && isObjectNotEmpty(shipperAccount) && isBooleanTrue(shipperAccount.isCreditApproved)) && <Radio.Button value="QUICK_PAY">Quick Pay (5% fee, 2-3 business days)</Radio.Button>}
                                                                    </Radio.Group>
                                                                )}
                                                                rules={{ required: "Required Field" }}
                                                                name="paymentMethod"
                                                                defaultValue={paymentMethod}
                                                            />
                                                            {bankAccountDocumentComponents()}
                                                            {paymentMethod === 'QUICK_PAY' ? (
                                                                <InvoiceTable
                                                                    entityType="CARRIER"
                                                                    isRateConfirmation={true}
                                                                    data={[...invoiceLineItems.filter(item => (item.fromEntityType === 'CARRIER' || item.toEntityType === 'CARRIER') && item.isDeleted === false), ...newInvoiceLineItemList]}
                                                                    columns={rateConfirmationColumns}
                                                                />
                                                            ) : null}
                                                        </>
                                                    ) : null}
                                                    {remittanceMethod === 'FACTORING' ? (
                                                        factoringNOADocumentComponents()
                                                    ) : null}
                                                    {(remittanceMethod === 'DIRECT' || remittanceMethod === 'FACTORING') ? (
                                                        <FormItem {...formItemLayout} label="Do you have any special instructions for remittance of the invoice?" format="vertical"
                                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                                <Input.TextArea
                                                                    placeholder="Any special instructions?"
                                                                    autoSize={{ minRows: 4 }}
                                                                    onBlur={onBlur}
                                                                    onChange={e => { onChange(e.target.value); }}
                                                                    value={value}
                                                                    name={name}
                                                                    ref={ref}
                                                                />
                                                            )}
                                                            rules={{ required: false }}
                                                            name="invoiceNotes"
                                                        />
                                                    ) : null}
                                                </Fieldset>

                                                {paymentMethod === 'FACTORING' ? (
                                                    <>
                                                        <Fieldset legend="Choose a Factoring Company to auto-fill the remittance information below">
                                                            {linkedAccounts.filter(a => a.entityTypes.includes("FACTORING")).map((linkedAccount, index) => (
                                                                <Row gutter={16} key={`factoring-${index}`}>
                                                                    <Col span={6}>
                                                                        <DataRow title="Company" value={linkedAccount.name} dataType="String" />
                                                                    </Col>
                                                                    <Col span={6}>
                                                                        <DataContactInfoRow valueStyle={{ paddingLeft: 16 }} title="Remittance Point of Contact" contact={linkedAccount.accountsReceivable} />
                                                                    </Col>
                                                                    <Col span={6}>
                                                                        <DataAddressRow valueStyle={{ paddingLeft: 16 }} title="Remittance Address" address={linkedAccount.accountsReceivableAddress} />
                                                                    </Col>
                                                                    <Col span={6}>
                                                                        <Button type="primary" onClick={() => { selectFactoring(linkedAccount); }} style={{ margin: "10px", float: "right" }}>Select</Button>
                                                                    </Col>
                                                                </Row>
                                                            ))}
                                                            {linkedAccounts.filter(a => a.entityTypes.includes("FACTORING")).length === 0 ? (
                                                                <div>
                                                                    <span>You have not setup any Factoring Companies</span>
                                                                </div>
                                                            ) : null}
                                                            <Tooltip placement="right" title="Add New Factoring Company">
                                                                <Button key="1" type="primary" onClick={toggleNewLinkedFactoring} style={{ margin: "10px" }}>Add New Factoring Company</Button>
                                                            </Tooltip>
                                                        </Fieldset>
                                                        <Fieldset legend="Factoring Company Point of Contact (if we have any questions, who should we contact?)">
                                                            <FormItemPointOfContact
                                                                format="penta"
                                                                required={true}
                                                                name="remitPointOfContact"
                                                            />
                                                        </Fieldset>
                                                        <Fieldset legend="Factoring Company Name and Address (who and where are we sending your money to?)">
                                                            <Row gutter={[12, 12]}>
                                                                <Col span={24}>
                                                                    <FormItem {...formItemLayout} label="Factoring Company Name (who should we make the payment out to?)" required
                                                                        render={({ onChange, onBlur, value, name, ref }) => (
                                                                            <Input
                                                                                onBlur={onBlur}
                                                                                onChange={e => { onChange(e.target.value); }}
                                                                                value={value}
                                                                                name={name}
                                                                                placeholder="Remittance Name"
                                                                                ref={ref}
                                                                            />
                                                                        )}
                                                                        rules={{ required: "Required Field" }}
                                                                        name="remitName"
                                                                    />
                                                                </Col>
                                                                <Col span={24}>
                                                                    <FormItemAddress
                                                                        format="vertical"
                                                                        required={false}
                                                                        name="remitAddress"
                                                                        isPostalAddress={true}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Fieldset>
                                                    </>
                                                ) : null}
                                                {(paymentMethod === 'CHECK' || paymentMethod === 'DIRECT_DEPOSIT' || paymentMethod === 'QUICK_PAY') ? (
                                                    <>
                                                        <Fieldset legend="Accounts Receivable Point of Contact (if we have any questions, who should we contact?)">
                                                            <FormItemPointOfContact
                                                                format="penta"
                                                                required={true}
                                                                name="remitPointOfContact"
                                                                defaultValue={isObjectNotEmpty(carrierAccount) && isObjectNotEmpty(carrierAccount.accountsReceivable) ? carrierAccount.accountsReceivable : null}
                                                            />
                                                        </Fieldset>
                                                        <Fieldset legend="Company Name and Address (who and where are we sending your money to?)">
                                                            <Row gutter={[12, 12]}>
                                                                <Col span={24}>
                                                                    <FormItem {...formItemLayout} label="Company Name (who should we make the payment out to?)" required
                                                                        render={({ onChange, onBlur, value, name, ref }) => (
                                                                            <Input
                                                                                onBlur={onBlur}
                                                                                onChange={e => { onChange(e.target.value); }}
                                                                                value={value}
                                                                                name={name}
                                                                                placeholder="Remittance Name"
                                                                                ref={ref}
                                                                            />
                                                                        )}
                                                                        rules={{ required: "Required Field" }}
                                                                        name="remitName"
                                                                        defaultValue={isObjectNotEmpty(carrierAccount) && isStringNotEmpty(carrierAccount.name) ? carrierAccount.name : null}
                                                                    />
                                                                </Col>
                                                                <Col span={24}>
                                                                    <FormItemAddress
                                                                        format="vertical"
                                                                        required={false}
                                                                        name="remitAddress"
                                                                        defaultValue={carrierAccount.accountsReceivableAddress}
                                                                        isPostalAddress={true}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Fieldset>
                                                    </>
                                                ) : null}

                                                {errorAddInvoice && <Alert message={`${errorAddInvoice}`} type="error" />}
                                                <div style={{ marginTop: 24, textAlign: 'right' }}>
                                                    <Button style={{ margin: '0 8px' }} onClick={() => prevStep()}>
                                                        Previous Step
                                                    </Button>
                                                    <Button type="primary" htmlType="submit">
                                                        Submit Invoice
                                                    </Button>
                                                </div>
                                            </Spin>
                                        </Form>
                                    </FormProvider>
                                </div>
                            ) : null}
                        </div>
                    </>
                )}

                {editMissingPedigreeInformationComponents}
                {editMissingDocumentComponents}
                {reviewDocumentComponents}
                {viewDocumentComponents}
                {viewInvoiceModal}
                <Drawer
                    title={'Add New Factoring Company'}
                    visible={showNewLinkedFactoring === true}
                    onClose={toggleNewLinkedFactoring}
                    bodyStyle={{ paddingBottom: 80 }}
                    style={{ zIndex: 1000 }}
                    width={fullWidth > 720 ? fullWidth / 2 : 360}
                    footer={null}
                    zIndex={1000}
                    destroyOnClose={true}
                    closable={false}
                    maskClosable={false}
                >
                    <NewLinkedFactoring accountId={carrierId} cancel={toggleNewLinkedFactoring} />
                </Drawer>
            </CanSee >
        );
    } else {
        return <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoadingDocuments === true || isLoadingNotes === true} />;
    }
};

export default LoadCompletionCarrier;