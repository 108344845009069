import React from 'react';
import { isBooleanFalse, isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';

const DataNameRow = ({ className = null, valueClassName = null, titleClassName = null, style = null, valueStyle = null, titleStyle = { fontWeight: 'bold' }, title = null, noColon = false, user = null, firstName = null, middleName = null, lastName = null }) => {
    let name = [];
    if (isObjectNotEmpty(user)) {
        if (isStringNotEmpty(user.firstName)) {
            name.push(user.firstName);
        }
        if (isStringNotEmpty(user.middleName)) {
            name.push(user.middleName);
        }
        if (isStringNotEmpty(user.lastName)) {
            name.push(user.lastName);
        }
    }

    if (isStringNotEmpty(firstName)) {
        name.push(firstName);
    }
    if (isStringNotEmpty(middleName)) {
        name.push(middleName);
    }
    if (isStringNotEmpty(lastName)) {
        name.push(lastName);
    }

    return (
        <div className={className} style={style}>
            {isStringNotEmpty(title) ? (<span className={titleClassName} style={titleStyle}>{title}{isBooleanFalse(noColon) ? ':' : ''} </span>) : null}
            {isListNotEmpty(name) ? (<span className={valueClassName} style={valueStyle}>{name.join(' ')}</span>) : null}
        </div>
    );
};

export default DataNameRow;