import React, { useState } from 'react';
import { MailOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, message, Row, Spin, Typography } from "antd";
import { Redirect } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form';
import { Link } from "react-router-dom";
import classes from './ForgotPasswordForm.module.scss';
import IRISFloatingFooter from '../../hoc/IRISFloatingFooter/IRISFloatingFooter';
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import FormItemDisplay from '../FormItemDisplay/FormItemDisplay';
import { forgotPassword } from '../../api/data';
import { isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import logger from '../../shared/logger';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';

const { Title } = Typography;

const ForgotPasswordForm = props => {

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isResetRequestComplete, setIsResetRequestComplete] = useState(false);

    const methods = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            email: ''
        }
    });

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            md: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            md: { span: 16 },
        },
    };

    const onSubmit = (data) => {
        if (isObjectNotEmpty(data) && isStringNotEmpty(data.email)) {
            setIsLoading(true);
            forgotPassword(data.email).then((res) => {
                if (res === true) {
                    message.success('Your password reset request has been submitted');
                    setIsLoading(false);
                    setIsResetRequestComplete(true);
                    methods.reset();
                } else {
                    setIsLoading(false);
                    setIsResetRequestComplete(false);
                }
            }).catch((error) => {
                let errorMessage = null;
                console.error(error)
                console.log(error.response)
                console.log(error.response.data)
                if (error.response.status === 400) {
                    if (isObjectNotEmpty(error) && isObjectNotEmpty(error.response) && isObjectNotEmpty(error.response.data) && isListNotEmpty(error.response.data.message)) {
                        errorMessage = error.response.data.message;
                    } else if (isObjectNotEmpty(error) && isObjectNotEmpty(error.response) && isListNotEmpty(error.response.data)) {
                        errorMessage = error.response.data;
                    } else {
                        errorMessage = "An unexpected error has occurred.";
                    }
                } else {
                    errorMessage = "An unexpected error has occurred.";
                }
                logger.logDebugEvent('ForgotPasswordForm.js', error.message, true);

                setError(errorMessage);
                setIsLoading(false);
                setIsResetRequestComplete(false);
            });
        }
    };

    return (
        <>
            {isResetRequestComplete && <Redirect to='/login' />}
            <Card bordered={true} className={classes.card} size="small">
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true}>
                    <Row gutter={[4, 4]}>
                        <Col span={24}>
                            <Title level={2} style={{ textAlign: 'center' }}>Forgot Your Password?</Title>
                        </Col>
                        <Col span={24}>
                            <FormProvider {...methods}>
                                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                                    <FormItem {...formItemLayout} label="Email Address" required
                                        render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email Address" ref={ref} />}
                                        rules={{
                                            required: 'Required Field',
                                            minLength: { value: 4, message: 'Email Address must be at least 4 characters long' }
                                        }}
                                        name="email"
                                    />
                                    {error && <ErrorMessage error={error} />}
                                    <FormItemDisplay>
                                        <Button block type="primary" htmlType="submit" className="login-form-button">
                                            Submit
                                        </Button>
                                    </FormItemDisplay>
                                    <FormItemDisplay style={{ textAlign: 'right' }}>
                                        Remembered your Password? <Link to="/login"><Button type="link">Back to Login</Button></Link>
                                    </FormItemDisplay>
                                </Form>
                            </FormProvider>
                        </Col>
                    </Row>
                </Spin>
                <IRISFloatingFooter />
            </Card>
        </>
    );
};

export default ForgotPasswordForm;
