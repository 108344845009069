import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Layout } from "antd";
import LoadMap from "../../components/LoadMap/LoadMap";
import axios from 'axios';
import * as Data from '../../api/data';
import { isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import logger from '../../shared/logger';

const Map = (props) => {
    //#region props

    const loadId = props.match.params.loadId;

    //#endregion
    //#region useStates

    const [load, setLoad] = useState(null);

    //#endregion
    //#region useEffects

    useEffect(() => {
        const { cancel, token } = axios.CancelToken.source();

        if (isStringNotEmpty(loadId)) {
            Data.getShipperTracker(loadId, { cancelToken: token }).then((data) => {
                setLoad(data);
                // console.log(data)
            }).catch(err => {
                if (axios.isCancel(err)) {
                    //console.log('Request cancelled', err.message);
                } else {
                    logger.logDebugEvent('Map.js', err.message, true);
                }
            });
        }

        return () => {
            // console.log("Cleaning up");
            if (cancel !== undefined) {
                cancel('Operation cancelled by the user.');
            }
        };
    }, [loadId]);

    //#endregion

    return (
        <Layout style={{ minHeight: '100vh', backgroundColor: 'white' }}>
            {isObjectNotEmpty(load) ? (
                <LoadMap
                    key={`fullscreenmap-${loadId}`}
                    loadId={load.id}
                    stops={load.stops}
                    notAuthenticated={true}
                    currentLatitude={load.latitude}
                    currentLongitude={load.longitude}
                    fullScreen={true}
                    showZoomControl={false}
                />
            ) : null}
        </Layout>
    );
};

export default withRouter(Map);