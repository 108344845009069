import Checks from '../entitlementUtils';

export const canCreateLoadStop = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadCarrier(load, auth) || Checks.isLoadShipper(load, auth) || Checks.isLoadDriver(load, auth);
};

export const canReadLoadStop = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadCarrier(load, auth) || Checks.isLoadShipper(load, auth) || Checks.isLoadDriver(load, auth);
};

export const canReadLoadStopList = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadCarrier(load, auth) || Checks.isLoadShipper(load, auth) || Checks.isLoadDriver(load, auth);
};

export const canUpdateLoadStop = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadCarrier(load, auth) || Checks.isLoadShipper(load, auth) || Checks.isLoadDriver(load, auth);
};

export const canDeleteLoadStop = (auth, load) => {
    return Checks.allowStaffAdmin(auth) || Checks.isLoadOwner(load, auth);
};