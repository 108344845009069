import React, { useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { DatePicker, Row, Col, Select, Spin, Alert, Checkbox, Table } from 'antd';
import StringFormatter from '../../shared/stringFormatter';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import DataTable from '../../components/DataTable/DataTable';
import { isListNotEmpty, isNotNullOrUndefined, isStringNotEmpty } from '../../shared/objectUtils';
import moment from 'moment';
import MomentDate from '../../shared/dateFormatter';
import Header from '../../components/Header/Header';
import { useForm, FormProvider } from 'react-hook-form';
import Form from '../../components/Form/Form';
import FormItem from '../../components/FormItem/FormItem';
import FormButtons from '../../components/FormButtons/FormButtons';
import { orderBy } from 'lodash';
import Enums from '../../shared/enums';
import EntityUtils from '../../api/entityUtils';
import Fieldset from '../../components/FormFieldset/FormFieldset';
import LoadUtils from '../../api/utils/loadUtils';

const stringFormatter = new StringFormatter();
const momentDate = new MomentDate();
const { RangePicker } = DatePicker;
const { Option } = Select;

const LoadReports = ({ shipperId, assignedCarrierId, driverId, assignedAccountRepUserId, ...props }) => {
    //#region constants

    const defaultLoadStatuses = ["CREATED", "PENDING", "APPROVED", "PENDING_RATE_CONFIRMATION", "BOOKED", "SCHEDULED", "IN_TRANSIT", "AT_STOP", "COMPLETED", "CLOSED", "REOPENED", "CANCELLED"];

    //#endregion
    //#region enums

    const loadStatusOptions = Enums.LoadStatuses.checkboxListOptions();

    //#endregion
    //#region useForms

    const methods = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            assignedAccountRepUserIds: isStringNotEmpty(assignedAccountRepUserId) ? [assignedAccountRepUserId] : [],
            shipperId: isStringNotEmpty(shipperId) ? [shipperId] : [],
            assignedCarrierId: isStringNotEmpty(assignedCarrierId) ? [assignedCarrierId] : [],
            pickUpDateRange: [null, null],
            loadStatus: defaultLoadStatuses
        }
    });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const entityType = useSelector(state => state.auth.entityType);
    const isLoading = useSelector(state => state.loads.isLoading);
    const error = useSelector(state => state.loads.error);
    const loads = useSelector(state => state.loads.records);
    const staff = useSelector(state => state.orchestrator.staff);
    const accounts = useSelector(state => state.orchestrator.accounts);

    //#endregion
    //#region useStates

    const [shippers, setShippers] = useState([]);
    const [carriers, setCarriers] = useState([]);

    //#endregion
    //#region onSubmit and onCancel

    const onSubmit = (data) => {
        // console.log(data);

        let searchParams = {
            page: 1,
            size: 1000000,
            sort: 'pickUpDateTime',
            order: 'asc',
            isDeleted: false
        };

        searchParams.loadStatus = data.loadStatus.join('|');

        const startDate = data.pickUpDateRange[0];
        const endDate = data.pickUpDateRange[1];
        if (isNotNullOrUndefined(startDate) && isNotNullOrUndefined(endDate)) {
            let time = moment().set({ hours: 0, minutes: 0, seconds: 0 });
            let startDateValue = momentDate.combineDateAndTimeAsTimeZoneUtcISOString(startDate, time, "UTC");
            let endDateValue = momentDate.combineDateAndTimeAsTimeZoneUtcISOString(endDate, time, "UTC");
            // console.log(startDateValue);
            // console.log(endDateValue);

            searchParams["pickUpDateTime:gte"] = startDateValue;
            searchParams["pickUpDateTime:lte"] = endDateValue;
        }

        if (isStringNotEmpty(shipperId)) {
            searchParams.shipperId = shipperId;
        } else if (isListNotEmpty(data.shipperId)) {
            searchParams.shipperId = data.shipperId.join('|');
        }

        if (isStringNotEmpty(assignedCarrierId)) {
            searchParams.assignedCarrierId = assignedCarrierId;
        } else if (isListNotEmpty(data.assignedCarrierId)) {
            searchParams.assignedCarrierId = data.assignedCarrierId.join('|');
        }

        if (isStringNotEmpty(driverId)) {
            searchParams.driverIds = driverId;
        }

        if (isStringNotEmpty(assignedAccountRepUserId)) {
            searchParams.assignedAccountRepUserIds = assignedAccountRepUserId;
        } else if (isListNotEmpty(data.accountRepUserIds)) {
            searchParams.assignedAccountRepUserIds = data.accountRepUserIds.join('|');
        }

        // console.log(searchParams);

        dispatch(actionCreators.fetchLoads(searchParams));
    };

    const onCancel = () => {
        methods.reset();
    };

    //#endregion
    //#region table displays

    const columns = [
        {
            title: 'Pick-Up Date',
            dataIndex: 'pickUpDateTime',
            key: 'pickUpDateTime',
            render: (text, record) => { return stringFormatter.toFormattedString("MomentDate", text); },
        },
        {
            title: 'Load Id',
            dataIndex: 'irisId',
            key: 'irisId',
        },
        {
            title: 'Name',
            dataIndex: 'parentName',
            key: 'parentName',
            render: (text, record) => { return (record.parentName ? record.parentName : record.name ? record.name : '') },
        },
        {
            title: 'Status',
            dataIndex: 'loadStatus',
            key: 'loadStatus',
            render: (text, record) => { return LoadUtils.getLoadStatusDisplay(text, entityType, record); },
        },
        {
            title: 'Shipper',
            dataIndex: 'shipperId',
            key: 'shipperId',
            render: (text, record) => { return EntityUtils.getAccountName(text, accounts); },
        },
        {
            title: 'Carrier',
            dataIndex: 'assignedCarrierId',
            key: 'assignedCarrierId',
            render: (text, record) => { return EntityUtils.getAccountName(text, accounts); },
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, record) => { return stringFormatter.toFormattedString("MomentDateTime", text, null); },
        },
        {
            title: 'Shipper Amount',
            dataIndex: 'shipperAmount',
            key: 'shipperAmount',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text); },
        },
        {
            title: 'Shipper Balance',
            dataIndex: 'shipperBalance',
            key: 'shipperBalance',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text); },
        },
        {
            title: 'Carrier Amount',
            dataIndex: 'carrierAmount',
            key: 'carrierAmount',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text); },
        },
        {
            title: 'Carrier Balance',
            dataIndex: 'carrierBalance',
            key: 'carrierBalance',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text); },
        },
        {
            title: 'Axle Payments Amount',
            dataIndex: 'factoringAmount',
            key: 'factoringAmount',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text); },
        },
        {
            title: 'Axle Payments Balance',
            dataIndex: 'factoringBalance',
            key: 'factoringBalance',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text); },
        },
        {
            title: 'IRIS Profits',
            dataIndex: 'shipperAmount',
            key: 'irisProfits',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", record.shipperAmount - record.carrierAmount - record.factoringAmount); },
        },
    ];

    //#endregion
    //#region useMemos

    useMemo(() => {
        dispatch(actionCreators.getStaff());
        dispatch(actionCreators.getAccounts());
    }, []);

    useMemo(() => {
        if (isListNotEmpty(accounts)) {
            const shipperAccounts = accounts.filter(account => account.entityTypes.includes("SHIPPER") === true);
            if (isListNotEmpty(shipperAccounts)) {
                setShippers(orderBy(shipperAccounts, ['name'], ['asc']));
            }
            const carrierAccounts = accounts.filter(account => account.entityTypes.includes("CARRIER") === true);
            if (isListNotEmpty(carrierAccounts)) {
                setCarriers(orderBy(carrierAccounts, ['name'], ['asc']));
            }
        }
    }, [accounts]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    //#endregion

    return (
        <>
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <Header
                        title="Load Report"
                        footer={
                            <FormProvider {...methods}>
                                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                                        <Fieldset legend="Set the filters and click Generate Report">
                                            <FormItem {...formItemLayout} label="Pick-Up Date" required format="horizontal"
                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                    <RangePicker
                                                        allowClear={true}
                                                        allowEmpty={false}
                                                        style={{ width: '100%' }}
                                                        onBlur={onBlur}
                                                        onChange={(dates, dateString) => { onChange(dates); }}
                                                        value={value}
                                                        name={name}
                                                        ref={ref}
                                                    />
                                                )}
                                                rules={{ required: 'Required Field' }}
                                                name="pickUpDateRange"
                                            />
                                            <FormItem {...formItemLayout} label="Account Representative(s)" format="horizontal"
                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                    <Select
                                                        mode="multiple"
                                                        placeholder="Please Select Account Representative(s)"
                                                        allowClear={true}
                                                        style={{ width: '100%' }}
                                                        virtual={false}
                                                        onBlur={onBlur}
                                                        onChange={(selected) => { onChange(selected); }}
                                                        value={value}
                                                        name={name}
                                                        ref={ref}
                                                        disabled={isStringNotEmpty(assignedAccountRepUserId)}
                                                    >
                                                        {staff.map(i => <Option value={i.user_id} key={i.user_id}>{i.firstName + ' ' + i.lastName}</Option>)}
                                                    </Select>
                                                )}
                                                rules={{ required: false }}
                                                name="accountRepUserIds"
                                            />
                                            <FormItem {...formItemLayout} label="Shipper(s)" format="horizontal"
                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                    <Select
                                                        mode="multiple"
                                                        placeholder="Please Select Shipper(s)"
                                                        allowClear={true}
                                                        style={{ width: '100%' }}
                                                        virtual={false}
                                                        onBlur={onBlur}
                                                        onChange={(selected) => { onChange(selected); }}
                                                        value={value}
                                                        name={name}
                                                        ref={ref}
                                                        disabled={isStringNotEmpty(shipperId)}
                                                    >
                                                        {shippers.map(i => <Option value={i.id} key={i.id}>{i.name}</Option>)}
                                                    </Select>
                                                )}
                                                rules={{ required: false }}
                                                name="shipperId"
                                            />
                                            <FormItem {...formItemLayout} label="Carrier(s)" format="horizontal"
                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                    <Select
                                                        mode="multiple"
                                                        placeholder="Please Select Carrier(s)"
                                                        allowClear={true}
                                                        style={{ width: '100%' }}
                                                        virtual={false}
                                                        onBlur={onBlur}
                                                        onChange={(selected) => { onChange(selected); }}
                                                        value={value}
                                                        name={name}
                                                        ref={ref}
                                                        disabled={isStringNotEmpty(assignedCarrierId)}
                                                    >
                                                        {carriers.map(i => <Option value={i.id} key={i.id}>{i.name}</Option>)}
                                                    </Select>
                                                )}
                                                rules={{ required: false }}
                                                name="assignedCarrierId"
                                            />
                                            <FormItem {...formItemLayout} label="Load Status" format="horizontal"
                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                    <Checkbox.Group
                                                        onBlur={onBlur}
                                                        options={loadStatusOptions}
                                                        onChange={(checkedValues) => { onChange(checkedValues); }}
                                                        value={value}
                                                        name={name}
                                                    />
                                                )}
                                                rules={{ required: false }}
                                                name='loadStatus'
                                            />
                                        </Fieldset>
                                        {error && <Alert message={`${error}`} type="error" />}
                                    </Spin>
                                    <FormButtons containerStyle={{ position: 'relative' }} cancel={onCancel} cancelText="Reset" disabled={isLoading === true && error === null} submitText="Generate Report" />
                                </Form>
                            </FormProvider>
                        }
                    />
                </Col>
                <Col span={24}>
                    <DataTable
                        dataSource={loads}
                        columns={columns}
                        hidePaging={true}
                        loading={isLoading === true}
                        title={false}
                        rowKey={record => record.id}
                        style={{ backgroundColor: '#ffffff' }}
                        summary={pageData => {
                            let totalShipperAmount = 0;
                            let totalShipperBalance = 0;
                            let totalCarrierAmount = 0;
                            let totalCarrierBalance = 0;
                            let totalIrisProfits = 0;

                            pageData.forEach((dataRow) => {
                                console.log(dataRow)
                                totalShipperAmount += dataRow.shipperAmount;
                                totalShipperBalance += dataRow.shipperBalance;
                                totalCarrierAmount += dataRow.carrierAmount;
                                totalCarrierBalance += dataRow.carrierBalance;
                                totalIrisProfits += (dataRow.shipperAmount - dataRow.carrierAmount);
                            });

                            return (
                                <Table.Summary.Row>
                                    <Table.Summary.Cell colSpan={7}>Total</Table.Summary.Cell>
                                    <Table.Summary.Cell>{stringFormatter.toFormattedString("Money", totalShipperAmount)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{stringFormatter.toFormattedString("Money", totalShipperBalance)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{stringFormatter.toFormattedString("Money", totalCarrierAmount)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{stringFormatter.toFormattedString("Money", totalCarrierBalance)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{stringFormatter.toFormattedString("Money", totalIrisProfits)}</Table.Summary.Cell>
                                </Table.Summary.Row>
                            );
                        }}
                    />
                </Col>
            </Row>
        </>
    );
};

export default withRouter(LoadReports);