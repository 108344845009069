import React, { useMemo, useState } from 'react';
import DataRow from '../../components/DataRow/DataRow';
import DataPhoneNumberRow from '../../components/DataPhoneNumberRow/DataPhoneNumberRow';
import DataAddressRow from '../../components/DataAddressRow/DataAddressRow';
import DataContactInfoRow from '../../components/DataContactInfoRow/DataContactInfoRow';
import DataSMSBasicRow from '../../components/DataSMSBasicRow/DataSMSBasicRow';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Col, Row, Card } from 'antd';
import { isBooleanTrue, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { getImageByDocumentId } from '../../api/data';
import logger from '../../shared/logger';
import DataListRow from '../DataListRow/DataListRow';

const Account = ({ account }) => {
    const [imageUrl, setImageUrl] = useState(null);

    useMemo(() => {
        // Get Document for Displaying
        if (isObjectNotEmpty(account) && isStringNotEmpty(account.logoFileId)) {
            getImageByDocumentId(account.logoFileId).then(data => {
                const blob = new Blob([data], {
                    type: 'image/png',
                });
                setImageUrl(URL.createObjectURL(blob));
            }).catch(err => {
                logger.logDebugEvent('Account.js', err.message, true);
            });
        }
    }, [account]);

    if (isObjectNotEmpty(account)) {
        return (
            <>
                <Card size="small" title="Company Details" style={{ marginTop: 16, marginBottom: 16 }}>
                    <Row gutter={16}>
                        <Col xs={24} md={24} lg={12} xl={12}>
                            <Avatar shape="square" size={128} icon={<UserOutlined />} src={imageUrl} />
                            {isStringNotEmpty(account.irisId) ? <DataRow title="Account ID" value={account.irisId} dataType="String" /> : null}
                            {isStringNotEmpty(account.name) ? <DataRow title="Account Name" value={account.name} dataType="String" /> : null}
                            {isStringNotEmpty(account.ein) ? <DataRow title="EIN" value={account.ein} dataType="String" /> : null}
                            {isStringNotEmpty(account.dotNum) ? <DataRow title="DOT #" value={account.dotNum} dataType="String" /> : null}
                            {isStringNotEmpty(account.mcNum) ? <DataRow title="MC #" value={account.mcNum} dataType="String" /> : null}
                            {isStringNotEmpty(account.dbNum) ? <DataRow title="D&B #" value={account.dbNum} dataType="String" /> : null}
                            {isStringNotEmpty(account.blueBookNum) ? <DataRow title="Produce Blue Book #" value={account.blueBookNum} dataType="String" /> : null}
                            {isStringNotEmpty(account.email) ? <DataRow title="Email Address" value={account.email} dataType="String" /> : null}
                            {isStringNotEmpty(account.phone) ? <DataPhoneNumberRow title="Phone Number" phone={account.phone} phoneExt={account.phoneExt} /> : null}
                            {isStringNotEmpty(account.afterHoursPhone) ? <DataPhoneNumberRow title="After Hours Phone Number" phone={account.afterHoursPhone} phoneExt={account.afterHoursPhoneExt} /> : null}
                            {isStringNotEmpty(account.fax) ? <DataRow title="Fax Number" value={account.fax} dataType="PhoneNumber" /> : null}
                            <DataRow title="Is Active" value={account.isActive} dataType="Boolean" />
                            <DataRow title="Is Verified" value={account.isVerified} dataType="Boolean" />
                            <DataRow title="Is Credit Approved" value={account.isCreditApproved} dataType="Boolean" />
                            <DataListRow title="Account Type(s)" data={account.entityTypes} dataType="EntityType" />
                            {isStringNotEmpty(account.preferredPaymentMethod) ? <DataRow title="Preferred Payment Method" value={account.preferredPaymentMethod} dataType="PreferredPaymentMethod" /> : null}
                            {isObjectNotEmpty(account.smsBasic) ? <DataSMSBasicRow title="SMS Basic Scores" valueStyle={{ marginLeft: 16 }} smsBasic={account.smsBasic} /> : null}
                        </Col>
                        <Col xs={24} md={24} lg={12} xl={12}>
                            {isObjectNotEmpty(account.pointOfContact) ? <DataContactInfoRow title="Point of Contact" valueStyle={{ marginLeft: 16 }} contact={account.pointOfContact} /> : null}
                            {isObjectNotEmpty(account.companyAddress) ? <DataAddressRow title="Company Address" valueStyle={{ marginLeft: 16 }} address={account.companyAddress} /> : null}
                            {isObjectNotEmpty(account.accountsPayable) ? <DataContactInfoRow title="A/P Contact" valueStyle={{ marginLeft: 16 }} contact={account.accountsPayable} /> : null}
                            {isObjectNotEmpty(account.accountsPayableAddress) ? <DataAddressRow title="A/P Address" valueStyle={{ marginLeft: 16 }} address={account.accountsPayableAddress} /> : null}
                            {isObjectNotEmpty(account.accountsReceivable) ? <DataContactInfoRow title="A/R Contact" valueStyle={{ marginLeft: 16 }} contact={account.accountsReceivable} /> : null}
                            {isObjectNotEmpty(account.accountsReceivableAddress) ? <DataAddressRow title="A/R Address" valueStyle={{ marginLeft: 16 }} address={account.accountsReceivableAddress} /> : null}
                        </Col>
                    </Row>
                </Card>

                {isObjectNotEmpty(account.assetNeeds) && isBooleanTrue(account.entityTypes.includes("SHIPPER")) ? (
                    <Card size="small" title="Equipment Needs" style={{ marginTop: 16, marginBottom: 16 }}>
                        <Row gutter={[16, 16]}>
                            <Col lg={{ span: 12 }} md={{ span: 24 }}>
                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Reefers</h3>
                                <DataRow title="Do you need 48' Reefers?" value={account.assetNeeds.has48Reefer} dataType="Boolean" />
                                <DataRow title="Do you need 53' Reefers?" value={account.assetNeeds.has53Reefer} dataType="Boolean" />
                                <DataRow title="Do you need to ship frozen?" value={account.assetNeeds.hasReeferFrozen} dataType="Boolean" />
                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Dry Vans</h3>
                                <DataRow title="Do you need 48' Dry Vans?" value={account.assetNeeds.has48DryVan} dataType="Boolean" />
                                <DataRow title="Do you need 53' Dry Vans?" value={account.assetNeeds.has53DryVan} dataType="Boolean" />
                                <DataRow title="Do you need lift gates for the dry vans?" value={account.assetNeeds.hasDryVanLiftGates} dataType="Boolean" />
                                <DataRow title="Do you need roller beds for the dry vans?" value={account.assetNeeds.hasDryVanRollerBed} dataType="Boolean" />
                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Flatbeds</h3>
                                <DataRow title="Do you need 48' Flatbeds?" value={account.assetNeeds.has48Flatbed} dataType="Boolean" />
                                <DataRow title="Do you need 53' Flatbeds?" value={account.assetNeeds.has53Flatbed} dataType="Boolean" />
                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Over-Sized Loads</h3>
                                <DataRow title="Do you need to ship over dimensional/overweight loads?" value={account.assetNeeds.hasOverSized} dataType="Boolean" />
                            </Col>
                            <Col lg={{ span: 12 }} md={{ span: 24 }}>
                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Low Boys</h3>
                                <DataRow title="Do you need Low Boys?" value={account.assetNeeds.hasLowBoy} dataType="Boolean" />
                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>RGNs</h3>
                                <DataRow title="Do you need RGNs?" value={account.assetNeeds.hasRGN} dataType="Boolean" />
                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Step Decks</h3>
                                <DataRow title="Do you need Step Decks with Ramps?" value={account.assetNeeds.hasStepDeckRamps} dataType="Boolean" />
                                <DataRow title="Do you need Step Decks without Ramps?" value={account.assetNeeds.hasStepDeckNoRamps} dataType="Boolean" />
                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Tankers</h3>
                                <DataRow title="Do you need Single Compartment Tankers?" value={account.assetNeeds.hasTankersSingleCompartment} dataType="Boolean" />
                                <DataRow title="Do you need Multi Compartment Tankers?" value={account.assetNeeds.hasTankersMultiCompartment} dataType="Boolean" />
                                <DataRow title="Do you need Fiber Glass Tankers?" value={account.assetNeeds.hasTankersFiberGlass} dataType="Boolean" />
                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Straight Trucks</h3>
                                <DataRow title="Do you need Cubed Straight Trucks?" value={account.assetNeeds.hasCubedStraightTruck} dataType="Boolean" />
                                <DataRow title="Do you need 26' Straight Trucks?" value={account.assetNeeds.has26StraightTruck} dataType="Boolean" />
                                <DataRow title="Do you need lift gates for the straight trucks?" value={account.assetNeeds.hasStraightTruckLiftGates} dataType="Boolean" />
                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Sprinter Vans</h3>
                                <DataRow title="Do you need Sprinter Vans?" value={account.assetNeeds.hasSprinterVan} dataType="Boolean" />
                            </Col>
                        </Row>
                    </Card>
                ) : null}
                {isObjectNotEmpty(account.assetNeeds) && isBooleanTrue(account.entityTypes.includes("CARRIER")) ? (
                    <>
                        <Card size="small" title="Asset Details" style={{ marginTop: 16, marginBottom: 16 }}>
                            <Row gutter={[16, 16]}>
                                <Col lg={{ span: 4 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                    <DataRow title="# of Power Units" value={account.assetNeeds.numPowerUnits} dataType="Integer" />
                                </Col>
                                <Col lg={{ span: 4 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                    <DataRow title="# of Trailers" value={account.assetNeeds.numTrailers} dataType="Integer" />
                                </Col>
                                <Col lg={{ span: 4 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                    <DataRow title="# of Drivers" value={account.assetNeeds.numDrivers} dataType="Integer" />
                                </Col>
                                <Col lg={{ span: 4 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                    <DataRow title="# of HazMat Drivers" value={account.assetNeeds.numHazMatDrivers} dataType="Integer" />
                                </Col>
                                <Col lg={{ span: 4 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                    <DataRow title="# of Teams" value={account.assetNeeds.numTeams} dataType="Integer" />
                                </Col>
                            </Row>
                        </Card>
                        <Card size="small" title="Trailer Types" style={{ marginTop: 16, marginBottom: 16 }}>
                            <Row gutter={[16, 16]}>
                                <Col lg={{ span: 12 }} md={{ span: 24 }}>
                                    <h3 style={{ marginTop: 12, marginBottom: 12 }}>Reefers</h3>
                                    <DataRow title="Do you have 48' Reefers?" value={account.assetNeeds.has48Reefer} dataType="Boolean" />
                                    <DataRow title="If so, how many?" value={account.assetNeeds.num48Reefer} dataType="Integer" />
                                    <DataRow title="Do you have 53' Reefers?" value={account.assetNeeds.has53Reefer} dataType="Boolean" />
                                    <DataRow title="If so, how many?" value={account.assetNeeds.num53Reefer} dataType="Integer" />
                                    <DataRow title="Do you haul frozen?" value={account.assetNeeds.hasReeferFrozen} dataType="Boolean" />
                                    <h3 style={{ marginTop: 12, marginBottom: 12 }}>Dry Vans</h3>
                                    <DataRow title="Do you have 48' Dry Vans?" value={account.assetNeeds.has48DryVan} dataType="Boolean" />
                                    <DataRow title="If so, how many?" value={account.assetNeeds.num48DryVan} dataType="Integer" />
                                    <DataRow title="Do you have 53' Dry Vans?" value={account.assetNeeds.has53DryVan} dataType="Boolean" />
                                    <DataRow title="If so, how many?" value={account.assetNeeds.num53DryVan} dataType="Integer" />
                                    <DataRow title="Do you have lift gates for the dry vans?" value={account.assetNeeds.hasDryVanLiftGates} dataType="Boolean" />
                                    <DataRow title="Do you have roller beds for the dry vans?" value={account.assetNeeds.hasDryVanRollerBed} dataType="Boolean" />
                                    <h3 style={{ marginTop: 12, marginBottom: 12 }}>Flatbeds</h3>
                                    <DataRow title="Do you have 48' Flatbeds?" value={account.assetNeeds.has48Flatbed} dataType="Boolean" />
                                    <DataRow title="If so, how many?" value={account.assetNeeds.num48Flatbed} dataType="Integer" />
                                    <DataRow title="Do you have 53' Flatbeds?" value={account.assetNeeds.has53Flatbed} dataType="Boolean" />
                                    <DataRow title="If so, how many?" value={account.assetNeeds.num53Flatbed} dataType="Integer" />
                                    <h3 style={{ marginTop: 12, marginBottom: 12 }}>Over-Sized Loads</h3>
                                    <DataRow title="Do you haul over dimensional/overweight loads?" value={account.assetNeeds.hasOverSized} dataType="Boolean" />
                                </Col>
                                <Col lg={{ span: 12 }} md={{ span: 24 }}>
                                    <h3 style={{ marginTop: 12, marginBottom: 12 }}>Low Boys</h3>
                                    <DataRow title="Do you have Low Boys?" value={account.assetNeeds.hasLowBoy} dataType="Boolean" />
                                    <DataRow title="If so, how many?" value={account.assetNeeds.numLowBoy} dataType="Integer" />
                                    <h3 style={{ marginTop: 12, marginBottom: 12 }}>RGNs</h3>
                                    <DataRow title="Do you have RGNs?" value={account.assetNeeds.hasRGN} dataType="Boolean" />
                                    <DataRow title="If so, how many?" value={account.assetNeeds.numRGN} dataType="Integer" />
                                    <h3 style={{ marginTop: 12, marginBottom: 12 }}>Step Decks</h3>
                                    <DataRow title="Do you have Step Decks with Ramps?" value={account.assetNeeds.hasStepDeckRamps} dataType="Boolean" />
                                    <DataRow title="If so, how many?" value={account.assetNeeds.numStepDeckRamps} dataType="Integer" />
                                    <DataRow title="Do you have Step Decks without Ramps?" value={account.assetNeeds.hasStepDeckNoRamps} dataType="Boolean" />
                                    <DataRow title="If so, how many?" value={account.assetNeeds.numStepDeckNoRamps} dataType="Integer" />
                                    <h3 style={{ marginTop: 12, marginBottom: 12 }}>Tankers</h3>
                                    <DataRow title="Do you have Single Compartment Tankers?" value={account.assetNeeds.hasTankersSingleCompartment} dataType="Boolean" />
                                    <DataRow title="If so, how many?" value={account.assetNeeds.numTankersSingleCompartment} dataType="Integer" />
                                    <DataRow title="Do you have Multi Compartment Tankers?" value={account.assetNeeds.hasTankersMultiCompartment} dataType="Boolean" />
                                    <DataRow title="If so, how many?" value={account.assetNeeds.numTankersMultiCompartment} dataType="Integer" />
                                    <DataRow title="Do you have Fiber Glass Tankers?" value={account.assetNeeds.hasTankersFiberGlass} dataType="Boolean" />
                                    <DataRow title="If so, how many?" value={account.assetNeeds.numTankersFiberGlass} dataType="Integer" />
                                    <h3 style={{ marginTop: 12, marginBottom: 12 }}>Straight Trucks</h3>
                                    <DataRow title="Do you have Cubed Straight Trucks?" value={account.assetNeeds.hasCubedStraightTruck} dataType="Boolean" />
                                    <DataRow title="If so, how many?" value={account.assetNeeds.numCubedStraightTruck} dataType="Integer" />
                                    <DataRow title="Do you have 26' Straight Trucks?" value={account.assetNeeds.has26StraightTruck} dataType="Boolean" />
                                    <DataRow title="If so, how many?" value={account.assetNeeds.num26StraightTruck} dataType="Integer" />
                                    <DataRow title="Do you have lift gates for the straight trucks?" value={account.assetNeeds.hasStraightTruckLiftGates} dataType="Boolean" />
                                    <h3 style={{ marginTop: 12, marginBottom: 12 }}>Sprinter Vans</h3>
                                    <DataRow title="Do you have Sprinter Vans?" value={account.assetNeeds.hasSprinterVan} dataType="Boolean" />
                                    <DataRow title="If so, how many?" value={account.assetNeeds.numSprinterVan} dataType="Integer" />
                                </Col>
                            </Row>
                        </Card>
                    </>
                ) : null}
            </>
        );
    } else {
        return null;
    }
};

export default Account;