import Checks from '../entitlementUtils';

export const canCreateLoadEvent = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadCarrier(load, auth) || Checks.isLoadShipper(load, auth) || Checks.isLoadDriver(load, auth);
};

export const canReadLoadEvent = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadCarrier(load, auth) || Checks.isLoadShipper(load, auth) || Checks.isLoadDriver(load, auth);
};

export const canReadLoadEventList = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadCarrier(load, auth) || Checks.isLoadShipper(load, auth) || Checks.isLoadDriver(load, auth);
};

export const canUpdateLoadEvent = (auth) => {
    return Checks.allowStaffAdmin(auth);
};

export const canDeleteLoadEvent = (auth) => {
    return Checks.allowStaffAdmin(auth);
};