//#region Account Registrations Constants

// Account Registrations
export const FETCH_ACCOUNT_REGISTRATIONS_START = 'FETCH_ACCOUNT_REGISTRATIONS_START';
export const FETCH_ACCOUNT_REGISTRATIONS_SUCCESS = 'FETCH_ACCOUNT_REGISTRATIONS_SUCCESS';
export const FETCH_ACCOUNT_REGISTRATIONS_FAIL = 'FETCH_ACCOUNT_REGISTRATIONS_FAIL';
export const CLEAR_ACCOUNT_REGISTRATIONS = 'CLEAR_ACCOUNT_REGISTRATIONS';
export const FETCH_ACCOUNT_REGISTRATION_START = 'FETCH_ACCOUNT_REGISTRATION_START';
export const FETCH_ACCOUNT_REGISTRATION_SUCCESS = 'FETCH_ACCOUNT_REGISTRATION_SUCCESS';
export const FETCH_ACCOUNT_REGISTRATION_FAIL = 'FETCH_ACCOUNT_REGISTRATION_FAIL';
export const CLEAR_ACCOUNT_REGISTRATION = 'CLEAR_ACCOUNT_REGISTRATION';
export const ADD_ACCOUNT_REGISTRATION = 'ADD_ACCOUNT_REGISTRATION';
export const ADD_ACCOUNT_REGISTRATION_START = 'ADD_ACCOUNT_REGISTRATION_START';
export const ADD_ACCOUNT_REGISTRATION_SUCCESS = 'ADD_ACCOUNT_REGISTRATION_SUCCESS';
export const ADD_ACCOUNT_REGISTRATION_FAIL = 'ADD_ACCOUNT_REGISTRATION_FAIL';
export const ADD_ACCOUNT_REGISTRATION_LOADING_CLEAR = 'ADD_ACCOUNT_REGISTRATION_LOADING_CLEAR';
export const ADD_ACCOUNT_REGISTRATION_ERROR_CLEAR = 'ADD_ACCOUNT_REGISTRATION_ERROR_CLEAR';
export const ADD_ACCOUNT_REGISTRATION_CANCEL = 'ADD_ACCOUNT_REGISTRATION_CANCEL';
export const UPDATE_ACCOUNT_REGISTRATION = 'UPDATE_ACCOUNT_REGISTRATION';
export const UPDATE_SINGLE_ACCOUNT_REGISTRATION = 'UPDATE_SINGLE_ACCOUNT_REGISTRATION';
export const UPDATE_ACCOUNT_REGISTRATION_START = 'UPDATE_ACCOUNT_REGISTRATION_START';
export const UPDATE_ACCOUNT_REGISTRATION_SUCCESS = 'UPDATE_ACCOUNT_REGISTRATION_SUCCESS';
export const UPDATE_ACCOUNT_REGISTRATION_FAIL= 'UPDATE_ACCOUNT_REGISTRATION_FAIL';
export const UPDATE_ACCOUNT_REGISTRATION_LOADING_CLEAR = 'UPDATE_ACCOUNT_REGISTRATION_LOADING_CLEAR';
export const UPDATE_ACCOUNT_REGISTRATION_ERROR_CLEAR = 'UPDATE_ACCOUNT_REGISTRATION_ERROR_CLEAR';
export const UPDATE_ACCOUNT_REGISTRATION_CANCEL = 'UPDATE_ACCOUNT_REGISTRATION_CANCEL';
export const REMOVE_ACCOUNT_REGISTRATION = 'REMOVE_ACCOUNT_REGISTRATION';

//#endregion
//#region Accounts Constants

// Accounts
export const FETCH_ACCOUNT_LIST_START = 'FETCH_ACCOUNT_LIST_START';
export const FETCH_ACCOUNT_LIST_SUCCESS = 'FETCH_ACCOUNT_LIST_SUCCESS';
export const FETCH_ACCOUNT_LIST_FAIL = 'FETCH_ACCOUNT_LIST_FAIL';
export const CLEAR_ACCOUNT_LIST = 'CLEAR_ACCOUNT_LIST';
export const CLEAR_ACCOUNT_LISTS = 'CLEAR_ACCOUNT_LISTS';
export const ADD_TO_ACCOUNT_LIST = 'ADD_TO_ACCOUNT_LIST';
export const UPDATE_IN_ACCOUNT_LIST = 'UPDATE_IN_ACCOUNT_LIST';
export const REMOVE_FROM_ACCOUNT_LIST = 'REMOVE_FROM_ACCOUNT_LIST';
export const FETCH_ACCOUNTS_START = 'FETCH_ACCOUNTS_START';
export const FETCH_ACCOUNTS_SUCCESS = 'FETCH_ACCOUNTS_SUCCESS';
export const FETCH_ACCOUNTS_FAIL = 'FETCH_ACCOUNTS_FAIL';
export const CLEAR_ACCOUNTS = 'CLEAR_ACCOUNTS';
export const FETCH_ACCOUNT_START = 'FETCH_ACCOUNT_START';
export const FETCH_ACCOUNT_SUCCESS = 'FETCH_ACCOUNT_SUCCESS';
export const FETCH_ACCOUNT_FAIL = 'FETCH_ACCOUNT_FAIL';
export const CLEAR_ACCOUNT = 'CLEAR_ACCOUNT';
export const ADD_ACCOUNT = 'ADD_ACCOUNT';
export const ADD_ACCOUNT_START = 'ADD_ACCOUNT_START';
export const ADD_ACCOUNT_SUCCESS = 'ADD_ACCOUNT_SUCCESS';
export const ADD_ACCOUNT_FAIL = 'ADD_ACCOUNT_FAIL';
export const ADD_ACCOUNT_LOADING_CLEAR = 'ADD_ACCOUNT_LOADING_CLEAR';
export const ADD_ACCOUNT_ERROR_CLEAR = 'ADD_ACCOUNT_ERROR_CLEAR';
export const ADD_ACCOUNT_CANCEL = 'ADD_ACCOUNT_CANCEL';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const UPDATE_SINGLE_ACCOUNT = 'UPDATE_SINGLE_ACCOUNT';
export const UPDATE_ACCOUNT_START = 'UPDATE_ACCOUNT_START';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_FAIL = 'UPDATE_ACCOUNT_FAIL';
export const UPDATE_ACCOUNT_LOADING_CLEAR = 'UPDATE_ACCOUNT_LOADING_CLEAR';
export const UPDATE_ACCOUNT_ERROR_CLEAR = 'UPDATE_ACCOUNT_ERROR_CLEAR';
export const UPDATE_ACCOUNT_CANCEL = 'UPDATE_ACCOUNT_CANCEL';
export const REMOVE_ACCOUNT = 'REMOVE_ACCOUNT';

//#endregion
//#region Account Users Constants

// Account Users
export const FETCH_ACCOUNT_USER_LIST_START = 'FETCH_ACCOUNT_USER_LIST_START';
export const FETCH_ACCOUNT_USER_LIST_SUCCESS = 'FETCH_ACCOUNT_USER_LIST_SUCCESS';
export const FETCH_ACCOUNT_USER_LIST_FAIL = 'FETCH_ACCOUNT_USER_LIST_FAIL';
export const CLEAR_ACCOUNT_USER_LIST = 'CLEAR_ACCOUNT_USER_LIST';
export const CLEAR_ACCOUNT_USER_LISTS = 'CLEAR_ACCOUNT_USER_LISTS';
export const ADD_TO_ACCOUNT_USER_LIST = 'ADD_TO_ACCOUNT_USER_LIST';
export const UPDATE_IN_ACCOUNT_USER_LIST = 'UPDATE_IN_ACCOUNT_USER_LIST';
export const REMOVE_FROM_ACCOUNT_USER_LIST = 'REMOVE_FROM_ACCOUNT_USER_LIST';
export const FETCH_ACCOUNT_USERS_START = 'FETCH_ACCOUNT_USERS_START';
export const FETCH_ACCOUNT_USERS_SUCCESS = 'FETCH_ACCOUNT_USERS_SUCCESS';
export const FETCH_ACCOUNT_USERS_FAIL = 'FETCH_ACCOUNT_USERS_FAIL';
export const CLEAR_ACCOUNT_USERS = 'CLEAR_ACCOUNT_USERS';
export const FETCH_ACCOUNT_USER_START = 'FETCH_ACCOUNT_USER_START';
export const FETCH_ACCOUNT_USER_SUCCESS = 'FETCH_ACCOUNT_USER_SUCCESS';
export const FETCH_ACCOUNT_USER_FAIL = 'FETCH_ACCOUNT_USER_FAIL';
export const CLEAR_ACCOUNT_USER = 'CLEAR_ACCOUNT_USER';
export const ADD_ACCOUNT_USER = 'ADD_ACCOUNT_USER';
export const ADD_ACCOUNT_USER_START = 'ADD_ACCOUNT_USER_START';
export const ADD_ACCOUNT_USER_SUCCESS = 'ADD_ACCOUNT_USER_SUCCESS';
export const ADD_ACCOUNT_USER_FAIL = 'ADD_ACCOUNT_USER_FAIL';
export const ADD_ACCOUNT_USER_LOADING_CLEAR = 'ADD_ACCOUNT_USER_LOADING_CLEAR';
export const ADD_ACCOUNT_USER_ERROR_CLEAR = 'ADD_ACCOUNT_USER_ERROR_CLEAR';
export const ADD_ACCOUNT_USER_CANCEL = 'ADD_ACCOUNT_USER_CANCEL';
export const UPDATE_ACCOUNT_USER = 'UPDATE_ACCOUNT_USER';
export const UPDATE_SINGLE_ACCOUNT_USER = 'UPDATE_SINGLE_ACCOUNT_USER';
export const UPDATE_ACCOUNT_USER_START = 'UPDATE_ACCOUNT_USER_START';
export const UPDATE_ACCOUNT_USER_SUCCESS = 'UPDATE_ACCOUNT_USER_SUCCESS';
export const UPDATE_ACCOUNT_USER_FAIL = 'UPDATE_ACCOUNT_USER_FAIL';
export const UPDATE_ACCOUNT_USER_LOADING_CLEAR = 'UPDATE_ACCOUNT_USER_LOADING_CLEAR';
export const UPDATE_ACCOUNT_USER_ERROR_CLEAR = 'UPDATE_ACCOUNT_USER_ERROR_CLEAR';
export const UPDATE_ACCOUNT_USER_CANCEL = 'UPDATE_ACCOUNT_USER_CANCEL';
export const REMOVE_ACCOUNT_USER = 'REMOVE_ACCOUNT_USER';

//#endregion
//#region Active Load Constants

// Active Load
export const FETCH_ACTIVE_LOAD_START = 'FETCH_ACTIVE_LOAD_START';
export const FETCH_ACTIVE_LOAD_SUCCESS = 'FETCH_ACTIVE_LOAD_SUCCESS';
export const FETCH_ACTIVE_LOAD_FAIL = 'FETCH_ACTIVE_LOAD_FAIL';
export const CLEAR_ACTIVE_LOAD = 'CLEAR_ACTIVE_LOAD';
export const UPDATE_ACTIVE_LOAD = 'UPDATE_ACTIVE_LOAD';
export const UPDATE_ACTIVE_LOAD_START = 'UPDATE_ACTIVE_LOAD_START';
export const UPDATE_ACTIVE_LOAD_SUCCESS = 'UPDATE_ACTIVE_LOAD_SUCCESS';
export const UPDATE_ACTIVE_LOAD_FAIL = 'UPDATE_ACTIVE_LOAD_FAIL';
export const UPDATE_ACTIVE_LOAD_ERROR_CLEAR = 'UPDATE_ACTIVE_LOAD_ERROR_CLEAR';
export const UPDATE_ACTIVE_LOAD_COMPLETE = 'UPDATE_ACTIVE_LOAD_COMPLETE';
export const UPDATE_DISTANCE = 'UPDATE_DISTANCE';
export const CLEAR_DISTANCE = 'CLEAR_DISTANCE';
export const UPDATE_CURRENT_LOCATION = 'UPDATE_CURRENT_LOCATION';
export const CLEAR_CURRENT_LOCATION = 'CLEAR_CURRENT_LOCATION';
export const FETCH_ACTIVE_LOAD_STOPS_START = 'FETCH_ACTIVE_LOAD_STOPS_START';
export const FETCH_ACTIVE_LOAD_STOPS_SUCCESS = 'FETCH_ACTIVE_LOAD_STOPS_SUCCESS';
export const FETCH_ACTIVE_LOAD_STOPS_FAIL = 'FETCH_ACTIVE_LOAD_STOPS_FAIL';
export const FETCH_ACTIVE_LOAD_DOCUMENTS_START = 'FETCH_ACTIVE_LOAD_DOCUMENTS_START';
export const FETCH_ACTIVE_LOAD_DOCUMENTS_SUCCESS = 'FETCH_ACTIVE_LOAD_DOCUMENTS_SUCCESS';
export const FETCH_ACTIVE_LOAD_DOCUMENTS_FAIL = 'FETCH_ACTIVE_LOAD_DOCUMENTS_FAIL';

//#endregion
//#region Assets Constants

// Assets
export const FETCH_ASSET_LIST_START = 'FETCH_ASSET_LIST_START';
export const FETCH_ASSET_LIST_SUCCESS = 'FETCH_ASSET_LIST_SUCCESS';
export const FETCH_ASSET_LIST_FAIL = 'FETCH_ASSET_LIST_FAIL';
export const CLEAR_ASSET_LIST = 'CLEAR_ASSET_LIST';
export const CLEAR_ASSET_LISTS = 'CLEAR_ASSET_LISTS';
export const ADD_TO_ASSET_LIST = 'ADD_TO_ASSET_LIST';
export const UPDATE_IN_ASSET_LIST = 'UPDATE_IN_ASSET_LIST';
export const REMOVE_FROM_ASSET_LIST = 'REMOVE_FROM_ASSET_LIST';
export const FETCH_ASSETS_START = 'FETCH_ASSETS_START';
export const FETCH_ASSETS_SUCCESS = 'FETCH_ASSETS_SUCCESS';
export const FETCH_ASSETS_FAIL = 'FETCH_ASSETS_FAIL';
export const CLEAR_ASSETS = 'CLEAR_ASSETS';
export const FETCH_ASSET_START = 'FETCH_ASSET_START';
export const FETCH_ASSET_SUCCESS = 'FETCH_ASSET_SUCCESS';
export const FETCH_ASSET_FAIL = 'FETCH_ASSET_FAIL';
export const CLEAR_ASSET = 'CLEAR_ASSET';
export const ADD_ASSET = 'ADD_ASSET';
export const ADD_ASSET_START = 'ADD_ASSET_START';
export const ADD_ASSET_SUCCESS = 'ADD_ASSET_SUCCESS';
export const ADD_ASSET_FAIL = 'ADD_ASSET_FAIL';
export const ADD_ASSET_LOADING_CLEAR = 'ADD_ASSET_LOADING_CLEAR';
export const ADD_ASSET_ERROR_CLEAR = 'ADD_ASSET_ERROR_CLEAR';
export const ADD_ASSET_CANCEL = 'ADD_ASSET_CANCEL';
export const UPDATE_ASSET = 'UPDATE_ASSET';
export const UPDATE_SINGLE_ASSET = 'UPDATE_SINGLE_ASSET';
export const UPDATE_ASSET_START = 'UPDATE_ASSET_START';
export const UPDATE_ASSET_SUCCESS = 'UPDATE_ASSET_SUCCESS';
export const UPDATE_ASSET_FAIL = 'UPDATE_ASSET_FAIL';
export const UPDATE_ASSET_LOADING_CLEAR = 'UPDATE_ASSET_LOADING_CLEAR';
export const UPDATE_ASSET_ERROR_CLEAR = 'UPDATE_ASSET_ERROR_CLEAR';
export const UPDATE_ASSET_CANCEL = 'UPDATE_ASSET_CANCEL';
export const REMOVE_ASSET = 'REMOVE_ASSET';

//#endregion
//#region Auth Constants

export const AUTH_START = 'AUTH_START';
export const AUTH_UPDATE = 'AUTH_UPDATE';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_CLEAR_ERROR = 'AUTH_CLEAR_ERROR';

//#endregion
//#region Carrier Rejections Constants

// Carrier Rejections
export const FETCH_CARRIER_REJECTION_LIST_START = 'FETCH_CARRIER_REJECTION_LIST_START';
export const FETCH_CARRIER_REJECTION_LIST_SUCCESS = 'FETCH_CARRIER_REJECTION_LIST_SUCCESS';
export const FETCH_CARRIER_REJECTION_LIST_FAIL = 'FETCH_CARRIER_REJECTION_LIST_FAIL';
export const CLEAR_CARRIER_REJECTION_LIST = 'CLEAR_CARRIER_REJECTION_LIST';
export const CLEAR_CARRIER_REJECTION_LISTS = 'CLEAR_CARRIER_REJECTION_LISTS';
export const ADD_TO_CARRIER_REJECTION_LIST = 'ADD_TO_CARRIER_REJECTION_LIST';
export const UPDATE_IN_CARRIER_REJECTION_LIST = 'UPDATE_IN_CARRIER_REJECTION_LIST';
export const REMOVE_FROM_CARRIER_REJECTION_LIST = 'REMOVE_FROM_CARRIER_REJECTION_LIST';
export const FETCH_CARRIER_REJECTIONS_START = 'FETCH_CARRIER_REJECTIONS_START';
export const FETCH_CARRIER_REJECTIONS_SUCCESS = 'FETCH_CARRIER_REJECTIONS_SUCCESS';
export const FETCH_CARRIER_REJECTIONS_FAIL = 'FETCH_CARRIER_REJECTIONS_FAIL';
export const CLEAR_CARRIER_REJECTIONS = 'CLEAR_CARRIER_REJECTIONS';
export const FETCH_CARRIER_REJECTION_START = 'FETCH_CARRIER_REJECTION_START';
export const FETCH_CARRIER_REJECTION_SUCCESS = 'FETCH_CARRIER_REJECTION_SUCCESS';
export const FETCH_CARRIER_REJECTION_FAIL = 'FETCH_CARRIER_REJECTION_FAIL';
export const CLEAR_CARRIER_REJECTION = 'CLEAR_CARRIER_REJECTION';
export const ADD_CARRIER_REJECTION = 'ADD_CARRIER_REJECTION';
export const ADD_CARRIER_REJECTION_START = 'ADD_CARRIER_REJECTION_START';
export const ADD_CARRIER_REJECTION_SUCCESS = 'ADD_CARRIER_REJECTION_SUCCESS';
export const ADD_CARRIER_REJECTION_FAIL= 'ADD_CARRIER_REJECTION_FAIL';
export const ADD_CARRIER_REJECTION_LOADING_CLEAR = 'ADD_CARRIER_REJECTION_LOADING_CLEAR';
export const ADD_CARRIER_REJECTION_ERROR_CLEAR = 'ADD_CARRIER_REJECTION_ERROR_CLEAR';
export const ADD_CARRIER_REJECTION_CANCEL = 'ADD_CARRIER_REJECTION_CANCEL';
export const UPDATE_CARRIER_REJECTION = 'UPDATE_CARRIER_REJECTION';
export const UPDATE_SINGLE_CARRIER_REJECTION = 'UPDATE_SINGLE_CARRIER_REJECTION';
export const UPDATE_CARRIER_REJECTION_START = 'UPDATE_CARRIER_REJECTION_START';
export const UPDATE_CARRIER_REJECTION_SUCCESS = 'UPDATE_CARRIER_REJECTION_SUCCESS';
export const UPDATE_CARRIER_REJECTION_FAIL = 'UPDATE_CARRIER_REJECTION_FAIL';
export const UPDATE_CARRIER_REJECTION_LOADING_CLEAR = 'UPDATE_CARRIER_REJECTION_LOADING_CLEAR';
export const UPDATE_CARRIER_REJECTION_ERROR_CLEAR = 'UPDATE_CARRIER_REJECTION_ERROR_CLEAR';
export const UPDATE_CARRIER_REJECTION_CANCEL = 'UPDATE_CARRIER_REJECTION_CANCEL';
export const REMOVE_CARRIER_REJECTION = 'REMOVE_CARRIER_REJECTION';

//#endregion
//#region Claims Constants

// Claims
export const FETCH_CLAIM_LIST_START = 'FETCH_CLAIM_LIST_START';
export const FETCH_CLAIM_LIST_SUCCESS = 'FETCH_CLAIM_LIST_SUCCESS';
export const FETCH_CLAIM_LIST_FAIL = 'FETCH_CLAIM_LIST_FAIL';
export const CLEAR_CLAIM_LIST = 'CLEAR_CLAIM_LIST';
export const CLEAR_CLAIM_LISTS = 'CLEAR_CLAIM_LISTS';
export const ADD_TO_CLAIM_LIST = 'ADD_TO_CLAIM_LIST';
export const UPDATE_IN_CLAIM_LIST = 'UPDATE_IN_CLAIM_LIST';
export const REMOVE_FROM_CLAIM_LIST = 'REMOVE_FROM_CLAIM_LIST';
export const FETCH_CLAIMS_START = 'FETCH_CLAIMS_START';
export const FETCH_CLAIMS_SUCCESS = 'FETCH_CLAIMS_SUCCESS';
export const FETCH_CLAIMS_FAIL = 'FETCH_CLAIMS_FAIL';
export const CLEAR_CLAIMS = 'CLEAR_CLAIMS';
export const FETCH_CLAIM_START = 'FETCH_CLAIM_START';
export const FETCH_CLAIM_SUCCESS = 'FETCH_CLAIM_SUCCESS';
export const FETCH_CLAIM_FAIL = 'FETCH_CLAIM_FAIL';
export const CLEAR_CLAIM = 'CLEAR_CLAIM';
export const ADD_CLAIM = 'ADD_CLAIM';
export const ADD_CLAIM_START = 'ADD_CLAIM_START';
export const ADD_CLAIM_SUCCESS = 'ADD_CLAIM_SUCCESS';
export const ADD_CLAIM_FAIL= 'ADD_CLAIM_FAIL';
export const ADD_CLAIM_LOADING_CLEAR = 'ADD_CLAIM_LOADING_CLEAR';
export const ADD_CLAIM_ERROR_CLEAR = 'ADD_CLAIM_ERROR_CLEAR';
export const ADD_CLAIM_CANCEL = 'ADD_CLAIM_CANCEL';
export const UPDATE_CLAIM = 'UPDATE_CLAIM';
export const UPDATE_SINGLE_CLAIM = 'UPDATE_SINGLE_CLAIM';
export const UPDATE_CLAIM_START = 'UPDATE_CLAIM_START';
export const UPDATE_CLAIM_SUCCESS = 'UPDATE_CLAIM_SUCCESS';
export const UPDATE_CLAIM_FAIL = 'UPDATE_CLAIM_FAIL';
export const UPDATE_CLAIM_LOADING_CLEAR = 'UPDATE_CLAIM_LOADING_CLEAR';
export const UPDATE_CLAIM_ERROR_CLEAR = 'UPDATE_CLAIM_ERROR_CLEAR';
export const UPDATE_CLAIM_CANCEL = 'UPDATE_CLAIM_CANCEL';
export const REMOVE_CLAIM = 'REMOVE_CLAIM';

//#endregion
//#region Commodities Constants

// Commodities
export const FETCH_COMMODITIES_START = 'FETCH_COMMODITIES_START';
export const FETCH_COMMODITIES_SUCCESS = 'FETCH_COMMODITIES_SUCCESS';
export const FETCH_COMMODITIES_FAIL = 'FETCH_COMMODITIES_FAIL';
export const CLEAR_COMMODITIES = 'CLEAR_COMMODITIES';
export const FETCH_COMMODITY_START = 'FETCH_COMMODITY_START';
export const FETCH_COMMODITY_SUCCESS = 'FETCH_COMMODITY_SUCCESS';
export const FETCH_COMMODITY_FAIL = 'FETCH_COMMODITY_FAIL';
export const CLEAR_COMMODITY = 'CLEAR_COMMODITY';
export const ADD_COMMODITY = 'ADD_COMMODITY';
export const ADD_COMMODITY_START = 'ADD_COMMODITY_START';
export const ADD_COMMODITY_SUCCESS = 'ADD_COMMODITY_SUCCESS';
export const ADD_COMMODITY_FAIL = 'ADD_COMMODITY_FAIL';
export const ADD_COMMODITY_LOADING_CLEAR = 'ADD_COMMODITY_LOADING_CLEAR';
export const ADD_COMMODITY_ERROR_CLEAR = 'ADD_COMMODITY_ERROR_CLEAR';
export const ADD_COMMODITY_CANCEL = 'ADD_COMMODITY_CANCEL';
export const UPDATE_COMMODITY = 'UPDATE_COMMODITY';
export const UPDATE_SINGLE_COMMODITY = 'UPDATE_SINGLE_COMMODITY';
export const UPDATE_COMMODITY_START = 'UPDATE_COMMODITY_START';
export const UPDATE_COMMODITY_SUCCESS = 'UPDATE_COMMODITY_SUCCESS';
export const UPDATE_COMMODITY_FAIL = 'UPDATE_COMMODITY_FAIL';
export const UPDATE_COMMODITY_LOADING_CLEAR = 'UPDATE_COMMODITY_LOADING_CLEAR';
export const UPDATE_COMMODITY_ERROR_CLEAR = 'UPDATE_COMMODITY_ERROR_CLEAR';
export const UPDATE_COMMODITY_CANCEL = 'UPDATE_COMMODITY_CANCEL';
export const REMOVE_COMMODITY = 'REMOVE_COMMODITY';

//#endregion
//#region Configurations Constants

// Configurations
export const FETCH_CONFIGURATIONS_START = 'FETCH_CONFIGURATIONS_START';
export const FETCH_CONFIGURATIONS_SUCCESS = 'FETCH_CONFIGURATIONS_SUCCESS';
export const FETCH_CONFIGURATIONS_FAIL = 'FETCH_CONFIGURATIONS_FAIL';
export const CLEAR_CONFIGURATIONS = 'CLEAR_CONFIGURATIONS';
export const FETCH_CONFIGURATION_START = 'FETCH_CONFIGURATION_START';
export const FETCH_CONFIGURATION_SUCCESS = 'FETCH_CONFIGURATION_SUCCESS';
export const FETCH_CONFIGURATION_FAIL = 'FETCH_CONFIGURATION_FAIL';
export const CLEAR_CONFIGURATION = 'CLEAR_CONFIGURATION';
export const ADD_CONFIGURATION = 'ADD_CONFIGURATION';
export const ADD_CONFIGURATION_START = 'ADD_CONFIGURATION_START';
export const ADD_CONFIGURATION_SUCCESS = 'ADD_CONFIGURATION_SUCCESS';
export const ADD_CONFIGURATION_FAIL= 'ADD_CONFIGURATION_FAIL';
export const ADD_CONFIGURATION_LOADING_CLEAR = 'ADD_CONFIGURATION_LOADING_CLEAR';
export const ADD_CONFIGURATION_ERROR_CLEAR = 'ADD_CONFIGURATION_ERROR_CLEAR';
export const ADD_CONFIGURATION_CANCEL = 'ADD_CONFIGURATION_CANCEL';
export const UPDATE_CONFIGURATION = 'UPDATE_CONFIGURATION';
export const UPDATE_SINGLE_CONFIGURATION = 'UPDATE_SINGLE_CONFIGURATION';
export const UPDATE_CONFIGURATION_START = 'UPDATE_CONFIGURATION_START';
export const UPDATE_CONFIGURATION_SUCCESS = 'UPDATE_CONFIGURATION_SUCCESS';
export const UPDATE_CONFIGURATION_FAIL = 'UPDATE_CONFIGURATION_FAIL';
export const UPDATE_CONFIGURATION_LOADING_CLEAR = 'UPDATE_CONFIGURATION_LOADING_CLEAR';
export const UPDATE_CONFIGURATION_ERROR_CLEAR = 'UPDATE_CONFIGURATION_ERROR_CLEAR';
export const UPDATE_CONFIGURATION_CANCEL = 'UPDATE_CONFIGURATION_CANCEL';
export const REMOVE_CONFIGURATION = 'REMOVE_CONFIGURATION';

//#endregion
//#region Contents Constants

// Contents
export const FETCH_CONTENTS_START = 'FETCH_CONTENTS_START';
export const FETCH_CONTENTS_SUCCESS = 'FETCH_CONTENTS_SUCCESS';
export const FETCH_CONTENTS_FAIL = 'FETCH_CONTENTS_FAIL';
export const CLEAR_CONTENTS = 'CLEAR_CONTENTS';
export const FETCH_CONTENT_START = 'FETCH_CONTENT_START';
export const FETCH_CONTENT_SUCCESS = 'FETCH_CONTENT_SUCCESS';
export const FETCH_CONTENT_FAIL = 'FETCH_CONTENT_FAIL';
export const CLEAR_CONTENT = 'CLEAR_CONTENT';
export const ADD_CONTENT = 'ADD_CONTENT';
export const ADD_CONTENT_START = 'ADD_CONTENT_START';
export const ADD_CONTENT_SUCCESS = 'ADD_CONTENT_SUCCESS';
export const ADD_CONTENT_FAIL= 'ADD_CONTENT_FAIL';
export const ADD_CONTENT_LOADING_CLEAR = 'ADD_CONTENT_LOADING_CLEAR';
export const ADD_CONTENT_ERROR_CLEAR = 'ADD_CONTENT_ERROR_CLEAR';
export const ADD_CONTENT_CANCEL = 'ADD_CONTENT_CANCEL';
export const UPDATE_CONTENT = 'UPDATE_CONTENT';
export const UPDATE_SINGLE_CONTENT = 'UPDATE_SINGLE_CONTENT';
export const UPDATE_CONTENT_START = 'UPDATE_CONTENT_START';
export const UPDATE_CONTENT_SUCCESS = 'UPDATE_CONTENT_SUCCESS';
export const UPDATE_CONTENT_FAIL = 'UPDATE_CONTENT_FAIL';
export const UPDATE_CONTENT_LOADING_CLEAR = 'UPDATE_CONTENT_LOADING_CLEAR';
export const UPDATE_CONTENT_ERROR_CLEAR = 'UPDATE_CONTENT_ERROR_CLEAR';
export const UPDATE_CONTENT_CANCEL = 'UPDATE_CONTENT_CANCEL';
export const REMOVE_CONTENT = 'REMOVE_CONTENT';

//#endregion
//#region Documents Constants

// Documents
export const FETCH_DOCUMENT_LIST_START = 'FETCH_DOCUMENT_LIST_START';
export const FETCH_DOCUMENT_LIST_SUCCESS = 'FETCH_DOCUMENT_LIST_SUCCESS';
export const FETCH_DOCUMENT_LIST_FAIL = 'FETCH_DOCUMENT_LIST_FAIL';
export const CLEAR_DOCUMENT_LIST = 'CLEAR_DOCUMENT_LIST';
export const CLEAR_DOCUMENT_LISTS = 'CLEAR_DOCUMENT_LISTS';
export const ADD_TO_DOCUMENT_LIST = 'ADD_TO_DOCUMENT_LIST';
export const UPDATE_IN_DOCUMENT_LIST = 'UPDATE_IN_DOCUMENT_LIST';
export const REMOVE_FROM_DOCUMENT_LIST = 'REMOVE_FROM_DOCUMENT_LIST';
export const FETCH_DOCUMENT_START = 'FETCH_DOCUMENT_START';
export const FETCH_DOCUMENT_SUCCESS = 'FETCH_DOCUMENT_SUCCESS';
export const FETCH_DOCUMENT_FAIL = 'FETCH_DOCUMENT_FAIL';
export const CLEAR_DOCUMENT = 'CLEAR_DOCUMENT';
export const ADD_DOCUMENT_START = 'ADD_DOCUMENT_START';
export const ADD_DOCUMENT_SUCCESS = 'ADD_DOCUMENT_SUCCESS';
export const ADD_DOCUMENT_FAIL = 'ADD_DOCUMENT_FAIL';
export const ADD_DOCUMENT_LOADING_CLEAR = 'ADD_DOCUMENT_LOADING_CLEAR';
export const ADD_DOCUMENT_ERROR_CLEAR = 'ADD_DOCUMENT_ERROR_CLEAR';
export const ADD_DOCUMENT_CANCEL = 'ADD_DOCUMENT_CANCEL';
export const UPDATE_SINGLE_DOCUMENT = 'UPDATE_SINGLE_DOCUMENT';
export const UPDATE_DOCUMENT_START = 'UPDATE_DOCUMENT_START';
export const UPDATE_DOCUMENT_SUCCESS = 'UPDATE_DOCUMENT_SUCCESS';
export const UPDATE_DOCUMENT_FAIL= 'UPDATE_DOCUMENT_FAIL';
export const UPDATE_DOCUMENT_LOADING_CLEAR = 'UPDATE_DOCUMENT_LOADING_CLEAR';
export const UPDATE_DOCUMENT_ERROR_CLEAR = 'UPDATE_DOCUMENT_ERROR_CLEAR';
export const UPDATE_DOCUMENT_CANCEL = 'UPDATE_DOCUMENT_CANCEL';

//#endregion
//#region Features Constants

// Features
export const FETCH_FEATURES_START = 'FETCH_FEATURES_START';
export const FETCH_FEATURES_SUCCESS = 'FETCH_FEATURES_SUCCESS';
export const FETCH_FEATURES_FAIL = 'FETCH_FEATURES_FAIL';
export const CLEAR_FEATURES = 'CLEAR_FEATURES';
export const FETCH_FEATURE_START = 'FETCH_FEATURE_START';
export const FETCH_FEATURE_SUCCESS = 'FETCH_FEATURE_SUCCESS';
export const FETCH_FEATURE_FAIL = 'FETCH_FEATURE_FAIL';
export const CLEAR_FEATURE = 'CLEAR_FEATURE';
export const ADD_FEATURE = 'ADD_FEATURE';
export const ADD_FEATURE_START = 'ADD_FEATURE_START';
export const ADD_FEATURE_SUCCESS = 'ADD_FEATURE_SUCCESS';
export const ADD_FEATURE_FAIL= 'ADD_FEATURE_FAIL';
export const ADD_FEATURE_LOADING_CLEAR = 'ADD_FEATURE_LOADING_CLEAR';
export const ADD_FEATURE_ERROR_CLEAR = 'ADD_FEATURE_ERROR_CLEAR';
export const ADD_FEATURE_CANCEL = 'ADD_FEATURE_CANCEL';
export const UPDATE_FEATURE = 'UPDATE_FEATURE';
export const UPDATE_SINGLE_FEATURE = 'UPDATE_SINGLE_FEATURE';
export const UPDATE_FEATURE_START = 'UPDATE_FEATURE_START';
export const UPDATE_FEATURE_SUCCESS = 'UPDATE_FEATURE_SUCCESS';
export const UPDATE_FEATURE_FAIL = 'UPDATE_FEATURE_FAIL';
export const UPDATE_FEATURE_LOADING_CLEAR = 'UPDATE_FEATURE_LOADING_CLEAR';
export const UPDATE_FEATURE_ERROR_CLEAR = 'UPDATE_FEATURE_ERROR_CLEAR';
export const UPDATE_FEATURE_CANCEL = 'UPDATE_FEATURE_CANCEL';
export const REMOVE_FEATURE = 'REMOVE_FEATURE';

//#endregion
//#region Keys Constants

// Keys
export const FETCH_KEYS_START = 'FETCH_KEYS_START';
export const FETCH_KEYS_SUCCESS = 'FETCH_KEYS_SUCCESS';
export const FETCH_KEYS_FAIL = 'FETCH_KEYS_FAIL';
export const CLEAR_KEYS = 'CLEAR_KEYS';

//#endregion
//#region Invoice Line Items Constants

// Invoice Line Items
export const FETCH_INVOICE_LINE_ITEM_LIST_START = 'FETCH_INVOICE_LINE_ITEM_LIST_START';
export const FETCH_INVOICE_LINE_ITEM_LIST_SUCCESS = 'FETCH_INVOICE_LINE_ITEM_LIST_SUCCESS';
export const FETCH_INVOICE_LINE_ITEM_LIST_FAIL = 'FETCH_INVOICE_LINE_ITEM_LIST_FAIL';
export const CLEAR_INVOICE_LINE_ITEM_LIST = 'CLEAR_INVOICE_LINE_ITEM_LIST';
export const CLEAR_INVOICE_LINE_ITEM_LISTS = 'CLEAR_INVOICE_LINE_ITEM_LISTS';
export const ADD_TO_INVOICE_LINE_ITEM_LIST = 'ADD_TO_INVOICE_LINE_ITEM_LIST';
export const UPDATE_IN_INVOICE_LINE_ITEM_LIST = 'UPDATE_IN_INVOICE_LINE_ITEM_LIST';
export const REMOVE_FROM_INVOICE_LINE_ITEM_LIST = 'REMOVE_FROM_INVOICE_LINE_ITEM_LIST';
export const FETCH_INVOICE_LINE_ITEMS_START = 'FETCH_INVOICE_LINE_ITEMS_START';
export const FETCH_INVOICE_LINE_ITEMS_SUCCESS = 'FETCH_INVOICE_LINE_ITEMS_SUCCESS';
export const FETCH_INVOICE_LINE_ITEMS_FAIL = 'FETCH_INVOICE_LINE_ITEMS_FAIL';
export const CLEAR_INVOICE_LINE_ITEMS = 'CLEAR_INVOICE_LINE_ITEMS';
export const FETCH_INVOICE_LINE_ITEM_START = 'FETCH_INVOICE_LINE_ITEM_START';
export const FETCH_INVOICE_LINE_ITEM_SUCCESS = 'FETCH_INVOICE_LINE_ITEM_SUCCESS';
export const FETCH_INVOICE_LINE_ITEM_FAIL = 'FETCH_INVOICE_LINE_ITEM_FAIL';
export const CLEAR_INVOICE_LINE_ITEM = 'CLEAR_INVOICE_LINE_ITEM';
export const ADD_INVOICE_LINE_ITEM = 'ADD_INVOICE_LINE_ITEM';
export const ADD_INVOICE_LINE_ITEM_START = 'ADD_INVOICE_LINE_ITEM_START';
export const ADD_INVOICE_LINE_ITEM_SUCCESS = 'ADD_INVOICE_LINE_ITEM_SUCCESS';
export const ADD_INVOICE_LINE_ITEM_FAIL= 'ADD_INVOICE_LINE_ITEM_FAIL';
export const ADD_INVOICE_LINE_ITEM_LOADING_CLEAR = 'ADD_INVOICE_LINE_ITEM_LOADING_CLEAR';
export const ADD_INVOICE_LINE_ITEM_ERROR_CLEAR = 'ADD_INVOICE_LINE_ITEM_ERROR_CLEAR';
export const ADD_INVOICE_LINE_ITEM_CANCEL = 'ADD_INVOICE_LINE_ITEM_CANCEL';
export const UPDATE_INVOICE_LINE_ITEM = 'UPDATE_INVOICE_LINE_ITEM';
export const UPDATE_SINGLE_INVOICE_LINE_ITEM = 'UPDATE_SINGLE_INVOICE_LINE_ITEM';
export const UPDATE_INVOICE_LINE_ITEM_START = 'UPDATE_INVOICE_LINE_ITEM_START';
export const UPDATE_INVOICE_LINE_ITEM_SUCCESS = 'UPDATE_INVOICE_LINE_ITEM_SUCCESS';
export const UPDATE_INVOICE_LINE_ITEM_FAIL = 'UPDATE_INVOICE_LINE_ITEM_FAIL';
export const UPDATE_INVOICE_LINE_ITEM_LOADING_CLEAR = 'UPDATE_INVOICE_LINE_ITEM_LOADING_CLEAR';
export const UPDATE_INVOICE_LINE_ITEM_ERROR_CLEAR = 'UPDATE_INVOICE_LINE_ITEM_ERROR_CLEAR';
export const UPDATE_INVOICE_LINE_ITEM_CANCEL = 'UPDATE_INVOICE_LINE_ITEM_CANCEL';
export const REMOVE_INVOICE_LINE_ITEM = 'REMOVE_INVOICE_LINE_ITEM';
export const REMOVE_INVOICE_LINE_ITEM_START = 'REMOVE_INVOICE_LINE_ITEM_START';
export const REMOVE_INVOICE_LINE_ITEM_SUCCESS = 'REMOVE_INVOICE_LINE_ITEM_SUCCESS';
export const REMOVE_INVOICE_LINE_ITEM_FAIL = 'REMOVE_INVOICE_LINE_ITEM_FAIL';
export const REMOVE_INVOICE_LINE_ITEM_LOADING_CLEAR = 'REMOVE_INVOICE_LINE_ITEM_LOADING_CLEAR';
export const REMOVE_INVOICE_LINE_ITEM_ERROR_CLEAR = 'REMOVE_INVOICE_LINE_ITEM_ERROR_CLEAR';
export const REMOVE_INVOICE_LINE_ITEM_CANCEL = 'REMOVE_INVOICE_LINE_ITEM_CANCEL';

//#endregion
//#region Invoices Constants

// Invoices
export const FETCH_INVOICE_LIST_START = 'FETCH_INVOICE_LIST_START';
export const FETCH_INVOICE_LIST_SUCCESS = 'FETCH_INVOICE_LIST_SUCCESS';
export const FETCH_INVOICE_LIST_FAIL = 'FETCH_INVOICE_LIST_FAIL';
export const CLEAR_INVOICE_LIST = 'CLEAR_INVOICE_LIST';
export const CLEAR_INVOICE_LISTS = 'CLEAR_INVOICE_LISTS';
export const ADD_TO_INVOICE_LIST = 'ADD_TO_INVOICE_LIST';
export const UPDATE_IN_INVOICE_LIST = 'UPDATE_IN_INVOICE_LIST';
export const REMOVE_FROM_INVOICE_LIST = 'REMOVE_FROM_INVOICE_LIST';
export const FETCH_INVOICES_START = 'FETCH_INVOICES_START';
export const FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS';
export const FETCH_INVOICES_FAIL = 'FETCH_INVOICES_FAIL';
export const CLEAR_INVOICES = 'CLEAR_INVOICES';
export const FETCH_INVOICE_START = 'FETCH_INVOICE_START';
export const FETCH_INVOICE_SUCCESS = 'FETCH_INVOICE_SUCCESS';
export const FETCH_INVOICE_FAIL = 'FETCH_INVOICE_FAIL';
export const CLEAR_INVOICE = 'CLEAR_INVOICE';
export const ADD_INVOICE = 'ADD_INVOICE';
export const ADD_INVOICE_START = 'ADD_INVOICE_START';
export const ADD_INVOICE_SUCCESS = 'ADD_INVOICE_SUCCESS';
export const ADD_INVOICE_FAIL = 'ADD_INVOICE_FAIL';
export const ADD_INVOICE_LOADING_CLEAR = 'ADD_INVOICE_LOADING_CLEAR';
export const ADD_INVOICE_ERROR_CLEAR = 'ADD_INVOICE_ERROR_CLEAR';
export const ADD_INVOICE_CANCEL = 'ADD_INVOICE_CANCEL';
export const UPDATE_INVOICE = 'UPDATE_INVOICE';
export const UPDATE_SINGLE_INVOICE = 'UPDATE_SINGLE_INVOICE';
export const UPDATE_INVOICE_START = 'UPDATE_INVOICE_START';
export const UPDATE_INVOICE_SUCCESS = 'UPDATE_INVOICE_SUCCESS';
export const UPDATE_INVOICE_FAIL = 'UPDATE_INVOICE_FAIL';
export const UPDATE_INVOICE_LOADING_CLEAR = 'UPDATE_INVOICE_LOADING_CLEAR';
export const UPDATE_INVOICE_ERROR_CLEAR = 'UPDATE_INVOICE_ERROR_CLEAR';
export const UPDATE_INVOICE_CANCEL = 'UPDATE_INVOICE_CANCEL';
export const REMOVE_INVOICE = 'REMOVE_INVOICE';

//#endregion
//#region Lanes Constants

// Lanes
export const FETCH_LANE_LIST_START = 'FETCH_LANE_LIST_START';
export const FETCH_LANE_LIST_SUCCESS = 'FETCH_LANE_LIST_SUCCESS';
export const FETCH_LANE_LIST_FAIL = 'FETCH_LANE_LIST_FAIL';
export const CLEAR_LANE_LIST = 'CLEAR_LANE_LIST';
export const CLEAR_LANE_LISTS = 'CLEAR_LANE_LISTS';
export const ADD_TO_LANE_LIST = 'ADD_TO_LANE_LIST';
export const UPDATE_IN_LANE_LIST = 'UPDATE_IN_LANE_LIST';
export const REMOVE_FROM_LANE_LIST = 'REMOVE_FROM_LANE_LIST';
export const FETCH_LANES_START = 'FETCH_LANES_START';
export const FETCH_LANES_SUCCESS = 'FETCH_LANES_SUCCESS';
export const FETCH_LANES_FAIL = 'FETCH_LANES_FAIL';
export const CLEAR_LANES = 'CLEAR_LANES';
export const FETCH_LANE_START = 'FETCH_LANE_START';
export const FETCH_LANE_SUCCESS = 'FETCH_LANE_SUCCESS';
export const FETCH_LANE_FAIL = 'FETCH_LANE_FAIL';
export const CLEAR_LANE = 'CLEAR_LANE';
export const ADD_LANE = 'ADD_LANE';
export const ADD_LANE_START = 'ADD_LANE_START';
export const ADD_LANE_SUCCESS = 'ADD_LANE_SUCCESS';
export const ADD_LANE_FAIL = 'ADD_LANE_FAIL';
export const ADD_LANE_LOADING_CLEAR = 'ADD_LANE_LOADING_CLEAR';
export const ADD_LANE_ERROR_CLEAR = 'ADD_LANE_ERROR_CLEAR';
export const ADD_LANE_CANCEL = 'ADD_LANE_CANCEL';
export const UPDATE_LANE = 'UPDATE_LANE';
export const UPDATE_SINGLE_LANE = 'UPDATE_SINGLE_LANE';
export const UPDATE_LANE_START = 'UPDATE_LANE_START';
export const UPDATE_LANE_SUCCESS = 'UPDATE_LANE_SUCCESS';
export const UPDATE_LANE_FAIL = 'UPDATE_LANE_FAIL';
export const UPDATE_LANE_LOADING_CLEAR = 'UPDATE_LANE_LOADING_CLEAR';
export const UPDATE_LANE_ERROR_CLEAR = 'UPDATE_LANE_ERROR_CLEAR';
export const UPDATE_LANE_CANCEL = 'UPDATE_LANE_CANCEL';
export const REMOVE_LANE = 'REMOVE_LANE';

//#endregion
//#region Line Item Types Constants

// Line Item Types
export const FETCH_LINE_ITEM_TYPES_START = 'FETCH_LINE_ITEM_TYPES_START';
export const FETCH_LINE_ITEM_TYPES_SUCCESS = 'FETCH_LINE_ITEM_TYPES_SUCCESS';
export const FETCH_LINE_ITEM_TYPES_FAIL = 'FETCH_LINE_ITEM_TYPES_FAIL';
export const CLEAR_LINE_ITEM_TYPES = 'CLEAR_LINE_ITEM_TYPES';
export const FETCH_LINE_ITEM_TYPE_START = 'FETCH_LINE_ITEM_TYPE_START';
export const FETCH_LINE_ITEM_TYPE_SUCCESS = 'FETCH_LINE_ITEM_TYPE_SUCCESS';
export const FETCH_LINE_ITEM_TYPE_FAIL = 'FETCH_LINE_ITEM_TYPE_FAIL';
export const CLEAR_LINE_ITEM_TYPE = 'CLEAR_LINE_ITEM_TYPE';
export const ADD_LINE_ITEM_TYPE = 'ADD_LINE_ITEM_TYPE';
export const ADD_LINE_ITEM_TYPE_START = 'ADD_LINE_ITEM_TYPE_START';
export const ADD_LINE_ITEM_TYPE_SUCCESS = 'ADD_LINE_ITEM_TYPE_SUCCESS';
export const ADD_LINE_ITEM_TYPE_FAIL= 'ADD_LINE_ITEM_TYPE_FAIL';
export const ADD_LINE_ITEM_TYPE_LOADING_CLEAR = 'ADD_LINE_ITEM_TYPE_LOADING_CLEAR';
export const ADD_LINE_ITEM_TYPE_ERROR_CLEAR = 'ADD_LINE_ITEM_TYPE_ERROR_CLEAR';
export const ADD_LINE_ITEM_TYPE_CANCEL = 'ADD_LINE_ITEM_TYPE_CANCEL';
export const UPDATE_LINE_ITEM_TYPE = 'UPDATE_LINE_ITEM_TYPE';
export const UPDATE_SINGLE_LINE_ITEM_TYPE = 'UPDATE_SINGLE_LINE_ITEM_TYPE';
export const UPDATE_LINE_ITEM_TYPE_START = 'UPDATE_LINE_ITEM_TYPE_START';
export const UPDATE_LINE_ITEM_TYPE_SUCCESS = 'UPDATE_LINE_ITEM_TYPE_SUCCESS';
export const UPDATE_LINE_ITEM_TYPE_FAIL = 'UPDATE_LINE_ITEM_TYPE_FAIL';
export const UPDATE_LINE_ITEM_TYPE_LOADING_CLEAR = 'UPDATE_LINE_ITEM_TYPE_LOADING_CLEAR';
export const UPDATE_LINE_ITEM_TYPE_ERROR_CLEAR = 'UPDATE_LINE_ITEM_TYPE_ERROR_CLEAR';
export const UPDATE_LINE_ITEM_TYPE_CANCEL = 'UPDATE_LINE_ITEM_TYPE_CANCEL';
export const REMOVE_LINE_ITEM_TYPE = 'REMOVE_LINE_ITEM_TYPE';

//#endregion
//#region Linked Accounts Constants

// LinkedAccounts
export const FETCH_LINKED_ACCOUNT_LIST_START = 'FETCH_LINKED_ACCOUNT_LIST_START';
export const FETCH_LINKED_ACCOUNT_LIST_SUCCESS = 'FETCH_LINKED_ACCOUNT_LIST_SUCCESS';
export const FETCH_LINKED_ACCOUNT_LIST_FAIL = 'FETCH_LINKED_ACCOUNT_LIST_FAIL';
export const CLEAR_LINKED_ACCOUNT_LIST = 'CLEAR_LINKED_ACCOUNT_LIST';
export const CLEAR_LINKED_ACCOUNT_LISTS = 'CLEAR_LINKED_ACCOUNT_LISTS';
export const ADD_TO_LINKED_ACCOUNT_LIST = 'ADD_TO_LINKED_ACCOUNT_LIST';
export const UPDATE_IN_LINKED_ACCOUNT_LIST = 'UPDATE_IN_LINKED_ACCOUNT_LIST';
export const REMOVE_FROM_LINKED_ACCOUNT_LIST = 'REMOVE_FROM_LINKED_ACCOUNT_LIST';
export const FETCH_LINKED_ACCOUNTS_START = 'FETCH_LINKED_ACCOUNTS_START';
export const FETCH_LINKED_ACCOUNTS = 'FETCH_LINKED_ACCOUNTS';
export const FETCH_LINKED_ACCOUNTS_SUCCESS = 'FETCH_LINKED_ACCOUNTS_SUCCESS';
export const FETCH_LINKED_ACCOUNTS_FAIL = 'FETCH_LINKED_ACCOUNTS_FAIL';
export const CLEAR_LINKED_ACCOUNTS = 'CLEAR_LINKED_ACCOUNTS';
export const FETCH_LINKED_ACCOUNT_START = 'FETCH_LINKED_ACCOUNT_START';
export const FETCH_LINKED_ACCOUNT_SUCCESS = 'FETCH_LINKED_ACCOUNT_SUCCESS';
export const FETCH_LINKED_ACCOUNT_FAIL = 'FETCH_LINKED_ACCOUNT_FAIL';
export const CLEAR_LINKED_ACCOUNT = 'CLEAR_LINKED_ACCOUNT';
export const ADD_LINKED_ACCOUNT = 'ADD_LINKED_ACCOUNT';
export const ADD_LINKED_ACCOUNT_START = 'ADD_LINKED_ACCOUNT_START';
export const ADD_LINKED_ACCOUNT_SUCCESS = 'ADD_LINKED_ACCOUNT_SUCCESS';
export const ADD_LINKED_ACCOUNT_FAIL = 'ADD_LINKED_ACCOUNT_FAIL';
export const ADD_LINKED_ACCOUNT_LOADING_CLEAR = 'ADD_LINKED_ACCOUNT_LOADING_CLEAR';
export const ADD_LINKED_ACCOUNT_ERROR_CLEAR = 'ADD_LINKED_ACCOUNT_ERROR_CLEAR';
export const ADD_LINKED_ACCOUNT_CANCEL = 'ADD_LINKED_ACCOUNT_CANCEL';
export const UPDATE_LINKED_ACCOUNT = 'UPDATE_LINKED_ACCOUNT';
export const UPDATE_SINGLE_LINKED_ACCOUNT = 'UPDATE_SINGLE_LINKED_ACCOUNT';
export const UPDATE_LINKED_ACCOUNT_START = 'UPDATE_LINKED_ACCOUNT_START';
export const UPDATE_LINKED_ACCOUNT_SUCCESS = 'UPDATE_LINKED_ACCOUNT_SUCCESS';
export const UPDATE_LINKED_ACCOUNT_FAIL = 'UPDATE_LINKED_ACCOUNT_FAIL';
export const UPDATE_LINKED_ACCOUNT_LOADING_CLEAR = 'UPDATE_LINKED_ACCOUNT_LOADING_CLEAR';
export const UPDATE_LINKED_ACCOUNT_ERROR_CLEAR = 'UPDATE_LINKED_ACCOUNT_ERROR_CLEAR';
export const UPDATE_LINKED_ACCOUNT_CANCEL = 'UPDATE_LINKED_ACCOUNT_CANCEL';
export const REMOVE_LINKED_ACCOUNT = 'REMOVE_LINKED_ACCOUNT';

//#endregion
//#region Linked Load Lanes Constants

// LinkedLoadLanes
export const FETCH_LINKED_LOAD_LANE_LIST_START = 'FETCH_LINKED_LOAD_LANE_LIST_START';
export const FETCH_LINKED_LOAD_LANE_LIST_SUCCESS = 'FETCH_LINKED_LOAD_LANE_LIST_SUCCESS';
export const FETCH_LINKED_LOAD_LANE_LIST_FAIL = 'FETCH_LINKED_LOAD_LANE_LIST_FAIL';
export const CLEAR_LINKED_LOAD_LANE_LIST = 'CLEAR_LINKED_LOAD_LANE_LIST';
export const CLEAR_LINKED_LOAD_LANE_LISTS = 'CLEAR_LINKED_LOAD_LANE_LISTS';
export const ADD_TO_LINKED_LOAD_LANE_LIST = 'ADD_TO_LINKED_LOAD_LANE_LIST';
export const UPDATE_IN_LINKED_LOAD_LANE_LIST = 'UPDATE_IN_LINKED_LOAD_LANE_LIST';
export const REMOVE_FROM_LINKED_LOAD_LANE_LIST = 'REMOVE_FROM_LINKED_LOAD_LANE_LIST';
export const FETCH_LINKED_LOAD_LANES_START = 'FETCH_LINKED_LOAD_LANES_START';
export const FETCH_LINKED_LOAD_LANES_SUCCESS = 'FETCH_LINKED_LOAD_LANES_SUCCESS';
export const FETCH_LINKED_LOAD_LANES_FAIL = 'FETCH_LINKED_LOAD_LANES_FAIL';
export const CLEAR_LINKED_LOAD_LANES = 'CLEAR_LINKED_LOAD_LANES';
export const FETCH_LINKED_LOAD_LANE_START = 'FETCH_LINKED_LOAD_LANE_START';
export const FETCH_LINKED_LOAD_LANE_SUCCESS = 'FETCH_LINKED_LOAD_LANE_SUCCESS';
export const FETCH_LINKED_LOAD_LANE_FAIL = 'FETCH_LINKED_LOAD_LANE_FAIL';
export const CLEAR_LINKED_LOAD_LANE = 'CLEAR_LINKED_LOAD_LANE';
export const ADD_LINKED_LOAD_LANE = 'ADD_LINKED_LOAD_LANE';
export const ADD_LINKED_LOAD_LANE_START = 'ADD_LINKED_LOAD_LANE_START';
export const ADD_LINKED_LOAD_LANE_SUCCESS = 'ADD_LINKED_LOAD_LANE_SUCCESS';
export const ADD_LINKED_LOAD_LANE_FAIL = 'ADD_LINKED_LOAD_LANE_FAIL';
export const ADD_LINKED_LOAD_LANE_LOADING_CLEAR = 'ADD_LINKED_LOAD_LANE_LOADING_CLEAR';
export const ADD_LINKED_LOAD_LANE_ERROR_CLEAR = 'ADD_LINKED_LOAD_LANE_ERROR_CLEAR';
export const ADD_LINKED_LOAD_LANE_CANCEL = 'ADD_LINKED_LOAD_LANE_CANCEL';
export const UPDATE_LINKED_LOAD_LANE = 'UPDATE_LINKED_LOAD_LANE';
export const UPDATE_SINGLE_LINKED_LOAD_LANE = 'UPDATE_SINGLE_LINKED_LOAD_LANE';
export const UPDATE_LINKED_LOAD_LANE_START = 'UPDATE_LINKED_LOAD_LANE_START';
export const UPDATE_LINKED_LOAD_LANE_SUCCESS = 'UPDATE_LINKED_LOAD_LANE_SUCCESS';
export const UPDATE_LINKED_LOAD_LANE_FAIL = 'UPDATE_LINKED_LOAD_LANE_FAIL';
export const UPDATE_LINKED_LOAD_LANE_LOADING_CLEAR = 'UPDATE_LINKED_LOAD_LANE_LOADING_CLEAR';
export const UPDATE_LINKED_LOAD_LANE_ERROR_CLEAR = 'UPDATE_LINKED_LOAD_LANE_ERROR_CLEAR';
export const UPDATE_LINKED_LOAD_LANE_CANCEL = 'UPDATE_LINKED_LOAD_LANE_CANCEL';
export const REMOVE_LINKED_LOAD_LANE = 'REMOVE_LINKED_LOAD_LANE';

//#endregion
//#region Load Cancellations Constants

// Load Cancellations
export const FETCH_LOAD_CANCELLATION_LIST_START = 'FETCH_LOAD_CANCELLATION_LIST_START';
export const FETCH_LOAD_CANCELLATION_LIST_SUCCESS = 'FETCH_LOAD_CANCELLATION_LIST_SUCCESS';
export const FETCH_LOAD_CANCELLATION_LIST_FAIL = 'FETCH_LOAD_CANCELLATION_LIST_FAIL';
export const CLEAR_LOAD_CANCELLATION_LIST = 'CLEAR_LOAD_CANCELLATION_LIST';
export const CLEAR_LOAD_CANCELLATION_LISTS = 'CLEAR_LOAD_CANCELLATION_LISTS';
export const ADD_TO_LOAD_CANCELLATION_LIST = 'ADD_TO_LOAD_CANCELLATION_LIST';
export const UPDATE_IN_LOAD_CANCELLATION_LIST = 'UPDATE_IN_LOAD_CANCELLATION_LIST';
export const REMOVE_FROM_LOAD_CANCELLATION_LIST = 'REMOVE_FROM_LOAD_CANCELLATION_LIST';
export const FETCH_LOAD_CANCELLATIONS_START = 'FETCH_LOAD_CANCELLATIONS_START';
export const FETCH_LOAD_CANCELLATIONS_SUCCESS = 'FETCH_LOAD_CANCELLATIONS_SUCCESS';
export const FETCH_LOAD_CANCELLATIONS_FAIL = 'FETCH_LOAD_CANCELLATIONS_FAIL';
export const CLEAR_LOAD_CANCELLATIONS = 'CLEAR_LOAD_CANCELLATIONS';
export const FETCH_LOAD_CANCELLATION_START = 'FETCH_LOAD_CANCELLATION_START';
export const FETCH_LOAD_CANCELLATION_SUCCESS = 'FETCH_LOAD_CANCELLATION_SUCCESS';
export const FETCH_LOAD_CANCELLATION_FAIL = 'FETCH_LOAD_CANCELLATION_FAIL';
export const CLEAR_LOAD_CANCELLATION = 'CLEAR_LOAD_CANCELLATION';
export const ADD_LOAD_CANCELLATION = 'ADD_LOAD_CANCELLATION';
export const ADD_LOAD_CANCELLATION_START = 'ADD_LOAD_CANCELLATION_START';
export const ADD_LOAD_CANCELLATION_SUCCESS = 'ADD_LOAD_CANCELLATION_SUCCESS';
export const ADD_LOAD_CANCELLATION_FAIL= 'ADD_LOAD_CANCELLATION_FAIL';
export const ADD_LOAD_CANCELLATION_LOADING_CLEAR = 'ADD_LOAD_CANCELLATION_LOADING_CLEAR';
export const ADD_LOAD_CANCELLATION_ERROR_CLEAR = 'ADD_LOAD_CANCELLATION_ERROR_CLEAR';
export const ADD_LOAD_CANCELLATION_CANCEL = 'ADD_LOAD_CANCELLATION_CANCEL';
export const UPDATE_LOAD_CANCELLATION = 'UPDATE_LOAD_CANCELLATION';
export const UPDATE_SINGLE_LOAD_CANCELLATION = 'UPDATE_SINGLE_LOAD_CANCELLATION';
export const UPDATE_LOAD_CANCELLATION_START = 'UPDATE_LOAD_CANCELLATION_START';
export const UPDATE_LOAD_CANCELLATION_SUCCESS = 'UPDATE_LOAD_CANCELLATION_SUCCESS';
export const UPDATE_LOAD_CANCELLATION_FAIL = 'UPDATE_LOAD_CANCELLATION_FAIL';
export const UPDATE_LOAD_CANCELLATION_LOADING_CLEAR = 'UPDATE_LOAD_CANCELLATION_LOADING_CLEAR';
export const UPDATE_LOAD_CANCELLATION_ERROR_CLEAR = 'UPDATE_LOAD_CANCELLATION_ERROR_CLEAR';
export const UPDATE_LOAD_CANCELLATION_CANCEL = 'UPDATE_LOAD_CANCELLATION_CANCEL';
export const REMOVE_LOAD_CANCELLATION = 'REMOVE_LOAD_CANCELLATION';

//#endregion
//#region Load Events

// Load Events
export const UPDATE_LOAD_EVENT_STATUS = 'UPDATE_LOAD_EVENT_STATUS';
export const UPDATE_LOAD_EVENT_START = 'UPDATE_LOAD_EVENT_START';
export const UPDATE_LOAD_EVENT_SUCCESS = 'UPDATE_LOAD_EVENT_SUCCESS';
export const UPDATE_LOAD_EVENT_FAIL = 'UPDATE_LOAD_EVENT_FAIL';
export const UPDATE_LOAD_EVENT_ERROR_CLEAR = 'UPDATE_LOAD_EVENT_ERROR_CLEAR';
export const UPDATE_LOAD_EVENT_COMPLETE = 'UPDATE_LOAD_EVENT_COMPLETE';

//#endregion
//#region Load Lanes Constants

// LoadLanes
export const FETCH_LOAD_LANE_LIST_START = 'FETCH_LOAD_LANE_LIST_START';
export const FETCH_LOAD_LANE_LIST_SUCCESS = 'FETCH_LOAD_LANE_LIST_SUCCESS';
export const FETCH_LOAD_LANE_LIST_FAIL = 'FETCH_LOAD_LANE_LIST_FAIL';
export const CLEAR_LOAD_LANE_LIST = 'CLEAR_LOAD_LANE_LIST';
export const CLEAR_LOAD_LANE_LISTS = 'CLEAR_LOAD_LANE_LISTS';
export const ADD_TO_LOAD_LANE_LIST = 'ADD_TO_LOAD_LANE_LIST';
export const UPDATE_IN_LOAD_LANE_LIST = 'UPDATE_IN_LOAD_LANE_LIST';
export const REMOVE_FROM_LOAD_LANE_LIST = 'REMOVE_FROM_LOAD_LANE_LIST';
export const FETCH_LOAD_LANES_START = 'FETCH_LOAD_LANES_START';
export const FETCH_LOAD_LANES_SUCCESS = 'FETCH_LOAD_LANES_SUCCESS';
export const FETCH_LOAD_LANES_FAIL = 'FETCH_LOAD_LANES_FAIL';
export const CLEAR_LOAD_LANES = 'CLEAR_LOAD_LANES';
export const FETCH_LOAD_LANE_START = 'FETCH_LOAD_LANE_START';
export const FETCH_LOAD_LANE_SUCCESS = 'FETCH_LOAD_LANE_SUCCESS';
export const FETCH_LOAD_LANE_FAIL = 'FETCH_LOAD_LANE_FAIL';
export const CLEAR_LOAD_LANE = 'CLEAR_LOAD_LANE';
export const ADD_LOAD_LANE = 'ADD_LOAD_LANE';
export const ADD_LOAD_LANE_START = 'ADD_LOAD_LANE_START';
export const ADD_LOAD_LANE_SUCCESS = 'ADD_LOAD_LANE_SUCCESS';
export const ADD_LOAD_LANE_FAIL = 'ADD_LOAD_LANE_FAIL';
export const ADD_LOAD_LANE_LOADING_CLEAR = 'ADD_LOAD_LANE_LOADING_CLEAR';
export const ADD_LOAD_LANE_ERROR_CLEAR = 'ADD_LOAD_LANE_ERROR_CLEAR';
export const ADD_LOAD_LANE_CANCEL = 'ADD_LOAD_LANE_CANCEL';
export const UPDATE_LOAD_LANE = 'UPDATE_LOAD_LANE';
export const UPDATE_SINGLE_LOAD_LANE = 'UPDATE_SINGLE_LOAD_LANE';
export const UPDATE_LOAD_LANE_START = 'UPDATE_LOAD_LANE_START';
export const UPDATE_LOAD_LANE_SUCCESS = 'UPDATE_LOAD_LANE_SUCCESS';
export const UPDATE_LOAD_LANE_FAIL = 'UPDATE_LOAD_LANE_FAIL';
export const UPDATE_LOAD_LANE_LOADING_CLEAR = 'UPDATE_LOAD_LANE_LOADING_CLEAR';
export const UPDATE_LOAD_LANE_ERROR_CLEAR = 'UPDATE_LOAD_LANE_ERROR_CLEAR';
export const UPDATE_LOAD_LANE_CANCEL = 'UPDATE_LOAD_LANE_CANCEL';
export const REMOVE_LOAD_LANE = 'REMOVE_LOAD_LANE';

//#endregion
//#region Loads Constants

// Loads
export const FETCH_LOAD_LIST_START = 'FETCH_LOAD_LIST_START';
export const FETCH_LOAD_LIST_SUCCESS = 'FETCH_LOAD_LIST_SUCCESS';
export const FETCH_LOAD_LIST_FAIL = 'FETCH_LOAD_LIST_FAIL';
export const CLEAR_LOAD_LIST = 'CLEAR_LOAD_LIST';
export const CLEAR_LOAD_LISTS = 'CLEAR_LOAD_LISTS';
export const ADD_TO_LOAD_LIST = 'ADD_TO_LOAD_LIST';
export const UPDATE_IN_LOAD_LIST = 'UPDATE_IN_LOAD_LIST';
export const REMOVE_FROM_LOAD_LIST = 'REMOVE_FROM_LOAD_LIST';
export const FETCH_LOADS_START = 'FETCH_LOADS_START';
export const FETCH_LOADS_SUCCESS = 'FETCH_LOADS_SUCCESS';
export const FETCH_LOADS_FAIL = 'FETCH_LOADS_FAIL';
export const CLEAR_LOADS = 'CLEAR_LOADS';
export const FETCH_BUNDLED_LOADS_START = 'FETCH_BUNDLED_LOADS_START';
export const FETCH_BUNDLED_LOADS_SUCCESS = 'FETCH_BUNDLED_LOADS_SUCCESS';
export const FETCH_BUNDLED_LOADS_FAIL = 'FETCH_BUNDLED_LOADS_FAIL';
export const CLEAR_BUNDLED_LOADS = 'CLEAR_BUNDLED_LOADS';
export const FETCH_LOAD_START = 'FETCH_LOAD_START';
export const FETCH_LOAD_SUCCESS = 'FETCH_LOAD_SUCCESS';
export const FETCH_LOAD_FAIL = 'FETCH_LOAD_FAIL';
export const CLEAR_LOAD = 'CLEAR_LOAD';
export const ADD_LOAD = 'ADD_LOAD';
export const ADD_LOAD_START = 'ADD_LOAD_START';
export const ADD_LOAD_SUCCESS = 'ADD_LOAD_SUCCESS';
export const ADD_LOAD_FAIL = 'ADD_LOAD_FAIL';
export const ADD_LOAD_LOADING_CLEAR = 'ADD_LOAD_LOADING_CLEAR';
export const ADD_LOAD_ERROR_CLEAR = 'ADD_LOAD_ERROR_CLEAR';
export const ADD_LOAD_CANCEL = 'ADD_LOAD_CANCEL';
export const UPDATE_LOAD = 'UPDATE_LOAD';
export const UPDATE_SINGLE_LOAD = 'UPDATE_SINGLE_LOAD';
export const UPDATE_BUNDLED_LOAD = 'UPDATE_BUNDLED_LOAD';
export const UPDATE_LOAD_START = 'UPDATE_LOAD_START';
export const UPDATE_LOAD_SUCCESS = 'UPDATE_LOAD_SUCCESS';
export const UPDATE_LOAD_FAIL = 'UPDATE_LOAD_FAIL';
export const UPDATE_LOAD_LOADING_CLEAR = 'UPDATE_LOAD_LOADING_CLEAR';
export const UPDATE_LOAD_ERROR_CLEAR = 'UPDATE_LOAD_ERROR_CLEAR';
export const UPDATE_LOAD_CANCEL = 'UPDATE_LOAD_CANCEL';
export const UPDATE_LOAD_STATUS_START = 'UPDATE_LOAD_STATUS_START';
export const UPDATE_LOAD_STATUS_SUCCESS = 'UPDATE_LOAD_STATUS_SUCCESS';
export const UPDATE_LOAD_STATUS_FAIL = 'UPDATE_LOAD_STATUS_FAIL';
export const UPDATE_LOAD_STATUS_LOADING_CLEAR = 'UPDATE_LOAD_STATUS_LOADING_CLEAR';
export const UPDATE_LOAD_STATUS_ERROR_CLEAR = 'UPDATE_LOAD_STATUS_ERROR_CLEAR';
export const UPDATE_LOAD_STATUS_CANCEL = 'UPDATE_LOAD_STATUS_CANCEL';
export const REMOVE_LOAD = 'REMOVE_LOAD';

//#endregion
//#region Load Stops Constants

// Load Stops
export const FETCH_LOAD_STOP_LIST_START = 'FETCH_LOAD_STOP_LIST_START';
export const FETCH_LOAD_STOP_LIST_SUCCESS = 'FETCH_LOAD_STOP_LIST_SUCCESS';
export const FETCH_LOAD_STOP_LIST_FAIL = 'FETCH_LOAD_STOP_LIST_FAIL';
export const CLEAR_LOAD_STOP_LIST = 'CLEAR_LOAD_STOP_LIST';
export const CLEAR_LOAD_STOP_LISTS = 'CLEAR_LOAD_STOP_LISTS';
export const ADD_TO_LOAD_STOP_LIST = 'ADD_TO_LOAD_STOP_LIST';
export const UPDATE_IN_LOAD_STOP_LIST = 'UPDATE_IN_LOAD_STOP_LIST';
export const REMOVE_FROM_LOAD_STOP_LIST = 'REMOVE_FROM_LOAD_STOP_LIST';
export const FETCH_LOAD_STOPS_START = 'FETCH_LOAD_STOPS_START';
export const FETCH_LOAD_STOPS_SUCCESS = 'FETCH_LOAD_STOPS_SUCCESS';
export const FETCH_LOAD_STOPS_FAIL = 'FETCH_LOAD_STOPS_FAIL';
export const CLEAR_LOAD_STOPS = 'CLEAR_LOAD_STOPS';
export const FETCH_LOAD_STOP_START = 'FETCH_LOAD_STOP_START';
export const FETCH_LOAD_STOP_SUCCESS = 'FETCH_LOAD_STOP_SUCCESS';
export const FETCH_LOAD_STOP_FAIL = 'FETCH_LOAD_STOP_FAIL';
export const CLEAR_LOAD_STOP = 'CLEAR_LOAD_STOP';
export const ADD_LOAD_STOP = 'ADD_LOAD_STOP';
export const ADD_LOAD_STOP_START = 'ADD_LOAD_STOP_START';
export const ADD_LOAD_STOP_SUCCESS = 'ADD_LOAD_STOP_SUCCESS';
export const ADD_LOAD_STOP_FAIL = 'ADD_LOAD_STOP_FAIL';
export const ADD_LOAD_STOP_LOADING_CLEAR = 'ADD_LOAD_STOP_LOADING_CLEAR';
export const ADD_LOAD_STOP_ERROR_CLEAR = 'ADD_LOAD_STOP_ERROR_CLEAR';
export const ADD_LOAD_STOP_CANCEL = 'ADD_LOAD_STOP_CANCEL';
export const UPDATE_LOAD_STOP = 'UPDATE_LOAD_STOP';
export const UPDATE_SINGLE_LOAD_STOP = 'UPDATE_SINGLE_LOAD_STOP';
export const UPDATE_LOAD_STOP_START = 'UPDATE_LOAD_STOP_START';
export const UPDATE_LOAD_STOP_SUCCESS = 'UPDATE_LOAD_STOP_SUCCESS';
export const UPDATE_LOAD_STOP_FAIL = 'UPDATE_LOAD_STOP_FAIL';
export const UPDATE_LOAD_STOP_LOADING_CLEAR = 'UPDATE_LOAD_STOP_LOADING_CLEAR';
export const UPDATE_LOAD_STOP_ERROR_CLEAR = 'UPDATE_LOAD_STOP_ERROR_CLEAR';
export const UPDATE_LOAD_STOP_CANCEL = 'UPDATE_LOAD_STOP_CANCEL';
export const REMOVE_LOAD_STOP = 'REMOVE_LOAD_STOP';

//#endregion
//#region Locations Constants

// Locations
export const FETCH_LOCATION_LIST_START = 'FETCH_LOCATION_LIST_START';
export const FETCH_LOCATION_LIST_SUCCESS = 'FETCH_LOCATION_LIST_SUCCESS';
export const FETCH_LOCATION_LIST_FAIL = 'FETCH_LOCATION_LIST_FAIL';
export const CLEAR_LOCATION_LIST = 'CLEAR_LOCATION_LIST';
export const CLEAR_LOCATION_LISTS = 'CLEAR_LOCATION_LISTS';
export const ADD_TO_LOCATION_LIST = 'ADD_TO_LOCATION_LIST';
export const UPDATE_IN_LOCATION_LIST = 'UPDATE_IN_LOCATION_LIST';
export const REMOVE_FROM_LOCATION_LIST = 'REMOVE_FROM_LOCATION_LIST';
export const FETCH_LOCATIONS_START = 'FETCH_LOCATIONS_START';
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';
export const FETCH_LOCATIONS_FAIL = 'FETCH_LOCATIONS_FAIL';
export const CLEAR_LOCATIONS = 'CLEAR_LOCATIONS';
export const FETCH_LOCATION_START = 'FETCH_LOCATION_START';
export const FETCH_LOCATION_SUCCESS = 'FETCH_LOCATION_SUCCESS';
export const FETCH_LOCATION_FAIL = 'FETCH_LOCATION_FAIL';
export const CLEAR_LOCATION = 'CLEAR_LOCATION';
export const ADD_LOCATION = 'ADD_LOCATION';
export const ADD_LOCATION_START = 'ADD_LOCATION_START';
export const ADD_LOCATION_SUCCESS = 'ADD_LOCATION_SUCCESS';
export const ADD_LOCATION_FAIL = 'ADD_LOCATION_FAIL';
export const ADD_LOCATION_LOADING_CLEAR = 'ADD_LOCATION_LOADING_CLEAR';
export const ADD_LOCATION_ERROR_CLEAR = 'ADD_LOCATION_ERROR_CLEAR';
export const ADD_LOCATION_CANCEL = 'ADD_LOCATION_CANCEL';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const UPDATE_SINGLE_LOCATION = 'UPDATE_SINGLE_LOCATION';
export const UPDATE_LOCATION_START = 'UPDATE_LOCATION_START';
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS';
export const UPDATE_LOCATION_FAIL = 'UPDATE_LOCATION_FAIL';
export const UPDATE_LOCATION_LOADING_CLEAR = 'UPDATE_LOCATION_LOADING_CLEAR';
export const UPDATE_LOCATION_ERROR_CLEAR = 'UPDATE_LOCATION_ERROR_CLEAR';
export const UPDATE_LOCATION_CANCEL = 'UPDATE_LOCATION_CANCEL';
export const REMOVE_LOCATION = 'REMOVE_LOCATION';

//#endregion
//#region Messages Constants

// Messages
export const FETCH_MESSAGE_LIST_START = 'FETCH_MESSAGE_LIST_START';
export const FETCH_MESSAGE_LIST_SUCCESS = 'FETCH_MESSAGE_LIST_SUCCESS';
export const FETCH_MESSAGE_LIST_FAIL = 'FETCH_MESSAGE_LIST_FAIL';
export const CLEAR_MESSAGE_LIST = 'CLEAR_MESSAGE_LIST';
export const CLEAR_MESSAGE_LISTS = 'CLEAR_MESSAGE_LISTS';
export const ADD_TO_MESSAGE_LIST = 'ADD_TO_MESSAGE_LIST';
export const UPDATE_IN_MESSAGE_LIST = 'UPDATE_IN_MESSAGE_LIST';
export const REMOVE_FROM_MESSAGE_LIST = 'REMOVE_FROM_MESSAGE_LIST';
export const FETCH_MESSAGE_START = 'FETCH_MESSAGE_START';
export const FETCH_MESSAGE_SUCCESS = 'FETCH_MESSAGE_SUCCESS';
export const FETCH_MESSAGE_FAIL = 'FETCH_MESSAGE_FAIL';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export const ADD_MESSAGE_START = 'ADD_MESSAGE_START';
export const ADD_MESSAGE_SUCCESS = 'ADD_MESSAGE_SUCCESS';
export const ADD_MESSAGE_FAIL= 'ADD_MESSAGE_FAIL';
export const ADD_MESSAGE_LOADING_CLEAR = 'ADD_MESSAGE_LOADING_CLEAR';
export const ADD_MESSAGE_ERROR_CLEAR = 'ADD_MESSAGE_ERROR_CLEAR';
export const ADD_MESSAGE_CANCEL = 'ADD_MESSAGE_CANCEL';
export const UPDATE_SINGLE_MESSAGE = 'UPDATE_SINGLE_MESSAGE';
export const UPDATE_MESSAGE_START = 'UPDATE_MESSAGE_START';
export const UPDATE_MESSAGE_SUCCESS = 'UPDATE_MESSAGE_SUCCESS';
export const UPDATE_MESSAGE_FAIL = 'UPDATE_MESSAGE_FAIL';
export const UPDATE_MESSAGE_LOADING_CLEAR = 'UPDATE_MESSAGE_LOADING_CLEAR';
export const UPDATE_MESSAGE_ERROR_CLEAR = 'UPDATE_MESSAGE_ERROR_CLEAR';
export const UPDATE_MESSAGE_CANCEL = 'UPDATE_MESSAGE_CANCEL';

//#endregion
//#region Metrics Constants

// Metrics
export const FETCH_ACCOUNTS_PENDING_VERIFICATION_START = 'FETCH_ACCOUNTS_PENDING_VERIFICATION_START';
export const FETCH_ACCOUNTS_PENDING_VERIFICATION_SUCCESS = 'FETCH_ACCOUNTS_PENDING_VERIFICATION_SUCCESS';
export const FETCH_ACCOUNTS_PENDING_VERIFICATION_FAIL = 'FETCH_ACCOUNTS_PENDING_VERIFICATION_FAIL';
export const CLEAR_ACCOUNTS_PENDING_VERIFICATION = 'CLEAR_ACCOUNTS_PENDING_VERIFICATION';
export const FETCH_DRIVERS_MISSING_DOCUMENTS_START = 'FETCH_DRIVERS_MISSING_DOCUMENTS_START';
export const FETCH_DRIVERS_MISSING_DOCUMENTS_SUCCESS = 'FETCH_DRIVERS_MISSING_DOCUMENTS_SUCCESS';
export const FETCH_DRIVERS_MISSING_DOCUMENTS_FAIL = 'FETCH_DRIVERS_MISSING_DOCUMENTS_FAIL';
export const CLEAR_DRIVERS_MISSING_DOCUMENTS = 'CLEAR_DRIVERS_MISSING_DOCUMENTS';
export const FETCH_DRIVERS_PENDING_APPROVAL_START = 'FETCH_DRIVERS_PENDING_APPROVAL_START';
export const FETCH_DRIVERS_PENDING_APPROVAL_SUCCESS = 'FETCH_DRIVERS_PENDING_APPROVAL_SUCCESS';
export const FETCH_DRIVERS_PENDING_APPROVAL_FAIL = 'FETCH_DRIVERS_PENDING_APPROVAL_FAIL';
export const CLEAR_DRIVERS_PENDING_APPROVAL = 'CLEAR_DRIVERS_PENDING_APPROVAL';
export const FETCH_DRIVERS_WITH_EXPIRING_LICENSES_START = 'FETCH_DRIVERS_WITH_EXPIRING_LICENSES_START';
export const FETCH_DRIVERS_WITH_EXPIRING_LICENSES_SUCCESS = 'FETCH_DRIVERS_WITH_EXPIRING_LICENSES_SUCCESS';
export const FETCH_DRIVERS_WITH_EXPIRING_LICENSES_FAIL = 'FETCH_DRIVERS_WITH_EXPIRING_LICENSES_FAIL';
export const CLEAR_DRIVERS_WITH_EXPIRING_LICENSES = 'CLEAR_DRIVERS_WITH_EXPIRING_LICENSES';
export const FETCH_IN_TRANSIT_LOADS_WITHOUT_LOCATION_DATA_START = 'FETCH_IN_TRANSIT_LOADS_WITHOUT_LOCATION_DATA_START';
export const FETCH_IN_TRANSIT_LOADS_WITHOUT_LOCATION_DATA_SUCCESS = 'FETCH_IN_TRANSIT_LOADS_WITHOUT_LOCATION_DATA_SUCCESS';
export const FETCH_IN_TRANSIT_LOADS_WITHOUT_LOCATION_DATA_FAIL = 'FETCH_IN_TRANSIT_LOADS_WITHOUT_LOCATION_DATA_FAIL';
export const CLEAR_IN_TRANSIT_LOADS_WITHOUT_LOCATION_DATA = 'CLEAR_IN_TRANSIT_LOADS_WITHOUT_LOCATION_DATA';
export const FETCH_CRITICAL_LOADS_START = 'FETCH_CRITICAL_LOADS_START';
export const FETCH_CRITICAL_LOADS_SUCCESS = 'FETCH_CRITICAL_LOADS_SUCCESS';
export const FETCH_CRITICAL_LOADS_FAIL = 'FETCH_CRITICAL_LOADS_FAIL';
export const CLEAR_CRITICAL_LOADS = 'CLEAR_CRITICAL_LOADS';
export const FETCH_METRICS_START = 'FETCH_METRICS_START';
export const FETCH_METRICS_SUCCESS = 'FETCH_METRICS_SUCCESS';
export const FETCH_METRICS_FAIL = 'FETCH_METRICS_FAIL';
export const CLEAR_METRICS = 'CLEAR_METRICS';

//#endregion
//#region Notes Constants

// Notes
export const FETCH_NOTE_LIST_START = 'FETCH_NOTE_LIST_START';
export const FETCH_NOTE_LIST_SUCCESS = 'FETCH_NOTE_LIST_SUCCESS';
export const FETCH_NOTE_LIST_FAIL = 'FETCH_NOTE_LIST_FAIL';
export const CLEAR_NOTE_LIST = 'CLEAR_NOTE_LIST';
export const CLEAR_NOTE_LISTS = 'CLEAR_NOTE_LISTS';
export const ADD_TO_NOTE_LIST = 'ADD_TO_NOTE_LIST';
export const UPDATE_IN_NOTE_LIST = 'UPDATE_IN_NOTE_LIST';
export const REMOVE_FROM_NOTE_LIST = 'REMOVE_FROM_NOTE_LIST';
export const FETCH_NOTE_START = 'FETCH_NOTE_START';
export const FETCH_NOTE_SUCCESS = 'FETCH_NOTE_SUCCESS';
export const FETCH_NOTE_FAIL = 'FETCH_NOTE_FAIL';
export const CLEAR_NOTE = 'CLEAR_NOTE';
export const ADD_NOTE_START = 'ADD_NOTE_START';
export const ADD_NOTE_SUCCESS = 'ADD_NOTE_SUCCESS';
export const ADD_NOTE_FAIL= 'ADD_NOTE_FAIL';
export const ADD_NOTE_LOADING_CLEAR = 'ADD_NOTE_LOADING_CLEAR';
export const ADD_NOTE_ERROR_CLEAR = 'ADD_NOTE_ERROR_CLEAR';
export const ADD_NOTE_CANCEL = 'ADD_NOTE_CANCEL';
export const UPDATE_SINGLE_NOTE = 'UPDATE_SINGLE_NOTE';
export const UPDATE_NOTE_START = 'UPDATE_NOTE_START';
export const UPDATE_NOTE_SUCCESS = 'UPDATE_NOTE_SUCCESS';
export const UPDATE_NOTE_FAIL = 'UPDATE_NOTE_FAIL';
export const UPDATE_NOTE_LOADING_CLEAR = 'UPDATE_NOTE_LOADING_CLEAR';
export const UPDATE_NOTE_ERROR_CLEAR = 'UPDATE_NOTE_ERROR_CLEAR';
export const UPDATE_NOTE_CANCEL = 'UPDATE_NOTE_CANCEL';

//#endregion
//#region Notifications Constants

// Notifications
export const FETCH_NOTIFICATION_LIST_START = 'FETCH_NOTIFICATION_LIST_START';
export const FETCH_NOTIFICATION_LIST_SUCCESS = 'FETCH_NOTIFICATION_LIST_SUCCESS';
export const FETCH_NOTIFICATION_LIST_FAIL = 'FETCH_NOTIFICATION_LIST_FAIL';
export const CLEAR_NOTIFICATION_LIST = 'CLEAR_NOTIFICATION_LIST';
export const CLEAR_NOTIFICATION_LISTS = 'CLEAR_NOTIFICATION_LISTS';
export const ADD_TO_NOTIFICATION_LIST = 'ADD_TO_NOTIFICATION_LIST';
export const UPDATE_IN_NOTIFICATION_LIST = 'UPDATE_IN_NOTIFICATION_LIST';
export const REMOVE_FROM_NOTIFICATION_LIST = 'REMOVE_FROM_NOTIFICATION_LIST';
export const FETCH_NOTIFICATION_START = 'FETCH_NOTIFICATION_START';
export const FETCH_NOTIFICATION_SUCCESS = 'FETCH_NOTIFICATION_SUCCESS';
export const FETCH_NOTIFICATION_FAIL = 'FETCH_NOTIFICATION_FAIL';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
export const ADD_NOTIFICATION_START = 'ADD_NOTIFICATION_START';
export const ADD_NOTIFICATION_SUCCESS = 'ADD_NOTIFICATION_SUCCESS';
export const ADD_NOTIFICATION_FAIL= 'ADD_NOTIFICATION_FAIL';
export const ADD_NOTIFICATION_LOADING_CLEAR = 'ADD_NOTIFICATION_LOADING_CLEAR';
export const ADD_NOTIFICATION_ERROR_CLEAR = 'ADD_NOTIFICATION_ERROR_CLEAR';
export const ADD_NOTIFICATION_CANCEL = 'ADD_NOTIFICATION_CANCEL';
export const UPDATE_SINGLE_NOTIFICATION = 'UPDATE_SINGLE_NOTIFICATION';
export const UPDATE_NOTIFICATION_START = 'UPDATE_NOTIFICATION_START';
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS';
export const UPDATE_NOTIFICATION_FAIL = 'UPDATE_NOTIFICATION_FAIL';
export const UPDATE_NOTIFICATION_LOADING_CLEAR = 'UPDATE_NOTIFICATION_LOADING_CLEAR';
export const UPDATE_NOTIFICATION_ERROR_CLEAR = 'UPDATE_NOTIFICATION_ERROR_CLEAR';
export const UPDATE_NOTIFICATION_CANCEL = 'UPDATE_NOTIFICATION_CANCEL';

//#endregion
//#region Orchestrator Constants

// ORCHESTRATOR
export const FETCH_ORCHESTRATOR_SUCCESS = 'FETCH_ORCHESTRATOR_SUCCESS';

//#endregion
//#region Preferred Lanes Constants

// PreferredLanes
export const FETCH_PREFERRED_LANE_LIST_START = 'FETCH_PREFERRED_LANE_LIST_START';
export const FETCH_PREFERRED_LANE_LIST_SUCCESS = 'FETCH_PREFERRED_LANE_LIST_SUCCESS';
export const FETCH_PREFERRED_LANE_LIST_FAIL = 'FETCH_PREFERRED_LANE_LIST_FAIL';
export const CLEAR_PREFERRED_LANE_LIST = 'CLEAR_PREFERRED_LANE_LIST';
export const CLEAR_PREFERRED_LANE_LISTS = 'CLEAR_PREFERRED_LANE_LISTS';
export const ADD_TO_PREFERRED_LANE_LIST = 'ADD_TO_PREFERRED_LANE_LIST';
export const UPDATE_IN_PREFERRED_LANE_LIST = 'UPDATE_IN_PREFERRED_LANE_LIST';
export const REMOVE_FROM_PREFERRED_LANE_LIST = 'REMOVE_FROM_PREFERRED_LANE_LIST';
export const FETCH_PREFERRED_LANES_START = 'FETCH_PREFERRED_LANES_START';
export const FETCH_PREFERRED_LANES_SUCCESS = 'FETCH_PREFERRED_LANES_SUCCESS';
export const FETCH_PREFERRED_LANES_FAIL = 'FETCH_PREFERRED_LANES_FAIL';
export const CLEAR_PREFERRED_LANES = 'CLEAR_PREFERRED_LANES';
export const FETCH_PREFERRED_LANE_START = 'FETCH_PREFERRED_LANE_START';
export const FETCH_PREFERRED_LANE_SUCCESS = 'FETCH_PREFERRED_LANE_SUCCESS';
export const FETCH_PREFERRED_LANE_FAIL = 'FETCH_PREFERRED_LANE_FAIL';
export const CLEAR_PREFERRED_LANE = 'CLEAR_PREFERRED_LANE';
export const ADD_PREFERRED_LANE = 'ADD_PREFERRED_LANE';
export const ADD_PREFERRED_LANE_START = 'ADD_PREFERRED_LANE_START';
export const ADD_PREFERRED_LANE_SUCCESS = 'ADD_PREFERRED_LANE_SUCCESS';
export const ADD_PREFERRED_LANE_FAIL = 'ADD_PREFERRED_LANE_FAIL';
export const ADD_PREFERRED_LANE_LOADING_CLEAR = 'ADD_PREFERRED_LANE_LOADING_CLEAR';
export const ADD_PREFERRED_LANE_ERROR_CLEAR = 'ADD_PREFERRED_LANE_ERROR_CLEAR';
export const ADD_PREFERRED_LANE_CANCEL = 'ADD_PREFERRED_LANE_CANCEL';
export const UPDATE_PREFERRED_LANE = 'UPDATE_PREFERRED_LANE';
export const UPDATE_SINGLE_PREFERRED_LANE = 'UPDATE_SINGLE_PREFERRED_LANE';
export const UPDATE_PREFERRED_LANE_START = 'UPDATE_PREFERRED_LANE_START';
export const UPDATE_PREFERRED_LANE_SUCCESS = 'UPDATE_PREFERRED_LANE_SUCCESS';
export const UPDATE_PREFERRED_LANE_FAIL = 'UPDATE_PREFERRED_LANE_FAIL';
export const UPDATE_PREFERRED_LANE_LOADING_CLEAR = 'UPDATE_PREFERRED_LANE_LOADING_CLEAR';
export const UPDATE_PREFERRED_LANE_ERROR_CLEAR = 'UPDATE_PREFERRED_LANE_ERROR_CLEAR';
export const UPDATE_PREFERRED_LANE_CANCEL = 'UPDATE_PREFERRED_LANE_CANCEL';
export const REMOVE_PREFERRED_LANE = 'REMOVE_PREFERRED_LANE';

//#endregion
//#region Products Constants

// Products
export const FETCH_PRODUCT_LIST_START = 'FETCH_PRODUCT_LIST_START';
export const FETCH_PRODUCT_LIST_SUCCESS = 'FETCH_PRODUCT_LIST_SUCCESS';
export const FETCH_PRODUCT_LIST_FAIL = 'FETCH_PRODUCT_LIST_FAIL';
export const CLEAR_PRODUCT_LIST = 'CLEAR_PRODUCT_LIST';
export const CLEAR_PRODUCT_LISTS = 'CLEAR_PRODUCT_LISTS';
export const ADD_TO_PRODUCT_LIST = 'ADD_TO_PRODUCT_LIST';
export const UPDATE_IN_PRODUCT_LIST = 'UPDATE_IN_PRODUCT_LIST';
export const REMOVE_FROM_PRODUCT_LIST = 'REMOVE_FROM_PRODUCT_LIST';
export const FETCH_PRODUCTS_START = 'FETCH_PRODUCTS_START';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAIL = 'FETCH_PRODUCTS_FAIL';
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS';
export const FETCH_PRODUCT_START = 'FETCH_PRODUCT_START';
export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS';
export const FETCH_PRODUCT_FAIL = 'FETCH_PRODUCT_FAIL';
export const CLEAR_PRODUCT = 'CLEAR_PRODUCT';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const ADD_PRODUCT_START = 'ADD_PRODUCT_START';
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
export const ADD_PRODUCT_FAIL= 'ADD_PRODUCT_FAIL';
export const ADD_PRODUCT_LOADING_CLEAR = 'ADD_PRODUCT_LOADING_CLEAR';
export const ADD_PRODUCT_ERROR_CLEAR = 'ADD_PRODUCT_ERROR_CLEAR';
export const ADD_PRODUCT_CANCEL = 'ADD_PRODUCT_CANCEL';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const UPDATE_SINGLE_PRODUCT = 'UPDATE_SINGLE_PRODUCT';
export const UPDATE_PRODUCT_START = 'UPDATE_PRODUCT_START';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAIL = 'UPDATE_PRODUCT_FAIL';
export const UPDATE_PRODUCT_LOADING_CLEAR = 'UPDATE_PRODUCT_LOADING_CLEAR';
export const UPDATE_PRODUCT_ERROR_CLEAR = 'UPDATE_PRODUCT_ERROR_CLEAR';
export const UPDATE_PRODUCT_CANCEL = 'UPDATE_PRODUCT_CANCEL';
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';

//#endregion
//#region Request For Bids Constants

// Request For Bids
export const FETCH_REQUEST_FOR_BID_LIST_START = 'FETCH_REQUEST_FOR_BID_LIST_START';
export const FETCH_REQUEST_FOR_BID_LIST_SUCCESS = 'FETCH_REQUEST_FOR_BID_LIST_SUCCESS';
export const FETCH_REQUEST_FOR_BID_LIST_FAIL = 'FETCH_REQUEST_FOR_BID_LIST_FAIL';
export const CLEAR_REQUEST_FOR_BID_LIST = 'CLEAR_REQUEST_FOR_BID_LIST';
export const CLEAR_REQUEST_FOR_BID_LISTS = 'CLEAR_REQUEST_FOR_BID_LISTS';
export const ADD_TO_REQUEST_FOR_BID_LIST = 'ADD_TO_REQUEST_FOR_BID_LIST';
export const UPDATE_IN_REQUEST_FOR_BID_LIST = 'UPDATE_IN_REQUEST_FOR_BID_LIST';
export const REMOVE_FROM_REQUEST_FOR_BID_LIST = 'REMOVE_FROM_REQUEST_FOR_BID_LIST';
export const FETCH_REQUEST_FOR_BIDS_START = 'FETCH_REQUEST_FOR_BIDS_START';
export const FETCH_REQUEST_FOR_BIDS_SUCCESS = 'FETCH_REQUEST_FOR_BIDS_SUCCESS';
export const FETCH_REQUEST_FOR_BIDS_FAIL = 'FETCH_REQUEST_FOR_BIDS_FAIL';
export const CLEAR_REQUEST_FOR_BIDS = 'CLEAR_REQUEST_FOR_BIDS';
export const FETCH_REQUEST_FOR_BID_START = 'FETCH_REQUEST_FOR_BID_START';
export const FETCH_REQUEST_FOR_BID_SUCCESS = 'FETCH_REQUEST_FOR_BID_SUCCESS';
export const FETCH_REQUEST_FOR_BID_FAIL = 'FETCH_REQUEST_FOR_BID_FAIL';
export const CLEAR_REQUEST_FOR_BID = 'CLEAR_REQUEST_FOR_BID';
export const ADD_REQUEST_FOR_BID = 'ADD_REQUEST_FOR_BID';
export const ADD_REQUEST_FOR_BID_START = 'ADD_REQUEST_FOR_BID_START';
export const ADD_REQUEST_FOR_BID_SUCCESS = 'ADD_REQUEST_FOR_BID_SUCCESS';
export const ADD_REQUEST_FOR_BID_FAIL= 'ADD_REQUEST_FOR_BID_FAIL';
export const ADD_REQUEST_FOR_BID_LOADING_CLEAR = 'ADD_REQUEST_FOR_BID_LOADING_CLEAR';
export const ADD_REQUEST_FOR_BID_ERROR_CLEAR = 'ADD_REQUEST_FOR_BID_ERROR_CLEAR';
export const ADD_REQUEST_FOR_BID_CANCEL = 'ADD_REQUEST_FOR_BID_CANCEL';
export const UPDATE_REQUEST_FOR_BID = 'UPDATE_REQUEST_FOR_BID';
export const UPDATE_SINGLE_REQUEST_FOR_BID = 'UPDATE_SINGLE_REQUEST_FOR_BID';
export const UPDATE_REQUEST_FOR_BID_START = 'UPDATE_REQUEST_FOR_BID_START';
export const UPDATE_REQUEST_FOR_BID_SUCCESS = 'UPDATE_REQUEST_FOR_BID_SUCCESS';
export const UPDATE_REQUEST_FOR_BID_FAIL = 'UPDATE_REQUEST_FOR_BID_FAIL';
export const UPDATE_REQUEST_FOR_BID_LOADING_CLEAR = 'UPDATE_REQUEST_FOR_BID_LOADING_CLEAR';
export const UPDATE_REQUEST_FOR_BID_ERROR_CLEAR = 'UPDATE_REQUEST_FOR_BID_ERROR_CLEAR';
export const UPDATE_REQUEST_FOR_BID_CANCEL = 'UPDATE_REQUEST_FOR_BID_CANCEL';
export const REMOVE_REQUEST_FOR_BID = 'REMOVE_REQUEST_FOR_BID';

//#endregion
//#region Scheduled Shifts Constants

// Scheduled Shifts
export const FETCH_SCHEDULED_SHIFT_LIST_START = 'FETCH_SCHEDULED_SHIFT_LIST_START';
export const FETCH_SCHEDULED_SHIFT_LIST_SUCCESS = 'FETCH_SCHEDULED_SHIFT_LIST_SUCCESS';
export const FETCH_SCHEDULED_SHIFT_LIST_FAIL = 'FETCH_SCHEDULED_SHIFT_LIST_FAIL';
export const CLEAR_SCHEDULED_SHIFT_LIST = 'CLEAR_SCHEDULED_SHIFT_LIST';
export const CLEAR_SCHEDULED_SHIFT_LISTS = 'CLEAR_SCHEDULED_SHIFT_LISTS';
export const ADD_TO_SCHEDULED_SHIFT_LIST = 'ADD_TO_SCHEDULED_SHIFT_LIST';
export const UPDATE_IN_SCHEDULED_SHIFT_LIST = 'UPDATE_IN_SCHEDULED_SHIFT_LIST';
export const REMOVE_FROM_SCHEDULED_SHIFT_LIST = 'REMOVE_FROM_SCHEDULED_SHIFT_LIST';
export const FETCH_SCHEDULED_SHIFTS_START = 'FETCH_SCHEDULED_SHIFTS_START';
export const FETCH_SCHEDULED_SHIFTS_SUCCESS = 'FETCH_SCHEDULED_SHIFTS_SUCCESS';
export const FETCH_SCHEDULED_SHIFTS_FAIL = 'FETCH_SCHEDULED_SHIFTS_FAIL';
export const CLEAR_SCHEDULED_SHIFTS = 'CLEAR_SCHEDULED_SHIFTS';
export const FETCH_SCHEDULED_SHIFT_START = 'FETCH_SCHEDULED_SHIFT_START';
export const FETCH_SCHEDULED_SHIFT_SUCCESS = 'FETCH_SCHEDULED_SHIFT_SUCCESS';
export const FETCH_SCHEDULED_SHIFT_FAIL = 'FETCH_SCHEDULED_SHIFT_FAIL';
export const CLEAR_SCHEDULED_SHIFT = 'CLEAR_SCHEDULED_SHIFT';
export const ADD_SCHEDULED_SHIFT = 'ADD_SCHEDULED_SHIFT';
export const ADD_SCHEDULED_SHIFT_START = 'ADD_SCHEDULED_SHIFT_START';
export const ADD_SCHEDULED_SHIFT_SUCCESS = 'ADD_SCHEDULED_SHIFT_SUCCESS';
export const ADD_SCHEDULED_SHIFT_FAIL= 'ADD_SCHEDULED_SHIFT_FAIL';
export const ADD_SCHEDULED_SHIFT_LOADING_CLEAR = 'ADD_SCHEDULED_SHIFT_LOADING_CLEAR';
export const ADD_SCHEDULED_SHIFT_ERROR_CLEAR = 'ADD_SCHEDULED_SHIFT_ERROR_CLEAR';
export const ADD_SCHEDULED_SHIFT_CANCEL = 'ADD_SCHEDULED_SHIFT_CANCEL';
export const UPDATE_SCHEDULED_SHIFT = 'UPDATE_SCHEDULED_SHIFT';
export const UPDATE_SINGLE_SCHEDULED_SHIFT = 'UPDATE_SINGLE_SCHEDULED_SHIFT';
export const UPDATE_SCHEDULED_SHIFT_START = 'UPDATE_SCHEDULED_SHIFT_START';
export const UPDATE_SCHEDULED_SHIFT_SUCCESS = 'UPDATE_SCHEDULED_SHIFT_SUCCESS';
export const UPDATE_SCHEDULED_SHIFT_FAIL = 'UPDATE_SCHEDULED_SHIFT_FAIL';
export const UPDATE_SCHEDULED_SHIFT_LOADING_CLEAR = 'UPDATE_SCHEDULED_SHIFT_LOADING_CLEAR';
export const UPDATE_SCHEDULED_SHIFT_ERROR_CLEAR = 'UPDATE_SCHEDULED_SHIFT_ERROR_CLEAR';
export const UPDATE_SCHEDULED_SHIFT_CANCEL = 'UPDATE_SCHEDULED_SHIFT_CANCEL';
export const REMOVE_SCHEDULED_SHIFT = 'REMOVE_SCHEDULED_SHIFT';

//#endregion
//#region Services Constants

// Services
export const FETCH_SERVICES_START = 'FETCH_SERVICES_START';
export const FETCH_SERVICES_SUCCESS = 'FETCH_SERVICES_SUCCESS';
export const FETCH_SERVICES_FAIL = 'FETCH_SERVICES_FAIL';
export const CLEAR_SERVICES = 'CLEAR_SERVICES';
export const FETCH_SERVICE_START = 'FETCH_SERVICE_START';
export const FETCH_SERVICE_SUCCESS = 'FETCH_SERVICE_SUCCESS';
export const FETCH_SERVICE_FAIL = 'FETCH_SERVICE_FAIL';
export const CLEAR_SERVICE = 'CLEAR_SERVICE';
export const ADD_SERVICE = 'ADD_SERVICE';
export const ADD_SERVICE_START = 'ADD_SERVICE_START';
export const ADD_SERVICE_SUCCESS = 'ADD_SERVICE_SUCCESS';
export const ADD_SERVICE_FAIL= 'ADD_SERVICE_FAIL';
export const ADD_SERVICE_LOADING_CLEAR = 'ADD_SERVICE_LOADING_CLEAR';
export const ADD_SERVICE_ERROR_CLEAR = 'ADD_SERVICE_ERROR_CLEAR';
export const ADD_SERVICE_CANCEL = 'ADD_SERVICE_CANCEL';
export const UPDATE_SERVICE = 'UPDATE_SERVICE';
export const UPDATE_SINGLE_SERVICE = 'UPDATE_SINGLE_SERVICE';
export const UPDATE_SERVICE_START = 'UPDATE_SERVICE_START';
export const UPDATE_SERVICE_SUCCESS = 'UPDATE_SERVICE_SUCCESS';
export const UPDATE_SERVICE_FAIL = 'UPDATE_SERVICE_FAIL';
export const UPDATE_SERVICE_LOADING_CLEAR = 'UPDATE_SERVICE_LOADING_CLEAR';
export const UPDATE_SERVICE_ERROR_CLEAR = 'UPDATE_SERVICE_ERROR_CLEAR';
export const UPDATE_SERVICE_CANCEL = 'UPDATE_SERVICE_CANCEL';
export const REMOVE_SERVICE = 'REMOVE_SERVICE';

//#endregion
//#region Shifts Constants

// Shifts
export const FETCH_SHIFT_LIST_START = 'FETCH_SHIFT_LIST_START';
export const FETCH_SHIFT_LIST_SUCCESS = 'FETCH_SHIFT_LIST_SUCCESS';
export const FETCH_SHIFT_LIST_FAIL = 'FETCH_SHIFT_LIST_FAIL';
export const CLEAR_SHIFT_LIST = 'CLEAR_SHIFT_LIST';
export const CLEAR_SHIFT_LISTS = 'CLEAR_SHIFT_LISTS';
export const ADD_TO_SHIFT_LIST = 'ADD_TO_SHIFT_LIST';
export const UPDATE_IN_SHIFT_LIST = 'UPDATE_IN_SHIFT_LIST';
export const REMOVE_FROM_SHIFT_LIST = 'REMOVE_FROM_SHIFT_LIST';
export const FETCH_SHIFTS_START = 'FETCH_SHIFTS_START';
export const FETCH_SHIFTS_SUCCESS = 'FETCH_SHIFTS_SUCCESS';
export const FETCH_SHIFTS_FAIL = 'FETCH_SHIFTS_FAIL';
export const CLEAR_SHIFTS = 'CLEAR_SHIFTS';
export const FETCH_SHIFT_START = 'FETCH_SHIFT_START';
export const FETCH_SHIFT_SUCCESS = 'FETCH_SHIFT_SUCCESS';
export const FETCH_SHIFT_FAIL = 'FETCH_SHIFT_FAIL';
export const CLEAR_SHIFT = 'CLEAR_SHIFT';
export const ADD_SHIFT = 'ADD_SHIFT';
export const ADD_SHIFT_START = 'ADD_SHIFT_START';
export const ADD_SHIFT_SUCCESS = 'ADD_SHIFT_SUCCESS';
export const ADD_SHIFT_FAIL= 'ADD_SHIFT_FAIL';
export const ADD_SHIFT_LOADING_CLEAR = 'ADD_SHIFT_LOADING_CLEAR';
export const ADD_SHIFT_ERROR_CLEAR = 'ADD_SHIFT_ERROR_CLEAR';
export const ADD_SHIFT_CANCEL = 'ADD_SHIFT_CANCEL';
export const UPDATE_SHIFT = 'UPDATE_SHIFT';
export const UPDATE_SINGLE_SHIFT = 'UPDATE_SINGLE_SHIFT';
export const UPDATE_SHIFT_START = 'UPDATE_SHIFT_START';
export const UPDATE_SHIFT_SUCCESS = 'UPDATE_SHIFT_SUCCESS';
export const UPDATE_SHIFT_FAIL = 'UPDATE_SHIFT_FAIL';
export const UPDATE_SHIFT_LOADING_CLEAR = 'UPDATE_SHIFT_LOADING_CLEAR';
export const UPDATE_SHIFT_ERROR_CLEAR = 'UPDATE_SHIFT_ERROR_CLEAR';
export const UPDATE_SHIFT_CANCEL = 'UPDATE_SHIFT_CANCEL';
export const REMOVE_SHIFT = 'REMOVE_SHIFT';

//#endregion
//#region States Constants

// States
export const FETCH_STATES_START = 'FETCH_STATES_START';
export const FETCH_STATES_SUCCESS = 'FETCH_STATES_SUCCESS';
export const FETCH_STATES_FAIL = 'FETCH_STATES_FAIL';
export const CLEAR_STATES = 'CLEAR_STATES';
export const FETCH_STATE_START = 'FETCH_STATE_START';
export const FETCH_STATE_SUCCESS = 'FETCH_STATE_SUCCESS';
export const FETCH_STATE_FAIL = 'FETCH_STATE_FAIL';
export const CLEAR_STATE = 'CLEAR_STATE';
export const ADD_STATE = 'ADD_STATE';
export const ADD_STATE_START = 'ADD_STATE_START';
export const ADD_STATE_SUCCESS = 'ADD_STATE_SUCCESS';
export const ADD_STATE_FAIL= 'ADD_STATE_FAIL';
export const ADD_STATE_LOADING_CLEAR = 'ADD_STATE_LOADING_CLEAR';
export const ADD_STATE_ERROR_CLEAR = 'ADD_STATE_ERROR_CLEAR';
export const ADD_STATE_CANCEL = 'ADD_STATE_CANCEL';
export const UPDATE_STATE = 'UPDATE_STATE';
export const UPDATE_SINGLE_STATE = 'UPDATE_SINGLE_STATE';
export const UPDATE_STATE_START = 'UPDATE_STATE_START';
export const UPDATE_STATE_SUCCESS = 'UPDATE_STATE_SUCCESS';
export const UPDATE_STATE_FAIL = 'UPDATE_STATE_FAIL';
export const UPDATE_STATE_LOADING_CLEAR = 'UPDATE_STATE_LOADING_CLEAR';
export const UPDATE_STATE_ERROR_CLEAR = 'UPDATE_STATE_ERROR_CLEAR';
export const UPDATE_STATE_CANCEL = 'UPDATE_STATE_CANCEL';
export const REMOVE_STATE = 'REMOVE_STATE';

//#endregion
//#region Trailer Types Constants

// Trailer Types
export const FETCH_TRAILER_TYPES_START = 'FETCH_TRAILER_TYPES_START';
export const FETCH_TRAILER_TYPES_SUCCESS = 'FETCH_TRAILER_TYPES_SUCCESS';
export const FETCH_TRAILER_TYPES_FAIL = 'FETCH_TRAILER_TYPES_FAIL';
export const CLEAR_TRAILER_TYPES = 'CLEAR_TRAILER_TYPES';
export const FETCH_TRAILER_TYPE_START = 'FETCH_TRAILER_TYPE_START';
export const FETCH_TRAILER_TYPE_SUCCESS = 'FETCH_TRAILER_TYPE_SUCCESS';
export const FETCH_TRAILER_TYPE_FAIL = 'FETCH_TRAILER_TYPE_FAIL';
export const CLEAR_TRAILER_TYPE = 'CLEAR_TRAILER_TYPE';
export const ADD_TRAILER_TYPE = 'ADD_TRAILER_TYPE';
export const ADD_TRAILER_TYPE_START = 'ADD_TRAILER_TYPE_START';
export const ADD_TRAILER_TYPE_SUCCESS = 'ADD_TRAILER_TYPE_SUCCESS';
export const ADD_TRAILER_TYPE_FAIL= 'ADD_TRAILER_TYPE_FAIL';
export const ADD_TRAILER_TYPE_LOADING_CLEAR = 'ADD_TRAILER_TYPE_LOADING_CLEAR';
export const ADD_TRAILER_TYPE_ERROR_CLEAR = 'ADD_TRAILER_TYPE_ERROR_CLEAR';
export const ADD_TRAILER_TYPE_CANCEL = 'ADD_TRAILER_TYPE_CANCEL';
export const UPDATE_TRAILER_TYPE = 'UPDATE_TRAILER_TYPE';
export const UPDATE_SINGLE_TRAILER_TYPE = 'UPDATE_SINGLE_TRAILER_TYPE';
export const UPDATE_TRAILER_TYPE_START = 'UPDATE_TRAILER_TYPE_START';
export const UPDATE_TRAILER_TYPE_SUCCESS = 'UPDATE_TRAILER_TYPE_SUCCESS';
export const UPDATE_TRAILER_TYPE_FAIL = 'UPDATE_TRAILER_TYPE_FAIL';
export const UPDATE_TRAILER_TYPE_LOADING_CLEAR = 'UPDATE_TRAILER_TYPE_LOADING_CLEAR';
export const UPDATE_TRAILER_TYPE_ERROR_CLEAR = 'UPDATE_TRAILER_TYPE_ERROR_CLEAR';
export const UPDATE_TRAILER_TYPE_CANCEL = 'UPDATE_TRAILER_TYPE_CANCEL';
export const REMOVE_TRAILER_TYPE = 'REMOVE_TRAILER_TYPE';

//#endregion
//#region Transactions Constants

// Transactions
export const FETCH_TRANSACTION_LIST_START = 'FETCH_TRANSACTION_LIST_START';
export const FETCH_TRANSACTION_LIST_SUCCESS = 'FETCH_TRANSACTION_LIST_SUCCESS';
export const FETCH_TRANSACTION_LIST_FAIL = 'FETCH_TRANSACTION_LIST_FAIL';
export const CLEAR_TRANSACTION_LIST = 'CLEAR_TRANSACTION_LIST';
export const CLEAR_TRANSACTION_LISTS = 'CLEAR_TRANSACTION_LISTS';
export const ADD_TO_TRANSACTION_LIST = 'ADD_TO_TRANSACTION_LIST';
export const UPDATE_IN_TRANSACTION_LIST = 'UPDATE_IN_TRANSACTION_LIST';
export const REMOVE_FROM_TRANSACTION_LIST = 'REMOVE_FROM_TRANSACTION_LIST';
export const FETCH_TRANSACTIONS_START = 'FETCH_TRANSACTIONS_START';
export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_FAIL = 'FETCH_TRANSACTIONS_FAIL';
export const CLEAR_TRANSACTIONS = 'CLEAR_TRANSACTIONS';
export const FETCH_TRANSACTION_START = 'FETCH_TRANSACTION_START';
export const FETCH_TRANSACTION_SUCCESS = 'FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_FAIL = 'FETCH_TRANSACTION_FAIL';
export const CLEAR_TRANSACTION = 'CLEAR_TRANSACTION';
export const ADD_TRANSACTION = 'ADD_TRANSACTION';
export const ADD_TRANSACTION_START = 'ADD_TRANSACTION_START';
export const ADD_TRANSACTION_SUCCESS = 'ADD_TRANSACTION_SUCCESS';
export const ADD_TRANSACTION_FAIL= 'ADD_TRANSACTION_FAIL';
export const ADD_TRANSACTION_LOADING_CLEAR = 'ADD_TRANSACTION_LOADING_CLEAR';
export const ADD_TRANSACTION_ERROR_CLEAR = 'ADD_TRANSACTION_ERROR_CLEAR';
export const ADD_TRANSACTION_CANCEL = 'ADD_TRANSACTION_CANCEL';
export const UPDATE_TRANSACTION = 'UPDATE_TRANSACTION';
export const UPDATE_SINGLE_TRANSACTION = 'UPDATE_SINGLE_TRANSACTION';
export const UPDATE_TRANSACTION_START = 'UPDATE_TRANSACTION_START';
export const UPDATE_TRANSACTION_SUCCESS = 'UPDATE_TRANSACTION_SUCCESS';
export const UPDATE_TRANSACTION_FAIL = 'UPDATE_TRANSACTION_FAIL';
export const UPDATE_TRANSACTION_LOADING_CLEAR = 'UPDATE_TRANSACTION_LOADING_CLEAR';
export const UPDATE_TRANSACTION_ERROR_CLEAR = 'UPDATE_TRANSACTION_ERROR_CLEAR';
export const UPDATE_TRANSACTION_CANCEL = 'UPDATE_TRANSACTION_CANCEL';
export const REMOVE_TRANSACTION = 'REMOVE_TRANSACTION';
export const REMOVE_TRANSACTION_START = 'REMOVE_TRANSACTION_START';
export const REMOVE_TRANSACTION_SUCCESS = 'REMOVE_TRANSACTION_SUCCESS';
export const REMOVE_TRANSACTION_FAIL = 'REMOVE_TRANSACTION_FAIL';
export const REMOVE_TRANSACTION_LOADING_CLEAR = 'REMOVE_TRANSACTION_LOADING_CLEAR';
export const REMOVE_TRANSACTION_ERROR_CLEAR = 'REMOVE_TRANSACTION_ERROR_CLEAR';
export const REMOVE_TRANSACTION_CANCEL = 'REMOVE_TRANSACTION_CANCEL';

//#endregion
//#region Users Constants

// Users
export const FETCH_USER_LIST_START = 'FETCH_USER_LIST_START';
export const FETCH_USER_LIST_SUCCESS = 'FETCH_USER_LIST_SUCCESS';
export const FETCH_USER_LIST_FAIL = 'FETCH_USER_LIST_FAIL';
export const CLEAR_USER_LIST = 'CLEAR_USER_LIST';
export const CLEAR_USER_LISTS = 'CLEAR_USER_LISTS';
export const ADD_TO_USER_LIST = 'ADD_TO_USER_LIST';
export const UPDATE_IN_USER_LIST = 'UPDATE_IN_USER_LIST';
export const REMOVE_FROM_USER_LIST = 'REMOVE_FROM_USER_LIST';
export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';
export const CLEAR_USERS = 'CLEAR_USERS';
export const FETCH_USER_START = 'FETCH_USER_START';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAIL = 'FETCH_USER_FAIL';
export const CLEAR_USER = 'CLEAR_USER';
export const ADD_USER = 'ADD_USER';
export const ADD_USER_START = 'ADD_USER_START';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAIL = 'ADD_USER_FAIL';
export const ADD_USER_LOADING_CLEAR = 'ADD_USER_LOADING_CLEAR';
export const ADD_USER_ERROR_CLEAR = 'ADD_USER_ERROR_CLEAR';
export const ADD_USER_CANCEL = 'ADD_USER_CANCEL';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_SINGLE_USER = 'UPDATE_SINGLE_USER';
export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
export const UPDATE_USER_LOADING_CLEAR = 'UPDATE_USER_LOADING_CLEAR';
export const UPDATE_USER_ERROR_CLEAR = 'UPDATE_USER_ERROR_CLEAR';
export const UPDATE_USER_CANCEL = 'UPDATE_USER_CANCEL';
export const REMOVE_USER = 'REMOVE_USER';

//#endregion
//#region Your Account Users Constants

// Account Users that belong to User Logged In
export const FETCH_YOUR_ACCOUNT_USERS_START = 'FETCH_YOUR_ACCOUNT_USERS_START';
export const FETCH_YOUR_ACCOUNT_USERS_SUCCESS = 'FETCH_YOUR_ACCOUNT_USERS_SUCCESS';
export const FETCH_YOUR_ACCOUNT_USERS_FAIL = 'FETCH_YOUR_ACCOUNT_USERS_FAIL';
export const CLEAR_YOUR_ACCOUNT_USERS = 'CLEAR_YOUR_ACCOUNT_USERS';
export const CLEAR_YOUR_ACCOUNT_USER = 'CLEAR_YOUR_ACCOUNT_USER';

//#endregion
//#region Your Accounts Constants

// Accounts that belong to User Logged In
export const FETCH_YOUR_ACCOUNTS_START = 'FETCH_YOUR_ACCOUNTS_START';
export const FETCH_YOUR_ACCOUNTS_SUCCESS = 'FETCH_YOUR_ACCOUNTS_SUCCESS';
export const FETCH_YOUR_ACCOUNTS_FAIL = 'FETCH_YOUR_ACCOUNTS_FAIL';
export const CLEAR_YOUR_ACCOUNTS = 'CLEAR_YOUR_ACCOUNTS';
export const CLEAR_YOUR_ACCOUNT = 'CLEAR_YOUR_ACCOUNT';

//#endregion