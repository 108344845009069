import * as actionTypes from "../actions/actionTypes";
import {
    addRecordFail,
    addRecordStart,
    addRecordSuccess,
    clearAddRecordError,
    clearUpdateRecordError,
    clearRemoveRecordError,
    updateRecordFail,
    updateRecordStart,
    updateRecordSuccess,
    clearUpdateRecordLoading,
    clearAddRecordLoading,
    clearRemoveRecordLoading,
    removeRecordFail,
    removeRecordStart,
    removeRecordSuccess,
    cancelAddRecord,
    cancelUpdateRecord,
    cancelRemoveRecord,
    addRecordToBottom,
    updateRecord,
    removeRecord,
    fetchRecordStart,
    fetchRecordSuccess,
    fetchRecordFail,
    clearRecord,
    updateSingleRecord,
    fetchListStart,
    fetchListSuccess,
    fetchListFail,
    clearList,
    clearAllLists,
    addToBottomOfList,
    updateInList,
    removeFromList,
} from "../utility";

const initialState = {
    lists: {},
    record: null,
    isRecordLoading: false,
    isRecordAddLoading: null,
    isRecordUpdateLoading: null,
    isRecordRemoveLoading: null,
    recordError: null,
    addRecordError: null,
    updateRecordError: null,
    removeRecordError: null,
    addRecordId: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_TRANSACTION_LIST_START: return fetchListStart(state, action.payload);
        case actionTypes.FETCH_TRANSACTION_LIST_SUCCESS: return fetchListSuccess(state, action.payload);
        case actionTypes.FETCH_TRANSACTION_LIST_FAIL: return fetchListFail(state, action.payload);
        case actionTypes.CLEAR_TRANSACTION_LIST: return clearList(state, action.payload);
        case actionTypes.CLEAR_TRANSACTION_LISTS: return clearAllLists(state);
        case actionTypes.ADD_TO_TRANSACTION_LIST: return addToBottomOfList(state, action.payload);
        case actionTypes.UPDATE_IN_TRANSACTION_LIST: return updateInList(state, action.payload);
        case actionTypes.REMOVE_FROM_TRANSACTION_LIST: return removeFromList(state, action.payload);
        case actionTypes.FETCH_TRANSACTION_START: return fetchRecordStart(state);
        case actionTypes.FETCH_TRANSACTION_SUCCESS: return fetchRecordSuccess(state, action.payload);
        case actionTypes.FETCH_TRANSACTION_FAIL: return fetchRecordFail(state, action.payload);
        case actionTypes.CLEAR_TRANSACTION: return clearRecord(state);
        case actionTypes.ADD_TRANSACTION: return addRecordToBottom(state, action.payload);
        case actionTypes.ADD_TRANSACTION_START: return addRecordStart(state);
        case actionTypes.ADD_TRANSACTION_SUCCESS: return addRecordSuccess(state);
        case actionTypes.ADD_TRANSACTION_FAIL: return addRecordFail(state, action.payload);
        case actionTypes.ADD_TRANSACTION_LOADING_CLEAR: return clearAddRecordLoading(state);
        case actionTypes.ADD_TRANSACTION_ERROR_CLEAR: return clearAddRecordError(state);
        case actionTypes.ADD_TRANSACTION_CANCEL: return cancelAddRecord(state);
        case actionTypes.UPDATE_TRANSACTION: return updateRecord(state, action.payload);
        case actionTypes.UPDATE_SINGLE_TRANSACTION: return updateSingleRecord(state, action.payload);
        case actionTypes.UPDATE_TRANSACTION_START: return updateRecordStart(state);
        case actionTypes.UPDATE_TRANSACTION_SUCCESS: return updateRecordSuccess(state);
        case actionTypes.UPDATE_TRANSACTION_FAIL: return updateRecordFail(state, action.payload);
        case actionTypes.UPDATE_TRANSACTION_LOADING_CLEAR: return clearUpdateRecordLoading(state);
        case actionTypes.UPDATE_TRANSACTION_ERROR_CLEAR: return clearUpdateRecordError(state);
        case actionTypes.UPDATE_TRANSACTION_CANCEL: return cancelUpdateRecord(state);
        case actionTypes.REMOVE_TRANSACTION: return removeRecord(state, action.payload);
        case actionTypes.REMOVE_TRANSACTION_START: return removeRecordStart(state);
        case actionTypes.REMOVE_TRANSACTION_SUCCESS: return removeRecordSuccess(state);
        case actionTypes.REMOVE_TRANSACTION_FAIL: return removeRecordFail(state, action.payload);
        case actionTypes.REMOVE_TRANSACTION_LOADING_CLEAR: return clearRemoveRecordLoading(state);
        case actionTypes.REMOVE_TRANSACTION_ERROR_CLEAR: return clearRemoveRecordError(state);
        case actionTypes.REMOVE_TRANSACTION_CANCEL: return cancelRemoveRecord(state);
        default:
            return state;
    }
};

export default reducer;