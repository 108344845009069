import EntityUtils from '../entityUtils';
import moment from 'moment';
import { isBooleanTrue, isListNotEmpty, isMomentDate, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';

export const getAccountUserDTO = (accountUser, accounts = [], users = [], driverDocuments = []) => {
    // Get Verified By User for the Account User
    if (isStringNotEmpty(accountUser.verifiedBy) && isListNotEmpty(users)) {
        accountUser.verifiedByUser = EntityUtils.getUserProfileInfo(accountUser.verifiedBy, users);
    }

    // Get driver info for the selected Account Users.
    if (isBooleanTrue(accountUser.isDriver) && isObjectNotEmpty(accountUser.driver)) {
        accountUser = EntityUtils.getDriverInfo(accountUser.id, accountUser, driverDocuments);
    }

    // Get Account Info
    if (isListNotEmpty(accounts)) {
        accountUser.account = EntityUtils.getAccountInfo(accountUser.accountId, accounts);
    }

    if (isObjectNotEmpty(accountUser.userProfile) && isStringNotEmpty(accountUser.userProfile.id) && isListNotEmpty(driverDocuments)) {
        let filteredDriverDocuments = driverDocuments.filter(document => document.entityId === accountUser.userProfile.id);
        let { profileImageId } = EntityUtils.getUserFileIds(filteredDriverDocuments);

        accountUser.profileImageId = isStringNotEmpty(profileImageId) ? profileImageId : null;
    }

    return accountUser;
};

export const getDriverDTO = (driver) => {
    if (isObjectNotEmpty(driver)) {
        if (isMomentDate(driver.hazmatLicenseExpiryDate)) {
            driver.hazmatLicenseExpiryDate = moment(driver.hazmatLicenseExpiryDate).utc().toISOString();
        }

        if (isMomentDate(driver.driverLicenseExpiryDate)) {
            driver.driverLicenseExpiryDate = moment(driver.driverLicenseExpiryDate).utc().toISOString();
        }

        if (isMomentDate(driver.commercialLicenseExpiryDate)) {
            driver.commercialLicenseExpiryDate = moment(driver.commercialLicenseExpiryDate).utc().toISOString();
        }

        if (isMomentDate(driver.pneumaticLicenseExpiryDate)) {
            driver.pneumaticLicenseExpiryDate = moment(driver.pneumaticLicenseExpiryDate).utc().toISOString();
        }

        if (isMomentDate(driver.mcCertificateLicenseExpiryDate)) {
            driver.mcCertificateLicenseExpiryDate = moment(driver.mcCertificateLicenseExpiryDate).utc().toISOString();
        }

        if (isMomentDate(driver.explosiveCertifiedLicenseExpiryDate)) {
            driver.explosiveCertifiedLicenseExpiryDate = moment(driver.explosiveCertifiedLicenseExpiryDate).utc().toISOString();
        }

        if (isMomentDate(driver.mshaLicenseExpiryDate)) {
            driver.mshaLicenseExpiryDate = moment(driver.mshaLicenseExpiryDate).utc().toISOString();
        }

        return driver;
    } else {
        return null;
    }
};