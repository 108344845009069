import React, { useMemo } from 'react';
import { Alert, Spin } from "antd";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import FormItemDisplay from '../FormItemDisplay/FormItemDisplay';
import Fieldset from '../FormFieldset/FormFieldset';
import { isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';

const RemoveTransaction = ({ transaction, invoices, cancel, loading = false }) => {
    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.transactions.isRecordRemoveLoading);
    const error = useSelector(state => state.transactions.removeRecordError);

    const onSubmit = () => {
        if (isObjectNotEmpty(transaction)) {
            let invoice = null;
            if (isListNotEmpty(invoices) && isStringNotEmpty(transaction.invoiceId)) {
                invoice = invoices.find(i => i.isDeleted === false && i.id === transaction.invoiceId);
            }

            dispatch(actionCreators.removeTransaction(transaction.id, transaction, transaction.loadId, invoice, true));
        } else {
            onCancel();
        }

    };

    const onCancel = () => {
        dispatch(actionCreators.removeTransactionCancel());
        cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.removeTransactionErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true && error === null) || loading === true}>
                <Fieldset legend="Delete Transaction">
                    <FormItemDisplay {...formItemLayout} label="Delete Transaction">
                        <span>Are you sure you want to Delete this Transaction? This action cannot be undone.</span>
                    </FormItemDisplay>
                </Fieldset>
                {error && <Alert message={`${error}`} type="error" />}
            </Spin>
            <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Delete Transaction" />
        </Form>
    );
};

export default RemoveTransaction;