import Checks from '../entitlementUtils';

export const canCreateLoadMessage = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadCarrier(load, auth) || Checks.isLoadShipper(load, auth) || Checks.isLoadDriver(load, auth);
};

export const canReadLoadMessage = (auth) => {
    return Checks.isAuthenticated(auth);
};

export const canReadLoadMessageList = (auth) => {
    return Checks.isAuthenticated(auth);
};

export const canUpdateLoadMessage = (auth) => {
    return Checks.allowStaffAdmin(auth);
};

export const canDeleteLoadMessage = (auth) => {
    return Checks.allowStaffAdmin(auth);
};