import * as actionTypes from "../actions/actionTypes";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { isBooleanTrue, isObjectNotEmpty } from "../../shared/objectUtils";
import * as actionCreators from "./index";
import ErrorUtils from "../../shared/errorUtils";

//#region Fetch Trailer Types Methods

export const fetchTrailerTypesStart = () => {
    return {
        type: actionTypes.FETCH_TRAILER_TYPES_START
    }
};

export const fetchTrailerTypesSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_TRAILER_TYPES_SUCCESS,
        payload: payload
    }
};

export const fetchTrailerTypesFail = (payload) => {
    return {
        type: actionTypes.FETCH_TRAILER_TYPES_FAIL,
        payload: payload
    }
};

export const clearTrailerTypes = () => {
    return {
        type: actionTypes.CLEAR_TRAILER_TYPES
    }
};

//#endregion
//#region Add Trailer Type Methods

const insertTrailerType = (payload) => {
    return {
        type: actionTypes.ADD_TRAILER_TYPE,
        payload: payload
    }
};

export const addTrailerTypeStart = () => {
    return {
        type: actionTypes.ADD_TRAILER_TYPE_START
    }
};

export const addTrailerTypeSuccess = () => {
    return {
        type: actionTypes.ADD_TRAILER_TYPE_SUCCESS
    }
};

export const addTrailerTypeFail = (payload) => {
    return {
        type: actionTypes.ADD_TRAILER_TYPE_FAIL,
        payload: payload
    }
};

export const addTrailerTypeLoadingClear = () => {
    return {
        type: actionTypes.ADD_TRAILER_TYPE_LOADING_CLEAR
    }
};

export const addTrailerTypeErrorClear = () => {
    return {
        type: actionTypes.ADD_TRAILER_TYPE_ERROR_CLEAR
    }
};

export const addTrailerTypeCancel = () => {
    return {
        type: actionTypes.ADD_TRAILER_TYPE_CANCEL
    }
};

//#endregion
//#region Update Trailer Type Methods

const changeTrailerType = (payload) => {
    return {
        type: actionTypes.UPDATE_TRAILER_TYPE,
        payload: payload
    }
};

const changeSingleTrailerType = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_TRAILER_TYPE,
        payload: payload
    }
};

export const updateTrailerTypeStart = () => {
    return {
        type: actionTypes.UPDATE_TRAILER_TYPE_START
    }
};

export const updateTrailerTypeSuccess = () => {
    return {
        type: actionTypes.UPDATE_TRAILER_TYPE_SUCCESS
    }
};

export const updateTrailerTypeFail = (payload) => {
    return {
        type: actionTypes.UPDATE_TRAILER_TYPE_FAIL,
        payload: payload
    }
};

export const updateTrailerTypeLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_TRAILER_TYPE_LOADING_CLEAR
    }
};

export const updateTrailerTypeErrorClear = () => {
    return {
        type: actionTypes.UPDATE_TRAILER_TYPE_ERROR_CLEAR
    }
};

export const updateTrailerTypeCancel = () => {
    return {
        type: actionTypes.UPDATE_TRAILER_TYPE_CANCEL
    }
};

//#endregion
//#region Remove Trailer Type Methods

const removeTrailerType = (payload) => {
    return {
        type: actionTypes.REMOVE_TRAILER_TYPE,
        payload: payload
    }
};

//#endregion
//#region Trailer Types Methods

export const fetchTrailerTypes = (payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(fetchTrailerTypesStart());

            const state = getState();
            const trailerTypesState = { ...state.trailerTypes };
            let pagination = { ...trailerTypesState.pagination };
            let searchParams = { ...trailerTypesState.searchParams };

            if (isObjectNotEmpty(payload)) {
                searchParams = { ...payload };
            }

            const res = await Data.getTrailerTypes({ ...searchParams }, pagination);
            dispatch(fetchTrailerTypesSuccess({ records: res.data, searchParams: res.searchParams, pagination: res.pagination }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchTrailerTypesFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const addTrailerType = (payload) => {
    return async (dispatch) => {
        try {
            dispatch(addTrailerTypeStart());

            const newTrailerType = await Data.addTrailerType(payload);
            if (isObjectNotEmpty(newTrailerType)) {
                dispatch(insertTrailerType(newTrailerType));

                // refresh the trailerType orchestrator
                dispatch(actionCreators.getTrailerTypes(true));
            }

            dispatch(addTrailerTypeSuccess());
            dispatch(addTrailerTypeLoadingClear());
            dispatch(addTrailerTypeErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addTrailerTypeFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateTrailerType = (id, payload) => {
    return async (dispatch) => {
        try {
            dispatch(updateTrailerTypeStart());

            if (isBooleanTrue(payload.isDeleted)) {
                const removedTrailerType = await Data.removeTrailerType(id);
                if (isObjectNotEmpty(removedTrailerType)) {
                    dispatch(removeTrailerType(removedTrailerType));

                    // refresh the trailerType orchestrator
                    dispatch(actionCreators.getTrailerTypes(true));
                }
            } else {
                const updatedTrailerType = await Data.updateTrailerType(id, payload);
                if (isObjectNotEmpty(updatedTrailerType)) {
                    dispatch(changeTrailerType(updatedTrailerType));
                    dispatch(changeSingleTrailerType(updatedTrailerType));

                    // refresh the trailerType orchestrator
                    dispatch(actionCreators.getTrailerTypes(true));
                }
            }

            dispatch(updateTrailerTypeSuccess());
            dispatch(updateTrailerTypeLoadingClear());
            dispatch(updateTrailerTypeErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateTrailerTypeFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion