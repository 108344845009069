import * as actionTypes from "../actions/actionTypes";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { isObjectNotEmpty } from "../../shared/objectUtils";
import * as actionCreators from "./index";
import ErrorUtils from "../../shared/errorUtils";

//#region Fetch States Methods

export const fetchStatesStart = () => {
    return {
        type: actionTypes.FETCH_STATES_START
    }
};

export const fetchStatesSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_STATES_SUCCESS,
        payload: payload
    }
};

export const fetchStatesFail = (payload) => {
    return {
        type: actionTypes.FETCH_STATES_FAIL,
        payload: payload
    }
};

export const clearStates = () => {
    return {
        type: actionTypes.CLEAR_STATES
    }
};

//#endregion
//#region Add State Methods

const insertState = (payload) => {
    return {
        type: actionTypes.ADD_STATE,
        payload: payload
    }
};

export const addStateStart = () => {
    return {
        type: actionTypes.ADD_STATE_START
    }
};

export const addStateSuccess = () => {
    return {
        type: actionTypes.ADD_STATE_SUCCESS
    }
};

export const addStateFail = (payload) => {
    return {
        type: actionTypes.ADD_STATE_FAIL,
        payload: payload
    }
};

export const addStateLoadingClear = () => {
    return {
        type: actionTypes.ADD_STATE_LOADING_CLEAR
    }
};

export const addStateErrorClear = () => {
    return {
        type: actionTypes.ADD_STATE_ERROR_CLEAR
    }
};

export const addStateCancel = () => {
    return {
        type: actionTypes.ADD_STATE_CANCEL
    }
};

//#endregion
//#region Update State Methods

const changeState = (payload) => {
    return {
        type: actionTypes.UPDATE_STATE,
        payload: payload
    }
};

const changeSingleState = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_STATE,
        payload: payload
    }
};

export const updateStateStart = () => {
    return {
        type: actionTypes.UPDATE_STATE_START
    }
};

export const updateStateSuccess = () => {
    return {
        type: actionTypes.UPDATE_STATE_SUCCESS
    }
};

export const updateStateFail = (payload) => {
    return {
        type: actionTypes.UPDATE_STATE_FAIL,
        payload: payload
    }
};

export const updateStateLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_STATE_LOADING_CLEAR
    }
};

export const updateStateErrorClear = () => {
    return {
        type: actionTypes.UPDATE_STATE_ERROR_CLEAR
    }
};

export const updateStateCancel = () => {
    return {
        type: actionTypes.UPDATE_STATE_CANCEL
    }
};

//#endregion
//#region States Methods

export const fetchStates = (payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(fetchStatesStart());

            const state = getState();
            const statesState = { ...state.states };
            let pagination = { ...statesState.pagination };
            let searchParams = { ...statesState.searchParams };

            if (isObjectNotEmpty(payload)) {
                searchParams = { ...payload };
            }

            const res = await Data.getStates({ ...searchParams }, pagination);
            dispatch(fetchStatesSuccess({ records: res.data, searchParams: res.searchParams, pagination: res.pagination }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchStatesFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const addState = (payload) => {
    return async (dispatch) => {
        try {
            dispatch(addStateStart());

            const newState = await Data.addState(payload);
            if (isObjectNotEmpty(newState)) {
                dispatch(insertState(newState));

                // refresh the states orchestrator
                dispatch(actionCreators.getStates(true));
            }

            dispatch(addStateSuccess());
            dispatch(addStateLoadingClear());
            dispatch(addStateErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addStateFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateState = (id, payload) => {
    return async (dispatch) => {
        try {
            dispatch(updateStateStart());

            // there is no isDeleted field for states so only updating states here
            const updatedState = await Data.updateState(id, payload);
            if (isObjectNotEmpty(updatedState)) {
                dispatch(changeState(updatedState));
                dispatch(changeSingleState(updatedState));

                // refresh the states orchestrator
                dispatch(actionCreators.getStates(true));
            }

            dispatch(updateStateSuccess());
            dispatch(updateStateLoadingClear());
            dispatch(updateStateErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateStateFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion