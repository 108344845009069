import Checks from '../entitlementUtils';

export const canCreateCommodity = (auth) => {
    return Checks.allowStaffAdmin(auth);
};

export const canReadCommodity = (auth) => {
    return Checks.allowStaffAdmin(auth);
};

export const canReadCommodityList = (auth) => {
    return Checks.allowStaffAdmin(auth);
};

export const canUpdateCommodity = (auth) => {
    return Checks.allowStaffAdmin(auth);
};

export const canDeleteCommodity = (auth) => {
    return Checks.allowStaffAdmin(auth);
};