import React, { useMemo, useState, useEffect } from 'react';
import { Alert, Spin, Select, Input } from "antd";
import DatePicker from '../DatePickerAutoAccept/DatePickerAutoAccept';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import MomentDate from '../../shared/dateFormatter';
import FormItem from '../FormItem/FormItem';
import Enums from '../../shared/enums';
import FormItemDisplay from '../FormItemDisplay/FormItemDisplay';
import { isEqual } from 'lodash';
import { isEquivalent, isObjectNotEmpty, isStringNotEmpty, removeEmpty } from '../../shared/objectUtils';
import FormItemPointOfContact from '../FormItemPointOfContact/FormItemPointOfContact';

const { TextArea } = Input;

const EditStopStatusLayover = ({ stop, load, cancel }) => {
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loadEvents.isLoadEventUpdateLoading);
    const error = useSelector(state => state.loadEvents.updateLoadEventError);

    //#endregion
    //#region enums

    const appointmentTypeOptions = Enums.AppointmentTypes.selectListOptions();

    //#endregion
    //#region useStates

    const [showFirstComeFirstServeDetails, setShowFirstComeFirstServeDetails] = useState(false);
    const [showHaveAppointmentDetails, setShowHaveAppointmentDetails] = useState(false);
    const [showNeedAppointmentDetails, setShowNeedAppointmentDetails] = useState(false);

    //#endregion
    //#region onChanges

    const onChangeAppointmentType = (value) => {
        if (isStringNotEmpty(value)) {
            //console.log(`selected ${value}`);
            if (value === "FIRST_COME_FIRST_SERVE") {
                setShowFirstComeFirstServeDetails(true);
                setShowHaveAppointmentDetails(false);
                setShowNeedAppointmentDetails(false);
            } else if (value === "HAVE_APPOINTMENT") {
                setShowFirstComeFirstServeDetails(false);
                setShowHaveAppointmentDetails(true);
                setShowNeedAppointmentDetails(false);
            } else if (value === "NEED_APPOINTMENT") {
                setShowFirstComeFirstServeDetails(false);
                setShowHaveAppointmentDetails(false);
                setShowNeedAppointmentDetails(true);
            } else {
                setShowFirstComeFirstServeDetails(false);
                setShowHaveAppointmentDetails(false);
                setShowNeedAppointmentDetails(false);
            }
        } else {
            setShowFirstComeFirstServeDetails(false);
            setShowHaveAppointmentDetails(false);
            setShowNeedAppointmentDetails(false);
        }
    };

    //#endregion
    //#region onSubmit and onCancel

    const onSubmit = (data) => {
        let payload = {};
        let objectToUpdate = { ...stop };
        for (let [key, value] of Object.entries(data)) {
            let valueToCompare = value;
            if (key.includes('DateTime')) {
                valueToCompare = new MomentDate().asTimeZoneUtcISOString(value, objectToUpdate.timeZone);
            } else {
                valueToCompare = valueToCompare !== undefined && valueToCompare !== null ? valueToCompare : '';
            }

            let oldValue = objectToUpdate[key] !== undefined && objectToUpdate[key] !== null ? objectToUpdate[key] : '';
            if (value && typeof value === 'object' && Object.keys(value).length > 0 && oldValue && typeof oldValue === 'object' && Object.keys(oldValue).length > 0) {
                let cleanedValue = removeEmpty(value);
                if (!isEquivalent(cleanedValue, oldValue)) {
                    //console.log('not equivalent')
                    // console.log(`${key}: new value - ${JSON.stringify(value)}, old value - ${JSON.stringify(oldValue)}`);
                    payload[key] = value;
                }
            } else if (!isEqual(valueToCompare, oldValue)) {
                // console.log(`${key}: new value - ${JSON.stringify(valueToCompare)}, old value - ${JSON.stringify(oldValue)}`);
                payload[key] = value;
            }
        }

        // needed in order for appt times to be updated
        payload.apptType = data.apptType;

        if (isObjectNotEmpty(payload)) {
            //console.log(payload);
            dispatch(actionCreators.updateLoadStopApptDetails(stop.id, load.id, payload, stop.timeZone, load));
        }

        dispatch(actionCreators.sendLoadEvent({
            loadId: load.id,
            stopId: stop.id,
            eventType: 'LOAD_LAYOVER_STARTED'
        }));
    };

    const onCancel = () => {
        dispatch(actionCreators.updateLoadEventComplete());
        cancel();
    };

    //#endregion
    //#region useMemos and useEffects

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLoadEventErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    useEffect(() => {
        if (isObjectNotEmpty(stop)) {
            if (stop.apptType !== undefined) {
                methods.setValue('apptType', stop.apptType);
                if (stop.apptType === "FIRST_COME_FIRST_SERVE") {
                    setShowFirstComeFirstServeDetails(true);
                    setShowHaveAppointmentDetails(false);
                    setShowNeedAppointmentDetails(false);
                } else if (stop.apptType === "HAVE_APPOINTMENT") {
                    setShowFirstComeFirstServeDetails(false);
                    setShowHaveAppointmentDetails(true);
                    setShowNeedAppointmentDetails(false);
                } else if (stop.apptType === "NEED_APPOINTMENT") {
                    setShowFirstComeFirstServeDetails(false);
                    setShowHaveAppointmentDetails(false);
                    setShowNeedAppointmentDetails(true);
                } else {
                    setShowFirstComeFirstServeDetails(false);
                    setShowHaveAppointmentDetails(false);
                    setShowNeedAppointmentDetails(false);
                }
            }
            if (stop.apptPointOfContact !== undefined) {
                if (stop.apptPointOfContact.firstName !== undefined) {
                    methods.setValue('apptPointOfContact.firstName', stop.apptPointOfContact.firstName);
                }
                if (stop.apptPointOfContact.lastName !== undefined) {
                    methods.setValue('apptPointOfContact.lastName', stop.apptPointOfContact.lastName);
                }
                if (stop.apptPointOfContact.email !== undefined) {
                    methods.setValue('apptPointOfContact.email', stop.apptPointOfContact.email);
                }
                if (stop.apptPointOfContact.phone !== undefined) {
                    methods.setValue('apptPointOfContact.phone', stop.apptPointOfContact.phone);
                }
                if (stop.apptPointOfContact.phoneExt !== undefined) {
                    methods.setValue('apptPointOfContact.phoneExt', stop.apptPointOfContact.phoneExt);
                }
            }
            if (stop.apptInstructions !== undefined) {
                methods.setValue('apptInstructions', stop.apptInstructions);
            }
        }
    }, [stop]);

    useEffect(() => {
        if (isObjectNotEmpty(stop)) {
            if (showFirstComeFirstServeDetails === true) {
                if (stop.apptWindowStartDateTime !== undefined) {
                    let apptWindowStartDateTime = new MomentDate().fromUtcToTimeZoneAsTimeZone(stop.apptWindowStartDateTime, stop.stopLocation.timeZone);
                    methods.setValue('apptWindowStartDateTime', apptWindowStartDateTime);
                }
                if (stop.apptWindowEndDateTime !== undefined) {
                    let apptWindowEndDateTime = new MomentDate().fromUtcToTimeZoneAsTimeZone(stop.apptWindowEndDateTime, stop.stopLocation.timeZone);
                    methods.setValue('apptWindowEndDateTime', apptWindowEndDateTime);
                }
            }
        }
    }, [stop, showFirstComeFirstServeDetails]);

    useEffect(() => {
        if (isObjectNotEmpty(stop)) {
            if (stop.apptNumber !== undefined) {
                methods.setValue('apptNumber', stop.apptNumber);
            }
            if (stop.apptDateTime !== undefined) {
                let apptDateTime = new MomentDate().fromUtcToTimeZoneAsTimeZone(stop.apptDateTime, stop.stopLocation.timeZone);
                methods.setValue('apptDateTime', apptDateTime);
            }
            if (stop.apptCallAheadDateTime !== undefined) {
                let apptCallAheadDateTime = new MomentDate().fromUtcToTimeZoneAsTimeZone(stop.apptCallAheadDateTime, stop.stopLocation.timeZone);
                methods.setValue('apptCallAheadDateTime', apptCallAheadDateTime);
            }
        }
    }, [stop, showHaveAppointmentDetails]);

    useEffect(() => {
        if (isObjectNotEmpty(stop)) {
            if (stop.apptCallAheadDateTime !== undefined) {
                let apptCallAheadDateTime = new MomentDate().fromUtcToTimeZoneAsTimeZone(stop.apptCallAheadDateTime, stop.stopLocation.timeZone);
                methods.setValue('apptCallAheadDateTime', apptCallAheadDateTime);
            }
        }
    }, [stop, showNeedAppointmentDetails]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 16 },
        },
    };

    //#endregion

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Fieldset legend="Driver is on Layover for this Stop">
                        <b>Has the Shipper or Driver requested a Layover for this Stop? Change the Status of this Stop to Layover. If you need to modify the appointment details for this stop, please do so below:</b>
                    </Fieldset>
                    <Fieldset legend="Please Update the Appointment Details for this Stop below">
                        <FormItem {...formItemLayout} label="Choose an Appointment Type" required format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select an Appointment Type"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChangeAppointmentType(selected); onChange(selected); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                >
                                    {appointmentTypeOptions}
                                </Select>
                            )}
                            rules={{ required: 'Required Field' }}
                            name="apptType"
                        />
                        {showFirstComeFirstServeDetails === true ? (
                            <>
                                <FormItem {...formItemLayout} label="Appointment Window Start Date & Time" required format="horizontal"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <DatePicker
                                            placeholder="Start Date/Time"
                                            showTime={{ format: 'HH:mm', minuteStep: 15 }}
                                            format="YYYY-MM-DD HH:mm"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={(date, dateString) => { onChange(date); }}
                                            value={value}
                                            name={name}
                                            onSelect={(date) => { onChange(date); }}
                                            ref={ref}
                                        />
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="apptWindowStartDateTime"
                                />
                                <FormItem {...formItemLayout} label="Appointment Window End Date & Time" required format="horizontal"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <DatePicker
                                            placeholder="End Date/Time"
                                            showTime={{ format: 'HH:mm', minuteStep: 15 }}
                                            format="YYYY-MM-DD HH:mm"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={(date, dateString) => { onChange(date); }}
                                            value={value}
                                            name={name}
                                            onSelect={(date) => { onChange(date); }}
                                            ref={ref}
                                        />
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="apptWindowEndDateTime"
                                />
                            </>
                        ) : null}
                        {showHaveAppointmentDetails === true ? (
                            <>
                                <FormItem {...formItemLayout} label="Appointment #" required format="horizontal"
                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Appointment #" ref={ref} />}
                                    rules={{ required: 'Required Field' }}
                                    name="apptNumber"
                                />
                                <FormItem {...formItemLayout} label="Appointment Date and Time" required format="horizontal"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <DatePicker
                                            placeholder="Appointment Date/Time"
                                            showTime={{ format: 'HH:mm', minuteStep: 15 }}
                                            format="YYYY-MM-DD HH:mm"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={(date, dateString) => { onChange(date); }}
                                            value={value}
                                            name={name}
                                            onSelect={(date) => { onChange(date); }}
                                            ref={ref}
                                        />
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="apptDateTime"
                                />
                                <FormItem {...formItemLayout} label="Appointment Call Ahead Date and Time" format="horizontal"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <DatePicker
                                            placeholder="Call Ahead Date/Time"
                                            showTime={{ format: 'HH:mm', minuteStep: 15 }}
                                            format="YYYY-MM-DD HH:mm"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={(date, dateString) => { onChange(date); }}
                                            value={value}
                                            name={name}
                                            onSelect={(date) => { onChange(date); }}
                                            ref={ref}
                                        />
                                    )}
                                    rules={{ required: false }}
                                    name="apptCallAheadDateTime"
                                />
                            </>
                        ) : null}
                        {showNeedAppointmentDetails === true ? (
                            <FormItem {...formItemLayout} label="Appointment Call Ahead Date and Time" format="horizontal"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <DatePicker
                                        placeholder="Call Ahead Date/Time"
                                        showTime={{ format: 'HH:mm', minuteStep: 15 }}
                                        format="YYYY-MM-DD HH:mm"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={(date, dateString) => { onChange(date); }}
                                        value={value}
                                        name={name}
                                        onSelect={(date) => { onChange(date); }}
                                        ref={ref}
                                    />
                                )}
                                rules={{ required: false }}
                                name="apptCallAheadDateTime"
                            />
                        ) : null}
                        <FormItemDisplay {...formItemLayout} label="Appointment Point of Contact">
                            <FormItemPointOfContact
                                format="penta"
                                required={false}
                                name="apptPointOfContact"
                            />
                        </FormItemDisplay>
                        <FormItem {...formItemLayout} label="Additional Appointment Instructions" format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => <TextArea onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} autoSize={{ minRows: 4 }} ref={ref} />}
                            rules={{ required: false }}
                            name="apptInstructions"
                        />
                    </Fieldset>
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Driver is on Layover" />
            </Form>
        </FormProvider>
    );
};

export default EditStopStatusLayover;