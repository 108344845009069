import React, { useMemo } from 'react';
import { Alert, Input, Select, Spin } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Enums from '../../shared/enums';
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import { isObjectNotEmpty } from '../../shared/objectUtils';

const { TextArea } = Input;

const EditContent = ({ cancel, content }) => {
    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.contents.isRecordUpdateLoading);
    const error = useSelector(state => state.contents.updateRecordError);

    const cmsChannelOptions = Enums.CMSChannels.selectListOptions();

    const onSubmit = (data) => {
        let payload = {};
        let objectToUpdate = { ...content };
        for (let [key, value] of Object.entries(data)) {
            let oldValue = objectToUpdate[key] !== undefined && objectToUpdate[key] !== null ? objectToUpdate[key] : '';
            if (value !== oldValue) {
                //console.log(`${key}: new value - ${value}, old value - ${oldValue}`);
                payload[key] = value;
            }
        }

        if (isObjectNotEmpty(payload)) {
            dispatch(actionCreators.updateContent(content.id, payload));
        } else {
            onCancel();
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.updateContentCancel());
        cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateContentErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <FormItem {...formItemLayout} label="Event Type"
                        render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Event Type" disabled={true} ref={ref} />}
                        rules={{ required: false }}
                        name="eventType"
                        defaultValue={content.eventType}
                    />
                    <FormItem {...formItemLayout} label="Message"
                        render={({ onChange, onBlur, value, name, ref }) => <TextArea onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} autoSize={{ minRows: 4 }} ref={ref} />}
                        rules={{ required: false }}
                        name="message"
                        defaultValue={content.message}
                    />
                    <FormItem {...formItemLayout} label="Description"
                        render={({ onChange, onBlur, value, name, ref }) => <TextArea onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} autoSize={{ minRows: 4 }} ref={ref} />}
                        rules={{ required: false }}
                        name="description"
                        defaultValue={content.description}
                    />
                    <FormItem {...formItemLayout} label="Channel" required
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Select
                                placeholder="Please Select a Channel"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                                ref={ref}
                            >
                                {cmsChannelOptions}
                            </Select>
                        )}
                        rules={{ required: 'Required Field' }}
                        defaultValue={content.channel}
                        name="channel"
                    />
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Update Content" />
            </Form>
        </FormProvider>
    );
};

export default EditContent;