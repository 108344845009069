import React, { useEffect, useRef, useState } from 'react';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import * as TrimbleMapsControl from '@trimblemaps/trimblemaps-singlesearch';
import { isNotNullOrUndefined, isStringNotEmpty } from '../../shared/objectUtils';

TrimbleMaps.APIKey = process.env.REACT_APP_TMS_API_KEY;
window.TrimbleMaps = TrimbleMaps;

const LocationMap = ({ containerName = null, height = "300px" }) => {
    const lon = -77.0365, lat = 38.8977, zoom = 8;
    containerName = isStringNotEmpty(containerName) ? containerName : `map`;

    // create map
    const mapRef = useRef(null);

    const [isMapReady, setIsMapReady] = useState(false);

    const searchSuccess = (props) => {
        console.log(props);

        return props;
    };

    useEffect(() => {
        if (isStringNotEmpty(containerName)) {
            mapRef.current = window.map = new TrimbleMaps.Map({
                container: containerName,
                center: new TrimbleMaps.LngLat(lon, lat),
                zoom: zoom,
                style: TrimbleMaps.Common.Style.TRANSPORTATION,
                region: TrimbleMaps.Common.Region.NA
            });
            let singleSearchControl = new TrimbleMapsControl.SingleSearch({
                placeholderText: "Search here",
                doneTypingInterval: 700,
                hideResults: true,
                minSearchTextLength: 3,
                locationRelevance: "mapCenter",
                searchOption: {
                    region: TrimbleMaps.Common.Region.NA,
                    maxResults: 30,
                    countries: 'USA',
                    countryType: 'GENC3',
                },
                markerStyle: {
                    graphicHeight: 26,
                    graphicWidth: 26,
                    graphicXOffset: -13,
                    graphicYOffset: -13
                },
                markerConfig: {
                    exclusive: true,
                    centerOnMap: true,
                    zoomLevel: 12
                },
                popupConfig: {
                    offset: 15,
                    show: true,
                    className: "single-search",
                    closeBox: true
                }
            });

            mapRef.current.addControl(singleSearchControl, 'top-left');

            mapRef.current.on('load', function () {
                if (isNotNullOrUndefined(mapRef.current)) {
                    setIsMapReady(true);

                    mapRef.current.resize();
                }
            });

            let mapDiv = document.getElementById(containerName);
            window.onresize = () => {
                //console.log('window resized');

                if (isNotNullOrUndefined(mapRef.current)) {
                    mapRef.current.resize();
                }
            };
            mapDiv.onresize = () => {
                //console.log('map container resized');

                if (isNotNullOrUndefined(mapRef.current)) {
                    mapRef.current.resize();
                }
            };

            // mapRef.current.on('click', function (e) {
            //     // Extract the lngLat object from the event
            //     const lngLat = e.lngLat;

            //     updateActualPath(lngLat.lat, lngLat.lng);
            // });
        } else {
            if (isNotNullOrUndefined(mapRef.current)) {
                // console.log('map is shutting down');
                mapRef.current.remove();
            }
        }

        return () => {
            if (isNotNullOrUndefined(mapRef.current)) {
                // console.log('map is shutting down');
                mapRef.current.remove();
            }
        };
    }, [containerName]);

    return (
        <div id={containerName} style={{ width: "100%", height: height }}></div>
    );
};

export default LocationMap;