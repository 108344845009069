import { useEffect, useRef } from 'react';
import { createCustomEqual } from "fast-equals";

const deepCompareEquals = createCustomEqual(
    (deepEqual) => (a, b) => {
        // use fast-equals for other objects
        return deepEqual(a, b);
    }
);

function useDeepCompareMemoize(value) {
    const ref = useRef();

    if (!deepCompareEquals(value, ref.current)) {
        ref.current = value;
    }

    return ref.current;
}

export function useDeepCompareEffect(callback, dependencies) {
    useEffect(callback, dependencies.map(useDeepCompareMemoize));
};