import Checks from '../entitlementUtils';

export const canCreateUser = (auth) => {
    return Checks.allowStaff(auth);
};

export const canReadUser = (auth, user) => {
    return Checks.allowStaff(auth) || Checks.isUserSelf(auth, user);
};

export const canReadUserList = (auth) => {
    return Checks.allowStaff(auth);
};

export const canUpdateUser = (auth, user) => {
    return Checks.allowStaff(auth) || Checks.isUserSelf(auth, user);
};

export const canDeleteUser = (auth) => {
    return Checks.allowStaffAdmin(auth);
};