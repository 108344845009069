import React from 'react';
import { Button, Row, Col } from "antd";
import Fieldset from '../FormFieldset/FormFieldset';
import Header from '../Header/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import DataRow from '../DataRow/DataRow';
import DataPhoneNumberRow from '../../components/DataPhoneNumberRow/DataPhoneNumberRow';
import DataAddressRow from '../../components/DataAddressRow/DataAddressRow';
import DataContactInfoRow from '../../components/DataContactInfoRow/DataContactInfoRow';
import DataSMSBasicRow from '../../components/DataSMSBasicRow/DataSMSBasicRow';
import { isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';

const NewAccountReview = ({ companyData, previousStepAction, nextStepAction }) => {

    const goToPreviousStep = () => {
        previousStepAction('company');
    };

    return (
        <div className="account-creation-step-container">
            <div className="account-creation-step">
                <Header
                    titleStyle={{ fontSize: 24 }}
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    title={"Review"}
                    title2={"Review the account details below."}
                    icon={<FontAwesomeIcon className="anticon" icon={faUsers} />}
                    iconStyle={{ background: 'transparent' }}
                />

                {isObjectNotEmpty(companyData) ? (
                    <Fieldset legend="Company Details">
                        {isStringNotEmpty(companyData.name) ? <DataRow title="Company Name" value={companyData.name} dataType="String" /> : null}
                        {isStringNotEmpty(companyData.ein) ? <DataRow title="EIN" value={companyData.ein} dataType="String" /> : null}
                        {isStringNotEmpty(companyData.dotNum) ? <DataRow title="DOT #" value={companyData.dotNum} dataType="String" /> : null}
                        {isStringNotEmpty(companyData.mcNum) ? <DataRow title="MC #" value={companyData.mcNum} dataType="String" /> : null}
                        {isStringNotEmpty(companyData.dbNum) ? <DataRow title="D&B #" value={companyData.dbNum} dataType="String" /> : null}
                        {isStringNotEmpty(companyData.blueBookNum) ? <DataRow title="Produce Blue Book #" value={companyData.blueBookNum} dataType="String" /> : null}
                        {isStringNotEmpty(companyData.email) ? <DataRow title="Company Email Address" value={companyData.email} dataType="String" /> : null}
                        {isStringNotEmpty(companyData.phone) ? <DataPhoneNumberRow title="Company Day Time Phone #" phone={companyData.phone} phoneExt={companyData.phoneExt} /> : null}
                        {isStringNotEmpty(companyData.afterHoursPhone) ? <DataPhoneNumberRow title="Company After Hours Phone #" phone={companyData.afterHoursPhone} phoneExt={companyData.afterHoursPhoneExt} /> : null}
                        {isStringNotEmpty(companyData.fax) ? <DataRow title="Company Fax Number" value={companyData.fax} dataType="PhoneNumber" /> : null}
                        {isObjectNotEmpty(companyData.pointOfContact) ? <DataContactInfoRow valueStyle={{ paddingLeft: 16 }} title="Point of Contact" contact={companyData.pointOfContact} /> : null}
                        {isObjectNotEmpty(companyData.companyAddress) ? <DataAddressRow valueStyle={{ paddingLeft: 16 }} title="Company Address" address={companyData.companyAddress} /> : null}
                        {isObjectNotEmpty(companyData.accountsPayable) ? <DataContactInfoRow valueStyle={{ paddingLeft: 16 }} title="A/P Contact" contact={companyData.accountsPayable} /> : null}
                        {isObjectNotEmpty(companyData.accountsPayableAddress) ? <DataAddressRow valueStyle={{ paddingLeft: 16 }} title="A/P Address" address={companyData.accountsPayableAddress} /> : null}
                        {isObjectNotEmpty(companyData.accountsReceivable) ? <DataContactInfoRow valueStyle={{ paddingLeft: 16 }} title="A/R Contact" contact={companyData.accountsReceivable} /> : null}
                        {isObjectNotEmpty(companyData.accountsReceivableAddress) ? <DataAddressRow valueStyle={{ paddingLeft: 16 }} title="A/R Address" address={companyData.accountsReceivableAddress} /> : null}
                        {isStringNotEmpty(companyData.preferredPaymentMethod) ? <DataRow title="Preferred Payment Method" value={companyData.preferredPaymentMethod} dataType="PreferredPaymentMethod" /> : null}
                        {isObjectNotEmpty(companyData.smsBasic) ? <DataSMSBasicRow valueStyle={{ paddingLeft: 16 }} title="SMS Basic Scores" smsBasic={companyData.smsBasic} /> : null}
                    </Fieldset>
                ) : null}

                {isObjectNotEmpty(companyData) && isObjectNotEmpty(companyData.assetNeeds) && companyData.entityTypes.includes("SHIPPER") ? (
                    <Fieldset legend="Equipment Needs">
                        <Row gutter={[16, 16]}>
                            <Col lg={{ span: 24 }} md={{ span: 24 }}>
                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Reefers</h3>
                                <DataRow title="Do you need 48' Reefers?" value={companyData.assetNeeds.has48Reefer} dataType="Boolean" />
                                <DataRow title="Do you need 53' Reefers?" value={companyData.assetNeeds.has53Reefer} dataType="Boolean" />
                                <DataRow title="Do you need to ship frozen?" value={companyData.assetNeeds.hasReeferFrozen} dataType="Boolean" />
                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Dry Vans</h3>
                                <DataRow title="Do you need 48' Dry Vans?" value={companyData.assetNeeds.has48DryVan} dataType="Boolean" />
                                <DataRow title="Do you need 53' Dry Vans?" value={companyData.assetNeeds.has53DryVan} dataType="Boolean" />
                                <DataRow title="Do you need lift gates for the dry vans?" value={companyData.assetNeeds.hasDryVanLiftGates} dataType="Boolean" />
                                <DataRow title="Do you need roller beds for the dry vans?" value={companyData.assetNeeds.hasDryVanRollerBed} dataType="Boolean" />
                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Flatbeds</h3>
                                <DataRow title="Do you need 48' Flatbeds?" value={companyData.assetNeeds.has48Flatbed} dataType="Boolean" />
                                <DataRow title="Do you need 53' Flatbeds?" value={companyData.assetNeeds.has53Flatbed} dataType="Boolean" />
                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Over-Sized Loads</h3>
                                <DataRow title="Do you need to ship over dimensional/overweight loads?" value={companyData.assetNeeds.hasOverSized} dataType="Boolean" />
                            </Col>
                            {/* <Col lg={{ span: 12 }} md={{ span: 24 }}>
                                        <h3 style={{ marginTop: 12, marginBottom: 12 }}>Low Boys</h3>
                                        <DataRow title="Do you need Low Boys?" value={companyData.assetNeeds.hasLowBoy} dataType="Boolean" />
                                        <h3 style={{ marginTop: 12, marginBottom: 12 }}>RGNs</h3>
                                        <DataRow title="Do you need RGNs?" value={companyData.assetNeeds.hasRGN} dataType="Boolean" />
                                        <h3 style={{ marginTop: 12, marginBottom: 12 }}>Step Decks</h3>
                                        <DataRow title="Do you need Step Decks with Ramps?" value={companyData.assetNeeds.hasStepDeckRamps} dataType="Boolean" />
                                        <DataRow title="Do you need Step Decks without Ramps?" value={companyData.assetNeeds.hasStepDeckNoRamps} dataType="Boolean" />
                                        <h3 style={{ marginTop: 12, marginBottom: 12 }}>Tankers</h3>
                                        <DataRow title="Do you need Single Compartment Tankers?" value={companyData.assetNeeds.hasTankersSingleCompartment} dataType="Boolean" />
                                        <DataRow title="Do you need Multi Compartment Tankers?" value={companyData.assetNeeds.hasTankersMultiCompartment} dataType="Boolean" />
                                        <DataRow title="Do you need Fiber Glass Tankers?" value={companyData.assetNeeds.hasTankersFiberGlass} dataType="Boolean" />
                                        <h3 style={{ marginTop: 12, marginBottom: 12 }}>Straight Trucks</h3>
                                        <DataRow title="Do you need Cubed Straight Trucks?" value={companyData.assetNeeds.hasCubedStraightTruck} dataType="Boolean" />
                                        <DataRow title="Do you need 26' Straight Trucks?" value={companyData.assetNeeds.has26StraightTruck} dataType="Boolean" />
                                        <DataRow title="Do you need lift gates for the straight trucks?" value={companyData.assetNeeds.hasStraightTruckLiftGates} dataType="Boolean" />
                                        <h3 style={{ marginTop: 12, marginBottom: 12 }}>Sprinter Vans</h3>
                                        <DataRow title="Do you need Sprinter Vans?" value={companyData.assetNeeds.hasSprinterVan} dataType="Boolean" />
                                    </Col> */}
                        </Row>
                    </Fieldset>
                ) : null}

                {isObjectNotEmpty(companyData) && isObjectNotEmpty(companyData.assetNeeds) && companyData.entityTypes.includes("CARRIER") ? (
                    <>
                        <Fieldset legend="Asset Details">
                            <Row gutter={[16, 16]}>
                                <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                    <DataRow title="# of Power Units" value={companyData.assetNeeds.numPowerUnits} dataType="Integer" />
                                </Col>
                                <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                    <DataRow title="# of Trailers" value={companyData.assetNeeds.numTrailers} dataType="Integer" />
                                </Col>
                                <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                    <DataRow title="# of Drivers" value={companyData.assetNeeds.numDrivers} dataType="Integer" />
                                </Col>
                                <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                    <DataRow title="# of HazMat Drivers" value={companyData.assetNeeds.numHazMatDrivers} dataType="Integer" />
                                </Col>
                                <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                    <DataRow title="# of Teams" value={companyData.assetNeeds.numTeams} dataType="Integer" />
                                </Col>
                            </Row>
                        </Fieldset>
                        <Fieldset legend="Trailer Types">
                            <Row gutter={[16, 16]}>
                                <Col lg={{ span: 24 }} md={{ span: 24 }}>
                                    <h3 style={{ marginTop: 12, marginBottom: 12 }}>Reefers</h3>
                                    <DataRow title="Do you have 48' Reefers?" value={companyData.assetNeeds.has48Reefer} dataType="Boolean" />
                                    <DataRow title="If so, how many?" value={companyData.assetNeeds.num48Reefer} dataType="Integer" />
                                    <DataRow title="Do you have 53' Reefers?" value={companyData.assetNeeds.has53Reefer} dataType="Boolean" />
                                    <DataRow title="If so, how many?" value={companyData.assetNeeds.num53Reefer} dataType="Integer" />
                                    <DataRow title="Do you haul frozen?" value={companyData.assetNeeds.hasReeferFrozen} dataType="Boolean" />
                                    <h3 style={{ marginTop: 12, marginBottom: 12 }}>Dry Vans</h3>
                                    <DataRow title="Do you have 48' Dry Vans?" value={companyData.assetNeeds.has48DryVan} dataType="Boolean" />
                                    <DataRow title="If so, how many?" value={companyData.assetNeeds.num48DryVan} dataType="Integer" />
                                    <DataRow title="Do you have 53' Dry Vans?" value={companyData.assetNeeds.has53DryVan} dataType="Boolean" />
                                    <DataRow title="If so, how many?" value={companyData.assetNeeds.num53DryVan} dataType="Integer" />
                                    <DataRow title="Do you have lift gates for the dry vans?" value={companyData.assetNeeds.hasDryVanLiftGates} dataType="Boolean" />
                                    <DataRow title="Do you have roller beds for the dry vans?" value={companyData.assetNeeds.hasDryVanRollerBed} dataType="Boolean" />
                                    <h3 style={{ marginTop: 12, marginBottom: 12 }}>Flatbeds</h3>
                                    <DataRow title="Do you have 48' Flatbeds?" value={companyData.assetNeeds.has48Flatbed} dataType="Boolean" />
                                    <DataRow title="If so, how many?" value={companyData.assetNeeds.num48Flatbed} dataType="Integer" />
                                    <DataRow title="Do you have 53' Flatbeds?" value={companyData.assetNeeds.has53Flatbed} dataType="Boolean" />
                                    <DataRow title="If so, how many?" value={companyData.assetNeeds.num53Flatbed} dataType="Integer" />
                                    <h3 style={{ marginTop: 12, marginBottom: 12 }}>Over-Sized Loads</h3>
                                    <DataRow title="Do you haul over dimensional/overweight loads?" value={companyData.assetNeeds.hasOverSized} dataType="Boolean" />
                                </Col>
                                {/* <Col lg={{ span: 24 }} md={{ span: 24 }}>
                                    <h3 style={{ marginTop: 12, marginBottom: 12 }}>Low Boys</h3>
                                    <DataRow title="Do you have Low Boys?" value={companyData.assetNeeds.hasLowBoy} dataType="Boolean" />
                                    <DataRow title="If so, how many?" value={companyData.assetNeeds.numLowBoy} dataType="Integer" />
                                    <h3 style={{ marginTop: 12, marginBottom: 12 }}>RGNs</h3>
                                    <DataRow title="Do you have RGNs?" value={companyData.assetNeeds.hasRGN} dataType="Boolean" />
                                    <DataRow title="If so, how many?" value={companyData.assetNeeds.numRGN} dataType="Integer" />
                                    <h3 style={{ marginTop: 12, marginBottom: 12 }}>Step Decks</h3>
                                    <DataRow title="Do you have Step Decks with Ramps?" value={companyData.assetNeeds.hasStepDeckRamps} dataType="Boolean" />
                                    <DataRow title="If so, how many?" value={companyData.assetNeeds.numStepDeckRamps} dataType="Integer" />
                                    <DataRow title="Do you have Step Decks without Ramps?" value={companyData.assetNeeds.hasStepDeckNoRamps} dataType="Boolean" />
                                    <DataRow title="If so, how many?" value={companyData.assetNeeds.numStepDeckNoRamps} dataType="Integer" />
                                    <h3 style={{ marginTop: 12, marginBottom: 12 }}>Tankers</h3>
                                    <DataRow title="Do you have Single Compartment Tankers?" value={companyData.assetNeeds.hasTankersSingleCompartment} dataType="Boolean" />
                                    <DataRow title="If so, how many?" value={companyData.assetNeeds.numTankersSingleCompartment} dataType="Integer" />
                                    <DataRow title="Do you have Multi Compartment Tankers?" value={companyData.assetNeeds.hasTankersMultiCompartment} dataType="Boolean" />
                                    <DataRow title="If so, how many?" value={companyData.assetNeeds.numTankersMultiCompartment} dataType="Integer" />
                                    <DataRow title="Do you have Fiber Glass Tankers?" value={companyData.assetNeeds.hasTankersFiberGlass} dataType="Boolean" />
                                    <DataRow title="If so, how many?" value={companyData.assetNeeds.numTankersFiberGlass} dataType="Integer" />
                                    <h3 style={{ marginTop: 12, marginBottom: 12 }}>Straight Trucks</h3>
                                    <DataRow title="Do you have Cubed Straight Trucks?" value={companyData.assetNeeds.hasCubedStraightTruck} dataType="Boolean" />
                                    <DataRow title="If so, how many?" value={companyData.assetNeeds.numCubedStraightTruck} dataType="Integer" />
                                    <DataRow title="Do you have 26' Straight Trucks?" value={companyData.assetNeeds.has26StraightTruck} dataType="Boolean" />
                                    <DataRow title="If so, how many?" value={companyData.assetNeeds.num26StraightTruck} dataType="Integer" />
                                    <DataRow title="Do you have lift gates for the straight trucks?" value={companyData.assetNeeds.hasStraightTruckLiftGates} dataType="Boolean" />
                                    <h3 style={{ marginTop: 12, marginBottom: 12 }}>Sprinter Vans</h3>
                                    <DataRow title="Do you have Sprinter Vans?" value={companyData.assetNeeds.hasSprinterVan} dataType="Boolean" />
                                    <DataRow title="If so, how many?" value={companyData.assetNeeds.numSprinterVan} dataType="Integer" />
                                </Col> */}
                            </Row>
                        </Fieldset>
                    </>
                ) : null}

                {isObjectNotEmpty(companyData) && isObjectNotEmpty(companyData.accountUser) ? (
                    <Fieldset legend="Default Account Admin Details">
                        {isStringNotEmpty(companyData.accountUser.firstName) ? <DataRow title="First Name" value={companyData.accountUser.firstName} dataType="String" /> : null}
                        {isStringNotEmpty(companyData.accountUser.lastName) ? <DataRow title="Last Name" value={companyData.accountUser.lastName} dataType="String" /> : null}
                        {isStringNotEmpty(companyData.accountUser.email) ? <DataRow title="Email Address" value={companyData.accountUser.email} dataType="String" /> : null}
                        {isStringNotEmpty(companyData.accountUser.phone) ? <DataPhoneNumberRow title="Phone #" phone={companyData.accountUser.phone} /> : null}
                    </Fieldset>
                ) : null}
            </div>
            <Row gutter={[12, 12]} className="account-creation-step-buttons">
                <Col xs={{ span: 24, order: 2 }} md={{ span: 12, order: 1 }}>
                    <Button type="default" block onClick={() => { goToPreviousStep(); }}>Previous Step</Button>
                </Col>
                <Col xs={{ span: 24, order: 1 }} md={{ span: 12, order: 2 }}>
                    <Button type="primary" block onClick={() => { nextStepAction(); }}>Create Account</Button>
                </Col>
            </Row>
        </div>
    );
};

export default NewAccountReview;