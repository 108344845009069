import Checks from '../entitlementUtils';

export const canCreateTransaction = (auth) => {
    return Checks.allowStaff(auth);
};

export const canReadTransaction = (auth) => {
    return Checks.allowStaff(auth);
};

export const canReadTransactionList = (auth) => {
    return Checks.allowStaff(auth);
};

export const canUpdateTransaction = (auth) => {
    return Checks.allowStaff(auth);
};

export const canDeleteTransaction = (auth) => {
    return Checks.allowStaffAdmin(auth);
};