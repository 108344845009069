import axiosAuthenticated from "./axiosAuthenticated";
import logger from "../shared/logger";

const emailsPath = '/email';
const emailTemplatesPath = '/emailTemplates';

export const sendEmail = async (subject, body, emailAddress) => {
    try {
        const newEmail = {
            body: body,
            subject: subject,
            email: emailAddress
        };

        const resp = await axiosAuthenticated.post(emailsPath, newEmail);

        // console.log(resp.data);
        return true;
    } catch (err) {
        logger.logErrorEvent('email_client_error', err, err.message, true);
        
        return false;
    }
};

export const sendTemplatedEmail = async (templateName, payload) => {
    try {
        const newEmailPayload = {
            ...payload,
            templateName: templateName
        };

        const resp = await axiosAuthenticated.post(emailTemplatesPath, newEmailPayload);

        // console.log(resp.data);
        return true;
    } catch (err) {
        logger.logErrorEvent('email_client_error', err, err.message, true);
        
        return false;
    }
};