import Checks from '../entitlementUtils';

export const canCreateLoadNotification = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadCarrier(load, auth) || Checks.isLoadShipper(load, auth) || Checks.isLoadDriver(load, auth);
};

export const canReadLoadNotification = (auth) => {
    return Checks.isAuthenticated(auth);
};

export const canReadLoadNotificationList = (auth) => {
    return Checks.isAuthenticated(auth);
};

export const canUpdateLoadNotification = (auth) => {
    return Checks.allowStaffAdmin(auth);
};

export const canDeleteLoadNotification = (auth) => {
    return Checks.allowStaffAdmin(auth);
};