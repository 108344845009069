import * as actionTypes from "../actions/actionTypes";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { isBooleanTrue, isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import * as actionCreators from "./index";
import ErrorUtils from "../../shared/errorUtils";

const employeeRoles = ['ADMIN', 'OPERATIONS_ADMIN', 'SAFETY_ADMIN', 'FINANCIAL_ADMIN', 'DISPATCHER', 'LOAD_PLANNER', 'CLERK', 'TRANSPORTATION_MANAGER'];

//#region Fetch Account User List Methods

export const fetchAccountUserListStart = (listName) => {
    return {
        type: actionTypes.FETCH_ACCOUNT_USER_LIST_START,
        payload: { listName: listName }
    }
};

export const fetchAccountUserListSuccess = (listName, payload) => {
    return {
        type: actionTypes.FETCH_ACCOUNT_USER_LIST_SUCCESS,
        payload: { listName: listName, ...payload }
    }
};

export const fetchAccountUserListFail = (listName, payload) => {
    return {
        type: actionTypes.FETCH_ACCOUNT_USER_LIST_FAIL,
        payload: { listName: listName, ...payload }
    }
};

export const clearAccountUserList = (listName) => {
    return {
        type: actionTypes.CLEAR_ACCOUNT_USER_LIST,
        payload: { listName: listName }
    }
};

export const clearAccountUserLists = () => {
    return {
        type: actionTypes.CLEAR_ACCOUNT_USER_LISTS
    }
};

export const addAccountUserToAccountUserList = (listName, payload) => {
    return {
        type: actionTypes.ADD_TO_ACCOUNT_USER_LIST,
        payload: { listName: listName, newRecord: payload }
    }
};

export const updateAccountUserInAccountUserList = (listName, payload) => {
    return {
        type: actionTypes.UPDATE_IN_ACCOUNT_USER_LIST,
        payload: { listName: listName, updatedRecord: payload }
    }
};

export const removeAccountUserFromAccountUserList = (listName, payload) => {
    return {
        type: actionTypes.REMOVE_FROM_ACCOUNT_USER_LIST,
        payload: { listName: listName, recordToRemove: payload }
    }
};

//#endregion
//#region Fetch Account User Methods

export const fetchAccountUserStart = () => {
    return {
        type: actionTypes.FETCH_ACCOUNT_USER_START
    }
};

export const fetchAccountUserSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_ACCOUNT_USER_SUCCESS,
        payload: payload
    }
};

export const fetchAccountUserFail = (payload) => {
    return {
        type: actionTypes.FETCH_ACCOUNT_USER_FAIL,
        payload: payload
    }
};

export const clearAccountUser = () => {
    return {
        type: actionTypes.CLEAR_ACCOUNT_USER
    }
};

//#endregion
//#region Add Account User Methods

export const addAccountUserStart = () => {
    return {
        type: actionTypes.ADD_ACCOUNT_USER_START
    }
};

export const addAccountUserSuccess = () => {
    return {
        type: actionTypes.ADD_ACCOUNT_USER_SUCCESS
    }
};

export const addAccountUserFail = (payload) => {
    return {
        type: actionTypes.ADD_ACCOUNT_USER_FAIL,
        payload: payload
    }
};

export const addAccountUserLoadingClear = () => {
    return {
        type: actionTypes.ADD_ACCOUNT_USER_LOADING_CLEAR
    }
};

export const addAccountUserErrorClear = () => {
    return {
        type: actionTypes.ADD_ACCOUNT_USER_ERROR_CLEAR
    }
};

export const addAccountUserCancel = () => {
    return {
        type: actionTypes.ADD_ACCOUNT_USER_CANCEL
    }
};

//#endregion
//#region Update Account User Methods

const changeSingleAccountUser = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_ACCOUNT_USER,
        payload: payload
    }
};

export const updateAccountUserStart = () => {
    return {
        type: actionTypes.UPDATE_ACCOUNT_USER_START
    }
};

export const updateAccountUserSuccess = () => {
    return {
        type: actionTypes.UPDATE_ACCOUNT_USER_SUCCESS
    }
};

export const updateAccountUserFail = (payload) => {
    return {
        type: actionTypes.UPDATE_ACCOUNT_USER_FAIL,
        payload: payload
    }
};

export const updateAccountUserLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_ACCOUNT_USER_LOADING_CLEAR
    }
};

export const updateAccountUserErrorClear = () => {
    return {
        type: actionTypes.UPDATE_ACCOUNT_USER_ERROR_CLEAR
    }
};

export const updateAccountUserCancel = () => {
    return {
        type: actionTypes.UPDATE_ACCOUNT_USER_CANCEL
    }
};

//#endregion
//#region Account Users Methods

export const fetchAccountUserList = (listName, payload) => {
    return async (dispatch, getState) => {
        if (isStringNotEmpty(listName)) {
            try {
                await dispatch(actionCreators.getAccounts());
                const state = getState();
                const orchestratorState = { ...state.orchestrator };
                const accounts = [...orchestratorState.accounts];
                const accountUsersState = { ...state.accountUsers };
                const existingLists = { ...accountUsersState.lists };

                let pagination = {};
                let searchParams = {};
                if (isObjectNotEmpty(existingLists[listName])) {
                    pagination = { ...existingLists[listName].pagination };
                    searchParams = { ...existingLists[listName].searchParams };
                }

                if (isObjectNotEmpty(payload)) {
                    searchParams = { ...payload };
                }

                dispatch(fetchAccountUserListStart(listName));

                const res = await Data.getAccountUsers({ ...searchParams }, pagination, accounts);
                dispatch(fetchAccountUserListSuccess(listName, { records: res.data, params: { searchParams: res.searchParams, pagination: res.pagination } }));
            } catch (error) {
                logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
                dispatch(fetchAccountUserListFail(listName, { error: ErrorUtils.getErrorMessage(error) }));
            }
        }
    }
};

export const fetchAccountUser = (id) => {
    return async (dispatch, getState) => {
        try {
            dispatch(fetchAccountUserStart());

            await dispatch(actionCreators.getAccounts());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const accounts = [...orchestratorState.accounts];

            const accountUser = await Data.getAccountUser(id, accounts);
            dispatch(fetchAccountUserSuccess({ record: accountUser }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchAccountUserFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const addAccountUser = (payload, accountId) => {
    return async (dispatch, getState) => {
        try {
            dispatch(addAccountUserStart());

            await dispatch(actionCreators.getAccounts());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const accounts = [...orchestratorState.accounts];

            const newAccountUser = await Data.addAccountUser(payload, accountId, false, accounts);
            if (isObjectNotEmpty(newAccountUser)) {
                if (newAccountUser.isDriver === true) {
                    dispatch(addAccountUserToAccountUserList(`${newAccountUser.accountId}_DRIVERS`, newAccountUser));
                    if (isListNotEmpty(newAccountUser.accountRoles)) {
                        const hasRoles = newAccountUser.accountRoles.some(i => employeeRoles.includes(i));
                        if (hasRoles === true) {
                            dispatch(addAccountUserToAccountUserList(`${newAccountUser.accountId}_EMPLOYEES`, newAccountUser));
                        }
                    }
                } else {
                    dispatch(addAccountUserToAccountUserList(`${newAccountUser.accountId}_EMPLOYEES`, newAccountUser));
                }

                // refresh employee and driver orchestrators
                if (newAccountUser.isDriver === true) {
                    await dispatch(actionCreators.getDrivers(newAccountUser.accountId, true));
                    const hasRoles = newAccountUser.accountRoles.some(i => employeeRoles.includes(i));
                    if (hasRoles === true) {
                        await dispatch(actionCreators.getEmployees(newAccountUser.accountId, true));
                    }
                } else {
                    await dispatch(actionCreators.getEmployees(newAccountUser.accountId, true));
                }
            }

            dispatch(addAccountUserSuccess());
            dispatch(addAccountUserLoadingClear());
            dispatch(addAccountUserErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addAccountUserFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const addAccountUserAndAddToLoad = (payload, accountId, load) => {
    return async (dispatch, getState) => {
        try {
            dispatch(addAccountUserStart());

            await dispatch(actionCreators.getAccounts());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const accounts = [...orchestratorState.accounts];

            const newAccountUser = await Data.addAccountUserAndAddToLoad(payload, accountId, load, accounts);
            if (isObjectNotEmpty(newAccountUser)) {
                if (newAccountUser.isDriver === true) {
                    dispatch(addAccountUserToAccountUserList(`${newAccountUser.accountId}_DRIVERS`, newAccountUser));
                    if (isListNotEmpty(newAccountUser.accountRoles)) {
                        const hasRoles = newAccountUser.accountRoles.some(i => employeeRoles.includes(i));
                        if (hasRoles === true) {
                            dispatch(addAccountUserToAccountUserList(`${newAccountUser.accountId}_EMPLOYEES`, newAccountUser));
                        }
                    }
                } else {
                    dispatch(addAccountUserToAccountUserList(`${newAccountUser.accountId}_EMPLOYEES`, newAccountUser));
                }
                dispatch(actionCreators.loadEventUpdateTimeout(load.id, null, null, null, 'LOAD_UPDATED'));

                // refresh employee and driver orchestrators
                if (newAccountUser.isDriver === true) {
                    await dispatch(actionCreators.getDrivers(newAccountUser.accountId, true));
                    const hasRoles = newAccountUser.accountRoles.some(i => employeeRoles.includes(i));
                    if (hasRoles === true) {
                        await dispatch(actionCreators.getEmployees(newAccountUser.accountId, true));
                    }
                } else {
                    await dispatch(actionCreators.getEmployees(newAccountUser.accountId, true));
                }
            }

            dispatch(addAccountUserSuccess());
            dispatch(addAccountUserLoadingClear());
            dispatch(addAccountUserErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addAccountUserFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateAccountUser = (id, payload, isDriver, entityType = null, signInAgain = false) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updateAccountUserStart());

            await dispatch(actionCreators.getAccounts());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const accounts = [...orchestratorState.accounts];

            if (isBooleanTrue(payload.isDeleted)) {
                const removedAccountUser = await Data.removeAccountUser(id);
                if (isObjectNotEmpty(removedAccountUser)) {
                    dispatch(removeAccountUserFromAccountUserList(`${removedAccountUser.accountId}_DRIVERS`, removedAccountUser));
                    dispatch(removeAccountUserFromAccountUserList(`${removedAccountUser.accountId}_EMPLOYEES`, removedAccountUser));

                    // refresh employee and driver orchestrators
                    if (removedAccountUser.isDriver === true) {
                        await dispatch(actionCreators.getDrivers(removedAccountUser.accountId, true));
                        const hasRoles = removedAccountUser.accountRoles.some(i => employeeRoles.includes(i));
                        if (hasRoles === true) {
                            await dispatch(actionCreators.getEmployees(removedAccountUser.accountId, true));
                        }
                    } else {
                        await dispatch(actionCreators.getEmployees(removedAccountUser.accountId, true));
                    }
                }
            } else {
                const updatedAccountUser = await Data.updateAccountUser(id, payload, isDriver, accounts, entityType);
                if (isObjectNotEmpty(updatedAccountUser)) {
                    if (updatedAccountUser.isDriver === true) {
                        dispatch(updateAccountUserInAccountUserList(`${updatedAccountUser.accountId}_DRIVERS`, updatedAccountUser));
                        if (isListNotEmpty(updatedAccountUser.accountRoles)) {
                            const hasRoles = updatedAccountUser.accountRoles.some(i => employeeRoles.includes(i));
                            if (hasRoles === true) {
                                dispatch(updateAccountUserInAccountUserList(`${updatedAccountUser.accountId}_EMPLOYEES`, updatedAccountUser));
                            } else {
                                dispatch(removeAccountUserFromAccountUserList(`${updatedAccountUser.accountId}_EMPLOYEES`, updatedAccountUser));
                            }
                        } else {
                            dispatch(removeAccountUserFromAccountUserList(`${updatedAccountUser.accountId}_EMPLOYEES`, updatedAccountUser));
                        }
                    } else {
                        dispatch(updateAccountUserInAccountUserList(`${updatedAccountUser.accountId}_EMPLOYEES`, updatedAccountUser));
                    }
                    dispatch(changeSingleAccountUser(updatedAccountUser));

                    // refresh employee and driver orchestrators
                    if (updatedAccountUser.isDriver === true) {
                        await dispatch(actionCreators.getDrivers(updatedAccountUser.accountId, true));
                        const hasRoles = updatedAccountUser.accountRoles.some(i => employeeRoles.includes(i));
                        if (hasRoles === true) {
                            await dispatch(actionCreators.getEmployees(updatedAccountUser.accountId, true));
                        }
                    } else {
                        await dispatch(actionCreators.getEmployees(updatedAccountUser.accountId, true));
                    }

                    if (signInAgain === true) {
                        await dispatch(actionCreators.autoLogin(true));
                    }
                }
            }

            dispatch(updateAccountUserSuccess());
            dispatch(updateAccountUserLoadingClear());
            dispatch(updateAccountUserErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateAccountUserFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateAccountUserDocument = (id, payload, entityType) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updateAccountUserStart());

            await dispatch(actionCreators.getAccounts());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const accounts = [...orchestratorState.accounts];

            const filesWereUpdated = await Data.updateAccountUserDocument(id, payload, entityType);

            // reset approval of driver if the entityType is not staff and the document type is commercial drivers license
            // if (entityType !== 'STAFF' && payload.frontDocumentType === 'COMMERCIAL_DRIVERS_LICENSE_FRONT') {
            //     const updatedAccountUser = await Data.unapproveDriver(id, accounts);
            //     dispatch(updateAccountUserInAccountUserList(`${updatedAccountUser.accountId}_${updatedAccountUser.isDriver === true ? 'DRIVERS' : 'EMPLOYEES'}`, updatedAccountUser));
            //     dispatch(changeSingleAccountUser(updatedAccountUser));
            // }

            if (isBooleanTrue(filesWereUpdated)) {
                const updatedAccountUser = await Data.getAccountUser(id, accounts);
                dispatch(changeSingleAccountUser(updatedAccountUser));
            }

            dispatch(updateAccountUserSuccess());
            dispatch(updateAccountUserLoadingClear());
            dispatch(updateAccountUserErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateAccountUserFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateAccountUserAndAddToLoad = (accountUserId, payload, isDriver, load) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updateAccountUserStart());

            await dispatch(actionCreators.getAccounts());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const accounts = [...orchestratorState.accounts];

            const updatedAccountUser = await Data.updateAccountUserAndAddToLoad(accountUserId, payload, isDriver, load, accounts);
            if (isObjectNotEmpty(updatedAccountUser)) {
                if (updatedAccountUser.isDriver === true) {
                    dispatch(updateAccountUserInAccountUserList(`${updatedAccountUser.accountId}_DRIVERS`, updatedAccountUser));
                    if (isListNotEmpty(updatedAccountUser.accountRoles)) {
                        const hasRoles = updatedAccountUser.accountRoles.some(i => employeeRoles.includes(i));
                        if (hasRoles === true) {
                            dispatch(updateAccountUserInAccountUserList(`${updatedAccountUser.accountId}_EMPLOYEES`, updatedAccountUser));
                        } else {
                            dispatch(removeAccountUserFromAccountUserList(`${updatedAccountUser.accountId}_EMPLOYEES`, updatedAccountUser));
                        }
                    } else {
                        dispatch(removeAccountUserFromAccountUserList(`${updatedAccountUser.accountId}_EMPLOYEES`, updatedAccountUser));
                    }
                } else {
                    dispatch(updateAccountUserInAccountUserList(`${updatedAccountUser.accountId}_EMPLOYEES`, updatedAccountUser));
                }
                dispatch(changeSingleAccountUser(updatedAccountUser));
                dispatch(actionCreators.loadEventUpdateTimeout(load.id, null, null, null, 'LOAD_UPDATED'));

                // refresh employee and driver orchestrators
                if (updatedAccountUser.isDriver === true) {
                    await dispatch(actionCreators.getDrivers(updatedAccountUser.accountId, true));
                    const hasRoles = updatedAccountUser.accountRoles.some(i => employeeRoles.includes(i));
                    if (hasRoles === true) {
                        await dispatch(actionCreators.getEmployees(updatedAccountUser.accountId, true));
                    }
                } else {
                    await dispatch(actionCreators.getEmployees(updatedAccountUser.accountId, true));
                }
            }

            dispatch(updateAccountUserSuccess());
            dispatch(updateAccountUserLoadingClear());
            dispatch(updateAccountUserErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateAccountUserFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const verifyAccountUser = (id) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updateAccountUserStart());

            await dispatch(actionCreators.getAccounts());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const accounts = [...orchestratorState.accounts];

            const updatedAccountUser = await Data.updateAccountUser(id, { isVerified: true }, false, accounts);
            if (isObjectNotEmpty(updatedAccountUser)) {
                if (updatedAccountUser.isDriver === true) {
                    dispatch(updateAccountUserInAccountUserList(`${updatedAccountUser.accountId}_DRIVERS`, updatedAccountUser));
                    if (isListNotEmpty(updatedAccountUser.accountRoles)) {
                        const hasRoles = updatedAccountUser.accountRoles.some(i => employeeRoles.includes(i));
                        if (hasRoles === true) {
                            dispatch(updateAccountUserInAccountUserList(`${updatedAccountUser.accountId}_EMPLOYEES`, updatedAccountUser));
                        } else {
                            dispatch(removeAccountUserFromAccountUserList(`${updatedAccountUser.accountId}_EMPLOYEES`, updatedAccountUser));
                        }
                    } else {
                        dispatch(removeAccountUserFromAccountUserList(`${updatedAccountUser.accountId}_EMPLOYEES`, updatedAccountUser));
                    }
                } else {
                    dispatch(updateAccountUserInAccountUserList(`${updatedAccountUser.accountId}_EMPLOYEES`, updatedAccountUser));
                }
                dispatch(changeSingleAccountUser(updatedAccountUser));

                // refresh employee and driver orchestrators
                if (updatedAccountUser.isDriver === true) {
                    await dispatch(actionCreators.getDrivers(updatedAccountUser.accountId, true));
                    const hasRoles = updatedAccountUser.accountRoles.some(i => employeeRoles.includes(i));
                    if (hasRoles === true) {
                        await dispatch(actionCreators.getEmployees(updatedAccountUser.accountId, true));
                    }
                } else {
                    await dispatch(actionCreators.getEmployees(updatedAccountUser.accountId, true));
                }
            }

            dispatch(updateAccountUserSuccess());
            dispatch(updateAccountUserLoadingClear());
            dispatch(updateAccountUserErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateAccountUserFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const unverifyAccountUser = (id) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updateAccountUserStart());

            await dispatch(actionCreators.getAccounts());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const accounts = [...orchestratorState.accounts];

            const updatedAccountUser = await Data.updateAccountUser(id, { isVerified: false }, false, accounts);
            if (isObjectNotEmpty(updatedAccountUser)) {
                if (updatedAccountUser.isDriver === true) {
                    dispatch(updateAccountUserInAccountUserList(`${updatedAccountUser.accountId}_DRIVERS`, updatedAccountUser));
                    if (isListNotEmpty(updatedAccountUser.accountRoles)) {
                        const hasRoles = updatedAccountUser.accountRoles.some(i => employeeRoles.includes(i));
                        if (hasRoles === true) {
                            dispatch(updateAccountUserInAccountUserList(`${updatedAccountUser.accountId}_EMPLOYEES`, updatedAccountUser));
                        } else {
                            dispatch(removeAccountUserFromAccountUserList(`${updatedAccountUser.accountId}_EMPLOYEES`, updatedAccountUser));
                        }
                    } else {
                        dispatch(removeAccountUserFromAccountUserList(`${updatedAccountUser.accountId}_EMPLOYEES`, updatedAccountUser));
                    }
                } else {
                    dispatch(updateAccountUserInAccountUserList(`${updatedAccountUser.accountId}_EMPLOYEES`, updatedAccountUser));
                }
                dispatch(changeSingleAccountUser(updatedAccountUser));

                // refresh employee and driver orchestrators
                if (updatedAccountUser.isDriver === true) {
                    await dispatch(actionCreators.getDrivers(updatedAccountUser.accountId, true));
                    const hasRoles = updatedAccountUser.accountRoles.some(i => employeeRoles.includes(i));
                    if (hasRoles === true) {
                        await dispatch(actionCreators.getEmployees(updatedAccountUser.accountId, true));
                    }
                } else {
                    await dispatch(actionCreators.getEmployees(updatedAccountUser.accountId, true));
                }
            }

            dispatch(updateAccountUserSuccess());
            dispatch(updateAccountUserLoadingClear());
            dispatch(updateAccountUserErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateAccountUserFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const approveDriver = (accountUserId) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updateAccountUserStart());

            await dispatch(actionCreators.getAccounts());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const accounts = [...orchestratorState.accounts];

            const updatedAccountUser = await Data.approveDriver(accountUserId, accounts);
            if (isObjectNotEmpty(updatedAccountUser)) {
                if (updatedAccountUser.isDriver === true) {
                    dispatch(updateAccountUserInAccountUserList(`${updatedAccountUser.accountId}_DRIVERS`, updatedAccountUser));
                    if (isListNotEmpty(updatedAccountUser.accountRoles)) {
                        const hasRoles = updatedAccountUser.accountRoles.some(i => employeeRoles.includes(i));
                        if (hasRoles === true) {
                            dispatch(updateAccountUserInAccountUserList(`${updatedAccountUser.accountId}_EMPLOYEES`, updatedAccountUser));
                        } else {
                            dispatch(removeAccountUserFromAccountUserList(`${updatedAccountUser.accountId}_EMPLOYEES`, updatedAccountUser));
                        }
                    } else {
                        dispatch(removeAccountUserFromAccountUserList(`${updatedAccountUser.accountId}_EMPLOYEES`, updatedAccountUser));
                    }
                } else {
                    dispatch(updateAccountUserInAccountUserList(`${updatedAccountUser.accountId}_EMPLOYEES`, updatedAccountUser));
                }
                dispatch(changeSingleAccountUser(updatedAccountUser));

                // refresh employee and driver orchestrators
                if (updatedAccountUser.isDriver === true) {
                    await dispatch(actionCreators.getDrivers(updatedAccountUser.accountId, true));
                    const hasRoles = updatedAccountUser.accountRoles.some(i => employeeRoles.includes(i));
                    if (hasRoles === true) {
                        await dispatch(actionCreators.getEmployees(updatedAccountUser.accountId, true));
                    }
                } else {
                    await dispatch(actionCreators.getEmployees(updatedAccountUser.accountId, true));
                }
            }

            dispatch(updateAccountUserSuccess());
            dispatch(updateAccountUserLoadingClear());
            dispatch(updateAccountUserErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateAccountUserFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const unapproveDriver = (accountUserId) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updateAccountUserStart());

            await dispatch(actionCreators.getAccounts());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const accounts = [...orchestratorState.accounts];

            const updatedAccountUser = await Data.unapproveDriver(accountUserId, accounts);
            if (isObjectNotEmpty(updatedAccountUser)) {
                if (updatedAccountUser.isDriver === true) {
                    dispatch(updateAccountUserInAccountUserList(`${updatedAccountUser.accountId}_DRIVERS`, updatedAccountUser));
                    if (isListNotEmpty(updatedAccountUser.accountRoles)) {
                        const hasRoles = updatedAccountUser.accountRoles.some(i => employeeRoles.includes(i));
                        if (hasRoles === true) {
                            dispatch(updateAccountUserInAccountUserList(`${updatedAccountUser.accountId}_EMPLOYEES`, updatedAccountUser));
                        } else {
                            dispatch(removeAccountUserFromAccountUserList(`${updatedAccountUser.accountId}_EMPLOYEES`, updatedAccountUser));
                        }
                    } else {
                        dispatch(removeAccountUserFromAccountUserList(`${updatedAccountUser.accountId}_EMPLOYEES`, updatedAccountUser));
                    }
                } else {
                    dispatch(updateAccountUserInAccountUserList(`${updatedAccountUser.accountId}_EMPLOYEES`, updatedAccountUser));
                }
                dispatch(changeSingleAccountUser(updatedAccountUser));

                // refresh employee and driver orchestrators
                if (updatedAccountUser.isDriver === true) {
                    await dispatch(actionCreators.getDrivers(updatedAccountUser.accountId, true));
                    const hasRoles = updatedAccountUser.accountRoles.some(i => employeeRoles.includes(i));
                    if (hasRoles === true) {
                        await dispatch(actionCreators.getEmployees(updatedAccountUser.accountId, true));
                    }
                } else {
                    await dispatch(actionCreators.getEmployees(updatedAccountUser.accountId, true));
                }
            }

            dispatch(updateAccountUserSuccess());
            dispatch(updateAccountUserLoadingClear());
            dispatch(updateAccountUserErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateAccountUserFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const markDriverAvailable = (accountUserId) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updateAccountUserStart());

            await dispatch(actionCreators.getAccounts());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const accounts = [...orchestratorState.accounts];

            const updatedAccountUser = await Data.markDriverAvailable(accountUserId, accounts);
            if (isObjectNotEmpty(updatedAccountUser)) {
                if (updatedAccountUser.isDriver === true) {
                    dispatch(updateAccountUserInAccountUserList(`${updatedAccountUser.accountId}_DRIVERS`, updatedAccountUser));
                    if (isListNotEmpty(updatedAccountUser.accountRoles)) {
                        const hasRoles = updatedAccountUser.accountRoles.some(i => employeeRoles.includes(i));
                        if (hasRoles === true) {
                            dispatch(updateAccountUserInAccountUserList(`${updatedAccountUser.accountId}_EMPLOYEES`, updatedAccountUser));
                        } else {
                            dispatch(removeAccountUserFromAccountUserList(`${updatedAccountUser.accountId}_EMPLOYEES`, updatedAccountUser));
                        }
                    } else {
                        dispatch(removeAccountUserFromAccountUserList(`${updatedAccountUser.accountId}_EMPLOYEES`, updatedAccountUser));
                    }
                } else {
                    dispatch(updateAccountUserInAccountUserList(`${updatedAccountUser.accountId}_EMPLOYEES`, updatedAccountUser));
                }
                dispatch(changeSingleAccountUser(updatedAccountUser));

                // refresh employee and driver orchestrators
                if (updatedAccountUser.isDriver === true) {
                    await dispatch(actionCreators.getDrivers(updatedAccountUser.accountId, true));
                    const hasRoles = updatedAccountUser.accountRoles.some(i => employeeRoles.includes(i));
                    if (hasRoles === true) {
                        await dispatch(actionCreators.getEmployees(updatedAccountUser.accountId, true));
                    }
                } else {
                    await dispatch(actionCreators.getEmployees(updatedAccountUser.accountId, true));
                }
            }

            dispatch(updateAccountUserSuccess());
            dispatch(updateAccountUserLoadingClear());
            dispatch(updateAccountUserErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateAccountUserFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const markDriverUnavailable = (accountUserId) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updateAccountUserStart());

            await dispatch(actionCreators.getAccounts());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const accounts = [...orchestratorState.accounts];

            const updatedAccountUser = await Data.markDriverUnavailable(accountUserId, accounts);
            if (isObjectNotEmpty(updatedAccountUser)) {
                if (updatedAccountUser.isDriver === true) {
                    dispatch(updateAccountUserInAccountUserList(`${updatedAccountUser.accountId}_DRIVERS`, updatedAccountUser));
                    if (isListNotEmpty(updatedAccountUser.accountRoles)) {
                        const hasRoles = updatedAccountUser.accountRoles.some(i => employeeRoles.includes(i));
                        if (hasRoles === true) {
                            dispatch(updateAccountUserInAccountUserList(`${updatedAccountUser.accountId}_EMPLOYEES`, updatedAccountUser));
                        } else {
                            dispatch(removeAccountUserFromAccountUserList(`${updatedAccountUser.accountId}_EMPLOYEES`, updatedAccountUser));
                        }
                    } else {
                        dispatch(removeAccountUserFromAccountUserList(`${updatedAccountUser.accountId}_EMPLOYEES`, updatedAccountUser));
                    }
                } else {
                    dispatch(updateAccountUserInAccountUserList(`${updatedAccountUser.accountId}_EMPLOYEES`, updatedAccountUser));
                }
                dispatch(changeSingleAccountUser(updatedAccountUser));

                // refresh employee and driver orchestrators
                if (updatedAccountUser.isDriver === true) {
                    await dispatch(actionCreators.getDrivers(updatedAccountUser.accountId, true));
                    const hasRoles = updatedAccountUser.accountRoles.some(i => employeeRoles.includes(i));
                    if (hasRoles === true) {
                        await dispatch(actionCreators.getEmployees(updatedAccountUser.accountId, true));
                    }
                } else {
                    await dispatch(actionCreators.getEmployees(updatedAccountUser.accountId, true));
                }
            }

            dispatch(updateAccountUserSuccess());
            dispatch(updateAccountUserLoadingClear());
            dispatch(updateAccountUserErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateAccountUserFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion