import axiosAuthenticated from "../../api/axiosAuthenticated";
import { addFile } from "../../api/fileClient";
import { isListNotEmpty, isObjectEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import * as DTO from "../dtos/index";
import * as Data from "./index";
import moment from 'moment';
import 'moment-timezone';

const loadStopsPath = '/stops';

//#region Load Stops Methods

export const getLoadStops = async (loadId, load = null) => {
    const loadStopsRes = await axiosAuthenticated.get(loadStopsPath, { params: { page: 1, size: 1000000, sort: 'sequence', order: 'asc', loadId: loadId, isDeleted: false } });
    if (loadStopsRes && loadStopsRes.status === 200) {
        const loadStops = loadStopsRes.data.data;

        let locationIds = getLocationIdsForLoadStops(loadStops);
        let locations = await Data.getLocationsByIds(locationIds);

        const transformedLoadStops = loadStops.map((stop) => { return DTO.getLoadStopDTO(stop, locations, load); });

        return transformedLoadStops;
    }

    return [];
};

export const addLoadStop = async (payload, docFiles, loadId, load) => {
    // First, create the LoadStop
    // Backend: The stop order will inject this Stop as that number Stop in the trip sequence. You cannot inject a Stop before any Completed Stops for a Load that is in progress.
    let loadStopPayload = DTO.getAddLoadStopRequestDTO(payload, loadId);

    const loadStopsRes = await axiosAuthenticated.post(loadStopsPath, loadStopPayload);
    if (loadStopsRes && loadStopsRes.status === 201) {
        let newLoadStop = loadStopsRes.data;

        // Second, if the stop has sequence 0 and stopType is PICK_UP, then we have a new pickUpDateTime for the load so update the load too
        if (newLoadStop.sequence === 0 && newLoadStop.stopType === "PICK_UP") {
            let pickUpDateTime = newLoadStop.requestedDateTime;
            await Data.updateLoad(loadId, { pickUpDateTime: pickUpDateTime });
        }

        // Third, Add BOL Documents
        if (isListNotEmpty(docFiles)) {
            for (let i = 0; i < docFiles.length; i++) {
                await addFile(docFiles[i].file, "STOP", newLoadStop.id, "BOL", `BOL #${docFiles[i].bolNumber} - ${i + 1}`, "", "ALL", null, null, null, null, [{ entityType: 'STOP', entityId: newLoadStop.id }, { entityType: 'LOAD', entityId: loadId }]);
            }
        }

        // Fourth, Add Invoice Line Items if hasLumperFee === true or hasDriverAssist === true
        if (newLoadStop.hasLumperFee === true || newLoadStop.hasDriverAssist === true) {
            let newInvoiceLineItems = DTO.getAddInvoiceLineItemsRequestDTO(newLoadStop.id, newLoadStop, loadStopPayload, loadId, load);
            await Data.addInvoiceLineItem(newInvoiceLineItems);
        }

        await Data.addLoadEvent({ loadId: loadId, eventType: 'LOAD_UPDATED', changeType: 'STOPS_UPDATED' })

        let locations = await Data.getLocationsByIds([newLoadStop.stopLocationId]);

        return DTO.getLoadStopDTO(newLoadStop, locations, load);
    }

    return null;
};

export const addRestStop = async (payload, latitude, longitude, distanceTravelled, loadId) => {
    const timeZone = moment.tz.guess();

    // Backend: The stop order will inject this Stop as that number Stop in the trip sequence. You cannot inject a Stop before any Completed Stops for a Load that is in progress.
    let loadStopPayload = {
        unitOfMeasure: "ENGLISH",
        loadId: loadId,
        stopType: payload.stopType,
        sequence: payload.sequence, // actual sequence since coming from mobile app injection
        timeZone: timeZone,
        latitude: latitude,
        longitude: longitude,
        actualDistanceTo: distanceTravelled ? distanceTravelled : 0,
        actualDistanceToUnit: "MI",
        hasLumperFee: false,
        hasDriverAssist: false,
        driverAssist: 0,
        driverAssistUnit: 'USD',
        lumperFee: 0,
        lumperFeeUnit: 'USD'
    };

    const loadStopsRes = await axiosAuthenticated.post(loadStopsPath, loadStopPayload);
    if (loadStopsRes && loadStopsRes.status === 201) {
        let newLoadStop = loadStopsRes.data;

        return DTO.getLoadStopDTO(newLoadStop, null, null);
    }

    return null;
};

export const updateLoadStop = async (stopId, payload, originalStop, loadId, load) => {
    let timeZone = isStringNotEmpty(originalStop.timeZone) ? originalStop.timeZone : null;

    let stopPayload = DTO.getUpdateLoadStopRequestDTO(payload, timeZone);

    const loadStopsRes = await axiosAuthenticated.put(loadStopsPath + `/${stopId}`, { ...stopPayload });
    if (loadStopsRes && loadStopsRes.status === 200) {
        let updatedLoadStop = loadStopsRes.data;

        // if the stop has sequence 0 and stopType is PICK_UP, then we have a new pickUpDateTime for the load so update the load too
        if (updatedLoadStop.sequence === 0 && updatedLoadStop.stopType === "PICK_UP") {
            let pickUpDateTime = updatedLoadStop.requestedDateTime;
            await Data.updateLoad(loadId, { pickUpDateTime: pickUpDateTime });
        }

        // get invoicelineitems for existing stop
        let existingInvoiceLineItems = await Data.getInvoiceLineItemsByLoadIdAndStopId(loadId, stopId);
        if (isListNotEmpty(existingInvoiceLineItems)) {
            // add/update/remove driver assist invoice line items
            if (updatedLoadStop.hasDriverAssist === true) {
                let driverAssistInvoiceLineItems = existingInvoiceLineItems.filter(i => i.itemType === "DRIVER_ASSIST");
                if (isListNotEmpty(driverAssistInvoiceLineItems)) {
                    // check to see if the driver assist invoice line items need to be updated
                    for (let i = 0; i < driverAssistInvoiceLineItems.length; i++) {
                        if (driverAssistInvoiceLineItems[i].baseAmount !== updatedLoadStop.driverAssist) {
                            await Data.updateInvoiceLineItem(driverAssistInvoiceLineItems[i].id, {
                                baseAmount: Number(updatedLoadStop.driverAssist),
                                totalAmount: Number(updatedLoadStop.driverAssist)
                            });
                        }
                    }
                } else {
                    // add invoicelineitems for driver assist
                    let req = DTO.getAddDriverAssistInvoiceLineItemsRequestDTO(updatedLoadStop.id, updatedLoadStop, originalStop, loadId, load);
                    await Data.addInvoiceLineItems(req);
                }
            } else {
                // check to see if invoice line items already exist, and if so remove them since they are no longer needed
                let driverAssistInvoiceLineItems = existingInvoiceLineItems.filter(i => i.itemType === "DRIVER_ASSIST");
                if (isListNotEmpty(driverAssistInvoiceLineItems)) {
                    await Data.removeInvoiceLineItems(driverAssistInvoiceLineItems.map(i => i.id));
                }
            }

            // add/update/remove lumper fee invoice line items
            if (updatedLoadStop.hasLumperFee === true) {
                let lumperFeeInvoiceLineItems = existingInvoiceLineItems.filter(i => i.itemType === "LUMPER_FEE");
                if (isListNotEmpty(lumperFeeInvoiceLineItems)) {
                    // check to see if the lumper fee invoice line items need to be updated
                    for (let i = 0; i < lumperFeeInvoiceLineItems.length; i++) {
                        if (lumperFeeInvoiceLineItems[i].baseAmount !== updatedLoadStop.lumperFee) {
                            await Data.updateInvoiceLineItem(lumperFeeInvoiceLineItems[i].id, {
                                baseAmount: Number(updatedLoadStop.lumperFee),
                                totalAmount: Number(updatedLoadStop.lumperFee)
                            });
                        }
                    }
                } else {
                    // add invoicelineitems for lumper fee
                    let req = DTO.getAddLumperFeeInvoiceLineItemsRequestDTO(updatedLoadStop.id, updatedLoadStop, originalStop, loadId, load);
                    await Data.addInvoiceLineItems(req);
                }
            } else {
                // check to see if invoice line items already exist, and if so remove them since they are no longer needed
                let lumperFeeInvoiceLineItems = existingInvoiceLineItems.filter(i => i.itemType === "LUMPER_FEE");
                if (isListNotEmpty(lumperFeeInvoiceLineItems)) {
                    await Data.removeInvoiceLineItems(lumperFeeInvoiceLineItems.map(i => i.id));
                }
            }
        }

        await Data.addLoadEvent({ loadId: loadId, eventType: 'LOAD_UPDATED', changeType: 'STOPS_UPDATED' })

        let locations = await Data.getLocationsByIds([updatedLoadStop.stopLocationId]);

        return DTO.getLoadStopDTO(updatedLoadStop, locations, load);
    }

    return null;
};

export const updateLoadStopApptDetails = async (stopId, loadId, payload, timeZone, load) => {
    let stopPayload = DTO.getUpdateLoadStopRequestDTO(payload, timeZone);

    const loadStopsRes = await axiosAuthenticated.put(loadStopsPath + `/${stopId}`, { ...stopPayload });
    if (loadStopsRes && loadStopsRes.status === 200) {
        let updatedLoadStop = loadStopsRes.data;

        await Data.addLoadEvent({ loadId: loadId, eventType: 'LOAD_UPDATED', changeType: 'STOPS_UPDATED' });

        let locations = await Data.getLocationsByIds([updatedLoadStop.stopLocationId]);

        return DTO.getLoadStopDTO(updatedLoadStop, locations, load);
    }

    return null;
};

export const completeLoadPickUpStop = async (loadId, stopId, payload) => {
    let docFiles = isListNotEmpty(payload.docFiles) ? [...payload.docFiles] : [];
    let scaleTicketFiles = isListNotEmpty(payload.scaleTicketFiles) ? [...payload.scaleTicketFiles] : [];
    let bolFiles = isListNotEmpty(payload.bolFiles) ? [...payload.bolFiles] : [];
    let lumperFeeReceiptFiles = isListNotEmpty(payload.lumperFeeReceiptFiles) ? [...payload.lumperFeeReceiptFiles] : [];

    let haveAllBolFilesBeenAdded = true;
    for (let i = 0; i < bolFiles.length; i++) {
        let file = bolFiles[i].file;
        let documentType = bolFiles[i].metaData.documentType;
        let displayName = `${bolFiles[i].metaData.displayName} - ${i + 1}`;
        let description = bolFiles[i].metaData.description;
        let document = await addFile(file, "STOP", stopId, documentType, displayName, description, "ALL", null, null, null, null, [{ entityType: 'STOP', entityId: stopId }, { entityType: 'LOAD', entityId: loadId }]);
        if (isObjectEmpty(document)) {
            haveAllBolFilesBeenAdded = false;
        }
    }

    let haveAllScaleTicketsFilesBeenAdded = true;
    for (let i = 0; i < scaleTicketFiles.length; i++) {
        let file = scaleTicketFiles[i];
        let document = await addFile(file, "STOP", stopId, "SCALE_TICKET", "Scale Ticket", null, "ALL", null, null, null, null, [{ entityType: 'STOP', entityId: stopId }, { entityType: 'LOAD', entityId: loadId }]);
        if (isObjectEmpty(document)) {
            haveAllScaleTicketsFilesBeenAdded = false;
        }
    }

    let haveAllLumperFeeReceiptFilesBeenAdded = true;
    for (let i = 0; i < lumperFeeReceiptFiles.length; i++) {
        let file = null;
        if (lumperFeeReceiptFiles[i].file === undefined) {
            file = lumperFeeReceiptFiles[i];
        } else {
            file = lumperFeeReceiptFiles[i].file;
        }
        let document = await addFile(file, "STOP", stopId, "LUMPER_FEE_RECEIPT", "Lumper Fee Receipt", null, "ALL", null, null, null, null, [{ entityType: 'STOP', entityId: stopId }, { entityType: 'LOAD', entityId: loadId }]);
        if (isObjectEmpty(document)) {
            haveAllLumperFeeReceiptFilesBeenAdded = false;
        }
    }

    let haveAllDocFilesBeenAdded = true;
    for (let i = 0; i < docFiles.length; i++) {
        let file = docFiles[i];
        let documentType = payload[`otherDocumentType${i}`];
        let displayName = payload[`otherDocumentDisplayName${i}`];
        let description = payload[`otherDocumentDescription${i}`];
        let document = await addFile(file, "STOP", stopId, documentType, displayName, description, "ALL", null, null, null, null, [{ entityType: 'STOP', entityId: stopId }, { entityType: 'LOAD', entityId: loadId }]);
        if (isObjectEmpty(document)) {
            haveAllDocFilesBeenAdded = false;
        }
    }

    if (haveAllBolFilesBeenAdded === true && haveAllScaleTicketsFilesBeenAdded === true && haveAllDocFilesBeenAdded === true && haveAllLumperFeeReceiptFilesBeenAdded === true) {
        return await Data.addLoadEvent({ loadId: loadId, stopId: stopId, eventType: 'LOAD_PICK_UP_COMPLETED' });
    } else {
        throw new Error('Failed to upload all the files.');
    }

    return null;
};

export const completeLoadDropOffStop = async (loadId, stopId, payload) => {
    let docFiles = isListNotEmpty(payload.docFiles) ? [...payload.docFiles] : [];
    let scaleTicketFiles = isListNotEmpty(payload.scaleTicketFiles) ? [...payload.scaleTicketFiles] : [];
    let bolFiles = isListNotEmpty(payload.bolFiles) ? [...payload.bolFiles] : [];
    let lumperFeeReceiptFiles = isListNotEmpty(payload.lumperFeeReceiptFiles) ? [...payload.lumperFeeReceiptFiles] : [];

    let haveAllBolFilesBeenAdded = true;
    for (let i = 0; i < bolFiles.length; i++) {
        let file = bolFiles[i].file;
        let documentType = bolFiles[i].metaData.documentType;
        let displayName = `${bolFiles[i].metaData.displayName} - ${i + 1}`;
        let description = bolFiles[i].metaData.description;
        let document = await addFile(file, "STOP", stopId, documentType, displayName, description, "ALL", null, null, null, null, [{ entityType: 'STOP', entityId: stopId }, { entityType: 'LOAD', entityId: loadId }]);
        if (isObjectEmpty(document)) {
            haveAllBolFilesBeenAdded = false;
        }
    }

    let haveAllScaleTicketsFilesBeenAdded = true;
    for (let i = 0; i < scaleTicketFiles.length; i++) {
        let file = scaleTicketFiles[i];
        let document = await addFile(file, "STOP", stopId, "SCALE_TICKET", "Scale Ticket", null, "ALL", null, null, null, null, [{ entityType: 'STOP', entityId: stopId }, { entityType: 'LOAD', entityId: loadId }]);
        if (isObjectEmpty(document)) {
            haveAllScaleTicketsFilesBeenAdded = false;
        }
    }

    let haveAllLumperFeeReceiptFilesBeenAdded = true;
    for (let i = 0; i < lumperFeeReceiptFiles.length; i++) {
        let file = null;
        if (lumperFeeReceiptFiles[i].file === undefined) {
            file = lumperFeeReceiptFiles[i];
        } else {
            file = lumperFeeReceiptFiles[i].file;
        }
        let document = await addFile(file, "STOP", stopId, "LUMPER_FEE_RECEIPT", "Lumper Fee Receipt", null, "ALL", null, null, null, null, [{ entityType: 'STOP', entityId: stopId }, { entityType: 'LOAD', entityId: loadId }]);
        if (isObjectEmpty(document)) {
            haveAllLumperFeeReceiptFilesBeenAdded = false;
        }
    }

    let haveAllDocFilesBeenAdded = true;
    for (let i = 0; i < docFiles.length; i++) {
        let file = docFiles[i];
        let documentType = payload[`otherDocumentType${i}`];
        let displayName = payload[`otherDocumentDisplayName${i}`];
        let description = payload[`otherDocumentDescription${i}`];
        let document = await addFile(file, "STOP", stopId, documentType, displayName, description, "ALL", null, null, null, null, [{ entityType: 'STOP', entityId: stopId }, { entityType: 'LOAD', entityId: loadId }]);
        if (isObjectEmpty(document)) {
            haveAllDocFilesBeenAdded = false;
        }
    }

    if (haveAllBolFilesBeenAdded === true && haveAllScaleTicketsFilesBeenAdded === true && haveAllDocFilesBeenAdded === true && haveAllLumperFeeReceiptFilesBeenAdded === true) {
        await Data.updateLoad(loadId, { completionCheckList: { proofOfDeliveryStatus: 'UPLOADED' } });
        return await Data.addLoadEvent({ loadId: loadId, stopId: stopId, eventType: 'LOAD_DROP_OFF_COMPLETED', isLoadComplete: false });
    } else {
        throw new Error('Failed to upload all the files.');
    }

    return null;
};

export const completeLoadStop = async (loadId, stopId, stopType, payload) => {
    if (stopType === "PICK_UP" || stopType === "DROP_OFF") {
        let docFiles = isListNotEmpty(payload.docFiles) ? [...payload.docFiles] : [];
        let scaleTicketFiles = isListNotEmpty(payload.scaleTicketFiles) ? [...payload.scaleTicketFiles] : [];
        let bolFiles = isListNotEmpty(payload.bolFiles) ? [...payload.bolFiles] : [];

        let haveAllBolFilesBeenAdded = true;
        for (let i = 0; i < bolFiles.length; i++) {
            let file = bolFiles[i];
            let displayName = payload[`bolDocumentDisplayName${i}`];
            let document = await addFile(file, "STOP", stopId, "BOL", displayName, "", "ALL", null, null, null, null, [{ entityType: 'STOP', entityId: stopId }, { entityType: 'LOAD', entityId: loadId }]);
            if (isObjectEmpty(document)) {
                haveAllBolFilesBeenAdded = false;
            }
        }

        let haveAllScaleTicketsFilesBeenAdded = true;
        for (let i = 0; i < scaleTicketFiles.length; i++) {
            let file = scaleTicketFiles[i];
            let displayName = payload[`scaleTicketDocumentDisplayName${i}`];
            let document = await addFile(file, "STOP", stopId, "SCALE_TICKET", displayName, "", "ALL", null, null, null, null, [{ entityType: 'STOP', entityId: stopId }, { entityType: 'LOAD', entityId: loadId }]);
            if (isObjectEmpty(document)) {
                haveAllScaleTicketsFilesBeenAdded = false;
            }
        }

        let haveAllDocFilesBeenAdded = true;
        for (let i = 0; i < docFiles.length; i++) {
            let file = docFiles[i];
            let documentType = payload[`otherDocumentType${i}`];
            let displayName = payload[`otherDocumentDisplayName${i}`];
            let description = payload[`otherDocumentDescription${i}`];
            let document = await addFile(file, "STOP", stopId, documentType, displayName, description, "ALL", null, null, null, null, [{ entityType: 'STOP', entityId: stopId }, { entityType: 'LOAD', entityId: loadId }]);
            if (isObjectEmpty(document)) {
                haveAllDocFilesBeenAdded = false;
            }
        }

        if (haveAllBolFilesBeenAdded === true && haveAllScaleTicketsFilesBeenAdded === true && haveAllDocFilesBeenAdded === true) {
            if (stopType === "PICK_UP") {
                return await Data.addLoadEvent({ loadId: loadId, stopId: stopId, eventType: 'LOAD_PICK_UP_COMPLETED' });
            } else if (stopType === "DROP_OFF") {
                await Data.updateLoad(loadId, { completionCheckList: { proofOfDeliveryStatus: 'UPLOADED' } });
                return await Data.addLoadEvent({ loadId: loadId, stopId: stopId, eventType: 'LOAD_DROP_OFF_COMPLETED', isLoadComplete: false });
            }
        } else {
            throw new Error('Failed to upload all the files');
        }
    }

    return null;
};

export const completeLoadStops = async (loadId, stops) => {
    if (isListNotEmpty(stops)) {
        stops.forEach(async (stop) => {
            if (stop.stopStatus !== "COMPLETED") {
                await axiosAuthenticated.put(loadStopsPath + `/${stop.id}`, { stopStatus: 'COMPLETED' });
            }
        });
    }

    return null;
};

export const removeLoadStop = async (stopId, loadId, existingInvoiceLineItems = [], existingTransactions = [], existingClaims = []) => {
    const loadStopsRes = await axiosAuthenticated.put(loadStopsPath + `/${stopId}`, { isDeleted: true });
    if (loadStopsRes && loadStopsRes.status === 200) {
        let updatedLoadStop = loadStopsRes.data;

        // backend will remove all invoice line items for that stop
        // remove invoice line items for that stop from front end and recalculate the pricing on the load
        let updatedInvoiceLineItems = [];
        let invoiceLineItemsToRemove = [];
        if (isListNotEmpty(existingInvoiceLineItems)) {
            updatedInvoiceLineItems = [...existingInvoiceLineItems];
            updatedInvoiceLineItems.forEach((updatedInvoiceLineItem) => {
                if (isStringNotEmpty(updatedInvoiceLineItem.stopId) && updatedInvoiceLineItem.stopId === stopId) {
                    invoiceLineItemsToRemove.push(updatedInvoiceLineItem);
                }
            });
        }
        let updatedTransactions = [];
        if (isListNotEmpty(existingTransactions)) {
            updatedTransactions = [...existingTransactions];
        }
        let updatedClaims = [];
        if (isListNotEmpty(existingClaims)) {
            updatedClaims = [...existingClaims];
        }

        if (isListNotEmpty(invoiceLineItemsToRemove)) {
            invoiceLineItemsToRemove.forEach((updatedInvoiceLineItem) => {
                const index = updatedInvoiceLineItems.findIndex(obj => obj.id === updatedInvoiceLineItem.id);
                if (index !== -1) {
                    // returns the deleted items
                    updatedInvoiceLineItems.splice(index, 1);
                }
            });

            await Data.updateLoadPricing(loadId, updatedInvoiceLineItems, updatedTransactions, updatedClaims);
        }

        await Data.addLoadEvent({ loadId: loadId, eventType: 'LOAD_UPDATED', changeType: 'STOPS_UPDATED' });

        return updatedLoadStop;
    }

    return null;
};

//#endregion
//#region Helper Methods

export const getLocationIdsForLoadStops = (loadStops = []) => {
    let locationIds = [];
    if (isListNotEmpty(loadStops)) {
        loadStops.forEach((stop) => {
            if (!locationIds.includes(stop.stopLocationId)) {
                locationIds.push(stop.stopLocationId);
            }
        });
    }

    return locationIds;
};

//#endregion