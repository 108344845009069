import axiosAuthenticated from "../../api/axiosAuthenticated";
import { isStringNotEmpty } from "../../shared/objectUtils";

const loadEventsPath = '/loadEvents';

//#region Load Events Methods

export const addLoadEvent = async (payload) => {
    const loadsRes = await axiosAuthenticated.post(loadEventsPath, { ...payload });
    if (loadsRes && loadsRes.status === 202) {
        // console.log(loadsRes.data);
        
        return {
            loadId: isStringNotEmpty(payload.loadId) ? payload.loadId : null,
            stopId: isStringNotEmpty(payload.stopId) ? payload.stopId : null,
            loadStatus: isStringNotEmpty(payload.loadStatus) ? payload.loadStatus : null,
            stopStatus: isStringNotEmpty(payload.stopStatus) ? payload.stopStatus : null,
            eventType: isStringNotEmpty(payload.eventType) ? payload.eventType : null,
            changeType: isStringNotEmpty(payload.changeType) ? payload.changeType : null
        };
    }

    return null;
};

//#endregion