import React from 'react';
import DataRow from '../../components/DataRow/DataRow';
import { isBooleanFalse, isBooleanTrue, isListNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import StringFormatter from '../../shared/stringFormatter';

const stringFormatter = new StringFormatter();

const DataListRow = ({ className = null, valueClassName = null, titleClassName = null, style = null, valueStyle = null, titleStyle = { fontWeight: 'bold' }, title = null, data, dataType = 'String', units = null, timeZone = null, noColon = false, oneLine = false }) => {
    if (isBooleanTrue(oneLine) && isListNotEmpty(data)) {
        return (
            <DataRow className={className} valueClassName={valueClassName} titleClassName={titleClassName} style={style} valueStyle={valueStyle} titleStyle={titleStyle} title={title} noColon={noColon} value={data.map((dataItem) => stringFormatter.toFormattedString(dataType, dataItem, units, timeZone)).join(', ')} dataType="String" />
        );
    } else {
        return (
            <div className={className} style={style}>
                {isStringNotEmpty(title) ? (<div className={titleClassName} style={titleStyle}>{title}{isBooleanFalse(noColon) ? ':' : ''} </div>) : null}
                {isListNotEmpty(data) ? data.map((dataItem) => { return (<DataRow valueClassName={valueClassName} valueStyle={valueStyle} key={`data-row-${dataItem}`} value={dataItem} dataType={dataType} units={units} timeZone={timeZone} />); }) : null}
            </div>
        );
    }
};

export default DataListRow;