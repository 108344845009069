import axiosAuthenticated from "../../api/axiosAuthenticated";

const metricsPath = '/metrics';

//#region Metrics Methods

export const getMetrics = async (searchParams = {}) => {
    const metricsRes = await axiosAuthenticated.get(metricsPath, { params: { ...searchParams } });
    if (metricsRes && metricsRes.status === 200) {
        return metricsRes.data;
    }

    return null;
};

//#endregion