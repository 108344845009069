import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const fetchLoadEventSuccess = (state, action) => {
    return updateObject(state, {
        ...action
    });
};

const updateLoadEventStart = (state) => {
    return updateObject(state, {
        isLoadEventUpdateLoading: true,
        updateLoadEventError: null
    });
};

const updateLoadEventSuccess = (state) => {
    return updateObject(state, {
        isLoadEventUpdateLoading: false
    });
};

const updateLoadEventFail = (state, action) => {
    return updateObject(state, {
        updateLoadEventError: action.error,
        isLoadEventUpdateLoading: false
    });
};

const clearUpdateLoadEventError = (state) => {
    return updateObject(state, {
        updateLoadEventError: null
    });
};

export const updateLoadEventComplete = (state) => {
    return updateObject(state, {
        isLoadEventUpdateLoading: null,
        updateLoadEventError: null
    });
};

const initialState = {
    loadEventUpdateStatus: 'COMPLETED',
    isLoadEventUpdateLoading: null,
    updateLoadEventError: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_LOAD_EVENT_STATUS: return fetchLoadEventSuccess(state, action.payload);
        case actionTypes.UPDATE_LOAD_EVENT_START: return updateLoadEventStart(state);
        case actionTypes.UPDATE_LOAD_EVENT_SUCCESS: return updateLoadEventSuccess(state);
        case actionTypes.UPDATE_LOAD_EVENT_FAIL: return updateLoadEventFail(state, action.payload);
        case actionTypes.UPDATE_LOAD_EVENT_ERROR_CLEAR: return clearUpdateLoadEventError(state);
        case actionTypes.UPDATE_LOAD_EVENT_COMPLETE: return updateLoadEventComplete(state);
        default:
            return state;
    }
};

export default reducer;