import React from 'react';
import Enums from '../shared/enums';
import MomentDate from '../shared/dateFormatter';
import { isNotNullOrUndefined, isObjectNotEmpty, isStringNotEmpty } from './objectUtils';

export default class StringFormatter {
    toYesNo(booleanValue) {
        if (isNotNullOrUndefined(booleanValue)) {
            if (booleanValue === true) {
                return 'Yes';
            } else {
                return 'No';
            }
        } else {
            return 'No';
        }
    };

    toFirstNameLastName(user) {
        let name = '';
        if (isObjectNotEmpty(user)) {
            if (isStringNotEmpty(user.firstName) && isStringNotEmpty(user.lastName)) {
                name = `${user.firstName} ${user.lastName}`;
            }
        }

        return name;
    };

    toFormattedPhoneNumber(phoneNumber, phoneNumberExtension = null) {
        let formattedPhoneNumber = '';
        if (isStringNotEmpty(phoneNumber)) {
            //Filter only numbers from the input
            let cleaned = ('' + phoneNumber).replace(/\D/g, '').replace('(', '').replace(')', '').replace('-', '').replace(' ', '');

            //Check if the input is of correct
            let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

            if (match) {
                //Remove the matched extension code
                //Change this to format for any country code.
                let intlCode = (match[1] ? '+1 ' : '')
                formattedPhoneNumber = [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
            }
        }

        return formattedPhoneNumber + (isStringNotEmpty(phoneNumberExtension) ? ', ext. ' + phoneNumberExtension : '');
    };

    toFormattedMoney(moneyValue) {
        if (isNotNullOrUndefined(moneyValue)) {
            let number = moneyValue ? ('' + moneyValue).replace('$', '').replace(',', '') : 0;
            let numberValue = Number(number);
            let decPlaces = 2;
            let decSep = ".";
            let thouSep = ",";
            let dollarSign = "$";
            let sign = numberValue < 0 ? "-" : "";

            numberValue = Math.abs(numberValue);

            // guarantees that the number will have two parts
            numberValue = numberValue.toFixed(decPlaces);

            let parts = numberValue.toString().split(decSep);
            //console.log(parts)
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thouSep);
            // if (parts.length > 1) {
            //     parts[1] = (parts[1].length > desPlaces ? parts[1].substring(0, desPlaces - 1) : (parts[1].length < desPlaces ? parts[1] + '0' : parts[1]);
            // }
            // if (parts.length === 1) {
            //     let trailingCharacters = '';
            //     for (let i = 0; i < decPlaces; i++) {
            //         trailingCharacters += '0';
            //     }
            //     parts.push(trailingCharacters);
            // }
            return sign + dollarSign + parts.join(decSep);
            // let i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
            // let j = i.length;
            // j = j > 3 ? j % 3 : 0;
            // let dollarSign = "$";

            // return sign + dollarSign +
            //     (j ? i.substr(0, j) + thouSep : "") +
            //     i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
            //     (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
        } else {
            return '';
        }
    };

    toFormattedDecimal(decimalValue, decPlacesValue = null, decSepValue = null, thouSepValue = null) {
        if (!isNaN(decimalValue) && isNotNullOrUndefined(decimalValue)) {
            let number = isNotNullOrUndefined(decimalValue) ? decimalValue : 0;
            let numberValue = Number(number);
            let decPlaces = isNotNullOrUndefined(decPlacesValue) && !isNaN(decPlacesValue) ? decPlacesValue : 2;
            let decSep = isNotNullOrUndefined(decSepValue) ? decSepValue : ".";
            let thouSep = isNotNullOrUndefined(thouSepValue) ? thouSepValue : ",";

            decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces;
            decSep = typeof decSep === "undefined" ? "." : decSep;
            thouSep = typeof thouSep === "undefined" ? "," : thouSep;
            let sign = numberValue < 0 ? "-" : "";

            numberValue = Math.abs(numberValue);

            // guarantees that the number will have two parts
            numberValue = numberValue.toFixed(decPlaces);

            let parts = numberValue.toString().split(decSep);
            //console.log(parts)
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thouSep);
            // if (parts.length > 1) {
            //     parts[1] = (parts[1].length > desPlaces ? parts[1].substring(0, desPlaces - 1) : (parts[1].length < desPlaces ? parts[1] + '0' : parts[1]);
            // }
            // if (parts.length === 1) {
            //     let trailingCharacters = '';
            //     for (let i = 0; i < decPlaces; i++) {
            //         trailingCharacters += '0';
            //     }
            //     parts.push(trailingCharacters);
            // }
            return sign + parts.join(decSep);
            // let i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
            // let j = i.length;
            // j = j > 3 ? j % 3 : 0;
            // let dollarSign = "$";

            // return sign + dollarSign +
            //     (j ? i.substr(0, j) + thouSep : "") +
            //     i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
            //     (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
        } else {
            return '';
        }
    };

    // takes decimal value, multiplies by 100 and adds the percentage symbol
    toFormattedPercentage(value) {
        if (!isNaN(value) && isNotNullOrUndefined(value)) {
            return `${(value * 100).toFixed(2)}%`;
        } else {
            return '';
        }
    };

    pad(n) {
        return (n < 10 ? "0" + n : n);
    };

    // takes decimal value for number of minutes and converts it into days, hours, minutes
    toFormattedMinutesCountdown(value) {
        if (!isNaN(value) && isNotNullOrUndefined(value)) {
            let minutes = value;
            let days = Math.floor(minutes / 24 / 60);
            let hoursLeft = Math.floor((minutes) - (days * 1440));
            let hours = Math.floor(hoursLeft / 60);
            let remainingMinutes = Math.floor((hoursLeft) - (hours * 60));

            let eta = (days > 0 ? this.pad(days) + 'd' : '') + (hours > 0 ? (days > 0 ? ' ' : '') + this.pad(hours) + 'h' : '') + (remainingMinutes > 0 ? (days > 0 || hours > 0 ? ' ' : '') + this.pad(remainingMinutes) + 'm' : '');
            //let eta = this.pad(days) + ":" + this.pad(hours) + ":" + this.pad(remainingMinutes);
            if (minutes === 0) {
                eta = "Completed";
            }

            return eta;
        } else {
            return '';
        }
    };

    // takes decimal value for number of minutes and converts it into days, hours, minutes, seconds
    toFormattedSecondsCountdown(value) {
        if (!isNaN(value) && isNotNullOrUndefined(value)) {
            let seconds = value;
            let days = Math.floor(seconds / 24 / 60 / 60);
            let hoursLeft = Math.floor((seconds) - (days * 86400));
            let hours = Math.floor(hoursLeft / 3600);
            let minutesLeft = Math.floor((hoursLeft) - (hours * 3600));
            let minutes = Math.floor(minutesLeft / 60);
            let remainingSeconds = Math.floor((minutesLeft) - (minutes * 60));
            //let remainingSeconds = seconds % 60;

            let eta = (days > 0 ? this.pad(days) + 'd' : '') + (hours > 0 ? (days > 0 ? ' ' : '') + this.pad(hours) + 'h' : '') + (minutes > 0 ? (days > 0 || hours > 0 ? ' ' : '') + this.pad(minutes) + 'm' : '') + (remainingSeconds > 0 ? (days > 0 || hours > 0 || minutes > 0 ? ' ' : '') + this.pad(remainingSeconds) + 's' : '');
            //let eta = this.pad(days) + ":" + this.pad(hours) + ":" + this.pad(minutes) + ":" + this.pad(remainingSeconds);
            if (seconds === 0) {
                eta = "Completed";
            }

            return eta;
        } else {
            return '';
        }
    };

    toFormattedTimeRange(startTime, endTime, timeZone) {
        return `${this.toFormattedString("MomentTime", startTime, null, timeZone)} - ${this.toFormattedString("MomentTime", endTime, null, timeZone)}`;
    };

    toFormattedAddress(address) {
        if (isObjectNotEmpty(address)) {
            let streetAddress1 = address.streetAddress1;
            let streetAddress2 = address.streetAddress2;
            let city = address.city;
            let state = address.state;
            let postalCode = address.postalCode;
            let country = address.country;

            return `${isStringNotEmpty(streetAddress1) ? streetAddress1 + ' ' : ''}${isStringNotEmpty(streetAddress2) ? streetAddress2 + ' ' : ''}${isStringNotEmpty(city) ? city + ', ' : ''}${isStringNotEmpty(state) ? state + ' ' : ''}${isStringNotEmpty(postalCode) ? postalCode + ' ' : ''}${isStringNotEmpty(country) ? country : ''}`;
        } else {
            return '';
        }
    };

    toFormattedContactInfo(contact) {
        if (isObjectNotEmpty(contact)) {
            let firstName = contact.firstName;
            let lastName = contact.lastName;
            let email = contact.email;
            let phone = contact.phone;
            let phoneExt = contact.phoneExt;

            return (
                <>
                    {isStringNotEmpty(firstName) || isStringNotEmpty(lastName) ? (<>{firstName} {lastName}<br /></>) : null}
                    {isStringNotEmpty(email) ? (<>{email}<br /></>) : null}
                    {isStringNotEmpty(phone) ? this.toFormattedPhoneNumber(phone, phoneExt) : null}
                </>
            );
        } else {
            return '';
        }
    };

    toFormattedString(dataType, value, units = null, timeZone = null) {
        if (isNotNullOrUndefined(value)) {
            let formattedValue = null;
            if (dataType === 'String') {
                formattedValue = value;
            } else if (dataType === 'PhoneNumber') {
                formattedValue = this.toFormattedPhoneNumber(value, units);
            } else if (dataType === 'FirstNameLastName') {
                formattedValue = this.toFirstNameLastName(value);
            } else if (dataType === 'Temperature') {
                formattedValue = `${value} ${String.fromCharCode(176)}${Enums.TemperatureUnits.getValueByName(units)}`;
            } else if (dataType === 'Weight') {
                formattedValue = `${value} ${Enums.WeightUnits.getValueByName(units)}`;
            } else if (dataType === 'Length') {
                formattedValue = `${value} ${Enums.LengthUnits.getValueByName(units)}`;
            } else if (dataType === 'Distance') {
                formattedValue = `${value} ${Enums.DistanceUnits.getValueByName(units)}`;
            } else if (dataType === 'Volume') {
                formattedValue = `${value} ${Enums.VolumeUnits.getValueByName(units)}`;
            } else if (dataType === 'Time') {
                formattedValue = `${value} ${Enums.TimeUnits.getValueByName(units)}`;
            } else if (dataType === 'UnitPackagingCount') {
                formattedValue = `${value} ${Enums.UnitPackaging.getValueByName(units)}`;
            } else if (dataType === 'BulkPackagingCount') {
                formattedValue = `${value} ${Enums.BulkPackaging.getValueByName(units)}`;
            } else if (dataType === 'MinutesCountdown') {
                formattedValue = this.toFormattedMinutesCountdown(value);
            } else if (dataType === 'SecondsCountdown') {
                formattedValue = this.toFormattedSecondsCountdown(value);
            } else if (dataType === 'Boolean') {
                formattedValue = this.toYesNo(value);
            } else if (dataType === 'Percentage') {
                formattedValue = this.toFormattedPercentage(value);
            } else if (dataType === 'Money') {
                formattedValue = `${this.toFormattedMoney(value)} ${Enums.Currencies.getValueByName(units)}`;
            } else if (dataType === 'Decimal') {
                formattedValue = this.toFormattedDecimal(value, null, null, null);
            } else if (dataType === 'Integer') {
                formattedValue = this.toFormattedDecimal(value, 0, null, null);
            } else if (dataType === 'MomentDateTime') {
                if (isStringNotEmpty(timeZone)) {
                    formattedValue = new MomentDate().fromUtcToTimeZone(value, timeZone);
                } else {
                    formattedValue = new MomentDate().fromUtcToDeviceTimeZone(value);
                }
            } else if (dataType === 'MomentDate') {
                if (isStringNotEmpty(timeZone)) {
                    formattedValue = new MomentDate().fromUtcToTimeZoneDateOnly(value, timeZone);
                } else {
                    formattedValue = new MomentDate().fromUtcToDeviceTimeZoneDateOnly(value);
                }
            } else if (dataType === 'MomentTime') {
                if (isStringNotEmpty(timeZone)) {
                    formattedValue = new MomentDate().fromUtcToTimeZoneTimeOnly(value, timeZone);
                } else {
                    formattedValue = new MomentDate().fromUtcToDeviceTimeZoneTimeOnly(value);
                }
            } else if (dataType === 'MomentTimeHideTimeZone') {
                if (isStringNotEmpty(timeZone)) {
                    formattedValue = new MomentDate().fromUtcToTimeZoneTimeOnlyHideTimeZone(value, timeZone);
                } else {
                    formattedValue = new MomentDate().fromUtcToDeviceTimeZoneTimeOnlyHideTimeZone(value);
                }
            } else if (dataType === 'AppointmentType') {
                formattedValue = Enums.AppointmentTypes.getValueByName(value);
            } else if (dataType === 'StopType') {
                formattedValue = Enums.StopTypes.getValueByName(value);
            } else if (dataType === 'EquipmentSpecific') {
                formattedValue = Enums.EquipmentSpecifics.getValueByName(value);
            } else if (dataType === 'IRISFee') {
                formattedValue = Enums.IRISFees.getValueByName(value);
            } else if (dataType === 'Accessorial') {
                formattedValue = Enums.Accessorials.getValueByName(value);
            } else if (dataType === 'AccountType') {
                formattedValue = Enums.AccountTypes.getValueByName(value);
            } else if (dataType === 'Adjustment') {
                formattedValue = Enums.Adjustments.getValueByName(value);
            } else if (dataType === 'BulkPackaging') {
                formattedValue = Enums.BulkPackaging.getValueByName(value);
            } else if (dataType === 'Currency') {
                formattedValue = Enums.Currencies.getValueByName(value);
            } else if (dataType === 'LineItemName') {
                formattedValue = Enums.LineItemNames.getValueByName(value);
            } else if (dataType === 'LineItemRule') {
                formattedValue = Enums.LineItemRules.getValueByName(value);
            } else if (dataType === 'LineItemType') {
                formattedValue = Enums.LineItemTypes.getValueByName(value);
            } else if (dataType === 'LoadFee') {
                formattedValue = Enums.LoadFees.getValueByName(value);
            } else if (dataType === 'LoadRequirement') {
                formattedValue = Enums.LoadRequirements.getValueByName(value);
            } else if (dataType === 'StopFee') {
                formattedValue = Enums.StopFees.getValueByName(value);
            } else if (dataType === 'UnitPackaging') {
                formattedValue = Enums.UnitPackaging.getValueByName(value);
            } else if (dataType === 'IRISServiceType') {
                formattedValue = Enums.IRISServiceTypes.getValueByName(value);
            } else if (dataType === 'Count') {
                formattedValue = Enums.Counts.getValueByName(value);
            } else if (dataType === 'YesNo') {
                formattedValue = Enums.YesNo.getValueByName(value);
            } else if (dataType === 'LocationType') {
                formattedValue = Enums.LocationTypes.getValueByName(value);
            } else if (dataType === 'DayOfWeek') {
                formattedValue = Enums.DayOfWeek.getValueByName(value);
            } else if (dataType === 'TimeUnit') {
                formattedValue = Enums.TimeUnits.getValueByName(value);
            } else if (dataType === 'DistanceUnit') {
                formattedValue = Enums.DistanceUnits.getValueByName(value);
            } else if (dataType === 'WeightUnit') {
                formattedValue = Enums.WeightUnits.getValueByName(value);
            } else if (dataType === 'LengthUnit') {
                formattedValue = Enums.LengthUnits.getValueByName(value);
            } else if (dataType === 'VolumeUnit') {
                formattedValue = Enums.VolumeUnits.getValueByName(value);
            } else if (dataType === 'ClaimStatus') {
                formattedValue = Enums.ClaimStatuses.getValueByName(value);
            } else if (dataType === 'BOLStatus') {
                formattedValue = Enums.BOLStatuses.getValueByName(value);
            } else if (dataType === 'CMSChannel') {
                formattedValue = Enums.CMSChannels.getValueByName(value);
            } else if (dataType === 'DocumentEntityType') {
                formattedValue = Enums.DocumentEntityTypes.getValueByName(value);
            } else if (dataType === 'DocumentReviewStatus') {
                formattedValue = Enums.DocumentReviewStatuses.getValueByName(value);
            } else if (dataType === 'DocumentVisibility') {
                formattedValue = Enums.DocumentVisibilities.getValueByName(value);
            } else if (dataType === 'DocumentType') {
                formattedValue = Enums.DocumentTypes.getValueByName(value);
            } else if (dataType === 'IRISServiceType') {
                formattedValue = Enums.IRISServiceTypes.getValueByName(value);
            } else if (dataType === 'EntitlementAction') {
                formattedValue = Enums.EntitlementActions.getValueByName(value);
            } else if (dataType === 'InvoiceAccountType') {
                formattedValue = Enums.InvoiceAccountTypes.getValueByName(value);
            } else if (dataType === 'InvoiceSourceAccountType') {
                formattedValue = Enums.InvoiceSourceAccountTypes.getValueByName(value);
            } else if (dataType === 'InvoiceLineItemStatus') {
                formattedValue = Enums.InvoiceLineItemStatuses.getValueByName(value);
            } else if (dataType === 'InvoiceStatus') {
                formattedValue = Enums.InvoiceStatuses.getValueByName(value);
            } else if (dataType === 'InvoiceApprovalStatus') {
                formattedValue = Enums.InvoiceApprovalStatuses.getValueByName(value);
            } else if (dataType === 'InvoiceQuantityUnit') {
                formattedValue = Enums.InvoiceQuantityUnits.getValueByName(value);
            } else if (dataType === 'TemperatureUnit') {
                formattedValue = Enums.TemperatureUnits.getValueByName(value);
            } else if (dataType === 'UnitOfMeasure') {
                formattedValue = Enums.UnitOfMeasure.getValueByName(value);
            } else if (dataType === 'TruckOwnerType') {
                formattedValue = Enums.TruckOwnerTypes.getValueByName(value);
            } else if (dataType === 'LoadStatus') {
                formattedValue = Enums.LoadStatuses.getValueByName(value);
            } else if (dataType === 'ShipperLoadStatus') {
                formattedValue = Enums.ShipperLoadStatuses.getValueByName(value);
            } else if (dataType === 'CarrierLoadStatus') {
                formattedValue = Enums.CarrierLoadStatuses.getValueByName(value);
            } else if (dataType === 'StopStatus') {
                formattedValue = Enums.StopStatuses.getValueByName(value);
            } else if (dataType === 'CancellationEntityType') {
                formattedValue = Enums.CancellationEntityTypes.getValueByName(value);
            } else if (dataType === 'CancellationReason') {
                formattedValue = Enums.CancellationReasons.getValueByName(value);
            } else if (dataType === 'AssetOutOfOrderReason') {
                formattedValue = Enums.AssetOutOfOrderReasons.getValueByName(value);
            } else if (dataType === 'AssetStatus') {
                formattedValue = Enums.AssetStatuses.getValueByName(value);
            } else if (dataType === 'AssetType') {
                formattedValue = Enums.AssetTypes.getValueByName(value);
            } else if (dataType === 'CreditReason') {
                formattedValue = Enums.CreditReasons.getValueByName(value);
            } else if (dataType === 'PaymentMethod') {
                formattedValue = Enums.PaymentMethods.getValueByName(value);
            } else if (dataType === 'PaymentStatus') {
                formattedValue = Enums.PaymentStatuses.getValueByName(value);
            } else if (dataType === 'NotificationSeverity') {
                formattedValue = Enums.NotificationSeverities.getValueByName(value);
            } else if (dataType === 'NoteEntityType') {
                formattedValue = Enums.NoteEntityTypes.getValueByName(value);
            } else if (dataType === 'MobileModeEntityType') {
                formattedValue = Enums.MobileModeEntityTypes.getValueByName(value);
            } else if (dataType === 'LoadDisplayStatus') {
                formattedValue = Enums.LoadDisplayStatuses.getValueByName(value);
            } else if (dataType === 'ShipperLoadDisplayStatus') {
                formattedValue = Enums.ShipperLoadDisplayStatuses.getValueByName(value);
            } else if (dataType === 'CarrierLoadDisplayStatus') {
                formattedValue = Enums.CarrierLoadDisplayStatuses.getValueByName(value);
            } else if (dataType === 'NoteVisibility') {
                formattedValue = Enums.NoteVisibilities.getValueByName(value);
            } else if (dataType === 'AccountRegistrationReviewStatus') {
                formattedValue = Enums.AccountRegistrationReviewStatuses.getValueByName(value);
            } else if (dataType === 'PreferredPaymentMethod') {
                formattedValue = Enums.PreferredPaymentMethods.getValueByName(value);
            } else if (dataType === 'ShipperCancellationReason') {
                formattedValue = Enums.ShipperCancellationReasons.getValueByName(value);
            } else if (dataType === 'CarrierCancellationReason') {
                formattedValue = Enums.CarrierCancellationReasons.getValueByName(value);
            } else if (dataType === 'StaffCancellationReason') {
                formattedValue = Enums.StaffCancellationReasons.getValueByName(value);
            } else if (dataType === 'MobileModeEntityType') {
                formattedValue = Enums.MobileModeEntityTypes.getValueByName(value);
            } else if (dataType === 'EntityType') {
                formattedValue = Enums.EntityTypes.getValueByName(value);
            } else if (dataType === 'ServiceType') {
                formattedValue = Enums.ServiceTypes.getValueByName(value);
            } else if (dataType === 'BillingFrequency') {
                formattedValue = Enums.BillingFrequencies.getValueByName(value);
            } else if (dataType === 'SystemBillingFrequency') {
                formattedValue = Enums.SystemBillingFrequencies.getValueByName(value);
            } else if (dataType === 'LoadBillingFrequency') {
                formattedValue = Enums.LoadBillingFrequencies.getValueByName(value);
            } else if (dataType === 'LoadServiceType') {
                formattedValue = Enums.LoadServiceTypes.getValueByName(value);
            } else if (dataType === 'LoadingType') {
                formattedValue = Enums.LoadingTypes.getValueByName(value);
            } else if (dataType === 'UnloadingType') {
                formattedValue = Enums.UnloadingTypes.getValueByName(value);
            } else if (dataType === 'DriverRequirement') {
                formattedValue = Enums.DriverRequirements.getValueByName(value);
            } else if (dataType === 'RestStopType') {
                formattedValue = Enums.RestStopTypes.getValueByName(value);
            } else if (dataType === 'AccountRole') {
                formattedValue = Enums.AccountRoles.getValueByName(value);
            } else if (dataType === 'InvoiceType') {
                formattedValue = Enums.InvoiceTypes.getValueByName(value);
            } else if (dataType === 'PODStatus') {
                formattedValue = Enums.PODStatuses.getValueByName(value);
            } else {
                formattedValue = value;
            }

            return formattedValue;
        } else {
            return '';
        }
    };
}