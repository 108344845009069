import React, { useEffect, useState, useMemo } from 'react';
import { Col, Row, Button, Select, Input, InputNumber, Alert, Radio, Switch, Timeline, Drawer } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import Form from '../Form/Form';
import Fieldset from '../FormFieldset/FormFieldset';
import FormItem from '../FormItem/FormItem';
import FormItemFile from '../FormItemFile/FormItemFile';
import Enums from '../../shared/enums';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import UploadFile from "../UploadFile/UploadFile";
import { DeleteOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import DataCollapsableRow from '../DataCollapsableRow/DataCollapsableRow';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import StringFormatter from '../../shared/stringFormatter';
import { ErrorMessage } from '@hookform/error-message';
import DataListRow from '../DataListRow/DataListRow';
import Header from '../Header/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRoute } from '@fortawesome/free-solid-svg-icons';
import classes from './NewLoadStops.module.scss';
import { isBooleanTrue, isListNotEmpty, isNotNullOrUndefined, isNumberNotEmpty, isObjectNotEmpty, isStringEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import LoadStopUtils from '../../api/utils/loadStopUtils';
import DataRow from '../DataRow/DataRow';

const { Option } = Select;
const { TextArea } = Input;
const stringFormatter = new StringFormatter();

const NewLoadStops = ({ shipperData, pickUpStops, setPickUpStops, dropOffStops, setDropOffStops, completedSteps, setCompletedSteps, previousStepAction, previousStepActionName, nextStepAction, nextStepActionName, docFiles, setDocFiles, dontRequireLoadSpecificInformation, hideLoadSpecificInformation }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    //#endregion
    //#region Enums

    const unitPackagingOptions = Enums.UnitPackaging.selectListOptions();
    const bulkPackagingOptions = Enums.BulkPackaging.selectListOptions();

    //#endregion
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const methods2 = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            existingBOLNumber: null,
            newBOLNumber: ''
        }
    });
    const methods3 = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            loadingType: null,
            hasLumperFee: false,
            lumperFee: null,
            hasDriverAssist: false,
            driverAssist: null,
            specialInstructions: ''
        }
    });
    const methods4 = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            commodityId: null,
            unitPackagingInitCount: null,
            unitPackaging: null,
            bulkPackagingInitCount: null,
            bulkPackaging: null
        }
    });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const commodities = useSelector(state => state.orchestrator.commodities);
    const entityType = useSelector(state => state.auth.entityType);
    const entityId = useSelector(state => state.auth.entityId);

    //#endregion
    //#region useStates

    const [selectedRecord, setSelectedRecord] = useState(null);
    const [showAddStopBOL, setShowAddStopBOL] = useState(false);
    const [showEditStopLoadingSpecifics, setShowEditStopLoadingSpecifics] = useState(false);
    const [showAddStopCommodity, setShowAddStopCommodity] = useState(false);
    const [lumperFeeDisabled, setLumperFeeDisabled] = useState(true);
    const [driverAssistDisabled, setDriverAssistDisabled] = useState(true);
    const [dropOffsEnabled, setDropOffsEnabled] = useState(false);
    const [bolNumberList, setBOLNumberList] = useState([]);
    const [bolDocFiles, setBOLDocFiles] = useState([]);
    const [showNewBOLNumber, setShowNewBOLNumber] = useState(false);

    //#endregion
    //#region toggles

    const toggleAddStopBOL = () => {
        // reset form
        setBOLDocFiles([]);
        setShowNewBOLNumber(false);
        methods2.setValue(`existingBOLNumber`, null);
        methods2.setValue(`newBOLNumber`, '');
        methods2.clearErrors();

        setShowAddStopBOL(!showAddStopBOL);
    };

    const toggleEditStopLoadingSpecifics = () => {
        setDriverAssistDisabled(true);
        setLumperFeeDisabled(true);

        // reset form
        methods3.setValue('loadingType', null);
        methods3.setValue('hasLumperFee', false);
        methods3.setValue('lumperFee', null);
        methods3.setValue('hasDriverAssist', false);
        methods3.setValue('driverAssist', null);
        methods3.setValue('specialInstructions', '');
        methods3.clearErrors();

        setShowEditStopLoadingSpecifics(!showEditStopLoadingSpecifics);
    };

    const toggleAddStopCommodity = () => {
        // reset form
        methods4.setValue('commodityId', null);
        methods4.setValue('unitPackagingInitCount', null);
        methods4.setValue('unitPackaging', null);
        methods4.setValue('bulkPackagingInitCount', null);
        methods4.setValue('bulkPackaging', null);
        methods4.clearErrors();

        setShowAddStopCommodity(!showAddStopCommodity);
    };

    //#endregion
    //#region submits and validation

    const validateStops = (pickUps, dropOffs) => {
        let hasErrors = false;
        methods.clearErrors();

        if (isListNotEmpty(pickUps) && isListNotEmpty(dropOffs)) {
            pickUps.forEach((stop, stopIndex, arr) => {
                let errors = {};
                if (isStringEmpty(stop.loadingType)) {
                    errors.missingLoadingType = `Pick-Up${arr.length > 1 ? ' ' + (stopIndex + 1) : ''} is missing a Loading Type.`;
                    hasErrors = true;
                }

                if (isObjectNotEmpty(errors)) {
                    methods.setError(`pickUpStops-${stopIndex}`, { types: { ...errors } });
                }
            });

            dropOffs.forEach((stop, stopIndex, arr) => {
                let errors = {};
                if (isStringEmpty(stop.loadingType)) {
                    errors.missingLoadingType = `Drop-Off${arr.length > 1 ? ' ' + (stopIndex + 1) : ''} is missing an Unloading Type.`;
                    hasErrors = true;
                }
                if ((stop.bolNumbers === undefined || stop.bolNumbers === null || stop.bolNumbers.length !== 1) && (dontRequireLoadSpecificInformation === false && hideLoadSpecificInformation === false)) {
                    errors.missingBOLNumber = `Drop-Off${arr.length > 1 ? ' ' + (stopIndex + 1) : ''} must have 1 BOL Number.`;
                    hasErrors = true;
                }

                if (isObjectNotEmpty(errors)) {
                    methods.setError(`dropOffStops-${stopIndex}`, { types: { ...errors } });
                }
            });

            // validate BOL Numbers
            // the Add BOL functionality will force the numbr of BOL Numbers to match the number of drop-off stops
            if (bolNumberList.length !== dropOffs.length && (dontRequireLoadSpecificInformation === false && hideLoadSpecificInformation === false)) {
                methods.setError('dropOffStops', { type: 'badBOLNumberCount', message: 'There does not appear to be a unique BOL Number for each Drop-Off. Please resolve the issue and try again.' });
                hasErrors = true;
            }

            // TODO: validate the commodities - skipping this for now until we have an inventory system
        } else {
            if (pickUps === undefined || pickUps === null || pickUps.length === 0) {
                methods.setError('pickUpStops', { type: 'required', message: 'There must be at least 1 Pick-Up. Go back to the Schedule step to add a Pick-Up.' });
                hasErrors = true;
            }
            if (dropOffs === undefined || dropOffs === null || dropOffs.length === 0) {
                methods.setError('dropOffStops', { type: 'required', message: 'There must be at least 1 Drop-Off. Go back to the Schedule step to add a Drop-Off.' });
                hasErrors = true;
            }
        }

        return !hasErrors;
    };

    const onAddStopBOL = (data) => {
        const newBOLNumber = data.newBOLNumber;
        const existingBOLNumber = data.existingBOLNumber;

        methods2.clearErrors();
        methods.clearErrors();

        let bolNumber = null;
        if (isStringNotEmpty(existingBOLNumber) && existingBOLNumber !== 'NEW') {
            bolNumber = existingBOLNumber;
        } else if (isStringNotEmpty(newBOLNumber)) {
            bolNumber = newBOLNumber;
        }

        if (isStringNotEmpty(bolNumber)) {
            if (isListNotEmpty(bolNumberList) && isListNotEmpty(dropOffStops)) {
                if (bolNumberList.length > dropOffStops.length) {
                    methods2.setError('existingBOLNumber', { type: 'validateBOLCount', message: 'You cannot add more BOL Numbers than the total number of Drop-Offs for this Load.' });
                }
            }

            if (selectedRecord !== undefined && selectedRecord !== null) {
                let stopBOLNumberList = [];
                if (isListNotEmpty(selectedRecord.bolNumbers)) {
                    stopBOLNumberList = [...selectedRecord.bolNumbers];
                    if (stopBOLNumberList.find(b => b === bolNumber) === undefined) {
                        stopBOLNumberList.push(bolNumber);
                    } else {
                        methods2.setError('newBOLNumber', { type: "duplicate", message: 'This BOL Number has already been added to this location.' });
                        return;
                    }
                } else {
                    stopBOLNumberList.push(bolNumber);
                }

                if (isListNotEmpty(bolDocFiles)) {
                    let newFiles = [];
                    bolDocFiles.forEach((file) => {
                        // console.log('file:');
                        // console.log(file);
                        let fileJson = { stopId: selectedRecord.id, bolNumber: bolNumber, file: file };
                        newFiles.push(fileJson);
                    });
                    setDocFiles(oldFiles => [...oldFiles, ...newFiles]);
                    setBOLDocFiles([]);
                }

                if (selectedRecord.stopType === 'PICK_UP') {
                    let existingList = [...pickUpStops];
                    const index = existingList.findIndex(obj => obj.id === selectedRecord.id);
                    // only update if the record exists in the list
                    if (index !== -1) {
                        const updatedValues = {
                            bolNumbers: [...stopBOLNumberList]
                        };

                        const updatedRecord = {
                            ...existingList[index],
                            ...updatedValues
                        };

                        let updatedRecords = [
                            ...existingList.slice(0, index), // everything before current obj
                            updatedRecord,
                            ...existingList.slice(index + 1), // everything after current obj
                        ];

                        if (dropOffStops.length === 1) {
                            // if there is only one drop-off stop, set the value for the BOL Number
                            let existingDropOffList = [...dropOffStops];
                            const updatedDropOffValues = {
                                bolNumbers: [...stopBOLNumberList]
                            };

                            const updatedDropOffRecord = {
                                ...existingDropOffList[0],
                                ...updatedDropOffValues
                            };
                            const updatedDropOffRecords = [
                                ...existingDropOffList.slice(0, 0), // everything before current obj
                                updatedDropOffRecord,
                                ...existingDropOffList.slice(1), // everything after current obj
                            ];

                            setDropOffStops([...updatedDropOffRecords]);

                            // update the other pick-up stops too since there is only one drop-off stop so all the pick-up stops will have the same BOL Number
                            if (pickUpStops.length > 1) {
                                pickUpStops.forEach((pickUpStop) => {
                                    if (pickUpStop.id !== selectedRecord.id) {
                                        const otherPickUpStopIndex = updatedRecords.findIndex(obj => obj.id === pickUpStop.id);
                                        if (otherPickUpStopIndex !== -1) {
                                            updatedRecords[otherPickUpStopIndex].bolNumbers = [...stopBOLNumberList];
                                        }
                                    }
                                })
                            }
                        }

                        setPickUpStops(updatedRecords);
                    }
                } else if (selectedRecord.stopType === 'DROP_OFF') {
                    let existingList = [...dropOffStops];
                    const index = existingList.findIndex(obj => obj.id === selectedRecord.id);
                    // only update if the record exists in the list
                    if (index !== -1) {
                        const updatedValues = {
                            bolNumbers: [...stopBOLNumberList]
                        };

                        const updatedRecord = {
                            ...existingList[index],
                            ...updatedValues
                        };
                        const updatedRecords = [
                            ...existingList.slice(0, index), // everything before current obj
                            updatedRecord,
                            ...existingList.slice(index + 1), // everything after current obj
                        ];

                        setDropOffStops([...updatedRecords]);
                    }
                }

                toggleAddStopBOL();
                setSelectedRecord(null);
            } else {
                methods2.setError('existingBOLNumber', { type: 'required', message: 'Required Field' });
                return;
            }
        } else {
            methods2.setError('existingBOLNumber', { type: 'required', message: 'Required Field' });
            return;
        }
    };

    const onRemoveStopBOL = (stop, bolNumber, index) => {
        if (stop !== undefined && stop !== null) {
            let stopBOLNumberList = [];
            if (isListNotEmpty(stop.bolNumbers)) {
                stopBOLNumberList = [...stop.bolNumbers];
                stopBOLNumberList.splice(index, 1);

                // also remove the BOL Files for this BOL Number for this stop
                let filteredArray = docFiles.filter(d => d.stopId !== stop.id && d.bolNumber !== bolNumber);
                setDocFiles([...filteredArray]);
            }
            if (stop.stopType === 'PICK_UP') {
                let existingList = [...pickUpStops];
                const index = existingList.findIndex(obj => obj.id === stop.id);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        bolNumbers: [...stopBOLNumberList]
                    };

                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    setPickUpStops(updatedRecords);

                    if (dropOffStops.length === 1) {
                        // if there is only one drop-off stop, set the value for the BOL Number
                        let existingDropOffList = [...dropOffStops];
                        const updatedDropOffValues = {
                            bolNumbers: [...stopBOLNumberList]
                        };

                        const updatedDropOffRecord = {
                            ...existingDropOffList[0],
                            ...updatedDropOffValues
                        };
                        const updatedDropOffRecords = [
                            ...existingDropOffList.slice(0, 0), // everything before current obj
                            updatedDropOffRecord,
                            ...existingDropOffList.slice(1), // everything after current obj
                        ];

                        setDropOffStops([...updatedDropOffRecords]);
                    }
                }
            } else if (stop.stopType === 'DROP_OFF') {
                let existingList = [...dropOffStops];
                const index = existingList.findIndex(obj => obj.id === stop.id);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        bolNumbers: [...stopBOLNumberList]
                    };
                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    setDropOffStops([...updatedRecords]);
                }
            }
        } else {
            return;
        }
    };

    const onEditStopLoadingSpecifics = (data) => {
        const loadingType = data.loadingType;
        const specialInstructions = data.specialInstructions;
        const hasLumperFee = data.hasLumperFee;
        const lumperFee = data.lumperFee;
        const hasDriverAssist = data.hasDriverAssist;
        const driverAssist = data.driverAssist;

        const updatedValues = {
            loadingType: loadingType,
            hasLumperFee: hasLumperFee,
            lumperFee: lumperFee,
            hasDriverAssist: hasDriverAssist,
            driverAssist: driverAssist,
            specialInstructions: specialInstructions
        };

        if (selectedRecord !== undefined && selectedRecord !== null) {
            if (selectedRecord.stopType === 'PICK_UP') {
                let existingList = [...pickUpStops];
                const index = existingList.findIndex(obj => obj.id === selectedRecord.id);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        ...updatedValues
                    };

                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    setPickUpStops(updatedRecords);
                    methods.clearErrors();
                }
            } else if (selectedRecord.stopType === 'DROP_OFF') {
                let existingList = [...dropOffStops];
                const index = existingList.findIndex(obj => obj.id === selectedRecord.id);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        ...updatedValues
                    };
                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    setDropOffStops(updatedRecords);
                    methods.clearErrors();
                }
            }

            toggleEditStopLoadingSpecifics();
            setSelectedRecord(null);
        } else {
            return;
        }
    };

    const onAddStopCommodity = (data) => {
        const commodityId = data.commodityId;
        const unitPackagingInitCount = data.unitPackagingInitCount;
        const unitPackaging = data.unitPackaging;
        const bulkPackagingInitCount = data.bulkPackagingInitCount;
        const bulkPackaging = data.bulkPackaging;

        methods4.clearErrors();

        let name = null;
        if (isListNotEmpty(commodities)) {
            let commodity = commodities.find(c => c.id === commodityId);
            if (commodity !== undefined && commodity !== null) {
                name = commodity.name;
            }
        }

        let newStopCommodity = {
            commodityId: commodityId,
            name: name
        };

        if (unitPackagingInitCount !== undefined && unitPackagingInitCount !== null && unitPackagingInitCount > 0) {
            if (unitPackaging === undefined || unitPackaging === null) {
                methods4.setError('unitPackaging', { type: "validate", message: 'You must provide a Unit of Measure/Unit Packaging if you are going to provide a Unit Quantity for this Commodity.' });
                return;
            } else {
                newStopCommodity.unitPackagingInitCount = unitPackagingInitCount;
                newStopCommodity.unitPackaging = unitPackaging;
            }
        } else if ((unitPackagingInitCount === undefined || unitPackagingInitCount === null || unitPackagingInitCount === 0) && (unitPackaging !== undefined && unitPackaging !== null)) {
            methods4.setError('unitPackagingInitCount', { type: "validate", message: 'You must provide a Unit Quantity greater than 0 if you are going to provide a Unit of Measure/Unit Packaging for this Commodity.' });
            return;
        }

        if (bulkPackagingInitCount !== undefined && bulkPackagingInitCount !== null && bulkPackagingInitCount > 0) {
            if (bulkPackaging === undefined || bulkPackaging === null) {
                methods4.setError('bulkPackaging', { type: "validate", message: 'You must provide Bulk Packaging if you are going to provide a Bulk Quantity for this Commodity.' });
                return;
            } else {
                newStopCommodity.bulkPackagingInitCount = bulkPackagingInitCount;
                newStopCommodity.bulkPackaging = bulkPackaging;
            }
        } else if ((bulkPackagingInitCount === undefined || bulkPackagingInitCount === null || bulkPackagingInitCount === 0) && (bulkPackaging !== undefined && bulkPackaging !== null)) {
            methods4.setError('bulkPackagingInitCount', { type: "validate", message: 'You must provide a Bulk Quantity greater than 0 if you are going to provide Bulk Packaging for this Commodity.' });
            return;
        }

        //console.log(newStopCommodity);

        if (selectedRecord !== undefined && selectedRecord !== null) {
            let stopCommodities = [];
            if (isListNotEmpty(selectedRecord.commodities)) {
                stopCommodities = [...selectedRecord.commodities];
                if (stopCommodities.find(c => c.commodityId === commodityId && c.bulkPackaging === bulkPackaging && c.unitPackaging === unitPackaging) === undefined) {
                    stopCommodities.push(newStopCommodity);
                } else {
                    methods4.setError('commodityId', { type: "validate", message: 'This commodity has already been added to this location with the unit and bulk packaging you provided.' });
                    return;
                }
            } else {
                stopCommodities.push(newStopCommodity);
            }

            //console.log(stopCommodities);

            if (selectedRecord.stopType === 'PICK_UP') {
                let existingList = [...pickUpStops];
                const index = existingList.findIndex(obj => obj.id === selectedRecord.id);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        commodities: [...stopCommodities]
                    };

                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    setPickUpStops(updatedRecords);

                    if (dropOffStops.length === 1) {
                        // if there is only one drop-off stop, set the value for the commodities
                        let updatedPickUpRecords = updatedRecords;
                        if (isListNotEmpty(updatedPickUpRecords)) {
                            let dropOffStopCommodities = [];
                            if (updatedPickUpRecords.length === 1) {
                                if (isListNotEmpty(updatedPickUpRecords[0].commodities)) {
                                    dropOffStopCommodities = updatedPickUpRecords[0].commodities;
                                }
                            } else if (updatedPickUpRecords.length > 1) {
                                updatedPickUpRecords.forEach((pickUpStop) => {
                                    if (isListNotEmpty(pickUpStop.commodities)) {
                                        pickUpStop.commodities.forEach((stopCommodity) => {
                                            let existingDropOffStopCommodity = dropOffStopCommodities.find(c => c.commodityId === stopCommodity.commodityId && c.unitPackaging === stopCommodity.unitPackaging && c.bulkPackaging === stopCommodity.bulkPackaging);
                                            if (existingDropOffStopCommodity === undefined || existingDropOffStopCommodity === null) {
                                                dropOffStopCommodities.push(stopCommodity);
                                            } else {
                                                const existingDropOffStopCommodityIndex = dropOffStopCommodities.findIndex(c => c.commodityId === stopCommodity.commodityId && c.unitPackaging === stopCommodity.unitPackaging && c.bulkPackaging === stopCommodity.bulkPackaging);
                                                // only update if the record exists in the list
                                                if (existingDropOffStopCommodityIndex !== -1) {
                                                    const updatedExistingDropOffStopCommodity = {
                                                        ...dropOffStopCommodities[existingDropOffStopCommodityIndex]
                                                    };

                                                    if (existingDropOffStopCommodity.unitPackaging !== undefined && existingDropOffStopCommodity.unitPackaging !== null && existingDropOffStopCommodity.unitPackaging === stopCommodity.unitPackaging) {
                                                        updatedExistingDropOffStopCommodity.unitPackagingInitCount = updatedExistingDropOffStopCommodity.unitPackagingInitCount + stopCommodity.unitPackagingInitCount;
                                                    }
                                                    if (existingDropOffStopCommodity.bulkPackaging !== undefined && existingDropOffStopCommodity.bulkPackaging !== null && existingDropOffStopCommodity.bulkPackaging === stopCommodity.bulkPackaging) {
                                                        updatedExistingDropOffStopCommodity.bulkPackagingInitCount = updatedExistingDropOffStopCommodity.bulkPackagingInitCount + stopCommodity.bulkPackagingInitCount;
                                                    }

                                                    const updatedExistingDropOffStopCommodities = [
                                                        ...dropOffStopCommodities.slice(0, existingDropOffStopCommodityIndex), // everything before current obj
                                                        updatedExistingDropOffStopCommodity,
                                                        ...dropOffStopCommodities.slice(existingDropOffStopCommodityIndex + 1), // everything after current obj
                                                    ];

                                                    dropOffStopCommodities = [...updatedExistingDropOffStopCommodities];
                                                }
                                            }
                                        });
                                    }
                                });
                            }

                            let existingDropOffList = [...dropOffStops];

                            const updatedDropOffRecord = {
                                ...existingDropOffList[0],
                                commodities: [...dropOffStopCommodities]
                            };
                            const updatedDropOffRecords = [
                                ...existingDropOffList.slice(0, 0), // everything before current obj
                                updatedDropOffRecord,
                                ...existingDropOffList.slice(1), // everything after current obj
                            ];

                            setDropOffStops([...updatedDropOffRecords]);
                        }
                    }
                }
            } else if (selectedRecord.stopType === 'DROP_OFF') {
                let existingList = [...dropOffStops];
                const index = existingList.findIndex(obj => obj.id === selectedRecord.id);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        commodities: [...stopCommodities]
                    };
                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    setDropOffStops([...updatedRecords]);
                }
            }

            toggleAddStopCommodity();
            setSelectedRecord(null);
        } else {
            return;
        }
    };

    const onRemoveStopCommodity = (stop, index) => {
        if (stop !== undefined && stop !== null) {
            let stopCommodities = [];
            if (isListNotEmpty(stop.commodities)) {
                stopCommodities = [...stop.commodities];
                stopCommodities.splice(index, 1);
            }
            if (stop.stopType === 'PICK_UP') {
                let existingList = [...pickUpStops];
                const index = existingList.findIndex(obj => obj.id === stop.id);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        commodities: [...stopCommodities]
                    };

                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    setPickUpStops(updatedRecords);

                    if (dropOffStops.length === 1) {
                        // if there is only one drop-off stop, set the value for the commodities
                        let updatedPickUpRecords = updatedRecords;
                        if (isListNotEmpty(updatedPickUpRecords)) {
                            let dropOffStopCommodities = [];
                            if (updatedPickUpRecords.length === 1) {
                                if (isListNotEmpty(updatedPickUpRecords[0].commodities)) {
                                    dropOffStopCommodities = updatedPickUpRecords[0].commodities;
                                }
                            } else if (updatedPickUpRecords.length > 1) {
                                updatedPickUpRecords.forEach((pickUpStop) => {
                                    if (isListNotEmpty(pickUpStop.commodities)) {
                                        pickUpStop.commodities.forEach((stopCommodity) => {
                                            let existingDropOffStopCommodity = dropOffStopCommodities.find(c => c.commodityId === stopCommodity.commodityId && c.unitPackaging === stopCommodity.unitPackaging && c.bulkPackaging === stopCommodity.bulkPackaging);
                                            if (existingDropOffStopCommodity === undefined || existingDropOffStopCommodity === null) {
                                                dropOffStopCommodities.push(stopCommodity);
                                            } else {
                                                const existingDropOffStopCommodityIndex = dropOffStopCommodities.findIndex(c => c.commodityId === stopCommodity.commodityId && c.unitPackaging === stopCommodity.unitPackaging && c.bulkPackaging === stopCommodity.bulkPackaging);
                                                // only update if the record exists in the list
                                                if (existingDropOffStopCommodityIndex !== -1) {
                                                    const updatedExistingDropOffStopCommodity = {
                                                        ...dropOffStopCommodities[existingDropOffStopCommodityIndex]
                                                    };

                                                    if (existingDropOffStopCommodity.unitPackaging !== undefined && existingDropOffStopCommodity.unitPackaging !== null && existingDropOffStopCommodity.unitPackaging === stopCommodity.unitPackaging) {
                                                        updatedExistingDropOffStopCommodity.unitPackagingInitCount = updatedExistingDropOffStopCommodity.unitPackagingInitCount + stopCommodity.unitPackagingInitCount;
                                                    }
                                                    if (existingDropOffStopCommodity.bulkPackaging !== undefined && existingDropOffStopCommodity.bulkPackaging !== null && existingDropOffStopCommodity.bulkPackaging === stopCommodity.bulkPackaging) {
                                                        updatedExistingDropOffStopCommodity.bulkPackagingInitCount = updatedExistingDropOffStopCommodity.bulkPackagingInitCount + stopCommodity.bulkPackagingInitCount;
                                                    }

                                                    const updatedExistingDropOffStopCommodities = [
                                                        ...dropOffStopCommodities.slice(0, existingDropOffStopCommodityIndex), // everything before current obj
                                                        updatedExistingDropOffStopCommodity,
                                                        ...dropOffStopCommodities.slice(existingDropOffStopCommodityIndex + 1), // everything after current obj
                                                    ];

                                                    dropOffStopCommodities = [...updatedExistingDropOffStopCommodities];
                                                }
                                            }
                                        });
                                    }
                                });
                            }

                            let existingDropOffList = [...dropOffStops];

                            const updatedDropOffRecord = {
                                ...existingDropOffList[0],
                                commodities: [...dropOffStopCommodities]
                            };
                            const updatedDropOffRecords = [
                                ...existingDropOffList.slice(0, 0), // everything before current obj
                                updatedDropOffRecord,
                                ...existingDropOffList.slice(1), // everything after current obj
                            ];

                            setDropOffStops([...updatedDropOffRecords]);
                        }
                    }
                }
            } else if (stop.stopType === 'DROP_OFF') {
                let existingList = [...dropOffStops];
                const index = existingList.findIndex(obj => obj.id === stop.id);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        commodities: [...stopCommodities]
                    };
                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    setDropOffStops([...updatedRecords]);
                }
            }
        } else {
            return;
        }
    };

    const onSubmit = () => {
        if (validateStops(pickUpStops, dropOffStops)) {
            setCompletedSteps({ ...completedSteps, stops: true });
            nextStepAction('services');
        } else {
            return;
        }
    };

    //#endregion
    //#region onChanges

    const onChangeBOLNumber = (value) => {
        // console.log(value);

        if (value !== undefined && value !== null) {
            if (value === 'NEW') {
                setShowNewBOLNumber(true);
            } else {
                setShowNewBOLNumber(false);
            }
        } else {
            setShowNewBOLNumber(false);
        }
    };

    //#endregion
    //#region useEffects and useMemos

    useMemo(() => {
        dispatch(actionCreators.getCommodities());
    }, []);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (showEditStopLoadingSpecifics === true && isObjectNotEmpty(selectedRecord)) {
                if (isStringNotEmpty(selectedRecord.loadingType)) {
                    methods3.setValue(`loadingType`, selectedRecord.loadingType);
                }

                if (selectedRecord.hasLumperFee === true) {
                    methods3.setValue(`hasLumperFee`, true);
                    setLumperFeeDisabled(false);
                    if (selectedRecord.lumperFee !== undefined) {
                        methods3.setValue(`lumperFee`, selectedRecord.lumperFee);
                    } else if (selectedRecord.stopLocation !== undefined && selectedRecord.stopLocation !== null && selectedRecord.stopLocation.lumperFee !== undefined && selectedRecord.stopLocation.lumperFee !== null && selectedRecord.stopLocation.lumperFee > 0) {
                        methods3.setValue(`lumperFee`, selectedRecord.stopLocation.lumperFee);
                    }
                } else {
                    setLumperFeeDisabled(true);
                    methods3.setValue(`hasLumperFee`, false);
                }

                if (selectedRecord.hasDriverAssist === true) {
                    methods3.setValue(`hasDriverAssist`, true);
                    setDriverAssistDisabled(false);
                    if (selectedRecord.driverAssist !== undefined) {
                        methods3.setValue(`driverAssist`, selectedRecord.driverAssist);
                    } else if (entityType === 'STAFF') {
                        methods3.setValue(`driverAssist`, 0);
                    }
                } else {
                    setDriverAssistDisabled(true);
                    methods3.setValue(`hasDriverAssist`, false);
                }

                if (selectedRecord.specialInstructions !== undefined) {
                    methods3.setValue(`specialInstructions`, selectedRecord.specialInstructions);
                } else if (selectedRecord.stopLocation !== undefined && selectedRecord.stopLocation !== null && selectedRecord.stopLocation.specialInstructions !== undefined && selectedRecord.stopLocation.specialInstructions !== null) {
                    methods3.setValue(`specialInstructions`, selectedRecord.stopLocation.specialInstructions);
                }
            }
        }

        return () => isSubscribed = false;
    }, [showEditStopLoadingSpecifics, selectedRecord]);

    useMemo(() => {
        let loadBOLNumberList = [];
        if (isListNotEmpty(pickUpStops)) {
            pickUpStops.forEach((stop, stopIndex, arr) => {
                if (isListNotEmpty(stop.bolNumbers)) {
                    stop.bolNumbers.forEach((bolNumber) => {
                        if (loadBOLNumberList.find(b => b === bolNumber) === undefined) {
                            loadBOLNumberList.push(bolNumber);
                        }
                    });
                }
            });
        }

        if (isListNotEmpty(dropOffStops)) {
            dropOffStops.forEach((stop, stopIndex, arr) => {
                if (isListNotEmpty(stop.bolNumbers)) {
                    stop.bolNumbers.forEach((bolNumber) => {
                        if (loadBOLNumberList.find(b => b === bolNumber) === undefined) {
                            loadBOLNumberList.push(bolNumber);
                        }
                    });
                }
            });
        }

        setBOLNumberList([...loadBOLNumberList]);
    }, [pickUpStops, dropOffStops]);

    useMemo(() => {
        if (isObjectNotEmpty(completedSteps) && isBooleanTrue(completedSteps.stops)) {
            setDropOffsEnabled(true);
        }
    }, [completedSteps]);

    //#endregion
    //#region displays

    const getLoadStopLocationDisplay = (stop, stopIndex) => {
        if (isObjectNotEmpty(stop)) {
            const stopName = LoadStopUtils.getStopLocationNameDisplay(stop, shipperData.shipperId, null, entityType, entityId);
            const stopAddress = LoadStopUtils.getStopAddressDisplay(stop);
            const stopType = LoadStopUtils.getStopType(stop);
            const apptType = LoadStopUtils.getAppointmentType(stop);

            return (
                <DataCollapsableRow
                    key={`stop-display-${stop.stopType}-${stopIndex}`}
                    isDisabled={(stop.stopType === 'DROP_OFF' && dropOffsEnabled === false) ? true : false}
                    isCollapsable={((!((isListNotEmpty(stop.bolNumbers) && dontRequireLoadSpecificInformation === false && hideLoadSpecificInformation === false) || dontRequireLoadSpecificInformation === true || hideLoadSpecificInformation === true) || (stop.stopType === 'DROP_OFF' && dropOffsEnabled === false) || !(isStringNotEmpty(stop.loadingType))) ? false : true)}
                    isCollapsed={isStringNotEmpty(stop.stopLocationId) ? false : true}
                    title={isStringNotEmpty(stopName) ? stopName : ''}
                    titleStyle={{ paddingLeft: 0, fontSize: 18, fontWeight: 500, color: '#000000' }}
                    subtitle={isStringNotEmpty(stopAddress) ? (<span style={{ fontSize: 14 }}>{stopAddress}</span>) : null}
                    header={isStringNotEmpty(apptType) ? (
                        <>
                            <span style={{ fontSize: 14, textTransform: 'uppercase', color: '#A9A9A9' }}>{(isStringNotEmpty(stopType) ? `${LoadStopUtils.getStopTypeDisplay(stop)}${stop.stopType === 'PICK_UP' && pickUpStops.length > 1 ? ' ' + (stopIndex + 1) : ''}${stop.stopType === 'DROP_OFF' && dropOffStops.length > 1 ? ' ' + (stopIndex + 1) : ''}` : '')}  </span>
                            <span style={{ fontSize: 14, textTransform: 'uppercase', color: '#A9A9A9' }}>{LoadStopUtils.getAppointmentDateTimeDisplay(stop)}</span>
                        </>
                    ) : (isStringNotEmpty(stopType) ? <span style={{ fontSize: 14, textTransform: 'uppercase', color: '#A9A9A9' }}>{`${LoadStopUtils.getStopTypeDisplay(stop)}${stop.stopType === 'PICK_UP' && pickUpStops.length > 1 ? ' ' + (stopIndex + 1) : ''}${stop.stopType === 'DROP_OFF' && dropOffStops.length > 1 ? ' ' + (stopIndex + 1) : ''}`}</span> : null)}
                >
                    <div style={{ marginTop: 12 }}>
                        <Row>
                            {(isListNotEmpty(stop.bolNumbers) && hideLoadSpecificInformation === false) ? (
                                <Col span={24}>
                                    <Fieldset legend={<div><span style={{ fontWeight: 'bold' }}>BOL Number(s)</span></div>}>
                                        {stop.bolNumbers.map((stopBOLNumber, stopBOLNumberIndex) => {
                                            return (
                                                <Row key={`stop-${stopIndex}-bolNumber-${stopBOLNumberIndex}`}>
                                                    <Col span={24}>
                                                        <DataRow
                                                            value={stopBOLNumber}
                                                            dataType="String"
                                                            extra={
                                                                (stop.stopType === 'PICK_UP' || (stop.stopType === 'DROP_OFF' && dropOffStops.length > 1)) ? (
                                                                    <Button type="default" icon={<DeleteOutlined />} style={{ marginLeft: 8 }} disabled={stop.stopType === 'DROP_OFF' && dropOffsEnabled === false} onClick={() => { onRemoveStopBOL(stop, stopBOLNumber, stopBOLNumberIndex); }} />
                                                                ) : null
                                                            }
                                                        />
                                                        {docFiles.filter(d => d.stopId === stop.id && d.bolNumber === stopBOLNumber).length > 0 ? (
                                                            <DataListRow data={docFiles.filter(d => d.stopId === stop.id && d.bolNumber === stopBOLNumber).map((docFile) => { if (docFile.file !== undefined && docFile.file !== null) { return docFile.file.name; } else { return ''; } })} />
                                                        ) : null}
                                                    </Col>
                                                </Row>
                                            );
                                        })}

                                        {(stop.stopType === 'PICK_UP' && dropOffStops.length > stop.bolNumbers.length) ? (
                                            <div>
                                                <Button style={{ marginLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} disabled={stop.stopType === 'DROP_OFF' && dropOffsEnabled === false} onClick={() => { setSelectedRecord(stop); toggleAddStopBOL(); }}>Another BOL Number</Button>
                                            </div>
                                        ) : null}
                                    </Fieldset>
                                </Col>
                            ) : (hideLoadSpecificInformation === false ? (
                                <Col span={24} style={{ marginTop: 18 }}>
                                    <Button style={{ marginLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} disabled={stop.stopType === 'DROP_OFF' && dropOffsEnabled === false} onClick={() => { setSelectedRecord(stop); toggleAddStopBOL(); }}>{`BOL Number${dontRequireLoadSpecificInformation === false ? ' *' : ''}`}</Button>
                                </Col>
                            ) : null)}
                            {isListNotEmpty(stop.commodities) ? (
                                <Col span={24}>
                                    <Fieldset legend={<div><span style={{ fontWeight: 'bold' }}>Commodities</span></div>}>
                                        {stop.commodities.map((stopCommodity, index) => {
                                            //console.log(stopCommodity);
                                            return (
                                                <DataRow
                                                    key={`stop-${stopIndex}-stopCommodity-${index}`}
                                                    title={stopCommodity.name}
                                                    value={`${isNumberNotEmpty(stopCommodity.unitPackagingInitCount) ? `${stopCommodity.unitPackagingInitCount} ${Enums.UnitPackaging.getValueByName(stopCommodity.unitPackaging)}` : ''}${isNumberNotEmpty(stopCommodity.bulkPackagingInitCount) ? `, ${stopCommodity.bulkPackagingInitCount} ${Enums.BulkPackaging.getValueByName(stopCommodity.bulkPackaging)}` : ''}`}
                                                    dataType="String"
                                                    extra={((stop.stopType === 'PICK_UP') || (stop.stopType === 'DROP_OFF' && dropOffStops.length > 1)) ? (
                                                        <Button type="default" style={{ marginLeft: 8 }} icon={<DeleteOutlined />} disabled={stop.stopType === 'DROP_OFF' && dropOffsEnabled === false} onClick={() => { onRemoveStopCommodity(stop, index); }} />
                                                    ) : null}
                                                />
                                            );
                                        })}

                                        {((stop.stopType === 'PICK_UP') || (stop.stopType === 'DROP_OFF' && dropOffStops.length > 1)) ? (
                                            <div>
                                                <Button style={{ marginLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} disabled={stop.stopType === 'DROP_OFF' && dropOffsEnabled === false} onClick={() => { setSelectedRecord(stop); toggleAddStopCommodity(); }}>Additional Commodity</Button>
                                            </div>
                                        ) : null}
                                    </Fieldset>
                                </Col>
                            ) : (((stop.stopType === 'PICK_UP') || (stop.stopType === 'DROP_OFF' && dropOffStops.length > 1)) ? (
                                <Col span={24} style={{ marginTop: 18 }}>
                                    <Button style={{ marginLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} disabled={!((isListNotEmpty(stop.bolNumbers) && dontRequireLoadSpecificInformation === false && hideLoadSpecificInformation === false) || dontRequireLoadSpecificInformation === true || hideLoadSpecificInformation === true) || (stop.stopType === 'DROP_OFF' && dropOffsEnabled === false)} onClick={() => { setSelectedRecord(stop); toggleAddStopCommodity(); }}>Commodities</Button>
                                </Col>
                            ) : null)}
                            {isStringNotEmpty(stop.loadingType) ? (
                                <Col span={24}>
                                    <Fieldset legend={<div><span style={{ fontWeight: 'bold' }}>{stop.stopType === 'PICK_UP' ? 'Loading Details' : 'Unloading Details'} *</span><Button style={{ fontWeight: 'bold' }} type="link" disabled={stop.stopType === 'DROP_OFF' && dropOffsEnabled === false} onClick={() => { setSelectedRecord(stop); toggleEditStopLoadingSpecifics(); }}>edit</Button></div>}>
                                        <DataRow title={stop.stopType === "PICK_UP" ? "Loading Type" : "Unloading Type"} value={stop.loadingType} dataType={stop.stopType === "PICK_UP" ? "LoadingType" : "UnloadingType"} />
                                        <DataRow title="Lumper Fee" value={isBooleanTrue(stop.hasLumperFee) ? `Yes, ${stringFormatter.toFormattedMoney(stop.lumperFee)}` : 'No'} dataType="String" />
                                        <DataRow title="Driver Assist" value={isBooleanTrue(stop.hasDriverAssist) ? `Yes, ${stringFormatter.toFormattedMoney(stop.driverAssist)}` : 'No'} dataType="String" />
                                        <DataRow title="Special Instructions" value={stop.specialInstructions} dataType="String" />
                                    </Fieldset>
                                </Col>
                            ) : (
                                <Col span={24} style={{ marginTop: 18 }}>
                                    <Button style={{ marginLeft: 0, fontWeight: 'bold' }} type="link" disabled={!((isListNotEmpty(stop.bolNumbers) && dontRequireLoadSpecificInformation === false && hideLoadSpecificInformation === false) || dontRequireLoadSpecificInformation === true || hideLoadSpecificInformation === true) || (stop.stopType === 'DROP_OFF' && dropOffsEnabled === false)} icon={<PlusOutlined />} onClick={() => { setSelectedRecord(stop); toggleEditStopLoadingSpecifics(); }}>{stop.stopType === 'PICK_UP' ? 'Loading Specifics *' : (stop.stopType === 'DROP_OFF' ? 'Unloading Specifics *' : 'Loading/Unloading Specifics *')}</Button>
                                </Col>
                            )}
                        </Row>
                    </div>
                </DataCollapsableRow>
            );
        } else {
            return null;
        }
    };

    const getStopDetails = () => {
        if (isObjectNotEmpty(selectedRecord)) {
            let stop = selectedRecord;
            const stopIcon = LoadStopUtils.getStopMarkerIcon(stop);

            return (
                <div>
                    <Row style={{ marginTop: 36, marginBottom: 36 }}>
                        <Col span={12}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: 30, marginRight: 12 }}>
                                    {isNotNullOrUndefined(stopIcon) ? (<img src={stopIcon} alt={LoadStopUtils.getStopTypeDisplay(stop)} style={{ width: 30, height: 30 }} />) : null}
                                </div>
                                <div style={{ flex: 1 }}>
                                    <span style={{ fontWeight: 'bold' }}>{LoadStopUtils.getStopLocationNameDisplay(stop, shipperData.shipperId, null, entityType, entityId)}</span><br />
                                    <span>{LoadStopUtils.getStopLocationCityStateDisplay(stop)}</span>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <span style={{ fontWeight: 'bold' }}>Appointment</span><br />
                            {LoadStopUtils.getAppointmentDateTimeDisplay(stop)}
                        </Col>
                    </Row>
                </div>
            );
        } else {
            return null;
        }
    };

    const getCommodityOptions = (stop) => {
        let options = [];
        if (isObjectNotEmpty(stop) && isListNotEmpty(commodities)) {
            let availableCommodities = [];
            // filter the commodities by what is set on the location if any are set
            if (isObjectNotEmpty(stop.stopLocation) && isListNotEmpty(stop.stopLocation.commodityIds)) {
                let availableStopCommodities = commodities.filter(c => c.isDeleted === false && stop.stopLocation.commodityIds.includes(c.id));
                if (isListNotEmpty(availableStopCommodities)) {
                    availableCommodities = availableStopCommodities;
                } else {
                    availableCommodities = commodities;
                }
            } else {
                availableCommodities = commodities;
            }
            if (stop.stopType === 'PICK_UP') {
                // if pick-up, don't show commodities that have already been added to this stop
                let usedCommodityIds = [];
                if (isListNotEmpty(stop.commodities)) {
                    stop.commodities.forEach((stopCommodity) => {
                        usedCommodityIds.push(stopCommodity.commodityId);
                    });
                }

                availableCommodities.forEach((commodity) => {
                    if (!usedCommodityIds.includes(commodity.id)) {
                        options.push(<Option value={commodity.id} key={commodity.id}>{commodity.name}</Option>);
                    }
                });
            } else if (stop.stopType === 'DROP_OFF') {
                let usedCommodityIds = [];
                let usedStopCommodityIds = [];

                // don't show commodities that have already been added to this stop
                if (isListNotEmpty(stop.commodities)) {
                    stop.commodities.forEach((stopCommodity) => {
                        usedStopCommodityIds.push(stopCommodity.commodityId);
                    });
                }

                // only show commodities that the pick-up stops have
                pickUpStops.forEach((pickUpStop) => {
                    if (isListNotEmpty(pickUpStop.commodities)) {
                        pickUpStop.commodities.forEach((stopCommodity) => {
                            if (!usedCommodityIds.includes(stopCommodity.commodityId)) {
                                usedCommodityIds.push(stopCommodity.commodityId);
                            }
                        });
                    }
                });

                availableCommodities.forEach((commodity) => {
                    if (usedCommodityIds.includes(commodity.id) && !usedStopCommodityIds.includes(commodity.id)) {
                        options.push(<Option value={commodity.id} key={commodity.id}>{commodity.name}</Option>);
                    }
                });
            }
        }

        return options;
    };

    const getBOLNumberOptions = (stop = null) => {
        let options = [];
        if (isListNotEmpty(bolNumberList) && isObjectNotEmpty(stop)) {
            let usedBOLNumbers = [];

            if (isListNotEmpty(stop.bolNumbers)) {
                stop.bolNumbers.forEach((stopBOLNumber) => {
                    usedBOLNumbers.push(stopBOLNumber);
                });
            }

            bolNumberList.forEach((bolNumber) => {
                if (!usedBOLNumbers.includes(bolNumber)) {
                    options.push({ key: bolNumber, value: bolNumber, label: bolNumber });
                }
            });

            if (isListNotEmpty(options) && stop.stopType === 'PICK_UP') {
                options.push({ key: 'NEW', value: 'NEW', label: 'Add a New BOL Number' });
            }
        }

        return options;
    };

    const getError = (errors, name) => {
        if (name !== undefined) {
            let obj = errors;

            name = name.split('.');
            let len = name.length;
            for (let i = 0; i < len - 1; i++) {
                if (obj[name[i]] !== undefined) {
                    //console.log(obj[name[i]]);
                    obj = obj[name[i]];
                }
            }

            return obj[name[len - 1]];
        } else {
            return undefined;
        }
    };

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    const formItemLayoutDouble = {
        labelCol1: { span: 24 },
        wrapperCol1: { span: 4 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 20 },
    };

    const formItemLayoutDouble2 = {
        labelCol1: { span: 12 },
        wrapperCol1: { span: 12 },
        labelCol2: { span: 12 },
        wrapperCol2: { span: 12 },
    };

    const radioStyle = {
        display: 'block',
        height: '60px',
        lineHeight: '30px',
    };

    //#endregion

    return (
        <>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className="load-creation-step-container">
                        <div className="load-creation-step">
                            <Header
                                titleStyle={{ fontSize: 18, fontWeight: 500 }}
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                                title="Stop Details"
                                title2="Provide information for each stop below."
                                icon={<FontAwesomeIcon className="anticon" icon={faRoute} />}
                                iconStyle={{ background: 'transparent' }}
                            />
                            <Timeline style={{ padding: '10px 24px' }}>
                                {isListNotEmpty(pickUpStops) ? (
                                    <>
                                        {pickUpStops.map((stop, index, arr) => {
                                            return (
                                                <Timeline.Item className="customTimelineItem" key={`pickUp-${index}`} color='#23BEBB'>
                                                    <div style={{ paddingLeft: 50, marginBottom: 36 }}>
                                                        {getLoadStopLocationDisplay(stop, index)}
                                                        {getError(methods.errors, `pickUpStops-${index}`) !== undefined ? (
                                                            <ErrorMessage
                                                                errors={methods.errors}
                                                                name={`pickUpStops-${index}`}
                                                                render={({ message, messages }) => {
                                                                    if (isNotNullOrUndefined(messages)) {
                                                                        return Object.entries(messages).map(([type, message]) => (<Alert key={`pickUpStops-${index}-${type}`} message={message} type="error" />));
                                                                    } else if (isStringNotEmpty(message)) {
                                                                        return <Alert message={message} type="error" />;
                                                                    } else {
                                                                        return null;
                                                                    }
                                                                }}
                                                            />
                                                        ) : null}
                                                        {getError(methods.errors, `pickUpStops`) !== undefined ? (
                                                            <ErrorMessage
                                                                errors={methods.errors}
                                                                name={`pickUpStops`}
                                                                render={({ message, messages }) => {
                                                                    if (isNotNullOrUndefined(messages)) {
                                                                        return Object.entries(messages).map(([type, message]) => (<Alert key={`pickUpStops-${type}`} message={message} type="error" />));
                                                                    } else if (isStringNotEmpty(message)) {
                                                                        return <Alert message={message} type="error" />;
                                                                    } else {
                                                                        return null;
                                                                    }
                                                                }}
                                                            />
                                                        ) : null}
                                                        {(dropOffsEnabled === false && (stop.stopTypeSequence + 1 === pickUpStops.length)) ? (
                                                            <div style={{ marginTop: 18 }}>
                                                                <Button style={{ marginLeft: 0, fontWeight: 'bold' }} type="link" icon={<RightOutlined />} disabled={!(((isListNotEmpty(stop.bolNumbers) && dontRequireLoadSpecificInformation === false && hideLoadSpecificInformation === false) || dontRequireLoadSpecificInformation === true || hideLoadSpecificInformation === true) && stop.loadingType)} onClick={() => { setDropOffsEnabled(true); }}>Continue to Drop-Off</Button>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Timeline.Item>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <Timeline.Item className="customTimelineItem" key={`pickUp`} color='#23BEBB'>
                                        <div style={{ paddingLeft: 50, marginBottom: 36 }}>
                                            {getError(methods.errors, `pickUpStops`) !== undefined ? (
                                                <ErrorMessage
                                                    errors={methods.errors}
                                                    name={`pickUpStops`}
                                                    render={({ message, messages }) => {
                                                        if (isNotNullOrUndefined(messages)) {
                                                            return Object.entries(messages).map(([type, message]) => (<Alert key={`pickUpStops-${type}`} message={message} type="error" />));
                                                        } else if (isStringNotEmpty(message)) {
                                                            return <Alert message={message} type="error" />;
                                                        } else {
                                                            return null;
                                                        }
                                                    }}
                                                />
                                            ) : null}
                                        </div>
                                    </Timeline.Item>
                                )}
                                {isListNotEmpty(dropOffStops) ? (
                                    <>
                                        {dropOffStops.map((stop, index, arr) => {
                                            return (
                                                <Timeline.Item className={(stop.stopTypeSequence + 1 === dropOffStops.length) ? "customTimelineItem-last" : "customTimelineItem"} key={`dropOff-${index}`} color={dropOffsEnabled === true ? '#23BEBB' : '#A9A9A9'}>
                                                    <div style={{ paddingLeft: 50, marginBottom: 36 }}>
                                                        <div style={dropOffsEnabled === true ? null : { zIndex: 500, backgroundColor: 'white', opacity: 0.2 }}>
                                                            {getLoadStopLocationDisplay(stop, index)}
                                                            {getError(methods.errors, `dropOffStops-${index}`) !== undefined ? (
                                                                <ErrorMessage
                                                                    errors={methods.errors}
                                                                    name={`dropOffStops-${index}`}
                                                                    render={({ message, messages }) => {
                                                                        if (isNotNullOrUndefined(messages)) {
                                                                            return Object.entries(messages).map(([type, message]) => (<Alert key={`dropOffStops-${index}-${type}`} message={message} type="error" />));
                                                                        } else if (isStringNotEmpty(message)) {
                                                                            return <Alert message={message} type="error" />;
                                                                        } else {
                                                                            return null;
                                                                        }
                                                                    }}
                                                                />
                                                            ) : null}
                                                            {getError(methods.errors, `dropOffStops`) !== undefined ? (
                                                                <ErrorMessage
                                                                    errors={methods.errors}
                                                                    name={`dropOffStops`}
                                                                    render={({ message, messages }) => {
                                                                        if (isNotNullOrUndefined(messages)) {
                                                                            return Object.entries(messages).map(([type, message]) => (<Alert key={`dropOffStops-${type}`} message={message} type="error" />));
                                                                        } else if (isStringNotEmpty(message)) {
                                                                            return <Alert message={message} type="error" />;
                                                                        } else {
                                                                            return null;
                                                                        }
                                                                    }}
                                                                />
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </Timeline.Item>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <Timeline.Item className="customTimelineItem-last" key={`dropOff`} color={dropOffsEnabled === true ? '#23BEBB' : '#A9A9A9'}>
                                        <div style={{ paddingLeft: 50, marginBottom: 36 }}>
                                            {getError(methods.errors, `dropOffStops`) !== undefined ? (
                                                <ErrorMessage
                                                    errors={methods.errors}
                                                    name={`dropOffStops`}
                                                    render={({ message, messages }) => {
                                                        if (isNotNullOrUndefined(messages)) {
                                                            return Object.entries(messages).map(([type, message]) => (<Alert key={`dropOffStops-${type}`} message={message} type="error" />));
                                                        } else if (isStringNotEmpty(message)) {
                                                            return <Alert message={message} type="error" />;
                                                        } else {
                                                            return null;
                                                        }
                                                    }}
                                                />
                                            ) : null}
                                        </div>
                                    </Timeline.Item>
                                )}
                            </Timeline>
                        </div>
                        <Row gutter={[12, 12]} className="load-creation-step-buttons">
                            <Col xs={{ span: 24, order: 2 }} md={{ span: 12, order: 1 }}>
                                {previousStepAction !== undefined && previousStepAction !== null ? (<Button type="default" block onClick={() => { previousStepAction('equipment'); }}>{previousStepActionName}</Button>) : null}
                            </Col>
                            <Col xs={{ span: 24, order: 1 }} md={{ span: 12, order: 2 }}>
                                <Button type="primary" block htmlType="submit">{nextStepActionName}</Button>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </FormProvider>
            <Drawer
                title={selectedRecord ? (selectedRecord.stopType === "PICK_UP" ? "Pick-Up BOL Information" : "Drop-Off BOL Information") : 'Stop BOL Information'}
                visible={showAddStopBOL === true}
                onClose={(e) => { toggleAddStopBOL(); setSelectedRecord(null); }}
                bodyStyle={{ paddingBottom: 80 }}
                style={{ zIndex: 1000 }}
                width={fullWidth > 720 ? fullWidth / 2 : 360}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                closable={false}
                maskClosable={false}
                forceRender={true}
            >
                <FormProvider {...methods2}>
                    <Form onSubmit={methods2.handleSubmit(onAddStopBOL)}>
                        {(getBOLNumberOptions(selectedRecord ? selectedRecord : null).length > 0) ? (
                            <>
                                <FormItem {...formItemLayout} label="Choose an existing BOL Number" format="vertical"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Radio.Group
                                            onBlur={onBlur}
                                            options={getBOLNumberOptions(selectedRecord ? selectedRecord : null)}
                                            onChange={e => { onChangeBOLNumber(e.target.value); onChange(e.target.value); }}
                                            value={value}
                                            name={name}
                                            ref={ref}
                                        />
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="existingBOLNumber"
                                />
                                {(showNewBOLNumber === true && selectedRecord && selectedRecord.stopType === 'PICK_UP') ? (
                                    <FormItem {...formItemLayout} label="BOL Number" required format="vertical"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Input
                                                onBlur={onBlur}
                                                onChange={e => { onChange(e.target.value); }}
                                                value={value}
                                                name={name}
                                                placeholder="BOL Number"
                                                ref={ref}
                                            />
                                        )}
                                        rules={{ required: 'Required Field' }}
                                        name="newBOLNumber"
                                    />
                                ) : null}
                            </>
                        ) : ((selectedRecord && selectedRecord.stopType === 'PICK_UP' && (bolNumberList.length < dropOffStops.length)) ? (
                            <FormItem {...formItemLayout} label="BOL Number" required format="vertical"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Input
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e.target.value); }}
                                        value={value}
                                        name={name}
                                        placeholder="BOL Number"
                                        ref={ref}
                                    />
                                )}
                                rules={{ required: 'Required Field' }}
                                name="newBOLNumber"
                            />
                        ) : null)}
                        <FormItemFile {...formItemLayout} label="BOL File(s)" name="docFiles" format="vertical">
                            <UploadFile
                                beforeUpload={(file, fileList) => {
                                    if (isNotNullOrUndefined(file)) {
                                        setBOLDocFiles(oldFiles => [...oldFiles, file]);
                                    }

                                    return false;
                                }}
                                onRemove={(file) => {
                                    let updatedBOLDocFiles = [...bolDocFiles];
                                    let fileToRemoveIndex = updatedBOLDocFiles.findIndex(d => d.uid === file.uid);
                                    if (fileToRemoveIndex !== -1) {
                                        updatedBOLDocFiles.splice(fileToRemoveIndex, 1);
                                    }
                                    // console.log(updatedDocFiles);
                                    setBOLDocFiles([...updatedBOLDocFiles]);
                                }}
                                message="Please upload a photo or pdf of the BOL."
                                multiple={true}
                            />
                        </FormItemFile>
                        <div className={classes.buttonContainer}>
                            <Button onClick={() => { toggleAddStopBOL(); setSelectedRecord(null); }} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary" htmlType="submit">Add BOL Information</Button>
                        </div>
                    </Form>
                </FormProvider>
            </Drawer>
            <Drawer
                title={selectedRecord ? (selectedRecord.stopType === "PICK_UP" ? "Loading Specifics" : "Unloading Specifics") : 'Loading/Unloading Specifics'}
                visible={showEditStopLoadingSpecifics === true}
                onClose={(e) => { toggleEditStopLoadingSpecifics(); setSelectedRecord(null); }}
                bodyStyle={{ paddingBottom: 80 }}
                style={{ zIndex: 1000 }}
                width={fullWidth > 720 ? fullWidth / 2 : 360}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                closable={false}
                maskClosable={false}
                forceRender={true}
            >
                <FormProvider {...methods3}>
                    <Form onSubmit={methods3.handleSubmit(onEditStopLoadingSpecifics)}>
                        {isObjectNotEmpty(selectedRecord) ? (
                            <Fieldset legend={selectedRecord.stopType === 'PICK_UP' ? "* Loading Type Options" : (selectedRecord.stopType === 'DROP_OFF' ? "* Unloading Type Options" : "* Loading/Unloading Type Options")}>
                                <div>How will the trailer be {selectedRecord.stopType === 'PICK_UP' ? "loaded" : (selectedRecord.stopType === 'DROP_OFF' ? "unloaded" : "loaded/unloaded")}?</div>
                                <FormItem {...formItemLayout} format="vertical"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Radio.Group onBlur={onBlur} onChange={e => onChange(e.target.value)} value={value} name={name} ref={ref}>
                                            <Radio style={radioStyle} key="LIVE" value="LIVE">
                                                <span style={{ fontWeight: 'bold', fontSize: 16 }}>Live {selectedRecord.stopType === 'PICK_UP' ? "Load" : (selectedRecord.stopType === 'DROP_OFF' ? "Unload" : "Load/Unload")} Trailer</span><br /><span style={{ fontSize: 14, paddingLeft: 24 }}>Trailer will be {selectedRecord.stopType === 'PICK_UP' ? "loaded" : (selectedRecord.stopType === 'DROP_OFF' ? "unloaded" : "loaded/unloaded")} upon arrival.</span>
                                            </Radio>
                                            <Radio style={radioStyle} key="DROP" value="DROP">
                                                <span style={{ fontWeight: 'bold', fontSize: 16 }}>Drop Trailer</span><br /><span style={{ fontSize: 14, paddingLeft: 24 }}>Drop and hook.</span>
                                            </Radio>
                                        </Radio.Group>
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="loadingType"
                                />
                            </Fieldset>
                        ) : null}
                        <Fieldset legend="Additional Fees">
                            <div>Are there any additional fees?</div>
                            <FormItemDouble {...formItemLayoutDouble} label1="Lumper Fee" format="vertical"
                                render1={({ onChange, onBlur, value, name, ref }) => (
                                    <Switch
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        onBlur={onBlur}
                                        onChange={(checked, event) => { onChange(checked); setLumperFeeDisabled(!checked); }}
                                        checked={value}
                                        name={name}
                                        ref={ref}
                                    />
                                )}
                                rules1={{ required: false }}
                                name1="hasLumperFee"
                                render2={({ onChange, onBlur, value, name, ref }) => (
                                    <InputNumber
                                        precision={2}
                                        min={0}
                                        placeholder="Lumper Fee"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                        disabled={lumperFeeDisabled === true}
                                        ref={ref}
                                    />
                                )}
                                rules2={{ required: false }}
                                name2="lumperFee"
                            />
                            <FormItemDouble {...formItemLayoutDouble} label1="Driver Assist" format="vertical"
                                render1={({ onChange, onBlur, value, name, ref }) => (
                                    <Switch
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        onBlur={onBlur}
                                        onChange={(checked, event) => { onChange(checked); setDriverAssistDisabled(!checked); }}
                                        checked={value}
                                        name={name}
                                        ref={ref}
                                    />
                                )}
                                rules1={{ required: false }}
                                name1="hasDriverAssist"
                                render2={({ onChange, onBlur, value, name, ref }) => (
                                    <InputNumber
                                        precision={2}
                                        min={0}
                                        placeholder="Driver Assist Fee"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                        disabled={driverAssistDisabled === true}
                                        ref={ref}
                                    />
                                )}
                                rules2={{ required: false }}
                                name2="driverAssist"
                            />
                        </Fieldset>
                        <Fieldset legend="Special Instructions">
                            <FormItem {...formItemLayout} label="Special Instructions" format="vertical"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <TextArea
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e.target.value); }}
                                        value={value}
                                        name={name}
                                        autoSize={{ minRows: 4 }}
                                        ref={ref}
                                    />
                                )}
                                rules={{ required: false }}
                                name="specialInstructions"
                            />
                        </Fieldset>
                        <div className={classes.buttonContainer}>
                            <Button onClick={() => { toggleEditStopLoadingSpecifics(); setSelectedRecord(null); }} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary" htmlType="submit">{selectedRecord ? (selectedRecord.stopType === "PICK_UP" ? "Update Loading Specifics" : "Update Unloading Specifics") : 'Update Loading/Unloading Specifics'}</Button>
                        </div>
                    </Form>
                </FormProvider>
            </Drawer>
            <Drawer
                title={selectedRecord ? (selectedRecord.stopType === "PICK_UP" ? "Pick-Up Commodity" : "Drop-Off Commodity") : 'Stop Commodity'}
                visible={showAddStopCommodity === true}
                onClose={(e) => { toggleAddStopCommodity(); setSelectedRecord(null); }}
                bodyStyle={{ paddingBottom: 80 }}
                style={{ zIndex: 1000 }}
                width={fullWidth > 720 ? fullWidth / 2 : 360}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                closable={false}
                maskClosable={false}
                forceRender={true}
            >
                <FormProvider {...methods4}>
                    <Form onSubmit={methods4.handleSubmit(onAddStopCommodity)}>
                        <Fieldset legend={selectedRecord ? (selectedRecord.stopType === "PICK_UP" ? "Pick-Up Location" : "Drop-Off Location") : 'Stop Location'}>
                            {getStopDetails()}
                        </Fieldset>
                        <Fieldset legend="Commodity Details">
                            <FormItem {...formItemLayout} label="Name" required format="vertical"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please Search and Select a Commodity to Add"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        showSearch={true}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        ref={ref}
                                    >
                                        {getCommodityOptions(selectedRecord ? selectedRecord : null)}
                                    </Select>
                                )}
                                rules={{ required: 'Required Field' }}
                                name="commodityId"
                            />
                            <FormItemDouble {...formItemLayoutDouble2} label1="Unit Quantity" label2="Unit of Measure/Unit Packaging" required1 required2 format="vertical"
                                render1={({ onChange, onBlur, value, name, ref }) => (
                                    <InputNumber
                                        precision={0}
                                        min={0}
                                        placeholder="Unit Quantity"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    />
                                )}
                                rules1={{ required: 'Required Field' }}
                                name1="unitPackagingInitCount"
                                render2={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please Select a Unit of Measure/Unit Packaging"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        showSearch={true}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        ref={ref}
                                    >
                                        {unitPackagingOptions}
                                    </Select>
                                )}
                                rules2={{ required: 'Required Field' }}
                                name2="unitPackaging"
                            />
                            <FormItemDouble {...formItemLayoutDouble2} label1="Bulk Quantity" label2="Bulk Packaging" format="vertical"
                                render1={({ onChange, onBlur, value, name, ref }) => (
                                    <InputNumber
                                        precision={0}
                                        min={0}
                                        placeholder="Bulk Quantity"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    />
                                )}
                                rules1={{ required: false }}
                                name1="bulkPackagingInitCount"
                                render2={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please Select a Bulk Packaging"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        showSearch={true}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        ref={ref}
                                    >
                                        {bulkPackagingOptions}
                                    </Select>
                                )}
                                rules2={{ required: false }}
                                name2="bulkPackaging"
                            />
                        </Fieldset>
                        <div className={classes.buttonContainer}>
                            <Button onClick={() => { toggleAddStopCommodity(); setSelectedRecord(null); }} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary" htmlType="submit">Add Commodity</Button>
                        </div>
                    </Form>
                </FormProvider>
            </Drawer>
        </>
    );
};

export default NewLoadStops;