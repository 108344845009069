import axiosAuthenticated from "../../api/axiosAuthenticated";
import { isListNotEmpty, isNotNullOrUndefined, isObjectNotEmpty } from "../../shared/objectUtils";
import * as DTO from "../dtos/index";
import * as Data from "./index";

const loadLanesPath = '/loadLanes';
const loadLanesBulkPath = '/loadLanes/bulk/get';

//#region LoadLanes Methods

export const getBulkLoadLanes = async (searchParams = {}, accounts = [], trailerTypes = [], entityId = null) => {
    const loadLanesRes = await axiosAuthenticated.post(loadLanesBulkPath, { ...searchParams });
    if (loadLanesRes && loadLanesRes.status === 200) {
        const loadLanes = loadLanesRes.data.data;

        let locationIds = getLocationIdsForLoadLanes(loadLanes);
        let locations = await Data.getLocationsByIds([...locationIds], entityId);

        return loadLanes.map((loadLane) => { return DTO.getLoadLaneDTO(loadLane, accounts, trailerTypes, locations, entityId); });
    }

    return [];
};

export const getLoadLanes = async (searchParams = {}, pagination = {}, accounts = [], trailerTypes = [], entityId = null) => {
    const loadLanesRes = await axiosAuthenticated.post(loadLanesBulkPath, { ...searchParams });
    if (loadLanesRes && loadLanesRes.status === 200) {
        const loadLanes = loadLanesRes.data.data;
        const otherData = loadLanesRes.data;

        let locationIds = getLocationIdsForLoadLanes(loadLanes);
        let locations = await Data.getLocationsByIds([...locationIds], entityId);

        const transformedLoadLanes = loadLanes.map((loadLane) => { return DTO.getLoadLaneDTO(loadLane, accounts, trailerTypes, locations, entityId); });

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;

        return { data: transformedLoadLanes, searchParams: searchParams, pagination: pagination };
    }

    return { data: [], searchParams: searchParams, pagination: pagination };
};

export const getLoadLane = async (id, accounts = [], trailerTypes = [], entityId = null) => {
    const loadLanesRes = await axiosAuthenticated.get(loadLanesPath + `/${id}`);
    if (loadLanesRes && loadLanesRes.status === 200) {
        let loadLane = loadLanesRes.data;

        let locationIds = getLocationIdsForLoadLane(loadLane);
        let locations = await Data.getLocationsByIds([...locationIds], entityId);

        return DTO.getLoadLaneDTO(loadLane, accounts, trailerTypes, locations, entityId);
    }

    return null;
};

export const addLoadLane = async (payload, accounts = [], trailerTypes = []) => {
    const loadLanesRes = await axiosAuthenticated.post(loadLanesPath, { ...payload });
    if (loadLanesRes && loadLanesRes.status === 201) {
        let newLoadLane = loadLanesRes.data;

        let locationIds = getLocationIdsForLoadLane(newLoadLane);
        let locations = await Data.getLocationsByIds([...locationIds], newLoadLane.accountId);

        return DTO.getLoadLaneDTO(newLoadLane, accounts, trailerTypes, locations, newLoadLane.accountId);
    }

    return null;
};

export const updateLoadLane = async (id, payload, accounts = [], trailerTypes = []) => {
    const loadLanesRes = await axiosAuthenticated.put(loadLanesPath + `/${id}`, { ...payload });
    if (loadLanesRes && loadLanesRes.status === 200) {
        let updatedLoadLane = loadLanesRes.data;

        let locationIds = getLocationIdsForLoadLane(updatedLoadLane);
        let locations = await Data.getLocationsByIds([...locationIds], updatedLoadLane.accountId);

        return DTO.getLoadLaneDTO(updatedLoadLane, accounts, trailerTypes, locations, updatedLoadLane.accountId);
    }

    return null;
};

export const removeLoadLane = async (id) => {
    const loadLanesRes = await axiosAuthenticated.put(loadLanesPath + `/${id}`, { isDeleted: true });
    if (loadLanesRes && loadLanesRes.status === 200) {
        return loadLanesRes.data;
    }

    return null;
};

//#endregion
//#region Helper Methods

export const getLocationIdsForLoadLanes = (loadLanes = []) => {
    let locationIds = [];
    if (isListNotEmpty(loadLanes)) {
        loadLanes.forEach((loadLane) => {
            if (isListNotEmpty(loadLane.locations)) {
                loadLane.locations.forEach((location) => {
                    if (!locationIds.includes(location.locationId)) {
                        locationIds.push(location.locationId);
                    }
                });
            }
        });
    }

    return locationIds;
};

export const getLocationIdsForLoadLane = (loadLane) => {
    let locationIds = [];
    if (isObjectNotEmpty(loadLane) && isListNotEmpty(loadLane.locations)) {
        loadLane.locations.forEach((location) => {
            if (!locationIds.includes(location.locationId)) {
                locationIds.push(location.locationId);
            }
        });
    }

    return locationIds;
};

//#endregion