export default class PDFJs {
    init = (source, element) => {
        const iframe = document.createElement('iframe');

        let url = source.replace(new RegExp('\\&', 'g'), '{replaceand}').replace(new RegExp('\\?', 'g'), '{replacequestion}').replace(new RegExp('\\=', 'g'), '{replaceequal}').replace(new RegExp('\\%', 'g'), '{replacepercent}');
        //console.log(url);
        //iframe.src = `https://unpkg.com/browse/pdfjs-dist@2.4.456/web/viewer.html?file=${source}`;
        iframe.src = `/pdfjs-2.4.456-dist/web/viewer.html?file=${url}`;
        iframe.width = '100%';
        iframe.height = '100%';

        element.appendChild(iframe);
    }
}