import * as actionTypes from "../actions/actionTypes";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { isBooleanTrue, isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import * as actionCreators from "./index";
import ErrorUtils from "../../shared/errorUtils";

//#region Fetch Account List Methods

export const fetchAccountListStart = (listName) => {
    return {
        type: actionTypes.FETCH_ACCOUNT_LIST_START,
        payload: { listName: listName }
    }
};

export const fetchAccountListSuccess = (listName, payload) => {
    return {
        type: actionTypes.FETCH_ACCOUNT_LIST_SUCCESS,
        payload: { listName: listName, ...payload }
    }
};

export const fetchAccountListFail = (listName, payload) => {
    return {
        type: actionTypes.FETCH_ACCOUNT_LIST_FAIL,
        payload: { listName: listName, ...payload }
    }
};

export const clearAccountList = (listName) => {
    return {
        type: actionTypes.CLEAR_ACCOUNT_LIST,
        payload: { listName: listName }
    }
};

export const clearAccountLists = () => {
    return {
        type: actionTypes.CLEAR_ACCOUNT_LISTS
    }
};

export const addAccountToAccountList = (listName, payload) => {
    return {
        type: actionTypes.ADD_TO_ACCOUNT_LIST,
        payload: { listName: listName, newRecord: payload }
    }
};

export const updateAccountInAccountList = (listName, payload) => {
    return {
        type: actionTypes.UPDATE_IN_ACCOUNT_LIST,
        payload: { listName: listName, updatedRecord: payload }
    }
};

export const removeAccountFromAccountList = (listName, payload) => {
    return {
        type: actionTypes.REMOVE_FROM_ACCOUNT_LIST,
        payload: { listName: listName, recordToRemove: payload }
    }
};

//#endregion
//#region Fetch Account Methods

export const fetchAccountStart = () => {
    return {
        type: actionTypes.FETCH_ACCOUNT_START
    }
};

export const fetchAccountSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_ACCOUNT_SUCCESS,
        payload: payload
    }
};

export const fetchAccountFail = (payload) => {
    return {
        type: actionTypes.FETCH_ACCOUNT_FAIL,
        payload: payload
    }
};

export const clearAccount = () => {
    return {
        type: actionTypes.CLEAR_ACCOUNT
    }
};

//#endregion
//#region Add Account Methods

export const addAccountStart = () => {
    return {
        type: actionTypes.ADD_ACCOUNT_START
    }
};

export const addAccountSuccess = () => {
    return {
        type: actionTypes.ADD_ACCOUNT_SUCCESS
    }
};

export const addAccountFail = (payload) => {
    return {
        type: actionTypes.ADD_ACCOUNT_FAIL,
        payload: payload
    }
};

export const addAccountLoadingClear = () => {
    return {
        type: actionTypes.ADD_ACCOUNT_LOADING_CLEAR
    }
};

export const addAccountErrorClear = () => {
    return {
        type: actionTypes.ADD_ACCOUNT_ERROR_CLEAR
    }
};

export const addAccountCancel = () => {
    return {
        type: actionTypes.ADD_ACCOUNT_CANCEL
    }
};

//#endregion
//#region Update Account Methods

const changeSingleAccount = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_ACCOUNT,
        payload: payload
    }
};

export const updateAccountStart = () => {
    return {
        type: actionTypes.UPDATE_ACCOUNT_START
    }
};

export const updateAccountSuccess = () => {
    return {
        type: actionTypes.UPDATE_ACCOUNT_SUCCESS
    }
};

export const updateAccountFail = (payload) => {
    return {
        type: actionTypes.UPDATE_ACCOUNT_FAIL,
        payload: payload
    }
};

export const updateAccountLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_ACCOUNT_LOADING_CLEAR
    }
};

export const updateAccountErrorClear = () => {
    return {
        type: actionTypes.UPDATE_ACCOUNT_ERROR_CLEAR
    }
};

export const updateAccountCancel = () => {
    return {
        type: actionTypes.UPDATE_ACCOUNT_CANCEL
    }
};

//#endregion
//#region Accounts Methods

export const fetchAccountList = (listName, payload) => {
    return async (dispatch, getState) => {
        if (isStringNotEmpty(listName)) {
            try {
                dispatch(fetchAccountListStart(listName));

                const state = getState();
                const accountsState = { ...state.accounts };
                const existingLists = { ...accountsState.lists };

                let pagination = {};
                let searchParams = {};
                if (isObjectNotEmpty(existingLists[listName])) {
                    pagination = { ...existingLists[listName].pagination };
                    searchParams = { ...existingLists[listName].searchParams };
                }

                if (isObjectNotEmpty(payload)) {
                    searchParams = { ...payload };
                }

                const res = await Data.getAccounts({ ...searchParams }, pagination);
                dispatch(fetchAccountListSuccess(listName, { records: res.data, params: { searchParams: res.searchParams, pagination: res.pagination } }));
            } catch (error) {
                logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
                dispatch(fetchAccountListFail(listName, { error: ErrorUtils.getErrorMessage(error) }));
            }
        }
    }
};

export const fetchAccount = (id) => {
    return async (dispatch) => {
        try {
            dispatch(fetchAccountStart());

            const account = await Data.getAccount(id);
            dispatch(fetchAccountSuccess({ record: account }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchAccountFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const addAccount = (payload, logoFile, insuranceFile, insuranceExpiryDate, w9File, w9ExpiryDate, entityType, accountRegistrationId = null) => {
    return async (dispatch) => {
        try {
            dispatch(addAccountStart());

            if (isStringNotEmpty(entityType) && entityType === "STAFF") {
                payload.isVerified = true;
                payload.isActive = true;
            } else {
                payload.isVerified = false;
                payload.isActive = true;
            }

            const newAccount = await Data.addAccount(payload, logoFile, insuranceFile, insuranceExpiryDate, w9File, w9ExpiryDate, entityType && entityType === "STAFF" ? true : false, entityType && entityType === "STAFF" ? "APPROVED" : "PENDING", accountRegistrationId);
            if (isObjectNotEmpty(newAccount)) {
                if (isListNotEmpty(newAccount.entityTypes)) {
                    newAccount.entityTypes.forEach((entityType) => {
                        dispatch(addAccountToAccountList(`${entityType}S`, newAccount));
                    });
                }

                dispatch(addAccountToAccountList('ALL', newAccount));
                dispatch(fetchAccountSuccess({ addRecordId: newAccount.id }));

                // refresh the accounts orchestrator
                dispatch(actionCreators.getAccounts(true));
            }

            dispatch(addAccountSuccess());
            dispatch(addAccountErrorClear());
            dispatch(addAccountLoadingClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addAccountFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateAccount = (id, payload, logoFile, logoFileId, insuranceFile, insuranceFileId, insuranceExpiryDate, w9File, w9FileId, w9ExpiryDate, entityType) => {
    return async (dispatch) => {
        try {
            dispatch(updateAccountStart());

            if (isBooleanTrue(payload.isDeleted)) {
                const removedAccount = await Data.removeAccount(id);
                if (isObjectNotEmpty(removedAccount)) {
                    if (isListNotEmpty(removedAccount.entityTypes)) {
                        removedAccount.entityTypes.forEach((entityType) => {
                            dispatch(removeAccountFromAccountList(`${entityType}S`, removedAccount));
                        });
                    }

                    dispatch(removeAccountFromAccountList('ALL', removedAccount));

                    // refresh the accounts orchestrator
                    dispatch(actionCreators.getAccounts(true));
                }
            } else {
                const updatedAccount = await Data.updateAccount(id, payload, logoFile, logoFileId, insuranceFile, insuranceFileId, insuranceExpiryDate, w9File, w9FileId, w9ExpiryDate, entityType && entityType === "STAFF" ? true : false, entityType && entityType === "STAFF" ? "APPROVED" : "PENDING");
                if (isObjectNotEmpty(updatedAccount)) {
                    if (isListNotEmpty(updatedAccount.entityTypes)) {
                        updatedAccount.entityTypes.forEach((entityType) => {
                            dispatch(updateAccountInAccountList(`${entityType}S`, updatedAccount));
                        });
                    }

                    dispatch(updateAccountInAccountList('ALL', updatedAccount));
                    dispatch(changeSingleAccount(updatedAccount));

                    // refresh the accounts orchestrator
                    dispatch(actionCreators.getAccounts(true));
                }
            }

            dispatch(updateAccountSuccess());
            dispatch(updateAccountLoadingClear());
            dispatch(updateAccountErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateAccountFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const verifyAccount = (id) => {
    return async (dispatch) => {
        try {
            dispatch(updateAccountStart());

            const updatedAccount = await Data.verifyAccount(id);
            if (isObjectNotEmpty(updatedAccount)) {
                if (isListNotEmpty(updatedAccount.entityTypes)) {
                    updatedAccount.entityTypes.forEach((entityType) => {
                        dispatch(updateAccountInAccountList(`${entityType}S`, updatedAccount));
                    });
                }

                dispatch(updateAccountInAccountList('ALL', updatedAccount));
                dispatch(changeSingleAccount(updatedAccount));

                // refresh the accounts orchestrator
                dispatch(actionCreators.getAccounts(true));
            }

            dispatch(updateAccountSuccess());
            dispatch(updateAccountLoadingClear());
            dispatch(updateAccountErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateAccountFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion