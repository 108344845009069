import React, { useState, useEffect } from 'react';
import ImageCard from "../../components/ImageCard/ImageCard";
import DataRow from '../../components/DataRow/DataRow';
import DataAddressRow from '../../components/DataAddressRow/DataAddressRow';
import DataNameRow from '../../components/DataNameRow/DataNameRow';
import { EditOutlined } from '@ant-design/icons';
import { Tooltip, Button, Drawer, Spin } from 'antd';
import DetailsList from '../../components/DetailsList/DetailsList';
import DetailsListDataRow from '../../components/DetailsListDataRow/DetailsListDataRow';
import DetailsListHeaderActionRow from '../../components/DetailsListHeaderActionRow/DetailsListHeaderActionRow';
import { tableBackgroundStyle } from "../../constants/theme";
import { useSelector, useDispatch } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import EditProfile from '../../components/EditProfile/EditProfile';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { getImageByDocumentId } from '../../api/data';
import { isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import logger from '../../shared/logger';
import { Can } from '../../shared/entitlements/entitlements';

const Profile = (props) => {
    //#region constants

    const fullWidth = global.window.innerWidth;
    const singularEntityName = "Profile";

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const userProfileId = useSelector(state => state.auth.userProfileId);
    const user = useSelector(state => state.users.record);
    const isLoading = useSelector(state => state.users.isRecordLoading);
    const profileImageId = useSelector(state => state.auth.profileImageId);

    //#endregion
    //#region useStates

    const [imageUrl, setImageUrl] = useState(null);
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [roles, setRoles] = useState([]);

    //#endregion
    //#region toggles

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    //#endregion
    //#region displays

    let extra = (
        <Tooltip placement="top" title={'Edit ' + singularEntityName}>
            <Button type="primary" shape="circle" icon={<EditOutlined />} onClick={(e) => { e.stopPropagation(); toggleEditEntity(); }} />
        </Tooltip>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName}
            onClose={toggleEditEntity}
            visible={showEditEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditProfile
                cancel={toggleEditEntity}
            />
        </Drawer>
    );

    //#endregion
    //#region useEffects

    useEffect(() => {
        if (isStringNotEmpty(userProfileId)) {
            dispatch(actionCreators.fetchUser(userProfileId));
        }
    }, [userProfileId]);

    useEffect(() => {
        if (isObjectNotEmpty(user)) {
            let rolesList = [];
            if (user.isAdmin === true) {
                rolesList.push("Admin");
            } else if (user.isVP === true) {
                rolesList.push("VP");
            } else if (user.isDirector === true) {
                rolesList.push("Director");
            } else if (user.isManager === true) {
                rolesList.push("Manager");
            } else if (user.isStaff === true) {
                rolesList.push("Staff");
            }
            setRoles([...rolesList]);
        }
    }, [user]);

    useEffect(() => {
        const { cancel, token } = axios.CancelToken.source();

        if (isStringNotEmpty(profileImageId)) {
            getImageByDocumentId(profileImageId, { cancelToken: token }).then((data) => {
                const blob = new Blob([data], {
                    type: 'image/png',
                });
                setImageUrl(URL.createObjectURL(blob));
            }).catch(err => {
                if (axios.isCancel(err)) {
                    //console.log('Request cancelled', err.message);
                } else {
                    logger.logDebugEvent('Profile.js', err.message, true);
                }
            });
        }

        return () => {
            // console.log("Cleaning up");
            if (cancel !== undefined) {
                cancel('Operation cancelled by the user.');
            }
        };
    }, [profileImageId]);

    //#endregion

    if (isObjectNotEmpty(user)) {
        return (
            <Can entityAction="READ" entityModel="USER" entityObject={user}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true}>
                    <DetailsList style={tableBackgroundStyle}>
                        <DetailsListHeaderActionRow label="My Profile" colSpan="2" extra={extra} />
                        <DetailsListDataRow label="Profile Image">
                            <ImageCard imageUrl={imageUrl} />
                        </DetailsListDataRow>
                        <DetailsListDataRow label="Name">
                            <DataNameRow firstName={user.firstName} middleName={user.middleName} lastName={user.lastName} />
                        </DetailsListDataRow>
                        <DetailsListDataRow label="Roles">
                            <DataRow value={roles.length > 0 ? roles.join(', ') : ''} dataType="String" />
                        </DetailsListDataRow>
                        <DetailsListDataRow label="UserName">
                            <DataRow value={user.isDummyEmail ? null : user.user} dataType="String" />
                        </DetailsListDataRow>
                        <DetailsListDataRow label="Contact Information">
                            <DataRow title="Email Address" value={user.isDummyEmail ? null : user.user} dataType="String" />
                            <DataRow title="Phone Number" value={user.phone} dataType="PhoneNumber" />
                            <DataAddressRow title="Address" street1={user.streetAddress1} city={user.city} state={user.state} postalCode={user.postalCode} country={user.country} />
                        </DetailsListDataRow>
                        <DetailsListDataRow label="Bio">
                            <DataRow value={user.bio} dataType="String" />
                        </DetailsListDataRow>
                        <DetailsListDataRow label="Date Joined">
                            <DataRow value={user.createdAt} dataType="MomentDate" />
                        </DetailsListDataRow>
                    </DetailsList>
                    {editEntityComponents}
                </Spin>
            </Can>
        );
    } else {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true}></Spin>
        );
    }
};

export default withRouter(Profile);