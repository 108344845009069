import React, { useState, useMemo, useEffect } from 'react';
import { Input, Select, Alert, Spin, InputNumber } from "antd";
import DatePicker from '../DatePickerAutoAccept/DatePickerAutoAccept';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Enums from '../../shared/enums';
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import { isListNotEmpty, isObjectNotEmpty, isStringEmpty, isStringNotEmpty, removeEmpty } from '../../shared/objectUtils';
import StringFormatter from '../../shared/stringFormatter';

const stringFormatter = new StringFormatter();
const { Option } = Select;

const NewTransaction = ({ invoiceId = null, invoices, shipperId, carrierId, loadId, cancel, loading = false }) => {
    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.transactions.isRecordAddLoading);
    const error = useSelector(state => state.transactions.addRecordError);
    const entityType = useSelector(state => state.auth.entityType);

    const paymentMethodOptions = Enums.PaymentMethods.selectListOptions();
    const paymentStatusOptions = Enums.PaymentStatuses.selectListOptions();
    const invoiceSourceAccountTypeOptions = entityType === "STAFF" ? Enums.InvoiceSourceAccountTypes.selectListOptions() : Enums.InvoiceSourceAccountTypes.selectListOptionsWithExclusions(["STAFF", "FACTORING"]);
    const invoiceAccountTypeOptions = entityType === "STAFF" ? Enums.InvoiceAccountTypes.selectListOptions() : Enums.InvoiceAccountTypes.selectListOptionsWithExclusions(["STAFF", "FACTORING"]);

    const [amount, setAmount] = useState(0);
    const [transactionFee, setTransactionFee] = useState(0);

    const onSubmit = (data) => {
        //console.log(data);
        let cleanedData = removeEmpty(data);

        if (isObjectNotEmpty(cleanedData)) {
            cleanedData.loadId = loadId;

            if (cleanedData.fromEntityType === "SHIPPER") {
                if (isStringNotEmpty(shipperId)) {
                    cleanedData.fromEntityId = shipperId;
                }
            } else if (cleanedData.fromEntityType === "CARRIER") {
                if (isStringNotEmpty(carrierId)) {
                    cleanedData.fromEntityId = carrierId;
                }
            }

            if (cleanedData.toEntityType === "SHIPPER") {
                if (isStringNotEmpty(shipperId)) {
                    cleanedData.toEntityId = shipperId;
                }
            } else if (cleanedData.toEntityType === "CARRIER") {
                if (isStringNotEmpty(carrierId)) {
                    cleanedData.toEntityId = carrierId;
                }
            }

            if (isStringNotEmpty(invoiceId)) {
                cleanedData.invoiceId = invoiceId;
            }

            let invoice = null;
            if (isStringNotEmpty(cleanedData.invoiceId) && isListNotEmpty(invoices)) {
                invoice = invoices.find(i => i.id === cleanedData.invoiceId);
            }

            dispatch(actionCreators.addTransaction(cleanedData, loadId, invoice, true));
        }
    };

    const onChangeInvoiceId = (value) => {
        if (isStringNotEmpty(value) && isListNotEmpty(invoices)) {
            let invoice = invoices.find(i => i.id === value);
            if (isObjectNotEmpty(invoice)) {
                methods.setValue("fromEntityType", invoice.toEntityType);
                methods.setValue("toEntityType", invoice.fromEntityType);
                methods.setValue("amount", invoice.balanceDue);
                methods.setValue("transactionFee", 0);
                methods.setValue("netAmount", invoice.balanceDue);

                if (invoice.paymentMethod === 'FACTORING') {
                    methods.setValue("fromSource", invoice.toEntityType);
                    methods.setValue("toSource", "FACTORING");
                } else {
                    methods.setValue("fromSource", invoice.toEntityType);
                    methods.setValue("toSource", invoice.fromEntityType);
                }
            }
        }
    };

    const onChangeFromSource = (value) => {
        if (isStringNotEmpty(value)) {
            if (value === "SHIPPER") {
                methods.setValue("fromEntityType", "SHIPPER");
            } else if (value === "CARRIER") {
                methods.setValue("fromEntityType", "CARRIER");
            } else if (value === "FACTORING") {
                methods.setValue("fromEntityType", "CARRIER");
            } else if (value === "INSURANCE") {
                methods.setValue("fromEntityType", "CARRIER");
            } else if (value === "CREDIT") {
                methods.setValue("fromEntityType", "SHIPPER");
            } else if (value === "STAFF") {
                methods.setValue("fromEntityType", "STAFF");
            }
        } else {
            methods.setValue("fromEntityType", undefined);
        }
    };

    const onChangeToSource = (value) => {
        if (isStringNotEmpty(value)) {
            if (value === "SHIPPER") {
                methods.setValue("toEntityType", "SHIPPER");
            } else if (value === "CARRIER") {
                methods.setValue("toEntityType", "CARRIER");
            } else if (value === "FACTORING") {
                methods.setValue("toEntityType", "CARRIER");
            } else if (value === "INSURANCE") {
                methods.setValue("toEntityType", "CARRIER");
            } else if (value === "CREDIT") {
                methods.setValue("toEntityType", "SHIPPER");
            } else if (value === "STAFF") {
                methods.setValue("toEntityType", "STAFF");
            }
        } else {
            methods.setValue("fromEntityType", undefined);
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.addTransactionCancel());
        cancel();
    };

    useMemo(() => {
        if (isStringNotEmpty(invoiceId) && isListNotEmpty(invoices)) {
            let invoice = invoices.find(i => i.id === invoiceId);
            if (isObjectNotEmpty(invoice)) {
                methods.setValue("fromEntityType", invoice.toEntityType);
                methods.setValue("toEntityType", invoice.fromEntityType);
                methods.setValue("amount", invoice.balanceDue);
                methods.setValue("transactionFee", 0);
                methods.setValue("netAmount", invoice.balanceDue);

                if (invoice.paymentMethod === 'FACTORING') {
                    methods.setValue("fromSource", invoice.toEntityType);
                    methods.setValue("toSource", "FACTORING");
                } else {
                    methods.setValue("fromSource", invoice.toEntityType);
                    methods.setValue("toSource", invoice.fromEntityType);
                }
            }
        }
    }, [invoiceId, invoices]);

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.addTransactionErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    useEffect(() => {
        // console.log(amount);
        // console.log(transactionFee);

        if (!isNaN(amount) && !isNaN(transactionFee)) {
            methods.setValue("netAmount", amount - transactionFee);
        } else {
            methods.setValue("netAmount", '');
        }
    }, [amount, transactionFee]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const formItemLayoutDouble = {
        labelCol1: { span: 12 },
        wrapperCol1: { span: 12 },
        labelCol2: { span: 12 },
        wrapperCol2: { span: 12 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true && error === null) || loading === true}>
                    {/* {isStringEmpty(invoiceId) ? (
                        <Fieldset legend="Choose an Invoice">
                            <FormItem {...formItemLayout} label="Invoice" required format="horizontal"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please Select the Invoice that this Transaction is for"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChangeInvoiceId(selected); onChange(selected); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                        showSearch={true}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {invoices.filter(i => i.invoiceType !== 'PRICE_CONFIRMATION' && i.invoiceType !== 'RATE_CONFIRMATION').map(i => <Option value={i.id} key={i.id}>{Enums.InvoiceTypes.getValueByName(i.invoiceType)} {i.irisId} - Balance: {stringFormatter.toFormattedMoney(i.balanceDue)}</Option>)}
                                    </Select>
                                )}
                                rules={{ required: 'Required Field' }}
                                name="invoiceId"
                            />
                        </Fieldset>
                    ) : null} */}
                    <FormItem {...formItemLayout} label="Transaction External Reference Id" format="horizontal"
                        render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Transaction External Reference Id" ref={ref} />}
                        rules={{ required: false }}
                        name="qbTransactionId"
                    />
                    <FormItem {...formItemLayout} label="Payment Amount" required format="horizontal"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <InputNumber
                                precision={2}
                                min={0}
                                placeholder="Payment Amount"
                                style={{ width: '100%' }}
                                onBlur={onBlur}
                                onChange={e => { setAmount(e); onChange(e); }}
                                value={value}
                                name={name}
                                ref={ref}
                            />
                        )}
                        rules={{ required: "Required Field" }}
                        name="amount"
                    />
                    <FormItem {...formItemLayout} label="Transaction Fee (if any)" format="horizontal"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <InputNumber
                                precision={2}
                                min={0}
                                placeholder="Transaction Fee (if any)"
                                style={{ width: '100%' }}
                                onBlur={onBlur}
                                onChange={e => { setTransactionFee(e); onChange(e); }}
                                value={value}
                                name={name}
                                ref={ref}
                            />
                        )}
                        rules={{ required: false }}
                        name="transactionFee"
                        defaultValue={0}
                    />
                    <FormItem {...formItemLayout} label="Net Amount (amount credited or debited against the invoice)" required format="horizontal"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <InputNumber
                                precision={2}
                                min={0}
                                placeholder="Net Amount"
                                style={{ width: '100%' }}
                                onBlur={onBlur}
                                onChange={e => { onChange(e); }}
                                value={value}
                                name={name}
                                disabled={true}
                                ref={ref}
                            />
                        )}
                        rules={{ required: 'Required Field' }}
                        name="netAmount"
                    />
                    <FormItem {...formItemLayout} label="Payment Method" required format="horizontal"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Select
                                placeholder="Please Select a Payment Method"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                                ref={ref}
                            >
                                {paymentMethodOptions}
                            </Select>
                        )}
                        rules={{ required: 'Required Field' }}
                        name="paymentMethod"
                    />
                    <FormItem {...formItemLayout} label="Payment Status" required format="horizontal"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Select
                                placeholder="Please Select a Payment Status"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                                ref={ref}
                            >
                                {paymentStatusOptions}
                            </Select>
                        )}
                        rules={{ required: 'Required Field' }}
                        name="paymentStatus"
                        defaultValue={'PENDING'}
                    />
                    <FormItemDouble {...formItemLayoutDouble} label1="From" label2="On Behalf Of" required1 required2 format="vertical"
                        render1={({ onChange, onBlur, value, name, ref }) => (
                            <Select
                                placeholder="Please Select a Payment Source"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChangeFromSource(selected); onChange(selected); }}
                                value={value}
                                name={name}
                                ref={ref}
                            >
                                {invoiceSourceAccountTypeOptions}
                            </Select>
                        )}
                        rules1={{ required: 'Required Field' }}
                        name1="fromSource"
                        render2={({ onChange, onBlur, value, name, ref }) => (
                            <Select
                                placeholder="Please Select a Payment Beneficiary"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                                ref={ref}
                            >
                                {invoiceAccountTypeOptions}
                            </Select>
                        )}
                        rules2={{ required: 'Required Field' }}
                        name2="fromEntityType"
                    />
                    <FormItemDouble {...formItemLayoutDouble} label1="To" label2="On Behalf Of" required1 required2 format="vertical"
                        render1={({ onChange, onBlur, value, name, ref }) => (
                            <Select
                                placeholder="Please Select a Payment Source"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChangeToSource(selected); onChange(selected); }}
                                value={value}
                                name={name}
                                ref={ref}
                            >
                                {invoiceSourceAccountTypeOptions}
                            </Select>
                        )}
                        rules1={{ required: 'Required Field' }}
                        name1="toSource"
                        render2={({ onChange, onBlur, value, name, ref }) => (
                            <Select
                                placeholder="Please Select a Payment Beneficiary"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                                ref={ref}
                            >
                                {invoiceAccountTypeOptions}
                            </Select>
                        )}
                        rules2={{ required: 'Required Field' }}
                        name2="toEntityType"
                    />
                    <FormItem {...formItemLayout} label="Paid At" format="horizontal"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <DatePicker
                                placeholder="Paid At"
                                format="YYYY-MM-DD"
                                style={{ width: '100%' }}
                                onBlur={onBlur}
                                onChange={(date, dateString) => { onChange(date); }}
                                value={value}
                                name={name}
                                onSelect={(date) => { onChange(date); }}
                                ref={ref}
                            />
                        )}
                        rules={{ required: false }}
                        name="paidAt"
                    />
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Create Transaction" />
            </Form>
        </FormProvider>
    );
};

export default NewTransaction;