import axiosAuthenticated from "../../api/axiosAuthenticated";
import { isNotNullOrUndefined } from "../../shared/objectUtils";
import * as DTO from "../dtos/index";

const carrierRejectionsPath = '/carrierRejections';

//#region Carrier Rejection Methods

export const getCarrierRejections = async (searchParams = {}, pagination = {}, accounts = []) => {
    const carrierRejectionsRes = await axiosAuthenticated.get(carrierRejectionsPath, { params: { ...searchParams } });
    if (carrierRejectionsRes && carrierRejectionsRes.status === 200) {
        const carrierRejections = carrierRejectionsRes.data.data;
        const otherData = carrierRejectionsRes.data;

        const transformedCarrierRejections = carrierRejections.map((carrierRejection) => { return DTO.getCarrierRejectionDTO(carrierRejection, accounts); });

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;

        return { data: transformedCarrierRejections, searchParams: searchParams, pagination: pagination };
    }

    return { data: [], searchParams: searchParams, pagination: pagination };
};

export const getCarrierRejection = async (id, accounts = []) => {
    const carrierRejectionsRes = await axiosAuthenticated.get(carrierRejectionsPath + `/${id}`);
    if (carrierRejectionsRes && carrierRejectionsRes.status === 200) {
        let carrierRejection = carrierRejectionsRes.data;

        return DTO.getCarrierRejectionDTO(carrierRejection, accounts);
    }

    return null;
};

export const addCarrierRejection = async (payload, accounts = []) => {
    const carrierRejectionRes = await axiosAuthenticated.post(carrierRejectionsPath, { ...payload });
    if (carrierRejectionRes && carrierRejectionRes.status === 201) {
        let newCarrierRejection = carrierRejectionRes.data;

        return DTO.getCarrierRejectionDTO(newCarrierRejection, accounts);
    }

    return null;
};

export const updateCarrierRejection = async (id, payload, accounts = []) => {
    const carrierRejectionsRes = await axiosAuthenticated.put(carrierRejectionsPath + `/${id}`, { ...payload });
    if (carrierRejectionsRes && carrierRejectionsRes.status === 200) {
        let updatedCarrierRejection = carrierRejectionsRes.data;

        return DTO.getCarrierRejectionDTO(updatedCarrierRejection, accounts);
    }

    return null;
};

export const removeCarrierRejection = async (id) => {
    const carrierRejectionsRes = await axiosAuthenticated.put(carrierRejectionsPath + `/${id}`, { isDeleted: true });
    if (carrierRejectionsRes && carrierRejectionsRes.status === 200) {
        return carrierRejectionsRes.data;
    }

    return null;
};

//#endregion