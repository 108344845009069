import Enums from "./enums";
import { isNumberGreaterThanZero, isNumberNotEmpty, isStringNotEmpty } from "./objectUtils";

const shipperLoadRequirementFees = (invoiceLineItems) => {
    let amount = 0.00;
    invoiceLineItems.filter(i => i.status !== 'CANCELLED' && i.isDeleted === false).forEach((item) => {
        if (item.fromEntityType === 'SHIPPER' && Enums.LoadRequirements.doesNameExist(item.itemType)) {
            amount += Number(item.totalAmount);
        } else if (item.toEntityType === 'SHIPPER' && Enums.LoadRequirements.doesNameExist(item.itemType)) {
            amount -= Number(item.totalAmount);
        }
    });

    return Number(amount);
};

const shipperAccessorialFees = (invoiceLineItems) => {
    let amount = 0.00;
    invoiceLineItems.filter(i => i.status !== 'CANCELLED' && i.isDeleted === false).forEach((item) => {
        if (item.fromEntityType === 'SHIPPER' && Enums.Accessorials.doesNameExist(item.itemType)) {
            amount += Number(item.totalAmount);
        } else if (item.toEntityType === 'SHIPPER' && Enums.Accessorials.doesNameExist(item.itemType)) {
            amount -= Number(item.totalAmount);
        }
    });

    return Number(amount);
};

const shipperStopFees = (invoiceLineItems) => {
    let amount = 0.00;
    invoiceLineItems.filter(i => i.status !== 'CANCELLED' && i.isDeleted === false).forEach((item) => {
        if (item.fromEntityType === 'SHIPPER' && Enums.StopFees.doesNameExist(item.itemType)) {
            amount += Number(item.totalAmount);
        } else if (item.toEntityType === 'SHIPPER' && Enums.StopFees.doesNameExist(item.itemType)) {
            amount -= Number(item.totalAmount);
        }
    });

    return Number(amount);
};

const shipperEquipmentSpecificFees = (invoiceLineItems) => {
    let amount = 0.00;
    invoiceLineItems.filter(i => i.status !== 'CANCELLED' && i.isDeleted === false).forEach((item) => {
        if (item.fromEntityType === 'SHIPPER' && Enums.EquipmentSpecifics.doesNameExist(item.itemType)) {
            amount += Number(item.totalAmount);
        } else if (item.toEntityType === 'SHIPPER' && Enums.EquipmentSpecifics.doesNameExist(item.itemType)) {
            amount -= Number(item.totalAmount);
        }
    });

    return Number(amount);
};

const carrierLoadRequirementFees = (invoiceLineItems) => {
    let amount = 0.00;
    invoiceLineItems.filter(i => i.status !== 'CANCELLED' && i.isDeleted === false).forEach((item) => {
        if (item.fromEntityType === 'CARRIER' && Enums.LoadRequirements.doesNameExist(item.itemType)) {
            amount -= Number(item.totalAmount);
        } else if (item.toEntityType === 'CARRIER' && Enums.LoadRequirements.doesNameExist(item.itemType)) {
            amount += Number(item.totalAmount);
        }
    });

    return Number(amount);
};

const carrierAccessorialFees = (invoiceLineItems) => {
    let amount = 0.00;
    invoiceLineItems.filter(i => i.status !== 'CANCELLED' && i.isDeleted === false).forEach((item) => {
        if (item.fromEntityType === 'CARRIER' && Enums.Accessorials.doesNameExist(item.itemType)) {
            amount -= Number(item.totalAmount);
        } else if (item.toEntityType === 'CARRIER' && Enums.Accessorials.doesNameExist(item.itemType)) {
            amount += Number(item.totalAmount);
        }
    });

    return Number(amount);
};

const carrierStopFees = (invoiceLineItems) => {
    let amount = 0.00;
    invoiceLineItems.filter(i => i.status !== 'CANCELLED' && i.isDeleted === false).forEach((item) => {
        if (item.fromEntityType === 'CARRIER' && Enums.StopFees.doesNameExist(item.itemType)) {
            amount -= Number(item.totalAmount);
        } else if (item.toEntityType === 'CARRIER' && Enums.StopFees.doesNameExist(item.itemType)) {
            amount += Number(item.totalAmount);
        }
    });

    return Number(amount);
};

const carrierEquipmentSpecificFees = (invoiceLineItems) => {
    let amount = 0.00;
    invoiceLineItems.filter(i => i.status !== 'CANCELLED' && i.isDeleted === false).forEach((item) => {
        if (item.fromEntityType === 'CARRIER' && Enums.EquipmentSpecifics.doesNameExist(item.itemType)) {
            amount -= Number(item.totalAmount);
        } else if (item.toEntityType === 'CARRIER' && Enums.EquipmentSpecifics.doesNameExist(item.itemType)) {
            amount += Number(item.totalAmount);
        }
    });

    return Number(amount);
};

const shipperClaims = (invoiceLineItems) => {
    let amount = 0.00;
    invoiceLineItems.filter(i => i.status !== 'CANCELLED' && i.isDeleted === false).forEach((item) => {
        if (item.fromEntityType === 'SHIPPER' && isStringNotEmpty(item.claimId)) {
            amount += Number(item.totalAmount);
        } else if (item.toEntityType === 'SHIPPER' && isStringNotEmpty(item.claimId)) {
            amount -= Number(item.totalAmount);
        }
    });

    return Number(amount);
};

const carrierClaims = (invoiceLineItems) => {
    let amount = 0.00;
    invoiceLineItems.filter(i => i.status !== 'CANCELLED' && i.isDeleted === false).forEach((item) => {
        if (item.fromEntityType === 'CARRIER' && isStringNotEmpty(item.claimId)) {
            amount -= Number(item.totalAmount);
        } else if (item.toEntityType === 'CARRIER' && isStringNotEmpty(item.claimId)) {
            amount += Number(item.totalAmount);
        }
    });

    return Number(amount);
};

const shipperAddOns = (invoiceLineItems) => {
    let amount = 0.00;
    invoiceLineItems.filter(i => i.status !== 'CANCELLED' && i.isDeleted === false).forEach((item) => {
        if ((Enums.EquipmentSpecifics.doesNameExist(item.itemType) || Enums.LoadRequirements.doesNameExist(item.itemType) || Enums.StopFees.doesNameExist(item.itemType)) && item.fromEntityType === 'SHIPPER') {
            amount += Number(item.totalAmount);
        } else if ((Enums.EquipmentSpecifics.doesNameExist(item.itemType) || Enums.LoadRequirements.doesNameExist(item.itemType) || Enums.StopFees.doesNameExist(item.itemType)) && item.toEntityType === 'SHIPPER') {
            amount -= Number(item.totalAmount);
        }
    });

    return Number(amount);
};

const carrierAddOns = (invoiceLineItems) => {
    let amount = 0.00;
    invoiceLineItems.filter(i => i.status !== 'CANCELLED' && i.isDeleted === false).forEach((item) => {
        if ((Enums.EquipmentSpecifics.doesNameExist(item.itemType) || Enums.LoadRequirements.doesNameExist(item.itemType) || Enums.StopFees.doesNameExist(item.itemType)) && item.fromEntityType === 'CARRIER') {
            amount -= Number(item.totalAmount);
        } else if ((Enums.EquipmentSpecifics.doesNameExist(item.itemType) || Enums.LoadRequirements.doesNameExist(item.itemType) || Enums.StopFees.doesNameExist(item.itemType)) && item.toEntityType === 'CARRIER') {
            amount += Number(item.totalAmount);
        }
    });

    return Number(amount);
};

const shipperRatePerMile = (invoiceLineItems) => {
    let amount = 0.00;
    invoiceLineItems.filter(i => i.status !== 'CANCELLED' && i.isDeleted === false).forEach((item) => {
        if (item.fromEntityType === 'SHIPPER' && item.quantityUnit === 'PER_MILE') {
            amount += Number(item.baseAmount);
        } else if (item.toEntityType === 'SHIPPER' && item.quantityUnit === 'PER_MILE') {
            amount -= Number(item.baseAmount);
        }
    });

    return Number(amount);
};

const carrierRatePerMile = (invoiceLineItems) => {
    let amount = 0.00;
    invoiceLineItems.filter(i => i.status !== 'CANCELLED' && i.isDeleted === false).forEach((item) => {
        if (item.fromEntityType === 'CARRIER' && item.quantityUnit === 'PER_MILE') {
            amount -= Number(item.baseAmount);
        } else if (item.toEntityType === 'CARRIER' && item.quantityUnit === 'PER_MILE') {
            amount += Number(item.baseAmount);
        }
    });

    return Number(amount);
};

const carrierPerMileTotalAmount = (invoiceLineItems) => {
    let amount = 0.00;
    invoiceLineItems.filter(i => i.status !== 'CANCELLED' && i.isDeleted === false).forEach((item) => {
        if (item.fromEntityType === 'CARRIER' && item.quantityUnit === 'PER_MILE') {
            amount -= Number(item.totalAmount);
        } else if (item.toEntityType === 'CARRIER' && item.quantityUnit === 'PER_MILE') {
            amount += Number(item.totalAmount);
        }
    });

    return Number(amount);
};

const carrierTotalAmountWithAddOns = (invoiceLineItems) => {
    let amount = 0.00;
    invoiceLineItems.filter(i => i.status !== 'CANCELLED' && i.isDeleted === false).forEach((item) => {
        if (item.fromEntityType === 'CARRIER') {
            amount -= Number(item.totalAmount);
        } else if (item.toEntityType === 'CARRIER') {
            amount += Number(item.totalAmount);
        }
    });

    return Number(amount);
};

const shipperPerMileTotalAmount = (invoiceLineItems) => {
    let amount = 0.00;
    invoiceLineItems.filter(i => i.status !== 'CANCELLED' && i.isDeleted === false).forEach((item) => {
        if (item.fromEntityType === 'SHIPPER' && item.quantityUnit === 'PER_MILE') {
            amount += Number(item.totalAmount);
        } else if (item.toEntityType === 'SHIPPER' && item.quantityUnit === 'PER_MILE') {
            amount -= Number(item.totalAmount);
        }
    });

    return Number(amount);
};

const shipperTotalAmountWithAddOns = (invoiceLineItems) => {
    let amount = 0.00;
    invoiceLineItems.filter(i => i.status !== 'CANCELLED' && i.isDeleted === false).forEach((item) => {
        if (item.fromEntityType === 'SHIPPER') {
            amount += Number(item.totalAmount);
        } else if (item.toEntityType === 'SHIPPER') {
            amount -= Number(item.totalAmount);
        }
    });

    return Number(amount);
};

const shipperTransactionTotalAmount = (transactions) => {
    let amount = 0.00;
    transactions.filter(t => t.paymentStatus === 'COMPLETED' && t.isDeleted === false).forEach((item) => {
        if (item.fromEntityType === 'SHIPPER') {
            amount += Number(item.netAmount);
        } else if (item.toEntityType === 'SHIPPER') {
            amount -= Number(item.netAmount);
        }
    });

    return Number(amount);
};

const carrierTransactionTotalAmount = (transactions) => {
    let amount = 0.00;
    transactions.filter(t => t.paymentStatus === 'COMPLETED' && t.isDeleted === false).forEach((item) => {
        if (item.fromEntityType === 'CARRIER') {
            amount -= Number(item.netAmount);
        } else if (item.toEntityType === 'CARRIER') {
            amount += Number(item.netAmount);
        }
    });

    return Number(amount);
};

const serviceFeePerMile = (invoiceLineItems) => {
    let amount = 0.00;
    invoiceLineItems.filter(i => i.status !== 'CANCELLED' && i.isDeleted === false).forEach((item) => {
        if (item.fromEntityType === 'STAFF' && item.isIrisFee === true && item.quantityUnit === 'PER_MILE') {
            amount -= Number(item.baseAmount);
        } else if (item.toEntityType === 'STAFF' && item.isIrisFee === true && item.quantityUnit === 'PER_MILE') {
            amount += Number(item.baseAmount);
        }
    });

    return Number(amount);
};

const serviceFee = (invoiceLineItems) => {
    let amount = 0.00;
    invoiceLineItems.filter(i => i.status !== 'CANCELLED' && i.isDeleted === false).forEach((item) => {
        if (item.fromEntityType === 'STAFF' && item.isIrisFee === true) {
            amount -= Number(item.baseAmount);
        } else if (item.toEntityType === 'STAFF' && item.isIrisFee === true) {
            amount += Number(item.baseAmount);
        }
    });

    return Number(amount);
};

const irisRatePerMile = (invoiceLineItems) => {
    let amount = 0.00;
    invoiceLineItems.filter(i => i.status !== 'CANCELLED' && i.isDeleted === false).forEach((item) => {
        if (item.fromEntityType === 'STAFF' && item.quantityUnit === 'PER_MILE') {
            amount -= Number(item.baseAmount);
        } else if (item.toEntityType === 'STAFF' && item.quantityUnit === 'PER_MILE') {
            amount += Number(item.baseAmount);
        }
    });

    return Number(amount);
};

const irisPerMileTotalAmount = (invoiceLineItems) => {
    let amount = 0.00;
    invoiceLineItems.filter(i => i.status !== 'CANCELLED' && i.isDeleted === false).forEach((item) => {
        if (item.fromEntityType === 'STAFF' && item.quantityUnit === 'PER_MILE') {
            amount -= Number(item.totalAmount);
        } else if (item.toEntityType === 'STAFF' && item.quantityUnit === 'PER_MILE') {
            amount += Number(item.totalAmount);
        }
    });

    return Number(amount);
};

const irisTotalAmount = (invoiceLineItems) => {
    let amount = 0.00;
    invoiceLineItems.filter(i => i.status !== 'CANCELLED' && i.isDeleted === false).forEach((item) => {
        if (item.fromEntityType === 'STAFF') {
            amount -= Number(item.totalAmount);
        } else if (item.toEntityType === 'STAFF') {
            amount += Number(item.totalAmount);
        }
    });

    return Number(amount);
};

const irisFeePerMile = (shipperTotal, carrierTotal, practicalRouteMiles) => {
    let amount = 0.00;
    if (isNumberGreaterThanZero(shipperTotal) && isNumberGreaterThanZero(carrierTotal) && isNumberGreaterThanZero(practicalRouteMiles)) {
        amount = (shipperTotal - carrierTotal) / practicalRouteMiles;
    }

    return Number(amount);
};

const irisTotal = (shipperTotal, carrierTotal) => {
    let amount = 0.00;
    if (isNumberGreaterThanZero(shipperTotal) && isNumberGreaterThanZero(carrierTotal)) {
        amount = shipperTotal - carrierTotal;
    }

    return Number(amount);
};

const factoringTotalAmount = (invoiceLineItems) => {
    let amount = 0.00;
    invoiceLineItems.filter(i => i.status !== 'CANCELLED' && i.isDeleted === false).forEach((item) => {
        if (item.fromEntityType === 'FACTORING') {
            amount -= Number(item.totalAmount);
        } else if (item.toEntityType === 'FACTORING') {
            amount += Number(item.totalAmount);
        }
    });

    return Number(amount);
};

const factoringTransactionTotalAmount = (transactions) => {
    let amount = 0.00;
    transactions.filter(t => t.paymentStatus === 'COMPLETED' && t.isDeleted === false).forEach((item) => {
        if (item.fromEntityType === 'FACTORING') {
            amount -= Number(item.netAmount);
        } else if (item.toEntityType === 'FACTORING') {
            amount += Number(item.netAmount);
        }
    });

    return Number(amount);
};

const newLoadPricingSummary = (
    baseRatePerMileAmount,
    fuelSurchargePerMileAmount,
    shipperContractRateAmount,
    carrierContractRateAmount,
    practicalRouteMiles,
    invoiceLineItems
) => {
    let shipperAmount = shipperTotalAmountWithAddOns(invoiceLineItems);
    let carrierAmount = carrierTotalAmountWithAddOns(invoiceLineItems);
    let IRISAmount = irisPerMileTotalAmount(invoiceLineItems);
    let feePerMileAmount = serviceFeePerMile(invoiceLineItems);
    let addOnChargeAmount = shipperAddOns(invoiceLineItems);
    let addOnCostAmount = carrierAddOns(invoiceLineItems);
    let IRISPercentage = 0.00;
    let shipperRatePerMileAmount = 0.00;
    let carrierRatePerMileAmount = 0.00;

    if (isNumberNotEmpty(baseRatePerMileAmount)) {
        shipperAmount += Number((Number(baseRatePerMileAmount) * Number(practicalRouteMiles)).toFixed(2));
        carrierAmount += Number((-1.0 * Number(baseRatePerMileAmount) * Number(practicalRouteMiles)).toFixed(2));
    }
    if (isNumberNotEmpty(fuelSurchargePerMileAmount)) {
        shipperAmount += Number((Number(fuelSurchargePerMileAmount) * Number(practicalRouteMiles)).toFixed(2));
        carrierAmount += Number((-1.0 * Number(fuelSurchargePerMileAmount) * Number(practicalRouteMiles)).toFixed(2));
    }
    if (isNumberNotEmpty(shipperContractRateAmount)) {
        shipperAmount += Number(shipperContractRateAmount);
    }
    if (isNumberNotEmpty(carrierContractRateAmount)) {
        carrierAmount += Number(carrierContractRateAmount);
    }

    IRISAmount = shipperAmount - carrierAmount;
    IRISPercentage = isNumberGreaterThanZero(shipperAmount) && isNumberGreaterThanZero(carrierAmount) ? ((shipperAmount - carrierAmount) / shipperAmount) : 0.00;
    shipperRatePerMileAmount = isNumberGreaterThanZero(shipperAmount) && isNumberGreaterThanZero(Number(practicalRouteMiles)) ? shipperAmount / Number(practicalRouteMiles) : 0.00;
    carrierRatePerMileAmount = isNumberGreaterThanZero(carrierAmount) && isNumberGreaterThanZero(Number(practicalRouteMiles)) ? carrierAmount / Number(practicalRouteMiles) : 0.00;

    return {
        shipperAmount: shipperAmount,
        carrierAmount: carrierAmount,
        IRISAmount: IRISAmount,
        feePerMileAmount: feePerMileAmount,
        shipperRatePerMileAmount: shipperRatePerMileAmount,
        carrierRatePerMileAmount: carrierRatePerMileAmount,
        addOnChargeAmount: addOnChargeAmount,
        addOnCostAmount: addOnCostAmount,
        IRISPercentage: IRISPercentage,
    };
};

const loadPricingSummary = (invoiceLineItems, transactions) => {
    let shipperAmount = Number((shipperPerMileTotalAmount(invoiceLineItems)).toFixed(2));
    let carrierAmount = Number((carrierPerMileTotalAmount(invoiceLineItems)).toFixed(2));
    let shipperAmountWithAddOns = Number((shipperTotalAmountWithAddOns(invoiceLineItems)).toFixed(2));
    let carrierAmountWithAddOns = Number((carrierTotalAmountWithAddOns(invoiceLineItems)).toFixed(2));
    let factoringAmount = Number((factoringTotalAmount(invoiceLineItems)).toFixed(2));
    let shipperClaimAmount = Number((shipperClaims(invoiceLineItems)).toFixed(2));
    let carrierClaimAmount = Number((carrierClaims(invoiceLineItems)).toFixed(2));
    let feePerMileAmount = Number((serviceFeePerMile(invoiceLineItems)).toFixed(2));
    let feeAmount = Number((serviceFee(invoiceLineItems)).toFixed(2));
    let shipperLoadRequirementAmount = Number((shipperLoadRequirementFees(invoiceLineItems)).toFixed(2));
    let shipperAccessorialAmount = Number((shipperAccessorialFees(invoiceLineItems)).toFixed(2));
    let shipperStopFeeAmount = Number((shipperStopFees(invoiceLineItems)).toFixed(2));
    let shipperEquipmentSpecificAmount = Number((shipperEquipmentSpecificFees(invoiceLineItems)).toFixed(2));
    let carrierLoadRequirementAmount = Number((carrierLoadRequirementFees(invoiceLineItems)).toFixed(2));
    let carrierAccessorialAmount = Number((carrierAccessorialFees(invoiceLineItems)).toFixed(2));
    let carrierStopFeeAmount = Number((carrierStopFees(invoiceLineItems)).toFixed(2));
    let carrierEquipmentSpecificAmount = Number((carrierEquipmentSpecificFees(invoiceLineItems)).toFixed(2));
    let shipperRatePerMileAmount = Number((shipperRatePerMile(invoiceLineItems)).toFixed(2));
    let carrierRatePerMileAmount = Number((carrierRatePerMile(invoiceLineItems)).toFixed(2));
    let addOnChargeAmount = Number((shipperAddOns(invoiceLineItems)).toFixed(2));
    let addOnCostAmount = Number((carrierAddOns(invoiceLineItems)).toFixed(2));
    let shipperTransactionAmount = Number((shipperTransactionTotalAmount(transactions)).toFixed(2));
    let carrierTransactionAmount = Number((carrierTransactionTotalAmount(transactions)).toFixed(2));
    let factoringTransactionAmount = Number((factoringTransactionTotalAmount(transactions)).toFixed(2));
    let IRISAmount = Number((Number(shipperTotalAmountWithAddOns(invoiceLineItems)) - Number(carrierTotalAmountWithAddOns(invoiceLineItems)) - Number(factoringTotalAmount(invoiceLineItems))).toFixed(2));
    let IRISPercentage = Number((IRISAmount > 0 ? (IRISAmount / Number(shipperTotalAmountWithAddOns(invoiceLineItems))) : 0.00).toFixed(2));
    let shipperBalance = Number((shipperTotalAmountWithAddOns(invoiceLineItems) - shipperTransactionTotalAmount(transactions)).toFixed(2));
    let carrierBalance = Number((carrierTotalAmountWithAddOns(invoiceLineItems) - carrierTransactionTotalAmount(transactions)).toFixed(2));
    let factoringBalance = Number((factoringTotalAmount(invoiceLineItems) - factoringTransactionTotalAmount(transactions)).toFixed(2));

    return {
        shipperAmount,
        carrierAmount,
        shipperAmountWithAddOns,
        carrierAmountWithAddOns,
        feePerMileAmount,
        feeAmount,
        shipperClaimAmount,
        carrierClaimAmount,
        shipperLoadRequirementAmount,
        shipperAccessorialAmount,
        shipperStopFeeAmount,
        shipperEquipmentSpecificAmount,
        carrierLoadRequirementAmount,
        carrierAccessorialAmount,
        carrierStopFeeAmount,
        carrierEquipmentSpecificAmount,
        shipperRatePerMileAmount,
        carrierRatePerMileAmount,
        addOnChargeAmount,
        addOnCostAmount,
        shipperTransactionAmount,
        carrierTransactionAmount,
        IRISPercentage,
        IRISAmount,
        shipperBalance,
        carrierBalance,
        factoringTransactionAmount,
        factoringAmount,
        factoringBalance
    };
};

const calculateSourceTotals = (invoiceLineItems, transactions) => {
    let owedByIRIS = 0.0;
    let owedByShipper = 0.0;
    let owedByCarrier = 0.0;
    let owedByFactoring = 0.0;
    let owedToIRIS = 0.0;
    let owedToShipper = 0.0;
    let owedToCarrier = 0.0;
    let owedToFactoring = 0.0;

    let paidByIRIS = 0.0;
    let paidByShipper = 0.0;
    let paidByCarrier = 0.0;
    let paidByFactoring = 0.0;
    let paidToIRIS = 0.0;
    let paidToShipper = 0.0;
    let paidToCarrier = 0.0;
    let paidToFactoring = 0.0;

    let irisInvoiceAmount = 0.00;
    let carrierInvoiceAmount = 0.00;
    let shipperInvoiceAmount = 0.00;
    let factoringInvoiceAmount = 0.00;

    let irisTransactionAmount = 0.00;
    let carrierTransactionAmount = 0.00;
    let shipperTransactionAmount = 0.00;
    let factoringTransactionAmount = 0.00;

    invoiceLineItems.filter(i => i.status !== 'CANCELLED' && i.fromEntityType !== undefined && i.fromEntityType !== null && i.toEntityType !== undefined && i.toEntityType !== null && i.isDeleted === false).forEach((item) => {
        switch (item.fromEntityType) {
            case 'SHIPPER':
                shipperInvoiceAmount += item.totalAmount;
                owedByShipper += item.totalAmount;
                break;
            case 'CARRIER':
                carrierInvoiceAmount -= item.totalAmount;
                owedByCarrier += item.totalAmount;
                break;
            case 'STAFF':
                irisInvoiceAmount -= item.totalAmount;
                owedByIRIS += item.totalAmount;
                break;
            case 'FACTORING':
                factoringInvoiceAmount -= item.totalAmount;
                owedByFactoring += item.totalAmount;
                break;
        }

        switch (item.toEntityType) {
            case 'SHIPPER':
                shipperInvoiceAmount -= item.totalAmount;
                owedToShipper += item.totalAmount;
                break;
            case 'CARRIER':
                carrierInvoiceAmount += item.totalAmount;
                owedToCarrier += item.totalAmount;
                break;
            case 'STAFF':
                irisInvoiceAmount += item.totalAmount;
                owedToIRIS += item.totalAmount;
                break;
            case 'FACTORING':
                factoringInvoiceAmount += item.totalAmount;
                owedToFactoring += item.totalAmount;
                break;
        }
    });

    transactions.filter(t => t.paymentStatus === 'COMPLETED' && t.fromEntityType !== undefined && t.fromEntityType !== null && t.toEntityType !== undefined && t.toEntityType !== null && t.isDeleted === false).forEach((item) => {
        switch (item.fromEntityType) {
            case 'SHIPPER':
                shipperTransactionAmount += item.netAmount;
                paidByShipper += item.netAmount;
                break;
            case 'CARRIER':
                carrierTransactionAmount -= item.netAmount;
                paidByCarrier += item.netAmount;
                break;
            case 'STAFF':
                irisTransactionAmount -= item.netAmount;
                paidByIRIS += item.netAmount;
                break;
            case 'FACTORING':
                factoringTransactionAmount -= item.netAmount;
                paidByFactoring += item.netAmount;
                break;
        }

        switch (item.toEntityType) {
            case 'SHIPPER':
                shipperTransactionAmount -= item.netAmount;
                paidToShipper += item.netAmount;
                break;
            case 'CARRIER':
                carrierTransactionAmount += item.netAmount;
                paidToCarrier += item.netAmount;
                break;
            case 'STAFF':
                irisTransactionAmount += item.netAmount;
                paidToIRIS += item.netAmount;
                break;
            case 'FACTORING':
                factoringTransactionAmount += item.netAmount;
                paidToFactoring += item.netAmount;
                break;
        }
    });

    return {
        irisInvoiceAmount,
        carrierInvoiceAmount,
        shipperInvoiceAmount,
        factoringInvoiceAmount,
        irisTransactionAmount,
        carrierTransactionAmount,
        shipperTransactionAmount,
        factoringTransactionAmount,
        owedByIRIS,
        owedByShipper,
        owedByCarrier,
        owedByFactoring,
        owedToIRIS,
        owedToShipper,
        owedToCarrier,
        owedToFactoring,
        paidByIRIS,
        paidByShipper,
        paidByCarrier,
        paidByFactoring,
        paidToIRIS,
        paidToShipper,
        paidToCarrier,
        paidToFactoring
    };
};

export default {
    shipperRatePerMile,
    serviceFeePerMile,
    serviceFee,
    shipperLoadRequirementFees,
    shipperAccessorialFees,
    shipperStopFees,
    shipperEquipmentSpecificFees,
    carrierLoadRequirementFees,
    carrierAccessorialFees,
    carrierStopFees,
    carrierEquipmentSpecificFees,
    shipperAddOns,
    carrierAddOns,
    carrierRatePerMile,
    carrierPerMileTotalAmount,
    carrierTotalAmountWithAddOns,
    shipperPerMileTotalAmount,
    shipperTotalAmountWithAddOns,
    shipperTransactionTotalAmount,
    carrierTransactionTotalAmount,
    shipperClaims,
    carrierClaims,
    newLoadPricingSummary,
    loadPricingSummary,
    calculateSourceTotals,
    irisRatePerMile,
    irisFeePerMile,
    irisPerMileTotalAmount,
    factoringTotalAmount,
    factoringTransactionTotalAmount,
    irisTotalAmount,
    irisTotal
};