import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import { DatePicker } from "antd";
import moment from 'moment';

const DatePickerAutoAccept = React.forwardRef((props, ref) => {
    // const datePickerRef = useRef();

    const onBlur = (e) => {
        // console.log(e.target)
        // console.log(e.target.value)
        // console.log(ref.current)
        // const datePickerDomNode = ReactDOM.findDOMNode(ref.current);
        // console.log(datePickerDomNode);
        // const dateInput = datePickerDomNode.querySelectorAll('.ant-picker-input input');
        // console.log(dateInput.value);
        let value = moment(e.target.value, props.format)
        if (value && value.isValid() && props.onChange) {
            props.onChange(value, e.target.value)
        }
    };

    return <DatePicker {...props} onBlur={onBlur} ref={ref} />
});

export default DatePickerAutoAccept;

// import moment, { Moment } from 'moment';
// import { PickerProps } from 'antd/lib/date-picker/generatePicker';

// const DatePickerAutoAccept = (props: PickerProps<Moment>) => {
//     const onBlur = (elem: React.FocusEvent<HTMLInputElement>) => {
//         let value = moment(elem.target.value, props.format)
//         if (value && value.isValid() && props.onChange) {
//             props.onChange(value, elem.target.value)
//         }
//     }

//     return <DatePicker { ...props } onBlur={onBlur} />
// }