import Checks from '../entitlementUtils';

export const canCreateScheduledShift = (auth, accountId) => {
    return Checks.allowStaff(auth) || Checks.isAccountOperationsAdmin(auth, accountId);
};

export const canReadScheduledShift = (auth, accountId) => {
    return Checks.allowStaff(auth) || Checks.isAccountOperationsAdmin(auth, accountId);
};

export const canReadScheduledShiftList = (auth, accountId) => {
    return Checks.allowStaff(auth) || Checks.isAccountOperationsAdmin(auth, accountId);
};

export const canUpdateScheduledShift = (auth, accountId) => {
    return Checks.allowStaff(auth) || Checks.isAccountOperationsAdmin(auth, accountId);
};

export const canDeleteScheduledShift = (auth) => {
    return Checks.allowStaffAdmin(auth);
};