import React from 'react';
import { isBooleanFalse, isStringNotEmpty } from '../../shared/objectUtils';
import StringFormatter from '../../shared/stringFormatter';

const stringFormatter = new StringFormatter();

const DataTemperatureRangeRow = ({ className = null, valueClassName = null, titleClassName = null, style = null, valueStyle = null, titleStyle = { fontWeight: 'bold' }, noColon = false, title = null, min, minUnits, max, maxUnits }) => {
    const minTemperature = stringFormatter.toFormattedString('Temperature', min, minUnits);
    const maxTemperature = stringFormatter.toFormattedString('Temperature', max, maxUnits);

    return (
        <div className={className} style={style}>
            {isStringNotEmpty(title) ? (<span className={titleClassName} style={titleStyle}>{title}{isBooleanFalse(noColon) ? ':' : ''} </span>) : null}
            {isStringNotEmpty(minTemperature) && isStringNotEmpty(maxTemperature) ? (<span className={valueClassName} style={valueStyle}>{minTemperature} - {maxTemperature}</span>) : null}
        </div>
    );
};

export default DataTemperatureRangeRow;