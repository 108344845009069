import axiosAuthenticated from "../../api/axiosAuthenticated";
import { isNotNullOrUndefined } from "../../shared/objectUtils";

const statesPath = '/states';

//#region States Methods

export const getAllStates = async () => {
    const statesRes = await axiosAuthenticated.get(statesPath, { params: { page: 1, size: 1000000, sort: 'stateName', order: 'asc' } });
    if (statesRes && statesRes.status === 200) {
        return statesRes.data.data;
    }

    return [];
};

export const getStates = async (searchParams = {}, pagination = {}) => {
    const statesRes = await axiosAuthenticated.get(statesPath, { params: { ...searchParams } });
    if (statesRes && statesRes.status === 200) {
        const states = statesRes.data.data;
        const otherData = statesRes.data;

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;

        return { data: states, searchParams: searchParams, pagination: pagination };
    }

    return { data: [], searchParams: searchParams, pagination: pagination };
};

export const addState = async (payload) => {
    const statesRes = await axiosAuthenticated.post(statesPath, { ...payload });
    if (statesRes && statesRes.status === 201) {
        return statesRes.data;
    }

    return null;
};

// there is no isDeleted field for states so only updating states here
export const updateState = async (id, payload) => {
    const statesRes = await axiosAuthenticated.put(statesPath + `/${id}`, { ...payload });
    if (statesRes && statesRes.status === 200) {
        return statesRes.data;
    }

    return null;
};

//#endregion