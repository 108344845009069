import React from 'react';
import { Avatar, Tooltip } from "antd";
import classes from './MessageReceived.module.scss';
import MomentDate from '../../shared/dateFormatter';

const MessageReceived = ({ message }) => {
    return (
        <div className={classes.incomingMessage} key={message.id}>
            <div className={classes.incomingMessageImage}>
                <Tooltip title={`${message.senderFirstName} ${message.senderLastName}`}><Avatar style={{ backgroundColor: '#87d068' }}>{`${message.senderFirstName.charAt(0)}${message.senderLastName.charAt(0)}`}</Avatar></Tooltip>
            </div>
            <div className={classes.receivedMessage}>
                <div className={classes.receivedMessageWithDate}>
                    <p>{message.message}</p>
                    <span className={classes.timeDate}>{new MomentDate().fromUtcToDeviceTimeZoneForMessages(message.createdAt)}</span>
                </div>
            </div>
        </div>
    );
};

export default MessageReceived;