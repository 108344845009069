import axiosAuthenticated from "../../api/axiosAuthenticated";
import { omit } from "lodash";
import { addFile, updateFile } from "../../api/fileClient";
import * as DTO from "../dtos/index";
import * as Data from "./index";
import { isListNotEmpty, isNotNullOrUndefined, isStringEmpty, isStringNotEmpty } from "../../shared/objectUtils";

const userProfilesPath = '/api/userProfiles/';
const systemUsersBulkPath = '/systemUsers/bulk/get';
const systemUsersPath = '/systemUsers';

//#region Users Methods

export const getUsersByUserIds = async (userIds = []) => {
    let users = [];
    if (isListNotEmpty(userIds)) {
        const usersRes = await axiosAuthenticated.post(systemUsersBulkPath, { userIds: [...userIds] });
        if (usersRes && usersRes.status === 200) {
            users = usersRes.data;
        }
    }

    return users;
};

export const getUsersByEmailAddress = async (email, config = {}) => {
    let users = [];
    if (isStringNotEmpty(email)) {
        const usersRes = await axiosAuthenticated.post(systemUsersBulkPath, { email: email }, config);
        if (usersRes && usersRes.status === 200) {
            users = usersRes.data;
        }
    }

    return users;
};

export const getUsersByPhoneNumber = async (phone, config = {}) => {
    let users = [];
    if (isStringNotEmpty(phone)) {
        const usersRes = await axiosAuthenticated.post(systemUsersBulkPath, { phone: phone }, config);
        if (usersRes && usersRes.status === 200) {
            users = usersRes.data;
        }
    }

    return users;
};

export const getAllStaff = async () => {
    const usersRes = await axiosAuthenticated.get(userProfilesPath, { params: { limit: 1000000, isStaff: 'True' } });
    if (usersRes && usersRes.status === 200) {
        return usersRes.data.results;
    }

    return [];
};

export const getUsers = async (searchParams = {}, pagination = {}) => {
    const usersRes = await axiosAuthenticated.get(userProfilesPath, { params: { ...searchParams } });
    if (usersRes && usersRes.status === 200) {
        const users = usersRes.data.results;

        let userIds = users.map((user) => { return user.id; });
        let userDocuments = await Data.getDocumentsByEntityIds([...userIds]);

        const transformedUsers = users.map((user) => { return DTO.getUserDTO(user, userDocuments); });

        // Read total count from server
        pagination.total = usersRes.data.count;
        pagination.pageSize = isNotNullOrUndefined(searchParams.limit) ? searchParams.limit : pagination.pageSize;

        return { data: transformedUsers, searchParams: searchParams, pagination: pagination };
    }

    return { data: [], searchParams: searchParams, pagination: pagination };
};

export const getUser = async (id) => {
    const usersRes = await axiosAuthenticated.get(userProfilesPath + `${id}/`);
    if (usersRes && usersRes.status === 200) {
        let user = usersRes.data;

        let userDocuments = await Data.getDocumentsByEntityIds([user.id]);

        return DTO.getUserDTO(user, userDocuments);
    }

    return null;
};

export const addUser = async (payload) => {
    const profileImage = payload.profileImage;
    payload = omit(payload, "profileImage");

    const usersRes = await axiosAuthenticated.post(systemUsersPath, { ...payload });
    if (usersRes && usersRes.status === 201) {
        let newUser = usersRes.data;
        await addFile(profileImage, "USER", newUser.userProfileId, "PROFILE_PHOTO", "Profile Image", "", "ALL", null, null, null, null, [{ entityType: 'USER', entityId: newUser.userProfileId }]);

        let userDocuments = await Data.getDocumentsByEntityIds([newUser.id]);

        return DTO.getUserDTO(newUser, userDocuments);
    }

    return null;
};

export const updateUser = async (id, payload) => {
    let profileImageId = isStringEmpty(payload.profileImageId) ? payload.profileImageId : null;
    let profileImage = isNotNullOrUndefined(payload.profileImage) ? payload.profileImage : null;

    if (isStringNotEmpty(profileImageId) && isNotNullOrUndefined(profileImage)) {
        const document = await updateFile(profileImageId, profileImage);
        profileImageId = document ? document.id : null;
    } else if (isNotNullOrUndefined(profileImage) && isStringEmpty(profileImageId)) {
        const document = await addFile(profileImage, "USER", id, "PROFILE_PHOTO", "Profile Image", "", "ALL", null, null, null, null, [{ entityType: 'USER', entityId: id }]);
        profileImageId = document ? document.id : null;
    }

    payload = omit(payload, "profileImage", "profileImageId");

    const usersRes = await axiosAuthenticated.put(systemUsersPath + `/${id}`, { ...payload });
    if (usersRes && usersRes.status === 200) {
        let updatedUser = usersRes.data;

        return DTO.getUserDTOWithProfileImageId(updatedUser, profileImageId);
    }

    return null;
};

export const updateUserProfile = async (id, userId, firstName, lastName, phoneNumber, isActive = false) => {
    // Update User Profile Data
    const userProfileRes = await axiosAuthenticated.put(userProfilesPath + `${id}/`, { firstName: firstName, lastName: lastName, phoneNumber: phoneNumber, createdBy: userId, modifiedBy: userId, isActive: isActive });
    if (userProfileRes && userProfileRes.status === 200) {
        return true;
    } else {
        return false;
    }
};

//#endregion