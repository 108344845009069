import React, { useMemo, useState } from 'react';
import { Alert, Input, Select, Spin, InputNumber, Switch } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import UploadFile from "../UploadFile/UploadFile";
import Enums from '../../shared/enums';
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import FormItemFile from '../FormItemFile/FormItemFile';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import { isObjectNotEmpty } from '../../shared/objectUtils';

const { Option } = Select;

const EditAsset = ({ cancel, asset }) => {
    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.assets.isRecordUpdateLoading);
    const error = useSelector(state => state.assets.updateRecordError);
    const trailerTypes = useSelector(state => state.orchestrator.trailerTypes);
    const states = useSelector(state => state.orchestrator.states);

    const lengthUnitOptions = Enums.LengthUnits.selectListOptions();
    const volumeUnitOptions = Enums.VolumeUnits.selectListOptions();
    const distanceUnitOptions = Enums.DistanceUnits.selectListOptions();
    const weightUnitOptions = Enums.WeightUnits.selectListOptions();
    const assetOutOfOrderReasonOptions = Enums.AssetOutOfOrderReasons.selectListOptions();
    const assetStatusOptions = Enums.AssetStatuses.selectListOptions();
    const assetTypeOptions = Enums.AssetTypes.selectListOptions();
    const truckOwnerTypeOptions = Enums.TruckOwnerTypes.selectListOptions();

    const [photoFile, setPhotoFile] = useState(null);
    const [licensePlateFile, setLicensePlateFile] = useState(null);
    const [insuranceFile, setInsuranceFile] = useState(null);
    const [registrationFile, setRegistrationFile] = useState(null);
    const [showTrailerTypes, setShowTrailerTypes] = useState(false);

    const onSubmit = (data) => {
        if (data.assetType === 'TRACTOR') {
            data.trailerTypeId = null;
        }

        let payload = {};
        let objectToUpdate = { ...asset };
        for (let [key, value] of Object.entries(data)) {
            let oldValue = objectToUpdate[key] !== undefined && objectToUpdate[key] !== null ? objectToUpdate[key] : '';
            if (value !== oldValue) {
                //console.log(`${key}: new value - ${value}, old value - ${oldValue}`);
                payload[key] = value;
            }
        }

        if (photoFile) {
            payload.photoFile = photoFile;
            payload.photoFileId = asset.photoFileId
        }
        if (licensePlateFile) {
            payload.licensePlateFile = licensePlateFile;
            payload.licensePlateFileId = asset.licensePlateFileId
        }
        if (insuranceFile) {
            payload.insuranceFile = insuranceFile;
            payload.insuranceFileId = asset.insuranceFileId
        }
        if (registrationFile) {
            payload.registrationFile = registrationFile;
            payload.registrationFileId = asset.registrationFileId
        }

        if (isObjectNotEmpty(payload)) {
            dispatch(actionCreators.updateAsset(asset.id, payload));
        } else {
            onCancel();
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.updateAssetCancel());
        cancel();
    };

    const onChangeAssetType = (value) => {
        //console.log(`selected ${value}`);
        if (value === 'TRAILER' || value === 'COMBINED') {
            setShowTrailerTypes(true);
        } else {
            setShowTrailerTypes(false);
        }
    };

    useMemo(() => {
        dispatch(actionCreators.getTrailerTypes());
        dispatch(actionCreators.getStates());
    }, []);

    useMemo(() => {
        if (isObjectNotEmpty(asset)) {
            if (asset.assetType === 'TRAILER' || asset.assetType === 'COMBINED') {
                setShowTrailerTypes(true);
            } else {
                setShowTrailerTypes(false);
            }
        }
    }, [asset]);

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateAssetErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const formItemLayoutDouble = {
        labelCol1: { span: 24 },
        wrapperCol1: { span: 16 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 8 },
        helpCol: { offset: 8, span: 16 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Fieldset legend="Asset Name, Asset Type, and License Plate Information">
                        <FormItem {...formItemLayout} label="Asset Name" format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Asset Name (optional)" ref={ref} />}
                            rules={{ required: false }}
                            name="name"
                            defaultValue={asset.name}
                        />
                        <FormItem {...formItemLayout} label="Asset Type" required format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select an Asset Type"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChangeAssetType(selected); onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    ref={ref}
                                >
                                    {assetTypeOptions}
                                </Select>
                            )}
                            rules={{ required: "Required Field" }}
                            defaultValue={asset.assetType}
                            name="assetType"
                            help={
                                <div>
                                    <b>What to choose:</b>
                                    <ul>
                                        <li>Tractor: Power Unit</li>
                                        <li>Trailer: Trailer Only - you will then specify what type of trailer it is.</li>
                                        <li>Truck: Vehicle that has a power unit and a trailer/deck but is not detachable.</li>
                                    </ul>
                                </div>
                            }
                        />
                        {(showTrailerTypes === true) ? (
                            <FormItem {...formItemLayout} label="Trailer Type" required format="horizontal"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please select a Trailer Type"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        showSearch={true}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        ref={ref}
                                    >
                                        {trailerTypes.map(i => <Option value={i.id} key={i.id}>{i.description}</Option>)}
                                    </Select>
                                )}
                                rules={{ required: "Required Field" }}
                                name="trailerTypeId"
                                defaultValue={asset.trailerTypeId}
                            />
                        ) : null}
                        <FormItem {...formItemLayout} label="License Plate #" required format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="License Plate #" ref={ref} />}
                            rules={{ required: "Required Field" }}
                            name="licensePlateNum"
                            defaultValue={asset.licensePlateNum}
                        />
                        <FormItem {...formItemLayout} label="License Plate State" required format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select a State"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    ref={ref}
                                >
                                    {states.map(i => <Option value={i.stateAbbr} key={i.stateAbbr}>{i.stateName}</Option>)}
                                </Select>
                            )}
                            rules={{ required: "Required Field" }}
                            name="licensePlateState"
                            defaultValue={asset.licensePlateState}
                        />
                    </Fieldset>
                    <Fieldset legend="Additional Details (optional)" isCollapsable={true} isCollapsed={false}>
                        <FormItem {...formItemLayout} label="Truck Class" format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Truck Class" ref={ref} />}
                            rules={{ required: false }}
                            name="truckClass"
                            defaultValue={asset.truckClass}
                        />
                        <FormItem {...formItemLayout} label="Truck Owner Type" format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select a Truck Owner Type"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                >
                                    {truckOwnerTypeOptions}
                                </Select>
                            )}
                            rules={{ required: false }}
                            defaultValue={asset.truckOwnerType}
                            name="truckOwnerType"
                        />
                        <FormItem {...formItemLayout} label="VIN" format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="VIN" ref={ref} />}
                            rules={{ required: false }}
                            name="vin"
                            defaultValue={asset.vin}
                        />
                        <FormItem {...formItemLayout} label="DOT #" format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="DOT #" ref={ref} />}
                            rules={{ required: false }}
                            name="dotNum"
                            defaultValue={asset.dotNum}
                        />
                        <FormItem {...formItemLayout} label="Is Available" format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    onBlur={onBlur}
                                    onChange={(checked, event) => { onChange(checked); }}
                                    checked={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            name="isAvailable"
                            defaultValue={asset.isAvailable}
                        />
                        <FormItem {...formItemLayout} label="Status" format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select a Status"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                >
                                    {assetStatusOptions}
                                </Select>
                            )}
                            rules={{ required: false }}
                            defaultValue={asset.status}
                            name="status"
                        />
                        <FormItem {...formItemLayout} label="Out of Order Reason" format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select an Out of Order Reason"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                >
                                    {assetOutOfOrderReasonOptions}
                                </Select>
                            )}
                            rules={{ required: false }}
                            defaultValue={asset.outOfOrderReason}
                            name="outOfOrderReason"
                        />
                        <FormItem {...formItemLayout} label="Asset belongs to Carrier" format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    onBlur={onBlur}
                                    onChange={(checked, event) => { onChange(checked); }}
                                    checked={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            name="isCarrierAsset"
                            defaultValue={asset.isCarrierAsset}
                        />
                        <FormItem {...formItemLayout} label="Asset belongs to Contractor" format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    onBlur={onBlur}
                                    onChange={(checked, event) => { onChange(checked); }}
                                    checked={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            name="isContractorAsset"
                            defaultValue={asset.isContractorAsset}
                        />
                    </Fieldset>
                    <Fieldset legend="Asset Specifications (optional)" isCollapsable={true} isCollapsed={false}>
                        <FormItem {...formItemLayout} label="Make" format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Make" ref={ref} />}
                            rules={{ required: false }}
                            name="make"
                            defaultValue={asset.make}
                        />
                        <FormItem {...formItemLayout} label="Model" format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Model" ref={ref} />}
                            rules={{ required: false }}
                            name="model"
                            defaultValue={asset.model}
                        />
                        <FormItem {...formItemLayout} label="Year" format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Year" ref={ref} />}
                            rules={{ required: false }}
                            name="year"
                            defaultValue={asset.year}
                        />
                        <FormItem {...formItemLayout} label="Color" format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Color" ref={ref} />}
                            rules={{ required: false }}
                            name="color"
                            defaultValue={asset.color}
                        />
                        <FormItem {...formItemLayout} label="Number of Axles" format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <InputNumber
                                    precision={0}
                                    min={1}
                                    max={14}
                                    placeholder="Number of Axles"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            rules={{ required: false }}
                            name="axles"
                            defaultValue={asset.axles}
                        />
                        <FormItemDouble {...formItemLayoutDouble} label1="Fuel Tank Capacity" format="horizontal"
                            render1={({ onChange, onBlur, value, name, ref }) => (
                                <InputNumber
                                    min={0}
                                    placeholder="Fuel Tank Capacity"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            rules1={{ required: false }}
                            name1="fuelTankCapacity"
                            defaultValue1={asset.fuelTankCapacity}
                            render2={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                >
                                    {volumeUnitOptions}
                                </Select>
                            )}
                            rules2={{ required: false }}
                            defaultValue2={asset.fuelTankCapacityUnit}
                            name2="fuelTankCapacityUnit"
                        />
                        <FormItemDouble {...formItemLayoutDouble} label1="Fuel Range" format="horizontal"
                            render1={({ onChange, onBlur, value, name, ref }) => (
                                <InputNumber
                                    min={0}
                                    placeholder="Fuel Range"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            rules1={{ required: false }}
                            name1="range"
                            defaultValue1={asset.range}
                            render2={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                >
                                    {distanceUnitOptions}
                                </Select>
                            )}
                            rules2={{ required: false }}
                            defaultValue2={asset.rangeUnit}
                            name2="rangeUnit"
                        />
                        <FormItemDouble {...formItemLayoutDouble} label1="Overall Length" format="horizontal"
                            render1={({ onChange, onBlur, value, name, ref }) => (
                                <InputNumber
                                    min={0}
                                    placeholder="Overall Length"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            rules1={{ required: false }}
                            name1="overallLength"
                            defaultValue1={asset.overallLength}
                            render2={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                >
                                    {lengthUnitOptions}
                                </Select>
                            )}
                            rules2={{ required: false }}
                            defaultValue2={asset.overallLengthUnit}
                            name2="overallLengthUnit"
                            help={
                                <div>
                                    <strong>The Overall Length is the total length of the asset including any hitches or other attachments that may overlap with another asset.</strong>
                                </div>
                            }
                        />
                        <FormItemDouble {...formItemLayoutDouble} label1="Length" format="horizontal"
                            render1={({ onChange, onBlur, value, name, ref }) => (
                                <InputNumber
                                    min={0}
                                    placeholder="Length"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            rules1={{ required: false }}
                            name1="length"
                            defaultValue1={asset.length}
                            render2={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                >
                                    {lengthUnitOptions}
                                </Select>
                            )}
                            rules2={{ required: false }}
                            defaultValue2={asset.lengthUnit}
                            name2="lengthUnit"
                            help={
                                <div>
                                    <strong>The Length is the effective length without overlap with another asset.</strong>
                                </div>
                            }
                        />
                        <FormItemDouble {...formItemLayoutDouble} label1="Height" format="horizontal"
                            render1={({ onChange, onBlur, value, name, ref }) => (
                                <InputNumber
                                    min={0}
                                    placeholder="Height"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            rules1={{ required: false }}
                            name1="height"
                            defaultValue1={asset.height}
                            render2={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                >
                                    {lengthUnitOptions}
                                </Select>
                            )}
                            rules2={{ required: false }}
                            defaultValue2={asset.heightUnit}
                            name2="heightUnit"
                        />
                        <FormItemDouble {...formItemLayoutDouble} label1="Width" format="horizontal"
                            render1={({ onChange, onBlur, value, name, ref }) => (
                                <InputNumber
                                    min={0}
                                    placeholder="Width"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            rules1={{ required: false }}
                            name1="width"
                            defaultValue1={asset.width}
                            render2={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                >
                                    {lengthUnitOptions}
                                </Select>
                            )}
                            rules2={{ required: false }}
                            defaultValue2={asset.widthUnit}
                            name2="widthUnit"
                        />
                        <FormItemDouble {...formItemLayoutDouble} label1="Weight" format="horizontal"
                            render1={({ onChange, onBlur, value, name, ref }) => (
                                <InputNumber
                                    min={0}
                                    placeholder="Weight"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            rules1={{ required: false }}
                            name1="weight"
                            defaultValue1={asset.weight}
                            render2={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                >
                                    {weightUnitOptions}
                                </Select>
                            )}
                            rules2={{ required: false }}
                            defaultValue2={asset.weightUnit}
                            name2="weightUnit"
                        />
                        <FormItemDouble {...formItemLayout} label1="Max Load Weight" format="horizontal"
                            render1={({ onChange, onBlur, value, name, ref }) => (
                                <InputNumber
                                    min={0}
                                    placeholder="Max Load Weight"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            rules1={{ required: false }}
                            name1="maxLoadWeight"
                            defaultValue1={asset.maxLoadWeight}
                            render2={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                >
                                    {weightUnitOptions}
                                </Select>
                            )}
                            rules2={{ required: false }}
                            defaultValue2={asset.maxLoadWeightUnit}
                            name2="maxLoadWeightUnit"
                        />
                    </Fieldset>
                    <Fieldset legend="Asset Documents" isCollapsable={true} isCollapsed={false}>
                        <FormItemFile {...formItemLayout} label="Asset Photo" name="photoFile" format="horizontal">
                            <UploadFile
                                beforeUpload={(file) => {
                                    setPhotoFile(file);
                                    return false;
                                }}
                                onRemove={(file) => {
                                    setPhotoFile(null);
                                }}
                                message="Please upload a photo of the asset."
                                fileId={asset.photoFileId}
                            />
                        </FormItemFile>
                        <FormItemFile {...formItemLayout} label="License Plate Photo" name="licensePlateFile" format="horizontal">
                            <UploadFile
                                beforeUpload={(file) => {
                                    setLicensePlateFile(file);
                                    return false;
                                }}
                                onRemove={(file) => {
                                    setLicensePlateFile(null);
                                }}
                                message="Please upload a photo of the license plate."
                                fileId={asset.licensePlateFileId}
                            />
                        </FormItemFile>
                        <FormItemFile {...formItemLayout} label="Insurance Photo" name="insuranceFile" format="horizontal">
                            <UploadFile
                                beforeUpload={(file) => {
                                    setInsuranceFile(file);
                                    return false;
                                }}
                                onRemove={(file) => {
                                    setInsuranceFile(null);
                                }}
                                message="Please upload a photo of the asset's insurance."
                                fileId={asset.insuranceFileId}
                            />
                        </FormItemFile>
                        <FormItemFile {...formItemLayout} label="Registration Photo" name="registrationFile" format="horizontal">
                            <UploadFile
                                beforeUpload={(file) => {
                                    setRegistrationFile(file);
                                    return false;
                                }}
                                onRemove={(file) => {
                                    setRegistrationFile(null);
                                }}
                                message="Please upload a photo of the registration."
                                fileId={asset.registrationFileId}
                            />
                        </FormItemFile>
                    </Fieldset>
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Update Asset" />
            </Form>
        </FormProvider>
    );
};

export default EditAsset;