import React, { useMemo } from 'react';
import { Select, Alert, Spin } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Enums from '../../shared/enums';
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import { isObjectNotEmpty } from '../../shared/objectUtils';

const EditAccountRegistrationReviewStatus = ({ accountRegistration, cancel }) => {

    const accountRegistrationReviewStatusOptions = Enums.AccountRegistrationReviewStatuses.selectListOptions();

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.accountRegistrations.isRecordUpdateLoading);
    const error = useSelector(state => state.accountRegistrations.updateRecordError);

    const onSubmit = (data) => {
        if (isObjectNotEmpty(data)) {
            data.isReviewed = true;
            dispatch(actionCreators.updateAccountRegistration(accountRegistration.id, data));
        } else {
            onCancel();
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.updateAccountRegistrationCancel());
        cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateAccountRegistrationErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <FormProvider {...methods}>
            <Form className="login-form" onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Fieldset legend="Update Review Status of Account Application">
                        <FormItem {...formItemLayout} label="Review Status" format="vertical" required
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please select a Review Status"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    ref={ref}
                                >
                                    {accountRegistrationReviewStatusOptions}
                                </Select>
                            )}
                            rules={{ required: 'Required Field' }}
                            name="reviewStatus"
                            defaultValue={accountRegistration.reviewStatus}
                        />
                    </Fieldset>
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Update Account Application" />
            </Form>
        </FormProvider>
    );
};

export default EditAccountRegistrationReviewStatus;