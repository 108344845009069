import React, { useMemo, useState } from 'react';
import { Alert, Select, Spin } from "antd";
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import UploadFile from "../UploadFile/UploadFile";
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import { isNotNullOrUndefined, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import FormItemFile from '../FormItemFile/FormItemFile';
import FormItem from '../FormItem/FormItem';

const { Option } = Select;

const EditMissingDocument = ({ cancel, missingDocument, load, loadId }) => {
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.documents.isRecordAddLoading);
    const error = useSelector(state => state.documents.addRecordError);
    const userEntityType = useSelector(state => state.auth.entityType);

    //#endregion
    //#region useStates

    const [file, setFile] = useState(null);

    //#endregion
    //#region document methods

    const getReviewStatuses = (missingDocument) => {
        let children = [];
        if (isObjectNotEmpty(missingDocument) && isStringNotEmpty(missingDocument.documentType)) {
            if (missingDocument.entitySubType === 'PICK_UP') {
                // only show approved as an option for BOLs if they belong to a pick up stop
                switch (missingDocument.documentType) {
                    case 'BOL':
                        children.push(<Option value="APPROVED" key="APPROVED">Approved</Option>);
                        break;
                    default:
                        children.push(<Option value="APPROVED" key="APPROVED">Approved</Option>);
                }
            } else if (missingDocument.entitySubType === 'DROP_OFF') {
                switch (missingDocument.documentType) {
                    case 'BOL':
                        children.push(<Option value="CLEAN" key="CLEAN">Clean</Option>);
                        children.push(<Option value="OSDR" key="OSDR">Has OSDR</Option>);
                        break;
                    default:
                        children.push(<Option value="APPROVED" key="APPROVED">Approved</Option>);
                }
            } else {
                switch (missingDocument.documentType) {
                    case 'BOL':
                        children.push(<Option value="CLEAN" key="CLEAN">Clean</Option>);
                        children.push(<Option value="OSDR" key="OSDR">Has OSDR</Option>);
                        children.push(<Option value="APPROVED" key="APPROVED">Approved</Option>);
                        break;
                    default:
                        children.push(<Option value="APPROVED" key="APPROVED">Approved</Option>);
                }
            }
        } else {
            children.push(<Option value="APPROVED" key="APPROVED">APPROVED</Option>);
        }
        return children;
    };

    //#endregion
    //#region onSubmit and onCancel

    const onSubmit = (data) => {
        if (isObjectNotEmpty(missingDocument) && isStringNotEmpty(loadId)) {
            let payload = {
                isReviewed: false,
                visibleTo: missingDocument.visibleTo,
                displayName: missingDocument.documentType === "BOL" && missingDocument.entityType === "STOP" && isObjectNotEmpty(missingDocument.entity) ? `BOL #${missingDocument.entity.bolNumbers.join(', ')}` : missingDocument.documentTypeName,
                documentType: missingDocument.documentType,
                description: null,
                entityId: missingDocument.entityId,
                entityType: missingDocument.entityType
            };

            let links = [];
            if (missingDocument.entityType === 'STOP') {
                links.push({
                    entityId: missingDocument.entityId,
                    entityType: missingDocument.entityType
                });
                links.push({
                    entityId: loadId,
                    entityType: 'LOAD'
                });
            } else if (missingDocument.entityType === 'LOAD') {
                links.push({
                    entityId: missingDocument.entityId,
                    entityType: missingDocument.entityType
                });
            }
            payload.links = links;

            if (userEntityType === "STAFF" || (userEntityType === 'CARRIER' && load.createdByEntityType === 'CARRIER' && load.serviceType === "TMS") || (userEntityType === 'SHIPPER' && load.createdByEntityType === 'SHIPPER' && load.serviceType === "TMS")) {
                payload.reviewStatus = "APPROVED";
                payload.isReviewed = true;
            } else {
                payload.reviewStatus = "PENDING";
                payload.isReviewed = false;
            }

            if (isStringNotEmpty(data.reviewStatus)) {
                if (data.reviewStatus === 'PENDING') {
                    payload.isReviewed = false;
                } else {
                    payload.isReviewed = true;
                }
                payload.reviewStatus = data.reviewStatus;
            }

            if (isNotNullOrUndefined(file)) {
                payload.file = file;
                methods.clearErrors("file");
            } else {
                methods.setError("file", { type: "required", message: "Required" });
                return;
            }

            // console.log(file)
            // console.log(payload)
            if (isObjectNotEmpty(payload)) {
                dispatch(actionCreators.addDocument(payload));
            }
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.addDocumentCancel());
        cancel();
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.addDocumentErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    //#endregion

    if (isObjectNotEmpty(missingDocument)) {
        return (
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                        <Fieldset legend={`Upload ${missingDocument.documentDisplayName}`}>
                            {(isStringNotEmpty(missingDocument.documentType) && missingDocument.entitySubType === 'DROP_OFF' && missingDocument.documentType === 'BOL') && (userEntityType === "STAFF" || (userEntityType === 'CARRIER' && load.createdByEntityType === 'CARRIER' && load.serviceType === "TMS") || (userEntityType === 'SHIPPER' && load.createdByEntityType === 'SHIPPER' && load.serviceType === "TMS")) ? (
                                <FormItem {...formItemLayout} label="BOL Status" required
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Select
                                            placeholder="Please Select a BOL Status"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChange(selected); }}
                                            value={value}
                                            name={name}
                                            ref={ref}
                                        >
                                            {getReviewStatuses(missingDocument)}
                                        </Select>
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="reviewStatus"
                                />
                            ) : null}
                            <FormItemFile {...formItemLayout} label={missingDocument.documentDisplayName} name="file" required>
                                <UploadFile
                                    beforeUpload={(file) => {
                                        setFile(file);
                                        methods.clearErrors("file");
                                        return false;
                                    }}
                                    onRemove={(file) => {
                                        setFile(null);
                                    }}
                                    message={`Please upload ${missingDocument.documentDisplayName}.`}
                                />
                            </FormItemFile>
                        </Fieldset>
                        {error && <Alert message={`${error}`} type="error" />}
                    </Spin>
                    <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Upload" />
                </Form>
            </FormProvider>
        );
    } else {
        return null;
    }
};

export default EditMissingDocument;