import axios from 'axios';
import uuid from 'react-uuid';
import jwt from 'jwt-decode';
import moment from 'moment';
import store from '../store/store';
import * as actionCreators from '../store/actions/index';
import logger from '../shared/logger';
import LocalStorage from '../shared/localStorageUtils';
import { isObjectNotEmpty, isStringNotEmpty } from '../shared/objectUtils';
import * as Data from "./data/index";

const instance = axios.create({});

// Set base URL for API calls
instance.defaults.baseURL = process.env.REACT_APP_IRIS_API_ENDPOINT;

// Interceptor for Request
instance.interceptors.request.use(async request => {
    // console.log("HTTP Request:");
    // console.log(request);
    // Add headers and configurations to request here
    // Check for Token in Local Storage to add for Authorization
    let token = await LocalStorage.getItem('token');
    let refreshToken = await LocalStorage.getItem('refreshToken');
    if (isStringNotEmpty(token) && isStringNotEmpty(refreshToken)) {
        let tokenDecoded = jwt(token);
        // console.log(JSON.stringify(tokenDecoded));
        let endDate = moment.unix(tokenDecoded.exp);
        let refreshTokenDecoded = jwt(refreshToken);
        // console.log(JSON.stringify(refreshTokenDecoded));
        let refreshEndDate = moment.unix(refreshTokenDecoded.exp);

        // If Token is present, get the expiration date
        let expirationDate = endDate.toDate();
        // console.log(expirationDate);
        let refreshExpirationDate = refreshEndDate.toDate();
        // console.log(refreshExpirationDate);
        const now = Date.now();

        // If the expiration Date is less than current datetime and the refresh token is still valid, refresh the access token
        if (expirationDate < now && refreshExpirationDate > now) {
            const newToken = await refreshAccessToken(refreshToken);
            if (isStringNotEmpty(newToken)) {
                // console.log('new access token:');
                // console.log(newToken);
                token = newToken;
            }
        }
    } else {
        await store.dispatch(actionCreators.completeLogout());
        throw new axios.Cancel('Token is not available. Please login again.');
    }

    // console.log('axios token: ' + token);
    if (isStringNotEmpty(token)) {
        request.headers['Authorization'] = "Bearer " + token;
    }
    request.headers['X-Correlation-Id'] = uuid();
    request.headers['Accept'] = "application/json";
    request.headers['Content-Type'] = "application/json";

    return request;
}, error => {
    // console.log("HTTP Request Error:");
    // console.log(error);

    return Promise.reject(error);
});

// Interceptor for Response
instance.interceptors.response.use(response => {
    // console.log("HTTP Response:");
    // console.log(response);
    // Add headers and configurations to request here

    return response;
}, error => {
    // console.log("HTTP Response Error:");
    // console.log(error);

    return Promise.reject(error);
});

const refreshAccessToken = async (refreshToken) => {
    // console.log('refreshing access token');
    try {
        let newToken = null;

        const resp = await Data.getRefreshToken(refreshToken, false);
        if (isObjectNotEmpty(resp)) {
            newToken = resp.newToken;

            await LocalStorage.setItem('token', resp.newToken);
            await LocalStorage.setItem('refreshToken', resp.newRefreshToken);
            await LocalStorage.setItem('expirationDate', resp.newEndDate.toDate().toString());

            // check to see if the user isActive and if they aren't, don't let them login
            if (resp.isUserActive === false) {
                logger.logDebugEvent('refreshAccessToken', 'user isnt active', true);
                try {
                    await store.dispatch(actionCreators.completeLogout());
                } catch (error) {
                    logger.logErrorEvent('refreshAccessToken', error, error.message, true);
                }
            } else {
                try {
                    store.dispatch(actionCreators.authSuccess({ token: resp.newToken }));
                } catch (error) {
                    logger.logErrorEvent('refreshAccessToken', error, error.message, true);
                }
            }
        } else {
            logger.logDebugEvent('refreshAccessToken', 'new token is bad', true);
            try {
                await store.dispatch(actionCreators.completeLogout());
            } catch (error) {
                logger.logErrorEvent('refreshAccessToken', error, error.message, true);
            }
        }

        return newToken;
    } catch (error) {
        logger.logErrorEvent('refreshAccessToken', error, error.message, true);

        return null;
    }
};

export default instance