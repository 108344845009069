import Checks from '../entitlementUtils';

export const canCreateClaim = (auth, load) => {
    return Checks.allowStaff(auth);
};

export const canReadClaim = (auth, claim) => {
    return Checks.allowStaff(auth);
};

export const canReadClaimList = (auth, load) => {
    return Checks.allowStaff(auth);
};

export const canUpdateClaim = (auth, claim) => {
    return Checks.allowStaff(auth);
};

export const canDeleteClaim = (auth) => {
    return Checks.allowStaffAdmin(auth);
};