import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { Drawer, Dropdown, Button, Menu, message } from 'antd';
import NewUser from "../../components/NewUser/NewUser";
import EditUser from "../../components/EditUser/EditUser";
import classes from './Staff.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import DataTable from '../../components/DataTable/DataTable';
import StringFormatter from '../../shared/stringFormatter';
import { DownOutlined, MailOutlined } from '@ant-design/icons';
import { forgotPassword } from '../../api/data';
import { isStringNotEmpty } from '../../shared/objectUtils';
import logger from '../../shared/logger';
import { Can, CanDo } from '../../shared/entitlements/entitlements';
import { selectListIsLoading, selectListPagination, selectListRecords } from '../../store/utility';

const stringFormatter = new StringFormatter();

const Staff = (props) => {
    //#region constants

    const fullWidth = global.window.innerWidth;
    const singularEntityName = "Staff";
    const pluralEntityName = "Staff";

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const records = useSelector(state => selectListRecords(state.users.lists, 'STAFF'));
    const isLoading = useSelector(state => selectListIsLoading(state.users.lists, 'STAFF'));
    const pagination = useSelector(state => selectListPagination(state.users.lists, 'STAFF'));

    //#endregion
    //#region useStates

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showNewEntity, setShowNewEntity] = useState(false);

    //#endregion
    //#region toggles

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    //#endregion
    //#region user methods

    const resendWelcomeEmail = (record) => {
        const email = record.email;
        if (isStringNotEmpty(email)) {
            forgotPassword(email).then(res => {
                if (res === true) {
                    message.success('A welcome email has been sent to this user.');
                } else {
                    message.error('Failed to send the welcome email has been sent to this user.');
                }
            }).catch(err => {
                logger.logDebugEvent('Staff.js', err.message, true);
                message.error('Failed to send the welcome email has been sent to this user.');
            });
        }
    };

    //#endregion
    //#region goTos

    const goToUserDashboard = (id) => {
        props.history.push({ pathname: `/staff/${id}` }, {
            previousPageTitle: "Staff",
            previousPageLocation: props.location,
            previousBreadcrumbs: props.breadcrumbs,
        });
    };

    //#endregion
    //#region table methods

    const refreshTable = () => {
        let searchParams = {
            offset: 0,
            limit: 5,
            isStaff: 'True'
        };

        dispatch(actionCreators.fetchUserList('STAFF', searchParams));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchUserListSuccess('STAFF', { params: { pagination: pager } }));

        let searchParams = {
            offset: (pagination.current - 1) * pagination.pageSize,
            limit: pagination.pageSize,
            isStaff: 'True'
        };

        dispatch(actionCreators.fetchUserList('STAFF', searchParams));
    };

    //#endregion
    //#region table displays

    const menu = (record) => {
        return (
            <Menu>
                {CanDo({ entityAction: 'UPDATE', entityModel: 'USER', entityObject: record }) &&
                    <Menu.Item key="welcomeUser" onClick={(e) => { e.domEvent.stopPropagation(); resendWelcomeEmail(record); }}>
                        <MailOutlined style={{ marginRight: 8 }} />
                        <span>Re-Send Welcome Email</span>
                    </Menu.Item>
                }
                {CanDo({ entityAction: 'UPDATE', entityModel: 'USER', entityObject: record }) &&
                    <Menu.Item key="editUser" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); toggleEditEntity(); }}>
                        <FontAwesomeIcon className="anticon" icon={faUserAlt} style={{ marginRight: 8 }} />
                        <span>Edit User Profile</span>
                    </Menu.Item>
                }
                {CanDo({ entityAction: 'READ', entityModel: 'USER', entityObject: record }) &&
                    <Menu.Item key="goToDashboard" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); goToUserDashboard(record.id); }}>
                        <span>Go to User Dashboard</span>
                    </Menu.Item>
                }
            </Menu>
        );
    };

    const columns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            width: 50,
            render: (text, record) => {
                return (
                    <Dropdown overlay={menu(record)} trigger={['click']}>
                        <Button>Actions <DownOutlined /></Button>
                    </Dropdown>
                );
            },
            align: 'center',
        },
        {
            title: 'UserName',
            dataIndex: 'user',
            key: 'userName',
        },
        {
            title: 'Email Address',
            dataIndex: 'user',
            key: 'emailAddress',
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Date Joined',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, record) => { return stringFormatter.toFormattedString("MomentDate", text, null); },
        },
        {
            title: 'Is Active',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        },
        {
            title: 'Is Admin',
            dataIndex: 'isAdmin',
            key: 'isAdmin',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        },
        {
            title: 'Is Staff',
            dataIndex: 'isStaff',
            key: 'isStaff',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        },
        {
            title: 'Phone Number',
            dataIndex: 'phone',
            key: 'phone',
            render: (text, record) => { return stringFormatter.toFormattedString("PhoneNumber", text, null); },
        }
    ];

    //#endregion
    //#region displays

    const newEntityComponents = (
        <Drawer
            title={"New " + singularEntityName}
            onClose={toggleNewEntity}
            visible={showNewEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewUser
                cancel={toggleNewEntity}
            />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName + " Profile"}
            onClose={toggleEditEntity}
            visible={showEditEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditUser
                cancel={toggleEditEntity}
                user={selectedRecord}
            />
        </Drawer>
    );

    //#endregion
    //#region useEffects

    useEffect(() => {
        refreshTable();
    }, []);

    //#endregion

    return (
        <Can entityAction="READ_LIST" entityModel="USER">
            <DataTable
                dataSource={records}
                columns={columns}
                pagination={pagination}
                onChange={handleTableChange}
                loading={isLoading === true}
                rowClassName={classes.dataTableRow}
                singularEntityName={singularEntityName}
                pluralEntityName={pluralEntityName}
                newEntityAction={CanDo({ entityAction: 'CREATE', entityModel: 'USER' }) ? toggleNewEntity : null}
                rowKey={record => record.id}
                style={{ backgroundColor: '#ffffff' }}
            >
                {newEntityComponents}
                {editEntityComponents}
            </DataTable>
        </Can>
    );
};

export default withRouter(Staff);