import React, { useState } from 'react';
import { Collapse, Col, Row, Drawer, Empty } from 'antd';
import classes from './LoadAssets.module.scss';
import EditAsset from "../../components/EditAsset/EditAsset";
import AssignLoadAsset from "../../components/AssignLoadAsset/AssignLoadAsset";
import ChangeLoadAsset from "../../components/ChangeLoadAsset/ChangeLoadAsset";
import RemoveLoadAsset from "../../components/RemoveLoadAsset/RemoveLoadAsset";
import LoadAsset from '../../components/LoadAsset/LoadAsset';
import AddEditChangeRemoveButtons from '../../components/AddEditChangeRemoveButtons/AddEditChangeRemoveButtons';
import StringFormatter from '../../shared/stringFormatter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck } from '@fortawesome/free-solid-svg-icons';
import { isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { useSelector } from 'react-redux';
import { CanSee } from '../../shared/entitlements/entitlements';
import LoadUtils from '../../api/utils/loadUtils';

const { Panel } = Collapse;
const stringFormatter = new StringFormatter();

const LoadAssets = ({ load, currentStatus }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    const singularEntityName = "Asset";

    //#endregion
    //#region useSelectors

    const entityType = useSelector(state => state.auth.entityType);

    //#endregion
    //#region useStates

    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showChangeEntity, setShowChangeEntity] = useState(false);
    const [showRemoveEntity, setShowRemoveEntity] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({});

    //#endregion
    //#region toggles

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleChangeEntity = () => {
        setShowChangeEntity(!showChangeEntity);
    };

    const toggleRemoveEntity = () => {
        setShowRemoveEntity(!showRemoveEntity);
    };

    //#endregion
    //#region displays

    const newEntityComponents = (
        <Drawer
            title={"Assign " + singularEntityName + " to Load"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleNewEntity}
            visible={showNewEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <AssignLoadAsset
                cancel={toggleNewEntity}
                load={load}
                carrierId={isObjectNotEmpty(load) && isStringNotEmpty(load.assignedCarrierId) ? load.assignedCarrierId : null}
            />
        </Drawer>
    );

    const changeEntityComponents = (
        <Drawer
            title={"Change " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleChangeEntity}
            visible={showChangeEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <ChangeLoadAsset
                cancel={toggleChangeEntity}
                asset={selectedRecord}
                load={load}
                carrierId={isObjectNotEmpty(load) && isStringNotEmpty(load.assignedCarrierId) ? load.assignedCarrierId : null}
            />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName + " Details"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditEntity}
            visible={showEditEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditAsset
                cancel={toggleEditEntity}
                asset={selectedRecord}
            />
        </Drawer>
    );

    const removeEntityComponents = (
        <Drawer
            title={"Remove " + singularEntityName + " from Load"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleRemoveEntity}
            visible={showRemoveEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <RemoveLoadAsset
                cancel={toggleRemoveEntity}
                asset={selectedRecord}
                load={load}
            />
        </Drawer>
    );

    const actionButtons = (record) => {
        return (
            <CanSee entityAction="UPDATE" entityModel="LOAD_ASSET" entityObject={load} key={`buttons-${record.id}`}>
                <AddEditChangeRemoveButtons
                    showAdd={false} addText='' addAction={null}
                    showEdit={true} editText={"Edit " + singularEntityName + " Details"} editAction={(e) => { e.stopPropagation(); setSelectedRecord(record); toggleEditEntity(); }}
                    showChange={false} changeText={"Change " + singularEntityName} changeAction={(e) => { e.stopPropagation(); setSelectedRecord(record); toggleChangeEntity(); }}
                    showRemove={LoadUtils.loadStatusIsNot(currentStatus, ['COMPLETED', 'REOPENED', 'CANCELLED', 'CLOSED'])} removeText={"Remove " + singularEntityName + " from Load"} removeAction={(e) => { e.stopPropagation(); setSelectedRecord(record); toggleRemoveEntity(); }}
                />
            </CanSee>
        );
    };

    const assetHeader = (asset) => {
        return (
            <Row gutter={[4, 4]} style={{ display: 'flex', alignItems: 'center' }}>
                <Col xs={{ span: 12 }}>
                    <b>{stringFormatter.toFormattedString("AssetType", asset.assetType, null)}{asset.trailerType !== undefined && asset.trailerType !== null ? ' (' + asset.trailerType.description + ')' : ''} ({asset.licensePlateNum} {asset.licensePlateState})</b>
                </Col>
                <CanSee staffOnly={true}>
                    <Col xs={{ span: 12 }} style={{ textAlign: 'right' }}>
                        {actionButtons(asset)}
                    </Col>
                </CanSee>
            </Row>
        );
    };

    //#endregion

    return (
        <CanSee entityAction="READ_LIST" entityModel="LOAD_ASSET" entityObject={load}>
            {isObjectNotEmpty(load) && isListNotEmpty(load.assets) ? (
                <Collapse defaultActiveKey={[]}>
                    {load.assets.map((asset, index) => (
                        <Panel header={assetHeader(asset)} key={'asset-panel-' + asset.id}>
                            <LoadAsset asset={asset} showDocuments={true} loadId={load.id} key={"asset-" + asset.id} />
                        </Panel>
                    ))}
                </Collapse>
            ) : (
                <Empty style={{ backgroundColor: '#ffffff', margin: 0 }} image={<FontAwesomeIcon className="anticon" size="4x" icon={faTruck} />} description={'No Assets are Assigned to this Load'} />
            )}
            <CanSee entityAction="CREATE" entityModel="LOAD_ASSET" entityObject={load}>
                {(isObjectNotEmpty(load) && isStringNotEmpty(load.assignedCarrierId) && LoadUtils.loadStatusIsNot(currentStatus, ['COMPLETED', 'REOPENED', 'CANCELLED', 'CLOSED'])) ? (
                    <div className={classes.buttonContainer}>
                        <AddEditChangeRemoveButtons
                            showAdd={true} addText={"Assign " + singularEntityName + " to Load"} addAction={(e) => { e.stopPropagation(); toggleNewEntity(); }}
                            showEdit={false} editText='' editAction={null}
                            showChange={false} changeText='' changeAction={null}
                            showRemove={false} removeText='' removeAction={null}
                        />
                    </div>
                ) : null}
            </CanSee>
            {editEntityComponents}
            {newEntityComponents}
            {changeEntityComponents}
            {removeEntityComponents}
        </CanSee>
    );
};

export default LoadAssets;