import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { Spin, Tabs } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import Header from '../../components/Header/Header';
import { useLocation } from 'react-router';
import { isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import QueueCompletedLoads from '../../components/QueueCompletedLoads/QueueCompletedLoads';

const { TabPane } = Tabs;

const Invoicing = (props) => {
    //#region useLocation

    const location = useLocation();

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const entityType = useSelector(state => state.auth.entityType);
    const isLoadingMetrics = useSelector(state => state.metrics.metricsIsLoading);
    const allMetrics = useSelector(state => state.metrics.metrics);

    //#endregion
    //#region useStates

    const [tabKey, setTabKey] = useState('snapshot');
    const [metrics, setMetrics] = useState(null);

    //#endregion
    //#region tab methods

    const onTabChange = (key) => {
        props.history.replace({ ...props.location, hash: `#${key}` });
    };

    //#endregion
    //#region useMemos and useEffects

    useMemo(() => {
        // console.log('location changed')
        // console.log(location)
        if (isStringNotEmpty(location.hash)) {
            // console.log(location.hash.replace('#', ''))
            setTabKey(location.hash.replace('#', ''));
        } else {
            setTabKey('snapshot');
            props.history.replace({ ...location, hash: `#snapshot` });
        }
    }, [location]);

    useMemo(() => {
        if (isStringNotEmpty(entityType)) {
            if (entityType === 'STAFF') {
                dispatch(actionCreators.fetchMetrics('IRIS', null));
            }
        }
    }, [entityType]);

    useEffect(() => {
        if (isObjectNotEmpty(allMetrics)) {
            if (entityType === 'STAFF') {
                //console.log(allMetrics['IRIS']);
                setMetrics(allMetrics['IRIS']);
            }
        }
    }, [allMetrics, entityType]);

    //#endregion

    if (isObjectNotEmpty(metrics)) {
        return (
            <>
                <Header
                    title="Invoicing & Payments"
                    icon={<FontAwesomeIcon className="anticon" icon={faMoneyBill} />}
                    highlights={[
                        {
                            title: 'Accounts Receivable',
                            value: null
                        },
                        {
                            title: 'Accounts Payable',
                            value: null
                        },
                    ]}
                />
                <Tabs activeKey={tabKey} onChange={onTabChange} destroyInactiveTabPane={true} tabBarStyle={{ backgroundColor: '#ffffff', paddingRight: 24, paddingLeft: 24, margin: 0 }}>
                    <TabPane tab="Snapshot" key="snapshot">
                        <div style={{ padding: 24 }}>
                            <QueueCompletedLoads
                                showAccountsReceivable={true}
                                showAccountsPayable={true}
                                showProofOfDelivery={true}
                                showRequiredDocumentStatus={true}
                                showClaimsStatus={true}
                                title="Completed Loads"
                                filter="COMPLETED_ALL"
                                defaultLoadStatusFilter={['COMPLETED', 'REOPENED']}
                                style={{ marginBottom: 24 }}
                                showTable={true}
                            />
                            <QueueCompletedLoads
                                showAccountsReceivable={true}
                                showProofOfDelivery={true}
                                title="Accounts Receivable"
                                filter="COMPLETED_AR_ALL"
                                defaultLoadStatusFilter={['COMPLETED', 'REOPENED']}
                                filterSearchParams={{
                                    'completionCheckList.shipperInvoiceStatus:not': 'COMPLETED'
                                }}
                                style={{ marginBottom: 24 }}
                                showTable={true}
                            />
                            <QueueCompletedLoads
                                showAccountsPayable={true}
                                showProofOfDelivery={true}
                                title="Accounts Payable"
                                filter="COMPLETED_AP_ALL"
                                defaultLoadStatusFilter={['COMPLETED', 'REOPENED']}
                                filterSearchParams={{
                                    'completionCheckList.carrierInvoiceStatus:not': 'COMPLETED'
                                }}
                                showTable={true}
                            />
                        </div>
                    </TabPane>
                    <TabPane tab="Need POD" key="missingpod">
                        <div style={{ padding: 24 }}>
                            <QueueCompletedLoads
                                showAccountsReceivable={true}
                                showAccountsPayable={true}
                                showProofOfDelivery={true}
                                title="Completed Loads Missing Proof of Delivery"
                                filter="COMPLETED_MISSING_POD_ALL"
                                defaultLoadStatusFilter={['COMPLETED', 'REOPENED']}
                                filterSearchParams={{
                                    'completionCheckList.proofOfDeliveryStatus:not': 'UPLOADED|CLEAN|OSDR'
                                }}
                                showTable={true}
                            />
                        </div>
                    </TabPane>
                    <TabPane tab="POD Pending Review" key="pendingpod">
                        <div style={{ padding: 24 }}>
                            <QueueCompletedLoads
                                showAccountsReceivable={true}
                                showAccountsPayable={true}
                                showProofOfDelivery={true}
                                title="Completed Loads With Proof of Delivery Pending Review"
                                filter="COMPLETED_UPLOADED_POD_ALL"
                                defaultLoadStatusFilter={['COMPLETED', 'REOPENED']}
                                filterSearchParams={{
                                    'completionCheckList.proofOfDeliveryStatus': 'UPLOADED'
                                }}
                                showTable={true}
                            />
                        </div>
                    </TabPane>
                    <TabPane tab="Need to Send Customer Invoice" key="missingshipperinvoice">
                        <div style={{ padding: 24 }}>
                            <QueueCompletedLoads
                                showAccountsReceivable={true}
                                showProofOfDelivery={true}
                                title="Completed Loads Missing Customer Invoice"
                                filter="COMPLETED_MISSING_SHIPPER_INVOICE_ALL"
                                defaultLoadStatusFilter={['COMPLETED', 'REOPENED']}
                                filterSearchParams={{
                                    'completionCheckList.shipperInvoiceStatus:not': 'SENT|COMPLETED'
                                }}
                                showTable={true}
                            />
                        </div>
                    </TabPane>
                    <TabPane tab="Customer Invoice Sent" key="sentshipperinvoice">
                        <div style={{ padding: 24 }}>
                            <QueueCompletedLoads
                                showAccountsReceivable={true}
                                showProofOfDelivery={true}
                                title="Completed Loads With Customer Invoice Sent"
                                filter="COMPLETED_SENT_SHIPPER_INVOICE_ALL"
                                defaultLoadStatusFilter={['COMPLETED', 'REOPENED']}
                                filterSearchParams={{
                                    'completionCheckList.shipperInvoiceStatus': 'SENT'
                                }}
                                showTable={true}
                            />
                        </div>
                    </TabPane>
                    <TabPane tab="Customer Invoice Paid" key="paidshipperinvoice">
                        <div style={{ padding: 24 }}>
                            <QueueCompletedLoads
                                showAccountsReceivable={true}
                                showProofOfDelivery={true}
                                title="Completed Loads With Customer Invoice Paid"
                                filter="COMPLETED_PAID_SHIPPER_INVOICE_ALL"
                                defaultLoadStatusFilter={['COMPLETED', 'REOPENED']}
                                filterSearchParams={{
                                    'completionCheckList.shipperInvoiceStatus': 'COMPLETED'
                                }}
                                showTable={true}
                            />
                        </div>
                    </TabPane>
                    <TabPane tab="Need Carrier Invoice" key="missingcarrierinvoice">
                        <div style={{ padding: 24 }}>
                            <QueueCompletedLoads
                                showAccountsPayable={true}
                                showProofOfDelivery={true}
                                title="Completed Loads Missing Carrier Invoice"
                                filter="COMPLETED_MISSING_CARRIER_INVOICE_ALL"
                                defaultLoadStatusFilter={['COMPLETED', 'REOPENED']}
                                filterSearchParams={{
                                    'completionCheckList.carrierInvoiceStatus:not': 'SENT|COMPLETED'
                                }}
                                showTable={true}
                            />
                        </div>
                    </TabPane>
                    <TabPane tab="Carrier Invoice Received" key="receivedcarrierinvoice">
                        <div style={{ padding: 24 }}>
                            <QueueCompletedLoads
                                showAccountsPayable={true}
                                showProofOfDelivery={true}
                                title="Completed Loads With Carrier Invoice Received"
                                filter="COMPLETED_SENT_CARRIER_INVOICE_ALL"
                                defaultLoadStatusFilter={['COMPLETED', 'REOPENED']}
                                filterSearchParams={{
                                    'completionCheckList.carrierInvoiceStatus': 'SENT'
                                }}
                                showTable={true}
                            />
                        </div>
                    </TabPane>
                    <TabPane tab="Carrier Invoice Paid" key="paidcarrierinvoice">
                        <div style={{ padding: 24 }}>
                            <QueueCompletedLoads
                                showAccountsPayable={true}
                                showProofOfDelivery={true}
                                title="Completed Loads With Carrier Invoice Paid"
                                filter="COMPLETED_PAID_CARRIER_INVOICE_ALL"
                                defaultLoadStatusFilter={['COMPLETED', 'REOPENED']}
                                filterSearchParams={{
                                    'completionCheckList.carrierInvoiceStatus': 'COMPLETED'
                                }}
                                showTable={true}
                            />
                        </div>
                    </TabPane>
                    <TabPane tab="Closed Loads" key="closedLoads">
                        <div style={{ padding: 24 }}>
                            <QueueCompletedLoads
                                showAccountsReceivable={true}
                                showAccountsPayable={true}
                                showProofOfDelivery={true}
                                title="Closed Loads"
                                filter="CLOSED_ALL"
                                defaultLoadStatusFilter={['CLOSED']}
                                showTable={true}
                            />
                        </div>
                    </TabPane>
                </Tabs>
            </>
        );
    } else {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={true} />
        );
    }
};

export default withRouter(Invoicing);