import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { Button, Drawer, Dropdown, Menu } from 'antd';
import TrailerType from "../../components/TrailerType/TrailerType";
import EditTrailerType from "../../components/EditTrailerType/EditTrailerType";
import NewTrailerType from "../../components/NewTrailerType/NewTrailerType";
import classes from './TrailerTypes.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTools } from '@fortawesome/free-solid-svg-icons';
import DataTable from "../../components/DataTable/DataTable";
import { isStringNotEmpty } from '../../shared/objectUtils';
import { DownOutlined } from '@ant-design/icons';
import { Can, CanDo } from '../../shared/entitlements/entitlements';
import { compareByAlph } from '../../shared/tableUtils';

const TrailerTypes = (props) => {
    //#region constants

    const fullWidth = global.window.innerWidth;
    const singularEntityName = "Trailer Type";
    const pluralEntityName = "Trailer Types";

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.trailerTypes.isLoading);
    const records = useSelector(state => state.trailerTypes.records);
    const pagination = useSelector(state => state.trailerTypes.pagination);

    //#endregion
    //#region useStates

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showNewEntity, setShowNewEntity] = useState(false);

    //#endregion
    //#region toggles

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    //#endregion
    //#region table methods

    const refreshTable = () => {
        let searchParams = {
            page: 1,
            size: 5,
            isDeleted: false
        };

        dispatch(actionCreators.fetchTrailerTypes(searchParams));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchTrailerTypesSuccess({ pagination: pager }));

        let searchParams = {
            page: pagination.current,
            size: pagination.pageSize,
            isDeleted: false
        };

        if (isStringNotEmpty(sorter.order)) {
            const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
            searchParams.order = sortOrder;
        }
        if (isStringNotEmpty(sorter.field)) {
            searchParams.sort = sorter.field;
        }

        dispatch(actionCreators.fetchTrailerTypes(searchParams));
    };

    //#endregion
    //#region table displays

    const menu = (record) => {
        return (
            <Menu>
                {CanDo({ entityAction: 'UPDATE', entityModel: 'TRAILER_TYPE', entityObject: record }) &&
                    <Menu.Item key="editEntity" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); toggleEditEntity(); }}>
                        <FontAwesomeIcon className="anticon" icon={faEdit} style={{ marginRight: 8 }} />
                        <span>Edit {singularEntityName} Details</span>
                    </Menu.Item>
                }
            </Menu>
        );
    };

    const columns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            width: 50,
            render: (text, record) => {
                return (
                    <Dropdown overlay={menu(record)} trigger={['click']}>
                        <Button>Actions <DownOutlined /></Button>
                    </Dropdown>
                );
            },
            align: 'center',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => compareByAlph(a.name, b.name),
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
    ];

    //#endregion
    //#region displays

    const newEntityComponents = (
        <Drawer
            title={"New " + singularEntityName}
            onClose={toggleNewEntity}
            visible={showNewEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewTrailerType
                cancel={toggleNewEntity}
            />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName + " Details"}
            onClose={toggleEditEntity}
            visible={showEditEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditTrailerType
                cancel={toggleEditEntity}
                trailerType={selectedRecord}
            />
        </Drawer>
    );

    //#endregion
    //#region useEffects

    useEffect(() => {
        refreshTable();
    }, []);

    //#endregion

    return (
        <Can entityAction="READ_LIST" entityModel="TRAILER_TYPE">
            <DataTable
                dataSource={records}
                columns={columns}
                pagination={pagination}
                onChange={handleTableChange}
                loading={isLoading === true}
                expandedRowRender={record => (<TrailerType trailerType={record} />)}
                rowClassName={classes.dataTableRow}
                singularEntityName={singularEntityName}
                pluralEntityName={pluralEntityName}
                newEntityAction={CanDo({ entityAction: 'CREATE', entityModel: 'TRAILER_TYPE' }) ? toggleNewEntity : null}
                rowKey={record => record.id}
                style={{ backgroundColor: '#ffffff' }}
            >
                {newEntityComponents}
                {editEntityComponents}
            </DataTable>
        </Can>
    );
};

export default withRouter(TrailerTypes);