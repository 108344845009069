import React from "react";
import { isStringNotEmpty } from "../../shared/objectUtils";
import FormItem from "../FormItem/FormItem";
import MaskedInput from 'antd-mask-input';
import FormItemDouble from "../FormItemDouble/FormItemDouble";
import { Input } from "antd";

const FormItemPhoneNumber = ({ format = 'vertical', required = false, name, defaultValue, phoneFieldLabel = 'Phone #', phoneExtFieldLabel = '', phoneFieldName = 'phone', phoneExtFieldName = 'phoneExt', showExtension = true }) => {
    //#region styles

    const formItemLayout = {
        labelCol: { sm: 24, md: 24 },
        wrapperCol: { sm: 24, md: 24 },
    };

    const formItemLayoutHorizontal = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const formItemLayoutDouble = {
        labelCol1: { sm: 24, md: 24 },
        wrapperCol1: { sm: 16, md: 16 },
        labelCol2: { span: 0 },
        wrapperCol2: { sm: 8, md: 8 },
    };

    const formItemLayoutDoubleHorizontal = {
        labelCol1: { span: 8 },
        wrapperCol1: { span: 8 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 8 },
    };

    //#endregion

    if (format === 'vertical') {
        if (showExtension === true) {
            return (
                <FormItemDouble {...formItemLayoutDouble} label1={phoneFieldLabel} label2={phoneExtFieldLabel} format="vertical" required1={required} noColon2
                    render1={({ onChange, onBlur, value, name, ref }) => (
                        <MaskedInput
                            onBlur={onBlur}
                            onChange={e => {
                                let value = null;
                                if (isStringNotEmpty(e.target.value)) {
                                    value = e.target.value.replace(/\s/g, '').replace(' ', '').replace('_', '').replace('-', '').replace('(', '').replace(')', '');
                                }
                                onChange(value);
                            }}
                            value={value}
                            name={name}
                            placeholder={phoneFieldLabel}
                            mask="(111) 111-1111"
                            ref={ref}
                        />
                    )}
                    rules1={{
                        required: (required ? 'Required Field' : false),
                        pattern: {
                            value: /^\d{10}$/,
                            message: "Please enter a valid 10 digit phone number with no special characters"
                        }
                    }}
                    name1={`${isStringNotEmpty(name) ? name + '.' : ''}${phoneFieldName}`}
                    defaultValue1={defaultValue && defaultValue[phoneFieldName] ? defaultValue[phoneFieldName] : ""}
                    render2={({ onChange, onBlur, value, name, ref }) => (
                        <Input
                            onBlur={onBlur}
                            onChange={e => { onChange(e.target.value); }}
                            value={value}
                            name={name}
                            placeholder="Ext"
                            ref={ref}
                        />
                    )}
                    rules2={{ required: false }}
                    name2={`${isStringNotEmpty(name) ? name + '.' : ''}${phoneExtFieldName}`}
                    defaultValue2={defaultValue && defaultValue[phoneExtFieldName] ? defaultValue[phoneExtFieldName] : ""}
                />
            );
        } else {
            return (
                <FormItem {...formItemLayout} label={phoneFieldLabel} format="vertical" required={required}
                    render={({ onChange, onBlur, value, name, ref }) => (
                        <MaskedInput
                            onBlur={onBlur}
                            onChange={e => {
                                let value = null;
                                if (isStringNotEmpty(e.target.value)) {
                                    value = e.target.value.replace(/\s/g, '').replace(' ', '').replace('_', '').replace('-', '').replace('(', '').replace(')', '');
                                }
                                onChange(value);
                            }}
                            value={value}
                            name={name}
                            placeholder={phoneFieldLabel}
                            mask="(111) 111-1111"
                            ref={ref}
                        />
                    )}
                    rules={{
                        required: (required ? 'Required Field' : false),
                        pattern: {
                            value: /^\d{10}$/,
                            message: "Please enter a valid 10 digit phone number with no special characters"
                        }
                    }}
                    name={`${isStringNotEmpty(name) ? name + '.' : ''}${phoneFieldName}`}
                    defaultValue={defaultValue && defaultValue[phoneFieldName] ? defaultValue[phoneFieldName] : ""}
                />
            );
        }
    } else if (format === 'horizontal') {
        if (showExtension === true) {
            return (
                <FormItemDouble {...formItemLayoutDoubleHorizontal} label1={phoneFieldLabel} label2={phoneExtFieldLabel} format="horizontal" required1={required} noColon2
                    render1={({ onChange, onBlur, value, name, ref }) => (
                        <MaskedInput
                            onBlur={onBlur}
                            onChange={e => {
                                let value = null;
                                if (isStringNotEmpty(e.target.value)) {
                                    value = e.target.value.replace(/\s/g, '').replace(' ', '').replace('_', '').replace('-', '').replace('(', '').replace(')', '');
                                }
                                onChange(value);
                            }}
                            value={value}
                            name={name}
                            placeholder={phoneFieldLabel}
                            mask="(111) 111-1111"
                            ref={ref}
                        />
                    )}
                    rules1={{
                        required: (required ? 'Required Field' : false),
                        pattern: {
                            value: /^\d{10}$/,
                            message: "Please enter a valid 10 digit phone number with no special characters"
                        }
                    }}
                    name1={`${isStringNotEmpty(name) ? name + '.' : ''}${phoneFieldName}`}
                    defaultValue1={defaultValue && defaultValue[phoneFieldName] ? defaultValue[phoneFieldName] : ""}
                    render2={({ onChange, onBlur, value, name, ref }) => (
                        <Input
                            onBlur={onBlur}
                            onChange={e => { onChange(e.target.value); }}
                            value={value}
                            name={name}
                            placeholder="Ext"
                            ref={ref}
                        />
                    )}
                    rules2={{ required: false }}
                    name2={`${isStringNotEmpty(name) ? name + '.' : ''}${phoneExtFieldName}`}
                    defaultValue2={defaultValue && defaultValue[phoneExtFieldName] ? defaultValue[phoneExtFieldName] : ""}
                />
            );
        } else {
            return (
                <FormItem {...formItemLayoutHorizontal} label={phoneFieldLabel} format="horizontal" required={required}
                    render={({ onChange, onBlur, value, name, ref }) => (
                        <MaskedInput
                            onBlur={onBlur}
                            onChange={e => {
                                let value = null;
                                if (isStringNotEmpty(e.target.value)) {
                                    value = e.target.value.replace(/\s/g, '').replace(' ', '').replace('_', '').replace('-', '').replace('(', '').replace(')', '');
                                }
                                onChange(value);
                            }}
                            value={value}
                            name={name}
                            placeholder={phoneFieldLabel}
                            mask="(111) 111-1111"
                            ref={ref}
                        />
                    )}
                    rules={{
                        required: (required ? 'Required Field' : false),
                        pattern: {
                            value: /^\d{10}$/,
                            message: "Please enter a valid 10 digit phone number with no special characters"
                        }
                    }}
                    name={`${isStringNotEmpty(name) ? name + '.' : ''}${phoneFieldName}`}
                    defaultValue={defaultValue && defaultValue[phoneFieldName] ? defaultValue[phoneFieldName] : ""}
                />
            );
        }
    } else {
        return null;
    }
};

export default FormItemPhoneNumber;