import * as actionTypes from "../actions/actionTypes";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { isBooleanTrue, isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import ErrorUtils from "../../shared/errorUtils";
import * as actionCreators from "./index";

//#region Fetch Load Lane List Methods

export const fetchLoadLaneListStart = (listName) => {
    return {
        type: actionTypes.FETCH_LOAD_LANE_LIST_START,
        payload: { listName: listName }
    }
};

export const fetchLoadLaneListSuccess = (listName, payload) => {
    return {
        type: actionTypes.FETCH_LOAD_LANE_LIST_SUCCESS,
        payload: { listName: listName, ...payload }
    }
};

export const fetchLoadLaneListFail = (listName, payload) => {
    return {
        type: actionTypes.FETCH_LOAD_LANE_LIST_FAIL,
        payload: { listName: listName, ...payload }
    }
};

export const clearLoadLaneList = (listName) => {
    return {
        type: actionTypes.CLEAR_LOAD_LANE_LIST,
        payload: { listName: listName }
    }
};

export const clearLoadLaneLists = () => {
    return {
        type: actionTypes.CLEAR_LOAD_LANE_LISTS
    }
};

export const addLoadLaneToLoadLaneList = (listName, payload) => {
    return {
        type: actionTypes.ADD_TO_LOAD_LANE_LIST,
        payload: { listName: listName, newRecord: payload }
    }
};

export const updateLoadLaneInLoadLaneList = (listName, payload) => {
    return {
        type: actionTypes.UPDATE_IN_LOAD_LANE_LIST,
        payload: { listName: listName, updatedRecord: payload }
    }
};

export const removeLoadLaneFromLoadLaneList = (listName, payload) => {
    return {
        type: actionTypes.REMOVE_FROM_LOAD_LANE_LIST,
        payload: { listName: listName, recordToRemove: payload }
    }
};

//#endregion
//#region Fetch Load Lane Methods

export const fetchLoadLaneStart = () => {
    return {
        type: actionTypes.FETCH_LOAD_LANE_START
    }
};

export const fetchLoadLaneSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_LOAD_LANE_SUCCESS,
        payload: payload
    }
};

export const fetchLoadLaneFail = (payload) => {
    return {
        type: actionTypes.FETCH_LOAD_LANE_FAIL,
        payload: payload
    }
};

export const clearLoadLane = () => {
    return {
        type: actionTypes.CLEAR_LOAD_LANE
    }
};

//#endregion
//#region Add Load Lane Methods

export const addLoadLaneStart = () => {
    return {
        type: actionTypes.ADD_LOAD_LANE_START
    }
};

export const addLoadLaneSuccess = () => {
    return {
        type: actionTypes.ADD_LOAD_LANE_SUCCESS
    }
};

export const addLoadLaneFail = (payload) => {
    return {
        type: actionTypes.ADD_LOAD_LANE_FAIL,
        payload: payload
    }
};

export const addLoadLaneLoadingClear = () => {
    return {
        type: actionTypes.ADD_LOAD_LANE_LOADING_CLEAR
    }
};

export const addLoadLaneErrorClear = () => {
    return {
        type: actionTypes.ADD_LOAD_LANE_ERROR_CLEAR
    }
};

export const addLoadLaneCancel = () => {
    return {
        type: actionTypes.ADD_LOAD_LANE_CANCEL
    }
};

//#endregion
//#region Update Load Lane Methods

const changeSingleLoadLane = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_LOAD_LANE,
        payload: payload
    }
};

export const updateLoadLaneStart = () => {
    return {
        type: actionTypes.UPDATE_LOAD_LANE_START
    }
};

export const updateLoadLaneSuccess = () => {
    return {
        type: actionTypes.UPDATE_LOAD_LANE_SUCCESS
    }
};

export const updateLoadLaneFail = (payload) => {
    return {
        type: actionTypes.UPDATE_LOAD_LANE_FAIL,
        payload: payload
    }
};

export const updateLoadLaneLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_LOAD_LANE_LOADING_CLEAR
    }
};

export const updateLoadLaneErrorClear = () => {
    return {
        type: actionTypes.UPDATE_LOAD_LANE_ERROR_CLEAR
    }
};

export const updateLoadLaneCancel = () => {
    return {
        type: actionTypes.UPDATE_LOAD_LANE_CANCEL
    }
};

//#endregion
//#region Load Lanes Methods

export const fetchLoadLaneList = (listName, payload, entityId = null) => {
    return async (dispatch, getState) => {
        if (isStringNotEmpty(listName)) {
            try {
                dispatch(fetchLoadLaneListStart(listName));

                await dispatch(actionCreators.getAccounts());
                await dispatch(actionCreators.getTrailerTypes());
                const state = getState();
                const orchestratorState = { ...state.orchestrator };
                const accounts = [...orchestratorState.accounts];
                const trailerTypes = [...orchestratorState.trailerTypes];
                const loadLanesState = { ...state.loadLanes };
                const existingLists = { ...loadLanesState.lists };

                let pagination = {};
                let searchParams = {};
                if (isObjectNotEmpty(existingLists[listName])) {
                    pagination = { ...existingLists[listName].pagination };
                    searchParams = { ...existingLists[listName].searchParams };
                }

                if (isObjectNotEmpty(payload)) {
                    searchParams = { ...payload };
                }

                const res = await Data.getLoadLanes({ ...searchParams }, pagination, accounts, trailerTypes, entityId);
                dispatch(fetchLoadLaneListSuccess(listName, { records: res.data, params: { searchParams: res.searchParams, pagination: res.pagination } }));
            } catch (error) {
                logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
                dispatch(fetchLoadLaneListFail(listName, { error: ErrorUtils.getErrorMessage(error) }));
            }
        }
    }
};

export const fetchLoadLane = (id, entityId = null) => {
    return async (dispatch, getState) => {
        try {
            dispatch(fetchLoadLaneStart());

            await dispatch(actionCreators.getAccounts());
            await dispatch(actionCreators.getTrailerTypes());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const accounts = [...orchestratorState.accounts];
            const trailerTypes = [...orchestratorState.trailerTypes];

            const loadLane = await Data.getLoadLane(id, accounts, trailerTypes, entityId);
            dispatch(fetchLoadLaneSuccess({ record: loadLane }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchLoadLaneFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const addLoadLane = (payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(addLoadLaneStart());

            await dispatch(actionCreators.getAccounts());
            await dispatch(actionCreators.getTrailerTypes());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const accounts = [...orchestratorState.accounts];
            const trailerTypes = [...orchestratorState.trailerTypes];

            const newLoadLane = await Data.addLoadLane(payload, accounts, trailerTypes);
            if (isObjectNotEmpty(newLoadLane)) {
                dispatch(addLoadLaneToLoadLaneList(newLoadLane.accountId, newLoadLane));
            }

            dispatch(addLoadLaneSuccess());
            dispatch(addLoadLaneLoadingClear());
            dispatch(addLoadLaneErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addLoadLaneFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateLoadLane = (id, payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updateLoadLaneStart());

            if (isBooleanTrue(payload.isDeleted)) {
                const removedLoadLane = await Data.removeLoadLane(id);
                if (isObjectNotEmpty(removedLoadLane)) {
                    dispatch(removeLoadLaneFromLoadLaneList(removedLoadLane.accountId, removedLoadLane));
                }
            } else {
                await dispatch(actionCreators.getAccounts());
                await dispatch(actionCreators.getTrailerTypes());
                const state = getState();
                const orchestratorState = { ...state.orchestrator };
                const accounts = [...orchestratorState.accounts];
                const trailerTypes = [...orchestratorState.trailerTypes];

                const updatedLoadLane = await Data.updateLoadLane(id, payload, accounts, trailerTypes);
                if (isObjectNotEmpty(updatedLoadLane)) {
                    dispatch(updateLoadLaneInLoadLaneList(updatedLoadLane.accountId, updatedLoadLane));
                    dispatch(changeSingleLoadLane(updatedLoadLane));
                }
            }

            dispatch(updateLoadLaneSuccess());
            dispatch(updateLoadLaneLoadingClear());
            dispatch(updateLoadLaneErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateLoadLaneFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion