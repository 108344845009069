import { isBooleanTrue, isListNotEmpty, isNotNullOrUndefined, isNumberNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";

export const getInvoiceLineItemDTO = (invoiceLineItem) => {
    return invoiceLineItem;
};

export const getAddInvoiceLineItemRequestDTO = (payload) => {
    if (isNumberNotEmpty(payload.baseAmount)) {
        payload.baseAmount = Number(payload.baseAmount);
    }

    if (isNumberNotEmpty(payload.quantity)) {
        payload.quantity = Number(payload.quantity);
    }

    if (isNumberNotEmpty(payload.totalAmount)) {
        payload.totalAmount = Number(payload.totalAmount);
    }

    if (isStringNotEmpty(payload.status)) {
        if (payload.status === 'COMPLETED') {
            payload.approvalStatus = 'APPROVED';
        }
    }

    return payload;
};

export const getUpdateInvoiceLineItemRequestDTO = (payload) => {
    if (isNumberNotEmpty(payload.baseAmount)) {
        payload.baseAmount = Number(payload.baseAmount);
    }

    if (isNumberNotEmpty(payload.quantity)) {
        payload.quantity = Number(payload.quantity);
    }

    if (isNumberNotEmpty(payload.totalAmount)) {
        payload.totalAmount = Number(payload.totalAmount);
    }

    if (isStringNotEmpty(payload.status)) {
        if (payload.status === 'COMPLETED') {
            payload.approvalStatus = 'APPROVED';
        }
    }

    return payload;
};

export const getAddInvoiceLineItemsRequestDTO = (invoiceLineItemsData) => {
    let invoiceLineItems = [];

    if (isListNotEmpty(invoiceLineItemsData)) {
        invoiceLineItemsData.forEach((item) => {
            let invoiceLineItem = {};

            if (isStringNotEmpty(item.description)) {
                invoiceLineItem.description = item.description;
            }

            if (isStringNotEmpty(item.loadId)) {
                invoiceLineItem.loadId = item.loadId;
            }

            if (isStringNotEmpty(item.stopId)) {
                invoiceLineItem.stopId = item.stopId;
            }

            if (isStringNotEmpty(item.claimId)) {
                invoiceLineItem.claimId = item.claimId;
            }

            if (isStringNotEmpty(item.itemType)) {
                invoiceLineItem.itemType = item.itemType;
            }

            if (isBooleanTrue(item.isIrisFee)) {
                invoiceLineItem.isIrisFee = true;
            } else {
                invoiceLineItem.isIrisFee = false;
            }

            if (isBooleanTrue(item.isOpen)) {
                invoiceLineItem.isOpen = true;
            } else {
                invoiceLineItem.isOpen = false;
            }

            if (isStringNotEmpty(item.fromEntityType)) {
                invoiceLineItem.fromEntityType = item.fromEntityType;
            }

            if (isStringNotEmpty(item.fromEntityId)) {
                invoiceLineItem.fromEntityId = item.fromEntityId;
            }

            if (isStringNotEmpty(item.toEntityType)) {
                invoiceLineItem.toEntityType = item.toEntityType;
            }

            if (isStringNotEmpty(item.toEntityId)) {
                invoiceLineItem.toEntityId = item.toEntityId;
            }

            if (isNumberNotEmpty(item.baseAmount)) {
                invoiceLineItem.baseAmount = Number(item.baseAmount);
                if (isStringNotEmpty(item.baseAmountUnit)) {
                    invoiceLineItem.baseAmountUnit = item.baseAmountUnit;
                }
            }

            if (isNumberNotEmpty(item.quantity)) {
                invoiceLineItem.quantity = Number(item.quantity);
                if (isStringNotEmpty(item.quantityUnit)) {
                    invoiceLineItem.quantityUnit = item.quantityUnit;
                }
            }

            if (isNumberNotEmpty(item.totalAmount)) {
                invoiceLineItem.totalAmount = Number(item.totalAmount);
                if (isStringNotEmpty(item.totalAmountUnit)) {
                    invoiceLineItem.totalAmountUnit = item.totalAmountUnit;
                }
            }

            if (isStringNotEmpty(item.status)) {
                invoiceLineItem.status = item.status;
                if (item.status === 'COMPLETED') {
                    invoiceLineItem.approvalStatus = 'APPROVED';
                }
            }

            if (isStringNotEmpty(item.approvalStatus)) {
                invoiceLineItem.approvalStatus = item.approvalStatus;
            }

            invoiceLineItems.push(invoiceLineItem);
        });
    }

    return invoiceLineItems;
};

export const getUpdateInvoiceLineItemsRequestDTO = (invoiceLineItemsData) => {
    let invoiceLineItems = [];

    invoiceLineItemsData.forEach((item) => {
        let invoiceLineItem = {};

        if (isStringNotEmpty(item.description)) {
            invoiceLineItem.description = item.description;
        } else if (item.description === null) {
            invoiceLineItem.description = null;
        }

        if (isStringNotEmpty(item.loadId)) {
            invoiceLineItem.loadId = item.loadId;
        }

        if (isStringNotEmpty(item.stopId)) {
            invoiceLineItem.stopId = item.stopId;
        }

        if (isStringNotEmpty(item.claimId)) {
            invoiceLineItem.claimId = item.claimId;
        }

        if (isStringNotEmpty(item.itemType)) {
            invoiceLineItem.itemType = item.itemType;
        }

        if (isNotNullOrUndefined(item.isIrisFee)) {
            invoiceLineItem.isIrisFee = item.isIrisFee;
        }

        if (isNotNullOrUndefined(item.isOpen)) {
            invoiceLineItem.isOpen = item.isOpen;
        }

        if (isStringNotEmpty(item.fromEntityType)) {
            invoiceLineItem.fromEntityType = item.fromEntityType;
        }

        if (isStringNotEmpty(item.fromEntityId)) {
            invoiceLineItem.fromEntityId = item.fromEntityId;
        }

        if (isStringNotEmpty(item.toEntityType)) {
            invoiceLineItem.toEntityType = item.toEntityType;
        }

        if (isStringNotEmpty(item.toEntityId)) {
            invoiceLineItem.toEntityId = item.toEntityId;
        }

        if (isNumberNotEmpty(item.baseAmount)) {
            invoiceLineItem.baseAmount = Number(item.baseAmount);
        }

        if (isStringNotEmpty(item.baseAmountUnit)) {
            invoiceLineItem.baseAmountUnit = item.baseAmountUnit;
        }

        if (isNumberNotEmpty(item.quantity)) {
            invoiceLineItem.quantity = Number(item.quantity);
        }

        if (isStringNotEmpty(item.quantityUnit)) {
            invoiceLineItem.quantityUnit = item.quantityUnit;
        }

        if (isNumberNotEmpty(item.totalAmount)) {
            invoiceLineItem.totalAmount = Number(item.totalAmount);
        }

        if (isStringNotEmpty(item.totalAmountUnit)) {
            invoiceLineItem.totalAmountUnit = item.totalAmountUnit;
        }

        if (isStringNotEmpty(item.status)) {
            invoiceLineItem.status = item.status;
            if (item.status === 'COMPLETED') {
                invoiceLineItem.approvalStatus = 'APPROVED';
            }
        }

        if (isStringNotEmpty(item.approvalStatus)) {
            invoiceLineItem.approvalStatus = item.approvalStatus;
        }

        invoiceLineItems.push(invoiceLineItem);
    });

    return invoiceLineItems;
};