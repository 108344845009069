import React, { useState } from 'react';
import { Spin, Modal, Avatar, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import Img from 'react-image';
import classes from './MessageReceivedWithFile.module.scss';
import MomentDate from '../../shared/dateFormatter';

const MessageReceivedWithFile = ({ message, fileName, fileLocation }) => {
    const [showModal, setShowModal] = useState(false);

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    return (
        <>
            <div className={classes.incomingMessage} key={message.id}>
                <div className={classes.incomingMessageImage}>
                    <Tooltip title={`${message.senderFirstName} ${message.senderLastName}`}><Avatar style={{ backgroundColor: '#87d068' }}>{`${message.senderFirstName.charAt(0)}${message.senderLastName.charAt(0)}`}</Avatar></Tooltip>
                </div>
                <div className={classes.receivedMessage}>
                    <div className={classes.receivedMessageWithDate}>
                        <p><FontAwesomeIcon icon={faPaperclip} size="sm" /> <a type="link" onClick={toggleModal}>Attachment</a></p>
                        <span className={classes.timeDate}>{new MomentDate().fromUtcToDeviceTimeZoneForMessages(message.createdAt)}</span>
                    </div>
                </div>
            </div>
            <Modal
                title={fileName ? fileName : 'File'}
                visible={showModal}
                width="100%"
                style={{ top: 0 }}
                onCancel={toggleModal}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                maskClosable={false}
            >
                <Img style={{ width: '600px' }}
                    src={[fileLocation]}
                    loader={<Spin size="large" />}
                    alt={fileName ? fileName : 'File'} />
            </Modal>
        </>
    );
};

export default MessageReceivedWithFile;