import React from 'react';
import { Button } from "antd";

const ActionDoubleButton = ({ iconPosition = 'left', buttonGroupStyle = null, button1Style = null, button2Style = null, button1Icon1 = null, button1Icon2 = null, button2Icon1 = null, buttonText = null, button1Icon1Style = null, button1Icon2Style = null, button2Icon1Style = null, buttonTextStyle = null, buttonGroupClassName = null, buttonClassName = null, buttonIconClassName = null, buttonTextClassName = null, onClick1, onClick2, ...buttonProps }) => {
    return (
        <div className={buttonGroupClassName} style={buttonGroupStyle}>
            <Button className={buttonClassName} style={button1Style} onClick={onClick1} {...buttonProps}>
                {(iconPosition === 'left' || iconPosition !== 'right') ? (
                    <div className={buttonIconClassName} style={button1Icon1Style}>
                        {button1Icon1}
                    </div>
                ) : null}
                <div className={buttonTextClassName} style={buttonTextStyle}>
                    {buttonText}
                </div>
                {(iconPosition === 'right') ? (
                    <div className={buttonIconClassName} style={button1Icon1Style}>
                        {button1Icon1}
                    </div>
                ) : null}
                {button1Icon2 ? (
                    <div className={buttonIconClassName} style={button1Icon2Style}>
                        {button1Icon2}
                    </div>
                ) : null}
            </Button>
            <Button className={buttonClassName} style={button2Style} onClick={onClick2} {...buttonProps}>
                {button2Icon1 ? (
                    <div className={buttonIconClassName} style={button2Icon1Style}>
                        {button2Icon1}
                    </div>
                ) : null}
            </Button>
        </div>
    );
};

export default ActionDoubleButton;