import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { DeleteOutlined, DownOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Button, Drawer, Modal, Dropdown, Menu } from 'antd';
import EditDriver from "../../components/EditDriver/EditDriver";
import NewDriver from "../../components/NewDriver/NewDriver";
import classes from './Drivers.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools, faIdCard } from '@fortawesome/free-solid-svg-icons';
import DataTable from '../../components/DataTable/DataTable';
import DocumentList from "../../components/DocumentList/DocumentList";
import StringFormatter from '../../shared/stringFormatter';
import ColumnSearchFilter from '../../shared/tableColumnSearchFilter';
import { withRouter } from 'react-router-dom';
import { isBooleanFalse, isListNotEmpty, isNotNullOrUndefined, isObjectNotEmpty, isStringEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { CanDo, CanSee } from '../../shared/entitlements/entitlements';
import { selectListIsLoading, selectListPagination, selectListRecords } from '../../store/utility';
import RemoveEmployee from '../../components/RemoveEmployee/RemoveEmployee';

const stringFormatter = new StringFormatter();
const columnSearchFilter = new ColumnSearchFilter();

const Drivers = ({ accountId = null, ...props }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;
    const singularEntityName = "Driver";
    const pluralEntityName = "Drivers";

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const updateIsLoading = useSelector(state => state.accountUsers.isRecordUpdateLoading);
    const updateError = useSelector(state => state.accountUsers.updateRecordError);
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);
    const records = useSelector(state => selectListRecords(state.accountUsers.lists, `${isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')}_DRIVERS`));
    const isLoading = useSelector(state => selectListIsLoading(state.accountUsers.lists, `${isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')}_DRIVERS`));
    const pagination = useSelector(state => selectListPagination(state.accountUsers.lists, `${isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')}_DRIVERS`));

    //#endregion
    //#region useStates

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showRemoveEntity, setShowRemoveEntity] = useState(false);
    const [showDocumentsModal, setShowDocumentsModal] = useState(false);
    const [missingDocumentsCount, setMissingDocumentsCount] = useState(0);
    const [pendingApprovalCount, setPendingApprovalCount] = useState(0);
    const [expiringSoonDocumentsCount, setExpiringSoonDocumentsCount] = useState(0);
    const [expiredDocumentsCount, setExpiredDocumentsCount] = useState(0);
    const [searchText, setSearchText] = useState({ 'driver.irisId': '', firstName: '', lastName: '', email: '' });
    const [isApprovingDriver, setIsApprovingDriver] = useState(false);

    //#endregion
    //#region toggles

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleDocumentsModal = () => {
        setShowDocumentsModal(!showDocumentsModal);
    };

    const toggleRemoveEntity = () => {
        setShowRemoveEntity(!showRemoveEntity);
    };

    //#endregion
    //#region accountUser methods

    const approveDriver = (accountUserId) => {
        dispatch(actionCreators.approveDriver(accountUserId));
        setIsApprovingDriver(true);
    };

    const finishedApproveDriver = () => {
        dispatch(actionCreators.updateAccountUserCancel());
        toggleDocumentsModal();
        setIsApprovingDriver(false);
    };

    //#endregion
    //#region goTos

    const goToEmployeeDashboard = (id) => {
        props.history.push({ pathname: `/drivers/${id}` }, {
            previousPageTitle: "Drivers",
            previousPageLocation: props.location,
            previousBreadcrumbs: props.breadcrumbs,
        });
    };

    //#endregion
    //#region table methods

    const refreshTable = () => {
        let searchParams = {
            page: 1,
            size: 5,
            isDriver: true,
            isDeleted: false
        };

        if (isStringNotEmpty(accountId)) {
            searchParams.accountId = accountId;
        } else if (isStringNotEmpty(entityId) && entityType !== 'STAFF') {
            searchParams.accountId = entityId;
        }

        dispatch(actionCreators.fetchAccountUserList(`${isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')}_DRIVERS`, searchParams));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchAccountUserListSuccess(`${isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')}_DRIVERS`, { params: { pagination: pager } }));

        let searchParams = {
            page: pagination.current,
            size: pagination.pageSize,
            isDriver: true,
            isDeleted: false
        };

        if (isStringNotEmpty(sorter.order)) {
            const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
            searchParams.order = sortOrder;
        }
        if (isStringNotEmpty(sorter.field)) {
            searchParams.sort = sorter.field;
        }

        if (isStringNotEmpty(accountId)) {
            searchParams.accountId = accountId;
        } else if (isStringNotEmpty(entityId) && entityType !== 'STAFF') {
            searchParams.accountId = entityId;
        }

        if (isNotNullOrUndefined(filters)) {
            if (isListNotEmpty(filters['driver.irisId'])) {
                searchParams['driver.irisId:contains'] = filters['driver.irisId'][0];
            }
            if (isListNotEmpty(filters.email)) {
                searchParams['email:contains'] = filters.email[0];
            }
            if (isListNotEmpty(filters.firstName)) {
                searchParams['firstName:contains'] = filters.firstName[0];
            }
            if (isListNotEmpty(filters.lastName)) {
                searchParams['lastName:contains'] = filters.lastName[0];
            }
        }

        dispatch(actionCreators.fetchAccountUserList(`${isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')}_DRIVERS`, searchParams));
    };

    //#endregion
    //#region table displays

    const setRowClassName = (record) => {
        return classes.dataTableRow;
        // return (isObjectNotEmpty(record.driver) && ((record.driver.isApproved === false && record.driver.documentMissingCount < 2) || record.driver.documentPendingApprovalCount > 0 || record.driver.documentExpiredCount > 0)) ? classes.unverifiedRow : classes.dataTableRow;
    };

    const menu = (record) => {
        return (
            <Menu>
                <Menu.Item key="manageDocuments" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); toggleDocumentsModal(); }}>
                    <PaperClipOutlined style={{ marginRight: 8 }} />
                    <span>Manage Driver Documents</span>
                </Menu.Item>
                <Menu.Item key="editEntity" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); toggleEditEntity(); }}>
                    <FontAwesomeIcon className="anticon" icon={faIdCard} style={{ marginRight: 8 }} />
                    <span>Edit Driver Details</span>
                </Menu.Item>
                <Menu.Item key="goToDashboard" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); goToEmployeeDashboard(record.id); }}>
                    <span>Go to Driver Dashboard</span>
                </Menu.Item>
                {CanDo({entityAction: 'DELETE', entityModel: 'ACCOUNT_USER', entityObject: record, accountId: isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : null)}) ? (
                    <Menu.Item key="deleteEntity" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); toggleRemoveEntity(); }}>
                        <DeleteOutlined style={{ marginRight: 8 }} />
                        <span>Delete {singularEntityName}</span>
                    </Menu.Item>
                ) : null}
            </Menu>
        );
    };

    const columns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            className: classes.columnNoBreak,
            width: 50,
            render: (text, record) => {
                return (
                    <Dropdown overlay={menu(record)} trigger={['click']}>
                        <Button>Actions <DownOutlined /></Button>
                    </Dropdown>
                );
            },
            align: 'center',
        },
        {
            title: 'Driver Id',
            dataIndex: ['driver', 'irisId'],
            key: 'driver.irisId',
            ...columnSearchFilter.getColumnSearchProps('driver.irisId', 'Driver Id', searchText, setSearchText)
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
            ...columnSearchFilter.getColumnSearchProps('firstName', 'First Name', searchText, setSearchText)
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
            ...columnSearchFilter.getColumnSearchProps('lastName', 'Last Name', searchText, setSearchText)
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ...columnSearchFilter.getColumnSearchProps('email', 'Email', searchText, setSearchText)
        },
        {
            title: 'Phone',
            dataIndex: ['userProfile', 'phone'],
            key: 'userProfile.phone',
            render: (text, record) => { return stringFormatter.toFormattedString("PhoneNumber", text, record.userProfile.phoneExt); },
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Role(s)',
            dataIndex: 'accountRoles',
            key: 'accountRoles',
            render: (text, record) => { return isListNotEmpty(text) ? text.map((accountRole) => stringFormatter.toFormattedString("AccountRole", accountRole)).join(', ') : '' },
        },
        {
            title: 'Work Phone',
            dataIndex: 'workPhone',
            key: 'workPhone',
            render: (text, record) => { return stringFormatter.toFormattedString("PhoneNumber", text, record.workPhoneExt); },
        },
        {
            title: 'Is Verified',
            dataIndex: 'isVerified',
            key: 'isVerified',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        },
        {
            title: 'Available For Loads',
            dataIndex: ['driver', 'isAvailable'],
            key: 'driver.isAvailable',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        },
        {
            title: 'Total Load Completed',
            dataIndex: ['driver', 'totalLoads'],
            key: 'driver.totalLoads',
        },
        {
            title: 'Scheduled Loads',
            dataIndex: ['driver', 'scheduledLoads'],
            key: 'driver.scheduledLoads',
        },
    ];

    if (CanDo({ entityTypes: ['STAFF', 'CARRIER', 'BROKER'] })) {
        // columns.push({
        //     title: 'Is Approved',
        //     dataIndex: ['driver', 'isApproved'],
        //     key: 'driver.isApproved',
        //     render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        // });
        columns.push({
            title: 'Documents Pending Approval',
            dataIndex: ['driver', 'documentPendingApprovalCount'],
            key: 'driver.documentPendingApprovalCount',
        });
        // columns.push({
        //     title: 'Missing Documents',
        //     dataIndex: ['driver', 'documentMissingCount'],
        //     key: 'driver.documentMissingCount',
        // });
        columns.push({
            title: 'Documents Expiring Soon',
            dataIndex: ['driver', 'documentExpiringSoonCount'],
            key: 'driver.documentExpiringSoonCount',
        });
        columns.push({
            title: 'Expired Documents',
            dataIndex: ['driver', 'documentExpiredCount'],
            key: 'driver.documentExpiredCount',
        });

        if (isStringEmpty(accountId)) {
            columns.push({
                title: 'Account Name',
                dataIndex: ['account', 'name'],
                key: 'account.name',
            });
        }
    }

    //#endregion
    //#region displays

    const newEntityComponents = (
        <Drawer
            title={"New " + singularEntityName}
            onClose={toggleNewEntity}
            visible={showNewEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewDriver
                cancel={toggleNewEntity}
                accountId={isStringNotEmpty(accountId) ? accountId : (isStringNotEmpty(entityId) && entityType !== 'STAFF' ? entityId : null)}
            />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName + " Profile"}
            onClose={toggleEditEntity}
            visible={showEditEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditDriver
                cancel={toggleEditEntity}
                accountUser={selectedRecord}
            />
        </Drawer>
    );

    const removeEntityComponents = (
        <Drawer
            title={"Delete " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleRemoveEntity}
            visible={showRemoveEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <RemoveEmployee
                cancel={toggleRemoveEntity}
                accountUser={selectedRecord}
                singularEntityName={singularEntityName}
            />
        </Drawer>
    );

    const documentsComponent = (
        <Modal
            title="Driver Documents"
            visible={showDocumentsModal === true}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleDocumentsModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            {(isObjectNotEmpty(selectedRecord) && isObjectNotEmpty(selectedRecord.driver)) ? (
                <DocumentList
                    accountUser={selectedRecord}
                    documentEntityType="DRIVER"
                    documentEntityId={selectedRecord.id}
                    updatePendingApprovalCount={setPendingApprovalCount}
                    updateMissingDocumentsCount={setMissingDocumentsCount}
                    updateExpiringSoonDocumentsCount={setExpiringSoonDocumentsCount}
                    updateExpiredDocumentsCount={setExpiredDocumentsCount}
                />
            ) : null}
            {/* {isObjectNotEmpty(selectedRecord) && isObjectNotEmpty(selectedRecord.driver) && isBooleanFalse(selectedRecord.driver.isApproved) ? (
                <CanSee entityAction="VERIFY" entityModel="ACCOUNT_USER" entityObject={selectedRecord} accountId={selectedRecord.accountId}>
                    <Button style={{ marginTop: 20 }} type='primary' onClick={() => { approveDriver(selectedRecord.id); }} disabled={pendingApprovalCount > 0 || missingDocumentsCount > 0 || expiredDocumentsCount > 0}>Approve Driver</Button>
                </CanSee>
            ) : null} */}
        </Modal>
    );

    //#region useMemos and useEffects

    useEffect(() => {
        refreshTable();
    }, [accountId, entityId]);

    useMemo(() => {
        if (updateIsLoading !== null && updateIsLoading === false && updateError === null && isApprovingDriver === true) {
            finishedApproveDriver();
        }
    }, [updateIsLoading, updateError, isApprovingDriver]);

    //#endregion

    return (
        <DataTable
            dataSource={records}
            columns={columns}
            pagination={pagination}
            onChange={handleTableChange}
            loading={isLoading === true}
            rowClassName={setRowClassName}
            singularEntityName={singularEntityName}
            pluralEntityName={pluralEntityName}
            newEntityAction={toggleNewEntity}
            rowKey={record => record.id}
            style={{ backgroundColor: '#ffffff' }}
            scroll={{ x: '100%' }}
        >
            {newEntityComponents}
            {editEntityComponents}
            {removeEntityComponents}
            {documentsComponent}
        </DataTable>
    );
};

export default withRouter(Drivers);