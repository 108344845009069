import logger from '../shared/logger';
import { isBooleanTrue, isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../shared/objectUtils';
import StringFormatter from '../shared/stringFormatter';

const stringFormatter = new StringFormatter();

const getDriverInfo = (accountUserId, accountUser, driverDocuments) => {
    if (isObjectNotEmpty(accountUser) && isObjectNotEmpty(accountUser.driver) && isBooleanTrue(accountUser.isDriver)) {
        let fileMetaData = [];
        if (isListNotEmpty(driverDocuments)) {
            fileMetaData = driverDocuments.filter(document => document.entityId === accountUserId);
        }

        let {
            hazmatLicenseFrontFileId,
            hazmatLicenseBackFileId,
            driverLicenseFrontFileId,
            driverLicenseBackFileId,
            commercialLicenseFrontFileId,
            commercialLicenseBackFileId,
            pneumaticLicenseFrontFileId,
            pneumaticLicenseBackFileId,
            mcCertificateLicenseFrontFileId,
            mcCertificateLicenseBackFileId,
            explosiveCertifiedLicenseFrontFileId,
            explosiveCertifiedLicenseBackFileId,
            mshaLicenseFrontFileId,
            mshaLicenseBackFileId,
            hazmatLicenseExpiryDate,
            driverLicenseExpiryDate,
            commercialLicenseExpiryDate,
            pneumaticLicenseExpiryDate,
            mcCertificateLicenseExpiryDate,
            explosiveCertifiedLicenseExpiryDate,
            mshaLicenseExpiryDate,
            hazmatLicenseNumber,
            driverLicenseNumber,
            commercialLicenseNumber,
            pneumaticLicenseNumber,
            mcCertificateLicenseNumber,
            explosiveCertifiedLicenseNumber,
            mshaLicenseNumber,
            licenses
        } = getDriverFileIdsNumbersAndDates(fileMetaData);

        accountUser.driver.documents = fileMetaData;
        accountUser.driver.licenses = licenses;

        accountUser.driver.hazmatLicenseFrontFileId = hazmatLicenseFrontFileId ? hazmatLicenseFrontFileId : null;
        accountUser.driver.hazmatLicenseBackFileId = hazmatLicenseBackFileId ? hazmatLicenseBackFileId : null;
        accountUser.driver.driverLicenseFrontFileId = driverLicenseFrontFileId ? driverLicenseFrontFileId : null;
        accountUser.driver.driverLicenseBackFileId = driverLicenseBackFileId ? driverLicenseBackFileId : null;
        accountUser.driver.commercialLicenseFrontFileId = commercialLicenseFrontFileId ? commercialLicenseFrontFileId : null;
        accountUser.driver.commercialLicenseBackFileId = commercialLicenseBackFileId ? commercialLicenseBackFileId : null;
        accountUser.driver.pneumaticLicenseFrontFileId = pneumaticLicenseFrontFileId ? pneumaticLicenseFrontFileId : null;
        accountUser.driver.pneumaticLicenseBackFileId = pneumaticLicenseBackFileId ? pneumaticLicenseBackFileId : null;
        accountUser.driver.mcCertificateLicenseFrontFileId = mcCertificateLicenseFrontFileId ? mcCertificateLicenseFrontFileId : null;
        accountUser.driver.mcCertificateLicenseBackFileId = mcCertificateLicenseBackFileId ? mcCertificateLicenseBackFileId : null;
        accountUser.driver.explosiveCertifiedLicenseFrontFileId = explosiveCertifiedLicenseFrontFileId ? explosiveCertifiedLicenseFrontFileId : null;
        accountUser.driver.explosiveCertifiedLicenseBackFileId = explosiveCertifiedLicenseBackFileId ? explosiveCertifiedLicenseBackFileId : null;
        accountUser.driver.mshaLicenseFrontFileId = mshaLicenseFrontFileId ? mshaLicenseFrontFileId : null;
        accountUser.driver.mshaLicenseBackFileId = mshaLicenseBackFileId ? mshaLicenseBackFileId : null;

        accountUser.driver.hazmatLicenseExpiryDate = hazmatLicenseExpiryDate ? hazmatLicenseExpiryDate : null;
        accountUser.driver.driverLicenseExpiryDate = driverLicenseExpiryDate ? driverLicenseExpiryDate : null;
        accountUser.driver.commercialLicenseExpiryDate = commercialLicenseExpiryDate ? commercialLicenseExpiryDate : null;
        accountUser.driver.pneumaticLicenseExpiryDate = pneumaticLicenseExpiryDate ? pneumaticLicenseExpiryDate : null;
        accountUser.driver.mcCertificateLicenseExpiryDate = mcCertificateLicenseExpiryDate ? mcCertificateLicenseExpiryDate : null;
        accountUser.driver.explosiveCertifiedLicenseExpiryDate = explosiveCertifiedLicenseExpiryDate ? explosiveCertifiedLicenseExpiryDate : null;
        accountUser.driver.mshaLicenseExpiryDate = mshaLicenseExpiryDate ? mshaLicenseExpiryDate : null;

        accountUser.driver.hazmatLicenseNumber = hazmatLicenseNumber ? hazmatLicenseNumber : null;
        accountUser.driver.driverLicenseNumber = driverLicenseNumber ? driverLicenseNumber : null;
        accountUser.driver.commercialLicenseNumber = commercialLicenseNumber ? commercialLicenseNumber : null;
        accountUser.driver.pneumaticLicenseNumber = pneumaticLicenseNumber ? pneumaticLicenseNumber : null;
        accountUser.driver.mcCertificateLicenseNumber = mcCertificateLicenseNumber ? mcCertificateLicenseNumber : null;
        accountUser.driver.explosiveCertifiedLicenseNumber = explosiveCertifiedLicenseNumber ? explosiveCertifiedLicenseNumber : null;
        accountUser.driver.mshaLicenseNumber = mshaLicenseNumber ? mshaLicenseNumber : null;
    }

    return accountUser;
};

const getAccountInfo = (accountId, accounts) => {
    if (isStringNotEmpty(accountId) && isListNotEmpty(accounts)) {
        let account = accounts.find(a => a.id === accountId);
        if (isObjectNotEmpty(account)) {
            return { ...account };
        }
    }

    return null;
};

const getAccountName = (accountId, accounts) => {
    if (isStringNotEmpty(accountId) && isListNotEmpty(accounts)) {
        let account = accounts.find(account => account.id === accountId);
        if (isObjectNotEmpty(account) && isStringNotEmpty(account.name)) {
            return account.name;
        }
    }

    return null;
};

const getUserProfileInfo = (userId, users) => {
    if (isStringNotEmpty(userId) && isListNotEmpty(users)) {
        let user = users.find(user => user.user_id === userId);
        if (isObjectNotEmpty(user)) {
            return {
                ...user,
                user: user.isDummyEmail === true ? null : user.user
            };
        }
    }

    return null;
};

const getTrailerTypeInfo = (trailerTypeId, trailerTypes) => {
    if (isStringNotEmpty(trailerTypeId) && isListNotEmpty(trailerTypes)) {
        let trailerType = trailerTypes.find(t => t.id === trailerTypeId);
        if (isObjectNotEmpty(trailerType)) {
            return trailerType;
        }
    }

    return null;
};

const getTrailerTypeName = (trailerTypeId, trailerTypes) => {
    if (isStringNotEmpty(trailerTypeId) && isListNotEmpty(trailerTypes)) {
        let trailerType = trailerTypes.find(t => t.id === trailerTypeId);
        if (isObjectNotEmpty(trailerType) && isStringNotEmpty(trailerType.description)) {
            return trailerType.description;
        }
    }

    return null;
};

const getLocationInfo = (locationId, locations) => {
    if (isStringNotEmpty(locationId) && isListNotEmpty(locations)) {
        let location = locations.find(l => l.id === locationId);
        if (isObjectNotEmpty(location)) {
            return location;
        }
    }

    return null;
};

const getCommodityInfo = (commodityId, commodities) => {
    if (isStringNotEmpty(commodityId) && isListNotEmpty(commodities)) {
        let commodity = commodities.find(l => l.id === commodityId);
        if (isObjectNotEmpty(commodity)) {
            return commodity;
        }
    }
    
    return null;
};

const getFeatureInfo = (featureId, features) => {
    if (isStringNotEmpty(featureId) && isListNotEmpty(features)) {
        let feature = features.find(l => l.id === featureId);
        if (isObjectNotEmpty(feature)) {
            return feature;
        }
    }
    
    return null;
};

const getAccountUserName = (accountUserId, accountUsers) => {
    if (isStringNotEmpty(accountUserId) && isListNotEmpty(accountUsers)) {
        let accountUser = accountUsers.find(a => a.id === accountUserId);
        if (isObjectNotEmpty(accountUser)) {
            return `${accountUser.firstName} ${accountUser.lastName}`;
        }
    }
    
    return null;
};

const getAssetName = (assetId, assets) => {
    if (isStringNotEmpty(assetId) && isListNotEmpty(assets)) {
        let asset = assets.find(a => a.id === assetId);
        if (isObjectNotEmpty(asset)) {
            let assetType = stringFormatter.toFormattedString("AssetType", asset.assetType, null);
            let trailerType = (asset.assetType === 'TRAILER' || asset.assetType === 'COMBINED') ? (isObjectNotEmpty(asset.trailerType) ? asset.trailerType.description : '') : null;
            let licensePlate = `${asset.licensePlateNum} ${asset.licensePlateState}`;
            let title = isStringNotEmpty(asset.name) ? asset.name : licensePlate;

            return `${title}, Type: ${assetType}${isStringNotEmpty(trailerType) ? ' - ' + trailerType : ''}`;
        }
    }

    return null;
};

const getUserFileIds = (userProfileDocuments) => {
    let profileImageId = null;
    try {
        if (isListNotEmpty(userProfileDocuments)) {
            userProfileDocuments.forEach((userProfileDocument) => {
                if (userProfileDocument.documentType === "PROFILE_PHOTO") {
                    profileImageId = userProfileDocument.id;
                }
            });
        }
    } catch (ex) {
        logger.logErrorEvent('getUserFileIds', ex, ex.message, true);
    }

    return { profileImageId };
};

const getDriverFileIdsNumbersAndDates = (driverDocuments) => {
    let hazmatLicenseFrontFileId = null;
    let hazmatLicenseBackFileId = null;
    let hazmatLicenseExpiryDate = null;
    let hazmatLicenseNumber = null;

    let driverLicenseFrontFileId = null;
    let driverLicenseBackFileId = null;
    let driverLicenseExpiryDate = null;
    let driverLicenseNumber = null;

    let commercialLicenseFrontFileId = null;
    let commercialLicenseBackFileId = null;
    let commercialLicenseExpiryDate = null;
    let commercialLicenseNumber = null;

    let pneumaticLicenseFrontFileId = null;
    let pneumaticLicenseBackFileId = null;
    let pneumaticLicenseExpiryDate = null;
    let pneumaticLicenseNumber = null;

    let mcCertificateLicenseFrontFileId = null;
    let mcCertificateLicenseBackFileId = null;
    let mcCertificateLicenseExpiryDate = null;
    let mcCertificateLicenseNumber = null;

    let explosiveCertifiedLicenseFrontFileId = null;
    let explosiveCertifiedLicenseBackFileId = null;
    let explosiveCertifiedLicenseExpiryDate = null;
    let explosiveCertifiedLicenseNumber = null;

    let mshaLicenseFrontFileId = null;
    let mshaLicenseBackFileId = null;
    let mshaLicenseExpiryDate = null;
    let mshaLicenseNumber = null;

    let hazmatLicenseFront = null;
    let hazmatLicenseBack = null;
    let explosiveCertifiedLicenseFront = null;
    let explosiveCertifiedLicenseBack = null;
    let mcCertificateLicenseFront = null;
    let mcCertificateLicenseBack = null;
    let mshaLicenseFront = null;
    let mshaLicenseBack = null;
    let pneumaticLicenseFront = null;
    let pneumaticLicenseBack = null;
    let commercialLicenseFront = null;
    let commercialLicenseBack = null;
    let driverLicenseFront = null;
    let driverLicenseBack = null;

    let licenses = [];

    try {
        if (isListNotEmpty(driverDocuments)) {
            driverDocuments.forEach((driverDocument) => {
                switch (driverDocument.documentType) {
                    case "HAZMAT_LICENSE_FRONT":
                        hazmatLicenseFrontFileId = driverDocument.id;
                        hazmatLicenseExpiryDate = driverDocument.expiryDate;
                        hazmatLicenseNumber = driverDocument.licenseNumber;
                        hazmatLicenseFront = driverDocument;
                        break;
                    case "HAZMAT_LICENSE_BACK":
                        hazmatLicenseBackFileId = driverDocument.id;
                        hazmatLicenseBack = driverDocument;
                        break;
                    case "DRIVERS_LICENSE_FRONT":
                        driverLicenseFrontFileId = driverDocument.id;
                        driverLicenseExpiryDate = driverDocument.expiryDate;
                        driverLicenseNumber = driverDocument.licenseNumber;
                        driverLicenseFront = driverDocument;
                        break;
                    case "DRIVERS_LICENSE_BACK":
                        driverLicenseBackFileId = driverDocument.id;
                        driverLicenseBack = driverDocument;
                        break;
                    case "COMMERCIAL_DRIVERS_LICENSE_FRONT":
                        commercialLicenseFrontFileId = driverDocument.id;
                        commercialLicenseExpiryDate = driverDocument.expiryDate;
                        commercialLicenseNumber = driverDocument.licenseNumber;
                        commercialLicenseFront = driverDocument;
                        break;
                    case "COMMERCIAL_DRIVERS_LICENSE_BACK":
                        commercialLicenseBackFileId = driverDocument.id;
                        commercialLicenseBack = driverDocument;
                        break;
                    case "PNEUMATIC_LICENSE_FRONT":
                        pneumaticLicenseFrontFileId = driverDocument.id;
                        pneumaticLicenseExpiryDate = driverDocument.expiryDate;
                        pneumaticLicenseNumber = driverDocument.licenseNumber;
                        pneumaticLicenseFront = driverDocument;
                        break;
                    case "PNEUMATIC_LICENSE_BACK":
                        pneumaticLicenseBackFileId = driverDocument.id;
                        pneumaticLicenseBack = driverDocument;
                        break;
                    case "MC_CERTIFICATE_LICENSE_FRONT":
                        mcCertificateLicenseFrontFileId = driverDocument.id;
                        mcCertificateLicenseExpiryDate = driverDocument.expiryDate;
                        mcCertificateLicenseNumber = driverDocument.licenseNumber;
                        mcCertificateLicenseFront = driverDocument;
                        break;
                    case "MC_CERTIFICATE_LICENSE_BACK":
                        mcCertificateLicenseBackFileId = driverDocument.id;
                        mcCertificateLicenseBack = driverDocument;
                        break;
                    case "EXPLOSIVE_CERTIFIED_LICENSE_FRONT":
                        explosiveCertifiedLicenseFrontFileId = driverDocument.id;
                        explosiveCertifiedLicenseExpiryDate = driverDocument.expiryDate;
                        explosiveCertifiedLicenseNumber = driverDocument.licenseNumber;
                        explosiveCertifiedLicenseFront = driverDocument;
                        break;
                    case "EXPLOSIVE_CERTIFIED_LICENSE_BACK":
                        explosiveCertifiedLicenseBackFileId = driverDocument.id;
                        explosiveCertifiedLicenseBack = driverDocument;
                        break;
                    case "MSHA_LICENSE_FRONT":
                        mshaLicenseFrontFileId = driverDocument.id;
                        mshaLicenseExpiryDate = driverDocument.expiryDate;
                        mshaLicenseNumber = driverDocument.licenseNumber;
                        mshaLicenseFront = driverDocument;
                        break;
                    case "MSHA_LICENSE_BACK":
                        mshaLicenseBackFileId = driverDocument.id;
                        mshaLicenseBack = driverDocument;
                        break;
                    default:
                        break;
                }
            });
        }

        licenses.push({
            licenseType: 'COMMERCIAL_DRIVERS_LICENSE',
            licenseTypeDisplayName: 'Commercial Drivers License',
            frontImageId: commercialLicenseFront && commercialLicenseFront.id ? commercialLicenseFront.id : null,
            backImageId: commercialLicenseBack && commercialLicenseBack.id ? commercialLicenseBack.id : null,
            licenseNumber: commercialLicenseFront && commercialLicenseFront.licenseNumber ? commercialLicenseFront.licenseNumber : (commercialLicenseBack && commercialLicenseBack.licenseNumber ? commercialLicenseBack.licenseNumber : null),
            expirationDate: commercialLicenseFront && commercialLicenseFront.expiryDate ? commercialLicenseFront.expiryDate : (commercialLicenseBack && commercialLicenseBack.expiryDate ? commercialLicenseBack.expiryDate : null),
            isExpired: commercialLicenseFront && commercialLicenseFront.isExpired ? commercialLicenseFront.isExpired : (commercialLicenseBack && commercialLicenseBack.isExpired ? commercialLicenseBack.isExpired : false),
            isExpiringSoon: commercialLicenseFront && commercialLicenseFront.isExpiringSoon ? commercialLicenseFront.isExpiringSoon : (commercialLicenseBack && commercialLicenseBack.isExpiringSoon ? commercialLicenseBack.isExpiringSoon : false),
            frontImage: commercialLicenseFront,
            backImage: commercialLicenseBack,
            isRequired: false,
            frontDocumentType: 'COMMERCIAL_DRIVERS_LICENSE_FRONT',
            backDocumentType: 'COMMERCIAL_DRIVERS_LICENSE_BACK'
        });
        licenses.push({
            licenseType: 'DRIVERS_LICENSE',
            licenseTypeDisplayName: 'Personal Drivers License',
            frontImageId: driverLicenseFront && driverLicenseFront.id ? driverLicenseFront.id : null,
            backImageId: driverLicenseBack && driverLicenseBack.id ? driverLicenseBack.id : null,
            licenseNumber: driverLicenseFront && driverLicenseFront.licenseNumber ? driverLicenseFront.licenseNumber : (driverLicenseBack && driverLicenseBack.licenseNumber ? driverLicenseBack.licenseNumber : null),
            expirationDate: driverLicenseFront && driverLicenseFront.expiryDate ? driverLicenseFront.expiryDate : (driverLicenseBack && driverLicenseBack.expiryDate ? driverLicenseBack.expiryDate : null),
            isExpired: driverLicenseFront && driverLicenseFront.isExpired ? driverLicenseFront.isExpired : (driverLicenseBack && driverLicenseBack.isExpired ? driverLicenseBack.isExpired : false),
            isExpiringSoon: driverLicenseFront && driverLicenseFront.isExpiringSoon ? driverLicenseFront.isExpiringSoon : (driverLicenseBack && driverLicenseBack.isExpiringSoon ? driverLicenseBack.isExpiringSoon : false),
            frontImage: driverLicenseFront,
            backImage: driverLicenseBack,
            isRequired: false,
            frontDocumentType: 'DRIVERS_LICENSE_FRONT',
            backDocumentType: 'DRIVERS_LICENSE_BACK'
        });
        licenses.push({
            licenseType: 'HAZMAT_LICENSE',
            licenseTypeDisplayName: 'HazMat License',
            frontImageId: hazmatLicenseFront && hazmatLicenseFront.id ? hazmatLicenseFront.id : null,
            backImageId: hazmatLicenseBack && hazmatLicenseBack.id ? hazmatLicenseBack.id : null,
            licenseNumber: hazmatLicenseFront && hazmatLicenseFront.licenseNumber ? hazmatLicenseFront.licenseNumber : (hazmatLicenseBack && hazmatLicenseBack.licenseNumber ? hazmatLicenseBack.licenseNumber : null),
            expirationDate: hazmatLicenseFront && hazmatLicenseFront.expiryDate ? hazmatLicenseFront.expiryDate : (hazmatLicenseBack && hazmatLicenseBack.expiryDate ? hazmatLicenseBack.expiryDate : null),
            isExpired: hazmatLicenseFront && hazmatLicenseFront.isExpired ? hazmatLicenseFront.isExpired : (hazmatLicenseBack && hazmatLicenseBack.isExpired ? hazmatLicenseBack.isExpired : false),
            isExpiringSoon: hazmatLicenseFront && hazmatLicenseFront.isExpiringSoon ? hazmatLicenseFront.isExpiringSoon : (hazmatLicenseBack && hazmatLicenseBack.isExpiringSoon ? hazmatLicenseBack.isExpiringSoon : false),
            frontImage: hazmatLicenseFront,
            backImage: hazmatLicenseBack,
            isRequired: false,
            frontDocumentType: 'HAZMAT_LICENSE_FRONT',
            backDocumentType: 'HAZMAT_LICENSE_BACK'
        });
        licenses.push({
            licenseType: 'PNEUMATIC_LICENSE',
            licenseTypeDisplayName: 'Pneumatic License',
            frontImageId: pneumaticLicenseFront && pneumaticLicenseFront.id ? pneumaticLicenseFront.id : null,
            backImageId: pneumaticLicenseBack && pneumaticLicenseBack.id ? pneumaticLicenseBack.id : null,
            licenseNumber: pneumaticLicenseFront && pneumaticLicenseFront.licenseNumber ? pneumaticLicenseFront.licenseNumber : (pneumaticLicenseBack && pneumaticLicenseBack.licenseNumber ? pneumaticLicenseBack.licenseNumber : null),
            expirationDate: pneumaticLicenseFront && pneumaticLicenseFront.expiryDate ? pneumaticLicenseFront.expiryDate : (pneumaticLicenseBack && pneumaticLicenseBack.expiryDate ? pneumaticLicenseBack.expiryDate : null),
            isExpired: pneumaticLicenseFront && pneumaticLicenseFront.isExpired ? pneumaticLicenseFront.isExpired : (pneumaticLicenseBack && pneumaticLicenseBack.isExpired ? pneumaticLicenseBack.isExpired : false),
            isExpiringSoon: pneumaticLicenseFront && pneumaticLicenseFront.isExpiringSoon ? pneumaticLicenseFront.isExpiringSoon : (pneumaticLicenseBack && pneumaticLicenseBack.isExpiringSoon ? pneumaticLicenseBack.isExpiringSoon : false),
            frontImage: pneumaticLicenseFront,
            backImage: pneumaticLicenseBack,
            isRequired: false,
            frontDocumentType: 'PNEUMATIC_LICENSE_FRONT',
            backDocumentType: 'PNEUMATIC_LICENSE_BACK'
        });
        licenses.push({
            licenseType: 'MC_CERTIFICATE_LICENSE',
            licenseTypeDisplayName: 'MC Certificate License',
            frontImageId: mcCertificateLicenseFront && mcCertificateLicenseFront.id ? mcCertificateLicenseFront.id : null,
            backImageId: mcCertificateLicenseBack && mcCertificateLicenseBack.id ? mcCertificateLicenseBack.id : null,
            licenseNumber: mcCertificateLicenseFront && mcCertificateLicenseFront.licenseNumber ? mcCertificateLicenseFront.licenseNumber : (mcCertificateLicenseBack && mcCertificateLicenseBack.licenseNumber ? mcCertificateLicenseBack.licenseNumber : null),
            expirationDate: mcCertificateLicenseFront && mcCertificateLicenseFront.expiryDate ? mcCertificateLicenseFront.expiryDate : (mcCertificateLicenseBack && mcCertificateLicenseBack.expiryDate ? mcCertificateLicenseBack.expiryDate : null),
            isExpired: mcCertificateLicenseFront && mcCertificateLicenseFront.isExpired ? mcCertificateLicenseFront.isExpired : (mcCertificateLicenseBack && mcCertificateLicenseBack.isExpired ? mcCertificateLicenseBack.isExpired : false),
            isExpiringSoon: mcCertificateLicenseFront && mcCertificateLicenseFront.isExpiringSoon ? mcCertificateLicenseFront.isExpiringSoon : (mcCertificateLicenseBack && mcCertificateLicenseBack.isExpiringSoon ? mcCertificateLicenseBack.isExpiringSoon : false),
            frontImage: mcCertificateLicenseFront,
            backImage: mcCertificateLicenseBack,
            isRequired: false,
            frontDocumentType: 'MC_CERTIFICATE_LICENSE_FRONT',
            backDocumentType: 'MC_CERTIFICATE_LICENSE_BACK'
        });
        licenses.push({
            licenseType: 'EXPLOSIVE_CERTIFIED_LICENSE',
            licenseTypeDisplayName: 'Explosive Certified License',
            frontImageId: explosiveCertifiedLicenseFront && explosiveCertifiedLicenseFront.id ? explosiveCertifiedLicenseFront.id : null,
            backImageId: explosiveCertifiedLicenseBack && explosiveCertifiedLicenseBack.id ? explosiveCertifiedLicenseBack.id : null,
            licenseNumber: explosiveCertifiedLicenseFront && explosiveCertifiedLicenseFront.licenseNumber ? explosiveCertifiedLicenseFront.licenseNumber : (explosiveCertifiedLicenseBack && explosiveCertifiedLicenseBack.licenseNumber ? explosiveCertifiedLicenseBack.licenseNumber : null),
            expirationDate: explosiveCertifiedLicenseFront && explosiveCertifiedLicenseFront.expiryDate ? explosiveCertifiedLicenseFront.expiryDate : (explosiveCertifiedLicenseBack && explosiveCertifiedLicenseBack.expiryDate ? explosiveCertifiedLicenseBack.expiryDate : null),
            isExpired: explosiveCertifiedLicenseFront && explosiveCertifiedLicenseFront.isExpired ? explosiveCertifiedLicenseFront.isExpired : (explosiveCertifiedLicenseBack && explosiveCertifiedLicenseBack.isExpired ? explosiveCertifiedLicenseBack.isExpired : false),
            isExpiringSoon: explosiveCertifiedLicenseFront && explosiveCertifiedLicenseFront.isExpiringSoon ? explosiveCertifiedLicenseFront.isExpiringSoon : (explosiveCertifiedLicenseBack && explosiveCertifiedLicenseBack.isExpiringSoon ? explosiveCertifiedLicenseBack.isExpiringSoon : false),
            frontImage: explosiveCertifiedLicenseFront,
            backImage: explosiveCertifiedLicenseBack,
            isRequired: false,
            frontDocumentType: 'EXPLOSIVE_CERTIFIED_LICENSE_FRONT',
            backDocumentType: 'EXPLOSIVE_CERTIFIED_LICENSE_BACK'
        });
        licenses.push({
            licenseType: 'MSHA_LICENSE',
            licenseTypeDisplayName: 'MSHA License',
            frontImageId: mshaLicenseFront && mshaLicenseFront.id ? mshaLicenseFront.id : null,
            backImageId: mshaLicenseBack && mshaLicenseBack.id ? mshaLicenseBack.id : null,
            licenseNumber: mshaLicenseFront && mshaLicenseFront.licenseNumber ? mshaLicenseFront.licenseNumber : (mshaLicenseBack && mshaLicenseBack.licenseNumber ? mshaLicenseBack.licenseNumber : null),
            expirationDate: mshaLicenseFront && mshaLicenseFront.expiryDate ? mshaLicenseFront.expiryDate : (mshaLicenseBack && mshaLicenseBack.expiryDate ? mshaLicenseBack.expiryDate : null),
            isExpired: mshaLicenseFront && mshaLicenseFront.isExpired ? mshaLicenseFront.isExpired : (mshaLicenseBack && mshaLicenseBack.isExpired ? mshaLicenseBack.isExpired : false),
            isExpiringSoon: mshaLicenseFront && mshaLicenseFront.isExpiringSoon ? mshaLicenseFront.isExpiringSoon : (mshaLicenseBack && mshaLicenseBack.isExpiringSoon ? mshaLicenseBack.isExpiringSoon : false),
            frontImage: mshaLicenseFront,
            backImage: mshaLicenseBack,
            isRequired: false,
            frontDocumentType: 'MSHA_LICENSE_FRONT',
            backDocumentType: 'MSHA_LICENSE_BACK'
        });
    } catch (error) {
        logger.logErrorEvent('getDriverFileIdsEtc', error, error.message, true);
    }

    return {
        hazmatLicenseFrontFileId, hazmatLicenseBackFileId, driverLicenseFrontFileId, driverLicenseBackFileId,
        commercialLicenseFrontFileId, commercialLicenseBackFileId, pneumaticLicenseFrontFileId, pneumaticLicenseBackFileId,
        mcCertificateLicenseFrontFileId, mcCertificateLicenseBackFileId, explosiveCertifiedLicenseFrontFileId,
        explosiveCertifiedLicenseBackFileId, mshaLicenseFrontFileId, mshaLicenseBackFileId, hazmatLicenseExpiryDate,
        driverLicenseExpiryDate, commercialLicenseExpiryDate, pneumaticLicenseExpiryDate, mcCertificateLicenseExpiryDate,
        explosiveCertifiedLicenseExpiryDate, mshaLicenseExpiryDate, hazmatLicenseNumber, driverLicenseNumber,
        commercialLicenseNumber, pneumaticLicenseNumber, mcCertificateLicenseNumber, explosiveCertifiedLicenseNumber,
        mshaLicenseNumber, licenses
    };
};

const getAccountFileIdsAndDates = (accountDocuments) => {
    let logoFileId = null;
    let insuranceFileId = null;
    let insuranceExpiryDate = null;
    let w9ExpiryDate = null;
    let w9FileId = null;
    if (isListNotEmpty(accountDocuments)) {
        accountDocuments.forEach((accountDocument) => {
            switch (accountDocument.documentType) {
                case "ACCOUNT_LOGO":
                    logoFileId = accountDocument.id;
                    break;
                case "CARRIER_INSURANCE":
                    insuranceFileId = accountDocument.id;
                    insuranceExpiryDate = accountDocument.expiryDate;
                    break;
                case "CARRIER_W9":
                    w9FileId = accountDocument.id;
                    w9ExpiryDate = accountDocument.expiryDate;
                    break;
                default:
                    break;
            }
        });
    }

    return { logoFileId, insuranceFileId, w9FileId, insuranceExpiryDate, w9ExpiryDate };
};

const getAssetFileIds = (assetDocuments) => {
    let photoFileId = null;
    let licensePlateFileId = null;
    let insuranceFileId = null;
    let registrationFileId = null;
    if (isListNotEmpty(assetDocuments)) {
        assetDocuments.forEach((assetDocument) => {
            switch (assetDocument.documentType) {
                case "ASSET_PHOTO":
                    photoFileId = assetDocument.id;
                    break;
                case "ASSET_LICENSE_PLATE":
                    licensePlateFileId = assetDocument.id;
                    break;
                case "ASSET_INSURANCE":
                    insuranceFileId = assetDocument.id;
                    break;
                case "ASSET_REGISTRATION":
                    registrationFileId = assetDocument.id;
                    break;
                default:
                    break;
            }
        });
    }

    return { photoFileId, licensePlateFileId, insuranceFileId, registrationFileId };
};

export default {
    getDriverInfo,
    getAccountInfo,
    getUserProfileInfo,
    getUserFileIds,
    getDriverFileIdsNumbersAndDates,
    getAccountFileIdsAndDates,
    getAssetFileIds,
    getTrailerTypeInfo,
    getLocationInfo,
    getCommodityInfo,
    getFeatureInfo,
    getAccountName,
    getTrailerTypeName,
    getAccountUserName,
    getAssetName
};