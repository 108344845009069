import React, { useState } from 'react';
import { Card, Drawer } from "antd";
import classes from './LoadAssetsCard.module.scss';
import EditAsset from "../../components/EditAsset/EditAsset";
import AssignLoadAsset from "../../components/AssignLoadAsset/AssignLoadAsset";
import ChangeLoadAsset from "../../components/ChangeLoadAsset/ChangeLoadAsset";
import RemoveLoadAsset from "../../components/RemoveLoadAsset/RemoveLoadAsset";
import DataInfoActionRow from '../../components/DataInfoActionRow/DataInfoActionRow';
import AddEditChangeRemoveButtons from '../../components/AddEditChangeRemoveButtons/AddEditChangeRemoveButtons';
import StringFormatter from '../../shared/stringFormatter';
import { isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { CanSee } from '../../shared/entitlements/entitlements';
import LoadUtils from '../../api/utils/loadUtils';

const stringFormatter = new StringFormatter();

const missingEntityStyle = {
    backgroundColor: '#fff1f0',
    border: '1px solid #ffa39e',
};

const missingEntityHeadStyle = {
    borderBottom: '1px solid #ffa39e',
};

const LoadAssetsCard = ({ load, carrierId, assets, title, style = {}, headStyle = {} }) => {
    const fullWidth = global.window.innerWidth;
    const singularEntityName = "Asset";

    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showChangeEntity, setShowChangeEntity] = useState(false);
    const [showRemoveEntity, setShowRemoveEntity] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({});

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleChangeEntity = () => {
        setShowChangeEntity(!showChangeEntity);
    };

    const toggleRemoveEntity = () => {
        setShowRemoveEntity(!showRemoveEntity);
    };

    const newEntityComponents = (
        <Drawer
            title={"Assign " + singularEntityName + " to Load"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleNewEntity}
            visible={showNewEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <AssignLoadAsset
                cancel={toggleNewEntity}
                load={load}
                carrierId={carrierId}
            />
        </Drawer>
    );

    const changeEntityComponents = (
        <Drawer
            title={"Change " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleChangeEntity}
            visible={showChangeEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <ChangeLoadAsset
                cancel={toggleChangeEntity}
                asset={selectedRecord}
                load={load}
                carrierId={carrierId}
            />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName + " Details"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditEntity}
            visible={showEditEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditAsset
                cancel={toggleEditEntity}
                asset={selectedRecord}
            />
        </Drawer>
    );

    const removeEntityComponents = (
        <Drawer
            title={"Remove " + singularEntityName + " from Load"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleRemoveEntity}
            visible={showRemoveEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <RemoveLoadAsset
                cancel={toggleRemoveEntity}
                asset={selectedRecord}
                load={load}
            />
        </Drawer>
    );

    const extra = (isObjectNotEmpty(load) && isStringNotEmpty(carrierId)) ? (
        <CanSee entityAction="CREATE" entityModel="LOAD_ASSET" entityObject={load}>
            <AddEditChangeRemoveButtons
                showAdd={(LoadUtils.loadStatusIsNot(load.loadStatus, ['CREATED', 'PENDING', 'APPROVED', 'PENDING_RATE_CONFIRMATION', 'CLOSED', 'CANCELLED'])) ? true : false} addText={"Assign " + singularEntityName + " to Load"} addAction={(e) => { e.stopPropagation(); toggleNewEntity(); }}
                showEdit={false} editText='' editAction={null}
                showChange={false} changeText='' changeAction={null}
                showRemove={false} removeText='' removeAction={null}
            />
        </CanSee>
    ) : null;

    const actionButtons = (record) => {
        if (isObjectNotEmpty(load) && isStringNotEmpty(carrierId)) {
            return (
                <CanSee entityAction="UPDATE" entityModel="LOAD_ASSET" entityObject={load}>
                    <AddEditChangeRemoveButtons key={`buttons-${record.id}`}
                        showAdd={false} addText='' addAction={null}
                        showEdit={true} editText={"Edit " + singularEntityName + " Details"} editAction={(e) => { e.stopPropagation(); setSelectedRecord(record); toggleEditEntity(); }}
                        showChange={false} changeText={"Change " + singularEntityName} changeAction={(e) => { e.stopPropagation(); setSelectedRecord(record); toggleChangeEntity(); }}
                        showRemove={(LoadUtils.loadStatusIsNot(load.loadStatus, ['COMPLETED', 'CLOSED', 'REOPENED', 'CANCELLED'])) ? true : false} removeText={"Remove " + singularEntityName + " from Load"} removeAction={(e) => { e.stopPropagation(); setSelectedRecord(record); toggleRemoveEntity(); }}
                    />
                </CanSee>
            );
        } else {
            return null;
        }
    };

    const renderAssetDetails = () => {
        if (isListNotEmpty(assets)) {
            return (
                <>
                    {assets.map((asset, index) => (
                        <DataInfoActionRow key={`asset-row-${index}`} title={`Asset ${index + 1}`} value={`${isStringNotEmpty(asset.name) ? asset.name + ' ' : ''}${stringFormatter.toFormattedString("AssetType", asset.assetType, null)}${isObjectNotEmpty(asset.trailerType) && isStringNotEmpty(asset.trailerType.description) ? ' (' + asset.trailerType.description + ')' : ''} (${asset.licensePlateNum} ${asset.licensePlateState}), Currently Available: ${stringFormatter.toYesNo(asset.isAvailable)}`} dataType="String" actions={actionButtons(asset)} />
                    ))}
                </>
            );
        } else {
            return (
                <i>No Assets Assigned.</i>
            );
        }
    };

    if (isObjectNotEmpty(load) && (LoadUtils.loadStatusIs(load.loadStatus, ['BOOKED', 'SCHEDULED', 'IN_TRANSIT', 'AT_STOP', 'COMPLETED', 'CLOSED', 'REOPENED']))) {
        return (
            <CanSee entityAction="READ_LIST" entityModel="LOAD_ASSET" entityObject={load}>
                <Card title={title} bordered={true} className={classes.card} style={isListNotEmpty(assets) ? style : { ...style, ...missingEntityStyle }} headStyle={isListNotEmpty(assets) ? headStyle : { ...headStyle, ...missingEntityHeadStyle }} extra={extra}>
                    {renderAssetDetails()}
                </Card>
                {editEntityComponents}
                {newEntityComponents}
                {changeEntityComponents}
                {removeEntityComponents}
            </CanSee>
        );
    } else {
        return null;
    }
};

export default LoadAssetsCard;