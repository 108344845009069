import React, { useMemo } from 'react';
import { Alert, Spin, Input, InputNumber, Row, Col } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import { isNotNullOrUndefined, isNumberNotEmpty, isObjectNotEmpty } from '../../shared/objectUtils';
import LaneUtils from '../../api/utils/laneUtils';
import DatePickerAutoAccept from '../DatePickerAutoAccept/DatePickerAutoAccept';
import moment from 'moment';
import LoadMap from '../LoadMap/LoadMap';

const EditPreferredLane = ({ preferredLane, cancel }) => {
    //#region useForms

    const methods = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            customerLaneId: preferredLane.customerLaneId ? preferredLane.customerLaneId : '',
            rate: preferredLane.rate ? preferredLane.rate : null,
            rateStartDateTime: preferredLane.rateStartDateTime ? preferredLane.rateStartDateTime : null,
            rateEndDateTime: preferredLane.rateEndDateTime ? preferredLane.rateEndDateTime : null,
            capacityPerWeek: preferredLane.capacityPerWeek ? preferredLane.capacityPerWeek : null,
            maxLoadWeight: preferredLane.maxLoadWeight ? preferredLane.maxLoadWeight : null
        }
    });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.preferredLanes.isRecordUpdateLoading);
    const error = useSelector(state => state.preferredLanes.updateRecordError);

    //#endregion
    //#region submit and cancel

    const onSubmit = (data) => {
        let payload = {};
        let objectToUpdate = { ...preferredLane };
        for (let [key, value] of Object.entries(data)) {
            let oldValue = objectToUpdate[key] !== undefined && objectToUpdate[key] !== null ? objectToUpdate[key] : '';
            if (value !== oldValue) {
                //console.log(`${key}: new value - ${value}, old value - ${oldValue}`);
                payload[key] = value;
            }
        }

        if (isObjectNotEmpty(payload)) {
            if (isNotNullOrUndefined(payload.rate)) {
                payload.rateUnit = 'USD';
            }
            if (isNotNullOrUndefined(payload.maxLoadWeight)) {
                payload.maxLoadWeightUnit = 'LB';
            }
            dispatch(actionCreators.updatePreferredLane(preferredLane.id, payload));
        } else {
            onCancel();
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.updatePreferredLaneCancel());
        cancel();
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updatePreferredLaneErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    //#endregion
    //#region displays

    const getStopDetails = (stopType, city, state) => {
        const stop = {
            stopType: stopType
        };
        const stopIcon = LaneUtils.getLaneStopMarkerIcon(stop);

        return (
            <div>
                <Row style={{ marginTop: 36, marginBottom: 36 }}>
                    <Col span={24}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: 30, marginRight: 12 }}>
                                {isNotNullOrUndefined(stopIcon) ? (<img src={stopIcon} alt={LaneUtils.getLaneStopTypeDisplay(stop)} style={{ width: 30, height: 30 }} />) : null}
                            </div>
                            <div style={{ flex: 1 }}>
                                <span>{city}, {state}</span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    };

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const formItemLayoutDouble = {
        labelCol1: { span: 24 },
        wrapperCol1: { span: 12 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 12 },
    };

    //#endregion

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true && error === null)}>
                    <Fieldset legend="Origin and Destination">
                        <Row gutter={[24, 24]}>
                            <Col span={24}>
                                <LoadMap
                                    loadId={isObjectNotEmpty(preferredLane) ? preferredLane.id : null}
                                    stops={isObjectNotEmpty(preferredLane) && isObjectNotEmpty(preferredLane.lane) && isNumberNotEmpty(preferredLane.lane.originLongitude) && isNumberNotEmpty(preferredLane.lane.originLatitude) && isNumberNotEmpty(preferredLane.lane.destinationLongitude) && isNumberNotEmpty(preferredLane.lane.destinationLatitude) ? [
                                        {
                                            longitude: preferredLane.lane.originLongitude,
                                            latitude: preferredLane.lane.originLatitude,
                                            stopType: 'PICK_UP',
                                            id: 0,
                                            loadId: preferredLane.id
                                        },
                                        {
                                            longitude: preferredLane.lane.destinationLongitude,
                                            latitude: preferredLane.lane.destinationLatitude,
                                            stopType: 'DROP_OFF',
                                            id: 1,
                                            loadId: preferredLane.id
                                        }
                                    ] : []}
                                    containerName={isObjectNotEmpty(preferredLane) ? `map-${preferredLane.id}` : 'map'}
                                    height="300px"
                                />
                            </Col>
                            <Col span={12}>
                                {getStopDetails('PICK_UP', preferredLane.originCity, preferredLane.originState)}
                            </Col>
                            <Col span={12}>
                                {getStopDetails('DROP_OFF', preferredLane.destinationCity, preferredLane.destinationState)}
                            </Col>
                        </Row>
                    </Fieldset>
                    <Fieldset legend="Lane Details">
                        <FormItem {...formItemLayout} label="Lane Id"
                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Customer Lane Id" ref={ref} />}
                            rules={{ required: false }}
                            name="customerLaneId"
                            defaultValue={preferredLane.customerLaneId}
                        />
                        <FormItem {...formItemLayout} label="Rate"
                            render1={({ onChange, onBlur, value, name, ref }) => (
                                <InputNumber
                                    min={0}
                                    precision={2}
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    placeholder="Rate"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            rules={{ required: false }}
                            name="rate"
                            defaultValue={preferredLane.rate}
                        />
                        <FormItemDouble {...formItemLayoutDouble} label1="Date Range that Rate is Valid For"
                            render1={({ onChange, onBlur, value, name, ref }) => (
                                <DatePickerAutoAccept
                                    allowClear={true}
                                    placeholder="Start Date/Time"
                                    showTime={{ format: 'HH:mm', minuteStep: 15 }}
                                    format="YYYY-MM-DD HH:mm"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={(date, dateString) => { onChange(date); }}
                                    value={value}
                                    name={name}
                                    onSelect={(date) => { onChange(date); }}
                                    ref={ref}
                                />
                            )}
                            rules1={{ required: false }}
                            name1="rateStartDateTime"
                            defaultValue1={preferredLane.rateStartDateTime ? moment(preferredLane.rateStartDateTime) : null}
                            render2={({ onChange, onBlur, value, name, ref }) => (
                                <DatePickerAutoAccept
                                    allowClear={true}
                                    placeholder="End Date/Time"
                                    showTime={{ format: 'HH:mm', minuteStep: 15 }}
                                    format="YYYY-MM-DD HH:mm"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={(date, dateString) => { onChange(date); }}
                                    value={value}
                                    name={name}
                                    onSelect={(date) => { onChange(date); }}
                                    ref={ref}
                                />
                            )}
                            rules2={{ required: false }}
                            name2="rateEndDateTime"
                            defaultValue2={preferredLane.rateEndDateTime ? moment(preferredLane.rateEndDateTime) : null}
                        />
                        <FormItem {...formItemLayout} label="Capacity Per Week"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <InputNumber
                                    min={0}
                                    precision={0}
                                    placeholder="Capacity Per Week"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            rules={{ required: false }}
                            name="capacityPerWeek"
                            defaultValue={preferredLane.capacityPerWeek}
                        />
                        <FormItem {...formItemLayout} label="Max Load Weight (in lbs)"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <InputNumber
                                    min={0}
                                    precision={0}
                                    step={1000}
                                    placeholder="Max Load Weight (in lbs)"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            rules={{ required: false }}
                            name="maxLoadWeight"
                            defaultValue={preferredLane.maxLoadWeight}
                        />
                    </Fieldset>
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Update Preferred Lane" />
            </Form>
        </FormProvider>
    );
};

export default EditPreferredLane;