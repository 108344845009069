import React, { useState, useMemo, useRef } from 'react';
import { Button, Input, Modal, Col, Row } from "antd";
import classes from "./Invoice.module.scss";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import moment from 'moment';
import { FormProvider, useForm } from 'react-hook-form';
import Form from '../../components/Form/Form';
import FormItem from '../../components/FormItem/FormItem';
import Fieldset from '../../components/FormFieldset/FormFieldset';
import { isListNotEmpty, isNotNullOrUndefined, isObjectNotEmpty, isStringEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import SignatureCanvas from "react-signature-canvas";
import { selectListIsLoading } from '../../store/utility';
import InvoiceUtils from '../../api/utils/invoiceUtils';
import DataLinkRow from '../../components/DataLinkRow/DataLinkRow';
import DocumentUtils from '../../api/utils/documentUtils';

const Invoice = ({ invoiceId, invoice, load, loadId, stops, displayManualInstructions = false }) => {
    //#region useForms

    const methods = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            signedByName: '',
            signedByTitle: ''
        }
    });

    //#endregion
    //#region useRefs

    const signatureArea = useRef();

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isAdmin = useSelector(state => state.auth.isAdmin);
    const entityType = useSelector(state => state.auth.entityType);
    const userId = useSelector(state => state.auth.userId);
    const documents = useSelector(state => DocumentUtils.selectListVisibleDocumentsByLoadIdAndStops(state.documents.lists, loadId, load, stops, entityType, isAdmin));
    const isLoadingDocuments = useSelector(state => selectListIsLoading(state.documents.lists, loadId));

    //#endregion
    //#region useStates

    const [showSignatureModal, setShowSignatureModal] = useState(false);

    //#endregion
    //#region useMemos

    useMemo(() => {
        if (isStringNotEmpty(loadId) && isListNotEmpty(stops)) {
            let ids = [];
            ids.push(loadId);

            stops.forEach((stop) => {
                ids.push(stop.id);
            });

            dispatch(actionCreators.fetchBulkDocumentLists(loadId, ids));
        }
    }, [loadId, stops]);

    //#endregion
    //#region toggles

    const toggleSignatureModal = () => {
        setShowSignatureModal(!showSignatureModal);
    };

    //#endregion
    //#region onSubmit

    const onSubmit = (data) => {
        const url = signatureArea.current.getTrimmedCanvas().toDataURL("image/png");
        // console.log(url);
        if (isNotNullOrUndefined(url) && isStringNotEmpty(userId) && isObjectNotEmpty(data)) {
            let updatedInvoicePayload = {
                approvedAt: moment(),
                approvedBy: userId,
                signature: url,
                signedAt: moment(),
                signedBy: userId,
                signedByName: data.signedByName,
                signedByTitle: data.signedByTitle,
                status: 'COMPLETED'
            };

            dispatch(actionCreators.updateInvoice(invoiceId, updatedInvoicePayload, invoice));

            if (invoice.invoiceType === 'PRICE_CONFIRMATION') {
                dispatch(actionCreators.updatePriceConfirmation({ approvedPriceConfirmation: true }, loadId, entityType === 'SHIPPER'));
            } else if (invoice.invoiceType === 'RATE_CONFIRMATION') {
                dispatch(actionCreators.updateRateConfirmation({ approvedRateConfirmation: true }, loadId, entityType === 'CARRIER'));
            }

            toggleSignatureModal();
        }
    };

    //#endregion
    //#region signature methods

    const resetSignaturePad = (e) => {
        e.preventDefault();
        signatureArea.current.clear();
    };

    const cancelSignaturePad = (e) => {
        e.preventDefault();
        methods.reset();
        signatureArea.current.clear();
        toggleSignatureModal();
    };

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    //#endregion
    //#region signature displays

    const signaturePadComponents = (
        <Modal
            title={isObjectNotEmpty(invoice) && invoice.invoiceType === "RATE_CONFIRMATION" ? "Sign and Accept Load" : "Sign and Approve Load"}
            visible={showSignatureModal === true}
            width="550px"
            style={{ top: 0 }}
            onCancel={(e) => { cancelSignaturePad(); }}
            footer={null}
            zIndex={1000}
            closable={false}
            destroyOnClose={true}
            maskClosable={false}
        >
            <SignatureCanvas ref={(ref) => { signatureArea.current = ref; }} penColor="black" canvasProps={{ width: 500, height: 200, className: "sigCanvas", style: { border: '1px solid black' } }} />
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Fieldset legend="Your Full Legal Name and Title">
                        <FormItem {...formItemLayout} label="Your Full Legal Name" required format="vertical"
                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Your Full Legal Name" ref={ref} />}
                            rules={{ required: 'Required Field' }}
                            name="signedByName"
                            defaultValue={''}
                        />
                        <FormItem {...formItemLayout} label="Title" required format="vertical"
                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Title" ref={ref} />}
                            rules={{ required: 'Required Field' }}
                            name="signedByTitle"
                            defaultValue={''}
                        />
                    </Fieldset>
                    <div className={classes.signatureButtonContainer}>
                        <Button key="cancel" type="default" onClick={(e) => { cancelSignaturePad(e); }} style={{ marginRight: 8 }}>
                            Cancel
                        </Button>
                        <Button key="reset" type="default" onClick={(e) => { resetSignaturePad(e); }} style={{ marginRight: 8 }}>
                            Reset
                        </Button>
                        <Button key="sign" type="primary" htmlType="submit">
                            {isObjectNotEmpty(invoice) && invoice.invoiceType === "RATE_CONFIRMATION" ? "Sign and Accept Load" : "Sign and Approve Load"}
                        </Button>
                    </div>
                </Form>
            </FormProvider>
        </Modal>
    );

    //#endregion
    //#region displays

    const getCarrierInvoiceDocuments = () => {
        if (isListNotEmpty(documents) && isLoadingDocuments === false) {
            return (
                <Row>
                    <Col span={24}>
                        <h3 style={{ paddingLeft: 10, paddingRight: 10 }}>Attached Documents:</h3>

                        <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                            {documents.filter(doc => doc.documentType !== 'PRICE_CONFIRMATION' && doc.documentType !== 'CARRIER_INVOICE' && doc.documentType !== 'SHIPPER_INVOICE' && doc.documentType !== 'FACTORING_INVOICE' && doc.documentType !== 'INVOICE').map((document, index, arr) => {
                                return (
                                    <DataLinkRow key={`visible-invoice-document-${index}`} style={{ marginBottom: 8 }} title={document.documentDisplayName} fileId={document.id} />
                                );
                            })}
                        </div>
                    </Col>
                </Row>
            );
        } else {
            return null;
        }
    };

    const getShipperInvoiceDocuments = () => {
        if (isListNotEmpty(documents) && isLoadingDocuments === false) {
            return (
                <Row>
                    <Col span={24}>
                        <h3 style={{ paddingLeft: 10, paddingRight: 10 }}>Load Documents:</h3>

                        <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                            {documents.filter(doc => doc.documentType !== 'RATE_CONFIRMATION' && doc.documentType !== 'CARRIER_INVOICE' && doc.documentType !== 'SHIPPER_INVOICE' && doc.documentType !== 'FACTORING_INVOICE' && doc.documentType !== 'INVOICE').map((document, index, arr) => {
                                return (
                                    <DataLinkRow key={`visible-invoice-document-${index}`} style={{ marginBottom: 8 }} title={document.documentDisplayName} fileId={document.id} />
                                );
                            })}
                        </div>
                    </Col>
                </Row>
            );
        } else {
            return null;
        }
    };

    //#endregion

    if (isObjectNotEmpty(invoice) && isObjectNotEmpty(load) && isListNotEmpty(stops) && isStringNotEmpty(invoice.id) && isStringNotEmpty(invoiceId) && invoice.id === invoiceId) {
        if (invoice.invoiceType === 'SHIPPER_INVOICE') {
            return (
                <>
                    <PDFDownloadLink document={<InvoiceUtils.ShipperInvoiceTemplate invoice={invoice} load={load} stops={stops} invoiceLineItems={invoice.invoiceLineItems} />} fileName={`Invoice for Load ${load.irisId}.pdf`}>
                        {({ blob, url, loading, error }) => (loading ? (<div style={{ textAlign: 'center' }}>Loading Invoice...</div>) : (
                            <>
                                {isNotNullOrUndefined(url) ? (
                                    <PDFViewer style={{ width: '100%', height: '80vh' }} src={url}></PDFViewer>
                                ) : null}
                                <div style={{ textAlign: 'center', marginTop: 12 }}>
                                    <Button style={{ height: 30, width: 300 }} type="default">Download Copy of Invoice</Button>
                                </div>
                            </>
                        ))}
                    </PDFDownloadLink>
                </>
            );
        } else if (invoice.invoiceType === 'CARRIER_INVOICE') {
            return (
                <>
                    <PDFDownloadLink document={<InvoiceUtils.CarrierInvoiceTemplate invoice={invoice} load={load} stops={stops} invoiceLineItems={invoice.invoiceLineItems} />} fileName={`Invoice for Load ${load.irisId}.pdf`}>
                        {({ blob, url, loading, error }) => (loading ? (<div style={{ textAlign: 'center' }}>Loading Invoice...</div>) : (
                            <>
                                <PDFViewer style={{ width: '100%', height: '80vh' }} src={url}></PDFViewer>
                                <div style={{ textAlign: 'center', marginTop: 12 }}>
                                    <Button style={{ height: 30, width: 300 }} type="default">Download Copy of Invoice</Button>
                                </div>
                            </>
                        ))}
                    </PDFDownloadLink>

                    {getCarrierInvoiceDocuments()}
                </>
            );
        } else if (invoice.invoiceType === 'RATE_CONFIRMATION') {
            return (
                <>
                    {isStringEmpty(invoice.signature) && invoice.status !== 'COMPLETED' ? (
                        <>
                            <PDFViewer style={{ width: '100%', height: '80vh' }}>
                                <InvoiceUtils.RateConfirmationTemplate invoice={invoice} load={load} stops={stops} invoiceLineItems={invoice.invoiceLineItems} displayManualInstructions={displayManualInstructions} />
                            </PDFViewer>
                            <div style={{ textAlign: 'center', marginTop: 12 }}>
                                <Button style={{ height: 60, width: 300 }} type="primary" onClick={toggleSignatureModal}>Sign and Accept Load</Button>
                            </div>
                        </>
                    ) : (
                        <PDFDownloadLink document={<InvoiceUtils.RateConfirmationTemplate invoice={invoice} load={load} stops={stops} invoiceLineItems={invoice.invoiceLineItems} displayManualInstructions={displayManualInstructions} />} fileName={`Rate Confirmation for Load ${load.irisId}.pdf`}>
                            {({ blob, url, loading, error }) => (loading ? (<div style={{ textAlign: 'center' }}>Loading Rate Confirmation...</div>) : (
                                <>
                                    <PDFViewer style={{ width: '100%', height: '80vh' }} src={url}></PDFViewer>
                                    <div style={{ textAlign: 'center', marginTop: 12 }}>
                                        <Button style={{ height: 30, width: 300 }} type="default">Download Copy of Rate Confirmation</Button>
                                    </div>
                                </>
                            ))}
                        </PDFDownloadLink>
                    )}
                    {signaturePadComponents}
                </>
            );
        } else if (invoice.invoiceType === 'PRICE_CONFIRMATION') {
            return (
                <>
                    {isStringEmpty(invoice.signature) && invoice.status !== 'COMPLETED' ? (
                        <>
                            <PDFViewer style={{ width: '100%', height: '80vh' }}>
                                <InvoiceUtils.PriceConfirmationTemplate invoice={invoice} load={load} stops={stops} invoiceLineItems={invoice.invoiceLineItems} displayManualInstructions={displayManualInstructions} />
                            </PDFViewer>
                            <div style={{ textAlign: 'center', marginTop: 12 }}>
                                <Button style={{ height: 60, width: 300 }} type="primary" onClick={toggleSignatureModal}>Sign and Approve Load</Button>
                            </div>
                        </>
                    ) : (
                        <PDFDownloadLink document={<InvoiceUtils.PriceConfirmationTemplate invoice={invoice} load={load} stops={stops} invoiceLineItems={invoice.invoiceLineItems} displayManualInstructions={displayManualInstructions} />} fileName={`Load Tender for Load ${load.irisId}.pdf`}>
                            {({ blob, url, loading, error }) => (loading ? (<div style={{ textAlign: 'center' }}>Loading Load Tender...</div>) : (
                                <>
                                    <PDFViewer style={{ width: '100%', height: '80vh' }} src={url}></PDFViewer>
                                    <div style={{ textAlign: 'center', marginTop: 12 }}>
                                        <Button style={{ height: 30, width: 300 }} type="default">Download Copy of Load Tender</Button>
                                    </div>
                                </>
                            ))}
                        </PDFDownloadLink>
                    )}
                    {signaturePadComponents}
                </>
            );
        } else {
            return null;
        }
    } else {
        return null;
    }
};

export default Invoice;