import React, { useMemo } from 'react';
import { Alert, Input, Select, Spin, InputNumber, Switch } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Enums from '../../shared/enums';
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import FormButtons from '../FormButtons/FormButtons';
import { isListEmpty, isObjectNotEmpty, isStringEmpty, isStringNotEmpty, removeEmpty } from '../../shared/objectUtils';

const { Option } = Select;
const { TextArea } = Input;

const NewClaim = ({ loadId = null, irisLoadId = null, cancel }) => {

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    const dispatch = useDispatch();
    const loads = useSelector(state => state.loads.records);
    const isLoadsLoading = useSelector(state => state.loads.isLoading);
    const isLoading = useSelector(state => state.claims.isRecordAddLoading);
    const error = useSelector(state => state.claims.addRecordError);

    const currencyOptions = Enums.Currencies.selectListOptions();
    const bolStatusOptions = Enums.BOLStatuses.selectListOptions();
    const unitPackagingOptions = Enums.UnitPackaging.selectListOptions();

    const onSubmit = (data) => {
        let cleanedData = removeEmpty(data);

        if (isObjectNotEmpty(cleanedData)) {
            if (isStringNotEmpty(loadId) && isStringNotEmpty(irisLoadId)) {
                cleanedData.loadId = loadId;
                cleanedData.irisLoadId = irisLoadId;
            } else if (isStringNotEmpty(loadId)) {
                const load = loads.find(l => l.id === loadId);
                if (isObjectNotEmpty(load)) {
                    cleanedData.irisLoadId = load.irisId;
                }
                cleanedData.loadId = loadId;
            } else if (isStringNotEmpty(cleanedData.loadId)) {
                const load = loads.find(l => l.id === cleanedData.loadId);
                if (isObjectNotEmpty(load)) {
                    cleanedData.irisLoadId = load.irisId;
                }
            }

            dispatch(actionCreators.addClaim(cleanedData));
        } else {
            onCancel();
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.addClaimCancel());
        cancel();
    };

    useMemo(() => {
        if (isListEmpty(loads) && isStringEmpty(loadId)) {
            dispatch(actionCreators.fetchLoads({ page: 1, size: 1000000, isDeleted: false, sort: 'pickUpDateTime', order: 'asc' }));
        }
    }, [loadId]);

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.addClaimErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const formItemLayoutDouble = {
        labelCol1: { span: 8 },
        wrapperCol1: { span: 8 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 8 },
    };

    const formItemLayoutDouble2 = {
        labelCol1: { span: 8 },
        wrapperCol1: { span: 4 },
        labelCol2: { span: 6 },
        wrapperCol2: { span: 6 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true || isLoadsLoading === true) && error === null}>
                    <FormItem {...formItemLayout} label="Is Against Carrier" format="horizontal"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                onBlur={onBlur}
                                onChange={(checked, event) => { onChange(checked); }}
                                checked={value}
                                name={name}
                                ref={ref}
                            />
                        )}
                        name="isAgainstCarrier"
                        defaultValue={false}
                    />
                    <FormItem {...formItemLayout} label="Is Against Shipper" format="horizontal"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                onBlur={onBlur}
                                onChange={(checked, event) => { onChange(checked); }}
                                checked={value}
                                name={name}
                                ref={ref}
                            />
                        )}
                        name="isAgainstShipper"
                        defaultValue={false}
                    />
                    <FormItem {...formItemLayout} label="Will be claimed against Insurance" format="horizontal"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                onBlur={onBlur}
                                onChange={(checked, event) => { onChange(checked); }}
                                checked={value}
                                name={name}
                                ref={ref}
                            />
                        )}
                        name="isInsuranceClaim"
                        defaultValue={false}
                    />
                    <FormItem {...formItemLayout} label="Needs Fed One Inspection" format="horizontal"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                onBlur={onBlur}
                                onChange={(checked, event) => { onChange(checked); }}
                                checked={value}
                                name={name}
                                ref={ref}
                            />
                        )}
                        name="isFedOneInspection"
                        defaultValue={false}
                    />
                    <FormItem {...formItemLayout} label="Comments" required format="horizontal"
                        render={({ onChange, onBlur, value, name, ref }) => <TextArea onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} autoSize={{ minRows: 4 }} ref={ref} />}
                        rules={{ required: 'Required Field' }}
                        name="comments"
                    />
                    <FormItemDouble {...formItemLayoutDouble2} label1="Count" label2="Unit of Measure/Packaging" required1 required2 format="horizontal"
                        render1={({ onChange, onBlur, value, name, ref }) => (
                            <InputNumber
                                min={0}
                                placeholder="Count"
                                style={{ width: '100%' }}
                                onBlur={onBlur}
                                onChange={e => { onChange(e); }}
                                value={value}
                                name={name}
                                ref={ref}
                            />
                        )}
                        rules1={{ required: 'Required Field' }}
                        name1="count"
                        render2={({ onChange, onBlur, value, name, ref }) => (
                            <Select
                                placeholder="Please Select a Unit of Measure/Unit Packaging"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                                ref={ref}
                            >
                                {unitPackagingOptions}
                            </Select>
                        )}
                        rules2={{ required: "Required Field" }}
                        name2="unitPackaging"
                    />
                    <FormItem {...formItemLayout} label="BOL Status" required format="horizontal"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Select
                                placeholder="Please Select the BOL Status"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                                ref={ref}
                            >
                                {bolStatusOptions}
                            </Select>
                        )}
                        rules={{ required: 'Required Field' }}
                        name="bolStatus"
                    />
                    {(isStringEmpty(loadId) && isStringEmpty(irisLoadId)) ? (
                        <FormItem {...formItemLayout} label="Load" required format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select a Load"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    ref={ref}
                                >
                                    {loads.map(i => <Option value={i.id} key={i.id}>{i.irisId}</Option>)}
                                </Select>
                            )}
                            rules={{ required: 'Required Field' }}
                            name="loadId"
                        />
                    ) : null}
                    <FormItemDouble {...formItemLayoutDouble} label1="Adjustment Amount" required1 required2 format="horizontal"
                        render1={({ onChange, onBlur, value, name, ref }) => (
                            <InputNumber
                                min={0}
                                precision={2}
                                placeholder="Adjustment Amount"
                                style={{ width: '100%' }}
                                onBlur={onBlur}
                                onChange={e => { onChange(e); }}
                                value={value}
                                name={name}
                                ref={ref}
                            />
                        )}
                        rules1={{ required: "Required Field" }}
                        name1="adjustment"
                        render2={({ onChange, onBlur, value, name, ref }) => (
                            <Select
                                placeholder="Please Select"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                                ref={ref}
                            >
                                {currencyOptions}
                            </Select>
                        )}
                        rules2={{ required: 'Required Field' }}
                        name2="adjustmentUnit"
                        defaultValue2='USD'
                    />
                    <FormItem {...formItemLayout} label="BOL #" required format="horizontal"
                        render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="BOL #" ref={ref} />}
                        rules={{ required: 'Required Field' }}
                        name="bolNumber"
                    />
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Create Claim" />
            </Form>
        </FormProvider>
    );
};

export default NewClaim;