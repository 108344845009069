import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { Spin, Tabs, Row, Col, Menu, Dropdown, Modal, Drawer, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruckMoving } from '@fortawesome/free-solid-svg-icons';
import Header from '../../components/Header/Header';
import { useLocation } from 'react-router';
import ComplexCard from '../../components/ComplexCard/ComplexCard';
import DataRow from '../../components/DataRow/DataRow';
import { CloseCircleTwoTone, CheckCircleTwoTone, PaperClipOutlined, ControlOutlined, DownOutlined } from '@ant-design/icons';
import StringFormatter from '../../shared/stringFormatter';
import EditAsset from '../../components/EditAsset/EditAsset';
import DocumentList from '../../components/DocumentList/DocumentList';
import { isBooleanTrue, isListNotEmpty, isNumberNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';

const { TabPane } = Tabs;
const stringFormatter = new StringFormatter();

const Asset = (props) => {
    //#region props and constants

    const fullWidth = global.window.innerWidth;
    const id = props.match.params.id;
    const previousBreadcrumbs = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isListNotEmpty(props.history.location.state.previousBreadcrumbs) ? props.history.location.state.previousBreadcrumbs : [];
    const previousLocation = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageLocation) ? props.history.location.state.previousPageLocation : { pathname: `/assets` };
    const previousPath = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageLocation) ? props.history.location.state.previousPageLocation : `/assets`;
    const previousBreadcrumbName = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageTitle) ? props.history.location.state.previousPageTitle : 'Assets';


    //#endregion
    //#region useLocation

    const location = useLocation();

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.assets.isRecordLoading);
    const error = useSelector(state => state.assets.recordError);
    const asset = useSelector(state => state.assets.record);

    //#endregion
    //#region useStates

    const [tabKey, setTabKey] = useState('snapshot');
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showDocumentsModal, setShowDocumentsModal] = useState(false);

    //#endregion
    //#region tab methods

    const onTabChange = (key) => {
        props.history.replace({ ...props.location, hash: `#${key}` });
    };

    //#endregion
    //#region toggles

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleDocumentsModal = () => {
        setShowDocumentsModal(!showDocumentsModal);
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        if (isStringNotEmpty(location.hash)) {
            setTabKey(location.hash.replace('#', ''));
        } else {
            setTabKey('snapshot');
            props.history.replace({ ...location, hash: `#snapshot` });
        }
    }, [location]);

    useMemo(() => {
        dispatch(actionCreators.fetchAsset(id));
    }, [id]);

    //#endregion
    //#region displays

    const menu = () => {
        return (
            <Menu>
                <Menu.Item key="manageDocuments" onClick={(e) => { e.domEvent.stopPropagation(); toggleDocumentsModal(); }}>
                    <PaperClipOutlined style={{ marginRight: 8 }} />
                    <span>Manage Documents</span>
                </Menu.Item>
                <Menu.Item key="editEntity" onClick={(e) => { e.domEvent.stopPropagation(); toggleEditEntity(); }}>
                    <FontAwesomeIcon className="anticon" icon={faTruckMoving} style={{ marginRight: 8 }} />
                    <span>Edit Asset Details</span>
                </Menu.Item>
            </Menu>
        );
    };

    const extra = (
        <Dropdown overlay={menu} trigger={['click']}>
            <Button onClick={(e) => { e.stopPropagation(); }}><ControlOutlined /> <DownOutlined /></Button>
        </Dropdown>
    );

    let breadcrumbs = [...previousBreadcrumbs,
    {
        location: previousLocation,
        path: previousPath,
        breadcrumbName: previousBreadcrumbName,
    },
    {
        path: `/assets/${isStringNotEmpty(id) ? id : null}`,
        breadcrumbName: `${isObjectNotEmpty(asset) ? (isStringNotEmpty(asset.name) ? asset.name : asset.irisId) : ''}`,
    },
    ];

    const editEntityComponents = (
        <Drawer
            title={"Edit Asset Details"}
            onClose={toggleEditEntity}
            visible={showEditEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditAsset
                cancel={toggleEditEntity}
                asset={asset}
            />
        </Drawer>
    );

    const documentsComponent = (
        <Modal
            title="Asset Documents"
            visible={showDocumentsModal === true}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleDocumentsModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            {isObjectNotEmpty(asset) ? (
                <DocumentList
                    documentEntityType="ASSET"
                    documentEntityId={asset.id}
                />
            ) : null}
        </Modal>
    );

    //#endregion

    if (isObjectNotEmpty(asset) && asset.id === id) {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                <Header
                    title={isStringNotEmpty(asset.name) ? asset.name : asset.irisId}
                    subtitle={isStringNotEmpty(asset.assetType) ? `Asset Type: ${stringFormatter.toFormattedString('AssetType', asset.assetType, null)}` : null}
                    subtitle2={isObjectNotEmpty(asset.trailerType) && isStringNotEmpty(asset.trailerType.description) ? `Trailer Type: ${asset.trailerType.description}` : null}
                    title2={isStringNotEmpty(asset.truckOwnerType) ? `Ownership Type: ${stringFormatter.toFormattedString('TruckOwnerType', asset.truckOwnerType, null)}` : null}
                    title3={isBooleanTrue(asset.isCarrierAsset) ? `Is Carrier Asset: ${stringFormatter.toFormattedString('Boolean', asset.isCarrierAsset, null)}` : null}
                    title4={isBooleanTrue(asset.isContractorAsset) ? `Is Contractor Asset: ${stringFormatter.toFormattedString('Boolean', asset.isContractorAsset, null)}` : null}
                    highlights={[
                        {
                            title: 'Status',
                            value: `${isStringNotEmpty(asset.status) ? stringFormatter.toFormattedString('AssetStatus', asset.status, null) : ''}`
                        },
                        {
                            title: 'Available',
                            value: isBooleanTrue(asset.isAvailable) ? <CheckCircleTwoTone title="Available" alt="Available" twoToneColor="#23BEBB" /> : <CloseCircleTwoTone title="Not Available" alt="Not Available" twoToneColor="#EF5350" />
                        },
                        {
                            title: 'License Plate',
                            value: `${isStringNotEmpty(asset.licensePlateNum) && isStringNotEmpty(asset.licensePlateState) ? asset.licensePlateNum + ' ' + asset.licensePlateState : ''}`
                        },
                    ]}
                    icon={<FontAwesomeIcon className="anticon" icon={faTruckMoving} />}
                    breadcrumbs={breadcrumbs}
                />
                <Tabs activeKey={tabKey} onChange={onTabChange} destroyInactiveTabPane={true} tabBarStyle={{ backgroundColor: '#ffffff', paddingRight: 24, paddingLeft: 24, margin: 0 }}>
                    <TabPane tab="Snapshot" key="snapshot">
                        <div style={{ padding: 24 }}>
                            {(isLoading === false) ? (
                                <Row gutter={[16, 16]}>
                                    <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                        <ComplexCard
                                            title="Asset Specs"
                                            headStyle={{ fontSize: 14 }}
                                            extra={extra}
                                        >
                                            {isStringNotEmpty(asset.make) ? <DataRow title="Make" value={asset.make} dataType="String" /> : null}
                                            {isStringNotEmpty(asset.model) ? <DataRow title="Model" value={asset.model} dataType="String" /> : null}
                                            {isStringNotEmpty(asset.year) ? <DataRow title="Year" value={asset.year} dataType="String" /> : null}
                                            {isStringNotEmpty(asset.color) ? <DataRow title="Color" value={asset.color} dataType="String" /> : null}
                                            {isStringNotEmpty(asset.vin) ? <DataRow title="VIN" value={asset.vin} dataType="String" /> : null}
                                            {isStringNotEmpty(asset.dotNum) ? <DataRow title="Dot #" value={asset.dotNum} dataType="String" /> : null}
                                            {isStringNotEmpty(asset.truckClass) ? <DataRow title="Truck Class" value={asset.truckClass} dataType="String" /> : null}
                                        </ComplexCard>
                                    </Col>
                                    <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                        <ComplexCard
                                            title="Asset Details"
                                            headStyle={{ fontSize: 14 }}
                                        >
                                            {isNumberNotEmpty(asset.axles) ? <DataRow title="Axles" value={asset.axles} dataType="Integer" /> : null}
                                            {isNumberNotEmpty(asset.range) ? <DataRow title="Range" value={asset.range} units={asset.rangeUnit} dataType="Distance" /> : null}
                                            {isNumberNotEmpty(asset.overallLength) ? <DataRow title="Overall Length" value={asset.overallLength} units={asset.overallLengthUnit} dataType="Length" /> : null}
                                            {isNumberNotEmpty(asset.length) ? <DataRow title="Length" value={asset.length} units={asset.lengthUnit} dataType="Length" /> : null}
                                            {isNumberNotEmpty(asset.height) ? <DataRow title="Height" value={asset.height} units={asset.heightUnit} dataType="Length" /> : null}
                                            {isNumberNotEmpty(asset.width) ? <DataRow title="Width" value={asset.width} units={asset.widthUnit} dataType="Length" /> : null}
                                            {isNumberNotEmpty(asset.weight) ? <DataRow title="Weight" value={asset.weight} units={asset.weightUnit} dataType="Weight" /> : null}
                                            {isNumberNotEmpty(asset.loadCapacity) ? <DataRow title="Load Capacity" value={asset.loadCapacity} units={asset.loadCapacityUnit} dataType="Weight" /> : null}
                                            {isNumberNotEmpty(asset.maxLoadWeight) ? <DataRow title="Max Load Weight" value={asset.maxLoadWeight} units={asset.maxLoadWeightUnit} dataType="Weight" /> : null}
                                            {isNumberNotEmpty(asset.fuelTankCapacity) ? <DataRow title="Fuel Tank Capacity" value={asset.fuelTankCapacity} units={asset.fuelTankCapacityUnit} dataType="Volume" /> : null}
                                        </ComplexCard>
                                    </Col>
                                </Row>
                            ) : null}
                        </div>
                    </TabPane>
                    <TabPane tab="Documents" key="documents">
                        <div style={{ padding: 24 }}>
                            {isObjectNotEmpty(asset) ? (
                                <DocumentList
                                    documentEntityType="ASSET"
                                    documentEntityId={asset.id}
                                />
                            ) : null}
                        </div>
                    </TabPane>
                </Tabs>
                {editEntityComponents}
                {documentsComponent}
            </Spin>
        );
    } else {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={true} />
        );
    }
};

export default withRouter(Asset);