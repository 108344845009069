import axiosAuthenticated from "../../api/axiosAuthenticated";
import { isListNotEmpty, isNotNullOrUndefined, isStringNotEmpty } from "../../shared/objectUtils";
import * as DTO from "../dtos/index";

const productsPath = '/products';
const productsBulkPath = '/products/bulk/get';

//#region Products Methods

export const getProductsByIds = async (ids = []) => {
    let products = [];
    if (isListNotEmpty(ids)) {
        const productsRes = await axiosAuthenticated.post(productsBulkPath, { page: 1, size: 1000000, isDeleted: false, id: [...ids] });
        if (productsRes && productsRes.status === 200) {
            products = productsRes.data.data;
        }
    }

    return products;
};

export const getProductsByAccountId = async (accountId) => {
    let products = [];
    if (isStringNotEmpty(accountId)) {
        const productsRes = await axiosAuthenticated.post(productsBulkPath, { page: 1, size: 1000000, isDeleted: false, accountId: accountId });
        if (productsRes && productsRes.status === 200) {
            products = productsRes.data.data;
        }
    }

    return products;
};

export const getBulkProducts = async (searchParams = {}) => {
    let products = [];
    const productsRes = await axiosAuthenticated.post(productsBulkPath, { page: 1, size: 1000000, isDeleted: false, ...searchParams });
    if (productsRes && productsRes.status === 200) {
        products = productsRes.data.data;
    }

    return products;
};

export const getProducts = async (searchParams = {}, pagination = {}, accounts = [], commodities = []) => {
    const productsRes = await axiosAuthenticated.get(productsPath, { params: { ...searchParams } });
    if (productsRes && productsRes.status === 200) {
        const products = productsRes.data.data;
        const otherData = productsRes.data;

        const transformedProducts = products.map((product) => { return DTO.getProductDTO(product, accounts, commodities); });

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;

        return { data: transformedProducts, searchParams: searchParams, pagination: pagination };
    }

    return { data: [], searchParams: searchParams, pagination: pagination };
};

export const getProduct = async (id, accounts = [], commodities = []) => {
    const productsRes = await axiosAuthenticated.get(productsPath + `/${id}`);
    if (productsRes && productsRes.status === 200) {
        let product = productsRes.data;

        return DTO.getProductDTO(product, accounts, commodities);
    }

    return null;
};

export const addProduct = async (payload, accounts = [], commodities = []) => {
    payload = DTO.getAddProductRequestDTO(payload);

    const productsRes = await axiosAuthenticated.post(productsPath, { ...payload });
    if (productsRes && productsRes.status === 201) {
        let newProduct = productsRes.data;

        return DTO.getProductDTO(newProduct, accounts, commodities);
    }

    return null;
};

export const updateProduct = async (id, payload, accounts = [], commodities = []) => {
    payload = DTO.getUpdateProductRequestDTO(payload);

    const productsRes = await axiosAuthenticated.put(productsPath + `/${id}`, { ...payload });
    if (productsRes && productsRes.status === 200) {
        let updatedProduct = productsRes.data;

        return DTO.getProductDTO(updatedProduct, accounts, commodities);
    }

    return null;
};

export const removeProduct = async (id) => {
    const productsRes = await axiosAuthenticated.put(productsPath + `/${id}`, { isDeleted: true });
    if (productsRes && productsRes.status === 200) {
        return productsRes.data;
    }

    return null;
};

//#endregion