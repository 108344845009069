import * as actionTypes from "../actions/actionTypes";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import * as actionCreators from "./index";
import ErrorUtils from "../../shared/errorUtils";
import { selectListRecords } from "../utility";

//#region Fetch Transaction List Methods

export const fetchTransactionListStart = (listName) => {
    return {
        type: actionTypes.FETCH_TRANSACTION_LIST_START,
        payload: { listName: listName }
    }
};

export const fetchTransactionListSuccess = (listName, payload) => {
    return {
        type: actionTypes.FETCH_TRANSACTION_LIST_SUCCESS,
        payload: { listName: listName, ...payload }
    }
};

export const fetchTransactionListFail = (listName, payload) => {
    return {
        type: actionTypes.FETCH_TRANSACTION_LIST_FAIL,
        payload: { listName: listName, ...payload }
    }
};

export const clearTransactionList = (listName) => {
    return {
        type: actionTypes.CLEAR_TRANSACTION_LIST,
        payload: { listName: listName }
    }
};

export const clearTransactionLists = () => {
    return {
        type: actionTypes.CLEAR_TRANSACTION_LISTS
    }
};

export const addTransactionToTransactionList = (listName, payload) => {
    return {
        type: actionTypes.ADD_TO_TRANSACTION_LIST,
        payload: { listName: listName, newRecord: payload }
    }
};

export const updateTransactionInTransactionList = (listName, payload) => {
    return {
        type: actionTypes.UPDATE_IN_TRANSACTION_LIST,
        payload: { listName: listName, updatedRecord: payload }
    }
};

export const removeTransactionFromTransactionList = (listName, payload) => {
    return {
        type: actionTypes.REMOVE_FROM_TRANSACTION_LIST,
        payload: { listName: listName, recordToRemove: payload }
    }
};

//#endregion
//#region Add Transaction Methods

export const addTransactionStart = () => {
    return {
        type: actionTypes.ADD_TRANSACTION_START
    }
};

export const addTransactionSuccess = () => {
    return {
        type: actionTypes.ADD_TRANSACTION_SUCCESS
    }
};

export const addTransactionFail = (payload) => {
    return {
        type: actionTypes.ADD_TRANSACTION_FAIL,
        payload: payload
    }
};

export const addTransactionLoadingClear = () => {
    return {
        type: actionTypes.ADD_TRANSACTION_LOADING_CLEAR
    }
};

export const addTransactionErrorClear = () => {
    return {
        type: actionTypes.ADD_TRANSACTION_ERROR_CLEAR
    }
};

export const addTransactionCancel = () => {
    return {
        type: actionTypes.ADD_TRANSACTION_CANCEL
    }
};

//#endregion
//#region Update Transaction Methods

const changeSingleTransaction = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_TRANSACTION,
        payload: payload
    }
};

export const updateTransactionStart = () => {
    return {
        type: actionTypes.UPDATE_TRANSACTION_START
    }
};

export const updateTransactionSuccess = () => {
    return {
        type: actionTypes.UPDATE_TRANSACTION_SUCCESS
    }
};

export const updateTransactionFail = (payload) => {
    return {
        type: actionTypes.UPDATE_TRANSACTION_FAIL,
        payload: payload
    }
};

export const updateTransactionLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_TRANSACTION_LOADING_CLEAR
    }
};

export const updateTransactionErrorClear = () => {
    return {
        type: actionTypes.UPDATE_TRANSACTION_ERROR_CLEAR
    }
};

export const updateTransactionCancel = () => {
    return {
        type: actionTypes.UPDATE_TRANSACTION_CANCEL
    }
};

//#endregion
//#region Remove Transaction Methods

export const removeTransactionStart = () => {
    return {
        type: actionTypes.REMOVE_TRANSACTION_START
    }
};

export const removeTransactionSuccess = () => {
    return {
        type: actionTypes.REMOVE_TRANSACTION_SUCCESS
    }
};

export const removeTransactionFail = (payload) => {
    return {
        type: actionTypes.REMOVE_TRANSACTION_FAIL,
        payload: payload
    }
};

export const removeTransactionLoadingClear = () => {
    return {
        type: actionTypes.REMOVE_TRANSACTION_LOADING_CLEAR
    }
};

export const removeTransactionErrorClear = () => {
    return {
        type: actionTypes.REMOVE_TRANSACTION_ERROR_CLEAR
    }
};

export const removeTransactionCancel = () => {
    return {
        type: actionTypes.REMOVE_TRANSACTION_CANCEL
    }
};

//#endregion
//#region Transactions Methods

export const fetchTransactionList = (listName, payload) => {
    return async (dispatch, getState) => {
        if (isStringNotEmpty(listName)) {
            try {
                dispatch(fetchTransactionListStart(listName));

                const state = getState();
                const transactionsState = { ...state.transactions };
                const existingLists = { ...transactionsState.lists };

                let pagination = {};
                let searchParams = {};
                if (isObjectNotEmpty(existingLists[listName])) {
                    pagination = { ...existingLists[listName].pagination };
                    searchParams = { ...existingLists[listName].searchParams };
                }

                if (isObjectNotEmpty(payload)) {
                    searchParams = { ...payload };
                }

                const res = await Data.getTransactions({ ...searchParams }, pagination);
                dispatch(fetchTransactionListSuccess(listName, { records: res.data, params: { searchParams: res.searchParams, pagination: res.pagination } }));
            } catch (error) {
                logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
                dispatch(fetchTransactionListFail(listName, { error: ErrorUtils.getErrorMessage(error) }));
            }
        }
    }
};

export const addTransaction = (payload, loadId = null, invoice, sendLoadEvent = false) => {
    return async (dispatch, getState) => {
        try {
            dispatch(addTransactionStart());

            const state = getState();
            const invoiceLineItemsState = { ...state.invoiceLineItems };
            const transactionsState = { ...state.transactions };
            const claimsState = { ...state.claims };
            let existingInvoiceLineItems = selectListRecords({ ...invoiceLineItemsState.lists }, loadId);
            let existingTransactions = selectListRecords({ ...transactionsState.lists }, loadId);
            let existingClaims = selectListRecords({ ...claimsState.lists }, loadId);

            const newTransaction = await Data.addTransaction(payload, existingInvoiceLineItems, existingTransactions, existingClaims, loadId, invoice, sendLoadEvent);
            if (isObjectNotEmpty(newTransaction)) {
                if (isStringNotEmpty(newTransaction.fromEntityId)) {
                    dispatch(addTransactionToTransactionList(newTransaction.fromEntityId, newTransaction));
                }
                if (isStringNotEmpty(newTransaction.toEntityId)) {
                    dispatch(addTransactionToTransactionList(newTransaction.toEntityId, newTransaction));
                }
                if (isStringNotEmpty(newTransaction.loadId)) {
                    dispatch(addTransactionToTransactionList(newTransaction.loadId, newTransaction));
                }

                if (isStringNotEmpty(loadId) && sendLoadEvent === true) {
                    dispatch(actionCreators.loadEventUpdateTimeout(loadId, null, null, null, 'LOAD_UPDATED'));
                }
            }

            dispatch(addTransactionSuccess());
            dispatch(addTransactionLoadingClear());
            dispatch(addTransactionErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addTransactionFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateTransaction = (id, payload, oldTransaction, loadId = null, invoice, sendLoadEvent = false) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updateTransactionStart());

            const state = getState();
            const invoiceLineItemsState = { ...state.invoiceLineItems };
            const transactionsState = { ...state.transactions };
            const claimsState = { ...state.claims };
            let existingInvoiceLineItems = selectListRecords({ ...invoiceLineItemsState.lists }, loadId);
            let existingTransactions = selectListRecords({ ...transactionsState.lists }, loadId);
            let existingClaims = selectListRecords({ ...claimsState.lists }, loadId);

            const updatedTransaction = await Data.updateTransaction(id, payload, oldTransaction, existingInvoiceLineItems, existingTransactions, existingClaims, loadId, invoice, sendLoadEvent);
            if (isObjectNotEmpty(updatedTransaction)) {
                if (isStringNotEmpty(updatedTransaction.fromEntityId)) {
                    dispatch(updateTransactionInTransactionList(updatedTransaction.fromEntityId, updatedTransaction));
                }
                if (isStringNotEmpty(updatedTransaction.toEntityId)) {
                    dispatch(updateTransactionInTransactionList(updatedTransaction.toEntityId, updatedTransaction));
                }
                if (isStringNotEmpty(updatedTransaction.loadId)) {
                    dispatch(updateTransactionInTransactionList(updatedTransaction.loadId, updatedTransaction));
                }
                changeSingleTransaction(updatedTransaction);

                if (isStringNotEmpty(loadId) && sendLoadEvent === true) {
                    dispatch(actionCreators.loadEventUpdateTimeout(loadId, null, null, null, 'LOAD_UPDATED'));
                }
            }

            dispatch(updateTransactionSuccess());
            dispatch(updateTransactionLoadingClear());
            dispatch(updateTransactionErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateTransactionFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const removeTransaction = (id, oldTransaction, loadId = null, invoice, sendLoadEvent = false) => {
    return async (dispatch, getState) => {
        try {
            dispatch(removeTransactionStart());

            const state = getState();
            const invoiceLineItemsState = { ...state.invoiceLineItems };
            const transactionsState = { ...state.transactions };
            const claimsState = { ...state.claims };
            let existingInvoiceLineItems = selectListRecords({ ...invoiceLineItemsState.lists }, loadId);
            let existingTransactions = selectListRecords({ ...transactionsState.lists }, loadId);
            let existingClaims = selectListRecords({ ...claimsState.lists }, loadId);

            const removedTransaction = await Data.removeTransaction(id, oldTransaction, existingInvoiceLineItems, existingTransactions, existingClaims, loadId, invoice, sendLoadEvent);
            if (isObjectNotEmpty(removedTransaction)) {
                if (isStringNotEmpty(removedTransaction.fromEntityId)) {
                    dispatch(removeTransactionFromTransactionList(removedTransaction.fromEntityId, removedTransaction));
                }
                if (isStringNotEmpty(removedTransaction.toEntityId)) {
                    dispatch(removeTransactionFromTransactionList(removedTransaction.toEntityId, removedTransaction));
                }
                if (isStringNotEmpty(removedTransaction.loadId)) {
                    dispatch(removeTransactionFromTransactionList(removedTransaction.loadId, removedTransaction));
                }

                if (isStringNotEmpty(loadId) && sendLoadEvent === true) {
                    dispatch(actionCreators.loadEventUpdateTimeout(loadId, null, null, null, 'LOAD_UPDATED'));
                }
            }

            dispatch(removeTransactionSuccess());
            dispatch(removeTransactionLoadingClear());
            dispatch(removeTransactionErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(removeTransactionFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion