import Checks from '../entitlementUtils';

export const canCreateCarrierRejection = (auth) => {
    return Checks.allowStaff(auth);
};

export const canReadCarrierRejection = (auth) => {
    return Checks.allowStaff(auth);
};

export const canReadCarrierRejectionList = (auth) => {
    return Checks.allowStaff(auth);
};

export const canUpdateCarrierRejection = (auth) => {
    return Checks.allowStaff(auth);
};

export const canDeleteCarrierRejection = (auth) => {
    return Checks.allowStaffAdmin(auth);
};