import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Button, Alert, Spin, Input, Modal, Select, InputNumber, Row, Col, Radio, Checkbox, Timeline, Empty } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Enums from '../../shared/enums';
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import StringFormatter from '../../shared/stringFormatter';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import DataCollapsableRow from '../DataCollapsableRow/DataCollapsableRow';
import { ErrorMessage } from '@hookform/error-message';
import { isListEmpty, isListNotEmpty, isNotNullOrUndefined, isNumberNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import LaneUtils from '../../api/utils/laneUtils';

const { Option } = Select;
const stringFormatter = new StringFormatter();

const EditLinkedLoadLane = ({ linkedLoadLane, cancel }) => {
    //#region useRefs

    const preCoolReefer = useRef(null);
    const minRunTemp = useRef(null);
    const maxRunTemp = useRef(null);

    //#endregion
    //#region useForms

    const methods = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            customerLaneId: linkedLoadLane.customerLaneId ? linkedLoadLane.customerLaneId : '',
            perMileRate: linkedLoadLane.perMileRate ? linkedLoadLane.perMileRate : null,
            perMileRateUnit: linkedLoadLane.perMileRateUnit ? linkedLoadLane.perMileRateUnit : null,
            contractRate: linkedLoadLane.contractRate ? linkedLoadLane.contractRate : null,
            contractRateUnit: linkedLoadLane.contractRateUnit ? linkedLoadLane.contractRateUnit : null,
            trailerWeightCapacity: linkedLoadLane.equipmentNeeds && linkedLoadLane.equipmentNeeds.trailerWeightCapacity ? linkedLoadLane.equipmentNeeds.trailerWeightCapacity : null,
            minRunTemp: linkedLoadLane.equipmentNeeds && linkedLoadLane.equipmentNeeds.minRunTemp ? linkedLoadLane.equipmentNeeds.minRunTemp : null,
            maxRunTemp: linkedLoadLane.equipmentNeeds && linkedLoadLane.equipmentNeeds.maxRunTemp ? linkedLoadLane.equipmentNeeds.maxRunTemp : null,
            preCoolReefer: linkedLoadLane.equipmentNeeds && linkedLoadLane.equipmentNeeds.preCoolReefer ? linkedLoadLane.equipmentNeeds.preCoolReefer : null,
            equipmentSpecifics: linkedLoadLane.equipmentSpecifics ? linkedLoadLane.equipmentSpecifics : null,
            driverRequirements: linkedLoadLane.driverRequirements ? linkedLoadLane.driverRequirements : null
        }
    });
    const methods2 = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            loadingType: linkedLoadLane.loadingType ? linkedLoadLane.loadingType : null
        }
    });
    const methods3 = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            commodityId: null,
            unitPackagingInitCount: null,
            unitPackaging: null,
            bulkPackagingInitCount: null,
            bulkPackaging: null
        }
    });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.linkedLoadLanes.isRecordUpdateLoading);
    const error = useSelector(state => state.linkedLoadLanes.updateRecordError);
    const commodities = useSelector(state => state.orchestrator.commodities);
    const lineItemTypes = useSelector(state => state.orchestrator.lineItemTypes);
    const trailerTypes = useSelector(state => state.orchestrator.trailerTypes);
    const entityType = useSelector(state => state.auth.entityType);
    const entityId = useSelector(state => state.auth.entityId);

    //#endregion
    //#region Enums

    const currencyOptions = Enums.Currencies.selectListOptions();
    const unitPackagingOptions = Enums.UnitPackaging.selectListOptions();
    const bulkPackagingOptions = Enums.BulkPackaging.selectListOptions();

    //#endregion
    //#region useStates

    const [pickUpStops, setPickUpStops] = useState([]);
    const [dropOffStops, setDropOffStops] = useState([]);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [showEditStopLocation, setShowEditStopLocation] = useState(false);
    const [showAddStopCommodity, setShowAddStopCommodity] = useState(false);
    const [equipmentSpecificOptions, setEquipmentSpecificOptions] = useState([]);
    const [driverRequirementOptions, setDriverRequirementOptions] = useState([]);
    const [showReeferOptions, setShowReeferOptions] = useState(false);

    //#endregion
    //#region toggles

    const toggleEditStopLocation = () => {
        // reset form
        methods2.setValue('loadingType', null);
        methods2.clearErrors();

        setShowEditStopLocation(!showEditStopLocation);
    };

    const toggleAddStopCommodity = () => {
        // reset form
        methods3.setValue('commodityId', null);
        methods3.setValue('unitPackagingInitCount', null);
        methods3.setValue('unitPackaging', null);
        methods3.setValue('bulkPackagingInitCount', null);
        methods3.setValue('bulkPackaging', null);
        methods3.clearErrors();

        setShowAddStopCommodity(!showAddStopCommodity);
    };

    //#endregion
    //#region submits, validators, and cancels

    const onEditStopLocation = (data) => {
        const loadingType = data.loadingType;
        const updatedValues = {
            loadingType: loadingType
        };
        if (isObjectNotEmpty(selectedRecord)) {
            if (selectedRecord.stopType === 'PICK_UP') {
                let existingList = [...pickUpStops];
                const index = existingList.findIndex(obj => obj.sequence === selectedRecord.sequence);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        ...updatedValues
                    };
                    // console.log(updatedRecord);
                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];
                    setPickUpStops([...updatedRecords]);
                }
            } else if (selectedRecord.stopType === 'DROP_OFF') {
                let existingList = [...dropOffStops];
                const index = existingList.findIndex(obj => obj.sequence === selectedRecord.sequence);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        ...updatedValues
                    };
                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    setDropOffStops([...updatedRecords]);
                }
            }

            toggleEditStopLocation();
            setSelectedRecord(null);
        } else {
            return;
        }
    };

    const onAddStopCommodity = (data) => {
        const commodityId = data.commodityId;
        const unitPackagingInitCount = data.unitPackagingInitCount;
        const unitPackaging = data.unitPackaging;
        const bulkPackagingInitCount = data.bulkPackagingInitCount;
        const bulkPackaging = data.bulkPackaging;

        methods3.clearErrors();

        let name = null;
        if (isListNotEmpty(commodities)) {
            let commodity = commodities.find(c => c.id === commodityId);
            if (isObjectNotEmpty(commodity)) {
                name = commodity.name;
            }
        }

        let newStopCommodity = {
            commodityId: commodityId,
            name: name
        };

        if (unitPackagingInitCount !== undefined && unitPackagingInitCount !== null && unitPackagingInitCount > 0) {
            if (unitPackaging === undefined || unitPackaging === null) {
                methods3.setError('unitPackaging', { type: "validate", message: 'You must provide a Unit of Measure/Unit Packaging if you are going to provide a Unit Quantity for this Commodity.' });
                return;
            } else {
                newStopCommodity.unitPackagingInitCount = unitPackagingInitCount;
                newStopCommodity.unitPackaging = unitPackaging;
            }
        } else if ((unitPackagingInitCount === undefined || unitPackagingInitCount === null || unitPackagingInitCount === 0) && (unitPackaging !== undefined && unitPackaging !== null)) {
            methods3.setError('unitPackagingInitCount', { type: "validate", message: 'You must provide a Unit Quantity greater than 0 if you are going to provide a Unit of Measure/Unit Packaging for this Commodity.' });
            return;
        }

        if (bulkPackagingInitCount !== undefined && bulkPackagingInitCount !== null && bulkPackagingInitCount > 0) {
            if (bulkPackaging === undefined || bulkPackaging === null) {
                methods3.setError('bulkPackaging', { type: "validate", message: 'You must provide Bulk Packaging if you are going to provide a Bulk Quantity for this Commodity.' });
                return;
            } else {
                newStopCommodity.bulkPackagingInitCount = bulkPackagingInitCount;
                newStopCommodity.bulkPackaging = bulkPackaging;
            }
        } else if ((bulkPackagingInitCount === undefined || bulkPackagingInitCount === null || bulkPackagingInitCount === 0) && (bulkPackaging !== undefined && bulkPackaging !== null)) {
            methods3.setError('bulkPackagingInitCount', { type: "validate", message: 'You must provide a Bulk Quantity greater than 0 if you are going to provide Bulk Packaging for this Commodity.' });
            return;
        }

        //console.log(newStopCommodity);

        if (isObjectNotEmpty(selectedRecord)) {
            let stopCommodities = [];
            if (isListNotEmpty(selectedRecord.commodities)) {
                stopCommodities = [...selectedRecord.commodities];
                if (stopCommodities.find(c => c.commodityId === commodityId && c.bulkPackaging === bulkPackaging && c.unitPackaging === unitPackaging) === undefined) {
                    stopCommodities.push(newStopCommodity);
                } else {
                    methods3.setError('commodityId', { type: "validate", message: 'This commodity has already been added to this location with the unit and bulk packaging you provided.' });
                    return;
                }
            } else {
                stopCommodities.push(newStopCommodity);
            }

            //console.log(stopCommodities);

            if (selectedRecord.stopType === 'PICK_UP') {
                let existingList = [...pickUpStops];
                const index = existingList.findIndex(obj => obj.sequence === selectedRecord.sequence);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        commodities: [...stopCommodities]
                    };

                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    setPickUpStops([...updatedRecords]);

                    if (dropOffStops.length === 1) {
                        // if there is only one drop-off stop, set the value for the commodities
                        let updatedPickUpRecords = [...updatedRecords];
                        if (isListNotEmpty(updatedPickUpRecords)) {
                            let dropOffStopCommodities = [];
                            if (updatedPickUpRecords.length === 1) {
                                if (isListNotEmpty(updatedPickUpRecords[0].commodities)) {
                                    dropOffStopCommodities = updatedPickUpRecords[0].commodities;
                                }
                            } else if (updatedPickUpRecords.length > 1) {
                                updatedPickUpRecords.forEach((pickUpStop) => {
                                    if (isListNotEmpty(pickUpStop.commodities)) {
                                        pickUpStop.commodities.forEach((stopCommodity) => {
                                            let existingDropOffStopCommodity = dropOffStopCommodities.find(c => c.commodityId === stopCommodity.commodityId && c.unitPackaging === stopCommodity.unitPackaging && c.bulkPackaging === stopCommodity.bulkPackaging);
                                            if (existingDropOffStopCommodity === undefined || existingDropOffStopCommodity === null) {
                                                dropOffStopCommodities.push(stopCommodity);
                                            } else {
                                                const existingDropOffStopCommodityIndex = dropOffStopCommodities.findIndex(c => c.commodityId === stopCommodity.commodityId && c.unitPackaging === stopCommodity.unitPackaging && c.bulkPackaging === stopCommodity.bulkPackaging);
                                                // only update if the record exists in the list
                                                if (existingDropOffStopCommodityIndex !== -1) {
                                                    const updatedExistingDropOffStopCommodity = {
                                                        ...dropOffStopCommodities[existingDropOffStopCommodityIndex]
                                                    };

                                                    if (existingDropOffStopCommodity.unitPackaging !== undefined && existingDropOffStopCommodity.unitPackaging !== null && existingDropOffStopCommodity.unitPackaging === stopCommodity.unitPackaging) {
                                                        updatedExistingDropOffStopCommodity.unitPackagingInitCount = updatedExistingDropOffStopCommodity.unitPackagingInitCount + stopCommodity.unitPackagingInitCount;
                                                    }
                                                    if (existingDropOffStopCommodity.bulkPackaging !== undefined && existingDropOffStopCommodity.bulkPackaging !== null && existingDropOffStopCommodity.bulkPackaging === stopCommodity.bulkPackaging) {
                                                        updatedExistingDropOffStopCommodity.bulkPackagingInitCount = updatedExistingDropOffStopCommodity.bulkPackagingInitCount + stopCommodity.bulkPackagingInitCount;
                                                    }

                                                    const updatedExistingDropOffStopCommodities = [
                                                        ...dropOffStopCommodities.slice(0, existingDropOffStopCommodityIndex), // everything before current obj
                                                        updatedExistingDropOffStopCommodity,
                                                        ...dropOffStopCommodities.slice(existingDropOffStopCommodityIndex + 1), // everything after current obj
                                                    ];

                                                    dropOffStopCommodities = [...updatedExistingDropOffStopCommodities];
                                                }
                                            }
                                        });
                                    }
                                });
                            }

                            let existingDropOffList = [...dropOffStops];

                            const updatedDropOffRecord = {
                                ...existingDropOffList[0],
                                commodities: [...dropOffStopCommodities]
                            };
                            const updatedDropOffRecords = [
                                ...existingDropOffList.slice(0, 0), // everything before current obj
                                updatedDropOffRecord,
                                ...existingDropOffList.slice(1), // everything after current obj
                            ];

                            setDropOffStops([...updatedDropOffRecords]);
                        }
                    }
                }
            } else if (selectedRecord.stopType === 'DROP_OFF') {
                let existingList = [...dropOffStops];
                const index = existingList.findIndex(obj => obj.sequence === selectedRecord.sequence);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        commodities: [...stopCommodities]
                    };
                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    setDropOffStops([...updatedRecords]);
                }
            }

            toggleAddStopCommodity();
            setSelectedRecord(null);
        } else {
            return;
        }
    };

    const onRemoveStopCommodity = (stop, index) => {
        if (isObjectNotEmpty(stop)) {
            let stopCommodities = [];
            if (isListNotEmpty(stop.commodities)) {
                stopCommodities = [...stop.commodities];
                stopCommodities.splice(index, 1);
            }
            if (stop.stopType === 'PICK_UP') {
                let existingList = [...pickUpStops];
                const index = existingList.findIndex(obj => obj.sequence === stop.sequence);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        commodities: [...stopCommodities]
                    };

                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    setPickUpStops([...updatedRecords]);

                    if (dropOffStops.length === 1) {
                        // if there is only one drop-off stop, set the value for the commodities
                        let updatedPickUpRecords = [...updatedRecords];
                        if (isListNotEmpty(updatedPickUpRecords)) {
                            let dropOffStopCommodities = [];
                            if (updatedPickUpRecords.length === 1) {
                                if (isListNotEmpty(updatedPickUpRecords[0].commodities)) {
                                    dropOffStopCommodities = updatedPickUpRecords[0].commodities;
                                }
                            } else if (updatedPickUpRecords.length > 1) {
                                updatedPickUpRecords.forEach((pickUpStop) => {
                                    if (isListNotEmpty(pickUpStop.commodities)) {
                                        pickUpStop.commodities.forEach((stopCommodity) => {
                                            let existingDropOffStopCommodity = dropOffStopCommodities.find(c => c.commodityId === stopCommodity.commodityId && c.unitPackaging === stopCommodity.unitPackaging && c.bulkPackaging === stopCommodity.bulkPackaging);
                                            if (existingDropOffStopCommodity === undefined || existingDropOffStopCommodity === null) {
                                                dropOffStopCommodities.push(stopCommodity);
                                            } else {
                                                const existingDropOffStopCommodityIndex = dropOffStopCommodities.findIndex(c => c.commodityId === stopCommodity.commodityId && c.unitPackaging === stopCommodity.unitPackaging && c.bulkPackaging === stopCommodity.bulkPackaging);
                                                // only update if the record exists in the list
                                                if (existingDropOffStopCommodityIndex !== -1) {
                                                    const updatedExistingDropOffStopCommodity = {
                                                        ...dropOffStopCommodities[existingDropOffStopCommodityIndex]
                                                    };

                                                    if (existingDropOffStopCommodity.unitPackaging !== undefined && existingDropOffStopCommodity.unitPackaging !== null && existingDropOffStopCommodity.unitPackaging === stopCommodity.unitPackaging) {
                                                        updatedExistingDropOffStopCommodity.unitPackagingInitCount = updatedExistingDropOffStopCommodity.unitPackagingInitCount + stopCommodity.unitPackagingInitCount;
                                                    }
                                                    if (existingDropOffStopCommodity.bulkPackaging !== undefined && existingDropOffStopCommodity.bulkPackaging !== null && existingDropOffStopCommodity.bulkPackaging === stopCommodity.bulkPackaging) {
                                                        updatedExistingDropOffStopCommodity.bulkPackagingInitCount = updatedExistingDropOffStopCommodity.bulkPackagingInitCount + stopCommodity.bulkPackagingInitCount;
                                                    }

                                                    const updatedExistingDropOffStopCommodities = [
                                                        ...dropOffStopCommodities.slice(0, existingDropOffStopCommodityIndex), // everything before current obj
                                                        updatedExistingDropOffStopCommodity,
                                                        ...dropOffStopCommodities.slice(existingDropOffStopCommodityIndex + 1), // everything after current obj
                                                    ];

                                                    dropOffStopCommodities = [...updatedExistingDropOffStopCommodities];
                                                }
                                            }
                                        });
                                    }
                                });
                            }

                            let existingDropOffList = [...dropOffStops];

                            const updatedDropOffRecord = {
                                ...existingDropOffList[0],
                                commodities: [...dropOffStopCommodities]
                            };
                            const updatedDropOffRecords = [
                                ...existingDropOffList.slice(0, 0), // everything before current obj
                                updatedDropOffRecord,
                                ...existingDropOffList.slice(1), // everything after current obj
                            ];

                            setDropOffStops([...updatedDropOffRecords]);
                        }
                    }
                }
            } else if (stop.stopType === 'DROP_OFF') {
                let existingList = [...dropOffStops];
                const index = existingList.findIndex(obj => obj.sequence === stop.sequence);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        commodities: [...stopCommodities]
                    };
                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    setDropOffStops([...updatedRecords]);
                }
            }
        } else {
            return;
        }
    };

    const onSubmit = (data) => {
        let payload = { ...data };

        if (isListNotEmpty(pickUpStops) && isListNotEmpty(dropOffStops)) {
            methods.clearErrors('pickUpStops');
            methods.clearErrors('dropOffStops');

            let loadLaneLocations = [];

            pickUpStops.forEach((stop, stopIndex, arr) => {
                let newStop = {
                    loadingType: stop.loadingType,
                    locationId: stop.locationId,
                    sequence: stop.sequence,
                    stopType: stop.stopType
                };

                if (isListNotEmpty(stop.commodities)) {
                    let newCommodities = [];
                    stop.commodities.forEach((commodity) => {
                        // only want to add commodities to the stop if there are quantities provided for that commodity
                        if (isNumberNotEmpty(commodity.unitPackagingInitCount)) {
                            let newCommodity = {
                                commodityId: commodity.commodityId,
                                name: commodity.name,
                                unitPackaging: commodity.unitPackaging,
                                unitPackagingInitCount: Number(commodity.unitPackagingInitCount)
                            };

                            if (isStringNotEmpty(commodity.bulkPackaging)) {
                                newCommodity.bulkPackaging = commodity.bulkPackaging;
                                newCommodity.bulkPackagingInitCount = Number(commodity.bulkPackagingInitCount);
                            }
                            newCommodities.push(newCommodity);
                        }
                    });

                    if (isListNotEmpty(newCommodities)) {
                        newStop.commodities = newCommodities;
                    }
                }

                loadLaneLocations.push({ ...newStop });
            });

            dropOffStops.forEach((stop, stopIndex, arr) => {
                let newStop = {
                    loadingType: stop.loadingType,
                    locationId: stop.locationId,
                    sequence: stop.sequence,
                    stopType: stop.stopType
                };

                if (isListNotEmpty(stop.commodities)) {
                    let newCommodities = [];
                    stop.commodities.forEach((commodity) => {
                        // only want to add commodities to the stop if there are quantities provided for that commodity
                        if (isNumberNotEmpty(commodity.unitPackagingInitCount)) {
                            let newCommodity = {
                                commodityId: commodity.commodityId,
                                name: commodity.name,
                                unitPackaging: commodity.unitPackaging,
                                unitPackagingInitCount: Number(commodity.unitPackagingInitCount)
                            };

                            if (isStringNotEmpty(commodity.bulkPackaging)) {
                                newCommodity.bulkPackaging = commodity.bulkPackaging;
                                newCommodity.bulkPackagingInitCount = Number(commodity.bulkPackagingInitCount);
                            }
                            newCommodities.push(newCommodity);
                        }
                    });

                    if (isListNotEmpty(newCommodities)) {
                        newStop.commodities = newCommodities;
                    }
                }

                loadLaneLocations.push({ ...newStop });
            });

            payload.locations = loadLaneLocations;

            payload.equipmentNeeds = {};
            if (isObjectNotEmpty(linkedLoadLane.equipmentNeeds)) {
                if (isStringNotEmpty(linkedLoadLane.equipmentNeeds.trailerTypeId)) {
                    payload.equipmentNeeds.trailerTypeId = linkedLoadLane.equipmentNeeds.trailerTypeId;
                } else {
                    payload.equipmentNeeds.trailerTypeId = null;
                }

                if (isNumberNotEmpty(linkedLoadLane.equipmentNeeds.trailerLength)) {
                    payload.equipmentNeeds.trailerLength = Number(linkedLoadLane.equipmentNeeds.trailerLength);
                    payload.equipmentNeeds.trailerLengthUnit = "FT";
                } else {
                    payload.equipmentNeeds.trailerLength = null;
                    payload.equipmentNeeds.trailerLengthUnit = null;
                }
            }

            if (isObjectNotEmpty(data.equipmentNeeds)) {
                if (isNumberNotEmpty(data.equipmentNeeds.trailerWeightCapacity)) {
                    payload.equipmentNeeds.trailerWeightCapacity = Number(data.equipmentNeeds.trailerWeightCapacity);
                    payload.equipmentNeeds.trailerWeightCapacityUnit = "LB";
                } else {
                    payload.equipmentNeeds.trailerWeightCapacity = null;
                    payload.equipmentNeeds.trailerWeightCapacityUnit = null;
                }

                if (isNumberNotEmpty(data.equipmentNeeds.preCoolReefer)) {
                    payload.equipmentNeeds.preCoolReefer = Number(data.equipmentNeeds.preCoolReefer);
                    payload.equipmentNeeds.preCoolReeferUnit = "F";
                } else if (data.equipmentNeeds.preCoolReefer === null) {
                    payload.equipmentNeeds.preCoolReefer = null;
                    payload.equipmentNeeds.preCoolReeferUnit = null;
                }

                if (isNumberNotEmpty(data.equipmentNeeds.minRunTemp)) {
                    payload.equipmentNeeds.minRunTemp = Number(data.equipmentNeeds.minRunTemp);
                    payload.equipmentNeeds.minRunTempUnit = "F";
                } else if (data.equipmentNeeds.minRunTemp === null) {
                    payload.equipmentNeeds.minRunTemp = null;
                    payload.equipmentNeeds.minRunTempUnit = null;
                }

                if (isNumberNotEmpty(data.equipmentNeeds.maxRunTemp)) {
                    payload.equipmentNeeds.maxRunTemp = Number(data.equipmentNeeds.maxRunTemp);
                    payload.equipmentNeeds.maxRunTempUnit = "F";
                } else if (data.equipmentNeeds.maxRunTemp === null) {
                    payload.equipmentNeeds.maxRunTemp = null;
                    payload.equipmentNeeds.maxRunTempUnit = null;
                }
            }

            if (isListNotEmpty(data.driverRequirements)) {
                payload.driverRequirements = data.driverRequirements;
            } else {
                payload.driverRequirements = [];
            }

            if (isListNotEmpty(data.equipmentSpecifics)) {
                payload.equipmentSpecifics = data.equipmentSpecifics;
            } else {
                payload.equipmentSpecifics = [];
            }

            if (isStringNotEmpty(data.customerLaneId)) {
                payload.customerLaneId = data.customerLaneId;
            } else {
                payload.customerLaneId = null;
            }

            if (isNumberNotEmpty(data.perMileRate)) {
                payload.perMileRate = Number(data.perMileRate);
                if (isStringNotEmpty(data.perMileRateUnit)) {
                    payload.perMileRateUnit = data.perMileRateUnit;
                } else {
                    payload.perMileRateUnit = 'USD';
                }
            } else {
                payload.perMileRate = null;
                payload.perMileRateUnit = null;
            }

            if (isNumberNotEmpty(data.contractRate)) {
                payload.contractRate = Number(data.contractRate);
                if (isStringNotEmpty(data.contractRateUnit)) {
                    payload.contractRateUnit = data.contractRateUnit;
                } else {
                    payload.contractRateUnit = 'USD';
                }
            } else {
                payload.contractRate = null;
                payload.contractRateUnit = null;
            }

            payload.accountId = linkedLoadLane.accountId;
            payload.linkedAccountId = linkedLoadLane.linkedAccountId;

            // console.log(payload);
            if (isObjectNotEmpty(payload)) {
                dispatch(actionCreators.updateLinkedLoadLane(linkedLoadLane.id, payload));
            } else {
                onCancel();
            }
        } else {
            if (isListEmpty(pickUpStops)) {
                methods.setError('pickUpStops', { type: "required", message: 'There must be at least 1 Pick-Up Stop' });
            }
            if (isListEmpty(dropOffStops)) {
                methods.setError('dropOffStops', { type: "required", message: 'There must be at least 1 Drop-Off Stop' });
            }

            return;
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.updateLinkedLoadLaneCancel());
        cancel();
    };

    //#endregion
    //#region useEffects and useMemos

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLinkedLoadLaneErrorClear());
        dispatch(actionCreators.getCommodities());
        dispatch(actionCreators.getLineItemTypes());
        dispatch(actionCreators.getTrailerTypes());
    }, []);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (showEditStopLocation === true && isObjectNotEmpty(selectedRecord)) {
                if (selectedRecord.loadingType !== undefined && selectedRecord.loadingType !== null) {
                    methods2.setValue('loadingType', selectedRecord.loadingType);
                }
            }
        }

        return () => isSubscribed = false;
    }, [showEditStopLocation, selectedRecord]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (isObjectNotEmpty(linkedLoadLane.equipmentNeeds) && isListNotEmpty(lineItemTypes) && isListNotEmpty(trailerTypes)) {
                if (isStringNotEmpty(linkedLoadLane.equipmentNeeds.trailerTypeId)) {
                    let equipmentSpecificOptionsList = [];
                    let driverRequirementOptionsList = [];
                    let equipmentSpecifics = lineItemTypes.filter(i => i.trailerTypeIds.includes(linkedLoadLane.equipmentNeeds.trailerTypeId) && i.itemType === 'EQUIPMENT_SPECIFIC');
                    let driverRequirements = lineItemTypes.filter(i => i.trailerTypeIds.includes(linkedLoadLane.equipmentNeeds.trailerTypeId) && i.itemType === 'LOAD_REQUIREMENT');

                    for (let i = 0; i < equipmentSpecifics.length; i++) {
                        equipmentSpecificOptionsList.push({ value: equipmentSpecifics[i].name, key: equipmentSpecifics[i].name, label: equipmentSpecifics[i].description });
                    }
                    for (let i = 0; i < driverRequirements.length; i++) {
                        driverRequirementOptionsList.push({ value: driverRequirements[i].name, key: driverRequirements[i].name, label: driverRequirements[i].description });
                    }
                    setEquipmentSpecificOptions(equipmentSpecificOptionsList);
                    setDriverRequirementOptions(driverRequirementOptionsList);

                    let selectedTrailerType = trailerTypes.find(s => s.id === linkedLoadLane.equipmentNeeds.trailerTypeId);
                    if (isObjectNotEmpty(selectedTrailerType)) {
                        if (selectedTrailerType.name === "REEFER") {
                            setShowReeferOptions(true);
                            if (linkedLoadLane.equipmentNeeds.preCoolReefer) {
                                preCoolReefer.current = linkedLoadLane.equipmentNeeds.preCoolReefer;
                                methods.setValue('equipmentNeeds.preCoolReefer', linkedLoadLane.equipmentNeeds.preCoolReefer);
                            }
                            if (linkedLoadLane.equipmentNeeds.minRunTemp) {
                                minRunTemp.current = linkedLoadLane.equipmentNeeds.minRunTemp;
                                methods.setValue('equipmentNeeds.minRunTemp', linkedLoadLane.equipmentNeeds.minRunTemp);
                            }
                            if (linkedLoadLane.equipmentNeeds.trailerLength) {
                                maxRunTemp.current = linkedLoadLane.equipmentNeeds.maxRunTemp;
                                methods.setValue('equipmentNeeds.maxRunTemp', linkedLoadLane.equipmentNeeds.maxRunTemp);
                            }
                        } else {
                            setShowReeferOptions(false);
                            methods.setValue('equipmentNeeds.minRunTemp', null);
                            methods.setValue('equipmentNeeds.maxRunTemp', null);
                            methods.setValue('equipmentNeeds.preCoolReefer', null);
                            preCoolReefer.current = null;
                            minRunTemp.current = null;
                            maxRunTemp.current = null;
                        }
                    }
                } else {
                    setEquipmentSpecificOptions([]);
                    setDriverRequirementOptions([]);
                    setShowReeferOptions(false);
                    methods.setValue('equipmentNeeds.minRunTemp', null);
                    methods.setValue('equipmentNeeds.maxRunTemp', null);
                    methods.setValue('equipmentNeeds.preCoolReefer', null);
                    preCoolReefer.current = null;
                    minRunTemp.current = null;
                    maxRunTemp.current = null;
                }
            }
        }

        return () => isSubscribed = false;
    }, [linkedLoadLane.equipmentNeeds, lineItemTypes, trailerTypes]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (showReeferOptions === true && isObjectNotEmpty(linkedLoadLane.equipmentNeeds)) {
                if (linkedLoadLane.equipmentNeeds.preCoolReefer) {
                    preCoolReefer.current = linkedLoadLane.equipmentNeeds.preCoolReefer;
                    methods.setValue('equipmentNeeds.preCoolReefer', linkedLoadLane.equipmentNeeds.preCoolReefer);
                }
                if (linkedLoadLane.equipmentNeeds.minRunTemp) {
                    minRunTemp.current = linkedLoadLane.equipmentNeeds.minRunTemp;
                    methods.setValue('equipmentNeeds.minRunTemp', linkedLoadLane.equipmentNeeds.minRunTemp);
                }
                if (linkedLoadLane.equipmentNeeds.trailerLength) {
                    maxRunTemp.current = linkedLoadLane.equipmentNeeds.maxRunTemp;
                    methods.setValue('equipmentNeeds.maxRunTemp', linkedLoadLane.equipmentNeeds.maxRunTemp);
                }
            }
        }

        return () => isSubscribed = false;
    }, [showReeferOptions, linkedLoadLane.equipmentNeeds]);

    useMemo(() => {
        //console.log(linkedLoadLane)
        if (isObjectNotEmpty(linkedLoadLane) && isListNotEmpty(linkedLoadLane.locations)) {
            let pickUpStopsList = [];
            let dropOffStopsList = [];
            linkedLoadLane.locations.forEach((stop, stopIndex) => {
                if (stop.stopType === 'PICK_UP') {
                    pickUpStopsList.push(stop);
                } else if (stop.stopType === 'DROP_OFF') {
                    dropOffStopsList.push(stop);
                }
            });
            setPickUpStops(pickUpStopsList);
            setDropOffStops(dropOffStopsList);
        }
    }, [linkedLoadLane]);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    //#endregion
    //#region displays

    const getCommodityOptions = (stop) => {
        let options = [];
        if (isObjectNotEmpty(stop) && isListNotEmpty(commodities)) {
            let availableCommodities = [];
            // filter the commodities by what is set on the location if any are set
            if (isObjectNotEmpty(stop.location) && isListNotEmpty(stop.location.commodityIds)) {
                let availableStopCommodities = commodities.filter(c => c.isDeleted === false && stop.location.commodityIds.includes(c.id));
                if (isListNotEmpty(availableStopCommodities)) {
                    availableCommodities = availableStopCommodities;
                } else {
                    availableCommodities = commodities;
                }
            } else {
                availableCommodities = commodities;
            }
            if (stop.stopType === 'PICK_UP') {
                // if pick-up, don't show commodities that have already been added to this stop
                let usedCommodityIds = [];
                if (isListNotEmpty(stop.commodities)) {
                    stop.commodities.forEach((stopCommodity) => {
                        usedCommodityIds.push(stopCommodity.commodityId);
                    });
                }

                availableCommodities.forEach((commodity) => {
                    if (!usedCommodityIds.includes(commodity.id)) {
                        options.push(<Option value={commodity.id} key={commodity.id}>{commodity.name}</Option>);
                    }
                });
            } else if (stop.stopType === 'DROP_OFF') {
                // only show commodities that the pick-up stops have
                let usedCommodityIds = [];
                pickUpStops.forEach((pickUpStop) => {
                    if (pickUpStop.commodities !== undefined && pickUpStop.commodities !== null && pickUpStop.commodities.length > 0) {
                        pickUpStop.commodities.forEach((stopCommodity) => {
                            if (!usedCommodityIds.includes(stopCommodity.commodityId)) {
                                usedCommodityIds.push(stopCommodity.commodityId);
                            }
                        });
                    }
                });

                availableCommodities.forEach((commodity) => {
                    if (usedCommodityIds.includes(commodity.id)) {
                        options.push(<Option value={commodity.id} key={commodity.id}>{commodity.name}</Option>);
                    }
                });
            }
        }

        return options;
    };

    const getLaneStopLocationDisplay = (stop, stopIndex) => {
        if (isObjectNotEmpty(stop)) {
            // console.log(stop);
            const stopName = LaneUtils.getLaneStopLocationNameDisplay(stop, linkedLoadLane.linkedAccountId, linkedLoadLane.accountId, entityType, entityId);
            const stopAddress = LaneUtils.getLaneStopAddressDisplay(stop);

            return (
                <DataCollapsableRow
                    isCollapsed={isStringNotEmpty(stop.locationId) ? false : true}
                    title={isStringNotEmpty(stopName) ? stopName : 'N/A'}
                    titleStyle={{ paddingLeft: 0, fontSize: 40, color: '#000000' }}
                    titleHasAction={false}
                    subtitle={isStringNotEmpty(stopAddress) ? (<span style={{ fontSize: 14 }}>{stopAddress}</span>) : null}
                    header={isStringNotEmpty(stop.stopType) ? (
                        <span style={{ fontSize: 14, textTransform: 'uppercase', color: '#A9A9A9' }}>{`${LaneUtils.getLaneStopTypeDisplay(stop)} ${stopIndex + 1}`}</span>
                    ) : null}
                >
                    <div style={{ marginTop: 24 }}>
                        {isStringNotEmpty(stop.loadingType) ? (
                            <Row gutter={[4, 4]} style={{ marginBottom: 24 }}>
                                <Col span={24}>
                                    <div><span style={{ fontSize: 14, color: '#000000' }}>{stop.stopType === 'PICK_UP' ? 'Loading Type' : 'Unloading Type'}</span><Button style={{ fontWeight: 'bold' }} type="link" onClick={() => { setSelectedRecord(stop); toggleEditStopLocation(); }}>edit</Button></div>
                                    <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>{stringFormatter.toFormattedString(stop.stopType === 'PICK_UP' ? 'LoadingType' : 'UnloadingType', stop.loadingType, null)}</span></div>
                                </Col>
                            </Row>
                        ) : (
                            <Row gutter={[4, 4]} style={{ marginBottom: 24 }}>
                                <Col span={24}>
                                    <Button style={{ marginLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} onClick={() => { setSelectedRecord(stop); toggleEditStopLocation(); }}>{stop.stopType === 'PICK_UP' ? 'Loading Specifics (optional)' : (stop.stopType === 'DROP_OFF' ? 'Unloading Specifics (optional)' : 'Loading/Unloading Specifics (optional)')}</Button>
                                </Col>
                            </Row>
                        )}
                        {isListNotEmpty(stop.commodities) ? (
                            <>
                                <Row gutter={[4, 4]} style={{ marginBottom: 24 }}>
                                    <Col span={24}>
                                        <div><span style={{ fontSize: 14, color: '#000000' }}>Commodities</span></div>
                                        {stop.commodities.map((stopCommodity, index) => {
                                            //console.log(stopCommodity);

                                            return (
                                                <Row gutter={[4, 4]} style={{ marginTop: 12, marginBottom: 12 }} key={`stop-${stopIndex}-stopCommodity-${index}`}>
                                                    <Col span={18}>
                                                        <span style={{ fontSize: 14, color: '#A9A9A9' }}>{stopCommodity.name}: {isNumberNotEmpty(stopCommodity.unitPackagingInitCount) ? `${stopCommodity.unitPackagingInitCount} ${Enums.UnitPackaging.getValueByName(stopCommodity.unitPackaging)}` : ''}{isNumberNotEmpty(stopCommodity.bulkPackagingInitCount) ? `, ${stopCommodity.bulkPackagingInitCount} ${Enums.BulkPackaging.getValueByName(stopCommodity.bulkPackaging)}` : ''}</span>
                                                    </Col>
                                                    <Col span={6}>
                                                        {((stop.stopType === 'PICK_UP') || (dropOffStops.length > 1)) ? (
                                                            <Button type="default" icon={<DeleteOutlined />} onClick={() => { onRemoveStopCommodity(stop, index); }} />
                                                        ) : null}
                                                    </Col>
                                                </Row>
                                            );
                                        })}
                                    </Col>
                                </Row>

                                {((stop.stopType === 'PICK_UP') || (dropOffStops.length > 1)) ? (
                                    <Row gutter={[4, 4]} style={{ marginBottom: 24 }}>
                                        <Col span={24}>
                                            <Button style={{ marginLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} onClick={() => { setSelectedRecord(stop); toggleAddStopCommodity(); }}>Additional Default Commodity (optional)</Button>
                                        </Col>
                                    </Row>
                                ) : null}
                            </>
                        ) : (((stop.stopType === 'PICK_UP') || (dropOffStops.length > 1)) ? (
                            <Row gutter={[4, 4]} style={{ marginBottom: 24 }}>
                                <Col span={24}>
                                    <Button style={{ marginLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} onClick={() => { setSelectedRecord(stop); toggleAddStopCommodity(); }}>Default Commodity (optional)</Button>
                                </Col>
                            </Row>
                        ) : null)}
                    </div>
                </DataCollapsableRow>
            );
        } else {
            return null;
        }
    };

    const getStopDetails = (stop) => {
        if (isObjectNotEmpty(stop)) {
            const stopIcon = LaneUtils.getLaneStopMarkerIcon(stop);

            return (
                <div>
                    <Row style={{ marginTop: 36, marginBottom: 36 }}>
                        <Col span={24}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: 30, marginRight: 12 }}>
                                    {isNotNullOrUndefined(stopIcon) ? (<img src={stopIcon} alt={LaneUtils.getLaneStopTypeDisplay(stop)} style={{ width: 30, height: 30 }} />) : null}
                                </div>
                                <div style={{ flex: 1 }}>
                                    <span style={{ fontWeight: 'bold' }}>{LaneUtils.getLaneStopLocationNameDisplay(stop, linkedLoadLane.linkedAccountId, linkedLoadLane.accountId, entityType, entityId)}</span><br />
                                    <span>{LaneUtils.getLaneStopLocationCityStateDisplay(stop)}</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            );
        } else {
            return null;
        }
    };

    const getError = (errors, name) => {
        if (name !== undefined) {
            let obj = errors;

            name = name.split('.');
            let len = name.length;
            for (let i = 0; i < len - 1; i++) {
                if (obj[name[i]] !== undefined) {
                    //console.log(obj[name[i]]);
                    obj = obj[name[i]];
                }
            }

            return obj[name[len - 1]];
        } else {
            return undefined;
        }
    };

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const formItemLayoutDouble = {
        labelCol1: { span: 24 },
        wrapperCol1: { span: 12 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 12 },
    };

    const formItemLayoutDouble2 = {
        labelCol1: { span: 12 },
        wrapperCol1: { span: 12 },
        labelCol2: { span: 12 },
        wrapperCol2: { span: 12 },
    };

    const radioStyle = {
        display: 'block',
        height: '60px',
        lineHeight: '30px',
    };

    //#endregion

    return (
        <>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true && error === null)}>
                        <Fieldset legend="Route">
                            <Timeline style={{ padding: '10px 24px' }}>
                                {isListNotEmpty(pickUpStops) ? (
                                    <>
                                        {pickUpStops.map((stop, index, arr) => {
                                            return (
                                                <Timeline.Item className="customTimelineItem" key={`pickUp-${index}`} color={'#23BEBB'}>
                                                    <div style={{ paddingLeft: 50 }}>
                                                        {getLaneStopLocationDisplay(stop, index)}
                                                        {getError(methods.errors, `pickUpStops-${index}`) !== undefined && (
                                                            <ErrorMessage
                                                                errors={methods.errors}
                                                                name={`pickUpStops-${index}`}
                                                                render={({ message, messages }) => {
                                                                    if (isNotNullOrUndefined(messages)) {
                                                                        return Object.entries(messages).map(([type, message]) => (<Alert key={`pickUpStops-${index}-${type}`} message={message} type="error" />));
                                                                    } else if (isStringNotEmpty(message)) {
                                                                        return <Alert message={message} type="error" />;
                                                                    } else {
                                                                        return null;
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </Timeline.Item>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <Timeline.Item className="customTimelineItem" key={`pickUp`} color={'#23BEBB'}>
                                        <div style={{ paddingLeft: 50 }}>
                                            {getError(methods.errors, `pickUpStops`) !== undefined && (
                                                <ErrorMessage
                                                    errors={methods.errors}
                                                    name={`pickUpStops`}
                                                    render={({ message, messages }) => {
                                                        if (isNotNullOrUndefined(messages)) {
                                                            return Object.entries(messages).map(([type, message]) => (<Alert key={`pickUpStops-${type}`} message={message} type="error" />));
                                                        } else if (isStringNotEmpty(message)) {
                                                            return <Alert message={message} type="error" />;
                                                        } else {
                                                            return null;
                                                        }
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </Timeline.Item>
                                )}
                                {isListNotEmpty(dropOffStops) ? (
                                    <>
                                        {dropOffStops.map((stop, index, arr) => {
                                            return (
                                                <Timeline.Item className="customTimelineItem" key={`dropOff-${index}`} color={'#23BEBB'}>
                                                    <div style={{ paddingLeft: 50 }}>
                                                        {getLaneStopLocationDisplay(stop, index)}
                                                        {getError(methods.errors, `dropOffStops-${index}`) !== undefined && (
                                                            <ErrorMessage
                                                                errors={methods.errors}
                                                                name={`dropOffStops-${index}`}
                                                                render={({ message, messages }) => {
                                                                    if (isNotNullOrUndefined(messages)) {
                                                                        return Object.entries(messages).map(([type, message]) => (<Alert key={`dropOffStops-${index}-${type}`} message={message} type="error" />));
                                                                    } else if (isStringNotEmpty(message)) {
                                                                        return <Alert message={message} type="error" />;
                                                                    } else {
                                                                        return null;
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </Timeline.Item>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <Timeline.Item className="customTimelineItem" key={`dropOff`} color={'#23BEBB'}>
                                        <div style={{ paddingLeft: 50 }}>
                                            {getError(methods.errors, `dropOffStops`) !== undefined && (
                                                <ErrorMessage
                                                    errors={methods.errors}
                                                    name={`dropOffStops`}
                                                    render={({ message, messages }) => {
                                                        if (isNotNullOrUndefined(messages)) {
                                                            return Object.entries(messages).map(([type, message]) => (<Alert key={`dropOffStops-${type}`} message={message} type="error" />));
                                                        } else if (isStringNotEmpty(message)) {
                                                            return <Alert message={message} type="error" />;
                                                        } else {
                                                            return null;
                                                        }
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </Timeline.Item>
                                )}
                            </Timeline>
                        </Fieldset>
                        <Fieldset legend="Lane Details (* indicates a required field)">
                            <FormItem {...formItemLayout} label="Customer Lane Id"
                                render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Customer Lane Id" ref={ref} />}
                                rules={{ required: false }}
                                name="customerLaneId"
                                defaultValue={linkedLoadLane.customerLaneId}
                            />
                            <FormItemDouble {...formItemLayoutDouble} label1="Contract Rate"
                                render1={({ onChange, onBlur, value, name, ref }) => (
                                    <InputNumber
                                        min={0}
                                        precision={2}
                                        placeholder="Contract Rate"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    />
                                )}
                                rules1={{ required: false }}
                                name1="contractRate"
                                defaultValue1={linkedLoadLane.contractRate}
                                render2={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please Select"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    >
                                        {currencyOptions}
                                    </Select>
                                )}
                                rules2={{ required: false }}
                                name2="contractRateUnit"
                                defaultValue2={linkedLoadLane.contractRateUnit}
                            />
                            <FormItemDouble {...formItemLayoutDouble} label1="Per Mile Rate"
                                render1={({ onChange, onBlur, value, name, ref }) => (
                                    <InputNumber
                                        min={0}
                                        precision={2}
                                        placeholder="Per Mile Rate"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    />
                                )}
                                rules1={{ required: false }}
                                name1="perMileRate"
                                defaultValue1={linkedLoadLane.perMileRate}
                                render2={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please Select"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    >
                                        {currencyOptions}
                                    </Select>
                                )}
                                rules2={{ required: false }}
                                defaultValue2={linkedLoadLane.perMileRateUnit}
                                name2="perMileRateUnit"
                            />
                        </Fieldset>
                        <Fieldset legend="Equipment Needs and Services (* indicates a required field)">
                            <Row gutter={[4, 4]}>
                                <Col span={8}>
                                    <div><span style={{ fontSize: 14, color: '#000000' }}>Trailer Type</span></div>
                                    <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>{linkedLoadLane.equipmentNeeds.trailerType.description}</span></div>
                                </Col>
                                <Col span={8}>
                                    <div><span style={{ fontSize: 14, color: '#000000' }}>Trailer Length</span></div>
                                    <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>{isObjectNotEmpty(linkedLoadLane.equipmentNeeds) && isNumberNotEmpty(linkedLoadLane.equipmentNeeds.trailerLength) && isStringNotEmpty(linkedLoadLane.equipmentNeeds.trailerLengthUnit) ? stringFormatter.toFormattedString('Length', linkedLoadLane.equipmentNeeds.trailerLength, linkedLoadLane.equipmentNeeds.trailerLengthUnit) : null}</span></div>
                                </Col>
                                <Col span={8}>
                                    <FormItem {...formItemLayout} label="Load Weight" required format="vertical"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <InputNumber
                                                step={1000}
                                                min={1000}
                                                precision={0}
                                                placeholder="Load Weight (lbs)"
                                                style={{ width: '100%' }}
                                                onBlur={onBlur}
                                                onChange={e => { onChange(e); }}
                                                value={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        rules={{ required: 'Required Field' }}
                                        name='equipmentNeeds.trailerWeightCapacity'
                                        defaultValue={isObjectNotEmpty(linkedLoadLane.equipmentNeeds) && isNumberNotEmpty(linkedLoadLane.equipmentNeeds.trailerWeightCapacity) ? linkedLoadLane.equipmentNeeds.trailerWeightCapacity : null}
                                    />
                                </Col>
                                {showReeferOptions === true ? (
                                    <>
                                        <Col span={24}>
                                            <span style={{ fontWeight: 'bold' }}>Temperature Requirements</span>
                                        </Col>
                                        <Col span={8}>
                                            <FormItem {...formItemLayout} label="Pre-Cool Temp" required format="vertical"
                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                    <InputNumber
                                                        placeholder="Pre-Cool Temp"
                                                        style={{ width: '100%' }}
                                                        onBlur={onBlur}
                                                        onChange={e => { preCoolReefer.current = e; onChange(e); }}
                                                        value={value}
                                                        name={name}
                                                        ref={ref}
                                                    />
                                                )}
                                                rules={{
                                                    required: 'Required Field',
                                                    validate: {
                                                        gteMin: value => (minRunTemp.current ? value >= minRunTemp.current : true) || 'Pre-Cool Temp must be greater than or equal to the Min Run Temp.',
                                                        lteMax: value => (maxRunTemp.current ? value <= maxRunTemp.current : true) || 'Pre-Cool Temp must be less than or equal to Max Run Temp.'
                                                    }
                                                }}
                                                name='equipmentNeeds.preCoolReefer'
                                                defaultValue={isObjectNotEmpty(linkedLoadLane.equipmentNeeds) && isNumberNotEmpty(linkedLoadLane.equipmentNeeds.preCoolReefer) ? linkedLoadLane.equipmentNeeds.preCoolReefer : null}
                                            />
                                        </Col>
                                        <Col span={8}>
                                            <FormItem {...formItemLayout} label="Min Run Temp" required format="vertical"
                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                    <InputNumber
                                                        placeholder="Min Run Temp"
                                                        style={{ width: '100%' }}
                                                        onBlur={onBlur}
                                                        onChange={e => { minRunTemp.current = e; onChange(e); }}
                                                        value={value}
                                                        name={name}
                                                        ref={ref}
                                                    />
                                                )}
                                                rules={{
                                                    required: 'Required Field',
                                                    validate: {
                                                        lte: value => (preCoolReefer.current && maxRunTemp.current ? value <= preCoolReefer.current && value <= maxRunTemp.current : true) || 'Min Run Temp must be less than or equal to the Pre-Cool Temp and Max Run Temp'
                                                    }
                                                }}
                                                name='equipmentNeeds.minRunTemp'
                                                defaultValue={isObjectNotEmpty(linkedLoadLane.equipmentNeeds) && isNumberNotEmpty(linkedLoadLane.equipmentNeeds.minRunTemp) ? linkedLoadLane.equipmentNeeds.minRunTemp : null}
                                            />
                                        </Col>
                                        <Col span={8}>
                                            <FormItem {...formItemLayout} label="Max Run Temp" required format="vertical"
                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                    <InputNumber
                                                        placeholder="Max Run Temp"
                                                        style={{ width: '100%' }}
                                                        onBlur={onBlur}
                                                        onChange={e => { maxRunTemp.current = e; onChange(e); }}
                                                        value={value}
                                                        name={name}
                                                        ref={ref}
                                                    />
                                                )}
                                                rules={{
                                                    required: 'Required Field',
                                                    validate: {
                                                        gte: value => (preCoolReefer.current && minRunTemp.current ? value >= preCoolReefer.current && value >= minRunTemp.current : true) || 'Max Run Temp must be greater than or equal to the Pre-Cool Temp and Min Run Temp'
                                                    }
                                                }}
                                                name='equipmentNeeds.maxRunTemp'
                                                defaultValue={isObjectNotEmpty(linkedLoadLane.equipmentNeeds) && isNumberNotEmpty(linkedLoadLane.equipmentNeeds.maxRunTemp) ? linkedLoadLane.equipmentNeeds.maxRunTemp : null}
                                            />
                                        </Col>
                                    </>
                                ) : null}
                                <Col span={24}>
                                    <FormItem {...formItemLayout} label="Additional Equipment and Services" format="vertical"
                                        render={({ onChange, onBlur, value, name, ref }) => {
                                            if (isObjectNotEmpty(linkedLoadLane.equipmentNeeds) && isStringNotEmpty(linkedLoadLane.equipmentNeeds.trailerTypeId)) {
                                                if (isListNotEmpty(equipmentSpecificOptions)) {
                                                    return (
                                                        <Checkbox.Group
                                                            onBlur={onBlur}
                                                            options={equipmentSpecificOptions}
                                                            onChange={(checkedValues) => { onChange(checkedValues); }}
                                                            value={value}
                                                            name={name}
                                                            ref={ref}
                                                        />
                                                    );
                                                } else {
                                                    return (
                                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but there are no options available for the selected trailer type." />
                                                    );
                                                }
                                            } else {
                                                return (
                                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="You must first select a trailer type to see available options." />
                                                );
                                            }
                                        }}
                                        rules={{ required: false }}
                                        name='equipmentSpecifics'
                                        defaultValue={linkedLoadLane.equipmentSpecifics}
                                    />
                                </Col>
                                <Col span={24}>
                                    <FormItem {...formItemLayout} label="Driver Requirements" format="vertical"
                                        render={({ onChange, onBlur, value, name, ref }) => {
                                            if (isObjectNotEmpty(linkedLoadLane.equipmentNeeds) && isStringNotEmpty(linkedLoadLane.equipmentNeeds.trailerTypeId)) {
                                                if (isListNotEmpty(driverRequirementOptions)) {
                                                    return (
                                                        <Checkbox.Group
                                                            onBlur={onBlur}
                                                            options={driverRequirementOptions}
                                                            onChange={(checkedValues) => { onChange(checkedValues); }}
                                                            value={value}
                                                            name={name}
                                                            ref={ref}
                                                        />
                                                    );
                                                } else {
                                                    return (
                                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but there are no options available for the selected trailer type." />
                                                    );
                                                }
                                            } else {
                                                return (
                                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="You must first select a trailer type to see available options." />
                                                );
                                            }
                                        }}
                                        rules={{ required: false }}
                                        name='driverRequirements'
                                        defaultValue={linkedLoadLane.driverRequirements}
                                    />
                                </Col>
                            </Row>
                        </Fieldset>

                        {error && <Alert message={`${error}`} type="error" />}
                    </Spin>
                    <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Update Lane" />
                </Form>
            </FormProvider>
            <Modal
                title={isObjectNotEmpty(selectedRecord) ? (selectedRecord.stopType === "PICK_UP" ? "Loading Details" : "Unloading Details") : 'Loading/Unloading Details'}
                visible={showEditStopLocation === true}
                width="60%"
                style={{ top: 0 }}
                onCancel={(e) => { toggleEditStopLocation(); setSelectedRecord(null); }}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                maskClosable={false}
                forceRender={true}
            >
                <FormProvider {...methods2}>
                    <Form onSubmit={methods2.handleSubmit(onEditStopLocation)}>
                        <Fieldset legend="Default Loading/Unloading Requirements">
                            <div>How will the trailer be loaded/unloaded?</div>
                            <FormItem {...formItemLayout} format="vertical"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Radio.Group onBlur={onBlur} onChange={e => onChange(e.target.value)} value={value} name={name} ref={ref}>
                                        <Radio style={radioStyle} key="LIVE" value="LIVE">
                                            <span style={{ fontWeight: 'bold', fontSize: 16 }}>Live Load/Unload Trailer</span><br /><span style={{ fontSize: 14, paddingLeft: 24 }}>Trailer will be loaded/unloaded upon arrival.</span>
                                        </Radio>
                                        <Radio style={radioStyle} key="DROP" value="DROP">
                                            <span style={{ fontWeight: 'bold', fontSize: 16 }}>Drop Trailer</span><br /><span style={{ fontSize: 14, paddingLeft: 24 }}>Drop and hook.</span>
                                        </Radio>
                                    </Radio.Group>
                                )}
                                rules={{ required: false }}
                                name="loadingType"
                            />
                        </Fieldset>
                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                borderTop: '1px solid #e9e9e9',
                                padding: '10px 0px',
                                background: '#fff',
                                textAlign: 'right',
                            }}
                        >
                            <Button onClick={() => { toggleEditStopLocation(); setSelectedRecord(null); }} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary" htmlType="submit">Update {selectedRecord ? (selectedRecord.stopType === "PICK_UP" ? "Loading Details" : "Unloading Details") : 'Loading/Unloading Details'}</Button>
                        </div>
                    </Form>
                </FormProvider>
            </Modal>
            <Modal
                title={isObjectNotEmpty(selectedRecord) ? (selectedRecord.stopType === "PICK_UP" ? "Pick-Up Commodity" : "Drop-Off Commodity") : 'Stop Commodity'}
                visible={showAddStopCommodity === true}
                width="60%"
                style={{ top: 0 }}
                onCancel={(e) => { toggleAddStopCommodity(); setSelectedRecord(null); }}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                maskClosable={false}
                forceRender={true}
            >
                <FormProvider {...methods3}>
                    <Form onSubmit={methods3.handleSubmit(onAddStopCommodity)}>
                        <Fieldset legend={selectedRecord ? (selectedRecord.stopType === "PICK_UP" ? "Pick-Up Location" : "Drop-Off Location") : 'Stop Location'}>
                            {getStopDetails(selectedRecord)}
                        </Fieldset>
                        <Fieldset legend="Commodity Details">
                            <FormItem {...formItemLayout} label="Name" required format="vertical"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please Search and Select a Commodity to Add"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        showSearch={true}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        ref={ref}
                                    >
                                        {getCommodityOptions(selectedRecord ? selectedRecord : null)}
                                    </Select>
                                )}
                                rules={{ required: 'Required Field' }}
                                name="commodityId"
                            />
                            <FormItemDouble {...formItemLayoutDouble2} label1="Unit Quantity" label2="Unit of Measure/Unit Packaging" required1 required2 format="vertical"
                                render1={({ onChange, onBlur, value, name, ref }) => (
                                    <InputNumber
                                        precision={0}
                                        min={0}
                                        placeholder="Unit Quantity"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    />
                                )}
                                rules1={{ required: 'Required Field' }}
                                name1="unitPackagingInitCount"
                                render2={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please Select a Unit of Measure/Unit Packaging"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        showSearch={true}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        ref={ref}
                                    >
                                        {unitPackagingOptions}
                                    </Select>
                                )}
                                rules2={{ required: 'Required Field' }}
                                name2="unitPackaging"
                            />
                            <FormItemDouble {...formItemLayoutDouble2} label1="Bulk Quantity" label2="Bulk Packaging" format="vertical"
                                render1={({ onChange, onBlur, value, name, ref }) => (
                                    <InputNumber
                                        precision={0}
                                        min={0}
                                        placeholder="Bulk Quantity"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    />
                                )}
                                rules1={{ required: false }}
                                name1="bulkPackagingInitCount"
                                render2={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please Select a Bulk Packaging"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        showSearch={true}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        ref={ref}
                                    >
                                        {bulkPackagingOptions}
                                    </Select>
                                )}
                                rules2={{ required: false }}
                                name2="bulkPackaging"
                            />
                        </Fieldset>
                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                borderTop: '1px solid #e9e9e9',
                                padding: '10px 0px',
                                background: '#fff',
                                textAlign: 'right',
                            }}
                        >
                            <Button onClick={() => { toggleAddStopCommodity(); setSelectedRecord(null); }} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary" htmlType="submit">Add Commodity</Button>
                        </div>
                    </Form>
                </FormProvider>
            </Modal>
        </>
    );
};

export default EditLinkedLoadLane;