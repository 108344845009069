import * as actionTypes from "../actions/actionTypes";
import {
    fetchRecordsStart,
    fetchRecordsSuccess,
    fetchRecordsFail,
    updateObject
} from "../utility";

const clearKeys = (state) => {
    return updateObject(state, {
        pubNubSubKey: null,
        pubNubPubKey: null,
        trimbleAPIKey: null,
        isLoading: false,
        error: null
    });
};

const initialState = {
    pubNubSubKey: null,
    pubNubPubKey: null,
    trimbleAPIKey: null,
    isLoading: false,
    error: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_KEYS_START: return fetchRecordsStart(state);
        case actionTypes.FETCH_KEYS_SUCCESS: return fetchRecordsSuccess(state, action.payload);
        case actionTypes.FETCH_KEYS_FAIL: return fetchRecordsFail(state, action.payload);
        case actionTypes.CLEAR_KEYS: return clearKeys(state);
        default:
            return state;
    }
};

export default reducer;