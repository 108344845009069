import React, { useMemo, useState } from 'react';
import { Collapse, Drawer, Col, Row, Button } from 'antd';
import NewLoadStop from '../../components/NewLoadStop/NewLoadStop';
import EditLoadStop from '../../components/EditLoadStop/EditLoadStop';
import RemoveLoadStop from '../../components/RemoveLoadStop/RemoveLoadStop';
import AddEditChangeRemoveButtons from '../../components/AddEditChangeRemoveButtons/AddEditChangeRemoveButtons';
import Enums from '../../shared/enums';
import { PlusOutlined } from '@ant-design/icons';
import LoadStop from '../../components/LoadStop/LoadStop';
import { isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { useSelector } from 'react-redux';
import LoadStopUtils from '../../api/utils/loadStopUtils';
import LoadUtils from '../../api/utils/loadUtils';

const { Panel } = Collapse;

const LoadStops = ({ load, shipperId, carrierId, stops, currentStatus }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    const singularEntityName = "Stop";

    //#endregion
    //#region useSelectors

    const entityType = useSelector(state => state.auth.entityType);
    const entityId = useSelector(state => state.auth.entityId);

    //#endregion
    //#region useStates

    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showRemoveEntity, setShowRemoveEntity] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({});
    const [pickUpStops, setPickUpStops] = useState([]);
    const [dropOffStops, setDropOffStops] = useState([]);
    const [newStopProps, setNewStopProps] = useState({});
    const [loadStops, setLoadStops] = useState([]);

    //#endregion
    //#region toggles

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleRemoveEntity = () => {
        setShowRemoveEntity(!showRemoveEntity);
    };

    //#endregion
    //#region displays

    const newEntityComponents = (
        <Drawer
            title={"Add New Stop to Load"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={(e) => { toggleNewEntity(); }}
            visible={showNewEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewLoadStop
                cancel={toggleNewEntity}
                load={load}
                stops={stops}
                shipperId={shipperId}
                carrierId={carrierId}
                stopType={newStopProps && newStopProps.stopType ? newStopProps.stopType : null}
                sequence={newStopProps && newStopProps.sequence ? newStopProps.sequence : null}
                stopTypeSequence={newStopProps && newStopProps.stopTypeSequence ? newStopProps.stopTypeSequence : null}
            />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit Stop Details"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditEntity}
            visible={showEditEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditLoadStop
                cancel={toggleEditEntity}
                load={load}
                stop={selectedRecord}
                shipperId={shipperId}
                carrierId={carrierId}
                stops={stops}
                stopType={selectedRecord && selectedRecord.stopType ? selectedRecord.stopType : null}
                sequence={selectedRecord && selectedRecord.sequence ? selectedRecord.sequence : null}
                stopTypeSequence={selectedRecord && selectedRecord.stopTypeSequence ? selectedRecord.stopTypeSequence : null}
            />
        </Drawer>
    );

    const removeEntityComponents = (
        <Drawer
            title={"Remove Stop from Load"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleRemoveEntity}
            visible={showRemoveEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <RemoveLoadStop
                cancel={toggleRemoveEntity}
                load={load}
                stop={selectedRecord}
            />
        </Drawer>
    );

    // only allow the stop to be edited if its status is Pending
    const header = (stop) => {
        if (isObjectNotEmpty(stop)) {
            const timeZone = LoadStopUtils.getStopTimeZone(stop);
            const stopName = LoadStopUtils.getStopLocationNameDisplay(stop, shipperId, carrierId, entityType, entityId);
            const stopLocation = LoadStopUtils.getStopLocationCityStateDisplay(stop);
            const stopStatus = stop.stopStatus;
            const stopNumber = (stop.stopType === 'PICK_UP' ? (pickUpStops.length > 1 ? (stop.stopTypeSequence + 1) : null) : (stop.stopType === 'DROP_OFF' ? (dropOffStops.length > 1 ? (stop.stopTypeSequence + 1) : null) : null));

            return (
                <Row gutter={[4, 4]} style={{ display: 'flex', alignItems: 'center' }}>
                    <Col md={{ span: 18 }} xs={{ span: 12 }}>
                        <span style={{ fontWeight: 500 }}>{(stop.stopType ? `${LoadStopUtils.getStopTypeDisplay(stop)}${stopNumber ? ' ' + stopNumber : ''}` : '')}</span>
                        {isStringNotEmpty(stop.apptType) ? (<span style={{ fontWeight: 500 }}> - {LoadStopUtils.getAppointmentDetailsDisplay(stop)}</span>) : null}
                        <span style={{ fontWeight: 500 }}>
                            {stopName ? " - " + stopName : ""}{stopLocation ? " - " + stopLocation : ""} - Status: {stopStatus}
                        </span>
                    </Col>
                    {(entityType === "STAFF" || (entityType === "SHIPPER" && load.createdByEntityType === "SHIPPER") || (entityType === "CARRIER" && load.createdByEntityType === "CARRIER") || (entityType === "BROKER" && load.createdByEntityType === "BROKER")) ? (
                        <Col md={{ span: 6 }} xs={{ span: 12 }} style={{ textAlign: 'right' }}>
                            <AddEditChangeRemoveButtons key={`buttons-${stop.id}`}
                                showAdd={false} addText='' addAction={null}
                                showEdit={LoadStopUtils.stopStatusIs(stop.stopStatus, ['PENDING', 'LAYOVER']) && LoadUtils.loadStatusIsNot(currentStatus, ['CANCELLED']) ? true : false} editText={"Edit " + singularEntityName + " Details"} editAction={(e) => { e.stopPropagation(); setSelectedRecord(stop); toggleEditEntity(stop); }}
                                showChange={false} changeText='' changeAction={null}
                                showRemove={(LoadStopUtils.stopStatusIs(stop.stopStatus, ['PENDING']) && LoadUtils.loadStatusIsNot(currentStatus, ['CANCELLED']) && ((stop.stopType === 'PICK_UP' && pickUpStops.length > 1) || (stop.stopType === 'DROP_OFF' && dropOffStops.length > 1))) ? true : false} removeText={"Remove " + singularEntityName + " from Load"} removeAction={(e) => { e.stopPropagation(); setSelectedRecord(stop); toggleRemoveEntity(stop); }}
                            />
                        </Col>
                    ) : null}
                </Row>
            );
        } else {
            return null;
        }
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        if (isListNotEmpty(stops)) {
            let pickUpStopsList = [];
            let pickUpIndex = 0;
            let dropOffStopsList = [];
            let dropOffIndex = 0;
            let loadStopsList = [];
            stops.forEach((stop, stopIndex, arr) => {
                if (stop.stopType === 'PICK_UP') {
                    pickUpStopsList.push(stop);
                    loadStopsList.push({ ...stop, stopTypeSequence: pickUpIndex });
                    pickUpIndex++;
                } else if (stop.stopType === 'DROP_OFF') {
                    dropOffStopsList.push(stop);
                    loadStopsList.push({ ...stop, stopTypeSequence: dropOffIndex });
                    dropOffIndex++;
                } else {
                    loadStopsList.push({ ...stop });
                }
            });
            setPickUpStops(pickUpStopsList);
            setDropOffStops(dropOffStopsList);
            setLoadStops(loadStopsList);
        }
    }, [stops]);

    //#endregion

    return (
        <>
            {isListNotEmpty(loadStops) ? loadStops.map((stop, stopIndex, arr) => {
                let beforeStopType = stop.stopType !== 'PICK_UP' && stop.stopType !== 'DROP_OFF' ? ((arr.find(i => i.stopType === 'DROP_OFF' && i.sequence < stopIndex) === undefined) ? 'PICK_UP' : 'DROP_OFF') : stop.stopType;
                let afterStopType = stop.stopType !== 'PICK_UP' && stop.stopType !== 'DROP_OFF' ? ((arr.find(i => i.stopType === 'DROP_OFF' && i.sequence < stopIndex) === undefined) ? 'PICK_UP' : 'DROP_OFF') : stop.stopType;
                return (
                    <div style={{ marginTop: 6, marginBottom: 6 }} key={`before-stop-add-new-stop-${stopIndex}`}>
                        {((stop.stopTypeSequence === 0 && stop.stopStatus === 'PENDING' && stop.stopType === 'DROP_OFF') && LoadUtils.loadStatusIsNot(currentStatus, ['COMPLETED','REOPENED','CLOSED','CANCELLED'])) ? (
                            <div style={{ marginTop: 18, marginBottom: 18 }}>
                                <Button style={{ paddingLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} onClick={() => { setNewStopProps({ stopType: 'PICK_UP', sequence: stop.sequence === 0 ? 0 : stop.sequence - 1, stopTypeSequence: pickUpStops.length }); toggleNewEntity(); }}>Add New Pick-Up</Button>
                            </div>
                        ) : null}
                        {((stop.stopTypeSequence === 0 && stop.stopStatus === 'PENDING' && (stop.stopType === 'PICK_UP' || stop.stopType === 'DROP_OFF')) && LoadUtils.loadStatusIsNot(currentStatus, ['COMPLETED','REOPENED','CLOSED','CANCELLED'])) ? (
                            <div style={{ marginTop: 18, marginBottom: 18 }}>
                                <Button style={{ paddingLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} onClick={() => { setNewStopProps({ stopType: beforeStopType, sequence: stop.sequence === 0 ? 0 : stop.sequence - 1, stopTypeSequence: stop.sequence === 0 ? 0 : stop.stopTypeSequence - 1 }); toggleNewEntity(); }}>Add New {Enums.StopTypes.getValueByName(beforeStopType)}</Button>
                            </div>
                        ) : null}
                        <Collapse key={`stop-panel-${stop.stopType}-${stop.sequence}`}>
                            <Panel header={header(stop)}>
                                <LoadStop
                                    load={load}
                                    stop={stop}
                                    showDocuments={true}
                                    showNotes={true}
                                    key={`stop-${stop.id}`}
                                />
                            </Panel>
                        </Collapse>
                        {((stop.stopStatus === 'PENDING' && (stop.stopType === 'PICK_UP' || stop.stopType === 'DROP_OFF')) && LoadUtils.loadStatusIsNot(currentStatus, ['COMPLETED','REOPENED','CLOSED','CANCELLED'])) ? (
                            <div style={{ marginTop: 18, marginBottom: 18 }} key={`after-stop-add-new-stop-${stopIndex}`}>
                                <Button style={{ paddingLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} onClick={() => { setNewStopProps({ stopType: afterStopType, sequence: stop.sequence + 1, stopTypeSequence: stop.stopTypeSequence + 1 }); toggleNewEntity(); }}>Add New {Enums.StopTypes.getValueByName(afterStopType)}</Button>
                            </div>
                        ) : null}
                    </div>
                );
            }) : null}
            {newEntityComponents}
            {editEntityComponents}
            {removeEntityComponents}
        </>
    );
};

export default LoadStops;