import * as actionTypes from "../actions/actionTypes";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { isBooleanTrue, isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import ErrorUtils from "../../shared/errorUtils";
import * as actionCreators from "./index";

//#region Fetch Document List Methods

export const fetchDocumentListStart = (listName) => {
    return {
        type: actionTypes.FETCH_DOCUMENT_LIST_START,
        payload: { listName: listName }
    }
};

export const fetchDocumentListSuccess = (listName, payload) => {
    return {
        type: actionTypes.FETCH_DOCUMENT_LIST_SUCCESS,
        payload: { listName: listName, ...payload }
    }
};

export const fetchDocumentListFail = (listName, payload) => {
    return {
        type: actionTypes.FETCH_DOCUMENT_LIST_FAIL,
        payload: { listName: listName, ...payload }
    }
};

export const clearDocumentList = (listName) => {
    return {
        type: actionTypes.CLEAR_DOCUMENT_LIST,
        payload: { listName: listName }
    }
};

export const clearDocumentLists = () => {
    return {
        type: actionTypes.CLEAR_DOCUMENT_LISTS
    }
};

export const addDocumentToDocumentList = (listName, payload) => {
    return {
        type: actionTypes.ADD_TO_DOCUMENT_LIST,
        payload: { listName: listName, newRecord: payload }
    }
};

export const updateDocumentInDocumentList = (listName, payload) => {
    return {
        type: actionTypes.UPDATE_IN_DOCUMENT_LIST,
        payload: { listName: listName, updatedRecord: payload }
    }
};

export const removeDocumentFromDocumentList = (listName, payload) => {
    return {
        type: actionTypes.REMOVE_FROM_DOCUMENT_LIST,
        payload: { listName: listName, recordToRemove: payload }
    }
};

//#endregion
//#region Add Document Methods

export const addDocumentStart = () => {
    return {
        type: actionTypes.ADD_DOCUMENT_START
    }
};

export const addDocumentSuccess = () => {
    return {
        type: actionTypes.ADD_DOCUMENT_SUCCESS
    }
};

export const addDocumentFail = (payload) => {
    return {
        type: actionTypes.ADD_DOCUMENT_FAIL,
        payload: payload
    }
};

export const addDocumentLoadingClear = () => {
    return {
        type: actionTypes.ADD_DOCUMENT_LOADING_CLEAR
    }
};

export const addDocumentErrorClear = () => {
    return {
        type: actionTypes.ADD_DOCUMENT_ERROR_CLEAR
    }
};

export const addDocumentCancel = () => {
    return {
        type: actionTypes.ADD_DOCUMENT_CANCEL
    }
};

//#endregion
//#region Update Document Methods

const changeSingleDocument = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_DOCUMENT,
        payload: payload
    }
};

export const updateDocumentStart = () => {
    return {
        type: actionTypes.UPDATE_DOCUMENT_START
    }
};

export const updateDocumentSuccess = () => {
    return {
        type: actionTypes.UPDATE_DOCUMENT_SUCCESS
    }
};

export const updateDocumentFail = (payload) => {
    return {
        type: actionTypes.UPDATE_DOCUMENT_FAIL,
        payload: payload
    }
};

export const updateDocumentLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_DOCUMENT_LOADING_CLEAR
    }
};

export const updateDocumentErrorClear = () => {
    return {
        type: actionTypes.UPDATE_DOCUMENT_ERROR_CLEAR
    }
};

export const updateDocumentCancel = () => {
    return {
        type: actionTypes.UPDATE_DOCUMENT_CANCEL
    }
};

//#endregion
//#region Documents Methods

export const fetchDocumentList = (listName, payload) => {
    return async (dispatch, getState) => {
        if (isStringNotEmpty(listName)) {
            try {
                await dispatch(actionCreators.getAccounts());
                const state = getState();
                const orchestratorState = { ...state.orchestrator };
                const accounts = [...orchestratorState.accounts];
                const documentsState = { ...state.documents };
                const existingLists = { ...documentsState.lists };

                let pagination = {};
                let searchParams = {};
                if (isObjectNotEmpty(existingLists[listName])) {
                    pagination = { ...existingLists[listName].pagination };
                    searchParams = { ...existingLists[listName].searchParams };
                }

                if (isObjectNotEmpty(payload)) {
                    searchParams = { ...payload };
                }

                dispatch(clearDocumentList(listName));
                dispatch(fetchDocumentListStart(listName));

                const res = await Data.getDocuments({ ...searchParams }, pagination, accounts);
                dispatch(fetchDocumentListSuccess(listName, { records: res.data, params: { searchParams: res.searchParams, pagination: res.pagination } }));
            } catch (error) {
                logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
                dispatch(fetchDocumentListFail(listName, { error: ErrorUtils.getErrorMessage(error) }));
            }
        }
    }
};

export const fetchBulkDocumentList = (listName, entityId) => {
    return async (dispatch, getState) => {
        if (isStringNotEmpty(listName)) {
            try {
                dispatch(fetchDocumentListStart(listName));

                await dispatch(actionCreators.getAccounts());
                const state = getState();
                const orchestratorState = { ...state.orchestrator };
                const accounts = [...orchestratorState.accounts];

                let searchParams = {
                    page: 1,
                    size: 1000000,
                    sort: 'createdAt',
                    order: 'desc'
                };

                if (isStringNotEmpty(entityId)) {
                    searchParams['links.entityId'] = entityId;
                }

                const documents = await Data.getBulkDocuments({ ...searchParams }, accounts);
                dispatch(fetchDocumentListSuccess(listName, { records: documents }));
            } catch (error) {
                logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
                dispatch(fetchDocumentListFail(listName, { error: ErrorUtils.getErrorMessage(error) }));
            }
        }
    }
};

export const fetchBulkDocumentLists = (listName, entityIds = []) => {
    return async (dispatch, getState) => {
        if (isStringNotEmpty(listName) && isListNotEmpty(entityIds)) {
            try {
                dispatch(fetchDocumentListStart(listName));

                await dispatch(actionCreators.getAccounts());
                const state = getState();
                const orchestratorState = { ...state.orchestrator };
                const accounts = [...orchestratorState.accounts];

                let searchParams = {
                    page: 1,
                    size: 1000000,
                    sort: 'createdAt',
                    order: 'desc',
                    'links.entityId': entityIds
                };

                const documents = await Data.getBulkDocuments({ ...searchParams }, accounts);
                entityIds.forEach((entityId) => {
                    const entityDocuments = documents.filter(d => d.links.map(l => l.entityId).includes(entityId));
                    dispatch(fetchDocumentListSuccess(entityId, { records: entityDocuments }));
                });
                dispatch(fetchDocumentListSuccess(listName, {}));
            } catch (error) {
                logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
                dispatch(fetchDocumentListFail(listName, { error: ErrorUtils.getErrorMessage(error) }));
            }
        }
    }
};

export const addDocument = (payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(addDocumentStart());

            await dispatch(actionCreators.getAccounts());
                const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const accounts = [...orchestratorState.accounts];

            // TODO: change to just add the document to whatever list is already in the document store
            const newDocument = await Data.addDocument(payload, accounts);
            if (isObjectNotEmpty(newDocument)) {
                if (isListNotEmpty(newDocument.links)) {
                    newDocument.links.forEach((link) => {
                        dispatch(addDocumentToDocumentList(link.entityId, newDocument));
                    });
                }

                dispatch(addDocumentSuccess());
                dispatch(addDocumentLoadingClear());
                dispatch(addDocumentErrorClear());
            } else {
                dispatch(addDocumentFail({ error: 'Failed to upload the file' }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addDocumentFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateDocument = (id, payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updateDocumentStart());

            if (isBooleanTrue(payload.isDeleted)) {
                const removedDocument = await Data.removeDocument(id);
                if (isObjectNotEmpty(removedDocument)) {
                    if (isListNotEmpty(removedDocument.links)) {
                        removedDocument.links.forEach((link) => {
                            dispatch(removeDocumentFromDocumentList(link.entityId, removedDocument));
                        });
                    }

                    dispatch(updateDocumentSuccess());
                    dispatch(updateDocumentLoadingClear());
                    dispatch(updateDocumentErrorClear());
                } else {
                    dispatch(updateDocumentFail({ error: 'Failed to delete the document.' }));
                }
            } else {
                await dispatch(actionCreators.getAccounts());
                const state = getState();
                const orchestratorState = { ...state.orchestrator };
                const accounts = [...orchestratorState.accounts];

                const updatedDocument = await Data.updateDocument(id, payload, accounts);
                if (isObjectNotEmpty(updatedDocument)) {
                    if (isListNotEmpty(updatedDocument.links)) {
                        updatedDocument.links.forEach((link) => {
                            dispatch(updateDocumentInDocumentList(link.entityId, updatedDocument));
                        });
                    }
                    dispatch(changeSingleDocument(updatedDocument));

                    dispatch(updateDocumentSuccess());
                    dispatch(updateDocumentLoadingClear());
                    dispatch(updateDocumentErrorClear());
                } else {
                    dispatch(updateDocumentFail({ error: 'Failed to update the document.' }));
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateDocumentFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion