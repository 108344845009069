import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, message, Row, Affix, Alert, Button, Popconfirm, Spin, Steps } from "antd";
import { withRouter } from "react-router";
import * as actionCreators from "../../store/actions/index";
import { useForm, FormProvider } from 'react-hook-form';
import DataRow from '../../components/DataRow/DataRow';
import DataPhoneNumberRow from '../../components/DataPhoneNumberRow/DataPhoneNumberRow';
import DataAddressRow from '../../components/DataAddressRow/DataAddressRow';
import DataContactInfoRow from '../../components/DataContactInfoRow/DataContactInfoRow';
import DataSMSBasicRow from '../../components/DataSMSBasicRow/DataSMSBasicRow';
import NewAccountCompany from '../../components/NewAccountCompany/NewAccountCompany';
import moment from 'moment';
import { isListNotEmpty, isMomentDate, isNotNullOrUndefined, isObjectEmpty, isObjectNotEmpty, isStringNotEmpty, removeEmpty } from '../../shared/objectUtils';
import Form from '../../components/Form/Form';
import logger from '../../shared/logger';
import * as Data from '../../api/data';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Header from '../../components/Header/Header';
import classes from './NewAccount.module.scss';
import { ErrorMessage } from '@hookform/error-message';
import NewAccountReview from '../../components/NewAccountReview/NewAccountReview';

const NewAccount = (props) => {
    //#region props

    const accountRegistrationId = isObjectNotEmpty(props.location) && isObjectNotEmpty(props.location.state) && isStringNotEmpty(props.location.state.accountRegistrationId) ? props.location.state.accountRegistrationId : null;
    const accountTypes = isObjectNotEmpty(props.location) && isObjectNotEmpty(props.location.state) && isListNotEmpty(props.location.state.accountTypes) ? props.location.state.accountTypes : [];

    //#endregion
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.accounts.isRecordAddLoading);
    const error = useSelector(state => state.accounts.addRecordError);
    const entityType = useSelector(state => state.auth.entityType);
    const userId = useSelector(state => state.auth.userId);
    const addRecordId = useSelector(state => state.accounts.addRecordId);

    //#endregion
    //#region useStates

    const [completedSteps, setCompletedSteps] = useState({ company: false });
    const [current, setCurrent] = useState('company');
    const [currentStepNumber, setCurrentStepNumber] = useState(0);
    const [isStepsLoading, setIsStepsLoading] = useState(false);
    const [companyData, setCompanyData] = useState({});
    const [insuranceFile, setInsuranceFile] = useState(null);
    const [insuranceExpiryDate, setInsuranceExpiryDate] = useState(null);
    const [w9File, setW9File] = useState(null);
    const [logoFile, setLogoFile] = useState(null);
    const [logoFileList, setLogoFileList] = useState([]);
    const [w9FileList, setW9FileList] = useState([]);
    const [insuranceFileList, setInsuranceFileList] = useState([]);

    //#endregion
    //#region wizard methods

    const cancelCancel = (event) => {
        // console.log('Cancelled cancel');
    };

    const confirmCancel = (event) => {
        dispatch(actionCreators.addAccountCancel());
        props.history.push({ pathname: "/accounts" });
        message.error('Account Creation Cancelled');
    };

    const onChangeStep = (key) => {
        // console.log(`step key: ${key}`);
        setCurrent(key);
        window.scrollTo(0, 0);

        if (key === 'company') {
            setCurrentStepNumber(0);
        } else if (key === 'review') {
            setCurrentStepNumber(1);
        } else {
            setCurrentStepNumber(- 1);
        }
    };

    const onChangeStepNumber = (step) => {
        // console.log(`step number: ${step}`);
        if (step === 0) {
            setCurrent('company');
        } else if (step === 1) {
            setCurrent('review');
        } else {
            setCurrent(null);
        }

        setCurrentStepNumber(step);
        window.scrollTo(0, 0);
    };

    const done = (newAccountId = null) => {
        if (entityType === 'STAFF') {
            if (isStringNotEmpty(newAccountId)) {
                props.history.push({ pathname: "/accounts/" + newAccountId });
            } else if (isStringNotEmpty(accountRegistrationId)) {
                props.history.push({ pathname: "/accountRegistrations/" });
            } else {
                props.history.goBack();
            }
        } else {
            props.history.goBack();
        }

        message.success('Successfully Created Account!');
        dispatch(actionCreators.addAccountCancel());
    };

    const onSubmit = (data) => {
        let insuranceExpiryDateValue = null;
        let w9ExpiryDate = null;

        if (isObjectNotEmpty(companyData)) {
            data = { ...companyData };
            if (isListNotEmpty(data.accountServices)) {
                let updatedAccountServices = [];
                data.accountServices.forEach((accountService) => {
                    delete accountService['service'];
                    updatedAccountServices.push(accountService);
                });
                data.accountServices = updatedAccountServices;
            }

            w9ExpiryDate = moment().year(moment().year() + 1).utc().toISOString();

            if (isMomentDate(insuranceExpiryDate)) {
                insuranceExpiryDateValue = insuranceExpiryDate.utc().toISOString();
            }

            if (isNotNullOrUndefined(data.insuranceExpiryDate)) {
                delete data['insuranceExpiryDate'];
            }
        }

        // console.log(data);

        let cleanedData = removeEmpty(data);

        // console.log(cleanedData);
        // console.log(insuranceExpiryDateValue)
        // console.log(w9ExpiryDate)
        // console.log(insuranceFile)
        // console.log(w9File)

        // if the user is not a staff member (is a customer instead), add the user creating the account as the default account user for the account
        if (entityType === 'SHIPPER' || entityType === 'CARRIER' || entityType === 'PRODUCER' || entityType === 'RECEIVER' || entityType === 'BROKER' || entityType === 'FACTORING') {
            cleanedData.accountUser = {
                userId: userId
            };
        } else if (entityType === 'STAFF') {
            // assigned the account user
            cleanedData.assignedAccountRepUserId = userId;
        }

        dispatch(actionCreators.addAccount(cleanedData, logoFile, insuranceFile, insuranceExpiryDateValue, w9File, w9ExpiryDate, entityType, accountRegistrationId));
    };

    //#endregion
    //#region useEffects and useMemos

    useEffect(() => {
        // console.log(`The Account Registration ID is ${accountRegistrationId}`);
        // console.log(accountTypes);

        if (isListNotEmpty(accountTypes)) {
            if (isObjectEmpty(companyData)) {
                setIsStepsLoading(true);
                let companyDataObj = {
                    entityTypes: accountTypes
                };

                if (isStringNotEmpty(accountRegistrationId)) {
                    Data.getAccountRegistration(accountRegistrationId).then((accountRegistration) => {
                        // console.log(accountRegistration);
                        if (isObjectNotEmpty(accountRegistration)) {
                            let companyDataAccountRegistrationObj = {
                                name: accountRegistration.name,
                                email: accountRegistration.email,
                                phone: accountRegistration.phone,
                                phoneExt: accountRegistration.phoneExt,
                                afterHoursPhone: accountRegistration.afterHoursPhone,
                                afterHoursPhoneExt: accountRegistration.afterHoursPhoneExt,
                                fax: accountRegistration.fax,
                                ein: accountRegistration.ein,
                                dbNum: accountRegistration.dbNum,
                                blueBookNum: accountRegistration.blueBookNum,
                                mcNum: accountRegistration.mcNum,
                                dotNum: accountRegistration.dotNum,
                                preferredPaymentMethod: accountRegistration.preferredPaymentMethod,
                                accountsReceivable: accountRegistration.accountsReceivable,
                                accountsReceivableAddress: accountRegistration.accountsReceivableAddress,
                                accountsPayable: accountRegistration.accountsPayable,
                                accountsPayableAddress: accountRegistration.accountsPayableAddress,
                                pointOfContact: accountRegistration.pointOfContact,
                                companyAddress: accountRegistration.companyAddress,
                                assetNeeds: {
                                    numPowerUnits: accountRegistration.numPowerUnits,
                                    numTrailers: accountRegistration.numTrailers,
                                    numHazMatDrivers: accountRegistration.numHazMatDrivers,
                                    numDrivers: accountRegistration.numDrivers,
                                    numTeams: accountRegistration.numTeams,
                                    has48DryVan: accountRegistration.has48DryVan,
                                    num48DryVan: accountRegistration.num48DryVan,
                                    has53DryVan: accountRegistration.has53DryVan,
                                    num53DryVan: accountRegistration.num53DryVan,
                                    has48Reefer: accountRegistration.has48Reefer,
                                    num48Reefer: accountRegistration.num48Reefer,
                                    has53Reefer: accountRegistration.has53Reefer,
                                    num53Reefer: accountRegistration.num53Reefer,
                                    has48Flatbed: accountRegistration.has48Flatbed,
                                    num48Flatbed: accountRegistration.num48Flatbed,
                                    has53Flatbed: accountRegistration.has53Flatbed,
                                    num53Flatbed: accountRegistration.num53Flatbed,
                                    hasLowBoy: accountRegistration.hasLowBoy,
                                    numLowBoy: accountRegistration.numLowBoy,
                                    hasRGN: accountRegistration.hasRGN,
                                    numRGN: accountRegistration.numRGN,
                                    hasStepDeckRamps: accountRegistration.hasStepDeckRamps,
                                    numStepDeckRamps: accountRegistration.numStepDeckRamps,
                                    hasStepDeckNoRamps: accountRegistration.hasStepDeckNoRamps,
                                    numStepDeckNoRamps: accountRegistration.numStepDeckNoRamps,
                                    hasTankersSingleCompartment: accountRegistration.hasTankersSingleCompartment,
                                    numTankersSingleCompartment: accountRegistration.numTankersSingleCompartment,
                                    hasTankersMultiCompartment: accountRegistration.hasTankersMultiCompartment,
                                    numTankersMultiCompartment: accountRegistration.numTankersMultiCompartment,
                                    hasTankersFiberGlass: accountRegistration.hasTankersFiberGlass,
                                    numTankersFiberGlass: accountRegistration.numTankersFiberGlass,
                                    hasCubedStraightTruck: accountRegistration.hasCubedStraightTruck,
                                    numCubedStraightTruck: accountRegistration.numCubedStraightTruck,
                                    has26StraightTruck: accountRegistration.has26StraightTruck,
                                    num26StraightTruck: accountRegistration.num26StraightTruck,
                                    hasSprinterVan: accountRegistration.hasSprinterVan,
                                    numSprinterVan: accountRegistration.numSprinterVan,
                                    hasStraightTruckLiftGates: accountRegistration.hasStraightTruckLiftGates,
                                    hasDryVanLiftGates: accountRegistration.hasDryVanLiftGates,
                                    hasDryVanRollerBed: accountRegistration.hasDryVanRollerBed,
                                    hasReeferFrozen: accountRegistration.hasReeferFrozen,
                                    hasOverSized: accountRegistration.hasOverSized
                                }
                            };

                            setCompanyData({ ...companyDataObj, ...companyDataAccountRegistrationObj });

                            setIsStepsLoading(false);
                        } else {
                            setCompanyData({ ...companyDataObj });
                            setIsStepsLoading(false);
                        }
                    }).catch(err => {
                        logger.logDebugEvent('NewAccount.js', err.message, true);
                        setCompanyData({ ...companyDataObj });
                        setIsStepsLoading(false);
                    });
                } else {
                    setCompanyData({ ...companyDataObj });
                    setIsStepsLoading(false);
                }
            }
        }

        return () => {
            // console.log("Cleaned up useEffect");
        };
    }, [accountRegistrationId, accountTypes, companyData]);

    useEffect(() => {
        // clear any previous errors if this is a new account creation
        dispatch(actionCreators.addAccountLoadingClear());
        dispatch(actionCreators.addAccountErrorClear());

        return () => {
            // console.log("Cleaned up useEffect");
        };
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null && addRecordId !== undefined && addRecordId !== null) {
            done(addRecordId);
        }
    }, [isLoading, error, addRecordId]);

    //#endregion

    return (
        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={((isLoading === true && error === null) || isStepsLoading === true)}>
            <Row gutter={[4, 4]}>
                {/* <Col xs={{ span: 0 }} md={{ span: 6 }} lg={{ span: 5, offset: 1 }}>
                    <Affix offsetTop={44} style={{ position: 'absolute', marginTop: 92 }}>
                        <div>
                            <Steps size="default" direction="vertical" current={currentStepNumber} onChange={onChangeStepNumber}>
                                <Steps.Step title="Company Details" />
                                <Steps.Step title="Review" disabled={completedSteps.company === false} />
                            </Steps>
                        </div>
                    </Affix>
                </Col> */}
                <Col xs={{ span: 24 }} md={{ span: 18 }} xl={{ span: 18 }}>
                    <Row gutter={[4, 4]}>
                        <Col span={24}>
                            <Header
                                title="New Account"
                                icon={<FontAwesomeIcon className="anticon" icon={faUsers} />}
                            />
                        </Col>
                    </Row>
                    {(current === 'company') ? (
                        <NewAccountCompany
                            setCompanyData={setCompanyData}
                            companyData={companyData}
                            entityTypes={accountTypes}
                            logoFile={logoFile}
                            setLogoFile={setLogoFile}
                            setLogoFileList={setLogoFileList}
                            logoFileList={logoFileList}
                            setInsuranceFile={setInsuranceFile}
                            insuranceFile={insuranceFile}
                            setInsuranceFileList={setInsuranceFileList}
                            insuranceFileList={insuranceFileList}
                            setInsuranceExpiryDate={setInsuranceExpiryDate}
                            insuranceExpiryDate={insuranceExpiryDate}
                            setW9File={setW9File}
                            w9File={w9File}
                            setW9FileList={setW9FileList}
                            w9FileList={w9FileList}
                            completedSteps={completedSteps}
                            setCompletedSteps={setCompletedSteps}
                            cancelButton={
                                <Popconfirm
                                    title="Are you sure you want to cancel the creation of this account? You will lose your changes."
                                    onConfirm={(e) => { confirmCancel(e); }}
                                    onCancel={(e) => { cancelCancel(e); }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button danger={true} type="link" block>Cancel Account Creation</Button>
                                </Popconfirm>
                            }
                            previousStepAction={null}
                            nextStepAction={onChangeStep} />
                    ) : null}
                    {(current === 'review') ? (
                        <NewAccountReview
                            companyData={companyData}
                            previousStepAction={onChangeStep}
                            nextStepAction={onSubmit}
                        />
                    ) : null}
                </Col>
                <Col xs={{ span: 0 }} md={{ span: 6 }} xl={{ span: 6 }}>
                    <FormProvider {...methods}>
                        <Form onSubmit={methods.handleSubmit(onSubmit)}>
                            <Affix offsetTop={4}>
                                <div className={classes.accountSummaryContainer}>
                                    <Row gutter={[12, 12]} className={classes.accountSummary}>
                                        <Col span={24}>
                                            <Card title="Account Summary" headStyle={{ textAlign: 'center', fontSize: 20 }} size="small" bordered={false}>
                                                {isObjectNotEmpty(companyData) ? (
                                                    <>
                                                        <h3 style={{ textAlign: 'center' }}>Company Details</h3>
                                                        {isStringNotEmpty(companyData.name) ? <DataRow title="Company Name" value={companyData.name} dataType="String" /> : null}
                                                        {isStringNotEmpty(companyData.ein) ? <DataRow title="EIN" value={companyData.ein} dataType="String" /> : null}
                                                        {isStringNotEmpty(companyData.dotNum) ? <DataRow title="DOT #" value={companyData.dotNum} dataType="String" /> : null}
                                                        {isStringNotEmpty(companyData.mcNum) ? <DataRow title="MC #" value={companyData.mcNum} dataType="String" /> : null}
                                                        {isStringNotEmpty(companyData.dbNum) ? <DataRow title="D&B #" value={companyData.dbNum} dataType="String" /> : null}
                                                        {isStringNotEmpty(companyData.blueBookNum) ? <DataRow title="Produce Blue Book #" value={companyData.blueBookNum} dataType="String" /> : null}
                                                        {isStringNotEmpty(companyData.email) ? <DataRow title="Company Email Address" value={companyData.email} dataType="String" /> : null}
                                                        {isStringNotEmpty(companyData.phone) ? <DataPhoneNumberRow title="Company Day Time Phone #" phone={companyData.phone} phoneExt={companyData.phoneExt} /> : null}
                                                        {isStringNotEmpty(companyData.afterHoursPhone) ? <DataPhoneNumberRow title="Company After Hours Phone #" phone={companyData.afterHoursPhone} phoneExt={companyData.afterHoursPhoneExt} /> : null}
                                                        {isStringNotEmpty(companyData.fax) ? <DataRow title="Company Fax Number" value={companyData.fax} dataType="PhoneNumber" /> : null}
                                                        {isObjectNotEmpty(companyData.pointOfContact) ? <DataContactInfoRow valueStyle={{ paddingLeft: 16 }} title="Point of Contact" contact={companyData.pointOfContact} /> : null}
                                                        {isObjectNotEmpty(companyData.companyAddress) ? <DataAddressRow valueStyle={{ paddingLeft: 16 }} title="Company Address" address={companyData.companyAddress} /> : null}
                                                        {isObjectNotEmpty(companyData.accountsPayable) ? <DataContactInfoRow valueStyle={{ paddingLeft: 16 }} title="A/P Contact" contact={companyData.accountsPayable} /> : null}
                                                        {isObjectNotEmpty(companyData.accountsPayableAddress) ? <DataAddressRow valueStyle={{ paddingLeft: 16 }} title="A/P Address" address={companyData.accountsPayableAddress} /> : null}
                                                        {isObjectNotEmpty(companyData.accountsReceivable) ? <DataContactInfoRow valueStyle={{ paddingLeft: 16 }} title="A/R Contact" contact={companyData.accountsReceivable} /> : null}
                                                        {isObjectNotEmpty(companyData.accountsReceivableAddress) ? <DataAddressRow valueStyle={{ paddingLeft: 16 }} title="A/R Address" address={companyData.accountsReceivableAddress} /> : null}
                                                        {isStringNotEmpty(companyData.preferredPaymentMethod) ? <DataRow title="Preferred Payment Method" value={companyData.preferredPaymentMethod} dataType="PreferredPaymentMethod" /> : null}
                                                        {isObjectNotEmpty(companyData.smsBasic) ? <DataSMSBasicRow valueStyle={{ paddingLeft: 16 }} title="SMS Basic Scores" smsBasic={companyData.smsBasic} /> : null}

                                                        {isObjectNotEmpty(companyData.assetNeeds) && companyData.entityTypes.includes("SHIPPER") ? (
                                                            <>
                                                                <hr />
                                                                <h3 style={{ textAlign: 'center' }}>Equipment Needs</h3>
                                                                <h4 style={{ marginTop: 12, marginBottom: 12 }}>Reefers</h4>
                                                                <DataRow title="Do you need 48' Reefers?" value={companyData.assetNeeds.has48Reefer} dataType="Boolean" />
                                                                <DataRow title="Do you need 53' Reefers?" value={companyData.assetNeeds.has53Reefer} dataType="Boolean" />
                                                                <DataRow title="Do you need to ship frozen?" value={companyData.assetNeeds.hasReeferFrozen} dataType="Boolean" />
                                                                <h4 style={{ marginTop: 12, marginBottom: 12 }}>Dry Vans</h4>
                                                                <DataRow title="Do you need 48' Dry Vans?" value={companyData.assetNeeds.has48DryVan} dataType="Boolean" />
                                                                <DataRow title="Do you need 53' Dry Vans?" value={companyData.assetNeeds.has53DryVan} dataType="Boolean" />
                                                                <DataRow title="Do you need lift gates for the dry vans?" value={companyData.assetNeeds.hasDryVanLiftGates} dataType="Boolean" />
                                                                <DataRow title="Do you need roller beds for the dry vans?" value={companyData.assetNeeds.hasDryVanRollerBed} dataType="Boolean" />
                                                                {/* <h4 style={{ marginTop: 12, marginBottom: 12 }}>Flatbeds</h4>
                                                                <DataRow title="Do you need 48' Flatbeds?" value={companyData.assetNeeds.has48Flatbed} dataType="Boolean" />
                                                                <DataRow title="Do you need 53' Flatbeds?" value={companyData.assetNeeds.has53Flatbed} dataType="Boolean" />
                                                                <h4 style={{ marginTop: 12, marginBottom: 12 }}>Over-Sized Loads</h4>
                                                                <DataRow title="Do you need to ship over dimensional/overweight loads?" value={companyData.assetNeeds.hasOverSized} dataType="Boolean" />
                                                                <h4 style={{ marginTop: 12, marginBottom: 12 }}>Low Boys</h4>
                                                                <DataRow title="Do you need Low Boys?" value={companyData.assetNeeds.hasLowBoy} dataType="Boolean" />
                                                                <h4 style={{ marginTop: 12, marginBottom: 12 }}>RGNs</h4>
                                                                <DataRow title="Do you need RGNs?" value={companyData.assetNeeds.hasRGN} dataType="Boolean" />
                                                                <h4 style={{ marginTop: 12, marginBottom: 12 }}>Step Decks</h4>
                                                                <DataRow title="Do you need Step Decks with Ramps?" value={companyData.assetNeeds.hasStepDeckRamps} dataType="Boolean" />
                                                                <DataRow title="Do you need Step Decks without Ramps?" value={companyData.assetNeeds.hasStepDeckNoRamps} dataType="Boolean" />
                                                                <h4 style={{ marginTop: 12, marginBottom: 12 }}>Tankers</h4>
                                                                <DataRow title="Do you need Single Compartment Tankers?" value={companyData.assetNeeds.hasTankersSingleCompartment} dataType="Boolean" />
                                                                <DataRow title="Do you need Multi Compartment Tankers?" value={companyData.assetNeeds.hasTankersMultiCompartment} dataType="Boolean" />
                                                                <DataRow title="Do you need Fiber Glass Tankers?" value={companyData.assetNeeds.hasTankersFiberGlass} dataType="Boolean" />
                                                                <h4 style={{ marginTop: 12, marginBottom: 12 }}>Straight Trucks</h4>
                                                                <DataRow title="Do you need Cubed Straight Trucks?" value={companyData.assetNeeds.hasCubedStraightTruck} dataType="Boolean" />
                                                                <DataRow title="Do you need 26' Straight Trucks?" value={companyData.assetNeeds.has26StraightTruck} dataType="Boolean" />
                                                                <DataRow title="Do you need lift gates for the straight trucks?" value={companyData.assetNeeds.hasStraightTruckLiftGates} dataType="Boolean" />
                                                                <h4 style={{ marginTop: 12, marginBottom: 12 }}>Sprinter Vans</h4>
                                                                <DataRow title="Do you need Sprinter Vans?" value={companyData.assetNeeds.hasSprinterVan} dataType="Boolean" /> */}
                                                            </>
                                                        ) : null}
                                                        {isObjectNotEmpty(companyData.assetNeeds) && companyData.entityTypes.includes("CARRIER") ? (
                                                            <>
                                                                <hr />
                                                                <h3 style={{ textAlign: 'center' }}>Asset Details</h3>
                                                                <Row gutter={[16, 16]}>
                                                                    <Col span={24}>
                                                                        <DataRow title="# of Power Units" value={companyData.assetNeeds.numPowerUnits} dataType="Integer" />
                                                                    </Col>
                                                                    <Col span={24}>
                                                                        <DataRow title="# of Trailers" value={companyData.assetNeeds.numTrailers} dataType="Integer" />
                                                                    </Col>
                                                                    <Col span={24}>
                                                                        <DataRow title="# of Drivers" value={companyData.assetNeeds.numDrivers} dataType="Integer" />
                                                                    </Col>
                                                                    <Col span={24}>
                                                                        <DataRow title="# of HazMat Drivers" value={companyData.assetNeeds.numHazMatDrivers} dataType="Integer" />
                                                                    </Col>
                                                                    <Col span={24}>
                                                                        <DataRow title="# of Teams" value={companyData.assetNeeds.numTeams} dataType="Integer" />
                                                                    </Col>
                                                                </Row>
                                                                <h3 style={{ textAlign: 'center' }}>Trailer Types</h3>
                                                                <Row gutter={[16, 16]}>
                                                                    <Col span={24}>
                                                                        <h4 style={{ marginTop: 12, marginBottom: 12 }}>Reefers</h4>
                                                                        <DataRow title="Do you have 48' Reefers?" value={companyData.assetNeeds.has48Reefer} dataType="Boolean" />
                                                                        <DataRow title="If so, how many?" value={companyData.assetNeeds.num48Reefer} dataType="Integer" />
                                                                        <DataRow title="Do you have 53' Reefers?" value={companyData.assetNeeds.has53Reefer} dataType="Boolean" />
                                                                        <DataRow title="If so, how many?" value={companyData.assetNeeds.num53Reefer} dataType="Integer" />
                                                                        <DataRow title="Do you haul frozen?" value={companyData.assetNeeds.hasReeferFrozen} dataType="Boolean" />
                                                                        <h4 style={{ marginTop: 12, marginBottom: 12 }}>Dry Vans</h4>
                                                                        <DataRow title="Do you have 48' Dry Vans?" value={companyData.assetNeeds.has48DryVan} dataType="Boolean" />
                                                                        <DataRow title="If so, how many?" value={companyData.assetNeeds.num48DryVan} dataType="Integer" />
                                                                        <DataRow title="Do you have 53' Dry Vans?" value={companyData.assetNeeds.has53DryVan} dataType="Boolean" />
                                                                        <DataRow title="If so, how many?" value={companyData.assetNeeds.num53DryVan} dataType="Integer" />
                                                                        <DataRow title="Do you have lift gates for the dry vans?" value={companyData.assetNeeds.hasDryVanLiftGates} dataType="Boolean" />
                                                                        <DataRow title="Do you have roller beds for the dry vans?" value={companyData.assetNeeds.hasDryVanRollerBed} dataType="Boolean" />
                                                                        <h4 style={{ marginTop: 12, marginBottom: 12 }}>Flatbeds</h4>
                                                                        <DataRow title="Do you have 48' Flatbeds?" value={companyData.assetNeeds.has48Flatbed} dataType="Boolean" />
                                                                        <DataRow title="If so, how many?" value={companyData.assetNeeds.num48Flatbed} dataType="Integer" />
                                                                        <DataRow title="Do you have 53' Flatbeds?" value={companyData.assetNeeds.has53Flatbed} dataType="Boolean" />
                                                                        <DataRow title="If so, how many?" value={companyData.assetNeeds.num53Flatbed} dataType="Integer" />
                                                                        <h4 style={{ marginTop: 12, marginBottom: 12 }}>Over-Sized Loads</h4>
                                                                        <DataRow title="Do you haul over dimensional/overweight loads?" value={companyData.assetNeeds.hasOverSized} dataType="Boolean" />
                                                                    </Col>
                                                                    {/* <Col span={24}>
                                                                        <h4 style={{ marginTop: 12, marginBottom: 12 }}>Low Boys</h4>
                                                                        <DataRow title="Do you have Low Boys?" value={companyData.assetNeeds.hasLowBoy} dataType="Boolean" />
                                                                        <DataRow title="If so, how many?" value={companyData.assetNeeds.numLowBoy} dataType="Integer" />
                                                                        <h4 style={{ marginTop: 12, marginBottom: 12 }}>RGNs</h4>
                                                                        <DataRow title="Do you have RGNs?" value={companyData.assetNeeds.hasRGN} dataType="Boolean" />
                                                                        <DataRow title="If so, how many?" value={companyData.assetNeeds.numRGN} dataType="Integer" />
                                                                        <h4 style={{ marginTop: 12, marginBottom: 12 }}>Step Decks</h4>
                                                                        <DataRow title="Do you have Step Decks with Ramps?" value={companyData.assetNeeds.hasStepDeckRamps} dataType="Boolean" />
                                                                        <DataRow title="If so, how many?" value={companyData.assetNeeds.numStepDeckRamps} dataType="Integer" />
                                                                        <DataRow title="Do you have Step Decks without Ramps?" value={companyData.assetNeeds.hasStepDeckNoRamps} dataType="Boolean" />
                                                                        <DataRow title="If so, how many?" value={companyData.assetNeeds.numStepDeckNoRamps} dataType="Integer" />
                                                                        <h4 style={{ marginTop: 12, marginBottom: 12 }}>Tankers</h4>
                                                                        <DataRow title="Do you have Single Compartment Tankers?" value={companyData.assetNeeds.hasTankersSingleCompartment} dataType="Boolean" />
                                                                        <DataRow title="If so, how many?" value={companyData.assetNeeds.numTankersSingleCompartment} dataType="Integer" />
                                                                        <DataRow title="Do you have Multi Compartment Tankers?" value={companyData.assetNeeds.hasTankersMultiCompartment} dataType="Boolean" />
                                                                        <DataRow title="If so, how many?" value={companyData.assetNeeds.numTankersMultiCompartment} dataType="Integer" />
                                                                        <DataRow title="Do you have Fiber Glass Tankers?" value={companyData.assetNeeds.hasTankersFiberGlass} dataType="Boolean" />
                                                                        <DataRow title="If so, how many?" value={companyData.assetNeeds.numTankersFiberGlass} dataType="Integer" />
                                                                        <h4 style={{ marginTop: 12, marginBottom: 12 }}>Straight Trucks</h4>
                                                                        <DataRow title="Do you have Cubed Straight Trucks?" value={companyData.assetNeeds.hasCubedStraightTruck} dataType="Boolean" />
                                                                        <DataRow title="If so, how many?" value={companyData.assetNeeds.numCubedStraightTruck} dataType="Integer" />
                                                                        <DataRow title="Do you have 26' Straight Trucks?" value={companyData.assetNeeds.has26StraightTruck} dataType="Boolean" />
                                                                        <DataRow title="If so, how many?" value={companyData.assetNeeds.num26StraightTruck} dataType="Integer" />
                                                                        <DataRow title="Do you have lift gates for the straight trucks?" value={companyData.assetNeeds.hasStraightTruckLiftGates} dataType="Boolean" />
                                                                        <h4 style={{ marginTop: 12, marginBottom: 12 }}>Sprinter Vans</h4>
                                                                        <DataRow title="Do you have Sprinter Vans?" value={companyData.assetNeeds.hasSprinterVan} dataType="Boolean" />
                                                                        <DataRow title="If so, how many?" value={companyData.assetNeeds.numSprinterVan} dataType="Integer" />
                                                                    </Col> */}
                                                                </Row>
                                                            </>
                                                        ) : null}
                                                        {isObjectNotEmpty(companyData.accountUser) ? (
                                                            <>
                                                                <hr />
                                                                <h3 style={{ textAlign: 'center' }}>Default Account Admin Details</h3>
                                                                {isStringNotEmpty(companyData.accountUser.firstName) ? <DataRow title="First Name" value={companyData.accountUser.firstName} dataType="String" /> : null}
                                                                {isStringNotEmpty(companyData.accountUser.lastName) ? <DataRow title="Last Name" value={companyData.accountUser.lastName} dataType="String" /> : null}
                                                                {isStringNotEmpty(companyData.accountUser.email) ? <DataRow title="Email Address" value={companyData.accountUser.email} dataType="String" /> : null}
                                                                {isStringNotEmpty(companyData.accountUser.phone) ? <DataPhoneNumberRow title="Phone #" phone={companyData.accountUser.phone} /> : null}
                                                            </>
                                                        ) : null}
                                                    </>
                                                ) : null}
                                                {error && <Alert message={`${error}`} type="error" />}
                                                <ErrorMessage
                                                    errors={methods.errors}
                                                    name="stops"
                                                    render={({ message, messages }) => {
                                                        if (isNotNullOrUndefined(messages)) {
                                                            return Object.entries(messages).map(([type, message]) => (<Alert key={`stops-${type}`} message={message} type="error" />));
                                                        } else if (isStringNotEmpty(message)) {
                                                            return <Alert message={message} type="error" />;
                                                        } else {
                                                            return null;
                                                        }
                                                    }}
                                                />
                                                {error && <Alert message={`${error}`} type="error" />}
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row gutter={[12, 12]} className={classes.accountSummaryButtonContainer}>
                                        <Col xs={{ span: 24, order: 2 }} xl={{ span: 12, order: 1 }}>
                                            <Popconfirm
                                                title="Are you sure you want to cancel the creation of this account? You will lose your changes."
                                                onConfirm={confirmCancel}
                                                onCancel={cancelCancel}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <Button type="danger" block>Cancel</Button>
                                            </Popconfirm>
                                        </Col>
                                        <Col xs={{ span: 24, order: 1 }} xl={{ span: 12, order: 2 }}>
                                            <Button type="primary" block htmlType="submit" disabled={completedSteps.company === false}>Create Account</Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Affix>
                        </Form>
                    </FormProvider>
                </Col>
            </Row>
        </Spin>
    );
};

export default withRouter(NewAccount);