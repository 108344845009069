import StringFormatter from '../shared/stringFormatter';

const stringFormatter = new StringFormatter();

const YesNo = ({ value }) => {
    return stringFormatter.toYesNo(value);
};

const FirstNameLastName = ({ value }) => {
    return stringFormatter.toFirstNameLastName(value);
};

const PhoneNumber = ({ value }) => {
    return stringFormatter.toFormattedPhoneNumber(value);
};

const Money = ({ value }) => {
    return stringFormatter.toFormattedMoney(value);
};

const ToDecimal = ({ value = 0, decPlaces = 2, decSep = ".", thouSep = "," }) => {
    return stringFormatter.toFormattedDecimal(value, decPlaces, decSep, thouSep);
};

// takes decimal value, multiplies by 100 and adds the percentage symbol
const Percentage = ({ value }) => {
    return stringFormatter.toFormattedPercentage(value);
};

const Address = ({ address }) => {
    return stringFormatter.toFormattedAddress(address);
};

export default {
    YesNo,
    FirstNameLastName,
    PhoneNumber,
    Money,
    ToDecimal,
    Percentage,
    Address,
};