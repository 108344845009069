import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { DownOutlined } from '@ant-design/icons';
import { Drawer, Button, Dropdown, Menu } from 'antd';
import classes from './PreferredLanes.module.scss';
import DataTable from '../../components/DataTable/DataTable';
import StringFormatter from '../../shared/stringFormatter';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools, faRoute } from "@fortawesome/free-solid-svg-icons";
import NewPreferredLane from '../../components/NewPreferredLane/NewPreferredLane';
import EditPreferredLane from "../../components/EditPreferredLane/EditPreferredLane";
import { withRouter } from 'react-router-dom';
import { isObjectNotEmpty, isStringEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { CanDo } from '../../shared/entitlements/entitlements';
import { selectListIsLoading, selectListPagination, selectListRecords } from '../../store/utility';
import ColumnSearchFilter from '../../shared/tableColumnSearchFilter';

const stringFormatter = new StringFormatter();

const PreferredLanes = ({ accountId = null, ...props }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;
    const singularEntityName = "Preferred Lane";
    const pluralEntityName = "Preferred Lanes";

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);
    const records = useSelector(state => selectListRecords(state.preferredLanes.lists, isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')));
    const isLoading = useSelector(state => selectListIsLoading(state.preferredLanes.lists, isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')));
    const pagination = useSelector(state => selectListPagination(state.preferredLanes.lists, isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')));

    //#endregion
    //#region useStates

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showEditEntity, setShowEditEntity] = useState(false);

    //#endregion
    //#region toggles

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    //#endregion
    //#region goTos

    const goToPreferredLane = (id) => {
        props.history.push({ pathname: `/preferredLanes/${id}` }, {
            previousPageTitle: "Preferred Lanes",
            previousPageLocation: props.location,
            previousBreadcrumbs: props.breadcrumbs,
        });
    };

    //#endregion
    //#region table methods

    const refreshTable = () => {
        let searchParams = {
            page: 1,
            size: 5,
            isDeleted: false
        };

        if (isStringNotEmpty(accountId)) {
            searchParams.accountId = accountId;
        } else if (isStringNotEmpty(entityId) && entityType !== 'STAFF') {
            searchParams.accountId = entityId;
        }

        dispatch(actionCreators.fetchPreferredLaneList(isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL'), searchParams));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchPreferredLaneListSuccess(isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL'), { params: { pagination: pager } }));

        let searchParams = {
            page: pagination.current,
            size: pagination.pageSize,
            isDeleted: false
        };

        if (isStringNotEmpty(sorter.order)) {
            const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
            searchParams.order = sortOrder;
        }
        if (isStringNotEmpty(sorter.field)) {
            searchParams.sort = sorter.field;
        }

        if (isStringNotEmpty(accountId)) {
            searchParams.accountId = accountId;
        } else if (isStringNotEmpty(entityId) && entityType !== 'STAFF') {
            searchParams.accountId = entityId;
        }

        dispatch(actionCreators.fetchPreferredLaneList(isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL'), searchParams));
    };

    //#endregion
    //#region table displays

    const menu = (record) => {
        return (
            <Menu>
                {CanDo({ entityAction: 'UPDATE', entityModel: 'PREFERRED_LANE', accountId: record.accountId }) && (
                    <Menu.Item key="editEntity" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); toggleEditEntity(); }}>
                        <FontAwesomeIcon className="anticon" icon={faRoute} style={{ marginRight: 8 }} />
                        <span>Edit {singularEntityName} Details</span>
                    </Menu.Item>
                )}
                {CanDo({ entityAction: 'READ', entityModel: 'PREFERRED_LANE', accountId: record.accountId }) && (
                    <Menu.Item key="goToDashboard" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); goToPreferredLane(record.id); }}>
                        <span>Go to {singularEntityName} Dashboard</span>
                    </Menu.Item>
                )}
            </Menu>
        );
    };

    const columns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            className: classes.columnNoBreak,
            width: 50,
            render: (text, record) => {
                return (
                    <Dropdown overlay={menu(record)} trigger={['click']}>
                        <Button>Actions <DownOutlined /></Button>
                    </Dropdown>
                );
            },
            align: 'center',
        },
        {
            title: 'Lane Id',
            dataIndex: 'customerLaneId',
            key: 'customerLaneId',
        },
        {
            title: 'Origin City',
            dataIndex: 'originCity',
            key: 'originCity',
        },
        {
            title: 'Origin State',
            dataIndex: 'originState',
            key: 'originState',
        },
        {
            title: 'Destination City',
            dataIndex: 'destinationCity',
            key: 'destinationCity',
        },
        {
            title: 'Destination State',
            dataIndex: 'destinationState',
            key: 'destinationState',
        },
        {
            title: 'Capacity Per Week',
            dataIndex: 'capacityPerWeek',
            key: 'capacityPerWeek',
            render: (text, record) => {
                if (isStringNotEmpty(text)) {
                    return text;
                } else {
                    return 0;
                }
            }
        },
        {
            title: 'Trailer Type',
            dataIndex: ['trailerType', 'description'],
            key: 'trailerType.description',
        },
        {
            title: 'Approx Dist',
            dataIndex: ['lane', 'approxDistance'],
            key: 'lane.approxDistance',
            render: (text, record) => { return stringFormatter.toFormattedString("Distance", text, isObjectNotEmpty(record.lane) ? record.lane.approxDistanceUnit : null); },
        },
        {
            title: 'Rate',
            dataIndex: 'rate',
            key: 'rate',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, record.rateUnit); },
        }
    ];

    if (CanDo({ staffOnly: true })) {
        columns.push({
            title: 'Customer Rate',
            dataIndex: ['lane', 'rate'],
            key: 'lane.rate',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, isObjectNotEmpty(record.lane) ? record.lane.rateUnit : null); },
        });
    }

    columns.push({
        title: 'Start Date',
        dataIndex: 'rateStartDateTime',
        key: 'rateStartDateTime',
        render: (text, record) => { return stringFormatter.toFormattedString("MomentDateTime", text, null); },
    });
    columns.push({
        title: 'End Date',
        dataIndex: 'rateEndDateTime',
        key: 'rateEndDateTime',
        render: (text, record) => { return stringFormatter.toFormattedString("MomentDateTime", text, null); },
    });
    columns.push({
        title: 'Max Load Weight',
        dataIndex: 'maxLoadWeight',
        key: 'maxLoadWeight',
        render: (text, record) => { return stringFormatter.toFormattedString("Weight", text, record.maxLoadWeightUnit); },
    });

    if (CanDo({ staffOnly: true })) {
        if (isStringEmpty(accountId)) {
            columns.push({
                title: 'Account Name',
                dataIndex: ['account', 'name'],
                key: 'account.name',
            });
        }
    }

    //#endregion
    //#region displays

    const newEntityComponents = (
        <Drawer
            title={"New " + singularEntityName}
            onClose={toggleNewEntity}
            visible={showNewEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewPreferredLane
                cancel={toggleNewEntity}
                accountId={isStringNotEmpty(accountId) ? accountId : (isStringNotEmpty(entityId) && entityType !== 'STAFF' ? entityId : null)}
            />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName}
            onClose={toggleEditEntity}
            visible={showEditEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditPreferredLane
                cancel={toggleEditEntity}
                preferredLane={selectedRecord}
            />
        </Drawer>
    );

    //#endregion
    //#region useEffects

    useEffect(() => {
        refreshTable();
    }, [accountId, entityId]);

    //#endregion

    return (
        <DataTable
            dataSource={records}
            columns={columns}
            pagination={pagination}
            onChange={handleTableChange}
            loading={isLoading === true}
            rowClassName={classes.dataTableRow}
            singularEntityName={singularEntityName}
            pluralEntityName={pluralEntityName}
            newEntityAction={CanDo({ entityAction: 'CREATE', entityModel: 'PREFERRED_LANE', accountId: isStringNotEmpty(accountId) ? accountId : (isStringNotEmpty(entityId) && entityType !== 'STAFF' ? entityId : null) }) ? toggleNewEntity : null}
            rowKey={record => record.id}
            style={{ backgroundColor: '#ffffff' }}
        >
            {newEntityComponents}
            {editEntityComponents}
        </DataTable>
    );
};

export default withRouter(PreferredLanes);