import Enums from "../../shared/enums";
import { isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import StringFormatter from '../../shared/stringFormatter';
import pickUpMarker from '../../assets/img/pickUpMarker.png';
import dropOffMarker from '../../assets/img/dropOffMarker.png';

const stringFormatter = new StringFormatter();

const getLaneStopMarkerIcon = (stop) => {
    let stopIcon = null;
    if (isObjectNotEmpty(stop) && isStringNotEmpty(stop.stopType)) {
        if (stop.stopType === 'PICK_UP') {
            stopIcon = pickUpMarker;
        } else if (stop.stopType === 'DROP_OFF') {
            stopIcon = dropOffMarker;
        }
    }

    return stopIcon;
};

const getLaneStopTypeDisplay = (stop) => {
    let stopTypeDisplay = '';
    if (isObjectNotEmpty(stop) && isStringNotEmpty(stop.stopType)) {
        stopTypeDisplay = Enums.StopTypes.getValueByName(stop.stopType);
    }

    return stopTypeDisplay;
};

const getLaneStopType = (stop) => {
    let stopType = null;
    if (isObjectNotEmpty(stop) && isStringNotEmpty(stop.stopType)) {
        stopType = stop.stopType;
    }

    return stopType;
};

const getLaneStopTimeZone = (stop) => {
    let stopTimeZone = null;
    if (isObjectNotEmpty(stop) && isObjectNotEmpty(stop.location) && isStringNotEmpty(stop.location.timeZone)) {
        stopTimeZone = stop.location.timeZone;
    }

    return stopTimeZone;
};

const getLaneLocationInfoByLink = (location, accountId) => {
    if (isObjectNotEmpty(location) && isListNotEmpty(location.links) && isStringNotEmpty(accountId)) {
        let accountLink = location.links.find(l => l.accountId === accountId);
        if (isObjectNotEmpty(accountLink)) {
            return {
                ...location,
                ...accountLink
            };
        }
    }

    return null;
};

const getLaneLocationNameByLink = (location, accountId) => {
    if (isObjectNotEmpty(location) && isListNotEmpty(location.links) && isStringNotEmpty(accountId)) {
        let accountLink = location.links.find(l => l.accountId === accountId);
        if (isObjectNotEmpty(accountLink) && isStringNotEmpty(accountLink.name)) {
            return accountLink.name;
        }
    }

    return null;
};

const getLaneStopLocationNameDisplay = (stop, shipperId, carrierId, entityType, entityId) => {
    if (isObjectNotEmpty(stop)) {
        let shipperLinkName = getLaneLocationNameByLink(stop.location, shipperId);
        let carrierLinkName = getLaneLocationNameByLink(stop.location, carrierId);
        let entityLinkName = getLaneLocationNameByLink(stop.location, entityId);
        if (entityType === 'SHIPPER') {
            if (isStringNotEmpty(shipperLinkName)) {
                return shipperLinkName;
            } else if (isStringNotEmpty(carrierLinkName)) {
                return carrierLinkName;
            } else if (isStringNotEmpty(entityLinkName)) {
                return entityLinkName;
            }
        } else if (entityType === 'CARRIER') {
            if (isStringNotEmpty(carrierLinkName)) {
                return carrierLinkName;
            } else if (isStringNotEmpty(shipperLinkName)) {
                return shipperLinkName;
            } else if (isStringNotEmpty(entityLinkName)) {
                return entityLinkName;
            }
        } else {
            if (isStringNotEmpty(entityLinkName)) {
                return entityLinkName;
            } else if (isStringNotEmpty(shipperLinkName)) {
                return shipperLinkName;
            } else if (isStringNotEmpty(carrierLinkName)) {
                return carrierLinkName;
            }
        }
    }

    return null;
};

const getLaneStopNameDisplay = (stop, shipperId, carrierId, entityType, entityId) => {
    if (isObjectNotEmpty(stop)) {
        let stopNameDisplay = getLaneStopLocationNameDisplay(stop, shipperId, carrierId, entityType, entityId);
        if (isStringNotEmpty(stopNameDisplay)) {
            return stopNameDisplay;
        } else if (isStringNotEmpty(stop.stopType)) {
            return Enums.StopTypes.getValueByName(stop.stopType);
        }
    }

    return null;
};

const getLaneStopLocationCityStateDisplay = (stop) => {
    if (isObjectNotEmpty(stop)) {
        if (isObjectNotEmpty(stop.location) && isObjectNotEmpty(stop.location.address) && isStringNotEmpty(stop.location.address.city) && isStringNotEmpty(stop.location.address.state)) {
            return `${stop.location.address.city}, ${stop.location.address.state}`;
        }
    }

    return null;
};

const getLaneStopLocationDisplay = (stop, shipperId, carrierId, entityType, entityId) => {
    if (isObjectNotEmpty(stop)) {
        let stopLocationDisplay = getLaneStopLocationCityStateDisplay(stop);
        if (isStringNotEmpty(stopLocationDisplay)) {
            return stopLocationDisplay;
        } else {
            return getLaneStopNameDisplay(stop, shipperId, carrierId, entityType, entityId);
        }
    }

    return null;
};

const getLaneStopAddressDisplay = (stop) => {
    if (isObjectNotEmpty(stop)) {
        if (isObjectNotEmpty(stop.location) && isObjectNotEmpty(stop.location.address)) {
            return stringFormatter.toFormattedAddress(stop.location.address);
        }
    }

    return null;
};

const getShipperId = (loadLane) => {
    if (isObjectNotEmpty(loadLane) && isStringNotEmpty(loadLane.accountId)) {
        return loadLane.accountId;
    }

    return null;
};

const getShipperName = (loadLane) => {
    if (isObjectNotEmpty(loadLane) && isObjectNotEmpty(loadLane.account) && isStringNotEmpty(loadLane.account.name)) {
        return loadLane.account.name;
    }

    return null;
};

export default {
    getLaneStopMarkerIcon,
    getLaneStopTypeDisplay,
    getLaneStopType,
    getLaneStopTimeZone,
    getLaneLocationInfoByLink,
    getLaneLocationNameByLink,
    getLaneStopLocationNameDisplay,
    getLaneStopNameDisplay,
    getLaneStopLocationCityStateDisplay,
    getLaneStopLocationDisplay,
    getLaneStopAddressDisplay,
    getShipperId,
    getShipperName
}