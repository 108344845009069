import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, withRouter } from 'react-router-dom';
import * as actionCreators from "../../store/actions/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPallet, faTruck, faTruckLoading, faDesktop, faRedoAlt, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import LoadsList from '../../components/LoadsList/LoadsList';
import { Col, Row, Tabs, Drawer, Dropdown, Button, Menu, Spin, Empty } from 'antd';
import LoadDetails from '../../components/LoadDetails/LoadDetails';
import AssignLoadCarrier from '../../components/AssignLoadCarrier/AssignLoadCarrier';
import { DownOutlined, InfoOutlined, UploadOutlined } from '@ant-design/icons';
import LoadRequests from '../LoadRequests/LoadRequests';
import CompletedLoads from '../CompletedLoads/CompletedLoads';
import InTransitLoads from '../InTransitLoads/InTransitLoads';
import BookedLoads from '../BookedLoads/BookedLoads';
import OpenLoads from '../OpenLoads/OpenLoads';
import NewLoadModal from '../../components/NewLoadModal/NewLoadModal';
import { isBooleanTrue, isListNotEmpty, isNumberGreaterThanZero, isObjectNotEmpty, isStringEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import LoadUtils from '../../api/utils/loadUtils';
import { CanDo, CanSee } from '../../shared/entitlements/entitlements';
import { selectListIsLoading, selectListRecords } from '../../store/utility';
import QueueCompletedLoads from '../../components/QueueCompletedLoads/QueueCompletedLoads';
import QueueLoads from '../../components/QueueLoads/QueueLoads';

const { TabPane } = Tabs;

const LoadList = ({ shipperId, carrierId, driverId, ...props }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    //#endregion
    //#region useLocation

    const location = useLocation();

    //#endregion
    //#region useStates

    const [tabKey, setTabKey] = useState('open');
    const [selectedRecord, setSelectedRecord] = useState({});
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showDuplicateEntity, setShowDuplicateEntity] = useState(false);
    const [showEntity, setShowEntity] = useState(false);
    const [showTableView, setShowTableView] = useState(true);
    const [currentStop, setCurrentStop] = useState(null);
    const [currentStopType, setCurrentStopType] = useState('Stop');
    const [isDeadHeading, setIsDeadHeading] = useState(false);
    const [currentStatus, setCurrentStatus] = useState('PENDING');
    const [displayStatus, setDisplayStatus] = useState('Pending');
    const [showAssignCarrier, setShowAssignCarrier] = useState(false);

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);
    const isLoading = useSelector(state => state.loads.isRecordLoading);
    const error = useSelector(state => state.loads.recordError);
    const load = useSelector(state => state.loads.record);

    const claims = useSelector(state => selectListRecords(state.claims.lists, isObjectNotEmpty(selectedRecord) && isStringNotEmpty(selectedRecord.id) ? selectedRecord.id : null));
    const isLoadingClaims = useSelector(state => selectListIsLoading(state.claims.lists, isObjectNotEmpty(selectedRecord) && isStringNotEmpty(selectedRecord.id) ? selectedRecord.id : null));
    const transactions = useSelector(state => selectListRecords(state.transactions.lists, isObjectNotEmpty(selectedRecord) && isStringNotEmpty(selectedRecord.id) ? selectedRecord.id : null));
    const isLoadingTransactions = useSelector(state => selectListIsLoading(state.transactions.lists, isObjectNotEmpty(selectedRecord) && isStringNotEmpty(selectedRecord.id) ? selectedRecord.id : null));
    const invoiceLineItems = useSelector(state => selectListRecords(state.invoiceLineItems.lists, isObjectNotEmpty(selectedRecord) && isStringNotEmpty(selectedRecord.id) ? selectedRecord.id : null));
    const isLoadingInvoiceLineItems = useSelector(state => selectListIsLoading(state.invoiceLineItems.lists, isObjectNotEmpty(selectedRecord) && isStringNotEmpty(selectedRecord.id) ? selectedRecord.id : null));
    const stops = useSelector(state => selectListRecords(state.loadStops.lists, isObjectNotEmpty(selectedRecord) && isStringNotEmpty(selectedRecord.id) ? selectedRecord.id : null));
    const isLoadingStops = useSelector(state => selectListIsLoading(state.loadStops.lists, isObjectNotEmpty(selectedRecord) && isStringNotEmpty(selectedRecord.id) ? selectedRecord.id : null));
    const loadCancellations = useSelector(state => selectListRecords(state.loadCancellations.lists, isObjectNotEmpty(selectedRecord) && isStringNotEmpty(selectedRecord.id) ? selectedRecord.id : null));
    const isLoadingLoadCancellations = useSelector(state => selectListIsLoading(state.loadCancellations.lists, isObjectNotEmpty(selectedRecord) && isStringNotEmpty(selectedRecord.id) ? selectedRecord.id : null));
    const carrierRejections = useSelector(state => selectListRecords(state.carrierRejections.lists, isObjectNotEmpty(selectedRecord) && isStringNotEmpty(selectedRecord.id) ? selectedRecord.id : null));
    const isLoadingCarrierRejections = useSelector(state => selectListIsLoading(state.carrierRejections.lists, isObjectNotEmpty(selectedRecord) && isStringNotEmpty(selectedRecord.id) ? selectedRecord.id : null));
    const invoices = useSelector(state => selectListRecords(state.invoices.lists, isObjectNotEmpty(selectedRecord) && isStringNotEmpty(selectedRecord.id) ? selectedRecord.id : null));
    const isLoadingInvoices = useSelector(state => selectListIsLoading(state.invoices.lists, isObjectNotEmpty(selectedRecord) && isStringNotEmpty(selectedRecord.id) ? selectedRecord.id : null));

    //#endregion
    //#region goTos

    const goToLoad = (load) => {
        if (isObjectNotEmpty(load)) {
            if (isBooleanTrue(load.isParentLoad) && (isNumberGreaterThanZero(load.childLoadInitCount) || isListNotEmpty(load.childLoads))) {
                props.history.push({ pathname: `/loadBundle/${load.id}` }, {
                    previousPageTitle: isStringNotEmpty(shipperId) || isStringNotEmpty(carrierId) ? 'Loads' : 'All Loads',
                    previousPageLocation: props.location,
                    previousBreadcrumbs: props.breadcrumbs,
                });
            } else {
                props.history.push({ pathname: `/loads/${load.id}` }, {
                    previousPageTitle: isStringNotEmpty(shipperId) || isStringNotEmpty(carrierId) ? 'Loads' : 'All Loads',
                    previousPageLocation: props.location,
                    previousBreadcrumbs: props.breadcrumbs,
                });
            }
        }
    };

    const goToNoAccess = () => {
        props.history.replace({ pathname: "/noaccess/" });
    };

    //#endregion
    //#region toggles

    const openShowEntity = () => {
        setShowEntity(true);
    };

    const closeShowEntity = () => {
        setShowEntity(false);
    };

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleDuplicateEntity = () => {
        setShowDuplicateEntity(!showDuplicateEntity);
    };

    const toggleAssignCarrier = () => {
        setShowAssignCarrier(!showAssignCarrier);
    };

    const toggleToListView = () => {
        setShowTableView(false);
    };

    const toggleToTableView = () => {
        setShowTableView(true);
    };

    //#endregion
    //#region refreshes

    const refreshLoad = () => {
        if (isObjectNotEmpty(load)) {
            dispatch(actionCreators.fetchLoadWithIncludes(load.id));
        }
    };

    //#endregion
    //#region tab methods

    const onChangeTab = (key) => {
        props.history.replace({ ...props.location, hash: `#${key}` });

        closeShowEntity();
        setSelectedRecord({});
    };

    //#endregion
    //#region onChanges

    const onChangeCurrentStop = (stop) => {
        setCurrentStop(stop);
    };

    //#endregion
    //#region displays

    const menu = (record) => {
        return (
            <Menu>
                {(isObjectNotEmpty(record) && isStringEmpty(record.assignedCarrierId) && LoadUtils.loadStatusIsNot(currentStatus, ['CANCELLED']) && CanDo({ entityAction: 'CREATE', entityModel: 'LOAD_CARRIER', entityObject: record })) &&
                    <Menu.Item key="assignCarrier" onClick={(e) => { e.domEvent.stopPropagation(); e.domEvent.preventDefault(); if (load && load.id === record.id) { openShowEntity(); } else { closeShowEntity(); } setSelectedRecord(record); toggleAssignCarrier(); }}>
                        <FontAwesomeIcon className="anticon" icon={faTruck} style={{ marginRight: 8 }} />
                        <span>Assign Carrier</span>
                    </Menu.Item>
                }
                {CanDo({ entityAction: 'DUPLICATE', entityModel: 'LOAD', entityObject: record }) &&
                    <Menu.Item key="duplicateLoad" onClick={(e) => { e.domEvent.stopPropagation(); e.domEvent.preventDefault(); if (load && load.id === record.id) { openShowEntity(); } else { closeShowEntity(); } setSelectedRecord(record); toggleDuplicateEntity(); }}>
                        <UploadOutlined style={{ marginRight: 8 }} />
                        <span>Duplicate Load</span>
                    </Menu.Item>
                }
                {CanDo({ entityAction: 'READ', entityModel: 'LOAD', entityObject: record }) &&
                    <Menu.Item key="goToDashboard" onClick={(e) => { e.domEvent.stopPropagation(); e.domEvent.preventDefault(); goToLoad(record); }}>
                        <InfoOutlined style={{ marginRight: 8 }} />
                        <span>Go To Load Dashboard</span>
                    </Menu.Item>
                }
            </Menu>
        );
    };

    const loadExtras = (record) => {
        return (
            <Dropdown overlay={menu(record)}>
                <Button style={{ border: 0 }} type="link" onClick={(e) => { e.stopPropagation(); e.preventDefault(); }} icon={<DownOutlined />} />
            </Dropdown>
        );
    };

    const getStatus = (load, stops, loadId, entityType) => {
        const res = LoadUtils.getLoadStatus(load, stops, loadId, entityType);
        if (isObjectNotEmpty(res)) {
            onChangeCurrentStop(res.currentStop);
            setIsDeadHeading(res.isDeadHeading);
            setCurrentStopType(res.currentStopType);
            setCurrentStatus(res.currentStatus);
            setDisplayStatus(res.displayStatus);
        }
    };

    const actions = () => {
        return (
            <>
                {isObjectNotEmpty(load) && isStringNotEmpty(load.id) ? (
                    <CanSee entityAction="READ" entityModel="LOAD" entityObject={load}>
                        <a href={`/track/${load.id}`} target='_blank' style={{ textDecoration: 'underline', marginRight: 12 }}>Shipper Tracker <FontAwesomeIcon className="anticon" icon={faExternalLinkAlt} /></a>
                    </CanSee>
                ) : null}
                <a onClick={refreshLoad}><FontAwesomeIcon className="anticon" icon={faRedoAlt} /></a>
            </>
        );
    };

    const assignCarrierComponent = (
        <Drawer
            title={"Assign Carrier to Load"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleAssignCarrier}
            visible={showAssignCarrier === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <AssignLoadCarrier
                cancel={toggleAssignCarrier}
                load={selectedRecord}
                loadId={isObjectNotEmpty(selectedRecord) && isStringNotEmpty(selectedRecord.id) ? selectedRecord.id : null}
                stops={stops}
                invoices={invoices}
                invoiceLineItems={invoiceLineItems}
            />
        </Drawer>
    );

    //#endregion
    //#region useMemos and useEffects

    useMemo(() => {
        if (showEntity === true && isObjectNotEmpty(selectedRecord) && isStringNotEmpty(selectedRecord.id)) {
            dispatch(actionCreators.fetchLoadWithIncludes(selectedRecord.id));
            if (selectedRecord.serviceType === 'BROKERAGE') {
                dispatch(actionCreators.fetchClaimList(selectedRecord.id, { page: 1, size: 1000000, loadId: selectedRecord.id, isDeleted: false }));
            }
            dispatch(actionCreators.fetchLoadStopList(selectedRecord.id, selectedRecord));
            dispatch(actionCreators.fetchInvoiceList(selectedRecord.id, { page: 1, size: 1000000, loadId: selectedRecord.id, isDeleted: false }));
            dispatch(actionCreators.fetchInvoiceLineItemList(selectedRecord.id, { page: 1, size: 1000000, loadId: selectedRecord.id, isDeleted: false }));
            dispatch(actionCreators.fetchTransactionList(selectedRecord.id, { page: 1, size: 1000000, loadId: selectedRecord.id, isDeleted: false }));
            dispatch(actionCreators.fetchLoadCancellationList(selectedRecord.id, { page: 1, size: 1000000, loadId: selectedRecord.id, isDeleted: false }));
            dispatch(actionCreators.fetchCarrierRejectionList(selectedRecord.id, { page: 1, size: 1000000, loadId: selectedRecord.id, isDeleted: false }));
        }
    }, [showEntity, selectedRecord]);

    useMemo(() => {
        if (showEntity === true && isObjectNotEmpty(selectedRecord)) {
            let hasAccess = LoadUtils.hasAccessToLoad(selectedRecord.shipperId, selectedRecord.assignedCarrierId, entityType, entityId);
            if (hasAccess === false) {
                goToNoAccess();
            }
        }
    }, [showEntity, selectedRecord, entityType, entityId]);

    useEffect(() => {
        if (showEntity === true && isObjectNotEmpty(load) && isObjectNotEmpty(selectedRecord) && isListNotEmpty(stops)) {
            getStatus(load, stops, selectedRecord.id, entityType);
        }
    }, [showEntity, load, currentStop, stops, selectedRecord, entityType]);

    useMemo(() => {
        if (isStringNotEmpty(location.hash)) {
            setTabKey(location.hash.replace('#', ''));
        } else {
            setTabKey('open');
            props.history.replace({ ...location, hash: `#open` });
        }
    }, [location]);

    //#endregion

    return (
        <>
            <Row>
                <Col span={24}>
                    <Tabs
                        activeKey={tabKey}
                        onChange={onChangeTab}
                        destroyInactiveTabPane={true}
                        tabBarStyle={{ backgroundColor: '#ffffff', paddingRight: 24, paddingLeft: 24, margin: 0 }}
                    >
                        <TabPane tab="Load Requests" key="loadrequest">
                            <div style={{ padding: 24 }}>
                                {showTableView === false ?
                                    <LoadsList
                                        filter={"loadrequest"}
                                        showStatus={false}
                                        showStatusToStaff={false}
                                        showCompletedDate={false}
                                        title="Load Requests"
                                        icon={<FontAwesomeIcon className="anticon" icon={faDesktop} />}
                                        selectedRecord={selectedRecord}
                                        setSelectedRecord={setSelectedRecord}
                                        openShowEntity={openShowEntity}
                                        closeShowEntity={closeShowEntity}
                                        showEntity={showEntity}
                                        toggleAssignCarrier={toggleAssignCarrier}
                                        loadExtras={loadExtras}
                                        carrierId={carrierId}
                                        shipperId={shipperId}
                                        driverId={driverId}
                                    /> : <LoadRequests carrierId={carrierId} shipperId={shipperId} driverId={driverId} />
                                }
                            </div>
                        </TabPane>
                        <TabPane tab="Open Loads" key="open">
                            <div style={{ padding: 24 }}>
                                {showTableView === false ?
                                    <LoadsList
                                        filter={"open"}
                                        showStatus={false}
                                        showStatusToStaff={false}
                                        showCompletedDate={false}
                                        title="Open"
                                        icon={<FontAwesomeIcon className="anticon" icon={faDesktop} />}
                                        selectedRecord={selectedRecord}
                                        setSelectedRecord={setSelectedRecord}
                                        openShowEntity={openShowEntity}
                                        closeShowEntity={closeShowEntity}
                                        showEntity={showEntity}
                                        toggleAssignCarrier={toggleAssignCarrier}
                                        loadExtras={loadExtras}
                                        carrierId={carrierId}
                                        shipperId={shipperId}
                                        driverId={driverId}
                                    /> : <OpenLoads carrierId={carrierId} shipperId={shipperId} driverId={driverId} />
                                }
                            </div>
                        </TabPane>
                        <TabPane tab="Booked Loads" key="booked">
                            <div style={{ padding: 24 }}>
                                {showTableView === false ?
                                    <LoadsList
                                        filter={"booked"}
                                        showStatus={false}
                                        showStatusToStaff={false}
                                        showCompletedDate={false}
                                        title="Booked"
                                        icon={<FontAwesomeIcon className="anticon" icon={faTruckLoading} />}
                                        selectedRecord={selectedRecord}
                                        setSelectedRecord={setSelectedRecord}
                                        openShowEntity={openShowEntity}
                                        closeShowEntity={closeShowEntity}
                                        showEntity={showEntity}
                                        toggleAssignCarrier={toggleAssignCarrier}
                                        loadExtras={loadExtras}
                                        carrierId={carrierId}
                                        shipperId={shipperId}
                                        driverId={driverId}
                                    /> : <BookedLoads carrierId={carrierId} shipperId={shipperId} driverId={driverId} />
                                }
                            </div>
                        </TabPane>
                        <TabPane tab="In Transit Loads" key="intransit">
                            <div style={{ padding: 24 }}>
                                {showTableView === false ?
                                    <LoadsList
                                        filter={"intransit"}
                                        showStatus={true}
                                        showStatusToStaff={false}
                                        showCompletedDate={false}
                                        title="In Transit"
                                        icon={<FontAwesomeIcon className="anticon" icon={faTruck} />}
                                        selectedRecord={selectedRecord}
                                        setSelectedRecord={setSelectedRecord}
                                        openShowEntity={openShowEntity}
                                        closeShowEntity={closeShowEntity}
                                        showEntity={showEntity}
                                        toggleAssignCarrier={toggleAssignCarrier}
                                        loadExtras={loadExtras}
                                        carrierId={carrierId}
                                        shipperId={shipperId}
                                        driverId={driverId}
                                    /> : <InTransitLoads carrierId={carrierId} shipperId={shipperId} driverId={driverId} />
                                }
                            </div>
                        </TabPane>
                        <TabPane tab="Completed Loads" key="completed">
                            <div style={{ padding: 24 }}>
                                {showTableView === false ?
                                    <LoadsList
                                        filter={"completed"}
                                        showStatus={true}
                                        showStatusToStaff={true}
                                        showCompletedDate={true}
                                        title="Completed"
                                        icon={<FontAwesomeIcon className="anticon" icon={faPallet} />}
                                        selectedRecord={selectedRecord}
                                        setSelectedRecord={setSelectedRecord}
                                        openShowEntity={openShowEntity}
                                        closeShowEntity={closeShowEntity}
                                        showEntity={showEntity}
                                        toggleAssignCarrier={toggleAssignCarrier}
                                        loadExtras={loadExtras}
                                        carrierId={carrierId}
                                        shipperId={shipperId}
                                        driverId={driverId}
                                    /> : (
                                        <QueueLoads
                                            title="Manage Completed Loads"
                                            filter={`COMPLETED_LOADS`}
                                            filterSearchParams={{
                                                sort: 'loadEndDateTime',
                                                order: 'desc',
                                                isDeleted: false,
                                                eta: false
                                            }}
                                            defaultLoadStatusFilter={['COMPLETED', 'REOPENED', 'CLOSED', 'CANCELLED']}
                                            showTable={true}
                                            carrierId={carrierId}
                                            shipperId={shipperId}
                                            driverId={driverId}
                                            showCompletedLoadData={true}
                                            showAccountsPayable={CanDo({ staffOnly: true })}
                                            showAccountsReceivable={CanDo({ staffOnly: true })}
                                        />
                                    )}
                            </div>
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true && error === null) || isLoadingCarrierRejections === true || isLoadingClaims === true || isLoadingInvoiceLineItems === true || isLoadingInvoices === true || isLoadingLoadCancellations === true || isLoadingStops === true || isLoadingTransactions === true}>
                        {(showEntity === true && isObjectNotEmpty(load) && isStringNotEmpty(load.id) && isObjectNotEmpty(selectedRecord) && isStringNotEmpty(selectedRecord.id) && selectedRecord.id === load.id) ? (
                            <div style={{ paddingLeft: 24, paddingRight: 24 }}>
                                <LoadDetails
                                    loadId={load.id}
                                    load={load}
                                    stops={stops}
                                    claims={claims}
                                    invoices={invoices}
                                    invoiceLineItems={invoiceLineItems}
                                    transactions={transactions}
                                    loadCancellations={loadCancellations}
                                    carrierRejections={carrierRejections}
                                    isDeadHeading={isDeadHeading}
                                    currentStatus={currentStatus}
                                    displayStatus={displayStatus}
                                    currentStop={currentStop}
                                    currentStopType={currentStopType}
                                    shipperId={LoadUtils.getShipperId(load)}
                                    carrierId={LoadUtils.getCarrierId(load)}
                                    serviceType={LoadUtils.getServiceType(load)}
                                    hasTeamDriving={LoadUtils.hasTeamDriving(load)}
                                    hasCarrierWashout={LoadUtils.hasCarrierWashout(load)}
                                />
                            </div>
                        ) : (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Please select a Load above to see more details." />
                        )}
                    </Spin>
                </Col>
            </Row>
            <NewLoadModal key='new-load' showModal={showNewEntity} toggleModal={toggleNewEntity} shipperId={isStringNotEmpty(shipperId) ? shipperId : null} />
            <NewLoadModal key='duplicate-load' showModal={showDuplicateEntity} toggleModal={() => { toggleDuplicateEntity(); setSelectedRecord({}); }} shipperId={isObjectNotEmpty(selectedRecord) && isStringNotEmpty(selectedRecord.shipperId) ? selectedRecord.shipperId : null} duplicateLoadId={isObjectNotEmpty(selectedRecord) && isStringNotEmpty(selectedRecord.id) ? selectedRecord.id : null} />
            {assignCarrierComponent}
        </>
    );
};

export default withRouter(LoadList);