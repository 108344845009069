import React from 'react';
import classes from './DetailsListHeaderActionRow.module.scss';
import { Row, Col } from 'antd';

const DetailsListHeaderActionRow = ({ colSpan = 1, label, extra }) => {
    return (
        <tr className={classes.dataRow}>
            <th className={classes.dataRowContent} colSpan={colSpan}>
                <Row gutter={[4, 4]} style={{ display: 'flex', alignItems: 'center' }}>
                    <Col xs={{ span: 12 }}>
                        <b>{label}</b>
                    </Col>
                    <Col xs={{ span: 12 }} style={{ textAlign: 'right' }}>
                        {extra}
                    </Col>
                </Row>
            </th>
        </tr>
    );
};

export default DetailsListHeaderActionRow;