import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { CheckOutlined, ControlOutlined, DownOutlined } from '@ant-design/icons';
import { Drawer, Button, Dropdown, Menu, Modal } from 'antd';
import Document from "../../components/Document/Document";
import EditDocument from "../../components/EditDocument/EditDocument";
import NewDocument from "../../components/NewDocument/NewDocument";
import ReviewDocument from "../../components/ReviewDocument/ReviewDocument";
import RemoveDocument from "../../components/RemoveDocument/RemoveDocument";
import classes from './DocumentList.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faTools } from '@fortawesome/free-solid-svg-icons';
import DataTableTitle from '../../components/DataTableTitle/DataTableTitle';
import StringFormatter from '../../shared/stringFormatter';
import DataTable from '../DataTable/DataTable';
import DocumentUtils from '../../api/utils/documentUtils';
import { isListNotEmpty, isNotNullOrUndefined, isNullOrUndefined, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { selectListIsLoading, selectListRecords } from '../../store/utility';

const stringFormatter = new StringFormatter();

const DocumentList = ({ documentEntityType, documentEntityId, documentEntitySubType, account, accountUser, load, loadId, needsCarrierWashoutLog = false, needsLumperFeeReceipt = false, tableTitle, updatePendingApprovalCount, updateMissingDocumentsCount, updateExpiringSoonDocumentsCount, updateExpiredDocumentsCount, style = {} }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;
    const singularEntityName = "Document";
    const pluralEntityName = "Documents";

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isAdmin = useSelector(state => state.auth.isAdmin);
    const userEntityType = useSelector(state => state.auth.entityType);
    const userEntityId = useSelector(state => state.auth.entityId);
    const entityDocuments = useSelector(state => selectListRecords(state.documents.lists, documentEntityId));
    const isLoading = useSelector(state => selectListIsLoading(state.documents.lists, documentEntityId));

    //#endregion
    //#region useStates

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showReviewEntity, setShowReviewEntity] = useState(false);
    const [showRemoveEntity, setShowRemoveEntity] = useState(false);
    const [showViewEntity, setShowViewEntity] = useState(false);
    const [pendingApproval, setPendingApproval] = useState([]);
    const [missingDocuments, setMissingDocuments] = useState([]);
    const [expiringSoonDocuments, setExpiringSoonDocuments] = useState([]);
    const [expiredDocuments, setExpiredDocuments] = useState([]);
    const [visibleDocuments, setVisibleDocuments] = useState([]);

    //#endregion
    //#region table displays

    const menu = (record) => {
        return (
            <Menu>
                <Menu.Item key="viewDocument" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); toggleViewEntity(); }}>
                    <FontAwesomeIcon className="anticon" icon={faFileAlt} style={{ marginRight: 8 }} />
                    <span>View {singularEntityName}</span>
                </Menu.Item>
                {((userEntityType === "STAFF" ||
                    (isStringNotEmpty(documentEntityType) && documentEntityType === "DRIVER" && (userEntityType === "STAFF" || userEntityType === "CARRIER" || userEntityType === "BROKER")) ||
                    (isStringNotEmpty(documentEntityType) && documentEntityType === "ASSET" && (userEntityType === "STAFF" || userEntityType === "CARRIER" || userEntityType === "BROKER")) ||
                    (isObjectNotEmpty(load) && load.createdByEntityType === userEntityType && load.serviceType === "TMS")) && DocumentUtils.reviewableDocumentTypes.includes(record.documentType) && record.isReviewed === false) ? (
                    <Menu.Item key="reviewDocument" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); toggleReviewEntity(); }}>
                        <CheckOutlined style={{ marginRight: 8 }} />
                        <span>Review {singularEntityName}</span>
                    </Menu.Item>
                ) : null}
                {(userEntityType === "STAFF" ||
                    (isStringNotEmpty(documentEntityType) && documentEntityType === "DRIVER" && (userEntityType === "STAFF" || userEntityType === "CARRIER" || userEntityType === "BROKER")) ||
                    (isStringNotEmpty(documentEntityType) && documentEntityType === "ASSET" && (userEntityType === "STAFF" || userEntityType === "CARRIER" || userEntityType === "BROKER")) ||
                    (isObjectNotEmpty(load) && load.createdByEntityType === userEntityType && load.serviceType === "TMS")
                ) ? (
                    <Menu.Item key="editDocument" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); toggleEditEntity(); }}>
                        <FontAwesomeIcon className="anticon" icon={faFileAlt} style={{ marginRight: 8 }} />
                        <span>Edit {singularEntityName} Details</span>
                    </Menu.Item>
                ) : null}
                {(isAdmin === true) ? (
                    <Menu.Item key="deleteDocument" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); toggleRemoveEntity(); }}>
                        <FontAwesomeIcon className="anticon" icon={faFileAlt} style={{ marginRight: 8 }} />
                        <span>Delete {singularEntityName}</span>
                    </Menu.Item>
                ) : null}
            </Menu>
        );
    };

    const columns = [
        {
            title: 'Document Type',
            dataIndex: 'documentType',
            key: 'documentType',
            render: (text, record) => { return stringFormatter.toFormattedString("DocumentType", text, null); },
        },
        {
            title: 'File Name',
            dataIndex: 'displayName',
            key: 'displayName',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        }
    ];

    if (userEntityType === "STAFF" ||
        (isStringNotEmpty(documentEntityType) && documentEntityType === "DRIVER" && (userEntityType === "STAFF" || userEntityType === "CARRIER" || userEntityType === "BROKER")) ||
        (isStringNotEmpty(documentEntityType) && documentEntityType === "ACCOUNT" && (userEntityType === "STAFF" || userEntityType === "SHIPPER" || userEntityType === "CARRIER" || userEntityType === "PRODUCER" || userEntityType === "RECEIVER" || userEntityType === "FACTORING" || userEntityType === "BROKER") && userEntityId === documentEntityId) ||
        (isStringNotEmpty(documentEntityType) && documentEntityType === "ASSET" && (userEntityType === "STAFF" || userEntityType === "CARRIER" || userEntityType === "BROKER")) ||
        (isObjectNotEmpty(load) && load.createdByEntityType === userEntityType && load.serviceType === "TMS")) {
        columns.unshift({
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            width: 100,
            render: (text, record) => {
                return (
                    <Dropdown overlay={menu(record)} trigger={['click']}>
                        <Button onClick={(e) => { e.stopPropagation(); }}><ControlOutlined /> <DownOutlined /></Button>
                    </Dropdown>
                );
            },
            align: 'center',
        });

        columns.push({
            title: 'Is Reviewed',
            dataIndex: 'isReviewed',
            key: 'isReviewed',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        });
        columns.push({
            title: 'Review Status',
            dataIndex: 'reviewStatus',
            key: 'reviewStatus',
            render: (text, record) => { return stringFormatter.toFormattedString("DocumentReviewStatus", text, null); },
        });
        columns.push({
            title: 'Reviewed At',
            dataIndex: 'reviewedAt',
            key: 'reviewedAt',
            render: (text, record) => { return stringFormatter.toFormattedString("MomentDateTime", text, null); },
        });
        columns.push({
            title: 'Reviewed By',
            dataIndex: 'reviewedByUser',
            key: 'reviewedByUser',
            render: (text, record) => { return stringFormatter.toFirstNameLastName(text); },
        });
        columns.push({
            title: 'Visbility',
            dataIndex: 'visibleTo',
            key: 'visibleTo',
            render: (text, record) => { return stringFormatter.toFormattedString("DocumentVisibility", text, null); },
        });
    }

    if (isNullOrUndefined(documentEntityType)) {
        columns.push({
            title: 'Is Expiring Soon',
            dataIndex: 'isExpiringSoon',
            key: 'isExpiringSoon',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        });
        columns.push({
            title: 'Is Expired',
            dataIndex: 'isExpired',
            key: 'isExpired',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        });
        columns.push({
            title: 'Entity Type',
            dataIndex: 'entityType',
            key: 'entityType',
            render: (text, record) => { return stringFormatter.toFormattedString("DocumentEntityType", text, null); },
        });
        columns.push({
            title: 'Entity',
            dataIndex: ['entity', 'name'],
            key: 'entity.name',
        });
    } else if (documentEntityType === 'DRIVER') {
        columns.push({
            title: 'License Number',
            dataIndex: 'licenseNumber',
            key: 'licenseNumber',
        });
        columns.push({
            title: 'Expiration Date',
            dataIndex: 'expiryDate',
            key: 'expiryDate',
            render: (text, record) => { return stringFormatter.toFormattedString("MomentDate", text, null); },
        });
        columns.push({
            title: 'Is Expiring Soon',
            dataIndex: 'isExpiringSoon',
            key: 'isExpiringSoon',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        });
        columns.push({
            title: 'Is Expired',
            dataIndex: 'isExpired',
            key: 'isExpired',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        });
    } else if (documentEntityType === 'ACCOUNT_CARRIER') {
        columns.push({
            title: 'Expiration Date',
            dataIndex: 'expiryDate',
            key: 'expiryDate',
            render: (text, record) => { return stringFormatter.toFormattedString("MomentDate", text, null); },
        });
        columns.push({
            title: 'Is Expiring Soon',
            dataIndex: 'isExpiringSoon',
            key: 'isExpiringSoon',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        });
        columns.push({
            title: 'Is Expired',
            dataIndex: 'isExpired',
            key: 'isExpired',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        });
    }

    //#endregion
    //#region toggles

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleReviewEntity = () => {
        setShowReviewEntity(!showReviewEntity);
    };

    const toggleRemoveEntity = () => {
        setShowRemoveEntity(!showRemoveEntity);
    };

    const toggleViewEntity = () => {
        setShowViewEntity(!showViewEntity);
    };

    //#endregion
    //#region displays

    const newEntityComponents = (
        <Drawer
            title={"New " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleNewEntity}
            visible={showNewEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewDocument
                cancel={toggleNewEntity}
                documentEntityType={documentEntityType}
                documentEntitySubType={documentEntitySubType}
                documentEntityId={documentEntityId}
                loadId={loadId}
                account={account}
            />
        </Drawer>
    );

    const reviewEntityComponents = (
        <Drawer
            title={"Review " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleReviewEntity}
            visible={showReviewEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <ReviewDocument
                cancel={toggleReviewEntity}
                document={selectedRecord}
                documentType={selectedRecord.documentType}
                documentEntitySubType={documentEntitySubType}
                loadId={loadId}
                fileId={selectedRecord.id}
            />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName + " Details"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditEntity}
            visible={showEditEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditDocument
                cancel={toggleEditEntity}
                document={selectedRecord}
            />
        </Drawer>
    );

    const removeEntityComponents = (
        <Drawer
            title={"Delete " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleRemoveEntity}
            visible={showRemoveEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <RemoveDocument
                cancel={toggleRemoveEntity}
                document={selectedRecord}
            />
        </Drawer>
    );

    const viewEntityComponents = (
        <Modal
            title={(selectedRecord && selectedRecord.displayName) ? selectedRecord.displayName : 'File'}
            visible={showViewEntity === true}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleViewEntity}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <Document
                fileId={selectedRecord ? selectedRecord.id : null}
                displayName={selectedRecord ? selectedRecord.displayName : null}
            />
        </Modal>
    );

    const createMissingDocumentList = () => {
        if (isListNotEmpty(missingDocuments)) {
            return (
                <div>
                    <b>Missing Documents ({missingDocuments.length}):</b> {missingDocuments.join(', ')}
                </div>
            );
        } else {
            return null;
        }
    };

    const createPendingApprovalList = () => {
        if (isListNotEmpty(pendingApproval)) {
            return (
                <div>
                    <b>Documents Pending Review ({pendingApproval.length}):</b> {pendingApproval.map(doc => doc.displayName).join(', ')}
                </div>
            );
        } else {
            return null;
        }
    };

    const createExpiringSoonDocumentList = () => {
        if (isListNotEmpty(expiringSoonDocuments)) {
            return (
                <div>
                    <b>The following Documents are expiring within the next 30 days ({expiringSoonDocuments.length}):</b> {expiringSoonDocuments.map(doc => doc.displayName).join(', ')}
                </div>
            );
        } else {
            return null;
        }
    };

    const createExpiredDocumentList = () => {
        if (isListNotEmpty(expiredDocuments)) {
            return (
                <div>
                    <b>Expired Documents ({expiredDocuments.length}):</b> {expiredDocuments.map(doc => doc.displayName).join(', ')}
                </div>
            );
        } else {
            return null;
        }
    };

    //#endregion
    //#region document count methods

    const getPendingApprovalCount = () => {
        let count = 0;
        if (isNotNullOrUndefined(pendingApproval)) {
            count = pendingApproval.length;
        }

        if (isNotNullOrUndefined(updatePendingApprovalCount)) {
            updatePendingApprovalCount(count);
        }

        if (isObjectNotEmpty(account)) {
            let updatedAccount = { ...account, documentPendingApprovalCount: count };
            if (updatedAccount.entityTypes.includes("SHIPPER")) {
                dispatch(actionCreators.updateAccountInAccountList('SHIPPERS', updatedAccount));
            }
            if (updatedAccount.entityTypes.includes("CARRIER")) {
                dispatch(actionCreators.updateAccountInAccountList('CARRIERS', updatedAccount));
            }
            if (updatedAccount.entityTypes.includes("PRODUCER")) {
                dispatch(actionCreators.updateAccountInAccountList('PRODUCERS', updatedAccount));
            }
            if (updatedAccount.entityTypes.includes("RECEIVER")) {
                dispatch(actionCreators.updateAccountInAccountList('RECEIVERS', updatedAccount));
            }
            if (updatedAccount.entityTypes.includes("BROKER")) {
                dispatch(actionCreators.updateAccountInAccountList('BROKERS', updatedAccount));
            }
            if (updatedAccount.entityTypes.includes("FACTORING")) {
                dispatch(actionCreators.updateAccountInAccountList('FACTORINGS', updatedAccount));
            }
        }

        if (isObjectNotEmpty(accountUser) && isObjectNotEmpty(accountUser.driver)) {
            let updatedAccountUser = { ...accountUser };
            updatedAccountUser.driver.documentPendingApprovalCount = count;
            dispatch(actionCreators.updateAccountUserInAccountUserList(updatedAccountUser.accountId, updatedAccountUser));
        }

        return count;
    };

    const getMissingDocumentCount = () => {
        let count = 0;
        if (isNotNullOrUndefined(missingDocuments)) {
            count = missingDocuments.length;
        }

        if (isNotNullOrUndefined(updateMissingDocumentsCount)) {
            updateMissingDocumentsCount(count);
        }

        if (isObjectNotEmpty(account)) {
            let updatedAccount = { ...account, documentMissingCount: count };
            if (updatedAccount.entityTypes.includes("SHIPPER")) {
                dispatch(actionCreators.updateAccountInAccountList('SHIPPERS', updatedAccount));
            }
            if (updatedAccount.entityTypes.includes("CARRIER")) {
                dispatch(actionCreators.updateAccountInAccountList('CARRIERS', updatedAccount));
            }
            if (updatedAccount.entityTypes.includes("PRODUCER")) {
                dispatch(actionCreators.updateAccountInAccountList('PRODUCERS', updatedAccount));
            }
            if (updatedAccount.entityTypes.includes("RECEIVER")) {
                dispatch(actionCreators.updateAccountInAccountList('RECEIVERS', updatedAccount));
            }
            if (updatedAccount.entityTypes.includes("BROKER")) {
                dispatch(actionCreators.updateAccountInAccountList('BROKERS', updatedAccount));
            }
            if (updatedAccount.entityTypes.includes("FACTORING")) {
                dispatch(actionCreators.updateAccountInAccountList('FACTORINGS', updatedAccount));
            }
        }

        if (isObjectNotEmpty(accountUser) && isObjectNotEmpty(accountUser.driver)) {
            let updatedAccountUser = { ...accountUser };
            updatedAccountUser.driver.documentMissingCount = count;
            dispatch(actionCreators.updateAccountUserInAccountUserList(updatedAccountUser.accountId, updatedAccountUser));
        }

        return count;
    };

    const getExpiringSoonDocumentCount = () => {
        let count = 0;
        if (isNotNullOrUndefined(expiringSoonDocuments)) {
            count = expiringSoonDocuments.length;
        }

        if (isNotNullOrUndefined(updateExpiringSoonDocumentsCount)) {
            updateExpiringSoonDocumentsCount(count);
        }

        if (isObjectNotEmpty(account)) {
            let updatedAccount = { ...account, documentExpiringSoonCount: count };
            if (updatedAccount.entityTypes.includes("SHIPPER")) {
                dispatch(actionCreators.updateAccountInAccountList('SHIPPERS', updatedAccount));
            }
            if (updatedAccount.entityTypes.includes("CARRIER")) {
                dispatch(actionCreators.updateAccountInAccountList('CARRIERS', updatedAccount));
            }
            if (updatedAccount.entityTypes.includes("PRODUCER")) {
                dispatch(actionCreators.updateAccountInAccountList('PRODUCERS', updatedAccount));
            }
            if (updatedAccount.entityTypes.includes("RECEIVER")) {
                dispatch(actionCreators.updateAccountInAccountList('RECEIVERS', updatedAccount));
            }
            if (updatedAccount.entityTypes.includes("BROKER")) {
                dispatch(actionCreators.updateAccountInAccountList('BROKERS', updatedAccount));
            }
            if (updatedAccount.entityTypes.includes("FACTORING")) {
                dispatch(actionCreators.updateAccountInAccountList('FACTORINGS', updatedAccount));
            }
        }

        if (isObjectNotEmpty(accountUser) && isObjectNotEmpty(accountUser.driver)) {
            let updatedAccountUser = { ...accountUser };
            updatedAccountUser.driver.documentExpiringSoonCount = count;
            dispatch(actionCreators.updateAccountUserInAccountUserList(updatedAccountUser.accountId, updatedAccountUser));
        }

        return count;
    };

    const getExpiredDocumentCount = () => {
        let count = 0;
        if (isNotNullOrUndefined(expiredDocuments)) {
            count = expiredDocuments.length;
        }

        if (isNotNullOrUndefined(updateExpiredDocumentsCount)) {
            updateExpiredDocumentsCount(count);
        }

        if (isObjectNotEmpty(account)) {
            let updatedAccount = { ...account, documentExpiredCount: count };
            if (updatedAccount.entityTypes.includes("SHIPPER")) {
                dispatch(actionCreators.updateAccountInAccountList('SHIPPERS', updatedAccount));
            }
            if (updatedAccount.entityTypes.includes("CARRIER")) {
                dispatch(actionCreators.updateAccountInAccountList('CARRIERS', updatedAccount));
            }
            if (updatedAccount.entityTypes.includes("PRODUCER")) {
                dispatch(actionCreators.updateAccountInAccountList('PRODUCERS', updatedAccount));
            }
            if (updatedAccount.entityTypes.includes("RECEIVER")) {
                dispatch(actionCreators.updateAccountInAccountList('RECEIVERS', updatedAccount));
            }
            if (updatedAccount.entityTypes.includes("BROKER")) {
                dispatch(actionCreators.updateAccountInAccountList('BROKERS', updatedAccount));
            }
            if (updatedAccount.entityTypes.includes("FACTORING")) {
                dispatch(actionCreators.updateAccountInAccountList('FACTORINGS', updatedAccount));
            }
        }

        if (isObjectNotEmpty(accountUser) && isObjectNotEmpty(accountUser.driver)) {
            let updatedAccountUser = { ...accountUser };
            updatedAccountUser.driver.documentExpiredCount = count;
            dispatch(actionCreators.updateAccountUserInAccountUserList(updatedAccountUser.accountId, updatedAccountUser));
        }

        return count;
    };

    //#endregion
    //#region useMemos and useEffects

    useMemo(() => {
        let listName = "ALL";
        if (isStringNotEmpty(documentEntityId)) {
            listName = documentEntityId;
        }

        dispatch(actionCreators.fetchBulkDocumentList(listName, documentEntityId));
    }, [documentEntityId]);

    useEffect(() => {
        //console.log(`entityDocuments: ${JSON.stringify(entityDocuments)}`);
        let filteredDocuments = DocumentUtils.getVisibleDocuments(entityDocuments, userEntityType, isAdmin);
        setVisibleDocuments(filteredDocuments);

        return () => {
        };
    }, [entityDocuments, userEntityType, isAdmin]);

    useEffect(() => {
        //console.log(`entityDocuments: ${JSON.stringify(entityDocuments)}`);
        setPendingApproval([]);
        setMissingDocuments([]);
        setExpiredDocuments([]);
        setExpiringSoonDocuments([]);

        let results = DocumentUtils.calculateCounts(entityDocuments, documentEntityId, documentEntityType, documentEntitySubType, account, needsCarrierWashoutLog, needsLumperFeeReceipt);

        setExpiringSoonDocuments(results.expiringSoonDocumentsList);
        setExpiredDocuments(results.expiredDocumentsList);
        setPendingApproval(results.pendingApprovalDocumentsList);
        setMissingDocuments(results.missingDocumentsList);

        return () => {
        };
    }, [documentEntityId, documentEntityType, documentEntitySubType, entityDocuments, account, needsCarrierWashoutLog]);

    useMemo(() => {
        getMissingDocumentCount();
    }, [missingDocuments]);

    useMemo(() => {
        getPendingApprovalCount();
    }, [pendingApproval]);

    useMemo(() => {
        getExpiringSoonDocumentCount();
    }, [expiringSoonDocuments]);

    useMemo(() => {
        getExpiredDocumentCount();
    }, [expiredDocuments]);

    //#endregion

    return (
        <DataTable
            dataSource={visibleDocuments}
            columns={columns}
            loading={isLoading === true}
            pageSize={10}
            rowClassName={classes.dataTableRow}
            title={() => (<DataTableTitle tableTitle={tableTitle} singularEntityName={singularEntityName} pluralEntityName={pluralEntityName} action={userEntityType === "STAFF" || (isStringNotEmpty(documentEntityType) && documentEntityType === "DRIVER" && (userEntityType === "STAFF" || userEntityType === "DRIVER" || userEntityType === "CARRIER" || userEntityType === "BROKER")) || (isStringNotEmpty(documentEntityType) && documentEntityType === "ASSET" && (userEntityType === "STAFF" || userEntityType === "DRIVER" || userEntityType === "CARRIER" || userEntityType === "BROKER")) || isObjectNotEmpty(load) || (isStringNotEmpty(documentEntityType) && documentEntityType === "ACCOUNT" && (userEntityType === "STAFF" || userEntityType === "SHIPPER" || userEntityType === "CARRIER" || userEntityType === "PRODUCER" || userEntityType === "RECEIVER" || userEntityType === "FACTORING" || userEntityType === "BROKER") && userEntityId === documentEntityId) ? toggleNewEntity : null} />)}
            footer={() => (<>{(userEntityType === "STAFF" || userEntityType === "BROKER" || (documentEntityType === "DRIVER" && userEntityType === "CARRIER")) ? createPendingApprovalList() : null}{createMissingDocumentList()}{createExpiredDocumentList()}{createExpiringSoonDocumentList()}</>)}
            style={{ backgroundColor: '#ffffff', ...style }}
            rowKey={record => record.id}
        >
            {newEntityComponents}
            {editEntityComponents}
            {reviewEntityComponents}
            {removeEntityComponents}
            {viewEntityComponents}
        </DataTable>
    );
};

export default withRouter(DocumentList);