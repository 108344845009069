import React, { useEffect, useReducer } from "react";
import MaskedInput from 'antd-mask-input';
import { CheckCircleTwoTone, LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { checkAccountMCNumberReducer, checkAccountMCNumber } from '../../shared/formUtils';
import axios from 'axios';
import { isBooleanTrue, isBooleanFalse, isObjectEmpty, isObjectNotEmpty, isStringNotEmpty, isNotNullOrUndefined } from '../../shared/objectUtils';
import { useFormContext } from "react-hook-form";
import { Alert } from "antd";
import FormItem from "../FormItem/FormItem";

const FormItemMCNumber = ({ format = 'vertical', required = false, name = "mcNum", defaultValue, accountMCNumber, setAccountMCNumber, hasValidAccountMCNumber, setHasValidAccountMCNumber, setExistingAccount = null, setAccountExists = null, account = null, allowSelf = true, allowExisting = false }) => {
    //#region useFormContext

    const methods = useFormContext();

    //#endregion
    //#region useReducers

    const [{ accountMCNumberExists, accountMCNumberExistingAccount, checkAccountMCNumberHasError, isCheckAccountMCNumberLoading, checkAccountMCNumberError }, checkAccountMCNumberDispatch] = useReducer(checkAccountMCNumberReducer, {
        accountMCNumberExists: null,
        accountMCNumberExistingAccount: null,
        isCheckAccountMCNumberLoading: false,
        checkAccountMCNumberHasError: false,
        checkAccountMCNumberError: null,
    });

    //#endregion
    //#region onChanges

    const onChangeAccountMCNumber = (value) => {
        if (isStringNotEmpty(value) && value.length >= 6) {
            if (isObjectNotEmpty(methods.errors.mcNum)) {
                checkAccountMCNumberDispatch({ type: "FETCH_FAILURE", payload: { error: 'You must first enter a valid unique MC Number.' } });
            }
            // console.log(value);
            setAccountMCNumber(value);
        } else {
            checkAccountMCNumberDispatch({ type: "FETCH_CLEAR" });
        }
    };

    //#endregion
    //#region useEffects

    useEffect(() => {
        //console.log(`accountMCNumber: ${accountMCNumber}`);
        const { cancel, token } = axios.CancelToken.source();
        const timeOutId = setTimeout(() => {
            if (isStringNotEmpty(accountMCNumber) && accountMCNumber.length >= 6) {
                checkAccountMCNumber(accountMCNumber, account, allowSelf, allowExisting, checkAccountMCNumberDispatch, token);
            }
        }, 500);
        return () => cancel("Searching...") || clearTimeout(timeOutId);
    }, [accountMCNumber, account, allowSelf, allowExisting]);

    useEffect(() => {
        // console.log(accountMCNumber);
        // console.log(methods.errors.mcNum);
        // console.log(accountMCNumberExists);
        if (isStringNotEmpty(accountMCNumber)) {
            if (isObjectEmpty(methods.errors.mcNum)) {
                if (isBooleanFalse(accountMCNumberExists)) {
                    setHasValidAccountMCNumber(true);
                    // console.log('Has a valid MC Number');
                } else if (isBooleanTrue(allowSelf) && isBooleanTrue(accountMCNumberExists) && isObjectNotEmpty(account) && isStringNotEmpty(account.id) && isObjectNotEmpty(accountMCNumberExistingAccount) && isStringNotEmpty(accountMCNumberExistingAccount.id) && account.id === accountMCNumberExistingAccount.id) {
                    setHasValidAccountMCNumber(true);
                    // console.log('MC Number matches what is already saved for this account');
                } else {
                    setHasValidAccountMCNumber(false);
                }
            } else {
                setHasValidAccountMCNumber(false);
                checkAccountMCNumberDispatch({ type: "FETCH_CLEAR" });
            }
        } else {
            setHasValidAccountMCNumber(false);
            checkAccountMCNumberDispatch({ type: "FETCH_CLEAR" });
        }
    }, [methods.errors, accountMCNumber, account, accountMCNumberExists, accountMCNumberExistingAccount, allowSelf]);

    useEffect(() => {
        if (isNotNullOrUndefined(setAccountExists) && isNotNullOrUndefined(accountMCNumberExists)) {
            setAccountExists(accountMCNumberExists)
        }
    }, [accountMCNumberExists]);

    useEffect(() => {
        if (isNotNullOrUndefined(setExistingAccount) && isNotNullOrUndefined(accountMCNumberExistingAccount)) {
            setExistingAccount(accountMCNumberExistingAccount)
        }
    }, [accountMCNumberExistingAccount]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { sm: 24, md: 24 },
        wrapperCol: { sm: 24, md: 24 },
    };

    const formItemLayoutHorizontal = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    //#endregion

    if (format === 'vertical') {
        return (
            <>
                <FormItem {...formItemLayout} label="US Docket # (MC, FF, MX)" format="vertical" required={required}
                    render={({ onChange, onBlur, value, name, ref }) => (
                        <MaskedInput
                            onBlur={onBlur}
                            onChange={e => {
                                let value = null;
                                if (isStringNotEmpty(e.target.value)) {
                                    value = e.target.value.replace(/[^0-9]/g, "");
                                }
                                // console.log(value);
                                onChangeAccountMCNumber(value);
                                onChange(value);
                            }}
                            value={value}
                            name={name}
                            placeholder="MC #"
                            addonAfter={isStringNotEmpty(value) && isBooleanTrue(hasValidAccountMCNumber) ? <CheckCircleTwoTone title="Valid" alt="Valid" twoToneColor="#52c41a" /> : (isBooleanTrue(isCheckAccountMCNumberLoading) ? <LoadingOutlined /> : <SearchOutlined />)}
                            mask="11111111"
                            ref={ref}
                        />
                    )}
                    rules={{
                        required: (required ? 'Required Field' : false),
                        pattern: {
                            value: /^\d{1,8}$/,
                            message: "Please enter a valid number with no special characters"
                        },
                        maxLength: { value: 8, message: 'MC Number cannot be more than 8 characters long' },
                        minLength: { value: 6, message: 'MC Number must be at least 6 characters long' },
                    }}
                    name={name}
                    defaultValue={defaultValue}
                />
                {checkAccountMCNumberHasError && <Alert message={checkAccountMCNumberError} type="error" style={{ marginBottom: 8 }} />}
            </>
        );
    } else if (format === 'horizontal') {
        return (
            <>
                <FormItem {...formItemLayoutHorizontal} label="US Docket # (MC, FF, MX)" format="horizontal" required={required}
                    render={({ onChange, onBlur, value, name, ref }) => (
                        <MaskedInput
                            onBlur={onBlur}
                            onChange={e => {
                                let value = null;
                                if (isStringNotEmpty(e.target.value)) {
                                    value = e.target.value.replace(/[^0-9]/g, "");
                                }
                                // console.log(value);
                                onChangeAccountMCNumber(value);
                                onChange(value);
                            }}
                            value={value}
                            name={name}
                            placeholder="MC #"
                            addonAfter={isStringNotEmpty(value) && isBooleanTrue(hasValidAccountMCNumber) ? <CheckCircleTwoTone title="Valid" alt="Valid" twoToneColor="#52c41a" /> : (isBooleanTrue(isCheckAccountMCNumberLoading) ? <LoadingOutlined /> : <SearchOutlined />)}
                            mask="11111111"
                            ref={ref}
                        />
                    )}
                    rules={{
                        required: (required ? 'Required Field' : false),
                        pattern: {
                            value: /^\d{1,8}$/,
                            message: "Please enter a valid number with no special characters"
                        },
                        maxLength: { value: 8, message: 'MC Number cannot be more than 8 characters long' },
                        minLength: { value: 6, message: 'MC Number must be at least 6 characters long' },
                    }}
                    name={name}
                    defaultValue={defaultValue}
                />
                {checkAccountMCNumberHasError && <Alert message={checkAccountMCNumberError} type="error" style={{ marginBottom: 8 }} />}
            </>
        );
    } else {
        return null;
    }
};

export default FormItemMCNumber;