import React, { useState, useMemo } from 'react';
import { Alert, Spin, Input, Select, InputNumber, Row, Col } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import { isNumberNotEmpty, isObjectNotEmpty, isStringEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import DatePickerAutoAccept from '../DatePickerAutoAccept/DatePickerAutoAccept';
import MapWrapper from '../Map/Map';

const { Option } = Select;

const NewPreferredLane = ({ accountId, cancel }) => {
    //#region useForms

    const methods = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            customerLaneId: null,
            originCity: null,
            originState: null,
            destinationCity: null,
            destinationState: null,
            trailerTypeId: null,
            rate: null,
            rateStartDateTime: null,
            rateEndDateTime: null,
            capacityPerWeek: null,
            maxLoadWeight: null
        }
    });

    //#endregion
    //#region useStates

    const [originCity, setOriginCity] = useState(null);
    const [originState, setOriginState] = useState(null);
    const [originLatitude, setOriginLatitude] = useState(null);
    const [originLongitude, setOriginLongitude] = useState(null);
    const [destinationCity, setDestinationCity] = useState(null);
    const [destinationState, setDestinationState] = useState(null);
    const [destinationLatitude, setDestinationLatitude] = useState(null);
    const [destinationLongitude, setDestinationLongitude] = useState(null);
    const [approxDistance, setApproxDistance] = useState(null);
    const [carriers, setCarriers] = useState([]);
    const [carrierId, setCarrierId] = useState(null);

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const entityType = useSelector(state => state.auth.entityType);
    const isLoading = useSelector(state => state.preferredLanes.isRecordAddLoading);
    const error = useSelector(state => state.preferredLanes.addRecordError);
    const states = useSelector(state => state.orchestrator.states);
    const trailerTypes = useSelector(state => state.orchestrator.trailerTypes);
    const accounts = useSelector(state => state.orchestrator.accounts);

    //#endregion
    //#region submit and cancel

    const onSubmit = (data) => {
        console.log(data);
        if (isObjectNotEmpty(data)) {
            data.accountId = accountId;
            if (isNumberNotEmpty(data.rate)) {
                data.rateUnit = 'USD';
            }
            if (isNumberNotEmpty(data.maxLoadWeight)) {
                data.maxLoadWeightUnit = 'LB';
            }
            if (isNumberNotEmpty(approxDistance)) {
                data.approxDistance = parseFloat(approxDistance);
                data.approxDistanceUnit = 'MI';
            }
            if (isNumberNotEmpty(originLatitude) && isNumberNotEmpty(originLongitude) && isNumberNotEmpty(destinationLatitude) && isNumberNotEmpty(destinationLongitude)) {
                data.originLatitude = parseFloat(originLatitude);
                data.originLongitude = parseFloat(originLongitude);
                data.destinationLatitude = parseFloat(destinationLatitude);
                data.destinationLongitude = parseFloat(destinationLongitude);
            }
            dispatch(actionCreators.addPreferredLane(data));
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.addPreferredLaneCancel());
        cancel();
    };

    //#endregion
    //#region useEffects and useMemos

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.addPreferredLaneErrorClear());

        dispatch(actionCreators.getStates());
        dispatch(actionCreators.getTrailerTypes());
        dispatch(actionCreators.getAccounts());
    }, []);

    useMemo(() => {
        if (isStringNotEmpty(accountId)) {
            setCarrierId(accountId);
        }
        const filteredAccounts = accounts.filter(a => a.entityTypes.includes("CARRIER") === true);
        setCarriers(filteredAccounts);
    }, [accountId, accounts]);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    const formItemLayoutDouble = {
        labelCol1: { span: 24 },
        wrapperCol1: { span: 12 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 12 },
    };

    //#endregion

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    {isStringEmpty(accountId) ? (
                        <Fieldset legend="Choose a Carrier">
                            <FormItem {...formItemLayout} label="Carrier" required
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please Select a Carrier"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { setCarrierId(selected); onChange(selected); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    >
                                        {carriers.map(a => <Option value={a.id} key={a.id}>{a.name}</Option>)}
                                    </Select>
                                )}
                                rules={{ required: 'Required Field' }}
                                name="accountId"
                            />
                        </Fieldset>
                    ) : null}
                    <Fieldset legend="Origin and Destination">
                        <Row gutter={[24, 24]}>
                            <Col span={24}>
                                <MapWrapper
                                    height={'200px'}
                                    key={`preferred-lane-creation-map-key`}
                                    loadId={'new-lane'}
                                    origin={isStringNotEmpty(originCity) && isStringNotEmpty(originState) ? `${originCity}, ${originState}, USA` : null}
                                    destination={isStringNotEmpty(destinationCity) && isStringNotEmpty(destinationState) ? `${destinationCity}, ${destinationState}, USA` : null}
                                    containerName={`lane-creation-map`}
                                    style={{ width: "100%", height: "200px" }}
                                    setRouteDistance={setApproxDistance}
                                    setOriginLatitude={setOriginLatitude}
                                    setOriginLongitude={setOriginLongitude}
                                    setDestinationLatitude={setDestinationLatitude}
                                    setDestinationLongitude={setDestinationLongitude}
                                />
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Origin" required
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Input
                                            onBlur={onBlur}
                                            onChange={e => { setOriginCity(e.target.value); onChange(e.target.value); }}
                                            value={value}
                                            name={name}
                                            placeholder="City"
                                            ref={ref}
                                        />
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="originCity"
                                />
                                <FormItem {...formItemLayout} required
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Select
                                            placeholder="Please Select a State"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { setOriginState(selected); onChange(selected); }}
                                            value={value}
                                            name={name}
                                            showSearch={true}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            ref={ref}
                                        >
                                            {states.map(i => <Option value={i.stateAbbr} key={i.stateAbbr}>{i.stateName}</Option>)}
                                        </Select>
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="originState"
                                />
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Destination" required
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Input
                                            onBlur={onBlur}
                                            onChange={e => { setDestinationCity(e.target.value); onChange(e.target.value); }}
                                            value={value}
                                            name={name}
                                            placeholder="City"
                                            ref={ref}
                                        />
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="destinationCity"
                                />
                                <FormItem {...formItemLayout} required
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Select
                                            placeholder="Please Select a State"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { setDestinationState(selected); onChange(selected); }}
                                            value={value}
                                            name={name}
                                            showSearch={true}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            ref={ref}
                                        >
                                            {states.map(i => <Option value={i.stateAbbr} key={i.stateAbbr}>{i.stateName}</Option>)}
                                        </Select>
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="destinationState"
                                />
                            </Col>
                        </Row>
                    </Fieldset>
                    <Fieldset legend="Lane Details">
                        <FormItem {...formItemLayout} label="Trailer Type" required
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select a Trailer Type"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    ref={ref}
                                >
                                    {trailerTypes.map(i => <Option value={i.id} key={i.id}>{i.description}</Option>)}
                                </Select>
                            )}
                            rules={{ required: 'Required Field' }}
                            name='trailerTypeId'
                        />
                        <FormItem {...formItemLayout} label="Lane Id"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Input
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e.target.value); }}
                                    value={value}
                                    name={name}
                                    placeholder="Lane Id"
                                    ref={ref}
                                />
                            )}
                            rules={{ required: false }}
                            name="customerLaneId"
                        />
                        <FormItem {...formItemLayout} label="Rate"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <InputNumber
                                    min={0}
                                    precision={2}
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    placeholder="Rate"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            rules={{ required: false }}
                            name="rate"
                        />
                        <FormItemDouble {...formItemLayoutDouble} label1="Date Range that Rate is Valid For"
                            render1={({ onChange, onBlur, value, name, ref }) => (
                                <DatePickerAutoAccept
                                    allowClear={true}
                                    placeholder="Start Date/Time"
                                    showTime={{ format: 'HH:mm', minuteStep: 15 }}
                                    format="YYYY-MM-DD HH:mm"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={(date, dateString) => { onChange(date); }}
                                    value={value}
                                    name={name}
                                    onSelect={(date) => { onChange(date); }}
                                    ref={ref}
                                />
                            )}
                            rules1={{ required: false }}
                            name1="rateStartDateTime"
                            render2={({ onChange, onBlur, value, name, ref }) => (
                                <DatePickerAutoAccept
                                    allowClear={true}
                                    placeholder="End Date/Time"
                                    showTime={{ format: 'HH:mm', minuteStep: 15 }}
                                    format="YYYY-MM-DD HH:mm"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={(date, dateString) => { onChange(date); }}
                                    value={value}
                                    name={name}
                                    onSelect={(date) => { onChange(date); }}
                                    ref={ref}
                                />
                            )}
                            rules2={{ required: false }}
                            name2="rateEndDateTime"
                        />
                        <FormItem {...formItemLayout} label="Capacity Per Week"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <InputNumber
                                    min={0}
                                    precision={0}
                                    placeholder="Capacity Per Week"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            rules={{ required: false }}
                            name="capacityPerWeek"
                        />
                        <FormItem {...formItemLayout} label="Max Load Weight (in lbs)"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <InputNumber
                                    min={0}
                                    precision={0}
                                    step={1000}
                                    placeholder="Max Load Weight (in lbs)"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            rules={{ required: false }}
                            name="maxLoadWeight"
                        />
                    </Fieldset>
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Create Preferred Lane" />
            </Form>
        </FormProvider>
    );
};

export default NewPreferredLane;