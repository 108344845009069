import React, { useMemo, useState } from 'react';
import { Alert, Input, Spin, Checkbox, Select, Switch } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import FormButtons from '../FormButtons/FormButtons';
import Enums from '../../shared/enums';
import TimePicker from '../TimePicker/TimePicker';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import { BlockPicker } from 'react-color';
import { isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';

const EditShift = ({ shift, cancel }) => {
    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.shifts.isRecordUpdateLoading);
    const error = useSelector(state => state.shifts.updateRecordError);

    const timeZoneOptions = Enums.USATimeZones.selectListOptions();

    const [textColor, setTextColor] = useState(null);
    const [backgroundColor, setBackgroundColor] = useState(null);

    const onChangeBackgroundColor = (color) => {
        let contrastingColor = '#ffffff';
        // console.log(color);

        if (color && color.rgb) {
            // console.log(color.hex);
            setBackgroundColor(color.hex);
            const yiq = ((color.rgb.r * 299) + (color.rgb.g * 587) + (color.rgb.b * 114)) / 1000;
            contrastingColor = (yiq >= 128) ? '#000000' : '#ffffff';
        }

        // console.log(contrastingColor);
        setTextColor(contrastingColor);
    };

    const onSubmit = (data) => {
        if (isStringNotEmpty(textColor) && isStringNotEmpty(backgroundColor)) {
            data.textColor = textColor;
            data.backgroundColor = backgroundColor;

            dispatch(actionCreators.updateShift(shift.id, data));
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.updateShiftCancel());
        cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateShiftErrorClear());
    }, []);

    useMemo(() => {
        if (isObjectNotEmpty(shift)) {
            if(isStringNotEmpty(shift.textColor)) {
                setTextColor(shift.textColor);
            }
            if(isStringNotEmpty(shift.backgroundColor)) {
                setBackgroundColor(shift.backgroundColor);
            }
        }
    }, [shift]);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    const formItemLayoutDouble = {
        labelCol1: { span: 12 },
        wrapperCol1: { span: 12 },
        labelCol2: { span: 12 },
        wrapperCol2: { span: 12 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <FormItem {...formItemLayout} label="Name" required format="vertical"
                        render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Name" ref={ref} />}
                        rules={{ required: 'Required Field' }}
                        name="name"
                        defaultValue={shift.name}
                    />
                    <FormItemDouble {...formItemLayoutDouble} label1="Start Time" label2="End Time" required1 required2 format="vertical"
                        render1={({ onChange, onBlur, value, name, ref }) => (
                            <TimePicker
                                placeholder="Start Time"
                                step={15}
                                style={{ width: '100%' }}
                                onBlur={onBlur}
                                onChange={(time) => { onChange(time); }}
                                value={value}
                                name={name}
                                ref={ref}
                            />
                        )}
                        rules1={{ required: 'Required Field' }}
                        name1="startTime"
                        defaultValue1={shift.startTime}
                        render2={({ onChange, onBlur, value, name, ref }) => (
                            <TimePicker
                                placeholder="End Time"
                                step={15}
                                style={{ width: '100%' }}
                                onBlur={onBlur}
                                onChange={(time) => { onChange(time); }}
                                value={value}
                                name={name}
                                ref={ref}
                            />
                        )}
                        rules2={{ required: 'Required Field' }}
                        name2="endTime"
                        defaultValue2={shift.endTime}
                    />
                    <FormItem {...formItemLayout} label="Is the End Time Next Day?" format="vertical"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                onBlur={onBlur}
                                onChange={(checked, event) => { onChange(checked); }}
                                checked={value}
                                name={name}
                                ref={ref}
                            />
                        )}
                        name="isNextDay"
                        defaultValue={shift.isNextDay}
                    />
                    <FormItem {...formItemLayout} label="Time Zone" required format="vertical"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Select
                                placeholder="Please Select the Time Zone for this Shift"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                                ref={ref}
                            >
                                {timeZoneOptions}
                            </Select>
                        )}
                        rules={{ required: "Required Field" }}
                        name="timeZone"
                        defaultValue={shift.timeZone}
                    />
                    <FormItem {...formItemLayout} label="Background/Text Color" required format="vertical"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <BlockPicker
                                onChange={(color, event) => { onChangeBackgroundColor(color); onChange(color); }}
                                color={value}
                                triangle="hide"
                            />
                        )}
                        rules={{ required: 'Required Field' }}
                        name="backgroundColor"
                        defaultValue={shift.backgroundColor}
                    />
                    <FormItem {...formItemLayout} label="Should the employees be shown as Available for this Shift?" format="vertical"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                onBlur={onBlur}
                                onChange={(checked, event) => { onChange(checked); }}
                                checked={value}
                                name={name}
                                ref={ref}
                            />
                        )}
                        name="isAvailable"
                        defaultValue={shift.isAvailable}
                    />
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Update Shift" />
            </Form>
        </FormProvider>
    );
};

export default EditShift;