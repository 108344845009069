import React from 'react';
import DataRow from '../../components/DataRow/DataRow';
import { Col, Row } from 'antd';
import { isListNotEmpty, isNumberNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';

const LineItemType = ({ lineItemType }) => {
    return (
        <Row>
            <Col xs={24} md={24} lg={12} xl={12}>
                {isStringNotEmpty(lineItemType.name) ? (<DataRow title="Name" value={lineItemType.name} dataType="String" />) : null}
                {isStringNotEmpty(lineItemType.description) ? (<DataRow title="Description" value={lineItemType.description} dataType="String" />) : null}
                {isStringNotEmpty(lineItemType.itemType) ? (<DataRow title="Type" value={lineItemType.itemType} dataType="LineItemType" />) : null}
                {isListNotEmpty(lineItemType.trailerTypes) ? (<DataRow title="Trailer Types" value={lineItemType.trailerTypes.map((item) => { return item.description; }).join(', ')} dataType="String" />) : null}
                <DataRow title="Is Required" value={lineItemType.isRequired} dataType="Boolean" />
                <DataRow title="Is Open Amount" value={lineItemType.isOpen} dataType="Boolean" />
            </Col>
            <Col xs={24} md={24} lg={12} xl={12}>
                {isNumberNotEmpty(lineItemType.upCharge) ? (<DataRow title="Up-Charge Amount" value={lineItemType.upCharge} dataType="Money" />) : null}
                {isStringNotEmpty(lineItemType.upChargeRule) ? (<DataRow title="Up-Charge Rule" value={lineItemType.upChargeRule} dataType="LineItemRule" />) : null}
                {isNumberNotEmpty(lineItemType.cost) ? (<DataRow title="Cost Amount" value={lineItemType.cost} dataType="Money" />) : null}
                {isStringNotEmpty(lineItemType.costRule) ? (<DataRow title="Cost Rule" value={lineItemType.costRule} dataType="LineItemRule" />) : null}
            </Col>
        </Row>
    );
};

export default LineItemType;