import React from 'react';
import { isBooleanFalse, isBooleanTrue, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import StringFormatter from '../../shared/stringFormatter';

const stringFormatter = new StringFormatter();

const DataAtByRow = ({ by, className = null, valueClassName = null, titleClassName = null, style = null, title = null, titleStyle = { fontWeight: 'bold' }, noColon = false, valueStyle = null, at, entityType }) => {
    let name = [];
    let isByStaff = false;
    if (isObjectNotEmpty(by)) {
        if (isStringNotEmpty(by.firstName)) {
            name.push(by.firstName);
        }
        if (isStringNotEmpty(by.middleName)) {
            name.push(by.middleName);
        }
        if (isStringNotEmpty(by.lastName)) {
            name.push(by.lastName);
        }
        if (isBooleanTrue(by.isStaff)) {
            isByStaff = true;
        }
    }

    // only show the By name to staff if it is by a staff member

    return (
        <div className={className} style={style}>
            {isStringNotEmpty(title) ? (<span className={titleClassName} style={titleStyle}>{title}{isBooleanFalse(noColon) ? ':' : ''} </span>) : null}
            <span className={valueClassName} style={valueStyle}>{stringFormatter.toFormattedString('MomentDate', at, null, null)}{((isStringNotEmpty(entityType) && entityType === 'STAFF' && isByStaff === true) || isByStaff === false) ? ` by ${name.join(' ')}` : ''}</span>
        </div>
    );
};

export default DataAtByRow;