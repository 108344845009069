import React from 'react';
import { Input, Button, Space } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

class ColumnSearchFilter extends React.Component {
    getColumnSearchProps = (dataIndex, title, searchText, setSearchText) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => { this.searchInput = node; }}
                    placeholder={`Search ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex, searchText, setSearchText)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex, searchText, setSearchText)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters, dataIndex, searchText, setSearchText)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => {
            const recordValue = this.getDescendantProp(record, dataIndex);
            return recordValue ? recordValue.toString().toLowerCase().includes(value.toLowerCase()) : ''
        },
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText[dataIndex]]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                    text
                ),
    });

    getDescendantProp = (obj, desc) => {
        var arr = desc.split(".");
        while(arr.length && (obj = obj[arr.shift()]));
        return obj;
    }

    handleSearch = (selectedKeys, confirm, dataIndex, searchText, setSearchText) => {
        confirm();
        const copyOfSearchText = { ...searchText };
        copyOfSearchText[dataIndex] = selectedKeys[0];
        setSearchText({ ...copyOfSearchText });
    };

    handleReset = (clearFilters, dataIndex, searchText, setSearchText) => {
        clearFilters();
        const copyOfSearchText = { ...searchText };
        copyOfSearchText[dataIndex] = '';
        setSearchText({ ...copyOfSearchText });
    };
};

export default ColumnSearchFilter;