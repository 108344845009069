import Checks from '../entitlementUtils';

export const canCreateLoadShipper = (auth, load) => {
    return Checks.allowStaff(auth) || (Checks.isLoadCarrier(load, auth) && Checks.isLoadTMS(load));
};

export const canReadLoadShipper = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadShipper(load, auth) || (Checks.isLoadCarrier(load, auth) && Checks.isLoadTMS(load));
};

export const canReadLoadShipperList = (auth, load) => {
    return Checks.allowStaffAdmin(auth);
};

export const canUpdateLoadShipper = (auth, load) => {
    return Checks.allowStaffAdmin(auth);
};

export const canDeleteLoadShipper = (auth, load) => {
    return Checks.allowStaffAdmin(auth);
};