import React from 'react';
import { withRouter } from 'react-router-dom';
import UnderConstruction from "../../components/UnderConstruction/UnderConstruction";

const RatingTypes = (props) => {
    return (
        <UnderConstruction />
    );
};

export default withRouter(RatingTypes);