import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { DownOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Modal, Drawer, Button, Dropdown, Menu } from 'antd';
import EditAccount from "../../components/EditAccount/EditAccount";
import { faTools, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from './Accounts.module.scss';
import DataTable from '../../components/DataTable/DataTable';
import StringFormatter from '../../shared/stringFormatter';
import DocumentList from '../../components/DocumentList/DocumentList';
import ColumnSearchFilter from '../../shared/tableColumnSearchFilter';
import NewAccountModal from '../../components/NewAccountModal/NewAccountModal';
import { isListNotEmpty, isNotNullOrUndefined, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { Can, CanDo } from '../../shared/entitlements/entitlements';
import { selectListIsLoading, selectListPagination, selectListRecords } from '../../store/utility';
import { compareByAlph } from '../../shared/tableUtils';

const stringFormatter = new StringFormatter();
const columnSearchFilter = new ColumnSearchFilter();

const Accounts = (props) => {
    //#region constants

    const fullWidth = global.window.innerWidth;
    const singularEntityName = "Account";
    const pluralEntityName = "Accounts";

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const records = useSelector(state => selectListRecords(state.accounts.lists, 'ALL'));
    const isLoading = useSelector(state => selectListIsLoading(state.accounts.lists, 'ALL'));
    const pagination = useSelector(state => selectListPagination(state.accounts.lists, 'ALL'));
    const isLoadingUpdateRecord = useSelector(state => state.accounts.isRecordUpdateLoading);
    const updateRecordError = useSelector(state => state.accounts.updateRecordError);

    //#endregion
    //#region useStates

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showDocumentsModal, setShowDocumentsModal] = useState(false);
    const [missingDocumentsCount, setMissingDocumentsCount] = useState(0);
    const [pendingApprovalCount, setPendingApprovalCount] = useState(0);
    const [expiringSoonDocumentsCount, setExpiringSoonDocumentsCount] = useState(0);
    const [expiredDocumentsCount, setExpiredDocumentsCount] = useState(0);
    const [searchText, setSearchText] = useState({ name: '', irisId: '', ein: '' });
    const [isVerifyingAccount, setIsVerifyingAccount] = useState(false);

    //#endregion
    //#region toggles

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleDocumentsModal = () => {
        setShowDocumentsModal(!showDocumentsModal);
    };

    //#endregion
    //#region account methods

    const verifyAccount = (accountId) => {
        dispatch(actionCreators.verifyAccount(accountId));
        setIsVerifyingAccount(true);
    };

    const finishedVerifyAccount = () => {
        dispatch(actionCreators.updateAccountCancel());
        toggleDocumentsModal();
        setIsVerifyingAccount(false);
    };

    //#endregion
    //#region goTos

    const goToAccountDashboard = (id) => {
        props.history.push({ pathname: `/accounts/${id}` }, {
            previousPageTitle: "Accounts",
            previousPageLocation: props.location,
            previousBreadcrumbs: props.breadcrumbs,
        });
    };

    //#endregion
    //#region table methods

    const refreshTable = () => {
        let searchParams = {
            page: 1,
            size: 5,
            sort: 'name',
            order: 'asc',
            isDeleted: false
        };

        dispatch(actionCreators.fetchAccountList('ALL', searchParams));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchAccountListSuccess('ALL', { params: { pagination: pager } }));

        let searchParams = {
            page: pagination.current,
            size: pagination.pageSize,
            isDeleted: false
        };

        if (isStringNotEmpty(sorter.order)) {
            const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
            searchParams.order = sortOrder;
        }
        if (isStringNotEmpty(sorter.field)) {
            searchParams.sort = sorter.field;
        }

        if (isNotNullOrUndefined(filters)) {
            if (isListNotEmpty(filters.name)) {
                searchParams['name:contains'] = filters.name[0];
            }
            if (isListNotEmpty(filters.irisId)) {
                searchParams['irisId:contains'] = filters.irisId[0];
            }
            if (isListNotEmpty(filters.ein)) {
                searchParams['ein:contains'] = filters.ein[0];
            }
        }

        dispatch(actionCreators.fetchAccountList('ALL', searchParams));
    };

    //#endregion
    //#region table displays

    const setRowClassName = (record) => {
        return record.isVerified === undefined || record.isVerified === null || record.isVerified === false || record.documentPendingApprovalCount > 0 || record.documentMissingCount > 0 || record.documentExpiredCount > 0 ? classes.unverifiedRow : classes.dataTableRow;
    };

    const menu = (record) => {
        return (
            <Menu>
                {CanDo({ entityAction: 'UPDATE', entityModel: 'ACCOUNT', entityObject: record }) ? (
                    <Menu.Item key="manageDocuments" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); toggleDocumentsModal(); }}>
                        <PaperClipOutlined style={{ marginRight: 8 }} />
                        <span>Manage Documents</span>
                    </Menu.Item>
                ) : null}
                {CanDo({ entityAction: 'UPDATE', entityModel: 'ACCOUNT', entityObject: record }) ? (
                    <Menu.Item key="editAccount" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); toggleEditEntity(); }}>
                        <FontAwesomeIcon className="anticon" icon={faUsers} style={{ marginRight: 8 }} />
                        <span>Edit {singularEntityName} Details</span>
                    </Menu.Item>
                ) : null}
                {CanDo({ entityAction: 'READ', entityModel: 'ACCOUNT', entityObject: record }) ? (
                    <Menu.Item key="goToAccountDashboard" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); goToAccountDashboard(record.id); }}>
                        <span>Go to {singularEntityName} Dashboard</span>
                    </Menu.Item>

                ) : null}
            </Menu>
        );
    };

    const columns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            width: 50,
            render: (text, record) => {
                return (
                    <Dropdown overlay={menu(record)} trigger={['click']}>
                        <Button>Actions <DownOutlined /></Button>
                    </Dropdown>
                );
            },
            align: 'center',
            className: classes.columnNoBreak
        },
        {
            title: 'Account Id',
            dataIndex: 'irisId',
            key: 'irisId',
            ...columnSearchFilter.getColumnSearchProps('irisId', 'Account Id', searchText, setSearchText)
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => compareByAlph(a.name, b.name),
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'ascend',
            ...columnSearchFilter.getColumnSearchProps('name', 'Name', searchText, setSearchText)
        },
        {
            title: 'EIN',
            dataIndex: 'ein',
            key: 'ein',
            ...columnSearchFilter.getColumnSearchProps('ein', 'EIN', searchText, setSearchText)
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Account Type(s)',
            dataIndex: 'entityTypes',
            key: 'entityTypes',
            render: (text, record) => {
                if (isListNotEmpty(text)) {
                    return text.map((entityType) => { return stringFormatter.toFormattedString("EntityType", entityType, null); }).join(', ');
                } else {
                    return '';
                }
            },
        },
        {
            title: 'Is Active',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        },
        {
            title: 'Is Verified',
            dataIndex: 'isVerified',
            key: 'isVerified',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        },
        {
            title: 'Documents Pending Approval',
            dataIndex: 'documentPendingApprovalCount',
            key: 'documentPendingApprovalCount',
        },
        {
            title: 'Missing Documents',
            dataIndex: 'documentMissingCount',
            key: 'documentMissingCount',
        },
        {
            title: 'Documents Expiring Soon',
            dataIndex: 'documentExpiringSoonCount',
            key: 'documentExpiringSoonCount',
        },
        {
            title: 'Expired Documents',
            dataIndex: 'documentExpiredCount',
            key: 'documentExpiredCount',
        }
    ];

    //#endregion
    //#region displays

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName + " Details"}
            onClose={toggleEditEntity}
            visible={showEditEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditAccount
                cancel={toggleEditEntity}
                account={selectedRecord}
            />
        </Drawer>
    );

    const documentsComponent = (
        <Modal
            title="Account Documents"
            visible={showDocumentsModal === true}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleDocumentsModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            {isObjectNotEmpty(selectedRecord) ? (
                <DocumentList
                    documentEntityType="ACCOUNT"
                    account={selectedRecord}
                    documentEntityId={selectedRecord.id}
                    updatePendingApprovalCount={setPendingApprovalCount}
                    updateMissingDocumentsCount={setMissingDocumentsCount}
                    updateExpiringSoonDocumentsCount={setExpiringSoonDocumentsCount}
                    updateExpiredDocumentsCount={setExpiredDocumentsCount}
                />
            ) : null}
            {isObjectNotEmpty(selectedRecord) && selectedRecord.isVerified === false ? (
                <CanDo entityAction="VERIFY" entityModel="ACCOUNT" entityObject={selectedRecord}>
                    <Button style={{ marginTop: 20 }} type='primary' onClick={() => { verifyAccount(selectedRecord.id); }} disabled={pendingApprovalCount > 0 || missingDocumentsCount > 0 || expiredDocumentsCount > 0}>Verify Account</Button>
                </CanDo>
            ) : null}
        </Modal>
    );

    //#endregion
    //#region useEffects and useMemos

    useEffect(() => {
        refreshTable();
    }, []);

    useMemo(() => {
        if (isLoadingUpdateRecord !== null && isLoadingUpdateRecord === false && updateRecordError === null && isVerifyingAccount === true) {
            finishedVerifyAccount();
        }
    }, [isLoadingUpdateRecord, updateRecordError, isVerifyingAccount]);

    //#endregion

    return (
        <Can staffOnly={true}>
            <DataTable
                dataSource={records}
                columns={columns}
                loading={isLoading === true}
                pagination={pagination}
                onChange={handleTableChange}
                rowClassName={setRowClassName}
                singularEntityName={singularEntityName}
                pluralEntityName={pluralEntityName}
                newEntityAction={toggleNewEntity}
                rowKey={record => record.id}
                style={{ backgroundColor: '#ffffff' }}
            >
                <NewAccountModal showModal={showNewEntity} toggleModal={toggleNewEntity} />
                {editEntityComponents}
                {documentsComponent}
            </DataTable>
        </Can>
    );
};

export default withRouter(Accounts);