import React, { useMemo, useState } from 'react';
import { Alert, Button, Col, Input, Row, Spin } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import { isObjectNotEmpty, removeEmpty } from '../../shared/objectUtils';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import FormItemDisplay from '../FormItemDisplay/FormItemDisplay';

const EditMissingPedigreeInformation = ({ load, missingPedigreeInformation, cancel }) => {
    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    const dispatch = useDispatch();
    const isLoadUpdateLoading = useSelector(state => state.loads.isRecordUpdateLoading);
    const errorLoadUpdate = useSelector(state => state.loads.updateRecordError);
    const isLoadStopUpdateLoading = useSelector(state => state.loadStops.isRecordUpdateLoading);
    const errorLoadStopUpdate = useSelector(state => state.loadStops.updateRecordError);

    const [bolNumberList, setBOLNumberList] = useState(['']);

    const addBOLNumber = () => {
        setBOLNumberList(oldList => [...oldList, '']);
    };

    const removeBOLNumber = (index) => {
        let updatedBOLNumberList = [...bolNumberList];
        updatedBOLNumberList.splice(index, 1);

        setBOLNumberList(updatedBOLNumberList);
    };

    const onChangeBOLNumber = (index, value) => {
        let updatedBOLNumbers = [...bolNumberList];
        updatedBOLNumbers[index] = value;
        setBOLNumberList(updatedBOLNumbers);
    };

    const onSubmit = (data) => {
        let payload = removeEmpty(data);
        if (isObjectNotEmpty(payload)) {
            if (missingPedigreeInformation.entityType === 'LOAD') {
                dispatch(actionCreators.updateLoad(missingPedigreeInformation.entityId, payload));
            } else if (missingPedigreeInformation.entityType === 'STOP') {
                payload.apptType = missingPedigreeInformation.entity.apptType;
                dispatch(actionCreators.updateLoadStop(missingPedigreeInformation.entityId, payload, missingPedigreeInformation.entity, load));
            } else {
                onCancel();
            }
        } else {
            onCancel();
        }
    };

    const onCancel = () => {
        if (missingPedigreeInformation.entityType === 'LOAD') {
            dispatch(actionCreators.updateLoadCancel());
        } else if (missingPedigreeInformation.entityType === 'STOP') {
            dispatch(actionCreators.updateLoadStopCancel());
        }

        cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        if (missingPedigreeInformation.entityType === 'LOAD') {
            dispatch(actionCreators.updateLoadErrorClear());
        } else if (missingPedigreeInformation.entityType === 'STOP') {
            dispatch(actionCreators.updateLoadStopErrorClear());
        }
    }, [missingPedigreeInformation]);

    useMemo(() => {
        if (isLoadUpdateLoading !== null && isLoadUpdateLoading === false && errorLoadUpdate === null) {
            onCancel();
        }
    }, [isLoadUpdateLoading, errorLoadUpdate]);

    useMemo(() => {
        if (isLoadStopUpdateLoading !== null && isLoadStopUpdateLoading === false && errorLoadStopUpdate === null) {
            onCancel();
        }
    }, [isLoadStopUpdateLoading, errorLoadStopUpdate]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    if (isObjectNotEmpty(missingPedigreeInformation)) {
        if (missingPedigreeInformation.entityType === 'LOAD') {
            return (
                <FormProvider {...methods}>
                    <Form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoadUpdateLoading === true && errorLoadUpdate === null}>
                            <FormItem {...formItemLayout} label={missingPedigreeInformation.missingFieldName}
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Input
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e.target.value); }}
                                        value={value}
                                        name={name}
                                        placeholder={missingPedigreeInformation.missingFieldName}
                                        ref={ref}
                                    />
                                )}
                                rules={{ required: false }}
                                name={missingPedigreeInformation.missingField}
                            />
                            {errorLoadUpdate && <Alert message={`${errorLoadUpdate}`} type="error" />}
                        </Spin>
                        <FormButtons cancel={onCancel} disabled={isLoadUpdateLoading === true && errorLoadUpdate === null} submitText={`Update Load`} />
                    </Form>
                </FormProvider>
            );
        } else if (missingPedigreeInformation.entityType === 'STOP') {
            return (
                <FormProvider {...methods}>
                    <Form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoadStopUpdateLoading === true && errorLoadStopUpdate === null}>
                            {missingPedigreeInformation.missingFields.map((missingField, index) => {
                                if (missingField.missingField !== 'bolNumbers') {
                                    return (
                                        <FormItem {...formItemLayout} label={missingField.missingFieldName}
                                            key={`missing-field-${missingField.missingField}-${index}`}
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Input
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e.target.value); }}
                                                    value={value}
                                                    name={name}
                                                    placeholder={missingField.missingFieldName}
                                                    ref={ref}
                                                />
                                            )}
                                            rules={{ required: false }}
                                            name={missingField.missingField}
                                        />
                                    );
                                } else {
                                    return (
                                        <FormItemDisplay {...formItemLayout} label="BOL Number(s)" key={`missing-field-bolNumbers-${index}`}>
                                            {bolNumberList.map((val, index) => {
                                                return (
                                                    <FormItem {...formItemLayout}
                                                        key={`missing-bol-number-${index}`}
                                                        render={({ onChange, onBlur, value, name, ref }) => (
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Input
                                                                        style={{ width: '50%' }}
                                                                        onBlur={onBlur}
                                                                        onChange={e => { onChangeBOLNumber(index, e.target.value); onChange(e.target.value); }}
                                                                        value={value}
                                                                        name={name}
                                                                        placeholder="BOL Number"
                                                                        ref={ref}
                                                                    />
                                                                    {(bolNumberList.length > 1) ? (
                                                                        <Button type="default" icon={<DeleteOutlined />} style={{ marginLeft: 8 }} onClick={() => { removeBOLNumber(index); }} />
                                                                    ) : null}
                                                                </Col>
                                                            </Row>
                                                        )}
                                                        rules={{ required: 'Required Field' }}
                                                        name={`bolNumbers.${index}`}
                                                        defaultValue={val ? val : ''}
                                                    />
                                                );
                                            })}
                                            <Row gutter={[8, 8]}>
                                                <Col span={24}>
                                                    <Button style={{ marginLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} onClick={() => { addBOLNumber(); }}>Add Another BOL Number</Button>
                                                </Col>
                                            </Row>
                                        </FormItemDisplay>
                                    );
                                }
                            })}
                            {errorLoadStopUpdate && <Alert message={`${errorLoadStopUpdate}`} type="error" />}
                        </Spin>
                        <FormButtons cancel={onCancel} disabled={isLoadStopUpdateLoading === true && errorLoadStopUpdate === null} submitText={`Update Stop`} />
                    </Form>
                </FormProvider>
            );
        } else {
            return null;
        }
    } else {
        return null;
    }
};

export default EditMissingPedigreeInformation;