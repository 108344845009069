import * as actionTypes from "../actions/actionTypes";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import * as actionCreators from "./index";
import ErrorUtils from "../../shared/errorUtils";
import { selectListRecords } from "../utility";

//#region Fetch Load Stop List Methods

export const fetchLoadStopListStart = (listName) => {
    return {
        type: actionTypes.FETCH_LOAD_STOP_LIST_START,
        payload: { listName: listName }
    }
};

export const fetchLoadStopListSuccess = (listName, payload) => {
    return {
        type: actionTypes.FETCH_LOAD_STOP_LIST_SUCCESS,
        payload: { listName: listName, ...payload }
    }
};

export const fetchLoadStopListFail = (listName, payload) => {
    return {
        type: actionTypes.FETCH_LOAD_STOP_LIST_FAIL,
        payload: { listName: listName, ...payload }
    }
};

export const clearLoadStopList = (listName) => {
    return {
        type: actionTypes.CLEAR_LOAD_STOP_LIST,
        payload: { listName: listName }
    }
};

export const clearLoadStopLists = () => {
    return {
        type: actionTypes.CLEAR_LOAD_STOP_LISTS
    }
};

export const addLoadStopToLoadStopList = (listName, payload) => {
    return {
        type: actionTypes.ADD_TO_LOAD_STOP_LIST,
        payload: { listName: listName, newRecord: payload }
    }
};

export const updateLoadStopInLoadStopList = (listName, payload) => {
    return {
        type: actionTypes.UPDATE_IN_LOAD_STOP_LIST,
        payload: { listName: listName, updatedRecord: payload }
    }
};

export const removeLoadStopFromLoadStopList = (listName, payload) => {
    return {
        type: actionTypes.REMOVE_FROM_LOAD_STOP_LIST,
        payload: { listName: listName, recordToRemove: payload }
    }
};

//#endregion
//#region Add Load Stop Methods

export const addLoadStopStart = () => {
    return {
        type: actionTypes.ADD_LOAD_STOP_START
    }
};

export const addLoadStopSuccess = () => {
    return {
        type: actionTypes.ADD_LOAD_STOP_SUCCESS
    }
};

export const addLoadStopFail = (payload) => {
    return {
        type: actionTypes.ADD_LOAD_STOP_FAIL,
        payload: payload
    }
};

export const addLoadStopLoadingClear = () => {
    return {
        type: actionTypes.ADD_LOAD_STOP_LOADING_CLEAR
    }
};

export const addLoadStopErrorClear = () => {
    return {
        type: actionTypes.ADD_LOAD_STOP_ERROR_CLEAR
    }
};

export const addLoadStopCancel = () => {
    return {
        type: actionTypes.ADD_LOAD_STOP_CANCEL
    }
};

//#endregion
//#region Update Load Stop Methods

const changeSingleLoadStop = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_LOAD_STOP,
        payload: payload
    }
};

export const updateLoadStopStart = () => {
    return {
        type: actionTypes.UPDATE_LOAD_STOP_START
    }
};

export const updateLoadStopSuccess = () => {
    return {
        type: actionTypes.UPDATE_LOAD_STOP_SUCCESS
    }
};

export const updateLoadStopFail = (payload) => {
    return {
        type: actionTypes.UPDATE_LOAD_STOP_FAIL,
        payload: payload
    }
};

export const updateLoadStopLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_LOAD_STOP_LOADING_CLEAR
    }
};

export const updateLoadStopErrorClear = () => {
    return {
        type: actionTypes.UPDATE_LOAD_STOP_ERROR_CLEAR
    }
};

export const updateLoadStopCancel = () => {
    return {
        type: actionTypes.UPDATE_LOAD_STOP_CANCEL
    }
};

//#endregion
//#region Load Stops Methods

export const fetchLoadStopList = (loadId, load = null) => {
    return async (dispatch) => {
        if (isStringNotEmpty(loadId)) {
            try {
                dispatch(fetchLoadStopListStart(loadId));

                const loadStops = await Data.getLoadStops(loadId, load);
                dispatch(fetchLoadStopListSuccess(loadId, { records: loadStops }));
            } catch (error) {
                logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
                dispatch(fetchLoadStopListFail(loadId, { error: ErrorUtils.getErrorMessage(error) }));
            }
        }
    }
};

export const addLoadStop = (payload, docFiles, load) => {
    return async (dispatch) => {
        try {
            if (isObjectNotEmpty(load) && isStringNotEmpty(load.id)) {
                dispatch(addLoadStopStart());

                const newLoadStop = await Data.addLoadStop(payload, docFiles, load.id, load);
                if (isObjectNotEmpty(newLoadStop)) {
                    dispatch(addLoadStopToLoadStopList(newLoadStop.loadId, newLoadStop));
                    dispatch(actionCreators.loadEventUpdateTimeout(load.id, null, null, null, 'LOAD_UPDATED'));
                }

                dispatch(addLoadStopSuccess());
                dispatch(addLoadStopLoadingClear());
                dispatch(addLoadStopErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addLoadStopFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateLoadStop = (stopId, payload, originalStop, load) => {
    return async (dispatch) => {
        try {
            if (isObjectNotEmpty(load) && isStringNotEmpty(load.id)) {
                dispatch(updateLoadStopStart());

                const updatedLoadStop = await Data.updateLoadStop(stopId, payload, originalStop, load.id, load);
                if (isObjectNotEmpty(updatedLoadStop)) {
                    dispatch(updateLoadStopInLoadStopList(updatedLoadStop.loadId, updatedLoadStop));
                    dispatch(changeSingleLoadStop(updatedLoadStop));
                    dispatch(actionCreators.loadEventUpdateTimeout(load.id, null, null, null, 'LOAD_UPDATED'));
                }

                dispatch(updateLoadStopSuccess());
                dispatch(updateLoadStopLoadingClear());
                dispatch(updateLoadStopErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateLoadStopFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateLoadStopApptDetails = (stopId, loadId, payload, timeZone, load) => {
    return async (dispatch) => {
        try {
            dispatch(updateLoadStopStart());

            const updatedLoadStop = await Data.updateLoadStopApptDetails(stopId, loadId, payload, timeZone, load);
            if (isObjectNotEmpty(updatedLoadStop)) {
                dispatch(actionCreators.loadEventUpdateTimeout(loadId, null, null, null, 'LOAD_UPDATED'));
            }

            dispatch(updateLoadStopSuccess());
            dispatch(updateLoadStopLoadingClear());
            dispatch(updateLoadStopErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateLoadStopFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const completeLoadStop = (payload, stop, load) => {
    return async (dispatch) => {
        try {
            dispatch(updateLoadStopStart());

            const updatedLoadEvent = await Data.completeLoadStop(load.id, stop.id, stop.stopType, payload);
            if (isObjectNotEmpty(updatedLoadEvent)) {
                dispatch(actionCreators.loadEventUpdateTimeout(updatedLoadEvent.loadId, updatedLoadEvent.stopId, null, null, updatedLoadEvent.eventType));
            }

            dispatch(updateLoadStopSuccess());
            dispatch(updateLoadStopLoadingClear());
            dispatch(updateLoadStopErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateLoadStopFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const removeLoadStop = (stopId, loadId) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updateLoadStopStart());

            const state = getState();
            const invoiceLineItemsState = { ...state.invoiceLineItems };
            const transactionsState = { ...state.transactions };
            const claimsState = { ...state.claims };
            let existingInvoiceLineItems = selectListRecords({ ...invoiceLineItemsState.lists }, loadId);
            let existingTransactions = selectListRecords({ ...transactionsState.lists }, loadId);
            let existingClaims = selectListRecords({ ...claimsState.lists }, loadId);

            const removedLoadStop = await Data.removeLoadStop(stopId, loadId, existingInvoiceLineItems, existingTransactions, existingClaims);
            if (isObjectNotEmpty(removedLoadStop)) {
                dispatch(removeLoadStopFromLoadStopList(removedLoadStop.loadId, removedLoadStop));
                dispatch(actionCreators.loadEventUpdateTimeout(loadId, null, null, null, 'LOAD_UPDATED'));
            }

            dispatch(updateLoadStopSuccess());
            dispatch(updateLoadStopLoadingClear());
            dispatch(updateLoadStopErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateLoadStopFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion