import React from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Row, Typography } from 'antd';
import { useSelector } from "react-redux";
import CompanyShipperSnapshot from '../../components/CompanyShipperSnapshot/CompanyShipperSnapshot';
import CompanyCarrierSnapshot from '../../components/CompanyCarrierSnapshot/CompanyCarrierSnapshot';
import CompanyBrokerSnapshot from '../../components/CompanyBrokerSnapshot/CompanyBrokerSnapshot';
import CompanyFactoringSnapshot from '../../components/CompanyFactoringSnapshot/CompanyFactoringSnapshot';
import CompanyProducerSnapshot from '../../components/CompanyProducerSnapshot/CompanyProducerSnapshot';
import CompanyReceiverSnapshot from '../../components/CompanyReceiverSnapshot/CompanyReceiverSnapshot';
import StaffDashboard from '../../components/StaffDashboard/StaffDashboard';
import { isStringNotEmpty } from '../../shared/objectUtils';

const Dashboard = (props) => {
    //#region useSelectors

    const entityType = useSelector(state => state.auth.entityType);

    //#endregion

    if (isStringNotEmpty(entityType)) {
        if (entityType === "STAFF") {
            return <StaffDashboard />;
        } else if (entityType === "SHIPPER") {
            return <CompanyShipperSnapshot />;
        } else if (entityType === "CARRIER") {
            return <CompanyCarrierSnapshot />;
        } else if (entityType === "BROKER") {
            return <CompanyBrokerSnapshot />;
        } else if (entityType === "FACTORING") {
            return <CompanyFactoringSnapshot />;
        } else if (entityType === "PRODUCER") {
            return <CompanyProducerSnapshot />;
        } else if (entityType === "RECEIVER") {
            return <CompanyReceiverSnapshot />;
        } else if (entityType === "NONE") {
            return (
                <Row gutter={[24, 24]}>
                        <Col span={24}>
                            <Typography.Title level={2} style={{ textAlign: 'center' }}>Account Registration</Typography.Title>
                        </Col>
                        <Col span={12}>
                            <Typography.Title level={3} style={{ textAlign: 'center' }}>What Type of Account Do You Need?</Typography.Title>
                        </Col>
                        <Col span={12}>
                            <Typography.Title level={3} style={{ textAlign: 'center' }}>Select All That Apply Below?</Typography.Title>
                        </Col>
                    </Row>
            );
        } else {
            return null;
        }
    } else {
        return null;
    }
};

export default withRouter(Dashboard);