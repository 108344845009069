import React, { useState } from 'react';
import { Button, Card, Modal } from "antd";
import classes from './DispatchAssetCard.module.scss';
import DispatchAddAsset from "../../components/DispatchAddAsset/DispatchAddAsset";
import DispatchChangeAsset from '../DispatchChangeAsset/DispatchChangeAsset';
import RemoveLoadAsset from "../../components/RemoveLoadAsset/RemoveLoadAsset";
import { useSelector } from "react-redux";
import ActionButton from '../../components/ActionButton/ActionButton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt, faPlus, faTimes, faTruck } from '@fortawesome/free-solid-svg-icons';
import { CloseOutlined } from '@ant-design/icons';
import StringFormatter from '../../shared/stringFormatter';
import ActionDoubleButton from '../ActionDoubleButton/ActionDoubleButton';
import { isBooleanTrue, isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import LoadUtils from '../../api/utils/loadUtils';

const stringFormatter = new StringFormatter();

const DispatchAssetCard = ({ load, assets, drivers, bundledLoads, assetLoads, transportUnits, setAssetSideBar, setDriverSideBar, displayAsText = false, setSelectedLoadToDispatchId, isBundledLoad = false, style }) => {
    //#region props

    const loadDriverIds = isObjectNotEmpty(load) && isListNotEmpty(load.driverIds) ? load.driverIds : [];
    const loadAssetIds = isObjectNotEmpty(load) && isListNotEmpty(load.assetIds) ? load.assetIds : [];
    const carrierId = isObjectNotEmpty(load) && isStringNotEmpty(load.assignedCarrierId) ? load.assignedCarrierId : null;
    
    //#endregion
    //#region useSelectors

    const isLoading = useSelector(state => state.loads.isRecordUpdateLoading);
    const error = useSelector(state => state.loads.updateRecordError);
    const loadEventUpdateStatus = useSelector(state => state.loadEvents.loadEventUpdateStatus);

    //#endregion
    //#region useStates

    const [showRemoveAssetModal, setShowRemoveAssetModal] = useState(false);

    //#endregion
    //#region Load to Dispatch Methods

    const onSelectLoadToDispatch = () => {
        if (isBooleanTrue(isBundledLoad) && isObjectNotEmpty(load)) {
            setAssetSideBar(null);
            setDriverSideBar(null);
            setSelectedLoadToDispatchId(load.id);
        }
    };

    //#endregion
    //#region displays

    const newEntityComponents = (
        <Card
            title={"Assign Asset to Load"}
            style={{ border: '1px solid #D8D8D8', marginBottom: 12 }}
            bodyStyle={{ backgroundColor: '#F5F5F5', minHeight: 100, padding: 12 }}
            headStyle={{ backgroundColor: '#334353', color: '#ffffff', borderBottom: '1px solid #D8D8D8', fontSize: 14, textAlign: 'center' }}
            extra={<Button type="link" icon={<CloseOutlined />} onClick={(e) => { setAssetSideBar(null); }}></Button>}
        >
            <DispatchAddAsset
                cancel={() => { setAssetSideBar(null); }}
                loadDriverIds={loadDriverIds}
                drivers={drivers}
                assets={assets}
                bundledLoads={bundledLoads}
                assetLoads={assetLoads}
                transportUnits={transportUnits}
                load={load}
                isBundledLoad={isBooleanTrue(isBundledLoad)}
                carrierId={carrierId}
            />
        </Card>
    );

    const changeEntityComponents = (asset) => {
        return (
            <Card
                title={"Change Asset"}
                style={{ border: '1px solid #D8D8D8', marginBottom: 12 }}
                bodyStyle={{ backgroundColor: '#F5F5F5', minHeight: 100, padding: 12 }}
                headStyle={{ backgroundColor: '#334353', color: '#ffffff', borderBottom: '1px solid #D8D8D8', fontSize: 14, textAlign: 'center' }}
                extra={<Button type="link" icon={<CloseOutlined />} onClick={(e) => { setAssetSideBar(null); }}></Button>}
            >
                <DispatchChangeAsset
                    cancel={() => { setAssetSideBar(null); }}
                    loadDriverIds={loadDriverIds}
                    asset={asset}
                    assets={assets}
                    bundledLoads={bundledLoads}
                    assetLoads={assetLoads}
                    transportUnits={transportUnits}
                    load={load}
                    isBundledLoad={isBooleanTrue(isBundledLoad)}
                    carrierId={carrierId}
                />
            </Card>
        );
    };

    const removeEntityComponents = (asset) => {
        return (
            <Modal
                title={null}
                visible={showRemoveAssetModal === true}
                maskClosable={false}
                closable={false}
                footer={null}
                bodyStyle={{ padding: 0 }}
                destroyOnClose={true}
            >
                <Card
                    title={"Confirm Cancellation"}
                    style={{ border: '1px solid #D8D8D8' }}
                    bodyStyle={{ minHeight: 100, padding: '12px 12px 57px 12px' }}
                    headStyle={{ backgroundColor: '#334353', color: '#ffffff', borderBottom: '1px solid #D8D8D8', fontSize: 14, textAlign: 'center' }}
                    extra={<Button type="link" icon={<CloseOutlined />} onClick={(e) => { setShowRemoveAssetModal(false); }}></Button>}
                >
                    <RemoveLoadAsset
                        cancel={() => { setShowRemoveAssetModal(false); }}
                        asset={asset}
                        load={load}
                        isBundledLoad={isBooleanTrue(isBundledLoad)}
                    />
                </Card>
            </Modal>
        );
    };

    const renderAssetDetails = (loadAsset) => {
        if (isObjectNotEmpty(loadAsset)) {
            let assetType = stringFormatter.toFormattedString("AssetType", loadAsset.assetType, null);
            let trailerType = (loadAsset.assetType === 'TRAILER' || loadAsset.assetType === 'COMBINED') ? (loadAsset.trailerType !== undefined && loadAsset.trailerType !== null ? loadAsset.trailerType.description : '') : null;
            let licensePlate = `${loadAsset.licensePlateNum} ${loadAsset.licensePlateState}`;
            let assetTitle = loadAsset.name ? loadAsset.name : licensePlate;

            return (
                <span><strong>{assetTitle}</strong><br /><i>Type: {assetType}{trailerType ? ' - ' + trailerType : ''}</i></span>
            );
        } else {
            return null
        }
    };

    const renderSingleRowAssetDetails = (loadAsset) => {
        if (isObjectNotEmpty(loadAsset)) {
            let assetType = stringFormatter.toFormattedString("AssetType", loadAsset.assetType, null);
            let trailerType = (loadAsset.assetType === 'TRAILER' || loadAsset.assetType === 'COMBINED') ? (loadAsset.trailerType !== undefined && loadAsset.trailerType !== null ? loadAsset.trailerType.description : '') : null;
            let licensePlate = `${loadAsset.licensePlateNum} ${loadAsset.licensePlateState}`;
            let assetTitle = loadAsset.name ? loadAsset.name : licensePlate;
            let assetTypeTitle = loadAsset.assetType === 'TRACTOR' ? assetType : trailerType;

            return (
                <span>{assetTypeTitle}: {assetTitle}</span>
            );
        } else {
            return null
        }
    };

    //#endregion

    if (isObjectNotEmpty(load) && load.isDeleted === false && LoadUtils.loadStatusIsNot(load.loadStatus, ['CANCELLED'])) {
        if (displayAsText === true) {
            if (LoadUtils.loadStatusIs(load.loadStatus, ['BOOKED'])) {
                return (
                    <>
                        {isListNotEmpty(loadAssetIds) ? (
                            <>
                                {loadAssetIds.map((loadAssetId) => {
                                    let loadAsset = assets.find(i => i.id === loadAssetId);
                                    if (isObjectNotEmpty(loadAsset)) {
                                        return (
                                            <div key={`load-asset-text-${load.id}-${loadAssetId}`}>
                                                <Button type="link" onClick={() => { onSelectLoadToDispatch(); setAssetSideBar(changeEntityComponents(loadAsset)); setDriverSideBar(null); }}>{renderSingleRowAssetDetails(loadAsset)}</Button>
                                            </div>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                                <div key={`load-asset-text-${load.id}-add-another`}>
                                    <Button type="link" onClick={() => { onSelectLoadToDispatch(); setAssetSideBar(newEntityComponents); setDriverSideBar(null); }}><span>Assign another Asset</span></Button>
                                </div>
                            </>
                        ) : (
                            <div key={`load-asset-text-${load.id}-add`}>
                                <Button type="link" onClick={() => { onSelectLoadToDispatch(); setAssetSideBar(newEntityComponents); setDriverSideBar(null); }}><span>Assign as Asset</span></Button>
                            </div>
                        )}
                    </>
                );
            } else if (LoadUtils.loadStatusIs(load.loadStatus, ['SCHEDULED', 'IN_TRANSIT', 'AT_STOP', 'COMPLETED', 'CLOSED', 'REOPENED'])) {
                return (
                    <>
                        {isListNotEmpty(loadAssetIds) ? (
                            <>
                                {loadAssetIds.map((loadAssetId) => {
                                    let loadAsset = assets.find(i => i.id === loadAssetId);
                                    if (isObjectNotEmpty(loadAsset)) {
                                        return (
                                            <div key={`load-asset-text-${load.id}-${loadAssetId}`}>{renderSingleRowAssetDetails(loadAsset)}</div>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                            </>
                        ) : null}
                    </>
                );
            } else {
                return null;
            }
        } else {
            if (LoadUtils.loadStatusIs(load.loadStatus, ['BOOKED'])) {
                return (
                    <>
                        {isListNotEmpty(loadAssetIds) ? (
                            <>
                                {loadAssetIds.map((loadAssetId) => {
                                    let loadAsset = assets.find(i => i.id === loadAssetId);
                                    if (isObjectNotEmpty(loadAsset)) {
                                        return (
                                            <div style={style} key={`load-asset-${load.id}-${loadAssetId}`}>
                                                <ActionDoubleButton
                                                    buttonGroupStyle={{ marginBottom: 12 }}
                                                    iconPosition="left"
                                                    buttonGroupClassName={classes.buttonGroup}
                                                    buttonClassName={`${classes.buttonGroupButton} ${classes.whiteButtonSolidBorder}`}
                                                    buttonIconClassName={classes.iconContainer}
                                                    buttonTextClassName={classes.textContainer}
                                                    button1Icon1={<FontAwesomeIcon className={classes.button1icon1} icon={faTruck} />}
                                                    button1Icon2={<FontAwesomeIcon className={classes.button1icon2} icon={faExchangeAlt} />}
                                                    button2Icon1={<FontAwesomeIcon className={classes.button2icon1} icon={faTimes} />}
                                                    buttonText={renderAssetDetails(loadAsset)}
                                                    button1Style={{ width: '100%' }}
                                                    button2Style={{ minWidth: 'unset' }}
                                                    onClick1={() => { setAssetSideBar(changeEntityComponents(loadAsset)); setDriverSideBar(null); }}
                                                    onClick2={() => { setShowRemoveAssetModal(true); }}
                                                    disabled={((isLoading === true && error === null) || loadEventUpdateStatus === 'PENDING')}
                                                />
                                                {removeEntityComponents(loadAsset)}
                                            </div>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                                <div style={style} key={`load-asset-${load.id}-add-another`}>
                                    <ActionButton
                                        iconPosition="left"
                                        buttonClassName={`${classes.button} ${classes.whiteButtonDashedBorder}`}
                                        buttonIconClassName={classes.iconContainer}
                                        buttonTextClassName={classes.textContainer}
                                        buttonIcon={<FontAwesomeIcon className={classes.button1icon1} icon={faTruck} />}
                                        buttonIcon2={<FontAwesomeIcon className={classes.button1icon2} icon={faPlus} />}
                                        buttonText={<span>Assign another Asset</span>}
                                        onClick={() => { setAssetSideBar(newEntityComponents); setDriverSideBar(null); }}
                                        disabled={((isLoading === true && error === null) || loadEventUpdateStatus === 'PENDING')}
                                    />
                                </div>
                            </>
                        ) : (
                            <div style={style} key={`load-asset-${load.id}-add`}>
                                <ActionButton
                                    iconPosition="left"
                                    buttonClassName={`${classes.button} ${classes.whiteButtonDashedBorder}`}
                                    buttonIconClassName={classes.iconContainer}
                                    buttonTextClassName={classes.textContainer}
                                    buttonIcon={<FontAwesomeIcon className={classes.button1icon1} icon={faTruck} />}
                                    buttonIcon2={<FontAwesomeIcon className={classes.button1icon2} icon={faPlus} />}
                                    buttonText={<span>Assign an Asset</span>}
                                    onClick={() => { setAssetSideBar(newEntityComponents); setDriverSideBar(null); }}
                                    disabled={((isLoading === true && error === null) || loadEventUpdateStatus === 'PENDING')}
                                />
                            </div>
                        )}
                    </>
                );
            } else {
                return null;
            }
        }
    } else {
        return null;
    }
};

export default DispatchAssetCard;