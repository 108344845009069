import Checks from '../entitlementUtils';

export const canCreateState = (auth) => {
    return Checks.allowStaffAdmin(auth);
};

export const canReadState = (auth) => {
    return Checks.allowStaffAdmin(auth);
};

export const canReadStateList = (auth) => {
    return Checks.allowStaffAdmin(auth);
};

export const canUpdateState = (auth) => {
    return Checks.allowStaffAdmin(auth);
};

export const canDeleteState = (auth) => {
    return Checks.allowStaffAdmin(auth);
};