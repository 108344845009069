import * as actionTypes from "../actions/actionTypes";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { isBooleanTrue, isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import * as actionCreators from "./index";
import ErrorUtils from "../../shared/errorUtils";

//#region Fetch Carrier Rejection List Methods

export const fetchCarrierRejectionListStart = (listName) => {
    return {
        type: actionTypes.FETCH_CARRIER_REJECTION_LIST_START,
        payload: { listName: listName }
    }
};

export const fetchCarrierRejectionListSuccess = (listName, payload) => {
    return {
        type: actionTypes.FETCH_CARRIER_REJECTION_LIST_SUCCESS,
        payload: { listName: listName, ...payload }
    }
};

export const fetchCarrierRejectionListFail = (listName, payload) => {
    return {
        type: actionTypes.FETCH_CARRIER_REJECTION_LIST_FAIL,
        payload: { listName: listName, ...payload }
    }
};

export const clearCarrierRejectionList = (listName) => {
    return {
        type: actionTypes.CLEAR_CARRIER_REJECTION_LIST,
        payload: { listName: listName }
    }
};

export const clearCarrierRejectionLists = () => {
    return {
        type: actionTypes.CLEAR_CARRIER_REJECTION_LISTS
    }
};

export const addCarrierRejectionToCarrierRejectionList = (listName, payload) => {
    return {
        type: actionTypes.ADD_TO_CARRIER_REJECTION_LIST,
        payload: { listName: listName, newRecord: payload }
    }
};

export const updateCarrierRejectionInCarrierRejectionList = (listName, payload) => {
    return {
        type: actionTypes.UPDATE_IN_CARRIER_REJECTION_LIST,
        payload: { listName: listName, updatedRecord: payload }
    }
};

export const removeCarrierRejectionFromCarrierRejectionList = (listName, payload) => {
    return {
        type: actionTypes.REMOVE_FROM_CARRIER_REJECTION_LIST,
        payload: { listName: listName, recordToRemove: payload }
    }
};

//#endregion
//#region Add Carrier Rejection Methods

export const addCarrierRejectionStart = () => {
    return {
        type: actionTypes.ADD_CARRIER_REJECTION_START
    }
};

export const addCarrierRejectionSuccess = () => {
    return {
        type: actionTypes.ADD_CARRIER_REJECTION_SUCCESS
    }
};

export const addCarrierRejectionFail = (payload) => {
    return {
        type: actionTypes.ADD_CARRIER_REJECTION_FAIL,
        payload: payload
    }
};

export const addCarrierRejectionLoadingClear = () => {
    return {
        type: actionTypes.ADD_CARRIER_REJECTION_LOADING_CLEAR
    }
};

export const addCarrierRejectionErrorClear = () => {
    return {
        type: actionTypes.ADD_CARRIER_REJECTION_ERROR_CLEAR
    }
};

export const addCarrierRejectionCancel = () => {
    return {
        type: actionTypes.ADD_CARRIER_REJECTION_CANCEL
    }
};

//#endregion
//#region Update Carrier Rejection Methods

const changeSingleCarrierRejection = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_CARRIER_REJECTION,
        payload: payload
    }
};

export const updateCarrierRejectionStart = () => {
    return {
        type: actionTypes.UPDATE_CARRIER_REJECTION_START
    }
};

export const updateCarrierRejectionSuccess = () => {
    return {
        type: actionTypes.UPDATE_CARRIER_REJECTION_SUCCESS
    }
};

export const updateCarrierRejectionFail = (payload) => {
    return {
        type: actionTypes.UPDATE_CARRIER_REJECTION_FAIL,
        payload: payload
    }
};

export const updateCarrierRejectionLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_CARRIER_REJECTION_LOADING_CLEAR
    }
};

export const updateCarrierRejectionErrorClear = () => {
    return {
        type: actionTypes.UPDATE_CARRIER_REJECTION_ERROR_CLEAR
    }
};

export const updateCarrierRejectionCancel = () => {
    return {
        type: actionTypes.UPDATE_CARRIER_REJECTION_CANCEL
    }
};

//#endregion
//#region Carrier Rejection Methods

export const fetchCarrierRejectionList = (listName, payload) => {
    return async (dispatch, getState) => {
        if (isStringNotEmpty(listName)) {
            try {
                dispatch(fetchCarrierRejectionListStart(listName));

                await dispatch(actionCreators.getAccounts());
                const state = getState();
                const orchestratorState = { ...state.orchestrator };
                const accounts = [...orchestratorState.accounts];
                const carrierRejectionsState = { ...state.carrierRejections };
                const existingLists = { ...carrierRejectionsState.lists };

                let pagination = {};
                let searchParams = {};
                if (isObjectNotEmpty(existingLists[listName])) {
                    pagination = { ...existingLists[listName].pagination };
                    searchParams = { ...existingLists[listName].searchParams };
                }

                if (isObjectNotEmpty(payload)) {
                    searchParams = { ...payload };
                }

                const res = await Data.getCarrierRejections({ ...searchParams }, pagination, accounts);
                dispatch(fetchCarrierRejectionListSuccess(listName, { records: res.data, params: { searchParams: res.searchParams, pagination: res.pagination } }));
            } catch (error) {
                logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
                dispatch(fetchCarrierRejectionListFail(listName, { error: ErrorUtils.getErrorMessage(error) }));
            }
        }
    }
};

export const addCarrierRejection = (payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(addCarrierRejectionStart());

            await dispatch(actionCreators.getAccounts());
                const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const accounts = [...orchestratorState.accounts];

            const newCarrierRejection = await Data.addCarrierRejection(payload, accounts);
            if (isObjectNotEmpty(newCarrierRejection)) {
                dispatch(addCarrierRejectionToCarrierRejectionList(newCarrierRejection.loadId, newCarrierRejection));
                dispatch(actionCreators.loadEventUpdateTimeout(payload.loadId, null, null, null, 'LOAD_REQUEST_TO_CARRIER_REJECTED'));
            }

            dispatch(addCarrierRejectionSuccess());
            dispatch(addCarrierRejectionLoadingClear());
            dispatch(addCarrierRejectionErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addCarrierRejectionFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateCarrierRejection = (id, payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updateCarrierRejectionStart());

            if (isBooleanTrue(payload.isDeleted)) {
                const removedCarrierRejection = await Data.removeCarrierRejection(id);
                if (isObjectNotEmpty(removedCarrierRejection)) {
                    dispatch(removeCarrierRejectionFromCarrierRejectionList(removedCarrierRejection.loadId, removedCarrierRejection));
                }
            } else {
                await dispatch(actionCreators.getAccounts());
                const state = getState();
                const orchestratorState = { ...state.orchestrator };
                const accounts = [...orchestratorState.accounts];

                const updatedCarrierRejection = await Data.updateCarrierRejection(id, payload, accounts);
                if (isObjectNotEmpty(updatedCarrierRejection)) {
                    dispatch(updateCarrierRejectionInCarrierRejectionList(updatedCarrierRejection.loadId, updatedCarrierRejection));
                    dispatch(changeSingleCarrierRejection(updatedCarrierRejection));
                }
            }

            dispatch(updateCarrierRejectionSuccess());
            dispatch(updateCarrierRejectionLoadingClear());
            dispatch(updateCarrierRejectionErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateCarrierRejectionFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion