import React, { useEffect, useRef, useMemo, useState } from 'react';
import { Wrapper } from "@googlemaps/react-wrapper";
import PubNub from 'pubnub';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { reverse } from 'lodash';
import Enums from '../../shared/enums';
import logger from '../../shared/logger';
import { isBooleanTrue, isListEmpty, isListNotEmpty, isNotNullOrUndefined, isNumberNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { selectListIsLoading, selectListPagination, selectListRecords } from '../../store/utility';
import { message, Spin } from 'antd';
import LoadStopUtils from '../../api/utils/loadStopUtils';
import StringFormatter from '../../shared/stringFormatter';
import { UnselectLoadControl } from '../../shared/MapUtils/UnselectLoadControl';
import { useDeepCompareEffectForMaps } from '../../shared/MapUtils/useDeepCompareEffectForMaps';

const stringFormatter = new StringFormatter();

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const render = (status) => {
    return <h1>{status}</h1>;
};

const LoadsMap = ({
    shipperId,
    carrierId,
    driverId,
    assignedAccountRepUserId,
    brokerId,
    filter = "IN_TRANSIT_ALL",
    defaultLoadStatusFilter = ["IN_TRANSIT", "AT_STOP"],
    filterSearchParams = { sort: 'pickUpDateTime', order: 'asc', isDeleted: false, eta: false },
    showZoomControl = true,
    notAuthenticated = false,
    containerName = null,
    fullScreen = false,
    height = "500px",
    style = {}
}) => {
    const [clicks, setClicks] = React.useState([]);
    const [zoom, setZoom] = React.useState(3); // initial zoom
    const [center, setCenter] = React.useState({
        lat: 37.8,
        lng: -96,
    });

    const onClick = (e) => {
        // avoid directly mutating state
        // setClicks([...clicks, e.latLng]);
    };

    const onIdle = (m) => {
        // console.log("onIdle");
        // setZoom(m.getZoom());
        // setCenter(m.getCenter().toJSON());
    };

    return (
        <Wrapper apiKey={apiKey} render={render}>
            <CustomMap
                center={center}
                onClick={onClick}
                onIdle={onIdle}
                zoom={zoom}
                shipperId={shipperId}
                carrierId={carrierId}
                driverId={driverId}
                assignedAccountRepUserId={assignedAccountRepUserId}
                brokerId={brokerId}
                filter={filter}
                defaultLoadStatusFilter={defaultLoadStatusFilter}
                filterSearchParams={filterSearchParams}
                showZoomControl={showZoomControl}
                notAuthenticated={notAuthenticated}
                containerName={containerName}
                fullScreen={fullScreen}
                height={height}
                style={style}
            //style={{ flexGrow: "1", height: "100%" }}
            >
                {clicks.map((latLng, i) => (
                    <Marker key={i} position={latLng} />
                ))}
            </CustomMap>
        </Wrapper>
    );
};

const Marker = (options) => {
    const [marker, setMarker] = React.useState();

    React.useEffect(() => {
        if (!marker) {
            setMarker(new window.google.maps.Marker());
        }

        // remove marker from map on unmount
        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [marker]);

    React.useEffect(() => {
        if (marker) {
            marker.setOptions(options);
        }
    }, [marker, options]);

    return null;
};

const CustomMap = ({
    onClick,
    onIdle,
    children,
    style,
    shipperId,
    carrierId,
    driverId,
    assignedAccountRepUserId,
    brokerId,
    filter = "IN_TRANSIT_ALL",
    defaultLoadStatusFilter = ["IN_TRANSIT", "AT_STOP"],
    filterSearchParams = { sort: 'pickUpDateTime', order: 'asc', isDeleted: false, eta: false },
    showZoomControl = true,
    containerName = null,
    fullScreen = false,
    height = "500px",
    ...options
}) => {
    //#region constants

    containerName = isStringNotEmpty(containerName) ? containerName : `map-${filter}`;

    const currentLocationMarker = {
        url: "https://map.b4x83us.irisfreight.com/current_location_mark.png",
        scaledSize: new window.google.maps.Size(30, 30),
        anchor: new window.google.maps.Point(15, 15),
        scale: 0.7,
    };
    const pickUpMarker = {
        url: "https://map.b4x83us.irisfreight.com/pickUpMarker.png",
        scaledSize: new window.google.maps.Size(30, 30),
        anchor: new window.google.maps.Point(15, 15),
        scale: 0.7,
    };
    const dropOffMarker = {
        url: "https://map.b4x83us.irisfreight.com/dropOffMarker.png",
        scaledSize: new window.google.maps.Size(30, 30),
        anchor: new window.google.maps.Point(15, 15),
        scale: 0.7,
    };
    const restStopMarker = {
        url: "https://map.b4x83us.irisfreight.com/restStopMarker.png",
        scaledSize: new window.google.maps.Size(30, 30),
        anchor: new window.google.maps.Point(15, 15),
        scale: 0.7,
    };

    //#endregion
    //#region useRefs

    // create map
    const mapRef = useRef(null);
    const actualPathRef = useRef(null);
    const markersRef = useRef(null);
    const directionsServiceRef = useRef(null);
    const directionsRendererRef = useRef(null);
    const unselectLoadButtonRef = useRef(null);
    const currentLocationMarkersRef = useRef(null);

    //#endregion
    //#region useStates

    const [selectedLoadId, setSelectedLoadId] = useState(null);
    const [selectedLoad, setSelectedLoad] = useState(null);
    const [map, setMap] = useState();

    //#endregion
    //#region useSelectors

    const dispatch = useDispatch();
    const userId = useSelector(state => state.auth.userId);
    const pubNubSubKey = useSelector(state => state.keys.pubNubSubKey);
    const pubNubPubKey = useSelector(state => state.keys.pubNubPubKey);
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);
    const loads = useSelector(state => selectListRecords(state.loads.lists, filter));
    const isLoadingLoads = useSelector(state => selectListIsLoading(state.loads.lists, filter));
    const stops = useSelector(state => selectListRecords(state.loadStops.lists, isStringNotEmpty(selectedLoadId) ? selectedLoadId : null));
    const isLoadingStops = useSelector(state => selectListIsLoading(state.loadStops.lists, isStringNotEmpty(selectedLoadId) ? selectedLoadId : null));
    const activeLoads = useSelector(state => selectListRecords(state.loads.lists, filter + '_MAP'));
    const isLoadingActiveLoads = useSelector(state => selectListIsLoading(state.loads.lists, filter + '_MAP'));

    //#endregion
    //#region load methods

    const getActiveLoads = (loadIds = []) => {
        if (isListNotEmpty(loadIds)) {
            let searchParams = {
                page: 1,
                size: 1000000,
                loadId: [...loadIds].join('|')
            };

            dispatch(actionCreators.fetchActiveLoadList(filter + '_MAP', searchParams));
        }
    };

    const getLoads = () => {
        if (isStringNotEmpty(entityType) && isStringNotEmpty(entityId)) {
            let searchParams = {
                page: 1,
                size: 1000000,
                ...filterSearchParams
            };

            if (entityType === "DRIVER") {
                searchParams.driverIds = entityId;
            } else if (entityType === "SHIPPER") {
                searchParams.shipperId = entityId;
            } else if (entityType === "BROKER") {
                searchParams.brokerId = entityId;
            } else if (entityType === "CARRIER") {
                searchParams.assignedCarrierId = entityId;

                if (isStringNotEmpty(driverId)) {
                    searchParams.driverIds = driverId;
                }
            } else if (entityType === "STAFF") {
                if (isStringNotEmpty(shipperId)) {
                    searchParams.shipperId = shipperId;
                }

                if (isStringNotEmpty(brokerId)) {
                    searchParams.brokerId = brokerId;
                }

                if (isStringNotEmpty(carrierId)) {
                    searchParams.assignedCarrierId = carrierId;
                }

                if (isStringNotEmpty(driverId)) {
                    searchParams.driverIds = driverId;
                }

                if (isStringNotEmpty(assignedAccountRepUserId)) {
                    searchParams.assignedAccountRepUserIds = assignedAccountRepUserId;
                }
            }

            searchParams.loadStatus = defaultLoadStatusFilter.join('|');

            dispatch(actionCreators.fetchLoadList(filter, searchParams));
        }
    };

    //#endregion
    //#region map methods

    const updateCurrentLocation = (loadId, load, latitude, longitude) => {
        if (map && isStringNotEmpty(loadId) && isObjectNotEmpty(load)) {
            // mapRef.current.jumpTo({
            //     center: { lat: latitude, lng: longitude },
            //     //zoom: 14
            // });

            const currentStatus = load.loadStatus;
            const currentStatusDisplay = currentStatus === 'IN_TRANSIT' ? 'In-Transit' : 'At Stop';
            const title = '<h3>' + load.irisId + '</h3>';
            const body = '<p>' +
                '<b>Current Status:</b> ' + currentStatusDisplay +
                '<br /><b>ETA:</b> ' + stringFormatter.toFormattedString('MomentDateTime', load.eta, null, load.etaTimeZone) +
                '<br /><b>Origin:</b> ' + LoadStopUtils.getLoadStopLocationName(load.origin, load.shipperId, load.assignedCarrierId, load.createdByEntityType) +
                '<br /><b>Destination:</b> ' + LoadStopUtils.getLoadStopLocationName(load.destination, load.shipperId, load.assignedCarrierId, load.createdByEntityType) +
                '<br /><b>Shipper:</b> ' + load.shipper.name +
                '<br /><b>Carrier:</b> ' + load.assignedCarrier.name +
                '<br /><b>Drivers:</b> ' + load.drivers.map((driver) => { return driver.firstName + ' ' + driver.lastName; }).join(', ') +
                '</p>';
            const content = title + body;

            let updatedCurrentLocationMarkers = currentLocationMarkersRef.current ? [...currentLocationMarkersRef.current] : [];
            let updatedLocationMarker = updatedCurrentLocationMarkers.find(l => l.loadId === loadId);
            let updatedLocationMarkerIndex = updatedCurrentLocationMarkers.findIndex(l => l.loadId === loadId);
            if (updatedLocationMarker !== undefined) {
                updatedLocationMarker.setPosition({ lat: latitude, lng: longitude });

                const infoWindow = new window.google.maps.InfoWindow({
                    content: content
                });

                updatedLocationMarker.addListener('mouseover', function () {
                    infoWindow.open({
                        anchor: updatedLocationMarker,
                        map,
                        shouldFocus: false,
                    });
                });

                updatedLocationMarker.addListener('mouseout', function () {
                    infoWindow.close();
                });
                updatedCurrentLocationMarkers[updatedLocationMarkerIndex] = updatedLocationMarker;
                currentLocationMarkersRef.current = updatedCurrentLocationMarkers;
            } else {
                updatedLocationMarker = new window.google.maps.Marker({
                    map,
                    draggable: false,
                    position: { lat: latitude, lng: longitude },
                    title: load.irisId,
                    icon: currentLocationMarker,
                    id: load.id,
                    loadId: load.id
                });

                updatedLocationMarker.loadId = loadId;

                const infoWindow = new window.google.maps.InfoWindow({
                    content: content
                });

                updatedLocationMarker.addListener('mouseover', function () {
                    infoWindow.open({
                        anchor: updatedLocationMarker,
                        map,
                        shouldFocus: false,
                    });
                });

                updatedLocationMarker.addListener('mouseout', function () {
                    infoWindow.close();
                });

                updatedLocationMarker.addListener('click', function (e) {
                    //mapRef.current.getCanvas().style.cursor = 'pointer'; // When the cursor enters a feature, set it to a pointer
                    //console.log(e);
                    setSelectedLoadId(load.id);
                    message.loading('Please wait while we get the route for this load...');
                });

                updatedCurrentLocationMarkers.push(updatedLocationMarker);
                console.log(updatedCurrentLocationMarkers);
                currentLocationMarkersRef.current = updatedCurrentLocationMarkers;
            }
        }
    };

    const addStopMarker = (markerIcon, stopId, stop, latitude, longitude, load) => {
        if (map) {
            let updatedMarkers = markersRef.current ? [...markersRef.current] : [];
            const marker = new window.google.maps.Marker({
                map,
                draggable: false,
                position: { lat: latitude, lng: longitude },
                title: LoadStopUtils.getLoadStopName(stop, load.shipperId, load.assignedCarrierId, load.createdByEntityType),
                icon: markerIcon
            });

            updatedMarkers.push(marker);
            console.log(updatedMarkers);
            markersRef.current = updatedMarkers;
        }
    };

    // Shows any markers currently in the array.
    const showMarkers = () => {
        if (isNotNullOrUndefined(map)) {
            if (isListNotEmpty(markersRef.current)) {
                let updatedMarkers = [...markersRef.current];
                for (let i = 0; i < updatedMarkers.length; i++) {
                    updatedMarkers[i].setMap(map);
                }
                markersRef.current = updatedMarkers;
            }
        }
    };

    // Removes the markers from the map, but keeps them in the array.
    const hideMarkers = () => {
        if (isListNotEmpty(markersRef.current)) {
            // console.log('hiding markers')
            let updatedMarkers = markersRef.current ? [...markersRef.current] : [];
            for (let i = 0; i < updatedMarkers.length; i++) {
                updatedMarkers[i].setMap(null);
            }
            markersRef.current = updatedMarkers;
        }
    };

    // Deletes all markers in the array by removing references to them.
    const deleteMarkers = () => {
        hideMarkers();

        if (markersRef.current !== null) {
            markersRef.current = [];
        }
        // console.log('delete markers');
    };

    const deleteRoute = () => {
        try {
            if (directionsRendererRef.current !== null) {
                directionsRendererRef.current.setMap(null);
            }

            deleteMarkers();
        } catch (err) {
            logger.logDebugEvent('Map.js', err.message, true);
        }
    };

    const unSelectLoad = () => {
        setSelectedLoadId(null);
        deleteRoute();

        try {
            map.controls[window.google.maps.ControlPosition.BOTTOM_LEFT].clear();
        } catch (ex) {
            console.log(ex);
        }
    };

    //#endregion
    //#region useEffects

    useEffect(() => {
        if (mapRef.current && !map) {
            setMap(new window.google.maps.Map(mapRef.current, {}));
        }
    }, [mapRef, map]);

    useEffect(() => {
        // console.log(showZoomControl)
        if (map) {
            map.setOptions({
                zoomControl: false,
                fullscreenControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false
            });

            if (showZoomControl === true) {
                map.setOptions({
                    zoomControl: true,
                    zoomControlOptions: {
                        position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
                    }
                });
            }
        }

        return () => {

        };
    }, [map, showZoomControl]);

    useMemo(() => {
        getLoads();
    }, [shipperId, carrierId, driverId, assignedAccountRepUserId, entityType, entityId]);

    useMemo(() => {
        if (isListNotEmpty(loads)) {
            const loadIds = loads.map((load) => load.id);
            getActiveLoads(loadIds);
        }
    }, [loads]);

    useMemo(() => {
        if (map && isListNotEmpty(activeLoads) && isListNotEmpty(loads)) {
            try {
                // console.log(activeLoads);
                //mapRef.current.setPOIVisibility(true);
                // mapRef.current.setZoom(4);
                // needed to keep from having a race condition where the current locations are added before the map is done being loaded
                setTimeout(() => {
                    activeLoads.forEach((activeLoad) => {
                        updateCurrentLocation(activeLoad.loadId, activeLoad, activeLoad.latitude, activeLoad.longitude);
                    });
                }, 3000);
            } catch (ex) {
                console.log(ex);
            }
        }
    }, [activeLoads, loads, map, currentLocationMarkersRef]);

    useMemo(() => {
        let pubnub = null;
        if (map && isStringNotEmpty(userId) && isStringNotEmpty(pubNubSubKey) && isStringNotEmpty(pubNubPubKey) && isListNotEmpty(activeLoads) && isListNotEmpty(loads)) {
            try {
                // console.log("Setting up load location PubNub!");
                pubnub = new PubNub({
                    publishKey: pubNubPubKey,
                    subscribeKey: pubNubSubKey,
                    ssl: true,
                    uuid: userId,
                    heartbeatInterval: 0
                });

                pubnub.addListener({
                    status: function (statusEvent) {
                        if (statusEvent.category === "PNConnectedCategory") {
                            // console.log("Connected to load location PubNub!");
                        }
                    },
                    message: function (msg) {
                        // console.log('Message from load location PubNub:');
                        // console.log(msg);
                        if (isObjectNotEmpty(msg) && isObjectNotEmpty(msg.message) && isStringNotEmpty(msg.message.loadId) && isNumberNotEmpty(msg.message.latitude) && isNumberNotEmpty(msg.message.longitude)) {
                            let currentLoad = activeLoads.find(l => l.loadId === msg.message.loadId);
                            updateCurrentLocation(msg.message.loadId, currentLoad, msg.message.latitude, msg.message.longitude);
                        }
                    }
                });

                let channels = [];
                activeLoads.forEach((load) => {
                    const channel = process.env.REACT_APP_LOAD_LOCATIONS_CHANNEL + '_' + load.loadId;
                    channels.push(channel);
                });

                //Subscribes to the channel in our state
                pubnub.subscribe({
                    channels: [...channels],
                    triggerEvents: true,
                    //withPresence: true,
                    //autoload: 100
                });
            } catch (ex) {
                console.log(ex);
            }
        } else if (isNotNullOrUndefined(pubnub)) {
            // console.log("Shutting down load location PubNub!");
            pubnub.unsubscribeAll();
        }

        return () => {
            if (isNotNullOrUndefined(pubnub)) {
                // console.log("Shutting down load location PubNub!");
                pubnub.unsubscribeAll();
            }
        };
    }, [map, userId, pubNubSubKey, pubNubPubKey, activeLoads, loads]);

    useMemo(() => {
        if (isStringNotEmpty(selectedLoadId) && isListNotEmpty(loads)) {
            const selectedLoadObj = loads.find(l => l.id === selectedLoadId);
            setSelectedLoad(selectedLoadObj);
            dispatch(actionCreators.fetchLoadStopList(selectedLoadId, selectedLoadObj));
        } else {
            setSelectedLoad(null);
        }
    }, [selectedLoadId, loads]);

    useEffect(() => {
        if (map) {
            try {
                if (isObjectNotEmpty(selectedLoad)) {
                    if (isNotNullOrUndefined(unselectLoadButtonRef.current)) {
                        try {
                            map.controls[window.google.maps.ControlPosition.BOTTOM_LEFT].clear();
                        } catch (ex) {
                            console.log(ex);
                        }

                        // Create the DIV to hold the control and call the UnselectLoadControl() constructor passing in this DIV.
                        unselectLoadButtonRef.current = document.createElement("div");

                        UnselectLoadControl(unselectLoadButtonRef.current, map, unSelectLoad, '<span>' + selectedLoad.irisId + '  <b>&#10006;</b></span>', 'Unselect Load ' + selectedLoad.irisId);
                        map.controls[window.google.maps.ControlPosition.BOTTOM_LEFT].push(unselectLoadButtonRef.current);

                    } else {
                        // Create the DIV to hold the control and call the UnselectLoadControl() constructor passing in this DIV.
                        unselectLoadButtonRef.current = document.createElement("div");

                        UnselectLoadControl(unselectLoadButtonRef.current, map, unSelectLoad, '<span>' + selectedLoad.irisId + '  <b>&#10006;</b></span>', 'Unselect Load ' + selectedLoad.irisId);
                        map.controls[window.google.maps.ControlPosition.BOTTOM_LEFT].push(unselectLoadButtonRef.current);
                    }
                } else {
                    map.setZoom(options.zoom);
                    map.setCenter(options.center);
                }
            } catch (ex) {
                console.log(ex);
            }
        }

        return () => {

        };
    }, [map, selectedLoad, unselectLoadButtonRef]);

    useEffect(() => {
        console.log(stops);
        // console.log(map);
        if (map && isStringNotEmpty(selectedLoadId) && isObjectNotEmpty(selectedLoad) && isListNotEmpty(stops)) {
            directionsServiceRef.current = new window.google.maps.DirectionsService();
            directionsRendererRef.current = new window.google.maps.DirectionsRenderer({
                suppressMarkers: true
            });

            directionsRendererRef.current.setMap(map);

            if (stops.filter(stop => stop.latitude !== undefined && stop.longitude !== undefined && stop.loadId === selectedLoadId).length > 0) {
                let mapStopsWithStopType = stops.filter(stop => stop.latitude !== undefined && stop.longitude !== undefined && stop.loadId === selectedLoadId).map((stop) => {
                    return {
                        stop: stop,
                        longitude: stop.longitude,
                        latitude: stop.latitude,
                        stopType: stop.stopType,
                        id: stop.id,
                        loadId: stop.loadId
                    };
                });

                if (mapStopsWithStopType.length > 1) {
                    let origin = mapStopsWithStopType[0];
                    let destination = mapStopsWithStopType[mapStopsWithStopType.length - 1];
                    let waypoints = [];

                    mapStopsWithStopType.forEach((stop) => {
                        if (stop.stopType === 'PICK_UP') {
                            addStopMarker(pickUpMarker, stop.id, stop.stop, stop.latitude, stop.longitude, selectedLoad);
                            if (stop.id !== origin.id) {
                                waypoints.push({
                                    location: { lat: stop.latitude, lng: stop.longitude },
                                    stopover: true
                                });
                            }
                        } else if (stop.stopType === 'DROP_OFF') {
                            addStopMarker(dropOffMarker, stop.id, stop.stop, stop.latitude, stop.longitude, selectedLoad);
                            if (stop.id !== destination.id) {
                                waypoints.push({
                                    location: { lat: stop.latitude, lng: stop.longitude },
                                    stopover: true
                                });
                            }
                        } else {
                            addStopMarker(restStopMarker, stop.id, stop.stop, stop.latitude, stop.longitude, selectedLoad);
                            waypoints.push({
                                location: { lat: stop.latitude, lng: stop.longitude },
                                stopover: true
                            });
                        }
                    });

                    directionsServiceRef.current
                        .route({
                            origin: { lat: origin.latitude, lng: origin.longitude },
                            destination: { lat: destination.latitude, lng: destination.longitude },
                            waypoints: waypoints,
                            travelMode: window.google.maps.TravelMode.DRIVING,
                            avoidFerries: true,
                            avoidTolls: true,
                            unitSystem: window.google.maps.UnitSystem.IMPERIAL,
                            drivingOptions: {
                                departureTime: new Date(Date.now() + 3600),  // for the time N milliseconds from now.
                                trafficModel: 'pessimistic'
                            }
                        })
                        .then((response) => {
                            console.log(response);

                            directionsRendererRef.current.setDirections(response);
                        })
                        .catch((e) => window.alert("Directions request failed due to " + e.message));
                }
            } else {
                deleteRoute();
            }
        }

        return () => {

        };
    }, [map, stops, selectedLoad, selectedLoadId, markersRef, directionsServiceRef, directionsRendererRef]);

    useDeepCompareEffectForMaps(() => {
        if (map) {
            map.setOptions(options);
        }
    }, [map, options]);

    useEffect(() => {
        if (map) {
            ["click", "idle"].forEach((eventName) =>
                window.google.maps.event.clearListeners(map, eventName)
            );

            if (onClick) {
                map.addListener("click", onClick);
            }

            if (onIdle) {
                map.addListener("idle", () => onIdle(map));
            }
        }
    }, [map, onClick, onIdle]);

    //#endregion

    return (
        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoadingActiveLoads === true || isLoadingLoads === true || isLoadingStops === true}>
            <div ref={mapRef} style={{ ...style, width: "100%", height: isBooleanTrue(fullScreen) ? "100vh" : (isStringNotEmpty(height) ? height : "500px") }} id={containerName} />
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    // set the map prop on the child component
                    return React.cloneElement(child, { map });
                }
            })}
        </Spin>
    );
};

export default LoadsMap;