import { isListNotEmpty, isNumberNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import EntityUtils from '../entityUtils';

export const getRequestForBidDTO = (requestForBid, accounts = []) => {
    // Get Account Info
    if (isListNotEmpty(accounts) && isStringNotEmpty(requestForBid.fromEntityId)) {
        requestForBid.fromEntity = EntityUtils.getAccountInfo(requestForBid.fromEntityId, accounts);
    }
    if (isListNotEmpty(accounts) && isStringNotEmpty(requestForBid.toEntityId)) {
        requestForBid.toEntity = EntityUtils.getAccountInfo(requestForBid.toEntityId, accounts);
    }

    return requestForBid;
};

export const getAddRequestForBidRequestDTO = (payload) => {
    if (isNumberNotEmpty(payload.targetRate)) {
        payload.targetRate = Number(payload.targetRate);
    }
    if (isNumberNotEmpty(payload.contractRate)) {
        payload.contractRate = Number(payload.contractRate);
    }
    if (isNumberNotEmpty(payload.offer)) {
        payload.offer = Number(payload.offer);
    }
    if (isNumberNotEmpty(payload.counter)) {
        payload.counter = Number(payload.counter);
    }

    return payload;
};

export const getUpdateRequestForBidRequestDTO = (payload) => {
    if (isNumberNotEmpty(payload.targetRate)) {
        payload.targetRate = Number(payload.targetRate);
    }
    if (isNumberNotEmpty(payload.contractRate)) {
        payload.contractRate = Number(payload.contractRate);
    }
    if (isNumberNotEmpty(payload.offer)) {
        payload.offer = Number(payload.offer);
    }
    if (isNumberNotEmpty(payload.counter)) {
        payload.counter = Number(payload.counter);
    }

    return payload;
};