import axiosAuthenticated from "../../api/axiosAuthenticated";
import { isNumberNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import moment from 'moment';

const loadLocationsPath = '/loadLocations';

//#region Load Locations Methods

export const getLoadLocations = async (loadId) => {
    const loadLocationsRes = await axiosAuthenticated.get(loadLocationsPath + `?size=1000000&loadId=${loadId}`);
    if (loadLocationsRes && loadLocationsRes.status === 200) {
        return loadLocationsRes.data.data;
    }

    return [];
};

export const getFirstLoadLocation = async (loadId) => {
    const loadLocationsRes = await axiosAuthenticated.get(loadLocationsPath + `?size=1&loadId=${loadId}`);
    if (loadLocationsRes && loadLocationsRes.status === 200) {
        return loadLocationsRes.data.data;
    }

    return [];
};

export const addLoadLocation = async (loadId, loadIrisId, latitude, longitude, sendToTMS = false) => {
    if (isStringNotEmpty(loadId) && isStringNotEmpty(loadIrisId) && isNumberNotEmpty(latitude) && isNumberNotEmpty(longitude)) {
        // set initial location for load
        let newLocation = {
            loadId: loadId,
            irisLoadId: loadIrisId,
            latitude: latitude,
            longitude: longitude,
            eventTimeStamp: moment().utc().toISOString(),
            sendToTMS: sendToTMS
        };

        const loadLocationsRes = await axiosAuthenticated.post(loadLocationsPath, newLocation);
        if (loadLocationsRes && loadLocationsRes.status === 202) {
            // console.log(`loadLocationRes: ${JSON.stringify(loadLocationRes)}`);
            return true;
        }
    }

    return false;
};

//#endregion