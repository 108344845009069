import * as actionTypes from "../actions/actionTypes";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { isBooleanTrue, isObjectNotEmpty } from "../../shared/objectUtils";
import * as actionCreators from "./index";
import ErrorUtils from "../../shared/errorUtils";

//#region Fetch Services Methods

export const fetchServicesStart = () => {
    return {
        type: actionTypes.FETCH_SERVICES_START
    }
};

export const fetchServicesSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_SERVICES_SUCCESS,
        payload: payload
    }
};

export const fetchServicesFail = (payload) => {
    return {
        type: actionTypes.FETCH_SERVICES_FAIL,
        payload: payload
    }
};

export const clearServices = () => {
    return {
        type: actionTypes.CLEAR_SERVICES
    }
};

//#endregion
//#region Add Service Methods

const insertService = (payload) => {
    return {
        type: actionTypes.ADD_SERVICE,
        payload: payload
    }
};

export const addServiceStart = () => {
    return {
        type: actionTypes.ADD_SERVICE_START
    }
};

export const addServiceSuccess = () => {
    return {
        type: actionTypes.ADD_SERVICE_SUCCESS
    }
};

export const addServiceFail = (payload) => {
    return {
        type: actionTypes.ADD_SERVICE_FAIL,
        payload: payload
    }
};

export const addServiceLoadingClear = () => {
    return {
        type: actionTypes.ADD_SERVICE_LOADING_CLEAR
    }
};

export const addServiceErrorClear = () => {
    return {
        type: actionTypes.ADD_SERVICE_ERROR_CLEAR
    }
};

export const addServiceCancel = () => {
    return {
        type: actionTypes.ADD_SERVICE_CANCEL
    }
};

//#endregion
//#region Update Service Methods

const changeService = (payload) => {
    return {
        type: actionTypes.UPDATE_SERVICE,
        payload: payload
    }
};

const changeSingleService = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_SERVICE,
        payload: payload
    }
};

export const updateServiceStart = () => {
    return {
        type: actionTypes.UPDATE_SERVICE_START
    }
};

export const updateServiceSuccess = () => {
    return {
        type: actionTypes.UPDATE_SERVICE_SUCCESS
    }
};

export const updateServiceFail = (payload) => {
    return {
        type: actionTypes.UPDATE_SERVICE_FAIL,
        payload: payload
    }
};

export const updateServiceLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_SERVICE_LOADING_CLEAR
    }
};

export const updateServiceErrorClear = () => {
    return {
        type: actionTypes.UPDATE_SERVICE_ERROR_CLEAR
    }
};

export const updateServiceCancel = () => {
    return {
        type: actionTypes.UPDATE_SERVICE_CANCEL
    }
};

//#endregion
//#region Remove Service Methods

const removeService = (payload) => {
    return {
        type: actionTypes.REMOVE_SERVICE,
        payload: payload
    }
};

//#endregion
//#region Services Methods

export const fetchServices = (payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(fetchServicesStart());

            await dispatch(actionCreators.getFeatures());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const features = [...orchestratorState.features];
            const servicesState = { ...state.services };
            let pagination = { ...servicesState.pagination };
            let searchParams = { ...servicesState.searchParams };

            if (isObjectNotEmpty(payload)) {
                searchParams = { ...payload };
            }

            const res = await Data.getServices({ ...searchParams }, pagination, features);
            dispatch(fetchServicesSuccess({ records: res.data, searchParams: res.searchParams, pagination: res.pagination }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchServicesFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const addService = (payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(addServiceStart());

            await dispatch(actionCreators.getFeatures());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const features = [...orchestratorState.features];

            const newService = await Data.addService(payload, features);
            if (isObjectNotEmpty(newService)) {
                dispatch(insertService(newService));

                // refresh services orchestrator
                dispatch(actionCreators.getServices(true));
            }

            dispatch(addServiceSuccess());
            dispatch(addServiceLoadingClear());
            dispatch(addServiceErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addServiceFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateService = (id, payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updateServiceStart());

            if (isBooleanTrue(payload.isDeleted)) {
                const removedService = await Data.removeService(id);
                if (isObjectNotEmpty(removedService)) {
                    dispatch(removeService(removedService));

                    // refresh services orchestrator
                    dispatch(actionCreators.getServices(true));
                }
            } else {
                await dispatch(actionCreators.getFeatures());
                const state = getState();
                const orchestratorState = { ...state.orchestrator };
                const features = [...orchestratorState.features];

                const updatedService = await Data.updateService(id, payload, features);
                if (isObjectNotEmpty(updatedService)) {
                    dispatch(changeService(updatedService));
                    dispatch(changeSingleService(updatedService));

                    // refresh services orchestrator
                    dispatch(actionCreators.getServices(true));
                }
            }

            dispatch(updateServiceSuccess());
            dispatch(updateServiceLoadingClear());
            dispatch(updateServiceErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateServiceFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion