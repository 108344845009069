import React from 'react';
import { isBooleanFalse, isStringNotEmpty } from '../../shared/objectUtils';
import StringFormatter from '../../shared/stringFormatter';

const stringFormatter = new StringFormatter();

const DataPhoneNumberRow = ({ className = null, valueClassName = null, titleClassName = null, style = null, valueStyle = null, titleStyle = { fontWeight: 'bold' }, title = null, phone, phoneExt = null, noColon = false }) => {
    return (
        <div className={className} style={style}>
            {isStringNotEmpty(title) ? (<span className={titleClassName} style={titleStyle}>{title}{isBooleanFalse(noColon) ? ':' : ''} </span>) : null}
            {isStringNotEmpty(phone) ? (<span className={valueClassName} style={valueStyle}>{stringFormatter.toFormattedPhoneNumber(phone, phoneExt)}</span>) : null}
        </div>
    );
};

export default DataPhoneNumberRow;