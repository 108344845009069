import React from 'react';
import { withRouter } from 'react-router-dom';
import { Layout, Result, Button, Card, Col, Row } from 'antd';
import irisBackground from '../../assets/img/backgrounds/background-1.jpg';
import classes from "./ResetPasswordConfirmation.module.scss";
import Logo from "../../components/Logo/Logo";
import appstore from '../../assets/img/appstore.svg';
import googleplay from '../../assets/img/googleplay.svg';
import { isStringNotEmpty } from '../../shared/objectUtils';

const ResetPasswordConfirmation = (props) => {
    //#region props

    const entityType = props.match.params.entityType;

    //#endregion
    //#region goTos

    const gotoLogin = () => {
        props.history.push('/login');
    };

    //#endregion

    return (
        <Layout className={classes.loginBackground} style={{ minHeight: '100vh', backgroundImage: `url(${irisBackground})` }}>
            <Logo size={"custom"} style={{ margin: '20px auto', height: '100px', padding: 10, }} height={100} />
            <Card bordered={true} className={classes.card} size="small">
                {isStringNotEmpty(entityType) && entityType.toUpperCase() !== "DRIVER" ? (
                    <Result
                        status="success"
                        title="Your Password has been Successfully Reset!"
                        subTitle="If you have not yet downloaded and installed our mobile app, please click on the appropriate link below and install our mobile app to access our Driver and Shipper features."
                    />
                ) : (
                        <Result
                            status="success"
                            title="Your Password has been Successfully Reset!"
                            subTitle="If you have not yet downloaded and installed our mobile app, please click on the appropriate link below and install our Driver Mobile App."
                        />
                    )}
                <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
                    <Col sm={{ span: 12 }} xs={{ span: 24 }} style={{ textAlign: 'center' }}><a href="https://apps.apple.com/us/app/iris-freight/id1503214438"><img src={appstore} alt="Download on the App Store" /></a></Col>
                    <Col sm={{ span: 12 }} xs={{ span: 24 }} style={{ textAlign: 'center' }}><a href="https://play.google.com/store/apps/details?id=com.irisfreight.irismobile"><img src={googleplay} alt="Get it on Google Play" /></a></Col>
                </Row>
                {isStringNotEmpty(entityType) && entityType.toUpperCase() !== "DRIVER" ? (
                    <Row gutter={[16, 16]}>
                        <Col sm={{ span: 24 }} xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} style={{ textAlign: 'center' }}>
                            <Button type="link" onClick={gotoLogin}>
                                <span>Login to IRIS Freight</span>
                            </Button>
                        </Col>
                    </Row>
                ) : null}
            </Card>
        </Layout>
    );
};

export default withRouter(ResetPasswordConfirmation);