import React from 'react';
import { Card } from "antd";
import classes from './ComplexCard.module.scss';

const ComplexCard = ({ title, style = null, headStyle = null, extra = null, ...props }) => {
    return (
        <Card bordered={true} title={title} className={classes.card} style={style} headStyle={headStyle} extra={extra}>
            {props.children}
        </Card>
    );
};

export default ComplexCard;