import { isListNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import EntityUtils from '../entityUtils';

export const getUserDTO = (user, userDocuments = []) => {
    if (isListNotEmpty(userDocuments)) {
        let filteredUserDocuments = userDocuments.filter(document => document.entityId === user.id);
        let { profileImageId } = EntityUtils.getUserFileIds(filteredUserDocuments);

        user.profileImageId = isStringNotEmpty(profileImageId) ? profileImageId : null;
    }

    if (user.isDummyEmail === true) {
        user.user = null;
    }

    return user;
};

export const getUserDTOWithProfileImageId = (user, profileImageId = null) => {
    user.profileImageId = isStringNotEmpty(profileImageId) ? profileImageId : null;

    if (user.isDummyEmail === true) {
        user.user = null;
    }

    return user;
};