import StringFormatter from "../../shared/stringFormatter";

export const getLoadMessageDTO = (loadMessage) => {
    return {
        ...loadMessage,
        _id: loadMessage.id,
        text: loadMessage.message,
        createdAt: new Date(new StringFormatter().toFormattedString("MomentDateTime", loadMessage.createdAt, null)),
        user: {
            _id: loadMessage.senderId,
            name: loadMessage.senderFirstName + ' ' + loadMessage.senderLastName
        },
        loadId: loadMessage.loadId,
        read: loadMessage.read
    };
};