import * as actionTypes from "../actions/actionTypes";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { isBooleanTrue, isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import ErrorUtils from "../../shared/errorUtils";
import * as actionCreators from "./index";

//#region Fetch Request For Bid List Methods

export const fetchRequestForBidListStart = (listName) => {
    return {
        type: actionTypes.FETCH_REQUEST_FOR_BID_LIST_START,
        payload: { listName: listName }
    }
};

export const fetchRequestForBidListSuccess = (listName, payload) => {
    return {
        type: actionTypes.FETCH_REQUEST_FOR_BID_LIST_SUCCESS,
        payload: { listName: listName, ...payload }
    }
};

export const fetchRequestForBidListFail = (listName, payload) => {
    return {
        type: actionTypes.FETCH_REQUEST_FOR_BID_LIST_FAIL,
        payload: { listName: listName, ...payload }
    }
};

export const clearRequestForBidList = (listName) => {
    return {
        type: actionTypes.CLEAR_REQUEST_FOR_BID_LIST,
        payload: { listName: listName }
    }
};

export const clearRequestForBidLists = () => {
    return {
        type: actionTypes.CLEAR_REQUEST_FOR_BID_LISTS
    }
};

export const addRequestForBidToRequestForBidList = (listName, payload) => {
    return {
        type: actionTypes.ADD_TO_REQUEST_FOR_BID_LIST,
        payload: { listName: listName, newRecord: payload }
    }
};

export const updateRequestForBidInRequestForBidList = (listName, payload) => {
    return {
        type: actionTypes.UPDATE_IN_REQUEST_FOR_BID_LIST,
        payload: { listName: listName, updatedRecord: payload }
    }
};

export const removeRequestForBidFromRequestForBidList = (listName, payload) => {
    return {
        type: actionTypes.REMOVE_FROM_REQUEST_FOR_BID_LIST,
        payload: { listName: listName, recordToRemove: payload }
    }
};

//#endregion
//#region Add Request For Bid Methods

export const addRequestForBidStart = () => {
    return {
        type: actionTypes.ADD_REQUEST_FOR_BID_START
    }
};

export const addRequestForBidSuccess = () => {
    return {
        type: actionTypes.ADD_REQUEST_FOR_BID_SUCCESS
    }
};

export const addRequestForBidFail = (payload) => {
    return {
        type: actionTypes.ADD_REQUEST_FOR_BID_FAIL,
        payload: payload
    }
};

export const addRequestForBidLoadingClear = () => {
    return {
        type: actionTypes.ADD_REQUEST_FOR_BID_LOADING_CLEAR
    }
};

export const addRequestForBidErrorClear = () => {
    return {
        type: actionTypes.ADD_REQUEST_FOR_BID_ERROR_CLEAR
    }
};

export const addRequestForBidCancel = () => {
    return {
        type: actionTypes.ADD_REQUEST_FOR_BID_CANCEL
    }
};

//#endregion
//#region Update Request For Bid Methods

const changeSingleRequestForBid = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_REQUEST_FOR_BID,
        payload: payload
    }
};

export const updateRequestForBidStart = () => {
    return {
        type: actionTypes.UPDATE_REQUEST_FOR_BID_START
    }
};

export const updateRequestForBidSuccess = () => {
    return {
        type: actionTypes.UPDATE_REQUEST_FOR_BID_SUCCESS
    }
};

export const updateRequestForBidFail = (payload) => {
    return {
        type: actionTypes.UPDATE_REQUEST_FOR_BID_FAIL,
        payload: payload
    }
};

export const updateRequestForBidLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_REQUEST_FOR_BID_LOADING_CLEAR
    }
};

export const updateRequestForBidErrorClear = () => {
    return {
        type: actionTypes.UPDATE_REQUEST_FOR_BID_ERROR_CLEAR
    }
};

export const updateRequestForBidCancel = () => {
    return {
        type: actionTypes.UPDATE_REQUEST_FOR_BID_CANCEL
    }
};

//#endregion
//#region Request For Bid Methods

export const fetchRequestForBidList = (listName, payload) => {
    return async (dispatch, getState) => {
        if (isStringNotEmpty(listName)) {
            try {
                dispatch(fetchRequestForBidListStart(listName));

                await dispatch(actionCreators.getAccounts());
                const state = getState();
                const orchestratorState = { ...state.orchestrator };
                const accounts = [...orchestratorState.accounts];
                const requestForBidsState = { ...state.requestForBids };
                const existingLists = { ...requestForBidsState.lists };

                let pagination = {};
                let searchParams = {};
                if (isObjectNotEmpty(existingLists[listName])) {
                    pagination = { ...existingLists[listName].pagination };
                    searchParams = { ...existingLists[listName].searchParams };
                }

                if (isObjectNotEmpty(payload)) {
                    searchParams = { ...payload };
                }

                const res = await Data.getRequestForBids({ ...searchParams }, pagination, accounts);
                dispatch(fetchRequestForBidListSuccess(listName, { records: res.data, params: { searchParams: res.searchParams, pagination: res.pagination } }));
            } catch (error) {
                logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
                dispatch(fetchRequestForBidListFail(listName, { error: ErrorUtils.getErrorMessage(error) }));
            }
        }
    }
};

export const fetchBulkRequestForBidList = (loadId, accountId) => {
    return async (dispatch, getState) => {
        if (isStringNotEmpty(loadId) && isStringNotEmpty(accountId)) {
            try {
                dispatch(fetchRequestForBidListStart(`${loadId}_${accountId}`));

                await dispatch(actionCreators.getAccounts());
                const state = getState();
                const orchestratorState = { ...state.orchestrator };
                const accounts = [...orchestratorState.accounts];

                let searchParams = {
                    page: 1,
                    size: 1000000,
                    sort: 'offer',
                    order: 'asc',
                    loadId: loadId,
                    'fromEntityId:or': accountId,
                    'toEntityId:or': accountId
                };

                const requestForBids = await Data.getBulkRequestForBids({ ...searchParams }, accounts);
                dispatch(fetchRequestForBidListSuccess(`${loadId}_${accountId}`, { records: requestForBids }));
            } catch (error) {
                logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
                dispatch(fetchRequestForBidListFail(`${loadId}_${accountId}`, { error: ErrorUtils.getErrorMessage(error) }));
            }
        }
    }
};

export const fetchBulkRequestForBidLists = (loadIds = [], accountId) => {
    return async (dispatch, getState) => {
        if (isStringNotEmpty(accountId) && isListNotEmpty(loadIds)) {
            try {
                dispatch(fetchRequestForBidListStart(accountId));

                await dispatch(actionCreators.getAccounts());
                const state = getState();
                const orchestratorState = { ...state.orchestrator };
                const accounts = [...orchestratorState.accounts];

                let searchParams = {
                    page: 1,
                    size: 1000000,
                    sort: 'offer',
                    order: 'asc',
                    loadId: loadIds,
                    'fromEntityId:or': accountId,
                    'toEntityId:or': accountId
                };

                const requestForBids = await Data.getBulkRequestForBids({ ...searchParams }, accounts);
                loadIds.forEach((loadId) => {
                    const loadRequestForBids = requestForBids.filter(r => r.loadId === loadId);
                    dispatch(fetchRequestForBidListSuccess(`${loadId}_${accountId}`, { records: loadRequestForBids }));
                });
                dispatch(fetchRequestForBidListSuccess(accountId, { records: requestForBids }));
            } catch (error) {
                logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
                dispatch(fetchRequestForBidListFail(accountId, { error: ErrorUtils.getErrorMessage(error) }));
            }
        }
    }
};

export const addRequestForBid = (payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(addRequestForBidStart());

            await dispatch(actionCreators.getAccounts());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const accounts = [...orchestratorState.accounts];

            const newRequestForBid = await Data.addRequestForBid(payload, accounts);
            if (isObjectNotEmpty(newRequestForBid)) {
                dispatch(addRequestForBidToRequestForBidList(newRequestForBid.loadId, newRequestForBid));
                if (isStringNotEmpty(newRequestForBid.fromEntityId)) {
                    dispatch(addRequestForBidToRequestForBidList(`${newRequestForBid.loadId}_${newRequestForBid.fromEntityId}`, newRequestForBid));
                }
                if (isStringNotEmpty(newRequestForBid.toEntityId)) {
                    dispatch(addRequestForBidToRequestForBidList(`${newRequestForBid.loadId}_${newRequestForBid.toEntityId}`, newRequestForBid));
                }
            }

            dispatch(addRequestForBidSuccess());
            dispatch(addRequestForBidLoadingClear());
            dispatch(addRequestForBidErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addRequestForBidFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateRequestForBid = (id, payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updateRequestForBidStart());

            if (isBooleanTrue(payload.isDeleted)) {
                const removedRequestForBid = await Data.removeRequestForBid(id);
                if (isObjectNotEmpty(removedRequestForBid)) {
                    dispatch(removeRequestForBidFromRequestForBidList(removedRequestForBid.loadId, removedRequestForBid));
                    if (isStringNotEmpty(removedRequestForBid.fromEntityId)) {
                        dispatch(removeRequestForBidFromRequestForBidList(`${removedRequestForBid.loadId}_${removedRequestForBid.fromEntityId}`, removedRequestForBid));
                    }
                    if (isStringNotEmpty(removedRequestForBid.toEntityId)) {
                        dispatch(removeRequestForBidFromRequestForBidList(`${removedRequestForBid.loadId}_${removedRequestForBid.toEntityId}`, removedRequestForBid));
                    }
                }
            } else {
                await dispatch(actionCreators.getAccounts());
                const state = getState();
                const orchestratorState = { ...state.orchestrator };
                const accounts = [...orchestratorState.accounts];

                const updatedRequestForBid = await Data.updateRequestForBid(id, payload, accounts);
                if (isObjectNotEmpty(updatedRequestForBid)) {
                    dispatch(updateRequestForBidInRequestForBidList(updatedRequestForBid.loadId, updatedRequestForBid));
                    if (isStringNotEmpty(updatedRequestForBid.fromEntityId)) {
                        dispatch(updateRequestForBidInRequestForBidList(`${updatedRequestForBid.loadId}_${updatedRequestForBid.fromEntityId}`, updatedRequestForBid));
                    }
                    if (isStringNotEmpty(updatedRequestForBid.toEntityId)) {
                        dispatch(updateRequestForBidInRequestForBidList(`${updatedRequestForBid.loadId}_${updatedRequestForBid.toEntityId}`, updatedRequestForBid));
                    }
                    dispatch(changeSingleRequestForBid(updatedRequestForBid));
                }
            }

            dispatch(updateRequestForBidSuccess());
            dispatch(updateRequestForBidLoadingClear());
            dispatch(updateRequestForBidErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateRequestForBidFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion