import React, { useEffect, useMemo, useState } from 'react';
import { Button, Input, Row, Col, Radio, InputNumber, Select, Empty } from "antd";
import { orderBy } from "lodash";
import { useForm, FormProvider } from 'react-hook-form';
import FormItem from '../FormItem/FormItem';
import { convertToObject, isListNotEmpty, isNotNullOrUndefined, isNumberEmpty, isNumberGreaterThanZero, isNumberNotEmpty, isObjectNotEmpty, isStringEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import Form from '../Form/Form';
import Fieldset from '../FormFieldset/FormFieldset';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import StringFormatter from '../../shared/stringFormatter';
import DataRow from '../DataRow/DataRow';
import DataListRow from '../DataListRow/DataListRow';
import Pricing from '../../shared/pricing';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import Header from '../Header/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classes from './NewLoadServices.module.scss';
import Enums from '../../shared/enums';
import { withRouter } from 'react-router';
import { selectListRecords } from '../../store/utility';

const { Option } = Select;
const stringFormatter = new StringFormatter();

const NewLoadServices = ({ servicesData, setServicesData, completedSteps, setCompletedSteps, previousStepAction, previousStepActionName, nextStepAction, nextStepActionName, equipmentData, pickUpStops, dropOffStops, practicalRouteMiles, setInvoiceLineItems, setCarrier, dontRequireLoadSpecificInformation, hideLoadSpecificInformation, ...props }) => {
    //#region useForms

    const methods = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            baseRatePerMileAmount: null,
            fuelSurchargePerMileAmount: null,
            contractRateAmount: null,
            contractRateCarrierAmount: null,
            fuelSurchargeAmount: null,
            customerOrderNumber: '',
            parentName: ''
        }
    });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const lineItemTypes = useSelector(state => state.orchestrator.lineItemTypes);
    const entityType = useSelector(state => state.auth.entityType);
    const entityId = useSelector(state => state.auth.entityId);
    const accounts = useSelector(state => state.orchestrator.accounts);
    const linkedAccounts = useSelector(state => selectListRecords(state.orchestrator.linkedAccounts, entityType !== 'STAFF' ? entityId : null));

    //#endregion
    //#region useStates

    const [showCarrierOptions, setShowCarrierOptions] = useState(false);
    const [tenderOption, setTenderOption] = useState(null);
    const [showPricing, setShowPricing] = useState(false);
    const [showShipperPricing, setShowShipperPricing] = useState(false);
    const [showCarrierPricing, setShowCarrierPricing] = useState(false);
    const [carriers, setCarriers] = useState([]);
    const [isCarriersLoading, setIsCarriersLoading] = useState(false);
    const [showPerMile, setShowPerMile] = useState(false);
    const [showContractRate, setShowContractRate] = useState(false);
    const [invoiceLineItemList, setInvoiceLineItemList] = useState([]);
    const [totalFeePerMile, setTotalFeePerMile] = useState(0);
    const [shipperRatePerMile, setShipperRatePerMile] = useState(0);
    const [carrierRatePerMile, setCarrierRatePerMile] = useState(0);
    const [commissionPercentage, setCommissionPercentage] = useState(0);
    const [totalAddOnCharge, setTotalAddOnCharge] = useState(0);
    const [totalAddOnCost, setTotalAddOnCost] = useState(0);
    const [shipperAmount, setShipperAmount] = useState(0);
    const [carrierAmount, setCarrierAmount] = useState(0);
    const [IRISProfit, setIRISProfit] = useState(0);

    const [baseRatePerMileAmount, setBaseRatePerMileAmount] = useState(0);
    const [fuelSurchargePerMileAmount, setFuelSurchargePerMileAmount] = useState(0);
    const [contractRateAmount, setContractRateAmount] = useState(0);
    const [contractRateCarrierAmount, setContractRateCarrierAmount] = useState(0);
    const [fuelSurchargeAmount, setFuelSurchargeAmount] = useState(0);

    //#endregion
    //#region validation

    const validateServices = (data) => {
        let hasErrors = false;
        if (entityType === 'CARRIER') {
            if (data.rateOption === 'PER_MILE') {
                if (isNumberEmpty(data.baseRatePerMileAmount)) {
                    methods.setError('baseRatePerMileAmount', { type: 'required', message: 'Required Field' });
                    hasErrors = true;
                }
            } else if (data.rateOption = 'CONTRACT_RATE') {
                if (isNumberEmpty(data.contractRateAmount)) {
                    methods.setError('contractRateAmount', { type: 'required', message: 'Required Field' });
                    hasErrors = true;
                }
            } else {
                methods.setError('rateOption', { type: 'required', message: 'Please choose a Trip Rate Option' });
                hasErrors = true;
            }
        } else if (entityType === 'STAFF' || entityType === 'BROKER') {
            if (data.tenderOption === 'ASSIGN_CARRIER') {
                if (isNumberEmpty(data.assignedCarrierId)) {
                    methods.setError('assignedCarrierId', { type: 'required', message: 'Required Field' });
                    hasErrors = true;
                }
                if (isStringEmpty(data.customerOrderNumber) && (dontRequireLoadSpecificInformation === false && hideLoadSpecificInformation === false)) {
                    methods.setError('customerOrderNumber', { type: 'required', message: 'Required Field' });
                    hasErrors = true;
                }
                if (isNumberEmpty(data.contractRateAmount)) {
                    methods.setError('contractRateAmount', { type: 'required', message: 'Required Field' });
                    hasErrors = true;
                }
                if (data.rateOption === 'PER_MILE') {
                    if (isNumberEmpty(data.baseRatePerMileAmount)) {
                        methods.setError('baseRatePerMileAmount', { type: 'required', message: 'Required Field' });
                        hasErrors = true;
                    }
                } else if (data.rateOption = 'CONTRACT_RATE') {
                    if (isNumberEmpty(data.contractRateCarrierAmount)) {
                        methods.setError('contractRateCarrierAmount', { type: 'required', message: 'Required Field' });
                        hasErrors = true;
                    }
                } else {
                    methods.setError('rateOption', { type: 'required', message: 'Please choose a Carrier Rate Option' });
                    hasErrors = true;
                }
            } else if (data.tenderOption === 'DEFER') {
                // TODO: should customer order number still be required?
            } else {
                methods.setError('tenderOption', { type: 'required', message: 'Please choose a Tender Option' });
                hasErrors = true;
            }
        } else if (entityType === 'SHIPPER' && (tenderOption === 'ASSIGN_CARRIER' || tenderOption === 'DEFER')) {
            if (data.tenderOption === 'ASSIGN_CARRIER') {
                if (isStringEmpty(data.assignedCarrierId)) {
                    methods.setError('assignedCarrierId', { type: 'required', message: 'Required Field' });
                    hasErrors = true;
                }
                if (isStringEmpty(data.customerOrderNumber) && (dontRequireLoadSpecificInformation === false && hideLoadSpecificInformation === false)) {
                    methods.setError('customerOrderNumber', { type: 'required', message: 'Required Field' });
                    hasErrors = true;
                }
                if (data.rateOption === 'PER_MILE') {
                    if (isNumberEmpty(data.baseRatePerMileAmount)) {
                        methods.setError('baseRatePerMileAmount', { type: 'required', message: 'Required Field' });
                        hasErrors = true;
                    }
                } else if (data.rateOption = 'CONTRACT_RATE') {
                    if (isNumberEmpty(data.contractRateAmount)) {
                        methods.setError('contractRateAmount', { type: 'required', message: 'Required Field' });
                        hasErrors = true;
                    }
                } else {
                    methods.setError('rateOption', { type: 'required', message: 'Please choose a Carrier Rate Option' });
                    hasErrors = true;
                }
            } else if (data.tenderOption === 'DEFER') {
                // TODO: should customer order number still be required?
            } else if (data.tenderOption === 'IRIS') {
                // TODO: should customer order number still be required?
            } else {
                methods.setError('tenderOption', { type: 'required', message: 'Please choose a Tender Option' });
                hasErrors = true;
            }
        }

        return !hasErrors;
    };

    //#endregion
    //#region onSubmit and saveForm

    const saveForm = () => {
        const payload = methods.getValues();
        //console.log(payload);
        let data = convertToObject(payload);

        if (entityType === 'CARRIER' || entityType === 'BROKER') {
            data.serviceType = 'TMS';
        } else if (entityType === 'STAFF') {
            data.serviceType = 'BROKERAGE';
        } else if (entityType === 'SHIPPER' && (tenderOption === 'ASSIGN_CARRIER' || tenderOption === 'DEFER')) {
            data.serviceType = 'TMS';
        } else if (entityType === 'SHIPPER' && tenderOption === 'IRIS') {
            data.serviceType = 'BROKERAGE';
        }

        let newInvoiceLineItems = [...invoiceLineItemList];

        // Base Fee Per Mile
        if (isNumberGreaterThanZero(data.baseRatePerMileAmount)) {
            let lineItemType = lineItemTypes.find(j => j.name === 'BASE_FEE_PER_MILE');
            if (isObjectNotEmpty(lineItemType)) {
                let baseAmount = Number(data.baseRatePerMileAmount);
                let quantity = Number(practicalRouteMiles);
                let totalAmount = baseAmount * quantity;
                let newInvoiceLineItem = {
                    description: lineItemType.description,
                    itemType: 'BASE_FEE_PER_MILE',
                    isIrisFee: false,
                    baseAmount: baseAmount.toFixed(2),
                    baseAmountUnit: 'USD',
                    quantity: quantity,
                    quantityUnit: 'PER_MILE',
                    totalAmount: totalAmount.toFixed(2),
                    totalAmountUnit: 'USD',
                    status: 'PENDING',
                    approvalStatus: 'PENDING',
                    isOpen: true,
                    isDeleted: false,
                };

                if (entityType === 'STAFF') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'STAFF' });
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER' });
                } else if (entityType === 'CARRIER') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                } else if (entityType === 'SHIPPER' && (tenderOption === 'ASSIGN_CARRIER' || tenderOption === 'DEFER')) {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                } else if (entityType === 'SHIPPER' && tenderOption === 'IRIS') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'STAFF' });
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER' });
                }
            }
        }

        // Fuel Surcharge Per Mile
        if (isNumberGreaterThanZero(data.fuelSurchargePerMileAmount)) {
            let lineItemType = lineItemTypes.find(j => j.name === 'FUEL_SURCHARGE_PER_MILE');
            if (isObjectNotEmpty(lineItemType)) {
                let baseAmount = Number(data.fuelSurchargePerMileAmount);
                let quantity = Number(practicalRouteMiles);
                let totalAmount = baseAmount * quantity;
                let newInvoiceLineItem = {
                    description: lineItemType.description,
                    itemType: 'FUEL_SURCHARGE_PER_MILE',
                    isIrisFee: false,
                    baseAmount: baseAmount.toFixed(2),
                    baseAmountUnit: 'USD',
                    quantity: quantity,
                    quantityUnit: 'PER_MILE',
                    totalAmount: totalAmount.toFixed(2),
                    totalAmountUnit: 'USD',
                    status: 'PENDING',
                    approvalStatus: 'PENDING',
                    isOpen: true,
                    isDeleted: false,
                };

                if (entityType === 'STAFF') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'STAFF' });
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER' });
                } else if (entityType === 'CARRIER') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                } else if (entityType === 'SHIPPER' && (tenderOption === 'ASSIGN_CARRIER' || tenderOption === 'DEFER')) {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                } else if (entityType === 'SHIPPER' && tenderOption === 'IRIS') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'STAFF' });
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER' });
                }
            }
        }

        // Fuel Surcharge Per Load
        if (isNumberGreaterThanZero(data.fuelSurchargeAmount)) {
            let lineItemType = lineItemTypes.find(j => j.name === 'FUEL_SURCHARGE');
            if (isObjectNotEmpty(lineItemType)) {
                let baseAmount = Number(data.fuelSurchargeAmount);
                let quantity = Number(practicalRouteMiles);
                let totalAmount = baseAmount * quantity;
                let newInvoiceLineItem = {
                    description: lineItemType.description,
                    itemType: 'FUEL_SURCHARGE',
                    isIrisFee: false,
                    baseAmount: baseAmount.toFixed(2),
                    baseAmountUnit: 'USD',
                    quantity: quantity,
                    quantityUnit: 'PER_LOAD',
                    totalAmount: totalAmount.toFixed(2),
                    totalAmountUnit: 'USD',
                    status: 'PENDING',
                    approvalStatus: 'PENDING',
                    isOpen: true,
                    isDeleted: false,
                };

                if (entityType === 'STAFF') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'STAFF' });
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER' });
                } else if (entityType === 'CARRIER') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                } else if (entityType === 'SHIPPER' && (tenderOption === 'ASSIGN_CARRIER' || tenderOption === 'DEFER')) {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                } else if (entityType === 'SHIPPER' && tenderOption === 'IRIS') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'STAFF' });
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER' });
                }
            }
        }

        // Contract Rate
        if (isNumberGreaterThanZero(data.contractRateAmount)) {
            let lineItemType = lineItemTypes.find(j => j.name === 'CONTRACT_RATE');
            if (isObjectNotEmpty(lineItemType)) {
                let baseAmount = Number(data.contractRateAmount);
                let quantity = Number(1);
                let totalAmount = baseAmount * quantity;
                let newInvoiceLineItem = {
                    description: lineItemType.description,
                    itemType: 'CONTRACT_RATE',
                    isIrisFee: false,
                    baseAmount: baseAmount.toFixed(2),
                    baseAmountUnit: 'USD',
                    quantity: quantity,
                    quantityUnit: 'PER_LOAD',
                    totalAmount: totalAmount.toFixed(2),
                    totalAmountUnit: 'USD',
                    status: 'PENDING',
                    approvalStatus: 'PENDING',
                    isOpen: true,
                    isDeleted: false,
                };

                if (entityType === 'STAFF') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'STAFF' });
                } else if (entityType === 'CARRIER') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                } else if (entityType === 'SHIPPER' && (tenderOption === 'ASSIGN_CARRIER' || tenderOption === 'DEFER')) {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                } else if (entityType === 'SHIPPER' && tenderOption === 'IRIS') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'STAFF' });
                }
            }
        }

        // Contract Carrier Rate
        if (isNumberGreaterThanZero(data.contractRateCarrierAmount)) {
            let lineItemType = lineItemTypes.find(j => j.name === 'CONTRACT_RATE');
            if (isObjectNotEmpty(lineItemType)) {
                let baseAmount = Number(data.contractRateCarrierAmount);
                let quantity = Number(1);
                let totalAmount = baseAmount * quantity;
                let newInvoiceLineItem = {
                    description: lineItemType.description,
                    itemType: 'CONTRACT_RATE',
                    isIrisFee: false,
                    baseAmount: baseAmount.toFixed(2),
                    baseAmountUnit: 'USD',
                    quantity: quantity,
                    quantityUnit: 'PER_LOAD',
                    totalAmount: totalAmount.toFixed(2),
                    totalAmountUnit: 'USD',
                    status: 'PENDING',
                    approvalStatus: 'PENDING',
                    isOpen: true,
                    isDeleted: false,
                };

                if (entityType === 'STAFF') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER' });
                } else if (entityType === 'SHIPPER' && tenderOption === 'IRIS') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER' });
                }
            }
        }

        //console.log(data);
        if (isObjectNotEmpty(data)) {
            if (isStringNotEmpty(data.assignedCarrierId)) {
                let carrierAccount = accounts.find(a => a.id === data.assignedCarrierId);
                if (isObjectNotEmpty(carrierAccount)) {
                    setCarrier(carrierAccount);
                } else {
                    setCarrier(null);
                }
            }

            setInvoiceLineItems([...newInvoiceLineItems]);
            setServicesData({
                ...data,
                totalFeePerMile: totalFeePerMile,
                shipperRatePerMile: shipperRatePerMile,
                carrierRatePerMile: carrierRatePerMile,
                commissionPercentage: commissionPercentage,
                totalAddOnCharge: totalAddOnCharge,
                totalAddOnCost: totalAddOnCost,
                shipperAmount: shipperAmount,
                carrierAmount: carrierAmount,
                IRISProfit: IRISProfit
            });
        }
    };

    const onSubmit = (data) => {
        if (validateServices(data)) {
            saveForm();
            setCompletedSteps({ ...completedSteps, services: true });
            nextStepAction('review');
        } else {
            return;
        }
    };

    //#endregion
    //#region invoiceLineItem methods

    const generateStopFeesInvoiceLineItems = () => {
        // create invoiceLineItems
        let newInvoiceLineItems = [];
        if (isListNotEmpty(lineItemTypes)) {
            if (isListNotEmpty(pickUpStops)) {
                pickUpStops.forEach((stop, stopIndex, arr) => {
                    if (stop.hasDriverAssist === true) {
                        let lineItemType = lineItemTypes.find(j => j.name === 'DRIVER_ASSIST');
                        if (isObjectNotEmpty(lineItemType)) {
                            let baseAmount = 0;
                            if (isNumberGreaterThanZero(stop.driverAssist)) {
                                baseAmount = parseFloat(stop.driverAssist);
                            }
                            let quantity = 1;
                            let totalAmount = baseAmount * quantity;
                            let newInvoiceLineItem = {
                                description: lineItemType.description + ' for ' + stop.stopLocation.name,
                                stopId: stop.id,
                                itemType: 'DRIVER_ASSIST',
                                isIrisFee: false,
                                baseAmount: baseAmount.toFixed(2),
                                baseAmountUnit: 'USD',
                                quantity: quantity,
                                quantityUnit: 'PER_STOP',
                                totalAmount: totalAmount.toFixed(2),
                                totalAmountUnit: 'USD',
                                status: 'PENDING',
                                approvalStatus: 'PENDING',
                                isOpen: true,
                                isDeleted: false,
                            };

                            if (entityType === 'STAFF') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'STAFF' });
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER' });
                            } else if (entityType === 'CARRIER') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                            } else if (entityType === 'SHIPPER' && tenderOption === 'ASSIGN_CARRIER') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                            } else if (entityType === 'SHIPPER' && tenderOption === 'IRIS') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'STAFF' });
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER' });
                            }
                        }
                    }

                    if (stop.hasLumperFee === true) {
                        let lineItemType = lineItemTypes.find(j => j.name === 'LUMPER_FEE');
                        if (isObjectNotEmpty(lineItemType)) {
                            let baseAmount = 0;
                            if (isNumberGreaterThanZero(stop.lumperFee)) {
                                baseAmount = parseFloat(stop.lumperFee);
                            }
                            let quantity = 1;
                            let totalAmount = baseAmount * quantity;
                            let newInvoiceLineItem = {
                                description: lineItemType.description + ' for ' + stop.stopLocation.name,
                                stopId: stop.id,
                                itemType: 'LUMPER_FEE',
                                isIrisFee: false,
                                baseAmount: baseAmount.toFixed(2),
                                baseAmountUnit: 'USD',
                                quantity: quantity,
                                quantityUnit: 'PER_STOP',
                                totalAmount: totalAmount.toFixed(2),
                                totalAmountUnit: 'USD',
                                status: 'PENDING',
                                approvalStatus: 'PENDING',
                                isOpen: true,
                                isDeleted: false,
                            }

                            if (entityType === 'STAFF') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'STAFF' });
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER' });
                            } else if (entityType === 'CARRIER') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                            } else if (entityType === 'SHIPPER' && tenderOption === 'ASSIGN_CARRIER') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                            } else if (entityType === 'SHIPPER' && tenderOption === 'IRIS') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'STAFF' });
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER' });
                            }
                        }
                    }
                });
            }

            if (isListNotEmpty(dropOffStops)) {
                dropOffStops.forEach((stop, stopIndex, arr) => {
                    if (stop.hasDriverAssist === true) {
                        let lineItemType = lineItemTypes.find(j => j.name === 'DRIVER_ASSIST');
                        if (isObjectNotEmpty(lineItemType)) {
                            let baseAmount = 0;
                            if (isNumberGreaterThanZero(stop.driverAssist)) {
                                baseAmount = parseFloat(stop.driverAssist);
                            }
                            let quantity = 1;
                            let totalAmount = baseAmount * quantity;
                            let newInvoiceLineItem = {
                                description: lineItemType.description + ' for ' + stop.stopLocation.name,
                                stopId: stop.id,
                                itemType: 'DRIVER_ASSIST',
                                isIrisFee: false,
                                baseAmount: baseAmount.toFixed(2),
                                baseAmountUnit: 'USD',
                                quantity: quantity,
                                quantityUnit: 'PER_STOP',
                                totalAmount: totalAmount.toFixed(2),
                                totalAmountUnit: 'USD',
                                status: 'PENDING',
                                approvalStatus: 'PENDING',
                                isOpen: true,
                                isDeleted: false,
                            };

                            if (entityType === 'STAFF') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'STAFF' });
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER' });
                            } else if (entityType === 'CARRIER') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                            } else if (entityType === 'SHIPPER' && tenderOption === 'ASSIGN_CARRIER') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                            } else if (entityType === 'SHIPPER' && tenderOption === 'IRIS') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'STAFF' });
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER' });
                            }
                        }
                    }

                    if (stop.hasLumperFee === true) {
                        let lineItemType = lineItemTypes.find(j => j.name === 'LUMPER_FEE');
                        if (isObjectNotEmpty(lineItemType)) {
                            let baseAmount = 0;
                            if (isNumberGreaterThanZero(stop.lumperFee)) {
                                baseAmount = parseFloat(stop.lumperFee);
                            }
                            let quantity = 1;
                            let totalAmount = baseAmount * quantity;
                            let newInvoiceLineItem = {
                                description: lineItemType.description + ' for ' + stop.stopLocation.name,
                                stopId: stop.id,
                                itemType: 'LUMPER_FEE',
                                isIrisFee: false,
                                baseAmount: baseAmount.toFixed(2),
                                baseAmountUnit: 'USD',
                                quantity: quantity,
                                quantityUnit: 'PER_STOP',
                                totalAmount: totalAmount.toFixed(2),
                                totalAmountUnit: 'USD',
                                status: 'PENDING',
                                approvalStatus: 'PENDING',
                                isOpen: true,
                                isDeleted: false,
                            }

                            if (entityType === 'STAFF') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'STAFF' });
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER' });
                            } else if (entityType === 'CARRIER') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                            } else if (entityType === 'SHIPPER' && tenderOption === 'ASSIGN_CARRIER') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                            } else if (entityType === 'SHIPPER' && tenderOption === 'IRIS') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'STAFF' });
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER' });
                            }
                        }
                    }
                });
            }

            setInvoiceLineItemList([...newInvoiceLineItems]);
        }
    };

    //#endregion
    //#region onChanges

    const onChangeCustomerOrderNumber = (value) => {
        setServicesData({ ...servicesData, customerOrderNumber: value });
    };

    const onChangeParentName = (value) => {
        setServicesData({ ...servicesData, parentName: value });
    };

    const onChangeAssignedCarrierId = (value) => {
        if (isStringNotEmpty(value)) {
            let carrierAccount = accounts.find(a => a.id === value);
            if (isObjectNotEmpty(carrierAccount)) {
                setCarrier(carrierAccount);
                setServicesData({ ...servicesData, assignedCarrierId: value });
            } else {
                setCarrier(null);
                setServicesData({ ...servicesData, assignedCarrierId: value });
            }
        } else {
            setCarrier(null);
            setServicesData({ ...servicesData, assignedCarrierId: null });
        }
    };

    const onChangeRateOption = (value) => {
        // console.log(value);
        if (isStringNotEmpty(value)) {
            if (value === 'PER_MILE') {
                setShowPerMile(true);
                setShowContractRate(false);
                if (entityType === 'STAFF') {
                    methods.setValue('contractRateCarrierAmount', null);
                    setContractRateCarrierAmount(0);
                } else if (entityType === 'SHIPPER') {
                    methods.setValue('contractRateAmount', null);
                    methods.setValue('fuelSurchargeAmount', null);
                    setContractRateAmount(0);
                    setFuelSurchargeAmount(0);
                } else if (entityType === 'CARRIER') {
                    methods.setValue('contractRateAmount', null);
                    methods.setValue('fuelSurchargeAmount', null);
                    setContractRateAmount(0);
                    setFuelSurchargeAmount(0);
                }
            } else if (value === 'CONTRACT_RATE') {
                setShowPerMile(false);
                setShowContractRate(true);
                if (entityType === 'STAFF') {
                    methods.setValue('baseRatePerMileAmount', null);
                    methods.setValue('fuelSurchargePerMileAmount', null);
                    setBaseRatePerMileAmount(0);
                    setFuelSurchargePerMileAmount(0);
                } else if (entityType === 'SHIPPER') {
                    methods.setValue('baseRatePerMileAmount', null);
                    methods.setValue('fuelSurchargePerMileAmount', null);
                    setBaseRatePerMileAmount(0);
                    setFuelSurchargePerMileAmount(0);
                } else if (entityType === 'CARRIER') {
                    methods.setValue('baseRatePerMileAmount', null);
                    methods.setValue('fuelSurchargePerMileAmount', null);
                    setBaseRatePerMileAmount(0);
                    setFuelSurchargePerMileAmount(0);
                }
            } else {
                setShowPerMile(false);
                setShowContractRate(false);
            }

            setServicesData({ ...servicesData, rateOption: value });
        } else {
            setShowPerMile(false);
            setShowContractRate(false);

            setServicesData({ ...servicesData, rateOption: null });
        }
    };

    const onChangeTenderOption = (value) => {
        methods.clearErrors();
        setTotalFeePerMile(0);
        setShipperRatePerMile(0);
        setCarrierRatePerMile(0);
        setCommissionPercentage(0);
        setTotalAddOnCharge(0);
        setTotalAddOnCost(0);
        setShipperAmount(0);
        setCarrierAmount(0);
        setIRISProfit(0);

        setBaseRatePerMileAmount(0);
        setFuelSurchargePerMileAmount(0);
        setContractRateAmount(0);
        setFuelSurchargeAmount(0);
        setContractRateCarrierAmount(0);
        methods.setValue('baseRatePerMileAmount', undefined);
        methods.setValue('fuelSurchargePerMileAmount', undefined);
        methods.setValue('contractRateAmount', undefined);
        methods.setValue('fuelSurchargeAmount', undefined);
        methods.setValue('contractRateCarrierAmount', undefined);
        
        methods.setValue('assignedCarrierId', null);
        setCarrier(null);

        setShowPerMile(false);
        setShowContractRate(false);

        setServicesData({
            ...servicesData,
            tenderOption: null,
            serviceType: null,
            assignedCarrierId: null,
            rateOption: null
        });

        // console.log(value);
        if (isStringNotEmpty(value)) {
            setTenderOption(value);
            if (value === 'ASSIGN_CARRIER') {
                setShowCarrierOptions(true);
                setShowPricing(true);
                setShowShipperPricing(true);
                setShowCarrierPricing(true);
            } else if (value === 'IRIS') {
                setShowCarrierOptions(false);
                setShowPricing(false);
                setShowShipperPricing(false);
                setShowCarrierPricing(false);
            } else if (value === 'DEFER') {
                setShowCarrierOptions(false);
                if (entityType === 'STAFF') {
                    setShowPricing(true);
                    setShowShipperPricing(true);
                    setShowCarrierPricing(false);
                } else {
                    setShowPricing(false);
                    setShowShipperPricing(false);
                    setShowCarrierPricing(false);
                }
            } else {
                setShowCarrierOptions(false);
                setShowPricing(false);
                setShowShipperPricing(false);
                setShowCarrierPricing(false);
            }

            let serviceType = 'TMS';
            if (entityType === 'STAFF') {
                serviceType = 'BROKERAGE';
            } else if (entityType === 'SHIPPER' && value === 'IRIS') {
                serviceType = 'BROKERAGE';
            }
        } else {
            setTenderOption(null);
            setShowCarrierOptions(false);
            setShowPricing(false);
            setShowShipperPricing(false);
            setShowCarrierPricing(false);
        }
    };

    //#endregion
    //#region useMemos and useEffects

    useMemo(() => {
        dispatch(actionCreators.getLineItemTypes());
    }, []);

    useMemo(() => {
        if (isStringNotEmpty(entityType) && isStringNotEmpty(entityId) && entityType !== 'STAFF') {
            dispatch(actionCreators.getLinkedAccounts(entityId));
        } else {
            dispatch(actionCreators.getAccounts());
        }
    }, [entityType, entityId]);

    useMemo(() => {
        if (entityType === 'STAFF') {
            setIsCarriersLoading(true);

            if (isListNotEmpty(accounts)) {
                const carrierAccounts = accounts.filter(a => a.entityTypes.includes("CARRIER") === true);
                if (isListNotEmpty(carrierAccounts)) {
                    setCarriers(orderBy(carrierAccounts, ['name'], ['asc']));
                }
                else {
                    setCarriers([]);
                }
            }

            setIsCarriersLoading(false);
        } else if (entityType === 'SHIPPER' || entityType === 'CARRIER' || entityType === 'PRODUCER' || entityType === 'RECEIVER' || entityType === 'BROKER') {
            setIsCarriersLoading(true);
            if (isListNotEmpty(linkedAccounts)) {
                const linkedCarriers = linkedAccounts.filter(a => a.entityTypes.includes("CARRIER") === true);
                if (isListNotEmpty(linkedCarriers)) {
                    setCarriers(orderBy(linkedCarriers, ['name'], ['asc']));
                } else {
                    setCarriers([]);
                }
            }
            setIsCarriersLoading(false);
        }
    }, [entityType, entityId, accounts, linkedAccounts]);

    useEffect(() => {
        if (isObjectNotEmpty(servicesData)) {
            if (isStringNotEmpty(servicesData.tenderOption)) {
                methods.setValue('tenderOption', servicesData.tenderOption);
                if (servicesData.tenderOption === 'ASSIGN_CARRIER') {
                    setShowCarrierOptions(true);
                    setShowPricing(true);
                    setShowShipperPricing(true);
                    setShowCarrierPricing(true);
                } else if (servicesData.tenderOption === 'IRIS') {
                    setShowCarrierOptions(false);
                    setShowPricing(false);
                    setShowShipperPricing(false);
                    setShowCarrierPricing(false);
                } else if (servicesData.tenderOption === 'DEFER') {
                    setShowCarrierOptions(false);
                    if (entityType === 'STAFF') {
                        setShowPricing(true);
                        setShowShipperPricing(true);
                        setShowCarrierPricing(false);
                    } else {
                        setShowPricing(false);
                        setShowShipperPricing(false);
                        setShowCarrierPricing(false);
                    }
                } else {
                    setShowCarrierOptions(false);
                    setShowPricing(false);
                    setShowShipperPricing(false);
                    setShowCarrierPricing(false);
                }
            }
            if (isStringNotEmpty(servicesData.parentName)) {
                methods.setValue('parentName', servicesData.parentName);
            }
            if (hideLoadSpecificInformation === false) {
                if (isStringNotEmpty(servicesData.customerOrderNumber)) {
                    methods.setValue('customerOrderNumber', servicesData.customerOrderNumber);
                }
            }
        }
    }, [entityType, hideLoadSpecificInformation]);

    useEffect(() => {
        if (isListNotEmpty(carriers) && isObjectNotEmpty(servicesData)) {
            if (isStringNotEmpty(servicesData.assignedCarrierId)) {
                methods.setValue('assignedCarrierId', servicesData.assignedCarrierId ? servicesData.assignedCarrierId : null);
            }
        }
    }, [carriers, entityType]);

    useEffect(() => {
        if (isObjectNotEmpty(servicesData) && (showPricing === true || entityType === 'CARRIER')) {
            if (entityType === "CARRIER") {
                if (isStringNotEmpty(servicesData.rateOption)) {
                    methods.setValue('rateOption', servicesData.rateOption);
                    if (servicesData.rateOption === 'PER_MILE') {
                        setShowPerMile(true);
                        setShowContractRate(false);
                    } else if (servicesData.rateOption === 'CONTRACT_RATE') {
                        setShowPerMile(false);
                        setShowContractRate(true);
                    } else {
                        setShowPerMile(false);
                        setShowContractRate(false);
                    }
                }
                if (isNumberNotEmpty(servicesData.baseRatePerMileAmount)) {
                    methods.setValue('baseRatePerMileAmount', servicesData.baseRatePerMileAmount);
                    setBaseRatePerMileAmount(servicesData.baseRatePerMileAmount);
                }
                if (isNumberNotEmpty(servicesData.fuelSurchargePerMileAmount)) {
                    methods.setValue('fuelSurchargePerMileAmount', servicesData.fuelSurchargePerMileAmount);
                    setFuelSurchargePerMileAmount(servicesData.fuelSurchargePerMileAmount);
                }
                if (isNumberNotEmpty(servicesData.contractRateAmount)) {
                    methods.setValue('contractRateAmount', servicesData.contractRateAmount);
                    setContractRateAmount(servicesData.contractRateAmount);
                }
                if (isNumberNotEmpty(servicesData.fuelSurchargeAmount)) {
                    methods.setValue('fuelSurchargeAmount', servicesData.fuelSurchargeAmount);
                    setFuelSurchargeAmount(servicesData.fuelSurchargeAmount);
                }
                if (isNumberNotEmpty(servicesData.contractRateCarrierAmount)) {
                    methods.setValue('contractRateCarrierAmount', undefined);
                    setContractRateCarrierAmount(0);
                }
            } else if (entityType === "SHIPPER") {
                if (isStringNotEmpty(servicesData.rateOption)) {
                    methods.setValue('rateOption', servicesData.rateOption);
                    if (servicesData.rateOption === 'PER_MILE') {
                        setShowPerMile(true);
                        setShowContractRate(false);
                    } else if (servicesData.rateOption === 'CONTRACT_RATE') {
                        setShowPerMile(false);
                        setShowContractRate(true);
                    } else {
                        setShowPerMile(false);
                        setShowContractRate(false);
                    }
                }
                if (isNumberNotEmpty(servicesData.baseRatePerMileAmount)) {
                    methods.setValue('baseRatePerMileAmount', servicesData.baseRatePerMileAmount);
                    setBaseRatePerMileAmount(servicesData.baseRatePerMileAmount);
                }
                if (isNumberNotEmpty(servicesData.fuelSurchargePerMileAmount)) {
                    methods.setValue('fuelSurchargePerMileAmount', servicesData.fuelSurchargePerMileAmount);
                    setFuelSurchargePerMileAmount(servicesData.fuelSurchargePerMileAmount);
                }
                if (isNumberNotEmpty(servicesData.contractRateAmount)) {
                    methods.setValue('contractRateAmount', servicesData.contractRateAmount);
                    setContractRateAmount(servicesData.contractRateAmount);
                }
                if (isNumberNotEmpty(servicesData.fuelSurchargeAmount)) {
                    methods.setValue('fuelSurchargeAmount', servicesData.fuelSurchargeAmount);
                    setFuelSurchargeAmount(servicesData.fuelSurchargeAmount);
                }
                if (isNumberNotEmpty(servicesData.contractRateCarrierAmount)) {
                    methods.setValue('contractRateCarrierAmount', undefined);
                    setContractRateCarrierAmount(0);
                }
            } else if (entityType === "STAFF") {
                if (isStringNotEmpty(servicesData.rateOption)) {
                    methods.setValue('rateOption', servicesData.rateOption);
                    if (servicesData.rateOption === 'PER_MILE') {
                        setShowPerMile(true);
                        setShowContractRate(false);
                    } else if (servicesData.rateOption === 'CONTRACT_RATE') {
                        setShowPerMile(false);
                        setShowContractRate(true);
                    } else {
                        setShowPerMile(false);
                        setShowContractRate(false);
                    }
                }
                if (isNumberNotEmpty(servicesData.baseRatePerMileAmount)) {
                    methods.setValue('baseRatePerMileAmount', servicesData.baseRatePerMileAmount);
                    setBaseRatePerMileAmount(servicesData.baseRatePerMileAmount);
                }
                if (isNumberNotEmpty(servicesData.fuelSurchargePerMileAmount)) {
                    methods.setValue('fuelSurchargePerMileAmount', servicesData.fuelSurchargePerMileAmount);
                    setFuelSurchargePerMileAmount(servicesData.fuelSurchargePerMileAmount);
                }
                if (isNumberNotEmpty(servicesData.contractRateAmount)) {
                    methods.setValue('contractRateAmount', servicesData.contractRateAmount);
                    setContractRateAmount(servicesData.contractRateAmount);
                }
                if (isNumberNotEmpty(servicesData.fuelSurchargeAmount)) {
                    methods.setValue('fuelSurchargeAmount', servicesData.fuelSurchargeAmount);
                    setFuelSurchargeAmount(servicesData.fuelSurchargeAmount);
                }
                if (isNumberNotEmpty(servicesData.contractRateCarrierAmount)) {
                    methods.setValue('contractRateCarrierAmount', servicesData.contractRateCarrierAmount);
                    setContractRateCarrierAmount(servicesData.contractRateCarrierAmount);
                }
            }
        }
    }, [entityType, showPricing]);

    useEffect(() => {
        if (isListNotEmpty(lineItemTypes) && isListNotEmpty(pickUpStops) && isListNotEmpty(dropOffStops) && isStringNotEmpty(entityType)) {
            if (entityType === 'STAFF' || entityType === 'CARRIER' || (entityType === 'SHIPPER' && isStringNotEmpty(tenderOption))) {
                generateStopFeesInvoiceLineItems();
            }
        }
    }, [lineItemTypes, pickUpStops, dropOffStops, entityType, tenderOption]);

    useEffect(() => {
        // console.log(invoiceLineItemList);
        let shipperContractAmount = 0.0;
        let carrierContractAmount = 0.0;
        if (entityType === "STAFF") {
            if (isNumberGreaterThanZero(baseRatePerMileAmount)) {
                if (isNumberGreaterThanZero(practicalRouteMiles)) {
                    carrierContractAmount = (Number(baseRatePerMileAmount) * Number(practicalRouteMiles));
                    if (isNumberGreaterThanZero(fuelSurchargePerMileAmount)) {
                        carrierContractAmount += (Number(fuelSurchargePerMileAmount) * Number(practicalRouteMiles));
                    }
                }
            } else if (isNumberGreaterThanZero(contractRateCarrierAmount)) {
                carrierContractAmount = contractRateCarrierAmount;
            }

            shipperContractAmount = isNumberGreaterThanZero(contractRateAmount) ? contractRateAmount : 0.0;
        } else if (entityType === "CARRIER") {
            if (isNumberGreaterThanZero(baseRatePerMileAmount)) {
                if (isNumberGreaterThanZero(practicalRouteMiles)) {
                    shipperContractAmount = (Number(baseRatePerMileAmount) * Number(practicalRouteMiles));
                    if (isNumberGreaterThanZero(fuelSurchargePerMileAmount)) {
                        shipperContractAmount += (Number(fuelSurchargePerMileAmount) * Number(practicalRouteMiles));
                    }
                }
            } else if (isNumberGreaterThanZero(contractRateAmount)) {
                shipperContractAmount = contractRateAmount;
                if (isNumberGreaterThanZero(fuelSurchargeAmount)) {
                    shipperContractAmount += Number(fuelSurchargeAmount);
                }
            }
            carrierContractAmount = shipperContractAmount;
        } else if (entityType === "SHIPPER") {
            if (isNumberGreaterThanZero(baseRatePerMileAmount)) {
                if (isNumberGreaterThanZero(practicalRouteMiles)) {
                    shipperContractAmount = (Number(baseRatePerMileAmount) * Number(practicalRouteMiles));
                    if (isNumberGreaterThanZero(fuelSurchargePerMileAmount)) {
                        shipperContractAmount += (Number(fuelSurchargePerMileAmount) * Number(practicalRouteMiles));
                    }
                }
            } else if (isNumberGreaterThanZero(contractRateAmount)) {
                shipperContractAmount = contractRateAmount;
                if (isNumberGreaterThanZero(fuelSurchargeAmount)) {
                    shipperContractAmount += Number(fuelSurchargeAmount);
                }
            }
            carrierContractAmount = shipperContractAmount;
        }
        let summary = Pricing.newLoadPricingSummary(
            0,
            0,
            shipperContractAmount,
            carrierContractAmount,
            practicalRouteMiles,
            invoiceLineItemList
        );

        // console.log(summary);

        setTotalFeePerMile(summary.feePerMileAmount);
        setShipperRatePerMile(summary.shipperRatePerMileAmount);
        setCarrierRatePerMile(summary.carrierRatePerMileAmount);
        setCommissionPercentage(summary.IRISPercentage);
        setTotalAddOnCharge(summary.addOnChargeAmount);
        setTotalAddOnCost(summary.addOnCostAmount);
        setShipperAmount(summary.shipperAmount);
        setCarrierAmount(summary.carrierAmount);
        setIRISProfit(summary.IRISAmount);

        setServicesData({
            ...servicesData,
            totalFeePerMile: summary.feePerMileAmount,
            shipperRatePerMile: summary.shipperRatePerMileAmount,
            carrierRatePerMile: summary.carrierRatePerMileAmount,
            commissionPercentage: summary.IRISPercentage,
            totalAddOnCharge: summary.addOnChargeAmount,
            totalAddOnCost: summary.addOnCostAmount,
            shipperAmount: summary.shipperAmount,
            carrierAmount: summary.carrierAmount,
            IRISProfit: summary.IRISAmount
        });
    }, [invoiceLineItemList, practicalRouteMiles, baseRatePerMileAmount, fuelSurchargePerMileAmount, fuelSurchargeAmount, contractRateAmount, contractRateCarrierAmount]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const formItemLayoutDouble = {
        labelCol1: { span: 12 },
        wrapperCol1: { span: 12 },
        labelCol2: { span: 12 },
        wrapperCol2: { span: 12 },
    };

    const radioStyle = {
        display: 'block',
        minHeight: '60px',
        lineHeight: '30px',
    };

    //#endregion

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className="load-creation-step-container">
                    <div className="load-creation-step">
                        <Header
                            titleStyle={{ fontSize: 24 }}
                            style={{ paddingLeft: 0, paddingRight: 0 }}
                            title={(entityType === 'STAFF' || entityType === 'SHIPPER') ? "Carrier & Rate Options" : "Invoice"}
                            title2={(entityType === 'STAFF' || entityType === 'SHIPPER') ? "Add invoice line items below to calculate all-in rate." : "Add fees below to calculate all-in rate."}
                            icon={<FontAwesomeIcon className="anticon" icon={faDollarSign} />}
                            iconStyle={{ background: 'transparent' }}
                        />

                        {entityType === 'SHIPPER' ? (
                            <>
                                <Fieldset legend="LOAD INFORMATION">
                                    <Row gutter={[8, 8]}>
                                        <Col span={12}>
                                            <FormItem {...formItemLayout} label={hideLoadSpecificInformation === true ? "Bundle Name" : "Load Name"} required={hideLoadSpecificInformation === true} format="vertical"
                                                render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChangeParentName(e.target.value); onChange(e.target.value); }} value={value} name={name} placeholder={hideLoadSpecificInformation === true ? "Bundle Name" : "Load Name"} ref={ref} />}
                                                rules={{ required: hideLoadSpecificInformation === true ? 'Required Field' : false }}
                                                name="parentName"
                                                help={
                                                    <div>
                                                        <span style={{ color: '#A9A9A9' }}>{hideLoadSpecificInformation === true ? "This field provides you the option to add an alias or nickname to display for this bundle of identical loads instead of the Iris Freight pre-assigned Load References. It should be unique but doesn't have to be." : "This field provides you the option to add an alias or nickname to display for this load instead of the Iris Freight pre-assigned Load Reference. It should be unique but doesn't have to be."}</span>
                                                    </div>
                                                }
                                            />
                                        </Col>
                                        {hideLoadSpecificInformation === false ? (
                                            <Col span={12}>
                                                <FormItem {...formItemLayout} label="Customer Order Number" required={dontRequireLoadSpecificInformation === false} format="vertical"
                                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChangeCustomerOrderNumber(e.target.value); onChange(e.target.value); }} value={value} name={name} placeholder="Customer Order Number" ref={ref} />}
                                                    rules={{ required: dontRequireLoadSpecificInformation === false ? 'Required Field' : false }}
                                                    name="customerOrderNumber"
                                                />
                                            </Col>
                                        ) : null}
                                    </Row>
                                </Fieldset>
                                <Fieldset legend="TENDER OPTIONS">
                                    {hideLoadSpecificInformation === true ? (
                                        <FormItem {...formItemLayout} format="vertical"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Radio.Group onBlur={onBlur} onChange={e => { onChangeTenderOption(e.target.value); onChange(e.target.value); }} value={value} name={name} ref={ref}>
                                                    <Radio style={radioStyle} key="ASSIGN_CARRIER" value="ASSIGN_CARRIER">
                                                        <span style={{ fontWeight: 'bold', fontSize: 16 }}>Offer to a Preferred Carrier</span><br />
                                                        <FormItem labelCol={0} wrapperCol={24} required format="vertical"
                                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                                <Select
                                                                    placeholder="Please Search and Select a Carrier"
                                                                    allowClear={true}
                                                                    style={{ width: '100%' }}
                                                                    virtual={false}
                                                                    loading={isCarriersLoading === true}
                                                                    disabled={showCarrierOptions === false}
                                                                    onBlur={onBlur}
                                                                    onChange={(selected) => { onChangeAssignedCarrierId(selected); onChange(selected); }}
                                                                    value={value}
                                                                    name={name}
                                                                    showSearch={true}
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) =>
                                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    }
                                                                    notFoundContent={
                                                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Carrier Accounts for you to choose from.">
                                                                            <Button type="primary" onClick={() => { props.history.push({ pathname: `/linkedCarriers/` }); }}>Setup Preferred Carriers</Button>
                                                                        </Empty>
                                                                    }
                                                                    ref={ref}
                                                                >
                                                                    {carriers.map(c => <Option value={c.id} key={c.id}>{c.name}</Option>)}
                                                                </Select>
                                                            )}
                                                            rules={{ required: showCarrierOptions === true ? "Required Field" : false }}
                                                            name="assignedCarrierId"
                                                        />
                                                    </Radio>
                                                </Radio.Group>
                                            )}
                                            rules={{ required: 'Required Field' }}
                                            name="tenderOption"
                                        />
                                    ) : (
                                        <FormItem {...formItemLayout} format="vertical"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Radio.Group onBlur={onBlur} onChange={e => { onChangeTenderOption(e.target.value); onChange(e.target.value); }} value={value} name={name} ref={ref}>
                                                    <Radio style={radioStyle} key="ASSIGN_CARRIER" value="ASSIGN_CARRIER">
                                                        <span style={{ fontWeight: 'bold', fontSize: 16 }}>Offer to a Preferred Carrier</span><br />
                                                        <FormItem labelCol={0} wrapperCol={24} required format="vertical"
                                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                                <Select
                                                                    placeholder="Please Search and Select a Carrier"
                                                                    allowClear={true}
                                                                    style={{ width: '100%' }}
                                                                    virtual={false}
                                                                    loading={isCarriersLoading === true}
                                                                    disabled={showCarrierOptions === false}
                                                                    onBlur={onBlur}
                                                                    onChange={(selected) => { onChangeAssignedCarrierId(selected); onChange(selected); }}
                                                                    value={value}
                                                                    name={name}
                                                                    showSearch={true}
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) =>
                                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    }
                                                                    notFoundContent={
                                                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Carrier Accounts for you to choose from.">
                                                                            <Button type="primary" onClick={() => { props.history.push({ pathname: `/linkedCarriers/` }); }}>Setup Preferred Carriers</Button>
                                                                        </Empty>
                                                                    }
                                                                    ref={ref}
                                                                >
                                                                    {carriers.map(c => <Option value={c.id} key={c.id}>{c.name}</Option>)}
                                                                </Select>
                                                            )}
                                                            rules={{ required: showCarrierOptions === true ? "Required Field" : false }}
                                                            name="assignedCarrierId"
                                                        />
                                                    </Radio>
                                                    <Radio style={radioStyle} key="IRIS" value="IRIS">
                                                        <span style={{ fontWeight: 'bold', fontSize: 16 }}>Have IRIS Freight Broker the Load</span>
                                                    </Radio>
                                                    <Radio style={radioStyle} key="DEFER" value="DEFER">
                                                        <span style={{ fontWeight: 'bold', fontSize: 16 }}>Tender this load later</span><br /><span style={{ fontSize: 14, paddingLeft: 24 }}>Tender this load later from the loads page.</span>
                                                    </Radio>
                                                </Radio.Group>
                                            )}
                                            rules={{ required: 'Required Field' }}
                                            name="tenderOption"
                                        />
                                    )}
                                </Fieldset>
                                {showPricing === true ? (
                                    <>
                                        <Row gutter={[8, 8]}>
                                            <Col span={24}>
                                                <Fieldset legend="ROUTE & EQUIPMENT NEEDS">
                                                    {isNumberNotEmpty(practicalRouteMiles) ? <DataRow title="Trip Miles" titleStyle={{ fontSize: 14, color: '#000000' }} valueStyle={{ fontSize: 14, color: '#000000' }} value={practicalRouteMiles} units="MI" dataType="Distance" /> : null}
                                                    {isListNotEmpty(pickUpStops) && isListNotEmpty(dropOffStops) ? <DataRow title="Total Stops" titleStyle={{ fontSize: 14, color: '#000000' }} valueStyle={{ fontSize: 14, color: '#000000' }} value={pickUpStops.length + dropOffStops.length} /> : null}
                                                    {isListNotEmpty(equipmentData.equipmentSpecifics) ? (
                                                        <DataListRow titleStyle={{ fontSize: 14, color: '#000000' }} valueStyle={{ fontSize: 14, color: '#A9A9A9', marginLeft: 8 }} title="Equipment Needs and Services" data={equipmentData.equipmentSpecifics} dataType="EquipmentSpecific" />
                                                    ) : null}
                                                    {isListNotEmpty(equipmentData.driverRequirements) ? (
                                                        <DataListRow titleStyle={{ fontSize: 14, color: '#000000' }} valueStyle={{ fontSize: 14, color: '#A9A9A9', marginLeft: 8 }} title="Driver Requirements" data={equipmentData.driverRequirements} dataType="DriverRequirement" />
                                                    ) : null}
                                                </Fieldset>
                                            </Col>
                                            <Col span={24}>
                                                <Fieldset legend="STOP FEES">
                                                    {invoiceLineItemList.filter(i => i.fromEntityType === 'SHIPPER' && Enums.StopFees.doesNameExist(i.itemType)).map((lineItem, index) => {
                                                        return (
                                                            <DataRow key={`subtotal-line-item-${index}`} titleStyle={{ fontSize: 14, color: '#000000' }} valueStyle={{ fontSize: 14, color: '#000000' }} title={lineItem.description} value={lineItem.totalAmount} dataType="Money" />
                                                        );
                                                    })}
                                                    <div><span style={{ fontWeight: 'bold', fontSize: 16, color: '#000000' }}>Subtotal: {stringFormatter.toFormattedMoney(totalAddOnCharge)}</span></div>
                                                </Fieldset>
                                            </Col>
                                        </Row>
                                        <Fieldset legend="CARRIER RATE OPTIONS">
                                            <FormItem {...formItemLayout} format="vertical"
                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                    <Radio.Group onBlur={onBlur} onChange={e => { onChangeRateOption(e.target.value); onChange(e.target.value); }} value={value} name={name} ref={ref}>
                                                        <Radio style={radioStyle} key="PER_MILE" value="PER_MILE">
                                                            <span style={{ fontWeight: 'bold', fontSize: 16 }}>Rate Per Mile</span><br />
                                                            <div style={{ paddingLeft: 24, paddingTop: 12, paddingBottom: 12 }}>
                                                                <div><span style={{ fontSize: 14 }}>Calculate the charge to your customer by the miles for this trip.</span></div>
                                                                <FormItemDouble {...formItemLayoutDouble} label1="Rate Per Mile" label2="Fuel Surcharge (optional)" required1 format="vertical"
                                                                    render1={({ onChange, onBlur, value, name, ref }) => (
                                                                        <InputNumber
                                                                            precision={2}
                                                                            min={0}
                                                                            placeholder="Enter the rate per mile in USD $"
                                                                            style={{ width: '100%' }}
                                                                            onBlur={onBlur}
                                                                            onChange={e => { setBaseRatePerMileAmount(e); onChange(e); }}
                                                                            value={value}
                                                                            name={name}
                                                                            disabled={showPerMile === false}
                                                                            ref={ref}
                                                                        />
                                                                    )}
                                                                    rules1={{ required: showPerMile === true ? "Required Field" : null }}
                                                                    name1="baseRatePerMileAmount"
                                                                    render2={({ onChange, onBlur, value, name, ref }) => (
                                                                        <InputNumber
                                                                            precision={2}
                                                                            min={0}
                                                                            placeholder="Enter the rate per mile in USD $"
                                                                            style={{ width: '100%' }}
                                                                            onBlur={onBlur}
                                                                            onChange={e => { setFuelSurchargePerMileAmount(e); onChange(e); }}
                                                                            value={value}
                                                                            name={name}
                                                                            disabled={showPerMile === false}
                                                                            ref={ref}
                                                                        />
                                                                    )}
                                                                    rules2={{ required: false }}
                                                                    name2="fuelSurchargePerMileAmount"
                                                                />
                                                            </div>
                                                        </Radio>
                                                        <Radio style={radioStyle} key="CONTRACT_RATE" value="CONTRACT_RATE">
                                                            <span style={{ fontWeight: 'bold', fontSize: 16 }}>Contract Rate</span><br />
                                                            <div style={{ paddingLeft: 24, paddingTop: 12, paddingBottom: 12 }}>
                                                                <div><span style={{ fontSize: 14 }}>Enter a rate to charge, in addition to fees accounted for above in Subtotal.</span></div>
                                                                <FormItemDouble {...formItemLayoutDouble} label1={`Subtotal: ${stringFormatter.toFormattedMoney(totalAddOnCharge)} plus`} label2="Fuel Surcharge (optional)" required1 format="vertical"
                                                                    render1={({ onChange, onBlur, value, name, ref }) => (
                                                                        <InputNumber
                                                                            precision={2}
                                                                            min={0}
                                                                            placeholder="Enter the rate in USD $"
                                                                            style={{ width: '100%' }}
                                                                            onBlur={onBlur}
                                                                            onChange={e => { setContractRateAmount(e); onChange(e); }}
                                                                            value={value}
                                                                            name={name}
                                                                            disabled={showContractRate === false}
                                                                            ref={ref}
                                                                        />
                                                                    )}
                                                                    rules1={{ required: showContractRate === true ? "Required Field" : false }}
                                                                    name1="contractRateAmount"
                                                                    render2={({ onChange, onBlur, value, name, ref }) => (
                                                                        <InputNumber
                                                                            precision={2}
                                                                            min={0}
                                                                            placeholder="Enter the rate in USD $"
                                                                            style={{ width: '100%' }}
                                                                            onBlur={onBlur}
                                                                            onChange={e => { setFuelSurchargeAmount(e); onChange(e); }}
                                                                            value={value}
                                                                            name={name}
                                                                            disabled={showContractRate === false}
                                                                            ref={ref}
                                                                        />
                                                                    )}
                                                                    rules2={{ required: false }}
                                                                    name2="fuelSurchargeAmount"
                                                                />
                                                            </div>
                                                        </Radio>
                                                    </Radio.Group>
                                                )}
                                                rules={{ required: 'Required Field' }}
                                                name="rateOption"
                                            />
                                        </Fieldset>
                                        <Row gutter={[8, 8]}>
                                            <Col span={24}>
                                                <div style={{ marginBottom: 12 }}><span style={{ fontWeight: 'bold', fontSize: 16, color: '#000000' }}>Total: {stringFormatter.toFormattedMoney(carrierAmount)}</span></div>
                                                <div><span style={{ fontWeight: 'bold', fontSize: 14, color: '#000000' }}>Total Rate Per Mile: {stringFormatter.toFormattedMoney(carrierRatePerMile)}</span></div>
                                            </Col>
                                        </Row>
                                    </>
                                ) : null}
                            </>
                        ) : null}
                        {entityType === "STAFF" ? (
                            <>
                                <Fieldset legend="LOAD INFORMATION">
                                    <Row gutter={[8, 8]}>
                                        <Col span={12}>
                                            <FormItem {...formItemLayout} label={hideLoadSpecificInformation === true ? "Bundle Name" : "Load Name"} required={hideLoadSpecificInformation === true} format="vertical"
                                                render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChangeParentName(e.target.value); onChange(e.target.value); }} value={value} name={name} placeholder={hideLoadSpecificInformation === true ? "Bundle Name" : "Load Name"} ref={ref} />}
                                                rules={{ required: hideLoadSpecificInformation === true ? 'Required Field' : false }}
                                                name="parentName"
                                                help={
                                                    <div>
                                                        <span style={{ color: '#A9A9A9' }}>{hideLoadSpecificInformation === true ? "This field provides you the option to add an alias or nickname to display for this bundle of identical loads instead of the Iris Freight pre-assigned Load References. It should be unique but doesn't have to be." : "This field provides you the option to add an alias or nickname to display for this load instead of the Iris Freight pre-assigned Load Reference. It should be unique but doesn't have to be."}</span>
                                                    </div>
                                                }
                                            />
                                        </Col>
                                        {hideLoadSpecificInformation === false ? (
                                            <Col span={12}>
                                                <FormItem {...formItemLayout} label="Customer Order Number" required={dontRequireLoadSpecificInformation === false} format="vertical"
                                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChangeCustomerOrderNumber(e.target.value); onChange(e.target.value); }} value={value} name={name} placeholder="Customer Order Number" ref={ref} />}
                                                    rules={{ required: dontRequireLoadSpecificInformation === false ? 'Required Field' : false }}
                                                    name="customerOrderNumber"
                                                />
                                            </Col>
                                        ) : null}
                                    </Row>
                                </Fieldset>
                                <Fieldset legend="TENDER OPTIONS">
                                    {hideLoadSpecificInformation === true ? (
                                        <FormItem {...formItemLayout} format="vertical"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Radio.Group onBlur={onBlur} onChange={e => { onChangeTenderOption(e.target.value); onChange(e.target.value); }} value={value} name={name} ref={ref}>
                                                    <Radio style={radioStyle} key="ASSIGN_CARRIER" value="ASSIGN_CARRIER">
                                                        <span style={{ fontWeight: 'bold', fontSize: 16 }}>Offer to a Preferred Carrier</span><br />
                                                        <div style={{ paddingLeft: 24, paddingTop: 12, paddingBottom: 12 }}>
                                                            <FormItem labelCol={0} wrapperCol={24} required format="vertical"
                                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                                    <Select
                                                                        placeholder="Please Search and Select a Carrier"
                                                                        allowClear={true}
                                                                        style={{ width: '100%' }}
                                                                        virtual={false}
                                                                        loading={isCarriersLoading === true}
                                                                        disabled={showCarrierOptions === false}
                                                                        onBlur={onBlur}
                                                                        onChange={(selected) => { onChangeAssignedCarrierId(selected); onChange(selected); }}
                                                                        value={value}
                                                                        name={name}
                                                                        showSearch={true}
                                                                        optionFilterProp="children"
                                                                        filterOption={(input, option) =>
                                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        }
                                                                        notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Carrier Accounts for you to choose from." />}
                                                                        ref={ref}
                                                                    >
                                                                        {carriers.map(c => <Option value={c.id} key={c.id}>{c.name}</Option>)}
                                                                    </Select>
                                                                )}
                                                                rules={{ required: showCarrierOptions === true ? "Required Field" : false }}
                                                                name="assignedCarrierId"
                                                            />
                                                        </div>
                                                    </Radio>
                                                </Radio.Group>
                                            )}
                                            rules={{ required: 'Required Field' }}
                                            name="tenderOption"
                                        />
                                    ) : (
                                        <FormItem {...formItemLayout} format="vertical"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Radio.Group onBlur={onBlur} onChange={e => { onChangeTenderOption(e.target.value); onChange(e.target.value); }} value={value} name={name} ref={ref}>
                                                    <Radio style={radioStyle} key="ASSIGN_CARRIER" value="ASSIGN_CARRIER">
                                                        <span style={{ fontWeight: 'bold', fontSize: 16 }}>Offer to a Preferred Carrier</span><br />
                                                        <div style={{ paddingLeft: 24, paddingTop: 12, paddingBottom: 12 }}>
                                                            <FormItem labelCol={0} wrapperCol={24} required format="vertical"
                                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                                    <Select
                                                                        placeholder="Please Search and Select a Carrier"
                                                                        allowClear={true}
                                                                        style={{ width: '100%' }}
                                                                        virtual={false}
                                                                        loading={isCarriersLoading === true}
                                                                        disabled={showCarrierOptions === false}
                                                                        onBlur={onBlur}
                                                                        onChange={(selected) => { onChangeAssignedCarrierId(selected); onChange(selected); }}
                                                                        value={value}
                                                                        name={name}
                                                                        showSearch={true}
                                                                        optionFilterProp="children"
                                                                        filterOption={(input, option) =>
                                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        }
                                                                        notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Carrier Accounts for you to choose from." />}
                                                                        ref={ref}
                                                                    >
                                                                        {carriers.map(c => <Option value={c.id} key={c.id}>{c.name}</Option>)}
                                                                    </Select>
                                                                )}
                                                                rules={{ required: showCarrierOptions === true ? "Required Field" : false }}
                                                                name="assignedCarrierId"
                                                            />
                                                        </div>
                                                    </Radio>
                                                    <Radio style={radioStyle} key="DEFER" value="DEFER">
                                                        <span style={{ fontWeight: 'bold', fontSize: 16 }}>Tender this load later</span><br /><span style={{ fontSize: 14, paddingLeft: 24 }}>Tender this load later from the loads page.</span>
                                                    </Radio>
                                                </Radio.Group>
                                            )}
                                            rules={{ required: 'Required Field' }}
                                            name="tenderOption"
                                        />
                                    )}
                                </Fieldset>
                                {showPricing === true ? (
                                    <>
                                        <Row gutter={[8, 8]}>
                                            <Col span={24}>
                                                <Fieldset legend="ROUTE & EQUIPMENT NEEDS">
                                                    {isNumberNotEmpty(practicalRouteMiles) ? <DataRow title="Trip Miles" titleStyle={{ fontSize: 14, color: '#000000' }} valueStyle={{ fontSize: 14, color: '#000000' }} value={practicalRouteMiles} units="MI" dataType="Distance" /> : null}
                                                    {isListNotEmpty(pickUpStops) && isListNotEmpty(dropOffStops) ? <DataRow title="Total Stops" titleStyle={{ fontSize: 14, color: '#000000' }} valueStyle={{ fontSize: 14, color: '#000000' }} value={pickUpStops.length + dropOffStops.length} /> : null}
                                                    {isListNotEmpty(equipmentData.equipmentSpecifics) ? (
                                                        <DataListRow titleStyle={{ fontSize: 14, color: '#000000' }} valueStyle={{ fontSize: 14, color: '#A9A9A9', marginLeft: 8 }} title="Equipment Needs and Services" data={equipmentData.equipmentSpecifics} dataType="EquipmentSpecific" />
                                                    ) : null}
                                                    {isListNotEmpty(equipmentData.driverRequirements) ? (
                                                        <DataListRow titleStyle={{ fontSize: 14, color: '#000000' }} valueStyle={{ fontSize: 14, color: '#A9A9A9', marginLeft: 8 }} title="Driver Requirements" data={equipmentData.driverRequirements} dataType="DriverRequirement" />
                                                    ) : null}
                                                </Fieldset>
                                            </Col>
                                            <Col span={24}>
                                                <Fieldset legend="STOP FEES">
                                                    {invoiceLineItemList.filter(i => i.fromEntityType === 'SHIPPER' && Enums.StopFees.doesNameExist(i.itemType)).map((lineItem, index) => {
                                                        return (
                                                            <DataRow key={`subtotal-line-item-${index}`} titleStyle={{ fontSize: 14, color: '#000000' }} valueStyle={{ fontSize: 14, color: '#000000' }} title={lineItem.description} value={lineItem.totalAmount} dataType="Money" />
                                                        );
                                                    })}
                                                    <div><span style={{ fontWeight: 'bold', fontSize: 16, color: '#000000' }}>Subtotal: {stringFormatter.toFormattedMoney(totalAddOnCharge)}</span></div>
                                                </Fieldset>
                                            </Col>
                                        </Row>
                                        {showShipperPricing === true ? (
                                            <Fieldset legend="RATE TO CHARGE SHIPPER">
                                                <Row gutter={[8, 8]}>
                                                    <Col span={24}>
                                                        <FormItem {...formItemLayout} label={`Subtotal: ${stringFormatter.toFormattedMoney(totalAddOnCharge)} plus`} required format="vertical"
                                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                                <InputNumber
                                                                    precision={2}
                                                                    min={0}
                                                                    placeholder="Enter the rate in USD $"
                                                                    style={{ width: '100%' }}
                                                                    onBlur={onBlur}
                                                                    onChange={e => { setContractRateAmount(e); onChange(e); }}
                                                                    value={value}
                                                                    name={name}
                                                                    ref={ref}
                                                                />
                                                            )}
                                                            rules={{ required: "Required Field" }}
                                                            name="contractRateAmount"
                                                        />
                                                    </Col>
                                                    <Col span={24}>
                                                        <div style={{ marginBottom: 12 }}><span style={{ fontWeight: 'bold', fontSize: 16, color: '#000000' }}>Total: {stringFormatter.toFormattedMoney(shipperAmount)}</span></div>
                                                        <div><span style={{ fontWeight: 'bold', fontSize: 14, color: '#000000' }}>Total Rate Per Mile: {stringFormatter.toFormattedMoney(shipperRatePerMile)}</span></div>
                                                    </Col>
                                                </Row>
                                            </Fieldset>
                                        ) : null}
                                        {showCarrierPricing === true ? (
                                            <>
                                                <Fieldset legend="CARRIER RATE OPTIONS">
                                                    <FormItem {...formItemLayout} format="vertical"
                                                        render={({ onChange, onBlur, value, name, ref }) => (
                                                            <Radio.Group onBlur={onBlur} onChange={e => { onChangeRateOption(e.target.value); onChange(e.target.value); }} value={value} name={name}>
                                                                <Radio style={radioStyle} key="PER_MILE" value="PER_MILE">
                                                                    <span style={{ fontWeight: 'bold', fontSize: 16 }}>Rate Per Mile</span><br />
                                                                    <div style={{ paddingLeft: 24, paddingTop: 12, paddingBottom: 12 }}>
                                                                        <div><span style={{ fontSize: 14 }}>Calculate the cost to the carrier by the miles for this trip.</span></div>
                                                                        <FormItemDouble {...formItemLayoutDouble} label1="Rate Per Mile" label2="Fuel Surcharge (optional)" required1 format="vertical"
                                                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                                                <InputNumber
                                                                                    precision={2}
                                                                                    min={0}
                                                                                    placeholder="Enter the rate per mile in USD $"
                                                                                    style={{ width: '100%' }}
                                                                                    onBlur={onBlur}
                                                                                    onChange={e => { setBaseRatePerMileAmount(e); onChange(e); }}
                                                                                    value={value}
                                                                                    name={name}
                                                                                    disabled={showPerMile === false}
                                                                                    ref={ref}
                                                                                />
                                                                            )}
                                                                            rules1={{ required: showPerMile === true ? "Required Field" : false }}
                                                                            name1="baseRatePerMileAmount"
                                                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                                                <InputNumber
                                                                                    precision={2}
                                                                                    min={0}
                                                                                    placeholder="Enter the rate per mile in USD $"
                                                                                    style={{ width: '100%' }}
                                                                                    onBlur={onBlur}
                                                                                    onChange={e => { setFuelSurchargePerMileAmount(e); onChange(e); }}
                                                                                    value={value}
                                                                                    name={name}
                                                                                    disabled={showPerMile === false}
                                                                                    ref={ref}
                                                                                />
                                                                            )}
                                                                            rules2={{ required: false }}
                                                                            name2="fuelSurchargePerMileAmount"
                                                                        />
                                                                    </div>
                                                                </Radio>
                                                                <Radio style={radioStyle} key="CONTRACT_RATE" value="CONTRACT_RATE">
                                                                    <span style={{ fontWeight: 'bold', fontSize: 16 }}>Contract Rate</span><br />
                                                                    <div style={{ paddingLeft: 24, paddingTop: 12, paddingBottom: 12 }}>
                                                                        <div><span style={{ fontSize: 14 }}>Enter a rate to charge, in addition to fees accounted for above in Subtotal.</span></div>
                                                                        <FormItem {...formItemLayout} label={`Subtotal: ${stringFormatter.toFormattedMoney(totalAddOnCost)} plus`} required format="vertical"
                                                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                                                <InputNumber
                                                                                    precision={2}
                                                                                    min={0}
                                                                                    placeholder="Enter the rate in USD $"
                                                                                    style={{ width: '100%' }}
                                                                                    onBlur={onBlur}
                                                                                    onChange={e => { setContractRateCarrierAmount(e); onChange(e); }}
                                                                                    value={value}
                                                                                    name={name}
                                                                                    disabled={showContractRate === false}
                                                                                    ref={ref}
                                                                                />
                                                                            )}
                                                                            rules={{ required: showContractRate === true ? "Required Field" : false }}
                                                                            name="contractRateCarrierAmount"
                                                                        />
                                                                    </div>
                                                                </Radio>
                                                            </Radio.Group>
                                                        )}
                                                        rules={{ required: 'Required Field' }}
                                                        name="rateOption"
                                                    />
                                                </Fieldset>
                                                <Row gutter={[8, 8]}>
                                                    <Col span={12}>
                                                        <div style={{ marginBottom: 12 }}><span style={{ fontWeight: 'bold', fontSize: 16, color: '#000000' }}>Total: {stringFormatter.toFormattedMoney(carrierAmount)}</span></div>
                                                        <div><span style={{ fontWeight: 'bold', fontSize: 14, color: '#000000' }}>Total Rate Per Mile: {stringFormatter.toFormattedMoney(carrierRatePerMile)}</span></div>
                                                    </Col>
                                                    <Col span={12}>
                                                        <div style={{ marginBottom: 12 }}><span style={{ fontWeight: 'bold', fontSize: 16, color: '#000000' }}>IRIS Profit: {stringFormatter.toFormattedMoney(IRISProfit)}</span></div>
                                                        <div><span style={{ fontWeight: 'bold', fontSize: 14, color: '#000000' }}>Commission: {stringFormatter.toFormattedPercentage(commissionPercentage)}</span></div>
                                                    </Col>
                                                </Row>
                                            </>
                                        ) : null}
                                    </>
                                ) : null}
                            </>
                        ) : null}
                        {entityType === "CARRIER" ? (
                            <>
                                <Fieldset legend="LOAD INFORMATION">
                                    <Row gutter={[8, 8]}>
                                        <Col span={12}>
                                            <FormItem {...formItemLayout} label={hideLoadSpecificInformation === true ? "Bundle Name" : "Load Name"} required={hideLoadSpecificInformation === true} format="vertical"
                                                render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChangeParentName(e.target.value); onChange(e.target.value); }} value={value} name={name} placeholder={hideLoadSpecificInformation === true ? "Bundle Name" : "Load Name"} ref={ref} />}
                                                rules={{ required: hideLoadSpecificInformation === true ? 'Required Field' : false }}
                                                name="parentName"
                                                help={
                                                    <div>
                                                        <span style={{ color: '#A9A9A9' }}>{hideLoadSpecificInformation === true ? "This field provides you the option to add an alias or nickname to display for this bundle of identical loads instead of the Iris Freight pre-assigned Load References. It should be unique but doesn't have to be." : "This field provides you the option to add an alias or nickname to display for this load instead of the Iris Freight pre-assigned Load Reference. It should be unique but doesn't have to be."}</span>
                                                    </div>
                                                }
                                            />
                                        </Col>
                                        {hideLoadSpecificInformation === false ? (
                                            <Col span={12}>
                                                <FormItem {...formItemLayout} label="Customer Order Number" format="vertical"
                                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChangeCustomerOrderNumber(e.target.value); onChange(e.target.value); }} value={value} name={name} placeholder="Customer Order Number" ref={ref} />}
                                                    rules={{ required: false }}
                                                    name="customerOrderNumber"
                                                />
                                            </Col>
                                        ) : null}
                                    </Row>
                                </Fieldset>
                                <Row gutter={[8, 8]}>
                                    <Col span={24}>
                                        <Fieldset legend="ROUTE & EQUIPMENT NEEDS">
                                            {isNumberNotEmpty(practicalRouteMiles) ? <DataRow title="Trip Miles" titleStyle={{ fontSize: 14, color: '#000000' }} valueStyle={{ fontSize: 14, color: '#000000' }} value={practicalRouteMiles} units="MI" dataType="Distance" /> : null}
                                            {isListNotEmpty(pickUpStops) && isListNotEmpty(dropOffStops) ? <DataRow title="Total Stops" titleStyle={{ fontSize: 14, color: '#000000' }} valueStyle={{ fontSize: 14, color: '#000000' }} value={pickUpStops.length + dropOffStops.length} /> : null}
                                            {isListNotEmpty(equipmentData.equipmentSpecifics) ? (
                                                <DataListRow titleStyle={{ fontSize: 14, color: '#000000' }} valueStyle={{ fontSize: 14, color: '#A9A9A9', marginLeft: 8 }} title="Equipment Needs and Services" data={equipmentData.equipmentSpecifics} dataType="EquipmentSpecific" />
                                            ) : null}
                                            {isListNotEmpty(equipmentData.driverRequirements) ? (
                                                <DataListRow titleStyle={{ fontSize: 14, color: '#000000' }} valueStyle={{ fontSize: 14, color: '#A9A9A9', marginLeft: 8 }} title="Driver Requirements" data={equipmentData.driverRequirements} dataType="DriverRequirement" />
                                            ) : null}
                                        </Fieldset>
                                    </Col>
                                </Row>
                                <Row gutter={[8, 8]}>
                                    <Col span={24}>
                                        <Fieldset legend="STOP FEES">
                                            {invoiceLineItemList.filter(i => i.fromEntityType === 'SHIPPER' && Enums.StopFees.doesNameExist(i.itemType)).map((lineItem, index) => {
                                                return (
                                                    <DataRow key={`subtotal-line-item-${index}`} titleStyle={{ fontSize: 14, color: '#000000' }} valueStyle={{ fontSize: 14, color: '#000000' }} title={lineItem.description} value={lineItem.totalAmount} dataType="Money" />
                                                );
                                            })}
                                            <div><span style={{ fontWeight: 'bold', fontSize: 16, color: '#000000' }}>Subtotal: {stringFormatter.toFormattedMoney(totalAddOnCharge)}</span></div>
                                        </Fieldset>
                                    </Col>
                                </Row>
                                <Fieldset legend="TRIP RATE OPTIONS">
                                    <FormItem {...formItemLayout} format="vertical"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Radio.Group onBlur={onBlur} onChange={e => { onChangeRateOption(e.target.value); onChange(e.target.value); }} value={value} name={name} ref={ref}>
                                                <Radio style={radioStyle} key="PER_MILE" value="PER_MILE">
                                                    <span style={{ fontWeight: 'bold', fontSize: 16 }}>Rate Per Mile</span><br />
                                                    <div style={{ paddingLeft: 24, paddingTop: 12, paddingBottom: 12 }}>
                                                        <div><span style={{ fontSize: 14 }}>Calculate the charge to your customer by the miles for this trip.</span></div>
                                                        <FormItemDouble {...formItemLayoutDouble} label1="Rate Per Mile" label2="Fuel Surcharge (optional)" required1 format="vertical"
                                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                                <InputNumber
                                                                    precision={2}
                                                                    min={0}
                                                                    placeholder="Enter the rate per mile in USD $"
                                                                    style={{ width: '100%' }}
                                                                    onBlur={onBlur}
                                                                    onChange={e => { setBaseRatePerMileAmount(e); onChange(e); }}
                                                                    value={value}
                                                                    name={name}
                                                                    disabled={showPerMile === false}
                                                                    ref={ref}
                                                                />
                                                            )}
                                                            rules1={{ required: showPerMile === true ? "Required Field" : false }}
                                                            name1="baseRatePerMileAmount"
                                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                                <InputNumber
                                                                    precision={2}
                                                                    min={0}
                                                                    placeholder="Enter the rate per mile in USD $"
                                                                    style={{ width: '100%' }}
                                                                    onBlur={onBlur}
                                                                    onChange={e => { setFuelSurchargePerMileAmount(e); onChange(e); }}
                                                                    value={value}
                                                                    name={name}
                                                                    disabled={showPerMile === false}
                                                                    ref={ref}
                                                                />
                                                            )}
                                                            rules2={{ required: false }}
                                                            name2="fuelSurchargePerMileAmount"
                                                        />
                                                    </div>
                                                </Radio>
                                                <Radio style={radioStyle} key="CONTRACT_RATE" value="CONTRACT_RATE">
                                                    <span style={{ fontWeight: 'bold', fontSize: 16 }}>Contract Rate</span><br />
                                                    <div style={{ paddingLeft: 24, paddingTop: 12, paddingBottom: 12 }}>
                                                        <div><span style={{ fontSize: 14 }}>Enter a rate to charge, in addition to fees accounted for above in Subtotal.</span></div>
                                                        <FormItemDouble {...formItemLayoutDouble} label1={`Subtotal: ${stringFormatter.toFormattedMoney(totalAddOnCharge)} plus`} label2="Fuel Surcharge (optional)" required1 format="vertical"
                                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                                <InputNumber
                                                                    precision={2}
                                                                    min={0}
                                                                    placeholder="Enter the rate in USD $"
                                                                    style={{ width: '100%' }}
                                                                    onBlur={onBlur}
                                                                    onChange={e => { setContractRateAmount(e); onChange(e); }}
                                                                    value={value}
                                                                    name={name}
                                                                    disabled={showContractRate === false}
                                                                    ref={ref}
                                                                />
                                                            )}
                                                            rules1={{ required: showContractRate === true ? "Required Field" : false }}
                                                            name1="contractRateAmount"
                                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                                <InputNumber
                                                                    precision={2}
                                                                    min={0}
                                                                    placeholder="Enter the rate in USD $"
                                                                    style={{ width: '100%' }}
                                                                    onBlur={onBlur}
                                                                    onChange={e => { setFuelSurchargeAmount(e); onChange(e); }}
                                                                    value={value}
                                                                    name={name}
                                                                    disabled={showContractRate === false}
                                                                    ref={ref}
                                                                />
                                                            )}
                                                            rules2={{ required: false }}
                                                            name2="fuelSurchargeAmount"
                                                        />
                                                    </div>
                                                </Radio>
                                            </Radio.Group>
                                        )}
                                        rules={{ required: 'Required Field' }}
                                        name="rateOption"
                                    />
                                </Fieldset>
                                <Row gutter={[8, 8]}>
                                    <Col span={24}>
                                        <div style={{ marginBottom: 12 }}><span style={{ fontWeight: 'bold', fontSize: 16, color: '#000000' }}>Total: {stringFormatter.toFormattedMoney(shipperAmount)}</span></div>
                                        <div><span style={{ fontWeight: 'bold', fontSize: 14, color: '#000000' }}>Total Rate Per Mile: {stringFormatter.toFormattedMoney(shipperRatePerMile)}</span></div>
                                    </Col>
                                </Row>
                            </>
                        ) : null}
                    </div>
                    <Row gutter={[12, 12]} className="load-creation-step-buttons">
                        <Col xs={{ span: 24, order: 2 }} md={{ span: 12, order: 1 }}>
                            {isNotNullOrUndefined(previousStepAction) ? (<Button type="default" block onClick={() => { previousStepAction('equipment'); }}>{previousStepActionName}</Button>) : null}
                        </Col>
                        <Col xs={{ span: 24, order: 1 }} md={{ span: 12, order: 2 }}>
                            <Button type="primary" block htmlType="submit">{nextStepActionName}</Button>
                        </Col>
                    </Row>
                </div>
            </Form>
        </FormProvider>
    );
};

export default withRouter(NewLoadServices);