import { isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import EntityUtils from '../entityUtils';

export const getLinkedLoadLaneDTO = (linkedLoadLane, accounts = [], trailerTypes = [], locations = [], accountId = null, linkedAccountId = null) => {
    let linkedLoadLaneObj = {
        id: linkedLoadLane.id,
        irisId: linkedLoadLane.irisId,
        laneId: linkedLoadLane.laneId,
        accountId: linkedLoadLane.accountId,
        customerLaneId: linkedLoadLane.customerLaneId,
        approxDistance: linkedLoadLane.approxDistance,
        approxDistanceUnit: linkedLoadLane.approxDistanceUnit,
        approxTransitTime: linkedLoadLane.approxTransitTime,
        approxTransitTimeUnit: linkedLoadLane.approxTransitTimeUnit,
        equipmentSpecifics: linkedLoadLane.equipmentSpecifics,
        driverRequirements: linkedLoadLane.driverRequirements,
        equipmentNeeds: linkedLoadLane.equipmentNeeds,
        locations: linkedLoadLane.locations,
        links: linkedLoadLane.links,
        isDeleted: linkedLoadLane.isDeleted,
        createdBy: linkedLoadLane.createdBy,
        createdAt: linkedLoadLane.createdAt,
        modifiedBy: linkedLoadLane.modifiedBy,
        modifiedAt: linkedLoadLane.modifiedAt
    };

    // Get Account Info
    linkedLoadLaneObj.account = EntityUtils.getAccountInfo(linkedLoadLane.accountId, accounts);

    // Get TrailerType Info
    if (isObjectNotEmpty(linkedLoadLane.equipmentNeeds) && isStringNotEmpty(linkedLoadLane.equipmentNeeds.trailerTypeId)) {
        linkedLoadLaneObj.equipmentNeeds.trailerType = EntityUtils.getTrailerTypeInfo(linkedLoadLane.equipmentNeeds.trailerTypeId, trailerTypes);
    }

    // Get Location Info
    if (isListNotEmpty(linkedLoadLane.locations)) {
        let linkedLoadLaneLocations = linkedLoadLane.locations.map((linkedLoadLaneLocation) => {
            let location = EntityUtils.getLocationInfo(linkedLoadLaneLocation.locationId, locations);

            return {
                ...linkedLoadLaneLocation,
                location: location
            };
        });

        linkedLoadLaneObj.locations = linkedLoadLaneLocations;
    }

    if (isStringNotEmpty(accountId) && isStringNotEmpty(linkedAccountId)) {
        if (isListNotEmpty(linkedLoadLane.links)) {
            let link = linkedLoadLane.links.find(l => l.accountId === accountId && l.linkedAccountId === linkedAccountId);
            if (isObjectNotEmpty(link)) {
                linkedLoadLaneObj.accountId = link.accountId;
                linkedLoadLaneObj.linkedAccountId = link.linkedAccountId;
                linkedLoadLaneObj.customerLaneId = link.customerLaneId;
                linkedLoadLaneObj.perMileRate = link.perMileRate;
                linkedLoadLaneObj.perMileRateUnit = link.perMileRateUnit;
                linkedLoadLaneObj.contractRate = link.contractRate;
                linkedLoadLaneObj.contractRateUnit = link.contractRateUnit;
                linkedLoadLaneObj.equipmentSpecifics = link.equipmentSpecifics;
                linkedLoadLaneObj.driverRequirements = link.driverRequirements;
                linkedLoadLaneObj.equipmentNeeds = link.equipmentNeeds;
                linkedLoadLaneObj.locations = link.locations;

                // Get Account Info
                linkedLoadLaneObj.account = EntityUtils.getAccountInfo(link.accountId, accounts);

                // Get LinkedAccount Info
                linkedLoadLaneObj.linkedAccount = EntityUtils.getAccountInfo(link.linkedAccountId, accounts);

                // Get TrailerType Info
                if (isObjectNotEmpty(link.equipmentNeeds) && isStringNotEmpty(link.equipmentNeeds.trailerTypeId)) {
                    linkedLoadLaneObj.equipmentNeeds.trailerType = EntityUtils.getTrailerTypeInfo(link.equipmentNeeds.trailerTypeId, trailerTypes);
                }

                // Get Location Info
                if (isListNotEmpty(link.locations)) {
                    let linkedLoadLaneLocations = link.locations.map((linkedLoadLaneLocation) => {
                        let location = EntityUtils.getLocationInfo(linkedLoadLaneLocation.locationId, locations);

                        return {
                            ...linkedLoadLaneLocation,
                            location: location
                        };
                    });

                    linkedLoadLaneObj.locations = linkedLoadLaneLocations;
                }
            }
        }
    }

    return linkedLoadLaneObj;
};