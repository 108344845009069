import React, { useState, useMemo } from 'react';
import { Button, Drawer, Empty, message, Select, Spin } from "antd";
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import InvoiceUtils from '../../api/utils/invoiceUtils';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import moment from 'moment';
import * as Data from '../../api/data';
import { isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { selectListRecords } from '../../store/utility';
import FormItem from '../FormItem/FormItem';
import NewEmployee from '../NewEmployee/NewEmployee';
import { CanSee } from '../../shared/entitlements/entitlements';

const { Option } = Select;

const LoadNewPriceConfirmation = ({ load, loadId, shipperId, stops, invoices, invoiceLineItems, cancel, loading = false }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    //#endregion
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const userId = useSelector(state => state.auth.userId);
    const employees = useSelector(state => selectListRecords(state.orchestrator.employees, shipperId));
    const accounts = useSelector(state => state.orchestrator.accounts);

    //#endregion
    //#region useStates

    const [invoice, setInvoice] = useState({});
    const [showNewEmployee, setShowNewEmployee] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [shipperAccount, setShipperAccount] = useState(null);

    //#endregion
    //#region toggles

    const toggleNewEmployee = () => {
        setShowNewEmployee(!showNewEmployee);
    };

    //#endregion
    //#region onSubmit and onCancel

    const createNewInvoice = async (invoice) => {
        let newInvoice = null;
        if (isObjectNotEmpty(invoice)) {
            newInvoice = await Data.addInvoice(invoice);
            if (isObjectNotEmpty(newInvoice)) {
                if (isStringNotEmpty(newInvoice.fromEntityId)) {
                    dispatch(actionCreators.addInvoiceToInvoiceList(newInvoice.fromEntityId, newInvoice));
                }
                if (isStringNotEmpty(newInvoice.toEntityId)) {
                    dispatch(actionCreators.addInvoiceToInvoiceList(newInvoice.toEntityId, newInvoice));
                }
                if (isStringNotEmpty(newInvoice.loadId)) {
                    dispatch(actionCreators.addInvoiceToInvoiceList(newInvoice.loadId, newInvoice));
                }
            }
        }

        return newInvoice;
    };

    const onSubmit = async (data) => {
        if (isObjectNotEmpty(invoice) && isListNotEmpty(employees) && isListNotEmpty(data.accountUserIds) && isObjectNotEmpty(load) && isStringNotEmpty(loadId)) {
            setIsLoading(true);
            let invoiceToSend = null;
            // first check to see if price confirmation already exists and if so, delete it or update it
            if (isListNotEmpty(invoices)) {
                let existingInvoice = invoices.find(i => i.invoiceType === 'PRICE_CONFIRMATION' && i.loadId === loadId && i.isDeleted === false);
                if (isObjectNotEmpty(existingInvoice)) {
                    dispatch(actionCreators.updateInvoice(existingInvoice.id, { isDeleted: true }, existingInvoice));

                    // second, create the price confirmation in the database if an existing one couldnt be updated
                    invoiceToSend = await createNewInvoice(invoice);
                } else {
                    // second, create the price confirmation in the database if an existing one couldnt be updated
                    invoiceToSend = await createNewInvoice(invoice);
                }
            } else {
                // second, create the price confirmation in the database if an existing one couldnt be updated
                invoiceToSend = await createNewInvoice(invoice);
            }

            if (isObjectNotEmpty(invoiceToSend)) {
                // third, save the pdf document to s3
                let newDocument = await InvoiceUtils.saveInvoiceAsDocument('PRICE_CONFIRMATION', invoiceToSend, invoiceToSend.invoiceLineItems, load, stops, true);

                // fourth, send price confirmation emails to recipients
                let emailsWereSent = false;
                let toEmails = [];
                for (let i = 0; i < data.accountUserIds.length; i++) {
                    const accountUserId = data.accountUserIds[i];
                    const accountUser = employees.find(a => a.id === accountUserId);
                    if (isObjectNotEmpty(accountUser)) {
                        toEmails.push({ email: accountUser.email, name: accountUser.firstName + ' ' + accountUser.lastName });
                    }
                }

                emailsWereSent = await InvoiceUtils.sendPriceConfirmationEmail('info@irisfreight.com', toEmails, invoiceToSend, load, stops, newDocument);
                if (emailsWereSent === true) {
                    message.success('Email(s) have been sent.');
                    dispatch(actionCreators.sendLoadEvent({
                        loadId: load.id,
                        eventType: 'LOAD_UPDATED',
                        changeType: 'LOAD_UPDATED'
                    }));
                    onCancel();
                } else {
                    message.error('Failed to send email(s)');
                }
            } else {
                message.error('Failed to create load tender');
            }

            setIsLoading(false);
        }
    };

    const onCancel = () => {
        cancel();
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        dispatch(actionCreators.getAccounts());
    }, []);

    useMemo(() => {
        if (isStringNotEmpty(shipperId)) {
            dispatch(actionCreators.getEmployees(shipperId, false));
        }
    }, [shipperId]);

    useMemo(() => {
        if (isStringNotEmpty(shipperId) && isListNotEmpty(accounts)) {
            let shipperAccountObj = accounts.find(i => i.id === load.shipperId);
            if (isObjectNotEmpty(shipperAccountObj)) {
                // console.log(shipperAccount);
                setShipperAccount({ ...shipperAccountObj });
            }
        }
    }, [accounts, shipperId]);

    useMemo(() => {
        if (isStringNotEmpty(loadId) && isStringNotEmpty(shipperId) && isStringNotEmpty(userId) && isObjectNotEmpty(shipperAccount)) {
            const { totalAmount, lineItems, invoiceLineItemList } = InvoiceUtils.getInvoiceLineItemsAndTotal("PRICE_CONFIRMATION", invoiceLineItems, "SHIPPER", "STAFF");

            let priceConfirmation = {
                loadId: loadId,
                invoiceDate: moment(),
                fromEntityType: 'SHIPPER',
                fromEntityId: shipperId,
                fromName: shipperAccount.name,
                fromAddress: shipperAccount.accountsPayableAddress,
                fromPointOfContact: shipperAccount.accountsPayable,
                toEntityType: "STAFF",
                toEntityId: null,
                status: 'SENT',
                amountDue: totalAmount,
                amountDueUnit: 'USD',
                invoiceLineItemIds: invoiceLineItemList.map(i => i.id),
                sentAt: moment(),
                sentBy: userId,
                invoiceType: 'PRICE_CONFIRMATION'
            };

            setInvoice(priceConfirmation);
        }
    }, [loadId, shipperId, invoiceLineItems, userId, invoices, shipperAccount]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    //#endregion

    if (isObjectNotEmpty(invoice)) {
        return (
            <CanSee entityAction="CREATE" entityModel="LOAD_PRICE_CONFIRMATION" entityObject={load}>
                <FormProvider {...methods}>
                    <Form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true || loading === true}>
                            <Fieldset legend="New Load Tender">
                                <PDFDownloadLink document={<InvoiceUtils.PriceConfirmationTemplate invoice={invoice} load={load} stops={stops} invoiceLineItems={invoiceLineItems} displayManualInstructions={true} />} fileName={`Load Tender for Load ${load.irisId}.pdf`}>
                                    {({ blob, url, loading, error }) => (loading ? (<div style={{ textAlign: 'center' }}>Loading Load Tender...</div>) : (
                                        <>
                                            <PDFViewer style={{ width: '100%', height: '80vh' }} src={url}></PDFViewer>
                                            <div style={{ textAlign: 'center', marginTop: 12 }}>
                                                <Button style={{ height: 30, width: 300 }} type="default">Download Copy of Load Tender</Button>
                                            </div>
                                        </>
                                    ))}
                                </PDFDownloadLink>
                            </Fieldset>
                            <Fieldset legend="Would you like the send the load tender to the shipper you have assigned to this load?">
                                <FormItem {...formItemLayout} label="Choose Employee(s) to send Load Tender to" required
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Select
                                            placeholder="Please Search and Select Employee(s)"
                                            mode="multiple"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChange(selected); }}
                                            value={value}
                                            name={name}
                                            showSearch={true}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Employees for you to choose from." />}
                                            ref={ref}
                                        >
                                            {employees.map(d => <Option value={d.id} key={d.id}>{d.firstName + ' ' + d.lastName} - Title: {d.title} - Email: {d.email}</Option>)}
                                        </Select>
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="accountUserIds"
                                />
                                <br />
                                <b>Employee Doesn't Exist Yet?</b>
                                <br />
                                <Button type='primary' onClick={toggleNewEmployee}>Add New Employee to Shipper Account</Button>
                            </Fieldset>
                        </Spin>
                        <FormButtons cancel={onCancel} disabled={isLoading === true} submitText="Generate and Send the Load Tender" />
                    </Form>
                </FormProvider>
                <Drawer
                    title={'Add New Employee'}
                    width={fullWidth > 720 ? fullWidth / 2 : 360}
                    onClose={toggleNewEmployee}
                    visible={showNewEmployee}
                    bodyStyle={{ paddingBottom: 80 }}
                    style={{ zIndex: 1000 }}
                    destroyOnClose={true}
                    closable={false}
                    maskClosable={false}
                >
                    <NewEmployee cancel={toggleNewEmployee} accountId={shipperId} />
                </Drawer>
            </CanSee>
        );
    } else {
        return null;
    }
};

export default LoadNewPriceConfirmation;