import React, { useState } from 'react';
import { InputNumber, Modal } from "antd";
import { useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import { isStringNotEmpty } from '../../shared/objectUtils';

const NewLoadModal = ({ showModal = false, toggleModal, shipperId = null, loadLaneId = null, linkedLoadLaneId = null, duplicateLoadId = null, ...props }) => {
    //#region useSelectors

    const entityType = useSelector(state => state.auth.entityType);

    //#endregion
    //#region useStates

    const [bulkCount, setBulkCount] = useState(1);

    //#endregion
    //#region goTos

    const goToNewLoad = () => {
        props.history.push({
            pathname: "/newLoad",
            state: {
                bulkCount: bulkCount,
                shipperId: shipperId ? shipperId : null,
                loadLaneId: loadLaneId ? loadLaneId : null,
                linkedLoadLaneId: linkedLoadLaneId ? linkedLoadLaneId : null,
                duplicateLoadId: duplicateLoadId ? duplicateLoadId : null,
            }
        });

        toggleModal();
    };

    //#endregion
    //#region onChanges

    const onChangeLoadCount = (count) => {
        setBulkCount(count);
    };

    //#endregion

    return (
        <Modal
            title={isStringNotEmpty(duplicateLoadId) ? "Choose how many Duplicated Loads you want to Create" : "Choose how many Loads you want to Create"}
            visible={showModal === true}
            onOk={goToNewLoad}
            onCancel={toggleModal}
            maskClosable={false}
        >
            <InputNumber
                value={bulkCount}
                min={1}
                step={1}
                max={entityType === 'CARRIER' ? 100 : 1}
                precision={0}
                style={{ width: '100%' }}
                onChange={onChangeLoadCount}
            />
            <div><span><i>A value greater than 1 will create a bundle of identical loads.</i></span></div>
        </Modal>
    );
};

export default withRouter(NewLoadModal);
