export const RefreshActualPathControl = (controlDiv, map, refresh) => {

    // Set CSS for the control border.
    const controlUI = document.createElement("div");
    controlUI.style.backgroundColor = '#FFFFFF';
    controlUI.style.border = '1px solid #fff';
    controlUI.style.borderRadius = '3px';
    controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    controlUI.style.boxSizing = "border-box";
    controlUI.style.cursor = "pointer";
    controlUI.style.marginTop = "8px";
    controlUI.style.marginBottom = "22px";
    controlUI.style.marginRight = "8px";
    controlUI.style.textAlign = "center";
    controlUI.style.width = "36px";
    controlUI.style.height = "36px";
    controlUI.title = "Click to Refresh Actual Route";
    controlDiv.appendChild(controlUI);

    // Set CSS for the control interior.
    const controlText = document.createElement("div");

    controlText.style.color = "rgba(0, 0, 0, 0.6)";
    //controlText.style.fontFamily = "Open Sans";
    controlText.style.fontWeight = "1000";
    controlText.style.fontSize = "28px";
    controlText.style.paddingLeft = "4px";
    controlText.style.paddingRight = "4px";
    controlText.innerHTML = "<b>&#8635;</b>";
    controlText.title = "Refresh Actual Route";
    controlUI.appendChild(controlText);

    // Setup the click event listeners: simply set the map to Chicago.
    controlUI.addEventListener("click", () => {
        if (map !== null) {
            refresh();
        }
    });
};