import * as actionTypes from "../actions/actionTypes";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { isBooleanTrue, isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import ErrorUtils from "../../shared/errorUtils";
import * as actionCreators from "./index";

//#region Fetch Asset List Methods

export const fetchAssetListStart = (listName) => {
    return {
        type: actionTypes.FETCH_ASSET_LIST_START,
        payload: { listName: listName }
    }
};

export const fetchAssetListSuccess = (listName, payload) => {
    return {
        type: actionTypes.FETCH_ASSET_LIST_SUCCESS,
        payload: { listName: listName, ...payload }
    }
};

export const fetchAssetListFail = (listName, payload) => {
    return {
        type: actionTypes.FETCH_ASSET_LIST_FAIL,
        payload: { listName: listName, ...payload }
    }
};

export const clearAssetList = (listName) => {
    return {
        type: actionTypes.CLEAR_ASSET_LIST,
        payload: { listName: listName }
    }
};

export const clearAssetLists = () => {
    return {
        type: actionTypes.CLEAR_ASSET_LISTS
    }
};

export const addAssetToAssetList = (listName, payload) => {
    return {
        type: actionTypes.ADD_TO_ASSET_LIST,
        payload: { listName: listName, newRecord: payload }
    }
};

export const updateAssetInAssetList = (listName, payload) => {
    return {
        type: actionTypes.UPDATE_IN_ASSET_LIST,
        payload: { listName: listName, updatedRecord: payload }
    }
};

export const removeAssetFromAssetList = (listName, payload) => {
    return {
        type: actionTypes.REMOVE_FROM_ASSET_LIST,
        payload: { listName: listName, recordToRemove: payload }
    }
};

//#endregion
//#region Fetch Asset Methods

export const fetchAssetStart = () => {
    return {
        type: actionTypes.FETCH_ASSET_START
    }
};

export const fetchAssetSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_ASSET_SUCCESS,
        payload: payload
    }
};

export const fetchAssetFail = (payload) => {
    return {
        type: actionTypes.FETCH_ASSET_FAIL,
        payload: payload
    }
};

export const clearAsset = () => {
    return {
        type: actionTypes.CLEAR_ASSET
    }
};

//#endregion
//#region Add Asset Methods

export const addAssetStart = () => {
    return {
        type: actionTypes.ADD_ASSET_START
    }
};

export const addAssetSuccess = () => {
    return {
        type: actionTypes.ADD_ASSET_SUCCESS
    }
};

export const addAssetFail = (payload) => {
    return {
        type: actionTypes.ADD_ASSET_FAIL,
        payload: payload
    }
};

export const addAssetLoadingClear = () => {
    return {
        type: actionTypes.ADD_ASSET_LOADING_CLEAR
    }
};

export const addAssetErrorClear = () => {
    return {
        type: actionTypes.ADD_ASSET_ERROR_CLEAR
    }
};

export const addAssetCancel = () => {
    return {
        type: actionTypes.ADD_ASSET_CANCEL
    }
};

//#endregion
//#region Update Asset Methods

const changeSingleAsset = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_ASSET,
        payload: payload
    }
};

export const updateAssetStart = () => {
    return {
        type: actionTypes.UPDATE_ASSET_START
    }
};

export const updateAssetSuccess = () => {
    return {
        type: actionTypes.UPDATE_ASSET_SUCCESS
    }
};

export const updateAssetFail = (payload) => {
    return {
        type: actionTypes.UPDATE_ASSET_FAIL,
        payload: payload
    }
};

export const updateAssetErrorClear = () => {
    return {
        type: actionTypes.UPDATE_ASSET_ERROR_CLEAR
    }
};

export const updateAssetLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_ASSET_LOADING_CLEAR
    }
};

export const updateAssetCancel = () => {
    return {
        type: actionTypes.UPDATE_ASSET_CANCEL
    }
};

//#endregion
//#region Assets Methods

export const fetchAssetList = (listName, payload) => {
    return async (dispatch, getState) => {
        if (isStringNotEmpty(listName)) {
            try {
                dispatch(fetchAssetListStart(listName));

                await dispatch(actionCreators.getAccounts());
                await dispatch(actionCreators.getTrailerTypes());
                const state = getState();
                const orchestratorState = { ...state.orchestrator };
                const trailerTypes = [...orchestratorState.trailerTypes];
                const accounts = [...orchestratorState.accounts];
                const assetsState = { ...state.assets };
                const existingLists = { ...assetsState.lists };

                let pagination = {};
                let searchParams = {};
                if (isObjectNotEmpty(existingLists[listName])) {
                    pagination = { ...existingLists[listName].pagination };
                    searchParams = { ...existingLists[listName].searchParams };
                }

                if (isObjectNotEmpty(payload)) {
                    searchParams = { ...payload };
                }

                const res = await Data.getAssets({ ...searchParams }, pagination, accounts, trailerTypes);
                dispatch(fetchAssetListSuccess(listName, { records: res.data, params: { searchParams: res.searchParams, pagination: res.pagination } }));
            } catch (error) {
                logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
                dispatch(fetchAssetListFail(listName, { error: ErrorUtils.getErrorMessage(error) }));
            }
        }
    }
};

export const fetchAsset = (id) => {
    return async (dispatch, getState) => {
        try {
            dispatch(fetchAssetStart());

            await dispatch(actionCreators.getAccounts());
            await dispatch(actionCreators.getTrailerTypes());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const trailerTypes = [...orchestratorState.trailerTypes];
            const accounts = [...orchestratorState.accounts];

            const asset = await Data.getAsset(id, accounts, trailerTypes);
            dispatch(fetchAssetSuccess({ record: asset }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchAssetFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const addAsset = (payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(addAssetStart());

            await dispatch(actionCreators.getAccounts());
            await dispatch(actionCreators.getTrailerTypes());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const trailerTypes = [...orchestratorState.trailerTypes];
            const accounts = [...orchestratorState.accounts];

            const newAsset = await Data.addAsset(payload, accounts, trailerTypes);
            if (isObjectNotEmpty(newAsset)) {
                dispatch(addAssetToAssetList(newAsset.carrierId, newAsset));

                // refresh assets orchestrator
                await dispatch(actionCreators.getAssets(newAsset.carrierId, true));
            }

            dispatch(addAssetSuccess());
            dispatch(addAssetLoadingClear());
            dispatch(addAssetErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addAssetFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const addAssetAndAddToLoad = (payload, carrierId, load) => {
    return async (dispatch, getState) => {
        try {
            dispatch(addAssetStart());

            await dispatch(actionCreators.getAccounts());
            await dispatch(actionCreators.getTrailerTypes());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const trailerTypes = [...orchestratorState.trailerTypes];
            const accounts = [...orchestratorState.accounts];

            const newAsset = await Data.addAssetAndAddToLoad(payload, carrierId, load, accounts, trailerTypes);
            if (isObjectNotEmpty(newAsset)) {
                dispatch(addAssetToAssetList(newAsset.carrierId, newAsset));

                // refresh assets orchestrator
                await dispatch(actionCreators.getAssets(newAsset.carrierId, true));
            }

            dispatch(addAssetSuccess());
            dispatch(addAssetLoadingClear());
            dispatch(addAssetErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addAssetFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateAsset = (id, payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updateAssetStart());

            if (isBooleanTrue(payload.isDeleted)) {
                const removedAsset = await Data.removeAsset(id);
                if (isObjectNotEmpty(removedAsset)) {
                    dispatch(removeAssetFromAssetList(removedAsset.carrierId, removedAsset));

                    // refresh assets orchestrator
                    await dispatch(actionCreators.getAssets(removedAsset.carrierId, true));
                }
            } else {
                await dispatch(actionCreators.getAccounts());
                await dispatch(actionCreators.getTrailerTypes());
                const state = getState();
                const orchestratorState = { ...state.orchestrator };
                const trailerTypes = [...orchestratorState.trailerTypes];
                const accounts = [...orchestratorState.accounts];

                const updatedAsset = await Data.updateAsset(id, payload, accounts, trailerTypes);
                if (isObjectNotEmpty(updatedAsset)) {
                    dispatch(updateAssetInAssetList(updatedAsset.carrierId, updatedAsset));
                    dispatch(changeSingleAsset(updatedAsset));

                    // refresh assets orchestrator
                    await dispatch(actionCreators.getAssets(updatedAsset.carrierId, true));
                }
            }

            dispatch(updateAssetSuccess());
            dispatch(updateAssetLoadingClear());
            dispatch(updateAssetErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateAssetFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion