import { isObjectNotEmpty } from '../../objectUtils';
import Checks from '../entitlementUtils';

//#region accounts

export const canCreateAccount = (auth) => {
    return Checks.isAuthenticated(auth);
};

export const canReadAccount = (auth, account) => {
    return Checks.allowStaff(auth) || Checks.isAccountUser(auth, isObjectNotEmpty(account) ? account.id : null);
};

export const canReadAccountList = (auth) => {
    return Checks.allowStaff(auth);
};

export const canVerifyAccount = (auth) => {
    return Checks.allowStaff(auth);
};

export const canUpdateAccount = (auth, account) => {
    return Checks.allowStaff(auth) || Checks.isAccountOperationsAdmin(auth, isObjectNotEmpty(account) ? account.id : null);
};

export const canDeleteAccount = (auth) => {
    return Checks.allowStaffAdmin(auth);
};

//#endregion
//#region shippers

export const canReadShipper = (auth, account) => {
    return Checks.allowStaff(auth) || Checks.isAccountUser(auth, isObjectNotEmpty(account) ? account.id : null);
};

export const canReadShipperList = (auth) => {
    return Checks.allowStaff(auth);
};

//#endregion
//#region carriers

export const canReadCarrier = (auth, account) => {
    return Checks.allowStaff(auth) || Checks.isAccountUser(auth, isObjectNotEmpty(account) ? account.id : null);
};

export const canReadCarrierList = (auth) => {
    return Checks.allowStaff(auth);
};

//#endregion
//#region producers

export const canReadProducer = (auth, account) => {
    return Checks.allowStaff(auth) || Checks.isAccountUser(auth, isObjectNotEmpty(account) ? account.id : null);
};

export const canReadProducerList = (auth) => {
    return Checks.allowStaff(auth);
};

//#endregion
//#region receivers

export const canReadReceiver = (auth, account) => {
    return Checks.allowStaff(auth) || Checks.isAccountUser(auth, isObjectNotEmpty(account) ? account.id : null);
};

export const canReadReceiverList = (auth) => {
    return Checks.allowStaff(auth);
};

//#endregion
//#region brokers

export const canReadBroker = (auth, account) => {
    return Checks.allowStaff(auth) || Checks.isAccountUser(auth, isObjectNotEmpty(account) ? account.id : null);
};

export const canReadBrokerList = (auth) => {
    return Checks.allowStaff(auth);
};

//#endregion
//#region factorings

export const canReadFactoring = (auth, account) => {
    return Checks.allowStaff(auth) || Checks.isAccountUser(auth, isObjectNotEmpty(account) ? account.id : null);
};

export const canReadFactoringList = (auth) => {
    return Checks.allowStaff(auth);
};

//#endregion