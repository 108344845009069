import React, { useMemo, useState } from 'react';
import { Alert, Button, Drawer, Empty, Select, Spin } from "antd";
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import NewDriver from '../NewDriver/NewDriver';
import StringFormatter from '../../shared/stringFormatter';
import { driverAssignedEmailTemplate } from "../../shared/emailTemplates";
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import { sendLoadNotification } from '../../api/data';
import { isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { sendEmail } from '../../api/emailClient';
import logger from '../../shared/logger';
import { selectListIsLoading, selectListRecords } from '../../store/utility';

const { Option } = Select;
const stringFormatter = new StringFormatter();

const AssignLoadDriver = ({ load, carrierId, cancel }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    //#endregion
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loads.isRecordUpdateLoading);
    const error = useSelector(state => state.loads.updateRecordError);
    const isLoadingAccountUsers = useSelector(state => selectListIsLoading(state.accountUsers.lists, `${carrierId}_DRIVERS`));
    const accountUsers = useSelector(state => selectListRecords(state.accountUsers.lists, `${carrierId}_DRIVERS`));

    //#endregion
    //#region useStates

    const [showNewEntity, setShowNewEntity] = useState(false);

    //#endregion
    //#region toggles

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    //#endregion
    //#region onSubmit and onCancel

    const onSubmit = (data) => {
        if (isObjectNotEmpty(data) && isObjectNotEmpty(load)) {
            // check to see if it is a duplicate and if it is found in the driverIds array already, throw an error
            if (isListNotEmpty(load.driverIds)) {
                if (load.driverIds.find(i => i === data.accountUserId) !== undefined) {
                    methods.setError("accountUserId", { type: "validate", message: "The driver you chose is already assigned to this load." });
                    return;
                } else {
                    methods.clearErrors("accountUserId");
                }
            }

            // console.log(data);
            let updatedDriverIds = isListNotEmpty(load.driverIds) ? [...load.driverIds, data.accountUserId] : [data.accountUserId];
            // console.log(updatedDriverIds);

            // removes duplicates
            const updatedDriverIds2 = [...new Set(updatedDriverIds)];
            // console.log(updatedDriverIds2);
            let payload = {};
            payload.driverIds = updatedDriverIds2;
            // console.log(payload);
            dispatch(actionCreators.updateLoad(load.id, payload));
            sendNotificationToDriver(data.accountUserId);
            sendEmailToDriver(data.accountUserId);
        } else {
            onCancel();
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.updateLoadCancel());
        cancel();
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        if (isStringNotEmpty(carrierId)) {
            dispatch(actionCreators.fetchAccountUserList(`${carrierId}_DRIVERS`, { page: 1, size: 1000000, isDriver: true, accountId: carrierId, isDeleted: false }));
        }
    }, [carrierId]);

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLoadErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    //#endregion
    //#region email and notification methods

    const sendEmailToDriver = (accountUserId) => {
        if (isListNotEmpty(accountUsers)) {
            let accountUser = accountUsers.find(d => d.id === accountUserId);
            if (isObjectNotEmpty(accountUser) && isObjectNotEmpty(accountUser.driver)) {
                if (accountUser.driver.isApproved === undefined || accountUser.driver.isApproved === null || accountUser.driver.isApproved === false) {
                    const { body, subject } = driverAssignedEmailTemplate(accountUser.firstName, load.irisId)
                    sendEmail(subject, body, accountUser.email).then(res => {
                        //console.log(res);
                    }).catch(err => {
                        logger.logDebugEvent('AssignLoadDriver.js', err.message, true);
                    });
                }
            }
        }
    };

    const sendNotificationToDriver = (accountUserId) => {
        if (isListNotEmpty(accountUsers)) {
            let accountUser = accountUsers.find(d => d.id === accountUserId);
            if (isObjectNotEmpty(accountUser) && isObjectNotEmpty(accountUser.driver)) {
                let newNotification = {
                    message: `Hi ${accountUser.firstName}, you have been assigned to a new load: ${load.irisId}. Please open up the IRIS Freight app for more details.`,
                    loadId: load.id,
                    loadIrisId: load.irisId,
                    saveOnly: false,
                    subject: `${load.irisId} You have been assigned to a new load`,
                    severity: "CRITICAL",
                    userIds: [accountUser.userId],
                    loadStatus: load.loadStatus,
                    eventType: 'DRIVER_ASSIGNED'
                };
                sendLoadNotification(newNotification).then(res => {
                    //console.log(res);
                }).catch(err => {
                    logger.logDebugEvent('AssignLoadDriver.js', err.message, true);
                });
            }
        }
    };

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    //#endregion

    return (
        <>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true && error === null) || isLoadingAccountUsers === true}>
                        <Fieldset legend="Choose a Driver to Assign to this Load">
                            <FormItem {...formItemLayout} label="Choose a Driver" required
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please Search and Select a Driver"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        loading={isLoadingAccountUsers === true}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        showSearch={true}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Drivers for you to choose from." />}
                                        ref={ref}
                                    >
                                        {accountUsers.map(d => <Option value={d.id} key={d.id}>{d.firstName + ' ' + d.lastName} - Currently Available: {stringFormatter.toYesNo(d.driver.isAvailable)}</Option>)}
                                    </Select>
                                )}
                                rules={{ required: 'Required Field' }}
                                name="accountUserId"
                            />
                            <br />
                            <b>Driver Doesn't Exist Yet?</b>
                            <br />
                            <Button type='primary' onClick={toggleNewEntity}>Add New Driver to Account</Button>
                        </Fieldset>
                        {error && <Alert message={`${error}`} type="error" />}
                    </Spin>
                    <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Assign Driver to Load" />
                </Form>
            </FormProvider>
            <Drawer
                title={'Add New Driver'}
                width={fullWidth > 720 ? fullWidth / 2 : 360}
                onClose={toggleNewEntity}
                visible={showNewEntity}
                bodyStyle={{ paddingBottom: 80 }}
                style={{ zIndex: 1000 }}
                destroyOnClose={true}
                closable={false}
                maskClosable={false}
            >
                <NewDriver cancel={toggleNewEntity} accountId={carrierId} />
            </Drawer>
        </>
    );
};

export default AssignLoadDriver;