import React, { useMemo } from 'react';
import { Alert, Spin, Input, Switch } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import { withRouter } from 'react-router';
import { isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { CanSee, CanDo } from '../../shared/entitlements/entitlements';

const { TextArea } = Input;

const EditLoadStatusRejectRateConfirmation = ({ load, cancel, ...props }) => {
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.carrierRejections.isRecordAddLoading);
    const error = useSelector(state => state.carrierRejections.addRecordError);
    const entityType = useSelector(state => state.auth.entityType);

    //#endregion
    //#region onSubmit, onCancel, and onDone

    const onSubmit = (data) => {
        if (isObjectNotEmpty(load) && isStringNotEmpty(load.assignedCarrierId)) {
            let payload = {};
            payload.loadId = load.id;
            payload.loadLaneId = load.loadLaneId;
            payload.rejectedReason = data.rejectedReason;
            payload.rejectedByCarrierId = load.assignedCarrierId;

            dispatch(actionCreators.addCarrierRejection(payload));
        } else {
            onCancel();
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.addCarrierRejectionCancel());
        cancel();
    };

    const onDone = () => {
        dispatch(actionCreators.addCarrierRejectionCancel());
        props.history.push({ pathname: "/loadRequests/" });
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.addCarrierRejectionErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            if (entityType === 'CARRIER') {
                onDone();
            } else {
                onCancel();
            }
        }
    }, [isLoading, error, entityType]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    //#endregion

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Fieldset legend="Reject Rate Confirmation">
                        <CanSee staffOnly={true}>
                            <b>The Carrier rejected the Rate Confirmation? This will remove the Carrier (and drivers and assets) from the Load and change the Status of the Load back to Approved.</b>
                        </CanSee>
                        <CanSee entityTypes={['CARRIER']}>
                            <b>Would you like to reject this Rate Confirmation? This will remove the load request from your queue.</b>
                        </CanSee>
                        <FormItem {...formItemLayout} label="Reason for Rejection" required format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => <TextArea onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} autoSize={{ minRows: 4 }} ref={ref} />}
                            rules={{ required: 'Required Field' }}
                            name="rejectedReason"
                        />
                        <FormItem {...formItemLayout} label={`I Reject the Rate Confirmation${CanDo({ staffOnly: true }) ? ' on behalf of the Carrier' : ''}`} required format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    onBlur={onBlur}
                                    onChange={(checked, event) => { onChange(checked); }}
                                    checked={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            rules={{
                                validate: {
                                    mustBeTrue: checked => checked === true || 'Rate Confirmation must be Rejected' // value must be true
                                }
                            }}
                            name="rejectedRateConfirmation"
                            defaultValue={false}
                        />
                    </Fieldset>
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Reject Rate Confirmation" />
            </Form>
        </FormProvider>
    );
};

export default withRouter(EditLoadStatusRejectRateConfirmation);