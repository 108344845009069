import React, { useMemo, useState } from 'react';
import { Alert, Spin, Select, Empty } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import moment from 'moment';
import { isListNotEmpty, isNotNullOrUndefined, isNullOrUndefined, isObjectNotEmpty, isStringEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import EntityUtils from '../../api/entityUtils';

const { Option } = Select;

const NewScheduledShift = ({ accountId, selectedDate, selectedAccountUserId, selectedShiftId, accountUsers, shifts, cancel }) => {
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.scheduledShifts.isRecordAddLoading);
    const error = useSelector(state => state.scheduledShifts.addRecordError);

    //#endregion
    //#region useStates

    const [selectedShift, setSelectedShift] = useState(null);
    const [selectedAccountUser, setSelectedAccountUser] = useState(null);

    //#endregion
    //#region onSubmit and cancel

    const onSubmit = (data) => {
        if (isStringNotEmpty(accountId)) {
            data.accountId = accountId;

            if (isStringNotEmpty(selectedAccountUserId)) {
                let accountUserIds = [];
                accountUserIds.push(selectedAccountUserId);
                data.accountUserIds = accountUserIds;
            }

            if (isStringNotEmpty(selectedShiftId)) {
                data.shiftId = selectedShiftId;
            }

            if (isNotNullOrUndefined(selectedDate)) {
                if (isStringNotEmpty(data.shiftId) && isListNotEmpty(shifts)) {
                    let selectedShift = shifts.find(i => i.id === data.shiftId);
                    if (isObjectNotEmpty(selectedShift)) {
                        // console.log(selectedShift);

                        data.isAvailable = selectedShift.isAvailable;

                        var formattedSelectedDate = moment(selectedDate).format("YYYY-MM-DD");

                        let startDate = `${formattedSelectedDate}T${selectedShift.startTime}`;
                        data.startDateTime = moment.tz(startDate, selectedShift.timeZone);

                        if (selectedShift.isNextDay === false) {
                            let endDate = `${formattedSelectedDate}T${selectedShift.endTime}`;
                            data.endDateTime = moment.tz(endDate, selectedShift.timeZone);
                        } else {
                            let nextDaySelectedDate = moment(selectedDate).add(1, 'days').format('YYYY-MM-DD');
                            let endDate = `${nextDaySelectedDate}T${selectedShift.endTime}`;
                            data.endDateTime = moment.tz(endDate, selectedShift.timeZone);
                        }

                        dispatch(actionCreators.addScheduledShift(data));
                    }
                }
            }
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.addScheduledShiftCancel());
        cancel();
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        let selectedAccountUserObj = EntityUtils.getAccountInfo(selectedAccountUserId, accountUsers);
        if (isObjectNotEmpty(selectedAccountUserObj)) {
            setSelectedAccountUser({ ...selectedAccountUserObj });
        } else {
            setSelectedAccountUser(null);
        }
    }, [selectedAccountUserId, accountUsers]);

    useMemo(() => {
        if (isStringNotEmpty(selectedShiftId) && isListNotEmpty(shifts)) {
            let selectedShiftObj = shifts.find(i => i.id === selectedShiftId);
            if (isObjectNotEmpty(selectedShiftObj)) {
                setSelectedShift({ ...selectedShiftObj });
            } else {
                setSelectedShift(null);
            }
        }
    }, [selectedShiftId, shifts]);

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.addScheduledShiftErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    //#endregion

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Fieldset legend="Selected Date">
                        <span><strong>{selectedDate}</strong></span>
                    </Fieldset>
                    {isStringEmpty(selectedShiftId) ? (
                        <Fieldset legend="Selected Shift">
                            <FormItem {...formItemLayout} label="Shift" required format="vertical"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please Select a Shift"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Shifts for you to choose from." />}
                                        ref={ref}
                                    >
                                        {shifts.map(a => <Option value={a.id} key={a.id}>{a.name}: {a.startTime} - {a.endTime} {a.timeZone}</Option>)}
                                    </Select>
                                )}
                                rules={{ required: 'Required Field' }}
                                name="shiftId"
                            />
                        </Fieldset>
                    ) : (
                            <Fieldset legend="Selected Shift">
                                <span><strong>{isObjectNotEmpty(selectedShift) && isStringNotEmpty(selectedShift.name) ? selectedShift.name : ''}: {isObjectNotEmpty(selectedShift) && isNotNullOrUndefined(selectedShift.startTime) ? selectedShift.startTime : ''} - {isObjectNotEmpty(selectedShift) && isNotNullOrUndefined(selectedShift.endTime) ? selectedShift.endTime : ''}</strong></span>
                            </Fieldset>
                        )}
                    {isStringEmpty(selectedAccountUserId) ? (
                        <Fieldset legend="Selected Employee(s)">
                            <FormItem {...formItemLayout} label="Please Select the Employee(s) for this Shift" required format="vertical"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        mode="multiple"
                                        placeholder="Please Select the Employee(s) for this Shift"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Employees for you to choose from." />}
                                        ref={ref}
                                    >
                                        {accountUsers.map(a => <Option value={a.id} key={a.id}>{a.firstName} {a.lastName}{a.title ? ' (' + a.title + ')' : ''}</Option>)}
                                    </Select>
                                )}
                                rules={{ required: 'Required Field' }}
                                name="accountUserIds"
                            />
                        </Fieldset>
                    ) : (
                            <Fieldset legend="Selected Employee">
                                <span><strong>{isObjectNotEmpty(selectedAccountUser) && isStringNotEmpty(selectedAccountUser.firstName) ? selectedAccountUser.firstName : ''} {selectedAccountUser && selectedAccountUser.lastName ? selectedAccountUser.lastName : ''}{selectedAccountUser && selectedAccountUser.title ? " (" + selectedAccountUser.title + ")" : ''}</strong></span>
                            </Fieldset>
                        )}
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText={isStringNotEmpty(selectedAccountUserId) ? "Add Employee to Scheduled Shift" : "Add Employee(s) to Scheduled Shift"} />
            </Form>
        </FormProvider>
    );
};

export default NewScheduledShift;