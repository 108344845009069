import React, { useState, useMemo, useEffect } from 'react';
import { Alert, Spin, Input, Select, InputNumber, Row, Col } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import { isListNotEmpty, isNumberNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import MapWrapper from '../Map/Map';
import { useDeepCompareEffect } from '../../shared/useDeepCompareEffect';
import * as Data from "../../api/data/index";
import { capitalize, startCase } from 'lodash';

const { Option } = Select;

const NewLane = ({ cancel }) => {
    //#region useForms

    const methods = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            originCity: null,
            originState: null,
            destinationCity: null,
            destinationState: null,
            trailerTypeId: null,
            rate: null
        }
    });

    //#endregion
    //#region useStates

    const [originCity, setOriginCity] = useState(null);
    const [originState, setOriginState] = useState(null);
    const [originLatitude, setOriginLatitude] = useState(null);
    const [originLongitude, setOriginLongitude] = useState(null);
    const [destinationCity, setDestinationCity] = useState(null);
    const [destinationState, setDestinationState] = useState(null);
    const [destinationLatitude, setDestinationLatitude] = useState(null);
    const [destinationLongitude, setDestinationLongitude] = useState(null);
    const [approxDistance, setApproxDistance] = useState(null);

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const entityType = useSelector(state => state.auth.entityType);
    const isLoading = useSelector(state => state.lanes.isRecordAddLoading);
    const error = useSelector(state => state.lanes.addRecordError);
    const states = useSelector(state => state.orchestrator.states);
    const trailerTypes = useSelector(state => state.orchestrator.trailerTypes);

    //#endregion
    //#region submit and cancel

    const onSubmit = (data) => {
        if (isObjectNotEmpty(data)) {
            if (isNumberNotEmpty(data.rate)) {
                data.rateUnit = 'USD';
            }
            if (isNumberNotEmpty(approxDistance)) {
                data.approxDistance = parseFloat(approxDistance);
                data.approxDistanceUnit = 'MI';
            }
            if (isNumberNotEmpty(originLatitude) && isNumberNotEmpty(originLongitude) && isNumberNotEmpty(destinationLatitude) && isNumberNotEmpty(destinationLongitude)) {
                data.originLatitude = parseFloat(originLatitude);
                data.originLongitude = parseFloat(originLongitude);
                data.destinationLatitude = parseFloat(destinationLatitude);
                data.destinationLongitude = parseFloat(destinationLongitude);
            }

            dispatch(actionCreators.addLane(data));
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.addLaneCancel());
        cancel();
    };

    //#endregion
    //#region useEffects and useMemos

    useEffect(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.addLaneErrorClear());

        methods.clearErrors();

        dispatch(actionCreators.getStates());
        dispatch(actionCreators.getTrailerTypes());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    //#endregion

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Fieldset legend="Origin and Destination">
                        <Row gutter={[24, 24]}>
                            <Col span={24}>
                                <MapWrapper
                                    height={'200px'}
                                    key={`lane-creation-map-key`}
                                    loadId={'new-lane'}
                                    origin={isStringNotEmpty(originCity) && isStringNotEmpty(originState) ? `${originCity}, ${originState}, USA` : null}
                                    destination={isStringNotEmpty(destinationCity) && isStringNotEmpty(destinationState) ? `${destinationCity}, ${destinationState}, USA` : null}
                                    containerName={`lane-creation-map`}
                                    style={{ width: "100%", height: "200px" }}
                                    setRouteDistance={setApproxDistance}
                                    setOriginLatitude={setOriginLatitude}
                                    setOriginLongitude={setOriginLongitude}
                                    setDestinationLatitude={setDestinationLatitude}
                                    setDestinationLongitude={setDestinationLongitude}
                                />
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Origin" required
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Input
                                            onBlur={onBlur}
                                            onChange={e => { setOriginCity(capitalize(e.target.value)); onChange(capitalize(e.target.value)); }}
                                            value={value}
                                            name={name}
                                            placeholder="City"
                                            ref={ref}
                                        />
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="originCity"
                                />
                                <FormItem {...formItemLayout} required
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Select
                                            placeholder="Please Select a State"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { setOriginState(selected); onChange(selected); }}
                                            value={value}
                                            name={name}
                                            showSearch={true}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            ref={ref}
                                        >
                                            {states.map(i => <Option value={i.stateAbbr} key={i.stateAbbr}>{i.stateName}</Option>)}
                                        </Select>
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="originState"
                                />
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Destination" required
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Input
                                            onBlur={onBlur}
                                            onChange={e => { setDestinationCity(capitalize(e.target.value)); onChange(capitalize(e.target.value)); }}
                                            value={value}
                                            name={name}
                                            placeholder="City"
                                            ref={ref}
                                        />
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="destinationCity"
                                />
                                <FormItem {...formItemLayout} required
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Select
                                            placeholder="Please Select a State"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { setDestinationState(selected); onChange(selected); }}
                                            value={value}
                                            name={name}
                                            showSearch={true}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            ref={ref}
                                        >
                                            {states.map(i => <Option value={i.stateAbbr} key={i.stateAbbr}>{i.stateName}</Option>)}
                                        </Select>
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="destinationState"
                                />
                            </Col>
                        </Row>
                    </Fieldset>
                    <Fieldset legend="Lane Details">
                        <FormItem {...formItemLayout} label="Trailer Type" required
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select a Trailer Type"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    ref={ref}
                                >
                                    {trailerTypes.map(i => <Option value={i.id} key={i.id}>{i.description}</Option>)}
                                </Select>
                            )}
                            rules={{ required: 'Required Field' }}
                            name='trailerTypeId'
                        />
                        {entityType === 'STAFF' ? (
                            <FormItem {...formItemLayout} label="Customer Rate"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <InputNumber
                                        min={0}
                                        precision={2}
                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        placeholder="Customer Rate"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    />
                                )}
                                rules={{ required: false }}
                                name="rate"
                            />
                        ) : null}
                    </Fieldset>
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Create Lane" />
            </Form>
        </FormProvider>
    );
};

export default NewLane;