import React, { useMemo, useState } from 'react';
import { Alert, Input, Spin, Switch, Select } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import { isBooleanTrue, isListNotEmpty, isObjectNotEmpty } from '../../shared/objectUtils';
import { CanSee } from '../../shared/entitlements/entitlements';
import Enums from '../../shared/enums';
import EntityUtils from '../../api/entityUtils';

const { OptGroup } = Select;

const EditEmployee = ({ cancel, employee }) => {
    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.accountUsers.isRecordUpdateLoading);
    const error = useSelector(state => state.accountUsers.updateRecordError);
    const accounts = useSelector(state => state.orchestrator.accounts);

    const shipperAccountRoles = Enums.ShipperAccountRoles.selectListOptions();
    const carrierAccountRoles = Enums.CarrierAccountRoles.selectListOptions();
    const producerAccountRoles = Enums.ProducerAccountRoles.selectListOptions();
    const receiverAccountRoles = Enums.ReceiverAccountRoles.selectListOptions();
    const brokerAccountRoles = Enums.BrokerAccountRoles.selectListOptions();
    const factoringAccountRoles = Enums.FactoringAccountRoles.selectListOptions();


    const [account, setAccount] = useState(null);

    const onSubmit = (data) => {
        let payload = {};
        let objectToUpdate = { ...employee };
        for (let [key, value] of Object.entries(data)) {
            let oldValue = objectToUpdate[key] !== undefined && objectToUpdate[key] !== null ? objectToUpdate[key] : '';
            if (value !== oldValue) {
                //console.log(`${key}: new value - ${value}, old value - ${oldValue}`);
                payload[key] = value;
            }
        }

        if (isObjectNotEmpty(payload)) {
            if (payload.isDriver === true && objectToUpdate.isDriver === false) {
                payload.driver = {
                    isAvailable: true
                };
                dispatch(actionCreators.updateAccountUser(employee.id, payload, true));
            } else {
                dispatch(actionCreators.updateAccountUser(employee.id, payload, false));
            }
        } else {
            onCancel();
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.updateAccountUserCancel());
        cancel();
    };

    useMemo(() => {
        if (isObjectNotEmpty(employee) && isListNotEmpty(accounts)) {
            const selectedAccount = EntityUtils.getAccountInfo(employee.accountId, accounts);
            setAccount(selectedAccount);
        }
    }, [employee, accounts]);

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateAccountUserErrorClear());
        dispatch(actionCreators.getAccounts());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Fieldset legend="Employee Details">
                        <FormItem {...formItemLayout} label="Title"
                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Title" ref={ref} />}
                            rules={{ required: false }}
                            name="title"
                            defaultValue={employee.title}
                        />
                        <FormItem {...formItemLayout} label="Work Phone #"
                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Work Phone #" ref={ref} />}
                            rules={{ required: false }}
                            name="workPhone"
                            defaultValue={employee.workPhone}
                        />
                        <FormItem {...formItemLayout} label="Is Contractor"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    onBlur={onBlur}
                                    onChange={(checked, event) => { onChange(checked); }}
                                    checked={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            name="isContractor"
                            defaultValue={employee.isContractor}
                        />
                    </Fieldset>
                    {isObjectNotEmpty(account) ? (
                        <CanSee accountId={account.id} accountRoles={["OPERATIONS_ADMIN", "ADMIN"]}>
                            <Fieldset legend="Account Role(s)">
                                {isBooleanTrue(account.entityTypes.includes("CARRIER")) ? (
                                    <FormItem {...formItemLayout} label="Is Driver"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Switch
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                onBlur={onBlur}
                                                onChange={(checked, event) => { onChange(checked); }}
                                                checked={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        name="isDriver"
                                        defaultValue={employee.isDriver}
                                    />
                                ) : null}
                                <FormItem {...formItemLayout} label="Account Role(s)" format="vertical" required
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Select
                                            placeholder="Please Select Account Role(s)"
                                            mode="multiple"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChange(selected); }}
                                            value={value}
                                            name={name}
                                            ref={ref}
                                        >
                                            {isBooleanTrue(account.entityTypes.includes("SHIPPER")) ? (
                                                <OptGroup label="Shipper Account Roles">
                                                    {shipperAccountRoles}
                                                </OptGroup>
                                            ) : null}
                                            {isBooleanTrue(account.entityTypes.includes("CARRIER")) ? (
                                                <OptGroup label="Carrier Account Roles">
                                                    {carrierAccountRoles}
                                                </OptGroup>
                                            ) : null}
                                            {isBooleanTrue(account.entityTypes.includes("PRODUCER")) ? (
                                                <OptGroup label="Producer Account Roles">
                                                    {producerAccountRoles}
                                                </OptGroup>
                                            ) : null}
                                            {isBooleanTrue(account.entityTypes.includes("RECEIVER")) ? (
                                                <OptGroup label="Receiver Account Roles">
                                                    {receiverAccountRoles}
                                                </OptGroup>
                                            ) : null}
                                            {isBooleanTrue(account.entityTypes.includes("BROKER")) ? (
                                                <OptGroup label="Brokerage Account Roles">
                                                    {brokerAccountRoles}
                                                </OptGroup>
                                            ) : null}
                                            {isBooleanTrue(account.entityTypes.includes("FACTORING")) ? (
                                                <OptGroup label="Factoring Company Account Roles">
                                                    {factoringAccountRoles}
                                                </OptGroup>
                                            ) : null}
                                        </Select>
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="accountRoles"
                                    defaultValue={employee.accountRoles}
                                />
                            </Fieldset>
                        </CanSee>
                    ) : null}
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Update Employee" />
            </Form>
        </FormProvider>
    );
};

export default EditEmployee;