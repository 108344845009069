import React, { useState, useRef, useMemo, useEffect, Fragment } from 'react';
import { Button, Card, Col, message, Row, Popconfirm, Collapse, Alert, Spin, Affix, Steps } from "antd";
import { withRouter } from "react-router";
import NewLoadSchedule from "../../components/NewLoadSchedule/NewLoadSchedule";
import NewLoadServices from "../../components/NewLoadServices/NewLoadServices";
import NewLoadStops from "../../components/NewLoadStops/NewLoadStops";
import NewLoadEquipment from "../../components/NewLoadEquipment/NewLoadEquipment";
import { divide, sortBy } from "lodash";
import { useForm, FormProvider } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import DataRow from '../../components/DataRow/DataRow';
import DataListRow from '../../components/DataListRow/DataListRow';
import DataCommodityListRow from '../../components/DataCommodityListRow/DataCommodityListRow';
import classes from './NewLoad.module.scss';
import MomentDate from '../../shared/dateFormatter';
import Form from '../../components/Form/Form';
import { isBooleanTrue, isListEmpty, isListNotEmpty, isNotNullOrUndefined, isNumberGreaterThanZero, isNumberNotEmpty, isObjectEmpty, isObjectNotEmpty, isStringEmpty, isStringNotEmpty, isMomentDate, removeEmpty } from '../../shared/objectUtils';
import { faPallet, faRoute, faTruck, faTruckLoading } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Header from '../../components/Header/Header';
import Enums from '../../shared/enums';
import StringFormatter from '../../shared/stringFormatter';
import logger from '../../shared/logger';
import NewLoadReview from '../../components/NewLoadReview/NewLoadReview';
import * as Data from '../../api/data';
import LoadStopUtils from '../../api/utils/loadStopUtils';
import { CanDo, CanSee } from '../../shared/entitlements/entitlements';
import InvoiceUtils from '../../api/utils/invoiceUtils';
import MapWrapper from '../../components/Map/Map';

const { Panel } = Collapse;
const stringFormatter = new StringFormatter();
const momentDate = new MomentDate();

const NewLoad = props => {
    //#region props

    const shipperIdValue = isObjectNotEmpty(props.location) && isObjectNotEmpty(props.location.state) && isStringNotEmpty(props.location.state.shipperId) ? props.location.state.shipperId : null;
    const loadLaneId = isObjectNotEmpty(props.location) && isObjectNotEmpty(props.location.state) && isStringNotEmpty(props.location.state.loadLaneId) ? props.location.state.loadLaneId : null;
    const linkedLoadLaneId = isObjectNotEmpty(props.location) && isObjectNotEmpty(props.location.state) && isStringNotEmpty(props.location.state.linkedLoadLaneId) ? props.location.state.linkedLoadLaneId : null;
    const duplicateLoadId = isObjectNotEmpty(props.location) && isObjectNotEmpty(props.location.state) && isStringNotEmpty(props.location.state.duplicateLoadId) ? props.location.state.duplicateLoadId : null;
    const bulkCount = isObjectNotEmpty(props.location) && isObjectNotEmpty(props.location.state) && isNumberNotEmpty(props.location.state.bulkCount) ? props.location.state.bulkCount : 1;

    //#endregion
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loads.isRecordAddLoading);
    const error = useSelector(state => state.loads.addRecordError);
    const addRecordId = useSelector(state => state.loads.addRecordId);
    const accounts = useSelector(state => state.orchestrator.accounts);
    const trailerTypes = useSelector(state => state.orchestrator.trailerTypes);
    const lineItemTypes = useSelector(state => state.orchestrator.lineItemTypes);
    const entityType = useSelector(state => state.auth.entityType);
    const entityId = useSelector(state => state.auth.entityId);
    const yourAccount = useSelector(state => state.yourAccounts.record);
    const services = useSelector(state => state.orchestrator.services);

    //#endregion
    //#region useStates

    const [completedSteps, setCompletedSteps] = useState({ schedule: false, stops: false, equipment: false, services: false });
    const [isStepsLoading, setIsStepsLoading] = useState(false);
    const [current, setCurrent] = useState('schedule');
    const [currentStepNumber, setCurrentStepNumber] = useState(0);
    const [shipperData, setShipperData] = useState({});
    const [equipmentData, setEquipmentData] = useState({});
    const [pickUpStops, setPickUpStops] = useState([]);
    const [dropOffStops, setDropOffStops] = useState([]);
    const [servicesData, setServicesData] = useState({});
    const [invoiceLineItems, setInvoiceLineItems] = useState([]);
    const [docFiles, setDocFiles] = useState([]);
    const [practicalRouteMiles, setPracticalRouteMiles] = useState(null);
    const [practicalRouteMinutes, setPracticalRouteMinutes] = useState(null);
    const [carrier, setCarrier] = useState(null);
    const [hideLoadSpecificInformation, setHideLoadSpecificInformation] = useState(null); // use null as 3rd/initial state so we know if the value has been set yet
    const [dontRequireLoadSpecificInformation, setDontRequireLoadSpecificInformation] = useState(null); // use null as 3rd/initial state so we know if the value has been set yet
    const [loadLane, setLoadLane] = useState({});

    //#endregion
    //#region fetch async methods

    const getLoadWithStops = async (loadId) => {
        let existingLoad = {};
        try {
            existingLoad = await Data.getLoadWithIncludes(loadId, false);
            const loadStops = await Data.getLoadStops(loadId, existingLoad);
            if (isListNotEmpty(loadStops)) {
                existingLoad.stops = loadStops;
            }
        } catch (err) {
            logger.logDebugEvent('NewLoad.js', err.message, true);
        }

        return existingLoad;
    };

    const getLoadLane = async (existingLoadLaneId, entityId = null) => {
        let existingLoadLane = null;
        try {
            existingLoadLane = await Data.getLoadLane(existingLoadLaneId, accounts, trailerTypes, entityId);
        } catch (err) {
            logger.logDebugEvent('NewLoad.js', err.message, true);
        }

        return existingLoadLane;
    };

    const getLinkedLoadLane = async (existingLinkedLoadLaneId, entityId = null) => {
        let existingLinkedLoadLane = null;
        try {
            existingLinkedLoadLane = await Data.getLinkedLoadLane(existingLinkedLoadLaneId, accounts, trailerTypes, entityId);
        } catch (err) {
            logger.logDebugEvent('NewLoad.js', err.message, true);
        }

        return existingLinkedLoadLane;
    };

    //#endregion
    //#region submits, cancels

    const cancelCancel = () => {
        return;
    };

    const confirmCancel = () => {
        dispatch(actionCreators.addLoadCancel());
        props.history.goBack();
        message.error('Load Creation Cancelled');
    };

    const done = (newLoadId = null) => {
        if (entityType === 'STAFF') {
            if (isStringNotEmpty(newLoadId) && bulkCount === 1) {
                props.history.push({ pathname: "/loads/" + newLoadId });
            } else {
                props.history.goBack();
            }
        } else if (entityType === 'SHIPPER' || entityType === 'BROKER') {
            if (isStringNotEmpty(newLoadId) && bulkCount === 1) {
                props.history.push({ pathname: "/loads/" + newLoadId });
            } else {
                props.history.goBack();
            }
        } else if (entityType === 'CARRIER') {
            if (isStringNotEmpty(newLoadId) && bulkCount === 1) {
                props.history.push({ pathname: "/loads/" + newLoadId });
            } else if (isStringNotEmpty(newLoadId) && bulkCount > 1) {
                props.history.push({ pathname: "/loadBundle/" + newLoadId });
            } else {
                props.history.push({ pathname: "/dispatch" });
            }
        } else {
            props.history.goBack();
        }

        message.success('Successfully Created Load!');
        dispatch(actionCreators.addLoadCancel());
    };

    const generateTMSInvoiceLineItem = (accountEntityId, accountEntityType) => {
        let newInvoiceLineItem = null;
        // if the serviceType is TMS then add the service fees
        if (isListNotEmpty(lineItemTypes) && isListNotEmpty(services) && isListNotEmpty(accounts) && isNumberNotEmpty(practicalRouteMiles)) {
            let lineItemType = lineItemTypes.find(j => j.name === 'TMS_SERVICE_FEE');
            let tmsService = services.find(j => j.serviceType === 'LOAD' && j.loadServiceType === 'TMS'); // get the default tms service
            let account = accounts.find(j => j.id === accountEntityId);
            if (isObjectNotEmpty(lineItemType) && isObjectNotEmpty(tmsService) && isObjectNotEmpty(account)) {
                newInvoiceLineItem = {
                    description: lineItemType.description,
                    itemType: 'TMS_SERVICE_FEE',
                    isIrisFee: true,
                    fromEntityType: accountEntityType,
                    fromEntityId: accountEntityId,
                    toEntityType: "STAFF",
                    status: "PENDING",
                    approvalStatus: "APPROVED",
                    isOpen: true,
                    isDeleted: false,
                };

                if (tmsService.billingFrequency === 'PER_LOAD') {
                    newInvoiceLineItem.quantity = Number(1);
                    newInvoiceLineItem.quantityUnit = 'PER_LOAD';
                    newInvoiceLineItem.baseAmount = Number(tmsService.priceAmount);
                    newInvoiceLineItem.baseAmountUnit = tmsService.priceAmountUnit;
                    newInvoiceLineItem.totalAmount = Number(tmsService.priceAmount);
                    newInvoiceLineItem.totalAmountUnit = tmsService.priceAmountUnit;
                } else if (tmsService.billingFrequency === 'PER_MILE') {
                    let baseAmount = Number(tmsService.priceAmount);
                    let quantity = Number(practicalRouteMiles);
                    let totalAmount = baseAmount * quantity;

                    newInvoiceLineItem.baseAmount = Number(baseAmount);
                    newInvoiceLineItem.baseAmountUnit = tmsService.priceAmountUnit;
                    newInvoiceLineItem.quantity = quantity;
                    newInvoiceLineItem.quantityUnit = 'PER_MILE';
                    newInvoiceLineItem.totalAmount = totalAmount;
                    newInvoiceLineItem.totalAmountUnit = tmsService.priceAmountUnit;
                } else {
                    newInvoiceLineItem.baseAmount = Number(0);
                    newInvoiceLineItem.baseAmountUnit = tmsService.priceAmountUnit;
                    newInvoiceLineItem.quantity = Number(1);
                    newInvoiceLineItem.quantityUnit = 'PER_LOAD';
                    newInvoiceLineItem.totalAmount = Number(0);
                    newInvoiceLineItem.totalAmountUnit = tmsService.priceAmountUnit;
                }

                // check to see if pricing for service should be over-riden
                let accountTMSService = null;
                if (isListNotEmpty(account.accountServices)) {
                    accountTMSService = account.accountServices.find(j => j.serviceName === tmsService.name);
                    if (isObjectNotEmpty(accountTMSService)) {
                        if (accountTMSService.isPriceOverride === true) {
                            if (accountTMSService.billingFrequency === 'PER_LOAD') {
                                newInvoiceLineItem.quantity = Number(1);
                                newInvoiceLineItem.quantityUnit = 'PER_LOAD';
                                newInvoiceLineItem.baseAmount = Number(accountTMSService.priceAmount);
                                newInvoiceLineItem.baseAmountUnit = accountTMSService.priceAmountUnit;
                                newInvoiceLineItem.totalAmount = Number(accountTMSService.priceAmount);
                                newInvoiceLineItem.totalAmountUnit = accountTMSService.priceAmountUnit;
                            } else if (accountTMSService.billingFrequency === 'PER_MILE') {
                                let baseAmount = Number(accountTMSService.priceAmount);
                                let quantity = Number(practicalRouteMiles);
                                let totalAmount = baseAmount * quantity;

                                newInvoiceLineItem.baseAmount = Number(baseAmount);
                                newInvoiceLineItem.baseAmountUnit = accountTMSService.priceAmountUnit;
                                newInvoiceLineItem.quantity = quantity;
                                newInvoiceLineItem.quantityUnit = 'PER_MILE';
                                newInvoiceLineItem.totalAmount = totalAmount;
                                newInvoiceLineItem.totalAmountUnit = accountTMSService.priceAmountUnit;
                            } else {
                                newInvoiceLineItem.baseAmount = Number(0);
                                newInvoiceLineItem.baseAmountUnit = accountTMSService.priceAmountUnit;
                                newInvoiceLineItem.quantity = Number(1);
                                newInvoiceLineItem.quantityUnit = 'PER_LOAD';
                                newInvoiceLineItem.totalAmount = Number(0);
                                newInvoiceLineItem.totalAmountUnit = accountTMSService.priceAmountUnit;
                            }
                        }
                    }
                }
            }
        }

        return newInvoiceLineItem;
    };

    const onSubmit = () => {
        let loadPayload = {
            unitOfMeasure: "ENGLISH",
            equipmentNeeds: {}
        };
        let stopsPayload = [];
        let invoiceLineItemsPayload = [];

        if (isNumberNotEmpty(practicalRouteMiles)) {
            loadPayload.practicalDistance = practicalRouteMiles;
            loadPayload.practicalDistanceUnit = "MI";
        }

        if (isObjectNotEmpty(shipperData) && isStringNotEmpty(shipperData.shipperId)) {
            loadPayload.shipperId = shipperData.shipperId;
        }

        if (entityType === 'BROKER') {
            loadPayload.brokerId = entityId;
        } else if (entityType === 'STAFF' && isStringNotEmpty(servicesData.serviceType) && servicesData.serviceType === 'BROKERAGE') {
            loadPayload.brokerId = InvoiceUtils.irisAccountId;
        }

        loadPayload.createdByEntityType = entityType;

        if (entityType === 'CARRIER' && isObjectNotEmpty(carrier)) {
            loadPayload.assignedCarrierId = carrier.id;
        } else if (isObjectNotEmpty(servicesData) && isStringNotEmpty(servicesData.assignedCarrierId)) {
            loadPayload.assignedCarrierId = servicesData.assignedCarrierId;
        }

        // assign the staff member as the default account rep for the load if being created by staff
        if (entityType === 'STAFF') {
            loadPayload.assignedAccountRepUserIds = [entityId];
        }

        if (bulkCount > 1) {
            loadPayload.childLoadInitCount = bulkCount - 1;
        }

        let stopsArray = [];
        if (isListNotEmpty(pickUpStops) && isListNotEmpty(dropOffStops)) {
            pickUpStops.forEach((stop) => {
                stopsArray.push({ ...stop });
            });

            dropOffStops.forEach((stop) => {
                stopsArray.push({ ...stop });
            });
        }

        if (isListNotEmpty(stopsArray) && stopsArray.length > 1) {
            methods.clearErrors('stops');
            let origin = stopsArray.find(stop => stop.sequence === 0 && stop.stopType === "PICK_UP");
            if (isObjectNotEmpty(origin)) {
                if (isMomentDate(origin.requestedDateTime)) {
                    let momentDateTime = origin.requestedDateTime;
                    let timeZone = origin.stopLocation.timeZone;
                    loadPayload.pickUpDateTime = momentDate.asTimeZoneUtcISOString(momentDateTime, timeZone);
                }
            } else {
                methods.setError('stops', { type: 'required', message: 'Cannot create a load without an origin stop.' });
                return;
            }
        } else {
            methods.setError('stops', { type: 'required', message: 'Cannot create a load without at least 2 stops.' });
            return;
        }

        if (isObjectNotEmpty(equipmentData)) {
            if (isStringNotEmpty(equipmentData.trailerTypeId)) {
                loadPayload.equipmentNeeds.trailerTypeId = equipmentData.trailerTypeId;
            }

            if (isNumberNotEmpty(equipmentData.trailerLength)) {
                loadPayload.equipmentNeeds.trailerLength = Number(equipmentData.trailerLength);
                loadPayload.equipmentNeeds.trailerLengthUnit = "FT";
            }

            if (isNumberNotEmpty(equipmentData.trailerWeightCapacity)) {
                loadPayload.equipmentNeeds.trailerWeightCapacity = Number(equipmentData.trailerWeightCapacity);
                loadPayload.equipmentNeeds.trailerWeightCapacityUnit = "LB";
            }

            if (isNumberNotEmpty(equipmentData.preCoolReefer)) {
                loadPayload.equipmentNeeds.preCoolReefer = Number(equipmentData.preCoolReefer);
                loadPayload.equipmentNeeds.preCoolReeferUnit = "F";
            }

            if (isNumberNotEmpty(equipmentData.minRunTemp)) {
                loadPayload.equipmentNeeds.minRunTemp = Number(equipmentData.minRunTemp);
                loadPayload.equipmentNeeds.minRunTempUnit = "F";
            }

            if (isNumberNotEmpty(equipmentData.maxRunTemp)) {
                loadPayload.equipmentNeeds.maxRunTemp = Number(equipmentData.maxRunTemp);
                loadPayload.equipmentNeeds.maxRunTempUnit = "F";
            }

            if (isListNotEmpty(equipmentData.driverRequirements)) {
                loadPayload.driverRequirements = equipmentData.driverRequirements;
            }

            if (isListNotEmpty(equipmentData.equipmentSpecifics)) {
                loadPayload.equipmentSpecifics = equipmentData.equipmentSpecifics;
            }
        }

        if (isObjectNotEmpty(servicesData)) {
            if (isStringNotEmpty(servicesData.customerOrderNumber)) {
                loadPayload.customerOrderNumber = servicesData.customerOrderNumber;
            }

            if (isStringNotEmpty(servicesData.parentName)) {
                loadPayload.parentName = servicesData.parentName;
            }

            if (isStringNotEmpty(servicesData.serviceType)) {
                loadPayload.serviceType = servicesData.serviceType;

                if (servicesData.serviceType === 'TMS') {
                    let tmsInvoiceLineItem = generateTMSInvoiceLineItem(entityId, entityType);
                    if (isObjectNotEmpty(tmsInvoiceLineItem)) {
                        invoiceLineItemsPayload.push(tmsInvoiceLineItem);
                    }
                }
            }

            if (isNumberNotEmpty(servicesData.shipperRatePerMile)) {
                loadPayload.shipperRatePerMile = servicesData.shipperRatePerMile;
                loadPayload.shipperRatePerMileUnit = 'USD';
            }
            if (isNumberNotEmpty(servicesData.shipperAmount)) {
                loadPayload.shipperAmount = servicesData.shipperAmount;
                loadPayload.shipperAmountUnit = 'USD';
                loadPayload.shipperBalance = servicesData.shipperAmount;
                loadPayload.shipperBalanceUnit = 'USD';
            }
            if (isNumberNotEmpty(servicesData.carrierRatePerMile)) {
                loadPayload.carrierRatePerMile = servicesData.carrierRatePerMile;
                loadPayload.carrierRatePerMileUnit = 'USD';
            }
            if (isNumberNotEmpty(servicesData.carrierAmount)) {
                loadPayload.carrierAmount = servicesData.carrierAmount;
                loadPayload.carrierAmountUnit = 'USD';
                loadPayload.carrierBalance = servicesData.carrierAmount;
                loadPayload.carrierBalanceUnit = 'USD';
            }
        }

        if (isListNotEmpty(stopsArray)) {
            stopsArray.forEach((stop, index) => {
                if (isObjectNotEmpty(stop.stopLocation)) {
                    let newStop = {
                        unitOfMeasure: "ENGLISH",
                        latitude: stop.stopLocation.latitude,
                        longitude: stop.stopLocation.longitude,
                        timeZone: stop.stopLocation.timeZone,
                        stopType: stop.stopType,
                        sequence: index,
                        stopLocationId: stop.stopLocationId,
                        hasDriverAssist: stop.hasDriverAssist,
                        hasLumperFee: stop.hasLumperFee,
                        bolNumbers: stop.bolNumbers,
                        apptType: stop.apptType,
                        loadingType: stop.loadingType
                    };

                    let timeZone = stop.stopLocation.timeZone;

                    if (stop.hasLumperFee === true && isNumberGreaterThanZero(stop.lumperFee)) {
                        newStop.lumperFee = Number(stop.lumperFee);
                        newStop.lumperFeeUnit = 'USD';
                    } else {
                        newStop.lumperFee = 0;
                        newStop.lumperFeeUnit = 'USD';
                    }

                    if (stop.hasDriverAssist === true && isNumberGreaterThanZero(stop.driverAssist)) {
                        newStop.driverAssist = Number(stop.driverAssist);
                        newStop.driverAssistUnit = 'USD';
                    } else {
                        newStop.driverAssist = 0;
                        newStop.driverAssistUnit = 'USD';
                    }

                    if (isStringNotEmpty(stop.purchaseOrderNumber)) {
                        newStop.purchaseOrderNumber = stop.purchaseOrderNumber;
                    }

                    if (isStringNotEmpty(stop.pickUpNumber)) {
                        newStop.pickUpNumber = stop.pickUpNumber;
                    }

                    if (isStringNotEmpty(stop.dropOffNumber)) {
                        newStop.dropOffNumber = stop.dropOffNumber;
                    }

                    if (isStringNotEmpty(stop.specialInstructions)) {
                        newStop.specialInstructions = stop.specialInstructions;
                    }

                    if (isStringNotEmpty(stop.apptInstructions)) {
                        newStop.apptInstructions = stop.apptInstructions;
                    }

                    if (isStringNotEmpty(stop.apptNumber)) {
                        newStop.apptNumber = stop.apptNumber;
                    }

                    if (isMomentDate(stop.apptWindowStartDateTime)) {
                        newStop.apptWindowStartDateTime = momentDate.asTimeZoneUtcISOString(stop.apptWindowStartDateTime, timeZone);
                    }

                    if (isMomentDate(stop.apptWindowEndDateTime)) {
                        newStop.apptWindowEndDateTime = momentDate.asTimeZoneUtcISOString(stop.apptWindowEndDateTime, timeZone);
                    }

                    if (isMomentDate(stop.requestedDateTime)) {
                        newStop.requestedDateTime = momentDate.asTimeZoneUtcISOString(stop.requestedDateTime, timeZone);
                    }

                    if (isMomentDate(stop.apptCallAheadDateTime)) {
                        newStop.apptCallAheadDateTime = momentDate.asTimeZoneUtcISOString(stop.apptCallAheadDateTime, timeZone);
                    }

                    if (isMomentDate(stop.apptDateTime)) {
                        newStop.apptDateTime = momentDate.asTimeZoneUtcISOString(stop.apptDateTime, timeZone);
                    }

                    if (isObjectNotEmpty(stop.apptPointOfContact)) {
                        newStop.apptPointOfContact = stop.apptPointOfContact;
                    }

                    if (isListNotEmpty(stop.commodities)) {
                        let newCommodities = [];
                        stop.commodities.forEach((commodity) => {
                            let newCommodity = {
                                commodityId: commodity.commodityId,
                                name: commodity.name,
                                unitPackaging: commodity.unitPackaging,
                                unitPackagingInitCount: Number(commodity.unitPackagingInitCount)
                            };

                            if (isStringNotEmpty(commodity.bulkPackaging)) {
                                newCommodity.bulkPackaging = commodity.bulkPackaging;
                                newCommodity.bulkPackagingInitCount = Number(commodity.bulkPackagingInitCount);
                            }

                            newCommodities.push(newCommodity);
                        });

                        if (isListNotEmpty(newCommodities)) {
                            newStop.commodities = newCommodities;
                        }
                    }

                    stopsPayload.push({ ...newStop });
                }
            });
        }

        if (isListNotEmpty(invoiceLineItems)) {
            invoiceLineItems.forEach((invoiceLineItem) => {
                let newInvoiceLineItem = {
                    description: invoiceLineItem.description,
                    itemType: invoiceLineItem.itemType,
                    isIrisFee: invoiceLineItem.isIrisFee
                };

                if (isStringNotEmpty(invoiceLineItem.stopId)) {
                    newInvoiceLineItem.stopId = invoiceLineItem.stopId;
                }

                if (isStringNotEmpty(invoiceLineItem.fromEntityType)) {
                    newInvoiceLineItem.fromEntityType = invoiceLineItem.fromEntityType;
                    if (invoiceLineItem.fromEntityType === 'SHIPPER' && isStringNotEmpty(loadPayload.shipperId)) {
                        newInvoiceLineItem.fromEntityId = loadPayload.shipperId;
                    } else if (invoiceLineItem.fromEntityType === 'CARRIER' && isStringNotEmpty(loadPayload.assignedCarrierId)) {
                        newInvoiceLineItem.fromEntityId = loadPayload.assignedCarrierId;
                    }
                }

                if (isStringNotEmpty(invoiceLineItem.toEntityType)) {
                    newInvoiceLineItem.toEntityType = invoiceLineItem.toEntityType;
                    if (invoiceLineItem.toEntityType === 'SHIPPER' && isStringNotEmpty(loadPayload.shipperId)) {
                        newInvoiceLineItem.toEntityId = loadPayload.shipperId;
                    } else if (invoiceLineItem.toEntityType === 'CARRIER' && isStringNotEmpty(loadPayload.assignedCarrierId)) {
                        newInvoiceLineItem.toEntityId = loadPayload.assignedCarrierId;
                    }
                }

                if (isNumberNotEmpty(invoiceLineItem.baseAmount)) {
                    newInvoiceLineItem.baseAmount = Number(invoiceLineItem.baseAmount);
                }

                if (isStringNotEmpty(invoiceLineItem.baseAmountUnit)) {
                    newInvoiceLineItem.baseAmountUnit = invoiceLineItem.baseAmountUnit;
                }

                if (isNumberNotEmpty(invoiceLineItem.totalAmount)) {
                    newInvoiceLineItem.totalAmount = Number(invoiceLineItem.totalAmount);
                }

                if (isStringNotEmpty(invoiceLineItem.totalAmountUnit)) {
                    newInvoiceLineItem.totalAmountUnit = invoiceLineItem.totalAmountUnit;
                }

                if (isNumberNotEmpty(invoiceLineItem.quantity)) {
                    newInvoiceLineItem.quantity = Number(invoiceLineItem.quantity);
                }

                if (isStringNotEmpty(invoiceLineItem.quantityUnit)) {
                    newInvoiceLineItem.quantityUnit = invoiceLineItem.quantityUnit;
                }

                if (isStringNotEmpty(invoiceLineItem.status)) {
                    newInvoiceLineItem.status = invoiceLineItem.status;
                }

                if (isStringNotEmpty(invoiceLineItem.approvalStatus)) {
                    newInvoiceLineItem.approvalStatus = invoiceLineItem.approvalStatus;
                }

                if (isNotNullOrUndefined(invoiceLineItem.isOpen)) {
                    newInvoiceLineItem.isOpen = invoiceLineItem.isOpen;
                }

                invoiceLineItemsPayload.push(newInvoiceLineItem);
            });
        }

        let newLoadPayload = removeEmpty(loadPayload);
        let newStopsPayload = removeEmpty(stopsPayload);

        dispatch(actionCreators.addLoad(newLoadPayload, newStopsPayload, invoiceLineItemsPayload, docFiles));
    };

    //#endregion
    //#region useEffects and useMemos

    useMemo(() => {
        if (entityType === 'CARRIER' && isObjectNotEmpty(yourAccount)) {
            setCarrier(yourAccount);
        } else {
            setCarrier(null);
        }
    }, [yourAccount, entityType]);

    useMemo(() => {
        // clear any previous errors if this is a new load creation
        dispatch(actionCreators.addLoadLoadingClear());
        dispatch(actionCreators.addLoadErrorClear());
        dispatch(actionCreators.getAccounts());
        dispatch(actionCreators.getServices());
        dispatch(actionCreators.getTrailerTypes());
        dispatch(actionCreators.getLineItemTypes());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null && isStringNotEmpty(addRecordId)) {
            done(addRecordId);
        }
    }, [isLoading, error, addRecordId]);

    useMemo(() => {
        if (entityType === 'CARRIER' || entityType === 'STAFF' || entityType === 'BROKER' || entityType === 'SHIPPER') {
            setDontRequireLoadSpecificInformation(true);
        } else {
            setDontRequireLoadSpecificInformation(false);
        }

        if (bulkCount > 1) {
            setHideLoadSpecificInformation(true);
        } else {
            setHideLoadSpecificInformation(false);
        }
    }, [bulkCount, entityType]);

    useMemo(() => {
        // console.log(`The Shipper ID is ${shipperIdValue}`);
        if (isListNotEmpty(accounts)) {
            if (isStringNotEmpty(shipperIdValue) && isObjectEmpty(shipperData)) {
                let selectedShipperAccount = accounts.find(s => s.id === shipperIdValue);
                if (isObjectNotEmpty(selectedShipperAccount)) {
                    const shipperDataObj = {
                        shipperId: shipperIdValue,
                        shipperName: selectedShipperAccount.name,
                        shipper: selectedShipperAccount
                    };
                    setShipperData(shipperDataObj);
                }
            }
        }
    }, [shipperIdValue, accounts]);

    useMemo(() => {
        // console.log(`The Load ID is ${duplicateLoadId}`);
        // console.log(`The Load Lane ID is ${loadLaneId}`);
        // console.log(`The Linked Load Lane ID is ${linkedLoadLaneId}`);
        if (isListNotEmpty(trailerTypes) && isListNotEmpty(lineItemTypes)) {
            if (isStringNotEmpty(duplicateLoadId)) {
                if (isObjectEmpty(equipmentData) && isListEmpty(pickUpStops) && isListEmpty(dropOffStops)) {
                    setIsStepsLoading(true);
                    getLoadWithStops(duplicateLoadId).then((load) => {
                        if (isObjectNotEmpty(load)) {
                            // console.log(load);
                            let equipmentDataObj = {};
                            if (isObjectNotEmpty(load.equipmentNeeds)) {
                                equipmentDataObj.trailerTypeId = load.equipmentNeeds.trailerTypeId;
                                equipmentDataObj.trailerLength = load.equipmentNeeds.trailerLength;
                                equipmentDataObj.trailerLengthUnit = load.equipmentNeeds.trailerLengthUnit;
                                equipmentDataObj.trailerWeightCapacity = load.equipmentNeeds.trailerWeightCapacity;
                                equipmentDataObj.trailerWeightCapacityUnit = load.equipmentNeeds.trailerWeightCapacityUnit;
                                equipmentDataObj.preCoolReefer = load.equipmentNeeds.preCoolReefer;
                                equipmentDataObj.preCoolReeferUnit = load.equipmentNeeds.preCoolReeferUnit;
                                equipmentDataObj.minRunTemp = load.equipmentNeeds.minRunTemp;
                                equipmentDataObj.minRunTempUnit = load.equipmentNeeds.minRunTempUnit;
                                equipmentDataObj.maxRunTemp = load.equipmentNeeds.maxRunTemp;
                                equipmentDataObj.maxRunTempUnit = load.equipmentNeeds.maxRunTempUnit;

                                if (isStringNotEmpty(load.equipmentNeeds.trailerTypeId)) {
                                    let selectedTrailerType = trailerTypes.find(s => s.id === load.equipmentNeeds.trailerTypeId);
                                    if (isObjectNotEmpty(selectedTrailerType)) {
                                        equipmentDataObj.trailerType = selectedTrailerType;
                                    }
                                }
                            }

                            if (isListNotEmpty(load.equipmentSpecifics)) {
                                equipmentDataObj.equipmentSpecifics = load.equipmentSpecifics;
                            }
                            if (isListNotEmpty(load.driverRequirements)) {
                                equipmentDataObj.driverRequirements = load.driverRequirements;
                            }

                            setEquipmentData({ ...equipmentDataObj });

                            let pickUpStopsArray = [];
                            let dropOffStopsArray = [];
                            if (isListNotEmpty(load.stops)) {
                                let pickUps = load.stops.filter(s => s.stopType === 'PICK_UP');
                                let dropOffs = load.stops.filter(s => s.stopType === 'DROP_OFF');
                                if (isListNotEmpty(pickUps)) {
                                    let orderedPickUpStops = sortBy(pickUps, [function (o) { return o.sequence; }]);
                                    // console.log(orderedPickUpStops);
                                    orderedPickUpStops.forEach((stop, stopIndex, arr) => {
                                        pickUpStopsArray.push({
                                            sequence: stopIndex,
                                            stopType: stop.stopType,
                                            stopTypeSequence: stopIndex,
                                            id: stopIndex,
                                            stopLocation: stop.stopLocation,
                                            stopLocationId: stop.stopLocationId,
                                            specialInstructions: stop.specialInstructions,
                                            hasLumperFee: stop.hasLumperFee,
                                            lumperFee: stop.lumperFee,
                                            hasDriverAssist: stop.hasDriverAssist,
                                            driverAssist: stop.driverAssist,
                                            apptType: stop.apptType,
                                            apptPointOfContact: stop.apptPointOfContact,
                                            commodities: stop.commodities,
                                            loadingType: stop.loadingType,
                                            timeZone: isObjectNotEmpty(stop.stopLocation) && isStringNotEmpty(stop.stopLocation.timeZone) ? stop.stopLocation.timeZone : null
                                        });
                                    });

                                    setPickUpStops(pickUpStopsArray);
                                }
                                if (isListNotEmpty(dropOffs)) {
                                    let orderedDropOffStops = sortBy(dropOffs, [function (o) { return o.sequence; }]);
                                    // console.log(orderedDropOffStops);
                                    orderedDropOffStops.forEach((stop, stopIndex, arr) => {
                                        dropOffStopsArray.push({
                                            sequence: stopIndex + pickUpStopsArray.length,
                                            stopType: stop.stopType,
                                            stopTypeSequence: stopIndex,
                                            id: stopIndex + pickUpStopsArray.length,
                                            stopLocation: stop.stopLocation,
                                            stopLocationId: stop.stopLocationId,
                                            specialInstructions: stop.specialInstructions,
                                            hasLumperFee: stop.hasLumperFee,
                                            lumperFee: stop.lumperFee,
                                            hasDriverAssist: stop.hasDriverAssist,
                                            driverAssist: stop.driverAssist,
                                            apptType: stop.apptType,
                                            apptPointOfContact: stop.apptPointOfContact,
                                            commodities: stop.commodities,
                                            loadingType: stop.loadingType,
                                            timeZone: isObjectNotEmpty(stop.stopLocation) && isStringNotEmpty(stop.stopLocation.timeZone) ? stop.stopLocation.timeZone : null
                                        });
                                    });

                                    setDropOffStops(dropOffStopsArray);
                                }
                            }
                        }

                        setIsStepsLoading(false);
                    }).catch(err => {
                        logger.logDebugEvent('NewLoad.js', err.message, true);
                        setIsStepsLoading(false);
                    });
                }
            } else if (isStringNotEmpty(loadLaneId)) {
                if (isListEmpty(pickUpStops) && isListEmpty(dropOffStops)) {
                    setIsStepsLoading(true);
                    getLoadLane(loadLaneId, entityId).then((loadLane) => {
                        // console.log(loadLane);

                        setLoadLane(loadLane);

                        let servicesDataObj = {};
                        if (isObjectEmpty(servicesData)) {
                            if (isStringNotEmpty(loadLane.customerLaneId)) {
                                if (bulkCount === 1) {
                                    servicesDataObj.parentName = loadLane.customerLaneId;
                                } else if (bulkCount > 1) {
                                    servicesDataObj.parentName = loadLane.customerLaneId;
                                }
                            }

                            if (isNumberGreaterThanZero(loadLane.contractRate)) {
                                servicesDataObj.contractRateAmount = loadLane.contractRate;
                                servicesDataObj.rateOption = 'CONTRACT_RATE';
                            } else if (isNumberGreaterThanZero(loadLane.perMileRate)) {
                                servicesDataObj.baseRatePerMileAmount = loadLane.perMileRate;
                                servicesDataObj.rateOption = 'PER_MILE';
                            }

                            setServicesData(servicesDataObj);
                        }

                        let equipmentDataObj = {};
                        if (isObjectNotEmpty(loadLane.equipmentNeeds)) {
                            equipmentDataObj.trailerType = loadLane.equipmentNeeds.trailerType;
                            equipmentDataObj.trailerTypeId = loadLane.equipmentNeeds.trailerTypeId;
                            equipmentDataObj.trailerLength = loadLane.equipmentNeeds.trailerLength;
                            equipmentDataObj.trailerLengthUnit = loadLane.equipmentNeeds.trailerLengthUnit;
                            equipmentDataObj.trailerWeightCapacity = loadLane.equipmentNeeds.trailerWeightCapacity;
                            equipmentDataObj.trailerWeightCapacityUnit = loadLane.equipmentNeeds.trailerWeightCapacityUnit;
                            equipmentDataObj.preCoolReefer = loadLane.equipmentNeeds.preCoolReefer;
                            equipmentDataObj.preCoolReeferUnit = loadLane.equipmentNeeds.preCoolReeferUnit;
                            equipmentDataObj.minRunTemp = loadLane.equipmentNeeds.minRunTemp;
                            equipmentDataObj.minRunTempUnit = loadLane.equipmentNeeds.minRunTempUnit;
                            equipmentDataObj.maxRunTemp = loadLane.equipmentNeeds.maxRunTemp;
                            equipmentDataObj.maxRunTempUnit = loadLane.equipmentNeeds.maxRunTempUnit;

                            // if (isStringNotEmpty(loadLane.equipmentNeeds.trailerTypeId)) {
                            //     let selectedTrailerType = trailerTypes.find(s => s.id === loadLane.equipmentNeeds.trailerTypeId);
                            //     if (isObjectNotEmpty(selectedTrailerType)) {
                            //         equipmentDataObj.trailerType = selectedTrailerType;
                            //     }
                            // }
                        }

                        if (isListNotEmpty(loadLane.equipmentSpecifics)) {
                            equipmentDataObj.equipmentSpecifics = loadLane.equipmentSpecifics;
                        }
                        if (isListNotEmpty(loadLane.driverRequirements)) {
                            equipmentDataObj.driverRequirements = loadLane.driverRequirements;
                        }

                        setEquipmentData({ ...equipmentDataObj });

                        let pickUpStopsArray = [];
                        let dropOffStopsArray = [];
                        if (isListNotEmpty(loadLane.locations)) {
                            let pickUps = loadLane.locations.filter(s => s.stopType === 'PICK_UP');
                            let dropOffs = loadLane.locations.filter(s => s.stopType === 'DROP_OFF');
                            if (isListNotEmpty(pickUps)) {
                                let orderedPickUpLocations = sortBy(pickUps, [function (o) { return o.sequence; }]);
                                // console.log(orderedPickUpLocations);
                                orderedPickUpLocations.forEach((laneLocation, laneLocationIndex, arr) => {
                                    let newStop = {
                                        sequence: laneLocationIndex,
                                        stopType: laneLocation.stopType,
                                        stopTypeSequence: laneLocationIndex,
                                        id: laneLocationIndex,
                                        stopLocation: laneLocation.location,
                                        stopLocationId: laneLocation.locationId,
                                        hasDriverAssist: false
                                    };

                                    if (isObjectNotEmpty(laneLocation.location)) {
                                        if (isStringNotEmpty(laneLocation.location.specialInstructions)) {
                                            newStop.specialInstructions = laneLocation.location.specialInstructions;
                                        }

                                        if (isNumberGreaterThanZero(laneLocation.location.lumperFee)) {
                                            newStop.hasLumperFee = true;
                                            newStop.lumperFee = laneLocation.location.lumperFee;
                                        } else {
                                            newStop.hasLumperFee = false;
                                        }

                                        if (isObjectNotEmpty(laneLocation.location.pointOfContact)) {
                                            newStop.apptPointOfContact = laneLocation.location.pointOfContact;
                                        }

                                        if (isStringNotEmpty(laneLocation.location.appointmentType)) {
                                            newStop.apptType = laneLocation.location.appointmentType;
                                        }

                                        if (isStringNotEmpty(laneLocation.location.timeZone)) {
                                            newStop.timeZone = laneLocation.location.timeZone;
                                        }
                                    }

                                    if (isStringNotEmpty(laneLocation.loadingType)) {
                                        newStop.loadingType = laneLocation.loadingType;
                                    }

                                    if (isListNotEmpty(laneLocation.commodities)) {
                                        newStop.commodities = laneLocation.commodities;
                                    }

                                    pickUpStopsArray.push(newStop);
                                });

                                setPickUpStops(pickUpStopsArray);
                            }
                            if (isListNotEmpty(dropOffs)) {
                                let orderedDropOffStops = sortBy(dropOffs, [function (o) { return o.sequence; }]);
                                // console.log(orderedDropOffStops);
                                orderedDropOffStops.forEach((laneLocation, laneLocationIndex, arr) => {
                                    let newStop = {
                                        sequence: laneLocationIndex + pickUpStopsArray.length,
                                        stopType: laneLocation.stopType,
                                        stopTypeSequence: laneLocationIndex,
                                        id: laneLocationIndex + pickUpStopsArray.length,
                                        stopLocation: laneLocation.location,
                                        stopLocationId: laneLocation.locationId,
                                        hasDriverAssist: false
                                    };

                                    if (isObjectNotEmpty(laneLocation.location)) {
                                        if (isStringNotEmpty(laneLocation.location.specialInstructions)) {
                                            newStop.specialInstructions = laneLocation.location.specialInstructions;
                                        }

                                        if (isNumberGreaterThanZero(laneLocation.location.lumperFee)) {
                                            newStop.hasLumperFee = true;
                                            newStop.lumperFee = laneLocation.location.lumperFee;
                                        } else {
                                            newStop.hasLumperFee = false;
                                        }

                                        if (isObjectNotEmpty(laneLocation.location.pointOfContact)) {
                                            newStop.apptPointOfContact = laneLocation.location.pointOfContact;
                                        }

                                        if (isStringNotEmpty(laneLocation.location.appointmentType)) {
                                            newStop.apptType = laneLocation.location.appointmentType;
                                        }

                                        if (isStringNotEmpty(laneLocation.location.timeZone)) {
                                            newStop.timeZone = laneLocation.location.timeZone;
                                        }
                                    }

                                    if (isStringNotEmpty(laneLocation.loadingType)) {
                                        newStop.loadingType = laneLocation.loadingType;
                                    }

                                    if (isListNotEmpty(laneLocation.commodities)) {
                                        newStop.commodities = laneLocation.commodities;
                                    }

                                    dropOffStopsArray.push(newStop);
                                });

                                setDropOffStops(dropOffStopsArray);
                            }
                        }

                        setIsStepsLoading(false);
                    }).catch(err => {
                        logger.logDebugEvent('NewLoad.js', err.message, true);
                        setIsStepsLoading(false);
                    });
                }
            } else if (isStringNotEmpty(linkedLoadLaneId)) {
                if (isListEmpty(pickUpStops) && isListEmpty(dropOffStops)) {
                    setIsStepsLoading(true);
                    getLinkedLoadLane(linkedLoadLaneId, entityId).then((linkedLoadLane) => {
                        console.log(linkedLoadLane);

                        setLoadLane(linkedLoadLane);

                        let servicesDataObj = {};
                        // console.log(bulkCount);
                        // console.log(servicesData);
                        if (isObjectEmpty(servicesData)) {
                            if (isStringNotEmpty(linkedLoadLane.customerLaneId)) {
                                if (bulkCount === 1) {
                                    servicesDataObj.parentName = linkedLoadLane.customerLaneId;
                                } else if (bulkCount > 1) {
                                    servicesDataObj.parentName = linkedLoadLane.customerLaneId;
                                }
                            }

                            if (isNumberGreaterThanZero(linkedLoadLane.contractRate)) {
                                servicesDataObj.contractRateAmount = linkedLoadLane.contractRate;
                                servicesDataObj.rateOption = 'CONTRACT_RATE';
                            } else if (isNumberGreaterThanZero(linkedLoadLane.perMileRate)) {
                                servicesDataObj.baseRatePerMileAmount = linkedLoadLane.perMileRate;
                                servicesDataObj.rateOption = 'PER_MILE';
                            }

                            // console.log(servicesDataObj);
                            setServicesData(servicesDataObj);
                        }

                        let equipmentDataObj = {};
                        if (isObjectNotEmpty(linkedLoadLane.equipmentNeeds)) {
                            equipmentDataObj.trailerTypeId = linkedLoadLane.equipmentNeeds.trailerTypeId;
                            equipmentDataObj.trailerLength = linkedLoadLane.equipmentNeeds.trailerLength;
                            equipmentDataObj.trailerLengthUnit = linkedLoadLane.equipmentNeeds.trailerLengthUnit;
                            equipmentDataObj.trailerWeightCapacity = linkedLoadLane.equipmentNeeds.trailerWeightCapacity;
                            equipmentDataObj.trailerWeightCapacityUnit = linkedLoadLane.equipmentNeeds.trailerWeightCapacityUnit;
                            equipmentDataObj.preCoolReefer = linkedLoadLane.equipmentNeeds.preCoolReefer;
                            equipmentDataObj.preCoolReeferUnit = linkedLoadLane.equipmentNeeds.preCoolReeferUnit;
                            equipmentDataObj.minRunTemp = linkedLoadLane.equipmentNeeds.minRunTemp;
                            equipmentDataObj.minRunTempUnit = linkedLoadLane.equipmentNeeds.minRunTempUnit;
                            equipmentDataObj.maxRunTemp = linkedLoadLane.equipmentNeeds.maxRunTemp;
                            equipmentDataObj.maxRunTempUnit = linkedLoadLane.equipmentNeeds.maxRunTempUnit;

                            if (isStringNotEmpty(linkedLoadLane.equipmentNeeds.trailerTypeId)) {
                                let selectedTrailerType = trailerTypes.find(s => s.id === linkedLoadLane.equipmentNeeds.trailerTypeId);
                                if (isObjectNotEmpty(selectedTrailerType)) {
                                    equipmentDataObj.trailerType = selectedTrailerType;
                                }
                            }
                        }

                        if (isListNotEmpty(linkedLoadLane.equipmentSpecifics)) {
                            equipmentDataObj.equipmentSpecifics = linkedLoadLane.equipmentSpecifics;
                        }
                        if (isListNotEmpty(linkedLoadLane.driverRequirements)) {
                            equipmentDataObj.driverRequirements = linkedLoadLane.driverRequirements;
                        }

                        setEquipmentData({ ...equipmentDataObj });

                        let pickUpStopsArray = [];
                        let dropOffStopsArray = [];
                        if (isListNotEmpty(linkedLoadLane.locations)) {
                            let pickUps = linkedLoadLane.locations.filter(s => s.stopType === 'PICK_UP');
                            let dropOffs = linkedLoadLane.locations.filter(s => s.stopType === 'DROP_OFF');
                            if (isListNotEmpty(pickUps)) {
                                let orderedPickUpLocations = sortBy(pickUps, [function (o) { return o.sequence; }]);
                                // console.log(orderedPickUpLocations);
                                orderedPickUpLocations.forEach((laneLocation, laneLocationIndex, arr) => {
                                    let newStop = {
                                        sequence: laneLocationIndex,
                                        stopType: laneLocation.stopType,
                                        stopTypeSequence: laneLocationIndex,
                                        id: laneLocationIndex,
                                        stopLocation: laneLocation.location,
                                        stopLocationId: laneLocation.locationId,
                                        hasDriverAssist: false
                                    };

                                    if (isObjectNotEmpty(laneLocation.location)) {
                                        if (isStringNotEmpty(laneLocation.location.specialInstructions)) {
                                            newStop.specialInstructions = laneLocation.location.specialInstructions;
                                        }

                                        if (isNumberGreaterThanZero(laneLocation.location.lumperFee)) {
                                            newStop.hasLumperFee = true;
                                            newStop.lumperFee = laneLocation.location.lumperFee;
                                        } else {
                                            newStop.hasLumperFee = false;
                                        }

                                        if (isObjectNotEmpty(laneLocation.location.pointOfContact)) {
                                            newStop.apptPointOfContact = laneLocation.location.pointOfContact;
                                        }

                                        if (isStringNotEmpty(laneLocation.location.appointmentType)) {
                                            newStop.apptType = laneLocation.location.appointmentType;
                                        }

                                        if (isStringNotEmpty(laneLocation.location.timeZone)) {
                                            newStop.timeZone = laneLocation.location.timeZone;
                                        }
                                    }

                                    if (isStringNotEmpty(laneLocation.loadingType)) {
                                        newStop.loadingType = laneLocation.loadingType;
                                    }

                                    if (isListNotEmpty(laneLocation.commodities)) {
                                        newStop.commodities = laneLocation.commodities;
                                    }

                                    pickUpStopsArray.push(newStop);
                                });

                                setPickUpStops(pickUpStopsArray);
                            }
                            if (isListNotEmpty(dropOffs)) {
                                let orderedDropOffStops = sortBy(dropOffs, [function (o) { return o.sequence; }]);
                                // console.log(orderedDropOffStops);
                                orderedDropOffStops.forEach((laneLocation, laneLocationIndex, arr) => {
                                    let newStop = {
                                        sequence: laneLocationIndex + pickUpStopsArray.length,
                                        stopType: laneLocation.stopType,
                                        stopTypeSequence: laneLocationIndex,
                                        id: laneLocationIndex + pickUpStopsArray.length,
                                        stopLocation: laneLocation.location,
                                        stopLocationId: laneLocation.locationId,
                                        hasDriverAssist: false
                                    };

                                    if (isObjectNotEmpty(laneLocation.location)) {
                                        if (isStringNotEmpty(laneLocation.location.specialInstructions)) {
                                            newStop.specialInstructions = laneLocation.location.specialInstructions;
                                        }

                                        if (isNumberGreaterThanZero(laneLocation.location.lumperFee)) {
                                            newStop.hasLumperFee = true;
                                            newStop.lumperFee = laneLocation.location.lumperFee;
                                        } else {
                                            newStop.hasLumperFee = false;
                                        }

                                        if (isObjectNotEmpty(laneLocation.location.pointOfContact)) {
                                            newStop.apptPointOfContact = laneLocation.location.pointOfContact;
                                        }

                                        if (isStringNotEmpty(laneLocation.location.appointmentType)) {
                                            newStop.apptType = laneLocation.location.appointmentType;
                                        }

                                        if (isStringNotEmpty(laneLocation.location.timeZone)) {
                                            newStop.timeZone = laneLocation.location.timeZone;
                                        }
                                    }

                                    if (isStringNotEmpty(laneLocation.loadingType)) {
                                        newStop.loadingType = laneLocation.loadingType;
                                    }

                                    if (isListNotEmpty(laneLocation.commodities)) {
                                        newStop.commodities = laneLocation.commodities;
                                    }

                                    dropOffStopsArray.push(newStop);
                                });

                                setDropOffStops(dropOffStopsArray);
                            }
                        }

                        setIsStepsLoading(false);
                    }).catch(err => {
                        logger.logDebugEvent('NewLoad.js', err.message, true);
                        setIsStepsLoading(false);
                    });
                }
            } else if (isStringEmpty(duplicateLoadId) || isStringEmpty(loadLaneId) || isStringEmpty(linkedLoadLaneId)) {
                if (isListEmpty(pickUpStops) && isListEmpty(dropOffStops)) {
                    setIsStepsLoading(true);
                    setPickUpStops([{
                        stopType: 'PICK_UP',
                        id: 0,
                        sequence: 0,
                        stopTypeSequence: 0
                    }]);

                    setDropOffStops([{
                        stopType: 'DROP_OFF',
                        id: 1,
                        sequence: 1,
                        stopTypeSequence: 0
                    }]);
                }
                setIsStepsLoading(false);
            }
        }
    }, [loadLaneId, linkedLoadLaneId, duplicateLoadId, lineItemTypes, trailerTypes, entityId]);

    //#endregion
    //#region displays

    const getEquipmentData = () => {
        if (isObjectNotEmpty(equipmentData)) {
            let trailerLength = stringFormatter.toFormattedString('Length', equipmentData.trailerLength, equipmentData.trailerLengthUnit);
            let trailerWeightCapacity = stringFormatter.toFormattedString('Weight', equipmentData.trailerWeightCapacity, equipmentData.trailerWeightCapacityUnit);

            return (
                <Row gutter={[12, 12]}>
                    {(isObjectNotEmpty(equipmentData.trailerType) && isStringNotEmpty(equipmentData.trailerType.description)) ? (
                        <Col span={24}>
                            <div style={{ marginBottom: 12 }}><span style={{ fontSize: 14, color: '#000000' }}>Trailer: {equipmentData.trailerType.description} &#8212; {trailerLength}, {trailerWeightCapacity}</span></div>
                            {isNumberNotEmpty(equipmentData.preCoolReefer) ? (
                                <div style={{ paddingLeft: 8 }}><span style={{ fontSize: 14, color: '#A9A9A9' }}>Pre-Cool Temp: {stringFormatter.toFormattedString('Temperature', equipmentData.preCoolReefer, equipmentData.preCoolReeferUnit)}</span></div>
                            ) : null}
                            {(isNumberNotEmpty(equipmentData.minRunTemp) && isNumberNotEmpty(equipmentData.maxRunTemp)) ? (
                                <div style={{ paddingLeft: 8 }}><span style={{ fontSize: 14, color: '#A9A9A9' }}>Run Temp Range: {stringFormatter.toFormattedString('Temperature', equipmentData.minRunTemp, equipmentData.minRunTempUnit)} - {stringFormatter.toFormattedString('Temperature', equipmentData.maxRunTemp, equipmentData.maxRunTempUnit)}</span></div>
                            ) : null}
                        </Col>
                    ) : null}
                    {isListNotEmpty(equipmentData.equipmentSpecifics) ? (
                        <Col span={24}>
                            <div style={{ marginBottom: 12 }}><span style={{ fontSize: 14, color: '#000000' }}>Additional Equipment and Services</span></div>
                            <DataListRow style={{ paddingLeft: 8 }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} data={equipmentData.equipmentSpecifics} dataType="EquipmentSpecific" />
                        </Col>
                    ) : null}
                    {isListNotEmpty(equipmentData.driverRequirements) ? (
                        <Col span={24}>
                            <div style={{ marginBottom: 12 }}><span style={{ fontSize: 14, color: '#000000' }}>Driver Requirements</span></div>
                            <DataListRow style={{ paddingLeft: 8 }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} data={equipmentData.driverRequirements} dataType="DriverRequirement" />
                        </Col>
                    ) : null}
                </Row>
            );
        } else {
            return null;
        }
    };

    const getStopDetails = (stop) => {
        if (isObjectNotEmpty(stop) && isObjectNotEmpty(stop.stopLocation)) {
            const stopIcon = LoadStopUtils.getStopMarkerIcon(stop);
            const appointmentTypeToShow = LoadStopUtils.getAppointmentDateTimeDisplay(stop);

            return (
                <div style={{ marginBottom: 24 }} key={`stop-details-${stop.id}`}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ width: 30, marginRight: 12 }}>
                            {isNotNullOrUndefined(stopIcon) ? (<img src={stopIcon} alt={LoadStopUtils.getStopTypeDisplay(stop)} style={{ width: 30, height: 30 }} />) : null}
                        </div>
                        <div style={{ flex: 1 }}>
                            <div><span style={{ fontWeight: 'bold' }}>{LoadStopUtils.getStopLocationCityStateDisplay(stop)}</span></div>
                            {appointmentTypeToShow}
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };

    const getRouteData = () => {
        if (isListNotEmpty(pickUpStops) && isListNotEmpty(dropOffStops)) {

            return (
                <div>
                    <Row style={{ marginTop: 24 }}>
                        <Col span={12}>
                            {pickUpStops.map((stop) => {
                                return getStopDetails(stop);
                            })}
                        </Col>
                        <Col span={12}>
                            {dropOffStops.map((stop) => {
                                return getStopDetails(stop);
                            })}
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: 24 }}>
                        <Col span={12}>
                            <DataRow title="Total Stops" valueStyle={{ fontWeight: 'bold' }} value={pickUpStops.length + dropOffStops.length} />
                        </Col>
                        <Col span={12}>
                            {isNumberNotEmpty(practicalRouteMiles) ? <DataRow title="Trip Miles" valueStyle={{ fontWeight: 'bold' }} value={practicalRouteMiles} units="MI" dataType="Distance" /> : null}
                        </Col>
                    </Row>
                </div>
            );
        } else {
            return null;
        }
    };

    const getStopsData = () => {
        if (isListNotEmpty(pickUpStops) && isListNotEmpty(dropOffStops)) {
            return (
                <Row gutter={[12, 12]}>
                    {pickUpStops.map((stop, index, arr) => {
                        return (
                            <Col span={24} key={`pick-up-stop-details-${index}`}>
                                <div style={{ marginBottom: 12 }}><span style={{ fontSize: 14, color: '#000000' }}>{`Details for Pick-Up${arr.length > 1 ? ' ' + (stop.stopTypeSequence + 1) : ''}`}</span></div>
                                {isListNotEmpty(stop.bolNumbers) ? <DataListRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} oneLine={true} title="BOL Number(s)" data={stop.bolNumbers} dataType="String" /> : null}
                                {isStringNotEmpty(stop.pickUpNumber) ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Pick-Up Number" value={stop.pickUpNumber} dataType="String" /> : null}
                                {isStringNotEmpty(stop.loadingType) ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Loading Type" value={stop.loadingType} dataType="LoadingType" /> : null}
                                {isBooleanTrue(stop.hasLumperFee) ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Lumper Fee" value={`Yes, ${stringFormatter.toFormattedMoney(stop.lumperFee)}`} dataType="String" /> : null}
                                {isBooleanTrue(stop.hasDriverAssist) ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Driver Assist" value={`Yes, ${stringFormatter.toFormattedMoney(stop.driverAssist)}`} dataType="String" /> : null}
                                {isStringNotEmpty(stop.specialInstructions) ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Special Instructions" value={stop.specialInstructions} dataType="String" /> : null}
                                {isListNotEmpty(stop.commodities) ? <DataCommodityListRow containerStyle={{ paddingLeft: 8 }} style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#000000' }} nameStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Commodities" data={stop.commodities} /> : null}
                            </Col>
                        );
                    })}
                    {dropOffStops.map((stop, index, arr) => {
                        return (
                            <Col span={24} key={`drop-off-stop-details-${index}`}>
                                <div style={{ marginBottom: 12 }}><span style={{ fontSize: 14, color: '#000000' }}>{`Details for Drop-Off${arr.length > 1 ? ' ' + (stop.stopTypeSequence + 1) : ''}`}</span></div>
                                {isListNotEmpty(stop.bolNumbers) ? <DataListRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} oneLine={true} title="BOL Number(s)" data={stop.bolNumbers} dataType="String" /> : null}
                                {isStringNotEmpty(stop.dropOffNumber) ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Drop-Off Number" value={stop.dropOffNumber} dataType="String" /> : null}
                                {isStringNotEmpty(stop.purchaseOrderNumber) ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Customer PO Number" value={stop.purchaseOrderNumber} dataType="String" /> : null}
                                {isStringNotEmpty(stop.loadingType) ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Unloading Type" value={stop.loadingType} dataType="UnloadingType" /> : null}
                                {isBooleanTrue(stop.hasLumperFee) ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Lumper Fee" value={`Yes, ${stringFormatter.toFormattedMoney(stop.lumperFee)}`} dataType="String" /> : null}
                                {isBooleanTrue(stop.hasDriverAssist) ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Driver Assist" value={`Yes, ${stringFormatter.toFormattedMoney(stop.driverAssist)}`} dataType="String" /> : null}
                                {isStringNotEmpty(stop.specialInstructions) ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Special Instructions" value={stop.specialInstructions} dataType="String" /> : null}
                                {isListNotEmpty(stop.commodities) ? <DataCommodityListRow containerStyle={{ paddingLeft: 8 }} style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#000000' }} nameStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Commodities" data={stop.commodities} /> : null}
                            </Col>
                        );
                    })}
                </Row>
            );
        } else {
            return null;
        }
    };

    const getLoadData = () => {
        if (isObjectNotEmpty(servicesData)) {
            return (
                <>
                    {isStringNotEmpty(servicesData.parentName) ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title={hideLoadSpecificInformation === true ? "Bundle Name" : "Load Name"} value={servicesData.parentName} dataType="String" /> : null}
                    {isStringNotEmpty(servicesData.customerOrderNumber) ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Customer Order Number" value={servicesData.customerOrderNumber} dataType="String" /> : null}
                    {entityType === "STAFF" && isStringNotEmpty(servicesData.serviceType) ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Service Type" value={servicesData.serviceType} dataType="LoadServiceType" /> : null}
                </>
            );
        } else {
            return null;
        }
    };

    const getPricingData = () => {
        if (isObjectNotEmpty(servicesData)) {
            return (
                <div>
                    {invoiceLineItems.filter(i => i.fromEntityType === 'SHIPPER' && Enums.StopFees.doesNameExist(i.itemType)).length > 0 && isNumberGreaterThanZero(servicesData.totalAddOnCharge) ? (
                        <>
                            <div style={{ marginBottom: 12 }}><span style={{ fontSize: 14, color: '#000000' }}>Stop Fees</span></div>

                            {invoiceLineItems.filter(i => i.fromEntityType === 'SHIPPER' && Enums.StopFees.doesNameExist(i.itemType)).map((lineItem, index) => {
                                return (
                                    <DataRow key={`subtotal-lineitem-${index}`} style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title={lineItem.description} value={lineItem.totalAmount} dataType="Money" />
                                );
                            })}
                            {isNumberGreaterThanZero(servicesData.totalAddOnCharge) ? (
                                <div style={{ paddingLeft: 8, marginBottom: 12 }}><span style={{ fontSize: 14, color: '#000000' }}><i>Subtotal: {stringFormatter.toFormattedMoney(servicesData.totalAddOnCharge)}</i></span></div>
                            ) : null}
                        </>
                    ) : null}

                    {(entityType === "STAFF" || entityType === "BROKER" || (entityType === "CARRIER" && servicesData.serviceType === 'TMS')) && isNumberGreaterThanZero(servicesData.shipperAmount) ? (
                        <div style={{ marginBottom: 12 }}><span style={{ fontWeight: 500, fontSize: 14, color: '#000000' }}>Total Charge to the Shipper: {stringFormatter.toFormattedMoney(servicesData.shipperAmount)}</span></div>
                    ) : null}
                    {(entityType === "STAFF" || entityType === "BROKER" || (entityType === "SHIPPER" && servicesData.serviceType === 'TMS')) && isNumberGreaterThanZero(servicesData.carrierAmount) ? (
                        <div style={{ marginBottom: 12 }}><span style={{ fontWeight: 500, fontSize: 14, color: '#000000' }}>Total Pay to the Carrier: {stringFormatter.toFormattedMoney(servicesData.carrierAmount)}</span></div>
                    ) : null}
                    {(entityType === "SHIPPER" && servicesData.serviceType === 'BROKERAGE') && isNumberGreaterThanZero(servicesData.shipperAmount) ? (
                        <div style={{ marginBottom: 12 }}><span style={{ fontWeight: 500, fontSize: 14, color: '#000000' }}>Total Pay to the IRIS Freight: {stringFormatter.toFormattedMoney(servicesData.shipperAmount)}</span></div>
                    ) : null}
                    {entityType === "STAFF" && isNumberGreaterThanZero(servicesData.IRISProfit) ? (
                        <div style={{ marginBottom: 12 }}><span style={{ fontWeight: 500, fontSize: 14, color: '#000000' }}>IRIS Profit: {stringFormatter.toFormattedMoney(servicesData.IRISProfit)}</span></div>
                    ) : null}
                    {entityType === "STAFF" && isNumberGreaterThanZero(servicesData.commissionPercentage) ? (
                        <div style={{ marginBottom: 12 }}><span style={{ fontWeight: 500, fontSize: 14, color: '#000000' }}>Commission: {stringFormatter.toFormattedPercentage(servicesData.commissionPercentage)}</span></div>
                    ) : null}
                </div>
            );
        } else {
            return null;
        }
    };

    //#endregion
    //#region onChanges

    const onChangeStep = (key) => {
        // console.log(`step key: ${key}`);
        setCurrent(key);
        window.scrollTo(0, 0);

        if (key === 'schedule') {
            setCurrentStepNumber(0);
        } else if (key === 'equipment') {
            setCurrentStepNumber(1);
        } else if (key === 'services') {
            setCurrentStepNumber(2);
        } else if (key === 'review') {
            setCurrentStepNumber(3);
        } else {
            setCurrentStepNumber(- 1);
        }
    };

    const onChangeStepNumber = (step) => {
        // console.log(`step number: ${step}`);
        if (step === 0) {
            setCurrent('schedule');
        } else if (step === 1) {
            setCurrent('equipment');
        } else if (step === 2) {
            setCurrent('services');
        } else if (step === 3) {
            setCurrent('review');
        } else {
            setCurrent(null);
        }

        setCurrentStepNumber(step);
        window.scrollTo(0, 0);
    };

    //#endregion

    return (
        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true && error === null) || isStepsLoading === true}>
            <Row gutter={[4, 4]}>
                <Col xs={0} sm={0} md={0} lg={{ span: 4, offset: 1 }}>
                    <Affix offsetTop={44} style={{ position: 'absolute', marginTop: 92 }}>
                        <div>
                            <Steps size="default" direction="vertical" current={currentStepNumber} onChange={onChangeStepNumber}>
                                <Steps.Step title="Route" />
                                <Steps.Step title="Equipment" disabled={completedSteps.schedule === false} />
                                <Steps.Step title={CanDo({ entityTypes: ['CARRIER'] }) ? "Invoice" : "Price & Tender"} disabled={completedSteps.equipment === false} />
                                {/* <Steps.Step title="Review" disabled={completedSteps.services === false} /> */}
                                <Steps.Step title="Review" />
                            </Steps>
                        </div>
                    </Affix>
                </Col>
                <Col xs={24} sm={24} md={16} lg={12}>
                    <Row gutter={[4, 4]}>
                        <Col span={24}>
                            <Header
                                title="New Load"
                                icon={<FontAwesomeIcon className="anticon" icon={faPallet} />}
                                title2={isObjectNotEmpty(shipperData) && isStringNotEmpty(shipperData.shipperName) ? (<Button type="link" style={{ paddingLeft: 0 }} target='_blank' onClick={(e) => {
                                    e.stopPropagation();
                                    if (entityType === 'STAFF') {
                                        window.open(`/accounts/${shipperData.shipperId}`, "_blank");
                                    } else if (entityType === 'SHIPPER') {
                                        window.open(`/dashboard`, "_blank");
                                    } else if (entityType === 'CARRIER' || entityType === 'BROKER') {
                                        window.open(`/linkedAccounts/${entityId}/${shipperData.shipperId}`, "_blank");
                                    }
                                }}>{shipperData.shipperName}</Button>) : 'TBD'}
                                title2Icon={<FontAwesomeIcon className="anticon" icon={faTruckLoading} />}
                                title3={(CanDo({ entityTypes: ['CARRIER', 'STAFF', 'BROKER'] }) || (isObjectNotEmpty(servicesData) && isStringNotEmpty(servicesData.serviceType) && servicesData.serviceType === 'TMS')) && isObjectNotEmpty(carrier) ? (<Button type="link" style={{ paddingLeft: 0 }} onClick={(e) => {
                                    e.stopPropagation();
                                    if (entityType === 'STAFF') {
                                        window.open(`/accounts/${carrier.id}`, "_blank");
                                    } else if (entityType === 'CARRIER') {
                                        window.open(`/dashboard`, "_blank");
                                    } else if (entityType === 'SHIPPER' || entityType === 'BROKER') {
                                        window.open(`/linkedAccounts/${entityId}/${carrier.id}`, "_blank");
                                    }
                                }}>{carrier.name}</Button>) : 'TBD'}
                                title3Icon={<FontAwesomeIcon className="anticon" icon={faTruck} />}
                                title4={isObjectNotEmpty(loadLane) ? (isStringNotEmpty(loadLane.customerLaneId) ? loadLane.customerLaneId : loadLane.irisId) : null}
                                title4Icon={<FontAwesomeIcon className="anticon" icon={faRoute} />}
                            />
                        </Col>
                    </Row>
                    {(current === 'schedule') ? (
                        <NewLoadSchedule
                            shipperData={shipperData}
                            setShipperData={setShipperData}
                            pickUpStops={pickUpStops}
                            setPickUpStops={setPickUpStops}
                            dropOffStops={dropOffStops}
                            setDropOffStops={setDropOffStops}
                            completedSteps={completedSteps}
                            setCompletedSteps={setCompletedSteps}
                            nextStepAction={onChangeStep}
                            nextStepActionName="Continue to Equipment"
                            cancelButton={
                                <Popconfirm
                                    title="Are you sure you want to cancel the creation of this load? You will lose your changes."
                                    onConfirm={confirmCancel}
                                    onCancel={cancelCancel}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button danger={true} type="link" block>Cancel Load Creation</Button>
                                </Popconfirm>
                            }
                            docFiles={docFiles}
                            setDocFiles={setDocFiles}
                            dontRequireLoadSpecificInformation={dontRequireLoadSpecificInformation}
                            hideLoadSpecificInformation={hideLoadSpecificInformation}
                        />
                    ) : null}
                    {(current === 'equipment') ? (
                        <NewLoadEquipment
                            equipmentData={equipmentData}
                            setEquipmentData={setEquipmentData}
                            completedSteps={completedSteps}
                            setCompletedSteps={setCompletedSteps}
                            previousStepAction={onChangeStep}
                            previousStepActionName="Back to Route"
                            nextStepAction={onChangeStep}
                            nextStepActionName={(entityType === 'STAFF' || entityType === 'SHIPPER') ? "Continue to Price & Tender" : "Continue to Invoice"}
                        />
                    ) : null}
                    {(current === 'services') ? (
                        <NewLoadServices
                            servicesData={servicesData}
                            setServicesData={setServicesData}
                            completedSteps={completedSteps}
                            setCompletedSteps={setCompletedSteps}
                            previousStepAction={onChangeStep}
                            previousStepActionName="Back to Equipment"
                            nextStepAction={onChangeStep}
                            nextStepActionName="Review Load Summary"
                            pickUpStops={pickUpStops}
                            dropOffStops={dropOffStops}
                            equipmentData={equipmentData}
                            practicalRouteMiles={practicalRouteMiles}
                            setInvoiceLineItems={setInvoiceLineItems}
                            setCarrier={setCarrier}
                            dontRequireLoadSpecificInformation={dontRequireLoadSpecificInformation}
                            hideLoadSpecificInformation={hideLoadSpecificInformation}
                        />
                    ) : null}
                    {(current === 'review') ? (
                        <NewLoadReview
                            shipperData={shipperData}
                            pickUpStops={pickUpStops}
                            dropOffStops={dropOffStops}
                            equipmentData={equipmentData}
                            servicesData={servicesData}
                            previousStepAction={onChangeStep}
                            previousStepActionName={(entityType === 'STAFF' || entityType === 'SHIPPER') ? "Back to Price & Tender" : "Back to Invoice"}
                            nextStepAction={onSubmit}
                            nextStepActionName="Create Load"
                            practicalRouteMiles={practicalRouteMiles}
                            invoiceLineItems={invoiceLineItems}
                            hideLoadSpecificInformation={hideLoadSpecificInformation}
                        />
                    ) : null}

                </Col>
                <Col xs={0} sm={0} md={8} lg={7}>
                    <FormProvider {...methods}>
                        <Form onSubmit={methods.handleSubmit(onSubmit)}>
                            <Affix offsetTop={4}>
                                <div className={classes.loadSummaryContainer}>
                                    <Row gutter={[12, 12]} className={classes.loadSummary}>
                                        <Col span={24}>
                                            <Card title={(isNumberNotEmpty(bulkCount) && bulkCount > 1) ? `Load Summary (x ${bulkCount})` : "Load Summary"} headStyle={{ textAlign: 'center', fontSize: 20 }} size="small" bordered={false}>
                                                <MapWrapper
                                                    height={'300px'}
                                                    key={`load-creation-map-key`}
                                                    loadId={'new-load'}
                                                    stops={isListNotEmpty(pickUpStops) && isListNotEmpty(dropOffStops) ? [...pickUpStops, ...dropOffStops] : []}
                                                    containerName={`load-creation-map`}
                                                    style={{ width: "100%", height: "300px", margin: '0px auto' }}
                                                    setRouteDistance={setPracticalRouteMiles}
                                                    setRouteDuration={setPracticalRouteMinutes}
                                                />

                                                {(completedSteps.schedule === true) && getRouteData()}
                                                <Collapse expandIconPosition={'right'} style={{ marginLeft: '-12px', marginRight: '-12px', marginTop: 24, marginBottom: 24, borderLeft: 0, borderRight: 0 }}>
                                                    {(completedSteps.schedule === true) ? (
                                                        <Panel style={{ background: '#ffffff' }} header={<span style={{ fontWeight: 'bold', fontSize: 14 }}>Stop Details</span>} key="stops">
                                                            {getStopsData()}
                                                        </Panel>
                                                    ) : null}
                                                    {(completedSteps.equipment === true) ? (
                                                        <Panel style={{ background: '#ffffff' }} header={<span style={{ fontWeight: 'bold', fontSize: 14 }}>Equipment Needs & Services</span>} key="equipment">
                                                            {getEquipmentData()}
                                                        </Panel>
                                                    ) : null}
                                                    {(completedSteps.services === true) ? (
                                                        <>
                                                            <Panel style={{ background: '#ffffff' }} header={<span style={{ fontWeight: 'bold', fontSize: 14 }}>Load Information</span>} key="loadInformation">
                                                                {getLoadData()}
                                                            </Panel>
                                                            <Panel style={{ background: '#ffffff' }} header={(entityType === 'SHIPPER' || entityType === 'STAFF') ? <span style={{ fontWeight: 'bold', fontSize: 14 }}>Pricing & Tender</span> : <span style={{ fontWeight: 'bold', fontSize: 14 }}>Invoice</span>} key="services">
                                                                {getPricingData()}
                                                            </Panel>
                                                        </>
                                                    ) : null}
                                                </Collapse>
                                                <ErrorMessage
                                                    errors={methods.errors}
                                                    name="stops"
                                                    render={({ message, messages }) => {
                                                        if (isNotNullOrUndefined(messages)) {
                                                            return Object.entries(messages).map(([type, message]) => (<Alert key={`stops-${type}`} message={message} type="error" />));
                                                        } else if (isStringNotEmpty(message)) {
                                                            return <Alert message={message} type="error" />;
                                                        } else {
                                                            return null;
                                                        }
                                                    }}
                                                />
                                                {error && <Alert message={`${error}`} type="error" />}
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row gutter={[12, 12]} className={classes.loadSummaryButtonContainer}>
                                        <Col xs={{ span: 24, order: 2 }} xl={{ span: 12, order: 1 }}>
                                            <Popconfirm
                                                title="Are you sure you want to cancel the creation of this load? You will lose your changes."
                                                onConfirm={confirmCancel}
                                                onCancel={cancelCancel}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <Button type="danger" block>Cancel</Button>
                                            </Popconfirm>
                                        </Col>
                                        <Col xs={{ span: 24, order: 1 }} xl={{ span: 12, order: 2 }}>
                                            <Button type="primary" block htmlType="submit" disabled={completedSteps.schedule === false || completedSteps.equipment === false || completedSteps.services === false}>Create Load</Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Affix>
                        </Form>
                    </FormProvider>
                </Col>
            </Row>
        </Spin>
    );
};

export default withRouter(NewLoad);