import * as actionTypes from "../actions/actionTypes";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { isBooleanTrue, isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import ErrorUtils from "../../shared/errorUtils";
import * as actionCreators from "./index";

//#region Fetch Linked Load Lane List Methods

export const fetchLinkedLoadLaneListStart = (listName) => {
    return {
        type: actionTypes.FETCH_LINKED_LOAD_LANE_LIST_START,
        payload: { listName: listName }
    }
};

export const fetchLinkedLoadLaneListSuccess = (listName, payload) => {
    return {
        type: actionTypes.FETCH_LINKED_LOAD_LANE_LIST_SUCCESS,
        payload: { listName: listName, ...payload }
    }
};

export const fetchLinkedLoadLaneListFail = (listName, payload) => {
    return {
        type: actionTypes.FETCH_LINKED_LOAD_LANE_LIST_FAIL,
        payload: { listName: listName, ...payload }
    }
};

export const clearLinkedLoadLaneList = (listName) => {
    return {
        type: actionTypes.CLEAR_LINKED_LOAD_LANE_LIST,
        payload: { listName: listName }
    }
};

export const clearLinkedLoadLaneLists = () => {
    return {
        type: actionTypes.CLEAR_LINKED_LOAD_LANE_LISTS
    }
};

export const addLinkedLoadLaneToLinkedLoadLaneList = (listName, payload) => {
    return {
        type: actionTypes.ADD_TO_LINKED_LOAD_LANE_LIST,
        payload: { listName: listName, newRecord: payload }
    }
};

export const updateLinkedLoadLaneInLinkedLoadLaneList = (listName, payload) => {
    return {
        type: actionTypes.UPDATE_IN_LINKED_LOAD_LANE_LIST,
        payload: { listName: listName, updatedRecord: payload }
    }
};

export const removeLinkedLoadLaneFromLinkedLoadLaneList = (listName, payload) => {
    return {
        type: actionTypes.REMOVE_FROM_LINKED_LOAD_LANE_LIST,
        payload: { listName: listName, recordToRemove: payload }
    }
};

//#endregion
//#region Fetch Linked Load Lane Methods

export const fetchLinkedLoadLaneStart = () => {
    return {
        type: actionTypes.FETCH_LINKED_LOAD_LANE_START
    }
};

export const fetchLinkedLoadLaneSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_LINKED_LOAD_LANE_SUCCESS,
        payload: payload
    }
};

export const fetchLinkedLoadLaneFail = (payload) => {
    return {
        type: actionTypes.FETCH_LINKED_LOAD_LANE_FAIL,
        payload: payload
    }
};

export const clearLinkedLoadLane = () => {
    return {
        type: actionTypes.CLEAR_LINKED_LOAD_LANE
    }
};

//#endregion
//#region Add Linked Load Lane Methods

export const addLinkedLoadLaneStart = () => {
    return {
        type: actionTypes.ADD_LINKED_LOAD_LANE_START
    }
};

export const addLinkedLoadLaneSuccess = () => {
    return {
        type: actionTypes.ADD_LINKED_LOAD_LANE_SUCCESS
    }
};

export const addLinkedLoadLaneFail = (payload) => {
    return {
        type: actionTypes.ADD_LINKED_LOAD_LANE_FAIL,
        payload: payload
    }
};

export const addLinkedLoadLaneLoadingClear = () => {
    return {
        type: actionTypes.ADD_LINKED_LOAD_LANE_LOADING_CLEAR
    }
};

export const addLinkedLoadLaneErrorClear = () => {
    return {
        type: actionTypes.ADD_LINKED_LOAD_LANE_ERROR_CLEAR
    }
};

export const addLinkedLoadLaneCancel = () => {
    return {
        type: actionTypes.ADD_LINKED_LOAD_LANE_CANCEL
    }
};

//#endregion
//#region Update Linked Load Lane Methods

const changeSingleLinkedLoadLane = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_LINKED_LOAD_LANE,
        payload: payload
    }
};

export const updateLinkedLoadLaneStart = () => {
    return {
        type: actionTypes.UPDATE_LINKED_LOAD_LANE_START
    }
};

export const updateLinkedLoadLaneSuccess = () => {
    return {
        type: actionTypes.UPDATE_LINKED_LOAD_LANE_SUCCESS
    }
};

export const updateLinkedLoadLaneFail = (payload) => {
    return {
        type: actionTypes.UPDATE_LINKED_LOAD_LANE_FAIL,
        payload: payload
    }
};

export const updateLinkedLoadLaneLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_LINKED_LOAD_LANE_LOADING_CLEAR
    }
};

export const updateLinkedLoadLaneErrorClear = () => {
    return {
        type: actionTypes.UPDATE_LINKED_LOAD_LANE_ERROR_CLEAR
    }
};

export const updateLinkedLoadLaneCancel = () => {
    return {
        type: actionTypes.UPDATE_LINKED_LOAD_LANE_CANCEL
    }
};

//#endregion
//#region Linked Load Lanes Methods

export const fetchLinkedLoadLaneList = (listName, payload, accountId = null, linkedAccountId = null) => {
    return async (dispatch, getState) => {
        if (isStringNotEmpty(listName)) {
            try {
                dispatch(fetchLinkedLoadLaneListStart(listName));

                await dispatch(actionCreators.getAccounts());
                await dispatch(actionCreators.getTrailerTypes());
                const state = getState();
                const orchestratorState = { ...state.orchestrator };
                const accounts = [...orchestratorState.accounts];
                const trailerTypes = [...orchestratorState.trailerTypes];
                const linkedLoadLanesState = { ...state.linkedLoadLanes };
                const existingLists = { ...linkedLoadLanesState.lists };

                let pagination = {};
                let searchParams = {};
                if (isObjectNotEmpty(existingLists[listName])) {
                    pagination = { ...existingLists[listName].pagination };
                    searchParams = { ...existingLists[listName].searchParams };
                }

                if (isObjectNotEmpty(payload)) {
                    searchParams = { ...payload };
                }

                const res = await Data.getLinkedLoadLanes({ ...searchParams }, pagination, accounts, trailerTypes, accountId, linkedAccountId);
                dispatch(fetchLinkedLoadLaneListSuccess(listName, { records: res.data, params: { searchParams: res.searchParams, pagination: res.pagination } }));
            } catch (error) {
                logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
                dispatch(fetchLinkedLoadLaneListFail(listName, { error: ErrorUtils.getErrorMessage(error) }));
            }
        }
    }
};

export const fetchLinkedLoadLane = (id, accountId = null, linkedAccountId = null) => {
    return async (dispatch, getState) => {
        try {
            dispatch(fetchLinkedLoadLaneStart());

            await dispatch(actionCreators.getAccounts());
            await dispatch(actionCreators.getTrailerTypes());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const accounts = [...orchestratorState.accounts];
            const trailerTypes = [...orchestratorState.trailerTypes];

            const linkedLoadLane = await Data.getLinkedLoadLane(id, accounts, trailerTypes, accountId, linkedAccountId);
            dispatch(fetchLinkedLoadLaneSuccess({ record: linkedLoadLane }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchLinkedLoadLaneFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const addLinkedLoadLane = (payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(addLinkedLoadLaneStart());

            await dispatch(actionCreators.getAccounts());
            await dispatch(actionCreators.getTrailerTypes());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const accounts = [...orchestratorState.accounts];
            const trailerTypes = [...orchestratorState.trailerTypes];

            const newLinkedLoadLane = await Data.addLinkedLoadLane(payload, accounts, trailerTypes);
            if (isObjectNotEmpty(newLinkedLoadLane)) {
                dispatch(addLinkedLoadLaneToLinkedLoadLaneList(`${payload.accountId}_${payload.linkedAccountId}`, newLinkedLoadLane));
                dispatch(addLinkedLoadLaneToLinkedLoadLaneList(`${payload.accountId}_ALL`, newLinkedLoadLane));

                // refresh location orchestrator
                dispatch(actionCreators.getLocations(payload.accountId, true));
            }

            dispatch(addLinkedLoadLaneSuccess());
            dispatch(addLinkedLoadLaneLoadingClear());
            dispatch(addLinkedLoadLaneErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addLinkedLoadLaneFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateLinkedLoadLane = (id, payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updateLinkedLoadLaneStart());

            if (isBooleanTrue(payload.isDeleted)) {
                const removedLinkedLoadLane = await Data.removeLinkedLoadLane(id);
                if (isObjectNotEmpty(removedLinkedLoadLane)) {
                    dispatch(removeLinkedLoadLaneFromLinkedLoadLaneList(`${removedLinkedLoadLane.accountId}_${removedLinkedLoadLane.linkedAccountId}`, removedLinkedLoadLane));
                    dispatch(removeLinkedLoadLaneFromLinkedLoadLaneList(`${removedLinkedLoadLane.accountId}_ALL`, removedLinkedLoadLane));
                }
            } else {
                await dispatch(actionCreators.getAccounts());
                await dispatch(actionCreators.getTrailerTypes());
                const state = getState();
                const orchestratorState = { ...state.orchestrator };
                const accounts = [...orchestratorState.accounts];
                const trailerTypes = [...orchestratorState.trailerTypes];

                const updatedLinkedLoadLane = await Data.updateLinkedLoadLane(id, payload, accounts, trailerTypes);
                if (isObjectNotEmpty(updatedLinkedLoadLane)) {
                    dispatch(updateLinkedLoadLaneInLinkedLoadLaneList(`${payload.accountId}_${updatedLinkedLoadLane.linkedAccountId}`, updatedLinkedLoadLane));
                    dispatch(updateLinkedLoadLaneInLinkedLoadLaneList(`${payload.accountId}_ALL`, updatedLinkedLoadLane));
                    dispatch(changeSingleLinkedLoadLane(updatedLinkedLoadLane));
                }
            }

            dispatch(updateLinkedLoadLaneSuccess());
            dispatch(updateLinkedLoadLaneLoadingClear());
            dispatch(updateLinkedLoadLaneErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateLinkedLoadLaneFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion