import { isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import EntityUtils from '../entityUtils';

export const getPreferredLaneDTO = (preferredLane, accounts = [], trailerTypes = []) => {
    if (isObjectNotEmpty(preferredLane.lane) && isStringNotEmpty(preferredLane.lane.trailerTypeId)) {
        let trailerType = EntityUtils.getTrailerTypeInfo(preferredLane.lane.trailerTypeId, trailerTypes);
        preferredLane.lane.trailerType = trailerType;
        preferredLane.trailerType = trailerType;
    }

    // Get Account Info
    preferredLane.account = EntityUtils.getAccountInfo(preferredLane.accountId, accounts);

    return preferredLane;
};