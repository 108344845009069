import React, { useMemo } from 'react';
import './App.scss';
import DrawerLayout from "./hoc/DrawerLayout/DrawerLayout";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Login from "./views/Login/Login";
import Register from "./views/Register/Register";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "./store/actions/index";
import Track from './views/Track/Track';
import Map from './views/Map/Map';
import ForgotPassword from './views/ForgotPassword/ForgotPassword';
import ResetPassword from './views/ResetPassword/ResetPassword';
import ResetPasswordConfirmation from './views/ResetPasswordConfirmation/ResetPasswordConfirmation';
import Terms from "./views/Terms/Terms";
import Invoice from './views/Invoice/Invoice';

const ProtectedRoute = ({ isAuthenticated, component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated === true ? (
                    <Component {...props} />
                ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location }
                            }}
                        />
                    )
            }
        />
    );
};

const App = () => {

    const dispatch = useDispatch();
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const isStaff = useSelector(state => state.auth.isStaff);
    const entityType = useSelector(state => state.auth.entityType);

    useMemo(() => {
        // Auto Login if Token is Available
        // console.log('initiate auto login');
        dispatch(actionCreators.autoLogin());
    }, []);

    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/forgotPassword" component={ForgotPassword} />
                <Route exact path="/resetPassword/:uuid/:token/:entityType?" component={ResetPassword} />
                <Route exact path="/resetPasswordConfirmation/:entityType?" component={ResetPasswordConfirmation} />
                <Route exact path="/track/:loadId" component={Track} />
                <Route exact path="/map/:loadId" component={Map} />
                <Route exact path="/terms" component={Terms} />
                
                <ProtectedRoute exact isAuthenticated={isAuthenticated === true} path="/invoice/:invoiceId" component={Invoice} />
                <Route path="/" component={DrawerLayout} />
            </Switch>
        </BrowserRouter>
    );
};

export default App;