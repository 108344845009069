import * as actionTypes from "../actions/actionTypes";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import ErrorUtils from "../../shared/errorUtils";
import * as actionCreators from "./index";

//#region Accounts Pending Verification Methods

export const fetchAccountsPendingVerificationStart = () => {
    return {
        type: actionTypes.FETCH_ACCOUNTS_PENDING_VERIFICATION_START
    }
};

export const fetchAccountsPendingVerificationSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_ACCOUNTS_PENDING_VERIFICATION_SUCCESS,
        payload: payload
    }
};

export const fetchAccountsPendingVerificationFail = (payload) => {
    return {
        type: actionTypes.FETCH_ACCOUNTS_PENDING_VERIFICATION_FAIL,
        payload: payload
    }
};

export const clearAccountsPendingVerification = () => {
    return {
        type: actionTypes.CLEAR_ACCOUNTS_PENDING_VERIFICATION
    }
};

//#endregion
//#region Drivers Missing Documents Methods

export const fetchDriversMissingDocumentsStart = () => {
    return {
        type: actionTypes.FETCH_DRIVERS_MISSING_DOCUMENTS_START
    }
};

export const fetchDriversMissingDocumentsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_DRIVERS_MISSING_DOCUMENTS_SUCCESS,
        payload: payload
    }
};

export const fetchDriversMissingDocumentsFail = (payload) => {
    return {
        type: actionTypes.FETCH_DRIVERS_MISSING_DOCUMENTS_FAIL,
        payload: payload
    }
};

export const clearDriversMissingDocuments = () => {
    return {
        type: actionTypes.CLEAR_DRIVERS_MISSING_DOCUMENTS
    }
};

//#endregion
//#region Drivers Pending Approval Methods

export const fetchDriversPendingApprovalStart = () => {
    return {
        type: actionTypes.FETCH_DRIVERS_PENDING_APPROVAL_START
    }
};

export const fetchDriversPendingApprovalSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_DRIVERS_PENDING_APPROVAL_SUCCESS,
        payload: payload
    }
};

export const fetchDriversPendingApprovalFail = (payload) => {
    return {
        type: actionTypes.FETCH_DRIVERS_PENDING_APPROVAL_FAIL,
        payload: payload
    }
};

export const clearDriversPendingApproval = () => {
    return {
        type: actionTypes.CLEAR_DRIVERS_PENDING_APPROVAL
    }
};

//#endregion
//#region Drivers With Expiring Licenses Methods

export const fetchDriversWithExpiringLicensesStart = () => {
    return {
        type: actionTypes.FETCH_DRIVERS_WITH_EXPIRING_LICENSES_START
    }
};

export const fetchDriversWithExpiringLicensesSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_DRIVERS_WITH_EXPIRING_LICENSES_SUCCESS,
        payload: payload
    }
};

export const fetchDriversWithExpiringLicensesFail = (payload) => {
    return {
        type: actionTypes.FETCH_DRIVERS_WITH_EXPIRING_LICENSES_FAIL,
        payload: payload
    }
};

export const clearDriversWithExpiringLicenses = () => {
    return {
        type: actionTypes.CLEAR_DRIVERS_WITH_EXPIRING_LICENSES
    }
};

//#endregion
//#region In-Transit Loads Without Location Data Methods

export const fetchInTransitLoadsWithoutLocationDataStart = () => {
    return {
        type: actionTypes.FETCH_IN_TRANSIT_LOADS_WITHOUT_LOCATION_DATA_START
    }
};

export const fetchInTransitLoadsWithoutLocationDataSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_IN_TRANSIT_LOADS_WITHOUT_LOCATION_DATA_SUCCESS,
        payload: payload
    }
};

export const fetchInTransitLoadsWithoutLocationDataFail = (payload) => {
    return {
        type: actionTypes.FETCH_IN_TRANSIT_LOADS_WITHOUT_LOCATION_DATA_FAIL,
        payload: payload
    }
};

export const clearInTransitLoadsWithoutLocationData = () => {
    return {
        type: actionTypes.CLEAR_IN_TRANSIT_LOADS_WITHOUT_LOCATION_DATA
    }
};

//#endregion
//#region Critical Loads Methods

export const fetchCriticalLoadsStart = () => {
    return {
        type: actionTypes.FETCH_CRITICAL_LOADS_START
    }
};

export const fetchCriticalLoadsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_CRITICAL_LOADS_SUCCESS,
        payload: payload
    }
};

export const fetchCriticalLoadsFail = (payload) => {
    return {
        type: actionTypes.FETCH_CRITICAL_LOADS_FAIL,
        payload: payload
    }
};

export const clearCriticalLoads = () => {
    return {
        type: actionTypes.CLEAR_CRITICAL_LOADS
    }
};

//#endregion
//#region Fetch Metrics Methods

export const fetchMetricsStart = () => {
    return {
        type: actionTypes.FETCH_METRICS_START
    }
};

export const fetchMetricsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_METRICS_SUCCESS,
        payload: payload
    }
};

export const fetchMetricsFail = (payload) => {
    return {
        type: actionTypes.FETCH_METRICS_FAIL,
        payload: payload
    }
};

export const clearMetrics = () => {
    return {
        type: actionTypes.CLEAR_METRICS
    }
};

//#endregion
//#region Metrics Methods

export const fetchAccountsPendingVerification = (payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(fetchAccountsPendingVerificationStart());

            const state = getState();
            const metricsState = { ...state.metrics };
            let searchParams = { ...metricsState.accountsPendingVerificationSearchParams };
            let pagination = { ...metricsState.accountsPendingVerificationPagination };

            if (isObjectNotEmpty(payload)) {
                searchParams = { ...payload };
            }

            const res = await Data.getAccounts({ ...searchParams }, pagination);
            dispatch(fetchAccountsPendingVerificationSuccess({ accountsPendingVerification: res.data, accountsPendingVerificationSearchParams: res.searchParams, accountsPendingVerificationPagination: res.pagination }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchAccountsPendingVerificationFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const fetchDriversMissingDocuments = (payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(fetchDriversMissingDocumentsStart());

            await dispatch(actionCreators.getAccounts());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const accounts = [...orchestratorState.accounts];
            const metricsState = { ...state.metrics };
            let searchParams = { ...metricsState.driversMissingDocumentsSearchParams };
            let pagination = { ...metricsState.driversMissingDocumentsPagination };

            if (isObjectNotEmpty(payload)) {
                searchParams = { ...payload };
            }

            const res = await Data.getAccountUsers({ ...searchParams }, pagination, accounts);
            dispatch(fetchDriversMissingDocumentsSuccess({ driversMissingDocuments: res.data, driversMissingDocumentsSearchParams: res.searchParams, driversMissingDocumentsPagination: res.pagination }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchDriversMissingDocumentsFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const fetchDriversPendingApproval = (payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(fetchDriversPendingApprovalStart());

            await dispatch(actionCreators.getAccounts());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const accounts = [...orchestratorState.accounts];
            const metricsState = { ...state.metrics };
            let pagination = { ...metricsState.driversPendingApprovalPagination };
            let searchParams = { ...metricsState.driversPendingApprovalSearchParams };

            if (isObjectNotEmpty(payload)) {
                searchParams = { ...payload };
            }

            const res = await Data.getAccountUsers({ ...searchParams }, pagination, accounts);
            dispatch(fetchDriversPendingApprovalSuccess({ driversPendingApproval: res.data, driversPendingApprovalSearchParams: res.searchParams, driversPendingApprovalPagination: res.pagination }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchDriversPendingApprovalFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const fetchDriversWithExpiringLicenses = (payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(fetchDriversWithExpiringLicensesStart());

            await dispatch(actionCreators.getAccounts());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const accounts = [...orchestratorState.accounts];
            const metricsState = { ...state.metrics };
            let pagination = { ...metricsState.driversWithExpiringLicensesPagination };
            let searchParams = { ...metricsState.driversWithExpiringLicensesSearchParams };

            if (isObjectNotEmpty(payload)) {
                searchParams = { ...payload };
            }

            const res = await Data.getAccountUsers({ ...searchParams }, pagination, accounts);
            dispatch(fetchDriversWithExpiringLicensesSuccess({ driversWithExpiringLicenses: res.data, driversWithExpiringLicensesSearchParams: res.searchParams, driversWithExpiringLicensesPagination: res.pagination }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchDriversWithExpiringLicensesFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const fetchInTransitLoadsWithoutLocationData = (payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(fetchInTransitLoadsWithoutLocationDataStart());

            const state = getState();
            const metricsState = { ...state.metrics };
            let pagination = { ...metricsState.inTransitLoadsWithoutLocationDataPagination };
            let searchParams = { ...metricsState.inTransitLoadsWithoutLocationDataSearchParams };

            if (isObjectNotEmpty(payload)) {
                searchParams = { ...payload };
            }

            const res = await Data.getInTransitLoadsWithoutLocationData({ ...searchParams }, pagination);
            dispatch(fetchInTransitLoadsWithoutLocationDataSuccess({ inTransitLoadsWithoutLocationData: res.data, inTransitLoadsWithoutLocationDataSearchParams: res.searchParams, inTransitLoadsWithoutLocationDataPagination: res.pagination }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchInTransitLoadsWithoutLocationDataFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const fetchCriticalLoads = (payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(fetchCriticalLoadsStart());

            const state = getState();
            const metricsState = { ...state.metrics };
            let pagination = { ...metricsState.criticalLoadsPagination };
            let searchParams = { ...metricsState.criticalLoadsSearchParams };

            if (isObjectNotEmpty(payload)) {
                searchParams = { ...payload };
            }

            const res = await Data.getLoads({ ...searchParams }, pagination);
            dispatch(fetchCriticalLoadsSuccess({ criticalLoads: res.data, criticalLoadsSearchParams: res.searchParams, criticalLoadsPagination: res.pagination }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchCriticalLoadsFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const fetchMetrics = (entityType, entityId = null) => {
    return async (dispatch) => {
        try {
            dispatch(fetchMetricsStart());

            let searchParams = {
                entityType: entityType
            };

            if (isStringNotEmpty(entityId)) {
                searchParams.entityId = entityId;
            }

            let metrics = await Data.getMetrics({ ...searchParams });
            if (isObjectNotEmpty(metrics)) {
                metrics.entityId = entityId;
                metrics.entityType = entityType;

                dispatch(fetchMetricsSuccess({ metrics: metrics }));
            } else {
                dispatch(fetchMetricsFail({ error: 'Failed to get metrics.' }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchMetricsFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion