import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col, Modal, Button, Drawer, Alert, Space } from "antd";
import LoadShipperCard from "../../components/LoadShipperCard/LoadShipperCard";
import LoadCarrierCard from "../../components/LoadCarrierCard/LoadCarrierCard";
import LoadDriverCard from "../../components/LoadDriverCard/LoadDriverCard";
import LoadRateCard from "../../components/LoadRateCard/LoadRateCard";
import LoadAssetsCard from "../../components/LoadAssetsCard/LoadAssetsCard";
import LoadDetailsCard from "../../components/LoadDetailsCard/LoadDetailsCard";
import LoadMap from "../../components/LoadMap/LoadMap";
import classes from './LoadDetails.module.scss';
import LoadRepresentativeCard from '../../components/LoadRepresentativeCard/LoadRepresentativeCard';
import LoadStatusCard from '../../components/LoadStatusCard/LoadStatusCard';
import DataRow from '../../components/DataRow/DataRow';
import StringFormatter from '../../shared/stringFormatter';
import DataPhoneNumberRow from '../DataPhoneNumberRow/DataPhoneNumberRow';
import DataContactInfoRow from '../DataContactInfoRow/DataContactInfoRow';
import ComplexCard from '../ComplexCard/ComplexCard';
import DataDateTimeRangeRow from '../DataDateTimeRangeRow/DataDateTimeRangeRow';
import Enums from '../../shared/enums';
import DocumentUtils from '../../api/utils/documentUtils';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from "../../store/actions/index";
import { orderBy } from 'lodash';
import { isBooleanTrue, isListEmpty, isListNotEmpty, isNotNullOrUndefined, isObjectNotEmpty, isStringEmpty, isStringNotEmpty, isNumberGreaterThanZero, isNumberNotEmpty } from '../../shared/objectUtils';
import LoadStopUtils from '../../api/utils/loadStopUtils';
import LoadUtils from '../../api/utils/loadUtils';
import { selectListRecords } from '../../store/utility';
import { CanSee } from '../../shared/entitlements/entitlements';
import LoadCompletionCard from '../LoadCompletionCard/LoadCompletionCard';
import LocationMap from '../LocationMap/LocationMap';
import LoadSendLoadFinalizationEmail from '../LoadSendLoadFinalizationEmail/LoadSendLoadFinalizationEmail';
import EditLoadEquipment from "../../components/EditLoadEquipment/EditLoadEquipment";
import DataListRow from '../../components/DataListRow/DataListRow';
import AddEditChangeRemoveButtons from '../../components/AddEditChangeRemoveButtons/AddEditChangeRemoveButtons';
import EditLoadStatusCancelled from "../../components/EditLoadStatusCancelled/EditLoadStatusCancelled";
import DataTemperatureRangeRow from '../../components/DataTemperatureRangeRow/DataTemperatureRangeRow';
import LoadCancellationList from '../../components/LoadCancellationList/LoadCancellationList';
import LoadCarrierRejectionList from '../../components/LoadCarrierRejectionList/LoadCarrierRejectionList';
import MapWrapper from '../Map/Map';
import EditLoadStatusCompleted from '../EditLoadStatusCompleted/EditLoadStatusCompleted';

const stringFormatter = new StringFormatter();

const LoadDetails = ({ loadId, load, shipperId, carrierId, serviceType, stops, claims, invoices, invoiceLineItems, transactions, loadCancellations, carrierRejections, isDeadHeading = false, currentStatus, displayStatus, currentStop, currentStopType, hasTeamDriving = false, hasCarrierWashout = false, onTabChange }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    //#endregion
    //#region useStates

    const [pendingApprovalDocuments, setPendingApprovalDocuments] = useState([]);
    const [missingDocuments, setMissingDocuments] = useState([]);
    const [missingPedigreeInformation, setMissingPedigreeInformation] = useState([]);
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showCompleted, setShowCompleted] = useState(false);
    const [showCancellation, setShowCancellation] = useState(false);
    const [showLoadCancellationsModal, setShowLoadCancellationsModal] = useState(false);
    const [showCarrierRejectionsModal, setShowCarrierRejectionsModal] = useState(false);
    const [showSendLoadFinalizationModal, setShowSendLoadFinalizationModal] = useState(false);
    const [BOLList, setBOLList] = useState([]);
    const [commodityList, setCommodityList] = useState([]);

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const entityType = useSelector(state => state.auth.entityType);
    const entityId = useSelector(state => state.auth.entityId);
    const documentLists = useSelector(state => state.documents.lists);

    //#endregion
    //#region toggles

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleCompleted = () => {
        setShowCompleted(!showCompleted);
    };

    const toggleCancellation = () => {
        setShowCancellation(!showCancellation);
    };

    const toggleLoadCancellationsModal = () => {
        setShowLoadCancellationsModal(!showLoadCancellationsModal);
    };

    const toggleCarrierRejectionsModal = () => {
        setShowCarrierRejectionsModal(!showCarrierRejectionsModal);
    };

    const toggleSendLoadFinalizationModal = () => {
        setShowSendLoadFinalizationModal(!showSendLoadFinalizationModal);
    };

    //#endregion
    //#region displays

    const renderStops = () => {
        if (isListNotEmpty(stops)) {
            const pickUpAndDropOffStops = stops.filter(s => s.stopType === 'PICK_UP' || s.stopType === 'DROP_OFF');
            if (isListNotEmpty(pickUpAndDropOffStops)) {
                const stopsDisplay = pickUpAndDropOffStops.map((stop) => {
                    const stopName = LoadStopUtils.getStopLocationNameDisplay(stop, shipperId, carrierId, entityType, entityId);
                    const stopIcon = LoadStopUtils.getStopMarkerIcon(stop);
                    let appointmentTypeToShow = null;
                    switch (stop.apptType) {
                        case "FIRST_COME_FIRST_SERVE":
                            appointmentTypeToShow = (isNotNullOrUndefined(stop.apptWindowStartDateTime) && isNotNullOrUndefined(stop.apptWindowEndDateTime) ? <DataDateTimeRangeRow key={`stop-appt-datetime-${stop.id}`} separateLines={true} startTime={stop.apptWindowStartDateTime} endTime={stop.apptWindowEndDateTime} timeZone={stop.timeZone} /> : null);
                            break;
                        case "HAVE_APPOINTMENT":
                            appointmentTypeToShow = (isNotNullOrUndefined(stop.apptDateTime) ? <DataRow key={`stop-appt-datetime-${stop.id}`} value={stop.apptDateTime} dataType="MomentDateTime" timeZone={stop.timeZone} /> : null);
                            break;
                        case "NEED_APPOINTMENT":
                            appointmentTypeToShow = (`${isNotNullOrUndefined(stop.apptCallAheadDateTime) ? 'Appt. Needed' : ''}`);
                            break;
                    }

                    return (
                        <div style={{ marginBottom: 24 }} key={`stop-details-${stop.id}`}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: 30, marginRight: 12 }}>
                                    {stopIcon && (<img src={stopIcon} alt={Enums.StopTypes.getValueByName(stop.stopType)} style={{ width: 30, height: 30 }} />)}
                                </div>
                                <div style={{ flex: 1 }}>
                                    <span style={{ fontWeight: 'bold' }}>{stopName}</span>
                                    <span>{appointmentTypeToShow}</span>
                                </div>
                            </div>
                        </div>
                    );
                });

                return (
                    <div>
                        <div style={{ height: 250, overflowY: 'auto' }}>
                            {stopsDisplay}
                        </div>
                        <DataRow style={{ marginTop: 24 }} title="Total Stops" valueStyle={{ fontWeight: 'bold' }} value={pickUpAndDropOffStops.length} />
                    </div>
                );
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    const extra = entityType === "STAFF" ? (
        <AddEditChangeRemoveButtons
            showAdd={false} addText='' addAction={null}
            showEdit={true} editText={"Edit Load Details"} editAction={(e) => { e.stopPropagation(); toggleEditEntity(); }}
            showChange={false} changeText='' changeAction={null}
            showRemove={false} removeText='' removeAction={null}
        />
    ) : null;

    const editEntityComponents = (
        <Drawer
            title={"Edit Load Details"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditEntity}
            visible={showEditEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditLoadEquipment
                cancel={toggleEditEntity}
                load={load}
            />
        </Drawer>
    );

    const completedComponents = (
        <Drawer
            title={"Update Load Status"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleCompleted}
            visible={showCompleted === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditLoadStatusCompleted
                cancel={toggleCompleted}
                load={load}
                stops={stops}
                currentStatus={currentStatus}
            />
        </Drawer>
    );

    const cancellationComponents = (
        <Drawer
            title={"Load Cancellation"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleCancellation}
            visible={showCancellation === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditLoadStatusCancelled
                cancel={toggleCancellation}
                load={load}
            />
        </Drawer>
    );

    const loadCancellationListComponents = (
        <Modal
            title="Load Cancellations"
            visible={showLoadCancellationsModal === true}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleLoadCancellationsModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <LoadCancellationList
                loadCancellations={loadCancellations}
            />
        </Modal>
    );

    const carrierRejectionListComponents = (
        <Modal
            title="Carrier Rejections"
            visible={showCarrierRejectionsModal === true}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleCarrierRejectionsModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <LoadCarrierRejectionList
                carrierRejections={carrierRejections}
            />
        </Modal>
    );

    const sendLoadFinalizationEmailModal = (
        <Modal
            title="Send Load Finalization Email"
            visible={showSendLoadFinalizationModal === true}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleSendLoadFinalizationModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <LoadSendLoadFinalizationEmail
                load={load}
                carrierId={carrierId}
                stops={stops}
                cancel={toggleSendLoadFinalizationModal}
            />
        </Modal>
    );

    //#endregion
    //#region useMemos and useEffects

    useEffect(() => {
        if (isObjectNotEmpty(load) && isListNotEmpty(stops)) {
            let ids = [];
            ids.push(load.id);

            stops.forEach((stop) => {
                ids.push(stop.id);
            });

            dispatch(actionCreators.fetchBulkDocumentLists(load.id, ids));
        }
    }, [load, stops]);

    useEffect(() => {
        if (isObjectNotEmpty(documentLists) && isObjectNotEmpty(load) && isListNotEmpty(stops)) {
            let pendingApprovalDocumentsList = [];
            let missingDocumentsList = [];

            let loadDocuments = selectListRecords(documentLists, load.id);
            let loadCounts = DocumentUtils.calculateCounts(loadDocuments, load.id, 'LOAD', null, null, hasCarrierWashout);
            if (isListNotEmpty(loadCounts.pendingApprovalDocumentsList)) {
                loadCounts.pendingApprovalDocumentsList.forEach((pendingApprovalDocument) => {
                    pendingApprovalDocumentsList.push({ ...pendingApprovalDocument, documentEntitySubType: null });
                });
            }

            if (isListNotEmpty(loadCounts.missingDocuments)) {
                loadCounts.missingDocuments.forEach((missingDocument) => {
                    missingDocumentsList.push({ entityType: 'LOAD', entityId: load.id, entity: load, entityName: isStringNotEmpty(load.parentName) ? load.parentName : (isStringNotEmpty(load.name) ? load.name : load.irisId), documentType: missingDocument.documentType, documentTypeName: missingDocument.name });
                });
            }

            stops.forEach((stop) => {
                let stopDocuments = selectListRecords(documentLists, stop.id);
                let stopCounts = DocumentUtils.calculateCounts(stopDocuments, stop.id, 'STOP', stop.stopType, null, false, stop.hasLumperFee);
                if (isListNotEmpty(stopCounts.pendingApprovalDocumentsList)) {
                    stopCounts.pendingApprovalDocumentsList.forEach((pendingApprovalDocument) => {
                        pendingApprovalDocumentsList.push({ ...pendingApprovalDocument, documentEntitySubType: stop.stopType });
                    });
                }

                if (isListNotEmpty(stopCounts.missingDocuments)) {
                    stopCounts.missingDocuments.forEach((missingDocument) => {
                        missingDocumentsList.push({ entityType: 'STOP', entityId: stop.id, entity: stop, entityName: LoadStopUtils.getStopNameDisplay(stop, shipperId, carrierId, entityType, entityId), documentType: missingDocument.documentType, documentTypeName: missingDocument.name });
                    });
                }
            });

            setPendingApprovalDocuments(pendingApprovalDocumentsList);
            setMissingDocuments(missingDocumentsList);
        }

        return () => {
        };
    }, [documentLists, load, stops, hasCarrierWashout, entityType, entityId, shipperId, carrierId]);

    useMemo(() => {
        if (isObjectNotEmpty(load) && isListNotEmpty(stops) && (entityType === 'STAFF' || (entityType === 'SHIPPER' && load.serviceType === 'TMS' && load.createdByEntityType === 'SHIPPER') || (entityType === 'CARRIER' && load.serviceType === 'TMS' && load.createdByEntityType === 'CARRIER'))) {
            let missingPedigreeInformationList = LoadUtils.getMissingPedigreeInformation(load, stops);
            setMissingPedigreeInformation(missingPedigreeInformationList);
        }
    }, [load, stops, entityType]);

    useMemo(() => {
        if (isListNotEmpty(stops)) {
            let listOfBOLs = [];
            let listOfCommodities = [];
            stops.forEach((stop) => {
                if (isListNotEmpty(stop.bolNumbers)) {
                    stop.bolNumbers.forEach((bolNum) => {
                        if (!listOfBOLs.includes(bolNum)) {
                            listOfBOLs.push(bolNum);
                        }
                    });
                }

                if (isListNotEmpty(stop.commodities)) {
                    stop.commodities.forEach((commodity) => {
                        if (!listOfCommodities.includes(commodity.name)) {
                            listOfCommodities.push(commodity.name);
                        }
                    });
                }
            });
            setBOLList([...listOfBOLs]);
            setCommodityList([...listOfCommodities]);
        }
    }, [stops]);

    //#endregion

    if (isObjectNotEmpty(load)) {
        return (
            <>
                {LoadUtils.loadStatusIs(currentStatus, ['COMPLETED', 'REOPENED']) && (isObjectNotEmpty(load.completionCheckList) && (load.completionCheckList.carrierInvoiceStatus !== 'SENT' && load.completionCheckList.carrierInvoiceStatus !== 'COMPLETED')) && (entityType === 'STAFF' || (LoadUtils.isTMSLoad(load) && (entityType === 'BROKER' || entityType === 'SHIPPER'))) ? (
                    <Alert
                        style={{ marginBottom: 24 }}
                        type="info"
                        showIcon
                        message="The system has sent an email to the Carrier to provide proof of delivery, remittance information, and their invoice."
                        action={<Space><Button size="small" type="ghost" onClick={(e) => { toggleSendLoadFinalizationModal(); }}>Re-Send Email</Button></Space>}
                    />
                ) : null}
                <LoadCompletionCard
                    load={load}
                    loadId={loadId}
                    stops={stops}
                    invoices={invoices}
                    invoiceLineItems={invoiceLineItems}
                    transactions={transactions}
                    claims={claims}
                    shipperId={shipperId}
                    carrierId={carrierId}
                    serviceType={serviceType}
                    currentStatus={currentStatus}
                    showDocumentCards={true}
                    showMissingPedigreeInformation={true}
                />
                <Row gutter={[24, 24]}>
                    <Col lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }}>
                        <LoadStatusCard
                            loadId={loadId}
                            load={load}
                            shipperId={shipperId}
                            carrierId={carrierId}
                            serviceType={serviceType}
                            stops={stops}
                            currentStop={currentStop}
                            currentStopType={currentStopType}
                            currentStatus={currentStatus}
                            displayStatus={displayStatus}
                            invoices={invoices}
                            invoiceLineItems={invoiceLineItems}
                            transactions={transactions}
                            claims={claims}
                            onTabChange={onTabChange}
                            missingPedigreeInformation={missingPedigreeInformation}
                            style={{ border: '1px solid #b7eb8f' }}
                            headStyle={{ backgroundColor: '#f6ffed', borderBottom: '1px solid #b7eb8f', fontSize: 14 }}
                        >
                            <div style={{ height: 300, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'stretch' }}>
                                <div>
                                    <Row gutter={[12, 12]}>
                                        <Col sm={24} md={12}>
                                            <div style={{ marginBottom: 12 }}><span style={{ fontSize: 14, fontWeight: 'bold', color: '#000000' }}>Load Details</span></div>
                                            <DataRow title="Load ID" value={load.irisId} dataType="String" />
                                            {isNotNullOrUndefined(load.loadStartDateTime) && isObjectNotEmpty(load.origin) ? (<DataRow title="Started At" value={load.loadStartDateTime} dataType="MomentDateTime" timeZone={load.origin.timeZone} />) : null}
                                            {isNotNullOrUndefined(load.loadEndDateTime) && isObjectNotEmpty(load.destination) ? (<DataRow title="Completed At" value={load.loadEndDateTime} dataType="MomentDateTime" timeZone={load.destination.timeZone} />) : null}
                                            {isNumberGreaterThanZero(load.loadWeight) ? (<DataRow title="Load Weight" value={load.loadWeight} units={load.loadWeightUnits} dataType="Weight" />) : null}
                                            {isNumberGreaterThanZero(load.totalWeight) ? (<DataRow title="Total Weight" value={load.totalWeight} units={load.totalWeightUnits} dataType="Weight" />) : null}
                                            {isStringNotEmpty(load.customerOrderNumber) ? (<DataRow title="Customer Order Number" value={load.customerOrderNumber} dataType="String" />) : null}
                                            {isListNotEmpty(BOLList) ? (<DataListRow title="BOL Number(s)" data={BOLList} />) : null}
                                            {isListNotEmpty(commodityList) ? (<DataListRow title="Commodities" data={commodityList} />) : null}
                                            {serviceType === 'BROKERAGE' ? (
                                                <LoadRepresentativeCard load={load} />
                                            ) : null}
                                        </Col>
                                        <Col sm={24} md={12}>
                                            <div style={{ marginBottom: 12 }}><span style={{ fontSize: 14, fontWeight: 'bold', color: '#000000' }}>Equipment Needs and Services</span>{((LoadUtils.loadStatusIs(load.loadStatus, ['CREATED', 'PENDING']) && entityType === "STAFF") || (LoadUtils.loadStatusIs(load.loadStatus, ['CREATED', 'BOOKED']) && entityType === "CARRIER") || (LoadUtils.loadStatusIs(load.loadStatus, ['CREATED', 'PENDING']) && entityType === "SHIPPER")) ? <Button style={{ fontWeight: 'bold' }} type="link" onClick={(e) => { e.stopPropagation(); toggleEditEntity(); }}>edit</Button> : null}</div>
                                            {isObjectNotEmpty(load.equipmentNeeds) ? (
                                                <>
                                                    {isObjectNotEmpty(load.equipmentNeeds.trailerType) && isStringNotEmpty(load.equipmentNeeds.trailerType.description) ? (<DataRow title="Trailer Type" value={load.equipmentNeeds.trailerType.description} dataType="String" />) : null}
                                                    {isNumberNotEmpty(load.equipmentNeeds.trailerLength) ? (<DataRow title="Trailer Length" value={load.equipmentNeeds.trailerLength} units={load.equipmentNeeds.trailerLengthUnit} dataType="Length" />) : null}
                                                    {isNumberNotEmpty(load.equipmentNeeds.trailerWeightCapacity) ? (<DataRow title="Max Load Weight Capacity" value={load.equipmentNeeds.trailerWeightCapacity} units={load.equipmentNeeds.trailerWeightCapacityUnit} dataType="Weight" />) : null}
                                                    {isNumberNotEmpty(load.equipmentNeeds.preCoolReefer) ? (<DataRow title="Pre-cool Temp" value={load.equipmentNeeds.preCoolReefer} units={load.equipmentNeeds.preCoolReeferUnit} dataType="Temperature" />) : null}
                                                    {isNumberNotEmpty(load.equipmentNeeds.minRunTemp) && isNumberNotEmpty(load.equipmentNeeds.maxRunTemp) ? (<DataTemperatureRangeRow title="Temp Range" min={load.equipmentNeeds.minRunTemp} minUnits={load.equipmentNeeds.minRunTempUnit} max={load.equipmentNeeds.maxRunTemp} maxUnits={load.equipmentNeeds.maxRunTempUnit} />) : null}
                                                </>
                                            ) : null}
                                            {isListNotEmpty(load.equipmentSpecifics) ? (
                                                <DataListRow valueStyle={{ paddingLeft: 16 }} title="Equipment Specifics" data={load.equipmentSpecifics} dataType="EquipmentSpecific" />
                                            ) : null}
                                            {isListNotEmpty(load.driverRequirements) ? (
                                                <DataListRow valueStyle={{ paddingLeft: 16 }} title="Driver Requirements" data={load.driverRequirements} dataType="DriverRequirement" />
                                            ) : null}
                                        </Col>
                                    </Row>
                                </div>
                                <div>
                                    <Row gutter={[12, 12]} justify="space-evenly">
                                        {((LoadUtils.loadStatusIs(currentStatus, ["PENDING", "APPROVED", "PENDING_RATE_CONFIRMATION", "BOOKED", "SCHEDULED", "DEAD_HEADING"]) || (LoadUtils.loadStatusIs(currentStatus, ["IN_TRANSIT", "LAYOVER", "ARRIVING", "ARRIVED", "AT_STOP"]) && isDeadHeading === true)) && (entityType === "STAFF" || LoadUtils.isLoadOwner(load, entityType, entityId))) ? (
                                            <Col sm={12} md={8} style={{ display: 'inline-flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                <Button type="link" onClick={toggleCancellation} style={{ marginTop: 5, marginBottom: 5, paddingLeft: 0 }}>Cancel Load</Button>
                                            </Col>
                                        ) : null}

                                        {(LoadUtils.loadStatusIs(currentStatus, ["IN_TRANSIT", "DEAD_HEADING", "LAYOVER", "ARRIVING", "ARRIVED", "AT_STOP", "LOADING", "LOADED", "UNLOADING", "UNLOADED", "DROP_OFF_COMPLETED"])) && (entityType === "STAFF" || LoadUtils.isLoadOwner(load, entityType, entityId)) ? (
                                            <Col sm={12} md={8} style={{ display: 'inline-flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                <Button type="link" onClick={toggleCompleted} style={{ marginTop: 5, marginBottom: 5, paddingLeft: 0 }}>Complete Load</Button>
                                            </Col>
                                        ) : null}

                                        {isListNotEmpty(loadCancellations) && entityType === "STAFF" ? (
                                            <Col sm={12} md={8} style={{ display: 'inline-flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                <b>Cancellations: </b><Button type="link" onClick={toggleLoadCancellationsModal}>{loadCancellations.length}</Button>
                                            </Col>
                                        ) : null}
                                        {isListNotEmpty(carrierRejections) && entityType === "STAFF" ? (
                                            <Col sm={12} md={8} style={{ display: 'inline-flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                <b>Carrier Rejections: </b><Button type="link" onClick={toggleCarrierRejectionsModal}>{carrierRejections.length}</Button>
                                            </Col>
                                        ) : null}
                                    </Row>
                                </div>
                            </div>
                        </LoadStatusCard>
                    </Col>
                    <Col lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }}>
                        <ComplexCard
                            title={LoadUtils.getLoadETADisplay(load, entityType)}
                            style={{ minHeight: 400, border: '1px solid #b7eb8f' }}
                            headStyle={{ backgroundColor: '#f6ffed', borderBottom: '1px solid #b7eb8f', fontSize: 14, textAlign: 'center' }}
                        >
                            <Row>
                                <Col span={10}>
                                    {renderStops()}
                                </Col>
                                <Col span={14}>
                                    <MapWrapper
                                        height={'300px'}
                                        key={`load-details-map-${loadId}-key`}
                                        loadId={loadId}
                                        stops={stops}
                                        containerName={`load-details-map-${loadId}`}
                                    />
                                </Col>
                            </Row>
                        </ComplexCard>
                    </Col>
                    <Col lg={{ span: (entityType === 'STAFF' && serviceType === 'BROKERAGE') || serviceType === 'TMS' ? 8 : 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                        <LoadShipperCard
                            title="Shipper"
                            load={load}
                            headStyle={{ fontSize: 14 }}
                        />
                    </Col>
                    <CanSee entityAction="READ" entityModel="LOAD_CARRIER" entityObject={load}>
                        <Col lg={{ span: (entityType === 'STAFF' && serviceType === 'BROKERAGE') || serviceType === 'TMS' ? 8 : 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                            <Row gutter={[24, 24]}>
                                <Col span={24}>
                                    <LoadCarrierCard
                                        title="Carrier"
                                        loadId={loadId}
                                        load={load}
                                        stops={stops}
                                        invoices={invoices}
                                        invoiceLineItems={invoiceLineItems}
                                        headStyle={{ fontSize: 14 }}
                                    />
                                </Col>
                                {isObjectNotEmpty(load) && isStringNotEmpty(load.assignedCarrierId) ? (
                                    <>
                                        <CanSee entityAction="READ" entityModel="LOAD_DRIVER" entityObject={load}>
                                            <Col span={24}>
                                                <LoadDriverCard
                                                    title="Driver 1"
                                                    driverAccountUser={isListNotEmpty(load.drivers) ? load.drivers[0] : null}
                                                    carrierId={carrierId}
                                                    driverId={isListNotEmpty(load.drivers) ? (isObjectNotEmpty(load.drivers[0]) && isStringNotEmpty(load.drivers[0].id) ? load.drivers[0].id : null) : null}
                                                    isApproved={isListNotEmpty(load.drivers) ? (isObjectNotEmpty(load.drivers[0]) && isObjectNotEmpty(load.drivers[0].driver) && isBooleanTrue(load.drivers[0].driver.isApproved) ? true : false) : false}
                                                    load={load}
                                                    headStyle={{ fontSize: 14 }}
                                                />
                                            </Col>
                                            {isBooleanTrue(hasTeamDriving) ? (
                                                <Col span={24}>
                                                    <LoadDriverCard
                                                        title="Driver 2"
                                                        driverAccountUser={isListNotEmpty(load.drivers) && load.drivers.length > 1 ? load.drivers[1] : null}
                                                        carrierId={carrierId}
                                                        driverId={isListNotEmpty(load.drivers) && load.drivers.length > 1 ? (isObjectNotEmpty(load.drivers[1]) && isStringNotEmpty(load.drivers[1].id) ? load.drivers[1].id : null) : null}
                                                        isApproved={isListNotEmpty(load.drivers) && load.drivers.length > 1 ? (isObjectNotEmpty(load.drivers[1]) && isObjectNotEmpty(load.drivers[1].driver) && isBooleanTrue(load.drivers[1].driver.isApproved) ? true : false) : false}
                                                        load={load}
                                                        headStyle={{ fontSize: 14 }}
                                                    />
                                                </Col>
                                            ) : null}
                                        </CanSee>
                                        <CanSee entityAction="READ_LIST" entityModel="LOAD_ASSET" entityObject={load}>
                                            <Col span={24}>
                                                <LoadAssetsCard
                                                    title="Load Assets"
                                                    load={load}
                                                    carrierId={carrierId}
                                                    assets={isListNotEmpty(load.assets) ? load.assets : []}
                                                    headStyle={{ fontSize: 14 }}
                                                />
                                            </Col>
                                        </CanSee>
                                    </>
                                ) : null}
                            </Row>
                        </Col>
                    </CanSee>
                    {(entityType === "STAFF" || entityType === "SHIPPER" || entityType === "CARRIER" || entityType === "BROKER") ? (
                        <Col lg={{ span: (entityType === 'STAFF' && serviceType === 'BROKERAGE') || serviceType === 'TMS' ? 8 : 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                            <Row gutter={[24, 24]}>
                                {LoadUtils.loadStatusIs(currentStatus, ['COMPLETED', 'REOPENED', 'CLOSED']) ? (
                                    <Col span={24}>
                                        <LoadCompletionCard
                                            load={load}
                                            loadId={loadId}
                                            stops={stops}
                                            claims={claims}
                                            invoices={invoices}
                                            invoiceLineItems={invoiceLineItems}
                                            transactions={transactions}
                                            shipperId={shipperId}
                                            carrierId={carrierId}
                                            serviceType={serviceType}
                                            currentStatus={currentStatus}
                                            title="Load Completion Checklist"
                                            headStyle={{ fontSize: 14 }}
                                            showCompletionChecklist={true}
                                        />
                                    </Col>
                                ) : null}
                                <Col span={24}>
                                    <LoadRateCard
                                        title="Financials"
                                        onTabChange={onTabChange}
                                        load={load}
                                        loadId={loadId}
                                        stops={stops}
                                        claims={claims}
                                        invoices={invoices}
                                        invoiceLineItems={invoiceLineItems}
                                        transactions={transactions}
                                        shipperId={shipperId}
                                        carrierId={carrierId}
                                        serviceType={serviceType}
                                        currentStatus={currentStatus}
                                        headStyle={{ backgroundColor: '#f6ffed', borderBottom: '1px solid #b7eb8f', fontSize: 14 }}
                                    />
                                </Col>
                                <Col span={24}>
                                    <LoadCompletionCard
                                        load={load}
                                        loadId={loadId}
                                        stops={stops}
                                        invoices={invoices}
                                        invoiceLineItems={invoiceLineItems}
                                        transactions={transactions}
                                        claims={claims}
                                        shipperId={shipperId}
                                        carrierId={carrierId}
                                        serviceType={serviceType}
                                        currentStatus={currentStatus}
                                        headStyle={{ backgroundColor: '#f6ffed', borderBottom: '1px solid #b7eb8f', fontSize: 14 }}
                                        showQuotes={true}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    ) : null}
                </Row>
                {editEntityComponents}
                {cancellationComponents}
                {completedComponents}
                {loadCancellationListComponents}
                {carrierRejectionListComponents}
                {sendLoadFinalizationEmailModal}
            </>
        );
    } else {
        return null;
    }
};

export default LoadDetails;