import React from 'react';
import { Button } from "antd";
import classes from './DispatchScheduleLoadCard.module.scss';
import { useSelector } from "react-redux";
import ActionButton from '../../components/ActionButton/ActionButton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { isBooleanTrue, isListNotEmpty, isNumberNotEmpty, isObjectNotEmpty } from '../../shared/objectUtils';
import LoadUtils from '../../api/utils/loadUtils';

const DispatchScheduleLoadCard = ({ load, displayAsText = false, isBundledLoad = false, setSelectedLoadToDispatchId, setShowDispatchLoadModal, setShowCancelLoadModal, setAssetSideBar, setDriverSideBar, style }) => {
    //#region constants

    const hasTeamDriving = isObjectNotEmpty(load) && isListNotEmpty(load.driverRequirements) && load.driverRequirements.includes('TEAM_DRIVING') ? true : false;
    const hasDrivers = isObjectNotEmpty(load) && isListNotEmpty(load.driverIds) && ((hasTeamDriving === true && load.driverIds.length === 2) || (hasTeamDriving === false && load.driverIds.length === 1)) ? true : false;
    const hasAssets = isObjectNotEmpty(load) && isListNotEmpty(load.assetIds) ? true : false;

    //#endregion
    //#region useDispatch and useSelectors

    const isLoading = useSelector(state => state.loads.isRecordUpdateLoading);
    const error = useSelector(state => state.loads.updateRecordError);
    const loadEventUpdateStatus = useSelector(state => state.loadEvents.loadEventUpdateStatus);

    //#endregion
    //#region Load to Dispatch Methods

    const onSelectLoadToDispatch = () => {
        if (isBooleanTrue(isBundledLoad) && isObjectNotEmpty(load)) {
            setAssetSideBar(null);
            setDriverSideBar(null);
            setSelectedLoadToDispatchId(load.id);
        }
    };

    const getBundleCount = (load) => {
        let dispatchedLoadStatuses = ['SCHEDULED', 'IN_TRANSIT', 'AT_STOP', 'COMPLETED', 'CLOSED', 'REOPENED', 'CANCELLED'];
        let remainingCount = 0;
        if (isNumberNotEmpty(load.childLoadInitCount)) {
            let totalCount = load.childLoadInitCount;
            let generatedChildLoadsCount = isNumberNotEmpty(load.childLoadActualCount) ? load.childLoadActualCount : 0;
            let dispatchedCount = 0;
            // console.log(`totalCount: ${totalCount}`);
            // if there are any child loads that have been dispatched already, include them in the dispatched count
            if (generatedChildLoadsCount > 0) {
                if (isListNotEmpty(load.childLoads)) {
                    let dispatchedChildLoads = load.childLoads.filter(cl => dispatchedLoadStatuses.includes(cl.status));
                    dispatchedCount += dispatchedChildLoads.length;
                }
            }

            // if the parent load is already dispatched then include it in the dispatched count as well
            if (dispatchedLoadStatuses.includes(load.loadStatus)) {
                dispatchedCount += 1;
            }

            remainingCount = totalCount - dispatchedCount;
        }
        // console.log(remainingCount);
        return remainingCount;
    };

    //#endregion

    if (isObjectNotEmpty(load)) {
        if (isBooleanTrue(displayAsText)) {
            if (isBooleanTrue(isBundledLoad)) {
                if (LoadUtils.loadStatusIs(load.loadStatus, ['CANCELLED']) || load.isDeleted === true) {
                    return (
                        <span>Cancelled</span>
                    );
                } else if (LoadUtils.loadStatusIs(load.loadStatus, ['BOOKED'])) {
                    if (hasDrivers === false || hasAssets === false) {
                        if ((load.driverIds === undefined || load.driverIds === null || load.driverIds.length === 0) && (load.assetIds === undefined || load.assetIds === null || load.assetIds.length === 0) && (load.isParentLoad === false || (load.isParentLoad === true && (load.childLoadInitCount === undefined || load.childLoadInitCount === null || load.childLoadInitCount === 0 || getBundleCount(load) === 0)))) {
                            return (
                                <div>
                                    <Button type="link" onClick={() => { onSelectLoadToDispatch(); setShowCancelLoadModal(true); }}>Cancel Load <FontAwesomeIcon className="anticon" icon={faTimesCircle} style={{ marginLeft: 8 }} /></Button>
                                </div>
                            );
                        } else {
                            return null;
                        }
                    } else {
                        return (
                            <div>
                                <Button type="link" onClick={() => { onSelectLoadToDispatch(); setShowDispatchLoadModal(true); }}>Dispatch <FontAwesomeIcon className="anticon" icon={faArrowCircleRight} style={{ marginLeft: 8, color: '#23BEBB' }} /></Button>
                            </div>
                        );
                    }
                } else if (LoadUtils.loadStatusIs(load.loadStatus, ['SCHEDULED', 'IN_TRANSIT', 'AT_STOP', 'COMPLETED', 'CLOSED', 'REOPENED'])) {
                    return (
                        <span>Dispatched</span>
                    );
                } else {
                    return null;
                }
            } else {
                if (LoadUtils.loadStatusIs(load.loadStatus, ['CANCELLED']) || load.isDeleted === true) {
                    return (
                        <span>Cancelled</span>
                    );
                } else if (LoadUtils.loadStatusIs(load.loadStatus, ['BOOKED'])) {
                    if (hasDrivers === false || hasAssets === false) {
                        if ((load.driverIds === undefined || load.driverIds === null || load.driverIds.length === 0) && (load.assetIds === undefined || load.assetIds === null || load.assetIds.length === 0) && (load.isParentLoad === false || (load.isParentLoad === true && (load.childLoadInitCount === undefined || load.childLoadInitCount === null || load.childLoadInitCount === 0 || getBundleCount(load) === 0)))) {
                            return (
                                <div>
                                    <Button type="link" onClick={() => { setShowCancelLoadModal(true); }}>Cancel Load <FontAwesomeIcon className="anticon" icon={faTimesCircle} style={{ marginLeft: 8 }} /></Button>
                                </div>
                            );
                        } else {
                            return null;
                        }
                    } else {
                        return (
                            <div>
                                <Button type="link" onClick={() => { setShowDispatchLoadModal(true); }}>Dispatch <FontAwesomeIcon className="anticon" icon={faArrowCircleRight} style={{ marginLeft: 8, color: '#23BEBB' }} /></Button>
                            </div>
                        );
                    }
                } else if (LoadUtils.loadStatusIs(load.loadStatus, ['SCHEDULED', 'IN_TRANSIT', 'AT_STOP', 'COMPLETED', 'CLOSED', 'REOPENED'])) {
                    return (
                        <span>Dispatched</span>
                    );
                } else {
                    return null;
                }
            }
        } else {
            if (isBooleanTrue(isBundledLoad)) {
                if (LoadUtils.loadStatusIs(load.loadStatus, ['CANCELLED']) || load.isDeleted === true) {
                    return (
                        <div style={style}>
                            <ActionButton
                                iconPosition="right"
                                buttonClassName={`${classes.button} ${classes.yellowButton}`}
                                buttonIconClassName={classes.iconContainer}
                                buttonTextClassName={classes.textContainer}
                                buttonTextStyle={{ fontSize: 24 }}
                                buttonIcon={<FontAwesomeIcon className={classes.button1icon2} icon={faCheckCircle} />}
                                buttonText={<span>Cancelled</span>}
                                onClick={() => { return; }}
                                disabled={true}
                            />
                        </div>
                    );
                } else if (LoadUtils.loadStatusIs(load.loadStatus, ['BOOKED'])) {
                    return (
                        <div style={style}>
                            <ActionButton
                                iconPosition="right"
                                buttonClassName={`${classes.button} ${(hasDrivers === false || hasAssets === false) ? classes.grayButtonSolidBorder : classes.blueButton}`}
                                buttonIconClassName={classes.iconContainer}
                                buttonTextClassName={classes.textContainer}
                                buttonTextStyle={{ fontSize: 24 }}
                                buttonIcon={<FontAwesomeIcon className={classes.button1icon2} icon={faArrowCircleRight} style={{ color: (hasDrivers === false || hasAssets === false) ? '#979797' : '#23BEBB' }} />}
                                buttonText={<span>Dispatch</span>}
                                onClick={() => { onSelectLoadToDispatch(); setShowDispatchLoadModal(true); }}
                                disabled={(hasDrivers === false || hasAssets === false) || ((isLoading === true && error === null) || loadEventUpdateStatus === 'PENDING')}
                            />
                        </div>
                    );
                } else if (LoadUtils.loadStatusIs(load.loadStatus, ['SCHEDULED', 'IN_TRANSIT', 'AT_STOP', 'COMPLETED', 'CLOSED', 'REOPENED'])) {
                    return (
                        <div style={style}>
                            <ActionButton
                                iconPosition="right"
                                buttonClassName={`${classes.button} ${classes.yellowButton}`}
                                buttonIconClassName={classes.iconContainer}
                                buttonTextClassName={classes.textContainer}
                                buttonTextStyle={{ fontSize: 24 }}
                                buttonIcon={<FontAwesomeIcon className={classes.button1icon2} icon={faCheckCircle} />}
                                buttonText={<span>Dispatched</span>}
                                onClick={() => { return; }}
                                disabled={true}
                            />
                        </div>
                    );
                } else {
                    return null;
                }
            } else {
                if (LoadUtils.loadStatusIs(load.loadStatus, ['CANCELLED']) || load.isDeleted === true) {
                    return (
                        <div style={style}>
                            <ActionButton
                                iconPosition="right"
                                buttonClassName={`${classes.button} ${classes.yellowButton}`}
                                buttonIconClassName={classes.iconContainer}
                                buttonTextClassName={classes.textContainer}
                                buttonTextStyle={{ fontSize: 24 }}
                                buttonIcon={<FontAwesomeIcon className={classes.button1icon2} icon={faCheckCircle} />}
                                buttonText={<span>Cancelled</span>}
                                onClick={() => { return; }}
                                disabled={true}
                            />
                        </div>
                    );
                } else if (LoadUtils.loadStatusIs(load.loadStatus, ['BOOKED'])) {
                    return (
                        <div style={style}>
                            <ActionButton
                                iconPosition="right"
                                buttonClassName={`${classes.button} ${(hasDrivers === false || hasAssets === false) ? classes.grayButtonSolidBorder : classes.blueButton}`}
                                buttonIconClassName={classes.iconContainer}
                                buttonTextClassName={classes.textContainer}
                                buttonTextStyle={{ fontSize: 24 }}
                                buttonIcon={<FontAwesomeIcon className={classes.button1icon2} icon={faArrowCircleRight} style={{ color: (hasDrivers === false || hasAssets === false) ? '#979797' : '#23BEBB' }} />}
                                buttonText={<span>Dispatch</span>}
                                onClick={() => { setShowDispatchLoadModal(true); }}
                                disabled={(hasDrivers === false || hasAssets === false) || ((isLoading === true && error === null) || loadEventUpdateStatus === 'PENDING')}
                            />
                        </div>
                    );
                } else if (LoadUtils.loadStatusIs(load.loadStatus, ['SCHEDULED', 'IN_TRANSIT', 'AT_STOP', 'COMPLETED', 'CLOSED', 'REOPENED'])) {
                    return (
                        <div style={style}>
                            <ActionButton
                                iconPosition="right"
                                buttonClassName={`${classes.button} ${classes.yellowButton}`}
                                buttonIconClassName={classes.iconContainer}
                                buttonTextClassName={classes.textContainer}
                                buttonTextStyle={{ fontSize: 24 }}
                                buttonIcon={<FontAwesomeIcon className={classes.button1icon2} icon={faCheckCircle} />}
                                buttonText={<span>Dispatched</span>}
                                onClick={() => { return; }}
                                disabled={true}
                            />
                        </div>
                    );
                } else {
                    return null;
                }
            }
        }
    } else {
        return null;
    }
};

export default DispatchScheduleLoadCard;