import React, { useMemo, useState } from 'react';
import { Alert, Input, Select, Spin, InputNumber } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Enums from '../../shared/enums';
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import Fieldset from '../FormFieldset/FormFieldset';
import { isListNotEmpty, isNumberNotEmpty, isObjectNotEmpty, isStringEmpty, isStringNotEmpty, removeEmpty } from '../../shared/objectUtils';
import FormItemQuadruple from '../FormItemQuadruple/FormItemQuadruple';
import { selectListRecords } from '../../store/utility';
import FormItemAddress from '../FormItemAddress/FormItemAddress';
import FormItemPointOfContact from '../FormItemPointOfContact/FormItemPointOfContact';

const { Option } = Select;
const { TextArea } = Input;

const EditLocation = ({ location, cancel }) => {
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.locations.isRecordUpdateLoading);
    const error = useSelector(state => state.locations.updateRecordError);
    const commodities = useSelector(state => state.orchestrator.commodities);
    const accounts = useSelector(state => state.orchestrator.accounts);
    const entityType = useSelector(state => state.auth.entityType);
    const entityId = useSelector(state => state.auth.entityId);
    const isAdmin = useSelector(state => state.auth.isAdmin);
    const linkedAccounts = useSelector(state => selectListRecords(state.orchestrator.linkedAccounts, isObjectNotEmpty(location) && isStringNotEmpty(location.accountId) ? location.accountId : (isStringNotEmpty(entityId) && entityType !== 'STAFF' ? entityId : null)));

    //#endregion
    //#region enums

    const locationTypeOptions = Enums.LocationTypes.selectListOptions();
    const appointmentTypeOptions = Enums.AppointmentTypes.selectListOptions();
    const currencyOptions = Enums.Currencies.selectListOptions();
    const timeZoneOptions = Enums.USATimeZones.selectListOptions();

    //#endregion
    //#region onSubmit and onCancel

    const onSubmit = (data) => {
        let payload = removeEmpty(data);
        if (isStringNotEmpty(location.accountId) && isStringEmpty(payload.accountId)) {
            payload.accountId = location.accountId;
        }
        if (isStringNotEmpty(location.linkedAccountId) && isStringEmpty(payload.linkedAccountId)) {
            payload.linkedAccountId = location.linkedAccountId;
        }

        dispatch(actionCreators.updateLocation(location.id, payload));
    };

    const onCancel = () => {
        dispatch(actionCreators.updateLocationCancel());
        cancel();
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        if (isObjectNotEmpty(location) && isStringNotEmpty(location.accountId)) {
            dispatch(actionCreators.getLinkedAccounts(location.accountId, false));
        }
    }, [location]);

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLocationErrorClear());
        dispatch(actionCreators.getAccounts());
        dispatch(actionCreators.getCommodities());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    const formItemLayoutDouble = {
        labelCol1: { span: 24 },
        wrapperCol1: { span: 12 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 12 },
    };

    //#endregion

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Fieldset legend={`Location Name (* indicates a required field)`}>
                        <FormItem {...formItemLayout} label="Name" required format="vertical"
                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Name" ref={ref} />}
                            rules={{ required: "Required Field" }}
                            name="name"
                            defaultValue={location.name ? location.name : ''}
                        />
                    </Fieldset>
                    {isAdmin ? (
                        <Fieldset legend={`Location Time Zone (* indicates a required field)`}>
                            <FormItem {...formItemLayout} label="Time Zone" required format="vertical"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please Select the Time Zone for this Location"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    >
                                        {timeZoneOptions}
                                    </Select>
                                )}
                                rules={{ required: "Required Field" }}
                                name="timeZone"
                                defaultValue={location.timeZone ? location.timeZone : null}
                            />
                        </Fieldset>
                    ) : null}
                    <Fieldset legend="Location Details (* indicates a required field)">
                        <FormItem {...formItemLayout} label="Location Type(s)" required format="vertical"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select the Location Type"
                                    mode="multiple"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                >
                                    {locationTypeOptions}
                                </Select>
                            )}
                            rules={{ required: "Required Field" }}
                            name="locationTypes"
                            defaultValue={location.locationTypes ? location.locationTypes : null}
                        />
                        <FormItem {...formItemLayout} label="Default Appointment Type" format="vertical"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select the Appointment Type"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                >
                                    {appointmentTypeOptions}
                                </Select>
                            )}
                            rules={{ required: false }}
                            name="appointmentType"
                            defaultValue={location.appointmentType ? location.appointmentType : null}
                        />
                    </Fieldset>
                    <Fieldset legend="Location Fees (optional)" isCollapsed={true} isCollapsable={isNumberNotEmpty(location.lumperFee) ? false : true}>
                        <FormItemDouble {...formItemLayoutDouble} label1="Default Lumper Fee" format="vertical"
                            render1={({ onChange, onBlur, value, name, ref }) => (
                                <InputNumber
                                    precision={2}
                                    min={0}
                                    placeholder="Lumper Fee"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            rules1={{ required: false }}
                            name1="lumperFee"
                            defaultValue1={location.lumperFee ? location.lumperFee : ''}
                            render2={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                >
                                    {currencyOptions}
                                </Select>
                            )}
                            rules={{ required: false }}
                            name2="lumperFeeUnit"
                            defaultValue2={location.lumperFeeUnit ? location.lumperFeeUnit : null}
                        />
                    </Fieldset>
                    <Fieldset legend="Special Instructions (optional)" isCollapsed={true} isCollapsable={isStringNotEmpty(location.specialInstructions) ? false : true}>
                        <FormItem {...formItemLayout} label="Default Special Instructions" format="vertical"
                            render={({ onChange, onBlur, value, name, ref }) => <TextArea onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} autoSize={{ minRows: 4 }} ref={ref} />}
                            rules={{ required: false }}
                            name="specialInstructions"
                            defaultValue={location.specialInstructions ? location.specialInstructions : ''}
                        />
                    </Fieldset>
                    <Fieldset legend="Default Point of Contact (optional)" isCollapsable={true} isCollapsed={isObjectNotEmpty(location.pointOfContact) ? false : true}>
                        <FormItemPointOfContact
                            format="penta"
                            required={false}
                            name="pointOfContact"
                            defaultValue={location.pointOfContact}
                        />
                    </Fieldset>
                    <Fieldset legend="Default Location Commodities (optional)" isCollapsable={true} isCollapsed={isListNotEmpty(location.commodityIds) ? false : true}>
                        <FormItem {...formItemLayout} label="Default Commodities" format="vertical"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select the Default Commodities"
                                    mode="multiple"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                >
                                    {commodities.map(i => <Option value={i.id} key={i.id}>{i.name}</Option>)}
                                </Select>
                            )}
                            rules={{ required: false }}
                            defaultValue={location.commodityIds}
                            name="commodityIds"
                        />
                    </Fieldset>
                    <Fieldset legend="Location Ownership">
                        {entityType === "STAFF" ? (
                            <FormItem {...formItemLayout} label="Choose an Account that this Location Belongs To" required format="vertical"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please Select an Account"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    >
                                        {accounts.map(a => <Option value={a.id} key={a.id}>{a.name}</Option>)}
                                    </Select>
                                )}
                                rules={{ required: 'Required Field' }}
                                name="accountId"
                                defaultValue={location.accountId ? location.accountId : null}
                            />
                        ) : null}
                        <FormItem {...formItemLayout} label="Choose Account that you want to link this Location to" format="vertical"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select an Account"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                >
                                    {linkedAccounts.map(a => <Option value={a.id} key={a.id}>{a.name}</Option>)}
                                </Select>
                            )}
                            rules={{ required: false }}
                            name="linkedAccountId"
                            defaultValue={location.linkedAccountId ? location.linkedAccountId : null}
                        />
                    </Fieldset>
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Update Location" />
            </Form>
        </FormProvider>
    );
};

export default EditLocation;