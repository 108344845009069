import axiosAuthenticated from "../../api/axiosAuthenticated";
import { isListNotEmpty, isNotNullOrUndefined } from "../../shared/objectUtils";

const notesPath = '/notes';
const notesBulkPath = '/notes/bulk/get';

//#region Notes Methods

export const getNotesByEntityIds = async (entityIds = []) => {
    let notes = [];
    if (isListNotEmpty(entityIds)) {
        const notesRes = await axiosAuthenticated.post(notesBulkPath, { page: 1, size: 1000000, entityId: [...entityIds] });
        if (notesRes && notesRes.status === 200) {
            notes = notesRes.data.data;
        }
    }

    return notes;
};

export const getNotes = async (searchParams = {}, pagination = {}) => {
    const notesRes = await axiosAuthenticated.get(notesPath, { params: { ...searchParams } });
    if (notesRes && notesRes.status === 200) {
        const notes = notesRes.data.data;
        const otherData = notesRes.data;

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;

        return { data: notes, searchParams: searchParams, pagination: pagination };
    }

    return { data: [], searchParams: searchParams, pagination: pagination };
};

export const getBulkNotes = async (searchParams = {}) => {
    const notesRes = await axiosAuthenticated.post(notesBulkPath, { page: 1, size: 1000000, ...searchParams });
    if (notesRes && notesRes.status === 200) {
        return notesRes.data.data;
    }

    return [];
};

export const addNote = async (payload) => {
    const noteRes = await axiosAuthenticated.post(notesPath, { ...payload });
    if (noteRes && noteRes.status === 201) {
        return noteRes.data;
    }

    return null;
};

export const updateNote = async (id, payload) => {
    const notesRes = await axiosAuthenticated.put(notesPath + `/${id}`, { ...payload });
    if (notesRes && notesRes.status === 200) {
        return notesRes.data;
    }

    return null;
};

export const removeNote = async (id) => {
    const notesRes = await axiosAuthenticated.put(notesPath + `/${id}`, { isDeleted: true });
    if (notesRes && notesRes.status === 200) {
        return notesRes.data;
    }

    return null;
};

//#endregion