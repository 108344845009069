import { isListNotEmpty, isNumberNotEmpty, isObjectNotEmpty } from "../../shared/objectUtils";
import EntityUtils from "../entityUtils";

export const getServiceDTO = (service, features = []) => {
    if (isListNotEmpty(service.featureIds)) {
        let featuresArray = [];
        service.featureIds.forEach((featureId) => {
            const feature = EntityUtils.getFeatureInfo(featureId, features);
            if (isObjectNotEmpty(feature)) {
                featuresArray.push(feature);
            }
        });
        service.features = featuresArray;
    }

    return service;
};

export const getAddServiceRequestDTO = (payload) => {
    if (isNumberNotEmpty(payload.priceAmount)) {
        payload.priceAmount = Number(payload.priceAmount);
        payload.priceAmountUnit = "USD";
    } else {
        payload.priceAmount = 0;
        payload.priceAmountUnit = "USD";
    }

    return payload;
};

export const getUpdateServiceRequestDTO = (payload) => {
    if (isNumberNotEmpty(payload.priceAmount)) {
        payload.priceAmount = Number(payload.priceAmount);
        payload.priceAmountUnit = "USD";
    } else if (payload.priceAmount === null) {
        payload.priceAmount = 0;
        payload.priceAmountUnit = "USD";
    }

    return payload;
};