import React, { useMemo } from 'react';
import { Alert, Input, Select, Spin, InputNumber, Switch } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Enums from '../../shared/enums';
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import FormButtons from '../FormButtons/FormButtons';
import { isObjectNotEmpty, isStringNotEmpty, removeEmpty } from '../../shared/objectUtils';

const { Option } = Select;

const NewLineItemType = ({ existingLineItemTypes, cancel }) => {
    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.lineItemTypes.isRecordAddLoading);
    const error = useSelector(state => state.lineItemTypes.addRecordError);
    const trailerTypes = useSelector(state => state.orchestrator.trailerTypes);

    const lineItemTypeOptions = Enums.LineItemTypes.selectListOptions();
    const lineItemRuleOptions = Enums.LineItemRules.selectListOptions();
    const accountTypeOptions = Enums.AccountTypes.selectListOptions();
    const currencyOptions = Enums.Currencies.selectListOptions();

    // remove options that already exist as line item types to maintain uniqueness of name
    const lineItemNameOptions = Enums.LineItemNames.selectListOptionsWithExclusions(existingLineItemTypes.map(i => i.name));

    const onSubmit = (data) => {
        let cleanedData = removeEmpty(data);

        if (isObjectNotEmpty(cleanedData)) {
            dispatch(actionCreators.addLineItemType(cleanedData));
        } else {
            onCancel();
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.addLineItemTypeCancel());
        cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.addLineItemTypeErrorClear());
        dispatch(actionCreators.getTrailerTypes());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    const onChangeName = (value) => {
        //console.log(`selected ${value}`);

        if (isStringNotEmpty(value)) {
            methods.setValue('description', Enums.LineItemNames.getValueByName(value));

            if (Enums.Accessorials.keys().includes(value)) {
                methods.setValue('itemType', 'ACCESSORIAL');
            } else if (Enums.LoadRequirements.keys().includes(value)) {
                methods.setValue('itemType', 'LOAD_REQUIREMENT');
            } else if (Enums.EquipmentSpecifics.keys().includes(value)) {
                methods.setValue('itemType', 'EQUIPMENT_SPECIFIC');
            } else if (Enums.IRISFees.keys().includes(value)) {
                methods.setValue('itemType', 'IRIS_FEE');
            } else if (Enums.LoadFees.keys().includes(value)) {
                methods.setValue('itemType', 'LOAD_FEE');
            } else if (Enums.StopFees.keys().includes(value)) {
                methods.setValue('itemType', 'STOP_FEE');
            } else if (Enums.Adjustments.keys().includes(value)) {
                methods.setValue('itemType', 'ADJUSTMENT');
            } else {
                methods.setValue('itemType', undefined);
            }
        } else {
            methods.setValue('description', '');
            methods.setValue('itemType', undefined);
        }
    };

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const formItemLayoutDouble = {
        labelCol1: { span: 24 },
        wrapperCol1: { span: 12 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 12 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <FormItem {...formItemLayout} label="Name" required
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Select
                                placeholder="Please Select a Name"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChangeName(selected); onChange(selected); }}
                                value={value}
                                name={name}
                                ref={ref}
                            >
                                {lineItemNameOptions !== [] ? lineItemNameOptions : null}
                            </Select>
                        )}
                        rules={{ required: 'Required Field' }}
                        name="name"
                    />
                    <FormItem {...formItemLayout} label="Description" required
                        render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Description" ref={ref} />}
                        rules={{ required: 'Required Field' }}
                        name="description"
                    />
                    <FormItem {...formItemLayout} label="Type" required
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Select
                                placeholder="Please Select the Type"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                                ref={ref}
                            >
                                {lineItemTypeOptions}
                            </Select>
                        )}
                        rules={{ required: 'Required Field' }}
                        name="itemType"
                    />
                    <FormItem {...formItemLayout} label="Trailer Types"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Select
                                placeholder="Please Select the Trailer Type(s)"
                                mode="multiple"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                                ref={ref}
                            >
                                {trailerTypes.map(i => <Option value={i.id} key={i.id}>{i.description}</Option>)}
                            </Select>
                        )}
                        rules={{ required: false }}
                        name="trailerTypeIds"
                    />
                    <FormItemDouble {...formItemLayoutDouble} label1="Up-Charge Amount"
                        render1={({ onChange, onBlur, value, name, ref }) => (
                            <InputNumber
                                min={0}
                                precision={2}
                                placeholder="Up-Charge Amount"
                                style={{ width: '100%' }}
                                onBlur={onBlur}
                                onChange={e => { onChange(e); }}
                                value={value}
                                name={name}
                                ref={ref}
                            />
                        )}
                        rules1={{ required: false }}
                        name1="upCharge"
                        render2={({ onChange, onBlur, value, name, ref }) => (
                            <Select
                                placeholder="Please Select"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                                ref={ref}
                            >
                                {currencyOptions}
                            </Select>
                        )}
                        rules2={{ required: false }}
                        name2="upChargeUnit"
                    />
                    <FormItem {...formItemLayout} label="Up-Charge Rule"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Select
                                placeholder="Please Select the Up-Charge Rule"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                                ref={ref}
                            >
                                {lineItemRuleOptions}
                            </Select>
                        )}
                        rules={{ required: false }}
                        name="upChargeRule"
                    />
                    <FormItem {...formItemLayout} label="Up-Charge To"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Select
                                placeholder="Please select who the Up-Charge Goes To"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                                ref={ref}
                            >
                                {accountTypeOptions}
                            </Select>
                        )}
                        rules={{ required: false }}
                        name="upChargeTo"
                    />
                    <FormItemDouble {...formItemLayoutDouble} label1="Cost Amount"
                        render1={({ onChange, onBlur, value, name, ref }) => (
                            <InputNumber
                                min={0}
                                precision={2}
                                placeholder="Cost Amount"
                                style={{ width: '100%' }}
                                onBlur={onBlur}
                                onChange={e => { onChange(e); }}
                                value={value}
                                name={name}
                                ref={ref}
                            />
                        )}
                        rules1={{ required: false }}
                        name1="cost"
                        render2={({ onChange, onBlur, value, name, ref }) => (
                            <Select
                                placeholder="Please Select"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                                ref={ref}
                            >
                                {currencyOptions}
                            </Select>
                        )}
                        rules2={{ required: false }}
                        name2="costUnit"
                    />
                    <FormItem {...formItemLayout} label="Cost Rule"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Select
                                placeholder="Please Select the Cost Rule"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                                ref={ref}
                            >
                                {lineItemRuleOptions}
                            </Select>
                        )}
                        rules={{ required: false }}
                        name="costRule"
                    />
                    <FormItem {...formItemLayout} label="Cost To"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Select
                                placeholder="Please select who the Cost Goes To"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                                ref={ref}
                            >
                                {accountTypeOptions}
                            </Select>
                        )}
                        rules={{ required: false }}
                        name="costTo"
                    />
                    <FormItem {...formItemLayout} label="Is Open Amount"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                onBlur={onBlur}
                                onChange={(checked, event) => { onChange(checked); }}
                                checked={value}
                                name={name}
                                ref={ref}
                            />
                        )}
                        name="isOpen"
                        defaultValue={false}
                    />
                    <FormItem {...formItemLayout} label="Is Required"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                onBlur={onBlur}
                                onChange={(checked, event) => { onChange(checked); }}
                                checked={value}
                                name={name}
                                ref={ref}
                            />
                        )}
                        name="isRequired"
                        defaultValue={false}
                    />
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Create Line Item Type" />
            </Form>
        </FormProvider>
    );
};

export default NewLineItemType;