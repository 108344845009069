import { isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import EntityUtils from '../entityUtils';

export const getLoadLaneDTO = (loadLane, accounts = [], trailerTypes = [], locations = [], accountId = null, linkedAccountId = null) => {
    let loadLaneObj = {
        id: loadLane.id,
        irisId: loadLane.irisId,
        laneId: loadLane.laneId,
        accountId: loadLane.accountId,
        customerLaneId: loadLane.customerLaneId,
        approxDistance: loadLane.approxDistance,
        approxDistanceUnit: loadLane.approxDistanceUnit,
        approxTransitTime: loadLane.approxTransitTime,
        approxTransitTimeUnit: loadLane.approxTransitTimeUnit,
        equipmentSpecifics: loadLane.equipmentSpecifics,
        driverRequirements: loadLane.driverRequirements,
        equipmentNeeds: loadLane.equipmentNeeds,
        locations: loadLane.locations,
        links: loadLane.links,
        isDeleted: loadLane.isDeleted,
        createdBy: loadLane.createdBy,
        createdAt: loadLane.createdAt,
        modifiedBy: loadLane.modifiedBy,
        modifiedAt: loadLane.modifiedAt
    };

    // Get Location Info
    if (isListNotEmpty(loadLane.locations)) {
        let loadLaneLocations = loadLane.locations.map((location) => {
            let locationLocation = null;
            if (isStringNotEmpty(location.locationId)) {
                locationLocation = EntityUtils.getLocationInfo(location.locationId, locations);
            }

            return {
                ...location,
                location: locationLocation
            };
        });

        loadLaneObj.locations = loadLaneLocations;
    }

    // Get Account Info
    loadLaneObj.account = EntityUtils.getAccountInfo(loadLane.accountId, accounts);

    if (isObjectNotEmpty(loadLane.equipmentNeeds) && isStringNotEmpty(loadLane.equipmentNeeds.trailerTypeId)) {
        loadLaneObj.equipmentNeeds.trailerType = EntityUtils.getTrailerTypeInfo(loadLane.equipmentNeeds.trailerTypeId, trailerTypes);
    }

    if (isStringNotEmpty(accountId) && isStringNotEmpty(linkedAccountId)) {
        if (isListNotEmpty(loadLane.links)) {
            let link = loadLane.links.find(l => l.accountId === accountId && l.linkedAccountId === linkedAccountId);
            if (isObjectNotEmpty(link)) {
                loadLaneObj.accountId = link.accountId;
                loadLaneObj.linkedAccountId = link.linkedAccountId;
                loadLaneObj.customerLaneId = link.customerLaneId;
                loadLaneObj.perMileRate = link.perMileRate;
                loadLaneObj.perMileRateUnit = link.perMileRateUnit;
                loadLaneObj.contractRate = link.contractRate;
                loadLaneObj.contractRateUnit = link.contractRateUnit;
                loadLaneObj.equipmentSpecifics = link.equipmentSpecifics;
                loadLaneObj.driverRequirements = link.driverRequirements;
                loadLaneObj.equipmentNeeds = link.equipmentNeeds;
                loadLaneObj.locations = link.locations;

                // Get Account Info
                loadLaneObj.account = EntityUtils.getAccountInfo(link.accountId, accounts);

                // Get LinkedAccount Info
                loadLaneObj.linkedAccount = EntityUtils.getAccountInfo(link.linkedAccountId, accounts);

                // Get TrailerType Info
                if (isObjectNotEmpty(link.equipmentNeeds) && isStringNotEmpty(link.equipmentNeeds.trailerTypeId)) {
                    loadLaneObj.equipmentNeeds.trailerType = EntityUtils.getTrailerTypeInfo(link.equipmentNeeds.trailerTypeId, trailerTypes);
                }

                // Get Location Info
                if (isListNotEmpty(link.locations)) {
                    let loadLaneLocations = link.locations.map((loadLaneLocation) => {
                        let location = EntityUtils.getLocationInfo(loadLaneLocation.locationId, locations);

                        return {
                            ...loadLaneLocation,
                            location: location
                        };
                    });

                    loadLaneObj.locations = loadLaneLocations;
                }
            }
        }
    }

    return loadLaneObj;
};