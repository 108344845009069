import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Select, Checkbox, InputNumber, Spin, Row, Col, Alert } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import Fieldset from '../FormFieldset/FormFieldset';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from "../../store/actions/index";
import Header from '../Header/Header';
import FormButtons from '../FormButtons/FormButtons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck } from '@fortawesome/free-solid-svg-icons';
import { isObjectNotEmpty } from '../../shared/objectUtils';

const { Option } = Select;

const EditLoadEquipment = ({ load, cancel }) => {
    //#region useForms

    const methods = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            trailerTypeId: null,
            trailerLength: null,
            trailerWeightCapacity: null,
            minRunTemp: null,
            maxRunTemp: null,
            preCoolReefer: null,
            equipmentSpecifics: null,
            driverRequirements: null
        }
    });

    //#endregion
    //#region useSelectors

    const dispatch = useDispatch();
    const lineItemTypes = useSelector(state => state.orchestrator.lineItemTypes);
    const trailerTypes = useSelector(state => state.orchestrator.trailerTypes);
    const isLoading = useSelector(state => state.loads.isRecordUpdateLoading);
    const error = useSelector(state => state.loads.updateRecordError);

    //#endregion
    //#region useStates

    const [equipmentSpecificOptions, setEquipmentSpecificOptions] = useState([]);
    const [driverRequirementOptions, setDriverRequirementOptions] = useState([]);
    const [showReeferOptions, setShowReeferOptions] = useState(false);
    const [equipmentData, setEquipmentData] = useState({});

    //#endregion
    //#region useRefs

    const preCoolReefer = useRef(null);
    const minRunTemp = useRef(null);
    const maxRunTemp = useRef(null);

    //#endregion
    //#region onChanges

    const onChangeTrailerType = (value) => {
        if (value !== undefined && value !== null) {
            let equipmentSpecificOptionsList = [];
            let driverRequirementOptionsList = [];
            if (lineItemTypes !== undefined && lineItemTypes !== null && lineItemTypes.length > 0) {
                let equipmentSpecifics = lineItemTypes.filter(i => i.trailerTypeIds.includes(value) && i.itemType === 'EQUIPMENT_SPECIFIC');
                let driverRequirements = lineItemTypes.filter(i => i.trailerTypeIds.includes(value) && i.itemType === 'LOAD_REQUIREMENT');

                for (let i = 0; i < equipmentSpecifics.length; i++) {
                    equipmentSpecificOptionsList.push({ value: equipmentSpecifics[i].name, key: equipmentSpecifics[i].name, label: equipmentSpecifics[i].description });
                }
                for (let i = 0; i < driverRequirements.length; i++) {
                    driverRequirementOptionsList.push({ value: driverRequirements[i].name, key: driverRequirements[i].name, label: driverRequirements[i].description });
                }
            }
            setEquipmentSpecificOptions(equipmentSpecificOptionsList);
            setDriverRequirementOptions(driverRequirementOptionsList);

            if (trailerTypes !== undefined && trailerTypes !== null && trailerTypes.length > 0) {
                let selectedTrailerType = trailerTypes.find(s => s.id === value);
                if (selectedTrailerType !== undefined && selectedTrailerType !== null) {
                    if (selectedTrailerType.name === "REEFER") {
                        setShowReeferOptions(true);
                    } else {
                        setShowReeferOptions(false);
                    }
                }
            }
        } else {
            setEquipmentSpecificOptions([]);
            setDriverRequirementOptions([]);
            setShowReeferOptions(false);
        }
    };

    //#endregion
    //#region submits

    const onSubmit = (data) => {
        let trailerTypeId = data.trailerTypeId;
        let trailerLength = data.trailerLength;
        let trailerWeightCapacity = data.trailerWeightCapacity;
        let minRunTemp = data.minRunTemp;
        let maxRunTemp = data.maxRunTemp;
        let preCoolReefer = data.preCoolReefer;
        let equipmentSpecifics = data.equipmentSpecifics;
        let driverRequirements = data.driverRequirements;

        let payload = {
            equipmentNeeds: {}
        };

        if (trailerTypeId !== undefined && trailerTypeId !== null) {
            payload.equipmentNeeds.trailerTypeId = trailerTypeId;
        } else {
            payload.equipmentNeeds.trailerTypeId = null;
        }

        if (trailerLength !== undefined && trailerLength !== null) {
            payload.equipmentNeeds.trailerLength = trailerLength;
            payload.equipmentNeeds.trailerLengthUnit = "FT";
        } else {
            payload.equipmentNeeds.trailerLength = null;
            payload.equipmentNeeds.trailerLengthUnit = null;
        }

        if (trailerWeightCapacity !== undefined && trailerWeightCapacity !== null) {
            payload.equipmentNeeds.trailerWeightCapacity = trailerWeightCapacity;
            payload.equipmentNeeds.trailerWeightCapacityUnit = "LB";
        } else {
            payload.equipmentNeeds.trailerWeightCapacity = null;
            payload.equipmentNeeds.trailerWeightCapacityUnit = null;
        }

        if (preCoolReefer !== undefined && preCoolReefer !== null) {
            payload.equipmentNeeds.preCoolReefer = Number(preCoolReefer);
            payload.equipmentNeeds.preCoolReeferUnit = "F";
        } else if (preCoolReefer === null) {
            payload.equipmentNeeds.preCoolReefer = null;
            payload.equipmentNeeds.preCoolReeferUnit = null;
        }

        if (minRunTemp !== undefined && minRunTemp !== null) {
            payload.equipmentNeeds.minRunTemp = Number(minRunTemp);
            payload.equipmentNeeds.minRunTempUnit = "F";
        } else if (minRunTemp === null) {
            payload.equipmentNeeds.minRunTemp = null;
            payload.equipmentNeeds.minRunTempUnit = null;
        }

        if (maxRunTemp !== undefined && maxRunTemp !== null) {
            payload.equipmentNeeds.maxRunTemp = Number(maxRunTemp);
            payload.equipmentNeeds.maxRunTempUnit = "F";
        } else if (maxRunTemp === null) {
            payload.equipmentNeeds.maxRunTemp = null;
            payload.equipmentNeeds.maxRunTempUnit = null;
        }

        if (equipmentSpecifics !== undefined && equipmentSpecifics !== null && equipmentSpecifics.length > 0) {
            payload.equipmentSpecifics = equipmentSpecifics;
        } else {
            payload.equipmentSpecifics = [];
        }

        if (driverRequirements !== undefined && driverRequirements !== null && driverRequirements.length > 0) {
            payload.driverRequirements = driverRequirements;
        } else {
            payload.driverRequirements = [];
        }

        dispatch(actionCreators.updateLoad(load.id, payload));
    };

    const onCancel = () => {
        dispatch(actionCreators.updateLoadCancel());
        cancel();
    };

    //#endregion
    //#region useMemos and useEffects

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLoadErrorClear());
        dispatch(actionCreators.getLineItemTypes());
        dispatch(actionCreators.getTrailerTypes());
    }, []);

    useMemo(() => {
        if (isObjectNotEmpty(load)) {
            let equipmentDataObj = {
                ...load.equipmentNeeds,
                equipmentSpecifics: load.equipmentSpecifics,
                driverRequirements: load.driverRequirements
            };
            setEquipmentData(equipmentDataObj);
        }
    }, [load]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (isObjectNotEmpty(equipmentData) && lineItemTypes !== undefined && lineItemTypes !== null && lineItemTypes.length > 0 && trailerTypes !== undefined && trailerTypes !== null && trailerTypes.length > 0) {
                if (equipmentData.trailerTypeId !== undefined && equipmentData.trailerTypeId !== null) {
                    methods.setValue('trailerTypeId', equipmentData.trailerTypeId);

                    let equipmentSpecificOptionsList = [];
                    let driverRequirementOptionsList = [];
                    let equipmentSpecifics = lineItemTypes.filter(i => i.trailerTypeIds.includes(equipmentData.trailerTypeId) && i.itemType === 'EQUIPMENT_SPECIFIC');
                    let driverRequirements = lineItemTypes.filter(i => i.trailerTypeIds.includes(equipmentData.trailerTypeId) && i.itemType === 'LOAD_REQUIREMENT');

                    for (let i = 0; i < equipmentSpecifics.length; i++) {
                        equipmentSpecificOptionsList.push({ value: equipmentSpecifics[i].name, key: equipmentSpecifics[i].name, label: equipmentSpecifics[i].description });
                    }
                    for (let i = 0; i < driverRequirements.length; i++) {
                        driverRequirementOptionsList.push({ value: driverRequirements[i].name, key: driverRequirements[i].name, label: driverRequirements[i].description });
                    }
                    setEquipmentSpecificOptions(equipmentSpecificOptionsList);
                    setDriverRequirementOptions(driverRequirementOptionsList);

                    let selectedTrailerType = trailerTypes.find(s => s.id === equipmentData.trailerTypeId);
                    if (selectedTrailerType !== undefined && selectedTrailerType !== null) {
                        if (selectedTrailerType.name === "REEFER") {
                            setShowReeferOptions(true);
                            methods.setValue('minRunTemp', equipmentData.minRunTemp ? equipmentData.minRunTemp : null);
                            methods.setValue('maxRunTemp', equipmentData.maxRunTemp ? equipmentData.maxRunTemp : null);
                            methods.setValue('preCoolReefer', equipmentData.preCoolReefer ? equipmentData.preCoolReefer : null);
                        } else {
                            setShowReeferOptions(false);
                            methods.setValue('minRunTemp', null);
                            methods.setValue('maxRunTemp', null);
                            methods.setValue('preCoolReefer', null);
                        }
                    }
                } else {
                    setEquipmentSpecificOptions([]);
                    setDriverRequirementOptions([]);
                    setShowReeferOptions(false);
                    methods.setValue('minRunTemp', null);
                    methods.setValue('maxRunTemp', null);
                    methods.setValue('preCoolReefer', null);
                }
                if (equipmentData.trailerLength !== undefined && equipmentData.trailerLength !== null) {
                    methods.setValue('trailerLength', equipmentData.trailerLength);
                }
                if (equipmentData.trailerWeightCapacity !== undefined && equipmentData.trailerWeightCapacity !== null) {
                    methods.setValue('trailerWeightCapacity', equipmentData.trailerWeightCapacity);
                }
                if (equipmentData.equipmentSpecifics !== undefined && equipmentData.equipmentSpecifics !== null) {
                    methods.setValue('equipmentSpecifics', equipmentData.equipmentSpecifics);
                }
                if (equipmentData.driverRequirements !== undefined && equipmentData.driverRequirements !== null) {
                    methods.setValue('driverRequirements', equipmentData.driverRequirements);
                }
            }
        }

        return () => isSubscribed = false;
    }, [equipmentData, lineItemTypes, trailerTypes]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (showReeferOptions === true && isObjectNotEmpty(equipmentData)) {
                if (equipmentData.preCoolReefer) {
                    methods.setValue('preCoolReefer', equipmentData.preCoolReefer);
                }
                if (equipmentData.minRunTemp) {
                    methods.setValue('minRunTemp', equipmentData.minRunTemp);
                }
                if (equipmentData.trailerLength) {
                    methods.setValue('maxRunTemp', equipmentData.maxRunTemp);
                }
            }
        }

        return () => isSubscribed = false;
    }, [showReeferOptions, equipmentData]);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    //#endregion

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Header titleStyle={{ fontSize: 24 }} style={{ paddingLeft: 0, paddingRight: 0 }} title="Equipment Needs and Services" title2="Brokered loads will need to be 53-foot, FTL, Reefers." icon={<FontAwesomeIcon className="anticon" icon={faTruck} />} iconStyle={{ background: 'transparent' }} />

                    <Fieldset legend="Trailer and Equipment Specifics">
                        <Row gutter={[4, 4]}>
                            <Col span={8}>
                                <FormItem {...formItemLayout} label="Trailer Type" required format="vertical"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Select
                                            placeholder="Please Select a Trailer Type"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChangeTrailerType(selected); onChange(selected); }}
                                            value={value}
                                            name={name}
                                            showSearch={true}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            ref={ref}
                                        >
                                            {trailerTypes.map(i => <Option value={i.id} key={i.id}>{i.description}</Option>)}
                                        </Select>
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name='trailerTypeId'
                                />
                            </Col>
                            <Col span={8}>
                                <FormItem {...formItemLayout} label="Trailer Length" required format="vertical"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <InputNumber
                                            step={1}
                                            min={0}
                                            precision={0}
                                            placeholder="Trailer Length (ft)"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={e => { onChange(e); }}
                                            value={value}
                                            name={name}
                                            ref={ref}
                                        />
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name='trailerLength'
                                />
                            </Col>
                            <Col span={8}>
                                <FormItem {...formItemLayout} label="Load Weight" required format="vertical"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <InputNumber
                                            step={1000}
                                            min={0}
                                            precision={0}
                                            placeholder="Load Weight (lbs)"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={e => { onChange(e); }}
                                            value={value}
                                            name={name}
                                            ref={ref}
                                        />
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name='trailerWeightCapacity'
                                />
                            </Col>
                        </Row>
                    </Fieldset>
                    {showReeferOptions === true ? (
                        <Fieldset legend="Temperature Requirements">
                            <Row gutter={[4, 4]}>
                                <Col span={8}>
                                    <FormItem {...formItemLayout} label="Pre-Cool Temp" required format="vertical"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <InputNumber
                                                placeholder="Pre-Cool Temp"
                                                style={{ width: '100%' }}
                                                onBlur={onBlur}
                                                onChange={e => { preCoolReefer.current = e; onChange(e); }}
                                                value={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        rules={{
                                            required: 'Required Field',
                                            validate: {
                                                gteMin: value => (minRunTemp.current ? value >= minRunTemp.current : true) || 'Pre-Cool Temp must be greater than or equal to the Min Run Temp.',
                                                lteMax: value => (maxRunTemp.current ? value <= maxRunTemp.current : true) || 'Pre-Cool Temp must be less than or equal to Max Run Temp.'
                                            }
                                        }}
                                        name='preCoolReefer'
                                    />
                                </Col>
                                <Col span={8}>
                                    <FormItem {...formItemLayout} label="Min Run Temp" required format="vertical"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <InputNumber
                                                placeholder="Min Run Temp"
                                                style={{ width: '100%' }}
                                                onBlur={onBlur}
                                                onChange={e => { minRunTemp.current = e; onChange(e); }}
                                                value={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        rules={{
                                            required: 'Required Field',
                                            validate: {
                                                lte: value => (preCoolReefer.current && maxRunTemp.current ? value <= preCoolReefer.current && value <= maxRunTemp.current : true) || 'Min Run Temp must be less than or equal to the Pre-Cool Temp and Max Run Temp'
                                            }
                                        }}
                                        name='minRunTemp'
                                    />
                                </Col>
                                <Col span={8}>
                                    <FormItem {...formItemLayout} label="Max Run Temp" required format="vertical"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <InputNumber
                                                placeholder="Max Run Temp"
                                                style={{ width: '100%' }}
                                                onBlur={onBlur}
                                                onChange={e => { maxRunTemp.current = e; onChange(e); }}
                                                value={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        rules={{
                                            required: 'Required Field',
                                            validate: {
                                                gte: value => (preCoolReefer.current && minRunTemp.current ? value >= preCoolReefer.current && value >= minRunTemp.current : true) || 'Max Run Temp must be greater than or equal to the Pre-Cool Temp and Min Run Temp'
                                            }
                                        }}
                                        name='maxRunTemp'
                                    />
                                </Col>
                            </Row>
                        </Fieldset>
                    ) : null}
                    <Fieldset legend="Additional Equipment and Services">
                        <FormItem {...formItemLayout} format="vertical"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Checkbox.Group
                                    onBlur={onBlur}
                                    options={equipmentSpecificOptions}
                                    onChange={(checkedValues) => { onChange(checkedValues); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            rules={{ required: false }}
                            name='equipmentSpecifics'
                        />
                    </Fieldset>
                    <Fieldset legend="Driver Requirements">
                        <FormItem {...formItemLayout} format="vertical"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Checkbox.Group
                                    onBlur={onBlur}
                                    options={driverRequirementOptions}
                                    onChange={(checkedValues) => { onChange(checkedValues); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            rules={{ required: false }}
                            name='driverRequirements'
                        />
                    </Fieldset>

                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Save Changes" />
            </Form>
        </FormProvider>
    );
};

export default EditLoadEquipment;