import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { Button, Row, Col, Spin, Select, Card, message, Drawer } from 'antd';
import { without } from 'lodash';
import classes from './DispatchChangeAsset.module.scss';
import StringFormatter from '../../shared/stringFormatter';
import Enums from '../../shared/enums';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt, faTimes, faTruck } from '@fortawesome/free-solid-svg-icons';
import { equalsIgnoreOrder, isBooleanTrue, isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import LoadStopUtils from '../../api/utils/loadStopUtils';
import LoadUtils from '../../api/utils/loadUtils';
import { PlusOutlined } from '@ant-design/icons';
import NewAsset from '../NewAsset/NewAsset';

const stringFormatter = new StringFormatter();

const DispatchChangeAsset = ({ load, assets, asset, bundledLoads, assetLoads, transportUnits, loadDriverIds, carrierId, cancel, isBundledLoad = false }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    //#endregion
    //#region Enums

    const assetTypeOptions = Enums.AssetTypes.selectListOptions();

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loads.isRecordUpdateLoading);
    const error = useSelector(state => state.loads.updateRecordError);

    //#endregion
    //#region useStates

    const [selectedAssetTypes, setSelectedAssetTypes] = useState([]);
    const [assetMap, setAssetMap] = useState([]);
    const [filteredTransportUnits, setFilteredTransportUnits] = useState([]);
    const [showNewAsset, setShowNewAsset] = useState(false);

    //#endregion
    //#region toggles

    const toggleNewAsset = () => {
        setShowNewAsset(!showNewAsset);
    };

    //#endregion
    //#region asset methods

    const onChangeAsset = (assetId) => {
        if (isStringNotEmpty(assetId) && isObjectNotEmpty(load) && isObjectNotEmpty(asset)) {
            let assetIds = isListNotEmpty(load.assetIds) ? load.assetIds : [];
            let updatedAssetIds = [];
            // check to see if it is a duplicate and if it is found in the assetIds array already, throw an error
            if (isListNotEmpty(assetIds)) {
                if (assetIds.find(i => i === assetId) !== undefined) {
                    message.error('The asset you chose is already assigned to this load.', 5);
                    return;
                } else {
                    updatedAssetIds = without(assetIds, asset.id);
                    updatedAssetIds.push(assetId);
                }
            } else {
                updatedAssetIds.push(assetId);
            }

            let payload = {
                assetIds: updatedAssetIds
            };
            // console.log(payload);

            if (isBooleanTrue(isBundledLoad)) {
                dispatch(actionCreators.updateBundledLoad(load.id, payload, load));
            } else {
                dispatch(actionCreators.updateLoad(load.id, payload));
            }
        } else {
            onDone();
        }
    };

    const onRemoveAsset = () => {
        if (isObjectNotEmpty(load) && isObjectNotEmpty(asset)) {
            let assetIds = isListNotEmpty(load.assetIds) ? load.assetIds : [];

            let updatedAssetIds = without(assetIds, asset.id);
            // console.log(updatedAssetIds);

            let payload = {
                assetIds: updatedAssetIds
            };
            // console.log(payload);

            if (isBooleanTrue(isBundledLoad)) {
                dispatch(actionCreators.updateBundledLoad(load.id, payload, load));
            } else {
                dispatch(actionCreators.updateLoad(load.id, payload));
            }
        } else {
            onDone();
        }
    };

    const onChangeTransportUnit = (assets) => {
        if (isListNotEmpty(assets) && isObjectNotEmpty(load) && isObjectNotEmpty(asset)) {
            let assetIds = isListNotEmpty(load.assetIds) ? load.assetIds : [];
            let updatedAssetIds = [];
            // check to see if it is a duplicate and if it is found in the assetIds array already, throw an error
            if (isListNotEmpty(assetIds)) {
                updatedAssetIds = without(assetIds, asset.id);
                assets.forEach((selectedAsset) => {
                    if (assetIds.find(i => i === selectedAsset.id) !== undefined) {
                        message.error('The asset you chose is already assigned to this load.', 5);
                        return;
                    } else {
                        updatedAssetIds.push(selectedAsset.id);
                    }
                });
            } else {
                assets.forEach((selectedAsset) => {
                    updatedAssetIds.push(selectedAsset.id);
                });
            }

            let payload = {
                assetIds: updatedAssetIds
            };
            // console.log(payload);

            if (isBooleanTrue(isBundledLoad)) {
                dispatch(actionCreators.updateBundledLoad(load.id, payload, load));
            } else {
                dispatch(actionCreators.updateLoad(load.id, payload));
            }
        } else {
            onDone();
        }
    };

    const onDone = () => {
        dispatch(actionCreators.updateLoadCancel());
        cancel();
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLoadErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onDone();
        }
    }, [isLoading, error]);

    useMemo(() => {
        if (isListNotEmpty(assets) && isObjectNotEmpty(asset) && isObjectNotEmpty(load)) {
            let assetsArray = [];

            let existingLoadAssetIds = isListNotEmpty(load.assetIds) ? [...load.assetIds] : [];

            assets.filter(i => i.id === asset.id).forEach((existingAsset) => {
                let existingAssetBundledLoads = [];
                let existingAssetOtherLoads = [];
                if (isListNotEmpty(bundledLoads)) {
                    bundledLoads.forEach((assetBundledLoad, loadIndex) => {
                        if (assetBundledLoad.isDeleted === false && LoadUtils.loadStatusIsNot(assetBundledLoad.loadStatus, ['CANCELLED']) && isListNotEmpty(assetBundledLoad.assetIds) && assetBundledLoad.assetIds.includes(existingAsset.id)) {
                            assetBundledLoad.loadName = LoadUtils.getChildLoadName(assetBundledLoad, loadIndex);
                            assetBundledLoad.loadNumber = loadIndex + 1;
                            if (existingAssetBundledLoads.find(i => i.id === assetBundledLoad.id) === undefined) {
                                existingAssetBundledLoads.push(assetBundledLoad);
                            }
                        }
                    });
                }
                if (isListNotEmpty(assetLoads)) {
                    assetLoads.forEach((assetOtherLoad) => {
                        if (assetOtherLoad.isDeleted === false && LoadUtils.loadStatusIsNot(assetOtherLoad.loadStatus, ['CANCELLED']) && isListNotEmpty(assetOtherLoad.assetIds) && assetOtherLoad.assetIds.includes(existingAsset.id)) {
                            if (existingAssetOtherLoads.find(i => i.id === assetOtherLoad.id) === undefined) {
                                existingAssetOtherLoads.push(assetOtherLoad);
                            }
                        }
                    });
                }
                assetsArray.push({
                    ...existingAsset,
                    asset: existingAsset,
                    assetBundledLoads: existingAssetBundledLoads,
                    assetOtherLoads: existingAssetOtherLoads
                });
            });

            if (isListNotEmpty(selectedAssetTypes)) {
                if (isListNotEmpty(existingLoadAssetIds)) {
                    assets.filter(i => i.id !== asset.id && !existingLoadAssetIds.includes(i.id) && selectedAssetTypes.includes(i.assetType)).forEach((otherAsset) => {
                        let otherAssetBundledLoads = [];
                        let otherAssetOtherLoads = [];
                        if (isListNotEmpty(bundledLoads)) {
                            bundledLoads.forEach((assetBundledLoad, loadIndex) => {
                                if (assetBundledLoad.isDeleted === false && LoadUtils.loadStatusIsNot(assetBundledLoad.loadStatus, ['CANCELLED']) && isListNotEmpty(assetBundledLoad.assetIds) && assetBundledLoad.assetIds.includes(otherAsset.id)) {
                                    assetBundledLoad.loadName = LoadUtils.getChildLoadName(assetBundledLoad, loadIndex);
                                    assetBundledLoad.loadNumber = loadIndex + 1;
                                    if (otherAssetBundledLoads.find(i => i.id === assetBundledLoad.id) === undefined) {
                                        otherAssetBundledLoads.push(assetBundledLoad);
                                    }
                                }
                            });
                        }
                        if (isListNotEmpty(assetLoads)) {
                            assetLoads.forEach((assetOtherLoad) => {
                                if (assetOtherLoad.isDeleted === false && LoadUtils.loadStatusIsNot(assetOtherLoad.loadStatus, ['CANCELLED']) && isListNotEmpty(assetOtherLoad.assetIds) && assetOtherLoad.assetIds.includes(otherAsset.id)) {
                                    if (otherAssetOtherLoads.find(i => i.id === assetOtherLoad.id) === undefined) {
                                        otherAssetOtherLoads.push(assetOtherLoad);
                                    }
                                }
                            });
                        }

                        assetsArray.push({
                            ...otherAsset,
                            asset: otherAsset,
                            assetBundledLoads: otherAssetBundledLoads,
                            assetOtherLoads: otherAssetOtherLoads
                        });
                    });
                } else {
                    assets.filter(i => i.id !== asset.id && selectedAssetTypes.includes(i.assetType)).forEach((otherAsset) => {
                        let otherAssetBundledLoads = [];
                        let otherAssetOtherLoads = [];
                        if (isListNotEmpty(bundledLoads)) {
                            bundledLoads.forEach((assetBundledLoad, loadIndex) => {
                                if (assetBundledLoad.isDeleted === false && LoadUtils.loadStatusIsNot(assetBundledLoad.loadStatus, ['CANCELLED']) && isListNotEmpty(assetBundledLoad.assetIds) && assetBundledLoad.assetIds.includes(otherAsset.id)) {
                                    assetBundledLoad.loadName = LoadUtils.getChildLoadName(assetBundledLoad, loadIndex);
                                    assetBundledLoad.loadNumber = loadIndex + 1;
                                    if (otherAssetBundledLoads.find(i => i.id === assetBundledLoad.id) === undefined) {
                                        otherAssetBundledLoads.push(assetBundledLoad);
                                    }
                                }
                            });
                        }
                        if (isListNotEmpty(assetLoads)) {
                            assetLoads.forEach((assetOtherLoad) => {
                                if (assetOtherLoad.isDeleted === false && LoadUtils.loadStatusIsNot(assetOtherLoad.loadStatus, ['CANCELLED']) && isListNotEmpty(assetOtherLoad.assetIds) && assetOtherLoad.assetIds.includes(otherAsset.id)) {
                                    if (otherAssetOtherLoads.find(i => i.id === assetOtherLoad.id) === undefined) {
                                        otherAssetOtherLoads.push(assetOtherLoad);
                                    }
                                }
                            });
                        }

                        assetsArray.push({
                            ...otherAsset,
                            asset: otherAsset,
                            assetBundledLoads: otherAssetBundledLoads,
                            assetOtherLoads: otherAssetOtherLoads
                        });
                    });
                }
            } else {
                if (isListNotEmpty(existingLoadAssetIds)) {
                    assets.filter(i => i.id !== asset.id && !existingLoadAssetIds.includes(i.id)).forEach((otherAsset) => {
                        let otherAssetBundledLoads = [];
                        let otherAssetOtherLoads = [];
                        if (isListNotEmpty(bundledLoads)) {
                            bundledLoads.forEach((assetBundledLoad, loadIndex) => {
                                if (assetBundledLoad.isDeleted === false && LoadUtils.loadStatusIsNot(assetBundledLoad.loadStatus, ['CANCELLED']) && isListNotEmpty(assetBundledLoad.assetIds) && assetBundledLoad.assetIds.includes(otherAsset.id)) {
                                    assetBundledLoad.loadName = LoadUtils.getChildLoadName(assetBundledLoad, loadIndex);
                                    assetBundledLoad.loadNumber = loadIndex + 1;
                                    if (otherAssetBundledLoads.find(i => i.id === assetBundledLoad.id) === undefined) {
                                        otherAssetBundledLoads.push(assetBundledLoad);
                                    }
                                }
                            });
                        }
                        if (isListNotEmpty(assetLoads)) {
                            assetLoads.forEach((assetOtherLoad) => {
                                if (assetOtherLoad.isDeleted === false && LoadUtils.loadStatusIsNot(assetOtherLoad.loadStatus, ['CANCELLED']) && isListNotEmpty(assetOtherLoad.assetIds) && assetOtherLoad.assetIds.includes(otherAsset.id)) {
                                    if (otherAssetOtherLoads.find(i => i.id === assetOtherLoad.id) === undefined) {
                                        otherAssetOtherLoads.push(assetOtherLoad);
                                    }
                                }
                            });
                        }

                        assetsArray.push({
                            ...otherAsset,
                            asset: otherAsset,
                            assetBundledLoads: otherAssetBundledLoads,
                            assetOtherLoads: otherAssetOtherLoads
                        });
                    });
                } else {
                    assets.filter(i => i.id !== asset.id).forEach((otherAsset) => {
                        let otherAssetBundledLoads = [];
                        let otherAssetOtherLoads = [];
                        if (isListNotEmpty(bundledLoads)) {
                            bundledLoads.forEach((assetBundledLoad, loadIndex) => {
                                if (assetBundledLoad.isDeleted === false && LoadUtils.loadStatusIsNot(assetBundledLoad.loadStatus, ['CANCELLED']) && isListNotEmpty(assetBundledLoad.assetIds) && assetBundledLoad.assetIds.includes(otherAsset.id)) {
                                    assetBundledLoad.loadName = LoadUtils.getChildLoadName(assetBundledLoad, loadIndex);
                                    assetBundledLoad.loadNumber = loadIndex + 1;
                                    if (otherAssetBundledLoads.find(i => i.id === assetBundledLoad.id) === undefined) {
                                        otherAssetBundledLoads.push(assetBundledLoad);
                                    }
                                }
                            });
                        }
                        if (isListNotEmpty(assetLoads)) {
                            assetLoads.forEach((assetOtherLoad) => {
                                if (assetOtherLoad.isDeleted === false && LoadUtils.loadStatusIsNot(assetOtherLoad.loadStatus, ['CANCELLED']) && isListNotEmpty(assetOtherLoad.assetIds) && assetOtherLoad.assetIds.includes(otherAsset.id)) {
                                    if (otherAssetOtherLoads.find(i => i.id === assetOtherLoad.id) === undefined) {
                                        otherAssetOtherLoads.push(assetOtherLoad);
                                    }
                                }
                            });
                        }

                        assetsArray.push({
                            ...otherAsset,
                            asset: otherAsset,
                            assetBundledLoads: otherAssetBundledLoads,
                            assetOtherLoads: otherAssetOtherLoads
                        });
                    });
                }
            }

            setAssetMap(assetsArray);
            // console.log(assetsArray);
        } else {
            setAssetMap([]);
        }
    }, [assets, bundledLoads, assetLoads, asset, selectedAssetTypes, load]);

    useMemo(() => {
        // filter transport units if loadDriverIds is provided
        if (isListNotEmpty(transportUnits) && isObjectNotEmpty(asset)) {
            if (isListNotEmpty(loadDriverIds)) {
                let transportUnitArray = transportUnits.filter(t => equalsIgnoreOrder(t.driverIds, loadDriverIds) && !t.assetIds.includes(asset.id));
                setFilteredTransportUnits(transportUnitArray);
            } else {
                setFilteredTransportUnits([]);
            }
        } else {
            setFilteredTransportUnits([]);
        }
    }, [transportUnits, loadDriverIds, asset]);

    //#endregion
    //#region display

    const getAssetLoadsDisplay = (asset, assetBundledLoads, assetOtherLoads) => {
        let assetLoadRows = [];
        let loadsToShow = [];

        if (isListNotEmpty(assetBundledLoads)) {
            assetBundledLoads.forEach((loadObj) => {
                if (loadsToShow.find(i => i.id === loadObj.id) === undefined && loadObj.id !== asset.id) {
                    loadsToShow.push(loadObj);
                }
            });
        }

        if (isListNotEmpty(assetOtherLoads)) {
            assetOtherLoads.forEach((loadObj) => {
                if (loadsToShow.find(i => i.id === loadObj.id) === undefined && loadObj.id !== asset.id) {
                    loadsToShow.push(loadObj);
                }
            });
        }

        if (isListNotEmpty(loadsToShow)) {
            loadsToShow.forEach((loadObj) => {
                let title = isStringNotEmpty(loadObj.loadName) ? loadObj.loadName : LoadUtils.getLoadName(loadObj);
                let time = LoadStopUtils.getOriginAndDestinationTimes(loadObj.origin, loadObj.destination);

                assetLoadRows.push(
                    <div key={`asset-load-${asset.id}-${loadObj.id}`}>
                        <span>{`${title} (${time})`}</span>
                    </div>
                );
            });
        }

        return (
            <div key={`asset-loads-${asset.id}`} style={{ marginTop: 24, marginBottom: 24 }}>
                <div><strong>Loads Assigned:</strong></div>
                {isListNotEmpty(assetLoadRows) ? assetLoadRows : <div><span>No Other Loads</span></div>}
            </div>
        );
    };

    const getTransportUnitLoadsDisplay = (loads, transportUnitIndex) => {
        let transportUnitLoadRows = [];
        let loadsToShow = [];

        if (isListNotEmpty(loads)) {
            loads.forEach((loadObj) => {
                if (loadsToShow.find(i => i.id === loadObj.id) === undefined) {
                    loadsToShow.push(loadObj);
                }
            });
        }

        if (isListNotEmpty(loadsToShow)) {
            loadsToShow.forEach((loadObj, index) => {
                let title = isStringNotEmpty(loadObj.loadName) ? loadObj.loadName : LoadUtils.getLoadName(loadObj);
                let time = LoadStopUtils.getOriginAndDestinationTimes(loadObj.origin, loadObj.destination);

                transportUnitLoadRows.push(
                    <div key={`transport-unit-load-${index}-${loadObj.id}`}>
                        <span>{`${title} (${time})`}</span>
                    </div>
                );
            });
        }

        return (
            <div key={`transport-unit-loads-${transportUnitIndex}`} style={{ marginTop: 12, marginBottom: 12 }}>
                <div><strong>Loads Assigned:</strong></div>
                {isListNotEmpty(transportUnitLoadRows) ? transportUnitLoadRows : <div><span>N/A</span></div>}
            </div>
        );
    };

    const getAssetDisplay = (asset, index) => {
        if (isObjectNotEmpty(asset)) {
            let assetType = stringFormatter.toFormattedString("AssetType", asset.assetType, null);
            let trailerType = (asset.assetType === 'TRAILER' || asset.assetType === 'COMBINED') ? (isObjectNotEmpty(asset.trailerType) ? asset.trailerType.description : '') : null;
            let licensePlate = `${asset.licensePlateNum} ${asset.licensePlateState}`;
            let assetName = isStringNotEmpty(asset.name) ? asset.name : null;

            return (
                <div key={`asset-row-${index}`} style={{ marginBottom: 4 }}>
                    <span><strong>{isStringNotEmpty(assetName) ? assetName + ', ' : ''}{isStringNotEmpty(licensePlate) ? licensePlate : ''}</strong><br /><i>Type: {assetType}{isStringNotEmpty(trailerType) ? ' - ' + trailerType : ''}</i></span>
                </div>
            );
        } else {
            return null;
        }
    };

    const getDriversDisplay = (drivers) => {
        let driverRows = [];
        if (isListNotEmpty(drivers)) {
            drivers.forEach((driver, index) => {
                driverRows.push(
                    <div key={`driver-row-${index}-${driver.id}`} style={{ marginBottom: 4 }}>
                        <span>{driver.firstName} {driver.lastName}</span>
                    </div>
                );
            });
        }

        return (
            <div style={{ marginTop: 12, marginBottom: 12 }}>
                <div><strong>Drivers Assigned to Asset(s):</strong></div>
                {isListNotEmpty(driverRows) ? driverRows : <div><span>N/A</span></div>}
            </div>
        );
    };

    //#endregion

    return (
        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
            <Row gutter={[24, 24]}>
                {isObjectNotEmpty(asset) && isListNotEmpty(assetMap) ? (
                    <Col span={24}>
                        {assetMap.filter(i => i.id === asset.id).map((asset, index) => {
                            return (
                                <Card
                                    key={`dispatch-change-asset-card-${asset.id}`}
                                    style={{ marginBottom: 12, backgroundColor: '#FFFBE5', borderRadius: '15px' }}
                                    title={
                                        <div style={{ display: 'flex' }}>
                                            <div className={classes.iconContainer}><FontAwesomeIcon className={classes.icon} icon={faTruck} /></div>
                                            <div className={classes.textContainer}>
                                                {getAssetDisplay(asset, index)}
                                            </div>
                                        </div>
                                    }
                                    extra={<Button danger type="primary" icon={<FontAwesomeIcon className="anticon" icon={faTimes} />} shape="circle" onClick={(e) => { onRemoveAsset(); }}></Button>}
                                >
                                    <div><span><strong>Available: {isObjectNotEmpty(asset) ? stringFormatter.toYesNo(asset.isAvailable) : 'No'}</strong></span></div>
                                    {getAssetLoadsDisplay(asset, asset.assetBundledLoads, asset.assetOtherLoads)}
                                </Card>
                            );
                        })}
                    </Col>
                ) : null}
                {isListNotEmpty(filteredTransportUnits) ? (
                    <Col span={24}>
                        <div style={{ marginBottom: 12 }}><span><strong>Assets already associated with Driver(s)</strong></span></div>
                        {filteredTransportUnits.map((transportUnit, index) => {
                            return (
                                <Card
                                    style={{ marginBottom: 12, backgroundColor: '#F6FFED', border: '1px solid #b7eb8f', borderRadius: '15px' }}
                                    headStyle={{ borderBottom: '1px solid #b7eb8f' }}
                                    title={
                                        <Row gutter={[12, 12]}>
                                            {isListNotEmpty(transportUnit.assets) ? transportUnit.assets.map((asset, index) => {
                                                return (
                                                    <Col key={`asset-row-${index}`} span={24}>
                                                        <div style={{ display: 'flex' }}>
                                                            <div className={classes.iconContainer}><FontAwesomeIcon className={classes.icon} icon={faTruck} /></div>
                                                            <div className={classes.textContainer}>
                                                                {getAssetDisplay(asset, index)}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                );
                                            }) : null}
                                        </Row>
                                    }
                                    extra={<Button type="primary" icon={<PlusOutlined />} shape="circle" onClick={(e) => { onChangeTransportUnit(transportUnit.assets); }}></Button>}
                                >
                                    {getDriversDisplay(transportUnit.drivers)}
                                    {getTransportUnitLoadsDisplay(transportUnit.loads, index)}
                                </Card>
                            );
                        })}
                    </Col>
                ) : null}
                <Col span={24}>
                    <Select
                        placeholder="Asset Types: All"
                        mode="multiple"
                        allowClear={true}
                        style={{ width: '100%' }}
                        virtual={false}
                        onChange={(selected) => { setSelectedAssetTypes(selected); }}
                        showSearch={true}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {assetTypeOptions}
                    </Select>
                </Col>
                <Col span={24}>
                    {isObjectNotEmpty(asset) && isListNotEmpty(assetMap) ? (
                        <div style={{ height: 500, overflowY: 'auto' }}>
                            {assetMap.filter(i => i.id !== asset.id).map((asset, index) => {
                                return (
                                    <Card
                                        key={`dispatch-change-asset-card-${asset.id}`}
                                        style={{ marginBottom: 12, borderRadius: '15px' }}
                                        title={
                                            <div style={{ display: 'flex' }}>
                                                <div className={classes.iconContainer}><FontAwesomeIcon className={classes.icon} icon={faTruck} /></div>
                                                <div className={classes.textContainer}>
                                                    {getAssetDisplay(asset, index)}
                                                </div>
                                            </div>
                                        }
                                        extra={<Button type="primary" icon={<FontAwesomeIcon className="anticon" icon={faExchangeAlt} />} shape="circle" onClick={(e) => { onChangeAsset(asset.id); }}></Button>}
                                    >
                                        <div><span><strong>Available: {isObjectNotEmpty(asset) ? stringFormatter.toYesNo(asset.isAvailable) : 'No'}</strong></span></div>
                                        {getAssetLoadsDisplay(asset, asset.assetBundledLoads, asset.assetOtherLoads)}
                                    </Card>
                                );
                            })}
                        </div>
                    ) : null}
                </Col>
                <Col span={24}>
                    <b>Asset Doesn't Exist Yet?</b>
                    <br />
                    <Button type='primary' onClick={toggleNewAsset}>Add New Asset to Account</Button>
                </Col>
            </Row>
            <Drawer
                title={'Add New Asset'}
                width={fullWidth > 720 ? fullWidth / 2 : 360}
                onClose={toggleNewAsset}
                visible={showNewAsset}
                bodyStyle={{ paddingBottom: 80 }}
                style={{ zIndex: 1000 }}
                destroyOnClose={true}
                closable={false}
                maskClosable={false}
            >
                <NewAsset cancel={toggleNewAsset} accountId={carrierId} />
            </Drawer>
        </Spin>
    );
};

export default DispatchChangeAsset;