import * as actionTypes from "../actions/actionTypes";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { isBooleanTrue, isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import ErrorUtils from "../../shared/errorUtils";

//#region Fetch Scheduled Shift List Methods

export const fetchScheduledShiftListStart = (listName) => {
    return {
        type: actionTypes.FETCH_SCHEDULED_SHIFT_LIST_START,
        payload: { listName: listName }
    }
};

export const fetchScheduledShiftListSuccess = (listName, payload) => {
    return {
        type: actionTypes.FETCH_SCHEDULED_SHIFT_LIST_SUCCESS,
        payload: { listName: listName, ...payload }
    }
};

export const fetchScheduledShiftListFail = (listName, payload) => {
    return {
        type: actionTypes.FETCH_SCHEDULED_SHIFT_LIST_FAIL,
        payload: { listName: listName, ...payload }
    }
};

export const clearScheduledShiftList = (listName) => {
    return {
        type: actionTypes.CLEAR_SCHEDULED_SHIFT_LIST,
        payload: { listName: listName }
    }
};

export const clearScheduledShiftLists = () => {
    return {
        type: actionTypes.CLEAR_SCHEDULED_SHIFT_LISTS
    }
};

export const addScheduledShiftToScheduledShiftList = (listName, payload) => {
    return {
        type: actionTypes.ADD_TO_SCHEDULED_SHIFT_LIST,
        payload: { listName: listName, newRecord: payload }
    }
};

export const updateScheduledShiftInScheduledShiftList = (listName, payload) => {
    return {
        type: actionTypes.UPDATE_IN_SCHEDULED_SHIFT_LIST,
        payload: { listName: listName, updatedRecord: payload }
    }
};

export const removeScheduledShiftFromScheduledShiftList = (listName, payload) => {
    return {
        type: actionTypes.REMOVE_FROM_SCHEDULED_SHIFT_LIST,
        payload: { listName: listName, recordToRemove: payload }
    }
};

//#endregion
//#region Fetch Scheduled Shift Methods

export const fetchScheduledShiftStart = () => {
    return {
        type: actionTypes.FETCH_SCHEDULED_SHIFT_START
    }
};

export const fetchScheduledShiftSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_SCHEDULED_SHIFT_SUCCESS,
        payload: payload
    }
};

export const fetchScheduledShiftFail = (payload) => {
    return {
        type: actionTypes.FETCH_SCHEDULED_SHIFT_FAIL,
        payload: payload
    }
};

export const clearScheduledShift = () => {
    return {
        type: actionTypes.CLEAR_SCHEDULED_SHIFT
    }
};

//#endregion
//#region Add Scheduled Shift Methods

export const addScheduledShiftStart = () => {
    return {
        type: actionTypes.ADD_SCHEDULED_SHIFT_START
    }
};

export const addScheduledShiftSuccess = () => {
    return {
        type: actionTypes.ADD_SCHEDULED_SHIFT_SUCCESS
    }
};

export const addScheduledShiftFail = (payload) => {
    return {
        type: actionTypes.ADD_SCHEDULED_SHIFT_FAIL,
        payload: payload
    }
};

export const addScheduledShiftLoadingClear = () => {
    return {
        type: actionTypes.ADD_SCHEDULED_SHIFT_LOADING_CLEAR
    }
};

export const addScheduledShiftErrorClear = () => {
    return {
        type: actionTypes.ADD_SCHEDULED_SHIFT_ERROR_CLEAR
    }
};

export const addScheduledShiftCancel = () => {
    return {
        type: actionTypes.ADD_SCHEDULED_SHIFT_CANCEL
    }
};

//#endregion
//#region Update Scheduled Shift Methods

const changeSingleScheduledShift = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_SCHEDULED_SHIFT,
        payload: payload
    }
};

export const updateScheduledShiftStart = () => {
    return {
        type: actionTypes.UPDATE_SCHEDULED_SHIFT_START
    }
};

export const updateScheduledShiftSuccess = () => {
    return {
        type: actionTypes.UPDATE_SCHEDULED_SHIFT_SUCCESS
    }
};

export const updateScheduledShiftFail = (payload) => {
    return {
        type: actionTypes.UPDATE_SCHEDULED_SHIFT_FAIL,
        payload: payload
    }
};

export const updateScheduledShiftLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_SCHEDULED_SHIFT_LOADING_CLEAR
    }
};

export const updateScheduledShiftErrorClear = () => {
    return {
        type: actionTypes.UPDATE_SCHEDULED_SHIFT_ERROR_CLEAR
    }
};

export const updateScheduledShiftCancel = () => {
    return {
        type: actionTypes.UPDATE_SCHEDULED_SHIFT_CANCEL
    }
};

//#endregion
//#region Scheduled Shifts Methods

export const fetchScheduledShiftList = (listName, payload) => {
    return async (dispatch, getState) => {
        if (isStringNotEmpty(listName)) {
            try {
                dispatch(fetchScheduledShiftListStart(listName));

                const state = getState();
                const scheduledShiftsState = { ...state.scheduledShifts };
                const existingLists = { ...scheduledShiftsState.lists };

                let pagination = {};
                let searchParams = {};
                if (isObjectNotEmpty(existingLists[listName])) {
                    pagination = { ...existingLists[listName].pagination };
                    searchParams = { ...existingLists[listName].searchParams };
                }

                if (isObjectNotEmpty(payload)) {
                    searchParams = { ...payload };
                }

                const res = await Data.getScheduledShiftsForCalendar({ ...searchParams }, pagination);
                dispatch(fetchScheduledShiftListSuccess(listName, { records: res.data, params: { searchParams: res.searchParams, pagination: res.pagination } }));
            } catch (error) {
                logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
                dispatch(fetchScheduledShiftListFail(listName, { error: ErrorUtils.getErrorMessage(error) }));
            }
        }
    }
};

export const fetchScheduledShift = (id) => {
    return async (dispatch) => {
        try {
            dispatch(fetchScheduledShiftStart());

            const scheduledShift = await Data.getScheduledShiftForCalendar(id);
            dispatch(fetchScheduledShiftSuccess({ record: scheduledShift }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchScheduledShiftFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const addScheduledShift = (payload) => {
    return async (dispatch) => {
        try {
            dispatch(addScheduledShiftStart());

            const newScheduledShift = await Data.addScheduledShiftForCalendar(payload);
            if (isObjectNotEmpty(newScheduledShift)) {
                dispatch(addScheduledShiftToScheduledShiftList(newScheduledShift.extendedProps.accountId, newScheduledShift));
            }

            dispatch(addScheduledShiftSuccess());
            dispatch(addScheduledShiftLoadingClear());
            dispatch(addScheduledShiftErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addScheduledShiftFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateScheduledShift = (id, payload) => {
    return async (dispatch) => {
        try {
            dispatch(updateScheduledShiftStart());

            if (isBooleanTrue(payload.isDeleted)) {
                const removedScheduledShift = await Data.removeScheduledShift(id);
                if (isObjectNotEmpty(removedScheduledShift)) {
                    dispatch(removeScheduledShiftFromScheduledShiftList(removedScheduledShift.accountId, removedScheduledShift));
                }
            } else {
                const updatedScheduledShift = await Data.updateScheduledShiftForCalendar(id, payload);
                if (isObjectNotEmpty(updatedScheduledShift)) {
                    dispatch(updateScheduledShiftInScheduledShiftList(updatedScheduledShift.extendedProps.accountId, updatedScheduledShift));
                    dispatch(changeSingleScheduledShift(updatedScheduledShift));
                }
            }

            dispatch(updateScheduledShiftSuccess());
            dispatch(updateScheduledShiftLoadingClear());
            dispatch(updateScheduledShiftErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateScheduledShiftFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion