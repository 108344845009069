import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { UploadOutlined, DownOutlined, LinkOutlined } from '@ant-design/icons';
import { Drawer, Button, Dropdown, Menu } from 'antd';
import NewLinkedLoadLane from '../../components/NewLinkedLoadLane/NewLinkedLoadLane';
import EditLinkedLoadLane from "../../components/EditLinkedLoadLane/EditLinkedLoadLane";
import classes from './LinkedLoadLanes.module.scss';
import DataTable from '../../components/DataTable/DataTable';
import StringFormatter from '../../shared/stringFormatter';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools, faRoute } from "@fortawesome/free-solid-svg-icons";
import DataAddressRow from "../../components/DataAddressRow/DataAddressRow";
import { withRouter } from 'react-router-dom';
import * as Data from '../../api/data';
import { isListNotEmpty, isNumberGreaterThanZero, isObjectNotEmpty, isStringEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import NewLoadModal from '../../components/NewLoadModal/NewLoadModal';
import logger from '../../shared/logger';
import { selectListIsLoading, selectListPagination, selectListRecords } from '../../store/utility';
import ColumnSearchFilter from '../../shared/tableColumnSearchFilter';
import LaneUtils from '../../api/utils/laneUtils';

const stringFormatter = new StringFormatter();
const columnSearchFilter = new ColumnSearchFilter();

const LinkedLoadLanes = ({ accountId, linkedAccountId, linkedAccountEntityType, ...props }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;
    const singularEntityName = "Lane";
    const pluralEntityName = "Lanes";

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);
    const records = useSelector(state => selectListRecords(state.linkedLoadLanes.lists, `${isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')}_${isStringNotEmpty(linkedAccountId) ? linkedAccountId : 'ALL'}`));
    const isLoading = useSelector(state => selectListIsLoading(state.linkedLoadLanes.lists, `${isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')}_${isStringNotEmpty(linkedAccountId) ? linkedAccountId : 'ALL'}`));
    const pagination = useSelector(state => selectListPagination(state.linkedLoadLanes.lists, `${isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')}_${isStringNotEmpty(linkedAccountId) ? linkedAccountId : 'ALL'}`));
    const isLoadingAddLinkedLoadLane = useSelector(state => state.linkedLoadLanes.isRecordAddLoading);
    const addError = useSelector(state => state.linkedLoadLanes.addRecordError);
    const accounts = useSelector(state => state.orchestrator.accounts);
    const trailerTypes = useSelector(state => state.orchestrator.trailerTypes);
    const linkedAccounts = useSelector(state => selectListRecords(state.orchestrator.linkedAccounts, isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : null)));

    //#endregion
    //#region useStates

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showNewLoad, setShowNewLoad] = useState(false);
    const [loadLanes, setLoadLanes] = useState([]);
    const [searchText, setSearchText] = useState({ customerLaneId: '' });

    //#endregion
    //#region toggles

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleNewLoad = () => {
        setShowNewLoad(!showNewLoad);
    };

    //#endregion
    //#region goTos

    const goToLinkedLoadLane = (record) => {
        if (isObjectNotEmpty(record) && isStringNotEmpty(record.id) && isStringNotEmpty(record.accountId) && isStringNotEmpty(record.linkedAccountId)) {
        props.history.push({ pathname: `/linkedLoadLanes/${record.id}/${record.accountId}/${record.linkedAccountId}` }, {
            previousPageTitle: "Lanes",
            previousPageLocation: props.location,
            previousBreadcrumbs: props.breadcrumbs,
        });
    }
    };

    //#endregion
    //#region loadLane methods

    const linkLoadLane = (record) => {
        // console.log(linkedAccountId)
        // console.log(record);
        if (isObjectNotEmpty(record)) {
            let payload = {};
            payload.linkedAccountId = isStringNotEmpty(linkedAccountId) ? linkedAccountId : record.accountId;
            payload.accountId = isStringNotEmpty(accountId) ? accountId : (isStringNotEmpty(entityId) && entityType !== 'STAFF' ? entityId : null);
            payload.loadLaneId = record.id;
            payload.laneId = record.laneId;
            payload.locations = record.locations;
            payload.equipmentNeeds = record.equipmentNeeds;

            if (isObjectNotEmpty(payload)) {
                dispatch(actionCreators.addLinkedLoadLane(payload));
            }
        }
    };

    const getOrigin = (loadLane) => {
        let locationDisplay = '';
        if (isListNotEmpty(loadLane.locations)) {
            let origin = loadLane.locations[0];
            if (isObjectNotEmpty(origin.location) && isObjectNotEmpty(origin.location.address)) {
                const locationName = LaneUtils.getLaneStopLocationNameDisplay(origin, loadLane.linkedAccountId, loadLane.accountId, entityType, entityId);
                locationDisplay = (<DataAddressRow key={`address-row-load-lane-${loadLane.id}-location-${origin.locationId}-${origin.sequence}`} title={locationName} address={origin.location.address} />);
            }
        }

        return locationDisplay;
    };

    const getDestination = (loadLane) => {
        let locationDisplay = '';
        if (isListNotEmpty(loadLane.locations)) {
            let destination = loadLane.locations[loadLane.locations.length - 1];
            if (isObjectNotEmpty(destination.location) && isObjectNotEmpty(destination.location.address)) {
                const locationName = LaneUtils.getLaneStopLocationNameDisplay(destination, loadLane.linkedAccountId, loadLane.accountId, entityType, entityId);
                locationDisplay = (<DataAddressRow key={`address-row-load-lane-${loadLane.id}-location-${destination.locationId}-${destination.sequence}`} title={locationName} address={destination.location.address} />);
            }
        }

        return locationDisplay;
    };

    const getCommodities = (loadLane) => {
        let commodityList = [];
        if (isListNotEmpty(loadLane.locations)) {
            let listOfCommodities = new Set();
            loadLane.locations.forEach((stop) => {
                if (isListNotEmpty(stop.commodities)) {
                    stop.commodities.forEach((commodity) => {
                        if (isNumberGreaterThanZero(commodity.unitPackagingInitCount) || isNumberGreaterThanZero(commodity.bulkPackagingInitCount)) {
                            listOfCommodities.add(commodity.name);
                        }
                    });
                }
            });

            commodityList = Array.from(listOfCommodities);
        }

        return commodityList;
    };

    const getLoadLanes = async () => {
        setLoadLanes([]);
        try {
            if (isStringNotEmpty(linkedAccountId)) {
                let searchParams = {
                    page: 1,
                    size: 1000000,
                    isDeleted: false
                };
                searchParams['accountId:or'] = linkedAccountId;
                searchParams['links:or'] = { '$elemMatch': { accountId: linkedAccountId } };

                let existingLoadLaneIds = [];
                if (isListNotEmpty(records)) {
                    existingLoadLaneIds = records.map((item) => item.id);
                    searchParams['id:not'] = existingLoadLaneIds;
                }

                // console.log(existingLoadLaneIds);

                const transformedLoadLanes = await Data.getBulkLoadLanes({ ...searchParams }, accounts, trailerTypes, linkedAccountId);

                setLoadLanes([...transformedLoadLanes]);
            } else {
                setLoadLanes([]);
            }
        } catch (err) {
            logger.logDebugEvent('LinkedLoadLanes.js', err.message, true);
            setLoadLanes([]);
        }
    };

    //#endregion
    //#region table methods

    const refreshTable = () => {
        let searchParams = {
            page: 1,
            size: 1000000,
            isDeleted: false
        };

        if (isStringNotEmpty(accountId) || (isStringNotEmpty(entityId) && entityType !== 'STAFF')) {
            if (isStringNotEmpty(linkedAccountId)) {
                searchParams.links = { '$elemMatch': { accountId: isStringNotEmpty(accountId) ? accountId : (isStringNotEmpty(entityId) && entityType !== 'STAFF' ? entityId : null), linkedAccountId: linkedAccountId } };
            } else {
                searchParams.links = { '$elemMatch': { accountId: isStringNotEmpty(accountId) ? accountId : (isStringNotEmpty(entityId) && entityType !== 'STAFF' ? entityId : null) } };
            }
        }

        dispatch(actionCreators.fetchLinkedLoadLaneList(`${isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')}_${isStringNotEmpty(linkedAccountId) ? linkedAccountId : 'ALL'}`, searchParams, isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : null), linkedAccountId));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchLinkedLoadLaneListSuccess(`${isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')}_${isStringNotEmpty(linkedAccountId) ? linkedAccountId : 'ALL'}`, { params: { pagination: pager } }));

        let searchParams = {
            page: pagination.current,
            size: pagination.pageSize,
            isDeleted: false
        };

        if (isStringNotEmpty(sorter.order)) {
            const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
            searchParams.order = sortOrder;
        }
        if (isStringNotEmpty(sorter.field)) {
            searchParams.sort = sorter.field;
        }

        if (isStringNotEmpty(accountId) || (isStringNotEmpty(entityId) && entityType !== 'STAFF')) {
            if (isStringNotEmpty(linkedAccountId)) {
                if (isListNotEmpty(filters.customerLaneId)) {
                    searchParams.links = { '$elemMatch': { accountId: isStringNotEmpty(accountId) ? accountId : (isStringNotEmpty(entityId) && entityType !== 'STAFF' ? entityId : null), linkedAccountId: linkedAccountId, 'customerLaneId:contains': filters.customerLaneId[0] } };
                } else {
                    searchParams.links = { '$elemMatch': { accountId: isStringNotEmpty(accountId) ? accountId : (isStringNotEmpty(entityId) && entityType !== 'STAFF' ? entityId : null), linkedAccountId: linkedAccountId } };
                }
            } else {
                if (isListNotEmpty(filters.customerLaneId)) {
                    searchParams.links = { '$elemMatch': { accountId: isStringNotEmpty(accountId) ? accountId : (isStringNotEmpty(entityId) && entityType !== 'STAFF' ? entityId : null), 'customerLaneId:contains': filters.customerLaneId[0] } };
                } else {
                    searchParams.links = { '$elemMatch': { accountId: isStringNotEmpty(accountId) ? accountId : (isStringNotEmpty(entityId) && entityType !== 'STAFF' ? entityId : null) } };
                }
            }
        }

        dispatch(actionCreators.fetchLinkedLoadLaneList(`${isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')}_${isStringNotEmpty(linkedAccountId) ? linkedAccountId : 'ALL'}`, searchParams, isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : null), linkedAccountId));
    };

    //#endregion
    //#region table displays

    const menu = (record) => {
        return (
            <Menu>
                <Menu.Item key="createLoadFromLinkedLoadLane" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); toggleNewLoad(); }}>
                    <UploadOutlined style={{ marginRight: 8 }} />
                    <span>Create Load(s)</span>
                </Menu.Item>
                <Menu.Item key="editEntity" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); toggleEditEntity(); }}>
                    <FontAwesomeIcon className="anticon" icon={faRoute} style={{ marginRight: 8 }} />
                    <span>Edit {singularEntityName} Details</span>
                </Menu.Item>
                <Menu.Item key="goToDashboard" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); goToLinkedLoadLane(record); }}>
                    <span>Go to {singularEntityName} Dashboard</span>
                </Menu.Item>
            </Menu>
        );
    };

    const columns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            className: classes.columnNoBreak,
            width: 50,
            render: (text, record) => {
                return (
                    <Dropdown overlay={menu(record)} trigger={['click']}>
                        <Button>Actions <DownOutlined /></Button>
                    </Dropdown>
                );
            },
            align: 'center',
        },
        {
            title: 'Customer Lane Id',
            dataIndex: 'customerLaneId',
            key: 'customerLaneId',
            ...columnSearchFilter.getColumnSearchProps('customerLaneId', 'Customer Lane Id', searchText, setSearchText),
        },
        {
            title: 'Origin',
            dataIndex: 'locations',
            key: 'origin',
            render: (text, record) => { return (getOrigin(record)); }
        },
        {
            title: 'Destination',
            dataIndex: 'locations',
            key: 'destination',
            render: (text, record) => { return (getDestination(record)); }
        },
        {
            title: 'Stop Count',
            dataIndex: 'locations',
            key: 'stopCount',
            render: (text, record) => {
                if (isListNotEmpty(text)) {
                    return text.length;
                } else {
                    return 0;
                }
            }
        },
        {
            title: 'Trailer Type',
            dataIndex: ['equipmentNeeds', 'trailerType', 'description'],
            key: 'equipmentNeeds.trailerType.description',
        },
        {
            title: 'Commodities',
            dataIndex: ['locations', 'commodities'],
            key: 'commodities',
            render: (text, record) => { return (getCommodities(record)); }
        },
        {
            title: 'Approx Dist',
            dataIndex: 'approxDistance',
            key: 'approxDistance',
            render: (text, record) => { return stringFormatter.toFormattedString('Distance', text, record.approxDistanceUnit ? record.approxDistanceUnit : null); }
        },
        {
            title: 'Contract Rate',
            dataIndex: 'contractRate',
            key: 'contractRate',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
        },
        {
            title: 'Per Mile Rate',
            dataIndex: 'perMileRate',
            key: 'perMileRate',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
        },
    ];

    if (isStringEmpty(linkedAccountId)) {
        columns.push({
            title: 'Account',
            dataIndex: 'linkedAccountId',
            key: 'linkedAccountId',
            render: (text, record) => {
                let linkedAccount = linkedAccounts.find(a => a.id === text);
                let account = accounts.find(a => a.id === text);
                if (isObjectNotEmpty(linkedAccount) && isStringNotEmpty(linkedAccount.name)) {
                    return linkedAccount.name;
                } else if (isObjectNotEmpty(account) && isStringNotEmpty(account.name)) {
                    return account.name;
                } else {
                    return '';
                }
            },
        });
    }

    const loadLaneColumns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            className: classes.columnNoBreak,
            width: 50,
            render: (text, record) => {
                return (
                    <Button icon={<LinkOutlined />} type="primary" shape="circle" onClick={(e) => { e.stopPropagation(); linkLoadLane(record); }} />
                );
            },
            align: 'center',
        },
        {
            title: 'Customer Lane Id',
            dataIndex: 'customerLaneId',
            key: 'customerLaneId',
        },
        {
            title: 'Origin',
            dataIndex: 'locations',
            key: 'origin',
            render: (text, record) => { return (getOrigin(record)); }
        },
        {
            title: 'Destination',
            dataIndex: 'locations',
            key: 'destination',
            render: (text, record) => { return (getDestination(record)); }
        },
        {
            title: 'Stop Count',
            dataIndex: 'locations',
            key: 'stopCount',
            render: (text, record) => {
                if (isListNotEmpty(text)) {
                    return text.length;
                } else {
                    return 0;
                }
            }
        },
        {
            title: 'Trailer Type',
            dataIndex: ['equipmentNeeds', 'trailerType', 'description'],
            key: 'equipmentNeeds.trailerType.description',
        },
        {
            title: 'Commodities',
            dataIndex: ['locations', 'commodities'],
            key: 'commodities',
            render: (text, record) => { return (getCommodities(record)); }
        },
        {
            title: 'Approx Dist',
            dataIndex: 'approxDistance',
            key: 'approxDistance',
            render: (text, record) => { return stringFormatter.toFormattedString('Distance', text, record.approxDistanceUnit ? record.approxDistanceUnit : null); }
        },
    ];

    //#endregion
    //#region displays

    const newEntityComponents = (
        <Drawer
            title={"New " + singularEntityName}
            onClose={toggleNewEntity}
            visible={showNewEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewLinkedLoadLane
                cancel={toggleNewEntity}
                accountId={isStringNotEmpty(accountId) ? accountId : (isStringNotEmpty(entityId) && entityType !== 'STAFF' ? entityId : null)}
                linkedAccountId={linkedAccountId}
                linkedAccountEntityType={linkedAccountEntityType}
            />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName}
            onClose={toggleEditEntity}
            visible={showEditEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditLinkedLoadLane
                cancel={toggleEditEntity}
                linkedLoadLane={selectedRecord}
            />
        </Drawer>
    );

    //#endregion
    //#region useMemos and useEffects

    useMemo(() => {
        dispatch(actionCreators.getAccounts());
        dispatch(actionCreators.getTrailerTypes());
    }, []);

    useMemo(() => {
        dispatch(actionCreators.getLinkedAccounts(isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : null), false));
    }, [accountId, entityId]);

    useEffect(() => {
        refreshTable();
    }, [accountId, entityId]);

    useEffect(() => {
        getLoadLanes();
    }, [records, accountId, entityId]);

    //#endregion

    return (
        <>
            <DataTable
                dataSource={records}
                columns={columns}
                hidePaging={true}
                //pagination={pagination}
                onChange={handleTableChange}
                loading={isLoading === true || isLoadingAddLinkedLoadLane === true}
                rowClassName={classes.dataTableRow}
                singularEntityName={singularEntityName}
                pluralEntityName={pluralEntityName}
                newEntityAction={toggleNewEntity}
                rowKey={record => record.id}
                style={{ backgroundColor: '#ffffff' }}
            >
                {newEntityComponents}
                {editEntityComponents}
                <NewLoadModal key='new-load' showModal={showNewLoad} toggleModal={() => { toggleNewLoad(); setSelectedRecord({}); }} shipperId={isObjectNotEmpty(selectedRecord) && isStringNotEmpty(selectedRecord.linkedAccountId) ? selectedRecord.linkedAccountId : null} linkedLoadLaneId={isObjectNotEmpty(selectedRecord) && isStringNotEmpty(selectedRecord.id) ? selectedRecord.id : null} />
            </DataTable>
            {(isStringNotEmpty(linkedAccountId) && isListNotEmpty(loadLanes)) ? (
                <DataTable
                    dataSource={loadLanes}
                    columns={loadLaneColumns}
                    hidePaging={true}
                    loading={isLoading === true || isLoadingAddLinkedLoadLane === true}
                    rowClassName={classes.dataTableRow}
                    title={() => (<b>This account has other Lanes. Would you like to copy them to your list of Lanes?</b>)}
                    rowKey={record => record.id}
                    style={{ backgroundColor: '#ffffff', marginTop: 24 }}
                />
            ) : null}
        </>
    );
};

export default withRouter(LinkedLoadLanes);