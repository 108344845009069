import { isListNotEmpty, isNotNullOrUndefined, isNumberNotEmpty, isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import EntityUtils from "../entityUtils";

export const getLocationDTO = (location, accounts = [], commodities = [], accountId = null, linkedAccountId = null) => {
    let locationObj = {
        id: location.id,
        irisId: location.irisId,
        longitude: location.longitude,
        latitude: location.latitude,
        coordinates: location.coordinates,
        links: location.links,
        address: location.address,
        timeZone: location.timeZone
    };

    if (isListNotEmpty(location.links)) {
        let links = [];
        location.links.forEach((link) => {
            let transformedLink = { ...link };

            // Get Account Info
            transformedLink.account = EntityUtils.getAccountInfo(link.accountId, accounts);
            if (isStringNotEmpty(link.linkedAccountId)) {
                transformedLink.linkedAccount = EntityUtils.getAccountInfo(link.linkedAccountId, accounts);
            }

            // Get Commodities Info
            if (isListNotEmpty(link.commodityIds)) {
                let commodityArray = [];
                link.commodityIds.forEach((commodityId) => {
                    const commodity = EntityUtils.getCommodityInfo(commodityId, commodities);
                    if (isObjectNotEmpty(commodity)) {
                        commodityArray.push(commodity);
                    }
                });
                transformedLink.commodities = commodityArray;
            } else {
                transformedLink.commodities = [];
            }
            links.push(transformedLink);
        });

        locationObj.links = links;
    }

    if (isStringNotEmpty(accountId)) {
        if (isListNotEmpty(location.links)) {
            let link = location.links.find(l => l.accountId === accountId);
            if (isStringNotEmpty(linkedAccountId)) {
                link = location.links.find(l => l.accountId === accountId && l.linkedAccountId === linkedAccountId);
            }
            if (isObjectNotEmpty(link)) {
                locationObj.accountId = link.accountId;
                locationObj.linkedAccountId = link.linkedAccountId;
                locationObj.name = link.name;
                locationObj.specialInstructions = link.specialInstructions;
                locationObj.lumperFee = link.lumperFee;
                locationObj.lumperFeeUnit = link.lumperFeeUnit;
                locationObj.pointOfContact = link.pointOfContact;
                locationObj.commodityIds = link.commodityIds;
                locationObj.appointmentType = link.appointmentType;
                locationObj.locationTypes = link.locationTypes;

                // Get Account Info
                locationObj.account = EntityUtils.getAccountInfo(link.accountId, accounts);

                // Get LinkedAccount Info
                if (isStringNotEmpty(link.linkedAccountId)) {
                    locationObj.linkedAccount = EntityUtils.getAccountInfo(link.linkedAccountId, accounts);
                }

                // Get Commodities Info
                let commodityArray = [];
                if (isListNotEmpty(link.commodityIds)) {
                    link.commodityIds.forEach((commodityId) => {
                        const commodity = EntityUtils.getCommodityInfo(commodityId, commodities);
                        if (isObjectNotEmpty(commodity)) {
                            commodityArray.push(commodity);
                        }
                    });
                }
                locationObj.commodities = commodityArray;
            }
        }
    }

    return locationObj;
};

export const getLocationDTOs = (location, accounts = [], commodities = [], accountId = null, linkedAccountId = null) => {
    let locationArray = [];

    if (isListNotEmpty(location.links)) {
        if (isStringNotEmpty(accountId)) {
            if (isStringNotEmpty(linkedAccountId)) {
                location.links.filter(l => l.accountId === accountId && l.linkedAccountId === linkedAccountId).forEach((link) => {
                    let locationObj = {
                        id: location.id,
                        irisId: location.irisId,
                        longitude: location.longitude,
                        latitude: location.latitude,
                        coordinates: location.coordinates,
                        links: location.links,
                        address: location.address,
                        timeZone: location.timeZone
                    };
                    
                    if (isObjectNotEmpty(link)) {
                        locationObj.accountId = link.accountId;
                        locationObj.linkedAccountId = link.linkedAccountId;
                        locationObj.name = link.name;
                        locationObj.specialInstructions = link.specialInstructions;
                        locationObj.lumperFee = link.lumperFee;
                        locationObj.lumperFeeUnit = link.lumperFeeUnit;
                        locationObj.pointOfContact = link.pointOfContact;
                        locationObj.commodityIds = link.commodityIds;
                        locationObj.appointmentType = link.appointmentType;
                        locationObj.locationTypes = link.locationTypes;
    
                        // Get Account Info
                        locationObj.account = EntityUtils.getAccountInfo(link.accountId, accounts);
    
                        // Get LinkedAccount Info
                        if (isStringNotEmpty(link.linkedAccountId)) {
                            locationObj.linkedAccount = EntityUtils.getAccountInfo(link.linkedAccountId, accounts);
                        }
    
                        // Get Commodities Info
                        let commodityArray = [];
                        if (isListNotEmpty(link.commodityIds)) {
                            link.commodityIds.forEach((commodityId) => {
                                const commodity = EntityUtils.getCommodityInfo(commodityId, commodities);
                                if (isObjectNotEmpty(commodity)) {
                                    commodityArray.push(commodity);
                                }
                            });
                        }
                        locationObj.commodities = commodityArray;
                    }
    
                    locationObj.key = `${locationObj.id}-${locationObj.accountId}-${locationObj.linkedAccountId}`;
    
                    locationArray.push(locationObj);
                });
            } else {
                location.links.filter(l => l.accountId === accountId).forEach((link) => {
                    let locationObj = {
                        id: location.id,
                        irisId: location.irisId,
                        longitude: location.longitude,
                        latitude: location.latitude,
                        coordinates: location.coordinates,
                        links: location.links,
                        address: location.address,
                        timeZone: location.timeZone
                    };
                    
                    if (isObjectNotEmpty(link)) {
                        locationObj.accountId = link.accountId;
                        locationObj.linkedAccountId = link.linkedAccountId;
                        locationObj.name = link.name;
                        locationObj.specialInstructions = link.specialInstructions;
                        locationObj.lumperFee = link.lumperFee;
                        locationObj.lumperFeeUnit = link.lumperFeeUnit;
                        locationObj.pointOfContact = link.pointOfContact;
                        locationObj.commodityIds = link.commodityIds;
                        locationObj.appointmentType = link.appointmentType;
                        locationObj.locationTypes = link.locationTypes;
    
                        // Get Account Info
                        locationObj.account = EntityUtils.getAccountInfo(link.accountId, accounts);
    
                        // Get LinkedAccount Info
                        if (isStringNotEmpty(link.linkedAccountId)) {
                            locationObj.linkedAccount = EntityUtils.getAccountInfo(link.linkedAccountId, accounts);
                        }
    
                        // Get Commodities Info
                        let commodityArray = [];
                        if (isListNotEmpty(link.commodityIds)) {
                            link.commodityIds.forEach((commodityId) => {
                                const commodity = EntityUtils.getCommodityInfo(commodityId, commodities);
                                if (isObjectNotEmpty(commodity)) {
                                    commodityArray.push(commodity);
                                }
                            });
                        }
                        locationObj.commodities = commodityArray;
                    }
    
                    locationObj.key = `${locationObj.id}-${locationObj.accountId}-${locationObj.linkedAccountId}`;
    
                    locationArray.push(locationObj);
                });
            }
        } else {
            location.links.forEach((link) => {
                let locationObj = {
                    id: location.id,
                    irisId: location.irisId,
                    longitude: location.longitude,
                    latitude: location.latitude,
                    coordinates: location.coordinates,
                    links: location.links,
                    address: location.address,
                    timeZone: location.timeZone
                };

                if (isObjectNotEmpty(link)) {
                    locationObj.accountId = link.accountId;
                    locationObj.linkedAccountId = link.linkedAccountId;
                    locationObj.name = link.name;
                    locationObj.specialInstructions = link.specialInstructions;
                    locationObj.lumperFee = link.lumperFee;
                    locationObj.lumperFeeUnit = link.lumperFeeUnit;
                    locationObj.pointOfContact = link.pointOfContact;
                    locationObj.commodityIds = link.commodityIds;
                    locationObj.appointmentType = link.appointmentType;
                    locationObj.locationTypes = link.locationTypes;

                    // Get Account Info
                    locationObj.account = EntityUtils.getAccountInfo(link.accountId, accounts);

                    // Get LinkedAccount Info
                    if (isStringNotEmpty(link.linkedAccountId)) {
                        locationObj.linkedAccount = EntityUtils.getAccountInfo(link.linkedAccountId, accounts);
                    }

                    // Get Commodities Info
                    let commodityArray = [];
                    if (isListNotEmpty(link.commodityIds)) {
                        link.commodityIds.forEach((commodityId) => {
                            const commodity = EntityUtils.getCommodityInfo(commodityId, commodities);
                            if (isObjectNotEmpty(commodity)) {
                                commodityArray.push(commodity);
                            }
                        });
                    }
                    locationObj.commodities = commodityArray;
                }

                locationObj.key = `${locationObj.id}-${locationObj.accountId}-${locationObj.linkedAccountId}`;

                locationArray.push(locationObj);
            });
        }
    }

    return locationArray;
};

export const getAddLocationRequestDTO = (payload) => {
    let newLocationPayload = {};
    if (isObjectNotEmpty(payload)) {
        if (isStringNotEmpty(payload.locationId)) {
            newLocationPayload.locationId = payload.locationId;
        }

        if (isObjectNotEmpty(payload.address)) {
            newLocationPayload.address = payload.address;
        }

        if (isStringNotEmpty(payload.timeZone)) {
            newLocationPayload.timeZone = payload.timeZone;
        }

        if (isNumberNotEmpty(payload.longitude)) {
            newLocationPayload.longitude = payload.longitude;
        }

        if (isNumberNotEmpty(payload.latitude)) {
            newLocationPayload.latitude = payload.latitude;
        }

        if (isListNotEmpty(payload.links)) {
            newLocationPayload.links = payload.links;
        } else {
            let links = [];
            let newLink = {};
            if (isStringNotEmpty(payload.accountId)) {
                newLink.accountId = payload.accountId;
            }
            if (isStringNotEmpty(payload.linkedAccountId)) {
                newLink.linkedAccountId = payload.linkedAccountId;
            }
            if (isStringNotEmpty(payload.name)) {
                newLink.name = payload.name;
            }
            if (isStringNotEmpty(payload.specialInstructions)) {
                newLink.specialInstructions = payload.specialInstructions;
            }
            if (isNumberNotEmpty(payload.lumperFee)) {
                newLink.lumperFee = Number(payload.lumperFee);
                if (isStringNotEmpty(payload.lumperFeeUnit)) {
                    newLink.lumperFeeUnit = payload.lumperFeeUnit;
                } else {
                    newLink.lumperFeeUnit = 'USD';
                }
            }
            if (isObjectNotEmpty(payload.pointOfContact)) {
                newLink.pointOfContact = payload.pointOfContact;
            }
            if (isListNotEmpty(payload.commodityIds)) {
                newLink.commodityIds = payload.commodityIds;
            }
            if (isStringNotEmpty(payload.appointmentType)) {
                newLink.appointmentType = payload.appointmentType;
            }
            if (isListNotEmpty(payload.locationTypes)) {
                newLink.locationTypes = payload.locationTypes;
            }
            links.push(newLink);

            if (isListNotEmpty(links)) {
                newLocationPayload.links = links;
            }
        }
    }

    return newLocationPayload;
};

export const getUpdateLocationRequestDTO = (payload) => {
    let updateLocationPayload = {};
    if (isObjectNotEmpty(payload)) {
        if (isObjectNotEmpty(payload.address)) {
            updateLocationPayload.address = payload.address;
        }

        if (isStringNotEmpty(payload.timeZone)) {
            updateLocationPayload.timeZone = payload.timeZone;
        }

        if (isNumberNotEmpty(payload.longitude)) {
            updateLocationPayload.longitude = payload.longitude;
        }

        if (isNumberNotEmpty(payload.latitude)) {
            updateLocationPayload.latitude = payload.latitude;
        }

        if (isNotNullOrUndefined(payload.isDeleted)) {
            updateLocationPayload.isDeleted = payload.isDeleted;
        }

        if (isListNotEmpty(payload.links)) {
            updateLocationPayload.links = payload.links;
        } else {
            let links = [];
            let newLink = {};
            if (isStringNotEmpty(payload.accountId)) {
                newLink.accountId = payload.accountId;
            }
            if (isStringNotEmpty(payload.linkedAccountId)) {
                newLink.linkedAccountId = payload.linkedAccountId;
            }
            if (isStringNotEmpty(payload.name)) {
                newLink.name = payload.name;
            }
            if (isStringNotEmpty(payload.specialInstructions)) {
                newLink.specialInstructions = payload.specialInstructions;
            }
            if (isNumberNotEmpty(payload.lumperFee)) {
                newLink.lumperFee = Number(payload.lumperFee);
                if (isStringNotEmpty(payload.lumperFeeUnit)) {
                    newLink.lumperFeeUnit = payload.lumperFeeUnit;
                } else {
                    newLink.lumperFeeUnit = 'USD';
                }
            }
            if (isObjectNotEmpty(payload.pointOfContact)) {
                newLink.pointOfContact = payload.pointOfContact;
            }
            if (isListNotEmpty(payload.commodityIds)) {
                newLink.commodityIds = payload.commodityIds;
            }
            if (isStringNotEmpty(payload.appointmentType)) {
                newLink.appointmentType = payload.appointmentType;
            }
            if (isListNotEmpty(payload.locationTypes)) {
                newLink.locationTypes = payload.locationTypes;
            }
            links.push(newLink);

            if (isListNotEmpty(links)) {
                updateLocationPayload.links = links;
            }
        }
    }

    return updateLocationPayload;
};