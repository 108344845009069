import React from 'react';
import classes from './InvoiceCell.module.scss';
import StringFormatter from '../../shared/stringFormatter';

const stringFormatter = new StringFormatter();

const InvoiceCell = ({ dataType, value, units = null, timeZone = null, textAlign, isCancelled = false, colSpan = 1, columnKey }) => {

    let formattedValue = stringFormatter.toFormattedString(dataType, value, units, timeZone);

    // need to implement column
    let classNames = [];
    if (textAlign === 'left') {
        classNames.push(classes.textLeft);
    }
    if (textAlign === 'center') {
        classNames.push(classes.textCenter);
    }
    if (textAlign === 'right') {
        classNames.push(classes.textRight);
    }

    if (isCancelled === true) {
        classNames.push(classes.strikeThrough);
    }

    return (
        <td colSpan={colSpan} className={classNames.join(' ')}>{formattedValue}</td>
    );
};

export default InvoiceCell;