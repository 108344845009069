import React from 'react';
import classes from './InvoiceHeaderRow.module.scss';

const InvoiceHeaderRow = ({ columns }) => {

    const createDataItems = () => {
        let dataItems = [];

        let i = 0;
        for (const column of columns) {

            let classNames = [];
            if (column.textAlign === 'left') {
                classNames.push(classes.textLeft);
            }
            if (column.textAlign === 'center') {
                classNames.push(classes.textCenter);
            }
            if (column.textAlign === 'right') {
                classNames.push(classes.textRight);
            }

            dataItems.push(<th key={`header-column-${i}`} colSpan={column.colSpan ? column.colSpan : 1} style={column.style ? { ...column.style } : { fontWeight: 'bold' }} className={classNames.join(' ')}>{column.title}</th>);
            i++;
        }

        return dataItems;
    };

    return (
        <thead>
            <tr>
                {createDataItems()}
            </tr>
        </thead>
    );
};

export default InvoiceHeaderRow;