import React, { useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { selectListIsLoading, selectListRecords } from '../../store/utility';
import InvoiceTemplate from '../../components/Invoice/Invoice';

const Invoice = ({ displayManualInstructions = false, ...props }) => {
    //#region props and constants

    const invoiceId = props.match.params.invoiceId;

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoadingInvoice = useSelector(state => state.invoices.isRecordLoading);
    const invoice = useSelector(state => state.invoices.record);
    const isLoadingLoad = useSelector(state => state.loads.isRecordLoading);
    const load = useSelector(state => state.loads.record);
    const stops = useSelector(state => selectListRecords(state.loadStops.lists, isObjectNotEmpty(load) && isStringNotEmpty(load.id) ? load.id : null));
    const isLoadingStops = useSelector(state => selectListIsLoading(state.loadStops.lists, isObjectNotEmpty(load) && isStringNotEmpty(load.id) ? load.id : null));

    //#endregion

    //#region useMemos

    useMemo(() => {
        if (isStringNotEmpty(invoiceId)) {
            dispatch(actionCreators.fetchInvoice(invoiceId));
        }
    }, [invoiceId]);

    useMemo(() => {
        if (isObjectNotEmpty(invoice) && isStringNotEmpty(invoice.loadId)) {
            dispatch(actionCreators.fetchLoadWithIncludes(invoice.loadId));
        }
    }, [invoice]);

    useMemo(() => {
        if (isObjectNotEmpty(load) && isStringNotEmpty(load.id)) {
            dispatch(actionCreators.fetchLoadStopList(load.id, load));
        }
    }, [load]);

    //#endregion

    if (isObjectNotEmpty(invoice) && isObjectNotEmpty(load) && isListNotEmpty(stops) && isStringNotEmpty(invoice.id) && isStringNotEmpty(invoiceId) && invoice.id === invoiceId) {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoadingInvoice === true || isLoadingLoad === true || isLoadingStops === true}>
                <InvoiceTemplate invoiceId={invoiceId} invoice={invoice} load={load} loadId={isObjectNotEmpty(load) && isStringNotEmpty(load.id) ? load.id : null} stops={stops} displayManualInstructions={displayManualInstructions} />
            </Spin>
        );
    } else {
        return <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoadingInvoice === true || isLoadingLoad === true || isLoadingStops === true} />;
    }
};

export default withRouter(Invoice);