import axiosAuthenticated from "../../api/axiosAuthenticated";
import { isNotNullOrUndefined } from "../../shared/objectUtils";

const trailerTypesPath = '/trailerTypes';

//#region Trailer Types Methods

export const getAllTrailerTypes = async () => {
    const trailerTypesRes = await axiosAuthenticated.get(trailerTypesPath, { params: { page: 1, size: 1000000, isDeleted: false } });
    if (trailerTypesRes && trailerTypesRes.status === 200) {
        return trailerTypesRes.data.data;
    }

    return [];
};

export const getTrailerTypes = async (searchParams = {}, pagination = {}) => {
    const trailerTypesRes = await axiosAuthenticated.get(trailerTypesPath, { params: { ...searchParams } });
    if (trailerTypesRes && trailerTypesRes.status === 200) {
        const trailerTypes = trailerTypesRes.data.data;
        const otherData = trailerTypesRes.data;

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;

        return { data: trailerTypes, searchParams: searchParams, pagination: pagination };
    }

    return { data: [], searchParams: searchParams, pagination: pagination };
};

export const addTrailerType = async (payload) => {
    const trailerTypesRes = await axiosAuthenticated.post(trailerTypesPath, { ...payload });
    if (trailerTypesRes && trailerTypesRes.status === 201) {
        return trailerTypesRes.data;
    }

    return null;
};

export const updateTrailerType = async (id, payload) => {
    const trailerTypesRes = await axiosAuthenticated.put(trailerTypesPath + `/${id}`, { ...payload });
    if (trailerTypesRes && trailerTypesRes.status === 200) {
        return trailerTypesRes.data;
    }

    return null;
};

export const removeTrailerType = async (id) => {
    const trailerTypesRes = await axiosAuthenticated.put(trailerTypesPath + `/${id}`, { isDeleted: true });
    if (trailerTypesRes && trailerTypesRes.status === 200) {
        return trailerTypesRes.data;
    }

    return null;
};

//#endregion