import axiosAuthenticated from "../../api/axiosAuthenticated";
import * as DTO from "../dtos/index";
import * as Data from "./index";
import { isListNotEmpty, isNotNullOrUndefined, isStringNotEmpty } from "../../shared/objectUtils";

const invoicesPath = '/invoices';

//#region Invoice Methods

export const getInvoicesByLoadId = async (loadId) => {
    if (isStringNotEmpty(loadId)) {
        const invoicesRes = await axiosAuthenticated.get(invoicesPath, { params: { page: 1, size: 1000000, loadId: loadId, isDeleted: false } });
        if (invoicesRes && invoicesRes.status === 200) {
            return invoicesRes.data.data;
        }
    }

    return [];
};

export const getInvoicesByLoadIdAndAccountId = async (loadId, accountId) => {
    if (isStringNotEmpty(loadId) && isStringNotEmpty(accountId)) {
        const invoicesRes = await axiosAuthenticated.get(invoicesPath, { params: { page: 1, size: 1000000, loadId: loadId, 'fromEntityId:or': accountId, 'toEntityId:or': accountId, isDeleted: false } });
        if (invoicesRes && invoicesRes.status === 200) {
            return invoicesRes.data.data;
        }
    }

    return [];
};

export const getInvoicesByAccountId = async (accountId) => {
    if (isStringNotEmpty(accountId)) {
        const invoicesRes = await axiosAuthenticated.get(invoicesPath, { params: { page: 1, size: 1000000, 'fromEntityId:or': accountId, 'toEntityId:or': accountId, isDeleted: false } });
        if (invoicesRes && invoicesRes.status === 200) {
            return invoicesRes.data.data;
        }
    }

    return [];
};

export const getSentInvoicesByAccountId = async (accountId) => {
    if (isStringNotEmpty(accountId)) {
        const invoicesRes = await axiosAuthenticated.get(invoicesPath, { params: { page: 1, size: 1000000, fromEntityId: accountId, isDeleted: false } });
        if (invoicesRes && invoicesRes.status === 200) {
            return invoicesRes.data.data;
        }
    }

    return [];
};

export const getReceivedInvoicesByAccountId = async (accountId) => {
    if (isStringNotEmpty(accountId)) {
        const invoicesRes = await axiosAuthenticated.get(invoicesPath, { params: { page: 1, size: 1000000, toEntityId: accountId, isDeleted: false } });
        if (invoicesRes && invoicesRes.status === 200) {
            return invoicesRes.data.data;
        }
    }

    return [];
};

export const getInvoices = async (searchParams = {}, pagination = {}) => {
    const invoicesRes = await axiosAuthenticated.get(invoicesPath, { params: { ...searchParams } });
    if (invoicesRes && invoicesRes.status === 200) {
        const invoices = invoicesRes.data.data;
        const otherData = invoicesRes.data;

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;

        return { data: invoices, searchParams: searchParams, pagination: pagination };
    }

    return { data: [], searchParams: searchParams, pagination: pagination };
};

export const getInvoice = async (id) => {
    const invoicesRes = await axiosAuthenticated.get(invoicesPath + `/${id}`);
    if (invoicesRes && invoicesRes.status === 200) {
        return invoicesRes.data;
    }

    return null;
};

export const addInvoice = async (payload, sendLoadEvent = false) => {
    const invoicePayload = DTO.getAddInvoiceRequestDTO(payload);

    const invoicesRes = await axiosAuthenticated.post(invoicesPath, { ...invoicePayload });
    if (invoicesRes && invoicesRes.status === 201) {
        let newInvoice = invoicesRes.data;

        // if the status of the invoice is COMPLETED and the invoice is a quote, mark all the invoice line item ids as approved
        if ((newInvoice.invoiceType === 'PRICE_CONFIRMATION' || newInvoice.invoiceType === 'RATE_CONFIRMATION' || newInvoice.invoiceType === 'QUOTE') && newInvoice.status === 'COMPLETED') {
            if (isListNotEmpty(newInvoice.invoiceLineItemIds)) {
                await Data.updateInvoiceLineItems(newInvoice.invoiceLineItemIds, { approvalStatus: "APPROVED" });
            }
        }

        // update all the invoice line items with the invoice id
        if ((newInvoice.invoiceType === 'CARRIER_INVOICE' || newInvoice.invoiceType === 'SHIPPER_INVOICE' || newInvoice.invoiceType === 'FACTORING_INVOICE' || newInvoice.invoiceType === 'INVOICE')) {
            if (isListNotEmpty(newInvoice.invoiceLineItemIds)) {
                if (newInvoice.status === 'CANCELLED' || newInvoice.isDeleted === true) {
                    await Data.updateInvoiceLineItems(newInvoice.invoiceLineItemIds, { invoiceId: null });
                } else {
                    await Data.updateInvoiceLineItems(newInvoice.invoiceLineItemIds, { invoiceId: newInvoice.id });
                }
            }
        }

        if (isStringNotEmpty(newInvoice.loadId) && sendLoadEvent === true) {
            await Data.addLoadEvent({ loadId: newInvoice.loadId, eventType: 'LOAD_UPDATED', changeType: 'INVOICES_UPDATED' });
        }

        return DTO.getInvoiceDTO(newInvoice);
    }

    return null;
};

export const updateInvoice = async (id, payload, oldInvoice, sendLoadEvent = false) => {
    const invoicePayload = DTO.getUpdateInvoiceRequestDTO(payload);

    const invoicesRes = await axiosAuthenticated.put(invoicesPath + `/${id}`, { ...invoicePayload });
    if (invoicesRes && invoicesRes.status === 200) {
        let updatedInvoice = invoicesRes.data;

        // if the status of the invoice gets updated to COMPLETED and the invoice is a quote, mark all the invoice line item ids as approved
        if ((updatedInvoice.invoiceType === 'PRICE_CONFIRMATION' || updatedInvoice.invoiceType === 'RATE_CONFIRMATION' || updatedInvoice.invoiceType === 'QUOTE') && isStringNotEmpty(invoicePayload.status) && invoicePayload.status === 'COMPLETED') {
            if (isListNotEmpty(updatedInvoice.invoiceLineItemIds)) {
                await Data.updateInvoiceLineItems(updatedInvoice.invoiceLineItemIds, { approvalStatus: "APPROVED" });
            }
        }

        // update all the invoice line items with the invoice id
        if ((updatedInvoice.invoiceType === 'CARRIER_INVOICE' || updatedInvoice.invoiceType === 'SHIPPER_INVOICE' || updatedInvoice.invoiceType === 'FACTORING_INVOICE' || updatedInvoice.invoiceType === 'INVOICE')) {
            let invoiceLineItemIdsToAdd = [];
            let invoiceLineItemIdsToRemove = [];
            if (updatedInvoice.status === 'CANCELLED' || updatedInvoice.isDeleted === true) {
                if (isListNotEmpty(payload.invoiceLineItemIds)) {
                    payload.invoiceLineItemIds.forEach((invoiceLineItemId) => {
                        if (!invoiceLineItemIdsToRemove.includes(invoiceLineItemId)) {
                            invoiceLineItemIdsToRemove.push(invoiceLineItemId);
                        }
                    });
                }
                if (isListNotEmpty(oldInvoice.invoiceLineItemIds)) {
                    oldInvoice.invoiceLineItemIds.forEach((invoiceLineItemId) => {
                        if (!invoiceLineItemIdsToRemove.includes(invoiceLineItemId)) {
                            invoiceLineItemIdsToRemove.push(invoiceLineItemId);
                        }
                    });
                }
            } else {
                if (isNotNullOrUndefined(payload.invoiceLineItemIds)) {
                    if (isListNotEmpty(oldInvoice.invoiceLineItemIds)) {
                        payload.invoiceLineItemIds.forEach((invoiceLineItemId) => {
                            if (!oldInvoice.invoiceLineItemIds.includes(invoiceLineItemId)) {
                                invoiceLineItemIdsToAdd.push(invoiceLineItemId);
                            }
                        });
                        oldInvoice.invoiceLineItemIds.forEach((invoiceLineItemId) => {
                            if (!payload.invoiceLineItemIds.includes(invoiceLineItemId)) {
                                invoiceLineItemIdsToRemove.push(invoiceLineItemId);
                            }
                        });
                    } else {
                        invoiceLineItemIdsToAdd = payload.invoiceLineItemIds;
                    }
                }
            }

            if (isListNotEmpty(invoiceLineItemIdsToRemove)) {
                await Data.updateInvoiceLineItems(invoiceLineItemIdsToRemove, { invoiceId: null });
            }
            if (isListNotEmpty(invoiceLineItemIdsToAdd)) {
                await Data.updateInvoiceLineItems(invoiceLineItemIdsToRemove, { invoiceId: updatedInvoice.id });
            }
        }

        if (isStringNotEmpty(updatedInvoice.loadId) && sendLoadEvent === true) {
            await Data.addLoadEvent({ loadId: updatedInvoice.loadId, eventType: 'LOAD_UPDATED', changeType: 'INVOICES_UPDATED' });
        }

        return DTO.getInvoiceDTO(updatedInvoice);
    }

    return null;
};

export const removeInvoice = async (id, oldInvoice, sendLoadEvent = false) => {
    const invoicesRes = await axiosAuthenticated.put(invoicesPath + `/${id}`, { isDeleted: true });
    if (invoicesRes && invoicesRes.status === 200) {
        let updatedInvoice = invoicesRes.data;

        // update all the invoice line items with the invoice id
        if ((oldInvoice.invoiceType === 'CARRIER_INVOICE' || oldInvoice.invoiceType === 'SHIPPER_INVOICE' || oldInvoice.invoiceType === 'FACTORING_INVOICE' || oldInvoice.invoiceType === 'INVOICE')) {
            if (isListNotEmpty(oldInvoice.invoiceLineItemIds)) {
                await Data.updateInvoiceLineItems(oldInvoice.invoiceLineItemIds, { invoiceId: null });
            }
        }

        if (isStringNotEmpty(updatedInvoice.loadId) && sendLoadEvent === true) {
            await Data.addLoadEvent({ loadId: updatedInvoice.loadId, eventType: 'LOAD_UPDATED', changeType: 'INVOICES_UPDATED' });
        }

        return updatedInvoice;
    }

    return null;
};

//#endregion