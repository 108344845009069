import { isObjectNotEmpty } from '../../objectUtils';
import Checks from '../entitlementUtils';

export const canCreateAsset = (auth, accountId) => {
    return Checks.isAuthenticated(auth) || Checks.isAccountUserWithRoles(auth, accountId, ["ADMIN", "OPERATIONS_ADMIN", "LOAD_PLANNER", "DISPATCHER"]);
};

export const canReadAsset = (auth, accountId) => {
    return Checks.allowStaff(auth) || Checks.isAccountUserWithRoles(auth, accountId, ["ADMIN", "OPERATIONS_ADMIN", "LOAD_PLANNER", "DISPATCHER"]);
};

export const canReadAssetList = (auth, accountId) => {
    return Checks.allowStaff(auth) || Checks.isAccountUserWithRoles(auth, accountId, ["ADMIN", "OPERATIONS_ADMIN", "LOAD_PLANNER", "DISPATCHER"]);
};

export const canUpdateAsset = (auth, accountId) => {
    return Checks.allowStaff(auth) || Checks.isAccountUserWithRoles(auth, accountId, ["ADMIN", "OPERATIONS_ADMIN", "LOAD_PLANNER", "DISPATCHER"]);
};

export const canDeleteAsset = (auth, accountId) => {
    return Checks.allowStaffAdmin(auth) || Checks.isAccountUserWithRoles(auth, accountId, ["ADMIN", "OPERATIONS_ADMIN"]);
};