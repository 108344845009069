import React, { useState, useEffect } from 'react';
import { Spin, Row, Col } from "antd";
import Img from 'react-image';
import axios from "axios";
import PDFViewer from '../../components/PDFViewer/PDFViewer';
import PDFJSBackend from '../../backends/pdfjs';
import { getDocumentByDocumentIdWithLink } from '../../api/data';
import { isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import logger from '../../shared/logger';

const Document = ({ fileId, displayName }) => {
    const [fileUrl, setFileUrl] = useState(null);
    const [fileExt, setFileExt] = useState(null);

    useEffect(() => {
        const { cancel, token } = axios.CancelToken.source();

        setFileUrl(null);
        if (isStringNotEmpty(fileId)) {
            getDocumentByDocumentIdWithLink(fileId, { cancelToken: token }).then((doc) => {
                if (isObjectNotEmpty(doc)) {
                    setFileExt(doc.fileExt);
                    setFileUrl(doc.link);
                    //console.log(doc.link);
                }
            }).catch(err => {
                if (axios.isCancel(err)) {
                    // console.log('Request cancelled', err.message);
                } else {
                    logger.logDebugEvent('Document.js', err.message, true);
                }
            });
        }

        return () => {
            // console.log("Cleaning up axios calls");
            if (cancel !== undefined) {
                cancel('Operation cancelled by the user.');
            }
        };
    }, [fileId]);

    if (isStringNotEmpty(fileExt)) {
        if (fileExt.toLowerCase() === 'jpeg' || fileExt.toLowerCase() === 'jpg' || fileExt.toLowerCase() === 'png') {
            return (
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24} lg={24} xl={24}>
                        <Img style={{ width: '100%' }}
                            src={fileUrl}
                            loader={<Spin size="large" />}
                            alt={displayName ? displayName : 'File'}
                        />
                    </Col>
                </Row>
            );
        } else if (fileExt.toLowerCase() === 'pdf') {
            return (
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24} lg={24} xl={24}>
                        {isStringNotEmpty(fileUrl) && <PDFViewer backend={PDFJSBackend} src={fileUrl} />}
                    </Col>
                </Row>
            );
        } else {
            return null;
        }
    } else {
        return null;
    }
};

export default Document;