import Checks from '../entitlementUtils';

export const canCreateInvoice = (auth) => {
    return Checks.allowStaff(auth);
};

export const canReadInvoice = (auth) => {
    return Checks.allowStaff(auth);
};

export const canReadInvoiceList = (auth) => {
    return Checks.allowStaff(auth);
};

export const canUpdateInvoice = (auth) => {
    return Checks.allowStaff(auth);
};

export const canDeleteInvoice = (auth) => {
    return Checks.allowStaff(auth);
};