import Checks from '../entitlementUtils';

export const canCreateInvoiceLineItem = (auth) => {
    return Checks.allowStaff(auth);
};

export const canReadInvoiceLineItem = (auth) => {
    return Checks.allowStaff(auth);
};

export const canReadInvoiceLineItemList = (auth) => {
    return Checks.allowStaff(auth);
};

export const canUpdateInvoiceLineItem = (auth) => {
    return Checks.allowStaff(auth);
};

export const canDeleteInvoiceLineItem = (auth) => {
    return Checks.allowStaffAdmin(auth);
};