import React, { useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { Button, Modal, Row, Col, Spin, Select, Empty, Card, message, Drawer } from 'antd';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import { without } from 'lodash';
import classes from './DispatchChangeDriver.module.scss';
import StringFormatter from '../../shared/stringFormatter';
import Enums from '../../shared/enums';
import MomentDate from '../../shared/dateFormatter';
import pickUpMarker from '../../assets/img/pickUpMarker.png';
import dropOffMarker from '../../assets/img/dropOffMarker.png';
import TitleBlockWithIcon from '../TitleBlockWithIcon/TitleBlockWithIcon';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt, faIdCard, faPallet, faRoute, faTimes } from '@fortawesome/free-solid-svg-icons';
import DataRow from '../DataRow/DataRow';
import DataListRow from '../DataListRow/DataListRow';
import DataTemperatureRangeRow from '../DataTemperatureRangeRow/DataTemperatureRangeRow';
import { driverAssignedEmailTemplate, driverUnAssignedEmailTemplate } from "../../shared/emailTemplates";
import { getScheduledShifts, sendLoadNotification } from '../../api/data';
import { sendEmail } from '../../api/emailClient';
import { isBooleanTrue, isListNotEmpty, isNotNullOrUndefined, isNumberNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import logger from '../../shared/logger';
import LoadStopUtils from '../../api/utils/loadStopUtils';
import LoadUtils from '../../api/utils/loadUtils';
import { CheckCircleTwoTone, CloseCircleTwoTone, InfoCircleTwoTone } from '@ant-design/icons';
import NewDriver from '../NewDriver/NewDriver';

const { Option } = Select;
const stringFormatter = new StringFormatter();
const momentDate = new MomentDate();

const DispatchChangeDriver = ({ load, stops, shipperId, carrierId, drivers, bundledLoads, driverLoads, transportUnits, shifts, driver, cancel, isBundledLoad = false }) => {
    //#region props and constants

    const fullWidth = global.window.innerWidth;

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);
    const isLoading = useSelector(state => state.loads.isRecordUpdateLoading);
    const error = useSelector(state => state.loads.updateRecordError);

    //#endregion
    //#region useRefs

    const schedulerRef = useRef(null);

    //#endregion
    //#region useStates

    const [selectedEvent, setSelectedEvent] = useState({});
    const [showDetails, setShowDetails] = useState(false);
    const [resourceMap, setResourceMap] = useState([]);
    const [selectedShiftIds, setSelectedShiftIds] = useState([]);
    const [selectedAccountUserIds, setSelectedAccountUserIds] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [loads, setLoads] = useState([]);
    const [isLoadingLoads, setIsLoadingLoads] = useState(false);
    const [scheduledShifts, setScheduledShifts] = useState([]);
    const [isLoadingScheduledShifts, setIsLoadingScheduledShifts] = useState(false);
    const [events, setEvents] = useState([]);
    const [driverMap, setDriverMap] = useState([]);
    const [duration, setDuration] = useState([]);
    const [oldDriverAccountUserId, setOldDriverAccountUserId] = useState(null);
    const [newDriverAccountUserId, setNewDriverAccountUserId] = useState(null);
    const [showNewDriver, setShowNewDriver] = useState(false);

    //#endregion
    //#region toggles

    const toggleShowDetails = () => {
        setShowDetails(!showDetails);
    };

    const toggleNewDriver = () => {
        setShowNewDriver(!showNewDriver);
    };

    //#endregion
    //#region calendar methods

    const handleEventClick = (clickInfo) => {
        clickInfo.jsEvent.preventDefault(); // don't let the browser navigate

        setSelectedEvent(clickInfo.event.extendedProps);

        setShowDetails(true);
    };

    const prev = () => {
        if (schedulerRef.current !== null && isObjectNotEmpty(load) && isNotNullOrUndefined(duration)) {
            let calendarApi = schedulerRef.current.getApi();

            calendarApi.prev();
            // console.log(calendarApi);
            let getDate = calendarApi.getDate();
            // console.log(moment(getDate));

            let startDate = moment(getDate);
            let time = moment().set({ hours: 0, minutes: 0, seconds: 0 });
            let startDateValue = momentDate.combineDateAndTimeAsTimeZoneUtcISOString(startDate, time, "UTC");
            let endDateValue = momentDate.combineDateAndTimeAsTimeZoneUtcISOString(startDate.add(duration, 'days'), time, "UTC");
            // console.log(startDateValue);
            // console.log(endDateValue);

            setStartDate(startDateValue);
            setEndDate(endDateValue);
        }
    };

    const next = () => {
        if (schedulerRef.current !== null && isObjectNotEmpty(load) && isNotNullOrUndefined(duration)) {
            let calendarApi = schedulerRef.current.getApi();

            calendarApi.next();
            // console.log(calendarApi);
            let getDate = calendarApi.getDate();
            // console.log(moment(getDate));

            let startDate = moment(getDate);
            let time = moment().set({ hours: 0, minutes: 0, seconds: 0 });
            let startDateValue = momentDate.combineDateAndTimeAsTimeZoneUtcISOString(startDate, time, "UTC");
            let endDateValue = momentDate.combineDateAndTimeAsTimeZoneUtcISOString(startDate.add(duration, 'days'), time, "UTC");
            // console.log(startDateValue);
            // console.log(endDateValue);

            setStartDate(startDateValue);
            setEndDate(endDateValue);
        }
    };

    //#endregion
    //#region driver methods

    const sendEmailToDriver = (accountUserId) => {
        if (isListNotEmpty(drivers) && isStringNotEmpty(accountUserId)) {
            let accountUser = drivers.find(d => d.id === accountUserId);
            if (isObjectNotEmpty(accountUser) && isObjectNotEmpty(accountUser.driver)) {
                if (accountUser.driver.isApproved === undefined || accountUser.driver.isApproved === null || accountUser.driver.isApproved === false) {
                    const { body, subject } = driverAssignedEmailTemplate(accountUser.firstName, load.irisId)
                    sendEmail(subject, body, accountUser.email).then((res) => {
                        // console.log(res);
                    }).catch(err => {
                        logger.logDebugEvent('DispatchChangeDriver.js', err.message, true);
                    });
                }
            }
        }
    };

    const sendEmailToOldDriver = (accountUserId) => {
        if (isListNotEmpty(drivers) && isStringNotEmpty(accountUserId)) {
            let accountUser = drivers.find(d => d.id === accountUserId);
            if (isObjectNotEmpty(accountUser) && isObjectNotEmpty(accountUser.driver)) {
                if (accountUser.driver.isApproved === undefined || accountUser.driver.isApproved === null || accountUser.driver.isApproved === false) {
                    const { body, subject } = driverUnAssignedEmailTemplate(accountUser.firstName, load.irisId)
                    sendEmail(subject, body, accountUser.email).then((res) => {
                        // console.log(res);
                    }).catch(err => {
                        logger.logDebugEvent('DispatchChangeDriver.js', err.message, true);
                    });
                }
            }
        }
    };

    const sendNotificationToDriver = (accountUserId) => {
        if (isListNotEmpty(drivers) && isStringNotEmpty(accountUserId)) {
            let accountUser = drivers.find(d => d.id === accountUserId);
            if (isObjectNotEmpty(accountUser) && isObjectNotEmpty(accountUser.driver)) {
                let newNotification = {
                    message: `Hi ${accountUser.firstName}, you have been assigned to a new load: ${load.irisId}. Please open up the IRIS Freight app for more details.`,
                    loadId: load.id,
                    loadIrisId: load.irisId,
                    saveOnly: false,
                    subject: `${load.irisId} You have been assigned to a new load`,
                    severity: "CRITICAL",
                    userIds: [accountUser.userId],
                    loadStatus: load.loadStatus,
                    eventType: 'DRIVER_ASSIGNED'
                };

                sendLoadNotification(newNotification).then((res) => {
                    // console.log(res);
                }).catch(err => {
                    logger.logDebugEvent('DispatchChangeDriver.js', err.message, true);
                });
            }
        }
    };

    const sendNotificationToOldDriver = (accountUserId) => {
        if (isListNotEmpty(drivers) && isStringNotEmpty(accountUserId)) {
            let accountUser = drivers.find(d => d.id === accountUserId);
            if (isObjectNotEmpty(accountUser) && isObjectNotEmpty(accountUser.driver)) {
                let newNotification = {
                    message: `Hi ${accountUser.firstName}, you are no longer assigned to load: ${load.irisId}. Please open up the IRIS Freight app for more details.`,
                    loadId: load.id,
                    loadIrisId: load.irisId,
                    saveOnly: false,
                    subject: `${load.irisId} You have been unassigned from a load`,
                    severity: "CRITICAL",
                    userIds: [accountUser.userId],
                    loadStatus: load.loadStatus,
                    eventType: 'DRIVER_UNASSIGNED'
                };

                sendLoadNotification(newNotification).then((res) => {
                    // console.log(res);
                }).catch(err => {
                    logger.logDebugEvent('DispatchChangeDriver.js', err.message, true);
                });
            }
        }
    };

    const onChangeDriver = (accountUserId) => {
        if (isStringNotEmpty(accountUserId) && isObjectNotEmpty(load) && isObjectNotEmpty(driver)) {
            let driverIds = isListNotEmpty(load.driverIds) ? load.driverIds : [];

            // check to see if it is a duplicate and if it is found in the driverIds array already, throw an error
            if (isListNotEmpty(driverIds)) {
                if (driverIds.find(i => i === accountUserId) !== undefined) {
                    message.error('The driver you chose is already assigned to this load.', 5);
                    return;
                } else {
                    let updatedDriverIds = without(driverIds, driver.id);
                    updatedDriverIds.push(accountUserId);
                    driverIds = updatedDriverIds;
                }
            } else {
                driverIds.push(accountUserId);
            }

            let payload = {
                driverIds: driverIds
            };
            // console.log(payload);
            setOldDriverAccountUserId(driver.id);
            setNewDriverAccountUserId(accountUserId);

            if (isBooleanTrue(isBundledLoad)) {
                dispatch(actionCreators.updateBundledLoad(load.id, payload, load));
            } else {
                dispatch(actionCreators.updateLoad(load.id, payload));
            }

            // toggleShowDetails();
        } else {
            onDone();
        }
    };

    const onRemoveDriver = () => {
        if (isObjectNotEmpty(load) && isObjectNotEmpty(driver)) {
            let driverIds = isListNotEmpty(load.driverIds) ? load.driverIds : [];

            let updatedDriverIds = without(driverIds, driver.id);
            // console.log(updatedDriverIds);

            let payload = {
                driverIds: updatedDriverIds
            };
            // console.log(payload);
            setOldDriverAccountUserId(driver.id);
            setNewDriverAccountUserId('none');

            if (isBooleanTrue(isBundledLoad)) {
                dispatch(actionCreators.removeDriverFromBundledLoad(load.id, payload, load, driver));
            } else {
                dispatch(actionCreators.removeDriverFromLoad(load.id, payload, driver));
            }

            // toggleShowDetails();
        } else {
            onDone();
        }
    };

    const onDone = () => {
        dispatch(actionCreators.updateLoadCancel());
        cancel();
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        if (isListNotEmpty(drivers) && isObjectNotEmpty(driver)) {
            if (isListNotEmpty(selectedAccountUserIds)) {
                let resourceMapArray = drivers.filter(i => selectedAccountUserIds.includes(i.id)).map((accountUser) => {
                    return ({
                        id: accountUser.id,
                        extendedProps: accountUser,
                        title: `${accountUser.firstName} ${accountUser.lastName}${isStringNotEmpty(accountUser.title) ? ' (' + accountUser.title + ')' : ''}, Available: ${isObjectNotEmpty(accountUser.driver) ? stringFormatter.toYesNo(accountUser.driver.isAvailable) : 'No'}`
                    });
                });

                setResourceMap(resourceMapArray);
            } else {
                let resourceMapArray = drivers.map((accountUser) => {
                    return ({
                        id: accountUser.id,
                        extendedProps: accountUser,
                        title: `${accountUser.firstName} ${accountUser.lastName}${isStringNotEmpty(accountUser.title) ? ' (' + accountUser.title + ')' : ''}, Available: ${isObjectNotEmpty(accountUser.driver) ? stringFormatter.toYesNo(accountUser.driver.isAvailable) : 'No'}`
                    });
                });

                setResourceMap(resourceMapArray);
            }
        } else {
            setResourceMap([]);
        }
    }, [drivers, selectedAccountUserIds]);

    // useMemo(() => {
    //     if (startDate !== undefined && startDate !== null && endDate !== undefined && endDate !== null && resourceMap !== undefined && resourceMap !== null && resourceMap.length > 0) {
    //         let driverIds = resourceMap.map((item) => item.id);
    //         let searchParams = {
    //             page: 1,
    //             size: 100000,
    //             isDeleted: false,
    //             eta: true,
    //             driverIds: driverIds,
    //             loadStatus: ['CREATED', 'PENDING', 'APPROVED', 'PENDING_RATE_CONFIRMATION', 'BOOKED', 'SCHEDULED', 'IN_TRANSIT', 'AT_STOP']
    //         };

    //         searchParams["pickUpDateTime:gte"] = startDate;
    //         searchParams["pickUpDateTime:lte"] = endDate;

    //         setIsLoadingLoads(true);
    //         axiosAuthenticated.post('/orch/loads', { ...searchParams }).then((loadsRes) => {
    //             // console.log(loadsRes.data.data);
    //             setLoads(loadsRes.data.data);
    //             setIsLoadingLoads(false);
    //         }).catch((error) => {
    //             // console.log(error.message);
    //             setLoads([]);
    //             setIsLoadingLoads(false);
    //         });
    //     } else {
    //         setLoads([]);
    //     }
    // }, [startDate, endDate, resourceMap]);

    useMemo(() => {
        let loadsArray = [];
        if (isListNotEmpty(driverLoads)) {
            driverLoads.forEach((loadObj) => {
                if (loadsArray.find(i => i.id === loadObj.id) === undefined) {
                    loadsArray.push(loadObj);
                }
            });
        }

        if (isListNotEmpty(bundledLoads)) {
            bundledLoads.forEach((loadObj, loadIndex) => {
                loadObj.loadName = LoadUtils.getChildLoadName(loadObj, loadIndex);
                loadObj.loadNumber = loadIndex + 1;
                if (loadsArray.find(i => i.id === loadObj.id) === undefined) {
                    loadsArray.push(loadObj);
                }
            });
        }

        setLoads(loadsArray);
        // console.log(loadsArray);
    }, [driverLoads, bundledLoads]);

    useMemo(() => {
        if (isNotNullOrUndefined(startDate) && isNotNullOrUndefined(endDate) && isStringNotEmpty(carrierId)) {
            let searchParams = {
                page: 1,
                size: 1000000,
                sort: 'startDateTime',
                order: 'asc',
                isDeleted: false
            };

            searchParams["startDateTime:between:or"] = [startDate, endDate];
            searchParams["endDateTime:between:or"] = [startDate, endDate];

            if (isListNotEmpty(selectedAccountUserIds)) {
                searchParams.accountUserIds = selectedAccountUserIds;
            }

            if (isListNotEmpty(selectedShiftIds)) {
                searchParams.shiftId = selectedShiftIds;
            }

            if (isStringNotEmpty(carrierId)) {
                searchParams.accountId = carrierId;
            }

            setIsLoadingScheduledShifts(true);
            getScheduledShifts({ ...searchParams }).then((res) => {
                setScheduledShifts(res.data);
                setIsLoadingScheduledShifts(false);
            }).catch((error) => {
                logger.logErrorEvent('DispatchChangeDriver.js', error, error.message, true);
                setScheduledShifts([]);
                setIsLoadingScheduledShifts(false);
            });
        } else {
            setScheduledShifts([]);
        }
    }, [carrierId, selectedAccountUserIds, selectedShiftIds, startDate, endDate]);

    useMemo(() => {
        let allEvents = [];
        if (isListNotEmpty(scheduledShifts)) {
            scheduledShifts.forEach((scheduledShift) => {
                allEvents.push({
                    id: scheduledShift.id,
                    title: scheduledShift.shift.name,
                    start: moment(scheduledShift.startDateTime).toDate(),
                    end: moment(scheduledShift.endDateTime).toDate(),
                    backgroundColor: scheduledShift.shift.backgroundColor,
                    textColor: scheduledShift.shift.textColor,
                    timeZone: scheduledShift.shift.timeZone,
                    resourceIds: isListNotEmpty(scheduledShift.accountUserIds) ? [...scheduledShift.accountUserIds] : [],
                    extendedProps: scheduledShift,
                    allDay: false,
                    eventType: 'ScheduledShift'
                });
            });
        }

        if (isListNotEmpty(loads)) {
            loads.forEach((load) => {
                allEvents.push({
                    id: load.id,
                    title: LoadUtils.getLoadName(load),
                    start: moment(load.pickUpDateTime).toDate(),
                    end: moment(load.eta).toDate(),
                    backgroundColor: '#ffffff',
                    textColor: '#000000',
                    timeZone: load.etaTimeZone,
                    resourceIds: isListNotEmpty(load.driverIds) ? [...load.driverIds] : [],
                    extendedProps: load,
                    allDay: false,
                    eventType: 'Load'
                });
            });
        }

        // console.log(allEvents);
        setEvents(allEvents);
    }, [scheduledShifts, loads]);

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLoadErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null && isStringNotEmpty(oldDriverAccountUserId) && isStringNotEmpty(newDriverAccountUserId) && isObjectNotEmpty(load)) {
            if (newDriverAccountUserId !== 'none') {
                sendNotificationToDriver(newDriverAccountUserId);
                sendEmailToDriver(newDriverAccountUserId);
            }
            sendNotificationToOldDriver(oldDriverAccountUserId);
            sendEmailToOldDriver(oldDriverAccountUserId);

            if (LoadUtils.loadStatusIs(load.loadStatus, ['IN_TRANSIT', 'AT_STOP'])) {
                dispatch(actionCreators.markDriverAvailable(oldDriverAccountUserId));
                if (newDriverAccountUserId !== 'none') {
                    dispatch(actionCreators.markDriverUnavailable(newDriverAccountUserId));
                }
            }

            onDone();
        }
    }, [isLoading, error, oldDriverAccountUserId, newDriverAccountUserId, load]);

    useMemo(() => {
        if (isObjectNotEmpty(load)) {
            let pickUpDateTimeObj = LoadStopUtils.getStopStartDateTime(load.origin);
            let dropOffDateTimeObj = LoadStopUtils.getStopEndDateTime(load.destination);
            let startDate = pickUpDateTimeObj.startDateTime.date;
            let endDate = dropOffDateTimeObj.endDateTime.date;
            let startDateValue = momentDate.toUtcISOString(startDate);
            let endDateValue = momentDate.toUtcISOString(endDate);
            // console.log(startDateValue);
            // console.log(endDateValue);

            let approxLoadDuration = endDate.diff(startDate, 'days', true); // in days and partial days as float
            let approxLoadDurationInDays = Math.ceil(approxLoadDuration); // in days rounded up as integer
            setDuration(approxLoadDurationInDays);

            setStartDate(startDateValue);
            setEndDate(endDateValue);
        }
    }, [load]);

    useMemo(() => {
        if (isListNotEmpty(drivers) && isObjectNotEmpty(driver)) {
            let driversArray = [];

            drivers.filter(i => i.id === driver.id).forEach((accountUser) => {
                let driverBundledLoads = [];
                let driverOtherLoads = [];
                if (isListNotEmpty(bundledLoads)) {
                    bundledLoads.forEach((driverBundledLoad, loadIndex) => {
                        if (driverBundledLoad.isDeleted === false && LoadUtils.loadStatusIsNot(driverBundledLoad.loadStatus, ['CANCELLED']) && isListNotEmpty(driverBundledLoad.driverIds) && driverBundledLoad.driverIds.includes(accountUser.id)) {
                            driverBundledLoad.loadName = LoadUtils.getChildLoadName(driverBundledLoad, loadIndex);
                            driverBundledLoad.loadNumber = loadIndex + 1;
                            if (driverBundledLoads.find(i => i.id === driverBundledLoad.id) === undefined) {
                                driverBundledLoads.push(driverBundledLoad);
                            }
                        }
                    });
                }
                if (isListNotEmpty(driverLoads)) {
                    driverLoads.forEach((driverOtherLoad) => {
                        if (driverOtherLoad.isDeleted === false && LoadUtils.loadStatusIsNot(driverOtherLoad.loadStatus, ['CANCELLED']) && isListNotEmpty(driverOtherLoad.driverIds) && driverOtherLoad.driverIds.includes(accountUser.id)) {
                            if (driverOtherLoads.find(i => i.id === driverOtherLoad.id) === undefined) {
                                driverOtherLoads.push(driverOtherLoad);
                            }
                        }
                    });
                }

                let driverScheduledShifts = scheduledShifts.filter(i => i.accountUserIds && i.accountUserIds.length > 0 && i.accountUserIds.includes(accountUser.id));

                driversArray.push({
                    ...accountUser,
                    accountUser: accountUser,
                    driverBundledLoads: driverBundledLoads,
                    driverOtherLoads: driverOtherLoads,
                    scheduledShifts: driverScheduledShifts
                });
            });

            if (isListNotEmpty(selectedAccountUserIds)) {
                drivers.filter(i => i.id !== driver.id && selectedAccountUserIds.includes(i.id)).forEach((accountUser) => {
                    let driverBundledLoads = [];
                    let driverOtherLoads = [];
                    if (isListNotEmpty(bundledLoads)) {
                        bundledLoads.forEach((driverBundledLoad, loadIndex) => {
                            if (driverBundledLoad.isDeleted === false && LoadUtils.loadStatusIsNot(driverBundledLoad.loadStatus, ['CANCELLED']) && isListNotEmpty(driverBundledLoad.driverIds) && driverBundledLoad.driverIds.includes(accountUser.id)) {
                                driverBundledLoad.loadName = LoadUtils.getChildLoadName(driverBundledLoad, loadIndex);
                                driverBundledLoad.loadNumber = loadIndex + 1;
                                if (driverBundledLoads.find(i => i.id === driverBundledLoad.id) === undefined) {
                                    driverBundledLoads.push(driverBundledLoad);
                                }
                            }
                        });
                    }
                    if (isListNotEmpty(driverLoads)) {
                        driverLoads.forEach((driverOtherLoad) => {
                            if (driverOtherLoad.isDeleted === false && LoadUtils.loadStatusIsNot(driverOtherLoad.loadStatus, ['CANCELLED']) && isListNotEmpty(driverOtherLoad.driverIds) && driverOtherLoad.driverIds.includes(accountUser.id)) {
                                if (driverOtherLoads.find(i => i.id === driverOtherLoad.id) === undefined) {
                                    driverOtherLoads.push(driverOtherLoad);
                                }
                            }
                        });
                    }

                    let driverScheduledShifts = scheduledShifts.filter(i => i.accountUserIds && i.accountUserIds.length > 0 && i.accountUserIds.includes(accountUser.id));

                    if (isListNotEmpty(selectedShiftIds)) {
                        let isInShift = false;
                        selectedShiftIds.forEach((selectedShiftId) => {
                            if (isListNotEmpty(driverScheduledShifts)) {
                                if (driverScheduledShifts.filter(i => i.shiftId === selectedShiftId).length > 0) {
                                    isInShift = true;
                                }
                            }
                        });

                        if (isInShift === true) {
                            driversArray.push({
                                ...accountUser,
                                accountUser: accountUser,
                                driverBundledLoads: driverBundledLoads,
                                driverOtherLoads: driverOtherLoads,
                                scheduledShifts: driverScheduledShifts
                            });
                        }
                    } else {
                        driversArray.push({
                            ...accountUser,
                            accountUser: accountUser,
                            driverBundledLoads: driverBundledLoads,
                            driverOtherLoads: driverOtherLoads,
                            scheduledShifts: driverScheduledShifts
                        });
                    }
                });
            } else {
                drivers.filter(i => i.id !== driver.id).forEach((accountUser) => {
                    let driverBundledLoads = [];
                    let driverOtherLoads = [];
                    if (isListNotEmpty(bundledLoads)) {
                        bundledLoads.forEach((driverBundledLoad, loadIndex) => {
                            if (driverBundledLoad.isDeleted === false && LoadUtils.loadStatusIsNot(driverBundledLoad.loadStatus, ['CANCELLED']) && isListNotEmpty(driverBundledLoad.driverIds) && driverBundledLoad.driverIds.includes(accountUser.id)) {
                                driverBundledLoad.loadName = LoadUtils.getChildLoadName(driverBundledLoad, loadIndex);
                                driverBundledLoad.loadNumber = loadIndex + 1;
                                if (driverBundledLoads.find(i => i.id === driverBundledLoad.id) === undefined) {
                                    driverBundledLoads.push(driverBundledLoad);
                                }
                            }
                        });
                    }
                    if (isListNotEmpty(driverLoads)) {
                        driverLoads.forEach((driverOtherLoad) => {
                            if (driverOtherLoad.isDeleted === false && LoadUtils.loadStatusIsNot(driverOtherLoad.loadStatus, ['CANCELLED']) && isListNotEmpty(driverOtherLoad.driverIds) && driverOtherLoad.driverIds.includes(accountUser.id)) {
                                if (driverOtherLoads.find(i => i.id === driverOtherLoad.id) === undefined) {
                                    driverOtherLoads.push(driverOtherLoad);
                                }
                            }
                        });
                    }

                    let driverScheduledShifts = scheduledShifts.filter(i => i.accountUserIds && i.accountUserIds.length > 0 && i.accountUserIds.includes(accountUser.id));

                    if (isListNotEmpty(selectedShiftIds)) {
                        let isInShift = false;
                        selectedShiftIds.forEach((selectedShiftId) => {
                            if (isListNotEmpty(driverScheduledShifts)) {
                                if (driverScheduledShifts.filter(i => i.shiftId === selectedShiftId).length > 0) {
                                    isInShift = true;
                                }
                            }
                        });

                        if (isInShift === true) {
                            driversArray.push({
                                ...accountUser,
                                accountUser: accountUser,
                                driverBundledLoads: driverBundledLoads,
                                driverOtherLoads: driverOtherLoads,
                                scheduledShifts: driverScheduledShifts
                            });
                        }
                    } else {
                        driversArray.push({
                            ...accountUser,
                            accountUser: accountUser,
                            driverBundledLoads: driverBundledLoads,
                            driverOtherLoads: driverOtherLoads,
                            scheduledShifts: driverScheduledShifts
                        });
                    }
                });
            }

            setDriverMap(driversArray);
            // console.log(driversArray);
        } else {
            setDriverMap([]);
        }
    }, [drivers, loads, scheduledShifts, driver, selectedAccountUserIds, selectedShiftIds]);

    //#endregion
    //#region stop display methods

    const renderStop = (stop, showApptContact = false) => {
        if (isObjectNotEmpty(stop)) {
            const stopIcon = stop.stopType === 'PICK_UP' ? pickUpMarker : (stop.stopType === 'DROP_OFF' ? dropOffMarker : null);
            const stopLocation = LoadStopUtils.getStopLocationDisplay(stop, shipperId, carrierId, entityType, entityId);
            const stopAddress = LoadStopUtils.getStopAddressDisplay(stop);
            const apptDetails = LoadStopUtils.getAppointmentDetailsDisplay(stop);
            const apptCallAhead = LoadStopUtils.getAppointmentCallAheadDisplay(stop);
            const apptContact = LoadStopUtils.getAppointmentContactDisplay(stop);

            return (
                <div className={classes.stopDetails} key={`stop-details-${stop.id}`}>
                    <div className={classes.stopLocationContainer}>
                        <div className={classes.stopIconContainer}>
                            {isNotNullOrUndefined(stopIcon) ? (<img src={stopIcon} alt={Enums.StopTypes.getValueByName(stop.stopType)} className={classes.stopIcon} />) : null}
                        </div>
                        <div className={classes.stopLocation}>{stopLocation}</div>
                    </div>
                    {isStringNotEmpty(stopAddress) ? (<div className={classes.stopAddress}>{stopAddress}</div>) : null}
                    {isStringNotEmpty(apptDetails) ? (<div className={classes.apptDetails}>{apptDetails}</div>) : null}
                    {isStringNotEmpty(apptCallAhead) ? (<div className={classes.apptDetails}>{apptCallAhead}</div>) : null}
                    {isStringNotEmpty(apptContact) && showApptContact === true ? (<div className={classes.apptDetails}>{apptContact}</div>) : null}
                </div>
            );
        } else {
            return null;
        }
    };

    const renderStops = () => {
        if (isListNotEmpty(stops)) {
            const pickUpAndDropOffStops = stops.filter(s => s.stopType === 'PICK_UP' || s.stopType === 'DROP_OFF');
            if (isListNotEmpty(pickUpAndDropOffStops)) {
                return pickUpAndDropOffStops.map((stop) => { return renderStop(stop, false); });
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    //#endregion
    //#region load display methods

    const renderLoadDetails = (load, stops = null) => {
        return (
            <Card
                title="Load & Route Details"
                style={{ border: '1px solid #D8D8D8' }}
                bodyStyle={{ minHeight: 400, padding: 0 }}
                headStyle={{ backgroundColor: '#334353', color: '#ffffff', borderBottom: '1px solid #D8D8D8', fontSize: 14, textAlign: 'center' }}
            >
                <Row>
                    <Col span={12} style={{ padding: 12 }}>
                        <TitleBlockWithIcon
                            style={{ marginTop: 40, marginBottom: 40, marginLeft: 'auto', marginRight: 'auto' }}
                            icon={<FontAwesomeIcon className={classes.icon} icon={faPallet} />}
                            title={
                                <span>Load ID: {load.irisId}<br />Status: {LoadUtils.getLoadStatusDisplay(load.loadStatus, entityType, load)}</span>
                            }
                        />
                        <div style={{ margin: '0px auto 0px auto', maxWidth: 'max-content' }}>
                            {isObjectNotEmpty(load.equipmentNeeds) ? (
                                <>
                                    {isStringNotEmpty(load.equipmentNeeds.trailerType) ? (<DataRow title="Trailer Type" value={load.equipmentNeeds.trailerType.description} dataType="String" />) : null}
                                    {isNumberNotEmpty(load.equipmentNeeds.trailerLength) ? (<DataRow title="Trailer Length" value={load.equipmentNeeds.trailerLength} units={load.equipmentNeeds.trailerLengthUnit} dataType="Length" />) : null}
                                    {isNumberNotEmpty(load.equipmentNeeds.trailerWeightCapacity) ? (<DataRow title="Max Load Weight Capacity" value={load.equipmentNeeds.trailerWeightCapacity} units={load.equipmentNeeds.trailerWeightCapacityUnit} dataType="Weight" />) : null}
                                    {isNumberNotEmpty(load.equipmentNeeds.preCoolReefer) ? (<DataRow title="Pre-cool Temp" value={load.equipmentNeeds.preCoolReefer} units={load.equipmentNeeds.preCoolReeferUnit} dataType="Temperature" />) : null}
                                    {isNumberNotEmpty(load.equipmentNeeds.minRunTemp) && isNumberNotEmpty(load.equipmentNeeds.maxRunTemp) ? (<DataTemperatureRangeRow title="Temp Range" min={load.equipmentNeeds.minRunTemp} minUnits={load.equipmentNeeds.minRunTempUnit} max={load.equipmentNeeds.maxRunTemp} maxUnits={load.equipmentNeeds.maxRunTempUnit} />) : null}
                                </>
                            ) : null}
                            {isListNotEmpty(load.equipmentSpecifics) ? (
                                <DataListRow title="Equipment Specifics" valueStyle={{ marginLeft: 16 }} data={load.equipmentSpecifics} dataType="EquipmentSpecific" />
                            ) : null}
                            {isListNotEmpty(load.driverRequirements) ? (
                                <DataListRow title="Driver Requirements" valueStyle={{ marginLeft: 16 }} data={load.driverRequirements} dataType="DriverRequirement" />
                            ) : null}
                        </div>
                    </Col>
                    <Col span={12} style={{ borderLeft: '1px solid #D8D8D8', padding: 12 }}>
                        <TitleBlockWithIcon
                            style={{ margin: '40px auto 40px auto' }}
                            icon={<FontAwesomeIcon className={classes.icon} icon={faRoute} />}
                            title={
                                <span>Trip Miles: {stringFormatter.toFormattedString('Distance', load.practicalDistance, load.practicalDistanceUnit)}<br />
                                Number of Stops: {stops !== null ? stops.filter(s => s.stopType === 'PICK_UP' || s.stopType === 'DROP_OFF').length : load.numberOfStops}</span>
                            }
                        />
                        <div style={{ margin: '0px auto 0px auto', maxWidth: 'max-content' }}>
                            {stops !== null ? (renderStops(stops)) : ((isObjectNotEmpty(load.origin) && isObjectNotEmpty(load.destination)) ? (
                                <>
                                    {renderStop(load.origin, false)}
                                    {renderStop(load.destination, false)}
                                </>
                            ) : null)}
                        </div>
                    </Col>
                </Row>
            </Card>
        );
    };

    //#endregion
    //#region resource display methods

    const renderResourceContent = (info) => {
        //console.log(info);
        let resource = info.resource;
        let accountUser = resource.extendedProps;

        return (
            <div>
                <div>
                    <span><strong>{accountUser.firstName} {accountUser.lastName}{isStringNotEmpty(accountUser.title) ? ' (' + accountUser.title + ')' : ''}</strong></span>
                </div>
                <div>
                    <span><i>Available: {isObjectNotEmpty(accountUser.driver) ? stringFormatter.toYesNo(accountUser.driver.isAvailable) : 'No'}</i></span>
                </div>
                <div>
                    <Button type="primary" onClick={(e) => { e.preventDefault(); e.stopPropagation(); onChangeDriver(accountUser.id); }}>Swap Driver</Button>
                </div>
            </div>
        );
    };

    const getDriverLoadsDisplay = (accountUser, driverBundledLoads, driverOtherLoads) => {
        let driverLoadRows = [];
        let loadsToShow = [];

        if (isListNotEmpty(driverBundledLoads)) {
            driverBundledLoads.forEach((loadObj) => {
                if (loadsToShow.find(i => i.id === loadObj.id) === undefined && loadObj.id !== accountUser.id) {
                    loadsToShow.push(loadObj);
                }
            });
        }

        if (isListNotEmpty(driverOtherLoads)) {
            driverOtherLoads.forEach((loadObj) => {
                if (loadsToShow.find(i => i.id === loadObj.id) === undefined && loadObj.id !== accountUser.id) {
                    loadsToShow.push(loadObj);
                }
            });
        }

        if (isListNotEmpty(loadsToShow)) {
            loadsToShow.forEach((loadObj) => {
                let title = isStringNotEmpty(loadObj.loadName) ? loadObj.loadName : LoadUtils.getLoadName(loadObj);
                let time = LoadStopUtils.getOriginAndDestinationTimes(loadObj.origin, loadObj.destination);

                driverLoadRows.push(
                    <div key={`driver-load-${accountUser.id}-${loadObj.id}`}>
                        <span><strong>{`${title} (${time})`}</strong></span>
                    </div>
                );
            });
        }

        return (
            <div key={`driver-loads-${accountUser.id}`} style={{ marginTop: 24, marginBottom: 24 }}>
                <div><strong>Loads Assigned:</strong></div>
                {isListNotEmpty(driverLoadRows) ? driverLoadRows : <div><span>No Other Loads</span></div>}
            </div>
        );
    };

    const getDriverScheduledShiftsDisplay = (accountUser, driverScheduledShifts, load, startDate, endDate) => {
        let driverScheduledShiftRows = [];
        if (isListNotEmpty(driverScheduledShifts)) {
            driverScheduledShifts.forEach((scheduledShift) => {
                let shift = isObjectNotEmpty(scheduledShift.shift) ? scheduledShift.shift : null;
                let title = isObjectNotEmpty(shift) && isStringNotEmpty(shift.name) ? shift.name : '';
                let scheduledDate = moment(scheduledShift.startDateTime).format('MM/DD/YYYY');
                let timeZone = moment.tz(shift.timeZone).zoneAbbr();
                let time = `${scheduledDate}, ${isObjectNotEmpty(shift) && isStringNotEmpty(shift.startTime) ? shift.startTime : ''} - ${isObjectNotEmpty(shift) && isStringNotEmpty(shift.endTime) ? shift.endTime : ''}${isStringNotEmpty(timeZone) ? ' ' + timeZone : ''}`;

                let isAvailable = false;
                if (scheduledShift.startDateTime <= endDate && scheduledShift.endDateTime >= load.pickUpDateTime && isObjectNotEmpty(shift) && isBooleanTrue(shift.isAvailable)) {
                    isAvailable = true;
                }
                driverScheduledShiftRows.push(
                    <div key={`driver-scheduled-shift-${accountUser.id}-${title}-${time}`}>
                        {isBooleanTrue(isAvailable) ? <InfoCircleTwoTone title="Available" alt="Available" twoToneColor="#23BEBB" /> : <InfoCircleTwoTone title="Not Available" alt="Not Available" twoToneColor="#EF5350" />} <span><strong>{`${title} (${time})`}</strong></span>
                    </div>
                );
            });
        }

        return (
            <div key={`driver-scheduled-shifts-${accountUser.id}`}>
                <div><strong>Shift(s):</strong></div>
                {isListNotEmpty(driverScheduledShiftRows) ? driverScheduledShiftRows : <div><span>--</span></div>}
            </div>
        );
    };

    const isDriverAvailable = (accountUser, driverScheduledShifts, load, startDate, endDate) => {
        let isAvailable = false;
        if (isObjectNotEmpty(accountUser) && isObjectNotEmpty(accountUser.driver)) {
            isAvailable = isBooleanTrue(accountUser.driver.isAvailable);
        }

        if (isListNotEmpty(driverScheduledShifts)) {
            driverScheduledShifts.forEach((scheduledShift) => {
                let shift = isObjectNotEmpty(scheduledShift.shift) ? scheduledShift.shift : null;
                if (isObjectNotEmpty(shift)) {
                    if (scheduledShift.startDateTime <= endDate && scheduledShift.endDateTime >= load.pickUpDateTime && isObjectNotEmpty(shift) && isBooleanTrue(shift.isAvailable)) {
                        isAvailable = true;
                    } else {
                        isAvailable = false;
                    }
                }
            });
        }

        return isAvailable;
    };

    //#endregion
    //#region event display methods

    const eventDetailsComponents = (eventObj) => {
        if (isObjectNotEmpty(eventObj)) {
            if (eventObj.eventType === 'Load') {
                let loadObj = eventObj;
                let title = LoadUtils.getLoadName(loadObj);
                return (
                    <Modal
                        title={`${title}`}
                        visible={showDetails === true}
                        width="80%"
                        style={{ top: 0 }}
                        onCancel={(e) => { toggleShowDetails(); }}
                        onOk={(e) => { toggleShowDetails(); }}
                        footer={[
                            <Button type="default" onClick={(e) => { toggleShowDetails(); }}>Close</Button>
                        ]}
                    >
                        {renderLoadDetails(loadObj)}
                    </Modal>
                );
            } else if (eventObj.eventType === 'ScheduledShift') {
                let scheduledShift = eventObj;
                let shift = isObjectNotEmpty(scheduledShift) && isObjectNotEmpty(scheduledShift.shift) ? scheduledShift.shift : null;
                let title = isObjectNotEmpty(shift) && isStringNotEmpty(shift.name) ? shift.name : '';
                let scheduledDate = moment(scheduledShift.startDateTime).format('MM/DD/YYYY');
                let timeZone = moment.tz(shift.timeZone).zoneAbbr();
                let time = `${scheduledDate}, ${isObjectNotEmpty(shift) && isStringNotEmpty(shift.startTime) ? shift.startTime : ''} - ${isObjectNotEmpty(shift) && isStringNotEmpty(shift.endTime) ? shift.endTime : ''}${isStringNotEmpty(timeZone) ? ' ' + timeZone : ''}`;

                return (
                    <Modal
                        title={`${title}: ${time}`}
                        visible={showDetails === true}
                        onCancel={(e) => { toggleShowDetails(); }}
                        onOk={(e) => { toggleShowDetails(); }}
                        footer={[
                            <Button type="default" onClick={(e) => { toggleShowDetails(); }}>Close</Button>
                        ]}
                    >
                        <div>
                            <div>
                                <span><strong>{isStringNotEmpty(scheduledShift.title) ? scheduledShift.title : ''}</strong></span>
                            </div>
                            {isListNotEmpty(scheduledShift.accountUsers) ? scheduledShift.accountUsers.map((accountUser) => {
                                return (
                                    <div>{accountUser.firstName} {accountUser.lastName}{isStringNotEmpty(accountUser.title) ? ' (' + accountUser.title + ')' : ''}</div>
                                );
                            }) : null}
                        </div>
                    </Modal>
                );
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    const renderEventContent = (eventInfo) => {
        // console.log(eventInfo);
        let eventObj = isObjectNotEmpty(eventInfo) && isObjectNotEmpty(eventInfo.event) && isObjectNotEmpty(eventInfo.event.extendedProps) ? eventInfo.event.extendedProps : {};
        if (isObjectNotEmpty(eventObj)) {
            if (eventObj.eventType === 'Load') {
                let load = eventObj;
                let title = LoadUtils.getLoadName(load);
                let pickUp = isObjectNotEmpty(load.origin) ? momentDate.fromUtcToTimeZone(load.origin.requestedDateTime, load.origin.timeZone) : '';
                // let dropOff = isObjectNotEmpty(load.destination) ? momentDate.fromUtcToTimeZone(load.destination.requestedDateTime, load.destination.timeZone) : '';

                return (
                    <div style={{ backgroundColor: eventInfo.backgroundColor, color: eventInfo.textColor, width: '100%', whiteSpace: 'normal' }}>
                        <div>
                            <span style={{ whiteSpace: 'normal' }}><b>{pickUp}</b> <i>{title}</i></span>
                        </div>
                    </div>
                );
            } else if (eventObj.eventType === 'ScheduledShift') {
                let scheduledShift = eventObj;
                let shift = isObjectNotEmpty(scheduledShift) && isObjectNotEmpty(scheduledShift.shift) ? scheduledShift.shift : null;
                if (isObjectNotEmpty(scheduledShift) && isObjectNotEmpty(shift)) {
                    return (
                        <div style={{ backgroundColor: eventInfo.backgroundColor, color: eventInfo.textColor, width: '100%', whiteSpace: 'normal' }}>
                            <div>
                                <span style={{ whiteSpace: 'normal' }}><b>{shift.startTime} - {shift.endTime}</b> <i>{shift.name}</i></span>
                            </div>
                        </div>
                    );
                } else {
                    return null;
                }
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    //#endregion

    return (
        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true && error === null) || isLoadingScheduledShifts === true || isLoadingLoads === true}>
            {/* <Row gutter={[24, 24]}>
                <Col span={24}>
                    <div style={{ paddingLeft: 24, paddingRight: 24 }}>
                        {renderLoadDetails(load, stops)}
                    </div>
                </Col>
            </Row> */}
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <Select
                        placeholder="Drivers: All"
                        mode="multiple"
                        allowClear={true}
                        style={{ width: '100%' }}
                        virtual={false}
                        onChange={(selected) => { setSelectedAccountUserIds(selected); }}
                        showSearch={true}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Employees for you to choose from." />}
                    >
                        {drivers.filter(i => i.id !== driver.id).map(i => <Option value={i.id} key={i.id}>{i.firstName} {i.lastName}{isStringNotEmpty(i.title) ? ' (' + i.title + ')' : ''}</Option>)}
                    </Select>
                </Col>
                <Col span={24}>
                    <Select
                        placeholder="Shifts: All"
                        mode="multiple"
                        allowClear={true}
                        style={{ width: '100%' }}
                        virtual={false}
                        onChange={(selected) => { setSelectedShiftIds(selected); }}
                        showSearch={true}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Shifts for you to choose from." />}
                    >
                        {shifts.map(i => <Option value={i.id} key={i.id}>{i.name}: {i.startTime} - {i.endTime}</Option>)}
                    </Select>
                </Col>
                <Col span={24}>
                    {isObjectNotEmpty(driver) && isListNotEmpty(driverMap) ? (
                        <>
                            {driverMap.filter(i => i.id === driver.id).map((driver) => {
                                let accountUser = driver.accountUser;
                                return (
                                    <Card
                                        key={`dispatch-change-driver-card-${accountUser.id}`}
                                        style={{ marginBottom: 12, backgroundColor: '#FFFBE5', borderRadius: '15px' }}
                                        title={
                                            <div style={{ display: 'flex' }}>
                                                <div className={classes.iconContainer}><FontAwesomeIcon className={classes.icon} icon={faIdCard} /></div>
                                                <div className={classes.textContainer}>
                                                    <span><strong>{accountUser.firstName} {accountUser.lastName}</strong><br /><i>Available: {isObjectNotEmpty(accountUser.driver) ? stringFormatter.toYesNo(accountUser.driver.isAvailable) : 'No'}</i></span>
                                                </div>
                                            </div>
                                        }
                                        extra={<Button danger type="primary" icon={<FontAwesomeIcon className="anticon" icon={faTimes} />} shape="circle" onClick={(e) => { onRemoveDriver(); }}></Button>}
                                    >
                                        {getDriverScheduledShiftsDisplay(accountUser, driver.scheduledShifts, load, startDate, endDate)}
                                        {getDriverLoadsDisplay(accountUser, driver.driverBundledLoads, driver.driverOtherLoads)}
                                    </Card>
                                );
                            })}
                            <div style={{ height: 500, overflowY: 'auto' }}>
                                {driverMap.filter(i => i.id !== driver.id).map((driver) => {
                                    let accountUser = driver.accountUser;
                                    let isAvailable = isDriverAvailable(accountUser, driver.scheduledShifts, load, startDate, endDate);
                                    return (
                                        <Card
                                            key={`dispatch-change-driver-card-${accountUser.id}`}
                                            style={{ marginBottom: 12, borderRadius: '15px' }}
                                            title={
                                                <div style={{ display: 'flex' }}>
                                                    <div className={classes.iconContainer}><FontAwesomeIcon className="anticon" icon={faIdCard} /></div>
                                                    <div className={classes.textContainer}>
                                                        <span><strong>{accountUser.firstName} {accountUser.lastName}</strong><br /><i>Available: {stringFormatter.toYesNo(isAvailable)}</i></span>
                                                    </div>
                                                </div>
                                            }
                                            extra={<Button type="primary" icon={<FontAwesomeIcon className="anticon" icon={faExchangeAlt} />} shape="circle" disabled={isAvailable === false} onClick={(e) => { onChangeDriver(driver.id); }} />}
                                        >
                                            {getDriverScheduledShiftsDisplay(accountUser, driver.scheduledShifts, load, startDate, endDate)}
                                            {getDriverLoadsDisplay(accountUser, driver.driverBundledLoads, driver.driverOtherLoads)}
                                        </Card>
                                    );
                                })}
                            </div>
                        </>
                    ) : null}
                    {/* {isNotNullOrUndefined(duration) ? (
                        <FullCalendar
                            ref={schedulerRef}
                            plugins={[dayGridPlugin, interactionPlugin, resourceTimelinePlugin]}
                            headerToolbar={{
                                left: 'prevButton,nextButton',
                                center: 'title',
                                right: ''
                            }}
                            titleFormat={{ year: 'numeric', month: 'long', day: 'numeric' }}
                            views={{
                                resourceTimelineWeek: {
                                    type: 'resourceTimeline',
                                    duration: { days: duration },
                                    slotDuration: { days: 1 },
                                    buttonText: 'week'
                                }
                            }}
                            slotLabelFormat={[
                                { month: '2-digit', year: 'numeric', day: '2-digit' }, // top level of text
                                { weekday: 'short' } // lower level of text
                            ]}
                            resourceAreaHeaderContent="Employees"
                            initialView='resourceTimelineWeek'
                            initialDate={isObjectNotEmpty(load) ? load.pickUpDateTime : null}
                            resources={resourceMap}
                            resourceOrder={'firstName,LastName'}
                            editable={false}
                            selectable={false}
                            allDaySlot={false}
                            dayMaxEventRows={false}
                            dayMaxEvents={false}
                            nextDayThreshold={'06:00:00'}
                            nowIndicator={true}
                            now={isObjectNotEmpty(load) ? load.pickUpDateTime : null}
                            //slotMinWidth={100}
                            weekends={true}
                            expandRows={true}
                            height="500px"
                            eventsSet={(events) => {
                                // console.log(events);

                                if (isObjectNotEmpty(load)) {
                                    // console.log(load);
                                    let loadPickUpDate = moment(load.pickUpDateTime);
                                    let loadDropOffDate = moment(load.eta);
                                    // console.log(loadPickUpDate);
                                    // console.log(loadDropOffDate);
                                    let days = document.getElementsByClassName('fc-day');

                                    for (let i = 0; i < days.length; i++) {
                                        let el = days.item(i);
                                        // console.log(el);
                                        let thisDate = el.getAttribute('data-date');
                                        if (moment(thisDate).isBetween(loadPickUpDate, loadDropOffDate, "day", "[]")) {
                                            // console.log(thisDate);


                                            if (el !== undefined && el !== null && el.className !== undefined && el.className !== null) {
                                                let classNames = el.className.split(' ');
                                                // console.log(classNames)
                                                if (!classNames.includes(classes['fc-load-highlight'])) {
                                                    el.className = classNames.join(' ') + ' ' + classes['fc-load-highlight'];
                                                }
                                            }
                                        } else {
                                            if (el !== undefined && el !== null && el.classNames !== undefined && el.classNames !== null) {
                                                let classNames = el.classNames.split(' ');
                                                if (classNames.includes(classes['fc-load-highlight'])) {
                                                    el.className = classNames.filter(i => i !== classes['fc-load-highlight']).join(' ');
                                                }
                                            }
                                        }
                                    }
                                }
                            }}
                            // resourcesSet={(resources) => { console.log(resources) }}
                            events={events}
                            eventContent={renderEventContent}
                            eventClick={handleEventClick}
                            resourceLabelContent={renderResourceContent}
                            customButtons={{
                                nextButton: {
                                    text: 'next',
                                    click: next
                                },
                                prevButton: {
                                    text: 'prev',
                                    click: prev
                                }
                            }}
                        />
                    ) : null} */}
                </Col>
                <Col span={24}>
                    <b>Driver Doesn't Exist Yet?</b>
                    <br />
                    <Button type='primary' onClick={toggleNewDriver}>Add New Driver to Account</Button>
                </Col>
            </Row>
            {eventDetailsComponents(selectedEvent)}
            <Drawer
                title={'Add New Driver'}
                width={fullWidth > 720 ? fullWidth / 2 : 360}
                onClose={toggleNewDriver}
                visible={showNewDriver}
                bodyStyle={{ paddingBottom: 80 }}
                style={{ zIndex: 1000 }}
                destroyOnClose={true}
                closable={false}
                maskClosable={false}
            >
                <NewDriver cancel={toggleNewDriver} accountId={carrierId} />
            </Drawer>
        </Spin>
    );
};

export default DispatchChangeDriver;