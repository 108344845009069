import React from 'react';
import DataRow from '../DataRow/DataRow';
import { Col, Row } from 'antd';
import { isNumberNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';

const Configuration = ({ configuration }) => {
    return (
        <Row>
            <Col xs={24} md={24} lg={24} xl={24}>
                {isStringNotEmpty(configuration.name) ? (<DataRow title="Name" value={configuration.name} dataType="String" />) : null}
                {isStringNotEmpty(configuration.companyPhoneNumber) ? (<DataRow title="Customer Support Phone Number" value={configuration.companyPhoneNumber} dataType="PhoneNumber" />) : null}
                {isNumberNotEmpty(configuration.geoFenceRadius) ? (<DataRow title="GeoFence Radius" value={configuration.geoFenceRadius} units={configuration.geoFenceRadiusUnit} dataType="Distance" />) : null}
                {isNumberNotEmpty(configuration.locationDistanceInterval) ? (<DataRow title="Location Distance Interval" value={configuration.locationDistanceInterval} units={configuration.locationDistanceIntervalUnit} dataType="Distance" />) : null}
                {isNumberNotEmpty(configuration.locationTimeInterval) ? (<DataRow title="Location Time Interval" value={configuration.locationTimeInterval} units={configuration.locationTimeIntervalUnit} dataType="Time" />) : null}
            </Col>
        </Row>
    );
};

export default Configuration;