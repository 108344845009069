import Checks from '../entitlementUtils';

export const canCreateFeature = (auth) => {
    return Checks.allowStaffAdmin(auth);
};

export const canReadFeature = (auth) => {
    return Checks.allowStaffAdmin(auth);
};

export const canReadFeatureList = (auth) => {
    return Checks.allowStaffAdmin(auth);
};

export const canUpdateFeature = (auth) => {
    return Checks.allowStaffAdmin(auth);
};

export const canDeleteFeature = (auth) => {
    return Checks.allowStaffAdmin(auth);
};