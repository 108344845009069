import React, { useMemo } from 'react';
import { Spin, Input, Select, Alert } from "antd";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import moment from 'moment';
import { FormProvider, useForm } from 'react-hook-form';
import Form from '../../components/Form/Form';
import FormItem from '../../components/FormItem/FormItem';
import Fieldset from '../../components/FormFieldset/FormFieldset';
import { isNumberNotEmpty, isObjectNotEmpty, isStringNotEmpty, removeEmpty } from '../../shared/objectUtils';
import FormItemDisplay from '../../components/FormItemDisplay/FormItemDisplay';
import { CanDo, CanSee } from '../../shared/entitlements/entitlements';
import FormButtons from '../FormButtons/FormButtons';
import Enums from '../../shared/enums';
import DatePicker from '../DatePickerAutoAccept/DatePickerAutoAccept';
import FormItemAddress from '../FormItemAddress/FormItemAddress';
import FormItemPointOfContact from '../FormItemPointOfContact/FormItemPointOfContact';

const { Option } = Select;
const { TextArea } = Input;

const EditInvoice = ({ invoice, load, shipperId, carrierId, cancel }) => {
    //#region constants

    const requireInvoiceFields = isObjectNotEmpty(invoice) && (invoice.invoiceType === 'SHIPPER_INVOICE' || invoice.invoiceType === 'CARRIER_INVOICE');

    //#endregion
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region enums

    const invoiceStatusOptions = Enums.InvoiceStatuses.selectListOptions();
    const paymentStatusOptions = Enums.PaymentStatuses.selectListOptions();
    const preferredPaymentMethodOptions = Enums.PreferredPaymentMethods.selectListOptions();
    const invoiceAccountTypeOptions = CanDo({ entityTypes: ['CARRIER'] }) ? Enums.InvoiceAccountTypes.selectListOptionsWithExclusions(["STAFF", "BROKER"]) : Enums.InvoiceAccountTypes.selectListOptions();

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.invoices.isRecordUpdateLoading);
    const error = useSelector(state => state.invoices.updateRecordError);
    const isAdmin = useSelector(state => state.auth.isAdmin);

    //#endregion
    //#region onSubmit

    const onSubmit = (data) => {
        if (isObjectNotEmpty(data)) {
            if (data.fromEntityType === 'SHIPPER' && isStringNotEmpty(shipperId)) {
                data.fromEntityId = shipperId;
            } else if (data.fromEntityType === 'CARRIER' && isStringNotEmpty(carrierId)) {
                data.fromEntityId = carrierId;
            }

            if (data.toEntityType === 'SHIPPER' && isStringNotEmpty(shipperId)) {
                data.toEntityId = shipperId;
            } else if (data.toEntityType === 'CARRIER' && isStringNotEmpty(carrierId)) {
                data.toEntityId = carrierId;
            }

            if (isObjectNotEmpty(data.fromPointOfContact)) {
                let cleanFromPointOfContact = removeEmpty(data.fromPointOfContact);
                data.fromPointOfContact = cleanFromPointOfContact;
            }
            if (isObjectNotEmpty(data.toPointOfContact)) {
                let cleanToPointOfContact = removeEmpty(data.toPointOfContact);
                data.toPointOfContact = cleanToPointOfContact;
            }
            if (isObjectNotEmpty(data.remitPointOfContact)) {
                let cleanRemitPointOfContact = removeEmpty(data.remitPointOfContact);
                data.remitPointOfContact = cleanRemitPointOfContact;
            }

            if (isNumberNotEmpty(data.amountDue)) {
                data.amountDueUnit = 'USD';
            }

            if (isNumberNotEmpty(data.balanceDue)) {
                data.balanceDueUnit = 'USD';
            }

            dispatch(actionCreators.updateInvoice(invoice.id, data, invoice, true));
        } else {
            onCancel();
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.updateInvoiceCancel());
        cancel();
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateInvoiceErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    //#endregion

    if (isObjectNotEmpty(invoice)) {
        return (
            <CanSee entityAction="UPDATE" entityModel="LOAD_INVOICE" entityObject={load}>
                <FormProvider {...methods}>
                    <Form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                            <Fieldset legend="Invoice Details">
                                <FormItem {...formItemLayout} label="Invoice Date" required format="horizontal"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <DatePicker
                                            placeholder="Invoice Date"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={(date, dateString) => { onChange(date); }}
                                            value={value}
                                            name={name}
                                            onSelect={(date) => { onChange(date); }}
                                            ref={ref}
                                        />
                                    )}
                                    rules={{ required: "Required Field" }}
                                    name="invoiceDate"
                                    defaultValue={invoice.invoiceDate ? moment(invoice.invoiceDate) : null}
                                />
                                <FormItem {...formItemLayout} label="Customer Invoice Number" format="horizontal"
                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Customer Invoice Number" ref={ref} />}
                                    rules={{ required: false }}
                                    name="customerInvoiceNumber"
                                    defaultValue={invoice.customerInvoiceNumber}
                                />
                                <FormItem {...formItemLayout} label="Customer Order Number" format="horizontal"
                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Customer Order Number" ref={ref} />}
                                    rules={{ required: false }}
                                    name="customerOrderNumber"
                                    defaultValue={invoice.customerOrderNumber}
                                />

                                <FormItem {...formItemLayout} label="Payment Terms" required={requireInvoiceFields} format="horizontal"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Select
                                            placeholder="Please Select the Payment Terms"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChange(selected); }}
                                            value={value}
                                            name={name}
                                            ref={ref}
                                        >
                                            <Option key='0' value='0'>NET 0</Option>
                                            <Option key='15' value='15'>NET 15</Option>
                                            <Option key='30' value='30'>NET 30</Option>
                                            <Option key='45' value='45'>NET 45</Option>
                                            <Option key='60' value='60'>NET 60</Option>
                                        </Select>
                                    )}
                                    rules={{ required: requireInvoiceFields ? "Required Field" : false }}
                                    name="paymentTerms"
                                    defaultValue={invoice.paymentTerms}
                                />
                                <FormItem {...formItemLayout} label="Payment Due Date" required={requireInvoiceFields} format="horizontal"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <DatePicker
                                            placeholder="Payment Due Date"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={(date, dateString) => { onChange(date); }}
                                            value={value}
                                            name={name}
                                            onSelect={(date) => { onChange(date); }}
                                            ref={ref}
                                        />
                                    )}
                                    rules={{ required: requireInvoiceFields ? "Required Field" : false }}
                                    name="paymentDueDate"
                                    defaultValue={invoice.paymentDueDate ? moment(invoice.paymentDueDate) : null}
                                />
                                <FormItem {...formItemLayout} label="Preferred Payment Method" required={invoice.invoiceType === 'CARRIER_INVOICE'} format="horizontal"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Select
                                            placeholder="Please Select the Preferred Payment Method"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChange(selected); }}
                                            value={value}
                                            name={name}
                                            ref={ref}
                                        >
                                            {preferredPaymentMethodOptions}
                                        </Select>
                                    )}
                                    rules={{ required: invoice.invoiceType === 'CARRIER_INVOICE' ? "Required Field" : false }}
                                    name="paymentMethod"
                                    defaultValue={invoice.paymentMethod}
                                />
                                {isAdmin === true ? (
                                    <>
                                        <FormItem {...formItemLayout} label="Payment Status" required={requireInvoiceFields} format="horizontal"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Select
                                                    placeholder="Please Select the Payment Status"
                                                    allowClear={true}
                                                    style={{ width: '100%' }}
                                                    virtual={false}
                                                    onBlur={onBlur}
                                                    onChange={(selected) => { onChange(selected); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                >
                                                    {paymentStatusOptions}
                                                </Select>
                                            )}
                                            rules={{ required: requireInvoiceFields ? "Required Field" : false }}
                                            name="paymentStatus"
                                            defaultValue={invoice.paymentStatus}
                                        />
                                        <FormItem {...formItemLayout} label="Invoice Status" required={requireInvoiceFields} format="horizontal"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Select
                                                    placeholder="Please Select the Invoice Status"
                                                    allowClear={true}
                                                    style={{ width: '100%' }}
                                                    virtual={false}
                                                    onBlur={onBlur}
                                                    onChange={(selected) => { onChange(selected); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                >
                                                    {invoiceStatusOptions}
                                                </Select>
                                            )}
                                            rules={{ required: requireInvoiceFields ? "Required Field" : false }}
                                            name="status"
                                            defaultValue={invoice.status}
                                        />
                                    </>
                                ) : null}

                                {/* <FormItem {...formItemLayout} label="Amount Due" required format="horizontal"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <InputNumber
                                            precision={2}
                                            min={0}
                                            placeholder="Amount Due"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={e => { onChange(e); }}
                                            value={value}
                                            name={name}
                                            ref={ref}
                                        />
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="amountDue"
                                    defaultValue={invoice.amountDue}
                                />
                                <FormItem {...formItemLayout} label="Balance Due" required format="horizontal"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <InputNumber
                                            precision={2}
                                            min={0}
                                            placeholder="Balance Due"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={e => { onChange(e); }}
                                            value={value}
                                            name={name}
                                            ref={ref}
                                        />
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="balanceDue"
                                    defaultValue={invoice.balanceDue}
                                /> */}

                                {/* <FormItemDouble {...formItemLayoutDouble} label1="Invoice From" label2="To" required1 required2 format="horizontal"
                                    render1={({ onChange, onBlur, value, name, ref }) => (
                                        <Select
                                            placeholder="Please Select"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChange(selected); }}
                                            value={value}
                                            name={name}
                                            ref={ref}
                                        >
                                            {invoiceAccountTypeOptions}
                                        </Select>
                                    )}
                                    rules1={{ required: 'Required Field' }}
                                    name1="fromEntityType"
                                    defaultValue1={invoice.fromEntityType}
                                    render2={({ onChange, onBlur, value, name, ref }) => (
                                        <Select
                                            placeholder="Please Select"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChange(selected); }}
                                            value={value}
                                            name={name}
                                            ref={ref}
                                        >
                                            {invoiceAccountTypeOptions}
                                        </Select>
                                    )}
                                    rules2={{ required: 'Required Field' }}
                                    name2="toEntityType"
                                    defaultValue2={invoice.toEntityType}
                                /> */}

                                <FormItem {...formItemLayout} label="From Name" format="horizontal"
                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Remit Name" ref={ref} />}
                                    rules={{ required: false }}
                                    name="fromName"
                                    defaultValue={invoice.fromName}
                                />
                                <FormItemDisplay {...formItemLayout} label="From Point of Contact" format="horizontal">
                                    <FormItemPointOfContact
                                        format="horizontal"
                                        required={false}
                                        name="fromPointOfContact"
                                        defaultValue={invoice.fromPointOfContact}
                                    />
                                </FormItemDisplay>
                                <FormItemDisplay {...formItemLayout} label="From Address" format="horizontal">
                                    <FormItemAddress
                                        format="horizontal"
                                        required={false}
                                        name="fromAddress"
                                        defaultValue={invoice.fromAddress}
                                        isPostalAddress={true}
                                    />
                                </FormItemDisplay>

                                <FormItem {...formItemLayout} label="Remit Name" format="horizontal"
                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Remit Name" ref={ref} />}
                                    rules={{ required: false }}
                                    name="remitName"
                                    defaultValue={invoice.remitName}
                                />
                                <FormItemDisplay {...formItemLayout} label="Remit Point of Contact" format="horizontal">
                                <FormItemPointOfContact
                                        format="horizontal"
                                        required={false}
                                        name="remitPointOfContact"
                                        defaultValue={invoice.remitPointOfContact}
                                    />
                                </FormItemDisplay>
                                <FormItemDisplay {...formItemLayout} label="Remit Address" format="horizontal">
                                    <FormItemAddress
                                        format="horizontal"
                                        required={false}
                                        name="remitAddress"
                                        defaultValue={invoice.remitAddress}
                                        isPostalAddress={true}
                                    />
                                </FormItemDisplay>

                                <FormItem {...formItemLayout} label="Bill To Name" format="horizontal"
                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Bill To Name" ref={ref} />}
                                    rules={{ required: false }}
                                    name="toName"
                                    defaultValue={invoice.toName}
                                />
                                <FormItemDisplay {...formItemLayout} label="Bill To Point of Contact" format="horizontal">
                                <FormItemPointOfContact
                                        format="horizontal"
                                        required={false}
                                        name="toPointOfContact"
                                        defaultValue={invoice.toPointOfContact}
                                    />
                                </FormItemDisplay>
                                <FormItemDisplay {...formItemLayout} label="Bill To Address" format="horizontal">
                                    <FormItemAddress
                                        format="horizontal"
                                        required={false}
                                        name="toAddress"
                                        defaultValue={invoice.toAddress}
                                        isPostalAddress={true}
                                    />
                                </FormItemDisplay>
                                <FormItem {...formItemLayout} label="Notes" format="horizontal"
                                    render={({ onChange, onBlur, value, name, ref }) => <TextArea onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} autoSize={{ minRows: 4 }} placeholder="Notes" ref={ref} />}
                                    rules={{ required: false }}
                                    name="invoiceNotes"
                                    defaultValue={invoice.invoiceNotes}
                                />
                            </Fieldset>
                            {error && <Alert message={`${error}`} type="error" />}
                        </Spin>
                        <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Update Invoice" />
                    </Form>
                </FormProvider>
            </CanSee>
        );
    } else {
        return null;
    }
};

export default EditInvoice;