import React, { useMemo, useState } from 'react';
import { Alert, Input, Spin } from "antd";
import DatePicker from '../DatePickerAutoAccept/DatePickerAutoAccept';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import UploadFile from "../UploadFile/UploadFile";
import moment from 'moment';
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import { isObjectNotEmpty } from '../../shared/objectUtils';
import FormItemFile from '../FormItemFile/FormItemFile';

const EditLicense = ({ cancel, license, accountUserId, actionText }) => {
    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.accountUsers.isRecordUpdateLoading);
    const error = useSelector(state => state.accountUsers.updateRecordError);
    const entityType = useSelector(state => state.auth.entityType);

    const [frontFile, setFrontFile] = useState(null);
    const [backFile, setBackFile] = useState(null);

    const onSubmit = (data) => {
        // console.log(data);

        let payload = {};

        payload.frontFileId = license.frontImageId;
        payload.backFileId = license.backImageId;

        payload.frontDocumentType = license.frontDocumentType;
        payload.backDocumentType = license.backDocumentType;

        if (frontFile) {
            payload.frontFile = frontFile;
        } else if (!license.frontImageId) {
            // Front of License is required
            methods.setError("frontFile", { type: "required", message: "Required File" });
            return;
        }

        if (backFile) {
            payload.backFile = backFile;
        } else if (!license.backImageId) {
            // Back of License is required
            methods.setError("backFile", { type: "required", message: "Required File" });
            return;
        }

        let newExpiryDate = null;
        if (data.expiryDate !== undefined && data.expiryDate !== null && moment.isMoment(data.expiryDate)) {
            newExpiryDate = data.expiryDate.toISOString();
        } else if (data.expiryDate !== undefined && data.expiryDate !== null) {
            newExpiryDate = moment(data.expiryDate).toISOString();
        }

        let existingExpiryDate = null;
        if (license.expirationDate !== undefined && license.expirationDate !== null && moment.isMoment(license.expirationDate)) {
            existingExpiryDate = license.expirationDate.toISOString();
        } else if (license.expirationDate !== undefined && license.expirationDate !== null) {
            existingExpiryDate = moment(license.expirationDate).toISOString();
        }

        if (newExpiryDate !== existingExpiryDate) {
            payload.expiryDate = data.expiryDate;
        }

        if (data.licenseNumber !== license.licenseNumber) {
            payload.licenseNumber = data.licenseNumber;
        }

        if (isObjectNotEmpty(payload)) {
            dispatch(actionCreators.updateAccountUserDocument(accountUserId, payload, entityType));
        } else {
            onCancel();
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.updateAccountUserCancel());
        cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateAccountUserErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    if (isObjectNotEmpty(license)) {
        return (
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                        <Fieldset legend={license.licenseTypeDisplayName}>
                            <FormItem {...formItemLayout} label="License #" required
                                render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="License #" ref={ref} />}
                                rules={{ required: 'Required Field' }}
                                name="licenseNumber"
                                defaultValue={license.licenseNumber}
                            />
                            <FormItem {...formItemLayout} label="Expiration Date" required
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <DatePicker
                                        placeholder="Expiration Date"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={(date, dateString) => { onChange(date); }}
                                        value={value}
                                        name={name}
                                        onSelect={(date) => { onChange(date); }}
                                        ref={ref}
                                    />
                                )}
                                rules={{ required: 'Required Field' }}
                                name="expiryDate"
                                defaultValue={license.expirationDate ? moment(license.expirationDate) : null}
                            />
                            <FormItem {...formItemLayout} label="Front Image" required
                                as={<UploadFile
                                    beforeUpload={(file) => {
                                        setFrontFile(file);
                                        methods.clearErrors('frontFile');
                                        return false;
                                    }}
                                    onRemove={(file) => {
                                        setFrontFile(null);
                                    }}
                                    message="Please upload a photo of the front of the license."
                                    fileId={license.isExpired === false && license.frontImageId ? license.frontImageId : null}
                                />}
                                rules={{ required: 'Required File' }}
                                name="frontFile"
                            />
                            <FormItem {...formItemLayout} label="Back Image" required
                                as={<UploadFile
                                    beforeUpload={(file) => {
                                        setBackFile(file);
                                        methods.clearErrors('backFile');
                                        return false;
                                    }}
                                    onRemove={(file) => {
                                        setBackFile(null);
                                    }}
                                    message="Please upload a photo of the back of the license."
                                    fileId={license.isExpired === false && license.backImageId ? license.backImageId : null}
                                />}
                                rules={{ required: 'Required File' }}
                                name="backFile"
                            />
                        </Fieldset>
                        {error && <Alert message={`${error}`} type="error" />}
                    </Spin>
                    <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText={actionText} />
                </Form>
            </FormProvider>
        );
    } else {
        return null;
    }
};

export default EditLicense;