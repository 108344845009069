import * as actionTypes from "../actions/actionTypes";
import {
    addRecordFail,
    addRecordStart,
    addRecordSuccess,
    clearAddRecordError,
    clearRecords,
    clearUpdateRecordError,
    fetchRecordsSuccess,
    fetchRecordsFail,
    fetchRecordsStart,
    updateRecordFail,
    updateRecordStart,
    updateRecordSuccess,
    clearUpdateRecordLoading,
    clearAddRecordLoading,
    cancelAddRecord,
    cancelUpdateRecord,
    addRecordToBottom,
    updateRecord,
    removeRecord,
    fetchRecordStart,
    fetchRecordSuccess,
    fetchRecordFail,
    clearRecord,
    updateSingleRecord
} from "../utility";

const initialState = {
    records: [],
    record: null,
    isLoading: false,
    isRecordLoading: false,
    isRecordAddLoading: null,
    isRecordUpdateLoading: null,
    error: null,
    recordError: null,
    addRecordError: null,
    updateRecordError: null,
    searchParams: {},
    pagination: {},
    addRecordId: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_FEATURES_START: return fetchRecordsStart(state);
        case actionTypes.FETCH_FEATURES_SUCCESS: return fetchRecordsSuccess(state, action.payload);
        case actionTypes.FETCH_FEATURES_FAIL: return fetchRecordsFail(state, action.payload);
        case actionTypes.CLEAR_FEATURES: return clearRecords(state);
        case actionTypes.FETCH_FEATURE_START: return fetchRecordStart(state);
        case actionTypes.FETCH_FEATURE_SUCCESS: return fetchRecordSuccess(state, action.payload);
        case actionTypes.FETCH_FEATURE_FAIL: return fetchRecordFail(state, action.payload);
        case actionTypes.CLEAR_FEATURE: return clearRecord(state);
        case actionTypes.ADD_FEATURE: return addRecordToBottom(state, action.payload);
        case actionTypes.ADD_FEATURE_START: return addRecordStart(state);
        case actionTypes.ADD_FEATURE_SUCCESS: return addRecordSuccess(state);
        case actionTypes.ADD_FEATURE_FAIL: return addRecordFail(state, action.payload);
        case actionTypes.ADD_FEATURE_LOADING_CLEAR: return clearAddRecordLoading(state);
        case actionTypes.ADD_FEATURE_ERROR_CLEAR: return clearAddRecordError(state);
        case actionTypes.ADD_FEATURE_CANCEL: return cancelAddRecord(state);
        case actionTypes.UPDATE_FEATURE: return updateRecord(state, action.payload);
        case actionTypes.UPDATE_SINGLE_FEATURE: return updateSingleRecord(state, action.payload);
        case actionTypes.UPDATE_FEATURE_START: return updateRecordStart(state);
        case actionTypes.UPDATE_FEATURE_SUCCESS: return updateRecordSuccess(state);
        case actionTypes.UPDATE_FEATURE_FAIL: return updateRecordFail(state, action.payload);
        case actionTypes.UPDATE_FEATURE_LOADING_CLEAR: return clearUpdateRecordLoading(state);
        case actionTypes.UPDATE_FEATURE_ERROR_CLEAR: return clearUpdateRecordError(state);
        case actionTypes.UPDATE_FEATURE_CANCEL: return cancelUpdateRecord(state);
        case actionTypes.REMOVE_FEATURE: return removeRecord(state, action.payload);
        default:
            return state;
    }
};

export default reducer;