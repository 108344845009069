import React, { useMemo, useState } from 'react';
import { Alert, Spin, Select, Drawer } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import UploadFile from "../../components/UploadFile/UploadFile";
import NewClaim from '../../components/NewClaim/NewClaim';
import Enums from '../../shared/enums';
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import FormItemFile from '../FormItemFile/FormItemFile';
import FormButtons from '../FormButtons/FormButtons';
import { isNotNullOrUndefined, isObjectNotEmpty, isStringEmpty, isStringNotEmpty } from '../../shared/objectUtils';

const { Option } = Select;

const NewDocument = ({ loadId, documentEntityType, documentEntitySubType, documentEntityId, account, cancel }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    //#endregion
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.documents.isRecordAddLoading);
    const error = useSelector(state => state.documents.addRecordError);
    const userEntityType = useSelector(state => state.auth.entityType);

    //#endregion
    //#region enums

    const documentVisibilityOptions = Enums.DocumentVisibilities.selectListOptions();

    //#endregion
    //#region useStates

    const [showClaimDrawer, setShowClaimDrawer] = useState(false);
    const [file, setFile] = useState(null);

    //#endregion
    //#region toggles

    const closeClaimDrawer = () => {
        setShowClaimDrawer(false);
    };

    const openClaimDrawer = () => {
        setShowClaimDrawer(true);
    };

    //#endregion
    //#region watch methods

    const documentType = methods.watch('documentType');

    //#endregion
    //#region onChanges

    const onChangeReviewStatus = (value) => {
        if (isStringNotEmpty(value) && isStringNotEmpty(loadId)) {
            if (value === 'CLEAN' || value === 'APPROVED') {
                setShowClaimDrawer(false);
            } else if (value === 'OSDR') {
                setShowClaimDrawer(true);
            }
        } else {
            setShowClaimDrawer(false);
        }
    };

    //#endregion
    //#region displays

    const getReviewStatuses = (documentType, documentEntitySubType) => {
        let children = [];
        if (isStringNotEmpty(documentType)) {
            if (documentEntitySubType === 'PICK_UP') {
                // only show approved as an option for BOLs if they belong to a pick up stop
                switch (documentType) {
                    case 'BOL':
                        children.push(<Option value="APPROVED" key="APPROVED">Approved</Option>);
                        break;
                    default:
                        children.push(<Option value="APPROVED" key="APPROVED">Approved</Option>);
                }
            } else if (documentEntitySubType === 'DROP_OFF') {
                switch (documentType) {
                    case 'BOL':
                        children.push(<Option value="CLEAN" key="CLEAN">Clean</Option>);
                        children.push(<Option value="OSDR" key="OSDR">Has OSDR</Option>);
                        break;
                    default:
                        children.push(<Option value="APPROVED" key="APPROVED">Approved</Option>);
                }
            } else {
                switch (documentType) {
                    case 'BOL':
                        children.push(<Option value="CLEAN" key="CLEAN">Clean</Option>);
                        children.push(<Option value="OSDR" key="OSDR">Has OSDR</Option>);
                        children.push(<Option value="APPROVED" key="APPROVED">Approved</Option>);
                        break;
                    default:
                        children.push(<Option value="APPROVED" key="APPROVED">Approved</Option>);
                }
            }
        } else {
            children.push(<Option value="APPROVED" key="APPROVED">APPROVED</Option>);
        }

        return children;
    };

    //#endregion
    //#region onSubmit and onCancel

    const onSubmit = (data) => {
        if (isNotNullOrUndefined(file)) {
            data.file = file;
            methods.clearErrors("file");
        } else {
            methods.setError("file", { type: "required", message: "Required" });
            return;
        }

        if (userEntityType === "STAFF") {
            data.isReviewed = true;
        }

        if (isStringEmpty(data.reviewStatus)) {
            data.reviewStatus = "PENDING";
        }

        if (isStringEmpty(data.visibleTo)) {
            data.visibleTo = "ALL";
        }

        data.entityId = documentEntityId;
        data.entityType = documentEntityType;

        let links = [];
        if (documentEntityType === 'STOP' && isStringNotEmpty(loadId)) {
            links.push({
                entityId: documentEntityId,
                entityType: documentEntityType
            });
            links.push({
                entityId: loadId,
                entityType: 'LOAD'
            });
        } else {
            links.push({
                entityId: documentEntityId,
                entityType: documentEntityType
            });
        }
        data.links = links;
        // console.log(file)
        // console.log(data)
        if (isObjectNotEmpty(data)) {
            dispatch(actionCreators.addDocument(data));
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.addDocumentCancel());
        cancel();
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.addDocumentErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    //#endregion

    return (
        <>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                        <FormItemFile {...formItemLayout} label="Document" name="file" required>
                            {documentEntityType === 'DRIVER' ? (
                                <UploadFile
                                    beforeUpload={(file) => {
                                        setFile(file);
                                        methods.clearErrors("file");
                                        return false;
                                    }}
                                    onRemove={(file) => {
                                        setFile(null);
                                    }}
                                    documentEntityType={documentEntityType}
                                    documentTypeInputName={'documentType'}
                                    displayNameInputName={'displayName'}
                                    descriptionInputName={'description'}
                                    expirationDateInputName={'expiryDate'}
                                    message="Please upload a photo of the document."
                                    cancel={onCancel}
                                />
                            ) : (
                                <UploadFile
                                    beforeUpload={(file) => {
                                        setFile(file);
                                        methods.clearErrors("file");
                                        return false;
                                    }}
                                    onRemove={(file) => {
                                        setFile(null);
                                    }}
                                    documentEntityType={documentEntityType}
                                    documentTypeInputName={'documentType'}
                                    displayNameInputName={'displayName'}
                                    descriptionInputName={'description'}
                                    message="Please upload a photo of the document."
                                    cancel={onCancel}
                                    account={account}
                                />
                            )}
                        </FormItemFile>
                        {(userEntityType === "STAFF" || (userEntityType === "CARRIER" && documentEntityType === "DRIVER")) &&
                            <FormItem {...formItemLayout} label="Review Status" required
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please Select a Review Status"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChangeReviewStatus(selected); onChange(selected); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    >
                                        {getReviewStatuses(documentType, documentEntitySubType)}
                                    </Select>
                                )}
                                rules={{ required: 'Required Field' }}
                                name="reviewStatus"
                                disabled={isStringNotEmpty(documentType) ? false : true}
                            />
                        }
                        {userEntityType === "STAFF" &&
                            <FormItem {...formItemLayout} label="Visibility" required
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please select the Visibility"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    >
                                        {documentVisibilityOptions}
                                    </Select>
                                )}
                                rules={{ required: 'Required Field' }}
                                name="visibleTo"
                            />
                        }
                        {error && <Alert message={`${error}`} type="error" />}
                    </Spin>
                    <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Create Document" />
                </Form>
            </FormProvider>
            <Drawer
                title="File Claim"
                width={fullWidth > 720 ? fullWidth / 2 : 360}
                closable={false}
                onClose={closeClaimDrawer}
                visible={showClaimDrawer}
                destroyOnClose={true}
                maskClosable={false}
            >
                <NewClaim cancel={closeClaimDrawer} loadId={loadId} />
            </Drawer>
        </>
    );
};

export default NewDocument;