import axiosAuthenticated from "../../api/axiosAuthenticated";
import { isNotNullOrUndefined } from "../../shared/objectUtils";
import * as DTO from "../dtos/index";
import * as Data from "./index";

const loadCancellationsPath = '/loadCancellations';

//#region Load Cancellations Methods

export const getLoadCancellations = async (searchParams = {}, pagination = {}, accounts = []) => {
    const loadCancellationsRes = await axiosAuthenticated.get(loadCancellationsPath, { params: { ...searchParams } });
    if (loadCancellationsRes && loadCancellationsRes.status === 200) {
        const loadCancellations = loadCancellationsRes.data.data;
        const otherData = loadCancellationsRes.data;

        let cancelledByUserIds = loadCancellations.filter((c) => c.createdBy !== undefined && c.createdBy !== null).map((c) => c.createdBy);
        let uniqueCancelledByUserIds = [...new Set([...cancelledByUserIds])];
        let users = await Data.getUsersByUserIds([...uniqueCancelledByUserIds]);

        let transformedLoadCancellations = loadCancellations.map((loadCancellation) => { return DTO.getLoadCancellationDTO(loadCancellation, accounts, users); });

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;

        return { data: transformedLoadCancellations, searchParams: searchParams, pagination: pagination };
    }

    return { data: [], searchParams: searchParams, pagination: pagination };
};

export const getLoadCancellation = async (id, accounts = []) => {
    const loadCancellationsRes = await axiosAuthenticated.get(loadCancellationsPath + `/${id}`);
    if (loadCancellationsRes && loadCancellationsRes.status === 200) {
        let loadCancellation = loadCancellationsRes.data;

        let users = await Data.getUsersByUserIds([loadCancellation.createdBy]);

        return DTO.getLoadCancellationDTO(loadCancellation, accounts, users);
    }

    return null;
};

export const addLoadCancellation = async (payload, accounts = []) => {
    const loadCancellationsRes = await axiosAuthenticated.post(loadCancellationsPath, { ...payload });
    if (loadCancellationsRes && loadCancellationsRes.status === 201) {
        let newLoadCancellation = loadCancellationsRes.data;

        let users = await Data.getUsersByUserIds([newLoadCancellation.createdBy]);

        return DTO.getLoadCancellationDTO(newLoadCancellation, accounts, users);
    }

    return null;
};

export const updateLoadCancellation = async (id, payload, accounts = []) => {
    const loadCancellationsRes = await axiosAuthenticated.put(loadCancellationsPath + `/${id}`, { ...payload });
    if (loadCancellationsRes && loadCancellationsRes.status === 200) {
        let updatedLoadCancellation = loadCancellationsRes.data;

        let users = await Data.getUsersByUserIds([updatedLoadCancellation.createdBy]);

        return DTO.getLoadCancellationDTO(updatedLoadCancellation, accounts, users);
    }

    return null;
};

export const removeLoadCancellation = async (id) => {
    const loadCancellationsRes = await axiosAuthenticated.put(loadCancellationsPath + `/${id}`, { isDeleted: true });
    if (loadCancellationsRes && loadCancellationsRes.status === 200) {
        return loadCancellationsRes.data;
    }

    return null;
};

//#endregion