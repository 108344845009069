import { orderBy } from "lodash";
import { isListEmpty, isListNotEmpty, isNotNullOrUndefined, isNumberNotEmpty, isObjectNotEmpty, isStringEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import StringFormatter from '../../shared/stringFormatter';
import Pricing from '../../shared/pricing';
import Enums from "../../shared/enums";

const stringFormatter = new StringFormatter();

const getLoadStatusDisplay = (status, entityType, load) => {
    if (isObjectNotEmpty(load)) {
        if (entityType === 'STAFF' || entityType === 'BROKER') {
            return stringFormatter.toFormattedString("LoadDisplayStatus", status, null);
        } else if (entityType === 'SHIPPER') {
            return stringFormatter.toFormattedString("ShipperLoadDisplayStatus", status, null);
        } else if (entityType === 'CARRIER' || entityType === 'DRIVER') {
            return stringFormatter.toFormattedString("CarrierLoadDisplayStatus", status, null);
        }
    }

    return '';
};

const getLoadStatus = (load, stops, loadId, entityType = "STAFF") => {
    if (isObjectNotEmpty(load) && isStringNotEmpty(load.id) && isStringNotEmpty(loadId) && load.id === loadId && isStringNotEmpty(entityType) && isListNotEmpty(stops)) {
        const loadStops = stops.filter(s => s.loadId === loadId && s.isDeleted === false);
        if (isListNotEmpty(loadStops)) {
            let isDeadHeading = false;
            let currentStop = null;
            let currentStopType = "STOP";
            let currentStopStatus = "PENDING";
            let currentStatus = "PENDING";

            const orderedLoadStops = orderBy(loadStops, ['sequence'], ['asc']);
            let firstPickUpStopObj = orderedLoadStops.find(stop => stop.stopType === 'PICK_UP');
            let currentStopObj = orderedLoadStops.find(stop => stop.stopStatus !== 'COMPLETED');

            if (isObjectNotEmpty(currentStopObj)) {
                currentStop = currentStopObj;
                currentStopType = currentStopObj.stopType;
                currentStopStatus = currentStopObj.stopStatus;
            }

            if (isObjectNotEmpty(firstPickUpStopObj)) {
                if (firstPickUpStopObj.stopStatus !== 'COMPLETED') {
                    isDeadHeading = true;
                }
            }

            switch (load.loadStatus) {
                case 'CREATED':
                    currentStatus = "PENDING";
                    break;
                case 'PENDING':
                    currentStatus = "PENDING";
                    break;
                case 'APPROVED':
                    currentStatus = "APPROVED";
                    break;
                case 'PENDING_RATE_CONFIRMATION':
                    currentStatus = "PENDING_RATE_CONFIRMATION";
                    break;
                case 'BOOKED':
                    currentStatus = "BOOKED";
                    break;
                case 'SCHEDULED':
                    currentStatus = "SCHEDULED";
                    break;
                case 'IN_TRANSIT':
                    if (isObjectNotEmpty(currentStop)) {
                        if (isDeadHeading === true && currentStopStatus === 'PENDING') {
                            currentStatus = "DEAD_HEADING";
                        } else if (currentStopStatus === 'ARRIVING') {
                            currentStatus = "ARRIVING";
                        } else if (currentStopStatus === 'LAYOVER') {
                            currentStatus = "LAYOVER";
                        } else if (currentStopStatus !== 'PENDING' && currentStopStatus !== 'COMPLETED') {
                            currentStatus = currentStopStatus; // should never happen based on state machine
                        } else {
                            currentStatus = "IN_TRANSIT";
                        }
                    } else {
                        currentStatus = "DROP_OFF_COMPLETED";
                    }
                    break;
                case 'AT_STOP':
                    if (currentStopType === 'PICK_UP' || currentStopType === 'DROP_OFF') {
                        if (currentStopStatus === 'PENDING') {
                            currentStatus = "IN_TRANSIT"; // should never happen based on state machine
                        } else if (currentStopStatus === 'LAYOVER') {
                            currentStatus = "LAYOVER";
                        } else {
                            currentStatus = currentStopStatus;
                        }
                    } else {
                        currentStatus = "AT_STOP";
                    }
                    break;
                case 'COMPLETED':
                    currentStatus = "COMPLETED";
                    break;
                case 'CLOSED':
                    currentStatus = "CLOSED";
                    break;
                case 'CANCELLED':
                    currentStatus = "CANCELLED";
                    break;
                case 'REOPENED':
                    currentStatus = "REOPENED";
                    break;
                default:
                    currentStatus = "PENDING";
            }

            return {
                isDeadHeading: isDeadHeading,
                currentStop: currentStop,
                currentStopType: currentStopType,
                currentStopStatus: currentStopStatus,
                currentStatus: currentStatus,
                displayStatus: getLoadStatusDisplay(currentStatus, entityType, load)
            };
        }
    }

    return null;
};

const getLoadName = (load) => {
    if (isObjectNotEmpty(load)) {
        if (isStringNotEmpty(load.parentName)) {
            return load.parentName;
        } else if (isStringNotEmpty(load.name)) {
            return load.name;
        } else {
            return load.irisId;
        }
    } else {
        return "";
    }
};

const getChildLoadName = (load, loadIndex) => {
    let loadName = "";
    if (isObjectNotEmpty(load)) {
        loadName = isStringNotEmpty(load.parentName) ? load.parentName : (isStringNotEmpty(load.name) ? load.name + ' (' + (loadIndex + 1) + ')' : load.irisId);
    }

    return loadName;
};

const loadStatusIsNot = (loadStatus, isNotList = []) => {
    let isNot = true;
    if (isNotList.includes(loadStatus)) {
        isNot = false;
    }

    return isNot;
};

const loadStatusIs = (loadStatus, isList = []) => {
    let is = false;
    if (isList.includes(loadStatus)) {
        is = true;
    }

    return is;
};

const getShipperId = (load) => {
    if (isObjectNotEmpty(load) && isStringNotEmpty(load.shipperId)) {
        return load.shipperId;
    }

    return null;
};

const getCarrierId = (load) => {
    if (isObjectNotEmpty(load) && isStringNotEmpty(load.assignedCarrierId)) {
        return load.assignedCarrierId;
    }

    return null;
};

const getServiceType = (load) => {
    if (isObjectNotEmpty(load) && isStringNotEmpty(load.serviceType)) {
        return load.serviceType;
    }

    return null;
};

const getTrailerTypeName = (load) => {
    if (isObjectNotEmpty(load) && isObjectNotEmpty(load.equipmentNeeds) && isObjectNotEmpty(load.equipmentNeeds.trailerType) && isStringNotEmpty(load.equipmentNeeds.trailerType.description)) {
        return load.equipmentNeeds.trailerType.description;
    } else {
        return '';
    }
};

const getTrailerLength = (load) => {
    if (isObjectNotEmpty(load) && isObjectNotEmpty(load.equipmentNeeds) && isStringNotEmpty(load.equipmentNeeds.trailerLength)) {
        return stringFormatter.toFormattedString("Length", load.equipmentNeeds.trailerLength, load.equipmentNeeds.trailerLengthUnit);
    } else {
        return '';
    }
};

const getTripDistance = (load) => {
    if (isObjectNotEmpty(load) && isNumberNotEmpty(load.practicalDistance)) {
        return stringFormatter.toFormattedString("Distance", load.practicalDistance, load.practicalDistanceUnit);
    } else {
        return '';
    }
};

const getTripRate = (loadId, invoiceLineItems, entityType) => {
    if (isStringNotEmpty(loadId) && isStringNotEmpty(entityType)) {
        const loadInvoiceLineItems = invoiceLineItems.filter(i => i.loadId !== undefined && i.loadId !== null && i.loadId === loadId);
        const pricingSummary = Pricing.loadPricingSummary(loadInvoiceLineItems, []);
        if (isObjectNotEmpty(pricingSummary)) {
            if ((entityType === "STAFF" || entityType === "SHIPPER") && isNumberNotEmpty(pricingSummary.shipperAmountWithAddOns)) {
                return stringFormatter.toFormattedMoney(Math.abs(pricingSummary.shipperAmountWithAddOns));
            } else if (entityType === "CARRIER" && isNumberNotEmpty(pricingSummary.carrierAmountWithAddOns)) {
                return stringFormatter.toFormattedMoney(Math.abs(pricingSummary.carrierAmountWithAddOns));
            }
        }
    }

    return '';
};

const getTemperatureRangeDisplay = (load) => {
    if (isObjectNotEmpty(load) && isObjectNotEmpty(load.equipmentNeeds) && isStringNotEmpty(load.equipmentNeeds.minRunTemp) && isStringNotEmpty(load.equipmentNeeds.maxRunTemp)) {
        return `${stringFormatter.toFormattedString('Temperature', load.equipmentNeeds.minRunTemp, load.equipmentNeeds.minRunTempUnit)} - ${stringFormatter.toFormattedString('Temperature', load.equipmentNeeds.maxRunTemp, load.equipmentNeeds.maxRunTempUnit)}`;
    }

    return null;
};

const getServiceTypeDisplay = (load, entityType) => {
    if (isObjectNotEmpty(load) && isStringNotEmpty(load.serviceType) && isStringNotEmpty(entityType) && entityType === "STAFF") {
        return stringFormatter.toFormattedString("LoadServiceType", load.serviceType);
    }

    return null;
};

const getLoadETA = (load, entityType) => {
    if (isObjectNotEmpty(load) && isNotNullOrUndefined(load.eta) && (entityType === "STAFF" || loadStatusIs(load.loadStatus, ['IN_TRANSIT', 'AT_STOP']))) {
        return stringFormatter.toFormattedString('MomentDateTime', load.eta, null, load.etaTimeZone);
    } else {
        return 'N/A';
    }
};

const getLoadETADisplay = (load, entityType) => {
    if (isObjectNotEmpty(load) && isNotNullOrUndefined(load.eta) && (entityType === "STAFF" || loadStatusIs(load.loadStatus, ['IN_TRANSIT', 'AT_STOP']))) {
        return `ETA: ${stringFormatter.toFormattedString('MomentDateTime', load.eta, null, load.etaTimeZone)}`;
    } else {
        return 'ETA: Will become available once the load starts.'
    }
};

const getShipperName = (load) => {
    if (isObjectNotEmpty(load) && isObjectNotEmpty(load.shipper) && isStringNotEmpty(load.shipper.name)) {
        return load.shipper.name;
    }

    return null;
};

const getCarrierName = (load) => {
    if (isObjectNotEmpty(load) && isObjectNotEmpty(load.assignedCarrier) && isStringNotEmpty(load.assignedCarrier.name)) {
        return load.assignedCarrier.name;
    }

    return null;
};

const hasTeamDriving = (load) => {
    if (isObjectNotEmpty(load) && isListNotEmpty(load.driverRequirements) && load.driverRequirements.includes('TEAM_DRIVING')) {
        return true;
    }

    return false;
};

const hasCarrierWashout = (load) => {
    if (isObjectNotEmpty(load) && isListNotEmpty(load.equipmentSpecifics) && load.equipmentSpecifics.includes('CARRIER_WASHOUT')) {
        return true;
    }

    return false;
};

const isTMSLoad = (load) => {
    if (isObjectNotEmpty(load) && isStringNotEmpty(load.serviceType)) {
        return load.serviceType === 'TMS';
    } else {
        return null;
    }
};

const isLoadOwner = (load, entityType, entityId) => {
    if (entityType === 'STAFF') {
        return true;
    } else if (isStringNotEmpty(load.shipperId) && entityType === "SHIPPER" && load.shipperId === entityId && load.createdByEntityType === 'SHIPPER') {
        return true;
    } else if (isStringNotEmpty(load.assignedCarrierId) && entityType === "CARRIER" && load.assignedCarrierId === entityId && load.createdByEntityType === 'CARRIER') {
        return true;
    } else if (isStringNotEmpty(load.brokerId) && entityType === "BROKER" && load.brokerId === entityId && load.createdByEntityType === 'BROKER') {
        return true;
    } else {
        return false;
    }
};

const isBrokerageLoad = (load) => {
    if (isObjectNotEmpty(load) && isStringNotEmpty(load.serviceType)) {
        return load.serviceType === 'BROKERAGE';
    } else {
        return null;
    }
};

const hasAccessToLoad = (shipperId = null, carrierId = null, entityType, entityId) => {
    if (entityType === 'STAFF') {
        return true;
    } else if (isStringNotEmpty(shipperId) && entityType === "SHIPPER" && shipperId === entityId) {
        return true;
    } else if (isStringNotEmpty(carrierId) && entityType === "CARRIER" && carrierId === entityId) {
        return true;
    } else {
        return false;
    }
};

const hasAccessToPriceConfirmation = (shipperId = null, entityType, entityId) => {
    if (entityType === 'STAFF') {
        return true;
    } else if (isStringNotEmpty(shipperId) && entityType === "SHIPPER" && shipperId === entityId) {
        return true;
    } else {
        return false;
    }
};

const hasAccessToRateConfirmation = (carrierId = null, entityType, entityId) => {
    if (entityType === 'STAFF') {
        return true;
    } else if (isStringNotEmpty(carrierId) && entityType === "CARRIER" && carrierId === entityId) {
        return true;
    } else {
        return false;
    }
};

const getMissingPedigreeInformation = (load, stops) => {
    let missingPedigreeInformationList = [];
    if (isObjectNotEmpty(load) && isListNotEmpty(stops)) {

        if (isStringEmpty(load.customerOrderNumber)) {
            missingPedigreeInformationList.push({ entity: load, entityType: 'LOAD', entityId: load.id, entityName: getLoadName(load), missingField: 'customerOrderNumber', missingFieldName: 'Customer Order Number' });
        }

        let orderedStops = orderBy(stops, ['sequence'], ['asc']);
        orderedStops.filter(s => s.stopType === 'PICK_UP').forEach((stop, stopIndex, pickUpStops) => {
            let missingFields = [];

            if (isListEmpty(stop.bolNumbers)) {
                missingFields.push({ missingField: 'bolNumbers', missingFieldName: 'BOL Number(s)' });
            }

            if (stop.apptType === 'HAVE_APPOINTMENT' && isStringEmpty(stop.apptNumber)) {
                missingFields.push({ missingField: 'apptNumber', missingFieldName: 'Appointment Number' });
            }

            if (isStringEmpty(stop.pickUpNumber)) {
                missingFields.push({ missingField: 'pickUpNumber', missingFieldName: 'Pick-Up Number' });
            }

            if (isListNotEmpty(missingFields)) {
                missingPedigreeInformationList.push({ entity: stop, entityType: 'STOP', entityId: stop.id, entityName: `${Enums.StopTypes.getValueByName(stop.stopType)}${pickUpStops.length > 1 ? ' ' + (stopIndex + 1) : ''}`, missingFields: missingFields });
            }
        });

        orderedStops.filter(s => s.stopType === 'DROP_OFF').forEach((stop, stopIndex, dropOffStops) => {
            let missingFields = [];

            if (isListEmpty(stop.bolNumbers)) {
                missingFields.push({ missingField: 'bolNumbers', missingFieldName: 'BOL Number(s)' });
            }

            if (stop.apptType === 'HAVE_APPOINTMENT' && isStringEmpty(stop.apptNumber)) {
                missingFields.push({ missingField: 'apptNumber', missingFieldName: 'Appointment Number' });
            }

            if (isStringEmpty(stop.dropOffNumber)) {
                missingFields.push({ missingField: 'dropOffNumber', missingFieldName: 'Drop-Off Number' });
            }

            if (isStringEmpty(stop.purchaseOrderNumber)) {
                missingFields.push({ missingField: 'purchaseOrderNumber', missingFieldName: 'Customer Purchase Order Number' });
            }

            if (isListNotEmpty(missingFields)) {
                missingPedigreeInformationList.push({ entity: stop, entityType: 'STOP', entityId: stop.id, entityName: `${Enums.StopTypes.getValueByName(stop.stopType)}${dropOffStops.length > 1 ? ' ' + (stopIndex + 1) : ''}`, missingFields: missingFields });
            }
        });
    }

    return missingPedigreeInformationList;
};

export default {
    getLoadStatusDisplay,
    getLoadStatus,
    getCarrierId,
    getCarrierName,
    getChildLoadName,
    getLoadName,
    getServiceType,
    getServiceTypeDisplay,
    getShipperId,
    getShipperName,
    getTemperatureRangeDisplay,
    getTrailerLength,
    getTrailerTypeName,
    getTripDistance,
    getTripRate,
    getLoadETA,
    getLoadETADisplay,
    hasAccessToLoad,
    hasAccessToPriceConfirmation,
    hasAccessToRateConfirmation,
    hasCarrierWashout,
    hasTeamDriving,
    isBrokerageLoad,
    isLoadOwner,
    isTMSLoad,
    loadStatusIs,
    loadStatusIsNot,
    getMissingPedigreeInformation
};