import Checks from '../entitlementUtils';

export const canCreateLinkedLoadLane = (auth, accountId) => {
    return Checks.allowStaff(auth) || (Checks.isAccountOperationsAdmin(auth, accountId) && Checks.canView(auth, ['CARRIER']));
};

export const canReadLinkedLoadLane = (auth, accountId) => {
    return Checks.allowStaff(auth) || (Checks.isAccountOperationsAdmin(auth, accountId) && Checks.canView(auth, ['CARRIER']));
};

export const canReadLinkedLoadLaneList = (auth, accountId) => {
    return Checks.allowStaff(auth) || (Checks.isAccountOperationsAdmin(auth, accountId) && Checks.canView(auth, ['CARRIER']));
};

export const canUpdateLinkedLoadLane = (auth, accountId) => {
    return Checks.allowStaff(auth) || (Checks.isAccountOperationsAdmin(auth, accountId) && Checks.canView(auth, ['CARRIER']));
};

export const canDeleteLinkedLoadLane = (auth) => {
    return Checks.allowStaffAdmin(auth);
};