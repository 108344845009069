import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { Spin, Tabs, Row, Col, Card, Button, Typography, Empty, Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentsDollar, faPallet, faRoute, faTruck, faTruckLoading } from '@fortawesome/free-solid-svg-icons';
import Header from '../../components/Header/Header';
import { useLocation } from 'react-router';
import ComplexDataLinkCard from '../../components/ComplexDataLinkCard/ComplexDataLinkCard';
import ComplexCard from '../../components/ComplexCard/ComplexCard';
import DataAddressRow from '../../components/DataAddressRow/DataAddressRow';
import DataRow from '../../components/DataRow/DataRow';
import DataTemperatureRangeRow from '../../components/DataTemperatureRangeRow/DataTemperatureRangeRow';
import DataListRow from '../../components/DataListRow/DataListRow';
import DataCommodityListRow from '../../components/DataCommodityListRow/DataCommodityListRow';
import { isListNotEmpty, isNumberNotEmpty, isObjectNotEmpty, isStringNotEmpty, isNotNullOrUndefined } from '../../shared/objectUtils';
import { CanDo } from '../../shared/entitlements/entitlements';
import TitleBlockWithIcon from '../../components/TitleBlockWithIcon/TitleBlockWithIcon';
import LaneUtils from '../../api/utils/laneUtils';
import classes from './LoadLane.module.scss';
import Enums from '../../shared/enums';
import StringFormatter from '../../shared/stringFormatter';
import LoadMap from '../../components/LoadMap/LoadMap';
import { compareByAlph } from '../../shared/tableUtils';
import DataTable from '../../components/DataTable/DataTable';
import { selectListIsLoading, selectListRecords } from '../../store/utility';
import InTransitLoads from '../InTransitLoads/InTransitLoads';
import LinkedCarriers from '../LinkedCarriers/LinkedCarriers';
import LinkedAccounts from '../LinkedAccounts/LinkedAccounts';
import LoadStopUtils from '../../api/utils/loadStopUtils';
import NewLoadAndRFB from '../../components/NewLoadAndRFB/NewLoadAndRFB';
import QueueLoads from '../../components/QueueLoads/QueueLoads';

const { TabPane } = Tabs;
const stringFormatter = new StringFormatter();

const LoadLane = (props) => {
    //#region props

    const id = props.match.params.id;
    const previousBreadcrumbs = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isListNotEmpty(props.history.location.state.previousBreadcrumbs) ? props.history.location.state.previousBreadcrumbs : [];
    const previousLocation = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageLocation) ? props.history.location.state.previousPageLocation : { pathname: `/loadLanes` };
    const previousPath = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageLocation) ? props.history.location.state.previousPageLocation : `/loadLanes`;
    const previousBreadcrumbName = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageTitle) ? props.history.location.state.previousPageTitle : 'Lanes';

    //#endregion
    //#region useLocation

    const location = useLocation();

    //#endregion
    //#region useStates

    const [tabKey, setTabKey] = useState('snapshot');
    const [selectedLoadId, setSelectedLoadId] = useState(null);
    const [selectedRequestForBidId, setSelectedRequestForBidId] = useState(null);
    const [loadsWithBids, setLoadsWithBids] = useState(null);
    const [showNewRequestForBids, setShowNewRequestForBids] = useState(false);
    const [showNewLoad, setShowNewLoad] = useState(false);

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const entityType = useSelector(state => state.auth.entityType);
    const entityId = useSelector(state => state.auth.entityId);
    const isLoading = useSelector(state => state.loadLanes.isRecordLoading);
    const error = useSelector(state => state.loadLanes.recordError);
    const loadLane = useSelector(state => state.loadLanes.record);
    const openLoads = useSelector(state => selectListRecords(state.loads.lists, `OPEN_${id}`));
    const isLoadingOpenLoads = useSelector(state => selectListIsLoading(state.loads.lists, `OPEN_${id}`));
    const inTransitLoads = useSelector(state => selectListRecords(state.loads.lists, `INTRANSIT_${id}`));
    const isLoadingInTransitLoads = useSelector(state => selectListIsLoading(state.loads.lists, `INTRANSIT_${id}`));
    const requestForBids = useSelector(state => selectListRecords(state.requestForBids.lists, isObjectNotEmpty(loadLane) && isStringNotEmpty(loadLane.accountId) ? loadLane.accountId : null));
    const isLoadingRequestForBids = useSelector(state => selectListIsLoading(state.requestForBids.lists, isObjectNotEmpty(loadLane) && isStringNotEmpty(loadLane.accountId) ? loadLane.accountId : null));

    //#endregion
    //#region toggles

    const toggleNewRequestForBids = () => {
        setShowNewRequestForBids(!showNewRequestForBids);
    };

    const toggleNewLoad = () => {
        setShowNewLoad(!showNewLoad);
    };

    //#endregion
    //#region tab methods

    const onTabChange = (key) => {
        props.history.replace({ ...props.location, hash: `#${key}` });
    };

    //#endregion
    //#region load methods

    const getOpenLoads = (id) => {
        if (isStringNotEmpty(id)) {
            let searchParams = {
                page: 1,
                size: 1000000,
                sort: 'pickUpDateTime',
                order: 'asc',
                isDeleted: false,
                eta: false,
                loadLaneId: id
            };

            let defaultLoadStatusFilter = ['PENDING', 'APPROVED', 'PENDING_RATE_CONFIRMATION'];
            searchParams.loadStatus = defaultLoadStatusFilter.join('|');

            dispatch(actionCreators.fetchLoadList(`OPEN_${id}`, searchParams));
        }
    };

    const getInTransitLoads = (id) => {
        if (isStringNotEmpty(id)) {
            let searchParams = {
                page: 1,
                size: 1000000,
                sort: 'pickUpDateTime',
                order: 'asc',
                isDeleted: false,
                eta: false,
                loadLaneId: id
            };

            let defaultLoadStatusFilter = ['IN_TRANSIT', 'AT_STOP'];
            searchParams.loadStatus = defaultLoadStatusFilter.join('|');

            dispatch(actionCreators.fetchLoadList(`INTRANSIT_${id}`, searchParams));
        }
    };

    //#endregion
    //#region request for bid methods

    const getRequestsForBids = (loadIds = [], accountId) => {
        if (isStringNotEmpty(accountId) && isListNotEmpty(loadIds)) {
            dispatch(actionCreators.fetchBulkRequestForBidLists(loadIds, accountId));
        }
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        if (isStringNotEmpty(location.hash)) {
            setTabKey(location.hash.replace('#', ''));
        } else {
            setTabKey('snapshot');
            props.history.replace({ ...location, hash: `#snapshot` });
        }
    }, [location]);

    useMemo(() => {
        if (isStringNotEmpty(id)) {
            dispatch(actionCreators.fetchLoadLane(id, entityId));
            getOpenLoads(id);
            getInTransitLoads(id);
        }
    }, [id, entityId]);

    useMemo(() => {
        if (isListNotEmpty(openLoads) && isObjectNotEmpty(loadLane) && isStringNotEmpty(loadLane.accountId)) {
            getRequestsForBids(openLoads.map(l => l.id), loadLane.accountId);
        }
    }, [openLoads, loadLane]);

    useMemo(() => {
        if (isListNotEmpty(openLoads) && isObjectNotEmpty(loadLane)) {
            const loadsWithBidsList = openLoads.map((load) => {
                return {
                    ...load,
                    bids: requestForBids.filter(r => r.loadId === load.id && r.fromEntityId === loadLane.accountId && r.offer !== undefined && r.offer !== null),
                    requests: requestForBids.filter(r => r.loadId === load.id && r.fromEntityId === loadLane.accountId && (r.offer === undefined || r.offer === null)),
                };
            });
            setLoadsWithBids(loadsWithBidsList);
        } else {
            setLoadsWithBids([]);
        }
    }, [openLoads, requestForBids, loadLane]);

    //#endregion
    //#region table displays

    const onClickRow = (record) => {
        return {
            onClick: () => {
                setSelectedLoadId(record.id);
            },
        };
    };

    const getLoadRowClassName = (record) => {
        if (isStringNotEmpty(selectedLoadId) && selectedLoadId === record.id) {
            return classes.readyToDispatchRow;
        } else {
            return classes.dataTableRow;
        }
    };

    const getRequestForBidRowClassName = (record) => {
        if (isStringNotEmpty(selectedRequestForBidId) && selectedRequestForBidId === record.id) {
            return classes.readyToDispatchRow;
        } else {
            return classes.dataTableRow;
        }
    };

    const rowSelectionLoad = {
        hideSelectAll: false,
        columnTitle: 'Select',
        columnWidth: 50,
        type: 'radio',
        selectedRowKeys: isStringNotEmpty(selectedLoadId) ? [selectedLoadId] : [],
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            console.log('selectedRowKeys changed: ', selectedRowKeys);
            if (isListNotEmpty(selectedRowKeys)) {
                setSelectedLoadId(selectedRowKeys[0]);
                console.log(openLoads.filter(l => l.id === selectedRowKeys[0]));
            } else {
                setSelectedLoadId(null);
            }
        },
        renderCell: (checked, record, index, originNode) => {
            // console.log(originNode);
            return {
                props: {
                    className: getLoadRowClassName(record)
                },
                children: originNode
            };
        },
        getCheckboxProps: record => ({
            name: record.id,
        }),
    };

    const rowSelectionRequestForBids = {
        hideSelectAll: false,
        columnTitle: 'Select',
        columnWidth: 50,
        type: 'radio',
        selectedRowKeys: isStringNotEmpty(selectedRequestForBidId) ? [selectedRequestForBidId] : [],
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            console.log('selectedRowKeys changed: ', selectedRowKeys);
            if (isListNotEmpty(selectedRowKeys)) {
                setSelectedRequestForBidId(selectedRowKeys[0]);
            } else {
                setSelectedRequestForBidId(null);
            }
        },
        renderCell: (checked, record, index, originNode) => {
            // console.log(originNode);
            return {
                props: {
                    className: getRequestForBidRowClassName(record)
                },
                children: originNode
            };
        },
        getCheckboxProps: record => ({
            name: record.id,
        }),
    };

    const loadColumns = [
        {
            title: 'Load ID',
            dataIndex: 'irisId',
            key: 'irisId',
            align: 'center',
            render: (text, record) => {
                return {
                    props: {
                        className: [getLoadRowClassName(record), classes.cell]
                    },
                    children: text,
                };
            },
        },
        {
            title: 'Pick-Up',
            dataIndex: 'pickUpDateTime',
            key: 'pickUpDateTime',
            sorter: (a, b) => compareByAlph(a.pickUpDateTime, b.pickUpDateTime),
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'ascend',
            align: 'center',
            render: (text, record) => {
                return {
                    props: {
                        className: [getLoadRowClassName(record), classes.cell]
                    },
                    children: isObjectNotEmpty(record.origin) ? stringFormatter.toFormattedString("MomentDate", text, null, record.origin.timeZone) : '',
                };
            },
        },
        {
            title: 'Drop-Off',
            dataIndex: ['destination', 'requestedDateTime'],
            key: 'destination.requestedDateTime',
            align: 'center',
            render: (text, record) => {
                return {
                    props: {
                        className: [getLoadRowClassName(record), classes.cell]
                    },
                    children: isObjectNotEmpty(record.destination) ? stringFormatter.toFormattedString("MomentDate", text, null, record.destination.timeZone) : '',
                };
            },
        },
        {
            title: 'Requests',
            dataIndex: 'requests',
            key: 'requests',
            align: 'center',
            render: (text, record) => {
                return {
                    props: {
                        className: [getLoadRowClassName(record), classes.cell]
                    },
                    children: <Button type="link" onClick={(e) => { e.stopPropagation(); e.preventDefault(); setSelectedLoadId(record.id); }} style={{ textDecoration: 'underline' }}>{isListNotEmpty(text) ? text.length : 0}</Button>,
                };
            },
        },
        {
            title: 'Bids',
            dataIndex: 'bids',
            key: 'bids',
            align: 'center',
            render: (text, record) => {
                return {
                    props: {
                        className: [getLoadRowClassName(record), classes.cell]
                    },
                    children: <Button type="link" onClick={(e) => { e.stopPropagation(); e.preventDefault(); setSelectedLoadId(record.id); }} style={{ textDecoration: 'underline' }}>{isListNotEmpty(text) ? text.length : 0}</Button>,
                };
            },
        },
    ];

    const requestForBidColumns = [
        {
            title: 'Load ID',
            dataIndex: 'irisId',
            key: 'irisId',
            align: 'center',
            render: (text, record) => {
                return {
                    props: {
                        className: [getRequestForBidRowClassName(record), classes.cell]
                    },
                    children: text,
                };
            },
        },
        {
            title: 'Carrier',
            dataIndex: ['toEntity', 'name'],
            key: 'toEntity.name',
            align: 'center',
            render: (text, record) => {
                return {
                    props: {
                        className: [getRequestForBidRowClassName(record), classes.cell]
                    },
                    children: text,
                };
            },
        },
        {
            title: 'All-In Rate',
            dataIndex: 'offer',
            key: 'offer',
            align: 'center',
            render: (text, record) => {
                return {
                    props: {
                        className: [getRequestForBidRowClassName(record), classes.cell]
                    },
                    children: stringFormatter.toFormattedString("Money", text, record.offerUnit, null),
                };
            },
        },
        {
            title: 'Award',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            render: (text, record) => {
                return {
                    props: {
                        className: [getRequestForBidRowClassName(record), classes.cell]
                    },
                    children: text,
                };
            },
        },
    ];

    //#endregion
    //#region displays

    let breadcrumbs = [...previousBreadcrumbs,
    {
        location: previousLocation,
        path: previousPath,
        breadcrumbName: previousBreadcrumbName,
    },
    {
        path: `/loadLanes/${isStringNotEmpty(id) ? id : ''}`,
        breadcrumbName: `${isObjectNotEmpty(loadLane) && isStringNotEmpty(loadLane.customerLaneId) ? loadLane.customerLaneId : (isObjectNotEmpty(loadLane) && isStringNotEmpty(loadLane.irisId) ? loadLane.irisId : '')}`,
    },
    ];

    const renderStop = (location, loadLane, index) => {
        if (isObjectNotEmpty(location) && isObjectNotEmpty(loadLane)) {
            const stopAddress = LaneUtils.getLaneStopAddressDisplay(location);
            const stopIcon = LaneUtils.getLaneStopMarkerIcon(location);
            const stopLocation = LaneUtils.getLaneStopLocationDisplay(location, loadLane.accountId, entityId, entityType, entityId);

            return (
                <div style={{ marginBottom: 24 }} key={`stop-details-${index}`}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ width: 30, marginRight: 12 }}>
                            {stopIcon && (<img src={stopIcon} alt={Enums.StopTypes.getValueByName(location.stopType)} style={{ width: 30, height: 30 }} />)}
                        </div>
                        <div style={{ flex: 1 }}>
                            <div style={{ fontWeight: 'bold' }}>{stopLocation}</div>
                            <div>{stopAddress}</div>
                            {isStringNotEmpty(location.loadingType) ? (<DataRow title={location.stopType === 'PICK_UP' ? 'Loading Type' : 'Unloading Type'} value={location.loadingType} dataType={location.stopType === 'PICK_UP' ? 'LoadingType' : 'UnloadingType'} />) : null}
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };

    const renderStops = (loadLane) => {
        if (isObjectNotEmpty(loadLane) && isListNotEmpty(loadLane.locations)) {
            return (
                <div>
                    <div style={{ height: 250, overflowY: 'auto' }}>
                        {loadLane.locations.map((location, index) => { return renderStop(location, loadLane, index); })}
                    </div>
                    <DataRow style={{ marginTop: 24 }} title="Total Stops" valueStyle={{ fontWeight: 'bold' }} value={loadLane.locations.length} />
                </div>
            );
        } else {
            return null;
        }
    };

    const requestForBidsModal = (
        <Modal
            title={null}
            visible={showNewRequestForBids === true}
            width="100%"
            bodyStyle={{ padding: 0, backgroundColor: 'transparent' }}
            style={{ top: 24, backgroundColor: 'transparent' }}
            onCancel={toggleNewRequestForBids}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
            closable={false}
            className="load-lane"
        >
            <NewLoadAndRFB
                shipperId={isObjectNotEmpty(loadLane) ? loadLane.accountId : null}
                loadLaneId={isObjectNotEmpty(loadLane) ? loadLane.id : null}
                cancel={toggleNewRequestForBids}
            />
        </Modal>
    );

    //#endregion

    if (isObjectNotEmpty(loadLane) && loadLane.id === id) {
        let shipperId = LaneUtils.getShipperId(loadLane);
        let shipperName = LaneUtils.getShipperName(loadLane);
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                <Header
                    title={isStringNotEmpty(loadLane.irisId) ? loadLane.irisId : 'Loading...'}
                    subtitle={isStringNotEmpty(loadLane.customerLaneId) ? 'Customer Lane ID: ' + loadLane.customerLaneId : null}
                    icon={<FontAwesomeIcon className="anticon" icon={faRoute} />}
                    title2={isStringNotEmpty(shipperName) ? (
                        <Button type="link" style={{ paddingLeft: 0 }} onClick={(e) => {
                            e.stopPropagation();
                            if (entityType === 'STAFF') {
                                window.open(`/accounts/${shipperId}`, "_blank");
                            } else if (entityType === 'SHIPPER') {
                                window.open(`/dashboard`, "_blank");
                            } else if (entityType === 'CARRIER' || entityType === 'BROKER') {
                                window.open(`/linkedAccounts/${entityId}/${shipperId}`, "_blank");
                            }
                        }}>{shipperName}</Button>) : 'TBD'
                    }
                    title2Icon={<FontAwesomeIcon className="anticon" icon={faTruckLoading} />}
                    breadcrumbs={breadcrumbs}
                    highlights={[
                        {
                            title: 'Active Loads',
                            value: inTransitLoads.length
                        },
                        {
                            title: 'Loads to Cover',
                            value: openLoads.length
                        },
                        {
                            title: 'Best All-In Rate',
                            value: 'N/A'
                        },
                    ]}
                />
                <Tabs activeKey={tabKey} onChange={onTabChange} destroyInactiveTabPane={true} tabBarStyle={{ backgroundColor: '#ffffff', paddingRight: 24, paddingLeft: 24, margin: 0 }}>
                    <TabPane tab="Snapshot" key="snapshot">
                        <div style={{ padding: 24 }}>
                            <Row gutter={[24, 24]}>
                                <Col md={12} sm={24}>
                                    <Card
                                        title="Lane Summary"
                                        style={{ minHeight: 400, border: '1px solid #b7eb8f' }}
                                        headStyle={{ backgroundColor: '#f6ffed', borderBottom: '1px solid #b7eb8f', fontSize: 14 }}
                                        extra={<Button type="primary" onClick={() => { toggleNewLoad(); }}>New Load +</Button>}
                                    >
                                        <Row>
                                            <Col md={12} sm={24} style={{ padding: '12px 24px', height: '100%' }}>
                                                <Typography.Title level={4}>Lane Details</Typography.Title>
                                                <DataRow title="Active Loads" value={inTransitLoads.length} />
                                                <DataRow title="Loads Need Cover" value={openLoads.length} />
                                                <DataRow title="Estimated Volume / Week" />
                                                <DataRow title="Insurance Requirement" />
                                            </Col>
                                            <Col md={12} sm={24} style={{ padding: '12px 24px', height: '100%' }}>
                                                <Typography.Title level={4}>Equipment Needs and Services</Typography.Title>
                                                {isObjectNotEmpty(loadLane.equipmentNeeds) ? (
                                                    <>
                                                        {isStringNotEmpty(loadLane.equipmentNeeds.trailerType) ? (<DataRow title="Trailer Type" value={loadLane.equipmentNeeds.trailerType.description} dataType="String" />) : null}
                                                        {isNumberNotEmpty(loadLane.equipmentNeeds.trailerLength) ? (<DataRow title="Trailer Length" value={loadLane.equipmentNeeds.trailerLength} units={loadLane.equipmentNeeds.trailerLengthUnit} dataType="Length" />) : null}
                                                        {isNumberNotEmpty(loadLane.equipmentNeeds.trailerWeightCapacity) ? (<DataRow title="Max Load Weight Capacity" value={loadLane.equipmentNeeds.trailerWeightCapacity} units={loadLane.equipmentNeeds.trailerWeightCapacityUnit} dataType="Weight" />) : null}
                                                        {isNumberNotEmpty(loadLane.equipmentNeeds.preCoolReefer) ? (<DataRow title="Pre-cool Temp" value={loadLane.equipmentNeeds.preCoolReefer} units={loadLane.equipmentNeeds.preCoolReeferUnit} dataType="Temperature" />) : null}
                                                        {isNumberNotEmpty(loadLane.equipmentNeeds.minRunTemp) && isNumberNotEmpty(loadLane.equipmentNeeds.maxRunTemp) ? (<DataTemperatureRangeRow title="Temp Range" min={loadLane.equipmentNeeds.minRunTemp} minUnits={loadLane.equipmentNeeds.minRunTempUnit} max={loadLane.equipmentNeeds.maxRunTemp} maxUnits={loadLane.equipmentNeeds.maxRunTempUnit} />) : null}
                                                    </>
                                                ) : null}
                                                {isListNotEmpty(loadLane.equipmentSpecifics) ? (
                                                    <DataListRow title="Equipment Specifics" valueStyle={{ marginLeft: 16 }} data={loadLane.equipmentSpecifics} dataType="EquipmentSpecific" />
                                                ) : null}
                                                {isListNotEmpty(loadLane.driverRequirements) ? (
                                                    <DataListRow title="Driver Requirements" valueStyle={{ marginLeft: 16 }} data={loadLane.driverRequirements} dataType="DriverRequirement" />
                                                ) : null}
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col md={12} sm={24}>
                                    <ComplexCard
                                        title={`Approx. Trip Miles: ${stringFormatter.toFormattedString('Distance', loadLane.approxDistance, loadLane.approxDistanceUnit)}`}
                                        style={{ minHeight: 400, border: '1px solid #b7eb8f' }}
                                        headStyle={{ backgroundColor: '#f6ffed', borderBottom: '1px solid #b7eb8f', fontSize: 14, textAlign: 'center' }}
                                    >
                                        <Row>
                                            <Col span={10} style={{ paddingRight: 12 }}>
                                                {renderStops(loadLane)}
                                            </Col>
                                            <Col span={14}>
                                                <LoadMap
                                                    height={'300px'}
                                                    key={`load-details-map-${loadLane.id}-key`}
                                                    loadId={isObjectNotEmpty(loadLane) ? loadLane.id : null}
                                                    stops={isObjectNotEmpty(loadLane) && isListNotEmpty(loadLane.locations) ?
                                                        loadLane.locations.map((location) => {
                                                            const stopLocation = LoadStopUtils.getLocationInfoByLink(location.location, loadLane.accountId);
                                                            if (isObjectNotEmpty(stopLocation)) {
                                                                return {
                                                                    longitude: stopLocation.longitude,
                                                                    latitude: stopLocation.latitude,
                                                                    stopLocation: stopLocation,
                                                                    stopType: location.stopType,
                                                                    id: location.locationId,
                                                                    loadId: loadLane.id
                                                                };
                                                            } else {
                                                                return {
                                                                    longitude: location.longitude,
                                                                    latitude: location.latitude,
                                                                    stopLocation: location.location,
                                                                    stopType: location.stopType,
                                                                    id: location.locationId,
                                                                    loadId: loadLane.id
                                                                };
                                                            }
                                                        }) : []}
                                                    containerName={`load-details-map-${loadLane.id}`}
                                                />
                                            </Col>
                                        </Row>
                                    </ComplexCard>
                                </Col>
                                <Col span={24}>
                                    <LinkedAccounts
                                        accountId={loadLane.accountId}
                                        entityTypes={["CARRIER", "BROKER"]}
                                        title="Carriers"
                                        actions={<Button type="primary" onClick={() => { toggleNewRequestForBids(); }}>Request New Quotes</Button>}
                                        breadcrumbs={breadcrumbs}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </TabPane>
                    <TabPane tab="Bids" key="bids">
                        <div style={{ padding: 24 }}>
                            <Row gutter={[24, 24]}>
                                <Col md={8} sm={24}>
                                    <Card
                                        title={`Open Loads for ${isStringNotEmpty(loadLane.irisId) ? loadLane.irisId : ''}`}
                                        style={{ border: '1px solid #b7eb8f' }}
                                        bodyStyle={{ padding: 0 }}
                                        headStyle={{ backgroundColor: '#f6ffed', borderBottom: '1px solid #b7eb8f', fontSize: 14 }}
                                    >
                                        <DataTable
                                            bordered={false}
                                            rowSelection={rowSelectionLoad}
                                            onRow={onClickRow}
                                            rowClassName={getLoadRowClassName}
                                            loading={isLoadingOpenLoads === true || isLoadingRequestForBids === true}
                                            dataSource={loadsWithBids.filter(l => l.loadLaneId === id)}
                                            columns={loadColumns}
                                            hidePaging={true}
                                            style={{ backgroundColor: '#ffffff' }}
                                            rowKey={record => record.id}
                                        />
                                    </Card>
                                </Col>
                                <Col md={16} sm={24}>
                                    <Card
                                        title="Carrier Quotes"
                                        style={{ border: '1px solid #b7eb8f' }}
                                        headStyle={{ backgroundColor: '#f6ffed', borderBottom: '1px solid #b7eb8f', fontSize: 14 }}
                                        extra={<Button type="primary" onClick={() => { toggleNewRequestForBids(); }}>Request New Quotes</Button>}
                                    >
                                        {isStringNotEmpty(selectedLoadId) ? (
                                            requestForBids.filter(r => r.loadId === selectedLoadId && r.fromEntityId === loadLane.accountId).length > 0 ? (
                                                <DataTable
                                                    bordered={false}
                                                    rowSelection={rowSelectionRequestForBids}
                                                    rowClassName={getRequestForBidRowClassName}
                                                    loading={isLoadingOpenLoads === true || isLoadingRequestForBids === true}
                                                    dataSource={requestForBids.filter(r => r.loadId === selectedLoadId && r.fromEntityId === loadLane.accountId)}
                                                    columns={requestForBidColumns}
                                                    hidePaging={true}
                                                    style={{ backgroundColor: '#ffffff' }}
                                                    rowKey={record => record.id}
                                                />
                                            ) : (
                                                <Empty style={{ backgroundColor: '#ffffff', margin: 0, border: '2px dashed #979797', minHeight: 300, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: 18, fontWeight: 500 }} image={<FontAwesomeIcon className="anticon" size="4x" icon={faCommentsDollar} />} description={'No Requests for Quotes have been sent for this Load.'} />
                                            )) : (
                                            <Empty style={{ backgroundColor: '#ffffff', margin: 0, border: '2px dashed #979797', minHeight: 300, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: 18, fontWeight: 500 }} image={<FontAwesomeIcon className="anticon" size="4x" icon={faPallet} />} description={'Select a Load to View Carrier Quotes.'} />
                                        )}
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </TabPane>
                    <TabPane tab="Loads In Transit" key="inTransit">
                        <div style={{ padding: 24 }}>
                            <QueueLoads
                                title="In Transit Loads"
                                filter={`IN_TRANSIT_LOAD_LANE_${loadLane.id}`}
                                filterSearchParams={{
                                    page: 1,
                                    size: 1000000,
                                    sort: 'pickUpDateTime',
                                    order: 'asc',
                                    isDeleted: false,
                                    eta: false
                                }}
                                shipperId={loadLane.accountId}
                                loadLaneId={loadLane.id}
                                defaultLoadStatusFilter={['IN_TRANSIT', 'AT_STOP']}
                                showTable={true}
                                showActiveLoadData={true}
                                showNewLoadButton={false}
                            />
                        </div>
                    </TabPane>
                    <TabPane tab="Carriers" key="carriers">
                        <div style={{ padding: 24 }}>
                            <LinkedAccounts
                                accountId={loadLane.accountId}
                                entityTypes={["CARRIER", "BROKER"]}
                                breadcrumbs={breadcrumbs}
                            />
                        </div>
                    </TabPane>
                </Tabs>
                {requestForBidsModal}
            </Spin>
        );
    } else {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={true} />
        );
    }
};

export default withRouter(LoadLane);