import axiosAuthenticated from "./axiosAuthenticated";
import moment from 'moment';
import logger from "../shared/logger";
import { isListNotEmpty, isMomentDate, isNotNullOrUndefined, isObjectNotEmpty, isStringNotEmpty } from "../shared/objectUtils";

const documentsPath = '/documents';

export const getFilesMetaData = async (entityId) => {
    if (isStringNotEmpty(entityId)) {
        try {
            const resp = await axiosAuthenticated.get(documentsPath, { params: { entityId: entityId, isDeleted: false } });

            return resp.data.data;
        } catch (err) {
            logger.logErrorEvent('file_client_error', err, err.message, true);

            return [];
        }
    }

    return [];
};

export const addFile = async (file, entityType, entityId, documentType, displayName, description, visibleTo, expiryDate = null, isReviewed = false, reviewStatus = null, licenseNumber = null, links = null) => {
    if (isNotNullOrUndefined(file)) {
        let metaData = {};

        if (isStringNotEmpty(displayName)) {
            metaData.displayName = displayName;
        }

        if (isStringNotEmpty(description)) {
            metaData.description = description;
        }

        if (isStringNotEmpty(entityType)) {
            metaData.entityType = entityType;
        }

        if (isStringNotEmpty(entityId)) {
            metaData.entityId = entityId;
        }

        if (isStringNotEmpty(documentType)) {
            metaData.documentType = documentType;
        }

        if (isStringNotEmpty(visibleTo)) {
            metaData.visibleTo = visibleTo;
        }

        if (isMomentDate(expiryDate)) {
            metaData.expiryDate = moment(expiryDate).utc().toISOString();
        } else if (isStringNotEmpty(expiryDate)) {
            // expiryDate is already an iso utc string
            metaData.expiryDate = expiryDate;
        }

        if (isNotNullOrUndefined(isReviewed)) {
            metaData.isReviewed = isReviewed;
        }

        if (isStringNotEmpty(reviewStatus)) {
            metaData.reviewStatus = reviewStatus;
        }

        if (isStringNotEmpty(licenseNumber)) {
            metaData.licenseNumber = licenseNumber;
        }

        if (isListNotEmpty(links)) {
            metaData.links = links;
        }

        let formData = new FormData();
        formData.append("file", file);
        formData.append("json", JSON.stringify(metaData));
        try {
            const resp = await axiosAuthenticated.post(documentsPath, formData);

            //console.log(resp.data);
            return resp.data;
        } catch (err) {
            logger.logErrorEvent('file_client_error', err, err.message, true);
            
            return null;
        }
    }

    return null;
};

export const deleteFile = async (id) => {
    if (isStringNotEmpty(id)) {
        let payload = { isDeleted: true };
        let formData = new FormData();
        if (isObjectNotEmpty(payload)) {
            formData.append("json", JSON.stringify(payload));
            try {
                await axiosAuthenticated.put(documentsPath + `/${id}`, formData);
    
                return true;
            } catch (err) {
                logger.logErrorEvent('file_client_error', err, err.message, true);
    
                return false;
            }
        }
    }

    return false;
};

export const updateFile = async (id, file) => {
    if (isNotNullOrUndefined(file) && isStringNotEmpty(id)) {
        let formData = new FormData();
        formData.append("file", file);
        try {
            const resp = await axiosAuthenticated.put(documentsPath + `/${id}`, formData);

            //console.log(resp.data);
            return resp.data;
        } catch (err) {
            logger.logErrorEvent('file_client_error', err, err.message, true);

            return null;
        }
    }

    return null;
};

export const updateFileMetaData = async (id, payload) => {
    if (isStringNotEmpty(id)) {
        let metaData = {};

        if (isNotNullOrUndefined(payload.isDeleted)) {
            metaData.isDeleted = payload.isDeleted;
        }

        if (isNotNullOrUndefined(payload.isReviewed)) {
            metaData.isReviewed = payload.isReviewed;
        }

        if (isStringNotEmpty(payload.displayName)) {
            metaData.displayName = payload.displayName;
        } else if (payload.displayName === null) {
            metaData.displayName = null;
        }

        if (isStringNotEmpty(payload.description)) {
            metaData.description = payload.description;
        } else if (payload.description === null) {
            metaData.description = null;
        }

        if (isMomentDate(payload.expiryDate)) {
            metaData.expiryDate = payload.expiryDate.utc().toISOString();
        } else if (isStringNotEmpty(payload.expiryDate)) {
            // expiryDate is already an iso utc string or null (null is to clear the value in the db)
            metaData.expiryDate = payload.expiryDate;
        } else if (payload.expiryDate === null) {
            metaData.expiryDate = null;
        }

        if (isStringNotEmpty(payload.visibleTo)) {
            metaData.visibleTo = payload.visibleTo;
        } else if (payload.visibleTo === null) {
            metaData.visibleTo = null;
        }

        if (isStringNotEmpty(payload.reviewStatus)) {
            metaData.reviewStatus = payload.reviewStatus;
        }

        if (isStringNotEmpty(payload.licenseNumber)) {
            metaData.licenseNumber = payload.licenseNumber;
        } else if (payload.licenseNumber === null) {
            metaData.licenseNumber = null;
        }

        if (isListNotEmpty(payload.links)) {
            metaData.links = payload.links;
        } else if (payload.links === null) {
            metaData.links = [];
        }

        let formData = new FormData();
        if (isObjectNotEmpty(metaData)) {
            formData.append("json", JSON.stringify(metaData));
            try {
                const resp = await axiosAuthenticated.put(documentsPath + `/${id}`, formData);

                //console.log(resp.data);
                return resp.data;
            } catch (err) {
                logger.logErrorEvent('file_client_error', err, err.message, true);

                return null;
            }
        }
    }

    return null;
};