import React from 'react';
import DataRow from '../DataRow/DataRow';
import { Col, Row } from 'antd';
import { isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';

const Content = ({ content }) => {
    if (isObjectNotEmpty(content)) {
        return (
            <Row>
                <Col xs={24} md={24} lg={12} xl={12}>
                    {isStringNotEmpty(content.irisId) ? (<DataRow title="Content ID" value={content.irisId} dataType="String" />) : null}
                    {isStringNotEmpty(content.eventType) ? (<DataRow title="Event Type" value={content.eventType} dataType="String" />) : null}
                    {isStringNotEmpty(content.channel) ? (<DataRow title="Channel" value={content.channel} dataType="CMSChannel" />) : null}
                </Col>
                <Col xs={24} md={24} lg={12} xl={12}>
                    {isStringNotEmpty(content.message) ? (<DataRow title="Message" value={content.message} dataType="String" />) : null}
                    {isStringNotEmpty(content.description) ? (<DataRow title="Description" value={content.description} dataType="String" />) : null}
                </Col>
            </Row>
        );
    } else {
        return null;
    }
};

export default Content;