import React from 'react';
import { Button, Row, Col } from "antd";
import Fieldset from '../FormFieldset/FormFieldset';
import { useSelector } from 'react-redux';
import Header from '../Header/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPallet } from '@fortawesome/free-solid-svg-icons';
import classes from './NewLoadReview.module.scss';
import Enums from '../../shared/enums';
import StringFormatter from '../../shared/stringFormatter';
import DataRow from '../DataRow/DataRow';
import DataListRow from '../DataListRow/DataListRow';
import DataCommodityListRow from '../../components/DataCommodityListRow/DataCommodityListRow';
import DataContactInfoRow from '../DataContactInfoRow/DataContactInfoRow';
import MomentDate from '../../shared/dateFormatter';
import { isBooleanTrue, isListNotEmpty, isNotNullOrUndefined, isNumberGreaterThanZero, isNumberNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import LoadStopUtils from '../../api/utils/loadStopUtils';
import DataCollapsableRow from '../DataCollapsableRow/DataCollapsableRow';
import DataTemperatureRangeRow from '../DataTemperatureRangeRow/DataTemperatureRangeRow';

const stringFormatter = new StringFormatter();
const momentDate = new MomentDate();

const NewLoadReview = ({ shipperData, pickUpStops, dropOffStops, equipmentData, servicesData, previousStepAction, previousStepActionName, nextStepAction, nextStepActionName, practicalRouteMiles, invoiceLineItems, hideLoadSpecificInformation }) => {
    //#region useSelectors

    const entityType = useSelector(state => state.auth.entityType);
    const entityId = useSelector(state => state.auth.entityId);

    //#endregion
    //#region displays

    const getEquipmentData = () => {
        if (isObjectNotEmpty(equipmentData)) {
            return (
                <>
                    {(isObjectNotEmpty(equipmentData.trailerType) && isStringNotEmpty(equipmentData.trailerType.description)) ? (
                        <DataRow title="Trailer Type" value={equipmentData.trailerType.description} />
                    ) : null}
                    {(isNumberNotEmpty(equipmentData.trailerLength) && isStringNotEmpty(equipmentData.trailerLengthUnit)) ? (
                        <DataRow title="Trailer Length" value={equipmentData.trailerLength} units={equipmentData.trailerLengthUnit} dataType="Length" />
                    ) : null}
                    {(isNumberNotEmpty(equipmentData.trailerWeightCapacity) && isStringNotEmpty(equipmentData.trailerWeightCapacityUnit)) ? (
                        <DataRow title="Load Weight" value={equipmentData.trailerWeightCapacity} units={equipmentData.trailerWeightCapacityUnit} dataType="Weight" />
                    ) : null}
                    {isNumberNotEmpty(equipmentData.preCoolReefer) && isNumberNotEmpty(equipmentData.minRunTemp) && isNumberNotEmpty(equipmentData.maxRunTemp) ? (
                        <>
                            <DataRow title="Pre-Cool Temp" value={equipmentData.preCoolReefer} units={equipmentData.preCoolReeferUnit} dataType="Temperature" />
                            <DataTemperatureRangeRow title="Run Temp Range" min={equipmentData.minRunTemp} minUnits={equipmentData.minRunTempUnit} max={equipmentData.maxRunTemp} maxUnits={equipmentData.maxRunTempUnit} />
                        </>
                    ) : null}
                    {isListNotEmpty(equipmentData.equipmentSpecifics) ? (
                        <DataListRow title="Equipment Specifics" valueStyle={{ paddingLeft: 8 }} data={equipmentData.equipmentSpecifics} dataType="EquipmentSpecific" />
                    ) : null}
                    {isListNotEmpty(equipmentData.driverRequirements) ? (
                        <DataListRow title="Driver Requirements" valueStyle={{ paddingLeft: 8 }} data={equipmentData.driverRequirements} dataType="DriverRequirement" />
                    ) : null}
                </>
            );
        } else {
            return null;
        }
    };

    const getLoadStopDisplay = (stop, stopIndex) => {
        if (isObjectNotEmpty(stop)) {
            const stopName = LoadStopUtils.getStopLocationNameDisplay(stop, shipperData.shipperId, null, entityType, entityId);
            const stopAddress = LoadStopUtils.getStopAddressDisplay(stop);
            const stopType = LoadStopUtils.getStopType(stop);
            const apptType = LoadStopUtils.getAppointmentType(stop);
            const stopIcon = LoadStopUtils.getStopMarkerIcon(stop);

            return (
                <DataCollapsableRow
                    key={`load-stop-details-display-${stop.id}`}
                    isCollapsable={true}
                    isCollapsed={false}
                    image={isNotNullOrUndefined(stopIcon) ? stopIcon : null}
                    imageAlt={LoadStopUtils.getStopTypeDisplay(stop)}
                    title={isStringNotEmpty(stopName) ? stopName : 'N/A'}
                    titleStyle={{ paddingLeft: 0, fontSize: 16, fontWeight: 500, color: '#000000' }}
                    subtitle={isStringNotEmpty(stopAddress) ? (
                        <>
                            <div>{stopAddress}</div>
                            {isStringNotEmpty(apptType) ? (
                                <div>
                                    <span style={{ fontSize: 14, textTransform: 'uppercase', color: '#A9A9A9' }}>{(isStringNotEmpty(stopType) ? `${LoadStopUtils.getStopTypeDisplay(stop)}${stopType === 'PICK_UP' && pickUpStops.length > 1 ? ' ' + (stopIndex + 1) : ''}${stopType === 'DROP_OFF' && dropOffStops.length > 1 ? ' ' + (stopIndex + 1) : ''}` : '')}  </span>
                                    <span style={{ fontSize: 14, textTransform: 'uppercase', color: '#A9A9A9' }}>{LoadStopUtils.getAppointmentDateTimeDisplay(stop)}</span>
                                </div>
                            ) : null}
                        </>
                    ) : null
                    }
                    subtitleClassName="load-summary-subtitle"
                >
                    <div style={{ marginTop: 12 }}>
                        <Row>
                            {isStringNotEmpty(apptType) ? (
                                <Col span={24}>
                                    <Fieldset
                                        legend={`Date, Appointment, & ${Enums.StopTypes.getValueByName(stop.stopType)} Details`}
                                        legendStyle={{ marginBottom: 10 }}
                                        fieldsetStyle={{ margin: '10px 0' }}
                                        isCollapsable={true}
                                    >
                                        <DataRow title="Appointment Type" value={apptType} dataType="AppointmentType" />
                                        <DataRow title={`Requested ${LoadStopUtils.getStopTypeDisplay(stop)} Date & Appointment`} value={LoadStopUtils.getAppointmentDateTimeDisplay(stop)} dataType="String" />

                                        {apptType === "HAVE_APPOINTMENT" ? (
                                            <DataRow title="Appointment Number" value={stop.apptNumber} dataType="String" />
                                        ) : null}
                                        {(stopType === 'PICK_UP' && hideLoadSpecificInformation === false) ? (
                                            <DataRow title="Pick-Up Number" value={stop.pickUpNumber} dataType="String" />
                                        ) : null}
                                        {(stopType === 'DROP_OFF' && hideLoadSpecificInformation === false) ? (
                                            <>
                                                <DataRow title="Drop-Off Number" value={stop.dropOffNumber} dataType="String" />
                                                <DataRow title="Customer PO Number Number" value={stop.purchaseOrderNumber} dataType="String" />
                                            </>
                                        ) : null}
                                        {apptType === "NEED_APPOINTMENT" ? (
                                            <DataRow title="Appointment Call Ahead Date/Time" value={isNotNullOrUndefined(stop.apptCallAheadDateTime) ? momentDate.fromTimeZonelessToTimeZoneAsTimeZoneDateTimeString(stop.apptCallAheadDateTime, isObjectNotEmpty(stop.stopLocation) && isStringNotEmpty(stop.stopLocation.timeZone) ? stop.stopLocation.timeZone : null) : null} dataType="String" />
                                        ) : null}
                                        <DataContactInfoRow separateLines={false} valueStyle={{ paddingLeft: 8 }} title={`${Enums.StopTypes.getValueByName(stop.stopType)} Contact`} contact={stop.apptPointOfContact} />
                                        <DataRow title="Additional Appointment Instructions" value={stop.apptInstructions} dataType="String" />
                                    </Fieldset>
                                </Col>
                            ) : null}
                            {(isListNotEmpty(stop.bolNumbers) && hideLoadSpecificInformation === false) || isListNotEmpty(stop.commodities) ? (
                                <Col span={24}>
                                    <Fieldset
                                        legend="BOL and Commodity Details"
                                        legendStyle={{ marginBottom: 10 }}
                                        fieldsetStyle={{ margin: '10px 0' }}
                                        isCollapsable={true}
                                    >
                                        <Row gutter={[12, 12]}>
                                            {(isListNotEmpty(stop.bolNumbers) && hideLoadSpecificInformation === false) ? (
                                                <Col span={12}>
                                                    <DataListRow title="BOL Number(s)" valueStyle={{ paddingLeft: 8 }} data={stop.bolNumbers} dataType="String" />
                                                </Col>
                                            ) : null}
                                            {isListNotEmpty(stop.commodities) ? (
                                                <Col span={12}>
                                                    <DataCommodityListRow title="Commodities" nameStyle={{ paddingLeft: 8 }} data={stop.commodities} />
                                                </Col>
                                            ) : null}
                                        </Row>
                                    </Fieldset>
                                </Col>
                            ) : null}
                            {isStringNotEmpty(stop.loadingType) ? (
                                <Col span={24}>
                                    <Fieldset
                                        legend={stop.stopType === 'PICK_UP' ? 'Loading Details' : 'Unloading Details'}
                                        legendStyle={{ marginBottom: 10 }}
                                        fieldsetStyle={{ margin: '10px 0' }}
                                        isCollapsable={true}
                                    >
                                        <DataRow title={stop.stopType === "PICK_UP" ? "Loading Type" : "Unloading Type"} value={stop.loadingType} dataType={stop.stopType === "PICK_UP" ? "LoadingType" : "UnloadingType"} />
                                        <DataRow title="Lumper Fee" value={isBooleanTrue(stop.hasLumperFee) ? `Yes, ${stringFormatter.toFormattedMoney(stop.lumperFee)}` : 'No'} dataType="String" />
                                        <DataRow title="Driver Assist" value={isBooleanTrue(stop.hasDriverAssist) ? `Yes, ${stringFormatter.toFormattedMoney(stop.driverAssist)}` : 'No'} dataType="String" />
                                        <DataRow title="Special Instructions" value={stop.specialInstructions} dataType="String" />
                                    </Fieldset>
                                </Col>
                            ) : null}
                        </Row>
                    </div>
                </DataCollapsableRow>
            );
        } else {
            return null;
        }
    };

    const getStopsData = () => {
        if (isListNotEmpty(pickUpStops) && isListNotEmpty(dropOffStops)) {
            return (
                <>
                    <Row gutter={[12, 12]}>
                        <Col span={24}>
                            {pickUpStops.map((stop, index) => { return getLoadStopDisplay(stop, index); })}
                        </Col>
                        <Col span={24}>
                            {dropOffStops.map((stop, index) => { return getLoadStopDisplay(stop, index); })}
                        </Col>
                    </Row>
                </>
            );
        } else {
            return null;
        }
    };

    const getLoadData = () => {
        if (isObjectNotEmpty(servicesData)) {
            return (
                <>
                    {isStringNotEmpty(servicesData.parentName) ? <DataRow title={hideLoadSpecificInformation === true ? "Bundle Name" : "Load Name"} value={servicesData.parentName} dataType="String" /> : null}
                    {isStringNotEmpty(servicesData.customerOrderNumber) ? <DataRow title="Customer Order Number" value={servicesData.customerOrderNumber} dataType="String" /> : null}
                    {entityType === "STAFF" && isStringNotEmpty(servicesData.serviceType) ? <DataRow title="Service Type" value={servicesData.serviceType} dataType="LoadServiceType" /> : null}
                </>
            );
        } else {
            return null;
        }
    };

    const getPricingData = () => {
        if (isObjectNotEmpty(servicesData)) {
            return (
                <div>
                    {invoiceLineItems.filter(i => i.fromEntityType === 'SHIPPER' && Enums.StopFees.doesNameExist(i.itemType)).length > 0 && isNumberGreaterThanZero(servicesData.totalAddOnCharge) ? (
                        <>
                            <div style={{ marginBottom: 12 }}><span className="load-summary-subtitle">Stop Fees</span></div>
                            {invoiceLineItems.filter(i => i.fromEntityType === 'SHIPPER' && Enums.StopFees.doesNameExist(i.itemType)).map((lineItem, index) => {
                                return (
                                    <DataRow key={`subtotal-lineitem-${index}`} title={lineItem.description} value={lineItem.totalAmount} dataType="Money" />
                                );
                            })}
                            {isNumberGreaterThanZero(servicesData.totalAddOnCharge) ? (
                                <div style={{ paddingLeft: 8, marginBottom: 12 }}><span className="load-summary-subtitle"><i>Subtotal: {stringFormatter.toFormattedMoney(servicesData.totalAddOnCharge)}</i></span></div>
                            ) : null}
                        </>
                    ) : null}

                    {(entityType === "STAFF" || entityType === "BROKER" || (entityType === "CARRIER" && servicesData.serviceType === 'TMS')) && isNumberGreaterThanZero(servicesData.shipperAmount) ? (
                        <div style={{ marginBottom: 12 }}><span className="load-summary-title">Total Charge to the Shipper: {stringFormatter.toFormattedMoney(servicesData.shipperAmount)}</span></div>
                    ) : null}
                    {(entityType === "STAFF" || entityType === "BROKER" || (entityType === "SHIPPER" && servicesData.serviceType === 'TMS')) && isNumberGreaterThanZero(servicesData.carrierAmount) ? (
                        <div style={{ marginBottom: 12 }}><span className="load-summary-title">Total Pay to the Carrier: {stringFormatter.toFormattedMoney(servicesData.carrierAmount)}</span></div>
                    ) : null}
                    {(entityType === "SHIPPER" && servicesData.serviceType === 'BROKERAGE') && isNumberGreaterThanZero(servicesData.shipperAmount) ? (
                        <div style={{ marginBottom: 12 }}><span className="load-summary-title">Total Pay to the IRIS Freight: {stringFormatter.toFormattedMoney(servicesData.shipperAmount)}</span></div>
                    ) : null}
                    {entityType === "STAFF" && isNumberGreaterThanZero(servicesData.IRISProfit) ? (
                        <div style={{ marginBottom: 12 }}><span className="load-summary-title">IRIS Profit: {stringFormatter.toFormattedMoney(servicesData.IRISProfit)}</span></div>
                    ) : null}
                    {entityType === "STAFF" && isNumberGreaterThanZero(servicesData.commissionPercentage) ? (
                        <div style={{ marginBottom: 12 }}><span className="load-summary-title">Commission: {stringFormatter.toFormattedPercentage(servicesData.commissionPercentage)}</span></div>
                    ) : null}
                </div>
            );
        } else {
            return null;
        }
    };

    //#endregion

    return (
        <div className="load-creation-step-container">
            <div className="load-creation-step">
                <Header
                    titleStyle={{ fontSize: 24 }}
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    title={"Review"}
                    title2={"Review the details below."}
                    icon={<FontAwesomeIcon className="anticon" icon={faPallet} />}
                    iconStyle={{ background: 'transparent' }}
                />
                {getStopsData()}
                <Fieldset legend="Equipment Needs & Services">
                    {getEquipmentData()}
                </Fieldset>
                <Fieldset legend="Load Information">
                    {getLoadData()}
                </Fieldset>
                <Fieldset legend={(entityType === 'SHIPPER' || entityType === 'STAFF') ? "Pricing & Tender" : "Invoice"}>
                    {getPricingData()}
                </Fieldset>
            </div>
            <Row gutter={[12, 12]} className="load-creation-step-buttons">
                <Col xs={{ span: 24, order: 2 }} md={{ span: 12, order: 1 }}>
                    {isNotNullOrUndefined(previousStepAction) ? (<Button type="default" block onClick={() => { previousStepAction('services'); }}>{previousStepActionName}</Button>) : null}
                </Col>
                <Col xs={{ span: 24, order: 1 }} md={{ span: 12, order: 2 }}>
                    {isNotNullOrUndefined(nextStepAction) ? (<Button type="primary" block onClick={() => { nextStepAction(); }}>{nextStepActionName}</Button>) : null}
                </Col>
            </Row>
        </div>
    );
};

export default NewLoadReview;