import React, { useState } from 'react';
import { CheckOutlined, PlusOutlined, QuestionOutlined, EditOutlined, DeleteOutlined, DownOutlined, StrikethroughOutlined, MinusOutlined } from '@ant-design/icons';
import { Row, Col, Button, Drawer, Tooltip, Card, Menu, Dropdown } from 'antd';
import classes from './LoadInvoice.module.scss';
import NewInvoiceLineItem from '../../components/NewInvoiceLineItem/NewInvoiceLineItem';
import EditInvoiceLineItem from '../../components/EditInvoiceLineItem/EditInvoiceLineItem';
import EditInvoiceLineItemStatus from '../EditInvoiceLineItemStatus/EditInvoiceLineItemStatus';
import EditInvoiceLineItemApprovalStatus from '../EditInvoiceLineItemApprovalStatus/EditInvoiceLineItemApprovalStatus';
import EditInvoiceLineItemStatusCancelled from '../EditInvoiceLineItemStatusCancelled/EditInvoiceLineItemStatusCancelled';
import RemoveInvoiceLineItem from '../../components/RemoveInvoiceLineItem/RemoveInvoiceLineItem';
import ValueFormatting from '../../shared/ValueFormatting';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools } from '@fortawesome/free-solid-svg-icons';
import Pricing from '../../shared/pricing';
import StringFormatter from '../../shared/stringFormatter';
import Enums from '../../shared/enums';
import { useSelector } from 'react-redux';
import { isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { CanSee } from '../../shared/entitlements/entitlements';
import LoadCompletionCard from '../LoadCompletionCard/LoadCompletionCard';
import LoadUtils from '../../api/utils/loadUtils';

const stringFormatter = new StringFormatter();

const LoadInvoice = ({ load, loadId, stops, invoices, invoiceLineItems, transactions, claims, serviceType, shipperId, carrierId, currentStatus }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    const singularEntityName = "Invoice Line Item";
    const pluralEntityName = "Invoice Line Items";

    //#endregion
    //#region useSelectors

    const isAdmin = useSelector(state => state.auth.isAdmin);
    const entityType = useSelector(state => state.auth.entityType);

    //#endregion
    //#region useStates

    const [selectedInvoiceLineItem, setSelectedInvoiceLineItem] = useState({});
    const [showEditInvoiceLineItem, setShowEditInvoiceLineItem] = useState(false);
    const [showEditStatusInvoiceLineItem, setShowEditStatusInvoiceLineItem] = useState(false);
    const [showCancelInvoiceLineItem, setShowCancelInvoiceLineItem] = useState(false);
    const [showEditApprovalStatusInvoiceLineItem, setShowEditApprovalStatusInvoiceLineItem] = useState(false);
    const [showNewInvoiceLineItem, setShowNewInvoiceLineItem] = useState(false);
    const [showRemoveInvoiceLineItem, setShowRemoveInvoiceLineItem] = useState(false);
    const [fromEntityType, setFromEntityType] = useState(null);
    const [toEntityType, setToEntityType] = useState(null);

    //#endregion
    //#region table displays

    const invoiceLineItemMenu = (record) => {
        return (
            <Menu>
                {isObjectNotEmpty(load) && (record.approvalStatus !== "APPROVED" && record.status !== 'COMPLETED' && record.status !== 'CANCELLED' && (record.toEntityType === entityType || record.fromEntityType === entityType || entityType === 'STAFF')) ? (
                    <Menu.Item key="approveInvoiceLineItem" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoiceLineItem(record); toggleEditApprovalStatusInvoiceLineItem(); }}>
                        <CheckOutlined style={{ marginRight: 8 }} />
                        <span>Approve Invoice Line Item</span>
                    </Menu.Item>
                ) : null}
                {isObjectNotEmpty(load) && (record.approvalStatus !== "APPROVED" && record.status !== 'COMPLETED' && record.status !== 'CANCELLED' && (load.serviceType === 'TMS' || entityType === 'STAFF')) ? (
                    <Menu.Item key="updateStatusInvoiceLineItem" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoiceLineItem(record); toggleEditStatusInvoiceLineItem(); }}>
                        <QuestionOutlined style={{ marginRight: 8 }} />
                        <span>Update Status of Invoice Line Item</span>
                    </Menu.Item>
                ) : null}
                {((record.approvalStatus !== "APPROVED" && record.status !== 'COMPLETED' && record.status !== 'CANCELLED' && isStringNotEmpty(record.itemType) && !Enums.StopFees.doesNameExist(record.itemType) && !Enums.EquipmentSpecifics.doesNameExist(record.itemType) && !Enums.LoadRequirements.doesNameExist(record.itemType) && isObjectNotEmpty(load) && (load.serviceType === 'TMS' || entityType === 'STAFF')) || isAdmin === true) && (
                    <Menu.Item key="editInvoiceLineItem" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoiceLineItem(record); toggleEditInvoiceLineItem(); }}>
                        <EditOutlined style={{ marginRight: 8 }} />
                        <span>Edit Invoice Line Item Details</span>
                    </Menu.Item>
                )}
                {(record.status !== 'COMPLETED' && record.status !== 'CANCELLED' && isObjectNotEmpty(load) && (load.serviceType === 'TMS' || entityType === 'STAFF')) && (
                    <Menu.Item key="cancelInvoiceLineItem" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoiceLineItem(record); toggleCancelInvoiceLineItem(); }}>
                        <StrikethroughOutlined style={{ marginRight: 8 }} />
                        <span>Cancel Invoice Line Item</span>
                    </Menu.Item>
                )}
                {((record.approvalStatus !== "APPROVED" && record.status !== 'COMPLETED' && record.status !== 'CANCELLED' && isStringNotEmpty(record.itemType) && !Enums.StopFees.doesNameExist(record.itemType) && !Enums.EquipmentSpecifics.doesNameExist(record.itemType) && !Enums.LoadRequirements.doesNameExist(record.itemType) && isObjectNotEmpty(load) && (load.serviceType === 'TMS' || entityType === 'STAFF')) || isAdmin === true) && (
                    <Menu.Item key="removeInvoiceLineItem" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoiceLineItem(record); toggleRemoveInvoiceLineItem(); }}>
                        <DeleteOutlined style={{ marginRight: 8 }} />
                        <span>Delete Invoice Line Item</span>
                    </Menu.Item>
                )}
            </Menu>
        );
    };

    const invoiceLineItemColumns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            width: 100,
            render: (text, record) => {
                if (LoadUtils.loadStatusIsNot(load.loadStatus, ['CLOSED'])) {
                    return (
                        <Dropdown overlay={invoiceLineItemMenu(record)} trigger={['click']}>
                            <Button>Actions <DownOutlined /></Button>
                        </Dropdown>
                    );
                } else {
                    return '';
                }
            },
            align: 'center',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            textAlign: 'left',
        },
        {
            title: 'Base Price',
            dataIndex: 'baseAmount',
            key: 'baseAmount',
            textAlign: 'center',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            textAlign: 'center',
        },
        {
            title: 'Total',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            textAlign: 'right',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
        },
        {
            title: 'Owed By',
            dataIndex: 'fromEntityType',
            key: 'fromEntityType',
            textAlign: 'right',
            render: (text, record) => { return stringFormatter.toFormattedString("InvoiceAccountType", text, null); },
        },
        {
            title: 'Owed To',
            dataIndex: 'toEntityType',
            key: 'toEntityType',
            textAlign: 'right',
            render: (text, record) => { return stringFormatter.toFormattedString("InvoiceAccountType", text, null); },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            textAlign: 'right',
            render: (text, record) => { return stringFormatter.toFormattedString("InvoiceLineItemStatus", text, null); },
        },
        {
            title: 'Approval',
            dataIndex: 'approvalStatus',
            key: 'approvalStatus',
            textAlign: 'right',
            render: (text, record) => { return stringFormatter.toFormattedString("InvoiceApprovalStatus", text, null); },
        },
    ];

    //#endregion
    //#region toggles

    const toggleNewInvoiceLineItem = () => {
        setShowNewInvoiceLineItem(!showNewInvoiceLineItem);
    };

    const toggleEditInvoiceLineItem = () => {
        setShowEditInvoiceLineItem(!showEditInvoiceLineItem);
    };

    const toggleEditStatusInvoiceLineItem = () => {
        setShowEditStatusInvoiceLineItem(!showEditStatusInvoiceLineItem);
    };

    const toggleCancelInvoiceLineItem = () => {
        setShowCancelInvoiceLineItem(!showCancelInvoiceLineItem);
    }

    const toggleEditApprovalStatusInvoiceLineItem = () => {
        setShowEditApprovalStatusInvoiceLineItem(!showEditApprovalStatusInvoiceLineItem);
    };

    const toggleRemoveInvoiceLineItem = () => {
        setShowRemoveInvoiceLineItem(!showRemoveInvoiceLineItem);
    };

    //#endregion
    //#region displays

    const newInvoiceLineItemComponents = (
        <Drawer
            title={"New " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleNewInvoiceLineItem}
            visible={showNewInvoiceLineItem === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewInvoiceLineItem
                cancel={toggleNewInvoiceLineItem}
                load={load}
                stops={stops}
                shipperId={shipperId}
                carrierId={carrierId}
                fromEntityType={fromEntityType}
                toEntityType={toEntityType}
            />
        </Drawer>
    );

    const editInvoiceLineItemComponents = (
        <Drawer
            title={"Edit " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditInvoiceLineItem}
            visible={showEditInvoiceLineItem === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditInvoiceLineItem
                cancel={toggleEditInvoiceLineItem}
                invoiceLineItem={selectedInvoiceLineItem}
                load={load}
                shipperId={shipperId}
                carrierId={carrierId}
            />
        </Drawer>
    );

    const editStatusInvoiceLineItemComponents = (
        <Drawer
            title={"Update Status of " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditStatusInvoiceLineItem}
            visible={showEditStatusInvoiceLineItem === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditInvoiceLineItemStatus
                cancel={toggleEditStatusInvoiceLineItem}
                invoiceLineItem={selectedInvoiceLineItem}
                load={load}
            />
        </Drawer>
    );

    const cancelInvoiceLineItemComponents = (
        <Drawer
            title={"Cancel " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleCancelInvoiceLineItem}
            visible={showCancelInvoiceLineItem === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditInvoiceLineItemStatusCancelled
                cancel={toggleCancelInvoiceLineItem}
                invoiceLineItem={selectedInvoiceLineItem}
                load={load}
            />
        </Drawer>
    );

    const editApprovalStatusInvoiceLineItemComponents = (
        <Drawer
            title={"Approve " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditApprovalStatusInvoiceLineItem}
            visible={showEditApprovalStatusInvoiceLineItem === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditInvoiceLineItemApprovalStatus
                cancel={toggleEditApprovalStatusInvoiceLineItem}
                invoiceLineItem={selectedInvoiceLineItem}
                load={load}
            />
        </Drawer>
    );

    const removeInvoiceLineItemComponents = (
        <Drawer
            title={"Delete " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleRemoveInvoiceLineItem}
            visible={showRemoveInvoiceLineItem === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <RemoveInvoiceLineItem
                cancel={toggleRemoveInvoiceLineItem}
                invoiceLineItem={selectedInvoiceLineItem}
            />
        </Drawer>
    );

    const invoiceLineItemTitle = (forAccount, from, to) => (
        <Row gutter={[4, 4]} style={{ display: 'flex', alignItems: 'center' }}>
            <Col xs={{ span: 12 }}>
                <b>{'Manage ' + pluralEntityName + ' for ' + forAccount}</b>
            </Col>
            <Col xs={{ span: 12 }} style={{ textAlign: 'right' }}>
                {LoadUtils.loadStatusIsNot(load.loadStatus, ['CLOSED']) ? (
                    <CanSee entityAction="CREATE" entityModel="LOAD_INVOICE_LINE_ITEM" entityObject={load}>
                        <Tooltip placement="top" key="1" title={'New ' + singularEntityName}>
                            <Button type="primary" icon={<PlusOutlined />} style={{ marginRight: 8 }} onClick={() => { setFromEntityType(to); setToEntityType(from); toggleNewInvoiceLineItem(); }}>Addition</Button>
                        </Tooltip>
                        <Tooltip placement="top" key="2" title={'New ' + singularEntityName}>
                            <Button type="primary" icon={<MinusOutlined />} onClick={() => { setFromEntityType(from); setToEntityType(to); toggleNewInvoiceLineItem(); }}>Deduction</Button>
                        </Tooltip>
                    </CanSee>
                ) : null}
            </Col>
        </Row>
    );

    const calculateInvoiceTotals = () => {
        let invoiceRows = [];
        let totals = Pricing.calculateSourceTotals(invoiceLineItems, transactions);

        invoiceRows.push(
            <tr className={classes.thickLine} key="shipper-totals">
                <td className={classes.textRight}><b>Shipper</b></td>
                <td className={classes.textRight}>{totals.owedByShipper > 0 && <ValueFormatting.Money value={totals.owedByShipper} />}</td>
                <td className={classes.textRight}>{totals.owedToShipper > 0 && <ValueFormatting.Money value={totals.owedToShipper} />}</td>
                <td className={classes.textRight}>{totals.paidByShipper > 0 && <ValueFormatting.Money value={totals.paidByShipper} />}</td>
                <td className={classes.textRight}>{totals.paidToShipper > 0 && <ValueFormatting.Money value={totals.paidToShipper} />}</td>
                <td className={classes.textRight}><ValueFormatting.Money value={totals.owedByShipper - totals.owedToShipper - totals.paidByShipper + totals.paidToShipper} /></td>
            </tr>
        );
        invoiceRows.push(
            <tr key="carrier-totals">
                <td className={classes.textRight}><b>Carrier</b></td>
                <td className={classes.textRight}>{totals.owedByCarrier > 0 && <ValueFormatting.Money value={totals.owedByCarrier} />}</td>
                <td className={classes.textRight}>{totals.owedToCarrier > 0 && <ValueFormatting.Money value={totals.owedToCarrier} />}</td>
                <td className={classes.textRight}>{totals.paidByCarrier > 0 && <ValueFormatting.Money value={totals.paidByCarrier} />}</td>
                <td className={classes.textRight}>{totals.paidToCarrier > 0 && <ValueFormatting.Money value={totals.paidToCarrier} />}</td>
                <td className={classes.textRight}><ValueFormatting.Money value={totals.owedToCarrier - totals.owedByCarrier - totals.paidToCarrier + totals.paidByCarrier} /></td>
            </tr>
        );
        invoiceRows.push(
            <tr key="factoring-totals">
                <td className={classes.textRight}><b>Factoring Company</b></td>
                <td className={classes.textRight}>{totals.owedByFactoring > 0 && <ValueFormatting.Money value={totals.owedByFactoring} />}</td>
                <td className={classes.textRight}>{totals.owedToFactoring > 0 && <ValueFormatting.Money value={totals.owedToFactoring} />}</td>
                <td className={classes.textRight}>{totals.paidByFactoring > 0 && <ValueFormatting.Money value={totals.paidByFactoring} />}</td>
                <td className={classes.textRight}>{totals.paidToFactoring > 0 && <ValueFormatting.Money value={totals.paidToFactoring} />}</td>
                <td className={classes.textRight}><ValueFormatting.Money value={totals.owedToFactoring - totals.owedByFactoring - totals.paidToFactoring + totals.paidByFactoring} /></td>
            </tr>
        );
        invoiceRows.push(
            <tr className={classes.thickLine} key="iris-totals">
                <th className={classes.textRight}><b>IRIS Freight</b></th>
                <th className={classes.textRight}>{totals.owedByIRIS > 0 && <ValueFormatting.Money value={totals.owedByIRIS} />}</th>
                <th className={classes.textRight}>{totals.owedToIRIS > 0 && <ValueFormatting.Money value={totals.owedToIRIS} />}</th>
                <th className={classes.textRight}>{totals.paidByIRIS > 0 && <ValueFormatting.Money value={totals.paidByIRIS} />}</th>
                <th className={classes.textRight}>{totals.paidToIRIS > 0 && <ValueFormatting.Money value={totals.paidToIRIS} />}</th>
                <th className={classes.textRight}><ValueFormatting.Money value={totals.owedToIRIS - totals.owedByIRIS - totals.paidToIRIS + totals.paidByIRIS} /></th>
            </tr>
        );

        return (
            <table style={{ width: '100%', marginTop: 10, marginBottom: 10 }}>
                <thead>
                    <tr>
                        <th></th>
                        <th className={classes.textRight}>Owed By</th>
                        <th className={classes.textRight}>Owed To</th>
                        <th className={classes.textRight}>Paid By</th>
                        <th className={classes.textRight}>Paid To</th>
                        <th className={classes.textRight}>Balance Due</th>
                    </tr>
                </thead>
                <tbody>
                    {invoiceRows}
                </tbody>
            </table>
        );
    };

    const getInvoiceLineItemRowClassName = (record) => {
        if (record.status === 'COMPLETED') {
            return classes.completedRow;
        } else if (record.status === 'CANCELLED') {
            return classes.cancelledRow;
        } else if (record.approvalStatus === 'APPROVED') {
            return classes.approvedRow;
        } else {
            return classes.pendingRow;
        }
    };

    //#endregion

    return (
        <CanSee entityAction="READ" entityModel="LOAD" entityObject={load}>
            <LoadCompletionCard
                load={load}
                loadId={loadId}
                stops={stops}
                invoices={invoices}
                invoiceLineItems={invoiceLineItems}
                transactions={transactions}
                claims={claims}
                shipperId={shipperId}
                carrierId={carrierId}
                serviceType={serviceType}
                currentStatus={currentStatus}
                showInvoicingCards={true}
                showQuotes={true}
            />
            {newInvoiceLineItemComponents}
            {editInvoiceLineItemComponents}
            {editStatusInvoiceLineItemComponents}
            {editApprovalStatusInvoiceLineItemComponents}
            {cancelInvoiceLineItemComponents}
            {removeInvoiceLineItemComponents}
        </CanSee>
    );
};

export default LoadInvoice;