import React from 'react';
import DataRow from '../../components/DataRow/DataRow';
import { Col, Row } from "antd";
import DocumentList from '../../components/DocumentList/DocumentList';
import { useSelector } from "react-redux";
import { isBooleanTrue, isNotNullOrUndefined, isNumberNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';

const LoadAsset = ({ loadId, asset, showDocuments = false, ...props }) => {
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

    if (isObjectNotEmpty(asset)) {
        return (
            <>
                <Row>
                    <Col xs={24} md={24} lg={4} xl={4}>
                        {isStringNotEmpty(asset.irisId) ? <DataRow title="Asset ID" value={asset.irisId} dataType="String" /> : null}
                        {isStringNotEmpty(asset.assetType) ? <DataRow title="Asset Type" value={asset.assetType} dataType="AssetType" /> : null}
                        {isStringNotEmpty(asset.status) ? <DataRow title="Status" value={asset.status} dataType="AssetStatus" /> : null}
                        {isNotNullOrUndefined(asset.isContractorAsset) ? <DataRow title="Contractor Asset" value={asset.isContractorAsset} dataType="Boolean" /> : null}
                        {isNotNullOrUndefined(asset.isCarrierAsset) ? <DataRow title="Carrier Asset" value={asset.isCarrierAsset} dataType="Boolean" /> : null}
                        {isStringNotEmpty(asset.truckClass) ? <DataRow title="Truck Class" value={asset.truckClass} dataType="String" /> : null}
                        {isStringNotEmpty(asset.dotNum) ? <DataRow title="Dot #" value={asset.dotNum} dataType="String" /> : null}
                    </Col>
                    <Col xs={24} md={24} lg={4} xl={4}>
                        {isStringNotEmpty(asset.licensePlateNum) ? <DataRow title="License #" value={asset.licensePlateNum} dataType="String" /> : null}
                        {isStringNotEmpty(asset.licensePlateState) ? <DataRow title="License State" value={asset.licensePlateState} dataType="String" /> : null}
                        {isStringNotEmpty(asset.make) ? <DataRow title="Make" value={asset.make} dataType="String" /> : null}
                        {isStringNotEmpty(asset.model) ? <DataRow title="Model" value={asset.model} dataType="String" /> : null}
                        {isStringNotEmpty(asset.year) ? <DataRow title="Year" value={asset.year} dataType="String" /> : null}
                        {isStringNotEmpty(asset.color) ? <DataRow title="Color" value={asset.color} dataType="String" /> : null}
                        {isStringNotEmpty(asset.vin) ? <DataRow title="VIN" value={asset.vin} dataType="String" /> : null}
                    </Col>
                    <Col xs={24} md={24} lg={4} xl={4}>
                        {isNumberNotEmpty(asset.axles) ? <DataRow title="Axles" value={asset.axles} dataType="Integer" /> : null}
                        {isNumberNotEmpty(asset.range) ? <DataRow title="Range" value={asset.range} units={asset.rangeUnit} dataType="Distance" /> : null}
                        {isObjectNotEmpty(asset.trailerType) && isStringNotEmpty(asset.trailerType.description) ? <DataRow title="Trailer Type" value={asset.trailerType.description} dataType="String" /> : null}
                        {isNotNullOrUndefined(asset.isAvailable) ? <DataRow title="Is Available for Loads" value={asset.isAvailable} dataType="Boolean" /> : null}
                        {isNumberNotEmpty(asset.overallLength) ? <DataRow title="Overall Length" value={asset.overallLength} units={asset.overallLengthUnit} dataType="Length" /> : null}
                        {isNumberNotEmpty(asset.fuelTankCapacity) ? <DataRow title="Fuel Tank Capacity" value={asset.fuelTankCapacity} units={asset.fuelTankCapacityUnit} dataType="Volume" /> : null}
                    </Col>
                    <Col xs={24} md={24} lg={4} xl={4}>
                        {isNumberNotEmpty(asset.height) ? <DataRow title="Height" value={asset.height} units={asset.heightUnit} dataType="Length" /> : null}
                        {isNumberNotEmpty(asset.length) ? <DataRow title="Length" value={asset.length} units={asset.lengthUnit} dataType="Length" /> : null}
                        {isNumberNotEmpty(asset.weight) ? <DataRow title="Weight" value={asset.weight} units={asset.weightUnit} dataType="Weight" /> : null}
                        {isNumberNotEmpty(asset.width) ? <DataRow title="Width" value={asset.width} units={asset.widthUnit} dataType="Length" /> : null}
                        {isNumberNotEmpty(asset.loadCapacity) ? <DataRow title="Load Capacity" value={asset.loadCapacity} units={asset.loadCapacityUnit} dataType="Weight" /> : null}
                        {isNumberNotEmpty(asset.maxLoadWeight) ? <DataRow title="Max Load Weight" value={asset.maxLoadWeight} units={asset.maxLoadWeightUnit} dataType="Weight" /> : null}
                        {isStringNotEmpty(asset.truckOwnerType) ? <DataRow title="Truck Owner Type" value={asset.truckOwnerType} dataType="TruckOwnerType" /> : null}
                    </Col>
                </Row>
                {isAuthenticated === true && isBooleanTrue(showDocuments) ? (
                    <DocumentList
                        tableTitle="Asset Documents"
                        documentEntityType="ASSET"
                        documentEntityId={asset.id}
                        loadId={loadId}
                        style={{ marginTop: 24 }}
                    />
                ) : null}
            </>
        );
    } else {
        return null;
    }
};

export default LoadAsset;