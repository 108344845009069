import React, { useState, useMemo } from 'react';
import { Alert, Button, Drawer, Empty, message, Select, Spin } from "antd";
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import InvoiceUtils from '../../api/utils/invoiceUtils';
import moment from 'moment';
import { isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { selectListRecords } from '../../store/utility';
import FormItem from '../FormItem/FormItem';
import NewEmployee from '../NewEmployee/NewEmployee';
import LoadUtils from '../../api/utils/loadUtils';
import Invoice from '../Invoice/Invoice';
import { CanSee } from '../../shared/entitlements/entitlements';

const { Option } = Select;

const LoadEditRateConfirmation = ({ load, loadId, invoice, carrierId, serviceType, stops, invoiceLineItems, cancel, loading = false }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    //#endregion
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const userId = useSelector(state => state.auth.userId);
    const employees = useSelector(state => selectListRecords(state.orchestrator.employees, carrierId));
    const isLoadingUpdateInvoice = useSelector(state => state.invoices.isRecordUpdateLoading);
    const errorUpdateInvoice = useSelector(state => state.invoices.updateRecordError);

    //#endregion
    //#region useStates

    const [updatedInvoice, setUpdatedInvoice] = useState({});
    const [showNewEmployee, setShowNewEmployee] = useState(false);
    const [isSending, setIsSending] = useState(false);

    //#endregion
    //#region toggles

    const toggleNewEmployee = () => {
        setShowNewEmployee(!showNewEmployee);
    };

    //#endregion
    //#region onSubmit and onCancel

    const onSubmitUpdateAndSendInvoice = async (data) => {
        if (isObjectNotEmpty(invoice) && isListNotEmpty(employees) && isListNotEmpty(data.accountUserIds) && isObjectNotEmpty(load) && isStringNotEmpty(loadId) && isStringNotEmpty(serviceType) && isStringNotEmpty(userId)) {
            setIsSending(true);
            if (invoice.status !== 'COMPLETED' && invoice.status !== 'CANCELLED') {
                // the rate confirmation isn't signed yet so just update it

                const { totalAmount, lineItems, invoiceLineItemList } = InvoiceUtils.getInvoiceLineItemsAndTotal("RATE_CONFIRMATION", invoiceLineItems, serviceType === "TMS" ? "SHIPPER" : "STAFF", "CARRIER");

                let updatedRateConfirmation = {
                    invoiceDate: moment(),
                    amountDue: totalAmount,
                    amountDueUnit: 'USD',
                    invoiceLineItemIds: invoiceLineItemList.map(i => i.id),
                    status: 'SENT',
                    sentAt: moment(),
                    sentBy: userId
                };

                // save the pdf document to s3
                let newDocument = await InvoiceUtils.saveInvoiceAsDocument('RATE_CONFIRMATION', {...invoice, ...updatedRateConfirmation}, invoiceLineItemList, load, stops, true);

                let emailsWereSent = false;
                let toEmails = [];
                for (let i = 0; i < data.accountUserIds.length; i++) {
                    const accountUserId = data.accountUserIds[i];
                    const accountUser = employees.find(a => a.id === accountUserId);
                    if (isObjectNotEmpty(accountUser)) {
                        toEmails.push({ email: accountUser.email, name: accountUser.firstName + ' ' + accountUser.lastName });
                    }
                }

                // send rate confirmation emails to recipients
                emailsWereSent = await InvoiceUtils.sendRateConfirmationEmail('info@irisfreight.com', toEmails, {...invoice, ...updatedRateConfirmation}, load, stops, newDocument);

                // send load event if the status of the load is still CREATED, PENDING, or APPROVED
                if (emailsWereSent === true) {
                    message.success('Email(s) have been sent.');
                    dispatch(actionCreators.updateInvoice(invoice.id, updatedRateConfirmation, invoice, true));
                } else {
                    message.error('Failed to send email(s)');
                }
            }
            setIsSending(false);
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.updateLoadEventComplete());
        cancel();
    };

    const done = (load) => {
        if (LoadUtils.loadStatusIs(load.loadStatus, ["PENDING", "CREATED", "APPROVED"])) {
            dispatch(actionCreators.sendLoadEvent({
                loadId: load.id,
                eventType: 'LOAD_REQUEST_TO_CARRIER'
            }));
        }
        onCancel();
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateInvoiceErrorClear());
    }, []);

    useMemo(() => {
        if (isStringNotEmpty(carrierId)) {
            dispatch(actionCreators.getEmployees(carrierId, false));
        }
    }, [carrierId]);

    useMemo(() => {
            if (isObjectNotEmpty(invoice) && isStringNotEmpty(userId) && isStringNotEmpty(serviceType)) {
                const { totalAmount, lineItems, invoiceLineItemList } = InvoiceUtils.getInvoiceLineItemsAndTotal("RATE_CONFIRMATION", invoiceLineItems, serviceType === "TMS" ? "SHIPPER" : "STAFF", "CARRIER");

                let updatedRateConfirmation = {
                    ...invoice,
                    invoiceDate: moment(),
                    amountDue: totalAmount,
                    amountDueUnit: 'USD',
                    invoiceLineItemIds: invoiceLineItemList.map(i => i.id),
                    status: 'SENT',
                    sentAt: moment(),
                    sentBy: userId
                };

                setUpdatedInvoice(updatedRateConfirmation);
            }
    }, [invoice, userId, serviceType, invoiceLineItems]);

    useMemo(() => {
        if (isLoadingUpdateInvoice !== null && isLoadingUpdateInvoice === false && errorUpdateInvoice === null && isObjectNotEmpty(load)) {
            done(load);
        }
    }, [isLoadingUpdateInvoice, errorUpdateInvoice, load]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    //#endregion

    if (isObjectNotEmpty(updatedInvoice)) {
        return (
            <CanSee entityAction="UPDATE" entityModel="LOAD_RATE_CONFIRMATION" entityObject={load}>
                <FormProvider {...methods}>
                    <Form onSubmit={methods.handleSubmit(onSubmitUpdateAndSendInvoice)}>
                        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoadingUpdateInvoice === true && errorUpdateInvoice === null) || isSending === true || loading === true}>
                            <Fieldset legend="Updated Rate Confirmation">
                                <Invoice invoiceId={updatedInvoice.id} invoice={updatedInvoice} load={load} loadId={loadId} stops={stops} />
                            </Fieldset>
                            <Fieldset legend="Would you like the send the rate confirmation to the carrier you have assigned to this load?">
                                <FormItem {...formItemLayout} label="Choose Employee(s) to send Rate Confirmation to" required
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Select
                                            placeholder="Please Search and Select Employee(s)"
                                            mode="multiple"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChange(selected); }}
                                            value={value}
                                            name={name}
                                            showSearch={true}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Employees for you to choose from." />}
                                            ref={ref}
                                        >
                                            {employees.map(d => <Option value={d.id} key={d.id}>{d.firstName + ' ' + d.lastName} - Title: {d.title} - Email: {d.email}</Option>)}
                                        </Select>
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="accountUserIds"
                                />
                                <br />
                                <b>Employee Doesn't Exist Yet?</b>
                                <br />
                                <Button type='primary' onClick={toggleNewEmployee}>Add New Employee to Carrier Account</Button>
                            </Fieldset>
                        </Spin>
                        {errorUpdateInvoice && <Alert message={`${errorUpdateInvoice}`} type="error" />}
                        <FormButtons cancel={onCancel} disabled={(isLoadingUpdateInvoice === true && errorUpdateInvoice === null) || isSending === true} submitText="Update and Send the Rate Confirmation" />
                    </Form>
                </FormProvider>
                <Drawer
                    title={'Add New Employee'}
                    width={fullWidth > 720 ? fullWidth / 2 : 360}
                    onClose={toggleNewEmployee}
                    visible={showNewEmployee}
                    bodyStyle={{ paddingBottom: 80 }}
                    style={{ zIndex: 1000 }}
                    destroyOnClose={true}
                    closable={false}
                    maskClosable={false}
                >
                    <NewEmployee cancel={toggleNewEmployee} accountId={carrierId} />
                </Drawer>
            </CanSee>
        );
    } else {
        return null;
    }
};

export default LoadEditRateConfirmation;