import React, { useMemo } from 'react';
import { Alert, Spin } from "antd";
import { without } from "lodash";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import FormItemDisplay from '../FormItemDisplay/FormItemDisplay';
import Fieldset from '../FormFieldset/FormFieldset';
import { isBooleanTrue } from '../../shared/objectUtils';

const RemoveLoadAsset = ({ asset, load, isBundledLoad = false, cancel }) => {
    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loads.isRecordUpdateLoading);
    const error = useSelector(state => state.loads.updateRecordError);

    const onSubmit = () => {
        const updatedAssetIds = without(load.assetIds, asset.id);
        // console.log(updatedAssetIds);

        let payload = {
            assetIds: updatedAssetIds
        };
        // console.log(payload);

        if (isBooleanTrue(isBundledLoad)) {
            dispatch(actionCreators.updateBundledLoad(load.id, payload, load));
        } else {
            dispatch(actionCreators.updateLoad(load.id, payload));
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.updateLoadCancel());
        cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLoadErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                <Fieldset legend="Remove Asset from this Load">
                    <FormItemDisplay {...formItemLayout} label="Remove Asset">
                        <span>Are you sure you want to remove this Asset from the Load?</span>
                    </FormItemDisplay>
                </Fieldset>
                {error && <Alert message={`${error}`} type="error" />}
            </Spin>
            <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Remove Asset from Load" />
        </Form>
    );
};

export default RemoveLoadAsset;