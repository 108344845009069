import React, { useState, useMemo } from 'react';
import { Card, Tooltip, Button, Drawer, Spin } from "antd";
import classes from './LoadStatusCard.module.scss';
import EditLoadStatusApproved from '../../components/EditLoadStatusApproved/EditLoadStatusApproved';
import EditLoadStatusPendingRateConfirmation from '../../components/EditLoadStatusPendingRateConfirmation/EditLoadStatusPendingRateConfirmation';
import EditLoadStatusApproveRateConfirmation from '../../components/EditLoadStatusApproveRateConfirmation/EditLoadStatusApproveRateConfirmation';
import EditLoadStatusRejectRateConfirmation from '../../components/EditLoadStatusRejectRateConfirmation/EditLoadStatusRejectRateConfirmation';
import EditLoadStatusScheduled from '../../components/EditLoadStatusScheduled/EditLoadStatusScheduled';
import EditLoadStatusInTransit from '../../components/EditLoadStatusInTransit/EditLoadStatusInTransit';
import EditLoadStatusCompleted from '../../components/EditLoadStatusCompleted/EditLoadStatusCompleted';
import EditLoadStatusClosed from '../../components/EditLoadStatusClosed/EditLoadStatusClosed';
import EditLoadStatusReOpened from '../../components/EditLoadStatusReOpened/EditLoadStatusReOpened';
import EditStopStatusArriving from '../../components/EditStopStatusArriving/EditStopStatusArriving';
import EditStopStatusArrived from '../../components/EditStopStatusArrived/EditStopStatusArrived';
import EditStopStatusLoading from '../../components/EditStopStatusLoading/EditStopStatusLoading';
import EditStopStatusLoaded from '../../components/EditStopStatusLoaded/EditStopStatusLoaded';
import EditStopStatusUnloading from '../../components/EditStopStatusUnloading/EditStopStatusUnloading';
import EditStopStatusUnloaded from '../../components/EditStopStatusUnloaded/EditStopStatusUnloaded';
import EditStopStatusLayover from '../../components/EditStopStatusLayover/EditStopStatusLayover';
import EditStopStatusCompleted from '../../components/EditStopStatusCompleted/EditStopStatusCompleted';
import { useSelector } from 'react-redux';
import { isStringEmpty, isStringNotEmpty } from '../../shared/objectUtils';

const LoadStatusCard = ({ load, loadId, shipperId, carrierId, serviceType, stops, invoices, invoiceLineItems, transactions, claims, currentStop, currentStopType, currentStatus, displayStatus, missingPedigreeInformation, onTabChange, style = null, headStyle = null, ...props }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    const singularEntityName = "Load";

    //#endregion
    //#region useSelectors

    const loadEventUpdateStatus = useSelector(state => state.loadEvents.loadEventUpdateStatus);
    const entityType = useSelector(state => state.auth.entityType);

    //#endregion
    //#region useStates

    const [showEditLoadStatus, setShowEditLoadStatus] = useState(false);
    const [actionViewKey, setActionViewKey] = useState('');
    const [actions, setActions] = useState(null);

    //#endregion
    //#region toggles

    const toggleEditLoadStatus = () => {
        setShowEditLoadStatus(!showEditLoadStatus);
    };

    const openEditLoadStatus = () => {
        setShowEditLoadStatus(true);
    };

    const closeEditLoadStatus = () => {
        setShowEditLoadStatus(false);
    };

    //#endregion
    //#region displays

    const actionViews = {
        approved: <EditLoadStatusApproved cancel={toggleEditLoadStatus} load={load} stops={stops} invoices={invoices} invoiceLineItems={invoiceLineItems} loadId={loadId} shipperId={shipperId} carrierId={carrierId} />,
        pendingRateConfirmation: <EditLoadStatusPendingRateConfirmation cancel={toggleEditLoadStatus} load={load} loadId={loadId} shipperId={shipperId} carrierId={carrierId} serviceType={serviceType} stops={stops} invoiceLineItems={invoiceLineItems} />,
        approveRateConfirmation: <EditLoadStatusApproveRateConfirmation cancel={toggleEditLoadStatus} load={load} stops={stops} invoices={invoices} loadId={loadId} />,
        rejectRateConfirmation: <EditLoadStatusRejectRateConfirmation cancel={toggleEditLoadStatus} load={load} />,
        scheduled: <EditLoadStatusScheduled cancel={toggleEditLoadStatus} load={load} missingPedigreeInformation={missingPedigreeInformation} />,
        inTransit: <EditLoadStatusInTransit cancel={toggleEditLoadStatus} load={load} />,
        stopArriving: <EditStopStatusArriving cancel={toggleEditLoadStatus} load={load} stop={currentStop} />,
        stopArrived: <EditStopStatusArrived cancel={toggleEditLoadStatus} load={load} stop={currentStop} />,
        stopLoading: <EditStopStatusLoading cancel={toggleEditLoadStatus} load={load} stop={currentStop} />,
        stopLoaded: <EditStopStatusLoaded cancel={toggleEditLoadStatus} load={load} stop={currentStop} />,
        stopUnloading: <EditStopStatusUnloading cancel={toggleEditLoadStatus} load={load} stop={currentStop} />,
        stopUnloaded: <EditStopStatusUnloaded cancel={toggleEditLoadStatus} load={load} stop={currentStop} />,
        stopLayover: <EditStopStatusLayover cancel={toggleEditLoadStatus} load={load} stop={currentStop} />,
        stopCompleted: <EditStopStatusCompleted cancel={toggleEditLoadStatus} load={load} stop={currentStop} />,
        completed: <EditLoadStatusCompleted cancel={toggleEditLoadStatus} load={load} stops={stops} currentStatus={currentStatus} />,
        closed: <EditLoadStatusClosed cancel={toggleEditLoadStatus} load={load} stops={stops} invoices={invoices} invoiceLineItems={invoiceLineItems} transactions={transactions} claims={claims} />,
        reOpened: <EditLoadStatusReOpened cancel={toggleEditLoadStatus} load={load} />,
    };

    const actionTitles = {
        approved: "Approve Load",
        pendingRateConfirmation: "Send Rate Confirmation",
        approveRateConfirmation: "Accept Rate Confirmation",
        rejectRateConfirmation: "Reject Rate Confirmation",
        scheduled: "Update Load Status",
        inTransit: "Update Load Status",
        stopArriving: "Update Load Status",
        stopArrived: "Update Load Status",
        stopLoading: "Update Load Status",
        stopLoaded: "Update Load Status",
        stopUnloading: "Update Load Status",
        stopUnloaded: "Update Load Status",
        stopLayover: "Update Load Status",
        stopCompleted: "Update Load Status",
        completed: "Update Load Status",
        closed: "Close Load",
        reOpened: "Re-Open Load",
    };

    const getStatusActions = (currentStatus, carrierId, currentStopType, entityType, serviceType) => {
        switch (currentStatus) {
            case 'PENDING':
                if (entityType === "STAFF" || entityType === 'SHIPPER') {
                    setActions(
                        <Tooltip placement="top" title={"Approve Load"}><Button key="1" type="primary" onClick={(e) => { e.stopPropagation(); setActionViewKey('approved'); toggleEditLoadStatus(); }}>Approve Load</Button></Tooltip>
                    );
                } else {
                    setActions(null);
                }
                break;
            case 'APPROVED':
                if (entityType === "STAFF" || (entityType === "SHIPPER" && serviceType === "TMS")) {
                    setActions(
                        <Tooltip placement="top" title={"Send Rate Confirmation"}><Button key="1" type="primary" disabled={isStringEmpty(carrierId)} onClick={(e) => { e.stopPropagation(); setActionViewKey('pendingRateConfirmation'); toggleEditLoadStatus(); }}>Send Rate Confirmation</Button></Tooltip>
                    );
                } else {
                    setActions(null);
                }
                break;
            case 'PENDING_RATE_CONFIRMATION':
                if (entityType === "STAFF" || entityType === "CARRIER" || entityType === "BROKER") {
                    setActions(
                        <>
                            <Tooltip placement="top" title={"Reject Rate Confirmation"}><Button key="2" type="danger" disabled={isStringEmpty(carrierId)} onClick={(e) => { e.stopPropagation(); setActionViewKey('rejectRateConfirmation'); toggleEditLoadStatus(); }} style={{ marginRight: 8 }}>Reject</Button></Tooltip>
                            <Tooltip placement="top" title={"Accept Rate Confirmation"}><Button key="1" type="primary" disabled={isStringEmpty(carrierId)} onClick={(e) => { e.stopPropagation(); setActionViewKey('approveRateConfirmation'); toggleEditLoadStatus(); }}>Accept</Button></Tooltip>
                        </>
                    );
                } else {
                    setActions(null);
                }
                break;
            case 'BOOKED':
                if (entityType === "STAFF" || entityType === 'BROKER') {
                    setActions(
                        <Tooltip placement="top" title={"Change to Scheduled"}><Button key="1" type="primary" disabled={isStringEmpty(carrierId)} onClick={(e) => { e.stopPropagation(); setActionViewKey('scheduled'); toggleEditLoadStatus(); }}>Scheduled</Button></Tooltip>
                    );
                } else if (entityType === 'CARRIER') {
                    setActions(
                        <Tooltip placement="top" title={"Dispatch Load"}><Button key="1" type="primary" disabled={isStringEmpty(carrierId)} onClick={(e) => { e.stopPropagation(); setActionViewKey('scheduled'); toggleEditLoadStatus(); }}>Dispatch Load</Button></Tooltip>
                    );
                } else {
                    setActions(null);
                }
                break;
            case 'SCHEDULED':
                if (entityType === "STAFF" || entityType === "BROKER") {
                    setActions(
                        <Tooltip placement="top" title={"Start Trip"}><Button key="1" type="primary" disabled={isStringEmpty(carrierId)} onClick={(e) => { e.stopPropagation(); setActionViewKey('inTransit'); toggleEditLoadStatus(); }}>Start Trip</Button></Tooltip>
                    );
                } else {
                    setActions(null);
                }
                break;
            case 'DEAD_HEADING':
                if (entityType === "STAFF" || entityType === "BROKER") {
                    if (currentStopType === 'PICK_UP' || currentStopType === 'DROP_OFF') {
                        setActions(
                            <>
                                <Tooltip placement="top" title={"Layover"}><Button key="2" type="danger" onClick={(e) => { e.stopPropagation(); setActionViewKey('stopLayover'); toggleEditLoadStatus(); }} style={{ marginRight: 8 }}>Layover</Button></Tooltip>
                                <Tooltip placement="top" title={"Arriving"}><Button key="1" type="primary" onClick={(e) => { e.stopPropagation(); setActionViewKey('stopArriving'); toggleEditLoadStatus(); }}>Arriving</Button></Tooltip>
                            </>
                        );
                    } else {
                        setActions(
                            <Tooltip placement="top" title={"Arrived"}><Button key="1" type="primary" onClick={(e) => { e.stopPropagation(); setActionViewKey('stopArrived'); toggleEditLoadStatus(); }}>Arrived</Button></Tooltip>
                        );
                    }
                } else {
                    setActions(null);
                }
                break;
            case 'LAYOVER':
                if (entityType === "STAFF" || entityType === "BROKER") {
                    setActions(
                        <Tooltip placement="top" title={"Arrived"}><Button key="1" type="primary" onClick={(e) => { e.stopPropagation(); setActionViewKey('stopArrived'); toggleEditLoadStatus(); }}>Arrived</Button></Tooltip>
                    );
                } else {
                    setActions(null);
                }
                break;
            case 'IN_TRANSIT':
                if (entityType === "STAFF" || entityType === "BROKER") {
                    if (currentStopType === 'PICK_UP' || currentStopType === 'DROP_OFF') {
                        setActions(
                            <>
                                <Tooltip placement="top" title={"Layover"}><Button key="2" type="danger" onClick={(e) => { e.stopPropagation(); setActionViewKey('stopLayover'); toggleEditLoadStatus(); }} style={{ marginRight: 8 }}>Layover</Button></Tooltip>
                                <Tooltip placement="top" title={"Arriving"}><Button key="1" type="primary" onClick={(e) => { e.stopPropagation(); setActionViewKey('stopArriving'); toggleEditLoadStatus(); }}>Arriving</Button></Tooltip>
                            </>
                        );
                    } else {
                        setActions(
                            <Tooltip placement="top" title={"Arrived"}><Button key="1" type="primary" onClick={(e) => { e.stopPropagation(); setActionViewKey('stopArrived'); toggleEditLoadStatus(); }}>Arrived</Button></Tooltip>
                        );
                    }
                } else {
                    setActions(null);
                }
                break;
            case 'DROP_OFF_COMPLETED':
                if (entityType === "STAFF" || entityType === "BROKER") {
                    setActions(
                        <Tooltip placement="top" title={"Complete Load"}><Button key="1" type="primary" onClick={(e) => { e.stopPropagation(); setActionViewKey('completed'); openEditLoadStatus(); }}>Complete Load</Button></Tooltip>
                    );
                } else {
                    setActions(null);
                }
                break;
            case 'ARRIVING':
                if (entityType === "STAFF" || entityType === "BROKER") {
                    setActions(
                        <>
                            <Tooltip placement="top" title={"Layover"}><Button key="2" type="danger" onClick={(e) => { e.stopPropagation(); setActionViewKey('stopLayover'); toggleEditLoadStatus(); }} style={{ marginRight: 8 }}>Layover</Button></Tooltip>
                            <Tooltip placement="top" title={"Arrived"}><Button key="1" type="primary" onClick={(e) => { e.stopPropagation(); setActionViewKey('stopArrived'); toggleEditLoadStatus(); }}>Arrived</Button></Tooltip>
                        </>
                    );
                } else {
                    setActions(null);
                }
                break;
            case 'ARRIVED':
                if (entityType === "STAFF" || entityType === "BROKER") {
                    if (currentStopType === 'PICK_UP') {
                        setActions(
                            <>
                                <Tooltip placement="top" title={"Layover"}><Button key="2" type="danger" onClick={(e) => { e.stopPropagation(); setActionViewKey('stopLayover'); toggleEditLoadStatus(); }} style={{ marginRight: 8 }}>Layover</Button></Tooltip>
                                <Tooltip placement="top" title={"Loading"}><Button key="1" type="primary" onClick={(e) => { e.stopPropagation(); setActionViewKey('stopLoading'); toggleEditLoadStatus(); }}>Loading</Button></Tooltip>
                            </>
                        );
                    } else if (currentStopType === 'DROP_OFF') {
                        setActions(
                            <>
                                <Tooltip placement="top" title={"Layover"}><Button key="2" type="danger" onClick={(e) => { e.stopPropagation(); setActionViewKey('stopLayover'); toggleEditLoadStatus(); }} style={{ marginRight: 8 }}>Layover</Button></Tooltip>
                                <Tooltip placement="top" title={"Unloading"}><Button key="1" type="primary" onClick={(e) => { e.stopPropagation(); setActionViewKey('stopUnloading'); toggleEditLoadStatus(); }}>Unloading</Button></Tooltip>
                            </>
                        );
                    }
                } else {
                    setActions(null);
                }
                break;
            case 'LOADING':
                if (entityType === "STAFF" || entityType === "BROKER") {
                    setActions(
                        <Tooltip placement="top" title={"Loaded"}><Button key="1" type="primary" onClick={(e) => { e.stopPropagation(); setActionViewKey('stopLoaded'); toggleEditLoadStatus(); }}>Loaded</Button></Tooltip>
                    );
                } else {
                    setActions(null);
                }
                break;
            case 'LOADED':
                if (entityType === "STAFF" || entityType === "BROKER") {
                    setActions(
                        <Tooltip placement="top" title={"Complete Stop"}><Button key="1" type="primary" onClick={(e) => { e.stopPropagation(); setActionViewKey('stopCompleted'); toggleEditLoadStatus(); }}>Complete Stop</Button></Tooltip>
                    );
                } else {
                    setActions(null);
                }
                break;
            case 'UNLOADING':
                if (entityType === "STAFF" || entityType === "BROKER") {
                    setActions(
                        <Tooltip placement="top" title={"Unloaded"}><Button key="1" type="primary" onClick={(e) => { e.stopPropagation(); setActionViewKey('stopUnloaded'); toggleEditLoadStatus(); }}>Unloaded</Button></Tooltip>
                    );
                } else {
                    setActions(null);
                }
                break;
            case 'UNLOADED':
                if (entityType === "STAFF" || entityType === "BROKER") {
                    setActions(
                        <Tooltip placement="top" title={"Complete Stop"}><Button key="1" type="primary" onClick={(e) => { e.stopPropagation(); setActionViewKey('stopCompleted'); toggleEditLoadStatus(); }}>Complete Stop</Button></Tooltip>
                    );
                } else {
                    setActions(null);
                }
                break;
            case 'AT_STOP':
                if (entityType === "STAFF" || entityType === "BROKER") {
                    setActions(
                        <Tooltip placement="top" title={"Complete Stop"}><Button key="1" type="primary" onClick={(e) => { e.stopPropagation(); setActionViewKey('stopCompleted'); toggleEditLoadStatus(); }}>Complete Stop</Button></Tooltip>
                    );
                } else {
                    setActions(null);
                }
                break;
            case 'COMPLETED':
                if (entityType === "STAFF" || entityType === "BROKER") {
                    setActions(
                        <Tooltip placement="top" title={"Close Load"}><Button key="1" type="primary" onClick={(e) => { e.stopPropagation(); setActionViewKey('closed'); toggleEditLoadStatus(); }}>Close Load</Button></Tooltip>
                    );
                } else {
                    setActions(null);
                }
                break;
            case 'CLOSED':
                if (entityType === "STAFF" || entityType === "BROKER") {
                    setActions(
                        <Tooltip placement="top" title={"Re-Open Load"}><Button key="1" type="primary" onClick={(e) => { e.stopPropagation(); setActionViewKey('reOpened'); toggleEditLoadStatus(); }}>Re-Open Load</Button></Tooltip>
                    );
                } else {
                    setActions(null);
                }
                break;
            case 'CANCELLED':
                setActions(null);
                break;
            case 'REOPENED':
                if (entityType === 'STAFF' || entityType === "BROKER") {
                    setActions(
                        <Tooltip placement="top" title={"Close Load"}><Button key="1" type="primary" onClick={(e) => { e.stopPropagation(); setActionViewKey('closed'); toggleEditLoadStatus(); }}>Close Load</Button></Tooltip>
                    );
                } else {
                    setActions(null);
                }
                break;
            default:
                if (entityType === "STAFF" || entityType === "BROKER") {
                    setActions(null);
                } else {
                    setActions(null);
                }
        }
    };

    const loadStatusComponent = (actionViewKey) => {
        return (
            <Drawer
                title={isStringNotEmpty(actionViewKey) ? actionTitles[actionViewKey] : "Update " + singularEntityName + " Status"}
                width={fullWidth > 720 ? fullWidth / 2 : 360}
                onClose={closeEditLoadStatus}
                visible={showEditLoadStatus === true}
                bodyStyle={{ paddingBottom: 80 }}
                style={{ zIndex: 1000 }}
                destroyOnClose={true}
                closable={false}
                maskClosable={false}
            >
                {actionViews[actionViewKey]}
            </Drawer>
        );
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        getStatusActions(currentStatus, carrierId, currentStopType, entityType, serviceType);
    }, [currentStatus, carrierId, currentStopType, entityType, serviceType]);

    //#endregion

    return (
        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={loadEventUpdateStatus === 'PENDING'}>
            <Card bordered={true} title={`Current Status: ${displayStatus}`} className={classes.card} style={style} headStyle={headStyle} extra={actions}>
                {props.children}
            </Card>
            {loadStatusComponent(actionViewKey)}
        </Spin>
    );
};

export default LoadStatusCard;