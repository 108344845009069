import React, { useMemo } from 'react';
import { Alert, Radio, Spin } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import { isObjectNotEmpty, removeEmpty } from '../../shared/objectUtils';
import Fieldset from '../FormFieldset/FormFieldset';
import FormItemPointOfContact from '../FormItemPointOfContact/FormItemPointOfContact';
import FormItemAddress from '../FormItemAddress/FormItemAddress';

const EditMissingAccountInformation = ({ account, missingAccountInformation, cancel }) => {
    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.accounts.isRecordUpdateLoading);
    const error = useSelector(state => state.accounts.updateRecordError);
    const entityType = useSelector(state => state.auth.entityType);

    const onSubmit = (data) => {
        let payload = removeEmpty(data);
        if (isObjectNotEmpty(payload)) {
            dispatch(actionCreators.updateAccount(missingAccountInformation.entityId, payload, null, null, null, null, null, null, null, null, entityType));
        } else {
            onCancel();
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.updateAccountCancel());
        cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateAccountErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    if (isObjectNotEmpty(missingAccountInformation)) {
        return (
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                        {missingAccountInformation.missingField === 'pointOfContact' ? (
                            <Fieldset legend="Point of Contact (* indicates a required field)">
                                <FormItemPointOfContact
                                    format="vertical"
                                    required={true}
                                    name="pointOfContact"
                                    defaultValue={account.pointOfContact}
                                />
                            </Fieldset>
                        ) : null}
                        {missingAccountInformation.missingField === 'companyAddress' ? (
                            <Fieldset legend="Company Address (* indicates a required field)">
                                <FormItemAddress
                                    format="vertical"
                                    required={true}
                                    name="companyAddress"
                                    defaultValue={account.companyAddress}
                                    isPostalAddress={true}
                                />
                            </Fieldset>
                        ) : null}
                        {missingAccountInformation.missingField === 'accountsPayable' ? (
                            <Fieldset legend="Accounts Payable Contact (* indicates a required field)">
                                <FormItemPointOfContact
                                    format="vertical"
                                    required={true}
                                    name="accountsPayable"
                                    defaultValue={account.accountsPayable}
                                />
                            </Fieldset>
                        ) : null}
                        {missingAccountInformation.missingField === 'accountsPayableAddress' ? (
                            <Fieldset legend="Accounts Payable Address (* indicates a required field)">
                                <FormItemAddress
                                    format="vertical"
                                    required={true}
                                    name="accountsPayableAddress"
                                    defaultValue={account.accountsPayableAddress}
                                    isPostalAddress={true}
                                />
                            </Fieldset>
                        ) : null}
                        {missingAccountInformation.missingField === 'accountsReceivable' ? (
                            <Fieldset legend="Accounts Receivable Contact (* indicates a required field)">
                                <FormItemPointOfContact
                                    format="vertical"
                                    required={true}
                                    name="accountsReceivable"
                                    defaultValue={account.accountsReceivable}
                                />
                            </Fieldset>
                        ) : null}
                        {missingAccountInformation.missingField === 'accountsReceivableAddress' ? (
                            <Fieldset legend="Accounts Receivable Address (* indicates a required field)">
                                <FormItemAddress
                                    format="vertical"
                                    required={true}
                                    name="accountsReceivableAddress"
                                    defaultValue={account.accountsReceivableAddress}
                                    isPostalAddress={true}
                                />
                            </Fieldset>
                        ) : null}
                        {missingAccountInformation.missingField === 'email' ? (
                            <Fieldset legend="Company Contact Information (* indicates a required field)">
                                <FormItemPointOfContact
                                    format="vertical"
                                    required={true}
                                    defaultValue={account}
                                    emailFieldLabel="Company Email"
                                    showName={false}
                                    showEmail={true}
                                    showPhone={false}
                                />
                            </Fieldset>
                        ) : null}
                        {missingAccountInformation.missingField === 'phone' ? (
                            <Fieldset legend="Company Contact Information (* indicates a required field)">
                                <FormItemPointOfContact
                                    format="vertical"
                                    required={true}
                                    defaultValue={account}
                                    phoneFieldLabel="Company Day Time Phone #"
                                    showPhone={true}
                                    showEmail={false}
                                    showName={false}
                                />
                            </Fieldset>
                        ) : null}
                        {missingAccountInformation.missingField === 'afterHoursPhone' ? (
                            <Fieldset legend="Company Contact Information (* indicates a required field)">
                                <FormItemPointOfContact
                                    format="vertical"
                                    required={true}
                                    defaultValue={account}
                                    phoneFieldLabel="Company After Hours Phone #"
                                    showPhone={true}
                                    showEmail={false}
                                    showName={false}
                                    phoneFieldName="afterHoursPhone"
                                    phoneExtFieldName="afterHoursPhoneExt"
                                />
                            </Fieldset>
                        ) : null}
                        {missingAccountInformation.missingField === 'preferredPaymentMethod' ? (
                            <Fieldset legend="Accounts Receivable (* indicates a required field)">
                                <FormItem {...formItemLayout} label="Preferred Payment Method" required format="vertical"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Radio.Group onBlur={onBlur} onChange={(e) => { onChange(e.target.value); }} value={value} name={name} buttonStyle="solid" ref={ref}>
                                            <Radio.Button value="CHECK">Paper Check</Radio.Button>
                                            <Radio.Button value="DIRECT_DEPOSIT">Direct Deposit</Radio.Button>
                                            <Radio.Button value="QUICK_PAY">Quick Pay</Radio.Button>
                                            <Radio.Button value="FACTORING">Factoring</Radio.Button>
                                        </Radio.Group>
                                    )}
                                    rules={{ required: "Required Field" }}
                                    name="preferredPaymentMethod"
                                    defaultValue={account.preferredPaymentMethod ? account.preferredPaymentMethod : undefined}
                                />
                            </Fieldset>
                        ) : null}
                        {error && <Alert message={`${error}`} type="error" />}
                    </Spin>
                    <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText={`Update Account`} />
                </Form>
            </FormProvider>
        );
    } else {
        return null;
    }
};

export default EditMissingAccountInformation;