import React from 'react';
import DocumentList from "../../components/DocumentList/DocumentList";
import { isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import StringFormatter from '../../shared/stringFormatter';
import LoadStopUtils from '../../api/utils/loadStopUtils';
import { useSelector } from 'react-redux';

const stringFormatter = new StringFormatter();

const LoadDocuments = ({ load, shipperId, carrierId, stops }) => {
    //#region useSelectors

    const entityType = useSelector(state => state.auth.entityType);
    const entityId = useSelector(state => state.auth.entityId);

    //#endregion

    if (isObjectNotEmpty(load) && isListNotEmpty(stops)) {
        return (
            <>
                <DocumentList
                    tableTitle="Load Documents"
                    documentEntityType="LOAD"
                    documentEntityId={load.id}
                    needsCarrierWashoutLog={isObjectNotEmpty(load) && isListNotEmpty(load.equipmentSpecifics) && load.equipmentSpecifics.includes('CARRIER_WASHOUT') ? true : false}
                    loadId={load.id}
                    load={load}
                    key={`load-documents-${load.id}`}
                />
                {stops.map((stop) => {
                    const stopName = LoadStopUtils.getStopLocationNameDisplay(stop, shipperId, carrierId, entityType, entityId);
                    const locationCityState = LoadStopUtils.getStopLocationCityStateDisplay(stop);
                    return (
                        <DocumentList
                            tableTitle={`Documents for Stop # ${stop.sequence + 1} - ${stringFormatter.toFormattedString("StopType", stop.stopType, null)}${isStringNotEmpty(stopName) ? ' - ' + stopName : ""}${isStringNotEmpty(locationCityState) ? " - " + locationCityState : ""}`}
                            documentEntityId={stop.id}
                            documentEntityType="STOP"
                            documentEntitySubType={stop.stopType}
                            needsLumperFeeReceipt={stop.hasLumperFee}
                            loadId={load.id}
                            load={load}
                            style={{ marginTop: 24 }}
                            key={`stop-documents-${stop.id}`}
                        />
                    );
                })}
            </>
        );
    } else {
        return null;
    }
};

export default LoadDocuments;