import axiosAuthenticated from "../../api/axiosAuthenticated";
import axios from 'axios';

const tmsPath = '/tms/route';
const tmsGeocodingPath = '/tms/geoCoding';
const shipperTrackerPath = '/shipperTracker';
const tmsLocationPath = '/tms/locations';
const tmsDirectionsPath = '/tms/directions';

//#region States Methods

export const getTMSRouteDetails = async (payload) => {
    const res = await axiosAuthenticated.post(tmsPath, payload);
    if (res && res.status === 200) {
        return res.data;
    }

    return null;
};

export const getGeocoding = async (payload) => {
    const res = await axiosAuthenticated.post(tmsGeocodingPath, payload);
    if (res && res.status === 200) {
        return res.data;
    }

    return null;
};

export const getShipperTracker = async (loadId, config = {}) => {
    const res = await axios.get(shipperTrackerPath + `?loadId=${loadId}`, config)
    if (res && res.status === 200) {
        return res.data;
    }

    return null;
};

export const searchLocation = async (address, config = {}) => {
    const res = await axiosAuthenticated.get(tmsLocationPath + `?address=${address}`, config)
    if (res && res.status === 200) {
        return res.data;
    }

    return [];
};

export const getDirections = async (payload) => {
    const res = await axiosAuthenticated.post(tmsDirectionsPath, payload);
    if (res && res.status === 200) {
        return res.data;
    }

    return null;
};

//#endregion