import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { DownOutlined, InfoOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import classes from './OpenLoads.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools } from '@fortawesome/free-solid-svg-icons';
import StringFormatter from '../../shared/stringFormatter';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { compareByAlph } from '../../shared/tableUtils';
import DataTable from '../../components/DataTable/DataTable';
import NewLoadModal from '../../components/NewLoadModal/NewLoadModal';
import { isListNotEmpty, isObjectNotEmpty, isStringEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import ColumnSearchFilter from '../../shared/tableColumnSearchFilter';
import { Can, CanDo } from '../../shared/entitlements/entitlements';
import { selectListIsLoading, selectListPagination, selectListRecords } from '../../store/utility';
import LoadUtils from '../../api/utils/loadUtils';

const stringFormatter = new StringFormatter();
const columnSearchFilter = new ColumnSearchFilter();

const OpenLoads = ({ shipperId, carrierId, driverId, assignedAccountRepUserId, brokerId, loadLaneId = null, laneId = null, ...props }) => {
    //#region constants

    const singularEntityName = "Open Load";
    const pluralEntityName = "Open Loads";
    const filter = "open";

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const entityType = useSelector(state => state.auth.entityType);
    const entityId = useSelector(state => state.auth.entityId);
    const records = useSelector(state => selectListRecords(state.loads.lists, filter));
    const isLoading = useSelector(state => selectListIsLoading(state.loads.lists, filter));
    const pagination = useSelector(state => selectListPagination(state.loads.lists, filter));

    //#endregion
    //#region useStates

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showDuplicateEntity, setShowDuplicateEntity] = useState(false);
    const [searchText, setSearchText] = useState({ irisId: '' });

    //#endregion
    //#region goTos

    const goToLoad = (id) => {
        props.history.push({ pathname: `/loads/${id}` }, {
            previousPageTitle: "Open Loads",
            previousPageLocation: props.location,
            previousBreadcrumbs: props.breadcrumbs,
        });
    };

    //#endregion
    //#region toggles

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleDuplicateEntity = () => {
        setShowDuplicateEntity(!showDuplicateEntity);
    };

    //#endregion
    //#region table methods

    const refreshTable = () => {
        if (isStringNotEmpty(entityType) && isStringNotEmpty(entityId)) {
            let searchParams = {
                page: 1,
                size: 5,
                sort: 'pickUpDateTime',
                order: 'asc',
                isDeleted: false,
                // eta: true
            };

            let defaultLoadStatusFilter = [];
            if (entityType === "DRIVER") {
                searchParams.driverIds = entityId;
                defaultLoadStatusFilter = ['BOOKED'];
            } else if (entityType === "SHIPPER") {
                searchParams.shipperId = entityId;
                defaultLoadStatusFilter = ['CREATED', 'PENDING', 'APPROVED', 'PENDING_RATE_CONFIRMATION'];
            } else if (entityType === "BROKER") {
                searchParams.brokerId = entityId;
                defaultLoadStatusFilter = ['CREATED', 'PENDING', 'APPROVED', 'PENDING_RATE_CONFIRMATION'];
            } else if (entityType === "CARRIER") {
                searchParams.assignedCarrierId = entityId;
                defaultLoadStatusFilter = ['BOOKED'];

                if (isStringNotEmpty(driverId)) {
                    searchParams.driverIds = driverId;
                }
            } else if (entityType === "STAFF") {
                defaultLoadStatusFilter = ['CREATED', 'PENDING', 'APPROVED', 'PENDING_RATE_CONFIRMATION'];

                if (isStringNotEmpty(shipperId)) {
                    searchParams.shipperId = shipperId;
                }

                if (isStringNotEmpty(brokerId)) {
                    searchParams.brokerId = brokerId;
                }

                if (isStringNotEmpty(carrierId)) {
                    searchParams.assignedCarrierId = carrierId;
                    defaultLoadStatusFilter = ['BOOKED']; // if this is just for carrier loads then open loads should just show booked loads for the carrier
                }

                if (isStringNotEmpty(driverId)) {
                    searchParams.driverIds = driverId;
                }

                if (isStringNotEmpty(assignedAccountRepUserId)) {
                    searchParams.assignedAccountRepUserIds = assignedAccountRepUserId;
                }
            }

            if (isStringNotEmpty(loadLaneId)) {
                searchParams.loadLaneId = loadLaneId;
            }

            if (isStringNotEmpty(laneId)) {
                searchParams.laneId = laneId;
            }

            searchParams.loadStatus = defaultLoadStatusFilter.join('|');

            dispatch(actionCreators.fetchLoadList(filter, searchParams));
        }
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchLoadListSuccess(filter, { params: { pagination: pager } }));

        const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';

        let searchParams = {
            page: pagination.current,
            size: pagination.pageSize,
            sort: sorter.field,
            order: sortOrder,
            isDeleted: false,
            // eta: true
        };

        let defaultLoadStatusFilter = [];
        if (entityType === "DRIVER") {
            searchParams.driverIds = entityId;
            defaultLoadStatusFilter = ['BOOKED'];
        } else if (entityType === "SHIPPER" || entityType === "BROKER") {
            searchParams.shipperId = entityId;
            defaultLoadStatusFilter = ['CREATED', 'PENDING', 'APPROVED', 'PENDING_RATE_CONFIRMATION'];
        } else if (entityType === "BROKER") {
            searchParams.brokerId = entityId;
            defaultLoadStatusFilter = ['CREATED', 'PENDING', 'APPROVED', 'PENDING_RATE_CONFIRMATION'];
        } else if (entityType === "CARRIER") {
            searchParams.assignedCarrierId = entityId;
            defaultLoadStatusFilter = ['BOOKED'];

            if (isStringNotEmpty(driverId)) {
                searchParams.driverIds = driverId;
            }
        } else if (entityType === "STAFF") {
            defaultLoadStatusFilter = ['CREATED', 'PENDING', 'APPROVED', 'PENDING_RATE_CONFIRMATION'];

            if (isStringNotEmpty(shipperId)) {
                searchParams.shipperId = shipperId;
            }

            if (isStringNotEmpty(brokerId)) {
                searchParams.brokerId = brokerId;
            }

            if (isStringNotEmpty(carrierId)) {
                searchParams.assignedCarrierId = carrierId;
                defaultLoadStatusFilter = ['BOOKED']; // if this is just for carrier loads then open loads should just show booked loads for the carrier
            }

            if (isStringNotEmpty(driverId)) {
                searchParams.driverIds = driverId;
            }

            if (isStringNotEmpty(assignedAccountRepUserId)) {
                searchParams.assignedAccountRepUserIds = assignedAccountRepUserId;
            }
        }

        if (isListNotEmpty(filters.loadStatus)) {
            searchParams.loadStatus = filters.loadStatus.join('|');
        } else {
            searchParams.loadStatus = defaultLoadStatusFilter.join('|');
        }

        if (isListNotEmpty(filters.irisId)) {
            searchParams['irisId:contains'] = filters.irisId[0];
        }

        if (isStringNotEmpty(loadLaneId)) {
            searchParams.loadLaneId = loadLaneId;
        }

        if (isStringNotEmpty(laneId)) {
            searchParams.laneId = laneId;
        }

        dispatch(actionCreators.fetchLoadList(filter, searchParams));
    };

    //#endregion
    //#region table displays

    const menu = (record) => {
        return (
            <Menu>
                {CanDo({ entityAction: 'DUPLICATE', entityModel: 'LOAD', entityObject: record }) &&
                    <Menu.Item key="duplicateLoad" onClick={(e) => { e.domEvent.stopPropagation(); e.domEvent.preventDefault(); setSelectedRecord(record); toggleDuplicateEntity(); }}>
                        <UploadOutlined style={{ marginRight: 8 }} />
                        <span>Duplicate Load</span>
                    </Menu.Item>
                }
                {CanDo({ entityAction: 'READ', entityModel: 'LOAD', entityObject: record }) &&
                    <Menu.Item key="goToDashboard" onClick={(e) => { e.domEvent.stopPropagation(); e.domEvent.preventDefault(); setSelectedRecord(record); goToLoad(record.id); }}>
                        <InfoOutlined style={{ marginRight: 8 }} />
                        <span>View Load Details</span>
                    </Menu.Item>
                }
            </Menu>
        );
    };

    const columns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            className: classes.columnNoBreak,
            width: 50,
            render: (text, record) => {
                return (
                    <Dropdown overlay={menu(record)} trigger={['click']}>
                        <Button>Actions <DownOutlined /></Button>
                    </Dropdown>
                );
            },
            align: 'center',
        },
        {
            title: 'Load Id',
            dataIndex: 'irisId',
            key: 'irisId',
            ...columnSearchFilter.getColumnSearchProps('irisId', 'Load Id', searchText, setSearchText)
        },
        {
            title: 'Load Name',
            dataIndex: 'parentName',
            key: 'parentName',
            render: (text, record) => { return LoadUtils.getLoadName(record); },
        },
        {
            title: 'Pick-Up Date',
            dataIndex: 'pickUpDateTime',
            key: 'pickUpDateTime',
            sorter: (a, b) => compareByAlph(a.pickUpDateTime, b.pickUpDateTime),
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'ascend',
            render: (text, record) => { return record.origin !== undefined && record.origin !== null ? stringFormatter.toFormattedString("MomentDate", text, null, record.origin.timeZone) : ''; },
        },
        {
            title: 'Origin',
            dataIndex: ['origin', 'stopLocation', 'name'],
            key: 'origin.stopLocation.name',
        },
        {
            title: 'Destination',
            dataIndex: ['destination', 'stopLocation', 'name'],
            key: 'destination.stopLocation.name',
        },
        {
            title: 'Type',
            dataIndex: ['equipmentNeeds', 'trailerType', 'description'],
            key: 'equipmentNeeds.trailerType.description',
        },
        {
            title: 'Length',
            dataIndex: ['equipmentNeeds', 'trailerLength'],
            key: 'equipmentNeeds.trailerLength',
            render: (text, record) => { return stringFormatter.toFormattedString("Length", text, record.equipmentNeeds.trailerLengthUnit); },
        },
        {
            title: 'Max Weight',
            dataIndex: ['equipmentNeeds', 'trailerWeightCapacity'],
            key: 'equipmentNeeds.trailerWeightCapacity',
            render: (text, record) => { return stringFormatter.toFormattedString("Weight", text, record.equipmentNeeds.trailerWeightCapacityUnit); },
        },
        {
            title: 'ETA',
            dataIndex: 'eta',
            key: 'eta',
            render: (text, record) => {
                if (LoadUtils.loadStatusIs(record.loadStatus, ['IN_TRANSIT', 'AT_STOP'])) {
                    return stringFormatter.toFormattedString("MomentDateTime", text, null, record.etaTimeZone);
                } else {
                    return 'N/A';
                }
            },
        }
    ];

    if ((CanDo({ staffOnly: true }) && isStringEmpty(carrierId)) || CanDo({ entityTypes: ['SHIPPER'] })) {
        columns.push({
            title: 'Status',
            dataIndex: 'loadStatus',
            key: 'loadStatus',
            filters: [
                {
                    text: 'Created',
                    value: 'CREATED',
                },
                {
                    text: 'Pending',
                    value: 'PENDING',
                },
                {
                    text: 'Approved',
                    value: 'APPROVED',
                },
                {
                    text: 'Pending Rate Confirmation',
                    value: 'PENDING_RATE_CONFIRMATION',
                }
            ],
            filterMultiple: true,
            defaultFilteredValue: ["CREATED", "PENDING", "APPROVED", "PENDING_RATE_CONFIRMATION"],
            onFilter: (value, record) => record.loadStatus.indexOf(value) === 0,
            sorter: (a, b) => compareByAlph(a.loadStatus, b.loadStatus),
            sortDirections: ['descend', 'ascend'],
            render: (text, record) => { return LoadUtils.getLoadStatusDisplay(text, entityType, record); },
        });
    } else if ((CanDo({ staffOnly: true }) && isStringNotEmpty(carrierId)) || CanDo({ entityTypes: ['CARRIER'] })) {
        columns.push({
            title: 'Status',
            dataIndex: 'loadStatus',
            key: 'loadStatus',
            render: (text, record) => { return LoadUtils.getLoadStatusDisplay(text, entityType, record); },
        });
    }

    if (CanDo({ staffOnly: true })) {
        columns.push({
            title: 'Shipper',
            dataIndex: ['shipper', 'name'],
            key: 'shipper.name',
        });
        columns.push({
            title: 'Carrier',
            dataIndex: ['assignedCarrier', 'name'],
            key: 'assignedCarrier.name',
        });
        columns.push({
            title: 'Account Rep',
            dataIndex: 'assignedAccountRepUserIds',
            key: 'assignedAccountRepUserIds',
            render: (text, record) => {
                if (isListNotEmpty(record.assignedAccountRepUsers)) {
                    return record.assignedAccountRepUsers.map((assignedAccountRepUser) => {
                        return (
                            <div key={`load-${record.id}-assignedAccountRepUser-${assignedAccountRepUser.id}`}>{stringFormatter.toFirstNameLastName(assignedAccountRepUser)}</div>
                        );
                    });
                } else {
                    return '';
                }
            },
        });
        columns.push({
            title: 'Service Type',
            dataIndex: 'serviceType',
            key: 'serviceType',
            render: (text, record) => { return stringFormatter.toFormattedString("LoadServiceType", text); },
        });
        columns.push({
            title: 'Shipper Rate',
            dataIndex: 'shipperAmount',
            key: 'shipperAmount',
            render: (text, record) => {return stringFormatter.toFormattedMoney(text); },
        });
        columns.push({
            title: 'Carrier Rate',
            dataIndex: 'carrierAmount',
            key: 'carrierAmount',
            render: (text, record) => {return stringFormatter.toFormattedMoney(text); },
        });
    } else if (isStringNotEmpty(shipperId) || CanDo({ entityTypes: ['SHIPPER'] })) {
        columns.push({
            title: 'Carrier',
            dataIndex: ['assignedCarrier', 'name'],
            key: 'assignedCarrier.name',
            render: (text, record) => {
                if (record.serviceType === 'TMS' || entityType === 'STAFF') {
                    return text;
                } else {
                    return 'IRIS Freight';
                }
            },
        });
        columns.push({
            title: 'A/P',
            dataIndex: 'shipperAmount',
            key: 'shipperAmount',
            render: (text, record) => {return stringFormatter.toFormattedMoney(text); },
        });
    } else if (isStringNotEmpty(carrierId) || CanDo({ entityTypes: ['CARRIER'] })) {
        columns.push({
            title: 'Shipper',
            dataIndex: ['shipper', 'name'],
            key: 'shipper.name',
            render: (text, record) => {
                if (record.serviceType === 'TMS' || entityType === 'STAFF') {
                    return text;
                } else {
                    return 'IRIS Freight';
                }
            },
        });
        columns.push({
            title: 'A/R',
            dataIndex: 'carrierAmount',
            key: 'carrierAmount',
            render: (text, record) => {return stringFormatter.toFormattedMoney(text); },
        });
    }

    //#endregion
    //#region useMemos and useEffects

    useEffect(() => {
        refreshTable();
    }, [shipperId, carrierId, driverId, assignedAccountRepUserId, entityType, entityId]);

    //#endregion

    return (
        <Can entityAction="READ_LIST" entityModel="LOAD">
            <DataTable
                dataSource={records}
                columns={columns}
                pagination={pagination}
                loading={isLoading === true}
                onChange={handleTableChange}
                singularEntityName={singularEntityName}
                pluralEntityName={pluralEntityName}
                newEntityAction={CanDo({ entityAction: 'CREATE', entityModel: 'LOAD' }) ? toggleNewEntity : null}
                rowKey={record => record.id}
                style={{ backgroundColor: '#ffffff' }}
                scroll={{ x: '100%' }}
            >
                <NewLoadModal key='new-load' showModal={showNewEntity} toggleModal={toggleNewEntity} shipperId={isStringNotEmpty(shipperId) ? shipperId : null} />
                <NewLoadModal key='duplicate-load' showModal={showDuplicateEntity} toggleModal={() => { toggleDuplicateEntity(); setSelectedRecord({}); }} shipperId={isObjectNotEmpty(selectedRecord) && isStringNotEmpty(selectedRecord.shipperId) ? selectedRecord.shipperId : null} duplicateLoadId={isObjectNotEmpty(selectedRecord) && isStringNotEmpty(selectedRecord.id) ? selectedRecord.id : null} />
            </DataTable>
        </Can>
    );
};

export default withRouter(OpenLoads);