import React from 'react';
import { isBooleanFalse, isNumberNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';

const DataSMSBasicRow = ({ className = null, valueClassName = null, titleClassName = null, style = null, valueStyle = null, titleStyle = { fontWeight: 'bold' }, noColon = false, title = null, hosCompliance = null, unsafeDriving = null, vehicleMaintenance = null, controlledSA = null, driverFitness = null, avgSMSScore = null, weightedAvgSMSScore = null, smsBasic = null }) => {
    if (isObjectNotEmpty(smsBasic)) {
        hosCompliance = smsBasic.hosCompliance
        unsafeDriving = smsBasic.unsafeDriving;
        vehicleMaintenance = smsBasic.vehicleMaintenance;
        controlledSA = smsBasic.controlledSA;
        driverFitness = smsBasic.driverFitness;
        avgSMSScore = smsBasic.avgSMSScore;
        weightedAvgSMSScore = smsBasic.weightedAvgSMSScore;
    }

    return (
        <div className={className} style={style}>
            {isStringNotEmpty(title) ? (<div className={titleClassName} style={titleStyle}>{title}{isBooleanFalse(noColon) ? ':' : ''} </div>) : null}
            {isNumberNotEmpty(hosCompliance) ? (<div className={valueClassName} style={valueStyle}>HOS Compliance: {hosCompliance}</div>) : null}
            {isNumberNotEmpty(unsafeDriving) ? (<div className={valueClassName} style={valueStyle}>Unsafe Driving: {unsafeDriving}</div>) : null}
            {isNumberNotEmpty(vehicleMaintenance) ? (<div className={valueClassName} style={valueStyle}>Vehicle Maintenance: {vehicleMaintenance}</div>) : null}
            {isNumberNotEmpty(controlledSA) ? (<div className={valueClassName} style={valueStyle}>Controlled Substance Abuse: {controlledSA}</div>) : null}
            {isNumberNotEmpty(driverFitness) ? (<div className={valueClassName} style={valueStyle}>Driver Fitness: {driverFitness}</div>) : null}
            {isNumberNotEmpty(avgSMSScore) ? (<div className={valueClassName} style={valueStyle}>Avg: {avgSMSScore}</div>) : null}
            {isNumberNotEmpty(weightedAvgSMSScore) ? (<div className={valueClassName} style={valueStyle}>Weighted Avg: {weightedAvgSMSScore}</div>) : null}
        </div>
    );
};

export default DataSMSBasicRow;