import React, { useState } from 'react';
import { Modal, Button } from "antd";
import Document from '../Document/Document';
import { isBooleanFalse, isStringNotEmpty } from '../../shared/objectUtils';
import { PaperClipOutlined } from '@ant-design/icons';

const DataLinkRow = ({ fileId, className = null, titleClassName = null, style = null, linkStyle = null, titleStyle = { fontWeight: 'bold' }, noColon = false, title = null }) => {
    const [showModal, setShowModal] = useState(false);

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    return (
        <div className={className} style={style}>
            <span className={titleClassName} style={titleStyle}><PaperClipOutlined style={{ marginRight: 8 }} />{isStringNotEmpty(title) ? title : 'File'}{isBooleanFalse(noColon) ? ':' : ''}</span><Button style={linkStyle} type="link" onClick={toggleModal}>view</Button>
            <Modal
                title={isStringNotEmpty(title) ? title : 'File'}
                visible={showModal === true}
                width="100%"
                style={{ top: 0 }}
                onCancel={toggleModal}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                maskClosable={false}
            >
                <Document displayName={title} fileId={fileId} />
            </Modal>
        </div>
    );
};

export default DataLinkRow;