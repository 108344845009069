import React from 'react';
import { Table } from "antd";
import DataTableTitle from '../../components/DataTableTitle/DataTableTitle';
import { isNotNullOrUndefined, isNumberGreaterThanZero, isStringNotEmpty } from '../../shared/objectUtils';

const DataTable = ({ hidePaging = false, pagination = null, pageSize = null, bordered = true, dataSource, columns,
    onChange = null, onRow = null, expandedRowRender, expandRowByClick, rowClassName, size = 'small',
    title = null, singularEntityName = null, pluralEntityName = null, newEntityAction = null, style = null, rowKey,
    loading = false, footer = null, rowSelection = null, scroll = null, summary = null, tableTitle = null, showTitleInstructions = false, ...props }) => {

    const tableProps = {
        bordered: bordered,
        dataSource: dataSource,
        columns: columns,
        size: size,
        loading: loading
    };

    if (isNotNullOrUndefined(onChange)) {
        tableProps.onChange = onChange;
    }

    if (isNotNullOrUndefined(onRow)) {
        tableProps.onRow = onRow;
    }

    if (isNotNullOrUndefined(expandedRowRender) || isNotNullOrUndefined(expandRowByClick)) {
        let expandable = {};
        if (isNotNullOrUndefined(expandedRowRender)) {
            expandable.expandedRowRender = expandedRowRender;
        }
    
        if (isNotNullOrUndefined(expandRowByClick)) {
            expandable.expandRowByClick = expandRowByClick;
        }

        expandable.rowExpandable = (record) => record.id !== '';

        tableProps.expandable = expandable;
    }

    if (isNotNullOrUndefined(expandRowByClick)) {
        tableProps.expandRowByClick = expandRowByClick;
    }

    if (isNotNullOrUndefined(rowClassName)) {
        tableProps.rowClassName = rowClassName;
    }

    if (isNotNullOrUndefined(rowSelection)) {
        tableProps.rowSelection = rowSelection;
    }

    if (isNotNullOrUndefined(scroll)) {
        tableProps.scroll = scroll;
    }

    if (isNotNullOrUndefined(summary)) {
        tableProps.summary = summary;
    }

    if (hidePaging === false) {
        if (isNotNullOrUndefined(pagination)) {
            tableProps.pagination = { ...pagination, showSizeChanger: true, pageSizeOptions: [5, 10, 20, 50, 100] };
        } else if (isNumberGreaterThanZero(pageSize)) {
            tableProps.pagination = { pageSize: pageSize, showSizeChanger: false };
        } else {
            tableProps.pagination = false;
        }
    } else {
        tableProps.pagination = false;
    }

    if (isNotNullOrUndefined(title)) {
        tableProps.title = title;
    } else if (isStringNotEmpty(tableTitle)) {
        tableProps.title = () => (<DataTableTitle tableTitle={tableTitle} action={isNotNullOrUndefined(newEntityAction) ? newEntityAction : null} showInstructions={showTitleInstructions} />);
    } else if (isStringNotEmpty(singularEntityName) && isStringNotEmpty(pluralEntityName)) {
        tableProps.title = () => (<DataTableTitle singularEntityName={singularEntityName} pluralEntityName={pluralEntityName} action={isNotNullOrUndefined(newEntityAction) ? newEntityAction : null} showInstructions={showTitleInstructions} />);
    } else {
        tableProps.title = false;
    }

    if (isNotNullOrUndefined(style)) {
        tableProps.style = style;
    }

    if (isNotNullOrUndefined(rowKey)) {
        tableProps.rowKey = rowKey;
    } else {
        tableProps.rowKey = (record) => record.id; // default, error will be thrown if the model doesn't have an id field so the rowKey should be set on that model's view
    }

    if (isNotNullOrUndefined(footer)) {
        tableProps.footer = footer;
    } else {
        tableProps.footer = false;
    }

    return (
        <>
            <Table {...tableProps} />
            {props.children}
        </>
    );
};

export default DataTable;