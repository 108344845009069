import React, { useState, useMemo, useEffect } from 'react';
import { Input, Select, Alert, Spin, InputNumber } from "antd";
import DatePicker from '../DatePickerAutoAccept/DatePickerAutoAccept';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import moment from 'moment';
import Enums from '../../shared/enums';
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import { isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';

const EditTransaction = ({ transaction, cancel, load, invoices, shipperId, carrierId, loading = false }) => {
    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.transactions.isRecordUpdateLoading);
    const error = useSelector(state => state.transactions.updateRecordError);
    const entityType = useSelector(state => state.auth.entityType);

    const paymentMethodOptions = Enums.PaymentMethods.selectListOptions();
    const paymentStatusOptions = Enums.PaymentStatuses.selectListOptions();
    const invoiceSourceAccountTypeOptions = entityType === "STAFF" ? Enums.InvoiceSourceAccountTypes.selectListOptions() : Enums.InvoiceSourceAccountTypes.selectListOptionsWithExclusions(["STAFF", "FACTORING"]);
    const invoiceAccountTypeOptions = entityType === "STAFF" ? Enums.InvoiceAccountTypes.selectListOptions() : Enums.InvoiceAccountTypes.selectListOptionsWithExclusions(["STAFF", "FACTORING"]);

    const [amount, setAmount] = useState(0);
    const [transactionFee, setTransactionFee] = useState(0);

    const onSubmit = (data) => {
        if (isObjectNotEmpty(data) && isObjectNotEmpty(load) && isObjectNotEmpty(transaction)) {
            if (data.fromEntityType === "SHIPPER" && isStringNotEmpty(shipperId)) {
                data.fromEntityId = shipperId;
            } else if (data.fromEntityType === "CARRIER" && isStringNotEmpty(carrierId)) {
                data.fromEntityId = carrierId;
            }

            if (data.toEntityType === "SHIPPER" && isStringNotEmpty(shipperId)) {
                data.toEntityId = shipperId;
            } else if (data.toEntityType === "CARRIER" && isStringNotEmpty(carrierId)) {
                data.toEntityId = carrierId;
            }

            let invoice = null;
            if (isListNotEmpty(invoices) && isStringNotEmpty(transaction.invoiceId)) {
                invoice = invoices.find(i => i.isDeleted === false && i.id === transaction.invoiceId);
            }

            dispatch(actionCreators.updateTransaction(transaction.id, data, transaction, load.id, invoice, true));
        } else {
            onCancel();
        }
    };

    const onChangeFromSource = (value) => {
        if (isStringNotEmpty(value)) {
            if (value === "SHIPPER") {
                methods.setValue("fromEntityType", "SHIPPER");
            } else if (value === "CARRIER") {
                methods.setValue("fromEntityType", "CARRIER");
            } else if (value === "FACTORING") {
                methods.setValue("fromEntityType", "CARRIER");
            } else if (value === "INSURANCE") {
                methods.setValue("fromEntityType", "CARRIER");
            } else if (value === "CREDIT") {
                methods.setValue("fromEntityType", "SHIPPER");
            } else if (value === "STAFF") {
                methods.setValue("fromEntityType", "STAFF");
            }
        } else {
            methods.setValue("fromEntityType", undefined);
        }
    };

    const onChangeToSource = (value) => {
        if (isStringNotEmpty(value)) {
            if (value === "SHIPPER") {
                methods.setValue("toEntityType", "SHIPPER");
            } else if (value === "CARRIER") {
                methods.setValue("toEntityType", "CARRIER");
            } else if (value === "FACTORING") {
                methods.setValue("toEntityType", "CARRIER");
            } else if (value === "INSURANCE") {
                methods.setValue("toEntityType", "CARRIER");
            } else if (value === "CREDIT") {
                methods.setValue("toEntityType", "SHIPPER");
            } else if (value === "STAFF") {
                methods.setValue("toEntityType", "STAFF");
            }
        } else {
            methods.setValue("fromEntityType", undefined);
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.updateTransactionCancel());
        cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateTransactionErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    useEffect(() => {
        // console.log(amount);
        // console.log(transactionFee);

        if (!isNaN(amount) && !isNaN(transactionFee)) {
            methods.setValue("netAmount", amount - transactionFee);
        } else {
            methods.setValue("netAmount", '');
        }
    }, [amount, transactionFee]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const formItemLayoutDouble = {
        labelCol1: { span: 12 },
        wrapperCol1: { span: 12 },
        labelCol2: { span: 12 },
        wrapperCol2: { span: 12 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true && error === null) || loading === true}>
                    <Fieldset legend="Transaction Details">
                        <FormItem {...formItemLayout} label="Transaction External Reference Id" format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Transaction External Reference Id" ref={ref} />}
                            rules={{ required: false }}
                            name="qbTransactionId"
                            defaultValue={transaction.qbTransactionId}
                        />
                        <FormItem {...formItemLayout} label="Payment Amount" required format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <InputNumber
                                    precision={2}
                                    min={0}
                                    placeholder="Payment Amount"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { setAmount(e); onChange(e); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            rules={{ required: "Required Field" }}
                            name="amount"
                            defaultValue={transaction.amount}
                        />
                        <FormItem {...formItemLayout} label="Transaction Fee (if any)" format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <InputNumber
                                    precision={2}
                                    min={0}
                                    placeholder="Transaction Fee (if any)"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { setTransactionFee(e); onChange(e); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            rules={{ required: false }}
                            name="transactionFee"
                            defaultValue={transaction.transactionFee}
                        />
                        <FormItem {...formItemLayout} label="Net Amount" required format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <InputNumber
                                    precision={2}
                                    min={0}
                                    placeholder="Net Amount"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                    disabled={true}
                                    ref={ref}
                                />
                            )}
                            rules={{ required: 'Required Field' }}
                            name="netAmount"
                            defaultValue={transaction.netAmount}
                        />
                        <FormItem {...formItemLayout} label="Payment Method" required format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select a Payment Method"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                >
                                    {paymentMethodOptions}
                                </Select>
                            )}
                            rules={{ required: 'Required Field' }}
                            name="paymentMethod"
                            defaultValue={transaction.paymentMethod}
                        />
                        <FormItem {...formItemLayout} label="Payment Status" required format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select a Payment Status"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                >
                                    {paymentStatusOptions}
                                </Select>
                            )}
                            rules={{ required: 'Required Field' }}
                            name="paymentStatus"
                            defaultValue={transaction.paymentStatus}
                        />
                        <FormItemDouble {...formItemLayoutDouble} label1="From" label2="On Behalf Of" required1 required2 format="vertical"
                            render1={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select a Payment Source"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChangeFromSource(selected); onChange(selected); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                >
                                    {invoiceSourceAccountTypeOptions}
                                </Select>
                            )}
                            rules1={{ required: 'Required Field' }}
                            name1="fromSource"
                            defaultValue1={transaction.fromSource}
                            render2={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select a Payment Beneficiary"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                >
                                    {invoiceAccountTypeOptions}
                                </Select>
                            )}
                            rules2={{ required: 'Required Field' }}
                            name2="fromEntityType"
                            defaultValue2={transaction.fromEntityType}
                        />
                        <FormItemDouble {...formItemLayoutDouble} label1="To" label2="On Behalf Of" required1 required2 format="vertical"
                            render1={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select a Payment Source"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChangeToSource(selected); onChange(selected); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                >
                                    {invoiceSourceAccountTypeOptions}
                                </Select>
                            )}
                            rules1={{ required: 'Required Field' }}
                            name1="toSource"
                            defaultValue1={transaction.toSource}
                            render2={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select a Payment Beneficiary"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                >
                                    {invoiceAccountTypeOptions}
                                </Select>
                            )}
                            rules2={{ required: 'Required Field' }}
                            name2="toEntityType"
                            defaultValue2={transaction.toEntityType}
                        />
                        <FormItem {...formItemLayout} label="Paid At" format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <DatePicker
                                    placeholder="Paid At"
                                    format="YYYY-MM-DD"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={(date, dateString) => { onChange(date); }}
                                    value={value}
                                    name={name}
                                    onSelect={(date) => { onChange(date); }}
                                    ref={ref}
                                />
                            )}
                            rules={{ required: false }}
                            name="paidAt"
                            defaultValue={transaction.paidAt ? moment(transaction.paidAt) : null}
                        />
                    </Fieldset>
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Update Transaction" />
            </Form>
        </FormProvider>
    );
};

export default EditTransaction;