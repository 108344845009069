import * as actionTypes from "../actions/actionTypes";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { isBooleanTrue, isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import ErrorUtils from "../../shared/errorUtils";
import * as actionCreators from "./index";

//#region Fetch Lane List Methods

export const fetchLaneListStart = (listName) => {
    return {
        type: actionTypes.FETCH_LANE_LIST_START,
        payload: { listName: listName }
    }
};

export const fetchLaneListSuccess = (listName, payload) => {
    return {
        type: actionTypes.FETCH_LANE_LIST_SUCCESS,
        payload: { listName: listName, ...payload }
    }
};

export const fetchLaneListFail = (listName, payload) => {
    return {
        type: actionTypes.FETCH_LANE_LIST_FAIL,
        payload: { listName: listName, ...payload }
    }
};

export const clearLaneList = (listName) => {
    return {
        type: actionTypes.CLEAR_LANE_LIST,
        payload: { listName: listName }
    }
};

export const clearLaneLists = () => {
    return {
        type: actionTypes.CLEAR_LANE_LISTS
    }
};

export const addLaneToLaneList = (listName, payload) => {
    return {
        type: actionTypes.ADD_TO_LANE_LIST,
        payload: { listName: listName, newRecord: payload }
    }
};

export const updateLaneInLaneList = (listName, payload) => {
    return {
        type: actionTypes.UPDATE_IN_LANE_LIST,
        payload: { listName: listName, updatedRecord: payload }
    }
};

export const removeLaneFromLaneList = (listName, payload) => {
    return {
        type: actionTypes.REMOVE_FROM_LANE_LIST,
        payload: { listName: listName, recordToRemove: payload }
    }
};

//#endregion
//#region Fetch Lane Methods

export const fetchLaneStart = () => {
    return {
        type: actionTypes.FETCH_LANE_START
    }
};

export const fetchLaneSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_LANE_SUCCESS,
        payload: payload
    }
};

export const fetchLaneFail = (payload) => {
    return {
        type: actionTypes.FETCH_LANE_FAIL,
        payload: payload
    }
};

export const clearLane = () => {
    return {
        type: actionTypes.CLEAR_LANE
    }
};

//#endregion
//#region Add Lane Methods

export const addLaneStart = () => {
    return {
        type: actionTypes.ADD_LANE_START
    }
};

export const addLaneSuccess = () => {
    return {
        type: actionTypes.ADD_LANE_SUCCESS
    }
};

export const addLaneFail = (payload) => {
    return {
        type: actionTypes.ADD_LANE_FAIL,
        payload: payload
    }
};

export const addLaneLoadingClear = () => {
    return {
        type: actionTypes.ADD_LANE_LOADING_CLEAR
    }
};

export const addLaneErrorClear = () => {
    return {
        type: actionTypes.ADD_LANE_ERROR_CLEAR
    }
};

export const addLaneCancel = () => {
    return {
        type: actionTypes.ADD_LANE_CANCEL
    }
};

//#endregion
//#region Update Lane Methods

const changeSingleLane = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_LANE,
        payload: payload
    }
};

export const updateLaneStart = () => {
    return {
        type: actionTypes.UPDATE_LANE_START
    }
};

export const updateLaneSuccess = () => {
    return {
        type: actionTypes.UPDATE_LANE_SUCCESS
    }
};

export const updateLaneFail = (payload) => {
    return {
        type: actionTypes.UPDATE_LANE_FAIL,
        payload: payload
    }
};

export const updateLaneLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_LANE_LOADING_CLEAR
    }
};

export const updateLaneErrorClear = () => {
    return {
        type: actionTypes.UPDATE_LANE_ERROR_CLEAR
    }
};

export const updateLaneCancel = () => {
    return {
        type: actionTypes.UPDATE_LANE_CANCEL
    }
};

//#endregion
//#region Lanes Methods

export const fetchLaneList = (listName, payload) => {
    return async (dispatch, getState) => {
        if (isStringNotEmpty(listName)) {
            try {
                dispatch(fetchLaneListStart(listName));

                await dispatch(actionCreators.getTrailerTypes());
                const state = getState();
                const orchestratorState = { ...state.orchestrator };
                const trailerTypes = [...orchestratorState.trailerTypes];
                const lanesState = { ...state.lanes };
                const existingLists = { ...lanesState.lists };

                let pagination = {};
                let searchParams = {};
                if (isObjectNotEmpty(existingLists[listName])) {
                    pagination = { ...existingLists[listName].pagination };
                    searchParams = { ...existingLists[listName].searchParams };
                }

                if (isObjectNotEmpty(payload)) {
                    searchParams = { ...payload };
                }

                const res = await Data.getLanes({ ...searchParams }, pagination, trailerTypes);
                dispatch(fetchLaneListSuccess(listName, { records: res.data, params: { searchParams: res.searchParams, pagination: res.pagination } }));
            } catch (error) {
                logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
                dispatch(fetchLaneListFail(listName, { error: ErrorUtils.getErrorMessage(error) }));
            }
        }
    }
};

export const fetchLane = (id) => {
    return async (dispatch, getState) => {
        try {
            dispatch(fetchLaneStart());

            await dispatch(actionCreators.getTrailerTypes());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const trailerTypes = [...orchestratorState.trailerTypes];

            const lane = await Data.getLane(id, trailerTypes);
            dispatch(fetchLaneSuccess({ record: lane }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchLaneFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const addLane = (payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(addLaneStart());

            await dispatch(actionCreators.getTrailerTypes());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const trailerTypes = [...orchestratorState.trailerTypes];

            const newLane = await Data.addLane(payload, trailerTypes);
            if (isObjectNotEmpty(newLane)) {
                dispatch(addLaneToLaneList("ALL", newLane));
                dispatch(addLaneToLaneList(newLane.trailerTypeId, newLane));
            }

            dispatch(addLaneSuccess());
            dispatch(addLaneLoadingClear());
            dispatch(addLaneErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addLaneFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateLane = (id, payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updateLaneStart());

            if (isBooleanTrue(payload.isDeleted)) {
                const removedLane = await Data.removeLane(id);
                if (isObjectNotEmpty(removedLane)) {
                    dispatch(removeLaneFromLaneList("ALL", removedLane));
                    dispatch(removeLaneFromLaneList(removedLane.trailerTypeId, removedLane));
                }
            } else {
                await dispatch(actionCreators.getTrailerTypes());
                const state = getState();
                const orchestratorState = { ...state.orchestrator };
                const trailerTypes = [...orchestratorState.trailerTypes];

                const updatedLane = await Data.updateLane(id, payload, trailerTypes);
                if (isObjectNotEmpty(updatedLane)) {
                    dispatch(updateLaneInLaneList("ALL", updatedLane));
                    dispatch(updateLaneInLaneList(updatedLane.trailerTypeId, updatedLane));
                    dispatch(changeSingleLane(updatedLane));
                }
            }

            dispatch(updateLaneSuccess());
            dispatch(updateLaneLoadingClear());
            dispatch(updateLaneErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateLaneFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion