import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { Spin, Tabs, Button, Modal, Drawer, Row, Col, Menu, Dropdown, message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhoneAlt, faUserAlt, faIdBadge, faTools, faIdCard } from '@fortawesome/free-solid-svg-icons';
import Header from '../../components/Header/Header';
import { useLocation } from 'react-router';
import { CheckCircleTwoTone, PaperClipOutlined, ControlOutlined, DownOutlined, PlusOutlined, FormOutlined, CheckOutlined } from '@ant-design/icons';
import DocumentList from '../../components/DocumentList/DocumentList';
import EditDriver from "../../components/EditDriver/EditDriver";
import EditEmployee from "../../components/EditEmployee/EditEmployee";
import ComplexDataLinkCard from '../../components/ComplexDataLinkCard/ComplexDataLinkCard';
import ComplexCard from '../../components/ComplexCard/ComplexCard';
import DataTable from '../../components/DataTable/DataTable';
import Thumbnail from '../../components/Thumbnail/Thumbnail';
import classes from './Employee.module.scss';
import StringFormatter from '../../shared/stringFormatter';
import EditLicense from '../../components/EditLicense/EditLicense';
import Document from '../../components/Document/Document';
import BookedLoads from "../../views/BookedLoads/BookedLoads";
import InTransitLoads from "../../views/InTransitLoads/InTransitLoads";
import CompletedLoads from "../../views/CompletedLoads/CompletedLoads";
import Calendar from '../../components/Calendar/Calendar';
import Scheduler from '../../components/Scheduler/Scheduler';
import Agenda from '../../components/Agenda/Agenda';
import axios from 'axios';
import * as Data from '../../api/data';
import { isBooleanFalse, isBooleanTrue, isListNotEmpty, isNullOrUndefined, isNumberGreaterThanZero, isNumberNotEmpty, isObjectNotEmpty, isStringEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import logger from '../../shared/logger';
import { CanDo, CanSee } from '../../shared/entitlements/entitlements';
import QueueLoads from '../../components/QueueLoads/QueueLoads';

const { TabPane } = Tabs;
const stringFormatter = new StringFormatter();

const Employee = (props) => {
    //#region props and constants

    const fullWidth = global.window.innerWidth;
    const accountUserId = props.match.params.accountUserId;
    const previousBreadcrumbs = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isListNotEmpty(props.history.location.state.previousBreadcrumbs) ? props.history.location.state.previousBreadcrumbs : [];
    const previousLocation = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageLocation) ? props.history.location.state.previousPageLocation : { pathname: `/employees` };
    const previousPath = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageLocation) ? props.history.location.state.previousPageLocation : `/employees`;
    const previousBreadcrumbName = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageTitle) ? props.history.location.state.previousPageTitle : 'Employees';

    //#endregion
    //#region useLocation

    const location = useLocation();

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.accountUsers.isRecordLoading);
    const error = useSelector(state => state.accountUsers.recordError);
    const accountUser = useSelector(state => state.accountUsers.record);
    const updateIsLoading = useSelector(state => state.accountUsers.isRecordUpdateLoading);
    const updateError = useSelector(state => state.accountUsers.updateRecordError);

    //#endregion
    //#region useStates

    const [tabKey, setTabKey] = useState('snapshot');
    const [imageUrl, setImageUrl] = useState(null);
    const [showEditEmployee, setShowEditEmployee] = useState(false);
    const [showEditDriver, setShowEditDriver] = useState(false);
    const [showDocumentsModal, setShowDocumentsModal] = useState(false);
    const [missingDocumentsCount, setMissingDocumentsCount] = useState(0);
    const [pendingApprovalCount, setPendingApprovalCount] = useState(0);
    const [expiringSoonDocumentsCount, setExpiringSoonDocumentsCount] = useState(0);
    const [expiredDocumentsCount, setExpiredDocumentsCount] = useState(0);
    const [isApprovingDriver, setIsApprovingDriver] = useState(false);
    const [showAddDocument, setShowAddDocument] = useState(false);
    const [showEditDocument, setShowEditDocument] = useState(false);
    const [showUpdateDocument, setShowUpdateDocument] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({});
    const [selectedDocument, setSelectedDocument] = useState({});
    const [showDocumentModal, setShowDocumentModal] = useState(false);

    //#endregion
    //#region toggles

    const toggleEditEmployee = () => {
        setShowEditEmployee(!showEditEmployee);
    };

    const toggleEditDriver = () => {
        setShowEditDriver(!showEditDriver);
    };

    const toggleDocumentsModal = () => {
        setShowDocumentsModal(!showDocumentsModal);
    };

    const toggleAddDocument = () => {
        setShowAddDocument(!showAddDocument);
    };

    const toggleEditDocument = () => {
        setShowEditDocument(!showEditDocument);
    };

    const toggleUpdateDocument = () => {
        setShowUpdateDocument(!showUpdateDocument);
    };

    const toggleDocumentModal = () => {
        setShowDocumentModal(!showDocumentModal);
    };

    //#endregion
    //#region accountUser methods

    const approveDriver = (accountUserId) => {
        dispatch(actionCreators.approveDriver(accountUserId));
        setIsApprovingDriver(true);
    };

    const checkForActiveLoad = () => {
        if (isObjectNotEmpty(accountUser)) {
            Data.getActiveLoadId(accountUser.id).then(activeLoadId => {
                if (isStringNotEmpty(activeLoadId)) {
                    window.open(`/track/${activeLoadId}`, "_blank");
                } else {
                    message.info('No active load was found.');
                }
            }).catch(err => {
                logger.logDebugEvent('Employee.js', err.message, true);
            });
        } else {
            message.info('No active load was found.');
        }
    };

    const finishedApproveDriver = () => {
        dispatch(actionCreators.updateAccountUserCancel());
        if (showDocumentsModal === true) {
            toggleDocumentsModal();
        }
        setIsApprovingDriver(false);
    };

    //#endregion
    //#region tab methods

    const onTabChange = (key) => {
        props.history.replace({ ...props.location, hash: `#${key}` });
    };

    //#endregion
    //#region useMemos and useEffects

    useMemo(() => {
        // console.log('location changed')
        // console.log(location)
        if (isStringNotEmpty(location.hash)) {
            // console.log(location.hash.replace('#', ''))
            setTabKey(location.hash.replace('#', ''));
        } else {
            setTabKey('snapshot');
            props.history.replace({ ...location, hash: `#snapshot` });
        }
    }, [location]);

    useMemo(() => {
        dispatch(actionCreators.fetchAccountUser(accountUserId));
    }, [accountUserId]);

    useEffect(() => {
        const { cancel, token } = axios.CancelToken.source();

        if (isObjectNotEmpty(accountUser) && isStringNotEmpty(accountUser.profileImageId)) {
            Data.getImageByDocumentId(accountUser.profileImageId, { cancelToken: token }).then((data) => {
                const blob = new Blob([data], {
                    type: 'image/png',
                });
                setImageUrl(URL.createObjectURL(blob));
            }).catch(err => {
                if (axios.isCancel(err)) {
                    //console.log('Request cancelled', err.message);
                } else {
                    logger.logDebugEvent('Employee.js', err.message, true);
                }
            });
        }

        return () => {
            //console.log("Cleaning up");
            if (cancel !== undefined) {
                cancel('Operation cancelled by the user.');
            }
        };
    }, [accountUser]);

    useMemo(() => {
        if (updateIsLoading !== null && updateIsLoading === false && updateError === null && isApprovingDriver === true) {
            finishedApproveDriver();
        }
    }, [updateIsLoading, updateError, isApprovingDriver, showDocumentsModal]);

    //#endregion
    //#region displays

    const editEmployeeComponents = (
        <Drawer
            title={"Edit Employee Profile"}
            onClose={toggleEditEmployee}
            visible={showEditEmployee === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditEmployee
                cancel={toggleEditEmployee}
                employee={accountUser}
            />
        </Drawer>
    );

    const editDriverComponents = (
        <Drawer
            title={"Edit Driver Profile"}
            onClose={toggleEditDriver}
            visible={showEditDriver === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditDriver
                cancel={toggleEditDriver}
                accountUser={accountUser}
            />
        </Drawer>
    );

    const documentsComponent = (
        <Modal
            title="Driver Documents"
            visible={showDocumentsModal === true}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleDocumentsModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            {(isObjectNotEmpty(accountUser) && isObjectNotEmpty(accountUser.driver)) ? (
                <DocumentList
                    accountUser={accountUser}
                    documentEntityType="DRIVER"
                    documentEntityId={accountUser.id}
                    updatePendingApprovalCount={setPendingApprovalCount}
                    updateMissingDocumentsCount={setMissingDocumentsCount}
                    updateExpiringSoonDocumentsCount={setExpiringSoonDocumentsCount}
                    updateExpiredDocumentsCount={setExpiredDocumentsCount}
                />
            ) : null}
            {/* {isObjectNotEmpty(accountUser) && isObjectNotEmpty(accountUser.driver) && isBooleanFalse(accountUser.driver.isApproved) ? (
                <CanSee entityAction="VERIFY" entityModel="ACCOUNT_USER" entityObject={accountUser} accountId={accountUser.accountId}>
                    <Button style={{ marginTop: 20 }} type='primary' onClick={() => { approveDriver(accountUser.id); }} disabled={pendingApprovalCount > 0 || missingDocumentsCount > 0 || expiredDocumentsCount > 0}>Approve Driver</Button>
                </CanSee>
            ) : null} */}
        </Modal>
    );

    const documentComponent = (
        <Modal
            title={selectedDocument.displayName}
            visible={showDocumentModal === true}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleDocumentModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            {isObjectNotEmpty(selectedDocument) ? (
                <Document
                    fileId={selectedDocument.id}
                    displayName={selectedDocument.displayName}
                />
            ) : null}
        </Modal>
    );

    const addDocumentComponents = (
        <Drawer
            title={"Add License"}
            onClose={toggleAddDocument}
            visible={showAddDocument === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditLicense
                cancel={toggleAddDocument}
                license={selectedRecord}
                actionText="Add License"
                accountUserId={isObjectNotEmpty(accountUser) && isStringNotEmpty(accountUser.id) ? accountUser.id : null}
            />
        </Drawer>
    );

    const editDocumentComponents = (
        <Drawer
            title={"Update License Details"}
            onClose={toggleEditDocument}
            visible={showEditDocument === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditLicense
                cancel={toggleEditDocument}
                license={selectedRecord}
                actionText="Update License Details"
                accountUserId={isObjectNotEmpty(accountUser) && isStringNotEmpty(accountUser.id) ? accountUser.id : null}
            />
        </Drawer>
    );

    const updateDocumentComponents = (
        <Drawer
            title={"Update Expired License"}
            onClose={toggleUpdateDocument}
            visible={showUpdateDocument === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditLicense
                cancel={toggleUpdateDocument}
                license={selectedRecord}
                actionText="Update Expired License"
                accountUserId={isObjectNotEmpty(accountUser) && isStringNotEmpty(accountUser.id) ? accountUser.id : null}
            />
        </Drawer>
    );

    const menu = () => {
        return (
            <Menu>
                {CanDo({ entityAction: 'UPDATE', entityModel: 'ACCOUNT_USER', entityObject: accountUser, accountId: accountUser.accountId }) &&
                    <Menu.Item key="editEmployee" onClick={(e) => { e.domEvent.stopPropagation(); toggleEditEmployee(); }}>
                        <FontAwesomeIcon className="anticon" icon={faIdBadge} style={{ marginRight: 8 }} />
                        <span>Edit Employee Details</span>
                    </Menu.Item>
                }
            </Menu>
        );
    };

    const driverMenu = () => {
        return (
            <Menu>
                {(isObjectNotEmpty(accountUser) && isBooleanTrue(accountUser.isDriver) && isObjectNotEmpty(accountUser.driver) && CanDo({ entityAction: 'UPDATE', entityModel: 'ACCOUNT_USER', entityObject: accountUser, accountId: accountUser.accountId })) ? (
                    <Menu.Item key="manageDocuments" onClick={(e) => { e.domEvent.stopPropagation(); toggleDocumentsModal(); }}>
                        <PaperClipOutlined style={{ marginRight: 8 }} />
                        <span>Manage Driver Documents</span>
                    </Menu.Item>
                ) : null}
                {(isObjectNotEmpty(accountUser) && isBooleanTrue(accountUser.isDriver) && isObjectNotEmpty(accountUser.driver) && CanDo({ entityAction: 'UPDATE', entityModel: 'ACCOUNT_USER', entityObject: accountUser, accountId: accountUser.accountId })) ? (
                    <Menu.Item key="editDriver" onClick={(e) => { e.domEvent.stopPropagation(); toggleEditDriver(); }}>
                        <FontAwesomeIcon className="anticon" icon={faIdCard} style={{ marginRight: 8 }} />
                        <span>Edit Driver Details</span>
                    </Menu.Item>
                ) : null}
                {/* {(isObjectNotEmpty(accountUser) && isBooleanTrue(accountUser.isDriver) && isObjectNotEmpty(accountUser.driver) && accountUser.driver.isApproved === false && CanDo({ entityAction: 'VERIFY', entityModel: 'ACCOUNT_USER', entityObject: accountUser, accountId: accountUser.accountId })) ? (
                    <Menu.Item key="approveDriver" onClick={(e) => { e.domEvent.stopPropagation(); toggleDocumentsModal(); }}>
                        <CheckOutlined style={{ marginRight: 8 }} />
                        <span>Approve Driver</span>
                    </Menu.Item>
                ) : null} */}
                {(isObjectNotEmpty(accountUser) && isBooleanTrue(accountUser.isDriver) && isObjectNotEmpty(accountUser.driver) && CanDo({ entityAction: 'READ', entityModel: 'ACCOUNT_USER', entityObject: accountUser, accountId: accountUser.accountId })) ? (
                    <Menu.Item key="checkForActiveLoad" onClick={(e) => { e.domEvent.stopPropagation(); checkForActiveLoad(); }}>
                        <span>Check for Active Load</span>
                    </Menu.Item>
                ) : null}
            </Menu>
        );
    };

    const extra = (
        <Dropdown overlay={menu} trigger={['click']}>
            <Button onClick={(e) => { e.stopPropagation(); }}><ControlOutlined /> <DownOutlined /></Button>
        </Dropdown>
    );

    const driverExtra = (
        <Dropdown overlay={driverMenu} trigger={['click']}>
            <Button onClick={(e) => { e.stopPropagation(); }}><ControlOutlined /> <DownOutlined /></Button>
        </Dropdown>
    );

    const breadcrumbs = [...previousBreadcrumbs,
    {
        location: previousLocation,
        path: previousPath,
        breadcrumbName: previousBreadcrumbName,
    },
    {
        path: `/employee/${isStringNotEmpty(accountUserId) ? accountUserId : null}`,
        breadcrumbName: `${isObjectNotEmpty(accountUser) && isStringNotEmpty(accountUser.firstName) && isStringNotEmpty(accountUser.lastName) ? accountUser.firstName + ' ' + accountUser.lastName : ''}`,
    },
    ];

    const setRowClassName = (record) => {
        if (isBooleanTrue(record.isExpired)) {
            return classes.expiredRow;
        } else if (record.isRequired === true && (isNullOrUndefined(record.expirationDate) || isStringEmpty(record.licenseNumber) || isStringEmpty(record.frontImageId) || isStringEmpty(record.backImageId))) {
            return classes.expiredRow;
        } else if (isBooleanTrue(record.isExpiringSoon)) {
            return classes.expiringSoonRow;
        } else {
            return classes.dataTableRow;
        }
    };

    const columns = [
        {
            title: '',
            dataIndex: 'licenseTypeDisplayName',
            key: 'licenseTypeDisplayName',
            render: (text, record) => {
                if (isBooleanTrue(record.isExpired)) {
                    return {
                        props: {
                            className: setRowClassName(record)
                        },
                        children: <strong>{text} {isBooleanTrue(record.isRequired) ? '(Required) ' : null}<i>(Expired - Please Update)</i></strong>,
                    };
                } else if (isBooleanTrue(record.isRequired) && (isNullOrUndefined(record.expirationDate) || isStringEmpty(record.licenseNumber) || isStringEmpty(record.frontImageId) || isStringEmpty(record.backImageId))) {
                    return {
                        props: {
                            className: setRowClassName(record)
                        },
                        children: <strong>{text} {isBooleanTrue(record.isRequired) ? '(Required) ' : null}<i>(Information is Missing)</i></strong>,
                    };
                } else if (isBooleanTrue(record.isExpiringSoon)) {
                    return {
                        props: {
                            className: setRowClassName(record)
                        },
                        children: <strong>{text} {record.isRequired === true ? '(Required) ' : null}<i>(Expiring Soon - Please Update)</i></strong>,
                    };
                } else {
                    return {
                        props: {
                            className: setRowClassName(record)
                        },
                        children: <strong>{text} {record.isRequired === true ? '(Required) ' : null}</strong>,
                    };
                }
            },
        },
        {
            title: 'Front',
            dataIndex: 'frontImageId',
            key: 'frontImageId',
            render: (text, record) => {
                return {
                    props: {
                        className: setRowClassName(record)
                    },
                    children: <Thumbnail alt={record.licenseTypeDisplayName} url={isObjectNotEmpty(record.frontImage) && isStringNotEmpty(record.frontImage.link) ? record.frontImage.link : null} action={(e) => { e.stopPropagation(); setSelectedDocument(record.frontImage); toggleDocumentModal(); }} />,
                };
            }
        },
        {
            title: 'Back',
            dataIndex: 'backImageId',
            key: 'backImageId',
            render: (text, record) => {
                return {
                    props: {
                        className: setRowClassName(record)
                    },
                    children: <Thumbnail alt={record.licenseTypeDisplayName} url={isObjectNotEmpty(record.backImage) && isStringNotEmpty(record.backImage.link) ? record.backImage.link : null} action={(e) => { e.stopPropagation(); setSelectedDocument(record.backImage); toggleDocumentModal(); }} />,
                };
            }
        },
        {
            title: 'Number',
            dataIndex: 'licenseNumber',
            key: 'licenseNumber',
            render: (text, record) => {
                return {
                    props: {
                        className: setRowClassName(record)
                    },
                    children: text,
                };
            }
        },
        {
            title: 'Expiration',
            dataIndex: 'expirationDate',
            key: 'expirationDate',
            render: (text, record) => {
                return {
                    props: {
                        className: setRowClassName(record)
                    },
                    children: stringFormatter.toFormattedString("MomentDate", text, null),
                };
            }
        },
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            className: classes.columnNoBreak,
            width: 50,
            render: (text, record) => {
                if (isBooleanTrue(record.isExpired)) {
                    return {
                        props: {
                            className: [setRowClassName(record), classes.columnNoBreak]
                        },
                        children: <Button type="danger" shape="circle" icon={<PlusOutlined />} onClick={(e) => { e.stopPropagation(); setSelectedRecord(record); toggleUpdateDocument(); }} />,
                    };
                } else if (isBooleanTrue(record.isExpiringSoon)) {
                    return {
                        props: {
                            className: [setRowClassName(record), classes.columnNoBreak]
                        },
                        children: <Button type="danger" shape="circle" icon={<PlusOutlined />} onClick={(e) => { e.stopPropagation(); setSelectedRecord(record); toggleUpdateDocument(); }} />,
                    };
                } else if (isBooleanTrue(record.isRequired) && (isStringEmpty(record.frontImageId) && isStringEmpty(record.backImageId) && isNullOrUndefined(record.expirationDate) && isStringEmpty(record.licenseNumber))) {
                    return {
                        props: {
                            className: [setRowClassName(record), classes.columnNoBreak]
                        },
                        children: <Button type="danger" shape="circle" icon={<PlusOutlined />} onClick={(e) => { e.stopPropagation(); setSelectedRecord(record); toggleAddDocument(); }} />,
                    };
                } else if (isBooleanTrue(record.isRequired) && (isStringEmpty(record.frontImageId) || isStringEmpty(record.backImageId) || isNullOrUndefined(record.expirationDate) || isStringEmpty(record.licenseNumber))) {
                    return {
                        props: {
                            className: [setRowClassName(record), classes.columnNoBreak]
                        },
                        children: <Button type="danger" shape="circle" icon={<FormOutlined />} onClick={(e) => { e.stopPropagation(); setSelectedRecord(record); toggleEditDocument(); }} />,
                    };
                } else if (isBooleanFalse(record.isRequired) && (isStringEmpty(record.frontImageId) && isStringEmpty(record.backImageId) && isNullOrUndefined(record.expirationDate) && isStringEmpty(record.licenseNumber))) {
                    return {
                        props: {
                            className: [setRowClassName(record), classes.columnNoBreak]
                        },
                        children: <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={(e) => { e.stopPropagation(); setSelectedRecord(record); toggleAddDocument(); }} />,
                    };
                } else if (isBooleanFalse(record.isRequired) && (isStringEmpty(record.frontImageId) || isStringEmpty(record.backImageId) || isNullOrUndefined(record.expirationDate) || isStringEmpty(record.licenseNumber))) {
                    return {
                        props: {
                            className: [setRowClassName(record), classes.columnNoBreak]
                        },
                        children: <Button type="primary" shape="circle" icon={<FormOutlined />} onClick={(e) => { e.stopPropagation(); setSelectedRecord(record); toggleEditDocument(); }} />,
                    };
                } else {
                    return {
                        props: {
                            className: [setRowClassName(record), classes.columnNoBreak]
                        },
                        children: <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={(e) => { e.stopPropagation(); setSelectedRecord(record); toggleAddDocument(); }} />,
                    };
                }
            },
            align: 'center',
        }
    ];

    //#endregion

    if (isObjectNotEmpty(accountUser) && accountUser.id === accountUserId) {
        return (
            <>
                <Header
                    title={isStringNotEmpty(accountUser.firstName) && isStringNotEmpty(accountUser.lastName) ? accountUser.firstName + ' ' + accountUser.lastName : 'Loading...'}
                    subtitle={isStringNotEmpty(accountUser.title) ? accountUser.title : null}
                    subtitle2={isBooleanTrue(accountUser.isVerified) ? (<CheckCircleTwoTone title="Verified" alt="Verified" twoToneColor="#52c41a" />) : (CanDo({ staffOnly: true }) ? (<a onClick={(e) => { e.stopPropagation(); toggleDocumentsModal(); }}>Verify</a>) : null)}
                    title2={isStringNotEmpty(accountUser.email) ? accountUser.email : ''}
                    title2Icon={<FontAwesomeIcon className="anticon" icon={faEnvelope} />}
                    title3={isStringNotEmpty(accountUser.workPhone) ? stringFormatter.toFormattedPhoneNumber(accountUser.workPhone, accountUser.workPhoneExt) : ''}
                    title3Icon={<FontAwesomeIcon className="anticon" icon={faPhoneAlt} />}
                    logo={imageUrl}
                    icon={<FontAwesomeIcon className="anticon" icon={faUserAlt} />}
                    breadcrumbs={breadcrumbs}
                />
                <Tabs activeKey={tabKey} onChange={onTabChange} destroyInactiveTabPane={true} tabBarStyle={{ backgroundColor: '#ffffff', paddingRight: 24, paddingLeft: 24, margin: 0 }}>
                    <TabPane tab="Snapshot" key="snapshot">
                        <div style={{ padding: 24 }}>
                            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && !error}>
                                {(isObjectNotEmpty(accountUser) && isLoading === false) ? (
                                    <Row gutter={[16, 16]}>
                                        <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                            <ComplexDataLinkCard
                                                title="Employee Details"
                                                style={{ height: 300 }}
                                                headStyle={{ fontSize: 14 }}
                                                data={{
                                                    "Employee ID": { value: isStringNotEmpty(accountUser.irisId) ? accountUser.irisId : '', dataType: 'String' },
                                                    "Email": { value: isStringNotEmpty(accountUser.email) ? accountUser.email : null, dataType: 'String' },
                                                    "Work Phone": { value: isStringNotEmpty(accountUser.workPhone) ? accountUser.workPhone : null, units: accountUser.workPhoneExt, dataType: 'PhoneNumber' },
                                                    "Account Role(s)": { value: isListNotEmpty(accountUser.accountRoles) ? accountUser.accountRoles.map((accountRole) => stringFormatter.toFormattedString("AccountRole", accountRole)).join(', ') : null, dataType: 'String' },
                                                    "Is Driver": { value: isBooleanTrue(accountUser.isDriver) ? accountUser.isDriver : false, dataType: 'Boolean' },
                                                    "Is Contractor": { value: isBooleanTrue(accountUser.isContractor) ? accountUser.isContractor : false, dataType: 'Boolean' },
                                                    "Is Verified": { value: isBooleanTrue(accountUser.isVerified) ? accountUser.isVerified : false, dataType: 'Boolean' },
                                                    "Verified By": { value: isBooleanTrue(accountUser.isVerified) && isObjectNotEmpty(accountUser.verifiedByUser) ? accountUser.verifiedByUser : null, dataType: 'FirstNameLastName' }
                                                }}
                                                extra={extra}
                                            />
                                        </Col>
                                        {isObjectNotEmpty(accountUser.driver) && isBooleanTrue(accountUser.isDriver) ? (
                                            <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                                <ComplexDataLinkCard
                                                    title="Driver Details"
                                                    style={{ height: 300 }}
                                                    headStyle={{ fontSize: 14 }}
                                                    data={{
                                                        "Driver ID": { value: isStringNotEmpty(accountUser.driver.irisId) ? accountUser.driver.irisId : '', dataType: 'String' },
                                                        // "Is Approved": { value: isBooleanTrue(accountUser.driver.isApproved) ? accountUser.driver.isApproved : false, dataType: 'Boolean' },
                                                        "Is Available": { value: isBooleanTrue(accountUser.driver.isAvailable) ? accountUser.driver.isAvailable : false, dataType: 'Boolean' },
                                                        "Total Loads Completed": { value: isNumberNotEmpty(accountUser.driver.totalLoads) ? accountUser.driver.totalLoads : 0, dataType: 'Integer' },
                                                        "Scheduled Loads": { value: isNumberNotEmpty(accountUser.driver.scheduledLoads) ? accountUser.driver.scheduledLoads : 0, dataType: 'Integer' },
                                                        // "IRIS Rating": { value: isNumberNotEmpty(accountUser.driver.totalRating) ? accountUser.driver.totalRating : null, dataType: 'String' },
                                                        "Documents Pending Approval": { value: isNumberGreaterThanZero(accountUser.driver.documentPendingApprovalCount) ? accountUser.driver.documentPendingApprovalCount : null, dataType: 'Integer' },
                                                        // "Documents Missing": { value: isNumberGreaterThanZero(accountUser.driver.documentMissingCount) ? accountUser.driver.documentMissingCount : null, dataType: 'Integer' },
                                                        "Documents Expiring Soon": { value: isNumberGreaterThanZero(accountUser.driver.documentExpiringSoonCount) ? accountUser.driver.documentExpiringSoonCount : null, dataType: 'Integer' },
                                                        "Documents Expired": { value: isNumberGreaterThanZero(accountUser.driver.documentExpiredCount) ? accountUser.driver.documentExpiredCount : null, dataType: 'Integer' },
                                                    }}
                                                    extra={driverExtra}
                                                />
                                            </Col>
                                        ) : null}
                                        {isObjectNotEmpty(accountUser.driver) && isBooleanTrue(accountUser.isDriver) ? (
                                            <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}>
                                                <ComplexCard
                                                    title="Driver Licenses and Certificates"
                                                    style={{ height: 500 }}
                                                    headStyle={{ fontSize: 14 }}
                                                >
                                                    <DataTable
                                                        dataSource={isListNotEmpty(accountUser.driver.licenses) ? accountUser.driver.licenses : []}
                                                        columns={columns}
                                                        hidePaging={true}
                                                        loading={isLoading === true}
                                                        rowClassName={setRowClassName}
                                                        rowKey={record => record.licenseType}
                                                        style={{ backgroundColor: '#ffffff' }}
                                                    />
                                                </ComplexCard>
                                            </Col>
                                        ) : null}
                                    </Row>
                                ) : null}
                            </Spin>
                        </div>
                    </TabPane>
                    {(isObjectNotEmpty(accountUser.driver) && isBooleanTrue(accountUser.isDriver)) ? (
                        <TabPane tab="Driver Documents" key="driverDocuments">
                            <div style={{ padding: 24 }}>
                                <DocumentList
                                    accountUser={accountUser}
                                    documentEntityType="DRIVER"
                                    documentEntityId={accountUser.id}
                                    updatePendingApprovalCount={setPendingApprovalCount}
                                    updateMissingDocumentsCount={setMissingDocumentsCount}
                                    updateExpiringSoonDocumentsCount={setExpiringSoonDocumentsCount}
                                    updateExpiredDocumentsCount={setExpiredDocumentsCount}
                                />
                                {/* {isBooleanFalse(accountUser.driver.isApproved) ? (
                                    <CanSee entityAction="VERIFY" entityModel="ACCOUNT_USER" entityObject={accountUser} accountId={accountUser.accountId}>
                                        <Button style={{ marginTop: 20 }} type='primary' onClick={() => { approveDriver(accountUser.id); }} disabled={pendingApprovalCount > 0 || missingDocumentsCount > 0 || expiredDocumentsCount > 0}>Approve Driver</Button>
                                    </CanSee>
                                ) : null} */}
                            </div>
                        </TabPane>
                    ) : null}
                    {(isObjectNotEmpty(accountUser.driver) && isBooleanTrue(accountUser.isDriver)) ? (
                        <TabPane tab="Booked Loads" key="bookedLoads">
                            <div style={{ padding: 24 }}>
                                <BookedLoads
                                    carrierId={accountUser.accountId}
                                    shipperId={null}
                                    accountId={accountUser.accountId}
                                    driverId={accountUser.id}
                                    breadcrumbs={breadcrumbs}
                                />
                            </div>
                        </TabPane>
                    ) : null}
                    {(isObjectNotEmpty(accountUser.driver) && isBooleanTrue(accountUser.isDriver)) ? (
                        <TabPane tab="In Transit Loads" key="intransitLoads">
                            <div style={{ padding: 24 }}>
                                <InTransitLoads
                                    carrierId={accountUser.accountId}
                                    shipperId={null}
                                    accountId={accountUser.accountId}
                                    driverId={accountUser.id}
                                    breadcrumbs={breadcrumbs}
                                />
                            </div>
                        </TabPane>
                    ) : null}
                    {(isObjectNotEmpty(accountUser.driver) && isBooleanTrue(accountUser.isDriver)) ? (
                        <TabPane tab="Completed Loads" key="completedLoads">
                            <div style={{ padding: 24 }}>
                                <QueueLoads
                                    title="Manage Completed Loads"
                                    filter={`COMPLETED_LOADS_DRIVER_${accountUser.id}`}
                                    filterSearchParams={{
                                        sort: 'loadEndDateTime',
                                        order: 'desc',
                                        isDeleted: false,
                                        eta: false
                                    }}
                                    defaultLoadStatusFilter={['COMPLETED', 'REOPENED', 'CLOSED', 'CANCELLED']}
                                    showTable={true}
                                    carrierId={accountUser.accountId}
                                    driverId={accountUser.id}
                                    breadcrumbs={breadcrumbs}
                                    showCompletedLoadData={true}
                                    showAccountsPayable={CanDo({ staffOnly: true })}
                                    showAccountsReceivable={CanDo({ staffOnly: true })}
                                />
                            </div>
                        </TabPane>
                    ) : null}
                    <TabPane tab="Calendar" key="calendar">
                        <div style={{ padding: 24 }}>
                            <ComplexCard>
                                <Calendar
                                    accountId={accountUser.accountId}
                                    accountUserId={accountUser.id}
                                />
                            </ComplexCard>
                        </div>
                    </TabPane>
                    <TabPane tab="Scheduler" key="scheduler">
                        <div style={{ padding: 24 }}>
                            <ComplexCard>
                                <Scheduler
                                    accountId={accountUser.accountId}
                                    accountUserId={accountUser.id}
                                />
                            </ComplexCard>
                        </div>
                    </TabPane>
                    <TabPane tab="Agenda" key="agenda">
                        <div style={{ padding: 24 }}>
                            <ComplexCard>
                                <Agenda
                                    accountId={accountUser.accountId}
                                    accountUserId={accountUser.id}
                                />
                            </ComplexCard>
                        </div>
                    </TabPane>
                </Tabs>
                {editEmployeeComponents}
                {editDriverComponents}
                {documentsComponent}
                {addDocumentComponents}
                {editDocumentComponents}
                {updateDocumentComponents}
                {documentComponent}
            </>
        );
    } else {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={true} />
        );
    }
};

export default withRouter(Employee);