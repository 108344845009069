import Checks from '../entitlementUtils';

export const canCreateProduct = (auth, accountId) => {
    return Checks.allowStaff(auth) || Checks.isAccountOperationsAdmin(auth, accountId);
};

export const canReadProduct = (auth, accountId) => {
    return Checks.allowStaff(auth) || Checks.isAccountOperationsAdmin(auth, accountId);
};

export const canReadProductList = (auth, accountId) => {
    return Checks.allowStaff(auth) || Checks.isAccountOperationsAdmin(auth, accountId);
};

export const canUpdateProduct = (auth, accountId) => {
    return Checks.allowStaff(auth) || Checks.isAccountOperationsAdmin(auth, accountId);
};

export const canDeleteProduct = (auth) => {
    return Checks.allowStaffAdmin(auth);
};