import React, { useMemo, useState, useEffect, useReducer } from 'react';
import { Alert, Input, Spin, Select, Switch } from "antd";
import DatePicker from '../DatePickerAutoAccept/DatePickerAutoAccept';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import UploadFile from "../UploadFile/UploadFile";
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import FormItemFile from '../FormItemFile/FormItemFile';
import FormButtons from '../FormButtons/FormButtons';
import { isBooleanTrue, isNotNullOrUndefined, isNullOrUndefined, isObjectEmpty, isObjectNotEmpty, isStringEmpty, isStringNotEmpty, removeEmpty } from '../../shared/objectUtils';
import Fieldset from '../FormFieldset/FormFieldset';
import { checkEmailAddressReducer, checkPhoneNumberReducer, isValidEmailAddress, checkEmployeeEmailAddress, checkEmployeePhoneNumber } from '../../shared/formUtils';
import { LoadingOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import axios from 'axios';
import { orderBy } from 'lodash';

const { Option } = Select;

const NewDriver = ({ accountId, cancel }) => {

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.accountUsers.isRecordAddLoading);
    const error = useSelector(state => state.accountUsers.addRecordError);
    const accounts = useSelector(state => state.orchestrator.accounts);

    const [{ emailAddressExists, checkEmailAddressHasError, isCheckEmailAddressLoading, checkEmailAddressError }, checkEmailAddressDispatch] = useReducer(checkEmailAddressReducer, {
        emailAddressExists: null,
        isCheckEmailAddressLoading: false,
        checkEmailAddressHasError: false,
        checkEmailAddressError: null,
    });

    const [{ phoneNumberExists, checkPhoneNumberHasError, isCheckPhoneNumberLoading, checkPhoneNumberError }, checkPhoneNumberDispatch] = useReducer(checkPhoneNumberReducer, {
        phoneNumberExists: null,
        isCheckPhoneNumberLoading: false,
        checkPhoneNumberHasError: false,
        checkPhoneNumberError: null,
    });

    const [hazmatLicenseFrontFile, setHazmatLicenseFrontFile] = useState(null);
    const [hazmatLicenseBackFile, setHazmatLicenseBackFile] = useState(null);
    const [driverLicenseFrontFile, setDriverLicenseFrontFile] = useState(null);
    const [driverLicenseBackFile, setDriverLicenseBackFile] = useState(null);
    const [commercialLicenseFrontFile, setCommercialLicenseFrontFile] = useState(null);
    const [commercialLicenseBackFile, setCommercialLicenseBackFile] = useState(null);
    const [pneumaticLicenseFrontFile, setPneumaticLicenseFrontFile] = useState(null);
    const [pneumaticLicenseBackFile, setPneumaticLicenseBackFile] = useState(null);
    const [mcCertificateLicenseFrontFile, setMcCertificateLicenseFrontFile] = useState(null);
    const [mcCertificateLicenseBackFile, setMcCertificateLicenseBackFile] = useState(null);
    const [explosiveCertifiedLicenseFrontFile, setExplosiveCertifiedLicenseFrontFile] = useState(null);
    const [explosiveCertifiedLicenseBackFile, setExplosiveCertifiedLicenseBackFile] = useState(null);
    const [mshaLicenseFrontFile, setMshaLicenseFrontFile] = useState(null);
    const [mshaLicenseBackFile, setMshaLicenseBackFile] = useState(null);

    const [carriers, setCarriers] = useState([]);
    const [carrierId, setCarrierId] = useState(null);

    const [emailAddress, setEmailAddress] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [hasValidEmailAddress, setHasValidEmailAddres] = useState(false);
    const [hasValidPhoneNumber, setHasValidPhoneNumber] = useState(false);

    useEffect(() => {
        // console.log(`emailAddress: ${emailAddress}`);
        const { cancel, token } = axios.CancelToken.source();
        const timeOutId = setTimeout(() => {
            if (isStringNotEmpty(carrierId) && isStringNotEmpty(emailAddress) && emailAddress.length >= 4) {
                if (isValidEmailAddress(emailAddress)) {
                    checkEmployeeEmailAddress(emailAddress, carrierId, checkEmailAddressDispatch, token);
                }
            }
        }, 500);
        return () => cancel("Searching...") || clearTimeout(timeOutId);
    }, [emailAddress, carrierId]);

    useEffect(() => {
        // console.log(`phoneNumber: ${phoneNumber}`);
        const { cancel, token } = axios.CancelToken.source();
        const timeOutId = setTimeout(() => {
            if (isStringNotEmpty(carrierId) && isStringNotEmpty(phoneNumber) && phoneNumber.length === 10) {
                checkEmployeePhoneNumber(phoneNumber, carrierId, checkPhoneNumberDispatch, token);
            }
        }, 500);
        return () => cancel("Searching...") || clearTimeout(timeOutId);
    }, [phoneNumber, carrierId]);

    useMemo(() => {
        if (isStringNotEmpty(accountId)) {
            setCarrierId(accountId);
        }
        const filteredAccounts = accounts.filter(a => a.entityTypes.includes("CARRIER") === true);
        setCarriers(orderBy(filteredAccounts, ['name'], ['asc']));
    }, [accountId, accounts]);

    const onChangeAccountId = (value) => {
        // console.log(`selected ${value}`);
        setCarrierId(value);
    };

    const onEmailAddressChange = (value) => {
        if (isStringNotEmpty(value) && value.length >= 4) {
            if (isObjectNotEmpty(methods.errors.email)) {
                checkEmailAddressDispatch({ type: "FETCH_FAILURE", payload: { error: 'You must first enter a valid email address' } });
            }
            // console.log(value);
            setEmailAddress(value);
        } else {
            checkEmailAddressDispatch({ type: "FETCH_CLEAR" });
        }
    };

    const onPhoneNumberChange = (value) => {
        if (isStringNotEmpty(value) && value.length === 10) {
            if (isObjectNotEmpty(methods.errors.phone)) {
                checkPhoneNumberDispatch({ type: "FETCH_FAILURE", payload: { error: 'You must first enter a valid phone number' } });
            }
            // console.log(value);
            setPhoneNumber(value);
        } else {
            checkPhoneNumberDispatch({ type: "FETCH_CLEAR" });
        }
    };

    useEffect(() => {
        // console.log(emailAddress);
        // console.log(methods.errors.email);
        // console.log(emailAddressExists);
        if (isStringNotEmpty(emailAddress)) {
            if (isObjectEmpty(methods.errors.email)) {
                if (emailAddressExists !== null && emailAddressExists === false) {
                    setHasValidEmailAddres(true);
                    // console.log('Has a valid email address');
                } else {
                    setHasValidEmailAddres(false);
                }
            } else {
                setHasValidEmailAddres(false);
                checkEmailAddressDispatch({ type: "FETCH_CLEAR" });
            }
        } else {
            setHasValidEmailAddres(false);
            checkEmailAddressDispatch({ type: "FETCH_CLEAR" });
        }
    }, [methods.errors, emailAddress, emailAddressExists]);

    useEffect(() => {
        // console.log(phoneNumber);
        // console.log(methods.errors.phone);
        // console.log(phoneNumberExists);
        if (isStringNotEmpty(phoneNumber)) {
            if (isObjectEmpty(methods.errors.phone)) {
                if (phoneNumberExists !== null && phoneNumberExists === false) {
                    setHasValidPhoneNumber(true);
                    // console.log('Has a valid phone number');
                } else {
                    setHasValidPhoneNumber(false);
                }
            } else {
                setHasValidPhoneNumber(false);
                checkPhoneNumberDispatch({ type: "FETCH_CLEAR" });
            }
        } else {
            setHasValidPhoneNumber(false);
            checkPhoneNumberDispatch({ type: "FETCH_CLEAR" });
        }
    }, [methods.errors, phoneNumber, phoneNumberExists]);

    const onSubmit = (data) => {
        let cleanedData = removeEmpty(data);

        let emailAndOrPhoneAreValid = false;
        if (isStringNotEmpty(cleanedData.email)) {
            if (hasValidEmailAddress === true) {
                emailAndOrPhoneAreValid = true;
            } else {
                emailAndOrPhoneAreValid = false;
            }
        }
        if (isStringNotEmpty(cleanedData.phone)) {
            if (hasValidPhoneNumber === true) {
                emailAndOrPhoneAreValid = true;
            } else {
                emailAndOrPhoneAreValid = false;
            }
        }

        let driver = {
            isAvailable: true
        };

        if (isObjectNotEmpty(cleanedData) && isBooleanTrue(emailAndOrPhoneAreValid)) {
            if (isNotNullOrUndefined(hazmatLicenseFrontFile) || isNotNullOrUndefined(hazmatLicenseBackFile)) {
                if (isNullOrUndefined(cleanedData.hazmatLicenseExpiryDate) || isStringEmpty(cleanedData.hazmatLicenseNumber)) {
                    if (isNullOrUndefined(cleanedData.hazmatLicenseExpiryDate)) {
                        methods.setError("hazmatLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    if (isStringEmpty(cleanedData.hazmatLicenseNumber)) {
                        methods.setError("hazmatLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    return;
                } else {
                    methods.clearErrors("hazmatLicenseExpiryDate");
                    methods.clearErrors("hazmatLicenseNumber");

                    driver.hazmatLicenseExpiryDate = cleanedData.hazmatLicenseExpiryDate;
                    driver.hazmatLicenseNumber = cleanedData.hazmatLicenseNumber;
                }

                if (isNotNullOrUndefined(hazmatLicenseFrontFile)) {
                    driver.hazmatLicenseFrontFile = hazmatLicenseFrontFile;
                }
                if (isNotNullOrUndefined(hazmatLicenseBackFile)) {
                    driver.hazmatLicenseBackFile = hazmatLicenseBackFile;
                }
            }

            if (isNotNullOrUndefined(driverLicenseFrontFile) || isNotNullOrUndefined(driverLicenseBackFile)) {
                if (isNullOrUndefined(cleanedData.driverLicenseExpiryDate) || isStringEmpty(cleanedData.driverLicenseNumber)) {
                    if (isNullOrUndefined(cleanedData.driverLicenseExpiryDate)) {
                        methods.setError("driverLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    if (isStringEmpty(cleanedData.driverLicenseNumber)) {
                        methods.setError("driverLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    return;
                } else {
                    methods.clearErrors("driverLicenseExpiryDate");
                    methods.clearErrors("driverLicenseNumber");

                    driver.driverLicenseExpiryDate = cleanedData.driverLicenseExpiryDate;
                    driver.driverLicenseNumber = cleanedData.driverLicenseNumber;
                }

                if (isNotNullOrUndefined(driverLicenseFrontFile)) {
                    driver.driverLicenseFrontFile = driverLicenseFrontFile;
                }
                if (isNotNullOrUndefined(driverLicenseBackFile)) {
                    driver.driverLicenseBackFile = driverLicenseBackFile;
                }
            }

            if (isNotNullOrUndefined(commercialLicenseFrontFile) || isNotNullOrUndefined(commercialLicenseBackFile)) {
                if (isNullOrUndefined(cleanedData.commercialLicenseExpiryDate) || isStringEmpty(cleanedData.commercialLicenseNumber)) {
                    if (isNullOrUndefined(cleanedData.commercialLicenseExpiryDate)) {
                        methods.setError("commercialLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    if (isStringEmpty(cleanedData.commercialLicenseNumber)) {
                        methods.setError("commercialLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    return;
                } else {
                    methods.clearErrors("commercialLicenseExpiryDate");
                    methods.clearErrors("commercialLicenseNumber");

                    driver.commercialLicenseExpiryDate = cleanedData.commercialLicenseExpiryDate;
                    driver.commercialLicenseNumber = cleanedData.commercialLicenseNumber;
                }

                if (isNotNullOrUndefined(commercialLicenseFrontFile)) {
                    driver.commercialLicenseFrontFile = commercialLicenseFrontFile;
                }
                if (isNotNullOrUndefined(commercialLicenseBackFile)) {
                    driver.commercialLicenseBackFile = commercialLicenseBackFile;
                }
            }

            if (isNotNullOrUndefined(pneumaticLicenseFrontFile) || isNotNullOrUndefined(pneumaticLicenseBackFile)) {
                if (isNullOrUndefined(cleanedData.pneumaticLicenseExpiryDate) || isStringEmpty(cleanedData.pneumaticLicenseNumber)) {
                    if (isNullOrUndefined(cleanedData.pneumaticLicenseExpiryDate)) {
                        methods.setError("pneumaticLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    if (isStringEmpty(cleanedData.pneumaticLicenseNumber)) {
                        methods.setError("pneumaticLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    return;
                } else {
                    methods.clearErrors("pneumaticLicenseExpiryDate");
                    methods.clearErrors("pneumaticLicenseNumber");

                    driver.pneumaticLicenseExpiryDate = cleanedData.pneumaticLicenseExpiryDate;
                    driver.pneumaticLicenseNumber = cleanedData.pneumaticLicenseNumber;
                }

                if (isNotNullOrUndefined(pneumaticLicenseFrontFile)) {
                    driver.pneumaticLicenseFrontFile = pneumaticLicenseFrontFile;
                }
                if (isNotNullOrUndefined(pneumaticLicenseBackFile)) {
                    driver.pneumaticLicenseBackFile = pneumaticLicenseBackFile;
                }
            }

            if (isNotNullOrUndefined(mcCertificateLicenseFrontFile) || isNotNullOrUndefined(mcCertificateLicenseBackFile)) {
                if (isNullOrUndefined(cleanedData.mcCertificateLicenseExpiryDate) || isStringEmpty(cleanedData.mcCertificateLicenseNumber)) {
                    if (isNullOrUndefined(cleanedData.mcCertificateLicenseExpiryDate)) {
                        methods.setError("mcCertificateLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    if (isStringEmpty(cleanedData.mcCertificateLicenseNumber)) {
                        methods.setError("mcCertificateLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    return;
                } else {
                    methods.clearErrors("mcCertificateLicenseExpiryDate");
                    methods.clearErrors("mcCertificateLicenseNumber");

                    driver.mcCertificateLicenseExpiryDate = cleanedData.mcCertificateLicenseExpiryDate;
                    driver.mcCertificateLicenseNumber = cleanedData.mcCertificateLicenseNumber;
                }

                if (isNotNullOrUndefined(mcCertificateLicenseFrontFile)) {
                    driver.mcCertificateLicenseFrontFile = mcCertificateLicenseFrontFile;
                }
                if (isNotNullOrUndefined(mcCertificateLicenseBackFile)) {
                    driver.mcCertificateLicenseBackFile = mcCertificateLicenseBackFile;
                }
            }

            if (isNotNullOrUndefined(explosiveCertifiedLicenseFrontFile) || isNotNullOrUndefined(explosiveCertifiedLicenseBackFile)) {
                if (isNullOrUndefined(cleanedData.explosiveCertifiedLicenseExpiryDate) || isStringEmpty(cleanedData.explosiveCertifiedLicenseNumber)) {
                    if (isNullOrUndefined(cleanedData.explosiveCertifiedLicenseExpiryDate)) {
                        methods.setError("explosiveCertifiedLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    if (isStringEmpty(cleanedData.explosiveCertifiedLicenseNumber)) {
                        methods.setError("explosiveCertifiedLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    return;
                } else {
                    methods.clearErrors("explosiveCertifiedLicenseExpiryDate");
                    methods.clearErrors("explosiveCertifiedLicenseNumber");

                    driver.explosiveCertifiedLicenseExpiryDate = cleanedData.explosiveCertifiedLicenseExpiryDate;
                    driver.explosiveCertifiedLicenseNumber = cleanedData.explosiveCertifiedLicenseNumber;
                }

                if (isNotNullOrUndefined(explosiveCertifiedLicenseFrontFile)) {
                    driver.explosiveCertifiedLicenseFrontFile = explosiveCertifiedLicenseFrontFile;
                }
                if (isNotNullOrUndefined(explosiveCertifiedLicenseBackFile)) {
                    driver.explosiveCertifiedLicenseBackFile = explosiveCertifiedLicenseBackFile;
                }
            }

            if (isNotNullOrUndefined(mshaLicenseFrontFile) || isNotNullOrUndefined(mshaLicenseBackFile)) {
                if (isNullOrUndefined(cleanedData.mshaLicenseExpiryDate) || isStringEmpty(cleanedData.mshaLicenseNumber)) {
                    if (isNullOrUndefined(cleanedData.mshaLicenseExpiryDate)) {
                        methods.setError("mshaLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    if (isStringEmpty(cleanedData.mshaLicenseNumber)) {
                        methods.setError("mshaLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    return;
                } else {
                    methods.clearErrors("mshaLicenseExpiryDate");
                    methods.clearErrors("mshaLicenseNumber");

                    driver.mshaLicenseExpiryDate = cleanedData.mshaLicenseExpiryDate;
                    driver.mshaLicenseNumber = cleanedData.mshaLicenseNumber;
                }

                if (isNotNullOrUndefined(mshaLicenseFrontFile)) {
                    driver.mshaLicenseFrontFile = mshaLicenseFrontFile;
                }
                if (isNotNullOrUndefined(mshaLicenseBackFile)) {
                    driver.mshaLicenseBackFile = mshaLicenseBackFile;
                }
            }

            let payload = {
                email: data.email,
                phone: data.phone,
                firstName: data.firstName,
                middleName: data.middleName,
                lastName: data.lastName,
                isContractor: data.isContractor,
                isDriver: true,
                isInvited: data.isInvited,
                driver: driver
            };

            dispatch(actionCreators.addAccountUser(payload, carrierId));
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.addAccountUserCancel());
        cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.addAccountUserErrorClear());
        dispatch(actionCreators.getAccounts());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    {(accountId === undefined || accountId === null) &&
                        <Fieldset legend="Choose a Carrier">
                            <FormItem {...formItemLayout} label="Carrier" required
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please Select a Carrier"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChangeAccountId(selected); onChange(selected); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    >
                                        {carriers.map(a => <Option value={a.id} key={a.id}>{a.name}</Option>)}
                                    </Select>
                                )}
                                rules={{ required: 'Required Field' }}
                                name="carrierId"
                            />
                        </Fieldset>
                    }
                    <Fieldset legend="Email or Phone Number are Required">
                        <FormItem {...formItemLayout} label="Email Address"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Input
                                    placeholder="Email Address"
                                    addonAfter={isCheckEmailAddressLoading ? <LoadingOutlined /> : <MailOutlined />}
                                    onBlur={onBlur}
                                    onChange={e => { onEmailAddressChange(e.target.value); onChange(e.target.value); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            rules={{
                                required: false,
                                minLength: { value: 4, message: 'Email Address must be at least 4 characters long' },
                                pattern: {
                                    value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                    message: "Please enter a valid email address"
                                }
                            }}
                            name="email"
                        />
                        {checkEmailAddressHasError && <Alert message={checkEmailAddressError} type="error" />}
                        <FormItem {...formItemLayout} label="Personal Phone #"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Input
                                    placeholder="Personal Phone #"
                                    addonAfter={isCheckPhoneNumberLoading ? <LoadingOutlined /> : <PhoneOutlined />}
                                    onBlur={onBlur}
                                    onChange={e => { onPhoneNumberChange(e.target.value); onChange(e.target.value); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            rules={{
                                required: false,
                                pattern: {
                                    value: /^\d{10}$/,
                                    message: "Please enter a valid 10 digit phone number with no special characters"
                                }
                            }}
                            name="phone"
                            defaultValue={''}
                        />
                        {checkPhoneNumberHasError && <Alert message={checkPhoneNumberError} type="error" />}
                    </Fieldset>
                    {(hasValidEmailAddress === true || hasValidPhoneNumber === true) && (
                        <>
                            <Fieldset legend="User Account will be created with these details if one doesn't already exist.">
                                <FormItem {...formItemLayout} label="First Name" required
                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="First Name" ref={ref} />}
                                    rules={{ required: "Required Field" }}
                                    name="firstName"
                                />
                                <FormItem {...formItemLayout} label="Middle Name"
                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Middle Name" ref={ref} />}
                                    rules={{ required: false }}
                                    name="middleName"
                                />
                                <FormItem {...formItemLayout} label="Last Name" required
                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Last Name" ref={ref} />}
                                    rules={{ required: "Required Field" }}
                                    name="lastName"
                                />
                                <FormItem {...formItemLayout} label="Send Invitation (email or text) to Driver"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Switch
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            onBlur={onBlur}
                                            onChange={(checked, event) => { onChange(checked); }}
                                            checked={value}
                                            name={name}
                                            ref={ref}
                                        />
                                    )}
                                    name="isInvited"
                                    defaultValue={true}
                                />
                            </Fieldset>
                            <Fieldset legend="Driver Details">
                                <FormItem {...formItemLayout} label="Is Contractor"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Switch
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            onBlur={onBlur}
                                            onChange={(checked, event) => { onChange(checked); }}
                                            checked={value}
                                            name={name}
                                            ref={ref}
                                        />
                                    )}
                                    name="isContractor"
                                    defaultValue={false}
                                />
                            </Fieldset>
                            <Fieldset legend="Commercial Drivers License">
                                <FormItem {...formItemLayout} label="Commercial Drivers License #"
                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Commercial Drivers License #" ref={ref} />}
                                    rules={{ required: false }}
                                    name="commercialLicenseNumber"
                                />
                                <FormItem {...formItemLayout} label="Expiration Date"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <DatePicker
                                            placeholder="Expiration Date"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={(date, dateString) => { onChange(date); }}
                                            value={value}
                                            name={name}
                                            onSelect={(date) => { onChange(date); }}
                                            ref={ref}
                                        />
                                    )}
                                    rules={{ required: false }}
                                    name="commercialLicenseExpiryDate"
                                />
                                <FormItemFile {...formItemLayout} label="Front Image" name="commercialLicenseFrontFile">
                                    <UploadFile
                                        beforeUpload={(file) => {
                                            setCommercialLicenseFrontFile(file);
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            setCommercialLicenseFrontFile(null);
                                        }}
                                        message="Please upload a photo of the front of the license."
                                    />
                                </FormItemFile>
                                <FormItemFile {...formItemLayout} label="Back Image" name="commercialLicenseBackFile">
                                    <UploadFile
                                        beforeUpload={(file) => {
                                            setCommercialLicenseBackFile(file);
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            setCommercialLicenseBackFile(null);
                                        }}
                                        message="Please upload a photo of the back of the license."
                                    />
                                </FormItemFile>
                            </Fieldset>
                            <Fieldset legend="Personal Drivers License">
                                <FormItem {...formItemLayout} label="Drivers License #"
                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Drivers License #" ref={ref} />}
                                    rules={{ required: false }}
                                    name="driverLicenseNumber"
                                />
                                <FormItem {...formItemLayout} label="Expiration Date"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <DatePicker
                                            placeholder="Expiration Date"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={(date, dateString) => { onChange(date); }}
                                            value={value}
                                            name={name}
                                            onSelect={(date) => { onChange(date); }}
                                            ref={ref}
                                        />
                                    )}
                                    rules={{ required: false }}
                                    name="driverLicenseExpiryDate"
                                />
                                <FormItemFile {...formItemLayout} label="Front Image" name="driverLicenseFrontFile">
                                    <UploadFile
                                        beforeUpload={(file) => {
                                            setDriverLicenseFrontFile(file);
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            setDriverLicenseFrontFile(null);
                                        }}
                                        message="Please upload a photo of the front of the license."
                                    />
                                </FormItemFile>
                                <FormItemFile {...formItemLayout} label="Back Image" name="driverLicenseBackFile">
                                    <UploadFile
                                        beforeUpload={(file) => {
                                            setDriverLicenseBackFile(file);
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            setDriverLicenseBackFile(null);
                                        }}
                                        message="Please upload a photo of the back of the license."
                                    />
                                </FormItemFile>
                            </Fieldset>
                            <Fieldset legend="Hazmat License">
                                <FormItem {...formItemLayout} label="HazMat License #"
                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="HazMat License #" ref={ref} />}
                                    rules={{ required: false }}
                                    name="hazmatLicenseNumber"
                                />
                                <FormItem {...formItemLayout} label="Expiration Date"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <DatePicker
                                            placeholder="Expiration Date"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={(date, dateString) => { onChange(date); }}
                                            value={value}
                                            name={name}
                                            onSelect={(date) => { onChange(date); }}
                                            ref={ref}
                                        />
                                    )}
                                    rules={{ required: false }}
                                    name="hazmatLicenseExpiryDate"
                                />
                                <FormItemFile {...formItemLayout} label="Front Image" name="hazmatLicenseFrontFile">
                                    <UploadFile
                                        beforeUpload={(file) => {
                                            setHazmatLicenseFrontFile(file);
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            setHazmatLicenseFrontFile(null);
                                        }}
                                        message="Please upload a photo of the front of the license."
                                    />
                                </FormItemFile>
                                <FormItemFile {...formItemLayout} label="Back Image" name="hazmatLicenseBackFile">
                                    <UploadFile
                                        beforeUpload={(file) => {
                                            setHazmatLicenseBackFile(file);
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            setHazmatLicenseBackFile(null);
                                        }}
                                        message="Please upload a photo of the back of the license."
                                    />
                                </FormItemFile>
                            </Fieldset>
                            <Fieldset legend="Pneumatic License">
                                <FormItem {...formItemLayout} label="Pneumatic License #"
                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Pneumatic License #" ref={ref} />}
                                    rules={{ required: false }}
                                    name="pneumaticLicenseNumber"
                                />
                                <FormItem {...formItemLayout} label="Expiration Date"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <DatePicker
                                            placeholder="Expiration Date"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={(date, dateString) => { onChange(date); }}
                                            value={value}
                                            name={name}
                                            onSelect={(date) => { onChange(date); }}
                                            ref={ref}
                                        />
                                    )}
                                    rules={{ required: false }}
                                    name="pneumaticLicenseExpiryDate"
                                />
                                <FormItemFile {...formItemLayout} label="Front Image" name="pneumaticLicenseFrontFile">
                                    <UploadFile
                                        beforeUpload={(file) => {
                                            setPneumaticLicenseFrontFile(file);
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            setPneumaticLicenseFrontFile(null);
                                        }}
                                        message="Please upload a photo of the front of the license."
                                    />
                                </FormItemFile>
                                <FormItemFile {...formItemLayout} label="Back Image" name="pneumaticLicenseBackFile">
                                    <UploadFile
                                        beforeUpload={(file) => {
                                            setPneumaticLicenseBackFile(file);
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            setPneumaticLicenseBackFile(null);
                                        }}
                                        message="Please upload a photo of the back of the license."
                                    />
                                </FormItemFile>
                            </Fieldset>
                            <Fieldset legend="MC Certificate License">
                                <FormItem {...formItemLayout} label="MC Certificate License #"
                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="MC Certificate License #" ref={ref} />}
                                    rules={{ required: false }}
                                    name="mcCertificateLicenseNumber"
                                />
                                <FormItem {...formItemLayout} label="Expiration Date"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <DatePicker
                                            placeholder="Expiration Date"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={(date, dateString) => { onChange(date); }}
                                            value={value}
                                            name={name}
                                            onSelect={(date) => { onChange(date); }}
                                            ref={ref}
                                        />
                                    )}
                                    rules={{ required: false }}
                                    name="mcCertificateLicenseExpiryDate"
                                />
                                <FormItemFile {...formItemLayout} label="Front Image" name="mcCertificateLicenseFrontFile">
                                    <UploadFile
                                        beforeUpload={(file) => {
                                            setMcCertificateLicenseFrontFile(file);
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            setMcCertificateLicenseFrontFile(null);
                                        }}
                                        message="Please upload a photo of the front of the license."
                                    />
                                </FormItemFile>
                                <FormItemFile {...formItemLayout} label="Back Image" name="mcCertificateLicenseBackFile">
                                    <UploadFile
                                        beforeUpload={(file) => {
                                            setMcCertificateLicenseBackFile(file);
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            setMcCertificateLicenseBackFile(null);
                                        }}
                                        message="Please upload a photo of the back of the license."
                                    />
                                </FormItemFile>
                            </Fieldset>
                            <Fieldset legend="Explosive Certified License">
                                <FormItem {...formItemLayout} label="Explosive Certified License #"
                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Explosive Certified License #" ref={ref} />}
                                    rules={{ required: false }}
                                    name="explosiveCertifiedLicenseNumber"
                                />
                                <FormItem {...formItemLayout} label="Expiration Date"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <DatePicker
                                            placeholder="Expiration Date"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={(date, dateString) => { onChange(date); }}
                                            value={value}
                                            name={name}
                                            onSelect={(date) => { onChange(date); }}
                                            ref={ref}
                                        />
                                    )}
                                    rules={{ required: false }}
                                    name="explosiveCertifiedLicenseExpiryDate"
                                />
                                <FormItemFile {...formItemLayout} label="Front Image" name="explosiveCertifiedLicenseFrontFile">
                                    <UploadFile
                                        beforeUpload={(file) => {
                                            setExplosiveCertifiedLicenseFrontFile(file);
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            setExplosiveCertifiedLicenseFrontFile(null);
                                        }}
                                        message="Please upload a photo of the front of the license."
                                    />
                                </FormItemFile>
                                <FormItemFile {...formItemLayout} label="Back Image" name="explosiveCertifiedLicenseBackFile">
                                    <UploadFile
                                        beforeUpload={(file) => {
                                            setExplosiveCertifiedLicenseBackFile(file);
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            setExplosiveCertifiedLicenseBackFile(null);
                                        }}
                                        message="Please upload a photo of the back of the license."
                                    />
                                </FormItemFile>
                            </Fieldset>
                            <Fieldset legend="MSHA License">
                                <FormItem {...formItemLayout} label="MSHA License #"
                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="MSHA License #" ref={ref} />}
                                    rules={{ required: false }}
                                    name="mshaLicenseNumber"
                                />
                                <FormItem {...formItemLayout} label="Expiration Date"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <DatePicker
                                            placeholder="Expiration Date"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={(date, dateString) => { onChange(date); }}
                                            value={value}
                                            name={name}
                                            onSelect={(date) => { onChange(date); }}
                                            ref={ref}
                                        />
                                    )}
                                    rules={{ required: false }}
                                    name="mshaLicenseExpiryDate"
                                />
                                <FormItemFile {...formItemLayout} label="Front Image" name="mshaLicenseFrontFile">
                                    <UploadFile
                                        beforeUpload={(file) => {
                                            setMshaLicenseFrontFile(file);
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            setMshaLicenseFrontFile(null);
                                        }}
                                        message="Please upload a photo of the front of the license."
                                    />
                                </FormItemFile>
                                <FormItemFile {...formItemLayout} label="Back Image" name="mshaLicenseBackFile">
                                    <UploadFile
                                        beforeUpload={(file) => {
                                            setMshaLicenseBackFile(file);
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            setMshaLicenseBackFile(null);
                                        }}
                                        message="Please upload a photo of the back of the license."
                                    />
                                </FormItemFile>
                            </Fieldset>
                        </>
                    )}
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} cancelDisabled={(isLoading === true && error === null)} submitDisabled={(isLoading === true && error === null) || (hasValidEmailAddress === false && hasValidPhoneNumber === false)} submitText="Create Driver" />
            </Form>
        </FormProvider>
    );
};

export default NewDriver;