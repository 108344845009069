import { isObjectNotEmpty, isStringNotEmpty } from "./objectUtils";

const getErrorMessage = (error) => {
    if (isObjectNotEmpty(error) && isObjectNotEmpty(error.response) && isObjectNotEmpty(error.response.data) && isStringNotEmpty(error.response.data.message)) {
        return error.response.data.message;
    } else if (isObjectNotEmpty(error) && isStringNotEmpty(error.message) && typeof (error.message) === 'string') {
        return error.message;
    } else if (typeof error === 'string') {
        return error;
    } else {
        return "An unexpected error has occurred.";
    }
};

export default {
    getErrorMessage
};