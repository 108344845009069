import React, { useEffect, useReducer } from "react";
import MaskedInput from 'antd-mask-input';
import { CheckCircleTwoTone, LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { checkAccountDOTNumberReducer, checkAccountDOTNumber } from '../../shared/formUtils';
import axios from 'axios';
import { isBooleanTrue, isBooleanFalse, isObjectEmpty, isObjectNotEmpty, isStringNotEmpty, isNotNullOrUndefined } from '../../shared/objectUtils';
import { useFormContext } from "react-hook-form";
import { Alert } from "antd";
import FormItem from "../FormItem/FormItem";

const FormItemDOTNumber = ({ format = 'vertical', required = false, name = "dotNum", defaultValue, accountDOTNumber, setAccountDOTNumber, hasValidAccountDOTNumber, setHasValidAccountDOTNumber, setExistingAccount = null, setAccountExists = null, account = null, allowSelf = true, allowExisting = false }) => {
    //#region useFormContext

    const methods = useFormContext();

    //#endregion
    //#region useReducers

    const [{ accountDOTNumberExists, accountDOTNumberExistingAccount, checkAccountDOTNumberHasError, isCheckAccountDOTNumberLoading, checkAccountDOTNumberError }, checkAccountDOTNumberDispatch] = useReducer(checkAccountDOTNumberReducer, {
        accountDOTNumberExists: null,
        accountDOTNumberExistingAccount: null,
        isCheckAccountDOTNumberLoading: false,
        checkAccountDOTNumberHasError: false,
        checkAccountDOTNumberError: null,
    });

    //#endregion
    //#region onChanges

    const onChangeAccountDOTNumber = (value) => {
        if (isStringNotEmpty(value) && value.length >= 6) {
            if (isObjectNotEmpty(methods.errors.dotNum)) {
                checkAccountDOTNumberDispatch({ type: "FETCH_FAILURE", payload: { error: 'You must first enter a valid unique DOT Number.' } });
            }
            // console.log(value);
            setAccountDOTNumber(value);
        } else {
            checkAccountDOTNumberDispatch({ type: "FETCH_CLEAR" });
        }
    };

    //#endregion
    //#region useEffects

    useEffect(() => {
        //console.log(`accountDOTNumber: ${accountDOTNumber}`);
        const { cancel, token } = axios.CancelToken.source();
        const timeOutId = setTimeout(() => {
            if (isStringNotEmpty(accountDOTNumber) && accountDOTNumber.length >= 6) {
                checkAccountDOTNumber(accountDOTNumber, account, allowSelf, allowExisting, checkAccountDOTNumberDispatch, token);
            }
        }, 500);
        return () => cancel("Searching...") || clearTimeout(timeOutId);
    }, [accountDOTNumber, account, allowSelf, allowExisting]);

    useEffect(() => {
        // console.log(accountDOTNumber);
        // console.log(methods.errors.dotNum);
        // console.log(accountDOTNumberExists);
        if (isStringNotEmpty(accountDOTNumber)) {
            if (isObjectEmpty(methods.errors.dotNum)) {
                if (isBooleanFalse(accountDOTNumberExists)) {
                    setHasValidAccountDOTNumber(true);
                    // console.log('Has a valid DOT Number');
                } else if (isBooleanTrue(allowSelf) && isBooleanTrue(accountDOTNumberExists) && isObjectNotEmpty(account) && isStringNotEmpty(account.id) && isObjectNotEmpty(accountDOTNumberExistingAccount) && isStringNotEmpty(accountDOTNumberExistingAccount.id) && account.id === accountDOTNumberExistingAccount.id) {
                    setHasValidAccountDOTNumber(true);
                    // console.log('DOT Number matches what is already saved for this account');
                } else {
                    setHasValidAccountDOTNumber(false);
                }
            } else {
                setHasValidAccountDOTNumber(false);
                checkAccountDOTNumberDispatch({ type: "FETCH_CLEAR" });
            }
        } else {
            setHasValidAccountDOTNumber(false);
            checkAccountDOTNumberDispatch({ type: "FETCH_CLEAR" });
        }
    }, [methods.errors, accountDOTNumber, account, accountDOTNumberExists, accountDOTNumberExistingAccount, allowSelf]);

    useEffect(() => {
        if (isNotNullOrUndefined(setAccountExists) && isNotNullOrUndefined(accountDOTNumberExists)) {
            setAccountExists(accountDOTNumberExists)
        }
    }, [accountDOTNumberExists]);

    useEffect(() => {
        if (isNotNullOrUndefined(setExistingAccount) && isNotNullOrUndefined(accountDOTNumberExistingAccount)) {
            setExistingAccount(accountDOTNumberExistingAccount)
        }
    }, [accountDOTNumberExistingAccount]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { sm: 24, md: 24 },
        wrapperCol: { sm: 24, md: 24 },
    };

    const formItemLayoutHorizontal = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    //#endregion

    if (format === 'vertical') {
        return (
            <>
                <FormItem {...formItemLayout} label="US DOT #" format="vertical" required={required}
                    render={({ onChange, onBlur, value, name, ref }) => (
                        <MaskedInput
                            onBlur={onBlur}
                            onChange={e => {
                                let value = null;
                                if (isStringNotEmpty(e.target.value)) {
                                    value = e.target.value.replace(/[^0-9]/g, "");
                                }
                                onChangeAccountDOTNumber(value);
                                onChange(value);
                            }}
                            value={value}
                            name={name}
                            placeholder="DOT #"
                            addonAfter={isStringNotEmpty(value) && isBooleanTrue(hasValidAccountDOTNumber) ? <CheckCircleTwoTone title="Valid" alt="Valid" twoToneColor="#52c41a" /> : (isBooleanTrue(isCheckAccountDOTNumberLoading) ? <LoadingOutlined /> : <SearchOutlined />)}
                            mask="11111111"
                            ref={ref}
                        />
                    )}
                    rules={{
                        required: (required ? 'Required Field' : false),
                        pattern: {
                            value: /^\d{1,8}$/,
                            message: "Please enter a valid number with no special characters"
                        },
                        maxLength: { value: 8, message: 'DOT Number cannot be more than 8 characters long' },
                        minLength: { value: 6, message: 'DOT Number must be at least 6 characters long' },
                    }}
                    name={name}
                    defaultValue={defaultValue}
                />
                {checkAccountDOTNumberHasError && <Alert message={checkAccountDOTNumberError} type="error" style={{ marginBottom: 8 }} />}
            </>
        );
    } else if (format === 'horizontal') {
        return (
            <>
                <FormItem {...formItemLayoutHorizontal} label="US DOT #" format="horizontal" required={required}
                    render={({ onChange, onBlur, value, name, ref }) => (
                        <MaskedInput
                            onBlur={onBlur}
                            onChange={e => {
                                let value = null;
                                if (isStringNotEmpty(e.target.value)) {
                                    value = e.target.value.replace(/[^0-9]/g, "");
                                }
                                onChangeAccountDOTNumber(value);
                                onChange(value);
                            }}
                            value={value}
                            name={name}
                            placeholder="DOT #"
                            addonAfter={isStringNotEmpty(value) && isBooleanTrue(hasValidAccountDOTNumber) ? <CheckCircleTwoTone title="Valid" alt="Valid" twoToneColor="#52c41a" /> : (isBooleanTrue(isCheckAccountDOTNumberLoading) ? <LoadingOutlined /> : <SearchOutlined />)}
                            mask="11111111"
                            ref={ref}
                        />
                    )}
                    rules={{
                        required: (required ? 'Required Field' : false),
                        pattern: {
                            value: /^\d{1,8}$/,
                            message: "Please enter a valid number with no special characters"
                        },
                        maxLength: { value: 8, message: 'DOT Number cannot be more than 8 characters long' },
                        minLength: { value: 6, message: 'DOT Number must be at least 6 characters long' },
                    }}
                    name={name}
                    defaultValue={defaultValue}
                />
                {checkAccountDOTNumberHasError && <Alert message={checkAccountDOTNumberError} type="error" style={{ marginBottom: 8 }} />}
            </>
        );
    } else {
        return null;
    }
};

export default FormItemDOTNumber;