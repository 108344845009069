import React from 'react';
import { withRouter } from 'react-router-dom';
import { Layout } from "antd";
import classes from "./Terms.module.scss";
import irisBackground from "../../assets/img/backgrounds/background-1.jpg";
import TermsOfUse from "../../components/TermsOfUse/TermsOfUse";
import PrivacyPolicy from "../../components/PrivacyPolicy/PrivacyPolicy";
import Logo from "../../components/Logo/Logo";

const Terms = (props) => {
    return (
        <Layout className={classes.termsBackground} style={{ minHeight: '100vh', backgroundImage: `url(${irisBackground})` }}>
            <Logo size={"custom"} style={{ margin: '20px auto', height: '100px', padding: 10, }} height={100} />
            <TermsOfUse />
            <PrivacyPolicy />
        </Layout>
    );
};

export default withRouter(Terms);