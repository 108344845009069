import Checks from '../entitlementUtils';

export const canCreateAccountUser = (auth, accountId) => {
    return Checks.allowStaff(auth) || Checks.isAccountOperationsAdmin(auth, accountId);
};

export const canReadAccountUser = (auth, accountId, accountUser) => {
    return Checks.allowStaff(auth) || Checks.isAccountOperationsAdmin(auth, accountId) || Checks.isAccountUserSelf(auth, accountUser);
};

export const canReadAccountUserList = (auth, accountId) => {
    return Checks.allowStaff(auth) || Checks.isAccountOperationsAdmin(auth, accountId);
};

export const canVerifyAccountUser = (auth, accountId) => {
    return Checks.allowStaff(auth) || Checks.isAccountOperationsAdmin(auth, accountId);
};

export const canUpdateAccountUser = (auth, accountId, accountUser) => {
    return Checks.allowStaff(auth) || Checks.isAccountOperationsAdmin(auth, accountId) || Checks.isAccountUserSelf(auth, accountUser);
};

export const canDeleteAccountUser = (auth, accountId) => {
    return Checks.allowStaff(auth) || Checks.isAccountOperationsAdmin(auth, accountId);
};