import * as actionTypes from "../actions/actionTypes";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { isObjectNotEmpty, isStringEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import LocalStorage from "../../shared/localStorageUtils";
import ErrorUtils from "../../shared/errorUtils";

//#region Get Orchestrator Methods

export const getOrchestratorSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_ORCHESTRATOR_SUCCESS,
        payload: payload
    }
};

//#endregion
//#region Orchestrator Methods

export const getOrchestrator = (force = false) => {
    return async (dispatch) => {
        try {
            await dispatch(getTrailerTypes(force));
            await dispatch(getFeatures(force));
            await dispatch(getAccounts(force));
            await dispatch(getStaff(force));
            await dispatch(getLineItemTypes(force));
            await dispatch(getStates(force));
            await dispatch(getCommodities(force));
            await dispatch(getServices(force));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
        }

        return dispatch(getOrchestratorSuccess({}));
    }
};

export const getAccountOrchestrator = (accountId, accountType = null, force = false) => {
    return async (dispatch) => {
        try {
            await dispatch(getEmployees(accountId, force));
            if ((isStringNotEmpty(accountType) && accountType === "CARRIER") || isStringEmpty(accountType)) {
                await dispatch(getDrivers(accountId, force));
                await dispatch(getAssets(accountId, force));
            }
            await dispatch(getLinkedAccounts(accountId, force));
            await dispatch(getLocations(accountId, force));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
        }

        return dispatch(getOrchestratorSuccess({}));
    }
};

export const getAccounts = (force = false) => {
    return async (dispatch) => {
        try {
            const now = new Date();
            let accounts = [];
            let accountsLastUpdatedAt = null;
            let accountsString = await LocalStorage.getItem('accounts');
            let accountsLastUpdatedAtString = await LocalStorage.getItem('accountsLastUpdatedAt');
            if (isStringNotEmpty(accountsString) && isStringNotEmpty(accountsLastUpdatedAtString)) {
                // already there so load into redux
                accounts = JSON.parse(accountsString);
                accountsLastUpdatedAt = new Date(accountsLastUpdatedAtString);
            }

            if (force === true || accounts.length === 0 || accountsLastUpdatedAt === null || accountsLastUpdatedAt.setHours(accountsLastUpdatedAt.getHours() + 24) < now) {
                accounts = await Data.getAllAccounts();
                accountsLastUpdatedAt = now;
                await LocalStorage.setItem('accounts', JSON.stringify(accounts));
                await LocalStorage.setItem('accountsLastUpdatedAt', accountsLastUpdatedAt.toString());
            }

            return dispatch(getOrchestratorSuccess({ accounts: accounts, accountsLastUpdatedAt: accountsLastUpdatedAt }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
        }

        return dispatch(getOrchestratorSuccess({}));
    }
};

export const getTrailerTypes = (force = false) => {
    return async (dispatch) => {
        try {
            const now = new Date();
            let trailerTypes = [];
            let trailerTypesLastUpdatedAt = null;
            let trailerTypesString = await LocalStorage.getItem('trailerTypes');
            let trailerTypesLastUpdatedAtString = await LocalStorage.getItem('trailerTypesLastUpdatedAt');
            if (isStringNotEmpty(trailerTypesString) && isStringNotEmpty(trailerTypesLastUpdatedAtString)) {
                // already there so load into redux
                trailerTypes = JSON.parse(trailerTypesString);
                trailerTypesLastUpdatedAt = new Date(trailerTypesLastUpdatedAtString);
            }

            if (force === true || trailerTypes.length === 0 || trailerTypesLastUpdatedAt === null || trailerTypesLastUpdatedAt.setHours(trailerTypesLastUpdatedAt.getHours() + 24) < now) {
                trailerTypes = await Data.getAllTrailerTypes();
                trailerTypesLastUpdatedAt = now;
                await LocalStorage.setItem('trailerTypes', JSON.stringify(trailerTypes));
                await LocalStorage.setItem('trailerTypesLastUpdatedAt', trailerTypesLastUpdatedAt.toString());
            }

            return dispatch(getOrchestratorSuccess({ trailerTypes: trailerTypes, trailerTypesLastUpdatedAt: trailerTypesLastUpdatedAt }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
        }

        return dispatch(getOrchestratorSuccess({}));
    }
};

export const getLineItemTypes = (force = false) => {
    return async (dispatch, getState) => {
        try {
            await dispatch(getTrailerTypes(false));
            const now = new Date();
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const trailerTypes = [...orchestratorState.trailerTypes];

            let lineItemTypes = [];
            let lineItemTypesLastUpdatedAt = null;
            let lineItemTypesString = await LocalStorage.getItem('lineItemTypes');
            let lineItemTypesLastUpdatedAtString = await LocalStorage.getItem('lineItemTypesLastUpdatedAt');
            if (isStringNotEmpty(lineItemTypesString) && isStringNotEmpty(lineItemTypesLastUpdatedAtString)) {
                // already there so load into redux
                lineItemTypes = JSON.parse(lineItemTypesString);
                lineItemTypesLastUpdatedAt = new Date(lineItemTypesLastUpdatedAtString);
            }

            if (force === true || lineItemTypes.length === 0 || lineItemTypesLastUpdatedAt === null || lineItemTypesLastUpdatedAt.setHours(lineItemTypesLastUpdatedAt.getHours() + 24) < now) {
                lineItemTypes = await Data.getAllLineItemTypes(trailerTypes);
                lineItemTypesLastUpdatedAt = now;
                await LocalStorage.setItem('lineItemTypes', JSON.stringify(lineItemTypes));
                await LocalStorage.setItem('lineItemTypesLastUpdatedAt', lineItemTypesLastUpdatedAt.toString());
            }

            return dispatch(getOrchestratorSuccess({ lineItemTypes: lineItemTypes, lineItemTypesLastUpdatedAt: lineItemTypesLastUpdatedAt }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
        }

        return dispatch(getOrchestratorSuccess({}));
    }
};

export const getStaff = (force = false) => {
    return async (dispatch) => {
        try {
            const now = new Date();
            let staff = [];
            let staffLastUpdatedAt = null;
            let staffString = await LocalStorage.getItem('staff');
            let staffLastUpdatedAtString = await LocalStorage.getItem('staffLastUpdatedAt');
            if (isStringNotEmpty(staffString) && isStringNotEmpty(staffLastUpdatedAtString)) {
                // already there so load into redux
                staff = JSON.parse(staffString);
                staffLastUpdatedAt = new Date(staffLastUpdatedAtString);
            }

            if (force === true || staff.length === 0 || staffLastUpdatedAt === null || staffLastUpdatedAt.setHours(staffLastUpdatedAt.getHours() + 24) < now) {
                staff = await Data.getAllStaff();
                staffLastUpdatedAt = now;
                await LocalStorage.setItem('staff', JSON.stringify(staff));
                await LocalStorage.setItem('staffLastUpdatedAt', staffLastUpdatedAt.toString());
            }

            return dispatch(getOrchestratorSuccess({ staff: staff, staffLastUpdatedAt: staffLastUpdatedAt }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
        }

        return dispatch(getOrchestratorSuccess({}));
    }
};

export const getStates = (force = false) => {
    return async (dispatch) => {
        try {
            const now = new Date();
            let states = [];
            let statesLastUpdatedAt = null;
            let statesString = await LocalStorage.getItem('states');
            let statesLastUpdatedAtString = await LocalStorage.getItem('statesLastUpdatedAt');
            if (isStringNotEmpty(statesString) && isStringNotEmpty(statesLastUpdatedAtString)) {
                // already there so load into redux
                states = JSON.parse(statesString);
                statesLastUpdatedAt = new Date(statesLastUpdatedAtString);
            }

            if (force === true || states.length === 0 || statesLastUpdatedAt === null || statesLastUpdatedAt.setHours(statesLastUpdatedAt.getHours() + 24) < now) {
                states = await Data.getAllStates();
                statesLastUpdatedAt = now;
                await LocalStorage.setItem('states', JSON.stringify(states));
                await LocalStorage.setItem('statesLastUpdatedAt', statesLastUpdatedAt.toString());
            }

            return dispatch(getOrchestratorSuccess({ states: states, statesLastUpdatedAt: statesLastUpdatedAt }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
        }

        return dispatch(getOrchestratorSuccess({}));
    }
};

export const getCommodities = (force = false) => {
    return async (dispatch) => {
        try {
            const now = new Date();
            let commodities = [];
            let commoditiesLastUpdatedAt = null;
            let commoditiesString = await LocalStorage.getItem('commodities');
            let commoditiesLastUpdatedAtString = await LocalStorage.getItem('commoditiesLastUpdatedAt');
            if (isStringNotEmpty(commoditiesString) && isStringNotEmpty(commoditiesLastUpdatedAtString)) {
                // already there so load into redux
                commodities = JSON.parse(commoditiesString);
                commoditiesLastUpdatedAt = new Date(commoditiesLastUpdatedAtString);
            }

            if (force === true || commodities.length === 0 || commoditiesLastUpdatedAt === null || commoditiesLastUpdatedAt.setHours(commoditiesLastUpdatedAt.getHours() + 24) < now) {
                commodities = await Data.getAllCommodities();
                commoditiesLastUpdatedAt = now;
                await LocalStorage.setItem('commodities', JSON.stringify(commodities));
                await LocalStorage.setItem('commoditiesLastUpdatedAt', commoditiesLastUpdatedAt.toString());
            }

            return dispatch(getOrchestratorSuccess({ commodities: commodities, commoditiesLastUpdatedAt: commoditiesLastUpdatedAt }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
        }

        return dispatch(getOrchestratorSuccess({}));
    }
};

export const getFeatures = (force = false) => {
    return async (dispatch) => {
        try {
            const now = new Date();
            let features = [];
            let featuresLastUpdatedAt = null;
            let featuresString = await LocalStorage.getItem('features');
            let featuresLastUpdatedAtString = await LocalStorage.getItem('featuresLastUpdatedAt');
            if (isStringNotEmpty(featuresString) && isStringNotEmpty(featuresLastUpdatedAtString)) {
                // already there so load into redux
                features = JSON.parse(featuresString);
                featuresLastUpdatedAt = new Date(featuresLastUpdatedAtString);
            }

            if (force === true || features.length === 0 || featuresLastUpdatedAt === null || featuresLastUpdatedAt.setHours(featuresLastUpdatedAt.getHours() + 24) < now) {
                features = await Data.getAllFeatures();
                featuresLastUpdatedAt = now;
                await LocalStorage.setItem('features', JSON.stringify(features));
                await LocalStorage.setItem('featuresLastUpdatedAt', featuresLastUpdatedAt.toString());
            }

            return dispatch(getOrchestratorSuccess({ features: features, featuresLastUpdatedAt: featuresLastUpdatedAt }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
        }

        return dispatch(getOrchestratorSuccess({}));
    }
};

export const getServices = (force = false) => {
    return async (dispatch, getState) => {
        try {
            await dispatch(getFeatures(false));
            const now = new Date();
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const features = [...orchestratorState.features];

            let services = [];
            let servicesLastUpdatedAt = null;
            let servicesString = await LocalStorage.getItem('services');
            let servicesLastUpdatedAtString = await LocalStorage.getItem('servicesLastUpdatedAt');
            if (isStringNotEmpty(servicesString) && isStringNotEmpty(servicesLastUpdatedAtString)) {
                // already there so load into redux
                services = JSON.parse(servicesString);
                servicesLastUpdatedAt = new Date(servicesLastUpdatedAtString);
            }

            if (force === true || services.length === 0 || servicesLastUpdatedAt === null || servicesLastUpdatedAt.setHours(servicesLastUpdatedAt.getHours() + 24) < now) {
                services = await Data.getAllServices(features);
                servicesLastUpdatedAt = now;
                await LocalStorage.setItem('services', JSON.stringify(services));
                await LocalStorage.setItem('servicesLastUpdatedAt', servicesLastUpdatedAt.toString());
            }

            return dispatch(getOrchestratorSuccess({ services: services, servicesLastUpdatedAt: servicesLastUpdatedAt }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
        }

        return dispatch(getOrchestratorSuccess({}));
    }
};

export const getEmployees = (accountId, force = false) => {
    return async (dispatch, getState) => {
        try {
            await dispatch(getAccounts(false));
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const accounts = [...orchestratorState.accounts];

            const now = new Date();
            let allEmployees = {};
            let employees = [];
            let employeesLastUpdatedAt = null;
            let allEmployeesString = await LocalStorage.getItem('employees');
            if (isStringNotEmpty(allEmployeesString)) {
                // already there so load into redux
                allEmployees = JSON.parse(allEmployeesString);
            }

            if (isObjectNotEmpty(allEmployees) && isStringNotEmpty(accountId) && isObjectNotEmpty(allEmployees[accountId])) {
                employees = allEmployees[accountId].records;
                employeesLastUpdatedAt = new Date(allEmployees[accountId].lastUpdatedAt);
            }

            if (force === true || employees.length === 0 || employeesLastUpdatedAt === null || employeesLastUpdatedAt.setHours(employeesLastUpdatedAt.getHours() + 24) < now) {
                employees = await Data.getEmployeesByAccountId(accountId, accounts);
                employeesLastUpdatedAt = now;
            }

            let newEmployees = {};
            newEmployees[accountId] = {
                records: employees,
                lastUpdatedAt: employeesLastUpdatedAt
            };

            let updatedAllEmployees = { ...allEmployees, ...newEmployees };

            await LocalStorage.setItem('employees', JSON.stringify(updatedAllEmployees));
            return dispatch(getOrchestratorSuccess({ employees: updatedAllEmployees }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
        }

        return dispatch(getOrchestratorSuccess({}));
    }
};

export const getDrivers = (accountId, force = false) => {
    return async (dispatch, getState) => {
        try {
            await dispatch(getAccounts(false));
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const accounts = [...orchestratorState.accounts];

            const now = new Date();
            let allDrivers = {};
            let drivers = [];
            let driversLastUpdatedAt = null;
            let allDriversString = await LocalStorage.getItem('drivers');
            if (isStringNotEmpty(allDriversString)) {
                // already there so load into redux
                allDrivers = JSON.parse(allDriversString);
            }

            if (isObjectNotEmpty(allDrivers) && isStringNotEmpty(accountId) && isObjectNotEmpty(allDrivers[accountId])) {
                drivers = allDrivers[accountId].records;
                driversLastUpdatedAt = new Date(allDrivers[accountId].lastUpdatedAt);
            }

            if (force === true || drivers.length === 0 || driversLastUpdatedAt === null || driversLastUpdatedAt.setHours(driversLastUpdatedAt.getHours() + 24) < now) {
                drivers = await Data.getDriversByAccountId(accountId, accounts);
                driversLastUpdatedAt = now;
            }

            let newDrivers = {};
            newDrivers[accountId] = {
                records: drivers,
                lastUpdatedAt: driversLastUpdatedAt
            };

            let updatedAllDrivers = { ...allDrivers, ...newDrivers };

            await LocalStorage.setItem('drivers', JSON.stringify(updatedAllDrivers));
            return dispatch(getOrchestratorSuccess({ drivers: updatedAllDrivers }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
        }

        return dispatch(getOrchestratorSuccess({}));
    }
};

export const getAssets = (accountId, force = false) => {
    return async (dispatch, getState) => {
        try {
            await dispatch(getTrailerTypes(false));
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const trailerTypes = [...orchestratorState.trailerTypes];

            const now = new Date();
            let allAssets = {};
            let assets = [];
            let assetsLastUpdatedAt = null;
            let allAssetsString = await LocalStorage.getItem('assets');
            if (isStringNotEmpty(allAssetsString)) {
                // already there so load into redux
                allAssets = JSON.parse(allAssetsString);
            }

            if (isObjectNotEmpty(allAssets) && isStringNotEmpty(accountId) && isObjectNotEmpty(allAssets[accountId])) {
                assets = allAssets[accountId].records;
                assetsLastUpdatedAt = new Date(allAssets[accountId].lastUpdatedAt);
            }

            if (force === true || assets.length === 0 || assetsLastUpdatedAt === null || assetsLastUpdatedAt.setHours(assetsLastUpdatedAt.getHours() + 24) < now) {
                assets = await Data.getAssetsByAccountId(accountId, trailerTypes);
                assetsLastUpdatedAt = now;
            }

            let newAssets = {};
            newAssets[accountId] = {
                records: assets,
                lastUpdatedAt: assetsLastUpdatedAt
            };

            let updatedAllAssets = { ...allAssets, ...newAssets };

            await LocalStorage.setItem('assets', JSON.stringify(updatedAllAssets));
            return dispatch(getOrchestratorSuccess({ assets: updatedAllAssets }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
        }

        return dispatch(getOrchestratorSuccess({}));
    }
};

export const getLinkedAccounts = (accountId, force = false) => {
    return async (dispatch) => {
        try {
            const now = new Date();
            let allLinkedAccounts = {};
            let linkedAccounts = [];
            let linkedAccountsLastUpdatedAt = null;
            let allLinkedAccountsString = await LocalStorage.getItem('linkedAccounts');
            if (isStringNotEmpty(allLinkedAccountsString)) {
                // already there so load into redux
                allLinkedAccounts = JSON.parse(allLinkedAccountsString);
            }

            if (isObjectNotEmpty(allLinkedAccounts) && isStringNotEmpty(accountId) && isObjectNotEmpty(allLinkedAccounts[accountId])) {
                linkedAccounts = allLinkedAccounts[accountId].records;
                linkedAccountsLastUpdatedAt = new Date(allLinkedAccounts[accountId].lastUpdatedAt);
            }

            if (force === true || linkedAccounts.length === 0 || linkedAccountsLastUpdatedAt === null || linkedAccountsLastUpdatedAt.setHours(linkedAccountsLastUpdatedAt.getHours() + 24) < now) {
                linkedAccounts = await Data.getLinkedAccountsByAccountId(accountId);
                linkedAccountsLastUpdatedAt = now;
            }

            let newLinkedAccounts = {};
            newLinkedAccounts[accountId] = {
                records: linkedAccounts,
                lastUpdatedAt: linkedAccountsLastUpdatedAt
            };

            let updatedAllLinkedAccounts = { ...allLinkedAccounts, ...newLinkedAccounts };

            await LocalStorage.setItem('linkedAccounts', JSON.stringify(updatedAllLinkedAccounts));
            return dispatch(getOrchestratorSuccess({ linkedAccounts: updatedAllLinkedAccounts }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
        }

        return dispatch(getOrchestratorSuccess({}));
    }
};

export const getLocations = (accountId, force = false) => {
    return async (dispatch, getState) => {
        try {
            await dispatch(getAccounts(false));
            await dispatch(getCommodities(false));
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const accounts = [...orchestratorState.accounts];
            const commodities = [...orchestratorState.commodities];

            const now = new Date();
            let allLoctions = {};
            let locations = [];
            let locationsLastUpdatedAt = null;
            let allLocationsString = await LocalStorage.getItem('locations');
            if (isStringNotEmpty(allLocationsString)) {
                // already there so load into redux
                allLoctions = JSON.parse(allLocationsString);
            }

            if (isObjectNotEmpty(allLoctions) && isStringNotEmpty(accountId) && isObjectNotEmpty(allLoctions[accountId])) {
                locations = allLoctions[accountId].records;
                locationsLastUpdatedAt = new Date(allLoctions[accountId].lastUpdatedAt);
            }

            if (force === true || locations.length === 0 || locationsLastUpdatedAt === null || locationsLastUpdatedAt.setHours(locationsLastUpdatedAt.getHours() + 24) < now) {
                locations = await Data.getLocationsByAccountId(accountId, accounts, commodities);
                locationsLastUpdatedAt = now;
            }

            let newLocations = {};
            newLocations[accountId] = {
                records: locations,
                lastUpdatedAt: locationsLastUpdatedAt
            };

            let updatedAllLocations = { ...allLoctions, ...newLocations };

            await LocalStorage.setItem('locations', JSON.stringify(updatedAllLocations));
            return dispatch(getOrchestratorSuccess({ locations: updatedAllLocations }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
        }

        return dispatch(getOrchestratorSuccess({}));
    }
};

//#endregion