import Checks from '../entitlementUtils';

export const canCreateLinkedAccount = (auth, accountId) => {
    return Checks.allowStaff(auth) || Checks.isAccountOperationsAdmin(auth, accountId);
};

export const canReadLinkedAccount = (auth, accountId) => {
    return Checks.allowStaff(auth) || Checks.isAccountOperationsAdmin(auth, accountId);
};

export const canReadLinkedAccountList = (auth, accountId) => {
    return Checks.allowStaff(auth) || Checks.isAccountOperationsAdmin(auth, accountId);
};

export const canUpdateLinkedAccount = (auth, accountId) => {
    return Checks.allowStaff(auth) || Checks.isAccountOperationsAdmin(auth, accountId);
};

export const canDeleteLinkedAccount = (auth) => {
    return Checks.allowStaffAdmin(auth);
};