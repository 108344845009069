import axiosAuthenticated from "../../api/axiosAuthenticated";

const keysPath = '/keys';

//#region Keys Methods

export const getKeys = async () => {
    const keysRes = await axiosAuthenticated.get(keysPath);
    if (keysRes && keysRes.status === 200) {
        const keysData = keysRes.data;

        return { pubNubSubKey: keysData.pubnubSubKey, pubNubPubKey: keysData.pubnubPushKey, trimbleAPIKey: keysData.trimbleAPIKey };
    }

    return { pubNubSubKey: null, pubNubPubKey: null, trimbleAPIKey: null };
};

//#endregion