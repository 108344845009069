import React from 'react';
import { Card, Col, Row, Typography } from "antd";
import classes from './PrivacyPolicy.module.scss';

const { Title } = Typography;

const PrivacyPolicy = (props) => {

    return (
        <Card bordered={true} className={classes.card} size="small">
            <Row gutter={[4, 4]}>
                <Col span={24}>
                    <Title level={2} style={{ textAlign: 'center' }}>Privacy Policy</Title>
                </Col>
                <Col sm={{ span: 24 }} xs={{ span: 24 }}>
                    <p style={{ textAlign: 'center' }}><strong><strong>IRIS TECHNOLOGIES, INC.</strong></strong></p>
                    <p style={{ textAlign: 'center' }}><strong>PRIVACY POLICY</strong></p>
                    <p style={{ textAlign: 'center' }}>Effective Date: April 15, 2020</p>
                    <p> Iris Technologies, Inc. (&ldquo;Iris Technologies&rdquo;) collects and utilizes personal data
                    about its users when they access or use a website or mobile application provided by Iris
                    Technologies (the &ldquo;Iris Platform&rdquo;), and when they communicate with us.
                        </p>
                    <p>&ldquo;Personal Information&rdquo; means information that relates to an identified or an
                    identifiable person.
                        </p>
                    <p> This Privacy Policy describes what Personal Information is collected, when it is collected
                    and when it is used.
                        </p>
                    <p><strong>BY ACCESSING OR USING THE IRIS PLATFORM, INCLUDING ANY RELATED APPLICATION, CONTENT,
                    DOWNLOAD, PRODUCT OR SERVICE (THE "SERVICES"), YOU ACKNOWLEDGE THAT YOU HAVE READ AND
                    UNDERSTAND THIS PRIVACY POLICY AND THAT YOU AGREE AND CONSENT TO THE COLLECTION AND USE OF
                            YOUR PERSONAL INFORMATION IN ACCORDANCE WITH THIS POLICY.</strong>
                    </p>
                    <p><strong><u>THE COLLECTION OF PERSONAL INFORMATION</u></strong></p>
                    <p>Personal Information is collected and used when you</p>
                    <ul>
                        <li>create, modify, or log in to your account;</li>
                        <li>when you use the Iris Platform or Services as a shipper or carrier (or as an employee,
                        representative or agent of such);
                            </li>
                        <li>request support from or otherwise communicate with us.</li>
                    </ul>
                    <p> This Personal Information may include but is not limited to your name, e-mail address,
                    mailing address, telephone number, fax number, business contact information, vehicle
                    registration information, insurance information, payment information, login name and
                    password, and communication preferences.
                        </p>
                    <p>Personal Information is also collected and used:</p>
                    <ul>
                        <li>during and in connection with any shipment of cargo arranged through the Iris Platform
                        or Services.
                            </li>
                    </ul>
                    <p> This Personal Information may include but is not limited to name, e-mail address, mailing
                    address, telephone number, business contact information, docket information, carrier number,
                    truck/equipment type, other registration information, driver&rsquo;s license information,
                    social security number, driver history, passport information, insurance information, and
                            service profile information. <u>The Personal Information collected or used includes personal
                                information about commercial drivers</u>.
                        </p>
                    <p>The effectiveness and usefulness of the Iris Platform and Services require that we collect
                            and use <strong>LOCATION DATA</strong> from the <strong>MOBILE DEVICES OF COMMERCIAL DRIVERS
                                AND DISPATCHERS</strong>. We only collect that data when the user has granted us
                            permission to do so through the permission system of the mobile device. <strong>LOCATION
                                DATA</strong>&nbsp;is collected whether the Iris Technologies application is running in
                            the <strong>FOREGROUND</strong>&nbsp;(application open and on-screen) or in
                            the <strong>BACKGROUND</strong>&nbsp;(application open but not on-screen) of the mobile
                            device.
                        </p>
                    <p>We collect photos of bills of lading, freight, and other matters directly related to our
                    services where users have uploaded such photos to the Iris Platform.
                        </p>
                    <p>We collect transaction information related to the use of the Iris Platform and Services,
                    including the type of services requested or provided, date and time the service was
                    provided, distance traveled, payment amounts, and other related transaction details.
                        </p>
                    <p>We may collect data about the devices used to access the Iris Platform and Services.</p>
                    <p>Personal Information and other data may be collected by Iris Technologies or on its behalf
                    from other sources, including other users, third parties, public records and governmental
                    agencies, including those involved in the regulation of carriers. Some examples are:
                        </p>
                    <ul>
                        <li>Personal Information and other data about commercial drivers.</li>
                        <li>Personal Information and other data about carriers or shippers.</li>
                    </ul>
                    <p>If a user engage with a separate app or website that uses our application program interface
                    (&ldquo;API&rdquo;) or whose API we use, we may receive information about the user or its
                    connections from that app or website.
                        </p>
                    <p>We may receive information from publicly available sources and marketing service providers
                    for lead generation.
                        </p>
                    <p><strong><u>COLLECTING PERSONAL INFORMATION AND DATA FROM MOBILE DEVICES</u></strong></p>
                    <p>Some of the Personal Information and other data described above is collected from
                    users&rsquo; mobile devices when we have been granted permission to do so through the
                    permission system of the mobile device. The Personal Information and other data is collected
                    from mobile devices when the Iris Technologies application is running either in the
                    foreground (application open and on-screen) or in the background (application open but not
                    on-screen) of the mobile device.
                        </p>
                    <p><strong><u>HOW PERSONAL INFORMATION IS USED</u></strong></p>
                    <p>Iris Technologies collects and uses Personal Information in connection with its service as a
                    Broker arranging the reliable and convenient transportation of cargo. This includes but is
                    not limited to tracking the status and location of shipments, sending communications
                    regarding the progress of shipments (such as estimated delivery arrival and delivery times),
                    and facilitating payments.
                        </p>
                    <p>We also collect and use Personal Information:</p>
                    <ul>
                        <li>to personalize, maintain, and improve our products and services, including for marketing
                        and non-marketing communications to users,
                            </li>
                        <li>for customer support, including monitoring and improving responses and processes,</li>
                        <li>to enhance the safety and security of the Iris Platform and Services.</li>
                    </ul>
                    <p><strong><u>DATA SHARING AND DISCLOSURE</u></strong></p>
                    <p>In operating its business, Iris Technologies may share Personal Information and other data
                    with its affiliates, consultants, business or marketing partners, and third-party service
                    providers and vendors. These may include but are not limited to (a) payment processors and
                    facilitators, (b) data storage providers, and (c) lawyers, accountants and other
                    professionals.
                        </p>
                    <p>Acting as a Broker may require that we share Personal Information with other users or at a
                    user&rsquo;s request. We may use and share Personal Information for legal reasons or in
                    connection with claims or disputes.
                        </p>
                    <p>Iris Technologies may share or disclose Personal Information when believed to be required by
                    law or regulation, to respond to a subpoena or other legal/governmental request,&nbsp;or
                    based on safety or similar concerns.
                        </p>
                    <p>Iris Technologies may share or disclose Personal Information to enforce any right or remedy
                    under any contract or any right or remedy under any statute or regulation.
                        </p>
                    <p>Iris Technologies may share or disclose Personal Information in connection with, or during
                    negotiations of, any merger, sale of company assets, consolidation or restructuring,
                    financing, or acquisition of all or a portion of its business by or into another
                    company.
                        </p>
                    <p>Iris Technologies may share or disclose a user&rsquo;s Personal Information under
                    circumstances not otherwise provided herein provided it obtains the user&rsquo;s
                    consent.
                        </p>
                    <p>Iris Technologies does not sell or share Personal Information of users with third parties for
                    their direct marketing efforts, except with users&rsquo; consent.
                        </p>
                    <p><strong><u>DATA RETENTION AND DELETION</u></strong></p>
                    <p>Iris Technologies retains a user&rsquo;s Personal Information for as long as the user
                    maintains an account with Iris Technologies or has continuing obligations, contractual or
                    otherwise, to Iris Technologies.
                        </p>
                    <p>For carriers and/or commercial drivers, Iris Technologies retains information relating to
                    vehicles and licenses (to the extent permitted by law) for as long as they maintain an
                    account with Iris Technologies or have continuing obligations, contractual or otherwise, to
                    Iris Technologies.
                        </p>
                    <p>Iris Technologies retains location, device and usage data for as long as necessary to provide
                    services and comply with applicable regulatory, tax, insurance, litigation, and other legal
                    requirements. Iris Technologies may also use such information for purposes of safety,
                    security, fraud prevention and detection, and research and development.
                        </p>
                    <p>A user may request deletion of Personal Information. Upon receiving such a request, Iris
                    Technologies will delete Personal Information that it is not required to retain for purposes
                    of regulatory, tax, insurance, litigation, or other legal requirements, or any legitimate
                    business purpose.
                        </p>
                    <p><strong><u>COOKIES</u></strong></p>
                    <p>Iris Technologies uses cookies and other identification technologies with its Platform and
                            Services. Review our <a title="Cookie Notice" href="https://irisfreight.com/cookies" target="_blank">Cookie
                                Notice</a>&nbsp;for an explanation of how we use them or may use them in the future.
                        </p>
                    <p><strong><u>UPDATES TO THIS PRIVACY POLICY</u></strong></p>
                    <p>We may occasionally update this Privacy Policy. You should review our Privacy Policy
                    periodically. Use of the Iris Platform or Services after an update constitutes consent to
                    the updated policy to the extent permitted by law.
                        </p>
                </Col>
            </Row>
        </Card>
    );
};

export default PrivacyPolicy;
