import React, { useEffect, useState, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { Alert, Button, Card, Dropdown, Menu, Modal, Space, Spin, Tooltip, Typography, Col, Row, Drawer, Divider } from 'antd';
import classes from './QueueLoads.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt, faRedoAlt, faTools } from '@fortawesome/free-solid-svg-icons';
import StringFormatter from '../../shared/stringFormatter';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { compareByAlph } from '../../shared/tableUtils';
import DataTable from '../../components/DataTable/DataTable';
import NewLoadModal from '../../components/NewLoadModal/NewLoadModal';
import { isBooleanFalse, isBooleanTrue, isListEmpty, isListNotEmpty, isNotNullOrUndefined, isObjectEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import ColumnSearchFilter from '../../shared/tableColumnSearchFilter';
import { CanDo, CanSee } from '../../shared/entitlements/entitlements';
import { selectListIsLoading, selectListPagination, selectListRecords } from '../../store/utility';
import NewInvoiceLineItem from '../../components/NewInvoiceLineItem/NewInvoiceLineItem';
import EditInvoiceLineItem from '../../components/EditInvoiceLineItem/EditInvoiceLineItem';
import RemoveInvoiceLineItem from '../../components/RemoveInvoiceLineItem/RemoveInvoiceLineItem';
import NewTransaction from '../../components/NewTransaction/NewTransaction';
import EditTransaction from '../../components/EditTransaction/EditTransaction';
import RemoveTransaction from '../../components/RemoveTransaction/RemoveTransaction';
import EditInvoiceLineItemStatus from '../EditInvoiceLineItemStatus/EditInvoiceLineItemStatus';
import EditInvoiceLineItemApprovalStatus from '../EditInvoiceLineItemApprovalStatus/EditInvoiceLineItemApprovalStatus';
import EditTransactionPaymentStatus from '../EditTransactionPaymentStatus/EditTransactionPaymentStatus';
import EditInvoiceLineItemStatusCancelled from '../EditInvoiceLineItemStatusCancelled/EditInvoiceLineItemStatusCancelled';
import EditMissingPedigreeInformation from '../EditMissingPedigreeInformation/EditMissingPedigreeInformation';
import LoadNewShipperInvoice from '../LoadNewShipperInvoice/LoadNewShipperInvoice';
import LoadSendShipperInvoice from '../LoadSendShipperInvoice/LoadSendShipperInvoice';
import LoadEditShipperInvoice from '../LoadEditShipperInvoice/LoadEditShipperInvoice';
import LoadSendPriceConfirmation from '../LoadSendPriceConfirmation/LoadSendPriceConfirmation';
import LoadSendRateConfirmation from '../LoadSendRateConfirmation/LoadSendRateConfirmation';
import LoadEditPriceConfirmation from '../LoadEditPriceConfirmation/LoadEditPriceConfirmation';
import LoadEditRateConfirmation from '../LoadEditRateConfirmation/LoadEditRateConfirmation';
import LoadEditCarrierInvoice from '../LoadEditCarrierInvoice/LoadEditCarrierInvoice';
import LoadNewPriceConfirmation from '../LoadNewPriceConfirmation/LoadNewPriceConfirmation';
import LoadNewRateConfirmation from '../LoadNewRateConfirmation/LoadNewRateConfirmation';
import LoadNewCarrierInvoice from '../LoadNewCarrierInvoice/LoadNewCarrierInvoice';
import LoadClaims from '../LoadClaims/LoadClaims';
import FormItemDisplay from '../FormItemDisplay/FormItemDisplay';
import DataLinkRow from '../DataLinkRow/DataLinkRow';
import Header from '../Header/Header';
import { InfoOutlined, UploadOutlined, CheckOutlined, DeleteOutlined, DeliveredProcedureOutlined, DollarOutlined, DownOutlined, EditOutlined, FileTextOutlined, MinusOutlined, PaperClipOutlined, PlusOutlined, QuestionOutlined, StrikethroughOutlined } from '@ant-design/icons';
import NoteList from '../NoteList/NoteList';
import Invoice from '../Invoice/Invoice';
import EditInvoice from '../EditInvoice/EditInvoice';
import Document from '../Document/Document';
import ReviewDocument from '../ReviewDocument/ReviewDocument';
import EditMissingDocument from '../EditMissingDocument/EditMissingDocument';
import LoadUtils from '../../api/utils/loadUtils';
import DocumentUtils from '../../api/utils/documentUtils';
import Enums from '../../shared/enums';
import EntityUtils from "../../api/entityUtils";

const stringFormatter = new StringFormatter();
const columnSearchFilter = new ColumnSearchFilter();

const QueueLoads = ({ showActiveLoadData = false, showCompletedLoadData = false, showAccountsPayable = false, showAccountsReceivable = false, showProofOfDelivery = false, showRequiredDocumentStatus = false, showClaimsStatus = false, showNewLoadButton = true, shipperId, carrierId, driverId, assignedAccountRepUserId, brokerId, loadLaneId = null, laneId = null, style = null, headStyle = null, showTable = false, title = "Accounts Payable", filter = "COMPLETED_AP", defaultLoadStatusFilter = [], filterSearchParams = { sort: 'pickUpDateTime', order: 'asc', isDeleted: false, eta: false }, ...props }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    //#endregion
    //#region useStates

    const [selectedLoad, setSelectedLoad] = useState(null);
    const [showNewLoad, setShowNewLoad] = useState(false);
    const [showDuplicateLoad, setShowDuplicateLoad] = useState(false);
    const [searchText, setSearchText] = useState({ irisId: '' });
    const [showTableModal, setShowTableModal] = useState(false);

    const [proofOfDeliveryDocuments, setProofOfDeliveryDocuments] = useState([]);
    const [visibleDocuments, setVisibleDocuments] = useState([]);
    const [pendingApprovalDocuments, setPendingApprovalDocuments] = useState([]);
    const [missingDocuments, setMissingDocuments] = useState([]);
    const [showEditMissingDocument, setShowEditMissingDocument] = useState(false);
    const [showReviewDocument, setShowReviewDocument] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [showViewDocument, setShowViewDocument] = useState(false);

    const [showReviewDocuments, setShowReviewDocuments] = useState(false);
    const [showViewDocuments, setShowViewDocuments] = useState(false);
    const [showMissingDocuments, setShowMissingDocuments] = useState(false);
    const [carrierInvoice, setCarrierInvoice] = useState(null);
    const [shipperInvoice, setShipperInvoice] = useState(null);
    const [carrierInvoiceDocument, setCarrierInvoiceDocument] = useState(null);
    const [shipperInvoiceDocument, setShipperInvoiceDocument] = useState(null);
    const [factoringInvoiceDocument, setFactoringInvoiceDocument] = useState(null);
    const [bankAccountDocument, setBankAccountDocument] = useState(null);
    const [factoringNOADocument, setFactoringNOADocument] = useState(null);

    const [shipperAccount, setShipperAccount] = useState(null);
    const [carrierAccount, setCarrierAccount] = useState(null);

    const [showReviewPOD, setShowReviewPOD] = useState(false);
    const [showViewPOD, setShowViewPOD] = useState(false);
    const [showMissingPOD, setShowMissingPOD] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
    const [showNotesModal, setShowNotesModal] = useState(false);
    const [showNewPriceConfirmationModal, setShowNewPriceConfirmationModal] = useState(false);
    const [showNewRateConfirmationModal, setShowNewRateConfirmationModal] = useState(false);
    const [showNewShipperInvoiceModal, setShowNewShipperInvoiceModal] = useState(false);
    const [showNewCarrierInvoiceModal, setShowNewCarrierInvoiceModal] = useState(false);
    const [showResendPriceConfirmationModal, setShowResendPriceConfirmationModal] = useState(false);
    const [showResendRateConfirmationModal, setShowResendRateConfirmationModal] = useState(false);
    const [showResendShipperInvoiceModal, setShowResendShipperInvoiceModal] = useState(false);
    const [showUpdatePriceConfirmationModal, setShowUpdatePriceConfirmationModal] = useState(false);
    const [showUpdateRateConfirmationModal, setShowUpdateRateConfirmationModal] = useState(false);
    const [showUpdateShipperInvoiceModal, setShowUpdateShipperInvoiceModal] = useState(false);
    const [showUpdateCarrierInvoiceModal, setShowUpdateCarrierInvoiceModal] = useState(false);
    const [showViewInvoiceModal, setShowViewInvoiceModal] = useState(false);
    const [showEditInvoice, setShowEditInvoice] = useState(false);
    const [showInvoiceLineItemModal, setShowInvoiceLineItemModal] = useState(false);
    const [showTransactionModal, setShowTransactionModal] = useState(false);
    const [showShipperInvoiceModal, setShowShipperInvoiceModal] = useState(false);
    const [showCarrierInvoiceModal, setShowCarrierInvoiceModal] = useState(false);
    const [showFactoringInvoiceModal, setShowFactoringInvoiceModal] = useState(false);
    const [showClaimsModal, setShowClaimsModal] = useState(false);
    const [selectedInvoiceLineItem, setSelectedInvoiceLineItem] = useState(null);
    const [showEditInvoiceLineItem, setShowEditInvoiceLineItem] = useState(false);
    const [showEditStatusInvoiceLineItem, setShowEditStatusInvoiceLineItem] = useState(false);
    const [showCancelInvoiceLineItem, setShowCancelInvoiceLineItem] = useState(false);
    const [showEditApprovalStatusInvoiceLineItem, setShowEditApprovalStatusInvoiceLineItem] = useState(false);
    const [showNewInvoiceLineItem, setShowNewInvoiceLineItem] = useState(false);
    const [showRemoveInvoiceLineItem, setShowRemoveInvoiceLineItem] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [showEditTransaction, setShowEditTransaction] = useState(false);
    const [showEditPaymentStatusTransaction, setShowEditPaymentStatusTransaction] = useState(false);
    const [showNewTransaction, setShowNewTransaction] = useState(false);
    const [showRemoveTransaction, setShowRemoveTransaction] = useState(false);
    const [fromEntityType, setFromEntityType] = useState(null);
    const [toEntityType, setToEntityType] = useState(null);

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const accounts = useSelector(state => state.orchestrator.accounts);
    const isAdmin = useSelector(state => state.auth.isAdmin);
    const entityType = useSelector(state => state.auth.entityType);
    const entityId = useSelector(state => state.auth.entityId);
    const records = useSelector(state => selectListRecords(state.loads.lists, filter));
    const isLoading = useSelector(state => selectListIsLoading(state.loads.lists, filter));
    const pagination = useSelector(state => selectListPagination(state.loads.lists, filter));

    const claims = useSelector(state => selectListRecords(state.claims.lists, isObjectNotEmpty(selectedLoad) && isStringNotEmpty(selectedLoad.id) ? selectedLoad.id : null));
    const isLoadingClaims = useSelector(state => selectListIsLoading(state.claims.lists, isObjectNotEmpty(selectedLoad) && isStringNotEmpty(selectedLoad.id) ? selectedLoad.id : null));
    const transactions = useSelector(state => selectListRecords(state.transactions.lists, isObjectNotEmpty(selectedLoad) && isStringNotEmpty(selectedLoad.id) ? selectedLoad.id : null));
    const isLoadingTransactions = useSelector(state => selectListIsLoading(state.transactions.lists, isObjectNotEmpty(selectedLoad) && isStringNotEmpty(selectedLoad.id) ? selectedLoad.id : null));
    const invoiceLineItems = useSelector(state => selectListRecords(state.invoiceLineItems.lists, isObjectNotEmpty(selectedLoad) && isStringNotEmpty(selectedLoad.id) ? selectedLoad.id : null));
    const isLoadingInvoiceLineItems = useSelector(state => selectListIsLoading(state.invoiceLineItems.lists, isObjectNotEmpty(selectedLoad) && isStringNotEmpty(selectedLoad.id) ? selectedLoad.id : null));
    const stops = useSelector(state => selectListRecords(state.loadStops.lists, isObjectNotEmpty(selectedLoad) && isStringNotEmpty(selectedLoad.id) ? selectedLoad.id : null));
    const isLoadingStops = useSelector(state => selectListIsLoading(state.loadStops.lists, isObjectNotEmpty(selectedLoad) && isStringNotEmpty(selectedLoad.id) ? selectedLoad.id : null));
    const invoices = useSelector(state => selectListRecords(state.invoices.lists, isObjectNotEmpty(selectedLoad) && isStringNotEmpty(selectedLoad.id) ? selectedLoad.id : null));
    const isLoadingInvoices = useSelector(state => selectListIsLoading(state.invoices.lists, isObjectNotEmpty(selectedLoad) && isStringNotEmpty(selectedLoad.id) ? selectedLoad.id : null));
    const documentLists = useSelector(state => state.documents.lists);
    const isLoadingDocuments = useSelector(state => selectListIsLoading(state.documents.lists, isObjectNotEmpty(selectedLoad) && isStringNotEmpty(selectedLoad.id) ? selectedLoad.id : null));
    const drivers = useSelector(state => selectListRecords(state.orchestrator.drivers, entityType === 'CARRIER' ? entityId : (isStringNotEmpty(carrierId) ? carrierId : null)));
    const assets = useSelector(state => selectListRecords(state.orchestrator.assets, entityType === 'CARRIER' ? entityId : (isStringNotEmpty(carrierId) ? carrierId : null)));
    const loadEventUpdateStatus = useSelector(state => state.loadEvents.loadEventUpdateStatus);

    //#endregion
    //#region goTos

    const goToLoad = (id) => {
        props.history.push({ pathname: `/loads/${id}` }, {
            previousPageTitle: 'Completed Loads',
            previousPageLocation: props.location,
            previousBreadcrumbs: props.breadcrumbs,
        });
    };

    //#endregion
    //#region toggles

    const toggleNewLoad = () => {
        setShowNewLoad(!showNewLoad);
    };

    const toggleDuplicateLoad = () => {
        setShowDuplicateLoad(!showDuplicateLoad);
    };

    const toggleTableModal = () => {
        setShowTableModal(!showTableModal);
    };

    const toggleEditMissingDocument = () => {
        setShowEditMissingDocument(!showEditMissingDocument);
    };

    const toggleReviewDocument = () => {
        setShowReviewDocument(!showReviewDocument);
    };

    const toggleViewDocument = () => {
        setShowViewDocument(!showViewDocument);
    };

    const toggleMissingDocuments = () => {
        setShowMissingDocuments(!showMissingDocuments);
    };

    const toggleReviewDocuments = () => {
        setShowReviewDocuments(!showReviewDocuments);
    };

    const toggleViewDocuments = () => {
        setShowViewDocuments(!showViewDocuments);
    };

    const toggleMissingPOD = () => {
        setShowMissingPOD(!showMissingPOD);
    };

    const toggleReviewPOD = () => {
        setShowReviewPOD(!showReviewPOD);
    };

    const toggleViewPOD = () => {
        setShowViewPOD(!showViewPOD);
    };

    const toggleNotesModal = () => {
        setShowNotesModal(!showNotesModal);
    };

    const toggleNewPriceConfirmationModal = () => {
        setShowNewPriceConfirmationModal(!showNewPriceConfirmationModal);
    };

    const toggleNewRateConfirmationModal = () => {
        setShowNewRateConfirmationModal(!showNewRateConfirmationModal);
    };

    const toggleNewShipperInvoiceModal = () => {
        setShowNewShipperInvoiceModal(!showNewShipperInvoiceModal);
    };

    const toggleNewCarrierInvoiceModal = () => {
        setShowNewCarrierInvoiceModal(!showNewCarrierInvoiceModal);
    };

    const toggleResendPriceConfirmationModal = () => {
        setShowResendPriceConfirmationModal(!showResendPriceConfirmationModal);
    };

    const toggleResendRateConfirmationModal = () => {
        setShowResendRateConfirmationModal(!showResendRateConfirmationModal);
    };

    const toggleResendShipperInvoiceModal = () => {
        setShowResendShipperInvoiceModal(!showResendShipperInvoiceModal);
    };

    const toggleUpdatePriceConfirmationModal = () => {
        setShowUpdatePriceConfirmationModal(!showUpdatePriceConfirmationModal);
    };

    const toggleUpdateRateConfirmationModal = () => {
        setShowUpdateRateConfirmationModal(!showUpdateRateConfirmationModal);
    };

    const toggleUpdateShipperInvoiceModal = () => {
        setShowUpdateShipperInvoiceModal(!showUpdateShipperInvoiceModal);
    };

    const toggleUpdateCarrierInvoiceModal = () => {
        setShowUpdateCarrierInvoiceModal(!showUpdateCarrierInvoiceModal);
    };

    const toggleViewInvoice = () => {
        setShowViewInvoiceModal(!showViewInvoiceModal);
    };

    const toggleEditInvoice = () => {
        setShowEditInvoice(!showEditInvoice);
    };

    const toggleInvoiceLineItemModal = () => {
        setShowInvoiceLineItemModal(!showInvoiceLineItemModal);
    };

    const toggleTransactionModal = () => {
        setShowTransactionModal(!showTransactionModal);
    };

    const toggleEditInvoiceLineItem = () => {
        setShowEditInvoiceLineItem(!showEditInvoiceLineItem);
    };

    const toggleEditStatusInvoiceLineItem = () => {
        setShowEditStatusInvoiceLineItem(!showEditStatusInvoiceLineItem);
    };

    const toggleCancelInvoiceLineItem = () => {
        setShowCancelInvoiceLineItem(!showCancelInvoiceLineItem);
    }

    const toggleEditApprovalStatusInvoiceLineItem = () => {
        setShowEditApprovalStatusInvoiceLineItem(!showEditApprovalStatusInvoiceLineItem);
    };

    const toggleNewInvoiceLineItem = () => {
        setShowNewInvoiceLineItem(!showNewInvoiceLineItem);
    };

    const toggleRemoveInvoiceLineItem = () => {
        setShowRemoveInvoiceLineItem(!showRemoveInvoiceLineItem);
    };

    const toggleEditTransaction = () => {
        setShowEditTransaction(!showEditTransaction);
    };

    const toggleEditPaymentStatusTransaction = () => {
        setShowEditPaymentStatusTransaction(!showEditPaymentStatusTransaction);
    };

    const toggleNewTransaction = () => {
        setShowNewTransaction(!showNewTransaction);
    };

    const toggleRemoveTransaction = () => {
        setShowRemoveTransaction(!showRemoveTransaction);
    };

    const toggleShipperInvoiceModal = () => {
        setShowShipperInvoiceModal(!showShipperInvoiceModal);
    };

    const toggleCarrierInvoiceModal = () => {
        setShowCarrierInvoiceModal(!showCarrierInvoiceModal);
    };

    const toggleFactoringInvoiceModal = () => {
        setShowFactoringInvoiceModal(!showFactoringInvoiceModal);
    };

    const toggleClaimsModal = () => {
        setShowClaimsModal(!showClaimsModal);
    };

    //#endregion
    //#region table methods

    const refreshTable = () => {
        if (isStringNotEmpty(entityType) && isStringNotEmpty(entityId)) {
            let searchParams = {
                page: 1,
                size: 5,
                ...filterSearchParams
            };

            if (entityType === "DRIVER") {
                searchParams.driverIds = entityId;
            } else if (entityType === "SHIPPER") {
                searchParams.shipperId = entityId;
            } else if (entityType === "BROKER") {
                searchParams.brokerId = entityId;
            } else if (entityType === "CARRIER") {
                searchParams.assignedCarrierId = entityId;

                if (isStringNotEmpty(driverId)) {
                    searchParams.driverIds = driverId;
                }
            } else if (entityType === "STAFF") {
                if (isStringNotEmpty(shipperId)) {
                    searchParams.shipperId = shipperId;
                }

                if (isStringNotEmpty(brokerId)) {
                    searchParams.brokerId = brokerId;
                }

                if (isStringNotEmpty(carrierId)) {
                    searchParams.assignedCarrierId = carrierId;
                }

                if (isStringNotEmpty(driverId)) {
                    searchParams.driverIds = driverId;
                }

                if (isStringNotEmpty(assignedAccountRepUserId)) {
                    searchParams.assignedAccountRepUserIds = assignedAccountRepUserId;
                }
            }

            if (isStringNotEmpty(loadLaneId)) {
                searchParams.loadLaneId = loadLaneId;
            }

            if (isStringNotEmpty(laneId)) {
                searchParams.laneId = laneId;
            }

            searchParams.loadStatus = defaultLoadStatusFilter.join('|');

            dispatch(actionCreators.fetchLoadList(filter, searchParams));
        }
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchLoadListSuccess(filter, { params: { pagination: pager } }));

        const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';

        let searchParams = {
            ...filterSearchParams,
            page: pagination.current,
            size: pagination.pageSize,
            sort: sorter.field,
            order: sortOrder
        };

        if (isListNotEmpty(filters.loadStatus)) {
            searchParams.loadStatus = filters.loadStatus.join('|');
        } else {
            searchParams.loadStatus = defaultLoadStatusFilter.join('|');
        }

        if (isListNotEmpty(filters.irisId)) {
            searchParams['irisId:contains'] = filters.irisId[0];
        }

        if (entityType === "DRIVER") {
            searchParams.driverIds = entityId;
        } else if (entityType === "SHIPPER") {
            searchParams.shipperId = entityId;
        } else if (entityType === "BROKER") {
            searchParams.brokerId = entityId;
        } else if (entityType === "CARRIER") {
            searchParams.assignedCarrierId = entityId;

            if (isStringNotEmpty(driverId)) {
                searchParams.driverIds = driverId;
            }
        } else if (entityType === "STAFF") {
            if (isStringNotEmpty(shipperId)) {
                searchParams.shipperId = shipperId;
            }

            if (isStringNotEmpty(brokerId)) {
                searchParams.brokerId = brokerId;
            }

            if (isStringNotEmpty(carrierId)) {
                searchParams.assignedCarrierId = carrierId;
            }

            if (isStringNotEmpty(driverId)) {
                searchParams.driverIds = driverId;
            }

            if (isStringNotEmpty(assignedAccountRepUserId)) {
                searchParams.assignedAccountRepUserIds = assignedAccountRepUserId;
            }
        }

        if (isStringNotEmpty(loadLaneId)) {
            searchParams.loadLaneId = loadLaneId;
        }

        if (isStringNotEmpty(laneId)) {
            searchParams.laneId = laneId;
        }

        dispatch(actionCreators.fetchLoadList(filter, searchParams));
    };

    //#endregion
    //#region load completion methods

    const markDocumentsReviewed = () => {
        if (isObjectNotEmpty(selectedLoad)) {
            if (isObjectNotEmpty(selectedLoad.completionCheckList) && isBooleanTrue(selectedLoad.completionCheckList.hasDocumentsReviewed)) {
                toggleViewDocuments();
            } else {
                dispatch(actionCreators.updateLoad(selectedLoad.id, { completionCheckList: { hasDocumentsReviewed: true } }));
                toggleViewDocuments();
            }
        }
    };

    const markPODDone = () => {
        if (isObjectNotEmpty(selectedLoad) && isListNotEmpty(proofOfDeliveryDocuments)) {
            if (proofOfDeliveryDocuments.filter(d => d.status === 'OSDR').length > 0) {
                dispatch(actionCreators.updateLoad(selectedLoad.id, { completionCheckList: { proofOfDeliveryStatus: 'OSDR' } }));
                toggleViewPOD();
            } else {
                dispatch(actionCreators.updateLoad(selectedLoad.id, { completionCheckList: { proofOfDeliveryStatus: 'CLEAN' } }));
                toggleViewPOD();
            }
        }
    };

    const markPODClean = () => {
        if (isObjectNotEmpty(selectedLoad)) {
            if (isObjectNotEmpty(selectedLoad.completionCheckList) && isStringNotEmpty(selectedLoad.completionCheckList.proofOfDeliveryStatus) && (selectedLoad.completionCheckList.proofOfDeliveryStatus === 'CLEAN')) {
                toggleViewPOD();
            } else {
                dispatch(actionCreators.updateLoad(selectedLoad.id, { completionCheckList: { proofOfDeliveryStatus: 'CLEAN' } }));
                toggleViewPOD();
            }
        }
    };

    const markPODOSDR = () => {
        if (isObjectNotEmpty(selectedLoad)) {
            if (isObjectNotEmpty(selectedLoad.completionCheckList) && isStringNotEmpty(selectedLoad.completionCheckList.proofOfDeliveryStatus) && (selectedLoad.completionCheckList.proofOfDeliveryStatus === 'OSDR')) {
                toggleViewPOD();
            } else {
                dispatch(actionCreators.updateLoad(selectedLoad.id, { completionCheckList: { proofOfDeliveryStatus: 'OSDR' } }));
                toggleViewPOD();
            }
        }
    };

    const closeLoad = () => {
        if (isObjectNotEmpty(selectedLoad)) {
            dispatch(actionCreators.sendLoadEvent({
                loadId: selectedLoad.id,
                eventType: 'LOAD_CLOSED'
            }));
        }
    };

    //#endregion
    //#region table displays

    const getInvoiceLineItemRowClassName = (record) => {
        if (record.status === 'COMPLETED') {
            return classes.completedRow;
        } else if (record.status === 'CANCELLED') {
            return classes.cancelledRow;
        } else if (record.approvalStatus === 'APPROVED') {
            return classes.approvedRow;
        } else {
            return classes.pendingRow;
        }
    };

    const getTransactionRowClassName = (record) => {
        if (record.paymentStatus === 'COMPLETED') {
            return classes.completedRow;
        } else if (record.paymentStatus === 'CANCELLED') {
            return classes.cancelledRow;
        } else if (record.paymentStatus === 'PENDING') {
            return classes.approvedRow;
        } else {
            return classes.pendingRow;
        }
    };

    const getPODStatusRowClassName = (value) => {
        if (value === 'CLEAN' || value === 'OSDR') {
            return classes.completedRow;
        } else if (value === 'MISSING') {
            return classes.missingRow;
        } else if (value === 'UPLOADED') {
            return classes.approvedRow;
        } else {
            return classes.missingRow;
        }
    };

    const getRequiredDocumentStatusRowClassName = (value) => {
        if (value === true) {
            return classes.completedRow;
        } else {
            return classes.missingRow;
        }
    };

    const getClaimsStatusRowClassName = (value) => {
        if (value === true) {
            return classes.missingRow;
        } else {
            return classes.completedRow;
        }
    };

    const getInvoiceStatusRowClassName = (value) => {
        if (value === 'COMPLETED') {
            return classes.completedRow;
        } else if (value === 'CANCELLED') {
            return classes.cancelledRow;
        } else if (value === 'SENT') {
            return classes.sentRow;
        } else if (value === 'PENDING') {
            return classes.pendingRow;
        } else {
            return classes.missingRow;
        }
    };

    const invoiceLineItemMenu = (record) => {
        return (
            <Menu>
                {isObjectNotEmpty(selectedLoad) && (record.loadId === selectedLoad.id) && (record.approvalStatus !== "APPROVED" && record.status !== 'COMPLETED' && record.status !== 'CANCELLED' && (record.toEntityType === entityType || record.fromEntityType === entityType || entityType === 'STAFF')) ? (
                    <Menu.Item key="approveInvoiceLineItem" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoiceLineItem(record); toggleEditApprovalStatusInvoiceLineItem(); }}>
                        <CheckOutlined style={{ marginRight: 8 }} />
                        <span>Approve Invoice Line Item</span>
                    </Menu.Item>
                ) : null}
                {isObjectNotEmpty(selectedLoad) && (record.loadId === selectedLoad.id) && (record.approvalStatus !== "APPROVED" && record.status !== 'COMPLETED' && record.status !== 'CANCELLED' && (selectedLoad.serviceType === 'TMS' || entityType === 'STAFF')) ? (
                    <Menu.Item key="updateStatusInvoiceLineItem" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoiceLineItem(record); toggleEditStatusInvoiceLineItem(); }}>
                        <QuestionOutlined style={{ marginRight: 8 }} />
                        <span>Update Status of Invoice Line Item</span>
                    </Menu.Item>
                ) : null}
                {isObjectNotEmpty(selectedLoad) && (record.loadId === selectedLoad.id) && ((record.approvalStatus !== "APPROVED" && record.status !== 'COMPLETED' && record.status !== 'CANCELLED' && isStringNotEmpty(record.itemType) && !Enums.StopFees.doesNameExist(record.itemType) && !Enums.EquipmentSpecifics.doesNameExist(record.itemType) && !Enums.LoadRequirements.doesNameExist(record.itemType) && (selectedLoad.serviceType === 'TMS' || entityType === 'STAFF')) || isAdmin === true) ? (
                    <Menu.Item key="editInvoiceLineItem" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoiceLineItem(record); toggleEditInvoiceLineItem(); }}>
                        <EditOutlined style={{ marginRight: 8 }} />
                        <span>Edit Invoice Line Item Details</span>
                    </Menu.Item>
                ) : null}
                {isObjectNotEmpty(selectedLoad) && (record.loadId === selectedLoad.id) && (record.status !== 'COMPLETED' && record.status !== 'CANCELLED' && (selectedLoad.serviceType === 'TMS' || entityType === 'STAFF')) ? (
                    <Menu.Item key="cancelInvoiceLineItem" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoiceLineItem(record); toggleCancelInvoiceLineItem(); }}>
                        <StrikethroughOutlined style={{ marginRight: 8 }} />
                        <span>Cancel Invoice Line Item</span>
                    </Menu.Item>
                ) : null}
                {isObjectNotEmpty(selectedLoad) && (record.loadId === selectedLoad.id) && ((record.approvalStatus !== "APPROVED" && record.status !== 'COMPLETED' && record.status !== 'CANCELLED' && isStringNotEmpty(record.itemType) && !Enums.StopFees.doesNameExist(record.itemType) && !Enums.EquipmentSpecifics.doesNameExist(record.itemType) && !Enums.LoadRequirements.doesNameExist(record.itemType) && (selectedLoad.serviceType === 'TMS' || entityType === 'STAFF')) || isAdmin === true) ? (
                    <Menu.Item key="removeInvoiceLineItem" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoiceLineItem(record); toggleRemoveInvoiceLineItem(); }}>
                        <DeleteOutlined style={{ marginRight: 8 }} />
                        <span>Delete Invoice Line Item</span>
                    </Menu.Item>
                ) : null}
            </Menu>
        );
    };

    const transactionMenu = (record) => {
        return (
            <Menu>
                {isObjectNotEmpty(selectedLoad) && (record.loadId === selectedLoad.id) && record.paymentStatus !== 'COMPLETED' && (selectedLoad.serviceType === 'TMS' || entityType === 'STAFF') ? (
                    <Menu.Item key="updatePaymentStatusTransaction" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedTransaction(record); toggleEditPaymentStatusTransaction(); }}>
                        <QuestionOutlined style={{ marginRight: 8 }} />
                        <span>Update Payment Status of Transaction</span>
                    </Menu.Item>
                ) : null}
                {isObjectNotEmpty(selectedLoad) && (record.loadId === selectedLoad.id) && (isAdmin === true) && (selectedLoad.serviceType === 'TMS' || entityType === 'STAFF') ? (
                    <Menu.Item key="editTransaction" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedTransaction(record); toggleEditTransaction(); }}>
                        <EditOutlined style={{ marginRight: 8 }} />
                        <span>Edit Transaction Details</span>
                    </Menu.Item>
                ) : null}
                {isObjectNotEmpty(selectedLoad) && (record.loadId === selectedLoad.id) && (record.paymentStatus !== 'COMPLETED' || isAdmin === true) && (selectedLoad.serviceType === 'TMS' || entityType === 'STAFF') ? (
                    <Menu.Item key="removeTransaction" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedTransaction(record); toggleRemoveTransaction(); }}>
                        <DeleteOutlined style={{ marginRight: 8 }} />
                        <span>Delete Transaction</span>
                    </Menu.Item>
                ) : null}
            </Menu>
        );
    };

    const loadMenu = (record) => {
        return (
            <Menu>
                {isObjectNotEmpty(record.completionCheckList) && isStringNotEmpty(record.completionCheckList.shipperInvoiceId) && isStringNotEmpty(record.completionCheckList.shipperInvoiceStatus) && record.completionCheckList.shipperInvoiceStatus !== 'CANCELLED' && showAccountsPayable === true && (entityType === 'STAFF' || entityType === 'SHIPPER') ? (
                    <Menu.Item key="viewShipperInvoice" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoiceId(record.completionCheckList.shipperInvoiceId); toggleViewInvoice(); }}>
                        <DollarOutlined style={{ marginRight: 8 }} />
                        <span>View {entityType === 'SHIPPER' ? 'Invoice' : 'Customer Invoice'}</span>
                    </Menu.Item>
                ) : null}
                {isObjectNotEmpty(record.completionCheckList) && isStringNotEmpty(record.completionCheckList.shipperInvoiceId) && isStringNotEmpty(record.completionCheckList.shipperInvoiceStatus) && record.completionCheckList.shipperInvoiceStatus !== 'COMPLETED' && record.completionCheckList.shipperInvoiceStatus !== 'CANCELLED' && showAccountsPayable === true && entityType === 'STAFF' ? (
                    <Menu.Item key="resendShipperInvoice" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoiceId(record.completionCheckList.shipperInvoiceId); toggleResendShipperInvoiceModal(); }}>
                        <DollarOutlined style={{ marginRight: 8 }} />
                        <span>Re-Send {entityType === 'SHIPPER' ? 'Invoice' : 'Customer Invoice'}</span>
                    </Menu.Item>
                ) : null}
                {isObjectNotEmpty(record.completionCheckList) && isStringNotEmpty(record.completionCheckList.shipperInvoiceId) && isStringNotEmpty(record.completionCheckList.shipperInvoiceStatus) && record.completionCheckList.shipperInvoiceStatus !== 'COMPLETED' && record.completionCheckList.shipperInvoiceStatus !== 'CANCELLED' && showAccountsPayable === true && entityType === 'STAFF' ? (
                    <Menu.Item key="updateShipperInvoice" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoiceId(record.completionCheckList.shipperInvoiceId); toggleUpdateShipperInvoiceModal(); }}>
                        <DollarOutlined style={{ marginRight: 8 }} />
                        <span>Update and Re-Send {entityType === 'SHIPPER' ? 'Invoice' : 'Customer Invoice'}</span>
                    </Menu.Item>
                ) : null}
                {isObjectNotEmpty(record.completionCheckList) && isStringNotEmpty(record.completionCheckList.shipperInvoiceId) && isStringNotEmpty(record.completionCheckList.shipperInvoiceStatus) && record.completionCheckList.shipperInvoiceStatus !== 'COMPLETED' && record.completionCheckList.shipperInvoiceStatus !== 'CANCELLED' && isAdmin === true && showAccountsPayable === true ? (
                    <Menu.Item key="editShipperInvoice" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoiceId(record.completionCheckList.shipperInvoiceId); toggleEditInvoice(); }}>
                        <DollarOutlined style={{ marginRight: 8 }} />
                        <span>Edit {entityType === 'SHIPPER' ? 'Invoice' : 'Customer Invoice'}</span>
                    </Menu.Item>
                ) : null}
                {isObjectNotEmpty(record.completionCheckList) && isStringNotEmpty(record.completionCheckList.shipperInvoiceId) && isStringNotEmpty(record.completionCheckList.shipperInvoiceStatus) && record.completionCheckList.shipperInvoiceStatus !== 'CANCELLED' && showAccountsPayable === true && entityType === 'STAFF' ? (
                    <Menu.Item key="manageShipperInvoiceNotes" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoiceId(record.completionCheckList.shipperInvoiceId); toggleNotesModal(); }}>
                        <FileTextOutlined style={{ marginRight: 8 }} />
                        <span>Manage {entityType === 'SHIPPER' ? 'Invoice' : 'Customer Invoice'} Notes</span>
                    </Menu.Item>
                ) : null}
                {isObjectNotEmpty(record.completionCheckList) && isStringNotEmpty(record.completionCheckList.carrierInvoiceId) && isStringNotEmpty(record.completionCheckList.carrierInvoiceStatus) && record.completionCheckList.carrierInvoiceStatus !== 'CANCELLED' && showAccountsReceivable === true && (entityType === 'STAFF' || entityType === 'CARRIER') ? (
                    <Menu.Item key="viewCarrierInvoice" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoiceId(record.completionCheckList.carrierInvoiceId); toggleViewInvoice(); }}>
                        <DollarOutlined style={{ marginRight: 8 }} />
                        <span>View {entityType === 'CARRIER' ? 'Invoice' : 'Carrier Invoice'}</span>
                    </Menu.Item>
                ) : null}
                {isObjectNotEmpty(record.completionCheckList) && isStringNotEmpty(record.completionCheckList.carrierInvoiceId) && isStringNotEmpty(record.completionCheckList.carrierInvoiceStatus) && record.completionCheckList.carrierInvoiceStatus !== 'COMPLETED' && record.completionCheckList.carrierInvoiceStatus !== 'CANCELLED' && showAccountsReceivable === true && entityType === 'STAFF' ? (
                    <Menu.Item key="updateCarrierInvoice" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoiceId(record.completionCheckList.carrierInvoiceId); toggleUpdateCarrierInvoiceModal(); }}>
                        <DollarOutlined style={{ marginRight: 8 }} />
                        <span>Update and Re-Submit {entityType === 'CARRIER' ? 'Invoice' : 'Carrier Invoice'}</span>
                    </Menu.Item>
                ) : null}
                {isObjectNotEmpty(record.completionCheckList) && isStringNotEmpty(record.completionCheckList.carrierInvoiceId) && isStringNotEmpty(record.completionCheckList.carrierInvoiceStatus) && record.completionCheckList.carrierInvoiceStatus !== 'COMPLETED' && record.completionCheckList.carrierInvoiceStatus !== 'CANCELLED' && isAdmin === true && showAccountsReceivable === true ? (
                    <Menu.Item key="editCarrierInvoice" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoiceId(record.completionCheckList.carrierInvoiceId); toggleEditInvoice(); }}>
                        <DollarOutlined style={{ marginRight: 8 }} />
                        <span>Edit {entityType === 'CARRIER' ? 'Invoice' : 'Carrier Invoice'}</span>
                    </Menu.Item>
                ) : null}
                {isObjectNotEmpty(record.completionCheckList) && isStringNotEmpty(record.completionCheckList.carrierInvoiceId) && isStringNotEmpty(record.completionCheckList.carrierInvoiceStatus) && record.completionCheckList.carrierInvoiceStatus !== 'CANCELLED' && showAccountsReceivable === true && entityType === 'STAFF' ? (
                    <Menu.Item key="manageCarrierInvoiceNotes" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoiceId(record.completionCheckList.carrierInvoiceId); toggleNotesModal(); }}>
                        <FileTextOutlined style={{ marginRight: 8 }} />
                        <span>Manage {entityType === 'CARRIER' ? 'Invoice' : 'Carrier Invoice'} Notes</span>
                    </Menu.Item>
                ) : null}
                {CanDo({ entityAction: 'DUPLICATE', entityModel: 'LOAD', entityObject: record }) &&
                    <Menu.Item key="duplicateLoad" onClick={(e) => { e.domEvent.stopPropagation(); e.domEvent.preventDefault(); setSelectedLoad(record); toggleDuplicateLoad(); }}>
                        <UploadOutlined style={{ marginRight: 8 }} />
                        <span>Duplicate Load</span>
                    </Menu.Item>
                }
                {CanDo({ entityAction: 'READ', entityModel: 'LOAD', entityObject: record }) &&
                    <Menu.Item key="goToDashboard" onClick={(e) => { e.domEvent.stopPropagation(); e.domEvent.preventDefault(); setSelectedLoad(record); goToLoad(record.id); }}>
                        <InfoOutlined style={{ marginRight: 8 }} />
                        <span>View Load Details</span>
                    </Menu.Item>
                }
            </Menu>
        );
    };

    const invoiceLineItemTitle = (forAccount, from, to) => (
        <Row gutter={[4, 4]} style={{ display: 'flex', alignItems: 'center' }}>
            <Col xs={{ span: 12 }}>
                <b>{'Manage Invoice Line Items for ' + forAccount}</b>
            </Col>
            <Col xs={{ span: 12 }} style={{ textAlign: 'right' }}>
                {isObjectNotEmpty(selectedLoad) && LoadUtils.loadStatusIsNot(selectedLoad.loadStatus, ['CLOSED']) ? (
                    <CanSee entityAction="CREATE" entityModel="LOAD_INVOICE_LINE_ITEM" entityObject={selectedLoad}>
                        <Tooltip placement="top" key="1" title={'New Invoice Line Item'}>
                            <Button type="primary" icon={<PlusOutlined />} style={{ marginRight: 8 }} onClick={() => { setFromEntityType(to); setToEntityType(from); toggleNewInvoiceLineItem(); }}>Addition</Button>
                        </Tooltip>
                        <Tooltip placement="top" key="2" title={'New Invoice Line Item'}>
                            <Button type="primary" icon={<MinusOutlined />} onClick={() => { setFromEntityType(from); setToEntityType(to); toggleNewInvoiceLineItem(); }}>Deduction</Button>
                        </Tooltip>
                    </CanSee>
                ) : null}
            </Col>
        </Row>
    );

    const transactionTitle = (invoice, invoiceName, invoiceType) => (
        <Row gutter={[4, 4]} style={{ display: 'flex', alignItems: 'center' }}>
            <Col xs={{ span: 12 }}>
                <b>{'Manage Transactions for ' + invoiceName}</b>
            </Col>
            <Col xs={{ span: 12 }} style={{ textAlign: 'right' }}>
                {isObjectNotEmpty(selectedLoad) && isStringNotEmpty(selectedLoad.assignedCarrierId) && isStringNotEmpty(selectedLoad.shipperId) && LoadUtils.loadStatusIsNot(selectedLoad.loadStatus, ['CLOSED']) && (isObjectNotEmpty(invoice) || invoiceType === 'FACTORING_INVOICE') ? (
                    <CanSee entityAction="CREATE" entityModel="TRANSACTION" entityObject={selectedLoad}>
                        <Tooltip placement="top" title={'New Transaction for ' + invoiceName}>
                            <Button key="1" type="primary" icon={<PlusOutlined />} shape="circle" onClick={() => { setSelectedInvoice(invoice); toggleNewTransaction(); }} />
                        </Tooltip>
                    </CanSee>
                ) : null}
            </Col>
        </Row>
    );

    const getPODStatusDisplay = (value, record) => {
        if (value === 'MISSING') {
            return (<Button type="link" onClick={() => { setSelectedLoad(record); toggleViewPOD(); }}>POD Needed</Button>);
        } else if (value === 'UPLOADED') {
            return <Button type="link" onClick={() => { setSelectedLoad(record); toggleViewPOD(); }}>Pending Review</Button>;
        } else if (value === 'CLEAN') {
            return (
                <>
                    <Button type="link" onClick={() => { setSelectedLoad(record); toggleViewPOD(); }}>Approved</Button>
                    <br />
                    <span>(Clean)</span>
                </>
            );
        } else if (value === 'OSDR') {
            return (
                <>
                    <Button type="link" onClick={() => { setSelectedLoad(record); toggleViewPOD(); }}>Approved</Button>
                    <br />
                    <span>(OSDR)</span>
                </>
            );
        } else {
            return '';
        }
    };

    const getRequiredDocumentStatusDisplay = (value, record) => {
        if (value === true) {
            return (<Button type="link" onClick={() => { setSelectedLoad(record); toggleViewDocuments(); }}>Completed</Button>);
        } else {
            return <Button type="link" onClick={() => { setSelectedLoad(record); toggleViewDocuments(); }}>Pending Review</Button>;
        }
    };

    const getClaimsStatusDisplay = (value, record) => {
        if (value === true) {
            return (<Button type="link" onClick={() => { setSelectedLoad(record); toggleClaimsModal(); }}>Has Open Claims</Button>);
        } else {
            return <Button type="link" onClick={() => { setSelectedLoad(record); toggleClaimsModal(); }}>No Open Claims</Button>;
        }
    };

    const getShipperInvoiceStatusDisplay = (value, record) => {
        if (isObjectNotEmpty(record.completionCheckList) && isStringNotEmpty(record.completionCheckList.shipperInvoiceId)) {
            if (value === 'PENDING') {
                return (
                    <Button type="link" onClick={() => { setSelectedLoad(record); setSelectedInvoiceId(record.completionCheckList.shipperInvoiceId); toggleResendShipperInvoiceModal(); }}>
                        {stringFormatter.toFormattedString('InvoiceStatus', value)}
                    </Button>
                );
            } else if (value === 'SENT') {
                return (
                    <>
                        <Button type="link" onClick={() => { setSelectedLoad(record); setSelectedInvoiceId(record.completionCheckList.shipperInvoiceId); toggleViewInvoice(); }}>
                            {stringFormatter.toFormattedString('InvoiceStatus', value)}
                        </Button>
                        <br />
                        <span>
                            {isObjectNotEmpty(record.completionCheckList) && isNotNullOrUndefined(record.completionCheckList.shipperInvoiceSentDate) ? stringFormatter.toFormattedString('MomentDate', record.completionCheckList.shipperInvoiceSentDate) : ''}
                        </span>
                    </>
                );
            } else if (value === 'COMPLETED') {
                return (
                    <>
                        <Button type="link" onClick={() => { setSelectedLoad(record); setSelectedInvoiceId(record.completionCheckList.shipperInvoiceId); toggleViewInvoice(); }}>
                            {stringFormatter.toFormattedString('InvoiceStatus', value)}
                        </Button>
                        <br />
                        <span>
                            {isObjectNotEmpty(record.completionCheckList) && isNotNullOrUndefined(record.completionCheckList.shipperInvoicePaymentDate) ? stringFormatter.toFormattedString('MomentDate', record.completionCheckList.shipperInvoicePaymentDate) : ''}
                        </span>
                    </>
                );
            } else {
                return (
                    <Button type="link" onClick={() => { setSelectedLoad(record); toggleNewShipperInvoiceModal(); }}>
                        Missing
                    </Button>
                );
            }
        } else {
            return '';
        }
    };

    const getCarrierInvoiceStatusDisplay = (value, record) => {
        if (isObjectNotEmpty(record.completionCheckList) && isStringNotEmpty(record.completionCheckList.carrierInvoiceId)) {
            if (value === 'PENDING') {
                return (
                    <Button type="link" onClick={() => { setSelectedLoad(record); setSelectedInvoiceId(record.completionCheckList.carrierInvoiceId); toggleUpdateCarrierInvoiceModal(); }}>
                        {stringFormatter.toFormattedString('InvoiceStatus', value)}
                    </Button>
                );
            } else if (value === 'SENT') {
                return (
                    <>
                        <Button type="link" onClick={() => { setSelectedLoad(record); setSelectedInvoiceId(record.completionCheckList.carrierInvoiceId); toggleViewInvoice(); }}>
                            Received
                    </Button>
                        <br />
                        <span>
                            {isObjectNotEmpty(record.completionCheckList) && isNotNullOrUndefined(record.completionCheckList.carrierInvoiceSentDate) ? stringFormatter.toFormattedString('MomentDate', record.completionCheckList.carrierInvoiceSentDate) : ''}
                        </span>
                    </>
                );
            } else if (value === 'COMPLETED') {
                return (
                    <>
                        <Button type="link" onClick={() => { setSelectedLoad(record); setSelectedInvoiceId(record.completionCheckList.carrierInvoiceId); toggleViewInvoice(); }}>
                            {stringFormatter.toFormattedString('InvoiceStatus', value)}
                        </Button>
                        <br />
                        <span>
                            {isObjectNotEmpty(record.completionCheckList) && isNotNullOrUndefined(record.completionCheckList.carrierInvoicePaymentDate) ? stringFormatter.toFormattedString('MomentDate', record.completionCheckList.carrierInvoicePaymentDate) : ''}
                        </span>
                    </>
                );
            } else {
                return (
                    <Button type="link" onClick={() => { setSelectedLoad(record); toggleNewCarrierInvoiceModal(); }}>
                        Missing
                    </Button>
                );
            }
        } else {
            return '';
        }
    };

    const invoiceLineItemColumns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            width: 100,
            render: (text, record) => {
                if (isObjectNotEmpty(selectedLoad) && LoadUtils.loadStatusIsNot(selectedLoad.loadStatus, ['CLOSED'])) {
                    return (
                        <Dropdown overlay={invoiceLineItemMenu(record)} trigger={['click']}>
                            <Button>Actions <DownOutlined /></Button>
                        </Dropdown>
                    );
                } else {
                    return '';
                }
            },
            align: 'center',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            textAlign: 'left',
        },
        {
            title: 'Base Price',
            dataIndex: 'baseAmount',
            key: 'baseAmount',
            textAlign: 'center',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            textAlign: 'center',
        },
        {
            title: 'Total',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            textAlign: 'right',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
        },
        {
            title: 'Owed By',
            dataIndex: 'fromEntityType',
            key: 'fromEntityType',
            textAlign: 'right',
            render: (text, record) => { return stringFormatter.toFormattedString("InvoiceAccountType", text, null); },
        },
        {
            title: 'Owed To',
            dataIndex: 'toEntityType',
            key: 'toEntityType',
            textAlign: 'right',
            render: (text, record) => { return stringFormatter.toFormattedString("InvoiceAccountType", text, null); },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            textAlign: 'right',
            render: (text, record) => { return stringFormatter.toFormattedString("InvoiceLineItemStatus", text, null); },
        },
        {
            title: 'Approval',
            dataIndex: 'approvalStatus',
            key: 'approvalStatus',
            textAlign: 'right',
            render: (text, record) => { return stringFormatter.toFormattedString("InvoiceApprovalStatus", text, null); },
        },
    ];

    const transactionColumns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            width: 100,
            render: (text, record) => {
                if (isObjectNotEmpty(selectedLoad) && LoadUtils.loadStatusIsNot(selectedLoad.loadStatus, ['CLOSED'])) {
                    return (
                        <Dropdown overlay={transactionMenu(record)} trigger={['click']}>
                            <Button>Actions <DownOutlined /></Button>
                        </Dropdown>
                    );
                } else {
                    return '';
                }
            },
            align: 'center',
        },
        {
            title: 'Payment Method',
            dataIndex: 'paymentMethod',
            key: 'paymentMethod',
            textAlign: 'left',
            render: (text, record) => { return stringFormatter.toFormattedString("PaymentMethod", text, null); },
        },
        {
            title: 'Payment Status',
            dataIndex: 'paymentStatus',
            key: 'paymentStatus',
            textAlign: 'left',
            render: (text, record) => { return stringFormatter.toFormattedString("PaymentStatus", text, null); },
        },
        {
            title: 'From',
            dataIndex: 'fromSource',
            key: 'fromSource',
            textAlign: 'left',
            render: (text, record) => { return stringFormatter.toFormattedString("InvoiceSourceAccountType", text, null); },
        },
        {
            title: 'For',
            dataIndex: 'fromEntityType',
            key: 'fromEntityType',
            textAlign: 'left',
            render: (text, record) => { return stringFormatter.toFormattedString("InvoiceAccountType", text, null); },
        },
        {
            title: 'To',
            dataIndex: 'toSource',
            key: 'toSource',
            textAlign: 'left',
            render: (text, record) => { return stringFormatter.toFormattedString("InvoiceSourceAccountType", text, null); },
        },
        {
            title: 'For',
            dataIndex: 'toEntityType',
            key: 'toEntityType',
            textAlign: 'left',
            render: (text, record) => { return stringFormatter.toFormattedString("InvoiceAccountType", text, null); },
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            textAlign: 'center',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
        },
        {
            title: 'Transaction Fee',
            dataIndex: 'transactionFee',
            key: 'transactionFee',
            textAlign: 'center',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
        },
        {
            title: 'Net Amount',
            dataIndex: 'netAmount',
            key: 'netAmount',
            textAlign: 'center',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
        },
        {
            title: 'Paid At',
            dataIndex: 'paidAt',
            key: 'paidAt',
            textAlign: 'left',
            render: (text, record) => { return stringFormatter.toFormattedString("MomentDateTime", text, null); },
        },
        {
            title: 'External Reference Id',
            dataIndex: 'qbTransactionId',
            key: 'qbTransactionId',
            textAlign: 'center',
        },
    ];

    const loadColumns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            className: classes.columnNoBreak,
            width: 50,
            render: (text, record) => {
                return (
                    <Dropdown overlay={loadMenu(record)} trigger={['click']}>
                        <Button>Actions <DownOutlined /></Button>
                    </Dropdown>
                );
            },
            align: 'center',
        },
        {
            title: 'Load Id',
            dataIndex: 'irisId',
            key: 'irisId',
            align: 'center',
            ...columnSearchFilter.getColumnSearchProps('irisId', 'Load Id', searchText, setSearchText)
        }
    ];

    if (showActiveLoadData === true) {
        loadColumns.push({
            title: 'Load Name',
            dataIndex: 'parentName',
            key: 'parentName',
            align: 'center',
            render: (text, record) => { return LoadUtils.getLoadName(record); },
        });
        loadColumns.push({
            title: 'Customer Order #',
            dataIndex: 'customerOrderNumber',
            key: 'customerOrderNumber',
            align: 'center',
        });
        loadColumns.push({
            title: 'Pick-Up Date',
            dataIndex: 'pickUpDateTime',
            key: 'pickUpDateTime',
            align: 'center',
            sorter: (a, b) => compareByAlph(a.pickUpDateTime, b.pickUpDateTime),
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'ascend',
            render: (text, record) => { return isObjectNotEmpty(record.origin) ? stringFormatter.toFormattedString("MomentDate", text, null, record.origin.timeZone) : ''; },
        });
        loadColumns.push({
            title: 'Origin',
            dataIndex: ['origin', 'stopLocation', 'name'],
            key: 'origin.stopLocation.name',
        });
        loadColumns.push({
            title: 'Destination',
            dataIndex: ['destination', 'stopLocation', 'name'],
            key: 'destination.stopLocation.name',
        });
        loadColumns.push({
            title: 'Type',
            dataIndex: ['equipmentNeeds', 'trailerType', 'description'],
            key: 'equipmentNeeds.trailerType.description',
        });
        loadColumns.push({
            title: 'ETA',
            dataIndex: 'eta',
            key: 'eta',
            render: (text, record) => {
                if (record.loadStatus === "IN_TRANSIT" || record.loadStatus === "AT_STOP") {
                    return stringFormatter.toFormattedString("MomentDateTime", text, null, record.etaTimeZone);
                } else {
                    return 'N/A';
                }
            },
        });
        loadColumns.push({
            title: 'Status',
            dataIndex: 'loadStatus',
            key: 'loadStatus',
            filters: defaultLoadStatusFilter.map((loadStatus) => {
                return {
                    text: LoadUtils.getLoadStatusDisplay(loadStatus, entityType, { createdByEntityType: entityType, serviceType: entityType === 'STAFF' ? 'BROKERAGE' : 'TMS' }),
                    value: loadStatus
                };
            }),
            filterMultiple: true,
            defaultFilteredValue: defaultLoadStatusFilter,
            onFilter: (value, record) => record.loadStatus.indexOf(value) === 0,
            sorter: (a, b) => compareByAlph(a.loadStatus, b.loadStatus),
            sortDirections: ['descend', 'ascend'],
            render: (text, record) => { return LoadUtils.getLoadStatusDisplay(text, entityType, record); },
        });

        if (CanDo({ staffOnly: true })) {
            if (isStringNotEmpty(carrierId)) {
                loadColumns.push({
                    title: 'Driver(s)',
                    dataIndex: 'driverIds',
                    key: 'driverIds',
                    render: (text, record) => {
                        if (isListNotEmpty(text)) {
                            return text.map((driverId) => {
                                return (
                                    <div key={`load-${record.id}-drivers-${driverId}`}>{EntityUtils.getAccountUserName(driverId, drivers)}</div>
                                );
                            });
                        } else {
                            return '--';
                        }
                    },
                });
                loadColumns.push({
                    title: 'Assets(s)',
                    dataIndex: 'assetIds',
                    key: 'assetIds',
                    render: (text, record) => {
                        if (isListNotEmpty(text)) {
                            return text.map((assetId) => {
                                return (
                                    <div key={`load-${record.id}-assets-${assetId}`}>{EntityUtils.getAssetName(assetId, assets)}</div>
                                );
                            });
                        } else {
                            return '--';
                        }
                    },
                });
            }

            loadColumns.push({
                title: 'Shipper',
                dataIndex: ['shipper', 'name'],
                key: 'shipper.name',
            });
            loadColumns.push({
                title: 'Carrier',
                dataIndex: ['assignedCarrier', 'name'],
                key: 'assignedCarrier.name',
            });
            loadColumns.push({
                title: 'Account Rep',
                dataIndex: 'assignedAccountRepUserIds',
                key: 'assignedAccountRepUserIds',
                render: (text, record) => {
                    if (isListNotEmpty(record.assignedAccountRepUsers)) {
                        return record.assignedAccountRepUsers.map((assignedAccountRepUser) => {
                            return (
                                <div key={`load-${record.id}-assignedAccountRepUser-${assignedAccountRepUser.id}`}>{stringFormatter.toFirstNameLastName(assignedAccountRepUser)}</div>
                            );
                        });
                    } else {
                        return '';
                    }
                },
            });
            loadColumns.push({
                title: 'Service Type',
                dataIndex: 'serviceType',
                key: 'serviceType',
                render: (text, record) => { return stringFormatter.toFormattedString("LoadServiceType", text); },
            });
            loadColumns.push({
                title: 'Shipper Rate',
                dataIndex: 'shipperAmount',
                key: 'shipperAmount',
                render: (text, record) => { return stringFormatter.toFormattedMoney(text); },
            });
            loadColumns.push({
                title: 'Carrier Rate',
                dataIndex: 'carrierAmount',
                key: 'carrierAmount',
                render: (text, record) => { return stringFormatter.toFormattedMoney(text); },
            });
        } else if (isStringNotEmpty(shipperId) || CanDo({ entityTypes: ['SHIPPER'] })) {
            loadColumns.push({
                title: 'Carrier',
                dataIndex: ['assignedCarrier', 'name'],
                key: 'assignedCarrier.name',
                render: (text, record) => {
                    if (record.serviceType === 'TMS' || entityType === 'STAFF') {
                        return text;
                    } else {
                        return 'IRIS Freight';
                    }
                },
            });
            loadColumns.push({
                title: 'A/P',
                dataIndex: 'shipperAmount',
                key: 'shipperAmount',
                render: (text, record) => { return stringFormatter.toFormattedMoney(text); },
            });
        } else if (isStringNotEmpty(carrierId) || CanDo({ entityTypes: ['CARRIER'] })) {
            loadColumns.push({
                title: 'Driver(s)',
                dataIndex: 'driverIds',
                key: 'driverIds',
                render: (text, record) => {
                    if (isListNotEmpty(text)) {
                        return text.map((driverId) => {
                            return (
                                <div key={`load-${record.id}-drivers-${driverId}`}>{EntityUtils.getAccountUserName(driverId, drivers)}</div>
                            );
                        });
                    } else {
                        return '--';
                    }
                },
            });
            loadColumns.push({
                title: 'Assets(s)',
                dataIndex: 'assetIds',
                key: 'assetIds',
                render: (text, record) => {
                    if (isListNotEmpty(text)) {
                        return text.map((assetId) => {
                            return (
                                <div key={`load-${record.id}-assets-${assetId}`}>{EntityUtils.getAssetName(assetId, assets)}</div>
                            );
                        });
                    } else {
                        return '--';
                    }
                },
            });
            loadColumns.push({
                title: 'Shipper',
                dataIndex: ['shipper', 'name'],
                key: 'shipper.name',
                render: (text, record) => {
                    if (record.serviceType === 'TMS' || entityType === 'STAFF') {
                        return text;
                    } else {
                        return 'IRIS Freight';
                    }
                },
            });
            loadColumns.push({
                title: 'A/R',
                dataIndex: 'carrierAmount',
                key: 'carrierAmount',
                render: (text, record) => { return stringFormatter.toFormattedMoney(text); },
            });
        }
    } else if (showCompletedLoadData === true) {
        loadColumns.push({
            title: 'Load Name',
            dataIndex: 'parentName',
            key: 'parentName',
            align: 'center',
            render: (text, record) => { return LoadUtils.getLoadName(record); },
        });
        loadColumns.push({
            title: 'Customer Order #',
            dataIndex: 'customerOrderNumber',
            key: 'customerOrderNumber',
            align: 'center',
        });
        loadColumns.push({
            title: 'Completed Date',
            dataIndex: 'loadEndDateTime',
            key: 'loadEndDateTime',
            align: 'center',
            sorter: (a, b) => compareByAlph(a.loadEndDateTime, b.loadEndDateTime),
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'descend',
            render: (text, record) => { return isObjectNotEmpty(record.destination) ? stringFormatter.toFormattedString("MomentDate", text, null, record.destination.timeZone) : ''; },
        });
        loadColumns.push({
            title: 'Pick-Up Date',
            dataIndex: 'pickUpDateTime',
            key: 'pickUpDateTime',
            align: 'center',
            sorter: (a, b) => compareByAlph(a.pickUpDateTime, b.pickUpDateTime),
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'ascend',
            render: (text, record) => { return isObjectNotEmpty(record.origin) ? stringFormatter.toFormattedString("MomentDate", text, null, record.origin.timeZone) : ''; },
        });
        loadColumns.push({
            title: 'Origin',
            dataIndex: ['origin', 'stopLocation', 'name'],
            key: 'origin.stopLocation.name',
        });
        loadColumns.push({
            title: 'Destination',
            dataIndex: ['destination', 'stopLocation', 'name'],
            key: 'destination.stopLocation.name',
        });
        // loadColumns.push({
        //     title: 'Type',
        //     dataIndex: ['equipmentNeeds', 'trailerType', 'description'],
        //     key: 'equipmentNeeds.trailerType.description',
        // });
        if (CanDo({ staffOnly: true })) {
            loadColumns.push({
                title: 'Status',
                dataIndex: 'loadStatus',
                key: 'loadStatus',
                filters: defaultLoadStatusFilter.map((loadStatus) => {
                    return {
                        text: LoadUtils.getLoadStatusDisplay(loadStatus, entityType, { createdByEntityType: entityType, serviceType: entityType === 'STAFF' ? 'BROKERAGE' : 'TMS' }),
                        value: loadStatus
                    };
                }),
                filterMultiple: true,
                defaultFilteredValue: defaultLoadStatusFilter,
                onFilter: (value, record) => record.loadStatus.indexOf(value) === 0,
                sorter: (a, b) => compareByAlph(a.loadStatus, b.loadStatus),
                sortDirections: ['descend', 'ascend'],
                render: (text, record) => { return LoadUtils.getLoadStatusDisplay(text, entityType, record); },
            });
        }

        if (CanDo({ staffOnly: true })) {
            if (isStringNotEmpty(carrierId)) {
                loadColumns.push({
                    title: 'Driver(s)',
                    dataIndex: 'driverIds',
                    key: 'driverIds',
                    render: (text, record) => {
                        if (isListNotEmpty(text)) {
                            return text.map((driverId) => {
                                return (
                                    <div key={`load-${record.id}-drivers-${driverId}`}>{EntityUtils.getAccountUserName(driverId, drivers)}</div>
                                );
                            });
                        } else {
                            return '--';
                        }
                    },
                });
                loadColumns.push({
                    title: 'Assets(s)',
                    dataIndex: 'assetIds',
                    key: 'assetIds',
                    render: (text, record) => {
                        if (isListNotEmpty(text)) {
                            return text.map((assetId) => {
                                return (
                                    <div key={`load-${record.id}-assets-${assetId}`}>{EntityUtils.getAssetName(assetId, assets)}</div>
                                );
                            });
                        } else {
                            return '--';
                        }
                    },
                });
            }

            loadColumns.push({
                title: 'Account Rep',
                dataIndex: 'assignedAccountRepUserIds',
                key: 'assignedAccountRepUserIds',
                render: (text, record) => {
                    if (isListNotEmpty(record.assignedAccountRepUsers)) {
                        return record.assignedAccountRepUsers.map((assignedAccountRepUser) => {
                            return (
                                <div key={`load-${record.id}-assignedAccountRepUser-${assignedAccountRepUser.id}`}>{stringFormatter.toFirstNameLastName(assignedAccountRepUser)}</div>
                            );
                        });
                    } else {
                        return '';
                    }
                },
            });
            loadColumns.push({
                title: 'Service Type',
                dataIndex: 'serviceType',
                key: 'serviceType',
                render: (text, record) => { return stringFormatter.toFormattedString("LoadServiceType", text); },
            });
            if (showAccountsReceivable === false) {
                loadColumns.push({
                    title: 'Shipper',
                    dataIndex: ['shipper', 'name'],
                    key: 'shipper.name',
                });
                loadColumns.push({
                    title: 'A/R',
                    children: [
                        {
                            title: 'Rate',
                            dataIndex: 'shipperAmount',
                            key: 'shipperAmount',
                            render: (text, record) => { return stringFormatter.toFormattedMoney(text); },
                        },
                        {
                            title: 'Balance',
                            dataIndex: 'shipperBalance',
                            key: 'shipperBalance',
                            render: (text, record) => { return stringFormatter.toFormattedMoney(text); },
                        },
                        {
                            title: 'Invoice Status',
                            dataIndex: ['completionCheckList', 'shipperInvoiceStatus'],
                            key: 'completionCheckList.shipperInvoiceStatus',
                            render: (text, record) => { return stringFormatter.toFormattedString('InvoiceStatus', text); },
                        },
                        {
                            title: 'Payment Status',
                            dataIndex: ['completionCheckList', 'shipperInvoicePaymentStatus'],
                            key: 'completionCheckList.shipperInvoicePaymentStatus',
                            render: (text, record) => { return stringFormatter.toFormattedString('PaymentStatus', text); },
                        },
                        {
                            title: 'Preferred Payment Method',
                            dataIndex: ['completionCheckList', 'shipperInvoicePreferredPaymentMethod'],
                            key: 'completionCheckList.shipperInvoicePreferredPaymentMethod',
                            render: (text, record) => { return stringFormatter.toFormattedString('PreferredPaymentMethod', text); },
                        },
                        {
                            title: 'Payment Method',
                            dataIndex: ['completionCheckList', 'shipperInvoicePaymentMethod'],
                            key: 'completionCheckList.shipperInvoicePaymentMethod',
                            render: (text, record) => { return stringFormatter.toFormattedString('PaymentMethod', text); },
                        },
                        {
                            title: 'Remit Name',
                            dataIndex: ['completionCheckList', 'shipperInvoiceRemitName'],
                            key: 'completionCheckList.shipperInvoiceRemitName',
                            render: (text, record) => { return stringFormatter.toFormattedString('String', text); },
                        },
                        {
                            title: 'Payment Due Date',
                            dataIndex: ['completionCheckList', 'shipperInvoicePaymentDueDate'],
                            key: 'completionCheckList.shipperInvoicePaymentDueDate',
                            render: (text, record) => { return stringFormatter.toFormattedString('MomentDate', text); },
                        }
                    ]
                });
            }
            if (showAccountsPayable === false) {
                loadColumns.push({
                    title: 'Carrier',
                    dataIndex: ['assignedCarrier', 'name'],
                    key: 'assignedCarrier.name',
                });
                loadColumns.push({
                    title: 'A/P',
                    children: [
                        {
                            title: 'Rate',
                            dataIndex: 'carrierAmount',
                            key: 'carrierAmount',
                            render: (text, record) => { return stringFormatter.toFormattedMoney(text); },
                        },
                        {
                            title: 'Balance',
                            dataIndex: 'carrierBalance',
                            key: 'carrierBalance',
                            render: (text, record) => { return stringFormatter.toFormattedMoney(text); },
                        },
                        {
                            title: 'Invoice Status',
                            dataIndex: ['completionCheckList', 'carrierInvoiceStatus'],
                            key: 'completionCheckList.carrierInvoiceStatus',
                            render: (text, record) => { return stringFormatter.toFormattedString('InvoiceStatus', text); },
                        },
                        {
                            title: 'Payment Status',
                            dataIndex: ['completionCheckList', 'carrierInvoicePaymentStatus'],
                            key: 'completionCheckList.carrierInvoicePaymentStatus',
                            render: (text, record) => { return stringFormatter.toFormattedString('PaymentStatus', text); },
                        },
                        {
                            title: 'Preferred Payment Method',
                            dataIndex: ['completionCheckList', 'carrierInvoicePreferredPaymentMethod'],
                            key: 'completionCheckList.carrierInvoicePreferredPaymentMethod',
                            render: (text, record) => { return stringFormatter.toFormattedString('PreferredPaymentMethod', text); },
                        },
                        {
                            title: 'Payment Method',
                            dataIndex: ['completionCheckList', 'carrierInvoicePaymentMethod'],
                            key: 'completionCheckList.carrierInvoicePaymentMethod',
                            render: (text, record) => { return stringFormatter.toFormattedString('PaymentMethod', text); },
                        },
                        {
                            title: 'Remit Name',
                            dataIndex: ['completionCheckList', 'carrierInvoiceRemitName'],
                            key: 'completionCheckList.carrierInvoiceRemitName',
                            render: (text, record) => { return stringFormatter.toFormattedString('String', text); },
                        },
                        {
                            title: 'Payment Due Date',
                            dataIndex: ['completionCheckList', 'carrierInvoicePaymentDueDate'],
                            key: 'completionCheckList.carrierInvoicePaymentDueDate',
                            render: (text, record) => { return stringFormatter.toFormattedString('MomentDate', text); },
                        }
                    ]
                });
            }
        } else if (isStringNotEmpty(shipperId) || CanDo({ entityTypes: ['SHIPPER'] })) {
            if (showAccountsPayable === false && showAccountsReceivable === false) {
                loadColumns.push({
                    title: 'Carrier',
                    dataIndex: ['assignedCarrier', 'name'],
                    key: 'assignedCarrier.name',
                    render: (text, record) => {
                        if (record.serviceType === 'TMS' || entityType === 'STAFF') {
                            return text;
                        } else {
                            return 'IRIS Freight';
                        }
                    },
                });
                loadColumns.push({
                    title: 'A/P',
                    dataIndex: 'shipperAmount',
                    key: 'shipperAmount',
                    render: (text, record) => { return stringFormatter.toFormattedMoney(text); },
                });
                loadColumns.push({
                    title: 'Balance Due',
                    dataIndex: 'shipperBalance',
                    key: 'shipperBalance',
                    render: (text, record) => { return stringFormatter.toFormattedMoney(text); },
                });
            }
        } else if (isStringNotEmpty(carrierId) || CanDo({ entityTypes: ['CARRIER'] })) {
            loadColumns.push({
                title: 'Driver(s)',
                dataIndex: 'driverIds',
                key: 'driverIds',
                render: (text, record) => {
                    if (isListNotEmpty(text)) {
                        return text.map((driverId) => {
                            return (
                                <div key={`load-${record.id}-drivers-${driverId}`}>{EntityUtils.getAccountUserName(driverId, drivers)}</div>
                            );
                        });
                    } else {
                        return '--';
                    }
                },
            });
            loadColumns.push({
                title: 'Assets(s)',
                dataIndex: 'assetIds',
                key: 'assetIds',
                render: (text, record) => {
                    if (isListNotEmpty(text)) {
                        return text.map((assetId) => {
                            return (
                                <div key={`load-${record.id}-assets-${assetId}`}>{EntityUtils.getAssetName(assetId, assets)}</div>
                            );
                        });
                    } else {
                        return '--';
                    }
                },
            });
            if (showAccountsPayable === false && showAccountsReceivable === false) {
                loadColumns.push({
                    title: 'Shipper',
                    dataIndex: ['shipper', 'name'],
                    key: 'shipper.name',
                    render: (text, record) => {
                        if (record.serviceType === 'TMS' || entityType === 'STAFF') {
                            return text;
                        } else {
                            return 'IRIS Freight';
                        }
                    },
                });
                loadColumns.push({
                    title: 'A/R',
                    dataIndex: 'carrierAmount',
                    key: 'carrierAmount',
                    render: (text, record) => { return stringFormatter.toFormattedMoney(text); },
                });
                loadColumns.push({
                    title: 'Balance Owed',
                    dataIndex: 'carrierBalance',
                    key: 'carrierBalance',
                    render: (text, record) => { return stringFormatter.toFormattedMoney(text); },
                });
            }
        }
    } else {
        loadColumns.push({
            title: 'Customer Order #',
            dataIndex: 'customerOrderNumber',
            key: 'customerOrderNumber',
            align: 'center',
        });
        loadColumns.push({
            title: 'Completed Date',
            dataIndex: 'loadEndDateTime',
            key: 'loadEndDateTime',
            align: 'center',
            sorter: (a, b) => compareByAlph(a.loadEndDateTime, b.loadEndDateTime),
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'descend',
            render: (text, record) => { return isObjectNotEmpty(record.destination) ? stringFormatter.toFormattedString("MomentDate", text, null, record.destination.timeZone) : ''; },
        });
    }

    if (showAccountsPayable === true && showAccountsReceivable === false) {
        if (showProofOfDelivery === true) {
            loadColumns.push({
                title: 'POD Status',
                dataIndex: ['completionCheckList', 'proofOfDeliveryStatus'],
                key: 'completionCheckList.proofOfDeliveryStatus',
                align: 'center',
                render: (text, record) => {
                    return {
                        props: {
                            className: getPODStatusRowClassName(text)
                        },
                        children: getPODStatusDisplay(text, record)
                    };
                },
            });
        }

        if (showRequiredDocumentStatus === true) {
            loadColumns.push({
                title: 'Required Document Status',
                dataIndex: ['completionCheckList', 'hasDocumentsReviewed'],
                key: 'completionCheckList.hasDocumentsReviewed',
                align: 'center',
                render: (text, record) => {
                    return {
                        props: {
                            className: getRequiredDocumentStatusRowClassName(text)
                        },
                        children: getRequiredDocumentStatusDisplay(text, record)
                    };
                },
            });
        }

        if (showClaimsStatus === true) {
            loadColumns.push({
                title: 'Claims Status',
                dataIndex: ['completionCheckList', 'hasOpenClaims'],
                key: 'completionCheckList.hasOpenClaims',
                align: 'center',
                render: (text, record) => {
                    return {
                        props: {
                            className: getClaimsStatusRowClassName(text)
                        },
                        children: getClaimsStatusDisplay(text, record)
                    };
                },
            });
        }

        loadColumns.push({
            title: 'Carrier',
            dataIndex: ['assignedCarrier', 'name'],
            key: 'assignedCarrier.name',
            align: 'center',
            render: (text, record) => {
                if (record.serviceType === 'TMS' || entityType === 'STAFF') {
                    return text;
                } else {
                    return 'IRIS Freight';
                }
            },
        });
        loadColumns.push({
            title: 'Invoice Status',
            dataIndex: ['completionCheckList', 'carrierInvoiceStatus'],
            key: 'completionCheckList.carrierInvoiceStatus',
            align: 'center',
            render: (text, record) => {
                return {
                    props: {
                        className: getInvoiceStatusRowClassName(text)
                    },
                    children: getCarrierInvoiceStatusDisplay(text, record)
                };

            },
        });
        loadColumns.push({
            title: 'A/P',
            dataIndex: 'carrierAmount',
            key: 'carrierAmount',
            align: 'center',
            render: (text, record) => {
                return (
                    <Button type="text" onClick={() => { setSelectedLoad(record); toggleInvoiceLineItemModal(); }}>{stringFormatter.toFormattedMoney(text)}</Button>
                );
            },
        });
        loadColumns.push({
            title: 'Balance',
            dataIndex: 'carrierBalance',
            key: 'carrierBalance',
            align: 'center',
            render: (text, record) => {
                return (
                    <Button type="text" onClick={() => { setSelectedLoad(record); toggleTransactionModal(); }}>{stringFormatter.toFormattedMoney(text)}</Button>
                );
            },
        });
        loadColumns.push({
            title: 'Remittance To',
            dataIndex: ['completionCheckList', 'carrierInvoiceRemitName'],
            key: 'completionCheckList.carrierInvoiceRemitName',
            align: 'center',
        });
        loadColumns.push({
            title: 'Payment Method',
            dataIndex: ['completionCheckList', 'carrierInvoicePreferredPaymentMethod'],
            key: 'completionCheckList.carrierInvoicePreferredPaymentMethod',
            align: 'center',
            render: (text, record) => { return stringFormatter.toFormattedString('PreferredPaymentMethod', text); },
        });
        loadColumns.push({
            title: 'A/P Status',
            dataIndex: ['completionCheckList', 'carrierInvoicePaymentStatus'],
            key: 'completionCheckList.carrierInvoicePaymentStatus',
            align: 'center',
            render: (text, record) => { return stringFormatter.toFormattedString('PaymentStatus', text); },
        });
        loadColumns.push({
            title: 'Pay Via',
            dataIndex: ['completionCheckList', 'carrierInvoicePaymentMethod'],
            key: 'completionCheckList.carrierInvoicePaymentMethod',
            align: 'center',
            render: (text, record) => {
                if (isStringNotEmpty(text)) {
                    return stringFormatter.toFormattedString('PaymentMethod', text);
                } else {
                    return (
                        <Button type="primary" icon={<PlusOutlined />} shape="circle" onClick={() => { setSelectedLoad(record); toggleTransactionModal(); }} />
                    );
                }
            },
        });
        loadColumns.push({
            title: 'Due By',
            dataIndex: ['completionCheckList', 'carrierInvoicePaymentDueDate'],
            key: 'completionCheckList.carrierInvoicePaymentDueDate',
            align: 'center',
            render: (text, record) => { return stringFormatter.toFormattedString('MomentDate', text); },
        });
    } else if (showAccountsPayable === false && showAccountsReceivable === true) {
        if (showProofOfDelivery === true) {
            loadColumns.push({
                title: 'POD Status',
                dataIndex: ['completionCheckList', 'proofOfDeliveryStatus'],
                key: 'completionCheckList.proofOfDeliveryStatus',
                align: 'center',
                render: (text, record) => {
                    return {
                        props: {
                            className: getPODStatusRowClassName(text)
                        },
                        children: getPODStatusDisplay(text, record)
                    };
                },
            });
        }

        if (showRequiredDocumentStatus === true) {
            loadColumns.push({
                title: 'Required Document Status',
                dataIndex: ['completionCheckList', 'hasDocumentsReviewed'],
                key: 'completionCheckList.hasDocumentsReviewed',
                align: 'center',
                render: (text, record) => {
                    return {
                        props: {
                            className: getRequiredDocumentStatusRowClassName(text)
                        },
                        children: getRequiredDocumentStatusDisplay(text, record)
                    };
                },
            });
        }

        if (showClaimsStatus === true) {
            loadColumns.push({
                title: 'Claims Status',
                dataIndex: ['completionCheckList', 'hasOpenClaims'],
                key: 'completionCheckList.hasOpenClaims',
                align: 'center',
                render: (text, record) => {
                    return {
                        props: {
                            className: getClaimsStatusRowClassName(text)
                        },
                        children: getClaimsStatusDisplay(text, record)
                    };
                },
            });
        }

        loadColumns.push({
            title: 'Shipper',
            dataIndex: ['shipper', 'name'],
            key: 'shipper.name',
            align: 'center',
            render: (text, record) => {
                if (record.serviceType === 'TMS' || entityType === 'STAFF') {
                    return text;
                } else {
                    return 'IRIS Freight';
                }
            },
        });
        loadColumns.push({
            title: 'Invoice Status',
            dataIndex: ['completionCheckList', 'shipperInvoiceStatus'],
            key: 'completionCheckList.shipperInvoiceStatus',
            align: 'center',
            render: (text, record) => {
                return {
                    props: {
                        className: getInvoiceStatusRowClassName(text)
                    },
                    children: getShipperInvoiceStatusDisplay(text, record)
                };

            },
        });
        loadColumns.push({
            title: 'A/R',
            dataIndex: 'shipperAmount',
            key: 'shipperAmount',
            align: 'center',
            render: (text, record) => {
                return (
                    <Button type="text" onClick={() => { setSelectedLoad(record); toggleInvoiceLineItemModal(); }}>{stringFormatter.toFormattedMoney(text)}</Button>
                );
            },
        });
        loadColumns.push({
            title: 'Balance',
            dataIndex: 'shipperBalance',
            key: 'shipperBalance',
            align: 'center',
            render: (text, record) => {
                return (
                    <Button type="text" onClick={() => { setSelectedLoad(record); toggleTransactionModal(); }}>{stringFormatter.toFormattedMoney(text)}</Button>
                );
            },
        });
        loadColumns.push({
            title: 'Remittance To',
            dataIndex: ['completionCheckList', 'shipperInvoiceRemitName'],
            key: 'completionCheckList.shipperInvoiceRemitName',
            align: 'center',
        });
        loadColumns.push({
            title: 'Payment Method',
            dataIndex: ['completionCheckList', 'shipperInvoicePreferredPaymentMethod'],
            key: 'completionCheckList.shipperInvoicePreferredPaymentMethod',
            align: 'center',
            render: (text, record) => { return stringFormatter.toFormattedString('PreferredPaymentMethod', text); },
        });
        loadColumns.push({
            title: 'A/R Status',
            dataIndex: ['completionCheckList', 'shipperInvoicePaymentStatus'],
            key: 'completionCheckList.shipperInvoicePaymentStatus',
            align: 'center',
            render: (text, record) => { return stringFormatter.toFormattedString('PaymentStatus', text); },
        });
        loadColumns.push({
            title: 'Pay Via',
            dataIndex: ['completionCheckList', 'shipperInvoicePaymentMethod'],
            key: 'completionCheckList.shipperInvoicePaymentMethod',
            align: 'center',
            render: (text, record) => {
                if (isStringNotEmpty(text)) {
                    return stringFormatter.toFormattedString('PaymentMethod', text);
                } else {
                    return (
                        <Button type="primary" icon={<PlusOutlined />} shape="circle" onClick={() => { setSelectedLoad(record); toggleTransactionModal(); }} />
                    );
                }
            },
        });
        loadColumns.push({
            title: 'Due By',
            dataIndex: ['completionCheckList', 'shipperInvoicePaymentDueDate'],
            key: 'completionCheckList.shipperInvoicePaymentDueDate',
            align: 'center',
            render: (text, record) => { return stringFormatter.toFormattedString('MomentDate', text); },
        });
    } else if (showAccountsPayable === true && showAccountsReceivable === true) {
        if (showProofOfDelivery === true) {
            loadColumns.push({
                title: 'POD Status',
                dataIndex: ['completionCheckList', 'proofOfDeliveryStatus'],
                key: 'completionCheckList.proofOfDeliveryStatus',
                align: 'center',
                render: (text, record) => {
                    return {
                        props: {
                            className: getPODStatusRowClassName(text)
                        },
                        children: getPODStatusDisplay(text, record)
                    };
                },
            });
        }

        if (showRequiredDocumentStatus === true) {
            loadColumns.push({
                title: 'Required Document Status',
                dataIndex: ['completionCheckList', 'hasDocumentsReviewed'],
                key: 'completionCheckList.hasDocumentsReviewed',
                align: 'center',
                render: (text, record) => {
                    return {
                        props: {
                            className: getRequiredDocumentStatusRowClassName(text)
                        },
                        children: getRequiredDocumentStatusDisplay(text, record)
                    };
                },
            });
        }

        if (showClaimsStatus === true) {
            loadColumns.push({
                title: 'Claims Status',
                dataIndex: ['completionCheckList', 'hasOpenClaims'],
                key: 'completionCheckList.hasOpenClaims',
                align: 'center',
                render: (text, record) => {
                    return {
                        props: {
                            className: getClaimsStatusRowClassName(text)
                        },
                        children: getClaimsStatusDisplay(text, record)
                    };
                },
            });
        }

        loadColumns.push({
            title: 'Shipper',
            dataIndex: ['shipper', 'name'],
            key: 'shipper.name',
            align: 'center',
            render: (text, record) => {
                if (record.serviceType === 'TMS' || entityType === 'STAFF') {
                    return text;
                } else {
                    return 'IRIS Freight';
                }
            },
        });
        loadColumns.push({
            title: 'A/R',
            dataIndex: 'shipperAmount',
            key: 'shipperAmount',
            align: 'center',
            render: (text, record) => {
                return (
                    <Button type="text" onClick={() => { setSelectedLoad(record); toggleInvoiceLineItemModal(); }}>{stringFormatter.toFormattedMoney(text)}</Button>
                );
            },
        });
        loadColumns.push({
            title: 'Customer Invoice Status',
            dataIndex: ['completionCheckList', 'shipperInvoiceStatus'],
            key: 'completionCheckList.shipperInvoiceStatus',
            align: 'center',
            render: (text, record) => {
                return {
                    props: {
                        className: getInvoiceStatusRowClassName(text)
                    },
                    children: getShipperInvoiceStatusDisplay(text, record)
                };

            },
        });
        loadColumns.push({
            title: 'A/R Due',
            dataIndex: ['completionCheckList', 'shipperInvoicePaymentDueDate'],
            key: 'completionCheckList.shipperInvoicePaymentDueDate',
            align: 'center',
            render: (text, record) => { return stringFormatter.toFormattedString('MomentDate', text); },
        });
        loadColumns.push({
            title: 'A/R Status',
            dataIndex: ['completionCheckList', 'shipperInvoicePaymentStatus'],
            key: 'completionCheckList.shipperInvoicePaymentStatus',
            align: 'center',
            render: (text, record) => { return stringFormatter.toFormattedString('PaymentStatus', text); },
        });
        loadColumns.push({
            title: 'A/R Balance',
            dataIndex: 'shipperBalance',
            key: 'shipperBalance',
            align: 'center',
            render: (text, record) => {
                return (
                    <Button type="text" onClick={() => { setSelectedLoad(record); toggleTransactionModal(); }}>{stringFormatter.toFormattedMoney(text)}</Button>
                );
            },
        });
        loadColumns.push({
            title: 'A/R Remittance To',
            dataIndex: ['completionCheckList', 'shipperInvoiceRemitName'],
            key: 'completionCheckList.shipperInvoiceRemitName',
            align: 'center',
        });
        loadColumns.push({
            title: 'A/R Payment Method',
            dataIndex: ['completionCheckList', 'shipperInvoicePreferredPaymentMethod'],
            key: 'completionCheckList.shipperInvoicePreferredPaymentMethod',
            align: 'center',
            render: (text, record) => { return stringFormatter.toFormattedString('PreferredPaymentMethod', text); },
        });
        loadColumns.push({
            title: 'A/R Pay Via',
            dataIndex: ['completionCheckList', 'shipperInvoicePaymentMethod'],
            key: 'completionCheckList.shipperInvoicePaymentMethod',
            align: 'center',
            render: (text, record) => {
                if (isStringNotEmpty(text)) {
                    return stringFormatter.toFormattedString('PaymentMethod', text);
                } else {
                    return (
                        <Button type="primary" icon={<PlusOutlined />} shape="circle" onClick={() => { setSelectedLoad(record); toggleTransactionModal(); }} />
                    );
                }
            },
        });

        loadColumns.push({
            title: 'Carrier',
            dataIndex: ['assignedCarrier', 'name'],
            key: 'assignedCarrier.name',
            align: 'center',
            render: (text, record) => {
                if (record.serviceType === 'TMS' || entityType === 'STAFF') {
                    return text;
                } else {
                    return 'IRIS Freight';
                }
            },
        });
        loadColumns.push({
            title: 'A/P',
            dataIndex: 'carrierAmount',
            key: 'carrierAmount',
            align: 'center',
            render: (text, record) => {
                return (
                    <Button type="text" onClick={() => { setSelectedLoad(record); toggleInvoiceLineItemModal(); }}>{stringFormatter.toFormattedMoney(text)}</Button>
                );
            },
        });
        loadColumns.push({
            title: 'Carrier Invoice Status',
            dataIndex: ['completionCheckList', 'carrierInvoiceStatus'],
            key: 'completionCheckList.carrierInvoiceStatus',
            align: 'center',
            render: (text, record) => {
                return {
                    props: {
                        className: getInvoiceStatusRowClassName(text)
                    },
                    children: getCarrierInvoiceStatusDisplay(text, record)
                };

            },
        });
        loadColumns.push({
            title: 'A/P Due',
            dataIndex: ['completionCheckList', 'carrierInvoicePaymentDueDate'],
            key: 'completionCheckList.carrierInvoicePaymentDueDate',
            align: 'center',
            render: (text, record) => { return stringFormatter.toFormattedString('MomentDate', text); },
        });
        loadColumns.push({
            title: 'A/P Status',
            dataIndex: ['completionCheckList', 'carrierInvoicePaymentStatus'],
            key: 'completionCheckList.carrierInvoicePaymentStatus',
            align: 'center',
            render: (text, record) => { return stringFormatter.toFormattedString('PaymentStatus', text); },
        });
        loadColumns.push({
            title: 'A/P Balance',
            dataIndex: 'carrierBalance',
            key: 'carrierBalance',
            align: 'center',
            render: (text, record) => {
                return (
                    <Button type="text" onClick={() => { setSelectedLoad(record); toggleTransactionModal(); }}>{stringFormatter.toFormattedMoney(text)}</Button>
                );
            },
        });
        loadColumns.push({
            title: 'A/P Remittance To',
            dataIndex: ['completionCheckList', 'carrierInvoiceRemitName'],
            key: 'completionCheckList.carrierInvoiceRemitName',
            align: 'center',
        });
        loadColumns.push({
            title: 'A/P Payment Method',
            dataIndex: ['completionCheckList', 'carrierInvoicePreferredPaymentMethod'],
            key: 'completionCheckList.carrierInvoicePreferredPaymentMethod',
            align: 'center',
            render: (text, record) => { return stringFormatter.toFormattedString('PreferredPaymentMethod', text); },
        });
        loadColumns.push({
            title: 'A/P Pay Via',
            dataIndex: ['completionCheckList', 'carrierInvoicePaymentMethod'],
            key: 'completionCheckList.carrierInvoicePaymentMethod',
            align: 'center',
            render: (text, record) => {
                if (isStringNotEmpty(text)) {
                    return stringFormatter.toFormattedString('PaymentMethod', text);
                } else {
                    return (
                        <Button type="primary" icon={<PlusOutlined />} shape="circle" onClick={() => { setSelectedLoad(record); toggleTransactionModal(); }} />
                    );
                }
            },
        });
    } else if (showProofOfDelivery === true) {
        loadColumns.push({
            title: 'POD Status',
            dataIndex: ['completionCheckList', 'proofOfDeliveryStatus'],
            key: 'completionCheckList.proofOfDeliveryStatus',
            align: 'center',
            render: (text, record) => {
                return {
                    props: {
                        className: getPODStatusRowClassName(text)
                    },
                    children: getPODStatusDisplay(text, record)
                };
            },
        });
    } else if (showRequiredDocumentStatus === true) {
        loadColumns.push({
            title: 'Required Document Status',
            dataIndex: ['completionCheckList', 'hasDocumentsReviewed'],
            key: 'completionCheckList.hasDocumentsReviewed',
            align: 'center',
            render: (text, record) => {
                return {
                    props: {
                        className: getRequiredDocumentStatusRowClassName(text)
                    },
                    children: getRequiredDocumentStatusDisplay(text, record)
                };
            },
        });
    } else if (showClaimsStatus === true) {
        loadColumns.push({
            title: 'Claims Status',
            dataIndex: ['completionCheckList', 'hasOpenClaims'],
            key: 'completionCheckList.hasOpenClaims',
            align: 'center',
            render: (text, record) => {
                return {
                    props: {
                        className: getClaimsStatusRowClassName(text)
                    },
                    children: getClaimsStatusDisplay(text, record)
                };
            },
        });
    }

    //#endregion
    //#region useMemos and useEffects

    useMemo(() => {
        dispatch(actionCreators.getAccounts());
    }, []);

    useEffect(() => {
        if (showActiveLoadData === true) {
            if (entityType === 'CARRIER') {
                dispatch(actionCreators.getAssets(entityId, false));
                dispatch(actionCreators.getDrivers(entityId, false));
            } else if (isStringNotEmpty(carrierId)) {
                dispatch(actionCreators.getAssets(carrierId, false));
                dispatch(actionCreators.getDrivers(carrierId, false));
            }
        }
    }, [showActiveLoadData, entityType, entityId, carrierId]);

    useMemo(() => {
        if (isObjectNotEmpty(selectedLoad) && isStringNotEmpty(selectedLoad.id)) {
            let ids = [];
            ids.push(selectedLoad.id);
            if (isStringNotEmpty(selectedLoad.assignedCarrierId)) {
                ids.push(selectedLoad.assignedCarrierId);
            }
            dispatch(actionCreators.fetchBulkDocumentLists(selectedLoad.id, ids));

            if (selectedLoad.serviceType === 'BROKERAGE') {
                dispatch(actionCreators.fetchClaimList(selectedLoad.id, { page: 1, size: 1000000, loadId: selectedLoad.id, isDeleted: false }));
            }
            dispatch(actionCreators.fetchLoadStopList(selectedLoad.id, selectedLoad));
            dispatch(actionCreators.fetchInvoiceList(selectedLoad.id, { page: 1, size: 1000000, loadId: selectedLoad.id, isDeleted: false }));
            dispatch(actionCreators.fetchInvoiceLineItemList(selectedLoad.id, { page: 1, size: 1000000, loadId: selectedLoad.id, isDeleted: false }));
            dispatch(actionCreators.fetchTransactionList(selectedLoad.id, { page: 1, size: 1000000, loadId: selectedLoad.id, isDeleted: false }));
        }
    }, [selectedLoad]);

    useMemo(() => {
        if (isObjectNotEmpty(selectedLoad) && isStringNotEmpty(selectedLoad.shipperId) && isStringNotEmpty(selectedLoad.assignedCarrierId) && isListNotEmpty(accounts)) {
            let shipperAccountObj = accounts.find(i => i.id === selectedLoad.shipperId);
            if (isObjectNotEmpty(shipperAccountObj)) {
                // console.log(shipperAccount);
                setShipperAccount({ ...shipperAccountObj });
            }

            let carrierAccountObj = accounts.find(i => i.id === selectedLoad.assignedCarrierId);
            if (isObjectNotEmpty(carrierAccountObj)) {
                // console.log(carrierAccountObj);
                setCarrierAccount({ ...carrierAccountObj });
            }
        } else {
            setShipperAccount(null);
            setCarrierAccount(null);
        }
    }, [accounts, selectedLoad]);

    useMemo(() => {
        if (isObjectNotEmpty(selectedLoad) && isStringNotEmpty(selectedLoad.id) && isListNotEmpty(invoices)) {
            let carrierInvoiceObj = invoices.find(i => i.loadId === selectedLoad.id && i.invoiceType === 'CARRIER_INVOICE' && i.status !== 'COMPLETED' && i.status !== 'CANCELLED' && i.isDeleted === false);
            if (isObjectNotEmpty(carrierInvoiceObj)) {
                setCarrierInvoice(carrierInvoiceObj);
            }
            let shipperInvoiceObj = invoices.find(i => i.loadId === selectedLoad.id && i.invoiceType === 'SHIPPER_INVOICE' && i.status !== 'COMPLETED' && i.status !== 'CANCELLED' && i.isDeleted === false);
            if (isObjectNotEmpty(shipperInvoiceObj)) {
                setShipperInvoice(shipperInvoiceObj);
            }
        } else {
            setCarrierInvoice(null);
            setShipperInvoice(null);
        }
    }, [selectedLoad, invoices]);

    useMemo(() => {
        if (isObjectNotEmpty(documentLists) && isObjectNotEmpty(selectedLoad) && isListNotEmpty(stops)) {
            let visibleDocumentsList = DocumentUtils.selectListVisibleDocumentsByLoadIdAndStops(documentLists, selectedLoad.id, selectedLoad, stops, entityType, isAdmin);
            let pendingApprovalDocumentsList = DocumentUtils.selectListPendingApprovalDocumentsByLoadIdAndStops(documentLists, selectedLoad.id, selectedLoad, stops, entityType, isAdmin);
            let missingDocumentsList = DocumentUtils.selectListMissingDocumentsByLoadIdAndStops(documentLists, selectedLoad.id, selectedLoad, stops, entityType, isAdmin);
            let podDocumentsList = DocumentUtils.selectListProofOfDeliveryDocumentsByStops(documentLists, selectedLoad.id, stops, entityType, isAdmin);

            let carrierDocuments = isStringNotEmpty(selectedLoad.assignedCarrierId) && isNotNullOrUndefined(documentLists[selectedLoad.assignedCarrierId]) && isListNotEmpty(documentLists[selectedLoad.assignedCarrierId].records) ? [...documentLists[selectedLoad.assignedCarrierId].records] : null;
            let visibleCarrierDocuments = DocumentUtils.getVisibleDocuments(carrierDocuments, entityType, isAdmin);
            let bankAccountDocumentObj = visibleCarrierDocuments.find(doc => doc.documentType === 'BANK_INFORMATION' && doc.isDeleted === false);
            if (isObjectNotEmpty(bankAccountDocumentObj)) {
                setBankAccountDocument(bankAccountDocumentObj);
            } else {
                setBankAccountDocument({});
            }
            let factoringNOADocumentObj = visibleCarrierDocuments.find(doc => doc.documentType === 'FACTORING_NOA' && doc.isDeleted === false);
            if (isObjectNotEmpty(factoringNOADocumentObj)) {
                setFactoringNOADocument(factoringNOADocumentObj);
            } else {
                setFactoringNOADocument({});
            }

            let carrierInvoiceDocumentObj = visibleDocumentsList.find(doc => doc.documentType === 'CARRIER_INVOICE' && doc.isDeleted === false);
            if (isObjectNotEmpty(carrierInvoiceDocumentObj)) {
                setCarrierInvoiceDocument(carrierInvoiceDocumentObj);
            } else {
                setCarrierInvoiceDocument({});
            }
            let shipperInvoiceDocumentObj = visibleDocumentsList.find(doc => doc.documentType === 'SHIPPER_INVOICE' && doc.isDeleted === false);
            if (isObjectNotEmpty(shipperInvoiceDocumentObj)) {
                setShipperInvoiceDocument(shipperInvoiceDocumentObj);
            } else {
                setShipperInvoiceDocument({});
            }
            let factoringInvoiceDocumentObj = visibleDocumentsList.find(doc => doc.documentType === 'FACTORING_INVOICE' && doc.isDeleted === false);
            if (isObjectNotEmpty(factoringInvoiceDocumentObj)) {
                setFactoringInvoiceDocument(factoringInvoiceDocumentObj);
            } else {
                setFactoringInvoiceDocument({});
            }

            setVisibleDocuments(visibleDocumentsList);
            setPendingApprovalDocuments(pendingApprovalDocumentsList);
            setMissingDocuments(missingDocumentsList);
            setProofOfDeliveryDocuments(podDocumentsList);
        } else {
            setBankAccountDocument({});
            setFactoringNOADocument({});
            setCarrierInvoiceDocument({});
            setShipperInvoiceDocument({});
            setFactoringInvoiceDocument({});
            setVisibleDocuments([]);
            setPendingApprovalDocuments([]);
            setMissingDocuments([]);
            setProofOfDeliveryDocuments([]);
        }
    }, [documentLists, selectedLoad, stops, entityType, isAdmin]);

    useEffect(() => {
        refreshTable();
    }, [shipperId, carrierId, driverId, assignedAccountRepUserId, entityType, entityId]);

    useMemo(() => {
        if (isStringNotEmpty(selectedInvoiceId) && isListNotEmpty(invoices)) {
            let selectedInvoiceObj = invoices.find(i => i.id === selectedInvoiceId && i.isDeleted === false);
            setSelectedInvoice(selectedInvoiceObj);
        } else if (isListEmpty(invoices)) {
            setSelectedInvoice(null);
        }
    }, [selectedInvoiceId, invoices]);

    //#endregion
    //#region load displays

    const buttons = (
        <>
            <Tooltip placement="top" title="Refresh Queue">
                <Button type="primary" shape='circle' onClick={refreshTable} style={{ marginRight: 8 }}>
                    <FontAwesomeIcon icon={faRedoAlt} />
                </Button>
            </Tooltip>
            <Tooltip placement="top" title="Open Queue">
                <Button type="primary" shape='circle' onClick={toggleTableModal}>
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                </Button>
            </Tooltip>
        </>
    );

    //#endregion
    //#region document displays

    const createMissingDocumentList = () => {
        if (isObjectNotEmpty(selectedLoad) && isListNotEmpty(missingDocuments) && isLoadingDocuments === false) {
            return missingDocuments.map((missingDocument, index, arr) => {
                return (
                    <Alert
                        key={`missing-document-${index}`}
                        style={{ marginBottom: 8 }}
                        type="error"
                        message={`${missingDocument.entityName} is missing ${missingDocument.documentDisplayName}`}
                        showIcon={true}
                        action={<Space><Button size="small" type="ghost" onClick={(e) => { setSelectedDocument(missingDocument); toggleEditMissingDocument(); }}>Upload</Button></Space>}
                    />
                );
            });
        } else {
            return null;
        }
    };

    const createPendingApprovalDocumentList = () => {
        if (isObjectNotEmpty(selectedLoad) && isListNotEmpty(pendingApprovalDocuments) && isLoadingDocuments === false) {
            return pendingApprovalDocuments.filter(d => d.links.map((link) => link.entityId).includes(selectedLoad.id)).map((document, index, arr) => {
                return (
                    <Alert
                        key={`pending-approval-document-${index}`}
                        style={{ marginBottom: 8 }}
                        type="warning"
                        message={`${document.documentDisplayName}${DocumentUtils.reviewableDocumentTypes.includes(document.documentType) ? ', Review Status: ' + stringFormatter.toFormattedString('DocumentReviewStatus', document.reviewStatus) : ''}`}
                        showIcon={true}
                        action={<Space><Button size="small" type="ghost" onClick={(e) => { setSelectedDocument(document); toggleReviewDocument(); }}>Review</Button></Space>}
                    />
                );
            });
        } else {
            return null;
        }
    };

    const createVisibleDocumentList = () => {
        if (isObjectNotEmpty(selectedLoad) && isListNotEmpty(visibleDocuments) && isLoadingDocuments === false) {
            return visibleDocuments.filter(d => d.links.map((link) => link.entityId).includes(selectedLoad.id) && d.documentType !== 'CARRIER_INVOICE' && d.documentType !== 'SHIPPER_INVOICE' && d.documentType !== 'FACTORING_INVOICE' && d.documentType !== 'INVOICE').map((document, index, arr) => {
                if (document.reviewStatus !== 'APPROVED' && document.reviewStatus !== 'CLEAN' && document.reviewStatus !== 'OSDR' && DocumentUtils.reviewableDocumentTypes.includes(document.documentType) && isBooleanFalse(document.isReviewed) && isBooleanFalse(document.isExpired) && isBooleanFalse(document.isDeleted)) {
                    return (
                        <Alert
                            key={`pending-approval-document-${index}`}
                            style={{ marginBottom: 8 }}
                            type="warning"
                            message={`${document.documentDisplayName}${DocumentUtils.reviewableDocumentTypes.includes(document.documentType) ? ', Review Status: ' + stringFormatter.toFormattedString('DocumentReviewStatus', document.reviewStatus) : ''}`}
                            showIcon={true}
                            action={<Space><Button size="small" type="ghost" onClick={(e) => { setSelectedDocument(document); toggleReviewDocument(); }}>Review</Button></Space>}
                        />
                    );
                } else {
                    return (
                        <Alert
                            key={`visible-document-${index}`}
                            style={{ marginBottom: 8 }}
                            type="info"
                            message={`${document.documentDisplayName}${DocumentUtils.reviewableDocumentTypes.includes(document.documentType) ? ', Review Status: ' + stringFormatter.toFormattedString('DocumentReviewStatus', document.reviewStatus) : ''}`}
                            showIcon={true}
                            action={<Space><Button size="small" type="ghost" onClick={(e) => { setSelectedDocument(document); toggleViewDocument(); }}>View</Button></Space>}
                        />
                    );
                }
            });
        } else {
            return null;
        }
    };

    const createMissingPODList = () => {
        if (isObjectNotEmpty(selectedLoad) && isListNotEmpty(proofOfDeliveryDocuments) && isLoadingDocuments === false) {
            return proofOfDeliveryDocuments.filter(d => d.status === 'MISSING').map((missingDocument, index, arr) => {
                return (
                    <Alert
                        key={`missing-document-${index}`}
                        style={{ marginBottom: 8 }}
                        type="error"
                        message={`${missingDocument.entityName} is missing ${missingDocument.documentDisplayName}`}
                        showIcon={true}
                        action={<Space><Button size="small" type="ghost" onClick={(e) => { setSelectedDocument(missingDocument); toggleEditMissingDocument(); }}>Upload</Button></Space>}
                    />
                );
            });
        } else {
            return null;
        }
    };

    const createPendingApprovalPODList = () => {
        if (isObjectNotEmpty(selectedLoad) && isListNotEmpty(proofOfDeliveryDocuments) && isLoadingDocuments === false) {
            return proofOfDeliveryDocuments.filter(d => d.status === 'PENDING_APPROVAL').map((document, index, arr) => {
                return (
                    <Alert
                        key={`pending-approval-document-${index}`}
                        style={{ marginBottom: 8 }}
                        type="warning"
                        message={`${document.documentDisplayName}${DocumentUtils.reviewableDocumentTypes.includes(document.documentType) ? ', Review Status: ' + stringFormatter.toFormattedString('DocumentReviewStatus', document.reviewStatus) : ''}`}
                        showIcon={true}
                        action={<Space><Button size="small" type="ghost" onClick={(e) => { setSelectedDocument(document); toggleReviewDocument(); }}>Review</Button></Space>}
                    />
                );
            });
        } else {
            return null;
        }
    };

    const createVisiblePODList = () => {
        if (isObjectNotEmpty(selectedLoad) && isListNotEmpty(proofOfDeliveryDocuments) && isLoadingDocuments === false) {
            return proofOfDeliveryDocuments.filter(d => d.status !== 'MISSING' && d.status !== 'PENDING_APPROVAL').map((document, index, arr) => {
                return (
                    <Alert
                        key={`visible-document-${index}`}
                        style={{ marginBottom: 8 }}
                        type="info"
                        message={`${document.documentDisplayName}${DocumentUtils.reviewableDocumentTypes.includes(document.documentType) ? ', Review Status: ' + stringFormatter.toFormattedString('DocumentReviewStatus', document.reviewStatus) : ''}`}
                        showIcon={true}
                        action={<Space><Button size="small" type="ghost" onClick={(e) => { setSelectedDocument(document); toggleViewDocument(); }}>View</Button></Space>}
                    />
                );
            });
        } else {
            return null;
        }
    };

    const editMissingDocumentDrawer = (
        <Drawer
            title={isObjectNotEmpty(selectedDocument) ? `Upload ${selectedDocument.documentDisplayName}` : ""}
            onClose={toggleEditMissingDocument}
            visible={showEditMissingDocument === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1010 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditMissingDocument
                cancel={toggleEditMissingDocument}
                missingDocument={selectedDocument}
                load={selectedLoad}
                loadId={isObjectNotEmpty(selectedLoad) ? selectedLoad.id : null}
            />
        </Drawer>
    );

    const reviewDocumentDrawer = (
        <Drawer
            title={isObjectNotEmpty(selectedDocument) ? `Review ${selectedDocument.documentDisplayName}` : ""}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleReviewDocument}
            visible={showReviewDocument === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1010 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <ReviewDocument
                cancel={toggleReviewDocument}
                document={selectedDocument}
                documentType={isObjectNotEmpty(selectedDocument) && isStringNotEmpty(selectedDocument.documentType) ? selectedDocument.documentType : null}
                documentEntitySubType={isObjectNotEmpty(selectedDocument) && isStringNotEmpty(selectedDocument.entitySubType) ? selectedDocument.entitySubType : null}
                loadId={isObjectNotEmpty(selectedLoad) ? selectedLoad.id : null}
                fileId={isObjectNotEmpty(selectedDocument) && isStringNotEmpty(selectedDocument.id) ? selectedDocument.id : null}
            />
        </Drawer>
    );

    const viewDocumentDrawer = (
        <Drawer
            title={isObjectNotEmpty(selectedDocument) && isStringNotEmpty(selectedDocument.documentDisplayName) ? selectedDocument.documentDisplayName : ""}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleViewDocument}
            visible={showViewDocument === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1010 }}
            destroyOnClose={true}
            closable={true}
            maskClosable={false}
        >
            <Document
                displayName={isObjectNotEmpty(selectedDocument) && isStringNotEmpty(selectedDocument.documentDisplayName) ? selectedDocument.documentDisplayName : null}
                fileId={isObjectNotEmpty(selectedDocument) && isStringNotEmpty(selectedDocument.id) ? selectedDocument.id : null}
            />
        </Drawer>
    );

    const missingDocumentsModal = (
        <Modal
            title="Missing Documents"
            visible={showMissingDocuments === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={toggleMissingDocuments}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoadingDocuments === true}>
                {createMissingDocumentList()}
            </Spin>
        </Modal>
    );

    const reviewDocumentsModal = (
        <Modal
            title="Documents Pending Review"
            visible={showReviewDocuments === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={toggleReviewDocuments}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoadingDocuments === true}>
                {createPendingApprovalDocumentList()}
            </Spin>
        </Modal>
    );

    const viewDocumentsModal = (
        <Modal
            title="Document Review"
            visible={showViewDocuments === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={toggleViewDocuments}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            {isObjectNotEmpty(selectedLoad) ? (
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoadingDocuments === true}>
                    <Header
                        title="Document Review"
                        titleStyle={{ paddingLeft: 0, color: '#000000', fontWeight: 500 }}
                        style={{ paddingLeft: 0, paddingRight: 0 }}
                        icon={<DeliveredProcedureOutlined />}
                        iconStyle={{ background: 'transparent' }}
                        title2={`Please review the documents below and upload any missing BOLs, receipts, etc. If there are any documents pending review, please review and approve them.`}
                        title2Style={{ whiteSpace: 'normal' }}
                    />
                    {createMissingDocumentList()}
                    {createVisibleDocumentList()}
                    <div style={{ marginTop: 24, textAlign: 'right' }}>
                        <Button type="primary" onClick={() => { markDocumentsReviewed(); }} disabled={missingDocuments.length > 0 || pendingApprovalDocuments.length > 0 || visibleDocuments.length === 0}>All Documents are Reviewed</Button>
                    </div>
                </Spin>
            ) : null}
        </Modal>
    );

    const missingPODModal = (
        <Modal
            title="Missing Proof of Delivery"
            visible={showMissingPOD === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={toggleMissingPOD}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoadingDocuments === true}>
                {createMissingPODList()}
            </Spin>
        </Modal>
    );

    const reviewPODModal = (
        <Modal
            title="Documents Pending Review"
            visible={showReviewPOD === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={toggleReviewPOD}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoadingDocuments === true}>
                {createPendingApprovalPODList()}
            </Spin>
        </Modal>
    );

    const viewPODModal = (
        <Modal
            title="Proof of Delivery"
            visible={showViewPOD === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={toggleViewPOD}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            {isObjectNotEmpty(selectedLoad) ? (
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoadingDocuments === true}>
                    <Header
                        title="Proof of Delivery Review"
                        titleStyle={{ paddingLeft: 0, color: '#000000', fontWeight: 500 }}
                        style={{ paddingLeft: 0, paddingRight: 0 }}
                        icon={<DeliveredProcedureOutlined />}
                        iconStyle={{ background: 'transparent' }}
                        title2={`Please review the proof of delivery below and upload any missing BOLs. If there are any documents pending review, please review and approve them.`}
                        title2Style={{ whiteSpace: 'normal' }}
                    />
                    {createMissingPODList()}
                    {createVisiblePODList()}
                    {createPendingApprovalPODList()}
                    <div style={{ marginTop: 24, textAlign: 'right' }}>
                        <Button type="primary" danger onClick={() => { toggleViewPOD(); }}>Cancel</Button>
                        <Button type="primary" style={{ marginLeft: 8 }} onClick={() => { markPODDone(); }} disabled={proofOfDeliveryDocuments.filter(d => d.status === 'MISSING').length > 0 || proofOfDeliveryDocuments.filter(d => d.status === 'PENDING_APPROVAL').length > 0}>Submit</Button>
                    </div>
                </Spin>
            ) : null}
        </Modal>
    );

    const bankAccountDocumentComponents = () => {
        if (isObjectNotEmpty(selectedLoad) && isObjectNotEmpty(carrierInvoice) && carrierInvoice.paymentMethod === 'DIRECT_DEPOSIT' && selectedLoad.serviceType === 'BROKERAGE' && isLoadingDocuments === false) {
            if (isObjectNotEmpty(bankAccountDocument)) {
                return (
                    <FormItemDisplay {...formItemLayout} label="Carrier chose Direct Deposit as their preferred payment method." noColon format="vertical">
                        <DataLinkRow title="Bank Account Information" fileId={bankAccountDocument.id} />
                    </FormItemDisplay>
                );
            } else {
                return (
                    <FormItemDisplay {...formItemLayout} label="Carrier chose Direct Deposit as their preferred payment method. If we don't already have their bank account information, please provide us with their bank account information." noColon format="vertical">
                        <Button type="default" onClick={(e) => { setSelectedDocument({ entityType: 'ACCOUNT', entityId: carrierAccount.id, entity: carrierAccount, entitySubType: null, entityName: carrierAccount.name, documentType: 'BANK_INFORMATION', documentTypeName: 'Bank Account Information', documentDisplayName: 'Bank Account Information', visibleTo: 'STAFF' }); toggleEditMissingDocument(); }}>Upload Bank Account Information</Button>
                    </FormItemDisplay>
                );
            }
        } else {
            return null;
        }
    };

    const factoringNOADocumentComponents = () => {
        if (isObjectNotEmpty(carrierInvoice) && carrierInvoice.paymentMethod === 'FACTORING' && isLoadingDocuments === false) {
            if (isObjectNotEmpty(factoringNOADocument)) {
                return (
                    <FormItemDisplay {...formItemLayout} label="Carrier chose Factoring as their preferred payment method." noColon format="vertical">
                        <DataLinkRow title="Factoring Notice of Assignment" fileId={factoringNOADocument.id} />
                    </FormItemDisplay>
                );
            } else {
                return (
                    <FormItemDisplay {...formItemLayout} label="Carrier chose Direct Deposit as their preferred payment method. If we don't already have the Notice of Assignment for your factoring company, please provide us with the Notice of Assignment." noColon format="vertical">
                        <Button type="default" onClick={(e) => { setSelectedDocument({ entityType: 'ACCOUNT', entityId: carrierAccount.id, entity: carrierAccount, entitySubType: null, entityName: carrierAccount.name, documentType: 'FACTORING_NOA', documentTypeName: 'Factoring Notice of Assignment', documentDisplayName: 'Factoring Notice of Assignment', visibleTo: 'STAFF' }); toggleEditMissingDocument(); }}>Upload Factoring Notice of Assignment</Button>
                    </FormItemDisplay>
                );
            }
        } else {
            return null;
        }
    };

    const carrierInvoiceDocumentComponents = () => {
        if (isObjectNotEmpty(selectedLoad) && isLoadingDocuments === false) {
            if (isObjectNotEmpty(carrierInvoiceDocument)) {
                return (
                    <FormItemDisplay {...formItemLayout} label="Carrier uploaded their own invoice." noColon format="vertical">
                        <DataLinkRow title="Carrier Invoice" fileId={carrierInvoiceDocument.id} />
                    </FormItemDisplay>
                );
            } else {
                return (
                    <FormItemDisplay {...formItemLayout} label="If the Carrier has their own invoice, upload it here." noColon format="vertical">
                        <Button size="small" type="ghost" onClick={(e) => { setSelectedDocument({ entityType: 'LOAD', entityId: selectedLoad.id, entity: selectedLoad, entitySubType: null, entityName: LoadUtils.getLoadName(selectedLoad), documentType: 'CARRIER_INVOICE', documentTypeName: 'Carrier Invoice', documentDisplayName: 'Carrier Invoice', visibleTo: 'CARRIER' }); toggleEditMissingDocument(); }}>Upload Carrier Invoice</Button>
                    </FormItemDisplay>
                );
            }
        } else {
            return null;
        }
    };

    const shipperInvoiceDocumentComponents = () => {
        if (isObjectNotEmpty(selectedLoad) && isLoadingDocuments === false) {
            if (isObjectNotEmpty(shipperInvoiceDocument)) {
                return (
                    <FormItemDisplay {...formItemLayout} label="Shipper uploaded their own invoice." noColon format="vertical">
                        <DataLinkRow title="Shipper Invoice" fileId={shipperInvoiceDocument.id} />
                    </FormItemDisplay>
                );
            } else {
                return (
                    <FormItemDisplay {...formItemLayout} label="If the Shipper has their own invoice, upload it here." noColon format="vertical">
                        <Button size="small" type="ghost" onClick={(e) => { setSelectedDocument({ entityType: 'LOAD', entityId: selectedLoad.id, entity: selectedLoad, entitySubType: null, entityName: LoadUtils.getLoadName(selectedLoad), documentType: 'SHIPPER_INVOICE', documentTypeName: 'Shipper Invoice', documentDisplayName: 'Shipper Invoice', visibleTo: 'SHIPPER' }); toggleEditMissingDocument(); }}>Upload Shipper Invoice</Button>
                    </FormItemDisplay>
                );
            }
        } else {
            return null;
        }
    };

    const factoringInvoiceDocumentComponents = () => {
        if (isObjectNotEmpty(selectedLoad) && isLoadingDocuments === false) {
            if (isObjectNotEmpty(factoringInvoiceDocument)) {
                return (
                    <FormItemDisplay {...formItemLayout} label="Factoring Invoice has been uploaded." noColon format="vertical">
                        <DataLinkRow title="Factoring Invoice" fileId={factoringInvoiceDocument.id} />
                    </FormItemDisplay>
                );
            } else {
                return (
                    <FormItemDisplay {...formItemLayout} label="If the Carrier chose Factoring as their Payment Method and the factoring company provided an invoice, upload it here." noColon format="vertical">
                        <Button size="small" type="ghost" onClick={(e) => { setSelectedDocument({ entityType: 'LOAD', entityId: selectedLoad.id, entity: selectedLoad, entitySubType: null, entityName: LoadUtils.getLoadName(selectedLoad), documentType: 'FACTORING_INVOICE', documentTypeName: 'Factoring Invoice', documentDisplayName: 'Factoring Invoice', visibleTo: 'STAFF' }); toggleEditMissingDocument(); }}>Upload Factoring Invoice</Button>
                    </FormItemDisplay>
                );
            }
        } else {
            return null;
        }
    };

    //#endregion
    //#region invoice line item displays

    const invoiceLineItemModal = (
        <Modal
            title={'Manage Invoice Line Items'}
            visible={showInvoiceLineItemModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={() => { toggleInvoiceLineItemModal(); }}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            {isObjectNotEmpty(selectedLoad) ? (
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoadingInvoiceLineItems === true}>
                    <CanSee entityModel='LOAD' entityObject={selectedLoad} entityTypes={['SHIPPER', 'STAFF']}>
                        <DataTable
                            dataSource={invoiceLineItems.filter(i => i.loadId === selectedLoad.id && (i.fromEntityType === 'SHIPPER' || i.toEntityType === 'SHIPPER'))}
                            columns={invoiceLineItemColumns}
                            hidePaging={true}
                            rowClassName={getInvoiceLineItemRowClassName}
                            title={() => (invoiceLineItemTitle('Shipper', selectedLoad.serviceType === 'TMS' ? 'CARRIER' : 'STAFF', 'SHIPPER'))}
                            style={{ backgroundColor: '#ffffff', marginBottom: 24 }}
                            rowKey={record => record.id}
                        />
                    </CanSee>
                    <CanSee entityModel='LOAD' entityObject={selectedLoad} entityTypes={['CARRIER', 'STAFF']}>
                        <DataTable
                            dataSource={invoiceLineItems.filter(i => i.loadId === selectedLoad.id && (i.fromEntityType === 'CARRIER' || i.toEntityType === 'CARRIER'))}
                            columns={invoiceLineItemColumns}
                            hidePaging={true}
                            rowClassName={getInvoiceLineItemRowClassName}
                            title={() => (invoiceLineItemTitle('Carrier', 'CARRIER', selectedLoad.serviceType === 'TMS' ? 'SHIPPER' : 'STAFF'))}
                            style={{ backgroundColor: '#ffffff', marginBottom: 24 }}
                            rowKey={record => record.id}
                        />
                    </CanSee>
                    <CanSee staffOnly={true}>
                        <DataTable
                            dataSource={invoiceLineItems.filter(i => i.loadId === selectedLoad.id && (i.fromEntityType === 'FACTORING' || i.toEntityType === 'FACTORING'))}
                            columns={invoiceLineItemColumns}
                            hidePaging={true}
                            rowClassName={getInvoiceLineItemRowClassName}
                            title={() => (invoiceLineItemTitle('Axle Payments', 'STAFF', 'FACTORING'))}
                            style={{ backgroundColor: '#ffffff', marginBottom: 24 }}
                            rowKey={record => record.id}
                        />
                    </CanSee>
                </Spin>
            ) : null}
        </Modal>
    );

    const newInvoiceLineItemDrawer = (
        <Drawer
            title={"New Invoice Line Item"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleNewInvoiceLineItem}
            visible={showNewInvoiceLineItem === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewInvoiceLineItem
                cancel={toggleNewInvoiceLineItem}
                load={selectedLoad}
                shipperId={isObjectNotEmpty(selectedLoad) ? selectedLoad.shipperId : null}
                carrierId={isObjectNotEmpty(selectedLoad) ? selectedLoad.assignedCarrierId : null}
                stops={stops}
                fromEntityType={fromEntityType}
                toEntityType={toEntityType}
                loading={isLoadingStops === true}
            />
        </Drawer>
    );

    const editInvoiceLineItemDrawer = (
        <Drawer
            title={"Edit Invoice Line Item"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditInvoiceLineItem}
            visible={showEditInvoiceLineItem === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditInvoiceLineItem
                cancel={toggleEditInvoiceLineItem}
                invoiceLineItem={selectedInvoiceLineItem}
                load={selectedLoad}
                shipperId={isObjectNotEmpty(selectedLoad) ? selectedLoad.shipperId : null}
                carrierId={isObjectNotEmpty(selectedLoad) ? selectedLoad.assignedCarrierId : null}
            />
        </Drawer>
    );

    const editStatusInvoiceLineItemDrawer = (
        <Drawer
            title={"Update Status of Invoice Line Item"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditStatusInvoiceLineItem}
            visible={showEditStatusInvoiceLineItem === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditInvoiceLineItemStatus
                cancel={toggleEditStatusInvoiceLineItem}
                invoiceLineItem={selectedInvoiceLineItem}
                load={selectedLoad}
            />
        </Drawer>
    );

    const cancelInvoiceLineItemDrawer = (
        <Drawer
            title={"Cancel Invoice Line Item"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleCancelInvoiceLineItem}
            visible={showCancelInvoiceLineItem === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditInvoiceLineItemStatusCancelled
                cancel={toggleCancelInvoiceLineItem}
                invoiceLineItem={selectedInvoiceLineItem}
                load={selectedLoad}
            />
        </Drawer>
    );

    const editApprovalStatusInvoiceLineItemDrawer = (
        <Drawer
            title={"Approve Invoice Line Item"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditApprovalStatusInvoiceLineItem}
            visible={showEditApprovalStatusInvoiceLineItem === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditInvoiceLineItemApprovalStatus
                cancel={toggleEditApprovalStatusInvoiceLineItem}
                invoiceLineItem={selectedInvoiceLineItem}
                load={selectedLoad}
            />
        </Drawer>
    );

    const removeInvoiceLineItemDrawer = (
        <Drawer
            title={"Delete Invoice Line Item"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleRemoveInvoiceLineItem}
            visible={showRemoveInvoiceLineItem === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <RemoveInvoiceLineItem
                cancel={toggleRemoveInvoiceLineItem}
                invoiceLineItem={selectedInvoiceLineItem}
            />
        </Drawer>
    );

    //#endregion
    //#region transaction displays

    const transactionModal = (
        <Modal
            title={'Manage Transactions'}
            visible={showTransactionModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={toggleTransactionModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            {isObjectNotEmpty(selectedLoad) ? (
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoadingTransactions === true}>
                    <DataTable
                        dataSource={transactions.filter(t => t.loadId === selectedLoad.id && (t.fromEntityType === 'SHIPPER' || t.toEntityType === 'SHIPPER') && t.isDeleted === false)}
                        columns={transactionColumns}
                        hidePaging={true}
                        rowClassName={getTransactionRowClassName}
                        title={() => (transactionTitle(shipperInvoice, 'Shipper', 'SHIPPER_INVOICE'))}
                        style={{ backgroundColor: '#ffffff', marginBottom: 24 }}
                        rowKey={record => record.id}
                    />
                    <DataTable
                        dataSource={transactions.filter(t => t.loadId === selectedLoad.id && (t.fromEntityType === 'CARRIER' || t.toEntityType === 'CARRIER'))}
                        columns={transactionColumns}
                        hidePaging={true}
                        rowClassName={getTransactionRowClassName}
                        title={() => (transactionTitle(carrierInvoice, 'Carrier', 'CARRIER_INVOICE'))}
                        style={{ backgroundColor: '#ffffff', marginBottom: 24 }}
                        rowKey={record => record.id}
                    />
                    <CanSee staffOnly={true}>
                        <DataTable
                            dataSource={transactions.filter(t => t.loadId === selectedLoad.id && (t.fromEntityType === 'FACTORING' || t.toEntityType === 'FACTORING'))}
                            columns={transactionColumns}
                            hidePaging={true}
                            rowClassName={getTransactionRowClassName}
                            title={() => (transactionTitle(null, 'Axle Payments', 'FACTORING_INVOICE'))}
                            style={{ backgroundColor: '#ffffff', marginBottom: 24 }}
                            rowKey={record => record.id}
                        />
                    </CanSee>
                </Spin>
            ) : null}
        </Modal>
    );

    const newTransactionDrawer = (
        <Drawer
            title={"New Transaction"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={() => { toggleNewTransaction(); setSelectedInvoice(null); }}
            visible={showNewTransaction === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewTransaction
                cancel={() => { toggleNewTransaction(); setSelectedInvoice(null); }}
                loadId={isObjectNotEmpty(selectedLoad) ? selectedLoad.id : null}
                shipperId={isObjectNotEmpty(selectedLoad) ? selectedLoad.shipperId : null}
                carrierId={isObjectNotEmpty(selectedLoad) ? selectedLoad.assignedCarrierId : null}
                invoices={invoices}
                invoiceId={isObjectNotEmpty(selectedInvoice) ? selectedInvoice.id : null}
                loading={isLoadingInvoices === true}
            />
        </Drawer>
    );

    const editTransactionDrawer = (
        <Drawer
            title={"Edit Transaction"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditTransaction}
            visible={showEditTransaction === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditTransaction
                cancel={toggleEditTransaction}
                load={selectedLoad}
                shipperId={isObjectNotEmpty(selectedLoad) ? selectedLoad.shipperId : null}
                carrierId={isObjectNotEmpty(selectedLoad) ? selectedLoad.assignedCarrierId : null}
                transaction={selectedTransaction}
                invoices={invoices}
                loading={isLoadingInvoices === true}
            />
        </Drawer>
    );

    const editPaymentStatusTransactionDrawer = (
        <Drawer
            title={"Update Payment Status of Transaction"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditPaymentStatusTransaction}
            visible={showEditPaymentStatusTransaction === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditTransactionPaymentStatus
                cancel={toggleEditPaymentStatusTransaction}
                transaction={selectedTransaction}
                load={selectedLoad}
                invoices={invoices}
                loading={isLoadingInvoices === true}
            />
        </Drawer>
    );

    const removeTransactionDrawer = (
        <Drawer
            title={"Delete Transaction"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleRemoveTransaction}
            visible={showRemoveTransaction === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <RemoveTransaction
                cancel={toggleRemoveTransaction}
                transaction={selectedTransaction}
                invoices={invoices}
                loading={isLoadingInvoices === true}
            />
        </Drawer>
    );

    //#endregion
    //#region claims displays

    const claimsModal = (
        <Modal
            title="Claims"
            visible={showClaimsModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={() => { toggleClaimsModal(); setSelectedLoad(null); }}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoadingClaims === true}>
                <Header
                    title="Claims"
                    titleStyle={{ paddingLeft: 0, color: '#000000', fontWeight: 500 }}
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    icon={<DollarOutlined />}
                    iconStyle={{ background: 'transparent' }}
                    title2={`Please make sure all claims, if any, are closed.`}
                    title2Style={{ whiteSpace: 'normal' }}
                />

                <LoadClaims
                    load={selectedLoad}
                    loadId={isObjectNotEmpty(selectedLoad) ? selectedLoad.id : null}
                    claims={claims}
                    currentStatus={isObjectNotEmpty(selectedLoad) ? selectedLoad.loadStatus : null}
                />

                <div style={{ marginTop: 24, textAlign: 'right' }}>
                    <Button onClick={() => { toggleClaimsModal(); setSelectedLoad(null); }}>Done</Button>
                </div>
            </Spin>
        </Modal>
    );

    //#endregion
    //#region invoice displays

    const invoiceNotesModal = (
        <Modal
            title="Invoice Notes and Corrections"
            visible={showNotesModal === true}
            width="100%"
            style={{ top: 0 }}
            onCancel={() => { toggleNotesModal(); setSelectedInvoice(null); }}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <NoteList
                noteEntityId={isObjectNotEmpty(selectedInvoice) ? selectedInvoice.id : null}
                noteEntityType="INVOICE"
            />
        </Modal>
    );

    const newPriceConfirmationModal = (
        <Modal
            title="Generate and Send New Load Tender"
            visible={showNewPriceConfirmationModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={() => { toggleNewPriceConfirmationModal(); setSelectedInvoice(null); setSelectedLoad(null); }}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <LoadNewPriceConfirmation
                load={selectedLoad}
                loadId={isObjectNotEmpty(selectedLoad) ? selectedLoad.id : null}
                shipperId={isObjectNotEmpty(selectedLoad) ? selectedLoad.shipperId : null}
                stops={stops}
                invoices={invoices}
                invoiceLineItems={invoiceLineItems}
                cancel={() => { toggleNewPriceConfirmationModal(); setSelectedInvoice(null); setSelectedLoad(null); }}
                loading={isLoadingStops === true || isLoadingInvoices === true || isLoadingInvoiceLineItems === true}
            />
        </Modal>
    );

    const newRateConfirmationModal = (
        <Modal
            title="Generate and Send New Rate Confirmation"
            visible={showNewRateConfirmationModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={() => { toggleNewRateConfirmationModal(); setSelectedInvoice(null); setSelectedLoad(null); }}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <LoadNewRateConfirmation
                load={selectedLoad}
                loadId={isObjectNotEmpty(selectedLoad) ? selectedLoad.id : null}
                shipperId={isObjectNotEmpty(selectedLoad) ? selectedLoad.shipperId : null}
                carrierId={isObjectNotEmpty(selectedLoad) ? selectedLoad.assignedCarrierId : null}
                serviceType={isObjectNotEmpty(selectedLoad) ? selectedLoad.serviceType : null}
                stops={stops}
                invoices={invoices}
                invoiceLineItems={invoiceLineItems}
                cancel={() => { toggleNewRateConfirmationModal(); setSelectedInvoice(null); setSelectedLoad(null); }}
                loading={isLoadingStops === true || isLoadingInvoices === true || isLoadingInvoiceLineItems === true}
            />
        </Modal>
    );

    const newShipperInvoiceModal = (
        <Modal
            title="Generate and Send New Shipper Invoice"
            visible={showNewShipperInvoiceModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={() => { toggleNewShipperInvoiceModal(); setSelectedInvoice(null); setSelectedLoad(null); }}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <LoadNewShipperInvoice
                load={selectedLoad}
                loadId={isObjectNotEmpty(selectedLoad) ? selectedLoad.id : null}
                shipperId={isObjectNotEmpty(selectedLoad) ? selectedLoad.shipperId : null}
                stops={stops}
                invoices={invoices}
                invoiceLineItems={invoiceLineItems}
                cancel={() => { toggleNewShipperInvoiceModal(); setSelectedInvoice(null); setSelectedLoad(null); }}
                loading={isLoadingStops === true || isLoadingInvoices === true || isLoadingInvoiceLineItems === true}
            />
        </Modal>
    );

    const newCarrierInvoiceModal = (
        <Modal
            title="Submit Carrier Invoice"
            visible={showNewCarrierInvoiceModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={() => { toggleNewCarrierInvoiceModal(); setSelectedInvoice(null); setSelectedLoad(null); }}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <LoadNewCarrierInvoice
                load={selectedLoad}
                loadId={isObjectNotEmpty(selectedLoad) ? selectedLoad.id : null}
                shipperId={isObjectNotEmpty(selectedLoad) ? selectedLoad.shipperId : null}
                carrierId={isObjectNotEmpty(selectedLoad) ? selectedLoad.assignedCarrierId : null}
                serviceType={isObjectNotEmpty(selectedLoad) ? selectedLoad.serviceType : null}
                stops={stops}
                invoices={invoices}
                invoiceLineItems={invoiceLineItems}
                transactions={transactions}
                claims={claims}
                cancel={() => { toggleNewCarrierInvoiceModal(); setSelectedInvoice(null); setSelectedLoad(null); }}
                loading={isLoadingStops === true || isLoadingInvoices === true || isLoadingInvoiceLineItems === true || isLoadingTransactions === true || isLoadingClaims === true}
            />
        </Modal>
    );

    const resendPriceConfirmationModal = (
        <Modal
            title="Re-Send Load Tender"
            visible={showResendPriceConfirmationModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={() => { toggleResendPriceConfirmationModal(); setSelectedInvoice(null); setSelectedLoad(null); }}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <LoadSendPriceConfirmation
                load={selectedLoad}
                loadId={isObjectNotEmpty(selectedLoad) ? selectedLoad.id : null}
                shipperId={isObjectNotEmpty(selectedLoad) ? selectedLoad.shipperId : null}
                invoice={selectedInvoice}
                stops={stops}
                cancel={() => { toggleResendPriceConfirmationModal(); setSelectedInvoice(null); setSelectedLoad(null); }}
                loading={isLoadingStops === true}
            />
        </Modal>
    );

    const resendRateConfirmationModal = (
        <Modal
            title="Re-Send Rate Confirmation"
            visible={showResendRateConfirmationModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={() => { toggleResendRateConfirmationModal(); setSelectedInvoice(null); setSelectedLoad(null); }}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <LoadSendRateConfirmation
                load={selectedLoad}
                loadId={isObjectNotEmpty(selectedLoad) ? selectedLoad.id : null}
                carrierId={isObjectNotEmpty(selectedLoad) ? selectedLoad.assignedCarrierId : null}
                invoice={selectedInvoice}
                stops={stops}
                cancel={() => { toggleResendRateConfirmationModal(); setSelectedInvoice(null); setSelectedLoad(null); }}
                loading={isLoadingStops === true}
            />
        </Modal>
    );

    const resendShipperInvoiceModal = (
        <Modal
            title="Re-Send Shipper Invoice"
            visible={showResendShipperInvoiceModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={() => { toggleResendShipperInvoiceModal(); setSelectedInvoice(null); setSelectedLoad(null); }}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <LoadSendShipperInvoice
                load={selectedLoad}
                loadId={isObjectNotEmpty(selectedLoad) ? selectedLoad.id : null}
                shipperId={isObjectNotEmpty(selectedLoad) ? selectedLoad.shipperId : null}
                invoice={selectedInvoice}
                stops={stops}
                cancel={() => { toggleResendShipperInvoiceModal(); setSelectedInvoice(null); setSelectedLoad(null); }}
                loading={isLoadingStops === true}
            />
        </Modal>
    );

    const updatePriceConfirmationModal = (
        <Modal
            title="Update and Re-Send Price Confirmation"
            visible={showUpdatePriceConfirmationModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={() => { toggleUpdatePriceConfirmationModal(); setSelectedInvoice(null); setSelectedLoad(null); }}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <LoadEditPriceConfirmation
                load={selectedLoad}
                loadId={isObjectNotEmpty(selectedLoad) ? selectedLoad.id : null}
                shipperId={isObjectNotEmpty(selectedLoad) ? selectedLoad.shipperId : null}
                invoice={selectedInvoice}
                stops={stops}
                invoiceLineItems={invoiceLineItems}
                cancel={() => { toggleUpdatePriceConfirmationModal(); setSelectedInvoice(null); setSelectedLoad(null); }}
                loading={isLoadingStops === true || isLoadingInvoiceLineItems === true}
            />
        </Modal>
    );

    const updateRateConfirmationModal = (
        <Modal
            title="Update and Re-Send Rate Confirmation"
            visible={showUpdateRateConfirmationModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={() => { toggleUpdateRateConfirmationModal(); setSelectedInvoice(null); setSelectedLoad(null); }}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <LoadEditRateConfirmation
                load={selectedLoad}
                loadId={isObjectNotEmpty(selectedLoad) ? selectedLoad.id : null}
                carrierId={isObjectNotEmpty(selectedLoad) ? selectedLoad.assignedCarrierId : null}
                serviceType={isObjectNotEmpty(selectedLoad) ? selectedLoad.serviceType : null}
                invoice={selectedInvoice}
                stops={stops}
                invoiceLineItems={invoiceLineItems}
                cancel={() => { toggleUpdateRateConfirmationModal(); setSelectedInvoice(null); setSelectedLoad(null); }}
                loading={isLoadingStops === true || isLoadingInvoiceLineItems === true}
            />
        </Modal>
    );

    const updateShipperInvoiceModal = (
        <Modal
            title="Update and Re-Send Shipper Invoice"
            visible={showUpdateShipperInvoiceModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={() => { toggleUpdateShipperInvoiceModal(); setSelectedInvoice(null); setSelectedLoad(null); }}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <LoadEditShipperInvoice
                load={selectedLoad}
                loadId={isObjectNotEmpty(selectedLoad) ? selectedLoad.id : null}
                shipperId={isObjectNotEmpty(selectedLoad) ? selectedLoad.shipperId : null}
                carrierId={isObjectNotEmpty(selectedLoad) ? selectedLoad.assignedCarrierId : null}
                serviceType={isObjectNotEmpty(selectedLoad) ? selectedLoad.serviceType : null}
                invoice={selectedInvoice}
                invoiceId={isObjectNotEmpty(selectedInvoice) ? selectedInvoice.id : null}
                stops={stops}
                invoiceLineItems={invoiceLineItems}
                cancel={() => { toggleUpdateShipperInvoiceModal(); setSelectedInvoice(null); setSelectedLoad(null); }}
                loading={isLoadingStops === true || isLoadingInvoiceLineItems === true}
            />
        </Modal>
    );

    const updateCarrierInvoiceModal = (
        <Modal
            title="Update and Re-Submit Carrier Invoice"
            visible={showUpdateCarrierInvoiceModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={() => { toggleUpdateCarrierInvoiceModal(); setSelectedInvoice(null); setSelectedLoad(null); }}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <LoadEditCarrierInvoice
                load={selectedLoad}
                loadId={isObjectNotEmpty(selectedLoad) ? selectedLoad.id : null}
                shipperId={isObjectNotEmpty(selectedLoad) ? selectedLoad.shipperId : null}
                carrierId={isObjectNotEmpty(selectedLoad) ? selectedLoad.assignedCarrierId : null}
                serviceType={isObjectNotEmpty(selectedLoad) ? selectedLoad.serviceType : null}
                invoice={selectedInvoice}
                invoiceId={isObjectNotEmpty(selectedInvoice) ? selectedInvoice.id : null}
                stops={stops}
                invoiceLineItems={invoiceLineItems}
                cancel={() => { toggleUpdateCarrierInvoiceModal(); setSelectedInvoice(null); setSelectedLoad(null); }}
                loading={isLoadingStops === true || isLoadingInvoiceLineItems === true}
            />
        </Modal>
    );

    const viewInvoiceModal = (
        <Modal
            title={isObjectNotEmpty(selectedInvoice) ? Enums.InvoiceTypes.getValueByName(selectedInvoice.invoiceType) : ''}
            visible={showViewInvoiceModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={() => { toggleViewInvoice(); setSelectedInvoice(null); setSelectedLoad(null); }}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoadingStops === true}>
                <Invoice
                    invoiceId={isObjectNotEmpty(selectedInvoice) && isStringNotEmpty(selectedInvoice.id) ? selectedInvoice.id : null}
                    invoice={isObjectNotEmpty(selectedInvoice) ? selectedInvoice : null}
                    load={selectedLoad}
                    loadId={isObjectNotEmpty(selectedLoad) ? selectedLoad.id : null}
                    stops={stops}
                    displayManualInstructions={false}
                />
            </Spin>
        </Modal>
    );

    const editInvoiceComponents = (
        <Drawer
            title={isObjectNotEmpty(selectedInvoice) ? `Edit ${Enums.InvoiceTypes.getValueByName(selectedInvoice.invoiceType)}` : ""}
            onClose={() => { toggleEditInvoice(); setSelectedInvoice(null); setSelectedLoad(null); }}
            visible={showEditInvoice === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditInvoice
                cancel={() => { toggleEditInvoice(); setSelectedInvoice(null); setSelectedLoad(null); }}
                invoice={selectedInvoice}
                load={selectedLoad}
                shipperId={isObjectNotEmpty(selectedLoad) ? selectedLoad.shipperId : null}
                carrierId={isObjectNotEmpty(selectedLoad) ? selectedLoad.assignedCarrierId : null}
                serviceType={isObjectNotEmpty(selectedLoad) ? selectedLoad.serviceType : null}
            />
        </Drawer>
    );

    const shipperInvoiceModal = (
        <Modal
            title="Shipper Invoice"
            visible={showShipperInvoiceModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={toggleShipperInvoiceModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            {isObjectNotEmpty(selectedLoad) ? (
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoadingStops === true || isLoadingInvoiceLineItems === true || isLoadingTransactions === true}>
                    <Header
                        title="Shipper Invoice"
                        titleStyle={{ paddingLeft: 0, color: '#000000', fontWeight: 500 }}
                        style={{ paddingLeft: 0, paddingRight: 0 }}
                        icon={<DollarOutlined />}
                        iconStyle={{ background: 'transparent' }}
                        title2={`Please review the Shipper Invoice below.`}
                        title2Style={{ whiteSpace: 'normal' }}
                    />

                    <DataTable
                        dataSource={invoiceLineItems.filter(i => i.loadId === selectedLoad.id && (i.fromEntityType === 'SHIPPER' || i.toEntityType === 'SHIPPER'))}
                        columns={invoiceLineItemColumns}
                        loading={isLoadingInvoiceLineItems}
                        hidePaging={true}
                        rowClassName={getInvoiceLineItemRowClassName}
                        title={() => (invoiceLineItemTitle('Shipper', selectedLoad.serviceType === 'TMS' ? 'CARRIER' : 'STAFF', 'SHIPPER'))}
                        style={{ backgroundColor: '#ffffff', marginBottom: 24 }}
                        rowKey={record => record.id}
                    />

                    <DataTable
                        dataSource={transactions.filter(t => t.loadId === selectedLoad.id && (t.fromEntityType === 'SHIPPER' || t.toEntityType === 'SHIPPER'))}
                        columns={transactionColumns}
                        loading={isLoadingTransactions}
                        hidePaging={true}
                        rowClassName={getTransactionRowClassName}
                        title={() => (transactionTitle(shipperInvoice, 'Shipper', 'SHIPPER_INVOICE'))}
                        style={{ backgroundColor: '#ffffff' }}
                        rowKey={record => record.id}
                    />

                    {isObjectNotEmpty(shipperInvoice) && isListNotEmpty(stops) ? (
                        <>
                            <Invoice invoiceId={shipperInvoice.id} invoice={shipperInvoice} load={selectedLoad} loadId={selectedLoad.id} stops={stops} />

                            <NoteList
                                noteEntityId={shipperInvoice.id}
                                noteEntityType="INVOICE"
                                tableTitle="Notes and Corrections"
                                style={{ marginTop: 12, marginBottom: 12 }}
                            />

                            <div style={{ marginTop: 12, marginBottom: 12 }}>
                                {shipperInvoice.status !== 'COMPLETED' && shipperInvoice.status !== 'CANCELLED' ? (
                                    <>
                                        <Button type="primary" onClick={() => { setSelectedInvoice(shipperInvoice); toggleResendShipperInvoiceModal(); }}>Re-Send Shipper Invoice</Button>
                                        <Button type="primary" style={{ marginLeft: 8 }} onClick={() => { setSelectedInvoice(shipperInvoice); toggleUpdateShipperInvoiceModal(); }}>Update and Re-Send Shipper Invoice</Button>
                                    </>
                                ) : null}
                                {invoiceLineItems.filter(i => i.loadId === selectedLoad.id && (i.invoiceId === null || i.invoiceId === undefined) && i.isDeleted === false && (i.fromEntityType === 'SHIPPER' || i.toEntityType === 'SHIPPER')).length > 0 ? (
                                    <Button type="primary" onClick={() => { toggleNewShipperInvoiceModal(); }}>Generate and Send Additional Shipper Invoice</Button>
                                ) : null}
                            </div>
                        </>
                    ) : (<Button type="primary" onClick={() => { toggleNewShipperInvoiceModal(); }}>Generate and Send Shipper Invoice</Button>)}

                    <div style={{ marginTop: 24, textAlign: 'right' }}>
                        <Button onClick={() => { toggleShipperInvoiceModal(); }}>Done</Button>
                    </div>
                </Spin>
            ) : null}
        </Modal>
    );

    const carrierInvoiceModal = (
        <Modal
            title={isObjectNotEmpty(selectedLoad) ? (selectedLoad.serviceType === "TMS" ? "Invoice" : "Carrier Invoice") : ""}
            visible={showCarrierInvoiceModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={toggleCarrierInvoiceModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            {isObjectNotEmpty(selectedLoad) ? (
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoadingStops === true || isLoadingInvoiceLineItems === true || isLoadingTransactions === true}>
                    <Header
                        title="Carrier Invoice"
                        titleStyle={{ paddingLeft: 0, color: '#000000', fontWeight: 500 }}
                        style={{ paddingLeft: 0, paddingRight: 0 }}
                        icon={<DollarOutlined />}
                        iconStyle={{ background: 'transparent' }}
                        title2={`Please review the Carrier Invoice Below.`}
                        title2Style={{ whiteSpace: 'normal' }}
                    />

                    <DataTable
                        dataSource={invoiceLineItems.filter(i => i.loadId === selectedLoad.id && (i.fromEntityType === 'CARRIER' || i.toEntityType === 'CARRIER'))}
                        columns={invoiceLineItemColumns}
                        loading={isLoadingInvoiceLineItems}
                        hidePaging={true}
                        rowClassName={getInvoiceLineItemRowClassName}
                        title={() => (invoiceLineItemTitle('Carrier', 'CARRIER', selectedLoad.serviceType === 'TMS' ? 'SHIPPER' : 'STAFF'))}
                        style={{ backgroundColor: '#ffffff', marginBottom: 24 }}
                        rowKey={record => record.id}
                    />

                    <DataTable
                        dataSource={transactions.filter(t => t.loadId === selectedLoad.id && (t.fromEntityType === 'CARRIER' || t.toEntityType === 'CARRIER'))}
                        columns={transactionColumns}
                        loading={isLoadingTransactions}
                        hidePaging={true}
                        rowClassName={getTransactionRowClassName}
                        title={() => (transactionTitle(carrierInvoice, 'Carrier', 'CARRIER_INVOICE'))}
                        style={{ backgroundColor: '#ffffff' }}
                        rowKey={record => record.id}
                    />

                    <div style={{ marginTop: 24, marginBottom: 24 }}>
                        {isObjectNotEmpty(carrierInvoice) && (carrierInvoice.paymentMethod === 'DIRECT_DEPOSIT' || carrierInvoice.paymentMethod === 'FACTORING') ? (
                            <Divider />
                        ) : null}
                        {bankAccountDocumentComponents()}
                        {factoringNOADocumentComponents()}
                        <Divider />
                        {carrierInvoiceDocumentComponents()}
                        <Divider />
                        {factoringInvoiceDocumentComponents()}
                        <Divider />
                    </div>

                    {isObjectNotEmpty(carrierInvoice) && isListNotEmpty(stops) ? (
                        <>
                            <Invoice invoiceId={carrierInvoice.id} invoice={carrierInvoice} load={selectedLoad} loadId={selectedLoad.id} stops={stops} />

                            <NoteList
                                noteEntityId={carrierInvoice.id}
                                noteEntityType="INVOICE"
                                tableTitle="Notes and Corrections"
                                style={{ marginTop: 12, marginBottom: 12 }}
                            />

                            <div style={{ marginTop: 12, marginBottom: 12 }}>
                                {(carrierInvoice.status !== 'COMPLETED' && carrierInvoice.status !== 'CANCELLED') ? (
                                    <Button type="primary" onClick={() => { setSelectedInvoice(carrierInvoice); toggleUpdateCarrierInvoiceModal(); }}>Update and Re-Submit Carrier Invoice</Button>
                                ) : null}
                                {(carrierInvoice.status === 'COMPLETED' || carrierInvoice.status === 'CANCELLED') && invoiceLineItems.filter(i => i.loadId === selectedLoad.id && (i.invoiceId === null || i.invoiceId === undefined) && i.isDeleted === false && (i.fromEntityType === 'CARRIER' || i.toEntityType === 'CARRIER')).length > 0 ? (
                                    <Button type="primary" onClick={() => { toggleNewCarrierInvoiceModal(); }}>Generate and Submit Additional Carrier Invoice</Button>
                                ) : null}
                            </div>
                        </>
                    ) : (<Button type="primary" onClick={() => { toggleNewCarrierInvoiceModal(); }}>Submit New Carrier Invoice</Button>)}

                    <div style={{ marginTop: 24, textAlign: 'right' }}>
                        <Button onClick={() => { toggleCarrierInvoiceModal(); }}>Done</Button>
                    </div>
                </Spin>
            ) : null}
        </Modal>
    );

    const factoringInvoiceModal = (
        <Modal
            title="Axle Payments"
            visible={showFactoringInvoiceModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={toggleFactoringInvoiceModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            {isObjectNotEmpty(selectedLoad) ? (
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoadingStops === true || isLoadingInvoiceLineItems === true || isLoadingTransactions === true}>
                    <Header
                        title="Axle Payments"
                        titleStyle={{ paddingLeft: 0, color: '#000000', fontWeight: 500 }}
                        style={{ paddingLeft: 0, paddingRight: 0 }}
                        icon={<DollarOutlined />}
                        iconStyle={{ background: 'transparent' }}
                        title2={`Please review the Axle Payments Financials below.`}
                        title2Style={{ whiteSpace: 'normal' }}
                    />

                    <DataTable
                        dataSource={invoiceLineItems.filter(i => i.loadId === selectedLoad.id && (i.fromEntityType === 'FACTORING' || i.toEntityType === 'FACTORING'))}
                        columns={invoiceLineItemColumns}
                        loading={isLoadingInvoiceLineItems}
                        hidePaging={true}
                        rowClassName={getInvoiceLineItemRowClassName}
                        title={() => (invoiceLineItemTitle('Axle Payments', 'STAFF', 'FACTORING'))}
                        style={{ backgroundColor: '#ffffff', marginBottom: 24 }}
                        rowKey={record => record.id}
                    />

                    <DataTable
                        dataSource={transactions.filter(t => t.loadId === selectedLoad.id && (t.fromEntityType === 'FACTORING' || t.toEntityType === 'FACTORING'))}
                        columns={transactionColumns}
                        loading={isLoadingTransactions}
                        hidePaging={true}
                        rowClassName={getTransactionRowClassName}
                        title={() => (transactionTitle(null, 'Axle Payments', 'FACTORING_INVOICE'))}
                        style={{ backgroundColor: '#ffffff' }}
                        rowKey={record => record.id}
                    />

                    <div style={{ marginTop: 24, textAlign: 'right' }}>
                        <Button onClick={() => { toggleFactoringInvoiceModal(); }}>Done</Button>
                    </div>
                </Spin>
            ) : null}
        </Modal>
    );

    //#endregion

    if (showTable === true) {
        return (
            <>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true || loadEventUpdateStatus === 'PENDING'}>
                    <DataTable
                        dataSource={records}
                        columns={loadColumns}
                        pagination={pagination}
                        loading={isLoading === true}
                        onChange={handleTableChange}
                        singularEntityName={title}
                        pluralEntityName={title}
                        newEntityAction={showNewLoadButton ? toggleNewLoad : null}
                        rowKey={record => record.id}
                        style={{ backgroundColor: '#ffffff', ...style }}
                        scroll={{ x: '100%' }}
                    >
                        <NewLoadModal key='new-load' showModal={showNewLoad} toggleModal={toggleNewLoad} shipperId={isStringNotEmpty(shipperId) ? shipperId : null} />
                        <NewLoadModal key='duplicate-load' showModal={showDuplicateLoad} toggleModal={() => { toggleDuplicateLoad(); setSelectedLoad({}); }} shipperId={isObjectNotEmpty(selectedLoad) && isStringNotEmpty(selectedLoad.shipperId) ? selectedLoad.shipperId : null} duplicateLoadId={isObjectNotEmpty(selectedLoad) && isStringNotEmpty(selectedLoad.id) ? selectedLoad.id : null} />
                    </DataTable>
                </Spin>
                {editMissingDocumentDrawer}
                {reviewDocumentDrawer}
                {viewDocumentDrawer}
                {missingDocumentsModal}
                {missingPODModal}
                {reviewDocumentsModal}
                {reviewPODModal}
                {viewDocumentsModal}
                {viewPODModal}
                {invoiceNotesModal}
                {newPriceConfirmationModal}
                {newRateConfirmationModal}
                {newShipperInvoiceModal}
                {newCarrierInvoiceModal}
                {resendPriceConfirmationModal}
                {resendRateConfirmationModal}
                {resendShipperInvoiceModal}
                {updatePriceConfirmationModal}
                {updateRateConfirmationModal}
                {updateShipperInvoiceModal}
                {updateCarrierInvoiceModal}
                {viewInvoiceModal}
                {editInvoiceComponents}
                {invoiceLineItemModal}
                {transactionModal}
                {shipperInvoiceModal}
                {carrierInvoiceModal}
                {factoringInvoiceModal}
                {claimsModal}
                {newInvoiceLineItemDrawer}
                {editInvoiceLineItemDrawer}
                {editStatusInvoiceLineItemDrawer}
                {editApprovalStatusInvoiceLineItemDrawer}
                {cancelInvoiceLineItemDrawer}
                {removeInvoiceLineItemDrawer}
                {newTransactionDrawer}
                {editTransactionDrawer}
                {editPaymentStatusTransactionDrawer}
                {removeTransactionDrawer}
            </>
        );
    } else {
        return (
            <>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true || loadEventUpdateStatus === 'PENDING'}>
                    <Card bordered={true} className={classes.card} style={style} headStyle={headStyle} title={title} extra={buttons} size="small">
                        {isObjectNotEmpty(pagination) ? (
                            <Typography>
                                <div><Typography.Title level={3}>{pagination.total}</Typography.Title></div>
                            </Typography>
                        ) : (
                            <Typography>
                                <div><Typography.Title level={3}>0</Typography.Title></div>
                            </Typography>
                        )}
                    </Card>
                    <Modal
                        title={title}
                        visible={showTableModal === true}
                        width="100%"
                        style={{ top: 0 }}
                        onCancel={toggleTableModal}
                        footer={null}
                        zIndex={1000}
                        destroyOnClose={true}
                        maskClosable={false}
                    >
                        <DataTable
                            dataSource={records}
                            columns={loadColumns}
                            pagination={pagination}
                            loading={isLoading === true}
                            onChange={handleTableChange}
                            singularEntityName={title}
                            pluralEntityName={title}
                            newEntityAction={showNewLoadButton ? toggleNewLoad : null}
                            rowKey={record => record.id}
                            style={{ backgroundColor: '#ffffff' }}
                            scroll={{ x: '100%' }}
                        >
                            <NewLoadModal key='new-load' showModal={showNewLoad} toggleModal={toggleNewLoad} shipperId={isStringNotEmpty(shipperId) ? shipperId : null} />
                            <NewLoadModal key='duplicate-load' showModal={showDuplicateLoad} toggleModal={() => { toggleDuplicateLoad(); setSelectedLoad({}); }} shipperId={isObjectNotEmpty(selectedLoad) && isStringNotEmpty(selectedLoad.shipperId) ? selectedLoad.shipperId : null} duplicateLoadId={isObjectNotEmpty(selectedLoad) && isStringNotEmpty(selectedLoad.id) ? selectedLoad.id : null} />
                        </DataTable>
                    </Modal>
                </Spin>
                {editMissingDocumentDrawer}
                {reviewDocumentDrawer}
                {viewDocumentDrawer}
                {missingDocumentsModal}
                {missingPODModal}
                {reviewDocumentsModal}
                {reviewPODModal}
                {viewDocumentsModal}
                {viewPODModal}
                {invoiceNotesModal}
                {newPriceConfirmationModal}
                {newRateConfirmationModal}
                {newShipperInvoiceModal}
                {newCarrierInvoiceModal}
                {resendPriceConfirmationModal}
                {resendRateConfirmationModal}
                {resendShipperInvoiceModal}
                {updatePriceConfirmationModal}
                {updateRateConfirmationModal}
                {updateShipperInvoiceModal}
                {updateCarrierInvoiceModal}
                {viewInvoiceModal}
                {editInvoiceComponents}
                {invoiceLineItemModal}
                {transactionModal}
                {shipperInvoiceModal}
                {carrierInvoiceModal}
                {factoringInvoiceModal}
                {claimsModal}
                {newInvoiceLineItemDrawer}
                {editInvoiceLineItemDrawer}
                {editStatusInvoiceLineItemDrawer}
                {editApprovalStatusInvoiceLineItemDrawer}
                {cancelInvoiceLineItemDrawer}
                {removeInvoiceLineItemDrawer}
                {newTransactionDrawer}
                {editTransactionDrawer}
                {editPaymentStatusTransactionDrawer}
                {removeTransactionDrawer}
            </>
        );
    }
};

export default withRouter(QueueLoads);