import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import ErrorBoundary from './ErrorBoundary';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import axios from 'axios';
import uuid from 'react-uuid';
import * as Sentry from '@sentry/browser';
import store from './store/store';
import invoiceUtils from './api/utils/invoiceUtils';

// Global Axios Configuration
axios.defaults.baseURL = process.env.REACT_APP_IRIS_API_ENDPOINT;

// has to be done at this level for font import to properly work
invoiceUtils.registerFonts();

// Interceptor for Request
axios.interceptors.request.use(request => {
    //console.log("HTTP Request:");
    //console.log(request);
    // Add headers and configurations to request here
    request.headers['X-Correlation-Id'] = uuid();
    request.headers['Accept'] = "application/json";
    request.headers['Content-Type'] = "application/json";
    return request;
}, error => {
    //console.log("HTTP Request Error:");
    //console.log(error);
    return Promise.reject(error);
});

// Interceptor for Response
axios.interceptors.response.use(response => {
    //console.log("HTTP Response:");
    //console.log(response);
    // Add headers and configurations to request here
    return response;
}, error => {
    //console.log("HTTP Response Error:");
    //console.log(error);
    return Promise.reject(error);
});

// Example of custom middleware
// const logger = store => {
//     return next => {
//         return action => {
//             // console.log('[Middleware] Dispatching', action);
//             const result = next(action);
//             // console.log('[Middleware] next state', store.getState());
//             return result;
//         }
//     }
// };

Sentry.init({
    dsn: "https://d629039fabe74257901477208e7cd151@o392346.ingest.sentry.io/5242469",
    environment: process.env.REACT_APP_ENVIRONMENT
});

ReactDOM.render(<ErrorBoundary><Provider store={store}><App /></Provider></ErrorBoundary>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
