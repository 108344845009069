import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { PaperClipOutlined } from '@ant-design/icons';
import { Button, Modal, Tooltip, Card, Typography, Spin } from 'antd';
import Account from "../../components/Account/Account";
import classes from './QueueAccountsPendingVerification.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools, faExternalLinkAlt, faRedoAlt } from '@fortawesome/free-solid-svg-icons';
import DocumentList from "../DocumentList/DocumentList";
import Queue from '../Queue/Queue';
import { isObjectNotEmpty } from '../../shared/objectUtils';

const { Title } = Typography;

const QueueAccountsPendingVerification = ({ style = null, headStyle = null }) => {
    const title = 'Accounts Pending Verification and Document Approval';

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.metrics.accountsPendingVerificationIsLoading);
    const data = useSelector(state => state.metrics.accountsPendingVerification);
    const pagination = useSelector(state => state.metrics.accountsPendingVerificationPagination);

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showDocumentsModal, setShowDocumentsModal] = useState(false);
    const [missingDocumentsCount, setMissingDocumentsCount] = useState(0);
    const [pendingApprovalCount, setPendingApprovalCount] = useState(0);
    const [showTableModal, setShowTableModal] = useState(false);

    const toggleTableModal = () => {
        setShowTableModal(!showTableModal);
    };

    const toggleDocumentsModal = () => {
        setShowDocumentsModal(!showDocumentsModal);
    };

    const verifyAccount = (accountId) => {
        dispatch(actionCreators.verifyAccount(accountId));
    };

    const refreshQueue = () => {
        let searchParams = {
            page: 1,
            size: 5,
            isDeleted: false,
            'isVerified:or': false,
            'documentPendingApprovalCount:gt:or': 0,
            'documentMissingCount:gt:or': 0
        };

        dispatch(actionCreators.fetchAccountsPendingVerification(searchParams));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchAccountsPendingVerificationSuccess({ accountsPendingVerificationPagination: pager }));

        let searchParams = {
            page: pagination.current,
            size: pagination.pageSize,
            isDeleted: false,
            'isVerified:or': false,
            'documentPendingApprovalCount:gt:or': 0,
            'documentMissingCount:gt:or': 0
        };

        dispatch(actionCreators.fetchAccountsPendingVerification(searchParams));
    };

    const columns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            width: 50,
            render: (text, record) => {
                return (
                    <Tooltip placement="top" title="Open Documents">
                        <Button key="5" type="primary" icon={<PaperClipOutlined />} shape='circle' onClick={(e) => { e.stopPropagation(); setSelectedRecord(record); toggleDocumentsModal(); }} style={{ marginRight: '8px' }} />
                    </Tooltip>
                );
            },
            align: 'center',
            className: classes.columnNoBreak
        },
        {
            title: 'Account Id',
            dataIndex: 'irisId',
            key: 'irisId',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        }
    ];

    const documentsComponent = (
        <Modal
            title="Account Documents"
            visible={showDocumentsModal === true}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleDocumentsModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            {isObjectNotEmpty(selectedRecord) ? (
                <DocumentList
                    documentEntityType="ACCOUNT"
                    account={selectedRecord}
                    documentEntityId={selectedRecord.id}
                    updatePendingApprovalCount={setPendingApprovalCount}
                    updateMissingDocumentsCount={setMissingDocumentsCount}
                />
            ) : null}
            {isObjectNotEmpty(selectedRecord) && selectedRecord.isVerified === false ? (
                <Button style={{ marginTop: 20 }} type='primary' onClick={() => { verifyAccount(selectedRecord.id); }} disabled={pendingApprovalCount > 0 || missingDocumentsCount > 0}>Verify Account</Button>
            ) : null}
        </Modal>
    );

    const buttons = (
        <>
            <Tooltip placement="top" title="Refresh Queue">
                <Button type="primary" shape='circle' onClick={refreshQueue} style={{ marginRight: 8 }}>
                    <FontAwesomeIcon icon={faRedoAlt} />
                </Button>
            </Tooltip>
            <Tooltip placement="top" title="Open Queue">
                <Button type="primary" shape='circle' onClick={toggleTableModal}>
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                </Button>
            </Tooltip>
        </>
    );

    useEffect(() => {
        refreshQueue();
    }, []);

    return (
        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true}>
            <Card bordered={true} className={classes.card} style={style} headStyle={headStyle} title={title} extra={buttons} size="small">
                {isObjectNotEmpty(pagination) ? (
                    <Typography>
                        <div><Title level={3}>{pagination.total}</Title></div>
                    </Typography>
                ) : (
                        <Typography>
                            <div><Title level={3}>0</Title></div>
                        </Typography>
                    )}
            </Card>
            <Modal
                title={title}
                visible={showTableModal === true}
                width="100%"
                style={{ top: 0 }}
                onCancel={toggleTableModal}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                maskClosable={false}
            >
                <Queue
                    dataSource={data}
                    columns={columns}
                    loading={isLoading === true}
                    pagination={pagination}
                    rowClassName={classes.dataTableRow}
                    onChange={handleTableChange}
                    expandedRowComponents={record => (<Account account={record} />)}
                />
            </Modal>
            {documentsComponent}
        </Spin>
    );
};

export default QueueAccountsPendingVerification;