import React, { useState } from 'react';
import { Checkbox, Col, Modal, Row } from 'antd';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruck, faTruckLoading, faWarehouse, faTractor, faHandshake, faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons';

const NewAccountModal = ({ showModal = false, toggleModal, accountRegistrationId = null, ...props }) => {
    //#region useStates

    const [accountTypes, setAccountTypes] = useState([]);

    //#endregion
    //#region goTos

    const goToNewAccount = () => {
        props.history.push({
            pathname: "/newAccount",
            state: {
                accountTypes: accountTypes,
                accountRegistrationId: accountRegistrationId ? accountRegistrationId : null
            }
        });

        toggleModal();
    };

    //#endregion
    //#region onChanges

    const onChangeAccountTypes = (checkedAccountTypes) => {
        setAccountTypes(checkedAccountTypes)
    };

    //#endregion

    return (
        <Modal
            title={"Choose Account Type"}
            visible={showModal === true}
            onOk={goToNewAccount}
            onCancel={toggleModal}>
            <Checkbox.Group style={{ width: '100%' }} onChange={onChangeAccountTypes}>
                <Row gutter={[4, 4]}>
                    <Col span={24}>
                        <span><strong>Select all that apply.</strong></span>
                    </Col>
                    <Col span={8}>
                        <FontAwesomeIcon size={"2x"} icon={faTruckLoading} /><br />
                        <Checkbox value="CARRIER"><b>Carrier</b></Checkbox>
                    </Col>
                    <Col span={8}>
                        <FontAwesomeIcon size={"2x"} icon={faTruck} /><br />
                        <Checkbox value="SHIPPER"><b>Shipper</b></Checkbox>
                    </Col>
                    <Col span={8}>
                        <FontAwesomeIcon size={"2x"} icon={faWarehouse} /><br />
                        <Checkbox value="RECEIVER"><b>Receiver</b></Checkbox>
                    </Col>
                    <Col span={8}>
                        <FontAwesomeIcon size={"2x"} icon={faTractor} /><br />
                        <Checkbox value="PRODUCER"><b>Producer</b></Checkbox>
                    </Col>
                    <Col span={8}>
                        <FontAwesomeIcon size={"2x"} icon={faHandshake} /><br />
                        <Checkbox value="BROKER"><b>Brokerage</b></Checkbox>
                    </Col>
                    <Col span={8}>
                        <FontAwesomeIcon size={"2x"} icon={faHandHoldingUsd} /><br />
                        <Checkbox value="FACTORING"><b>Factoring Company</b></Checkbox>
                    </Col>
                </Row>
            </Checkbox.Group>
        </Modal>
    );
};

export default withRouter(NewAccountModal);
