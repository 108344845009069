import React, { useMemo, useState } from 'react';
import { Alert, Spin, Select, Empty, Button } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import { without } from 'lodash';
import moment from 'moment';
import { isBooleanFalse, isBooleanTrue, isStringNotEmpty } from '../../shared/objectUtils';

const { Option } = Select;

const EditScheduledShift = ({ scheduledShift, selectedDate, selectedAccountUserId, selectedShiftId, accountUsers, shifts, isRemove = false, cancel }) => {
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.scheduledShifts.isRecordUpdateLoading);
    const error = useSelector(state => state.scheduledShifts.updateRecordError);

    //#region useStates

    const [selectedShift, setSelectedShift] = useState(null);
    const [selectedAccountUser, setSelectedAccountUser] = useState(null);
    const [showEditShiftId, setShowEditShiftId] = useState(isBooleanFalse(isRemove) && isStringNotEmpty(selectedAccountUserId));

    //#endregion
    //#region onSubmit and cancel

    const onSubmit = (data) => {
        let updatedScheduledShift = { ...scheduledShift };

        if (selectedAccountUserId !== undefined && selectedAccountUserId !== null) {
            let existingAccountUserIds = updatedScheduledShift.accountUserIds && updatedScheduledShift.accountUserIds.length > 0 ? [...updatedScheduledShift.accountUserIds] : [];
            if (existingAccountUserIds.length > 0) {
                const updatedAccountUserIds = without(existingAccountUserIds, selectedAccountUserId);
                data.accountUserIds = updatedAccountUserIds;
            } else {
                data.accountUserIds = [];
            }

            // check to see if the user changed the shift
            if (showEditShiftId === true && data.newShiftId !== updatedScheduledShift.shiftId) {
                // if so, the accountUser needs to be removed from the scheduledShift and a new scheduled shift needs to be created

                let payload = {
                    accountId: updatedScheduledShift.accountId,
                    shiftId: data.newShiftId
                };

                if (selectedAccountUserId !== undefined && selectedAccountUserId !== null) {
                    let accountUserIds = [];
                    accountUserIds.push(selectedAccountUserId);
                    payload.accountUserIds = accountUserIds;
                }

                if (selectedDate !== undefined && selectedDate !== null) {
                    if (data.newShiftId !== undefined && data.newShiftId !== null && shifts !== undefined && shifts !== null && shifts.length > 0) {
                        let newShift = shifts.find(i => i.id === data.newShiftId);
                        if (newShift !== undefined && newShift !== null) {
                            // console.log(selectedShift);

                            payload.isAvailable = newShift.isAvailable;

                            var formattedSelectedDate = moment(selectedDate).format("YYYY-MM-DD");

                            let startDate = `${formattedSelectedDate}T${newShift.startTime}`;
                            payload.startDateTime = moment.tz(startDate, newShift.timeZone);

                            if (newShift.isNextDay === false) {
                                let endDate = `${formattedSelectedDate}T${newShift.endTime}`;
                                payload.endDateTime = moment.tz(endDate, newShift.timeZone);
                            } else {
                                let nextDaySelectedDate = moment(selectedDate).add(1, 'days').format('YYYY-MM-DD');
                                let endDate = `${nextDaySelectedDate}T${newShift.endTime}`;
                                payload.endDateTime = moment.tz(endDate, newShift.timeZone);
                            }

                            dispatch(actionCreators.addScheduledShift(payload));
                        }
                    }
                }
            }
        }

        let updatePayload = { ...data, shiftId: updatedScheduledShift.shiftId };
        if (updatePayload.newShiftId) {
            delete updatePayload.newShiftId;
        }

        dispatch(actionCreators.updateScheduledShift(updatedScheduledShift.id, updatePayload));
    };

    const onCancel = () => {
        dispatch(actionCreators.updateScheduledShiftCancel());
        cancel();
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        if (selectedAccountUserId !== undefined && selectedAccountUserId !== null && accountUsers !== undefined && accountUsers !== null && accountUsers.length > 0) {
            let selectedAccountUserObj = accountUsers.find(i => i.id === selectedAccountUserId);
            if (selectedAccountUserObj !== undefined && selectedAccountUserObj !== null) {
                setSelectedAccountUser({ ...selectedAccountUserObj });
            } else {
                setSelectedAccountUser(null);
            }
        }
    }, [selectedAccountUserId, accountUsers]);

    useMemo(() => {
        if (scheduledShift !== undefined && scheduledShift !== null && scheduledShift.shiftId !== undefined && scheduledShift.shiftId !== null && shifts !== undefined && shifts !== null && shifts.length > 0) {
            let selectedShiftObj = shifts.find(i => i.id === scheduledShift.shiftId);
            if (selectedShiftObj !== undefined && selectedShiftObj !== null) {
                setSelectedShift({ ...selectedShiftObj });
            } else {
                setSelectedShift(null);
            }
        }
    }, [scheduledShift, shifts]);

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateScheduledShiftErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    //#endregion

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Fieldset legend="Selected Date">
                        <span><strong>{selectedDate}</strong></span>
                    </Fieldset>
                    {showEditShiftId === false ? (
                        <Fieldset legend={selectedAccountUserId !== undefined && selectedAccountUserId !== null ? "Shift for Employee to be Removed From" : "Selected Shift"}>
                            <span><strong>{selectedShift && selectedShift.name ? selectedShift.name : ''}: {selectedShift && selectedShift.startTime ? selectedShift.startTime : ''} - {selectedShift && selectedShift.endTime ? selectedShift.endTime : ''}</strong></span> {selectedAccountUserId !== undefined && selectedAccountUserId !== null && !(selectedShiftId !== undefined && selectedShiftId !== null) && isBooleanFalse(isRemove) ? (<Button type="link" onClick={(e) => { setShowEditShiftId(true); }}>(change shift)</Button>) : null}
                        </Fieldset>
                    ) : (
                            <>
                                <Fieldset legend="Shift for Employee to be Removed From">
                                    <span><strong>{selectedShift && selectedShift.name ? selectedShift.name : ''}: {selectedShift && selectedShift.startTime ? selectedShift.startTime : ''} - {selectedShift && selectedShift.endTime ? selectedShift.endTime : ''}</strong></span> {selectedAccountUserId !== undefined && selectedAccountUserId !== null && !(selectedShiftId !== undefined && selectedShiftId !== null) && isBooleanTrue(isRemove) ? (<Button type="link" onClick={(e) => { setShowEditShiftId(false); }}>(cancel shift change)</Button>) : null}
                                </Fieldset>
                                <Fieldset legend="Shift for Employee to be Added To">
                                    <FormItem {...formItemLayout} label="Shift" required format="vertical"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Select
                                                placeholder="Please Select a Shift"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                                notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Shifts for you to choose from." />}
                                                ref={ref}
                                            >
                                                {shifts.filter(i => i.id !== scheduledShift.shiftId).map(a => <Option value={a.id} key={a.id}>{a.name}: {a.startTime} - {a.endTime} {a.timeZone}</Option>)}
                                            </Select>
                                        )}
                                        rules={{ required: 'Required Field' }}
                                        name="newShiftId"
                                    //defaultValue={scheduledShift ? scheduledShift.shiftId : null}
                                    />
                                </Fieldset>
                            </>
                        )}
                    {selectedAccountUserId === undefined || selectedAccountUserId === null ? (
                        <Fieldset legend="Selected Employee(s)">
                            <FormItem {...formItemLayout} label="Please Select the Employee(s) for this Shift" required format="vertical"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        mode="multiple"
                                        placeholder="Please Select the Employee(s) to for this Shift"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Employees for you to choose from." />}
                                        ref={ref}
                                    >
                                        {accountUsers.map(a => <Option value={a.id} key={a.id}>{a.firstName} {a.lastName}{a.title ? ' (' + a.title + ')' : ''}</Option>)}
                                    </Select>
                                )}
                                rules={{ required: 'Required Field' }}
                                name="accountUserIds"
                                defaultValue={scheduledShift ? scheduledShift.accountUserIds : null}
                            />
                        </Fieldset>
                    ) : (
                            <Fieldset legend="Selected Employee">
                                <span><strong>{selectedAccountUser && selectedAccountUser.firstName ? selectedAccountUser.firstName : ''} {selectedAccountUser && selectedAccountUser.lastName ? selectedAccountUser.lastName : ''}{selectedAccountUser && selectedAccountUser.title ? " (" + selectedAccountUser.title + ")" : ''}</strong></span>
                            </Fieldset>
                        )}
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText={selectedAccountUserId ? (showEditShiftId === true ? "Save Changes" : "Remove Employee from Scheduled Shift") : "Update Scheduled Shift"} />
            </Form>
        </FormProvider>
    );
};

export default EditScheduledShift;