import { isListNotEmpty, isNotNullOrUndefined, isObjectNotEmpty, isStringNotEmpty } from "../shared/objectUtils";

export const updateObject = (oldObject, updatedValues) => {
    return {
        ...oldObject,
        ...updatedValues
    };
};

export const addObjectToTop = (oldObjects, newObject) => {
    return [newObject, ...oldObjects];
};

export const addObjectToBottom = (oldObjects, newObject) => {
    return [...oldObjects, newObject];
};

export const fetchRecordsStart = (state) => {
    return updateObject(state, {
        isLoading: true,
        error: null
    });
};

export const fetchRecordsSuccess = (state, action) => {
    return updateObject(state, {
        ...action,
        isLoading: false,
        error: null
    });
};

export const clearRecords = (state) => {
    return updateObject(state, {
        records: []
    });
};

export const fetchRecordsFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        isLoading: false
    });
};

export const fetchRecordStart = (state) => {
    return updateObject(state, {
        isRecordLoading: true,
        recordError: null
    });
};

export const fetchRecordSuccess = (state, action) => {
    return updateObject(state, {
        ...action,
        recordError: null,
        isRecordLoading: false
    });
};

export const clearRecord = (state) => {
    return updateObject(state, {
        record: null
    });
};

export const fetchRecordFail = (state, action) => {
    return updateObject(state, {
        recordError: action.error,
        isRecordLoading: false
    });
};

export const addRecordStart = (state) => {
    return updateObject(state, {
        isRecordAddLoading: true,
        addRecordError: null
    });
};

export const addRecordToTop = (state, newRecord) => {
    // check to see if record already exists in the state, update if so, insert if not
    const copyOfState = { ...state };
    let existingList = [...copyOfState.records];
    const index = existingList.findIndex(obj => obj.id === newRecord.id);
    let updatedRecords = [];
    // only update if the record exists in the list
    if (index !== -1) {
        updatedRecords = [
            ...existingList.slice(0, index), // everything before current obj
            newRecord,
            ...existingList.slice(index + 1), // everything after current obj
        ];
    } else {
        updatedRecords = addObjectToTop(existingList, newRecord);
    }

    return updateObject(state, {
        records: updatedRecords
    });
};

export const addRecordToBottom = (state, newRecord) => {
    // check to see if record already exists in the state, update if so, insert if not
    const copyOfState = { ...state };
    let existingList = [...copyOfState.records];
    const index = existingList.findIndex(obj => obj.id === newRecord.id);
    let updatedRecords = [];
    // only update if the record exists in the list
    if (index !== -1) {
        updatedRecords = [
            ...existingList.slice(0, index), // everything before current obj
            newRecord,
            ...existingList.slice(index + 1), // everything after current obj
        ];
    } else {
        updatedRecords = addObjectToBottom(existingList, newRecord);
    }

    return updateObject(state, {
        records: updatedRecords
    });
};

export const addRecordSuccess = (state) => {
    return updateObject(state, {
        isRecordAddLoading: false,
        addRecordError: null
    });
};

export const addRecordFail = (state, action) => {
    return updateObject(state, {
        addRecordError: action.error,
        isRecordAddLoading: false
    });
};

export const clearAddRecordLoading = (state) => {
    return updateObject(state, {
        isRecordAddLoading: null
    });
};

export const clearAddRecordError = (state) => {
    return updateObject(state, {
        addRecordError: null
    });
};

export const cancelAddRecord = (state) => {
    return updateObject(state, {
        addRecordError: null,
        isRecordAddLoading: null,
        addRecordId: null
    });
};

export const updateRecord = (state, updatedRecord) => {
    const copyOfState = { ...state };
    let existingList = [...copyOfState.records];
    const index = existingList.findIndex(obj => obj.id === updatedRecord.id);

    // only update if the record exists in the list
    if (index !== -1) {
        const updatedRecords = [
            ...existingList.slice(0, index), // everything before current obj
            updatedRecord,
            ...existingList.slice(index + 1), // everything after current obj
        ];

        return updateObject(state, {
            records: updatedRecords
        });
    } else {
        return state;
    }
};

export const updateSingleRecord = (state, updatedRecord) => {
    // only update if the record matches what is in the state
    const copyOfState = { ...state };
    const existingObject = copyOfState.record !== undefined && copyOfState.record !== null && copyOfState.record !== {} ? { ...copyOfState.record } : null;
    if (existingObject !== null && existingObject.id === updatedRecord.id) {
        return updateObject(state, {
            record: updatedRecord
        });
    } else {
        return state;
    }
};

export const updateRecordStart = (state) => {
    return updateObject(state, {
        isRecordUpdateLoading: true,
        updateRecordError: null
    });
};

export const updateRecordSuccess = (state) => {
    return updateObject(state, {
        isRecordUpdateLoading: false,
        updateRecordError: null
    });
};

export const updateRecordFail = (state, action) => {
    return updateObject(state, {
        updateRecordError: action.error,
        isRecordUpdateLoading: false
    });
};

export const clearUpdateRecordLoading = (state) => {
    return updateObject(state, {
        isRecordUpdateLoading: null
    });
};

export const clearUpdateRecordError = (state) => {
    return updateObject(state, {
        updateRecordError: null
    });
};

export const cancelUpdateRecord = (state) => {
    return updateObject(state, {
        updateRecordError: null,
        isRecordUpdateLoading: null
    });
};

export const updateRecordStatusStart = (state) => {
    return updateObject(state, {
        isRecordStatusUpdateLoading: true,
        updateRecordStatusError: null
    });
};

export const updateRecordStatusSuccess = (state) => {
    return updateObject(state, {
        isRecordStatusUpdateLoading: false,
        updateRecordStatusError: null
    });
};

export const updateRecordStatusFail = (state, action) => {
    return updateObject(state, {
        updateRecordStatusError: action.error,
        isRecordStatusUpdateLoading: false
    });
};

export const clearUpdateRecordStatusLoading = (state) => {
    return updateObject(state, {
        isRecordStatusUpdateLoading: null
    });
};

export const clearUpdateRecordStatusError = (state) => {
    return updateObject(state, {
        updateRecordStatusError: null
    });
};

export const cancelUpdateRecordStatus = (state) => {
    return updateObject(state, {
        updateRecordStatusError: null,
        isRecordStatusUpdateLoading: null
    });
};

export const removeRecordStart = (state) => {
    return updateObject(state, {
        isRecordRemoveLoading: true,
        removeRecordError: null
    });
};

export const removeRecord = (state, recordToRemove) => {
    const copyOfState = { ...state };
    let existingList = [...copyOfState.records];
    const index = existingList.findIndex(obj => obj.id === recordToRemove.id);

    // only update if the record exists in the list
    if (index !== -1) {
        // returns the deleted items
        existingList.splice(index, 1);

        return updateObject(state, {
            records: existingList
        });
    } else {
        return state;
    }
};

export const removeRecordSuccess = (state) => {
    return updateObject(state, {
        isRecordRemoveLoading: false,
        removeRecordError: null
    });
};

export const removeRecordFail = (state, action) => {
    return updateObject(state, {
        removeRecordError: action.error,
        isRecordRemoveLoading: false
    });
};

export const clearRemoveRecordLoading = (state) => {
    return updateObject(state, {
        isRecordRemoveLoading: null
    });
};

export const clearRemoveRecordError = (state) => {
    return updateObject(state, {
        removeRecordError: null
    });
};

export const cancelRemoveRecord = (state) => {
    return updateObject(state, {
        removeRecordError: null,
        isRecordRemoveLoading: null
    });
};

const newList = {
    records: [],
    searchParams: {},
    pagination: { total: 0, current: 1, pageSize: 5, totalPages: 1 },
    isLoading: false,
    error: null
};

export const fetchListStart = (state, action) => {
    const copyOfState = { ...state };
    let existingLists = { ...copyOfState.lists };
    let newLists = {};

    if (isStringNotEmpty(action.listName)) {
        if (isObjectNotEmpty(existingLists[action.listName])) {
            newLists[action.listName] = {
                ...existingLists[action.listName],
                isLoading: true,
                error: null
            };
        } else {
            newLists[action.listName] = {
                ...newList,
                isLoading: true,
                error: null
            };
        }
    }

    return updateObject(state, {
        lists: { ...existingLists, ...newLists }
    });
};

export const fetchListSuccess = (state, action) => {
    const copyOfState = { ...state };
    let existingLists = { ...copyOfState.lists };
    let newLists = {};

    if (isStringNotEmpty(action.listName)) {
        if (isObjectNotEmpty(existingLists[action.listName])) {
            if (isObjectNotEmpty(action.params)) {
                newLists[action.listName] = {
                    ...existingLists[action.listName],
                    ...action.params,
                    isLoading: false,
                    error: null
                };

                if (isNotNullOrUndefined(action.records)) {
                    newLists[action.listName].records = [...action.records];
                }
            } else {
                newLists[action.listName] = {
                    ...existingLists[action.listName],
                    isLoading: false,
                    error: null
                };

                if (isNotNullOrUndefined(action.records)) {
                    newLists[action.listName].records = [...action.records];
                }
            }
        } else {
            if (isObjectNotEmpty(action.params)) {
                newLists[action.listName] = {
                    ...newList,
                    ...action.params,
                    isLoading: false,
                    error: null
                };

                if (isNotNullOrUndefined(action.records)) {
                    newLists[action.listName].records = [...action.records];
                }
            } else {
                newLists[action.listName] = {
                    ...newList,
                    isLoading: false,
                    error: null
                };

                if (isNotNullOrUndefined(action.records)) {
                    newLists[action.listName].records = [...action.records];
                }
            }
        }
    }

    return updateObject(state, {
        lists: { ...existingLists, ...newLists }
    });
};

export const fetchListFail = (state, action) => {
    const copyOfState = { ...state };
    let existingLists = { ...copyOfState.lists };
    let newLists = {};

    if (isStringNotEmpty(action.listName)) {
        if (isObjectNotEmpty(existingLists[action.listName])) {
            newLists[action.listName] = {
                ...existingLists[action.listName],
                isLoading: false,
                error: null
            };

            if (isNotNullOrUndefined(action.error)) {
                newLists[action.listName].error = action.error;
            }
        } else {
            newLists[action.listName] = {
                ...newList,
                isLoading: false,
                error: null
            };

            if (isNotNullOrUndefined(action.error)) {
                newLists[action.listName].error = action.error;
            }
        }
    }

    return updateObject(state, {
        lists: { ...existingLists, ...newLists }
    });
};

export const addToTopOfList = (state, action) => {
    if (isObjectNotEmpty(action.newRecord) && isStringNotEmpty(action.listName)) {
        const copyOfState = { ...state };
        let existingLists = { ...copyOfState.lists };
        let newLists = {};

        if (isObjectNotEmpty(existingLists[action.listName])) {
            let existingList = isListNotEmpty(existingLists[action.listName].records) ? [...existingLists[action.listName].records] : [];
            const index = existingList.findIndex(obj => obj.id === action.newRecord.id);

            // only add the record if it doesn't exist in the list already
            if (index === -1) {
                newLists[action.listName] = {
                    ...existingLists[action.listName],
                    records: [action.newRecord, ...existingLists[action.listName].records]
                };

                newLists[action.listName].pagination.total = newLists[action.listName].pagination.total + 1;
            } else {
                const updatedRecords = [
                    ...existingList.slice(0, index), // everything before current obj
                    action.newRecord,
                    ...existingList.slice(index + 1), // everything after current obj
                ];

                newLists[action.listName] = {
                    ...existingLists[action.listName],
                    records: [...updatedRecords]
                };
            }
        } else {
            newLists[action.listName] = {
                ...newList,
                records: [action.newRecord]
            };

            newLists[action.listName].pagination.total = newLists[action.listName].pagination.total + 1;
        }

        return updateObject(state, {
            lists: { ...existingLists, ...newLists }
        });
    }

    return state;
};

export const addToBottomOfList = (state, action) => {
    if (isObjectNotEmpty(action.newRecord) && isStringNotEmpty(action.listName)) {
        const copyOfState = { ...state };
        let existingLists = { ...copyOfState.lists };
        let newLists = {};

        if (isObjectNotEmpty(existingLists[action.listName])) {
            let existingList = isListNotEmpty(existingLists[action.listName].records) ? [...existingLists[action.listName].records] : [];
            const index = existingList.findIndex(obj => obj.id === action.newRecord.id);

            // only add the record if it doesn't exist in the list already
            if (index === -1) {
                newLists[action.listName] = {
                    ...existingLists[action.listName],
                    records: [...existingLists[action.listName].records, action.newRecord]
                };

                newLists[action.listName].pagination.total = newLists[action.listName].pagination.total + 1;
            } else {
                const updatedRecords = [
                    ...existingList.slice(0, index), // everything before current obj
                    action.newRecord,
                    ...existingList.slice(index + 1), // everything after current obj
                ];

                newLists[action.listName] = {
                    ...existingLists[action.listName],
                    records: [...updatedRecords]
                };
            }
        } else {
            newLists[action.listName] = {
                ...newList,
                records: [action.newRecord]
            };

            newLists[action.listName].pagination.total = newLists[action.listName].pagination.total + 1;
        }

        return updateObject(state, {
            lists: { ...existingLists, ...newLists }
        });
    }

    return state;
};

export const addToTopOfLists = (state, action) => {
    if (isObjectNotEmpty(action.newRecord)) {
        const copyOfState = { ...state };
        let existingLists = { ...copyOfState.lists };
        let newLists = {};

        if (isObjectNotEmpty(existingLists['ALL'])) {
            let existingList = isListNotEmpty(existingLists['ALL'].records) ? [...existingLists['ALL'].records] : [];
            const index = existingList.findIndex(obj => obj.id === action.newRecord.id);

            // only add the record if it doesn't exist in the list already
            if (index === -1) {
                newLists['ALL'] = {
                    ...existingLists['ALL'],
                    records: [action.newRecord, ...existingLists['ALL'].records]
                };

                newLists['ALL'].pagination.total = newLists['ALL'].pagination.total + 1;
            } else {
                const updatedRecords = [
                    ...existingList.slice(0, index), // everything before current obj
                    action.newRecord,
                    ...existingList.slice(index + 1), // everything after current obj
                ];

                newLists['ALL'] = {
                    ...existingLists['ALL'],
                    records: [...updatedRecords]
                };
            }
        } else {
            newLists['ALL'] = {
                ...newList,
                records: [action.newRecord]
            };

            newLists['ALL'].pagination.total = newLists['ALL'].pagination.total + 1;
        }

        if (isStringNotEmpty(action.listName)) {
            if (isObjectNotEmpty(existingLists[action.listName])) {
                let existingList = isListNotEmpty(existingLists[action.listName].records) ? [...existingLists[action.listName].records] : [];
                const index = existingList.findIndex(obj => obj.id === action.newRecord.id);

                // only add the record if it doesn't exist in the list already
                if (index === -1) {
                    newLists[action.listName] = {
                        ...existingLists[action.listName],
                        records: [action.newRecord, ...existingLists[action.listName].records]
                    };

                    newLists[action.listName].pagination.total = newLists[action.listName].pagination.total + 1;
                } else {
                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        action.newRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    newLists[action.listName] = {
                        ...existingLists[action.listName],
                        records: [...updatedRecords]
                    };
                }
            } else {
                newLists[action.listName] = {
                    ...newList,
                    records: [action.newRecord]
                };

                newLists[action.listName].pagination.total = newLists[action.listName].pagination.total + 1;
            }
        }

        return updateObject(state, {
            lists: { ...existingLists, ...newLists }
        });
    }

    return state;
};

export const addToBottomOfLists = (state, action) => {
    if (isObjectNotEmpty(action.newRecord)) {
        const copyOfState = { ...state };
        let existingLists = { ...copyOfState.lists };
        let newLists = {};

        if (isObjectNotEmpty(existingLists['ALL'])) {
            let existingList = isListNotEmpty(existingLists['ALL'].records) ? [...existingLists['ALL'].records] : [];
            const index = existingList.findIndex(obj => obj.id === action.newRecord.id);

            // only add the record if it doesn't exist in the list already
            if (index === -1) {
                newLists['ALL'] = {
                    ...existingLists['ALL'],
                    records: [...existingLists['ALL'].records, action.newRecord]
                };

                newLists['ALL'].pagination.total = newLists['ALL'].pagination.total + 1;
            } else {
                const updatedRecords = [
                    ...existingList.slice(0, index), // everything before current obj
                    action.newRecord,
                    ...existingList.slice(index + 1), // everything after current obj
                ];

                newLists['ALL'] = {
                    ...existingLists['ALL'],
                    records: [...updatedRecords]
                };
            }
        } else {
            newLists['ALL'] = {
                ...newList,
                records: [action.newRecord]
            };

            newLists['ALL'].pagination.total = newLists['ALL'].pagination.total + 1;
        }

        if (isStringNotEmpty(action.listName)) {
            if (isObjectNotEmpty(existingLists[action.listName])) {
                let existingList = isListNotEmpty(existingLists[action.listName].records) ? [...existingLists[action.listName].records] : [];
                const index = existingList.findIndex(obj => obj.id === action.newRecord.id);

                // only add the record if it doesn't exist in the list already
                if (index === -1) {
                    newLists[action.listName] = {
                        ...existingLists[action.listName],
                        records: [...existingLists[action.listName].records, action.newRecord]
                    };

                    newLists[action.listName].pagination.total = newLists[action.listName].pagination.total + 1;
                } else {
                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        action.newRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    newLists[action.listName] = {
                        ...existingLists[action.listName],
                        records: [...updatedRecords]
                    };
                }
            } else {
                newLists[action.listName] = {
                    ...newList,
                    records: [action.newRecord]
                };

                newLists[action.listName].pagination.total = newLists[action.listName].pagination.total + 1;
            }
        }

        return updateObject(state, {
            lists: { ...existingLists, ...newLists }
        });
    }

    return state;
};

export const updateInList = (state, action) => {
    if (isObjectNotEmpty(action.updatedRecord) && isStringNotEmpty(action.listName)) {
        const copyOfState = { ...state };
        let existingLists = { ...copyOfState.lists };
        let newLists = {};

        if (isObjectNotEmpty(existingLists[action.listName])) {
            let existingList = isListNotEmpty(existingLists[action.listName].records) ? [...existingLists[action.listName].records] : [];
            const index = existingList.findIndex(obj => obj.id === action.updatedRecord.id);

            // only update if the record exists in the list
            if (index !== -1) {
                const updatedRecords = [
                    ...existingList.slice(0, index), // everything before current obj
                    action.updatedRecord,
                    ...existingList.slice(index + 1), // everything after current obj
                ];

                newLists[action.listName] = {
                    ...existingLists[action.listName],
                    records: [...updatedRecords]
                };
            }
        }

        if (isObjectNotEmpty(newLists)) {
            return updateObject(state, {
                lists: { ...existingLists, ...newLists }
            });
        }
    }

    return state;
};

export const updateInLists = (state, action) => {
    if (isObjectNotEmpty(action.updatedRecord)) {
        const copyOfState = { ...state };
        let existingLists = { ...copyOfState.lists };
        let newLists = {};

        if (isObjectNotEmpty(existingLists['ALL'])) {
            let existingList = isListNotEmpty(existingLists['ALL'].records) ? [...existingLists['ALL'].records] : [];
            const index = existingList.findIndex(obj => obj.id === action.updatedRecord.id);

            // only update if the record exists in the list
            if (index !== -1) {
                const updatedRecords = [
                    ...existingList.slice(0, index), // everything before current obj
                    action.updatedRecord,
                    ...existingList.slice(index + 1), // everything after current obj
                ];

                newLists['ALL'] = {
                    ...existingLists['ALL'],
                    records: [...updatedRecords]
                };
            }
        }

        if (isStringNotEmpty(action.listName) && isObjectNotEmpty(existingLists[action.listName])) {
            let existingList = isListNotEmpty(existingLists[action.listName].records) ? [...existingLists[action.listName].records] : [];
            const index = existingList.findIndex(obj => obj.id === action.updatedRecord.id);

            // only update if the record exists in the list
            if (index !== -1) {
                const updatedRecords = [
                    ...existingList.slice(0, index), // everything before current obj
                    action.updatedRecord,
                    ...existingList.slice(index + 1), // everything after current obj
                ];

                newLists[action.listName] = {
                    ...existingLists[action.listName],
                    records: [...updatedRecords]
                };
            }
        }

        if (isObjectNotEmpty(newLists)) {
            return updateObject(state, {
                lists: { ...existingLists, ...newLists }
            });
        }
    }

    return state;
};

export const removeFromList = (state, action) => {
    if (isObjectNotEmpty(action.recordToRemove) && isStringNotEmpty(action.listName)) {
        const copyOfState = { ...state };
        let existingLists = { ...copyOfState.lists };
        let newLists = {};

        if (isObjectNotEmpty(existingLists[action.listName])) {
            let existingList = isListNotEmpty(existingLists[action.listName].records) ? [...existingLists[action.listName].records] : [];
            const index = existingList.findIndex(obj => obj.id === action.recordToRemove.id);

            // only update if the record exists in the list
            if (index !== -1) {
                existingList.splice(index, 1);

                newLists[action.listName] = {
                    ...existingLists[action.listName],
                    records: [...existingList]
                };

                newLists[action.listName].pagination.total = newLists[action.listName].pagination.total > 0 ? newLists[action.listName].pagination.total - 1 : 0;
            }
        }

        if (isObjectNotEmpty(newLists)) {
            return updateObject(state, {
                lists: { ...existingLists, ...newLists }
            });
        }
    }

    return state;
};

export const removeFromLists = (state, action) => {
    if (isObjectNotEmpty(action.recordToRemove)) {
        const copyOfState = { ...state };
        let existingLists = { ...copyOfState.lists };
        let newLists = {};

        if (isObjectNotEmpty(existingLists['ALL'])) {
            let existingList = isListNotEmpty(existingLists['ALL'].records) ? [...existingLists['ALL'].records] : [];
            const index = existingList.findIndex(obj => obj.id === action.recordToRemove.id);

            // only update if the record exists in the list
            if (index !== -1) {
                existingList.splice(index, 1);

                newLists['ALL'] = {
                    ...existingLists['ALL'],
                    records: [...existingList]
                };

                newLists['ALL'].pagination.total = newLists['ALL'].pagination.total > 0 ? newLists['ALL'].pagination.total - 1 : 0;
            }
        }

        if (isStringNotEmpty(action.listName) && isObjectNotEmpty(existingLists[action.listName])) {
            let existingList = isListNotEmpty(existingLists[action.listName].records) ? [...existingLists[action.listName].records] : [];
            const index = existingList.findIndex(obj => obj.id === action.recordToRemove.id);

            // only update if the record exists in the list
            if (index !== -1) {
                existingList.splice(index, 1);

                newLists[action.listName] = {
                    ...existingLists[action.listName],
                    records: [...existingList]
                };

                newLists[action.listName].pagination.total = newLists[action.listName].pagination.total > 0 ? newLists[action.listName].pagination.total - 1 : 0;
            }
        }

        if (isObjectNotEmpty(newLists)) {
            return updateObject(state, {
                lists: { ...existingLists, ...newLists }
            });
        }
    }

    return state;
};

export const clearList = (state, action) => {
    if (isStringNotEmpty(action.listName)) {
        const copyOfState = { ...state };
        let existingLists = { ...copyOfState.lists };
        let newLists = {};

        newLists[action.listName] = {
            ...newList
        };

        return updateObject(state, {
            lists: { ...existingLists, ...newLists }
        });
    }

    return state;
};

export const clearAllLists = (state) => {
    return updateObject(state, {
        lists: {}
    });
};

export const selectListRecords = (lists, filter) => {
    if (isObjectNotEmpty(lists)) {
        if (isStringNotEmpty(filter)) {
            if (isObjectNotEmpty(lists[filter])) {
                return lists[filter].records;
            }
        }
    }

    return newList.records;
};

export const selectListIsLoading = (lists, filter) => {
    if (isObjectNotEmpty(lists)) {
        if (isStringNotEmpty(filter)) {
            if (isObjectNotEmpty(lists[filter])) {
                return lists[filter].isLoading;
            }
        }
    }

    return newList.isLoading;
};

export const selectListError = (lists, filter) => {
    if (isObjectNotEmpty(lists)) {
        if (isStringNotEmpty(filter)) {
            if (isObjectNotEmpty(lists[filter])) {
                return lists[filter].error;
            }
        }
    }

    return newList.error;
};

export const selectListSearchParams = (lists, filter) => {
    if (isObjectNotEmpty(lists)) {
        if (isStringNotEmpty(filter)) {
            if (isObjectNotEmpty(lists[filter])) {
                return lists[filter].searchParams;
            }
        }
    }

    return newList.searchParams;
};

export const selectListPagination = (lists, filter) => {
    if (isObjectNotEmpty(lists)) {
        if (isStringNotEmpty(filter)) {
            if (isObjectNotEmpty(lists[filter])) {
                return lists[filter].pagination;
            }
        }
    }

    return newList.pagination;
};