import React from 'react';
import { useSelector } from "react-redux";
import { isBooleanTrue, isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../objectUtils';
import utils from './entitlementUtils';
import store from '../../store/store';

export const Can = ({ entityAction = "", entityModel = "", entityObject = {}, entityTypes = [], accountId = null, accountRoles = [], staffOnly = false, adminsOnly = false, children }) => {
    const isStaff = useSelector(state => state.auth.isStaff);
    const isAdmin = useSelector(state => state.auth.isAdmin);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const accountEntityType = useSelector(state => state.auth.entityType);
    const accountEntityId = useSelector(state => state.auth.entityId);
    const userId = useSelector(state => state.auth.userId);
    const yourAccountUser = useSelector(state => state.yourAccountUsers.record);
    const yourAccount = useSelector(state => state.yourAccounts.record);

    const auth = {
        isStaff: isStaff,
        isAdmin: isAdmin,
        isAuthenticated: isAuthenticated,
        account: yourAccount,
        accountUser: yourAccountUser,
        entityType: accountEntityType,
        entityId: accountEntityId,
        userId: userId
    };

    let can = false;
    if (isBooleanTrue(staffOnly)) {
        can = utils.allowStaff(auth);
    } else if (isBooleanTrue(adminsOnly)) {
        can = utils.allowStaffAdmin(auth);
    } else if (isStringNotEmpty(entityAction) && isStringNotEmpty(entityModel)) {
        can = utils.checkEntitlements(entityAction, entityModel, entityObject, auth, accountId);
    } else if (isStringNotEmpty(accountId)) {
        if (isListNotEmpty(accountRoles)) {
            can = utils.isAccountUserWithRoles(auth, accountId, accountRoles) || utils.allowStaff(auth);
        } else {
            can = utils.isAccountUser(auth, accountId) || utils.allowStaff(auth);
        }
    } else if (isStringNotEmpty(entityModel) && entityModel === 'LOAD' && isObjectNotEmpty(entityObject) && isListNotEmpty(entityTypes)) {
        can = utils.canViewLoad(entityObject, auth, entityTypes);
    } else if (isListNotEmpty(entityTypes)) {
        can = utils.canView(auth, entityTypes);
    }

    if (can === false) {
        return (
            <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <h2 style={{ textAlign: 'center' }}>Uh Oh! It looks like you don't have permissions to view the page you are trying to access.</h2>
            </div>
        );
    } else {
        return children;
    }
};

export const CanSee = ({ entityAction = "", entityModel = "", entityObject = {}, entityTypes = [], accountId = null, accountRoles = [], staffOnly = false, adminsOnly = false, children }) => {
    const isStaff = useSelector(state => state.auth.isStaff);
    const isAdmin = useSelector(state => state.auth.isAdmin);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const accountEntityType = useSelector(state => state.auth.entityType);
    const accountEntityId = useSelector(state => state.auth.entityId);
    const userId = useSelector(state => state.auth.userId);
    const yourAccountUser = useSelector(state => state.yourAccountUsers.record);
    const yourAccount = useSelector(state => state.yourAccounts.record);

    const auth = {
        isStaff: isStaff,
        isAdmin: isAdmin,
        isAuthenticated: isAuthenticated,
        account: yourAccount,
        accountUser: yourAccountUser,
        entityType: accountEntityType,
        entityId: accountEntityId,
        userId: userId
    };

    let can = false;
    if (isBooleanTrue(staffOnly)) {
        can = utils.allowStaff(auth);
    } else if (isBooleanTrue(adminsOnly)) {
        can = utils.allowStaffAdmin(auth);
    } else if (isStringNotEmpty(entityAction) && isStringNotEmpty(entityModel)) {
        can = utils.checkEntitlements(entityAction, entityModel, entityObject, auth, accountId);
    } else if (isStringNotEmpty(accountId)) {
        if (isListNotEmpty(accountRoles)) {
            can = utils.isAccountUserWithRoles(auth, accountId, accountRoles) || utils.allowStaff(auth);
        } else {
            can = utils.isAccountUser(auth, accountId) || utils.allowStaff(auth);
        }
    } else if (isStringNotEmpty(entityModel) && entityModel === 'LOAD' && isObjectNotEmpty(entityObject) && isListNotEmpty(entityTypes)) {
        can = utils.canViewLoad(entityObject, auth, entityTypes);
    } else if (isListNotEmpty(entityTypes)) {
        can = utils.canView(auth, entityTypes);
    }

    if (can === false) {
        return null;
    } else {
        return children;
    }
};

export const CanDo = ({ entityAction = "", entityModel = "", entityObject = {}, entityTypes = [], accountId = null, accountRoles = [], staffOnly = false, adminsOnly = false }) => {
    const state = store.getState();
    const authState = { ...state.auth };
    const yourAccountUsersState = { ...state.yourAccountUsers };
    const yourAccountsState = { ...state.yourAccounts };
    const isStaff = authState.isStaff;
    const isAdmin = authState.isAdmin;
    const isAuthenticated = authState.isAuthenticated;
    const accountEntityType = authState.entityType;
    const accountEntityId = authState.entityId;
    const userId = authState.userId;
    const yourAccountUser = yourAccountUsersState.record;
    const yourAccount = yourAccountsState.record;

    const auth = {
        isStaff: isStaff,
        isAdmin: isAdmin,
        isAuthenticated: isAuthenticated,
        account: yourAccount,
        accountUser: yourAccountUser,
        entityType: accountEntityType,
        entityId: accountEntityId,
        userId: userId
    };

    let can = false;
    if (isBooleanTrue(staffOnly)) {
        can = utils.allowStaff(auth);
    } else if (isBooleanTrue(adminsOnly)) {
        can = utils.allowStaffAdmin(auth);
    } else if (isStringNotEmpty(entityAction) && isStringNotEmpty(entityModel)) {
        can = utils.checkEntitlements(entityAction, entityModel, entityObject, auth, accountId);
    } else if (isStringNotEmpty(accountId)) {
        if (isListNotEmpty(accountRoles)) {
            can = utils.isAccountUserWithRoles(auth, accountId, accountRoles) || utils.allowStaff(auth);
        } else {
            can = utils.isAccountUser(auth, accountId) || utils.allowStaff(auth);
        }
    } else if (isStringNotEmpty(entityModel) && entityModel === 'LOAD' && isObjectNotEmpty(entityObject) && isListNotEmpty(entityTypes)) {
        can = utils.canViewLoad(entityObject, auth, entityTypes);
    } else if (isListNotEmpty(entityTypes)) {
        can = utils.canView(auth, entityTypes);
    }

    return can;
};