import * as actionTypes from "../actions/actionTypes";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { isBooleanTrue, isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import LocalStorage from '../../shared/localStorageUtils';
import * as actionCreators from "./index";
import ErrorUtils from "../../shared/errorUtils";

//#region Fetch User List Methods

export const fetchUserListStart = (listName) => {
    return {
        type: actionTypes.FETCH_USER_LIST_START,
        payload: { listName: listName }
    }
};

export const fetchUserListSuccess = (listName, payload) => {
    return {
        type: actionTypes.FETCH_USER_LIST_SUCCESS,
        payload: { listName: listName, ...payload }
    }
};

export const fetchUserListFail = (listName, payload) => {
    return {
        type: actionTypes.FETCH_USER_LIST_FAIL,
        payload: { listName: listName, ...payload }
    }
};

export const clearUserList = (listName) => {
    return {
        type: actionTypes.CLEAR_USER_LIST,
        payload: { listName: listName }
    }
};

export const clearUserLists = () => {
    return {
        type: actionTypes.CLEAR_USER_LISTS
    }
};

export const addUserToUserList = (listName, payload) => {
    return {
        type: actionTypes.ADD_TO_USER_LIST,
        payload: { listName: listName, newRecord: payload }
    }
};

export const updateUserInUserList = (listName, payload) => {
    return {
        type: actionTypes.UPDATE_IN_USER_LIST,
        payload: { listName: listName, updatedRecord: payload }
    }
};

export const removeUserFromUserList = (listName, payload) => {
    return {
        type: actionTypes.REMOVE_FROM_USER_LIST,
        payload: { listName: listName, recordToRemove: payload }
    }
};

//#endregion
//#region Fetch User Methods

export const fetchUserStart = () => {
    return {
        type: actionTypes.FETCH_USER_START
    }
};

export const fetchUserSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_USER_SUCCESS,
        payload: payload
    }
};

export const fetchUserFail = (payload) => {
    return {
        type: actionTypes.FETCH_USER_FAIL,
        payload: payload
    }
};

export const clearUser = () => {
    return {
        type: actionTypes.CLEAR_USER
    }
};

//#endregion
//#region Add User Methods

export const addUserStart = () => {
    return {
        type: actionTypes.ADD_USER_START
    }
};

export const addUserSuccess = () => {
    return {
        type: actionTypes.ADD_USER_SUCCESS
    }
};

export const addUserFail = (payload) => {
    return {
        type: actionTypes.ADD_USER_FAIL,
        payload: payload
    }
};

export const addUserLoadingClear = () => {
    return {
        type: actionTypes.ADD_USER_LOADING_CLEAR
    }
};

export const addUserErrorClear = () => {
    return {
        type: actionTypes.ADD_USER_ERROR_CLEAR
    }
};

export const addUserCancel = () => {
    return {
        type: actionTypes.ADD_USER_CANCEL
    }
};

//#endregion
//#region Update User Methods

const changeSingleUser = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_USER,
        payload: payload
    }
};

export const updateUserStart = () => {
    return {
        type: actionTypes.UPDATE_USER_START
    }
};

export const updateUserSuccess = () => {
    return {
        type: actionTypes.UPDATE_USER_SUCCESS
    }
};

export const updateUserFail = (payload) => {
    return {
        type: actionTypes.UPDATE_USER_FAIL,
        payload: payload
    }
};

export const updateUserLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_USER_LOADING_CLEAR
    }
};

export const updateUserErrorClear = () => {
    return {
        type: actionTypes.UPDATE_USER_ERROR_CLEAR
    }
};

export const updateUserCancel = () => {
    return {
        type: actionTypes.UPDATE_USER_CANCEL
    }
};

//#endregion
//#region Users Methods

export const fetchUserList = (listName, payload) => {
    return async (dispatch, getState) => {
        if (isStringNotEmpty(listName)) {
            try {
                dispatch(fetchUserListStart(listName));

                const state = getState();
                const usersState = { ...state.users };
                const existingLists = { ...usersState.lists };

                let pagination = {};
                let searchParams = {};
                if (isObjectNotEmpty(existingLists[listName])) {
                    pagination = { ...existingLists[listName].pagination };
                    searchParams = { ...existingLists[listName].searchParams };
                }

                if (isObjectNotEmpty(payload)) {
                    searchParams = { ...payload };
                }

                const res = await Data.getUsers({ ...searchParams }, pagination);
                dispatch(fetchUserListSuccess(listName, { records: res.data, params: { searchParams: res.searchParams, pagination: res.pagination } }));
            } catch (error) {
                logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
                dispatch(fetchUserListFail(listName, { error: ErrorUtils.getErrorMessage(error) }));
            }
        }
    }
};

export const fetchUser = (userProfileId) => {
    return async (dispatch) => {
        try {
            dispatch(fetchUserStart());

            const user = await Data.getUser(userProfileId);
            dispatch(fetchUserSuccess({ record: user }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchUserFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const addUser = (payload) => {
    return async (dispatch) => {
        try {
            dispatch(addUserStart());

            const newUser = await Data.addUser(payload);
            if (isObjectNotEmpty(newUser)) {
                if (isBooleanTrue(newUser.isStaff)) {
                    dispatch(addUserToUserList('STAFF', newUser));
                    dispatch(addUserToUserList('ALL', newUser));
                } else {
                    dispatch(addUserToUserList('ALL', newUser));
                }

                // if staff then refresh the staff orchestrator
                if (newUser.isStaff === true) {
                    dispatch(actionCreators.getStaff(true));
                }
            }

            dispatch(addUserSuccess());
            dispatch(addUserLoadingClear());
            dispatch(addUserErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addUserFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateUser = (userProfileId, payload, isProfile, isEmailChanged = false) => {
    return async (dispatch) => {
        try {
            dispatch(updateUserStart());

            if ((isStringNotEmpty(payload.profileImageId) || isObjectNotEmpty(payload.profileImage)) && isProfile === true) {
                dispatch(actionCreators.authUpdate({ profileImageId: null }));
            }

            const updatedUser = await Data.updateUser(userProfileId, payload);
            if (isObjectNotEmpty(updatedUser)) {
                if (isEmailChanged === true) {
                    await dispatch(actionCreators.completeLogout());
                } else {
                    if (isProfile === true) {
                        dispatch(changeSingleUser(updatedUser));
                        dispatch(actionCreators.authUpdate({ firstName: updatedUser.firstName, lastName: updatedUser.lastName, profileImageId: updatedUser.profileImageId }));

                        await LocalStorage.setItem('firstName', updatedUser.firstName.toString());
                        await LocalStorage.setItem('lastName', updatedUser.lastName.toString());
                        if (isStringNotEmpty(updatedUser.profileImageId)) {
                            await LocalStorage.setItem('profileImageId', updatedUser.profileImageId);
                        } else {
                            await LocalStorage.removeItem('profileImageId');
                        }
                    } else {
                        if (isBooleanTrue(updatedUser.isDeleted)) {
                            if (isBooleanTrue(updatedUser.isStaff)) {
                                dispatch(removeUserFromUserList('STAFF', updatedUser));
                                dispatch(removeUserFromUserList('ALL', updatedUser));
                            } else {
                                dispatch(removeUserFromUserList('ALL', updatedUser));
                            }
                        } else {
                            if (isBooleanTrue(updatedUser.isStaff)) {
                                dispatch(updateUserInUserList('STAFF', updatedUser));
                                dispatch(updateUserInUserList('ALL', updatedUser));
                            } else {
                                dispatch(updateUserInUserList('ALL', updatedUser));
                            }
                            dispatch(changeSingleUser(updatedUser));
                        }
                    }

                    // if staff then refresh the staff orchestrator
                    if (isBooleanTrue(updatedUser.isStaff)) {
                        dispatch(actionCreators.getStaff(true));
                    }
                }
            }

            dispatch(updateUserSuccess());
            dispatch(updateUserLoadingClear());
            dispatch(updateUserErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateUserFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion