import React, { useState, useMemo } from 'react';
import { Input, Select, Alert, Spin } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Enums from '../../shared/enums';
import { driverUnAssignedEmailTemplate } from "../../shared/emailTemplates";
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import { sendEmail } from '../../api/emailClient';
import { isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { sendLoadNotification } from '../../api/data';
import logger from '../../shared/logger';
import { CanDo } from '../../shared/entitlements/entitlements';

const { TextArea } = Input;

const EditLoadStatusCancelled = ({ load, cancel }) => {
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loadCancellations.isRecordAddLoading);
    const error = useSelector(state => state.loadCancellations.addRecordError);
    const entityId = useSelector(state => state.auth.entityId);

    //#endregion
    //#region enums

    const cancellationEntityTypeOptions = Enums.CancellationEntityTypes.selectListOptions();
    const shipperCancellationReasonOptions = Enums.ShipperCancellationReasons.selectListOptions();
    const carrierCancellationReasonOptions = Enums.CarrierCancellationReasons.selectListOptions();
    const staffCancellationReasonOptions = Enums.StaffCancellationReasons.selectListOptions();

    //#endregion
    //#region useStates

    const [showShipperReasons, setShowShipperReasons] = useState(false);
    const [showCarrierReasons, setShowCarrierReasons] = useState(false);
    const [showStaffReasons, setShowStaffReasons] = useState(false);
    const [showCancellationDetails, setShowCancellationDetails] = useState(false);

    //#endregion
    //#region onChanges

    const onChangeCancelledBy = (value) => {
        if (isStringNotEmpty(value)) {
            switch (value) {
                case 'SHIPPER':
                    setShowShipperReasons(true);
                    setShowCarrierReasons(false);
                    setShowStaffReasons(false);
                    setShowCancellationDetails(false);
                    break;
                case 'CARRIER':
                    setShowShipperReasons(false);
                    setShowCarrierReasons(true);
                    setShowStaffReasons(false);
                    setShowCancellationDetails(false);
                    break;
                case 'STAFF':
                    setShowShipperReasons(false);
                    setShowCarrierReasons(false);
                    setShowStaffReasons(true);
                    setShowCancellationDetails(false);
                    break;
                case 'BROKER':
                    setShowShipperReasons(false);
                    setShowCarrierReasons(false);
                    setShowStaffReasons(true);
                    setShowCancellationDetails(false);
                    break;
            }
        } else {
            setShowShipperReasons(false);
            setShowCarrierReasons(false);
            setShowStaffReasons(false);
            setShowCancellationDetails(false);
        }
    };

    const onChangeCancellationReason = (value) => {
        if (isStringNotEmpty(value)) {
            if (value === "OTHER") {
                setShowCancellationDetails(true);
            } else {
                setShowCancellationDetails(false);
            }
        } else {
            setShowCancellationDetails(false);
        }
    };

    //#endregion
    //#region send methods for emails and notifications

    const sendEmailToOldDrivers = (accountUsers) => {
        if (isListNotEmpty(accountUsers)) {
            accountUsers.forEach((accountUser) => {
                if (isObjectNotEmpty(accountUser.driver)) {
                    if (accountUser.driver.isApproved === undefined || accountUser.driver.isApproved === null || accountUser.driver.isApproved === false) {
                        const { body, subject } = driverUnAssignedEmailTemplate(accountUser.firstName, load.irisId)
                        sendEmail(subject, body, accountUser.email).then(res => {
                            //console.log(res);
                        }).catch(err => {
                            logger.logDebugEvent('EditLoadStatusCancelled.js', err.message, true);
                        });
                    }
                }
            });
        }
    };

    const sendNotificationToOldDrivers = (accountUsers) => {
        if (isListNotEmpty(accountUsers)) {
            accountUsers.forEach((accountUser) => {
                if (isObjectNotEmpty(accountUser.driver)) {
                    let newNotification = {
                        message: `Hi ${accountUser.firstName}, you are no longer assigned to load: ${load.irisId}.`,
                        loadId: load.id,
                        loadIrisId: load.irisId,
                        saveOnly: false,
                        subject: `${load.irisId} You have been unassigned from a load`,
                        severity: "CRITICAL",
                        userIds: [accountUser.userId],
                        loadStatus: load.loadStatus,
                        eventType: 'DRIVER_UNASSIGNED'
                    };
                    sendLoadNotification(newNotification).then(res => {
                        //console.log(res);
                    }).catch(err => {
                        logger.logDebugEvent('EditLoadStatusCancelled.js', err.message, true);
                    });
                }
            });
        }
    };

    const sendNotificationsToOldCarrier = (carrierId) => {
        if (isStringNotEmpty(carrierId)) {
            let newNotification = {
                message: `Hi, your account is no longer assigned to load: ${load.irisId}.`,
                loadId: load.id,
                loadIrisId: load.irisId,
                saveOnly: false,
                subject: `${load.irisId} This load has been removed from your account.`,
                severity: "CRITICAL",
                accountIds: [carrierId],
                loadStatus: load.loadStatus,
                eventType: 'CARRIER_UNASSIGNED'
            };
            sendLoadNotification(newNotification).then(res => {
                //console.log(res);
            }).catch(err => {
                logger.logDebugEvent('EditLoadStatusCancelled.js', err.message, true);
            });
        }
    };

    //#endregion
    //#region onSubmit and onCancel

    const onSubmit = (data) => {
        if (isObjectNotEmpty(load)) {
            if (data.entityType === "SHIPPER") {
                if (isStringNotEmpty(load.shipperId)) {
                    methods.clearErrors("entityType");
                    data.entityId = load.shipperId;
                } else {
                    methods.setError("entityType", { type: "required", message: "Cannot choose Shipper if there is no Shipper assigned to the Load" });
                    return;
                }
            } else if (data.entityType === "CARRIER") {
                if (isStringNotEmpty(load.assignedCarrierId)) {
                    methods.clearErrors("entityType");
                    // send notification to driver and carrier 
                    sendNotificationToOldDrivers(load.drivers);
                    sendEmailToOldDrivers(load.drivers);
                    sendNotificationsToOldCarrier(load.assignedCarrierId);
                    data.entityId = load.assignedCarrierId;
                } else {
                    methods.setError("entityType", { type: "required", message: "Cannot choose Carrier if there is no Carrier assigned to the Load" });
                    return;
                }
            } else if (data.entityType === "STAFF" && CanDo({ staffOnly: true }) && isStringNotEmpty(entityId)) {
                methods.clearErrors("entityType");
                data.entityId = entityId;
            }

            data.loadId = load.id;

            dispatch(actionCreators.addLoadCancellation(data));
        } else {
            onCancel();
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.addLoadCancellationCancel());
        cancel();
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.addLoadCancellationErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    //#endregion

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Fieldset legend="Load Cancellation Details">
                        <FormItem {...formItemLayout} label="Who Cancelled the Load?" required format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select Who the Load is Cancelled By"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChangeCancelledBy(selected); onChange(selected); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                >
                                    {cancellationEntityTypeOptions}
                                </Select>
                            )}
                            rules={{ required: 'Required Field' }}
                            name="entityType"
                        />
                        {showShipperReasons === true ? (
                            <FormItem {...formItemLayout} label="Cancellation Reason" required format="horizontal"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please Select a Reason"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChangeCancellationReason(selected); onChange(selected); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    >
                                        {shipperCancellationReasonOptions}
                                    </Select>
                                )}
                                rules={{ required: 'Required Field' }}
                                name="reason"
                            />
                        ) : null}
                        {showCarrierReasons === true ? (
                            <FormItem {...formItemLayout} label="Cancellation Reason" required format="horizontal"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please Select a Reason"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChangeCancellationReason(selected); onChange(selected); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    >
                                        {carrierCancellationReasonOptions}
                                    </Select>
                                )}
                                rules={{ required: 'Required Field' }}
                                name="reason"
                            />
                        ) : null}
                        {showStaffReasons === true ? (
                            <FormItem {...formItemLayout} label="Cancellation Reason" required format="horizontal"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please Select a Reason"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChangeCancellationReason(selected); onChange(selected); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    >
                                        {staffCancellationReasonOptions}
                                    </Select>
                                )}
                                rules={{ required: 'Required Field' }}
                                name="reason"
                            />
                        ) : null}
                        {showCancellationDetails === true ? (
                            <FormItem {...formItemLayout} label="Cancellation Explanation" required format="horizontal"
                                render={({ onChange, onBlur, value, name, ref }) => <TextArea onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} autoSize={{ minRows: 4 }} ref={ref} />}
                                rules={{ required: 'Required Field' }}
                                name="reasonDetail"
                            />
                        ) : null}
                    </Fieldset>
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Cancel Load" />
            </Form>
        </FormProvider>
    );
};

export default EditLoadStatusCancelled;