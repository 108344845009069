import Checks from '../entitlementUtils';

export const canCreateContent = (auth) => {
    return Checks.allowStaffVP(auth);
};

export const canReadContent = (auth) => {
    return Checks.allowStaffVP(auth);
};

export const canReadContentList = (auth) => {
    return Checks.allowStaffVP(auth);
};

export const canUpdateContent = (auth) => {
    return Checks.allowStaffVP(auth);
};

export const canDeleteContent = (auth) => {
    return Checks.allowStaffAdmin(auth);
};