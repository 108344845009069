import React from 'react';
import DataRow from '../../components/DataRow/DataRow';
import DataAddressRow from '../../components/DataAddressRow/DataAddressRow';
import DataContactInfoRow from '../../components/DataContactInfoRow/DataContactInfoRow';
import DocumentList from '../../components/DocumentList/DocumentList';
import NoteList from '../../components/NoteList/NoteList';
import { useSelector } from "react-redux";
import StringFormatter from '../../shared/stringFormatter';
import DataDateTimeRangeRow from '../DataDateTimeRangeRow/DataDateTimeRangeRow';
import DataTable from '../DataTable/DataTable';
import { isBooleanTrue, isListNotEmpty, isNotNullOrUndefined, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import LoadStopUtils from '../../api/utils/loadStopUtils';

const stringFormatter = new StringFormatter();

const LoadStop = ({ load, stop, showDocuments = false, showNotes = false }) => {
    //#region useSelectors

    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

    //#endregion
    //#region table displays

    const columns = [
        {
            title: 'Commodity',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Init Unit Quantity',
            dataIndex: 'unitPackagingInitCount',
            key: 'unitPackagingInitCount',
        },
        {
            title: 'Actual Unit Quantity',
            dataIndex: 'unitPackagingActualCount',
            key: 'unitPackagingActualCount',
        },
        {
            title: 'Unit of Measure/Packaging',
            dataIndex: 'unitPackaging',
            key: 'unitPackaging',
            render: (text, record) => { return stringFormatter.toFormattedString("UnitPackaging", text, null); },
        },
        {
            title: 'Init Bulk Quantity',
            dataIndex: 'bulkPackagingInitCount',
            key: 'bulkPackagingInitCount',
        },
        {
            title: 'Actual Bulk Quantity',
            dataIndex: 'bulkPackagingActualCount',
            key: 'bulkPackagingActualCount',
        },
        {
            title: 'Bulk Packaging',
            dataIndex: 'bulkPackaging',
            key: 'bulkPackaging',
            render: (text, record) => { return stringFormatter.toFormattedString("BulkPackaging", text, null); },
        },
    ];

    //#endregion

    if (isObjectNotEmpty(stop)) {
        const stopAddress = isObjectNotEmpty(stop) && isObjectNotEmpty(stop.stopLocation) && isObjectNotEmpty(stop.stopLocation.address) ? stop.stopLocation.address : null;
        const stopName = LoadStopUtils.getLoadStopLocationName(stop, load.shipperId, load.assignedCarrierId, load.createdByEntityType);

        return (
            <>
                {isStringNotEmpty(stopName) ? (<DataRow title="Stop Name" value={stopName} dataType="String" />) : null}
                <DataRow title="Stop Type" value={stop.stopType} dataType="StopType" />
                {isObjectNotEmpty(stopAddress) ? (<DataAddressRow title="Stop Address" address={stopAddress} />) : null}
                {isNotNullOrUndefined(stop.startDateTime) ? <DataRow title="Arrival Date/Time" value={stop.startDateTime} dataType="MomentDateTime" timeZone={stop.timeZone} /> : null}
                {isNotNullOrUndefined(stop.endDateTime) ? <DataRow title="Departure Date/Time" value={stop.endDateTime} dataType="MomentDateTime" timeZone={stop.timeZone} /> : null}
                {isNotNullOrUndefined(stop.stopDuration) && isNotNullOrUndefined(stop.stopDurationUnit) ? <DataRow title="Stop Duration" value={stop.stopDuration} dataType="Time" units={stop.stopDurationUnit} /> : null}
                {isNotNullOrUndefined(stop.actualDistanceTo) && isNotNullOrUndefined(stop.actualDistanceToUnit) ? <DataRow title="Leg Distance" value={stop.actualDistanceTo} dataType="Distance" units={stop.actualDistanceToUnit} /> : null}
                {isStringNotEmpty(stop.specialInstructions) ? (<DataRow title="Special Instructions" value={stop.specialInstructions} dataType="String" />) : null}
                {isListNotEmpty(stop.bolNumbers) ? <DataRow title="BOL Number(s)" dataType="String" value={stop.bolNumbers.join(', ')} /> : null}
                {isStringNotEmpty(stop.pickUpNumber) ? <DataRow title="Pick-Up Number" value={stop.pickUpNumber} dataType="String" /> : null}
                {isStringNotEmpty(stop.dropOffNumber) ? <DataRow title="Drop-Off Number" value={stop.dropOffNumber} dataType="String" /> : null}
                {isStringNotEmpty(stop.purchaseOrderNumber) ? <DataRow title="Customer PO Number" value={stop.purchaseOrderNumber} dataType="String" /> : null}
                {isBooleanTrue(stop.hasDriverAssist) === true ? <DataRow title="Driver Assist" value={`Yes, ${stringFormatter.toFormattedMoney(stop.driverAssist)}`} dataType="String" /> : null}
                {isBooleanTrue(stop.hasLumperFee) === true ? <DataRow title="Lumper Fee" value={`Yes, ${stringFormatter.toFormattedMoney(stop.lumperFee)}`} dataType="String" /> : null}
                {isStringNotEmpty(stop.apptType) ? (
                    <>
                        <DataRow title="Appointment Type" value={stop.apptType} dataType="AppointmentType" />
                        {isNotNullOrUndefined(stop.apptWindowStartDateTime) && isNotNullOrUndefined(stop.apptWindowEndDateTime) ? <DataDateTimeRangeRow title="Appointment Window" startTime={stop.apptWindowStartDateTime} endTime={stop.apptWindowEndDateTime} timeZone={stop.timeZone} /> : null}
                        {isStringNotEmpty(stop.apptNumber) ? <DataRow title="Appointment #" value={stop.apptNumber} dataType="String" /> : null}
                        {isNotNullOrUndefined(stop.apptDateTime) ? <DataRow title="Appointment Date/Time" value={stop.apptDateTime} dataType="MomentDateTime" timeZone={stop.timeZone} /> : null}
                        {isNotNullOrUndefined(stop.apptCallAheadDateTime) ? <DataRow title="Appointment Call Ahead Date/Time" value={stop.apptCallAheadDateTime} dataType="MomentDateTime" timeZone={stop.timeZone} /> : null}
                        {isObjectNotEmpty(stop.apptPointOfContact) ? <DataContactInfoRow title="Appointment POC" contact={stop.apptPointOfContact} /> : null}
                        {isStringNotEmpty(stop.apptInstructions) ? <DataRow title="Additional Appointment Instructions" value={stop.apptInstructions} dataType="String" /> : null}
                    </>
                ) : null}
                {isListNotEmpty(stop.commodities) ? (
                    <DataTable
                        style={{ marginTop: 24 }}
                        tableTitle="Stop Commodities"
                        showTitleInstructions={false}
                        bordered={true}
                        dataSource={stop.commodities}
                        columns={columns}
                        size="small"
                        pagination={false}
                        rowKey={record => `${stop.id}-${record.commodityId}`}
                    />
                ) : null}
                {isAuthenticated === true && isBooleanTrue(showDocuments) ? (
                    <DocumentList
                        tableTitle="Stop Documents"
                        documentEntityId={stop.id}
                        documentEntityType="STOP"
                        documentEntitySubType={stop.stopType}
                        needsLumperFeeReceipt={stop.hasLumperFee}
                        loadId={stop.loadId}
                        load={load}
                        style={{ marginTop: 24 }}
                    />
                ) : null}
                {isAuthenticated === true && isBooleanTrue(showNotes) ? (
                    <NoteList
                        tableTitle="Stop Notes"
                        noteEntityId={stop.id}
                        noteEntityType="STOP"
                        style={{ marginTop: 24 }}
                    />
                ) : null}
            </>
        );
    } else {
        return null;
    }
};

export default LoadStop;