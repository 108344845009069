import { doesAccountExistByEIN, doesAccountExistByMCNumber, doesAccountExistByDOTNumber, doesAccountExistByName, doesAccountUserExistByEmailAddress, doesAccountUserExistByPhoneNumber, doesUserExistByEmailAddress, doesUserExistByPhoneNumber } from '../api/data';
import { isListNotEmpty, isObjectEmpty, isObjectNotEmpty, isStringNotEmpty } from './objectUtils';

export const onRadioButtonChange = (event) => {
    // console.log(event.target.value);
    return event.target.value;
};

export const onCheckBoxChange = (event) => {
    // console.log(event.target.checked);
    return event.target.checked;
};

export const checkEmailAddressReducer = (state, action) => {
    switch (action.type) {
        case "FETCH_START":
            return {
                ...state,
                isCheckEmailAddressLoading: true,
                checkEmailAddressHasError: false,
                checkEmailAddressError: null,
            };
        case "FETCH_SUCCESS":
            return {
                ...state,
                isCheckEmailAddressLoading: false,
                checkEmailAddressHasError: false,
                checkEmailAddressError: null,
                emailAddressExists: action.payload.exists,
            };
        case "FETCH_FAILURE":
            return {
                ...state,
                isCheckEmailAddressLoading: false,
                checkEmailAddressHasError: true,
                checkEmailAddressError: action.payload.error,
                emailAddressExists: action.payload.exists,
            };
        case "FETCH_CLEAR":
            return {
                ...state,
                isCheckEmailAddressLoading: false,
                checkEmailAddressHasError: false,
                checkEmailAddressError: null,
                emailAddressExists: null,
            };
        default:
            throw new Error();
    }
};

export const checkPhoneNumberReducer = (state, action) => {
    switch (action.type) {
        case "FETCH_START":
            return {
                ...state,
                isCheckPhoneNumberLoading: true,
                checkPhoneNumberHasError: false,
                checkPhoneNumberError: null,
            };
        case "FETCH_SUCCESS":
            return {
                ...state,
                isCheckPhoneNumberLoading: false,
                checkPhoneNumberHasError: false,
                checkPhoneNumberError: null,
                phoneNumberExists: action.payload.exists,
            };
        case "FETCH_FAILURE":
            return {
                ...state,
                isCheckPhoneNumberLoading: false,
                checkPhoneNumberHasError: true,
                checkPhoneNumberError: action.payload.error,
                phoneNumberExists: action.payload.exists,
            };
        case "FETCH_CLEAR":
            return {
                ...state,
                isCheckPhoneNumberLoading: false,
                checkPhoneNumberHasError: false,
                checkPhoneNumberError: null,
                phoneNumberExists: null,
            };
        default:
            throw new Error();
    }
};

export const checkAccountEINReducer = (state, action) => {
    switch (action.type) {
        case "FETCH_START":
            return {
                ...state,
                isCheckAccountEINLoading: true,
                checkAccountEINHasError: false,
                checkAccountEINError: null,
            };
        case "FETCH_SUCCESS":
            return {
                ...state,
                isCheckAccountEINLoading: false,
                checkAccountEINHasError: false,
                checkAccountEINError: null,
                accountEINExists: action.payload.exists,
                accountEINExistingAccount: action.payload.existingAccount
            };
        case "FETCH_FAILURE":
            return {
                ...state,
                isCheckAccountEINLoading: false,
                checkAccountEINHasError: true,
                checkAccountEINError: action.payload.error,
                accountEINExists: action.payload.exists,
                accountEINExistingAccount: action.payload.existingAccount
            };
        case "FETCH_CLEAR":
            return {
                ...state,
                isCheckAccountEINLoading: false,
                checkAccountEINHasError: false,
                checkAccountEINError: null,
                accountEINExists: null,
                accountEINExistingAccount: null
            };
        default:
            throw new Error();
    }
};

export const checkAccountNameReducer = (state, action) => {
    switch (action.type) {
        case "FETCH_START":
            return {
                ...state,
                isCheckAccountNameLoading: true,
                checkAccountNameHasError: false,
                checkAccountNameError: null,
            };
        case "FETCH_SUCCESS":
            return {
                ...state,
                isCheckAccountNameLoading: false,
                checkAccountNameHasError: false,
                checkAccountNameError: null,
                accountNameExists: action.payload.exists,
                accountNameExistingAccount: action.payload.existingAccount
            };
        case "FETCH_FAILURE":
            return {
                ...state,
                isCheckAccountNameLoading: false,
                checkAccountNameHasError: true,
                checkAccountNameError: action.payload.error,
                accountNameExists: action.payload.exists,
                accountNameExistingAccount: action.payload.existingAccount
            };
        case "FETCH_CLEAR":
            return {
                ...state,
                isCheckAccountNameLoading: false,
                checkAccountNameHasError: false,
                checkAccountNameError: null,
                accountNameExists: null,
                accountNameExistingAccount: null
            };
        default:
            throw new Error();
    }
};

export const checkAccountMCNumberReducer = (state, action) => {
    switch (action.type) {
        case "FETCH_START":
            return {
                ...state,
                isCheckAccountMCNumberLoading: true,
                checkAccountMCNumberHasError: false,
                checkAccountMCNumberError: null,
            };
        case "FETCH_SUCCESS":
            return {
                ...state,
                isCheckAccountMCNumberLoading: false,
                checkAccountMCNumberHasError: false,
                checkAccountMCNumberError: null,
                accountMCNumberExists: action.payload.exists,
                accountMCNumberExistingAccount: action.payload.existingAccount
            };
        case "FETCH_FAILURE":
            return {
                ...state,
                isCheckAccountMCNumberLoading: false,
                checkAccountMCNumberHasError: true,
                checkAccountMCNumberError: action.payload.error,
                accountMCNumberExists: action.payload.exists,
                accountMCNumberExistingAccount: action.payload.existingAccount
            };
        case "FETCH_CLEAR":
            return {
                ...state,
                isCheckAccountMCNumberLoading: false,
                checkAccountMCNumberHasError: false,
                checkAccountMCNumberError: null,
                accountMCNumberExists: null,
                accountMCNumberExistingAccount: null
            };
        default:
            throw new Error();
    }
};

export const checkAccountDOTNumberReducer = (state, action) => {
    switch (action.type) {
        case "FETCH_START":
            return {
                ...state,
                isCheckAccountDOTNumberLoading: true,
                checkAccountDOTNumberHasError: false,
                checkAccountDOTNumberError: null,
            };
        case "FETCH_SUCCESS":
            return {
                ...state,
                isCheckAccountDOTNumberLoading: false,
                checkAccountDOTNumberHasError: false,
                checkAccountDOTNumberError: null,
                accountDOTNumberExists: action.payload.exists,
                accountDOTNumberExistingAccount: action.payload.existingAccount
            };
        case "FETCH_FAILURE":
            return {
                ...state,
                isCheckAccountDOTNumberLoading: false,
                checkAccountDOTNumberHasError: true,
                checkAccountDOTNumberError: action.payload.error,
                accountDOTNumberExists: action.payload.exists,
                accountDOTNumberExistingAccount: action.payload.existingAccount
            };
        case "FETCH_CLEAR":
            return {
                ...state,
                isCheckAccountDOTNumberLoading: false,
                checkAccountDOTNumberHasError: false,
                checkAccountDOTNumberError: null,
                accountDOTNumberExists: null,
                accountDOTNumberExistingAccount: null
            };
        default:
            throw new Error();
    }
};

export const checkEmailAddress = async (emailAddress, user = null, dispatch, cancelToken) => {
    dispatch({ type: "FETCH_START" });
    try {
        if ((isObjectNotEmpty(user) && isStringNotEmpty(user.user) && user.user !== emailAddress) || isObjectEmpty(user)) {
            const exists = await doesUserExistByEmailAddress(emailAddress, { cancelToken });
            if (exists === false) {
                dispatch({ type: "FETCH_SUCCESS", payload: { exists: false } });
            } else {
                dispatch({ type: "FETCH_FAILURE", payload: { error: 'A user account already exists with this Email Address.', exists: true } });
            }
        } else {
            dispatch({ type: "FETCH_SUCCESS", payload: { exists: false } });
        }
    } catch (err) {
        dispatch({ type: "FETCH_FAILURE", payload: { error: err.message, exists: null } });
    }
};

export const checkPhoneNumber = async (phoneNumber, user = null, dispatch, cancelToken) => {
    dispatch({ type: "FETCH_START" });
    try {
        if ((isObjectNotEmpty(user) && isStringNotEmpty(user.phone) && user.phone !== phoneNumber) || isObjectEmpty(user)) {
            const exists = await doesUserExistByPhoneNumber(phoneNumber, { cancelToken });
            if (exists === false) {
                dispatch({ type: "FETCH_SUCCESS", payload: { exists: false } });
            } else {
                dispatch({ type: "FETCH_FAILURE", payload: { error: 'A user account already exists with this Phone Number.', exists: true } });
            }
        } else {
            dispatch({ type: "FETCH_SUCCESS", payload: { exists: false } });
        }
    } catch (err) {
        dispatch({ type: "FETCH_FAILURE", payload: { error: err.message, exists: null } });
    }
};

export const checkEmployeeEmailAddress = async (emailAddress, accountId, dispatch, cancelToken) => {
    dispatch({ type: "FETCH_START" });
    try {
        const exists = await doesAccountUserExistByEmailAddress(emailAddress, accountId, { cancelToken });
        if (exists === false) {
            dispatch({ type: "FETCH_SUCCESS", payload: { exists: false } });
        } else {
            dispatch({ type: "FETCH_FAILURE", payload: { error: 'An employee already exists with this Email Address.', exists: true } });
        }
    } catch (err) {
        dispatch({ type: "FETCH_FAILURE", payload: { error: err.message, exists: null } });
    }
};

export const checkEmployeePhoneNumber = async (phoneNumber, accountId, dispatch, cancelToken) => {
    dispatch({ type: "FETCH_START" });
    try {
        const exists = await doesAccountUserExistByPhoneNumber(phoneNumber, accountId, { cancelToken });
        if (exists === false) {
            dispatch({ type: "FETCH_SUCCESS", payload: { exists: false } });
        } else {
            dispatch({ type: "FETCH_FAILURE", payload: { error: 'An employee already exists with this Phone Number.', exists: true } });
        }
    } catch (err) {
        dispatch({ type: "FETCH_FAILURE", payload: { error: err.message, exists: null } });
    }
};

export const checkAccountEIN = async (ein, account = null, allowSelf = true, allowExisting = false, dispatch, cancelToken) => {
    dispatch({ type: "FETCH_START" });
    try {
        if ((isObjectNotEmpty(account) && isStringNotEmpty(account.ein) && account.ein !== ein) || isObjectEmpty(account)) {
            const results = await doesAccountExistByEIN(ein, { cancelToken });
            let existingAccount = null;
            if (isObjectNotEmpty(results) && results.exists === true && isListNotEmpty(results.existingAccounts)) {
                if (isObjectNotEmpty(account) && isStringNotEmpty(account.id) && isObjectNotEmpty(results.existingAccounts.find(a => a.id === account.id))) {
                    existingAccount = results.existingAccounts.find(a => a.id === account.id);

                    if (allowSelf === true) {
                        dispatch({ type: "FETCH_SUCCESS", payload: { exists: true, existingAccount: existingAccount } });
                    } else {
                        dispatch({ type: "FETCH_FAILURE", payload: { error: 'The EIN you provided matches the EIN tied to your account. You cannot link to your own account.', exists: true, existingAccount: existingAccount } });
                    }
                } else if (results.existingAccounts.length > 1) {
                    // if more than one existing account, choose the one that has an exact match since the backend searches based on a contains call to the database
                    existingAccount = results.existingAccounts.find(a => a.ein === ein);
                    if (isObjectEmpty(existingAccount)) {
                        // if no match then take the first result as a fall back
                        existingAccount = results.existingAccounts[0];
                    }

                    if (allowExisting === true) {
                        dispatch({ type: "FETCH_SUCCESS", payload: { exists: true, existingAccount: existingAccount } });
                    } else {
                        dispatch({ type: "FETCH_FAILURE", payload: { error: 'An account already exists with this EIN.', exists: true, existingAccount: existingAccount } });
                    }
                } else {
                    // if results.existingAccounts.length === 1 falls here since this is inside the isListNotEmpty and length wasn't greater than 1 but is greater than zero
                    existingAccount = results.existingAccounts[0];

                    if (allowExisting === true) {
                        dispatch({ type: "FETCH_SUCCESS", payload: { exists: true, existingAccount: existingAccount } });
                    } else {
                        dispatch({ type: "FETCH_FAILURE", payload: { error: 'An account already exists with this EIN.', exists: true, existingAccount: existingAccount } });
                    }
                }
            } else {
                dispatch({ type: "FETCH_SUCCESS", payload: { exists: false, existingAccount: null } });
            }
        } else if (isObjectNotEmpty(account) && isStringNotEmpty(account.ein) && account.ein === ein) {
            if (allowSelf === true) {
                dispatch({ type: "FETCH_SUCCESS", payload: { exists: true, existingAccount: account } });
            } else {
                dispatch({ type: "FETCH_FAILURE", payload: { error: 'The EIN you provided matches the EIN tied to your account. You cannot link to your own account.', exists: true, existingAccount: account } });
            }
        } else {
            dispatch({ type: "FETCH_SUCCESS", payload: { exists: false, existingAccount: null } });
        }
    } catch (err) {
        dispatch({ type: "FETCH_FAILURE", payload: { error: err.message, exists: null } });
    }
};

export const checkAccountName = async (name, account = null, allowSelf = true, allowExisting = false, dispatch, cancelToken) => {
    dispatch({ type: "FETCH_START" });
    try {
        if ((isObjectNotEmpty(account) && isStringNotEmpty(account.name) && account.name !== name) || isObjectEmpty(account)) {
            const results = await doesAccountExistByName(name, { cancelToken });
            let existingAccount = null;
            if (isObjectNotEmpty(results) && results.exists === true && isListNotEmpty(results.existingAccounts)) {
                if (isObjectNotEmpty(account) && isStringNotEmpty(account.id) && isObjectNotEmpty(results.existingAccounts.find(a => a.id === account.id))) {
                    existingAccount = results.existingAccounts.find(a => a.id === account.id);

                    if (allowSelf === true) {
                        dispatch({ type: "FETCH_SUCCESS", payload: { exists: true, existingAccount: existingAccount } });
                    } else {
                        dispatch({ type: "FETCH_FAILURE", payload: { error: 'The Company Name you provided matches the Company Name tied to your account. You cannot link to your own account.', exists: true, existingAccount: existingAccount } });
                    }
                } else if (results.existingAccounts.length > 1) {
                    // if more than one existing account, choose the one that has an exact match since the backend searches based on a contains call to the database
                    existingAccount = results.existingAccounts.find(a => a.name === name);
                    if (isObjectEmpty(existingAccount)) {
                        // if no match then take the first result as a fall back
                        existingAccount = results.existingAccounts[0];
                    }

                    if (allowExisting === true) {
                        dispatch({ type: "FETCH_SUCCESS", payload: { exists: true, existingAccount: existingAccount } });
                    } else {
                        dispatch({ type: "FETCH_FAILURE", payload: { error: 'An account already exists with this Company Name.', exists: true, existingAccount: existingAccount } });
                    }
                } else {
                    // if results.existingAccounts.length === 1 falls here since this is inside the isListNotEmpty and length wasn't greater than 1 but is greater than zero
                    existingAccount = results.existingAccounts[0];

                    if (allowExisting === true) {
                        dispatch({ type: "FETCH_SUCCESS", payload: { exists: true, existingAccount: existingAccount } });
                    } else {
                        dispatch({ type: "FETCH_FAILURE", payload: { error: 'An account already exists with this Company Name.', exists: true, existingAccount: existingAccount } });
                    }
                }
            } else {
                dispatch({ type: "FETCH_SUCCESS", payload: { exists: false, existingAccount: null } });
            }
        } else if (isObjectNotEmpty(account) && isStringNotEmpty(account.name) && account.name === name) {
            if (allowSelf === true) {
                dispatch({ type: "FETCH_SUCCESS", payload: { exists: true, existingAccount: account } });
            } else {
                dispatch({ type: "FETCH_FAILURE", payload: { error: 'The Company Name you provided matches the Company Name tied to your account. You cannot link to your own account.', exists: true, existingAccount: account } });
            }
        } else {
            dispatch({ type: "FETCH_SUCCESS", payload: { exists: false, existingAccount: null } });
        }
    } catch (err) {
        dispatch({ type: "FETCH_FAILURE", payload: { error: err.message, exists: null } });
    }
};

export const checkAccountMCNumber = async (mcNum, account = null, allowSelf = true, allowExisting = false, dispatch, cancelToken) => {
    dispatch({ type: "FETCH_START" });
    try {
        if ((isObjectNotEmpty(account) && isStringNotEmpty(account.mcNum) && account.mcNum !== mcNum) || isObjectEmpty(account)) {
            const results = await doesAccountExistByMCNumber(mcNum, { cancelToken });
            let existingAccount = null;
            if (isObjectNotEmpty(results) && results.exists === true && isListNotEmpty(results.existingAccounts)) {
                if (isObjectNotEmpty(account) && isStringNotEmpty(account.id) && isObjectNotEmpty(results.existingAccounts.find(a => a.id === account.id))) {
                    existingAccount = results.existingAccounts.find(a => a.id === account.id);

                    if (allowSelf === true) {
                        dispatch({ type: "FETCH_SUCCESS", payload: { exists: true, existingAccount: existingAccount } });
                    } else {
                        dispatch({ type: "FETCH_FAILURE", payload: { error: 'The MC Number you provided matches the MC Number tied to your account. You cannot link to your own account.', exists: true, existingAccount: existingAccount } });
                    }
                } else if (results.existingAccounts.length > 1) {
                    // if more than one existing account, choose the one that has an exact match since the backend searches based on a contains call to the database
                    existingAccount = results.existingAccounts.find(a => a.mcNum === mcNum);
                    if (isObjectEmpty(existingAccount)) {
                        // if no match then take the first result as a fall back
                        existingAccount = results.existingAccounts[0];
                    }

                    if (allowExisting === true) {
                        dispatch({ type: "FETCH_SUCCESS", payload: { exists: true, existingAccount: existingAccount } });
                    } else {
                        dispatch({ type: "FETCH_FAILURE", payload: { error: 'An account already exists with this MC Number.', exists: true, existingAccount: existingAccount } });
                    }
                } else {
                    // if results.existingAccounts.length === 1 falls here since this is inside the isListNotEmpty and length wasn't greater than 1 but is greater than zero
                    existingAccount = results.existingAccounts[0];

                    if (allowExisting === true) {
                        dispatch({ type: "FETCH_SUCCESS", payload: { exists: true, existingAccount: existingAccount } });
                    } else {
                        dispatch({ type: "FETCH_FAILURE", payload: { error: 'An account already exists with this MC Number.', exists: true, existingAccount: existingAccount } });
                    }
                }
            } else {
                dispatch({ type: "FETCH_SUCCESS", payload: { exists: false, existingAccount: null } });
            }
        } else if (isObjectNotEmpty(account) && isStringNotEmpty(account.mcNum) && account.mcNum === mcNum) {
            if (allowSelf === true) {
                dispatch({ type: "FETCH_SUCCESS", payload: { exists: true, existingAccount: account } });
            } else {
                dispatch({ type: "FETCH_FAILURE", payload: { error: 'The MC Number you provided matches the MC Number tied to your account. You cannot link to your own account.', exists: true, existingAccount: account } });
            }
        } else {
            dispatch({ type: "FETCH_SUCCESS", payload: { exists: false, existingAccount: null } });
        }
    } catch (err) {
        dispatch({ type: "FETCH_FAILURE", payload: { error: err.message, exists: null } });
    }
};

export const checkAccountDOTNumber = async (dotNum, account = null, allowSelf = true, allowExisting = false, dispatch, cancelToken) => {
    dispatch({ type: "FETCH_START" });
    try {
        if ((isObjectNotEmpty(account) && isStringNotEmpty(account.dotNum) && account.dotNum !== dotNum) || isObjectEmpty(account)) {
            const results = await doesAccountExistByDOTNumber(dotNum, { cancelToken });
            let existingAccount = null;
            if (isObjectNotEmpty(results) && results.exists === true && isListNotEmpty(results.existingAccounts)) {
                if (isObjectNotEmpty(account) && isStringNotEmpty(account.id) && isObjectNotEmpty(results.existingAccounts.find(a => a.id === account.id))) {
                    existingAccount = results.existingAccounts.find(a => a.id === account.id);

                    if (allowSelf === true) {
                        dispatch({ type: "FETCH_SUCCESS", payload: { exists: true, existingAccount: existingAccount } });
                    } else {
                        dispatch({ type: "FETCH_FAILURE", payload: { error: 'The DOT Number you provided matches the DOT Number tied to your account. You cannot link to your own account.', exists: true, existingAccount: existingAccount } });
                    }
                } else if (results.existingAccounts.length > 1) {
                    // if more than one existing account, choose the one that has an exact match since the backend searches based on a contains call to the database
                    existingAccount = results.existingAccounts.find(a => a.dotNum === dotNum);
                    if (isObjectEmpty(existingAccount)) {
                        // if no match then take the first result as a fall back
                        existingAccount = results.existingAccounts[0];
                    }

                    if (allowExisting === true) {
                        dispatch({ type: "FETCH_SUCCESS", payload: { exists: true, existingAccount: existingAccount } });
                    } else {
                        dispatch({ type: "FETCH_FAILURE", payload: { error: 'An account already exists with this DOT Number.', exists: true, existingAccount: existingAccount } });
                    }
                } else {
                    // if results.existingAccounts.length === 1 falls here since this is inside the isListNotEmpty and length wasn't greater than 1 but is greater than zero
                    existingAccount = results.existingAccounts[0];

                    if (allowExisting === true) {
                        dispatch({ type: "FETCH_SUCCESS", payload: { exists: true, existingAccount: existingAccount } });
                    } else {
                        dispatch({ type: "FETCH_FAILURE", payload: { error: 'An account already exists with this DOT Number.', exists: true, existingAccount: existingAccount } });
                    }
                }
            } else {
                dispatch({ type: "FETCH_SUCCESS", payload: { exists: false, existingAccount: null } });
            }
        } else if (isObjectNotEmpty(account) && isStringNotEmpty(account.dotNum) && account.dotNum === dotNum) {
            if (allowSelf === true) {
                dispatch({ type: "FETCH_SUCCESS", payload: { exists: true, existingAccount: account } });
            } else {
                dispatch({ type: "FETCH_FAILURE", payload: { error: 'The DOT Number you provided matches the DOT Number tied to your account. You cannot link to your own account.', exists: true, existingAccount: account } });
            }
        } else {
            dispatch({ type: "FETCH_SUCCESS", payload: { exists: false, existingAccount: null } });
        }
    } catch (err) {
        dispatch({ type: "FETCH_FAILURE", payload: { error: err.message, exists: null } });
    }
};

export const isValidEmailAddress = (emailAddress) => {
    // console.log(emailAddress)
    var pattern = /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i;
    // console.log(pattern.test(emailAddress))
    if (pattern.test(emailAddress)) {
        return true;
    } else {
        return false;
    }
};