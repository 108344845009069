import React from 'react';
import { useSelector } from 'react-redux';
import NoteList from "../../components/NoteList/NoteList";
import { isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import StringFormatter from '../../shared/stringFormatter';
import LoadStopUtils from '../../api/utils/loadStopUtils';

const stringFormatter = new StringFormatter();

const LoadNotes = ({ load, shipperId, carrierId, stops }) => {
    //#region useSelectors

    const entityType = useSelector(state => state.auth.entityType);
    const entityId = useSelector(state => state.auth.entityId);

    //#endregion

    if (isObjectNotEmpty(load) && isStringNotEmpty(load.id)) {
        return (
            <>
                <NoteList
                    tableTitle="Load Notes"
                    noteEntityId={load.id}
                    noteEntityType="LOAD"
                    key={`load-notes-${load.id}`}
                />
                {stops.map((stop) => {
                    const stopName = LoadStopUtils.getStopLocationNameDisplay(stop, shipperId, carrierId, entityType, entityId);
                    const locationCityState = LoadStopUtils.getStopLocationCityStateDisplay(stop);

                    return (
                        <NoteList
                            tableTitle={`Notes for Stop # ${stop.sequence + 1} - ${stringFormatter.toFormattedString("StopType", stop.stopType, null)}${isStringNotEmpty(stopName) ? ' - ' + stopName : ''}${isStringNotEmpty(locationCityState) ? " - " + locationCityState : ''}`}
                            noteEntityId={stop.id}
                            noteEntityType="STOP"
                            style={{ marginTop: 24 }}
                            key={`stop-notes-${stop.id}`}
                        />
                    );
                })}
            </>
        );
    } else {
        return null;
    }
};

export default LoadNotes;