import * as actionTypes from "../actions/actionTypes";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { isBooleanTrue, isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import ErrorUtils from "../../shared/errorUtils";
import * as actionCreators from "./index";

//#region Fetch Invoice List Methods

export const fetchInvoiceListStart = (listName) => {
    return {
        type: actionTypes.FETCH_INVOICE_LIST_START,
        payload: { listName: listName }
    }
};

export const fetchInvoiceListSuccess = (listName, payload) => {
    return {
        type: actionTypes.FETCH_INVOICE_LIST_SUCCESS,
        payload: { listName: listName, ...payload }
    }
};

export const fetchInvoiceListFail = (listName, payload) => {
    return {
        type: actionTypes.FETCH_INVOICE_LIST_FAIL,
        payload: { listName: listName, ...payload }
    }
};

export const clearInvoiceList = (listName) => {
    return {
        type: actionTypes.CLEAR_INVOICE_LIST,
        payload: { listName: listName }
    }
};

export const clearInvoiceLists = () => {
    return {
        type: actionTypes.CLEAR_INVOICE_LISTS
    }
};

export const addInvoiceToInvoiceList = (listName, payload) => {
    return {
        type: actionTypes.ADD_TO_INVOICE_LIST,
        payload: { listName: listName, newRecord: payload }
    }
};

export const updateInvoiceInInvoiceList = (listName, payload) => {
    return {
        type: actionTypes.UPDATE_IN_INVOICE_LIST,
        payload: { listName: listName, updatedRecord: payload }
    }
};

export const removeInvoiceFromInvoiceList = (listName, payload) => {
    return {
        type: actionTypes.REMOVE_FROM_INVOICE_LIST,
        payload: { listName: listName, recordToRemove: payload }
    }
};

//#endregion
//#region Fetch Invoice Methods

export const fetchInvoiceStart = () => {
    return {
        type: actionTypes.FETCH_INVOICE_START
    }
};

export const fetchInvoiceSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_INVOICE_SUCCESS,
        payload: payload
    }
};

export const fetchInvoiceFail = (payload) => {
    return {
        type: actionTypes.FETCH_INVOICE_FAIL,
        payload: payload
    }
};

export const clearInvoice = () => {
    return {
        type: actionTypes.CLEAR_INVOICE
    }
};

//#endregion
//#region Add Invoice Methods

export const addInvoiceStart = () => {
    return {
        type: actionTypes.ADD_INVOICE_START
    }
};

export const addInvoiceSuccess = () => {
    return {
        type: actionTypes.ADD_INVOICE_SUCCESS
    }
};

export const addInvoiceFail = (payload) => {
    return {
        type: actionTypes.ADD_INVOICE_FAIL,
        payload: payload
    }
};

export const addInvoiceLoadingClear = () => {
    return {
        type: actionTypes.ADD_INVOICE_LOADING_CLEAR
    }
};

export const addInvoiceErrorClear = () => {
    return {
        type: actionTypes.ADD_INVOICE_ERROR_CLEAR
    }
};

export const addInvoiceCancel = () => {
    return {
        type: actionTypes.ADD_INVOICE_CANCEL
    }
};

//#endregion
//#region Update Invoice Methods

export const changeSingleInvoice = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_INVOICE,
        payload: payload
    }
};

export const updateInvoiceStart = () => {
    return {
        type: actionTypes.UPDATE_INVOICE_START
    }
};

export const updateInvoiceSuccess = () => {
    return {
        type: actionTypes.UPDATE_INVOICE_SUCCESS
    }
};

export const updateInvoiceFail = (payload) => {
    return {
        type: actionTypes.UPDATE_INVOICE_FAIL,
        payload: payload
    }
};

export const updateInvoiceLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_INVOICE_LOADING_CLEAR
    }
};

export const updateInvoiceErrorClear = () => {
    return {
        type: actionTypes.UPDATE_INVOICE_ERROR_CLEAR
    }
};

export const updateInvoiceCancel = () => {
    return {
        type: actionTypes.UPDATE_INVOICE_CANCEL
    }
};

//#endregion
//#region Invoice Line Item Methods

export const fetchInvoiceList = (listName, payload) => {
    return async (dispatch, getState) => {
        if (isStringNotEmpty(listName)) {
            try {
                dispatch(fetchInvoiceListStart(listName));

                const state = getState();
                const invoicesState = { ...state.invoices };
                const existingLists = { ...invoicesState.lists };

                let pagination = {};
                let searchParams = {};
                if (isObjectNotEmpty(existingLists[listName])) {
                    pagination = { ...existingLists[listName].pagination };
                    searchParams = { ...existingLists[listName].searchParams };
                }

                if (isObjectNotEmpty(payload)) {
                    searchParams = { ...payload };
                }

                const res = await Data.getInvoices({ ...searchParams }, pagination);
                dispatch(fetchInvoiceListSuccess(listName, { records: res.data, params: { searchParams: res.searchParams, pagination: res.pagination } }));
            } catch (error) {
                logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
                dispatch(fetchInvoiceListFail(listName, { error: ErrorUtils.getErrorMessage(error) }));
            }
        }
    }
};

export const fetchInvoice = (id) => {
    return async (dispatch) => {
        try {
            dispatch(fetchInvoiceStart());

            const invoice = await Data.getInvoice(id);
            dispatch(fetchInvoiceSuccess({ record: invoice }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchInvoiceFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const addInvoice = (payload, invoiceCorrections, sendLoadEvent = false) => {
    return async (dispatch) => {
        try {
            dispatch(addInvoiceStart());

            const newInvoice = await Data.addInvoice(payload, sendLoadEvent);
            if (isObjectNotEmpty(newInvoice)) {
                // add note to the invoice so it can get resolved if there are invoiceCorrections
                if (isStringNotEmpty(invoiceCorrections)) {
                    dispatch(actionCreators.addNote({
                        body: invoiceCorrections,
                        entityId: newInvoice.id,
                        entityType: 'INVOICE',
                        visibleTo: newInvoice.toEntityType === 'STAFF' ? newInvoice.fromEntityType : 'ALL'
                    }));
                }

                if (isStringNotEmpty(newInvoice.loadId) && sendLoadEvent === true) {
                    dispatch(actionCreators.loadEventUpdateTimeout(newInvoice.loadId, null, null, null, 'LOAD_UPDATED'));
                }

                if (isStringNotEmpty(newInvoice.fromEntityId)) {
                    dispatch(addInvoiceToInvoiceList(newInvoice.fromEntityId, newInvoice));
                }
                if (isStringNotEmpty(newInvoice.toEntityId)) {
                    dispatch(addInvoiceToInvoiceList(newInvoice.toEntityId, newInvoice));
                }
                if (isStringNotEmpty(newInvoice.loadId)) {
                    dispatch(addInvoiceToInvoiceList(newInvoice.loadId, newInvoice));
                }

                dispatch(fetchInvoiceSuccess({ addRecordId: newInvoice.id }));
            }

            dispatch(addInvoiceSuccess());
            dispatch(addInvoiceLoadingClear());
            dispatch(addInvoiceErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addInvoiceFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateInvoice = (id, payload, oldInvoice, sendLoadEvent = false) => {
    return async (dispatch) => {
        try {
            dispatch(updateInvoiceStart());

            if (isBooleanTrue(payload.isDeleted)) {
                const removedInvoice = await Data.removeInvoice(id, oldInvoice, sendLoadEvent);
                if (isObjectNotEmpty(removedInvoice)) {
                    if (isStringNotEmpty(removedInvoice.loadId) && sendLoadEvent === true) {
                        dispatch(actionCreators.loadEventUpdateTimeout(removedInvoice.loadId, null, null, null, 'LOAD_UPDATED'));
                    }

                    if (isStringNotEmpty(removedInvoice.fromEntityId)) {
                        dispatch(removeInvoiceFromInvoiceList(removedInvoice.fromEntityId, removedInvoice));
                    }
                    if (isStringNotEmpty(removedInvoice.toEntityId)) {
                        dispatch(removeInvoiceFromInvoiceList(removedInvoice.toEntityId, removedInvoice));
                    }
                    if (isStringNotEmpty(removedInvoice.loadId)) {
                        dispatch(removeInvoiceFromInvoiceList(removedInvoice.loadId, removedInvoice));
                    }
                }
            } else {
                const updatedInvoice = await Data.updateInvoice(id, payload, oldInvoice, sendLoadEvent);
                if (isObjectNotEmpty(updatedInvoice)) {
                    if (isStringNotEmpty(updatedInvoice.loadId) && sendLoadEvent === true) {
                        dispatch(actionCreators.loadEventUpdateTimeout(updatedInvoice.loadId, null, null, null, 'LOAD_UPDATED'));
                    }

                    if (isStringNotEmpty(updatedInvoice.fromEntityId)) {
                        dispatch(updateInvoiceInInvoiceList(updatedInvoice.fromEntityId, updatedInvoice));
                    }
                    if (isStringNotEmpty(updatedInvoice.toEntityId)) {
                        dispatch(updateInvoiceInInvoiceList(updatedInvoice.toEntityId, updatedInvoice));
                    }
                    if (isStringNotEmpty(updatedInvoice.loadId)) {
                        dispatch(updateInvoiceInInvoiceList(updatedInvoice.loadId, updatedInvoice));
                    }
                    dispatch(changeSingleInvoice(updatedInvoice));
                }
            }

            dispatch(updateInvoiceSuccess());
            dispatch(updateInvoiceLoadingClear());
            dispatch(updateInvoiceErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateInvoiceFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion