import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Modal, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import classes from './LoadMessages.module.scss';
import MessageReceived from '../MessageReceived/MessageReceived';
import MessageSent from '../MessageSent/MessageSent';
import MessageReceivedWithFile from '../MessageReceivedWithFile/MessageReceivedWithFile';
import MessageSentWithFile from '../MessageSentWithFile/MessageSentWithFile';
import { useSelector, useDispatch } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Document from '../Document/Document';
import Form from '../Form/Form';
import { isListEmpty, isListNotEmpty, isObjectEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { selectListIsLoading, selectListPagination, selectListRecords } from '../../store/utility';

const scrollToRef = (ref) => {
    var element = document.getElementById("messages");

    // needed due to the case where the user switches tabs before the messageThread is updated and then the ref is no longer in the dom
    if (ref.current) {
        if (ref.current.offsetTop) {
            //console.log('current: ' + ref.current.offsetTop);
            element.scrollTo({ left: 0, top: ref.current.offsetTop, behavior: 'smooth' });
            // console.log('executed scroll');
        }
    }
};

const LoadMessages = ({ load, loadId }) => {
    const scrollParentRef = useRef(null);

    const dispatch = useDispatch();
    const entityType = useSelector(state => state.auth.entityType);
    const entityId = useSelector(state => state.auth.entityId);
    const userId = useSelector(state => state.auth.userId);
    const firstName = useSelector(state => state.auth.firstName);
    const lastName = useSelector(state => state.auth.lastName);
    const messageLists = useSelector(state => state.messages.lists);
    const messages = useSelector(state => selectListRecords(state.messages.lists, loadId));
    const isLoading = useSelector(state => selectListIsLoading(state.messages.lists, loadId));
    const pagination = useSelector(state => selectListPagination(state.messages.lists, loadId));
    const isAddMessageLoading = useSelector(state => state.messages.isRecordAddLoading);
    const addMessageError = useSelector(state => state.messages.addRecordError);

    const [showModal, setShowModal] = useState(false);
    const [documentId, setDocumentId] = useState(null);
    const [documentDisplayName, setDocumentDisplayName] = useState(null);
    const [atBottom, setAtBottom] = useState(true);
    const [currentHeight, setCurrentHeight] = useState(0);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [previousMessageCount, setPreviousMessageCount] = useState(0);

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const myRef = useRef(null);
    const executeScroll = () => scrollToRef(myRef);

    const onScroll = () => {
        // var element = document.getElementById("messages");
        // console.log(element.scrollHeight)
        // console.log(element.offsetHeight)
        // console.log(element.clientHeight)
        // console.log(element.offsetTop)
        // console.log(element.scrollTop)
        if (scrollParentRef.current) {
            const bottom = scrollParentRef.current.scrollHeight;
            const scrollTop = scrollParentRef.current.scrollTop;
            // console.log(scrollParentRef.current.scrollHeight);
            // console.log(scrollParentRef.current.offsetHeight);
            // console.log(scrollParentRef.current.clientHeight);
            // console.log(scrollParentRef.current.offsetTop);
            // console.log(scrollParentRef.current.scrollTop);
            const scrollBottom = (scrollParentRef.current.scrollTop + scrollParentRef.current.offsetHeight);
            // console.log(`scrollTop: ${scrollTop}`);
            // console.log(`scrollBottom: ${scrollBottom}`);
            //console.log('current: ' + scrollParentRef.current.scrollTop);
            if (scrollTop === 0 && isLoading === false) {
                //console.log('load more');
                setCurrentHeight(scrollParentRef.current.scrollHeight);
                // console.log('set current height');
                onLoadEarlier();
            }

            if (scrollBottom === bottom) {
                //console.log('reached bottom');
                setAtBottom(true);
            } else {
                setAtBottom(false);
            }
        }
    };

    const createMessages = (messages) => {
        let messageRows = [];

        let count = 0;
        for (const message of messages) {
            let isSent = false;
            if (message.senderId === userId) {
                isSent = true;
            }

            if (isSent == true && isListNotEmpty(message.documentFileIds)) {
                messageRows.push(<MessageSentWithFile key={count++} message={message} />);
            } else if (isSent == true && isListEmpty(message.documentFileIds)) {
                messageRows.push(<MessageSent key={count++} message={message} />);
            } else if (isSent == false && isListNotEmpty(message.documentFileIds)) {
                messageRows.push(<MessageReceivedWithFile key={count++} message={message} />);
            } else if (isSent == false && isListEmpty(message.documentFileIds)) {
                messageRows.push(<MessageReceived key={count++} message={message} />);
            }
        }

        return messageRows;
    };

    const getMessages = () => {
        if (isStringNotEmpty(entityType) && isStringNotEmpty(entityId) && isStringNotEmpty(userId) && isStringNotEmpty(loadId)) {
            let searchParams = {
                page: 1,
                size: 10,
                sort: 'createdAt',
                order: 'desc',
                loadId: loadId
            };

            if (entityType === "STAFF") {
                //searchParams.toStaff = true;
            } else if (entityType === "SHIPPER" || entityType === "PRODUCER" || entityType === "RECEIVER" || entityType === "BROKER" || entityType === "FACTORING") {
                searchParams.accountIds = entityId;
            } else if (entityType === "CARRIER") {
                searchParams['accountIds:or'] = entityId;
                searchParams['userIds:or'] = userId;
            } else if (entityType === "DRIVER" || entityType === "NONE") {
                searchParams.userIds = userId;
            }

            dispatch(actionCreators.fetchMessageList(loadId, searchParams));
        }
    };

    const onLoadEarlier = () => {
        if (isStringNotEmpty(entityType) && isStringNotEmpty(entityId) && isStringNotEmpty(userId) && isStringNotEmpty(loadId)) {
            if (pagination.current + 1 <= pagination.totalPages && isLoading === false) {
                // console.log('get more');
                let searchParams = {
                    page: pagination.current + 1,
                    size: 10,
                    sort: 'createdAt',
                    order: 'desc',
                    loadId: loadId
                };

                if (entityType === "STAFF") {
                    //searchParams.toStaff = true;
                } else if (entityType === "SHIPPER" || entityType === "PRODUCER" || entityType === "RECEIVER" || entityType === "BROKER" || entityType === "FACTORING") {
                    searchParams.accountIds = entityId;
                } else if (entityType === "CARRIER") {
                    searchParams['accountIds:or'] = entityId;
                    searchParams['userIds:or'] = userId;
                } else if (entityType === "DRIVER") {
                    searchParams.userIds = userId;
                }

                dispatch(actionCreators.fetchMoreMessageList(loadId, searchParams));
            } else {
                // console.log('no more items to get');
            }
        }
    };

    const sendMessage = (event) => {
        event.preventDefault();

        const formElements = event.target.elements;
        let messageText = formElements[0].value;

        if (isStringNotEmpty(userId) && isObjectNotEmpty(load) && isStringNotEmpty(load.id)) {
            dispatch(actionCreators.sendMessage([{ text: messageText }], userId, load));
            formElements[0].value = '';
        }
    };

    useMemo(() => {
        if (isAddMessageLoading !== null && isAddMessageLoading === false && addMessageError === null) {
            executeScroll();
        }
    }, [isAddMessageLoading, addMessageError]);

    useEffect(() => {
        if (isStringNotEmpty(entityType) && isStringNotEmpty(entityId) && isStringNotEmpty(userId) && isStringNotEmpty(loadId)) {
            if (isObjectEmpty(messageLists) || (isObjectNotEmpty(messageLists) && isObjectEmpty(messageLists[loadId]))) {
                getMessages();
            }
        }
    }, [messageLists, entityType, entityId, userId, loadId]);

    const onLoadEarlierIsComplete = () => {
        const newHeight = scrollParentRef.current.scrollHeight;
        // console.log(currentHeight);
        // console.log(newHeight);
        if (currentHeight !== newHeight) {
            const loadMoreOffset = newHeight - currentHeight;
            // console.log(`loadMoreOffset: ${loadMoreOffset}`);
            scrollParentRef.current.scrollTo({ left: 0, top: loadMoreOffset, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        // console.log(`atBottom: ${atBottom}`);
        // console.log(`isInitialLoad: ${isInitialLoad}`);

        if (atBottom === true) {
            // console.log('is at the bottom');
            executeScroll();
        } else if (messages.length > previousMessageCount) {
            // console.log(`new messages: ${messages.length - previousMessageCount}`);
            if (messages.length - previousMessageCount === 10) {
                onLoadEarlierIsComplete();
            }
            setPreviousMessageCount(messages.length);
        } else if (isInitialLoad === false) {
            // const newHeight = scrollParentRef.current.scrollHeight;
            // console.log(currentHeight);
            // console.log(newHeight);
            // if (currentHeight !== newHeight) {
            //     const loadMoreOffset = newHeight - currentHeight;
            //     console.log(`loadMoreOffset: ${loadMoreOffset}`);
            //     scrollParentRef.current.scrollTo({ left: 0, top: loadMoreOffset, behavior: 'smooth' });
            // }
            //setIsLoadedMore(false);
        } else if (isInitialLoad === true) {
            // set the initial height if the screen just loaded
            setCurrentHeight(scrollParentRef.current.scrollHeight);
            setIsInitialLoad(false);
            // console.log('set current height');
        }
    }, [messages, atBottom, currentHeight, scrollParentRef.current, isInitialLoad, previousMessageCount]);

    return (
        <>
            <div className={classes.messages}>
                <div className={classes.messageHistory}>
                    <div id="messages" className={classes.messagesContainer} onScroll={onScroll} ref={scrollParentRef}>
                        {createMessages(messages.slice().reverse())}
                        <div style={{ float: "left", clear: "both" }} ref={myRef}></div>
                    </div>
                </div>

                <Form onSubmit={sendMessage}>
                    <div className={classes.typeMessage}>
                        <div className={classes.inputMessageWrite}>
                            <input type="text" id="newMessage" className={classes.writeMessage} name="message" placeholder="Type a message" />
                            <Button className={classes.messageSendButton} type="primary" shape="circle" htmlType="submit"><FontAwesomeIcon icon={faPaperPlane} size="sm" /></Button>
                        </div>
                    </div>
                </Form>
            </div>
            <Modal
                title={documentDisplayName}
                visible={showModal === true}
                width="100%"
                style={{ top: 0 }}
                onCancel={toggleModal}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                maskClosable={false}
            >
                <Document fileId={documentId} />
            </Modal>
        </>
    );
};

export default LoadMessages;