import * as actionTypes from "../actions/actionTypes";
import {
    addRecordFail,
    addRecordStart,
    addRecordSuccess,
    clearAddRecordError,
    clearUpdateRecordError,
    updateRecordFail,
    updateRecordStart,
    updateRecordSuccess,
    clearUpdateRecordLoading,
    clearAddRecordLoading,
    clearRemoveRecordError,
    clearRemoveRecordLoading,
    removeRecordFail,
    removeRecordStart,
    removeRecordSuccess,
    cancelAddRecord,
    cancelUpdateRecord,
    cancelRemoveRecord,
    addRecordToBottom,
    removeRecord,
    updateRecord,
    fetchRecordStart,
    fetchRecordSuccess,
    fetchRecordFail,
    clearRecord,
    updateSingleRecord,
    fetchListStart,
    fetchListSuccess,
    fetchListFail,
    clearList,
    clearAllLists,
    addToBottomOfList,
    updateInList,
    removeFromList,
} from "../utility";

const initialState = {
    lists: {},
    record: null,
    isRecordLoading: false,
    isRecordAddLoading: null,
    isRecordUpdateLoading: null,
    isRecordRemoveLoading: null,
    recordError: null,
    addRecordError: null,
    updateRecordError: null,
    removeRecordError: null,
    addRecordId: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_INVOICE_LINE_ITEM_LIST_START: return fetchListStart(state, action.payload);
        case actionTypes.FETCH_INVOICE_LINE_ITEM_LIST_SUCCESS: return fetchListSuccess(state, action.payload);
        case actionTypes.FETCH_INVOICE_LINE_ITEM_LIST_FAIL: return fetchListFail(state, action.payload);
        case actionTypes.CLEAR_INVOICE_LINE_ITEM_LIST: return clearList(state, action.payload);
        case actionTypes.CLEAR_INVOICE_LINE_ITEM_LISTS: return clearAllLists(state);
        case actionTypes.ADD_TO_INVOICE_LINE_ITEM_LIST: return addToBottomOfList(state, action.payload);
        case actionTypes.UPDATE_IN_INVOICE_LINE_ITEM_LIST: return updateInList(state, action.payload);
        case actionTypes.REMOVE_FROM_INVOICE_LINE_ITEM_LIST: return removeFromList(state, action.payload);
        case actionTypes.FETCH_INVOICE_LINE_ITEM_START: return fetchRecordStart(state);
        case actionTypes.FETCH_INVOICE_LINE_ITEM_SUCCESS: return fetchRecordSuccess(state, action.payload);
        case actionTypes.FETCH_INVOICE_LINE_ITEM_FAIL: return fetchRecordFail(state, action.payload);
        case actionTypes.CLEAR_INVOICE_LINE_ITEM: return clearRecord(state);
        case actionTypes.ADD_INVOICE_LINE_ITEM: return addRecordToBottom(state, action.payload);
        case actionTypes.ADD_INVOICE_LINE_ITEM_START: return addRecordStart(state);
        case actionTypes.ADD_INVOICE_LINE_ITEM_SUCCESS: return addRecordSuccess(state);
        case actionTypes.ADD_INVOICE_LINE_ITEM_FAIL: return addRecordFail(state, action.payload);
        case actionTypes.ADD_INVOICE_LINE_ITEM_LOADING_CLEAR: return clearAddRecordLoading(state);
        case actionTypes.ADD_INVOICE_LINE_ITEM_ERROR_CLEAR: return clearAddRecordError(state);
        case actionTypes.ADD_INVOICE_LINE_ITEM_CANCEL: return cancelAddRecord(state);
        case actionTypes.UPDATE_INVOICE_LINE_ITEM: return updateRecord(state, action.payload);
        case actionTypes.UPDATE_SINGLE_INVOICE_LINE_ITEM: return updateSingleRecord(state, action.payload);
        case actionTypes.UPDATE_INVOICE_LINE_ITEM_START: return updateRecordStart(state);
        case actionTypes.UPDATE_INVOICE_LINE_ITEM_SUCCESS: return updateRecordSuccess(state);
        case actionTypes.UPDATE_INVOICE_LINE_ITEM_FAIL: return updateRecordFail(state, action.payload);
        case actionTypes.UPDATE_INVOICE_LINE_ITEM_LOADING_CLEAR: return clearUpdateRecordLoading(state);
        case actionTypes.UPDATE_INVOICE_LINE_ITEM_ERROR_CLEAR: return clearUpdateRecordError(state);
        case actionTypes.UPDATE_INVOICE_LINE_ITEM_CANCEL: return cancelUpdateRecord(state);
        case actionTypes.REMOVE_INVOICE_LINE_ITEM: return removeRecord(state, action.payload);
        case actionTypes.REMOVE_INVOICE_LINE_ITEM_START: return removeRecordStart(state);
        case actionTypes.REMOVE_INVOICE_LINE_ITEM_SUCCESS: return removeRecordSuccess(state);
        case actionTypes.REMOVE_INVOICE_LINE_ITEM_FAIL: return removeRecordFail(state, action.payload);
        case actionTypes.REMOVE_INVOICE_LINE_ITEM_LOADING_CLEAR: return clearRemoveRecordLoading(state);
        case actionTypes.REMOVE_INVOICE_LINE_ITEM_ERROR_CLEAR: return clearRemoveRecordError(state);
        case actionTypes.REMOVE_INVOICE_LINE_ITEM_CANCEL: return cancelRemoveRecord(state);
        default:
            return state;
    }
};

export default reducer;