import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { DownOutlined } from '@ant-design/icons';
import { Button, Drawer, Dropdown, Menu } from 'antd';
import EditShift from "../../components/EditShift/EditShift";
import NewShift from "../../components/NewShift/NewShift";
import classes from './Shifts.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck, faTools } from '@fortawesome/free-solid-svg-icons';
import DataTable from '../../components/DataTable/DataTable';
import StringFormatter from '../../shared/stringFormatter';
import { withRouter } from 'react-router-dom';
import { isStringNotEmpty } from '../../shared/objectUtils';
import { Can, CanDo } from '../../shared/entitlements/entitlements';
import { selectListIsLoading, selectListPagination, selectListRecords } from '../../store/utility';
import { compareByAlph } from '../../shared/tableUtils';

const stringFormatter = new StringFormatter();

const Shifts = ({ accountId, ...props }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;
    const singularEntityName = "Shift";
    const pluralEntityName = "Shifts";

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);
    const records = useSelector(state => selectListRecords(state.shifts.lists, isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')));
    const isLoading = useSelector(state => selectListIsLoading(state.shifts.lists, isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')));
    const pagination = useSelector(state => selectListPagination(state.shifts.lists, isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')));

    //#endregion
    //#region useStates

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showEditEntity, setShowEditEntity] = useState(false);

    //#endregion
    //#region toggles

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    //#endregion
    //#region goTos

    const goToShiftDashboard = (id) => {
        props.history.push({ pathname: `/shifts/${id}` }, {
            previousPageTitle: "Shifts",
            previousPageLocation: props.location,
            previousBreadcrumbs: props.breadcrumbs,
        });
    };

    const refreshTable = () => {
        let searchParams = {
            page: 1,
            size: 10,
            isDeleted: false,
            sort: 'name',
            order: 'asc'
        };

        if (isStringNotEmpty(accountId)) {
            searchParams.accountId = accountId;
        } else if (isStringNotEmpty(entityId) && entityType !== 'STAFF') {
            searchParams.accountId = entityId;
        }

        dispatch(actionCreators.fetchShiftList(isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL'), searchParams));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchShiftListSuccess(isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL'), { params: { pagination: pager } }));

        let searchParams = {
            page: pagination.current,
            size: pagination.pageSize,
            isDeleted: false
        };

        if (isStringNotEmpty(sorter.order)) {
            const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
            searchParams.order = sortOrder;
        }
        if (isStringNotEmpty(sorter.field)) {
            searchParams.sort = sorter.field;
        }

        if (isStringNotEmpty(accountId)) {
            searchParams.accountId = accountId;
        } else if (isStringNotEmpty(entityId) && entityType !== 'STAFF') {
            searchParams.accountId = entityId;
        }

        dispatch(actionCreators.fetchShiftList(isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL'), searchParams));
    };

    //#endregion
    //#region table displays

    const menu = (record) => {
        return (
            <Menu>
                {CanDo({ entityAction: 'UPDATE', entityModel: 'SHIFT', entityObject: record, accountId: record.accountId }) &&
                    <Menu.Item key="editEntity" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); toggleEditEntity(); }}>
                        <FontAwesomeIcon className="anticon" icon={faCalendarCheck} style={{ marginRight: 8 }} />
                        <span>Edit {singularEntityName} Details</span>
                    </Menu.Item>
                }
                {CanDo({ entityAction: 'READ', entityModel: 'SHIFT', entityObject: record, accountId: record.accountId }) &&
                    <Menu.Item key="goToDashboard" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); goToShiftDashboard(record.id); }}>
                        <span>Go to {singularEntityName} Dashboard</span>
                    </Menu.Item>
                }
            </Menu>
        );
    };

    const columns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            className: classes.columnNoBreak,
            width: 50,
            render: (text, record) => {
                return (
                    <Dropdown overlay={menu(record)} trigger={['click']}>
                        <Button>Actions <DownOutlined /></Button>
                    </Dropdown>
                );
            },
            align: 'center',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => compareByAlph(a.name, b.name),
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'ascend',
            render: (text, record) => {
                return (
                    <div style={{ backgroundColor: record.backgroundColor, color: record.textColor }}>
                        {text}
                    </div>
                );
            },
        },
        {
            title: 'Start Time',
            dataIndex: 'startTime',
            key: 'startTime',
        },
        {
            title: 'End Time',
            dataIndex: 'endTime',
            key: 'endTime',
        },
        {
            title: 'Is End Time Next Day?',
            dataIndex: 'isNextDay',
            key: 'isNextDay',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        },
        {
            title: 'Time Zone',
            dataIndex: 'timeZone',
            key: 'timeZone',
        },
        {
            title: 'Are Employees Available?',
            dataIndex: 'isAvailable',
            key: 'isAvailable',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        },
    ];

    //#endregion
    //#region displays

    const newEntityComponents = (
        <Drawer
            title={"New " + singularEntityName}
            onClose={toggleNewEntity}
            visible={showNewEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewShift
                cancel={toggleNewEntity}
                accountId={isStringNotEmpty(accountId) ? accountId : (isStringNotEmpty(entityId) && entityType !== 'STAFF' ? entityId : null)}
            />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName}
            onClose={toggleEditEntity}
            visible={showEditEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditShift
                cancel={toggleEditEntity}
                shift={selectedRecord}
            />
        </Drawer>
    );

    //#endregion
    //#region useEffects

    useEffect(() => {
        refreshTable();
    }, [accountId, entityId]);

    //#endregion

    return (
        <Can entityAction="READ_LIST" entityModel="SHIFT" accountId={isStringNotEmpty(accountId) ? accountId : (isStringNotEmpty(entityId) && entityType !== 'STAFF' ? entityId : null)}>
            <DataTable
                dataSource={records}
                columns={columns}
                pagination={pagination}
                onChange={handleTableChange}
                loading={isLoading === true}
                rowClassName={classes.dataTableRow}
                singularEntityName={singularEntityName}
                pluralEntityName={pluralEntityName}
                newEntityAction={CanDo({ entityAction: 'CREATE', entityModel: 'SHIFT', accountId: isStringNotEmpty(accountId) ? accountId : (isStringNotEmpty(entityId) && entityType !== 'STAFF' ? entityId : null) }) ? toggleNewEntity : null}
                rowKey={record => record.id}
                style={{ backgroundColor: '#ffffff' }}
            >
                {newEntityComponents}
                {editEntityComponents}
            </DataTable>
        </Can>
    );
};

export default withRouter(Shifts);