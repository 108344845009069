import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const fetchActiveLoadStart = (state) => {
    return updateObject(state, {
        isLoading: true,
        error: null
    });
};

const fetchActiveLoadSuccess = (state, action) => {
    return updateObject(state, {
        ...action,
        isLoading: false,
        error: null
    });
};

const fetchActiveLoadFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        isLoading: false
    });
};

const fetchActiveLoadStopsStart = (state) => {
    return updateObject(state, {
        isActiveLoadStopsLoading: true,
        activeLoadStopsError: null
    });
};

const fetchActiveLoadStopsSuccess = (state, action) => {
    return updateObject(state, {
        ...action,
        isActiveLoadStopsLoading: false,
        activeLoadStopsError: null
    });
};

const fetchActiveLoadStopsFail = (state, action) => {
    return updateObject(state, {
        activeLoadStopsError: action.error,
        isActiveLoadStopsLoading: false
    });
};

const fetchActiveLoadDocumentsStart = (state) => {
    return updateObject(state, {
        isActiveLoadDocumentsLoading: true,
        activeLoadDocumentsError: null
    });
};

const fetchActiveLoadDocumentsSuccess = (state, action) => {
    return updateObject(state, {
        ...action,
        isActiveLoadDocumentsLoading: false,
        activeLoadDocumentsError: null
    });
};

const fetchActiveLoadDocumentsFail = (state, action) => {
    return updateObject(state, {
        activeLoadDocumentsError: action.error,
        isActiveLoadDocumentsLoading: false
    });
};

const updateActiveLoad = (state, action) => {
    return updateObject(state, {
        ...action
    });
};

const clearActiveLoad = (state) => {
    return updateObject(state, {
        isLocationTurnedOn: false,
        isGeoFencingTurnedOn: false,
        activeLoadId: null,
        activeLoadIrisId: null,
        activeLoad: null,
        activeLoadStops: [],
        activeLoadDocuments: [],
        hasLocationPermission: false,
        hasCameraPermission: false,
        hasPushNotificationPermission: false,
        hasMediaLibraryPermission: false
    });
};

const initialState = {
    isLocationTurnedOn: false,
    isGeoFencingTurnedOn: false,
    isLocationServicesEnabled: true,
    activeLoadId: null,
    activeLoadIrisId: null,
    activeLoad: null,
    activeLoadStops: [],
    activeLoadDocuments: [],
    isLoading: false,
    isActiveLoadStopsLoading: false,
    isActiveLoadDocumentsLoading: false,
    activeLoadStopsError: null,
    activeLoadDocumentsError: null,
    error: null,
    hasLocationPermission: false,
    hasCameraPermission: false,
    hasPushNotificationPermission: false,
    hasMediaLibraryPermission: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_ACTIVE_LOAD_START: return fetchActiveLoadStart(state);
        case actionTypes.FETCH_ACTIVE_LOAD_SUCCESS: return fetchActiveLoadSuccess(state, action.payload);
        case actionTypes.FETCH_ACTIVE_LOAD_FAIL: return fetchActiveLoadFail(state, action.payload);
        case actionTypes.UPDATE_ACTIVE_LOAD: return updateActiveLoad(state, action.payload);
        case actionTypes.CLEAR_ACTIVE_LOAD: return clearActiveLoad(state);
        case actionTypes.FETCH_ACTIVE_LOAD_STOPS_START: return fetchActiveLoadStopsStart(state);
        case actionTypes.FETCH_ACTIVE_LOAD_STOPS_SUCCESS: return fetchActiveLoadStopsSuccess(state, action.payload);
        case actionTypes.FETCH_ACTIVE_LOAD_STOPS_FAIL: return fetchActiveLoadStopsFail(state, action.payload);
        case actionTypes.FETCH_ACTIVE_LOAD_DOCUMENTS_START: return fetchActiveLoadDocumentsStart(state);
        case actionTypes.FETCH_ACTIVE_LOAD_DOCUMENTS_SUCCESS: return fetchActiveLoadDocumentsSuccess(state, action.payload);
        case actionTypes.FETCH_ACTIVE_LOAD_DOCUMENTS_FAIL: return fetchActiveLoadDocumentsFail(state, action.payload);
        default:
            return state;
    }
};

export default reducer;