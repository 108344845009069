import React from 'react';
import classes from './DetailsListDataRow.module.scss';

const DetailsListDataRow = ({ label, colSpan = 1, children }) => {
    return (
        <tr className={classes.dataRow}>
            <th className={classes.dataRowLabel}>{label}</th>
            <td className={classes.dataRowContent} colSpan={colSpan}>{children}</td>
        </tr>
    );
};

export default DetailsListDataRow;