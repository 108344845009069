import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const authLogout = (state) => {
    return updateObject(state, {
        token: null,
        email: null,
        userName: null,
        userId: null,
        userProfileId: null,
        entityId: null,
        entityIrisId: null,
        entityType: null,
        isAuthComplete: null,
        isAuthenticated: null,
        error: null,
        errorType: null,
        isLoading: false,
        firstName: null,
        lastName: null,
        profileImageId: null,
        isAdmin: false,
        isStaff: false,
        isVP: false,
        isDirector: false,
        isManager: false,
        featuresList: []
    });
};

const authSuccess = (state, action) => {
    return updateObject(state, {
        ...action,
        isLoading: false,
        error: null,
        errorType: null
    });
};

const authUpdate = (state, action) => {
    return updateObject(state, {
        ...action
    });
};

const authStart = (state) => {
    return updateObject(state, {
        error: null,
        errorType: null,
        isLoading: true
    });
};

const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        errorType: action.errorType,
        isLoading: false,
        isAuthenticated: null,
        isAuthComplete: null
    });
};

const authClearError = (state) => {
    return updateObject(state, {
        error: null,
        errorType: null,
        isLoading: false
    });
};

const initialState = {
    isAuthenticated: null,
    isAuthComplete: null,
    token: null,
    email: null,
    userName: null,
    userId: null,
    userProfileId: null,
    error: null,
    errorType: null,
    isLoading: false,
    entityId: null,
    entityIrisId: null,
    entityType: null,
    firstName: null,
    lastName: null,
    profileImageId: null,
    isAdmin: false,
    isStaff: false,
    isVP: false,
    isDirector: false,
    isManager: false,
    featuresList: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START: return authStart(state);
        case actionTypes.AUTH_UPDATE: return authUpdate(state, action.payload);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action.payload);
        case actionTypes.AUTH_FAIL: return authFail(state, action.payload);
        case actionTypes.AUTH_LOGOUT: return authLogout(state);
        case actionTypes.AUTH_CLEAR_ERROR: return authClearError(state);
        default:
            return state;
    }
};

export default reducer;