import React, { useEffect } from 'react';
import { Timeline, Spin } from 'antd';
import StringFormatter from '../../shared/stringFormatter';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from "../../store/actions/index";
import { isObjectEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { selectListIsLoading, selectListRecords } from '../../store/utility';

const stringFormatter = new StringFormatter();

const LoadNotifications = ({ loadId }) => {
    const dispatch = useDispatch();
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);
    const userId = useSelector(state => state.auth.userId);
    const notificationLists = useSelector(state => state.notifications.lists);
    const notifications = useSelector(state => selectListRecords(state.notifications.lists, loadId));
    const isLoading = useSelector(state => selectListIsLoading(state.notifications.lists, loadId));

    const getNotifications = () => {
        if (isStringNotEmpty(entityType) && isStringNotEmpty(entityId) && isStringNotEmpty(userId) && isStringNotEmpty(loadId)) {
            let searchParams = {
                page: 1,
                size: 1000000,
                sort: 'createdAt',
                order: 'desc',
                loadId: loadId
            };

            if (entityType === "STAFF") {
                searchParams.toStaff = true;
            } else if (entityType === "SHIPPER" || entityType === "PRODUCER" || entityType === "RECEIVER" || entityType === "BROKER" || entityType === "FACTORING") {
                searchParams.accountIds = entityId;
            } else if (entityType === "CARRIER") {
                searchParams['accountIds:or'] = entityId;
                searchParams['userIds:or'] = userId;
            } else if (entityType === "DRIVER" || entityType === "NONE") {
                searchParams.userIds = userId;
            }

            dispatch(actionCreators.fetchNotificationList(loadId, searchParams));
        }
    };

    useEffect(() => {
        if (isStringNotEmpty(entityType) && isStringNotEmpty(entityId) && isStringNotEmpty(userId) && isStringNotEmpty(loadId)) {
            if (isObjectEmpty(notificationLists) || (isObjectNotEmpty(notificationLists) && isObjectEmpty(notificationLists[loadId]))) {
                getNotifications();
            }
        }
    }, [notificationLists, entityType, entityId, userId, loadId]);

    return (
        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true}>
            <Timeline>
                {notifications.map((loadEvent, index) => (
                    <Timeline.Item key={`load-event-${index}`} color={loadEvent.severity === "CRITICAL" ? "red" : "green"}>
                        {stringFormatter.toFormattedString("MomentDateTime", loadEvent.createdAt, null)}<br />
                        <b>{loadEvent.subject}:</b> {loadEvent.message}
                    </Timeline.Item>
                ))}
            </Timeline>
        </Spin>
    );
};

export default LoadNotifications;