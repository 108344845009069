import Checks from '../entitlementUtils';

//#region load

export const canCreateLoad = (auth) => {
    return Checks.allowStaff(auth) || Checks.canView(auth, ['SHIPPER', 'CARRIER', 'BROKER']);
};

export const canReadLoad = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadCarrier(load, auth) || Checks.isLoadShipper(load, auth) || Checks.isLoadDriver(load, auth) || Checks.isLoadBroker(load, auth);
};

export const canReadLoadList = (auth) => {
    return Checks.isAuthenticated(auth);
};

export const canUpdateLoad = (auth, load) => {
    return Checks.allowStaffAdmin(auth) || Checks.isLoadOwner(load, auth);
};

export const canDeleteLoad = (auth) => {
    return Checks.allowStaffAdmin(auth);
};

export const canDuplicateLoad = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadOwner(load, auth);
};

//#endregion
//#region load completion checklist

export const canReadLoadCompletionChecklist = (auth, load) => {
    return Checks.allowStaff(auth) || (Checks.isLoadTMS(load) && Checks.isLoadOwner(load, auth));
};

export const canUpdateLoadCompletionChecklist = (auth, load) => {
    return Checks.allowStaff(auth) || (Checks.isLoadTMS(load) && Checks.isLoadOwner(load, auth));
};

//#endregion
//#region load invoices

export const canCreateLoadInvoice = (auth, load) => {
    return Checks.allowStaff(auth) || (Checks.isLoadShipper(load, auth) && Checks.isLoadTMS(load)) || (Checks.isLoadCarrier(load, auth) && Checks.isLoadTMS(load));
};

export const canReadLoadInvoice = (auth, load) => {
    return Checks.allowStaff(auth) || (Checks.isLoadShipper(load, auth) && Checks.isLoadTMS(load)) || (Checks.isLoadCarrier(load, auth) && Checks.isLoadTMS(load));
};

export const canReadLoadInvoiceList = (auth, load) => {
    return Checks.allowStaff(auth) || (Checks.isLoadShipper(load, auth) && Checks.isLoadTMS(load)) || (Checks.isLoadCarrier(load, auth) && Checks.isLoadTMS(load));
};

export const canUpdateLoadInvoice = (auth, load) => {
    return Checks.allowStaff(auth) || (Checks.isLoadShipper(load, auth) && Checks.isLoadTMS(load)) || (Checks.isLoadCarrier(load, auth) && Checks.isLoadTMS(load));
};

export const canDeleteLoadInvoice = (auth, load) => {
    return Checks.allowStaff(auth) || (Checks.isLoadShipper(load, auth) && Checks.isLoadTMS(load)) || (Checks.isLoadCarrier(load, auth) && Checks.isLoadTMS(load));
};

//#endregion
//#region load carrier invoices

export const canCreateLoadCarrierInvoice = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadCarrier(load, auth) || (Checks.isLoadShipper(load, auth) && Checks.isLoadTMS(load) && Checks.isLoadOwner(load, auth));
};

export const canReadLoadCarrierInvoice = (auth, load) => {
    return Checks.allowStaff(auth) || (Checks.isLoadShipper(load, auth) && Checks.isLoadTMS(load)) || Checks.isLoadCarrier(load, auth);
};

export const canReadLoadCarrierInvoiceList = (auth, load) => {
    return Checks.allowStaff(auth) || (Checks.isLoadShipper(load, auth) && Checks.isLoadTMS(load)) || Checks.isLoadCarrier(load, auth);
};

export const canUpdateLoadCarrierInvoice = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadCarrier(load, auth) || (Checks.isLoadShipper(load, auth) && Checks.isLoadTMS(load) && Checks.isLoadOwner(load, auth));
};

export const canDeleteLoadCarrierInvoice = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadCarrier(load, auth) || (Checks.isLoadShipper(load, auth) && Checks.isLoadTMS(load) && Checks.isLoadOwner(load, auth));
};

//#endregion
//#region load shipper invoices

export const canCreateLoadShipperInvoice = (auth, load) => {
    return Checks.allowStaff(auth);
};

export const canReadLoadShipperInvoice = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadShipper(load, auth);
};

export const canReadLoadShipperInvoiceList = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadShipper(load, auth);
};

export const canUpdateLoadShipperInvoice = (auth, load) => {
    return Checks.allowStaff(auth);
};

export const canDeleteLoadShipperInvoice = (auth, load) => {
    return Checks.allowStaff(auth);
};

//#endregion
//#region load invoice line items

export const canCreateLoadInvoiceLineItem = (auth, load) => {
    return Checks.allowStaff(auth) || (Checks.isLoadShipper(load, auth) && Checks.isLoadTMS(load)) || (Checks.isLoadCarrier(load, auth) && Checks.isLoadTMS(load));
};

export const canReadLoadInvoiceLineItem = (auth, load) => {
    return Checks.allowStaff(auth) || (Checks.isLoadShipper(load, auth) && Checks.isLoadTMS(load)) || (Checks.isLoadCarrier(load, auth) && Checks.isLoadTMS(load));
};

export const canReadLoadInvoiceLineItemList = (auth, load) => {
    return Checks.allowStaff(auth) || (Checks.isLoadShipper(load, auth) && Checks.isLoadTMS(load)) || (Checks.isLoadCarrier(load, auth) && Checks.isLoadTMS(load));
};

export const canUpdateLoadInvoiceLineItem = (auth, load) => {
    return Checks.allowStaff(auth) || (Checks.isLoadShipper(load, auth) && Checks.isLoadTMS(load)) || (Checks.isLoadCarrier(load, auth) && Checks.isLoadTMS(load));
};

export const canDeleteLoadInvoiceLineItem = (auth, load) => {
    return Checks.allowStaff(auth) || (Checks.isLoadShipper(load, auth) && Checks.isLoadTMS(load)) || (Checks.isLoadCarrier(load, auth) && Checks.isLoadTMS(load));
};

//#endregion
//#region load transactions

export const canCreateLoadTransaction = (auth, load) => {
    return Checks.allowStaff(auth) || (Checks.isLoadShipper(load, auth) && Checks.isLoadTMS(load)) || (Checks.isLoadCarrier(load, auth) && Checks.isLoadTMS(load));
};

export const canReadLoadTransaction = (auth, load) => {
    return Checks.allowStaff(auth) || (Checks.isLoadShipper(load, auth) && Checks.isLoadTMS(load)) || (Checks.isLoadCarrier(load, auth) && Checks.isLoadTMS(load));
};

export const canReadLoadTransactionList = (auth, load) => {
    return Checks.allowStaff(auth) || (Checks.isLoadShipper(load, auth) && Checks.isLoadTMS(load)) || (Checks.isLoadCarrier(load, auth) && Checks.isLoadTMS(load));
};

export const canUpdateLoadTransaction = (auth, load) => {
    return Checks.allowStaff(auth) || (Checks.isLoadShipper(load, auth) && Checks.isLoadTMS(load)) || (Checks.isLoadCarrier(load, auth) && Checks.isLoadTMS(load));
};

export const canDeleteLoadTransaction = (auth, load) => {
    return Checks.allowStaff(auth) || (Checks.isLoadShipper(load, auth) && Checks.isLoadTMS(load)) || (Checks.isLoadCarrier(load, auth) && Checks.isLoadTMS(load));
};

//#endregion
//#region load price confirmation

export const canCreateLoadPriceConfirmation = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadShipper(load, auth) || (Checks.isLoadCarrier(load, auth) && Checks.isLoadTMS(load) && Checks.isLoadOwner(load, auth));
};

export const canReadLoadPriceConfirmation = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadShipper(load, auth) || (Checks.isLoadCarrier(load, auth) && Checks.isLoadTMS(load));
};

export const canReadLoadPriceConfirmationList = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadShipper(load, auth) || (Checks.isLoadCarrier(load, auth) && Checks.isLoadTMS(load));
};

export const canUpdateLoadPriceConfirmation = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadShipper(load, auth) || (Checks.isLoadCarrier(load, auth) && Checks.isLoadTMS(load) && Checks.isLoadOwner(load, auth));
};

export const canDeleteLoadPriceConfirmation = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadShipper(load, auth) || (Checks.isLoadCarrier(load, auth) && Checks.isLoadTMS(load) && Checks.isLoadOwner(load, auth));
};

//#endregion
//#region load rate confirmation

export const canCreateLoadRateConfirmation = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadCarrier(load, auth) || (Checks.isLoadShipper(load, auth) && Checks.isLoadTMS(load) && Checks.isLoadOwner(load, auth));
};

export const canReadLoadRateConfirmation = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadCarrier(load, auth) || (Checks.isLoadShipper(load, auth) && Checks.isLoadTMS(load));
};

export const canReadLoadRateConfirmationList = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadCarrier(load, auth) || (Checks.isLoadShipper(load, auth) && Checks.isLoadTMS(load));
};

export const canUpdateLoadRateConfirmation = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadCarrier(load, auth) || (Checks.isLoadShipper(load, auth) && Checks.isLoadTMS(load) && Checks.isLoadOwner(load, auth));
};

export const canDeleteLoadRateConfirmation = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadCarrier(load, auth) || (Checks.isLoadShipper(load, auth) && Checks.isLoadTMS(load) && Checks.isLoadOwner(load, auth));
};

//#endregion
