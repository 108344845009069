import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { DownOutlined, InfoOutlined, MessageOutlined } from '@ant-design/icons';
import { Button, Card, Tooltip, Typography, Modal, Spin, message, Dropdown, Menu } from 'antd';
import classes from './QueueInTransitLoads.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools, faExternalLinkAlt, faRedoAlt } from '@fortawesome/free-solid-svg-icons';
import StringFormatter from '../../shared/stringFormatter';
import { compareByAlph } from '../../shared/tableUtils';
import DataTable from '../DataTable/DataTable';
import { sendLoadNotification } from '../../api/data';
import { isListNotEmpty, isObjectNotEmpty } from '../../shared/objectUtils';
import logger from '../../shared/logger';
import { withRouter } from 'react-router';

const { Title } = Typography;
const stringFormatter = new StringFormatter();

const QueueInTransitLoads = ({ style = null, headStyle = null, ...props }) => {
    const title = 'In-Transit Loads Without Recent Location Data';
    const singularEntityName = "In Transit Load";

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.metrics.inTransitLoadsWithoutLocationDataIsLoading);
    const data = useSelector(state => state.metrics.inTransitLoadsWithoutLocationData);
    const pagination = useSelector(state => state.metrics.inTransitLoadsWithoutLocationDataPagination);

    const [showTableModal, setShowTableModal] = useState(false);

    const toggleTableModal = () => {
        setShowTableModal(!showTableModal);
    };

    const goToLoad = (id) => {
        props.history.push({ pathname: `/loads/${id}` });
    };

    const confirmNotification = (load) => {
        //console.log(e);
        //message.success('Click on Yes');
        sendNotificationToDrivers(load);
    };

    const sendNotificationToDrivers = (load) => {
        if (isObjectNotEmpty(load) && isListNotEmpty(load.drivers)) {
            load.drivers.forEach((accountUser) => {
                let newNotification = {
                    message: `Hi ${accountUser.firstName}, it appears that we have not received location data from you in a while for Load: ${load.irisId}. If you closed the app, please re-open it. If you have stopped, please go to Change Status and Add a Stop. Thanks!`,
                    loadId: load.loadId,
                    loadIrisId: load.irisId,
                    saveOnly: false,
                    subject: `${load.irisId} Need Location Data`,
                    severity: "CRITICAL",
                    userIds: [accountUser.userId],
                    loadStatus: load.loadStatus,
                    eventType: 'NEED_LOCATION_DATA'
                };

                sendLoadNotification(newNotification).then(() => {
                    //console.log(res);
                    message.success('Push Notification was sent successfully!');
                }).catch(err => {
                    message.error('Failed to send the push notification.');
                    logger.logDebugEvent('QueueInTransitLoads.js', err.message, true);
                });
            });
        }
    };

    const refreshQueue = () => {
        let searchParams = {
            page: 1,
            size: 5,
            sort: 'lastUpdateTimeStamp',
            order: 'asc'
        };

        dispatch(actionCreators.fetchInTransitLoadsWithoutLocationData(searchParams));
    };

    const handleTableChange = (pagination) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchInTransitLoadsWithoutLocationDataSuccess({ inTransitLoadsWithoutLocationDataPagination: pager }));

        let searchParams = {
            page: pagination.current,
            size: pagination.pageSize,
            sort: 'lastUpdateTimeStamp',
            order: 'asc'
        };

        dispatch(actionCreators.fetchInTransitLoadsWithoutLocationData(searchParams));
    };

    const menu = (record) => {
        return (
            <Menu>
                <Menu.Item key="sendNotificationToDrivers" onClick={(e) => { e.domEvent.stopPropagation(); e.domEvent.preventDefault(); confirmNotification(record); }}>
                    <MessageOutlined style={{ marginRight: 8 }} />
                    <span>Send Notification to Driver(s)</span>
                </Menu.Item>
                <Menu.Item key="goToDashboard" onClick={(e) => { e.domEvent.stopPropagation(); e.domEvent.preventDefault(); goToLoad(record.loadId); }}>
                    <InfoOutlined style={{ marginRight: 8 }} />
                    <span>View Load Details</span>
                </Menu.Item>
            </Menu>
        );
    };

    const columns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            width: 50,
            render: (text, record) => {
                return (
                    <Dropdown overlay={menu(record)} trigger={['click']}>
                        <Button>Actions <DownOutlined /></Button>
                    </Dropdown>
                );
            },
            align: 'center',
            className: classes.columnNoBreak
        },
        {
            title: 'Load Id',
            dataIndex: 'irisId',
            key: 'irisId',
            sorter: (a, b) => compareByAlph(a.irisId, b.irisId),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Pick-Up Date',
            dataIndex: 'pickUpDateTime',
            key: 'pickUpDateTime',
            sorter: (a, b) => compareByAlph(a.pickUpDateTime, b.pickUpDateTime),
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'ascend',
            render: (text, record) => { return record.origin !== undefined && record.origin !== null ? stringFormatter.toFormattedString("MomentDate", text, null, record.origin.timeZone) : ''; },
        },
        {
            title: 'Origin',
            dataIndex: ['origin', 'stopLocation', 'name'],
            key: 'origin.stopLocation.name',
        },
        {
            title: 'Destination',
            dataIndex: ['destination', 'stopLocation', 'name'],
            key: 'destination.stopLocation.name',
        },
        {
            title: 'Status',
            dataIndex: 'loadStatus',
            key: 'loadStatus',
            filters: [
                {
                    text: 'In Transit',
                    value: 'IN_TRANSIT',
                },
                {
                    text: 'At Stop',
                    value: 'AT_STOP',
                }
            ],
            filterMultiple: true,
            defaultFilteredValue: ["IN_TRANSIT", "AT_STOP"],
            onFilter: (value, record) => record.loadStatus.indexOf(value) === 0,
            sorter: (a, b) => compareByAlph(a.loadStatus, b.loadStatus),
            sortDirections: ['descend', 'ascend'],
            render: (text) => { return stringFormatter.toFormattedString("LoadStatus", text, null); },
        },
        {
            title: 'Time Lapse Since Last Location Data',
            dataIndex: 'timeLapseWithoutLocationData',
            key: 'timeLapseWithoutLocationData',
            render: (text) => { return stringFormatter.toFormattedString("MinutesCountdown", text, null); },
        },
        {
            title: 'Last Location Timestamp',
            dataIndex: 'lastUpdateTimeStamp',
            key: 'lastUpdateTimeStamp',
            render: (text) => { return stringFormatter.toFormattedString("MomentDateTime", text, null); },
        },
        {
            title: 'Shipper',
            dataIndex: ['shipper', 'name'],
            key: 'shipper.name',
        },
        {
            title: 'Carrier',
            dataIndex: ['assignedCarrier', 'name'],
            key: 'assignedCarrier.name',
        }
    ];

    const buttons = (
        <>
            <Tooltip placement="top" title="Refresh Queue">
                <Button type="primary" shape='circle' onClick={refreshQueue} style={{ marginRight: 8 }}>
                    <FontAwesomeIcon icon={faRedoAlt} />
                </Button>
            </Tooltip>
            <Tooltip placement="top" title="Open Queue">
                <Button type="primary" shape='circle' onClick={toggleTableModal}>
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                </Button>
            </Tooltip>
        </>
    );

    useEffect(() => {
        refreshQueue();
    }, []);

    return (
        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true}>
            <Card bordered={true} className={classes.card} style={style} headStyle={headStyle} title={title} extra={buttons} size="small">
                {isObjectNotEmpty(pagination) ? (
                    <Typography>
                        <div><Title level={3}>{pagination.total}</Title></div>
                    </Typography>
                ) : (
                        <Typography>
                            <div><Title level={3}>0</Title></div>
                        </Typography>
                    )}
            </Card>
            <Modal
                title={title}
                visible={showTableModal === true}
                width="100%"
                style={{ top: 0 }}
                onCancel={toggleTableModal}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                maskClosable={false}
            >
                <DataTable
                    dataSource={data}
                    columns={columns}
                    pagination={pagination}
                    loading={isLoading === true}
                    onChange={handleTableChange}
                    style={{ backgroundColor: '#ffffff' }}
                    rowKey={record => record.loadId}
                />
            </Modal>
        </Spin>
    );
};

export default withRouter(QueueInTransitLoads);