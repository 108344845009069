import React, { useState, useMemo } from 'react';
import { Card, Drawer, Button, Modal, Spin } from "antd";
import DataRow from "../../components/DataRow/DataRow";
import classes from './LoadDriverCard.module.scss';
import EditDriver from "../../components/EditDriver/EditDriver";
import AssignLoadDriver from "../../components/AssignLoadDriver/AssignLoadDriver";
import ChangeLoadDriver from "../../components/ChangeLoadDriver/ChangeLoadDriver";
import RemoveLoadDriver from "../../components/RemoveLoadDriver/RemoveLoadDriver";
import AddEditChangeRemoveButtons from '../../components/AddEditChangeRemoveButtons/AddEditChangeRemoveButtons';
import DocumentList from '../../components/DocumentList/DocumentList';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions";
import DataPhoneNumberRow from '../DataPhoneNumberRow/DataPhoneNumberRow';
import { isBooleanFalse, isBooleanTrue, isNullOrUndefined, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { CanSee } from '../../shared/entitlements/entitlements';
import LoadUtils from '../../api/utils/loadUtils';

const missingEntityStyle = {
    backgroundColor: '#fff1f0',
    border: '1px solid #ffa39e',
};

const missingEntityHeadStyle = {
    borderBottom: '1px solid #ffa39e',
};

const LoadDriverCard = ({ load, driverAccountUser, carrierId, driverId, isApproved = false, title, style = {}, headStyle = {} }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    const singularEntityName = "Driver";

    //#endregion
    //#region useDispatch and useSelections

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.accountUsers.isRecordUpdateLoading);
    const error = useSelector(state => state.accountUsers.updateRecordError);

    //#endregion
    //#region useStates

    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showChangeEntity, setShowChangeEntity] = useState(false);
    const [showRemoveEntity, setShowRemoveEntity] = useState(false);
    const [showDocumentsModal, setShowDocumentsModal] = useState(false);
    const [missingDocumentsCount, setMissingDocumentsCount] = useState(0);
    const [pendingApprovalCount, setPendingApprovalCount] = useState(0);
    const [expiringSoonDocumentsCount, setExpiringSoonDocumentsCount] = useState(0);
    const [expiredDocumentsCount, setExpiredDocumentsCount] = useState(0);

    //#endregion
    //#region toggles

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleChangeEntity = () => {
        setShowChangeEntity(!showChangeEntity);
    };

    const toggleRemoveEntity = () => {
        setShowRemoveEntity(!showRemoveEntity);
    };

    const toggleDocumentsModal = () => {
        setShowDocumentsModal(!showDocumentsModal);
    };

    //#endregion
    //#region displays

    const extra = isObjectNotEmpty(load) ? (
        <>
            <CanSee entityAction="CREATE" entityModel="LOAD_DRIVER" entityObject={load}>
                <AddEditChangeRemoveButtons
                    showAdd={isNullOrUndefined(driverId) && isStringNotEmpty(carrierId) && LoadUtils.loadStatusIsNot(load.loadStatus, ['CREATED', 'PENDING', 'APPROVED', 'PENDING_RATE_CONFIRMATION', 'COMPLETED', 'REOPENED', 'CANCELLED', 'CLOSED']) ? true : false} addText={"Assign " + singularEntityName + " to Load"} addAction={(e) => { e.stopPropagation(); toggleNewEntity(); }}
                    showEdit={false}
                    showChange={false}
                    showRemove={false}
                />
            </CanSee>
            <CanSee entityAction="UPDATE" entityModel="LOAD_DRIVER" entityObject={load}>
                <AddEditChangeRemoveButtons
                    showAdd={false}
                    showEdit={false} editText={"Edit " + singularEntityName + " Details"} editAction={(e) => { e.stopPropagation(); toggleEditEntity(); }}
                    showChange={isStringNotEmpty(driverId) && isStringNotEmpty(carrierId) && LoadUtils.loadStatusIsNot(load.loadStatus, ['COMPLETED', 'CLOSED', 'REOPENED', 'CANCELLED']) ? true : false} changeText={"Change " + singularEntityName} changeAction={(e) => { e.stopPropagation(); toggleChangeEntity(); }}
                    showRemove={isStringNotEmpty(driverId) && isStringNotEmpty(carrierId) && LoadUtils.loadStatusIsNot(load.loadStatus, ['COMPLETED', 'CLOSED', 'REOPENED', 'CANCELLED', 'IN_TRANSIT', 'AT_STOP']) ? true : false} removeText={"Remove " + singularEntityName + " from Load"} removeAction={(e) => { e.stopPropagation(); toggleRemoveEntity(); }}
                />
            </CanSee>
        </>
    ) : null;

    const newEntityComponents = (
        <Drawer
            title={"Assign " + singularEntityName + " to Load"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleNewEntity}
            visible={showNewEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <AssignLoadDriver
                cancel={toggleNewEntity}
                carrierId={carrierId}
                load={load}
            />
        </Drawer>
    );

    const changeEntityComponents = (
        <Drawer
            title={"Change " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleChangeEntity}
            visible={showChangeEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <ChangeLoadDriver
                cancel={toggleChangeEntity}
                driver={driverAccountUser}
                carrierId={carrierId}
                load={load}
            />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName + " Details"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditEntity}
            visible={showEditEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditDriver
                cancel={toggleEditEntity}
                accountUser={driverAccountUser}
            />
        </Drawer>
    );

    const removeEntityComponents = (
        <Drawer
            title={"Remove " + singularEntityName + " from Load"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleRemoveEntity}
            visible={showRemoveEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <RemoveLoadDriver
                cancel={toggleRemoveEntity}
                driver={driverAccountUser}
                carrierId={carrierId}
                load={load}
            />
        </Drawer>
    );

    const approveDriver = () => {
        dispatch(actionCreators.approveDriver(driverId));
    };

    useMemo(() => {
        if (isLoading !== null && isLoading === false) {
            // close documents modal after the driver is updated
            toggleDocumentsModal();
            // fetch the load to refresh the load with the updated driver information
            dispatch(actionCreators.fetchLoadWithIncludes(load.id));
        }
    }, [isLoading]);

    const documentsComponent = (
        <Modal
            title="Driver Documents"
            visible={showDocumentsModal === true}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleDocumentsModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            {isStringNotEmpty(driverId) ? (
                <DocumentList
                    documentEntityType="DRIVER"
                    documentEntityId={driverId}
                    updatePendingApprovalCount={setPendingApprovalCount}
                    updateMissingDocumentsCount={setMissingDocumentsCount}
                    updateExpiringSoonDocumentsCount={setExpiringSoonDocumentsCount}
                    updateExpiredDocumentsCount={setExpiredDocumentsCount}
                    loadId={load.id}
                />
            ) : null}
            {/* {isStringNotEmpty(driverId) && isObjectNotEmpty(driverAccountUser) && isObjectNotEmpty(driverAccountUser.driver) && isBooleanFalse(driverAccountUser.driver.isApproved) ? (
                <CanSee entityAction="VERIFY" entityModel="ACCOUNT_USER" entityObject={driverAccountUser} accountId={driverAccountUser.accountId}>
                    <Button style={{ marginTop: 20 }} type='primary' onClick={approveDriver} disabled={pendingApprovalCount > 0 || missingDocumentsCount > 0 || expiredDocumentsCount > 0}>Approve Driver</Button>
                </CanSee>
            ) : null} */}
        </Modal>
    );

    const renderDriverDetails = () => {
        if (isObjectNotEmpty(driverAccountUser) && isObjectNotEmpty(driverAccountUser.driver)) {
            return (
                <>
                    <DataRow title="Driver ID" value={driverAccountUser.driver.irisId} dataType="String" />
                    <DataRow title="Name" value={driverAccountUser.firstName + ' ' + driverAccountUser.lastName} dataType="String" />
                    <DataRow title="Email" value={driverAccountUser.email} dataType="String" />
                    <DataPhoneNumberRow title="Phone" phone={driverAccountUser.workPhone} phoneExt={driverAccountUser.workPhoneExt} />
                    <DataRow title="Currently Available" value={driverAccountUser.driver.isAvailable} dataType="Boolean" />
                    {/* <DataRow title="IRIS Rating" value={props.irisRating} dataType="String" /> */}
                    <div><b>Documents:</b> <Button type="link" onClick={toggleDocumentsModal}>view</Button></div>
                    {/* {isBooleanFalse(isApproved) ? (
                        <CanSee entityAction="VERIFY" entityModel="ACCOUNT_USER" entityObject={driverAccountUser} accountId={driverAccountUser.accountId}>
                            <Button style={{ marginTop: 20 }} type='primary' onClick={toggleDocumentsModal}>Approve Driver</Button>
                        </CanSee>
                    ) : null} */}
                </>
            );
        } else {
            return <i>No Driver Assigned.</i>
        }
    };

    if (isObjectNotEmpty(load) && LoadUtils.loadStatusIs(load.loadStatus, ['BOOKED', 'SCHEDULED', 'IN_TRANSIT', 'AT_STOP', 'COMPLETED', 'CLOSED', 'REOPENED', 'CANCELLED'])) {
        return (
            <CanSee entityAction="READ" entityModel="LOAD_DRIVER" entityObject={load}>
                <Card title={title} bordered={true} className={classes.card} style={isStringNotEmpty(driverId) ? style : { ...style, ...missingEntityStyle }} headStyle={isStringNotEmpty(driverId) ? headStyle : { ...headStyle, ...missingEntityHeadStyle }} extra={extra}>
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                        {renderDriverDetails()}
                    </Spin>
                    {editEntityComponents}
                    {newEntityComponents}
                    {changeEntityComponents}
                    {removeEntityComponents}
                    {documentsComponent}
                </Card>
            </CanSee>
        );
    } else {
        return null;
    }
};

export default LoadDriverCard;