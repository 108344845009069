import { isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import EntityUtils from '../entityUtils';

export const getLinkedAccountDTO = (linkedAccount, linkedAccountDocuments = [], entityId = null) => {
    linkedAccount.linkedAccountId = linkedAccount.id;

    if (isListNotEmpty(linkedAccountDocuments)) {
        let filteredLinkedAccountDocuments = linkedAccountDocuments.filter(document => document.entityId === linkedAccount.id);
        linkedAccount.documents = filteredLinkedAccountDocuments;

        let { logoFileId } = EntityUtils.getAccountFileIdsAndDates(filteredLinkedAccountDocuments);
        linkedAccount.logoFileId = isStringNotEmpty(logoFileId) ? logoFileId : null;
    }

    if (isStringNotEmpty(entityId)) {
        if (isListNotEmpty(linkedAccount.links)) {
            let link = linkedAccount.links.find(l => l.accountId === entityId);
            if (isObjectNotEmpty(link)) {
                linkedAccount.accountId = link.accountId;
                linkedAccount.customerId = link.customerId;
                
                if (isStringNotEmpty(link.name)) {
                    linkedAccount.name = link.name;
                }

                linkedAccount.pointOfContact = link.pointOfContact;
                linkedAccount.accountsPayable = link.accountsPayable;
                linkedAccount.accountsPayableAddress = link.accountsPayableAddress;
                linkedAccount.accountsReceivable = link.accountsReceivable;
                linkedAccount.accountsReceivableAddress = link.accountsReceivableAddress;
            }
        }
    }

    return linkedAccount;
};