import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { DownloadOutlined, FileTextOutlined, DownOutlined, EditOutlined } from '@ant-design/icons';
import { Modal, Drawer, Button, Menu, Dropdown } from 'antd';
import AccountRegistration from "../../components/AccountRegistration/AccountRegistration";
import EditAccountRegistration from "../../components/EditAccountRegistration/EditAccountRegistration";
import { faTools, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from './AccountRegistrations.module.scss';
import StringFormatter from '../../shared/stringFormatter';
import EditAccountRegistrationReviewStatus from '../../components/EditAccountRegistrationReviewStatus/EditAccountRegistrationReviewStatus';
import DataTable from '../../components/DataTable/DataTable';
import { compareByAlph } from '../../shared/tableUtils';
import NoteList from '../../components/NoteList/NoteList';
import NewAccountModal from '../../components/NewAccountModal/NewAccountModal';
import { isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { CanDo } from '../../shared/entitlements/entitlements';

const stringFormatter = new StringFormatter();

const AccountRegistrations = (props) => {
    //#region constants

    const fullWidth = global.window.innerWidth;
    const singularEntityName = "Account Application";
    const pluralEntityName = "Account Applications";

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.accountRegistrations.isLoading);
    const records = useSelector(state => state.accountRegistrations.records);
    const pagination = useSelector(state => state.accountRegistrations.pagination);

    //#endregion
    //#region useStates

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showEditReviewStatusEntity, setShowEditReviewStatusEntity] = useState(false);
    const [showNotesModal, setShowNotesModal] = useState(false);

    //#endregion
    //#region toggles

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleEditReviewStatusEntity = () => {
        setShowEditReviewStatusEntity(!showEditReviewStatusEntity);
    };

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleNotesModal = () => {
        setShowNotesModal(!showNotesModal);
    };

    //#endregion
    //#region table methods

    const setRowClassName = (record) => {
        return record.isReviewed === undefined || record.isReviewed === null || record.isReviewed === false || record.reviewStatus !== 'APPROVED' ? classes.unverifiedRow : classes.dataTableRow;
    };

    const refreshTable = () => {
        let searchParams = {
            page: 1,
            size: 5,
            sort: 'createdAt',
            order: 'desc',
            isDeleted: false
        };

        let defaultReviewStatusFilter = ['PENDING', 'APPROVED', 'CONTACTED'];
        searchParams.reviewStatus = defaultReviewStatusFilter.join('|');

        dispatch(actionCreators.fetchAccountRegistrations(searchParams));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchAccountRegistrationsSuccess({ pagination: pager }));

        let searchParams = {
            page: pagination.current,
            size: pagination.pageSize,
            isDeleted: false
        };

        if (isStringNotEmpty(sorter.order)) {
            const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
            searchParams.order = sortOrder;
        }
        if (isStringNotEmpty(sorter.field)) {
            searchParams.sort = sorter.field;
        }

        if (isListNotEmpty(filters.reviewStatus)) {
            searchParams.reviewStatus = filters.reviewStatus.join('|');
        }

        dispatch(actionCreators.fetchAccountRegistrations(searchParams));
    };

    //#endregion
    //#region table displays

    const menu = (record) => {
        return (
            <Menu>
                {CanDo({ entityAction: 'UPDATE', entityModel: 'ACCOUNT_REGISTRATION', entityObject: record }) ? (
                    <Menu.Item key="manageNotes" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); toggleNotesModal(); }}>
                        <FileTextOutlined style={{ marginRight: 8 }} />
                        <span>Open Notes</span>
                    </Menu.Item>
                ) : null}
                {(record.reviewStatus !== "IMPORTED" || record.isReviewed === false) && CanDo({ entityAction: 'UPDATE', entityModel: 'ACCOUNT_REGISTRATION', entityObject: record }) ? (
                    <Menu.Item key="reviewAccountRegistration" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); toggleEditReviewStatusEntity(); }}>
                        <FontAwesomeIcon className="anticon" icon={faCheck} style={{ marginRight: 8 }} />
                        <span>Review Account Application</span>
                    </Menu.Item>
                ) : null}
                {CanDo({ entityAction: 'UPDATE', entityModel: 'ACCOUNT_REGISTRATION', entityObject: record }) ? (
                    <Menu.Item key="editAccountRegistration" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); toggleEditEntity(); }}>
                        <EditOutlined style={{ marginRight: 8 }} />
                        <span>Edit Account Application Details</span>
                    </Menu.Item>
                ) : null}
                {(record.reviewStatus === "APPROVED" || record.reviewStatus === "CONTACTED") && CanDo({ entityAction: 'UPDATE', entityModel: 'ACCOUNT_REGISTRATION', entityObject: record }) ? (
                    <Menu.Item key="importAccoutRegistration" onClick={
                        (e) => {
                            e.domEvent.stopPropagation();
                            setSelectedRecord(record);
                            props.history.push({
                                pathname: `/newAccount`,
                                state: {
                                    accountTypes: record.entityTypes,
                                    accountRegistrationId: record.id
                                }
                            });
                        }
                    }>
                        <DownloadOutlined style={{ marginRight: 8 }} />
                        <span>Import Account</span>
                    </Menu.Item>
                ) : null}
            </Menu>
        );
    };

    const columns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            width: 50,
            render: (text, record) => {
                return (
                    <Dropdown overlay={menu(record)} trigger={['click']}>
                        <Button>Actions <DownOutlined /></Button>
                    </Dropdown>
                );
            },
            align: 'center',
            className: classes.columnNoBreak
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Account Type(s)',
            dataIndex: 'entityTypes',
            key: 'entityTypes',
            render: (text, record) => {
                if (isListNotEmpty(text)) {
                    return text.map((entityType) => { return stringFormatter.toFormattedString("EntityType", entityType, null); }).join(', ');
                } else {
                    return '';
                }
            },
        },
        {
            title: 'Is Reviewed',
            dataIndex: 'isReviewed',
            key: 'isReviewed',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        },
        {
            title: 'Review Status',
            dataIndex: 'reviewStatus',
            key: 'reviewStatus',
            filters: [
                {
                    text: 'Pending',
                    value: 'PENDING',
                },
                {
                    text: 'Approved',
                    value: 'APPROVED',
                },
                {
                    text: 'Rejected',
                    value: 'REJECTED',
                },
                {
                    text: 'Contacted',
                    value: 'CONTACTED',
                },
                {
                    text: 'Imported',
                    value: 'IMPORTED',
                }
            ],
            filterMultiple: true,
            defaultFilteredValue: ["PENDING", "APPROVED", "CONTACTED"],
            onFilter: (value, record) => record.reviewStatus.indexOf(value) === 0,
            render: (text, record) => { return stringFormatter.toFormattedString("AccountRegistrationReviewStatus", text, null); },
        },
        {
            title: 'Submission Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: (a, b) => compareByAlph(a.createdAt, b.createdAt),
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'ascend',
            render: (text, record) => { return stringFormatter.toFormattedString("MomentDateTime", text, null); },
        },
    ];

    //#endregion
    //#region displays

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName + " Details"}
            onClose={toggleEditEntity}
            visible={showEditEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditAccountRegistration
                cancel={toggleEditEntity}
                accountRegistration={selectedRecord}
            />
        </Drawer>
    );

    const editReviewStatusEntityComponents = (
        <Drawer
            title={"Review " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditReviewStatusEntity}
            visible={showEditReviewStatusEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditAccountRegistrationReviewStatus
                cancel={toggleEditReviewStatusEntity}
                accountRegistration={selectedRecord}
            />
        </Drawer>
    );

    const notesComponent = (
        <Modal
            title="Account Application Notes"
            visible={showNotesModal === true}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleNotesModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            {isObjectNotEmpty(selectedRecord) ? (
                <NoteList
                    noteEntityType="ACCOUNT_REGISTRATION"
                    noteEntityId={selectedRecord.id}
                />
            ) : null}
        </Modal>
    );

    //#endregion
    //#region useEffects

    useEffect(() => {
        refreshTable();
    }, []);

    //#endregion

    return (
        <DataTable
            dataSource={records}
            columns={columns}
            loading={isLoading === true}
            pagination={pagination}
            onChange={handleTableChange}
            expandedRowRender={record => (<AccountRegistration accountRegistration={record} />)}
            expandRowByClick={true}
            rowClassName={setRowClassName}
            singularEntityName={singularEntityName}
            pluralEntityName={pluralEntityName}
            newEntityAction={CanDo({ entityAction: 'CREATE', entityModel: 'ACCOUNT' }) ? toggleNewEntity : null}
            rowKey={record => record.id}
            style={{ backgroundColor: '#ffffff' }}
        >
            <NewAccountModal showModal={showNewEntity} toggleModal={toggleNewEntity} />
            {editEntityComponents}
            {editReviewStatusEntityComponents}
            {notesComponent}
        </DataTable>
    );
};

export default withRouter(AccountRegistrations);