import React from 'react';
import DataRow from '../DataRow/DataRow';
import { Col, Row } from 'antd';
import { isNumberNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';

const Claim = ({ claim }) => {
    if (isObjectNotEmpty(claim)) {
        return (
            <Row>
                <Col xs={24} md={24} lg={12} xl={12}>
                    {isStringNotEmpty(claim.irisId) ? (<DataRow title="Claim ID" value={claim.irisId} dataType="String" />) : null}
                    <DataRow title="Is Against Carrier" value={claim.isAgainstCarrier} dataType="Boolean" />
                    <DataRow title="Is Against Shipper" value={claim.isAgainstShipper} dataType="Boolean" />
                    {isStringNotEmpty(claim.claimStatus) ? (<DataRow title="Claim Status" value={claim.claimStatus} dataType="ClaimStatus" />) : null}
                    {isStringNotEmpty(claim.comments) ? (<DataRow title="Comments" value={claim.comments} dataType="String" />) : null}
                    {isNumberNotEmpty(claim.count) ? (<DataRow title="Count" value={claim.count} dataType="String" />) : null}
                </Col>
                <Col xs={24} md={24} lg={12} xl={12}>
                    {isStringNotEmpty(claim.bolStatus) ? (<DataRow title="BOL Status" value={claim.bolStatus} dataType="BOLStatus" />) : null}
                    {isStringNotEmpty(claim.irisLoadId) ? (<DataRow title="Load ID" value={claim.irisLoadId} dataType="String" />) : null}
                    {isNumberNotEmpty(claim.adjustment) ? (<DataRow title="Adjustment Amount" value={claim.adjustment} dataType="Money" />) : null}
                    {isStringNotEmpty(claim.bolNumber) ? (<DataRow title="BOL #" value={claim.bolNumber} dataType="String" />) : null}
                </Col>
            </Row>
        );
    } else {
        return null;
    }
};

export default Claim;