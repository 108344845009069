import * as actionTypes from "../actions/actionTypes";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { isBooleanTrue, isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import * as actionCreators from "./index";
import ErrorUtils from "../../shared/errorUtils";

//#region Fetch Linked Account List Methods

export const fetchLinkedAccountListStart = (listName) => {
    return {
        type: actionTypes.FETCH_LINKED_ACCOUNT_LIST_START,
        payload: { listName: listName }
    }
};

export const fetchLinkedAccountListSuccess = (listName, payload) => {
    return {
        type: actionTypes.FETCH_LINKED_ACCOUNT_LIST_SUCCESS,
        payload: { listName: listName, ...payload }
    }
};

export const fetchLinkedAccountListFail = (listName, payload) => {
    return {
        type: actionTypes.FETCH_LINKED_ACCOUNT_LIST_FAIL,
        payload: { listName: listName, ...payload }
    }
};

export const clearLinkedAccountList = (listName) => {
    return {
        type: actionTypes.CLEAR_LINKED_ACCOUNT_LIST,
        payload: { listName: listName }
    }
};

export const clearLinkedAccountLists = () => {
    return {
        type: actionTypes.CLEAR_LINKED_ACCOUNT_LISTS
    }
};

export const addLinkedAccountToLinkedAccountList = (listName, payload) => {
    return {
        type: actionTypes.ADD_TO_LINKED_ACCOUNT_LIST,
        payload: { listName: listName, newRecord: payload }
    }
};

export const updateLinkedAccountInLinkedAccountList = (listName, payload) => {
    return {
        type: actionTypes.UPDATE_IN_LINKED_ACCOUNT_LIST,
        payload: { listName: listName, updatedRecord: payload }
    }
};

export const removeLinkedAccountFromLinkedAccountList = (listName, payload) => {
    return {
        type: actionTypes.REMOVE_FROM_LINKED_ACCOUNT_LIST,
        payload: { listName: listName, recordToRemove: payload }
    }
};

//#endregion
//#region Fetch Linked Account Methods

export const fetchLinkedAccountStart = () => {
    return {
        type: actionTypes.FETCH_LINKED_ACCOUNT_START
    }
};

export const fetchLinkedAccountSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_LINKED_ACCOUNT_SUCCESS,
        payload: payload
    }
};

export const fetchLinkedAccountFail = (payload) => {
    return {
        type: actionTypes.FETCH_LINKED_ACCOUNT_FAIL,
        payload: payload
    }
};

export const clearLinkedAccount = () => {
    return {
        type: actionTypes.CLEAR_LINKED_ACCOUNT
    }
};

//#endregion
//#region Add Linked Account Methods

export const addLinkedAccountStart = () => {
    return {
        type: actionTypes.ADD_LINKED_ACCOUNT_START
    }
};

export const addLinkedAccountSuccess = () => {
    return {
        type: actionTypes.ADD_LINKED_ACCOUNT_SUCCESS
    }
};

export const addLinkedAccountFail = (payload) => {
    return {
        type: actionTypes.ADD_LINKED_ACCOUNT_FAIL,
        payload: payload
    }
};

export const addLinkedAccountLoadingClear = () => {
    return {
        type: actionTypes.ADD_LINKED_ACCOUNT_LOADING_CLEAR
    }
};

export const addLinkedAccountErrorClear = () => {
    return {
        type: actionTypes.ADD_LINKED_ACCOUNT_ERROR_CLEAR
    }
};

export const addLinkedAccountCancel = () => {
    return {
        type: actionTypes.ADD_LINKED_ACCOUNT_CANCEL
    }
};

//#endregion
//#region Update Linked Account Methods

const changeSingleLinkedAccount = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_LINKED_ACCOUNT,
        payload: payload
    }
};

export const updateLinkedAccountStart = () => {
    return {
        type: actionTypes.UPDATE_LINKED_ACCOUNT_START
    }
};

export const updateLinkedAccountSuccess = () => {
    return {
        type: actionTypes.UPDATE_LINKED_ACCOUNT_SUCCESS
    }
};

export const updateLinkedAccountFail = (payload) => {
    return {
        type: actionTypes.UPDATE_LINKED_ACCOUNT_FAIL,
        payload: payload
    }
};

export const updateLinkedAccountLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_LINKED_ACCOUNT_LOADING_CLEAR
    }
};

export const updateLinkedAccountErrorClear = () => {
    return {
        type: actionTypes.UPDATE_LINKED_ACCOUNT_ERROR_CLEAR
    }
};

export const updateLinkedAccountCancel = () => {
    return {
        type: actionTypes.UPDATE_LINKED_ACCOUNT_CANCEL
    }
};

//#endregion
//#region Linked Accounts Methods

export const fetchLinkedAccountList = (listName, payload, entityId = null) => {
    return async (dispatch, getState) => {
        if (isStringNotEmpty(listName)) {
            try {
                dispatch(fetchLinkedAccountListStart(listName));

                const state = getState();
                const linkedAccountsState = { ...state.linkedAccounts };
                const existingLists = { ...linkedAccountsState.lists };

                let pagination = {};
                let searchParams = {};
                if (isObjectNotEmpty(existingLists[listName])) {
                    pagination = { ...existingLists[listName].pagination };
                    searchParams = { ...existingLists[listName].searchParams };
                }

                if (isObjectNotEmpty(payload)) {
                    searchParams = { ...payload };
                }

                const res = await Data.getLinkedAccounts({ ...searchParams }, pagination, entityId);
                console.log(res.data)
                dispatch(fetchLinkedAccountListSuccess(listName, { records: res.data, params: { searchParams: res.searchParams, pagination: res.pagination } }));
            } catch (error) {
                logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
                dispatch(fetchLinkedAccountListFail(listName, { error: ErrorUtils.getErrorMessage(error) }));
            }
        }
    }
};

export const fetchLinkedAccount = (id, entityId = null) => {
    return async (dispatch) => {
        try {
            dispatch(fetchLinkedAccountStart());

            const linkedAccount = await Data.getLinkedAccount(id, entityId);
            dispatch(fetchLinkedAccountSuccess({ record: linkedAccount }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchLinkedAccountFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const fetchLinkedAccountByAccountIdAndLinkedAccountId = (accountId, linkedAccountId) => {
    return async (dispatch) => {
        try {
            dispatch(fetchLinkedAccountStart());

            const linkedAccount = await Data.getLinkedAccountByAccountIdAndLinkedAccountId(accountId, linkedAccountId);
            dispatch(fetchLinkedAccountSuccess({ record: linkedAccount }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchLinkedAccountFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const addLinkedAccount = (payload) => {
    return async (dispatch) => {
        try {
            dispatch(addLinkedAccountStart());

            const newLinkedAccount = await Data.addLinkedAccount(payload)
            if (isObjectNotEmpty(newLinkedAccount)) {
                if (isListNotEmpty(newLinkedAccount.entityTypes)) {
                    newLinkedAccount.entityTypes.forEach((entityType) => {
                        dispatch(addLinkedAccountToLinkedAccountList(`${payload.accountId}_${entityType}S`, newLinkedAccount));
                    });
                }

                dispatch(addLinkedAccountToLinkedAccountList(`${payload.accountId}_ALL`, newLinkedAccount));

                // refresh accounts orchestrator
                dispatch(actionCreators.getAccounts(true));
                dispatch(actionCreators.getLinkedAccounts(payload.accountId, true));
            }

            dispatch(addLinkedAccountSuccess());
            dispatch(addLinkedAccountErrorClear());
            dispatch(addLinkedAccountLoadingClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addLinkedAccountFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateLinkedAccount = (id, payload) => {
    return async (dispatch) => {
        try {
            dispatch(updateLinkedAccountStart());

            if (isBooleanTrue(payload.isDeleted)) {
                const removedLinkedAccount = await Data.removeLinkedAccount(id);
                if (isObjectNotEmpty(removedLinkedAccount)) {
                    if (isListNotEmpty(removedLinkedAccount.entityTypes)) {
                        removedLinkedAccount.entityTypes.forEach((entityType) => {
                            dispatch(removeLinkedAccountFromLinkedAccountList(`${payload.accountId}_${entityType}S`, removedLinkedAccount));
                        });
                    }

                    dispatch(removeLinkedAccountFromLinkedAccountList(`${payload.accountId}_ALL`, removedLinkedAccount));

                    // refresh accounts orchestrator
                    dispatch(actionCreators.getAccounts(true));
                    dispatch(actionCreators.getLinkedAccounts(payload.accountId, true));
                }
            } else {
                const updatedLinkedAccount = await Data.updateLinkedAccount(id, payload);
                if (isObjectNotEmpty(updatedLinkedAccount)) {
                    if (isListNotEmpty(updatedLinkedAccount.entityTypes)) {
                        updatedLinkedAccount.entityTypes.forEach((entityType) => {
                            dispatch(updateLinkedAccountInLinkedAccountList(`${payload.accountId}_${entityType}S`, updatedLinkedAccount));
                        });
                    }

                    dispatch(updateLinkedAccountInLinkedAccountList(`${payload.accountId}_ALL`, updatedLinkedAccount));

                    dispatch(changeSingleLinkedAccount(updatedLinkedAccount));

                    // refresh accounts orchestrator
                    dispatch(actionCreators.getAccounts(true));
                    dispatch(actionCreators.getLinkedAccounts(payload.accountId, true));
                }
            }

            dispatch(updateLinkedAccountSuccess());
            dispatch(updateLinkedAccountLoadingClear());
            dispatch(updateLinkedAccountErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateLinkedAccountFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion