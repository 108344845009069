import React from "react";
import { Col, Row, Input } from "antd";
import FormItem from "../FormItem/FormItem";
import FormItemDouble from "../FormItemDouble/FormItemDouble";
import { isStringNotEmpty } from "../../shared/objectUtils";
import FormItemPenta from "../FormItemPenta/FormItemPenta";
import MaskedInput from 'antd-mask-input';

const FormItemPointOfContact = ({ format = 'vertical', required = false, name, defaultValue, nameFieldLabel = 'Name', firstNameFieldName = 'firstName', lastNameFieldName = 'lastName', emailFieldLabel = 'Email', emailFieldName = 'email', phoneFieldLabel = 'Phone #', phoneExtFieldLabel = '', phoneFieldName = 'phone', phoneExtFieldName = 'phoneExt', showName = true, showEmail = true, showPhone = true }) => {
    //#region styles

    const formItemLayout = {
        labelCol: { sm: 24, md: 24 },
        wrapperCol: { sm: 24, md: 24 },
    };

    const formItemLayoutHorizontal = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const formItemLayoutDouble = {
        labelCol1: { sm: 24, md: 24 },
        wrapperCol1: { sm: 16, md: 16 },
        labelCol2: { span: 0 },
        wrapperCol2: { sm: 8, md: 8 },
    };

    const formItemLayoutDouble2 = {
        labelCol1: { sm: 24, md: 12 },
        wrapperCol1: { sm: 24, md: 12 },
        labelCol2: { sm: 24, md: 12 },
        wrapperCol2: { sm: 24, md: 12 },
    };

    const formItemLayoutDoubleHorizontal = {
        labelCol1: { span: 8 },
        wrapperCol1: { span: 8 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 8 },
    };

    const formItemLayoutPenta = {
        labelCol1: { span: 10 },
        wrapperCol1: { span: 5 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 5 },
        labelCol3: { span: 6 },
        wrapperCol3: { span: 6 },
        labelCol4: { span: 8 },
        wrapperCol4: { span: 5 },
        labelCol5: { span: 0 },
        wrapperCol5: { span: 3 },
    };

    //#endregion

    if (format === 'vertical') {
        return (
            <Row gutter={[8, 8]} style={{ marginBottom: 8 }}>
                <Col span={24}>
                    {showName ? (
                        <FormItemDouble {...formItemLayoutDouble2} label1={nameFieldLabel} format="vertical" required1={required} required2={required}
                            render1={({ onChange, onBlur, value, name, ref }) => (
                                <Input
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e.target.value); }}
                                    value={value}
                                    name={name}
                                    placeholder="First Name"
                                    ref={ref}
                                />
                            )}
                            rules1={{ required: (required ? 'Required Field' : false) }}
                            name1={`${isStringNotEmpty(name) ? name + '.' : ''}${firstNameFieldName}`}
                            defaultValue1={defaultValue && defaultValue[firstNameFieldName] ? defaultValue[firstNameFieldName] : ""}
                            render2={({ onChange, onBlur, value, name, ref }) => (
                                <Input
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e.target.value); }}
                                    value={value}
                                    name={name}
                                    placeholder="Last Name"
                                    ref={ref}
                                />
                            )}
                            rules2={{ required: (required ? 'Required Field' : false) }}
                            name2={`${isStringNotEmpty(name) ? name + '.' : ''}${lastNameFieldName}`}
                            defaultValue2={defaultValue && defaultValue[lastNameFieldName] ? defaultValue[lastNameFieldName] : ""}
                        />
                    ) : null}
                    {showEmail ? (
                        <FormItem {...formItemLayout} label={emailFieldLabel} format="vertical" required={required}
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Input
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e.target.value); }}
                                    value={value}
                                    name={name}
                                    placeholder={emailFieldLabel}
                                    ref={ref}
                                />
                            )}
                            rules={{
                                required: (required ? 'Required Field' : false),
                                pattern: {
                                    value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                    message: "Please enter a valid email address"
                                }
                            }}
                            name={`${isStringNotEmpty(name) ? name + '.' : ''}${emailFieldName}`}
                            defaultValue={defaultValue && defaultValue[emailFieldName] ? defaultValue[emailFieldName] : ""}
                        />
                    ) : null}
                    {showPhone ? (
                        <FormItemDouble {...formItemLayoutDouble} label1={phoneFieldLabel} label2={phoneExtFieldLabel} format="vertical" required1={required} noColon2
                            render1={({ onChange, onBlur, value, name, ref }) => (
                                <MaskedInput
                                    onBlur={onBlur}
                                    onChange={e => {
                                        let value = null;
                                        if (isStringNotEmpty(e.target.value)) {
                                            value = e.target.value.replace(/\s/g, '').replace(' ', '').replace('_', '').replace('-', '').replace('(', '').replace(')', '');
                                        }
                                        onChange(value);
                                    }}
                                    value={value}
                                    name={name}
                                    placeholder={phoneFieldLabel}
                                    mask="(111) 111-1111"
                                    ref={ref}
                                />
                            )}
                            rules1={{
                                required: (required ? 'Required Field' : false),
                                pattern: {
                                    value: /^\d{10}$/,
                                    message: "Please enter a valid 10 digit phone number with no special characters"
                                }
                            }}
                            name1={`${isStringNotEmpty(name) ? name + '.' : ''}${phoneFieldName}`}
                            defaultValue1={defaultValue && defaultValue[phoneFieldName] ? defaultValue[phoneFieldName] : ""}
                            render2={({ onChange, onBlur, value, name, ref }) => (
                                <Input
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e.target.value); }}
                                    value={value}
                                    name={name}
                                    placeholder="Ext"
                                    ref={ref}
                                />
                            )}
                            rules2={{ required: false }}
                            name2={`${isStringNotEmpty(name) ? name + '.' : ''}${phoneExtFieldName}`}
                            defaultValue2={defaultValue && defaultValue[phoneExtFieldName] ? defaultValue[phoneExtFieldName] : ""}
                        />
                    ) : null}
                </Col>
            </Row>
        );
    } else if (format === 'horizontal') {
        return (
            <Row gutter={[8, 8]} style={{ marginBottom: 8 }}>
                <Col span={24}>
                    {showName ? (
                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1={nameFieldLabel} format="horizontal" required1={required} required2={required}
                            render1={({ onChange, onBlur, value, name, ref }) => (
                                <Input
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e.target.value); }}
                                    value={value}
                                    name={name}
                                    placeholder="First Name"
                                    ref={ref}
                                />
                            )}
                            rules1={{ required: (required ? 'Required Field' : false) }}
                            name1={`${isStringNotEmpty(name) ? name + '.' : ''}${firstNameFieldName}`}
                            defaultValue1={defaultValue && defaultValue[firstNameFieldName] ? defaultValue[firstNameFieldName] : ""}
                            render2={({ onChange, onBlur, value, name, ref }) => (
                                <Input
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e.target.value); }}
                                    value={value}
                                    name={name}
                                    placeholder="Last Name"
                                    ref={ref}
                                />
                            )}
                            rules2={{ required: (required ? 'Required Field' : false) }}
                            name2={`${isStringNotEmpty(name) ? name + '.' : ''}${lastNameFieldName}`}
                            defaultValue2={defaultValue && defaultValue[lastNameFieldName] ? defaultValue[lastNameFieldName] : ""}
                        />
                    ) : null}
                    {showEmail ? (
                        <FormItem {...formItemLayoutHorizontal} label={emailFieldLabel} format="horizontal" required={required}
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Input
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e.target.value); }}
                                    value={value}
                                    name={name}
                                    placeholder={emailFieldLabel}
                                    ref={ref}
                                />
                            )}
                            rules={{
                                required: (required ? 'Required Field' : false),
                                pattern: {
                                    value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                    message: "Please enter a valid email address"
                                }
                            }}
                            name={`${isStringNotEmpty(name) ? name + '.' : ''}${emailFieldName}`}
                            defaultValue={defaultValue && defaultValue[emailFieldName] ? defaultValue[emailFieldName] : ""}
                        />
                    ) : null}
                    {showPhone ? (
                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1={phoneFieldLabel} label2={phoneExtFieldLabel} format="horizontal" required1={required} noColon2
                            render1={({ onChange, onBlur, value, name, ref }) => (
                                <MaskedInput
                                    onBlur={onBlur}
                                    onChange={e => {
                                        let value = null;
                                        if (isStringNotEmpty(e.target.value)) {
                                            value = e.target.value.replace(/\s/g, '').replace(' ', '').replace('_', '').replace('-', '').replace('(', '').replace(')', '');
                                        }
                                        onChange(value);
                                    }}
                                    value={value}
                                    name={name}
                                    placeholder={phoneFieldLabel}
                                    mask="(111) 111-1111"
                                    ref={ref}
                                />
                            )}
                            rules1={{
                                required: (required ? 'Required Field' : false),
                                pattern: {
                                    value: /^\d{10}$/,
                                    message: "Please enter a valid 10 digit phone number with no special characters"
                                }
                            }}
                            name1={`${isStringNotEmpty(name) ? name + '.' : ''}${phoneFieldName}`}
                            defaultValue1={defaultValue && defaultValue[phoneFieldName] ? defaultValue[phoneFieldName] : ""}
                            render2={({ onChange, onBlur, value, name, ref }) => (
                                <Input
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e.target.value); }}
                                    value={value}
                                    name={name}
                                    placeholder="Ext"
                                    ref={ref}
                                />
                            )}
                            rules2={{ required: false }}
                            name2={`${isStringNotEmpty(name) ? name + '.' : ''}${phoneExtFieldName}`}
                            defaultValue2={defaultValue && defaultValue[phoneExtFieldName] ? defaultValue[phoneExtFieldName] : ""}
                        />
                    ) : null}
                </Col>
            </Row>
        );
    } else if (format === 'penta') {
        return (
            <Row gutter={[8, 8]} style={{ marginBottom: 8 }}>
                <Col span={24}>
                    <FormItemPenta {...formItemLayoutPenta}
                        label1={nameFieldLabel}
                        required1={required}
                        render1={({ onChange, onBlur, value, name, ref }) => (
                            <Input
                                onBlur={onBlur}
                                onChange={e => { onChange(e.target.value); }}
                                value={value}
                                name={name}
                                placeholder="First Name"
                                ref={ref}
                            />
                        )}
                        rules1={{ required: required ? "Required Field" : false }}
                        name1={`${isStringNotEmpty(name) ? name + '.' : ''}${firstNameFieldName}`}
                        defaultValue1={defaultValue && defaultValue[firstNameFieldName] ? defaultValue[firstNameFieldName] : ""}
                        required2={required}
                        render2={({ onChange, onBlur, value, name, ref }) => (
                            <Input
                                onBlur={onBlur}
                                onChange={e => { onChange(e.target.value); }}
                                value={value}
                                name={name}
                                placeholder="Last Name"
                                ref={ref}
                            />
                        )}
                        rules2={{ required: required ? "Required Field" : false }}
                        name2={`${isStringNotEmpty(name) ? name + '.' : ''}${lastNameFieldName}`}
                        defaultValue2={defaultValue && defaultValue[lastNameFieldName] ? defaultValue[lastNameFieldName] : ""}
                        label3={emailFieldLabel}
                        required3={required}
                        render3={({ onChange, onBlur, value, name, ref }) => (
                            <Input
                                onBlur={onBlur}
                                onChange={e => { onChange(e.target.value); }}
                                value={value}
                                name={name}
                                placeholder={emailFieldLabel}
                                ref={ref}
                            />
                        )}
                        rules3={{
                            required: required ? "Required Field" : false,
                            pattern: {
                                value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                message: "Please enter a valid email address"
                            }
                        }}
                        name3={`${isStringNotEmpty(name) ? name + '.' : ''}${emailFieldName}`}
                        defaultValue3={defaultValue && defaultValue[emailFieldName] ? defaultValue[emailFieldName] : ""}
                        label4={phoneFieldLabel}
                        required4={required}
                        render4={({ onChange, onBlur, value, name, ref }) => (
                            <MaskedInput
                                onBlur={onBlur}
                                onChange={e => {
                                    let value = null;
                                    if (isStringNotEmpty(e.target.value)) {
                                        value = e.target.value.replace(/\s/g, '').replace(' ', '').replace('_', '').replace('-', '').replace('(', '').replace(')', '');
                                    }
                                    onChange(value);
                                }}
                                value={value}
                                name={name}
                                placeholder={phoneFieldLabel}
                                mask="(111) 111-1111"
                                ref={ref}
                            />
                        )}
                        rules4={{
                            required: required ? "Required Field" : false,
                            pattern: {
                                value: /^\d{10}$/,
                                message: "Please enter a valid 10 digit phone number with no special characters"
                            }
                        }}
                        name4={`${isStringNotEmpty(name) ? name + '.' : ''}${phoneFieldName}`}
                        defaultValue4={defaultValue && defaultValue[phoneFieldName] ? defaultValue[phoneFieldName] : ""}
                        render5={({ onChange, onBlur, value, name, ref }) => (
                            <Input
                                onBlur={onBlur}
                                onChange={e => { onChange(e.target.value); }}
                                value={value}
                                name={name}
                                placeholder="Ext"
                                ref={ref}
                            />
                        )}
                        rules5={{ required: false }}
                        name5={`${isStringNotEmpty(name) ? name + '.' : ''}${phoneExtFieldName}`}
                        defaultValue5={defaultValue && defaultValue[phoneExtFieldName] ? defaultValue[phoneExtFieldName] : ""}
                    />
                </Col>
            </Row>
        );
    } else {
        return null;
    }
};

export default FormItemPointOfContact;