import React from "react";
import { Col, Row, Alert, Tooltip } from "antd";
import { Controller, useFormContext } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { InfoCircleOutlined } from "@ant-design/icons";
import { isBooleanTrue, isNotNullOrUndefined, isStringNotEmpty } from "../../shared/objectUtils";

const FormItemDouble = ({ 
    label1, labelCol1 = {}, wrapperCol1 = {}, required1 = false, noColon1 = false, name1, as1, render1, rules1, defaultValue1, onChange1, disabled1 = false, tooltip1: tooltipText1,
    label2, labelCol2 = {}, wrapperCol2 = {}, required2 = false, noColon2 = false, name2, as2, render2, rules2, defaultValue2, onChange2, disabled2 = false, tooltip2: tooltipText2,
    helpCol = {}, help = null, format = 'vertical' }) => {
    const methods = useFormContext();

    const controller1Props = {
        name: name1
    };

    if (as1 !== undefined) {
        controller1Props.as = as1;
    }

    if (render1 !== undefined) {
        controller1Props.render = render1;
    }

    if (rules1 !== undefined) {
        controller1Props.rules = rules1;
    }

    if (defaultValue1 !== undefined) {
        controller1Props.defaultValue = defaultValue1;
    }

    if (onChange1 !== undefined) {
        controller1Props.onChange = onChange1;
    }

    if (disabled1 !== undefined) {
        controller1Props.disabled = disabled1;
    }

    // console.log(controller1Props);

    const controller2Props = {
        name: name2
    };

    if (as2 !== undefined) {
        controller2Props.as = as2;
    }

    if (render2 !== undefined) {
        controller2Props.render = render2;
    }

    if (rules2 !== undefined) {
        controller2Props.rules = rules2;
    }

    if (defaultValue2 !== undefined) {
        controller2Props.defaultValue = defaultValue2;
    }

    if (onChange2 !== undefined) {
        controller2Props.onChange = onChange2;
    }

    if (disabled2 !== undefined) {
        controller2Props.disabled = disabled2;
    }

    // console.log(controller2Props);

    const getError = (errors, name) => {
        if (name !== undefined) {
            let obj = errors;

            name = name.split('.');
            let len = name.length;
            for (let i = 0; i < len - 1; i++) {
                if (obj[name[i]] !== undefined) {
                    //console.log(obj[name[i]]);
                    obj = obj[name[i]];
                }
            }

            return obj[name[len - 1]];
        } else {
            return undefined;
        }
    };

    const getTooltip = (text) => {
        if (isStringNotEmpty(text)) {
            return (
                <Tooltip placement="top" title={text}>
                    <InfoCircleOutlined style={{ color: '#23BEBB', marginLeft: 8 }} />
                </Tooltip>
            );
        } else {
            return null;
        }
    };

    if (format === 'vertical') {
        return (
            <>
                {(isStringNotEmpty(label1) || isStringNotEmpty(label2)) ? (
                    <Row gutter={[8, 8]} style={{ lineHeight: 2.0, marginBottom: 8 }}>
                        {isStringNotEmpty(label1) ? (<Col {...labelCol1}>{isBooleanTrue(required1) ? '* ' : ''}{label1}{getTooltip(tooltipText1)}{isBooleanTrue(noColon1) ? '' : ':'}</Col>) : null}
                        {isStringNotEmpty(label2) ? (<Col {...labelCol2}>{isBooleanTrue(required2) ? '* ' : ''}{label2}{getTooltip(tooltipText2)}{isBooleanTrue(noColon2) ? '' : ':'}</Col>) : null}
                    </Row>
                ) : null}
                <Row gutter={[8, 8]} style={{ marginBottom: 8 }}>
                    <Col {...wrapperCol1}>
                        <Controller control={methods.control} {...controller1Props} />
                    </Col>
                    <Col {...wrapperCol2}>
                        <Controller control={methods.control} {...controller2Props} />
                    </Col>
                </Row>
                {isNotNullOrUndefined(help) ? (
                    <Row gutter={[8, 8]} style={{ marginBottom: 8 }}>
                        <Col {...helpCol}>{help}</Col>
                    </Row>
                ) : null}
                {(getError(methods.errors, name1) !== undefined || getError(methods.errors, name2) !== undefined) ? (
                    <Row gutter={[8, 8]} style={{ marginBottom: 8 }}>
                        <Col {...wrapperCol1}>
                            <ErrorMessage
                                errors={methods.errors}
                                name={name1}
                                render={({ message, messages }) => {
                                    if (isNotNullOrUndefined(messages)) {
                                        return Object.entries(messages).map(([type, message]) => (<Alert key={`${name1}-${type}`} message={message} type="error" style={{ marginBottom: 8 }} />));
                                    } else if (isStringNotEmpty(message)) {
                                        return <Alert message={message} type="error" style={{ marginBottom: 8 }} />;
                                    } else {
                                        return null;
                                    }
                                }}
                            />
                        </Col>
                        <Col {...wrapperCol2}>
                            <ErrorMessage
                                errors={methods.errors}
                                name={name2}
                                render={({ message, messages }) => {
                                    if (isNotNullOrUndefined(messages)) {
                                        return Object.entries(messages).map(([type, message]) => (<Alert key={`${name2}-${type}`} message={message} type="error" style={{ marginBottom: 8 }} />));
                                    } else if (isStringNotEmpty(message)) {
                                        return <Alert message={message} type="error" style={{ marginBottom: 8 }} />;
                                    } else {
                                        return null;
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                ) : null}
            </>
        );
    } else if (format === 'horizontal') {
        return (
            <>
                <Row gutter={[8, 8]} style={{ marginBottom: 8 }}>
                    {isStringNotEmpty(label1) ? (
                        <Col {...labelCol1} style={{ textAlign: 'right' }}>{isBooleanTrue(required1) ? '* ' : ''}{label1}{getTooltip(tooltipText1)}{isBooleanTrue(noColon1) ? '' : ':'}</Col>
                    ) : null}
                    <Col {...wrapperCol1}>
                        <Controller control={methods.control} {...controller1Props} />
                    </Col>
                    {isStringNotEmpty(label2) ? (
                        <Col {...labelCol2} style={{ textAlign: 'right' }}>{isBooleanTrue(required2) ? '* ' : ''}{label2}{getTooltip(tooltipText2)}{isBooleanTrue(noColon2) ? '' : ':'}</Col>
                    ) : null}
                    <Col {...wrapperCol2}>
                        <Controller control={methods.control} {...controller2Props} />
                    </Col>
                </Row>
                {isNotNullOrUndefined(help) ? (
                    <Row gutter={[8, 8]} style={{ marginBottom: 8 }}>
                        <Col {...helpCol}>{help}</Col>
                    </Row>
                ) : null}
                {(getError(methods.errors, name1) !== undefined || getError(methods.errors, name2) !== undefined) ? (
                    <Row gutter={[8, 8]} style={{ marginBottom: 8 }}>
                        <Col {...labelCol1}></Col>
                        <Col {...wrapperCol1}>
                            <ErrorMessage
                                errors={methods.errors}
                                name={name1}
                                render={({ message, messages }) => {
                                    if (isNotNullOrUndefined(messages)) {
                                        return Object.entries(messages).map(([type, message]) => (<Alert key={`${name1}-${type}`} message={message} type="error" style={{ marginBottom: 8 }} />));
                                    } else if (isStringNotEmpty(message)) {
                                        return <Alert message={message} type="error" style={{ marginBottom: 8 }} />;
                                    } else {
                                        return null;
                                    }
                                }}
                            />
                        </Col>
                        <Col {...labelCol2}></Col>
                        <Col {...wrapperCol2}>
                            <ErrorMessage
                                errors={methods.errors}
                                name={name2}
                                render={({ message, messages }) => {
                                    if (isNotNullOrUndefined(messages)) {
                                        return Object.entries(messages).map(([type, message]) => (<Alert key={`${name2}-${type}`} message={message} type="error" style={{ marginBottom: 8 }} />));
                                    } else if (isStringNotEmpty(message)) {
                                        return <Alert message={message} type="error" style={{ marginBottom: 8 }} />;
                                    } else {
                                        return null;
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                ) : null}
            </>
        );
    } else {
        return null;
    }
};

export default FormItemDouble;