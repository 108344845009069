import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Layout, Row, Col, PageHeader, Divider, Collapse, Timeline } from "antd";
import LoadMap from "../../components/LoadMap/LoadMap";
import PoweredByLogo from "../../assets/img/PoweredByDark.png";
import DataRow from "../../components/DataRow/DataRow";
import DataListRow from '../../components/DataListRow/DataListRow';
import DataInfoActionRow from '../../components/DataInfoActionRow/DataInfoActionRow';
import DataTemperatureRangeRow from '../../components/DataTemperatureRangeRow/DataTemperatureRangeRow';
import LoadStop from '../../components/LoadStop/LoadStop';
import StringFormatter from '../../shared/stringFormatter';
import axios from "axios";
import moment from "moment";
import { isListNotEmpty, isNumberGreaterThanZero, isNumberNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import * as Data from '../../api/data';
import logger from '../../shared/logger';

const { Panel } = Collapse;
const stringFormatter = new StringFormatter();

const Track = (props) => {
    //#region props

    const loadId = props.match.params.loadId;

    //#endregion
    //#region useStates

    const [load, setLoad] = useState(null);

    //#endregion
    //#region useEffects

    useEffect(() => {
        const { cancel, token } = axios.CancelToken.source();

        if (isStringNotEmpty(loadId)) {
            Data.getShipperTracker(loadId, { cancelToken: token }).then((data) => {
                setLoad(data);
                // console.log(data);
            }).catch(err => {
                if (axios.isCancel(err)) {
                    // console.log('Request cancelled', err.message);
                } else {
                    logger.logDebugEvent('Track.js', err.message, true);
                }
            });
        }

        return () => {
            // console.log("Cleaning up");
            if (cancel !== undefined) {
                cancel('Operation cancelled by the user.');
            }
        };
    }, [loadId]);

    //#endregion
    //#region displays

    const header = (stop) => {
        if (isObjectNotEmpty(stop)) {
            const stopSequence = stop.sequence;
            const stopType = stop.stopType;
            const stopDate = stop.requestedDateTime;
            const stopTimeZone = stop.timeZone;
            const stopName = isObjectNotEmpty(stop.stopLocation) && isStringNotEmpty(stop.stopLocation.name) ? stop.stopLocation.name : null;
            const stopCity = isObjectNotEmpty(stop.stopLocation) && isObjectNotEmpty(stop.stopLocation.address) && isStringNotEmpty(stop.stopLocation.address.city) ? stop.stopLocation.address.city : null;
            const stopState = isObjectNotEmpty(stop.stopLocation) && isObjectNotEmpty(stop.stopLocation.address) && isStringNotEmpty(stop.stopLocation.address.state) ? stop.stopLocation.address.state : null;
            const stopStatus = stop.stopStatus;

            return (
                <Row gutter={[4, 4]} style={{ display: 'flex', alignItems: 'center' }}>
                    <Col md={{ span: 18 }} xs={{ span: 12 }}>
                        <b>{`Stop # ${stopSequence + 1} - `}{stringFormatter.toFormattedString("StopType", stopType, null)} - {stringFormatter.toFormattedString("MomentDate", stopDate, null, stopTimeZone)}{isStringNotEmpty(stopName) ? " - " + stopName : null}{isStringNotEmpty(stopCity) && isStringNotEmpty(stopState) ? " - " + stopCity + ", " + stopState : null} - Status: {stopStatus}</b>
                    </Col>
                </Row>
            );
        } else {
            return null;
        }
    };

    //#endregion

    return (
        <>
            {isObjectNotEmpty(load) ? (
                <Layout style={{ minHeight: '100vh', backgroundColor: 'white' }}>
                    <Row>
                        <Col span={24} style={{ padding: 8 }}>
                            <DataRow title="Load ID" value={load.irisId} dataType="string" />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <LoadMap
                                key={`loadMapTrack-${loadId}`}
                                loadId={load.id}
                                notAuthenticated={true}
                                stops={load.stops}
                                currentLatitude={load.latitude}
                                currentLongitude={load.longitude}
                                containerName={`track-load-map-${loadId}`}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ padding: 8 }}>
                            <Row>
                                <Col span={24}>
                                    <DataRow style={{ textAlign: 'center', fontSize: '30px', fontWeight: 'bold' }} value={load.loadStatus} dataType="ShipperLoadStatus" />
                                    <DataRow style={{ textAlign: 'center' }} title="ETA" value={load.approxLoadDuration} dataType="MinutesCountdown" />
                                    <DataRow style={{ textAlign: 'center' }} title="Trip Miles" value={load.practicalDistance} units={load.practicalDistanceUnit} dataType="Distance" />
                                    <DataRow style={{ textAlign: 'center' }} title="Last Updated At" value={moment.utc()} dataType="MomentDateTime" />
                                </Col>
                            </Row>
                            <Divider style={{ margin: '8px 0' }} />
                            {isListNotEmpty(load.loadNotifications) ? (
                                <>
                                    <Row>
                                        <Col span={24}>
                                            <PageHeader ghost={false} title={'Notifications'} style={{ padding: 0 }} />
                                            <div style={{ maxHeight: '150px', overflow: 'auto' }}>
                                                <Timeline>
                                                    {load.loadNotifications.map((loadEvent, index) => (
                                                        <Timeline.Item key={`load-event-${index}`} color={loadEvent.severity === "CRITICAL" ? "red" : "green"}>
                                                            {loadEvent.message} {stringFormatter.toFormattedString("MomentDateTime", loadEvent.createdAt, null)}
                                                        </Timeline.Item>
                                                    ))}
                                                </Timeline>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Divider style={{ margin: '8px 0' }} />
                                </>
                            ) : null}
                            {isListNotEmpty(load.drivers) ? (
                                <>
                                    <PageHeader ghost={false} title={'Your Driver(s)'} style={{ padding: 0 }} />
                                    {load.drivers.map((accountUser, index) => (
                                        <Row key={`driver-details-${index}`}>
                                            <Col span={24}>
                                                <DataInfoActionRow title={`Driver ${index + 1}`} value={accountUser.user.firstName + ' ' + accountUser.user.lastName} dataType="String" actions={null} />
                                            </Col>
                                        </Row>
                                    ))}
                                    <Divider style={{ margin: '8px 0' }} />
                                </>
                            ) : null}
                            {isListNotEmpty(load.stops) ? (
                                <>
                                    <PageHeader ghost={false} title={'Stops'} style={{ padding: 0 }} />
                                    <Collapse>
                                        {load.stops.map(stop => (
                                            <Panel header={header(stop)} key={`stop-panel-${stop.id}`}>
                                                <LoadStop load={load} stop={stop} showDocuments={false} showNotes={false} key={`stop-${stop.id}`} />
                                            </Panel>
                                        ))}
                                    </Collapse>
                                    <Divider style={{ margin: '8px 0' }} />
                                </>
                            ) : null}
                            <PageHeader ghost={false} title={'Equipment Details'} style={{ padding: 0 }} />
                            <Row>
                                <Col span={24}>
                                    {isObjectNotEmpty(load.equipmentNeeds) && isObjectNotEmpty(load.equipmentNeeds.trailerType) && isStringNotEmpty(load.equipmentNeeds.trailerType.description) ? (<DataRow title="Trailer Type" value={load.equipmentNeeds.trailerType.description} dataType="String" />) : null}
                                    {isObjectNotEmpty(load.equipmentNeeds) && isNumberNotEmpty(load.equipmentNeeds.trailerLength) ? (<DataRow title="Trailer Length" value={load.equipmentNeeds.trailerLength} units={load.equipmentNeeds.trailerLengthUnit} dataType="Length" />) : null}
                                    {isObjectNotEmpty(load.equipmentNeeds) && isNumberNotEmpty(load.equipmentNeeds.trailerWeightCapacity) ? (<DataRow title="Max Load Weight Capacity" value={load.equipmentNeeds.trailerWeightCapacity} units={load.equipmentNeeds.trailerWeightCapacityUnit} dataType="Weight" />) : null}
                                    <DataRow title="Team Driving" value={isListNotEmpty(load.driverRequirements) && load.driverRequirements.includes('TEAM_DRIVING') ? true : false} dataType="Boolean" />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    {isObjectNotEmpty(load.equipmentNeeds) && isNumberNotEmpty(load.equipmentNeeds.preCoolReefer) ? (<DataRow title="Pre-cool Temp" value={load.equipmentNeeds.preCoolReefer} units={load.equipmentNeeds.preCoolReeferUnit} dataType="Temperature" />) : null}
                                    {isObjectNotEmpty(load.equipmentNeeds) && isNumberNotEmpty(load.equipmentNeeds.minRunTemp) && isNumberNotEmpty(load.equipmentNeeds.maxRunTemp) ? (<DataTemperatureRangeRow title="Temp Range" min={load.equipmentNeeds.minRunTemp} minUnits={load.equipmentNeeds.minRunTempUnit} max={load.equipmentNeeds.maxRunTemp} maxUnits={load.equipmentNeeds.maxRunTempUnit} />) : null}
                                </Col>
                                <Col span={12}>
                                    {isNumberGreaterThanZero(load.loadWeight) ? (<DataRow title="Load Weight" value={load.loadWeight} units={load.loadWeightUnits} dataType="Weight" />) : null}
                                    {isNumberGreaterThanZero(load.totalWeight) ? (<DataRow title="Total Weight" value={load.totalWeight} units={load.totalWeightUnits} dataType="Weight" />) : null}
                                </Col>
                            </Row>
                            <Row>
                                {isListNotEmpty(load.equipmentSpecifics) ? (
                                    <Col span={12}>
                                        <DataListRow valueStyle={{ paddingLeft: 16 }} title="Equipment Specifics" data={load.equipmentSpecifics} dataType="EquipmentSpecific" />
                                    </Col>
                                ) : null}
                                {isListNotEmpty(load.driverRequirements) ? (
                                    <Col span={12}>
                                        <DataListRow valueStyle={{ paddingLeft: 16 }} title="Driver Requirements" data={load.driverRequirements} dataType="DriverRequirement" />
                                    </Col>
                                ) : null}
                            </Row>
                            <Divider style={{ margin: '8px 0' }} />
                            {isListNotEmpty(load.assets) ? (
                                <>
                                    <PageHeader ghost={false} title={'Asset Details'} style={{ padding: 0 }} />
                                    <Row>
                                        <Col span={24}>
                                            <div style={{ paddingTop: 10 }}>
                                                {load.assets.map((asset, index) => (
                                                    <DataInfoActionRow title={`Asset ${index + 1}`} value={`${stringFormatter.toFormattedString("AssetType", asset.assetType, null)}${isObjectNotEmpty(asset.trailerType) && isStringNotEmpty(asset.trailerType.description) ? ' (' + asset.trailerType.description + ')' : ''} (${asset.licensePlateNum} ${asset.licensePlateState})`} dataType="String" actions={null} />
                                                ))}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Divider style={{ margin: '8px 0' }} />
                                </>
                            ) : null}
                            {isObjectNotEmpty(load.assignedCarrier) ? (
                                <>
                                    <PageHeader ghost={false} title={'Carrier Details'} style={{ padding: 0 }} />
                                    <Row>
                                        <Col span={12}>
                                            <DataRow title='Carrier ID' value={load.assignedCarrier.irisId} dataType='String' />
                                            <DataRow title='Name' value={load.assignedCarrier.name} dataType='String' />
                                        </Col>
                                        <Col span={12}>
                                            <DataRow title='DOT #' value={load.assignedCarrier.dotNum} dataType='String' />
                                            <DataRow title='MC #' value={load.assignedCarrier.mcNum} dataType='String' />
                                            {isObjectNotEmpty(load.assignedCarrier.smsBasic) && isNumberNotEmpty(load.assignedCarrier.smsBasic.weightedAvgSMSScore) ? <DataRow title='SMS Basic Avg' value={load.assignedCarrier.smsBasic.weightedAvgSMSScore} dataType='String' /> : null}
                                        </Col>
                                    </Row>
                                    <Divider style={{ margin: '8px 0' }} />
                                </>
                            ) : null}
                            {isObjectNotEmpty(load.shipper) ? (
                                <>
                                    <PageHeader ghost={false} title={'Your Details'} style={{ padding: 0 }} />
                                    <Row>
                                        <Col span={12}>
                                            <DataRow title='Shipper ID' value={load.shipper.irisId} dataType='String' />
                                            <DataRow title='Name' value={load.name} dataType='String' />
                                        </Col>
                                        <Col span={12}>
                                            <DataRow title='D&B #' value={load.shipper.dbNum} dataType='String' />
                                            <DataRow title='PBB #' value={load.shipper.blueBookNum} dataType='String' />
                                        </Col>
                                    </Row>
                                    <Divider style={{ margin: '8px 0' }} />
                                </>
                            ) : null}
                            <div style={{ textAlign: 'center' }}><img src={PoweredByLogo} alt="IRIS" /></div>
                        </Col>
                    </Row>
                </Layout>
            ) : null}
        </>
    );
};

export default withRouter(Track);