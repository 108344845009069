import React from 'react';
import { isArray, isString } from "lodash";
import { Alert } from "antd";

const ErrorMessage = ({ error }) => {
    const getErrors = (errors) => {
        let content = [];
        for (let i = 0; i < errors.length; i++) {
            content.push(<li key={i}>{errors[i]}</li>);
        }
        return (
            <ul>
                {content}
            </ul>
        );
    };

    return (
        <>
            {(error && isArray(error) && error.length > 0) && (
                <Alert message={null} description={getErrors(error)} type="error" style={{ marginBottom: 8 }} />
            )}
            {error && isString(error) && <Alert message={`${error}`} type="error" style={{ marginBottom: 8 }} />}
        </>
    );
};

export default ErrorMessage;