import React from 'react';
import classes from './DispatchSelectedLoadCard.module.scss';
import ActionButton from '../../components/ActionButton/ActionButton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPallet } from '@fortawesome/free-solid-svg-icons';
import { isBooleanTrue, isObjectNotEmpty } from '../../shared/objectUtils';

const DispatchSelectedLoadCard = ({ load, loadsToDispatch, displayAsText = false, isBundledLoad = false, style }) => {
    if (isBooleanTrue(displayAsText)) {
        if (isBooleanTrue(isBundledLoad)) {
            if (isObjectNotEmpty(load)) {
                return (
                    <span>{load.loadNumber}/{loadsToDispatch.length}</span>
                );
            } else {
                return null;
            }
        } else {
            if (isObjectNotEmpty(load)) {
                return (
                    <span>{`Load ID: ${(load.parentName ? load.parentName : (load.name ? load.name : load.irisId))}`}</span>
                );
            } else {
                return null;
            }
        }
    } else {
        if (isBooleanTrue(isBundledLoad)) {
            if (isObjectNotEmpty(load)) {
                return (
                    <div style={style}>
                        <ActionButton
                            iconPosition="left"
                            buttonClassName={`${classes.button} ${classes.yellowButton}`}
                            buttonIconClassName={classes.iconContainer}
                            buttonTextClassName={classes.textContainer}
                            buttonIcon={<FontAwesomeIcon className={classes.button1icon1} icon={faPallet} />}
                            buttonText={<span>Scheduling Load:<br />{`${load.loadNumber} of ${loadsToDispatch.length}`}</span>}
                            onClick={() => { return; }}
                            disabled={true}
                        />
                    </div>
                );
            } else {
                return (
                    <div style={style}>
                        <ActionButton
                            iconPosition="left"
                            buttonClassName={`${classes.button} ${classes.blueButton}`}
                            buttonIconClassName={classes.iconContainer}
                            buttonTextClassName={classes.textContainer}
                            buttonIcon={<FontAwesomeIcon className={classes.button1icon1} icon={faPallet} />}
                            buttonText={<span>Select a Load to Edit/<br />Schedule Drivers and Assets</span>}
                            onClick={() => { return; }}
                            disabled={true}
                        />
                    </div>
                );
            }
        } else {
            if (isObjectNotEmpty(load)) {
                return (
                    <div style={style}>
                        <ActionButton
                            iconPosition="left"
                            buttonClassName={`${classes.button} ${classes.yellowButton}`}
                            buttonIconClassName={classes.iconContainer}
                            buttonTextClassName={classes.textContainer}
                            buttonIcon={<FontAwesomeIcon className={classes.button1icon1} icon={faPallet} />}
                            buttonText={<span>{`Load ID: ${(load.parentName ? load.parentName : (load.name ? load.name : load.irisId))}`}</span>}
                            onClick={() => { return; }}
                            disabled={true}
                        />
                    </div>
                );
            } else {
                return (
                    <div style={style}>
                        <ActionButton
                            iconPosition="left"
                            buttonClassName={`${classes.button} ${classes.blueButton}`}
                            buttonIconClassName={classes.iconContainer}
                            buttonTextClassName={classes.textContainer}
                            buttonIcon={<FontAwesomeIcon className={classes.button1icon1} icon={faPallet} />}
                            buttonText={<span>Select a Load to Edit/<br />Schedule Drivers and Assets</span>}
                            onClick={() => { return; }}
                            disabled={true}
                        />
                    </div>
                );
            }
        }
    }
};

export default DispatchSelectedLoadCard;