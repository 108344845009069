import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { Spin, Tabs, Row, Col } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck, faStore } from '@fortawesome/free-solid-svg-icons';
import Header from '../../components/Header/Header';
import { useLocation } from 'react-router';
import ComplexDataLinkCard from '../../components/ComplexDataLinkCard/ComplexDataLinkCard';
import Calendar from '../../components/Calendar/Calendar';
import Scheduler from '../../components/Scheduler/Scheduler';
import ComplexCard from '../../components/ComplexCard/ComplexCard';
import Agenda from '../../components/Agenda/Agenda';
import { isBooleanTrue, isListNotEmpty, isNumberNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { Can } from '../../shared/entitlements/entitlements';

const { TabPane } = Tabs;

const Product = props => {
    //#region props and constants

    const id = props.match.params.id;

    const previousBreadcrumbs = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isListNotEmpty(props.history.location.state.previousBreadcrumbs) ? props.history.location.state.previousBreadcrumbs : [];
    const previousLocation = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageLocation) ? props.history.location.state.previousPageLocation : { pathname: `/products` };
    const previousPath = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageLocation) ? props.history.location.state.previousPageLocation : `/products`;
    const previousBreadcrumbName = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageTitle) ? props.history.location.state.previousPageTitle : 'Products';

    //#endregion
    //#region useLocation

    const location = useLocation();

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.products.isRecordLoading);
    const error = useSelector(state => state.products.recordError);
    const product = useSelector(state => state.products.record);

    //#endregion
    //#region useStates

    const [tabKey, setTabKey] = useState('snapshot');

    //#endregion
    //#region tab methods

    const onTabChange = (key) => {
        props.history.replace({ ...props.location, hash: `#${key}` });
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        if (isStringNotEmpty(location.hash)) {
            setTabKey(location.hash.replace('#', ''));
        } else {
            setTabKey('snapshot');
            props.history.replace({ ...location, hash: `#snapshot` });
        }
    }, [location]);

    useMemo(() => {
        dispatch(actionCreators.fetchProduct(id));
    }, [id]);

    //#endregion
    //#region breadcrumbs

    let breadcrumbs = [...previousBreadcrumbs,
    {
        location: previousLocation,
        path: previousPath,
        breadcrumbName: previousBreadcrumbName,
    },
    {
        path: `/products/${isStringNotEmpty(id) ? id : ''}`,
        breadcrumbName: `${isObjectNotEmpty(product) && isStringNotEmpty(product.name) ? product.name : ''}`,
    },
    ];

    //#endregion

    if (isObjectNotEmpty(product) && product.id === id) {
        return (
            <Can entityAction="READ" entityModel="PRODUCT" entityObject={product} accountId={product.accountId}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Header
                        title={isStringNotEmpty(product.name) ? product.name : 'Loading...'}
                        icon={<FontAwesomeIcon className="anticon" icon={faStore} />}
                        breadcrumbs={breadcrumbs}
                        title2={`Commodity: ${isObjectNotEmpty(product.commodity) && isStringNotEmpty(product.commodity.name) ? product.commodity.name : 'N/A'}`}
                    />
                    <Tabs activeKey={tabKey} onChange={onTabChange} destroyInactiveTabPane={true} tabBarStyle={{ backgroundColor: '#ffffff', paddingRight: 24, paddingLeft: 24, margin: 0 }}>
                        <TabPane tab="Snapshot" key="snapshot">
                            <div style={{ padding: 24 }}>
                                <Row gutter={[24, 24]}>
                                    {product.variants.map((variant) => {
                                        return (
                                            <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                                <ComplexDataLinkCard
                                                    title={variant.name}
                                                    style={{ height: 200 }}
                                                    headStyle={{ fontSize: 14 }}
                                                    data={{
                                                        "SKU": { value: isStringNotEmpty(variant.sku) ? variant.sku : null, dataType: 'String' },
                                                        "Unit Price": { value: isNumberNotEmpty(variant.unitPrice) ? variant.unitPrice : null, units: isStringNotEmpty(variant.unitPriceUnit) ? variant.unitPriceUnit : null,  dataType: 'Money' },
                                                        "Unit Weight": { value: isNumberNotEmpty(variant.unitWeight) ? variant.unitWeight : null, units: isStringNotEmpty(variant.unitWeightUnit) ? variant.unitWeightUnit : null, dataType: 'Weight' },
                                                        "Quantity Per Unit": { value: isNumberNotEmpty(variant.quantityPerUnit) ? variant.quantityPerUnit : null, dataType: 'Integer' }
                                                    }}
                                                />
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </div>
                        </TabPane>
                    </Tabs>
                </Spin>
            </Can>
        );
    } else {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={true} />
        );
    }
};

export default withRouter(Product);