import * as actionTypes from "../actions/actionTypes";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import LocalStorage from "../../shared/localStorageUtils";
import ErrorUtils from "../../shared/errorUtils";

//#region Fetch Keys Methods

export const fetchKeysStart = () => {
    return {
        type: actionTypes.FETCH_KEYS_START
    }
};

export const fetchKeysSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_KEYS_SUCCESS,
        payload: payload
    }
};

export const fetchKeysFail = (payload) => {
    return {
        type: actionTypes.FETCH_KEYS_FAIL,
        payload: payload
    }
};

export const clearKeys = () => {
    return {
        type: actionTypes.CLEAR_KEYS
    }
};

//#endregion
//#region Keys Methods

export const fetchKeys = () => {
    return async (dispatch) => {
        try {
            dispatch(fetchKeysStart());
            // check local storage first and if it is there, load it into redux, otherwise fetch from server
            let keysValueString = await LocalStorage.getItem('keys');
            if (isStringNotEmpty(keysValueString)) {
                // already there so load into redux
                let keysValue = JSON.parse(keysValueString);
                return dispatch(fetchKeysSuccess({ ...keysValue }));
            } else {
                const keys = await Data.getKeys();
                if (isObjectNotEmpty(keys) && isStringNotEmpty(keys.pubNubSubKey) && isStringNotEmpty(keys.pubNubPubKey) && isStringNotEmpty(keys.trimbleAPIKey)) {
                    await LocalStorage.setItem('keys', JSON.stringify(keys));
                    return dispatch(fetchKeysSuccess({ ...keys }));
                } else {
                    return dispatch(fetchKeysFail({ error: 'Failed to get keys.' }));
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            return dispatch(fetchKeysFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion