import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { PaperClipOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Drawer, Modal, Dropdown, Menu } from 'antd';
import EditAsset from "../../components/EditAsset/EditAsset";
import NewAsset from "../../components/NewAsset/NewAsset";
import classes from './Assets.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools, faTruckMoving } from '@fortawesome/free-solid-svg-icons';
import DataTable from '../../components/DataTable/DataTable';
import DocumentList from "../../components/DocumentList/DocumentList";
import StringFormatter from '../../shared/stringFormatter';
import { withRouter } from 'react-router-dom';
import { isObjectNotEmpty, isStringEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { CanDo, CanSee } from '../../shared/entitlements/entitlements';
import { selectListIsLoading, selectListPagination, selectListRecords } from '../../store/utility';
import { compareByAlph } from '../../shared/tableUtils';

const stringFormatter = new StringFormatter();

const Assets = ({ accountId = null, ...props }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;
    const singularEntityName = "Asset";
    const pluralEntityName = "Assets";

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);
    const records = useSelector(state => selectListRecords(state.assets.lists, isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')));
    const isLoading = useSelector(state => selectListIsLoading(state.assets.lists, isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')));
    const pagination = useSelector(state => selectListPagination(state.assets.lists, isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')));

    //#endregion
    //#region useStates

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showDocumentsModal, setShowDocumentsModal] = useState(false);

    //#endregion
    //#region toggles

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleDocumentsModal = () => {
        setShowDocumentsModal(!showDocumentsModal);
    };

    //#endregion
    //#region goTos

    const goToAssetDashboard = (id) => {
        props.history.push({ pathname: `/assets/${id}` }, {
            previousPageTitle: "Assets",
            previousPageLocation: props.location,
            previousBreadcrumbs: props.breadcrumbs,
        });
    };

    //#endregion
    //#region table methods

    const refreshTable = () => {
        let searchParams = {
            page: 1,
            size: 5,
            isDeleted: false,
            sort: 'name',
            order: 'asc'
        };

        if (isStringNotEmpty(accountId)) {
            searchParams.carrierId = accountId;
        } else if (isStringNotEmpty(entityId) && entityType !== 'STAFF') {
            searchParams.carrierId = entityId;
        }

        dispatch(actionCreators.fetchAssetList(isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL'), searchParams));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchAssetListSuccess(isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL'), {params:{ pagination: pager }}));

        let searchParams = {
            page: pagination.current,
            size: pagination.pageSize,
            isDeleted: false
        };

        if (isStringNotEmpty(sorter.order)) {
            const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
            searchParams.order = sortOrder;
        }
        if (isStringNotEmpty(sorter.field)) {
            searchParams.sort = sorter.field;
        }

        if (isStringNotEmpty(accountId)) {
            searchParams.carrierId = accountId;
        } else if (isStringNotEmpty(entityId) && entityType !== 'STAFF') {
            searchParams.carrierId = entityId;
        }

        dispatch(actionCreators.fetchAssetList(isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL'), searchParams));
    };

    //#endregion
    //#region table displays

    const menu = (record) => {
        return (
            <Menu>
                {CanDo({ entityAction: 'UPDATE', entityModel: 'ASSET', accountId: record.carrierId }) ? (
                    <Menu.Item key="manageDocuments" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); toggleDocumentsModal(); }}>
                        <PaperClipOutlined style={{ marginRight: 8 }} />
                        <span>Manage Documents</span>
                    </Menu.Item>
                ) : null}
                {CanDo({ entityAction: 'UPDATE', entityModel: 'ASSET', accountId: record.carrierId }) ? (
                    <Menu.Item key="editEntity" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); toggleEditEntity(); }}>
                        <FontAwesomeIcon className="anticon" icon={faTruckMoving} style={{ marginRight: 8 }} />
                        <span>Edit {singularEntityName} Details</span>
                    </Menu.Item>
                ) : null}
                {CanDo({ entityAction: 'READ', entityModel: 'ASSET', accountId: record.carrierId }) ? (
                    <Menu.Item key="goToDashboard" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); goToAssetDashboard(record.id); }}>
                        <span>Go to {singularEntityName} Dashboard</span>
                    </Menu.Item>
                ) : null}
            </Menu>
        );
    };

    const columns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            className: classes.columnNoBreak,
            width: 50,
            render: (text, record) => {
                return (
                    <Dropdown overlay={menu(record)} trigger={['click']}>
                        <Button>Actions <DownOutlined /></Button>
                    </Dropdown>
                );
            },
            align: 'center',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => compareByAlph(a.name, b.name),
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Asset Type',
            dataIndex: 'assetType',
            key: 'assetType',
            render: (text, record) => { return stringFormatter.toFormattedString("AssetType", text, null); },
        },
        {
            title: 'Trailer Type',
            dataIndex: ['trailerType', 'description'],
            key: 'trailerType.description',
        },
        {
            title: 'Available',
            dataIndex: 'isAvailable',
            key: 'isAvailable',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => { return stringFormatter.toFormattedString("AssetStatus", text, null); },
        },
        {
            title: 'License #',
            dataIndex: 'licensePlateNum',
            key: 'licensePlateNum',
        },
        {
            title: 'License State',
            dataIndex: 'licensePlateState',
            key: 'licensePlateState',
        },
        {
            title: 'Dot #',
            dataIndex: 'dotNum',
            key: 'dotNum',
        },
        {
            title: 'VIN',
            dataIndex: 'vin',
            key: 'vin',
        },
        {
            title: 'Truck Class',
            dataIndex: 'truckClass',
            key: 'truckClass',
        },
        {
            title: 'Load Capacity',
            dataIndex: 'loadCapacity',
            key: 'loadCapacity',
            render: (text, record) => { return stringFormatter.toFormattedString("Weight", record.loadCapacity, record.loadCapacityUnit); },
        },
        {
            title: 'Max Load Weight',
            dataIndex: 'maxLoadWeight',
            key: 'maxLoadWeight',
            render: (text, record) => { return stringFormatter.toFormattedString("Weight", record.maxLoadWeight, record.maxLoadWeightUnit); },
        },
        {
            title: 'Contractor Asset',
            dataIndex: 'isContractorAsset',
            key: 'isContractorAsset',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        },
        {
            title: 'Carrier Asset',
            dataIndex: 'isCarrierAsset',
            key: 'isCarrierAsset',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        },
    ];

    if (isStringEmpty(accountId) && CanDo({ staffOnly: true })) {
        columns.push({
            title: 'Account Name',
            dataIndex: ['account', 'name'],
            key: 'account.name',
        });
    }

    //#endregion
    //#region displays

    const newEntityComponents = (
        <Drawer
            title={"New " + singularEntityName}
            onClose={toggleNewEntity}
            visible={showNewEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewAsset
                cancel={toggleNewEntity}
                accountId={isStringNotEmpty(accountId) ? accountId : (isStringNotEmpty(entityId) && entityType !== 'STAFF' ? entityId : null)}
            />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName}
            onClose={toggleEditEntity}
            visible={showEditEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditAsset
                cancel={toggleEditEntity}
                asset={selectedRecord}
            />
        </Drawer>
    );

    const documentsComponent = (
        <Modal
            title="Asset Documents"
            visible={showDocumentsModal === true}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleDocumentsModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            {isObjectNotEmpty(selectedRecord) ? (
                <DocumentList
                    documentEntityType="ASSET"
                    documentEntityId={selectedRecord.id}
                />
            ) : null}
        </Modal>
    );

    //#endregion
    //#region useEffects

    useEffect(() => {
        refreshTable();
    }, [accountId, entityId]);

    //#endregion

    return (
        <CanSee entityAction="READ_LIST" entityModel="ASSET" accountId={isStringNotEmpty(accountId) ? accountId : (isStringNotEmpty(entityId) && entityType !== 'STAFF' ? entityId : null)}>
            <DataTable
                dataSource={records}
                columns={columns}
                pagination={pagination}
                onChange={handleTableChange}
                loading={isLoading === true}
                rowClassName={classes.dataTableRow}
                singularEntityName={singularEntityName}
                pluralEntityName={pluralEntityName}
                newEntityAction={CanDo({ entityAction: 'CREATE', entityModel: 'ASSET', accountId: isStringNotEmpty(accountId) ? accountId : (isStringNotEmpty(entityId) && entityType !== 'STAFF' ? entityId : null) }) ? toggleNewEntity : null}
                rowKey={record => record.id}
                style={{ backgroundColor: '#ffffff' }}
            >
                {newEntityComponents}
                {editEntityComponents}
                {documentsComponent}
            </DataTable>
        </CanSee>
    );
};

export default withRouter(Assets);