import axiosAuthenticated from "../../api/axiosAuthenticated";
import { isNotNullOrUndefined } from "../../shared/objectUtils";
import * as DTO from "../dtos/index";

const scheduledShiftsPath = '/scheduledShifts';
const scheduledShiftsBulkPath = '/scheduledShifts/bulk/get';

//#region Scheduled Shifts Methods

export const getScheduledShifts = async (searchParams = {}, pagination = {}) => {
    const scheduledShiftsRes = await axiosAuthenticated.post(scheduledShiftsBulkPath, { ...searchParams });
    if (scheduledShiftsRes && scheduledShiftsRes.status === 200) {
        const scheduledShifts = scheduledShiftsRes.data.data;
        const otherData = scheduledShiftsRes.data;

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;

        return { data: scheduledShifts, searchParams: searchParams, pagination: pagination };
    }

    return { data: [], searchParams: searchParams, pagination: pagination };
};

export const getScheduledShiftsForCalendar = async (searchParams = {}, pagination = {}) => {
    const scheduledShiftsRes = await axiosAuthenticated.post(scheduledShiftsBulkPath, { ...searchParams });
    if (scheduledShiftsRes && scheduledShiftsRes.status === 200) {
        const scheduledShifts = scheduledShiftsRes.data.data;
        const otherData = scheduledShiftsRes.data;

        const transformedScheduledShifts = scheduledShifts.map((scheduledShift) => { return DTO.getScheduledShiftCalendarDTO(scheduledShift); });

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;

        return { data: transformedScheduledShifts, searchParams: searchParams, pagination: pagination };
    }

    return { data: [], searchParams: searchParams, pagination: pagination };
};

export const getScheduledShift = async (id) => {
    const scheduledShiftsRes = await axiosAuthenticated.get(scheduledShiftsPath + `/${id}`);
    if (scheduledShiftsRes && scheduledShiftsRes.status === 200) {
        return scheduledShiftsRes.data;
    }

    return null;
};

export const getScheduledShiftForCalendar = async (id) => {
    const scheduledShiftsRes = await axiosAuthenticated.get(scheduledShiftsPath + `/${id}`);
    if (scheduledShiftsRes && scheduledShiftsRes.status === 200) {
        let scheduledShift = scheduledShiftsRes.data;

        return DTO.getScheduledShiftCalendarDTO(scheduledShift);
    }

    return null;
};

export const addScheduledShift = async (payload) => {
    const scheduledShiftsRes = await axiosAuthenticated.post(scheduledShiftsPath, { ...payload });
    if (scheduledShiftsRes && scheduledShiftsRes.status === 201) {
        return scheduledShiftsRes.data;
    }

    return null;
};

export const addScheduledShiftForCalendar = async (payload) => {
    const scheduledShiftsRes = await axiosAuthenticated.post(scheduledShiftsPath, { ...payload });
    if (scheduledShiftsRes && scheduledShiftsRes.status === 201) {
        let newScheduledShift = scheduledShiftsRes.data;

        return DTO.getScheduledShiftCalendarDTO(newScheduledShift);
    }

    return null;
};

export const updateScheduledShift = async (id, payload) => {
    const scheduledShiftsRes = await axiosAuthenticated.put(scheduledShiftsPath + `/${id}`, { ...payload });
    if (scheduledShiftsRes && scheduledShiftsRes.status === 200) {
        return scheduledShiftsRes.data;
    }

    return null;
};

export const updateScheduledShiftForCalendar = async (id, payload) => {
    const scheduledShiftsRes = await axiosAuthenticated.put(scheduledShiftsPath + `/${id}`, { ...payload });
    if (scheduledShiftsRes && scheduledShiftsRes.status === 200) {
        let updatedScheduledShift = scheduledShiftsRes.data;

        return DTO.getScheduledShiftCalendarDTO(updatedScheduledShift);
    }

    return null;
};

export const removeScheduledShift = async (id) => {
    const scheduledShiftsRes = await axiosAuthenticated.put(scheduledShiftsPath + `/${id}`, { isDeleted: true });
    if (scheduledShiftsRes && scheduledShiftsRes.status === 200) {
        return scheduledShiftsRes.data;
    }

    return null;
};

//#endregion