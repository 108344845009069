import axios from 'axios';
import axiosAuthenticated from "../../api/axiosAuthenticated";
import jwt from 'jwt-decode';
import moment from 'moment';
import EntityUtils from '../entityUtils';
import * as DTO from "../dtos/index";
import * as Data from "./index";
import { isListNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';

const tokenRefreshPath = '/api-token-refresh/';
const registerPath = '/rest-auth/registration/';
const loginPath = '/rest-auth/login/';
const passwordlessLoginPath = '/rest-auth/passwordless/login/';
const passwordlessLoginValidationPath = '/rest-auth/passwordless/validate/';
const forgotPasswordPath = '/rest-auth/password/reset/';
const changePasswordPath = '/rest-auth/password/change/';
const resetPasswordPath = '/rest-auth/password/reset/confirm/';
const userCheckerPath = '/rest-auth/user/checker/';

//#region Auth Methods

export const getRefreshToken = async (refreshToken, withProfileImageId = false) => {
    // console.log('getting refresh token');
    const resp = await axios.post(tokenRefreshPath, { refresh: refreshToken });
    if (resp && resp.status === 200) {
        const newToken = resp.data.access;
        const newRefreshToken = resp.data.refresh;

        if (isStringNotEmpty(newToken)) {
            const newTokenDecoded = jwt(newToken);
            const userId = newTokenDecoded.user_id;
            const userProfileId = newTokenDecoded.user_profile_id;
            const firstName = newTokenDecoded.f_name;
            const lastName = newTokenDecoded.l_name;
            const isUserActive = newTokenDecoded.is_active;
            const isStaff = newTokenDecoded.is_staff;
            const isAdmin = newTokenDecoded.is_superuser;
            const isVP = newTokenDecoded.is_vp;
            const isDirector = newTokenDecoded.is_director;
            const isManager = newTokenDecoded.is_manager;
            const email = newTokenDecoded.email;
            const userName = newTokenDecoded.username;
            const newEndDate = moment.unix(newTokenDecoded.exp);

            let refreshObj = {
                newToken: newToken,
                newRefreshToken: newRefreshToken,
                newTokenDecoded: newTokenDecoded,
                userId: userId ? userId : '',
                userProfileId: userProfileId ? userProfileId : '',
                firstName: firstName ? firstName : '',
                lastName: lastName ? lastName : '',
                isUserActive: isUserActive,
                isStaff: isStaff,
                isAdmin: isAdmin,
                isVP: isVP,
                isDirector: isDirector,
                isManager: isManager,
                email: email ? email : '',
                userName: userName ? userName : '',
                newEndDate: newEndDate,
                expirationDate: newEndDate.toDate().toString(),
                profileImageId: null
            };

            if (withProfileImageId === true) {
                const userProfileDocuments = await Data.getDocumentsByEntityIdWithToken(userProfileId, newToken);
                let { profileImageId } = EntityUtils.getUserFileIds(userProfileDocuments);
                if (isStringNotEmpty(profileImageId)) {
                    refreshObj.profileImageId = profileImageId;
                }
            }

            return refreshObj;
        }
    }

    return null;
};

export const register = async (password, comparePassword, email) => {
    const registerRes = await axios.post(registerPath, { username: email, password1: password, password2: comparePassword, email: email });
    if (registerRes && registerRes.status === 201) {
        return {
            token: registerRes.data.access,
            refreshToken: registerRes.data.refresh,
            userId: registerRes.data.user_id,
            userProfileId: registerRes.data.user_profile_id
        };
    }

    return null;
};

export const login = async (email, password) => {
    const loginRes = await axios.post(loginPath, { username: email, password: password });
    if (loginRes && loginRes.status === 200) {
        const token = loginRes.data.access;
        const refreshToken = loginRes.data.refresh;

        if (isStringNotEmpty(token)) {
            const tokenDecoded = jwt(token);
            const userId = tokenDecoded.user_id;
            const userProfileId = tokenDecoded.user_profile_id;
            const firstName = tokenDecoded.f_name;
            const lastName = tokenDecoded.l_name;
            const isUserActive = tokenDecoded.is_active;
            const isStaff = tokenDecoded.is_staff;
            const isAdmin = tokenDecoded.is_superuser;
            const isVP = tokenDecoded.is_vp;
            const isDirector = tokenDecoded.is_director;
            const isManager = tokenDecoded.is_manager;
            const email = tokenDecoded.email;
            const userName = tokenDecoded.username;
            const endDate = moment.unix(tokenDecoded.exp);

            let loginObj = {
                token: token,
                refreshToken: refreshToken,
                tokenDecoded: tokenDecoded,
                userId: userId ? userId : '',
                userProfileId: userProfileId ? userProfileId : '',
                firstName: firstName ? firstName : '',
                lastName: lastName ? lastName : '',
                isUserActive: isUserActive,
                isStaff: isStaff,
                isAdmin: isAdmin,
                isVP: isVP,
                isDirector: isDirector,
                isManager: isManager,
                email: email ? email : '',
                userName: userName ? userName : '',
                endDate: endDate,
                expirationDate: endDate.toDate().toString(),
                profileImageId: null
            };

            const userProfileDocuments = await Data.getDocumentsByEntityIdWithToken(userProfileId, token);
            let { profileImageId } = EntityUtils.getUserFileIds(userProfileDocuments);
            if (isStringNotEmpty(profileImageId)) {
                loginObj.profileImageId = profileImageId;
            }

            return loginObj;
        }
    }

    return null;
};

export const getAccountUsersAndAccountsByUserId = async (userId) => {
    let accounts = [];
    let accountUsers = [];
    const accountUsersArray = await Data.getAccountUsersByUserId(userId);
    if (isListNotEmpty(accountUsersArray)) {
        let accountIds = accountUsersArray.filter((accountUser) => accountUser.accountId !== undefined && accountUser.accountId !== null).map((accountUser) => { return accountUser.accountId; });
        let accountUserIds = accountUsersArray.map((accountUser) => { return accountUser.id; });
        let driverDocuments = await Data.getDocumentsByEntityIds([...accountUserIds]);
        accounts = await Data.getAccountsByIds([...accountIds]);

        accountUsers = accountUsersArray.map((accountUser) => { return DTO.getAccountUserDTO(accountUser, accounts, null, driverDocuments); });
    }

    return {
        accounts: accounts,
        accountUsers: accountUsers
    };
};

export const loginWithPasswordless = async (phoneNumber) => {
    const passwordlessLoginRes = await axios.post(passwordlessLoginPath, { phone: phoneNumber });
    if (passwordlessLoginRes && passwordlessLoginRes.status === 200) {
        return true;
    } else {
        return false;
    }
};

export const validatePasswordlessLogin = async (phoneNumber, code) => {
    const loginRes = await axios.post(passwordlessLoginValidationPath, { phone: phoneNumber, code: code });
    if (loginRes && loginRes.status === 200) {
        const token = loginRes.data.access;
        const refreshToken = loginRes.data.refresh;

        if (isStringNotEmpty(token)) {
            const tokenDecoded = jwt(token);
            const userId = tokenDecoded.user_id;
            const userProfileId = tokenDecoded.user_profile_id;
            const firstName = tokenDecoded.f_name;
            const lastName = tokenDecoded.l_name;
            const isUserActive = tokenDecoded.is_active;
            const isStaff = tokenDecoded.is_staff;
            const isAdmin = tokenDecoded.is_superuser;
            const isVP = tokenDecoded.is_vp;
            const isDirector = tokenDecoded.is_director;
            const isManager = tokenDecoded.is_manager;
            const email = tokenDecoded.email;
            const userName = tokenDecoded.username;
            const endDate = moment.unix(tokenDecoded.exp);

            let loginObj = {
                token: token,
                refreshToken: refreshToken,
                tokenDecoded: tokenDecoded,
                userId: userId ? userId : '',
                userProfileId: userProfileId ? userProfileId : '',
                firstName: firstName ? firstName : '',
                lastName: lastName ? lastName : '',
                isUserActive: isUserActive,
                isStaff: isStaff,
                isAdmin: isAdmin,
                isVP: isVP,
                isDirector: isDirector,
                isManager: isManager,
                email: email ? email : '',
                userName: userName ? userName : '',
                endDate: endDate,
                expirationDate: endDate.toDate().toString()
            };
            
            const userProfileDocuments = await Data.getDocumentsByEntityIdWithToken(userProfileId, token);
            let { profileImageId } = EntityUtils.getUserFileIds(userProfileDocuments);
            if (isStringNotEmpty(profileImageId)) {
                loginObj.profileImageId = profileImageId;
            }

            return loginObj;
        }
    }

    return null;
};

export const forgotPassword = async (emailAddress) => {
    const forgotPasswordRes = await axios.post(forgotPasswordPath, { email: emailAddress });
    if (forgotPasswordRes && forgotPasswordRes.status === 200) {
        return true;
    } else {
        return false;
    }
};

export const changePassword = async (password, confirmPassword, oldPassword) => {
    const changePasswordRes = await axiosAuthenticated.post(changePasswordPath, { new_password1: password, new_password2: confirmPassword, old_password: oldPassword });
    if (changePasswordRes && changePasswordRes.status === 200) {
        return true;
    } else {
        return false;
    }
};

export const resetPassword = async (uuid, token, password, confirmPassword) => {
    const resetPasswordRes = await axios.post(resetPasswordPath, { uid: uuid, token: token, new_password1: password, new_password2: confirmPassword });
    if (resetPasswordRes && resetPasswordRes.status === 200) {
        return true;
    } else {
        return false;
    }
};

export const doesUserExistByEmailAddress = async (emailAddress, config = {}) => {
    const results = await axios.get(userCheckerPath + `?email=${emailAddress}`, config);
    if (results && results.status === 200) {
        return results.data.exist;
    }

    return false;
};

export const doesUserExistByPhoneNumber = async (phoneNumber, config = {}) => {
    const results = await axios.get(userCheckerPath + `?phone=${phoneNumber}`, config);
    if (results && results.status === 200) {
        return results.data.exist;
    }

    return false;
};

//#endregion