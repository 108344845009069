import React from 'react';
import { Card, Typography } from "antd";
import classes from './SimpleStatsLinkCard.module.scss';
import { isNotNullOrUndefined, isStringNotEmpty } from '../../shared/objectUtils';
import { withRouter } from 'react-router';

const { Title } = Typography;

const SimpleStatsLinkCard = ({ title, style = null, headStyle = null, amountSize = 3, amount, extra, date = null, url = null, onClick = null, currentBreadcrumbName = "Dashboard", ...props }) => {
    const goToUrl = (path) => {
        props.history.push(path, {
            previousPageTitle: currentBreadcrumbName,
            previousPageLocation: props.location,
            previousBreadcrumbs: props.breadcrumbs,
        });
    };

    if (isStringNotEmpty(url)) {
        return (
            <Card bordered={true} title={title} className={classes.card} style={style} headStyle={headStyle} extra={extra}>
                {isNotNullOrUndefined(date) ? (<div><span>{date}</span></div>) : null}
                <div><a onClick={() => { goToUrl(url); }} style={{ textDecoration: 'underline' }}><Title level={amountSize} style={{ color: '#23bebb' }}>{amount}</Title></a></div>
            </Card>
        );
    } else if (isNotNullOrUndefined(onClick)) {
        return (
            <Card bordered={true} title={title} className={classes.card} style={style} headStyle={headStyle} extra={extra}>
                {isNotNullOrUndefined(date) ? (<div><span>{date}</span></div>) : null}
                <div><a onClick={onClick} style={{ textDecoration: 'underline' }}><Title level={amountSize} style={{ color: '#23bebb' }}>{amount}</Title></a></div>
            </Card>
        );
    } else {
        return (
            <Card bordered={true} title={title} className={classes.card} style={style} headStyle={headStyle} extra={extra}>
                {isNotNullOrUndefined(date) ? (<div><span>{date}</span></div>) : null}
                <div><Title level={amountSize}>{amount}</Title></div>
            </Card>
        );
    }
};

export default withRouter(SimpleStatsLinkCard);