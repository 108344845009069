import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { Spin, Tabs, Row, Col } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import Header from '../../components/Header/Header';
import { useLocation } from 'react-router';
import ComplexDataLinkCard from '../../components/ComplexDataLinkCard/ComplexDataLinkCard';
import Calendar from '../../components/Calendar/Calendar';
import Scheduler from '../../components/Scheduler/Scheduler';
import ComplexCard from '../../components/ComplexCard/ComplexCard';
import Agenda from '../../components/Agenda/Agenda';
import { isBooleanTrue, isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { Can } from '../../shared/entitlements/entitlements';

const { TabPane } = Tabs;

const Shift = props => {
    //#region props and constants

    const id = props.match.params.id;

    const previousBreadcrumbs = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isListNotEmpty(props.history.location.state.previousBreadcrumbs) ? props.history.location.state.previousBreadcrumbs : [];
    const previousLocation = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageLocation) ? props.history.location.state.previousPageLocation : { pathname: `/shifts` };
    const previousPath = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageLocation) ? props.history.location.state.previousPageLocation : `/shifts`;
    const previousBreadcrumbName = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageTitle) ? props.history.location.state.previousPageTitle : 'Shifts';

    //#endregion
    //#region useLocation

    const location = useLocation();

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.shifts.isRecordLoading);
    const error = useSelector(state => state.shifts.recordError);
    const shift = useSelector(state => state.shifts.record);

    //#endregion
    //#region useStates

    const [tabKey, setTabKey] = useState('snapshot');

    //#endregion
    //#region tab methods

    const onTabChange = (key) => {
        props.history.replace({ ...props.location, hash: `#${key}` });
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        if (isStringNotEmpty(location.hash)) {
            setTabKey(location.hash.replace('#', ''));
        } else {
            setTabKey('snapshot');
            props.history.replace({ ...location, hash: `#snapshot` });
        }
    }, [location]);

    useMemo(() => {
        dispatch(actionCreators.fetchShift(id));
    }, [id]);

    //#endregion
    //#region breadcrumbs

    let breadcrumbs = [...previousBreadcrumbs,
    {
        location: previousLocation,
        path: previousPath,
        breadcrumbName: previousBreadcrumbName,
    },
    {
        path: `/shifts/${isStringNotEmpty(id) ? id : ''}`,
        breadcrumbName: `${isObjectNotEmpty(shift) && isStringNotEmpty(shift.name) ? shift.name : ''}`,
    },
    ];

    //#endregion

    if (isObjectNotEmpty(shift) && shift.id === id) {
        return (
            <Can entityAction="READ" entityModel="SHIFT" entityObject={shift} accountId={shift.accountId}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Header
                        title={isStringNotEmpty(shift.name) ? shift.name : 'Loading...'}
                        icon={<FontAwesomeIcon className="anticon" icon={faCalendarCheck} />}
                        breadcrumbs={breadcrumbs}
                    />
                    <Tabs activeKey={tabKey} onChange={onTabChange} destroyInactiveTabPane={true} tabBarStyle={{ backgroundColor: '#ffffff', paddingRight: 24, paddingLeft: 24, margin: 0 }}>
                        <TabPane tab="Snapshot" key="snapshot">
                            <div style={{ padding: 24 }}>
                                <Row gutter={[16, 16]}>
                                    <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                        <ComplexDataLinkCard
                                            title="Shift Details"
                                            style={{ height: 300 }}
                                            headStyle={{ fontSize: 14 }}
                                            data={{
                                                "Start Time": { value: isStringNotEmpty(shift.startTime) ? shift.startTime : null, dataType: 'String' },
                                                "End Time": { value: isStringNotEmpty(shift.endTime) ? shift.endTime : null, dataType: 'String' },
                                                "Is End Time Next Day?": { value: isBooleanTrue(shift.isNextDay) ? shift.isNextDay : false, dataType: 'Boolean' },
                                                "Time Zone": { value: isStringNotEmpty(shift.timeZone) ? shift.timeZone : null, dataType: 'String' },
                                                "Are Employees Available?": { value: isBooleanTrue(shift.isAvailable) ? shift.isAvailable : false, dataType: 'Boolean' }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </TabPane>
                        <TabPane tab="Calendar" key="calendar">
                            <div style={{ padding: 24 }}>
                                <ComplexCard>
                                    <Calendar
                                        accountId={isStringNotEmpty(shift.accountId) ? shift.accountId : null}
                                        shiftId={isStringNotEmpty(shift.id) ? shift.id : null}
                                        breadcrumbs={breadcrumbs}
                                    />
                                </ComplexCard>
                            </div>
                        </TabPane>
                        <TabPane tab="Scheduler" key="scheduler">
                            <div style={{ padding: 24 }}>
                                <ComplexCard>
                                    <Scheduler
                                        accountId={isStringNotEmpty(shift.accountId) ? shift.accountId : null}
                                        shiftId={isStringNotEmpty(shift.id) ? shift.id : null}
                                        breadcrumbs={breadcrumbs}
                                    />
                                </ComplexCard>
                            </div>
                        </TabPane>
                        <TabPane tab="Agenda" key="agenda">
                            <div style={{ padding: 24 }}>
                                <ComplexCard>
                                    <Agenda
                                        accountId={isStringNotEmpty(shift.accountId) ? shift.accountId : null}
                                        shiftId={isStringNotEmpty(shift.id) ? shift.id : null}
                                        breadcrumbs={breadcrumbs}
                                    />
                                </ComplexCard>
                            </div>
                        </TabPane>
                    </Tabs>
                </Spin>
            </Can>
        );
    } else {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={true} />
        );
    }
};

export default withRouter(Shift);