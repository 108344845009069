import React, { useState } from 'react';
import { Button, Card, Modal } from "antd";
import classes from './DispatchDriverCard.module.scss';
import DispatchAddDriver from "../../components/DispatchAddDriver/DispatchAddDriver";
import DispatchChangeDriver from '../DispatchChangeDriver/DispatchChangeDriver';
import RemoveLoadDriver from "../../components/RemoveLoadDriver/RemoveLoadDriver";
import { useSelector } from "react-redux";
import ActionButton from '../../components/ActionButton/ActionButton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt, faIdCard, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { CloseOutlined } from '@ant-design/icons';
import ActionDoubleButton from '../ActionDoubleButton/ActionDoubleButton';
import { isBooleanTrue, isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import LoadUtils from '../../api/utils/loadUtils';

const DispatchDriverCard = ({ load, stops, drivers, shifts, bundledLoads, driverLoads, transportUnits, setAssetSideBar, setDriverSideBar, displayAsText = false, setSelectedLoadToDispatchId, isBundledLoad = false, style }) => {
    //#region constants

    const shipperId = isObjectNotEmpty(load) && isStringNotEmpty(load.shipperId) ? load.shipperId : null;
    const carrierId = isObjectNotEmpty(load) && isStringNotEmpty(load.assignedCarrierId) ? load.assignedCarrierId : null;
    const hasTeamDriving = isObjectNotEmpty(load) && isListNotEmpty(load.driverRequirements) && load.driverRequirements.includes('TEAM_DRIVING') ? true : false;
    const loadDriverIds = isObjectNotEmpty(load) && isListNotEmpty(load.driverIds) ? load.driverIds : [];
    const loadDriverId1 = isListNotEmpty(loadDriverIds) ? loadDriverIds[0] : null;
    const loadDriverId2 = isListNotEmpty(loadDriverIds) && loadDriverIds.length > 1 && hasTeamDriving === true ? loadDriverIds[1] : null;

    //#endregion
    //#region useSelectors

    const isLoading = useSelector(state => state.loads.isRecordUpdateLoading);
    const error = useSelector(state => state.loads.updateRecordError);
    const loadEventUpdateStatus = useSelector(state => state.loadEvents.loadEventUpdateStatus);

    //#endregion
    //#region useStates

    const [showRemoveDriverModal, setShowRemoveDriverModal] = useState(false);

    //#endregion
    //#region Load to Dispatch Methods

    const onSelectLoadToDispatch = () => {
        if (isBooleanTrue(isBundledLoad) && isObjectNotEmpty(load)) {
            setAssetSideBar(null);
            setDriverSideBar(null);
            setSelectedLoadToDispatchId(load.id);
        }
    };

    //#endregion
    //#region displays

    const newEntityComponents = (
        <Card
            title={"Assign Driver to Load"}
            style={{ border: '1px solid #D8D8D8', marginBottom: 12 }}
            bodyStyle={{ backgroundColor: '#F5F5F5', minHeight: 100, padding: 12 }}
            headStyle={{ backgroundColor: '#334353', color: '#ffffff', borderBottom: '1px solid #D8D8D8', fontSize: 14, textAlign: 'center' }}
            extra={<Button type="link" icon={<CloseOutlined />} onClick={(e) => { setDriverSideBar(null); }}></Button>}
        >
            <DispatchAddDriver
                cancel={() => { setDriverSideBar(null); }}
                shipperId={shipperId}
                carrierId={carrierId}
                drivers={drivers}
                shifts={shifts}
                load={load}
                stops={stops}
                isBundledLoad={isBooleanTrue(isBundledLoad)}
                bundledLoads={bundledLoads}
                driverLoads={driverLoads}
                transportUnits={transportUnits}
            />
        </Card>
    );

    const changeEntityComponents = (driver) => {
        return (
            <Card
                title={"Change Driver"}
                style={{ border: '1px solid #D8D8D8', marginBottom: 12 }}
                bodyStyle={{ backgroundColor: '#F5F5F5', minHeight: 100, padding: 12 }}
                headStyle={{ backgroundColor: '#334353', color: '#ffffff', borderBottom: '1px solid #D8D8D8', fontSize: 14, textAlign: 'center' }}
                extra={<Button type="link" icon={<CloseOutlined />} onClick={(e) => { setDriverSideBar(null); }}></Button>}
            >
                <DispatchChangeDriver
                    cancel={() => { setDriverSideBar(null); }}
                    carrierId={carrierId}
                    shipperId={shipperId}
                    drivers={drivers}
                    shifts={shifts}
                    load={load}
                    stops={stops}
                    driver={driver}
                    isBundledLoad={isBooleanTrue(isBundledLoad)}
                    bundledLoads={bundledLoads}
                    driverLoads={driverLoads}
                    transportUnits={transportUnits}
                />
            </Card>
        );
    };

    const removeEntityComponents = (driver) => {
        return (
            <Modal
                title={null}
                visible={showRemoveDriverModal === true}
                maskClosable={false}
                closable={false}
                footer={null}
                bodyStyle={{ padding: 0 }}
                destroyOnClose={true}
            >
                <Card
                    title={"Remove Driver from Load"}
                    style={{ border: '1px solid #D8D8D8' }}
                    bodyStyle={{ minHeight: 100, padding: '12px 12px 57px 12px' }}
                    headStyle={{ backgroundColor: '#334353', color: '#ffffff', borderBottom: '1px solid #D8D8D8', fontSize: 14, textAlign: 'center' }}
                    extra={<Button type="link" icon={<CloseOutlined />} onClick={(e) => { setShowRemoveDriverModal(false); }}></Button>}
                >
                    <RemoveLoadDriver
                        cancel={() => { setShowRemoveDriverModal(false); }}
                        driver={driver}
                        load={load}
                        isBundledLoad={isBooleanTrue(isBundledLoad)}
                    />
                </Card>
            </Modal>
        );
    };

    const renderDriverDetails = (loadDriver) => {
        if (isObjectNotEmpty(loadDriver) && isObjectNotEmpty(loadDriver.driver)) {
            return (
                <span>{loadDriver.firstName + ' ' + loadDriver.lastName}</span>
            );
        } else {
            return null
        }
    };

    //#endregion

    if (isObjectNotEmpty(load) && isListNotEmpty(drivers) && load.isDeleted === false && LoadUtils.loadStatusIsNot(load.loadStatus, ['CANCELLED'])) {
        let loadDriver1 = null;
        if (isStringNotEmpty(loadDriverId1)) {
            loadDriver1 = drivers.find(i => i.id === loadDriverId1);
        }
        let loadDriver2 = null;
        if (isStringNotEmpty(loadDriverId2)) {
            loadDriver2 = drivers.find(i => i.id === loadDriverId2);
        }

        if (displayAsText === true) {
            if (LoadUtils.loadStatusIs(load.loadStatus, ['BOOKED'])) {
                return (
                    <>
                        {isObjectNotEmpty(loadDriver1) && isObjectNotEmpty(loadDriver1.driver) ? (
                            <div>
                                <Button type="link" onClick={() => { onSelectLoadToDispatch(); setDriverSideBar(changeEntityComponents(loadDriver1)); setAssetSideBar(null); }}>{renderDriverDetails(loadDriver1)}</Button>
                            </div>
                        ) : (
                                <div>
                                    <Button type="link" onClick={() => { onSelectLoadToDispatch(); setDriverSideBar(newEntityComponents); setAssetSideBar(null); }}><span>Assign {hasTeamDriving === true ? 'Driver 1' : 'Driver'}</span></Button>
                                </div>
                            )}
                        {hasTeamDriving === true && isObjectNotEmpty(loadDriver2) && isObjectNotEmpty(loadDriver2.driver) ? (
                            <div>
                                <Button type="link" onClick={() => { onSelectLoadToDispatch(); setDriverSideBar(changeEntityComponents(loadDriver2)); setAssetSideBar(null); }}><span>{renderDriverDetails(loadDriver2)}</span></Button>
                            </div>
                        ) : (hasTeamDriving === true ? (
                            <div>
                                <Button type="link" onClick={() => { onSelectLoadToDispatch(); setDriverSideBar(newEntityComponents); setAssetSideBar(null); }}><span>Assign Driver 2</span></Button>
                            </div>
                        ) : null)}
                    </>
                );
            } else if (LoadUtils.loadStatusIs(load.loadStatus, ['SCHEDULED', 'IN_TRANSIT', 'AT_STOP', 'COMPLETED', 'CLOSED', 'REOPENED'])) {
                return (
                    <>
                        {isObjectNotEmpty(loadDriver1) && isObjectNotEmpty(loadDriver1.driver) ? (
                            <div>{renderDriverDetails(loadDriver1)}</div>
                        ) : null}
                        {hasTeamDriving === true && isObjectNotEmpty(loadDriver2) && isObjectNotEmpty(loadDriver2.driver) ? (
                            <div>{renderDriverDetails(loadDriver2)}</div>
                        ) : null}
                    </>
                );
            } else {
                return null;
            }
        } else {
            if (LoadUtils.loadStatusIs(load.loadStatus, ['BOOKED'])) {
                return (
                    <>
                        {isObjectNotEmpty(loadDriver1) && isObjectNotEmpty(loadDriver1.driver) ? (
                            <div style={style}>
                                <ActionDoubleButton
                                    iconPosition="left"
                                    buttonGroupClassName={classes.buttonGroup}
                                    buttonClassName={`${classes.buttonGroupButton} ${classes.whiteButtonSolidBorder}`}
                                    buttonIconClassName={classes.iconContainer}
                                    buttonTextClassName={classes.textContainer}
                                    button1Icon1={<FontAwesomeIcon className={classes.button1icon1} icon={faIdCard} />}
                                    button1Icon2={<FontAwesomeIcon className={classes.button1icon2} icon={faExchangeAlt} />}
                                    button2Icon1={<FontAwesomeIcon className={classes.button2icon1} icon={faTimes} />}
                                    buttonText={renderDriverDetails(loadDriver1)}
                                    button1Style={{ width: '100%' }}
                                    button2Style={{ minWidth: 'unset' }}
                                    onClick1={() => { setDriverSideBar(changeEntityComponents(loadDriver1)); setAssetSideBar(null); }}
                                    onClick2={() => { setShowRemoveDriverModal(true); }}
                                    disabled={((isLoading === true && error === null) || loadEventUpdateStatus === 'PENDING')}
                                />
                                {removeEntityComponents(loadDriver1)}
                            </div>
                        ) : (
                                <div style={style}>
                                    <ActionButton
                                        iconPosition="left"
                                        buttonClassName={`${classes.button} ${classes.whiteButtonDashedBorder}`}
                                        buttonIconClassName={classes.iconContainer}
                                        buttonTextClassName={classes.textContainer}
                                        buttonIcon={<FontAwesomeIcon className={classes.button1icon1} icon={faIdCard} />}
                                        buttonIcon2={<FontAwesomeIcon className={classes.button1icon2} icon={faPlus} />}
                                        buttonText={<span>Assign {hasTeamDriving === true ? 'Driver 1' : 'Driver'}</span>}
                                        onClick={() => { setDriverSideBar(newEntityComponents); setAssetSideBar(null); }}
                                        disabled={((isLoading === true && error === null) || loadEventUpdateStatus === 'PENDING')}
                                    />
                                </div>
                            )}
                        {hasTeamDriving === true && isObjectNotEmpty(loadDriver2) && isObjectNotEmpty(loadDriver2.driver) ? (
                            <div style={style}>
                                <ActionDoubleButton
                                    iconPosition="left"
                                    buttonGroupStyle={{ marginTop: 12 }}
                                    buttonGroupClassName={classes.buttonGroup}
                                    buttonClassName={`${classes.buttonGroupButton} ${classes.whiteButtonSolidBorder}`}
                                    buttonIconClassName={classes.iconContainer}
                                    buttonTextClassName={classes.textContainer}
                                    button1Icon1={<FontAwesomeIcon className={classes.button1icon1} icon={faIdCard} />}
                                    button1Icon2={<FontAwesomeIcon className={classes.button1icon2} icon={faExchangeAlt} />}
                                    button2Icon1={<FontAwesomeIcon className={classes.button2icon1} icon={faTimes} />}
                                    buttonText={renderDriverDetails(loadDriver2)}
                                    button1Style={{ width: '100%' }}
                                    button2Style={{ minWidth: 'unset' }}
                                    onClick1={() => { setDriverSideBar(changeEntityComponents(loadDriver2)); setAssetSideBar(null); }}
                                    onClick2={() => { setShowRemoveDriverModal(true); }}
                                    disabled={((isLoading === true && error === null) || loadEventUpdateStatus === 'PENDING')}
                                />
                                {removeEntityComponents(loadDriver2)}
                            </div>
                        ) : (hasTeamDriving === true ? (
                            <div style={style}>
                                <ActionButton
                                    iconPosition="left"
                                    buttonStyle={{ marginTop: 12 }}
                                    buttonClassName={`${classes.button} ${classes.whiteButtonDashedBorder}`}
                                    buttonIconClassName={classes.iconContainer}
                                    buttonTextClassName={classes.textContainer}
                                    buttonIcon={<FontAwesomeIcon className={classes.button1icon1} icon={faIdCard} />}
                                    buttonIcon2={<FontAwesomeIcon className={classes.button1icon2} icon={faPlus} />}
                                    buttonText={<span>Assign Driver 2</span>}
                                    onClick={() => { setDriverSideBar(newEntityComponents); setAssetSideBar(null); }}
                                    disabled={((isLoading === true && error === null) || loadEventUpdateStatus === 'PENDING')}
                                />
                            </div>
                        ) : null)}
                    </>
                );
            } else {
                return null;
            }
        }
    } else {
        return null;
    }
};

export default DispatchDriverCard;