import Checks from '../entitlementUtils';

export const canCreateLane = (auth) => {
    return Checks.allowStaff(auth);
};

export const canReadLane = (auth) => {
    return Checks.allowStaff(auth);
};

export const canReadLaneList = (auth) => {
    return Checks.allowStaff(auth);
};

export const canUpdateLane = (auth) => {
    return Checks.allowStaff(auth);
};

export const canDeleteLane = (auth) => {
    return Checks.allowStaffAdmin(auth);
};