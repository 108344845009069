import React, { useMemo, useState } from 'react';
import { Alert, Input, InputNumber, Select, Spin } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import FormButtons from '../FormButtons/FormButtons';
import { isObjectNotEmpty, removeEmpty } from '../../shared/objectUtils';
import Enums from '../../shared/enums';
import FormItemDouble from '../FormItemDouble/FormItemDouble';

const { Option } = Select;

const NewService = ({ cancel }) => {
    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.services.isRecordAddLoading);
    const error = useSelector(state => state.services.addRecordError);
    const features = useSelector(state => state.orchestrator.features);

    const currencyOptions = Enums.Currencies.selectListOptions();
    const serviceTypeOptions = Enums.ServiceTypes.selectListOptions();
    const billingFrequencyOptions = Enums.BillingFrequencies.selectListOptions();
    const loadServiceTypeOptions = Enums.LoadServiceTypes.selectListOptions();

    const [showLoadServiceType, setShowLoadServiceType] = useState(false);

    const onServiceTypeChange = (value) => {
        //console.log(`selected ${value}`);
        if (value === 'LOAD') {
            setShowLoadServiceType(true);
        } else {
            setShowLoadServiceType(false);
        }
    };

    const onSubmit = (data) => {
        let cleanedData = removeEmpty(data);

        if (isObjectNotEmpty(cleanedData)) {
            dispatch(actionCreators.addService(cleanedData));
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.addServiceCancel());
        cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.addServiceErrorClear());
        dispatch(actionCreators.getFeatures());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const formItemLayoutDouble = {
        labelCol1: { span: 8 },
        wrapperCol1: { span: 8 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 8 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <FormItem {...formItemLayout} label="Name (ENUM)" required format="horizontal"
                        render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Name" ref={ref} />}
                        rules={{ required: 'Required Field' }}
                        name="name"
                    />
                    <FormItem {...formItemLayout} label="Display Name" required format="horizontal"
                        render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Display Name" ref={ref} />}
                        rules={{ required: 'Required Field' }}
                        name="displayName"
                    />
                    <FormItem {...formItemLayout} label="Description" format="horizontal"
                        render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Description" ref={ref} />}
                        rules={{ required: false }}
                        name="description"
                    />
                    <FormItem {...formItemLayout} label="Features" required format="horizontal"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Select
                                placeholder="Please Select the Features"
                                mode="multiple"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                                ref={ref}
                            >
                                {features.map(i => <Option value={i.id} key={i.id}>{i.displayName}</Option>)}
                            </Select>
                        )}
                        rules={{ required: "Required Field" }}
                        name="featureIds"
                    />
                    <FormItem {...formItemLayout} label="Service Type" required format="horizontal"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Select
                                placeholder="Please Select a Service Type"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onServiceTypeChange(selected); onChange(selected); }}
                                value={value}
                                name={name}
                                ref={ref}
                            >
                                {serviceTypeOptions}
                            </Select>
                        )}
                        rules={{ required: "Required Field" }}
                        name="serviceType"
                    />
                    {(showLoadServiceType === true) ? (
                        <FormItem {...formItemLayout} label="Load Service Type" required format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select the Load Service Type"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                >
                                    {loadServiceTypeOptions}
                                </Select>
                            )}
                            rules={{ required: "Required Field" }}
                            name="loadServiceType"
                        />
                    ) : null}
                    <FormItemDouble {...formItemLayoutDouble} label1="Price" required1 required2 format="horizontal"
                        render1={({ onChange, onBlur, value, name, ref }) => (
                            <InputNumber
                                precision={2}
                                min={0}
                                placeholder="Price"
                                style={{ width: '100%' }}
                                onBlur={onBlur}
                                onChange={e => { onChange(e); }}
                                value={value}
                                name={name}
                                ref={ref}
                            />
                        )}
                        rules1={{ required: "Required Field" }}
                        name1="priceAmount"
                        render2={({ onChange, onBlur, value, name, ref }) => (
                            <Select
                                placeholder="Please Select"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                                ref={ref}
                            >
                                {currencyOptions}
                            </Select>
                        )}
                        rules2={{ required: "Required Field" }}
                        name2="priceAmountUnit"
                    />
                    <FormItem {...formItemLayout} label="Billing Frequency" required format="horizontal"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Select
                                placeholder="Please Select the Billing Frequency"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                                ref={ref}
                            >
                                {billingFrequencyOptions}
                            </Select>
                        )}
                        rules={{ required: "Required Field" }}
                        name="billingFrequency"
                    />
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Create Service" />
            </Form>
        </FormProvider>
    );
};

export default NewService;