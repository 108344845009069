import Checks from '../entitlementUtils';

export const canCreateConfiguration = (auth) => {
    return Checks.allowStaffAdmin(auth);
};

export const canReadConfiguration = (auth) => {
    return Checks.allowStaffAdmin(auth);
};

export const canReadConfigurationList = (auth) => {
    return Checks.allowStaffAdmin(auth);
};

export const canUpdateConfiguration = (auth) => {
    return Checks.allowStaffAdmin(auth);
};

export const canDeleteConfiguration = (auth) => {
    return Checks.allowStaffAdmin(auth);
};