import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Button, Select, Checkbox, InputNumber, Row, Col, Empty } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import Fieldset from '../FormFieldset/FormFieldset';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from "../../store/actions/index";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck } from '@fortawesome/free-solid-svg-icons';
import Header from '../Header/Header';
import classes from './NewLoadEquipment.module.scss';
import { convertToObject, isListNotEmpty, isNotNullOrUndefined, isNumberEmpty, isNumberNotEmpty, isObjectNotEmpty, isStringEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

const NewLoadEquipment = ({ equipmentData, setEquipmentData, completedSteps, setCompletedSteps, previousStepAction, previousStepActionName, nextStepAction, nextStepActionName }) => {
    //#region useForms

    const methods = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            trailerTypeId: null,
            trailerLength: null,
            trailerWeightCapacity: null,
            minRunTemp: null,
            maxRunTemp: null,
            preCoolReefer: null,
            equipmentSpecifics: null,
            driverRequirements: null
        }
    });

    //#endregion

    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const lineItemTypes = useSelector(state => state.orchestrator.lineItemTypes);
    const trailerTypes = useSelector(state => state.orchestrator.trailerTypes);

    //#endregion
    //#region useStates

    const [equipmentSpecificOptions, setEquipmentSpecificOptions] = useState([]);
    const [driverRequirementOptions, setDriverRequirementOptions] = useState([]);
    const [showReeferOptions, setShowReeferOptions] = useState(false);
    const [trailerTypeId, setTrailerTypeId] = useState(null);
    const [trailerLength, setTrailerLength] = useState(null);
    const [trailerWeightCapacity, setTrailerWeightCapacity] = useState(null);
    const [equipmentSpecifics, setEquipmentSpecifics] = useState([]);
    const [driverRequirements, setDriverRequirements] = useState([]);

    //#endregion
    //#region useRefs

    const preCoolReefer = useRef(null);
    const minRunTemp = useRef(null);
    const maxRunTemp = useRef(null);

    //#endregion
    //#region validators

    const validateTemperatureRequirements = (equipmentData) => {
        let hasErrors = false;

        if (isObjectNotEmpty(equipmentData)) {
            let minRunTemp = equipmentData.minRunTemp;
            let maxRunTemp = equipmentData.maxRunTemp;
            let preCoolReefer = equipmentData.preCoolReefer;
            if (isNumberNotEmpty(preCoolReefer) && isNumberNotEmpty(minRunTemp) && isNumberNotEmpty(maxRunTemp)) {
                if (preCoolReefer < minRunTemp || preCoolReefer > maxRunTemp) {
                    methods.setError('preCoolReefer', { type: 'gtelte', message: 'Pre-Cool Temp must be greater than or equal to the Min Run Temp and less than or equal to Max Run Temp' });
                    hasErrors = true;
                }
                if (minRunTemp > preCoolReefer || minRunTemp > maxRunTemp) {
                    methods.setError('minRunTemp', { type: 'lte', message: 'Min Run Temp must be less than or equal to the Pre-Cool Temp and Max Run Temp' });
                    hasErrors = true;
                }
                if (maxRunTemp < preCoolReefer || maxRunTemp < minRunTemp) {
                    methods.setError('maxRunTemp', { type: 'gte', message: 'Max Run Temp must be greater than or equal to the Pre-Cool Temp and Min Run Temp' });
                    hasErrors = true;
                }
            } else {
                if (preCoolReefer === undefined || preCoolReefer === null) {
                    methods.setError('preCoolReefer', { type: 'required', message: 'Required Field' });
                    hasErrors = true;
                }
                if (minRunTemp === undefined || minRunTemp === null) {
                    methods.setError('minRunTemp', { type: 'required', message: 'Required Field' });
                    hasErrors = true;
                }
                if (maxRunTemp === undefined || maxRunTemp === null) {
                    methods.setError('maxRunTemp', { type: 'required', message: 'Required Field' });
                    hasErrors = true;
                }
            }
        }

        return !hasErrors;
    };

    const validateTrailerLength = (equipmentData) => {
        let hasErrors = false;
        methods.clearErrors('trailerLength');

        if (isObjectNotEmpty(equipmentData)) {
            if (isNumberEmpty(equipmentData.trailerLength)) {
                methods.setError('trailerLength', { type: 'required', message: 'Required Field' });
                hasErrors = true;
            }
        }

        return !hasErrors;
    };

    const validateTrailerWeightCapacity = (equipmentData) => {
        let hasErrors = false;
        methods.clearErrors('trailerWeightCapacity');

        if (isObjectNotEmpty(equipmentData)) {
            if (isNumberEmpty(equipmentData.trailerWeightCapacity)) {
                methods.setError('trailerWeightCapacity', { type: 'required', message: 'Required Field' });
                hasErrors = true;
            }
        }

        return !hasErrors;
    };

    const validateTrailerTypeId = (equipmentData) => {
        let hasErrors = false;
        methods.clearErrors('trailerTypeId');

        if (isObjectNotEmpty(equipmentData)) {
            if (isStringEmpty(equipmentData.trailerTypeId)) {
                methods.setError('trailerTypeId', { type: 'required', message: 'Required Field' });
                hasErrors = true;
            }
        }

        return !hasErrors;
    };

    const validateEquipmentNeedsAndServices = (updatedEquipmentData) => {
        let hasErrors = false;

        if (!validateTrailerTypeId(updatedEquipmentData)) { hasErrors = true; }
        if (!validateTrailerLength(updatedEquipmentData)) { hasErrors = true; }
        if (!validateTrailerWeightCapacity(updatedEquipmentData)) { hasErrors = true; }
        if (isObjectNotEmpty(updatedEquipmentData)) {
            if (isStringNotEmpty(updatedEquipmentData.trailerTypeId)) {
                if (isListNotEmpty(trailerTypes)) {
                    let selectedTrailerType = trailerTypes.find(s => s.id === updatedEquipmentData.trailerTypeId);
                    if (isObjectNotEmpty(selectedTrailerType)) {
                        if (selectedTrailerType.name === 'REEFER') {
                            if (!validateTemperatureRequirements(updatedEquipmentData)) { hasErrors = true; }
                        }
                    }
                }
            }
        } else {
            methods.setError('trailerTypeId', { type: 'required', message: 'Required Field' });
            hasErrors = true;
        }

        return !hasErrors;
    };

    //#endregion
    //#region onChanges

    const onChangeTrailerType = (value) => {
        setTrailerTypeId(value);
        if (isStringNotEmpty(value)) {
            let equipmentSpecificOptionsList = [];
            let driverRequirementOptionsList = [];
            if (isListNotEmpty(lineItemTypes)) {
                let equipmentSpecifics = lineItemTypes.filter(i => i.trailerTypeIds.includes(value) && i.itemType === 'EQUIPMENT_SPECIFIC');
                let driverRequirements = lineItemTypes.filter(i => i.trailerTypeIds.includes(value) && i.itemType === 'LOAD_REQUIREMENT');

                for (let i = 0; i < equipmentSpecifics.length; i++) {
                    equipmentSpecificOptionsList.push({ value: equipmentSpecifics[i].name, key: equipmentSpecifics[i].name, label: equipmentSpecifics[i].description });
                }
                for (let i = 0; i < driverRequirements.length; i++) {
                    driverRequirementOptionsList.push({ value: driverRequirements[i].name, key: driverRequirements[i].name, label: driverRequirements[i].description });
                }
            }
            setEquipmentSpecificOptions(equipmentSpecificOptionsList);
            setDriverRequirementOptions(driverRequirementOptionsList);

            if (isListNotEmpty(trailerTypes)) {
                let selectedTrailerType = trailerTypes.find(s => s.id === value);
                if (isObjectNotEmpty(selectedTrailerType)) {
                    if (selectedTrailerType.name === "REEFER") {
                        setShowReeferOptions(true);
                    } else {
                        setShowReeferOptions(false);
                    }
                }
            }
        } else {
            setEquipmentSpecificOptions([]);
            setDriverRequirementOptions([]);
            setShowReeferOptions(false);
        }
    };

    //#endregion
    //#region submits

    const saveForm = () => {
        const payload = methods.getValues();
        //console.log(payload);
        let data = convertToObject(payload);

        let trailerTypeId = data.trailerTypeId;
        let trailerLength = data.trailerLength;
        let trailerWeightCapacity = data.trailerWeightCapacity;
        let minRunTemp = data.minRunTemp;
        let maxRunTemp = data.maxRunTemp;
        let preCoolReefer = data.preCoolReefer;
        let equipmentSpecifics = data.equipmentSpecifics;
        let driverRequirements = data.driverRequirements;

        let updatedValues = {};

        if (isStringNotEmpty(trailerTypeId)) {
            updatedValues.trailerTypeId = trailerTypeId;
            if (isListNotEmpty(trailerTypes)) {
                let selectedTrailerType = trailerTypes.find(s => s.id === trailerTypeId);
                if (isObjectNotEmpty(selectedTrailerType)) {
                    updatedValues.trailerType = selectedTrailerType;
                }
            }
        }

        if (isNumberNotEmpty(trailerLength)) {
            updatedValues.trailerLength = trailerLength;
            updatedValues.trailerLengthUnit = "FT";
        }

        if (isNumberNotEmpty(trailerWeightCapacity)) {
            updatedValues.trailerWeightCapacity = trailerWeightCapacity;
            updatedValues.trailerWeightCapacityUnit = "LB";
        }

        if (isNumberNotEmpty(preCoolReefer)) {
            updatedValues.preCoolReefer = Number(preCoolReefer);
            updatedValues.preCoolReeferUnit = "F";
        }
        if (isNumberNotEmpty(minRunTemp)) {
            updatedValues.minRunTemp = Number(minRunTemp);
            updatedValues.minRunTempUnit = "F";
        }
        if (isNumberNotEmpty(maxRunTemp)) {
            updatedValues.maxRunTemp = Number(maxRunTemp);
            updatedValues.maxRunTempUnit = "F";
        }

        if (isNotNullOrUndefined(equipmentSpecifics)) {
            updatedValues.equipmentSpecifics = equipmentSpecifics;
        }

        if (isNotNullOrUndefined(driverRequirements)) {
            updatedValues.driverRequirements = driverRequirements;
        }

        let updatedEquipmentData = {
            ...equipmentData,
            ...updatedValues
        };

        setEquipmentData(updatedEquipmentData);
    };

    const onSubmit = (data) => {
        if (validateEquipmentNeedsAndServices(data)) {
            saveForm();
            setCompletedSteps({ ...completedSteps, equipment: true });
            nextStepAction('services');
        } else {
            return;
        }
    };

    //#endregion
    //#region useEffects and useMemos

    useMemo(() => {
        dispatch(actionCreators.getLineItemTypes());
        dispatch(actionCreators.getTrailerTypes());
    }, []);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            saveForm();
        }

        return () => isSubscribed = false;
    }, [trailerTypeId, trailerLength, trailerWeightCapacity, minRunTemp.current, maxRunTemp.current, preCoolReefer.current, equipmentSpecifics, driverRequirements]);

    useMemo(() => {
        //console.log(equipmentData)
        if (isObjectNotEmpty(equipmentData) && isListNotEmpty(lineItemTypes) && isListNotEmpty(trailerTypes)) {
            if (isStringNotEmpty(equipmentData.trailerTypeId)) {
                methods.setValue('trailerTypeId', equipmentData.trailerTypeId);
                let equipmentSpecificOptionsList = [];
                let driverRequirementOptionsList = [];
                let equipmentSpecifics = lineItemTypes.filter(i => i.trailerTypeIds.includes(equipmentData.trailerTypeId) && i.itemType === 'EQUIPMENT_SPECIFIC');
                let driverRequirements = lineItemTypes.filter(i => i.trailerTypeIds.includes(equipmentData.trailerTypeId) && i.itemType === 'LOAD_REQUIREMENT');

                for (let i = 0; i < equipmentSpecifics.length; i++) {
                    equipmentSpecificOptionsList.push({ value: equipmentSpecifics[i].name, key: equipmentSpecifics[i].name, label: equipmentSpecifics[i].description });
                }
                for (let i = 0; i < driverRequirements.length; i++) {
                    driverRequirementOptionsList.push({ value: driverRequirements[i].name, key: driverRequirements[i].name, label: driverRequirements[i].description });
                }
                setEquipmentSpecificOptions(equipmentSpecificOptionsList);
                setDriverRequirementOptions(driverRequirementOptionsList);

                let selectedTrailerType = trailerTypes.find(s => s.id === equipmentData.trailerTypeId);
                if (isObjectNotEmpty(selectedTrailerType)) {
                    if (selectedTrailerType.name === "REEFER") {
                        setShowReeferOptions(true);
                    } else {
                        setShowReeferOptions(false);
                    }
                }
            } else {
                setEquipmentSpecificOptions([]);
                setDriverRequirementOptions([]);
                setShowReeferOptions(false);
            }
            if (isNumberNotEmpty(equipmentData.trailerLength)) {
                methods.setValue('trailerLength', equipmentData.trailerLength);
            }
            if (isNumberNotEmpty(equipmentData.trailerWeightCapacity)) {
                methods.setValue('trailerWeightCapacity', equipmentData.trailerWeightCapacity);
            }
            if (isNotNullOrUndefined(equipmentData.equipmentSpecifics)) {
                methods.setValue('equipmentSpecifics', equipmentData.equipmentSpecifics);
            }
            if (isNotNullOrUndefined(equipmentData.driverRequirements)) {
                methods.setValue('driverRequirements', equipmentData.driverRequirements);
            }
        }
    }, [equipmentData, lineItemTypes, trailerTypes]);

    useMemo(() => {
        if (showReeferOptions === true && isObjectNotEmpty(equipmentData)) {
            if (isNumberNotEmpty(equipmentData.preCoolReefer)) {
                methods.setValue('preCoolReefer', equipmentData.preCoolReefer);
            }
            if (isNumberNotEmpty(equipmentData.minRunTemp)) {
                methods.setValue('minRunTemp', equipmentData.minRunTemp);
            }
            if (isNumberNotEmpty(equipmentData.maxRunTemp)) {
                methods.setValue('maxRunTemp', equipmentData.maxRunTemp);
            }
        }
    }, [showReeferOptions, equipmentData]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    //#endregion

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className="load-creation-step-container">
                    <div className="load-creation-step">
                        <Header
                            titleStyle={{ fontSize: 18, fontWeight: 500 }}
                            style={{ paddingLeft: 0, paddingRight: 0 }}
                            title="Equipment Needs and Services"
                            icon={<FontAwesomeIcon className="anticon" icon={faTruck} />}
                            iconStyle={{ background: 'transparent' }}
                        />
                        <Fieldset legend="Trailer and Equipment Specifics">
                            <Row gutter={[4, 4]}>
                                <Col span={8}>
                                    <FormItem {...formItemLayout} label="Trailer Type" required format="vertical"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Select
                                                placeholder="Please Select a Trailer Type"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChangeTrailerType(selected); onChange(selected); }}
                                                value={value}
                                                name={name}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                ref={ref}
                                            >
                                                {trailerTypes.map(i => <Option value={i.id} key={i.id}>{i.description}</Option>)}
                                            </Select>
                                        )}
                                        rules={{ required: 'Required Field' }}
                                        name='trailerTypeId'
                                    />
                                </Col>
                                <Col span={8}>
                                    <FormItem {...formItemLayout} label="Trailer Length (ft)" required format="vertical"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <InputNumber
                                                step={1}
                                                min={0}
                                                precision={0}
                                                placeholder="Trailer Length (ft)"
                                                style={{ width: '100%' }}
                                                onBlur={onBlur}
                                                onChange={e => { setTrailerLength(e); onChange(e); }}
                                                value={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        rules={{ required: 'Required Field' }}
                                        name='trailerLength'
                                    />
                                </Col>
                                <Col span={8}>
                                    <FormItem {...formItemLayout} label="Load Weight (lbs)" required format="vertical"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <InputNumber
                                                step={1000}
                                                min={0}
                                                precision={0}
                                                placeholder="Load Weight (lbs)"
                                                style={{ width: '100%' }}
                                                onBlur={onBlur}
                                                onChange={e => { setTrailerWeightCapacity(e); onChange(e); }}
                                                value={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        rules={{ required: 'Required Field' }}
                                        name='trailerWeightCapacity'
                                    />
                                </Col>
                            </Row>
                        </Fieldset>
                        {showReeferOptions === true ? (
                            <Fieldset legend="Temperature Requirements">
                                <Row gutter={[4, 4]}>
                                    <Col span={8}>
                                        <FormItem {...formItemLayout} label="Pre-Cool Temp" required format="vertical"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    placeholder="Pre-Cool Temp"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { preCoolReefer.current = e; onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            rules={{
                                                required: 'Required Field',
                                                validate: {
                                                    gteMin: value => (minRunTemp.current ? value >= minRunTemp.current : true) || 'Pre-Cool Temp must be greater than or equal to the Min Run Temp.',
                                                    lteMax: value => (maxRunTemp.current ? value <= maxRunTemp.current : true) || 'Pre-Cool Temp must be less than or equal to Max Run Temp.'
                                                }
                                            }}
                                            name='preCoolReefer'
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <FormItem {...formItemLayout} label="Min Run Temp" required format="vertical"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    placeholder="Min Run Temp"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { minRunTemp.current = e; onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            rules={{
                                                required: 'Required Field',
                                                validate: {
                                                    lte: value => (preCoolReefer.current && maxRunTemp.current ? value <= preCoolReefer.current && value <= maxRunTemp.current : true) || 'Min Run Temp must be less than or equal to the Pre-Cool Temp and Max Run Temp'
                                                }
                                            }}
                                            name='minRunTemp'
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <FormItem {...formItemLayout} label="Max Run Temp" required format="vertical"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    placeholder="Max Run Temp"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { maxRunTemp.current = e; onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            rules={{
                                                required: 'Required Field',
                                                validate: {
                                                    gte: value => (preCoolReefer.current && minRunTemp.current ? value >= preCoolReefer.current && value >= minRunTemp.current : true) || 'Max Run Temp must be greater than or equal to the Pre-Cool Temp and Min Run Temp'
                                                }
                                            }}
                                            name='maxRunTemp'
                                        />
                                    </Col>
                                </Row>
                            </Fieldset>
                        ) : null}
                        {isStringNotEmpty(equipmentData.trailerTypeId) ? (
                            <Fieldset legend="Additional Equipment and Services">
                                <FormItem {...formItemLayout} format="vertical"
                                    render={({ onChange, onBlur, value, name, ref }) => {
                                        if (isStringNotEmpty(equipmentData.trailerTypeId)) {
                                            if (isListNotEmpty(equipmentSpecificOptions)) {
                                                return (
                                                    <Checkbox.Group
                                                        onBlur={onBlur}
                                                        options={equipmentSpecificOptions}
                                                        onChange={(checkedValues) => { setEquipmentSpecifics(checkedValues); onChange(checkedValues); }}
                                                        value={value}
                                                        name={name}
                                                    />
                                                );
                                            } else {
                                                return (
                                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but there are no options available for the selected trailer type." />
                                                );
                                            }
                                        } else {
                                            return (
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="You must first select a trailer type to see available options." />
                                            );
                                        }
                                    }}
                                    rules={{ required: false }}
                                    name='equipmentSpecifics'
                                />
                            </Fieldset>
                        ) : (
                            <div style={{ marginTop: 18 }}>
                                <Button style={{ paddingLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} disabled={!(equipmentData.trailerTypeId !== undefined && equipmentData.trailerTypeId !== null)} onClick={() => { return; }}>Additional Equipment and Services</Button>
                            </div>
                        )}
                        {isStringNotEmpty(equipmentData.trailerTypeId) ? (
                            <Fieldset legend="Driver Requirements">
                                <FormItem {...formItemLayout} format="vertical"
                                    render={({ onChange, onBlur, value, name, ref }) => {
                                        if (isStringNotEmpty(equipmentData.trailerTypeId)) {
                                            if (isListNotEmpty(driverRequirementOptions)) {
                                                return (
                                                    <Checkbox.Group
                                                        onBlur={onBlur}
                                                        options={driverRequirementOptions}
                                                        onChange={(checkedValues) => { setDriverRequirements(checkedValues); onChange(checkedValues); }}
                                                        value={value}
                                                        name={name}
                                                    />
                                                );
                                            } else {
                                                return (
                                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but there are no options available for the selected trailer type." />
                                                );
                                            }
                                        } else {
                                            return (
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="You must first select a trailer type to see available options." />
                                            );
                                        }
                                    }}
                                    rules={{ required: false }}
                                    name='driverRequirements'
                                />
                            </Fieldset>
                        ) : (
                            <div style={{ marginTop: 18 }}>
                                <Button style={{ paddingLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} disabled={!(equipmentData.trailerTypeId !== undefined && equipmentData.trailerTypeId !== null)} onClick={() => { return; }}>Driver Requirements</Button>
                            </div>
                        )}
                    </div>
                    <Row gutter={[12, 12]} className="load-creation-step-buttons">
                        <Col xs={{ span: 24, order: 2 }} md={{ span: 12, order: 1 }}>
                            {isNotNullOrUndefined(previousStepAction) ? (<Button type="default" block onClick={() => { previousStepAction('schedule'); }}>{previousStepActionName}</Button>) : null}
                        </Col>
                        <Col xs={{ span: 24, order: 1 }} md={{ span: 12, order: 2 }}>
                            <Button type="primary" block htmlType="submit">{nextStepActionName}</Button>
                        </Col>
                    </Row>
                </div>
            </Form>
        </FormProvider>
    );
};

export default NewLoadEquipment;