import React, { useMemo, useState } from 'react';
import { Alert, Input, Spin, Select, Tooltip, Button, Col, Row, Drawer, InputNumber } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import FormButtons from '../FormButtons/FormButtons';
import Enums from '../../shared/enums';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import Fieldset from '../FormFieldset/FormFieldset';
import { isListNotEmpty, isNumberNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import FormItemDisplay from '../FormItemDisplay/FormItemDisplay';
import DataRow from '../DataRow/DataRow';

const { Option } = Select;

const NewProduct = ({ accountId, cancel }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    //#endregion
    //#region useForms

    const methods = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
    });
    const methods2 = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
    });
    const methods3 = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
    });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.products.isRecordAddLoading);
    const error = useSelector(state => state.products.addRecordError);
    const accounts = useSelector(state => state.orchestrator.accounts);
    const commodities = useSelector(state => state.orchestrator.commodities);

    //#endregion
    //#region useStates

    const [variants, setVariants] = useState([]);
    const [showAddVariant, setShowAddVariant] = useState(false);
    const [showEditVariant, setShowEditVariant] = useState(false);
    const [selectedVariantIndex, setSelectedVariantIndex] = useState(null);

    //#endregion
    //#region enums

    const currencyOptions = Enums.Currencies.selectListOptions();
    const weightUnitOptions = Enums.WeightUnits.selectListOptions();

    //#endregion
    //#region toggles

    const toggleNewVariant = () => {
        // reset form
        methods2.setValue('name', null);
        methods2.setValue('sku', null);
        methods2.setValue('unitPrice', null);
        methods2.setValue('unitPriceUnit', 'USD');
        methods2.setValue('unitWeight', null);
        methods2.setValue('unitWeightUnit', 'LB');
        methods2.setValue('quantityPerUnit', null);
        methods2.clearErrors();

        setShowAddVariant(!showAddVariant);
    };

    const toggleEditVariant = () => {
        // reset form
        methods3.setValue('name', null);
        methods3.setValue('sku', null);
        methods3.setValue('unitPrice', null);
        methods3.setValue('unitPriceUnit', 'USD');
        methods3.setValue('unitWeight', null);
        methods3.setValue('unitWeightUnit', 'LB');
        methods3.setValue('quantityPerUnit', null);
        methods3.clearErrors();

        setShowEditVariant(!showEditVariant);
    };

    //#endregion
    //#region variant methods

    const addVariant = (data) => {
        // console.log(data);

        setVariants(oldList => [...oldList, { ...data }]);
        setShowAddVariant(false);
    };

    const updateVariant = (data) => {
        // console.log(data);
        if (isNumberNotEmpty(selectedVariantIndex)) {
            let variantToUpdate = variants[selectedVariantIndex];
            let existingList = [...variants];
            if (isObjectNotEmpty(variantToUpdate)) {
                const updatedRecords = [
                    ...existingList.slice(0, selectedVariantIndex), // everything before current obj
                    data,
                    ...existingList.slice(selectedVariantIndex + 1), // everything after current obj
                ];

                setVariants([...updatedRecords]);
                setShowEditVariant(false);
                setSelectedVariantIndex(null);
            }
        }
    };

    const removeVariant = (index) => {
        let updatedVariants = [...variants];
        updatedVariants.splice(index, 1);
        setVariants([...updatedVariants]);
    };

    //#endregion
    //#region onSubmit and onCancel

    const onSubmit = (data) => {
        if (isStringNotEmpty(accountId)) {
            data.accountId = accountId;
        }

        if (isListNotEmpty(variants)) {
            data.variants = [...variants];
        }

        if (isStringNotEmpty(data.accountId)) {
            dispatch(actionCreators.addProduct(data));
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.addProductCancel());
        cancel();
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.addProductErrorClear());
        dispatch(actionCreators.getAccounts());
        dispatch(actionCreators.getCommodities());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    const formItemLayoutDouble = {
        labelCol1: { span: 12 },
        wrapperCol1: { span: 12 },
        labelCol2: { span: 12 },
        wrapperCol2: { span: 12 },
    };

    //#endregion

    return (
        <>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                        {(accountId === undefined || accountId === null) &&
                            <Fieldset legend="Choose an Account">
                                <FormItem {...formItemLayout} label="Account" required format="vertical"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Select
                                            placeholder="Please Select an Account"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChange(selected); }}
                                            value={value}
                                            name={name}
                                            ref={ref}
                                        >
                                            {accounts.map(a => <Option value={a.id} key={a.id}>{a.name}</Option>)}
                                        </Select>
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="accountId"
                                />
                            </Fieldset>
                        }
                        <Fieldset legend="Product Details">
                            <FormItem {...formItemLayout} label="Name" required format="vertical"
                                render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Name" ref={ref} />}
                                rules={{ required: 'Required Field' }}
                                name="name"
                            />
                            <FormItem {...formItemLayout} label="Commodity" required format="vertical"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please Select the Type of Commodity for this Product"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    >
                                        {commodities.map(i => <Option value={i.id} key={i.id}>{i.name}</Option>)}
                                    </Select>
                                )}
                                rules={{ required: "Required Field" }}
                                name="commodityId"
                            />
                        </Fieldset>
                        <Fieldset legend="Product Variants">
                            {variants.map((variant, index) => (
                                <Row gutter={[16, 16]} key={`variants-${index}`}>
                                    <Col span={18}>
                                        <FormItemDisplay label={variant.name} format="horizontal">
                                            <DataRow title="SKU" value={variant.sku} dataType="String" />
                                            <DataRow title="Unit Price" value={variant.unitPrice} units={variant.unitPriceUnit} dataType="Money" />
                                            <DataRow title="Unit Weight" value={variant.unitWeight} units={variant.unitWeightUnit} dataType="Weight" />
                                            <DataRow title="Quantity Per Unit" value={variant.quantityPerUnit} dataType="Integer" />
                                        </FormItemDisplay>
                                    </Col>
                                    <Col span={6}>
                                        <Button icon={<EditOutlined />} onClick={() => { setSelectedVariantIndex(index); toggleEditVariant(); }} />
                                        <Button icon={<DeleteOutlined />} onClick={() => { removeVariant(index); }} />
                                    </Col>
                                </Row>
                            ))}
                            <Tooltip placement="left" title="Add Variant">
                                <Button key="1" type="primary" icon={<PlusOutlined />} shape='circle' onClick={toggleNewVariant} style={{ margin: 12, float: "right" }} />
                            </Tooltip>
                        </Fieldset>
                        {error && <Alert message={`${error}`} type="error" />}
                    </Spin>
                    <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Create Product" />
                </Form>
            </FormProvider>
            <Drawer
                title={'Add New Variant'}
                width={fullWidth > 720 ? fullWidth / 2 : 360}
                onClose={toggleNewVariant}
                visible={showAddVariant === true}
                bodyStyle={{ paddingBottom: 80 }}
                style={{ zIndex: 1000 }}
                destroyOnClose={true}
                closable={false}
                maskClosable={false}
            >
                <FormProvider {...methods2}>
                    <Form onSubmit={methods2.handleSubmit(addVariant)}>
                        <FormItem {...formItemLayout} label="Variant Name" required format="vertical"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Input
                                    placeholder="Variant Name"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            rules={{ required: "Required Field" }}
                            name="name"
                            defaultValue=""
                        />
                        <FormItem {...formItemLayout} label="SKU" required format="vertical"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Input
                                    placeholder="SKU"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            rules={{ required: "Required Field" }}
                            name="sku"
                            defaultValue=""
                        />
                        <FormItemDouble {...formItemLayoutDouble} label1="Unit Price" required1 required2 format="vertical"
                            render1={({ onChange, onBlur, value, name, ref }) => (
                                <InputNumber
                                    precision={2}
                                    min={0}
                                    placeholder="Unit Price"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            rules1={{ required: false }}
                            name1="unitPrice"
                            render2={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                >
                                    {currencyOptions}
                                </Select>
                            )}
                            rules2={{ required: false }}
                            name2="unitPriceUnit"
                            defaultValue2="USD"
                        />
                        <FormItemDouble {...formItemLayoutDouble} label1="Unit Weight" format="vertical"
                            render1={({ onChange, onBlur, value, name, ref }) => (
                                <InputNumber
                                    precision={2}
                                    min={0}
                                    placeholder="Unit Weight"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            rules1={{ required: false }}
                            name1="unitWeight"
                            render2={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                >
                                    {weightUnitOptions}
                                </Select>
                            )}
                            rules2={{ required: false }}
                            name2="unitWeightUnit"
                            defaultValue2="LB"
                        />
                        <FormItem {...formItemLayout} label="Quantity Per Unit" format="vertical"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <InputNumber
                                    precision={0}
                                    min={0}
                                    step={1}
                                    placeholder="Quantity Per Unit"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            rules={{ required: false }}
                            name="quantityPerUnit"
                            defaultValue={null}
                        />
                        <FormButtons cancel={toggleNewVariant} submitText="Add Variant" />
                    </Form>
                </FormProvider>
            </Drawer>
            <Drawer
                title={'Edit Variant'}
                width={fullWidth > 720 ? fullWidth / 2 : 360}
                onClose={toggleEditVariant}
                visible={showEditVariant === true}
                bodyStyle={{ paddingBottom: 80 }}
                style={{ zIndex: 1000 }}
                destroyOnClose={true}
                closable={false}
                maskClosable={false}
            >
                {isNumberNotEmpty(selectedVariantIndex) && isListNotEmpty(variants) && isObjectNotEmpty(variants[selectedVariantIndex]) ? (
                    <FormProvider {...methods3}>
                        <Form onSubmit={methods3.handleSubmit(updateVariant)}>
                            <FormItem {...formItemLayout} label="Variant Name" required format="vertical"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Input
                                        placeholder="Variant Name"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    />
                                )}
                                rules={{ required: "Required Field" }}
                                name="name"
                                defaultValue={variants[selectedVariantIndex].name}
                            />
                            <FormItem {...formItemLayout} label="SKU" required format="vertical"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Input
                                        placeholder="SKU"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    />
                                )}
                                rules={{ required: "Required Field" }}
                                name="sku"
                                defaultValue={variants[selectedVariantIndex].sku}
                            />
                            <FormItemDouble {...formItemLayoutDouble} label1="Unit Price" required1 required2 format="vertical"
                                render1={({ onChange, onBlur, value, name, ref }) => (
                                    <InputNumber
                                        precision={2}
                                        min={0}
                                        placeholder="Unit Price"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    />
                                )}
                                rules1={{ required: false }}
                                name1="unitPrice"
                                defaultValue1={variants[selectedVariantIndex].unitPrice}
                                render2={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please Select"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    >
                                        {currencyOptions}
                                    </Select>
                                )}
                                rules2={{ required: false }}
                                name2="unitPriceUnit"
                                defaultValue2={variants[selectedVariantIndex].unitPriceUnit}
                            />
                            <FormItemDouble {...formItemLayoutDouble} label1="Unit Weight" format="vertical"
                                render1={({ onChange, onBlur, value, name, ref }) => (
                                    <InputNumber
                                        precision={2}
                                        min={0}
                                        placeholder="Unit Weight"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    />
                                )}
                                rules1={{ required: false }}
                                name1="unitWeight"
                                defaultValue1={variants[selectedVariantIndex].unitWeight}
                                render2={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please Select"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    >
                                        {weightUnitOptions}
                                    </Select>
                                )}
                                rules2={{ required: false }}
                                name2="unitWeightUnit"
                                defaultValue2={variants[selectedVariantIndex].unitWeightUnit}
                            />
                            <FormItem {...formItemLayout} label="Quantity Per Unit" format="vertical"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <InputNumber
                                        precision={0}
                                        min={0}
                                        step={1}
                                        placeholder="Quantity Per Unit"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    />
                                )}
                                rules={{ required: false }}
                                name="quantityPerUnit"
                                defaultValue={variants[selectedVariantIndex].quantityPerUnit}
                            />
                            <FormButtons cancel={toggleEditVariant} submitText="Update Variant" />
                        </Form>
                    </FormProvider>
                ) : null}
            </Drawer>
        </>
    );
};

export default NewProduct;