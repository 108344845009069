import * as actionTypes from "../actions/actionTypes";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import * as DTO from "../../api/dtos/index";
import { isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import ErrorUtils from "../../shared/errorUtils";

//#region Fetch Message List Methods

export const fetchMessageListStart = (listName) => {
    return {
        type: actionTypes.FETCH_MESSAGE_LIST_START,
        payload: { listName: listName }
    }
};

export const fetchMessageListSuccess = (listName, payload) => {
    return {
        type: actionTypes.FETCH_MESSAGE_LIST_SUCCESS,
        payload: { listName: listName, ...payload }
    }
};

export const fetchMessageListFail = (listName, payload) => {
    return {
        type: actionTypes.FETCH_MESSAGE_LIST_FAIL,
        payload: { listName: listName, ...payload }
    }
};

export const clearMessageList = (listName) => {
    return {
        type: actionTypes.CLEAR_MESSAGE_LIST,
        payload: { listName: listName }
    }
};

export const clearMessageLists = () => {
    return {
        type: actionTypes.CLEAR_MESSAGE_LISTS
    }
};

export const addMessageToMessageList = (listName, payload) => {
    return {
        type: actionTypes.ADD_TO_MESSAGE_LIST,
        payload: { listName: listName, newRecord: payload }
    }
};

export const updateMessageInMessageList = (listName, payload) => {
    return {
        type: actionTypes.UPDATE_IN_MESSAGE_LIST,
        payload: { listName: listName, updatedRecord: payload }
    }
};

export const removeMessageFromMessageList = (listName, payload) => {
    return {
        type: actionTypes.REMOVE_FROM_MESSAGE_LIST,
        payload: { listName: listName, recordToRemove: payload }
    }
};

//#endregion
//#region Add Message Methods

export const addMessageStart = () => {
    return {
        type: actionTypes.ADD_MESSAGE_START
    }
};

export const addMessageSuccess = () => {
    return {
        type: actionTypes.ADD_MESSAGE_SUCCESS
    }
};

export const addMessageFail = (payload) => {
    return {
        type: actionTypes.ADD_MESSAGE_FAIL,
        payload: payload
    }
};

export const addMessageLoadingClear = () => {
    return {
        type: actionTypes.ADD_MESSAGE_LOADING_CLEAR
    }
};

export const addMessageErrorClear = () => {
    return {
        type: actionTypes.ADD_MESSAGE_ERROR_CLEAR
    }
};

export const addMessageCancel = () => {
    return {
        type: actionTypes.ADD_MESSAGE_CANCEL
    }
};

//#endregion
//#region Update Message Methods

const changeSingleMessage = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_MESSAGE,
        payload: payload
    }
};

export const updateMessageStart = () => {
    return {
        type: actionTypes.UPDATE_MESSAGE_START
    }
};

export const updateMessageSuccess = () => {
    return {
        type: actionTypes.UPDATE_MESSAGE_SUCCESS
    }
};

export const updateMessageFail = (payload) => {
    return {
        type: actionTypes.UPDATE_MESSAGE_FAIL,
        payload: payload
    }
};

export const updateMessageLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_MESSAGE_LOADING_CLEAR
    }
};

export const updateMessageErrorClear = () => {
    return {
        type: actionTypes.UPDATE_MESSAGE_ERROR_CLEAR
    }
};

export const updateMessageCancel = () => {
    return {
        type: actionTypes.UPDATE_MESSAGE_CANCEL
    }
};

//#endregion
//#region Messages Methods

export const fetchMessageList = (listName, payload) => {
    return async (dispatch, getState) => {
        if (isStringNotEmpty(listName)) {
            try {
                const state = getState();
                const messagesState = { ...state.messages };
                const existingLists = { ...messagesState.lists };

                let pagination = {};
                let searchParams = {};
                if (isObjectNotEmpty(existingLists[listName])) {
                    pagination = { ...existingLists[listName].pagination };
                    searchParams = { ...existingLists[listName].searchParams };
                }

                if (isObjectNotEmpty(payload)) {
                    searchParams = { ...payload };
                }

                dispatch(clearMessageList(listName));
                dispatch(fetchMessageListStart(listName));

                const res = await Data.getLoadMessages({ ...searchParams });
                dispatch(fetchMessageListSuccess(listName, { records: res.data, params: { searchParams: res.searchParams, pagination: res.pagination } }));
            } catch (error) {
                logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
                dispatch(fetchMessageListFail(listName, { error: ErrorUtils.getErrorMessage(error) }));
            }
        }
    }
};

export const fetchMoreMessageList = (listName, payload) => {
    return async (dispatch, getState) => {
        if (isStringNotEmpty(listName)) {
            try {
                dispatch(fetchMessageListStart(listName));

                const state = getState();
                const messagesState = { ...state.messages };
                const existingLists = { ...messagesState.lists };

                let pagination = {};
                let searchParams = {};
                let updatedMessages = [];
                if (isObjectNotEmpty(existingLists[listName])) {
                    pagination = { ...existingLists[listName].pagination };
                    searchParams = { ...existingLists[listName].searchParams };
                    updatedMessages = [...existingLists[listName].records];
                }

                if (isObjectNotEmpty(payload)) {
                    searchParams = { ...payload };
                }

                const res = await Data.getMoreLoadMessages({ ...searchParams }, pagination, updatedMessages);
                dispatch(fetchMessageListSuccess(listName, { records: res.data, params: { searchParams: res.searchParams, pagination: res.pagination } }));
            } catch (error) {
                logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
                dispatch(fetchMessageListFail(listName, { error: ErrorUtils.getErrorMessage(error) }));
            }
        }
    }
};

export const sendMessage = (msgs, userId, load) => {
    return async (dispatch) => {
        try {
            if (isObjectNotEmpty(load) && isStringNotEmpty(load.id) && isStringNotEmpty(userId) && isListNotEmpty(msgs)) {
                dispatch(addMessageStart());

                const newMessages = await Data.addLoadMessages(msgs, userId, load);
                if (isListNotEmpty(newMessages)) {
                    newMessages.forEach((newMessage) => {
                        dispatch(addMessageToMessageList(load.id, newMessage));
                    });
                }

                dispatch(addMessageSuccess());
                dispatch(addMessageErrorClear());
                dispatch(addMessageLoadingClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addMessageFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const addMessage = (newMessage) => {
    return async (dispatch) => {
        dispatch(addMessageToMessageList(newMessage.loadId, DTO.getLoadMessageDTO(newMessage)));
    }
};

export const updateMessage = (id, payload) => {
    return async (dispatch) => {
        try {
            dispatch(updateMessageStart());

            const updatedLoadMessage = await Data.updateLoadMessage(id, payload);
            if (isObjectNotEmpty(updatedLoadMessage)) {
                dispatch(updateMessageInMessageList(updatedLoadMessage.loadId, updatedLoadMessage));
            }

            dispatch(updateMessageSuccess());
            dispatch(updateMessageErrorClear());
            dispatch(updateMessageLoadingClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateMessageFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const readMessage = (id, userId) => {
    return async (dispatch) => {
        try {
            dispatch(updateMessageStart());

            const updatedLoadMessage = await Data.markLoadMessageAsRead(id, userId);
            if (isObjectNotEmpty(updatedLoadMessage)) {
                dispatch(updateMessageInMessageList(updatedLoadMessage.loadId, updatedLoadMessage));
            }

            dispatch(updateMessageSuccess());
            dispatch(updateMessageErrorClear());
            dispatch(updateMessageLoadingClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateMessageFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const readMultipleMessages = (ids, userId) => {
    return async (dispatch) => {
        try {
            dispatch(updateMessageStart());

            const updatedLoadMessages = await Data.markMultipleLoadMessagesAsRead(ids, userId);
            if (isListNotEmpty(updatedLoadMessages)) {
                updatedLoadMessages.forEach((updatedLoadMessage) => {
                    dispatch(updateMessageInMessageList(updatedLoadMessage.loadId, updatedLoadMessage));
                });
            }

            dispatch(updateMessageSuccess());
            dispatch(updateMessageErrorClear());
            dispatch(updateMessageLoadingClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateMessageFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion