import React from 'react';
import { isBooleanFalse, isBooleanTrue, isStringNotEmpty } from '../../shared/objectUtils';
import StringFormatter from '../../shared/stringFormatter';

const stringFormatter = new StringFormatter();

const DataDateTimeRangeRow = ({ className = null, valueClassName = null, titleClassName = null, style = null, title = null, titleStyle = { fontWeight: 'bold' }, valueStyle = null, startTime, endTime, timeZone = null, separateLines = false, noColon = false }) => {
    if (isBooleanTrue(separateLines)) {
        return (
            <div className={className} style={style}>
                {isStringNotEmpty(title) ? (<span className={titleClassName} style={titleStyle}>{title}{isBooleanFalse(noColon) ? ':' : ''} </span>) : null}
                <div className={valueClassName} style={valueStyle}>{stringFormatter.toFormattedString("MomentDateTime", startTime, null, timeZone)} -</div>
                <div className={valueClassName} style={valueStyle}>{stringFormatter.toFormattedString("MomentDateTime", endTime, null, timeZone)}</div>
            </div>
        );
    } else {
        return (
            <div className={className} style={style}>
                {isStringNotEmpty(title) ? (<span className={titleClassName} style={titleStyle}>{title}{isBooleanFalse(noColon) ? ':' : ''} </span>) : null}
                <span className={valueClassName} style={valueStyle}>{stringFormatter.toFormattedString("MomentDateTime", startTime, null, timeZone)} - {stringFormatter.toFormattedString("MomentDateTime", endTime, null, timeZone)}</span>
            </div>
        );
    }
};

export default DataDateTimeRangeRow;