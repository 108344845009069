import axiosAuthenticated from "../../api/axiosAuthenticated";
import { isListNotEmpty, isNotNullOrUndefined, isObjectNotEmpty } from "../../shared/objectUtils";
import * as DTO from "../dtos/index";
import * as Data from "./index";

const linkedLoadLanesPath = '/linkedLoadLanes';
const linkedLoadLanesBulkPath = '/linkedLoadLanes/bulk/get';

//#region LinkedLoadLanes Methods

export const getBulkLinkedLoadLanes = async (searchParams = {}, accounts = [], trailerTypes = [], accountId = null, linkedAccountId = null) => {
    const linkedLoadLanesRes = await axiosAuthenticated.post(linkedLoadLanesBulkPath, { ...searchParams });
    if (linkedLoadLanesRes && linkedLoadLanesRes.status === 200) {
        const linkedLoadLanes = linkedLoadLanesRes.data.data;

        let locationIds = getLocationIdsForLinkedLoadLanes(linkedLoadLanes);
        let locations = await Data.getLocationsByIds([...locationIds], accountId);

        return linkedLoadLanes.map((linkedLoadLane) => { return DTO.getLinkedLoadLaneDTO(linkedLoadLane, accounts, trailerTypes, locations, accountId, linkedAccountId); });
    }

    return [];
};

export const getLinkedLoadLanes = async (searchParams = {}, pagination = {}, accounts = [], trailerTypes = [], accountId = null, linkedAccountId = null) => {
    const linkedLoadLanesRes = await axiosAuthenticated.post(linkedLoadLanesBulkPath, { ...searchParams });
    if (linkedLoadLanesRes && linkedLoadLanesRes.status === 200) {
        const linkedLoadLanes = linkedLoadLanesRes.data.data;
        const otherData = linkedLoadLanesRes.data;

        let locationIds = getLocationIdsForLinkedLoadLanes(linkedLoadLanes);
        let locations = await Data.getLocationsByIds([...locationIds], accountId);

        const transformedLinkedLoadLanes = linkedLoadLanes.map((linkedLoadLane) => { return DTO.getLinkedLoadLaneDTO(linkedLoadLane, accounts, trailerTypes, locations, accountId, linkedAccountId); });

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;

        return { data: transformedLinkedLoadLanes, searchParams: searchParams, pagination: pagination };
    }

    return { data: [], searchParams: searchParams, pagination: pagination };
};

export const getLinkedLoadLane = async (id, accounts = [], trailerTypes = [], accountId = null, linkedAccountId = null) => {
    const linkedLoadLanesRes = await axiosAuthenticated.get(linkedLoadLanesPath + `/${id}`);
    if (linkedLoadLanesRes && linkedLoadLanesRes.status === 200) {
        let linkedLoadLane = linkedLoadLanesRes.data;

        let locationIds = getLocationIdsForLinkedLoadLane(linkedLoadLane);
        let locations = await Data.getLocationsByIds([...locationIds], accountId);

        return DTO.getLinkedLoadLaneDTO(linkedLoadLane, accounts, trailerTypes, locations, accountId, linkedAccountId);
    }

    return null;
};

export const addLinkedLoadLane = async (payload, accounts = [], trailerTypes = []) => {
    const linkedLoadLanesRes = await axiosAuthenticated.post(linkedLoadLanesPath, { ...payload });
    if (linkedLoadLanesRes && linkedLoadLanesRes.status === 201) {
        let linkedLoadLane = linkedLoadLanesRes.data;

        let locationIds = getLocationIdsForLinkedLoadLane(linkedLoadLane);
        let locations = await Data.getLocationsByIds([...locationIds], payload.accountId);

        return DTO.getLinkedLoadLaneDTO(linkedLoadLane, accounts, trailerTypes, locations, payload.accountId, payload.linkedAccountId);
    }

    return null;
};

export const updateLinkedLoadLane = async (id, payload, accounts = [], trailerTypes = []) => {
    const linkedLoadLanesRes = await axiosAuthenticated.put(linkedLoadLanesPath + `/${id}`, { ...payload });
    if (linkedLoadLanesRes && linkedLoadLanesRes.status === 200) {
        let updatedLinkedLoadLane = linkedLoadLanesRes.data;

        let locationIds = getLocationIdsForLinkedLoadLane(updatedLinkedLoadLane);
        let locations = await Data.getLocationsByIds([...locationIds], payload.accountId);

        return DTO.getLinkedLoadLaneDTO(updatedLinkedLoadLane, accounts, trailerTypes, locations, payload.accountId, payload.linkedAccountId);
    }

    return null;
};

export const removeLinkedLoadLane = async (id) => {
    const linkedLoadLanesRes = await axiosAuthenticated.put(linkedLoadLanesPath + `/${id}`, { isDeleted: true });
    if (linkedLoadLanesRes && linkedLoadLanesRes.status === 200) {
        return linkedLoadLanesRes.data;
    }

    return null;
};

//#endregion
//#region Helper Methods

export const getLocationIdsForLinkedLoadLanes = (linkedLoadLanes = []) => {
    let locationIds = [];
    if (isListNotEmpty(linkedLoadLanes)) {
        linkedLoadLanes.forEach((linkedLoadLane) => {
            if (isListNotEmpty(linkedLoadLane.locations)) {
                linkedLoadLane.locations.forEach((location) => {
                    if (!locationIds.includes(location.locationId)) {
                        locationIds.push(location.locationId);
                    }
                });
            }
        });
    }

    return locationIds;
};

export const getLocationIdsForLinkedLoadLane = (linkedLoadLane) => {
    let locationIds = [];
    if (isObjectNotEmpty(linkedLoadLane) && isListNotEmpty(linkedLoadLane.locations)) {
        linkedLoadLane.locations.forEach((location) => {
            if (!locationIds.includes(location.locationId)) {
                locationIds.push(location.locationId);
            }
        });
    }

    return locationIds;
};

//#endregion