import * as actionTypes from "../actions/actionTypes";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { isBooleanTrue, isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import ErrorUtils from "../../shared/errorUtils";
import * as actionCreators from "./index";

//#region Fetch Claim List Methods

export const fetchClaimListStart = (listName) => {
    return {
        type: actionTypes.FETCH_CLAIM_LIST_START,
        payload: { listName: listName }
    }
};

export const fetchClaimListSuccess = (listName, payload) => {
    return {
        type: actionTypes.FETCH_CLAIM_LIST_SUCCESS,
        payload: { listName: listName, ...payload }
    }
};

export const fetchClaimListFail = (listName, payload) => {
    return {
        type: actionTypes.FETCH_CLAIM_LIST_FAIL,
        payload: { listName: listName, ...payload }
    }
};

export const clearClaimList = (listName) => {
    return {
        type: actionTypes.CLEAR_CLAIM_LIST,
        payload: { listName: listName }
    }
};

export const clearClaimLists = () => {
    return {
        type: actionTypes.CLEAR_CLAIM_LISTS
    }
};

export const addClaimToClaimList = (listName, payload) => {
    return {
        type: actionTypes.ADD_TO_CLAIM_LIST,
        payload: { listName: listName, newRecord: payload }
    }
};

export const updateClaimInClaimList = (listName, payload) => {
    return {
        type: actionTypes.UPDATE_IN_CLAIM_LIST,
        payload: { listName: listName, updatedRecord: payload }
    }
};

export const removeClaimFromClaimList = (listName, payload) => {
    return {
        type: actionTypes.REMOVE_FROM_CLAIM_LIST,
        payload: { listName: listName, recordToRemove: payload }
    }
};

//#endregion
//#region Fetch Claim Methods

export const fetchClaimStart = () => {
    return {
        type: actionTypes.FETCH_CLAIM_START
    }
};

export const fetchClaimSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_CLAIM_SUCCESS,
        payload: payload
    }
};

export const fetchClaimFail = (payload) => {
    return {
        type: actionTypes.FETCH_CLAIM_FAIL,
        payload: payload
    }
};

export const clearClaim = () => {
    return {
        type: actionTypes.CLEAR_CLAIM
    }
};

//#endregion
//#region Add Claim Methods

export const addClaimStart = () => {
    return {
        type: actionTypes.ADD_CLAIM_START
    }
};

export const addClaimSuccess = () => {
    return {
        type: actionTypes.ADD_CLAIM_SUCCESS
    }
};

export const addClaimFail = (payload) => {
    return {
        type: actionTypes.ADD_CLAIM_FAIL,
        payload: payload
    }
};

export const addClaimLoadingClear = () => {
    return {
        type: actionTypes.ADD_CLAIM_LOADING_CLEAR
    }
};

export const addClaimErrorClear = () => {
    return {
        type: actionTypes.ADD_CLAIM_ERROR_CLEAR
    }
};

export const addClaimCancel = () => {
    return {
        type: actionTypes.ADD_CLAIM_CANCEL
    }
};

//#endregion
//#region Update Claim Methods

const changeSingleClaim = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_CLAIM,
        payload: payload
    }
};

export const updateClaimStart = () => {
    return {
        type: actionTypes.UPDATE_CLAIM_START
    }
};

export const updateClaimSuccess = () => {
    return {
        type: actionTypes.UPDATE_CLAIM_SUCCESS
    }
};

export const updateClaimFail = (payload) => {
    return {
        type: actionTypes.UPDATE_CLAIM_FAIL,
        payload: payload
    }
};

export const updateClaimLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_CLAIM_LOADING_CLEAR
    }
};

export const updateClaimErrorClear = () => {
    return {
        type: actionTypes.UPDATE_CLAIM_ERROR_CLEAR
    }
};

export const updateClaimCancel = () => {
    return {
        type: actionTypes.UPDATE_CLAIM_CANCEL
    }
};

//#endregion
//#region Claims Methods

export const fetchClaimList = (listName, payload) => {
    return async (dispatch, getState) => {
        if (isStringNotEmpty(listName)) {
            try {
                dispatch(fetchClaimListStart(listName));

                await dispatch(actionCreators.getAccounts());
                const state = getState();
                const orchestratorState = { ...state.orchestrator };
                const accounts = [...orchestratorState.accounts];
                const claimsState = { ...state.claims };
                const existingLists = { ...claimsState.lists };

                let pagination = {};
                let searchParams = {};
                if (isObjectNotEmpty(existingLists[listName])) {
                    pagination = { ...existingLists[listName].pagination };
                    searchParams = { ...existingLists[listName].searchParams };
                }

                if (isObjectNotEmpty(payload)) {
                    searchParams = { ...payload };
                }

                const res = await Data.getClaims({ ...searchParams }, pagination, accounts);
                dispatch(fetchClaimListSuccess(listName, { records: res.data, params: { searchParams: res.searchParams, pagination: res.pagination } }));
            } catch (error) {
                logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
                dispatch(fetchClaimListFail(listName, { error: ErrorUtils.getErrorMessage(error) }));
            }
        }
    }
};

export const fetchClaim = (id) => {
    return async (dispatch, getState) => {
        try {
            dispatch(fetchClaimStart());

            await dispatch(actionCreators.getAccounts());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const accounts = [...orchestratorState.accounts];

            const claim = await Data.getClaim(id, accounts);
            dispatch(fetchClaimSuccess({ record: claim }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchClaimFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const addClaim = (payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(addClaimStart());

            await dispatch(actionCreators.getAccounts());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const accounts = [...orchestratorState.accounts];

            let existingInvoiceLineItems = [];
            let existingTransactions = [];
            let existingClaims = [];
            const loadId = payload.loadId ? payload.loadId : null;
            if (isStringNotEmpty(loadId)) {
                existingInvoiceLineItems = await Data.getInvoiceLineItemsByLoadId(loadId);
                existingTransactions = await Data.getTransactionsByLoadId(loadId);
                existingClaims = await Data.getClaimsByLoadId(loadId);
            }

            const newClaim = await Data.addClaim(payload, accounts, existingInvoiceLineItems, existingTransactions, existingClaims, loadId);
            if (isObjectNotEmpty(newClaim)) {
                dispatch(addClaimToClaimList(newClaim.loadId, newClaim));
            }

            dispatch(addClaimSuccess());
            dispatch(addClaimLoadingClear());
            dispatch(addClaimErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addClaimFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateClaim = (id, payload, changeType, loadId) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updateClaimStart());

            let existingInvoiceLineItems = [];
            let existingTransactions = [];
            let existingClaims = [];
            if (isStringNotEmpty(loadId)) {
                existingInvoiceLineItems = await Data.getInvoiceLineItemsByLoadId(loadId);
                existingTransactions = await Data.getTransactionsByLoadId(loadId);
                existingClaims = await Data.getClaimsByLoadId(loadId);
            }

            if (isBooleanTrue(payload.isDeleted)) {
                const removedClaim = await Data.removeClaim(id, existingInvoiceLineItems, existingTransactions, existingClaims, loadId);
                if (isObjectNotEmpty(removedClaim)) {
                    dispatch(removeClaimFromClaimList(removedClaim.loadId, removedClaim));
                }
            } else {
                await dispatch(actionCreators.getAccounts());
                const state = getState();
                const orchestratorState = { ...state.orchestrator };
                const accounts = [...orchestratorState.accounts];

                const updatedClaim = await Data.updateClaim(id, payload, changeType, accounts, existingInvoiceLineItems, existingTransactions, existingClaims, loadId);
                if (isObjectNotEmpty(updatedClaim)) {
                    dispatch(updateClaimInClaimList(updatedClaim.loadId, updatedClaim));
                    dispatch(changeSingleClaim(updatedClaim));
                }
            }

            dispatch(updateClaimSuccess());
            dispatch(updateClaimLoadingClear());
            dispatch(updateClaimErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateClaimFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion