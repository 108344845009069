import React, { useMemo, useState } from 'react';
import { Alert, Button, Input, Select, Spin, InputNumber, Drawer, Switch, Empty, Row, Col } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { orderBy } from "lodash";
import Enums from '../../shared/enums';
import FormItem from '../FormItem/FormItem';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import FormButtons from '../FormButtons/FormButtons';
import { isListNotEmpty, isNumberNotEmpty, isObjectNotEmpty, isStringEmpty, isStringNotEmpty, removeEmpty } from '../../shared/objectUtils';
import Fieldset from '../FormFieldset/FormFieldset';
import Form from '../Form/Form';
import NewProducer from '../NewProducer/NewProducer';
import NewReceiver from '../NewReceiver/NewReceiver';
import NewLinkedProducer from '../NewLinkedProducer/NewLinkedProducer';
import NewLinkedReceiver from '../NewLinkedReceiver/NewLinkedReceiver';
import { selectListRecords } from '../../store/utility';
import FormItemAddress from '../FormItemAddress/FormItemAddress';
import FormItemPointOfContact from '../FormItemPointOfContact/FormItemPointOfContact';
import NewLinkedShipper from '../NewLinkedShipper/NewLinkedShipper';

const { Option } = Select;
const { TextArea } = Input;

const NewLocation = ({ accountId, linkedAccountId, linkedAccountEntityType, cancel, ...props }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    //#endregion
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useStates

    const [selectedAccountId, setSelectedAccountId] = useState(null);
    const [selectedLinkedAccountId, setSelectedLinkedAccountId] = useState(null);
    const [showNewProducer, setShowNewProducer] = useState(false);
    const [showNewReceiver, setShowNewReceiver] = useState(false);
    const [showNewLinkedShipper, setShowNewLinkedShipper] = useState(false);
    const [showNewLinkedProducer, setShowNewLinkedProducer] = useState(false);
    const [showNewLinkedReceiver, setShowNewLinkedReceiver] = useState(false);
    const [belongsToAccount, setBelongsToAccount] = useState(true);

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const entityType = useSelector(state => state.auth.entityType);
    const entityId = useSelector(state => state.auth.entityId);
    const isLoading = useSelector(state => state.locations.isRecordAddLoading);
    const error = useSelector(state => state.locations.addRecordError);
    const commodities = useSelector(state => state.orchestrator.commodities);
    const accounts = useSelector(state => state.orchestrator.accounts);
    const linkedAccounts = useSelector(state => selectListRecords(state.orchestrator.linkedAccounts, selectedAccountId));

    //#endregion
    //#region enums

    const locationTypeOptions = Enums.LocationTypes.selectListOptions();
    const appointmentTypeOptions = Enums.AppointmentTypes.selectListOptions();
    const currencyOptions = Enums.Currencies.selectListOptions();
    const timeZoneOptions = Enums.USATimeZones.selectListOptions();

    //#endregion
    //#region toggles

    const toggleNewProducer = () => {
        setShowNewProducer(!showNewProducer);
    };

    const toggleNewReceiver = () => {
        setShowNewReceiver(!showNewReceiver);
    };

    const toggleNewLinkedShipper = () => {
        setShowNewLinkedShipper(!showNewLinkedShipper);
    };

    const toggleNewLinkedProducer = () => {
        setShowNewLinkedProducer(!showNewLinkedProducer);
    };

    const toggleNewLinkedReceiver = () => {
        setShowNewLinkedReceiver(!showNewLinkedReceiver);
    };

    //#endregion 
    //#region onChanges

    const onChangeLinkedAccountId = (value) => {
        //console.log(`selected ${value}`);
        setSelectedLinkedAccountId(value);
    };

    const onChangeAccountId = (value) => {
        //console.log(`selected ${value}`);
        setSelectedAccountId(value);
    };

    const onChangeLocationOwnership = (value) => {
        //console.log(`selected ${value}`);
        setBelongsToAccount(value);
    };

    //#endregion
    //#region onSubmit and onCancel

    const onSubmit = async (data) => {
        let cleanedData = removeEmpty(data);
        // let cleanedData = data;
        console.log(cleanedData);
        const address = cleanedData.address;
        const timeZone = cleanedData.timeZone;
        const selectedLinkedAccountIds = cleanedData.linkedAccountIds;
        if (isObjectNotEmpty(address) && isStringNotEmpty(timeZone) && isStringNotEmpty(selectedAccountId)) {
            let links = [];
            if (isListNotEmpty(selectedLinkedAccountIds)) {
                selectedLinkedAccountIds.forEach((sLinkedAccountId) => {
                    let link = {};
                    if (isStringNotEmpty(cleanedData.name)) {
                        link.name = cleanedData.name;
                    }
                    if (isStringNotEmpty(cleanedData.specialInstructions)) {
                        link.specialInstructions = cleanedData.specialInstructions;
                    }
                    if (isNumberNotEmpty(cleanedData.lumperFee)) {
                        link.lumperFee = cleanedData.lumperFee;
                        if (isStringNotEmpty(cleanedData.lumperFeeUnit)) {
                            link.lumperFeeUnit = cleanedData.lumperFeeUnit;
                        } else {
                            link.lumperFeeUnit = 'USD';
                        }
                    }
                    if (isObjectNotEmpty(cleanedData.pointOfContact)) {
                        link.pointOfContact = cleanedData.pointOfContact;
                    }
                    if (isListNotEmpty(cleanedData.commodityIds)) {
                        link.commodityIds = cleanedData.commodityIds;
                    }
                    if (isStringNotEmpty(cleanedData.appointmentType)) {
                        link.appointmentType = cleanedData.appointmentType;
                    }
                    if (isListNotEmpty(cleanedData.locationTypes)) {
                        link.locationTypes = cleanedData.locationTypes;
                    }

                    link.accountId = selectedAccountId;

                    if (isStringNotEmpty(sLinkedAccountId)) {
                        link.linkedAccountId = sLinkedAccountId;
                    }

                    links.push(link);
                });
            } else {
                let link = {};
                if (isStringNotEmpty(cleanedData.name)) {
                    link.name = cleanedData.name;
                }
                if (isStringNotEmpty(cleanedData.specialInstructions)) {
                    link.specialInstructions = cleanedData.specialInstructions;
                }
                if (isNumberNotEmpty(cleanedData.lumperFee)) {
                    link.lumperFee = cleanedData.lumperFee;
                    if (isStringNotEmpty(cleanedData.lumperFeeUnit)) {
                        link.lumperFeeUnit = cleanedData.lumperFeeUnit;
                    } else {
                        link.lumperFeeUnit = 'USD';
                    }
                }
                if (isObjectNotEmpty(cleanedData.pointOfContact)) {
                    link.pointOfContact = cleanedData.pointOfContact;
                }
                if (isListNotEmpty(cleanedData.commodityIds)) {
                    link.commodityIds = cleanedData.commodityIds;
                }
                if (isStringNotEmpty(cleanedData.appointmentType)) {
                    link.appointmentType = cleanedData.appointmentType;
                }
                if (isListNotEmpty(cleanedData.locationTypes)) {
                    link.locationTypes = cleanedData.locationTypes;
                }

                link.accountId = selectedAccountId;

                if (isStringNotEmpty(selectedLinkedAccountId)) {
                    link.linkedAccountId = selectedLinkedAccountId;
                }

                links.push(link);
            }

            let payload = {
                address: address,
                timeZone: timeZone,
                links: links
            };

            dispatch(actionCreators.addLocation(payload));
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.addLocationCancel());
        cancel();
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.addLocationErrorClear());

        dispatch(actionCreators.getCommodities());
        dispatch(actionCreators.getAccounts());
    }, []);

    useMemo(() => {
        if (isStringNotEmpty(accountId)) {
            setSelectedAccountId(accountId);
        } else if (isStringNotEmpty(entityType) && isStringNotEmpty(entityId) && entityType !== 'STAFF') {
            setSelectedAccountId(entityId);
        }
    }, [accountId, entityType, entityId]);

    useMemo(() => {
        if (isStringNotEmpty(selectedAccountId)) {
            dispatch(actionCreators.getLinkedAccounts(selectedAccountId, false));
        }
    }, [selectedAccountId]);

    useMemo(() => {
        if (isStringNotEmpty(linkedAccountId)) {
            setSelectedLinkedAccountId(linkedAccountId);
        }
    }, [linkedAccountId]);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    const formItemLayoutDouble = {
        labelCol1: { span: 24 },
        wrapperCol1: { span: 12 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 12 },
    };

    const radioStyle = {
        display: 'block',
        minHeight: '30px',
        lineHeight: '30px',
    };

    //#endregion

    return (
        <>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true && error === null)}>
                        <Fieldset legend="Location Name (* indicates a required field)">
                            <FormItem {...formItemLayout} label="Name" required format="vertical"
                                render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Name" ref={ref} />}
                                rules={{ required: "Required Field" }}
                                name="name"
                            />
                        </Fieldset>
                        <Fieldset legend="Location Address and Time Zone (* indicates a required field)">
                            <FormItemAddress
                                format="vertical"
                                required={true}
                                name="address"
                            />
                            <FormItem {...formItemLayout} label="Time Zone" format="vertical" required
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please Select the Time Zone for this Location"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    >
                                        {timeZoneOptions}
                                    </Select>
                                )}
                                rules={{ required: "Required Field" }}
                                name="timeZone"
                            />
                        </Fieldset>
                        <Fieldset legend="Location Details (* indicates a required field)">
                            <FormItem {...formItemLayout} label="Location Type(s)" required format="vertical"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please Select the Location Type"
                                        mode="multiple"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    >
                                        {locationTypeOptions}
                                    </Select>
                                )}
                                rules={{ required: "Required Field" }}
                                name="locationTypes"
                            />
                            <FormItem {...formItemLayout} label="Default Appointment Type" format="vertical"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please Select the Appointment Type"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    >
                                        {appointmentTypeOptions}
                                    </Select>
                                )}
                                rules={{ required: false }}
                                name="appointmentType"
                            />
                        </Fieldset>
                        <Fieldset legend="Location Fees (optional)" isCollapsed={true} isCollapsable={true}>
                            <FormItemDouble {...formItemLayoutDouble} label1="Default Lumper Fee" format="vertical"
                                render1={({ onChange, onBlur, value, name, ref }) => (
                                    <InputNumber
                                        precision={2}
                                        min={0}
                                        placeholder="Lumper Fee"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    />
                                )}
                                rules1={{ required: false }}
                                name1="lumperFee"
                                render2={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please Select"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    >
                                        {currencyOptions}
                                    </Select>
                                )}
                                rules2={{ required: false }}
                                name2="lumperFeeUnit"
                            />
                        </Fieldset>
                        <Fieldset legend="Special Instructions (optional)" isCollapsed={true} isCollapsable={true}>
                            <FormItem {...formItemLayout} label="Default Special Instructions" format="vertical"
                                render={({ onChange, onBlur, value, name, ref }) => <TextArea onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} autoSize={{ minRows: 4 }} ref={ref} />}
                                rules={{ required: false }}
                                name="specialInstructions"
                            />
                        </Fieldset>
                        <Fieldset legend="Default Point of Contact (optional)" isCollapsable={true} isCollapsed={true}>
                            <FormItemPointOfContact
                                format="penta"
                                required={false}
                                name="pointOfContact"
                            />
                        </Fieldset>
                        <Fieldset legend="Default Location Commodities (optional)" isCollapsable={true} isCollapsed={true}>
                            <FormItem {...formItemLayout} label="Default Commodities" format="vertical"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please Select the Default Commodities"
                                        mode="multiple"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    >
                                        {commodities.map(i => <Option value={i.id} key={i.id}>{i.name}</Option>)}
                                    </Select>
                                )}
                                rules={{ required: false }}
                                name="commodityIds"
                            />
                        </Fieldset>
                        <Fieldset legend="Location Ownership">
                            {isStringEmpty(accountId) && entityType === 'STAFF' ? (
                                <>
                                    <FormItem {...formItemLayout} label="Choose an Account to Create this Location For" required format="vertical"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Select
                                                placeholder="Please Select an Account"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChangeAccountId(selected); onChange(selected); }}
                                                value={value}
                                                name={name}
                                                ref={ref}
                                            >
                                                {accounts.map(a => <Option value={a.id} key={a.id}>{a.name}</Option>)}
                                            </Select>
                                        )}
                                        rules={{ required: 'Required Field' }}
                                        name="accountId"
                                    />
                                    <Row gutter={[24, 24]} style={{ marginTop: 24, marginBottom: 24 }}>
                                        <Col span={24}><b>Account Doesn't Exist Yet?</b></Col>
                                        <Col md={12} lg={12}><Button type='primary' onClick={toggleNewProducer} style={{ marginLeft: 8, marginRight: 8 }}>Add New Producer</Button></Col>
                                        <Col md={12} lg={12}><Button type='primary' onClick={toggleNewReceiver} style={{ marginLeft: 8, marginRight: 8 }}>Add New Receiver</Button></Col>
                                    </Row>
                                    {isStringEmpty(linkedAccountId) ? (
                                        <>
                                            <FormItem {...formItemLayout} label={`Does this Location belong to ${entityType === 'STAFF' ? `the ${isStringNotEmpty(linkedAccountEntityType) ? Enums.EntityTypes.getValueByName(linkedAccountEntityType) : 'Account'}?` : 'your Company?'}`} format="vertical"
                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                    <Switch
                                                        checkedChildren="Yes"
                                                        unCheckedChildren="No"
                                                        onBlur={onBlur}
                                                        onChange={(checked, event) => { onChangeLocationOwnership(checked); onChange(checked); }}
                                                        checked={value}
                                                        name={name}
                                                        ref={ref}
                                                    />
                                                )}
                                                defaultValue={true}
                                                name="locationOwnership"
                                            />
                                            {belongsToAccount === false ? (
                                                <>
                                                    <FormItem {...formItemLayout} label="Choose Account(s) that you want to link this Location to" required format="vertical"
                                                        render={({ onChange, onBlur, value, name, ref }) => (
                                                            <Select
                                                                placeholder="Please Select Account(s)"
                                                                allowClear={true}
                                                                mode="multiple"
                                                                style={{ width: '100%' }}
                                                                virtual={false}
                                                                onBlur={onBlur}
                                                                onChange={(selected) => { onChange(selected); }}
                                                                value={value}
                                                                name={name}
                                                                showSearch={true}
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Accounts for you to choose from. You cannot create a location without assigning it to a Shipper, Producer, or Receiver account. Please add Shipper, Producer, and Receiver accounts first." />}
                                                                options={linkedAccounts.map(a => { return { label: `${a.name ? a.name : 'N/A'} (${a.entityTypes.map((accountEntityType) => { return Enums.EntityTypes.getValueByName(accountEntityType); }).join(', ')})`, value: a.id } })}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        rules={{ required: 'Required Field' }}
                                                        name="linkedAccountIds"
                                                    />
                                                    <Row gutter={[24, 24]} style={{ marginTop: 24 }}>
                                                        <Col span={24}><b>Account Doesn't Exist Yet?</b></Col>
                                                        <Col md={12} lg={8}><Button type='primary' onClick={toggleNewLinkedShipper} style={{ marginLeft: 8, marginRight: 8 }}>Add New Shipper to Account</Button></Col>
                                                        <Col md={12} lg={8}><Button type='primary' onClick={toggleNewLinkedProducer} style={{ marginLeft: 8, marginRight: 8 }}>Add New Producer to Account</Button></Col>
                                                        <Col md={12} lg={8}><Button type='primary' onClick={toggleNewLinkedReceiver} style={{ marginLeft: 8, marginRight: 8 }}>Add New Receiver to Account</Button></Col>
                                                    </Row>
                                                </>
                                            ) : null}
                                        </>
                                    ) : null}
                                </>
                            ) : (
                                <>
                                    {isStringEmpty(linkedAccountId) ? (
                                        <>
                                            <FormItem {...formItemLayout} label={`Does this Location belong to ${entityType === 'STAFF' ? `the ${isStringNotEmpty(linkedAccountEntityType) ? Enums.EntityTypes.getValueByName(linkedAccountEntityType) : 'Account'}?` : 'your Company?'}`} format="vertical"
                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                    <Switch
                                                        checkedChildren="Yes"
                                                        unCheckedChildren="No"
                                                        onBlur={onBlur}
                                                        onChange={(checked, event) => { onChangeLocationOwnership(checked); onChange(checked); }}
                                                        checked={value}
                                                        name={name}
                                                        ref={ref}
                                                    />
                                                )}
                                                defaultValue={true}
                                                name="locationOwnership"
                                            />
                                            {belongsToAccount === false ? (
                                                <>
                                                    <FormItem {...formItemLayout} label="Choose Account(s) that you want to link this Location to" required format="vertical"
                                                        render={({ onChange, onBlur, value, name, ref }) => (
                                                            <Select
                                                                placeholder="Please Select Account(s)"
                                                                allowClear={true}
                                                                mode="multiple"
                                                                style={{ width: '100%' }}
                                                                virtual={false}
                                                                onBlur={onBlur}
                                                                onChange={(selected) => { onChange(selected); }}
                                                                value={value}
                                                                name={name}
                                                                showSearch={true}
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Accounts for you to choose from. You cannot create a location without assigning it to a Shipper, Producer, or Receiver account. Please add Shipper, Producer, and Receiver accounts first." />}
                                                                options={linkedAccounts.map(a => { return { label: `${a.name ? a.name : 'N/A'} (${a.entityTypes.map((accountEntityType) => { return Enums.EntityTypes.getValueByName(accountEntityType); }).join(', ')})`, value: a.id } })}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        rules={{ required: 'Required Field' }}
                                                        name="linkedAccountId"
                                                    />
                                                    <Row gutter={[24, 24]} style={{ marginTop: 24 }}>
                                                        <Col span={24}><b>Account Doesn't Exist Yet?</b></Col>
                                                        {(entityType === 'CARRIER' || entityType === 'BROKER' || entityType === 'PRODUCER' || entityType === 'RECEIVER' || entityType === 'STAFF') ? (
                                                            <Col md={12} lg={8}><Button type='primary' onClick={toggleNewLinkedShipper} style={{ marginLeft: 8, marginRight: 8 }}>Add New Shipper</Button></Col>
                                                        ) : null}
                                                        {(entityType === 'CARRIER' || entityType === 'BROKER' || entityType === 'SHIPPER' || entityType === 'RECEIVER' || entityType === 'STAFF') ? (
                                                            <Col md={12} lg={8}><Button type='primary' onClick={toggleNewLinkedProducer} style={{ marginLeft: 8, marginRight: 8 }}>Add New Producer</Button></Col>
                                                        ) : null}
                                                        {(entityType === 'CARRIER' || entityType === 'BROKER' || entityType === 'SHIPPER' || entityType === 'PRODUCER' || entityType === 'STAFF') ? (
                                                            <Col md={12} lg={8}><Button type='primary' onClick={toggleNewLinkedReceiver} style={{ marginLeft: 8, marginRight: 8 }}>Add New Receiver</Button></Col>
                                                        ) : null}
                                                    </Row>
                                                </>
                                            ) : null}
                                        </>
                                    ) : null}
                                </>
                            )}
                        </Fieldset>
                        {error && <Alert message={`${error}`} type="error" />}
                    </Spin>
                    <FormButtons cancel={onCancel} disabled={(isLoading === true && error === null)} submitText="Create Location" />
                </Form>
            </FormProvider>
            <Drawer
                title={'Add New Producer Account'}
                visible={showNewProducer === true}
                onClose={toggleNewProducer}
                bodyStyle={{ paddingBottom: 80 }}
                style={{ zIndex: 1000 }}
                width={fullWidth > 720 ? fullWidth / 2 : 360}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                closable={false}
                maskClosable={false}
            >
                <NewProducer
                    cancel={toggleNewProducer}
                />
            </Drawer>
            <Drawer
                title={'Add New Receiver Account'}
                visible={showNewReceiver === true}
                onClose={toggleNewReceiver}
                bodyStyle={{ paddingBottom: 80 }}
                style={{ zIndex: 1000 }}
                width={fullWidth > 720 ? fullWidth / 2 : 360}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                closable={false}
                maskClosable={false}
            >
                <NewReceiver
                    cancel={toggleNewReceiver}
                />
            </Drawer>
            <Drawer
                title={'Add New Producer Account'}
                visible={showNewLinkedProducer === true}
                onClose={toggleNewLinkedProducer}
                bodyStyle={{ paddingBottom: 80 }}
                style={{ zIndex: 1000 }}
                width={fullWidth > 720 ? fullWidth / 2 : 360}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                closable={false}
                maskClosable={false}
            >
                <NewLinkedProducer
                    accountId={selectedAccountId}
                    cancel={toggleNewLinkedProducer}
                />
            </Drawer>
            <Drawer
                title={'Add New Receiver Account'}
                visible={showNewLinkedReceiver === true}
                onClose={toggleNewLinkedReceiver}
                bodyStyle={{ paddingBottom: 80 }}
                style={{ zIndex: 1000 }}
                width={fullWidth > 720 ? fullWidth / 2 : 360}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                closable={false}
                maskClosable={false}
            >
                <NewLinkedReceiver
                    accountId={selectedAccountId}
                    cancel={toggleNewLinkedReceiver}
                />
            </Drawer>
            <Drawer
                title={'Add New Shipper Account'}
                visible={showNewLinkedShipper === true}
                onClose={toggleNewLinkedShipper}
                bodyStyle={{ paddingBottom: 80 }}
                style={{ zIndex: 1000 }}
                width={fullWidth > 720 ? fullWidth / 2 : 360}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                closable={false}
                maskClosable={false}
            >
                <NewLinkedShipper
                    accountId={selectedAccountId}
                    cancel={toggleNewLinkedShipper}
                />
            </Drawer>
        </>
    );
};

export default NewLocation;