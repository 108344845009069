import React, { useMemo, useState, useEffect, useReducer } from 'react';
import { Alert, Input, Spin, Select } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions";
import UploadFile from "../UploadFile/UploadFile";
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import FormItemFile from '../FormItemFile/FormItemFile';
import Fieldset from '../FormFieldset/FormFieldset';
import axios from 'axios';
import { LoadingOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { checkEmailAddressReducer, checkPhoneNumberReducer, checkEmailAddress, checkPhoneNumber, isValidEmailAddress } from '../../shared/formUtils';
import { isNotNullOrUndefined, isObjectEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';

const { Option } = Select;
const { TextArea } = Input;

const EditProfile = ({ cancel }) => {
    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    const dispatch = useDispatch();
    const userProfileId = useSelector(state => state.auth.userProfileId);
    const user = useSelector(state => state.users.record);
    const isLoading = useSelector(state => state.users.isRecordLoading);
    const isUpdateLoading = useSelector(state => state.users.isRecordUpdateLoading);
    const error = useSelector(state => state.users.updateRecordError);
    const states = useSelector(state => state.orchestrator.states);

    const [{ emailAddressExists, checkEmailAddressHasError, isCheckEmailAddressLoading, checkEmailAddressError }, checkEmailAddressDispatch] = useReducer(checkEmailAddressReducer, {
        emailAddressExists: null,
        isCheckEmailAddressLoading: false,
        checkEmailAddressHasError: false,
        checkEmailAddressError: null,
    });

    const [{ phoneNumberExists, checkPhoneNumberHasError, isCheckPhoneNumberLoading, checkPhoneNumberError }, checkPhoneNumberDispatch] = useReducer(checkPhoneNumberReducer, {
        phoneNumberExists: null,
        isCheckPhoneNumberLoading: false,
        checkPhoneNumberHasError: false,
        checkPhoneNumberError: null,
    });

    const [profileImage, setProfileImage] = useState(null);
    const [userToUpdate, setUserToUpdate] = useState(null);
    const [emailAddress, setEmailAddress] = useState(user.user && user.isDummyEmail === false ? user.user : null);
    const [phoneNumber, setPhoneNumber] = useState(user.phone ? user.phone : null);
    const [hasValidEmailAddress, setHasValidEmailAddres] = useState(false);
    const [hasValidPhoneNumber, setHasValidPhoneNumber] = useState(false);

    useEffect(() => {
        //console.log(`emailAddress: ${emailAddress}`);
        const { cancel, token } = axios.CancelToken.source();
        const timeOutId = setTimeout(() => {
            if (isStringNotEmpty(emailAddress) && emailAddress.length >= 4) {
                if (isValidEmailAddress(emailAddress)) {
                    checkEmailAddress(emailAddress, user, checkEmailAddressDispatch, token);
                }
            }
        }, 500);
        return () => cancel("Searching...") || clearTimeout(timeOutId);
    }, [emailAddress, user]);

    useEffect(() => {
        //console.log(`phoneNumber: ${phoneNumber}`);
        const { cancel, token } = axios.CancelToken.source();
        const timeOutId = setTimeout(() => {
            if (isStringNotEmpty(phoneNumber) && phoneNumber.length === 10) {
                checkPhoneNumber(phoneNumber, user, checkPhoneNumberDispatch, token);
            }
        }, 500);
        return () => cancel("Searching...") || clearTimeout(timeOutId);
    }, [phoneNumber, user]);

    const onSubmit = (data) => {
        if ((isStringNotEmpty(data.email) || isStringNotEmpty(data.phone)) && isObjectNotEmpty(userToUpdate)) {
            let payload = {};
            let isEmailChanged = false;

            for (let [key, value] of Object.entries(data)) {
                let oldValue = '';
                if (key === "email") {
                    oldValue = userToUpdate["user"] !== undefined && userToUpdate["user"] !== null ? userToUpdate["user"] : '';
                } else {
                    oldValue = userToUpdate[key] !== undefined && userToUpdate[key] !== null ? userToUpdate[key] : '';
                }
                if (value !== oldValue) {
                    //console.log(`${key}: new value - ${value}, old value - ${oldValue}`);
                    payload[key] = value;
                    if (key === "email") {
                        isEmailChanged = true;
                    }
                }
            }

            let emailAndOrPhoneAreValid = true;
            if (isStringNotEmpty(payload.email) && hasValidEmailAddress === false) {
                emailAndOrPhoneAreValid = false;
            }
            if (isStringNotEmpty(payload.phone) && hasValidPhoneNumber === false) {
                emailAndOrPhoneAreValid = false;
            }

            if (isNotNullOrUndefined(profileImage)) {
                payload.profileImage = profileImage;
                payload.profileImageId = user.profileImageId;
            } else if (isNotNullOrUndefined(user.profileImageId)) {
                payload.profileImageId = user.profileImageId;
            }

            if (emailAndOrPhoneAreValid === true && isObjectNotEmpty(payload)) {
                //console.log(payload);
                dispatch(actionCreators.updateUser(userProfileId, payload, true, isEmailChanged));
            } else {
                onCancel();
            }
        } else {
            dispatch(actionCreators.updateUserFail({ error: 'Email Address or Phone Number are required' }));
        }
    };

    const onEmailAddressChange = (value) => {
        if (isStringNotEmpty(value) && value.length >= 4) {
            if (isObjectNotEmpty(methods.errors.email)) {
                checkEmailAddressDispatch({ type: "FETCH_FAILURE", payload: { error: 'You must first enter a valid email address' } });
            }
            //console.log(value);
            setEmailAddress(value);
        } else {
            checkEmailAddressDispatch({ type: "FETCH_CLEAR" });
        }
    };

    const onPhoneNumberChange = (value) => {
        if (isStringNotEmpty(value) && value.length === 10) {
            if (isObjectNotEmpty(methods.errors.phone)) {
                checkPhoneNumberDispatch({ type: "FETCH_FAILURE", payload: { error: 'You must first enter a valid phone number' } });
            }
            //console.log(value);
            setPhoneNumber(value);
        } else {
            checkPhoneNumberDispatch({ type: "FETCH_CLEAR" });
        }
    };

    useEffect(() => {
        // console.log(emailAddress);
        // console.log(methods.errors.email);
        // console.log(emailAddressExists);
        if (isStringNotEmpty(emailAddress)) {
            if (isObjectEmpty(methods.errors.email)) {
                if (emailAddress === user.user || (emailAddressExists !== null && emailAddressExists === false)) {
                    setHasValidEmailAddres(true);
                    // console.log('Has a valid email address');
                } else {
                    setHasValidEmailAddres(false);
                }
            } else {
                setHasValidEmailAddres(false);
                checkEmailAddressDispatch({ type: "FETCH_CLEAR" });
            }
        } else {
            setHasValidEmailAddres(false);
            checkEmailAddressDispatch({ type: "FETCH_CLEAR" });
        }
    }, [methods.errors, emailAddress, emailAddressExists, user]);

    useEffect(() => {
        // console.log(phoneNumber);
        // console.log(methods.errors.phone);
        // console.log(phoneNumberExists);
        if (isStringNotEmpty(phoneNumber)) {
            if (isObjectEmpty(methods.errors.phone)) {
                if (phoneNumber === user.phone || (phoneNumberExists !== null && phoneNumberExists === false)) {
                    setHasValidPhoneNumber(true);
                    // console.log('Has a valid phone number');
                } else {
                    setHasValidPhoneNumber(false);
                }
            } else {
                setHasValidPhoneNumber(false);
                checkPhoneNumberDispatch({ type: "FETCH_CLEAR" });
            }
        } else {
            setHasValidPhoneNumber(false);
            checkPhoneNumberDispatch({ type: "FETCH_CLEAR" });
        }
    }, [methods.errors, phoneNumber, phoneNumberExists, user]);

    const onCancel = () => {
        dispatch(actionCreators.updateUserCancel());
        cancel();
    };

    useMemo(() => {
        if (isStringNotEmpty(userProfileId)) {
            dispatch(actionCreators.fetchUser(userProfileId));
        }
    }, [userProfileId]);

    useMemo(() => {
        if (isObjectNotEmpty(user)) {
            setUserToUpdate(user);
        }
    }, [user]);

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateUserErrorClear());
        dispatch(actionCreators.getStates());
    }, []);

    useMemo(() => {
        if (isUpdateLoading !== null && isUpdateLoading === false && error === null) {
            onCancel();
        }
    }, [isUpdateLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true || isUpdateLoading === true) && error === null}>
                    <Fieldset legend="Email or Phone Number are Required">
                        <FormItem {...formItemLayout} label="Email Address" format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Input
                                    placeholder="Email Address"
                                    addonAfter={isCheckEmailAddressLoading ? <LoadingOutlined /> : <MailOutlined />}
                                    onBlur={onBlur}
                                    onChange={e => { onEmailAddressChange(e.target.value); onChange(e.target.value); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            rules={{
                                required: false,
                                minLength: { value: 4, message: 'Email Address must be at least 4 characters long' },
                                pattern: {
                                    value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                    message: "Please enter a valid email address"
                                }
                            }}
                            name="email"
                            defaultValue={user.isDummyEmail === true ? '' : user.user}
                            help={
                                <div>
                                    <strong>If you update your Email address, you will be signed out of the system and will need to sign back in with your new email address.</strong>
                                </div>
                            }
                        />
                        {checkEmailAddressHasError && <Alert message={checkEmailAddressError} type="error" />}
                        <FormItem {...formItemLayout} label="Phone #" format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Input
                                    placeholder="Phone #"
                                    addonAfter={isCheckPhoneNumberLoading ? <LoadingOutlined /> : <PhoneOutlined />}
                                    onBlur={onBlur}
                                    onChange={e => { onPhoneNumberChange(e.target.value); onChange(e.target.value); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            rules={{
                                required: false,
                                pattern: {
                                    value: /^\d{10}$/,
                                    message: "Please enter a valid 10 digit phone number with no special characters"
                                }
                            }}
                            name="phone"
                            defaultValue={user && user.phone ? user.phone : ''}
                        />
                        {checkPhoneNumberHasError && <Alert message={checkPhoneNumberError} type="error" />}
                    </Fieldset>
                    <Fieldset legend="Additional User Information">
                        <FormItem {...formItemLayout} label="First Name" required format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="First Name" ref={ref} />}
                            rules={{ required: "Required Field" }}
                            name="firstName"
                            defaultValue={user.firstName}
                        />
                        <FormItem {...formItemLayout} label="Middle Name" format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Middle Name" ref={ref} />}
                            rules={{ required: false }}
                            name="middleName"
                            defaultValue={user.middleName}
                        />
                        <FormItem {...formItemLayout} label="Last Name" required format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Last Name" ref={ref} />}
                            rules={{ required: "Required Field" }}
                            name="lastName"
                            defaultValue={user.lastName}
                        />
                        <FormItemFile {...formItemLayout} label="Profile Image" name="profileImage" format="horizontal">
                            <UploadFile
                                beforeUpload={(file) => {
                                    setProfileImage(file);
                                    return false;
                                }}
                                onRemove={(file) => {
                                    setProfileImage(null);
                                }}
                                message="Please upload your profile image."
                                fileId={user.profileImageId}
                            />
                        </FormItemFile>
                        <FormItem {...formItemLayout} label="Bio" format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => <TextArea onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} autoSize={{ minRows: 4 }} ref={ref} />}
                            rules={{ required: false }}
                            name="bio"
                            defaultValue={user.bio}
                        />
                    </Fieldset>
                    <Fieldset legend="Personal Address" isCollapsable={true} isCollapsed={user && user.streetAddress1 ? false : true}>
                        <FormItem {...formItemLayout} label="Street Address 1" format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 1" ref={ref} />}
                            rules={{ required: false }}
                            name="streetAddress1"
                            defaultValue={user.streetAddress1}
                        />
                        <FormItem {...formItemLayout} label="Street Address 2" format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 2" ref={ref} />}
                            rules={{ required: false }}
                            name="streetAddress2"
                            defaultValue={user.streetAddress2}
                        />
                        <FormItem {...formItemLayout} label="City" format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="City" ref={ref} />}
                            rules={{ required: false }}
                            name="city"
                            defaultValue={user.city}
                        />
                        <FormItem {...formItemLayout} label="State" format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select a State"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    ref={ref}
                                >
                                    {states.map(i => <Option value={i.stateAbbr} key={i.stateAbbr}>{i.stateName}</Option>)}
                                </Select>
                            )}
                            rules={{ required: false }}
                            name="state"
                            defaultValue={user.state}
                        />
                        <FormItem {...formItemLayout} label="Postal Code" format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Postal Code" ref={ref} />}
                            rules={{ required: false }}
                            name="postalCode"
                            defaultValue={user.postalCode}
                        />
                        <FormItem {...formItemLayout} label="Country" format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select a Country"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    ref={ref}
                                >
                                    <Option value="USA" key="USA">United States</Option>
                                </Select>
                            )}
                            rules={{ required: false }}
                            name="country"
                            defaultValue={user.country}
                        />
                    </Fieldset>
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} cancelDisabled={((isUpdateLoading === true || isLoading === true) && error === null)} submitDisabled={((isUpdateLoading === true || isLoading === true) && error === null) || (hasValidEmailAddress === false && hasValidPhoneNumber === false)} submitText="Update Profile" />
            </Form>
        </FormProvider>
    );
};

export default EditProfile;