import React, { useEffect, useState } from "react";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { isBooleanTrue, isNotNullOrUndefined } from "../../shared/objectUtils";

const DataCollapsableRow = ({ className = null, headerClassName = null, titleClassName = null, subtitleClassName = null, header = null, collapsedHeader = null, title, subtitle = null, image = null, imageAlt = null, extra = null, isCollapsed = false, isCollapsable = true, isDisabled = false, style = null, titleStyle = null, titleHasAction = false, ...props }) => {
    const [isRowCollapsed, setIsRowCollapsed] = useState(false);

    useEffect(() => {
        setIsRowCollapsed(isCollapsed);
    }, [isCollapsed]);

    const onCollapse = () => {
        setIsRowCollapsed(!isRowCollapsed);
    };

    const getCollapseIcon = (title) => {
        if (isBooleanTrue(isRowCollapsed)) {
            return (
                <Button type="link" style={titleStyle} className={titleClassName} disabled={isDisabled} onClick={onCollapse}>{title} <RightOutlined /></Button>
            );
        } else if (isBooleanTrue(isCollapsable)) {
            return (
                <Button type="link" style={titleStyle} className={titleClassName} disabled={isDisabled} onClick={onCollapse}>{title} <DownOutlined /></Button>
            );
        } else {
            return (
                <span style={titleStyle}>{title}</span>
            );
        }
    };

    if (isBooleanTrue(isRowCollapsed)) {
        return (
            <div className={className} style={style}>
                {isNotNullOrUndefined(collapsedHeader) ? (<div className={headerClassName}>{collapsedHeader}</div>) : (isNotNullOrUndefined(header) ? (<div>{header}</div>) : null)}
                {isNotNullOrUndefined(image) ? (
                    <div style={{ display: 'flex' }}>
                        <div style={{ width: 30, marginRight: 12, display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={image} alt={imageAlt} style={{ width: 30, height: 30 }} />
                        </div>
                        <div style={{ flex: 1 }}>
                            {isNotNullOrUndefined(title) ? (<div className={titleClassName}>{isBooleanTrue(titleHasAction) ? title : getCollapseIcon(title)}{extra}</div>) : null}
                            {isNotNullOrUndefined(subtitle) ? (<div className={subtitleClassName}>{subtitle}</div>) : null}
                        </div>
                    </div>
                ) : (
                    <>
                        {isNotNullOrUndefined(title) ? (<div className={titleClassName}>{isBooleanTrue(titleHasAction) ? title : getCollapseIcon(title)}{extra}</div>) : null}
                        {isNotNullOrUndefined(subtitle) ? (<div className={subtitleClassName}>{subtitle}</div>) : null}
                    </>
                )}
            </div>
        );
    } else {
        return (
            <div style={style}>
                {isNotNullOrUndefined(header) ? (<div>{header}</div>) : null}
                {isNotNullOrUndefined(image) ? (
                    <div style={{ display: 'flex' }}>
                        <div style={{ width: 30, marginRight: 12, display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={image} alt={imageAlt} style={{ width: 30, height: 30 }} />
                        </div>
                        <div style={{ flex: 1 }}>
                            {isNotNullOrUndefined(title) ? (<div className={titleClassName}>{isBooleanTrue(titleHasAction) ? title : getCollapseIcon(title)}{extra}</div>) : null}
                            {isNotNullOrUndefined(subtitle) ? (<div className={subtitleClassName}>{subtitle}</div>) : null}
                        </div>
                    </div>
                ) : (
                    <>
                        {isNotNullOrUndefined(title) ? (<div className={titleClassName}>{isBooleanTrue(titleHasAction) ? title : getCollapseIcon(title)}{extra}</div>) : null}
                        {isNotNullOrUndefined(subtitle) ? (<div className={subtitleClassName}>{subtitle}</div>) : null}
                    </>
                )}
                {props.children}
            </div>
        );
    }
};

export default DataCollapsableRow;