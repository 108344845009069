import axiosAuthenticated from "../../api/axiosAuthenticated";
import { addFile, updateFileMetaData } from "../../api/fileClient";
import * as Data from "./index";
import * as DTO from "../dtos/index";
import { isListNotEmpty, isNotNullOrUndefined, isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import axios from "axios";

const documentsPath = '/documents';
const documentsBulkPath = '/documents/bulk/get';

//#region Documents Methods

export const getImageByDocumentId = async (id, config = {}) => {
    const documentsRes = await axiosAuthenticated.get(documentsPath + `/${id}`, {
        responseType: 'arraybuffer',
        headers: {
            'Accept': 'image/png,image/jpeg,image/jpg'
        },
        ...config
    });
    if (documentsRes && documentsRes.status === 200) {
        return documentsRes.data;
    }

    return null;
};

export const getDocumentByDocumentIdWithLink = async (id, config = {}) => {
    const documentsRes = await axiosAuthenticated.get(documentsPath, { params: { 'id': id }, ...config });
    if (documentsRes && documentsRes.status === 200) {
        return documentsRes.data.data[0];
    }

    return null;
};

export const getDocumentsByEntityIdWithToken = async (entityId, token) => {
    let documents = [];
    if (isStringNotEmpty(entityId)) {
        const documentsRes = await axios.get(documentsPath, {
            params: { 'links.entityId': entityId, isDeleted: false },
            headers: { Authorization: "Bearer " + token }
        });
        if (documentsRes && documentsRes.status === 200) {
            documents = documentsRes.data.data;
        }
    }

    return documents;
};

export const getDocumentsByEntityIds = async (entityIds = []) => {
    let documents = [];
    if (isListNotEmpty(entityIds)) {
        const documentsRes = await axiosAuthenticated.post(documentsBulkPath, { page: 1, size: 1000000, 'links.entityId': [...entityIds] });
        if (documentsRes && documentsRes.status === 200) {
            documents = documentsRes.data.data;
        }
    }

    return documents;
};

export const getDocuments = async (searchParams = {}, pagination = {}, accounts = []) => {
    const documentsRes = await axiosAuthenticated.get(documentsPath, { params: { ...searchParams } });
    if (documentsRes && documentsRes.status === 200) {
        const documents = documentsRes.data.data;
        const otherData = documentsRes.data;

        let reviewedByUserIds = documents.filter((d) => d.reviewedBy !== undefined && d.reviewedBy !== null).map((d) => d.reviewedBy);
        let uniqueReviewedByUserIds = [...new Set([...reviewedByUserIds])];
        let systemUsers = await Data.getUsersByUserIds([...uniqueReviewedByUserIds]);

        const transformedDocuments = documents.map((document) => { return DTO.getDocumentDTO(document, accounts, systemUsers); });

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;

        return { data: transformedDocuments, searchParams: searchParams, pagination: pagination };
    }

    return { data: [], searchParams: searchParams, pagination: pagination };
};

export const getBulkDocuments = async (payload, accounts = []) => {
    let transformedDocuments = [];
    const documentsRes = await axiosAuthenticated.post(documentsBulkPath, { page: 1, size: 1000000, ...payload });
    if (documentsRes && documentsRes.status === 200) {
        const documents = documentsRes.data.data;

        let reviewedByUserIds = documents.filter((d) => d.reviewedBy !== undefined && d.reviewedBy !== null).map((d) => d.reviewedBy);
        let uniqueReviewedByUserIds = [...new Set([...reviewedByUserIds])];
        let systemUsers = await Data.getUsersByUserIds([...uniqueReviewedByUserIds]);

        transformedDocuments = documents.map((document) => { return DTO.getDocumentDTO(document, accounts, systemUsers); });
    }

    return transformedDocuments;
};

export const getDocument = async (id, accounts = []) => {
    const documentsRes = await axiosAuthenticated.get(documentsPath + `/${id}`);
    if (documentsRes && documentsRes.status === 200) {
        let document = documentsRes.data;

        let systemUsers = [];
        if (isStringNotEmpty(document.reviewedBy)) {
            systemUsers = await Data.getUsersByUserIds([document.reviewedBy]);
        }

        return DTO.getDocumentDTO(document, accounts, systemUsers);
    }

    return null;
};

export const addDocument = async (payload, accounts = []) => {
    let document = await addFile(payload.file, payload.entityType, payload.entityId, payload.documentType, payload.displayName, payload.description, payload.visibleTo ? payload.visibleTo : "ALL", payload.expiryDate ? payload.expiryDate : null, payload.isReviewed, payload.reviewStatus, payload.licenseNumber, payload.links);
    if (isObjectNotEmpty(document)) {
        let systemUsers = [];
        if (isStringNotEmpty(document.reviewedBy)) {
            systemUsers = await Data.getUsersByUserIds([document.reviewedBy]);
        }

        return DTO.getDocumentDTO(document, accounts, systemUsers);
    }

    return null;
};

export const updateDocument = async (id, payload, accounts = []) => {
    let document = await updateFileMetaData(id, payload);
    if (isObjectNotEmpty(document)) {
        let systemUsers = [];
        if (isStringNotEmpty(document.reviewedBy)) {
            systemUsers = await Data.getUsersByUserIds([document.reviewedBy]);
        }

        return DTO.getDocumentDTO(document, accounts, systemUsers);
    }

    return null;
};

export const removeDocument = async (id) => {
    let document = await updateFileMetaData(id, { isDeleted: true });
    if (isObjectNotEmpty(document)) {
        return document;
    }

    return null;
};

//#endregion