import React, { useEffect, useReducer } from "react";
import MaskedInput from 'antd-mask-input';
import { CheckCircleTwoTone, LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { checkAccountEINReducer, checkAccountEIN } from '../../shared/formUtils';
import axios from 'axios';
import { isBooleanTrue, isBooleanFalse, isObjectEmpty, isObjectNotEmpty, isStringNotEmpty, isNotNullOrUndefined } from '../../shared/objectUtils';
import { useFormContext } from "react-hook-form";
import { Alert } from "antd";
import FormItem from "../FormItem/FormItem";

const FormItemEIN = ({ format = 'vertical', required = false, name = "ein", defaultValue, accountEIN, setAccountEIN, hasValidAccountEIN, setHasValidAccountEIN, setExistingAccount = null, setAccountExists = null, account = null, allowSelf = true, allowExisting = false }) => {
    //#region useFormContext

    const methods = useFormContext();

    //#endregion
    //#region useReducers

    const [{ accountEINExists, accountEINExistingAccount, checkAccountEINHasError, isCheckAccountEINLoading, checkAccountEINError }, checkAccountEINDispatch] = useReducer(checkAccountEINReducer, {
        accountEINExists: null,
        accountEINExistingAccount: null,
        isCheckAccountEINLoading: false,
        checkAccountEINHasError: false,
        checkAccountEINError: null,
    });

    //#endregion
    //#region onChanges

    const onChangeAccountEIN = (value) => {
        if (isStringNotEmpty(value) && value.length >= 9) {
            if (isObjectNotEmpty(methods.errors.ein)) {
                checkAccountEINDispatch({ type: "FETCH_FAILURE", payload: { error: 'You must first enter a valid unique EIN.' } });
            }
            // console.log(value);
            setAccountEIN(value);
        } else {
            checkAccountEINDispatch({ type: "FETCH_CLEAR" });
        }
    };

    //#endregion
    //#region useEffects

    useEffect(() => {
        //console.log(`accountEIN: ${accountEIN}`);
        const { cancel, token } = axios.CancelToken.source();
        const timeOutId = setTimeout(() => {
            if (isStringNotEmpty(accountEIN) && accountEIN.length >= 9) {
                checkAccountEIN(accountEIN, account, allowSelf, allowExisting, checkAccountEINDispatch, token);
            }
        }, 500);
        return () => cancel("Searching...") || clearTimeout(timeOutId);
    }, [accountEIN, account, allowSelf, allowExisting]);

    useEffect(() => {
        // console.log(accountEIN);
        // console.log(methods.errors.ein);
        // console.log(accountEINExists);
        if (isStringNotEmpty(accountEIN)) {
            if (isObjectEmpty(methods.errors.ein)) {
                if (isBooleanFalse(accountEINExists)) {
                    setHasValidAccountEIN(true);
                    // console.log('Has a valid ein');
                } else if (isBooleanTrue(allowSelf) && isBooleanTrue(accountEINExists) && isObjectNotEmpty(account) && isStringNotEmpty(account.id) && isObjectNotEmpty(accountEINExistingAccount) && isStringNotEmpty(accountEINExistingAccount.id) && account.id === accountEINExistingAccount.id) {
                    setHasValidAccountEIN(true);
                    // console.log('EIN matches what is already saved for this account');
                } else {
                    setHasValidAccountEIN(false);
                }
            } else {
                setHasValidAccountEIN(false);
                checkAccountEINDispatch({ type: "FETCH_CLEAR" });
            }
        } else {
            setHasValidAccountEIN(false);
            checkAccountEINDispatch({ type: "FETCH_CLEAR" });
        }
    }, [methods.errors, accountEIN, account, accountEINExists, accountEINExistingAccount, allowSelf]);

    useEffect(() => {
        if (isNotNullOrUndefined(setAccountExists) && isNotNullOrUndefined(accountEINExists)) {
            setAccountExists(accountEINExists)
        }
    }, [accountEINExists]);

    useEffect(() => {
        if (isNotNullOrUndefined(setExistingAccount) && isNotNullOrUndefined(accountEINExistingAccount)) {
            setExistingAccount(accountEINExistingAccount)
        }
    }, [accountEINExistingAccount]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { sm: 24, md: 24 },
        wrapperCol: { sm: 24, md: 24 },
    };

    const formItemLayoutHorizontal = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    //#endregion

    if (format === 'vertical') {
        return (
            <>
                <FormItem {...formItemLayout} label="Employer Identification Number (EIN)" format="vertical" required={required}
                    render={({ onChange, onBlur, value, name, ref }) => (
                        <MaskedInput
                            onBlur={onBlur}
                            onChange={e => {
                                let value = null;
                                if (isStringNotEmpty(e.target.value)) {
                                    value = e.target.value.replace(/[^0-9]/g, "");
                                }
                                onChangeAccountEIN(value);
                                onChange(value);
                            }}
                            value={value}
                            name={name}
                            placeholder="EIN"
                            addonAfter={isStringNotEmpty(value) && isBooleanTrue(hasValidAccountEIN) ? <CheckCircleTwoTone title="Valid" alt="Valid" twoToneColor="#52c41a" /> : (isBooleanTrue(isCheckAccountEINLoading) ? <LoadingOutlined /> : <SearchOutlined />)}
                            mask="11-1111111"
                            ref={ref}
                        />
                    )}
                    rules={{
                        required: (required ? 'Required Field' : false),
                        pattern: {
                            value: /^\d{9}$/,
                            message: "Please enter a valid 9 digit EIN with no special characters"
                        }
                    }}
                    name={name}
                    defaultValue={defaultValue}
                />
                {checkAccountEINHasError && <Alert message={checkAccountEINError} type="error" style={{ marginBottom: 8 }} />}
            </>
        );
    } else if (format === 'horizontal') {
        return (
            <>
                <FormItem {...formItemLayoutHorizontal} label="Employer Identification Number (EIN)" format="horizontal" required={required}
                    render={({ onChange, onBlur, value, name, ref }) => (
                        <MaskedInput
                            onBlur={onBlur}
                            onChange={e => {
                                let value = null;
                                if (isStringNotEmpty(e.target.value)) {
                                    value = e.target.value.replace(/[^0-9]/g, "");
                                }
                                onChangeAccountEIN(value);
                                onChange(value);
                            }}
                            value={value}
                            name={name}
                            placeholder="EIN"
                            addonAfter={isStringNotEmpty(value) && isBooleanTrue(hasValidAccountEIN) ? <CheckCircleTwoTone title="Valid" alt="Valid" twoToneColor="#52c41a" /> : (isBooleanTrue(isCheckAccountEINLoading) ? <LoadingOutlined /> : <SearchOutlined />)}
                            mask="11-1111111"
                            ref={ref}
                        />
                    )}
                    rules={{
                        required: (required ? 'Required Field' : false),
                        pattern: {
                            value: /^\d{9}$/,
                            message: "Please enter a valid 9 digit EIN with no special characters"
                        }
                    }}
                    name={name}
                    defaultValue={defaultValue}
                />
                {checkAccountEINHasError && <Alert message={checkAccountEINError} type="error" style={{ marginBottom: 8 }} />}
            </>
        );
    } else {
        return null;
    }
};

export default FormItemEIN;