import React from 'react';
import { withRouter } from 'react-router-dom';

const NoAccess = (props) => {
    return (
        <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h2 style={{ textAlign: 'center' }}>Uh Oh! It looks like you don't have permissions to view the page you are trying to access.</h2>
        </div>
    );
};

export default withRouter(NoAccess);