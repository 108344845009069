import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { Spin, Tabs, Row, Col } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarked } from '@fortawesome/free-solid-svg-icons';
import Header from '../../components/Header/Header';
import { useLocation } from 'react-router';
import ComplexDataLinkCard from '../../components/ComplexDataLinkCard/ComplexDataLinkCard';
import ComplexCard from '../../components/ComplexCard/ComplexCard';
import DataAddressRow from '../../components/DataAddressRow/DataAddressRow';
import DataListRow from '../../components/DataListRow/DataListRow';
import DataContactInfoRow from '../../components/DataContactInfoRow/DataContactInfoRow';
import DataRow from '../../components/DataRow/DataRow';
import StringFormatter from '../../shared/stringFormatter';
import { isListNotEmpty, isNumberNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import LoadStopUtils from '../../api/utils/loadStopUtils';

const { TabPane } = Tabs;
const stringFormatter = new StringFormatter();

const Location = props => {
    //#region props

    const id = props.match.params.id;
    const previousBreadcrumbs = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isListNotEmpty(props.history.location.state.previousBreadcrumbs) ? props.history.location.state.previousBreadcrumbs : [];
    const previousLocation = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageLocation) ? props.history.location.state.previousPageLocation : { pathname: `/locations` };
    const previousPath = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageLocation) ? props.history.location.state.previousPageLocation : `/locations`;
    const previousBreadcrumbName = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageTitle) ? props.history.location.state.previousPageTitle : 'Locations';

    //#endregion
    //#region useLocation

    const routerLocation = useLocation();

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.locations.isRecordLoading);
    const error = useSelector(state => state.locations.recordError);
    const location = useSelector(state => state.locations.record);
    const entityId = useSelector(state => state.auth.entityId);

    //#endregion
    //#region useStates

    const [tabKey, setTabKey] = useState('snapshot');

    //#endregion
    //#region tab methods

    const onTabChange = (key) => {
        props.history.replace({ ...props.location, hash: `#${key}` });
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        // console.log('location changed')
        // console.log(routerLocation)
        if (routerLocation.hash !== undefined && routerLocation.hash !== null && routerLocation.hash !== "") {
            // console.log(routerLocation.hash.replace('#', ''))
            setTabKey(routerLocation.hash.replace('#', ''));
        } else {
            setTabKey('snapshot');
            props.history.replace({ ...routerLocation, hash: `#snapshot` });
        }
    }, [routerLocation]);

    useMemo(() => {
        dispatch(actionCreators.fetchLocation(id, entityId));
    }, [id, entityId]);

    //#endregion
    //#region displays

    const breadcrumbs = [...previousBreadcrumbs,
    {
        location: previousLocation,
        path: previousPath,
        breadcrumbName: previousBreadcrumbName,
    },
    {
        path: `/locations/${isStringNotEmpty(id) ? id : ''}`,
        breadcrumbName: `${isObjectNotEmpty(location) && isStringNotEmpty(location.name) ? location.name : ''}`,
    },
    ];

    //#endregion

    if (isObjectNotEmpty(location) && location.id === id) {
        const locationInfo = LoadStopUtils.getLocationInfoByLink(location, entityId);
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                <Header
                    title={isStringNotEmpty(location.name) ? location.name : 'Loading...'}
                    subtitle={isStringNotEmpty(location.irisId) ? location.irisId : null}
                    icon={<FontAwesomeIcon className="anticon" icon={faMapMarked} />}
                    breadcrumbs={breadcrumbs}
                />
                <Tabs activeKey={tabKey} onChange={onTabChange} destroyInactiveTabPane={true} tabBarStyle={{ backgroundColor: '#ffffff', paddingRight: 24, paddingLeft: 24, margin: 0 }}>
                    <TabPane tab="Snapshot" key="snapshot">
                        <div style={{ padding: 24 }}>
                            <Row gutter={[16, 16]}>
                                <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                    <ComplexCard
                                        title="Location Details"
                                        headStyle={{ fontSize: 14 }}
                                    >
                                        {isObjectNotEmpty(location.address) ? <DataAddressRow title="Address" address={location.address} /> : null}
                                        {isListNotEmpty(locationInfo.locationTypes) ? <DataRow title="Location Type(s)" value={locationInfo.locationTypes.map(item => stringFormatter.toFormattedString("LocationType", item, null)).join(', ')} dataType="String" /> : null}
                                        {isStringNotEmpty(location.timeZone) ? <DataRow title="Time Zone" value={location.timeZone} dataType="String" /> : null}
                                        {isObjectNotEmpty(locationInfo.pointOfContact) ? <DataContactInfoRow title="Point of Contact" contact={locationInfo.pointOfContact} /> : null}
                                        {isListNotEmpty(locationInfo.commodities) ? <DataListRow title="Commodities" data={locationInfo.commodities.map((commodity) => { return commodity.name; })} /> : null}
                                    </ComplexCard>
                                </Col>
                                <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                    <ComplexDataLinkCard
                                        title="Additional Location Details"
                                        style={{ height: 300 }}
                                        headStyle={{ fontSize: 14 }}
                                        data={{
                                            "Appointment Type": { value: isStringNotEmpty(locationInfo.appointmentType) ? locationInfo.appointmentType : null, dataType: 'AppointmentType' },
                                            "Special Instructions": { value: isStringNotEmpty(locationInfo.specialInstructions) ? locationInfo.specialInstructions : null, dataType: 'String' },
                                            "Lumper Fee": { value: isNumberNotEmpty(locationInfo.lumperFee) ? locationInfo.LumperFee : null, units: isStringNotEmpty(locationInfo.lumperFeeUnit) ? locationInfo.lumperFeeUnit : null, dataType: 'Money' },
                                        }}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </TabPane>
                </Tabs>
            </Spin>
        );
    } else {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={true} />
        );
    }
};

export default withRouter(Location);