import { isNumberNotEmpty } from "../../shared/objectUtils";

export const getAddConfigurationRequestDTO = (payload) => {
    if (isNumberNotEmpty(payload.geoFenceRadius)) {
        payload.geoFenceRadius = Number(payload.geoFenceRadius);
        payload.geoFenceRadiusUnit = "MI";
    }
    if (isNumberNotEmpty(payload.locationDistanceInterval)) {
        payload.locationDistanceInterval = Number(payload.locationDistanceInterval);
        payload.locationDistanceIntervalUnit = "MI";
    }
    if (isNumberNotEmpty(payload.locationTimeInterval)) {
        payload.locationTimeInterval = Number(payload.locationTimeInterval);
        payload.locationTimeIntervalUnit = "SECS";
    }

    return payload;
};

export const getUpdateConfigurationRequestDTO = (payload) => {
    if (isNumberNotEmpty(payload.geoFenceRadius)) {
        payload.geoFenceRadius = Number(payload.geoFenceRadius);
        payload.geoFenceRadiusUnit = "MI";
    }
    if (isNumberNotEmpty(payload.locationDistanceInterval)) {
        payload.locationDistanceInterval = Number(payload.locationDistanceInterval);
        payload.locationDistanceIntervalUnit = "MI";
    }
    if (isNumberNotEmpty(payload.locationTimeInterval)) {
        payload.locationTimeInterval = Number(payload.locationTimeInterval);
        payload.locationTimeIntervalUnit = "SECS";
    }

    return payload;
};