import { isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";

export const getAccountRegistrationDTO = (accountRegistration, staff = []) => {
    if (isStringNotEmpty(accountRegistration.reviewedBy) && isListNotEmpty(staff)) {
        let reviewedByUser = staff.find(i => i.user_id === accountRegistration.reviewedBy);
        if (isObjectNotEmpty(reviewedByUser)) {
            accountRegistration.reviewedByUser = reviewedByUser;
        }
    }

    return accountRegistration;
};