import React, { useState, useMemo } from 'react';
import { Card, Drawer, Modal, Button, Spin } from "antd";
import DataRow from "../../components/DataRow/DataRow";
import DataContactInfoRow from "../../components/DataContactInfoRow/DataContactInfoRow";
import DataAddressRow from "../../components/DataAddressRow/DataAddressRow";
import classes from './LoadShipperCard.module.scss';
import EditShipper from "../../components/EditShipper/EditShipper";
import AddEditChangeRemoveButtons from '../../components/AddEditChangeRemoveButtons/AddEditChangeRemoveButtons';
import DocumentList from '../../components/DocumentList/DocumentList';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import DataPhoneNumberRow from '../DataPhoneNumberRow/DataPhoneNumberRow';
import { isBooleanFalse, isBooleanTrue, isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import EntityUtils from '../../api/entityUtils';
import { CanSee } from '../../shared/entitlements/entitlements';

const missingEntityStyle = {
    backgroundColor: '#fff1f0',
    border: '1px solid #ffa39e',
};

const LoadShipperCard = ({ load, title, style = {}, headStyle = {} }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    const singularEntityName = "Shipper";

    //#endregion
    //#region useStates

    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showDocumentsModal, setShowDocumentsModal] = useState(false);
    const [missingDocumentsCount, setMissingDocumentsCount] = useState(0);
    const [pendingApprovalCount, setPendingApprovalCount] = useState(0);
    const [expiringSoonDocumentsCount, setExpiringSoonDocumentsCount] = useState(0);
    const [expiredDocumentsCount, setExpiredDocumentsCount] = useState(0);
    const [shipperAccount, setShipperAccount] = useState(null);
    const [isShipperActive, setIsShipperActive] = useState(false);
    const [isShipperVerified, setIsShipperVerified] = useState(false);

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.accounts.isRecordUpdateLoading);
    const error = useSelector(state => state.accounts.updateRecordError);
    const accounts = useSelector(state => state.orchestrator.accounts);

    //#endregion
    //#region account methods

    const verifyAccount = (accountId) => {
        dispatch(actionCreators.verifyAccount(accountId));
    };

    //#endregion
    //#region toggles

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleDocumentsModal = () => {
        setShowDocumentsModal(!showDocumentsModal);
    };

    //#endregion
    //#region displays

    const extra = (
        <CanSee staffOnly={true}>
            <AddEditChangeRemoveButtons
                showAdd={false} addText='' addAction={null}
                showEdit={isObjectNotEmpty(load) && isStringNotEmpty(load.shipperId) ? true : false} editText={"Edit " + singularEntityName + " Details"} editAction={(e) => { e.stopPropagation(); toggleEditEntity(); }}
                showChange={false} changeText='' changeAction={null}
                showRemove={false} removeText='' removeAction={null}
            />
        </CanSee>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName + " Details"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditEntity}
            visible={showEditEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditShipper
                cancel={toggleEditEntity}
                account={shipperAccount}
            />
        </Drawer>
    );

    const documentsComponent = (
        <Modal
            title="Shipper Documents"
            visible={showDocumentsModal === true}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleDocumentsModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            {isObjectNotEmpty(load) && isStringNotEmpty(load.shipperId) && isObjectNotEmpty(shipperAccount) ? (
                <DocumentList
                    documentEntityType="ACCOUNT"
                    documentEntityId={load.shipperId}
                    account={shipperAccount}
                    updatePendingApprovalCount={setPendingApprovalCount}
                    updateMissingDocumentsCount={setMissingDocumentsCount}
                    updateExpiringSoonDocumentsCount={setExpiringSoonDocumentsCount}
                    updateExpiredDocumentsCount={setExpiredDocumentsCount}
                    loadId={load.id}
                />
            ) : null}
            {isObjectNotEmpty(load) && isStringNotEmpty(load.shipperId) && isBooleanFalse(isShipperVerified) ? (
                <CanSee entityAction="VERIFY" entityModel="ACCOUNT" entityObject={load.shipper}>
                    <Button style={{ marginTop: 20 }} type='primary' onClick={() => { verifyAccount(load.shipperId); }} disabled={pendingApprovalCount > 0 || missingDocumentsCount > 0 || expiredDocumentsCount > 0}>Verify Account</Button>
                </CanSee>
            ) : null}
        </Modal>
    );

    //#endregion
    //#region useMemos

    useMemo(() => {
        dispatch(actionCreators.getAccounts());
    }, []);

    useMemo(() => {
        let isShipperActiveValue = false;
        let isShipperVerifiedValue = false;
        let shipperAccountValue = null;
        if (isListNotEmpty(accounts) && isObjectNotEmpty(load) && isStringNotEmpty(load.shipperId)) {
            shipperAccountValue = EntityUtils.getAccountInfo(load.shipperId, accounts);
            if (isObjectNotEmpty(shipperAccountValue)) {
                isShipperActiveValue = isBooleanTrue(shipperAccountValue.isActive) ? true : false;
                isShipperVerifiedValue = isBooleanTrue(shipperAccountValue.isVerified) ? true : false;
            }
        }

        setShipperAccount(shipperAccountValue);
        setIsShipperActive(isShipperActiveValue === true);
        setIsShipperVerified(isShipperVerifiedValue === true);
    }, [accounts, load]);

    useMemo(() => {
        if (isLoading !== null && isLoading === false) {
            // close documents modal after the shipper is updated
            setShowDocumentsModal(false);
        }
    }, [isLoading]);

    //#endregion

    if (isObjectNotEmpty(load) && isObjectNotEmpty(shipperAccount)) {
        return (
            <CanSee entityAction="READ" entityModel="LOAD_SHIPPER" entityObject={load}>
                <Card title={title} bordered={true} className={classes.card} style={isObjectNotEmpty(shipperAccount) && isBooleanTrue(isShipperActive) && (isBooleanTrue(isShipperVerified) || load.serviceType === 'TMS') ? style : { ...style, ...missingEntityStyle }} headStyle={headStyle ? headStyle : null} extra={extra}>
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                        <DataRow title='Shipper ID' value={shipperAccount.irisId} dataType='String' />
                        <DataRow title='Name' value={shipperAccount.name} dataType='String' />
                        <DataRow title='D&B #' value={shipperAccount.dbNum} dataType='String' />
                        <DataRow title='PBB #' value={shipperAccount.blueBookNum} dataType='String' />
                        {isObjectNotEmpty(shipperAccount.pointOfContact) ? <DataContactInfoRow separateLines={true} title="Contact" contact={shipperAccount.pointOfContact} /> : null}
                        {isStringNotEmpty(shipperAccount.afterHoursPhone) ? <DataPhoneNumberRow title="After Hours Phone" phone={shipperAccount.afterHoursPhone} phoneExt={shipperAccount.afterHoursPhoneExt} /> : null}
                        <CanSee staffOnly={true}>
                            <div><b>Documents:</b> <Button type="link" onClick={toggleDocumentsModal}>view</Button></div>
                        </CanSee>
                        <CanSee entityTypes={['STAFF', 'SHIPPER']}>
                            {isObjectNotEmpty(shipperAccount.accountsPayable) ? <DataContactInfoRow valueStyle={{ marginLeft: 16 }} title="A/P" contact={shipperAccount.accountsPayable} /> : null}
                            {isObjectNotEmpty(shipperAccount.accountsPayableAddress) ? <DataAddressRow valueStyle={{ marginLeft: 16 }} title="A/P Address" address={shipperAccount.accountsPayableAddress} /> : null}
                        </CanSee>
                        {isBooleanFalse(isShipperVerified) ? (
                            <CanSee staffOnly={true}>
                                <Button style={{ marginTop: 20 }} type='primary' onClick={toggleDocumentsModal}>Verify Account</Button>
                            </CanSee>
                        ) : null}
                    </Spin>
                </Card>
                {editEntityComponents}
                {documentsComponent}
            </CanSee>
        );
    } else {
        return null;
    }
};

export default LoadShipperCard;