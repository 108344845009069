import axiosAuthenticated from "../../api/axiosAuthenticated";
import { isListNotEmpty, isNotNullOrUndefined, isNumberNotEmpty, isObjectNotEmpty, isStringEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import * as DTO from "../dtos/index";
import * as Data from "./index";

const transactionsPath = '/transactions';

//#region Transactions Methods

export const getTransactionsByLoadId = async (loadId) => {
    if (isStringNotEmpty(loadId)) {
        const transactionsRes = await axiosAuthenticated.get(transactionsPath, { params: { page: 1, size: 1000000, loadId: loadId, isDeleted: false } });
        if (transactionsRes && transactionsRes.status === 200) {
            return transactionsRes.data.data;
        }
    }

    return [];
};

export const getTransactions = async (searchParams = {}, pagination = {}) => {
    const transactionsRes = await axiosAuthenticated.get(transactionsPath, { params: { ...searchParams } });
    if (transactionsRes && transactionsRes.status === 200) {
        const transactions = transactionsRes.data.data;
        const otherData = transactionsRes.data;

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;

        return { data: transactions, searchParams: searchParams, pagination: pagination };
    }

    return { data: [], searchParams: searchParams, pagination: pagination };
};

export const addTransaction = async (payload, existingInvoiceLineItems = [], existingTransactions = [], existingClaims = [], loadId = null, invoice, sendLoadEvent = false) => {
    if (isStringEmpty(payload.fromEntityId)) {
        if (payload.fromEntityType === 'SHIPPER') {
            throw new Error('No ShipperId was provided');
        } else if (payload.fromEntityType === 'CARRIER') {
            throw new Error('No AssignedCarrierId was provided');
        }
    }

    if (isStringEmpty(payload.toEntityId)) {
        if (payload.toEntityType === 'SHIPPER') {
            throw new Error('No ShipperId was provided');
        } else if (payload.toEntityType === 'CARRIER') {
            throw new Error('No AssignedCarrierId was provided');
        }
    }

    payload = DTO.getAddTransactionRequestDTO(payload);

    const transactionsRes = await axiosAuthenticated.post(transactionsPath, { ...payload });
    if (transactionsRes && transactionsRes.status === 201) {
        let newTransaction = transactionsRes.data;

        if (isStringNotEmpty(loadId)) {
            let updatedInvoiceLineItems = [];
            if (isListNotEmpty(existingInvoiceLineItems)) {
                updatedInvoiceLineItems = [...existingInvoiceLineItems];
            }
            let updatedTransactions = [];
            if (isListNotEmpty(existingTransactions)) {
                updatedTransactions = [...existingTransactions];
            }
            let updatedClaims = [];
            if (isListNotEmpty(existingClaims)) {
                updatedClaims = [...existingClaims];
            }

            updatedTransactions.push(newTransaction);

            await Data.updateLoadPricing(loadId, updatedInvoiceLineItems, updatedTransactions, updatedClaims);

            if (isObjectNotEmpty(invoice)) {
                let updatedLoadPayload = {};
                if (invoice.invoiceType === 'CARRIER_INVOICE') {
                    updatedLoadPayload.completionCheckList = {
                        carrierInvoicePaymentMethod: newTransaction.paymentMethod
                    };
                } else if (invoice.invoiceType === 'SHIPPER_INVOICE') {
                    updatedLoadPayload.completionCheckList = {
                        shipperInvoicePaymentMethod: newTransaction.paymentMethod
                    };
                }
                await Data.updateLoad(loadId, updatedLoadPayload);

                let updatedInvoicePayload = {
                    paymentStatus: newTransaction.paymentStatus,
                    paidAt: newTransaction.paidAt,
                    paidBy: newTransaction.createdBy
                };

                if (newTransaction.paymentStatus === 'COMPLETED') {
                    if (isNumberNotEmpty(invoice.balanceDue) && isNumberNotEmpty(newTransaction.netAmount)) {
                        if (Number(invoice.balanceDue) === Number(newTransaction.netAmount)) {
                            // if the transaction is for the full balance due and the status of the transaction is COMPLETED, mark the invoice as COMPLETED
                            let invoiceLineItemIds = [];
                            updatedInvoicePayload.balanceDue = 0;
                            updatedInvoicePayload.status = 'COMPLETED';

                            if (isListNotEmpty(invoice.invoiceLineItems)) {
                                invoice.invoiceLineItems.filter(i => i.status === 'PENDING').forEach((invoiceLineItem) => {
                                    invoiceLineItemIds.push(invoiceLineItem.id);
                                });
                                await Data.updateInvoiceLineItemsStatus(invoiceLineItemIds, 'COMPLETED');
                            }
                        } else {
                            // if the transaction amount is less than the full balance due on the invoice, only mark some of the invoice line items as COMPLETED
                            let invoiceLineItemIds = [];
                            let fromEntityType = newTransaction.fromEntityType;
                            let toEntityType = newTransaction.toEntityType;

                            if (isListNotEmpty(invoice.invoiceLineItems)) {
                                let totalAmountDue = 0.0;
                                invoice.invoiceLineItems.filter(i => i.status === 'PENDING' && ((i.fromEntityType === fromEntityType && i.toEntityType === toEntityType) || (i.fromEntityType === toEntityType && i.toEntityType === fromEntityType)) && i.totalAmount > 0).forEach((invoiceLineItem) => {
                                    if (invoiceLineItem.fromEntityType === fromEntityType && invoiceLineItem.toEntityType === toEntityType) {
                                        if (isNumberNotEmpty(invoiceLineItem.totalAmount)) {
                                            totalAmountDue += Number(Number(invoiceLineItem.totalAmount).toFixed(2));
                                        }
                                    } else if (invoiceLineItem.fromEntityType === toEntityType && invoiceLineItem.toEntityType === fromEntityType) {
                                        if (isNumberNotEmpty(invoiceLineItem.totalAmount)) {
                                            totalAmountDue -= Number(Number(invoiceLineItem.totalAmount).toFixed(2));
                                        }
                                    }
                                });
                                if (totalAmountDue <= newTransaction.netAmount) {
                                    invoice.invoiceLineItems.filter(i => i.status === 'PENDING' && ((i.fromEntityType === fromEntityType && i.toEntityType === toEntityType) || (i.fromEntityType === toEntityType && i.toEntityType === fromEntityType)) && i.totalAmount > 0).forEach((invoiceLineItem) => {
                                        invoiceLineItemIds.push(invoiceLineItem.id);
                                    });
                                }
                                if (isListNotEmpty(invoiceLineItemIds)) {
                                    await Data.updateInvoiceLineItemsStatus(invoiceLineItemIds, 'COMPLETED');
                                }
                            }

                            updatedInvoicePayload.balanceDue = Number(invoice.balanceDue) - Number(newTransaction.netAmount);
                        }
                    }
                }

                await Data.updateInvoice(invoice.id, updatedInvoicePayload, invoice);
            } else {
                if (newTransaction.paymentStatus === 'COMPLETED') {
                    let invoiceLineItemIds = [];
                    let fromEntityType = newTransaction.fromEntityType;
                    let toEntityType = newTransaction.toEntityType;
                    if (isListNotEmpty(updatedInvoiceLineItems)) {
                        let totalAmountDue = 0.0;
                        updatedInvoiceLineItems.filter(i => i.status === 'PENDING' && ((i.fromEntityType === fromEntityType && i.toEntityType === toEntityType) || (i.fromEntityType === toEntityType && i.toEntityType === fromEntityType)) && i.totalAmount > 0).forEach((invoiceLineItem) => {
                            if (invoiceLineItem.fromEntityType === fromEntityType && invoiceLineItem.toEntityType === toEntityType) {
                                if (isNumberNotEmpty(invoiceLineItem.totalAmount)) {
                                    totalAmountDue += Number(Number(invoiceLineItem.totalAmount).toFixed(2));
                                }
                            } else if (invoiceLineItem.fromEntityType === toEntityType && invoiceLineItem.toEntityType === fromEntityType) {
                                if (isNumberNotEmpty(invoiceLineItem.totalAmount)) {
                                    totalAmountDue -= Number(Number(invoiceLineItem.totalAmount).toFixed(2));
                                }
                            }
                        });
                        if (totalAmountDue <= newTransaction.netAmount) {
                            updatedInvoiceLineItems.filter(i => i.status === 'PENDING' && ((i.fromEntityType === fromEntityType && i.toEntityType === toEntityType) || (i.fromEntityType === toEntityType && i.toEntityType === fromEntityType)) && i.totalAmount > 0).forEach((invoiceLineItem) => {
                                invoiceLineItemIds.push(invoiceLineItem.id);
                            });
                        }
                        if (isListNotEmpty(invoiceLineItemIds)) {
                            await Data.updateInvoiceLineItemsStatus(invoiceLineItemIds, 'COMPLETED');
                        }
                    }
                }
            }

            if (sendLoadEvent === true) {
                await Data.addLoadEvent({ loadId: loadId, eventType: 'LOAD_UPDATED', changeType: 'TRANSACTIONS_UPDATED' });
            }
        }

        return newTransaction;
    }

    return null;
};

export const updateTransaction = async (id, payload, oldTransaction, existingInvoiceLineItems = [], oldTransactions = [], existingClaims = [], loadId = null, invoice, sendLoadEvent = false) => {
    payload = DTO.getUpdateTransactionRequestDTO(payload);

    const transactionsRes = await axiosAuthenticated.put(transactionsPath + `/${id}`, { ...payload });
    if (transactionsRes && transactionsRes.status === 200) {
        let updatedTransaction = transactionsRes.data;

        if (isStringNotEmpty(loadId)) {
            let updatedInvoiceLineItems = [];
            if (isListNotEmpty(existingInvoiceLineItems)) {
                updatedInvoiceLineItems = [...existingInvoiceLineItems];
            }
            let existingTransactions = [];
            if (isListNotEmpty(oldTransactions)) {
                existingTransactions = [...oldTransactions];
            }
            let updatedClaims = [];
            if (isListNotEmpty(existingClaims)) {
                updatedClaims = [...existingClaims];
            }

            const index = existingTransactions.findIndex(obj => obj.id === updatedTransaction.id);
            if (index !== -1) {
                const updatedTransactions = [
                    ...existingTransactions.slice(0, index), // everything before current obj
                    updatedTransaction,
                    ...existingTransactions.slice(index + 1), // everything after current obj
                ];

                await Data.updateLoadPricing(loadId, updatedInvoiceLineItems, updatedTransactions, updatedClaims);
            }

            if (isObjectNotEmpty(invoice)) {
                // if payment method changed
                if (updatedTransaction.paymentMethod !== oldTransaction.paymentMethod) {
                    let updatedLoadPayload = {};
                    if (invoice.invoiceType === 'CARRIER_INVOICE') {
                        updatedLoadPayload.completionCheckList = {
                            carrierInvoicePaymentMethod: updatedTransaction.paymentMethod
                        };
                    } else if (invoice.invoiceType === 'SHIPPER_INVOICE') {
                        updatedLoadPayload.completionCheckList = {
                            shipperInvoicePaymentMethod: updatedTransaction.paymentMethod
                        };
                    }
                    await Data.updateLoad(loadId, updatedLoadPayload);
                }

                // if payment status changed
                if (updatedTransaction.paymentStatus !== oldTransaction.paymentStatus) {
                    let updatedInvoicePayload = {
                        paymentStatus: updatedTransaction.paymentStatus
                    };

                    if (updatedTransaction.paymentStatus === 'COMPLETED') {
                        if (isNumberNotEmpty(invoice.balanceDue) && isNumberNotEmpty(updatedTransaction.netAmount)) {
                            if (Number(invoice.balanceDue) === Number(updatedTransaction.netAmount)) {
                                // if the transaction is for the full balance due and the status of the transaction is COMPLETED, mark the invoice as COMPLETED
                                let invoiceLineItemIds = [];
                                updatedInvoicePayload.balanceDue = 0;
                                updatedInvoicePayload.status = 'COMPLETED';
    
                                if (isListNotEmpty(invoice.invoiceLineItems)) {
                                    invoice.invoiceLineItems.filter(i => i.status === 'PENDING').forEach((invoiceLineItem) => {
                                        invoiceLineItemIds.push(invoiceLineItem.id);
                                    });
                                    await Data.updateInvoiceLineItemsStatus(invoiceLineItemIds, 'COMPLETED');
                                }
                            } else {
                                // if the transaction amount is less than the full balance due on the invoice, only mark some of the invoice line items as COMPLETED
                                let invoiceLineItemIds = [];
                                let fromEntityType = updatedTransaction.fromEntityType;
                                let toEntityType = updatedTransaction.toEntityType;

                                if (isListNotEmpty(invoice.invoiceLineItems)) {
                                    let totalAmountDue = 0.0;
                                    invoice.invoiceLineItems.filter(i => i.status === 'PENDING' && ((i.fromEntityType === fromEntityType && i.toEntityType === toEntityType) || (i.fromEntityType === toEntityType && i.toEntityType === fromEntityType)) && i.totalAmount > 0).forEach((invoiceLineItem) => {
                                        if (invoiceLineItem.fromEntityType === fromEntityType && invoiceLineItem.toEntityType === toEntityType) {
                                            if (isNumberNotEmpty(invoiceLineItem.totalAmount)) {
                                                totalAmountDue += Number(Number(invoiceLineItem.totalAmount).toFixed(2));
                                            }
                                        } else if (invoiceLineItem.fromEntityType === toEntityType && invoiceLineItem.toEntityType === fromEntityType) {
                                            if (isNumberNotEmpty(invoiceLineItem.totalAmount)) {
                                                totalAmountDue -= Number(Number(invoiceLineItem.totalAmount).toFixed(2));
                                            }
                                        }
                                    });
                                    if (totalAmountDue <= updatedTransaction.netAmount) {
                                        invoice.invoiceLineItems.filter(i => i.status === 'PENDING' && ((i.fromEntityType === fromEntityType && i.toEntityType === toEntityType) || (i.fromEntityType === toEntityType && i.toEntityType === fromEntityType)) && i.totalAmount > 0).forEach((invoiceLineItem) => {
                                            invoiceLineItemIds.push(invoiceLineItem.id);
                                        });
                                    }
                                    if (isListNotEmpty(invoiceLineItemIds)) {
                                        await Data.updateInvoiceLineItemsStatus(invoiceLineItemIds, 'COMPLETED');
                                    }
                                }

                                updatedInvoicePayload.balanceDue = Number(invoice.balanceDue) - Number(updatedTransaction.netAmount);
                            }
                        }
                    }

                    await Data.updateInvoice(invoice.id, updatedInvoicePayload, invoice);
                }
            } else {
                // if payment status changed
                if (updatedTransaction.paymentStatus !== oldTransaction.paymentStatus) {
                    if (updatedTransaction.paymentStatus === 'COMPLETED') {
                        let invoiceLineItemIds = [];
                        let fromEntityType = updatedTransaction.fromEntityType;
                        let toEntityType = updatedTransaction.toEntityType;
                        if (isListNotEmpty(updatedInvoiceLineItems)) {
                            let totalAmountDue = 0.0;
                            updatedInvoiceLineItems.filter(i => i.status === 'PENDING' && ((i.fromEntityType === fromEntityType && i.toEntityType === toEntityType) || (i.fromEntityType === toEntityType && i.toEntityType === fromEntityType)) && i.totalAmount > 0).forEach((invoiceLineItem) => {
                                if (invoiceLineItem.fromEntityType === fromEntityType && invoiceLineItem.toEntityType === toEntityType) {
                                    if (isNumberNotEmpty(invoiceLineItem.totalAmount)) {
                                        totalAmountDue += Number(Number(invoiceLineItem.totalAmount).toFixed(2));
                                    }
                                } else if (invoiceLineItem.fromEntityType === toEntityType && invoiceLineItem.toEntityType === fromEntityType) {
                                    if (isNumberNotEmpty(invoiceLineItem.totalAmount)) {
                                        totalAmountDue -= Number(Number(invoiceLineItem.totalAmount).toFixed(2));
                                    }
                                }
                            });
                            if (totalAmountDue <= updatedTransaction.netAmount) {
                                updatedInvoiceLineItems.filter(i => i.status === 'PENDING' && ((i.fromEntityType === fromEntityType && i.toEntityType === toEntityType) || (i.fromEntityType === toEntityType && i.toEntityType === fromEntityType)) && i.totalAmount > 0).forEach((invoiceLineItem) => {
                                    invoiceLineItemIds.push(invoiceLineItem.id);
                                });
                            }
                            if (isListNotEmpty(invoiceLineItemIds)) {
                                await Data.updateInvoiceLineItemsStatus(invoiceLineItemIds, 'COMPLETED');
                            }
                        }
                    }
                }
            }

            if (sendLoadEvent === true) {
                await Data.addLoadEvent({ loadId: loadId, eventType: 'LOAD_UPDATED', changeType: 'TRANSACTIONS_UPDATED' });
            }
        }

        return updatedTransaction;
    }

    return null;
};

export const removeTransaction = async (id, oldTransaction, existingInvoiceLineItems = [], existingTransactions = [], existingClaims = [], loadId = null, invoice, sendLoadEvent = false) => {
    const transactionsRes = await axiosAuthenticated.put(transactionsPath + `/${id}`, { isDeleted: true });
    if (transactionsRes && transactionsRes.status === 200) {
        let updatedTransaction = transactionsRes.data;

        if (isStringNotEmpty(loadId)) {
            let updatedInvoiceLineItems = [];
            if (isListNotEmpty(existingInvoiceLineItems)) {
                updatedInvoiceLineItems = [...existingInvoiceLineItems];
            }
            let updatedTransactions = [];
            if (isListNotEmpty(existingTransactions)) {
                updatedTransactions = [...existingTransactions];
            }
            let updatedClaims = [];
            if (isListNotEmpty(existingClaims)) {
                updatedClaims = [...existingClaims];
            }

            const index = updatedTransactions.findIndex(obj => obj.id === updatedTransaction.id);
            if (index !== -1) {
                // returns the deleted items
                updatedTransactions.splice(index, 1);

                await Data.updateLoadPricing(loadId, updatedInvoiceLineItems, updatedTransactions, updatedClaims);
            }

            if (isObjectNotEmpty(invoice)) {
                let updatedLoadPayload = {};
                if (invoice.invoiceType === 'CARRIER_INVOICE') {
                    updatedLoadPayload.completionCheckList = {
                        carrierInvoicePaymentMethod: null
                    };
                } else if (invoice.invoiceType === 'SHIPPER_INVOICE') {
                    updatedLoadPayload.completionCheckList = {
                        shipperInvoicePaymentMethod: null
                    };
                }
                await Data.updateLoad(loadId, updatedLoadPayload);

                let updatedInvoicePayload = {
                    paymentStatus: 'PENDING',
                    paidAt: null,
                    paidBy: null,
                    balanceDue: Number(invoice.balanceDue) + Number(oldTransaction.netAmount),
                    status: 'SENT'
                };
                await Data.updateInvoice(invoice.id, updatedInvoicePayload, invoice);
            }

            if (sendLoadEvent === true) {
                await Data.addLoadEvent({ loadId: loadId, eventType: 'LOAD_UPDATED', changeType: 'TRANSACTIONS_UPDATED' });
            }
        }

        return updatedTransaction;
    }

    return null;
};

//#endregion