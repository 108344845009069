const getItem = async (key) => {
    return Promise.resolve().then(() => {
        return localStorage.getItem(key);
    });
};

const setItem = async (key, value) => {
    return Promise.resolve().then(() => {
        localStorage.setItem(key, value);
    });
};

const removeItem = async (key) => {
    return Promise.resolve().then(() => {
        localStorage.removeItem(key);
    });
};

export default {
    getItem,
    setItem,
    removeItem
};