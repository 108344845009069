import * as actionTypes from "../actions/actionTypes";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { isBooleanTrue, isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import ErrorUtils from "../../shared/errorUtils";
import * as actionCreators from "./index";

//#region Fetch Preferred Lane List Methods

export const fetchPreferredLaneListStart = (listName) => {
    return {
        type: actionTypes.FETCH_PREFERRED_LANE_LIST_START,
        payload: { listName: listName }
    }
};

export const fetchPreferredLaneListSuccess = (listName, payload) => {
    return {
        type: actionTypes.FETCH_PREFERRED_LANE_LIST_SUCCESS,
        payload: { listName: listName, ...payload }
    }
};

export const fetchPreferredLaneListFail = (listName, payload) => {
    return {
        type: actionTypes.FETCH_PREFERRED_LANE_LIST_FAIL,
        payload: { listName: listName, ...payload }
    }
};

export const clearPreferredLaneList = (listName) => {
    return {
        type: actionTypes.CLEAR_PREFERRED_LANE_LIST,
        payload: { listName: listName }
    }
};

export const clearPreferredLaneLists = () => {
    return {
        type: actionTypes.CLEAR_PREFERRED_LANE_LISTS
    }
};

export const addPreferredLaneToPreferredLaneList = (listName, payload) => {
    return {
        type: actionTypes.ADD_TO_PREFERRED_LANE_LIST,
        payload: { listName: listName, newRecord: payload }
    }
};

export const updatePreferredLaneInPreferredLaneList = (listName, payload) => {
    return {
        type: actionTypes.UPDATE_IN_PREFERRED_LANE_LIST,
        payload: { listName: listName, updatedRecord: payload }
    }
};

export const removePreferredLaneFromPreferredLaneList = (listName, payload) => {
    return {
        type: actionTypes.REMOVE_FROM_PREFERRED_LANE_LIST,
        payload: { listName: listName, recordToRemove: payload }
    }
};

//#endregion
//#region Fetch Preferred Lane Methods

export const fetchPreferredLaneStart = () => {
    return {
        type: actionTypes.FETCH_PREFERRED_LANE_START
    }
};

export const fetchPreferredLaneSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_PREFERRED_LANE_SUCCESS,
        payload: payload
    }
};

export const fetchPreferredLaneFail = (payload) => {
    return {
        type: actionTypes.FETCH_PREFERRED_LANE_FAIL,
        payload: payload
    }
};

export const clearPreferredLane = () => {
    return {
        type: actionTypes.CLEAR_PREFERRED_LANE
    }
};

//#endregion
//#region Add Preferred Lane Methods

export const addPreferredLaneStart = () => {
    return {
        type: actionTypes.ADD_PREFERRED_LANE_START
    }
};

export const addPreferredLaneSuccess = () => {
    return {
        type: actionTypes.ADD_PREFERRED_LANE_SUCCESS
    }
};

export const addPreferredLaneFail = (payload) => {
    return {
        type: actionTypes.ADD_PREFERRED_LANE_FAIL,
        payload: payload
    }
};

export const addPreferredLaneLoadingClear = () => {
    return {
        type: actionTypes.ADD_PREFERRED_LANE_LOADING_CLEAR
    }
};

export const addPreferredLaneErrorClear = () => {
    return {
        type: actionTypes.ADD_PREFERRED_LANE_ERROR_CLEAR
    }
};

export const addPreferredLaneCancel = () => {
    return {
        type: actionTypes.ADD_PREFERRED_LANE_CANCEL
    }
};

//#endregion
//#region Update Preferred Lane Methods

const changeSinglePreferredLane = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_PREFERRED_LANE,
        payload: payload
    }
};

export const updatePreferredLaneStart = () => {
    return {
        type: actionTypes.UPDATE_PREFERRED_LANE_START
    }
};

export const updatePreferredLaneSuccess = () => {
    return {
        type: actionTypes.UPDATE_PREFERRED_LANE_SUCCESS
    }
};

export const updatePreferredLaneFail = (payload) => {
    return {
        type: actionTypes.UPDATE_PREFERRED_LANE_FAIL,
        payload: payload
    }
};

export const updatePreferredLaneLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_PREFERRED_LANE_LOADING_CLEAR
    }
};

export const updatePreferredLaneErrorClear = () => {
    return {
        type: actionTypes.UPDATE_PREFERRED_LANE_ERROR_CLEAR
    }
};

export const updatePreferredLaneCancel = () => {
    return {
        type: actionTypes.UPDATE_PREFERRED_LANE_CANCEL
    }
};

//#endregion
//#region Preferred Lanes Methods

export const fetchPreferredLaneList = (listName, payload) => {
    return async (dispatch, getState) => {
        if (isStringNotEmpty(listName)) {
            try {
                dispatch(fetchPreferredLaneListStart(listName));

                await dispatch(actionCreators.getAccounts());
                await dispatch(actionCreators.getTrailerTypes());
                const state = getState();
                const orchestratorState = { ...state.orchestrator };
                const accounts = [...orchestratorState.accounts];
                const trailerTypes = [...orchestratorState.trailerTypes];
                const preferredLanesState = { ...state.preferredLanes };
                const existingLists = { ...preferredLanesState.lists };

                let pagination = {};
                let searchParams = {};
                if (isObjectNotEmpty(existingLists[listName])) {
                    pagination = { ...existingLists[listName].pagination };
                    searchParams = { ...existingLists[listName].searchParams };
                }

                if (isObjectNotEmpty(payload)) {
                    searchParams = { ...payload };
                }

                const res = await Data.getPreferredLanes({ ...searchParams }, pagination, accounts, trailerTypes);
                dispatch(fetchPreferredLaneListSuccess(listName, { records: res.data, params: { searchParams: res.searchParams, pagination: res.pagination } }));
            } catch (error) {
                logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
                dispatch(fetchPreferredLaneListFail(listName, { error: ErrorUtils.getErrorMessage(error) }));
            }
        }
    }
};

export const fetchPreferredLane = (id) => {
    return async (dispatch, getState) => {
        try {
            dispatch(fetchPreferredLaneStart());

            await dispatch(actionCreators.getAccounts());
            await dispatch(actionCreators.getTrailerTypes());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const accounts = [...orchestratorState.accounts];
            const trailerTypes = [...orchestratorState.trailerTypes];

            const preferredLane = await Data.getPreferredLane(id, accounts, trailerTypes);
            dispatch(fetchPreferredLaneSuccess({ record: preferredLane }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchPreferredLaneFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const addPreferredLane = (payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(addPreferredLaneStart());

            await dispatch(actionCreators.getAccounts());
            await dispatch(actionCreators.getTrailerTypes());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const accounts = [...orchestratorState.accounts];
            const trailerTypes = [...orchestratorState.trailerTypes];

            const newPreferredLane = await Data.addPreferredLane(payload, accounts, trailerTypes);
            if (isObjectNotEmpty(newPreferredLane)) {
                dispatch(addPreferredLaneToPreferredLaneList(newPreferredLane.accountId, newPreferredLane));
            }

            dispatch(addPreferredLaneSuccess());
            dispatch(addPreferredLaneLoadingClear());
            dispatch(addPreferredLaneErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addPreferredLaneFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updatePreferredLane = (id, payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updatePreferredLaneStart());

            if (isBooleanTrue(payload.isDeleted)) {
                const removedPreferredLane = await Data.removePreferredLane(id);
                if (isObjectNotEmpty(removedPreferredLane)) {
                    dispatch(removePreferredLaneFromPreferredLaneList(removedPreferredLane.accountId, removedPreferredLane));
                }
            } else {
                await dispatch(actionCreators.getAccounts());
                await dispatch(actionCreators.getTrailerTypes());
                const state = getState();
                const orchestratorState = { ...state.orchestrator };
                const accounts = [...orchestratorState.accounts];
                const trailerTypes = [...orchestratorState.trailerTypes];

                const updatedPreferredLane = await Data.updatePreferredLane(id, payload, accounts, trailerTypes);
                if (isObjectNotEmpty(updatedPreferredLane)) {
                    dispatch(updatePreferredLaneInPreferredLaneList(updatedPreferredLane.accountId, updatedPreferredLane));
                    dispatch(changeSinglePreferredLane(updatedPreferredLane));
                }
            }

            dispatch(updatePreferredLaneSuccess());
            dispatch(updatePreferredLaneLoadingClear());
            dispatch(updatePreferredLaneErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updatePreferredLaneFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion