import React, { useMemo, useState, useEffect, useReducer } from 'react';
import { Alert, Divider, Input, Spin, Select, Switch } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import FormButtons from '../FormButtons/FormButtons';
import { isBooleanTrue, isListNotEmpty, isObjectEmpty, isObjectNotEmpty, isStringNotEmpty, removeEmpty } from '../../shared/objectUtils';
import { checkEmailAddressReducer, checkPhoneNumberReducer, isValidEmailAddress, checkEmployeeEmailAddress, checkEmployeePhoneNumber } from '../../shared/formUtils';
import Fieldset from '../FormFieldset/FormFieldset';
import { LoadingOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import axios from 'axios';
import EntityUtils from '../../api/entityUtils';
import { CanSee } from '../../shared/entitlements/entitlements';
import Enums from '../../shared/enums';

const { Option, OptGroup } = Select;

const NewEmployee = ({ accountId: accountIdValue, cancel }) => {
    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.accountUsers.isRecordAddLoading);
    const error = useSelector(state => state.accountUsers.addRecordError);
    const accounts = useSelector(state => state.orchestrator.accounts);

    const shipperAccountRoles = Enums.ShipperAccountRoles.selectListOptions();
    const carrierAccountRoles = Enums.CarrierAccountRoles.selectListOptions();
    const producerAccountRoles = Enums.ProducerAccountRoles.selectListOptions();
    const receiverAccountRoles = Enums.ReceiverAccountRoles.selectListOptions();
    const brokerAccountRoles = Enums.BrokerAccountRoles.selectListOptions();
    const factoringAccountRoles = Enums.FactoringAccountRoles.selectListOptions();

    const [{ emailAddressExists, checkEmailAddressHasError, isCheckEmailAddressLoading, checkEmailAddressError }, checkEmailAddressDispatch] = useReducer(checkEmailAddressReducer, {
        emailAddressExists: null,
        isCheckEmailAddressLoading: false,
        checkEmailAddressHasError: false,
        checkEmailAddressError: null,
    });

    const [{ phoneNumberExists, checkPhoneNumberHasError, isCheckPhoneNumberLoading, checkPhoneNumberError }, checkPhoneNumberDispatch] = useReducer(checkPhoneNumberReducer, {
        phoneNumberExists: null,
        isCheckPhoneNumberLoading: false,
        checkPhoneNumberHasError: false,
        checkPhoneNumberError: null,
    });

    const [accountId, setAccountId] = useState(null);
    const [account, setAccount] = useState(null);
    const [emailAddress, setEmailAddress] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [hasValidEmailAddress, setHasValidEmailAddres] = useState(false);
    const [hasValidPhoneNumber, setHasValidPhoneNumber] = useState(false);

    useEffect(() => {
        // console.log(`emailAddress: ${emailAddress}`);
        const { cancel, token } = axios.CancelToken.source();
        const timeOutId = setTimeout(() => {
            if (isStringNotEmpty(accountId) && isStringNotEmpty(emailAddress) && emailAddress.length >= 4) {
                if (isValidEmailAddress(emailAddress)) {
                    checkEmployeeEmailAddress(emailAddress, accountId, checkEmailAddressDispatch, token);
                }
            }
        }, 500);
        return () => cancel("Searching...") || clearTimeout(timeOutId);
    }, [emailAddress, accountId]);

    useEffect(() => {
        // console.log(`phoneNumber: ${phoneNumber}`);
        const { cancel, token } = axios.CancelToken.source();
        const timeOutId = setTimeout(() => {
            if (isStringNotEmpty(accountId) && isStringNotEmpty(phoneNumber) && phoneNumber.length === 10) {
                checkEmployeePhoneNumber(phoneNumber, accountId, checkPhoneNumberDispatch, token);
            }
        }, 500);
        return () => cancel("Searching...") || clearTimeout(timeOutId);
    }, [phoneNumber, accountId]);

    useMemo(() => {
        if (isStringNotEmpty(accountIdValue) && isListNotEmpty(accounts)) {
            setAccountId(accountIdValue);
            const selectedAccount = EntityUtils.getAccountInfo(accountIdValue, accounts);
            setAccount(selectedAccount);
        }
    }, [accountIdValue, accounts]);

    const handleAccountChange = (value) => {
        // console.log(`selected ${value}`);
        setAccountId(value);
        const selectedAccount = EntityUtils.getAccountInfo(value, accounts);
        setAccount(selectedAccount);
    };

    const onEmailAddressChange = (value) => {
        if (isStringNotEmpty(value) && value.length >= 4) {
            if (isObjectNotEmpty(methods.errors.email)) {
                checkEmailAddressDispatch({ type: "FETCH_FAILURE", payload: { error: 'You must first enter a valid email address' } });
            }
            // console.log(value);
            setEmailAddress(value);
        } else {
            checkEmailAddressDispatch({ type: "FETCH_CLEAR" });
        }
    };

    const onPhoneNumberChange = (value) => {
        if (isStringNotEmpty(value) && value.length === 10) {
            if (isObjectNotEmpty(methods.errors.phone)) {
                checkPhoneNumberDispatch({ type: "FETCH_FAILURE", payload: { error: 'You must first enter a valid phone number' } });
            }
            // console.log(value);
            setPhoneNumber(value);
        } else {
            checkPhoneNumberDispatch({ type: "FETCH_CLEAR" });
        }
    };

    useEffect(() => {
        // console.log(emailAddress);
        // console.log(methods.errors.email);
        // console.log(emailAddressExists);
        if (isStringNotEmpty(emailAddress)) {
            if (isObjectEmpty(methods.errors.email)) {
                if (emailAddressExists !== null && emailAddressExists === false) {
                    setHasValidEmailAddres(true);
                    // console.log('Has a valid email address');
                } else {
                    setHasValidEmailAddres(false);
                }
            } else {
                setHasValidEmailAddres(false);
                checkEmailAddressDispatch({ type: "FETCH_CLEAR" });
            }
        } else {
            setHasValidEmailAddres(false);
            checkEmailAddressDispatch({ type: "FETCH_CLEAR" });
        }
    }, [methods.errors, emailAddress, emailAddressExists]);

    useEffect(() => {
        // console.log(phoneNumber);
        // console.log(methods.errors.phone);
        // console.log(phoneNumberExists);
        if (isStringNotEmpty(phoneNumber)) {
            if (isObjectEmpty(methods.errors.phone)) {
                if (phoneNumberExists !== null && phoneNumberExists === false) {
                    setHasValidPhoneNumber(true);
                    // console.log('Has a valid phone number');
                } else {
                    setHasValidPhoneNumber(false);
                }
            } else {
                setHasValidPhoneNumber(false);
                checkPhoneNumberDispatch({ type: "FETCH_CLEAR" });
            }
        } else {
            setHasValidPhoneNumber(false);
            checkPhoneNumberDispatch({ type: "FETCH_CLEAR" });
        }
    }, [methods.errors, phoneNumber, phoneNumberExists]);

    const onSubmit = (data) => {
        let cleanedData = removeEmpty(data);

        if (isObjectNotEmpty(cleanedData)) {
            let emailAndOrPhoneAreValid = false;
            if (isStringNotEmpty(cleanedData.email)) {
                if (hasValidEmailAddress === true) {
                    emailAndOrPhoneAreValid = true;
                } else {
                    emailAndOrPhoneAreValid = false;
                }
            }
            if (isStringNotEmpty(cleanedData.phone)) {
                if (hasValidPhoneNumber === true) {
                    emailAndOrPhoneAreValid = true;
                } else {
                    emailAndOrPhoneAreValid = false;
                }
            }

            cleanedData.isVerified = true;

            if (emailAndOrPhoneAreValid === true) {
                dispatch(actionCreators.addAccountUser(cleanedData, accountId));
            }
        } else {
            onCancel();
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.addAccountUserCancel());
        cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.addAccountUserErrorClear());
        dispatch(actionCreators.getAccounts());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    {(accountIdValue === undefined || accountIdValue === null) &&
                        <Fieldset legend="Choose an Account">
                            <FormItem {...formItemLayout} label="Account" required
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please Select an Account"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { handleAccountChange(selected); onChange(selected); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    >
                                        {accounts.map(a => <Option value={a.id} key={a.id}>{a.name}</Option>)}
                                    </Select>
                                )}
                                rules={{ required: 'Required Field' }}
                                name="accountId"
                            />
                        </Fieldset>
                    }
                    <Fieldset legend="Email or Phone Number are Required">
                        <FormItem {...formItemLayout} label="Email Address"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Input
                                    placeholder="Email Address"
                                    addonAfter={isCheckEmailAddressLoading ? <LoadingOutlined /> : <MailOutlined />}
                                    onBlur={onBlur}
                                    onChange={e => { onEmailAddressChange(e.target.value); onChange(e.target.value); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            rules={{
                                required: false,
                                minLength: { value: 4, message: 'Email Address must be at least 4 characters long' },
                                pattern: {
                                    value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                    message: "Please enter a valid email address"
                                }
                            }}
                            name="email"
                        />
                        {checkEmailAddressHasError && <Alert message={checkEmailAddressError} type="error" />}
                        <FormItem {...formItemLayout} label="Personal Phone #"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Input
                                    placeholder="Personal Phone #"
                                    addonAfter={isCheckPhoneNumberLoading ? <LoadingOutlined /> : <PhoneOutlined />}
                                    onBlur={onBlur}
                                    onChange={e => { onPhoneNumberChange(e.target.value); onChange(e.target.value); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            rules={{
                                required: false,
                                pattern: {
                                    value: /^\d{10}$/,
                                    message: "Please enter a valid 10 digit phone number with no special characters"
                                }
                            }}
                            name="phone"
                            defaultValue={''}
                        />
                        {checkPhoneNumberHasError && <Alert message={checkPhoneNumberError} type="error" />}
                    </Fieldset>
                    {(hasValidEmailAddress === true || hasValidPhoneNumber === true) ? (
                        <>
                            <Fieldset legend="Employee Details">
                                <div>User Account will be created with these details if one doesn't already exist.</div>
                                <FormItem {...formItemLayout} label="First Name" required
                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="First Name" ref={ref} />}
                                    rules={{ required: 'Required Field' }}
                                    name="firstName"
                                />
                                <FormItem {...formItemLayout} label="Middle Name"
                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Middle Name" ref={ref} />}
                                    rules={{ required: false }}
                                    name="middleName"
                                />
                                <FormItem {...formItemLayout} label="Last Name" required
                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Last Name" ref={ref} />}
                                    rules={{ required: 'Required Field' }}
                                    name="lastName"
                                />
                                <Divider />
                                <FormItem {...formItemLayout} label="Title" required
                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Title" ref={ref} />}
                                    rules={{ required: 'Required Field' }}
                                    name="title"
                                />
                                <FormItem {...formItemLayout} label="Work Phone #"
                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Work Phone #" ref={ref} />}
                                    rules={{ required: false }}
                                    name="workPhone"
                                />
                                <FormItem {...formItemLayout} label="Is Contractor"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Switch
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            onBlur={onBlur}
                                            onChange={(checked, event) => { onChange(checked); }}
                                            checked={value}
                                            name={name}
                                            ref={ref}
                                        />
                                    )}
                                    name="isContractor"
                                    defaultValue={false}
                                />
                                <FormItem {...formItemLayout} label="Send Invitation (email or text) to Employee"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Switch
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            onBlur={onBlur}
                                            onChange={(checked, event) => { onChange(checked); }}
                                            checked={value}
                                            name={name}
                                            ref={ref}
                                        />
                                    )}
                                    name="isInvited"
                                    defaultValue={true}
                                />
                            </Fieldset>
                            {isObjectNotEmpty(account) ? (
                                <CanSee accountId={account.id} accountRoles={["OPERATIONS_ADMIN", "ADMIN"]}>
                                    <Fieldset legend="Account Role(s)">
                                        <FormItem {...formItemLayout} label="Account Role(s)" format="vertical" required
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Select
                                                    placeholder="Please Select Account Role(s)"
                                                    mode="multiple"
                                                    allowClear={true}
                                                    style={{ width: '100%' }}
                                                    virtual={false}
                                                    onBlur={onBlur}
                                                    onChange={(selected) => { onChange(selected); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                >
                                                    {isBooleanTrue(account.entityTypes.includes("SHIPPER")) ? (
                                                        <OptGroup label="Shipper Account Roles">
                                                            {shipperAccountRoles}
                                                        </OptGroup>
                                                    ) : null}
                                                    {isBooleanTrue(account.entityTypes.includes("CARRIER")) ? (
                                                        <OptGroup label="Carrier Account Roles">
                                                            {carrierAccountRoles}
                                                        </OptGroup>
                                                    ) : null}
                                                    {isBooleanTrue(account.entityTypes.includes("PRODUCER")) ? (
                                                        <OptGroup label="Producer Account Roles">
                                                            {producerAccountRoles}
                                                        </OptGroup>
                                                    ) : null}
                                                    {isBooleanTrue(account.entityTypes.includes("RECEIVER")) ? (
                                                        <OptGroup label="Receiver Account Roles">
                                                            {receiverAccountRoles}
                                                        </OptGroup>
                                                    ) : null}
                                                    {isBooleanTrue(account.entityTypes.includes("BROKER")) ? (
                                                        <OptGroup label="Brokerage Account Roles">
                                                            {brokerAccountRoles}
                                                        </OptGroup>
                                                    ) : null}
                                                    {isBooleanTrue(account.entityTypes.includes("FACTORING")) ? (
                                                        <OptGroup label="Factoring Company Account Roles">
                                                            {factoringAccountRoles}
                                                        </OptGroup>
                                                    ) : null}
                                                </Select>
                                            )}
                                            rules={{ required: 'Required Field' }}
                                            name="accountRoles"
                                        />
                                    </Fieldset>
                                </CanSee>
                            ) : null}
                        </>
                    ) : null}
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} cancelDisabled={(isLoading === true && error === null)} submitDisabled={(isLoading === true && error === null) || (hasValidEmailAddress === false && hasValidPhoneNumber === false)} submitText="Create Employee" />
            </Form>
        </FormProvider>
    );
};

export default NewEmployee;