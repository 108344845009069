import React, { useMemo, useState, useEffect } from 'react';
import { Input, Spin, Alert } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import FormItem from '../FormItem/FormItem';
import FormButtons from '../FormButtons/FormButtons';
import Form from '../Form/Form';
import { isListNotEmpty, isNotNullOrUndefined, isObjectNotEmpty, isStringEmpty, isStringNotEmpty, removeEmpty } from '../../shared/objectUtils';
import Fieldset from '../FormFieldset/FormFieldset';
import FormItemAddress from '../FormItemAddress/FormItemAddress';
import FormItemPointOfContact from '../FormItemPointOfContact/FormItemPointOfContact';
import FormItemDOTNumber from '../FormItemDOTNumber/FormItemDOTNumber';
import FormItemMCNumber from '../FormItemMCNumber/FormItemMCNumber';
import FormItemEIN from '../FormItemEIN/FormItemEIN';
import EntityUtils from '../../api/entityUtils';

const NewLinkedBroker = ({ accountId, addLinkedAccount, cancel }) => {
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.linkedAccounts.isRecordAddLoading);
    const error = useSelector(state => state.linkedAccounts.addRecordError);
    const entityType = useSelector(state => state.auth.entityType);
    const accounts = useSelector(state => state.orchestrator.accounts);

    //#endregion
    //#region useStates

    const [account, setAccount] = useState(null);
    const [linkedAccount, setLinkedAccount] = useState(null);
    const [doesAccountExist, setDoesAccountExist] = useState(false);
    const [accountEIN, setAccountEIN] = useState(null);
    const [accountMCNumber, setAccountMCNumber] = useState(null);
    const [accountDOTNumber, setAccountDOTNumber] = useState(null);
    const [hasValidAccountEIN, setHasValidAccountEIN] = useState(false);
    const [hasValidAccountMCNumber, setHasValidAccountMCNumber] = useState(false);
    const [hasValidAccountDOTNumber, setHasValidAccountDOTNumber] = useState(false);

    //#endregion
    //#region onSubmit and onCancel

    const onSubmit = (data) => {
        // console.log(data);
        if (isStringNotEmpty(accountId)) {
            let payload = removeEmpty(data);

            let einIsValid = false;
            if ((isStringNotEmpty(payload.ein) && hasValidAccountEIN === true) || isStringEmpty(payload.ein)) {
                einIsValid = true;
            }

            let mcNumIsValid = false;
            if ((isStringNotEmpty(payload.mcNum) && hasValidAccountMCNumber === true) || isStringEmpty(payload.mcNum)) {
                mcNumIsValid = true;
            }

            let dotNumIsValid = false;
            if ((isStringNotEmpty(payload.dotNum) && hasValidAccountDOTNumber === true) || isStringEmpty(payload.dotNum)) {
                dotNumIsValid = true;
            }

            payload.accountId = accountId;

            payload.entityTypes = ["BROKER"];

            if ((entityType === "SHIPPER" || entityType === "CARRIER") && doesAccountExist === false) {
                payload.isInvited = true;
            } else {
                payload.inInvited = false;
            }

            if (isObjectNotEmpty(payload.pointOfContact)) {
                payload.accountUser = payload.pointOfContact; // create the default account user for the account
            }

            if (isObjectNotEmpty(linkedAccount)) {
                payload.linkedAccountId = linkedAccount.id;
            }

            if (isObjectNotEmpty(payload) && einIsValid === true && mcNumIsValid === true && dotNumIsValid === true) {
                dispatch(actionCreators.addLinkedAccount(payload));
            }
        } else if (isNotNullOrUndefined(addLinkedAccount)) {
            let payload = removeEmpty(data);

            let einIsValid = false;
            if ((isStringNotEmpty(payload.ein) && hasValidAccountEIN === true) || isStringEmpty(payload.ein)) {
                einIsValid = true;
            }

            let mcNumIsValid = false;
            if ((isStringNotEmpty(payload.mcNum) && hasValidAccountMCNumber === true) || isStringEmpty(payload.mcNum)) {
                mcNumIsValid = true;
            }

            let dotNumIsValid = false;
            if ((isStringNotEmpty(payload.dotNum) && hasValidAccountDOTNumber === true) || isStringEmpty(payload.dotNum)) {
                dotNumIsValid = true;
            }

            payload.entityTypes = ["BROKER"];

            if (doesAccountExist === false) {
                payload.isInvited = true;
            } else {
                payload.inInvited = false;
            }

            if (isObjectNotEmpty(payload.pointOfContact)) {
                payload.accountUser = payload.pointOfContact; // create the default account user for the account
            }

            if (isObjectNotEmpty(linkedAccount)) {
                payload.linkedAccountId = linkedAccount.id;
            }

            if (isObjectNotEmpty(payload) && einIsValid === true && mcNumIsValid === true && dotNumIsValid === true) {
                addLinkedAccount(payload);
            }
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.addLinkedAccountCancel());
        cancel();
    };

    //#endregion
    //#region useEffects and useMemos

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.addLinkedAccountErrorClear());
        dispatch(actionCreators.getAccounts());
    }, []);

    useMemo(() => {
        if (isStringNotEmpty(accountId) && isListNotEmpty(accounts)) {
            let accountObj = EntityUtils.getAccountInfo(accountId, accounts);
            setAccount(accountObj);
        }
    }, [accountId, accounts]);

    useEffect(() => {
        // console.log(`linkedAccount: ${JSON.stringify(linkedAccount)}`);
        // console.log(`hasValidAccountDOTNumber: ${hasValidAccountDOTNumber}`);
        // console.log(`doesAccountExist: ${doesAccountExist}`);
        if (hasValidAccountDOTNumber === true && doesAccountExist === true && isObjectNotEmpty(linkedAccount)) {
            methods.setValue('name', linkedAccount.name);
        }
    }, [linkedAccount, hasValidAccountDOTNumber, doesAccountExist]);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    //#endregion

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Fieldset legend="First Search for the Brokerage by DOT # to see if they Already Exist in the System">
                        <FormItemDOTNumber
                            format="vertical"
                            required={true}
                            name="dotNum"
                            defaultValue={''}
                            accountDOTNumber={accountDOTNumber}
                            setAccountDOTNumber={setAccountDOTNumber}
                            hasValidAccountDOTNumber={hasValidAccountDOTNumber}
                            setHasValidAccountDOTNumber={setHasValidAccountDOTNumber}
                            setAccountExists={setDoesAccountExist}
                            setExistingAccount={setLinkedAccount}
                            account={account}
                            allowExisting={true}
                            allowSelf={false}
                        />
                    </Fieldset>
                    {hasValidAccountDOTNumber === true ? (
                        <>
                            {doesAccountExist === false ? (
                                <Fieldset legend="Brokerage Details">
                                    <FormItemMCNumber
                                        format="vertical"
                                        required={true}
                                        name="mcNum"
                                        defaultValue={''}
                                        accountMCNumber={accountMCNumber}
                                        setAccountMCNumber={setAccountMCNumber}
                                        hasValidAccountMCNumber={hasValidAccountMCNumber}
                                        setHasValidAccountMCNumber={setHasValidAccountMCNumber}
                                    />
                                </Fieldset>
                            ) : null}
                            <Fieldset legend="Company Details">
                                <FormItem {...formItemLayout} label="Company Name" format="vertical"
                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Company Name" ref={ref} />}
                                    rules={{ required: "Required Field" }}
                                    name="name"
                                />
                                <FormItem {...formItemLayout} label="Customer Id" tooltip="Do you have an identifier for this company outside of this system?" format="vertical"
                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Customer Id" ref={ref} />}
                                    rules={{ required: false }}
                                    name="customerId"
                                />
                                {doesAccountExist === false ? (
                                    <FormItemEIN
                                        format="vertical"
                                        required={false}
                                        name="ein"
                                        defaultValue={''}
                                        accountEIN={accountEIN}
                                        setAccountEIN={setAccountEIN}
                                        hasValidAccountEIN={hasValidAccountEIN}
                                        setHasValidAccountEIN={setHasValidAccountEIN}
                                    />
                                ) : null}
                            </Fieldset>
                            <Fieldset legend={`Point of Contact${(entityType === "SHIPPER" || entityType === "CARRIER") && doesAccountExist === false ? " (we will send an invite to the contact listed below so they can setup their account)" : ""}`}>
                                <FormItemPointOfContact
                                    format="penta"
                                    required={(entityType === "SHIPPER" || entityType === "CARRIER") && doesAccountExist === false}
                                    name="pointOfContact"
                                />
                            </Fieldset>
                            <Fieldset isCollapsable isCollapsed legend="Accounts Payable Point of Contact">
                                <FormItemPointOfContact
                                    format="penta"
                                    required={false}
                                    name="accountsPayable"
                                />
                            </Fieldset>
                            <Fieldset isCollapsable isCollapsed legend="Accounts Payable Address">
                                <FormItemAddress
                                    format="vertical"
                                    required={false}
                                    name="accountsPayableAddress"
                                    isPostalAddress={true}
                                />
                            </Fieldset>
                            <Fieldset isCollapsable isCollapsed legend="Accounts Receivable Point of Contact">
                                <FormItemPointOfContact
                                    format="penta"
                                    required={false}
                                    name="accountsReceivable"
                                />
                            </Fieldset>
                            <Fieldset isCollapsable isCollapsed legend="Accounts Receivable Address">
                                <FormItemAddress
                                    format="vertical"
                                    required={false}
                                    name="accountsReceivableAddress"
                                    isPostalAddress={true}
                                />
                            </Fieldset>
                        </>
                    ) : null}
                    {error && <Alert message={`${error}`} type="error" style={{ marginBottom: 8 }} />}
                </Spin>
                <FormButtons cancel={onCancel} submitDisabled={hasValidAccountDOTNumber === false || (isLoading === true && error === null)} disabled={isLoading === true && error === null} submitText="Add Brokerage" />
            </Form>
        </FormProvider>
    );
};

export default NewLinkedBroker;