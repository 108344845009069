import React, { useMemo, useState, useEffect } from 'react';
import { Input, Spin, Alert } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import FormItem from '../FormItem/FormItem';
import FormButtons from '../FormButtons/FormButtons';
import Form from '../Form/Form';
import { isListNotEmpty, isNotNullOrUndefined, isObjectNotEmpty, isStringEmpty, isStringNotEmpty, removeEmpty } from '../../shared/objectUtils';
import Fieldset from '../FormFieldset/FormFieldset';
import FormItemAddress from '../FormItemAddress/FormItemAddress';
import FormItemPointOfContact from '../FormItemPointOfContact/FormItemPointOfContact';
import FormItemEIN from '../FormItemEIN/FormItemEIN';
import EntityUtils from '../../api/entityUtils';

const NewLinkedShipper = ({ accountId, addLinkedAccount, cancel }) => {
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.linkedAccounts.isRecordAddLoading);
    const error = useSelector(state => state.linkedAccounts.addRecordError);
    const entityType = useSelector(state => state.auth.entityType);
    const accounts = useSelector(state => state.orchestrator.accounts);

    //#endregion
    //#region useStates

    const [account, setAccount] = useState(null);
    const [linkedAccount, setLinkedAccount] = useState(null);
    const [doesAccountExist, setDoesAccountExist] = useState(false);
    const [accountEIN, setAccountEIN] = useState(null);
    const [hasValidAccountEIN, setHasValidAccountEIN] = useState(false);

    //#endregion
    //#region onSubmit and onCancel

    const onSubmit = (data) => {
        console.log(data);
        if (isStringNotEmpty(accountId)) {
            let payload = removeEmpty(data);

            let einIsValid = false;
            if ((isStringNotEmpty(payload.ein) && hasValidAccountEIN === true) || isStringEmpty(payload.ein)) {
                einIsValid = true;
            }

            payload.accountId = accountId;

            payload.entityTypes = ["SHIPPER"];

            if (entityType === "SHIPPER" && doesAccountExist === false) {
                payload.isInvited = true;
            } else {
                payload.inInvited = false;
            }

            if (isObjectNotEmpty(payload.pointOfContact)) {
                payload.accountUser = payload.pointOfContact; // create the default account user for the account
            }

            if (isObjectNotEmpty(linkedAccount)) {
                payload.linkedAccountId = linkedAccount.id;
            }

            if (isObjectNotEmpty(payload) && einIsValid === true) {
                dispatch(actionCreators.addLinkedAccount(payload));
            }
        } else if (isNotNullOrUndefined(addLinkedAccount)) {
            let payload = removeEmpty(data);

            let einIsValid = false;
            if ((isStringNotEmpty(payload.ein) && hasValidAccountEIN === true) || isStringEmpty(payload.ein)) {
                einIsValid = true;
            }

            payload.entityTypes = ["SHIPPER"];

            if (doesAccountExist === false) {
                payload.isInvited = true;
            } else {
                payload.inInvited = false;
            }

            if (isObjectNotEmpty(payload.pointOfContact)) {
                payload.accountUser = payload.pointOfContact; // create the default account user for the account
            }

            if (isObjectNotEmpty(linkedAccount)) {
                payload.linkedAccountId = linkedAccount.id;
            }

            if (isObjectNotEmpty(payload) && einIsValid === true) {
                addLinkedAccount(payload);
            }
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.addLinkedAccountCancel());
        cancel();
    };

    //#endregion
    //#region useEffects and useMemos

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.addLinkedAccountErrorClear());
        dispatch(actionCreators.getAccounts());
    }, []);

    useMemo(() => {
        if (isStringNotEmpty(accountId) && isListNotEmpty(accounts)) {
            let accountObj = EntityUtils.getAccountInfo(accountId, accounts);
            setAccount(accountObj);
        }
    }, [accountId, accounts]);

    useEffect(() => {
        // console.log(`linkedAccount: ${JSON.stringify(linkedAccount)}`);
        // console.log(`hasValidAccountEIN: ${hasValidAccountEIN}`);
        // console.log(`doesAccountExist: ${doesAccountExist}`);
        if (hasValidAccountEIN === true && doesAccountExist === true && isObjectNotEmpty(linkedAccount)) {
            methods.setValue('name', linkedAccount.name);
        }
    }, [linkedAccount, hasValidAccountEIN, doesAccountExist]);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    //#endregion

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Fieldset legend="First Search for the Company by EIN to see if they Already Exist in the System">
                        <FormItemEIN
                            format="vertical"
                            required
                            name="ein"
                            defaultValue={''}
                            accountEIN={accountEIN}
                            setAccountEIN={setAccountEIN}
                            hasValidAccountEIN={hasValidAccountEIN}
                            setHasValidAccountEIN={setHasValidAccountEIN}
                            setAccountExists={setDoesAccountExist}
                            setExistingAccount={setLinkedAccount}
                            account={account}
                            allowExisting={true}
                            allowSelf={false}
                        />
                    </Fieldset>
                    {hasValidAccountEIN === true ? (
                        <>
                            <Fieldset legend="Company Details">
                                <FormItem {...formItemLayout} label="Company Name" required format="vertical"
                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Company Name" ref={ref} />}
                                    rules={{ required: "Required Field" }}
                                    name="name"
                                />
                                <FormItem {...formItemLayout} label="Customer Id" tooltip="Do you have an identifier for this company outside of this system?" format="vertical"
                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Customer Id" ref={ref} />}
                                    rules={{ required: false }}
                                    name="customerId"
                                />
                            </Fieldset>
                            <Fieldset legend={`Point of Contact${entityType === "SHIPPER" && doesAccountExist === false ? " (we will send an invite to the contact listed below so they can setup their account)" : ""}`}>
                                <FormItemPointOfContact
                                    format="penta"
                                    required={entityType === "SHIPPER" && doesAccountExist === false}
                                    name="pointOfContact"
                                />
                            </Fieldset>
                            <Fieldset isCollapsable isCollapsed legend="Accounts Payable Point of Contact">
                                <FormItemPointOfContact
                                    format="penta"
                                    required={false}
                                    name="accountsPayable"
                                />
                            </Fieldset>
                            <Fieldset isCollapsable isCollapsed legend="Accounts Payable Address">
                                <FormItemAddress
                                    format="vertical"
                                    required={false}
                                    name="accountsPayableAddress"
                                    isPostalAddress={true}
                                />
                            </Fieldset>
                        </>
                    ) : null}
                    {error && <Alert message={`${error}`} type="error" style={{ marginBottom: 8 }} />}
                </Spin>
                <FormButtons cancel={onCancel} submitDisabled={hasValidAccountEIN === false || (isLoading === true && error === null)} disabled={isLoading === true && error === null} submitText="Add Shipper" />
            </Form>
        </FormProvider>
    );
};

export default NewLinkedShipper;