import { isBoolean, isEmpty } from 'lodash';
import moment from 'moment';

export const removeEmpty = (obj) => {
    Object.keys(obj).forEach(key => {
        if (!moment.isMoment(obj[key])) {
            if (obj[key] && typeof obj[key] === 'object' && Object.keys(obj[key]).length > 0) removeEmpty(obj[key]);
            else if (obj[key] === undefined) delete obj[key];
            else if (obj[key] === "") delete obj[key];

            if (obj[key] && typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0) delete obj[key];
        }
    });

    return obj;
};

export const removeEmptyAndNull = (obj) => {
    Object.keys(obj).forEach(key => {
        if (!moment.isMoment(obj[key])) {
            if (obj[key] && typeof obj[key] === 'object' && Object.keys(obj[key]).length > 0) removeEmptyAndNull(obj[key]);
            else if (obj[key] === undefined) delete obj[key];
            else if (obj[key] === "") delete obj[key];
            else if (obj[key] === null) delete obj[key];

            if (obj[key] && typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0) delete obj[key];
        }
    });

    return obj;
};

export const convertToObject = (data) => {
    let obj = {};
    let t;
    let parts;
    let part;

    for (let k in data) {
        t = obj;
        parts = k.split('.');
        var key = parts.pop();
        while (parts.length) {
            part = parts.shift();
            t = t[part] = t[part] || {};
        }
        t[key] = data[k]
    }

    return obj;
};

export const isEquivalent = (a, b) => {
    // Create arrays of property names
    if (isObjectEmpty(a) && isObjectNotEmpty(b)) {
        return false;
    } else if (isObjectNotEmpty(a) && isObjectEmpty(b)) {
        return false;
    } else if (isObjectEmpty(a) && isObjectEmpty(b)) {
        return true;
    } else {
        var aProps = Object.getOwnPropertyNames(a);
        var bProps = Object.getOwnPropertyNames(b);

        // console.log(aProps);
        // console.log(bProps);
        // If number of properties is different,
        // objects are not equivalent
        if (aProps.length != bProps.length) {
            return false;
        }

        for (var i = 0; i < aProps.length; i++) {
            var propName = aProps[i];

            // console.log(a[propName]);
            // console.log(b[propName]);
            // If values of same property are not equal,
            // objects are not equivalent
            if (a[propName] !== b[propName]) {
                return false;
            }
        }

        // If we made it this far, objects
        // are considered equivalent
        return true;
    }
};

export const isStringEmpty = (value) => {
    if (value === undefined || value === null || value === '') {
        return true;
    }

    return false;
};

export const isStringNotEmpty = (value) => {
    if (value !== undefined && value !== null && value !== '') {
        return true;
    }

    return false;
};

export const isListEmpty = (list) => {
    if (list === undefined || list === null || (list !== undefined && list !== null && list.length === 0)) {
        return true;
    }

    return false;
};

export const isListNotEmpty = (list) => {
    if (list !== undefined && list !== null && list.length > 0) {
        return true;
    }

    return false;
};

export const isObjectEmpty = (obj) => {
    if (obj === undefined || obj === null || (obj !== undefined && obj !== null && isEmpty(obj))) {
        return true;
    }

    return false;
};

export const isObjectNotEmpty = (obj) => {
    if (obj !== undefined && obj !== null && !isEmpty(obj)) {
        return true;
    }

    return false;
};

export const isNotNullOrUndefined = (obj) => {
    if (obj !== undefined && obj !== null) {
        return true;
    }

    return false;
};

export const isNullOrUndefined = (obj) => {
    if (obj === undefined || obj === null) {
        return true;
    }

    return false;
};

export const isMomentDate = (value) => {
    if (value !== undefined && value !== null && moment.isMoment(value)) {
        return true;
    }

    return false;
};

export const isNumberNotEmpty = (value) => {
    if (value !== undefined && value !== null) {
        return true;
    }

    return false;
};

export const isNumberEmpty = (value) => {
    if (value !== undefined && value !== null) {
        return false;
    }

    return true;
};

export const isNumberEmptyOrZero = (value) => {
    if (value === undefined || value === null || value === 0) {
        return true;
    }

    return false;
};

export const isNumberGreaterThanZero = (value) => {
    if (value !== undefined && value !== null && value > 0) {
        return true;
    }

    return false;
};

export const isNumberLessThanOrEqualToZero = (value) => {
    if (value !== undefined && value !== null && value <= 0) {
        return true;
    }

    return false;
};

export const isBooleanTrue = (value) => {
    if (value !== undefined && value !== null && isBoolean(value) && value === true) {
        return true;
    }

    return false;
};

export const isBooleanFalse = (value) => {
    if (value !== undefined && value !== null && isBoolean(value) && value === false) {
        return true;
    }

    return false;
};

export const convertStringToFloatOrZero = (value) => {
    try {
        if (isNumberNotEmpty(value)) {
            return isNaN(parseFloat(value)) ? 0 : parseFloat(value);
        }
    } catch (error) {
        // console.log(error.message);
    }

    return 0;
};

export const convertStringToIntOrZero = (value) => {
    try {
        if (isNumberNotEmpty(value)) {
            return isNaN(parseInt(value)) ? 0 : parseInt(value);
        }
    } catch (error) {
        // console.log(error.message);
    }

    return 0;
};

export const convertStringToObject = (value) => {
    try {
        if (isStringNotEmpty(value)) {
            return JSON.parse(value);
        }
    } catch (error) {
        // console.log(error.message);
    }

    return null;
};

export const equals = (a, b) => {
    return a.length === b.length && a.every((v, i) => v === b[i]);
};

export const equalsIgnoreOrder = (a, b) => {
    if (a.length !== b.length) return false;
    const uniqueValues = new Set([...a, ...b]);
    for (const v of uniqueValues) {
        const aCount = a.filter(e => e === v).length;
        const bCount = b.filter(e => e === v).length;
        if (aCount !== bCount) return false;
    }

    return true;
};