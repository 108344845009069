import { isStringNotEmpty } from '../../shared/objectUtils';
import EntityUtils from '../entityUtils';

export const getLoadCancellationDTO = (loadCancellation, accounts = [], systemUsers = []) => {
    if (isStringNotEmpty(loadCancellation.entityType) && isStringNotEmpty(loadCancellation.entityId) && loadCancellation.entityType === 'SHIPPER' || loadCancellation.entityType === 'CARRIER' || loadCancellation.entityType === 'BROKER') {
        loadCancellation.cancelledByAccount = EntityUtils.getAccountInfo(loadCancellation.entityId, accounts);
    }

    loadCancellation.cancelledByUser = EntityUtils.getUserProfileInfo(loadCancellation.createdBy, systemUsers);

    return loadCancellation;
};