import * as actionTypes from "../actions/actionTypes";
import haversine from 'haversine';
import LocalStorage from '../../shared/localStorageUtils';
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import * as actionCreators from "./index";
import { convertStringToFloatOrZero, convertStringToIntOrZero, convertStringToObject, isListNotEmpty, isNumberNotEmpty, isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import ErrorUtils from "../../shared/errorUtils";

const HAVERSINE_UNIT = 'mile';

//#region Active Load Functions

export const fetchActiveLoadStart = () => {
    return {
        type: actionTypes.FETCH_ACTIVE_LOAD_START
    }
};

export const fetchActiveLoadSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_ACTIVE_LOAD_SUCCESS,
        payload: payload
    }
};

export const fetchActiveLoadFail = (payload) => {
    return {
        type: actionTypes.FETCH_ACTIVE_LOAD_FAIL,
        payload: payload
    }
};

export const fetchActiveLoadStopsStart = () => {
    return {
        type: actionTypes.FETCH_ACTIVE_LOAD_STOPS_START
    }
};

export const fetchActiveLoadStopsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_ACTIVE_LOAD_STOPS_SUCCESS,
        payload: payload
    }
};

export const fetchActiveLoadStopsFail = (payload) => {
    return {
        type: actionTypes.FETCH_ACTIVE_LOAD_STOPS_FAIL,
        payload: payload
    }
};

export const fetchActiveLoadDocumentsStart = () => {
    return {
        type: actionTypes.FETCH_ACTIVE_LOAD_DOCUMENTS_START
    }
};

export const fetchActiveLoadDocumentsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_ACTIVE_LOAD_DOCUMENTS_SUCCESS,
        payload: payload
    }
};

export const fetchActiveLoadDocumentsFail = (payload) => {
    return {
        type: actionTypes.FETCH_ACTIVE_LOAD_DOCUMENTS_FAIL,
        payload: payload
    }
};

export const updateActiveLoad = (payload) => {
    return {
        type: actionTypes.UPDATE_ACTIVE_LOAD,
        payload: payload
    }
};

export const clearActiveLoad = () => {
    return {
        type: actionTypes.CLEAR_ACTIVE_LOAD
    }
};

//#endregion
//#region Active Load Methods

export const updateActiveLoadDistance = (prevLatLng, newLatLng) => {
    return async (dispatch) => {
        try {
            if (isNumberNotEmpty(prevLatLng.latitude) && isNumberNotEmpty(prevLatLng.longitude) && isNumberNotEmpty(newLatLng.latitude) && isNumberNotEmpty(newLatLng.longitude)) {
                if (prevLatLng.latitude !== newLatLng.latitude || prevLatLng.longitude !== newLatLng.longitude) {
                    const distance = haversine(prevLatLng, newLatLng, { unit: HAVERSINE_UNIT }) || 0;
                    const prevDistanceTravelledVal = await LocalStorage.getItem('distanceTravelled');
                    let prevDistanceTravelled = convertStringToFloatOrZero(prevDistanceTravelledVal);

                    const newDistanceTravelled = prevDistanceTravelled + distance;

                    await LocalStorage.setItem('distanceTravelled', newDistanceTravelled.toString());

                    logger.logDebugEvent('distance_travelled', JSON.stringify({
                        previousCoordinate: prevLatLng,
                        currentCoordinate: newLatLng,
                        distanceTravelled: newDistanceTravelled,
                        distanceTravelledUnit: HAVERSINE_UNIT
                    }), true);
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
        }
    }
};

export const updateActiveLoadLocation = (newLatLng) => {
    return async (dispatch) => {
        try {
            const sendToTMSValue = await LocalStorage.getItem('sendToTMS');
            let sendToTMSCount = convertStringToIntOrZero(sendToTMSValue);

            const loadId = await LocalStorage.getItem('activeLoadId');
            const loadIrisId = await LocalStorage.getItem('activeLoadIrisId');
            const notMovedCountValue = await LocalStorage.getItem('notMovedCount');
            let notMovedCount = convertStringToIntOrZero(notMovedCountValue);

            const previousLocation = await LocalStorage.getItem('currentLocation');
            let activeLoadPrevLatLng = convertStringToObject(previousLocation);

            let prevLatLng = null;
            if (isObjectNotEmpty(activeLoadPrevLatLng)) {
                prevLatLng = { ...activeLoadPrevLatLng };
            } else {
                prevLatLng = newLatLng;
            }

            logger.logDebugEvent('new_location', `New Location being sent to api: ${JSON.stringify({
                previousLocation: prevLatLng,
                currentLocation: newLatLng,
                sendToTMS: sendToTMSCount,
                notMovedCount: notMovedCount,
                loadId: loadId ? loadId : '',
                loadIrisId: loadIrisId ? loadIrisId : ''
            })}`, true);

            if (isStringNotEmpty(loadId) && isStringNotEmpty(loadIrisId) && isNumberNotEmpty(prevLatLng.latitude) && isNumberNotEmpty(prevLatLng.longitude) && isNumberNotEmpty(newLatLng.latitude) && isNumberNotEmpty(newLatLng.longitude)) {
                // check to make sure the truck moved
                if (prevLatLng.latitude !== newLatLng.latitude || prevLatLng.longitude !== newLatLng.longitude || notMovedCount === 30) {
                    const isNewLoadLocationAdded = await Data.addLoadLocation(loadId, loadIrisId, newLatLng.latitude, newLatLng.longitude, ((sendToTMSCount % 10 === 0 || (notMovedCount % 30 === 0 && notMovedCount !== 0)) ? true : false));
                    if (isNewLoadLocationAdded === true) {
                        await LocalStorage.setItem('currentLocation', JSON.stringify(newLatLng));
                        await LocalStorage.setItem('sendToTMS', (sendToTMSCount + 1).toString());
                        await LocalStorage.setItem('notMovedCount', (0).toString());
                    }
                } else {
                    // increment not moved count
                    await LocalStorage.setItem('currentLocation', JSON.stringify(newLatLng));
                    await LocalStorage.setItem('notMovedCount', (notMovedCount + 1).toString());
                }

                dispatch(updateActiveLoadDistance(prevLatLng, newLatLng));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
        }
    }
};

export const updateActiveLoadArriving = (region) => {
    return async (dispatch) => {
        try {
            const stopId = region.identifier;
            const currentStopId = await LocalStorage.getItem('currentStopId');
            const currentStopStatus = await LocalStorage.getItem('currentStopStatus');
            const activeLoadStatus = await LocalStorage.getItem('activeLoadStatus');
            const activeLoadId = await LocalStorage.getItem('activeLoadId');

            logger.logDebugEvent('updateActiveLoadArriving', `Geofence details: ${JSON.stringify({
                stopId: stopId,
                currentStopId: currentStopId,
                currentStopStatus: currentStopStatus,
                activeLoadStatus: activeLoadStatus,
                activeLoadId: activeLoadId
            })}`, true);

            // only trigger if the load is in the IN_TRANSIT status and stop status in in the PENDING status
            if (isStringNotEmpty(activeLoadId) && isStringNotEmpty(activeLoadStatus) && activeLoadStatus === "IN_TRANSIT" && isStringNotEmpty(stopId) && isStringNotEmpty(currentStopId) && currentStopId === stopId && isStringNotEmpty(currentStopStatus) && currentStopStatus === "PENDING") {
                // trigger load event for arriving based on stopId which is the identifier for region
                logger.logDebugEvent('updateActiveLoadArriving', `You entered region: ${JSON.stringify(region)}`, true);

                const loadEventUpdate = await Data.addLoadEvent({ loadId: activeLoadId, stopId: stopId, eventType: 'LOAD_ARRIVING' });

                if (isObjectNotEmpty(loadEventUpdate)) {
                    await LocalStorage.setItem('currentStopStatus', 'ARRIVING');

                    logger.logDebugEvent('updateActiveLoadArriving', `Fired off Load Arriving Event: ${JSON.stringify(loadEventUpdate)}`, true);
                }
            }
        } catch (error) {
            logger.logErrorEvent('updateActiveLoadArriving', error, error.message, true);
        }
    }
};

export const fetchActiveLoad = (driverId) => {
    return async (dispatch) => {
        try {
            dispatch(fetchActiveLoadStart());

            const activeLoadId = await Data.getActiveLoadId(driverId);
            if (isStringNotEmpty(activeLoadId)) {
                // const activeLoad = await Data.getLoadWithIncludes(activeLoadId, true);
                const activeLoad = await Data.getLoadWithIncludes(activeLoadId, false);

                if (isObjectNotEmpty(activeLoad)) {
                    const activeLoadStops = await Data.getLoadStops(activeLoadId, activeLoad);

                    // set the currentStop and stopStatus values
                    if (isListNotEmpty(activeLoadStops)) {
                        let currentStop = activeLoadStops.find(stop => stop.stopStatus !== 'COMPLETED');
                        if (isObjectNotEmpty(currentStop)) {
                            await LocalStorage.setItem('currentStopId', currentStop.id);
                            await LocalStorage.setItem('currentStopStatus', currentStop.stopStatus);
                        } else {
                            await LocalStorage.removeItem('currentStopId');
                            await LocalStorage.removeItem('currentStopStatus');
                        }
                    } else {
                        await LocalStorage.removeItem('currentStopId');
                        await LocalStorage.removeItem('currentStopStatus');
                    }

                    // check if there is an active load and location or geofencing haven't been started
                    let isLocationTurnedOn = false;
                    let isGeoFencingTurnedOn = false;
                    if (isStringNotEmpty(activeLoad.loadStatus) && (activeLoad.loadStatus === "IN_TRANSIT" || activeLoad.loadStatus === "AT_STOP")) {
                        isLocationTurnedOn = true;
                        isGeoFencingTurnedOn = true;
                    }

                    await LocalStorage.setItem('activeLoadId', activeLoad.id);
                    await LocalStorage.setItem('activeLoadIrisId', activeLoad.irisId);
                    await LocalStorage.setItem('activeLoadStatus', activeLoad.loadStatus);

                    dispatch(fetchActiveLoadSuccess({
                        activeLoad: activeLoad,
                        activeLoadId: activeLoadId,
                        activeLoadIrisId: activeLoad.irisId,
                        activeLoadStops: activeLoadStops,
                        isLocationTurnedOn: isLocationTurnedOn,
                        isGeoFencingTurnedOn: isGeoFencingTurnedOn,
                        isLoading: false,
                        error: null
                    }));
                    dispatch(actionCreators.updateLoadEventStatus({ loadEventUpdateStatus: 'COMPLETED' }));
                }
            } else {
                await LocalStorage.removeItem('activeLoadId');
                await LocalStorage.removeItem('activeLoadIrisId');
                await LocalStorage.removeItem('currentLocation');
                await LocalStorage.removeItem('sendToTMS');
                await LocalStorage.removeItem('notMovedCount');
                await LocalStorage.removeItem('distanceTravelled');
                await LocalStorage.removeItem('activeLoadStatus');
                await LocalStorage.removeItem('currentStopId');
                await LocalStorage.removeItem('currentStopStatus');

                dispatch(fetchActiveLoadSuccess({
                    activeLoad: null,
                    activeLoadId: null,
                    activeLoadIrisId: null,
                    activeLoadStops: [],
                    activeLoadDocuments: [],
                    isLocationTurnedOn: false,
                    isGeoFencingTurnedOn: false,
                    isLoading: false,
                    error: null
                }));
                dispatch(actionCreators.updateLoadEventStatus({ loadEventUpdateStatus: 'COMPLETED' }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchActiveLoadFail({ error: ErrorUtils.getErrorMessage(error), isLoading: false }));
        }
    }
};

export const fetchActiveLoadStops = (loadId) => {
    return async (dispatch) => {
        try {
            dispatch(fetchActiveLoadStopsStart());

            // set the currentStopId and currentStopStatus values
            const loadStops = await Data.getLoadStops(loadId, null);
            if (isListNotEmpty(loadStops)) {
                let currentStop = loadStops.find(stop => stop.stopStatus !== 'COMPLETED');
                if (isObjectNotEmpty(currentStop)) {
                    await LocalStorage.setItem('currentStopId', currentStop.id);
                    await LocalStorage.setItem('currentStopStatus', currentStop.stopStatus);
                } else {
                    await LocalStorage.removeItem('currentStopId');
                    await LocalStorage.removeItem('currentStopStatus');
                }
            } else {
                await LocalStorage.removeItem('currentStopId');
                await LocalStorage.removeItem('currentStopStatus');
            }

            dispatch(fetchActiveLoadStopsSuccess({ activeLoadStops: loadStops }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchActiveLoadStopsFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const fetchActiveLoadDocuments = () => {
    return async (dispatch, getState) => {
        try {
            dispatch(fetchActiveLoadDocumentsStart());

            const state = getState();
            const activeLoadState = { ...state.activeLoad };
            const activeLoad = isObjectNotEmpty(activeLoadState.activeLoad) ? { ...activeLoadState.activeLoad } : null;
            const activeLoadStops = isListNotEmpty(activeLoadState.activeLoadStops) ? [...activeLoadState.activeLoadStops] : [];
            if (isObjectNotEmpty(activeLoad) && isListNotEmpty(activeLoadStops)) {
                let ids = [];
                ids.push(activeLoad.id);
                activeLoadStops.forEach(stop => {
                    ids.push(stop.id);
                });

                const documents = await Data.getDocumentsByEntityIds(ids);

                dispatch(fetchActiveLoadDocumentsSuccess({ activeLoadDocuments: documents }));
            } else {
                dispatch(fetchActiveLoadDocumentsSuccess({ isActiveLoadDocumentsLoading: false }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchActiveLoadDocumentsFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateCurrentStop = () => {
    return async (dispatch, getState) => {
        try {
            const state = getState();
            const activeLoadState = { ...state.activeLoad };
            const activeLoadStops = isListNotEmpty(activeLoadState.activeLoadStops) ? [...activeLoadState.activeLoadStops] : [];

            if (isListNotEmpty(activeLoadStops)) {
                let currentStop = activeLoadStops.find(stop => stop.stopStatus !== 'COMPLETED');
                if (isObjectNotEmpty(currentStop)) {
                    await LocalStorage.setItem('currentStopId', currentStop.id);
                    await LocalStorage.setItem('currentStopStatus', currentStop.stopStatus);
                } else {
                    await LocalStorage.removeItem('currentStopId');
                    await LocalStorage.removeItem('currentStopStatus');
                }
            } else {
                await LocalStorage.removeItem('currentStopId');
                await LocalStorage.removeItem('currentStopStatus');
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchActiveLoadStopsFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateActiveLoadStatusSuccess = (loadId, stopId, loadStatus, stopStatus) => {
    return async (dispatch, getState) => {
        try {
            const state = getState();
            const activeLoadState = { ...state.activeLoad };

            if (isObjectNotEmpty(activeLoadState.activeLoad)) {
                // check if the load that is in the active load state matches the loadId and if so, update the status of it
                const activeLoad = { ...activeLoadState.activeLoad };
                const activeLoadStops = isListNotEmpty(activeLoadState.activeLoadStops) ? [...activeLoadState.activeLoadStops] : [];
                if (isStringNotEmpty(activeLoad.id) && isStringNotEmpty(loadId) && activeLoad.id === loadId) {
                    if (isStringNotEmpty(loadStatus) && isStringNotEmpty(stopStatus) && isStringNotEmpty(stopId) && isListNotEmpty(activeLoadStops)) {
                        const stopToUpdateIndex = activeLoadStops.findIndex(stop => { return stop.id === stopId });
                        if (stopToUpdateIndex !== -1) {
                            let updatedStop = { ...activeLoadStops[stopToUpdateIndex], stopStatus: stopStatus };
                            const updatedStops = [
                                ...activeLoadStops.slice(0, stopToUpdateIndex), // everything before current obj
                                updatedStop,
                                ...activeLoadStops.slice(stopToUpdateIndex + 1), // everything after current obj
                            ];

                            let activeLoadToUpdate = { ...activeLoad, loadStatus: loadStatus };

                            await LocalStorage.setItem('activeLoadStatus', loadStatus);
                            await LocalStorage.setItem('currentStopId', stopId);
                            await LocalStorage.setItem('currentStopStatus', stopStatus);

                            dispatch(fetchActiveLoadSuccess({ activeLoad: activeLoadToUpdate, activeLoadStops: updatedStops }));
                            dispatch(actionCreators.updateLoadEventStatus({ loadEventUpdateStatus: 'COMPLETED' }));
                        } else {
                            // can't find the stop so just update the load status - this should never happen but it's a fail safe
                            let activeLoadToUpdate = { ...activeLoad, loadStatus: loadStatus };

                            await LocalStorage.setItem('activeLoadStatus', loadStatus);

                            dispatch(fetchActiveLoadSuccess({ activeLoad: activeLoadToUpdate }));
                            dispatch(actionCreators.updateLoadEventStatus({ loadEventUpdateStatus: 'COMPLETED' }));
                        }
                    } else if (isStringNotEmpty(loadStatus)) {
                        // no stop status to update so just update the load status
                        let activeLoadToUpdate = { ...activeLoad, loadStatus: loadStatus };

                        await LocalStorage.setItem('activeLoadStatus', loadStatus);

                        dispatch(fetchActiveLoadSuccess({ activeLoad: activeLoadToUpdate }));
                        dispatch(actionCreators.updateLoadEventStatus({ loadEventUpdateStatus: 'COMPLETED' }));
                    } else if (isStringNotEmpty(stopStatus) && isStringNotEmpty(stopId) && isListNotEmpty(activeLoadStops)) {
                        const stopToUpdateIndex = activeLoadStops.findIndex(stop => { return stop.id === stopId });
                        if (stopToUpdateIndex !== -1) {
                            let updatedStop = { ...activeLoadStops[stopToUpdateIndex], stopStatus: stopStatus };
                            const updatedStops = [
                                ...activeLoadStops.slice(0, stopToUpdateIndex), // everything before current obj
                                updatedStop,
                                ...activeLoadStops.slice(stopToUpdateIndex + 1), // everything after current obj
                            ];

                            await LocalStorage.setItem('currentStopId', stopId);
                            await LocalStorage.setItem('currentStopStatus', stopStatus);

                            // update just the stop status and not the load status
                            dispatch(fetchActiveLoadSuccess({ activeLoadStops: updatedStops }));
                            dispatch(actionCreators.updateLoadEventStatus({ loadEventUpdateStatus: 'COMPLETED' }));
                        }
                    }
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
        }
    }
};

//#endregion