import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Select } from 'antd';

const { Option } = Select;

class TimePicker extends React.Component {

    isEarlierThanEndLimit(timeValue, endLimit, lastValue) {
        let timeValueIsEarlier = moment(timeValue, 'HH:mm').diff(moment(endLimit, 'HH:mm')) < 0;
        let timeValueIsLaterThanLastValue = lastValue === undefined ? true : moment(lastValue, 'HH:mm').diff(moment(timeValue, 'HH:mm')) < 0;
        //console.log(timeValue, endLimit, lastValue, timeValueIsEarlier, timeValueIsLaterThanLastValue);
        return (timeValueIsEarlier === true && timeValueIsLaterThanLastValue === true);
    }

    render() {
        let timeValue = this.props.beginLimit || "00:00";
        let lastValue;
        let endLimit = this.props.endLimit || "23:59";
        let step = this.props.step || 15;

        let options = [];

        while (this.isEarlierThanEndLimit(timeValue, endLimit, lastValue)) {
            lastValue = timeValue;
            //console.log(timeValue, moment(timeValue, 'HH:mm').diff(moment(endLimit, 'HH:mm'), 'minutes'));
            options.push(<Option key={timeValue} value={timeValue}>{timeValue}</Option>);
            timeValue = moment(timeValue, 'HH:mm').add(step, 'minutes').format('HH:mm');
        }

        return (
            <Select {...this.props} virtual={false}>
                {options}
            </Select>
        )
    }
};

TimePicker.propTypes = {
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    name: PropTypes.string,
    beginLimit: PropTypes.string,
    endLimit: PropTypes.string,
    step: PropTypes.number,
    style: PropTypes.object
};

export default TimePicker;