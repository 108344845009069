import React, { useEffect, useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Input, Upload, Alert, Select } from "antd";
import DatePicker from '../DatePickerAutoAccept/DatePickerAutoAccept';
import uuid from 'react-uuid';
import { Controller, useFormContext } from 'react-hook-form';
import Enums from '../../shared/enums';
import { useSelector } from 'react-redux';
import { getDocumentByDocumentIdWithLink, getImageByDocumentId } from '../../api/data';
import { isNotNullOrUndefined, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import logger from '../../shared/logger';

const UploadFile = ({ documentEntityType, account, onRemove, beforeUpload, multiple = false, setFileList, name, fileId, fileList, documentTypeInputName, displayNameInputName, displayName, descriptionInputName, expirationDateInputName, message }) => {
    const methods = useFormContext();

    const userEntityType = useSelector(state => state.auth.entityType);

    // Limit only to one file
    const [localFileList, setLocalFileList] = useState([]);

    // Only if there is already a file
    const [descriptionValue, setDescriptionValue] = useState(null);
    const [displayNameValue, setDisplayNameValue] = useState(null);

    const getDocumentTypes = () => {
        let children = [];
        if (documentEntityType) {
            if (documentEntityType === 'STOP') {
                children = Enums.StopDocumentTypes.selectListOptions();
            } else if (documentEntityType === 'DRIVER') {
                children = Enums.DriverDocumentTypes.selectListOptions();
            } else if (documentEntityType === 'ACCOUNT_SHIPPER') {
                children = Enums.ShipperDocumentTypes.selectListOptions();
            } else if (documentEntityType === 'ACCOUNT_CARRIER') {
                children = Enums.CarrierDocumentTypes.selectListOptions();
            } else if (documentEntityType === 'ACCOUNT_RECEIVER') {
                children = Enums.ReceiverDocumentTypes.selectListOptions();
            } else if (documentEntityType === 'ACCOUNT_PRODUCER') {
                children = Enums.ProducerDocumentTypes.selectListOptions();
            } else if (documentEntityType === 'ACCOUNT_BROKER') {
                children = Enums.BrokerDocumentTypes.selectListOptions();
            } else if (documentEntityType === 'ACCOUNT_FACTORING') {
                children = Enums.FactoringDocumentTypes.selectListOptions();
            } else if (documentEntityType === 'ACCOUNT') {
                if (isObjectNotEmpty(account)) {
                    let accountChildren = [];
                    if (account.entityTypes.includes("SHIPPER") === true) {
                        accountChildren.push(Enums.ShipperDocumentTypes.selectListOptions());
                    }
                    if (account.entityTypes.includes("CARRIER") === true) {
                        accountChildren.push(Enums.CarrierDocumentTypes.selectListOptions());
                    }
                    if (account.entityTypes.includes("PRODUCER") === true) {
                        accountChildren.push(Enums.ProducerDocumentTypes.selectListOptions());
                    }
                    if (account.entityTypes.includes("RECEIVER") === true) {
                        accountChildren.push(Enums.ReceiverDocumentTypes.selectListOptions());
                    }
                    if (account.entityTypes.includes("BROKER") === true) {
                        accountChildren.push(Enums.BrokerDocumentTypes.selectListOptions());
                    }
                    if (account.entityTypes.includes("FACTORING") === true) {
                        accountChildren.push(Enums.FactoringDocumentTypes.selectListOptions());
                    }
                    children = [... new Set(accountChildren)];
                } else {
                    children = Enums.AccountDocumentTypes.selectListOptions();
                }
            } else if (documentEntityType === 'LOAD') {
                children = userEntityType === "STAFF" || userEntityType === "BROKER" ? Enums.LoadDocumentTypes.selectListOptions() : Enums.LoadDocumentTypes.selectListOptionsWithExclusions(["PRICE_CONFIRMATION", "RATE_CONFIRMATION", "PRICE_CONFIRMATION_BLANK", "RATE_CONFIRMATION_BLANK", "SHIPPER_INVOICE", "CARRIER_INVOICE", "FACTORING_INVOICE"]);
            } else if (documentEntityType === 'CLAIM') {
                children = Enums.ClaimDocumentTypes.selectListOptions();
            } else if (documentEntityType === 'ASSET') {
                children = Enums.AssetDocumentTypes.selectListOptions();
            } else if (documentEntityType === 'USER') {
                children = Enums.UserDocumentTypes.selectListOptions();
            } else if (documentEntityType === 'TRANSPORT_UNIT') {
                children = Enums.OtherDocumentTypes.selectListOptions();
            } else if (documentEntityType === 'LOAD_CANCEL') {
                children = Enums.OtherDocumentTypes.selectListOptions();
            } else if (documentEntityType === 'MESSAGE') {
                children = Enums.OtherDocumentTypes.selectListOptions();
            } else {
                children = Enums.OtherDocumentTypes.selectListOptions();
            }
        }

        return children;
    };

    const onDocumentTypeChange = (value) => {
        if (isStringNotEmpty(value)) {
            let displayName = Enums.DocumentDisplayNames.getValueByName(value);
            if (isStringNotEmpty(displayName)) {
                methods.setValue(displayNameInputName, displayName);
            } else {
                methods.setValue(displayNameInputName, null);
            }
        } else {
            methods.setValue(displayNameInputName, null);
        }
    };

    const handleChange = (info) => {
        let fileListVal = [...info.fileList];
        if (multiple === false) {
            // 1. Limit the number of uploaded files
            // Only to show two recent uploaded files, and old ones will be replaced by the new
            fileListVal = fileListVal.slice(-1);
        }

        // console.log(info);

        // 2. Read from response and show file link
        fileListVal = fileListVal.map(file => {
            if (file.response) {
                // Component will show file.url as link
                file.url = file.response.url;
            }
            return file;
        });

        // console.log('handleChange fileList')
        // console.log(fileList)

        methods.setValue(name ? name : 'file', info.file);

        setLocalFileList(fileListVal);

        if (isNotNullOrUndefined(setFileList)) {
            setFileList(fileListVal);
        }
    };

    //Display current file if one is present
    useEffect(() => {
        if (fileId) {
            // Get File
            getImageByDocumentId(fileId).then(data => {
                const blob = new Blob([data], {
                    type: 'image/png',
                });
                // Get File Metadata
                getDocumentByDocumentIdWithLink(fileId).then(fileMetaData => {
                    if (isObjectNotEmpty(fileMetaData)) {
                        if (blob) {
                            const file = new File([blob], fileMetaData.fileName, { type: "image/png" });
                            // Add the file to the File List, this will allow the file to be displayed in the component
                            let fileListVal = [{
                                originFileObj: file,
                                uid: uuid(),
                                type: "image/png",
                                name: fileMetaData.fileName,
                                size: fileMetaData.fileSize,
                                percent: 0
                            }];

                            methods.setValue(name ? name : 'file', file);

                            // 2. Read from response and show file link
                            fileListVal = fileListVal.map(file => {
                                if (file.response) {
                                    // Component will show file.url as link
                                    file.url = file.response.url;
                                }
                                return file;
                            });
                            setDescriptionValue(fileMetaData.description);
                            setDisplayNameValue(fileMetaData.displayName);
                            setLocalFileList(fileListVal);
                        }
                    }
                }).catch(err => {
                    logger.logDebugEvent('UploadFile.js', err.message, true);
                });
            }).catch(err => {
                logger.logDebugEvent('UploadFile.js', err.message, true);
            });
        }
    }, [fileId]);

    useEffect(() => {
        if (isNotNullOrUndefined(fileList)) {
            // console.log('fileList');
            // console.log(fileList);
            setLocalFileList(fileList);
        }
    }, [fileList]);

    const uploadProps = {
        name: name ? name : "file",
        accept: ".png,.jpg,.jpeg,.pdf",
        multiple: multiple,
        actions: "image/png,image/jpg,image/jpeg,application/pdf",
        showUploadList: {
            showPreviewIcon: false,
            showRemoveIcon: true,
            showDownloadIcon: false
        },
        listType: 'picture',
        onChange: handleChange,
        onRemove: onRemove,
        fileList: localFileList,
        beforeUpload: beforeUpload,
        // Disables auto sending to the server
        customRequest: ({ onSuccess, onError, file }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        }
    };

    return (
        <div style={{ display: 'block' }}>
            {documentTypeInputName ? (
                <>
                    <Controller control={methods.control}
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Select
                                placeholder="Please Select a Document Type"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onDocumentTypeChange(selected); onChange(selected); }}
                                value={value}
                                name={name}
                                ref={ref}
                            >
                                {getDocumentTypes()}
                            </Select>
                        )}
                        rules={{ required: 'Required Field' }}
                        name={documentTypeInputName}
                    />
                    {methods.errors[documentTypeInputName] && (<Alert message={methods.errors[documentTypeInputName].message} type="error" />)}
                </>
            ) : null}
            {displayNameInputName ? (
                <Controller control={methods.control}
                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="File Display Name" ref={ref} />}
                    rules={{ required: false }}
                    name={displayNameInputName}
                    defaultValue={displayName ? displayName : (fileId && displayNameValue ? displayNameValue : null)}
                />
            ) : null}
            {descriptionInputName ? (
                <Controller control={methods.control}
                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="File Description" ref={ref} />}
                    rules={{ required: false }}
                    name={descriptionInputName}
                    defaultValue={fileId && descriptionValue ? descriptionValue : null}
                />
            ) : null}
            {expirationDateInputName ? (
                <>
                    <Controller control={methods.control}
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <DatePicker
                                placeholder="Expiration Date"
                                style={{ width: '100%' }}
                                onBlur={onBlur}
                                onChange={(date, dateString) => { onChange(date); }}
                                value={value}
                                name={name}
                                onSelect={(date) => { onChange(date); }}
                                ref={ref}
                            />
                        )}
                        rules={{ required: 'Required Field' }}
                        name={expirationDateInputName}
                    />
                    {methods.errors[expirationDateInputName] && (<Alert message={methods.errors[expirationDateInputName].message} type="error" />)}
                </>
            ) : null}
            <Upload.Dragger {...uploadProps}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload.</p>
                <p className="ant-upload-hint">Only .png, .jpg, .jpeg, or .pdf files.</p>
                <p className="ant-upload-hint">{message}</p>
            </Upload.Dragger>
        </div>
    );
};

export default UploadFile;