import React, { useState, useMemo } from 'react';
import { Spin, Button, Select, Empty, Drawer, Checkbox, message, Alert } from "antd";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import moment from 'moment';
import { FormProvider, useForm } from 'react-hook-form';
import Form from '../../components/Form/Form';
import FormItem from '../../components/FormItem/FormItem';
import Fieldset from '../../components/FormFieldset/FormFieldset';
import { isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { CanSee } from '../../shared/entitlements/entitlements';
import InvoiceUtils from '../../api/utils/invoiceUtils';
import { selectListIsLoading, selectListRecords } from '../../store/utility';
import NewEmployee from '../NewEmployee/NewEmployee';
import FormButtons from '../FormButtons/FormButtons';
import DocumentUtils from '../../api/utils/documentUtils';
import Invoice from '../Invoice/Invoice';
import DataTable from '../DataTable/DataTable';
import DataLinkRow from '../DataLinkRow/DataLinkRow';

const { Option } = Select;

const LoadSendShipperInvoice = ({ load, loadId, invoice, shipperId, stops, cancel, loading = false }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    //#endregion
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const userId = useSelector(state => state.auth.userId);
    const employees = useSelector(state => selectListRecords(state.orchestrator.employees, shipperId));
    const documents = useSelector(state => DocumentUtils.selectListVisibleDocumentsByLoadIdAndStops(state.documents.lists, loadId, load, stops, 'SHIPPER', false));
    const isLoadingDocuments = useSelector(state => selectListIsLoading(state.documents.lists, loadId));
    const isLoadingUpdateInvoice = useSelector(state => state.invoices.isRecordUpdateLoading);
    const errorUpdateInvoice = useSelector(state => state.invoices.updateRecordError);

    //#endregion
    //#region useStates

    const [showNewEmployee, setShowNewEmployee] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [selectedDocumentIds, setSelectedDocumentIds] = useState([]);

    //#endregion
    //#region toggles

    const toggleNewEmployee = () => {
        setShowNewEmployee(!showNewEmployee);
    };

    //#endregion
    //#region table methods

    const rowSelectionDocuments = {
        hideSelectAll: false,
        columnTitle: 'Attach',
        columnWidth: 50,
        type: 'checkbox',
        selectedRowKeys: isListNotEmpty(selectedDocumentIds) ? [...selectedDocumentIds] : [],
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            console.log('selectedRowKeys changed: ', selectedRowKeys);
            if (isListNotEmpty(selectedRowKeys)) {
                setSelectedDocumentIds(selectedRowKeys);
            } else {
                setSelectedDocumentIds([]);
            }
        },
        getCheckboxProps: record => ({
            name: record.id,
        }),
    };

    const documentColumns = [
        {
            title: 'Document',
            dataIndex: 'documentDisplayName',
            key: 'documentDisplayName',
            textAlign: 'left',
            render: (text, record, index) => {
                return (<DataLinkRow key={`invoice-document-${index}`} title={text} fileId={record.id} />);
            },
        },
    ];

    //#endregion
    //#region onSubmit

    const onSubmitSendInvoice = async (data) => {
        if (isObjectNotEmpty(invoice) && isStringNotEmpty(invoice.id) && isListNotEmpty(employees) && isListNotEmpty(data.accountUserIds) && isObjectNotEmpty(load) && isStringNotEmpty(loadId) && isStringNotEmpty(userId)) {
            setIsSending(true);
            if (invoice.status !== 'COMPLETED') {
                // save the pdf document to s3
                let newDocument = await InvoiceUtils.saveInvoiceAsDocument('SHIPPER_INVOICE', invoice, invoice.invoiceLineItems, load, stops, false);

                let attachments = [];
                if (isListNotEmpty(selectedDocumentIds) && isListNotEmpty(documents)) {
                    selectedDocumentIds.forEach((documentId) => {
                        let doc = documents.find(d => d.id === documentId);
                        if (isObjectNotEmpty(doc)) {
                            attachments.push({
                                documentId: doc.id,
                                documentFileName: `${doc.documentDisplayName}${doc.fileExt}`,
                                documentFileType: doc.fileType
                            });
                        }
                    });
                }

                // send shipper invoice emails to recipients
                let emailsWereSent = false;
                let toEmails = [];
                for (let i = 0; i < data.accountUserIds.length; i++) {
                    const accountUserId = data.accountUserIds[i];
                    const accountUser = employees.find(a => a.id === accountUserId);
                    if (isObjectNotEmpty(accountUser)) {
                        toEmails.push({ email: accountUser.email, name: accountUser.firstName + ' ' + accountUser.lastName });
                    }
                }

                emailsWereSent = await InvoiceUtils.sendShipperInvoiceEmail('ap@irisfreight.com', toEmails, invoice, load, stops, newDocument, attachments);
                if (emailsWereSent === true) {
                    message.success('Email(s) have been sent.');
                    dispatch(actionCreators.updateInvoice(invoice.id, { status: 'SENT', sentAt: moment(), sentBy: userId }, invoice, true));
                } else {
                    message.error('Failed to send email(s)');
                }
            }
            setIsSending(false);
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.updateInvoiceCancel());
        cancel();
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        dispatch(actionCreators.updateInvoiceErrorClear());
    }, []);

    useMemo(() => {
        if (isStringNotEmpty(shipperId)) {
            dispatch(actionCreators.getEmployees(shipperId, false));
        }
    }, [shipperId]);

    useMemo(() => {
        if (isStringNotEmpty(loadId)) {
            let ids = [];
            ids.push(loadId);

            dispatch(actionCreators.fetchBulkDocumentLists(loadId, ids));
        }
    }, [loadId]);

    useMemo(() => {
        if (isLoadingUpdateInvoice !== null && isLoadingUpdateInvoice === false && errorUpdateInvoice === null) {
            onCancel();
        }
    }, [isLoadingUpdateInvoice, errorUpdateInvoice]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    //#endregion

    if (isObjectNotEmpty(invoice)) {
        return (
            <CanSee entityAction="READ" entityModel="LOAD_SHIPPER_INVOICE" entityObject={load}>
                <Invoice invoiceId={invoice.id} invoice={invoice} load={load} loadId={loadId} stops={stops} />

                {invoice.status !== 'COMPLETED' && invoice.status !== 'CANCELLED' ? (
                    <>
                        <FormProvider {...methods}>
                            <Form onSubmit={methods.handleSubmit(onSubmitSendInvoice)}>
                                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoadingDocuments === true || isSending === true || (isLoadingUpdateInvoice === true && errorUpdateInvoice === null) || loading === true}>
                                    <Fieldset legend="Would you like the send the invoice to the shipper you have assigned to this load?">
                                        <FormItem {...formItemLayout} label="Choose Employee(s) to send Invoice to" required
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Select
                                                    placeholder="Please Search and Select Employee(s)"
                                                    mode="multiple"
                                                    allowClear={true}
                                                    style={{ width: '100%' }}
                                                    virtual={false}
                                                    onBlur={onBlur}
                                                    onChange={(selected) => { onChange(selected); }}
                                                    value={value}
                                                    name={name}
                                                    showSearch={true}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Employees for you to choose from." />}
                                                    ref={ref}
                                                >
                                                    {employees.map(d => <Option value={d.id} key={d.id}>{d.firstName + ' ' + d.lastName} - Title: {d.title} - Email: {d.email}</Option>)}
                                                </Select>
                                            )}
                                            rules={{ required: 'Required Field' }}
                                            name="accountUserIds"
                                        />
                                        <br />
                                        <b>Employee Doesn't Exist Yet?</b>
                                        <br />
                                        <Button type='primary' onClick={toggleNewEmployee}>Add New Employee to Shipper Account</Button>
                                    </Fieldset>
                                    <Fieldset legend="Do you want to attach any documents to the email?">
                                        <DataTable
                                            bordered={false}
                                            rowSelection={rowSelectionDocuments}
                                            dataSource={documents.filter(d => d.documentType !== 'CARRIER_INVOICE' && d.documentType !== 'SHIPPER_INVOICE' && d.documentType !== 'FACTORING_INVOICE' && d.documentType !== 'INVOICE')}
                                            columns={documentColumns}
                                            hidePaging={true}
                                            style={{ backgroundColor: '#ffffff' }}
                                            rowKey={record => record.id}
                                        />
                                    </Fieldset>
                                </Spin>
                                {errorUpdateInvoice && <Alert message={`${errorUpdateInvoice}`} type="error" />}
                                <FormButtons cancel={onCancel} disabled={isSending === true || (isLoadingUpdateInvoice === true && errorUpdateInvoice === null)} submitText="Send the Invoice" />
                            </Form>
                        </FormProvider>
                        <Drawer
                            title={'Add New Employee'}
                            width={fullWidth > 720 ? fullWidth / 2 : 360}
                            onClose={toggleNewEmployee}
                            visible={showNewEmployee}
                            bodyStyle={{ paddingBottom: 80 }}
                            style={{ zIndex: 1000 }}
                            destroyOnClose={true}
                            closable={false}
                            maskClosable={false}
                        >
                            <NewEmployee cancel={toggleNewEmployee} accountId={shipperId} />
                        </Drawer>
                    </>
                ) : null}
            </CanSee>
        );
    } else {
        return null;
    }
};

export default LoadSendShipperInvoice;