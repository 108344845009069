import Checks from '../entitlementUtils';

export const canCreateTrailerType = (auth) => {
    return Checks.allowStaffAdmin(auth);
};

export const canReadTrailerType = (auth) => {
    return Checks.allowStaffAdmin(auth);
};

export const canReadTrailerTypeList = (auth) => {
    return Checks.allowStaffAdmin(auth);
};

export const canUpdateTrailerType = (auth) => {
    return Checks.allowStaffAdmin(auth);
};

export const canDeleteTrailerType = (auth) => {
    return Checks.allowStaffAdmin(auth);
};