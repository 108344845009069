export const UnselectLoadControl = (controlDiv, map, setSelectedLoadId, buttonContent, buttonTitle) => {

    // Set CSS for the control border.
    const controlUI = document.createElement("div");
    controlUI.style.backgroundColor = '#FFFFFF';
    controlUI.style.border = '1px solid #fff';
    controlUI.style.borderRadius = '3px';
    controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    controlUI.style.boxSizing = "border-box";
    controlUI.style.cursor = "pointer";
    controlUI.style.marginTop = "22px";
    controlUI.style.marginBottom = "8px";
    controlUI.style.marginLeft = "8px";
    controlUI.style.textAlign = "left";
    controlUI.style.width = "140px";
    controlUI.style.height = "36px";
    controlUI.title = buttonTitle;
    controlDiv.appendChild(controlUI);

    // Set CSS for the control interior.
    const controlText = document.createElement("div");

    controlText.style.color = "rgba(0, 0, 0, 0.6)";
    //controlText.style.fontFamily = "Open Sans";
    controlText.style.fontWeight = "1000";
    controlText.style.fontSize = "14px";
    controlText.style.lineHeight = "36px";
    controlText.style.paddingLeft = "4px";
    controlText.style.paddingRight = "4px";
    controlText.innerHTML = buttonContent;
    controlText.title = buttonTitle;
    controlUI.appendChild(controlText);

    // Setup the click event listeners: simply set the map to Chicago.
    controlUI.addEventListener("click", () => {
        if (map !== null && setSelectedLoadId !== null) {
            setSelectedLoadId(null);
        }
    });
};