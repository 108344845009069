import React, { useMemo } from 'react';
import { Input, Spin, Typography, Alert, Switch, Row, Col } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions";
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import { isObjectNotEmpty } from '../../shared/objectUtils';
import FormItemAddress from '../FormItemAddress/FormItemAddress';
import FormItemPointOfContact from '../FormItemPointOfContact/FormItemPointOfContact';

const { Paragraph } = Typography;

const EditShipper = ({ account, cancel }) => {
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.accounts.isRecordUpdateLoading);
    const error = useSelector(state => state.accounts.updateRecordError);
    const entityType = useSelector(state => state.auth.entityType);

    //#endregion
    //#region onSubmit and onCancel

    const onSubmit = (data) => {
        if (isObjectNotEmpty(data)) {
            dispatch(actionCreators.updateAccount(account.id, data, null, null, null, null, null, null, null, null, entityType));
        } else {
            onCancel();
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.updateAccountCancel());
        cancel();
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateAccountErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const formItemLayoutHorizontal = {
        labelCol: { span: 20 },
        wrapperCol: { span: 4 },
    };

    //#endregion
    if (isObjectNotEmpty(account)) {
        return (
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                        <Fieldset legend="Shipper Details">
                            <FormItem {...formItemLayout} label="D&B #" format="horizontal"
                                render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="D&B #" ref={ref} />}
                                rules={{ required: false }}
                                name="dbNum"
                                defaultValue={account.dbNum}
                            />
                            <FormItem {...formItemLayout} label="Produce Blue Book #" format="horizontal"
                                render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Produce Blue Book #" ref={ref} />}
                                rules={{ required: false }}
                                name="blueBookNum"
                                defaultValue={account.blueBookNum}
                            />
                        </Fieldset>
                        <Fieldset legend="Accounts Payable (* indicates a required field)">
                            <FormItemPointOfContact
                                format="horizontal"
                                required={true}
                                name="accountsPayable"
                                defaultValue={account.accountsPayable}
                            />

                            <Paragraph style={{ marginTop: 12, marginBottom: 12 }}>If the shipper's accounts payable department's address is different than the company's main physical address or mailing address, please provide it below.</Paragraph>

                            <FormItemAddress
                                format="horizontal"
                                required={false}
                                name="accountsPayableAddress"
                                defaultValue={account.accountsPayableAddress}
                                isPostalAddress={true}
                            />
                        </Fieldset>
                        <Fieldset legend="Point of Contact">
                            <FormItemPointOfContact
                                format="horizontal"
                                required={false}
                                name="pointOfContact"
                                defaultValue={account.pointOfContact}
                            />
                        </Fieldset>
                        <Fieldset legend="What are your equipment needs based on the commodities you ship?">
                            <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                    <h4 style={{ textAlign: 'center' }}>Reefers</h4>
                                    <FormItem {...formItemLayoutHorizontal} label="Do you need 48' Reefers?" noColon format="horizontal"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Switch
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                onBlur={onBlur}
                                                onChange={(checked, event) => { onChange(checked); }}
                                                checked={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        name="assetNeeds.has48Reefer"
                                        defaultValue={account.assetNeeds && account.assetNeeds.has48Reefer ? account.assetNeeds.has48Reefer : false}
                                    />
                                    <FormItem {...formItemLayoutHorizontal} label="Do you need 53' Reefers?" noColon format="horizontal"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Switch
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                onBlur={onBlur}
                                                onChange={(checked, event) => { onChange(checked); }}
                                                checked={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        name="assetNeeds.has53Reefer"
                                        defaultValue={account.assetNeeds && account.assetNeeds.has53Reefer ? account.assetNeeds.has53Reefer : false}
                                    />
                                    <FormItem {...formItemLayoutHorizontal} label="Do you need to ship frozen?" noColon format="horizontal"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Switch
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                onBlur={onBlur}
                                                onChange={(checked, event) => { onChange(checked); }}
                                                checked={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        name="assetNeeds.hasReeferFrozen"
                                        defaultValue={account.assetNeeds && account.assetNeeds.hasReeferFrozen ? account.assetNeeds.hasReeferFrozen : false}
                                    />
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                    <h4 style={{ textAlign: 'center' }}>Dry Vans</h4>
                                    <FormItem {...formItemLayoutHorizontal} label="Do you need 48' Dry Vans?" noColon format="horizontal"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Switch
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                onBlur={onBlur}
                                                onChange={(checked, event) => { onChange(checked); }}
                                                checked={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        name="assetNeeds.has48DryVan"
                                        defaultValue={account.assetNeeds && account.assetNeeds.has48DryVan ? account.assetNeeds.has48DryVan : false}
                                    />
                                    <FormItem {...formItemLayoutHorizontal} label="Do you need 53' Dry Vans?" noColon format="horizontal"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Switch
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                onBlur={onBlur}
                                                onChange={(checked, event) => { onChange(checked); }}
                                                checked={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        name="assetNeeds.has53DryVan"
                                        defaultValue={account.assetNeeds && account.assetNeeds.has53DryVan ? account.assetNeeds.has53DryVan : false}
                                    />
                                    <FormItem {...formItemLayoutHorizontal} label="Do you need lift gates for the dry vans?" noColon format="horizontal"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Switch
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                onBlur={onBlur}
                                                onChange={(checked, event) => { onChange(checked); }}
                                                checked={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        name="assetNeeds.hasDryVanLiftGates"
                                        defaultValue={account.assetNeeds && account.assetNeeds.hasDryVanLiftGates ? account.assetNeeds.hasDryVanLiftGates : false}
                                    />
                                    <FormItem {...formItemLayoutHorizontal} label="Do you need roller beds for the dry vans?" noColon format="horizontal"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Switch
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                onBlur={onBlur}
                                                onChange={(checked, event) => { onChange(checked); }}
                                                checked={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        name="assetNeeds.hasDryVanRollerBed"
                                        defaultValue={account.assetNeeds && account.assetNeeds.hasDryVanRollerBed ? account.assetNeeds.hasDryVanRollerBed : false}
                                    />
                                    <h4 style={{ textAlign: 'center' }}>Over-Sized Loads</h4>
                                    <FormItem {...formItemLayoutHorizontal} label="Do you need to ship over dimensional/overweight loads?" noColon format="horizontal"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Switch
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                onBlur={onBlur}
                                                onChange={(checked, event) => { onChange(checked); }}
                                                checked={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        name="assetNeeds.hasOverSized"
                                        defaultValue={account.assetNeeds && account.assetNeeds.hasOverSized ? account.assetNeeds.hasOverSized : false}
                                    />
                                </Col>
                                {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                    <h4 style={{ textAlign: 'center' }}>Flatbeds</h4>
                                    <FormItem {...formItemLayoutHorizontal} label="Do you need 48' Flatbeds?" noColon format="horizontal"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Switch
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                onBlur={onBlur}
                                                onChange={(checked, event) => { onChange(checked); }}
                                                checked={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        name="assetNeeds.has48Flatbed"
                                        defaultValue={account.assetNeeds && account.assetNeeds.has48Flatbed ? account.assetNeeds.has48Flatbed : false}
                                    />
                                    <FormItem {...formItemLayoutHorizontal} label="Do you need 53' Flatbeds?" noColon format="horizontal"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Switch
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                onBlur={onBlur}
                                                onChange={(checked, event) => { onChange(checked); }}
                                                checked={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        name="assetNeeds.has53Flatbed"
                                        defaultValue={account.assetNeeds && account.assetNeeds.has53Flatbed ? account.assetNeeds.has53Flatbed : false}
                                    />
                                    <h4 style={{ textAlign: 'center' }}>Low Boys</h4>
                                    <FormItem {...formItemLayoutHorizontal} label="Do you need Low Boys?" noColon format="horizontal"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Switch
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                onBlur={onBlur}
                                                onChange={(checked, event) => { onChange(checked); }}
                                                checked={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        name="assetNeeds.hasLowBoy"
                                        defaultValue={account.assetNeeds && account.assetNeeds.hasLowBoy ? account.assetNeeds.hasLowBoy : false}
                                    />
                                    <h4 style={{ textAlign: 'center' }}>RGNs</h4>
                                    <FormItem {...formItemLayoutHorizontal} label="Do you need RGNs?" noColon format="horizontal"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Switch
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                onBlur={onBlur}
                                                onChange={(checked, event) => { onChange(checked); }}
                                                checked={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        name="assetNeeds.hasRGN"
                                        defaultValue={account.assetNeeds && account.assetNeeds.hasRGN ? account.assetNeeds.hasRGN : false}
                                    />
                                    <h4 style={{ textAlign: 'center' }}>Step Decks</h4>
                                    <FormItem {...formItemLayoutHorizontal} label="Do you need Step Decks with Ramps?" noColon format="horizontal"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Switch
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                onBlur={onBlur}
                                                onChange={(checked, event) => { onChange(checked); }}
                                                checked={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        name="assetNeeds.hasStepDeckRamps"
                                        defaultValue={account.assetNeeds && account.assetNeeds.hasStepDeckRamps ? account.assetNeeds.hasStepDeckRamps : false}
                                    />
                                    <FormItem {...formItemLayoutHorizontal} label="Do you need Step Decks without Ramps?" noColon format="horizontal"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Switch
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                onBlur={onBlur}
                                                onChange={(checked, event) => { onChange(checked); }}
                                                checked={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        name="assetNeeds.hasStepDeckNoRamps"
                                        defaultValue={account.assetNeeds && account.assetNeeds.hasStepDeckNoRamps ? account.assetNeeds.hasStepDeckNoRamps : false}
                                    />
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}> 
                                    <h4 style={{ textAlign: 'center' }}>Tankers</h4>
                                    <FormItem {...formItemLayoutHorizontal} label="Do you need Single Compartment Tankers?" noColon format="horizontal"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Switch
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                onBlur={onBlur}
                                                onChange={(checked, event) => { onChange(checked); }}
                                                checked={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        name="assetNeeds.hasTankersSingleCompartment"
                                        defaultValue={account.assetNeeds && account.assetNeeds.hasTankersSingleCompartment ? account.assetNeeds.hasTankersSingleCompartment : false}
                                    />
                                    <FormItem {...formItemLayoutHorizontal} label="Do you need Multi Compartment Tankers?" noColon format="horizontal"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Switch
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                onBlur={onBlur}
                                                onChange={(checked, event) => { onChange(checked); }}
                                                checked={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        name="assetNeeds.hasTankersMultiCompartment"
                                        defaultValue={account.assetNeeds && account.assetNeeds.hasTankersMultiCompartment ? account.assetNeeds.hasTankersMultiCompartment : false}
                                    />
                                    <FormItem {...formItemLayoutHorizontal} label="Do you need Fiber Glass Tankers?" noColon format="horizontal"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Switch
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                onBlur={onBlur}
                                                onChange={(checked, event) => { onChange(checked); }}
                                                checked={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        name="assetNeeds.hasTankersFiberGlass"
                                        defaultValue={account.assetNeeds && account.assetNeeds.hasTankersFiberGlass ? account.assetNeeds.hasTankersFiberGlass : false}
                                    />
                                    <h4 style={{ textAlign: 'center' }}>Straight Trucks</h4>
                                    <FormItem {...formItemLayoutHorizontal} label="Do you need Cubed Straight Trucks?" noColon format="horizontal"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Switch
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                onBlur={onBlur}
                                                onChange={(checked, event) => { onChange(checked); }}
                                                checked={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        name="assetNeeds.hasCubedStraightTruck"
                                        defaultValue={account.assetNeeds && account.assetNeeds.hasCubedStraightTruck ? account.assetNeeds.hasCubedStraightTruck : false}
                                    />
                                    <FormItem {...formItemLayoutHorizontal} label="Do you need 26' Straight Trucks?" noColon format="horizontal"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Switch
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                onBlur={onBlur}
                                                onChange={(checked, event) => { onChange(checked); }}
                                                checked={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        name="assetNeeds.has26StraightTruck"
                                        defaultValue={account.assetNeeds && account.assetNeeds.has26StraightTruck ? account.assetNeeds.has26StraightTruck : false}
                                    />
                                    <FormItem {...formItemLayoutHorizontal} label="Do you need lift gates for the straight trucks?" noColon format="horizontal"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Switch
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                onBlur={onBlur}
                                                onChange={(checked, event) => { onChange(checked); }}
                                                checked={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        name="assetNeeds.hasStraightTruckLiftGates"
                                        defaultValue={account.assetNeeds && account.assetNeeds.hasStraightTruckLiftGates ? account.assetNeeds.hasStraightTruckLiftGates : false}
                                    />
                                    <h4 style={{ textAlign: 'center' }}>Sprinter Vans</h4>
                                    <FormItem {...formItemLayoutHorizontal} label="Do you need Sprinter Vans?" noColon format="horizontal"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Switch
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                onBlur={onBlur}
                                                onChange={(checked, event) => { onChange(checked); }}
                                                checked={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        name="assetNeeds.hasSprinterVan"
                                        defaultValue={account.assetNeeds && account.assetNeeds.hasSprinterVan ? account.assetNeeds.hasSprinterVan : false}
                                    />
                                </Col> */}
                            </Row>
                        </Fieldset>
                        {error && <Alert message={`${error}`} type="error" />}
                    </Spin>
                    <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Update Shipper" />
                </Form>
            </FormProvider>
        );
    } else {
        return null;
    }
};

export default EditShipper;