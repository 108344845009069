import React, { useMemo, useState } from 'react';
import { Alert, Input, Spin, Switch, Select } from "antd";
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import { isBooleanTrue, isEquivalent, isListNotEmpty, isObjectNotEmpty, removeEmpty } from '../../shared/objectUtils';
import Fieldset from '../FormFieldset/FormFieldset';
import { CanSee } from '../../shared/entitlements/entitlements';
import Enums from '../../shared/enums';
import EntityUtils from '../../api/entityUtils';
import { isEqual } from 'lodash';
import logger from '../../shared/logger';

const { OptGroup } = Select;

const EditDriver = ({ cancel, accountUser }) => {
    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.accountUsers.isRecordUpdateLoading);
    const error = useSelector(state => state.accountUsers.updateRecordError);
    const accounts = useSelector(state => state.orchestrator.accounts);

    const shipperAccountRoles = Enums.ShipperAccountRoles.selectListOptions();
    const carrierAccountRoles = Enums.CarrierAccountRoles.selectListOptions();
    const producerAccountRoles = Enums.ProducerAccountRoles.selectListOptions();
    const receiverAccountRoles = Enums.ReceiverAccountRoles.selectListOptions();

    const [account, setAccount] = useState(null);

    const onSubmit = (data) => {
        console.log(data)
        let payload = {};
        let objectToUpdate = { ...accountUser };
        for (let [key, value] of Object.entries(data)) {
            let valueToCompare = value !== undefined && value !== null ? value : '';
            let oldValue = objectToUpdate[key] !== undefined && objectToUpdate[key] !== null ? objectToUpdate[key] : '';
            if (value && typeof value === 'object' && Object.keys(value).length > 0) {
                if (oldValue && typeof oldValue === 'object' && Object.keys(oldValue).length > 0) {
                    let cleanedValue = removeEmpty(value);
                    if (!isEquivalent(cleanedValue, oldValue)) {
                        // console.log('not equivalent')
                        logger.logDebugEvent('EditDriver.js', `${key}: new value - ${JSON.stringify(value)}, old value - ${JSON.stringify(oldValue)}`, true);
                        payload[key] = value;
                    }
                } else {
                    let cleanedValue = removeEmpty(value);
                    // console.log(cleanedValue);
                    if (isObjectNotEmpty(cleanedValue)) {
                        // console.log('not equivalent')
                        logger.logDebugEvent('EditDriver.js', `${key}: new value - ${JSON.stringify(cleanedValue)}, old value - ${JSON.stringify(oldValue)}`, true);
                        payload[key] = cleanedValue;
                    }
                }
            } else if (!isEqual(valueToCompare, oldValue)) {
                logger.logDebugEvent('EditDriver.js', `${key}: new value - ${JSON.stringify(valueToCompare)}, old value - ${JSON.stringify(oldValue)}`, true);
                payload[key] = value;
            }
        }

        logger.logDebugEvent('EditDriver.js', JSON.stringify(payload), true);

        if (isObjectNotEmpty(payload)) {
            dispatch(actionCreators.updateAccountUser(accountUser.id, payload, accountUser.isDriver));
        } else {
            onCancel();
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.updateAccountUserCancel());
        cancel();
    };

    useMemo(() => {
        if (isObjectNotEmpty(accountUser) && isListNotEmpty(accounts)) {
            const selectedAccount = EntityUtils.getAccountInfo(accountUser.accountId, accounts);
            setAccount(selectedAccount);
        }
    }, [accountUser, accounts]);

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateAccountUserErrorClear());
        dispatch(actionCreators.getAccounts());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    
    if (isObjectNotEmpty(accountUser) && isObjectNotEmpty(accountUser.driver)) {
        return (
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                        <Fieldset legend="Employee Details">
                            <FormItem {...formItemLayout} label="Title"
                                render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Title" ref={ref} />}
                                rules={{ required: false }}
                                name="title"
                                defaultValue={accountUser.title}
                            />
                            <FormItem {...formItemLayout} label="Work Phone # (not used for login)"
                                render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Work Phone #" ref={ref} />}
                                rules={{ required: false }}
                                name="workPhone"
                                defaultValue={accountUser.workPhone}
                            />
                            <FormItem {...formItemLayout} label="Is Contractor"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Switch
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        onBlur={onBlur}
                                        onChange={(checked, event) => { onChange(checked); }}
                                        checked={value}
                                        name={name}
                                        ref={ref}
                                    />
                                )}
                                name="isContractor"
                                defaultValue={accountUser.isContractor}
                            />
                        </Fieldset>
                        {isObjectNotEmpty(account) ? (
                            <CanSee accountId={account.id} accountRoles={["OPERATIONS_ADMIN", "ADMIN"]}>
                                <Fieldset legend="Account Role(s)">
                                    <FormItem {...formItemLayout} label="Account Role(s)" format="vertical"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Select
                                                placeholder="Please Select a Line Item Type"
                                                mode="multiple"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                                ref={ref}
                                            >
                                                {isBooleanTrue(account.entityTypes.includes("SHIPPER")) ? (
                                                    <OptGroup label="Shipper Account Roles">
                                                        {shipperAccountRoles}
                                                    </OptGroup>
                                                ) : null}
                                                {isBooleanTrue(account.entityTypes.includes("CARRIER")) ? (
                                                    <OptGroup label="Carrier Account Roles">
                                                        {carrierAccountRoles}
                                                    </OptGroup>
                                                ) : null}
                                                {isBooleanTrue(account.entityTypes.includes("PRODUCER")) ? (
                                                    <OptGroup label="Producer Account Roles">
                                                        {producerAccountRoles}
                                                    </OptGroup>
                                                ) : null}
                                                {isBooleanTrue(account.entityTypes.includes("RECEIVER")) ? (
                                                    <OptGroup label="Receiver Account Roles">
                                                        {receiverAccountRoles}
                                                    </OptGroup>
                                                ) : null}
                                            </Select>
                                        )}
                                        rules={{ required: false }}
                                        name="accountRoles"
                                        defaultValue={accountUser.accountRoles}
                                    />
                                </Fieldset>
                            </CanSee>
                        ) : null}
                        <Fieldset legend="Driver Details">
                            <FormItem {...formItemLayout} label="Is Available"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Switch
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        onBlur={onBlur}
                                        onChange={(checked, event) => { onChange(checked); }}
                                        checked={value}
                                        name={name}
                                        ref={ref}
                                    />
                                )}
                                name="driver.isAvailable"
                                defaultValue={accountUser.driver.isAvailable}
                            />
                        </Fieldset>
                        {/* {isObjectNotEmpty(account) ? (
                            <CanSee accountId={account.id} accountRoles={["OPERATIONS_ADMIN", "ADMIN"]}>
                                <Fieldset legend="User Account (Login Credentials)">

                                </Fieldset>
                            </CanSee>
                        ) : null} */}
                        {error && <Alert message={`${error}`} type="error" />}
                    </Spin>
                    <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Update Driver" />
                </Form>
            </FormProvider>
        );
    } else {
        return null;
    }
};

export default EditDriver;