import * as actionTypes from "../actions/actionTypes";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { isBooleanTrue, isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import ErrorUtils from "../../shared/errorUtils";

//#region Fetch Note List Methods

export const fetchNoteListStart = (listName) => {
    return {
        type: actionTypes.FETCH_NOTE_LIST_START,
        payload: { listName: listName }
    }
};

export const fetchNoteListSuccess = (listName, payload) => {
    return {
        type: actionTypes.FETCH_NOTE_LIST_SUCCESS,
        payload: { listName: listName, ...payload }
    }
};

export const fetchNoteListFail = (listName, payload) => {
    return {
        type: actionTypes.FETCH_NOTE_LIST_FAIL,
        payload: { listName: listName, ...payload }
    }
};

export const clearNoteList = (listName) => {
    return {
        type: actionTypes.CLEAR_NOTE_LIST,
        payload: { listName: listName }
    }
};

export const clearNoteLists = () => {
    return {
        type: actionTypes.CLEAR_NOTE_LISTS
    }
};

export const addNoteToNoteList = (listName, payload) => {
    return {
        type: actionTypes.ADD_TO_NOTE_LIST,
        payload: { listName: listName, newRecord: payload }
    }
};

export const updateNoteInNoteList = (listName, payload) => {
    return {
        type: actionTypes.UPDATE_IN_NOTE_LIST,
        payload: { listName: listName, updatedRecord: payload }
    }
};

export const removeNoteFromNoteList = (listName, payload) => {
    return {
        type: actionTypes.REMOVE_FROM_NOTE_LIST,
        payload: { listName: listName, recordToRemove: payload }
    }
};

//#endregion
//#region Add Note Methods

export const addNoteStart = () => {
    return {
        type: actionTypes.ADD_NOTE_START
    }
};

export const addNoteSuccess = () => {
    return {
        type: actionTypes.ADD_NOTE_SUCCESS
    }
};

export const addNoteFail = (payload) => {
    return {
        type: actionTypes.ADD_NOTE_FAIL,
        payload: payload
    }
};

export const addNoteLoadingClear = () => {
    return {
        type: actionTypes.ADD_NOTE_LOADING_CLEAR
    }
};

export const addNoteErrorClear = () => {
    return {
        type: actionTypes.ADD_NOTE_ERROR_CLEAR
    }
};

export const addNoteCancel = () => {
    return {
        type: actionTypes.ADD_NOTE_CANCEL
    }
};

//#endregion
//#region Update Note Methods

const changeSingleNote = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_NOTE,
        payload: payload
    }
};

export const updateNoteStart = () => {
    return {
        type: actionTypes.UPDATE_NOTE_START
    }
};

export const updateNoteSuccess = () => {
    return {
        type: actionTypes.UPDATE_NOTE_SUCCESS
    }
};

export const updateNoteFail = (payload) => {
    return {
        type: actionTypes.UPDATE_NOTE_FAIL,
        payload: payload
    }
};

export const updateNoteLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_NOTE_LOADING_CLEAR
    }
};

export const updateNoteErrorClear = () => {
    return {
        type: actionTypes.UPDATE_NOTE_ERROR_CLEAR
    }
};

export const updateNoteCancel = () => {
    return {
        type: actionTypes.UPDATE_NOTE_CANCEL
    }
};

//#endregion
//#region Notes Methods

export const fetchNoteList = (listName, payload) => {
    return async (dispatch, getState) => {
        if (isStringNotEmpty(listName)) {
            try {
                const state = getState();
                const notesState = { ...state.notes };
                const existingLists = { ...notesState.lists };

                let pagination = {};
                let searchParams = {};
                if (isObjectNotEmpty(existingLists[listName])) {
                    pagination = { ...existingLists[listName].pagination };
                    searchParams = { ...existingLists[listName].searchParams };
                }

                if (isObjectNotEmpty(payload)) {
                    searchParams = { ...payload };
                }

                dispatch(clearNoteList(listName));
                dispatch(fetchNoteListStart(listName));

                const res = await Data.getNotes({ ...searchParams }, pagination);
                dispatch(fetchNoteListSuccess(listName, { records: res.data, params: { searchParams: res.searchParams, pagination: res.pagination } }));
            } catch (error) {
                logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
                dispatch(fetchNoteListFail(listName, { error: ErrorUtils.getErrorMessage(error) }));
            }
        }
    }
};

export const fetchBulkNoteList = (listName, entityId) => {
    return async (dispatch) => {
        if (isStringNotEmpty(listName)) {
            try {
                dispatch(fetchNoteListStart(listName));

                let searchParams = {
                    page: 1,
                    size: 1000000,
                    sort: 'createdAt',
                    order: 'desc',
                    isDeleted: false
                };

                if (isStringNotEmpty(entityId)) {
                    searchParams.entityId = entityId;
                }

                const notes = await Data.getBulkNotes({ ...searchParams });
                dispatch(fetchNoteListSuccess(listName, { records: notes }));
            } catch (error) {
                logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
                dispatch(fetchNoteListFail(listName, { error: ErrorUtils.getErrorMessage(error) }));
            }
        }
    }
};

export const fetchBulkNoteLists = (listName, entityIds = []) => {
    return async (dispatch) => {
        try {
            if (isListNotEmpty(entityIds)) {
                dispatch(fetchNoteListStart(listName));

                let searchParams = {
                    page: 1,
                    size: 1000000,
                    sort: 'createdAt',
                    order: 'desc',
                    entityId: entityIds,
                    isDeleted: false
                };

                const notes = await Data.getBulkNotes({ ...searchParams });
                entityIds.forEach((entityId) => {
                    const entityNotes = notes.filter(d => d.entityId === entityId);
                    dispatch(fetchNoteListSuccess(entityId, { records: entityNotes }));
                });
                dispatch(fetchNoteListSuccess(listName, { }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchNoteListFail(listName, { error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const addNote = (payload) => {
    return async (dispatch) => {
        try {
            dispatch(addNoteStart());

            const newNote = await Data.addNote(payload);
            if (isObjectNotEmpty(newNote)) {
                dispatch(addNoteToNoteList(newNote.entityId, newNote));
            }

            dispatch(addNoteSuccess());
            dispatch(addNoteLoadingClear());
            dispatch(addNoteErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addNoteFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateNote = (id, payload) => {
    return async (dispatch) => {
        try {
            dispatch(updateNoteStart());

            if (isBooleanTrue(payload.isDeleted)) {
                const removedNote = await Data.removeNote(id);
                if (isObjectNotEmpty(removedNote)) {
                    dispatch(removeNoteFromNoteList(removedNote.entityId, removedNote));
                }
            } else {
                const updatedNote = await Data.updateNote(id, payload);
                if (isObjectNotEmpty(updatedNote)) {
                    dispatch(updateNoteInNoteList(updatedNote.entityId, updatedNote));
                    dispatch(changeSingleNote(updatedNote));
                }
            }

            dispatch(updateNoteSuccess());
            dispatch(updateNoteLoadingClear());
            dispatch(updateNoteErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateNoteFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion