import React, { useMemo, useState } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import { Col, Row, Spin, Tabs, Typography } from 'antd';
import StringFormatter from '../../shared/stringFormatter';
import SimpleDataLinkCard from '../../components/SimpleDataLinkCard/SimpleDataLinkCard';
import SimpleStatsLinkCard from '../../components/SimpleStatsLinkCard/SimpleStatsLinkCard';
import SimpleDataCard from "../../components/SimpleDataCard/SimpleDataCard";
import { isNumberNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import QueueDriversPendingApproval from '../../components/QueueDriversPendingApproval/QueueDriversPendingApproval';
import QueueAccountsPendingVerification from '../../components/QueueAccountsPendingVerification/QueueAccountsPendingVerification';
import QueueDriversMissingDocuments from '../../components/QueueDriversMissingDocuments/QueueDriversMissingDocuments';
import QueueDriversWithExpiringLicenses from '../../components/QueueDriversWithExpiringLicenses/QueueDriversWithExpiringLicenses';
import QueueInTransitLoads from '../../components/QueueInTransitLoads/QueueInTransitLoads';
import QueueCriticalLoads from '../../components/QueueCriticalLoads/QueueCriticalLoads';
import { PlusOutlined } from '@ant-design/icons';
import NewLoadModal from '../../components/NewLoadModal/NewLoadModal';
import NewAccountModal from '../../components/NewAccountModal/NewAccountModal';
import ActionButton from '../../components/ActionButton/ActionButton';
import classes from './StaffDashboard.module.scss';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Header from '../../components/Header/Header';
import OpenLoads from "../../views/OpenLoads/OpenLoads";
import BookedLoads from "../../views/BookedLoads/BookedLoads";
import InTransitLoads from "../../views/InTransitLoads/InTransitLoads";
import LoadRequests from '../../views/LoadRequests/LoadRequests';
import QueueCompletedLoads from '../../components/QueueCompletedLoads/QueueCompletedLoads';
import LoadsMap from '../../components/LoadsMap/LoadsMap';
import QueueLoads from '../../components/QueueLoads/QueueLoads';
import { CanDo } from '../../shared/entitlements/entitlements';

const stringFormatter = new StringFormatter();
const { TabPane } = Tabs;

const StaffDashboard = (props) => {
    //#region useLocation

    const location = useLocation();

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const entityId = useSelector(state => state.auth.entityId);
    const firstName = useSelector(state => state.auth.firstName);
    const isLoading = useSelector(state => state.metrics.metricsIsLoading);
    const allMetrics = useSelector(state => state.metrics.metrics);

    //#endregion
    //#region useStates

    const [showNewAccountModal, setShowNewAccountModal] = useState(false);
    const [showNewLoadModal, setShowNewLoadModal] = useState(false);
    const [tabKey, setTabKey] = useState('snapshot');
    const [metrics, setMetrics] = useState(null);

    //#endregion
    //#region toggles

    const toggleNewAccountModal = () => {
        setShowNewAccountModal(!showNewAccountModal);
    };

    const toggleNewLoadModal = () => {
        setShowNewLoadModal(!showNewLoadModal);
    };

    //#endregion
    //#region goTos

    const goToNewCarrier = () => {
        props.history.push({
            pathname: "/newAccount",
            state: {
                accountTypes: ['CARRIER'],
                accountRegistrationId: null
            }
        });
    };

    const goToNewShipper = () => {
        props.history.push({
            pathname: "/newAccount",
            state: {
                accountTypes: ['SHIPPER'],
                accountRegistrationId: null
            }
        });
    };

    const goToNewProducer = () => {
        props.history.push({
            pathname: "/newAccount",
            state: {
                accountTypes: ['PRODUCER'],
                accountRegistrationId: null
            }
        });
    };

    const goToNewReceiver = () => {
        props.history.push({
            pathname: "/newAccount",
            state: {
                accountTypes: ['RECEIVER'],
                accountRegistrationId: null
            }
        });
    };

    const goToNewBroker = () => {
        props.history.push({
            pathname: "/newAccount",
            state: {
                accountTypes: ['BROKER'],
                accountRegistrationId: null
            }
        });
    };

    const goToNewFactoring = () => {
        props.history.push({
            pathname: "/newAccount",
            state: {
                accountTypes: ['FACTORING'],
                accountRegistrationId: null
            }
        });
    };

    //#endregion
    //#region tab methods

    const onTabChange = (key) => {
        props.history.replace({ ...props.location, hash: `#${key}` });
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        if (isStringNotEmpty(location.hash)) {
            setTabKey(location.hash.replace('#', ''));
        } else {
            setTabKey('snapshot');
            props.history.replace({ ...location, hash: `#snapshot` });
        }
    }, [location]);

    useMemo(() => {
        dispatch(actionCreators.fetchMetrics('IRIS', null));
    }, []);

    useMemo(() => {
        if (isObjectNotEmpty(allMetrics)) {
            //console.log(allMetrics['IRIS']);
            setMetrics(allMetrics['IRIS']);
        }
    }, [allMetrics]);

    //#endregion
    
    return (
        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true}>
            <Header
                title={`Hi, ${firstName}! Welcome to IRIS Freight.`}
                highlights={[
                    {
                        title: "Shippers",
                        value: `${isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.totalShippers) ? stringFormatter.toFormattedDecimal(metrics.totalShippers, 0, null, null) : 0}`,
                        url: "/shippers"
                    },
                    {
                        title: "Carriers",
                        value: `${isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.totalCarriers) ? stringFormatter.toFormattedDecimal(metrics.totalCarriers, 0, null, null) : 0}`,
                        url: "/carriers"
                    },
                    {
                        title: "Drivers",
                        value: `${isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.totalDrivers) ? stringFormatter.toFormattedDecimal(metrics.totalDrivers, 0, null, null) : 0}`,
                        url: "/drivers"
                    },
                    {
                        title: "Assets",
                        value: `${isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.totalAssets) ? stringFormatter.toFormattedDecimal(metrics.totalAssets, 0, null, null) : 0}`,
                        url: "/assets"
                    },
                ]}
            />
            <Tabs activeKey={tabKey} onChange={onTabChange} destroyInactiveTabPane={true} tabBarStyle={{ backgroundColor: '#ffffff', paddingRight: 24, paddingLeft: 24, margin: 0 }}>
                <TabPane tab="Snapshot" key="snapshot">
                    <div style={{ padding: 24 }}>
                        <Row gutter={[24, 24]}>
                            {(isObjectNotEmpty(metrics) && isLoading === false) ? (
                                <Col span={24}>
                                    <Row gutter={[24, 24]}>
                                        <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                            <ActionButton
                                                buttonClassName={classes.actionButton}
                                                buttonIconClassName={classes.iconContainer}
                                                buttonTextClassName={classes.textContainer}
                                                buttonIcon={<PlusOutlined className={classes.icon} />}
                                                buttonText='New Load'
                                                onClick={toggleNewLoadModal}
                                            />
                                        </Col>
                                        <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                            <ActionButton
                                                buttonClassName={classes.actionButton}
                                                buttonIconClassName={classes.iconContainer}
                                                buttonTextClassName={classes.textContainer}
                                                buttonIcon={<PlusOutlined className={classes.icon} />}
                                                buttonText='New Shipper'
                                                onClick={goToNewShipper}
                                            />
                                        </Col>
                                        <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                            <ActionButton
                                                buttonClassName={classes.actionButton}
                                                buttonIconClassName={classes.iconContainer}
                                                buttonTextClassName={classes.textContainer}
                                                buttonIcon={<PlusOutlined className={classes.icon} />}
                                                buttonText='New Carrier'
                                                onClick={goToNewCarrier}
                                            />
                                        </Col>
                                        <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                            <ActionButton
                                                buttonClassName={classes.actionButton}
                                                buttonIconClassName={classes.iconContainer}
                                                buttonTextClassName={classes.textContainer}
                                                buttonIcon={<PlusOutlined className={classes.icon} />}
                                                buttonText='New Account'
                                                onClick={toggleNewAccountModal}
                                            />
                                        </Col>
                                        <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                            <SimpleStatsLinkCard
                                                style={{ border: '1px solid #ffa39e' }}
                                                headStyle={{ backgroundColor: '#fff1f0', borderBottom: '1px solid #ffa39e', fontSize: 14 }}
                                                title="Loads Needing Coverage"
                                                date="within 24 hours"
                                                amount={isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.loadsNeedingCoverage24hrs) ? stringFormatter.toFormattedDecimal(metrics.loadsNeedingCoverage24hrs, 0, null, null) : 0}
                                                url="/openLoads"
                                            />
                                        </Col>
                                        <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                            <SimpleStatsLinkCard
                                                style={{ border: '1px solid #ffa39e' }}
                                                headStyle={{ backgroundColor: '#fff1f0', borderBottom: '1px solid #ffa39e', fontSize: 14 }}
                                                title="Loads Needing Coverage"
                                                amount={isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.loadsNeedingCoverage) ? stringFormatter.toFormattedDecimal(metrics.loadsNeedingCoverage, 0, null, null) : 0}
                                                url="/openLoads"
                                            />
                                        </Col>
                                        <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                            <SimpleStatsLinkCard
                                                style={{ border: '1px solid #ffa39e' }}
                                                headStyle={{ backgroundColor: '#fff1f0', borderBottom: '1px solid #ffa39e', fontSize: 14 }}
                                                title="Loads Needing Dispatch"
                                                date="within 24 hours"
                                                amount={isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.loadsNeedingDispatch24hrs) ? stringFormatter.toFormattedDecimal(metrics.loadsNeedingDispatch24hrs, 0, null, null) : 0}
                                                url="/bookedLoads"
                                            />
                                        </Col>
                                        <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                            <SimpleStatsLinkCard
                                                style={{ border: '1px solid #ffa39e' }}
                                                headStyle={{ backgroundColor: '#fff1f0', borderBottom: '1px solid #ffa39e', fontSize: 14 }}
                                                title="Loads Needing Dispatch"
                                                amount={isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.loadsNeedingDispatch) ? stringFormatter.toFormattedDecimal(metrics.loadsNeedingDispatch, 0, null, null) : 0}
                                                url="/bookedLoads"
                                            />
                                        </Col>
                                        <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                            <SimpleDataLinkCard
                                                headStyle={{ fontSize: 14 }}
                                                title="Total Active Loads"
                                                data={isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.totalActiveLoads) ? stringFormatter.toFormattedDecimal(metrics.totalActiveLoads, 0, null, null) : 0}
                                                url="/intransitLoads"
                                            />
                                        </Col>
                                        <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                            <SimpleDataLinkCard
                                                headStyle={{ fontSize: 14 }}
                                                title="Total Completed Loads"
                                                data={isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.totalCompletedLoads) ? stringFormatter.toFormattedDecimal(metrics.totalCompletedLoads, 0, null, null) : 0}
                                                url="/completedLoads"
                                            />
                                        </Col>
                                        <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                            <SimpleDataLinkCard
                                                headStyle={{ fontSize: 14 }}
                                                title="Total Closed Loads"
                                                data={isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.totalClosedLoads) ? stringFormatter.toFormattedDecimal(metrics.totalClosedLoads, 0, null, null) : 0}
                                                url="/completedLoads"
                                            />
                                        </Col>
                                        <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                            <SimpleDataCard
                                                headStyle={{ fontSize: 14 }}
                                                title="Total Earnings"
                                                data={isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.totalEarnings) ? stringFormatter.toFormattedString("Money", metrics.totalEarnings, null) + ' ' + metrics.totalEarningsUnit : ''}
                                            />
                                        </Col>
                                        <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                            <SimpleDataCard
                                                headStyle={{ fontSize: 14 }}
                                                title="Service Fee"
                                                data={isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.serviceFee) ? stringFormatter.toFormattedString("Money", metrics.serviceFee, null) + ' ' + metrics.serviceFeeUnit : ''}
                                            />
                                        </Col>
                                        <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                            <SimpleDataLinkCard
                                                headStyle={{ fontSize: 14 }}
                                                title="Completed Loads This Month"
                                                data={isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.completedLoadsThisMonth) ? stringFormatter.toFormattedDecimal(metrics.completedLoadsThisMonth, 0, null, null) : 0}
                                                url="/completedLoads"
                                            />
                                        </Col>
                                        <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                            <SimpleDataLinkCard
                                                headStyle={{ fontSize: 14 }}
                                                title="Closed Loads This Month"
                                                data={isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.closedLoadsThisMonth) ? stringFormatter.toFormattedDecimal(metrics.closedLoadsThisMonth, 0, null, null) : 0}
                                                url="/completedLoads"
                                            />
                                        </Col>
                                        <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                            <SimpleDataCard
                                                style={{ border: '1px solid #b7eb8f' }}
                                                headStyle={{ backgroundColor: '#f6ffed', borderBottom: '1px solid #b7eb8f', fontSize: 14 }}
                                                title="Earnings This Month"
                                                data={isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.earningsThisMonth) ? stringFormatter.toFormattedString("Money", metrics.earningsThisMonth, null) + ' ' + metrics.earningsThisMonthUnit : ''}
                                            />
                                        </Col>
                                        <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                            <QueueDriversPendingApproval
                                                style={{ border: '1px solid #ffa39e' }}
                                                headStyle={{ backgroundColor: '#fff1f0', borderBottom: '1px solid #ffa39e', fontSize: 14 }}
                                            />
                                        </Col>
                                        <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                            <QueueDriversMissingDocuments
                                                style={{ border: '1px solid #ffa39e' }}
                                                headStyle={{ backgroundColor: '#fff1f0', borderBottom: '1px solid #ffa39e', fontSize: 14 }}
                                            />
                                        </Col>
                                        <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                            <QueueDriversWithExpiringLicenses
                                                style={{ border: '1px solid #ffa39e' }}
                                                headStyle={{ backgroundColor: '#fff1f0', borderBottom: '1px solid #ffa39e', fontSize: 14 }}
                                            />
                                        </Col>
                                        <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                            <QueueAccountsPendingVerification
                                                style={{ border: '1px solid #ffa39e' }}
                                                headStyle={{ backgroundColor: '#fff1f0', borderBottom: '1px solid #ffa39e', fontSize: 14 }}
                                            />
                                        </Col>
                                        <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                            <QueueInTransitLoads
                                                style={{ border: '1px solid #ffa39e' }}
                                                headStyle={{ backgroundColor: '#fff1f0', borderBottom: '1px solid #ffa39e', fontSize: 14 }}
                                            />
                                        </Col>
                                        <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                            <QueueCriticalLoads
                                                style={{ border: '1px solid #ffa39e' }}
                                                headStyle={{ backgroundColor: '#fff1f0', borderBottom: '1px solid #ffa39e', fontSize: 14 }}
                                            />
                                        </Col>
                                        <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                            <QueueCompletedLoads
                                                showAccountsPayable={true}
                                                showProofOfDelivery={true}
                                                title="Accounts Payable"
                                                filter="COMPLETED_AP_ALL"
                                                defaultLoadStatusFilter={['COMPLETED', 'REOPENED']}
                                                filterSearchParams={{
                                                    'completionCheckList.carrierInvoiceStatus:not': 'COMPLETED'
                                                }}
                                                style={{ border: '1px solid #b7eb8f' }}
                                                headStyle={{ backgroundColor: '#f6ffed', borderBottom: '1px solid #b7eb8f', fontSize: 14 }}
                                            />
                                        </Col>
                                        <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                            <QueueCompletedLoads
                                                showAccountsReceivable={true}
                                                showProofOfDelivery={true}
                                                title="Accounts Receivable"
                                                filter="COMPLETED_AR_ALL"
                                                defaultLoadStatusFilter={['COMPLETED', 'REOPENED']}
                                                filterSearchParams={{
                                                    'completionCheckList.shipperInvoiceStatus:not': 'COMPLETED'
                                                }}
                                                style={{ border: '1px solid #b7eb8f' }}
                                                headStyle={{ backgroundColor: '#f6ffed', borderBottom: '1px solid #b7eb8f', fontSize: 14 }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            ) : null}
                            <Col span={24}>
                                <LoadsMap showZoomControl={true} containerName="map-all-loads" />
                            </Col>
                        </Row>
                    </div>
                </TabPane>
                <TabPane tab="My Open Loads" key="openLoads">
                    <div style={{ padding: 24 }}>
                        <OpenLoads assignedAccountRepUserId={entityId} />
                    </div>
                </TabPane>
                <TabPane tab="My Load Requests" key="loadRequests">
                    <div style={{ padding: 24 }}>
                        <LoadRequests assignedAccountRepUserId={entityId} />
                    </div>
                </TabPane>
                <TabPane tab="My Booked Loads" key="bookedLoads">
                    <div style={{ padding: 24 }}>
                        <BookedLoads assignedAccountRepUserId={entityId} />
                    </div>
                </TabPane>
                <TabPane tab="My In Transit Loads" key="intransitLoads">
                    <div style={{ padding: 24 }}>
                        <InTransitLoads assignedAccountRepUserId={entityId} />
                    </div>
                </TabPane>
                <TabPane tab="My Completed Loads" key="completedLoads">
                    <div style={{ padding: 24 }}>
                        <QueueLoads
                            title="Completed Loads"
                            filter={`COMPLETED_LOADS_ACCOUNT_REP_${entityId}`}
                            filterSearchParams={{
                                sort: 'loadEndDateTime',
                                order: 'desc',
                                isDeleted: false,
                                eta: false
                            }}
                            defaultLoadStatusFilter={['COMPLETED', 'REOPENED', 'CLOSED', 'CANCELLED']}
                            showTable={true}
                            assignedAccountRepUserId={entityId}
                            showCompletedLoadData={true}
                            showAccountsPayable={CanDo({ staffOnly: true })}
                            showAccountsReceivable={CanDo({ staffOnly: true })}
                        />
                    </div>
                </TabPane>
                {CanDo({ staffOnly: true }) && (
                    <TabPane tab="My AR/AP" key="arap">
                        <div style={{ padding: 24 }}>
                            <QueueCompletedLoads
                                showAccountsReceivable={true}
                                showAccountsPayable={true}
                                showProofOfDelivery={true}
                                showRequiredDocumentStatus={true}
                                showClaimsStatus={true}
                                title="AR/AP"
                                filter={`COMPLETED_ALL_ACCOUNT_REP_${entityId}`}
                                defaultLoadStatusFilter={['COMPLETED', 'REOPENED']}
                                showTable={true}
                                assignedAccountRepUserId={entityId}
                            />
                        </div>
                    </TabPane>
                )}
            </Tabs>

            <NewAccountModal showModal={showNewAccountModal} toggleModal={toggleNewAccountModal} />
            <NewLoadModal key='new-load' showModal={showNewLoadModal} toggleModal={toggleNewLoadModal} />
        </Spin>
    );
};

export default withRouter(StaffDashboard);