import axiosAuthenticated from "../../api/axiosAuthenticated";
import { isNotNullOrUndefined } from "../../shared/objectUtils";

const shiftsBulkPath = '/shifts/bulk/get';
const shiftsPath = '/shifts';

//#region Shifts Methods

export const getShifts = async (searchParams = {}, pagination = {}) => {
    const shiftsRes = await axiosAuthenticated.post(shiftsBulkPath, { ...searchParams });
    if (shiftsRes && shiftsRes.status === 200) {
        const shifts = shiftsRes.data.data;
        const otherData = shiftsRes.data;

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;

        return { data: shifts, searchParams: searchParams, pagination: pagination };
    }

    return { data: [], searchParams: searchParams, pagination: pagination };
};

export const getShift = async (id) => {
    const shiftsRes = await axiosAuthenticated.get(shiftsPath + `/${id}`);
    if (shiftsRes && shiftsRes.status === 200) {
        return shiftsRes.data;
    }

    return null;
};

export const addShift = async (payload) => {
    const shiftsRes = await axiosAuthenticated.post(shiftsPath, { ...payload });
    if (shiftsRes && shiftsRes.status === 201) {
        return shiftsRes.data;
    }

    return null;
};

export const updateShift = async (id, payload) => {
    const shiftsRes = await axiosAuthenticated.put(shiftsPath + `/${id}`, { ...payload });
    if (shiftsRes && shiftsRes.status === 200) {
        return shiftsRes.data;
    }

    return null;
};

export const removeShift = async (id) => {
    const shiftsRes = await axiosAuthenticated.put(shiftsPath + `/${id}`, { isDeleted: true });
    if (shiftsRes && shiftsRes.status === 200) {
        return shiftsRes.data;
    }

    return null;
};

//#endregion