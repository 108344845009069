import { isListNotEmpty, isNumberNotEmpty } from '../../shared/objectUtils';
import EntityUtils from '../entityUtils';

export const getLineItemTypeDTO = (lineItemType, trailerTypes = []) => {
    let trailerTypesArray = [];
    if (isListNotEmpty(lineItemType.trailerTypeIds)) {
        lineItemType.trailerTypeIds.forEach((trailerTypeId) => {
            trailerTypesArray.push(EntityUtils.getTrailerTypeInfo(trailerTypeId, trailerTypes));
        });
    }

    lineItemType.trailerTypes = trailerTypesArray;

    return lineItemType;
};

export const getAddLineItemTypeRequestDTO = (payload) => {
    if (isNumberNotEmpty(payload.upCharge)) {
        payload.upCharge = Number(payload.upCharge);
    }

    if (isNumberNotEmpty(payload.cost)) {
        payload.cost = Number(payload.cost);
    }

    return payload;
};

export const getUpdateLineItemTypeRequestDTO = (payload) => {
    if (isNumberNotEmpty(payload.upCharge)) {
        payload.upCharge = Number(payload.upCharge);
    }

    if (isNumberNotEmpty(payload.cost)) {
        payload.cost = Number(payload.cost);
    }

    return payload;
};