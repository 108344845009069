import Checks from '../entitlementUtils';

export const canCreateLoadAsset = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadCarrier(load, auth);
};

export const canReadLoadAsset = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadCarrier(load, auth);
};

export const canReadLoadAssetList = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadCarrier(load, auth);
};

export const canUpdateLoadAsset = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadCarrier(load, auth);
};

export const canDeleteLoadAsset = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadCarrier(load, auth);
};