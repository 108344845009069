import * as actionTypes from "../actions/actionTypes";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { isBooleanTrue, isObjectNotEmpty } from "../../shared/objectUtils";
import * as actionCreators from "./index";
import ErrorUtils from "../../shared/errorUtils";

//#region Fetch Commodities Methods

export const fetchCommoditiesStart = () => {
    return {
        type: actionTypes.FETCH_COMMODITIES_START
    }
};

export const fetchCommoditiesSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_COMMODITIES_SUCCESS,
        payload: payload
    }
};

export const fetchCommoditiesFail = (payload) => {
    return {
        type: actionTypes.FETCH_COMMODITIES_FAIL,
        payload: payload
    }
};

export const clearCommodities = () => {
    return {
        type: actionTypes.CLEAR_COMMODITIES
    }
};

//#endregion
//#region Add Commodity Methods

const insertCommodity = (payload) => {
    return {
        type: actionTypes.ADD_COMMODITY,
        payload: payload
    }
};

export const addCommodityStart = () => {
    return {
        type: actionTypes.ADD_COMMODITY_START
    }
};

export const addCommoditySuccess = () => {
    return {
        type: actionTypes.ADD_COMMODITY_SUCCESS
    }
};

export const addCommodityFail = (payload) => {
    return {
        type: actionTypes.ADD_COMMODITY_FAIL,
        payload: payload
    }
};

export const addCommodityLoadingClear = () => {
    return {
        type: actionTypes.ADD_COMMODITY_LOADING_CLEAR
    }
};

export const addCommodityErrorClear = () => {
    return {
        type: actionTypes.ADD_COMMODITY_ERROR_CLEAR
    }
};

export const addCommodityCancel = () => {
    return {
        type: actionTypes.ADD_COMMODITY_CANCEL
    }
};

//#endregion
//#region Update Commodity Methods

const changeCommodity = (payload) => {
    return {
        type: actionTypes.UPDATE_COMMODITY,
        payload: payload
    }
};

const changeSingleCommodity = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_COMMODITY,
        payload: payload
    }
};

export const updateCommodityStart = () => {
    return {
        type: actionTypes.UPDATE_COMMODITY_START
    }
};

export const updateCommoditySuccess = () => {
    return {
        type: actionTypes.UPDATE_COMMODITY_SUCCESS
    }
};

export const updateCommodityFail = (payload) => {
    return {
        type: actionTypes.UPDATE_COMMODITY_FAIL,
        payload: payload
    }
};

export const updateCommodityLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_COMMODITY_LOADING_CLEAR
    }
};

export const updateCommodityErrorClear = () => {
    return {
        type: actionTypes.UPDATE_COMMODITY_ERROR_CLEAR
    }
};

export const updateCommodityCancel = () => {
    return {
        type: actionTypes.UPDATE_COMMODITY_CANCEL
    }
};

//#endregion
//#region Remove Commodity Methods

const removeCommodity = (payload) => {
    return {
        type: actionTypes.REMOVE_COMMODITY,
        payload: payload
    }
};

//#endregion
//#region Commodities Methods

export const fetchCommodities = (payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(fetchCommoditiesStart());

            const state = getState();
            const commoditiesState = { ...state.commodities };
            let pagination = { ...commoditiesState.pagination };
            let searchParams = { ...commoditiesState.searchParams };

            if (isObjectNotEmpty(payload)) {
                searchParams = { ...payload };
            }

            const res = await Data.getCommodities({ ...searchParams }, pagination);
            dispatch(fetchCommoditiesSuccess({ records: res.data, searchParams: res.searchParams, pagination: res.pagination }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchCommoditiesFail({ error: ErrorUtils.getErrorMessage(error) }));
        }

    }
};

export const addCommodity = (payload) => {
    return async (dispatch) => {
        try {
            dispatch(addCommodityStart());

            const newCommodity = await Data.addCommodity(payload);
            if (isObjectNotEmpty(newCommodity)) {
                dispatch(insertCommodity(newCommodity));

                // refresh the commodities orchestrator
                dispatch(actionCreators.getCommodities(true));
            }

            dispatch(addCommoditySuccess());
            dispatch(addCommodityLoadingClear());
            dispatch(addCommodityErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addCommodityFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateCommodity = (id, payload) => {
    return async (dispatch) => {
        try {
            dispatch(updateCommodityStart());

            if (isBooleanTrue(payload.isDeleted)) {
                const removedCommodity = await Data.removeCommodity(id);
                if (isObjectNotEmpty(removedCommodity)) {
                    dispatch(removeCommodity(removedCommodity));

                    // refresh orchestrator
                    dispatch(actionCreators.getCommodities(true));
                }
            } else {
                const updatedCommodity = await Data.updateCommodity(id, payload);
                if (isObjectNotEmpty(updatedCommodity)) {
                    dispatch(changeCommodity(updatedCommodity));
                    dispatch(changeSingleCommodity(updatedCommodity));

                    // refresh orchestrator
                    dispatch(actionCreators.getCommodities(true));
                }
            }

            dispatch(updateCommoditySuccess());
            dispatch(updateCommodityLoadingClear());
            dispatch(updateCommodityErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateCommodityFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion