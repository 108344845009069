import React from 'react';
import MapWrapper from '../Map/Map';

const LoadMap = ({ loadId, stops, currentLatitude, currentLongitude, showZoomControl = true, notAuthenticated = false, containerName = null, fullScreen = false, height = "500px" }) => {
    return (
        <MapWrapper loadId={loadId}
            stops={stops}
            currentLatitude={currentLatitude}
            currentLongitude={currentLongitude}
            showZoomControl={showZoomControl}
            notAuthenticated={notAuthenticated}
            containerName={containerName}
            fullScreen={fullScreen}
            height={height}
        />
    );
};

export default LoadMap;