import React from 'react';
import { Card, Typography } from "antd";
import classes from './SimpleDataLinkCard.module.scss';
import { withRouter } from 'react-router';
import { isNotNullOrUndefined, isStringNotEmpty } from '../../shared/objectUtils';

const { Title } = Typography;

const SimpleDataLinkCard = ({ title, style = null, headStyle = null, dataSize = 3, data, extra, url = null, onClick = null, currentBreadcrumbName = "Dashboard", ...props }) => {
    const goToUrl = (path) => {
        props.history.push(path, {
            previousPageTitle: currentBreadcrumbName,
            previousPageLocation: props.location,
            previousBreadcrumbs: props.breadcrumbs,
        });
    };
    
    if (isStringNotEmpty(url)) {
        return (
            <Card bordered={true} title={title} className={classes.card} style={style} headStyle={headStyle} extra={extra}>
                <div><a onClick={() => { goToUrl(url); }} style={{ textDecoration: 'underline' }}><Title level={dataSize} style={{ color: '#23bebb' }}>{data}</Title></a></div>
            </Card>
        );
    } else if (isNotNullOrUndefined(onClick)) {
        return (
            <Card bordered={true} title={title} className={classes.card} style={style} headStyle={headStyle} extra={extra}>
                <div><a onClick={onClick} style={{ textDecoration: 'underline' }}><Title level={dataSize} style={{ color: '#23bebb' }}>{data}</Title></a></div>
            </Card>
        );
    } else {
        return (
            <Card bordered={true} title={title} className={classes.card} style={style} headStyle={headStyle} extra={extra}>
                <div><Title level={dataSize}>{data}</Title></div>
            </Card>
        );
    }
};

export default withRouter(SimpleDataLinkCard);