import React from 'react';
import classes from './MessageSent.module.scss';
import MomentDate from '../../shared/dateFormatter';

const MessageSent = ({ message }) => {
    return (
        <div className={classes.outgoingMessage} key={message.id}>
            <div className={classes.sentMessage}>
                <p>{message.message}</p>
                <span className={classes.timeDate}>{new MomentDate().fromUtcToDeviceTimeZoneForMessages(message.createdAt)}</span>
            </div>
        </div>
    );
};

export default MessageSent;