import axiosAuthenticated from "../../api/axiosAuthenticated";
import { isNotNullOrUndefined } from "../../shared/objectUtils";
import * as DTO from "../dtos/index";

const configurationsPath = '/configurations';

//#region Configurations Methods

export const getConfigurations = async (searchParams = {}, pagination = {}) => {
    const configurationsRes = await axiosAuthenticated.get(configurationsPath, { params: { ...searchParams } });
    if (configurationsRes && configurationsRes.status === 200) {
        const configurations = configurationsRes.data.data;
        const otherData = configurationsRes.data;

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;

        return { data: configurations, searchParams: searchParams, pagination: pagination };
    }

    return { data: [], searchParams: searchParams, pagination: pagination };
};

export const getConfiguration = async (id) => {
    const configurationsRes = await axiosAuthenticated.get(configurationsPath + `/${id}`);
    if (configurationsRes && configurationsRes.status === 200) {
        return configurationsRes.data;
    }

    return null;
};

export const addConfiguration = async (payload) => {
    let configurationPayload = DTO.getAddConfigurationRequestDTO(payload);

    const configurationsRes = await axiosAuthenticated.post(configurationsPath, { ...configurationPayload });
    if (configurationsRes && configurationsRes.status === 201) {
        return configurationsRes.data;
    }

    return null;
};

export const updateConfiguration = async (id, payload) => {
    let configurationPayload = DTO.getUpdateConfigurationRequestDTO(payload);

    const configurationsRes = await axiosAuthenticated.put(configurationsPath + `/${id}`, { ...configurationPayload });
    if (configurationsRes && configurationsRes.status === 200) {
        return configurationsRes.data;
    }

    return null;
};

export const removeConfiguration = async (id) => {
    const configurationsRes = await axiosAuthenticated.put(configurationsPath + `/${id}`, { isDeleted: true });
    if (configurationsRes && configurationsRes.status === 200) {
        return configurationsRes.data;
    }

    return null;
};

//#endregion