import * as actionTypes from "../actions/actionTypes";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import ErrorUtils from "../../shared/errorUtils";
import { selectListRecords } from "../utility";

//#region Fetch Notification List Methods

export const fetchNotificationListStart = (listName) => {
    return {
        type: actionTypes.FETCH_NOTIFICATION_LIST_START,
        payload: { listName: listName }
    }
};

export const fetchNotificationListSuccess = (listName, payload) => {
    return {
        type: actionTypes.FETCH_NOTIFICATION_LIST_SUCCESS,
        payload: { listName: listName, ...payload }
    }
};

export const fetchNotificationListFail = (listName, payload) => {
    return {
        type: actionTypes.FETCH_NOTIFICATION_LIST_FAIL,
        payload: { listName: listName, ...payload }
    }
};

export const clearNotificationList = (listName) => {
    return {
        type: actionTypes.CLEAR_NOTIFICATION_LIST,
        payload: { listName: listName }
    }
};

export const clearNotificationLists = () => {
    return {
        type: actionTypes.CLEAR_NOTIFICATION_LISTS
    }
};

export const addNotificationToNotificationList = (listName, payload) => {
    return {
        type: actionTypes.ADD_TO_NOTIFICATION_LIST,
        payload: { listName: listName, newRecord: payload }
    }
};

export const updateNotificationInNotificationList = (listName, payload) => {
    return {
        type: actionTypes.UPDATE_IN_NOTIFICATION_LIST,
        payload: { listName: listName, updatedRecord: payload }
    }
};

export const removeNotificationFromNotificationList = (listName, payload) => {
    return {
        type: actionTypes.REMOVE_FROM_NOTIFICATION_LIST,
        payload: { listName: listName, recordToRemove: payload }
    }
};

//#endregion
//#region Add Notification Methods

export const addNotificationStart = () => {
    return {
        type: actionTypes.ADD_NOTIFICATION_START
    }
};

export const addNotificationSuccess = () => {
    return {
        type: actionTypes.ADD_NOTIFICATION_SUCCESS
    }
};

export const addNotificationFail = (payload) => {
    return {
        type: actionTypes.ADD_NOTIFICATION_FAIL,
        payload: payload
    }
};

export const addNotificationLoadingClear = () => {
    return {
        type: actionTypes.ADD_NOTIFICATION_LOADING_CLEAR
    }
};

export const addNotificationErrorClear = () => {
    return {
        type: actionTypes.ADD_NOTIFICATION_ERROR_CLEAR
    }
};

export const addNotificationCancel = () => {
    return {
        type: actionTypes.ADD_NOTIFICATION_CANCEL
    }
};

//#endregion
//#region Update Notification Methods

const changeSingleNotification = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_NOTIFICATION,
        payload: payload
    }
};

export const updateNotificationStart = () => {
    return {
        type: actionTypes.UPDATE_NOTIFICATION_START
    }
};

export const updateNotificationSuccess = () => {
    return {
        type: actionTypes.UPDATE_NOTIFICATION_SUCCESS
    }
};

export const updateNotificationFail = (payload) => {
    return {
        type: actionTypes.UPDATE_NOTIFICATION_FAIL,
        payload: payload
    }
};

export const updateNotificationLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_NOTIFICATION_LOADING_CLEAR
    }
};

export const updateNotificationErrorClear = () => {
    return {
        type: actionTypes.UPDATE_NOTIFICATION_ERROR_CLEAR
    }
};

export const updateNotificationCancel = () => {
    return {
        type: actionTypes.UPDATE_NOTIFICATION_CANCEL
    }
};

//#endregion
//#region Notifications Methods

export const fetchNotificationList = (listName, payload) => {
    return async (dispatch, getState) => {
        if (isStringNotEmpty(listName)) {
            try {
                const state = getState();
                const notificationsState = { ...state.messages };
                const existingLists = { ...notificationsState.lists };

                let pagination = {};
                let searchParams = {};
                if (isObjectNotEmpty(existingLists[listName])) {
                    pagination = { ...existingLists[listName].pagination };
                    searchParams = { ...existingLists[listName].searchParams };
                }

                if (isObjectNotEmpty(payload)) {
                    searchParams = { ...payload };
                }

                dispatch(clearNotificationList(listName));
                dispatch(fetchNotificationListStart(listName));

                const res = await Data.getLoadNotifications({ ...searchParams });
                dispatch(fetchNotificationListSuccess(listName, { records: res.data, params: { searchParams: res.searchParams, pagination: res.pagination } }));
            } catch (error) {
                logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
                dispatch(fetchNotificationListFail(listName, { error: ErrorUtils.getErrorMessage(error) }));
            }
        }
    }
};

export const fetchMoreNotificationList = (listName, payload) => {
    return async (dispatch, getState) => {
        if (isStringNotEmpty(listName)) {
            try {
                dispatch(fetchNotificationListStart(listName));

                const state = getState();
                const notificationsState = { ...state.notifications };
                const existingLists = { ...notificationsState.lists };

                let pagination = {};
                let searchParams = {};
                let updatedNotifications = [];
                if (isObjectNotEmpty(existingLists[listName])) {
                    pagination = { ...existingLists[listName].pagination };
                    searchParams = { ...existingLists[listName].searchParams };
                    updatedNotifications = [...existingLists[listName].records];
                }

                if (isObjectNotEmpty(payload)) {
                    searchParams = { ...payload };
                }

                const res = await Data.getMoreLoadNotifications({ ...searchParams }, pagination, updatedNotifications);
                dispatch(fetchNotificationListSuccess(listName, { records: res.data, params: { searchParams: res.searchParams, pagination: res.pagination } }));
            } catch (error) {
                logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
                dispatch(fetchNotificationListFail(listName, { error: ErrorUtils.getErrorMessage(error) }));
            }
        }
    }
};

export const sendNotification = (subject, message, userId, entityType, entityId, loadId, loadIrisId, loadStatus, eventType, toStaff = false) => {
    return async (dispatch) => {
        try {
            const newLoadNotification = await Data.addLoadNotification(subject, message, userId, entityType, entityId, loadId, loadIrisId, loadStatus, eventType, toStaff);
            if (isObjectNotEmpty(newLoadNotification)) {
                dispatch(addNotificationToNotificationList(loadId, newLoadNotification));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
        }
    }
};

export const addNotification = (payload) => {
    return async (dispatch) => {
        dispatch(addNotificationToNotificationList(payload.loadId, payload));
    }
};

export const updateNotification = (id, payload) => {
    return async (dispatch) => {
        try {
            dispatch(updateNotificationStart());

            const updatedLoadNotification = await Data.updateLoadNotification(id, payload);
            if (isObjectNotEmpty(updatedLoadNotification)) {
                dispatch(updateNotificationInNotificationList(updatedLoadNotification.loadId, updatedLoadNotification));
                dispatch(changeSingleNotification(updatedLoadNotification));
            }

            dispatch(updateNotificationSuccess());
            dispatch(updateNotificationErrorClear());
            dispatch(updateNotificationLoadingClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateNotificationFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const readNotification = (id, userId) => {
    return async (dispatch) => {
        try {
            dispatch(updateNotificationStart());

            const updatedLoadNotification = await Data.markLoadNotificationAsRead(id, userId);
            if (isObjectNotEmpty(updatedLoadNotification)) {
                dispatch(updateNotificationInNotificationList(updatedLoadNotification.loadId, updatedLoadNotification));
                dispatch(changeSingleNotification(updatedLoadNotification));
            }

            dispatch(updateNotificationSuccess());
            dispatch(updateNotificationErrorClear());
            dispatch(updateNotificationLoadingClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateNotificationFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const readMultipleNotifications = (ids, userId) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updateNotificationStart());

            const updatedLoadNotifications = await Data.markMultipleLoadNotificationsAsRead(ids);
            if (updatedLoadNotifications === true) {
                const state = getState();
                const notificationsState = { ...state.notifications };
                const existingNotifications = selectListRecords({ ...notificationsState.lists }, "ALL");

                let readRecord = { readAt: Date.now().toString(), readBy: userId };
                ids.forEach((id) => {
                    let updatedLoadNotification = existingNotifications.find(i => i.id === id);
                    if (isObjectNotEmpty(updatedLoadNotification)) {
                        if (!updatedLoadNotification.read.some(item => { return item.readBy === userId })) {
                            updatedLoadNotification.read.push(readRecord);

                            dispatch(updateNotificationInNotificationList(updatedLoadNotification.loadId, updatedLoadNotification));
                            dispatch(changeSingleNotification(updatedLoadNotification));
                        }
                    }
                });

                dispatch(updateNotificationSuccess());
                dispatch(updateNotificationErrorClear());
                dispatch(updateNotificationLoadingClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateNotificationFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion