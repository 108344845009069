import React from 'react';
import DataTable from '../DataTable/DataTable';

const Queue = ({ dataSource, columns, loading = false, pagination, onChange, rowClassName, expandedRowComponents, ...props }) => {
    return (
        <DataTable
            dataSource={dataSource}
            columns={columns}
            loading={loading === true}
            pagination={pagination}
            onChange={onChange}
            rowClassName={rowClassName}
            expandedRowRender={expandedRowComponents}
            expandRowByClick={true}
            style={{ backgroundColor: '#ffffff' }}
            rowKey={record => record.id}
        >
            {props.children}
        </DataTable>
    );
};

export default Queue;