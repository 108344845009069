import React from 'react';
import { Card } from "antd";
import classes from './ComplexDataLinkCard.module.scss';
import StringFormatter from '../../shared/stringFormatter';
import { isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { withRouter } from 'react-router';

const stringFormatter = new StringFormatter();

const ComplexDataLinkCard = ({ data = {}, title, style = null, headStyle = null, titleStyle = null, valueStyle = null, extra = null, children, currentBreadcrumbName = "Dashboard", ...props }) => {
    const goToUrl = (path) => {
        props.history.push(path, {
            previousPageTitle: currentBreadcrumbName,
            previousPageLocation: props.location,
            previousBreadcrumbs: props.breadcrumbs,
        });
    };
    
    const createDataItems = () => {
        let dataItems = [];
        const entries = Object.entries(data);

        for (const [key, item] of entries) {
            if (isStringNotEmpty(item.url) && isStringNotEmpty(item.value)) {
                dataItems.push(
                    <div key={`data-link-${key}`}>
                        {isStringNotEmpty(key) ? (<span style={isObjectNotEmpty(titleStyle) ? { fontWeight: 'bold', ...titleStyle } : { fontWeight: 'bold' }}>{key}: </span>) : null}
                        <span style={valueStyle}><a onClick={() => { goToUrl(item.url); }} style={{ textDecoration: 'underline' }}>{stringFormatter.toFormattedString(item.dataType, item.value, isStringNotEmpty(item.units) ? item.units : null, isStringNotEmpty(item.timeZone) ? item.timeZone : null)}</a></span>
                    </div>
                );
            } else if (isStringNotEmpty(item.onClick) && isStringNotEmpty(item.value)) {
                dataItems.push(
                    <div key={`data-link-${key}`}>
                        {isStringNotEmpty(key) ? (<span style={isObjectNotEmpty(titleStyle) ? { fontWeight: 'bold', ...titleStyle } : { fontWeight: 'bold' }}>{key}: </span>) : null}
                        <span style={valueStyle}><a onClick={item.onClick} style={{ textDecoration: 'underline' }}>{stringFormatter.toFormattedString(item.dataType, item.value, isStringNotEmpty(item.units) ? item.units : null, isStringNotEmpty(item.timeZone) ? item.timeZone : null)}</a></span>
                    </div>
                );
            } else if (isStringNotEmpty(item.value)) {
                dataItems.push(
                    <div key={`data-link-${key}`}>
                        {isStringNotEmpty(key) ? (<span style={isObjectNotEmpty(titleStyle) ? { ...titleStyle } : { fontWeight: 'bold' }}>{key}: </span>) : null}
                        <span style={valueStyle}>{stringFormatter.toFormattedString(item.dataType, item.value, isStringNotEmpty(item.units) ? item.units : null, isStringNotEmpty(item.timeZone) ? item.timeZone : null)}</span>
                    </div>
                );
            }
        }

        return dataItems;
    };

    return (
        <Card bordered={true} title={title} className={classes.card} style={style} headStyle={headStyle} extra={extra}>
            {createDataItems()}
            {children}
        </Card>
    );
};

export default withRouter(ComplexDataLinkCard);