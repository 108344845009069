import axiosAuthenticated from "../../api/axiosAuthenticated";
import * as DTO from "../dtos/index";
import { isListNotEmpty, isNotNullOrUndefined, isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";

const loadMessagesPath = '/loadMessages';

//#region Load Messages Methods

export const getLoadMessages = async (searchParams = {}, pagination = {}) => {
    const loadMessagesRes = await axiosAuthenticated.get(loadMessagesPath, { params: { ...searchParams } });
    if (loadMessagesRes && loadMessagesRes.status === 200) {
        const loadMessages = loadMessagesRes.data.data;
        const otherData = loadMessagesRes.data;

        const transformedLoadMessages = loadMessages.map((loadMessage) => { return DTO.getLoadMessageDTO(loadMessage); });

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;

        return { data: transformedLoadMessages, searchParams: searchParams, pagination: pagination };
    }

    return { data: [], searchParams: searchParams, pagination: pagination };
};

export const getMoreLoadMessages = async (searchParams = {}, pagination = {}, existingRecords = []) => {
    let updatedLoadMessages = [];
    if (isListNotEmpty(existingRecords)) {
        updatedLoadMessages = [...existingRecords];
    }

    const loadMessagesRes = await axiosAuthenticated.get(loadMessagesPath, { params: { ...searchParams } });
    if (loadMessagesRes && loadMessagesRes.status === 200) {
        const loadMessages = loadMessagesRes.data.data;
        const otherData = loadMessagesRes.data;

        const transformedLoadMessages = loadMessages.map((loadMessage) => { return DTO.getLoadMessageDTO(loadMessage); });

        transformedLoadMessages.forEach((loadMessage) => {
            if (updatedLoadMessages.find(lm => lm.id === loadMessage.id) === undefined) {
                updatedLoadMessages.push(loadMessage);
            }
        });

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;
    }

    return { data: updatedLoadMessages, searchParams: searchParams, pagination: pagination };
};

export const addLoadMessage = async (payload, senderUserId, load) => {
    if (isObjectNotEmpty(load) && isStringNotEmpty(load.id) && isStringNotEmpty(senderUserId)) {
        let accountIds = [];
        let userIds = [];
        if (isStringNotEmpty(load.assignedCarrierId)) {
            accountIds.push(load.assignedCarrierId);
        }
        if (isStringNotEmpty(load.shipperId)) {
            accountIds.push(load.shipperId);
        }
        if (isListNotEmpty(load.drivers)) {
            load.drivers.forEach((driver) => {
                userIds.push(driver.userId);
            });
        }
        if (isListNotEmpty(load.assignedAccountRepUsers)) {
            load.assignedAccountRepUsers.forEach((assignedAccountRepUser) => {
                userIds.push(assignedAccountRepUser.user_id);
            });
        }

        let newLoadMessageObj = {
            message: payload.text,
            senderId: senderUserId,
            loadId: load.id,
            loadIrisId: load.irisId,
            accountIds: accountIds,
            userIds: userIds
        };

        const loadMessagesRes = await axiosAuthenticated.post(loadMessagesPath, newLoadMessageObj);
        if (loadMessagesRes && loadMessagesRes.status === '201') {
            let newLoadMessage = loadMessagesRes.data;

            return DTO.getLoadMessageDTO(newLoadMessage);
        }
    }

    return null;
};

export const addLoadMessages = async (newLoadMessagesArray = [], senderUserId, load) => {
    let newLoadMessages = [];
    if (isListNotEmpty(newLoadMessagesArray) && isObjectNotEmpty(load) && isStringNotEmpty(load.id) && isStringNotEmpty(senderUserId)) {
        let accountIds = [];
        let userIds = [];
        if (isStringNotEmpty(load.assignedCarrierId)) {
            accountIds.push(load.assignedCarrierId);
        }
        if (isStringNotEmpty(load.shipperId)) {
            accountIds.push(load.shipperId);
        }
        if (isListNotEmpty(load.drivers)) {
            load.drivers.forEach((driver) => {
                userIds.push(driver.userId);
            });
        }
        if (isListNotEmpty(load.assignedAccountRepUsers)) {
            load.assignedAccountRepUsers.forEach((assignedAccountRepUser) => {
                userIds.push(assignedAccountRepUser.user_id);
            });
        }

        for (let i = 0; i < newLoadMessagesArray.length; i++) {
            let newLoadMessageObj = {
                message: newLoadMessagesArray[i].text,
                senderId: senderUserId,
                loadId: load.id,
                loadIrisId: load.irisId,
                accountIds: accountIds,
                userIds: userIds
            };

            const loadMessagesRes = await axiosAuthenticated.post(loadMessagesPath, newLoadMessageObj);
            if (loadMessagesRes && loadMessagesRes.status === '201') {
                let newLoadMessage = loadMessagesRes.data;

                newLoadMessages.push(DTO.getLoadMessageDTO(newLoadMessage));
            }
        }
    }

    return newLoadMessages;
};

export const updateLoadMessage = async (id, payload) => {
    const loadMessagesRes = await axiosAuthenticated.put(loadMessagesPath + `/${id}`, { ...payload });
    if (loadMessagesRes && loadMessagesRes.status === 200) {
        let updatedLoadMessage = loadMessagesRes.data;

        return DTO.getLoadMessageDTO(updatedLoadMessage);
    }

    return null;
};

export const markLoadMessageAsRead = async (id, userId) => {
    let readRecord = { readAt: Date.now().toString(), readBy: userId };

    const loadMessagesRes = await axiosAuthenticated.put(loadMessagesPath + `/${id}`, { 'add_read': [readRecord] });
    if (loadMessagesRes && loadMessagesRes.status === 200) {
        let updatedLoadMessage = loadMessagesRes.data;

        return DTO.getLoadMessageDTO(updatedLoadMessage);
    }

    return null;
};

export const markMultipleLoadMessagesAsRead = async (ids, userId) => {
    let updatedLoadMessages = [];
    if (isListNotEmpty(ids)) {
        let readRecord = { readAt: Date.now().toString(), readBy: userId };

        for (let i = 0; i < ids.length; i++) {
            const loadMessagesRes = await axiosAuthenticated.put(loadMessagesPath + `/${ids[i]}`, { 'add_read': [readRecord] });
            if (loadMessagesRes && loadMessagesRes.status === 200) {
                let updatedLoadMessage = loadMessagesRes.data;

                updatedLoadMessages.push(DTO.getLoadMessageDTO(updatedLoadMessage));
            }
        }
    }

    return updatedLoadMessages;
};

//#endregion