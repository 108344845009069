import * as actionTypes from "../actions/actionTypes";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { isBooleanTrue, isObjectNotEmpty } from "../../shared/objectUtils";
import ErrorUtils from "../../shared/errorUtils";

//#region Fetch Contents Methods

export const fetchContentsStart = () => {
    return {
        type: actionTypes.FETCH_CONTENTS_START
    }
};

export const fetchContentsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_CONTENTS_SUCCESS,
        payload: payload
    }
};

export const fetchContentsFail = (payload) => {
    return {
        type: actionTypes.FETCH_CONTENTS_FAIL,
        payload: payload
    }
};

export const clearContents = () => {
    return {
        type: actionTypes.CLEAR_CONTENTS
    }
};

//#endregion
//#region Add Content Methods

const insertContent = (payload) => {
    return {
        type: actionTypes.ADD_CONTENT,
        payload: payload
    }
};

export const addContentStart = () => {
    return {
        type: actionTypes.ADD_CONTENT_START
    }
};

export const addContentSuccess = () => {
    return {
        type: actionTypes.ADD_CONTENT_SUCCESS
    }
};

export const addContentFail = (payload) => {
    return {
        type: actionTypes.ADD_CONTENT_FAIL,
        payload: payload
    }
};

export const addContentLoadingClear = () => {
    return {
        type: actionTypes.ADD_CONTENT_LOADING_CLEAR
    }
};

export const addContentErrorClear = () => {
    return {
        type: actionTypes.ADD_CONTENT_ERROR_CLEAR
    }
};

export const addContentCancel = () => {
    return {
        type: actionTypes.ADD_CONTENT_CANCEL
    }
};

//#endregion
//#region Update Content Methods

const changeContent = (payload) => {
    return {
        type: actionTypes.UPDATE_CONTENT,
        payload: payload
    }
};

const changeSingleContent = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_CONTENT,
        payload: payload
    }
};

export const updateContentStart = () => {
    return {
        type: actionTypes.UPDATE_CONTENT_START
    }
};

export const updateContentSuccess = () => {
    return {
        type: actionTypes.UPDATE_CONTENT_SUCCESS
    }
};

export const updateContentFail = (payload) => {
    return {
        type: actionTypes.UPDATE_CONTENT_FAIL,
        payload: payload
    }
};

export const updateContentLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_CONTENT_LOADING_CLEAR
    }
};

export const updateContentErrorClear = () => {
    return {
        type: actionTypes.UPDATE_CONTENT_ERROR_CLEAR
    }
};

export const updateContentCancel = () => {
    return {
        type: actionTypes.UPDATE_CONTENT_CANCEL
    }
};

//#endregion
//#region Remove Content Methods

const removeContent = (payload) => {
    return {
        type: actionTypes.REMOVE_CONTENT,
        payload: payload
    }
};

//#endregion
//#region Contents Methods

export const fetchContents = (payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(fetchContentsStart());

            const state = getState();
            const contentsState = { ...state.contents };
            let pagination = { ...contentsState.pagination };
            let searchParams = { ...contentsState.searchParams };

            if (isObjectNotEmpty(payload)) {
                searchParams = { ...payload };
            }

            const res = await Data.getContents({ ...searchParams }, pagination);
            dispatch(fetchContentsSuccess({ records: res.data, searchParams: res.searchParams, pagination: res.pagination }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchContentsFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const addContent = (payload) => {
    return async (dispatch) => {
        try {
            dispatch(addContentStart());

            const newContent = await Data.addContent(payload);
            if (isObjectNotEmpty(newContent)) {
                dispatch(insertContent(newContent));
            }

            dispatch(addContentSuccess());
            dispatch(addContentLoadingClear());
            dispatch(addContentErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addContentFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateContent = (id, payload) => {
    return async (dispatch) => {
        try {
            dispatch(updateContentStart());

            if (isBooleanTrue(payload.isDeleted)) {
                const removedContent = await Data.removeContent(id);
                if (isObjectNotEmpty(removedContent)) {
                    dispatch(removeContent(removedContent));
                }
            } else {
                const updatedContent = await Data.updateContent(id, payload);
                if (isObjectNotEmpty(updatedContent)) {
                    dispatch(changeContent(updatedContent));
                    dispatch(changeSingleContent(updatedContent));
                }
            }

            dispatch(updateContentSuccess());
            dispatch(updateContentLoadingClear());
            dispatch(updateContentErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateContentFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion