import React, { useState, useMemo, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Header from '../../components/Header/Header';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedoAlt, faExternalLinkAlt, faPallet, faRoute, faDollarSign, faTruckLoading, faTruck } from '@fortawesome/free-solid-svg-icons';
import { Col, Modal, Row, Dropdown, Button, Menu, Spin, Card, Alert, Switch, Popover } from 'antd';
import { CloseOutlined, DownOutlined, InfoCircleOutlined } from '@ant-design/icons';
import TitleBlockWithIcon from '../../components/TitleBlockWithIcon/TitleBlockWithIcon';
import StringFormatter from '../../shared/stringFormatter';
import DataRow from '../../components/DataRow/DataRow';
import DataPhoneNumberRow from '../../components/DataPhoneNumberRow/DataPhoneNumberRow';
import DataContactInfoRow from '../../components/DataContactInfoRow/DataContactInfoRow';
import DataTemperatureRangeRow from '../../components/DataTemperatureRangeRow/DataTemperatureRangeRow';
import DataListRow from '../../components/DataListRow/DataListRow';
import DataAtByRow from '../../components/DataAtByRow/DataAtByRow';
import pickUpMarker from '../../assets/img/pickUpMarker.png';
import dropOffMarker from '../../assets/img/dropOffMarker.png';
import Enums from '../../shared/enums';
import LoadMap from "../../components/LoadMap/LoadMap";
import classes from './LoadBundle.module.scss';
import LoadDocuments from '../../components/LoadDocuments/LoadDocuments';
import DispatchDriverCard from '../../components/DispatchDriverCard/DispatchDriverCard';
import moment from 'moment';
import DispatchAssetCard from '../../components/DispatchAssetCard/DispatchAssetCard';
import DispatchScheduleLoadCard from '../../components/DispatchScheduleLoadCard/DispatchScheduleLoadCard';
import DataTable from '../../components/DataTable/DataTable';
import DispatchSelectedLoadCard from '../../components/DispatchSelectedLoadCard/DispatchSelectedLoadCard';
import FormItem from '../../components/FormItem/FormItem';
import Form from '../../components/Form/Form';
import { useForm, FormProvider } from 'react-hook-form';
import FormButtons from '../../components/FormButtons/FormButtons';
import * as Data from '../../api/data';
import { equalsIgnoreOrder, isBooleanTrue, isListEmpty, isListNotEmpty, isNotNullOrUndefined, isNumberEmpty, isNumberGreaterThanZero, isNumberNotEmpty, isObjectNotEmpty, isStringEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import logger from '../../shared/logger';
import LoadStopUtils from '../../api/utils/loadStopUtils';
import LoadUtils from '../../api/utils/loadUtils';
import { selectListIsLoading, selectListRecords } from '../../store/utility';
import MomentDate from '../../shared/dateFormatter';
import { Can } from '../../shared/entitlements/entitlements';

const stringFormatter = new StringFormatter();
const momentDate = new MomentDate();

const LoadBundle = (props) => {
    //#region props and constants

    const loadId = props.match.params.loadId;

    //#endregion
    //#region useForms

    const methods1 = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const methods2 = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useStates

    const [carrierId, setCarrierId] = useState(null);
    const [showDocumentsModal, setShowDocumentsModal] = useState(false);
    const [loadsToDispatch, setLoadsToDispatch] = useState([]);
    const [driverLoads, setDriverLoads] = useState(null);
    const [isLoadingDriverLoads, setIsLoadingDriverLoads] = useState(false);
    const [assetLoads, setAssetLoads] = useState(null);
    const [isLoadingAssetLoads, setIsLoadingAssetLoads] = useState(false);
    const [scheduledShifts, setScheduledShifts] = useState(null);
    const [isLoadingScheduledShifts, setIsLoadingScheduledShifts] = useState(false);
    const [driverSideBar, setDriverSideBar] = useState(null);
    const [assetSideBar, setAssetSideBar] = useState(null);
    const [selectedLoadToDispatchId, setSelectedLoadToDispatchId] = useState(null);
    const [showDispatchLoadModal, setShowDispatchLoadModal] = useState(false);
    const [overrideScheduled, setOverrideScheduled] = useState(false);
    const [showCancelLoadModal, setShowCancelLoadModal] = useState(false);
    const [allLoads, setAllLoads] = useState(null);
    const [transportUnits, setTransportUnits] = useState([]);

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);
    const isAdmin = useSelector(state => state.auth.isAdmin);
    const isLoading = useSelector(state => state.loads.isRecordLoading);
    const error = useSelector(state => state.loads.recordError);
    const load = useSelector(state => state.loads.record);
    const stops = useSelector(state => selectListRecords(state.loadStops.lists, loadId));
    const isLoadingStops = useSelector(state => selectListIsLoading(state.loadStops.lists, loadId));
    const drivers = useSelector(state => selectListRecords(state.orchestrator.drivers, carrierId));
    const assets = useSelector(state => selectListRecords(state.orchestrator.assets, carrierId));
    const shifts = useSelector(state => selectListRecords(state.shifts.lists, carrierId));
    const isLoadingShifts = useSelector(state => selectListIsLoading(state.shifts.lists, carrierId));
    const accounts = useSelector(state => state.orchestrator.accounts);
    const isLoadingLoadUpdate = useSelector(state => state.loads.isRecordUpdateLoading);
    const errorLoadUpdate = useSelector(state => state.loads.updateRecordError);
    const loadEventUpdateStatus = useSelector(state => state.loadEvents.loadEventUpdateStatus);
    const bundledLoads = useSelector(state => state.loads.bundledLoads);
    const isLoadingBundledLoads = useSelector(state => state.loads.isBundledLoadsLoading);

    //#endregion
    //#region toggles

    const toggleDocumentsModal = () => {
        setShowDocumentsModal(!showDocumentsModal);
    };

    //#endregion
    //#region submits and cancels

    const onDispatchLoad = () => {
        if (isStringNotEmpty(selectedLoadToDispatchId) && isListNotEmpty(loadsToDispatch)) {
            let selectedLoadToDispatch = loadsToDispatch.find(i => i.id === selectedLoadToDispatchId);
            if (isObjectNotEmpty(selectedLoadToDispatch)) {
                dispatch(actionCreators.dispatchBundledLoad(selectedLoadToDispatch));
            }
        } else if (isObjectNotEmpty(load)) {
            dispatch(actionCreators.sendLoadEvent({
                loadId: load.id,
                eventType: 'LOAD_SCHEDULED'
            }));
        }
    };

    const onCancelLoad = () => {
        // console.log(selectedLoadToDispatchId)
        // console.log(bundledLoads)
        if (isStringNotEmpty(selectedLoadToDispatchId) && isListNotEmpty(bundledLoads)) {
            let selectedLoadToDispatch = bundledLoads.find(i => i.id === selectedLoadToDispatchId);
            let parentLoad = bundledLoads.find(i => i.isParentLoad === true);
            // console.log(selectedLoadToDispatch)
            // console.log(parentLoad)
            if (isObjectNotEmpty(selectedLoadToDispatch) && isObjectNotEmpty(parentLoad)) {
                dispatch(actionCreators.deleteBundledLoad(selectedLoadToDispatch.id, selectedLoadToDispatch, parentLoad.id, parentLoad));
            }
        } else if (isObjectNotEmpty(load)) {
            dispatch(actionCreators.deleteLoad(load.id, load));
        }
    };

    const onDoneDispatchLoad = () => {
        dispatch(actionCreators.updateLoadCancel());
        setShowDispatchLoadModal(false);
        setSelectedLoadToDispatchId(null);
    };

    const onCancelDispatchLoad = () => {
        dispatch(actionCreators.updateLoadCancel());
        setShowDispatchLoadModal(false);
    };

    const onDoneCancelLoad = () => {
        dispatch(actionCreators.updateLoadCancel());
        setShowCancelLoadModal(false);
        setSelectedLoadToDispatchId(null);
    };

    const onCancelCancelLoad = () => {
        dispatch(actionCreators.updateLoadCancel());
        setShowCancelLoadModal(false);
        setSelectedLoadToDispatchId(null);
    };

    //#endregion
    //#region load methods

    const getDriverLoads = () => {
        if (isListNotEmpty(drivers) && isStringNotEmpty(carrierId)) {
            let driverIds = drivers.map((driver) => { return driver.id });
            let searchParams = {
                page: 1,
                size: 1000000,
                isDeleted: false,
                eta: false,
                driverIds: driverIds,
                assignedCarrierId: carrierId,
                loadStatus: ['CREATED', 'PENDING', 'APPROVED', 'PENDING_RATE_CONFIRMATION', 'BOOKED', 'SCHEDULED', 'IN_TRANSIT', 'AT_STOP']
            };
            setIsLoadingDriverLoads(true);
            Data.getLoadsWithIncludes({ ...searchParams }).then((loadsRes) => {
                setDriverLoads(loadsRes.data ? loadsRes.data : []);
                setIsLoadingDriverLoads(false);
            }).catch((err) => {
                logger.logDebugEvent('LoadBundle.js', err.message, true);
                setDriverLoads(null);
                setIsLoadingDriverLoads(false);
            });
        } else {
            setDriverLoads([]);
        }
    };

    const getAssetsLoads = () => {
        if (isListNotEmpty(assets) && isStringNotEmpty(carrierId)) {
            let assetIds = assets.map((asset) => { return asset.id });
            let searchParams = {
                page: 1,
                size: 1000000,
                isDeleted: false,
                eta: false,
                assignedCarrierId: carrierId,
                assetIds: assetIds,
                loadStatus: ['CREATED', 'PENDING', 'APPROVED', 'PENDING_RATE_CONFIRMATION', 'BOOKED', 'SCHEDULED', 'IN_TRANSIT', 'AT_STOP']
            };

            setIsLoadingAssetLoads(true);
            Data.getLoadsWithIncludes({ ...searchParams }).then((loadsRes) => {
                setAssetLoads(loadsRes.data ? loadsRes.data : []);
                setIsLoadingAssetLoads(false);
            }).catch((err) => {
                logger.logDebugEvent('Dispatch.js', err.message, true);
                setAssetLoads(null);
                setIsLoadingAssetLoads(false);
            });
        } else {
            setAssetLoads([]);
        }
    };

    const getScheduledShifts = (selectedLoad, selectedCarrierId) => {
        if (isObjectNotEmpty(selectedLoad) && isStringNotEmpty(selectedCarrierId)) {
            let searchParams = {
                page: 1,
                size: 1000000,
                sort: 'startDateTime',
                order: 'asc',
                accountId: selectedCarrierId,
                isDeleted: false
            };

            if (isObjectNotEmpty(selectedLoad.origin) && isObjectNotEmpty(selectedLoad.destination)) {
                // console.log(selectedLoad)
                let { startDateTime } = LoadStopUtils.getStopStartDateTime(selectedLoad.origin);
                let { endDateTime } = LoadStopUtils.getStopEndDateTime(selectedLoad.destination);
                if (isObjectNotEmpty(startDateTime) && isObjectNotEmpty(endDateTime)) {
                    let startDate = momentDate.toUtcISOString(startDateTime.date);
                    let endDate = momentDate.toUtcISOString(endDateTime.date);

                    searchParams["startDateTime:between:or"] = [startDate, endDate];
                    searchParams["endDateTime:between:or"] = [startDate, endDate];
                }
            }

            setIsLoadingScheduledShifts(true);

            Data.getScheduledShifts({ ...searchParams }).then((scheduledShiftsRes) => {
                setScheduledShifts(scheduledShiftsRes.data ? scheduledShiftsRes.data : []);
                setIsLoadingScheduledShifts(false);
            }).catch((err) => {
                logger.logDebugEvent('LoadBundle.js', err.message, true);
                setScheduledShifts(null);
                setIsLoadingScheduledShifts(false);
            });
        } else {
            setScheduledShifts([]);
        }
    };

    const refreshLoad = () => {
        if (isStringNotEmpty(loadId)) {
            dispatch(actionCreators.fetchLoadWithIncludes(loadId));
        }
    };

    //#endregion
    //#region document methods

    const getDocuments = () => {
        if (isObjectNotEmpty(load) && isListNotEmpty(stops)) {
            let ids = [];
            ids.push(load.id);

            stops.forEach((stop) => {
                ids.push(stop.id);
            });

            dispatch(actionCreators.fetchBulkDocumentLists(load.id, ids));
        }
    };

    //#endregion
    //#region useMemos and useEffects

    useMemo(() => {
        dispatch(actionCreators.clearBundledLoads()); // reset bundled loads
        dispatch(actionCreators.clearLoad()); // reset load
        setSelectedLoadToDispatchId(null); // reset selected load
        setScheduledShifts(null); // reset scheduled shifts
        setDriverSideBar(null); // reset side bar
        setAssetSideBar(null); // reset side bar
        dispatch(actionCreators.getAccounts());
    }, []);

    useMemo(() => {
        if (isStringNotEmpty(loadId)) {
            dispatch(actionCreators.fetchLoadWithIncludes(loadId));
        }
    }, [loadId]);

    useMemo(() => {
        if (isObjectNotEmpty(load) && isStringNotEmpty(load.id)) {
            dispatch(actionCreators.fetchLoadStopList(load.id, load));
            setCarrierId(LoadUtils.getCarrierId(load));
        }
    }, [load]);

    useMemo(() => {
        if (isStringNotEmpty(loadId) && isStringNotEmpty(carrierId)) {
            dispatch(actionCreators.fetchBundledLoadsWithIncludes(loadId, carrierId));
        }
    }, [loadId, carrierId]);

    useMemo(() => {
        if (isStringNotEmpty(carrierId)) {
            dispatch(actionCreators.getDrivers(carrierId, false));
            dispatch(actionCreators.getAssets(carrierId, false));
            dispatch(actionCreators.fetchShiftList(carrierId, { page: 1, size: 1000000, accountId: carrierId, isDeleted: false }));
        }
    }, [carrierId]);

    useEffect(() => {
        getDriverLoads();
    }, [drivers, carrierId]);

    useEffect(() => {
        getAssetsLoads();
    }, [assets, carrierId]);

    useEffect(() => {
        if (isObjectNotEmpty(load) && isStringEmpty(load.parentLoadId) && isListNotEmpty(stops)) {
            getDocuments();
        }
    }, [load, stops]);

    useEffect(() => {
        getScheduledShifts(load, carrierId);
    }, [load, carrierId]);

    useEffect(() => {
        // console.log(bundledLoads);
        // console.log(driverLoads);
        // console.log(assetLoads);
        // console.log(scheduledShifts);
        // just check if driverLoads and scheduledShifts are not null nor undefined since they could be empty lists
        if (isListNotEmpty(bundledLoads) && isNotNullOrUndefined(driverLoads) && isNotNullOrUndefined(assetLoads) && isNotNullOrUndefined(scheduledShifts)) {
            let loadsToDispatchArray = [];

            bundledLoads.forEach((bundledLoad, index) => {
                let bundledLoadDriverScheduledShifts = [];
                let bundledLoadDriverBundledLoads = [];
                let bundledLoadDriverOtherLoads = [];
                let bundledLoadAssetBundledLoads = [];
                let bundledLoadAssetOtherLoads = [];
                if (isListNotEmpty(bundledLoad.driverIds)) {
                    bundledLoad.driverIds.forEach((driverId) => {
                        if (isListNotEmpty(scheduledShifts)) {
                            scheduledShifts.forEach((driverScheduledShift) => {
                                if (isListNotEmpty(driverScheduledShift.accountUserIds) && driverScheduledShift.accountUserIds.includes(driverId)) {
                                    if (bundledLoadDriverScheduledShifts.find(i => i.id === driverScheduledShift.id) === undefined) {
                                        bundledLoadDriverScheduledShifts.push(driverScheduledShift);
                                    }
                                }
                            });
                        }
                        bundledLoads.forEach((driverBundledLoad, loadIndex) => {
                            if (driverBundledLoad.isDeleted === false && LoadUtils.loadStatusIsNot(driverBundledLoad.loadStatus, ['CANCELLED']) && isListNotEmpty(driverBundledLoad.driverIds) && driverBundledLoad.driverIds.includes(driverId)) {
                                driverBundledLoad.loadName = isStringNotEmpty(driverBundledLoad.parentName) ? driverBundledLoad.parentName : (isStringNotEmpty(driverBundledLoad.name) ? driverBundledLoad.name + ' (' + (loadIndex + 1) + ')' : driverBundledLoad.irisId);
                                driverBundledLoad.loadNumber = loadIndex + 1;
                                if (bundledLoadDriverBundledLoads.find(i => i.id === driverBundledLoad.id) === undefined) {
                                    bundledLoadDriverBundledLoads.push(driverBundledLoad);
                                }
                            }
                        });
                        if (isListNotEmpty(driverLoads)) {
                            driverLoads.forEach((driverOtherLoad) => {
                                if (driverOtherLoad.isDeleted === false && LoadUtils.loadStatusIsNot(driverOtherLoad.loadStatus, ['CANCELLED']) && isListNotEmpty(driverOtherLoad.driverIds) && driverOtherLoad.driverIds.includes(driverId)) {
                                    if (bundledLoadDriverOtherLoads.find(i => i.id === driverOtherLoad.id) === undefined) {
                                        bundledLoadDriverOtherLoads.push(driverOtherLoad);
                                    }
                                }
                            });
                        }
                    });
                }
                if (isListNotEmpty(bundledLoad.assetIds)) {
                    bundledLoad.assetIds.forEach((assetId) => {
                        bundledLoads.forEach((assetBundledLoad, loadIndex) => {
                            if (assetBundledLoad.isDeleted === false && LoadUtils.loadStatusIsNot(assetBundledLoad.loadStatus, ['CANCELLED']) && isListNotEmpty(assetBundledLoad.assetIds) && assetBundledLoad.assetIds.includes(assetId)) {
                                assetBundledLoad.loadName = isStringNotEmpty(assetBundledLoad.parentName) ? assetBundledLoad.parentName : (isStringNotEmpty(assetBundledLoad.name) ? assetBundledLoad.name + ' (' + (loadIndex + 1) + ')' : assetBundledLoad.irisId);
                                assetBundledLoad.loadNumber = loadIndex + 1;
                                if (bundledLoadAssetBundledLoads.find(i => i.id === assetBundledLoad.id) === undefined) {
                                    bundledLoadAssetBundledLoads.push(assetBundledLoad);
                                }
                            }
                        });
                        if (isListNotEmpty(assetLoads)) {
                            assetLoads.forEach((assetOtherLoad) => {
                                if (assetOtherLoad.isDeleted === false && LoadUtils.loadStatusIsNot(assetOtherLoad.loadStatus, ['CANCELLED']) && isListNotEmpty(assetOtherLoad.assetIds) && assetOtherLoad.assetIds.includes(assetId)) {
                                    if (bundledLoadAssetOtherLoads.find(i => i.id === assetOtherLoad.id) === undefined) {
                                        bundledLoadAssetOtherLoads.push(assetOtherLoad);
                                    }
                                }
                            });
                        }
                    });
                }

                let dispatchStatus = 'PENDING';
                if (LoadUtils.loadStatusIs(bundledLoad.loadStatus, ['SCHEDULED', 'IN_TRANSIT', 'AT_STOP', 'COMPLETED', 'CLOSED', 'REOPENED'])) {
                    dispatchStatus = 'DISPATCHED';
                } else if (LoadUtils.loadStatusIs(bundledLoad.loadStatus, ['CANCELLED'])) {
                    dispatchStatus = 'CANCELLED';
                }

                let loadNumber = index + 1;

                let hasTeamDriving = isListNotEmpty(bundledLoad.driverRequirements) && bundledLoad.driverRequirements.includes('TEAM_DRIVING') ? true : false;
                let hasDrivers = isListNotEmpty(bundledLoad.driverIds) && ((hasTeamDriving === true && bundledLoad.driverIds.length === 2) || (hasTeamDriving === false && bundledLoad.driverIds.length === 1)) ? true : false;
                let hasAssets = isListNotEmpty(bundledLoad.assetIds) ? true : false;

                loadsToDispatchArray.push({
                    ...bundledLoad,
                    hasTeamDriving: hasTeamDriving,
                    hasDrivers: hasDrivers,
                    hasAssets: hasAssets,
                    loadNumber: loadNumber,
                    loadName: isStringNotEmpty(bundledLoad.parentName) ? bundledLoad.parentName : (isStringNotEmpty(bundledLoad.name) ? bundledLoad.name + ' (' + loadNumber + ')' : bundledLoad.irisId),
                    dispatchStatus: dispatchStatus,
                    driverBundledLoads: bundledLoadDriverBundledLoads,
                    driverOtherLoads: bundledLoadDriverOtherLoads,
                    driverScheduledShifts: bundledLoadDriverScheduledShifts,
                    assetBundledLoads: bundledLoadAssetBundledLoads,
                    assetOtherLoads: bundledLoadAssetOtherLoads
                });
            });

            setLoadsToDispatch(loadsToDispatchArray);
            // console.log(loadsToDispatchArray);
        } else {
            setLoadsToDispatch([]);
        }
    }, [bundledLoads, driverLoads, scheduledShifts]);

    useEffect(() => {
        let loadsArray = [];
        if (isListNotEmpty(assetLoads)) {
            assetLoads.forEach((loadObj) => {
                if (loadsArray.find(i => i.id === loadObj.id) === undefined) {
                    loadsArray.push(loadObj);
                }
            });
        }

        if (isListNotEmpty(driverLoads)) {
            driverLoads.forEach((loadObj) => {
                if (loadsArray.find(i => i.id === loadObj.id) === undefined) {
                    loadsArray.push(loadObj);
                }
            });
        }

        if (isListNotEmpty(bundledLoads)) {
            bundledLoads.forEach((loadObj, loadIndex) => {
                loadObj.loadName = LoadUtils.getChildLoadName(loadObj, loadIndex);
                loadObj.loadNumber = loadIndex + 1;
                if (loadsArray.find(i => i.id === loadObj.id) === undefined) {
                    loadsArray.push(loadObj);
                }
            });
        }

        setAllLoads(loadsArray);
        // console.log(loadsArray);
    }, [driverLoads, assetLoads, bundledLoads]);

    useMemo(() => {
        // console.log(allLoads);
        // console.log(drivers);
        // console.log(assets);

        let transportUnitArray = [];
        if (isListNotEmpty(allLoads) && isListNotEmpty(drivers) && isListNotEmpty(assets)) {
            allLoads.filter(i => isListNotEmpty(i.driverIds) && isListNotEmpty(i.assetIds)).forEach((transportUnitLoad) => {
                let loadDriverIds = [];
                let loadAssetIds = [];
                transportUnitLoad.driverIds.forEach((driverId) => {
                    if (!loadDriverIds.includes(driverId)) {
                        loadDriverIds.push(driverId);
                    }
                });
                transportUnitLoad.assetIds.forEach((assetId) => {
                    if (!loadAssetIds.includes(assetId)) {
                        loadAssetIds.push(assetId);
                    }
                });

                // check transport unit array to see if there are already any transport units with the same drivers and assets, and only add if it doesn't already exist
                let existingTransportUnitIndex = transportUnitArray.findIndex(t => equalsIgnoreOrder(t.assetIds, loadAssetIds) && equalsIgnoreOrder(t.driverIds, loadDriverIds));
                if (existingTransportUnitIndex === -1) {
                    let transportUnitLoadDrivers = [];
                    let transportUnitLoadAssets = [];
                    loadDriverIds.forEach((driverId) => {
                        let transportUnitLoadDriver = drivers.find(i => i.id === driverId);
                        if (isObjectNotEmpty(transportUnitLoadDriver)) {
                            if (!transportUnitLoadDrivers.includes(transportUnitLoadDriver)) {
                                transportUnitLoadDrivers.push(transportUnitLoadDriver);
                            }
                        }
                    });
                    loadAssetIds.forEach((assetId) => {
                        let transportUnitLoadAsset = assets.find(i => i.id === assetId);
                        if (isObjectNotEmpty(transportUnitLoadAsset)) {
                            if (!transportUnitLoadAssets.includes(transportUnitLoadAsset)) {
                                transportUnitLoadAssets.push(transportUnitLoadAsset);
                            }
                        }
                    });

                    transportUnitArray.push({
                        driverIds: loadDriverIds,
                        drivers: transportUnitLoadDrivers,
                        assetIds: loadAssetIds,
                        assets: transportUnitLoadAssets,
                        loads: [transportUnitLoad],
                        loadIds: [transportUnitLoad.id]
                    });
                } else {
                    // update existing transport unit in array with additional load
                    let loadsArray = transportUnitArray[existingTransportUnitIndex].loads;
                    loadsArray.push(transportUnitLoad);
                    let loadIdsArray = transportUnitArray[existingTransportUnitIndex].loadIds;
                    loadIdsArray.push(transportUnitLoad.id);
                    transportUnitArray[existingTransportUnitIndex] = {
                        ...transportUnitArray[existingTransportUnitIndex],
                        loads: loadsArray,
                        loadIds: loadIdsArray
                    }
                }
            });
        }

        setTransportUnits(transportUnitArray);
        // console.log(transportUnitArray);
    }, [allLoads, drivers, assets]);

    useMemo(() => {
        if (showDispatchLoadModal === true && isLoadingLoadUpdate !== null && isLoadingLoadUpdate === false && errorLoadUpdate === null) {
            onDoneDispatchLoad();
        }
    }, [isLoadingLoadUpdate, errorLoadUpdate, showDispatchLoadModal]);

    useMemo(() => {
        if (showCancelLoadModal === true && isLoadingLoadUpdate !== null && isLoadingLoadUpdate === false && errorLoadUpdate === null) {
            onDoneCancelLoad();
        }
    }, [isLoadingLoadUpdate, errorLoadUpdate, showCancelLoadModal]);

    //#endregion
    //#region display components

    const documentsComponent = (
        <Modal
            title="Documents"
            visible={showDocumentsModal === true}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleDocumentsModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <LoadDocuments
                load={load}
                stops={stops}
            />
        </Modal>
    );

    const dispatchLoadComponents = () => {
        let loadObj = null
        if (isStringNotEmpty(selectedLoadToDispatchId) && isListNotEmpty(loadsToDispatch)) {
            let selectedLoadToDispatch = loadsToDispatch.find(i => i.id === selectedLoadToDispatchId);
            if (isObjectNotEmpty(selectedLoadToDispatch)) {
                loadObj = selectedLoadToDispatch;
            }
        } else if (isObjectNotEmpty(load)) {
            loadObj = load;
        }

        if (isObjectNotEmpty(loadObj)) {
            // let hasApprovedDriversCheck = false;
            let hasDriversCheck = false;
            let hasAssetsCheck = false;
            let hasActiveCarrierCheck = false;
            let hasVerifiedCarrierCheck = false;
            let hasTeamDriving = isListNotEmpty(loadObj.driverRequirements) && loadObj.driverRequirements.includes('TEAM_DRIVING') ? true : false;
            let loadName = isStringNotEmpty(loadObj.loadName) ? loadObj.loadName : (isStringNotEmpty(loadObj.parentName) ? loadObj.parentName : (isStringNotEmpty(loadObj.name) ? loadObj.name : loadObj.irisId));

            if (isListNotEmpty(loadObj.driverIds)) {
                // if team driving is selected then make sure there are 2 drivers assigned, otherwise make sure there is only 1 driver assigned to the load
                if ((hasTeamDriving === true && loadObj.driverIds.length === 2) || (hasTeamDriving === false && loadObj.driverIds.length === 1)) {
                    hasDriversCheck = true;

                    // if (isListNotEmpty(drivers)) {
                    //     // make sure all drivers assigned to the load are approved
                    //     let allDriversAreApproved = true;
                    //     loadObj.driverIds.forEach((loadDriverId) => {
                    //         let loadDriver = drivers.find(i => i.id === loadDriverId);
                    //         if (isObjectNotEmpty(loadDriver) && isObjectNotEmpty(loadDriver.driver)) {
                    //             if (loadDriver.driver.isApproved === false) {
                    //                 allDriversAreApproved = false;
                    //             }
                    //         } else {
                    //             allDriversAreApproved = false;
                    //         }
                    //     });
                    //     hasApprovedDriversCheck = allDriversAreApproved;
                    // }
                }
            }

            if (isListNotEmpty(loadObj.assetIds)) {
                hasAssetsCheck = true;
            }

            if (isListNotEmpty(accounts) && isStringNotEmpty(loadObj.assignedCarrierId)) {
                let loadCarrier = accounts.find(i => i.id === loadObj.assignedCarrierId);
                if (isObjectNotEmpty(loadCarrier)) {
                    // console.log(loadCarrier);
                    // make sure the carrier assigned to the load is active and verified
                    hasActiveCarrierCheck = isBooleanTrue(loadCarrier.isActive);
                    hasVerifiedCarrierCheck = isBooleanTrue(loadCarrier.isVerified);
                }
            }

            // console.log(loadObj);
            // console.log(hasDriversCheck)
            // console.log(hasApprovedDriversCheck)
            // console.log(hasAssetsCheck)
            // console.log(hasActiveCarrierCheck)
            // console.log(hasVerifiedCarrierCheck)

            return (
                <Modal
                    title={null}
                    visible={showDispatchLoadModal === true}
                    maskClosable={false}
                    closable={false}
                    footer={null}
                    bodyStyle={{ padding: 0 }}
                    destroyOnClose={true}
                >
                    <Card
                        title={"Confirm Dispatch"}
                        style={{ border: '1px solid #D8D8D8' }}
                        bodyStyle={{ minHeight: 100, padding: '12px 12px 57px 12px' }}
                        headStyle={{ backgroundColor: '#334353', color: '#ffffff', borderBottom: '1px solid #D8D8D8', fontSize: 14, textAlign: 'center' }}
                        extra={<Button type="link" icon={<CloseOutlined />} onClick={(e) => { onCancelDispatchLoad(); }}></Button>}
                    >
                        <FormProvider {...methods1}>
                            <Form onSubmit={methods1.handleSubmit(onDispatchLoad)}>
                                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoadingLoadUpdate === true && errorLoadUpdate === null}>
                                    <div>Are you sure you want to dispatch the load {loadName}?</div>
                                    {hasDriversCheck === false && hasTeamDriving === false ? (<Alert message="You must assign a driver to this load." type="error" />) : null}
                                    {hasDriversCheck === false && hasTeamDriving === true ? (<Alert message="You must assign two drivers to this load." type="error" />) : null}
                                    {hasAssetsCheck === false ? (<Alert message="You must assign at least one asset to this load." type="error" />) : null}
                                    {/* {hasDriversCheck === true && hasApprovedDriversCheck === false && hasTeamDriving === false ? (<Alert message="The driver assigned to this load must be approved before this load can be dispatched." type="error" />) : null}
                                    {hasDriversCheck === true && hasApprovedDriversCheck === false && hasTeamDriving === true ? (<Alert message="Both drivers assigned to this load must be approved before this load can be dispatched." type="error" />) : null} */}
                                    {hasActiveCarrierCheck === false ? (<Alert message="Your Carrier Account must be activated first before dispatching this load. Please contact Customer Support for assistance." type="error" />) : null}
                                    {hasVerifiedCarrierCheck === false ? (<Alert message="Your Carrier Account must be verified first before dispatching this load. Please contact Customer Support for assistance." type="error" />) : null}

                                    {entityType === "STAFF" && (
                                        <div style={{ marginTop: 60 }}>
                                            <FormItem {...formItemLayout} label={`Over-Ride Scheduling of this Load without Drivers or Assets due to Carrier Not Cooperating.`} format="horizontal"
                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                    <Switch
                                                        checkedChildren="Yes"
                                                        unCheckedChildren="No"
                                                        onBlur={onBlur}
                                                        onChange={(checked, event) => { setOverrideScheduled(checked); onChange(checked); }}
                                                        checked={value}
                                                        name={name}
                                                        ref={ref}
                                                    />
                                                )}
                                                name="overrideScheduled"
                                                defaultValue={false}
                                            />
                                        </div>
                                    )}

                                    {errorLoadUpdate && <Alert message={`${errorLoadUpdate}`} type="error" />}
                                </Spin>
                                <FormButtons cancel={onCancelDispatchLoad} cancelDisabled={isLoadingLoadUpdate === true && errorLoadUpdate === null} submitDisabled={((hasDriversCheck === false || hasAssetsCheck === false || hasActiveCarrierCheck === false || hasVerifiedCarrierCheck === false) && overrideScheduled === false) || (isLoadingLoadUpdate === true && errorLoadUpdate === null)} submitText="Dispatch Load" />
                            </Form>
                        </FormProvider>
                    </Card>
                </Modal>
            );
        } else {
            return null;
        }
    };

    const cancelLoadComponents = () => {
        let loadObj = null
        if (isStringNotEmpty(selectedLoadToDispatchId) && isListNotEmpty(loadsToDispatch)) {
            let selectedLoadToDispatch = loadsToDispatch.find(i => i.id === selectedLoadToDispatchId);
            if (isObjectNotEmpty(selectedLoadToDispatch)) {
                loadObj = selectedLoadToDispatch;
            }
        } else if (isObjectNotEmpty(load)) {
            loadObj = load;
        }

        if (isObjectNotEmpty(loadObj)) {
            let loadName = isStringNotEmpty(loadObj.loadName) ? loadObj.loadName : (isStringNotEmpty(loadObj.parentName) ? loadObj.parentName : (isStringNotEmpty(loadObj.name) ? loadObj.name : loadObj.irisId));

            return (
                <Modal
                    title={null}
                    visible={showCancelLoadModal === true}
                    maskClosable={false}
                    closable={false}
                    footer={null}
                    bodyStyle={{ padding: 0 }}
                    destroyOnClose={true}
                >
                    <Card
                        title={"Confirm Cancellation"}
                        style={{ border: '1px solid #D8D8D8' }}
                        bodyStyle={{ minHeight: 100, padding: '12px 12px 57px 12px' }}
                        headStyle={{ backgroundColor: '#334353', color: '#ffffff', borderBottom: '1px solid #D8D8D8', fontSize: 14, textAlign: 'center' }}
                        extra={<Button type="link" icon={<CloseOutlined />} onClick={(e) => { onCancelCancelLoad(); }}></Button>}
                    >
                        <FormProvider {...methods2}>
                            <Form onSubmit={methods2.handleSubmit(onCancelLoad)}>
                                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoadingLoadUpdate === true && errorLoadUpdate === null}>
                                    <div>Are you sure you want to cancel the load {loadName}?</div>
                                    {errorLoadUpdate && <Alert message={`${errorLoadUpdate}`} type="error" />}
                                </Spin>
                                <FormButtons cancel={onCancelCancelLoad} cancelDisabled={isLoadingLoadUpdate === true && errorLoadUpdate === null} submitDisabled={(isLoadingLoadUpdate === true && errorLoadUpdate === null)} submitText="Cancel Load" />
                            </Form>
                        </FormProvider>
                    </Card>
                </Modal>
            );
        } else {
            return null;
        }
    };

    //#endregion
    //#region stop displays

    const renderStop = (stop, load) => {
        if (isObjectNotEmpty(stop) && isObjectNotEmpty(load)) {
            const stopIcon = stop.stopType === 'PICK_UP' ? pickUpMarker : (stop.stopType === 'DROP_OFF' ? dropOffMarker : null);
            const stopLocation = LoadStopUtils.getStopLocationDisplay(stop, load.shipperId, load.assignedCarrierId, entityType, entityId);
            const stopAddress = LoadStopUtils.getStopAddressDisplay(stop);
            const apptDetails = LoadStopUtils.getAppointmentDetailsDisplay(stop);
            const apptCallAhead = LoadStopUtils.getAppointmentCallAheadDisplay(stop);
            const apptContact = LoadStopUtils.getAppointmentContactDisplay(stop);

            return (
                <div className={classes.stopDetails} key={`stop-details-${stop.id}`}>
                    <div className={classes.stopLocationContainer}>
                        <div className={classes.stopIconContainer}>
                            {isNotNullOrUndefined(stopIcon) ? (<img src={stopIcon} alt={Enums.StopTypes.getValueByName(stop.stopType)} className={classes.stopIcon} />) : null}
                        </div>
                        <div className={classes.stopLocation}>{stopLocation}</div>
                    </div>
                    {isStringNotEmpty(stopAddress) ? (<div className={classes.stopAddress}>{stopAddress}</div>) : null}
                    {isStringNotEmpty(apptDetails) ? (<div className={classes.apptDetails}>{apptDetails}</div>) : null}
                    {isStringNotEmpty(apptCallAhead) ? (<div className={classes.apptDetails}>{apptCallAhead}</div>) : null}
                    {isStringNotEmpty(apptContact) ? (<div className={classes.apptDetails}>{apptContact}</div>) : null}
                </div>
            );
        } else {
            return null;
        }
    };

    const renderStops = (stops, load) => {
        if (isListNotEmpty(stops) && isObjectNotEmpty(load)) {
            const pickUpAndDropOffStops = stops.filter(s => s.stopType === 'PICK_UP' || s.stopType === 'DROP_OFF');
            if (isListNotEmpty(pickUpAndDropOffStops)) {
                return pickUpAndDropOffStops.map((stop) => { return renderStop(stop, load); });
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    //#endregion
    //#region bundled loads displays

    const getDriverScheduledShiftsDisplay = (record, driverScheduledShifts) => {
        let driverScheduledShiftRows = [];
        if (isListNotEmpty(driverScheduledShifts)) {
            driverScheduledShifts.forEach((scheduledShift) => {
                let shift = isObjectNotEmpty(scheduledShift.shift) ? scheduledShift.shift : null;
                let title = isObjectNotEmpty(shift) && isStringNotEmpty(shift.name) ? shift.name : '';
                let scheduledDate = moment(scheduledShift.startDateTime).format('MM/DD/YYYY');
                let timeZone = moment.tz(shift.timeZone).zoneAbbr();
                let time = `${scheduledDate}, ${isObjectNotEmpty(shift) && isStringNotEmpty(shift.startTime) ? shift.startTime : ''} - ${isObjectNotEmpty(shift) && isStringNotEmpty(shift.endTime) ? shift.endTime : ''}${isStringNotEmpty(timeZone) ? ' ' + timeZone : ''}`;

                driverScheduledShiftRows.push(
                    <div key={`${title}-${time}-driver-scheduled-shift-row-${record.id}`}><Popover title={title} content={<span>{time}</span>}><span>{title}</span><InfoCircleOutlined style={{ color: '#23BEBB', marginLeft: 8 }} /></Popover></div>
                );
            });
        }

        return isListNotEmpty(record.driverIds) ? (isListNotEmpty(driverScheduledShiftRows) ? driverScheduledShiftRows : '--') : '--';
    };

    const getDriverLoadsDisplay = (record, driverBundledLoads, driverOtherLoads) => {
        let driverLoadRows = [];
        let loadsToShow = [];

        if (isListNotEmpty(driverBundledLoads)) {
            driverBundledLoads.forEach((loadObj) => {
                if (loadsToShow.find(i => i.id === loadObj.id) === undefined && loadObj.id !== record.id) {
                    loadsToShow.push(loadObj);
                }
            });
        }

        if (isListNotEmpty(driverOtherLoads)) {
            driverOtherLoads.forEach((loadObj) => {
                if (loadsToShow.find(i => i.id === loadObj.id) === undefined && loadObj.id !== record.id) {
                    loadsToShow.push(loadObj);
                }
            });
        }

        if (isListNotEmpty(loadsToShow)) {
            loadsToShow.forEach((loadObj) => {
                let title = isStringNotEmpty(loadObj.loadName) ? loadObj.loadName : LoadUtils.getLoadName(loadObj);
                let time = LoadStopUtils.getOriginAndDestinationTimes(loadObj.origin, loadObj.destination);

                driverLoadRows.push(
                    <div key={`${loadObj.id}-driver-load-row-${record.id}`}><Popover title={title} content={<span>{time}</span>}><span>{title}</span><InfoCircleOutlined style={{ color: '#23BEBB', marginLeft: 8 }} /></Popover></div>
                );
            });
        }

        return isListNotEmpty(record.driverIds) ? (isListNotEmpty(driverLoadRows) ? driverLoadRows : 'No Other Loads') : '--';
    };

    //#endregion
    //#region displays

    const loadMenu = (load) => {
        return (
            <Menu>
                <Menu.Item key="refreshLoad" onClick={(e) => { e.domEvent.stopPropagation(); e.domEvent.preventDefault(); refreshLoad(); }}>
                    <FontAwesomeIcon className="anticon" icon={faRedoAlt} style={{ marginRight: 8 }} />
                    <span>Refresh Load</span>
                </Menu.Item>
                {((entityType === 'STAFF' || (entityType === 'SHIPPER' && load.createdByEntityType === 'SHIPPER')) && load.id) &&
                    <Menu.Item key="shipperTracker">
                        <a href={`/track/${load.id}`} target='_blank'>
                            <FontAwesomeIcon className="anticon" icon={faExternalLinkAlt} style={{ marginRight: 8 }} />
                            <span>Shipper Tracker</span>
                        </a>
                    </Menu.Item>
                }
            </Menu>
        );
    }

    const loadActions = (load) => {
        if (isObjectNotEmpty(load)) {
            return (
                <Dropdown overlay={loadMenu(load)}>
                    <Button style={{ border: 0 }} type="link" onClick={(e) => { e.stopPropagation(); e.preventDefault(); }} icon={<DownOutlined />} />
                </Dropdown>
            );
        } else {
            return null;
        }
    };

    // rowSelection object indicates the need for row selection
    const rowSelection = {
        hideSelectAll: true,
        columnTitle: 'Pick',
        columnWidth: 50,
        type: 'radio',
        selectedRowKeys: isStringNotEmpty(selectedLoadToDispatchId) ? [selectedLoadToDispatchId] : [],
        renderCell: (checked, record, index, originNode) => {
            // console.log(originNode);
            return {
                props: {
                    className: getRowClassName(record)
                },
                children: originNode
            };
        },
        onChange: (selectedRowKeys, selectedRows) => {
            // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            if (isListNotEmpty(selectedRowKeys)) {
                setSelectedLoadToDispatchId(selectedRowKeys[0]);
                setAssetSideBar(null);
                setDriverSideBar(null);
            } else {
                setSelectedLoadToDispatchId(null);
                setAssetSideBar(null);
                setDriverSideBar(null);
            }
        },
        getCheckboxProps: record => ({
            name: record.id,
        }),
    };

    const getRowClassName = (record) => {
        if (record.isDeleted === true || LoadUtils.loadStatusIs(record.loadStatus, ['CANCELLED'])) {
            return classes.missingRow;
        } else if (record.dispatchStatus === 'DISPATCHED') {
            return classes.dispatchedRow;
        } else if (isStringNotEmpty(selectedLoadToDispatchId) && selectedLoadToDispatchId === record.id) {
            return classes.selectedRow;
        } else {
            return classes.undispatchedRow;
        }
    };

    const getDriverColumnClassName = (record) => {
        if (record.dispatchStatus === 'DISPATCHED') {
            return classes.dispatchedRow;
        } else if (isStringNotEmpty(selectedLoadToDispatchId) && selectedLoadToDispatchId === record.id) {
            return classes.selectedRow;
        } else {
            return classes.undispatchedRow;
        }
    };

    const getAssetColumnClassName = (record) => {
        if (record.dispatchStatus === 'DISPATCHED') {
            return classes.dispatchedRow;
        } else if (isStringNotEmpty(selectedLoadToDispatchId) && selectedLoadToDispatchId === record.id) {
            return classes.selectedRow;
        } else {
            return classes.undispatchedRow;
        }
    };

    const getDispatchColumnClassName = (record) => {
        if (record.dispatchStatus === 'CANCELLED' || record.isDeleted === true) {
            return classes.missingRow;
        } else if (record.dispatchStatus === 'DISPATCHED') {
            return classes.dispatchedRow;
        } else if (record.hasDrivers === true && record.hasAssets === true && record.dispatchStatus === 'PENDING') {
            return classes.readyToDispatchRow;
        } else if (isStringNotEmpty(selectedLoadToDispatchId) && selectedLoadToDispatchId === record.id) {
            return classes.selectedRow;
        } else {
            return classes.undispatchedRow;
        }
    };

    const loadsToDispatchColumns = [
        {
            align: 'center',
            title: 'Load',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => {
                return {
                    props: {
                        className: [getRowClassName(record), classes.cell]
                    },
                    children: <DispatchSelectedLoadCard
                        key={`dispatch-selected-load-card-${index}-${record.id}`}
                        load={record}
                        loadsToDispatch={loadsToDispatch}
                        displayAsText={true}
                        isBundledLoad={true}
                    />,
                };
            },
        },
        {
            align: 'center',
            title: 'Driver(s)',
            dataIndex: 'drivers',
            key: 'drivers',
            render: (text, record, index) => {
                return {
                    props: {
                        className: [getDriverColumnClassName(record), classes.cell]
                    },
                    children: <DispatchDriverCard
                        key={`dispatch-driver-card-${index}-${record.id}`}
                        load={record}
                        stops={stops}
                        drivers={drivers}
                        shifts={shifts}
                        bundledLoads={loadsToDispatch}
                        driverLoads={driverLoads}
                        transportUnits={transportUnits}
                        setAssetSideBar={setAssetSideBar}
                        setDriverSideBar={setDriverSideBar}
                        displayAsText={true}
                        isBundledLoad={true}
                        setSelectedLoadToDispatchId={setSelectedLoadToDispatchId}
                    />,
                };
            },
        },
        {
            align: 'center',
            title: 'Driver Shifts',
            dataIndex: 'driverScheduledShifts',
            key: 'driverScheduledShifts',
            render: (text, record) => {
                return {
                    props: {
                        className: [getRowClassName(record), classes.cell]
                    },
                    children: getDriverScheduledShiftsDisplay(record, record.driverScheduledShifts),
                };
            },
        },
        {
            align: 'center',
            title: 'Driver Loads',
            dataIndex: 'driverOtherLoads',
            key: 'driverOtherLoads',
            render: (text, record) => {
                return {
                    props: {
                        className: [getRowClassName(record), classes.cell]
                    },
                    children: getDriverLoadsDisplay(record, record.driverBundledLoads, record.driverOtherLoads),
                };
            },
        },
        {
            align: 'center',
            title: 'Asset(s)',
            dataIndex: 'assets',
            key: 'assets',
            render: (text, record, index) => {
                return {
                    props: {
                        className: [getAssetColumnClassName(record), classes.cell]
                    },
                    children: <DispatchAssetCard
                        key={`dispatch-asset-card-${index}-${record.id}`}
                        load={record}
                        assets={assets}
                        drivers={drivers}
                        bundledLoads={loadsToDispatch}
                        assetLoads={assetLoads}
                        transportUnits={transportUnits}
                        setAssetSideBar={setAssetSideBar}
                        setDriverSideBar={setDriverSideBar}
                        displayAsText={true}
                        isBundledLoad={true}
                        setSelectedLoadToDispatchId={setSelectedLoadToDispatchId}
                    />,
                };
            },
        },
        {
            align: 'center',
            title: 'Status',
            dataIndex: 'dispatchStatus',
            key: 'dispatchStatus',
            render: (text, record, index) => {
                return {
                    props: {
                        className: [getDispatchColumnClassName(record), classes.cell]
                    },
                    children: record ? <DispatchScheduleLoadCard
                        key={`dispatch-schedule-load-card-${index}-${record.id}`}
                        load={record}
                        displayAsText={true}
                        isBundledLoad={true}
                        setShowDispatchLoadModal={setShowDispatchLoadModal}
                        setShowCancelLoadModal={setShowCancelLoadModal}
                        setAssetSideBar={setAssetSideBar}
                        setDriverSideBar={setDriverSideBar}
                        setSelectedLoadToDispatchId={setSelectedLoadToDispatchId}
                    /> : null,
                };
            },
        },
    ];

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 16 },
        wrapperCol: { span: 8 },
    };

    //#endregion
    //#region location constants

    const previousBreadcrumbs = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isListNotEmpty(props.history.location.state.previousBreadcrumbs) ? props.history.location.state.previousBreadcrumbs : [];
    const previousLocation = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageLocation) ? props.history.location.state.previousPageLocation : { pathname: `/dispatch` };
    const previousPath = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageLocation) ? props.history.location.state.previousPageLocation : `/dispatch`;
    const previousBreadcrumbName = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageTitle) ? props.history.location.state.previousPageTitle : 'Dispatch';

    //#endregion

    if (isObjectNotEmpty(load) && isStringNotEmpty(load.id) && load.id === loadId && isBooleanTrue(load.isParentLoad) && (isNumberGreaterThanZero(load.childLoadInitCount) || isListNotEmpty(load.childLoads))) {
        const loadName = LoadUtils.getLoadName(load);
        const shipperName = LoadUtils.getShipperName(load);
        const carrierName = LoadUtils.getCarrierName(load);
        const serviceTypeDisplay = LoadUtils.getServiceTypeDisplay(load, entityType);
        const temperatureRangeDisplay = LoadUtils.getTemperatureRangeDisplay(load);
        const trailerTypeName = LoadUtils.getTrailerTypeName(load);
        const trailerLength = LoadUtils.getTrailerLength(load);
        const tripMiles = LoadUtils.getTripDistance(load);
        const shipperId = LoadUtils.getShipperId(load);
        const carrierId = LoadUtils.getCarrierId(load);

        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true && error === null) || isLoadingStops === true}>
                <Can entityAction="READ" entityModel="LOAD" entityObject={load}>
                    <Header
                        title={loadName}
                        subtitle={`${trailerTypeName}, ${trailerLength}`}
                        subtitle2={isStringNotEmpty(temperatureRangeDisplay) ? (<span>Temp Range: {temperatureRangeDisplay}</span>) : null}
                        title2={isStringNotEmpty(shipperName) ? (
                            <Button type="link" style={{ paddingLeft: 0 }} onClick={(e) => {
                                e.stopPropagation();
                                if (entityType === 'STAFF') {
                                    window.open(`/accounts/${shipperId}`, "_blank");
                                } else if (entityType === 'SHIPPER') {
                                    window.open(`/dashboard`, "_blank");
                                } else if (entityType === 'CARRIER' || entityType === 'BROKER') {
                                    window.open(`/linkedAccounts/${entityId}/${shipperId}`, "_blank");
                                }
                            }}>{shipperName}</Button>) : 'TBD'
                        }
                        title2Icon={<FontAwesomeIcon className="anticon" icon={faTruckLoading} />}
                        title3={isStringNotEmpty(carrierName) ? (
                            <Button type="link" style={{ paddingLeft: 0 }} onClick={(e) => {
                                e.stopPropagation();
                                if (entityType === 'STAFF') {
                                    window.open(`/accounts/${carrierId}`, "_blank");
                                } else if (entityType === 'CARRIER') {
                                    window.open(`/dashboard`, "_blank");
                                } else if (entityType === 'SHIPPER' || entityType === 'BROKER') {
                                    window.open(`/linkedAccounts/${entityId}/${carrierId}`, "_blank");
                                }
                            }}>{carrierName}</Button>) : 'TBD'
                        }
                        title3Icon={<FontAwesomeIcon className="anticon" icon={faTruck} />}
                        title4={isStringNotEmpty(serviceTypeDisplay) ? (<span>Service Type: {serviceTypeDisplay}</span>) : null}
                        icon={<FontAwesomeIcon className="anticon" icon={faPallet} />}
                        breadcrumbs={[...previousBreadcrumbs,
                        {
                            location: previousLocation,
                            path: previousPath,
                            breadcrumbName: previousBreadcrumbName,
                        },
                        {
                            path: `/loadBundle/${isStringNotEmpty(loadId) ? loadId : ''}`,
                            breadcrumbName: `${loadName}`,
                        },
                        ]}
                        highlights={[
                            {
                                title: 'Trip Miles',
                                value: tripMiles
                            },
                            {
                                title: 'Loads In Bundle',
                                value: isListNotEmpty(loadsToDispatch) ? loadsToDispatch.length : 0
                            },
                        ]}
                    />
                    <Row gutter={[12, 12]} style={{ paddingLeft: 8, paddingRight: 8, paddingTop: 4, borderTop: '4px solid #D8D8D8' }}>
                        {(isBooleanTrue(load.isParentLoad) && isNumberEmpty(load.childLoadInitCount) && isListEmpty(load.childLoads)) ? (
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
                                <DispatchSelectedLoadCard
                                    key={`dispatch-selected-load-card-parent-load`}
                                    load={isObjectNotEmpty(load) ? load : null}
                                    loadsToDispatch={null}
                                    displayAsText={false}
                                    isBundledLoad={false}
                                    style={{ marginBottom: 12 }}
                                />
                                <DispatchDriverCard
                                    key={`dispatch-driver-card-parent-load`}
                                    load={isObjectNotEmpty(load) ? load : null}
                                    stops={stops}
                                    drivers={drivers}
                                    shifts={shifts}
                                    bundledLoads={[]}
                                    driverLoads={driverLoads}
                                    transportUnits={transportUnits}
                                    setAssetSideBar={setAssetSideBar}
                                    setDriverSideBar={setDriverSideBar}
                                    displayAsText={false}
                                    isBundledLoad={false}
                                    setSelectedLoadToDispatchId={setSelectedLoadToDispatchId}
                                    style={{ marginBottom: 12 }}
                                />
                                {isNotNullOrUndefined(driverSideBar) ? driverSideBar : null}
                                <DispatchAssetCard
                                    key={`dispatch-asset-card-parent-load`}
                                    load={isObjectNotEmpty(load) ? load : null}
                                    assets={assets}
                                    drivers={drivers}
                                    bundledLoads={[]}
                                    assetLoads={assetLoads}
                                    transportUnits={transportUnits}
                                    setAssetSideBar={setAssetSideBar}
                                    setDriverSideBar={setDriverSideBar}
                                    displayAsText={false}
                                    isBundledLoad={false}
                                    setSelectedLoadToDispatchId={setSelectedLoadToDispatchId}
                                    style={{ marginBottom: 12 }}
                                />
                                {isNotNullOrUndefined(assetSideBar) ? assetSideBar : null}
                                <DispatchScheduleLoadCard
                                    key={`dispatch-schedule-load-card-parent-load`}
                                    load={isObjectNotEmpty(load) ? load : null}
                                    displayAsText={false}
                                    isBundledLoad={false}
                                    setShowDispatchLoadModal={setShowDispatchLoadModal}
                                    setShowCancelLoadModal={setShowCancelLoadModal}
                                    setAssetSideBar={setAssetSideBar}
                                    setDriverSideBar={setDriverSideBar}
                                    setSelectedLoadToDispatchId={setSelectedLoadToDispatchId}
                                    style={{ marginBottom: 12 }}
                                />
                            </Col>
                        ) : (
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
                                <DispatchSelectedLoadCard
                                    key={`dispatch-selected-load-card-load-to-dispatch`}
                                    load={isStringNotEmpty(selectedLoadToDispatchId) && isListNotEmpty(loadsToDispatch) ? loadsToDispatch.find(i => i.id === selectedLoadToDispatchId) : null}
                                    loadsToDispatch={loadsToDispatch}
                                    displayAsText={false}
                                    isBundledLoad={true}
                                    style={{ marginBottom: 12 }}
                                />
                                <DispatchDriverCard
                                    key={`dispatch-driver-card-load-to-dispatch`}
                                    load={isStringNotEmpty(selectedLoadToDispatchId) && isListNotEmpty(loadsToDispatch) ? loadsToDispatch.find(i => i.id === selectedLoadToDispatchId) : null}
                                    stops={stops}
                                    drivers={drivers}
                                    shifts={shifts}
                                    bundledLoads={loadsToDispatch}
                                    driverLoads={driverLoads}
                                    transportUnits={transportUnits}
                                    setAssetSideBar={setAssetSideBar}
                                    setDriverSideBar={setDriverSideBar}
                                    displayAsText={false}
                                    isBundledLoad={true}
                                    setLoadsToDispatch={setLoadsToDispatch}
                                    style={{ marginBottom: 12 }}
                                />
                                {isNotNullOrUndefined(driverSideBar) ? driverSideBar : null}
                                <DispatchAssetCard
                                    key={`dispatch-asset-card-load-to-dispatch`}
                                    load={isStringNotEmpty(selectedLoadToDispatchId) && isListNotEmpty(loadsToDispatch) ? loadsToDispatch.find(i => i.id === selectedLoadToDispatchId) : null}
                                    assets={assets}
                                    drivers={drivers}
                                    bundledLoads={loadsToDispatch}
                                    assetLoads={assetLoads}
                                    transportUnits={transportUnits}
                                    setAssetSideBar={setAssetSideBar}
                                    setDriverSideBar={setDriverSideBar}
                                    displayAsText={false}
                                    isBundledLoad={true}
                                    setSelectedLoadToDispatchId={setSelectedLoadToDispatchId}
                                    style={{ marginBottom: 12 }}
                                />
                                {isNotNullOrUndefined(assetSideBar) ? assetSideBar : null}
                                <DispatchScheduleLoadCard
                                    key={`dispatch-schedule-load-card-load-to-dispatch`}
                                    load={isStringNotEmpty(selectedLoadToDispatchId) && isListNotEmpty(loadsToDispatch) ? loadsToDispatch.find(i => i.id === selectedLoadToDispatchId) : null}
                                    displayAsText={false}
                                    isBundledLoad={true}
                                    setShowDispatchLoadModal={setShowDispatchLoadModal}
                                    setShowCancelLoadModal={setShowCancelLoadModal}
                                    setAssetSideBar={setAssetSideBar}
                                    setDriverSideBar={setDriverSideBar}
                                    setSelectedLoadToDispatchId={setSelectedLoadToDispatchId}
                                    style={{ marginBottom: 12 }}
                                />
                            </Col>
                        )}
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 16 }}>
                            <Row gutter={[12, 12]}>
                                {(isBooleanTrue(load.isParentLoad) && (isNumberGreaterThanZero(load.childLoadInitCount) || isListNotEmpty(load.childLoads))) ? (
                                    <Col span={24}>
                                        <Card
                                            title={`Loads to Dispatch for ${load.parentName}`}
                                            style={{ border: '1px solid #D8D8D8' }}
                                            bodyStyle={{ minHeight: 400, padding: 0 }}
                                            headStyle={{ backgroundColor: '#334353', color: '#ffffff', borderBottom: '1px solid #D8D8D8', fontSize: 14, textAlign: 'center' }}
                                        >
                                            <DataTable
                                                scroll={{ x: '100%' }}
                                                rowSelection={rowSelection}
                                                dataSource={loadsToDispatch}
                                                columns={loadsToDispatchColumns}
                                                hidePaging={true}
                                                loading={(isLoadingBundledLoads === true || isLoadingDriverLoads === true || isLoadingAssetLoads === true || isLoadingScheduledShifts === true || (isLoadingLoadUpdate === true && errorLoadUpdate === null) || loadEventUpdateStatus === 'PENDING')}
                                                rowClassName={getRowClassName}
                                                title={false}
                                                style={{ backgroundColor: '#ffffff' }}
                                                rowKey={record => record.id}
                                            />
                                        </Card>
                                    </Col>
                                ) : null}
                                <Col span={24}>
                                    <Card
                                        title="Map & Route Details"
                                        style={{ border: '1px solid #D8D8D8' }}
                                        bodyStyle={{ minHeight: 400, padding: 0 }}
                                        headStyle={{ backgroundColor: '#334353', color: '#ffffff', borderBottom: '1px solid #D8D8D8', fontSize: 14, textAlign: 'center' }}
                                        extra={loadActions(load)}
                                    >
                                        <Row>
                                            <Col span={12} style={{ padding: '0px 12px' }}>
                                                <TitleBlockWithIcon
                                                    style={{ margin: '40px auto 40px auto' }}
                                                    icon={<FontAwesomeIcon className={classes.icon} icon={faRoute} />}
                                                    title={
                                                        <span>Trip Miles: {stringFormatter.toFormattedString('Distance', load.practicalDistance, load.practicalDistanceUnit)}<br />Number of Stops: {stops.filter(s => s.stopType === 'PICK_UP' || s.stopType === 'DROP_OFF').length}</span>
                                                    }
                                                />
                                                <div style={{ margin: '0px auto 0px auto', maxWidth: 'max-content' }}>
                                                    {renderStops(stops, load)}
                                                </div>
                                            </Col>
                                            <Col span={12} style={{ borderLeft: '1px solid #D8D8D8' }}>
                                                <LoadMap
                                                    height="100%"
                                                    key={load.id}
                                                    loadId={load.id}
                                                    stops={stops}
                                                    containerName={`load-details-map-${load.id}`}
                                                />
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col span={24}>
                                    <Card
                                        title="Load & Rate Details"
                                        style={{ border: '1px solid #D8D8D8' }}
                                        bodyStyle={{ minHeight: 200, padding: 0 }}
                                        headStyle={{ backgroundColor: '#334353', color: '#ffffff', borderBottom: '1px solid #D8D8D8', fontSize: 14, textAlign: 'center' }}
                                    >
                                        <Row>
                                            <Col span={12} style={{ padding: '0px 12px' }}>
                                                <TitleBlockWithIcon
                                                    style={{ marginTop: 40, marginBottom: 40, marginLeft: 'auto', marginRight: 'auto' }}
                                                    icon={<FontAwesomeIcon className={classes.icon} icon={faPallet} />}
                                                    title={
                                                        <span>Load ID: {load.irisId}<br />Status: {LoadUtils.getLoadStatusDisplay(load.loadStatus, entityType, load)}</span>
                                                    }
                                                />
                                                <div style={{ margin: '0px auto 40px auto', maxWidth: 'max-content' }}>
                                                    {isObjectNotEmpty(load.equipmentNeeds) ? (
                                                        <>
                                                            {isStringNotEmpty(load.equipmentNeeds.trailerType) ? (<DataRow title="Trailer Type" value={load.equipmentNeeds.trailerType.description} dataType="String" />) : null}
                                                            {isNumberNotEmpty(load.equipmentNeeds.trailerLength) ? (<DataRow title="Trailer Length" value={load.equipmentNeeds.trailerLength} units={load.equipmentNeeds.trailerLengthUnit} dataType="Length" />) : null}
                                                            {isNumberNotEmpty(load.equipmentNeeds.trailerWeightCapacity) ? (<DataRow title="Max Load Weight Capacity" value={load.equipmentNeeds.trailerWeightCapacity} units={load.equipmentNeeds.trailerWeightCapacityUnit} dataType="Weight" />) : null}
                                                            {isNumberNotEmpty(load.equipmentNeeds.preCoolReefer) ? (<DataRow title="Pre-cool Temp" value={load.equipmentNeeds.preCoolReefer} units={load.equipmentNeeds.preCoolReeferUnit} dataType="Temperature" />) : null}
                                                            {isNumberNotEmpty(load.equipmentNeeds.minRunTemp) && isNumberNotEmpty(load.equipmentNeeds.maxRunTemp) ? (<DataTemperatureRangeRow title="Temp Range" min={load.equipmentNeeds.minRunTemp} minUnits={load.equipmentNeeds.minRunTempUnit} max={load.equipmentNeeds.maxRunTemp} maxUnits={load.equipmentNeeds.maxRunTempUnit} />) : null}
                                                        </>
                                                    ) : null}
                                                    {isListNotEmpty(load.equipmentSpecifics) ? (
                                                        <DataListRow title="Equipment Specifics" valueStyle={{ marginLeft: 16 }} data={load.equipmentSpecifics} dataType="EquipmentSpecific" />
                                                    ) : null}
                                                    {isListNotEmpty(load.driverRequirements) ? (
                                                        <DataListRow title="Driver Requirements" valueStyle={{ marginLeft: 16 }} data={load.driverRequirements} dataType="DriverRequirement" />
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col span={12} style={{ borderLeft: '1px solid #D8D8D8', padding: '0px 12px' }}>
                                                <TitleBlockWithIcon
                                                    style={{ marginTop: 40, marginBottom: 40, marginLeft: 'auto', marginRight: 'auto' }}
                                                    icon={<FontAwesomeIcon className={classes.icon} icon={faDollarSign} />}
                                                    title={
                                                        <span>Trip Rate: {stringFormatter.toFormattedMoney(load.carrierAmount)}<br />Rate Per Mile: {stringFormatter.toFormattedMoney(load.carrierRatePerMile)}</span>
                                                    }
                                                />
                                                <div style={{ margin: '0px auto 40px auto', maxWidth: 'max-content' }}>
                                                    {isObjectNotEmpty(load.shipper) && isStringNotEmpty(load.shipper.name) ? <DataRow title="Shipper" value={load.shipper.name} /> : null}
                                                    {isObjectNotEmpty(load.shipper) && isObjectNotEmpty(load.shipper.pointOfContact) ? <DataContactInfoRow separateLines={true} title="Shipper Contact" contact={load.shipper.pointOfContact} /> : null}
                                                    {isObjectNotEmpty(load.shipper) && isStringNotEmpty(load.shipper.afterHoursPhone) ? <DataPhoneNumberRow title="Shipper After Hours Phone" phone={load.shipper.afterHoursPhone} phoneExt={load.shipper.afterHoursPhoneExt} /> : null}
                                                    <br />
                                                    {isStringEmpty(load.parentLoadId) ? (isBooleanTrue(load.isRateConfirmed) && isObjectNotEmpty(load.rateConfirmedByUser) && isNotNullOrUndefined(load.rateConfirmedAt) ? <DataAtByRow entityType={entityType} title="Rate Confirmation" at={load.rateConfirmedAt} by={load.rateConfirmedByUser} /> : <DataRow title="Rate Confirmation" value="Pending" dataType="String" />) : null}
                                                    {isStringEmpty(load.parentLoadId) ? <div><b>Documents:</b> <Button type="link" onClick={toggleDocumentsModal} style={{ textDecoration: 'underline' }}>view</Button></div> : null}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {documentsComponent}
                    {dispatchLoadComponents()}
                    {cancelLoadComponents()}
                </Can>
            </Spin>
        );
    } else {
        return <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true && error === null)} />
    }
};

export default withRouter(LoadBundle);