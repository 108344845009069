import React, { useState, useMemo } from 'react';
import { Card, Drawer, Modal, Button, Spin } from "antd";
import DataRow from "../../components/DataRow/DataRow";
import DataContactInfoRow from "../../components/DataContactInfoRow/DataContactInfoRow";
import DataAddressRow from "../../components/DataAddressRow/DataAddressRow";
import classes from './LoadCarrierCard.module.scss';
import EditCarrier from "../../components/EditCarrier/EditCarrier";
import AssignLoadCarrier from "../../components/AssignLoadCarrier/AssignLoadCarrier";
import ChangeLoadCarrier from "../../components/ChangeLoadCarrier/ChangeLoadCarrier";
import AddEditChangeRemoveButtons from '../../components/AddEditChangeRemoveButtons/AddEditChangeRemoveButtons';
import DocumentList from '../../components/DocumentList/DocumentList';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import RemoveLoadCarrier from '../RemoveLoadCarrier/RemoveLoadCarrier';
import DataPhoneNumberRow from '../DataPhoneNumberRow/DataPhoneNumberRow';
import { isListNotEmpty, isNumberNotEmpty, isObjectNotEmpty, isStringNotEmpty, isBooleanTrue, isBooleanFalse } from '../../shared/objectUtils';
import { CanDo, CanSee } from '../../shared/entitlements/entitlements';
import LoadUtils from '../../api/utils/loadUtils';

const missingEntityStyle = {
    backgroundColor: '#fff1f0',
    border: '1px solid #ffa39e',
};

const missingEntityHeadStyle = {
    borderBottom: '1px solid #ffa39e',
};

const LoadCarrierCard = ({ loadId, load, stops, invoices, invoiceLineItems, title, style = {}, headStyle = {} }) => {
    const fullWidth = global.window.innerWidth;

    const singularEntityName = "Carrier";

    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showChangeEntity, setShowChangeEntity] = useState(false);
    const [showRemoveEntity, setShowRemoveEntity] = useState(false);
    const [showDocumentsModal, setShowDocumentsModal] = useState(false);
    const [missingDocumentsCount, setMissingDocumentsCount] = useState(0);
    const [pendingApprovalCount, setPendingApprovalCount] = useState(0);
    const [expiringSoonDocumentsCount, setExpiringSoonDocumentsCount] = useState(0);
    const [expiredDocumentsCount, setExpiredDocumentsCount] = useState(0);
    const [assignedCarrier, setAssignedCarrier] = useState(null);
    const [isAssignedCarrierActive, setIsAssignedCarrierActive] = useState(false);
    const [isAssignedCarrierVerified, setIsAssignedCarrierVerified] = useState(false);

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.accounts.isRecordUpdateLoading);
    const error = useSelector(state => state.accounts.updateRecordError);
    const accounts = useSelector(state => state.orchestrator.accounts);
    const entityType = useSelector(state => state.auth.entityType);

    const verifyAccount = (accountId) => {
        dispatch(actionCreators.verifyAccount(accountId));
    };

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleChangeEntity = () => {
        setShowChangeEntity(!showChangeEntity);
    };

    const toggleRemoveEntity = () => {
        setShowRemoveEntity(!showRemoveEntity);
    };

    const toggleDocumentsModal = () => {
        setShowDocumentsModal(!showDocumentsModal);
    };

    const extra = isObjectNotEmpty(load) ? (
        <>
            <CanSee entityAction="CREATE" entityModel="LOAD_CARRIER" entityObject={load}>
                <AddEditChangeRemoveButtons
                    showAdd={isStringNotEmpty(load.assignedCarrierId) || LoadUtils.loadStatusIs(load.loadStatus, ['CANCELLED']) ? false : true} addText={"Assign " + singularEntityName + " to Load"} addAction={(e) => { e.stopPropagation(); toggleNewEntity(); }}
                    showEdit={false} editText={null} editAction={null}
                    showChange={false} changeText={null} changeAction={null}
                    showRemove={false} removeText={null} removeAction={null}
                />
            </CanSee>
            <CanSee entityAction="UPDATE" entityModel="LOAD_CARRIER" entityObject={load}>
                <AddEditChangeRemoveButtons
                    showAdd={false} addText={null} addAction={null}
                    showEdit={isStringNotEmpty(load.assignedCarrierId) && entityType === 'STAFF'} editText={"Edit " + singularEntityName + " Details"} editAction={(e) => { e.stopPropagation(); toggleEditEntity(); }}
                    showChange={isStringNotEmpty(load.assignedCarrierId) && LoadUtils.loadStatusIs(load.loadStatus, ['PENDING','APPROVED','PENDING_RATE_CONFIRMATION'])} changeText={"Change " + singularEntityName} changeAction={(e) => { e.stopPropagation(); toggleChangeEntity(); }}
                    showRemove={isStringNotEmpty(load.assignedCarrierId) && LoadUtils.loadStatusIs(load.loadStatus, ['PENDING','APPROVED','PENDING_RATE_CONFIRMATION'])} removeText={"Remove " + singularEntityName} removeAction={(e) => { e.stopPropagation(); toggleRemoveEntity(); }}
                />
            </CanSee>
        </>
    ) : null;

    const newEntityComponents = (
        <Drawer
            title={"Assign " + singularEntityName + " to Load"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleNewEntity}
            visible={showNewEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <AssignLoadCarrier
                cancel={toggleNewEntity}
                loadId={loadId}
                load={load}
                stops={stops}
                invoices={invoices}
                invoiceLineItems={invoiceLineItems}
            />
        </Drawer>
    );

    const changeEntityComponents = (
        <Drawer
            title={"Change " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleChangeEntity}
            visible={showChangeEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <ChangeLoadCarrier
                cancel={toggleChangeEntity}
                load={load}
                stops={stops}
                invoices={invoices}
                invoiceLineItems={invoiceLineItems}
            />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName + " Details"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditEntity}
            visible={showEditEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditCarrier
                cancel={toggleEditEntity}
                account={assignedCarrier}
            />
        </Drawer>
    );

    const removeEntityComponents = (
        <Drawer
            title={"Remove " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleRemoveEntity}
            visible={showRemoveEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <RemoveLoadCarrier
                cancel={toggleRemoveEntity}
                invoices={invoices}
                load={load}
            />
        </Drawer>
    );

    const documentsComponent = (
        <Modal
            title="Carrier Documents"
            visible={showDocumentsModal === true}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleDocumentsModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            {isObjectNotEmpty(load) && isStringNotEmpty(load.assignedCarrierId) && isObjectNotEmpty(assignedCarrier) ? (
                <DocumentList
                    documentEntityType="ACCOUNT"
                    documentEntityId={load.assignedCarrierId}
                    account={assignedCarrier}
                    updatePendingApprovalCount={setPendingApprovalCount}
                    updateMissingDocumentsCount={setMissingDocumentsCount}
                    updateExpiringSoonDocumentsCount={setExpiringSoonDocumentsCount}
                    updateExpiredDocumentsCount={setExpiredDocumentsCount}
                    loadId={load.id}
                />
            ) : null}
            {isObjectNotEmpty(load) && isStringNotEmpty(load.assignedCarrierId) && isBooleanFalse(isAssignedCarrierVerified) ? (
                <CanSee entityAction="VERIFY" entityModel="ACCOUNT" entityObject={load.assignedCarrier}>
                    <Button style={{ marginTop: 20 }} type='primary' onClick={() => { verifyAccount(load.assignedCarrierId); }} disabled={pendingApprovalCount > 0 || missingDocumentsCount > 0 || expiredDocumentsCount > 0}>Verify Account</Button>
                </CanSee>
            ) : null}
        </Modal>
    );

    useMemo(() => {
        dispatch(actionCreators.getAccounts());
    }, []);

    useMemo(() => {
        let isAssignedCarrierActiveValue = false;
        let isAssignedCarrierVerifiedValue = false;
        let assignedCarrierValue = null;

        if (isListNotEmpty(accounts) && isObjectNotEmpty(load) && isStringNotEmpty(load.assignedCarrierId)) {
            assignedCarrierValue = accounts.find(i => i.id === load.assignedCarrierId);
            if (isObjectNotEmpty(assignedCarrierValue)) {
                isAssignedCarrierActiveValue = isBooleanTrue(assignedCarrierValue.isActive) ? true : false;
                isAssignedCarrierVerifiedValue = isBooleanTrue(assignedCarrierValue.isVerified) ? true : false;
            }
        }

        setAssignedCarrier(assignedCarrierValue);
        setIsAssignedCarrierActive(isAssignedCarrierActiveValue === true);
        setIsAssignedCarrierVerified(isAssignedCarrierVerifiedValue === true);
    }, [accounts, load])

    useMemo(() => {
        if (isLoading !== null && isLoading === false) {
            // close documents modal after the carrier is updated
            setShowDocumentsModal(false);
        }
    }, [isLoading]);

    const renderCarrierDetails = () => {
        if (isObjectNotEmpty(load) && isObjectNotEmpty(assignedCarrier)) {
            if (CanDo({ entityTypes: ['STAFF', 'CARRIER'] }) || LoadUtils.isTMSLoad(load)) {
                return (
                    <>
                        <DataRow title='Account ID' value={assignedCarrier.irisId} dataType='String' />
                        <DataRow title='Name' value={assignedCarrier.name} dataType='String' />
                        <DataRow title='DOT #' value={assignedCarrier.dotNum} dataType='String' />
                        <DataRow title='MC #' value={assignedCarrier.mcNum} dataType='String' />
                        {isObjectNotEmpty(assignedCarrier.pointOfContact) ? <DataContactInfoRow separateLines={true} title="Contact" contact={assignedCarrier.pointOfContact} /> : null}
                        {isStringNotEmpty(assignedCarrier.afterHoursPhone) ? <DataPhoneNumberRow title="After Hours Phone" phone={assignedCarrier.afterHoursPhone} phoneExt={assignedCarrier.afterHoursPhoneExt} /> : null}
                        {isObjectNotEmpty(assignedCarrier.smsBasic) && isNumberNotEmpty(assignedCarrier.smsBasic.weightedAvgSMSScore) && <DataRow title='SMS Basic Avg' value={assignedCarrier.smsBasic.weightedAvgSMSScore} dataType='String' />}
                        <CanSee staffOnly={true}>
                            <div><b>Documents:</b> <Button type="link" onClick={toggleDocumentsModal}>view</Button></div>
                        </CanSee>
                        {isObjectNotEmpty(assignedCarrier.accountsReceivable) ? <DataContactInfoRow valueStyle={{ marginLeft: 16 }} title="A/R Contact" contact={assignedCarrier.accountsReceivable} /> : null}
                        {isObjectNotEmpty(assignedCarrier.accountsReceivableAddress) ? <DataAddressRow valueStyle={{ marginLeft: 16 }} title="A/R Address" address={assignedCarrier.accountsReceivableAddress} /> : null}
                        {isBooleanFalse(isAssignedCarrierVerified) ? (
                            <CanSee staffOnly={true}>
                                <Button style={{ marginTop: 20 }} type='primary' onClick={toggleDocumentsModal}>Verify Account</Button>
                            </CanSee>
                        ) : null}
                    </>
                );
            } else {
                return (<DataRow title='Name' value="IRIS Freight" dataType='String' />);
            }
        } else {
            return (<i>No Carrier Assigned.</i>);
        }
    };

    if (isObjectNotEmpty(load)) {
        return (
            <CanSee entityAction="READ" entityModel="LOAD_CARRIER" entityObject={load}>
                <Card title={title} bordered={true} className={classes.card} style={(isObjectNotEmpty(assignedCarrier) && isAssignedCarrierActive === true && isAssignedCarrierVerified === true) || LoadUtils.isTMSLoad(load) ? style : { ...style, ...missingEntityStyle }} headStyle={(isObjectNotEmpty(assignedCarrier) && isAssignedCarrierActive === true && isAssignedCarrierVerified === true) || LoadUtils.isTMSLoad(load) ? headStyle : { ...headStyle, ...missingEntityHeadStyle }} extra={extra}>
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                        {renderCarrierDetails()}
                    </Spin>
                </Card>
                {editEntityComponents}
                {newEntityComponents}
                {changeEntityComponents}
                {removeEntityComponents}
                {documentsComponent}
            </CanSee>
        );
    } else {
        return null;
    }
};

export default LoadCarrierCard;