import React from 'react';
import irisLogoSmall from '../../assets/img/iris-logo.png';
import irisLogoLarge from '../../assets/img/logos/iris-main-logo.png';
import { isStringNotEmpty } from '../../shared/objectUtils';
import classes from './Logo.module.scss';

const logo = ({ size = null, padding = 0, height, style: customStyle }) => {

    let irisLogo = irisLogoSmall;
    let className = classes.Logo;
    let style = { height: height };
    let logoStyle = null;
    if (isStringNotEmpty(size) && size === "large") {
        irisLogo = irisLogoLarge;
        className = classes.LogoLarge;
        style = { margin: 'auto', padding: padding, height: height }
    }
    else if (isStringNotEmpty(size) && size === "custom") {
        irisLogo = irisLogoLarge;
        className = classes.LogoLarge;
        style = customStyle;
        logoStyle = { height: height };
    } else if (isStringNotEmpty(size) && size === "menu") {
        irisLogo = irisLogoLarge;
        className = classes.LogoLarge;
    } else {
        irisLogo = irisLogoSmall;
        className = classes.LogoLarge;
        style = { margin: 'auto', padding: padding, height: height }
    }
    return (
        <div className={className} style={style}>
            <img src={irisLogo} alt="IRIS" style={logoStyle} />
        </div>
    )
};

export default logo;