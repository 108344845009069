import React, { Fragment } from "react";
import { Row, Col, Avatar, Divider, Breadcrumb } from 'antd';
import { withRouter } from "react-router-dom";
import classes from "./Header.module.scss";
import { isBooleanFalse, isBooleanTrue, isListNotEmpty, isNotNullOrUndefined, isNullOrUndefined } from "../../shared/objectUtils";

const IconLink = ({ src, text }) => (
    <a className="example-link">
        <img className="example-link-icon" src={src} alt={text} />
        {text}
    </a>
);

const TopContent = ({ title, titleHasAction = false, title2, title2Icon, title3, title3Icon, title4, title4Icon, subtitle, subtitle2, subtitle3, logo, icon, highlights, actions, titleStyle, title2Style, title3Style, title4Style, subtitleStyle, subtitle2Style, subtitle3Style, iconStyle, logoStyle }) => {
    return (
        <div style={{ flexDirection: 'row', display: 'flex' }}>
            {isNotNullOrUndefined(icon) && isNullOrUndefined(logo) ? (
                <div style={{ height: 64, width: 64, marginRight: 12, flexDirection: 'column' }}>
                    <Avatar icon={icon} shape="circle" size={64} style={iconStyle ? { color: '#000000', ...iconStyle } : { color: '#000000' }} />
                </div>
            ) : (isNotNullOrUndefined(logo) ? (
                <div style={{ height: 64, width: 64, marginRight: 12, flexDirection: 'column' }}>
                    <Avatar src={logo} shape="circle" size={64} style={logoStyle} />
                </div>
            ) : null)}
            <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column' }}>
                <div style={{ flexDirection: 'row', display: 'inline-flex', alignItems: 'center' }}>
                    {isNotNullOrUndefined(title) && isBooleanFalse(titleHasAction) ? (
                        <span className="ant-page-header-heading-title" style={titleStyle}>{title}</span>
                    ) : null}
                    {isNotNullOrUndefined(title) && isBooleanTrue(titleHasAction) ? (
                        <span className="ant-page-header-heading-title" style={titleStyle}>{title}</span>
                    ) : null}
                    {actions}
                    {isNotNullOrUndefined(subtitle) ? (
                        <span className="ant-page-header-heading-sub-title" style={subtitleStyle}>{subtitle}</span>
                    ) : null}
                    {isNotNullOrUndefined(subtitle2) ? (
                        <>
                            <Divider type="vertical" />
                            <span className="ant-page-header-heading-sub-title" style={subtitle2Style}>{subtitle2}</span>
                        </>
                    ) : null}
                    {isNotNullOrUndefined(subtitle3) ? (
                        <>
                            <Divider type="vertical" />
                            <span className="ant-page-header-heading-sub-title" style={subtitle3Style}>{subtitle3}</span>
                        </>
                    ) : null}
                </div>
                {isNotNullOrUndefined(title2) ? (
                    <div style={{ flexDirection: 'row' }}>
                            {isNotNullOrUndefined(title2Icon) ? (
                                <span style={{ marginRight: 12 }}>{title2Icon}</span>
                            ) : null}
                            {isNotNullOrUndefined(title2) ? (
                                <span className="ant-page-header-heading-sub-title" style={title2Style}>{title2}</span>
                            ) : null}
                            {isNotNullOrUndefined(title3Icon) ? (
                                <span style={{ marginRight: 12 }}>{title3Icon}</span>
                            ) : null}
                            {isNotNullOrUndefined(title3) ? (
                                <span className="ant-page-header-heading-sub-title" style={title3Style}>{title3}</span>
                            ) : null}
                            {isNotNullOrUndefined(title4Icon) ? (
                                <span style={{ marginRight: 12 }}>{title4Icon}</span>
                            ) : null}
                            {isNotNullOrUndefined(title4) ? (
                                <span className="ant-page-header-heading-sub-title" style={title4Style}>{title4}</span>
                            ) : null}
                    </div>
                ) : null}
            </div>

            {isListNotEmpty(highlights) ? (
                <div style={{ display: 'inline-flex', alignContent: 'flex-end', justifyContent: 'center', flexGrow: 1, flexDirection: 'column' }}>
                    <div style={{ alignItems: 'center', justifyContent: 'flex-end', display: 'inline-flex' }}>
                        {highlights.filter(i => i.value !== undefined && i.value !== null).map((highlight, index, arr) => {
                            if (index < (arr.length - 1)) {
                                if (isNotNullOrUndefined(highlight.value)) {
                                    return (
                                        <Fragment key={`highlight-fragment-${index}`}>
                                            <div className={classes.highlight} key={`highlight-${index}`}>
                                                <div className={classes.highlightTitle}>{highlight.title}</div>
                                                <div className={classes.highlightContent}>{highlight.url ? (<a href={highlight.url} style={{ textDecoration: 'underline' }}>{highlight.value}</a>) : highlight.value}</div>
                                            </div>
                                            <Divider type="vertical" style={{ height: 24 }} />
                                        </Fragment>
                                    );
                                } else {
                                    return null;
                                }
                            } else {
                                if (isNotNullOrUndefined(highlight.value)) {
                                    return (
                                        <div className={classes.highlight} key={`highlight-${index}`}>
                                            <div className={classes.highlightTitle}>{highlight.title}</div>
                                            <div className={classes.highlightContent}>{highlight.url ? (<a href={highlight.url} style={{ textDecoration: 'underline' }}>{highlight.value}</a>) : highlight.value}</div>
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            }
                        })}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

const Content = ({ children, extra }) => {
    return (
        <div className="content">
            <div className="main">{children}</div>
            <div className="extra">{extra}</div>
        </div>
    );
};

const Header = ({ breadcrumbs, footer, style, ...props }) => {
    return (
        <div className={classes.header} style={style}>
            {isListNotEmpty(breadcrumbs) ? (
                <Breadcrumb style={{ marginBottom: 8 }}>
                    {breadcrumbs.map((breadcrumb, index) => {
                        if (isNotNullOrUndefined(breadcrumb.location)) {
                            return (
                                <Breadcrumb.Item key={`breadcrumb-${index}`}><a onClick={(e) => { e.stopPropagation(); e.preventDefault(); props.history.push(breadcrumb.location); }}>{breadcrumb.breadcrumbName}</a></Breadcrumb.Item>
                            );
                        } else {
                            return (
                                <Breadcrumb.Item key={`breadcrumb-${index}`}><a onClick={(e) => { e.stopPropagation(); e.preventDefault(); props.history.push({ pathname: breadcrumb.path }); }}>{breadcrumb.breadcrumbName}</a></Breadcrumb.Item>
                            );
                        }
                    })}
                </Breadcrumb>
            ) : null}
            <TopContent {...props} />
            {isNotNullOrUndefined(footer) ? footer : null}
        </div>
    );
};

export default withRouter(Header);