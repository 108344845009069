import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { Spin, Tabs, Row, Col, Avatar, Button, Menu, Dropdown, Drawer, Modal, Alert, Space } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import Header from '../../components/Header/Header';
import { useLocation } from 'react-router';
import ComplexCard from '../../components/ComplexCard/ComplexCard';
import DataRow from '../../components/DataRow/DataRow';
import DataPhoneNumberRow from '../../components/DataPhoneNumberRow/DataPhoneNumberRow';
import DataAddressRow from '../../components/DataAddressRow/DataAddressRow';
import DataContactInfoRow from '../../components/DataContactInfoRow/DataContactInfoRow';
import DataSMSBasicRow from '../../components/DataSMSBasicRow/DataSMSBasicRow';
import ValueFormatting from '../../shared/ValueFormatting';
import DocumentList from '../../components/DocumentList/DocumentList';
import { isBooleanFalse, isBooleanTrue, isListNotEmpty, isNumberNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import * as Data from '../../api/data';
import logger from '../../shared/logger';
import { CanDo, CanSee } from '../../shared/entitlements/entitlements';
import Drivers from "../../views/Drivers/Drivers";
import Employees from "../../views/Employees/Employees";
import BookedLoads from "../../views/BookedLoads/BookedLoads";
import InTransitLoads from "../../views/InTransitLoads/InTransitLoads";
import LoadRequests from '../LoadRequests/LoadRequests';
import Assets from '../../views/Assets/Assets';
import Dispatch from '../Dispatch/Dispatch';
import LinkedShippers from "../../views/LinkedShippers/LinkedShippers";
import LinkedProducers from "../../views/LinkedProducers/LinkedProducers";
import LinkedReceivers from "../../views/LinkedReceivers/LinkedReceivers";
import QueueCompletedLoads from '../../components/QueueCompletedLoads/QueueCompletedLoads';
import PreferredLanes from '../PreferredLanes/PreferredLanes';
import OpenLoads from '../OpenLoads/OpenLoads';
import QueueLoads from '../../components/QueueLoads/QueueLoads';
import LinkedFactorings from '../LinkedFactorings/LinkedFactorings';
import LinkedBrokers from '../LinkedBrokers/LinkedBrokers';
import Locations from '../Locations/Locations';
import LoadLanes from '../LoadLanes/LoadLanes';
import LinkedLoadLanes from '../LinkedLoadLanes/LinkedLoadLanes';
import ComplexDataLinkCard from '../../components/ComplexDataLinkCard/ComplexDataLinkCard';
import SimpleStatsLinkCard from '../../components/SimpleStatsLinkCard/SimpleStatsLinkCard';
import { ControlOutlined, DownOutlined, PaperClipOutlined } from '@ant-design/icons';
import EditAccount from '../../components/EditAccount/EditAccount';
import SimpleDataLinkCard from '../../components/SimpleDataLinkCard/SimpleDataLinkCard';
import LinkedCarriers from '../LinkedCarriers/LinkedCarriers';
import StringFormatter from '../../shared/stringFormatter';
import AccountUtils from '../../api/utils/accountUtils';
import EditMissingAccountInformation from '../../components/EditMissingAccountInformation/EditMissingAccountInformation';
import axios from 'axios';

const { TabPane } = Tabs;
const stringFormatter = new StringFormatter();

const Account = (props) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    //#endregion
    //#region props

    const id = props.match.params.id;

    //#endregion
    //#region useLocation

    const location = useLocation();

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const allMetrics = useSelector(state => state.metrics.metrics);
    const isLoading = useSelector(state => state.accounts.isRecordLoading);
    const error = useSelector(state => state.accounts.recordError);
    const account = useSelector(state => state.accounts.record);
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);

    //#endregion
    //#region useStates

    const [metrics, setMetrics] = useState(null);
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showDocumentsModal, setShowDocumentsModal] = useState(false);
    const [tabKey, setTabKey] = useState('snapshot');
    const [imageUrl, setImageUrl] = useState(null);
    const [missingDocumentsCount, setMissingDocumentsCount] = useState(0);
    const [pendingApprovalCount, setPendingApprovalCount] = useState(0);
    const [expiringSoonDocumentsCount, setExpiringSoonDocumentsCount] = useState(0);
    const [expiredDocumentsCount, setExpiredDocumentsCount] = useState(0);
    const [missingAccountInformation, setMissingAccountInformation] = useState([]);
    const [showEditMissingAccountInformation, setShowEditMissingAccountInformation] = useState([]);
    const [selectedMissingAccountInformation, setSelectedMissingAccountInformation] = useState(null);

    //#endregion
    //#region account methods

    const verifyAccount = (accountId) => {
        dispatch(actionCreators.verifyAccount(accountId));
    };

    //#endregion
    //#region toggles

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleDocumentsModal = () => {
        setShowDocumentsModal(!showDocumentsModal);
    };

    const toggleEditMissingAccountInformation = () => {
        setShowEditMissingAccountInformation(!showEditMissingAccountInformation);
    };

    //#endregion
    //#region tab methods

    const onTabChange = (key) => {
        props.history.replace({ ...props.location, hash: `#${key}` });
    };

    //#endregion
    //#region useMemos and useEffects

    useMemo(() => {
        // console.log('location changed')
        // console.log(location)
        if (isStringNotEmpty(location.hash)) {
            // console.log(location.hash.replace('#', ''))
            setTabKey(location.hash.replace('#', ''));
        } else {
            setTabKey('snapshot');
            props.history.replace({ ...location, hash: `#snapshot` });
        }
    }, [location]);

    useMemo(() => {
        dispatch(actionCreators.fetchAccount(id));
    }, [id]);

    useMemo(() => {
        if (isObjectNotEmpty(account)) {
            let missingAccountInformationList = AccountUtils.getMissingAccountInformation(account);
            setMissingAccountInformation(missingAccountInformationList);
        }
    }, [account]);

    useEffect(() => {
        const { cancel, token } = axios.CancelToken.source();

        if (isObjectNotEmpty(account) && isStringNotEmpty(account.logoFileId)) {
            Data.getImageByDocumentId(account.logoFileId, { cancelToken: token }).then((data) => {
                const blob = new Blob([data], {
                    type: 'image/png',
                });
                setImageUrl(URL.createObjectURL(blob));
            }).catch(err => {
                if (axios.isCancel(err)) {
                    // console.log('Request cancelled', err.message);
                } else {
                    logger.logDebugEvent('Account.js', err.message, true);
                }
            });
        }

        return () => {
            // console.log("Cleaning up");
            if (cancel !== undefined) {
                cancel('Operation cancelled by the user.');
            }
        };
    }, [account]);

    useEffect(() => {
        if (isObjectNotEmpty(account)) {
            if (isListNotEmpty(account.entityTypes)) {
                const accountType = account.entityTypes[0];
                if (accountType === 'SHIPPER' || accountType === 'CARRIER') {
                    if (isObjectNotEmpty(allMetrics)) {
                        // console.log(allMetrics[account.id]);
                        if (isObjectNotEmpty(allMetrics[account.id])) {
                            setMetrics(allMetrics[account.id]);
                        } else {
                            dispatch(actionCreators.fetchMetrics(accountType, account.id));
                        }
                    } else {
                        dispatch(actionCreators.fetchMetrics(accountType, account.id));
                    }
                }
            }
        }
    }, [allMetrics, account]);

    //#endregion
    //#region displays

    const previousBreadcrumbs = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isListNotEmpty(props.history.location.state.previousBreadcrumbs) ? props.history.location.state.previousBreadcrumbs : [];
    const previousLocation = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageLocation) ? props.history.location.state.previousPageLocation : { pathname: `/accounts` };
    const previousPath = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageLocation) ? props.history.location.state.previousPageLocation : `/accounts`;
    const previousBreadcrumbName = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageTitle) ? props.history.location.state.previousPageTitle : 'Accounts';
    const currentPath = `/accounts/${isStringNotEmpty(id) ? id : ''}`;
    const currentBreadcrumbName = `${isObjectNotEmpty(account) && isStringNotEmpty(account.name) ? account.name : ''}`;
    const breadcrumbs = [...previousBreadcrumbs,
    {
        location: previousLocation,
        path: previousPath,
        breadcrumbName: previousBreadcrumbName,
    },
    {
        path: currentPath,
        breadcrumbName: currentBreadcrumbName,
    },
    ];

    const menu = () => {
        return (
            <Menu>
                <Menu.Item key="manageDocuments" onClick={(e) => { e.domEvent.stopPropagation(); toggleDocumentsModal(); }}>
                    <PaperClipOutlined style={{ marginRight: 8 }} />
                    <span>Manage Documents</span>
                </Menu.Item>
                <Menu.Item key="editAccount" onClick={(e) => { e.domEvent.stopPropagation(); toggleEditEntity(); }}>
                    <FontAwesomeIcon className="anticon" icon={faUsers} style={{ marginRight: 8 }} />
                    <span>Edit Account Details</span>
                </Menu.Item>
            </Menu>
        );
    };

    const extra = (
        <Dropdown overlay={menu} trigger={['click']}>
            <Button onClick={(e) => { e.stopPropagation(); }}><ControlOutlined /> <DownOutlined /></Button>
        </Dropdown>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit Account Details"}
            onClose={toggleEditEntity}
            visible={showEditEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditAccount
                cancel={toggleEditEntity}
                account={account}
            />
        </Drawer>
    );

    const documentsComponent = (
        <Modal
            title="Account Documents"
            visible={showDocumentsModal === true}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleDocumentsModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            {isObjectNotEmpty(account) ? (
                <DocumentList
                    documentEntityType="ACCOUNT"
                    account={account}
                    documentEntityId={account.id}
                    updatePendingApprovalCount={setPendingApprovalCount}
                    updateMissingDocumentsCount={setMissingDocumentsCount}
                    updateExpiredDocumentsCount={setExpiringSoonDocumentsCount}
                    updateExpiredDocumentsCount={setExpiredDocumentsCount}
                />
            ) : null}
            {isObjectNotEmpty(account) && isBooleanFalse(account.isVerified) ? (
                <CanSee entityAction="VERIFY" entityModel="ACCOUNT" entityObject={account}>
                    <Button style={{ marginTop: 20 }} type='primary' onClick={() => { verifyAccount(account.id); }} disabled={pendingApprovalCount > 0 || missingDocumentsCount > 0 || expiredDocumentsCount > 0}>Verify Account</Button>
                </CanSee>
            ) : null}
        </Modal>
    );

    const editMissingAccountInformationDrawer = (
        <Drawer
            title={"Edit Missing Details for " + (isObjectNotEmpty(selectedMissingAccountInformation) ? selectedMissingAccountInformation.entityName : "")}
            onClose={toggleEditMissingAccountInformation}
            visible={showEditMissingAccountInformation === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditMissingAccountInformation
                cancel={toggleEditMissingAccountInformation}
                missingAccountInformation={selectedMissingAccountInformation}
                account={account}
            />
        </Drawer>
    );

    const missingAccountInformationDisplay = () => {
        if (isListNotEmpty(missingAccountInformation)) {
            return (
                <Row gutter={[24, 24]} style={{ marginBottom: 24 }}>
                    <Col span={24}>
                        {missingAccountInformation.map((missingInfo, index, arr) => {
                            return (
                                <Alert
                                    key={`missing-account-information-${index}`}
                                    style={index < arr.length - 1 ? { marginBottom: 8 } : null}
                                    type="error"
                                    message={`${missingInfo.entityName} is missing ${missingInfo.missingFieldName}`}
                                    showIcon={true}
                                    action={<Space><Button size="small" type="ghost" onClick={(e) => { setSelectedMissingAccountInformation(missingInfo); toggleEditMissingAccountInformation(); }}>Fix</Button></Space>}
                                />
                            );
                        })}
                    </Col>
                </Row>
            );
        } else {
            return null;
        }
    };

    //#endregion

    if (isObjectNotEmpty(account) && account.id === id) {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                <CanSee entityAction="READ" entityModel="ACCOUNT" entityObject={account}>
                {missingAccountInformation.length > 0 ? (
                missingAccountInformationDisplay()
            ) : null}
                    <Header
                        title={isStringNotEmpty(account.name) ? account.name : 'Loading...'}
                        title2={isObjectNotEmpty(account.companyAddress) ? (<ValueFormatting.Address address={account.companyAddress} />) : ''}
                        subtitle={isStringNotEmpty(account.irisId) ? account.irisId : ''}
                        logo={imageUrl}
                        icon={<FontAwesomeIcon className="anticon" icon={faUsers} />}
                        breadcrumbs={breadcrumbs}
                        highlights={isBooleanTrue(account.entityTypes.includes('CARRIER')) ? [
                            {
                                title: 'EIN #',
                                value: account.ein
                            },
                            {
                                title: 'DOT #',
                                value: account.dotNum
                            },
                            {
                                title: 'MC #',
                                value: account.mcNum
                            },
                            {
                                title: 'SMS Score',
                                value: `${isObjectNotEmpty(account.smsBasic) && isNumberNotEmpty(account.smsBasic.weightedAvgSMSScore) ? account.smsBasic.weightedAvgSMSScore : 'N/A'}`
                            },
                        ] : (isBooleanTrue(account.entityTypes.includes('SHIPPER')) ? [
                            {
                                title: 'EIN #',
                                value: account.ein
                            },
                            {
                                title: 'D&B #',
                                value: account.dbNum
                            },
                            {
                                title: 'PBB #',
                                value: account.blueBookNum
                            },
                        ] : (isBooleanTrue(account.entityTypes.includes('BROKER')) ? [
                            {
                                title: 'EIN #',
                                value: account.ein
                            },
                            {
                                title: 'DOT #',
                                value: account.dotNum
                            },
                            {
                                title: 'MC #',
                                value: account.mcNum
                            },
                        ]
                            : [
                                {
                                    title: 'EIN #',
                                    value: account.ein
                                }
                            ]))}
                    />
                    <Tabs activeKey={tabKey} onChange={onTabChange} destroyInactiveTabPane={true} tabBarStyle={{ backgroundColor: '#ffffff', paddingRight: 24, paddingLeft: 24, margin: 0 }}>
                        <TabPane tab="Snapshot" key="snapshot">
                            <div style={{ padding: 24 }}>
                                {isLoading === false ? (
                                    <Row gutter={[24, 24]}>
                                        <Col lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                            <ComplexCard
                                                title="Account Details"
                                                headStyle={{ fontSize: 14 }}
                                                extra={extra}
                                            >
                                                {isStringNotEmpty(account.email) ? <DataRow title="Email Address" value={account.email} dataType="String" /> : null}
                                                {isStringNotEmpty(account.phone) ? <DataPhoneNumberRow title="Phone Number" phone={account.phone} phoneExt={account.phoneExt} /> : null}
                                                {isStringNotEmpty(account.afterHoursPhone) ? <DataPhoneNumberRow title="After Hours Phone Number" phone={account.afterHoursPhone} phoneExt={account.afterHoursPhoneExt} /> : null}
                                                {isStringNotEmpty(account.fax) ? <DataPhoneNumberRow title="Fax Number" phone={account.fax} /> : null}
                                                {isObjectNotEmpty(account.pointOfContact) ? <DataContactInfoRow separateLines={false} valueStyle={{ paddingLeft: 16 }} title="Point of Contact" contact={account.pointOfContact} /> : null}
                                                {isObjectNotEmpty(account.companyAddress) ? <DataAddressRow title="Company Address" oneLine={false} valueStyle={{ paddingLeft: 16 }} address={account.companyAddress} /> : null}
                                                {CanDo({ staffOnly: true }) ? (
                                                    <>
                                                        <DataRow title="Is Active" value={account.isActive} dataType="Boolean" />
                                                        <DataRow title="Is Verified" value={account.isVerified} dataType="Boolean" />
                                                    </>
                                                ) : null}
                                            </ComplexCard>
                                        </Col>
                                        {account.entityTypes.includes('SHIPPER') ? (
                                            <>
                                                <Col lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                                    <ComplexDataLinkCard
                                                        title="Shipper Details"
                                                        headStyle={{ fontSize: 14 }}
                                                        data={{
                                                            "Is Credit Approved": { 
                                                                value: account.isCreditApproved, 
                                                                dataType: 'Boolean' 
                                                            },
                                                            "Locations": { 
                                                                value: isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.totalLocations) ? metrics.totalLocations : 0, 
                                                                dataType: 'Integer', 
                                                                url: CanDo({ entityTypes: ['SHIPPER'] }) ? '/locations' : null,
                                                                onClick: CanDo({ entityTypes: ['SHIPPER'] }) ? null : () => { onTabChange('locations'); }
                                                            },
                                                            "Employees": { 
                                                                value: isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.totalEmployees) ? metrics.totalEmployees : 0, 
                                                                dataType: 'Integer', 
                                                                url: CanDo({ entityTypes: ['SHIPPER'] }) ? '/employees' : null,
                                                                onClick: CanDo({ entityTypes: ['SHIPPER'] }) ? null : () => { onTabChange('employees'); }
                                                            }
                                                        }}
                                                        extra={extra}
                                                        currentBreadcrumbName={currentBreadcrumbName}
                                                    />
                                                </Col>
                                                <Col lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                                    <ComplexDataLinkCard
                                                        title="Freight Spend"
                                                        style={{ border: '1px solid #b7eb8f' }}
                                                        headStyle={{ backgroundColor: '#f6ffed', borderBottom: '1px solid #b7eb8f', fontSize: 14 }}
                                                        data={{
                                                            "Total Spend": { 
                                                                value: isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.totalFreightSpend) ? Math.abs(metrics.totalFreightSpend) : 0, 
                                                                units: isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.totalFreightSpend) && isStringNotEmpty(metrics.totalFreightSpendUnit) ? metrics.totalFreightSpendUnit : null, 
                                                                dataType: 'Money' 
                                                            },
                                                            "Total Completed Loads": { 
                                                                value: isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.totalCompletedLoads) ? metrics.totalCompletedLoads : 0, 
                                                                dataType: 'Integer', 
                                                                url: CanDo({ entityTypes: ['SHIPPER'] }) ? '/completedLoads' : null,
                                                                onClick: CanDo({ entityTypes: ['SHIPPER'] }) ? null : () => { onTabChange('completedLoads'); }
                                                            },
                                                            "Spend This Month": { 
                                                                value: isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.totalFreightSpendThisMonth) ? Math.abs(metrics.totalFreightSpendThisMonth) : 0, 
                                                                units: isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.totalFreightSpendThisMonth) && isStringNotEmpty(metrics.totalFreightSpendThisMonthUnit) ? metrics.totalFreightSpendThisMonthUnit : null, 
                                                                dataType: 'Money' 
                                                            },
                                                            "Completed Loads This Mo": { 
                                                                value: isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.completedLoadsThisMonth) ? metrics.completedLoadsThisMonth : 0, 
                                                                dataType: 'Integer', 
                                                                url: CanDo({ entityTypes: ['SHIPPER'] }) ? '/completedLoads' : null,
                                                                onClick: CanDo({ entityTypes: ['SHIPPER'] }) ? null : () => { onTabChange('completedLoads'); }
                                                            }
                                                        }}
                                                        currentBreadcrumbName={currentBreadcrumbName}
                                                    />
                                                </Col>
                                                <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                                    <SimpleStatsLinkCard
                                                        style={{ border: '1px solid #ffa39e' }}
                                                        headStyle={{ backgroundColor: '#fff1f0', borderBottom: '1px solid #ffa39e', fontSize: 14 }}
                                                        title="Loads To Cover"
                                                        amountSize={1}
                                                        amount={isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.loadsNeedingCoverage) ? stringFormatter.toFormattedDecimal(metrics.loadsNeedingCoverage, 0, null, null) : 0}
                                                        url={CanDo({ entityTypes: ['SHIPPER'] }) ? '/openLoads' : null}
                                                        onClick={CanDo({ entityTypes: ['SHIPPER'] }) ? null : () => { onTabChange('openLoads'); }}
                                                        currentBreadcrumbName={currentBreadcrumbName}
                                                    />
                                                </Col>
                                                <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                                    <SimpleDataLinkCard
                                                        style={{ border: '1px solid #ffa39e' }}
                                                        headStyle={{ backgroundColor: '#fff1f0', borderBottom: '1px solid #ffa39e', fontSize: 14 }}
                                                        title="Requests for Proposals"
                                                        dataSize={3}
                                                        data={'Coming Soon'}
                                                        url={CanDo({ entityTypes: ['SHIPPER'] }) ? '/requestsForProposals' : null}
                                                        onClick={CanDo({ entityTypes: ['SHIPPER'] }) ? null : () => { onTabChange('requestsForProposals'); }}
                                                        currentBreadcrumbName={currentBreadcrumbName}
                                                    />
                                                </Col>
                                                <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                                    <SimpleDataLinkCard
                                                        style={{ border: '1px solid #ffa39e' }}
                                                        headStyle={{ backgroundColor: '#fff1f0', borderBottom: '1px solid #ffa39e', fontSize: 14 }}
                                                        title="Requests for Bids"
                                                        dataSize={3}
                                                        data={'Coming Soon'}
                                                        url={CanDo({ entityTypes: ['SHIPPER'] }) ? '/requestsForBids' : null}
                                                        onClick={CanDo({ entityTypes: ['SHIPPER'] }) ? null : () => { onTabChange('requestsForBids'); }}
                                                        currentBreadcrumbName={currentBreadcrumbName}
                                                    />
                                                </Col>
                                                <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                                    <SimpleDataLinkCard
                                                        title="Active Loads"
                                                        headStyle={{ fontSize: 14 }}
                                                        dataSize={1}
                                                        data={isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.totalActiveLoads) ? stringFormatter.toFormattedDecimal(metrics.totalActiveLoads, 0, null, null) : 0}
                                                        url={CanDo({ entityTypes: ['SHIPPER'] }) ? '/intransitLoads' : null}
                                                        onClick={CanDo({ entityTypes: ['SHIPPER'] }) ? null : () => { onTabChange('intransitLoads'); }}
                                                        currentBreadcrumbName={currentBreadcrumbName}
                                                    />
                                                </Col>
                                            </>
                                        ) : null}
                                        {account.entityTypes.includes('CARRIER') ? (
                                            <>
                                                <Col lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                                    <ComplexDataLinkCard
                                                        title="Dispatch Information"
                                                        style={{ border: '1px solid #ffe58f' }}
                                                        headStyle={{ backgroundColor: '#fffbe6', borderBottom: '1px solid #ffe58f', fontSize: 14 }}
                                                        data={{
                                                            "Assets Available": { 
                                                                value: `${isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.totalAssetsAvailable) ? metrics.totalAssetsAvailable : 0} / ${isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.totalAssets) ? metrics.totalAssets : 0}`, 
                                                                dataType: 'String', 
                                                                url: CanDo({ entityTypes: ['CARRIER'] }) ? '/assets' : null,
                                                                onClick: CanDo({ entityTypes: ['CARRIER'] }) ? null : () => { onTabChange('assets'); }
                                                            },
                                                            "Drivers Available": { 
                                                                value: `${isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.totalDriversAvailable) ? metrics.totalDriversAvailable : 0} / ${isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.totalDrivers) ? metrics.totalDrivers : 0}`, 
                                                                dataType: 'String', 
                                                                url: CanDo({ entityTypes: ['CARRIER'] }) ? '/drivers' : null,
                                                                onClick: CanDo({ entityTypes: ['CARRIER'] }) ? null : () => { onTabChange('drivers'); }
                                                            }
                                                        }}
                                                        currentBreadcrumbName={currentBreadcrumbName}
                                                    />
                                                </Col>
                                                <Col lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                                    <ComplexCard
                                                        title="SMS Basic Scores"
                                                        headStyle={{ fontSize: 14 }}
                                                    >
                                                        {isObjectNotEmpty(account.smsBasic) ? (<DataSMSBasicRow title="SMS Basic Scores" valueStyle={{ marginLeft: 16 }} smsBasic={account.smsBasic} />) : null}
                                                    </ComplexCard>
                                                </Col>
                                                <Col lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                                    <ComplexDataLinkCard
                                                        title="Earnings"
                                                        style={{ border: '1px solid #b7eb8f' }}
                                                        headStyle={{ backgroundColor: '#f6ffed', borderBottom: '1px solid #b7eb8f', fontSize: 14 }}
                                                        data={{
                                                            "Total Earnings": { 
                                                                value: isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.totalEarnings) ? Math.abs(metrics.totalEarnings) : 0, 
                                                                units: isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.totalEarnings) && isStringNotEmpty(metrics.totalEarningsUnit) ? metrics.totalEarningsUnit : null, 
                                                                dataType: 'Money' 
                                                            },
                                                            "Total Completed Loads": { 
                                                                value: isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.totalCompletedLoads) ? metrics.totalCompletedLoads : 0, 
                                                                dataType: 'Integer', 
                                                                url: CanDo({ entityTypes: ['CARRIER'] }) ? '/completedLoads' : null,
                                                                onClick: CanDo({ entityTypes: ['CARRIER'] }) ? null : () => { onTabChange('completedLoads'); }
                                                            },
                                                            "Earnings This Mo": { 
                                                                value: isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.totalEarningsThisMonth) ? Math.abs(metrics.totalEarningsThisMonth) : 0, 
                                                                units: isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.totalEarningsThisMonth) && isStringNotEmpty(metrics.totalEarningsThisMonthUnit) ? metrics.totalEarningsThisMonthUnit : null, 
                                                                dataType: 'Money' 
                                                            },
                                                            "Completed Loads This Mo": { 
                                                                value: isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.completedLoadsThisMonth) ? metrics.completedLoadsThisMonth : 0, 
                                                                dataType: 'Integer', 
                                                                url: CanDo({ entityTypes: ['CARRIER'] }) ? '/completedLoads' : null,
                                                                onClick: CanDo({ entityTypes: ['CARRIER'] }) ? null : () => { onTabChange('completedLoads'); }
                                                                
                                                            }
                                                        }}
                                                        currentBreadcrumbName={currentBreadcrumbName}
                                                    />
                                                </Col>
                                                <Col lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                                    <SimpleDataLinkCard
                                                        style={{ border: '1px solid #ffa39e' }}
                                                        headStyle={{ backgroundColor: '#fff1f0', borderBottom: '1px solid #ffa39e', fontSize: 14 }}
                                                        title="Requests for Proposals"
                                                        dataSize={3}
                                                        data={'Coming Soon'}
                                                        url={CanDo({ entityTypes: ['CARRIER'] }) ? '/requestsForProposals' : null}
                                                        onClick={CanDo({ entityTypes: ['CARRIER'] }) ? null : () => { onTabChange('requestsForProposals'); }}
                                                        currentBreadcrumbName={currentBreadcrumbName}
                                                    />
                                                </Col>
                                                <Col lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                                    <SimpleDataLinkCard
                                                        style={{ border: '1px solid #ffa39e' }}
                                                        headStyle={{ backgroundColor: '#fff1f0', borderBottom: '1px solid #ffa39e', fontSize: 14 }}
                                                        title="Requests for Bids"
                                                        dataSize={3}
                                                        data={'Coming Soon'}
                                                        url={CanDo({ entityTypes: ['CARRIER'] }) ? '/requestsForBids' : null}
                                                        onClick={CanDo({ entityTypes: ['CARRIER'] }) ? null : () => { onTabChange('requestsForBids'); }}
                                                        currentBreadcrumbName={currentBreadcrumbName}
                                                    />
                                                </Col>
                                                <Col lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                                    <SimpleDataLinkCard
                                                        style={{ border: '1px solid #ffa39e' }}
                                                        headStyle={{ backgroundColor: '#fff1f0', borderBottom: '1px solid #ffa39e', fontSize: 14 }}
                                                        title="Load Requests"
                                                        dataSize={1}
                                                        data={isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.totalLoadRequests) ? metrics.totalLoadRequests : 0}
                                                        url={CanDo({ entityTypes: ['CARRIER'] }) ? '/loadRequests' : null}
                                                        onClick={CanDo({ entityTypes: ['CARRIER'] }) ? null : () => { onTabChange('loadRequests'); }}
                                                        currentBreadcrumbName={currentBreadcrumbName}
                                                    />
                                                </Col>
                                                <Col lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                                    <SimpleDataLinkCard
                                                        style={{ border: '1px solid #ffa39e' }}
                                                        headStyle={{ backgroundColor: '#fff1f0', borderBottom: '1px solid #ffa39e', fontSize: 14 }}
                                                        title="Loads to Dispatch"
                                                        dataSize={1}
                                                        data={isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.totalLoadsToDispatch) ? metrics.totalLoadsToDispatch : 0}
                                                        url={CanDo({ entityTypes: ['CARRIER'] }) ? '/openLoads' : null}
                                                        onClick={CanDo({ entityTypes: ['CARRIER'] }) ? null : () => { onTabChange('openLoads'); }}
                                                        currentBreadcrumbName={currentBreadcrumbName}
                                                    />
                                                </Col>
                                                <Col lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                                    <SimpleDataLinkCard
                                                        title="Active Loads"
                                                        headStyle={{ fontSize: 14 }}
                                                        dataSize={1}
                                                        data={isObjectNotEmpty(metrics) && isNumberNotEmpty(metrics.totalActiveLoads) ? stringFormatter.toFormattedDecimal(metrics.totalActiveLoads, 0, null, null) : 0}
                                                        url={CanDo({ entityTypes: ['CARRIER'] }) ? '/intransitLoads' : null}
                                                        onClick={CanDo({ entityTypes: ['CARRIER'] }) ? null : () => { onTabChange('intransitLoads'); }}
                                                        currentBreadcrumbName={currentBreadcrumbName}
                                                    />
                                                </Col>
                                            </>
                                        ) : null}
                                        {isObjectNotEmpty(account.assetNeeds) && isBooleanTrue(account.entityTypes.includes("SHIPPER")) ? (
                                            <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                                <ComplexCard
                                                    title="Equipment Needs"
                                                    headStyle={{ fontSize: 14 }}
                                                >
                                                    <Row gutter={[16, 16]}>
                                                        <Col lg={{ span: 24 }} md={{ span: 24 }}>
                                                            <h3 style={{ marginTop: 12, marginBottom: 12 }}>Reefers</h3>
                                                            <DataRow title="Do you need 48' Reefers?" value={account.assetNeeds.has48Reefer} dataType="Boolean" />
                                                            <DataRow title="Do you need 53' Reefers?" value={account.assetNeeds.has53Reefer} dataType="Boolean" />
                                                            <DataRow title="Do you need to ship frozen?" value={account.assetNeeds.hasReeferFrozen} dataType="Boolean" />
                                                            <h3 style={{ marginTop: 12, marginBottom: 12 }}>Dry Vans</h3>
                                                            <DataRow title="Do you need 48' Dry Vans?" value={account.assetNeeds.has48DryVan} dataType="Boolean" />
                                                            <DataRow title="Do you need 53' Dry Vans?" value={account.assetNeeds.has53DryVan} dataType="Boolean" />
                                                            <DataRow title="Do you need lift gates for the dry vans?" value={account.assetNeeds.hasDryVanLiftGates} dataType="Boolean" />
                                                            <DataRow title="Do you need roller beds for the dry vans?" value={account.assetNeeds.hasDryVanRollerBed} dataType="Boolean" />
                                                            <h3 style={{ marginTop: 12, marginBottom: 12 }}>Flatbeds</h3>
                                                            <DataRow title="Do you need 48' Flatbeds?" value={account.assetNeeds.has48Flatbed} dataType="Boolean" />
                                                            <DataRow title="Do you need 53' Flatbeds?" value={account.assetNeeds.has53Flatbed} dataType="Boolean" />
                                                            <h3 style={{ marginTop: 12, marginBottom: 12 }}>Over-Sized Loads</h3>
                                                            <DataRow title="Do you need to ship over dimensional/overweight loads?" value={account.assetNeeds.hasOverSized} dataType="Boolean" />
                                                        </Col>
                                                        {/* <Col lg={{ span: 24 }} md={{ span: 24 }}>
                                                            <h3 style={{ marginTop: 12, marginBottom: 12 }}>Low Boys</h3>
                                                            <DataRow title="Do you need Low Boys?" value={account.assetNeeds.hasLowBoy} dataType="Boolean" />
                                                            <h3 style={{ marginTop: 12, marginBottom: 12 }}>RGNs</h3>
                                                            <DataRow title="Do you need RGNs?" value={account.assetNeeds.hasRGN} dataType="Boolean" />
                                                            <h3 style={{ marginTop: 12, marginBottom: 12 }}>Step Decks</h3>
                                                            <DataRow title="Do you need Step Decks with Ramps?" value={account.assetNeeds.hasStepDeckRamps} dataType="Boolean" />
                                                            <DataRow title="Do you need Step Decks without Ramps?" value={account.assetNeeds.hasStepDeckNoRamps} dataType="Boolean" />
                                                            <h3 style={{ marginTop: 12, marginBottom: 12 }}>Tankers</h3>
                                                            <DataRow title="Do you need Single Compartment Tankers?" value={account.assetNeeds.hasTankersSingleCompartment} dataType="Boolean" />
                                                            <DataRow title="Do you need Multi Compartment Tankers?" value={account.assetNeeds.hasTankersMultiCompartment} dataType="Boolean" />
                                                            <DataRow title="Do you need Fiber Glass Tankers?" value={account.assetNeeds.hasTankersFiberGlass} dataType="Boolean" />
                                                            <h3 style={{ marginTop: 12, marginBottom: 12 }}>Straight Trucks</h3>
                                                            <DataRow title="Do you need Cubed Straight Trucks?" value={account.assetNeeds.hasCubedStraightTruck} dataType="Boolean" />
                                                            <DataRow title="Do you need 26' Straight Trucks?" value={account.assetNeeds.has26StraightTruck} dataType="Boolean" />
                                                            <DataRow title="Do you need lift gates for the straight trucks?" value={account.assetNeeds.hasStraightTruckLiftGates} dataType="Boolean" />
                                                            <h3 style={{ marginTop: 12, marginBottom: 12 }}>Sprinter Vans</h3>
                                                            <DataRow title="Do you need Sprinter Vans?" value={account.assetNeeds.hasSprinterVan} dataType="Boolean" />
                                                        </Col> */}
                                                    </Row>
                                                </ComplexCard>
                                            </Col>
                                        ) : null}

                                        {isObjectNotEmpty(account.assetNeeds) && isBooleanTrue(account.entityTypes.includes("CARRIER")) ? (
                                            <>
                                                <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                                    <ComplexCard
                                                        title="Asset Details"
                                                        headStyle={{ fontSize: 14 }}
                                                    >
                                                        <Row gutter={[16, 16]}>
                                                        <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                                                <DataRow title="# of Power Units" value={account.assetNeeds.numPowerUnits} dataType="Integer" />
                                                            </Col>
                                                            <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                                                <DataRow title="# of Trailers" value={account.assetNeeds.numTrailers} dataType="Integer" />
                                                            </Col>
                                                            <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                                                <DataRow title="# of Drivers" value={account.assetNeeds.numDrivers} dataType="Integer" />
                                                            </Col>
                                                            <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                                                <DataRow title="# of HazMat Drivers" value={account.assetNeeds.numHazMatDrivers} dataType="Integer" />
                                                            </Col>
                                                            <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                                                <DataRow title="# of Teams" value={account.assetNeeds.numTeams} dataType="Integer" />
                                                            </Col>
                                                        </Row>
                                                    </ComplexCard>
                                                </Col>
                                                <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                                    <ComplexCard
                                                        title="Trailer Types"
                                                        headStyle={{ fontSize: 14 }}
                                                    >
                                                        <Row gutter={[16, 16]}>
                                                            <Col lg={{ span: 24 }} md={{ span: 24 }}>
                                                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Reefers</h3>
                                                                <DataRow title="Do you have 48' Reefers?" value={account.assetNeeds.has48Reefer} dataType="Boolean" />
                                                                <DataRow title="If so, how many?" value={account.assetNeeds.num48Reefer} dataType="Integer" />
                                                                <DataRow title="Do you have 53' Reefers?" value={account.assetNeeds.has53Reefer} dataType="Boolean" />
                                                                <DataRow title="If so, how many?" value={account.assetNeeds.num53Reefer} dataType="Integer" />
                                                                <DataRow title="Do you haul frozen?" value={account.assetNeeds.hasReeferFrozen} dataType="Boolean" />
                                                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Dry Vans</h3>
                                                                <DataRow title="Do you have 48' Dry Vans?" value={account.assetNeeds.has48DryVan} dataType="Boolean" />
                                                                <DataRow title="If so, how many?" value={account.assetNeeds.num48DryVan} dataType="Integer" />
                                                                <DataRow title="Do you have 53' Dry Vans?" value={account.assetNeeds.has53DryVan} dataType="Boolean" />
                                                                <DataRow title="If so, how many?" value={account.assetNeeds.num53DryVan} dataType="Integer" />
                                                                <DataRow title="Do you have lift gates for the dry vans?" value={account.assetNeeds.hasDryVanLiftGates} dataType="Boolean" />
                                                                <DataRow title="Do you have roller beds for the dry vans?" value={account.assetNeeds.hasDryVanRollerBed} dataType="Boolean" />
                                                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Flatbeds</h3>
                                                                <DataRow title="Do you have 48' Flatbeds?" value={account.assetNeeds.has48Flatbed} dataType="Boolean" />
                                                                <DataRow title="If so, how many?" value={account.assetNeeds.num48Flatbed} dataType="Integer" />
                                                                <DataRow title="Do you have 53' Flatbeds?" value={account.assetNeeds.has53Flatbed} dataType="Boolean" />
                                                                <DataRow title="If so, how many?" value={account.assetNeeds.num53Flatbed} dataType="Integer" />
                                                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Over-Sized Loads</h3>
                                                                <DataRow title="Do you haul over dimensional/overweight loads?" value={account.assetNeeds.hasOverSized} dataType="Boolean" />
                                                            </Col>
                                                            {/* <Col lg={{ span: 24 }} md={{ span: 24 }}>
                                                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Low Boys</h3>
                                                                <DataRow title="Do you have Low Boys?" value={account.assetNeeds.hasLowBoy} dataType="Boolean" />
                                                                <DataRow title="If so, how many?" value={account.assetNeeds.numLowBoy} dataType="Integer" />
                                                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>RGNs</h3>
                                                                <DataRow title="Do you have RGNs?" value={account.assetNeeds.hasRGN} dataType="Boolean" />
                                                                <DataRow title="If so, how many?" value={account.assetNeeds.numRGN} dataType="Integer" />
                                                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Step Decks</h3>
                                                                <DataRow title="Do you have Step Decks with Ramps?" value={account.assetNeeds.hasStepDeckRamps} dataType="Boolean" />
                                                                <DataRow title="If so, how many?" value={account.assetNeeds.numStepDeckRamps} dataType="Integer" />
                                                                <DataRow title="Do you have Step Decks without Ramps?" value={account.assetNeeds.hasStepDeckNoRamps} dataType="Boolean" />
                                                                <DataRow title="If so, how many?" value={account.assetNeeds.numStepDeckNoRamps} dataType="Integer" />
                                                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Tankers</h3>
                                                                <DataRow title="Do you have Single Compartment Tankers?" value={account.assetNeeds.hasTankersSingleCompartment} dataType="Boolean" />
                                                                <DataRow title="If so, how many?" value={account.assetNeeds.numTankersSingleCompartment} dataType="Integer" />
                                                                <DataRow title="Do you have Multi Compartment Tankers?" value={account.assetNeeds.hasTankersMultiCompartment} dataType="Boolean" />
                                                                <DataRow title="If so, how many?" value={account.assetNeeds.numTankersMultiCompartment} dataType="Integer" />
                                                                <DataRow title="Do you have Fiber Glass Tankers?" value={account.assetNeeds.hasTankersFiberGlass} dataType="Boolean" />
                                                                <DataRow title="If so, how many?" value={account.assetNeeds.numTankersFiberGlass} dataType="Integer" />
                                                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Straight Trucks</h3>
                                                                <DataRow title="Do you have Cubed Straight Trucks?" value={account.assetNeeds.hasCubedStraightTruck} dataType="Boolean" />
                                                                <DataRow title="If so, how many?" value={account.assetNeeds.numCubedStraightTruck} dataType="Integer" />
                                                                <DataRow title="Do you have 26' Straight Trucks?" value={account.assetNeeds.has26StraightTruck} dataType="Boolean" />
                                                                <DataRow title="If so, how many?" value={account.assetNeeds.num26StraightTruck} dataType="Integer" />
                                                                <DataRow title="Do you have lift gates for the straight trucks?" value={account.assetNeeds.hasStraightTruckLiftGates} dataType="Boolean" />
                                                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Sprinter Vans</h3>
                                                                <DataRow title="Do you have Sprinter Vans?" value={account.assetNeeds.hasSprinterVan} dataType="Boolean" />
                                                                <DataRow title="If so, how many?" value={account.assetNeeds.numSprinterVan} dataType="Integer" />
                                                            </Col> */}
                                                        </Row>
                                                    </ComplexCard>
                                                </Col>
                                            </>
                                        ) : null}

                                        {account.entityTypes.includes('SHIPPER') || account.entityTypes.includes('BROKER') || account.entityTypes.includes('FACTORING') ? (
                                            <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                                <ComplexCard
                                                    title="Accounts Payable"
                                                    headStyle={{ fontSize: 14 }}
                                                >
                                                    {isObjectNotEmpty(account.accountsPayable) ? <DataContactInfoRow title="A/P Contact" separateLines={false} valueStyle={{ paddingLeft: 16 }} contact={account.accountsPayable} /> : null}
                                                    {isObjectNotEmpty(account.accountsPayableAddress) ? <DataAddressRow title="A/P Address" oneLine={false} valueStyle={{ paddingLeft: 16 }} address={account.accountsPayableAddress} /> : null}
                                                </ComplexCard>
                                            </Col>
                                        ) : null}
                                        {account.entityTypes.includes('CARRIER') || account.entityTypes.includes('BROKER') || account.entityTypes.includes('FACTORING') ? (
                                            <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                                <ComplexCard
                                                    title="Accounts Receivable"
                                                    headStyle={{ fontSize: 14 }}
                                                >
                                                    {isObjectNotEmpty(account.accountsReceivable) ? <DataContactInfoRow title="A/R Contact" separateLines={false} valueStyle={{ paddingLeft: 16 }} contact={account.accountsReceivable} /> : null}
                                                    {isObjectNotEmpty(account.accountsReceivableAddress) ? <DataAddressRow title="A/R Address" oneLine={false} valueStyle={{ paddingLeft: 16 }} address={account.accountsReceivableAddress} /> : null}
                                                    {isStringNotEmpty(account.preferredPaymentMethod) ? <DataRow title="Preferred Payment Method" value={account.preferredPaymentMethod} dataType="PreferredPaymentMethod" /> : null}
                                                </ComplexCard>
                                            </Col>
                                        ) : null}
                                    </Row>
                                ) : null}
                            </div>
                        </TabPane>
                        <TabPane tab="Documents" key="documents">
                            <div style={{ padding: 24 }}>
                                {isObjectNotEmpty(account) ? (
                                    <DocumentList
                                        documentEntityType="ACCOUNT"
                                        account={account}
                                        documentEntityId={account.id}
                                        updatePendingApprovalCount={setPendingApprovalCount}
                                        updateMissingDocumentsCount={setMissingDocumentsCount}
                                        updateExpiringSoonDocumentsCount={setExpiringSoonDocumentsCount}
                                        updateExpiredDocumentsCount={setExpiredDocumentsCount}
                                    />
                                ) : null}
                                {isObjectNotEmpty(account) && isBooleanFalse(account.isVerified) ? (
                                    <CanSee entityAction="VERIFY" entityModel="ACCOUNT" entityObject={account}>
                                        <Button style={{ marginTop: 20 }} type='primary' onClick={() => { verifyAccount(account.id); }} disabled={pendingApprovalCount > 0 || missingDocumentsCount > 0 || expiredDocumentsCount > 0}>Verify Account</Button>
                                    </CanSee>
                                ) : null}
                            </div>
                        </TabPane>

                        {(account.entityTypes.includes("CARRIER") || account.entityTypes.includes("BROKER")) && (
                            <TabPane tab="Load Requests" key="loadRequests">
                                <div style={{ padding: 24 }}>
                                    <LoadRequests
                                        carrierId={account.entityTypes.includes("CARRIER") ? id : null}
                                        brokerId={account.entityTypes.includes("BROKER") ? id : null}
                                        breadcrumbs={breadcrumbs}
                                    />
                                </div>
                            </TabPane>
                        )}
                        {(account.entityTypes.includes("CARRIER") || account.entityTypes.includes("SHIPPER") || account.entityTypes.includes("BROKER")) && (
                            <TabPane tab="Open Loads" key="openLoads">
                                <div style={{ padding: 24 }}>
                                    <OpenLoads
                                        carrierId={account.entityTypes.includes("CARRIER") ? id : null}
                                        shipperId={account.entityTypes.includes("SHIPPER") ? id : null}
                                        brokerId={account.entityTypes.includes("BROKER") ? id : null}
                                        breadcrumbs={breadcrumbs}
                                    />
                                </div>
                            </TabPane>
                        )}
                        {(account.entityTypes.includes("CARRIER") || account.entityTypes.includes("BROKER")) && (
                            <TabPane tab="Dispatch" key="dispatch">
                                <Dispatch
                                    carrierId={account.entityTypes.includes("CARRIER") ? id : null}
                                    brokerId={account.entityTypes.includes("BROKER") ? id : null}
                                    breadcrumbs={breadcrumbs}
                                />
                            </TabPane>
                        )}
                        {(account.entityTypes.includes("CARRIER") || account.entityTypes.includes("SHIPPER") || account.entityTypes.includes("BROKER")) && (
                            <TabPane tab="Booked Loads" key="bookedLoads">
                                <div style={{ padding: 24 }}>
                                    <BookedLoads
                                        carrierId={account.entityTypes.includes("CARRIER") ? id : null}
                                        shipperId={account.entityTypes.includes("SHIPPER") ? id : null}
                                        brokerId={account.entityTypes.includes("BROKER") ? id : null}
                                        breadcrumbs={breadcrumbs}
                                    />
                                </div>
                            </TabPane>
                        )}
                        {(account.entityTypes.includes("CARRIER") || account.entityTypes.includes("SHIPPER") || account.entityTypes.includes("BROKER")) && (
                            <TabPane tab="In Transit Loads" key="intransitLoads">
                                <div style={{ padding: 24 }}>
                                    <InTransitLoads
                                        carrierId={account.entityTypes.includes("CARRIER") ? id : null}
                                        shipperId={account.entityTypes.includes("SHIPPER") ? id : null}
                                        brokerId={account.entityTypes.includes("BROKER") ? id : null}
                                        breadcrumbs={breadcrumbs}
                                    />
                                </div>
                            </TabPane>
                        )}
                        {(account.entityTypes.includes("CARRIER") || account.entityTypes.includes("SHIPPER") || account.entityTypes.includes("BROKER")) && (
                            <TabPane tab="Completed Loads" key="completedLoads">
                                <div style={{ padding: 24 }}>
                                    <QueueLoads
                                        title="Completed Loads"
                                        filter={`COMPLETED_LOADS_${id}`}
                                        filterSearchParams={{
                                            sort: 'loadEndDateTime',
                                            order: 'desc',
                                            isDeleted: false,
                                            eta: false
                                        }}
                                        defaultLoadStatusFilter={['COMPLETED', 'REOPENED', 'CLOSED', 'CANCELLED']}
                                        showTable={true}
                                        carrierId={account.entityTypes.includes("CARRIER") ? id : null}
                                        shipperId={account.entityTypes.includes("SHIPPER") ? id : null}
                                        brokerId={account.entityTypes.includes("BROKER") ? id : null}
                                        breadcrumbs={breadcrumbs}
                                        showCompletedLoadData={true}
                                        showAccountsPayable={CanDo({ staffOnly: true })}
                                        showAccountsReceivable={CanDo({ staffOnly: true })}
                                    />
                                </div>
                            </TabPane>
                        )}
                        {(account.entityTypes.includes("CARRIER") || account.entityTypes.includes("BROKER")) && (
                            <TabPane tab="Lanes" key="lanes">
                                <div style={{ padding: 24 }}>
                                    <PreferredLanes accountId={id} breadcrumbs={breadcrumbs} />
                                </div>
                            </TabPane>
                        )}
                        {(account.entityTypes.includes("CARRIER") || account.entityTypes.includes("BROKER")) && (
                            <TabPane tab="Customer Lanes" key="customerLanes">
                                <div style={{ padding: 24 }}>
                                    <LinkedLoadLanes accountId={id} breadcrumbs={breadcrumbs} />
                                </div>
                            </TabPane>
                        )}
                        {(account.entityTypes.includes("CARRIER") || account.entityTypes.includes("BROKER")) && (
                            <TabPane tab="RFP's & RPB's" key="requestsForBids">
                                <div style={{ padding: 24 }}>
                                    Under Contruction
                                </div>
                            </TabPane>
                        )}
                        {(account.entityTypes.includes("SHIPPER")) && (
                            <TabPane tab="Lanes & RFP's" key="lanes">
                                <div style={{ padding: 24 }}>
                                    <LoadLanes accountId={id} />
                                </div>
                            </TabPane>
                        )}
                        {(account.entityTypes.includes("SHIPPER") || account.entityTypes.includes("CARRIER") || account.entityTypes.includes("PRODUCER") || account.entityTypes.includes("RECEIVER") || account.entityTypes.includes("BROKER")) && (
                            <TabPane tab="Locations" key="locations">
                                <div style={{ padding: 24 }}>
                                    <Locations accountId={id} />
                                </div>
                            </TabPane>
                        )}
                        {account.entityTypes.includes("CARRIER") && (
                            <TabPane tab="Assets" key="assets">
                                <div style={{ padding: 24 }}>
                                    <Assets accountId={id} breadcrumbs={breadcrumbs} />
                                </div>
                            </TabPane>
                        )}
                        {account.entityTypes.includes("CARRIER") && (
                            <TabPane tab="Drivers" key="drivers">
                                <div style={{ padding: 24 }}>
                                    <Drivers accountId={id} breadcrumbs={breadcrumbs} />
                                </div>
                            </TabPane>
                        )}
                        <TabPane tab="Employees" key="employees">
                            <div style={{ padding: 24 }}>
                                <Employees accountId={id} breadcrumbs={breadcrumbs} />
                            </div>
                        </TabPane>
                        {(account.entityTypes.includes("CARRIER") || account.entityTypes.includes("BROKER") || account.entityTypes.includes("FACTORING")) && (
                            <TabPane tab="Shippers" key="shippers">
                                <div style={{ padding: 24 }}>
                                    <LinkedShippers accountId={id} breadcrumbs={breadcrumbs} />
                                </div>
                            </TabPane>
                        )}
                        {(account.entityTypes.includes("SHIPPER") || account.entityTypes.includes("BROKER") || account.entityTypes.includes("FACTORING")) && (
                            <TabPane tab="Carriers" key="carriers">
                                <div style={{ padding: 24 }}>
                                    <LinkedCarriers accountId={id} breadcrumbs={breadcrumbs} />
                                </div>
                            </TabPane>
                        )}
                        {(account.entityTypes.includes("SHIPPER") || account.entityTypes.includes("CARRIER") || account.entityTypes.includes("BROKER")) && (
                            <TabPane tab="Receivers" key="receivers">
                                <div style={{ padding: 24 }}>
                                    <LinkedReceivers accountId={id} breadcrumbs={breadcrumbs} />
                                </div>
                            </TabPane>
                        )}
                        {(account.entityTypes.includes("SHIPPER") || account.entityTypes.includes("CARRIER") || account.entityTypes.includes("BROKER")) && (
                            <TabPane tab="Producers" key="producers">
                                <div style={{ padding: 24 }}>
                                    <LinkedProducers accountId={id} breadcrumbs={breadcrumbs} />
                                </div>
                            </TabPane>
                        )}
                        {(account.entityTypes.includes("SHIPPER") || account.entityTypes.includes("CARRIER") || account.entityTypes.includes("FACTORING")) && (
                            <TabPane tab="Brokerages" key="brokerages">
                                <div style={{ padding: 24 }}>
                                    <LinkedBrokers accountId={id} breadcrumbs={breadcrumbs} />
                                </div>
                            </TabPane>
                        )}
                        {(account.entityTypes.includes("CARRIER")) && (
                            <TabPane tab="Factoring Companies" key="factorings">
                                <div style={{ padding: 24 }}>
                                    <LinkedFactorings accountId={id} breadcrumbs={breadcrumbs} />
                                </div>
                            </TabPane>
                        )}
                        {(account.entityTypes.includes("CARRIER")) && CanDo({ staffOnly: true }) && (
                            <TabPane tab="A/P" key="ap">
                                <div style={{ padding: 24 }}>
                                    <QueueCompletedLoads
                                        showAccountsPayable={true}
                                        showProofOfDelivery={true}
                                        showRequiredDocumentStatus={true}
                                        showClaimsStatus={true}
                                        carrierId={id}
                                        title="Accounts Payable"
                                        filter={`COMPLETED_AP_${id}`}
                                        defaultLoadStatusFilter={['COMPLETED', 'REOPENED']}
                                        filterSearchParams={{
                                            'completionCheckList.carrierInvoiceStatus:not': 'COMPLETED'
                                        }}
                                        showTable={true}
                                    />
                                </div>
                            </TabPane>
                        )}
                        {(account.entityTypes.includes("CARRIER")) && CanDo({ staffOnly: true }) && (
                            <TabPane tab="A/R" key="ar">
                                <div style={{ padding: 24 }}>
                                    <QueueCompletedLoads
                                        showAccountsReceivable={true}
                                        showProofOfDelivery={true}
                                        showRequiredDocumentStatus={true}
                                        showClaimsStatus={true}
                                        shipperId={id}
                                        title="Accounts Receivable"
                                        filter={`COMPLETED_AR_${id}`}
                                        defaultLoadStatusFilter={['COMPLETED', 'REOPENED']}
                                        filterSearchParams={{
                                            'completionCheckList.shipperInvoiceStatus:not': 'COMPLETED'
                                        }}
                                        showTable={true}
                                    />
                                </div>
                            </TabPane>
                        )}
                    </Tabs>

                    {editEntityComponents}
                    {documentsComponent}
                    {editMissingAccountInformationDrawer}
                </CanSee>
            </Spin>
        );
    } else {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={true} />
        );
    }
};

export default withRouter(Account);