import React from 'react';
import classes from './DetailsList.module.scss';

const DetailsList = ({ style = null, children }) => {
    return (
        <div className={classes.detailsListContainer}>
            <div className={classes.detailsList}>
                <table style={style}>
                    <tbody>
                        {children}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default DetailsList;