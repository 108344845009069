import axiosAuthenticated from "../../api/axiosAuthenticated";
import { isNotNullOrUndefined } from "../../shared/objectUtils";
import * as DTO from "../dtos/index";

const accountRegistrationsPath = '/accountRegistrations';

//#region Account Registrations Methods

export const getAccountRegistrations = async (searchParams = {}, pagination = {}, staff = []) => {
    const accountRegistrationsRes = await axiosAuthenticated.get(accountRegistrationsPath, { params: { ...searchParams } });
    if (accountRegistrationsRes && accountRegistrationsRes.status === 200) {
        const accountRegistrations = accountRegistrationsRes.data.data;
        const otherData = accountRegistrationsRes.data;

        const transformedAccountRegistrations = accountRegistrations.map((accountRegistration) => { return DTO.getAccountRegistrationDTO(accountRegistration, staff); });

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;

        return { data: transformedAccountRegistrations, searchParams: searchParams, pagination: pagination };
    }

    return { data: [], searchParams: searchParams, pagination: pagination };
};

export const getAccountRegistration = async (id, staff = []) => {
    const accountRegistrationsRes = await axiosAuthenticated.get(accountRegistrationsPath + `/${id}`);
    if (accountRegistrationsRes && accountRegistrationsRes.status === 200) {
        let accountRegistration = accountRegistrationsRes.data;

        return DTO.getAccountRegistrationDTO(accountRegistration, staff);
    }

    return null;
};

export const addAccountRegistration = async (payload, staff = []) => {
    const accountRegistrationsRes = await axiosAuthenticated.post(accountRegistrationsPath, { ...payload });
    if (accountRegistrationsRes && accountRegistrationsRes.status === 201) {
        let newAccountRegistration = accountRegistrationsRes.data;

        return DTO.getAccountRegistrationDTO(newAccountRegistration, staff);
    }

    return null;
};

export const updateAccountRegistration = async (id, payload, staff = []) => {
    const accountRegistrationsRes = await axiosAuthenticated.put(accountRegistrationsPath + `/${id}`, { ...payload });
    if (accountRegistrationsRes && accountRegistrationsRes.status === 200) {
        let updatedAccountRegistration = accountRegistrationsRes.data;

        return DTO.getAccountRegistrationDTO(updatedAccountRegistration, staff);
    }

    return null;
};

export const removeAccountRegistration = async (id) => {
    const accountRegistrationsRes = await axiosAuthenticated.put(accountRegistrationsPath + `/${id}`, { isDeleted: true });
    if (accountRegistrationsRes && accountRegistrationsRes.status === 200) {
        return accountRegistrationsRes.data;
    }

    return null;
};

//#endregion