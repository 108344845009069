import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { Spin, Tabs, Button, Drawer, Row, Col, Menu, Dropdown, message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhoneAlt, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import Header from '../../components/Header/Header';
import { useLocation } from 'react-router';
import { ControlOutlined, DownOutlined, MailOutlined } from '@ant-design/icons';
import EditUser from "../../components/EditUser/EditUser";
import ComplexDataLinkCard from '../../components/ComplexDataLinkCard/ComplexDataLinkCard';
import DataAddressRow from '../../components/DataAddressRow/DataAddressRow';
import DataRow from '../../components/DataRow/DataRow';
import OpenLoads from "../../views/OpenLoads/OpenLoads";
import BookedLoads from "../../views/BookedLoads/BookedLoads";
import InTransitLoads from "../../views/InTransitLoads/InTransitLoads";
import CompletedLoads from "../../views/CompletedLoads/CompletedLoads";
import * as Data from '../../api/data';
import { isBooleanTrue, isListNotEmpty, isNotNullOrUndefined, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import logger from '../../shared/logger';
import StringFormatter from '../../shared/stringFormatter';
import LoadRequests from '../LoadRequests/LoadRequests';
import { Can, CanDo } from '../../shared/entitlements/entitlements';
import QueueCompletedLoads from '../../components/QueueCompletedLoads/QueueCompletedLoads';
import QueueLoads from '../../components/QueueLoads/QueueLoads';

const { TabPane } = Tabs;
const stringFormatter = new StringFormatter();

const User = (props) => {
    //#region props and constants

    const fullWidth = global.window.innerWidth;
    const userProfileId = props.match.params.userProfileId;
    const previousBreadcrumbs = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isListNotEmpty(props.history.location.state.previousBreadcrumbs) ? props.history.location.state.previousBreadcrumbs : [];
    const previousLocation = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageLocation) ? props.history.location.state.previousPageLocation : { pathname: `/users` };
    const previousPath = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageLocation) ? props.history.location.state.previousPageLocation : `/users`;
    const previousBreadcrumbName = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageTitle) ? props.history.location.state.previousPageTitle : 'Users';

    //#endregion
    //#region useLocation

    const location = useLocation();

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.users.isRecordLoading);
    const error = useSelector(state => state.users.recordError);
    const user = useSelector(state => state.users.record);

    //#endregion
    //#region useStates

    const [tabKey, setTabKey] = useState('snapshot');
    const [imageUrl, setImageUrl] = useState(null);
    const [showEditEntity, setShowEditEntity] = useState(false);

    //#endregion
    //#region toggles

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    //#endregion
    //#region tab methods

    const onTabChange = (key) => {
        props.history.replace({ ...props.location, hash: `#${key}` });
    };

    //#endregion
    //#region user methods

    const resendWelcomeEmail = () => {
        if (isObjectNotEmpty(user)) {
            const email = user.user;
            if (isStringNotEmpty(email)) {
                Data.forgotPassword(email).then((res) => {
                    if (res === true) {
                        message.success('A welcome email has been sent to this user.');
                    } else {
                        message.error('Failed to send the welcome email has been sent to this user.');
                    }
                }).catch(err => {
                    logger.logDebugEvent('User.js', err.message, true);
                    message.error('Failed to send the welcome email has been sent to this user.');
                });
            }
        }
    };

    //#endregion
    //#region useMemos and useEffects

    useMemo(() => {
        if (isStringNotEmpty(location.hash)) {
            setTabKey(location.hash.replace('#', ''));
        } else {
            setTabKey('snapshot');
            props.history.replace({ ...location, hash: `#snapshot` });
        }
    }, [location]);

    useMemo(() => {
        dispatch(actionCreators.fetchUser(userProfileId));
    }, [userProfileId]);

    useEffect(() => {
        if (isObjectNotEmpty(user) && isStringNotEmpty(user.profileImageId)) {
            Data.getImageByDocumentId(user.profileImageId).then((data) => {
                const blob = new Blob([data], {
                    type: 'image/png',
                });
                setImageUrl(URL.createObjectURL(blob));
            }).catch(err => {
                logger.logDebugEvent('User.js', err.message, true);
            });
        }
    }, [user]);

    //#endregion
    //#region displays

    const editEntityComponents = (
        <Drawer
            title={"Edit User Profile"}
            onClose={toggleEditEntity}
            visible={showEditEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditUser
                cancel={toggleEditEntity}
                user={user}
            />
        </Drawer>
    );

    const menu = () => {
        return (
            <Menu>
                {CanDo({ entityAction: 'UPDATE', entityModel: 'USER', entityObject: user }) &&
                    <Menu.Item key="welcomeUser" onClick={(e) => { e.domEvent.stopPropagation(); resendWelcomeEmail(); }}>
                        <MailOutlined style={{ marginRight: 8 }} />
                        <span>Re-Send Welcome Email</span>
                    </Menu.Item>
                }
                {CanDo({ entityAction: 'UPDATE', entityModel: 'USER', entityObject: user }) &&
                    <Menu.Item key="editUser" onClick={(e) => { e.domEvent.stopPropagation(); toggleEditEntity(); }}>
                        <FontAwesomeIcon className="anticon" icon={faUserAlt} style={{ marginRight: 8 }} />
                        <span>Edit User Profile</span>
                    </Menu.Item>
                }
            </Menu>
        );
    };

    const extra = (
        <Dropdown overlay={menu} trigger={['click']}>
            <Button onClick={(e) => { e.stopPropagation(); }}><ControlOutlined /> <DownOutlined /></Button>
        </Dropdown>
    );

    const breadcrumbs = [...previousBreadcrumbs,
    {
        location: previousLocation,
        path: previousPath,
        breadcrumbName: previousBreadcrumbName,
    },
    {
        path: `/users/${isStringNotEmpty(userProfileId) ? userProfileId : ''}`,
        breadcrumbName: `${isObjectNotEmpty(user) && isStringNotEmpty(user.firstName) && isStringNotEmpty(user.lastName) ? user.firstName + ' ' + user.lastName : ''}`,
    },
    ];

    if (isObjectNotEmpty(user) && user.id === userProfileId) {
        return (
            <Can entityAction="READ" entityModel="USER" entityObject={user}>
                <Header
                    title={isStringNotEmpty(user.firstName) && isStringNotEmpty(user.lastName) ? user.firstName + ' ' + user.lastName : 'Loading...'}
                    title2={isStringNotEmpty(user.user) && user.isDummyEmail === false ? user.user : ''}
                    title2Icon={<FontAwesomeIcon className="anticon" icon={faEnvelope} />}
                    title3={isStringNotEmpty(user.phone) ? stringFormatter.toFormattedPhoneNumber(user.phone, user.phoneExt) : ''}
                    title3Icon={<FontAwesomeIcon className="anticon" icon={faPhoneAlt} />}
                    icon={<FontAwesomeIcon className="anticon" icon={faUserAlt} />}
                    logo={imageUrl}
                    breadcrumbs={breadcrumbs}
                />
                <Tabs activeKey={tabKey} onChange={onTabChange} destroyInactiveTabPane={true} tabBarStyle={{ backgroundColor: '#ffffff', paddingRight: 24, paddingLeft: 24, margin: 0 }}>
                    <TabPane tab="Snapshot" key="snapshot">
                        <div style={{ padding: 24 }}>
                            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && !error}>
                                {(isLoading === false) ? (
                                    <Row gutter={[16, 16]}>
                                        <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                            <ComplexDataLinkCard
                                                title="User Details"
                                                style={{ height: 300 }}
                                                headStyle={{ fontSize: 14 }}
                                                data={{
                                                    "Name": { value: isStringNotEmpty(user.firstName) && isStringNotEmpty(user.lastName) ? user.firstName + ' ' + user.lastName : null, dataType: 'String' },
                                                    "UserName": { value: isStringNotEmpty(user.user) && user.isDummyEmail === false ? user.user : null, dataType: 'String' },
                                                    "Email": { value: isStringNotEmpty(user.user) && user.isDummyEmail === false ? user.user : null, dataType: 'String' },
                                                    "Phone": { value: isStringNotEmpty(user.phone) ? user.phone : null, units: user.phoneExt, dataType: 'PhoneNumber' },
                                                    "Date Joined": { value: isNotNullOrUndefined(user.createdAt) ? user.createdAt : null, dataType: 'MomentDate' },
                                                    "Is Active": { value: isBooleanTrue(user.isActive) ? user.isActive : false, dataType: 'Boolean' },
                                                }}
                                                extra={extra}
                                            >
                                                {isStringNotEmpty(user.streetAddress1) ? <DataAddressRow title="Address" streetAddress1={user.streetAddress1} streetAddress2={user.streetAddress2} city={user.city} state={user.state} postalCode={user.postalCode} country={user.country} /> : null}
                                                {isStringNotEmpty(user.bio) ? <DataRow title="Bio" value={user.bio} dataType="String" /> : null}
                                            </ComplexDataLinkCard>
                                        </Col>
                                        <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                            <ComplexDataLinkCard
                                                title="System Roles"
                                                style={{ height: 300 }}
                                                headStyle={{ fontSize: 14 }}
                                                data={{
                                                    "Is Admin": { value: isBooleanTrue(user.isAdmin) ? user.isAdmin : false, dataType: 'Boolean' },
                                                    "Is VP": { value: isBooleanTrue(user.isVP) ? user.isVP : false, dataType: 'Boolean' },
                                                    "Is Director": { value: isBooleanTrue(user.isDirector) ? user.isDirector : false, dataType: 'Boolean' },
                                                    "Is Manager": { value: isBooleanTrue(user.isManager) ? user.isManager : false, dataType: 'Boolean' },
                                                    "Is Staff": { value: isBooleanTrue(user.isStaff) ? user.isStaff : false, dataType: 'Boolean' },
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                ) : null}
                            </Spin>
                        </div>
                    </TabPane>
                    {(user.isStaff === true) ? (
                        <TabPane tab="Open Loads" key="openLoads">
                            <div style={{ padding: 24 }}>
                                <OpenLoads assignedAccountRepUserId={user.id} breadcrumbs={breadcrumbs} />
                            </div>
                        </TabPane>
                    ) : null}
                    {(user.isStaff === true) ? (
                        <TabPane tab="Load Requests" key="loadRequests">
                            <div style={{ padding: 24 }}>
                                <LoadRequests assignedAccountRepUserId={user.id} breadcrumbs={breadcrumbs} />
                            </div>
                        </TabPane>
                    ) : null}
                    {(user.isStaff === true) ? (
                        <TabPane tab="Booked Loads" key="bookedLoads">
                            <div style={{ padding: 24 }}>
                                <BookedLoads assignedAccountRepUserId={user.id} breadcrumbs={breadcrumbs} />
                            </div>
                        </TabPane>
                    ) : null}
                    {(user.isStaff === true) ? (
                        <TabPane tab="In Transit Loads" key="intransitLoads">
                            <div style={{ padding: 24 }}>
                                <InTransitLoads assignedAccountRepUserId={user.id} breadcrumbs={breadcrumbs} />
                            </div>
                        </TabPane>
                    ) : null}
                    {(user.isStaff === true) ? (
                        <TabPane tab="Completed Loads" key="completedLoads">
                            <div style={{ padding: 24 }}>
                                <QueueLoads
                                    title="Manage Completed Loads"
                                    filter={`COMPLETED_LOADS_ACCOUNT_REP_${user.id}`}
                                    filterSearchParams={{
                                        sort: 'loadEndDateTime',
                                        order: 'desc',
                                        isDeleted: false,
                                        eta: false
                                    }}
                                    defaultLoadStatusFilter={['COMPLETED', 'REOPENED', 'CLOSED', 'CANCELLED']}
                                    showTable={true}
                                    assignedAccountRepUserId={user.id}
                                    breadcrumbs={breadcrumbs}
                                    showCompletedLoadData={true}
                                    showAccountsPayable={CanDo({ staffOnly: true })}
                                    showAccountsReceivable={CanDo({ staffOnly: true })}
                                />
                            </div>
                        </TabPane>
                    ) : null}
                    {CanDo({staffOnly: true}) && (user.isStaff === true) ? (
                        <TabPane tab="AR/AP" key="arap">
                            <div style={{ padding: 24 }}>
                                <QueueCompletedLoads
                                    showAccountsReceivable={true}
                                    showAccountsPayable={true}
                                    showProofOfDelivery={true}
                                    showRequiredDocumentStatus={true}
                                    showClaimsStatus={true}
                                    title="AR/AP"
                                    filter={`COMPLETED_ALL_ACCOUNT_REP_${user.id}`}
                                    defaultLoadStatusFilter={['COMPLETED', 'REOPENED']}
                                    showTable={true}
                                    assignedAccountRepUserId={user.id}
                                />
                            </div>
                        </TabPane>
                    ) : null}
                </Tabs>
                {editEntityComponents}
            </Can>
        );
    } else {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={true} />
        );
    }
};

export default withRouter(User);