import axiosAuthenticated from "../../api/axiosAuthenticated";
import { isListNotEmpty, isNotNullOrUndefined, isStringNotEmpty } from "../../shared/objectUtils";
import * as DTO from "../dtos/index";
import * as Data from "./index";

const claimsPath = '/claims';

//#region Claims Methods

export const getClaimsByLoadId = async (loadId) => {
    if (isStringNotEmpty(loadId)) {
        const claimsRes = await axiosAuthenticated.get(claimsPath, { params: { page: 1, size: 1000000, loadId: loadId, isDeleted: false } });
        if (claimsRes && claimsRes.status === 200) {
            return claimsRes.data.data;
        }
    }

    return [];
};

export const getClaims = async (searchParams = {}, pagination = {}, accounts = []) => {
    const claimsRes = await axiosAuthenticated.get(claimsPath, { params: { ...searchParams } });
    if (claimsRes && claimsRes.status === 200) {
        const claims = claimsRes.data.data;
        const otherData = claimsRes.data;

        const transformedClaims = claims.map((claim) => { return DTO.getClaimDTO(claim, accounts); });

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;

        return { data: transformedClaims, searchParams: searchParams, pagination: pagination };
    }

    return { data: [], searchParams: searchParams, pagination: pagination };
};

export const getClaim = async (id, accounts = []) => {
    const claimsRes = await axiosAuthenticated.get(claimsPath + `/${id}`);
    if (claimsRes && claimsRes.status === 200) {
        let claim = claimsRes.data;

        return DTO.getClaimDTO(claim, accounts);
    }

    return null;
};

export const addClaim = async (payload, accounts = [], existingInvoiceLineItems = [], existingTransactions = [], existingClaims = [], loadId) => {
    let claimPayload = DTO.getAddClaimRequestDTO(payload);

    const claimsRes = await axiosAuthenticated.post(claimsPath, { ...claimPayload });
    if (claimsRes && claimsRes.status === 201) {
        let newClaim = claimsRes.data;

        await Data.addLoadEvent({ loadId: loadId, eventType: 'LOAD_UPDATED', changeType: 'LOAD_CLAIM_OPENED' });

        let updatedInvoiceLineItems = [];
        if (isListNotEmpty(existingInvoiceLineItems)) {
            updatedInvoiceLineItems = [...existingInvoiceLineItems];
        }
        let updatedTransactions = [];
        if (isListNotEmpty(existingTransactions)) {
            updatedTransactions = [...existingTransactions];
        }
        let updatedClaims = [];
        if (isListNotEmpty(existingClaims)) {
            updatedClaims = [...existingClaims];
        }

        updatedClaims.push(newClaim);

        if (isListNotEmpty(newClaim.newInvoiceLineItems)) {
            newClaim.newInvoiceLineItems.forEach((newInvoiceLineItem) => {
                updatedInvoiceLineItems.push(newInvoiceLineItem);
            });
        }

        // recalculate load pricing due to adjustment
        await Data.updateLoadPricing(loadId, updatedInvoiceLineItems, updatedTransactions, updatedClaims);

        if (isListNotEmpty(newClaim.newInvoiceLineItems)) {
            await Data.addLoadEvent({ loadId: loadId, eventType: 'LOAD_UPDATED', changeType: 'LOAD_CLAIM_ADJUSTMENT' });
        }

        return DTO.getClaimDTO(newClaim, accounts);
    }

    return null;
};

export const updateClaim = async (id, payload, changeType = null, accounts = [], existingInvoiceLineItems = [], existingTransactions = [], oldClaims = [], loadId) => {
    let claimPayload = DTO.getUpdateClaimRequestDTO(payload);

    const claimsRes = await axiosAuthenticated.put(claimsPath + `/${id}`, { ...claimPayload });
    if (claimsRes && claimsRes.status === 200) {
        let updatedClaim = claimsRes.data;

        if (isStringNotEmpty(changeType)) {
            await Data.addLoadEvent({ loadId: loadId, eventType: 'LOAD_UPDATED', changeType: changeType });

            if (changeType === 'LOAD_CLAIM_REOPENDED') {
                // if the claim is re-opened, re-open the load as well
                await Data.addLoadEvent({ loadId: loadId, eventType: 'LOAD_REOPENED' });
            }
        }

        let updatedInvoiceLineItems = [];
        if (isListNotEmpty(existingInvoiceLineItems)) {
            updatedInvoiceLineItems = [...existingInvoiceLineItems];
        }
        let updatedTransactions = [];
        if (isListNotEmpty(existingTransactions)) {
            updatedTransactions = [...existingTransactions];
        }
        let existingClaims = [];
        if (isListNotEmpty(oldClaims)) {
            existingClaims = [...oldClaims];
        }

        let updatedClaims = [...existingClaims];
        const index = existingClaims.findIndex(obj => obj.id === updatedClaim.id);
        if (index !== -1) {
            updatedClaims = [
                ...existingClaims.slice(0, index), // everything before current obj
                updatedClaim,
                ...existingClaims.slice(index + 1), // everything after current obj
            ];
        }

        if (isListNotEmpty(updatedClaim.newInvoiceLineItems) || isListNotEmpty(updatedClaim.oldInvoiceLineItems)) {
            if (isListNotEmpty(updatedClaim.newInvoiceLineItems)) {
                updatedClaim.newInvoiceLineItems.forEach((newInvoiceLineItem) => {
                    updatedInvoiceLineItems.push(newInvoiceLineItem);
                });
            }

            if (isListNotEmpty(updatedClaim.oldInvoiceLineItems)) {
                updatedClaim.oldInvoiceLineItems.forEach((oldInvoiceLineItem) => {
                    const index = updatedInvoiceLineItems.findIndex(obj => obj.id === oldInvoiceLineItem.id);
                    if (index !== -1) {
                        // returns the deleted items
                        updatedInvoiceLineItems.splice(index, 1);
                    }
                });
            }
        }

        // recalculate load pricing due to adjustment
        await Data.updateLoadPricing(loadId, updatedInvoiceLineItems, updatedTransactions, updatedClaims);

        if (isListNotEmpty(updatedClaim.newInvoiceLineItems) || isListNotEmpty(updatedClaim.oldInvoiceLineItems)) {
            await Data.addLoadEvent({ loadId: loadId, eventType: 'LOAD_UPDATED', changeType: 'LOAD_CLAIM_ADJUSTMENT' });
        }


        return DTO.getClaimDTO(updatedClaim, accounts);
    }

    return null;
};

export const removeClaim = async (id, existingInvoiceLineItems = [], existingTransactions = [], existingClaims = [], loadId) => {
    const claimsRes = await axiosAuthenticated.put(claimsPath + `/${id}`, { isDeleted: true });
    if (claimsRes && claimsRes.status === 200) {
        let updatedClaim = claimsRes.data;

        let updatedInvoiceLineItems = [];
        if (isListNotEmpty(existingInvoiceLineItems)) {
            updatedInvoiceLineItems = [...existingInvoiceLineItems];
        }
        let updatedTransactions = [];
        if (isListNotEmpty(existingTransactions)) {
            updatedTransactions = [...existingTransactions];
        }
        let updatedClaims = [];
        if (isListNotEmpty(existingClaims)) {
            updatedClaims = [...existingClaims];
        }

        const index = updatedClaims.findIndex(obj => obj.id === updatedClaim.id);
        if (index !== -1) {
            // returns the deleted items
            updatedClaims.splice(index, 1);
        }

        if (isListNotEmpty(updatedClaim.newInvoiceLineItems) || isListNotEmpty(updatedClaim.oldInvoiceLineItems)) {
            if (isListNotEmpty(updatedClaim.newInvoiceLineItems)) {
                updatedClaim.newInvoiceLineItems.forEach((newInvoiceLineItem) => {
                    updatedInvoiceLineItems.push(newInvoiceLineItem);
                });
            }

            if (isListNotEmpty(updatedClaim.oldInvoiceLineItems)) {
                updatedClaim.oldInvoiceLineItems.forEach((oldInvoiceLineItem) => {
                    const index = updatedInvoiceLineItems.findIndex(obj => obj.id === oldInvoiceLineItem.id);
                    if (index !== -1) {
                        // returns the deleted items
                        updatedInvoiceLineItems.splice(index, 1);
                    }
                });
            }
        }

        // recalculate load pricing due to adjustment
        await Data.updateLoadPricing(loadId, updatedInvoiceLineItems, updatedTransactions, updatedClaims);

        if (isListNotEmpty(updatedClaim.newInvoiceLineItems) || isListNotEmpty(updatedClaim.oldInvoiceLineItems)) {
            await Data.addLoadEvent({ loadId: loadId, eventType: 'LOAD_UPDATED', changeType: 'LOAD_CLAIM_ADJUSTMENT' });
        }

        return updatedClaim;
    }

    return null;
};

//#endregion