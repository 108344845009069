import React, { useEffect, useMemo, useState } from 'react';
import { Button, Row, Col, Radio, InputNumber, Select, Typography, Empty } from "antd";
import { orderBy } from "lodash";
import { useForm, FormProvider } from 'react-hook-form';
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import StringFormatter from '../../shared/stringFormatter';
import DataRow from '../DataRow/DataRow';
import DataListRow from '../DataListRow/DataListRow';
import Pricing from '../../shared/pricing';
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import Header from '../Header/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Enums from '../../shared/enums';
import { isListNotEmpty, isNumberGreaterThanZero, isNumberNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { withRouter } from 'react-router';
import { CanDo } from '../../shared/entitlements/entitlements';
import FormItemDisplay from '../FormItemDisplay/FormItemDisplay';
import { selectListRecords } from '../../store/utility';

const { Option } = Select;
const stringFormatter = new StringFormatter();

const AssignLoadCarrier = ({ loadId, load, stops, invoices, invoiceLineItems, cancel, ...props }) => {
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const lineItemTypes = useSelector(state => state.orchestrator.lineItemTypes);
    const accounts = useSelector(state => state.orchestrator.accounts);
    const services = useSelector(state => state.orchestrator.services);
    const isLoading = useSelector(state => state.loads.isRecordUpdateLoading);
    const error = useSelector(state => state.loads.updateRecordError);
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);
    const linkedAccounts = useSelector(state => selectListRecords(state.orchestrator.linkedAccounts, entityType !== 'STAFF' ? entityId : null));

    //#endregion
    //#region useStates

    const [showCarrierOptions, setShowCarrierOptions] = useState(false);
    const [tenderOption, setTenderOption] = useState(null);
    const [showPricing, setShowPricing] = useState(false);
    const [carriers, setCarriers] = useState([]);
    const [isCarriersLoading, setIsCarriersLoading] = useState(false);
    const [showPerMile, setShowPerMile] = useState(false);
    const [showContractRate, setShowContractRate] = useState(false);
    const [totalFeePerMile, setTotalFeePerMile] = useState(0);
    const [shipperRatePerMile, setShipperRatePerMile] = useState(0);
    const [carrierRatePerMile, setCarrierRatePerMile] = useState(0);
    const [commissionPercentage, setCommissionPercentage] = useState(0);
    const [totalAddOnCharge, setTotalAddOnCharge] = useState(0);
    const [totalAddOnCost, setTotalAddOnCost] = useState(0);
    const [shipperAmount, setShipperAmount] = useState(0);
    const [carrierAmount, setCarrierAmount] = useState(0);
    const [IRISProfit, setIRISProfit] = useState(0);

    const [baseRatePerMileAmount, setBaseRatePerMileAmount] = useState(0);
    const [fuelSurchargePerMileAmount, setFuelSurchargePerMileAmount] = useState(0);
    const [contractRateAmount, setContractRateAmount] = useState(0);
    const [contractRateCarrierAmount, setContractRateCarrierAmount] = useState(0);
    const [fuelSurchargeAmount, setFuelSurchargeAmount] = useState(0);

    //#endregion
    //#region onSubmit and cancel

    const generateTMSInvoiceLineItem = (accountEntityId, accountEntityType) => {
        let newInvoiceLineItem = null;
        // if the serviceType is TMS then add the service fees
        if (isObjectNotEmpty(load) && isListNotEmpty(lineItemTypes) && isListNotEmpty(services) && isListNotEmpty(accounts) && isNumberNotEmpty(load.practicalDistance)) {
            let lineItemType = lineItemTypes.find(j => j.name === 'TMS_SERVICE_FEE');
            let tmsService = services.find(j => j.serviceType === 'LOAD' && j.loadServiceType === 'TMS'); // get the default tms service
            let account = accounts.find(j => j.id === accountEntityId);
            if (isObjectNotEmpty(lineItemType) && isObjectNotEmpty(tmsService) && isObjectNotEmpty(account)) {
                newInvoiceLineItem = {
                    description: lineItemType.description,
                    itemType: 'TMS_SERVICE_FEE',
                    isIrisFee: true,
                    fromEntityType: accountEntityType,
                    fromEntityId: accountEntityId,
                    toEntityType: "STAFF",
                    status: "PENDING",
                    approvalStatus: "APPROVED",
                    isOpen: true,
                    isDeleted: false,
                    loadId: load.id
                };

                if (tmsService.billingFrequency === 'PER_LOAD') {
                    newInvoiceLineItem.quantity = Number(1);
                    newInvoiceLineItem.quantityUnit = 'PER_LOAD';
                    newInvoiceLineItem.baseAmount = Number(tmsService.priceAmount);
                    newInvoiceLineItem.baseAmountUnit = tmsService.priceAmountUnit;
                    newInvoiceLineItem.totalAmount = Number(tmsService.priceAmount);
                    newInvoiceLineItem.totalAmountUnit = tmsService.priceAmountUnit;
                } else if (tmsService.billingFrequency === 'PER_MILE') {
                    let baseAmount = Number(tmsService.priceAmount);
                    let quantity = Number(load.practicalDistance);
                    let totalAmount = baseAmount * quantity;

                    newInvoiceLineItem.baseAmount = Number(baseAmount);
                    newInvoiceLineItem.baseAmountUnit = tmsService.priceAmountUnit;
                    newInvoiceLineItem.quantity = quantity;
                    newInvoiceLineItem.quantityUnit = 'PER_MILE';
                    newInvoiceLineItem.totalAmount = totalAmount;
                    newInvoiceLineItem.totalAmountUnit = tmsService.priceAmountUnit;
                } else {
                    newInvoiceLineItem.baseAmount = Number(0);
                    newInvoiceLineItem.baseAmountUnit = tmsService.priceAmountUnit;
                    newInvoiceLineItem.quantity = Number(1);
                    newInvoiceLineItem.quantityUnit = 'PER_LOAD';
                    newInvoiceLineItem.totalAmount = Number(0);
                    newInvoiceLineItem.totalAmountUnit = tmsService.priceAmountUnit;
                }

                // check to see if pricing for service should be over-riden
                let accountTMSService = null;
                if (isListNotEmpty(account.accountServices)) {
                    accountTMSService = account.accountServices.find(j => j.serviceName === tmsService.name);
                    if (isObjectNotEmpty(accountTMSService)) {
                        if (accountTMSService.isPriceOverride === true) {
                            if (accountTMSService.billingFrequency === 'PER_LOAD') {
                                newInvoiceLineItem.quantity = Number(1);
                                newInvoiceLineItem.quantityUnit = 'PER_LOAD';
                                newInvoiceLineItem.baseAmount = Number(accountTMSService.priceAmount);
                                newInvoiceLineItem.baseAmountUnit = accountTMSService.priceAmountUnit;
                                newInvoiceLineItem.totalAmount = Number(accountTMSService.priceAmount);
                                newInvoiceLineItem.totalAmountUnit = accountTMSService.priceAmountUnit;
                            } else if (accountTMSService.billingFrequency === 'PER_MILE') {
                                let baseAmount = Number(accountTMSService.priceAmount);
                                let quantity = Number(load.practicalDistance);
                                let totalAmount = baseAmount * quantity;

                                newInvoiceLineItem.baseAmount = Number(baseAmount);
                                newInvoiceLineItem.baseAmountUnit = accountTMSService.priceAmountUnit;
                                newInvoiceLineItem.quantity = quantity;
                                newInvoiceLineItem.quantityUnit = 'PER_MILE';
                                newInvoiceLineItem.totalAmount = totalAmount;
                                newInvoiceLineItem.totalAmountUnit = accountTMSService.priceAmountUnit;
                            } else {
                                newInvoiceLineItem.baseAmount = Number(0);
                                newInvoiceLineItem.baseAmountUnit = accountTMSService.priceAmountUnit;
                                newInvoiceLineItem.quantity = Number(1);
                                newInvoiceLineItem.quantityUnit = 'PER_LOAD';
                                newInvoiceLineItem.totalAmount = Number(0);
                                newInvoiceLineItem.totalAmountUnit = accountTMSService.priceAmountUnit;
                            }
                        }
                    }
                }
            }
        }

        return newInvoiceLineItem;
    };

    const onSubmit = (data) => {
        let loadPayload = {};
        if (CanDo({ entityTypes: ['STAFF'] })) {
            loadPayload.serviceType = 'BROKERAGE';
        } else if (CanDo({ entityTypes: ['SHIPPER'] }) && tenderOption === 'ASSIGN_CARRIER') {
            loadPayload.serviceType = 'TMS';
        } else if (CanDo({ entityTypes: ['SHIPPER'] }) && tenderOption === 'IRIS') {
            loadPayload.serviceType = 'BROKERAGE';
        }

        let newInvoiceLineItems = [];
        let removeInvoiceLineItems = [];

        // find old iris fees if they exist and set them to be removed
        if (isListNotEmpty(invoiceLineItems)) {
            invoiceLineItems.forEach((existingInvoiceLineItem) => {
                if (Enums.IRISFees.doesNameExist(existingInvoiceLineItem.itemType)) {
                    if (removeInvoiceLineItems.find(i => i.id === existingInvoiceLineItem.id) === undefined) {
                        removeInvoiceLineItems.push(existingInvoiceLineItem);
                    }
                }
            });
        }

        // add TMS service fees if service type is TMS which means this is being done by a shipper or carrier
        if (isStringNotEmpty(loadPayload.serviceType) && loadPayload.serviceType === 'TMS') {
            let tmsInvoiceLineItem = generateTMSInvoiceLineItem(entityId, entityType);
            if (isObjectNotEmpty(tmsInvoiceLineItem)) {
                newInvoiceLineItems.push(tmsInvoiceLineItem);
            }
        }

        // Base Fee Per Mile
        if (isNumberGreaterThanZero(data.baseRatePerMileAmount)) {
            let lineItemType = lineItemTypes.find(j => j.name === 'BASE_FEE_PER_MILE');
            if (isObjectNotEmpty(lineItemType)) {
                let baseAmount = Number(data.baseRatePerMileAmount);
                let quantity = Number(load.practicalDistance);
                let totalAmount = baseAmount * quantity;
                let newInvoiceLineItem = {
                    description: lineItemType.description,
                    itemType: 'BASE_FEE_PER_MILE',
                    isIrisFee: false,
                    baseAmount: baseAmount.toFixed(2),
                    baseAmountUnit: 'USD',
                    quantity: quantity,
                    quantityUnit: 'PER_MILE',
                    totalAmount: totalAmount.toFixed(2),
                    totalAmountUnit: 'USD',
                    status: 'PENDING',
                    approvalStatus: 'PENDING',
                    isOpen: true,
                    isDeleted: false,
                    loadId: load.id
                };

                if (CanDo({ entityTypes: ['STAFF'] })) {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', fromEntityId: load.shipperId, toEntityType: 'STAFF' });
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER', toEntityId: data.assignedCarrierId });

                    // find old fees if they exist and set them to be removed
                    invoiceLineItems.filter(i => i.itemType === 'BASE_FEE_PER_MILE' && (i.fromEntityType === 'SHIPPER' || i.toEntityType === 'CARRIER') && i.isDeleted === false).forEach((existingInvoiceLineItem) => {
                        if (removeInvoiceLineItems.find(i => i.id === existingInvoiceLineItem.id) === undefined) {
                            removeInvoiceLineItems.push(existingInvoiceLineItem);
                        }
                    });
                } else if (CanDo({ entityTypes: ['SHIPPER'] }) && tenderOption === 'ASSIGN_CARRIER') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', fromEntityId: load.shipperId, toEntityType: 'CARRIER', toEntityId: data.assignedCarrierId });

                    // find old fees if they exist and set them to be removed
                    invoiceLineItems.filter(i => i.itemType === 'BASE_FEE_PER_MILE' && (i.fromEntityType === 'SHIPPER' || i.toEntityType === 'CARRIER') && i.isDeleted === false).forEach((existingInvoiceLineItem) => {
                        if (removeInvoiceLineItems.find(i => i.id === existingInvoiceLineItem.id) === undefined) {
                            removeInvoiceLineItems.push(existingInvoiceLineItem);
                        }
                    });
                } else if (CanDo({ entityTypes: ['SHIPPER'] }) && tenderOption === 'IRIS') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', fromEntityId: load.shipperId, toEntityType: 'STAFF' });
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER', toEntityId: data.assignedCarrierId });

                    // find old fees if they exist and set them to be removed
                    invoiceLineItems.filter(i => i.itemType === 'BASE_FEE_PER_MILE' && (i.fromEntityType === 'SHIPPER' || i.toEntityType === 'CARRIER') && i.isDeleted === false).forEach((existingInvoiceLineItem) => {
                        if (removeInvoiceLineItems.find(i => i.id === existingInvoiceLineItem.id) === undefined) {
                            removeInvoiceLineItems.push(existingInvoiceLineItem);
                        }
                    });
                }
            }
        }

        // Fuel Surcharge Per Mile
        if (isNumberGreaterThanZero(data.fuelSurchargePerMileAmount)) {
            let lineItemType = lineItemTypes.find(j => j.name === 'FUEL_SURCHARGE_PER_MILE');
            if (isObjectNotEmpty(lineItemType)) {
                let baseAmount = Number(data.fuelSurchargePerMileAmount);
                let quantity = Number(load.practicalDistance);
                let totalAmount = baseAmount * quantity;
                let newInvoiceLineItem = {
                    description: lineItemType.description,
                    itemType: 'FUEL_SURCHARGE_PER_MILE',
                    isIrisFee: false,
                    baseAmount: baseAmount.toFixed(2),
                    baseAmountUnit: 'USD',
                    quantity: quantity,
                    quantityUnit: 'PER_MILE',
                    totalAmount: totalAmount.toFixed(2),
                    totalAmountUnit: 'USD',
                    status: 'PENDING',
                    approvalStatus: 'PENDING',
                    isOpen: true,
                    isDeleted: false,
                    loadId: load.id
                };

                if (CanDo({ entityTypes: ['STAFF'] })) {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', fromEntityId: load.shipperId, toEntityType: 'STAFF' });
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER', toEntityId: data.assignedCarrierId });

                    // find old fees if they exist and set them to be removed
                    invoiceLineItems.filter(i => i.itemType === 'FUEL_SURCHARGE_PER_MILE' && (i.fromEntityType === 'SHIPPER' || i.toEntityType === 'CARRIER') && i.isDeleted === false).forEach((existingInvoiceLineItem) => {
                        if (removeInvoiceLineItems.find(i => i.id === existingInvoiceLineItem.id) === undefined) {
                            removeInvoiceLineItems.push(existingInvoiceLineItem);
                        }
                    });
                } else if (CanDo({ entityTypes: ['SHIPPER'] }) && tenderOption === 'ASSIGN_CARRIER') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', fromEntityId: load.shipperId, toEntityType: 'CARRIER', toEntityId: data.assignedCarrierId });

                    // find old fees if they exist and set them to be removed
                    invoiceLineItems.filter(i => i.itemType === 'FUEL_SURCHARGE_PER_MILE' && (i.fromEntityType === 'SHIPPER' || i.toEntityType === 'CARRIER') && i.isDeleted === false).forEach((existingInvoiceLineItem) => {
                        if (removeInvoiceLineItems.find(i => i.id === existingInvoiceLineItem.id) === undefined) {
                            removeInvoiceLineItems.push(existingInvoiceLineItem);
                        }
                    });
                } else if (CanDo({ entityTypes: ['SHIPPER'] }) && tenderOption === 'IRIS') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', fromEntityId: load.shipperId, toEntityType: 'STAFF' });
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER', toEntityId: data.assignedCarrierId });

                    // find old fees if they exist and set them to be removed
                    invoiceLineItems.filter(i => i.itemType === 'FUEL_SURCHARGE_PER_MILE' && (i.fromEntityType === 'SHIPPER' || i.toEntityType === 'CARRIER') && i.isDeleted === false).forEach((existingInvoiceLineItem) => {
                        if (removeInvoiceLineItems.find(i => i.id === existingInvoiceLineItem.id) === undefined) {
                            removeInvoiceLineItems.push(existingInvoiceLineItem);
                        }
                    });
                }
            }
        }

        // Fuel Surcharge Per Load
        if (isNumberGreaterThanZero(data.fuelSurchargeAmount)) {
            let lineItemType = lineItemTypes.find(j => j.name === 'FUEL_SURCHARGE');
            if (isObjectNotEmpty(lineItemType)) {
                let baseAmount = Number(data.fuelSurchargeAmount);
                let quantity = Number(load.practicalDistance);
                let totalAmount = baseAmount * quantity;
                let newInvoiceLineItem = {
                    description: lineItemType.description,
                    itemType: 'FUEL_SURCHARGE',
                    isIrisFee: false,
                    baseAmount: baseAmount.toFixed(2),
                    baseAmountUnit: 'USD',
                    quantity: quantity,
                    quantityUnit: 'PER_LOAD',
                    totalAmount: totalAmount.toFixed(2),
                    totalAmountUnit: 'USD',
                    status: 'PENDING',
                    approvalStatus: 'PENDING',
                    isOpen: true,
                    isDeleted: false,
                    loadId: load.id
                };

                if (CanDo({ entityTypes: ['STAFF'] })) {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', fromEntityId: load.shipperId, toEntityType: 'STAFF' });
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER', toEntityId: data.assignedCarrierId });

                    // find old fees if they exist and set them to be removed
                    invoiceLineItems.filter(i => i.itemType === 'FUEL_SURCHARGE' && (i.fromEntityType === 'SHIPPER' || i.toEntityType === 'CARRIER') && i.isDeleted === false).forEach((existingInvoiceLineItem) => {
                        if (removeInvoiceLineItems.find(i => i.id === existingInvoiceLineItem.id) === undefined) {
                            removeInvoiceLineItems.push(existingInvoiceLineItem);
                        }
                    });
                } else if (CanDo({ entityTypes: ['SHIPPER'] }) && tenderOption === 'ASSIGN_CARRIER') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', fromEntityId: load.shipperId, toEntityType: 'CARRIER', toEntityId: data.assignedCarrierId });

                    // find old fees if they exist and set them to be removed
                    invoiceLineItems.filter(i => i.itemType === 'FUEL_SURCHARGE' && (i.fromEntityType === 'SHIPPER' || i.toEntityType === 'CARRIER') && i.isDeleted === false).forEach((existingInvoiceLineItem) => {
                        if (removeInvoiceLineItems.find(i => i.id === existingInvoiceLineItem.id) === undefined) {
                            removeInvoiceLineItems.push(existingInvoiceLineItem);
                        }
                    });
                } else if (CanDo({ entityTypes: ['SHIPPER'] }) && tenderOption === 'IRIS') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', fromEntityId: load.shipperId, toEntityType: 'STAFF' });
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER', toEntityId: data.assignedCarrierId });

                    // find old fees if they exist and set them to be removed
                    invoiceLineItems.filter(i => i.itemType === 'FUEL_SURCHARGE' && (i.fromEntityType === 'SHIPPER' || i.toEntityType === 'CARRIER') && i.isDeleted === false).forEach((existingInvoiceLineItem) => {
                        if (removeInvoiceLineItems.find(i => i.id === existingInvoiceLineItem.id) === undefined) {
                            removeInvoiceLineItems.push(existingInvoiceLineItem);
                        }
                    });
                }
            }
        }

        // Contract Rate
        if (isNumberGreaterThanZero(data.contractRateAmount)) {
            let lineItemType = lineItemTypes.find(j => j.name === 'CONTRACT_RATE');
            if (isObjectNotEmpty(lineItemType)) {
                let baseAmount = Number(data.contractRateAmount);
                let quantity = Number(1);
                let totalAmount = baseAmount * quantity;
                let newInvoiceLineItem = {
                    description: lineItemType.description,
                    itemType: 'CONTRACT_RATE',
                    isIrisFee: false,
                    baseAmount: baseAmount.toFixed(2),
                    baseAmountUnit: 'USD',
                    quantity: quantity,
                    quantityUnit: 'PER_LOAD',
                    totalAmount: totalAmount.toFixed(2),
                    totalAmountUnit: 'USD',
                    status: 'PENDING',
                    approvalStatus: 'PENDING',
                    isOpen: true,
                    isDeleted: false,
                    loadId: load.id
                };

                if (CanDo({ entityTypes: ['STAFF'] })) {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', fromEntityId: load.shipperId, toEntityType: 'STAFF' });

                    // find old fees if they exist and set them to be removed
                    invoiceLineItems.filter(i => i.itemType === 'CONTRACT_RATE' && i.fromEntityType === 'SHIPPER' && i.isDeleted === false).forEach((existingInvoiceLineItem) => {
                        if (removeInvoiceLineItems.find(i => i.id === existingInvoiceLineItem.id) === undefined) {
                            removeInvoiceLineItems.push(existingInvoiceLineItem);
                        }
                    });
                } else if (CanDo({ entityTypes: ['SHIPPER'] }) && tenderOption === 'ASSIGN_CARRIER') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', fromEntityId: load.shipperId, toEntityType: 'CARRIER', toEntityId: data.assignedCarrierId });

                    // find old fees if they exist and set them to be removed
                    invoiceLineItems.filter(i => i.itemType === 'CONTRACT_RATE' && i.fromEntityType === 'SHIPPER' && i.isDeleted === false).forEach((existingInvoiceLineItem) => {
                        if (removeInvoiceLineItems.find(i => i.id === existingInvoiceLineItem.id) === undefined) {
                            removeInvoiceLineItems.push(existingInvoiceLineItem);
                        }
                    });
                } else if (CanDo({ entityTypes: ['SHIPPER'] }) && tenderOption === 'IRIS') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', fromEntityId: load.shipperId, toEntityType: 'STAFF' });

                    // find old fees if they exist and set them to be removed
                    invoiceLineItems.filter(i => i.itemType === 'CONTRACT_RATE' && i.fromEntityType === 'SHIPPER' && i.isDeleted === false).forEach((existingInvoiceLineItem) => {
                        if (removeInvoiceLineItems.find(i => i.id === existingInvoiceLineItem.id) === undefined) {
                            removeInvoiceLineItems.push(existingInvoiceLineItem);
                        }
                    });
                }
            }
        }

        // Contract Carrier Rate
        if (isNumberGreaterThanZero(data.contractRateCarrierAmount)) {
            let lineItemType = lineItemTypes.find(j => j.name === 'CONTRACT_RATE');
            if (isObjectNotEmpty(lineItemType)) {
                let baseAmount = Number(data.contractRateCarrierAmount);
                let quantity = Number(1);
                let totalAmount = baseAmount * quantity;
                let newInvoiceLineItem = {
                    description: lineItemType.description,
                    itemType: 'CONTRACT_RATE',
                    isIrisFee: false,
                    baseAmount: baseAmount.toFixed(2),
                    baseAmountUnit: 'USD',
                    quantity: quantity,
                    quantityUnit: 'PER_LOAD',
                    totalAmount: totalAmount.toFixed(2),
                    totalAmountUnit: 'USD',
                    status: 'PENDING',
                    approvalStatus: 'PENDING',
                    isOpen: true,
                    isDeleted: false,
                    loadId: load.id
                };

                if (CanDo({ entityTypes: ['STAFF'] })) {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER', toEntityId: data.assignedCarrierId });

                    // find old fees if they exist and set them to be removed
                    invoiceLineItems.filter(i => i.itemType === 'CONTRACT_RATE' && i.toEntityType === 'CARRIER' && i.isDeleted === false).forEach((existingInvoiceLineItem) => {
                        if (removeInvoiceLineItems.find(i => i.id === existingInvoiceLineItem.id) === undefined) {
                            removeInvoiceLineItems.push(existingInvoiceLineItem);
                        }
                    });
                } else if (CanDo({ entityTypes: ['SHIPPER'] }) && tenderOption === 'IRIS') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER', toEntityId: data.assignedCarrierId });

                    // find old fees if they exist and set them to be removed
                    invoiceLineItems.filter(i => i.itemType === 'CONTRACT_RATE' && i.toEntityType === 'CARRIER' && i.isDeleted === false).forEach((existingInvoiceLineItem) => {
                        if (removeInvoiceLineItems.find(i => i.id === existingInvoiceLineItem.id) === undefined) {
                            removeInvoiceLineItems.push(existingInvoiceLineItem);
                        }
                    });
                }
            }
        }

        if (isNumberNotEmpty(shipperRatePerMile)) {
            loadPayload.shipperRatePerMile = shipperRatePerMile;
            loadPayload.shipperRatePerMileUnit = 'USD';
        }
        if (isNumberNotEmpty(shipperAmount)) {
            loadPayload.shipperAmount = shipperAmount;
            loadPayload.shipperAmountUnit = 'USD';
            loadPayload.shipperBalance = shipperAmount;
            loadPayload.shipperBalanceUnit = 'USD';
        }
        if (isNumberNotEmpty(carrierRatePerMile)) {
            loadPayload.carrierRatePerMile = carrierRatePerMile;
            loadPayload.carrierRatePerMileUnit = 'USD';
        }
        if (isNumberNotEmpty(carrierAmount)) {
            loadPayload.carrierAmount = carrierAmount;
            loadPayload.carrierAmountUnit = 'USD';
            loadPayload.carrierBalance = carrierAmount;
            loadPayload.carrierBalanceUnit = 'USD';
        }

        if (isStringNotEmpty(data.assignedCarrierId)) {
            loadPayload.assignedCarrierId = data.assignedCarrierId;
        } else {
            loadPayload.assignedCarrierId = null;
        }

        loadPayload.isRateConfirmed = null;
        loadPayload.rateConfirmedBy = null;
        loadPayload.rateConfirmedAt = null;

        if (isObjectNotEmpty(loadPayload)) {
            // console.log(loadPayload);
            // console.log(newInvoiceLineItems);
            // console.log(removeInvoiceLineItems);
            // delete old iris fees and load fees if they exist
            if (isListNotEmpty(removeInvoiceLineItems)) {
                dispatch(actionCreators.removeInvoiceLineItems(removeInvoiceLineItems.map(i => i.id), load.id));
            }
            if (isListNotEmpty(newInvoiceLineItems)) {
                dispatch(actionCreators.addInvoiceLineItems(newInvoiceLineItems, load.id));
            }

            if (isListNotEmpty(invoices)) {
                invoices.forEach((invoice) => {
                    if (invoice.invoiceType === 'RATE_CONFIRMATION') {
                        dispatch(actionCreators.updateInvoice(invoice.id, { isDeleted: true, status: 'CANCELLED' }, invoice));
                    }
                });
            }

            dispatch(actionCreators.updateLoad(load.id, loadPayload));
        } else {
            onCancel();
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.updateLoadCancel());
        cancel();
    };

    //#endregion
    //#region onChanges

    const onChangeRateOption = (value) => {
        // console.log(value);
        if (isStringNotEmpty(value)) {
            if (value === 'PER_MILE') {
                setShowPerMile(true);
                setShowContractRate(false);
                if (CanDo({ entityTypes: ['STAFF'] })) {
                    methods.setValue('contractRateCarrierAmount', null);
                    setContractRateCarrierAmount(0);
                } else if (CanDo({ entityTypes: ['SHIPPER'] })) {
                    methods.setValue('contractRateAmount', null);
                    methods.setValue('fuelSurchargeAmount', null);
                    setContractRateAmount(0);
                    setFuelSurchargeAmount(0);
                }
            } else if (value === 'CONTRACT_RATE') {
                setShowPerMile(false);
                setShowContractRate(true);
                if (CanDo({ entityTypes: ['STAFF'] })) {
                    methods.setValue('baseRatePerMileAmount', null);
                    methods.setValue('fuelSurchargePerMileAmount', null);
                    setBaseRatePerMileAmount(0);
                    setFuelSurchargePerMileAmount(0);
                } else if (CanDo({ entityTypes: ['SHIPPER'] })) {
                    methods.setValue('baseRatePerMileAmount', null);
                    methods.setValue('fuelSurchargePerMileAmount', null);
                    setBaseRatePerMileAmount(0);
                    setFuelSurchargePerMileAmount(0);
                }
            } else {
                setShowPerMile(false);
                setShowContractRate(false);
            }
        } else {
            setShowPerMile(false);
            setShowContractRate(false);
        }
    };

    const onChangeTenderOption = (value) => {
        // console.log(value);
        if (isStringNotEmpty(value)) {
            setTenderOption(value);
            if (value === 'ASSIGN_CARRIER') {
                setShowCarrierOptions(true);
                setShowPricing(true);
            } else if (value === 'IRIS') {
                setShowCarrierOptions(false);
                setShowPricing(false);
            } else {
                setShowCarrierOptions(false);
                setShowPricing(false);
            }
        } else {
            setTenderOption(null);
            setShowCarrierOptions(false);
            setShowPricing(false);
        }
    };

    //#endregion
    //#region useMemos and useEffects

    useMemo(() => {
        dispatch(actionCreators.getServices());
        dispatch(actionCreators.getStates());
    }, []);

    useMemo(() => {
        if (isStringNotEmpty(entityType) && isStringNotEmpty(entityId) && entityType !== 'STAFF') {
            dispatch(actionCreators.getLinkedAccounts(entityId));
        } else {
            dispatch(actionCreators.getAccounts());
        }
    }, [entityType, entityId]);

    useMemo(() => {
        if (CanDo({ entityTypes: ['STAFF'] }) && isListNotEmpty(invoiceLineItems)) {
            let shipperInvoiceLineItem = invoiceLineItems.find(i => i.itemType === 'CONTRACT_RATE' && i.fromEntityType === 'SHIPPER' && i.isDeleted === false);
            if (isObjectNotEmpty(shipperInvoiceLineItem)) {
                setContractRateAmount(shipperInvoiceLineItem.totalAmount);
            }
        }
    }, [invoiceLineItems]);

    useEffect(() => {
        // console.log(invoiceLineItems);
        let shipperContractAmount = 0.0;
        let carrierContractAmount = 0.0;
        let practicalDistance = 0.0;
        if (isObjectNotEmpty(load)) {
            practicalDistance = load.practicalDistance;

            if (CanDo({ entityTypes: ['STAFF'] })) {
                if (isNumberGreaterThanZero(baseRatePerMileAmount) && isNumberGreaterThanZero(load.practicalDistance)) {
                    carrierContractAmount = (Number(baseRatePerMileAmount) * Number(load.practicalDistance));
                    if (isNumberGreaterThanZero(fuelSurchargePerMileAmount)) {
                        carrierContractAmount += (Number(fuelSurchargePerMileAmount) * Number(load.practicalDistance));
                    }
                } else if (isNumberGreaterThanZero(contractRateCarrierAmount)) {
                    carrierContractAmount = contractRateCarrierAmount;
                }

                shipperContractAmount = isNumberGreaterThanZero(contractRateAmount) ? contractRateAmount : 0.0;
            } else if (CanDo({ entityTypes: ['SHIPPER'] })) {
                if (isNumberGreaterThanZero(baseRatePerMileAmount) && isNumberGreaterThanZero(load.practicalDistance)) {
                    shipperContractAmount = (Number(baseRatePerMileAmount) * Number(load.practicalDistance));
                    if (isNumberGreaterThanZero(fuelSurchargePerMileAmount)) {
                        shipperContractAmount += (Number(fuelSurchargePerMileAmount) * Number(load.practicalDistance));
                    }
                } else if (isNumberGreaterThanZero(contractRateAmount)) {
                    shipperContractAmount = contractRateAmount;
                    if (isNumberGreaterThanZero(fuelSurchargeAmount)) {
                        shipperContractAmount += Number(fuelSurchargeAmount);
                    }
                }
                carrierContractAmount = 0;
            }
        }
        let summary = Pricing.newLoadPricingSummary(
            0,
            0,
            shipperContractAmount,
            carrierContractAmount,
            practicalDistance,
            isListNotEmpty(invoiceLineItems) ? invoiceLineItems.filter(i => Enums.StopFees.doesNameExist(i.itemType)) : []
        );

        // console.log(summary);

        setTotalFeePerMile(summary.feePerMileAmount);
        setShipperRatePerMile(summary.shipperRatePerMileAmount);
        setCarrierRatePerMile(summary.carrierRatePerMileAmount);
        setCommissionPercentage(summary.IRISPercentage);
        setTotalAddOnCharge(summary.addOnChargeAmount);
        setTotalAddOnCost(summary.addOnCostAmount);
        setShipperAmount(summary.shipperAmount);
        setCarrierAmount(summary.carrierAmount);
        setIRISProfit(summary.IRISAmount);
    }, [invoiceLineItems, load, baseRatePerMileAmount, fuelSurchargePerMileAmount, fuelSurchargeAmount, contractRateAmount, contractRateCarrierAmount]);

    useMemo(() => {
        if (entityType === 'STAFF') {
            setIsCarriersLoading(true);
            if (isListNotEmpty(accounts)) {
                const carrierAccounts = accounts.filter(a => a.entityTypes.includes("CARRIER") === true);
                if (isListNotEmpty(carrierAccounts)) {
                    setCarriers(orderBy(carrierAccounts, ['name'], ['asc']));
                }
                else {
                    setCarriers([]);
                }
            }

            setIsCarriersLoading(false);
        } else if (entityType === 'SHIPPER') {
            setIsCarriersLoading(true);
            if (isListNotEmpty(linkedAccounts)) {
                const linkedCarriers = linkedAccounts.filter(a => a.entityTypes.includes("CARRIER") === true);
                if (isListNotEmpty(linkedCarriers)) {
                    setCarriers(orderBy(linkedCarriers, ['name'], ['asc']));
                } else {
                    setCarriers([]);
                }
            }
            setIsCarriersLoading(false);
        }
    }, [entityType, entityId, accounts, linkedAccounts]);

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLoadErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const formItemLayoutDouble = {
        labelCol1: { span: 12 },
        wrapperCol1: { span: 12 },
        labelCol2: { span: 12 },
        wrapperCol2: { span: 12 },
    };

    const radioStyle = {
        display: 'block',
        minHeight: '60px',
        lineHeight: '30px',
    };

    //#endregion

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Header
                    titleStyle={{ fontSize: 24 }}
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    title={"Carrier & Rate Options"}
                    title2={"Add invoice line items below to calculate all-in rate."}
                    icon={<FontAwesomeIcon className="anticon" icon={faMoneyBill} />}
                    iconStyle={{ background: 'transparent' }}
                />

                {CanDo({ entityTypes: ['SHIPPER'] }) ? (
                    <>
                        <Fieldset legend="TENDER OPTIONS">
                            <FormItem {...formItemLayout} format="vertical"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Radio.Group onBlur={onBlur} onChange={e => { onChangeTenderOption(e.target.value); onChange(e.target.value); }} value={value} name={name} ref={ref}>
                                        <Radio style={radioStyle} key="ASSIGN_CARRIER" value="ASSIGN_CARRIER">
                                            <span style={{ fontWeight: 'bold', fontSize: 16 }}>Offer to a Preferred Carrier</span><br />
                                            <FormItem labelCol={0} wrapperCol={24} required format="vertical"
                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                    <Select
                                                        placeholder="Please Search and Select a Carrier"
                                                        allowClear={true}
                                                        style={{ width: '100%' }}
                                                        virtual={false}
                                                        loading={isCarriersLoading === true}
                                                        disabled={showCarrierOptions === false}
                                                        onBlur={onBlur}
                                                        onChange={(selected) => { onChange(selected); }}
                                                        value={value}
                                                        name={name}
                                                        showSearch={true}
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        notFoundContent={
                                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Carrier Accounts for you to choose from.">
                                                                <Button type="primary" onClick={() => { props.history.push({ pathname: `/linkedCarriers/` }); }}>Setup Preferred Carriers</Button>
                                                            </Empty>
                                                        }
                                                        ref={ref}
                                                    >
                                                        {carriers.map(c => <Option value={c.id} key={c.id}>{c.name}</Option>)}
                                                    </Select>
                                                )}
                                                rules={{ required: showCarrierOptions === true ? "Required Field" : false }}
                                                name="assignedCarrierId"
                                            />
                                        </Radio>
                                        <Radio style={radioStyle} key="IRIS" value="IRIS">
                                            <span style={{ fontWeight: 'bold', fontSize: 16 }}>Have IRIS Freight Broker the Load</span>
                                        </Radio>
                                    </Radio.Group>
                                )}
                                rules={{ required: 'Required Field' }}
                                name="tenderOption"
                            />
                        </Fieldset>
                        {showPricing === true ? (
                            <>
                                <Row gutter={[24, 24]}>
                                    <Col span={12}>
                                        <Fieldset legend="SUBTOTAL">
                                            {isListNotEmpty(invoiceLineItems) ? invoiceLineItems.filter(i => i.fromEntityType === 'SHIPPER' && Enums.StopFees.doesNameExist(i.itemType)).map((lineItem, index) => {
                                                return (
                                                    <DataRow key={`lineItem-${index}`} title={lineItem.description} value={lineItem.totalAmount} dataType="Money" />
                                                );
                                            }) : null}
                                            <div><Typography.Title level={3}>Subtotal: {stringFormatter.toFormattedMoney(totalAddOnCharge)}</Typography.Title></div>
                                        </Fieldset>
                                    </Col>
                                    <Col span={12}>
                                        <Fieldset legend="ROUTE & EQUIPMENT NEEDS">
                                            {isNumberNotEmpty(load.practicalDistance) ? <DataRow title="Trip Miles" titleStyle={{ fontWeight: 'normal' }} value={load.practicalDistance} units="MI" dataType="Distance" /> : null}
                                            {isListNotEmpty(stops) ? <DataRow title="Total Stops" titleStyle={{ fontWeight: 'normal' }} value={stops.length} /> : null}
                                            {isListNotEmpty(load.equipmentSpecifics) ? (
                                                <DataListRow titleStyle={{ fontWeight: 'normal' }} title="Equipment Needs and Services" data={load.equipmentSpecifics} dataType="EquipmentSpecific" />
                                            ) : null}
                                            {isListNotEmpty(load.driverRequirements) ? (
                                                <DataListRow titleStyle={{ fontWeight: 'normal' }} title="Driver Requirements" data={load.driverRequirements} dataType="DriverRequirement" />
                                            ) : null}
                                        </Fieldset>
                                    </Col>
                                </Row>
                                <Fieldset legend="CARRIER RATE OPTIONS">
                                    <FormItem {...formItemLayout} format="vertical"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Radio.Group onBlur={onBlur} onChange={e => { onChangeRateOption(e.target.value); onChange(e.target.value); }} value={value} name={name} ref={ref}>
                                                <Radio style={radioStyle} key="PER_MILE" value="PER_MILE">
                                                    <span style={{ fontWeight: 'bold', fontSize: 16 }}>Rate Per Mile</span><br />
                                                    <div style={{ paddingLeft: 24, paddingTop: 12, paddingBottom: 12 }}>
                                                        <div><span style={{ fontSize: 14 }}>Calculate the charge to your customer by the miles for this trip.</span></div>
                                                        <FormItemDouble {...formItemLayoutDouble} label1="Rate Per Mile" label2="Fuel Surcharge (optional)" required1 format="vertical"
                                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                                <InputNumber
                                                                    precision={2}
                                                                    min={0}
                                                                    placeholder="Enter the rate per mile in USD $"
                                                                    style={{ width: '100%' }}
                                                                    onBlur={onBlur}
                                                                    onChange={e => { setBaseRatePerMileAmount(e); onChange(e); }}
                                                                    value={value}
                                                                    name={name}
                                                                    disabled={showPerMile === false}
                                                                    ref={ref}
                                                                />
                                                            )}
                                                            rules1={{ required: showPerMile === true ? "Required Field" : null }}
                                                            name1="baseRatePerMileAmount"
                                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                                <InputNumber
                                                                    precision={2}
                                                                    min={0}
                                                                    placeholder="Enter the rate per mile in USD $"
                                                                    style={{ width: '100%' }}
                                                                    onBlur={onBlur}
                                                                    onChange={e => { setFuelSurchargePerMileAmount(e); onChange(e); }}
                                                                    value={value}
                                                                    name={name}
                                                                    disabled={showPerMile === false}
                                                                    ref={ref}
                                                                />
                                                            )}
                                                            rules2={{ required: false }}
                                                            name2="fuelSurchargePerMileAmount"
                                                        />
                                                    </div>
                                                </Radio>
                                                <Radio style={radioStyle} key="CONTRACT_RATE" value="CONTRACT_RATE">
                                                    <span style={{ fontWeight: 'bold', fontSize: 16 }}>Contract Rate</span><br />
                                                    <div style={{ paddingLeft: 24, paddingTop: 12, paddingBottom: 12 }}>
                                                        <div><span style={{ fontSize: 14 }}>Enter a rate to charge, in addition to fees accounted for above in Subtotal.</span></div>
                                                        <FormItemDouble {...formItemLayoutDouble} label1={`Subtotal: ${stringFormatter.toFormattedMoney(totalAddOnCharge)} plus`} label2="Fuel Surcharge (optional)" required1 format="vertical"
                                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                                <InputNumber
                                                                    precision={2}
                                                                    min={0}
                                                                    placeholder="Enter the rate in USD $"
                                                                    style={{ width: '100%' }}
                                                                    onBlur={onBlur}
                                                                    onChange={e => { setContractRateAmount(e); onChange(e); }}
                                                                    value={value}
                                                                    name={name}
                                                                    disabled={showContractRate === false}
                                                                    ref={ref}
                                                                />
                                                            )}
                                                            rules1={{ required: showContractRate === true ? "Required Field" : false }}
                                                            name1="contractRateAmount"
                                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                                <InputNumber
                                                                    precision={2}
                                                                    min={0}
                                                                    placeholder="Enter the rate in USD $"
                                                                    style={{ width: '100%' }}
                                                                    onBlur={onBlur}
                                                                    onChange={e => { setFuelSurchargeAmount(e); onChange(e); }}
                                                                    value={value}
                                                                    name={name}
                                                                    disabled={showContractRate === false}
                                                                    ref={ref}
                                                                />
                                                            )}
                                                            rules2={{ required: false }}
                                                            name2="fuelSurchargeAmount"
                                                        />
                                                    </div>
                                                </Radio>
                                            </Radio.Group>
                                        )}
                                        rules={{ required: 'Required Field' }}
                                        name="rateOption"
                                    />
                                </Fieldset>
                                <Row gutter={[8, 8]}>
                                    <Col span={12}>
                                        <div><Typography.Title level={2}>Total: {stringFormatter.toFormattedMoney(shipperAmount)}</Typography.Title></div>
                                        <div><Typography.Title level={4}>Total Rate Per Mile: {stringFormatter.toFormattedMoney(shipperRatePerMile)}</Typography.Title></div>
                                    </Col>
                                </Row>
                            </>
                        ) : null}
                    </>
                ) : null}
                {CanDo({ staffOnly: true }) ? (
                    <>
                        <Fieldset legend="ASSIGN A CARRIER TO THE LOAD">
                            <div><span style={{ fontWeight: 'bold', fontSize: 16 }}>Offer to a Preferred Carrier</span><br /></div>
                            <FormItem {...formItemLayout} required format="vertical"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please Search and Select a Carrier"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        loading={isCarriersLoading === true}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        showSearch={true}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Carrier Accounts for you to choose from." />}
                                        ref={ref}
                                    >
                                        {carriers.map(c => <Option value={c.id} key={c.id}>{c.name}</Option>)}
                                    </Select>
                                )}
                                rules={{ required: "Required Field" }}
                                name="assignedCarrierId"
                            />
                        </Fieldset>
                        <Row gutter={[8, 8]}>
                            <Col span={12}>
                                <Fieldset legend="SUBTOTAL">
                                    {isListNotEmpty(invoiceLineItems) ? invoiceLineItems.filter(i => i.fromEntityType === 'SHIPPER' && Enums.StopFees.doesNameExist(i.itemType)).map((lineItem, index) => {
                                        return (
                                            <DataRow key={`lineItem-${index}`} title={lineItem.description} value={lineItem.totalAmount} dataType="Money" />
                                        );
                                    }) : null}
                                    <div><Typography.Title level={3}>Subtotal: {stringFormatter.toFormattedMoney(totalAddOnCharge)}</Typography.Title></div>
                                </Fieldset>
                            </Col>
                            <Col span={12}>
                                <Fieldset legend="ROUTE & EQUIPMENT NEEDS">
                                    {isNumberNotEmpty(load.practicalDistance) ? <DataRow title="Trip Miles" titleStyle={{ fontWeight: 'normal' }} value={load.practicalDistance} units="MI" dataType="Distance" /> : null}
                                    {isListNotEmpty(stops) ? <DataRow title="Total Stops" titleStyle={{ fontWeight: 'normal' }} value={stops.length} /> : null}
                                    {isListNotEmpty(load.equipmentSpecifics) ? (
                                        <DataListRow titleStyle={{ fontWeight: 'normal' }} title="Equipment Needs and Services" data={load.equipmentSpecifics} dataType="EquipmentSpecific" />
                                    ) : null}
                                    {isListNotEmpty(load.driverRequirements) ? (
                                        <DataListRow titleStyle={{ fontWeight: 'normal' }} title="Driver Requirements" data={load.driverRequirements} dataType="DriverRequirement" />
                                    ) : null}
                                </Fieldset>
                            </Col>
                        </Row>
                        <Fieldset legend="RATE TO CHARGE SHIPPER">
                            <Row gutter={[8, 8]}>
                                <Col span={24}>
                                    <FormItemDisplay {...formItemLayout} label={`Subtotal: ${stringFormatter.toFormattedMoney(totalAddOnCharge)} plus`} required format="vertical">
                                        <div><Typography.Title level={4}>Shipper Rate: {stringFormatter.toFormattedMoney(contractRateAmount)}</Typography.Title></div>
                                    </FormItemDisplay>
                                    {/* <FormItem {...formItemLayout} label={`Subtotal: ${stringFormatter.toFormattedMoney(totalAddOnCharge)} plus`} required format="vertical"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <InputNumber
                                                precision={2}
                                                min={0}
                                                placeholder="Enter the rate in USD $"
                                                style={{ width: '100%' }}
                                                onBlur={onBlur}
                                                onChange={e => { setContractRateAmount(e); onChange(e); }}
                                                value={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        rules={{ required: "Required Field" }}
                                        name="contractRateAmount"
                                    /> */}
                                </Col>
                                <Col span={24}>
                                    <div><Typography.Title level={2}>Total: {stringFormatter.toFormattedMoney(shipperAmount)}</Typography.Title></div>
                                    <div><Typography.Title level={4}>Total Rate Per Mile: {stringFormatter.toFormattedMoney(shipperRatePerMile)}</Typography.Title></div>
                                </Col>
                            </Row>
                        </Fieldset>
                        <Fieldset legend="CARRIER RATE OPTIONS">
                            <FormItem {...formItemLayout} format="vertical"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Radio.Group onBlur={onBlur} onChange={e => { onChangeRateOption(e.target.value); onChange(e.target.value); }} value={value} name={name} ref={ref}>
                                        <Radio style={radioStyle} key="PER_MILE" value="PER_MILE">
                                            <span style={{ fontWeight: 'bold', fontSize: 16 }}>Rate Per Mile</span><br />
                                            <div style={{ paddingLeft: 24, paddingTop: 12, paddingBottom: 12 }}>
                                                <div><span style={{ fontSize: 14 }}>Calculate the cost to the carrier by the miles for this trip.</span></div>
                                                <FormItemDouble {...formItemLayoutDouble} label1="Rate Per Mile" label2="Fuel Surcharge (optional)" required1 format="vertical"
                                                    render1={({ onChange, onBlur, value, name, ref }) => (
                                                        <InputNumber
                                                            precision={2}
                                                            min={0}
                                                            placeholder="Enter the rate per mile in USD $"
                                                            style={{ width: '100%' }}
                                                            onBlur={onBlur}
                                                            onChange={e => { setBaseRatePerMileAmount(e); onChange(e); }}
                                                            value={value}
                                                            name={name}
                                                            disabled={showPerMile === false}
                                                            ref={ref}
                                                        />
                                                    )}
                                                    rules1={{ required: showPerMile === true ? "Required Field" : false }}
                                                    name1="baseRatePerMileAmount"
                                                    render2={({ onChange, onBlur, value, name, ref }) => (
                                                        <InputNumber
                                                            precision={2}
                                                            min={0}
                                                            placeholder="Enter the rate per mile in USD $"
                                                            style={{ width: '100%' }}
                                                            onBlur={onBlur}
                                                            onChange={e => { setFuelSurchargePerMileAmount(e); onChange(e); }}
                                                            value={value}
                                                            name={name}
                                                            disabled={showPerMile === false}
                                                            ref={ref}
                                                        />
                                                    )}
                                                    rules2={{ required: false }}
                                                    name2="fuelSurchargePerMileAmount"
                                                />
                                            </div>
                                        </Radio>
                                        <Radio style={radioStyle} key="CONTRACT_RATE" value="CONTRACT_RATE">
                                            <span style={{ fontWeight: 'bold', fontSize: 16 }}>Contract Rate</span><br />
                                            <div style={{ paddingLeft: 24, paddingTop: 12, paddingBottom: 12 }}>
                                                <div><span style={{ fontSize: 14 }}>Enter a rate to charge, in addition to fees accounted for above in Subtotal.</span></div>
                                                <FormItem {...formItemLayout} label={`Subtotal: ${stringFormatter.toFormattedMoney(totalAddOnCost)} plus`} required format="vertical"
                                                    render={({ onChange, onBlur, value, name, ref }) => (
                                                        <InputNumber
                                                            precision={2}
                                                            min={0}
                                                            placeholder="Enter the rate in USD $"
                                                            style={{ width: '100%' }}
                                                            onBlur={onBlur}
                                                            onChange={e => { setContractRateCarrierAmount(e); onChange(e); }}
                                                            value={value}
                                                            name={name}
                                                            disabled={showContractRate === false}
                                                            ref={ref}
                                                        />
                                                    )}
                                                    rules={{ required: showContractRate === true ? "Required Field" : false }}
                                                    name="contractRateCarrierAmount"
                                                />
                                            </div>
                                        </Radio>
                                    </Radio.Group>
                                )}
                                rules={{ required: 'Required Field' }}
                                name="rateOption"
                            />
                        </Fieldset>
                        <Row gutter={[8, 8]}>
                            <Col span={12}>
                                <div><Typography.Title level={2}>Total: {stringFormatter.toFormattedMoney(carrierAmount)}</Typography.Title></div>
                                <div><Typography.Title level={4}>Total Rate Per Mile: {stringFormatter.toFormattedMoney(carrierRatePerMile)}</Typography.Title></div>
                            </Col>
                            <Col span={12}>
                                <div><Typography.Title level={2}>IRIS Profit: {stringFormatter.toFormattedMoney(IRISProfit)}</Typography.Title></div>
                                <div><Typography.Title level={4}>Commission: {stringFormatter.toFormattedPercentage(commissionPercentage)}</Typography.Title></div>
                            </Col>
                        </Row>
                    </>
                ) : null}
                <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Save Changes" />
            </Form>
        </FormProvider>
    );
};

export default withRouter(AssignLoadCarrier);