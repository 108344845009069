import React, { useMemo, useState } from 'react';
import { Input, Spin, Radio, Typography, Alert, InputNumber, Switch, Row, Col } from "antd";
import DatePicker from '../DatePickerAutoAccept/DatePickerAutoAccept';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions";
import UploadFile from "../UploadFile/UploadFile";
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import Fieldset from '../FormFieldset/FormFieldset';
import FormItemFile from '../FormItemFile/FormItemFile';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import FormButtons from '../FormButtons/FormButtons';
import moment from 'moment';
import { isMomentDate, isNotNullOrUndefined, isNullOrUndefined, isObjectNotEmpty } from '../../shared/objectUtils';
import { CanDo } from '../../shared/entitlements/entitlements';
import FormItemAddress from '../FormItemAddress/FormItemAddress';
import FormItemPointOfContact from '../FormItemPointOfContact/FormItemPointOfContact';

const { Paragraph } = Typography;

const EditCarrier = ({ cancel, account }) => {
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.accounts.isRecordUpdateLoading);
    const error = useSelector(state => state.accounts.updateRecordError);
    const entityType = useSelector(state => state.auth.entityType);

    //#endregion
    //#region useStates

    const [insuranceExpiryDate, setInsuranceExpiryDate] = useState(null);
    const [w9ExpiryDate, setW9ExpiryDate] = useState(null);
    const [insuranceFile, setInsuranceFile] = useState(null);
    const [w9File, setW9File] = useState(null);

    //#endregion
    //#region onSubmit and onCancel

    const onSubmit = (data) => {
        // console.log(data);
        if (isNotNullOrUndefined(w9File)) {
            methods.clearErrors('w9File');
        } else if (isNullOrUndefined(account.w9FileId)) {
            methods.setError('w9File', { type: 'required', message: "Required File" });
        }

        // console.log(insuranceFile);
        if (isNotNullOrUndefined(insuranceFile)) {
            methods.clearErrors('insuranceFile');
        } else if (isNullOrUndefined(account.insuranceFileId)) {
            methods.setError('insuranceFile', { type: 'required', message: "Required File" });
        }

        if ((isNullOrUndefined(w9File) && isNullOrUndefined(account.w9FileId)) || (isNullOrUndefined(insuranceFile) && isNullOrUndefined(account.insuranceFileId))) {
            return;
        }

        let insuranceExpiryDateValue = null;
        let w9ExpiryDateValue = null;

        if (isNotNullOrUndefined(data.insuranceExpiryDate)) {
            if (isMomentDate(data.insuranceExpiryDate)) {
                insuranceExpiryDateValue = data.insuranceExpiryDate.utc().toISOString();
            }
            delete data['insuranceExpiryDate'];
        }

        if (isNotNullOrUndefined(data.w9ExpiryDate)) {
            if (isMomentDate(data.w9ExpiryDate)) {
                w9ExpiryDateValue = data.w9ExpiryDate.utc().toISOString();
            }
            delete data['w9ExpiryDate'];
        }

        if (isObjectNotEmpty(data)) {
            dispatch(actionCreators.updateAccount(account.id, data, null, null, insuranceFile, account.insuranceFileId, insuranceExpiryDateValue, w9File, account.w9FileId, w9ExpiryDateValue, entityType));
        } else {
            onCancel();
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.updateAccountCancel());
        cancel();
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        if (isObjectNotEmpty(account)) {
            if (isNotNullOrUndefined(account.insuranceExpiryDate)) {
                setInsuranceExpiryDate(moment(account.insuranceExpiryDate));
            }
            if (isNotNullOrUndefined(account.w9ExpiryDate)) {
                setW9ExpiryDate(moment(account.w9ExpiryDate));
            }
        }
    }, [account]);

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateAccountErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const formItemLayoutHorizontal = {
        labelCol: { span: 20 },
        wrapperCol: { span: 4 },
    };

    const formItemLayoutDoubleHorizontal = {
        labelCol1: { span: 12 },
        wrapperCol1: { span: 1 },
        labelCol2: { span: 6 },
        wrapperCol2: { span: 5 },
    };

    //#endregion

    return (
        <>
            {isObjectNotEmpty(account) ? (
                <FormProvider {...methods}>
                    <Form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                            <Fieldset legend="Carrier Details (* indicates a required field)">
                                <FormItem {...formItemLayout} label="US Docket # (MC, FF, MX)" required format="horizontal"
                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="MC Number" ref={ref} />}
                                    rules={{ required: "Required Field" }}
                                    name="mcNum"
                                    defaultValue={account.mcNum ? account.mcNum : ''}
                                />
                                <FormItem {...formItemLayout} label="US DOT #" required format="horizontal"
                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="DOT #" ref={ref} />}
                                    rules={{ required: "Required Field" }}
                                    name="dotNum"
                                    defaultValue={account.dotNum ? account.dotNum : ''}
                                />
                            </Fieldset>
                            <Fieldset legend="Carrier Documents (* indicates a required field or file)">
                                <FormItem {...formItemLayout} label="W9 Expiration Date" required format="horizontal"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <DatePicker
                                            placeholder="Expiration Date"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={(date, dateString) => { setW9ExpiryDate(date); onChange(date); }}
                                            value={value}
                                            name={name}
                                            onSelect={(date) => { onChange(date); }}
                                            ref={ref}
                                        />
                                    )}
                                    rules={{ required: "Required Field" }}
                                    name="w9ExpiryDate"
                                    defaultValue={account.w9ExpiryDate ? moment(account.w9ExpiryDate) : null}
                                />
                                <FormItemFile {...formItemLayout} label="W9 File" required name="w9File" format="horizontal">
                                    <UploadFile
                                        beforeUpload={(file) => {
                                            setW9File(file);
                                            methods.clearErrors('w9File');
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            setW9File(null);
                                        }}
                                        message="Please upload a photo of the document."
                                        fileId={account.w9FileId}
                                    />
                                </FormItemFile>
                                <FormItem {...formItemLayout} label="Insurance Expiration Date" required format="horizontal"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <DatePicker
                                            placeholder="Expiration Date"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={(date, dateString) => { setInsuranceExpiryDate(date); onChange(date); }}
                                            value={value}
                                            name={name}
                                            onSelect={(date) => { onChange(date); }}
                                            ref={ref}
                                        />
                                    )}
                                    rules={{ required: "Required Field" }}
                                    name="insuranceExpiryDate"
                                    defaultValue={account.w9ExpiryDate ? moment(account.w9ExpiryDate) : null}
                                />
                                <FormItemFile {...formItemLayout} label="Insurance File" required name="insuranceFile" format="horizontal">
                                    <UploadFile
                                        beforeUpload={(file) => {
                                            setInsuranceFile(file);
                                            methods.clearErrors('insuranceFile');
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            setInsuranceFile(null);
                                        }}
                                        message="Please upload a photo of the document."
                                        fileId={account.insuranceFileId}
                                    />
                                </FormItemFile>
                            </Fieldset>
                            <Fieldset legend="Accounts Receivable (* indicates a required field)">
                                <FormItem {...formItemLayout} label="Preferred Payment Method" required format="horizontal"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Radio.Group onBlur={onBlur} onChange={e => onChange(e.target.value)} value={value} name={name} buttonStyle="solid" ref={ref}>
                                            <Radio.Button value="DIRECT_DEPOSIT">Direct Deposit</Radio.Button>
                                            <Radio.Button value="QUICK_PAY">Quick Pay</Radio.Button>
                                            <Radio.Button value="FACTORING">Factoring</Radio.Button>
                                        </Radio.Group>
                                    )}
                                    rules={{ required: "Required Field" }}
                                    name="preferredPaymentMethod"
                                    defaultValue={account.preferredPaymentMethod ? account.preferredPaymentMethod : undefined}
                                />
                                <FormItemPointOfContact
                                    format="horizontal"
                                    required={true}
                                    name="accountsReceivable"
                                    defaultValue={account.accountsReceivable}
                                    nameFieldLabel="Contact Name"
                                />

                                <Paragraph style={{ marginTop: 12, marginBottom: 12 }}>If the carrier's accounts receivable department's address is different than the company's main physical address, please provide it below.</Paragraph>

                                <FormItemAddress
                                    format="horizontal"
                                    required={false}
                                    name="accountsReceivableAddress"
                                    defaultValue={account.accountsReceivableAddress}
                                    isPostalAddress={true}
                                />
                            </Fieldset>
                            <Fieldset legend="Point of Contact">
                                <FormItemPointOfContact
                                    format="horizontal"
                                    required={false}
                                    name="pointOfContact"
                                    defaultValue={account.pointOfContact}
                                />
                            </Fieldset>
                            <Fieldset legend="Asset Information (* indicates a required field)">
                                <Row gutter={[16, 16]}>
                                    <Col xxl={{ span: 4 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <FormItem {...formItemLayout} label="# of Power Units" format="vertical"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            rules={{ required: false }}
                                            name="assetNeeds.numPowerUnits"
                                            defaultValue={account.assetNeeds && account.assetNeeds.numPowerUnits ? account.assetNeeds.numPowerUnits : ''}
                                        />
                                    </Col>
                                    <Col xxl={{ span: 4 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <FormItem {...formItemLayout} label="# of Trailers" format="vertical"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            rules={{ required: false }}
                                            name="assetNeeds.numTrailers"
                                            defaultValue={account.assetNeeds && account.assetNeeds.numTrailers ? account.assetNeeds.numTrailers : ''}
                                        />
                                    </Col>
                                    <Col xxl={{ span: 4 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <FormItem {...formItemLayout} label="# of Drivers" format="vertical"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            rules={{ required: false }}
                                            name="assetNeeds.numDrivers"
                                            defaultValue={account.assetNeeds && account.assetNeeds.numDrivers ? account.assetNeeds.numDrivers : ''}
                                        />
                                    </Col>
                                    <Col xxl={{ span: 4 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <FormItem {...formItemLayout} label="# of HazMat Drivers" format="vertical"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            rules={{ required: false }}
                                            name="assetNeeds.numHazMatDrivers"
                                            defaultValue={account.assetNeeds && account.assetNeeds.numHazMatDrivers ? account.assetNeeds.numHazMatDrivers : ''}
                                        />
                                    </Col>
                                    <Col xxl={{ span: 4 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <FormItem {...formItemLayout} label="# of Teams" format="vertical"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            rules={{ required: false }}
                                            name="assetNeeds.numTeams"
                                            defaultValue={account.assetNeeds && account.assetNeeds.numTeams ? account.assetNeeds.numTeams : ''}
                                        />
                                    </Col>
                                </Row>
                            </Fieldset>
                            <Fieldset legend="Trailer Types">
                                <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <h4 style={{ textAlign: 'center' }}>Reefers</h4>
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 48' Reefers?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                <Switch
                                                    checkedChildren="Yes"
                                                    unCheckedChildren="No"
                                                    onBlur={onBlur}
                                                    onChange={(checked, event) => { onChange(checked); }}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name1="assetNeeds.has48Reefer"
                                            defaultValue1={account.assetNeeds && account.assetNeeds.has48Reefer ? account.assetNeeds.has48Reefer : false}
                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name2="assetNeeds.num48Reefer"
                                            defaultValue2={account.assetNeeds && account.assetNeeds.num48Reefer ? account.assetNeeds.num48Reefer : ''}
                                        />
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 53' Reefers?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                <Switch
                                                    checkedChildren="Yes"
                                                    unCheckedChildren="No"
                                                    onBlur={onBlur}
                                                    onChange={(checked, event) => { onChange(checked); }}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name1="assetNeeds.has53Reefer"
                                            defaultValue1={account.assetNeeds && account.assetNeeds.has53Reefer ? account.assetNeeds.has53Reefer : false}
                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name2="assetNeeds.num53Reefer"
                                            defaultValue2={account.assetNeeds && account.assetNeeds.num53Reefer ? account.assetNeeds.num53Reefer : ''}
                                        />
                                        <FormItem {...formItemLayoutHorizontal} label="Do you haul frozen?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Switch
                                                    checkedChildren="Yes"
                                                    unCheckedChildren="No"
                                                    onBlur={onBlur}
                                                    onChange={(checked, event) => { onChange(checked); }}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name="assetNeeds.hasReeferFrozen"
                                            defaultValue={account.assetNeeds && account.assetNeeds.hasReeferFrozen ? account.assetNeeds.hasReeferFrozen : false}
                                        />
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <h4 style={{ textAlign: 'center' }}>Dry Vans</h4>
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 48' Dry Vans?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                <Switch
                                                    checkedChildren="Yes"
                                                    unCheckedChildren="No"
                                                    onBlur={onBlur}
                                                    onChange={(checked, event) => { onChange(checked); }}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name1="assetNeeds.has48DryVan"
                                            defaultValue1={account.assetNeeds && account.assetNeeds.has48DryVan ? account.assetNeeds.has48DryVan : false}
                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name2="assetNeeds.num48DryVan"
                                            defaultValue2={account.assetNeeds && account.assetNeeds.num48DryVan ? account.assetNeeds.num48DryVan : ''}
                                        />
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 53' Dry Vans?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                <Switch
                                                    checkedChildren="Yes"
                                                    unCheckedChildren="No"
                                                    onBlur={onBlur}
                                                    onChange={(checked, event) => { onChange(checked); }}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name1="assetNeeds.has53DryVan"
                                            defaultValue1={account.assetNeeds && account.assetNeeds.has53DryVan ? account.assetNeeds.has53DryVan : false}
                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name2="assetNeeds.num53DryVan"
                                            defaultValue2={account.assetNeeds && account.assetNeeds.num53DryVan ? account.assetNeeds.num53DryVan : ''}
                                        />
                                        <FormItem {...formItemLayoutHorizontal} label="Do you have lift gates for the dry vans?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Switch
                                                    checkedChildren="Yes"
                                                    unCheckedChildren="No"
                                                    onBlur={onBlur}
                                                    onChange={(checked, event) => { onChange(checked); }}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name="assetNeeds.hasDryVanLiftGates"
                                            defaultValue={account.assetNeeds && account.assetNeeds.hasDryVanLiftGates ? account.assetNeeds.hasDryVanLiftGates : false}
                                        />
                                        <FormItem {...formItemLayoutHorizontal} label="Do you have roller beds for the dry vans?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Switch
                                                    checkedChildren="Yes"
                                                    unCheckedChildren="No"
                                                    onBlur={onBlur}
                                                    onChange={(checked, event) => { onChange(checked); }}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name="assetNeeds.hasDryVanRollerBed"
                                            defaultValue={account.assetNeeds && account.assetNeeds.hasDryVanRollerBed ? account.assetNeeds.hasDryVanRollerBed : false}
                                        />
                                        <h4 style={{ textAlign: 'center' }}>Over-Sized Loads</h4>
                                        <FormItem {...formItemLayoutHorizontal} label="Do you haul over dimensional/overweight loads?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Switch
                                                    checkedChildren="Yes"
                                                    unCheckedChildren="No"
                                                    onBlur={onBlur}
                                                    onChange={(checked, event) => { onChange(checked); }}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name="assetNeeds.hasOverSized"
                                            defaultValue={account.assetNeeds && account.assetNeeds.hasOverSized ? account.assetNeeds.hasOverSized : false}
                                        />
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <h4 style={{ textAlign: 'center' }}>Flatbeds</h4>
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 48' Flatbeds?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                <Switch
                                                    checkedChildren="Yes"
                                                    unCheckedChildren="No"
                                                    onBlur={onBlur}
                                                    onChange={(checked, event) => { onChange(checked); }}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name1="assetNeeds.has48Flatbed"
                                            defaultValue1={account.assetNeeds && account.assetNeeds.has48Flatbed ? account.assetNeeds.has48Flatbed : false}
                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name2="assetNeeds.num48Flatbed"
                                            defaultValue2={account.assetNeeds && account.assetNeeds.num48Flatbed ? account.assetNeeds.num48Flatbed : ''}
                                        />
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 53' Flatbeds?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                <Switch
                                                    checkedChildren="Yes"
                                                    unCheckedChildren="No"
                                                    onBlur={onBlur}
                                                    onChange={(checked, event) => { onChange(checked); }}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name1="assetNeeds.has53Flatbed"
                                            defaultValue1={account.assetNeeds && account.assetNeeds.has53Flatbed ? account.assetNeeds.has53Flatbed : false}
                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name2="assetNeeds.num53Flatbed"
                                            defaultValue2={account.assetNeeds && account.assetNeeds.num53Flatbed ? account.assetNeeds.num53Flatbed : ''}
                                        />
                                        <h4 style={{ textAlign: 'center' }}>Low Boys</h4>
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Low Boys?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                <Switch
                                                    checkedChildren="Yes"
                                                    unCheckedChildren="No"
                                                    onBlur={onBlur}
                                                    onChange={(checked, event) => { onChange(checked); }}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name1="assetNeeds.hasLowBoy"
                                            defaultValue1={account.assetNeeds && account.assetNeeds.hasLowBoy ? account.assetNeeds.hasLowBoy : false}
                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name2="assetNeeds.numLowBoy"
                                            defaultValue2={account.assetNeeds && account.assetNeeds.numLowBoy ? account.assetNeeds.numLowBoy : ''}
                                        />
                                        <h4 style={{ textAlign: 'center' }}>RGNs</h4>
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have RGNs?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                <Switch
                                                    checkedChildren="Yes"
                                                    unCheckedChildren="No"
                                                    onBlur={onBlur}
                                                    onChange={(checked, event) => { onChange(checked); }}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name1="assetNeeds.hasRGN"
                                            defaultValue1={account.assetNeeds && account.assetNeeds.hasRGN ? account.assetNeeds.hasRGN : false}
                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name2="assetNeeds.numRGN"
                                            defaultValue2={account.assetNeeds && account.assetNeeds.numRGN ? account.assetNeeds.numRGN : ''}
                                        />
                                        <h4 style={{ textAlign: 'center' }}>Step Decks</h4>
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Step Decks with Ramps?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                <Switch
                                                    checkedChildren="Yes"
                                                    unCheckedChildren="No"
                                                    onBlur={onBlur}
                                                    onChange={(checked, event) => { onChange(checked); }}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name1="assetNeeds.hasStepDeckRamps"
                                            defaultValue1={account.assetNeeds && account.assetNeeds.hasStepDeckRamps ? account.assetNeeds.hasStepDeckRamps : false}
                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name2="assetNeeds.numStepDeckRamps"
                                            defaultValue2={account.assetNeeds && account.assetNeeds.numStepDeckRamps ? account.assetNeeds.numStepDeckRamps : ''}
                                        />
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Step Decks without Ramps?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                <Switch
                                                    checkedChildren="Yes"
                                                    unCheckedChildren="No"
                                                    onBlur={onBlur}
                                                    onChange={(checked, event) => { onChange(checked); }}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name1="assetNeeds.hasStepDeckNoRamps"
                                            defaultValue1={account.assetNeeds && account.assetNeeds.hasStepDeckNoRamps ? account.assetNeeds.hasStepDeckNoRamps : false}
                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name2="assetNeeds.numStepDeckNoRamps"
                                            defaultValue2={account.assetNeeds && account.assetNeeds.numStepDeckNoRamps ? account.assetNeeds.numStepDeckNoRamps : ''}
                                        />
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <h4 style={{ textAlign: 'center' }}>Tankers</h4>
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Single Compartment Tankers?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                <Switch
                                                    checkedChildren="Yes"
                                                    unCheckedChildren="No"
                                                    onBlur={onBlur}
                                                    onChange={(checked, event) => { onChange(checked); }}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name1="assetNeeds.hasTankersSingleCompartment"
                                            defaultValue1={account.assetNeeds && account.assetNeeds.hasTankersSingleCompartment ? account.assetNeeds.hasTankersSingleCompartment : false}
                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name2="assetNeeds.numTankersSingleCompartment"
                                            defaultValue2={account.assetNeeds && account.assetNeeds.numTankersSingleCompartment ? account.assetNeeds.numTankersSingleCompartment : ''}
                                        />
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Multi Compartment Tankers?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                <Switch
                                                    checkedChildren="Yes"
                                                    unCheckedChildren="No"
                                                    onBlur={onBlur}
                                                    onChange={(checked, event) => { onChange(checked); }}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name1="assetNeeds.hasTankersMultiCompartment"
                                            defaultValue1={account.assetNeeds && account.assetNeeds.hasTankersMultiCompartment ? account.assetNeeds.hasTankersMultiCompartment : false}
                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name2="assetNeeds.numTankersMultiCompartment"
                                            defaultValue2={account.assetNeeds && account.assetNeeds.numTankersMultiCompartment ? account.assetNeeds.numTankersMultiCompartment : ''}
                                        />
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Fiber Glass Tankers?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                <Switch
                                                    checkedChildren="Yes"
                                                    unCheckedChildren="No"
                                                    onBlur={onBlur}
                                                    onChange={(checked, event) => { onChange(checked); }}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name1="assetNeeds.hasTankersFiberGlass"
                                            defaultValue1={account.assetNeeds && account.assetNeeds.hasTankersFiberGlass ? account.assetNeeds.hasTankersFiberGlass : false}
                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name2="assetNeeds.numTankersFiberGlass"
                                            defaultValue2={account.assetNeeds && account.assetNeeds.numTankersFiberGlass ? account.assetNeeds.numTankersFiberGlass : ''}
                                        />
                                        <h4 style={{ textAlign: 'center' }}>Straight Trucks</h4>
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Cubed Straight Trucks?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                <Switch
                                                    checkedChildren="Yes"
                                                    unCheckedChildren="No"
                                                    onBlur={onBlur}
                                                    onChange={(checked, event) => { onChange(checked); }}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name1="assetNeeds.hasCubedStraightTruck"
                                            defaultValue1={account.assetNeeds && account.assetNeeds.hasCubedStraightTruck ? account.assetNeeds.hasCubedStraightTruck : false}
                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name2="assetNeeds.numCubedStraightTruck"
                                            defaultValue2={account.assetNeeds && account.assetNeeds.numCubedStraightTruck ? account.assetNeeds.numCubedStraightTruck : ''}
                                        />
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 26' Straight Trucks?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                <Switch
                                                    checkedChildren="Yes"
                                                    unCheckedChildren="No"
                                                    onBlur={onBlur}
                                                    onChange={(checked, event) => { onChange(checked); }}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name1="assetNeeds.has26StraightTruck"
                                            defaultValue1={account.assetNeeds && account.assetNeeds.has26StraightTruck ? account.assetNeeds.has26StraightTruck : false}
                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name2="assetNeeds.num26StraightTruck"
                                            defaultValue2={account.assetNeeds && account.assetNeeds.num26StraightTruck ? account.assetNeeds.num26StraightTruck : ''}
                                        />
                                        <FormItem {...formItemLayoutHorizontal} label="Do you have lift gates for the straight trucks?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Switch
                                                    checkedChildren="Yes"
                                                    unCheckedChildren="No"
                                                    onBlur={onBlur}
                                                    onChange={(checked, event) => { onChange(checked); }}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name="assetNeeds.hasStraightTruckLiftGates"
                                            defaultValue={account.assetNeeds && account.assetNeeds.hasStraightTruckLiftGates ? account.assetNeeds.hasStraightTruckLiftGates : false}
                                        />
                                        <h4 style={{ textAlign: 'center' }}>Sprinter Vans</h4>
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Sprinter Vans?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                <Switch
                                                    checkedChildren="Yes"
                                                    unCheckedChildren="No"
                                                    onBlur={onBlur}
                                                    onChange={(checked, event) => { onChange(checked); }}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name1="assetNeeds.hasSprinterVan"
                                            defaultValue1={account.assetNeeds && account.assetNeeds.hasSprinterVan ? account.assetNeeds.hasSprinterVan : false}
                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            name2="assetNeeds.numSprinterVan"
                                            defaultValue2={account.assetNeeds && account.assetNeeds.numSprinterVan ? account.assetNeeds.numSprinterVan : ''}
                                        />
                                    </Col>
                                </Row>
                            </Fieldset>
                            {CanDo({ staffOnly: true }) ? (
                                <Fieldset legend="SMS Basic Scores">
                                    <FormItem {...formItemLayout} label="Hours of Service Compliance" format="horizontal"
                                        render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Hours of Service Compliance" ref={ref} />}
                                        rules={{ required: false }}
                                        name="smsBasic.hosCompliance"
                                        defaultValue={account.smsBasic && account.smsBasic.hosCompliance ? account.smsBasic.hosCompliance : ""}
                                    />
                                    <FormItem {...formItemLayout} label="Unsafe Driving" format="horizontal"
                                        render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Unsafe Driving" ref={ref} />}
                                        rules={{ required: false }}
                                        name="smsBasic.unsafeDriving"
                                        defaultValue={account.smsBasic && account.smsBasic.unsafeDriving ? account.smsBasic.unsafeDriving : ""}
                                    />
                                    <FormItem {...formItemLayout} label="Vehicle Maintenance" format="horizontal"
                                        render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Vehicle Maintenance" ref={ref} />}
                                        rules={{ required: false }}
                                        name="smsBasic.vehicleMaintenance"
                                        defaultValue={account.smsBasic && account.smsBasic.vehicleMaintenance ? account.smsBasic.vehicleMaintenance : ""}
                                    />
                                    <FormItem {...formItemLayout} label="Controlled Substance Abuse" format="horizontal"
                                        render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Controlled Substance Abuse" ref={ref} />}
                                        rules={{ required: false }}
                                        name="smsBasic.controlledSA"
                                        defaultValue={account.smsBasic && account.smsBasic.controlledSA ? account.smsBasic.controlledSA : ""}
                                    />
                                    <FormItem {...formItemLayout} label="Driver Fitness" format="horizontal"
                                        render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Driver Fitness" ref={ref} />}
                                        rules={{ required: false }}
                                        name="smsBasic.driverFitness"
                                        defaultValue={account.smsBasic && account.smsBasic.driverFitness ? account.smsBasic.driverFitness : ""}
                                    />
                                    <FormItem {...formItemLayout} label="Average SMS Score" format="horizontal"
                                        render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Average SMS Score" ref={ref} />}
                                        rules={{ required: false }}
                                        name="smsBasic.avgSMSScore"
                                        defaultValue={account.smsBasic && account.smsBasic.avgSMSScore ? account.smsBasic.avgSMSScore : ""}
                                    />
                                    <FormItem {...formItemLayout} label="Weighted Average SMS Score" format="horizontal"
                                        render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Weighted Average SMS Score" ref={ref} />}
                                        rules={{ required: false }}
                                        name="smsBasic.weightedAvgSMSScore"
                                        defaultValue={account.smsBasic && account.smsBasic.weightedAvgSMSScore ? account.smsBasic.weightedAvgSMSScore : ""}
                                    />
                                </Fieldset>
                            ) : null}
                            {error && <Alert message={`${error}`} type="error" />}
                        </Spin>
                        <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Update Carrier" />
                    </Form>
                </FormProvider>
            ) : null}
        </>
    );
};

export default EditCarrier;