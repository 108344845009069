import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { Spin, Tabs, Row, Col } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruckMoving } from '@fortawesome/free-solid-svg-icons';
import Header from '../../components/Header/Header';
import { useLocation } from 'react-router';
import ComplexCard from '../../components/ComplexCard/ComplexCard';
import DataRow from '../../components/DataRow/DataRow';
import { isListNotEmpty, isNumberNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';

const { TabPane } = Tabs;

const Claim = props => {
    //#region props

    const id = props.match.params.id;
    const previousBreadcrumbs = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isListNotEmpty(props.history.location.state.previousBreadcrumbs) ? props.history.location.state.previousBreadcrumbs : [];
    const previousLocation = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageLocation) ? props.history.location.state.previousPageLocation : { pathname: `/claims` };
    const previousPath = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageLocation) ? props.history.location.state.previousPageLocation : `/claims`;
    const previousBreadcrumbName = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageTitle) ? props.history.location.state.previousPageTitle : 'Claims';

    //#endregion
    //#region useLocation

    const location = useLocation();

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.claims.isRecordLoading);
    const error = useSelector(state => state.claims.recordError);
    const claim = useSelector(state => state.claims.record);

    //#endregion
    //#region useStates

    const [tabKey, setTabKey] = useState('snapshot');

    //#endregion
    //#region tab methods

    const onTabChange = (key) => {
        props.history.replace({ ...props.location, hash: `#${key}` });
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        if (isStringNotEmpty(location.hash)) {
            setTabKey(location.hash.replace('#', ''));
        } else {
            setTabKey('snapshot');
            props.history.replace({ ...location, hash: `#snapshot` });
        }
    }, [location]);

    useMemo(() => {
        dispatch(actionCreators.fetchClaim(id));
    }, [id]);

    //#endregion
    //#region displays

    const breadcrumbs = [...previousBreadcrumbs,
    {
        location: previousLocation,
        path: previousPath,
        breadcrumbName: previousBreadcrumbName,
    },
    {
        path: `/accounts/${isStringNotEmpty(id) ? id : ''}`,
        breadcrumbName: `${isObjectNotEmpty(claim) && isStringNotEmpty(claim.irisId) ? claim.irisId : ''}`,
    },
    ];

    //#endregion

    if (isObjectNotEmpty(claim) && claim.id === id) {
        return (
            <>
                <Header
                    title={isStringNotEmpty(claim.irisId) ? claim.irisId : 'Loading...'}
                    icon={<FontAwesomeIcon className="anticon" icon={faTruckMoving} />}
                    breadcrumbs={breadcrumbs}
                />
                <Tabs activeKey={tabKey} onChange={onTabChange} destroyInactiveTabPane={true} tabBarStyle={{ backgroundColor: '#ffffff', paddingRight: 24, paddingLeft: 24, margin: 0 }}>
                    <TabPane tab="Snapshot" key="snapshot">
                        <div style={{ padding: 24 }}>
                            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && !error}>
                                {(isObjectNotEmpty(claim) && isLoading === false) ? (
                                    <Row gutter={[16, 16]}>
                                        <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}>
                                            <ComplexCard
                                                title="Claim Details"
                                                headStyle={{ fontSize: 14 }}
                                            >
                                                <Row>
                                                    <Col xs={24} md={24} lg={12} xl={12}>
                                                        {isStringNotEmpty(claim.irisId) ? (<DataRow title="Claim ID" value={claim.irisId} dataType="String" />) : null}
                                                        <DataRow title="Is Against Carrier" value={claim.isAgainstCarrier} dataType="Boolean" />
                                                        <DataRow title="Is Against Shipper" value={claim.isAgainstShipper} dataType="Boolean" />
                                                        {isStringNotEmpty(claim.claimStatus) ? (<DataRow title="Claim Status" value={claim.claimStatus} dataType="ClaimStatus" />) : null}
                                                        {isStringNotEmpty(claim.comments) ? (<DataRow title="Comments" value={claim.comments} dataType="String" />) : null}
                                                        {isNumberNotEmpty(claim.count) ? (<DataRow title="Count" value={claim.count} dataType="String" />) : null}
                                                    </Col>
                                                    <Col xs={24} md={24} lg={12} xl={12}>
                                                        {isStringNotEmpty(claim.bolStatus) ? (<DataRow title="BOL Status" value={claim.bolStatus} dataType="BOLStatus" />) : null}
                                                        {isStringNotEmpty(claim.irisLoadId) ? (<DataRow title="Load ID" value={claim.irisLoadId} dataType="String" />) : null}
                                                        {isNumberNotEmpty(claim.adjustment) ? (<DataRow title="Adjustment Amount" value={claim.adjustment} dataType="Money" />) : null}
                                                        {isStringNotEmpty(claim.bolNumber) ? (<DataRow title="BOL #" value={claim.bolNumber} dataType="String" />) : null}
                                                    </Col>
                                                </Row>
                                            </ComplexCard>
                                        </Col>
                                    </Row>
                                ) : null}
                            </Spin>
                        </div>
                    </TabPane>
                </Tabs>
            </>
        );
    } else {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={true} />
        );
    }
};

export default withRouter(Claim);