import React, { useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { Row, Col, Spin, Select, Empty, Checkbox } from 'antd';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import Header from '../Header/Header';
import { isBooleanTrue, isListNotEmpty, isNotNullOrUndefined, isObjectNotEmpty, isStringEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { CanDo } from '../../shared/entitlements/entitlements';
import { selectListError, selectListIsLoading, selectListRecords } from '../../store/utility';

const { Option } = Select;

const Agenda = ({ accountId, accountUserId, shiftId }) => {
    //#region useStates

    const [selectedShiftIds, setSelectedShiftIds] = useState(null);
    const [selectedAccountUserIds, setSelectedAccountUserIds] = useState(null);
    const [selectedAccountId, setSelectedAccountId] = useState(null);
    const [driversOnly, setDriversOnly] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState(null);

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);
    const isLoading = useSelector(state => selectListIsLoading(state.scheduledShifts.lists, selectedAccountId));
    const error = useSelector(state => selectListError(state.scheduledShifts.lists, selectedAccountId));
    const records = useSelector(state => selectListRecords(state.scheduledShifts.lists, selectedAccountId));
    const shifts = useSelector(state => selectListRecords(state.shifts.lists, selectedAccountId));
    const isLoadingShifts = useSelector(state => selectListIsLoading(state.shifts.lists, selectedAccountId));
    const accountUsers = useSelector(state => selectListRecords(state.accountUsers.lists, selectedAccountId));
    const isLoadingAccountUsers = useSelector(state => selectListIsLoading(state.accountUsers.lists, selectedAccountId));
    const accounts = useSelector(state => state.orchestrator.accounts);

    //#endregion
    //#region useRefs

    const calendarRef = useRef(null);

    //#endregion
    //#region fetch methods

    const getScheduledShifts = () => {
        if (isStringNotEmpty(selectedAccountId)) {
            let searchParams = {
                page: 1,
                size: 10000,
                sort: 'startDateTime',
                order: 'asc',
                isDeleted: false,
                accountId: selectedAccountId
            };

            if (isListNotEmpty(selectedAccountUserIds)) {
                searchParams.accountUserIds = selectedAccountUserIds;
            } else if (isListNotEmpty(accountUsers) && driversOnly === true) {
                searchParams.accountUserIds = accountUsers.filter(i => i.isDriver === true).map(i => i.id);
            }

            if (isListNotEmpty(selectedShiftIds)) {
                searchParams.shiftId = selectedShiftIds;
            }

            dispatch(actionCreators.fetchScheduledShiftList(selectedAccountId, searchParams));
        }
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        dispatch(actionCreators.getAccounts());
    }, []);

    useMemo(() => {
        if (isStringNotEmpty(selectedAccountId)) {
            dispatch(actionCreators.fetchAccountUserList(selectedAccountId, { page: 1, size: 1000000, accountId: selectedAccountId, isDeleted: false }));
            dispatch(actionCreators.fetchShiftList(selectedAccountId, { page: 1, size: 1000000, accountId: selectedAccountId, isDeleted: false }));
        }
    }, [selectedAccountId]);

    useMemo(() => {
        if (isListNotEmpty(accounts)) {
            if (isStringNotEmpty(accountId)) {
                setSelectedAccountId(accountId);
                let account = accounts.find(i => i.id === accountId);
                setSelectedAccount(account);
            } else if (entityType !== 'STAFF' && isStringNotEmpty(entityId)) {
                setSelectedAccountId(entityId);
                let account = accounts.find(i => i.id === entityId);
                setSelectedAccount(account);
            }
        } else {
            setSelectedAccount(null);
        }
    }, [accountId, entityType, entityId, accounts]);

    useMemo(() => {
        if (isStringNotEmpty(accountUserId)) {
            setSelectedAccountUserIds([accountUserId]);
        } else {
            setSelectedAccountUserIds([]);
        }
    }, [accountUserId]);

    useMemo(() => {
        if (isStringNotEmpty(shiftId)) {
            setSelectedShiftIds([shiftId]);
        } else {
            setSelectedShiftIds([]);
        }
    }, [shiftId]);

    useMemo(() => {
        // want to use isNotNullOrUndefined and not isListNotEmpty because we want to allow empty lists
        if (isStringNotEmpty(selectedAccountId) && isNotNullOrUndefined(selectedAccountUserIds) && isNotNullOrUndefined(selectedShiftIds)) {
            getScheduledShifts();
        }
    }, [selectedAccountId, selectedAccountUserIds, selectedShiftIds, driversOnly, accountUsers]);

    //#endregion

    if (isNotNullOrUndefined(records)) {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true && error === null) || isLoadingAccountUsers === true || isLoadingShifts === true}>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Header
                            title="Agenda"
                            footer={
                                <div><span style={{ fontSize: 16, fontWeight: 500 }}>The Agenda view is read-only. You cannot add shifts, edit shifts, or see details of shifts from this view.</span></div>
                            }
                        />
                    </Col>
                    <Col span={24}>
                        {(isStringEmpty(accountUserId) || isStringEmpty(shiftId) || (isStringEmpty(accountId) && CanDo({ staffOnly: true }))) ? (
                            <>
                                <Row>
                                    <Col span={24}>
                                        <span><strong>Filters: </strong></span>
                                    </Col>
                                </Row>
                                <Row>
                                    {(isStringEmpty(accountId) && CanDo({ staffOnly: true })) ? (
                                        <Col span={8}>
                                            <Select
                                                placeholder="Filter by Account"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onChange={(selected) => { setSelectedAccountId(selected); }}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Accounts for you to choose from." />}
                                            >
                                                {accounts.map(i => <Option value={i.id} key={i.id}>{i.name}</Option>)}
                                            </Select>
                                        </Col>
                                    ) : null}
                                    {isStringEmpty(shiftId) ? (
                                        <Col span={8}>
                                            <Select
                                                placeholder="Filter by Shift"
                                                mode="multiple"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onChange={(selected) => { setSelectedShiftIds(selected); }}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Shifts for you to choose from." />}
                                            >
                                                {shifts.map(i => <Option value={i.id} key={i.id}>{i.name}: {i.startTime} - {i.endTime}</Option>)}
                                            </Select>
                                        </Col>
                                    ) : null}
                                    {isStringEmpty(accountUserId) ? (
                                        <Col span={8}>
                                            <Select
                                                placeholder="Filter by Employee"
                                                mode="multiple"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onChange={(selected) => { setSelectedAccountUserIds(selected); }}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Employees for you to choose from." />}
                                            >
                                                {driversOnly === true ? accountUsers.filter(i => i.isDriver === true).map(i => <Option value={i.id} key={i.id}>{i.firstName} {i.lastName}{i.title ? ' (' + i.title + ')' : ''}</Option>) : accountUsers.map(i => <Option value={i.id} key={i.id}>{i.firstName} {i.lastName}{i.title ? ' (' + i.title + ')' : ''}</Option>)}
                                            </Select>
                                            {(CanDo({ entityTypes: ['CARRIER'] }) || (isObjectNotEmpty(selectedAccount) && isBooleanTrue(selectedAccount.entityTypes.includes("CARRIER")))) ? (
                                                <div>
                                                    <Checkbox
                                                        onChange={e => { setDriversOnly(e.target.checked); return e.target.checked; }}
                                                        checked={driversOnly}
                                                        name={'driversOnly'}
                                                    >Drivers Only</Checkbox>
                                                </div>
                                            ) : null}
                                        </Col>
                                    ) : null}
                                </Row>
                            </>
                        ) : null}
                    </Col>
                    <Col span={24}>
                        <FullCalendar
                            ref={calendarRef}
                            plugins={[interactionPlugin, listPlugin]}
                            headerToolbar={{
                                left: 'today prev,next',
                                center: 'title',
                                right: 'listMonth,listWeek'
                            }}
                            views={{
                                listWeek: { buttonText: 'weekly', slotDuration: { days: 1 }, selectable: false },
                                listMonth: { buttonText: 'monthly', slotDuration: { days: 1 }, selectable: false },
                            }}
                            titleFormat={{ year: 'numeric', month: 'long', day: 'numeric' }}
                            eventTimeFormat={{ hour: '2-digit', minute: '2-digit', hour12: false }}
                            initialView='listMonth'
                            editable={false}
                            dayMaxEvents={false}
                            dayMaxEventRows={false}
                            nextDayThreshold={'06:00:00'}
                            weekends={true}
                            events={records}
                            contentHeight={"auto"}
                        />
                    </Col>
                </Row>
            </Spin>
        );
    } else {
        return null;
    }
};

export default Agenda;