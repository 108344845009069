import React, { useState, useMemo } from 'react';
import { Alert, Button, Divider, Drawer, Empty, Select, Spin } from "antd";
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Form from '../Form/Form';
import Fieldset from '../FormFieldset/FormFieldset';
import InvoiceUtils from '../../api/utils/invoiceUtils';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import moment from 'moment';
import * as Data from '../../api/data';
import { isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { selectListRecords } from '../../store/utility';
import FormItem from '../FormItem/FormItem';
import NewEmployee from '../NewEmployee/NewEmployee';
import Header from '../Header/Header';
import classes from './EditLoadStatusPendingRateConfirmation.module.scss';
import { FileDoneOutlined } from '@ant-design/icons';

const { Option } = Select;

const EditLoadStatusPendingRateConfirmation = ({ load, loadId, shipperId, carrierId, serviceType, stops, invoiceLineItems, cancel }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    //#endregion
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const methods2 = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loadEvents.isLoadEventUpdateLoading);
    const error = useSelector(state => state.loadEvents.updateLoadEventError);
    const userId = useSelector(state => state.auth.userId);
    const employees = useSelector(state => selectListRecords(state.orchestrator.employees, carrierId));
    const accounts = useSelector(state => state.orchestrator.accounts);
    const linkedShipper = useSelector(state => state.linkedAccounts.record);

    //#endregion
    //#region useStates

    const [invoice, setInvoice] = useState({});
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [shipperAccount, setShipperAccount] = useState(null);
    const [carrierAccount, setCarrierAccount] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);

    //#endregion
    //#region toggles

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    //#endregion
    //#region onSubmit and onCancel

    const onSubmit = async (data) => {
        if (isObjectNotEmpty(invoice) && isListNotEmpty(employees) && isListNotEmpty(data.accountUserIds)) {
            setIsProcessing(true);
            // first, create the rate confirmation in the database
            let newInvoice = await Data.addInvoice(invoice);
            if (isObjectNotEmpty(newInvoice)) {
                if (isStringNotEmpty(newInvoice.fromEntityId)) {
                    dispatch(actionCreators.addInvoiceToInvoiceList(newInvoice.fromEntityId, newInvoice));
                }
                if (isStringNotEmpty(newInvoice.toEntityId)) {
                    dispatch(actionCreators.addInvoiceToInvoiceList(newInvoice.toEntityId, newInvoice));
                }
                if (isStringNotEmpty(newInvoice.loadId)) {
                    dispatch(actionCreators.addInvoiceToInvoiceList(newInvoice.loadId, newInvoice));
                }
                // second, save the pdf document to s3
                let newDocument = await InvoiceUtils.saveInvoiceAsDocument('RATE_CONFIRMATION', newInvoice, newInvoice.invoiceLineItems, load, stops, true);

                // third, send rate confirmation emails to recipients
                let emailsWereSent = true;
                let toEmails = [];
                for (let i = 0; i < data.accountUserIds.length; i++) {
                    const accountUserId = data.accountUserIds[i];
                    const accountUser = employees.find(a => a.id === accountUserId);
                    if (isObjectNotEmpty(accountUser)) {
                        toEmails.push({ email: accountUser.email, name: accountUser.firstName + ' ' + accountUser.lastName });
                    }
                }

                // fourth, send rate confirmation emails to recipients
                emailsWereSent = await InvoiceUtils.sendRateConfirmationEmail('info@irisfreight.com', toEmails, newInvoice, load, stops, newDocument);

                // fourth, send load event
                if (emailsWereSent === true) {
                    dispatch(actionCreators.sendLoadEvent({
                        loadId: load.id,
                        eventType: 'LOAD_REQUEST_TO_CARRIER'
                    }));
                }
            }

            setIsProcessing(false);
        }
    };

    const onSubmitPendingRateConfirmation = async () => {
        if (isObjectNotEmpty(invoice)) {
            setIsProcessing(true);
            // first, create the rate confirmation in the database
            let newInvoice = await Data.addInvoice(invoice);
            if (isObjectNotEmpty(newInvoice)) {
                if (isStringNotEmpty(newInvoice.fromEntityId)) {
                    dispatch(actionCreators.addInvoiceToInvoiceList(newInvoice.fromEntityId, newInvoice));
                }
                if (isStringNotEmpty(newInvoice.toEntityId)) {
                    dispatch(actionCreators.addInvoiceToInvoiceList(newInvoice.toEntityId, newInvoice));
                }
                if (isStringNotEmpty(newInvoice.loadId)) {
                    dispatch(actionCreators.addInvoiceToInvoiceList(newInvoice.loadId, newInvoice));
                }
                dispatch(actionCreators.sendLoadEvent({
                    loadId: loadId,
                    eventType: 'LOAD_REQUEST_TO_CARRIER'
                }));

                setIsProcessing(false);
            }
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.updateLoadEventComplete());
        cancel();
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLoadEventErrorClear());
        dispatch(actionCreators.getAccounts());
    }, []);

    useMemo(() => {
        if (isStringNotEmpty(carrierId)) {
            dispatch(actionCreators.getEmployees(carrierId, false));
        }
    }, [carrierId]);

    useMemo(() => {
        if (isStringNotEmpty(shipperId) && isStringNotEmpty(carrierId) && isListNotEmpty(accounts)) {
            let shipperAccountObj = accounts.find(i => i.id === load.shipperId);
            if (isObjectNotEmpty(shipperAccountObj)) {
                // console.log(shipperAccount);
                setShipperAccount({ ...shipperAccountObj });
            }

            let carrierAccountObj = accounts.find(i => i.id === carrierId);
            if (isObjectNotEmpty(carrierAccountObj)) {
                // console.log(carrierAccountObj);
                setCarrierAccount({ ...carrierAccountObj });
            }
        }
    }, [accounts, shipperId, carrierId]);

    useMemo(() => {
        if (isStringNotEmpty(serviceType) && serviceType === "TMS" && isObjectNotEmpty(carrierAccount) && isStringNotEmpty(carrierAccount.id) && isObjectNotEmpty(shipperAccount) && isStringNotEmpty(shipperAccount.id)) {
            dispatch(actionCreators.fetchLinkedAccountByAccountIdAndLinkedAccountId(carrierAccount.id, shipperAccount.id));
        }
    }, [serviceType, carrierAccount, shipperAccount]);

    useMemo(() => {
        if (isStringNotEmpty(loadId) && isStringNotEmpty(carrierId) && isStringNotEmpty(userId) && isStringNotEmpty(serviceType)) {
            let fromName = null;
            let fromAddress = {};
            let fromPointOfContact = {};
            if (serviceType === "TMS") {
                if (isObjectNotEmpty(linkedShipper)) {
                    fromName = isStringNotEmpty(linkedShipper.name) ? linkedShipper.name : null;
                    fromAddress = isObjectNotEmpty(linkedShipper.accountsPayableAddress) ? linkedShipper.accountsPayableAddress : {};
                    fromPointOfContact = isObjectNotEmpty(linkedShipper.accountsPayable) ? linkedShipper.accountsPayable : {};
                } else if (isObjectNotEmpty(shipperAccount)) {
                    fromName = isStringNotEmpty(shipperAccount.name) ? shipperAccount.name : null;
                    fromAddress = isObjectNotEmpty(shipperAccount.accountsPayableAddress) ? shipperAccount.accountsPayableAddress : {};
                    fromPointOfContact = isObjectNotEmpty(shipperAccount.accountsPayable) ? shipperAccount.accountsPayable : {};
                }
            } else {
                fromName = InvoiceUtils.irisCompanyName;
                fromAddress = InvoiceUtils.irisPaymentAddress;
                fromPointOfContact = InvoiceUtils.irisPointOfContact;
            }

            const { totalAmount, lineItems, invoiceLineItemList } = InvoiceUtils.getInvoiceLineItemsAndTotal("RATE_CONFIRMATION", invoiceLineItems, serviceType === "TMS" ? "SHIPPER" : "STAFF", "CARRIER");

            let rateConfirmation = {
                loadId: loadId,
                invoiceDate: moment(),
                fromEntityType: serviceType === "TMS" ? "SHIPPER" : "STAFF",
                fromEntityId: serviceType === "TMS" ? shipperId : null,
                fromName: fromName,
                fromAddress: fromAddress,
                fromPointOfContact: fromPointOfContact,
                toEntityType: 'CARRIER',
                toEntityId: carrierId,
                status: 'SENT',
                amountDue: totalAmount,
                amountDueUnit: 'USD',
                invoiceLineItemIds: invoiceLineItemList.map(i => i.id),
                sentAt: moment(),
                sentBy: userId,
                invoiceType: 'RATE_CONFIRMATION'
            };

            setInvoice(rateConfirmation);
        }
    }, [loadId, shipperId, carrierId, serviceType, invoiceLineItems, userId, shipperAccount, linkedShipper]);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    //#endregion

    return (
        <>
            <div style={{ minHeight: 200, padding: 24, backgroundColor: '#fafafa', border: '1px dashed #e9e9e9', borderRadius: '2px' }}>
                <Header
                    title="Send Rate Confirmation"
                    titleStyle={{ paddingLeft: 0, color: '#000000', fontWeight: 500 }}
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    icon={<FileDoneOutlined />}
                    iconStyle={{ background: 'transparent' }}
                    title2={`Would you like the send the rate confirmation to the carrier you have assigned to this load?`}
                    title2Style={{ whiteSpace: 'normal' }}
                />
                <PDFDownloadLink document={<InvoiceUtils.RateConfirmationTemplate invoice={invoice} load={load} stops={stops} invoiceLineItems={invoiceLineItems} displayManualInstructions={true} />} fileName={`Rate Confirmation for Load ${load.irisId}.pdf`}>
                    {({ blob, url, loading, error }) => (loading ? (<div style={{ textAlign: 'center' }}>Loading Rate Confirmation...</div>) : (
                        <>
                            <PDFViewer style={{ width: '100%', height: '80vh' }} src={url}></PDFViewer>
                            <div style={{ textAlign: 'center', marginTop: 12 }}>
                                <Button style={{ height: 30, width: 300 }} type="default">Download Copy of Rate Confirmation</Button>
                            </div>
                        </>
                    ))}
                </PDFDownloadLink>
            </div>
            <div style={{ minHeight: 200, padding: 24, marginTop: 24, marginBottom: 24, backgroundColor: '#fafafa', border: '1px dashed #e9e9e9', borderRadius: '2px' }}>
                <FormProvider {...methods}>
                    <Form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true && error === null) || isProcessing === true}>
                            <Fieldset legend="Choose Employee(s) to send Rate Confirmation to">
                                <FormItem {...formItemLayout} label="Choose Employee(s)" required
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Select
                                            placeholder="Please Search and Select Employee(s)"
                                            mode="multiple"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChange(selected); }}
                                            value={value}
                                            name={name}
                                            showSearch={true}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Employees for you to choose from." />}
                                            ref={ref}
                                        >
                                            {employees.map(d => <Option value={d.id} key={d.id}>{d.firstName + ' ' + d.lastName} - Title: {d.title} - Email: {d.email}</Option>)}
                                        </Select>
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="accountUserIds"
                                />
                                <br />
                                <b>Employee Doesn't Exist Yet?</b>
                                <br />
                                <Button type='primary' onClick={toggleNewEntity}>Add New Employee to Carrier Account</Button>
                            </Fieldset>
                            {error && <Alert message={`${error}`} type="error" />}
                        </Spin>
                        <div className={classes.relativeButtonContainer}>
                            <Button type="danger" onClick={onCancel} disabled={isProcessing === true || (isLoading === true && error === null)} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary" htmlType="submit" disabled={isProcessing === true || (isLoading === true && error === null)}>Send the Rate Confirmation</Button>
                        </div>
                    </Form>
                </FormProvider>
            </div>
            <Divider>OR</Divider>
            <div style={{ minHeight: 200, padding: 24, marginTop: 24, backgroundColor: '#fafafa', border: '1px dashed #e9e9e9', borderRadius: '2px' }}>
                <FormProvider {...methods2}>
                    <Form onSubmit={methods2.handleSubmit(onSubmitPendingRateConfirmation)}>
                        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isProcessing === true || (isLoading === true && error === null)}>
                            <Fieldset legend="Manually Sent Rate Confirmation">
                                <b>Did you manually send a Rate Confirmation to the Carrier?</b>
                            </Fieldset>
                            {error && <Alert message={`${error}`} type="error" />}
                        </Spin>
                        <div className={classes.relativeButtonContainer}>
                            <Button type="danger" onClick={onCancel} disabled={isProcessing === true || (isLoading === true && error === null)} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary" htmlType="submit" disabled={isProcessing === true || (isLoading === true && error === null)}>Manually Sent the Rate Confirmation</Button>
                        </div>
                    </Form>
                </FormProvider>
            </div>
            <Drawer
                title={'Add New Employee'}
                width={fullWidth > 720 ? fullWidth / 2 : 360}
                onClose={toggleNewEntity}
                visible={showNewEntity}
                bodyStyle={{ paddingBottom: 80 }}
                style={{ zIndex: 1000 }}
                destroyOnClose={true}
                closable={false}
                maskClosable={false}
            >
                <NewEmployee cancel={toggleNewEntity} accountId={carrierId} />
            </Drawer>
        </>
    );
};

export default EditLoadStatusPendingRateConfirmation;