import * as actionTypes from "../actions/actionTypes";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { isBooleanTrue, isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import * as actionCreators from "./index";
import ErrorUtils from "../../shared/errorUtils";
import { selectListRecords } from "../utility";

//#region Fetch Invoice Line Item List Methods

export const fetchInvoiceLineItemListStart = (listName) => {
    return {
        type: actionTypes.FETCH_INVOICE_LINE_ITEM_LIST_START,
        payload: { listName: listName }
    }
};

export const fetchInvoiceLineItemListSuccess = (listName, payload) => {
    return {
        type: actionTypes.FETCH_INVOICE_LINE_ITEM_LIST_SUCCESS,
        payload: { listName: listName, ...payload }
    }
};

export const fetchInvoiceLineItemListFail = (listName, payload) => {
    return {
        type: actionTypes.FETCH_INVOICE_LINE_ITEM_LIST_FAIL,
        payload: { listName: listName, ...payload }
    }
};

export const clearInvoiceLineItemList = (listName) => {
    return {
        type: actionTypes.CLEAR_INVOICE_LINE_ITEM_LIST,
        payload: { listName: listName }
    }
};

export const clearInvoiceLineItemLists = () => {
    return {
        type: actionTypes.CLEAR_INVOICE_LINE_ITEM_LISTS
    }
};

export const addInvoiceLineItemToInvoiceLineItemList = (listName, payload) => {
    return {
        type: actionTypes.ADD_TO_INVOICE_LINE_ITEM_LIST,
        payload: { listName: listName, newRecord: payload }
    }
};

export const updateInvoiceLineItemInInvoiceLineItemList = (listName, payload) => {
    return {
        type: actionTypes.UPDATE_IN_INVOICE_LINE_ITEM_LIST,
        payload: { listName: listName, updatedRecord: payload }
    }
};

export const removeInvoiceLineItemFromInvoiceLineItemList = (listName, payload) => {
    return {
        type: actionTypes.REMOVE_FROM_INVOICE_LINE_ITEM_LIST,
        payload: { listName: listName, recordToRemove: payload }
    }
};

//#endregion
//#region Add Invoice Line Item Methods

export const addInvoiceLineItemStart = () => {
    return {
        type: actionTypes.ADD_INVOICE_LINE_ITEM_START
    }
};

export const addInvoiceLineItemSuccess = () => {
    return {
        type: actionTypes.ADD_INVOICE_LINE_ITEM_SUCCESS
    }
};

export const addInvoiceLineItemFail = (payload) => {
    return {
        type: actionTypes.ADD_INVOICE_LINE_ITEM_FAIL,
        payload: payload
    }
};

export const addInvoiceLineItemLoadingClear = () => {
    return {
        type: actionTypes.ADD_INVOICE_LINE_ITEM_LOADING_CLEAR
    }
};

export const addInvoiceLineItemErrorClear = () => {
    return {
        type: actionTypes.ADD_INVOICE_LINE_ITEM_ERROR_CLEAR
    }
};

export const addInvoiceLineItemCancel = () => {
    return {
        type: actionTypes.ADD_INVOICE_LINE_ITEM_CANCEL
    }
};

//#endregion
//#region Update Invoice Line Item Methods

export const updateInvoiceLineItemStart = () => {
    return {
        type: actionTypes.UPDATE_INVOICE_LINE_ITEM_START
    }
};

export const updateInvoiceLineItemSuccess = () => {
    return {
        type: actionTypes.UPDATE_INVOICE_LINE_ITEM_SUCCESS
    }
};

export const updateInvoiceLineItemFail = (payload) => {
    return {
        type: actionTypes.UPDATE_INVOICE_LINE_ITEM_FAIL,
        payload: payload
    }
};

export const updateInvoiceLineItemLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_INVOICE_LINE_ITEM_LOADING_CLEAR
    }
};

export const updateInvoiceLineItemErrorClear = () => {
    return {
        type: actionTypes.UPDATE_INVOICE_LINE_ITEM_ERROR_CLEAR
    }
};

export const updateInvoiceLineItemCancel = () => {
    return {
        type: actionTypes.UPDATE_INVOICE_LINE_ITEM_CANCEL
    }
};

//#endregion
//#region Remove Invoice Line Item Methods

export const removeInvoiceLineItemStart = () => {
    return {
        type: actionTypes.REMOVE_INVOICE_LINE_ITEM_START
    }
};

export const removeInvoiceLineItemSuccess = () => {
    return {
        type: actionTypes.REMOVE_INVOICE_LINE_ITEM_SUCCESS
    }
};

export const removeInvoiceLineItemFail = (payload) => {
    return {
        type: actionTypes.REMOVE_INVOICE_LINE_ITEM_FAIL,
        payload: payload
    }
};

export const removeInvoiceLineItemLoadingClear = () => {
    return {
        type: actionTypes.REMOVE_INVOICE_LINE_ITEM_LOADING_CLEAR
    }
};

export const removeInvoiceLineItemErrorClear = () => {
    return {
        type: actionTypes.REMOVE_INVOICE_LINE_ITEM_ERROR_CLEAR
    }
};

export const removeInvoiceLineItemCancel = () => {
    return {
        type: actionTypes.REMOVE_INVOICE_LINE_ITEM_CANCEL
    }
};

//#endregion
//#region Invoice Line Item Methods

export const fetchInvoiceLineItemList = (listName, payload) => {
    return async (dispatch, getState) => {
        if (isStringNotEmpty(listName)) {
            try {
                dispatch(fetchInvoiceLineItemListStart(listName));

                const state = getState();
                const invoiceLineItemsState = { ...state.invoiceLineItems };
                const existingLists = { ...invoiceLineItemsState.lists };

                let pagination = {};
                let searchParams = {};
                if (isObjectNotEmpty(existingLists[listName])) {
                    pagination = { ...existingLists[listName].pagination };
                    searchParams = { ...existingLists[listName].searchParams };
                }

                if (isObjectNotEmpty(payload)) {
                    searchParams = { ...payload };
                }

                const res = await Data.getInvoiceLineItems({ ...searchParams }, pagination);
                dispatch(fetchInvoiceLineItemListSuccess(listName, { records: res.data, params: { searchParams: res.searchParams, pagination: res.pagination } }));
            } catch (error) {
                logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
                dispatch(fetchInvoiceLineItemListFail(listName, { error: ErrorUtils.getErrorMessage(error) }));
            }
        }
    }
};

export const addInvoiceLineItem = (payload, loadId = null, sendLoadEvent = false) => {
    return async (dispatch, getState) => {
        try {
            dispatch(addInvoiceLineItemStart());

            const state = getState();
            const invoiceLineItemsState = { ...state.invoiceLineItems };
            const transactionsState = { ...state.transactions };
            const claimsState = { ...state.claims };
            let existingInvoiceLineItems = selectListRecords({ ...invoiceLineItemsState.lists }, loadId);
            let existingTransactions = selectListRecords({ ...transactionsState.lists }, loadId);
            let existingClaims = selectListRecords({ ...claimsState.lists }, loadId);

            const newInvoiceLineItem = await Data.addInvoiceLineItem(payload, existingInvoiceLineItems, existingTransactions, existingClaims, loadId, sendLoadEvent);
            if (isObjectNotEmpty(newInvoiceLineItem)) {
                if (isStringNotEmpty(loadId) && sendLoadEvent === true) {
                    dispatch(actionCreators.loadEventUpdateTimeout(loadId, null, null, null, 'LOAD_UPDATED'));
                }

                if (isStringNotEmpty(newInvoiceLineItem.fromEntityId)) {
                    dispatch(addInvoiceLineItemToInvoiceLineItemList(newInvoiceLineItem.fromEntityId, newInvoiceLineItem));
                }
                if (isStringNotEmpty(newInvoiceLineItem.toEntityId)) {
                    dispatch(addInvoiceLineItemToInvoiceLineItemList(newInvoiceLineItem.toEntityId, newInvoiceLineItem));
                }
                if (isStringNotEmpty(newInvoiceLineItem.loadId)) {
                    dispatch(addInvoiceLineItemToInvoiceLineItemList(newInvoiceLineItem.loadId, newInvoiceLineItem));
                }
            }

            dispatch(addInvoiceLineItemSuccess());
            dispatch(addInvoiceLineItemLoadingClear());
            dispatch(addInvoiceLineItemErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addInvoiceLineItemFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const addInvoiceLineItems = (payload, loadId = null, sendLoadEvent = false) => {
    return async (dispatch, getState) => {
        try {
            dispatch(addInvoiceLineItemStart());

            const state = getState();
            const invoiceLineItemsState = { ...state.invoiceLineItems };
            const transactionsState = { ...state.transactions };
            const claimsState = { ...state.claims };
            let existingInvoiceLineItems = selectListRecords({ ...invoiceLineItemsState.lists }, loadId);
            let existingTransactions = selectListRecords({ ...transactionsState.lists }, loadId);
            let existingClaims = selectListRecords({ ...claimsState.lists }, loadId);

            const newInvoiceLineItems = await Data.addInvoiceLineItems(payload, existingInvoiceLineItems, existingTransactions, existingClaims, loadId, sendLoadEvent);
            if (isListNotEmpty(newInvoiceLineItems)) {
                if (isStringNotEmpty(loadId) && sendLoadEvent === true) {
                    dispatch(actionCreators.loadEventUpdateTimeout(loadId, null, null, null, 'LOAD_UPDATED'));
                }

                newInvoiceLineItems.forEach((newInvoiceLineItem) => {
                    if (isStringNotEmpty(newInvoiceLineItem.fromEntityId)) {
                        dispatch(addInvoiceLineItemToInvoiceLineItemList(newInvoiceLineItem.fromEntityId, newInvoiceLineItem));
                    }
                    if (isStringNotEmpty(newInvoiceLineItem.toEntityId)) {
                        dispatch(addInvoiceLineItemToInvoiceLineItemList(newInvoiceLineItem.toEntityId, newInvoiceLineItem));
                    }
                    if (isStringNotEmpty(newInvoiceLineItem.loadId)) {
                        dispatch(addInvoiceLineItemToInvoiceLineItemList(newInvoiceLineItem.loadId, newInvoiceLineItem));
                    }
                });
            }

            dispatch(addInvoiceLineItemSuccess());
            dispatch(addInvoiceLineItemLoadingClear());
            dispatch(addInvoiceLineItemErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addInvoiceLineItemFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateInvoiceLineItem = (id, payload, loadId = null, sendLoadEvent = false) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updateInvoiceLineItemStart());

            const state = getState();
            const invoiceLineItemsState = { ...state.invoiceLineItems };
            const transactionsState = { ...state.transactions };
            const claimsState = { ...state.claims };
            let existingInvoiceLineItems = selectListRecords({ ...invoiceLineItemsState.lists }, loadId);
            let existingTransactions = selectListRecords({ ...transactionsState.lists }, loadId);
            let existingClaims = selectListRecords({ ...claimsState.lists }, loadId);

            const updatedInvoiceLineItem = await Data.updateInvoiceLineItem(id, payload, existingInvoiceLineItems, existingTransactions, existingClaims, loadId, sendLoadEvent);
            if (isObjectNotEmpty(updatedInvoiceLineItem)) {
                if (isStringNotEmpty(loadId) && sendLoadEvent === true) {
                    dispatch(actionCreators.loadEventUpdateTimeout(loadId, null, null, null, 'LOAD_UPDATED'));
                }

                if (isStringNotEmpty(updatedInvoiceLineItem.fromEntityId)) {
                    dispatch(updateInvoiceLineItemInInvoiceLineItemList(updatedInvoiceLineItem.fromEntityId, updatedInvoiceLineItem));
                }
                if (isStringNotEmpty(updatedInvoiceLineItem.toEntityId)) {
                    dispatch(updateInvoiceLineItemInInvoiceLineItemList(updatedInvoiceLineItem.toEntityId, updatedInvoiceLineItem));
                }
                if (isStringNotEmpty(updatedInvoiceLineItem.loadId)) {
                    dispatch(updateInvoiceLineItemInInvoiceLineItemList(updatedInvoiceLineItem.loadId, updatedInvoiceLineItem));
                }
            }

            dispatch(updateInvoiceLineItemSuccess());
            dispatch(updateInvoiceLineItemLoadingClear());
            dispatch(updateInvoiceLineItemErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateInvoiceLineItemFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateInvoiceLineItemsStatus = (ids, status, loadId = null) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updateInvoiceLineItemStart());

            const state = getState();
            const invoiceLineItemsState = { ...state.invoiceLineItems };
            let existingInvoiceLineItems = selectListRecords({ ...invoiceLineItemsState.lists }, loadId);

            const res = await Data.updateInvoiceLineItemsStatus(ids, status, existingInvoiceLineItems);
            if (isBooleanTrue(res.updateStatus)) {
                // if (isStringNotEmpty(loadId)) {
                //     dispatch(actionCreators.loadEventUpdateTimeout(loadId, null, null, null, 'LOAD_UPDATED'));
                // }

                if (isListNotEmpty(res.updatedInvoiceLineItems)) {
                    res.updatedInvoiceLineItems.forEach((updatedInvoiceLineItem) => {
                        if (isStringNotEmpty(updatedInvoiceLineItem.fromEntityId)) {
                            dispatch(updateInvoiceLineItemInInvoiceLineItemList(updatedInvoiceLineItem.fromEntityId, updatedInvoiceLineItem));
                        }
                        if (isStringNotEmpty(updatedInvoiceLineItem.toEntityId)) {
                            dispatch(updateInvoiceLineItemInInvoiceLineItemList(updatedInvoiceLineItem.toEntityId, updatedInvoiceLineItem));
                        }
                        if (isStringNotEmpty(updatedInvoiceLineItem.loadId)) {
                            dispatch(updateInvoiceLineItemInInvoiceLineItemList(updatedInvoiceLineItem.loadId, updatedInvoiceLineItem));
                        }
                    });
                }

                dispatch(updateInvoiceLineItemSuccess());
                dispatch(updateInvoiceLineItemLoadingClear());
                dispatch(updateInvoiceLineItemErrorClear());
            } else {
                dispatch(updateInvoiceLineItemFail({ error: 'Failed to update the status of the invoice line items.' }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateInvoiceLineItemFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const removeInvoiceLineItem = (id, loadId = null, sendLoadEvent = false) => {
    return async (dispatch, getState) => {
        try {
            dispatch(removeInvoiceLineItemStart());

            const state = getState();
            const invoiceLineItemsState = { ...state.invoiceLineItems };
            const transactionsState = { ...state.transactions };
            const claimsState = { ...state.claims };
            let existingInvoiceLineItems = selectListRecords({ ...invoiceLineItemsState.lists }, loadId);
            let existingTransactions = selectListRecords({ ...transactionsState.lists }, loadId);
            let existingClaims = selectListRecords({ ...claimsState.lists }, loadId);

            const removedInvoiceLineItem = await Data.removeInvoiceLineItem(id, existingInvoiceLineItems, existingTransactions, existingClaims, loadId, sendLoadEvent);
            if (isObjectNotEmpty(removedInvoiceLineItem)) {
                if (isStringNotEmpty(loadId) && sendLoadEvent === true) {
                    dispatch(actionCreators.loadEventUpdateTimeout(loadId, null, null, null, 'LOAD_UPDATED'));
                }

                if (isStringNotEmpty(removedInvoiceLineItem.fromEntityId)) {
                    dispatch(removeInvoiceLineItemFromInvoiceLineItemList(removedInvoiceLineItem.fromEntityId, removedInvoiceLineItem));
                }
                if (isStringNotEmpty(removedInvoiceLineItem.toEntityId)) {
                    dispatch(removeInvoiceLineItemFromInvoiceLineItemList(removedInvoiceLineItem.toEntityId, removedInvoiceLineItem));
                }
                if (isStringNotEmpty(removedInvoiceLineItem.loadId)) {
                    dispatch(removeInvoiceLineItemFromInvoiceLineItemList(removedInvoiceLineItem.loadId, removedInvoiceLineItem));
                }
            }

            dispatch(removeInvoiceLineItemSuccess());
            dispatch(removeInvoiceLineItemLoadingClear());
            dispatch(removeInvoiceLineItemErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(removeInvoiceLineItemFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const removeInvoiceLineItems = (ids, loadId = null, sendLoadEvent = false) => {
    return async (dispatch, getState) => {
        try {
            dispatch(removeInvoiceLineItemStart());

            const state = getState();
            const invoiceLineItemsState = { ...state.invoiceLineItems };
            const transactionsState = { ...state.transactions };
            const claimsState = { ...state.claims };
            let existingInvoiceLineItems = selectListRecords({ ...invoiceLineItemsState.lists }, loadId);
            let existingTransactions = selectListRecords({ ...transactionsState.lists }, loadId);
            let existingClaims = selectListRecords({ ...claimsState.lists }, loadId);

            const removedInvoiceLineItems = await Data.removeInvoiceLineItems(ids, existingInvoiceLineItems, existingTransactions, existingClaims, loadId, sendLoadEvent);
            if (isListNotEmpty(removedInvoiceLineItems)) {
                if (isStringNotEmpty(loadId) && sendLoadEvent === true) {
                    dispatch(actionCreators.loadEventUpdateTimeout(loadId, null, null, null, 'LOAD_UPDATED'));
                }

                removedInvoiceLineItems.forEach((removedInvoiceLineItem) => {
                    if (isStringNotEmpty(removedInvoiceLineItem.fromEntityId)) {
                        dispatch(removeInvoiceLineItemFromInvoiceLineItemList(removedInvoiceLineItem.fromEntityId, removedInvoiceLineItem));
                    }
                    if (isStringNotEmpty(removedInvoiceLineItem.toEntityId)) {
                        dispatch(removeInvoiceLineItemFromInvoiceLineItemList(removedInvoiceLineItem.toEntityId, removedInvoiceLineItem));
                    }
                    if (isStringNotEmpty(removedInvoiceLineItem.loadId)) {
                        dispatch(removeInvoiceLineItemFromInvoiceLineItemList(removedInvoiceLineItem.loadId, removedInvoiceLineItem));
                    }
                });
            }

            dispatch(removeInvoiceLineItemSuccess());
            dispatch(removeInvoiceLineItemLoadingClear());
            dispatch(removeInvoiceLineItemErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(removeInvoiceLineItemFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion