import Enums from "../../shared/enums";
import { isListNotEmpty, isNotNullOrUndefined, isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import MomentDate from '../../shared/dateFormatter';
import StringFormatter from '../../shared/stringFormatter';
import pickUpMarker from '../../assets/img/pickUpMarker.png';
import dropOffMarker from '../../assets/img/dropOffMarker.png';

const momentDate = new MomentDate();
const stringFormatter = new StringFormatter();

const getStopMarkerIcon = (stop) => {
    let stopIcon = null;
    if (isObjectNotEmpty(stop) && isStringNotEmpty(stop.stopType)) {
        if (stop.stopType === 'PICK_UP') {
            stopIcon = pickUpMarker;
        } else if (stop.stopType === 'DROP_OFF') {
            stopIcon = dropOffMarker;
        }
    }

    return stopIcon;
};

const getStopTypeDisplay = (stop) => {
    let stopTypeDisplay = '';
    if (isObjectNotEmpty(stop) && isStringNotEmpty(stop.stopType)) {
        stopTypeDisplay = Enums.StopTypes.getValueByName(stop.stopType);
    }

    return stopTypeDisplay;
};

const getStopType = (stop) => {
    let stopType = null;
    if (isObjectNotEmpty(stop) && isStringNotEmpty(stop.stopType)) {
        stopType = stop.stopType;
    }

    return stopType;
};

const getAppointmentType = (stop) => {
    let appointmentType = null;
    if (isObjectNotEmpty(stop) && isStringNotEmpty(stop.apptType)) {
        appointmentType = stop.apptType;
    }

    return appointmentType;
};

const getStopTimeZone = (stop) => {
    let stopTimeZone = null;
    if (isObjectNotEmpty(stop) && isObjectNotEmpty(stop.stopLocation) && isStringNotEmpty(stop.stopLocation.timeZone)) {
        stopTimeZone = stop.stopLocation.timeZone;
    }

    return stopTimeZone;
};

const getLocationInfoByLink = (location, accountId) => {
    if (isObjectNotEmpty(location) && isListNotEmpty(location.links) && isStringNotEmpty(accountId)) {
        let accountLink = location.links.find(l => l.accountId === accountId);
        if (isObjectNotEmpty(accountLink)) {
            return {
                ...location,
                ...accountLink
            };
        }

        return location;
    }

    return null;
};

const getLocationNameByLink = (location, accountId) => {
    if (isObjectNotEmpty(location) && isListNotEmpty(location.links) && isStringNotEmpty(accountId)) {
        let accountLink = location.links.find(l => l.accountId === accountId);
        if (isObjectNotEmpty(accountLink) && isStringNotEmpty(accountLink.name)) {
            return accountLink.name;
        }
    }

    return null;
};

const getLoadStopLocationName = (stop, shipperId, carrierId, loadCreatedByEntityType) => {
    if (isObjectNotEmpty(stop)) {
        let shipperLinkName = getLocationNameByLink(stop.stopLocation, shipperId);
        let carrierLinkName = getLocationNameByLink(stop.stopLocation, carrierId);
        if (loadCreatedByEntityType === 'SHIPPER') {
            if (isStringNotEmpty(shipperLinkName)) {
                return shipperLinkName;
            } else if (isStringNotEmpty(carrierLinkName)) {
                return carrierLinkName;
            }
        } else if (loadCreatedByEntityType === 'CARRIER') {
            if (isStringNotEmpty(carrierLinkName)) {
                return carrierLinkName;
            } else if (isStringNotEmpty(shipperLinkName)) {
                return shipperLinkName;
            }
        } else {
            if (isStringNotEmpty(shipperLinkName)) {
                return shipperLinkName;
            } else if (isStringNotEmpty(carrierLinkName)) {
                return carrierLinkName;
            }
        }
    }

    return '';
};

const getLoadStopName = (stop, shipperId, carrierId, loadCreatedByEntityType) => {
    if (isObjectNotEmpty(stop)) {
        let locationName = getLoadStopLocationName(stop, shipperId, carrierId, loadCreatedByEntityType);
        if (isStringNotEmpty(locationName)) {
            return locationName;
        } else if (isStringNotEmpty(stop.stopType)) {
            return Enums.StopTypes.getValueByName(stop.stopType);
        }
    }

    return '';
};

const getStopLocationNameDisplay = (stop, shipperId, carrierId, entityType, entityId) => {
    if (isObjectNotEmpty(stop)) {
        let shipperLinkName = getLocationNameByLink(stop.stopLocation, shipperId);
        let carrierLinkName = getLocationNameByLink(stop.stopLocation, carrierId);
        let entityLinkName = getLocationNameByLink(stop.stopLocation, entityId);
        if (entityType === 'SHIPPER') {
            if (isStringNotEmpty(shipperLinkName)) {
                return shipperLinkName;
            } else if (isStringNotEmpty(carrierLinkName)) {
                return carrierLinkName;
            } else if (isStringNotEmpty(entityLinkName)) {
                return entityLinkName;
            }
        } else if (entityType === 'CARRIER') {
            if (isStringNotEmpty(carrierLinkName)) {
                return carrierLinkName;
            } else if (isStringNotEmpty(shipperLinkName)) {
                return shipperLinkName;
            } else if (isStringNotEmpty(entityLinkName)) {
                return entityLinkName;
            }
        } else {
            if (isStringNotEmpty(entityLinkName)) {
                return entityLinkName;
            } else if (isStringNotEmpty(shipperLinkName)) {
                return shipperLinkName;
            } else if (isStringNotEmpty(carrierLinkName)) {
                return carrierLinkName;
            }
        }
    }

    return null;
};

const getStopNameDisplay = (stop, shipperId, carrierId, entityType, entityId) => {
    if (isObjectNotEmpty(stop)) {
        let locationName = getStopLocationNameDisplay(stop, shipperId, carrierId, entityType, entityId);
        if (isStringNotEmpty(locationName)) {
            return locationName;
        } else if (isStringNotEmpty(stop.stopType)) {
            return Enums.StopTypes.getValueByName(stop.stopType);
        }
    }

    return null;
};

const getStopLocationCityStateDisplay = (stop) => {
    if (isObjectNotEmpty(stop)) {
        if (isObjectNotEmpty(stop.stopLocation) && isObjectNotEmpty(stop.stopLocation.address) && isStringNotEmpty(stop.stopLocation.address.city) && isStringNotEmpty(stop.stopLocation.address.state)) {
            return `${stop.stopLocation.address.city}, ${stop.stopLocation.address.state}`;
        }
    }

    return null;
};

const getStopLocationDisplay = (stop, shipperId, carrierId, entityType, entityId) => {
    if (isObjectNotEmpty(stop)) {
        let locationCityStateDisplay = getStopLocationCityStateDisplay(stop);
        if (isStringNotEmpty(locationCityStateDisplay)) {
            return locationCityStateDisplay;
        } else {
            return getStopNameDisplay(stop, shipperId, carrierId, entityType, entityId);
        }
    }

    return null;
};

const getStopAddressDisplay = (stop) => {
    if (isObjectNotEmpty(stop)) {
        if (isObjectNotEmpty(stop.stopLocation) && isObjectNotEmpty(stop.stopLocation.address)) {
            return stringFormatter.toFormattedAddress(stop.stopLocation.address);
        }
    }

    return null;
};

const getAppointmentDateTimeDisplay = (stop) => {
    let appointmentDetailsDisplay = null;
    if (isObjectNotEmpty(stop)) {
        if (stop.apptType === "FIRST_COME_FIRST_SERVE") {
            let startDateObj = isNotNullOrUndefined(stop.apptWindowStartDateTime) && isStringNotEmpty(stop.timeZone) ? momentDate.fromTimeZonelessToTimeZoneAsDateObject(stop.apptWindowStartDateTime, stop.timeZone) : null;
            let endDateObj = isNotNullOrUndefined(stop.apptWindowEndDateTime) && isStringNotEmpty(stop.timeZone) ? momentDate.fromTimeZonelessToTimeZoneAsDateObject(stop.apptWindowEndDateTime, stop.timeZone) : null;

            if (isObjectNotEmpty(startDateObj) && isObjectNotEmpty(endDateObj)) {
                if (startDateObj.dateString === endDateObj.dateString) {
                    appointmentDetailsDisplay = `${startDateObj.dateString}, ${startDateObj.timeString} - ${endDateObj.timeString} ${startDateObj.timeZone}`;
                } else {
                    appointmentDetailsDisplay = `${startDateObj.dateString}, ${startDateObj.timeString} - ${endDateObj.dateString}, ${endDateObj.timeString} ${startDateObj.timeZone}`;
                }
            }
        } else if (stop.apptType === "HAVE_APPOINTMENT") {
            let apptDateObj = isNotNullOrUndefined(stop.apptDateTime) && isStringNotEmpty(stop.timeZone) ? momentDate.fromTimeZonelessToTimeZoneAsDateObject(stop.apptDateTime, stop.timeZone) : null;
            if (isObjectNotEmpty(apptDateObj)) {
                appointmentDetailsDisplay = `${apptDateObj.dateString}, ${apptDateObj.timeString} ${apptDateObj.timeZone}`;
            }
        } else if (stop.apptType === "NEED_APPOINTMENT") {
            let requestedDateObj = isNotNullOrUndefined(stop.requestedDateTime) && isStringNotEmpty(stop.timeZone) ? momentDate.fromTimeZonelessToTimeZoneAsDateObject(stop.requestedDateTime, stop.timeZone) : null;
            if (isObjectNotEmpty(requestedDateObj)) {
                appointmentDetailsDisplay = `${requestedDateObj.dateString}, TBD`;
            }
        }
    }

    return appointmentDetailsDisplay;
};

const getAppointmentDateTimeDisplayFromMomentDateTime = (stop) => {
    if (isObjectNotEmpty(stop) && isStringNotEmpty(stop.apptType)) {
        const stopTimeZone = isObjectNotEmpty(stop.stopLocation) && isStringNotEmpty(stop.stopLocation.timeZone) ? stop.stopLocation.timeZone : null;

        let appointmentTypeToShow = null;
        switch (stop.apptType) {
            case "FIRST_COME_FIRST_SERVE":
                appointmentTypeToShow = (isNotNullOrUndefined(stop.apptWindowStartDateTime) && isNotNullOrUndefined(stop.apptWindowEndDateTime) ? stringFormatter.toFormattedTimeRange(stop.apptWindowStartDateTime, stop.apptWindowEndDateTime, stopTimeZone) : null);
                break;
            case "HAVE_APPOINTMENT":
                appointmentTypeToShow = (isNotNullOrUndefined(stop.apptDateTime) ? stringFormatter.toFormattedString('MomentDateTime', stop.apptDateTime, null, stopTimeZone) : null);
                break;
            case "NEED_APPOINTMENT":
                appointmentTypeToShow = (isNotNullOrUndefined(stop.requestedDateTime) ? stringFormatter.toFormattedString('MomentDate', stop.requestedDateTime, null, stopTimeZone) : null);
                break;
        }

        return appointmentTypeToShow;
    } else {
        return null;
    }
};

const getAppointmentDetailsDisplay = (stop) => {
    let appointmentDetailsDisplay = null;
    if (isObjectNotEmpty(stop)) {
        if (stop.apptType === "FIRST_COME_FIRST_SERVE") {
            let startDateObj = isNotNullOrUndefined(stop.apptWindowStartDateTime) && isStringNotEmpty(stop.timeZone) ? momentDate.fromUtcToTimeZoneDateObject(stop.apptWindowStartDateTime, stop.timeZone) : null;
            let endDateObj = isNotNullOrUndefined(stop.apptWindowEndDateTime) && isStringNotEmpty(stop.timeZone) ? momentDate.fromUtcToTimeZoneDateObject(stop.apptWindowEndDateTime, stop.timeZone) : null;

            if (isObjectNotEmpty(startDateObj) && isObjectNotEmpty(endDateObj)) {
                if (startDateObj.dateString === endDateObj.dateString) {
                    appointmentDetailsDisplay = `Appt: ${startDateObj.dateString}, ${startDateObj.timeString} - ${endDateObj.timeString} ${startDateObj.timeZone}`;
                } else {
                    appointmentDetailsDisplay = `Appt: ${startDateObj.dateString}, ${startDateObj.timeString} - ${endDateObj.dateString}, ${endDateObj.timeString} ${startDateObj.timeZone}`;
                }
            }
        } else if (stop.apptType === "HAVE_APPOINTMENT") {
            let apptDateObj = isNotNullOrUndefined(stop.apptDateTime) && isStringNotEmpty(stop.timeZone) ? momentDate.fromUtcToTimeZoneDateObject(stop.apptDateTime, stop.timeZone) : null;
            if (isObjectNotEmpty(apptDateObj)) {
                appointmentDetailsDisplay = `Appt: ${apptDateObj.dateString}, ${apptDateObj.timeString} ${apptDateObj.timeZone}`;
            }
        } else if (stop.apptType === "NEED_APPOINTMENT") {
            let requestedDateObj = isNotNullOrUndefined(stop.requestedDateTime) && isStringNotEmpty(stop.timeZone) ? momentDate.fromUtcToTimeZoneDateObject(stop.requestedDateTime, stop.timeZone) : null;
            if (isObjectNotEmpty(requestedDateObj)) {
                appointmentDetailsDisplay = `Appt: ${requestedDateObj.dateString}, TBD`;
            }
        }
    }

    return appointmentDetailsDisplay;
};

const getAppointmentContactDisplay = (stop) => {
    let appointmentContactDisplay = null;
    if (isObjectNotEmpty(stop)) {
        if (isObjectNotEmpty(stop.apptPointOfContact)) {
            appointmentContactDisplay = `Appt Contact: ${isStringNotEmpty(stop.apptPointOfContact.firstName) || isStringNotEmpty(stop.apptPointOfContact.lastName) ? stop.apptPointOfContact.firstName + ' ' + stop.apptPointOfContact.lastName + ', ' : ''}${stringFormatter.toFormattedPhoneNumber(stop.apptPointOfContact.phone, stop.apptPointOfContact.phoneExt)}`;
        }
    }

    return appointmentContactDisplay;
};

const getAppointmentCallAheadDisplay = (stop) => {
    let appointmentCallAheadDisplay = null;
    if (isObjectNotEmpty(stop)) {
        let apptCallAheadDateObj = isNotNullOrUndefined(stop.apptCallAheadDateTime) && isStringNotEmpty(stop.timeZone) ? momentDate.fromUtcToTimeZoneDateObject(stop.apptCallAheadDateTime, stop.timeZone) : null;
        if (isObjectNotEmpty(apptCallAheadDateObj)) {
            appointmentCallAheadDisplay = `Appt Call Ahead: ${apptCallAheadDateObj.dateString}, ${apptCallAheadDateObj.timeString} ${apptCallAheadDateObj.timeZone}`;
        }
    }

    return appointmentCallAheadDisplay;
};

const getStopStartDateTime = (stop) => {
    let stopDateObj = null;
    let apptType = null;
    if (isObjectNotEmpty(stop)) {
        apptType = stop.apptType;
        if (stop.apptType === "FIRST_COME_FIRST_SERVE") {
            stopDateObj = isNotNullOrUndefined(stop.apptWindowStartDateTime) && isStringNotEmpty(stop.timeZone) ? momentDate.fromUtcToTimeZoneDateObject(stop.apptWindowStartDateTime, stop.timeZone) : null;
        } else if (stop.apptType === "HAVE_APPOINTMENT") {
            stopDateObj = isNotNullOrUndefined(stop.apptDateTime) && isStringNotEmpty(stop.timeZone) ? momentDate.fromUtcToTimeZoneDateObject(stop.apptDateTime, stop.timeZone) : null;
        } else if (stop.apptType === "NEED_APPOINTMENT") {
            stopDateObj = isNotNullOrUndefined(stop.requestedDateTime) && isStringNotEmpty(stop.timeZone) ? momentDate.fromUtcToTimeZoneDateObject(stop.requestedDateTime, stop.timeZone) : null;
        }
    }

    return { apptType: apptType, startDateTime: stopDateObj };
};

const getStopEndDateTime = (stop) => {
    let stopDateObj = null;
    let apptType = null;
    if (isObjectNotEmpty(stop)) {
        apptType = stop.apptType;
        if (stop.apptType === "FIRST_COME_FIRST_SERVE") {
            stopDateObj = isNotNullOrUndefined(stop.apptWindowEndDateTime) && isStringNotEmpty(stop.timeZone) ? momentDate.fromUtcToTimeZoneDateObject(stop.apptWindowEndDateTime, stop.timeZone) : null;
        } else if (stop.apptType === "HAVE_APPOINTMENT") {
            stopDateObj = isNotNullOrUndefined(stop.apptDateTime) && isStringNotEmpty(stop.timeZone) ? momentDate.fromUtcToTimeZoneDateObject(stop.apptDateTime, stop.timeZone) : null;
        } else if (stop.apptType === "NEED_APPOINTMENT") {
            stopDateObj = isNotNullOrUndefined(stop.requestedDateTime) && isStringNotEmpty(stop.timeZone) ? momentDate.fromUtcToTimeZoneDateObject(stop.requestedDateTime, stop.timeZone) : null;
        }
    }

    return { apptType: apptType, endDateTime: stopDateObj };
};

const getOriginAndDestinationTimes = (origin, destination) => {
    let timeDisplay = '';
    if (isObjectNotEmpty(origin) && isObjectNotEmpty(destination)) {
        let { apptType: startApptType, startDateTime } = getStopStartDateTime(origin);
        let { apptType: endApptType, endDateTime } = getStopEndDateTime(destination);
        if (isObjectNotEmpty(startDateTime) && isObjectNotEmpty(endDateTime)) {
            let startTime = startApptType === 'NEED_APPOINTMENT' ? 'TBD' : startDateTime.timeString;
            let endTime = endApptType === 'NEED_APPOINTMENT' ? 'TBD' : endDateTime.timeString;
            if (startDateTime.dateString === endDateTime.dateString) {
                if (startDateTime.timeZone === endDateTime.timeZone) {
                    timeDisplay = `${startDateTime.dateString}, ${startTime} - ${endTime} ${startDateTime.timeZone}`;
                } else {
                    timeDisplay = `${startDateTime.dateString}, ${startTime} ${startDateTime.timeZone} - ${endTime} ${endDateTime.timeZone}`;
                }
            } else {
                if (startDateTime.timeZone === endDateTime.timeZone) {
                    timeDisplay = `${startDateTime.dateString}, ${startTime} - ${endDateTime.dateString}, ${endTime} ${startDateTime.timeZone}`;
                } else {
                    timeDisplay = `${startDateTime.dateString}, ${startTime} ${startDateTime.timeZone} - ${endDateTime.dateString}, ${endTime} ${endDateTime.timeZone}`;
                }
            }
        }
    }

    return timeDisplay;
};

const stopStatusIsNot = (stopStatus, isNotList = []) => {
    let isNot = true;
    if (isNotList.includes(stopStatus)) {
        isNot = false;
    }

    return isNot;
};

const stopStatusIs = (stopStatus, isList = []) => {
    let is = false;
    if (isList.includes(stopStatus)) {
        is = true;
    }

    return is;
};

export default {
    getAppointmentCallAheadDisplay,
    getAppointmentContactDisplay,
    getAppointmentDateTimeDisplay,
    getAppointmentDateTimeDisplayFromMomentDateTime,
    getAppointmentDetailsDisplay,
    getAppointmentType,
    getLoadStopLocationName,
    getLoadStopName,
    getLocationInfoByLink,
    getLocationNameByLink,
    getOriginAndDestinationTimes,
    getStopAddressDisplay,
    getStopEndDateTime,
    getStopLocationCityStateDisplay,
    getStopLocationDisplay,
    getStopLocationNameDisplay,
    getStopMarkerIcon,
    getStopNameDisplay,
    getStopStartDateTime,
    getStopTimeZone,
    getStopType,
    getStopTypeDisplay,
    stopStatusIs,
    stopStatusIsNot
}