import * as actionTypes from "../actions/actionTypes";
import {
    clearRecord,
    clearRecords,
    fetchRecordsFail,
    fetchRecordsStart,
    fetchRecordsSuccess
} from "../utility";

const initialState = {
    records: [],
    record: null,
    isLoading: false,
    error: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_YOUR_ACCOUNT_USERS_START: return fetchRecordsStart(state, action.payload);
        case actionTypes.FETCH_YOUR_ACCOUNT_USERS_SUCCESS: return fetchRecordsSuccess(state, action.payload);
        case actionTypes.FETCH_YOUR_ACCOUNT_USERS_FAIL: return fetchRecordsFail(state, action.payload);
        case actionTypes.CLEAR_YOUR_ACCOUNT_USERS: return clearRecords(state);
        case actionTypes.CLEAR_YOUR_ACCOUNT_USER: return clearRecord(state);
        default:
            return state;
    }
};

export default reducer;