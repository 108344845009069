import React, { useState, useMemo, useEffect } from 'react';
import { Alert, Spin } from "antd";
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Pricing from '../../shared/pricing';
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import { isListNotEmpty, isNotNullOrUndefined, isObjectNotEmpty } from '../../shared/objectUtils';
import { selectListRecords } from '../../store/utility';

const EditLoadStatusClosed = ({ load, stops, invoices, invoiceLineItems, transactions, claims, cancel }) => {
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loadEvents.isLoadEventUpdateLoading);
    const error = useSelector(state => state.loadEvents.updateLoadEventError);
    const documentLists = useSelector(state => state.documents.lists);

    //#endregion
    //#region useStates

    const [hasOpenClaims, setHasOpenClaims] = useState(true);
    const [hasBalance, setHasBalance] = useState(true);
    const [hasPendingInvoiceLineItems, setHasPendingInvoiceLineItems] = useState(true);
    const [hasPendingTransactions, setHasPendingTransactions] = useState(true);
    const [hasUnreviewedBOLs, setHasUnreviewedBOLs] = useState(true);
    const [pricingSummary, setPricingSummary] = useState({});

    //#endregion
    //#region onSubmit and cancel

    const onSubmit = () => {
        dispatch(actionCreators.sendLoadEvent({
            loadId: load.id,
            eventType: 'LOAD_CLOSED'
        }));
    };

    const onCancel = () => {
        dispatch(actionCreators.updateLoadEventComplete());
        cancel();
    };

    //#endregion
    //#region useMemos and useEffects

    useMemo(() => {
        if (isNotNullOrUndefined(invoiceLineItems) && isNotNullOrUndefined(transactions)) {
            const pricingSummaryValue = Pricing.loadPricingSummary(invoiceLineItems, transactions);
            setPricingSummary(pricingSummaryValue);
        }
    }, [invoiceLineItems, transactions]);

    useEffect(() => {
        if (isListNotEmpty(stops)) {
            let ids = [];

            stops.forEach((stop) => {
                ids.push(stop.id);
            });

            dispatch(actionCreators.fetchBulkDocumentLists(load.id, ids));
        }
    }, [stops]);

    useEffect(() => {
        if (isObjectNotEmpty(load) && load.serviceType === 'TMS') {
            // if the load has a service type of TMS then documents don't need to be reviewed
            setHasUnreviewedBOLs(false);
        } else if (isListNotEmpty(stops) && isObjectNotEmpty(documentLists)) {
            // make sure BOLs and other required documents are uploaded for each pick-up and drop-off stop and that all documents are reviewed

            let hasBOLsReviewed = false;
            let hasPendingApprovalDocuments = false;
            let hasMissingDocuments = false;
            stops.filter(s => s.stopType === 'PICK_UP' || s.stopType === 'DROP_OFF').forEach((stop) => {
                let stopDocuments = selectListRecords(documentLists, stop.id);
                if (isListNotEmpty(stopDocuments)) {
                    stopDocuments.forEach((item) => {
                        if (!item.isReviewed) {
                            if (item.documentType === 'BOL' ||
                                item.documentType === 'LUMPER_FEE_RECEIPT' ||
                                item.documentType === 'SCALE_TICKET' ||
                                item.documentType === 'TRAILER_CONTROL_FILE' ||
                                item.documentType === 'FUEL_RECEIPT' ||
                                item.documentType === 'TOLL_RECEIPT' ||
                                item.documentType === 'RECEIPT' ||
                                item.documentType === 'OTHER') {
                                hasPendingApprovalDocuments = true;
                            }
                        }
                    });

                    // check for missing documents that are required for stops
                    if (stop.stopType === 'PICK_UP') {
                        if (stopDocuments.find(item => item.documentType === 'BOL') === undefined) {
                            hasMissingDocuments = true;
                        }
                        if (stopDocuments.find(item => item.documentType === 'SCALE_TICKET') === undefined) {
                            hasMissingDocuments = true;
                        }
                    } else if (stop.stopType === 'DROP_OFF') {
                        if (stopDocuments.find(item => item.documentType === 'BOL') === undefined) {
                            hasMissingDocuments = true;
                        }
                    }
                } else {
                    hasMissingDocuments = true;
                }
            });
            //console.log(hasPendingApprovalDocuments);
            //console.log(hasMissingDocuments);
            hasBOLsReviewed = (hasPendingApprovalDocuments === false && hasMissingDocuments === false);
            //console.log(hasBOLsReviewed);
            setHasUnreviewedBOLs(hasBOLsReviewed === false);
        }
    }, [load, stops, documentLists]);

    useEffect(() => {
        // check to see if transactions and invoice match and the balance for shippers and carriers is zero
        if (isObjectNotEmpty(pricingSummary)) {
            setHasBalance(pricingSummary.shipperBalance > 0 || pricingSummary.carrierBalance > 0 || pricingSummary.factoringBalance > 0);
        }
    }, [pricingSummary]);

    useEffect(() => {
        // check to see if there are any open claims for this load
        if (isListNotEmpty(claims)) {
            let openClaims = claims.filter(i => i.claimStatus !== 'CLOSED');
            setHasOpenClaims(openClaims.length > 0);
        } else {
            setHasOpenClaims(false);
        }
    }, [claims]);

    useEffect(() => {
        // check to see if there are any pending invoice line items for this load
        if (isListNotEmpty(invoiceLineItems)) {
            let pendingInvoiceLineItems = invoiceLineItems.filter(i => i.status === 'PENDING' || i.approvalStatus === 'NEW' || i.approvalStatus === 'PENDING');
            setHasPendingInvoiceLineItems(isListNotEmpty(pendingInvoiceLineItems));
        } else {
            setHasPendingInvoiceLineItems(false);
        }
    }, [invoiceLineItems]);

    useEffect(() => {
        // check to see if there are any pending transactions for this load
        if (isListNotEmpty(transactions)) {
            let pendingTransactions = transactions.filter(i => i.paymentStatus === 'PENDING');
            setHasPendingTransactions(isListNotEmpty(pendingTransactions));
        } else {
            setHasPendingTransactions(false);
        }
    }, [transactions]);

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLoadEventErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    //#endregion  

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true) && error === null}>
                    <Fieldset legend="Close the Load">
                        <b>Make sure there are no open claims for this Load, that every pick-up and drop-off stop has BOLs uploaded and all documents reviewed, and that all payments from Shippers and to Carriers have been made.</b>
                    </Fieldset>
                    {hasOpenClaims === true ? (<Alert message="There are Claims that are Open" type="error" />) : null}
                    {hasBalance === true ? (<Alert message="There is a Balance on the Load" type="error" />) : null}
                    {hasUnreviewedBOLs === true ? (<Alert message="There are Documents that are Missing or Pending Approval" type="error" />) : null}
                    {hasPendingInvoiceLineItems === true ? (<Alert message="There are Invoice Line Items with Pending Status or without Approval" type="error" />) : null}
                    {hasPendingTransactions === true ? (<Alert message="There are Transactions with Pending Payment Status" type="error" />) : null}
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} cancelDisabled={isLoading === true && error === null} submitDisabled={(hasBalance === true || hasOpenClaims === true || hasUnreviewedBOLs === true || hasPendingInvoiceLineItems === true || hasPendingTransactions === true) || (isLoading === true && error === null)} submitText="Close the Load" />
            </Form>
        </FormProvider>
    );
};

export default EditLoadStatusClosed;