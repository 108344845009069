import axiosAuthenticated from "../../api/axiosAuthenticated";
import { isListNotEmpty, isNotNullOrUndefined } from "../../shared/objectUtils";

const loadNotificationsPath = '/loadNotifications';
const loadNotificationsReadAllPath = '/loadNotificationsReadAll';

//#region Load Notifications Methods

export const getLoadNotifications = async (searchParams = {}, pagination = {}) => {
    const loadNotificationsRes = await axiosAuthenticated.get(loadNotificationsPath, { params: { ...searchParams } });
    if (loadNotificationsRes && loadNotificationsRes.status === 200) {
        const loadNotifications = loadNotificationsRes.data.data;
        const otherData = loadNotificationsRes.data;

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;

        return { data: loadNotifications, searchParams: searchParams, pagination: pagination };
    }

    return { data: [], searchParams: searchParams, pagination: pagination };
};

export const getMoreLoadNotifications = async (searchParams = {}, pagination = {}, existingRecords = []) => {
    let updatedLoadNotifications = [];
    if (isListNotEmpty(existingRecords)) {
        updatedLoadNotifications = [...existingRecords];
    }

    const loadNotificationsRes = await axiosAuthenticated.get(loadNotificationsPath, { params: { ...searchParams } });
    if (loadNotificationsRes && loadNotificationsRes.status === 200) {
        const loadNotifications = loadNotificationsRes.data.data;
        const otherData = loadNotificationsRes.data;

        loadNotifications.forEach((loadNotification) => {
            if (updatedLoadNotifications.find(ln => ln.id === loadNotification.id) === undefined) {
                updatedLoadNotifications.push(loadNotification);
            }
        });

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;
    }

    return { data: updatedLoadNotifications, searchParams: searchParams, pagination: pagination };
};

export const addLoadNotification = async (subject, message, userId, entityType, entityId, loadId, loadIrisId, loadStatus, eventType, toStaff = false) => {
    let newNotification = {
        message: message,
        loadId: loadId,
        loadIrisId: loadIrisId,
        saveOnly: false,
        subject: subject,
        severity: "CRITICAL",
        loadStatus: loadStatus,
        eventType: eventType,
        toStaff: toStaff
    };

    if (entityType === "DRIVER") {
        newNotification.userIds = [userId];
    } else if (entityType === 'SHIPPER' || entityType === 'CARRIER' || entityType === 'PRODUCER' || entityType === 'RECEIVER' || entityType === 'BROKER' || entityType === 'FACTORING') {
        newNotification.accountIds = [entityId];
    }

    const loadNotificationsRes = await axiosAuthenticated.post(loadNotificationsPath, newNotification);
    if (loadNotificationsRes && loadNotificationsRes.status === 201) {
        return loadNotificationsRes.data;
    }

    return null;
};

export const sendLoadNotification = async (payload) => {
    const loadNotificationsRes = await axiosAuthenticated.post(loadNotificationsPath, { ...payload });
    if (loadNotificationsRes && loadNotificationsRes.status === 201) {
        return loadNotificationsRes.data;
    }

    return null;
};

export const updateLoadNotification = async (id, payload) => {
    const loadNotificationsRes = await axiosAuthenticated.put(loadNotificationsPath + `/${id}`, { ...payload });
    if (loadNotificationsRes && loadNotificationsRes.status === 200) {
        return loadNotificationsRes.data;
    }

    return null;
};

export const markLoadNotificationAsRead = async (id, userId) => {
    let readRecord = { readAt: Date.now().toString(), readBy: userId };

    const loadNotificationsRes = await axiosAuthenticated.put(loadNotificationsPath + `/${id}`, { 'add_read': [readRecord] });
    if (loadNotificationsRes && loadNotificationsRes.status === 200) {
        return loadNotificationsRes.data;
    }

    return null;
};

export const markMultipleLoadNotificationsAsRead = async (ids) => {
    if (isListNotEmpty(ids)) {
        const loadNotificationsRes = await axiosAuthenticated.post(loadNotificationsReadAllPath, { ids: ids });
        if (loadNotificationsRes && loadNotificationsRes.status === 204) {
            return true;
        }
    }
    return false;
};

//#endregion