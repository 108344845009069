import React, { useMemo, useState } from 'react';
import { Drawer } from "antd";
import EditLoadRepresentative from "../../components/EditLoadRepresentative/EditLoadRepresentative";
import AddEditChangeRemoveButtons from '../../components/AddEditChangeRemoveButtons/AddEditChangeRemoveButtons';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import DataPhoneNumberRow from '../DataPhoneNumberRow/DataPhoneNumberRow';
import DataNameRow from '../DataNameRow/DataNameRow';
import { isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { CanSee } from '../../shared/entitlements/entitlements';
import LoadUtils from '../../api/utils/loadUtils';
import DataContactInfoRow from '../DataContactInfoRow/DataContactInfoRow';

const LoadRepresentativeCard = ({ load }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const staff = useSelector(state => state.orchestrator.staff);

    //#endregion
    //#region useStates

    const [showEditEntity, setShowEditEntity] = useState(false);

    //#endregion
    //#region toggles

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    //#endregion
    //#region displays

    const editEntityComponents = (
        <Drawer
            title={"Change Representative(s) for Load"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditEntity}
            visible={showEditEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditLoadRepresentative
                cancel={toggleEditEntity}
                load={load}
                users={staff}
            />
        </Drawer>
    );

    //#endregion
    //#region useMemos

    useMemo(() => {
        dispatch(actionCreators.getStaff());
    }, []);

    //#endregion

    return (
        <>
            <div style={{ marginTop: 12, marginBottom: 12 }}>
                <span style={{ fontSize: 14, fontWeight: 'bold', color: '#000000' }}>Account Representative(s):</span>
                <CanSee staffOnly={true}>
                    {isObjectNotEmpty(load) ? (
                        <AddEditChangeRemoveButtons
                            showAdd={false} addText='' addAction={null}
                            showEdit={false} editText='' editAction={null}
                            showChange={LoadUtils.loadStatusIsNot(load.loadStatus, ['CLOSED']) ? true : false} changeText={"Change Representative(s) for Load"} changeAction={(e) => { e.stopPropagation(); toggleEditEntity(); }}
                            showRemove={false} removeText='' removeAction={null}
                        />
                    ) : null}
                </CanSee>
            </div>
            {isListNotEmpty(load.assignedAccountRepUsers) ? load.assignedAccountRepUsers.map((assignedAccountRepUser, index) => {
                return (
                    <DataContactInfoRow key={`accountrep-${index}`} title={assignedAccountRepUser.firstName + " " + assignedAccountRepUser.lastName} phone={assignedAccountRepUser.phone} phoneExt={assignedAccountRepUser.phoneExt} />
                );
            }) : (<div><i>No Account Representatives Assigned</i></div>)}
            {editEntityComponents}
        </>
    );
};

export default LoadRepresentativeCard;