import React, { useMemo, useState } from 'react';
import { Card, Typography } from "antd";
import DataRow from "../../components/DataRow/DataRow";
import classes from './LoadRateCard.module.scss';
import StringFormatter from '../../shared/stringFormatter';
import AddEditChangeRemoveButtons from '../AddEditChangeRemoveButtons/AddEditChangeRemoveButtons';
import Pricing from '../../shared/pricing';
import DataAtByRow from '../DataAtByRow/DataAtByRow';
import { isNotNullOrUndefined, isObjectNotEmpty } from '../../shared/objectUtils';
import { useSelector } from 'react-redux';

const { Title } = Typography;
const stringFormatter = new StringFormatter();

const LoadRateCard = ({ loadId, load, stops, invoices, invoiceLineItems, transactions, claims, currentStatus, shipperId, carrierId, serviceType, title, style = null, headStyle = null, onTabChange }) => {
    //#region useSelectors

    const entityType = useSelector(state => state.auth.entityType);

    //#endregion
    //#region useStates

    const [pricingSummary, setPricingSummary] = useState({});

    //#endregion
    //#region displays

    const extra = (entityType === "STAFF") ? (
        <AddEditChangeRemoveButtons
            showAdd={false} addText='' addAction={null}
            showEdit={true} editText={'Edit Pricing for this Load'} editAction={(e) => { e.stopPropagation(); onTabChange('financials'); }}
            showChange={false} changeText='' changeAction={null}
            showRemove={false} removeText='' removeAction={null}
        />
    ) : null;

    //#endregion
    //#region useMemos

    useMemo(() => {
        if (isNotNullOrUndefined(invoiceLineItems) && isNotNullOrUndefined(transactions)) {
            const pricingSummaryValue = Pricing.loadPricingSummary(invoiceLineItems, transactions);
            setPricingSummary(pricingSummaryValue);
        }
    }, [invoiceLineItems, transactions]);

    //#endregion

    return (
        <Card title={title} bordered={true} className={classes.card} style={style ? style : null} headStyle={headStyle ? headStyle : null} extra={extra}>
            {isObjectNotEmpty(pricingSummary) && entityType === "STAFF" ? (
                <Title level={1} style={{ marginBottom: '0.5em' }}>{stringFormatter.toFormattedString("Money", pricingSummary.IRISAmount, null)}</Title>
            ) : null}
            {isObjectNotEmpty(pricingSummary) && entityType === "SHIPPER" ? (
                <Title level={1} style={{ marginBottom: '0.5em' }}>{stringFormatter.toFormattedString("Money", Math.abs(pricingSummary.shipperAmountWithAddOns), null)}</Title>
            ) : null}
            {isObjectNotEmpty(pricingSummary) && entityType === "CARRIER" ? (
                <Title level={1} style={{ marginBottom: '0.5em' }}>{stringFormatter.toFormattedString("Money", Math.abs(pricingSummary.carrierAmountWithAddOns), null)}</Title>
            ) : null}
            {entityType === "STAFF" ? (
                <>
                    {load.isPriceConfirmed && load.priceConfirmedByUser && load.priceConfirmedAt ? <DataAtByRow entityType={entityType} title="Price Confirmed" at={load.priceConfirmedAt} by={load.priceConfirmedByUser} /> : <DataRow title="Price Confirmed" value={'Pending'} dataType={'String'} />}
                    {load.isRateConfirmed && load.rateConfirmedByUser && load.rateConfirmedAt ? <DataAtByRow entityType={entityType} title="Rate Confirmed" at={load.rateConfirmedAt} by={load.rateConfirmedByUser} /> : <DataRow title="Rate Confirmed" value={'Pending'} dataType={'String'} />}
                    <hr />
                </>
            ) : null}
            {entityType === "CARRIER" ? (
                <>
                    {load.isRateConfirmed && load.rateConfirmedByUser && load.rateConfirmedAt ? <DataAtByRow entityType={entityType} title="Rate Confirmed" at={load.rateConfirmedAt} by={load.rateConfirmedByUser} /> : <DataRow title="Rate Confirmed" value={'Pending'} dataType={'String'} />}
                    <hr />
                </>
            ) : null}
            {entityType === "SHIPPER" ? (
                <>
                    {load.isPriceConfirmed && load.priceConfirmedByUser && load.priceConfirmedAt ? <DataAtByRow entityType={entityType} title="Price Confirmed" at={load.priceConfirmedAt} by={load.priceConfirmedByUser} /> : <DataRow title="Price Confirmed" value={'Pending'} dataType={'String'} />}
                    <hr />
                </>
            ) : null}
            <DataRow title="Trip Miles" value={load.practicalDistance} units={load.practicalDistanceUnit} dataType="Distance" />
            {isObjectNotEmpty(pricingSummary) && entityType === "STAFF" ? (
                <>
                    {pricingSummary.shipperAccessorialAmount !== 0.00 ? (<DataRow title="Accessorials" value={pricingSummary.shipperAccessorialAmount} dataType="Money" />) : null}
                    {pricingSummary.addOnChargeAmount !== 0.00 ? (<DataRow title="Add-On Fees" value={pricingSummary.addOnChargeAmount} dataType="Money" />) : null}
                    {pricingSummary.shipperClaimAmount !== 0.00 ? (<DataRow title="Claim Adjustments" value={pricingSummary.shipperClaimAmount} dataType="Money" />) : null}
                    <DataRow title="Shipper Rate/Mile" value={pricingSummary.shipperRatePerMileAmount} dataType="Money" />
                    <DataRow valueStyle={{ fontWeight: 'bold' }} title={pricingSummary.shipperAmountWithAddOns > 0 ? "Amount Owed by Shipper" : "Amount Owed to Shipper"} value={Math.abs(pricingSummary.shipperAmountWithAddOns)} dataType="Money" />
                    <DataRow valueStyle={{ fontWeight: 'bold' }} title={pricingSummary.shipperBalance > 0 ? "Balance Owed by Shipper" : "Balance Owed to Shipper"} value={Math.abs(pricingSummary.shipperBalance)} dataType="Money" />
                    <hr />
                    {pricingSummary.carrierAccessorialAmount !== 0.00 ? (<DataRow title="Accessorials" value={pricingSummary.carrierAccessorialAmount} dataType="Money" />) : null}
                    {pricingSummary.addOnCostAmount !== 0.00 ? (<DataRow title="Add-On Fees" value={pricingSummary.addOnCostAmount} dataType="Money" />) : null}
                    {pricingSummary.carrierClaimAmount !== 0.00 ? (<DataRow title="Claim Adjustments" value={pricingSummary.carrierClaimAmount} dataType="Money" />) : null}
                    <DataRow title="Carrier Rate/Mile" value={Math.abs(pricingSummary.carrierRatePerMileAmount)} dataType="Money" />
                    <DataRow valueStyle={{ fontWeight: 'bold' }} title={pricingSummary.carrierAmountWithAddOns < 0 ? "Amount Owed by Carrier" : "Amount Owed to Carrier"} value={Math.abs(pricingSummary.carrierAmountWithAddOns)} dataType="Money" />
                    <DataRow valueStyle={{ fontWeight: 'bold' }} title={pricingSummary.carrierBalance < 0 ? "Balance Owed by Carrier" : "Balance Owed to Carrier"} value={Math.abs(pricingSummary.carrierBalance)} dataType="Money" />
                    <hr />
                    <DataRow valueStyle={{ fontWeight: 'bold' }} title={pricingSummary.factoringAmount < 0 ? "Amount Owed by Factoring Company" : "Amount Owed to Factoring Company"} value={Math.abs(pricingSummary.factoringAmount)} dataType="Money" />
                    <DataRow valueStyle={{ fontWeight: 'bold' }} title={pricingSummary.factoringBalance < 0 ? "Balance Owed by Factoring Company" : "Balance Owed to Factoring Company"} value={Math.abs(pricingSummary.factoringBalance)} dataType="Money" />
                    <hr />
                    <DataRow title="Service Fee/Mile" value={pricingSummary.feePerMileAmount} dataType="Money" />
                    <DataRow valueStyle={{ fontWeight: 'bold' }} title="IRIS Service Fee" value={pricingSummary.feeAmount} dataType="Money" />
                    <DataRow title="Commission" value={pricingSummary.IRISPercentage} dataType="Percentage" />
                    <DataRow valueStyle={{ fontWeight: 'bold' }} title="Expected Revenue" value={pricingSummary.IRISAmount} dataType="Money" />
                </>
            ) : null}
            {isObjectNotEmpty(pricingSummary) && entityType === "SHIPPER" ? (
                <>
                    {pricingSummary.shipperAccessorialAmount !== 0.00 ? (<DataRow title="Shipper Accessorials" value={Math.abs(pricingSummary.shipperAccessorialAmount)} dataType="Money" />) : null}
                    {pricingSummary.addOnChargeAmount !== 0.00 ? (<DataRow title="Shipper Add-Ons" value={Math.abs(pricingSummary.addOnChargeAmount)} dataType="Money" />) : null}
                    {pricingSummary.shipperClaimAmount !== 0.00 ? (<DataRow title="Shipper Claim Adjustments" value={Math.abs(pricingSummary.shipperClaimAmount)} dataType="Money" />) : null}
                    <DataRow valueStyle={{ fontWeight: 'bold' }} title={pricingSummary.shipperAmountWithAddOns > 0 ? "Amount Due from Shipper" : "Amount Due to Shipper"} value={Math.abs(pricingSummary.shipperAmountWithAddOns)} dataType="Money" />
                    <DataRow valueStyle={{ fontWeight: 'bold' }} title={pricingSummary.shipperBalance > 0 ? "Balance Due from Shipper" : "Balance Due to Shipper"} value={Math.abs(pricingSummary.shipperBalance)} dataType="Money" />
                </>
            ) : null}
            {isObjectNotEmpty(pricingSummary) && entityType === "CARRIER" ? (
                <>
                    {pricingSummary.carrierAccessorialAmount !== 0.00 ? (<DataRow title="Carrier Accessorials" value={Math.abs(pricingSummary.carrierAccessorialAmount)} dataType="Money" />) : null}
                    {pricingSummary.addOnCostAmount !== 0.00 ? (<DataRow title="Carrier Add-Ons Costs" value={Math.abs(pricingSummary.addOnCostAmount)} dataType="Money" />) : null}
                    {pricingSummary.carrierClaimAmount !== 0.00 ? (<DataRow title="Carrier Claim Adjustments" value={Math.abs(pricingSummary.carrierClaimAmount)} dataType="Money" />) : null}
                    <DataRow valueStyle={{ fontWeight: 'bold' }} title={pricingSummary.carrierAmountWithAddOns > 0 ? "Amount Due from Carrier" : "Amount Due to Carrier"} value={Math.abs(pricingSummary.carrierAmountWithAddOns)} dataType="Money" />
                    <DataRow valueStyle={{ fontWeight: 'bold' }} title={pricingSummary.carrierBalance > 0 ? "Balance Due from Carrier" : "Balance Due to Carrier"} value={Math.abs(pricingSummary.carrierBalance)} dataType="Money" />
                </>
            ) : null}
            {isNotNullOrUndefined(claims) && entityType === "STAFF" ? (
                <>
                    <hr />
                    <DataRow title="Open Claims" value={claims.filter(i => i.claimStatus !== 'CLOSED') !== undefined ? claims.filter(i => i.claimStatus !== 'CLOSED').length : 0} dataType="String" />
                </>
            ) : null}
        </Card>
    );
};

export default LoadRateCard;