import React, { useState, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { DownOutlined, FileTextOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Drawer, Modal, Button, Menu, Dropdown } from 'antd';
import Claim from "../../components/Claim/Claim";
import EditClaim from "../../components/EditClaim/EditClaim";
import NewClaim from "../../components/NewClaim/NewClaim";
import classes from './LoadClaims.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faScroll, faTools } from '@fortawesome/free-solid-svg-icons';
import DataTable from '../../components/DataTable/DataTable';
import StringFormatter from '../../shared/stringFormatter';
import DocumentList from '../../components/DocumentList/DocumentList';
import NoteList from '../NoteList/NoteList';
import { useSelector } from 'react-redux';
import { isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { CanDo } from '../../shared/entitlements/entitlements';
import LoadUtils from '../../api/utils/loadUtils';

const stringFormatter = new StringFormatter();

const LoadClaims = ({ load, loadId, claims, currentStatus }) => {
    const fullWidth = global.window.innerWidth;

    const singularEntityName = "Claim";
    const pluralEntityName = "Claims";

    const entityType = useSelector(state => state.auth.entityType);

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showDocumentsModal, setShowDocumentsModal] = useState(false);
    const [showNotesModal, setShowNotesModal] = useState(false);
    const [loadClaims, setLoadClaims] = useState([]);

    const menu = (record) => {
        return (
            <Menu>
                {CanDo({ entityAction: 'UPDATE', entityModel: 'CLAIM', entityObject: record }) ? (
                    <Menu.Item key="manageDocuments" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); toggleDocumentsModal(); }}>
                        <PaperClipOutlined style={{ marginRight: 8 }} />
                        <span>Manage Documents</span>
                    </Menu.Item>
                ) : null}
                {CanDo({ entityAction: 'UPDATE', entityModel: 'CLAIM', entityObject: record }) ? (
                    <Menu.Item key="manageNotes" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); toggleNotesModal(); }}>
                        <FileTextOutlined style={{ marginRight: 8 }} />
                        <span>Manage Notes</span>
                    </Menu.Item>
                ) : null}
                {CanDo({ entityAction: 'UPDATE', entityModel: 'CLAIM', entityObject: record }) ? (
                    <Menu.Item key="editClaim" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); toggleEditEntity(); }}>
                        <FontAwesomeIcon className="anticon" icon={faScroll} style={{ marginRight: 8 }} />
                        <span>Edit {singularEntityName} Details</span>
                    </Menu.Item>
                ) : null}
            </Menu>
        );
    };

    const columns = [
        {
            title: 'Claim ID',
            dataIndex: 'irisId',
            key: 'irisId',
        },
        {
            title: 'Claim Status',
            dataIndex: 'claimStatus',
            key: 'claimStatus',
            render: (text, record) => { return stringFormatter.toFormattedString("ClaimStatus", text, null); },
        },
        {
            title: 'Is Against Carrier',
            dataIndex: 'isAgainstCarrier',
            key: 'isAgainstCarrier',
            render: (text, record) => { return stringFormatter.toYesNo(text); },
        },
        {
            title: 'Is Against Shipper',
            dataIndex: 'isAgainstShipper',
            key: 'isAgainstShipper',
            render: (text, record) => { return stringFormatter.toYesNo(text); },
        },
        {
            title: 'Is Claim Against Insurance',
            dataIndex: 'isInsuranceClaim',
            key: 'isInsuranceClaim',
            render: (text, record) => { return stringFormatter.toYesNo(text); },
        },
        {
            title: 'Claim Against',
            dataIndex: ['account', 'name'],
            key: 'account.name',
        },
        {
            title: 'Comments',
            dataIndex: 'comments',
            key: 'comments',
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
            render: (text, record) => { return stringFormatter.toFormattedString("UnitPackagingCount", text, record.unitPackaging); },
        },
        {
            title: 'BOL Status',
            dataIndex: 'bolStatus',
            key: 'bolStatus',
            render: (text, record) => { return stringFormatter.toFormattedString("BOLStatus", text, null); },
        },
        {
            title: 'Adjustment Amount',
            dataIndex: 'adjustment',
            key: 'adjustment',
            render: (text, record) => { return stringFormatter.toFormattedMoney(text); },
        },
        {
            title: 'BOL #',
            dataIndex: 'bolNumber',
            key: 'bolNumber',
        }
    ];

    if (LoadUtils.loadStatusIsNot(currentStatus, ['CLOSED'])) {
        columns.unshift({
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            width: 50,
            render: (text, record) => {
                return (
                    <Dropdown overlay={menu(record)} trigger={['click']}>
                        <Button>Actions <DownOutlined /></Button>
                    </Dropdown>
                );
            },
            align: 'center',
        });
    }

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleDocumentsModal = () => {
        setShowDocumentsModal(!showDocumentsModal);
    };

    const toggleNotesModal = () => {
        setShowNotesModal(!showNotesModal);
    };

    const newEntityComponents = (
        <Drawer
            title={"New " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleNewEntity}
            visible={showNewEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewClaim
                cancel={toggleNewEntity}
                loadId={load.id}
                irisLoadId={load.irisId}
            />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditEntity}
            visible={showEditEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditClaim
                cancel={toggleEditEntity}
                claim={selectedRecord}
            />
        </Drawer>
    );

    const documentsComponent = (
        <Modal
            title="Claim Documents"
            visible={showDocumentsModal === true}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleDocumentsModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            {isObjectNotEmpty(selectedRecord) && isObjectNotEmpty(load) ? (
                <DocumentList
                    documentEntityType="CLAIM"
                    documentEntityId={selectedRecord.id}
                    loadId={load.id}
                    load={load}
                />
            ) : null}
        </Modal>
    );

    const notesComponent = (
        <Modal
            title="Claim Notes"
            visible={showNotesModal === true}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleNotesModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            {isObjectNotEmpty(selectedRecord) ? (
                <NoteList
                    noteEntityId={selectedRecord.id}
                    noteEntityType="CLAIM"
                />
            ) : null}
        </Modal>
    );

    useMemo(() => {
        if (isListNotEmpty(claims)) {
            setLoadClaims(claims.filter(c => c.loadId === loadId));
        } else {
            setLoadClaims([]);
        }
    }, [claims, loadId]);

    if (isObjectNotEmpty(load) && isStringNotEmpty(load.id)) {
        return (
            <DataTable
                dataSource={loadClaims}
                columns={columns}
                pageSize={5}
                // loading={isLoading === true}
                expandedRowRender={record => (<Claim claim={record} />)}
                rowClassName={classes.dataTableRow}
                singularEntityName={singularEntityName}
                pluralEntityName={pluralEntityName}
                newEntityAction={CanDo({ entityAction: 'CREATE', entityModel: 'CLAIM', entityObject: load }) && LoadUtils.loadStatusIsNot(currentStatus, ['CANCELLED', 'CLOSED']) ? toggleNewEntity : null}
                rowKey={record => record.id}
                style={{ backgroundColor: '#ffffff' }}
            >
                {editEntityComponents}
                {newEntityComponents}
                {documentsComponent}
                {notesComponent}
            </DataTable>
        );
    } else {
        return null;
    }
};

export default withRouter(LoadClaims);