import React from 'react';
import DataRow from '../../components/DataRow/DataRow';
import Enums from '../../shared/enums';
import { isBooleanFalse, isNumberNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';

const DataCommodityListRow = ({ className = null, nameClassName = null, valueClassName = null, titleClassName = null, containerStyle = null, style = null, valueStyle = null, nameStyle = { fontWeight: 'bold' }, titleStyle = { fontWeight: 'bold' }, title = null, data, noColon = false, dataType = 'String' }) => {
    const createDataItems = (items) => {
        let dataItems = [];
        const entries = Object.entries(items);

        for (const [key, value] of entries) {
            dataItems.push(<DataRow className={className} valueClassName={valueClassName} titleClassName={nameClassName} style={style} titleStyle={nameStyle} valueStyle={valueStyle} key={`data-row-${key}`} title={value.name} value={`${isNumberNotEmpty(value.unitPackagingInitCount) ? value.unitPackagingInitCount + ' ' + Enums.UnitPackaging.getValueByName(value.unitPackaging) : ''}${isNumberNotEmpty(value.bulkPackagingInitCount) ? ', ' + value.bulkPackagingInitCount + ' ' + Enums.BulkPackaging.getValueByName(value.bulkPackaging) : ''}`} dataType={dataType} />);
        }

        return dataItems;
    };

    return (
        <div style={containerStyle}>
            {isStringNotEmpty(title) ? (<div className={titleClassName} style={titleStyle}>{title}{isBooleanFalse(noColon) ? ':' : ''} </div>) : null}
            {createDataItems(data)}
        </div>
    );
};

export default DataCommodityListRow;