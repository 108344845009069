import axiosAuthenticated from "../../api/axiosAuthenticated";
import { getFilesMetaData } from "../../api/fileClient";
import { isListNotEmpty, isNotNullOrUndefined, isStringNotEmpty } from "../../shared/objectUtils";
import * as DTO from "../dtos/index";
import * as Data from "./index";

const linkedAccountsPath = '/linkedAccounts';
const accountsPath = '/accounts';

//#region LinkedAccounts Methods

export const getLinkedAccountsAsync = async (searchParams = {}, config = {}, entityId = null) => {
    const linkedAccountsRes = await axiosAuthenticated.get(accountsPath, { params: { ...searchParams }, ...config });
    if (linkedAccountsRes && linkedAccountsRes.status === 200) {
        const linkedAccounts = linkedAccountsRes.data.data;
        const otherData = linkedAccountsRes.data;

        let linkedAccountIds = linkedAccounts.map(item => { return item.id; });
        let linkedAccountDocuments = await Data.getDocumentsByEntityIds(linkedAccountIds);

        return linkedAccounts.map((linkedAccount) => { return DTO.getLinkedAccountDTO(linkedAccount, linkedAccountDocuments, entityId); });
    }

    return [];
};

export const getLinkedAccounts = async (searchParams = {}, pagination = {}, entityId = null) => {
    const linkedAccountsRes = await axiosAuthenticated.get(accountsPath, { params: { ...searchParams } });
    if (linkedAccountsRes && linkedAccountsRes.status === 200) {
        const linkedAccounts = linkedAccountsRes.data.data;
        const otherData = linkedAccountsRes.data;

        let linkedAccountIds = linkedAccounts.map(item => { return item.id; });
        let linkedAccountDocuments = await Data.getDocumentsByEntityIds(linkedAccountIds);

        const transformedLinkedAccounts = linkedAccounts.map((linkedAccount) => { return DTO.getLinkedAccountDTO(linkedAccount, linkedAccountDocuments, entityId); });

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;

        return { data: transformedLinkedAccounts, searchParams: searchParams, pagination: pagination };
    }

    return { data: [], searchParams: searchParams, pagination: pagination };
};

export const getLinkedAccount = async (id, entityId = null) => {
    const linkedAccountsRes = await axiosAuthenticated.get(accountsPath + `/${id}`);
    if (linkedAccountsRes && linkedAccountsRes.status === 200) {
        let linkedAccount = linkedAccountsRes.data;

        let linkedAccountDocuments = await getFilesMetaData(linkedAccount.id);

        return DTO.getLinkedAccountDTO(linkedAccount, linkedAccountDocuments, entityId);
    }

    return null;
};

export const getLinkedAccountByAccountIdAndLinkedAccountId = async (accountId, linkedAccountId) => {
    const linkedAccountsRes = await axiosAuthenticated.get(accountsPath, { params: { 'links.accountId': accountId, 'id': linkedAccountId } });
    if (linkedAccountsRes && linkedAccountsRes.status === 200) {
        let linkedAccounts = linkedAccountsRes.data.data;
        if (isListNotEmpty(linkedAccounts)) {
            let linkedAccount = linkedAccounts[0];

            let linkedAccountDocuments = await getFilesMetaData(linkedAccount.id);

            return DTO.getLinkedAccountDTO(linkedAccount, linkedAccountDocuments, accountId);
        }
    }

    return null;
};

export const getLinkedAccountsByAccountId = async (accountId) => {
    if (isStringNotEmpty(accountId)) {
        const linkedAccountsRes = await axiosAuthenticated.get(accountsPath, { params: { page: 1, size: 1000000, 'links.accountId': accountId, isDeleted: false } });
        if (linkedAccountsRes && linkedAccountsRes.status === 200) {
            const linkedAccounts = linkedAccountsRes.data.data;

            let linkedAccountIds = linkedAccounts.map(item => { return item.id; });
            let linkedAccountDocuments = await Data.getDocumentsByEntityIds(linkedAccountIds);

            return linkedAccounts.map((linkedAccount) => { return DTO.getLinkedAccountDTO(linkedAccount, linkedAccountDocuments, accountId); });
        }
    }

    return [];
};

export const addLinkedAccount = async (payload) => {
    const linkedAccountsRes = await axiosAuthenticated.post(linkedAccountsPath, { ...payload });
    if (linkedAccountsRes && linkedAccountsRes.status === 201) {
        let newLinkedAccount = linkedAccountsRes.data;

        let linkedAccountDocuments = await getFilesMetaData(newLinkedAccount.id);

        return DTO.getLinkedAccountDTO(newLinkedAccount, linkedAccountDocuments, payload.accountId);
    }

    return null;
};

export const updateLinkedAccount = async (id, payload) => {
    const linkedAccountsRes = await axiosAuthenticated.put(linkedAccountsPath + `/${id}`, { ...payload });
    if (linkedAccountsRes && linkedAccountsRes.status === 200) {
        let updatedLinkedAccount = linkedAccountsRes.data;

        let linkedAccountDocuments = await getFilesMetaData(updatedLinkedAccount.id);

        return DTO.getLinkedAccountDTO(updatedLinkedAccount, linkedAccountDocuments, payload.accountId);
    }

    return null;
};

export const removeLinkedAccount = async (id) => {
    const linkedAccountsRes = await axiosAuthenticated.put(accountsPath + `/${id}`, { isDeleted: true });
    if (linkedAccountsRes && linkedAccountsRes.status === 200) {
        return linkedAccountsRes.data;
    }

    return null;
};

//#endregion