import React, { useMemo } from 'react';
import { Input, Spin, Alert } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import FormItem from '../FormItem/FormItem';
import FormButtons from '../FormButtons/FormButtons';
import Form from '../Form/Form';
import Fieldset from '../FormFieldset/FormFieldset';
import { isObjectNotEmpty } from '../../shared/objectUtils';
import FormItemAddress from '../FormItemAddress/FormItemAddress';
import FormItemPointOfContact from '../FormItemPointOfContact/FormItemPointOfContact';

const EditLinkedAccount = ({ linkedAccount, cancel }) => {
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.linkedAccounts.isRecordUpdateLoading);
    const error = useSelector(state => state.linkedAccounts.updateRecordError);

    //#endregion
    //#region onSubmit and onCancel

    const onSubmit = (data) => {
        let payload = {
            ...data,
            accountId: linkedAccount.accountId
        };

        // console.log(payload);

        if (isObjectNotEmpty(payload)) {
            dispatch(actionCreators.updateLinkedAccount(linkedAccount.id, payload));
        } else {
            onCancel();
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.updateLinkedAccountCancel());
        cancel();
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLinkedAccountErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    
    //#endregion

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Fieldset legend="Company Details">
                        <FormItem {...formItemLayout} label="Company Name" required format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Company Name" ref={ref} />}
                            rules={{ required: false }}
                            name="name"
                            defaultValue={linkedAccount && linkedAccount.name ? linkedAccount.name : ''}
                        />
                        <FormItem {...formItemLayout} label="Customer Id" tooltip="Do you have an identifier for this company outside of this system?" format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Customer Id" ref={ref} />}
                            rules={{ required: false }}
                            name="customerId"
                            defaultValue={linkedAccount && linkedAccount.customerId ? linkedAccount.customerId : ''}
                        />
                    </Fieldset>
                    <Fieldset isCollapsable={linkedAccount && linkedAccount.pointOfContact ? false : true} legend="Point of Contact">
                        <FormItemPointOfContact
                            format="horizontal"
                            required={false}
                            name="pointOfContact"
                            defaultValue={linkedAccount.pointOfContact}
                        />
                    </Fieldset>
                    <Fieldset isCollapsable={linkedAccount && (linkedAccount.accountsPayable || linkedAccount.accountsPayableAddress) ? false : true} legend="Accounts Payable">
                        <FormItemPointOfContact
                            format="horizontal"
                            required={false}
                            name="accountsPayable"
                            defaultValue={linkedAccount.accountsPayable}
                            nameFieldLabel="A/P Contact Name"
                        />
                        <FormItemAddress
                            format="horizontal"
                            required={false}
                            name="accountsPayableAddress"
                            defaultValue={linkedAccount.accountsPayableAddress}
                            isPostalAddress={true}
                        />
                    </Fieldset>
                    <Fieldset isCollapsable={linkedAccount && (linkedAccount.accountsReceivable || linkedAccount.accountsReceivableAddress) ? false : true} legend="Accounts Receivable">
                        <FormItemPointOfContact
                            format="horizontal"
                            required={false}
                            name="accountsReceivable"
                            defaultValue={linkedAccount.accountsReceivable}
                            nameFieldLabel="A/R Contact Name"
                        />
                        <FormItemAddress
                            format="horizontal"
                            required={false}
                            name="accountsReceivableAddress"
                            defaultValue={linkedAccount.accountsReceivableAddress}
                            isPostalAddress={true}
                        />
                    </Fieldset>
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Update Account" />
            </Form>
        </FormProvider>
    );
};

export default EditLinkedAccount;