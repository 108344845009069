import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { UploadOutlined, DownOutlined } from '@ant-design/icons';
import { Drawer, Button, Dropdown, Menu } from 'antd';
import EditLoadLane from "../../components/EditLoadLane/EditLoadLane";
import classes from './LoadLanes.module.scss';
import DataTable from '../../components/DataTable/DataTable';
import StringFormatter from '../../shared/stringFormatter';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools, faRoute } from "@fortawesome/free-solid-svg-icons";
import DataAddressRow from "../../components/DataAddressRow/DataAddressRow";
import NewLoadLane from '../../components/NewLoadLane/NewLoadLane';
import { withRouter } from 'react-router-dom';
import NewLoadModal from '../../components/NewLoadModal/NewLoadModal';
import { isListNotEmpty, isNumberGreaterThanZero, isObjectNotEmpty, isStringEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { CanDo } from '../../shared/entitlements/entitlements';
import { selectListIsLoading, selectListPagination, selectListRecords } from '../../store/utility';
import ColumnSearchFilter from '../../shared/tableColumnSearchFilter';

const stringFormatter = new StringFormatter();
const columnSearchFilter = new ColumnSearchFilter();

const LoadLanes = ({ accountId, ...props }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;
    const singularEntityName = "Lane";
    const pluralEntityName = "Lanes";

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);
    const records = useSelector(state => selectListRecords(state.loadLanes.lists, isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')));
    const isLoading = useSelector(state => selectListIsLoading(state.loadLanes.lists, isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')));
    const pagination = useSelector(state => selectListPagination(state.loadLanes.lists, isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')));

    //#endregion
    //#region useStates

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showNewLoad, setShowNewLoad] = useState(false);
    const [searchText, setSearchText] = useState({ customerLaneId: '' });

    //#endregion
    //#region toggles

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleNewLoad = () => {
        setShowNewLoad(!showNewLoad);
    };

    //#endregion
    //#region goTos

    const goToLoadLane = (id) => {
        props.history.push({ pathname: `/loadLanes/${id}` }, {
            previousPageTitle: "Lanes",
            previousPageLocation: props.location,
            previousBreadcrumbs: props.breadcrumbs,
        });
    };

    //#endregion
    //#region loadLane methods

    const getOrigin = (loadLane) => {
        let locationDisplay = '';
        if (isListNotEmpty(loadLane.locations)) {
            let origin = loadLane.locations[0];
            if (isObjectNotEmpty(origin.location) && isObjectNotEmpty(origin.location.address)) {
                locationDisplay = (<DataAddressRow key={`address-row-load-lane-${loadLane.id}-location-${origin.locationId}-${origin.sequence}`} title={origin.location.name} address={origin.location.address} />);
            }
        }

        return locationDisplay;
    };

    const getDestination = (loadLane) => {
        let locationDisplay = '';
        if (isListNotEmpty(loadLane.locations)) {
            let destination = loadLane.locations[loadLane.locations.length - 1];
            if (isObjectNotEmpty(destination.location) && isObjectNotEmpty(destination.location.address)) {
                locationDisplay = (<DataAddressRow key={`address-row-load-lane-${loadLane.id}-location-${destination.locationId}-${destination.sequence}`} title={destination.location.name} address={destination.location.address} />);
            }
        }

        return locationDisplay;
    };

    const getCommodities = (loadLane) => {
        let commodityList = [];
        if (isListNotEmpty(loadLane.locations)) {
            let listOfCommodities = new Set();
            loadLane.locations.forEach((stop) => {
                if (isListNotEmpty(stop.commodities)) {
                    stop.commodities.forEach((commodity) => {
                        if (isNumberGreaterThanZero(commodity.unitPackagingInitCount) || isNumberGreaterThanZero(commodity.bulkPackagingInitCount)) {
                            listOfCommodities.add(commodity.name);
                        }
                    });
                }
            });

            commodityList = Array.from(listOfCommodities);
        }

        return commodityList;
    };

    //#endregion
    //#region table methods

    const refreshTable = () => {
        let searchParams = {
            page: 1,
            size: 10,
            isDeleted: false
        };

        if (isStringNotEmpty(accountId) || (isStringNotEmpty(entityId) && entityType !== 'STAFF')) {
            searchParams.accountId = isStringNotEmpty(accountId) ? accountId : (isStringNotEmpty(entityId) && entityType !== 'STAFF' ? entityId : null);
        }

        dispatch(actionCreators.fetchLoadLaneList(isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL'), searchParams, isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : null)));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchLoadLaneListSuccess(isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL'), { params: { pagination: pager } }));

        let searchParams = {
            page: pagination.current,
            size: pagination.pageSize,
            isDeleted: false
        };

        if (isStringNotEmpty(sorter.order)) {
            const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
            searchParams.order = sortOrder;
        }
        if (isStringNotEmpty(sorter.field)) {
            searchParams.sort = sorter.field;
        }

        if (isListNotEmpty(filters.customerLaneId)) {
            searchParams['customerLaneId:contains'] = filters.customerLaneId[0];
        }

        if (isStringNotEmpty(accountId) || (isStringNotEmpty(entityId) && entityType !== 'STAFF')) {
            searchParams.accountId = isStringNotEmpty(accountId) ? accountId : (isStringNotEmpty(entityId) && entityType !== 'STAFF' ? entityId : null);
        }

        dispatch(actionCreators.fetchLoadLaneList(isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL'), searchParams, isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : null)));
    };

    //#endregion
    //#region table displays

    const menu = (record) => {
        return (
            <Menu>
                {CanDo({ entityAction: 'UPDATE', entityModel: 'LOAD_LANE', accountId: record.accountId }) && (
                    <Menu.Item key="createLoadFromLoadLane" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); toggleNewLoad(); }}>
                        <UploadOutlined style={{ marginRight: 8 }} />
                        <span>Create Load(s)</span>
                    </Menu.Item>
                )}
                {CanDo({ entityAction: 'UPDATE', entityModel: 'LOAD_LANE', accountId: record.accountId }) && (
                    <Menu.Item key="editEntity" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); toggleEditEntity(); }}>
                        <FontAwesomeIcon className="anticon" icon={faRoute} style={{ marginRight: 8 }} />
                        <span>Edit {singularEntityName} Details</span>
                    </Menu.Item>
                )}
                {CanDo({ entityAction: 'READ', entityModel: 'LOAD_LANE', accountId: record.accountId }) && (
                    <Menu.Item key="goToDashboard" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); goToLoadLane(record.id); }}>
                        <span>Go to {singularEntityName} Dashboard</span>
                    </Menu.Item>
                )}
            </Menu>
        );
    };

    const columns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            className: classes.columnNoBreak,
            width: 50,
            render: (text, record) => {
                return (
                    <Dropdown overlay={menu(record)} trigger={['click']}>
                        <Button>Actions <DownOutlined /></Button>
                    </Dropdown>
                );
            },
            align: 'center',
        },
        {
            title: 'Customer Lane Id',
            dataIndex: 'customerLaneId',
            key: 'customerLaneId',
            ...columnSearchFilter.getColumnSearchProps('customerLaneId', 'Customer Lane Id', searchText, setSearchText),
        },
        {
            title: 'Origin',
            dataIndex: 'locations',
            key: 'origin',
            render: (text, record) => { return (getOrigin(record)); }
        },
        {
            title: 'Destination',
            dataIndex: 'locations',
            key: 'destination',
            render: (text, record) => { return (getDestination(record)); }
        },
        {
            title: 'Stop Count',
            dataIndex: 'locations',
            key: 'stopCount',
            render: (text, record) => {
                if (isListNotEmpty(text)) {
                    return text.length;
                } else {
                    return 0;
                }
            }
        },
        {
            title: 'Trailer Type',
            dataIndex: ['equipmentNeeds', 'trailerType', 'description'],
            key: 'equipmentNeeds.trailerType.description',
        },
        {
            title: 'Commodities',
            dataIndex: ['locations', 'commodities'],
            key: 'commodities',
            render: (text, record) => { return (getCommodities(record)); }
        },
        {
            title: 'Approx Dist',
            dataIndex: 'approxDistance',
            key: 'approxDistance',
            render: (text, record) => { return stringFormatter.toFormattedString('Distance', text, record.approxDistanceUnit ? record.approxDistanceUnit : null); }
        },
        // {
        //     title: 'Approx Trans Time',
        //     dataIndex: 'approxTransitTime',
        //     key: 'approxTransitTime',
        // },
    ];

    if (CanDo({ staffOnly: true })) {
        columns.push({
            title: 'Per Mile Rate',
            dataIndex: 'perMileRate',
            key: 'perMileRate',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
        });

        if (isStringEmpty(accountId)) {
            columns.push({
                title: 'Account Name',
                dataIndex: ['account', 'name'],
                key: 'account.name',
            });
        }
    }

    //#endregion
    //#region displays

    const newEntityComponents = (
        <Drawer
            title={"New " + singularEntityName}
            onClose={toggleNewEntity}
            visible={showNewEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewLoadLane
                cancel={toggleNewEntity}
                accountId={isStringNotEmpty(accountId) ? accountId : (isStringNotEmpty(entityId) && entityType !== 'STAFF' ? entityId : null)}
            />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName}
            onClose={toggleEditEntity}
            visible={showEditEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditLoadLane
                cancel={toggleEditEntity}
                loadLane={selectedRecord}
            />
        </Drawer>
    );

    //#endregion
    //#region useEffects

    useEffect(() => {
        refreshTable();
    }, [accountId, entityId]);

    //#endregion

    return (
        <DataTable
            dataSource={records}
            columns={columns}
            pagination={pagination}
            onChange={handleTableChange}
            loading={isLoading === true}
            rowClassName={classes.dataTableRow}
            singularEntityName={singularEntityName}
            pluralEntityName={pluralEntityName}
            newEntityAction={CanDo({ entityAction: 'CREATE', entityModel: 'LOAD_LANE', accountId: isStringNotEmpty(accountId) ? accountId : (isStringNotEmpty(entityId) && entityType !== 'STAFF' ? entityId : null) }) ? toggleNewEntity : null}
            rowKey={record => record.id}
            style={{ backgroundColor: '#ffffff' }}
        >
            {newEntityComponents}
            {editEntityComponents}
            <NewLoadModal key='new-load' showModal={showNewLoad} toggleModal={() => { toggleNewLoad(); setSelectedRecord({}); }} shipperId={isObjectNotEmpty(selectedRecord) && isStringNotEmpty(selectedRecord.accountId) ? selectedRecord.accountId : null} loadLaneId={isObjectNotEmpty(selectedRecord) && isStringNotEmpty(selectedRecord.id) ? selectedRecord.id : null} />
        </DataTable>
    );
};

export default withRouter(LoadLanes);