import Checks from '../entitlementUtils';

export const canCreateLineItemType = (auth) => {
    return Checks.allowStaffAdmin(auth);
};

export const canReadLineItemType = (auth) => {
    return Checks.allowStaffAdmin(auth);
};

export const canReadLineItemTypeList = (auth) => {
    return Checks.allowStaffAdmin(auth);
};

export const canUpdateLineItemType = (auth) => {
    return Checks.allowStaffAdmin(auth);
};

export const canDeleteLineItemType = (auth) => {
    return Checks.allowStaffAdmin(auth);
};