import React, { useMemo } from 'react';
import { Alert, Input, Spin } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import { isObjectNotEmpty } from '../../shared/objectUtils';

const EditCommodity = ({ cancel, commodity }) => {
    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.commodities.isRecordUpdateLoading);
    const error = useSelector(state => state.commodities.updateRecordError);

    const onSubmit = (data) => {
        let payload = {};
        let objectToUpdate = { ...commodity };
        for (let [key, value] of Object.entries(data)) {
            let oldValue = objectToUpdate[key] !== undefined && objectToUpdate[key] !== null ? objectToUpdate[key] : '';
            if (value !== oldValue) {
                //console.log(`${key}: new value - ${value}, old value - ${oldValue}`);
                payload[key] = value;
            }
        }

        if (isObjectNotEmpty(payload)) {
            dispatch(actionCreators.updateCommodity(commodity.id, payload));
        } else {
            onCancel();
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.updateCommodityCancel());
        cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateCommodityErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <FormItem {...formItemLayout} label="Name" required
                        render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Name" ref={ref} />}
                        rules={{ required: "Required Field" }}
                        name="name"
                        defaultValue={commodity.name}
                    />
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Update Commodity" />
            </Form>
        </FormProvider>
    );
};

export default EditCommodity;