import * as actionTypes from "../actions/actionTypes";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { isBooleanTrue, isObjectNotEmpty } from "../../shared/objectUtils";
import ErrorUtils from "../../shared/errorUtils";

//#region Fetch Configurations Methods

export const fetchConfigurationsStart = () => {
    return {
        type: actionTypes.FETCH_CONFIGURATIONS_START
    }
};

export const fetchConfigurationsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_CONFIGURATIONS_SUCCESS,
        payload: payload
    }
};

export const fetchConfigurationsFail = (payload) => {
    return {
        type: actionTypes.FETCH_CONFIGURATIONS_FAIL,
        payload: payload
    }
};

export const clearConfigurations = () => {
    return {
        type: actionTypes.CLEAR_CONFIGURATIONS
    }
};

//#endregion
//#region Add Configuration Methods

const insertConfiguration = (payload) => {
    return {
        type: actionTypes.ADD_CONFIGURATION,
        payload: payload
    }
};

export const addConfigurationStart = () => {
    return {
        type: actionTypes.ADD_CONFIGURATION_START
    }
};

export const addConfigurationSuccess = () => {
    return {
        type: actionTypes.ADD_CONFIGURATION_SUCCESS
    }
};

export const addConfigurationFail = (payload) => {
    return {
        type: actionTypes.ADD_CONFIGURATION_FAIL,
        payload: payload
    }
};

export const addConfigurationLoadingClear = () => {
    return {
        type: actionTypes.ADD_CONFIGURATION_LOADING_CLEAR
    }
};

export const addConfigurationErrorClear = () => {
    return {
        type: actionTypes.ADD_CONFIGURATION_ERROR_CLEAR
    }
};

export const addConfigurationCancel = () => {
    return {
        type: actionTypes.ADD_CONFIGURATION_CANCEL
    }
};

//#endregion
//#region Update Configuration Methods

const changeConfiguration = (payload) => {
    return {
        type: actionTypes.UPDATE_CONFIGURATION,
        payload: payload
    }
};

const changeSingleConfiguration = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_CONFIGURATION,
        payload: payload
    }
};

export const updateConfigurationStart = () => {
    return {
        type: actionTypes.UPDATE_CONFIGURATION_START
    }
};

export const updateConfigurationSuccess = () => {
    return {
        type: actionTypes.UPDATE_CONFIGURATION_SUCCESS
    }
};

export const updateConfigurationFail = (payload) => {
    return {
        type: actionTypes.UPDATE_CONFIGURATION_FAIL,
        payload: payload
    }
};

export const updateConfigurationLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_CONFIGURATION_LOADING_CLEAR
    }
};

export const updateConfigurationErrorClear = () => {
    return {
        type: actionTypes.UPDATE_CONFIGURATION_ERROR_CLEAR
    }
};

export const updateConfigurationCancel = () => {
    return {
        type: actionTypes.UPDATE_CONFIGURATION_CANCEL
    }
};

//#endregion
//#region Remove Configuration Methods

const removeConfiguration = (payload) => {
    return {
        type: actionTypes.REMOVE_CONFIGURATION,
        payload: payload
    }
};

//#endregion
//#region Configurations Methods

export const fetchConfigurations = (payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(fetchConfigurationsStart());

            const state = getState();
            const configurationsState = { ...state.configurations };
            let pagination = { ...configurationsState.pagination };
            let searchParams = { ...configurationsState.searchParams };

            if (isObjectNotEmpty(payload)) {
                searchParams = { ...payload };
            }

            const res = await Data.getConfigurations({ ...searchParams }, pagination);
            dispatch(fetchConfigurationsSuccess({ records: res.data, searchParams: res.searchParams, pagination: res.pagination }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchConfigurationsFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const addConfiguration = (payload) => {
    return async (dispatch) => {
        try {
            dispatch(addConfigurationStart());

            const newConfiguration = await Data.addConfiguration(payload);
            if (isObjectNotEmpty(newConfiguration)) {
                dispatch(insertConfiguration(newConfiguration));
            }

            dispatch(addConfigurationSuccess());
            dispatch(addConfigurationLoadingClear());
            dispatch(addConfigurationErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addConfigurationFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateConfiguration = (id, payload) => {
    return async (dispatch) => {
        try {
            dispatch(updateConfigurationStart());

            if (isBooleanTrue(payload.isDeleted)) {
                const removedConfiguration = await Data.removeConfiguration(id);
                if (isObjectNotEmpty(removedConfiguration)) {
                    dispatch(removeConfiguration(removedConfiguration));
                }
            } else {
                const updatedConfiguration = await Data.updateConfiguration(id, payload);
                if (isObjectNotEmpty(updatedConfiguration)) {
                    dispatch(changeConfiguration(updatedConfiguration));
                    dispatch(changeSingleConfiguration(updatedConfiguration));
                }
            }

            dispatch(updateConfigurationSuccess());
            dispatch(updateConfigurationLoadingClear());
            dispatch(updateConfigurationErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateConfigurationFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion