import moment from 'moment';
import { isListNotEmpty } from '../../shared/objectUtils';

export const getScheduledShiftCalendarDTO = (scheduledShift) => {
    return {
        id: scheduledShift.id,
        title: scheduledShift.shift.name,
        start: moment(scheduledShift.startDateTime).toDate(),
        end: moment(scheduledShift.endDateTime).toDate(),
        backgroundColor: scheduledShift.shift.backgroundColor,
        borderColor: scheduledShift.shift.backgroundColor,
        textColor: scheduledShift.shift.textColor,
        timeZone: scheduledShift.shift.timeZone,
        resourceIds: isListNotEmpty(scheduledShift.accountUserIds) ? [...scheduledShift.accountUserIds] : [],
        extendedProps: scheduledShift,
        allDay: false
    };
};