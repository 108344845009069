import React, { useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { DeleteOutlined, DownOutlined, InfoOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Modal } from 'antd';
import classes from './AdminLoads.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools } from '@fortawesome/free-solid-svg-icons';
import StringFormatter from '../../shared/stringFormatter';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import DataTable from '../../components/DataTable/DataTable';
import { compareByAlph } from '../../shared/tableUtils';
import NewLoadModal from '../../components/NewLoadModal/NewLoadModal';
import { isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import ColumnSearchFilter from '../../shared/tableColumnSearchFilter';
import { Can, CanDo } from '../../shared/entitlements/entitlements';
import { selectListIsLoading, selectListPagination, selectListRecords } from '../../store/utility';
import * as Data from '../../api/data/index';
import LoadUtils from '../../api/utils/loadUtils';

const stringFormatter = new StringFormatter();
const columnSearchFilter = new ColumnSearchFilter();

const AdminLoads = ({ shipperId, carrierId, driverId, assignedAccountRepUserId, brokerId, loadLaneId = null, laneId = null, ...props }) => {
    //#region constants

    const singularEntityName = "Load";
    const pluralEntityName = "Loads";
    const filter = "ADMIN";
    const defaultLoadStatusFilter = ["CREATED", "PENDING", "APPROVED", "PENDING_RATE_CONFIRMATION", "BOOKED", "SCHEDULED", "IN_TRANSIT", "AT_STOP", "COMPLETED", "CLOSED", "REOPENED", "CANCELLED"];

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);
    const records = useSelector(state => selectListRecords(state.loads.lists, filter));
    const isLoading = useSelector(state => selectListIsLoading(state.loads.lists, filter));
    const pagination = useSelector(state => selectListPagination(state.loads.lists, filter));
    const isLoadingUpdateLoad = useSelector(state => state.loads.isRecordUpdateLoading);
    const errorUpdateLoad = useSelector(state => state.loads.updateRecordError);

    //#endregion
    //#region useStates

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showDuplicateEntity, setShowDuplicateEntity] = useState(false);
    const [showDeleteEntity, setShowDeleteEntity] = useState(false);
    const [showUndeleteEntity, setShowUndeleteEntity] = useState(false);
    const [searchText, setSearchText] = useState({ irisId: '' });

    //#endregion
    //#region goTos

    const goToLoad = (id) => {
        props.history.push({ pathname: `/loads/${id}` }, {
            previousPageTitle: isStringNotEmpty(shipperId) || isStringNotEmpty(carrierId) ? 'Loads' : 'All Loads',
            previousPageLocation: props.location,
        });
    };

    //#endregion
    //#region toggles

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleDuplicateEntity = () => {
        setShowDuplicateEntity(!showDuplicateEntity);
    };

    const toggleDeleteEntity = () => {
        setShowDeleteEntity(!showDeleteEntity);
    };

    const toggleUndeleteEntity = () => {
        setShowUndeleteEntity(!showUndeleteEntity);
    };

    //#endregion
    //#region load methods

    const deleteLoad = (loadToUpdate) => {
        if (isObjectNotEmpty(loadToUpdate)) {
            dispatch(actionCreators.deleteLoad(loadToUpdate.id));
        }
    };

    const undeleteLoad = (loadToUpdate) => {
        if (isObjectNotEmpty(loadToUpdate)) {
            dispatch(actionCreators.undeleteLoad(loadToUpdate.id));
        }
    };

    const cancel = () => {
        dispatch(actionCreators.updateLoadCancel());
        setSelectedRecord({});
    };

    const populateLoadPricing = async () => {
        let loadsRes = await Data.getLoads({ page: 1, size: 1000000 });
        let allLoads = loadsRes.data;
        let invoiceLineItemsRes = await Data.getInvoiceLineItems({ page: 1, size: 1000000 });
        let allInvoiceLineItems = invoiceLineItemsRes.data;
        let transactionsRes = await Data.getTransactions({ page: 1, size: 1000000 });
        let allTransactions = transactionsRes.data;
        let claimsRes = await Data.getClaims({ page: 1, size: 1000000 });
        let allClaims = claimsRes.data;
        for (let i = 0; i < allLoads.length; i++) {
            let filteredClaims = allClaims.filter(c => c.loadId === allLoads[i].id);
            await Data.updateLoadPricing(allLoads[i].id, allInvoiceLineItems, allTransactions, filteredClaims);
        }
    };

    //#endregion
    //#region table methods

    const refreshTable = () => {
        if (isStringNotEmpty(entityType) && isStringNotEmpty(entityId)) {
            let searchParams = {
                page: 1,
                size: 5,
                sort: 'createdAt',
                order: 'asc',
                eta: false
            };

            if (isStringNotEmpty(shipperId)) {
                searchParams.shipperId = shipperId;
            }

            if (isStringNotEmpty(carrierId)) {
                searchParams.assignedCarrierId = carrierId;
            }

            if (isStringNotEmpty(driverId)) {
                searchParams.driverIds = driverId;
            }

            if (isStringNotEmpty(assignedAccountRepUserId)) {
                searchParams.assignedAccountRepUserIds = assignedAccountRepUserId;
            }

            if (isStringNotEmpty(brokerId)) {
                searchParams.brokerId = brokerId;
            }

            if (isStringNotEmpty(loadLaneId)) {
                searchParams.loadLaneId = loadLaneId;
            }

            if (isStringNotEmpty(laneId)) {
                searchParams.laneId = laneId;
            }

            searchParams.loadStatus = defaultLoadStatusFilter.join('|');

            dispatch(actionCreators.fetchLoadList(filter, searchParams));
        }
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchLoadListSuccess(filter, { params: { pagination: pager } }));

        const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';

        let searchParams = {
            page: pagination.current,
            size: pagination.pageSize,
            sort: sorter.field,
            order: sortOrder,
            eta: false
        };

        if (isListNotEmpty(filters.loadStatus)) {
            searchParams.loadStatus = filters.loadStatus.join('|');
        } else {
            searchParams.loadStatus = defaultLoadStatusFilter.join('|');
        }

        if (isListNotEmpty(filters.irisId)) {
            searchParams['irisId:contains'] = filters.irisId[0];
        }

        if (isStringNotEmpty(shipperId)) {
            searchParams.shipperId = shipperId;
        }

        if (isStringNotEmpty(carrierId)) {
            searchParams.assignedCarrierId = carrierId;
        }

        if (isStringNotEmpty(driverId)) {
            searchParams.driverIds = driverId;
        }

        if (isStringNotEmpty(assignedAccountRepUserId)) {
            searchParams.assignedAccountRepUserIds = assignedAccountRepUserId;
        }

        if (isStringNotEmpty(brokerId)) {
            searchParams.brokerId = brokerId;
        }

        if (isStringNotEmpty(loadLaneId)) {
            searchParams.loadLaneId = loadLaneId;
        }

        if (isStringNotEmpty(laneId)) {
            searchParams.laneId = laneId;
        }

        dispatch(actionCreators.fetchLoadList(filter, searchParams));
    };

    //#endregion
    //#region table displays

    const menu = (record) => {
        return (
            <Menu>
                {CanDo({ entityAction: 'DUPLICATE', entityModel: 'LOAD', entityObject: record }) ? (
                    <Menu.Item key="duplicateLoad" onClick={(e) => { e.domEvent.stopPropagation(); e.domEvent.preventDefault(); setSelectedRecord(record); toggleDuplicateEntity(); }}>
                        <UploadOutlined style={{ marginRight: 8 }} />
                        <span>Duplicate Load</span>
                    </Menu.Item>
                ) : null}
                {CanDo({ entityAction: 'READ', entityModel: 'LOAD', entityObject: record }) ? (
                    <Menu.Item key="goToDashboard" onClick={(e) => { e.domEvent.stopPropagation(); e.domEvent.preventDefault(); setSelectedRecord(record); goToLoad(record.id); }}>
                        <InfoOutlined style={{ marginRight: 8 }} />
                        <span>View Load Details</span>
                    </Menu.Item>
                ) : null}
                {(record.isDeleted === false) && CanDo({ entityAction: 'DELETE', entityModel: 'LOAD', entityObject: record }) ? (
                    <Menu.Item key="deleteLoad" onClick={(e) => { e.domEvent.stopPropagation(); e.domEvent.preventDefault(); setSelectedRecord(record); toggleDeleteEntity(); }}>
                        <DeleteOutlined style={{ marginRight: 8 }} />
                        <span>Delete Load</span>
                    </Menu.Item>
                ) : null}
                {(record.isDeleted === true) && CanDo({ entityAction: 'DELETE', entityModel: 'LOAD', entityObject: record }) ? (
                    <Menu.Item key="undeleteLoad" onClick={(e) => { e.domEvent.stopPropagation(); e.domEvent.preventDefault(); setSelectedRecord(record); toggleUndeleteEntity(); }}>
                        <DeleteOutlined style={{ marginRight: 8 }} />
                        <span>Un-Delete Load</span>
                    </Menu.Item>
                ) : null}
            </Menu>
        );
    };

    const columns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            className: classes.columnNoBreak,
            width: 50,
            render: (text, record) => {
                return (
                    <Dropdown overlay={menu(record)} trigger={['click']}>
                        <Button>Actions <DownOutlined /></Button>
                    </Dropdown>
                );
            },
            align: 'center',
        },
        {
            title: 'Load Id',
            dataIndex: 'irisId',
            key: 'irisId',
            ...columnSearchFilter.getColumnSearchProps('irisId', 'Load Id', searchText, setSearchText)
        },
        {
            title: 'Load Name',
            dataIndex: 'parentName',
            key: 'parentName',
            render: (text, record) => { return LoadUtils.getLoadName(record); },
        },
        {
            title: 'Name',
            dataIndex: 'parentName',
            key: 'parentName',
            render: (text, record) => { return (record.parentName ? record.parentName : record.name ? record.name : '') },
        },
        {
            title: 'Pick-Up Date',
            dataIndex: 'pickUpDateTime',
            key: 'pickUpDateTime',
            sorter: (a, b) => compareByAlph(a.pickUpDateTime, b.pickUpDateTime),
            sortDirections: ['descend', 'ascend'],
            render: (text, record) => { return record.origin !== undefined && record.origin !== null ? stringFormatter.toFormattedString("MomentDate", text, null, record.origin.timeZone) : ''; },
        },
        {
            title: 'Origin',
            dataIndex: ['origin', 'stopLocation', 'name'],
            key: 'origin.stopLocation.name',
        },
        {
            title: 'Destination',
            dataIndex: ['destination', 'stopLocation', 'name'],
            key: 'destination.stopLocation.name',
        },
        {
            title: 'Type',
            dataIndex: ['equipmentNeeds', 'trailerType', 'description'],
            key: 'equipmentNeeds.trailerType.description',
        },
        {
            title: 'Length',
            dataIndex: ['equipmentNeeds', 'trailerLength'],
            key: 'equipmentNeeds.trailerLength',
            render: (text, record) => { return stringFormatter.toFormattedString("Length", text, record.equipmentNeeds.trailerLengthUnit); },
        },
        {
            title: 'Max Weight',
            dataIndex: ['equipmentNeeds', 'trailerWeightCapacity'],
            key: 'equipmentNeeds.trailerWeightCapacity',
            render: (text, record) => { return stringFormatter.toFormattedString("Weight", text, record.equipmentNeeds.trailerWeightCapacityUnit); },
        },
        {
            title: 'ETA',
            dataIndex: 'eta',
            key: 'eta',
            render: (text, record) => {
                if (LoadUtils.loadStatusIs(record.loadStatus, ['IN_TRANSIT', 'AT_STOP'])) {
                    return stringFormatter.toFormattedString("MomentDateTime", text, null, record.etaTimeZone);
                } else {
                    return 'N/A';
                }
            },
        },
        {
            title: 'Status',
            dataIndex: 'loadStatus',
            key: 'loadStatus',
            filters: [
                {
                    text: 'Created',
                    value: 'CREATED',
                },
                {
                    text: 'Pending',
                    value: 'PENDING',
                },
                {
                    text: 'Approved',
                    value: 'APPROVED',
                },
                {
                    text: 'Pending Rate Confirmation',
                    value: 'PENDING_RATE_CONFIRMATION',
                },
                {
                    text: 'Booked',
                    value: 'BOOKED',
                },
                {
                    text: 'Scheduled',
                    value: 'SCHEDULED',
                },
                {
                    text: 'In Transit',
                    value: 'IN_TRANSIT',
                },
                {
                    text: 'At Stop',
                    value: 'AT_STOP',
                },
                {
                    text: 'Completed',
                    value: 'COMPLETED',
                },
                {
                    text: 'Closed',
                    value: 'CLOSED',
                },
                {
                    text: 'Re-Opened',
                    value: 'REOPENED',
                },
                {
                    text: 'Cancelled',
                    value: 'CANCELLED',
                },
            ],
            filterMultiple: true,
            defaultFilteredValue: ["CREATED", "PENDING", "APPROVED", "PENDING_RATE_CONFIRMATION", "BOOKED", "SCHEDULED", "IN_TRANSIT", "AT_STOP", "COMPLETED", "CLOSED", "REOPENED", "CANCELLED"],
            onFilter: (value, record) => record.loadStatus.indexOf(value) === 0,
            sorter: (a, b) => compareByAlph(a.loadStatus, b.loadStatus),
            sortDirections: ['descend', 'ascend'],
            render: (text, record) => { return LoadUtils.getLoadStatusDisplay(text, entityType, record); },
        },
        {
            title: 'Service Type',
            dataIndex: 'serviceType',
            key: 'serviceType',
            render: (text, record) => { return stringFormatter.toFormattedString("LoadServiceType", text); },
        },
        {
            title: 'Shipper',
            dataIndex: ['shipper', 'name'],
            key: 'shipper.name',
        },
        {
            title: 'Carrier',
            dataIndex: ['assignedCarrier', 'name'],
            key: 'assignedCarrier.name',
        },
        {
            title: 'Shipper Rate',
            dataIndex: 'shipperAmount',
            key: 'shipperAmount',
            render: (text, record) => { return stringFormatter.toFormattedMoney(text); },
        },
        {
            title: 'Carrier Rate',
            dataIndex: 'carrierAmount',
            key: 'carrierAmount',
            render: (text, record) => { return stringFormatter.toFormattedMoney(text); },
        },
        {
            title: 'Is ParentLoad',
            dataIndex: 'isParentLoad',
            key: 'isParentLoad',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        },
        {
            title: 'Parent LoadId',
            dataIndex: 'parentLoadId',
            key: 'parentLoadId',
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: (a, b) => compareByAlph(a.createdAt, b.createdAt),
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'ascend',
            render: (text, record) => { return stringFormatter.toFormattedString("MomentDateTime", text, null); },
        },
        {
            title: 'Is Deleted',
            dataIndex: 'isDeleted',
            key: 'isDeleted',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        }
    ];

    //#endregion
    //#region useMemos and useEffects

    useEffect(() => {
        refreshTable();
    }, [shipperId, carrierId, driverId, assignedAccountRepUserId, entityType, entityId]);

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLoadErrorClear());
    }, []);

    useMemo(() => {
        if (isLoadingUpdateLoad !== null && isLoadingUpdateLoad === false && errorUpdateLoad === null) {
            setShowDeleteEntity(false);
            setShowUndeleteEntity(false);
            cancel();
        }
    }, [isLoadingUpdateLoad, errorUpdateLoad]);

    //#endregion
    //#region displays

    const deleteEntityComponents = (loadToUpdate) => {
        if (isObjectNotEmpty(loadToUpdate)) {
            return (
                <Modal
                    title={`Are you sure you want to Delete Load ${loadToUpdate.irisId}?`}
                    visible={showDeleteEntity === true}
                    onOk={(e) => { deleteLoad(loadToUpdate); }}
                    onCancel={(e) => { toggleDeleteEntity(); cancel(); }}
                    maskClosable={false}
                />
            );
        } else {
            return null;
        }
    };

    const undeleteEntityComponents = (loadToUpdate) => {
        if (isObjectNotEmpty(loadToUpdate)) {
            return (
                <Modal
                    title={`Are you sure you want to Un-Delete Load ${loadToUpdate.irisId}?`}
                    visible={showUndeleteEntity === true}
                    onOk={(e) => { undeleteLoad(loadToUpdate); }}
                    onCancel={(e) => { toggleUndeleteEntity(); cancel(); }}
                    maskClosable={false}
                />
            );
        } else {
            return null;
        }
    };

    //#endregion

    return (
        <Can adminsOnly={true}>
            {/* <div>
                <Button onClick={(e) => { populateLoadPricing(); }}>Re-Populate Loads with Pricing Data</Button>
            </div> */}
            <DataTable
                dataSource={records}
                columns={columns}
                pagination={pagination}
                onChange={handleTableChange}
                loading={isLoading === true}
                singularEntityName={singularEntityName}
                pluralEntityName={pluralEntityName}
                newEntityAction={toggleNewEntity}
                rowKey={record => record.id}
                style={{ backgroundColor: '#ffffff' }}
                scroll={{ x: '100%' }}
            >
                <NewLoadModal key='new-load' showModal={showNewEntity} toggleModal={toggleNewEntity} shipperId={isStringNotEmpty(shipperId) ? shipperId : null} />
                <NewLoadModal key='duplicate-load' showModal={showDuplicateEntity} toggleModal={() => { toggleDuplicateEntity(); setSelectedRecord({}); }} shipperId={isObjectNotEmpty(selectedRecord) && isStringNotEmpty(selectedRecord.shipperId) ? selectedRecord.shipperId : null} duplicateLoadId={isObjectNotEmpty(selectedRecord) && isStringNotEmpty(selectedRecord.id) ? selectedRecord.id : null} />
                {deleteEntityComponents(selectedRecord)}
                {undeleteEntityComponents(selectedRecord)}
            </DataTable>
        </Can>
    );
};

export default withRouter(AdminLoads);