import axiosAuthenticated from "../../api/axiosAuthenticated";
import { isNotNullOrUndefined } from "../../shared/objectUtils";
import * as DTO from "../dtos/index";

const preferredLanesPath = '/preferredLanes';
const preferredLanesBulkPath = '/preferredLanes/bulk/get';

//#region PreferredLanes Methods

export const getPreferredLanesByAccountId = async (accountId, accounts = [], trailerTypes = []) => {
    const preferredLanesRes = await axiosAuthenticated.post(preferredLanesBulkPath, { page: 1, size: 1000000, isDeleted: false, accountId: accountId });
    if (preferredLanesRes && preferredLanesRes.status === 200) {
        const preferredLanes = preferredLanesRes.data.data;

        return preferredLanes.map((preferredLane) => { return DTO.getPreferredLaneDTO(preferredLane, accounts, trailerTypes); });
    }

    return [];
};

export const getBulkPreferredLanes = async (searchParams = {}, accounts = [], trailerTypes = []) => {
    const preferredLanesRes = await axiosAuthenticated.post(preferredLanesBulkPath, { ...searchParams });
    if (preferredLanesRes && preferredLanesRes.status === 200) {
        const preferredLanes = preferredLanesRes.data.data;

        return preferredLanes.map((preferredLane) => { return DTO.getPreferredLaneDTO(preferredLane, accounts, trailerTypes); });
    }

    return [];
};

export const getPreferredLanes = async (searchParams = {}, pagination = {}, accounts = [], trailerTypes = []) => {
    const preferredLanesRes = await axiosAuthenticated.get(preferredLanesPath, { params: { ...searchParams } });
    if (preferredLanesRes && preferredLanesRes.status === 200) {
        const preferredLanes = preferredLanesRes.data.data;
        const otherData = preferredLanesRes.data;

        const transformedPreferredLanes = preferredLanes.map((preferredLane) => { return DTO.getPreferredLaneDTO(preferredLane, accounts, trailerTypes); });

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;

        return { data: transformedPreferredLanes, searchParams: searchParams, pagination: pagination };
    }

    return { data: [], searchParams: searchParams, pagination: pagination };
};

export const getPreferredLane = async (id, accounts = [], trailerTypes = []) => {
    const preferredLanesRes = await axiosAuthenticated.get(preferredLanesPath + `/${id}`);
    if (preferredLanesRes && preferredLanesRes.status === 200) {
        let preferredLane = preferredLanesRes.data;

        return DTO.getPreferredLaneDTO(preferredLane, accounts, trailerTypes);
    }

    return null;
};

export const addPreferredLane = async (payload, accounts = [], trailerTypes = []) => {
    const preferredLanesRes = await axiosAuthenticated.post(preferredLanesPath, { ...payload });
    if (preferredLanesRes && preferredLanesRes.status === 201) {
        let newPreferredLane = preferredLanesRes.data;

        return DTO.getPreferredLaneDTO(newPreferredLane, accounts, trailerTypes);
    }

    return null;
};

export const updatePreferredLane = async (id, payload, accounts = [], trailerTypes = []) => {
    const preferredLanesRes = await axiosAuthenticated.put(preferredLanesPath + `/${id}`, { ...payload });
    if (preferredLanesRes && preferredLanesRes.status === 200) {
        let updatedPreferredLane = preferredLanesRes.data;

        return DTO.getPreferredLaneDTO(updatedPreferredLane, accounts, trailerTypes);
    }

    return null;
};

export const removePreferredLane = async (id) => {
    const preferredLanesRes = await axiosAuthenticated.put(preferredLanesPath + `/${id}`, { isDeleted: true });
    if (preferredLanesRes && preferredLanesRes.status === 200) {
        return preferredLanesRes.data;
    }

    return null;
};

//#endregion