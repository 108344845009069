import React from 'react';
import DataRow from '../DataRow/DataRow';
import { Col, Row } from 'antd';
import { isStringNotEmpty } from '../../shared/objectUtils';

const TrailerType = ({ trailerType }) => {
    return (
        <Row>
            <Col xs={24} md={24} lg={24} xl={24}>
                {isStringNotEmpty(trailerType.name) ? (<DataRow title="Name" value={trailerType.name} dataType="String" />) : null}
                {isStringNotEmpty(trailerType.description) ? (<DataRow title="Display Name" value={trailerType.description} dataType="String" />) : null}
            </Col>
        </Row>
    );
};

export default TrailerType;