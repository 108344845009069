import Checks from '../entitlementUtils';

export const canCreateLoadDriver = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadCarrier(load, auth);
};

export const canReadLoadDriver = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadCarrier(load, auth);
};

export const canReadLoadDriverList = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadCarrier(load, auth);
};

export const canUpdateLoadDriver = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadCarrier(load, auth);
};

export const canDeleteLoadDriver = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadCarrier(load, auth);
};