import Checks from '../entitlementUtils';

export const canCreateLoadCarrier = (auth, load) => {
    return Checks.allowStaff(auth) || (Checks.isLoadShipper(load, auth) && Checks.isLoadTMS(load));
};

export const canReadLoadCarrier = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadCarrier(load, auth) || (Checks.isLoadShipper(load, auth) && Checks.isLoadTMS(load));
};

export const canReadLoadCarrierList = (auth, load) => {
    return Checks.allowStaff(auth) || (Checks.isLoadShipper(load, auth) && Checks.isLoadTMS(load));
};

export const canUpdateLoadCarrier = (auth, load) => {
    return Checks.allowStaff(auth) || (Checks.isLoadShipper(load, auth) && Checks.isLoadTMS(load));
};

export const canDeleteLoadCarrier = (auth, load) => {
    return Checks.allowStaff(auth) || (Checks.isLoadShipper(load, auth) && Checks.isLoadTMS(load));
};