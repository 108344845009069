import * as actionTypes from "../actions/actionTypes";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { isBooleanTrue, isObjectNotEmpty } from "../../shared/objectUtils";
import * as actionCreators from "./index";
import ErrorUtils from "../../shared/errorUtils";

//#region Fetch Features Methods

export const fetchFeaturesStart = () => {
    return {
        type: actionTypes.FETCH_FEATURES_START
    }
};

export const fetchFeaturesSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_FEATURES_SUCCESS,
        payload: payload
    }
};

export const fetchFeaturesFail = (payload) => {
    return {
        type: actionTypes.FETCH_FEATURES_FAIL,
        payload: payload
    }
};

export const clearFeatures = () => {
    return {
        type: actionTypes.CLEAR_FEATURES
    }
};

//#endregion
//#region Add Feature Methods

const insertFeature = (payload) => {
    return {
        type: actionTypes.ADD_FEATURE,
        payload: payload
    }
};

export const addFeatureStart = () => {
    return {
        type: actionTypes.ADD_FEATURE_START
    }
};

export const addFeatureSuccess = () => {
    return {
        type: actionTypes.ADD_FEATURE_SUCCESS
    }
};

export const addFeatureFail = (payload) => {
    return {
        type: actionTypes.ADD_FEATURE_FAIL,
        payload: payload
    }
};

export const addFeatureLoadingClear = () => {
    return {
        type: actionTypes.ADD_FEATURE_LOADING_CLEAR
    }
};

export const addFeatureErrorClear = () => {
    return {
        type: actionTypes.ADD_FEATURE_ERROR_CLEAR
    }
};

export const addFeatureCancel = () => {
    return {
        type: actionTypes.ADD_FEATURE_CANCEL
    }
};

//#endregion
//#region Update Feature Methods

const changeFeature = (payload) => {
    return {
        type: actionTypes.UPDATE_FEATURE,
        payload: payload
    }
};

const changeSingleFeature = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_FEATURE,
        payload: payload
    }
};

export const updateFeatureStart = () => {
    return {
        type: actionTypes.UPDATE_FEATURE_START
    }
};

export const updateFeatureSuccess = () => {
    return {
        type: actionTypes.UPDATE_FEATURE_SUCCESS
    }
};

export const updateFeatureFail = (payload) => {
    return {
        type: actionTypes.UPDATE_FEATURE_FAIL,
        payload: payload
    }
};

export const updateFeatureLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_FEATURE_LOADING_CLEAR
    }
};

export const updateFeatureErrorClear = () => {
    return {
        type: actionTypes.UPDATE_FEATURE_ERROR_CLEAR
    }
};

export const updateFeatureCancel = () => {
    return {
        type: actionTypes.UPDATE_FEATURE_CANCEL
    }
};

//#endregion
//#region Remove Feature Methods

const removeFeature = (payload) => {
    return {
        type: actionTypes.REMOVE_FEATURE,
        payload: payload
    }
};

//#endregion
//#region Features Methods

export const fetchFeatures = (payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(fetchFeaturesStart());

            const state = getState();
            const featuresState = { ...state.features };
            let pagination = { ...featuresState.pagination };
            let searchParams = { ...featuresState.searchParams };

            if (isObjectNotEmpty(payload)) {
                searchParams = { ...payload };
            }

            const res = await Data.getFeatures({ ...searchParams }, pagination);
            dispatch(fetchFeaturesSuccess({ records: res.data, searchParams: res.searchParams, pagination: res.pagination }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchFeaturesFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const addFeature = (payload) => {
    return async (dispatch) => {
        try {
            dispatch(addFeatureStart());

            const newFeature = await Data.addFeature(payload);
            if (isObjectNotEmpty(newFeature)) {
                dispatch(insertFeature(newFeature));

                // refresh the feature orchestrator
                dispatch(actionCreators.getFeatures(true));
            }

            dispatch(addFeatureSuccess());
            dispatch(addFeatureLoadingClear());
            dispatch(addFeatureErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addFeatureFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateFeature = (id, payload) => {
    return async (dispatch) => {
        try {
            dispatch(updateFeatureStart());

            if (isBooleanTrue(payload.isDeleted)) {
                const removedFeature = await Data.removeFeature(id);
                if (isObjectNotEmpty(removedFeature)) {
                    dispatch(removeFeature(removedFeature));

                    // refresh the feature orchestrator
                    dispatch(actionCreators.getFeatures(true));
                }
            } else {
                const updatedFeature = await Data.updateFeature(id, payload);
                if (isObjectNotEmpty(updatedFeature)) {
                    dispatch(changeFeature(updatedFeature));
                    dispatch(changeSingleFeature(updatedFeature));

                    // refresh the feature orchestrator
                    dispatch(actionCreators.getFeatures(true));
                }
            }

            dispatch(updateFeatureSuccess());
            dispatch(updateFeatureLoadingClear());
            dispatch(updateFeatureErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateFeatureFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion