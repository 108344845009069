import React, { useMemo } from 'react';
import { Select, Alert, Spin, Input } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Enums from '../../shared/enums';
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import { isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import moment from 'moment';
import DatePicker from '../DatePickerAutoAccept/DatePickerAutoAccept';

const EditTransactionPaymentStatus = ({ transaction, load, invoices, cancel, loading = false }) => {
    const paymentStatusOptions = Enums.PaymentStatuses.selectListOptions();

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.transactions.isRecordUpdateLoading);
    const error = useSelector(state => state.transactions.updateRecordError);

    const onSubmit = (data) => {
        if (isObjectNotEmpty(data) && isObjectNotEmpty(load) && isObjectNotEmpty(transaction)) {
            let invoice = null;
            if (isListNotEmpty(invoices) && isStringNotEmpty(transaction.invoiceId)) {
                invoice = invoices.find(i => i.isDeleted === false && i.id === transaction.invoiceId);
            }

            dispatch(actionCreators.updateTransaction(transaction.id, data, transaction, load.id, invoice, true));
        } else {
            onCancel();
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.updateTransactionCancel());
        cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateTransactionErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true && error === null) || loading === true}>
                    <Fieldset legend="Update Payment Status of Transaction">
                        <FormItem {...formItemLayout} label="Transaction External Reference Id" format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Transaction External Reference Id" ref={ref} />}
                            rules={{ required: false }}
                            name="qbTransactionId"
                            defaultValue={transaction.qbTransactionId}
                        />
                        <FormItem {...formItemLayout} label="Payment Status" required
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select a Payment Status"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                >
                                    {paymentStatusOptions}
                                </Select>
                            )}
                            rules={{ required: 'Required Field' }}
                            name="paymentStatus"
                            defaultValue={transaction.paymentStatus}
                        />
                        <FormItem {...formItemLayout} label="Paid At" format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <DatePicker
                                    placeholder="Paid At"
                                    format="YYYY-MM-DD"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={(date, dateString) => { onChange(date); }}
                                    value={value}
                                    name={name}
                                    onSelect={(date) => { onChange(date); }}
                                    ref={ref}
                                />
                            )}
                            rules={{ required: false }}
                            name="paidAt"
                            defaultValue={transaction.paidAt ? moment(transaction.paidAt) : null}
                        />
                    </Fieldset>
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Update Transaction" />
            </Form>
        </FormProvider>
    );
};

export default EditTransactionPaymentStatus;