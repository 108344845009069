import React, { Fragment } from 'react';
import StringFormatter from '../../shared/stringFormatter';
import irisFullLogo from '../../assets/img/BannerIrisFreight@5x.png';
import { isBooleanTrue, isListNotEmpty, isNotNullOrUndefined, isNumberGreaterThanZero, isNumberNotEmpty, isObjectNotEmpty, isStringEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { Page, Text, View, Document, StyleSheet, Image, Link, Font, pdf } from '@react-pdf/renderer';
import { addFile, deleteFile } from '../fileClient';
import { getBulkDocuments, getDocumentsByEntityIds } from '../data';
import { sendTemplatedEmail } from '../emailClient';
import logger from '../../shared/logger';

const stringFormatter = new StringFormatter();

const irisPhoneNumber = '4809007454';
const irisFaxNumber = '4807814027';
const irisCompanyName = 'IRIS TECHNOLOGIES, INC.';
const irisPaymentAddress = {
    streetAddress1: "1014 N Kennebec St",
    city: "Arlington",
    state: "VA",
    postalCode: "22205"
};
const irisCompanyAddress = {
    streetAddress1: "1014 N Kennebec St",
    city: "Arlington",
    state: "VA",
    postalCode: "22205"
};
const irisPointOfContact = {
    phone: '4809007454'
};
const irisAccountId = '60c235de8ca130a3ab2306bc';

//#region pdf styles

// Register font
const registerFonts = () => {
    try {
        Font.register({
            family: 'Roboto', fonts: [
                { src: '/fonts/roboto/Roboto-Thin.ttf', fontStyle: 'normal', fontWeight: 'thin' },
                { src: '/fonts/roboto/Roboto-ThinItalic.ttf', fontStyle: 'italic', fontWeight: 'thin' },
                { src: '/fonts/roboto/Roboto-Light.ttf', fontStyle: 'normal', fontWeight: 'light' },
                { src: '/fonts/roboto/Roboto-LightItalic.ttf', fontStyle: 'italic', fontWeight: 'light' },
                { src: '/fonts/roboto/Roboto-Regular.ttf', fontStyle: 'normal', fontWeight: 'normal' },
                { src: '/fonts/roboto/Roboto-Italic.ttf', fontStyle: 'italic', fontWeight: 'normal' },
                { src: '/fonts/roboto/Roboto-Medium.ttf', fontStyle: 'normal', fontWeight: 'medium' },
                { src: '/fonts/roboto/Roboto-MediumItalic.ttf', fontStyle: 'italic', fontWeight: 'medium' },
                { src: '/fonts/roboto/Roboto-Bold.ttf', fontStyle: 'normal', fontWeight: 'bold' },
                { src: '/fonts/roboto/Roboto-BoldItalic.ttf', fontStyle: 'italic', fontWeight: 'bold' },
                { src: '/fonts/roboto/Roboto-Black.ttf', fontStyle: 'normal', fontWeight: 'heavy' },
                { src: '/fonts/roboto/Roboto-BlackItalic.ttf', fontStyle: 'italic', fontWeight: 'heavy' },
            ]
        });
    } catch (err) {
        console.log(err);
    }
    try {
        Font.registerEmojiSource({
            format: 'png',
            url: 'https://twemoji.maxcdn.com/2/72x72/',
        });
    } catch (err) {
        console.log(err);
    }
};

// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#FFFFFF',
        padding: 20,
        alignItems: 'stretch'
    },
    text: {
        fontSize: 10,
        fontWeight: 'normal'
    },
    boldText: {
        fontFamily: 'Roboto',
        fontSize: 10,
        fontWeight: 'bold'
    },
    header: {
        flexDirection: 'row',
        alignItems: 'stretch',
        marginBottom: 20
    },
    headerWithBorder: {
        flexDirection: 'row',
        borderBottomWidth: 2,
        borderBottomColor: '#112131',
        borderBottomStyle: 'solid',
        alignItems: 'stretch',
        marginBottom: 20
    },
    fromColumn: {
        flexDirection: 'column',
        flex: 1,
    },
    invoiceTypeColumn: {
        flexDirection: 'column',
        flex: 1,
    },
    invoiceTypeText: {
        fontFamily: 'Roboto',
        fontSize: 16,
        textTransform: 'uppercase',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    invoiceTypeSubText: {
        fontFamily: 'Roboto',
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    detailsRow: {
        display: 'flex',
        marginBottom: 4,
        flexDirection: 'row',
    },
    detailsColumn: {
        display: 'flex',
        flexDirection: 'column',
        flex: 2,
    },
    detailsDataColumn: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        padding: '0 2px',
        alignItems: 'center',
        justifyContent: 'center',
        // textAlign: 'center'
    },
    invoiceHeaderTitle: {
        flexDirection: 'column',
        padding: '2px 5px',
        backgroundColor: '#333333',
        color: '#ffffff',
        textAlign: 'center',
        width: '100%'
    },
    invoiceHeaderData: {
        flexDirection: 'column',
        padding: '2px 5px',
        textAlign: 'center',
        color: '#000000',
        width: '100%'
    },
    detailsText: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: 8,
        alignSelf: 'center',
        textAlign: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        fontWeight: 'normal',
        width: '100%'
    },
    row: {
        display: 'flex',
        marginTop: 10,
        marginBottom: 10,
        flexDirection: 'row',
        //flex: 1
    },
    col: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    table: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '100%',
        marginTop: 10,
        marginBottom: 10,
        color: '#000000'
    },
    tableHeadRow: {
        //display: 'flex',
        flexDirection: 'row'
    },
    tableHeadCol: {
        //display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#333333',
        color: '#ffffff',
        fontFamily: 'Roboto',
        fontWeight: 'bold'
    },
    tableRow: {
        //display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid #ddd'
    },
    tableCol: {
        display: 'flex',
        flexDirection: 'column'
    },
    tableCell: {
        flexDirection: 'column',
        padding: '5px'
    },
    tableFootRow: {
        //display: 'flex',
        flexDirection: 'row',
        borderTop: '2px solid #333333'
    },
    tableFootCol: {
        //display: 'flex',
        flexDirection: 'column'
    },
    textRight: {
        textAlign: 'right',
        // alignItems: 'flex-end',
        // justifyContent: 'flex-end'
    },
    textCenter: {
        textAlign: 'center',
        // alignItems: 'center',
        // justifyContent: 'center'
    },
    textLeft: {
        textAlign: 'left',
        // alignItems: 'flex-start',
        // justifyContent: 'flex-start'
    },
    strikeThrough: {
        textDecoration: 'line-through'
    },
    invoiceDetailsData: {
        padding: '2px 8px',
        lineHeight: '1.5'
    },
    sectionColumn: {
        flexDirection: 'column',
        flex: 1,
        padding: '0 2px'
    },
    sectionRow: {
        marginBottom: 4,
        flexDirection: 'row',
        // flexGrow: 1
    },
    sectionHeader: {
        flexDirection: 'column',
        padding: '2px 5px',
        backgroundColor: '#333333',
        color: '#ffffff',
        textAlign: 'left',
        fontFamily: 'Roboto',
        fontWeight: 'bold'
    },
    sectionHeaderText: {
        color: '#ffffff',
        lineHeight: '1.5',
        fontSize: 10,
    },
    sectionBody: {
        padding: '2px 8px',
        lineHeight: '1.5'
    },
});

//#endregion
//#region invoice methods

const getBOLList = (stops) => {
    let BOLList = [];
    if (isListNotEmpty(stops)) {
        let listOfBOLs = new Set();
        stops.forEach((stop) => {
            if (isListNotEmpty(stop.bolNumbers)) {
                stop.bolNumbers.forEach((bolNumber) => {
                    listOfBOLs.add(bolNumber);
                });
            }
        });
        BOLList = Array.from(listOfBOLs);
    }

    return BOLList;
};

const getPurchaseOrderNumberList = (stops) => {
    let purchaseOrderNumberList = [];
    if (isListNotEmpty(stops)) {
        let listOfPurchaseOrderNumbers = new Set();
        stops.forEach((stop) => {
            if (isStringNotEmpty(stop.purchaseOrderNumber)) {
                listOfPurchaseOrderNumbers.add(stop.purchaseOrderNumber);
            }
        });
        purchaseOrderNumberList = Array.from(listOfPurchaseOrderNumbers);
    }

    return purchaseOrderNumberList;
};

const getCommodityList = (stops) => {
    let commodityList = [];
    if (isListNotEmpty(stops)) {
        let listOfCommodities = new Set();
        stops.forEach((stop) => {
            if (isListNotEmpty(stop.commodities)) {
                stop.commodities.forEach((commodity) => {
                    if (isNumberGreaterThanZero(commodity.unitPackagingInitCount) || isNumberGreaterThanZero(commodity.bulkPackagingInitCount)) {
                        listOfCommodities.add(commodity.name);
                    }
                });
            }
        });

        commodityList = Array.from(listOfCommodities);
    }

    return commodityList;
};

const getPickUpStopList = (stops) => {
    let pickUpStopList = [];
    if (isListNotEmpty(stops)) {
        pickUpStopList = stops.filter(stop => stop.stopType === 'PICK_UP');
    }

    return pickUpStopList;
};

const getDropOffStopList = (stops) => {
    let dropOffStopList = [];
    if (isListNotEmpty(stops)) {
        dropOffStopList = stops.filter(stop => stop.stopType === 'DROP_OFF');
    }

    return dropOffStopList;
};

// fromEntityType is the entity who is sending payment to the toEntityType, not the entity who is sending the invoice. Typically the entities are flipped (i.e. staff sends invoice to shipper but shipper pays staff based on the invoice)
const getInvoiceLineItemsAndTotal = (invoiceType, invoiceLineItems, fromEntityType, toEntityType) => {
    let total = 0.00;
    let lineItemsList = [];
    let invoiceLineItemList = [];
    if (isStringNotEmpty(invoiceType) && isListNotEmpty(invoiceLineItems) && isStringNotEmpty(fromEntityType) && isStringNotEmpty(toEntityType)) {
        let irisFees = 0.00;
        let irisBaseFees = 0.00;
        let irisQuantity = 0;

        invoiceLineItemList = invoiceLineItems.filter(i => ((i.fromEntityType === fromEntityType && i.toEntityType === toEntityType) || (i.toEntityType === fromEntityType && i.fromEntityType === toEntityType)) && i.isDeleted === false);
        invoiceLineItemList.forEach((invoiceLineItem) => {
            if (((invoiceLineItem.isIrisFee === true && invoiceLineItem.quantityUnit === 'PER_MILE') || invoiceLineItem.quantityUnit === 'PER_MILE') && invoiceLineItem.status !== 'CANCELLED') {
                if (invoiceLineItem.fromEntityType === fromEntityType) {
                    irisBaseFees += invoiceLineItem.baseAmount;
                    irisFees += invoiceLineItem.totalAmount;
                    irisQuantity = invoiceLineItem.quantity;
                } else if (invoiceLineItem.toEntityType === fromEntityType) {
                    irisBaseFees -= invoiceLineItem.baseAmount;
                    irisFees -= invoiceLineItem.totalAmount;
                    irisQuantity = invoiceLineItem.quantity;
                }
            } else {
                lineItemsList.push({
                    description: invoiceLineItem.description,
                    baseAmount: invoiceLineItem.fromEntityType === fromEntityType ? invoiceLineItem.baseAmount : -1.0 * invoiceLineItem.baseAmount,
                    quantity: invoiceLineItem.quantity,
                    totalAmount: invoiceLineItem.fromEntityType === fromEntityType ? invoiceLineItem.totalAmount : -1.0 * invoiceLineItem.totalAmount,
                    isCancelled: invoiceLineItem.status === 'CANCELLED'
                });
            }

            if (invoiceLineItem.status !== 'CANCELLED') {
                if (invoiceLineItem.fromEntityType === fromEntityType) {
                    total += invoiceLineItem.totalAmount;
                } else if (invoiceLineItem.toEntityType === fromEntityType) {
                    total -= invoiceLineItem.totalAmount;
                }
            }
        });
        // if (invoiceType === 'PRICE_CONFIRMATION' || invoiceType === 'SHIPPER_INVOICE') {
        //     invoiceLineItemList = invoiceLineItems.filter(i => (i.fromEntityType === 'SHIPPER' || i.toEntityType === 'SHIPPER') && i.isDeleted === false);

        //     invoiceLineItemList.forEach((invoiceLineItem) => {
        //         if (((invoiceLineItem.isIrisFee === true && invoiceLineItem.quantityUnit === 'PER_MILE') || invoiceLineItem.quantityUnit === 'PER_MILE') && invoiceLineItem.status !== 'CANCELLED') {
        //             if (invoiceLineItem.fromEntityType === 'SHIPPER') {
        //                 irisBaseFees += invoiceLineItem.baseAmount;
        //                 irisFees += invoiceLineItem.totalAmount;
        //                 irisQuantity = invoiceLineItem.quantity;
        //             } else if (invoiceLineItem.toEntityType === 'SHIPPER') {
        //                 irisBaseFees -= invoiceLineItem.baseAmount;
        //                 irisFees -= invoiceLineItem.totalAmount;
        //                 irisQuantity = invoiceLineItem.quantity;
        //             }
        //         } else {
        //             lineItemsList.push({
        //                 description: invoiceLineItem.description,
        //                 baseAmount: invoiceLineItem.fromEntityType === 'SHIPPER' ? invoiceLineItem.baseAmount : -1.0 * invoiceLineItem.baseAmount,
        //                 quantity: invoiceLineItem.quantity,
        //                 totalAmount: invoiceLineItem.fromEntityType === 'SHIPPER' ? invoiceLineItem.totalAmount : -1.0 * invoiceLineItem.totalAmount,
        //                 isCancelled: invoiceLineItem.status === 'CANCELLED'
        //             });
        //         }

        //         if (invoiceLineItem.status !== 'CANCELLED') {
        //             if (invoiceLineItem.fromEntityType === 'SHIPPER') {
        //                 total += invoiceLineItem.totalAmount;
        //             } else if (invoiceLineItem.toEntityType === 'SHIPPER') {
        //                 total -= invoiceLineItem.totalAmount;
        //             }
        //         }
        //     });
        // } else if (invoiceType === 'RATE_CONFIRMATION' || invoiceType === 'CARRIER_INVOICE') {
        //     invoiceLineItemList = invoiceLineItems.filter(i => (i.fromEntityType === 'CARRIER' || i.toEntityType === 'CARRIER') && i.isDeleted === false);

        //     invoiceLineItemList.forEach((invoiceLineItem) => {
        //         if (((invoiceLineItem.isIrisFee === true && invoiceLineItem.quantityUnit === 'PER_MILE') || invoiceLineItem.quantityUnit === 'PER_MILE') && invoiceLineItem.status !== 'CANCELLED') {
        //             if (invoiceLineItem.fromEntityType === 'CARRIER') {
        //                 irisBaseFees -= invoiceLineItem.baseAmount;
        //                 irisFees -= invoiceLineItem.totalAmount;
        //                 irisQuantity = invoiceLineItem.quantity;
        //             } else if (invoiceLineItem.toEntityType === 'CARRIER') {
        //                 irisBaseFees += invoiceLineItem.baseAmount;
        //                 irisFees += invoiceLineItem.totalAmount;
        //                 irisQuantity = invoiceLineItem.quantity;
        //             }
        //         } else {
        //             lineItemsList.push({
        //                 description: invoiceLineItem.description,
        //                 baseAmount: invoiceLineItem.toEntityType === 'CARRIER' ? invoiceLineItem.baseAmount : -1.0 * invoiceLineItem.baseAmount,
        //                 quantity: invoiceLineItem.quantity,
        //                 totalAmount: invoiceLineItem.toEntityType === 'CARRIER' ? invoiceLineItem.totalAmount : -1.0 * invoiceLineItem.totalAmount,
        //                 isCancelled: invoiceLineItem.status === 'CANCELLED'
        //             });
        //         }

        //         if (invoiceLineItem.status !== 'CANCELLED') {
        //             if (invoiceLineItem.fromEntityType === 'CARRIER') {
        //                 total -= invoiceLineItem.totalAmount;
        //             } else if (invoiceLineItem.toEntityType === 'CARRIER') {
        //                 total += invoiceLineItem.totalAmount;
        //             }
        //         }
        //     });
        // }

        if (irisFees > 0 || irisFees < 0) {
            lineItemsList.push({ description: "IRIS Fees Per Mile", baseAmount: irisBaseFees > 0 ? irisBaseFees : -1.0 * irisBaseFees, quantity: irisQuantity, totalAmount: irisFees > 0 ? irisFees : -1.0 * irisFees });
        }
    }

    return {
        totalAmount: total,
        lineItems: lineItemsList,
        invoiceLineItemList: invoiceLineItemList
    }
};

const saveInvoiceAsDocument = async (invoiceType, invoice, invoiceLineItems, load, stops, displayManualInstructions = false) => {
    let newFile = null;
    if (isStringNotEmpty(invoiceType) && isObjectNotEmpty(invoice) && isObjectNotEmpty(load) && isListNotEmpty(stops)) {
        if (invoiceType === 'PRICE_CONFIRMATION') {
            const myPdf = pdf(<PriceConfirmationTemplate invoice={invoice} invoiceLineItems={invoiceLineItems} load={load} stops={stops} displayManualInstructions={displayManualInstructions} />); // important, without an argument it blows up
            // myPdf.updateContainer(<PriceConfirmationTemplate invoice={invoice} invoiceLineItems={invoiceLineItems} load={load} stops={stops} displayManualInstructions={displayManualInstructions} />);
            const blob = await myPdf.toBlob();
            // console.log(blob)
            let file = new File([blob], `Load Tender for Load ${load.irisId}.pdf`);
            // console.log(file)
            const existingDocuments = await getDocumentsByEntityIds([load.id]);
            let existingDocument = existingDocuments.find(d => d.documentType === "PRICE_CONFIRMATION_BLANK" && d.isDeleted === false);
            if (isObjectNotEmpty(existingDocument)) {
                await deleteFile(existingDocument.id);
            }
            newFile = await addFile(file, 'LOAD', load.id, "PRICE_CONFIRMATION_BLANK", "Blank Load Tender", "", "SHIPPER", null, true, "APPROVED", null, [{ entityType: 'LOAD', entityId: load.id }, { entityType: 'INVOICE', entityId: invoice.id }]);
        } else if (invoiceType === 'RATE_CONFIRMATION') {
            const myPdf = pdf(<RateConfirmationTemplate invoice={invoice} invoiceLineItems={invoiceLineItems} load={load} stops={stops} displayManualInstructions={displayManualInstructions} />); // important, without an argument it blows up
            // myPdf.updateContainer();
            const blob = await myPdf.toBlob();
            // console.log(blob)
            let file = new File([blob], `Rate Confirmation for Load ${load.irisId}.pdf`);
            // console.log(file)
            const existingDocuments = await getDocumentsByEntityIds([load.id]);
            let existingDocument = existingDocuments.find(d => d.documentType === "RATE_CONFIRMATION_BLANK" && d.isDeleted === false);
            if (isObjectNotEmpty(existingDocument)) {
                await deleteFile(existingDocument.id);
            }
            newFile = await addFile(file, 'LOAD', load.id, "RATE_CONFIRMATION_BLANK", "Blank Rate Confirmation", "", "CARRIER", null, true, "APPROVED", null, [{ entityType: 'LOAD', entityId: load.id }, { entityType: 'INVOICE', entityId: invoice.id }]);
        } else if (invoiceType === 'SHIPPER_INVOICE') {
            const myPdf = pdf(<ShipperInvoiceTemplate invoice={invoice} invoiceLineItems={invoiceLineItems} load={load} stops={stops} />); // important, without an argument it blows up
            // myPdf.updateContainer(<ShipperInvoiceTemplate invoice={invoice} invoiceLineItems={invoiceLineItems} load={load} stops={stops} />);
            const blob = await myPdf.toBlob();
            // console.log(blob)
            let file = new File([blob], `Shipper Invoice for Load ${load.irisId}.pdf`);
            // console.log(file)
            const existingDocuments = await getDocumentsByEntityIds([invoice.id]);
            let existingDocument = existingDocuments.find(d => d.documentType === "SHIPPER_INVOICE" && d.isDeleted === false);
            if (isObjectNotEmpty(existingDocument)) {
                await deleteFile(existingDocument.id);
            }
            newFile = await addFile(file, 'LOAD', load.id, "SHIPPER_INVOICE", "Shipper Invoice", "", "SHIPPER", null, true, "APPROVED", null, [{ entityType: 'LOAD', entityId: load.id }, { entityType: 'INVOICE', entityId: invoice.id }]);
        } else if (invoiceType === 'CARRIER_INVOICE') {
            const myPdf = pdf(<CarrierInvoiceTemplate invoice={invoice} invoiceLineItems={invoiceLineItems} load={load} stops={stops} />); // important, without an argument it blows up
            // myPdf.updateContainer(<CarrierInvoiceTemplate invoice={invoice} invoiceLineItems={invoiceLineItems} load={load} stops={stops} />);
            const blob = await myPdf.toBlob();
            // console.log(blob)
            let file = new File([blob], `Carrier Invoice for Load ${load.irisId}.pdf`);
            // console.log(file)
            const existingDocuments = await getDocumentsByEntityIds([invoice.id]);
            let existingDocument = existingDocuments.find(d => d.documentType === "CARRIER_INVOICE" && d.isDeleted === false);
            if (isObjectNotEmpty(existingDocument)) {
                await deleteFile(existingDocument.id);
            }
            newFile = await addFile(file, 'LOAD', load.id, "CARRIER_INVOICE", "Carrier Invoice", "", "CARRIER", null, true, "APPROVED", null, [{ entityType: 'LOAD', entityId: load.id }, { entityType: 'INVOICE', entityId: invoice.id }]);
        }
    }

    return newFile;
};

const sendRateConfirmationEmail = async (fromEmail, toEmails, invoice, load, stops, document) => {
    if (isStringNotEmpty(fromEmail) && isListNotEmpty(toEmails) && isObjectNotEmpty(invoice) && isObjectNotEmpty(load) && isListNotEmpty(stops) && isObjectNotEmpty(document)) {
        let emailPayload = {
            fromEmail: fromEmail,
            toEmails: toEmails,
            subject: 'Rate Confirmation ' + load.irisId + ' from IRIS Freight',
            trailerDetails: isObjectNotEmpty(load.equipmentNeeds) && isObjectNotEmpty(load.equipmentNeeds.trailerType) && isStringNotEmpty(load.equipmentNeeds.trailerType.description) && isNumberNotEmpty(load.equipmentNeeds.trailerLength) ? load.equipmentNeeds.trailerType.description + ', ' + stringFormatter.toFormattedString("Length", load.equipmentNeeds.trailerLength, load.equipmentNeeds.trailerLengthUnit) : '',
            numberOfPickUpStops: stops.filter(s => s.stopType === 'PICK_UP').length.toString(),
            numberOfDropOffStops: stops.filter(s => s.stopType === 'DROP_OFF').length.toString(),
            loadId: load.id,
            loadIrisId: load.irisId,
            originState: isObjectNotEmpty(load.origin) && isObjectNotEmpty(load.origin.stopLocation) && isObjectNotEmpty(load.origin.stopLocation.address) && isStringNotEmpty(load.origin.stopLocation.address.state) ? load.origin.stopLocation.address.state : '',
            originCity: isObjectNotEmpty(load.origin) && isObjectNotEmpty(load.origin.stopLocation) && isObjectNotEmpty(load.origin.stopLocation.address) && isStringNotEmpty(load.origin.stopLocation.address.city) ? load.origin.stopLocation.address.city : '',
            destinationState: isObjectNotEmpty(load.destination) && isObjectNotEmpty(load.destination.stopLocation) && isObjectNotEmpty(load.destination.stopLocation.address) && isStringNotEmpty(load.destination.stopLocation.address.state) ? load.destination.stopLocation.address.state : '',
            destinationCity: isObjectNotEmpty(load.destination) && isObjectNotEmpty(load.destination.stopLocation) && isObjectNotEmpty(load.destination.stopLocation.address) && isStringNotEmpty(load.destination.stopLocation.address.city) ? load.destination.stopLocation.address.city : '',
            invoiceId: invoice.id,
            documentId: document.id,
            documentFileName: document.fileName,
            documentFileType: document.fileType,
            practicalDistance: isNumberNotEmpty(load.practicalDistance) ? stringFormatter.toFormattedString("Distance", load.practicalDistance, load.practicalDistanceUnit) : '',
            rate: isNumberNotEmpty(invoice.amountDue) ? invoice.amountDue.toString() : ''
        };
        const res = await sendTemplatedEmail("RATE_CONFIRMATION", emailPayload);
        return res;
    } else {
        return false;
    }
};

const sendPriceConfirmationEmail = async (fromEmail, toEmails, invoice, load, stops, document) => {
    if (isStringNotEmpty(fromEmail) && isListNotEmpty(toEmails) && isObjectNotEmpty(invoice) && isObjectNotEmpty(load) && isListNotEmpty(stops) && isObjectNotEmpty(document)) {
        let emailPayload = {
            fromEmail: fromEmail,
            toEmails: toEmails,
            subject: 'Load Tender ' + load.irisId + ' from IRIS Freight',
            trailerDetails: isObjectNotEmpty(load.equipmentNeeds) && isObjectNotEmpty(load.equipmentNeeds.trailerType) && isStringNotEmpty(load.equipmentNeeds.trailerType.description) && isNumberNotEmpty(load.equipmentNeeds.trailerLength) ? load.equipmentNeeds.trailerType.description + ', ' + stringFormatter.toFormattedString("Length", load.equipmentNeeds.trailerLength, load.equipmentNeeds.trailerLengthUnit) : '',
            numberOfPickUpStops: stops.filter(s => s.stopType === 'PICK_UP').length.toString(),
            numberOfDropOffStops: stops.filter(s => s.stopType === 'DROP_OFF').length.toString(),
            loadId: load.id,
            loadIrisId: load.irisId,
            originState: isObjectNotEmpty(load.origin) && isObjectNotEmpty(load.origin.stopLocation) && isObjectNotEmpty(load.origin.stopLocation.address) && isStringNotEmpty(load.origin.stopLocation.address.state) ? load.origin.stopLocation.address.state : '',
            originCity: isObjectNotEmpty(load.origin) && isObjectNotEmpty(load.origin.stopLocation) && isObjectNotEmpty(load.origin.stopLocation.address) && isStringNotEmpty(load.origin.stopLocation.address.city) ? load.origin.stopLocation.address.city : '',
            destinationState: isObjectNotEmpty(load.destination) && isObjectNotEmpty(load.destination.stopLocation) && isObjectNotEmpty(load.destination.stopLocation.address) && isStringNotEmpty(load.destination.stopLocation.address.state) ? load.destination.stopLocation.address.state : '',
            destinationCity: isObjectNotEmpty(load.destination) && isObjectNotEmpty(load.destination.stopLocation) && isObjectNotEmpty(load.destination.stopLocation.address) && isStringNotEmpty(load.destination.stopLocation.address.city) ? load.destination.stopLocation.address.city : '',
            invoiceId: invoice.id,
            documentId: document.id,
            documentFileName: document.fileName,
            documentFileType: document.fileType,
            practicalDistance: isNumberNotEmpty(load.practicalDistance) ? stringFormatter.toFormattedString("Distance", load.practicalDistance, load.practicalDistanceUnit) : '',
            rate: isNumberNotEmpty(invoice.amountDue) ? invoice.amountDue.toString() : ''
        };
        const res = await sendTemplatedEmail("PRICE_CONFIRMATION", emailPayload);
        return res;
    } else {
        return false;
    }
};

const sendShipperInvoiceEmail = async (fromEmail, toEmails, invoice, load, stops, document, attachments) => {
    if (isStringNotEmpty(fromEmail) && isListNotEmpty(toEmails) && isObjectNotEmpty(invoice) && isObjectNotEmpty(load) && isListNotEmpty(stops) && isObjectNotEmpty(document)) {
        let emailPayload = {
            fromEmail: fromEmail,
            toEmails: toEmails,
            subject: 'Invoice ' + invoice.irisId + ' from IRIS Freight',
            trailerDetails: isObjectNotEmpty(load.equipmentNeeds) && isObjectNotEmpty(load.equipmentNeeds.trailerType) && isStringNotEmpty(load.equipmentNeeds.trailerType.description) && isNumberNotEmpty(load.equipmentNeeds.trailerLength) ? load.equipmentNeeds.trailerType.description + ', ' + stringFormatter.toFormattedString("Length", load.equipmentNeeds.trailerLength, load.equipmentNeeds.trailerLengthUnit) : '',
            numberOfPickUpStops: stops.filter(s => s.stopType === 'PICK_UP').length.toString(),
            numberOfDropOffStops: stops.filter(s => s.stopType === 'DROP_OFF').length.toString(),
            loadId: load.id,
            loadIrisId: load.irisId,
            originState: isObjectNotEmpty(load.origin) && isObjectNotEmpty(load.origin.stopLocation) && isObjectNotEmpty(load.origin.stopLocation.address) && isStringNotEmpty(load.origin.stopLocation.address.state) ? load.origin.stopLocation.address.state : '',
            originCity: isObjectNotEmpty(load.origin) && isObjectNotEmpty(load.origin.stopLocation) && isObjectNotEmpty(load.origin.stopLocation.address) && isStringNotEmpty(load.origin.stopLocation.address.city) ? load.origin.stopLocation.address.city : '',
            destinationState: isObjectNotEmpty(load.destination) && isObjectNotEmpty(load.destination.stopLocation) && isObjectNotEmpty(load.destination.stopLocation.address) && isStringNotEmpty(load.destination.stopLocation.address.state) ? load.destination.stopLocation.address.state : '',
            destinationCity: isObjectNotEmpty(load.destination) && isObjectNotEmpty(load.destination.stopLocation) && isObjectNotEmpty(load.destination.stopLocation.address) && isStringNotEmpty(load.destination.stopLocation.address.city) ? load.destination.stopLocation.address.city : '',
            invoiceId: invoice.id,
            documentId: document.id,
            documentFileName: document.fileName,
            documentFileType: document.fileType,
            attachments: attachments,
            practicalDistance: isNumberNotEmpty(load.practicalDistance) ? stringFormatter.toFormattedString("Distance", load.practicalDistance, load.practicalDistanceUnit) : '',
            rate: isNumberNotEmpty(invoice.amountDue) ? invoice.amountDue.toString() : ''
        };
        const res = await sendTemplatedEmail("SHIPPER_INVOICE", emailPayload);
        return res;
    } else {
        return false;
    }
};

// const sendRateConfirmation = async (invoice, load, stops, template, document) => {
//     if (isObjectNotEmpty(invoice) && isObjectNotEmpty(load) && isListNotEmpty(stops)) {
//         const myPdf = pdf([]); // important, without an argument it blows up
//         myPdf.updateContainer(template);
//         const blob = await myPdf.toBlob();
//         // console.log(blob)
//         let file = new File([blob], `Rate Confirmation for Load ${load.irisId}.pdf`);
//         // console.log(file)
//         const existingDocuments = await getDocumentsByEntityIds([load.id]);
//         let existingDocument = existingDocuments.find(d => d.documentType === "RATE_CONFIRMATION" && d.isDeleted === false);
//         if (isObjectNotEmpty(existingDocument)) {
//             await deleteFile(existingDocument.id);
//         }
//         const newFile = await addFile(file, 'LOAD', load.id, "RATE_CONFIRMATION", "Rate Confirmation", "", "CARRIER", null, true, "APPROVED", null);
//         if (isObjectNotEmpty(newFile)) {
//             // send email newFile.link
//             let emailPayload = {
//                 name: 'Garrett Lang',
//                 trailerDetails: isObjectNotEmpty(load) && isObjectNotEmpty(load.equipmentNeeds) && isObjectNotEmpty(load.equipmentNeeds.trailerType) && isStringNotEmpty(load.equipmentNeeds.trailerType.description) && isNumberNotEmpty(load.equipmentNeeds.trailerLength) ? load.equipmentNeeds.trailerType.description + ', ' + stringFormatter.toFormattedString("Length", load.equipmentNeeds.trailerLength, load.equipmentNeeds.trailerLengthUnit) : '',
//                 numberOfPickUpStops: isListNotEmpty(stops) ? stops.filter(s => s.stopType === 'PICK_UP').length.toString() : '',
//                 numberOfDropOffStops: isListNotEmpty(stops) ? stops.filter(s => s.stopType === 'DROP_OFF').length.toString() : '',
//                 loadId: isObjectNotEmpty(load) && isStringNotEmpty(load.id) ? load.id : null,
//                 loadIrisId: isObjectNotEmpty(load) && isStringNotEmpty(load.irisId) ? load.irisId : null,
//                 originState: isObjectNotEmpty(load) && isObjectNotEmpty(load.origin) && isObjectNotEmpty(load.origin.stopLocation) && isObjectNotEmpty(load.origin.stopLocation.address) && isStringNotEmpty(load.origin.stopLocation.address.state) ? load.origin.stopLocation.address.state : '',
//                 originCity: isObjectNotEmpty(load) && isObjectNotEmpty(load.origin) && isObjectNotEmpty(load.origin.stopLocation) && isObjectNotEmpty(load.origin.stopLocation.address) && isStringNotEmpty(load.origin.stopLocation.address.city) ? load.origin.stopLocation.address.city : '',
//                 destinationState: isObjectNotEmpty(load) && isObjectNotEmpty(load.destination) && isObjectNotEmpty(load.destination.stopLocation) && isObjectNotEmpty(load.destination.stopLocation.address) && isStringNotEmpty(load.destination.stopLocation.address.state) ? load.destination.stopLocation.address.state : '',
//                 destinationCity: isObjectNotEmpty(load) && isObjectNotEmpty(load.destination) && isObjectNotEmpty(load.destination.stopLocation) && isObjectNotEmpty(load.destination.stopLocation.address) && isStringNotEmpty(load.destination.stopLocation.address.city) ? load.destination.stopLocation.address.city : '',
//                 invoiceId: '32362462',
//                 documentId: newFile.id,
//                 documentFileName: newFile.fileName,
//                 documentFileType: newFile.fileType,
//                 practicalDistance: isObjectNotEmpty(load) && isNumberNotEmpty(load.practicalDistance) ? stringFormatter.toFormattedString("Distance", load.practicalDistance, load.practicalDistanceUnit) : '',
//                 rate: isObjectNotEmpty(invoice) && isNumberNotEmpty(invoice.amountDue) ? invoice.amountDue.toString() : ''
//             };
//             sendTemplatedEmail("RATE_CONFIRMATION", "info@irisfreight.com", emailPayload).then(res => {
//                 // console.log(res);
//             }).catch(err => {
//                 logger.logDebugEvent('Invoice.js', err.message, true);
//             });
//         }
//     }
// };

// const saveInvoiceAsDocument = async (invoice, load, stops, template) => {
//     if (isObjectNotEmpty(invoice) && isObjectNotEmpty(load) && isListNotEmpty(stops)) {
//         const myPdf = pdf([]); // important, without an argument it blows up
//         myPdf.updateContainer(template);
//         const blob = await myPdf.toBlob();
//         // console.log(blob)
//         let file = new File([blob], `Rate Confirmation for Load ${load.irisId}.pdf`);
//         // console.log(file)
//         const existingDocuments = await getDocumentsByEntityIds([load.id]);
//         let existingDocument = existingDocuments.find(d => d.documentType === "RATE_CONFIRMATION" && d.isDeleted === false);
//         if (isObjectNotEmpty(existingDocument)) {
//             await deleteFile(existingDocument.id);
//         }
//         const newFile = await addFile(file, 'LOAD', load.id, "RATE_CONFIRMATION", "Rate Confirmation", "", "CARRIER", null, true, "APPROVED", null);
//         if (isObjectNotEmpty(newFile)) {
//             // send email newFile.link
//             let emailPayload = {
//                 name: 'Garrett Lang',
//                 trailerDetails: isObjectNotEmpty(load) && isObjectNotEmpty(load.equipmentNeeds) && isObjectNotEmpty(load.equipmentNeeds.trailerType) && isStringNotEmpty(load.equipmentNeeds.trailerType.description) && isNumberNotEmpty(load.equipmentNeeds.trailerLength) ? load.equipmentNeeds.trailerType.description + ', ' + stringFormatter.toFormattedString("Length", load.equipmentNeeds.trailerLength, load.equipmentNeeds.trailerLengthUnit) : '',
//                 numberOfPickUpStops: isListNotEmpty(stops) ? stops.filter(s => s.stopType === 'PICK_UP').length.toString() : '',
//                 numberOfDropOffStops: isListNotEmpty(stops) ? stops.filter(s => s.stopType === 'DROP_OFF').length.toString() : '',
//                 loadId: isObjectNotEmpty(load) && isStringNotEmpty(load.id) ? load.id : null,
//                 loadIrisId: isObjectNotEmpty(load) && isStringNotEmpty(load.irisId) ? load.irisId : null,
//                 originState: isObjectNotEmpty(load) && isObjectNotEmpty(load.origin) && isObjectNotEmpty(load.origin.stopLocation) && isObjectNotEmpty(load.origin.stopLocation.address) && isStringNotEmpty(load.origin.stopLocation.address.state) ? load.origin.stopLocation.address.state : '',
//                 originCity: isObjectNotEmpty(load) && isObjectNotEmpty(load.origin) && isObjectNotEmpty(load.origin.stopLocation) && isObjectNotEmpty(load.origin.stopLocation.address) && isStringNotEmpty(load.origin.stopLocation.address.city) ? load.origin.stopLocation.address.city : '',
//                 destinationState: isObjectNotEmpty(load) && isObjectNotEmpty(load.destination) && isObjectNotEmpty(load.destination.stopLocation) && isObjectNotEmpty(load.destination.stopLocation.address) && isStringNotEmpty(load.destination.stopLocation.address.state) ? load.destination.stopLocation.address.state : '',
//                 destinationCity: isObjectNotEmpty(load) && isObjectNotEmpty(load.destination) && isObjectNotEmpty(load.destination.stopLocation) && isObjectNotEmpty(load.destination.stopLocation.address) && isStringNotEmpty(load.destination.stopLocation.address.city) ? load.destination.stopLocation.address.city : '',
//                 invoiceId: '32362462',
//                 documentId: newFile.id,
//                 documentFileName: newFile.fileName,
//                 documentFileType: newFile.fileType,
//                 practicalDistance: isObjectNotEmpty(load) && isNumberNotEmpty(load.practicalDistance) ? stringFormatter.toFormattedString("Distance", load.practicalDistance, load.practicalDistanceUnit) : '',
//                 rate: isObjectNotEmpty(invoice) && isNumberNotEmpty(invoice.amountDue) ? invoice.amountDue.toString() : ''
//             };
//             sendTemplatedEmail("RATE_CONFIRMATION", "info@irisfreight.com", emailPayload).then(res => {
//                 // console.log(res);
//             }).catch(err => {
//                 logger.logDebugEvent('Invoice.js', err.message, true);
//             });
//         }
//     }
// };

//#endregion
//#region table displays

const invoiceColumns = [
    {
        title: 'Description',
        key: 'description',
        textAlign: 'left',
        dataType: 'String',
        colSpan: 1
    },
    {
        title: 'Units',
        key: 'quantity',
        textAlign: 'center',
        dataType: 'Decimal',
        colSpan: 1
    },
    {
        title: 'Rate',
        key: 'baseAmount',
        textAlign: 'center',
        dataType: 'Money',
        colSpan: 1
    },
    {
        title: 'Amount',
        key: 'totalAmount',
        textAlign: 'center',
        dataType: 'Money',
        colSpan: 1
    },
];

const commodityColumns = [
    {
        title: 'Commodity',
        key: 'name',
        textAlign: 'center',
        dataType: 'String',
        colSpan: 1
    },
    {
        title: 'Unit of Measure/Packaging',
        key: 'unitPackagingInitCount',
        textAlign: 'center',
        dataType: 'UnitPackagingCount',
        units: 'unitPackaging',
        colSpan: 1
    },
    {
        title: 'Bulk Packaging',
        key: 'bulkPackagingInitCount',
        textAlign: 'center',
        dataType: 'BulkPackagingCount',
        units: 'bulkPackaging',
        colSpan: 1
    },
];

//#endregion
//#region invoice displays

const Row = (props) => {
    return (
        <View style={props.style ? { ...styles.row, ...props.style } : styles.row} wrap={false}>
            {props.children}
        </View>
    );
};

const Col = (props) => {
    return (
        <View style={props.style ? { ...styles.col, ...props.style } : styles.col}>
            {props.children}
        </View>
    );
};

const InvoiceCell = ({ dataType, value, units = null, timeZone = null, textAlign, isCancelled = false, colSpan = 1 }) => {

    let formattedValue = stringFormatter.toFormattedString(dataType, value, units, timeZone);

    if (textAlign === 'center') {
        return (
            <View style={{ ...styles.tableCol, flex: colSpan }}>
                <View style={{ ...styles.tableCell, ...styles.textCenter }}>
                    <Text style={isCancelled === true ? { ...styles.text, ...styles.strikeThrough } : styles.text}>{formattedValue}</Text>
                </View>
            </View>
        );
    } else if (textAlign === 'right') {
        return (
            <View style={{ ...styles.tableCol, flex: colSpan }}>
                <View style={{ ...styles.tableCell, ...styles.textRight }}>
                    <Text style={isCancelled === true ? { ...styles.text, ...styles.strikeThrough } : styles.text}>{formattedValue}</Text>
                </View>
            </View>
        );
    } else {
        return (
            <View style={{ ...styles.tableCol, flex: colSpan }}>
                <View style={{ ...styles.tableCell, ...styles.textLeft }}>
                    <Text style={isCancelled === true ? { ...styles.text, ...styles.strikeThrough } : styles.text}>{formattedValue}</Text>
                </View>
            </View>
        );
    }
};

const InvoiceRows = ({ data, columns }) => {
    let dataRows = [];
    let i = 0;
    for (const row of data) {
        let dataRow = [];
        columns.forEach((column, index) => {
            let key = column.key;
            dataRow.push(<InvoiceCell key={`invoice-cell-${index}`} value={row[key]} colSpan={(column.colSpan ? column.colSpan : 1)} columnKey={column.key} dataType={(column.dataType ? column.dataType : 'String')} textAlign={(column.textAlign ? column.textAlign : 'left')} isCancelled={isBooleanTrue(row.isCancelled)} />);
        });

        dataRows.push(<View style={styles.tableRow} key={`invoice-row-${i}`}>{dataRow}</View>);
        i++;
    }

    return dataRows;
};

const InvoiceHeaderRow = ({ columns }) => {
    let dataItems = [];
    let i = 0;
    for (const column of columns) {
        let cellStyle = { ...styles.tableCell };
        if (column.textAlign === 'left') {
            cellStyle = { ...cellStyle, ...styles.textLeft };
        }
        if (column.textAlign === 'center') {
            cellStyle = { ...cellStyle, ...styles.textCenter };
        }
        if (column.textAlign === 'right') {
            cellStyle = { ...cellStyle, ...styles.textRight };
        }
        if (isObjectNotEmpty(column.style)) {
            cellStyle = { ...cellStyle, ...column.style };
        }

        dataItems.push(
            <View key={`header-column-${i}`} style={{ ...styles.tableHeadCol, flex: column.colSpan ? column.colSpan : 1 }}>
                <View style={cellStyle}>
                    <Text style={styles.boldText}>{column.title}</Text>
                </View>
            </View>
        );
        i++;
    }

    return (
        <View style={styles.tableHeadRow}>{dataItems}</View>
    );
};

const InvoiceFooterRow = ({ totalTitleStyle = {}, totalTitle = 'Total', totalStyle = {}, total = 0 }) => {
    return (
        <View style={{ ...styles.tableFootRow, borderTop: '2px solid #333333' }}>
            <View style={{ ...styles.tableFootCol, flex: 1 }}>
            </View>
            <View style={{ ...styles.tableFootCol, flex: 1 }}>
            </View>
            <View style={{ ...styles.tableFootCol, flex: 1 }}>
                <View style={{ ...styles.tableCell, ...styles.textLeft, ...totalTitleStyle }}>
                    <Text style={styles.boldText}>{totalTitle}</Text>
                </View>
            </View>
            <View style={{ ...styles.tableFootCol, flex: 1 }}>
                <View style={{ ...styles.tableCell, ...styles.textCenter, ...totalStyle }}>
                    <Text style={styles.boldText}>{stringFormatter.toFormattedMoney(total)}</Text>
                </View>
            </View>
        </View>
    );
};

const InvoiceTable = ({ columns, invoiceLineItems, totalAmount = 0, totalTitle = 'Total', totalTitleStyle = {}, totalStyle = {} }) => {
    if (isListNotEmpty(columns)) {
        return (
            <View style={styles.table}>
                <InvoiceHeaderRow columns={columns} />
                <InvoiceRows data={invoiceLineItems} columns={columns} />
                <InvoiceFooterRow total={totalAmount} totalTitle={totalTitle} totalStyle={totalStyle} totalTitleStyle={totalTitleStyle} />
            </View>
        );
    } else {
        return null;
    }
};

const CommodityTableCell = ({ dataType, value, units = null, timeZone = null, textAlign, colSpan = 1 }) => {

    let formattedValue = stringFormatter.toFormattedString(dataType, value, units, timeZone);

    if (textAlign === 'center') {
        return (
            <View style={{ ...styles.tableCol, ...styles.textCenter, flex: colSpan }}>
                <Text style={styles.text}>{formattedValue}</Text>
            </View>
        );
    } else if (textAlign === 'right') {
        return (
            <View style={{ ...styles.tableCol, ...styles.textRight, flex: colSpan }}>
                <Text style={styles.text}>{formattedValue}</Text>
            </View>
        );
    } else {
        return (
            <View style={{ ...styles.tableCol, ...styles.textLeft, flex: colSpan }}>
                <Text style={styles.text}>{formattedValue}</Text>
            </View>
        );
    }
};

const CommodityTableRows = ({ data, columns }) => {
    let dataRows = [];
    let i = 0;
    for (const row of data) {
        let dataRow = [];
        columns.forEach((column, index) => {
            let key = column.key;
            let units = column.units;
            dataRow.push(<CommodityTableCell key={`commodity-cell-${index}`} value={row[key]} colSpan={(column.colSpan ? column.colSpan : 1)} columnKey={column.key} dataType={(column.dataType ? column.dataType : 'String')} textAlign={(column.textAlign ? column.textAlign : 'left')} units={row[units]} />);
        });

        dataRows.push(<View style={styles.tableRow} key={`commodity-row-${i}`}>{dataRow}</View>);
        i++;
    }

    return dataRows;
};

const CommodityTableHeaderRow = ({ columns }) => {
    let dataItems = [];
    let i = 0;
    for (const column of columns) {
        let cellStyle = { ...styles.tableCell };
        if (column.textAlign === 'left') {
            cellStyle = { ...cellStyle, ...styles.textLeft };
        }
        if (column.textAlign === 'center') {
            cellStyle = { ...cellStyle, ...styles.textCenter };
        }
        if (column.textAlign === 'right') {
            cellStyle = { ...cellStyle, ...styles.textRight };
        }
        if (isObjectNotEmpty(column.style)) {
            cellStyle = { ...cellStyle, ...column.style };
        }

        dataItems.push(
            <View key={`header-column-${i}`} style={{ ...styles.tableHeadCol, flex: column.colSpan ? column.colSpan : 1 }}>
                <View style={cellStyle}>
                    <Text style={styles.boldText}>{column.title}</Text>
                </View>
            </View>
        );
        i++;
    }

    return (
        <View style={styles.tableHeadRow}>{dataItems}</View>
    );
};

const CommodityTable = ({ stop, columns }) => {
    if (isListNotEmpty(columns) && isObjectNotEmpty(stop)) {
        return (
            <View style={styles.table}>
                <CommodityTableHeaderRow columns={columns} />
                <CommodityTableRows data={stop.commodities} columns={columns} />
            </View>
        );
    } else {
        return null;
    }
};

const RowText = ({ style = styles.text, title, value, dataType, units = null, timeZone = null }) => {
    return (
        <Text style={style}>{`${isStringNotEmpty(title) ? title + ': ' : ''}${stringFormatter.toFormattedString(dataType, value, units, timeZone)}`}</Text>
    );
};

const RowPhoneNumberText = ({ style = styles.text, title, phoneNumber, phoneNumberExtension = null }) => {
    return (
        <Text style={style}>{`${isStringNotEmpty(title) ? title + ': ' : ''}${stringFormatter.toFormattedPhoneNumber(phoneNumber, phoneNumberExtension)}`}</Text>
    );
};

const RowAddressText = ({ style = styles.text, title, address }) => {
    if (isObjectNotEmpty(address)) {
        return (
            <>
                {isStringNotEmpty(title) ? (<Text style={style}>{title}:</Text>) : null}
                {isStringNotEmpty(address.streetAddress1) ? (<Text style={{ ...style, paddingLeft: 8 }}>{address.streetAddress1}</Text>) : null}
                {isStringNotEmpty(address.streetAddress2) ? (<Text style={{ ...style, paddingLeft: 8 }}>{address.streetAddress2}</Text>) : null}
                {(isStringNotEmpty(address.city) || isStringNotEmpty(address.state) || isStringNotEmpty(address.postalCode) || isStringNotEmpty(address.country)) ? (<Text style={{ ...style, paddingLeft: 8 }}>{`${isStringNotEmpty(address.city) ? address.city + ', ' : ''}${isStringNotEmpty(address.state) ? address.state + ' ' : ''}${isStringNotEmpty(address.postalCode) ? address.postalCode + ' ' : ''}${isStringNotEmpty(address.country) ? address.country : ''}`}</Text>) : null}
            </>
        );
    } else {
        return null;
    }
};

const RowContactText = ({ style = styles.text, title, contact }) => {
    if (isObjectNotEmpty(contact)) {
        return (
            <>
                {isStringNotEmpty(title) ? (<Text style={style}>{title}:</Text>) : null}
                {isStringNotEmpty(contact.firstName) && isStringNotEmpty(contact.lastName) ? (<Text style={{ ...style, paddingLeft: 8 }}>{contact.firstName} {contact.lastName}</Text>) : null}
                {isStringNotEmpty(contact.email) ? (<Text style={{ ...style, paddingLeft: 8 }}>{contact.email}</Text>) : null}
                {isStringNotEmpty(contact.phone) ? (<Text style={{ ...style, paddingLeft: 8 }}>{stringFormatter.toFormattedPhoneNumber(contact.phone, contact.phoneExt)}</Text>) : null}
            </>
        );
    } else {
        return null;
    }
};

const RowListText = ({ style = styles.text, title, data = [], dataType, units = null, timeZone = null }) => {
    if (isListNotEmpty(data)) {
        return (
            <>
                {isStringNotEmpty(title) ? (<Text style={style}>{title}:</Text>) : null}
                {data.map((dataItem, index) => {
                    return (
                        <Text style={{ ...style, paddingLeft: 8 }} key={`row-list-text-${title}-${index}`}>{stringFormatter.toFormattedString(dataType, dataItem, units, timeZone)}</Text>
                    );
                })}
            </>
        );
    } else {
        return null;
    }
};

const RowTemperatureRangeText = ({ style = styles.text, title, min, minUnits, max, maxUnits }) => {
    return (
        <Text style={style}>{`${isStringNotEmpty(title) ? title + ': ' : ''}${stringFormatter.toFormattedString('Temperature', min, minUnits, null)} - ${stringFormatter.toFormattedString('Temperature', max, maxUnits, null)}`}</Text>
    );
};

//#endregion
//#region invoice templates

const ShipperInvoiceTemplate = ({ invoice, load, stops, invoiceLineItems = [] }) => {
    const { totalAmount, lineItems } = getInvoiceLineItemsAndTotal(invoice.invoiceType, invoiceLineItems, invoice.toEntityType, invoice.fromEntityType);
    const bolNumbers = getBOLList(stops);
    const purchaseOrderNumbers = getPurchaseOrderNumberList(stops);

    return (
        <Document
            author="IRIS Freight"
            title="Invoice"
        >
            <Page size="A4" style={styles.page}>
                {isObjectNotEmpty(invoice) ? (
                    <>
                        <View style={styles.header} wrap={false}>
                            <View style={styles.fromColumn}>
                                <Image src={irisFullLogo} style={{ width: 100, marginBottom: 12 }} />
                                <Text style={styles.text}>{invoice.fromName}</Text>
                                <Text style={styles.text}>{invoice.fromAddress.streetAddress1}</Text>
                                <Text style={styles.text}>{invoice.fromAddress.city}, {invoice.fromAddress.state} {invoice.fromAddress.postalCode}</Text>
                                <Text style={styles.text}>{isObjectNotEmpty(invoice.fromPointOfContact) && isStringNotEmpty(invoice.fromPointOfContact.phone) ? stringFormatter.toFormattedPhoneNumber(invoice.fromPointOfContact.phone) : null}</Text>
                            </View>
                            <View style={styles.invoiceTypeColumn}>
                                <Text style={styles.invoiceTypeText}>Invoice</Text>
                            </View>
                            <View style={styles.detailsColumn}>
                                <View style={styles.detailsRow}>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderTitle}>
                                            <Text style={styles.detailsText}>INVOICE #</Text>
                                        </View>
                                    </View>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderTitle}>
                                            <Text style={styles.detailsText}>INVOICE DATE</Text>
                                        </View>
                                    </View>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderTitle}>
                                            <Text style={styles.detailsText}>ORDER #</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.detailsRow}>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderData}>
                                            <Text style={styles.detailsText}>{invoice.irisId}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderData}>
                                            <Text style={styles.detailsText}>{stringFormatter.toFormattedString('MomentDate', invoice.invoiceDate)}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderData}>
                                            <Text style={styles.detailsText}>{invoice.customerOrderNumber}</Text>
                                        </View>
                                    </View>
                                </View>

                                <View style={styles.detailsRow}>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderTitle}>
                                            <Text style={styles.detailsText}>BOL #</Text>
                                        </View>
                                    </View>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderTitle}>
                                            <Text style={styles.detailsText}>PO #</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.detailsRow}>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderData}>
                                            {isListNotEmpty(bolNumbers) ? bolNumbers.map((bolNumber, index) => (
                                                <Text key={`stop-bol-${index}`} style={styles.detailsText}>{bolNumber}</Text>
                                            )) : null}
                                        </View>
                                    </View>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderData}>
                                            {isListNotEmpty(purchaseOrderNumbers) ? purchaseOrderNumbers.map((purchaseOrderNumber, index) => (
                                                <Text key={`stop-po-${index}`} style={styles.detailsText}>{purchaseOrderNumber}</Text>
                                            )) : null}
                                        </View>
                                    </View>
                                </View>

                                <View style={styles.detailsRow}>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderTitle}>
                                            <Text style={styles.detailsText}>SHIP DATE</Text>
                                        </View>
                                    </View>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderTitle}>
                                            <Text style={styles.detailsText}>DELIVER DATE</Text>
                                        </View>
                                    </View>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderTitle}>
                                            <Text style={styles.detailsText}>LOAD ID</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.detailsRow}>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderData}>
                                            <Text style={styles.detailsText}>{isObjectNotEmpty(load) && isObjectNotEmpty(load.origin) ? stringFormatter.toFormattedString('MomentDate', load.origin.endDateTime, null, load.origin.timeZone) : null}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderData}>
                                            <Text style={styles.detailsText}>{isObjectNotEmpty(load) && isObjectNotEmpty(load.destination) ? stringFormatter.toFormattedString('MomentDate', load.destination.endDateTime, null, load.destination.timeZone) : null}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderData}>
                                            <Text style={styles.detailsText}>{isObjectNotEmpty(load) && isStringNotEmpty(load.irisId) ? stringFormatter.toFormattedString('String', load.irisId) : null}</Text>
                                        </View>
                                    </View>
                                </View>

                            </View>
                        </View>

                        <Row>
                            <Col style={{ marginLeft: 100 }}>
                                <Text style={{ ...styles.boldText, marginBottom: 8 }}>BILLED TO:</Text>
                                {isStringNotEmpty(invoice.toName) ? <Text style={{ ...styles.text, textTransform: 'uppercase' }}>{stringFormatter.toFormattedString('String', invoice.toName)}</Text> : null}
                                {isObjectNotEmpty(invoice.toPointOfContact) && isStringNotEmpty(invoice.toPointOfContact.name) ? <Text style={styles.text}>{stringFormatter.toFormattedString('String', invoice.toPointOfContact.name)}</Text> : null}
                                {isObjectNotEmpty(invoice.toAddress) ? (
                                    <>
                                        <Text style={styles.text}>{invoice.toAddress.streetAddress1}</Text>
                                        <Text style={styles.text}>{invoice.toAddress.city}, {invoice.toAddress.state} {invoice.toAddress.postalCode}</Text>
                                    </>
                                ) : null}
                            </Col>
                            {isListNotEmpty(invoice.consignees) ? (
                                <Col>
                                    <Text style={{ ...styles.boldText, marginBottom: 8 }}>CONSIGNEE:</Text>
                                    {invoice.consignees.map((consignee, index) => (
                                        <Fragment key={`consignee-row-${index}`}>
                                            {isStringNotEmpty(consignee.name) ? <Text style={{ ...styles.text, textTransform: 'uppercase' }}>{stringFormatter.toFormattedString('String', consignee.name)}</Text> : null}

                                            {isObjectNotEmpty(consignee.address) ? (
                                                <>
                                                    <Text style={styles.text}>{consignee.address.streetAddress1}</Text>
                                                    <Text style={styles.text}>{consignee.address.city}, {consignee.address.state} {consignee.address.postalCode}</Text>
                                                </>
                                            ) : null}
                                        </Fragment>
                                    ))}
                                </Col>
                            ) : null}
                        </Row>

                        <Row>
                            <Col style={{ paddingLeft: 20, paddingRight: 20 }}>
                                <Text style={{ ...styles.boldText, marginBottom: 8 }}>INVOICE:</Text>

                            </Col>
                        </Row>

                        <InvoiceTable
                            columns={invoiceColumns}
                            invoiceLineItems={lineItems}
                            totalAmount={totalAmount}
                            totalTitle="AMOUNT DUE"
                            totalTitleStyle={{ textAlign: 'right' }}
                            totalStyle={{ textAlign: 'center', border: '2px solid #333333' }}
                        />

                        {isStringNotEmpty(invoice.invoiceNotes) ? (
                            <Row>
                                <Col style={{ paddingLeft: 20, paddingRight: 20 }}>
                                    <Text style={{ ...styles.boldText, marginBottom: 8 }}>Notes:</Text>
                                    <Text style={{ ...styles.text }}>{invoice.invoiceNotes}</Text>
                                </Col>
                            </Row>
                        ) : null}

                        <Row style={{ position: 'absolute', bottom: 0, left: 20, width: '100%' }}>
                            <Col>
                                <Text style={{ ...styles.text, textAlign: 'center' }}>PLEASE DETACH AND RETURN THE PORTION BELOW WITH YOUR PAYMENT &#9986;&#65039;</Text>
                                <Col style={{ borderTop: '2px dashed #333333', paddingTop: 20 }}>
                                    <Row>
                                        <Col style={{ flex: 1 }}>
                                            <Image src={irisFullLogo} style={{ width: 100, marginBottom: 12 }} />
                                            <Text style={styles.text}>{invoice.fromName}</Text>
                                        </Col>
                                        <Col style={{ flex: 2, display: 'flex' }}>
                                            <View style={styles.detailsRow}>
                                                <View style={styles.detailsDataColumn}>
                                                    <View style={styles.invoiceHeaderTitle}>
                                                        <Text style={styles.detailsText}>ACCOUNT #</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.detailsDataColumn}>
                                                    <View style={styles.invoiceHeaderTitle}>
                                                        <Text style={styles.detailsText}>INVOICE #</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.detailsDataColumn}>
                                                    <View style={styles.invoiceHeaderTitle}>
                                                        <Text style={styles.detailsText}>INVOICE DATE</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.detailsDataColumn}>
                                                    <View style={styles.invoiceHeaderTitle}>
                                                        <Text style={styles.detailsText}>ORDER #</Text>
                                                    </View>
                                                </View>
                                            </View>
                                            <View style={styles.detailsRow}>
                                                <View style={styles.detailsDataColumn}>
                                                    <View style={styles.invoiceHeaderData}>
                                                        <Text style={styles.detailsText}>{invoice.accountNumber}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.detailsDataColumn}>
                                                    <View style={styles.invoiceHeaderData}>
                                                        <Text style={styles.detailsText}>{invoice.irisId}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.detailsDataColumn}>
                                                    <View style={styles.invoiceHeaderData}>
                                                        <Text style={styles.detailsText}>{stringFormatter.toFormattedString('MomentDate', invoice.invoiceDate)}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.detailsDataColumn}>
                                                    <View style={styles.invoiceHeaderData}>
                                                        <Text style={styles.detailsText}>{invoice.customerOrderNumber}</Text>
                                                    </View>
                                                </View>
                                            </View>

                                            <View style={styles.detailsRow}>
                                                <View style={styles.detailsDataColumn}>
                                                    <View style={styles.invoiceHeaderTitle}>
                                                        <Text style={styles.detailsText}>LOAD ID</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.detailsDataColumn}>
                                                    <View style={styles.invoiceHeaderTitle}>
                                                        <Text style={styles.detailsText}>PAYMENT TERMS</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.detailsDataColumn}>
                                                    <View style={styles.invoiceHeaderTitle}>
                                                        <Text style={styles.detailsText}>PAYMENT DUE DATE</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.detailsDataColumn}>
                                                    <View style={styles.invoiceHeaderTitle}>
                                                        <Text style={styles.detailsText}>AMOUNT DUE</Text>
                                                    </View>
                                                </View>
                                            </View>
                                            <View style={styles.detailsRow}>
                                                <View style={styles.detailsDataColumn}>
                                                    <View style={styles.invoiceHeaderData}>
                                                        <Text style={styles.detailsText}>{isObjectNotEmpty(load) && isStringNotEmpty(load.irisId) ? stringFormatter.toFormattedString('String', load.irisId) : null}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.detailsDataColumn}>
                                                    <View style={styles.invoiceHeaderData}>
                                                        <Text style={styles.detailsText}>{invoice.paymentTerms > 0 ? `NET ${invoice.paymentTerms}` : 'DUE IMMEDIATELY'}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.detailsDataColumn}>
                                                    <View style={styles.invoiceHeaderData}>
                                                        <Text style={styles.detailsText}>{stringFormatter.toFormattedString('MomentDate', invoice.paymentDueDate)}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.detailsDataColumn}>
                                                    <View style={styles.invoiceHeaderData}>
                                                        <Text style={styles.detailsText}>{stringFormatter.toFormattedMoney(invoice.amountDue)}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </Col>
                                    </Row>
                                    {isStringNotEmpty(invoice.remitName) && isObjectNotEmpty(invoice.remitAddress) ? (
                                        <Row>
                                            <Col style={{ paddingLeft: '50%' }}>
                                                <Text style={{ ...styles.boldText, textTransform: 'uppercase', marginBottom: 8 }}>PLEASE REMIT PAYMENT TO:</Text>
                                                <Text style={{ ...styles.text, textTransform: 'uppercase' }}>{invoice.remitName}</Text>
                                                <Text style={{ ...styles.text, textTransform: 'uppercase' }}>{invoice.remitAddress.streetAddress1}</Text>
                                                <Text style={{ ...styles.text, textTransform: 'uppercase' }}>{invoice.remitAddress.city}, {invoice.remitAddress.state} {invoice.remitAddress.postalCode}</Text>
                                            </Col>
                                        </Row>
                                    ) : null}
                                </Col>
                            </Col>
                        </Row>
                    </>
                ) : null}
            </Page>
        </Document>
    );
};

const CarrierInvoiceTemplate = ({ invoice, load, stops, invoiceLineItems = [] }) => {
    const { totalAmount, lineItems } = getInvoiceLineItemsAndTotal(invoice.invoiceType, invoiceLineItems, invoice.toEntityType, invoice.fromEntityType);
    const bolNumbers = getBOLList(stops);
    const purchaseOrderNumbers = getPurchaseOrderNumberList(stops);

    return (
        <Document
            author="IRIS Freight"
            title="Carrier Invoice"
        >
            <Page size="A4" style={styles.page}>
                {isObjectNotEmpty(invoice) ? (
                    <>
                        <View style={styles.header} wrap={false}>
                            <View style={styles.fromColumn}>
                                <Text style={styles.text}>{invoice.fromName}</Text>
                                <Text style={styles.text}>{invoice.fromAddress.streetAddress1}</Text>
                                <Text style={styles.text}>{invoice.fromAddress.city}, {invoice.fromAddress.state} {invoice.fromAddress.postalCode}</Text>
                                <Text style={styles.text}>{isObjectNotEmpty(invoice.fromPointOfContact) && isStringNotEmpty(invoice.fromPointOfContact.phone) ? stringFormatter.toFormattedPhoneNumber(invoice.fromPointOfContact.phone) : null}</Text>
                            </View>
                            <View style={styles.invoiceTypeColumn}>
                                <Text style={styles.invoiceTypeText}>Invoice</Text>
                            </View>

                            <View style={styles.detailsColumn}>
                                <View style={styles.detailsRow}>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderTitle}>
                                            <Text style={styles.detailsText}>IRIS INVOICE #</Text>
                                        </View>
                                    </View>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderTitle}>
                                            <Text style={styles.detailsText}>INVOICE #</Text>
                                        </View>
                                    </View>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderTitle}>
                                            <Text style={styles.detailsText}>INVOICE DATE</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.detailsRow}>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderData}>
                                            <Text style={styles.detailsText}>{invoice.irisId}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderData}>
                                            <Text style={styles.detailsText}>{invoice.customerInvoiceNumber}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderData}>
                                            <Text style={styles.detailsText}>{stringFormatter.toFormattedString('MomentDate', invoice.invoiceDate)}</Text>
                                        </View>
                                    </View>
                                </View>

                                <View style={styles.detailsRow}>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderTitle}>
                                            <Text style={styles.detailsText}>ORDER #</Text>
                                        </View>
                                    </View>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderTitle}>
                                            <Text style={styles.detailsText}>BOL #</Text>
                                        </View>
                                    </View>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderTitle}>
                                            <Text style={styles.detailsText}>PO #</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.detailsRow}>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderData}>
                                            {isListNotEmpty(bolNumbers) ? bolNumbers.map((bolNumber, index) => (
                                                <Text key={`stop-bol-${index}`} style={styles.detailsText}>{bolNumber}</Text>
                                            )) : null}
                                        </View>
                                    </View>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderData}>
                                            {isListNotEmpty(purchaseOrderNumbers) ? purchaseOrderNumbers.map((purchaseOrderNumber, index) => (
                                                <Text key={`stop-po-${index}`} style={styles.detailsText}>{purchaseOrderNumber}</Text>
                                            )) : null}
                                        </View>
                                    </View>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderData}>
                                            <Text style={styles.detailsText}>{invoice.customerOrderNumber}</Text>
                                        </View>
                                    </View>
                                </View>

                                <View style={styles.detailsRow}>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderTitle}>
                                            <Text style={styles.detailsText}>SHIP DATE</Text>
                                        </View>
                                    </View>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderTitle}>
                                            <Text style={styles.detailsText}>DELIVER DATE</Text>
                                        </View>
                                    </View>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderTitle}>
                                            <Text style={styles.detailsText}>LOAD ID</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.detailsRow}>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderData}>
                                            <Text style={styles.detailsText}>{isObjectNotEmpty(load) && isObjectNotEmpty(load.origin) ? stringFormatter.toFormattedString('MomentDate', load.origin.endDateTime, null, load.origin.timeZone) : null}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderData}>
                                            <Text style={styles.detailsText}>{isObjectNotEmpty(load) && isObjectNotEmpty(load.destination) ? stringFormatter.toFormattedString('MomentDate', load.destination.endDateTime, null, load.destination.timeZone) : null}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderData}>
                                            <Text style={styles.detailsText}>{isObjectNotEmpty(load) && isStringNotEmpty(load.irisId) ? stringFormatter.toFormattedString('String', load.irisId) : null}</Text>
                                        </View>
                                    </View>
                                </View>

                                <View style={styles.detailsRow}>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderTitle}>
                                            <Text style={styles.detailsText}>ACCOUNT #</Text>
                                        </View>
                                    </View>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderTitle}>
                                            <Text style={styles.detailsText}>PAYMENT TERMS</Text>
                                        </View>
                                    </View>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderTitle}>
                                            <Text style={styles.detailsText}>PAYMENT METHOD</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.detailsRow}>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderData}>
                                            <Text style={styles.detailsText}>{invoice.accountNumber}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderData}>
                                            <Text style={styles.detailsText}>{invoice.paymentTerms > 0 ? `NET ${invoice.paymentTerms}` : 'DUE IMMEDIATELY'}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.detailsDataColumn}>
                                        <View style={styles.invoiceHeaderData}>
                                            <Text style={styles.detailsText}>{stringFormatter.toFormattedString('PreferredPaymentMethod', invoice.paymentMethod)}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                        <Row>
                            <Col style={{ marginLeft: 100 }}>
                                <Text style={{ ...styles.boldText, marginBottom: 8 }}>BILLED TO:</Text>
                                {isStringNotEmpty(invoice.toName) ? <Text style={{ ...styles.text, textTransform: 'uppercase' }}>{stringFormatter.toFormattedString('String', invoice.toName)}</Text> : null}
                                {isObjectNotEmpty(invoice.toPointOfContact) && isStringNotEmpty(invoice.toPointOfContact.name) ? <Text style={styles.text}>{stringFormatter.toFormattedString('String', invoice.toPointOfContact.name)}</Text> : null}
                                {isObjectNotEmpty(invoice.toAddress) ? (
                                    <>
                                        <Text style={styles.text}>{invoice.toAddress.streetAddress1}</Text>
                                        <Text style={styles.text}>{invoice.toAddress.city}, {invoice.toAddress.state} {invoice.toAddress.postalCode}</Text>
                                    </>
                                ) : null}
                            </Col>
                            {isListNotEmpty(invoice.consignees) ? (
                                <Col>
                                    <Text style={{ ...styles.boldText, marginBottom: 8 }}>CONSIGNEE:</Text>
                                    {invoice.consignees.map((consignee, index) => (
                                        <Fragment key={`consignee-row-${index}`}>
                                            {isStringNotEmpty(consignee.name) ? <Text style={{ ...styles.text, textTransform: 'uppercase' }}>{stringFormatter.toFormattedString('String', consignee.name)}</Text> : null}
                                            {isObjectNotEmpty(consignee.address) ? (
                                                <>
                                                    <Text style={styles.text}>{consignee.address.streetAddress1}</Text>
                                                    <Text style={styles.text}>{consignee.address.city}, {consignee.address.state} {consignee.address.postalCode}</Text>
                                                </>
                                            ) : null}
                                            <br />
                                        </Fragment>
                                    ))}
                                </Col>
                            ) : null}
                        </Row>
                        <Row>
                            <Col style={{ paddingLeft: 20, paddingRight: 20 }}>
                                <Text style={{ ...styles.boldText, marginBottom: 8 }}>INVOICE:</Text>
                            </Col>
                        </Row>

                        <InvoiceTable
                            columns={invoiceColumns}
                            invoiceLineItems={lineItems}
                            totalAmount={totalAmount}
                            totalTitle="AMOUNT DUE"
                            totalTitleStyle={{ textAlign: 'right' }}
                            totalStyle={{ textAlign: 'center', border: '2px solid #333333' }}
                        />

                        {isStringNotEmpty(invoice.invoiceNotes) ? (
                            <Row>
                                <Col style={{ paddingLeft: 20, paddingRight: 20 }}>
                                    <Text style={{ ...styles.boldText, marginBottom: 8 }}>Notes:</Text>
                                    <Text style={{ ...styles.text }}>{invoice.invoiceNotes}</Text>
                                </Col>
                            </Row>

                        ) : null}
                        <Row style={{ position: 'absolute', bottom: 0, left: 20, width: '100%' }}>
                            <Col>
                                <Text style={{ ...styles.text, textAlign: 'center' }}>PLEASE DETACH AND RETURN THE PORTION BELOW WITH YOUR PAYMENT &#9986;&#65039;</Text>
                                <Col style={{ borderTop: '2px dashed #333333', paddingTop: 20 }}>
                                    <Row>
                                        <Col style={{ flex: 1 }}>
                                            <Image src={irisFullLogo} style={{ width: 100, marginBottom: 12 }} />
                                            <Text style={styles.text}>{invoice.fromName}</Text>
                                        </Col>
                                        <Col style={{ flex: 2, display: 'flex' }}>
                                            <View style={styles.detailsRow}>
                                                <View style={styles.detailsDataColumn}>
                                                    <View style={styles.invoiceHeaderTitle}>
                                                        <Text style={styles.detailsText}>ACCOUNT #</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.detailsDataColumn}>
                                                    <View style={styles.invoiceHeaderTitle}>
                                                        <Text style={styles.detailsText}>INVOICE #</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.detailsDataColumn}>
                                                    <View style={styles.invoiceHeaderTitle}>
                                                        <Text style={styles.detailsText}>INVOICE DATE</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.detailsDataColumn}>
                                                    <View style={styles.invoiceHeaderTitle}>
                                                        <Text style={styles.detailsText}>ORDER #</Text>
                                                    </View>
                                                </View>
                                            </View>
                                            <View style={styles.detailsRow}>
                                                <View style={styles.detailsDataColumn}>
                                                    <View style={styles.invoiceHeaderData}>
                                                        <Text style={styles.detailsText}>{invoice.accountNumber}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.detailsDataColumn}>
                                                    <View style={styles.invoiceHeaderData}>
                                                        <Text style={styles.detailsText}>{invoice.customerInvoiceNumber}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.detailsDataColumn}>
                                                    <View style={styles.invoiceHeaderData}>
                                                        <Text style={styles.detailsText}>{stringFormatter.toFormattedString('MomentDate', invoice.invoiceDate)}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.detailsDataColumn}>
                                                    <View style={styles.invoiceHeaderData}>
                                                        <Text style={styles.detailsText}>{invoice.customerOrderNumber}</Text>
                                                    </View>
                                                </View>
                                            </View>

                                            <View style={styles.detailsRow}>
                                                <View style={styles.detailsDataColumn}>
                                                    <View style={styles.invoiceHeaderTitle}>
                                                        <Text style={styles.detailsText}>IRIS INVOICE #</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.detailsDataColumn}>
                                                    <View style={styles.invoiceHeaderTitle}>
                                                        <Text style={styles.detailsText}>PAYMENT TERMS</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.detailsDataColumn}>
                                                    <View style={styles.invoiceHeaderTitle}>
                                                        <Text style={styles.detailsText}>PAYMENT DUE DATE</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.detailsDataColumn}>
                                                    <View style={styles.invoiceHeaderTitle}>
                                                        <Text style={styles.detailsText}>AMOUNT DUE</Text>
                                                    </View>
                                                </View>
                                            </View>
                                            <View style={styles.detailsRow}>
                                                <View style={styles.detailsDataColumn}>
                                                    <View style={styles.invoiceHeaderData}>
                                                        <Text style={styles.detailsText}>{invoice.irisId}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.detailsDataColumn}>
                                                    <View style={styles.invoiceHeaderData}>
                                                        <Text style={styles.detailsText}>{invoice.paymentTerms > 0 ? `NET ${invoice.paymentTerms}` : 'DUE IMMEDIATELY'}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.detailsDataColumn}>
                                                    <View style={styles.invoiceHeaderData}>
                                                        <Text style={styles.detailsText}>{stringFormatter.toFormattedString('MomentDate', invoice.paymentDueDate)}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.detailsDataColumn}>
                                                    <View style={styles.invoiceHeaderData}>
                                                        <Text style={styles.detailsText}>{stringFormatter.toFormattedMoney(invoice.amountDue)}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </Col>
                                    </Row>
                                    {isStringNotEmpty(invoice.remitName) && isObjectNotEmpty(invoice.remitAddress) ? (
                                        <Row>
                                            <Col style={{ paddingLeft: '50%' }}>
                                                <Text style={{ ...styles.boldText, textTransform: 'uppercase', marginBottom: 8 }}>PLEASE REMIT PAYMENT TO:</Text>
                                                <Text style={{ ...styles.text, textTransform: 'uppercase' }}>{invoice.remitName}</Text>
                                                <Text style={{ ...styles.text, textTransform: 'uppercase' }}>{invoice.remitAddress.streetAddress1}</Text>
                                                <Text style={{ ...styles.text, textTransform: 'uppercase' }}>{invoice.remitAddress.city}, {invoice.remitAddress.state} {invoice.remitAddress.postalCode}</Text>
                                            </Col>
                                        </Row>
                                    ) : null}
                                </Col>
                            </Col>
                        </Row>
                    </>
                ) : null}
            </Page>
        </Document>
    );
};

const RateConfirmationTemplate = ({ invoice, load, stops, invoiceLineItems = [], displayManualInstructions = false }) => {
    const { totalAmount, lineItems } = getInvoiceLineItemsAndTotal(invoice.invoiceType, invoiceLineItems, invoice.fromEntityType, invoice.toEntityType);
    const bolNumbers = getBOLList(stops);
    const commodityList = getCommodityList(stops);

    return (
        <Document
            author="IRIS Freight"
            title={`Rate Confirmation for Load ${isObjectNotEmpty(load) && isStringNotEmpty(load.irisId) ? load.irisId : ''}`}
        >
            <Page size="A4" style={styles.page}>
                {isObjectNotEmpty(invoice) ? (
                    <>
                        <View style={styles.header} wrap={false}>
                            <Col>
                                <Image src={irisFullLogo} style={{ width: 100, marginBottom: 12 }} />

                                {isStringNotEmpty(invoice.fromName) ? (<Text style={styles.boldText}>{invoice.fromName}</Text>) : null}
                                {isObjectNotEmpty(invoice.fromAddress) ? (<Text style={styles.boldText}>{invoice.fromAddress.streetAddress1}</Text>) : null}
                                {isObjectNotEmpty(invoice.fromAddress) ? (<Text style={styles.boldText}>{invoice.fromAddress.city}, {invoice.fromAddress.state} {invoice.fromAddress.postalCode}</Text>) : null}
                                {isObjectNotEmpty(invoice.fromPointOfContact) && isStringNotEmpty(invoice.fromPointOfContact.phone) ? (<RowPhoneNumberText style={styles.boldText} phoneNumber={invoice.fromPointOfContact.phone} />) : null}
                            </Col>
                            <Col>
                                <Text style={styles.invoiceTypeText}>Rate Confirmation</Text>
                                <Text style={styles.invoiceTypeSubText}>Load ID: {isObjectNotEmpty(load) && isStringNotEmpty(load.irisId) ? stringFormatter.toFormattedString('String', load.irisId) : null}</Text>
                            </Col>
                            <Col style={{ textAlign: 'right' }}>
                                {displayManualInstructions === true ? (
                                    <>
                                        <Text style={styles.text}>Send signed Rate Confirmation to:</Text>
                                        <Link src="mailto: carrierratecon@irisfreight.com" style={styles.text}>carrierratecon@irisfreight.com</Link>
                                        <RowPhoneNumberText style={styles.text} title="Fax #" phoneNumber={irisFaxNumber} />
                                    </>
                                ) : (
                                    <Text style={styles.text}>Please Digitially Sign this Rate Confirmation to Accept the Terms and Confirm the Load.</Text>
                                )}
                            </Col>
                        </View>
                        <Row>
                            <Col style={{ textAlign: 'center' }}>
                                <Text style={{ ...styles.boldText, fontSize: 12 }}>Review & Accept to Confirm the Load.</Text>
                            </Col>
                        </Row>
                        <View style={styles.sectionRow} wrap={false}>
                            <View style={styles.sectionColumn}>
                                <View style={styles.sectionHeader}>
                                    <Text style={styles.sectionHeaderText}>Customer Requirements</Text>
                                </View>
                                {isObjectNotEmpty(load) && isObjectNotEmpty(load.equipmentNeeds) ? (
                                    <View style={styles.sectionBody}>
                                        {isObjectNotEmpty(load.equipmentNeeds.trailerType) && isStringNotEmpty(load.equipmentNeeds.trailerType.description) ? (<RowText style={styles.text} title="Trailer Type" value={load.equipmentNeeds.trailerType.description} dataType="String" />) : null}
                                        {isNumberNotEmpty(load.equipmentNeeds.trailerLength) ? (<RowText style={styles.text} title="Trailer Length" value={load.equipmentNeeds.trailerLength} units={load.equipmentNeeds.trailerLengthUnit} dataType="Length" />) : null}
                                        {isNumberNotEmpty(load.equipmentNeeds.trailerWeightCapacity) ? (<RowText style={styles.text} title="Max Load Weight Capacity" value={load.equipmentNeeds.trailerWeightCapacity} units={load.equipmentNeeds.trailerWeightCapacityUnit} dataType="Weight" />) : null}
                                        {isNumberNotEmpty(load.equipmentNeeds.preCoolReefer) ? (<RowText style={styles.text} title="Pre-cool Temp*" value={load.equipmentNeeds.preCoolReefer} units={load.equipmentNeeds.preCoolReeferUnit} dataType="Temperature" />) : null}
                                        {isNumberNotEmpty(load.equipmentNeeds.minRunTemp) && isNumberNotEmpty(load.equipmentNeeds.maxRunTemp) ? (
                                            <>
                                                <RowText style={styles.text} title="Load Temp*" value="Continuous" dataType="String" />
                                                <RowTemperatureRangeText style={styles.text} title="Temp Range*" min={load.equipmentNeeds.minRunTemp} minUnits={load.equipmentNeeds.minRunTempUnit} max={load.equipmentNeeds.maxRunTemp} maxUnits={load.equipmentNeeds.maxRunTempUnit} />
                                            </>
                                        ) : null}
                                        <RowText style={styles.text} title="Team Driving" value={isObjectNotEmpty(load) && isListNotEmpty(load.driverRequirements) && load.driverRequirements.includes('TEAM_DRIVING') ? true : false} dataType="Boolean" />
                                        {isListNotEmpty(load.equipmentSpecifics) ? (
                                            <RowListText style={styles.text} title="Equipment Specifics" data={load.equipmentSpecifics} dataType="EquipmentSpecific" />
                                        ) : null}
                                        {isListNotEmpty(load.driverRequirements) ? (
                                            <RowListText style={styles.text} title="Driver Requirements" data={load.driverRequirements} dataType="DriverRequirement" />
                                        ) : null}
                                        {isNumberNotEmpty(load.equipmentNeeds.preCoolReefer) ? <Text style={{ ...styles.text, textAlign: 'center', marginTop: 16 }}>* See BOL for Final TEMP</Text> : null}
                                    </View>
                                ) : null}
                            </View>
                            <View style={styles.sectionColumn}>
                                <View style={styles.sectionHeader}>
                                    <Text style={styles.sectionHeaderText}>Customer Requirements</Text>
                                </View>
                                <View style={styles.sectionBody}>
                                    {isObjectNotEmpty(load) && isStringNotEmpty(load.irisId) ? (<RowText style={styles.text} title="Load ID" value={load.irisId} dataType="String" />) : null}
                                    {isObjectNotEmpty(load) && isNumberNotEmpty(load.practicalDistance) ? (<RowText style={styles.text} title="Distance" value={load.practicalDistance} units={load.practicalDistanceUnit} dataType="Distance" />) : null}
                                    {isListNotEmpty(commodityList) ? (
                                        <RowListText style={styles.text} title="Commodities" data={commodityList} dataType="String" />
                                    ) : null}
                                    {isListNotEmpty(bolNumbers) ? (
                                        <RowListText style={styles.text} title="BOL(s)" data={bolNumbers} dataType="String" />
                                    ) : null}
                                </View>
                            </View>
                            <View style={styles.sectionColumn}>
                                <View style={styles.sectionHeader}>
                                    <Text style={styles.sectionHeaderText}>Booked By</Text>
                                </View>
                                <View style={styles.sectionBody}>
                                    {isObjectNotEmpty(load) && isStringNotEmpty(load.serviceType) && load.serviceType === 'BROKERAGE' ? (
                                        <>
                                            <RowText style={styles.text} title="Broker" value="IRIS Technologies, Inc." dataType="String" />
                                            {isListNotEmpty(load.assignedAccountRepUsers) ?
                                                load.assignedAccountRepUsers.map((assignedAccountRepUser, index, arr) => {
                                                    return (
                                                        <RowContactText key={`BookedBy-Rep${arr.length > 1 ? ' ' + (index + 1) : ''}`} style={styles.text} title={`Rep${arr.length > 1 ? ' ' + (index + 1) : ''}`} contact={{ name: assignedAccountRepUser.firstName + ' ' + assignedAccountRepUser.lastName, email: assignedAccountRepUser.user, phone: assignedAccountRepUser.phone, phoneExt: assignedAccountRepUser.phoneExt }} />
                                                    );
                                                }) : null}
                                            <RowPhoneNumberText style={styles.text} title="Fax #" phoneNumber={irisFaxNumber} />
                                        </>
                                    ) : null}
                                    <RowText style={styles.text} title="Date" value={invoice.invoiceDate} dataType="MomentDateTime" />
                                </View>
                            </View>
                        </View>

                        {isListNotEmpty(stops) ? (
                            <View>
                                {stops.filter(stop => stop.stopType === 'PICK_UP' || stop.stopType === 'DROP_OFF').map((stop, index) => (
                                    <Fragment key={`stop-details-${index}`}>
                                        <View style={styles.sectionRow} wrap={false}>
                                            <View style={styles.sectionColumn}>
                                                <View style={styles.sectionHeader}>
                                                    <Text style={styles.sectionHeaderText}>{`Stop #${index + 1}: ${stringFormatter.toFormattedString("StopType", stop.stopType, null)}`}</Text>
                                                </View>
                                                <View style={styles.sectionBody}>
                                                    {isObjectNotEmpty(stop.stopLocation) ? (
                                                        <>
                                                            {isStringNotEmpty(stop.stopLocation.name) ? (<RowText style={styles.text} title="Facility" value={stop.stopLocation.name} dataType="String" />) : null}
                                                            {isObjectNotEmpty(stop.stopLocation.address) ? (
                                                                <RowAddressText style={styles.text} title="Address" address={stop.stopLocation.address} />
                                                            ) : null}
                                                        </>
                                                    ) : null}
                                                    {isObjectNotEmpty(stop.apptPointOfContact) ? (
                                                        <RowContactText style={styles.text} title="Contact" contact={stop.apptPointOfContact} />
                                                    ) : null}
                                                </View>
                                            </View>
                                            <View style={styles.sectionColumn}>
                                                <View style={styles.sectionHeader}>
                                                    <Text style={styles.sectionHeaderText}>{stringFormatter.toFormattedString("MomentDate", stop.requestedDateTime, null, stop.timeZone)}</Text>
                                                </View>
                                                <View style={styles.sectionBody}>
                                                    {isStringNotEmpty(stop.apptType) ? (
                                                        <>
                                                            <RowText style={styles.text} title="Appointment Type" value={stop.apptType} dataType="AppointmentType" />
                                                            {isNotNullOrUndefined(stop.apptWindowStartDateTime) && isNotNullOrUndefined(stop.apptWindowEndDateTime) ? (<Text style={styles.text}>Appointment Window: {stringFormatter.toFormattedString('MomentDateTime', stop.apptWindowStartDateTime, null, stop.timeZone)} - {stringFormatter.toFormattedString('MomentDateTime', stop.apptWindowEndDateTime, null, stop.timeZone)}</Text>) : null}
                                                            {isStringNotEmpty(stop.apptNumber) ? (<RowText style={styles.text} title="Appointment #" value={stop.apptNumber} dataType="String" />) : null}
                                                            {isNotNullOrUndefined(stop.apptDateTime) ? (<RowText style={styles.text} title="Appointment Date/Time" value={stop.apptDateTime} dataType="MomentDateTime" timeZone={stop.timeZone} />) : null}
                                                            {isNotNullOrUndefined(stop.apptCallAheadDateTime) ? (<RowText style={styles.text} title="Appointment Call Ahead Date/Time" value={stop.apptCallAheadDateTime} dataType="MomentDateTime" timeZone={stop.timeZone} />) : null}
                                                            {isStringNotEmpty(stop.apptInstructions) ? (<RowText style={styles.text} title="Additional Appointment Instructions" value={stop.apptInstructions} dataType="String" />) : null}
                                                        </>
                                                    ) : null}
                                                    {isBooleanTrue(stop.hasDriverAssist) === true ? (<RowText style={styles.text} title="Driver Assist" value={`Yes, ${stringFormatter.toFormattedMoney(stop.driverAssist)}`} dataType="String" />) : null}
                                                    {isBooleanTrue(stop.hasLumperFee) === true ? (<RowText style={styles.text} title="Lumper Fee" value={`Yes, ${stringFormatter.toFormattedMoney(stop.lumperFee)}`} dataType="String" />) : null}
                                                </View>
                                            </View>
                                            <View style={styles.sectionColumn}>
                                                <View style={styles.sectionHeader}>
                                                    <Text style={styles.sectionHeaderText}>{stop.stopType === 'PICK_UP' ? `Pick-Up #: ${isStringNotEmpty(stop.pickUpNumber) ? stop.pickUpNumber : 'N/A'}` : `Drop-Off #: ${isStringNotEmpty(stop.dropOffNumber) ? stop.dropOffNumber : 'N/A'}`}</Text>
                                                </View>
                                                <View style={styles.sectionBody}>
                                                    {isStringNotEmpty(stop.purchaseOrderNumber) ? (<RowText style={styles.text} title="Customer PO Number" value={stop.purchaseOrderNumber} dataType="String" />) : null}
                                                    {isListNotEmpty(stop.bolNumbers) ? (<RowText style={styles.text} title="BOL Number(s)" dataType="String" value={stop.bolNumbers.join(', ')} />) : null}
                                                    {isStringNotEmpty(stop.specialInstructions) ? (<RowText style={styles.text} title="Special Instructions" value={stop.specialInstructions} dataType="String" />) : null}
                                                </View>
                                            </View>
                                        </View>

                                        {isListNotEmpty(stop.commodities) ? (
                                            <View style={styles.sectionRow} wrap={false}>
                                                <View style={styles.sectionColumn}>
                                                    <View style={styles.sectionBody}>
                                                        <CommodityTable stop={stop} columns={commodityColumns} />
                                                    </View>
                                                </View>
                                            </View>
                                        ) : null}
                                    </Fragment>
                                ))}
                            </View>
                        ) : null}

                        <View style={styles.sectionRow} wrap={false}>
                            <View style={styles.sectionColumn}>
                                <View style={styles.sectionHeader}>
                                    <Text style={styles.sectionHeaderText}>Payment</Text>
                                </View>

                                <View style={styles.sectionBody}>
                                    <InvoiceTable
                                        columns={invoiceColumns}
                                        invoiceLineItems={lineItems}
                                        totalAmount={totalAmount}
                                        totalTitleStyle={{ textAlign: 'right' }}
                                        totalStyle={{ textAlign: 'center', border: '2px solid #333333' }}
                                    />
                                </View>
                            </View>
                        </View>

                        <View style={styles.sectionRow} wrap={false}>
                            <View style={styles.sectionColumn}>
                                <View style={styles.sectionHeader}>
                                    <Text style={styles.sectionHeaderText}>Agreement</Text>
                                </View>

                                {isObjectNotEmpty(load) && isObjectNotEmpty(load.assignedCarrier) ? (
                                    <View style={styles.sectionBody}>
                                        <Row>
                                            <Col>
                                                <RowText style={styles.text} title="Carrier" value={load.assignedCarrier.name} dataType="String" />
                                                <RowText style={styles.text} title="MC #" value={load.assignedCarrier.mcNum} dataType="String" />
                                                <RowText style={styles.text} title="Email" value={load.assignedCarrier.email} dataType="String" />
                                                <RowPhoneNumberText style={styles.text} title="Phone #" phoneNumber={load.assignedCarrier.phone} phoneNumberExtension={load.assignedCarrier.phoneExt} />
                                                {isStringNotEmpty(load.assignedCarrier.fax) ? <RowPhoneNumberText style={styles.text} title="Fax #" phoneNumber={load.assignedCarrier.fax} /> : null}
                                            </Col>
                                            <Col>
                                                {isStringNotEmpty(load.serviceType) && load.serviceType === 'BROKERAGE' ? (
                                                    <>
                                                        <RowText style={styles.text} title="Broker" value="IRIS Technologies, Inc." dataType="String" />
                                                        {isListNotEmpty(load.assignedAccountRepUsers) ?
                                                            load.assignedAccountRepUsers.map((assignedAccountRepUser, index, arr) => {
                                                                return (
                                                                    <RowContactText key={`Agreement-Rep${arr.length > 1 ? ' ' + (index + 1) : ''}`} style={styles.text} title={`Rep${arr.length > 1 ? ' ' + (index + 1) : ''}`} contact={{ name: assignedAccountRepUser.firstName + ' ' + assignedAccountRepUser.lastName, email: assignedAccountRepUser.user, phone: assignedAccountRepUser.phone, phoneExt: assignedAccountRepUser.phoneExt }} />
                                                                );
                                                            }) : null}
                                                        <RowPhoneNumberText style={styles.text} title="Fax #" phoneNumber={irisFaxNumber} />
                                                    </>
                                                ) : null}
                                                <RowText style={styles.text} title="Date" value={invoice.invoiceDate} dataType="MomentDateTime" />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col style={{ textAlign: 'center', marginTop: 10, marginBottom: 10 }}>
                                                <Text style={styles.text}>By signing below, {load.assignedCarrier.name} agrees to the terms and conditions set forth below and provided herewith, if any.</Text>
                                            </Col>
                                        </Row>
                                        <View style={styles.sectionRow}>
                                            <Col style={{ alignItems: 'center', justifyContent: 'flex-end', borderBottom: '1px solid black', height: 20 }}>
                                                {isStringNotEmpty(invoice.signedByName) ? <Text style={styles.text}>{invoice.signedByName}</Text> : null}
                                            </Col>
                                            <Col style={{ alignItems: 'center', justifyContent: 'flex-end', borderBottom: '1px solid black', height: 20 }}>
                                                {isStringNotEmpty(invoice.signedByTitle) ? <Text style={styles.text}>{invoice.signedByTitle}</Text> : null}
                                            </Col>
                                        </View>
                                        <View style={styles.sectionRow}>
                                            <Col style={{ alignItems: 'center', justifyContent: 'flex-start', height: 20 }}>
                                                <Text style={styles.text}>Name</Text>
                                            </Col>
                                            <Col style={{ alignItems: 'center', justifyContent: 'flex-start', height: 20 }}>
                                                <Text style={styles.text}>Title</Text>
                                            </Col>
                                        </View>
                                        <View style={styles.sectionRow}>
                                            <Col style={{ borderBottom: '1px solid black', height: 40 }}>
                                                {isStringNotEmpty(invoice.signature) ? <Image src={invoice.signature} style={{ height: 30 }} /> : null}
                                            </Col>
                                            <Col style={{ alignItems: 'center', justifyContent: 'flex-end', borderBottom: '1px solid black', height: 40 }}>
                                                {isObjectNotEmpty(invoice.signedAt) ? <Text style={styles.text}>{stringFormatter.toFormattedString('MomentDateTime', invoice.signedAt)}</Text> : null}
                                            </Col>
                                        </View>
                                        <View style={styles.sectionRow}>
                                            <Col style={{ alignItems: 'center', justifyContent: 'flex-start', height: 20 }}>
                                                <Text style={styles.text}>Signature</Text>
                                            </Col>
                                            <Col style={{ alignItems: 'center', justifyContent: 'flex-start', height: 20 }}>
                                                <Text style={styles.text}>Date</Text>
                                            </Col>
                                        </View>
                                        {displayManualInstructions === true ? (
                                            <Row>
                                                <Col style={{ textAlign: 'center' }}>
                                                    <Text style={styles.boldText}>PLEASE RETURN THE SIGNED COPY TO <Link style={styles.text} src="mailto: carrierratecon@irisfreight.com">CARRIERRATECON@IRISFREIGHT.COM</Link></Text>
                                                    <Text style={styles.boldText}>OR FAX BACK TO {stringFormatter.toFormattedPhoneNumber(irisFaxNumber)} (ATTN: Load Id: {load.irisId})</Text>
                                                </Col>
                                            </Row>
                                        ) : null}
                                    </View>
                                ) : null}
                            </View>
                        </View>
                        {isObjectNotEmpty(load) && isStringNotEmpty(load.serviceType) && load.serviceType === 'BROKERAGE' ? (
                            <View style={styles.sectionRow} wrap={false}>
                                <View style={styles.sectionColumn}>
                                    <View style={styles.sectionHeader}>
                                        <Text style={{ ...styles.sectionHeaderText }}>Terms & Conditions</Text>
                                    </View>
                                    <View style={styles.sectionBody}>
                                        <Text style={{ ...styles.text, marginBottom: 16 }}>This Rate Confirmation is subject to the terms of the master Broker-Carrier Agreement between our companies. It amends that master Agreement as to any additional terms.</Text>
                                        <Text style={{ ...styles.text, marginBottom: 16 }}>THIS LOAD MUST NOT BE DOUBLE BROKERED. Except as specified above, the Rate(s) confirmed hereby are inclusive of all charges, including any fuel surcharge. No different tariff or schedule rates apply. Additional charges not listed above may not be added by the Carrier. Any additional charges must appear on a revised Rate Confirmation signed by Broker.</Text>
                                        <Text style={{ ...styles.text, marginBottom: 16 }}>Lumper fees will not be paid unless Broker is advised by phone before unloading.</Text>
                                        <Text style={{ ...styles.text, marginBottom: 16 }}>Carrier certifies that if transportation occurs in California any refrigeration unit furnished will comply with the California TRU Regulations. Carrier agrees to be responsible for any fines imposed on Broker and/or Shipper because of noncompliance.</Text>
                                        <Text style={{ ...styles.text, marginBottom: 16 }}>Carrier hereby confirms that it maintains applicable and valid insurance without exclusions that would prevent coverage for the items listed above.</Text>
                                        <Text style={{ ...styles.text, marginBottom: 16 }}>If your truck is late for pickup/delivery, call Broker immediately. After hours phone number: (310) 774-2147.</Text>
                                        <Text style={{ ...styles.text, marginBottom: 16 }}>Carrier acknowledges that any routing instructions from the Shipper herein or in the Iris Freight mobile application are being provided for convenience only, and the Carrier may choose the route except as otherwise set forth herein.</Text>
                                        <Text style={{ ...styles.text, marginBottom: 16 }}>Driver is responsible for count & quality of the delivered product.</Text>
                                        <Text style={{ ...styles.text, marginBottom: 16 }}>Driver must pulp produce loads prior to loading. Daily updates via mobile app are due by 08:00 am Pacific Time.</Text>
                                        <Text style={{ ...styles.text, marginBottom: 16 }}>Carrier must include a signed copy of the Shipper’s Bill of Lading and other proof of delivery with its invoice to Broker.</Text>
                                        <Text style={{ ...styles.text, marginBottom: 16 }}>The master Broker-Carrier Agreement and this document constitute the complete agreement of the parties as to the load involved.</Text>
                                        <Text style={{ ...styles.boldText, marginBottom: 16 }}>UNLESS YOU SEND THE BROKER A WRITTEN (FAX) OBJECTION TO THIS RATE CONFIRMATION WITHIN 24 HOURS OF ITS TRANSMISSION, YOU HAVE AGREED TO THESE TERMS.</Text>

                                        <Text style={{ ...styles.boldText, textAlign: 'center', marginTop: 8 }}>ALL LOADS ARE SUBJECT TO ELECTRONIC MONITORING.</Text>
                                    </View>
                                </View>
                            </View>
                        ) : null}
                    </>
                ) : null}
            </Page>
        </Document>
    );
};

const PriceConfirmationTemplate = ({ invoice, load, stops, invoiceLineItems = [], displayManualInstructions = false }) => {
    const { totalAmount, lineItems } = getInvoiceLineItemsAndTotal(invoice.invoiceType, invoiceLineItems, invoice.fromEntityType, invoice.toEntityType);
    const bolNumbers = getBOLList(stops);
    const commodityList = getCommodityList(stops);

    return (
        <Document
            author="IRIS Freight"
            title={`Load Tender for Load ${isObjectNotEmpty(load) && isStringNotEmpty(load.irisId) ? load.irisId : ''}`}
        >
            <Page size="A4" style={styles.page}>
                {isObjectNotEmpty(invoice) ? (
                    <>
                        <View style={styles.header} wrap={false}>
                            <Col>
                                {isStringNotEmpty(invoice.fromName) ? (<Text style={styles.boldText}>{invoice.fromName}</Text>) : null}
                                {isObjectNotEmpty(invoice.fromAddress) ? (<Text style={styles.boldText}>{invoice.fromAddress.streetAddress1}</Text>) : null}
                                {isObjectNotEmpty(invoice.fromAddress) ? (<Text style={styles.boldText}>{invoice.fromAddress.city}, {invoice.fromAddress.state} {invoice.fromAddress.postalCode}</Text>) : null}
                                {isObjectNotEmpty(invoice.fromPointOfContact) && isStringNotEmpty(invoice.fromPointOfContact.phone) ? (<RowPhoneNumberText style={styles.boldText} phoneNumber={invoice.fromPointOfContact.phone} />) : null}
                            </Col>
                            <Col>
                                <Text style={styles.invoiceTypeText}>Load Tender</Text>
                                <Text style={styles.invoiceTypeSubText}>Load ID: {isObjectNotEmpty(load) && isStringNotEmpty(load.irisId) ? stringFormatter.toFormattedString('String', load.irisId) : null}</Text>
                            </Col>
                            <Col style={{ textAlign: 'right' }}>
                                {displayManualInstructions === true ? (
                                    <>
                                        <Text style={styles.text}>Send signed Load Tender to:</Text>
                                        <Link src="mailto: loadtender@irisfreight.com" style={styles.text}>loadtender@irisfreight.com</Link>
                                        <RowPhoneNumberText style={styles.text} title="Fax #" phoneNumber={irisFaxNumber} />
                                    </>
                                ) : (
                                    <Text style={styles.text}>Please Digitially Sign this Load Tender to Accept the Terms and Confirm the Load.</Text>
                                )}
                            </Col>
                        </View>
                        <Row>
                            <Col style={{ textAlign: 'center' }}>
                                <Text style={{ ...styles.boldText, fontSize: 12 }}>Review & Accept to Confirm the Load.</Text>
                            </Col>
                        </Row>
                        <View style={styles.sectionRow} wrap={false}>
                            <View style={styles.sectionColumn}>
                                <View style={styles.sectionHeader}>
                                    <Text style={styles.sectionHeaderText}>Customer Requirements</Text>
                                </View>
                                {isObjectNotEmpty(load) && isObjectNotEmpty(load.equipmentNeeds) ? (
                                    <View style={styles.sectionBody}>
                                        {isObjectNotEmpty(load.equipmentNeeds.trailerType) && isStringNotEmpty(load.equipmentNeeds.trailerType.description) ? (<RowText style={styles.text} title="Trailer Type" value={load.equipmentNeeds.trailerType.description} dataType="String" />) : null}
                                        {isNumberNotEmpty(load.equipmentNeeds.trailerLength) ? (<RowText style={styles.text} title="Trailer Length" value={load.equipmentNeeds.trailerLength} units={load.equipmentNeeds.trailerLengthUnit} dataType="Length" />) : null}
                                        {isNumberNotEmpty(load.equipmentNeeds.trailerWeightCapacity) ? (<RowText style={styles.text} title="Max Load Weight Capacity" value={load.equipmentNeeds.trailerWeightCapacity} units={load.equipmentNeeds.trailerWeightCapacityUnit} dataType="Weight" />) : null}
                                        {isNumberNotEmpty(load.equipmentNeeds.preCoolReefer) ? (<RowText style={styles.text} title="Pre-cool Temp*" value={load.equipmentNeeds.preCoolReefer} units={load.equipmentNeeds.preCoolReeferUnit} dataType="Temperature" />) : null}
                                        {isNumberNotEmpty(load.equipmentNeeds.minRunTemp) && isNumberNotEmpty(load.equipmentNeeds.maxRunTemp) ? (
                                            <>
                                                <RowText style={styles.text} title="Load Temp*" value="Continuous" dataType="String" />
                                                <RowTemperatureRangeText style={styles.text} title="Temp Range*" min={load.equipmentNeeds.minRunTemp} minUnits={load.equipmentNeeds.minRunTempUnit} max={load.equipmentNeeds.maxRunTemp} maxUnits={load.equipmentNeeds.maxRunTempUnit} />
                                            </>
                                        ) : null}
                                        <RowText style={styles.text} title="Team Driving" value={isObjectNotEmpty(load) && isListNotEmpty(load.driverRequirements) && load.driverRequirements.includes('TEAM_DRIVING') ? true : false} dataType="Boolean" />
                                        {isListNotEmpty(load.equipmentSpecifics) ? (
                                            <RowListText style={styles.text} title="Equipment Specifics" data={load.equipmentSpecifics} dataType="EquipmentSpecific" />
                                        ) : null}
                                        {isListNotEmpty(load.driverRequirements) ? (
                                            <RowListText style={styles.text} title="Driver Requirements" data={load.driverRequirements} dataType="DriverRequirement" />
                                        ) : null}
                                        {isNumberNotEmpty(load.equipmentNeeds.preCoolReefer) ? <Text style={{ ...styles.text, textAlign: 'center', marginTop: 16 }}>* See BOL for Final TEMP</Text> : null}
                                    </View>
                                ) : null}
                            </View>
                            <View style={styles.sectionColumn}>
                                <View style={styles.sectionHeader}>
                                    <Text style={styles.sectionHeaderText}>Order</Text>
                                </View>
                                <View style={styles.sectionBody}>
                                    {isObjectNotEmpty(load) && isStringNotEmpty(load.irisId) ? (<RowText style={styles.text} title="Load ID" value={load.irisId} dataType="String" />) : null}
                                    {isObjectNotEmpty(load) && isStringNotEmpty(load.customerOrderNumber) ? <RowText style={styles.text} title="Order #" value={load.customerOrderNumber} dataType="String" /> : null}
                                    {isObjectNotEmpty(load) && isNumberNotEmpty(load.practicalDistance) ? (<RowText style={styles.text} title="Distance" value={load.practicalDistance} units={load.practicalDistanceUnit} dataType="Distance" />) : null}
                                    {isListNotEmpty(commodityList) ? (
                                        <RowListText style={styles.text} title="Commodities" data={commodityList} dataType="String" />
                                    ) : null}
                                    {isListNotEmpty(bolNumbers) ? (
                                        <RowListText style={styles.text} title="BOL(s)" data={bolNumbers} dataType="String" />
                                    ) : null}
                                </View>
                            </View>
                            <View style={styles.sectionColumn}>
                                <View style={styles.sectionHeader}>
                                    <Text style={styles.sectionHeaderText}>Booked By</Text>
                                </View>
                                <View style={styles.sectionBody}>
                                    {isObjectNotEmpty(load) && isStringNotEmpty(load.serviceType) && load.serviceType === 'BROKERAGE' ? (
                                        <>
                                            <RowText style={styles.text} title="Broker" value="IRIS Technologies, Inc." dataType="String" />
                                            {isListNotEmpty(load.assignedAccountRepUsers) ?
                                                load.assignedAccountRepUsers.map((assignedAccountRepUser, index, arr) => {
                                                    return (
                                                        <RowContactText key={`BookedBy-Rep${arr.length > 1 ? ' ' + (index + 1) : ''}`} style={styles.text} title={`Rep${arr.length > 1 ? ' ' + (index + 1) : ''}`} contact={{ name: assignedAccountRepUser.firstName + ' ' + assignedAccountRepUser.lastName, email: assignedAccountRepUser.user, phone: assignedAccountRepUser.phone, phoneExt: assignedAccountRepUser.phoneExt }} />
                                                    );
                                                }) : null}
                                            <RowPhoneNumberText style={styles.text} title="Fax #" phoneNumber={irisFaxNumber} />
                                        </>
                                    ) : null}
                                    <RowText style={styles.text} title="Date" value={invoice.invoiceDate} dataType="MomentDateTime" />
                                </View>
                            </View>
                        </View>

                        {isListNotEmpty(stops) ? (
                            <View wrap={false}>
                                {stops.filter(stop => stop.stopType === 'PICK_UP' || stop.stopType === 'DROP_OFF').map((stop, index) => (
                                    <Fragment key={`stop-details-${index}`}>
                                        <View style={styles.sectionRow}>
                                            <View style={styles.sectionColumn}>
                                                <View style={styles.sectionHeader}>
                                                    <Text style={styles.sectionHeaderText}>{`Stop #${index + 1}: ${stringFormatter.toFormattedString("StopType", stop.stopType, null)}`}</Text>
                                                </View>
                                                <View style={styles.sectionBody}>
                                                    {isObjectNotEmpty(stop.stopLocation) ? (
                                                        <>
                                                            {isStringNotEmpty(stop.stopLocation.name) ? (<RowText style={styles.text} title="Facility" value={stop.stopLocation.name} dataType="String" />) : null}
                                                            {isObjectNotEmpty(stop.stopLocation.address) ? (
                                                                <RowAddressText style={styles.text} title="Address" address={stop.stopLocation.address} />
                                                            ) : null}
                                                        </>
                                                    ) : null}
                                                    {isObjectNotEmpty(stop.apptPointOfContact) ? (
                                                        <RowContactText style={styles.text} title="Contact" contact={stop.apptPointOfContact} />
                                                    ) : null}
                                                </View>
                                            </View>
                                            <View style={styles.sectionColumn}>
                                                <View style={styles.sectionHeader}>
                                                    <Text style={styles.sectionHeaderText}>{stringFormatter.toFormattedString("MomentDate", stop.requestedDateTime, null, stop.timeZone)}</Text>
                                                </View>
                                                <View style={styles.sectionBody}>
                                                    {isStringNotEmpty(stop.apptType) ? (
                                                        <>
                                                            <RowText style={styles.text} title="Appointment Type" value={stop.apptType} dataType="AppointmentType" />
                                                            {isNotNullOrUndefined(stop.apptWindowStartDateTime) && isNotNullOrUndefined(stop.apptWindowEndDateTime) ? (<Text style={styles.text}>Appointment Window: {stringFormatter.toFormattedString('MomentDateTime', stop.apptWindowStartDateTime, null, stop.timeZone)} - {stringFormatter.toFormattedString('MomentDateTime', stop.apptWindowEndDateTime, null, stop.timeZone)}</Text>) : null}
                                                            {isStringNotEmpty(stop.apptNumber) ? (<RowText style={styles.text} title="Appointment #" value={stop.apptNumber} dataType="String" />) : null}
                                                            {isNotNullOrUndefined(stop.apptDateTime) ? (<RowText style={styles.text} title="Appointment Date/Time" value={stop.apptDateTime} dataType="MomentDateTime" timeZone={stop.timeZone} />) : null}
                                                            {isNotNullOrUndefined(stop.apptCallAheadDateTime) ? (<RowText style={styles.text} title="Appointment Call Ahead Date/Time" value={stop.apptCallAheadDateTime} dataType="MomentDateTime" timeZone={stop.timeZone} />) : null}
                                                            {isStringNotEmpty(stop.apptInstructions) ? (<RowText style={styles.text} title="Additional Appointment Instructions" value={stop.apptInstructions} dataType="String" />) : null}
                                                        </>
                                                    ) : null}
                                                    {isBooleanTrue(stop.hasDriverAssist) === true ? (<RowText style={styles.text} title="Driver Assist" value={`Yes, ${stringFormatter.toFormattedMoney(stop.driverAssist)}`} dataType="String" />) : null}
                                                    {isBooleanTrue(stop.hasLumperFee) === true ? (<RowText style={styles.text} title="Lumper Fee" value={`Yes, ${stringFormatter.toFormattedMoney(stop.lumperFee)}`} dataType="String" />) : null}
                                                </View>
                                            </View>
                                            <View style={styles.sectionColumn}>
                                                <View style={styles.sectionHeader}>
                                                    <Text style={styles.sectionHeaderText}>{stop.stopType === 'PICK_UP' ? `Pick-Up #: ${isStringNotEmpty(stop.pickUpNumber) ? stop.pickUpNumber : 'N/A'}` : `Drop-Off #: ${isStringNotEmpty(stop.dropOffNumber) ? stop.dropOffNumber : 'N/A'}`}</Text>
                                                </View>
                                                <View style={styles.sectionBody}>
                                                    {isStringNotEmpty(stop.purchaseOrderNumber) ? (<RowText style={styles.text} title="Customer PO Number" value={stop.purchaseOrderNumber} dataType="String" />) : null}
                                                    {isListNotEmpty(stop.bolNumbers) ? (<RowText style={styles.text} title="BOL Number(s)" dataType="String" value={stop.bolNumbers.join(', ')} />) : null}
                                                    {isStringNotEmpty(stop.specialInstructions) ? (<RowText style={styles.text} title="Special Instructions" value={stop.specialInstructions} dataType="String" />) : null}
                                                </View>
                                            </View>
                                        </View>

                                        {isListNotEmpty(stop.commodities) ? (
                                            <View style={styles.sectionRow}>
                                                <View style={styles.sectionColumn}>
                                                    <View style={styles.sectionBody}>
                                                        <CommodityTable stop={stop} columns={commodityColumns} />
                                                    </View>
                                                </View>
                                            </View>
                                        ) : null}
                                    </Fragment>
                                ))}
                            </View>
                        ) : null}

                        <View style={styles.sectionRow} wrap={false}>
                            <View style={styles.sectionColumn}>
                                <View style={styles.sectionHeader}>
                                    <Text style={styles.sectionHeaderText}>Freight Rate</Text>
                                </View>

                                <View style={styles.sectionBody}>
                                    <InvoiceTable
                                        columns={invoiceColumns}
                                        invoiceLineItems={lineItems}
                                        totalAmount={totalAmount}
                                        totalTitleStyle={{ textAlign: 'right' }}
                                        totalStyle={{ textAlign: 'center', border: '2px solid #333333' }}
                                    />
                                </View>
                            </View>
                        </View>

                        <View style={styles.sectionRow} wrap={false}>
                            <View style={styles.sectionColumn}>
                                <View style={styles.sectionHeader}>
                                    <Text style={styles.sectionHeaderText}>Agreement</Text>
                                </View>

                                {isObjectNotEmpty(load) && isObjectNotEmpty(load.shipper) ? (
                                    <View style={styles.sectionBody}>
                                        <Row>
                                            <Col>
                                                <RowText style={styles.text} title="Shipper" value={load.shipper.name} dataType="String" />
                                                <RowText style={styles.text} title="Email" value={load.shipper.email} dataType="String" />
                                                <RowPhoneNumberText style={styles.text} title="Phone #" phoneNumber={load.shipper.phone} phoneNumberExtension={load.shipper.phoneExt} />
                                                {isStringNotEmpty(load.shipper.fax) ? <RowPhoneNumberText style={styles.text} title="Fax #" phoneNumber={load.shipper.fax} /> : null}
                                            </Col>
                                            <Col>
                                                {isStringNotEmpty(load.serviceType) && load.serviceType === 'BROKERAGE' ? (
                                                    <>
                                                        <RowText style={styles.text} title="Broker" value="IRIS Technologies, Inc." dataType="String" />
                                                        {isListNotEmpty(load.assignedAccountRepUsers) ?
                                                            load.assignedAccountRepUsers.map((assignedAccountRepUser, index, arr) => {
                                                                return (
                                                                    <RowContactText key={`Agreement-Rep${arr.length > 1 ? ' ' + (index + 1) : ''}`} style={styles.text} title={`Rep${arr.length > 1 ? ' ' + (index + 1) : ''}`} contact={{ name: assignedAccountRepUser.firstName + ' ' + assignedAccountRepUser.lastName, email: assignedAccountRepUser.user, phone: assignedAccountRepUser.phone, phoneExt: assignedAccountRepUser.phoneExt }} />
                                                                );
                                                            }) : null}
                                                        <RowPhoneNumberText style={styles.text} title="Fax #" phoneNumber={irisFaxNumber} />
                                                    </>
                                                ) : null}
                                                <RowText style={styles.text} title="Date" value={invoice.invoiceDate} dataType="MomentDateTime" />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col style={{ textAlign: 'center', marginTop: 10, marginBottom: 10 }}>
                                                <Text style={styles.text}>By signing below, {load.shipper.name} agrees to the terms and conditions set forth below and provided herewith, if any.</Text>
                                            </Col>
                                        </Row>
                                        <View style={styles.sectionRow}>
                                            <Col style={{ alignItems: 'center', justifyContent: 'flex-end', borderBottom: '1px solid black', height: 20 }}>
                                                {isStringNotEmpty(invoice.signedByName) ? <Text style={styles.text}>{invoice.signedByName}</Text> : null}
                                            </Col>
                                            <Col style={{ alignItems: 'center', justifyContent: 'flex-end', borderBottom: '1px solid black', height: 20 }}>
                                                {isStringNotEmpty(invoice.signedByTitle) ? <Text style={styles.text}>{invoice.signedByTitle}</Text> : null}
                                            </Col>
                                        </View>
                                        <View style={styles.sectionRow}>
                                            <Col style={{ alignItems: 'center', justifyContent: 'flex-start', height: 20 }}>
                                                <Text style={styles.text}>Name</Text>
                                            </Col>
                                            <Col style={{ alignItems: 'center', justifyContent: 'flex-start', height: 20 }}>
                                                <Text style={styles.text}>Title</Text>
                                            </Col>
                                        </View>
                                        <View style={styles.sectionRow}>
                                            <Col style={{ borderBottom: '1px solid black', height: 40 }}>
                                                {isStringNotEmpty(invoice.signature) ? <Image src={invoice.signature} style={{ height: 30 }} /> : null}
                                            </Col>
                                            <Col style={{ alignItems: 'center', justifyContent: 'flex-end', borderBottom: '1px solid black', height: 40 }}>
                                                {isObjectNotEmpty(invoice.signedAt) ? <Text style={styles.text}>{stringFormatter.toFormattedString('MomentDateTime', invoice.signedAt)}</Text> : null}
                                            </Col>
                                        </View>
                                        <View style={styles.sectionRow}>
                                            <Col style={{ alignItems: 'center', justifyContent: 'flex-start', height: 20 }}>
                                                <Text style={styles.text}>Signature</Text>
                                            </Col>
                                            <Col style={{ alignItems: 'center', justifyContent: 'flex-start', height: 20 }}>
                                                <Text style={styles.text}>Date</Text>
                                            </Col>
                                        </View>

                                        {displayManualInstructions === true ? (
                                            <Row>
                                                <Col style={{ textAlign: 'center' }}>
                                                    <Text style={styles.boldText}>PLEASE RETURN THE SIGNED COPY TO <Link style={styles.text} src="mailto: loadtender@irisfreight.com">LOADTENDER@IRISFREIGHT.COM</Link></Text>
                                                    <Text style={styles.boldText}>OR FAX BACK TO {stringFormatter.toFormattedPhoneNumber(irisFaxNumber)} (ATTN: Load Id: {load.irisId})</Text>
                                                </Col>
                                            </Row>
                                        ) : null}
                                    </View>
                                ) : null}
                            </View>
                        </View>
                    </>
                ) : null}
            </Page>
        </Document>
    );
};

//#endregion

export default {
    ShipperInvoiceTemplate,
    CarrierInvoiceTemplate,
    RateConfirmationTemplate,
    PriceConfirmationTemplate,
    sendPriceConfirmationEmail,
    sendRateConfirmationEmail,
    sendShipperInvoiceEmail,
    saveInvoiceAsDocument,
    getInvoiceLineItemsAndTotal,
    registerFonts,
    irisPaymentAddress,
    irisCompanyAddress,
    irisPhoneNumber,
    irisFaxNumber,
    irisPointOfContact,
    irisCompanyName,
    irisAccountId
};