import { isListNotEmpty } from "../../shared/objectUtils";

const getVisibleNotes = (notes, userEntityType, isAdmin) => {
    let filteredNotes = [];
    if (isListNotEmpty(notes)) {
        if (userEntityType === "STAFF" && isAdmin === true) {
            filteredNotes = notes;
        } else if (userEntityType === "STAFF" && isAdmin === false) {
            filteredNotes = notes.filter(i => i.visibleTo !== "ADMIN");
        } else if (userEntityType === "DRIVER") {
            filteredNotes = notes.filter(i => (i.visibleTo === "ALL" || i.visibleTo === "DRIVER"));
        } else if (userEntityType === "CARRIER") {
            filteredNotes = notes.filter(i => (i.visibleTo === "ALL" || i.visibleTo === "DRIVER" || i.visibleTo === "CARRIER"));
        } else if (userEntityType === "PRODUCER") {
            filteredNotes = notes.filter(i => (i.visibleTo === "ALL" || i.visibleTo === "PRODUCER"));
        } else if (userEntityType === "RECEIVER") {
            filteredNotes = notes.filter(i => (i.visibleTo === "ALL" || i.visibleTo === "RECEIVER"));
        } else if (userEntityType === "SHIPPER") {
            filteredNotes = notes.filter(i => (i.visibleTo === "ALL" || i.visibleTo === "SHIPPER"));
        } else if (userEntityType === "BROKER") {
            filteredNotes = notes.filter(i => (i.visibleTo === "ALL" || i.visibleTo === "BROKER"));
        } else if (userEntityType === "FACTORING") {
            filteredNotes = notes.filter(i => (i.visibleTo === "ALL" || i.visibleTo === "FACTORING"));
        }
    }

    return filteredNotes;
};

export default {
    getVisibleNotes
}