import axiosAuthenticated from "../../api/axiosAuthenticated";
import { addFile } from "../../api/fileClient";
import Pricing from "../../shared/pricing";
import { orderBy } from "lodash";
import * as Data from './index';
import { isListEmpty, isListNotEmpty, isNotNullOrUndefined, isNumberGreaterThanZero, isNumberNotEmpty, isObjectEmpty, isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";

const loadsPath = '/loads';
const loadsOrchPath = '/orch/loads';
const activeLoadLocationsPath = '/activeLoadLocations';
const activeLoadsPath = '/activeLoad';

//#region Loads Methods

export const getChildLoadsByParentLoadId = async (parentLoadId) => {
    const loadsRes = await axiosAuthenticated.get(loadsPath, { params: { page: 1, size: 1000000, parentLoadId: parentLoadId, isDeleted: false } });
    if (loadsRes && loadsRes.status === 200) {
        return loadsRes.data.data;
    }

    return [];
};

export const getBundledLoadsWithIncludes = async (parentLoadId, carrierId) => {
    let searchParams = {
        page: 1,
        size: 1000000,
        sort: 'createdAt',
        order: 'asc',
        eta: false,
        assignedCarrierId: carrierId,
        'parentLoadId:or': parentLoadId,
        'id:or': parentLoadId
    };

    const loadsRes = await axiosAuthenticated.get(loadsOrchPath, { params: { ...searchParams } });
    if (loadsRes && loadsRes.status === 200) {
        let bundledLoads = loadsRes.data.data;

        let orderedBundledLoads = [];
        if (isListNotEmpty(bundledLoads)) {
            orderedBundledLoads = orderBy(bundledLoads, ['isParentLoad', 'createdAt'], ['desc', 'asc']);
        }

        return orderedBundledLoads;
    }

    return [];
};

export const getLoadList = async (searchParams = {}, pagination = {}) => {
    const loadsRes = await axiosAuthenticated.get(loadsOrchPath, { params: { ...searchParams } });
    if (loadsRes && loadsRes.status === 200) {
        const loads = loadsRes.data.data;
        const otherData = loadsRes.data;

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;

        return { data: loads, searchParams: searchParams, pagination: pagination };
    }

    return { data: [], searchParams: searchParams, pagination: pagination };
};

export const getMoreLoadList = async (searchParams = {}, pagination = {}, existingRecords = []) => {
    let updatedLoads = [];
    if (isListNotEmpty(existingRecords)) {
        updatedLoads = [...existingRecords];
    }

    const loadsRes = await axiosAuthenticated.get(loadsOrchPath, { params: { ...searchParams } });
    if (loadsRes && loadsRes.status === 200) {
        const loads = loadsRes.data.data;
        const otherData = loadsRes.data;

        loads.forEach((load) => {
            if (updatedLoads.find(l => l.id === load.id) === undefined) {
                updatedLoads.push(load);
            }
        });

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;
    }

    return { data: updatedLoads, searchParams: searchParams, pagination: pagination };
};

export const getInTransitLoadsWithoutLocationData = async (searchParams = {}, pagination = {}) => {
    const loadsRes = await axiosAuthenticated.get(activeLoadLocationsPath, { params: { ...searchParams } });
    if (loadsRes && loadsRes.status === 200) {
        const loads = loadsRes.data.data;
        const otherData = loadsRes.data;

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;

        return { data: loads, searchParams: searchParams, pagination: pagination };
    }

    return { data: [], searchParams: searchParams, pagination: pagination };
};

export const getLoads = async (searchParams = {}, pagination = {}) => {
    const loadsRes = await axiosAuthenticated.get(loadsPath, { params: { ...searchParams } });
    if (loadsRes && loadsRes.status === 200) {
        const loads = loadsRes.data.data;
        const otherData = loadsRes.data;

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;

        return { data: loads, searchParams: searchParams, pagination: pagination };
    }

    return { data: [], searchParams: searchParams, pagination: pagination };
};

export const getLoad = async (id) => {
    const loadsRes = await axiosAuthenticated.get(loadsPath + `/${id}`);
    if (loadsRes && loadsRes.status === 200) {
        return loadsRes.data;
    }

    return null;
};

export const getLoadsWithIncludes = async (searchParams = {}, pagination = {}) => {
    const loadsRes = await axiosAuthenticated.get(loadsOrchPath, { params: { ...searchParams } });
    if (loadsRes && loadsRes.status === 200) {
        const loads = loadsRes.data.data;
        const otherData = loadsRes.data;

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;

        return { data: loads, searchParams: searchParams, pagination: pagination };
    }

    return { data: [], searchParams: searchParams, pagination: pagination };
};

export const getLoadWithIncludes = async (id, withEta = false) => {
    const loadsRes = await axiosAuthenticated.get(loadsOrchPath + `/${id}`, { params: { eta: withEta } });
    if (loadsRes && loadsRes.status === 200) {
        return loadsRes.data;
    }

    return null;
};

export const getActiveLoadId = async (driverAccountUserId) => {
    const activeLoadRes = await axiosAuthenticated.get(activeLoadsPath + `?driverId=${driverAccountUserId}`);
    if (activeLoadRes && activeLoadRes.status === 200) {
        const activeLoadData = activeLoadRes.data;

        if (isObjectNotEmpty(activeLoadData)) {
            return activeLoadData.loadId;
        }
    }

    return null;
};

export const addLoad = async (loadPayload, stopsPayload, invoiceLineItemsPayload, docFiles) => {
    if (isListNotEmpty(stopsPayload)) {
        loadPayload.stops = [...stopsPayload];
    }

    const loadsRes = await axiosAuthenticated.post(loadsPath, { ...loadPayload, isParentLoad: true });
    if (loadsRes && loadsRes.status === 201) {
        let parentLoad = loadsRes.data;
        let parentLoadStops = parentLoad.stops;
        if (isListNotEmpty(parentLoadStops)) {

            // Add BOL Documents
            if (isListNotEmpty(docFiles)) {
                for (let stop of parentLoadStops) {
                    let sequence = stop.sequence;
                    let stopDocFiles = docFiles.filter(doc => doc.stopId === sequence);
                    if (isListNotEmpty(stopDocFiles)) {
                        for (let i = 0; i < stopDocFiles.length; i++) {
                            let docFile = stopDocFiles[i];
                            if (isObjectNotEmpty(docFile)) {
                                await addFile(docFile.file, "STOP", stop.id, "BOL", `BOL #${docFile.bolNumber} - ${i + 1}`, "", "ALL", null, null, null, null, [{ entityType: 'STOP', entityId: stop.id }, { entityType: 'LOAD', entityId: parentLoad.id }]);
                            }
                        }
                    }
                }
            }

            let newInvoiceLineItemsPayload = [];
            // Add Invoice Line Items
            if (isListNotEmpty(invoiceLineItemsPayload)) {
                invoiceLineItemsPayload.forEach((invoiceLineItem) => {
                    let newInvoiceLineItem = {
                        ...invoiceLineItem,
                        loadId: parentLoad.id
                    };

                    // Get the right StopId, the Sequence is already subtracted
                    if (isStringNotEmpty(invoiceLineItem.stopId)) {
                        let filteredStop = parentLoadStops.find(stop => stop.sequence === invoiceLineItem.stopId);
                        if (isObjectNotEmpty(filteredStop)) {
                            newInvoiceLineItem.stopId = filteredStop.id;
                        }
                    }

                    newInvoiceLineItemsPayload.push({ ...newInvoiceLineItem });
                });
            }

            let childLoadIds = [];
            let generatedChildLoadCount = 0;
            if (isNumberGreaterThanZero(parentLoad.childLoadInitCount)) {
                // Generate the child loads as well
                let childLoadsPayload = [];

                for (let i = 0; i < parentLoad.childLoadInitCount; i++) {
                    let childLoadPayload = {
                        ...loadPayload,
                        parentLoadId: parentLoad.id,
                        isParentLoad: false
                    };

                    childLoadPayload.childLoadInitCount = undefined;

                    if (isStringNotEmpty(childLoadPayload.parentName)) {
                        childLoadPayload.name = childLoadPayload.parentName;
                        childLoadPayload.parentName = undefined;
                    }

                    if (isListNotEmpty(stopsPayload)) {
                        childLoadPayload.stops = [...stopsPayload];
                    }
                    childLoadsPayload.push(childLoadPayload);
                }

                try {
                    const childLoadsRes = await axiosAuthenticated.post(loadsPath, [...childLoadsPayload]);
                    if (childLoadsRes && childLoadsRes.status === 201) {
                        let childLoads = childLoadsRes.data;
                        if (isListNotEmpty(childLoads)) {
                            generatedChildLoadCount = childLoads.length;
                            childLoadIds = childLoads.map((childLoad) => { return childLoad.id; });

                            for (let i = 0; i < childLoads.length; i++) {
                                let childLoad = childLoads[i];
                                if (isObjectNotEmpty(childLoad)) {
                                    let childLoadStops = childLoad.stops;
                                    if (isListNotEmpty(childLoadStops)) {

                                        // Add Invoice Line Items
                                        if (isListNotEmpty(invoiceLineItemsPayload)) {
                                            invoiceLineItemsPayload.forEach((invoiceLineItem) => {
                                                let newInvoiceLineItem = {
                                                    ...invoiceLineItem,
                                                    loadId: childLoad.id
                                                };

                                                // Get the right StopId, the Sequence is already subtracted
                                                if (isStringNotEmpty(invoiceLineItem.stopId)) {
                                                    let filteredStop = childLoadStops.find(stop => stop.sequence === invoiceLineItem.stopId);
                                                    if (isObjectNotEmpty(filteredStop)) {
                                                        newInvoiceLineItem.stopId = filteredStop.id;
                                                    }
                                                }

                                                newInvoiceLineItemsPayload.push({ ...newInvoiceLineItem });
                                            });
                                        }
                                    } else {
                                        throw new Error(`Failed to create stops for Load Bundle ${parentLoad.irisId}.`);
                                    }
                                }
                            }
                        }
                    }
                } catch (error) {
                    // delete all childloads that were generated if not all of them were created and delete the parent load

                    let generatedChildLoads = await getChildLoadsByParentLoadId(parentLoad.id);
                    let generatedChildLoadIds = [];
                    if (isListNotEmpty(generatedChildLoads)) {
                        generatedChildLoadIds = generatedChildLoads.map((generatedChildLoad) => { return generatedChildLoad.id; });
                        await removeLoads(generatedChildLoadIds);
                    }
                    await removeLoad(parentLoad.id);

                    throw new Error(`Failed to create the load bundle ${parentLoad.irisId}. Please try again or contact Customer Support for assistance and provide error code: 0101.`);
                }

                if (generatedChildLoadCount !== parentLoad.childLoadInitCount) {
                    // delete all childloads that were generated if not all of them were created and delete the parent load

                    let generatedChildLoads = await getChildLoadsByParentLoadId(parentLoad.id);
                    let generatedChildLoadIds = [];
                    if (isListNotEmpty(generatedChildLoads)) {
                        generatedChildLoadIds = generatedChildLoads.map((generatedChildLoad) => { return generatedChildLoad.id; });
                        await removeLoads(generatedChildLoadIds);
                    }
                    await removeLoad(parentLoad.id);

                    throw new Error(`Failed to create the load bundle ${parentLoad.irisId}. Please try again or contact Customer Support for assistance and provide error code: 0102.`);
                }

                if (isListNotEmpty(newInvoiceLineItemsPayload)) {
                    try {
                        const newInvoiceLineItems = await Data.addInvoiceLineItems([...newInvoiceLineItemsPayload]);
                        if (isListEmpty(newInvoiceLineItems)) {
                            throw new Error(`Failed to create invoice line items for Load ${parentLoad.irisId}.`);
                        }
                    } catch (error) {
                        // delete all childloads and parent load if invoice line items are not generated
                        // by this point, all the child loads would have been generated if they were supposed to or an error would have been thrown above
                        await removeLoads(childLoadIds);
                        await removeLoad(parentLoad.id);

                        throw new Error(`Failed to create the load bundle ${parentLoad.irisId}. Please try again or contact Customer Support for assistance and provide error code: 0103.`);
                    }
                }
            } else {
                if (isListNotEmpty(newInvoiceLineItemsPayload)) {
                    try {
                        const newInvoiceLineItems = await Data.addInvoiceLineItems([...newInvoiceLineItemsPayload]);
                        if (isListEmpty(newInvoiceLineItems)) {
                            throw new Error(`Failed to create invoice line items for Load ${parentLoad.irisId}.`);
                        }
                    } catch (error) {
                        // delete the parent load if invoice line items are not generated
                        await removeLoad(parentLoad.id);

                        throw new Error(`Failed to create the load ${parentLoad.irisId}. Please try again or contact Customer Support for assistance and provide error code: 0104.`);
                    }
                }
            }
        } else {
            // delete parent load if stops are not generated
            await removeLoad(parentLoad.id);

            throw new Error(`Failed to create the load ${parentLoad.irisId}. Please try again or contact Customer Support for assistance and provide error code: 0105.`);
        }

        await Data.addLoadEvent({ loadId: parentLoad.id, eventType: 'LOAD_UPDATED', changeType: 'LOAD_ADDED' });

        return parentLoad;
    }

    return null;
};

export const addDuplicateLoads = async (loadPayload, stopsPayload, invoiceLineItemsPayload, duplicateCount = 1) => {
    let newInvoiceLineItemsPayload = [];
    let duplicateLoadIds = [];
    let generatedDuplicateLoadCount = 0;
    let duplicateLoads = [];
    if (isNumberGreaterThanZero(duplicateCount)) {
        // Generate the duplicate loads
        let duplicateLoadsPayload = [];

        for (let i = 0; i < duplicateCount; i++) {
            let duplicateLoadPayload = {
                ...loadPayload,
                isParentLoad: true
            };

            if (isListNotEmpty(stopsPayload)) {
                duplicateLoadPayload.stops = [...stopsPayload];
            }

            duplicateLoadsPayload.push(duplicateLoadPayload);
        }

        try {
            const duplicateLoadsRes = await axiosAuthenticated.post(loadsPath, [...duplicateLoadsPayload]);
            if (duplicateLoadsRes && duplicateLoadsRes.status === 201) {
                duplicateLoads = duplicateLoadsRes.data;
                if (isListNotEmpty(duplicateLoads)) {
                    generatedDuplicateLoadCount = duplicateLoads.length;
                    duplicateLoadIds = duplicateLoads.map((duplicateLoad) => { return duplicateLoad.id; });

                    for (let i = 0; i < duplicateLoads.length; i++) {
                        let duplicateLoad = duplicateLoads[i];
                        if (isObjectNotEmpty(duplicateLoad)) {
                            let duplicateLoadStops = duplicateLoad.stops;
                            if (isListNotEmpty(duplicateLoadStops)) {

                                // Add Invoice Line Items
                                if (isListNotEmpty(invoiceLineItemsPayload)) {
                                    invoiceLineItemsPayload.forEach((invoiceLineItem) => {
                                        let newInvoiceLineItem = {
                                            ...invoiceLineItem,
                                            loadId: duplicateLoad.id
                                        };

                                        // Get the right StopId, the Sequence is already subtracted
                                        if (isStringNotEmpty(invoiceLineItem.stopId)) {
                                            let filteredStop = duplicateLoadStops.find(stop => stop.sequence === invoiceLineItem.stopId);
                                            if (isObjectNotEmpty(filteredStop)) {
                                                newInvoiceLineItem.stopId = filteredStop.id;
                                            }
                                        }

                                        newInvoiceLineItemsPayload.push({ ...newInvoiceLineItem });
                                    });
                                }
                            } else {
                                throw new Error(`Failed to create stops for Duplicate Load ${duplicateLoad.irisId}.`);
                            }
                        }
                    }
                }
            }
        } catch (error) {
            // delete all duplicate loads that were generated if not all of them were created
            if (isListNotEmpty(duplicateLoadIds)) {
                await removeLoads(duplicateLoadIds);
            }

            throw new Error(`Failed to create the duplicate loads ${duplicateLoadIds.join(', ')}. Please try again or contact Customer Support for assistance and provide error code: 0101.`);
        }

        if (generatedDuplicateLoadCount !== duplicateCount) {
            // delete all duplicate loads that were generated if not all of them were created
            if (isListNotEmpty(duplicateLoadIds)) {
                await removeLoads(duplicateLoadIds);
            }

            throw new Error(`Failed to create the duplicate loads ${duplicateLoadIds.join(', ')}. Please try again or contact Customer Support for assistance and provide error code: 0102.`);
        }

        if (isListNotEmpty(newInvoiceLineItemsPayload)) {
            try {
                const newInvoiceLineItems = await Data.addInvoiceLineItems([...newInvoiceLineItemsPayload]);
                if (isListEmpty(newInvoiceLineItems)) {
                    throw new Error(`Failed to create invoice line items for the duplicate loads ${duplicateLoadIds.join(', ')}.`);
                }
            } catch (error) {
                // delete all duplicate loads if invoice line items are not generated
                // by this point, all the duplicate loads would have been generated if they were supposed to or an error would have been thrown above
                await removeLoads(duplicateLoadIds);

                throw new Error(`Failed to create the duplicate loads ${duplicateLoadIds.join(', ')}. Please try again or contact Customer Support for assistance and provide error code: 0103.`);
            }
        }

        for (let i = 0; i < duplicateLoads.length; i++) {
            let duplicateLoad = duplicateLoads[i];
            await Data.addLoadEvent({ loadId: duplicateLoad.id, eventType: 'LOAD_UPDATED', changeType: 'LOAD_ADDED' });
        }

        return duplicateLoads;
    }

    return [];
};

export const addMissingChildLoads = async (id) => {
    const parentLoad = await getLoadWithIncludes(id, false);
    if (isObjectNotEmpty(parentLoad)) {
        let loadPayload = {
            unitOfMeasure: "ENGLISH",
            equipmentNeeds: parentLoad.equipmentNeeds,
            practicalDistance: parentLoad.practicalDistance,
            practicalDistanceUnit: parentLoad.practicalDistanceUnit,
            shipperId: parentLoad.shipperId,
            createdByEntityType: parentLoad.createdByEntityType,
            assignedCarrierId: parentLoad.assignedCarrierId,
            pickUpDateTime: parentLoad.pickUpDateTime,
            name: parentLoad.parentName,
            serviceType: parentLoad.serviceType,
            shipperRatePerMile: parentLoad.shipperRatePerMile,
            shipperRatePerMileUnit: parentLoad.shipperRatePerMileUnit,
            shipperAmount: parentLoad.shipperAmount,
            shipperAmountUnit: parentLoad.shipperAmountUnit,
            shipperBalance: parentLoad.shipperBalance,
            shipperBalanceUnit: parentLoad.shipperBalanceUnit,
            carrierRatePerMile: parentLoad.carrierRatePerMile,
            carrierRatePerMileUnit: parentLoad.carrierRatePerMileUnit,
            carrierAmount: parentLoad.carrierAmount,
            carrierAmountUnit: parentLoad.carrierAmountUnit,
            carrierBalance: parentLoad.carrierBalance,
            carrierBalanceUnit: parentLoad.carrierBalanceUnit
        };

        if (isListNotEmpty(parentLoad.driverRequirements)) {
            loadPayload.driverRequirements = parentLoad.driverRequirements;
        }

        if (isListNotEmpty(parentLoad.equipmentSpecifics)) {
            loadPayload.equipmentSpecifics = parentLoad.equipmentSpecifics;
        }
        let stopsPayload = [];
        let invoiceLineItemsArray = [];
        let newInvoiceLineItemsPayload = [];

        const stopsArray = await Data.getLoadStops(parentLoad.id, parentLoad);
        if (isListNotEmpty(stopsArray)) {
            stopsArray.forEach((stop) => {
                let newStop = {
                    unitOfMeasure: "ENGLISH",
                    latitude: stop.latitude,
                    longitude: stop.longitude,
                    timeZone: stop.timeZone,
                    stopType: stop.stopType,
                    sequence: stop.sequence,
                    stopLocationId: stop.stopLocationId,
                    hasDriverAssist: stop.hasDriverAssist,
                    driverAssist: stop.driverAssist,
                    driverAssistUnit: stop.driverAssistUnit,
                    hasLumperFee: stop.hasLumperFee,
                    lumperFee: stop.lumperFee,
                    lumperFeeUnit: stop.lumperFeeUnit,
                    bolNumbers: stop.bolNumbers,
                    apptType: stop.apptType,
                    loadingType: stop.loadingType,
                    specialInstructions: stop.specialInstructions,
                    apptInstructions: stop.apptInstructions,
                    apptNumber: stop.apptNumber,
                    apptWindowStartDateTime: stop.apptWindowStartDateTime,
                    apptWindowEndDateTime: stop.apptWindowEndDateTime,
                    requestedDateTime: stop.requestedDateTime,
                    apptCallAheadDateTime: stop.apptCallAheadDateTime,
                    apptDateTime: stop.apptDateTime,
                    apptPointOfContact: stop.apptPointOfContact
                };

                if (isListNotEmpty(stop.commodities)) {
                    newStop.commodities = stop.commodities;
                }

                stopsPayload.push({ ...newStop });
            });

            const invoiceLineItems = await Data.getInvoiceLineItemsByLoadId(parentLoad.id);
            if (isListNotEmpty(invoiceLineItems)) {
                invoiceLineItems.forEach((invoiceLineItem) => {
                    let newInvoiceLineItem = {
                        description: invoiceLineItem.description,
                        itemType: invoiceLineItem.itemType,
                        isIrisFee: invoiceLineItem.isIrisFee,
                        fromEntityType: invoiceLineItem.fromEntityType,
                        fromEntityId: invoiceLineItem.fromEntityId,
                        toEntityType: invoiceLineItem.toEntityType,
                        toEntityId: invoiceLineItem.toEntityId,
                        baseAmount: invoiceLineItem.baseAmount,
                        baseAmountUnit: invoiceLineItem.baseAmountUnit,
                        totalAmount: invoiceLineItem.totalAmount,
                        totalAmountUnit: invoiceLineItem.totalAmountUnit,
                        quantity: invoiceLineItem.quantity,
                        quantityUnit: invoiceLineItem.quantityUnit,
                        status: invoiceLineItem.status,
                        approvalStatus: invoiceLineItem.approvalStatus,
                        isOpen: invoiceLineItem.isOpen
                    };

                    if (isStringNotEmpty(invoiceLineItem.stopId)) {
                        let filteredStop = stopsArray.find(i => i.id === invoiceLineItem.stopId);
                        if (isObjectNotEmpty(filteredStop)) {
                            newInvoiceLineItem.stopId = filteredStop.sequence;
                        }
                    }

                    invoiceLineItemsArray.push(newInvoiceLineItem);
                });
            }
        }

        let childLoadInitCount = isNumberGreaterThanZero(parentLoad.childLoadInitCount) ? parentLoad.childLoadInitCount : 0;
        if (childLoadInitCount > 0) {
            // generate the child loads as well
            let generatedChildLoadCount = isNumberGreaterThanZero(parentLoad.childLoadActualCount) ? parentLoad.childLoadActualCount : 0;
            if (generatedChildLoadCount < childLoadInitCount) {
                try {
                    let childLoadsPayload = [];
                    for (let i = generatedChildLoadCount; i < childLoadInitCount; i++) {
                        let childLoadPayload = {
                            ...loadPayload,
                            parentLoadId: parentLoad.id,
                            isParentLoad: false
                        };
                        if (isStringNotEmpty(childLoadPayload.parentName)) {
                            childLoadPayload.name = childLoadPayload.parentName;
                            childLoadPayload.parentName = undefined;
                        }

                        if (isListNotEmpty(stopsPayload)) {
                            childLoadPayload.stops = [...stopsPayload];
                        }

                        childLoadsPayload.push(childLoadPayload);
                    }

                    const childLoadsRes = await axiosAuthenticated.post(loadsPath, [...childLoadsPayload]);
                    if (childLoadsRes && childLoadsRes.status === 201) {
                        let childLoads = childLoadsRes.data;
                        if (isListNotEmpty(childLoads)) {
                            generatedChildLoadCount = generatedChildLoadCount + childLoads.length;

                            for (let i = 0; i < childLoads.length; i++) {
                                let childLoad = childLoads[i];
                                if (isObjectNotEmpty(childLoad)) {
                                    let childLoadStops = childLoad.stops;

                                    // Add Invoice Line Items
                                    if (isListNotEmpty(invoiceLineItemsArray)) {
                                        invoiceLineItemsArray.forEach((invoiceLineItem) => {
                                            let newInvoiceLineItem = {
                                                ...invoiceLineItem,
                                                loadId: childLoad.id
                                            };

                                            // Get the right StopId, the Sequence is already subtracted
                                            if (isStringNotEmpty(invoiceLineItem.stopId)) {
                                                let filteredStop = childLoadStops.find(stop => stop.sequence === invoiceLineItem.stopId);
                                                if (isObjectNotEmpty(filteredStop)) {
                                                    newInvoiceLineItem.stopId = filteredStop.id;
                                                }
                                            }

                                            newInvoiceLineItemsPayload.push({ ...newInvoiceLineItem });
                                        });
                                    }
                                }
                            }
                        }
                    }
                } catch (childLoadError) {
                    throw new Error(childLoadError.message);
                }
            }

            if (generatedChildLoadCount !== childLoadInitCount) {
                throw new Error(`Failed to create all the loads for this load bundle. Please contact Customer Support for assistance.`);
            }

            if (isListNotEmpty(newInvoiceLineItemsPayload)) {
                const newInvoiceLineItems = await Data.addInvoiceLineItems([...newInvoiceLineItemsPayload]);
                if (isListEmpty(newInvoiceLineItems)) {
                    throw new Error(`Failed to create the invoice line items load bundle. Please contact Customer Support for assistance.`);
                }
            }
        }

        return true;
    }

    return false;
};

export const updateLoad = async (id, payload) => {
    const loadsRes = await axiosAuthenticated.put(loadsPath + `/${id}`, { ...payload });
    if (loadsRes && loadsRes.status === 200) {
        let changeType = 'LOAD_UPDATED';
        if (payload.driverIds !== undefined) {
            changeType = 'DRIVERS_UPDATED';
        } else if (payload.assetIds !== undefined) {
            changeType = 'ASSETS_UPDATED';
        } else if (payload.assignedCarrierId !== undefined) {
            changeType = 'CARRIER_UPDATED';
        }

        await Data.addLoadEvent({ loadId: id, eventType: 'LOAD_UPDATED', changeType: changeType });

        return loadsRes.data;
    }

    return null;
};

export const updateBundledLoad = async (id, payload, oldLoad) => {
    const loadsRes = await axiosAuthenticated.put(loadsPath + `/${id}`, { ...payload });
    if (loadsRes && loadsRes.status === 200) {
        let updatedLoad = { ...oldLoad };
        let changeType = 'LOAD_UPDATED';
        if (payload.driverIds !== undefined) {
            updatedLoad.driverIds = payload.driverIds;
            changeType = 'DRIVERS_UPDATED';
        } else if (payload.assetIds !== undefined) {
            updatedLoad.assetIds = payload.assetIds;
            changeType = 'ASSETS_UPDATED';
        } else if (payload.assignedCarrierId !== undefined) {
            updatedLoad.assignedCarrierId = payload.assignedCarrierId;
            changeType = 'CARRIER_UPDATED';
        }

        await Data.addLoadEvent({ loadId: id, eventType: 'LOAD_UPDATED', changeType: changeType });

        return updatedLoad;
    }

    return null;
};

export const dispatchBundledLoad = async (load) => {
    const loadEventRes = await Data.addLoadEvent({ loadId: load.id, eventType: 'LOAD_SCHEDULED' });
    if (loadEventRes) {
        return { ...load, loadStatus: 'SCHEDULED' };
    }

    return null;
};

export const removeLoad = async (id) => {
    const loadsRes = await axiosAuthenticated.put(loadsPath + `/${id}`, { isDeleted: true, loadStatus: 'CANCELLED' });
    if (loadsRes && loadsRes.status === 200) {
        await Data.addLoadEvent({ loadId: id, eventType: 'LOAD_UPDATED', changeType: 'LOAD_REMOVED' });

        return loadsRes.data;
    }

    return null;
};

export const removeLoads = async (ids) => {
    if (isListNotEmpty(ids)) {
        let loadsToRemove = [];
        for (let i = 0; i < ids.length; i++) {
            const loadsRes = await axiosAuthenticated.put(loadsPath + `/${ids[i]}`, { isDeleted: true, loadStatus: 'CANCELLED' });
            if (loadsRes && loadsRes.status === 200) {
                const updatedLoad = loadsRes.data;

                await Data.addLoadEvent({ loadId: ids[i], eventType: 'LOAD_UPDATED', changeType: 'LOAD_REMOVED' });

                loadsToRemove.push(updatedLoad);
            }
        }

        return loadsToRemove;
    }

    return [];
};

export const removeBundledLoad = async (id, load, parentLoadId, parentLoad, existingBundledLoads = []) => {
    let updatedBundledLoads = [];
    if (isListNotEmpty(existingBundledLoads)) {
        updatedBundledLoads = [...existingBundledLoads];
    }

    let updatedLoad = { ...load, isDeleted: true };

    let updatedParentLoad = { ...parentLoad };
    let parentLoadPayload = {};
    let newChildLoadInitCount = updatedParentLoad.childLoadInitCount;
    let newChildLoadActualCount = updatedParentLoad.childLoadActualCount;
    let updatedChildLoads = isListNotEmpty(updatedParentLoad.childLoads) ? [...updatedParentLoad.childLoads] : [];

    if (isNumberGreaterThanZero(updatedParentLoad.childLoadInitCount)) {
        newChildLoadInitCount = updatedParentLoad.childLoadInitCount - 1;
        updatedParentLoad.childLoadInitCount = newChildLoadInitCount;
        parentLoadPayload.childLoadInitCount = newChildLoadInitCount;
    }

    if (isListNotEmpty(updatedChildLoads)) {
        let childLoadToRemoveIndex = updatedChildLoads.findIndex(i => i.childLoadId === id);
        if (childLoadToRemoveIndex !== -1) {
            updatedChildLoads.splice(childLoadToRemoveIndex, 1);
            updatedParentLoad.childLoads = updatedChildLoads;
            parentLoadPayload.childLoads = updatedChildLoads;

            // set the actual count to be the length of the new childLoads array
            newChildLoadActualCount = updatedChildLoads.length;
            updatedParentLoad.childLoadActualCount = newChildLoadActualCount;
            parentLoadPayload.childLoadActualCount = newChildLoadActualCount;
        }
    }

    if (isObjectNotEmpty(parentLoadPayload)) {
        const parentLoadsRes = await axiosAuthenticated.put(loadsPath + `/${parentLoadId}`, { ...parentLoadPayload });
        if (parentLoadsRes && parentLoadsRes.status === 200) {
            await Data.addLoadEvent({ loadId: parentLoadId, eventType: 'LOAD_UPDATED', changeType: 'LOAD_UPDATED' });

            if (isListNotEmpty(updatedBundledLoads)) {
                const index = updatedBundledLoads.findIndex(obj => obj.id === parentLoadId);
                if (index !== -1) {
                    updatedBundledLoads = [
                        ...updatedBundledLoads.slice(0, index), // everything before current obj
                        updatedParentLoad,
                        ...updatedBundledLoads.slice(index + 1), // everything after current obj
                    ];
                }
            }
        }
    }

    const loadsRes = await axiosAuthenticated.put(loadsPath + `/${id}`, { isDeleted: true });
    if (loadsRes && loadsRes.status === 200) {
        await Data.addLoadEvent({ loadId: id, eventType: 'LOAD_UPDATED', changeType: 'LOAD_UPDATED' });

        if (isListNotEmpty(updatedBundledLoads)) {
            const index = updatedBundledLoads.findIndex(obj => obj.id === id);
            if (index !== -1) {
                updatedBundledLoads = [
                    ...updatedBundledLoads.slice(0, index), // everything before current obj
                    updatedLoad,
                    ...updatedBundledLoads.slice(index + 1), // everything after current obj
                ];
            }
        }
    }

    return updatedBundledLoads;
};

export const unremoveLoad = async (id) => {
    const loadsRes = await axiosAuthenticated.put(loadsPath + `/${id}`, { isDeleted: false });
    if (loadsRes && loadsRes.status === 200) {
        await Data.addLoadEvent({ loadId: id, eventType: 'LOAD_UPDATED', changeType: 'LOAD_UNREMOVED' });

        return loadsRes.data;
    }

    return null;
};

export const updateAssignedCarrierForLoad = async (id, payload) => {
    // backend should: update carrier for the load, update invoicelineitems (change the accountId for the carrier lines to the new carrier), remove drivers and remove assets
    const loadsRes = await axiosAuthenticated.put(loadsPath + `/${id}`, { ...payload });
    if (loadsRes && loadsRes.status === 200) {
        let updatedLoad = loadsRes.data;
        // If the Load is pending rate confirmation, then set the load status back to approved
        if (isNotNullOrUndefined(updatedLoad.loadStatus) && updatedLoad.loadStatus === "PENDING_RATE_CONFIRMATION") {
            return await Data.addLoadEvent({ loadId: id, eventType: 'LOAD_APPROVED' });
        } else {
            return await Data.addLoadEvent({ loadId: id, eventType: 'LOAD_UPDATED', changeType: 'LOAD_UPDATED' });
        }
    }

    return null;
};

export const removeDriverFromLoad = async (id, payload, accountUser, accounts = []) => {
    const loadsRes = await axiosAuthenticated.put(loadsPath + `/${id}`, { ...payload });
    if (loadsRes && loadsRes.status === 200) {
        let updatedLoad = loadsRes.data;
        try {
            const scheduledLoads = isObjectNotEmpty(accountUser) && isObjectNotEmpty(accountUser.driver) && accountUser.driver.scheduledLoads ? accountUser.driver.scheduledLoads : 0;
            // If the Load is scheduled then decrement the scheduled load count of the removed driver
            if (isNotNullOrUndefined(updatedLoad.loadStatus) && updatedLoad.loadStatus === 'SCHEDULED') {
                if (isStringNotEmpty(accountUser.id) && isNumberNotEmpty(scheduledLoads) && Number.isInteger(scheduledLoads)) {
                    let accountUserPayload = {};
                    accountUserPayload.driver = {};
                    accountUserPayload.driver.scheduledLoads = scheduledLoads > 0 ? scheduledLoads - 1 : 0;

                    await Data.updateAccountUser(accountUser.id, accountUserPayload, true, accounts);
                }
            }
        } catch (error) {
            console.log(error.message);
        }

        // If the Load is scheduled or beyond, fire off the load event to set the load back to booked
        if (isStringNotEmpty(updatedLoad.loadStatus) && (updatedLoad.loadStatus !== "PENDING" && updatedLoad.loadStatus !== "APPROVED" && updatedLoad.loadStatus !== "PENDING_RATE_CONFIRMATION" && updatedLoad.loadStatus !== "BOOKED")) {
            return await Data.addLoadEvent({ loadId: id, eventType: 'LOAD_BOOKED' });
        } else {
            return await Data.addLoadEvent({ loadId: id, eventType: 'LOAD_UPDATED', changeType: 'DRIVERS_UPDATED' });
        }
    }

    return null;
};

export const removeDriverFromBundledLoad = async (id, payload, oldLoad, accountUser, accounts = []) => {
    let updatedLoad = { ...oldLoad };

    if (isListNotEmpty(payload.driverIds)) {
        updatedLoad.driverIds = payload.driverIds;
    } else {
        updatedLoad.driverIds = [];
    }

    const loadsRes = await axiosAuthenticated.put(loadsPath + `/${id}`, { ...payload });
    if (loadsRes && loadsRes.status === 200) {
        try {
            const scheduledLoads = isObjectNotEmpty(accountUser) && isObjectNotEmpty(accountUser.driver) && accountUser.driver.scheduledLoads ? accountUser.driver.scheduledLoads : 0;
            // If the Load is scheduled then decrement the scheduled load count of the removed driver
            if (updatedLoad.loadStatus !== undefined && updatedLoad.loadStatus !== null && updatedLoad.loadStatus === 'SCHEDULED') {
                if (isStringNotEmpty(accountUser.id) && isNumberNotEmpty(scheduledLoads) && Number.isInteger(scheduledLoads)) {
                    let accountUserPayload = {};
                    accountUserPayload.driver = {};
                    accountUserPayload.driver.scheduledLoads = scheduledLoads > 0 ? scheduledLoads - 1 : 0;

                    await Data.updateAccountUser(accountUser.id, accountUserPayload, true, accounts);
                }
            }
        } catch (error) {
            console.log(error.message);
        }

        // If the Load is scheduled or beyond, fire off the load event to set the load back to booked
        if (isStringNotEmpty(updatedLoad.loadStatus) && (updatedLoad.loadStatus !== "PENDING" && updatedLoad.loadStatus !== "APPROVED" && updatedLoad.loadStatus !== "PENDING_RATE_CONFIRMATION" && updatedLoad.loadStatus !== "BOOKED")) {
            await Data.addLoadEvent({ loadId: id, eventType: 'LOAD_BOOKED' });
        } else {
            await Data.addLoadEvent({ loadId: id, eventType: 'LOAD_UPDATED', changeType: 'DRIVERS_UPDATED' });
        }



        return updatedLoad;
    }

    return null;
};

export const updatePriceConfirmation = async (id, file, approvedPriceConfirmation = false, isShipper = false) => {
    let isReviewed = null;
    let reviewStatus = null;
    if (approvedPriceConfirmation === true) {
        isReviewed = true;
        reviewStatus = "APPROVED";
    }

    let document = null;
    if (isNotNullOrUndefined(file)) {
        document = await addFile(file, 'LOAD', id, "PRICE_CONFIRMATION", "Shipper Load Tender", "", "SHIPPER", null, isReviewed, reviewStatus, null, [{ entityType: 'LOAD', entityId: id }]);
    }

    if (isObjectNotEmpty(document) || isShipper === true) {
        // Now that price confirmation document has been added, approve the load
        return await Data.addLoadEvent({ loadId: id, eventType: 'LOAD_APPROVED' });
    }

    return null;
};

export const updateRateConfirmation = async (id, file, approvedRateConfirmation = false, isCarrier = false) => {
    let isReviewed = null;
    let reviewStatus = null;
    if (approvedRateConfirmation === true) {
        isReviewed = true;
        reviewStatus = "APPROVED";
    }

    let document = null;
    if (isNotNullOrUndefined(file)) {
        document = await addFile(file, 'LOAD', id, "RATE_CONFIRMATION", "Carrier Rate Confirmation", "", "CARRIER", null, isReviewed, reviewStatus, null, [{ entityType: 'LOAD', entityId: id }]);
    }

    if (isObjectNotEmpty(document) || isCarrier === true) {
        // Now that price confirmation document has been added, approve the load
        return await Data.addLoadEvent({ loadId: id, eventType: 'LOAD_REQUEST_TO_CARRIER_APPROVED' });
    }

    return null;
};

export const startLoad = async (payload, load, drivers, assets, latitude = null, longitude = null) => {
    // make sure assets and drivers are available - will throw an error if any aren't available or drivers aren't assigned to the load
    const driversAndAssetsAreAvailable = await Data.checkAvailabilityOfLoadDriversAndAssets(drivers, assets);
    if (driversAndAssetsAreAvailable === true) {
        let hasCarrierWashoutLogFileBeenAdded = true;
        if (isObjectNotEmpty(payload.carrierWashoutLogFile)) {
            let carrierWashoutLogFile = { ...payload.carrierWashoutLogFile };
            let document = await addFile(carrierWashoutLogFile, "LOAD", load.id, "CARRIER_WASHOUT_LOG", "Carrier Washout Log", "", "ALL", null, null, null, null, [{ entityType: 'LOAD', entityId: load.id }]);
            if (isObjectEmpty(document)) {
                hasCarrierWashoutLogFileBeenAdded = false;
            }
        }

        if (hasCarrierWashoutLogFileBeenAdded === true) {
            let newLoadEvent = { loadId: load.id, eventType: 'LOAD_STARTED' };
            if (isNumberNotEmpty(latitude)) {
                newLoadEvent.latitude = latitude;
            }
            if (isNumberNotEmpty(longitude)) {
                newLoadEvent.longitude = longitude;
            }
            const loadEventUpdate = await Data.addLoadEvent(newLoadEvent);

            try {
                // set initial location for load
                await Data.addLoadLocation(load.id, load.irisId, latitude, longitude, true);
            } catch (error) {
                // console.log(error);
            }

            return loadEventUpdate;
        } else {
            throw new Error('Failed to upload the carrier washout log file.');
        }
    }

    return null;
};

export const completeLoad = async (id, payload, latitude = null, longitude = null) => {
    let docFiles = isListNotEmpty(payload.docFiles) ? [...payload.docFiles] : [];

    let haveAllDocFilesBeenAdded = true;
    for (let i = 0; i < docFiles.length; i++) {
        let file = docFiles[i].file;
        let documentType = docFiles[i].metaData.documentType;
        let displayName = docFiles[i].metaData.displayName;
        let description = docFiles[i].metaData.description;
        let document = await addFile(file, "LOAD", id, documentType, displayName, description, "ALL", null, null, null, null, [{ entityType: 'LOAD', entityId: id }]);
        if (isObjectEmpty(document)) {
            haveAllDocFilesBeenAdded = false;
        }
    }

    if (haveAllDocFilesBeenAdded === true) {
        let newLoadEvent = { loadId: id, eventType: 'LOAD_COMPLETED' };
        if (isNumberNotEmpty(latitude)) {
            newLoadEvent.latitude = latitude;
        }
        if (isNumberNotEmpty(longitude)) {
            newLoadEvent.longitude = longitude;
        }

        return await Data.addLoadEvent(newLoadEvent);
    } else {
        throw new Error('Failed to upload all the files');
    }
};

export const checkAvailabilityOfLoadDriversAndAssets = async (drivers = [], assets = []) => {
    // console.log(assets);
    // console.log(drivers);
    // drivers have to be assigned to the load for the load to start
    if (isListNotEmpty(drivers)) {
        let driverIds = drivers.map(i => i.id).join('|');

        const res = await Data.getAccountUsers({ id: driverIds, isDeleted: false });
        const updatedDrivers = res.data;
        const unavailableDriver = updatedDrivers.find(i => i.isAvailable === false);
        // some of the drivers that are part of this load are not available because they are either active on another load or were manually set to be unavailable
        if (isObjectNotEmpty(unavailableDriver)) {
            if (drivers.length > 1) {
                throw new Error(`It appears that ${unavailableDriver.firstName} is already on a different active load. Please contact us as soon as possible so we may assist you.`);
            } else {
                throw new Error('It appears that you are already on a different active load. Please contact us as soon as possible if you receive this message.');
            }
        }
    } else {
        throw new Error('It appears that there are no drivers assigned to this load.');
    }

    // assets don't have to be assigned to the load for the load to start
    if (isListNotEmpty(assets)) {
        let assetIds = assets.map(i => i.id).join('|');

        const res = await Data.getAssets({ id: assetIds, isDeleted: false });
        const updatedAssets = res.data;
        const unavailableAsset = updatedAssets.find(i => i.isAvailable === false);

        if (isObjectNotEmpty(unavailableAsset)) {
            throw new Error('It appears that one or more asset(s) assigned to this load are marked as unavailable in the system. Please contact Customer Support as soon as possible so that we may assist you.');
        }
    }

    return true;
};

export const updateLoadPricing = async (loadId, invoiceLineItems, transactions, claims) => {
    let filteredInvoiceLineItems = invoiceLineItems.filter(i => i.loadId !== undefined && i.loadId !== null && i.loadId === loadId);
    let filteredTransactions = transactions.filter(i => i.loadId !== undefined && i.loadId !== null && i.loadId === loadId);

    let pricingSummary = Pricing.loadPricingSummary(filteredInvoiceLineItems, filteredTransactions);

    let updatedLoadPayload = {};

    if (isNumberNotEmpty(pricingSummary.shipperRatePerMileAmount)) {
        updatedLoadPayload.shipperRatePerMile = pricingSummary.shipperRatePerMileAmount;
        updatedLoadPayload.shipperRatePerMileUnit = 'USD';
    } else {
        updatedLoadPayload.shipperRatePerMile = null;
        updatedLoadPayload.shipperRatePerMileUnit = null;
    }

    if (isNumberNotEmpty(pricingSummary.shipperAmountWithAddOns)) {
        updatedLoadPayload.shipperAmount = pricingSummary.shipperAmountWithAddOns;
        updatedLoadPayload.shipperAmountUnit = 'USD';
    } else {
        updatedLoadPayload.shipperAmount = null;
        updatedLoadPayload.shipperAmountUnit = null;
    }

    if (isNumberNotEmpty(pricingSummary.shipperBalance)) {
        updatedLoadPayload.shipperBalance = pricingSummary.shipperBalance;
        updatedLoadPayload.shipperBalanceUnit = 'USD';
    } else {
        updatedLoadPayload.shipperBalance = null;
        updatedLoadPayload.shipperBalanceUnit = null;
    }

    if (isNumberNotEmpty(pricingSummary.carrierRatePerMileAmount)) {
        updatedLoadPayload.carrierRatePerMile = pricingSummary.carrierRatePerMileAmount;
        updatedLoadPayload.carrierRatePerMileUnit = 'USD';
    } else {
        updatedLoadPayload.carrierRatePerMile = null;
        updatedLoadPayload.carrierRatePerMileUnit = null;
    }

    if (isNumberNotEmpty(pricingSummary.carrierAmountWithAddOns)) {
        updatedLoadPayload.carrierAmount = pricingSummary.carrierAmountWithAddOns;
        updatedLoadPayload.carrierAmountUnit = 'USD';
    } else {
        updatedLoadPayload.carrierAmount = null;
        updatedLoadPayload.carrierAmountUnit = null;
    }

    if (isNumberNotEmpty(pricingSummary.carrierBalance)) {
        updatedLoadPayload.carrierBalance = pricingSummary.carrierBalance;
        updatedLoadPayload.carrierBalanceUnit = 'USD';
    } else {
        updatedLoadPayload.carrierBalance = null;
        updatedLoadPayload.carrierBalanceUnit = null;
    }

    if (isNumberNotEmpty(pricingSummary.factoringAmount)) {
        updatedLoadPayload.factoringAmount = pricingSummary.factoringAmount;
        updatedLoadPayload.factoringAmountUnit = 'USD';
    } else {
        updatedLoadPayload.factoringAmount = null;
        updatedLoadPayload.factoringAmountUnit = null;
    }

    if (isNumberNotEmpty(pricingSummary.factoringBalance)) {
        updatedLoadPayload.factoringBalance = pricingSummary.factoringBalance;
        updatedLoadPayload.factoringBalanceUnit = 'USD';
    } else {
        updatedLoadPayload.factoringBalance = null;
        updatedLoadPayload.factoringBalanceUnit = null;
    }

    updatedLoadPayload.completionCheckList = {};

    if (isNumberGreaterThanZero(pricingSummary.shipperBalance) || isNumberGreaterThanZero(pricingSummary.carrierBalance) || isNumberGreaterThanZero(pricingSummary.factoringBalance)) {
        updatedLoadPayload.completionCheckList.hasBalance = true;
    } else {
        updatedLoadPayload.completionCheckList.hasBalance = false;
    }

    let pendingInvoiceLineItems = invoiceLineItems.filter(i => i.status === 'PENDING' || ((i.approvalStatus === 'NEW' || i.approvalStatus === 'PENDING') && i.status !== 'CANCELLED'));
    if (pendingInvoiceLineItems.length > 0) {
        updatedLoadPayload.completionCheckList.hasPendingInvoiceLineItems = true;
    } else {
        updatedLoadPayload.completionCheckList.hasPendingInvoiceLineItems = false;
    }

    let pendingTransactions = transactions.filter(i => i.paymentStatus === 'PENDING');
    if (pendingTransactions.length > 0) {
        updatedLoadPayload.completionCheckList.hasPendingTransactions = true;
    } else {
        updatedLoadPayload.completionCheckList.hasPendingTransactions = false;
    }

    let openClaims = claims.filter(i => i.claimStatus !== 'CLOSED');
    if (openClaims.length > 0) {
        updatedLoadPayload.completionCheckList.hasOpenClaims = true;
    } else {
        updatedLoadPayload.completionCheckList.hasOpenClaims = false;
    }

    const loadsRes = await axiosAuthenticated.put(loadsPath + `/${loadId}`, { ...updatedLoadPayload });
    if (loadsRes && loadsRes.status === 200) {
        return true;
    } else {
        return false;
    }
};

export const recalculateLoadPricing = async (loadId) => {
    let invoiceLineItems = await Data.getInvoiceLineItemsByLoadId(loadId);
    let transactions = await Data.getTransactionsByLoadId(loadId);
    let claims = await Data.getClaimsByLoadId(loadId);

    let pricingSummary = Pricing.loadPricingSummary(invoiceLineItems, transactions);

    let updatedLoadPayload = {};

    if (isNumberNotEmpty(pricingSummary.shipperRatePerMileAmount)) {
        updatedLoadPayload.shipperRatePerMile = pricingSummary.shipperRatePerMileAmount;
        updatedLoadPayload.shipperRatePerMileUnit = 'USD';
    } else {
        updatedLoadPayload.shipperRatePerMile = null;
        updatedLoadPayload.shipperRatePerMileUnit = null;
    }

    if (isNumberNotEmpty(pricingSummary.shipperAmountWithAddOns)) {
        updatedLoadPayload.shipperAmount = pricingSummary.shipperAmountWithAddOns;
        updatedLoadPayload.shipperAmountUnit = 'USD';
    } else {
        updatedLoadPayload.shipperAmount = null;
        updatedLoadPayload.shipperAmountUnit = null;
    }

    if (isNumberNotEmpty(pricingSummary.shipperBalance)) {
        updatedLoadPayload.shipperBalance = pricingSummary.shipperBalance;
        updatedLoadPayload.shipperBalanceUnit = 'USD';
    } else {
        updatedLoadPayload.shipperBalance = null;
        updatedLoadPayload.shipperBalanceUnit = null;
    }

    if (isNumberNotEmpty(pricingSummary.carrierRatePerMileAmount)) {
        updatedLoadPayload.carrierRatePerMile = pricingSummary.carrierRatePerMileAmount;
        updatedLoadPayload.carrierRatePerMileUnit = 'USD';
    } else {
        updatedLoadPayload.carrierRatePerMile = null;
        updatedLoadPayload.carrierRatePerMileUnit = null;
    }

    if (isNumberNotEmpty(pricingSummary.carrierAmountWithAddOns)) {
        updatedLoadPayload.carrierAmount = pricingSummary.carrierAmountWithAddOns;
        updatedLoadPayload.carrierAmountUnit = 'USD';
    } else {
        updatedLoadPayload.carrierAmount = null;
        updatedLoadPayload.carrierAmountUnit = null;
    }

    if (isNumberNotEmpty(pricingSummary.carrierBalance)) {
        updatedLoadPayload.carrierBalance = pricingSummary.carrierBalance;
        updatedLoadPayload.carrierBalanceUnit = 'USD';
    } else {
        updatedLoadPayload.carrierBalance = null;
        updatedLoadPayload.carrierBalanceUnit = null;
    }

    if (isNumberNotEmpty(pricingSummary.factoringAmount)) {
        updatedLoadPayload.factoringAmount = pricingSummary.factoringAmount;
        updatedLoadPayload.factoringAmountUnit = 'USD';
    } else {
        updatedLoadPayload.factoringAmount = null;
        updatedLoadPayload.factoringAmountUnit = null;
    }

    if (isNumberNotEmpty(pricingSummary.factoringBalance)) {
        updatedLoadPayload.factoringBalance = pricingSummary.factoringBalance;
        updatedLoadPayload.factoringBalanceUnit = 'USD';
    } else {
        updatedLoadPayload.factoringBalance = null;
        updatedLoadPayload.factoringBalanceUnit = null;
    }

    updatedLoadPayload.completionCheckList = {};

    if (isNumberGreaterThanZero(pricingSummary.shipperBalance) || isNumberGreaterThanZero(pricingSummary.carrierBalance) || isNumberGreaterThanZero(pricingSummary.factoringBalance)) {
        updatedLoadPayload.completionCheckList.hasBalance = true;
    } else {
        updatedLoadPayload.completionCheckList.hasBalance = false;
    }

    let pendingInvoiceLineItems = invoiceLineItems.filter(i => i.status === 'PENDING' || ((i.approvalStatus === 'NEW' || i.approvalStatus === 'PENDING') && i.status !== 'CANCELLED'));
    if (pendingInvoiceLineItems.length > 0) {
        updatedLoadPayload.completionCheckList.hasPendingInvoiceLineItems = true;
    } else {
        updatedLoadPayload.completionCheckList.hasPendingInvoiceLineItems = false;
    }

    let pendingTransactions = transactions.filter(i => i.paymentStatus === 'PENDING');
    if (pendingTransactions.length > 0) {
        updatedLoadPayload.completionCheckList.hasPendingTransactions = true;
    } else {
        updatedLoadPayload.completionCheckList.hasPendingTransactions = false;
    }

    let openClaims = claims.filter(i => i.claimStatus !== 'CLOSED');
    if (openClaims.length > 0) {
        updatedLoadPayload.completionCheckList.hasOpenClaims = true;
    } else {
        updatedLoadPayload.completionCheckList.hasOpenClaims = false;
    }

    const loadsRes = await axiosAuthenticated.put(loadsPath + `/${loadId}`, { ...updatedLoadPayload });
    if (loadsRes && loadsRes.status === 200) {
        return true;
    } else {
        return false;
    }
};

//#endregion