import React from "react";
import { Col, Row, Alert, Tooltip } from "antd";
import { Controller, useFormContext } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { InfoCircleOutlined } from "@ant-design/icons";
import { isBooleanTrue, isNotNullOrUndefined, isStringNotEmpty } from "../../shared/objectUtils";

const FormItemPenta = ({
    label1, labelCol1 = {}, wrapperCol1 = {}, required1 = false, noColon1 = false, name1, as1, render1, rules1, defaultValue1, onChange1, disabled1 = false, tooltip1: tooltipText1,
    label2, labelCol2 = {}, wrapperCol2 = {}, required2 = false, noColon2 = false, name2, as2, render2, rules2, defaultValue2, onChange2, disabled2 = false, tooltip2: tooltipText2,
    label3, labelCol3 = {}, wrapperCol3 = {}, required3 = false, noColon3 = false, name3, as3, render3, rules3, defaultValue3, onChange3, disabled3 = false, tooltip3: tooltipText3,
    label4, labelCol4 = {}, wrapperCol4 = {}, required4 = false, noColon4 = false, name4, as4, render4, rules4, defaultValue4, onChange4, disabled4 = false, tooltip4: tooltipText4,
    label5, labelCol5 = {}, wrapperCol5 = {}, required5 = false, noColon5 = false, name5, as5, render5, rules5, defaultValue5, onChange5, disabled5 = false, tooltip5: tooltipText5,
    helpCol = {}, help = null, format = 'vertical'
}) => {
    const methods = useFormContext();

    const controller1Props = {
        name: name1
    };

    if (as1 !== undefined) {
        controller1Props.as = as1;
    }

    if (render1 !== undefined) {
        controller1Props.render = render1;
    }

    if (rules1 !== undefined) {
        controller1Props.rules = rules1;
    }

    if (defaultValue1 !== undefined) {
        controller1Props.defaultValue = defaultValue1;
    }

    if (onChange1 !== undefined) {
        controller1Props.onChange = onChange1;
    }

    if (disabled1 !== undefined) {
        controller1Props.disabled = disabled1;
    }

    // console.log(controller1Props);

    const controller2Props = {
        name: name2
    };

    if (as2 !== undefined) {
        controller2Props.as = as2;
    }

    if (render2 !== undefined) {
        controller2Props.render = render2;
    }

    if (rules2 !== undefined) {
        controller2Props.rules = rules2;
    }

    if (defaultValue2 !== undefined) {
        controller2Props.defaultValue = defaultValue2;
    }

    if (onChange2 !== undefined) {
        controller2Props.onChange = onChange2;
    }

    if (disabled2 !== undefined) {
        controller2Props.disabled = disabled2;
    }

    // console.log(controller2Props);

    const controller3Props = {
        name: name3
    };

    if (as3 !== undefined) {
        controller3Props.as = as3;
    }

    if (render3 !== undefined) {
        controller3Props.render = render3;
    }

    if (rules3 !== undefined) {
        controller3Props.rules = rules3;
    }

    if (defaultValue3 !== undefined) {
        controller3Props.defaultValue = defaultValue3;
    }

    if (onChange3 !== undefined) {
        controller3Props.onChange = onChange3;
    }

    if (disabled3 !== undefined) {
        controller3Props.disabled = disabled3;
    }

    // console.log(controller3Props);

    const controller4Props = {
        name: name4
    };

    if (as4 !== undefined) {
        controller4Props.as = as4;
    }

    if (render4 !== undefined) {
        controller4Props.render = render4;
    }

    if (rules4 !== undefined) {
        controller4Props.rules = rules4;
    }

    if (defaultValue4 !== undefined) {
        controller4Props.defaultValue = defaultValue4;
    }

    if (onChange4 !== undefined) {
        controller4Props.onChange = onChange4;
    }

    if (disabled4 !== undefined) {
        controller4Props.disabled = disabled4;
    }

    // console.log(controller4Props);

    const controller5Props = {
        name: name5
    };

    if (as5 !== undefined) {
        controller5Props.as = as5;
    }

    if (render5 !== undefined) {
        controller5Props.render = render5;
    }

    if (rules5 !== undefined) {
        controller5Props.rules = rules5;
    }

    if (defaultValue5 !== undefined) {
        controller5Props.defaultValue = defaultValue5;
    }

    if (onChange5 !== undefined) {
        controller5Props.onChange = onChange5;
    }

    if (disabled5 !== undefined) {
        controller5Props.disabled = disabled5;
    }

    // console.log(controller5Props);

    const getError = (errors, name) => {
        if (name !== undefined) {
            let obj = errors;

            name = name.split('.');
            let len = name.length;
            for (let i = 0; i < len - 1; i++) {
                if (obj[name[i]] !== undefined) {
                    //console.log(obj[name[i]]);
                    obj = obj[name[i]];
                }
            }

            return obj[name[len - 1]];
        } else {
            return undefined;
        }
    };

    const getTooltip = (text) => {
        if (isStringNotEmpty(text)) {
            return (
                <Tooltip placement="top" title={text}>
                    <InfoCircleOutlined style={{ color: '#23BEBB', marginLeft: 8 }} />
                </Tooltip>
            );
        } else {
            return null;
        }
    };

    if (format === 'vertical') {
        return (
            <>
                {(isStringNotEmpty(label1) || isStringNotEmpty(label2) || isStringNotEmpty(label3) || isStringNotEmpty(label4) || isStringNotEmpty(label5)) ? (
                    <Row gutter={[8, 8]} style={{ lineHeight: 2.0, marginBottom: 8 }}>
                        {isStringNotEmpty(label1) ? (<Col {...labelCol1}>{isBooleanTrue(required1) ? '* ' : ''}{label1}{getTooltip(tooltipText1)}{isBooleanTrue(noColon1) ? '' : ':'}</Col>) : null}
                        {isStringNotEmpty(label2) ? (<Col {...labelCol2}>{isBooleanTrue(required2) ? '* ' : ''}{label2}{getTooltip(tooltipText2)}{isBooleanTrue(noColon2) ? '' : ':'}</Col>) : null}
                        {isStringNotEmpty(label3) ? (<Col {...labelCol3}>{isBooleanTrue(required3) ? '* ' : ''}{label3}{getTooltip(tooltipText3)}{isBooleanTrue(noColon3) ? '' : ':'}</Col>) : null}
                        {isStringNotEmpty(label4) ? (<Col {...labelCol4}>{isBooleanTrue(required4) ? '* ' : ''}{label4}{getTooltip(tooltipText4)}{isBooleanTrue(noColon4) ? '' : ':'}</Col>) : null}
                        {isStringNotEmpty(label5) ? (<Col {...labelCol5}>{isBooleanTrue(required5) ? '* ' : ''}{label5}{getTooltip(tooltipText5)}{isBooleanTrue(noColon5) ? '' : ':'}</Col>) : null}
                    </Row>
                ) : null}
                <Row gutter={[8, 8]} style={{ marginBottom: 8 }}>
                    <Col {...wrapperCol1}>
                        <Controller control={methods.control} {...controller1Props} />
                    </Col>
                    <Col {...wrapperCol2}>
                        <Controller control={methods.control} {...controller2Props} />
                    </Col>
                    <Col {...wrapperCol3}>
                        <Controller control={methods.control} {...controller3Props} />
                    </Col>
                    <Col {...wrapperCol4}>
                        <Controller control={methods.control} {...controller4Props} />
                    </Col>
                    <Col {...wrapperCol5}>
                        <Controller control={methods.control} {...controller5Props} />
                    </Col>
                </Row>
                {isNotNullOrUndefined(help) ? (
                    <Row gutter={[8, 8]} style={{ marginBottom: 8 }}>
                        <Col {...helpCol}>{help}</Col>
                    </Row>
                ) : null}
                {(getError(methods.errors, name1) !== undefined || getError(methods.errors, name2) !== undefined || getError(methods.errors, name3) !== undefined || getError(methods.errors, name4) !== undefined || getError(methods.errors, name5) !== undefined) ? (
                    <Row gutter={[8, 8]} style={{ marginBottom: 8 }}>
                        <Col {...wrapperCol1}>
                            <ErrorMessage
                                errors={methods.errors}
                                name={name1}
                                render={({ message, messages }) => {
                                    if (isNotNullOrUndefined(messages)) {
                                        return Object.entries(messages).map(([type, message]) => (<Alert key={`${name1}-${type}`} message={message} type="error" style={{ marginBottom: 8 }} />));
                                    } else if (isStringNotEmpty(message)) {
                                        return <Alert message={message} type="error" style={{ marginBottom: 8 }} />;
                                    } else {
                                        return null;
                                    }
                                }}
                            />
                        </Col>
                        <Col {...wrapperCol2}>
                            <ErrorMessage
                                errors={methods.errors}
                                name={name2}
                                render={({ message, messages }) => {
                                    if (isNotNullOrUndefined(messages)) {
                                        return Object.entries(messages).map(([type, message]) => (<Alert key={`${name2}-${type}`} message={message} type="error" style={{ marginBottom: 8 }} />));
                                    } else if (isStringNotEmpty(message)) {
                                        return <Alert message={message} type="error" style={{ marginBottom: 8 }} />;
                                    } else {
                                        return null;
                                    }
                                }}
                            />
                        </Col>
                        <Col {...wrapperCol3}>
                            <ErrorMessage
                                errors={methods.errors}
                                name={name3}
                                render={({ message, messages }) => {
                                    if (isNotNullOrUndefined(messages)) {
                                        return Object.entries(messages).map(([type, message]) => (<Alert key={`${name3}-${type}`} message={message} type="error" style={{ marginBottom: 8 }} />));
                                    } else if (isStringNotEmpty(message)) {
                                        return <Alert message={message} type="error" style={{ marginBottom: 8 }} />;
                                    } else {
                                        return null;
                                    }
                                }}
                            />
                        </Col>
                        <Col {...wrapperCol4}>
                            <ErrorMessage
                                errors={methods.errors}
                                name={name4}
                                render={({ message, messages }) => {
                                    if (isNotNullOrUndefined(messages)) {
                                        return Object.entries(messages).map(([type, message]) => (<Alert key={`${name4}-${type}`} message={message} type="error" style={{ marginBottom: 8 }} />));
                                    } else if (isStringNotEmpty(message)) {
                                        return <Alert message={message} type="error" style={{ marginBottom: 8 }} />;
                                    } else {
                                        return null;
                                    }
                                }}
                            />
                        </Col>
                        <Col {...wrapperCol5}>
                            <ErrorMessage
                                errors={methods.errors}
                                name={name5}
                                render={({ message, messages }) => {
                                    if (isNotNullOrUndefined(messages)) {
                                        return Object.entries(messages).map(([type, message]) => (<Alert key={`${name5}-${type}`} message={message} type="error" style={{ marginBottom: 8 }} />));
                                    } else if (isStringNotEmpty(message)) {
                                        return <Alert message={message} type="error" style={{ marginBottom: 8 }} />;
                                    } else {
                                        return null;
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                ) : null}
            </>
        );
    } else if (format === 'horizontal') {
        return (
            <>
                <Row gutter={[8, 8]} style={{ marginBottom: 8 }}>
                    {isStringNotEmpty(label1) ? (
                        <Col {...labelCol1} style={{ textAlign: 'right' }}>{isBooleanTrue(required1) ? '* ' : ''}{label1}{getTooltip(tooltipText1)}{isBooleanTrue(noColon1) ? '' : ':'}</Col>
                    ) : null}
                    <Col {...wrapperCol1}>
                        <Controller control={methods.control} {...controller1Props} />
                    </Col>
                    {isStringNotEmpty(label2) ? (
                        <Col {...labelCol2} style={{ textAlign: 'right' }}>{isBooleanTrue(required2) ? '* ' : ''}{label2}{getTooltip(tooltipText2)}{isBooleanTrue(noColon2) ? '' : ':'}</Col>
                    ) : null}
                    <Col {...wrapperCol2}>
                        <Controller control={methods.control} {...controller2Props} />
                    </Col>
                    {isStringNotEmpty(label3) ? (
                        <Col {...labelCol3} style={{ textAlign: 'right' }}>{isBooleanTrue(required3) ? '* ' : ''}{label3}{getTooltip(tooltipText3)}{isBooleanTrue(noColon3) ? '' : ':'}</Col>
                    ) : null}
                    <Col {...wrapperCol3}>
                        <Controller control={methods.control} {...controller3Props} />
                    </Col>
                    {isStringNotEmpty(label4) ? (
                        <Col {...labelCol4} style={{ textAlign: 'right' }}>{isBooleanTrue(required4) ? '* ' : ''}{label4}{getTooltip(tooltipText4)}{isBooleanTrue(noColon4) ? '' : ':'}</Col>
                    ) : null}
                    <Col {...wrapperCol4}>
                        <Controller control={methods.control} {...controller4Props} />
                    </Col>
                    {isStringNotEmpty(label5) ? (
                        <Col {...labelCol5} style={{ textAlign: 'right' }}>{isBooleanTrue(required5) ? '* ' : ''}{label5}{getTooltip(tooltipText5)}{isBooleanTrue(noColon5) ? '' : ':'}</Col>
                    ) : null}
                    <Col {...wrapperCol5}>
                        <Controller control={methods.control} {...controller5Props} />
                    </Col>
                </Row>
                {isNotNullOrUndefined(help) ? (
                    <Row gutter={[8, 8]} style={{ marginBottom: 8 }}>
                        <Col {...helpCol}>{help}</Col>
                    </Row>
                ) : null}
                {(getError(methods.errors, name1) !== undefined || getError(methods.errors, name2) !== undefined || getError(methods.errors, name3) !== undefined || getError(methods.errors, name4) !== undefined || getError(methods.errors, name5) !== undefined) ? (
                    <Row gutter={[8, 8]} style={{ marginBottom: 8 }}>
                        <Col {...labelCol1}></Col>
                        <Col {...wrapperCol1}>
                            <ErrorMessage
                                errors={methods.errors}
                                name={name1}
                                render={({ message, messages }) => {
                                    if (isNotNullOrUndefined(messages)) {
                                        return Object.entries(messages).map(([type, message]) => (<Alert key={`${name1}-${type}`} message={message} type="error" style={{ marginBottom: 8 }} />));
                                    } else if (isStringNotEmpty(message)) {
                                        return <Alert message={message} type="error" style={{ marginBottom: 8 }} />;
                                    } else {
                                        return null;
                                    }
                                }}
                            />
                        </Col>
                        <Col {...labelCol2}></Col>
                        <Col {...wrapperCol2}>
                            <ErrorMessage
                                errors={methods.errors}
                                name={name2}
                                render={({ message, messages }) => {
                                    if (isNotNullOrUndefined(messages)) {
                                        return Object.entries(messages).map(([type, message]) => (<Alert key={`${name2}-${type}`} message={message} type="error" style={{ marginBottom: 8 }} />));
                                    } else if (isStringNotEmpty(message)) {
                                        return <Alert message={message} type="error" style={{ marginBottom: 8 }} />;
                                    } else {
                                        return null;
                                    }
                                }}
                            />
                        </Col>
                        <Col {...labelCol3}></Col>
                        <Col {...wrapperCol3}>
                            <ErrorMessage
                                errors={methods.errors}
                                name={name3}
                                render={({ message, messages }) => {
                                    if (isNotNullOrUndefined(messages)) {
                                        return Object.entries(messages).map(([type, message]) => (<Alert key={`${name3}-${type}`} message={message} type="error" style={{ marginBottom: 8 }} />));
                                    } else if (isStringNotEmpty(message)) {
                                        return <Alert message={message} type="error" style={{ marginBottom: 8 }} />;
                                    } else {
                                        return null;
                                    }
                                }}
                            />
                        </Col>
                        <Col {...labelCol4}></Col>
                        <Col {...wrapperCol4}>
                            <ErrorMessage
                                errors={methods.errors}
                                name={name4}
                                render={({ message, messages }) => {
                                    if (isNotNullOrUndefined(messages)) {
                                        return Object.entries(messages).map(([type, message]) => (<Alert key={`${name4}-${type}`} message={message} type="error" style={{ marginBottom: 8 }} />));
                                    } else if (isStringNotEmpty(message)) {
                                        return <Alert message={message} type="error" style={{ marginBottom: 8 }} />;
                                    } else {
                                        return null;
                                    }
                                }}
                            />
                        </Col>
                        <Col {...labelCol5}></Col>
                        <Col {...wrapperCol5}>
                            <ErrorMessage
                                errors={methods.errors}
                                name={name5}
                                render={({ message, messages }) => {
                                    if (isNotNullOrUndefined(messages)) {
                                        return Object.entries(messages).map(([type, message]) => (<Alert key={`${name5}-${type}`} message={message} type="error" style={{ marginBottom: 8 }} />));
                                    } else if (isStringNotEmpty(message)) {
                                        return <Alert message={message} type="error" style={{ marginBottom: 8 }} />;
                                    } else {
                                        return null;
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                ) : null}
            </>
        );
    } else {
        return null;
    }
};

export default FormItemPenta;