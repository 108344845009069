import React, { useEffect, useMemo, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { ExpandAltOutlined, LeftOutlined, RightOutlined, ShrinkOutlined } from '@ant-design/icons';
import { Button, Card, Timeline, Spin, Badge } from 'antd';
import classes from './LoadsList.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruck, faTruckLoading } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Enums from '../../shared/enums';
import ReactList from 'react-list';
import MomentDate from '../../shared/dateFormatter';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { isBooleanTrue, isListNotEmpty, isNotNullOrUndefined, isNumberGreaterThanZero, isNumberNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import LoadUtils from '../../api/utils/loadUtils';
import { selectListError, selectListIsLoading, selectListPagination, selectListRecords } from '../../store/utility';
import LoadStopUtils from '../../api/utils/loadStopUtils';

const momentDate = new MomentDate();

const LoadsList = ({ shipperId, carrierId, driverId, brokerId, filter, icon: iconToShow, title: filterTitle, showCompletedDate = false, showStatus = false, showStatusToStaff = false, additionalIsLoading = false, selectedRecord, setSelectedRecord, loadExtras, openShowEntity, closeShowEntity, toggleAssignCarrier, showEntity = false, listActions = null, filterDrawer = null, getList, getMoreList }) => {
    //#region useRefs

    const scrollParentRef = useRef(null);

    //#endregion
    //#region useStates

    const [collapseCards, setCollapseCards] = useState(false);

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);
    const filteredData = useSelector(state => selectListRecords(state.loads.lists, filter));
    const isLoading = useSelector(state => selectListIsLoading(state.loads.lists, filter));
    const pagination = useSelector(state => selectListPagination(state.loads.lists, filter));
    const error = useSelector(state => selectListError(state.loads.lists, filter));

    //#endregion
    //#region listMethods

    const refreshList = () => {
        if (isNotNullOrUndefined(getList)) {
            getList();
        } else {
            if (isStringNotEmpty(filter) && isStringNotEmpty(entityType) && isStringNotEmpty(entityId)) {
                let searchParams = {
                    page: 1,
                    size: 5,
                    sort: 'pickUpDateTime',
                    order: 'asc',
                    isDeleted: false,
                    // eta: true
                };

                if (entityType === "DRIVER") {
                    searchParams.driverIds = entityId;
                } else if (entityType === "SHIPPER") {
                    searchParams.shipperId = entityId;
                } else if (entityType === "BROKER") {
                    searchParams.brokerId = entityId;
                } else if (entityType === "CARRIER") {
                    searchParams.assignedCarrierId = entityId;

                    if (isStringNotEmpty(driverId)) {
                        searchParams.driverIds = driverId;
                    }
                } else if (entityType === "STAFF") {
                    if (isStringNotEmpty(shipperId)) {
                        searchParams.shipperId = shipperId;
                    }

                    if (isStringNotEmpty(brokerId)) {
                        searchParams.brokerId = brokerId;
                    }

                    if (isStringNotEmpty(carrierId)) {
                        searchParams.assignedCarrierId = carrierId;
                    }

                    if (isStringNotEmpty(driverId)) {
                        searchParams.driverIds = driverId;
                    }
                }

                if (filter === 'carrier-dispatch') {
                    searchParams.loadStatus = 'BOOKED';
                } else if (filter === 'loadrequest') {
                    searchParams.loadStatus = 'PENDING_RATE_CONFIRMATION';
                } else if (filter === 'open') {
                    if (entityType === "DRIVER" || entityType === "CARRIER" || isStringNotEmpty(carrierId)) {
                        searchParams.loadStatus = 'BOOKED';
                    } else if (entityType === "SHIPPER" || entityType === "STAFF" || entityType === "BROKER") {
                        searchParams.loadStatus = 'CREATED|PENDING|APPROVED|PENDING_RATE_CONFIRMATION';
                    }
                } else if (filter === 'booked') {
                    searchParams.loadStatus = (entityType === "CARRIER" || isStringNotEmpty(carrierId)) ? 'SCHEDULED' : 'BOOKED|SCHEDULED';
                } else if (filter === 'intransit') {
                    searchParams.loadStatus = 'IN_TRANSIT|AT_STOP';
                } else if (filter === 'completed') {
                    searchParams.loadStatus = (entityType === "STAFF" ? 'COMPLETED|CLOSED|REOPENED|CANCELLED' : 'COMPLETED|CLOSED|REOPENED');
                    searchParams.order = 'desc';
                    searchParams.sort = 'loadEndDateTime';
                }

                dispatch(actionCreators.fetchLoadList(filter, searchParams));
            }
        }
    };

    const retrieveMore = () => {
        // console.log(`Current Page: ${pagination.current}, Total Pages: ${pagination.totalPages}`);
        if (pagination.current + 1 <= pagination.totalPages && isLoading === false) {
            // console.log('get more');

            if (isNotNullOrUndefined(getMoreList)) {
                getMoreList();
            } else {
                if (isStringNotEmpty(filter) && isStringNotEmpty(entityType) && isStringNotEmpty(entityId)) {
                    let searchParams = {
                        page: pagination.current + 1,
                        size: 5,
                        sort: 'pickUpDateTime',
                        order: 'asc',
                        isDeleted: false,
                        // eta: true
                    };

                    if (entityType === "DRIVER") {
                        searchParams.driverIds = entityId;
                    } else if (entityType === "SHIPPER") {
                        searchParams.shipperId = entityId;
                    } else if (entityType === "BROKER") {
                        searchParams.brokerId = entityId;
                    } else if (entityType === "CARRIER") {
                        searchParams.assignedCarrierId = entityId;

                        if (isStringNotEmpty(driverId)) {
                            searchParams.driverIds = driverId;
                        }
                    } else if (entityType === "STAFF") {
                        if (isStringNotEmpty(shipperId)) {
                            searchParams.shipperId = shipperId;
                        }

                        if (isStringNotEmpty(brokerId)) {
                            searchParams.brokerId = brokerId;
                        }

                        if (isStringNotEmpty(carrierId)) {
                            searchParams.assignedCarrierId = carrierId;
                        }

                        if (isStringNotEmpty(driverId)) {
                            searchParams.driverIds = driverId;
                        }
                    }

                    if (filter === 'carrier-dispatch') {
                        searchParams.loadStatus = 'BOOKED';
                    } else if (filter === 'loadrequest') {
                        searchParams.loadStatus = 'PENDING_RATE_CONFIRMATION';
                    } else if (filter === 'open') {
                        if (entityType === "DRIVER" || entityType === "CARRIER" || isStringNotEmpty(carrierId)) {
                            searchParams.loadStatus = 'BOOKED';
                        } else if (entityType === "SHIPPER" || entityType === "STAFF" || entityType === "BROKER") {
                            searchParams.loadStatus = 'CREATED|PENDING|APPROVED|PENDING_RATE_CONFIRMATION';
                        }
                    } else if (filter === 'booked') {
                        searchParams.loadStatus = (entityType === "CARRIER" || isStringNotEmpty(carrierId)) ? 'SCHEDULED' : 'BOOKED|SCHEDULED';
                    } else if (filter === 'intransit') {
                        searchParams.loadStatus = 'IN_TRANSIT|AT_STOP';
                    } else if (filter === 'completed') {
                        searchParams.loadStatus = (entityType === "STAFF" ? 'COMPLETED|CLOSED|REOPENED|CANCELLED' : 'COMPLETED|CLOSED|REOPENED');
                        searchParams.order = 'desc';
                        searchParams.sort = 'loadEndDateTime';
                    }

                    dispatch(actionCreators.fetchMoreLoadList(filter, searchParams));
                }
            }
        } else {
            //console.log('no more items to get');
        }
    };

    //#endregion
    //#region useMemos and useEffects

    useEffect(() => {
        refreshList();
    }, [filter, entityType, entityId]);

    useMemo(() => {
        if (isObjectNotEmpty(selectedRecord)) {
            setCollapseCards(true);
        }
    }, [selectedRecord]);

    //#endregion
    //#region scroll methods

    const scrollToLeft = () => {
        if (isNotNullOrUndefined(scrollParentRef.current)) {
            const scrollLeft = scrollParentRef.current.scrollLeft;
            // console.log(scrollLeft);
            // console.log(scrollLeft - 400 > 0 ? scrollLeft - 400 : 0);
            scrollParentRef.current.scrollTo({ left: scrollLeft - 400 > 0 ? scrollLeft - 400 : 0, behavior: 'smooth' });
        }
    };

    const scrollToRight = () => {
        if (scrollParentRef.current) {
            const scrollLeft = scrollParentRef.current.scrollLeft;
            const scrollWidth = scrollParentRef.current.scrollWidth;
            // console.log(scrollLeft);
            // console.log(scrollLeft + 400 > scrollWidth ? scrollWidth : scrollLeft + 400);
            scrollParentRef.current.scrollTo({ left: scrollLeft + 400, behavior: 'smooth' });
        }
    };

    const handleScroll = () => {
        if (scrollParentRef.current) {
            const right = scrollParentRef.current.scrollWidth;
            const scrollWidth = scrollParentRef.current.scrollWidth;
            const clientWidth = scrollParentRef.current.clientWidth;
            const scrollLeft = scrollParentRef.current.scrollLeft;
            const scrollRight = (scrollParentRef.current.scrollLeft + scrollParentRef.current.offsetWidth);
            const offsetWidth = scrollParentRef.current.offsetWidth;
            const offsetLeft = scrollParentRef.current.offsetLeft;
            // console.log(`scrollWidth: ${scrollWidth}`);
            // console.log(`offsetWidth: ${offsetWidth}`);
            // console.log(`clientWidth: ${clientWidth}`);
            // console.log(`offsetLeft: ${offsetLeft}`);
            // console.log(`scrollLeft: ${scrollLeft}`);
            // console.log(`scrollRight: ${scrollRight}`);

            // const widthPerItem = totalCount > 0 ? (scrollWidth / 10) : 0;
            // console.log(`widthPerItem: ${widthPerItem}`);
            // console.log(`right/scrollWidth: ${right}`);
            // console.log(`current scroll position/scrollLeft: ${scrollLeft}`);
            // if (scrollLeft === 0 && isLoading === false) {
            //     console.log('load more');
            //     retrieveMore();
            // }
            // console.log(`currentPage: ${currentPage}`);
            const threshold = (scrollWidth - 200);
            // console.log(`threshold: ${threshold}`);
            if ((scrollRight > threshold) && isLoading === false) {
                // console.log('reached right threshold');
                // console.log('load more');
                retrieveMore();
            }
        }
    };

    //#endregion
    //#region displays

    const renderStop = (stop) => {
        if (isObjectNotEmpty(stop)) {
            return (
                <div className="stopDetails" key={`stop-details-${stop.id}`}>
                    <div><span className="locationDetails">{LoadStopUtils.getStopLocationDisplay(stop, shipperId, carrierId, entityType, entityId)}</span></div>
                    <div><span className="apptDetails">{LoadStopUtils.getAppointmentDetailsDisplay(stop)}</span></div>
                </div>
            );
        } else {
            return null;
        }
    };

    // render empty list component
    const renderError = (error) => {
        if (isNotNullOrUndefined(error)) {
            return (
                <Card className={classes.emptyCard}>
                    <div>Error: {error}</div>
                </Card>
            );
        } else {
            return null;
        }
    };

    // render empty list component
    const renderEmptyList = () => {
        if (isLoading === false) {
            return (
                <div className={classes.emptyList}>
                    <div className={classes.emptyIconContainer}>{iconToShow}</div>
                    <div className={classes.emptyTextContainer}>No {filterTitle} Loads have been found.</div>
                </div>
            );
        } else {
            return (
                <div className={classes.emptyList}>
                    <div className={classes.emptyIconContainer}>{iconToShow}</div>
                    <div className={classes.emptyTextContainer}>Searching for {filterTitle} Loads.</div>
                </div>
            );
        }
    };

    // render card container
    const renderContainer = (items, ref) => {
        return (
            <div ref={ref}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true && error === null) || isBooleanTrue(additionalIsLoading)}>
                    {isNotNullOrUndefined(error) ? renderError(error) : (isListNotEmpty(items) ? (
                        <>
                            {(isNotNullOrUndefined(listActions) && isLoading === false && isNotNullOrUndefined(filterDrawer)) ? filterDrawer : null}
                            {(isNotNullOrUndefined(listActions) && isLoading === false) ? (
                                <div className={classes.buttonContainer}>
                                    {listActions}
                                </div>
                            ) : null}
                            <div id="left" className={classes.leftButtonContainer}>
                                {isLoading === false ? (
                                    <Button type="primary" className={classes.scrollButton} icon={<LeftOutlined style={{ fontSize: 24, lineHeight: '32px' }} />} onClick={(e) => { e.stopPropagation(); e.preventDefault(); scrollToLeft(); }} />
                                ) : null}
                            </div>
                            <div id="infinite-scroll-container" className={classes.cardItemInfiniteScrollContainer} onScroll={handleScroll} ref={(ref) => scrollParentRef.current = ref}>
                                {items}
                            </div>
                            <div id="right" className={classes.rightButtonContainer}>
                                {isLoading === false ? (
                                    <Button type="primary" className={classes.scrollButton} icon={<RightOutlined style={{ fontSize: 24, lineHeight: '32px' }} />} onClick={(e) => { e.stopPropagation(); e.preventDefault(); scrollToRight(); }} />
                                ) : null}
                            </div>
                        </>
                    ) : renderEmptyList()
                    )}
                </Spin>
            </div>
        );
    };

    const getBundleCount = (load) => {
        let dispatchedLoadStatuses = ['SCHEDULED', 'IN_TRANSIT', 'AT_STOP', 'COMPLETED', 'CLOSED', 'REOPENED', 'CANCELLED'];
        let remainingCount = 0;
        if (isNumberNotEmpty(load.childLoadInitCount)) {
            let initCount = 0;
            if (isNumberGreaterThanZero(load.childLoadInitCount)) {
                initCount = load.childLoadInitCount;
            } else if (load.childLoads.filter(i => i.status === 'BOOKED').length > 0) {
                initCount = load.childLoads.filter(i => i.status === 'BOOKED').length;
            }
            let totalCount = initCount + 1; // adding one for the parent load
            let generatedChildLoadsCount = isNumberNotEmpty(load.childLoadActualCount) ? load.childLoadActualCount : 0;
            let dispatchedCount = 0;
            // console.log(load.parentName)
            // console.log(load.childLoads.filter(i => i.status === 'BOOKED').length)
            // console.log(`totalCount: ${totalCount}`);
            // if there are any child loads that have been dispatched already, include them in the dispatched count
            //if (generatedChildLoadsCount > 0) {
                if (isListNotEmpty(load.childLoads)) {
                    let dispatchedChildLoads = load.childLoads.filter(cl => dispatchedLoadStatuses.includes(cl.status));
                    dispatchedCount += dispatchedChildLoads.length;
                }
            //}

            // if the parent load is already dispatched then include it in the dispatched count as well
            if (dispatchedLoadStatuses.includes(load.loadStatus)) {
                dispatchedCount += 1;
            }

            remainingCount = totalCount - dispatchedCount;
        }
        // console.log(remainingCount);
        return remainingCount;
    };

    const renderCard = (load) => {
        if (isObjectNotEmpty(load)) {
            const pickUpDateObj = isNotNullOrUndefined(load.pickUpDateTime) && isObjectNotEmpty(load.origin) ? momentDate.fromUtcToTimeZoneDateObject(load.pickUpDateTime, load.origin.timeZone) : null;

            let cardClassNames = [];
            if (load.id === selectedRecord.id && showEntity === true) {
                cardClassNames.push('selectedLoadsListCard');
            } else {
                cardClassNames.push('unselectedLoadsListCard');
            }

            if (collapseCards === true) {
                cardClassNames.push('collapsedListCard');
            }

            return (
                <Card
                    size="small"
                    bordered={true}
                    onClick={(e) => { e.stopPropagation(); e.preventDefault(); if (selectedRecord.id === load.id) { setSelectedRecord({}); closeShowEntity(); } else { setSelectedRecord(load); openShowEntity(); } }}
                    className={cardClassNames.join(' ')}
                    hoverable={true}
                >
                    <div className="title">{`Load: ${LoadUtils.getLoadName(load)}`}</div>
                    {(entityType === 'STAFF' || (entityType === 'CARRIER' && isObjectNotEmpty(load.shipper) && LoadUtils.isTMSLoad(load))) ? (<div className="subtitle"><FontAwesomeIcon className="anticon" icon={faTruckLoading} style={{ marginRight: 8 }} />{isObjectNotEmpty(load.shipper) && isStringNotEmpty(load.shipper.name) ? load.shipper.name : 'TBD'}</div>) : null}
                    {(entityType === 'STAFF' || (entityType === 'SHIPPER' && isObjectNotEmpty(load.assignedCarrier) && LoadUtils.isTMSLoad(load))) ? (<div className="subtitle"><FontAwesomeIcon className="anticon" icon={faTruck} style={{ marginRight: 8 }} />{isObjectNotEmpty(load.assignedCarrier) && isStringNotEmpty(load.assignedCarrier.name) ? load.assignedCarrier.name : (load.status !== 'CANCELLED' && isNotNullOrUndefined(toggleAssignCarrier) ? (<a onClick={(e) => { e.stopPropagation(); e.preventDefault(); setSelectedRecord(load); toggleAssignCarrier(); }}>Assign Carrier</a>) : '')}</div>) : null}

                    <div className={collapseCards === true ? classes.hidden : classes.loadDetails}>
                        {isObjectNotEmpty(pickUpDateObj) ? (
                            <div className={classes.loadDate}>
                                <div className={classes.calendarDate}>
                                    <div className={classes.monthContainer}><span>{pickUpDateObj.monthAbbr}</span></div>
                                    <div className={classes.calendarContainer}><FontAwesomeIcon icon={faCalendar} className={classes.calendarIcon} /></div>
                                    <div className={classes.dayContainer}><span>{pickUpDateObj.day}</span></div>
                                </div>
                            </div>
                        ) : null}
                        <div className={classes.loadRoute}>
                            <Timeline className="loadsListTimeline">
                                {(isObjectNotEmpty(load.origin) && isObjectNotEmpty(load.origin.stopLocation)) ? (
                                    <Timeline.Item className="loadsListTimelineItem" color='#23BEBB'>
                                        {renderStop(load.origin)}
                                    </Timeline.Item>
                                ) : null}
                                {(isObjectNotEmpty(load.destination) && isObjectNotEmpty(load.destination.stopLocation)) ? (
                                    <Timeline.Item className="loadsListTimelineItem" color='#23BEBB'>
                                        {renderStop(load.destination)}
                                    </Timeline.Item>
                                ) : null}
                            </Timeline>
                        </div>
                    </div>
                    <div style={{ position: 'absolute', bottom: 0, left: 0 }}><Button style={{ border: 0 }} type="link" onClick={(e) => { e.preventDefault(); e.stopPropagation(); setCollapseCards(!collapseCards); }} icon={collapseCards ? <ExpandAltOutlined /> : <ShrinkOutlined />} /></div>
                    <div style={{ position: 'absolute', bottom: 0, right: 0 }}>{loadExtras(load)}</div>
                </Card>
            );
        } else {
            return null;
        }
    };

    // render card item
    const renderCardItem = (index, key) => {
        let cardContainerClassNames = [];
        cardContainerClassNames.push(classes.cardItemContainer);
        if (collapseCards === true) {
            cardContainerClassNames.push(classes.cardItemContainerCollapsed);
        }

        if (isListNotEmpty(filteredData)) {
            let load = filteredData[index];
            if (isObjectNotEmpty(load)) {
                const completedDateObj = isNotNullOrUndefined(load.loadEndDateTime) && isObjectNotEmpty(load.destination) ? new MomentDate().fromUtcToTimeZoneDateObject(load.loadEndDateTime, load.destination.timeZone) : null;
                if (isNumberNotEmpty(load.childLoadInitCount)) {
                    let remainingCount = getBundleCount(load);
                    if (isNumberGreaterThanZero(remainingCount)) {
                        return (
                            <div key={key} className={cardContainerClassNames.join(' ')}>
                                <Badge count={`x${getBundleCount(load)}`} offset={[-20, 20]} style={{ backgroundColor: (load.id === selectedRecord.id && showEntity === true) ? '#334353' : '#FFFFFF', color: (load.id === selectedRecord.id && showEntity === true) ? '#FFFFFF' : '#000000', border: '2px solid #23BEBB', width: 65, height: 65, borderRadius: 50, lineHeight: '60px', fontSize: 20, fontWeight: 600 }} title="Loads Remaining to Dispatch">
                                    {renderCard(load)}
                                </Badge>
                            </div>
                        );
                    } else if (isBooleanTrue(showStatus) || (entityType === 'STAFF' && isBooleanTrue(showStatusToStaff)) || (isBooleanTrue(showCompletedDate) && isObjectNotEmpty(completedDateObj))) {
                        let ribbonHeight = 24;
                        let ribbonContent = null;
                        if (isBooleanTrue(showStatus) || (entityType === 'STAFF' && isBooleanTrue(showStatusToStaff))) {
                            if (isBooleanTrue(showCompletedDate) && isObjectNotEmpty(completedDateObj)) {
                                ribbonHeight = 48;
                                ribbonContent = <span>{LoadUtils.getLoadStatusDisplay(load.loadStatus, entityType, load)}<br />{completedDateObj.dateString}</span>
                            } else {
                                ribbonContent = (<span>{LoadUtils.getLoadStatusDisplay(load.loadStatus, entityType, load)}</span>)
                            }
                        } else {
                            if (isBooleanTrue(showCompletedDate) && isObjectNotEmpty(completedDateObj)) {
                                ribbonContent = <span>{completedDateObj.dateString}</span>
                            }
                        }

                        return (
                            <div key={key} className={cardContainerClassNames.join(' ')}>
                                <Badge.Ribbon text={ribbonContent} offset={[0, 0]} style={{ backgroundColor: '#23BEBB', fontSize: 14, height: ribbonHeight }}>
                                    {renderCard(load)}
                                </Badge.Ribbon>
                            </div>
                        );
                    } else {
                        return (
                            <div key={key} className={cardContainerClassNames.join(' ')}>
                                {renderCard(load)}
                            </div>
                        );
                    }
                } else {
                    if (isBooleanTrue(showStatus) || (entityType === 'STAFF' && isBooleanTrue(showStatusToStaff)) || (isBooleanTrue(showCompletedDate) && isObjectNotEmpty(completedDateObj))) {
                        let ribbonHeight = 24;
                        let ribbonContent = null;
                        if (isBooleanTrue(showStatus) || (entityType === 'STAFF' && isBooleanTrue(showStatusToStaff))) {
                            if (isBooleanTrue(showCompletedDate) && isObjectNotEmpty(completedDateObj)) {
                                ribbonHeight = 48;
                                ribbonContent = <span>{LoadUtils.getLoadStatusDisplay(load.loadStatus, entityType, load)}<br />{completedDateObj.dateString}</span>
                            } else {
                                ribbonContent = (<span>{LoadUtils.getLoadStatusDisplay(load.loadStatus, entityType, load)}</span>)
                            }
                        } else {
                            if (isBooleanTrue(showCompletedDate) && isObjectNotEmpty(completedDateObj)) {
                                ribbonContent = <span>{completedDateObj.dateString}</span>
                            }
                        }

                        return (
                            <div key={key} className={cardContainerClassNames.join(' ')}>
                                <Badge.Ribbon text={ribbonContent} offset={[0, 0]} style={{ backgroundColor: '#23BEBB', fontSize: 14, height: ribbonHeight }}>
                                    {renderCard(load)}
                                </Badge.Ribbon>
                            </div>
                        );
                    } else {
                        return (
                            <div key={key} className={cardContainerClassNames.join(' ')}>
                                {renderCard(load)}
                            </div>
                        );
                    }
                }
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    //#endregion

    if (isListNotEmpty(filteredData) || (isLoading === true && error === null) || isBooleanTrue(additionalIsLoading)) {
        return (
            <ReactList
                axis="x"
                itemRenderer={renderCardItem}
                itemsRenderer={renderContainer}
                length={pagination.total}
                threshold={0}
                type="simple"
            />
        );
    } else if (isNotNullOrUndefined(filteredData) && filteredData.length === 0) {
        return renderEmptyList();
    } else {
        return renderError(error);
    }
};

export default withRouter(LoadsList);