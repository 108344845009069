import React, { useState, useMemo } from 'react';
import { Alert, Button, Drawer, Empty, message, Select, Spin } from "antd";
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import InvoiceUtils from '../../api/utils/invoiceUtils';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import moment from 'moment';
import * as Data from '../../api/data';
import { isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { selectListRecords } from '../../store/utility';
import FormItem from '../FormItem/FormItem';
import NewEmployee from '../NewEmployee/NewEmployee';
import LoadUtils from '../../api/utils/loadUtils';
import { CanSee } from '../../shared/entitlements/entitlements';

const { Option } = Select;

const LoadNewRateConfirmation = ({ load, loadId, shipperId, carrierId, serviceType, stops, invoices, invoiceLineItems, cancel, loading = false }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    //#endregion
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loadEvents.isLoadEventUpdateLoading);
    const error = useSelector(state => state.loadEvents.updateLoadEventError);
    const userId = useSelector(state => state.auth.userId);
    const employees = useSelector(state => selectListRecords(state.orchestrator.employees, carrierId));
    const accounts = useSelector(state => state.orchestrator.accounts);
    const linkedShipper = useSelector(state => state.linkedAccounts.record);

    //#endregion
    //#region useStates

    const [invoice, setInvoice] = useState({});
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [shipperAccount, setShipperAccount] = useState(null);
    const [carrierAccount, setCarrierAccount] = useState(null);

    //#endregion
    //#region toggles

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    //#endregion
    //#region onSubmit and onCancel

    const createNewInvoice = async (invoice) => {
        let newInvoice = null;
        if (isObjectNotEmpty(invoice)) {
            newInvoice = await Data.addInvoice(invoice);
            if (isObjectNotEmpty(newInvoice)) {
                if (isStringNotEmpty(newInvoice.fromEntityId)) {
                    dispatch(actionCreators.addInvoiceToInvoiceList(newInvoice.fromEntityId, newInvoice));
                }
                if (isStringNotEmpty(newInvoice.toEntityId)) {
                    dispatch(actionCreators.addInvoiceToInvoiceList(newInvoice.toEntityId, newInvoice));
                }
                if (isStringNotEmpty(newInvoice.loadId)) {
                    dispatch(actionCreators.addInvoiceToInvoiceList(newInvoice.loadId, newInvoice));
                }
            }
        }

        return newInvoice;
    };

    const onSubmit = async (data) => {
        if (isObjectNotEmpty(invoice) && isListNotEmpty(employees) && isListNotEmpty(data.accountUserIds) && isObjectNotEmpty(load) && isStringNotEmpty(loadId) && isStringNotEmpty(serviceType)) {
            setIsSending(true);
            let invoiceToSend = null;
            // first check to see if rate confirmation already exists and if so, delete it or update it
            if (isListNotEmpty(invoices)) {
                let existingInvoice = invoices.find(i => i.invoiceType === 'RATE_CONFIRMATION' && i.loadId === loadId && i.isDeleted === false);
                if (isObjectNotEmpty(existingInvoice)) {
                    dispatch(actionCreators.updateInvoice(existingInvoice.id, { isDeleted: true }, existingInvoice));

                    // second, create the rate confirmation in the database if an existing one couldnt be updated
                    invoiceToSend = await createNewInvoice(invoice);
                } else {
                    // second, create the rate confirmation in the database if an existing one couldnt be updated
                    invoiceToSend = await createNewInvoice(invoice);
                }
            } else {
                // second, create the rate confirmation in the database if an existing one couldnt be updated
                invoiceToSend = await createNewInvoice(invoice);
            }

            if (isObjectNotEmpty(invoiceToSend)) {
                // third, save the pdf document to s3
                let newDocument = await InvoiceUtils.saveInvoiceAsDocument('RATE_CONFIRMATION', invoiceToSend, invoiceToSend.invoiceLineItems, load, stops, true);

                let emailsWereSent = false;
                let toEmails = [];
                for (let i = 0; i < data.accountUserIds.length; i++) {
                    const accountUserId = data.accountUserIds[i];
                    const accountUser = employees.find(a => a.id === accountUserId);
                    if (isObjectNotEmpty(accountUser)) {
                        toEmails.push({ email: accountUser.email, name: accountUser.firstName + ' ' + accountUser.lastName });
                    }
                }

                // fourth, send rate confirmation emails to recipients
                emailsWereSent = await InvoiceUtils.sendRateConfirmationEmail('info@irisfreight.com', toEmails, invoiceToSend, load, stops, newDocument);

                // fifth, send load event if the status of the load is still CREATED, PENDING, or APPROVED
                if (emailsWereSent === true) {
                    message.success('Email(s) have been sent.');
                    if (LoadUtils.loadStatusIs(load.loadStatus, ["PENDING", "CREATED", "APPROVED"])) {
                        dispatch(actionCreators.sendLoadEvent({
                            loadId: load.id,
                            eventType: 'LOAD_REQUEST_TO_CARRIER'
                        }));
                    } else {
                        onCancel();
                    }
                } else {
                    message.error('Failed to send email(s)');
                }
            } else {
                message.error('Failed to create rate confirmation');
            }
            setIsSending(false);
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.updateLoadEventComplete());
        cancel();
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLoadEventErrorClear());
        dispatch(actionCreators.getAccounts());
    }, []);

    useMemo(() => {
        if (isStringNotEmpty(carrierId)) {
            dispatch(actionCreators.getEmployees(carrierId, false));
        }
    }, [carrierId]);

    useMemo(() => {
        if (isStringNotEmpty(shipperId) && isListNotEmpty(accounts)) {
            let shipperAccountObj = accounts.find(i => i.id === load.shipperId);
            if (isObjectNotEmpty(shipperAccountObj)) {
                // console.log(shipperAccount);
                setShipperAccount({ ...shipperAccountObj });
            }
        }
    }, [accounts, shipperId]);

    useMemo(() => {
        if (isStringNotEmpty(carrierId) && isListNotEmpty(accounts)) {
            let carrierAccountObj = accounts.find(i => i.id === carrierId);
            if (isObjectNotEmpty(carrierAccountObj)) {
                // console.log(carrierAccountObj);
                setCarrierAccount({ ...carrierAccountObj });
            }
        }
    }, [accounts, carrierId]);

    useMemo(() => {
        if (isStringNotEmpty(serviceType) && serviceType === "TMS" && isObjectNotEmpty(carrierAccount) && isStringNotEmpty(carrierAccount.id) && isObjectNotEmpty(shipperAccount) && isStringNotEmpty(shipperAccount.id)) {
            dispatch(actionCreators.fetchLinkedAccountByAccountIdAndLinkedAccountId(carrierAccount.id, shipperAccount.id));
        }
    }, [serviceType, carrierAccount, shipperAccount]);

    useMemo(() => {
        if (isStringNotEmpty(loadId) && isStringNotEmpty(carrierId) && isStringNotEmpty(userId) && isStringNotEmpty(serviceType)) {
            let fromNameValue = null;
            let fromAddressObj = {};
            let fromPointOfContactObj = {};
            if (serviceType === "TMS") {
                if (isObjectNotEmpty(linkedShipper)) {
                    fromNameValue = isStringNotEmpty(linkedShipper.name) ? linkedShipper.name : null;
                    fromAddressObj = isObjectNotEmpty(linkedShipper.accountsPayableAddress) ? linkedShipper.accountsPayableAddress : {};
                    fromPointOfContactObj = isObjectNotEmpty(linkedShipper.accountsPayable) ? linkedShipper.accountsPayable : {};
                } else if (isObjectNotEmpty(shipperAccount)) {
                    fromNameValue = isStringNotEmpty(shipperAccount.name) ? shipperAccount.name : null;
                    fromAddressObj = isObjectNotEmpty(shipperAccount.accountsPayableAddress) ? shipperAccount.accountsPayableAddress : {};
                    fromPointOfContactObj = isObjectNotEmpty(shipperAccount.accountsPayable) ? shipperAccount.accountsPayable : {};
                }
            } else {
                fromNameValue = InvoiceUtils.irisCompanyName;
                fromAddressObj = InvoiceUtils.irisPaymentAddress;
                fromPointOfContactObj = InvoiceUtils.irisPointOfContact;
            }

            const { totalAmount, lineItems, invoiceLineItemList } = InvoiceUtils.getInvoiceLineItemsAndTotal("RATE_CONFIRMATION", invoiceLineItems, serviceType === "TMS" ? "SHIPPER" : "STAFF", "CARRIER");

            let rateConfirmation = {
                loadId: loadId,
                invoiceDate: moment(),
                fromEntityType: serviceType === "TMS" ? "SHIPPER" : "STAFF",
                fromEntityId: serviceType === "TMS" ? shipperId : null,
                fromName: fromNameValue,
                fromAddress: fromAddressObj,
                fromPointOfContact: fromPointOfContactObj,
                toEntityType: 'CARRIER',
                toEntityId: carrierId,
                status: 'SENT',
                amountDue: totalAmount,
                amountDueUnit: 'USD',
                invoiceLineItemIds: invoiceLineItemList.map(i => i.id),
                sentAt: moment(),
                sentBy: userId,
                invoiceType: 'RATE_CONFIRMATION'
            };

            setInvoice(rateConfirmation);
        }
    }, [loadId, serviceType, shipperId, carrierId, invoiceLineItems, userId, invoices, shipperAccount, linkedShipper]);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    //#endregion

    if (isObjectNotEmpty(invoice)) {
        return (
            <CanSee entityAction="CREATE" entityModel="LOAD_RATE_CONFIRMATION" entityObject={load}>
                <FormProvider {...methods}>
                    <Form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true && error === null) || isSending === true || loading === true}>
                            <Fieldset legend="New Rate Confirmation">
                                <PDFDownloadLink document={<InvoiceUtils.RateConfirmationTemplate invoice={invoice} load={load} stops={stops} invoiceLineItems={invoiceLineItems} displayManualInstructions={true} />} fileName={`Rate Confirmation for Load ${load.irisId}.pdf`}>
                                    {({ blob, url, loading, error }) => (loading ? (<div style={{ textAlign: 'center' }}>Loading Rate Confirmation...</div>) : (
                                        <>
                                            <PDFViewer style={{ width: '100%', height: '80vh' }} src={url}></PDFViewer>
                                            <div style={{ textAlign: 'center', marginTop: 12 }}>
                                                <Button style={{ height: 30, width: 300 }} type="default">Download Copy of Rate Confirmation</Button>
                                            </div>
                                        </>
                                    ))}
                                </PDFDownloadLink>
                            </Fieldset>
                            <Fieldset legend="Would you like the send the rate confirmation to the carrier you have assigned to this load?">
                                <FormItem {...formItemLayout} label="Choose Employee(s) to send Rate Confirmation to" required
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Select
                                            placeholder="Please Search and Select Employee(s)"
                                            mode="multiple"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChange(selected); }}
                                            value={value}
                                            name={name}
                                            showSearch={true}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Employees for you to choose from." />}
                                            ref={ref}
                                        >
                                            {employees.map(d => <Option value={d.id} key={d.id}>{d.firstName + ' ' + d.lastName} - Title: {d.title} - Email: {d.email}</Option>)}
                                        </Select>
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="accountUserIds"
                                />
                                <br />
                                <b>Employee Doesn't Exist Yet?</b>
                                <br />
                                <Button type='primary' onClick={toggleNewEntity}>Add New Employee to Carrier Account</Button>
                            </Fieldset>
                            {error && <Alert message={`${error}`} type="error" />}
                        </Spin>
                        <FormButtons cancel={onCancel} disabled={(isLoading === true && error === null) || isSending === true} submitText="Generate and Send the Rate Confirmation" />
                    </Form>
                </FormProvider>
                <Drawer
                    title={'Add New Employee'}
                    width={fullWidth > 720 ? fullWidth / 2 : 360}
                    onClose={toggleNewEntity}
                    visible={showNewEntity}
                    bodyStyle={{ paddingBottom: 80 }}
                    style={{ zIndex: 1000 }}
                    destroyOnClose={true}
                    closable={false}
                    maskClosable={false}
                >
                    <NewEmployee cancel={toggleNewEntity} accountId={carrierId} />
                </Drawer>
            </CanSee>
        );
    } else {
        return null;
    }
};

export default LoadNewRateConfirmation;