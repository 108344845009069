import React, { useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { List, Avatar, Tooltip, Button, PageHeader, Card, Row, Col, Alert } from "antd";
import classes from './Notifications.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faExclamationTriangle, faInfoCircle, faTimesCircle, faBell, faPallet, faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { pageHeader } from '../../constants/theme';
import MomentDate from '../../shared/dateFormatter';
import InfiniteScroll from 'react-infinite-scroller';
import { isObjectEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { Can } from '../../shared/entitlements/entitlements';
import { selectListIsLoading, selectListPagination, selectListRecords } from '../../store/utility';

const momentDate = new MomentDate();

const Notifications = (props) => {
    //#region useRefs

    const scrollParentRef = useRef(null);

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const entityType = useSelector(state => state.auth.entityType);
    const entityId = useSelector(state => state.auth.entityId);
    const userId = useSelector(state => state.auth.userId);
    const notificationLists = useSelector(state => state.notifications.lists);
    const notifications = useSelector(state => selectListRecords(state.notifications.lists, "ALL"));
    const isLoading = useSelector(state => selectListIsLoading(state.notifications.lists, "ALL"));
    const pagination = useSelector(state => selectListPagination(state.notifications.lists, "ALL"));
    const error = useSelector(state => state.notifications.addRecordError);

    //#endregion
    //#region goTos

    const goToLoad = (loadId) => {
        props.history.push({ pathname: "/loads/" + loadId });
    };

    //#endregion
    //#region notification methods

    const markRead = (id) => {
        dispatch(actionCreators.readNotification(id, userId));
    };

    const markAllRead = () => {
        // console.log('mark all read');

        let unreadNotifications = notifications.filter(n => {
            return !n.read.some(r => { return r.readBy === userId; });
        });

        // console.log(unreadNotifications);

        let unreadNotificationIds = unreadNotifications.map((item) => {
            return item.id;
        });

        dispatch(actionCreators.readMultipleNotifications(unreadNotificationIds, userId));
    };

    const getNotifications = () => {
        if (isStringNotEmpty(entityType) && isStringNotEmpty(entityId) && isStringNotEmpty(userId)) {
            let searchParams = {
                page: 1,
                size: 10,
                sort: 'createdAt',
                order: 'desc'
            };

            if (entityType === "STAFF") {
                searchParams.toStaff = true;
            } else if (entityType === "SHIPPER" || entityType === "PRODUCER" || entityType === "RECEIVER" || entityType === "BROKER" || entityType === "FACTORING") {
                searchParams.accountIds = entityId;
            } else if (entityType === "CARRIER") {
                searchParams['accountIds:or'] = entityId;
                searchParams['userIds:or'] = userId;
            } else if (entityType === "DRIVER" || entityType === "NONE") {
                searchParams.userIds = userId;
            }

            dispatch(actionCreators.fetchNotificationList("ALL", searchParams));
        }
    };

    const handleInfiniteOnLoad = () => {
        // console.log(`Current Page: ${pagination.current}, Total Pages: ${pagination.totalPages}`);
        if (pagination.current + 1 <= pagination.totalPages && isLoading === false && isStringNotEmpty(entityType) && isStringNotEmpty(entityId) && isStringNotEmpty(userId)) {
            // console.log('get more');
            let searchParams = {
                page: pagination.current + 1,
                size: 10,
                sort: 'createdAt',
                order: 'desc'
            };

            if (entityType === "STAFF") {
                searchParams.toStaff = true;
            } else if (entityType === "SHIPPER" || entityType === "PRODUCER" || entityType === "RECEIVER" || entityType === "BROKER" || entityType === "FACTORING") {
                searchParams.accountIds = entityId;
            } else if (entityType === "CARRIER") {
                searchParams['accountIds:or'] = entityId;
                searchParams['userIds:or'] = userId;
            } else if (entityType === "DRIVER" || entityType === "NONE") {
                searchParams.userIds = userId;
            }

            dispatch(actionCreators.fetchMoreNotificationList("ALL", searchParams));
        } else {
            // console.log('no more items to get');
        }
    };

    //#endregion
    //#region displays

    // const extras = (
    //     <Button type="primary" onClick={(e) => { e.stopPropagation(); markAllRead(); }}>Mark All Read</Button>
    // );

    //#endregion
    //#region useEffects

    useEffect(() => {
        if (isStringNotEmpty(entityType) && isStringNotEmpty(entityId) && isStringNotEmpty(userId)) {
            if (isObjectEmpty(notificationLists) || (isObjectNotEmpty(notificationLists) && isObjectEmpty(notificationLists["ALL"]))) {
                getNotifications();
            }
        }
    }, [notificationLists, entityType, entityId, userId]);

    //#endregion

    return (
        <Can entityAction="READ_LIST" entityModel="LOAD_NOTIFICATION">
            <Row gutter={[4, 4]}>
                <Col span={24}>
                    <PageHeader
                        ghost={false}
                        title={"My Notifications"}
                        // extra={extras}
                        style={pageHeader}
                    />
                </Col>
                <Col span={24}>
                    <Card size="small">
                        {error && <Alert message={`${error}`} type="error" />}
                        <div className={classes.infiniteScrollContainer} ref={(ref) => scrollParentRef.current = ref}>
                            <InfiniteScroll
                                initialLoad={false}
                                pageStart={0}
                                loadMore={handleInfiniteOnLoad}
                                hasMore={isLoading === false && pagination.current + 1 <= pagination.totalPages}
                                useWindow={false}
                                getScrollParent={() => scrollParentRef.current}
                            >
                                <List
                                    itemLayout="vertical"
                                    dataSource={notifications}
                                    loading={isLoading === true}
                                    style={{ maxWidth: 400, margin: '0 auto' }}
                                    renderItem={(item, i) => {

                                        const itemClass = item.read.some(r => { return r.readBy === userId; }) ? classes.read : '';
                                        let leftIcon = <Avatar style={{ backgroundColor: 'gray', color: '#00000075' }}><FontAwesomeIcon icon={faBell} size="lg" /></Avatar>;
                                        let notificationColor = '#ffffff';

                                        if (item.severity) {
                                            switch (item.severity) {
                                                case 'critical':
                                                    leftIcon = <Avatar style={{ backgroundColor: 'red', color: '#ffffff75' }}><FontAwesomeIcon icon={faTimesCircle} size="lg" /></Avatar>
                                                    notificationColor = 'red';
                                                    break;
                                                case 'high':
                                                    leftIcon = <Avatar style={{ backgroundColor: 'orange', color: '#00000075' }}><FontAwesomeIcon icon={faExclamationTriangle} size="lg" /></Avatar>
                                                    notificationColor = 'orange';
                                                    break;
                                                case 'medium':
                                                    leftIcon = <Avatar style={{ backgroundColor: 'yellow', color: '#00000075' }}><FontAwesomeIcon icon={faExclamationCircle} size="lg" /></Avatar>
                                                    notificationColor = 'yellow';
                                                    break;
                                                case 'low':
                                                    leftIcon = <Avatar style={{ backgroundColor: 'blue', color: '#ffffff75' }}><FontAwesomeIcon icon={faInfoCircle} size="lg" /></Avatar>
                                                    notificationColor = 'blue';
                                                    break;
                                                default:
                                                    leftIcon = <Avatar style={{ backgroundColor: 'gray', color: '#00000075' }}><FontAwesomeIcon icon={faBell} size="lg" /></Avatar>
                                                    notificationColor = 'gray';
                                            }
                                        }

                                        let actionButtons = [];
                                        if (!item.read.some(r => { return r.readBy === userId; })) {
                                            actionButtons.push(<Tooltip placement="top" title={'Mark Read'}><Button key="1" type="link" onClick={(e) => { e.stopPropagation(); markRead(item.id); }} style={null}><FontAwesomeIcon icon={faEnvelopeOpen} size="sm" /> <span>&nbsp;&nbsp;Mark Read</span></Button></Tooltip>);
                                        }
                                        actionButtons.push(<Tooltip placement="top" title={'Go To Load'}><Button key="2" type="link" onClick={(e) => { e.stopPropagation(); goToLoad(item.loadId); }} style={null}><FontAwesomeIcon icon={faPallet} size="sm" /> <span>&nbsp;&nbsp;Go To Load</span></Button></Tooltip>);

                                        return (
                                            <List.Item
                                                className={classes.item + ' ' + itemClass}
                                                key={item.id}
                                                actions={actionButtons}>
                                                <List.Item.Meta
                                                    avatar={leftIcon}
                                                    className={classes.meta}
                                                    title={
                                                        <div className={classes.title}>{item.subject}</div>
                                                    }
                                                    description={
                                                        <div className={classes.description}>
                                                            <div className={classes.description}>{item.message}</div>
                                                            <div className={classes.dateTime}>{momentDate.fromUtcToDeviceTimeZoneForMessages(item.createdAt)}</div>
                                                        </div>
                                                    }
                                                />
                                            </List.Item>
                                        );
                                    }}
                                />
                            </InfiniteScroll>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Can>
    );
};

export default withRouter(Notifications);