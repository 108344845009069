import axiosAuthenticated from "../../api/axiosAuthenticated";
import * as Data from "./index";
import * as DTO from "../dtos/index";
import { isListNotEmpty, isNotNullOrUndefined, isStringNotEmpty } from "../../shared/objectUtils";

const invoiceLineItemsPath = '/invoiceLineItems';
const invoiceLineItemsUpdateStatusPath = '/invoiceLineItemsUpdateStatus';

//#region Invoice Line Item Methods

export const getInvoiceLineItemsByLoadId = async (loadId) => {
    if (isStringNotEmpty(loadId)) {
        const invoiceLineItemsRes = await axiosAuthenticated.get(invoiceLineItemsPath, { params: { page: 1, size: 1000000, loadId: loadId, isDeleted: false } });
        if (invoiceLineItemsRes && invoiceLineItemsRes.status === 200) {
            return invoiceLineItemsRes.data.data;
        }
    }

    return [];
};

export const getInvoiceLineItemsByLoadIdAndStopId = async (loadId, stopId) => {
    if (isStringNotEmpty(loadId) && isStringNotEmpty(stopId)) {
        const invoiceLineItemsRes = await axiosAuthenticated.get(invoiceLineItemsPath, { params: { page: 1, size: 1000000, loadId: loadId, stopId: stopId, isDeleted: false } });
        if (invoiceLineItemsRes && invoiceLineItemsRes.status === 200) {
            return invoiceLineItemsRes.data.data;
        }
    }

    return [];
};

export const getInvoiceLineItems = async (searchParams = {}, pagination = {}) => {
    const invoiceLineItemsRes = await axiosAuthenticated.get(invoiceLineItemsPath, { params: { ...searchParams } });
    if (invoiceLineItemsRes && invoiceLineItemsRes.status === 200) {
        const invoiceLineItems = invoiceLineItemsRes.data.data;
        const otherData = invoiceLineItemsRes.data;

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;

        return { data: invoiceLineItems, searchParams: searchParams, pagination: pagination };
    }

    return { data: [], searchParams: searchParams, pagination: pagination };
};

export const getInvoiceLineItem = async (id) => {
    const invoiceLineItemsRes = await axiosAuthenticated.get(invoiceLineItemsPath + `/${id}`);
    if (invoiceLineItemsRes && invoiceLineItemsRes.status === 200) {
        return invoiceLineItemsRes.data;
    }

    return null;
};

export const addInvoiceLineItem = async (payload, existingInvoiceLineItems = [], existingTransactions = [], existingClaims = [], loadId = null, sendLoadEvent = false) => {
    const invoiceLineItemPayload = DTO.getAddInvoiceLineItemRequestDTO(payload);

    const invoiceLineItemsRes = await axiosAuthenticated.post(invoiceLineItemsPath, { ...invoiceLineItemPayload });
    if (invoiceLineItemsRes && invoiceLineItemsRes.status === 201) {
        let newInvoiceLineItem = invoiceLineItemsRes.data;

        if (isStringNotEmpty(loadId)) {
            let updatedInvoiceLineItems = [];
            if (isListNotEmpty(existingInvoiceLineItems)) {
                updatedInvoiceLineItems = [...existingInvoiceLineItems];
            }
            let updatedTransactions = [];
            if (isListNotEmpty(existingTransactions)) {
                updatedTransactions = [...existingTransactions];
            }
            let updatedClaims = [];
            if (isListNotEmpty(existingClaims)) {
                updatedClaims = [...existingClaims];
            }

            updatedInvoiceLineItems.push(newInvoiceLineItem);

            await Data.updateLoadPricing(loadId, updatedInvoiceLineItems, updatedTransactions, updatedClaims);

            if (sendLoadEvent === true) {
                await Data.addLoadEvent({ loadId: loadId, eventType: 'LOAD_UPDATED', changeType: 'INVOICE_LINE_ITEMS_UPDATED' });
            }
        }

        return newInvoiceLineItem;
    }

    return null;
};

export const addInvoiceLineItems = async (invoiceLineItems, existingInvoiceLineItems = [], existingTransactions = [], existingClaims = [], loadId = null, sendLoadEvent = false) => {
    const invoiceLineItemArray = invoiceLineItems.map((invoiceLineItem) => { return DTO.getAddInvoiceLineItemRequestDTO(invoiceLineItem); });

    const invoiceLineItemsRes = await axiosAuthenticated.post(invoiceLineItemsPath, [...invoiceLineItemArray]);
    if (invoiceLineItemsRes && invoiceLineItemsRes.status === 201) {
        let newInvoiceLineItems = invoiceLineItemsRes.data;

        if (isStringNotEmpty(loadId)) {
            let updatedInvoiceLineItems = [];
            if (isListNotEmpty(existingInvoiceLineItems)) {
                updatedInvoiceLineItems = [...existingInvoiceLineItems];
            }
            let updatedTransactions = [];
            if (isListNotEmpty(existingTransactions)) {
                updatedTransactions = [...existingTransactions];
            }
            let updatedClaims = [];
            if (isListNotEmpty(existingClaims)) {
                updatedClaims = [...existingClaims];
            }

            if (isListNotEmpty(newInvoiceLineItems)) {
                newInvoiceLineItems.forEach((newInvoiceLineItem) => {
                    updatedInvoiceLineItems.push(newInvoiceLineItem);
                });
            }

            await Data.updateLoadPricing(loadId, updatedInvoiceLineItems, updatedTransactions, updatedClaims);

            if (sendLoadEvent === true) {
                await Data.addLoadEvent({ loadId: loadId, eventType: 'LOAD_UPDATED', changeType: 'INVOICE_LINE_ITEMS_UPDATED' });
            }
        }

        return newInvoiceLineItems;
    }

    return [];
};

export const updateInvoiceLineItem = async (id, payload, oldInvoiceLineItems = [], existingTransactions = [], existingClaims = [], loadId = null, sendLoadEvent = false) => {
    const invoiceLineItemPayload = DTO.getUpdateInvoiceLineItemRequestDTO(payload);

    const invoiceLineItemsRes = await axiosAuthenticated.put(invoiceLineItemsPath + `/${id}`, { ...invoiceLineItemPayload });
    if (invoiceLineItemsRes && invoiceLineItemsRes.status === 200) {
        let updatedInvoiceLineItem = invoiceLineItemsRes.data;

        if (isStringNotEmpty(loadId)) {
            let existingInvoiceLineItems = [];
            if (isListNotEmpty(oldInvoiceLineItems)) {
                existingInvoiceLineItems = [...oldInvoiceLineItems];
            }
            let updatedTransactions = [];
            if (isListNotEmpty(existingTransactions)) {
                updatedTransactions = [...existingTransactions];
            }
            let updatedClaims = [];
            if (isListNotEmpty(existingClaims)) {
                updatedClaims = [...existingClaims];
            }

            const index = existingInvoiceLineItems.findIndex(obj => obj.id === updatedInvoiceLineItem.id);
            if (index !== -1) {
                const updatedInvoiceLineItems = [
                    ...existingInvoiceLineItems.slice(0, index), // everything before current obj
                    updatedInvoiceLineItem,
                    ...existingInvoiceLineItems.slice(index + 1), // everything after current obj
                ];

                await Data.updateLoadPricing(loadId, updatedInvoiceLineItems, updatedTransactions, updatedClaims);
            }

            if (sendLoadEvent === true) {
                await Data.addLoadEvent({ loadId: loadId, eventType: 'LOAD_UPDATED', changeType: 'INVOICE_LINE_ITEMS_UPDATED' });
            }
        }

        return updatedInvoiceLineItem;
    }

    return null;
};

export const updateInvoiceLineItems = async (ids, payload, existingInvoiceLineItems = [], existingTransactions = [], existingClaims = [], loadId = null, sendLoadEvent = false) => {
    if (isListNotEmpty(ids)) {
        let invoiceLineItemsToUpdate = [];
        for (let i = 0; i < ids.length; i++) {
            const invoiceLineItemsRes = await axiosAuthenticated.put(invoiceLineItemsPath + `/${ids[i]}`, { ...payload });
            if (invoiceLineItemsRes && invoiceLineItemsRes.status === 200) {
                let updatedInvoiceLineItem = invoiceLineItemsRes.data;

                invoiceLineItemsToUpdate.push(updatedInvoiceLineItem);
            }
        }

        if (isStringNotEmpty(loadId)) {
            let updatedInvoiceLineItems = [];
            if (isListNotEmpty(existingInvoiceLineItems)) {
                updatedInvoiceLineItems = [...existingInvoiceLineItems];
            }
            let updatedTransactions = [];
            if (isListNotEmpty(existingTransactions)) {
                updatedTransactions = [...existingTransactions];
            }
            let updatedClaims = [];
            if (isListNotEmpty(existingClaims)) {
                updatedClaims = [...existingClaims];
            }

            if (isListNotEmpty(invoiceLineItemsToUpdate)) {
                invoiceLineItemsToUpdate.forEach((updatedInvoiceLineItem) => {
                    const index = existingInvoiceLineItems.findIndex(obj => obj.id === updatedInvoiceLineItem.id);
                    if (index !== -1) {
                        updatedInvoiceLineItems = [
                            ...updatedInvoiceLineItems.slice(0, index), // everything before current obj
                            updatedInvoiceLineItem,
                            ...updatedInvoiceLineItems.slice(index + 1), // everything after current obj
                        ];
                    }
                });

                await Data.updateLoadPricing(loadId, updatedInvoiceLineItems, updatedTransactions, updatedClaims);
            }

            if (sendLoadEvent === true) {
                await Data.addLoadEvent({ loadId: loadId, eventType: 'LOAD_UPDATED', changeType: 'INVOICE_LINE_ITEMS_UPDATED' });
            }
        }

        return invoiceLineItemsToUpdate;
    }

    return [];
};

export const updateInvoiceLineItemsStatus = async (ids, status, existingInvoiceLineItems = []) => {
    if (isListNotEmpty(ids)) {
        const invoiceLineItemsRes = await axiosAuthenticated.post(invoiceLineItemsUpdateStatusPath, { ids: ids, status: status });
        if (invoiceLineItemsRes && invoiceLineItemsRes.status === 204) {
            let updatedInvoiceLineItems = [];
            if (isListNotEmpty(existingInvoiceLineItems)) {
                existingInvoiceLineItems.forEach((existingInvoiceLineItem) => {
                    let updatedExistingInvoiceLineItem = {
                        ...existingInvoiceLineItem,
                        status: status
                    };
                    updatedInvoiceLineItems.push(updatedExistingInvoiceLineItem);
                });
            }

            return { updateStatus: true, updatedInvoiceLineItems: updatedInvoiceLineItems };
        }
    }

    return { updateStatus: false, updatedInvoiceLineItems: [] };
};

export const removeInvoiceLineItem = async (id, existingInvoiceLineItems = [], existingTransactions = [], existingClaims = [], loadId = null, sendLoadEvent = false) => {
    const invoiceLineItemsRes = await axiosAuthenticated.put(invoiceLineItemsPath + `/${id}`, { isDeleted: true });
    if (invoiceLineItemsRes && invoiceLineItemsRes.status === 200) {
        let updatedInvoiceLineItem = invoiceLineItemsRes.data;

        if (isStringNotEmpty(loadId)) {
            let updatedInvoiceLineItems = [];
            if (isListNotEmpty(existingInvoiceLineItems)) {
                updatedInvoiceLineItems = [...existingInvoiceLineItems];
            }
            let updatedTransactions = [];
            if (isListNotEmpty(existingTransactions)) {
                updatedTransactions = [...existingTransactions];
            }
            let updatedClaims = [];
            if (isListNotEmpty(existingClaims)) {
                updatedClaims = [...existingClaims];
            }

            const index = updatedInvoiceLineItems.findIndex(obj => obj.id === updatedInvoiceLineItem.id);
            if (index !== -1) {
                // returns the deleted items
                updatedInvoiceLineItems.splice(index, 1);

                await Data.updateLoadPricing(loadId, updatedInvoiceLineItems, updatedTransactions, updatedClaims);
            }

            if (sendLoadEvent === true) {
                await Data.addLoadEvent({ loadId: loadId, eventType: 'LOAD_UPDATED', changeType: 'INVOICE_LINE_ITEMS_UPDATED' });
            }
        }

        return updatedInvoiceLineItem;
    }

    return null;
};

export const removeInvoiceLineItems = async (ids, existingInvoiceLineItems = [], existingTransactions = [], existingClaims = [], loadId = null, sendLoadEvent = false) => {
    if (isListNotEmpty(ids)) {
        let invoiceLineItemsToRemove = [];
        for (let i = 0; i < ids.length; i++) {
            const invoiceLineItemsRes = await axiosAuthenticated.put(invoiceLineItemsPath + `/${ids[i]}`, { isDeleted: true });
            if (invoiceLineItemsRes && invoiceLineItemsRes.status === 200) {
                const updatedInvoiceLineItem = invoiceLineItemsRes.data;

                invoiceLineItemsToRemove.push(updatedInvoiceLineItem);
            }
        }

        if (isStringNotEmpty(loadId)) {
            let updatedInvoiceLineItems = [];
            if (isListNotEmpty(existingInvoiceLineItems)) {
                updatedInvoiceLineItems = [...existingInvoiceLineItems];
            }
            let updatedTransactions = [];
            if (isListNotEmpty(existingTransactions)) {
                updatedTransactions = [...existingTransactions];
            }
            let updatedClaims = [];
            if (isListNotEmpty(existingClaims)) {
                updatedClaims = [...existingClaims];
            }

            if (isListNotEmpty(invoiceLineItemsToRemove)) {
                invoiceLineItemsToRemove.forEach((updatedInvoiceLineItem) => {
                    const index = updatedInvoiceLineItems.findIndex(obj => obj.id === updatedInvoiceLineItem.id);
                    if (index !== -1) {
                        // returns the deleted items
                        updatedInvoiceLineItems.splice(index, 1);
                    }
                });

                await Data.updateLoadPricing(loadId, updatedInvoiceLineItems, updatedTransactions, updatedClaims);
            }

            if (sendLoadEvent === true) {
                await Data.addLoadEvent({ loadId: loadId, eventType: 'LOAD_UPDATED', changeType: 'INVOICE_LINE_ITEMS_UPDATED' });
            }
        }

        return invoiceLineItemsToRemove;
    }

    return [];
};

//#endregion