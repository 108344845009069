import React, { useState, useMemo } from 'react';
import { Spin, Button, Input, Select, Empty, Drawer, message, Alert } from "antd";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import moment from 'moment';
import { FormProvider, useForm } from 'react-hook-form';
import Form from '../../components/Form/Form';
import FormItem from '../../components/FormItem/FormItem';
import Fieldset from '../../components/FormFieldset/FormFieldset';
import { isBooleanTrue, isListNotEmpty, isNotNullOrUndefined, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import FormItemDisplay from '../../components/FormItemDisplay/FormItemDisplay';
import { CanSee } from '../../shared/entitlements/entitlements';
import InvoiceUtils from '../../api/utils/invoiceUtils';
import { selectListIsLoading, selectListRecords } from '../../store/utility';
import NewEmployee from '../NewEmployee/NewEmployee';
import FormButtons from '../FormButtons/FormButtons';
import StringFormatter from '../../shared/stringFormatter';
import DocumentUtils from '../../api/utils/documentUtils';
import Invoice from '../Invoice/Invoice';
import DataTable from '../DataTable/DataTable';
import Enums from '../../shared/enums';
import DatePicker from '../DatePickerAutoAccept/DatePickerAutoAccept';
import DataLinkRow from '../DataLinkRow/DataLinkRow';
import InvoiceTable from '../InvoiceTable/InvoiceTable';
import FormItemAddress from '../FormItemAddress/FormItemAddress';

const { Option } = Select;
const { TextArea } = Input;
const stringFormatter = new StringFormatter();

const LoadEditShipperInvoice = ({ load, loadId, serviceType, shipperId, carrierId, stops, invoiceId, invoice, invoiceLineItems, cancel, loading = false }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    //#endregion
    //#region enums

    const preferredPaymentMethodOptions = Enums.PreferredPaymentMethods.selectListOptions();

    //#endregion
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const methods2 = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const userId = useSelector(state => state.auth.userId);
    const employees = useSelector(state => selectListRecords(state.orchestrator.employees, shipperId));
    const documents = useSelector(state => DocumentUtils.selectListVisibleDocumentsByLoadIdAndStops(state.documents.lists, loadId, load, stops, 'SHIPPER', false));
    const isLoadingDocuments = useSelector(state => selectListIsLoading(state.documents.lists, loadId));
    const updatedInvoice = useSelector(state => state.invoices.record);
    const isLoadingUpdatedInvoice = useSelector(state => state.invoices.isRecordLoading);
    const errorUpdatedInvoice = useSelector(state => state.invoices.recordError);
    const isLoadingUpdateInvoice = useSelector(state => state.invoices.isRecordUpdateLoading);
    const errorUpdateInvoice = useSelector(state => state.invoices.updateRecordError);

    //#endregion
    //#region useStates

    const [showNewEmployee, setShowNewEmployee] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [showUpdatedInvoice, setShowUpdatedInvoice] = useState(false);
    const [selectedDocumentIds, setSelectedDocumentIds] = useState([]);

    //#endregion
    //#region table displays

    const rowSelectionDocuments = {
        hideSelectAll: false,
        columnTitle: 'Attach',
        columnWidth: 50,
        type: 'checkbox',
        selectedRowKeys: isListNotEmpty(selectedDocumentIds) ? [...selectedDocumentIds] : [],
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            console.log('selectedRowKeys changed: ', selectedRowKeys);
            if (isListNotEmpty(selectedRowKeys)) {
                setSelectedDocumentIds(selectedRowKeys);
            } else {
                setSelectedDocumentIds([]);
            }
        },
        getCheckboxProps: record => ({
            name: record.id,
        }),
    };

    const documentColumns = [
        {
            title: 'Document',
            dataIndex: 'documentDisplayName',
            key: 'documentDisplayName',
            textAlign: 'left',
            render: (text, record, index) => {
                return (<DataLinkRow key={`invoice-document-${index}`} title={text} fileId={record.id} />);
            },
        },
    ];

    const priceConfirmationColumns = [
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            textAlign: 'left',
        },
        {
            title: 'Units',
            dataIndex: 'quantity',
            key: 'quantity',
            textAlign: 'center',
            dataType: 'Decimal',
        },
        {
            title: 'Per',
            dataIndex: 'baseAmount',
            key: 'baseAmount',
            textAlign: 'center',
            dataType: 'Money',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
        },
        {
            title: 'Amount',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            textAlign: 'right',
            dataType: 'Money',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
        },
    ];

    //#endregion
    //#region toggles

    const toggleNewEmployee = () => {
        setShowNewEmployee(!showNewEmployee);
    };

    //#endregion
    //#region onSubmit

    const onSubmitUpdateInvoice = async (data) => {
        if (isListNotEmpty(invoiceLineItems) && isObjectNotEmpty(invoice) && isObjectNotEmpty(load) && isStringNotEmpty(loadId) && isListNotEmpty(stops) && isStringNotEmpty(invoiceId)) {
            if (invoice.status !== 'COMPLETED' && invoice.status !== 'CANCELLED') {
                // the shipper invoice isn't paid yet so just update it

                let selectedInvoiceLineItems = [];
                invoiceLineItems.filter(i => (i.fromEntityType === 'SHIPPER' || i.toEntityType === 'SHIPPER') && (i.invoiceId === null || i.invoiceId === undefined || i.invoiceId === invoiceId) && i.status !== 'COMPLETED' && i.isDeleted === false).forEach((invoiceLineItem) => {
                    selectedInvoiceLineItems.push(invoiceLineItem);
                });

                const { totalAmount, lineItems, invoiceLineItemList } = InvoiceUtils.getInvoiceLineItemsAndTotal("SHIPPER_INVOICE", selectedInvoiceLineItems, "SHIPPER", "STAFF");

                let updatedShipperInvoice = {
                    paymentMethod: data.paymentMethod,
                    invoiceDate: data.invoiceDate,
                    invoiceNotes: isStringNotEmpty(data.invoiceNotes) ? data.invoiceNotes : null,
                    remitName: data.remitName,
                    remitAddress: data.remitAddress,
                    paymentTerms: Number(data.paymentTerms),
                    paymentDueDate: data.paymentDueDate,
                    amountDue: totalAmount,
                    amountDueUnit: 'USD',
                    balanceDue: totalAmount,
                    balanceDueUnit: 'USD',
                    invoiceLineItemIds: invoiceLineItemList.map(i => i.id),
                };

                dispatch(actionCreators.updateInvoice(invoice.id, updatedShipperInvoice, invoice, true));
            }
        }
    };

    const onSubmitSendInvoice = async (data) => {
        if (isObjectNotEmpty(updatedInvoice) && isStringNotEmpty(updatedInvoice.id) && isListNotEmpty(employees) && isListNotEmpty(data.accountUserIds) && isObjectNotEmpty(load) && isStringNotEmpty(loadId) && isStringNotEmpty(userId)) {
            setIsSending(true);
            if (updatedInvoice.status !== 'COMPLETED') {
                // save the pdf document to s3
                let newDocument = await InvoiceUtils.saveInvoiceAsDocument('SHIPPER_INVOICE', updatedInvoice, updatedInvoice.invoiceLineItems, load, stops, false);

                let attachments = [];
                if (isListNotEmpty(selectedDocumentIds) && isListNotEmpty(documents)) {
                    selectedDocumentIds.forEach((documentId) => {
                        let doc = documents.find(d => d.id === documentId);
                        if (isObjectNotEmpty(doc)) {
                            attachments.push({
                                documentId: doc.id,
                                documentFileName: `${doc.documentDisplayName}${doc.fileExt}`,
                                documentFileType: doc.fileType
                            });
                        }
                    });
                }

                // send shipper invoice emails to recipients
                let emailsWereSent = false;
                let toEmails = [];
                for (let i = 0; i < data.accountUserIds.length; i++) {
                    const accountUserId = data.accountUserIds[i];
                    const accountUser = employees.find(a => a.id === accountUserId);
                    if (isObjectNotEmpty(accountUser)) {
                        toEmails.push({ email: accountUser.email, name: accountUser.firstName + ' ' + accountUser.lastName });
                    }
                }

                emailsWereSent = await InvoiceUtils.sendShipperInvoiceEmail('ap@irisfreight.com', toEmails, updatedInvoice, load, stops, newDocument, attachments);
                if (emailsWereSent === true) {
                    message.success('Email(s) have been sent.');
                    dispatch(actionCreators.updateInvoice(updatedInvoice.id, { status: 'SENT', sentAt: moment(), sentBy: userId }, updatedInvoice, true));
                    onCancel();
                } else {
                    message.error('Failed to send email(s)');
                }
            }
            setIsSending(false);
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.updateInvoiceCancel());
        cancel();
    };

    const done = (invoiceId) => {
        dispatch(actionCreators.updateInvoiceCancel());
        setShowUpdatedInvoice(true);
        dispatch(actionCreators.fetchInvoice(invoiceId));
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        dispatch(actionCreators.updateInvoiceErrorClear());
    }, []);

    useMemo(() => {
        if (isStringNotEmpty(shipperId)) {
            dispatch(actionCreators.getEmployees(shipperId, false));
        }
    }, [shipperId]);

    useMemo(() => {
        if (isStringNotEmpty(loadId) && isBooleanTrue(showUpdatedInvoice)) {
            let ids = [];
            ids.push(loadId);

            dispatch(actionCreators.fetchBulkDocumentLists(loadId, ids));
        }
    }, [loadId, showUpdatedInvoice]);

    useMemo(() => {
        if (isLoadingUpdateInvoice !== null && isLoadingUpdateInvoice === false && errorUpdateInvoice === null && isStringNotEmpty(invoiceId)) {
            if (showUpdatedInvoice === false) {
                done(invoiceId);
            }
        }
    }, [isLoadingUpdateInvoice, errorUpdateInvoice, showUpdatedInvoice, invoiceId]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    //#endregion

    if (showUpdatedInvoice === true) {
        if (isObjectNotEmpty(updatedInvoice) && isStringNotEmpty(invoiceId) && updatedInvoice.id === invoiceId && isLoadingUpdatedInvoice === false && errorUpdatedInvoice === null) {
            return (
                <CanSee entityAction="READ" entityModel="LOAD_SHIPPER_INVOICE" entityObject={load}>
                    <Invoice invoiceId={updatedInvoice.id} invoice={updatedInvoice} load={load} loadId={loadId} stops={stops} />

                    {updatedInvoice.status !== 'COMPLETED' ? (
                        <>
                            <FormProvider {...methods2}>
                                <Form onSubmit={methods2.handleSubmit(onSubmitSendInvoice)}>
                                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoadingDocuments === true || isSending === true || (isLoadingUpdateInvoice === true && errorUpdateInvoice === null) || loading === true}>
                                        <Fieldset legend="Would you like the send the invoice to the shipper you have assigned to this load?">
                                            <FormItem {...formItemLayout} label="Choose Employee(s) to send Invoice to" required
                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                    <Select
                                                        placeholder="Please Search and Select Employee(s)"
                                                        mode="multiple"
                                                        allowClear={true}
                                                        style={{ width: '100%' }}
                                                        virtual={false}
                                                        onBlur={onBlur}
                                                        onChange={(selected) => { onChange(selected); }}
                                                        value={value}
                                                        name={name}
                                                        showSearch={true}
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Employees for you to choose from." />}
                                                        ref={ref}
                                                    >
                                                        {employees.map(d => <Option value={d.id} key={d.id}>{d.firstName + ' ' + d.lastName} - Title: {d.title} - Email: {d.email}</Option>)}
                                                    </Select>
                                                )}
                                                rules={{ required: 'Required Field' }}
                                                name="accountUserIds"
                                            />
                                            <br />
                                            <b>Employee Doesn't Exist Yet?</b>
                                            <br />
                                            <Button type='primary' onClick={toggleNewEmployee}>Add New Employee to Shipper Account</Button>
                                        </Fieldset>
                                        <Fieldset legend="Do you want to attach any documents to the email?">
                                            <DataTable
                                                bordered={false}
                                                rowSelection={rowSelectionDocuments}
                                                dataSource={documents.filter(d => d.documentType !== 'CARRIER_INVOICE' && d.documentType !== 'SHIPPER_INVOICE' && d.documentType !== 'FACTORING_INVOICE' && d.documentType !== 'INVOICE')}
                                                columns={documentColumns}
                                                hidePaging={true}
                                                style={{ backgroundColor: '#ffffff' }}
                                                rowKey={record => record.id}
                                            />
                                        </Fieldset>
                                    </Spin>
                                    {errorUpdateInvoice && <Alert message={`${errorUpdateInvoice}`} type="error" />}
                                    <FormButtons cancel={onCancel} disabled={isSending === true || (isLoadingUpdateInvoice === true && errorUpdateInvoice === null)} submitText="Send the Invoice" />
                                </Form>
                            </FormProvider>
                            <Drawer
                                title={'Add New Employee'}
                                width={fullWidth > 720 ? fullWidth / 2 : 360}
                                onClose={toggleNewEmployee}
                                visible={showNewEmployee}
                                bodyStyle={{ paddingBottom: 80 }}
                                style={{ zIndex: 1000 }}
                                destroyOnClose={true}
                                closable={false}
                                maskClosable={false}
                            >
                                <NewEmployee cancel={toggleNewEmployee} accountId={shipperId} />
                            </Drawer>
                        </>
                    ) : null}
                </CanSee>
            );
        } else if (isNotNullOrUndefined(errorUpdateInvoice)) {
            return <Alert message={`${errorUpdateInvoice}`} type="error" />;
        } else if (isLoadingUpdateInvoice === true && errorUpdateInvoice === null) {
            return <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoadingUpdateInvoice === true && errorUpdateInvoice === null} />
        } else {
            return null;
        }
    } else if (isObjectNotEmpty(invoice) && isStringNotEmpty(invoiceId) && invoice.id === invoiceId) {
        return (
            <CanSee entityAction="UPDATE" entityModel="LOAD_SHIPPER_INVOICE" entityObject={load}>
                <FormProvider {...methods}>
                    <Form onSubmit={methods.handleSubmit(onSubmitUpdateInvoice)}>
                        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoadingUpdateInvoice === true && errorUpdateInvoice === null) || loading === true}>
                            <Fieldset legend="Review the charges below">
                                <InvoiceTable
                                    entityType="SHIPPER"
                                    isPriceConfirmation={true}
                                    data={invoiceLineItems.filter(i => (i.fromEntityType === 'SHIPPER' || i.toEntityType === 'SHIPPER') && (i.invoiceId === null || i.invoiceId === undefined || i.invoiceId === invoiceId) && i.status !== 'COMPLETED' && i.isDeleted === false)}
                                    columns={priceConfirmationColumns}
                                />
                            </Fieldset>
                            <Fieldset legend="Please specify the details below before generating the Shipper Invoice">
                                <FormItem {...formItemLayout} label="Invoice Date" required format="vertical"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <DatePicker
                                            placeholder="Invoice Date"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={(date, dateString) => { onChange(date); }}
                                            value={value}
                                            name={name}
                                            onSelect={(date) => { onChange(date); }}
                                            ref={ref}
                                        />
                                    )}
                                    rules={{ required: "Required Field" }}
                                    name="invoiceDate"
                                    defaultValue={invoice.invoiceDate ? moment(invoice.invoiceDate) : null}
                                />

                                <FormItem {...formItemLayout} label="Payment Terms" required format="vertical"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Select
                                            placeholder="Please Select the Payment Terms"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChange(selected); }}
                                            value={value}
                                            name={name}
                                            ref={ref}
                                        >
                                            <Option key='0' value='0'>NET 0</Option>
                                            <Option key='15' value='15'>NET 15</Option>
                                            <Option key='30' value='30'>NET 30</Option>
                                            <Option key='45' value='45'>NET 45</Option>
                                            <Option key='60' value='60'>NET 60</Option>
                                        </Select>
                                    )}
                                    rules={{ required: "Required Field" }}
                                    name="paymentTerms"
                                    defaultValue={invoice.paymentTerms}
                                />
                                <FormItem {...formItemLayout} label="Payment Due Date" required format="vertical"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <DatePicker
                                            placeholder="Payment Due Date"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={(date, dateString) => { onChange(date); }}
                                            value={value}
                                            name={name}
                                            onSelect={(date) => { onChange(date); }}
                                            ref={ref}
                                        />
                                    )}
                                    rules={{ required: "Required Field" }}
                                    name="paymentDueDate"
                                    defaultValue={invoice.paymentDueDate ? moment(invoice.paymentDueDate) : null}
                                />
                                <FormItem {...formItemLayout} label="Preferred Payment Method" format="vertical"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Select
                                            placeholder="Please Select the Preferred Payment Method"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChange(selected); }}
                                            value={value}
                                            name={name}
                                            ref={ref}
                                        >
                                            {preferredPaymentMethodOptions}
                                        </Select>
                                    )}
                                    rules={{ required: false }}
                                    name="paymentMethod"
                                    defaultValue={invoice.paymentMethod}
                                />
                                <FormItemDisplay {...formItemLayout} label="Remit Address" format="vertical">
                                    <FormItem {...formItemLayout} label="Remit Name" format="vertical" required
                                        render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Remit Name" ref={ref} />}
                                        rules={{ required: "Required Field" }}
                                        name="remitName"
                                        defaultValue={invoice.remitName}
                                    />
                                    <FormItemAddress
                                        format="vertical"
                                        required={false}
                                        name="remitAddress"
                                        defaultValue={invoice.remitAddress}
                                        isPostalAddress={true}
                                    />
                                </FormItemDisplay>
                                <FormItem {...formItemLayout} label="Notes" format="vertical"
                                    render={({ onChange, onBlur, value, name, ref }) => <TextArea onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} autoSize={{ minRows: 4 }} placeholder="Notes" ref={ref} />}
                                    rules={{ required: false }}
                                    name="invoiceNotes"
                                    defaultValue={invoice.invoiceNotes}
                                />
                            </Fieldset>
                        </Spin>
                        {errorUpdateInvoice && <Alert message={`${errorUpdateInvoice}`} type="error" />}
                        <FormButtons containerStyle={{ position: 'relative' }} cancel={onCancel} disabled={isLoadingUpdateInvoice === true && errorUpdateInvoice === null} submitText="Update Shipper Invoice" />
                    </Form>
                </FormProvider>
            </CanSee>
        );
    } else {
        return null;
    }
};

export default LoadEditShipperInvoice;