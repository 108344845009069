import * as actionTypes from "../actions/actionTypes";
import * as actionCreators from "./index";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { isBooleanTrue, isObjectNotEmpty } from "../../shared/objectUtils";
import ErrorUtils from "../../shared/errorUtils";

//#region Fetch Account Registrations Functions

export const fetchAccountRegistrationsStart = () => {
    return {
        type: actionTypes.FETCH_ACCOUNT_REGISTRATIONS_START
    }
};

export const fetchAccountRegistrationsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_ACCOUNT_REGISTRATIONS_SUCCESS,
        payload: payload
    }
};

export const fetchAccountRegistrationsFail = (payload) => {
    return {
        type: actionTypes.FETCH_ACCOUNT_REGISTRATIONS_FAIL,
        payload: payload
    }
};

export const clearAccountRegistrations = () => {
    return {
        type: actionTypes.CLEAR_ACCOUNT_REGISTRATIONS
    }
};

//#endregion
//#region Add Account Registration Methods

const insertAccountRegistration = (payload) => {
    return {
        type: actionTypes.ADD_ACCOUNT_REGISTRATION,
        payload: payload
    }
};

export const addAccountRegistrationStart = () => {
    return {
        type: actionTypes.ADD_ACCOUNT_REGISTRATION_START
    }
};

export const addAccountRegistrationSuccess = () => {
    return {
        type: actionTypes.ADD_ACCOUNT_REGISTRATION_SUCCESS
    }
};

export const addAccountRegistrationFail = (payload) => {
    return {
        type: actionTypes.ADD_ACCOUNT_REGISTRATION_FAIL,
        payload: payload
    }
};

export const addAccountRegistrationLoadingClear = () => {
    return {
        type: actionTypes.ADD_ACCOUNT_REGISTRATION_LOADING_CLEAR
    }
};

export const addAccountRegistrationErrorClear = () => {
    return {
        type: actionTypes.ADD_ACCOUNT_REGISTRATION_ERROR_CLEAR
    }
};

export const addAccountRegistrationCancel = () => {
    return {
        type: actionTypes.ADD_ACCOUNT_REGISTRATION_CANCEL
    }
};

//#endregion
//#region Update Account Registration Methods

const changeAccountRegistration = (payload) => {
    return {
        type: actionTypes.UPDATE_ACCOUNT_REGISTRATION,
        payload: payload
    }
};

const changeSingleAccountRegistration = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_ACCOUNT_REGISTRATION,
        payload: payload
    }
};

export const updateAccountRegistrationStart = () => {
    return {
        type: actionTypes.UPDATE_ACCOUNT_REGISTRATION_START
    }
};

export const updateAccountRegistrationSuccess = () => {
    return {
        type: actionTypes.UPDATE_ACCOUNT_REGISTRATION_SUCCESS
    }
};

export const updateAccountRegistrationFail = (payload) => {
    return {
        type: actionTypes.UPDATE_ACCOUNT_REGISTRATION_FAIL,
        payload: payload
    }
};

export const updateAccountRegistrationLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_ACCOUNT_REGISTRATION_LOADING_CLEAR
    }
};

export const updateAccountRegistrationErrorClear = () => {
    return {
        type: actionTypes.UPDATE_ACCOUNT_REGISTRATION_ERROR_CLEAR
    }
};

export const updateAccountRegistrationCancel = () => {
    return {
        type: actionTypes.UPDATE_ACCOUNT_REGISTRATION_CANCEL
    }
};

//#endregion
//#region Remove Account Registration Methods

const removeAccountRegistration = (payload) => {
    return {
        type: actionTypes.REMOVE_ACCOUNT_REGISTRATION,
        payload: payload
    }
};

//#endregion
//#region Account Registrations Methods

export const fetchAccountRegistrations = (payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(fetchAccountRegistrationsStart());

            await dispatch(actionCreators.getStaff());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const staff = [...orchestratorState.staff];
            const accountRegistrationsState = { ...state.accountRegistrations };
            let pagination = { ...accountRegistrationsState.pagination };
            let searchParams = { ...accountRegistrationsState.searchParams };

            if (isObjectNotEmpty(payload)) {
                searchParams = { ...payload };
            }

            const res = await Data.getAccountRegistrations({ ...searchParams }, pagination, staff);
            dispatch(fetchAccountRegistrationsSuccess({ records: res.data, searchParams: res.searchParams, pagination: res.pagination }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchAccountRegistrationsFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const addAccountRegistration = (payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(addAccountRegistrationStart());

            await dispatch(actionCreators.getStaff());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const staff = [...orchestratorState.staff];

            const newAccountRegistration = await Data.addAccountRegistration({ ...payload }, staff);
            if (isObjectNotEmpty(newAccountRegistration)) {
                dispatch(insertAccountRegistration(newAccountRegistration));
            }

            dispatch(addAccountRegistrationSuccess());
            dispatch(addAccountRegistrationLoadingClear());
            dispatch(addAccountRegistrationErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addAccountRegistrationFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateAccountRegistration = (id, payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updateAccountRegistrationStart());

            if (isBooleanTrue(payload.isDeleted)) {
                const removedAccountRegistration = await Data.removeAccountRegistration(id);
                if (isObjectNotEmpty(removedAccountRegistration)) {
                    dispatch(removeAccountRegistration(removedAccountRegistration));
                }
            } else {
                await dispatch(actionCreators.getStaff());
                const state = getState();
                const orchestratorState = { ...state.orchestrator };
                const staff = [...orchestratorState.staff];

                const updatedAccountRegistration = await Data.updateAccountRegistration(id, { ...payload }, staff);
                if (isObjectNotEmpty(updatedAccountRegistration)) {
                    dispatch(changeAccountRegistration(updatedAccountRegistration));
                    dispatch(changeSingleAccountRegistration(updatedAccountRegistration));
                }
            }

            dispatch(updateAccountRegistrationSuccess());
            dispatch(updateAccountRegistrationLoadingClear());
            dispatch(updateAccountRegistrationErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateAccountRegistrationFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion