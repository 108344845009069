import React, { useState, useMemo } from 'react';
import { Spin, Button, Input, Select, Empty, Drawer, message, Alert } from "antd";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import moment from 'moment';
import { FormProvider, useForm } from 'react-hook-form';
import Form from '../../components/Form/Form';
import FormItem from '../../components/FormItem/FormItem';
import Fieldset from '../../components/FormFieldset/FormFieldset';
import { isBooleanTrue, isListNotEmpty, isNotNullOrUndefined, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import FormItemDisplay from '../../components/FormItemDisplay/FormItemDisplay';
import { CanSee } from '../../shared/entitlements/entitlements';
import InvoiceUtils from '../../api/utils/invoiceUtils';
import { selectListIsLoading, selectListRecords } from '../../store/utility';
import NewEmployee from '../NewEmployee/NewEmployee';
import FormButtons from '../FormButtons/FormButtons';
import InvoiceTable from '../InvoiceTable/InvoiceTable';
import StringFormatter from '../../shared/stringFormatter';
import DocumentUtils from '../../api/utils/documentUtils';
import Invoice from '../Invoice/Invoice';
import Enums from '../../shared/enums';
import DataTable from '../DataTable/DataTable';
import DataLinkRow from '../DataLinkRow/DataLinkRow';
import LoadStopUtils from '../../api/utils/loadStopUtils';
import FormItemAddress from '../FormItemAddress/FormItemAddress';

const { Option } = Select;
const { TextArea } = Input;
const stringFormatter = new StringFormatter();

const LoadNewShipperInvoice = ({ load, loadId, shipperId, stops, invoices, invoiceLineItems, cancel, loading = false }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    //#endregion
    //#region enums

    const preferredPaymentMethodOptions = Enums.PreferredPaymentMethods.selectListOptions();

    //#endregion
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const methods2 = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const accounts = useSelector(state => state.orchestrator.accounts);
    const userId = useSelector(state => state.auth.userId);
    const employees = useSelector(state => selectListRecords(state.orchestrator.employees, shipperId));
    const documents = useSelector(state => DocumentUtils.selectListVisibleDocumentsByLoadIdAndStops(state.documents.lists, loadId, load, stops, 'SHIPPER', false));
    const isLoadingDocuments = useSelector(state => selectListIsLoading(state.documents.lists, loadId));
    const addInvoiceId = useSelector(state => state.invoices.addRecordId);
    const isLoadingAddInvoice = useSelector(state => state.invoices.isRecordAddLoading);
    const errorAddInvoice = useSelector(state => state.invoices.addRecordError);
    const newInvoice = useSelector(state => state.invoices.record);
    const isLoadingNewInvoice = useSelector(state => state.invoices.isRecordLoading);
    const errorNewInvoice = useSelector(state => state.invoices.recordError);
    const isLoadingUpdateInvoice = useSelector(state => state.invoices.isRecordUpdateLoading);
    const errorUpdateInvoice = useSelector(state => state.invoices.updateRecordError);

    //#endregion
    //#region useStates

    const [showNewEmployee, setShowNewEmployee] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [showNewInvoice, setShowNewInvoice] = useState(false);
    const [shipperAccount, setShipperAccount] = useState(null);
    const [selectedDocumentIds, setSelectedDocumentIds] = useState([]);

    //#endregion
    //#region table displays

    const rowSelectionDocuments = {
        hideSelectAll: false,
        columnTitle: 'Attach',
        columnWidth: 50,
        type: 'checkbox',
        selectedRowKeys: isListNotEmpty(selectedDocumentIds) ? [...selectedDocumentIds] : [],
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            console.log('selectedRowKeys changed: ', selectedRowKeys);
            if (isListNotEmpty(selectedRowKeys)) {
                setSelectedDocumentIds(selectedRowKeys);
            } else {
                setSelectedDocumentIds([]);
            }
        },
        getCheckboxProps: record => ({
            name: record.id,
        }),
    };

    const documentColumns = [
        {
            title: 'Document',
            dataIndex: 'documentDisplayName',
            key: 'documentDisplayName',
            textAlign: 'left',
            render: (text, record, index) => {
                return (<DataLinkRow key={`invoice-document-${index}`} title={text} fileId={record.id} />);
            },
        },
    ];

    //#endregion
    //#region toggles

    const toggleNewEmployee = () => {
        setShowNewEmployee(!showNewEmployee);
    };

    //#endregion
    //#region onSubmit

    const onSubmitCreateInvoice = async (data) => {
        if (isObjectNotEmpty(load) && isStringNotEmpty(loadId) && isStringNotEmpty(shipperId) && isObjectNotEmpty(shipperAccount) && isListNotEmpty(stops)) {
            const { totalAmount, lineItems, invoiceLineItemList } = InvoiceUtils.getInvoiceLineItemsAndTotal("SHIPPER_INVOICE", invoiceLineItems.filter(i => (i.invoiceId === undefined || i.invoiceId === null) && i.isDeleted === false), "SHIPPER", "STAFF");

            let shipperInvoice = {
                customerOrderNumber: load.customerOrderNumber,
                accountNumber: isStringNotEmpty(shipperAccount.irisId) ? shipperAccount.irisId : '',
                loadId: loadId,
                invoiceDate: moment(),
                invoiceNotes: isStringNotEmpty(data.invoiceNotes) ? data.invoiceNotes : null,
                fromEntityType: 'STAFF',
                fromEntityId: null,
                fromName: InvoiceUtils.irisCompanyName,
                fromAddress: InvoiceUtils.irisCompanyAddress,
                fromPointOfContact: InvoiceUtils.irisPointOfContact,
                remitName: data.remitName,
                remitAddress: data.remitAddress,
                remitPointOfContact: InvoiceUtils.irisPointOfContact,
                toEntityType: 'SHIPPER',
                toEntityId: shipperId,
                toName: shipperAccount.name,
                toAddress: shipperAccount.accountsPayableAddress,
                toPointOfContact: shipperAccount.accountsPayable,
                consignees: stops.filter(s => s.stopType === 'DROP_OFF').map((stop) => {
                    return {
                        name: LoadStopUtils.getLoadStopLocationName(stop, shipperId, null, 'STAFF'),
                        address: isObjectNotEmpty(stop.stopLocation) && isObjectNotEmpty(stop.stopLocation.address) ? stop.stopLocation.address : null,
                    };
                }),
                status: 'PENDING',
                paymentStatus: 'PENDING',
                paymentTerms: Number(data.paymentTerms),
                paymentDueDate: moment().add(Number(data.paymentTerms), 'days'),
                amountDue: totalAmount,
                amountDueUnit: 'USD',
                balanceDue: totalAmount,
                balanceDueUnit: 'USD',
                invoiceLineItemIds: invoiceLineItemList.map(i => i.id),
                invoiceType: 'SHIPPER_INVOICE'
            };

            dispatch(actionCreators.addInvoice(shipperInvoice, null, true));
        }
    };

    const onSubmitSendInvoice = async (data) => {
        if (isObjectNotEmpty(newInvoice) && isStringNotEmpty(newInvoice.id) && isListNotEmpty(employees) && isListNotEmpty(data.accountUserIds) && isObjectNotEmpty(load) && isStringNotEmpty(loadId) && isStringNotEmpty(userId)) {
            setIsSending(true);
            if (newInvoice.status !== 'COMPLETED') {
                // save the pdf document to s3
                let newDocument = await InvoiceUtils.saveInvoiceAsDocument('SHIPPER_INVOICE', newInvoice, newInvoice.invoiceLineItems, load, stops, false);

                let attachments = [];
                if (isListNotEmpty(selectedDocumentIds) && isListNotEmpty(documents)) {
                    selectedDocumentIds.forEach((documentId) => {
                        let doc = documents.find(d => d.id === documentId);
                        if (isObjectNotEmpty(doc)) {
                            attachments.push({
                                documentId: doc.id,
                                documentFileName: `${doc.documentDisplayName}${doc.fileExt}`,
                                documentFileType: doc.fileType
                            });
                        }
                    });
                }

                // send shipper invoice emails to recipients
                let emailsWereSent = false;
                let toEmails = [];
                for (let i = 0; i < data.accountUserIds.length; i++) {
                    const accountUserId = data.accountUserIds[i];
                    const accountUser = employees.find(a => a.id === accountUserId);
                    if (isObjectNotEmpty(accountUser)) {
                        toEmails.push({ email: accountUser.email, name: accountUser.firstName + ' ' + accountUser.lastName });
                    }
                }

                emailsWereSent = await InvoiceUtils.sendShipperInvoiceEmail('ap@irisfreight.com', toEmails, newInvoice, load, stops, newDocument, attachments);
                if (emailsWereSent === true) {
                    message.success('Email(s) have been sent.');
                    dispatch(actionCreators.updateInvoice(newInvoice.id, { status: 'SENT', sentAt: moment(), sentBy: userId }, newInvoice, true));
                } else {
                    message.error('Failed to send email(s)');
                }
            }
            setIsSending(false);
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.addInvoiceCancel());
        dispatch(actionCreators.updateInvoiceCancel());
        cancel();
    };

    const done = (invoiceId) => {
        setShowNewInvoice(true);
        dispatch(actionCreators.fetchInvoice(invoiceId));
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        dispatch(actionCreators.addInvoiceErrorClear());
        dispatch(actionCreators.updateInvoiceErrorClear());
        dispatch(actionCreators.getAccounts());
    }, []);

    useMemo(() => {
        if (isStringNotEmpty(shipperId)) {
            dispatch(actionCreators.getEmployees(shipperId, false));
        }
    }, [shipperId]);

    useMemo(() => {
        if (isStringNotEmpty(shipperId) && isListNotEmpty(accounts)) {
            let shipperAccountObj = accounts.find(i => i.id === load.shipperId);
            if (isObjectNotEmpty(shipperAccountObj)) {
                // console.log(shipperAccount);
                setShipperAccount({ ...shipperAccountObj });
            }
        }
    }, [accounts, shipperId]);

    useMemo(() => {
        if (isStringNotEmpty(loadId) && isBooleanTrue(showNewInvoice)) {
            let ids = [];
            ids.push(loadId);

            dispatch(actionCreators.fetchBulkDocumentLists(loadId, ids));
        }
    }, [loadId, showNewInvoice]);

    useMemo(() => {
        if (isLoadingAddInvoice !== null && isLoadingAddInvoice === false && errorAddInvoice === null && isStringNotEmpty(addInvoiceId)) {
            done(addInvoiceId);
        }
    }, [isLoadingAddInvoice, errorAddInvoice, addInvoiceId]);

    useMemo(() => {
        if (isLoadingUpdateInvoice !== null && isLoadingUpdateInvoice === false && errorUpdateInvoice === null) {
            onCancel();
        }
    }, [isLoadingUpdateInvoice, errorUpdateInvoice]);

    //#endregion
    //#region table displays

    const priceConfirmationColumns = [
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            textAlign: 'left',
        },
        {
            title: 'Units',
            dataIndex: 'quantity',
            key: 'quantity',
            textAlign: 'center',
            dataType: 'Decimal',
        },
        {
            title: 'Per',
            dataIndex: 'baseAmount',
            key: 'baseAmount',
            textAlign: 'center',
            dataType: 'Money',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
        },
        {
            title: 'Amount',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            textAlign: 'right',
            dataType: 'Money',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
        },
    ];

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    //#endregion

    if (showNewInvoice === true) {
        if (isObjectNotEmpty(newInvoice) && isStringNotEmpty(addInvoiceId) && newInvoice.id === addInvoiceId && isLoadingNewInvoice === false && errorNewInvoice === null) {
            return (
                <CanSee entityAction="READ" entityModel="LOAD_SHIPPER_INVOICE" entityObject={load}>
                    <Invoice invoiceId={newInvoice.id} invoice={newInvoice} load={load} loadId={loadId} stops={stops} />

                    <FormProvider {...methods2}>
                        <Form onSubmit={methods2.handleSubmit(onSubmitSendInvoice)}>
                            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoadingDocuments === true || isSending === true || (isLoadingUpdateInvoice === true && errorUpdateInvoice === null) || loading === true}>
                                <Fieldset legend="Would you like the send the invoice to the shipper you have assigned to this load?">
                                    <FormItem {...formItemLayout} label="Choose Employee(s) to send Invoice to" required
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Select
                                                placeholder="Please Search and Select Employee(s)"
                                                mode="multiple"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Employees for you to choose from." />}
                                                ref={ref}
                                            >
                                                {employees.map(d => <Option value={d.id} key={d.id}>{d.firstName + ' ' + d.lastName} - Title: {d.title} - Email: {d.email}</Option>)}
                                            </Select>
                                        )}
                                        rules={{ required: 'Required Field' }}
                                        name="accountUserIds"
                                    />
                                    <br />
                                    <b>Employee Doesn't Exist Yet?</b>
                                    <br />
                                    <Button type='primary' onClick={toggleNewEmployee}>Add New Employee to Shipper Account</Button>
                                </Fieldset>
                                <Fieldset legend="Do you want to attach any documents to the email?">
                                    <DataTable
                                        bordered={false}
                                        rowSelection={rowSelectionDocuments}
                                        dataSource={documents.filter(d => d.documentType !== 'CARRIER_INVOICE' && d.documentType !== 'SHIPPER_INVOICE' && d.documentType !== 'FACTORING_INVOICE' && d.documentType !== 'INVOICE')}
                                        columns={documentColumns}
                                        hidePaging={true}
                                        style={{ backgroundColor: '#ffffff' }}
                                        rowKey={record => record.id}
                                    />
                                </Fieldset>
                            </Spin>
                            {errorUpdateInvoice && <Alert message={`${errorUpdateInvoice}`} type="error" />}
                            <FormButtons cancel={onCancel} disabled={isSending === true || (isLoadingUpdateInvoice === true && errorUpdateInvoice === null)} submitText="Send the Invoice" />
                        </Form>
                    </FormProvider>
                    <Drawer
                        title={'Add New Employee'}
                        width={fullWidth > 720 ? fullWidth / 2 : 360}
                        onClose={toggleNewEmployee}
                        visible={showNewEmployee}
                        bodyStyle={{ paddingBottom: 80 }}
                        style={{ zIndex: 1000 }}
                        destroyOnClose={true}
                        closable={false}
                        maskClosable={false}
                    >
                        <NewEmployee cancel={toggleNewEmployee} accountId={shipperId} />
                    </Drawer>
                </CanSee>
            );
        } else if (isNotNullOrUndefined(errorNewInvoice)) {
            return <Alert message={`${errorNewInvoice}`} type="error" />;
        } else if (isLoadingNewInvoice === true && errorNewInvoice === null) {
            return <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoadingNewInvoice === true && errorNewInvoice === null} />
        } else {
            return null;
        }
    } else {
        return (
            <CanSee entityAction="CREATE" entityModel="LOAD_SHIPPER_INVOICE" entityObject={load}>
                <FormProvider {...methods}>
                    <Form onSubmit={methods.handleSubmit(onSubmitCreateInvoice)}>
                        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoadingAddInvoice === true && errorAddInvoice === null) || loading === true}>
                            <Fieldset legend="Review the charges below">
                                <InvoiceTable
                                    entityType="SHIPPER"
                                    isPriceConfirmation={true}
                                    data={invoiceLineItems.filter(i => (i.fromEntityType === 'SHIPPER' || i.toEntityType === 'SHIPPER') && (i.invoiceId === null || i.invoiceId === undefined) && i.status !== 'COMPLETED' && i.isDeleted === false)}
                                    columns={priceConfirmationColumns}
                                />
                            </Fieldset>
                            <Fieldset legend="Please specify the details below before generating the Shipper Invoice">
                                <FormItem {...formItemLayout} label="Payment Terms" required format="horizontal"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Select
                                            placeholder="Please Select the Payment Terms"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChange(selected); }}
                                            value={value}
                                            name={name}
                                            ref={ref}
                                        >
                                            <Option key='0' value='0'>NET 0</Option>
                                            <Option key='15' value='15'>NET 15</Option>
                                            <Option key='30' value='30'>NET 30</Option>
                                            <Option key='45' value='45'>NET 45</Option>
                                            <Option key='60' value='60'>NET 60</Option>
                                        </Select>
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="paymentTerms"
                                />
                                <FormItem {...formItemLayout} label="Preferred Payment Method" format="horizontal"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Select
                                            placeholder="Please Select the Preferred Payment Method"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChange(selected); }}
                                            value={value}
                                            name={name}
                                            ref={ref}
                                        >
                                            {preferredPaymentMethodOptions}
                                        </Select>
                                    )}
                                    rules={{ required: false }}
                                    name="paymentMethod"
                                />
                                <FormItemDisplay {...formItemLayout} label="Remit Address" format="horizontal">
                                    <FormItem {...formItemLayout} label="Remit Name" format="horizontal" required
                                        render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Remit Name" ref={ref} />}
                                        rules={{ required: "Required Field" }}
                                        name="remitName"
                                        defaultValue={InvoiceUtils.irisCompanyName}
                                    />
                                    <FormItemAddress
                                        format="horizontal"
                                        required={true}
                                        name="remitAddress"
                                        defaultValue={InvoiceUtils.irisCompanyAddress}
                                        isPostalAddress={true}
                                    />
                                </FormItemDisplay>
                                <FormItem {...formItemLayout} label="Notes" format="horizontal"
                                    render={({ onChange, onBlur, value, name, ref }) => <TextArea onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} autoSize={{ minRows: 4 }} placeholder="Notes" ref={ref} />}
                                    rules={{ required: false }}
                                    name="invoiceNotes"
                                />
                            </Fieldset>
                            {errorAddInvoice && <Alert message={`${errorAddInvoice}`} type="error" />}
                        </Spin>
                        <FormButtons containerStyle={{ position: 'relative' }} cancel={onCancel} disabled={isLoadingAddInvoice === true && errorAddInvoice === null} submitText="Generate Shipper Invoice" />
                    </Form>
                </FormProvider>
            </CanSee>
        );
    }
};

export default LoadNewShipperInvoice;