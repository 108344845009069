import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { Spin, Tabs, Row, Col, Drawer, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRoute } from '@fortawesome/free-solid-svg-icons';
import Header from '../../components/Header/Header';
import { useLocation } from 'react-router';
import ComplexDataLinkCard from '../../components/ComplexDataLinkCard/ComplexDataLinkCard';
import { isListNotEmpty, isNotNullOrUndefined, isNumberNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { CanDo, CanSee } from '../../shared/entitlements/entitlements';
import QueueLoads from '../../components/QueueLoads/QueueLoads';
import { EditOutlined } from '@ant-design/icons';
import EditPreferredLane from '../../components/EditPreferredLane/EditPreferredLane';
import LoadMap from '../../components/LoadMap/LoadMap';
import LaneUtils from '../../api/utils/laneUtils';

const { TabPane } = Tabs;

const PreferredLane = props => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    //#endregion
    //#region props

    const id = props.match.params.id;
    const previousBreadcrumbs = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isListNotEmpty(props.history.location.state.previousBreadcrumbs) ? props.history.location.state.previousBreadcrumbs : [];
    const previousLocation = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageLocation) ? props.history.location.state.previousPageLocation : { pathname: `/preferredLanes` };
    const previousPath = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageLocation) ? props.history.location.state.previousPageLocation : `/preferredLanes`;
    const previousBreadcrumbName = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageTitle) ? props.history.location.state.previousPageTitle : 'Preferred Lanes';

    //#endregion
    //#region useLocation

    const location = useLocation();

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.preferredLanes.isRecordLoading);
    const error = useSelector(state => state.preferredLanes.recordError);
    const preferredLane = useSelector(state => state.preferredLanes.record);

    //#endregion
    //#region useStates

    const [tabKey, setTabKey] = useState('snapshot');
    const [showEditEntity, setShowEditEntity] = useState(false);

    //#endregion
    //#region toggles

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    //#endregion
    //#region tab methods

    const onTabChange = (key) => {
        props.history.replace({ ...props.location, hash: `#${key}` });
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        if (isStringNotEmpty(location.hash)) {
            setTabKey(location.hash.replace('#', ''));
        } else {
            setTabKey('snapshot');
            props.history.replace({ ...location, hash: `#snapshot` });
        }
    }, [location]);

    useMemo(() => {
        dispatch(actionCreators.fetchPreferredLane(id));
    }, [id]);

    //#endregion
    //#region displays

    let breadcrumbs = [...previousBreadcrumbs,
    {
        location: previousLocation,
        path: previousPath,
        breadcrumbName: previousBreadcrumbName,
    },
    {
        path: `/preferredLanes/${isStringNotEmpty(id) ? id : ''}`,
        breadcrumbName: `${isObjectNotEmpty(preferredLane) && isStringNotEmpty(preferredLane.customerLaneId) ? preferredLane.customerLaneId : (isObjectNotEmpty(preferredLane) && isStringNotEmpty(preferredLane.irisId) ? preferredLane.irisId : '')}`,
    },
    ];

    const getStopDetails = (stopType, city, state) => {
        const stop = {
            stopType: stopType
        };
        const stopIcon = LaneUtils.getLaneStopMarkerIcon(stop);

        return (
            <div>
                <Row style={{ marginTop: 36, marginBottom: 36 }}>
                    <Col span={24}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: 30, marginRight: 12 }}>
                                {isNotNullOrUndefined(stopIcon) ? (<img src={stopIcon} alt={LaneUtils.getLaneStopTypeDisplay(stop)} style={{ width: 30, height: 30 }} />) : null}
                            </div>
                            <div style={{ flex: 1 }}>
                                <span>{city}, {state}</span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    };

    const editEntityComponents = (
        <Drawer
            title={"Edit Lane"}
            onClose={toggleEditEntity}
            visible={showEditEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditPreferredLane
                cancel={toggleEditEntity}
                preferredLane={preferredLane}
            />
        </Drawer>
    );

    //#endregion

    if (isObjectNotEmpty(preferredLane) && isObjectNotEmpty(preferredLane.lane) && preferredLane.id === id) {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                <Header
                    title={isObjectNotEmpty(preferredLane) && isStringNotEmpty(preferredLane.customerLaneId) ? preferredLane.customerLaneId : (isObjectNotEmpty(preferredLane.lane) && isStringNotEmpty(preferredLane.lane.irisId) ? preferredLane.lane.irisId : 'Loading...')}
                    subtitle={isObjectNotEmpty(preferredLane) && isObjectNotEmpty(preferredLane.trailerType) && isStringNotEmpty(preferredLane.trailerType.description) ? 'Trailer Type: ' + preferredLane.trailerType.description : null}
                    icon={<FontAwesomeIcon className="anticon" icon={faRoute} />}
                    breadcrumbs={breadcrumbs}
                />
                <Tabs activeKey={tabKey} onChange={onTabChange} destroyInactiveTabPane={true} tabBarStyle={{ backgroundColor: '#ffffff', paddingRight: 24, paddingLeft: 24, margin: 0 }}>
                    <TabPane tab="Snapshot" key="snapshot">
                        <div style={{ padding: 24 }}>
                            <Row gutter={[16, 16]}>
                                <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                    <ComplexDataLinkCard
                                        title="Lane Details"
                                        headStyle={{ fontSize: 14 }}
                                        data={{
                                            "Lane ID": { value: isObjectNotEmpty(preferredLane) && isObjectNotEmpty(preferredLane.lane) && isStringNotEmpty(preferredLane.lane.irisId) ? preferredLane.lane.irisId : null, dataType: 'String' },
                                            "Customer Lane ID": { value: isObjectNotEmpty(preferredLane) && isStringNotEmpty(preferredLane.customerLaneId) ? preferredLane.customerLaneId : null, dataType: 'String' },
                                            "Approx Distance": { value: isObjectNotEmpty(preferredLane) && isObjectNotEmpty(preferredLane.lane) && isNumberNotEmpty(preferredLane.lane.approxDistance) ? preferredLane.lane.approxDistance : null, dataType: 'Distance', units: isObjectNotEmpty(preferredLane) && isObjectNotEmpty(preferredLane.lane) && isStringNotEmpty(preferredLane.lane.approxDistanceUnit) ? preferredLane.lane.approxDistanceUnit : null },
                                            "Customer Rate": { value: isObjectNotEmpty(preferredLane) && isObjectNotEmpty(preferredLane.lane) && isNumberNotEmpty(preferredLane.lane.rate) && CanDo({ staffOnly: true }) ? preferredLane.lane.rate : null, dataType: 'Money', units: isObjectNotEmpty(preferredLane) && isObjectNotEmpty(preferredLane.lane) && isStringNotEmpty(preferredLane.lane.rateUnit) ? preferredLane.lane.rateUnit : null },
                                            "Customer Rate Last Updated At": { value: isObjectNotEmpty(preferredLane) && isObjectNotEmpty(preferredLane.lane) && isNumberNotEmpty(preferredLane.lane.rate) && isNotNullOrUndefined(preferredLane.lane.rateLastUpdatedAt) && CanDo({ staffOnly: true }) ? preferredLane.lane.rateLastUpdatedAt : null, dataType: 'MomentDateTime' },
                                            "Carrier Rate": { value: isObjectNotEmpty(preferredLane) && isNumberNotEmpty(preferredLane.rate) ? preferredLane.rate : null, dataType: 'Money', units: isObjectNotEmpty(preferredLane) && isStringNotEmpty(preferredLane.rateUnit) ? preferredLane.rateUnit : null },
                                            "Carrier Rate Start Date": { value: isObjectNotEmpty(preferredLane) && isNumberNotEmpty(preferredLane.rate) && isNotNullOrUndefined(preferredLane.rateStartDateTime) ? preferredLane.rateStartDateTime : null, dataType: 'MomentDateTime' },
                                            "Carrier Rate End Date": { value: isObjectNotEmpty(preferredLane) && isNumberNotEmpty(preferredLane.rate) && isNotNullOrUndefined(preferredLane.rateEndDateTime) ? preferredLane.rateEndDateTime : null, dataType: 'MomentDateTime' },
                                            "Capacity Per Week": { value: isObjectNotEmpty(preferredLane) && isNumberNotEmpty(preferredLane.capacityPerWeek) ? preferredLane.capacityPerWeek : null, dataType: 'Integer' },
                                            "Max Load Weight": { value: isObjectNotEmpty(preferredLane) && isNumberNotEmpty(preferredLane.maxLoadWeight) ? preferredLane.maxLoadWeight : null, dataType: 'Weight', units: isObjectNotEmpty(preferredLane) && isStringNotEmpty(preferredLane.maxLoadWeightUnit) ? preferredLane.maxLoadWeightUnit : null }
                                        }}
                                        extra={<Button type="primary" icon={<EditOutlined />} shape='circle' onClick={toggleEditEntity} />}
                                    />
                                </Col>
                                <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                    <ComplexDataLinkCard
                                        title="Origin and Destination"
                                        headStyle={{ fontSize: 14 }}
                                        style={{ height: 'fit-content' }}
                                        children={
                                            <Row gutter={[24, 24]}>
                                                <Col span={12}>
                                                    {getStopDetails('PICK_UP', preferredLane.originCity, preferredLane.originState)}
                                                </Col>
                                                <Col span={12}>
                                                    {getStopDetails('DROP_OFF', preferredLane.destinationCity, preferredLane.destinationState)}
                                                </Col>
                                                <Col span={24}>
                                                    <LoadMap
                                                        loadId={isObjectNotEmpty(preferredLane) ? preferredLane.id : null}
                                                        stops={isObjectNotEmpty(preferredLane) && isObjectNotEmpty(preferredLane.lane) && isNumberNotEmpty(preferredLane.lane.originLongitude) && isNumberNotEmpty(preferredLane.lane.originLatitude) && isNumberNotEmpty(preferredLane.lane.destinationLongitude) && isNumberNotEmpty(preferredLane.lane.destinationLatitude) ? [
                                                            {
                                                                longitude: preferredLane.lane.originLongitude,
                                                                latitude: preferredLane.lane.originLatitude,
                                                                stopType: 'PICK_UP',
                                                                id: 0,
                                                                loadId: preferredLane.id
                                                            },
                                                            {
                                                                longitude: preferredLane.lane.destinationLongitude,
                                                                latitude: preferredLane.lane.destinationLatitude,
                                                                stopType: 'DROP_OFF',
                                                                id: 1,
                                                                loadId: preferredLane.id
                                                            }
                                                        ] : []}
                                                        containerName={isObjectNotEmpty(preferredLane) ? `map-${preferredLane.id}` : 'map'}
                                                        height="400px"
                                                    />
                                                </Col>
                                            </Row>
                                        }
                                    />
                                </Col>
                            </Row>
                        </div>
                    </TabPane>
                    <TabPane tab="Load Requests" key="loadRequests">
                        <CanSee staffOnly={true}>
                            <div style={{ padding: 24 }}>
                                {isObjectNotEmpty(preferredLane) ? (
                                    <QueueLoads
                                        title="Load Requests"
                                        filter={`LOAD_REQUEST_LANE_${preferredLane.laneId}_CARRIER_${preferredLane.accountId}`}
                                        filterSearchParams={{
                                            sort: 'pickUpDateTime',
                                            order: 'asc',
                                            isDeleted: false,
                                            // eta: true,
                                            'laneId': preferredLane.laneId
                                        }}
                                        defaultLoadStatusFilter={['PENDING_RATE_CONFIRMATION']}
                                        showTable={true}
                                        showActiveLoadData={true}
                                        carrierId={preferredLane.accountId}
                                    />
                                ) : null}
                            </div>
                        </CanSee>
                    </TabPane>
                    <TabPane tab="Open Loads" key="openLoads">
                        <CanSee staffOnly={true}>
                            <div style={{ padding: 24 }}>
                                {isObjectNotEmpty(preferredLane) ? (
                                    <QueueLoads
                                        title="Open Loads"
                                        filter={`OPEN_LANE_${preferredLane.laneId}_CARRIER_${preferredLane.accountId}`}
                                        filterSearchParams={{
                                            sort: 'pickUpDateTime',
                                            order: 'asc',
                                            isDeleted: false,
                                            // eta: true,
                                            'laneId': preferredLane.laneId
                                        }}
                                        defaultLoadStatusFilter={['BOOKED']}
                                        showTable={true}
                                        showActiveLoadData={true}
                                        carrierId={preferredLane.accountId}
                                    />
                                ) : null}
                            </div>
                        </CanSee>
                    </TabPane>
                    <TabPane tab="Booked Loads" key="bookedLoads">
                        <CanSee staffOnly={true}>
                            <div style={{ padding: 24 }}>
                                {isObjectNotEmpty(preferredLane) ? (
                                    <QueueLoads
                                        title="Booked Loads"
                                        filter={`BOOKED_LANE_${preferredLane.laneId}_CARRIER_${preferredLane.accountId}`}
                                        filterSearchParams={{
                                            sort: 'pickUpDateTime',
                                            order: 'asc',
                                            isDeleted: false,
                                            // eta: true,
                                            'laneId': preferredLane.laneId
                                        }}
                                        defaultLoadStatusFilter={['SCHEDULED']}
                                        showTable={true}
                                        showActiveLoadData={true}
                                        carrierId={preferredLane.accountId}
                                    />
                                ) : null}
                            </div>
                        </CanSee>
                    </TabPane>
                    <TabPane tab="In Transit Loads" key="intransitLoads">
                        <CanSee staffOnly={true}>
                            <div style={{ padding: 24 }}>
                                {isObjectNotEmpty(preferredLane) ? (
                                    <QueueLoads
                                        title="In Transit Loads"
                                        filter={`IN_TRANSIT_LANE_${preferredLane.laneId}_CARRIER_${preferredLane.accountId}`}
                                        filterSearchParams={{
                                            sort: 'pickUpDateTime',
                                            order: 'asc',
                                            isDeleted: false,
                                            // eta: true,
                                            'laneId': preferredLane.laneId
                                        }}
                                        defaultLoadStatusFilter={['IN_TRANSIT', 'AT_STOP']}
                                        showTable={true}
                                        showActiveLoadData={true}
                                        carrierId={preferredLane.accountId}
                                    />
                                ) : null}
                            </div>
                        </CanSee>
                    </TabPane>
                    <TabPane tab="Completed Loads" key="completedLoads">
                        <CanSee staffOnly={true}>
                            <div style={{ padding: 24 }}>
                                {isObjectNotEmpty(preferredLane) ? (
                                    <QueueLoads
                                        title="Completed Loads"
                                        filter={`COMPLETED_LANE_${preferredLane.laneId}_CARRIER_${preferredLane.accountId}`}
                                        filterSearchParams={{
                                            sort: 'loadEndDateTime',
                                            order: 'desc',
                                            isDeleted: false,
                                            eta: false,
                                            'laneId': preferredLane.laneId
                                        }}
                                        defaultLoadStatusFilter={['COMPLETED', 'REOPENED', 'CLOSED']}
                                        showTable={true}
                                        carrierId={preferredLane.accountId}
                                    />
                                ) : null}
                            </div>
                        </CanSee>
                    </TabPane>
                </Tabs>
                {editEntityComponents}
            </Spin>
        );
    } else {
        return <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={true} />;
    }
};

export default withRouter(PreferredLane);