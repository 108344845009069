import React, { useState, useMemo, useEffect } from 'react';
import { Input, Select, Alert, Spin, Divider, InputNumber } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Enums from '../../shared/enums';
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import { isListNotEmpty, isNumberNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';

const { Option, OptGroup } = Select;

const NewInvoiceLineItem = ({ load, stops, shipperId, carrierId, fromEntityType, toEntityType, cancel, loading = false }) => {
    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    const dispatch = useDispatch();
    const lineItemTypes = useSelector(state => state.orchestrator.lineItemTypes);
    const isLoading = useSelector(state => state.invoiceLineItems.isRecordAddLoading);
    const error = useSelector(state => state.invoiceLineItems.addRecordError);
    const entityType = useSelector(state => state.auth.entityType);

    const invoiceQuantityUnitOptions = Enums.InvoiceQuantityUnits.selectListOptions();
    const accessorialOptions = Enums.Accessorials.selectListOptions();
    const irisFeeOptions = entityType === "STAFF" ? Enums.IRISFees.selectListOptions() : [];
    const loadFeeOptions = Enums.LoadFees.selectListOptions();
    const adjustmentOptions = entityType === "STAFF" ? Enums.Adjustments.selectListOptions() : Enums.Adjustments.selectListOptionsWithExclusions(["BROKERAGE_FEE_ADJUSTMENT", "CARRIER_BROKERAGE_FEE_ADJUSTMENT"]);
    const invoiceAccountTypeOptions = entityType === "STAFF" ? Enums.InvoiceAccountTypes.selectListOptions() : Enums.InvoiceAccountTypes.selectListOptionsWithExclusions(["STAFF","FACTORING"]);

    const [lineItemTypeId, setLineItemTypeId] = useState(null);
    const [stopId, setStopId] = useState(null);
    const [showStops, setShowStops] = useState(false);

    const onSubmit = (data) => {
        // console.log(data);
        // const cleanedData = removeEmpty(data);
        if (isObjectNotEmpty(data)) {
            let newInvoiceLineItems = [];
            if (isStringNotEmpty(shipperId)) {
                let baseAmount = data.baseAmount;
                let quantity = data.quantity;
                let quantityUnit = data.quantityUnit;
                let totalAmount = data.totalAmount;

                let newInvoiceLineItem = {
                    description: data.description,
                    loadId: load.id,
                    itemType: data.itemType,
                    isIrisFee: Enums.IRISFees.doesNameExist(data.itemType) || data.itemType === "BROKERAGE_FEE_ADJUSTMENT" || data.itemType === "FEE_ADJUSTMENT",
                    baseAmount: Math.abs(baseAmount),
                    baseAmountUnit: 'USD',
                    quantity: quantity,
                    quantityUnit: quantityUnit,
                    totalAmount: Math.abs(totalAmount),
                    totalAmountUnit: 'USD',
                    status: 'PENDING',
                    approvalStatus: 'PENDING',
                    isOpen: true,
                    isDeleted: false,
                };

                if (isStringNotEmpty(stopId)) {
                    newInvoiceLineItem.stopId = stopId;
                }

                if (entityType === 'STAFF') {
                    newInvoiceLineItem.fromEntityType = data.fromEntityType;
                    newInvoiceLineItem.toEntityType = data.toEntityType;
                    if (data.fromEntityType === 'SHIPPER' && isStringNotEmpty(shipperId)) {
                        newInvoiceLineItem.fromEntityId = shipperId;
                    } else if (data.fromEntityType === 'CARRIER' && isStringNotEmpty(carrierId)) {
                        newInvoiceLineItem.fromEntityId = carrierId;
                    }

                    if (data.toEntityType === 'SHIPPER' && isStringNotEmpty(shipperId)) {
                        newInvoiceLineItem.toEntityId = shipperId;
                    } else if (data.toEntityType === 'CARRIER' && isStringNotEmpty(carrierId)) {
                        newInvoiceLineItem.toEntityId = carrierId;
                    }
                } else if (load.serviceType === 'TMS') {
                    newInvoiceLineItem.fromEntityType = 'SHIPPER';
                    newInvoiceLineItem.toEntityType = 'CARRIER';
                    if (isStringNotEmpty(shipperId)) {
                        newInvoiceLineItem.fromEntityId = shipperId;
                    }

                    if (isStringNotEmpty(carrierId)) {
                        newInvoiceLineItem.toEntityId = carrierId;
                    }
                }

                newInvoiceLineItems.push(newInvoiceLineItem);

                dispatch(actionCreators.addInvoiceLineItems(newInvoiceLineItems, load.id, true));
            }
        }
    };

    const onChangeLineItemType = (value) => {
        methods.setValue("description", '');
        methods.setValue('locationId', '');
        methods.setValue('stopId', null);
        methods.setValue("baseAmount", '');
        methods.setValue("quantityUnit", null);
        methods.setValue("quantity", '');
        methods.setValue("totalAmount", '');

        if (isStringNotEmpty(value) && isListNotEmpty(lineItemTypes)) {
            // console.log(value);
            // console.log(Enums.LineItemNames.getValueByName(value));

            let lineItemType = lineItemTypes.find(j => j.name === value);
            // console.log(lineItemType);
            if (isObjectNotEmpty(lineItemType)) {
                setLineItemTypeId(lineItemType.id);
                if (lineItemType.name === "LUMPER_FEE" || lineItemType.name === "LUMPER_FEE_ACCESSORIAL" || lineItemType.name === "DRIVER_ASSIST" || lineItemType.name === "DRIVER_ASSIST_ACCESSORIAL" || lineItemType.name === "DETENTION" || lineItemType.name === "LAYOVER") {
                    setShowStops(true);
                } else {
                    setShowStops(false);
                    methods.setValue("description", lineItemType.description);
                }
            } else {
                setLineItemTypeId(null);
                setShowStops(false);
                methods.setValue("description", Enums.LineItemNames.getValueByName(value));
            }
        } else {
            setLineItemTypeId(null);
            setShowStops(false);
            methods.setValue("description", '');
        }
    };

    const onChangeStopId = (value) => {
        setStopId(value);
    };

    useEffect(() => {
        if (isStringNotEmpty(stopId) && isListNotEmpty(stops) && isListNotEmpty(lineItemTypes)) {
            let stop = stops.find(i => i.id === stopId);
            if (isObjectNotEmpty(stop)) {
                methods.setValue("locationId", stop.stopLocationId);
                let lineItemType = lineItemTypes.find(j => j.id === lineItemTypeId);
                if (isObjectNotEmpty(lineItemType)) {
                    let descriptionValue = lineItemType.description + ' for ' + stop.stopLocation.name;
                    methods.setValue("description", descriptionValue);
                }
            }
        }
    }, [stopId]);

    useEffect(() => {
        if (isStringNotEmpty(lineItemTypeId) && isListNotEmpty(lineItemTypes) && isListNotEmpty(stops)) {
            let lineItemType = lineItemTypes.find(j => j.id === lineItemTypeId);
            if (isObjectNotEmpty(lineItemType)) {
                // if (lineItemType.upChargeTo !== undefined && lineItemType.upChargeTo !== null) {
                //     methods.setValue("fromEntityType", lineItemType.upChargeTo);
                // }

                if (isStringNotEmpty(lineItemType.upChargeRule)) {
                    if (isNumberNotEmpty(lineItemType.upCharge)) {
                        methods.setValue("baseAmount", lineItemType.upCharge.toFixed(2));
                    } else {
                        methods.setValue("baseAmount", '');
                    }
                    methods.setValue("quantityUnit", lineItemType.upChargeRule);

                    if (lineItemType.name === "LUMPER_FEE" || lineItemType.name === "LUMPER_FEE_ACCESSORIAL") {
                        if (isStringNotEmpty(stopId)) {
                            let stop = stops.find(i => i.id === stopId);
                            if (isObjectNotEmpty(stop)) {
                                if (isNumberNotEmpty(stop.stopLocation.lumperFee)) {
                                    methods.setValue("baseAmount", stop.stopLocation.lumperFee.toFixed(2));
                                    methods.setValue("quantity", 1);
                                    methods.setValue("totalAmount", stop.stopLocation.lumperFee.toFixed(2));
                                } else {
                                    methods.setValue("baseAmount", '');
                                    methods.setValue("quantity", 1);
                                    methods.setValue("totalAmount", 0);
                                }
                            }
                        }
                    } else if (lineItemType.upChargeRule === "PER_LOAD" || lineItemType.upChargeRule === "PER_STOP" || lineItemType.upChargeRule === "ADJUSTMENT" || lineItemType.upChargeRule === "FLAT") {
                        methods.setValue("quantity", 1);

                        if (isNumberNotEmpty(lineItemType.upCharge)) {
                            methods.setValue("totalAmount", lineItemType.upCharge.toFixed(2));
                        } else {
                            methods.setValue("totalAmount", 0);
                        }

                    } else if (lineItemType.upChargeRule === "PER_MILE") {
                        methods.setValue("quantity", load.practicalDistance);

                        if (isNumberNotEmpty(lineItemType.upCharge)) {
                            methods.setValue("totalAmount", (lineItemType.upCharge * load.practicalDistance).toFixed(2));
                        } else {
                            methods.setValue("totalAmount", 0);
                        }
                    }
                }
            }
        }
    }, [stopId, lineItemTypeId]);

    // useEffect(() => {
    //     if (lineItemTypeId !== undefined && lineItemTypeId !== null) {
    //         let lineItemType = lineItemTypes.find(j => j.id === lineItemTypeId);
    //         if (lineItemType !== null && lineItemType !== undefined) {
    //             if (lineItemType.costTo !== undefined && lineItemType.costTo !== null) {
    //                 methods.setValue("toEntityType", lineItemType.costTo);
    //             }

    //             if (lineItemType.isOpen === false || lineItemType.isOpen === null || lineItemType.isOpen === undefined) {
    //                 if (lineItemType.costRule !== undefined && lineItemType.costRule !== null && lineItemType.cost !== null && lineItemType.cost !== undefined) {
    //                     methods.setValue("baseAmount", (lineItemType.cost).toFixed(2));
    //                     methods.setValue("quantityUnit", lineItemType.costRule);

    //                     if (lineItemType.costRule === "PER_LOAD" || lineItemType.costRule === "PER_STOP" || lineItemType.costRule === "ADJUSTMENT" || lineItemType.costRule === "FLAT") {
    //                         methods.setValue("quantity", 1);
    //                         methods.setValue("totalAmount", (lineItemType.cost).toFixed(2));
    //                     } else if (lineItemType.costRule === "PER_MILE") {
    //                         methods.setValue("quantity", load.practicalDistance);
    //                         methods.setValue("totalAmount", ((lineItemType.cost) * load.practicalDistance).toFixed(2));
    //                     }
    //                 }
    //             } else {
    //                 // lineItemType isOpen === true
    //                 if (lineItemType.costRule !== undefined && lineItemType.costRule !== null) {
    //                     methods.setValue("baseAmount", '');
    //                     methods.setValue("quantityUnit", lineItemType.costRule);

    //                     if (lineItemType.name === "LUMPER_FEE" || lineItemType.name === "LUMPER_FEE_ACCESSORIAL") {
    //                         if (stopId !== null && stopId !== undefined) {
    //                             let stop = stops.find(i => i.id === stopId);
    //                             if (stop !== null && stop !== undefined) {
    //                                 if (stop.stopLocation.lumperFee !== null && stop.stopLocation.lumperFee !== undefined) {
    //                                     methods.setValue("baseAmount", (stop.stopLocation.lumperFee).toFixed(2));
    //                                     methods.setValue("quantity", 1);
    //                                     methods.setValue("totalAmount", (stop.stopLocation.lumperFee).toFixed(2));
    //                                 } else {
    //                                     methods.setValue("quantity", 1);
    //                                 }
    //                             }
    //                         }
    //                     } else if (lineItemType.costRule === "PER_LOAD" || lineItemType.costRule === "PER_STOP" || lineItemType.costRule === "ADJUSTMENT" || lineItemType.costRule === "FLAT") {
    //                         methods.setValue("quantity", 1);
    //                         methods.setValue("totalAmount", 0);
    //                     } else if (lineItemType.costRule === "PER_MILE") {
    //                         methods.setValue("quantity", load.practicalDistance);
    //                         methods.setValue("totalAmount", 0);
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }, [stopId, lineItemTypeId]);

    const baseAmountValue = methods.watch("baseAmount");
    const quantityValue = methods.watch("quantity");

    useEffect(() => {
        if (!isNaN(baseAmountValue) && !isNaN(quantityValue)) {
            methods.setValue("totalAmount", (baseAmountValue * quantityValue).toFixed(2));
        } else {
            methods.setValue("totalAmount", '');
        }
    }, [baseAmountValue, quantityValue]);

    const onCancel = () => {
        dispatch(actionCreators.addInvoiceLineItemCancel());
        cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.addInvoiceLineItemErrorClear());
        
        dispatch(actionCreators.getLineItemTypes());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const formItemLayoutDouble2 = {
        labelCol1: { span: 6 },
        wrapperCol1: { span: 6 },
        labelCol2: { span: 6 },
        wrapperCol2: { span: 6 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true && error === null) || loading === true}>
                    <Fieldset legend="New Invoice Line Item">
                        <FormItem {...formItemLayout} label="Line Item Type" required format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select a Line Item Type"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChangeLineItemType(selected); onChange(selected); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                >
                                    <OptGroup label="Accessorials">
                                        {accessorialOptions}
                                    </OptGroup>
                                    {/* <OptGroup label="Stop Fees">
                                        {stopFeeOptions}
                                    </OptGroup> */}
                                    {/* <OptGroup label="Load Requirements">
                                        {loadRequirementOptions}
                                    </OptGroup> */}
                                    {/* <OptGroup label="Equipment Specifics">
                                        {equipmentSpecificOptions}
                                    </OptGroup> */}
                                    <OptGroup label="Load Fees">
                                        {loadFeeOptions}
                                    </OptGroup>
                                    {entityType === 'STAFF' ? (
                                        <OptGroup label="IRIS Fees">
                                            {irisFeeOptions}
                                        </OptGroup>
                                    ) : null}
                                    <OptGroup label="Adjustments">
                                        {adjustmentOptions}
                                    </OptGroup>
                                </Select>
                            )}
                            rules={{ required: 'Required Field' }}
                            name="itemType"
                        />
                        {(showStops === true) ? (
                            <>
                                <FormItem {...formItemLayout} label="Stop" required format="horizontal"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Select
                                            placeholder="Please Select a Stop"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChangeStopId(selected); onChange(selected); }}
                                            value={value}
                                            name={name}
                                            ref={ref}
                                        >
                                            {stops.filter(i => i.stopType === 'PICK_UP' || i.stopType === 'DROP_OFF').map(i => <Option value={i.id} key={i.id}>{"Stop # " + (i.sequence + 1) + ", " + i.stopLocation.name}</Option>)}
                                        </Select>
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="stopId"
                                />
                                <input type="hidden" name="locationId" />
                            </>
                        ) : null}
                        <FormItem {...formItemLayout} label="Description" required format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Description" ref={ref} />}
                            rules={{ required: 'Required Field' }}
                            name="description"
                        />
                        {(entityType === 'STAFF') ? (
                            <>
                                <Divider />
                                <FormItemDouble {...formItemLayoutDouble2} label1="Payment From" label2="To" required1 required2 format="horizontal"
                                    render1={({ onChange, onBlur, value, name, ref }) => (
                                        <Select
                                            placeholder="Please Select"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChange(selected); }}
                                            value={value}
                                            name={name}
                                            ref={ref}
                                        >
                                            {invoiceAccountTypeOptions}
                                        </Select>
                                    )}
                                    rules1={{ required: 'Required Field' }}
                                    name1="fromEntityType"
                                    defaultValue1={fromEntityType}
                                    render2={({ onChange, onBlur, value, name, ref }) => (
                                        <Select
                                            placeholder="Please Select"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChange(selected); }}
                                            value={value}
                                            name={name}
                                            ref={ref}
                                        >
                                            {invoiceAccountTypeOptions}
                                        </Select>
                                    )}
                                    rules2={{ required: 'Required Field' }}
                                    name2="toEntityType"
                                    defaultValue2={toEntityType}
                                />
                            </>
                        ) : null}
                        <FormItem {...formItemLayout} label="Base Amount" required format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <InputNumber
                                    precision={2}
                                    min={0}
                                    placeholder="Base Amount"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            rules={{ required: 'Required Field' }}
                            name="baseAmount"
                        />
                        <FormItemDouble {...formItemLayoutDouble2} label1="Quantity" label2="Units" required1 required2 format="horizontal"
                            render1={({ onChange, onBlur, value, name, ref }) => (
                                <InputNumber
                                    precision={2}
                                    min={0}
                                    placeholder="Quantity"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                            rules1={{ required: 'Required Field' }}
                            name1="quantity"
                            render2={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                >
                                    {invoiceQuantityUnitOptions}
                                </Select>
                            )}
                            rules2={{ required: 'Required Field' }}
                            name2="quantityUnit"
                        />
                        <FormItem {...formItemLayout} label="Total Amount" required format="horizontal"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <InputNumber
                                    precision={2}
                                    min={0}
                                    placeholder="Total Amount"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                    disabled={true}
                                    ref={ref}
                                />
                            )}
                            rules={{ required: 'Required Field' }}
                            name="totalAmount"
                        />
                    </Fieldset>
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Create Invoice Line Item" />
            </Form>
        </FormProvider>
    );
};

export default NewInvoiceLineItem;