import React, { useMemo } from 'react';
import { Alert, Spin } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import FormItemDisplay from '../FormItemDisplay/FormItemDisplay';

const EditInvoiceLineItemStatusCancelled = ({ load, invoiceLineItem, cancel }) => {
    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.invoiceLineItems.isRecordUpdateLoading);
    const error = useSelector(state => state.invoiceLineItems.updateRecordError);

    const onSubmit = () => {
        dispatch(actionCreators.updateInvoiceLineItem(invoiceLineItem.id, { status: 'CANCELLED' }, load.id, true));
    };

    const onCancel = () => {
        dispatch(actionCreators.updateInvoiceLineItemCancel());
        cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateInvoiceLineItemErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Fieldset legend="Cancel Invoice Line Item">
                        <FormItemDisplay {...formItemLayout} label="Cancel Invoice Line Item">
                            <span>Are you sure you want to Cancel this Invoice Line Item? This will make it so the Invoice Line Item still shows up on the Rate Confirmations and Invoices but will not count towards any totals or balances.</span>
                        </FormItemDisplay>
                    </Fieldset>
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Update Invoice Line Item" />
            </Form>
        </FormProvider>
    );
};

export default EditInvoiceLineItemStatusCancelled;