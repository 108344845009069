import React from "react";
import { Col, Row, Alert } from "antd";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { isBooleanTrue, isNotNullOrUndefined, isStringNotEmpty } from "../../shared/objectUtils";

const FormItemFile = ({ label, labelCol = {}, wrapperCol = {}, format = 'vertical', required = false, noColon = false, name, children }) => {
    const methods = useFormContext();

    const getError = (errors, name) => {
        if (name !== undefined) {
            let obj = errors;

            name = name.split('.');
            let len = name.length;
            for (let i = 0; i < len - 1; i++) {
                if (obj[name[i]] !== undefined) {
                    //console.log(obj[name[i]]);
                    obj = obj[name[i]];
                }
            }

            return obj[name[len - 1]];
        } else {
            return undefined;
        }
    };

    if (format === 'vertical') {
        return (
            <>
                {isStringNotEmpty(label) ? (
                    <Row gutter={[8, 8]} style={{ lineHeight: 2.0, marginBottom: 8 }}>
                        <Col span={24}>{isBooleanTrue(required) ? '* ' : ''}{label}{isBooleanTrue(noColon) ? '' : ':'}</Col>
                    </Row>
                ) : null}
                <Row gutter={[8, 8]} style={{ marginBottom: 8 }}>
                    <Col span={24}>
                        {children}
                    </Col>
                </Row>
                {getError(methods.errors, name) !== undefined ? (
                    <ErrorMessage
                        errors={methods.errors}
                        name={name}
                        render={({ message, messages }) => {
                            if (isNotNullOrUndefined(messages)) {
                                return Object.entries(messages).map(([type, message]) => (<Alert key={`${name}-${type}`} message={message} type="error" style={{ marginBottom: 8 }} />));
                            } else if (isStringNotEmpty(message)) {
                                return <Alert message={message} type="error" style={{ marginBottom: 8 }} />;
                            } else {
                                return null;
                            }
                        }}
                    />
                ) : null}
            </>
        );
    } else if (format === 'horizontal') {
        return (
            <>
                <Row gutter={[8, 8]} style={{ marginBottom: 8 }}>
                    {isStringNotEmpty(label) ? (
                        <Col {...labelCol} style={{ textAlign: 'right' }}>{isBooleanTrue(required) ? '* ' : ''}{label}{isBooleanTrue(noColon) ? '' : ':'}</Col>
                    ) : null}
                    <Col {...wrapperCol}>
                        {children}
                    </Col>
                </Row>
                {getError(methods.errors, name) !== undefined ? (
                    <ErrorMessage
                        errors={methods.errors}
                        name={name}
                        render={({ message, messages }) => {
                            if (isNotNullOrUndefined(messages)) {
                                return Object.entries(messages).map(([type, message]) => (<Alert key={`${name}-${type}`} message={message} type="error" style={{ marginBottom: 8 }} />));
                            } else if (isStringNotEmpty(message)) {
                                return <Alert message={message} type="error" style={{ marginBottom: 8 }} />;
                            } else {
                                return null;
                            }
                        }}
                    />
                ) : null}
            </>
        );
    } else {
        return null;
    }
};

export default FormItemFile;