import React, { useMemo, useState } from 'react';
import { Alert, Button, Card, Col, Divider, Drawer, Dropdown, Menu, Modal, Row, Space, Spin, Tooltip, Typography } from "antd";
import classes from './LoadCompletionCard.module.scss';
import EditLoadCompletionChecklist from "../../components/EditLoadCompletionChecklist/EditLoadCompletionChecklist";
import AddEditChangeRemoveButtons from '../../components/AddEditChangeRemoveButtons/AddEditChangeRemoveButtons';
import { isBooleanFalse, isBooleanTrue, isListEmpty, isListNotEmpty, isNotNullOrUndefined, isNumberGreaterThanZero, isObjectEmpty, isObjectNotEmpty, isStringEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { CanSee } from '../../shared/entitlements/entitlements';
import LoadUtils from '../../api/utils/loadUtils';
import DocumentUtils from '../../api/utils/documentUtils';
import StringFormatter from '../../shared/stringFormatter';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from "../../store/actions/index";
import Document from '../Document/Document';
import ReviewDocument from '../ReviewDocument/ReviewDocument';
import EditMissingDocument from '../EditMissingDocument/EditMissingDocument';
import { selectListIsLoading } from '../../store/utility';
import Enums from '../../shared/enums';
import { CheckOutlined, DeleteOutlined, DeliveredProcedureOutlined, DollarOutlined, DownOutlined, EditOutlined, FileTextOutlined, MinusOutlined, PaperClipOutlined, PlusOutlined, QuestionOutlined, StrikethroughOutlined } from '@ant-design/icons';
import NoteList from '../NoteList/NoteList';
import Invoice from '../Invoice/Invoice';
import EditInvoice from '../EditInvoice/EditInvoice';
import DataTable from '../DataTable/DataTable';

import NewInvoiceLineItem from '../../components/NewInvoiceLineItem/NewInvoiceLineItem';
import EditInvoiceLineItem from '../../components/EditInvoiceLineItem/EditInvoiceLineItem';
import RemoveInvoiceLineItem from '../../components/RemoveInvoiceLineItem/RemoveInvoiceLineItem';
import NewTransaction from '../../components/NewTransaction/NewTransaction';
import EditTransaction from '../../components/EditTransaction/EditTransaction';
import RemoveTransaction from '../../components/RemoveTransaction/RemoveTransaction';
import EditInvoiceLineItemStatus from '../EditInvoiceLineItemStatus/EditInvoiceLineItemStatus';
import EditInvoiceLineItemApprovalStatus from '../EditInvoiceLineItemApprovalStatus/EditInvoiceLineItemApprovalStatus';
import EditTransactionPaymentStatus from '../EditTransactionPaymentStatus/EditTransactionPaymentStatus';
import EditInvoiceLineItemStatusCancelled from '../EditInvoiceLineItemStatusCancelled/EditInvoiceLineItemStatusCancelled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faTools } from '@fortawesome/free-solid-svg-icons';
import Header from '../Header/Header';
import LoadClaims from '../LoadClaims/LoadClaims';
import FormItemDisplay from '../FormItemDisplay/FormItemDisplay';
import DataLinkRow from '../DataLinkRow/DataLinkRow';
import EditMissingPedigreeInformation from '../EditMissingPedigreeInformation/EditMissingPedigreeInformation';
import LoadNewShipperInvoice from '../LoadNewShipperInvoice/LoadNewShipperInvoice';
import LoadSendShipperInvoice from '../LoadSendShipperInvoice/LoadSendShipperInvoice';
import LoadEditShipperInvoice from '../LoadEditShipperInvoice/LoadEditShipperInvoice';
import LoadSendPriceConfirmation from '../LoadSendPriceConfirmation/LoadSendPriceConfirmation';
import LoadSendRateConfirmation from '../LoadSendRateConfirmation/LoadSendRateConfirmation';
import LoadEditPriceConfirmation from '../LoadEditPriceConfirmation/LoadEditPriceConfirmation';
import LoadEditRateConfirmation from '../LoadEditRateConfirmation/LoadEditRateConfirmation';
import LoadEditCarrierInvoice from '../LoadEditCarrierInvoice/LoadEditCarrierInvoice';
import LoadNewPriceConfirmation from '../LoadNewPriceConfirmation/LoadNewPriceConfirmation';
import LoadNewRateConfirmation from '../LoadNewRateConfirmation/LoadNewRateConfirmation';
import LoadNewCarrierInvoice from '../LoadNewCarrierInvoice/LoadNewCarrierInvoice';
import { orderBy } from 'lodash';
import DataRow from '../DataRow/DataRow';
import LoadSendLoadFinalizationEmail from '../LoadSendLoadFinalizationEmail/LoadSendLoadFinalizationEmail';

const missingEntityStyle = {
    backgroundColor: '#fff1f0',
    border: '1px solid #ffa39e',
};

const missingEntityHeadStyle = {
    borderBottom: '1px solid #ffa39e',
};

const pendingEntityStyle = {
    backgroundColor: '#fffbe6',
    border: '1px solid #ffe58f',
};

const pendingEntityHeadStyle = {
    borderBottom: '1px solid #ffe58f',
};

const completedEntityStyle = {
    backgroundColor: '#f6ffed',
    border: '1px solid #b7eb8f',
};

const completedEntityHeadStyle = {
    borderBottom: '1px solid #b7eb8f',
};

const errorEntityStyle = {
    backgroundColor: '#fff2f0',
    border: '1px solid #ffccc7',
};

const gridStyle = {
    width: '50%',
    textAlign: 'center',
};

const stringFormatter = new StringFormatter();

const LoadCompletionCard = ({ load, loadId, stops, invoices, invoiceLineItems, transactions, claims, serviceType, shipperId, carrierId, currentStatus, title, style = {}, headStyle = {}, showDocumentCards = false, showInvoicingCards = false, showCompletionChecklist = false, showQuotes = false, showMissingPedigreeInformation = false }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const accounts = useSelector(state => state.orchestrator.accounts);
    const isAdmin = useSelector(state => state.auth.isAdmin);
    const entityType = useSelector(state => state.auth.entityType);
    const entityId = useSelector(state => state.auth.entityId);
    const documentLists = useSelector(state => state.documents.lists);
    const isLoadingDocuments = useSelector(state => selectListIsLoading(state.documents.lists, loadId));
    const noteLists = useSelector(state => state.notes.lists);
    const isLoadingNotes = useSelector(state => selectListIsLoading(state.notes.lists, loadId));

    //#endregion
    //#region useStates

    const [visibleDocuments, setVisibleDocuments] = useState([]);
    const [pendingApprovalDocuments, setPendingApprovalDocuments] = useState([]);
    const [missingDocuments, setMissingDocuments] = useState([]);
    const [showEditCompletionChecklist, setShowEditEntity] = useState(false);
    const [hasUnreviewedDocuments, setHasUnreviewedDocuments] = useState(true);
    const [hasMissingDocuments, setHasMissingDocuments] = useState(true);
    const [isChecklistCompleted, setIsChecklistCompleted] = useState(false);
    const [proofOfDeliveryDocuments, setProofOfDeliveryDocuments] = useState([]);
    const [hasMissingProofOfDelivery, setHasMissingProofOfDelivery] = useState(true);
    const [hasPendingProofOfDelivery, setHasPendingProofOfDelivery] = useState(true);
    const [showEditMissingDocument, setShowEditMissingDocument] = useState(false);
    const [showReviewDocument, setShowReviewDocument] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [showViewDocument, setShowViewDocument] = useState(false);
    const [showSendLoadFinalizationModal, setShowSendLoadFinalizationModal] = useState(false);

    const [showReviewDocuments, setShowReviewDocuments] = useState(false);
    const [showViewDocuments, setShowViewDocuments] = useState(false);
    const [showMissingDocuments, setShowMissingDocuments] = useState(false);

    const [showReviewPOD, setShowReviewPOD] = useState(false);
    const [showViewPOD, setShowViewPOD] = useState(false);
    const [showMissingPOD, setShowMissingPOD] = useState(false);

    const [shipperAccount, setShipperAccount] = useState(null);
    const [carrierAccount, setCarrierAccount] = useState(null);
    const [carrierInvoice, setCarrierInvoice] = useState(null);
    const [shipperInvoice, setShipperInvoice] = useState(null);
    const [priceConfirmation, setPriceConfirmation] = useState(null);
    const [rateConfirmation, setRateConfirmation] = useState(null);
    const [carrierInvoiceDocument, setCarrierInvoiceDocument] = useState({});
    const [shipperInvoiceDocument, setShipperInvoiceDocument] = useState({});
    const [factoringInvoiceDocument, setFactoringInvoiceDocument] = useState({});
    const [bankAccountDocument, setBankAccountDocument] = useState({});
    const [factoringNOADocument, setFactoringNOADocument] = useState({});

    const [invoiceList, setInvoiceList] = useState([]);
    const [quoteList, setQuoteList] = useState([]);
    const [selectedInvoice, setSelectedInvoice] = useState({});
    const [showNotesModal, setShowNotesModal] = useState(false);
    const [showNewPriceConfirmationModal, setShowNewPriceConfirmationModal] = useState(false);
    const [showNewRateConfirmationModal, setShowNewRateConfirmationModal] = useState(false);
    const [showNewShipperInvoiceModal, setShowNewShipperInvoiceModal] = useState(false);
    const [showNewCarrierInvoiceModal, setShowNewCarrierInvoiceModal] = useState(false);
    const [showResendPriceConfirmationModal, setShowResendPriceConfirmationModal] = useState(false);
    const [showResendRateConfirmationModal, setShowResendRateConfirmationModal] = useState(false);
    const [showResendShipperInvoiceModal, setShowResendShipperInvoiceModal] = useState(false);
    const [showUpdatePriceConfirmationModal, setShowUpdatePriceConfirmationModal] = useState(false);
    const [showUpdateRateConfirmationModal, setShowUpdateRateConfirmationModal] = useState(false);
    const [showUpdateShipperInvoiceModal, setShowUpdateShipperInvoiceModal] = useState(false);
    const [showUpdateCarrierInvoiceModal, setShowUpdateCarrierInvoiceModal] = useState(false);
    const [showViewInvoiceModal, setShowViewInvoiceModal] = useState(false);
    const [showEditInvoice, setShowEditInvoice] = useState(false);
    const [showInvoiceLineItemModal, setShowInvoiceLineItemModal] = useState(false);
    const [showTransactionModal, setShowTransactionModal] = useState(false);
    const [showShipperInvoiceModal, setShowShipperInvoiceModal] = useState(false);
    const [showCarrierInvoiceModal, setShowCarrierInvoiceModal] = useState(false);
    const [showFactoringInvoiceModal, setShowFactoringInvoiceModal] = useState(false);
    const [showClaimsModal, setShowClaimsModal] = useState(false);

    const [selectedInvoiceLineItem, setSelectedInvoiceLineItem] = useState({});
    const [showEditInvoiceLineItem, setShowEditInvoiceLineItem] = useState(false);
    const [showEditStatusInvoiceLineItem, setShowEditStatusInvoiceLineItem] = useState(false);
    const [showCancelInvoiceLineItem, setShowCancelInvoiceLineItem] = useState(false);
    const [showEditApprovalStatusInvoiceLineItem, setShowEditApprovalStatusInvoiceLineItem] = useState(false);
    const [showNewInvoiceLineItem, setShowNewInvoiceLineItem] = useState(false);
    const [showRemoveInvoiceLineItem, setShowRemoveInvoiceLineItem] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState({});
    const [showEditTransaction, setShowEditTransaction] = useState(false);
    const [showEditPaymentStatusTransaction, setShowEditPaymentStatusTransaction] = useState(false);
    const [showNewTransaction, setShowNewTransaction] = useState(false);
    const [showRemoveTransaction, setShowRemoveTransaction] = useState(false);
    const [fromEntityType, setFromEntityType] = useState(null);
    const [toEntityType, setToEntityType] = useState(null);

    const [missingPedigreeInformation, setMissingPedigreeInformation] = useState([]);
    const [showEditMissingPedigreeInformation, setShowEditMissingPedigreeInformation] = useState(false);
    const [selectedMissingPedigreeInformation, setSelectedMissingPedigreeInformation] = useState(null);

    //#endregion
    //#region load completion methods

    const markDocumentsReviewed = () => {
        if (isObjectNotEmpty(load.completionCheckList) && isBooleanTrue(load.completionCheckList.hasDocumentsReviewed)) {
            toggleViewDocuments();
        } else {
            dispatch(actionCreators.updateLoad(loadId, { completionCheckList: { hasDocumentsReviewed: true } }));
            toggleViewDocuments();
        }
    };

    const markPODDone = () => {
        if (isListNotEmpty(proofOfDeliveryDocuments)) {
            if (proofOfDeliveryDocuments.filter(d => d.status === 'OSDR').length > 0) {
                dispatch(actionCreators.updateLoad(loadId, { completionCheckList: { proofOfDeliveryStatus: 'OSDR' } }));
                toggleViewPOD();
            } else {
                dispatch(actionCreators.updateLoad(loadId, { completionCheckList: { proofOfDeliveryStatus: 'CLEAN' } }));
                toggleViewPOD();
            }
        }
    };

    const markPODClean = () => {
        if (isObjectNotEmpty(load.completionCheckList) && isStringNotEmpty(load.completionCheckList.proofOfDeliveryStatus) && (load.completionCheckList.proofOfDeliveryStatus === 'CLEAN')) {
            toggleViewPOD();
        } else {
            dispatch(actionCreators.updateLoad(loadId, { completionCheckList: { proofOfDeliveryStatus: 'CLEAN' } }));
            toggleViewPOD();
        }
    };

    const markPODOSDR = () => {
        if (isObjectNotEmpty(load.completionCheckList) && isStringNotEmpty(load.completionCheckList.proofOfDeliveryStatus) && (load.completionCheckList.proofOfDeliveryStatus === 'OSDR')) {
            toggleViewPOD();
        } else {
            dispatch(actionCreators.updateLoad(loadId, { completionCheckList: { proofOfDeliveryStatus: 'OSDR' } }));
            toggleViewPOD();
        }
    };

    const closeLoad = () => {
        dispatch(actionCreators.sendLoadEvent({
            loadId: load.id,
            eventType: 'LOAD_CLOSED'
        }));
    };

    //#endregion
    //#region table displays

    const invoiceLineItemMenu = (record) => {
        return (
            <Menu>
                {isObjectNotEmpty(load) && (record.approvalStatus !== "APPROVED" && record.status !== 'COMPLETED' && record.status !== 'CANCELLED' && (record.toEntityType === entityType || record.fromEntityType === entityType || entityType === 'STAFF')) ? (
                    <Menu.Item key="approveInvoiceLineItem" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoiceLineItem(record); toggleEditApprovalStatusInvoiceLineItem(); }}>
                        <CheckOutlined style={{ marginRight: 8 }} />
                        <span>Approve Invoice Line Item</span>
                    </Menu.Item>
                ) : null}
                {isObjectNotEmpty(load) && (record.status !== 'COMPLETED' && record.status !== 'CANCELLED' && ((load.serviceType === 'TMS' && LoadUtils.isLoadOwner(load, entityType, entityId)) || entityType === 'STAFF')) ? (
                    <Menu.Item key="updateStatusInvoiceLineItem" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoiceLineItem(record); toggleEditStatusInvoiceLineItem(); }}>
                        <QuestionOutlined style={{ marginRight: 8 }} />
                        <span>Update Status of Invoice Line Item</span>
                    </Menu.Item>
                ) : null}
                {((record.approvalStatus !== "APPROVED" && record.status !== 'COMPLETED' && record.status !== 'CANCELLED' && isStringNotEmpty(record.itemType) && !Enums.StopFees.doesNameExist(record.itemType) && !Enums.EquipmentSpecifics.doesNameExist(record.itemType) && !Enums.LoadRequirements.doesNameExist(record.itemType) && isObjectNotEmpty(load) && ((load.serviceType === 'TMS' && LoadUtils.isLoadOwner(load, entityType, entityId)) || entityType === 'STAFF')) || isAdmin === true) && (
                    <Menu.Item key="editInvoiceLineItem" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoiceLineItem(record); toggleEditInvoiceLineItem(); }}>
                        <EditOutlined style={{ marginRight: 8 }} />
                        <span>Edit Invoice Line Item Details</span>
                    </Menu.Item>
                )}
                {(record.status !== 'COMPLETED' && record.status !== 'CANCELLED' && isObjectNotEmpty(load) && ((load.serviceType === 'TMS' && LoadUtils.isLoadOwner(load, entityType, entityId)) || entityType === 'STAFF')) && (
                    <Menu.Item key="cancelInvoiceLineItem" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoiceLineItem(record); toggleCancelInvoiceLineItem(); }}>
                        <StrikethroughOutlined style={{ marginRight: 8 }} />
                        <span>Cancel Invoice Line Item</span>
                    </Menu.Item>
                )}
                {((record.approvalStatus !== "APPROVED" && record.status !== 'COMPLETED' && record.status !== 'CANCELLED' && isStringNotEmpty(record.itemType) && !Enums.StopFees.doesNameExist(record.itemType) && !Enums.EquipmentSpecifics.doesNameExist(record.itemType) && !Enums.LoadRequirements.doesNameExist(record.itemType) && isObjectNotEmpty(load) && ((load.serviceType === 'TMS' && LoadUtils.isLoadOwner(load, entityType, entityId)) || entityType === 'STAFF')) || isAdmin === true) && (
                    <Menu.Item key="removeInvoiceLineItem" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoiceLineItem(record); toggleRemoveInvoiceLineItem(); }}>
                        <DeleteOutlined style={{ marginRight: 8 }} />
                        <span>Delete Invoice Line Item</span>
                    </Menu.Item>
                )}
            </Menu>
        );
    };

    const transactionMenu = (record) => {
        return (
            <Menu>
                {(record.paymentStatus !== 'COMPLETED' && isObjectNotEmpty(load) && ((load.serviceType === 'TMS' && LoadUtils.isLoadOwner(load, entityType, entityId)) || entityType === 'STAFF')) && (
                    <Menu.Item key="updatePaymentStatusTransaction" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedTransaction(record); toggleEditPaymentStatusTransaction(); }}>
                        <QuestionOutlined style={{ marginRight: 8 }} />
                        <span>Update Payment Status of Transaction</span>
                    </Menu.Item>
                )}
                {(isAdmin === true) && (
                    <Menu.Item key="editTransaction" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedTransaction(record); toggleEditTransaction(); }}>
                        <EditOutlined style={{ marginRight: 8 }} />
                        <span>Edit Transaction Details</span>
                    </Menu.Item>
                )}
                {((record.paymentStatus !== 'COMPLETED' && isObjectNotEmpty(load) && ((load.serviceType === 'TMS' && LoadUtils.isLoadOwner(load, entityType, entityId))) || entityType === 'STAFF') || isAdmin === true) && (
                    <Menu.Item key="removeTransaction" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedTransaction(record); toggleRemoveTransaction(); }}>
                        <DeleteOutlined style={{ marginRight: 8 }} />
                        <span>Delete Transaction</span>
                    </Menu.Item>
                )}
            </Menu>
        );
    };

    const invoiceMenu = (record) => {
        return (
            <Menu>
                <Menu.Item key="viewInvoice" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoice(record); toggleViewInvoice(); }}>
                    <DollarOutlined style={{ marginRight: 8 }} />
                    <span>View {stringFormatter.toFormattedString('InvoiceType', record.invoiceType)}</span>
                </Menu.Item>
                {record.status !== 'COMPLETED' && record.status !== 'CANCELLED' && record.invoiceType === 'SHIPPER_INVOICE' && entityType === 'STAFF' ? (
                    <Menu.Item key="resendShipperInvoice" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoice(record); toggleResendShipperInvoiceModal(); }}>
                        <DollarOutlined style={{ marginRight: 8 }} />
                        <span>Re-Send {stringFormatter.toFormattedString('InvoiceType', record.invoiceType)}</span>
                    </Menu.Item>
                ) : null}
                {record.status !== 'COMPLETED' && record.status !== 'CANCELLED' && record.invoiceType === 'SHIPPER_INVOICE' && entityType === 'STAFF' ? (
                    <Menu.Item key="updateShipperInvoice" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoice(record); toggleUpdateShipperInvoiceModal(); }}>
                        <DollarOutlined style={{ marginRight: 8 }} />
                        <span>Update and Re-Send {stringFormatter.toFormattedString('InvoiceType', record.invoiceType)}</span>
                    </Menu.Item>
                ) : null}
                {record.status !== 'COMPLETED' && record.status !== 'CANCELLED' && record.invoiceType === 'CARRIER_INVOICE' && (entityType === 'STAFF' || entityType === 'SHIPPER') ? (
                    <Menu.Item key="updateCarrierInvoice" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoice(record); toggleUpdateCarrierInvoiceModal(); }}>
                        <DollarOutlined style={{ marginRight: 8 }} />
                        <span>Update and Re-Submit {stringFormatter.toFormattedString('InvoiceType', record.invoiceType)}</span>
                    </Menu.Item>
                ) : null}
                {record.status !== 'COMPLETED' && record.status !== 'CANCELLED' && isAdmin === true ? (
                    <Menu.Item key="editInvoice" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoice(record); toggleEditInvoice(); }}>
                        <DollarOutlined style={{ marginRight: 8 }} />
                        <span>Edit {stringFormatter.toFormattedString('InvoiceType', record.invoiceType)}</span>
                    </Menu.Item>
                ) : null}
                <Menu.Item key="manageNotes" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoice(record); toggleNotesModal(); }}>
                    <FileTextOutlined style={{ marginRight: 8 }} />
                    <span>Notes</span>
                </Menu.Item>
            </Menu>
        );
    };

    const quoteMenu = (record) => {
        return (
            <Menu>
                {isObjectNotEmpty(record.document) ? (
                    <Menu.Item key="viewManualDocument" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedDocument(record.document); toggleViewDocument(); }}>
                        <PaperClipOutlined style={{ marginRight: 8 }} />
                        <span>View Uploaded {record.name} Document</span>
                    </Menu.Item>
                ) : null}
                {isObjectEmpty(record.document) && isObjectNotEmpty(record.load) ? (
                    <Menu.Item key="uploadManualDocument" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedDocument({ entityType: 'LOAD', entityId: record.load.id, entity: record.load, entitySubType: null, entityName: isStringNotEmpty(record.load.parentName) ? record.load.parentName : (isStringNotEmpty(record.load.name) ? record.load.name : record.load.irisId), documentType: record.type, documentTypeName: record.name, documentDisplayName: record.name, visibleTo: record.visibleTo }); toggleEditMissingDocument(); }}>
                        <PaperClipOutlined style={{ marginRight: 8 }} />
                        <span>Upload {record.name} Document</span>
                    </Menu.Item>
                ) : null}
                {isObjectNotEmpty(record.invoice) ? (
                    <Menu.Item key="viewInvoice" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoice(record.invoice); toggleViewInvoice(); }}>
                        <DollarOutlined style={{ marginRight: 8 }} />
                        <span>{record.invoice.status === 'COMPLETED' ? 'View' : (((entityType === 'CARRIER' && record.type === 'RATE_CONFIRMATION') || (entityType === 'SHIPPER' && record.type === 'PRICE_CONFIRMATION')) ? 'Sign and Accept' : 'View')} {record.name}</span>
                    </Menu.Item>
                ) : null}
                {isObjectNotEmpty(record.invoice) && (record.invoice.status !== 'COMPLETED' && record.invoice.status !== 'CANCELLED') && record.type === 'PRICE_CONFIRMATION' && entityType === 'STAFF' ? (
                    <Menu.Item key="resendPriceConfirmation" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoice(record.invoice); toggleResendPriceConfirmationModal(); }}>
                        <DollarOutlined style={{ marginRight: 8 }} />
                        <span>Re-Send {record.name}</span>
                    </Menu.Item>
                ) : null}
                {isObjectNotEmpty(record.invoice) && (record.invoice.status !== 'COMPLETED' && record.invoice.status !== 'CANCELLED') && record.type === 'RATE_CONFIRMATION' && (entityType === 'STAFF' || entityType === 'SHIPPER') ? (
                    <Menu.Item key="resendRateConfirmation" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoice(record.invoice); toggleResendRateConfirmationModal(); }}>
                        <DollarOutlined style={{ marginRight: 8 }} />
                        <span>Re-Send {record.name}</span>
                    </Menu.Item>
                ) : null}
                {isObjectNotEmpty(record.invoice) && (record.invoice.status !== 'COMPLETED' && record.invoice.status !== 'CANCELLED') && record.type === 'SHIPPER_INVOICE' && entityType === 'STAFF' ? (
                    <Menu.Item key="resendShipperInvoice" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoice(record.invoice); toggleResendShipperInvoiceModal(); }}>
                        <DollarOutlined style={{ marginRight: 8 }} />
                        <span>Re-Send {record.name}</span>
                    </Menu.Item>
                ) : null}
                {isObjectNotEmpty(record.invoice) && (record.invoice.status !== 'COMPLETED' && record.invoice.status !== 'CANCELLED') && record.type === 'PRICE_CONFIRMATION' && entityType === 'STAFF' ? (
                    <Menu.Item key="updatePriceConfirmation" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoice(record.invoice); toggleUpdatePriceConfirmationModal(); }}>
                        <DollarOutlined style={{ marginRight: 8 }} />
                        <span>Update and Re-Send {record.name}</span>
                    </Menu.Item>
                ) : null}
                {isObjectNotEmpty(record.invoice) && (record.invoice.status !== 'COMPLETED' && record.invoice.status !== 'CANCELLED') && record.type === 'RATE_CONFIRMATION' && (entityType === 'STAFF' || entityType === 'SHIPPER') ? (
                    <Menu.Item key="updateRateConfirmation" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoice(record.invoice); toggleUpdateRateConfirmationModal(); }}>
                        <DollarOutlined style={{ marginRight: 8 }} />
                        <span>Update and Re-Send {record.name}</span>
                    </Menu.Item>
                ) : null}
                {(isObjectEmpty(record.invoice) || (isObjectNotEmpty(record.invoice) && (record.invoice.status === 'COMPLETED' || record.invoice.status === 'CANCELLED'))) && record.type === 'PRICE_CONFIRMATION' && entityType === 'STAFF' ? (
                    <Menu.Item key="newPriceConfirmation" onClick={(e) => { e.domEvent.stopPropagation(); toggleNewPriceConfirmationModal(); }}>
                        <DollarOutlined style={{ marginRight: 8 }} />
                        <span>Generate and Send New {record.name}</span>
                    </Menu.Item>
                ) : null}
                {(isObjectEmpty(record.invoice) || (isObjectNotEmpty(record.invoice) && (record.invoice.status === 'COMPLETED' || record.invoice.status === 'CANCELLED'))) && record.type === 'RATE_CONFIRMATION' && (entityType === 'STAFF' || entityType === 'SHIPPER') ? (
                    <Menu.Item key="sendRateConfirmation" onClick={(e) => { e.domEvent.stopPropagation(); toggleNewRateConfirmationModal(); }}>
                        <DollarOutlined style={{ marginRight: 8 }} />
                        <span>Generate and Send New {record.name}</span>
                    </Menu.Item>
                ) : null}
                {isObjectNotEmpty(record.invoice) && (record.invoice.status !== 'COMPLETED' && record.invoice.status !== 'CANCELLED') && isAdmin === true ? (
                    <Menu.Item key="editInvoice" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoice(record.invoice); toggleEditInvoice(); }}>
                        <DollarOutlined style={{ marginRight: 8 }} />
                        <span>Edit {record.name}</span>
                    </Menu.Item>
                ) : null}
                {isObjectNotEmpty(record.invoice) ? (
                    <Menu.Item key="manageNotes" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedInvoice(record.invoice); toggleNotesModal(); }}>
                        <FileTextOutlined style={{ marginRight: 8 }} />
                        <span>Notes</span>
                    </Menu.Item>
                ) : null}
            </Menu>
        );
    };

    const invoiceColumns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            width: 100,
            render: (text, record) => {
                if (LoadUtils.loadStatusIsNot(load.loadStatus, ['CLOSED'])) {
                    return (
                        <Dropdown overlay={invoiceMenu(record)} trigger={['click']}>
                            <Button>Actions <DownOutlined /></Button>
                        </Dropdown>
                    );
                } else {
                    return '';
                }
            },
            align: 'center',
        },
        {
            title: 'Invoice #',
            dataIndex: 'irisId',
            key: 'irisId',
        },
        {
            title: 'Invoice Status',
            dataIndex: 'status',
            key: 'status',
            textAlign: 'right',
            render: (text, record) => { return stringFormatter.toFormattedString('InvoiceStatus', text); },
        },
        {
            title: 'Payment',
            children: [
                {
                    title: 'Status',
                    dataIndex: 'paymentStatus',
                    key: 'paymentStatus',
                    textAlign: 'right',
                    render: (text, record) => { return stringFormatter.toFormattedString('PaymentStatus', text); },
                },
                {
                    title: 'Preferred Method',
                    dataIndex: 'paymentMethod',
                    key: 'paymentMethod',
                    textAlign: 'right',
                    render: (text, record) => { return stringFormatter.toFormattedString('PreferredPaymentMethod', text); },
                },
                {
                    title: 'Remit Name',
                    dataIndex: 'remitName',
                    key: 'remitName',
                    textAlign: 'right',
                    render: (text, record) => { return stringFormatter.toFormattedString('String', text); },
                },
                {
                    title: 'Due Date',
                    dataIndex: 'paymentDueDate',
                    key: 'paymentDueDate',
                    textAlign: 'right',
                    render: (text, record) => { return stringFormatter.toFormattedString('MomentDate', text); },
                },
                {
                    title: 'Paid At',
                    dataIndex: 'paidAt',
                    key: 'paidAt',
                    textAlign: 'right',
                    render: (text, record) => { return stringFormatter.toFormattedString('MomentDate', text); },
                },
            ]
        },
        {
            title: 'Notes and Corrections',
            dataIndex: 'notes',
            key: 'notes',
            textAlign: 'right',
            render: (text, record) => {
                return {
                    props: {
                        className: [classes.approvedRow, classes.cell]
                    },
                    children: ((isObjectNotEmpty(record) && isObjectNotEmpty(noteLists) && isObjectNotEmpty(noteLists[record.id])) ? <Button type="link" onClick={(e) => { e.stopPropagation(); setSelectedInvoice(record); toggleNotesModal(); }}>{noteLists[record.id].records.length}</Button> : ''),
                };
            },
        },
    ];

    const quoteColumns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            width: 100,
            render: (text, record) => {
                if (LoadUtils.loadStatusIsNot(load.loadStatus, ['CLOSED'])) {
                    return (
                        <Dropdown overlay={quoteMenu(record)} trigger={['click']}>
                            <Button>Actions <DownOutlined /></Button>
                        </Dropdown>
                    );
                } else {
                    return '';
                }
            },
            align: 'center',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            textAlign: 'left',
        },
        {
            title: 'Document Status',
            dataIndex: 'documentStatus',
            key: 'documentStatus',
            textAlign: 'right',
            render: (text, record) => {
                if (isObjectNotEmpty(record.document)) {
                    return 'Uploaded';
                } else {
                    return '';
                }
            },
        },
        {
            title: 'Digital Status',
            dataIndex: ['invoice', 'status'],
            key: 'invoice.status',
            textAlign: 'right',
            render: (text, record) => {
                if (text === 'COMPLETED') {
                    return 'Signed';
                } else {
                    if (entityType === 'CARRIER' && text === 'SENT') {
                        return 'Pending Acceptance';
                    } else {
                        return stringFormatter.toFormattedString('InvoiceStatus', text);
                    }
                }
            },
        },
    ];

    const invoiceLineItemColumns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            width: 100,
            render: (text, record) => {
                if (LoadUtils.loadStatusIsNot(load.loadStatus, ['CLOSED'])) {
                    return (
                        <Dropdown overlay={invoiceLineItemMenu(record)} trigger={['click']}>
                            <Button>Actions <DownOutlined /></Button>
                        </Dropdown>
                    );
                } else {
                    return '';
                }
            },
            align: 'center',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            textAlign: 'left',
        },
        {
            title: 'Base Price',
            dataIndex: 'baseAmount',
            key: 'baseAmount',
            textAlign: 'center',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            textAlign: 'center',
        },
        {
            title: 'Total',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            textAlign: 'right',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
        },
        {
            title: 'Owed By',
            dataIndex: 'fromEntityType',
            key: 'fromEntityType',
            textAlign: 'right',
            render: (text, record) => { return stringFormatter.toFormattedString("InvoiceAccountType", text, null); },
        },
        {
            title: 'Owed To',
            dataIndex: 'toEntityType',
            key: 'toEntityType',
            textAlign: 'right',
            render: (text, record) => { return stringFormatter.toFormattedString("InvoiceAccountType", text, null); },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            textAlign: 'right',
            render: (text, record) => { return stringFormatter.toFormattedString("InvoiceLineItemStatus", text, null); },
        },
        {
            title: 'Approval',
            dataIndex: 'approvalStatus',
            key: 'approvalStatus',
            textAlign: 'right',
            render: (text, record) => { return stringFormatter.toFormattedString("InvoiceApprovalStatus", text, null); },
        },
    ];

    const transactionColumns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            width: 100,
            render: (text, record) => {
                if (LoadUtils.loadStatusIsNot(load.loadStatus, ['CLOSED'])) {
                    return (
                        <Dropdown overlay={transactionMenu(record)} trigger={['click']}>
                            <Button>Actions <DownOutlined /></Button>
                        </Dropdown>
                    );
                } else {
                    return '';
                }
            },
            align: 'center',
        },
        {
            title: 'Payment Method',
            dataIndex: 'paymentMethod',
            key: 'paymentMethod',
            textAlign: 'left',
            render: (text, record) => { return stringFormatter.toFormattedString("PaymentMethod", text, null); },
        },
        {
            title: 'Payment Status',
            dataIndex: 'paymentStatus',
            key: 'paymentStatus',
            textAlign: 'left',
            render: (text, record) => { return stringFormatter.toFormattedString("PaymentStatus", text, null); },
        },
        {
            title: 'From',
            dataIndex: 'fromSource',
            key: 'fromSource',
            textAlign: 'left',
            render: (text, record) => { return stringFormatter.toFormattedString("InvoiceSourceAccountType", text, null); },
        },
        {
            title: 'For',
            dataIndex: 'fromEntityType',
            key: 'fromEntityType',
            textAlign: 'left',
            render: (text, record) => { return stringFormatter.toFormattedString("InvoiceAccountType", text, null); },
        },
        {
            title: 'To',
            dataIndex: 'toSource',
            key: 'toSource',
            textAlign: 'left',
            render: (text, record) => { return stringFormatter.toFormattedString("InvoiceSourceAccountType", text, null); },
        },
        {
            title: 'For',
            dataIndex: 'toEntityType',
            key: 'toEntityType',
            textAlign: 'left',
            render: (text, record) => { return stringFormatter.toFormattedString("InvoiceAccountType", text, null); },
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            textAlign: 'center',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
        },
        {
            title: 'Transaction Fee',
            dataIndex: 'transactionFee',
            key: 'transactionFee',
            textAlign: 'center',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
        },
        {
            title: 'Net Amount',
            dataIndex: 'netAmount',
            key: 'netAmount',
            textAlign: 'center',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
        },
        {
            title: 'Paid At',
            dataIndex: 'paidAt',
            key: 'paidAt',
            textAlign: 'left',
            render: (text, record) => { return stringFormatter.toFormattedString("MomentDateTime", text, null); },
        },
        {
            title: 'External Reference Id',
            dataIndex: 'qbTransactionId',
            key: 'qbTransactionId',
            textAlign: 'center',
        },
    ];

    const invoiceTitle = (title, invoiceType, load, entityType, newInvoiceTitle, newInvoiceAction = null) => (
        <Row gutter={[4, 4]} style={{ display: 'flex', alignItems: 'center' }}>
            <Col xs={{ span: 12 }}>
                <b>{title}</b>
            </Col>
            <Col xs={{ span: 12 }} style={{ textAlign: 'right' }}>
                {LoadUtils.loadStatusIsNot(load.loadStatus, ["CLOSED"]) && invoiceLineItems.find(i => (i.invoiceId === null || i.invoiceId === undefined) && (i.fromEntityType === entityType || i.toEntityType === entityType) && i.isDeleted === false) !== undefined && newInvoiceAction !== null ? (
                    <CanSee entityAction="CREATE" entityModel={`LOAD_${invoiceType}`} entityObject={load}>
                        <Tooltip placement="top" key="1" title={newInvoiceTitle}>
                            <Button type="primary" icon={<PlusOutlined />} style={{ marginRight: 8 }} onClick={() => { newInvoiceAction(); }}>{newInvoiceTitle}</Button>
                        </Tooltip>
                    </CanSee>
                ) : null}
            </Col>
        </Row>
    );

    const getInvoiceRowClassName = (record) => {
        if (isObjectNotEmpty(record.invoice)) {
            if (record.invoice.status === 'COMPLETED') {
                return classes.completedRow;
            } else if (record.invoice.status === 'PENDING') {
                return classes.cancelledRow;
            } else if (record.invoice.status === 'SENT') {
                return classes.approvedRow;
            } else {
                return classes.pendingRow;
            }
        } else if (isObjectNotEmpty(record.document)) {
            return classes.completedRow;
        } else {
            return classes.pendingRow;
        }
    };

    const invoiceLineItemTitle = (forAccount, from, to) => (
        <Row gutter={[4, 4]} style={{ display: 'flex', alignItems: 'center' }}>
            <Col xs={{ span: 12 }}>
                <b>{entityType === 'STAFF' ? 'Manage Invoice Line Items for ' + forAccount : 'Manage Invoice Line Items'}</b>
            </Col>
            <Col xs={{ span: 12 }} style={{ textAlign: 'right' }}>
                {LoadUtils.loadStatusIsNot(load.loadStatus, ['CLOSED']) ? (
                    <CanSee entityAction="CREATE" entityModel="LOAD_INVOICE_LINE_ITEM" entityObject={load}>
                        <Tooltip placement="top" key="1" title={'New Invoice Line Item'}>
                            <Button type="primary" icon={<PlusOutlined />} style={{ marginRight: 8 }} onClick={() => { setFromEntityType(to); setToEntityType(from); toggleNewInvoiceLineItem(); }}>Addition</Button>
                        </Tooltip>
                        <Tooltip placement="top" key="2" title={'New Invoice Line Item'}>
                            <Button type="primary" icon={<MinusOutlined />} onClick={() => { setFromEntityType(from); setToEntityType(to); toggleNewInvoiceLineItem(); }}>Deduction</Button>
                        </Tooltip>
                    </CanSee>
                ) : null}
            </Col>
        </Row>
    );

    const getInvoiceLineItemRowClassName = (record) => {
        if (record.status === 'COMPLETED') {
            return classes.completedRow;
        } else if (record.status === 'CANCELLED') {
            return classes.cancelledRow;
        } else if (record.approvalStatus === 'APPROVED') {
            return classes.approvedRow;
        } else {
            return classes.pendingRow;
        }
    };

    const transactionTitle = (invoice, invoiceName, invoiceType) => (
        <Row gutter={[4, 4]} style={{ display: 'flex', alignItems: 'center' }}>
            <Col xs={{ span: 12 }}>
                <b>{entityType === 'STAFF' ? 'Manage Transactions for ' + invoiceName : 'Manage Transactions'}</b>
            </Col>
            <Col xs={{ span: 12 }} style={{ textAlign: 'right' }}>
                {isStringNotEmpty(carrierId) && isStringNotEmpty(shipperId) && LoadUtils.loadStatusIsNot(currentStatus, ['CLOSED']) && (isObjectNotEmpty(invoice) || invoiceType === 'FACTORING_INVOICE') ? (
                    <CanSee entityAction="CREATE" entityModel="LOAD_TRANSACTION" entityObject={load}>
                        <Tooltip placement="top" title={'New Transaction for ' + invoiceName}>
                            <Button key="1" type="primary" icon={<PlusOutlined />} shape="circle" onClick={() => { setSelectedInvoice(invoice); toggleNewTransaction(); }} />
                        </Tooltip>
                    </CanSee>
                ) : null}
            </Col>
        </Row>
    );

    const getTransactionRowClassName = (record) => {
        if (record.paymentStatus === 'COMPLETED') {
            return classes.completedRow;
        } else if (record.paymentStatus === 'CANCELLED') {
            return classes.cancelledRow;
        } else if (record.paymentStatus === 'PENDING') {
            return classes.approvedRow;
        } else {
            return classes.pendingRow;
        }
    };

    //#endregion
    //#region toggles

    const toggleSendLoadFinalizationModal = () => {
        setShowSendLoadFinalizationModal(!showSendLoadFinalizationModal);
    };

    const toggleEditMissingPedigreeInformation = () => {
        setShowEditMissingPedigreeInformation(!showEditMissingPedigreeInformation);
    };

    const toggleEditCompletionChecklist = () => {
        setShowEditEntity(!showEditCompletionChecklist);
    };

    const toggleEditMissingDocument = () => {
        setShowEditMissingDocument(!showEditMissingDocument);
    };

    const toggleReviewDocument = () => {
        setShowReviewDocument(!showReviewDocument);
    };

    const toggleViewDocument = () => {
        setShowViewDocument(!showViewDocument);
    };

    const toggleMissingDocuments = () => {
        setShowMissingDocuments(!showMissingDocuments);
    };

    const toggleReviewDocuments = () => {
        setShowReviewDocuments(!showReviewDocuments);
    };

    const toggleViewDocuments = () => {
        setShowViewDocuments(!showViewDocuments);
    };

    const toggleMissingPOD = () => {
        setShowMissingPOD(!showMissingPOD);
    };

    const toggleReviewPOD = () => {
        setShowReviewPOD(!showReviewPOD);
    };

    const toggleViewPOD = () => {
        setShowViewPOD(!showViewPOD);
    };

    const toggleNotesModal = () => {
        setShowNotesModal(!showNotesModal);
    };

    const toggleNewPriceConfirmationModal = () => {
        setShowNewPriceConfirmationModal(!showNewPriceConfirmationModal);
    };

    const toggleNewRateConfirmationModal = () => {
        setShowNewRateConfirmationModal(!showNewRateConfirmationModal);
    };

    const toggleNewShipperInvoiceModal = () => {
        setShowNewShipperInvoiceModal(!showNewShipperInvoiceModal);
    };

    const toggleNewCarrierInvoiceModal = () => {
        setShowNewCarrierInvoiceModal(!showNewCarrierInvoiceModal);
    };

    const toggleResendPriceConfirmationModal = () => {
        setShowResendPriceConfirmationModal(!showResendPriceConfirmationModal);
    };

    const toggleResendRateConfirmationModal = () => {
        setShowResendRateConfirmationModal(!showResendRateConfirmationModal);
    };

    const toggleResendShipperInvoiceModal = () => {
        setShowResendShipperInvoiceModal(!showResendShipperInvoiceModal);
    };

    const toggleUpdatePriceConfirmationModal = () => {
        setShowUpdatePriceConfirmationModal(!showUpdatePriceConfirmationModal);
    };

    const toggleUpdateRateConfirmationModal = () => {
        setShowUpdateRateConfirmationModal(!showUpdateRateConfirmationModal);
    };

    const toggleUpdateShipperInvoiceModal = () => {
        setShowUpdateShipperInvoiceModal(!showUpdateShipperInvoiceModal);
    };

    const toggleUpdateCarrierInvoiceModal = () => {
        setShowUpdateCarrierInvoiceModal(!showUpdateCarrierInvoiceModal);
    };

    const toggleViewInvoice = () => {
        setShowViewInvoiceModal(!showViewInvoiceModal);
    };

    const toggleEditInvoice = () => {
        setShowEditInvoice(!showEditInvoice);
    };

    const toggleInvoiceLineItemModal = () => {
        setShowInvoiceLineItemModal(!showInvoiceLineItemModal);
    };

    const toggleTransactionModal = () => {
        setShowTransactionModal(!showTransactionModal);
    };

    const toggleEditInvoiceLineItem = () => {
        setShowEditInvoiceLineItem(!showEditInvoiceLineItem);
    };

    const toggleEditStatusInvoiceLineItem = () => {
        setShowEditStatusInvoiceLineItem(!showEditStatusInvoiceLineItem);
    };

    const toggleCancelInvoiceLineItem = () => {
        setShowCancelInvoiceLineItem(!showCancelInvoiceLineItem);
    }

    const toggleEditApprovalStatusInvoiceLineItem = () => {
        setShowEditApprovalStatusInvoiceLineItem(!showEditApprovalStatusInvoiceLineItem);
    };

    const toggleNewInvoiceLineItem = () => {
        setShowNewInvoiceLineItem(!showNewInvoiceLineItem);
    };

    const toggleRemoveInvoiceLineItem = () => {
        setShowRemoveInvoiceLineItem(!showRemoveInvoiceLineItem);
    };

    const toggleEditTransaction = () => {
        setShowEditTransaction(!showEditTransaction);
    };

    const toggleEditPaymentStatusTransaction = () => {
        setShowEditPaymentStatusTransaction(!showEditPaymentStatusTransaction);
    };

    const toggleNewTransaction = () => {
        setShowNewTransaction(!showNewTransaction);
    };

    const toggleRemoveTransaction = () => {
        setShowRemoveTransaction(!showRemoveTransaction);
    };

    const toggleShipperInvoiceModal = () => {
        setShowShipperInvoiceModal(!showShipperInvoiceModal);
    };

    const toggleCarrierInvoiceModal = () => {
        setShowCarrierInvoiceModal(!showCarrierInvoiceModal);
    };

    const toggleFactoringInvoiceModal = () => {
        setShowFactoringInvoiceModal(!showFactoringInvoiceModal);
    };

    const toggleClaimsModal = () => {
        setShowClaimsModal(!showClaimsModal);
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        dispatch(actionCreators.getAccounts());
    }, []);

    useMemo(() => {
        if (isStringNotEmpty(loadId) && isStringNotEmpty(carrierId)) {
            let ids = [];
            ids.push(loadId);
            ids.push(carrierId);

            dispatch(actionCreators.fetchBulkDocumentLists(load.id, ids));
        }
    }, [loadId, carrierId]);

    useMemo(() => {
        if (isStringNotEmpty(shipperId) && isStringNotEmpty(carrierId) && isListNotEmpty(accounts)) {
            let shipperAccountObj = accounts.find(i => i.id === shipperId);
            if (isObjectNotEmpty(shipperAccountObj)) {
                // console.log(shipperAccount);
                setShipperAccount({ ...shipperAccountObj });
            }

            let carrierAccountObj = accounts.find(i => i.id === carrierId);
            if (isObjectNotEmpty(carrierAccountObj)) {
                // console.log(carrierAccountObj);
                setCarrierAccount({ ...carrierAccountObj });
            }
        }
    }, [accounts, shipperId, carrierId]);

    useMemo(() => {
        if (isStringNotEmpty(loadId) && isListNotEmpty(invoices)) {
            let carrierInvoiceObj = invoices.find(i => i.invoiceType === 'CARRIER_INVOICE' && i.status !== 'COMPLETED' && i.status !== 'CANCELLED' && i.isDeleted === false);
            if (isObjectNotEmpty(carrierInvoiceObj)) {
                setCarrierInvoice(carrierInvoiceObj);
            }
            let shipperInvoiceObj = invoices.find(i => i.invoiceType === 'SHIPPER_INVOICE' && i.status !== 'COMPLETED' && i.status !== 'CANCELLED' && i.isDeleted === false);
            if (isObjectNotEmpty(shipperInvoiceObj)) {
                setShipperInvoice(shipperInvoiceObj);
            }
            // let priceConfirmationObj = invoices.find(i => i.invoiceType === 'PRICE_CONFIRMATION' && i.status !== 'CANCELLED' && i.isDeleted === false);
            // if (isObjectNotEmpty(priceConfirmationObj)) {
            //     setPriceConfirmation(priceConfirmationObj);
            // }
            // let rateConfirmationObj = invoices.find(i => i.invoiceType === 'RATE_CONFIRMATION' && i.status !== 'CANCELLED' && i.isDeleted === false);
            // if (isObjectNotEmpty(rateConfirmationObj)) {
            //     setRateConfirmation(rateConfirmationObj);
            // }

            let ids = [];
            invoices.forEach((invoice) => {
                ids.push(invoice.id);
            });

            dispatch(actionCreators.fetchBulkNoteLists(loadId, ids));
        }
    }, [loadId, invoices]);

    useMemo(() => {
        if (isObjectNotEmpty(documentLists) && isObjectNotEmpty(load) && isListNotEmpty(stops)) {
            let visibleDocumentsList = DocumentUtils.selectListVisibleDocumentsByLoadIdAndStops(documentLists, load.id, load, stops, entityType, isAdmin);
            let pendingApprovalDocumentsList = DocumentUtils.selectListPendingApprovalDocumentsByLoadIdAndStops(documentLists, load.id, load, stops, entityType, isAdmin);
            let missingDocumentsList = DocumentUtils.selectListMissingDocumentsByLoadIdAndStops(documentLists, load.id, load, stops, entityType, isAdmin);
            let podDocumentsList = DocumentUtils.selectListProofOfDeliveryDocumentsByStops(documentLists, load.id, stops, entityType, isAdmin);

            let carrierDocuments = isStringNotEmpty(load.assignedCarrierId) && isNotNullOrUndefined(documentLists[load.assignedCarrierId]) && isListNotEmpty(documentLists[load.assignedCarrierId].records) ? [...documentLists[load.assignedCarrierId].records] : null;
            let visibleCarrierDocuments = DocumentUtils.getVisibleDocuments(carrierDocuments, entityType, isAdmin);
            let bankAccountDocumentObj = visibleCarrierDocuments.find(doc => doc.documentType === 'BANK_INFORMATION' && doc.isDeleted === false);
            if (isObjectNotEmpty(bankAccountDocumentObj)) {
                setBankAccountDocument(bankAccountDocumentObj);
            } else {
                setBankAccountDocument({});
            }
            let factoringNOADocumentObj = visibleCarrierDocuments.find(doc => doc.documentType === 'FACTORING_NOA' && doc.isDeleted === false);
            if (isObjectNotEmpty(factoringNOADocumentObj)) {
                setFactoringNOADocument(factoringNOADocumentObj);
            } else {
                setFactoringNOADocument({});
            }

            let carrierInvoiceDocumentObj = visibleDocumentsList.find(doc => doc.documentType === 'CARRIER_INVOICE' && doc.isDeleted === false);
            if (isObjectNotEmpty(carrierInvoiceDocumentObj)) {
                setCarrierInvoiceDocument(carrierInvoiceDocumentObj);
            } else {
                setCarrierInvoiceDocument({});
            }
            let shipperInvoiceDocumentObj = visibleDocumentsList.find(doc => doc.documentType === 'SHIPPER_INVOICE' && doc.isDeleted === false);
            if (isObjectNotEmpty(shipperInvoiceDocumentObj)) {
                setShipperInvoiceDocument(shipperInvoiceDocumentObj);
            } else {
                setShipperInvoiceDocument({});
            }
            let factoringInvoiceDocumentObj = visibleDocumentsList.find(doc => doc.documentType === 'FACTORING_INVOICE' && doc.isDeleted === false);
            if (isObjectNotEmpty(factoringInvoiceDocumentObj)) {
                setFactoringInvoiceDocument(factoringInvoiceDocumentObj);
            } else {
                setFactoringInvoiceDocument({});
            }

            setHasUnreviewedDocuments(pendingApprovalDocumentsList.length > 0);
            setHasMissingDocuments(missingDocumentsList.length > 0);
            setVisibleDocuments(visibleDocumentsList);
            setPendingApprovalDocuments(pendingApprovalDocumentsList);
            setMissingDocuments(missingDocumentsList);

            setProofOfDeliveryDocuments(podDocumentsList);

            if (isListEmpty(podDocumentsList) || (isListNotEmpty(podDocumentsList) && podDocumentsList.find(d => d.status === 'MISSING'))) {
                setHasMissingProofOfDelivery(true);
            } else {
                setHasMissingProofOfDelivery(false);
            }

            if (isListEmpty(podDocumentsList) || (isListNotEmpty(podDocumentsList) && podDocumentsList.find(d => d.status === 'PENDING'))) {
                setHasPendingProofOfDelivery(true);
            } else {
                setHasPendingProofOfDelivery(false);
            }
        }
    }, [documentLists, load, stops, entityType, isAdmin]);

    useMemo(() => {
        if (isObjectNotEmpty(load) && isObjectNotEmpty(load.completionCheckList)) {
            if (isBooleanFalse(load.completionCheckList.hasBalance) && isBooleanFalse(load.completionCheckList.hasOpenClaims) && isBooleanTrue(load.completionCheckList.hasDocumentsReviewed) && isBooleanFalse(hasUnreviewedDocuments) && isBooleanFalse(hasMissingDocuments) && isBooleanFalse(hasMissingProofOfDelivery) && load.completionCheckList.carrierInvoiceStatus === 'COMPLETED' && ((load.serviceType === 'BROKERAGE' && load.completionCheckList.shipperInvoiceStatus === 'COMPLETED') || load.serviceType === 'TMS') && isBooleanFalse(load.completionCheckList.hasPendingInvoiceLineItems) && isBooleanFalse(load.completionCheckList.hasPendingTransactions)) {
                setIsChecklistCompleted(true);
            } else {
                setIsChecklistCompleted(false);
            }
        } else {
            setIsChecklistCompleted(false);
        }
    }, [load, hasUnreviewedDocuments, hasMissingDocuments, hasMissingProofOfDelivery]);

    useMemo(() => {
        if (isObjectNotEmpty(documentLists) && isObjectNotEmpty(load) && isStringNotEmpty(entityType) && isStringNotEmpty(serviceType)) {
            let visibleDocumentsList = [];
            let shipperInvoiceDocument = { name: 'Shipper Invoice', load: load, type: 'SHIPPER_INVOICE', visibleTo: serviceType === 'TMS' ? 'ALL' : 'SHIPPER' };
            let carrierInvoiceDocument = { name: 'Carrier Invoice', load: load, type: 'CARRIER_INVOICE', visibleTo: serviceType === 'TMS' ? 'ALL' : 'CARRIER' };

            let loadDocuments = isObjectNotEmpty(documentLists[load.id]) && isNotNullOrUndefined(documentLists[load.id].records) ? [...documentLists[load.id].records] : null;
            let visibleLoadDocuments = DocumentUtils.getVisibleDocuments(loadDocuments, entityType, isAdmin);

            if (isListNotEmpty(visibleLoadDocuments)) {
                visibleLoadDocuments.forEach((visibleDocument) => {
                    visibleDocumentsList.push({ ...visibleDocument, entitySubType: null, entityName: isStringNotEmpty(load.parentName) ? load.parentName : (isStringNotEmpty(load.name) ? load.name : load.irisId), entity: load });
                });
            }

            let carrierInvoiceDocumentObj = visibleLoadDocuments.find(doc => doc.documentType === 'CARRIER_INVOICE' && doc.isDeleted === false);
            if (isObjectNotEmpty(carrierInvoiceDocumentObj)) {
                carrierInvoiceDocument.document = carrierInvoiceDocumentObj;
            }
            let carrierInvoice = invoices.find(i => i.invoiceType === 'CARRIER_INVOICE' && i.status !== 'COMPLETED' && i.status !== 'CANCELLED' && i.isDeleted === false);
            if (isObjectNotEmpty(carrierInvoice)) {
                carrierInvoiceDocument.invoice = carrierInvoice;
            }

            let shipperInvoice = invoices.find(i => i.invoiceType === 'SHIPPER_INVOICE' && i.status !== 'COMPLETED' && i.status !== 'CANCELLED' && i.isDeleted === false);
            if (isObjectNotEmpty(shipperInvoice)) {
                shipperInvoiceDocument.invoice = shipperInvoice;
            }

            let invoiceArray = [];
            if (LoadUtils.loadStatusIs(currentStatus, ["COMPLETED", "REOPENED", "CLOSED"])) {
                // shipper invoice is only used for brokered loads
                if (serviceType === 'BROKERAGE' && (entityType === 'STAFF' || entityType === 'SHIPPER')) {
                    invoiceArray.push(shipperInvoiceDocument);
                }
                if (entityType === 'STAFF' || (serviceType === 'TMS' && entityType === 'SHIPPER') || entityType === 'CARRIER') {
                    invoiceArray.push(carrierInvoiceDocument);
                }
            }

            setInvoiceList(invoiceArray);
        }
    }, [documentLists, invoices, load, entityType, isAdmin, serviceType, currentStatus, loadId]);

    useMemo(() => {
        if (isObjectNotEmpty(documentLists) && isObjectNotEmpty(load) && isStringNotEmpty(entityType) && isStringNotEmpty(serviceType)) {
            let visibleDocumentsList = [];
            let priceConfirmationDocument = { name: 'Load Tender', load: load, type: 'PRICE_CONFIRMATION', visibleTo: serviceType === 'TMS' ? 'ALL' : 'SHIPPER' };
            let rateConfirmationDocument = { name: 'Rate Confirmation', load: load, type: 'RATE_CONFIRMATION', visibleTo: serviceType === 'TMS' ? 'ALL' : 'CARRIER' };

            let loadDocuments = isObjectNotEmpty(documentLists[load.id]) && isNotNullOrUndefined(documentLists[load.id].records) ? [...documentLists[load.id].records] : null;
            let visibleLoadDocuments = DocumentUtils.getVisibleDocuments(loadDocuments, entityType, isAdmin);

            if (isListNotEmpty(visibleLoadDocuments)) {
                visibleLoadDocuments.forEach((visibleDocument) => {
                    visibleDocumentsList.push({ ...visibleDocument, entitySubType: null, entityName: isStringNotEmpty(load.parentName) ? load.parentName : (isStringNotEmpty(load.name) ? load.name : load.irisId), entity: load });
                });
            }

            let priceConfirmationDocumentObj = visibleLoadDocuments.find(doc => doc.documentType === 'PRICE_CONFIRMATION' && doc.isDeleted === false);
            if (isObjectNotEmpty(priceConfirmationDocumentObj)) {
                priceConfirmationDocument.document = priceConfirmationDocumentObj;
            }
            let priceConfirmationInvoice = invoices.find(i => i.invoiceType === 'PRICE_CONFIRMATION' && i.isDeleted === false);
            if (isObjectNotEmpty(priceConfirmationInvoice)) {
                priceConfirmationDocument.invoice = priceConfirmationInvoice;
            }

            let rateConfirmationDocumentObj = visibleLoadDocuments.find(doc => doc.documentType === 'RATE_CONFIRMATION' && doc.isDeleted === false);
            if (isObjectNotEmpty(rateConfirmationDocumentObj)) {
                rateConfirmationDocument.document = rateConfirmationDocumentObj;
            }
            let rateConfirmationInvoice = invoices.find(i => i.invoiceType === 'RATE_CONFIRMATION' && i.isDeleted === false);
            if (isObjectNotEmpty(rateConfirmationInvoice)) {
                rateConfirmationDocument.invoice = rateConfirmationInvoice;
            }

            let quoteArray = [];
            // load tender is only used for brokered loads
            if (serviceType === 'BROKERAGE' && (entityType === 'STAFF' || entityType === 'SHIPPER')) {
                setPriceConfirmation(priceConfirmationDocument);
                quoteArray.push(priceConfirmationDocument);
            }
            if ((entityType === 'STAFF' || (serviceType === 'TMS' && entityType === 'SHIPPER') || entityType === 'CARRIER') && isStringNotEmpty(load.assignedCarrierId)) {
                setRateConfirmation(rateConfirmationDocument);
                quoteArray.push(rateConfirmationDocument);
            }

            setQuoteList(quoteArray);
        }
    }, [documentLists, invoices, load, entityType, isAdmin, serviceType]);

    useMemo(() => {
        if (isObjectNotEmpty(load) && isListNotEmpty(stops) && (entityType === 'STAFF' || (entityType === 'SHIPPER' && load.serviceType === 'TMS' && load.createdByEntityType === 'SHIPPER') || (entityType === 'CARRIER' && load.serviceType === 'TMS' && load.createdByEntityType === 'CARRIER'))) {
            let missingPedigreeInformationList = LoadUtils.getMissingPedigreeInformation(load, stops);
            setMissingPedigreeInformation(missingPedigreeInformationList);
        }
    }, [load, stops, entityType]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    const formItemLayoutDouble = {
        labelCol1: { span: 24 },
        wrapperCol1: { span: 16 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 8 },
    };

    //#endregion
    //#region document displays

    const podButton = () => {
        const claimsCount = claims.length;
        const openClaimsCount = claims.filter(c => c.claimStatus !== 'CLOSED').length;
        if (isObjectNotEmpty(load)) {
            if (isObjectNotEmpty(load.completionCheckList) && isStringNotEmpty(load.completionCheckList.proofOfDeliveryStatus)) {
                if (load.completionCheckList.proofOfDeliveryStatus === 'CLEAN') {
                    return (<Button type="default" onClick={() => { toggleViewPOD(); }}>View POD</Button>);
                } else if (load.completionCheckList.proofOfDeliveryStatus === 'OSDR') {
                    if (claimsCount > 0 && openClaimsCount > 0) {
                        return (<><Button type="default" style={{ marginRight: 8 }} onClick={() => { toggleViewPOD(); }}>View POD</Button><Button type="default" onClick={() => { toggleClaimsModal(); }}>Manage Claim(s)</Button></>);
                    } else if (claimsCount > 0) {
                        return (<><Button type="default" style={{ marginRight: 8 }} onClick={() => { toggleViewPOD(); }}>View POD</Button><Button type="default" onClick={() => { toggleClaimsModal(); }}>View Claim(s)</Button></>);
                    } else {
                        return (<><Button type="default" style={{ marginRight: 8 }} onClick={() => { toggleViewPOD(); }}>View POD</Button><Button type="default" onClick={() => { toggleClaimsModal(); }}>File Claim(s)</Button></>);
                    }
                } else if (load.completionCheckList.proofOfDeliveryStatus === 'UPLOADED') {
                    return (<Button type="default" onClick={() => { toggleViewPOD(); }}>Review POD</Button>);
                }
            }
        }

        if (entityType === 'STAFF' || entityType === 'SHIPPER' || entityType === 'BROKER') {
            return (<><Button type="default" style={{ marginRight: 8 }} onClick={() => { toggleViewPOD(); }}>Upload POD</Button><Button type="default" onClick={() => { toggleViewPOD(); }}>Request from Carrier</Button></>);
        } else {
            return (<Button type="default" onClick={() => { toggleViewPOD(); }}>Upload POD</Button>);
        }
    };

    const podStyle = () => {
        const claimsCount = claims.length;
        const openClaimsCount = claims.filter(c => c.claimStatus !== 'CLOSED').length;
        if (isObjectNotEmpty(load)) {
            if (isObjectNotEmpty(load.completionCheckList) && isStringNotEmpty(load.completionCheckList.proofOfDeliveryStatus)) {
                if (load.completionCheckList.proofOfDeliveryStatus === 'CLEAN') {
                    return completedEntityStyle;
                } else if (load.completionCheckList.proofOfDeliveryStatus === 'OSDR') {
                    if (claimsCount > 0 && openClaimsCount > 0) {
                        return errorEntityStyle;
                    } else if (claimsCount > 0) {
                        return completedEntityStyle;
                    } else {
                        return errorEntityStyle;
                    }

                } else if (load.completionCheckList.proofOfDeliveryStatus === 'UPLOADED') {
                    return pendingEntityStyle;
                }
            }
        }

        return missingEntityStyle;
    };

    const podStatus = () => {
        const claimsCount = claims.length;
        const openClaimsCount = claims.filter(c => c.claimStatus !== 'CLOSED').length;
        if (isObjectNotEmpty(load)) {
            if (isObjectNotEmpty(load.completionCheckList) && isStringNotEmpty(load.completionCheckList.proofOfDeliveryStatus)) {
                if (load.completionCheckList.proofOfDeliveryStatus === 'CLEAN') {
                    return 'Clean';
                } else if (load.completionCheckList.proofOfDeliveryStatus === 'OSDR') {
                    if (claimsCount > 0 && openClaimsCount > 0) {
                        return 'OSDR (Unresolved)';
                    } else if (claimsCount > 0) {
                        return 'OSDR (Resolved)';
                    } else {
                        return 'OSDR (Unresolved)';
                    }

                } else if (load.completionCheckList.proofOfDeliveryStatus === 'UPLOADED') {
                    return 'Pending Review';
                }
            }
        }

        return 'Missing';
    };

    const createMissingDocumentList = () => {
        if (isListNotEmpty(missingDocuments) && isLoadingDocuments === false) {
            return missingDocuments.map((missingDocument, index, arr) => {
                return (
                    <Alert
                        key={`missing-document-${index}`}
                        style={{ marginBottom: 8 }}
                        type="error"
                        message={`${missingDocument.entityName} is missing ${missingDocument.documentDisplayName}`}
                        showIcon={true}
                        action={<Space><Button size="small" type="ghost" onClick={(e) => { setSelectedDocument(missingDocument); toggleEditMissingDocument(); }}>Upload</Button></Space>}
                    />
                );
            });
        } else {
            return null;
        }
    };

    const createPendingApprovalDocumentList = () => {
        if (isListNotEmpty(pendingApprovalDocuments) && isLoadingDocuments === false) {
            return pendingApprovalDocuments.map((document, index, arr) => {
                return (
                    <Alert
                        key={`pending-approval-document-${index}`}
                        style={{ marginBottom: 8 }}
                        type="warning"
                        message={`${document.documentDisplayName}${DocumentUtils.reviewableDocumentTypes.includes(document.documentType) ? ', Review Status: ' + stringFormatter.toFormattedString('DocumentReviewStatus', document.reviewStatus) : ''}`}
                        showIcon={true}
                        action={<Space><Button size="small" type="ghost" onClick={(e) => { setSelectedDocument(document); toggleReviewDocument(); }}>Review</Button></Space>}
                    />
                );
            });
        } else {
            return null;
        }
    };

    const createVisibleDocumentList = () => {
        if (isListNotEmpty(visibleDocuments) && isLoadingDocuments === false) {
            return visibleDocuments.filter(doc => doc.documentType !== 'CARRIER_INVOICE' && doc.documentType !== 'SHIPPER_INVOICE' && doc.documentType !== 'FACTORING_INVOICE' && doc.documentType !== 'INVOICE').map((document, index, arr) => {
                if (document.reviewStatus !== 'APPROVED' && document.reviewStatus !== 'CLEAN' && document.reviewStatus !== 'OSDR' && DocumentUtils.reviewableDocumentTypes.includes(document.documentType) && isBooleanFalse(document.isReviewed) && isBooleanFalse(document.isExpired) && isBooleanFalse(document.isDeleted)) {
                    return (
                        <Alert
                            key={`pending-approval-document-${index}`}
                            style={{ marginBottom: 8 }}
                            type="warning"
                            message={`${document.documentDisplayName}${DocumentUtils.reviewableDocumentTypes.includes(document.documentType) ? ', Review Status: ' + stringFormatter.toFormattedString('DocumentReviewStatus', document.reviewStatus) : ''}`}
                            showIcon={true}
                            action={<Space><Button size="small" type="ghost" onClick={(e) => { setSelectedDocument(document); toggleReviewDocument(); }}>Review</Button></Space>}
                        />
                    );
                } else {
                    return (
                        <Alert
                            key={`visible-document-${index}`}
                            style={{ marginBottom: 8 }}
                            type="info"
                            message={`${document.documentDisplayName}${DocumentUtils.reviewableDocumentTypes.includes(document.documentType) ? ', Review Status: ' + stringFormatter.toFormattedString('DocumentReviewStatus', document.reviewStatus) : ''}`}
                            showIcon={true}
                            action={<Space><Button size="small" type="ghost" onClick={(e) => { setSelectedDocument(document); toggleViewDocument(); }}>View</Button></Space>}
                        />
                    );
                }
            });
        } else {
            return null;
        }
    };

    const createMissingPODList = () => {
        if (isListNotEmpty(proofOfDeliveryDocuments)) {
            return proofOfDeliveryDocuments.filter(d => d.status === 'MISSING').map((missingDocument, index, arr) => {
                return (
                    <Alert
                        key={`missing-document-${index}`}
                        style={{ marginBottom: 8 }}
                        type="error"
                        message={`${missingDocument.entityName} is missing ${missingDocument.documentDisplayName}`}
                        showIcon={true}
                        action={<Space><Button size="small" type="ghost" onClick={(e) => { setSelectedDocument(missingDocument); toggleEditMissingDocument(); }}>Upload</Button></Space>}
                    />
                );
            });
        } else {
            return null;
        }
    };

    const createPendingApprovalPODList = () => {
        if (isListNotEmpty(proofOfDeliveryDocuments)) {
            return proofOfDeliveryDocuments.filter(d => d.status === 'PENDING_APPROVAL').map((document, index, arr) => {
                return (
                    <Alert
                        key={`pending-approval-document-${index}`}
                        style={{ marginBottom: 8 }}
                        type="warning"
                        message={`${document.documentDisplayName}${DocumentUtils.reviewableDocumentTypes.includes(document.documentType) ? ', Review Status: ' + stringFormatter.toFormattedString('DocumentReviewStatus', document.reviewStatus) : ''}`}
                        showIcon={true}
                        action={<Space><Button size="small" type="ghost" onClick={(e) => { setSelectedDocument(document); toggleReviewDocument(); }}>Review</Button></Space>}
                    />
                );
            });
        } else {
            return null;
        }
    };

    const createVisiblePODList = () => {
        if (isListNotEmpty(proofOfDeliveryDocuments)) {
            return proofOfDeliveryDocuments.filter(d => d.status !== 'MISSING' && d.status !== 'PENDING_APPROVAL').map((document, index, arr) => {
                return (
                    <Alert
                        key={`visible-document-${index}`}
                        style={{ marginBottom: 8 }}
                        type="info"
                        message={`${document.documentDisplayName}${DocumentUtils.reviewableDocumentTypes.includes(document.documentType) ? ', Review Status: ' + stringFormatter.toFormattedString('DocumentReviewStatus', document.reviewStatus) : ''}`}
                        showIcon={true}
                        action={<Space><Button size="small" type="ghost" onClick={(e) => { setSelectedDocument(document); toggleViewDocument(); }}>View</Button></Space>}
                    />
                );
            });
        } else {
            return null;
        }
    };

    const editMissingDocumentDrawer = (
        <Drawer
            title={isObjectNotEmpty(selectedDocument) ? `Upload ${selectedDocument.documentDisplayName}` : ""}
            onClose={toggleEditMissingDocument}
            visible={showEditMissingDocument === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1010 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditMissingDocument
                cancel={toggleEditMissingDocument}
                missingDocument={selectedDocument}
                load={load}
                loadId={loadId}
            />
        </Drawer>
    );

    const reviewDocumentDrawer = (
        <Drawer
            title={isObjectNotEmpty(selectedDocument) ? `Review ${selectedDocument.documentDisplayName}` : ""}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleReviewDocument}
            visible={showReviewDocument === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1010 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <ReviewDocument
                cancel={toggleReviewDocument}
                document={selectedDocument}
                documentType={isObjectNotEmpty(selectedDocument) && isStringNotEmpty(selectedDocument.documentType) ? selectedDocument.documentType : null}
                documentEntitySubType={isObjectNotEmpty(selectedDocument) && isStringNotEmpty(selectedDocument.entitySubType) ? selectedDocument.entitySubType : null}
                loadId={isObjectNotEmpty(load) ? load.id : null}
                fileId={isObjectNotEmpty(selectedDocument) && isStringNotEmpty(selectedDocument.id) ? selectedDocument.id : null}
            />
        </Drawer>
    );

    const viewDocumentDrawer = (
        <Drawer
            title={isObjectNotEmpty(selectedDocument) && isStringNotEmpty(selectedDocument.documentDisplayName) ? selectedDocument.documentDisplayName : ""}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleViewDocument}
            visible={showViewDocument === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1010 }}
            destroyOnClose={true}
            closable={true}
            maskClosable={false}
        >
            <Document
                displayName={isObjectNotEmpty(selectedDocument) && isStringNotEmpty(selectedDocument.documentDisplayName) ? selectedDocument.documentDisplayName : null}
                fileId={isObjectNotEmpty(selectedDocument) && isStringNotEmpty(selectedDocument.id) ? selectedDocument.id : null}
            />
        </Drawer>
    );

    const missingDocumentsModal = (
        <Modal
            title="Missing Documents"
            visible={showMissingDocuments === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={toggleMissingDocuments}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            {createMissingDocumentList()}
        </Modal>
    );

    const reviewDocumentsModal = (
        <Modal
            title="Documents Pending Review"
            visible={showReviewDocuments === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={toggleReviewDocuments}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            {createPendingApprovalDocumentList()}
        </Modal>
    );

    const viewDocumentsModal = (
        <Modal
            title="Document Review"
            visible={showViewDocuments === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={toggleViewDocuments}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <div>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoadingDocuments === true}>
                    <Header
                        title="Document Review"
                        titleStyle={{ paddingLeft: 0, color: '#000000', fontWeight: 500 }}
                        style={{ paddingLeft: 0, paddingRight: 0 }}
                        icon={<DeliveredProcedureOutlined />}
                        iconStyle={{ background: 'transparent' }}
                        title2={`Please review the documents below and upload any missing BOLs, receipts, etc. If there are any documents pending review, please review and approve them.`}
                        title2Style={{ whiteSpace: 'normal' }}
                    />
                    {createMissingDocumentList()}
                    {createVisibleDocumentList()}
                    <div style={{ marginTop: 24, textAlign: 'right' }}>
                        <Button type="primary" onClick={() => { markDocumentsReviewed(); }} disabled={missingDocuments.length > 0 || pendingApprovalDocuments.length > 0 || hasUnreviewedDocuments === true}>All Documents are Reviewed</Button>
                    </div>
                </Spin>
            </div>
        </Modal>
    );

    const missingPODModal = (
        <Modal
            title="Missing Proof of Delivery"
            visible={showMissingPOD === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={toggleMissingPOD}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            {createMissingPODList()}
        </Modal>
    );

    const reviewPODModal = (
        <Modal
            title="Documents Pending Review"
            visible={showReviewPOD === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={toggleReviewPOD}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            {createPendingApprovalPODList()}
        </Modal>
    );

    const viewPODModal = (
        <Modal
            title="Proof of Delivery"
            visible={showViewPOD === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={toggleViewPOD}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <div>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoadingDocuments === true}>
                    <Header
                        title="Proof of Delivery Review"
                        titleStyle={{ paddingLeft: 0, color: '#000000', fontWeight: 500 }}
                        style={{ paddingLeft: 0, paddingRight: 0 }}
                        icon={<DeliveredProcedureOutlined />}
                        iconStyle={{ background: 'transparent' }}
                        title2={`Please review the proof of delivery below and upload any missing BOLs. If there are any documents pending review, please review and approve them.`}
                        title2Style={{ whiteSpace: 'normal' }}
                    />
                    {createMissingPODList()}
                    {createVisiblePODList()}
                    {createPendingApprovalPODList()}
                    <div style={{ marginTop: 24, textAlign: 'right' }}>
                        <Button type="primary" danger onClick={() => { toggleViewPOD(); }}>Cancel</Button>
                        <Button type="primary" style={{ marginLeft: 8 }} onClick={() => { markPODDone(); }} disabled={proofOfDeliveryDocuments.filter(d => d.status === 'MISSING').length > 0 || proofOfDeliveryDocuments.filter(d => d.status === 'PENDING_APPROVAL').length > 0}>Submit</Button>
                    </div>
                </Spin>
            </div>
        </Modal>
    );

    const proofOfDeliveryMenu = () => {
        if (isListNotEmpty(proofOfDeliveryDocuments)) {
            return (
                <Menu>
                    {proofOfDeliveryDocuments.filter(d => d.status === 'MISSING').length > 0 ? (
                        <Menu.Item key="missingPOD" onClick={(e) => { e.domEvent.stopPropagation(); toggleMissingPOD(); }}>
                            <span>Upload Proof of Delivery</span>
                        </Menu.Item>
                    ) : null}
                    {proofOfDeliveryDocuments.filter(d => d.status === 'PENDING_APPROVAL').length > 0 ? (
                        <Menu.Item key="pendingApprovalPOD" onClick={(e) => { e.domEvent.stopPropagation(); toggleReviewPOD(); }}>
                            <span>Review Proof of Delivery</span>
                        </Menu.Item>
                    ) : null}
                    {proofOfDeliveryDocuments.filter(d => d.status !== 'MISSING').length > 0 ? (
                        <Menu.Item key="visiblePOD" onClick={(e) => { e.domEvent.stopPropagation(); toggleViewPOD(); }}>
                            <span>View Proof of Delivery</span>
                        </Menu.Item>
                    ) : null}
                </Menu>
            );
        } else {
            return null;
        }
    };

    const documentsMenu = () => {
        return (
            <Menu>
                {isListNotEmpty(missingDocuments) ? (
                    <Menu.Item key="missingDocuments" onClick={(e) => { e.domEvent.stopPropagation(); toggleMissingDocuments(); }}>
                        <span>Upload Missing Required Load Documents</span>
                    </Menu.Item>
                ) : null}
                {isListNotEmpty(pendingApprovalDocuments) ? (
                    <Menu.Item key="pendingApprovalDocuments" onClick={(e) => { e.domEvent.stopPropagation(); toggleReviewDocuments(); }}>
                        <span>Review Load Documents</span>
                    </Menu.Item>
                ) : null}
                {isListNotEmpty(visibleDocuments) ? (
                    <Menu.Item key="visibleDocuments" onClick={(e) => { e.domEvent.stopPropagation(); toggleViewDocuments(); }}>
                        <span>View Load Documents</span>
                    </Menu.Item>
                ) : null}
            </Menu>
        );
    };

    const bankAccountDocumentComponents = () => {
        if (isObjectNotEmpty(carrierInvoice) && carrierInvoice.paymentMethod === 'DIRECT_DEPOSIT' && load.serviceType === 'BROKERAGE') {
            if (isObjectNotEmpty(bankAccountDocument)) {
                return (
                    <FormItemDisplay {...formItemLayout} label="Carrier chose Direct Deposit as their preferred payment method." noColon format="vertical">
                        <DataLinkRow title="Bank Account Information" fileId={bankAccountDocument.id} />
                    </FormItemDisplay>
                );
            } else {
                return (
                    <FormItemDisplay {...formItemLayout} label="Carrier chose Direct Deposit as their preferred payment method. If we don't already have their bank account information, please provide us with their bank account information." noColon format="vertical">
                        <Button type="default" onClick={(e) => { setSelectedDocument({ entityType: 'ACCOUNT', entityId: carrierAccount.id, entity: carrierAccount, entitySubType: null, entityName: carrierAccount.name, documentType: 'BANK_INFORMATION', documentTypeName: 'Bank Account Information', documentDisplayName: 'Bank Account Information', visibleTo: 'STAFF' }); toggleEditMissingDocument(); }}>Upload Bank Account Information</Button>
                    </FormItemDisplay>
                );
            }
        } else {
            return null;
        }
    };

    const factoringNOADocumentComponents = () => {
        if (isObjectNotEmpty(carrierInvoice) && carrierInvoice.paymentMethod === 'FACTORING') {
            if (isObjectNotEmpty(factoringNOADocument)) {
                return (
                    <FormItemDisplay {...formItemLayout} label="Carrier chose Factoring as their preferred payment method." noColon format="vertical">
                        <DataLinkRow title="Factoring Notice of Assignment" fileId={factoringNOADocument.id} />
                    </FormItemDisplay>
                );
            } else {
                return (
                    <FormItemDisplay {...formItemLayout} label="Carrier chose Direct Deposit as their preferred payment method. If we don't already have the Notice of Assignment for your factoring company, please provide us with the Notice of Assignment." noColon format="vertical">
                        <Button type="default" onClick={(e) => { setSelectedDocument({ entityType: 'ACCOUNT', entityId: carrierAccount.id, entity: carrierAccount, entitySubType: null, entityName: carrierAccount.name, documentType: 'FACTORING_NOA', documentTypeName: 'Factoring Notice of Assignment', documentDisplayName: 'Factoring Notice of Assignment', visibleTo: 'STAFF' }); toggleEditMissingDocument(); }}>Upload Factoring Notice of Assignment</Button>
                    </FormItemDisplay>
                );
            }
        } else {
            return null;
        }
    };

    const carrierInvoiceDocumentComponents = () => {
        if (isObjectNotEmpty(carrierInvoiceDocument)) {
            return (
                <FormItemDisplay {...formItemLayout} label="Carrier uploaded their own invoice." noColon format="vertical">
                    <DataLinkRow title="Carrier Invoice" fileId={carrierInvoiceDocument.id} />
                </FormItemDisplay>
            );
        } else {
            return (
                <FormItemDisplay {...formItemLayout} label="If the Carrier has their own invoice, upload it here." noColon format="vertical">
                    <Button size="small" type="ghost" onClick={(e) => { setSelectedDocument({ entityType: 'LOAD', entityId: load.id, entity: load, entitySubType: null, entityName: isStringNotEmpty(load.parentName) ? load.parentName : (isStringNotEmpty(load.name) ? load.name : load.irisId), documentType: 'CARRIER_INVOICE', documentTypeName: 'Carrier Invoice', documentDisplayName: 'Carrier Invoice', visibleTo: 'CARRIER' }); toggleEditMissingDocument(); }}>Upload Carrier Invoice</Button>
                </FormItemDisplay>
            );
        }
    };

    const shipperInvoiceDocumentComponents = () => {
        if (isObjectNotEmpty(shipperInvoiceDocument)) {
            return (
                <FormItemDisplay {...formItemLayout} label="Shipper uploaded their own invoice." noColon format="vertical">
                    <DataLinkRow title="Shipper Invoice" fileId={shipperInvoiceDocument.id} />
                </FormItemDisplay>
            );
        } else {
            return (
                <FormItemDisplay {...formItemLayout} label="If the Shipper has their own invoice, upload it here." noColon format="vertical">
                    <Button size="small" type="ghost" onClick={(e) => { setSelectedDocument({ entityType: 'LOAD', entityId: load.id, entity: load, entitySubType: null, entityName: isStringNotEmpty(load.parentName) ? load.parentName : (isStringNotEmpty(load.name) ? load.name : load.irisId), documentType: 'SHIPPER_INVOICE', documentTypeName: 'Shipper Invoice', documentDisplayName: 'Shipper Invoice', visibleTo: 'SHIPPER' }); toggleEditMissingDocument(); }}>Upload Shipper Invoice</Button>
                </FormItemDisplay>
            );
        }
    };

    const factoringInvoiceDocumentComponents = () => {
        if (isObjectNotEmpty(factoringInvoiceDocument)) {
            return (
                <FormItemDisplay {...formItemLayout} label="Factoring Invoice has been uploaded." noColon format="vertical">
                    <DataLinkRow title="Factoring Invoice" fileId={factoringInvoiceDocument.id} />
                </FormItemDisplay>
            );
        } else {
            return (
                <FormItemDisplay {...formItemLayout} label="If the Carrier chose Factoring as their Payment Method and the factoring company provided an invoice, upload it here." noColon format="vertical">
                    <Button size="small" type="ghost" onClick={(e) => { setSelectedDocument({ entityType: 'LOAD', entityId: load.id, entity: load, entitySubType: null, entityName: isStringNotEmpty(load.parentName) ? load.parentName : (isStringNotEmpty(load.name) ? load.name : load.irisId), documentType: 'FACTORING_INVOICE', documentTypeName: 'Factoring Invoice', documentDisplayName: 'Factoring Invoice', visibleTo: 'STAFF' }); toggleEditMissingDocument(); }}>Upload Factoring Invoice</Button>
                </FormItemDisplay>
            );
        }
    };

    const getInvoiceDocuments = () => {
        if (isListNotEmpty(visibleDocuments) && isLoadingDocuments === false) {
            return (
                <Row>
                    <Col span={24}>
                        <h3 style={{ paddingLeft: 10, paddingRight: 10 }}>Attached Documents:</h3>

                        <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                            {visibleDocuments.filter(doc => doc.documentType !== 'CARRIER_INVOICE' && doc.documentType !== 'SHIPPER_INVOICE' && doc.documentType !== 'FACTORING_INVOICE' && doc.documentType !== 'INVOICE').map((document, index, arr) => {
                                return (
                                    <DataLinkRow key={`visible-invoice-document-${index}`} style={{ marginBottom: 8 }} title={document.documentDisplayName} fileId={document.id} />
                                );
                            })}
                        </div>
                    </Col>
                </Row>
            );
        } else {
            return null;
        }
    };

    const getCarrierInvoiceDocuments = () => {
        if (isListNotEmpty(visibleDocuments) && isLoadingDocuments === false) {
            return (
                <Row>
                    <Col span={24}>
                        <h3 style={{ paddingLeft: 10, paddingRight: 10 }}>Attached Documents:</h3>

                        <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                            {visibleDocuments.filter(doc => doc.documentType !== 'PRICE_CONFIRMATION' && doc.documentType !== 'CARRIER_INVOICE' && doc.documentType !== 'SHIPPER_INVOICE' && doc.documentType !== 'FACTORING_INVOICE' && doc.documentType !== 'INVOICE').map((document, index, arr) => {
                                return (
                                    <DataLinkRow key={`visible-invoice-document-${index}`} style={{ marginBottom: 8 }} title={document.documentDisplayName} fileId={document.id} />
                                );
                            })}
                        </div>
                    </Col>
                </Row>
            );
        } else {
            return null;
        }
    };

    const getShipperInvoiceDocuments = () => {
        if (isListNotEmpty(visibleDocuments) && isLoadingDocuments === false) {
            return (
                <Row>
                    <Col span={24}>
                        <h3 style={{ paddingLeft: 10, paddingRight: 10 }}>Attached Documents:</h3>

                        <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                            {visibleDocuments.filter(doc => doc.documentType !== 'RATE_CONFIRMATION' && doc.documentType !== 'CARRIER_INVOICE' && doc.documentType !== 'SHIPPER_INVOICE' && doc.documentType !== 'FACTORING_INVOICE' && doc.documentType !== 'INVOICE').map((document, index, arr) => {
                                return (
                                    <DataLinkRow key={`visible-invoice-document-${index}`} style={{ marginBottom: 8 }} title={document.documentDisplayName} fileId={document.id} />
                                );
                            })}
                        </div>
                    </Col>
                </Row>
            );
        } else {
            return null;
        }
    };

    //#endregion
    //#region invoice line item displays

    const invoiceLineItemModal = (
        <Modal
            title={'Manage Invoice Line Items'}
            visible={showInvoiceLineItemModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={() => { toggleInvoiceLineItemModal(); }}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <CanSee entityModel='LOAD' entityObject={load} entityTypes={['SHIPPER', 'STAFF']}>
                <DataTable
                    dataSource={invoiceLineItems.filter(i => (i.fromEntityType === 'SHIPPER' || i.toEntityType === 'SHIPPER'))}
                    columns={invoiceLineItemColumns}
                    hidePaging={true}
                    rowClassName={getInvoiceLineItemRowClassName}
                    title={() => (invoiceLineItemTitle('Shipper', serviceType === 'TMS' ? 'CARRIER' : 'STAFF', 'SHIPPER'))}
                    style={{ backgroundColor: '#ffffff', marginBottom: 24 }}
                    rowKey={record => record.id}
                    scroll={{ x: '100%' }}
                />
            </CanSee>
            <CanSee entityModel='LOAD' entityObject={load} entityTypes={['CARRIER', 'STAFF']}>
                <DataTable
                    dataSource={invoiceLineItems.filter(i => (i.fromEntityType === 'CARRIER' || i.toEntityType === 'CARRIER'))}
                    columns={invoiceLineItemColumns}
                    hidePaging={true}
                    rowClassName={getInvoiceLineItemRowClassName}
                    title={() => (invoiceLineItemTitle('Carrier', 'CARRIER', serviceType === 'TMS' ? 'SHIPPER' : 'STAFF'))}
                    style={{ backgroundColor: '#ffffff', marginBottom: 24 }}
                    rowKey={record => record.id}
                    scroll={{ x: '100%' }}
                />
            </CanSee>
            <CanSee staffOnly={true}>
                <DataTable
                    dataSource={invoiceLineItems.filter(i => (i.fromEntityType === 'FACTORING' || i.toEntityType === 'FACTORING'))}
                    columns={invoiceLineItemColumns}
                    hidePaging={true}
                    rowClassName={getInvoiceLineItemRowClassName}
                    title={() => (invoiceLineItemTitle('Axle Payments', 'STAFF', 'FACTORING'))}
                    style={{ backgroundColor: '#ffffff', marginBottom: 24 }}
                    rowKey={record => record.id}
                    scroll={{ x: '100%' }}
                />
            </CanSee>
        </Modal>
    );

    const newInvoiceLineItemDrawer = (
        <Drawer
            title={"New Invoice Line Item"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleNewInvoiceLineItem}
            visible={showNewInvoiceLineItem === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewInvoiceLineItem
                cancel={toggleNewInvoiceLineItem}
                load={load}
                stops={stops}
                shipperId={shipperId}
                carrierId={carrierId}
                fromEntityType={fromEntityType}
                toEntityType={toEntityType}
            />
        </Drawer>
    );

    const editInvoiceLineItemDrawer = (
        <Drawer
            title={"Edit Invoice Line Item"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditInvoiceLineItem}
            visible={showEditInvoiceLineItem === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditInvoiceLineItem
                cancel={toggleEditInvoiceLineItem}
                invoiceLineItem={selectedInvoiceLineItem}
                load={load}
                shipperId={shipperId}
                carrierId={carrierId}
            />
        </Drawer>
    );

    const editStatusInvoiceLineItemDrawer = (
        <Drawer
            title={"Update Status of Invoice Line Item"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditStatusInvoiceLineItem}
            visible={showEditStatusInvoiceLineItem === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditInvoiceLineItemStatus
                cancel={toggleEditStatusInvoiceLineItem}
                invoiceLineItem={selectedInvoiceLineItem}
                load={load}
            />
        </Drawer>
    );

    const cancelInvoiceLineItemDrawer = (
        <Drawer
            title={"Cancel Invoice Line Item"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleCancelInvoiceLineItem}
            visible={showCancelInvoiceLineItem === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditInvoiceLineItemStatusCancelled
                cancel={toggleCancelInvoiceLineItem}
                invoiceLineItem={selectedInvoiceLineItem}
                load={load}
            />
        </Drawer>
    );

    const editApprovalStatusInvoiceLineItemDrawer = (
        <Drawer
            title={"Approve Invoice Line Item"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditApprovalStatusInvoiceLineItem}
            visible={showEditApprovalStatusInvoiceLineItem === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditInvoiceLineItemApprovalStatus
                cancel={toggleEditApprovalStatusInvoiceLineItem}
                invoiceLineItem={selectedInvoiceLineItem}
                load={load}
            />
        </Drawer>
    );

    const removeInvoiceLineItemDrawer = (
        <Drawer
            title={"Delete Invoice Line Item"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleRemoveInvoiceLineItem}
            visible={showRemoveInvoiceLineItem === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <RemoveInvoiceLineItem
                cancel={toggleRemoveInvoiceLineItem}
                invoiceLineItem={selectedInvoiceLineItem}
            />
        </Drawer>
    );

    //#endregion
    //#region transaction displays

    const transactionModal = (
        <Modal
            title={'Manage Transactions'}
            visible={showTransactionModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={() => { toggleTransactionModal(); }}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <DataTable
                dataSource={transactions.filter(t => (t.fromEntityType === 'SHIPPER' || t.toEntityType === 'SHIPPER') && t.isDeleted === false)}
                columns={transactionColumns}
                hidePaging={true}
                rowClassName={getTransactionRowClassName}
                title={() => (transactionTitle(shipperInvoice, 'Shipper', 'SHIPPER_INVOICE'))}
                style={{ backgroundColor: '#ffffff' }}
                rowKey={record => record.id}
                scroll={{ x: '100%' }}
            />
            <DataTable
                dataSource={transactions.filter(t => (t.fromEntityType === 'CARRIER' || t.toEntityType === 'CARRIER'))}
                columns={transactionColumns}
                hidePaging={true}
                rowClassName={getTransactionRowClassName}
                title={() => (transactionTitle(carrierInvoice, 'Carrier', 'CARRIER_INVOICE'))}
                style={{ backgroundColor: '#ffffff' }}
                rowKey={record => record.id}
                scroll={{ x: '100%' }}
            />
            <CanSee staffOnly={true}>
                <DataTable
                    dataSource={transactions.filter(t => (t.fromEntityType === 'FACTORING' || t.toEntityType === 'FACTORING'))}
                    columns={transactionColumns}
                    hidePaging={true}
                    rowClassName={getTransactionRowClassName}
                    title={() => (transactionTitle(null, 'Axle Payments', 'FACTORING_INVOICE'))}
                    style={{ backgroundColor: '#ffffff' }}
                    rowKey={record => record.id}
                    scroll={{ x: '100%' }}
                />
            </CanSee>
        </Modal>
    );

    const newTransactionDrawer = (
        <Drawer
            title={"New Transaction"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleNewTransaction}
            visible={showNewTransaction === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewTransaction
                cancel={toggleNewTransaction}
                loadId={loadId}
                invoices={invoices}
                invoiceId={isObjectNotEmpty(selectedInvoice) ? selectedInvoice.id : null}
                shipperId={shipperId}
                carrierId={carrierId}
            />
        </Drawer>
    );

    const editTransactionDrawer = (
        <Drawer
            title={"Edit Transaction"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditTransaction}
            visible={showEditTransaction === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditTransaction
                cancel={toggleEditTransaction}
                transaction={selectedTransaction}
                load={load}
                invoices={invoices}
                shipperId={shipperId}
                carrierId={carrierId}
            />
        </Drawer>
    );

    const editPaymentStatusTransactionDrawer = (
        <Drawer
            title={"Update Payment Status of Transaction"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditPaymentStatusTransaction}
            visible={showEditPaymentStatusTransaction === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditTransactionPaymentStatus
                cancel={toggleEditPaymentStatusTransaction}
                transaction={selectedTransaction}
                load={load}
                invoices={invoices}
            />
        </Drawer>
    );

    const removeTransactionDrawer = (
        <Drawer
            title={"Delete Transaction"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleRemoveTransaction}
            visible={showRemoveTransaction === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <RemoveTransaction
                cancel={toggleRemoveTransaction}
                transaction={selectedTransaction}
                invoices={invoices}
            />
        </Drawer>
    );

    //#endregion
    //#region claims displays

    const claimsModal = (
        <Modal
            title="Claims"
            visible={showClaimsModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={toggleClaimsModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <div>
                <Header
                    title="Claims"
                    titleStyle={{ paddingLeft: 0, color: '#000000', fontWeight: 500 }}
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    icon={<DollarOutlined />}
                    iconStyle={{ background: 'transparent' }}
                    title2={`Please make sure all claims, if any, are closed.`}
                    title2Style={{ whiteSpace: 'normal' }}
                />

                <LoadClaims
                    loadId={loadId}
                    load={load}
                    claims={claims}
                    currentStatus={currentStatus}
                />

                <div style={{ marginTop: 24, textAlign: 'right' }}>
                    <Button onClick={() => toggleClaimsModal()}>Done</Button>
                </div>
            </div>
        </Modal>
    );

    //#endregion
    //#region invoice displays

    const invoiceNotesModal = (
        <Modal
            title="Invoice Notes and Corrections"
            visible={showNotesModal === true}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleNotesModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            {isObjectNotEmpty(selectedInvoice) ? (
                <NoteList
                    noteEntityId={selectedInvoice.id}
                    noteEntityType="INVOICE"
                />
            ) : null}
        </Modal>
    );

    const newPriceConfirmationModal = (
        <Modal
            title="Generate and Send New Load Tender"
            visible={showNewPriceConfirmationModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={toggleNewPriceConfirmationModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <LoadNewPriceConfirmation
                load={load}
                loadId={loadId}
                shipperId={shipperId}
                stops={stops}
                invoices={invoices}
                invoiceLineItems={invoiceLineItems}
                cancel={toggleNewPriceConfirmationModal}
            />
        </Modal>
    );

    const newRateConfirmationModal = (
        <Modal
            title="Generate and Send New Rate Confirmation"
            visible={showNewRateConfirmationModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={toggleNewRateConfirmationModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <LoadNewRateConfirmation
                load={load}
                loadId={loadId}
                shipperId={shipperId}
                carrierId={carrierId}
                serviceType={serviceType}
                stops={stops}
                invoices={invoices}
                invoiceLineItems={invoiceLineItems}
                cancel={toggleNewRateConfirmationModal}
            />
        </Modal>
    );

    const newShipperInvoiceModal = (
        <Modal
            title="Generate and Send New Shipper Invoice"
            visible={showNewShipperInvoiceModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={toggleNewShipperInvoiceModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <LoadNewShipperInvoice
                load={load}
                loadId={loadId}
                shipperId={shipperId}
                stops={stops}
                invoices={invoices}
                invoiceLineItems={invoiceLineItems}
                cancel={toggleNewShipperInvoiceModal}
            />
        </Modal>
    );

    const newCarrierInvoiceModal = (
        <Modal
            title="Submit Carrier Invoice"
            visible={showNewCarrierInvoiceModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={toggleNewCarrierInvoiceModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <LoadNewCarrierInvoice
                load={load}
                loadId={loadId}
                shipperId={shipperId}
                carrierId={carrierId}
                serviceType={serviceType}
                stops={stops}
                invoices={invoices}
                invoiceLineItems={invoiceLineItems}
                transactions={transactions}
                claims={claims}
                cancel={toggleNewCarrierInvoiceModal}
            />
        </Modal>
    );

    const resendPriceConfirmationModal = (
        <Modal
            title="Re-Send Load Tender"
            visible={showResendPriceConfirmationModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={toggleResendPriceConfirmationModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <LoadSendPriceConfirmation
                load={load}
                loadId={loadId}
                invoice={selectedInvoice}
                shipperId={shipperId}
                stops={stops}
                cancel={toggleResendPriceConfirmationModal}
            />
        </Modal>
    );

    const resendRateConfirmationModal = (
        <Modal
            title="Re-Send Rate Confirmation"
            visible={showResendRateConfirmationModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={toggleResendRateConfirmationModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <LoadSendRateConfirmation
                load={load}
                loadId={loadId}
                invoice={selectedInvoice}
                carrierId={carrierId}
                stops={stops}
                cancel={toggleResendRateConfirmationModal}
            />
        </Modal>
    );

    const resendShipperInvoiceModal = (
        <Modal
            title="Re-Send Shipper Invoice"
            visible={showResendShipperInvoiceModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={toggleResendShipperInvoiceModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <LoadSendShipperInvoice
                load={load}
                loadId={loadId}
                invoice={selectedInvoice}
                shipperId={shipperId}
                stops={stops}
                cancel={toggleResendShipperInvoiceModal}
            />
        </Modal>
    );

    const updatePriceConfirmationModal = (
        <Modal
            title="Update and Re-Send Price Confirmation"
            visible={showUpdatePriceConfirmationModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={toggleUpdatePriceConfirmationModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <LoadEditPriceConfirmation
                load={load}
                loadId={loadId}
                invoice={selectedInvoice}
                shipperId={shipperId}
                stops={stops}
                invoiceLineItems={invoiceLineItems}
                cancel={toggleUpdatePriceConfirmationModal}
            />
        </Modal>
    );

    const updateRateConfirmationModal = (
        <Modal
            title="Update and Re-Send Rate Confirmation"
            visible={showUpdateRateConfirmationModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={toggleUpdateRateConfirmationModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <LoadEditRateConfirmation
                load={load}
                loadId={loadId}
                invoice={selectedInvoice}
                carrierId={carrierId}
                serviceType={serviceType}
                stops={stops}
                invoiceLineItems={invoiceLineItems}
                cancel={toggleUpdateRateConfirmationModal}
            />
        </Modal>
    );

    const updateShipperInvoiceModal = (
        <Modal
            title="Update and Re-Send Shipper Invoice"
            visible={showUpdateShipperInvoiceModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={toggleUpdateShipperInvoiceModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <LoadEditShipperInvoice
                load={load}
                loadId={loadId}
                invoice={selectedInvoice}
                invoiceId={isObjectNotEmpty(selectedInvoice) ? selectedInvoice.id : null}
                shipperId={shipperId}
                carrierId={carrierId}
                serviceType={serviceType}
                stops={stops}
                invoiceLineItems={invoiceLineItems}
                cancel={toggleUpdateShipperInvoiceModal}
            />
        </Modal>
    );

    const updateCarrierInvoiceModal = (
        <Modal
            title="Update and Re-Submit Carrier Invoice"
            visible={showUpdateCarrierInvoiceModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={toggleUpdateCarrierInvoiceModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <LoadEditCarrierInvoice
                load={load}
                loadId={loadId}
                invoice={selectedInvoice}
                invoiceId={isObjectNotEmpty(selectedInvoice) ? selectedInvoice.id : null}
                shipperId={shipperId}
                carrierId={carrierId}
                serviceType={serviceType}
                stops={stops}
                invoiceLineItems={invoiceLineItems}
                cancel={toggleUpdateCarrierInvoiceModal}
            />
        </Modal>
    );

    const viewInvoiceModal = (
        <Modal
            title={isObjectNotEmpty(selectedInvoice) ? Enums.InvoiceTypes.getValueByName(selectedInvoice.invoiceType) : ''}
            visible={showViewInvoiceModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={() => { toggleViewInvoice(); setSelectedInvoice(null); }}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <Invoice
                invoiceId={isObjectNotEmpty(selectedInvoice) && isStringNotEmpty(selectedInvoice.id) ? selectedInvoice.id : null}
                invoice={isObjectNotEmpty(selectedInvoice) ? selectedInvoice : null}
                load={load}
                loadId={loadId}
                stops={stops}
                displayManualInstructions={false}
            />
        </Modal>
    );

    const editInvoiceComponents = (
        <Drawer
            title={isObjectNotEmpty(selectedInvoice) ? `Edit ${Enums.InvoiceTypes.getValueByName(selectedInvoice.invoiceType)}` : ""}
            onClose={() => { toggleEditInvoice(); setSelectedInvoice(null); }}
            visible={showEditInvoice === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditInvoice
                cancel={() => { toggleEditInvoice(); setSelectedInvoice(null); }}
                invoice={selectedInvoice}
                load={load}
                shipperId={shipperId}
                carrierId={carrierId}
                serviceType={serviceType}
            />
        </Drawer>
    );

    const shipperInvoiceModal = (
        <Modal
            title="Shipper Invoice"
            visible={showShipperInvoiceModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={toggleShipperInvoiceModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <div>
                <Header
                    title="Shipper Invoice"
                    titleStyle={{ paddingLeft: 0, color: '#000000', fontWeight: 500 }}
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    icon={<DollarOutlined />}
                    iconStyle={{ background: 'transparent' }}
                    title2={`Please review the Shipper Invoice below.`}
                    title2Style={{ whiteSpace: 'normal' }}
                />

                <DataTable
                    dataSource={invoiceLineItems.filter(i => (i.fromEntityType === 'SHIPPER' || i.toEntityType === 'SHIPPER'))}
                    columns={invoiceLineItemColumns}
                    hidePaging={true}
                    rowClassName={getInvoiceLineItemRowClassName}
                    title={() => (invoiceLineItemTitle('Shipper', serviceType === 'TMS' ? 'CARRIER' : 'STAFF', 'SHIPPER'))}
                    style={{ backgroundColor: '#ffffff', marginBottom: 24 }}
                    rowKey={record => record.id}
                    scroll={{ x: '100%' }}
                />

                <DataTable
                    dataSource={transactions.filter(t => (t.fromEntityType === 'SHIPPER' || t.toEntityType === 'SHIPPER'))}
                    columns={transactionColumns}
                    hidePaging={true}
                    rowClassName={getTransactionRowClassName}
                    title={() => (transactionTitle(shipperInvoice, 'Shipper', 'SHIPPER_INVOICE'))}
                    style={{ backgroundColor: '#ffffff' }}
                    rowKey={record => record.id}
                    scroll={{ x: '100%' }}
                />

                {isObjectNotEmpty(shipperInvoice) ? (
                    <>
                        <Invoice invoiceId={shipperInvoice.id} invoice={shipperInvoice} load={load} loadId={loadId} stops={stops} />

                        <NoteList
                            noteEntityId={shipperInvoice.id}
                            noteEntityType="INVOICE"
                            tableTitle="Notes and Corrections"
                            style={{ marginTop: 12, marginBottom: 12 }}
                        />

                        <div style={{ marginTop: 12, marginBottom: 12 }}>
                            {shipperInvoice.status !== 'COMPLETED' && shipperInvoice.status !== 'CANCELLED' ? (
                                <>
                                    <Button type="primary" onClick={() => { setSelectedInvoice(shipperInvoice); toggleResendShipperInvoiceModal(); }}>Re-Send Shipper Invoice</Button>
                                    <Button type="primary" style={{ marginLeft: 8 }} onClick={() => { setSelectedInvoice(shipperInvoice); toggleUpdateShipperInvoiceModal(); }}>Update and Re-Send Shipper Invoice</Button>
                                </>
                            ) : null}
                            {invoiceLineItems.filter(i => (i.invoiceId === null || i.invoiceId === undefined) && i.isDeleted === false && (i.fromEntityType === 'SHIPPER' || i.toEntityType === 'SHIPPER')).length > 0 ? (
                                <Button type="primary" onClick={() => { toggleNewShipperInvoiceModal(); }}>Generate and Send Additional Shipper Invoice</Button>
                            ) : null}
                        </div>
                    </>
                ) : (<Button type="primary" onClick={() => { toggleNewShipperInvoiceModal(); }}>Generate and Send Shipper Invoice</Button>)}

                <div style={{ marginTop: 24, textAlign: 'right' }}>
                    <Button onClick={() => toggleShipperInvoiceModal()}>Done</Button>
                </div>
            </div>
        </Modal>
    );

    const carrierInvoiceModal = (
        <Modal
            title={serviceType === "TMS" ? "Invoice" : "Carrier Invoice"}
            visible={showCarrierInvoiceModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={toggleCarrierInvoiceModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <div>
                <Header
                    title="Carrier Invoice"
                    titleStyle={{ paddingLeft: 0, color: '#000000', fontWeight: 500 }}
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    icon={<DollarOutlined />}
                    iconStyle={{ background: 'transparent' }}
                    title2={`Please review the Carrier Invoice Below.`}
                    title2Style={{ whiteSpace: 'normal' }}
                />

                <DataTable
                    dataSource={invoiceLineItems.filter(i => (i.fromEntityType === 'CARRIER' || i.toEntityType === 'CARRIER'))}
                    columns={invoiceLineItemColumns}
                    hidePaging={true}
                    rowClassName={getInvoiceLineItemRowClassName}
                    title={() => (invoiceLineItemTitle('Carrier', 'CARRIER', serviceType === 'TMS' ? 'SHIPPER' : 'STAFF'))}
                    style={{ backgroundColor: '#ffffff', marginBottom: 24 }}
                    rowKey={record => record.id}
                    scroll={{ x: '100%' }}
                />

                <DataTable
                    dataSource={transactions.filter(t => (t.fromEntityType === 'CARRIER' || t.toEntityType === 'CARRIER'))}
                    columns={transactionColumns}
                    hidePaging={true}
                    rowClassName={getTransactionRowClassName}
                    title={() => (transactionTitle(carrierInvoice, 'Carrier', 'CARRIER_INVOICE'))}
                    style={{ backgroundColor: '#ffffff' }}
                    rowKey={record => record.id}
                    scroll={{ x: '100%' }}
                />

                <div style={{ marginTop: 24, marginBottom: 24 }}>
                    {isObjectNotEmpty(carrierInvoice) && (carrierInvoice.paymentMethod === 'DIRECT_DEPOSIT' || carrierInvoice.paymentMethod === 'FACTORING') ? (
                        <Divider />
                    ) : null}
                    {bankAccountDocumentComponents()}
                    {factoringNOADocumentComponents()}
                    <Divider />
                    {carrierInvoiceDocumentComponents()}
                    <Divider />
                    {factoringInvoiceDocumentComponents()}
                    <Divider />
                </div>

                {isObjectNotEmpty(carrierInvoice) && isListNotEmpty(stops) ? (
                    <>
                        <Invoice invoiceId={carrierInvoice.id} invoice={carrierInvoice} load={load} loadId={load.id} stops={stops} />

                        <NoteList
                            noteEntityId={carrierInvoice.id}
                            noteEntityType="INVOICE"
                            tableTitle="Notes and Corrections"
                            style={{ marginTop: 12, marginBottom: 12 }}
                        />

                        <div style={{ marginTop: 12, marginBottom: 12 }}>
                            {(carrierInvoice.status !== 'COMPLETED' && carrierInvoice.status !== 'CANCELLED') ? (
                                <Button type="primary" onClick={() => { setSelectedInvoice(carrierInvoice); toggleUpdateCarrierInvoiceModal(); }}>Update and Re-Submit Carrier Invoice</Button>
                            ) : null}
                            {(carrierInvoice.status === 'COMPLETED' || carrierInvoice.status === 'CANCELLED') && invoiceLineItems.filter(i => i.loadId === load.id && (i.invoiceId === null || i.invoiceId === undefined) && i.isDeleted === false && (i.fromEntityType === 'CARRIER' || i.toEntityType === 'CARRIER')).length > 0 ? (
                                <Button type="primary" onClick={() => { toggleNewCarrierInvoiceModal(); }}>Generate and Submit Additional Carrier Invoice</Button>
                            ) : null}
                        </div>
                    </>
                ) : (<Button type="primary" onClick={() => { toggleNewCarrierInvoiceModal(); }}>Submit New Carrier Invoice</Button>)}

                <div style={{ marginTop: 24, textAlign: 'right' }}>
                    <Button onClick={() => toggleCarrierInvoiceModal()}>Done</Button>
                </div>
            </div>
        </Modal>
    );

    const factoringInvoiceModal = (
        <Modal
            title="Axle Payments"
            visible={showFactoringInvoiceModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={toggleFactoringInvoiceModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <div>
                <Header
                    title="Axle Payments"
                    titleStyle={{ paddingLeft: 0, color: '#000000', fontWeight: 500 }}
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    icon={<DollarOutlined />}
                    iconStyle={{ background: 'transparent' }}
                    title2={`Please review the Axle Payments Financials below.`}
                    title2Style={{ whiteSpace: 'normal' }}
                />

                <DataTable
                    dataSource={invoiceLineItems.filter(i => (i.fromEntityType === 'FACTORING' || i.toEntityType === 'FACTORING'))}
                    columns={invoiceLineItemColumns}
                    hidePaging={true}
                    rowClassName={getInvoiceLineItemRowClassName}
                    title={() => (invoiceLineItemTitle('Axle Payments', 'STAFF', 'FACTORING'))}
                    style={{ backgroundColor: '#ffffff', marginBottom: 24 }}
                    rowKey={record => record.id}
                    scroll={{ x: '100%' }}
                />

                <DataTable
                    dataSource={transactions.filter(t => (t.fromEntityType === 'FACTORING' || t.toEntityType === 'FACTORING'))}
                    columns={transactionColumns}
                    hidePaging={true}
                    rowClassName={getTransactionRowClassName}
                    title={() => (transactionTitle(null, 'Axle Payments', 'FACTORING_INVOICE'))}
                    style={{ backgroundColor: '#ffffff' }}
                    rowKey={record => record.id}
                    scroll={{ x: '100%' }}
                />

                <div style={{ marginTop: 24, textAlign: 'right' }}>
                    <Button onClick={() => toggleFactoringInvoiceModal()}>Done</Button>
                </div>
            </div>
        </Modal>
    );

    const sendLoadFinalizationEmailModal = (
        <Modal
            title="Send Load Finalization Email"
            visible={showSendLoadFinalizationModal === true}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleSendLoadFinalizationModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <LoadSendLoadFinalizationEmail
                load={load}
                carrierId={carrierId}
                stops={stops}
                cancel={toggleSendLoadFinalizationModal}
            />
        </Modal>
    );

    //#endregion
    //#region completion checklist displays

    const editCompletionChecklistDrawer = (
        <Drawer
            title={"Update Load Completion Checklist"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditCompletionChecklist}
            visible={showEditCompletionChecklist === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditLoadCompletionChecklist
                cancel={toggleEditCompletionChecklist}
                load={load}
            />
        </Drawer>
    );

    const extra = isObjectNotEmpty(load) ? (
        <CanSee entityAction="UPDATE" entityModel="LOAD_COMPLETION_CHECKLIST" entityObject={load}>
            <AddEditChangeRemoveButtons
                showAdd={false} addText='' addAction={null}
                showEdit={LoadUtils.loadStatusIs(load.loadStatus, ['COMPLETED']) ? true : false} editText='Update Completion Checklist' editAction={(e) => { e.stopPropagation(); toggleEditCompletionChecklist(); }}
                showChange={false} changeText='' changeAction={null}
                showRemove={false} removeText='' removeAction={null}
            />
        </CanSee>
    ) : null;

    //#endregion
    //#region pedigree information displays

    const editMissingPedigreeInformationDrawer = (
        <Drawer
            title={"Edit Missing Details for " + (isObjectNotEmpty(selectedMissingPedigreeInformation) ? selectedMissingPedigreeInformation.entityName : "")}
            onClose={toggleEditMissingPedigreeInformation}
            visible={showEditMissingPedigreeInformation === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditMissingPedigreeInformation
                cancel={toggleEditMissingPedigreeInformation}
                missingPedigreeInformation={selectedMissingPedigreeInformation}
                load={load}
            />
        </Drawer>
    );

    const missingPedigreeInformationComponents = () => {
        if (isListNotEmpty(missingPedigreeInformation) && LoadUtils.loadStatusIsNot(currentStatus, ["CANCELLED", "CLOSED"])) {
            return (
                <Row gutter={[24, 24]} style={{ marginBottom: 24 }}>
                    <Col span={24}>
                        {missingPedigreeInformation.map((missingInfo, index, arr) => {
                            if (missingInfo.entityType === 'STOP') {
                                return (
                                    <Alert
                                        key={`missing-pedigree-information-${index}`}
                                        style={index < arr.length - 1 ? { marginBottom: 8 } : null}
                                        type="error"
                                        message={`${missingInfo.entityName} is missing ${missingInfo.missingFields.map((missingField) => missingField.missingFieldName).join(', ')}`}
                                        showIcon={true}
                                        action={<Space><Button size="small" type="ghost" onClick={(e) => { setSelectedMissingPedigreeInformation(missingInfo); toggleEditMissingPedigreeInformation(); }}>Fix</Button></Space>}
                                    />
                                );
                            } else {
                                return (
                                    <Alert
                                        key={`missing-pedigree-information-${index}`}
                                        style={index < arr.length - 1 ? { marginBottom: 8 } : null}
                                        type="error"
                                        message={`${missingInfo.entityName} is missing ${missingInfo.missingFieldName}`}
                                        showIcon={true}
                                        action={<Space><Button size="small" type="ghost" onClick={(e) => { setSelectedMissingPedigreeInformation(missingInfo); toggleEditMissingPedigreeInformation(); }}>Fix</Button></Space>}
                                    />
                                );
                            }
                        })}
                    </Col>
                </Row>
            );
        } else {
            return null;
        }
    };

    //#endregion

    if (isObjectNotEmpty(load)) {
        return (
            <>
                <div style={style}>
                    {showDocumentCards === true && LoadUtils.loadStatusIs(load.loadStatus, ['COMPLETED', 'CLOSED', 'REOPENED']) ? (
                        <Row gutter={[24, 24]} style={{ marginBottom: 24 }}>
                            <Col xs={24} md={(entityType === 'STAFF' && serviceType === 'BROKERAGE') ? 6 : 8} lg={(entityType === 'STAFF' && serviceType === 'BROKERAGE') ? 6 : 8} xl={(entityType === 'STAFF' && serviceType === 'BROKERAGE') ? 6 : 8}>
                                <Card
                                    size="small"
                                    title={`Proof of Delivery: ${podStatus()}`}
                                    bordered={true}
                                    className={classes.card}
                                    headStyle={podStyle()}
                                >
                                    <Row>
                                        <Col span={24}>
                                            {podButton()}
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xs={24} md={(entityType === 'STAFF' && serviceType === 'BROKERAGE') ? 6 : 8} lg={(entityType === 'STAFF' && serviceType === 'BROKERAGE') ? 6 : 8} xl={(entityType === 'STAFF' && serviceType === 'BROKERAGE') ? 6 : 8}>
                                <Card
                                    size="small"
                                    title="Documents"
                                    bordered={true}
                                    className={classes.card}
                                    headStyle={(isObjectNotEmpty(load.completionCheckList) && isBooleanFalse(load.completionCheckList.hasDocumentsReviewed)) && isBooleanTrue(hasMissingDocuments) ? { ...missingEntityStyle } : ((isObjectNotEmpty(load.completionCheckList) && isBooleanFalse(load.completionCheckList.hasDocumentsReviewed)) || isBooleanTrue(hasUnreviewedDocuments) ? { ...pendingEntityStyle } : { ...completedEntityStyle })}
                                >
                                    <Row>
                                        <Col span={24}>
                                            <Button type="default" onClick={() => { toggleViewDocuments(); }}>Manage</Button>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>

                            {serviceType === 'BROKERAGE' && (entityType === 'STAFF' || entityType === 'SHIPPER') ? (
                                <Col xs={24} md={entityType === 'STAFF' ? 6 : 8} lg={entityType === 'STAFF' ? 6 : 8} xl={entityType === 'STAFF' ? 6 : 8}>
                                    <Card
                                        size="small"
                                        title={`${entityType === 'STAFF' ? 'Shipper' : 'A/P'} Invoice: ${isObjectNotEmpty(load.completionCheckList) && isStringNotEmpty(load.completionCheckList.shipperInvoiceStatus) ? stringFormatter.toFormattedString("InvoiceStatus", load.completionCheckList.shipperInvoiceStatus) : "Missing"}`}
                                        bordered={true}
                                        className={classes.card}
                                        headStyle={isObjectNotEmpty(load.completionCheckList) && isStringNotEmpty(load.completionCheckList.shipperInvoiceStatus) ? (load.completionCheckList.shipperInvoiceStatus === 'COMPLETED' ? { ...completedEntityStyle } : (load.completionCheckList.shipperInvoiceStatus === 'SENT' ? { ...pendingEntityStyle } : { ...missingEntityStyle })) : { ...missingEntityStyle }}
                                        extra={isObjectNotEmpty(shipperInvoice) && (shipperInvoice.status === 'SENT' || shipperInvoice.status === 'COMPLETED') ? (
                                            <Button type="default" shape="circle" icon={<FontAwesomeIcon icon={faExternalLinkAlt} size="sm" />} onClick={() => { setSelectedInvoice(shipperInvoice); toggleViewInvoice(); }}></Button>
                                        ) : null}
                                    >
                                        {entityType === 'STAFF' ? (
                                            <Row gutter={[24, 24]}>
                                                {isObjectNotEmpty(load.completionCheckList) && isStringNotEmpty(load.completionCheckList.shipperInvoiceStatus) ? (load.completionCheckList.shipperInvoiceStatus === 'COMPLETED' ? (
                                                    <>
                                                        <Col span={12}><DataRow title="Balance" value={load.shipperBalance} dataType="Money" /></Col>
                                                        <Col span={12}><DataRow title="Paid" value={isObjectNotEmpty(load.completionCheckList) ? load.completionCheckList.shipperInvoicePaymentDate : null} dataType="MomentDate" /></Col>
                                                    </>
                                                ) : (load.completionCheckList.shipperInvoiceStatus === 'SENT' ? (
                                                    <>
                                                        <Col span={12}><DataRow title="Balance" value={load.shipperBalance} dataType="Money" /></Col>
                                                        <Col span={12}><DataRow title="Due" value={isObjectNotEmpty(load.completionCheckList) ? load.completionCheckList.shipperInvoicePaymentDueDate : null} dataType="MomentDate" /></Col>
                                                    </>
                                                ) : (
                                                    <Col span={24}><Button type="default" onClick={() => { toggleNewShipperInvoiceModal(); }}>Generate and Send New Invoice</Button></Col>
                                                ))) : (
                                                    <Col span={24}><Button type="default" onClick={() => { toggleNewShipperInvoiceModal(); }}>Generate and Send New Invoice</Button></Col>
                                                )}
                                            </Row>
                                        ) : (
                                            <Row gutter={[24, 24]}>
                                                {isObjectNotEmpty(load.completionCheckList) && isStringNotEmpty(load.completionCheckList.shipperInvoiceStatus) ? (load.completionCheckList.shipperInvoiceStatus === 'COMPLETED' ? (
                                                    <>
                                                        <Col span={12}><DataRow title="Balance" value={load.shipperBalance} dataType="Money" /></Col>
                                                        <Col span={12}><DataRow title="Paid" value={isObjectNotEmpty(load.completionCheckList) ? load.completionCheckList.shipperInvoicePaymentDate : null} dataType="MomentDate" /></Col>
                                                    </>
                                                ) : (load.completionCheckList.shipperInvoiceStatus === 'SENT' ? (
                                                    <>
                                                        <Col span={12}><DataRow title="Balance" value={load.shipperBalance} dataType="Money" /></Col>
                                                        <Col span={12}><DataRow title="Due" value={isObjectNotEmpty(load.completionCheckList) ? load.completionCheckList.shipperInvoicePaymentDueDate : null} dataType="MomentDate" /></Col>
                                                    </>
                                                ) : (
                                                    <Col span={24}><DataRow title="Balance" value={load.shipperBalance} dataType="Money" /></Col>
                                                ))) : (
                                                    <Col span={24}><DataRow title="Balance" value={load.shipperBalance} dataType="Money" /></Col>
                                                )}
                                            </Row>
                                        )}
                                    </Card>
                                </Col>
                            ) : null}
                            {(serviceType === 'BROKERAGE' && (entityType === 'STAFF' || entityType === 'CARRIER')) || serviceType === 'TMS' ? (
                                <Col xs={24} md={entityType === 'STAFF' ? 6 : 8} lg={entityType === 'STAFF' && serviceType === 'BROKERAGE' ? 6 : 8} xl={entityType === 'STAFF' && serviceType === 'BROKERAGE' ? 6 : 8}>
                                    <Card
                                        size="small"
                                        title={`${entityType === 'STAFF' ? 'Carrier Invoice' : (entityType === 'CARRIER' ? 'A/R Invoice' : (entityType === 'SHIPPER' ? 'A/P Invoice' : 'Invoice'))}: ${isObjectNotEmpty(load.completionCheckList) && isStringNotEmpty(load.completionCheckList.carrierInvoiceStatus) ? stringFormatter.toFormattedString("InvoiceStatus", load.completionCheckList.carrierInvoiceStatus) : "Missing"}`}
                                        bordered={true}
                                        className={classes.card}
                                        headStyle={isObjectNotEmpty(load.completionCheckList) && isStringNotEmpty(load.completionCheckList.carrierInvoiceStatus) ? (load.completionCheckList.carrierInvoiceStatus === 'COMPLETED' ? { ...completedEntityStyle } : (load.completionCheckList.carrierInvoiceStatus === 'SENT' ? { ...pendingEntityStyle } : { ...missingEntityStyle })) : { ...missingEntityStyle }}
                                        extra={isObjectNotEmpty(carrierInvoice) && (carrierInvoice.status === 'SENT' || carrierInvoice.status === 'COMPLETED') ? (
                                            <Button type="default" shape="circle" icon={<FontAwesomeIcon icon={faExternalLinkAlt} size="sm" />} onClick={() => { setSelectedInvoice(carrierInvoice); toggleViewInvoice(); }}></Button>
                                        ) : null}
                                    >
                                        {entityType === 'STAFF' || entityType === 'SHIPPER' ? (
                                            <Row gutter={[24, 24]}>
                                                {isObjectNotEmpty(load.completionCheckList) && isStringNotEmpty(load.completionCheckList.carrierInvoiceStatus) ? (load.completionCheckList.carrierInvoiceStatus === 'COMPLETED' ? (
                                                    <>
                                                        <Col span={12}><DataRow title="Balance" value={load.carrierBalance} dataType="Money" /></Col>
                                                        <Col span={12}><DataRow title="Paid" value={isObjectNotEmpty(load.completionCheckList) ? load.completionCheckList.carrierInvoicePaymentDate : null} dataType="MomentDate" /></Col>
                                                    </>
                                                ) : (load.completionCheckList.carrierInvoiceStatus === 'SENT' ? (
                                                    <>
                                                        <Col span={12}><DataRow title="Balance" value={load.carrierBalance} dataType="Money" /></Col>
                                                        <Col span={12}><DataRow title="Due" value={isObjectNotEmpty(load.completionCheckList) ? load.completionCheckList.carrierInvoicePaymentDueDate : null} dataType="MomentDate" /></Col>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Col span={12}><Button type="default" onClick={() => { toggleSendLoadFinalizationModal(); }}>Request Invoice</Button></Col>
                                                        <Col span={12}><Button type="default" onClick={() => { toggleNewCarrierInvoiceModal(); }}>Upload Invoice</Button></Col>
                                                    </>
                                                ))) : (
                                                    <>
                                                        <Col span={12}><Button type="default" onClick={() => { toggleSendLoadFinalizationModal(); }}>Request Invoice</Button></Col>
                                                        <Col span={12}><Button type="default" onClick={() => { toggleNewCarrierInvoiceModal(); }}>Upload Invoice</Button></Col>
                                                    </>
                                                )}
                                            </Row>
                                        ) : (
                                            <Row gutter={[24, 24]}>
                                                {isObjectNotEmpty(load.completionCheckList) && isStringNotEmpty(load.completionCheckList.carrierInvoiceStatus) ? (load.completionCheckList.carrierInvoiceStatus === 'COMPLETED' ? (
                                                    <>
                                                        <Col span={12}><DataRow title="Balance" value={load.carrierBalance} dataType="Money" /></Col>
                                                        <Col span={12}><DataRow title="Paid" value={isObjectNotEmpty(load.completionCheckList) ? load.completionCheckList.carrierInvoicePaymentDate : null} dataType="MomentDate" /></Col>
                                                    </>
                                                ) : (load.completionCheckList.carrierInvoiceStatus === 'SENT' ? (
                                                    <>
                                                        <Col span={12}><DataRow title="Balance" value={load.carrierBalance} dataType="Money" /></Col>
                                                        <Col span={12}><DataRow title="Due" value={isObjectNotEmpty(load.completionCheckList) ? load.completionCheckList.carrierInvoicePaymentDueDate : null} dataType="MomentDate" /></Col>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Col span={24}><Button type="default" onClick={() => { toggleNewCarrierInvoiceModal(); }}>Upload Invoice</Button></Col>
                                                    </>
                                                ))) : (
                                                    <>
                                                        <Col span={24}><Button type="default" onClick={() => { toggleNewCarrierInvoiceModal(); }}>Upload Invoice</Button></Col>
                                                    </>
                                                )}
                                            </Row>
                                        )}
                                    </Card>
                                </Col>
                            ) : null}
                        </Row>
                    ) : null}

                    {showMissingPedigreeInformation === true && missingPedigreeInformation.length > 0 ? (
                        missingPedigreeInformationComponents()
                    ) : null}

                    {showInvoicingCards === true ? (
                        <Row gutter={[24, 24]}>
                            {serviceType === 'BROKERAGE' && (entityType === 'STAFF' || entityType === 'SHIPPER') ? (
                                <Col xs={24} md={24} lg={entityType === 'STAFF' ? 12 : 24} xl={entityType === 'STAFF' ? 12 : 24}>
                                    <Card
                                        title={
                                            <Typography.Title level={3}>{entityType === 'STAFF' ? 'Shipper Balance' : 'A/P'}: {stringFormatter.toFormattedMoney(load.shipperBalance)}</Typography.Title>
                                        }
                                        bordered={true}
                                        className={classes.card}
                                        headStyle={isNumberGreaterThanZero(load.shipperBalance) ? { ...missingEntityStyle } : { ...completedEntityStyle }}
                                    >
                                        <Row gutter={[24, 24]}>
                                            {showQuotes === true && LoadUtils.loadStatusIsNot(currentStatus, ['CANCELLED']) ? (
                                                <CanSee entityAction="READ" entityModel="LOAD_PRICE_CONFIRMATION" entityObject={load}>
                                                    <Col span={24}>
                                                        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoadingDocuments === true}>
                                                            <DataTable
                                                                dataSource={quoteList.filter(i => i.type === 'PRICE_CONFIRMATION')}
                                                                columns={quoteColumns}
                                                                hidePaging={true}
                                                                rowClassName={getInvoiceRowClassName}
                                                                title={() => (<b>Load Tender</b>)}
                                                                style={{ backgroundColor: '#ffffff' }}
                                                                rowKey={record => record.type}
                                                                scroll={{ x: '100%' }}
                                                            />
                                                        </Spin>
                                                    </Col>
                                                </CanSee>
                                            ) : null}
                                            {LoadUtils.loadStatusIs(currentStatus, ['COMPLETED', 'CLOSED', 'REOPENED', 'CANCELLED']) ? (
                                                <Col span={24}>
                                                    <DataTable
                                                        dataSource={invoices.filter(i => i.invoiceType === 'SHIPPER_INVOICE' && i.status !== 'CANCELLED' && i.isDeleted === false)}
                                                        columns={invoiceColumns}
                                                        hidePaging={true}
                                                        rowClassName={getInvoiceRowClassName}
                                                        title={() => (invoiceTitle('Shipper Invoices', 'SHIPPER_INVOICE', load, 'SHIPPER', 'Generate and Send New Shipper Invoice', toggleNewShipperInvoiceModal))}
                                                        style={{ backgroundColor: '#ffffff' }}
                                                        rowKey={record => record.id}
                                                        scroll={{ x: '100%' }}
                                                    />
                                                </Col>
                                            ) : null}
                                            <CanSee entityModel='LOAD' entityObject={load} entityTypes={['SHIPPER', 'STAFF']}>
                                                <Col span={24}>
                                                    <DataTable
                                                        dataSource={invoiceLineItems.filter(i => (i.fromEntityType === 'SHIPPER' || i.toEntityType === 'SHIPPER'))}
                                                        columns={invoiceLineItemColumns}
                                                        hidePaging={true}
                                                        rowClassName={getInvoiceLineItemRowClassName}
                                                        title={() => (invoiceLineItemTitle('Shipper', serviceType === 'TMS' ? 'CARRIER' : 'STAFF', 'SHIPPER'))}
                                                        style={{ backgroundColor: '#ffffff' }}
                                                        rowKey={record => record.id}
                                                        scroll={{ x: '100%' }}
                                                    />
                                                </Col>
                                                {LoadUtils.loadStatusIs(currentStatus, ['COMPLETED', 'CLOSED', 'REOPENED']) ? (
                                                    <Col span={24}>
                                                        <DataTable
                                                            dataSource={transactions.filter(t => (t.fromEntityType === 'SHIPPER' || t.toEntityType === 'SHIPPER'))}
                                                            columns={transactionColumns}
                                                            hidePaging={true}
                                                            rowClassName={getTransactionRowClassName}
                                                            title={() => (transactionTitle(shipperInvoice, 'Shipper', 'SHIPPER_INVOICE'))}
                                                            style={{ backgroundColor: '#ffffff' }}
                                                            rowKey={record => record.id}
                                                            scroll={{ x: '100%' }}
                                                        />
                                                    </Col>
                                                ) : null}
                                            </CanSee>
                                        </Row>
                                    </Card>
                                </Col>
                            ) : null}
                            {(serviceType === 'BROKERAGE' && (entityType === 'STAFF' || entityType === 'CARRIER')) || serviceType === 'TMS' ? (
                                <Col xs={24} md={24} lg={entityType === 'STAFF' && serviceType === 'BROKERAGE' ? 12 : 24} xl={entityType === 'STAFF' && serviceType === 'BROKERAGE' ? 12 : 24}>
                                    <Card
                                        title={
                                            <Typography.Title level={3}>{entityType === 'STAFF' ? 'Carrier Balance' : (entityType === 'CARRIER' ? 'A/R' : (entityType === 'SHIPPER' ? 'A/P' : 'Balance'))}: {stringFormatter.toFormattedMoney(load.carrierBalance)}</Typography.Title>
                                        }
                                        bordered={true}
                                        className={classes.card}
                                        headStyle={isNumberGreaterThanZero(load.carrierBalance) ? { ...missingEntityStyle } : { ...completedEntityStyle }}
                                    >
                                        <Row gutter={[24, 24]}>
                                            {showQuotes === true && LoadUtils.loadStatusIsNot(currentStatus, ['CREATED', 'PENDING', 'CANCELLED']) ? (
                                                <CanSee entityAction="READ" entityModel="LOAD_RATE_CONFIRMATION" entityObject={load}>
                                                    <Col span={24}>
                                                        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoadingDocuments === true}>
                                                            <DataTable
                                                                dataSource={quoteList.filter(i => i.type === 'RATE_CONFIRMATION')}
                                                                columns={quoteColumns}
                                                                hidePaging={true}
                                                                rowClassName={getInvoiceRowClassName}
                                                                title={() => (<b>Rate Confirmation</b>)}
                                                                style={{ backgroundColor: '#ffffff' }}
                                                                rowKey={record => record.type}
                                                                scroll={{ x: '100%' }}
                                                            />
                                                        </Spin>
                                                    </Col>
                                                </CanSee>
                                            ) : null}
                                            {LoadUtils.loadStatusIs(currentStatus, ['COMPLETED', 'CLOSED', 'REOPENED', 'CANCELLED']) ? (
                                                <Col span={24}>
                                                    <DataTable
                                                        dataSource={invoices.filter(i => i.invoiceType === 'CARRIER_INVOICE' && i.status !== 'CANCELLED' && i.isDeleted === false)}
                                                        columns={invoiceColumns}
                                                        hidePaging={true}
                                                        rowClassName={getInvoiceRowClassName}
                                                        title={() => (invoiceTitle('Carrier Invoices', 'CARRIER_INVOICE', load, 'CARRIER', 'Submit New Carrier Invoice', toggleNewCarrierInvoiceModal))}
                                                        style={{ backgroundColor: '#ffffff' }}
                                                        rowKey={record => record.id}
                                                        scroll={{ x: '100%' }}
                                                    />
                                                </Col>
                                            ) : null}
                                            {/* <Col span={24}>
                                            {bankAccountDocumentComponents()}
                                            {factoringNOADocumentComponents()}
                                            <Divider />
                                            {carrierInvoiceDocumentComponents()}
                                            <Divider />
                                            {factoringInvoiceDocumentComponents()}
                                        </Col> */}
                                            <CanSee entityModel='LOAD' entityObject={load} entityTypes={['CARRIER', 'STAFF']}>
                                                <Col span={24}>
                                                    <DataTable
                                                        dataSource={invoiceLineItems.filter(i => (i.fromEntityType === 'CARRIER' || i.toEntityType === 'CARRIER'))}
                                                        columns={invoiceLineItemColumns}
                                                        hidePaging={true}
                                                        rowClassName={getInvoiceLineItemRowClassName}
                                                        title={() => (invoiceLineItemTitle('Carrier', 'CARRIER', serviceType === 'TMS' ? 'SHIPPER' : 'STAFF'))}
                                                        style={{ backgroundColor: '#ffffff' }}
                                                        rowKey={record => record.id}
                                                        scroll={{ x: '100%' }}
                                                    />
                                                </Col>
                                                {LoadUtils.loadStatusIs(currentStatus, ['COMPLETED', 'CLOSED', 'REOPENED']) ? (
                                                    <Col span={24}>
                                                        <DataTable
                                                            dataSource={transactions.filter(t => (t.fromEntityType === 'CARRIER' || t.toEntityType === 'CARRIER'))}
                                                            columns={transactionColumns}
                                                            hidePaging={true}
                                                            rowClassName={getTransactionRowClassName}
                                                            title={() => (transactionTitle(carrierInvoice, 'Carrier', 'CARRIER_INVOICE'))}
                                                            style={{ backgroundColor: '#ffffff' }}
                                                            rowKey={record => record.id}
                                                            scroll={{ x: '100%' }}
                                                        />
                                                    </Col>
                                                ) : null}
                                            </CanSee>
                                        </Row>
                                    </Card>
                                </Col>
                            ) : null}
                            {/* <Col xs={24} md={12} lg={12} xl={12}>
                                <Card bordered={true} className={classes.card} style={invoiceLineItems.filter(i => i.status === 'PENDING').length === 0 && isBooleanFalse(load.completionCheckList.hasBalance) ? { ...completedEntityStyle } : { ...missingEntityStyle }}>
                                    <Row>
                                        <Col span={18}>
                                            <Typography.Title level={3}>Invoice Line Items</Typography.Title>
                                        </Col>
                                        <Col span={6} style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <Button type="default" size="large" shape="circle" icon={<FontAwesomeIcon icon={faExternalLinkAlt} size="lg" />} onClick={() => { toggleInvoiceLineItemModal(); }} />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col> */}

                            {/* <Col xs={24} md={12} lg={12} xl={12}>
                                <Card bordered={true} className={classes.card} style={transactions.length > 0 && transactions.filter(i => i.paymentStatus === 'PENDING').length === 0 && isBooleanFalse(load.completionCheckList.hasBalance) ? { ...completedEntityStyle } : { ...missingEntityStyle }}>
                                    <Row>
                                        <Col span={18}>
                                            <Typography.Title level={3}>Transactions</Typography.Title>
                                        </Col>
                                        <Col span={6} style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <Button type="default" size="large" shape="circle" icon={<FontAwesomeIcon icon={faExternalLinkAlt} size="lg" />} onClick={() => { toggleTransactionModal(); }} />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col> */}
                            {serviceType === 'BROKERAGE' ? (
                                <CanSee staffOnly={true}>
                                    <Col xs={24} md={12} lg={12} xl={12}>
                                        <Card
                                            title={
                                                <Typography.Title level={3}>Axle Payments Balance: {stringFormatter.toFormattedMoney(load.factoringBalance)}</Typography.Title>
                                            }
                                            bordered={true}
                                            className={classes.card}
                                            headStyle={isNumberGreaterThanZero(load.factoringBalance) ? { ...missingEntityStyle } : { ...completedEntityStyle }}
                                        >
                                            <Row gutter={[24, 24]}>
                                                <Col span={24}>
                                                    <DataTable
                                                        dataSource={invoiceLineItems.filter(i => (i.fromEntityType === 'FACTORING' || i.toEntityType === 'FACTORING'))}
                                                        columns={invoiceLineItemColumns}
                                                        hidePaging={true}
                                                        rowClassName={getInvoiceLineItemRowClassName}
                                                        title={() => (invoiceLineItemTitle('Axle Payments', 'STAFF', 'FACTORING'))}
                                                        style={{ backgroundColor: '#ffffff', marginTop: 24 }}
                                                        rowKey={record => record.id}
                                                        scroll={{ x: '100%' }}
                                                    />
                                                </Col>
                                                <Col span={24}>
                                                    <DataTable
                                                        dataSource={transactions.filter(t => (t.fromEntityType === 'FACTORING' || t.toEntityType === 'FACTORING'))}
                                                        columns={transactionColumns}
                                                        hidePaging={true}
                                                        rowClassName={getTransactionRowClassName}
                                                        title={() => (transactionTitle(null, 'Axle Payments', 'FACTORING_INVOICE'))}
                                                        style={{ backgroundColor: '#ffffff' }}
                                                        rowKey={record => record.id}
                                                        scroll={{ x: '100%' }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                </CanSee>
                            ) : null}
                        </Row>
                    ) : null}
                </div>

                {showQuotes === true && showInvoicingCards === false ? (
                    <Row gutter={[24, 24]} style={style}>
                        {serviceType === 'BROKERAGE' && (entityType === 'STAFF' || entityType === 'SHIPPER') && LoadUtils.loadStatusIsNot(currentStatus, ['CANCELLED', 'COMPLETED', 'REOPENED', 'CLOSED']) ? (
                            <CanSee entityAction="READ" entityModel="LOAD_PRICE_CONFIRMATION" entityObject={load}>
                                <Col span={24}>
                                    <Card
                                        size="small"
                                        title={`Load Tender: ${isObjectNotEmpty(priceConfirmation) && isObjectNotEmpty(priceConfirmation.document) ? 'Uploaded' : (isObjectNotEmpty(priceConfirmation) && isObjectNotEmpty(priceConfirmation.invoice) && isStringNotEmpty(priceConfirmation.invoice.status) ? (priceConfirmation.invoice.status === 'COMPLETED' ? 'Signed' : Enums.InvoiceStatuses.getValueByName(priceConfirmation.invoice.status)) : 'Needs to be Sent')}`}
                                        bordered={true}
                                        className={classes.card}
                                        headStyle={headStyle}
                                    >
                                        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoadingDocuments === true}>
                                            {LoadUtils.loadStatusIsNot(load.loadStatus, ['CLOSED']) && isObjectNotEmpty(priceConfirmation) ? (
                                                <Dropdown overlay={quoteMenu(priceConfirmation)} trigger={['click']}>
                                                    <Button>Actions <DownOutlined /></Button>
                                                </Dropdown>
                                            ) : null}
                                        </Spin>
                                    </Card>
                                </Col>
                            </CanSee>
                        ) : null}
                        {((serviceType === 'BROKERAGE' && (entityType === 'STAFF' || entityType === 'CARRIER')) || serviceType === 'TMS') && LoadUtils.loadStatusIsNot(currentStatus, ['CREATED', 'PENDING', 'COMPLETED', 'REOPENED', 'CLOSED', 'CANCELLED']) ? (
                            <CanSee entityAction="READ" entityModel="LOAD_RATE_CONFIRMATION" entityObject={load}>
                                <Col span={24}>
                                    <Card
                                        size="small"
                                        title={`Rate Confirmation: ${isObjectNotEmpty(rateConfirmation) && isObjectNotEmpty(rateConfirmation.document) ? 'Uploaded' : (isObjectNotEmpty(rateConfirmation) && isObjectNotEmpty(rateConfirmation.invoice) && isStringNotEmpty(rateConfirmation.invoice.status) ? (rateConfirmation.invoice.status === 'COMPLETED' ? 'Signed' : (rateConfirmation.invoice.status === 'SENT' && entityType === 'CARRIER' ? 'Pending Acceptance' : Enums.InvoiceStatuses.getValueByName(rateConfirmation.invoice.status))) : 'Needs to be Sent')}`}
                                        bordered={true}
                                        className={classes.card}
                                        headStyle={headStyle}
                                    >
                                        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoadingDocuments === true}>
                                            {LoadUtils.loadStatusIsNot(load.loadStatus, ['CLOSED']) && isObjectNotEmpty(rateConfirmation) ? (
                                                <Dropdown overlay={quoteMenu(rateConfirmation)} trigger={['click']}>
                                                    <Button>Actions <DownOutlined /></Button>
                                                </Dropdown>
                                            ) : null}
                                        </Spin>
                                    </Card>
                                </Col>
                            </CanSee>
                        ) : null}
                    </Row>
                ) : null}

                {showCompletionChecklist === true ? (
                    <CanSee entityAction="READ" entityModel="LOAD_COMPLETION_CHECKLIST" entityObject={load}>
                        <Card title={title} bordered={true} className={classes.card} extra={extra}>
                            <Alert style={{ marginBottom: 8 }} message="Proof of Delivery Is Uploaded" type={(isObjectNotEmpty(load.completionCheckList) && (isStringEmpty(load.completionCheckList.proofOfDeliveryStatus) || load.completionCheckList.proofOfDeliveryStatus === 'MISSING')) || isBooleanTrue(hasMissingProofOfDelivery) ? "error" : "success"} showIcon />
                            <Alert style={{ marginBottom: 8 }} message="Proof of Delivery Is Reviewed" type={(isObjectNotEmpty(load.completionCheckList) && isStringNotEmpty(load.completionCheckList.proofOfDeliveryStatus) && load.completionCheckList.proofOfDeliveryStatus === 'UPLOADED') || isBooleanTrue(hasPendingProofOfDelivery) ? "error" : "success"} showIcon />
                            <Alert style={{ marginBottom: 8 }} message="All Required Documents Are Uploaded" type={(isObjectNotEmpty(load.completionCheckList) && isBooleanTrue(load.completionCheckList.hasDocumentsReviewed)) || isBooleanFalse(hasMissingDocuments) ? "success" : "error"} showIcon />
                            <Alert style={{ marginBottom: 8 }} message="All Documents Are Reviewed" type={isObjectNotEmpty(load.completionCheckList) && isBooleanTrue(load.completionCheckList.hasDocumentsReviewed) && isBooleanFalse(hasUnreviewedDocuments) ? "success" : "error"} showIcon />
                            {load.serviceType === 'BROKERAGE' ? (
                                <>
                                    <Alert style={{ marginBottom: 8 }} message={`Carrier Invoice Status: ${isObjectNotEmpty(load.completionCheckList) && isStringNotEmpty(load.completionCheckList.carrierInvoiceStatus) ? stringFormatter.toFormattedString("InvoiceStatus", load.completionCheckList.carrierInvoiceStatus) : "Missing"}`} type={isObjectNotEmpty(load.completionCheckList) && isStringNotEmpty(load.completionCheckList.carrierInvoiceStatus) ? (load.completionCheckList.carrierInvoiceStatus === 'COMPLETED' ? "success" : (load.completionCheckList.carrierInvoiceStatus === 'SENT' ? "success" : "error")) : "error"} showIcon />
                                    <Alert style={{ marginBottom: 8 }} message={`Shipper Invoice Status: ${isObjectNotEmpty(load.completionCheckList) && isStringNotEmpty(load.completionCheckList.shipperInvoiceStatus) ? stringFormatter.toFormattedString("InvoiceStatus", load.completionCheckList.shipperInvoiceStatus) : "Missing"}`} type={isObjectNotEmpty(load.completionCheckList) && isStringNotEmpty(load.completionCheckList.shipperInvoiceStatus) ? (load.completionCheckList.shipperInvoiceStatus === 'COMPLETED' ? "success" : (load.completionCheckList.shipperInvoiceStatus === 'SENT' ? "success" : "error")) : "error"} showIcon />
                                </>
                            ) : (
                                <Alert style={{ marginBottom: 8 }} message={`Invoice Status: ${isObjectNotEmpty(load.completionCheckList) && isStringNotEmpty(load.completionCheckList.carrierInvoiceStatus) ? stringFormatter.toFormattedString("InvoiceStatus", load.completionCheckList.carrierInvoiceStatus) : "Missing"}`} type={isObjectNotEmpty(load.completionCheckList) && isStringNotEmpty(load.completionCheckList.carrierInvoiceStatus) ? (load.completionCheckList.carrierInvoiceStatus === 'COMPLETED' ? "success" : (load.completionCheckList.carrierInvoiceStatus === 'SENT' ? "success" : "error")) : "error"} showIcon />
                            )}
                            <Alert style={{ marginBottom: 8 }} message="All Invoice Line Items Are Completed" type={isObjectNotEmpty(load.completionCheckList) && isBooleanFalse(load.completionCheckList.hasPendingInvoiceLineItems) ? "success" : "error"} showIcon />
                            <Alert style={{ marginBottom: 8 }} message="All Transactions Are Completed" type={isObjectNotEmpty(load.completionCheckList) && isBooleanFalse(load.completionCheckList.hasPendingTransactions) ? "success" : "error"} showIcon />
                            <Alert style={{ marginBottom: 8 }} message="All Claims Are Closed" type={isObjectNotEmpty(load.completionCheckList) && isBooleanFalse(load.completionCheckList.hasOpenClaims) ? "success" : "error"} showIcon />
                            <Alert message="Balance Is Paid" type={isObjectNotEmpty(load.completionCheckList) && isBooleanFalse(load.completionCheckList.hasBalance) ? "success" : "error"} showIcon />
                        </Card>
                    </CanSee>
                ) : null}
                {editCompletionChecklistDrawer}
                {editMissingDocumentDrawer}
                {reviewDocumentDrawer}
                {viewDocumentDrawer}
                {missingDocumentsModal}
                {missingPODModal}
                {reviewDocumentsModal}
                {reviewPODModal}
                {viewDocumentsModal}
                {viewPODModal}
                {invoiceNotesModal}
                {newPriceConfirmationModal}
                {newRateConfirmationModal}
                {newShipperInvoiceModal}
                {newCarrierInvoiceModal}
                {resendPriceConfirmationModal}
                {resendRateConfirmationModal}
                {resendShipperInvoiceModal}
                {updatePriceConfirmationModal}
                {updateRateConfirmationModal}
                {updateShipperInvoiceModal}
                {updateCarrierInvoiceModal}
                {viewInvoiceModal}
                {editInvoiceComponents}
                {invoiceLineItemModal}
                {transactionModal}
                {shipperInvoiceModal}
                {carrierInvoiceModal}
                {factoringInvoiceModal}
                {claimsModal}
                {newInvoiceLineItemDrawer}
                {editInvoiceLineItemDrawer}
                {editStatusInvoiceLineItemDrawer}
                {editApprovalStatusInvoiceLineItemDrawer}
                {cancelInvoiceLineItemDrawer}
                {removeInvoiceLineItemDrawer}
                {newTransactionDrawer}
                {editTransactionDrawer}
                {editPaymentStatusTransactionDrawer}
                {removeTransactionDrawer}
                {editMissingPedigreeInformationDrawer}
                {sendLoadFinalizationEmailModal}
            </>
        );
    } else {
        return null;
    }
};

export default LoadCompletionCard;