import * as actionTypes from "../actions/actionTypes";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import ErrorUtils from "../../shared/errorUtils";
import * as actionCreators from "./index";

//#region Fetch Load Cancellation List Methods

export const fetchLoadCancellationListStart = (listName) => {
    return {
        type: actionTypes.FETCH_LOAD_CANCELLATION_LIST_START,
        payload: { listName: listName }
    }
};

export const fetchLoadCancellationListSuccess = (listName, payload) => {
    return {
        type: actionTypes.FETCH_LOAD_CANCELLATION_LIST_SUCCESS,
        payload: { listName: listName, ...payload }
    }
};

export const fetchLoadCancellationListFail = (listName, payload) => {
    return {
        type: actionTypes.FETCH_LOAD_CANCELLATION_LIST_FAIL,
        payload: { listName: listName, ...payload }
    }
};

export const clearLoadCancellationList = (listName) => {
    return {
        type: actionTypes.CLEAR_LOAD_CANCELLATION_LIST,
        payload: { listName: listName }
    }
};

export const clearLoadCancellationLists = () => {
    return {
        type: actionTypes.CLEAR_LOAD_CANCELLATION_LISTS
    }
};

export const addLoadCancellationToLoadCancellationList = (listName, payload) => {
    return {
        type: actionTypes.ADD_TO_LOAD_CANCELLATION_LIST,
        payload: { listName: listName, newRecord: payload }
    }
};

export const updateLoadCancellationInLoadCancellationList = (listName, payload) => {
    return {
        type: actionTypes.UPDATE_IN_LOAD_CANCELLATION_LIST,
        payload: { listName: listName, updatedRecord: payload }
    }
};

//#endregion
//#region Add Load Cancellation Methods

export const addLoadCancellationStart = () => {
    return {
        type: actionTypes.ADD_LOAD_CANCELLATION_START
    }
};

export const addLoadCancellationSuccess = () => {
    return {
        type: actionTypes.ADD_LOAD_CANCELLATION_SUCCESS
    }
};

export const addLoadCancellationFail = (payload) => {
    return {
        type: actionTypes.ADD_LOAD_CANCELLATION_FAIL,
        payload: payload
    }
};

export const addLoadCancellationLoadingClear = () => {
    return {
        type: actionTypes.ADD_LOAD_CANCELLATION_LOADING_CLEAR
    }
};

export const addLoadCancellationErrorClear = () => {
    return {
        type: actionTypes.ADD_LOAD_CANCELLATION_ERROR_CLEAR
    }
};

export const addLoadCancellationCancel = () => {
    return {
        type: actionTypes.ADD_LOAD_CANCELLATION_CANCEL
    }
};

//#endregion
//#region Update Load Cancellation Methods

export const updateLoadCancellationStart = () => {
    return {
        type: actionTypes.UPDATE_LOAD_CANCELLATION_START
    }
};

const changeSingleLoadCancellation = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_LOAD_CANCELLATION,
        payload: payload
    }
};

export const updateLoadCancellationSuccess = () => {
    return {
        type: actionTypes.UPDATE_LOAD_CANCELLATION_SUCCESS
    }
};

export const updateLoadCancellationFail = (payload) => {
    return {
        type: actionTypes.UPDATE_LOAD_CANCELLATION_FAIL,
        payload: payload
    }
};

export const updateLoadCancellationLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_LOAD_CANCELLATION_LOADING_CLEAR
    }
};

export const updateLoadCancellationErrorClear = () => {
    return {
        type: actionTypes.UPDATE_LOAD_CANCELLATION_ERROR_CLEAR
    }
};

export const updateLoadCancellationCancel = () => {
    return {
        type: actionTypes.UPDATE_LOAD_CANCELLATION_CANCEL
    }
};

//#endregion
//#region Load Cancellations Methods

export const fetchLoadCancellationList = (listName, payload) => {
    return async (dispatch, getState) => {
        if (isStringNotEmpty(listName)) {
            try {
                dispatch(fetchLoadCancellationListStart(listName));

                await dispatch(actionCreators.getAccounts());
                const state = getState();
                const orchestratorState = { ...state.orchestrator };
                const accounts = [...orchestratorState.accounts];
                const loadCancellationsState = { ...state.loadCancellations };
                const existingLists = { ...loadCancellationsState.lists };

                let pagination = {};
                let searchParams = {};
                if (isObjectNotEmpty(existingLists[listName])) {
                    pagination = { ...existingLists[listName].pagination };
                    searchParams = { ...existingLists[listName].searchParams };
                }

                if (isObjectNotEmpty(payload)) {
                    searchParams = { ...payload };
                }

                const res = await Data.getLoadCancellations({ ...searchParams }, pagination, accounts);
                dispatch(fetchLoadCancellationListSuccess(listName, { records: res.data, params: { searchParams: res.searchParams, pagination: res.pagination } }));
            } catch (error) {
                logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
                dispatch(fetchLoadCancellationListFail(listName, { error: ErrorUtils.getErrorMessage(error) }));
            }
        }
    }
};

export const addLoadCancellation = (payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(addLoadCancellationStart());

            await dispatch(actionCreators.getAccounts());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const accounts = [...orchestratorState.accounts];

            const newLoadCancellation = await Data.addLoadCancellation(payload, accounts);
            if (isObjectNotEmpty(newLoadCancellation)) {
                dispatch(addLoadCancellationToLoadCancellationList(newLoadCancellation.loadId, newLoadCancellation));
            }

            dispatch(addLoadCancellationSuccess());
            dispatch(addLoadCancellationLoadingClear());
            dispatch(addLoadCancellationErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addLoadCancellationFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateLoadCancellation = (id, payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updateLoadCancellationStart());

            // can only update load cancellations, not delete them

            await dispatch(actionCreators.getAccounts());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const accounts = [...orchestratorState.accounts];

            const updatedLoadCancellation = await Data.updateLoadCancellation(id, payload, accounts);
            if (isObjectNotEmpty(updatedLoadCancellation)) {
                dispatch(updateLoadCancellationInLoadCancellationList(updatedLoadCancellation.loadId, updatedLoadCancellation));
                dispatch(changeSingleLoadCancellation(updatedLoadCancellation));
            }

            dispatch(updateLoadCancellationSuccess());
            dispatch(updateLoadCancellationLoadingClear());
            dispatch(updateLoadCancellationErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateLoadCancellationFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion