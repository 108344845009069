import React, { useMemo, useState } from 'react';
import { Input } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import Fieldset from '../FormFieldset/FormFieldset';
import FormButtons from '../FormButtons/FormButtons';
import { isStringNotEmpty } from '../../shared/objectUtils';
import FormItemEIN from '../FormItemEIN/FormItemEIN';

const NewProducer = ({ cancel }) => {
    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.accounts.isRecordAddLoading);
    const error = useSelector(state => state.accounts.addRecordError);
    const entityType = useSelector(state => state.auth.entityType);

    const [accountEIN, setAccountEIN] = useState(null);
    const [hasValidAccountEIN, setHasValidAccountEIN] = useState(false);

    const onSubmit = (data) => {
        data.entityTypes = ["PRODUCER"];

        let einIsValid = false;
        if (isStringNotEmpty(data.ein) && hasValidAccountEIN === true) {
            einIsValid = true;
        }

        if (einIsValid === true) {
            dispatch(actionCreators.addAccount(data, null, null, null, null, null, entityType, null));
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.addAccountCancel());
        cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new account creation
        dispatch(actionCreators.addAccountLoadingClear());
        dispatch(actionCreators.addAccountErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Fieldset legend="Company Details (* indicates a required field)">
                    <FormItemEIN
                        format="vertical"
                        required
                        name="ein"
                        defaultValue={""}
                        accountEIN={accountEIN}
                        setAccountEIN={setAccountEIN}
                        hasValidAccountEIN={hasValidAccountEIN}
                        setHasValidAccountEIN={setHasValidAccountEIN}
                    />
                    <FormItem {...formItemLayout} label="Company Name" required format="vertical"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Input
                                placeholder="Company Name"
                                onBlur={onBlur}
                                onChange={e => { onChange(e.target.value); }}
                                value={value}
                                name={name}
                                ref={ref}
                            />
                        )}
                        rules={{
                            required: 'Required Field',
                            minLength: { value: 4, message: 'Company Name must be at least 4 characters long' },
                        }}
                        name="name"
                        defaultValue={""}
                    />
                </Fieldset>
                <FormButtons cancel={onCancel} submitDisabled={hasValidAccountEIN === false} submitText="Add Producer" />
            </Form>
        </FormProvider>
    );
};

export default NewProducer;