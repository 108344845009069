import React, { useMemo, useState } from "react";
import { Col, Row, Input, Select, AutoComplete } from "antd";
import { useFormContext } from "react-hook-form";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import FormItem from "../FormItem/FormItem";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useDeepCompareEffect } from "../../shared/useDeepCompareEffect";

const { Option } = Select;

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const FormItemAddress = ({ format = 'vertical', required = false, name, defaultValue, isPostalAddress = false }) => {
    //#region useForms

    const methods = useFormContext();

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const states = useSelector(state => state.orchestrator.states);
    const isAdmin = useSelector(state => state.auth.isAdmin);

    //#endregion

    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = usePlacesService({
        apiKey: apiKey,
        debounce: 3000
    });

    //#region useStates

    const [options, setOptions] = useState([]);
    const [results, setResults] = useState([]);
    const [searchText, setSearchText] = useState(null);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [isSearching, setIsSearching] = useState(false);

    //#endregion
    //#region useEffects and useMemos

    useDeepCompareEffect(() => {
        console.log(placePredictions)

        if (isListNotEmpty(placePredictions)) {
            setResults(placePredictions);
            setOptions(placePredictions.map((match) => {
                return {
                    label: match.description,
                    value: match.description
                };
            }));
        } else {
            setOptions([]);
            setResults([]);
        }
    }, [placePredictions]);

    useMemo(() => {
        dispatch(actionCreators.getStates());
    }, []);

    //#endregion
    //#region autocomplete methods

    const onSearch = async (value) => {
        getPlacePredictions({ input: value });
    };

    const onSelect = (value) => {
        console.log('onSelect', value);
        const match = results.find(r => r.description === value);

        // fetch place details for the first element in placePredictions array
        if (placesService && isObjectNotEmpty(match)) {
            placesService.getDetails(
                {
                    placeId: match.place_id,
                },
                (placeDetails) => {
                    console.log(placeDetails)
                    if (isObjectNotEmpty(placeDetails)) {

                        let streetAddress1 = "";
                        let city = "";
                        let state = "";
                        let postalCode = "";
                        let country = "";

                        // Get each component of the address from the place details,
                        // and then fill-in the corresponding field on the form.
                        // place.address_components are google.maps.GeocoderAddressComponent objects
                        // which are documented at http://goo.gle/3l5i5Mr
                        for (const component of placeDetails.address_components) {
                            // @ts-ignore remove once typings fixed
                            const componentType = component.types[0];

                            switch (componentType) {
                                case "street_number": {
                                    streetAddress1 = `${component.long_name} ${streetAddress1}`;
                                    break;
                                }

                                case "route": {
                                    streetAddress1 += component.short_name;
                                    break;
                                }

                                case "postal_code": {
                                    postalCode = `${component.long_name}${postalCode}`;
                                    break;
                                }

                                case "postal_code_suffix": {
                                    postalCode = `${postalCode}-${component.long_name}`;
                                    break;
                                }
                                case "locality":
                                    city = component.long_name;
                                    break;
                                case "administrative_area_level_1": {
                                    state = component.short_name;
                                    break;
                                }
                                case "country":
                                    country = component.long_name;
                                    break;
                            }
                        }

                        setSelectedAddress(placeDetails.formatted_address);
                        if (isStringNotEmpty(streetAddress1)) {
                            methods.setValue(`${name}.streetAddress1`, streetAddress1);
                        }
                        if (isStringNotEmpty(city)) {
                            methods.setValue(`${name}.city`, city);
                        }
                        if (isStringNotEmpty(state)) {
                            methods.setValue(`${name}.state`, state);
                        }
                        if (isStringNotEmpty(postalCode)) {
                            methods.setValue(`${name}.postalCode`, postalCode);
                        }
                        if (isStringNotEmpty(country)) {
                            methods.setValue(`${name}.country`, country);
                        }
                    }
                }
            );
        }
    };

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { sm: 24, md: 12 },
        wrapperCol: { sm: 24, md: 12 },
    };

    const formItemLayoutHorizontal = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    //#endregion

    if (format === 'vertical') {
        return (
            <Row gutter={[8, 8]} style={{ marginBottom: 8 }}>
                <Col span={24}>
                    <AutoComplete
                        dropdownMatchSelectWidth={false}
                        style={{ width: '100%' }}
                        options={options}
                        onSelect={onSelect}
                        onSearch={onSearch}
                    >
                        <Input
                            placeholder="Enter Address Here"
                            addonAfter={isPlacePredictionsLoading ? <LoadingOutlined /> : <SearchOutlined />}
                            allowClear={true}
                        />
                    </AutoComplete>
                </Col>
                <Col lg={12} md={24}>
                    <FormItem {...formItemLayout} label="Street Address 1" format="vertical" required={required}
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Input
                                onBlur={onBlur}
                                onChange={e => { onChange(e.target.value); }}
                                value={value}
                                name={name}
                                placeholder="Street Address 1"
                                ref={ref}
                                disabled={((isObjectNotEmpty(selectedAddress) && isAdmin === true) || isPostalAddress === true) ? false : true}
                            />
                        )}
                        rules={{ required: required ? 'Required Field' : false }}
                        name={`${name}.streetAddress1`}
                        defaultValue={isObjectNotEmpty(defaultValue) && isStringNotEmpty(defaultValue.streetAddress1) ? defaultValue.streetAddress1 : ""}
                    />
                </Col>
                <Col lg={12} md={24}>
                    <FormItem {...formItemLayout} label="Street Address 2" format="vertical"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Input
                                onBlur={onBlur}
                                onChange={e => { onChange(e.target.value); }}
                                value={value}
                                name={name}
                                placeholder="Street Address 2"
                                ref={ref}
                                disabled={(isObjectNotEmpty(selectedAddress) || isPostalAddress === true || isAdmin === true) ? false : true}
                            />
                        )}
                        rules={{ required: false }}
                        name={`${name}.streetAddress2`}
                        defaultValue={isObjectNotEmpty(defaultValue) && isStringNotEmpty(defaultValue.streetAddress2) ? defaultValue.streetAddress2 : ""}
                    />
                </Col>
                <Col lg={12} md={24}>
                    <FormItem {...formItemLayout} label="City" format="vertical" required={required}
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Input
                                onBlur={onBlur}
                                onChange={e => { onChange(e.target.value); }}
                                value={value}
                                name={name}
                                placeholder="City"
                                ref={ref}
                                disabled={((isObjectNotEmpty(selectedAddress) && isAdmin === true) || isPostalAddress === true) ? false : true}
                            />
                        )}
                        rules={{ required: required ? 'Required Field' : false }}
                        name={`${name}.city`}
                        defaultValue={isObjectNotEmpty(defaultValue) && isStringNotEmpty(defaultValue.city) ? defaultValue.city : ""}
                    />
                </Col>
                <Col lg={12} md={24}>
                    <FormItem {...formItemLayout} label="State" format="vertical" required={required}
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Select
                                placeholder="Please Select a State"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                                showSearch={true}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                ref={ref}
                                disabled={((isObjectNotEmpty(selectedAddress) && isAdmin === true) || isPostalAddress === true) ? false : true}
                            >
                                {states.map(i => <Option value={i.stateAbbr} key={i.stateAbbr}>{i.stateName}</Option>)}
                            </Select>
                        )}
                        rules={{ required: required ? 'Required Field' : false }}
                        name={`${name}.state`}
                        defaultValue={isObjectNotEmpty(defaultValue) && isStringNotEmpty(defaultValue.state) ? defaultValue.state : undefined}
                    />
                </Col>
                <Col lg={12} md={24}>
                    <FormItem {...formItemLayout} label="Postal Code" format="vertical" required={required}
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Input
                                onBlur={onBlur}
                                onChange={e => { onChange(e.target.value); }}
                                value={value}
                                name={name}
                                placeholder="Postal Code"
                                ref={ref}
                                disabled={((isObjectNotEmpty(selectedAddress) && isAdmin === true) || isPostalAddress === true) ? false : true}
                            />
                        )}
                        rules={{ required: required ? 'Required Field' : false }}
                        name={`${name}.postalCode`}
                        defaultValue={isObjectNotEmpty(defaultValue) && isStringNotEmpty(defaultValue.postalCode) ? defaultValue.postalCode : ""}
                    />
                </Col>
                <Col lg={12} md={24}>
                    <FormItem {...formItemLayout} label="Country" format="vertical" required={required}
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Select
                                placeholder="Please Select a Country"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                                showSearch={true}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                ref={ref}
                                disabled={((isObjectNotEmpty(selectedAddress) && isAdmin === true) || isPostalAddress === true) ? false : true}
                            >
                                <Option value="USA" key="USA">United States</Option>
                            </Select>
                        )}
                        rules={{ required: required ? 'Required Field' : false }}
                        name={`${name}.country`}
                        defaultValue={isObjectNotEmpty(defaultValue) && isStringNotEmpty(defaultValue.country) ? defaultValue.country : 'USA'}
                    />
                </Col>
            </Row>
        );
    } else if (format === 'horizontal') {
        return (
            <Row gutter={[8, 8]} style={{ marginBottom: 8 }}>
                <Col span={16} offset={8}>
                    <AutoComplete
                        dropdownMatchSelectWidth={false}
                        style={{ width: '100%' }}
                        options={options}
                        onSelect={onSelect}
                        onSearch={onSearch}
                    >
                        <Input
                            placeholder="Enter Address Here"
                            addonAfter={isPlacePredictionsLoading ? <LoadingOutlined /> : <SearchOutlined />}
                            allowClear={true}
                        />
                    </AutoComplete>
                </Col>
                <Col span={24}>
                    <FormItem {...formItemLayoutHorizontal} label="Street Address 1" format="horizontal" required={required}
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Input
                                onBlur={onBlur}
                                onChange={e => { onChange(e.target.value); }}
                                value={value}
                                name={name}
                                placeholder="Street Address 1"
                                ref={ref}
                                disabled={((isObjectNotEmpty(selectedAddress) && isAdmin === true) || isPostalAddress === true) ? false : true}
                            />
                        )}
                        rules={{ required: required ? 'Required Field' : false }}
                        name={`${name}.streetAddress1`}
                        defaultValue={isObjectNotEmpty(defaultValue) && isStringNotEmpty(defaultValue.streetAddress1) ? defaultValue.streetAddress1 : ""}
                    />
                    <FormItem {...formItemLayoutHorizontal} label="Street Address 2" format="horizontal"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Input
                                onBlur={onBlur}
                                onChange={e => { onChange(e.target.value); }}
                                value={value}
                                name={name}
                                placeholder="Street Address 2"
                                ref={ref}
                                disabled={(isObjectNotEmpty(selectedAddress) || isPostalAddress === true || isAdmin === true) ? false : true}
                            />
                        )}
                        rules={{ required: false }}
                        name={`${name}.streetAddress2`}
                        defaultValue={isObjectNotEmpty(defaultValue) && isStringNotEmpty(defaultValue.streetAddress2) ? defaultValue.streetAddress2 : ""}
                    />
                    <FormItem {...formItemLayoutHorizontal} label="City" format="horizontal" required={required}
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Input
                                onBlur={onBlur}
                                onChange={e => { onChange(e.target.value); }}
                                value={value}
                                name={name}
                                placeholder="City"
                                ref={ref}
                                disabled={((isObjectNotEmpty(selectedAddress) && isAdmin === true) || isPostalAddress === true) ? false : true}
                            />
                        )}
                        rules={{ required: required ? 'Required Field' : false }}
                        name={`${name}.city`}
                        defaultValue={isObjectNotEmpty(defaultValue) && isStringNotEmpty(defaultValue.city) ? defaultValue.city : ""}
                    />
                    <FormItem {...formItemLayoutHorizontal} label="State" format="horizontal" required={required}
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Select
                                placeholder="Please Select a State"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                                showSearch={true}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                ref={ref}
                                disabled={((isObjectNotEmpty(selectedAddress) && isAdmin === true) || isPostalAddress === true) ? false : true}
                            >
                                {states.map(i => <Option value={i.stateAbbr} key={i.stateAbbr}>{i.stateName}</Option>)}
                            </Select>
                        )}
                        rules={{ required: required ? 'Required Field' : false }}
                        name={`${name}.state`}
                        defaultValue={isObjectNotEmpty(defaultValue) && isStringNotEmpty(defaultValue.state) ? defaultValue.state : undefined}
                    />
                    <FormItem {...formItemLayoutHorizontal} label="Postal Code" format="horizontal" required={required}
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Input
                                onBlur={onBlur}
                                onChange={e => { onChange(e.target.value); }}
                                value={value}
                                name={name}
                                placeholder="Postal Code"
                                ref={ref}
                                disabled={((isObjectNotEmpty(selectedAddress) && isAdmin === true) || isPostalAddress === true) ? false : true}
                            />
                        )}
                        rules={{ required: required ? 'Required Field' : false }}
                        name={`${name}.postalCode`}
                        defaultValue={isObjectNotEmpty(defaultValue) && isStringNotEmpty(defaultValue.postalCode) ? defaultValue.postalCode : ""}
                    />
                    <FormItem {...formItemLayoutHorizontal} label="Country" format="horizontal" required={required}
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Select
                                placeholder="Please Select a Country"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                                showSearch={true}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                ref={ref}
                                disabled={((isObjectNotEmpty(selectedAddress) && isAdmin === true) || isPostalAddress === true) ? false : true}
                            >
                                <Option value="USA" key="USA">United States</Option>
                            </Select>
                        )}
                        rules={{ required: required ? 'Required Field' : false }}
                        name={`${name}.country`}
                        defaultValue={defaultValue && defaultValue.country ? defaultValue.country : 'USA'}
                    />
                </Col>
            </Row>
        );
    } else {
        return null;
    }
};

export default FormItemAddress;