import React, { useMemo } from 'react';
import { Alert, Spin, InputNumber, Row, Col } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import { isNotNullOrUndefined, isNumberNotEmpty, isObjectNotEmpty } from '../../shared/objectUtils';
import LaneUtils from '../../api/utils/laneUtils';
import { CanSee } from '../../shared/entitlements/entitlements';
import FormItem from '../FormItem/FormItem';
import LoadMap from '../LoadMap/LoadMap';

const EditLane = ({ lane, cancel }) => {
    //#region useForms

    const methods = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            rate: lane.rate ? lane.rate : null
        }
    });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.lanes.isRecordUpdateLoading);
    const error = useSelector(state => state.lanes.updateRecordError);

    //#endregion
    //#region submit and cancel

    const onSubmit = (data) => {
        let payload = {};
        let objectToUpdate = { ...lane };
        for (let [key, value] of Object.entries(data)) {
            let oldValue = objectToUpdate[key] !== undefined && objectToUpdate[key] !== null ? objectToUpdate[key] : '';
            if (value !== oldValue) {
                //console.log(`${key}: new value - ${value}, old value - ${oldValue}`);
                payload[key] = value;
            }
        }

        if (isObjectNotEmpty(payload)) {
            if (isNotNullOrUndefined(payload.rate)) {
                payload.rateUnit = 'USD';
            }
            dispatch(actionCreators.updateLane(lane.id, payload));
        } else {
            onCancel();
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.updateLaneCancel());
        cancel();
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLaneErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    //#endregion
    //#region displays

    const getStopDetails = (stopType, city, state) => {
        const stop = {
            stopType: stopType
        };
        const stopIcon = LaneUtils.getLaneStopMarkerIcon(stop);

        return (
            <div>
                <Row style={{ marginTop: 36, marginBottom: 36 }}>
                    <Col span={24}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: 30, marginRight: 12 }}>
                                {isNotNullOrUndefined(stopIcon) ? (<img src={stopIcon} alt={LaneUtils.getLaneStopTypeDisplay(stop)} style={{ width: 30, height: 30 }} />) : null}
                            </div>
                            <div style={{ flex: 1 }}>
                                <span>{city}, {state}</span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    };

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol1: { span: 24 },
        wrapperCol1: { span: 24 }
    };

    //#endregion

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true && error === null)}>
                    <Fieldset legend="Origin and Destination">
                        <Row gutter={[24, 24]}>
                            <Col span={24}>
                                <LoadMap
                                    loadId={isObjectNotEmpty(lane) ? lane.id : null}
                                    stops={isObjectNotEmpty(lane) && isNumberNotEmpty(lane.originLongitude) && isNumberNotEmpty(lane.originLatitude) && isNumberNotEmpty(lane.destinationLongitude) && isNumberNotEmpty(lane.destinationLatitude) ? [
                                        {
                                            longitude: lane.originLongitude,
                                            latitude: lane.originLatitude,
                                            stopType: 'PICK_UP',
                                            id: 0,
                                            loadId: lane.id
                                        },
                                        {
                                            longitude: lane.destinationLongitude,
                                            latitude: lane.destinationLatitude,
                                            stopType: 'DROP_OFF',
                                            id: 1,
                                            loadId: lane.id
                                        }
                                    ] : []}
                                    containerName={isObjectNotEmpty(lane) ? `map-${lane.id}` : 'map'}
                                    height="300px"
                                />
                            </Col>
                            <Col span={12}>
                                {getStopDetails('PICK_UP', lane.originCity, lane.originState)}
                            </Col>
                            <Col span={12}>
                                {getStopDetails('DROP_OFF', lane.destinationCity, lane.destinationState)}
                            </Col>
                        </Row>
                    </Fieldset>
                    <Fieldset legend="Lane Details">
                        <CanSee staffOnly={true}>
                            <FormItem {...formItemLayout} label="Customer Rate"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <InputNumber
                                        min={0}
                                        precision={2}
                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        placeholder="Customer Rate"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    />
                                )}
                                rules={{ required: false }}
                                name="rate"
                                defaultValue={lane.rate}
                            />
                        </CanSee>
                    </Fieldset>

                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Update Lane" />
            </Form>
        </FormProvider>
    );
};

export default EditLane;