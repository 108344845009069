import React, { useMemo, useState } from 'react';
import { Alert, Input, Select, Spin, InputNumber, Drawer, Button, Row, Col, Tooltip, Switch, Checkbox, Radio } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import UploadFile from "../UploadFile/UploadFile";
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import { isMomentDate, isListEmpty, isListNotEmpty, isNotNullOrUndefined, isObjectNotEmpty, isStringNotEmpty, isNullOrUndefined } from '../../shared/objectUtils';
import FormItemFile from '../FormItemFile/FormItemFile';
import FormButtons from '../FormButtons/FormButtons';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import Fieldset from '../FormFieldset/FormFieldset';
import Enums from '../../shared/enums';
import StringFormatter from '../../shared/stringFormatter';
import DataRow from '../DataRow/DataRow';
import FormItemDisplay from '../FormItemDisplay/FormItemDisplay';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { CanDo } from '../../shared/entitlements/entitlements';
import FormItemAddress from '../FormItemAddress/FormItemAddress';
import FormItemPointOfContact from '../FormItemPointOfContact/FormItemPointOfContact';
import moment from 'moment';
import DatePicker from '../DatePickerAutoAccept/DatePickerAutoAccept';
import { selectListRecords } from '../../store/utility';
import NewLinkedFactoring from '../NewLinkedFactoring/NewLinkedFactoring';
import DataContactInfoRow from '../DataContactInfoRow/DataContactInfoRow';
import DataAddressRow from '../DataAddressRow/DataAddressRow';
import FormItemEIN from '../FormItemEIN/FormItemEIN';
import FormItemPhoneNumber from '../FormItemPhoneNumber/FormItemPhoneNumber';
import FormItemDOTNumber from '../FormItemDOTNumber/FormItemDOTNumber';
import FormItemMCNumber from '../FormItemMCNumber/FormItemMCNumber';

const { Option } = Select;
const stringFormatter = new StringFormatter();

const EditAccount = ({ cancel, account }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    //#endregion
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const methods2 = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.accounts.isRecordUpdateLoading);
    const error = useSelector(state => state.accounts.updateRecordError);
    const services = useSelector(state => state.orchestrator.services);
    const entityType = useSelector(state => state.auth.entityType);
    const linkedAccounts = useSelector(state => selectListRecords(state.orchestrator.linkedAccounts, account.id));

    //#endregion
    //#region enums

    const currencyOptions = Enums.Currencies.selectListOptions();
    const billingFrequencyOptions = Enums.BillingFrequencies.selectListOptions();

    //#endregion
    //#region useStates

    const [accountEIN, setAccountEIN] = useState(account.ein ? account.ein : null);
    const [accountMCNumber, setAccountMCNumber] = useState(account.mcNum ? account.mcNum : null);
    const [accountDOTNumber, setAccountDOTNumber] = useState(account.dotNum ? account.dotNum : null);
    const [hasValidAccountEIN, setHasValidAccountEIN] = useState(false);
    const [hasValidAccountMCNumber, setHasValidAccountMCNumber] = useState(false);
    const [hasValidAccountDOTNumber, setHasValidAccountDOTNumber] = useState(false);
    const [logoFile, setLogoFile] = useState(null);
    const [insuranceExpiryDate, setInsuranceExpiryDate] = useState(null);
    const [w9ExpiryDate, setW9ExpiryDate] = useState(null);
    const [insuranceFile, setInsuranceFile] = useState(null);
    const [w9File, setW9File] = useState(null);
    const [accountServices, setAccountServices] = useState([]);
    const [showAddNewService, setShowAddNewService] = useState(false);
    const [showOverridePrice, setShowOverridePrice] = useState(false);
    const [entityTypes, setEntityTypes] = useState(isListNotEmpty(account.entityTypes) ? account.entityTypes : []);
    const [showNewLinkedFactoring, setShowNewLinkedFactoring] = useState(false);
    const [preferredPaymentMethod, setPreferredPaymentMethod] = useState(account.preferredPaymentMethod ? account.preferredPaymentMethod : null);

    //#endregion
    //#region toggles

    const toggleNewLinkedFactoring = () => {
        setShowNewLinkedFactoring(!showNewLinkedFactoring);
    };

    //#endregion
    //#region onSubmit and onCancel

    const onSubmit = (data) => {
        let payload = {};

        if (isListEmpty(data.entityTypes)) {
            methods.setError("entityTypes", { type: "required", message: "At least one Account Type must be selected." });
            return;
        } else {
            methods.clearErrors("entityTypes");
        }

        let insuranceExpiryDateValue = null;
        let w9ExpiryDateValue = null;
        if (data.entityTypes.includes("CARRIER")) {
            if (isNotNullOrUndefined(w9File)) {
                methods.clearErrors('w9File');
            } else if (isNullOrUndefined(account.w9FileId)) {
                methods.setError('w9File', { type: 'required', message: "Required File" });
            }

            // console.log(insuranceFile);
            if (isNotNullOrUndefined(insuranceFile)) {
                methods.clearErrors('insuranceFile');
            } else if (isNullOrUndefined(account.insuranceFileId)) {
                methods.setError('insuranceFile', { type: 'required', message: "Required File" });
            }

            if ((isNullOrUndefined(w9File) && isNullOrUndefined(account.w9FileId)) || (isNullOrUndefined(insuranceFile) && isNullOrUndefined(account.insuranceFileId))) {
                return;
            }

            if (isNotNullOrUndefined(data.insuranceExpiryDate)) {
                if (isMomentDate(data.insuranceExpiryDate)) {
                    insuranceExpiryDateValue = data.insuranceExpiryDate.utc().toISOString();
                }
                delete data['insuranceExpiryDate'];
            }

            if (isNotNullOrUndefined(data.w9ExpiryDate)) {
                if (isMomentDate(data.w9ExpiryDate)) {
                    w9ExpiryDateValue = data.w9ExpiryDate.utc().toISOString();
                }
                delete data['w9ExpiryDate'];
            }
        }

        let einIsValid = false;
        let mcNumIsValid = false;
        let dotNumIsValid = false;
        if (isStringNotEmpty(payload.ein)) {
            if (hasValidAccountEIN === true) {
                einIsValid = true;
            } else {
                einIsValid = false;
            }
        } else {
            einIsValid = true;
        }
        if (isStringNotEmpty(payload.mcNum)) {
            if (hasValidAccountMCNumber === true) {
                mcNumIsValid = true;
            } else {
                mcNumIsValid = false;
            }
        } else {
            mcNumIsValid = true;
        }
        if (isStringNotEmpty(payload.dotNum)) {
            if (hasValidAccountDOTNumber === true) {
                dotNumIsValid = true;
            } else {
                dotNumIsValid = false;
            }
        } else {
            dotNumIsValid = true;
        }

        if (isListNotEmpty(accountServices)) {
            let newServices = [];
            accountServices.forEach((accountService) => {
                delete accountService['service'];
                newServices.push({ ...accountService });
            });

            data.accountServices = [...newServices];
        } else {
            data.accountServices = [];
        }

        // let objectToUpdate = { ...account };
        // // console.log(objectToUpdate);
        // for (let [key, value] of Object.entries(data)) {
        //     let valueToCompare = value !== undefined && value !== null ? value : '';
        //     let oldValue = objectToUpdate[key] !== undefined && objectToUpdate[key] !== null ? objectToUpdate[key] : '';
        //     if (value && typeof value === 'object' && Object.keys(value).length > 0) {
        //         if (oldValue && typeof oldValue === 'object' && Object.keys(oldValue).length > 0) {
        //             let cleanedValue = removeEmpty(value);
        //             if (!isEquivalent(cleanedValue, oldValue)) {
        //                 //console.log('not equivalent')
        //                 // console.log(`${key}: new value - ${JSON.stringify(value)}, old value - ${JSON.stringify(oldValue)}`);
        //                 payload[key] = value;
        //             }
        //         } else {
        //             let cleanedValue = removeEmpty(value);
        //             // console.log(cleanedValue);
        //             if (isObjectNotEmpty(cleanedValue)) {
        //                 //console.log('not equivalent')
        //                 // console.log(`${key}: new value - ${JSON.stringify(cleanedValue)}, old value - ${JSON.stringify(oldValue)}`);
        //                 payload[key] = cleanedValue;
        //             }
        //         }
        //     } else if (!isEqual(valueToCompare, oldValue)) {
        //         // console.log(`${key}: new value - ${JSON.stringify(valueToCompare)}, old value - ${JSON.stringify(oldValue)}`);
        //         payload[key] = value;
        //     }
        // }

        //console.log(payload);

        if (einIsValid === true && mcNumIsValid === true && dotNumIsValid === true && isObjectNotEmpty(data)) {
            dispatch(actionCreators.updateAccount(account.id, data, logoFile, account.logoFileId, insuranceFile, account.insuranceFileId, insuranceExpiryDateValue, w9File, account.w9FileId, w9ExpiryDateValue, entityType));
        } else {
            onCancel();
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.updateAccountCancel());
        cancel();
    };

    //#endregion
    //#region service methods

    const addService = (data) => {
        //console.log(data);
        let newService = {
            serviceName: data.serviceName
        };

        let service = services.find(i => i.name === data.serviceName);
        if (isObjectNotEmpty(service)) {
            newService.service = service;
        }

        if (data.isPriceOverride === true) {
            newService.isPriceOverride = true;
            newService.priceAmount = data.priceAmount;
            newService.priceAmountUnit = data.priceAmountUnit;
            newService.billingFrequency = data.billingFrequency;
        } else {
            newService.isPriceOverride = false;
            newService.priceAmount = 0;
        }

        //console.log(newServices);
        setAccountServices(oldList => [...oldList, { ...newService }]);
        setShowAddNewService(false);
        setShowOverridePrice(false);
    };

    const removeService = (index) => {
        let updatedServices = [...accountServices];
        updatedServices.splice(index, 1);
        setAccountServices([...updatedServices]);
    };

    const toggleNewService = () => {
        if (showAddNewService === true) {
            setShowAddNewService(false);
            setShowOverridePrice(false);
        } else {
            setShowAddNewService(true);
        }
    };

    const onChangeIsPriceOverride = (value) => {
        //console.log(value);
        if (value === true) {
            setShowOverridePrice(true);
        } else {
            setShowOverridePrice(false);
        }
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        dispatch(actionCreators.getServices());
        dispatch(actionCreators.getStates());
    }, []);

    useMemo(() => {
        if (isObjectNotEmpty(account)) {
            if (isStringNotEmpty(account.ein)) {
                setAccountEIN(account.ein);
                setHasValidAccountEIN(true);
            }
            if (isStringNotEmpty(account.mcNum)) {
                setAccountMCNumber(account.mcNum);
                setHasValidAccountMCNumber(true);
            }
            if (isStringNotEmpty(account.dotNum)) {
                setAccountDOTNumber(account.dotNum);
                setHasValidAccountDOTNumber(true);
            }
            if (isStringNotEmpty(account.preferredPaymentMethod)) {
                setPreferredPaymentMethod(account.preferredPaymentMethod);
            }

            if (isNotNullOrUndefined(account.insuranceExpiryDate)) {
                setInsuranceExpiryDate(moment(account.insuranceExpiryDate));
            }
            if (isNotNullOrUndefined(account.w9ExpiryDate)) {
                setW9ExpiryDate(moment(account.w9ExpiryDate));
            }

            if (isListNotEmpty(account.accountServices)) {
                let existingAccountServices = [];
                account.accountServices.forEach((accountService) => {
                    let service = services.find(i => i.name === accountService.serviceName);
                    existingAccountServices.push({
                        ...accountService,
                        service: service
                    });
                });
                setAccountServices([...existingAccountServices]);
            }
        }
    }, [account]);

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateAccountErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    const formItemLayoutDouble = {
        labelCol1: { span: 24 },
        wrapperCol1: { span: 16 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 8 },
    };

    const formItemLayoutHorizontal = {
        labelCol: { span: 20 },
        wrapperCol: { span: 4 },
    };

    const formItemLayoutDoubleHorizontal = {
        labelCol1: { span: 12 },
        wrapperCol1: { span: 1 },
        labelCol2: { span: 6 },
        wrapperCol2: { span: 5 },
    };

    //#endregion

    return (
        <>
            {isObjectNotEmpty(account) ? (
                <>
                    <FormProvider {...methods}>
                        <Form onSubmit={methods.handleSubmit(onSubmit)}>
                            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                                {entityTypes.includes("CARRIER") || entityTypes.includes("BROKER") ? (
                                    <Fieldset legend={entityTypes.includes("BROKER") ? "Broker Details (* indicates a required field)" : "Carrier Details (* indicates a required field)"}>
                                        <FormItemDOTNumber
                                            format="vertical"
                                            required={true}
                                            name="dotNum"
                                            defaultValue={isObjectNotEmpty(account) && isStringNotEmpty(account.dotNum) ? account.dotNum : ''}
                                            accountDOTNumber={accountDOTNumber}
                                            setAccountDOTNumber={setAccountDOTNumber}
                                            hasValidAccountDOTNumber={hasValidAccountDOTNumber}
                                            setHasValidAccountDOTNumber={setHasValidAccountDOTNumber}
                                            account={account}
                                        />
                                        <FormItemMCNumber
                                            format="vertical"
                                            required={entityTypes.includes("BROKER") === true}
                                            name="mcNum"
                                            defaultValue={isObjectNotEmpty(account) && isStringNotEmpty(account.mcNum) ? account.mcNum : ''}
                                            accountMCNumber={accountMCNumber}
                                            setAccountMCNumber={setAccountMCNumber}
                                            hasValidAccountMCNumber={hasValidAccountMCNumber}
                                            setHasValidAccountMCNumber={setHasValidAccountMCNumber}
                                            account={account}
                                        />
                                    </Fieldset>
                                ) : null}
                                <Fieldset legend="Company Details (* indicates a required field)">
                                    <FormItem {...formItemLayout} label="Company Name" required format="vertical"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Input
                                                placeholder="Company Name"
                                                onBlur={onBlur}
                                                onChange={e => { onChange(e.target.value); }}
                                                value={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        rules={{
                                            required: 'Required Field',
                                            minLength: { value: 4, message: 'Company Name must be at least 4 characters long' },
                                        }}
                                        name="name"
                                        defaultValue={account.name ? account.name : ""}
                                    />
                                    <FormItemEIN
                                        format="vertical"
                                        required={entityTypes.includes("BROKER") === false && entityTypes.includes("CARRIER") === false}
                                        name="ein"
                                        defaultValue={isObjectNotEmpty(account) && isStringNotEmpty(account.ein) ? account.ein : ''}
                                        accountEIN={accountEIN}
                                        setAccountEIN={setAccountEIN}
                                        hasValidAccountEIN={hasValidAccountEIN}
                                        setHasValidAccountEIN={setHasValidAccountEIN}
                                        account={account}
                                    />
                                    <FormItemFile {...formItemLayout} label="Company Logo" name="logoFile" format="vertical">
                                        <UploadFile
                                            beforeUpload={(file) => {
                                                setLogoFile(file);
                                                return false;
                                            }}
                                            onRemove={(file) => {
                                                setLogoFile(null);
                                            }}
                                            message="Please upload a photo of your company's logo."
                                            fileId={account.logoFileId}
                                        />
                                    </FormItemFile>
                                </Fieldset>
                                <Fieldset legend="Company Contact Information (* indicates a required field)">
                                    <FormItemPointOfContact
                                        format="vertical"
                                        required={entityTypes.includes("SHIPPER") || entityTypes.includes("CARRIER") || entityTypes.includes("BROKER")}
                                        defaultValue={account}
                                        emailFieldLabel="Company Email"
                                        showName={false}
                                        showEmail={true}
                                        showPhone={false}
                                    />
                                    <FormItemPointOfContact
                                        format="vertical"
                                        required={entityTypes.includes("SHIPPER") || entityTypes.includes("CARRIER") || entityTypes.includes("BROKER")}
                                        defaultValue={account}
                                        phoneFieldLabel="Company Day Time Phone #"
                                        showPhone={true}
                                        showEmail={false}
                                        showName={false}
                                    />
                                    <FormItemPointOfContact
                                        format="vertical"
                                        required={entityTypes.includes("SHIPPER") || entityTypes.includes("CARRIER") || entityTypes.includes("BROKER")}
                                        defaultValue={account}
                                        phoneFieldLabel="Company After Hours Phone #"
                                        showPhone={true}
                                        showEmail={false}
                                        showName={false}
                                        phoneFieldName="afterHoursPhone"
                                        phoneExtFieldName="afterHoursPhoneExt"
                                    />
                                    <FormItemPhoneNumber
                                        format="vertical"
                                        required={false}
                                        phoneFieldName="fax"
                                        phoneFieldLabel="Company Fax #"
                                        showExtension={false}
                                        defaultValue={account.fax ? account.fax : ""}
                                    />
                                </Fieldset>
                                <Fieldset legend="Point of Contact (* indicates a required field)">
                                    <FormItemPointOfContact
                                        format="penta"
                                        required={account.entityTypes.includes("SHIPPER") || account.entityTypes.includes("CARRIER") || entityTypes.includes("BROKER")}
                                        name="pointOfContact"
                                        defaultValue={account.pointOfContact}
                                    />
                                </Fieldset>
                                <Fieldset legend="Company Address (* indicates a required field)">
                                    <FormItemAddress
                                        format="vertical"
                                        required={account.entityTypes.includes("SHIPPER") || account.entityTypes.includes("CARRIER") || entityTypes.includes("BROKER")}
                                        name="companyAddress"
                                        defaultValue={account.companyAddress}
                                        isPostalAddress={true}
                                    />
                                </Fieldset>
                                {entityTypes.includes("SHIPPER") ? (
                                    <Fieldset legend="Shipper Details">
                                        <FormItem {...formItemLayout} label="D&B #" format="vertical"
                                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="D&B #" ref={ref} />}
                                            rules={{ required: false }}
                                            name="dbNum"
                                            defaultValue={account.dbNum}
                                        />
                                        <FormItem {...formItemLayout} label="Produce Blue Book #" format="vertical"
                                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Produce Blue Book #" ref={ref} />}
                                            rules={{ required: false }}
                                            name="blueBookNum"
                                            defaultValue={account.blueBookNum}
                                        />
                                    </Fieldset>
                                ) : null}
                                {entityTypes.includes("CARRIER") ? (
                                    <Fieldset legend="Carrier Documents (* indicates a required field or file)">
                                        <FormItem {...formItemLayout} label="W9 Expiration Date" required format="vertical"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <DatePicker
                                                    placeholder="Expiration Date"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={(date, dateString) => { setW9ExpiryDate(date); onChange(date); }}
                                                    value={value}
                                                    name={name}
                                                    onSelect={(date) => { onChange(date); }}
                                                    ref={ref}
                                                />
                                            )}
                                            rules={{ required: "Required Field" }}
                                            name="w9ExpiryDate"
                                            defaultValue={account.w9ExpiryDate ? moment(account.w9ExpiryDate) : null}
                                        />
                                        <FormItemFile {...formItemLayout} label="W9 File" required name="w9File" format="vertical">
                                            <UploadFile
                                                beforeUpload={(file) => {
                                                    setW9File(file);
                                                    methods.clearErrors('w9File');
                                                    return false;
                                                }}
                                                onRemove={(file) => {
                                                    setW9File(null);
                                                }}
                                                message="Please upload a photo of the document."
                                                fileId={account.w9FileId}
                                            />
                                        </FormItemFile>
                                        <FormItem {...formItemLayout} label="Insurance Expiration Date" required format="vertical"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <DatePicker
                                                    placeholder="Expiration Date"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={(date, dateString) => { setInsuranceExpiryDate(date); onChange(date); }}
                                                    value={value}
                                                    name={name}
                                                    onSelect={(date) => { onChange(date); }}
                                                    ref={ref}
                                                />
                                            )}
                                            rules={{ required: "Required Field" }}
                                            name="insuranceExpiryDate"
                                            defaultValue={account.w9ExpiryDate ? moment(account.w9ExpiryDate) : null}
                                        />
                                        <FormItemFile {...formItemLayout} label="Insurance File" required name="insuranceFile" format="vertical">
                                            <UploadFile
                                                beforeUpload={(file) => {
                                                    setInsuranceFile(file);
                                                    methods.clearErrors('insuranceFile');
                                                    return false;
                                                }}
                                                onRemove={(file) => {
                                                    setInsuranceFile(null);
                                                }}
                                                message="Please upload a photo of the document."
                                                fileId={account.insuranceFileId}
                                            />
                                        </FormItemFile>
                                    </Fieldset>
                                ) : null}
                                {entityTypes.includes("CARRIER") || entityTypes.includes("BROKER") ? (
                                    <Fieldset legend="Accounts Receivable (* indicates a required field)">
                                        <FormItem {...formItemLayout} label="Preferred Payment Method" required format="vertical"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Radio.Group onBlur={onBlur} onChange={(e) => { setPreferredPaymentMethod(e.target.value); onChange(e.target.value); }} value={value} name={name} buttonStyle="solid" ref={ref}>
                                                    <Radio.Button value="CHECK">Paper Check</Radio.Button>
                                                    <Radio.Button value="DIRECT_DEPOSIT">Direct Deposit</Radio.Button>
                                                    <Radio.Button value="QUICK_PAY">Quick Pay</Radio.Button>
                                                    <Radio.Button value="FACTORING">Factoring</Radio.Button>
                                                </Radio.Group>
                                            )}
                                            rules={{ required: "Required Field" }}
                                            name="preferredPaymentMethod"
                                            defaultValue={account.preferredPaymentMethod ? account.preferredPaymentMethod : undefined}
                                        />
                                        {preferredPaymentMethod === 'FACTORING' ? (
                                            <Fieldset legend="My Factoring Companies (if applicable)">
                                                {linkedAccounts.filter(a => a.entityTypes.includes("FACTORING")).map((linkedAccount, index) => (
                                                    <Row gutter={16} key={`factoring-${index}`}>
                                                        <Col span={24}>
                                                            <FormItemDisplay label={linkedAccount.name}>
                                                                <DataContactInfoRow title="Remittance Point of Contact" contact={linkedAccount.accountsReceivable} />
                                                                <DataAddressRow title="Remittance Address" address={linkedAccount.accountsReceivableAddress} />
                                                            </FormItemDisplay>
                                                        </Col>
                                                    </Row>
                                                ))}
                                                <Tooltip placement="left" title="Add Factoring Company">
                                                    <Button key="1" type="primary" icon={<PlusOutlined />} shape='circle' onClick={toggleNewLinkedFactoring} style={{ margin: "10px", float: "right" }} />
                                                </Tooltip>
                                            </Fieldset>
                                        ) : null}
                                    </Fieldset>
                                ) : null}
                                {entityTypes.includes("BROKER") || entityTypes.includes("SHIPPER") || entityTypes.includes("FACTORING") || entityTypes.includes("PRODUCER") || entityTypes.includes("RECEIVER") ? (
                                    <>
                                        <Fieldset legend="Accounts Payable Contact (* indicates a required field)">
                                            <FormItemPointOfContact
                                                format="penta"
                                                required={true}
                                                name="accountsPayable"
                                                defaultValue={account.accountsPayable}
                                            />
                                        </Fieldset>
                                        <Fieldset legend="Accounts Payable Address (* indicates a required field)">
                                            <FormItemAddress
                                                format="vertical"
                                                required={false}
                                                name="accountsPayableAddress"
                                                defaultValue={account.accountsPayableAddress}
                                                isPostalAddress={true}
                                            />
                                        </Fieldset>
                                    </>
                                ) : null}
                                {entityTypes.includes("BROKER") || entityTypes.includes("CARRIER") || entityTypes.includes("FACTORING") || entityTypes.includes("PRODUCER") || entityTypes.includes("RECEIVER") ? (
                                    <>
                                        <Fieldset legend="Accounts Receivable Contact (* indicates a required field)">
                                            <FormItemPointOfContact
                                                format="penta"
                                                required={true}
                                                name="accountsReceivable"
                                                defaultValue={account.accountsReceivable}
                                            />
                                        </Fieldset>
                                        <Fieldset legend="Accounts Receivable Address (* indicates a required field)">
                                            <FormItemAddress
                                                format="vertical"
                                                required={false}
                                                name="accountsReceivableAddress"
                                                defaultValue={account.accountsReceivableAddress}
                                                isPostalAddress={true}
                                            />
                                        </Fieldset>
                                    </>
                                ) : null}
                                {entityTypes.includes("CARRIER") ? (
                                    <>
                                        <Fieldset legend="Asset Information (* indicates a required field)" isCollapsable={true} isCollapsed={account && account.assetNeeds ? false : true}>
                                            <Row gutter={[16, 16]}>
                                                <Col xxl={{ span: 4 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                                    <FormItem {...formItemLayout} label="# of Power Units" format="vertical"
                                                        render={({ onChange, onBlur, value, name, ref }) => (
                                                            <InputNumber
                                                                precision={0}
                                                                min={0}
                                                                step={1}
                                                                placeholder="How many?"
                                                                style={{ width: '100%' }}
                                                                onBlur={onBlur}
                                                                onChange={e => { onChange(e); }}
                                                                value={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        rules={{ required: false }}
                                                        name="assetNeeds.numPowerUnits"
                                                        defaultValue={account.assetNeeds && account.assetNeeds.numPowerUnits ? account.assetNeeds.numPowerUnits : ''}
                                                    />
                                                </Col>
                                                <Col xxl={{ span: 4 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                                    <FormItem {...formItemLayout} label="# of Trailers" format="vertical"
                                                        render={({ onChange, onBlur, value, name, ref }) => (
                                                            <InputNumber
                                                                precision={0}
                                                                min={0}
                                                                step={1}
                                                                placeholder="How many?"
                                                                style={{ width: '100%' }}
                                                                onBlur={onBlur}
                                                                onChange={e => { onChange(e); }}
                                                                value={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        rules={{ required: false }}
                                                        name="assetNeeds.numTrailers"
                                                        defaultValue={account.assetNeeds && account.assetNeeds.numTrailers ? account.assetNeeds.numTrailers : ''}
                                                    />
                                                </Col>
                                                <Col xxl={{ span: 4 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                                    <FormItem {...formItemLayout} label="# of Drivers" format="vertical"
                                                        render={({ onChange, onBlur, value, name, ref }) => (
                                                            <InputNumber
                                                                precision={0}
                                                                min={0}
                                                                step={1}
                                                                placeholder="How many?"
                                                                style={{ width: '100%' }}
                                                                onBlur={onBlur}
                                                                onChange={e => { onChange(e); }}
                                                                value={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        rules={{ required: false }}
                                                        name="assetNeeds.numDrivers"
                                                        defaultValue={account.assetNeeds && account.assetNeeds.numDrivers ? account.assetNeeds.numDrivers : ''}
                                                    />
                                                </Col>
                                                <Col xxl={{ span: 4 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                                    <FormItem {...formItemLayout} label="# of HazMat Drivers" format="vertical"
                                                        render={({ onChange, onBlur, value, name, ref }) => (
                                                            <InputNumber
                                                                precision={0}
                                                                min={0}
                                                                step={1}
                                                                placeholder="How many?"
                                                                style={{ width: '100%' }}
                                                                onBlur={onBlur}
                                                                onChange={e => { onChange(e); }}
                                                                value={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        rules={{ required: false }}
                                                        name="assetNeeds.numHazMatDrivers"
                                                        defaultValue={account.assetNeeds && account.assetNeeds.numHazMatDrivers ? account.assetNeeds.numHazMatDrivers : ''}
                                                    />
                                                </Col>
                                                <Col xxl={{ span: 4 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                                    <FormItem {...formItemLayout} label="# of Teams" format="vertical"
                                                        render={({ onChange, onBlur, value, name, ref }) => (
                                                            <InputNumber
                                                                precision={0}
                                                                min={0}
                                                                step={1}
                                                                placeholder="How many?"
                                                                style={{ width: '100%' }}
                                                                onBlur={onBlur}
                                                                onChange={e => { onChange(e); }}
                                                                value={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        rules={{ required: false }}
                                                        name="assetNeeds.numTeams"
                                                        defaultValue={account.assetNeeds && account.assetNeeds.numTeams ? account.assetNeeds.numTeams : ''}
                                                    />
                                                </Col>
                                            </Row>
                                        </Fieldset>
                                        <Fieldset legend="Trailer Types" isCollapsable={true} isCollapsed={account && account.assetNeeds ? false : true}>
                                            <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
                                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                                    <h4 style={{ textAlign: 'center' }}>Reefers</h4>
                                                    <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 48' Reefers?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                                        render1={({ onChange, onBlur, value, name, ref }) => (
                                                            <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                onBlur={onBlur}
                                                                onChange={(checked, event) => { onChange(checked); }}
                                                                checked={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        name1="assetNeeds.has48Reefer"
                                                        defaultValue1={account.assetNeeds && account.assetNeeds.has48Reefer ? account.assetNeeds.has48Reefer : false}
                                                        render2={({ onChange, onBlur, value, name, ref }) => (
                                                            <InputNumber
                                                                precision={0}
                                                                min={0}
                                                                step={1}
                                                                placeholder="How many?"
                                                                style={{ width: '100%' }}
                                                                onBlur={onBlur}
                                                                onChange={e => { onChange(e); }}
                                                                value={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        name2="assetNeeds.num48Reefer"
                                                        defaultValue2={account.assetNeeds && account.assetNeeds.num48Reefer ? account.assetNeeds.num48Reefer : ''}
                                                    />
                                                    <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 53' Reefers?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                                        render1={({ onChange, onBlur, value, name, ref }) => (
                                                            <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                onBlur={onBlur}
                                                                onChange={(checked, event) => { onChange(checked); }}
                                                                checked={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        name1="assetNeeds.has53Reefer"
                                                        defaultValue1={account.assetNeeds && account.assetNeeds.has53Reefer ? account.assetNeeds.has53Reefer : false}
                                                        render2={({ onChange, onBlur, value, name, ref }) => (
                                                            <InputNumber
                                                                precision={0}
                                                                min={0}
                                                                step={1}
                                                                placeholder="How many?"
                                                                style={{ width: '100%' }}
                                                                onBlur={onBlur}
                                                                onChange={e => { onChange(e); }}
                                                                value={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        name2="assetNeeds.num53Reefer"
                                                        defaultValue2={account.assetNeeds && account.assetNeeds.num53Reefer ? account.assetNeeds.num53Reefer : ''}
                                                    />
                                                    <FormItem {...formItemLayoutHorizontal} label="Do you haul frozen?" noColon format="horizontal"
                                                        render={({ onChange, onBlur, value, name, ref }) => (
                                                            <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                onBlur={onBlur}
                                                                onChange={(checked, event) => { onChange(checked); }}
                                                                checked={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        name="assetNeeds.hasReeferFrozen"
                                                        defaultValue={account.assetNeeds && account.assetNeeds.hasReeferFrozen ? account.assetNeeds.hasReeferFrozen : false}
                                                    />
                                                </Col>
                                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                                    <h4 style={{ textAlign: 'center' }}>Dry Vans</h4>
                                                    <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 48' Dry Vans?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                                        render1={({ onChange, onBlur, value, name, ref }) => (
                                                            <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                onBlur={onBlur}
                                                                onChange={(checked, event) => { onChange(checked); }}
                                                                checked={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        name1="assetNeeds.has48DryVan"
                                                        defaultValue1={account.assetNeeds && account.assetNeeds.has48DryVan ? account.assetNeeds.has48DryVan : false}
                                                        render2={({ onChange, onBlur, value, name, ref }) => (
                                                            <InputNumber
                                                                precision={0}
                                                                min={0}
                                                                step={1}
                                                                placeholder="How many?"
                                                                style={{ width: '100%' }}
                                                                onBlur={onBlur}
                                                                onChange={e => { onChange(e); }}
                                                                value={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        name2="assetNeeds.num48DryVan"
                                                        defaultValue2={account.assetNeeds && account.assetNeeds.num48DryVan ? account.assetNeeds.num48DryVan : ''}
                                                    />
                                                    <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 53' Dry Vans?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                                        render1={({ onChange, onBlur, value, name, ref }) => (
                                                            <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                onBlur={onBlur}
                                                                onChange={(checked, event) => { onChange(checked); }}
                                                                checked={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        name1="assetNeeds.has53DryVan"
                                                        defaultValue1={account.assetNeeds && account.assetNeeds.has53DryVan ? account.assetNeeds.has53DryVan : false}
                                                        render2={({ onChange, onBlur, value, name, ref }) => (
                                                            <InputNumber
                                                                precision={0}
                                                                min={0}
                                                                step={1}
                                                                placeholder="How many?"
                                                                style={{ width: '100%' }}
                                                                onBlur={onBlur}
                                                                onChange={e => { onChange(e); }}
                                                                value={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        name2="assetNeeds.num53DryVan"
                                                        defaultValue2={account.assetNeeds && account.assetNeeds.num53DryVan ? account.assetNeeds.num53DryVan : ''}
                                                    />
                                                    <FormItem {...formItemLayoutHorizontal} label="Do you have lift gates for the dry vans?" noColon format="horizontal"
                                                        render={({ onChange, onBlur, value, name, ref }) => (
                                                            <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                onBlur={onBlur}
                                                                onChange={(checked, event) => { onChange(checked); }}
                                                                checked={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        name="assetNeeds.hasDryVanLiftGates"
                                                        defaultValue={account.assetNeeds && account.assetNeeds.hasDryVanLiftGates ? account.assetNeeds.hasDryVanLiftGates : false}
                                                    />
                                                    <FormItem {...formItemLayoutHorizontal} label="Do you have roller beds for the dry vans?" noColon format="horizontal"
                                                        render={({ onChange, onBlur, value, name, ref }) => (
                                                            <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                onBlur={onBlur}
                                                                onChange={(checked, event) => { onChange(checked); }}
                                                                checked={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        name="assetNeeds.hasDryVanRollerBed"
                                                        defaultValue={account.assetNeeds && account.assetNeeds.hasDryVanRollerBed ? account.assetNeeds.hasDryVanRollerBed : false}
                                                    />
                                                    <h4 style={{ textAlign: 'center' }}>Over-Sized Loads</h4>
                                                    <FormItem {...formItemLayoutHorizontal} label="Do you haul over dimensional/overweight loads?" noColon format="horizontal"
                                                        render={({ onChange, onBlur, value, name, ref }) => (
                                                            <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                onBlur={onBlur}
                                                                onChange={(checked, event) => { onChange(checked); }}
                                                                checked={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        name="assetNeeds.hasOverSized"
                                                        defaultValue={account.assetNeeds && account.assetNeeds.hasOverSized ? account.assetNeeds.hasOverSized : false}
                                                    />
                                                </Col>
                                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                                    <h4 style={{ textAlign: 'center' }}>Flatbeds</h4>
                                                    <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 48' Flatbeds?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                                        render1={({ onChange, onBlur, value, name, ref }) => (
                                                            <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                onBlur={onBlur}
                                                                onChange={(checked, event) => { onChange(checked); }}
                                                                checked={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        name1="assetNeeds.has48Flatbed"
                                                        defaultValue1={account.assetNeeds && account.assetNeeds.has48Flatbed ? account.assetNeeds.has48Flatbed : false}
                                                        render2={({ onChange, onBlur, value, name, ref }) => (
                                                            <InputNumber
                                                                precision={0}
                                                                min={0}
                                                                step={1}
                                                                placeholder="How many?"
                                                                style={{ width: '100%' }}
                                                                onBlur={onBlur}
                                                                onChange={e => { onChange(e); }}
                                                                value={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        name2="assetNeeds.num48Flatbed"
                                                        defaultValue2={account.assetNeeds && account.assetNeeds.num48Flatbed ? account.assetNeeds.num48Flatbed : ''}
                                                    />
                                                    <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 53' Flatbeds?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                                        render1={({ onChange, onBlur, value, name, ref }) => (
                                                            <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                onBlur={onBlur}
                                                                onChange={(checked, event) => { onChange(checked); }}
                                                                checked={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        name1="assetNeeds.has53Flatbed"
                                                        defaultValue1={account.assetNeeds && account.assetNeeds.has53Flatbed ? account.assetNeeds.has53Flatbed : false}
                                                        render2={({ onChange, onBlur, value, name, ref }) => (
                                                            <InputNumber
                                                                precision={0}
                                                                min={0}
                                                                step={1}
                                                                placeholder="How many?"
                                                                style={{ width: '100%' }}
                                                                onBlur={onBlur}
                                                                onChange={e => { onChange(e); }}
                                                                value={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        name2="assetNeeds.num53Flatbed"
                                                        defaultValue2={account.assetNeeds && account.assetNeeds.num53Flatbed ? account.assetNeeds.num53Flatbed : ''}
                                                    />
                                                    <h4 style={{ textAlign: 'center' }}>Low Boys</h4>
                                                    <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Low Boys?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                                        render1={({ onChange, onBlur, value, name, ref }) => (
                                                            <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                onBlur={onBlur}
                                                                onChange={(checked, event) => { onChange(checked); }}
                                                                checked={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        name1="assetNeeds.hasLowBoy"
                                                        defaultValue1={account.assetNeeds && account.assetNeeds.hasLowBoy ? account.assetNeeds.hasLowBoy : false}
                                                        render2={({ onChange, onBlur, value, name, ref }) => (
                                                            <InputNumber
                                                                precision={0}
                                                                min={0}
                                                                step={1}
                                                                placeholder="How many?"
                                                                style={{ width: '100%' }}
                                                                onBlur={onBlur}
                                                                onChange={e => { onChange(e); }}
                                                                value={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        name2="assetNeeds.numLowBoy"
                                                        defaultValue2={account.assetNeeds && account.assetNeeds.numLowBoy ? account.assetNeeds.numLowBoy : ''}
                                                    />
                                                    <h4 style={{ textAlign: 'center' }}>RGNs</h4>
                                                    <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have RGNs?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                                        render1={({ onChange, onBlur, value, name, ref }) => (
                                                            <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                onBlur={onBlur}
                                                                onChange={(checked, event) => { onChange(checked); }}
                                                                checked={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        name1="assetNeeds.hasRGN"
                                                        defaultValue1={account.assetNeeds && account.assetNeeds.hasRGN ? account.assetNeeds.hasRGN : false}
                                                        render2={({ onChange, onBlur, value, name, ref }) => (
                                                            <InputNumber
                                                                precision={0}
                                                                min={0}
                                                                step={1}
                                                                placeholder="How many?"
                                                                style={{ width: '100%' }}
                                                                onBlur={onBlur}
                                                                onChange={e => { onChange(e); }}
                                                                value={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        name2="assetNeeds.numRGN"
                                                        defaultValue2={account.assetNeeds && account.assetNeeds.numRGN ? account.assetNeeds.numRGN : ''}
                                                    />
                                                    <h4 style={{ textAlign: 'center' }}>Step Decks</h4>
                                                    <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Step Decks with Ramps?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                                        render1={({ onChange, onBlur, value, name, ref }) => (
                                                            <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                onBlur={onBlur}
                                                                onChange={(checked, event) => { onChange(checked); }}
                                                                checked={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        name1="assetNeeds.hasStepDeckRamps"
                                                        defaultValue1={account.assetNeeds && account.assetNeeds.hasStepDeckRamps ? account.assetNeeds.hasStepDeckRamps : false}
                                                        render2={({ onChange, onBlur, value, name, ref }) => (
                                                            <InputNumber
                                                                precision={0}
                                                                min={0}
                                                                step={1}
                                                                placeholder="How many?"
                                                                style={{ width: '100%' }}
                                                                onBlur={onBlur}
                                                                onChange={e => { onChange(e); }}
                                                                value={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        name2="assetNeeds.numStepDeckRamps"
                                                        defaultValue2={account.assetNeeds && account.assetNeeds.numStepDeckRamps ? account.assetNeeds.numStepDeckRamps : ''}
                                                    />
                                                    <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Step Decks without Ramps?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                                        render1={({ onChange, onBlur, value, name, ref }) => (
                                                            <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                onBlur={onBlur}
                                                                onChange={(checked, event) => { onChange(checked); }}
                                                                checked={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        name1="assetNeeds.hasStepDeckNoRamps"
                                                        defaultValue1={account.assetNeeds && account.assetNeeds.hasStepDeckNoRamps ? account.assetNeeds.hasStepDeckNoRamps : false}
                                                        render2={({ onChange, onBlur, value, name, ref }) => (
                                                            <InputNumber
                                                                precision={0}
                                                                min={0}
                                                                step={1}
                                                                placeholder="How many?"
                                                                style={{ width: '100%' }}
                                                                onBlur={onBlur}
                                                                onChange={e => { onChange(e); }}
                                                                value={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        name2="assetNeeds.numStepDeckNoRamps"
                                                        defaultValue2={account.assetNeeds && account.assetNeeds.numStepDeckNoRamps ? account.assetNeeds.numStepDeckNoRamps : ''}
                                                    />
                                                </Col>
                                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                                    <h4 style={{ textAlign: 'center' }}>Tankers</h4>
                                                    <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Single Compartment Tankers?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                                        render1={({ onChange, onBlur, value, name, ref }) => (
                                                            <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                onBlur={onBlur}
                                                                onChange={(checked, event) => { onChange(checked); }}
                                                                checked={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        name1="assetNeeds.hasTankersSingleCompartment"
                                                        defaultValue1={account.assetNeeds && account.assetNeeds.hasTankersSingleCompartment ? account.assetNeeds.hasTankersSingleCompartment : false}
                                                        render2={({ onChange, onBlur, value, name, ref }) => (
                                                            <InputNumber
                                                                precision={0}
                                                                min={0}
                                                                step={1}
                                                                placeholder="How many?"
                                                                style={{ width: '100%' }}
                                                                onBlur={onBlur}
                                                                onChange={e => { onChange(e); }}
                                                                value={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        name2="assetNeeds.numTankersSingleCompartment"
                                                        defaultValue2={account.assetNeeds && account.assetNeeds.numTankersSingleCompartment ? account.assetNeeds.numTankersSingleCompartment : ''}
                                                    />
                                                    <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Multi Compartment Tankers?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                                        render1={({ onChange, onBlur, value, name, ref }) => (
                                                            <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                onBlur={onBlur}
                                                                onChange={(checked, event) => { onChange(checked); }}
                                                                checked={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        name1="assetNeeds.hasTankersMultiCompartment"
                                                        defaultValue1={account.assetNeeds && account.assetNeeds.hasTankersMultiCompartment ? account.assetNeeds.hasTankersMultiCompartment : false}
                                                        render2={({ onChange, onBlur, value, name, ref }) => (
                                                            <InputNumber
                                                                precision={0}
                                                                min={0}
                                                                step={1}
                                                                placeholder="How many?"
                                                                style={{ width: '100%' }}
                                                                onBlur={onBlur}
                                                                onChange={e => { onChange(e); }}
                                                                value={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        name2="assetNeeds.numTankersMultiCompartment"
                                                        defaultValue2={account.assetNeeds && account.assetNeeds.numTankersMultiCompartment ? account.assetNeeds.numTankersMultiCompartment : ''}
                                                    />
                                                    <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Fiber Glass Tankers?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                                        render1={({ onChange, onBlur, value, name, ref }) => (
                                                            <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                onBlur={onBlur}
                                                                onChange={(checked, event) => { onChange(checked); }}
                                                                checked={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        name1="assetNeeds.hasTankersFiberGlass"
                                                        defaultValue1={account.assetNeeds && account.assetNeeds.hasTankersFiberGlass ? account.assetNeeds.hasTankersFiberGlass : false}
                                                        render2={({ onChange, onBlur, value, name, ref }) => (
                                                            <InputNumber
                                                                precision={0}
                                                                min={0}
                                                                step={1}
                                                                placeholder="How many?"
                                                                style={{ width: '100%' }}
                                                                onBlur={onBlur}
                                                                onChange={e => { onChange(e); }}
                                                                value={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        name2="assetNeeds.numTankersFiberGlass"
                                                        defaultValue2={account.assetNeeds && account.assetNeeds.numTankersFiberGlass ? account.assetNeeds.numTankersFiberGlass : ''}
                                                    />
                                                    <h4 style={{ textAlign: 'center' }}>Straight Trucks</h4>
                                                    <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Cubed Straight Trucks?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                                        render1={({ onChange, onBlur, value, name, ref }) => (
                                                            <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                onBlur={onBlur}
                                                                onChange={(checked, event) => { onChange(checked); }}
                                                                checked={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        name1="assetNeeds.hasCubedStraightTruck"
                                                        defaultValue1={account.assetNeeds && account.assetNeeds.hasCubedStraightTruck ? account.assetNeeds.hasCubedStraightTruck : false}
                                                        render2={({ onChange, onBlur, value, name, ref }) => (
                                                            <InputNumber
                                                                precision={0}
                                                                min={0}
                                                                step={1}
                                                                placeholder="How many?"
                                                                style={{ width: '100%' }}
                                                                onBlur={onBlur}
                                                                onChange={e => { onChange(e); }}
                                                                value={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        name2="assetNeeds.numCubedStraightTruck"
                                                        defaultValue2={account.assetNeeds && account.assetNeeds.numCubedStraightTruck ? account.assetNeeds.numCubedStraightTruck : ''}
                                                    />
                                                    <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 26' Straight Trucks?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                                        render1={({ onChange, onBlur, value, name, ref }) => (
                                                            <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                onBlur={onBlur}
                                                                onChange={(checked, event) => { onChange(checked); }}
                                                                checked={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        name1="assetNeeds.has26StraightTruck"
                                                        defaultValue1={account.assetNeeds && account.assetNeeds.has26StraightTruck ? account.assetNeeds.has26StraightTruck : false}
                                                        render2={({ onChange, onBlur, value, name, ref }) => (
                                                            <InputNumber
                                                                precision={0}
                                                                min={0}
                                                                step={1}
                                                                placeholder="How many?"
                                                                style={{ width: '100%' }}
                                                                onBlur={onBlur}
                                                                onChange={e => { onChange(e); }}
                                                                value={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        name2="assetNeeds.num26StraightTruck"
                                                        defaultValue2={account.assetNeeds && account.assetNeeds.num26StraightTruck ? account.assetNeeds.num26StraightTruck : ''}
                                                    />
                                                    <FormItem {...formItemLayoutHorizontal} label="Do you have lift gates for the straight trucks?" noColon format="horizontal"
                                                        render={({ onChange, onBlur, value, name, ref }) => (
                                                            <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                onBlur={onBlur}
                                                                onChange={(checked, event) => { onChange(checked); }}
                                                                checked={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        name="assetNeeds.hasStraightTruckLiftGates"
                                                        defaultValue={account.assetNeeds && account.assetNeeds.hasStraightTruckLiftGates ? account.assetNeeds.hasStraightTruckLiftGates : false}
                                                    />
                                                    <h4 style={{ textAlign: 'center' }}>Sprinter Vans</h4>
                                                    <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Sprinter Vans?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                                        render1={({ onChange, onBlur, value, name, ref }) => (
                                                            <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                onBlur={onBlur}
                                                                onChange={(checked, event) => { onChange(checked); }}
                                                                checked={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        name1="assetNeeds.hasSprinterVan"
                                                        defaultValue1={account.assetNeeds && account.assetNeeds.hasSprinterVan ? account.assetNeeds.hasSprinterVan : false}
                                                        render2={({ onChange, onBlur, value, name, ref }) => (
                                                            <InputNumber
                                                                precision={0}
                                                                min={0}
                                                                step={1}
                                                                placeholder="How many?"
                                                                style={{ width: '100%' }}
                                                                onBlur={onBlur}
                                                                onChange={e => { onChange(e); }}
                                                                value={value}
                                                                name={name}
                                                                ref={ref}
                                                            />
                                                        )}
                                                        name2="assetNeeds.numSprinterVan"
                                                        defaultValue2={account.assetNeeds && account.assetNeeds.numSprinterVan ? account.assetNeeds.numSprinterVan : ''}
                                                    />
                                                </Col>
                                            </Row>
                                        </Fieldset>
                                        {CanDo({ staffOnly: true }) ? (
                                            <Fieldset legend="SMS Basic Scores">
                                                <FormItem {...formItemLayout} label="Hours of Service Compliance" format="vertical"
                                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Hours of Service Compliance" ref={ref} />}
                                                    rules={{ required: false }}
                                                    name="smsBasic.hosCompliance"
                                                    defaultValue={account.smsBasic && account.smsBasic.hosCompliance ? account.smsBasic.hosCompliance : ""}
                                                />
                                                <FormItem {...formItemLayout} label="Unsafe Driving" format="vertical"
                                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Unsafe Driving" ref={ref} />}
                                                    rules={{ required: false }}
                                                    name="smsBasic.unsafeDriving"
                                                    defaultValue={account.smsBasic && account.smsBasic.unsafeDriving ? account.smsBasic.unsafeDriving : ""}
                                                />
                                                <FormItem {...formItemLayout} label="Vehicle Maintenance" format="vertical"
                                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Vehicle Maintenance" ref={ref} />}
                                                    rules={{ required: false }}
                                                    name="smsBasic.vehicleMaintenance"
                                                    defaultValue={account.smsBasic && account.smsBasic.vehicleMaintenance ? account.smsBasic.vehicleMaintenance : ""}
                                                />
                                                <FormItem {...formItemLayout} label="Controlled Substance Abuse" format="vertical"
                                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Controlled Substance Abuse" ref={ref} />}
                                                    rules={{ required: false }}
                                                    name="smsBasic.controlledSA"
                                                    defaultValue={account.smsBasic && account.smsBasic.controlledSA ? account.smsBasic.controlledSA : ""}
                                                />
                                                <FormItem {...formItemLayout} label="Driver Fitness" format="vertical"
                                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Driver Fitness" ref={ref} />}
                                                    rules={{ required: false }}
                                                    name="smsBasic.driverFitness"
                                                    defaultValue={account.smsBasic && account.smsBasic.driverFitness ? account.smsBasic.driverFitness : ""}
                                                />
                                                <FormItem {...formItemLayout} label="Average SMS Score" format="vertical"
                                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Average SMS Score" ref={ref} />}
                                                    rules={{ required: false }}
                                                    name="smsBasic.avgSMSScore"
                                                    defaultValue={account.smsBasic && account.smsBasic.avgSMSScore ? account.smsBasic.avgSMSScore : ""}
                                                />
                                                <FormItem {...formItemLayout} label="Weighted Average SMS Score" format="vertical"
                                                    render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Weighted Average SMS Score" ref={ref} />}
                                                    rules={{ required: false }}
                                                    name="smsBasic.weightedAvgSMSScore"
                                                    defaultValue={account.smsBasic && account.smsBasic.weightedAvgSMSScore ? account.smsBasic.weightedAvgSMSScore : ""}
                                                />
                                            </Fieldset>
                                        ) : null}
                                    </>
                                ) : null}
                                {entityTypes.includes("SHIPPER") ? (
                                    <Fieldset legend="What are your equipment needs based on the commodities you ship?" isCollapsable={true} isCollapsed={account && account.assetNeeds ? false : true}>
                                        <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
                                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                                <h4 style={{ textAlign: 'center' }}>Reefers</h4>
                                                <FormItem {...formItemLayoutHorizontal} label="Do you need 48' Reefers?" noColon format="horizontal"
                                                    render={({ onChange, onBlur, value, name, ref }) => (
                                                        <Switch
                                                            checkedChildren="Yes"
                                                            unCheckedChildren="No"
                                                            onBlur={onBlur}
                                                            onChange={(checked, event) => { onChange(checked); }}
                                                            checked={value}
                                                            name={name}
                                                            ref={ref}
                                                        />
                                                    )}
                                                    name="assetNeeds.has48Reefer"
                                                    defaultValue={account.assetNeeds && account.assetNeeds.has48Reefer ? account.assetNeeds.has48Reefer : false}
                                                />
                                                <FormItem {...formItemLayoutHorizontal} label="Do you need 53' Reefers?" noColon format="horizontal"
                                                    render={({ onChange, onBlur, value, name, ref }) => (
                                                        <Switch
                                                            checkedChildren="Yes"
                                                            unCheckedChildren="No"
                                                            onBlur={onBlur}
                                                            onChange={(checked, event) => { onChange(checked); }}
                                                            checked={value}
                                                            name={name}
                                                            ref={ref}
                                                        />
                                                    )}
                                                    name="assetNeeds.has53Reefer"
                                                    defaultValue={account.assetNeeds && account.assetNeeds.has53Reefer ? account.assetNeeds.has53Reefer : false}
                                                />
                                                <FormItem {...formItemLayoutHorizontal} label="Do you need to ship frozen?" noColon format="horizontal"
                                                    render={({ onChange, onBlur, value, name, ref }) => (
                                                        <Switch
                                                            checkedChildren="Yes"
                                                            unCheckedChildren="No"
                                                            onBlur={onBlur}
                                                            onChange={(checked, event) => { onChange(checked); }}
                                                            checked={value}
                                                            name={name}
                                                            ref={ref}
                                                        />
                                                    )}
                                                    name="assetNeeds.hasReeferFrozen"
                                                    defaultValue={account.assetNeeds && account.assetNeeds.hasReeferFrozen ? account.assetNeeds.hasReeferFrozen : false}
                                                />
                                            </Col>
                                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                                <h4 style={{ textAlign: 'center' }}>Dry Vans</h4>
                                                <FormItem {...formItemLayoutHorizontal} label="Do you need 48' Dry Vans?" noColon format="horizontal"
                                                    render={({ onChange, onBlur, value, name, ref }) => (
                                                        <Switch
                                                            checkedChildren="Yes"
                                                            unCheckedChildren="No"
                                                            onBlur={onBlur}
                                                            onChange={(checked, event) => { onChange(checked); }}
                                                            checked={value}
                                                            name={name}
                                                            ref={ref}
                                                        />
                                                    )}
                                                    name="assetNeeds.has48DryVan"
                                                    defaultValue={account.assetNeeds && account.assetNeeds.has48DryVan ? account.assetNeeds.has48DryVan : false}
                                                />
                                                <FormItem {...formItemLayoutHorizontal} label="Do you need 53' Dry Vans?" noColon format="horizontal"
                                                    render={({ onChange, onBlur, value, name, ref }) => (
                                                        <Switch
                                                            checkedChildren="Yes"
                                                            unCheckedChildren="No"
                                                            onBlur={onBlur}
                                                            onChange={(checked, event) => { onChange(checked); }}
                                                            checked={value}
                                                            name={name}
                                                            ref={ref}
                                                        />
                                                    )}
                                                    name="assetNeeds.has53DryVan"
                                                    defaultValue={account.assetNeeds && account.assetNeeds.has53DryVan ? account.assetNeeds.has53DryVan : false}
                                                />
                                                <FormItem {...formItemLayoutHorizontal} label="Do you need lift gates for the dry vans?" noColon format="horizontal"
                                                    render={({ onChange, onBlur, value, name, ref }) => (
                                                        <Switch
                                                            checkedChildren="Yes"
                                                            unCheckedChildren="No"
                                                            onBlur={onBlur}
                                                            onChange={(checked, event) => { onChange(checked); }}
                                                            checked={value}
                                                            name={name}
                                                            ref={ref}
                                                        />
                                                    )}
                                                    name="assetNeeds.hasDryVanLiftGates"
                                                    defaultValue={account.assetNeeds && account.assetNeeds.hasDryVanLiftGates ? account.assetNeeds.hasDryVanLiftGates : false}
                                                />
                                                <FormItem {...formItemLayoutHorizontal} label="Do you need roller beds for the dry vans?" noColon format="horizontal"
                                                    render={({ onChange, onBlur, value, name, ref }) => (
                                                        <Switch
                                                            checkedChildren="Yes"
                                                            unCheckedChildren="No"
                                                            onBlur={onBlur}
                                                            onChange={(checked, event) => { onChange(checked); }}
                                                            checked={value}
                                                            name={name}
                                                            ref={ref}
                                                        />
                                                    )}
                                                    name="assetNeeds.hasDryVanRollerBed"
                                                    defaultValue={account.assetNeeds && account.assetNeeds.hasDryVanRollerBed ? account.assetNeeds.hasDryVanRollerBed : false}
                                                />
                                                <h4 style={{ textAlign: 'center' }}>Over-Sized Loads</h4>
                                                <FormItem {...formItemLayoutHorizontal} label="Do you need to ship over dimensional/overweight loads?" noColon format="horizontal"
                                                    render={({ onChange, onBlur, value, name, ref }) => (
                                                        <Switch
                                                            checkedChildren="Yes"
                                                            unCheckedChildren="No"
                                                            onBlur={onBlur}
                                                            onChange={(checked, event) => { onChange(checked); }}
                                                            checked={value}
                                                            name={name}
                                                            ref={ref}
                                                        />
                                                    )}
                                                    name="assetNeeds.hasOverSized"
                                                    defaultValue={account.assetNeeds && account.assetNeeds.hasOverSized ? account.assetNeeds.hasOverSized : false}
                                                />
                                            </Col>
                                            {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                                <h4 style={{ textAlign: 'center' }}>Flatbeds</h4>
                                                <FormItem {...formItemLayoutHorizontal} label="Do you need 48' Flatbeds?" noColon format="horizontal"
                                                    render={({ onChange, onBlur, value, name, ref }) => (
                                                        <Switch
                                                            checkedChildren="Yes"
                                                            unCheckedChildren="No"
                                                            onBlur={onBlur}
                                                            onChange={(checked, event) => { onChange(checked); }}
                                                            checked={value}
                                                            name={name}
                                                            ref={ref}
                                                        />
                                                    )}
                                                    name="assetNeeds.has48Flatbed"
                                                    defaultValue={account.assetNeeds && account.assetNeeds.has48Flatbed ? account.assetNeeds.has48Flatbed : false}
                                                />
                                                <FormItem {...formItemLayoutHorizontal} label="Do you need 53' Flatbeds?" noColon format="horizontal"
                                                    render={({ onChange, onBlur, value, name, ref }) => (
                                                        <Switch
                                                            checkedChildren="Yes"
                                                            unCheckedChildren="No"
                                                            onBlur={onBlur}
                                                            onChange={(checked, event) => { onChange(checked); }}
                                                            checked={value}
                                                            name={name}
                                                            ref={ref}
                                                        />
                                                    )}
                                                    name="assetNeeds.has53Flatbed"
                                                    defaultValue={account.assetNeeds && account.assetNeeds.has53Flatbed ? account.assetNeeds.has53Flatbed : false}
                                                />
                                                <h4 style={{ textAlign: 'center' }}>Low Boys</h4>
                                                <FormItem {...formItemLayoutHorizontal} label="Do you need Low Boys?" noColon format="horizontal"
                                                    render={({ onChange, onBlur, value, name, ref }) => (
                                                        <Switch
                                                            checkedChildren="Yes"
                                                            unCheckedChildren="No"
                                                            onBlur={onBlur}
                                                            onChange={(checked, event) => { onChange(checked); }}
                                                            checked={value}
                                                            name={name}
                                                            ref={ref}
                                                        />
                                                    )}
                                                    name="assetNeeds.hasLowBoy"
                                                    defaultValue={account.assetNeeds && account.assetNeeds.hasLowBoy ? account.assetNeeds.hasLowBoy : false}
                                                />
                                                <h4 style={{ textAlign: 'center' }}>RGNs</h4>
                                                <FormItem {...formItemLayoutHorizontal} label="Do you need RGNs?" noColon format="horizontal"
                                                    render={({ onChange, onBlur, value, name, ref }) => (
                                                        <Switch
                                                            checkedChildren="Yes"
                                                            unCheckedChildren="No"
                                                            onBlur={onBlur}
                                                            onChange={(checked, event) => { onChange(checked); }}
                                                            checked={value}
                                                            name={name}
                                                            ref={ref}
                                                        />
                                                    )}
                                                    name="assetNeeds.hasRGN"
                                                    defaultValue={account.assetNeeds && account.assetNeeds.hasRGN ? account.assetNeeds.hasRGN : false}
                                                />
                                                <h4 style={{ textAlign: 'center' }}>Step Decks</h4>
                                                <FormItem {...formItemLayoutHorizontal} label="Do you need Step Decks with Ramps?" noColon format="horizontal"
                                                    render={({ onChange, onBlur, value, name, ref }) => (
                                                        <Switch
                                                            checkedChildren="Yes"
                                                            unCheckedChildren="No"
                                                            onBlur={onBlur}
                                                            onChange={(checked, event) => { onChange(checked); }}
                                                            checked={value}
                                                            name={name}
                                                            ref={ref}
                                                        />
                                                    )}
                                                    name="assetNeeds.hasStepDeckRamps"
                                                    defaultValue={account.assetNeeds && account.assetNeeds.hasStepDeckRamps ? account.assetNeeds.hasStepDeckRamps : false}
                                                />
                                                <FormItem {...formItemLayoutHorizontal} label="Do you need Step Decks without Ramps?" noColon format="horizontal"
                                                    render={({ onChange, onBlur, value, name, ref }) => (
                                                        <Switch
                                                            checkedChildren="Yes"
                                                            unCheckedChildren="No"
                                                            onBlur={onBlur}
                                                            onChange={(checked, event) => { onChange(checked); }}
                                                            checked={value}
                                                            name={name}
                                                            ref={ref}
                                                        />
                                                    )}
                                                    name="assetNeeds.hasStepDeckNoRamps"
                                                    defaultValue={account.assetNeeds && account.assetNeeds.hasStepDeckNoRamps ? account.assetNeeds.hasStepDeckNoRamps : false}
                                                />
                                            </Col>
                                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                                <h4 style={{ textAlign: 'center' }}>Tankers</h4>
                                                <FormItem {...formItemLayoutHorizontal} label="Do you need Single Compartment Tankers?" noColon format="horizontal"
                                                    render={({ onChange, onBlur, value, name, ref }) => (
                                                        <Switch
                                                            checkedChildren="Yes"
                                                            unCheckedChildren="No"
                                                            onBlur={onBlur}
                                                            onChange={(checked, event) => { onChange(checked); }}
                                                            checked={value}
                                                            name={name}
                                                            ref={ref}
                                                        />
                                                    )}
                                                    name="assetNeeds.hasTankersSingleCompartment"
                                                    defaultValue={account.assetNeeds && account.assetNeeds.hasTankersSingleCompartment ? account.assetNeeds.hasTankersSingleCompartment : false}
                                                />
                                                <FormItem {...formItemLayoutHorizontal} label="Do you need Multi Compartment Tankers?" noColon format="horizontal"
                                                    render={({ onChange, onBlur, value, name, ref }) => (
                                                        <Switch
                                                            checkedChildren="Yes"
                                                            unCheckedChildren="No"
                                                            onBlur={onBlur}
                                                            onChange={(checked, event) => { onChange(checked); }}
                                                            checked={value}
                                                            name={name}
                                                            ref={ref}
                                                        />
                                                    )}
                                                    name="assetNeeds.hasTankersMultiCompartment"
                                                    defaultValue={account.assetNeeds && account.assetNeeds.hasTankersMultiCompartment ? account.assetNeeds.hasTankersMultiCompartment : false}
                                                />
                                                <FormItem {...formItemLayoutHorizontal} label="Do you need Fiber Glass Tankers?" noColon format="horizontal"
                                                    render={({ onChange, onBlur, value, name, ref }) => (
                                                        <Switch
                                                            checkedChildren="Yes"
                                                            unCheckedChildren="No"
                                                            onBlur={onBlur}
                                                            onChange={(checked, event) => { onChange(checked); }}
                                                            checked={value}
                                                            name={name}
                                                            ref={ref}
                                                        />
                                                    )}
                                                    name="assetNeeds.hasTankersFiberGlass"
                                                    defaultValue={account.assetNeeds && account.assetNeeds.hasTankersFiberGlass ? account.assetNeeds.hasTankersFiberGlass : false}
                                                />
                                                <h4 style={{ textAlign: 'center' }}>Straight Trucks</h4>
                                                <FormItem {...formItemLayoutHorizontal} label="Do you need Cubed Straight Trucks?" noColon format="horizontal"
                                                    render={({ onChange, onBlur, value, name, ref }) => (
                                                        <Switch
                                                            checkedChildren="Yes"
                                                            unCheckedChildren="No"
                                                            onBlur={onBlur}
                                                            onChange={(checked, event) => { onChange(checked); }}
                                                            checked={value}
                                                            name={name}
                                                            ref={ref}
                                                        />
                                                    )}
                                                    name="assetNeeds.hasCubedStraightTruck"
                                                    defaultValue={account.assetNeeds && account.assetNeeds.hasCubedStraightTruck ? account.assetNeeds.hasCubedStraightTruck : false}
                                                />
                                                <FormItem {...formItemLayoutHorizontal} label="Do you need 26' Straight Trucks?" noColon format="horizontal"
                                                    render={({ onChange, onBlur, value, name, ref }) => (
                                                        <Switch
                                                            checkedChildren="Yes"
                                                            unCheckedChildren="No"
                                                            onBlur={onBlur}
                                                            onChange={(checked, event) => { onChange(checked); }}
                                                            checked={value}
                                                            name={name}
                                                            ref={ref}
                                                        />
                                                    )}
                                                    name="assetNeeds.has26StraightTruck"
                                                    defaultValue={account.assetNeeds && account.assetNeeds.has26StraightTruck ? account.assetNeeds.has26StraightTruck : false}
                                                />
                                                <FormItem {...formItemLayoutHorizontal} label="Do you need lift gates for the straight trucks?" noColon format="horizontal"
                                                    render={({ onChange, onBlur, value, name, ref }) => (
                                                        <Switch
                                                            checkedChildren="Yes"
                                                            unCheckedChildren="No"
                                                            onBlur={onBlur}
                                                            onChange={(checked, event) => { onChange(checked); }}
                                                            checked={value}
                                                            name={name}
                                                            ref={ref}
                                                        />
                                                    )}
                                                    name="assetNeeds.hasStraightTruckLiftGates"
                                                    defaultValue={account.assetNeeds && account.assetNeeds.hasStraightTruckLiftGates ? account.assetNeeds.hasStraightTruckLiftGates : false}
                                                />
                                                <h4 style={{ textAlign: 'center' }}>Sprinter Vans</h4>
                                                <FormItem {...formItemLayoutHorizontal} label="Do you need Sprinter Vans?" noColon format="horizontal"
                                                    render={({ onChange, onBlur, value, name, ref }) => (
                                                        <Switch
                                                            checkedChildren="Yes"
                                                            unCheckedChildren="No"
                                                            onBlur={onBlur}
                                                            onChange={(checked, event) => { onChange(checked); }}
                                                            checked={value}
                                                            name={name}
                                                            ref={ref}
                                                        />
                                                    )}
                                                    name="assetNeeds.hasSprinterVan"
                                                    defaultValue={account.assetNeeds && account.assetNeeds.hasSprinterVan ? account.assetNeeds.hasSprinterVan : false}
                                                />
                                            </Col> */}
                                        </Row>
                                    </Fieldset>
                                ) : null}
                                {CanDo({ adminsOnly: true }) ? (
                                    <>
                                        <Fieldset legend="Other Company Details">
                                            <FormItem {...formItemLayout} label="Is Active" format="vertical"
                                                name="isActive"
                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                    <Switch
                                                        checkedChildren="Yes"
                                                        unCheckedChildren="No"
                                                        onBlur={onBlur}
                                                        onChange={(checked, event) => { onChange(checked); }}
                                                        checked={value}
                                                        name={name}
                                                        ref={ref}
                                                    />
                                                )}
                                                defaultValue={account.isActive}
                                            />
                                            <FormItem {...formItemLayout} label="Is Credit Approved" format="vertical"
                                                name="isCreditApproved"
                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                    <Switch
                                                        checkedChildren="Yes"
                                                        unCheckedChildren="No"
                                                        onBlur={onBlur}
                                                        onChange={(checked, event) => { onChange(checked); }}
                                                        checked={value}
                                                        name={name}
                                                        ref={ref}
                                                    />
                                                )}
                                                defaultValue={account.isCreditApproved}
                                            />
                                        </Fieldset>
                                        <Fieldset legend="Services (if applicable)">
                                            {accountServices.map((item, index) => (
                                                <Row gutter={16} key={`services-${index}`}>
                                                    <Col span={18}>
                                                        {item.service && (
                                                            <FormItemDisplay label={item.service && item.service.displayName ? item.service.displayName : item.serviceName} format="vertical">
                                                                <DataRow title="Price" value={item.isPriceOverride ? item.priceAmount : item.service.priceAmount} units={item.isPriceOverride ? item.priceAmountUnit : item.service.priceAmountUnit} dataType="Money" />
                                                                <DataRow title="Billing Frequency" value={item.isPriceOverride ? item.billingFrequency : item.service.billingFrequency} dataType="BillingFrequency" />
                                                            </FormItemDisplay>
                                                        )}
                                                    </Col>
                                                    <Col span={6}>
                                                        <Button icon={<DeleteOutlined />} onClick={() => { removeService(index); }} />
                                                    </Col>
                                                </Row>
                                            ))}
                                            <Tooltip placement="left" title="Add Service">
                                                <Button key="1" type="primary" icon={<PlusOutlined />} shape='circle' onClick={toggleNewService} style={{ margin: "10px", float: "right" }} />
                                            </Tooltip>
                                        </Fieldset>
                                        <Fieldset legend="Account Type(s)">
                                            <FormItem {...formItemLayout} label="Account Types" format="vertical"
                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                    <Checkbox.Group
                                                        onBlur={onBlur}
                                                        options={Enums.EntityTypes.checkboxListOptions()}
                                                        onChange={(checkedValues) => { setEntityTypes(checkedValues); onChange(checkedValues); }}
                                                        value={value}
                                                        name={name}
                                                    />
                                                )}
                                                name="entityTypes"
                                                defaultValue={account.entityTypes}
                                            />
                                        </Fieldset>
                                    </>
                                ) : null}
                                {error && <Alert message={`${error}`} type="error" />}
                            </Spin>
                            <FormButtons cancel={onCancel} disabled={(isLoading === true && error === null) || ((entityTypes.includes("CARRIER") === true && ((hasValidAccountEIN === false && isStringNotEmpty(accountEIN)) || (hasValidAccountMCNumber === false && isStringNotEmpty(accountMCNumber)) || hasValidAccountDOTNumber === false)) || (entityTypes.includes("BROKER") === true && ((hasValidAccountEIN === false && isStringNotEmpty(accountEIN)) || hasValidAccountMCNumber === false || hasValidAccountDOTNumber === false)) || ((entityTypes.includes("CARRIER") === false && entityTypes.includes("BROKER") === false) && hasValidAccountEIN === false))} submitText="Update Account" />
                        </Form>
                    </FormProvider>
                    <Drawer
                        title={'Add New Factoring Company'}
                        visible={showNewLinkedFactoring === true}
                        onClose={toggleNewLinkedFactoring}
                        bodyStyle={{ paddingBottom: 80 }}
                        style={{ zIndex: 1000 }}
                        width={fullWidth > 720 ? fullWidth / 2 : 360}
                        footer={null}
                        zIndex={1000}
                        destroyOnClose={true}
                        closable={false}
                        maskClosable={false}
                    >
                        <NewLinkedFactoring accountId={isObjectNotEmpty(account) && isStringNotEmpty(account.id) ? account.id : null} cancel={toggleNewLinkedFactoring} />
                    </Drawer>
                    <Drawer
                        title={'Add New Service'}
                        width={fullWidth > 720 ? fullWidth / 2 : 360}
                        onClose={toggleNewService}
                        visible={showAddNewService}
                        bodyStyle={{ paddingBottom: 80 }}
                        style={{ zIndex: 1000 }}
                        destroyOnClose={true}
                        closable={false}
                        maskClosable={false}
                    >
                        <FormProvider {...methods2}>
                            <Form onSubmit={methods2.handleSubmit(addService)}>
                                <FormItem {...formItemLayout} label="Service" format="vertical" required
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Select
                                            placeholder="Please Select a Service"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChange(selected); }}
                                            value={value}
                                            name={name}
                                            showSearch={true}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            ref={ref}
                                        >
                                            {services.map(a => <Option value={a.name} key={a.name}>{`${a.displayName} - ${stringFormatter.toFormattedMoney(a.priceAmount)}${a.billingFrequency !== 'NONE' ? ' billed ' + stringFormatter.toFormattedString('BillingFrequency', a.billingFrequency, null) : ''}`}</Option>)}
                                        </Select>
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="serviceName"
                                />
                                <FormItem {...formItemLayout} label="Over-Ride Price?" format="vertical"
                                    name="isPriceOverride"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Switch
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            onBlur={onBlur}
                                            onChange={(checked, event) => { onChange(checked); onChangeIsPriceOverride(checked); }}
                                            checked={value}
                                            name={name}
                                            ref={ref}
                                        />
                                    )}
                                    defaultValue={false}
                                />
                                {(showOverridePrice === true) && (
                                    <>
                                        <FormItemDouble {...formItemLayoutDouble} label1="Over-Ride Price" required1 required2 format="vertical"
                                            render1={({ onChange, onBlur, value, name, ref }) => (
                                                <InputNumber
                                                    precision={2}
                                                    min={0}
                                                    placeholder="Enter the Over-Ride Price"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                    ref={ref}
                                                />
                                            )}
                                            rules1={{ required: "Required Field" }}
                                            name1="priceAmount"
                                            render2={({ onChange, onBlur, value, name, ref }) => (
                                                <Select
                                                    placeholder="Please select the Price Units"
                                                    allowClear={true}
                                                    style={{ width: '100%' }}
                                                    virtual={false}
                                                    onBlur={onBlur}
                                                    onChange={(selected) => { onChange(selected); }}
                                                    value={value}
                                                    name={name}
                                                    showSearch={true}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    ref={ref}
                                                >
                                                    {currencyOptions}
                                                </Select>
                                            )}
                                            rules2={{ required: "Required Field" }}
                                            name2="priceAmountUnit"
                                        />
                                        <FormItem {...formItemLayout} label="Over-Ride Billing Frequency" required format="vertical"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Select
                                                    placeholder="Please select the Over-Ride Billing Frequency"
                                                    allowClear={true}
                                                    style={{ width: '100%' }}
                                                    virtual={false}
                                                    onBlur={onBlur}
                                                    onChange={(selected) => { onChange(selected); }}
                                                    value={value}
                                                    name={name}
                                                    showSearch={true}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    ref={ref}
                                                >
                                                    {billingFrequencyOptions}
                                                </Select>
                                            )}
                                            rules={{ required: "Required Field" }}
                                            name="billingFrequency"
                                        />
                                    </>
                                )}
                                <FormButtons cancel={toggleNewService} submitText="Add Service" />
                            </Form>
                        </FormProvider>
                    </Drawer>
                </>
            ) : null}
        </>
    );
};

export default EditAccount;