import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row, Button, Drawer, Modal, Dropdown, Menu, Spin, Alert, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from "../../store/actions/index";
import ComplexCard from '../ComplexCard/ComplexCard';
import DataContactInfoRow from '../DataContactInfoRow/DataContactInfoRow';
import DataPhoneNumberRow from '../DataPhoneNumberRow/DataPhoneNumberRow';
import DataAddressRow from '../DataAddressRow/DataAddressRow';
import { DownOutlined, PaperClipOutlined, ControlOutlined } from '@ant-design/icons';
import DocumentList from "../../components/DocumentList/DocumentList";
import EditAccount from "../../components/EditAccount/EditAccount";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHoldingUsd, faUsers } from '@fortawesome/free-solid-svg-icons';
import { isBooleanFalse, isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { CanDo, CanSee } from '../../shared/entitlements/entitlements';
import DataRow from '../DataRow/DataRow';
import axios from 'axios';
import * as Data from '../../api/data';
import Header from '../../components/Header/Header';
import logger from '../../shared/logger';
import StringFormatter from '../../shared/stringFormatter';
import AccountUtils from '../../api/utils/accountUtils';
import EditMissingAccountInformation from '../EditMissingAccountInformation/EditMissingAccountInformation';

const stringFormatter = new StringFormatter();

const CompanyFactoringSnapshot = (props) => {
    const fullWidth = global.window.innerWidth;

    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showDocumentsModal, setShowDocumentsModal] = useState(false);
    const [missingDocumentsCount, setMissingDocumentsCount] = useState(0);
    const [pendingApprovalCount, setPendingApprovalCount] = useState(0);
    const [imageUrl, setImageUrl] = useState(null);
    const [missingAccountInformation, setMissingAccountInformation] = useState([]);
    const [showEditMissingAccountInformation, setShowEditMissingAccountInformation] = useState([]);
    const [selectedMissingAccountInformation, setSelectedMissingAccountInformation] = useState(null);

    const dispatch = useDispatch();
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);
    const isAccountLoading = useSelector(state => state.accounts.isRecordLoading);
    const account = useSelector(state => state.accounts.record);

    const verifyAccount = (accountId) => {
        dispatch(actionCreators.verifyAccount(accountId));
    };

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleDocumentsModal = () => {
        setShowDocumentsModal(!showDocumentsModal);
    };

    const toggleEditMissingAccountInformation = () => {
        setShowEditMissingAccountInformation(!showEditMissingAccountInformation);
    };

    const menu = () => {
        return (
            <Menu>
                <Menu.Item key="manageDocuments" onClick={(e) => { e.domEvent.stopPropagation(); toggleDocumentsModal(); }}>
                    <PaperClipOutlined style={{ marginRight: 8 }} />
                    <span>Manage Documents</span>
                </Menu.Item>
                <Menu.Item key="editAccount" onClick={(e) => { e.domEvent.stopPropagation(); toggleEditEntity(); }}>
                    <FontAwesomeIcon className="anticon" icon={faUsers} style={{ marginRight: 8 }} />
                    <span>Edit Account Details</span>
                </Menu.Item>
            </Menu>
        );
    };

    const extra = (
        <Dropdown overlay={menu} trigger={['click']}>
            <Button onClick={(e) => { e.stopPropagation(); }}><ControlOutlined /> <DownOutlined /></Button>
        </Dropdown>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit Account Details"}
            onClose={toggleEditEntity}
            visible={showEditEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditAccount
                cancel={toggleEditEntity}
                account={account}
            />
        </Drawer>
    );

    const documentsComponent = (
        <Modal
            title="Account Documents"
            visible={showDocumentsModal === true}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleDocumentsModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            {isObjectNotEmpty(account) ? (
                <DocumentList
                    documentEntityType="ACCOUNT"
                    account={account}
                    documentEntityId={account.id}
                    updatePendingApprovalCount={setPendingApprovalCount}
                    updateMissingDocumentsCount={setMissingDocumentsCount}
                />
            ) : null}
            {isObjectNotEmpty(account) && isBooleanFalse(account.isVerified) ? (
                <CanSee entityAction="VERIFY" entityModel="ACCOUNT" entityObject={account}>
                    <Button style={{ marginTop: 20 }} type='primary' onClick={() => { verifyAccount(account.id); }} disabled={pendingApprovalCount > 0 || missingDocumentsCount > 0}>Verify Account</Button>
                </CanSee>
            ) : null}
        </Modal>
    );

    const editMissingAccountInformationDrawer = (
        <Drawer
            title={"Edit Missing Details for " + (isObjectNotEmpty(selectedMissingAccountInformation) ? selectedMissingAccountInformation.entityName : "")}
            onClose={toggleEditMissingAccountInformation}
            visible={showEditMissingAccountInformation === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditMissingAccountInformation
                cancel={toggleEditMissingAccountInformation}
                missingAccountInformation={selectedMissingAccountInformation}
                account={account}
            />
        </Drawer>
    );

    const missingAccountInformationDisplay = () => {
        if (isListNotEmpty(missingAccountInformation)) {
            return (
                <Row gutter={[24, 24]} style={{ marginBottom: 24 }}>
                    <Col span={24}>
                        {missingAccountInformation.map((missingInfo, index, arr) => {
                            return (
                                <Alert
                                    key={`missing-account-information-${index}`}
                                    style={index < arr.length - 1 ? { marginBottom: 8 } : null}
                                    type="error"
                                    message={`${missingInfo.entityName} is missing ${missingInfo.missingFieldName}`}
                                    showIcon={true}
                                    action={<Space><Button size="small" type="ghost" onClick={(e) => { setSelectedMissingAccountInformation(missingInfo); toggleEditMissingAccountInformation(); }}>Fix</Button></Space>}
                                />
                            );
                        })}
                    </Col>
                </Row>
            );
        } else {
            return null;
        }
    };

    useMemo(() => {
        if (isStringNotEmpty(entityType) && isStringNotEmpty(entityId) && (entityType === "SHIPPER" || entityType === "CARRIER" || entityType === "PRODUCER" || entityType === "RECEIVER" || entityType === "BROKER" || entityType === "FACTORING")) {
            dispatch(actionCreators.fetchAccount(entityId));
        }
    }, [entityType, entityId]);

    useMemo(() => {
        if (isObjectNotEmpty(account) && account.id === entityId) {
            let missingAccountInformationList = AccountUtils.getMissingAccountInformation(account);
            setMissingAccountInformation(missingAccountInformationList);
        }
    }, [account, entityId]);

    useEffect(() => {
        const { cancel, token } = axios.CancelToken.source();

        if (isObjectNotEmpty(account) && isStringNotEmpty(account.logoFileId)) {
            Data.getImageByDocumentId(account.logoFileId, { cancelToken: token }).then((data) => {
                const blob = new Blob([data], {
                    type: 'image/png',
                });
                setImageUrl(URL.createObjectURL(blob));
            }).catch(err => {
                if (axios.isCancel(err)) {
                    // console.log('Request cancelled', err.message);
                } else {
                    logger.logDebugEvent('Dashboard.js', err.message, true);
                }
            });
        }

        return () => {
            // console.log("Cleaning up");
            if (cancel !== undefined) {
                cancel('Operation cancelled by the user.');
            }
        };
    }, [account]);

    return (
        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isAccountLoading === true}>
            {isObjectNotEmpty(account) && missingAccountInformation.length > 0 ? (
                missingAccountInformationDisplay()
            ) : null}
            <Header
                title={isObjectNotEmpty(account) && isStringNotEmpty(account.name) ? account.name : 'Loading...'}
                title2={isObjectNotEmpty(account) && isObjectNotEmpty(account.companyAddress) ? stringFormatter.toFormattedAddress(account.companyAddress) : ''}
                subtitle={isObjectNotEmpty(account) && isStringNotEmpty(account.irisId) ? account.irisId : ''}
                logo={imageUrl}
                icon={<FontAwesomeIcon className="anticon" icon={faHandHoldingUsd} />}
                highlights={[
                    {
                        title: 'EIN #',
                        value: isObjectNotEmpty(account) && isStringNotEmpty(account.ein) ? account.ein : null,
                    },
                ]}
            />
            {(isObjectNotEmpty(account) && isAccountLoading === false) ? (
                <div style={{ padding: 24 }}>
                    <Row gutter={[24, 24]}>
                        <Col lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 24 }}>
                            <ComplexCard
                                title="Account Details"
                                headStyle={{ fontSize: 14 }}
                                extra={extra}
                            >
                                {isStringNotEmpty(account.irisId) ? <DataRow title="Account ID" value={account.irisId} dataType="String" /> : null}
                                {isStringNotEmpty(account.email) ? <DataRow title="Email Address" value={account.email} dataType="String" /> : null}
                                {isStringNotEmpty(account.phone) ? <DataPhoneNumberRow title="Phone Number" phone={account.phone} phoneExt={account.phoneExt} /> : null}
                                {isStringNotEmpty(account.afterHoursPhone) ? <DataPhoneNumberRow title="After Hours Phone Number" phone={account.afterHoursPhone} phoneExt={account.afterHoursPhoneExt} /> : null}
                                {isStringNotEmpty(account.fax) ? <DataPhoneNumberRow title="Fax Number" phone={account.fax} /> : null}
                                {isObjectNotEmpty(account.pointOfContact) ? <DataContactInfoRow separateLines={false} valueStyle={{ paddingLeft: 16 }} title="Point of Contact" contact={account.pointOfContact} /> : null}
                                {isObjectNotEmpty(account.companyAddress) ? <DataAddressRow title="Company Address" oneLine={false} valueStyle={{ paddingLeft: 16 }} address={account.companyAddress} /> : null}
                                {CanDo({ adminsOnly: true }) ? (
                                    <>
                                        <DataRow title="Is Active" value={account.isActive} dataType="Boolean" />
                                        <DataRow title="Is Verified" value={account.isVerified} dataType="Boolean" />
                                    </>
                                ) : null}
                            </ComplexCard>
                        </Col>
                        <Col lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 24 }}>
                            <ComplexCard
                                title="Accounts Payable"
                                headStyle={{ fontSize: 14 }}
                            >
                                {isObjectNotEmpty(account.accountsPayable) ? <DataContactInfoRow title="A/P" separateLines={false} valueStyle={{ paddingLeft: 16 }} contact={account.accountsPayable} /> : null}
                                {isObjectNotEmpty(account.accountsPayableAddress) ? <DataAddressRow title="A/P Address" oneLine={false} valueStyle={{ paddingLeft: 16 }} address={account.accountsPayableAddress} /> : null}
                            </ComplexCard>
                        </Col>
                        <Col lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 24 }}>
                            <ComplexCard
                                title="Accounts Receivable"
                                headStyle={{ fontSize: 14 }}
                            >
                                {isObjectNotEmpty(account.accountsReceivable) ? <DataContactInfoRow title="A/R" separateLines={false} valueStyle={{ paddingLeft: 16 }} contact={account.accountsReceivable} /> : null}
                                {isObjectNotEmpty(account.accountsReceivableAddress) ? <DataAddressRow title="A/R Address" oneLine={false} valueStyle={{ paddingLeft: 16 }} address={account.accountsReceivableAddress} /> : null}
                                {isStringNotEmpty(account.preferredPaymentMethod) ? <DataRow title="Preferred Payment Method" value={account.preferredPaymentMethod} dataType="PreferredPaymentMethod" /> : null}
                            </ComplexCard>
                        </Col>
                    </Row>
                    {editEntityComponents}
                    {documentsComponent}
                    {editMissingAccountInformationDrawer}
                </div>
            ) : null}
        </Spin>
    );
};

export default CompanyFactoringSnapshot;