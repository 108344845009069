import React, { useMemo } from 'react';
import { Alert, Spin, Switch, Select } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import FormButtons from '../FormButtons/FormButtons';
import { isBooleanTrue, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';

const EditLoadCompletionChecklist = ({ load, cancel }) => {

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loads.isRecordUpdateLoading);
    const error = useSelector(state => state.loads.updateRecordError);

    const onSubmit = (data) => {
        let loadPayload = { completionCheckList: { ...data } };
        
        dispatch(actionCreators.updateLoad(load.id, loadPayload));
    };

    const onCancel = () => {
        dispatch(actionCreators.updateLoadCancel());
        cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLoadErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    //#region styles

    const formItemLayout = {
        labelCol: { span: 16 },
        wrapperCol: { span: 8 },
    };

    //#endregion

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true) && error === null}>
                    <FormItem {...formItemLayout} label="Have all Documents Been Reviewed?" format="horizontal"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                onBlur={onBlur}
                                onChange={(checked, event) => { onChange(checked); }}
                                checked={value}
                                name={name}
                                ref={ref}
                                disabled={true}
                            />
                        )}
                        name="hasDocumentsReviewed"
                        defaultValue={isObjectNotEmpty(load) && isObjectNotEmpty(load.completionCheckList) && isBooleanTrue(load.completionCheckList.hasDocumentsReviewed)}
                    />
                    <FormItem {...formItemLayout} label="Proof of Delivery Status"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Select
                                placeholder="Please Select a Status"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                                ref={ref}
                            >
                                <Select.Option value="MISSING" key="MISSING">POD is Needed</Select.Option>
                                <Select.Option value="UPLOADED" key="UPLOADED">POD is Pending Review</Select.Option>
                                <Select.Option value="CLEAN" key="CLEAN">POD is Clean</Select.Option>
                                <Select.Option value="OSDR" key="OSDR">POD has OSDR</Select.Option>
                            </Select>
                        )}
                        rules={{ required: false }}
                        name="proofOfDeliveryStatus"
                        defaultValue={isObjectNotEmpty(load) && isObjectNotEmpty(load.completionCheckList) && isStringNotEmpty(load.completionCheckList.proofOfDeliveryStatus) ? load.completionCheckList.proofOfDeliveryStatus : null}
                    />
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} cancelDisabled={isLoading === true && error === null} submitDisabled={(isLoading === true && error === null)} submitText="Update Checklist" />
            </Form>
        </FormProvider>
    );
};

export default EditLoadCompletionChecklist;