import * as actionTypes from "../actions/actionTypes";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { isBooleanTrue, isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import ErrorUtils from "../../shared/errorUtils";

//#region Fetch Shift List Methods

export const fetchShiftListStart = (listName) => {
    return {
        type: actionTypes.FETCH_SHIFT_LIST_START,
        payload: { listName: listName }
    }
};

export const fetchShiftListSuccess = (listName, payload) => {
    return {
        type: actionTypes.FETCH_SHIFT_LIST_SUCCESS,
        payload: { listName: listName, ...payload }
    }
};

export const fetchShiftListFail = (listName, payload) => {
    return {
        type: actionTypes.FETCH_SHIFT_LIST_FAIL,
        payload: { listName: listName, ...payload }
    }
};

export const clearShiftList = (listName) => {
    return {
        type: actionTypes.CLEAR_SHIFT_LIST,
        payload: { listName: listName }
    }
};

export const clearShiftLists = () => {
    return {
        type: actionTypes.CLEAR_SHIFT_LISTS
    }
};

export const addShiftToShiftList = (listName, payload) => {
    return {
        type: actionTypes.ADD_TO_SHIFT_LIST,
        payload: { listName: listName, newRecord: payload }
    }
};

export const updateShiftInShiftList = (listName, payload) => {
    return {
        type: actionTypes.UPDATE_IN_SHIFT_LIST,
        payload: { listName: listName, updatedRecord: payload }
    }
};

export const removeShiftFromShiftList = (listName, payload) => {
    return {
        type: actionTypes.REMOVE_FROM_SHIFT_LIST,
        payload: { listName: listName, recordToRemove: payload }
    }
};

//#endregion
//#region Fetch Shift Methods

export const fetchShiftStart = () => {
    return {
        type: actionTypes.FETCH_SHIFT_START
    }
};

export const fetchShiftSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_SHIFT_SUCCESS,
        payload: payload
    }
};

export const fetchShiftFail = (payload) => {
    return {
        type: actionTypes.FETCH_SHIFT_FAIL,
        payload: payload
    }
};

export const clearShift = () => {
    return {
        type: actionTypes.CLEAR_SHIFT
    }
};

//#endregion
//#region Add Shift Methods

export const addShiftStart = () => {
    return {
        type: actionTypes.ADD_SHIFT_START
    }
};

export const addShiftSuccess = () => {
    return {
        type: actionTypes.ADD_SHIFT_SUCCESS
    }
};

export const addShiftFail = (payload) => {
    return {
        type: actionTypes.ADD_SHIFT_FAIL,
        payload: payload
    }
};

export const addShiftLoadingClear = () => {
    return {
        type: actionTypes.ADD_SHIFT_LOADING_CLEAR
    }
};

export const addShiftErrorClear = () => {
    return {
        type: actionTypes.ADD_SHIFT_ERROR_CLEAR
    }
};

export const addShiftCancel = () => {
    return {
        type: actionTypes.ADD_SHIFT_CANCEL
    }
};

//#endregion
//#region Update Shift Methods

const changeSingleShift = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_SHIFT,
        payload: payload
    }
};

export const updateShiftStart = () => {
    return {
        type: actionTypes.UPDATE_SHIFT_START
    }
};

export const updateShiftSuccess = () => {
    return {
        type: actionTypes.UPDATE_SHIFT_SUCCESS
    }
};

export const updateShiftFail = (payload) => {
    return {
        type: actionTypes.UPDATE_SHIFT_FAIL,
        payload: payload
    }
};

export const updateShiftLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_SHIFT_LOADING_CLEAR
    }
};

export const updateShiftErrorClear = () => {
    return {
        type: actionTypes.UPDATE_SHIFT_ERROR_CLEAR
    }
};

export const updateShiftCancel = () => {
    return {
        type: actionTypes.UPDATE_SHIFT_CANCEL
    }
};

//#endregion
//#region Shifts Methods

export const fetchShiftList = (listName, payload) => {
    return async (dispatch, getState) => {
        if (isStringNotEmpty(listName)) {
            try {
                dispatch(fetchShiftListStart(listName));

                const state = getState();
                const shiftsState = { ...state.shifts };
                const existingLists = { ...shiftsState.lists };

                let pagination = {};
                let searchParams = {};
                if (isObjectNotEmpty(existingLists[listName])) {
                    pagination = { ...existingLists[listName].pagination };
                    searchParams = { ...existingLists[listName].searchParams };
                }

                if (isObjectNotEmpty(payload)) {
                    searchParams = { ...payload };
                }

                const res = await Data.getShifts({ ...searchParams }, pagination);
                dispatch(fetchShiftListSuccess(listName, { records: res.data, params: { searchParams: res.searchParams, pagination: res.pagination } }));
            } catch (error) {
                logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
                dispatch(fetchShiftListFail(listName, { error: ErrorUtils.getErrorMessage(error) }));
            }
        }
    }
};

export const fetchShift = (id) => {
    return async (dispatch) => {
        try {
            dispatch(fetchShiftStart());

            const shift = await Data.getShift(id);
            dispatch(fetchShiftSuccess({ record: shift }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchShiftFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const addShift = (payload) => {
    return async (dispatch) => {
        try {
            dispatch(addShiftStart());

            const newShift = await Data.addShift(payload);
            if (isObjectNotEmpty(newShift)) {
                dispatch(addShiftToShiftList(newShift.accountId, newShift));
            }

            dispatch(addShiftSuccess());
            dispatch(addShiftLoadingClear());
            dispatch(addShiftErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addShiftFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateShift = (id, payload) => {
    return async (dispatch) => {
        try {
            dispatch(updateShiftStart());

            if (isBooleanTrue(payload.isDeleted)) {
                const removedShift = await Data.removeShift(id);
                if (isObjectNotEmpty(removedShift)) {
                    dispatch(removeShiftFromShiftList(removedShift.accountId, removedShift));
                }
            } else {
                const updatedShift = await Data.updateShift(id, payload);
                if (isObjectNotEmpty(updatedShift)) {
                    dispatch(updateShiftInShiftList(updatedShift.accountId, updatedShift));
                    dispatch(changeSingleShift(updatedShift));
                }
            }

            dispatch(updateShiftSuccess());
            dispatch(updateShiftLoadingClear());
            dispatch(updateShiftErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateShiftFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion