import * as actionTypes from "../actions/actionTypes";
import moment from "moment";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { isListNotEmpty, isNotNullOrUndefined, isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import * as actionCreators from "./index";
import LocalStorage from '../../shared/localStorageUtils';
import ErrorUtils from "../../shared/errorUtils";
import { selectListRecords } from "../utility";

//#region Fetch Load List Methods

export const fetchLoadListStart = (listName) => {
    return {
        type: actionTypes.FETCH_LOAD_LIST_START,
        payload: { listName: listName }
    }
};

export const fetchLoadListSuccess = (listName, payload) => {
    return {
        type: actionTypes.FETCH_LOAD_LIST_SUCCESS,
        payload: { listName: listName, ...payload }
    }
};

export const fetchLoadListFail = (listName, payload) => {
    return {
        type: actionTypes.FETCH_LOAD_LIST_FAIL,
        payload: { listName: listName, ...payload }
    }
};

export const clearLoadList = (listName) => {
    return {
        type: actionTypes.CLEAR_LOAD_LIST,
        payload: { listName: listName }
    }
};

export const clearLoadLists = () => {
    return {
        type: actionTypes.CLEAR_LOAD_LISTS
    }
};

export const addLoadToLoadList = (listName, payload) => {
    return {
        type: actionTypes.ADD_TO_LOAD_LIST,
        payload: { listName: listName, newRecord: payload }
    }
};

export const updateLoadInLoadList = (listName, payload) => {
    return {
        type: actionTypes.UPDATE_IN_LOAD_LIST,
        payload: { listName: listName, updatedRecord: payload }
    }
};

export const removeLoadFromLoadList = (listName, payload) => {
    return {
        type: actionTypes.REMOVE_FROM_LOAD_LIST,
        payload: { listName: listName, recordToRemove: payload }
    }
};

//#endregion
//#region Fetch Loads Methods

export const fetchLoadsStart = () => {
    return {
        type: actionTypes.FETCH_LOADS_START
    }
};

export const fetchLoadsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_LOADS_SUCCESS,
        payload: payload
    }
};

export const fetchLoadsFail = (payload) => {
    return {
        type: actionTypes.FETCH_LOADS_FAIL,
        payload: payload
    }
};

export const clearLoads = () => {
    return {
        type: actionTypes.CLEAR_LOADS
    }
};

//#endregion
//#region Fetch Bundled Loads Methods

export const fetchBundledLoadsStart = () => {
    return {
        type: actionTypes.FETCH_BUNDLED_LOADS_START
    }
};

export const fetchBundledLoadsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_BUNDLED_LOADS_SUCCESS,
        payload: payload
    }
};

export const fetchBundledLoadsFail = (payload) => {
    return {
        type: actionTypes.FETCH_BUNDLED_LOADS_FAIL,
        payload: payload
    }
};

export const clearBundledLoads = () => {
    return {
        type: actionTypes.CLEAR_BUNDLED_LOADS
    }
};

//#endregion
//#region Fetch Load Methods

export const fetchLoadStart = () => {
    return {
        type: actionTypes.FETCH_LOAD_START
    }
};

export const fetchLoadSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_LOAD_SUCCESS,
        payload: payload
    }
};

export const fetchLoadFail = (payload) => {
    return {
        type: actionTypes.FETCH_LOAD_FAIL,
        payload: payload
    }
};

export const clearLoad = () => {
    return {
        type: actionTypes.CLEAR_LOAD
    }
};

//#endregion
//#region Add Load Methods

export const addLoadStart = () => {
    return {
        type: actionTypes.ADD_LOAD_START
    }
};

export const addLoadSuccess = () => {
    return {
        type: actionTypes.ADD_LOAD_SUCCESS
    }
};

export const addLoadFail = (payload) => {
    return {
        type: actionTypes.ADD_LOAD_FAIL,
        payload: payload
    }
};

export const addLoadLoadingClear = () => {
    return {
        type: actionTypes.ADD_LOAD_LOADING_CLEAR
    }
};

export const addLoadErrorClear = () => {
    return {
        type: actionTypes.ADD_LOAD_ERROR_CLEAR
    }
};

export const addLoadCancel = () => {
    return {
        type: actionTypes.ADD_LOAD_CANCEL
    }
};

//#endregion
//#region Update Load Methods

const changeLoad = (payload) => {
    return {
        type: actionTypes.UPDATE_LOAD,
        payload: payload
    }
};

const changeSingleLoad = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_LOAD,
        payload: payload
    }
};

export const updateLoadStart = () => {
    return {
        type: actionTypes.UPDATE_LOAD_START
    }
};

export const updateLoadSuccess = () => {
    return {
        type: actionTypes.UPDATE_LOAD_SUCCESS
    }
};

export const updateLoadFail = (payload) => {
    return {
        type: actionTypes.UPDATE_LOAD_FAIL,
        payload: payload
    }
};

export const updateLoadLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_LOAD_LOADING_CLEAR
    }
};

export const updateLoadErrorClear = () => {
    return {
        type: actionTypes.UPDATE_LOAD_ERROR_CLEAR
    }
};

export const updateLoadCancel = () => {
    return {
        type: actionTypes.UPDATE_LOAD_CANCEL
    }
};

//#endregion
//#region Update Bundled Load Methods

const changeBundledLoad = (payload) => {
    return {
        type: actionTypes.UPDATE_BUNDLED_LOAD,
        payload: payload
    }
};

//#endregion
//#region Update Load Status Methods

export const updateLoadStatusStart = () => {
    return {
        type: actionTypes.UPDATE_LOAD_STATUS_START
    }
};

export const updateLoadStatusSuccess = () => {
    return {
        type: actionTypes.UPDATE_LOAD_STATUS_SUCCESS
    }
};

export const updateLoadStatusFail = (payload) => {
    return {
        type: actionTypes.UPDATE_LOAD_STATUS_FAIL,
        payload: payload
    }
};

export const updateLoadStatusLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_LOAD_STATUS_LOADING_CLEAR
    }
};

export const updateLoadStatusErrorClear = () => {
    return {
        type: actionTypes.UPDATE_LOAD_STATUS_ERROR_CLEAR
    }
};

export const updateLoadStatusCancel = () => {
    return {
        type: actionTypes.UPDATE_LOAD_STATUS_CANCEL
    }
};

//#endregion
//#region Loads Methods

export const fetchLoadList = (listName, payload) => {
    return async (dispatch, getState) => {
        if (isStringNotEmpty(listName)) {
            try {
                const state = getState();
                const loadsState = { ...state.loads };
                const existingLists = { ...loadsState.lists };

                let pagination = {};
                let searchParams = {};
                if (isObjectNotEmpty(existingLists[listName])) {
                    pagination = { ...existingLists[listName].pagination };
                    searchParams = { ...existingLists[listName].searchParams };
                }

                if (isObjectNotEmpty(payload)) {
                    searchParams = { ...payload };
                }

                dispatch(fetchLoadListStart(listName));

                const res = await Data.getLoadList({ ...searchParams }, pagination);
                dispatch(fetchLoadListSuccess(listName, { records: res.data, params: { pagination: res.pagination, searchParams: res.searchParams } }));
            } catch (error) {
                dispatch(fetchLoadListFail(listName, { error: ErrorUtils.getErrorMessage(error) }));
                logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            }
        }
    }
};

export const fetchMoreLoadList = (listName, payload) => {
    return async (dispatch, getState) => {
        if (isStringNotEmpty(listName)) {
            try {
                const state = getState();
                const loadsState = { ...state.loads };
                const existingLists = { ...loadsState.lists };

                let pagination = {};
                let searchParams = {};
                let updatedRecords = [];
                if (isObjectNotEmpty(existingLists[listName])) {
                    pagination = { ...existingLists[listName].pagination };
                    searchParams = { ...existingLists[listName].searchParams };
                    updatedRecords = [...existingLists[listName].records];
                }

                if (isObjectNotEmpty(payload)) {
                    searchParams = { ...payload };
                }

                dispatch(fetchLoadListStart(listName));

                const res = await Data.getMoreLoadList({ ...searchParams }, pagination, updatedRecords);
                dispatch(fetchLoadListSuccess(listName, { records: res.data, params: { pagination: res.pagination, searchParams: res.searchParams } }));
            } catch (error) {
                dispatch(fetchLoadListFail(listName, { error: ErrorUtils.getErrorMessage(error) }));
                logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            }
        }
    }
};

export const fetchLoads = (payload) => {
    return async (dispatch) => {
        try {
            dispatch(fetchLoadsStart());

            const res = await Data.getLoads({ ...payload, isDeleted: false, sort: 'pickUpDateTime', order: 'asc' })
            dispatch(fetchLoadsSuccess({ records: res.data }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchLoadsFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const fetchLoadsWithIncludes = (payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(fetchLoadsStart());

            const state = getState();
            const loadsState = { ...state.loads };
            let searchParams = { ...loadsState.searchParams };
            let pagination = { ...loadsState.pagination };

            if (isObjectNotEmpty(payload)) {
                searchParams = { ...payload };
            }

            const res = await Data.getLoadsWithIncludes({ ...searchParams }, pagination);
            dispatch(fetchLoadsSuccess({ records: res.data, searchParams: res.searchParams, pagination: res.pagination }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchLoadsFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const fetchAllLoadsWithIncludes = (payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(fetchLoadsStart());

            const state = getState();
            const loadsState = { ...state.loads };
            let searchParams = { ...loadsState.searchParams };
            let pagination = { ...loadsState.pagination };

            if (isObjectNotEmpty(payload)) {
                searchParams = { ...payload };
            }

            const res = await Data.getLoadsWithIncludes({ ...searchParams }, pagination);
            dispatch(fetchLoadsSuccess({ records: res.data, searchParams: res.searchParams, pagination: res.pagination }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchLoadsFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const fetchActiveLoadList = (listName, payload) => {
    return async (dispatch, getState) => {
        if (isStringNotEmpty(listName)) {
            try {
                const state = getState();
                const loadsState = { ...state.loads };
                const existingLists = { ...loadsState.lists };

                let pagination = {};
                let searchParams = {};
                if (isObjectNotEmpty(existingLists[listName])) {
                    pagination = { ...existingLists[listName].pagination };
                    searchParams = { ...existingLists[listName].searchParams };
                }

                if (isObjectNotEmpty(payload)) {
                    searchParams = { ...payload };
                }

                dispatch(fetchLoadListStart(listName));

                const res = await Data.getInTransitLoadsWithoutLocationData({ ...searchParams }, pagination);
                dispatch(fetchLoadListSuccess(listName, { records: res.data, params: { pagination: res.pagination, searchParams: res.searchParams } }));
            } catch (error) {
                dispatch(fetchLoadListFail(listName, { error: ErrorUtils.getErrorMessage(error) }));
                logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            }
        }
    }
};

export const fetchLoadWithIncludes = (id) => {
    return async (dispatch) => {
        try {
            if (isStringNotEmpty(id)) {
                dispatch(fetchLoadStart());

                //const load = await Data.getLoadWithIncludes(id, true);
                const load = await Data.getLoadWithIncludes(id, false);
                dispatch(fetchLoadSuccess({ record: load }));
                dispatch(actionCreators.updateLoadEventStatus({ loadEventUpdateStatus: 'COMPLETED' }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchLoadFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const addLoad = (loadPayload, stopsPayload, invoiceLineItemsPayload, docFiles) => {
    return async (dispatch) => {
        try {
            dispatch(addLoadStart());

            const newLoad = await Data.addLoad(loadPayload, stopsPayload, invoiceLineItemsPayload, docFiles);
            if (isObjectNotEmpty(newLoad)) {
                dispatch(fetchLoadSuccess({ addRecordId: newLoad.id }));

                dispatch(addLoadSuccess());
                dispatch(addLoadLoadingClear());
                dispatch(addLoadErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addLoadFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const addDuplicateLoads = (loadPayload, stopsPayload, invoiceLineItemsPayload, duplicateCount) => {
    return async (dispatch) => {
        try {
            dispatch(addLoadStart());

            const newLoads = await Data.addDuplicateLoads(loadPayload, stopsPayload, invoiceLineItemsPayload, duplicateCount);
            if (isListNotEmpty(newLoads)) {
                dispatch(fetchLoadSuccess({ addRecordId: newLoads.map(l => l.id) }));

                dispatch(addLoadSuccess());
                dispatch(addLoadLoadingClear());
                dispatch(addLoadErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addLoadFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateLoad = (id, payload) => {
    return async (dispatch) => {
        try {
            dispatch(updateLoadStart());

            const updatedLoad = await Data.updateLoad(id, payload);
            if (isObjectNotEmpty(updatedLoad)) {
                dispatch(actionCreators.loadEventUpdateTimeout(id, null, null, null, 'LOAD_UPDATED'));
            }

            dispatch(updateLoadSuccess());
            dispatch(updateLoadLoadingClear());
            dispatch(updateLoadErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateLoadFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const deleteLoad = (id) => {
    return async (dispatch) => {
        try {
            dispatch(updateLoadStart());

            const removedLoad = await Data.removeLoad(id);
            if (isObjectNotEmpty(removedLoad)) {
                dispatch(actionCreators.loadEventUpdateTimeout(id, null, null, null, 'LOAD_UPDATED', 'LOAD_REMOVED'));
            }

            dispatch(updateLoadSuccess());
            dispatch(updateLoadLoadingClear());
            dispatch(updateLoadErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateLoadFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const undeleteLoad = (id) => {
    return async (dispatch) => {
        try {
            dispatch(updateLoadStart());

            const unremovedLoad = await Data.unremoveLoad(id);
            if (isObjectNotEmpty(unremovedLoad)) {
                dispatch(actionCreators.loadEventUpdateTimeout(id, null, null, null, 'LOAD_UPDATED', 'LOAD_UNREMOVED'));
            }

            dispatch(updateLoadSuccess());
            dispatch(updateLoadLoadingClear());
            dispatch(updateLoadErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateLoadFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const changeCarrierInLoad = (id, payload) => {
    return async (dispatch) => {
        try {
            dispatch(updateLoadStart());

            const updatedLoadEvent = await Data.updateAssignedCarrierForLoad(id, payload);
            if (isObjectNotEmpty(updatedLoadEvent)) {
                dispatch(actionCreators.loadEventUpdateTimeout(updatedLoadEvent.loadId, null, null, null, updatedLoadEvent.eventType, updatedLoadEvent.changeType));
            }

            dispatch(updateLoadSuccess());
            dispatch(updateLoadLoadingClear());
            dispatch(updateLoadErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateLoadFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const removeDriverFromLoad = (id, payload, accountUser) => {
    return async (dispatch, getState) => {
        try {
            if (isObjectNotEmpty(accountUser)) {
                dispatch(updateLoadStart());

                await dispatch(actionCreators.getAccounts());
                const state = getState();
                const orchestratorState = { ...state.orchestrator };
                const accounts = [...orchestratorState.accounts];

                const updatedLoadEvent = await Data.removeDriverFromLoad(id, payload, accountUser, accounts);
                if (isObjectNotEmpty(updatedLoadEvent)) {
                    dispatch(actionCreators.loadEventUpdateTimeout(updatedLoadEvent.loadId, null, null, null, updatedLoadEvent.eventType, updatedLoadEvent.changeType));
                }

                dispatch(updateLoadSuccess());
                dispatch(updateLoadLoadingClear());
                dispatch(updateLoadErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateLoadFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updatePriceConfirmation = (payload, loadId, isShipper = false) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updateLoadStart());

            const updatedLoadEvent = await Data.updatePriceConfirmation(loadId, payload.file, payload.approvedPriceConfirmation, isShipper);
            if (isObjectNotEmpty(updatedLoadEvent)) {
                const state = getState();
                const loadsState = { ...state.loads };
                // check if the load that is in the current state matches the loadId and if so, update the load in state
                if (loadsState.record.id === loadId) {
                    let singleLoadToUpdate = { ...loadsState.record, isPriceConfirmed: true, priceConfirmedAt: moment().utc().toISOString(), priceConfirmedBy: null };
                    dispatch(changeSingleLoad(singleLoadToUpdate));
                }

                dispatch(actionCreators.loadEventUpdateTimeout(updatedLoadEvent.loadId, null, null, null, updatedLoadEvent.eventType, updatedLoadEvent.changeType));

                dispatch(updateLoadSuccess());
                dispatch(updateLoadLoadingClear());
                dispatch(updateLoadErrorClear());
            } else {
                dispatch(updateLoadFail({ error: 'Failed to upload the Load Tender document.' }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateLoadFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateRateConfirmation = (payload, loadId, isCarrier = false) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updateLoadStart());

            const updatedLoadEvent = await Data.updateRateConfirmation(loadId, payload.file, payload.approvedRateConfirmation, isCarrier);
            if (isObjectNotEmpty(updatedLoadEvent)) {
                const state = getState();
                const loadsState = { ...state.loads };
                // check if the load that is in the current state matches the loadId and if so, update the load in state
                if (loadsState.record.id === loadId) {
                    let singleLoadToUpdate = { ...loadsState.record, isRateConfirmed: true, rateConfirmedAt: moment().utc().toISOString(), rateConfirmedBy: null };
                    dispatch(changeSingleLoad(singleLoadToUpdate));
                }

                dispatch(actionCreators.loadEventUpdateTimeout(updatedLoadEvent.loadId, null, null, null, updatedLoadEvent.eventType, updatedLoadEvent.changeType));

                dispatch(updateLoadSuccess());
                dispatch(updateLoadLoadingClear());
                dispatch(updateLoadErrorClear());
            } else {
                dispatch(updateLoadFail({ error: 'Failed to upload the Rate Confirmation document.' }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateLoadFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateLoadStatus = (loadId, loadStatus) => {
    return async (dispatch, getState) => {
        try {
            if (isStringNotEmpty(loadId) && isStringNotEmpty(loadStatus)) {
                const state = getState();
                const loadsState = { ...state.loads };
                const updatedLoads = [...loadsState.records];

                let loadToUpdate = updatedLoads.find(i => i.id === loadId);
                if (isObjectNotEmpty(loadToUpdate)) {
                    // loads in load list don't have stops attached to them so no need to update the stops on the load in the list
                    loadToUpdate.loadStatus = loadStatus;
                    dispatch(changeLoad(loadToUpdate));
                }

                dispatch(actionCreators.updateLoadEventStatus({ loadEventUpdateStatus: 'COMPLETED' }));
            } else if (isStringNotEmpty(loadId)) {
                dispatch(actionCreators.updateLoadEventStatus({ loadEventUpdateStatus: 'COMPLETED' }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
        }
    }
};

export const updateLoadStatusAndLoadLists = (loadId, stopId, loadStatus, stopStatus, eventType, entityType, refreshLoad = false, refreshLoads = false, refreshStops = false, refreshInvoiceLineItems = false, refreshTransactions = false, refreshClaims = false, refreshLoadCancellations = false, refreshCarrierRejections = false, clearActiveLoad = false, refreshInvoices = false) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updateLoadStart());

            const state = getState();
            const loadsState = { ...state.loads };

            if (isStringNotEmpty(loadId)) {
                if (clearActiveLoad === true) {
                    await LocalStorage.removeItem('activeLoadId');
                    await LocalStorage.removeItem('activeLoadIrisId');
                    await LocalStorage.removeItem('currentLocation');
                    await LocalStorage.removeItem('sendToTMS');
                    await LocalStorage.removeItem('notMovedCount');
                    await LocalStorage.removeItem('distanceTravelled');
                    await LocalStorage.removeItem('activeLoadStatus');
                    await LocalStorage.removeItem('currentStopId');
                    await LocalStorage.removeItem('currentStopStatus');

                    dispatch(actionCreators.clearActiveLoad());
                }

                if (refreshLoads === true) {
                    // refresh all load lists that contain the load that was updated
                    const loadLists = { ...loadsState.lists };
                    for (let [key, value] of Object.entries(loadLists)) {
                        let loads = value.records;
                        let existingLoad = loads.find(l => l.id === loadId);
                        if (isNotNullOrUndefined(existingLoad)) {
                            dispatch(fetchLoadList(key, null));
                        }
                    }
                }

                if (isObjectNotEmpty(loadsState.record)) {
                    const oldLoad = { ...loadsState.record };
                    if (oldLoad.id === loadId) {
                        if (refreshLoad === true) {
                            await dispatch(fetchLoadWithIncludes(loadId));
                        }
                        if (refreshLoads === true) {
                            // check if it's in the list first
                            const currentLoads = [...loadsState.records];
                            const loadToUpdateIndex = currentLoads.findIndex(load => { return load.id === loadId });
                            // findIndex will return -1 if it doesn't exist
                            if (loadToUpdateIndex !== -1) {
                                await dispatch(fetchLoadsWithIncludes(null));
                            }

                            if (eventType === 'LOAD_REQUEST_TO_CARRIER_REJECTED') {
                                if (entityType === "CARRIER") {
                                    dispatch(fetchLoadList('loadrequest', null));
                                } else {
                                    dispatch(fetchLoadList('open', null));
                                }
                            }
                        }
                        if (refreshStops === true) {
                            await dispatch(actionCreators.fetchLoadStopList(loadId, oldLoad));
                        }
                        if (refreshInvoiceLineItems === true) {
                            await dispatch(actionCreators.fetchInvoiceLineItemList(loadId, { page: 1, size: 100000, loadId: loadId, isDeleted: false }));
                        }
                        if (refreshTransactions === true) {
                            await dispatch(actionCreators.fetchTransactionList(loadId, { page: 1, size: 100000, loadId: loadId, isDeleted: false }));
                        }
                        if (refreshClaims === true) {
                            await dispatch(actionCreators.fetchClaimList(loadId, { page: 1, size: 100000, loadId: loadId, isDeleted: false }));
                        }
                        if (refreshLoadCancellations === true) {
                            await dispatch(actionCreators.fetchLoadCancellationList(loadId, { page: 1, size: 100000, loadId: loadId, isDeleted: false }));
                        }
                        if (refreshCarrierRejections === true) {
                            await dispatch(actionCreators.fetchCarrierRejectionList(loadId, { page: 1, size: 100000, loadId: loadId, isDeleted: false }));
                        }
                        if (refreshInvoices === true) {
                            await dispatch(actionCreators.fetchInvoiceList(loadId, { page: 1, size: 100000, loadId: loadId, isDeleted: false }));
                        }
                    }
                }

                if (isStringNotEmpty(loadStatus)) {
                    if (entityType === "STAFF" || entityType === "SHIPPER" || entityType === "BROKER") {
                        if (loadStatus === 'PENDING' || loadStatus === 'APPROVED' || loadStatus === 'PENDING_RATE_CONFIRMATION') {
                            const state2 = getState();
                            const loadsState2 = { ...state2.loads };
                            const loadLists = { ...loadsState2.lists };
                            const openLoadsList = selectListRecords(loadLists, 'open');

                            const openLoadsLoadToUpdateIndex = openLoadsList.findIndex(load => load.id === loadId);
                            if (openLoadsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = { ...openLoadsList[openLoadsLoadToUpdateIndex], loadStatus: loadStatus };
                                dispatch(updateLoadInLoadList('open', loadToUpdate));
                            }
                        } else if (loadStatus === 'BOOKED') {
                            const state2 = getState();
                            const loadsState2 = { ...state2.loads };
                            const loadLists = { ...loadsState2.lists };
                            const openLoadsList = selectListRecords(loadLists, 'open');
                            const bookedLoadsList = selectListRecords(loadLists, 'booked');

                            const bundledLoadsList = [...loadsState2.bundledLoads];
                            const bundledLoadsLoadToUpdateIndex = bundledLoadsList.findIndex(load => load.id === loadId);
                            if (bundledLoadsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = { ...bundledLoadsList[bundledLoadsLoadToUpdateIndex], loadStatus: loadStatus };
                                dispatch(changeBundledLoad(loadToUpdate));
                            }

                            const openLoadsLoadToUpdateIndex = openLoadsList.findIndex(load => load.id === loadId);
                            if (openLoadsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = { ...openLoadsList[openLoadsLoadToUpdateIndex], loadStatus: loadStatus };
                                dispatch(removeLoadFromLoadList('open', loadToUpdate));
                                dispatch(addLoadToLoadList('booked', loadToUpdate));
                            } else {
                                const bookedLoadsLoadToUpdateIndex = bookedLoadsList.findIndex(load => load.id === loadId);
                                if (bookedLoadsLoadToUpdateIndex !== -1) {
                                    let loadToUpdate = { ...bookedLoadsList[bookedLoadsLoadToUpdateIndex], loadStatus: loadStatus };
                                    dispatch(updateLoadInLoadList('booked', loadToUpdate));
                                }
                            }
                        } else if (loadStatus === 'SCHEDULED') {
                            const state2 = getState();
                            const loadsState2 = { ...state2.loads };
                            const loadLists = { ...loadsState2.lists };
                            const bookedLoadsList = selectListRecords(loadLists, 'booked');

                            const bundledLoadsList = [...loadsState2.bundledLoads];
                            const bundledLoadsLoadToUpdateIndex = bundledLoadsList.findIndex(load => load.id === loadId);
                            if (bundledLoadsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = { ...bundledLoadsList[bundledLoadsLoadToUpdateIndex], loadStatus: loadStatus };
                                dispatch(changeBundledLoad(loadToUpdate));
                            }

                            const bookedLoadsLoadToUpdateIndex = bookedLoadsList.findIndex(load => load.id === loadId);
                            if (bookedLoadsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = { ...bookedLoadsList[bookedLoadsLoadToUpdateIndex], loadStatus: loadStatus };
                                dispatch(updateLoadInLoadList('booked', loadToUpdate));
                            }
                        } else if (loadStatus === 'IN_TRANSIT') {
                            const state2 = getState();
                            const loadsState2 = { ...state2.loads };
                            const loadLists = { ...loadsState2.lists };
                            const bookedLoadsList = selectListRecords(loadLists, 'booked');
                            const intransitLoadsList = selectListRecords(loadLists, 'intransit');

                            const bookedLoadsLoadToUpdateIndex = bookedLoadsList.findIndex(load => load.id === loadId);
                            if (bookedLoadsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = { ...bookedLoadsList[bookedLoadsLoadToUpdateIndex], loadStatus: loadStatus };
                                dispatch(removeLoadFromLoadList('booked', loadToUpdate));
                                dispatch(addLoadToLoadList('intransit', loadToUpdate));
                            } else {
                                const intransitLoadsLoadToUpdateIndex = intransitLoadsList.findIndex(load => load.id === loadId);
                                if (intransitLoadsLoadToUpdateIndex !== -1) {
                                    let loadToUpdate = { ...intransitLoadsList[intransitLoadsLoadToUpdateIndex], loadStatus: loadStatus };
                                    dispatch(updateLoadInLoadList('intransit', loadToUpdate));
                                }
                            }
                        } else if (loadStatus === 'AT_STOP') {
                            const state2 = getState();
                            const loadsState2 = { ...state2.loads };
                            const loadLists = { ...loadsState2.lists };
                            const intransitLoadsList = selectListRecords(loadLists, 'intransit');

                            const intransitLoadsLoadToUpdateIndex = intransitLoadsList.findIndex(load => load.id === loadId);
                            if (intransitLoadsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = { ...intransitLoadsList[intransitLoadsLoadToUpdateIndex], loadStatus: loadStatus };
                                dispatch(updateLoadInLoadList('intransit', loadToUpdate));
                            }
                        } else if (loadStatus === 'COMPLETED') {
                            const state2 = getState();
                            const loadsState2 = { ...state2.loads };
                            const loadLists = { ...loadsState2.lists };
                            const intransitLoadsList = selectListRecords(loadLists, 'intransit');
                            const completedLoadsList = selectListRecords(loadLists, 'completed');

                            const intransitLoadsLoadToUpdateIndex = intransitLoadsList.findIndex(load => load.id === loadId);
                            if (intransitLoadsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = { ...intransitLoadsList[intransitLoadsLoadToUpdateIndex], loadStatus: loadStatus };
                                dispatch(removeLoadFromLoadList('intransit', loadToUpdate));
                                dispatch(addLoadToLoadList('completed', loadToUpdate));
                            } else {
                                const completedLoadToUpdateIndex = completedLoadsList.findIndex(load => load.id === loadId);
                                if (completedLoadToUpdateIndex !== -1) {
                                    let loadToUpdate = { ...completedLoadsList[completedLoadToUpdateIndex], loadStatus: loadStatus };
                                    dispatch(updateLoadInLoadList('completed', loadToUpdate));
                                }
                            }
                        } else if (loadStatus === 'CLOSED' || loadStatus === 'REOPENED') {
                            const state2 = getState();
                            const loadsState2 = { ...state2.loads };
                            const loadLists = { ...loadsState2.lists };
                            const completedLoadsList = selectListRecords(loadLists, 'completed');

                            const completedLoadToUpdateIndex = completedLoadsList.findIndex(load => load.id === loadId);
                            if (completedLoadToUpdateIndex !== -1) {
                                let loadToUpdate = { ...completedLoadsList[completedLoadToUpdateIndex], loadStatus: loadStatus };
                                dispatch(updateLoadInLoadList('completed', loadToUpdate));
                            }
                        } else if (loadStatus === 'CANCELLED') {
                            const state2 = getState();
                            const loadsState2 = { ...state2.loads };
                            const loadLists = { ...loadsState2.lists };
                            const openLoadsList = selectListRecords(loadLists, 'open');
                            const bookedLoadsList = selectListRecords(loadLists, 'booked');
                            const intransitLoadsList = selectListRecords(loadLists, 'intransit');
                            const completedLoadsList = selectListRecords(loadLists, 'completed');

                            const openLoadsLoadToUpdateIndex = openLoadsList.findIndex(load => load.id === loadId);
                            if (openLoadsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = { ...openLoadsList[openLoadsLoadToUpdateIndex], loadStatus: loadStatus };
                                dispatch(removeLoadFromLoadList('open', loadToUpdate));
                                dispatch(addLoadToLoadList('completed', loadToUpdate));
                            }
                            const bookedLoadsLoadToUpdateIndex = bookedLoadsList.findIndex(load => load.id === loadId);
                            if (bookedLoadsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = { ...bookedLoadsList[bookedLoadsLoadToUpdateIndex], loadStatus: loadStatus };
                                dispatch(removeLoadFromLoadList('booked', loadToUpdate));
                                dispatch(addLoadToLoadList('completed', loadToUpdate));
                            }
                            const intransitLoadsLoadToUpdateIndex = intransitLoadsList.findIndex(load => load.id === loadId);
                            if (intransitLoadsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = { ...intransitLoadsList[intransitLoadsLoadToUpdateIndex], loadStatus: loadStatus };
                                dispatch(removeLoadFromLoadList('intransit', loadToUpdate));
                                dispatch(addLoadToLoadList('completed', loadToUpdate));
                            }
                            const completedLoadToUpdateIndex = completedLoadsList.findIndex(load => load.id === loadId);
                            if (completedLoadToUpdateIndex !== -1) {
                                let loadToUpdate = { ...completedLoadsList[completedLoadToUpdateIndex], loadStatus: loadStatus };
                                dispatch(updateLoadInLoadList('completed', loadToUpdate));
                            }
                        }
                    } else if (entityType === "CARRIER") {
                        if (loadStatus === 'PENDING_RATE_CONFIRMATION') {
                            const state2 = getState();
                            const loadsState2 = { ...state2.loads };
                            const loadLists = { ...loadsState2.lists };
                            const loadRequestsList = selectListRecords(loadLists, 'loadrequest');

                            const index = loadRequestsList.findIndex(load => load.id === loadId);
                            if (index !== -1) {
                                let loadToUpdate = { ...loadRequestsList[index], loadStatus: loadStatus };
                                dispatch(updateLoadInLoadList('loadrequest', loadToUpdate));
                            }
                        } else if (loadStatus === 'BOOKED') {
                            const state2 = getState();
                            const loadsState2 = { ...state2.loads };
                            const loadLists = { ...loadsState2.lists };
                            const loadRequestsList = selectListRecords(loadLists, 'loadrequest');
                            const openLoadsList = selectListRecords(loadLists, 'open');

                            const bundledLoadsList = [...loadsState2.bundledLoads];
                            const bundledLoadsLoadToUpdateIndex = bundledLoadsList.findIndex(load => load.id === loadId);
                            if (bundledLoadsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = { ...bundledLoadsList[bundledLoadsLoadToUpdateIndex], loadStatus: loadStatus };
                                dispatch(changeBundledLoad(loadToUpdate));
                            }

                            const loadRequestsLoadToUpdateIndex = loadRequestsList.findIndex(load => load.id === loadId);
                            if (loadRequestsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = { ...loadRequestsList[loadRequestsLoadToUpdateIndex], loadStatus: loadStatus };
                                dispatch(removeLoadFromLoadList('loadrequest', loadToUpdate));
                                dispatch(addLoadToLoadList('open', loadToUpdate));
                            } else {
                                const openLoadsLoadToUpdateIndex = openLoadsList.findIndex(load => load.id === loadId);
                                if (openLoadsLoadToUpdateIndex !== -1) {
                                    let loadToUpdate = { ...openLoadsList[openLoadsLoadToUpdateIndex], loadStatus: loadStatus };
                                    dispatch(updateLoadInLoadList('open', loadToUpdate));
                                }
                            }
                        } else if (loadStatus === 'SCHEDULED') {
                            const state2 = getState();
                            const loadsState2 = { ...state2.loads };
                            const loadLists = { ...loadsState2.lists };
                            const openLoadsList = selectListRecords(loadLists, 'open');
                            const bookedLoadsList = selectListRecords(loadLists, 'booked');

                            const bundledLoadsList = [...loadsState2.bundledLoads];
                            const bundledLoadsLoadToUpdateIndex = bundledLoadsList.findIndex(load => load.id === loadId);
                            if (bundledLoadsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = { ...bundledLoadsList[bundledLoadsLoadToUpdateIndex], loadStatus: loadStatus };
                                dispatch(changeBundledLoad(loadToUpdate));
                            }

                            const openLoadsLoadToUpdateIndex = openLoadsList.findIndex(load => load.id === loadId);
                            if (openLoadsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = { ...openLoadsList[openLoadsLoadToUpdateIndex], loadStatus: loadStatus };
                                dispatch(removeLoadFromLoadList('open', loadToUpdate));
                                dispatch(addLoadToLoadList('booked', loadToUpdate));
                            } else {
                                const bookedLoadsLoadToUpdateIndex = bookedLoadsList.findIndex(load => load.id === loadId);
                                if (bookedLoadsLoadToUpdateIndex !== -1) {
                                    let loadToUpdate = { ...bookedLoadsList[bookedLoadsLoadToUpdateIndex], loadStatus: loadStatus };
                                    dispatch(updateLoadInLoadList('booked', loadToUpdate));
                                }
                            }
                        } else if (loadStatus === 'IN_TRANSIT') {
                            const state2 = getState();
                            const loadsState2 = { ...state2.loads };
                            const loadLists = { ...loadsState2.lists };
                            const bookedLoadsList = selectListRecords(loadLists, 'booked');
                            const intransitLoadsList = selectListRecords(loadLists, 'intransit');

                            const bookedLoadsLoadToUpdateIndex = bookedLoadsList.findIndex(load => load.id === loadId);
                            if (bookedLoadsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = { ...bookedLoadsList[bookedLoadsLoadToUpdateIndex], loadStatus: loadStatus };
                                dispatch(removeLoadFromLoadList('booked', loadToUpdate));
                                dispatch(addLoadToLoadList('intransit', loadToUpdate));
                            } else {
                                const intransitLoadsLoadToUpdateIndex = intransitLoadsList.findIndex(load => load.id === loadId);
                                if (intransitLoadsLoadToUpdateIndex !== -1) {
                                    let loadToUpdate = { ...intransitLoadsList[intransitLoadsLoadToUpdateIndex], loadStatus: loadStatus };
                                    dispatch(updateLoadInLoadList('intransit', loadToUpdate));
                                }
                            }
                        } else if (loadStatus === 'AT_STOP') {
                            const state2 = getState();
                            const loadsState2 = { ...state2.loads };
                            const loadLists = { ...loadsState2.lists };
                            const intransitLoadsList = selectListRecords(loadLists, 'intransit');

                            const intransitLoadsLoadToUpdateIndex = intransitLoadsList.findIndex(load => load.id === loadId);
                            if (intransitLoadsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = { ...intransitLoadsList[intransitLoadsLoadToUpdateIndex], loadStatus: loadStatus };
                                dispatch(updateLoadInLoadList('intransit', loadToUpdate));
                            }
                        } else if (loadStatus === 'COMPLETED') {
                            const state2 = getState();
                            const loadsState2 = { ...state2.loads };
                            const loadLists = { ...loadsState2.lists };
                            const intransitLoadsList = selectListRecords(loadLists, 'intransit');
                            const completedLoadsList = selectListRecords(loadLists, 'completed');

                            const intransitLoadsLoadToUpdateIndex = intransitLoadsList.findIndex(load => load.id === loadId);
                            if (intransitLoadsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = { ...intransitLoadsList[intransitLoadsLoadToUpdateIndex], loadStatus: loadStatus };
                                dispatch(removeLoadFromLoadList('intransit', loadToUpdate));
                                dispatch(addLoadToLoadList('completed', loadToUpdate));
                            } else {
                                const completedLoadToUpdateIndex = completedLoadsList.findIndex(load => load.id === loadId);
                                if (completedLoadToUpdateIndex !== -1) {
                                    let loadToUpdate = { ...completedLoadsList[completedLoadToUpdateIndex], loadStatus: loadStatus };
                                    dispatch(updateLoadInLoadList('completed', loadToUpdate));
                                }
                            }
                        } else if (loadStatus === 'CLOSED' || loadStatus === 'REOPENED') {
                            const state2 = getState();
                            const loadsState2 = { ...state2.loads };
                            const loadLists = { ...loadsState2.lists };
                            const completedLoadsList = selectListRecords(loadLists, 'completed');

                            const completedLoadToUpdateIndex = completedLoadsList.findIndex(load => load.id === loadId);
                            if (completedLoadToUpdateIndex !== -1) {
                                let loadToUpdate = { ...completedLoadsList[completedLoadToUpdateIndex], loadStatus: loadStatus };
                                dispatch(updateLoadInLoadList('completed', loadToUpdate));
                            }
                        } else if (loadStatus === 'CANCELLED') {
                            const state2 = getState();
                            const loadsState2 = { ...state2.loads };
                            const loadLists = { ...loadsState2.lists };
                            const loadRequestsList = selectListRecords(loadLists, 'loadrequest');
                            const openLoadsList = selectListRecords(loadLists, 'open');
                            const bookedLoadsList = selectListRecords(loadLists, 'booked');
                            const intransitLoadsList = selectListRecords(loadLists, 'intransit');
                            const completedLoadsList = selectListRecords(loadLists, 'completed');

                            const loadRequestsLoadToUpdateIndex = loadRequestsList.findIndex(load => load.id === loadId);
                            if (loadRequestsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = { ...loadRequestsList[loadRequestsLoadToUpdateIndex], loadStatus: loadStatus };
                                dispatch(removeLoadFromLoadList('loadrequest', loadToUpdate));
                            }
                            const openLoadsLoadToUpdateIndex = openLoadsList.findIndex(load => load.id === loadId);
                            if (openLoadsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = { ...openLoadsList[openLoadsLoadToUpdateIndex], loadStatus: loadStatus };
                                dispatch(removeLoadFromLoadList('open', loadToUpdate));
                            }
                            const bookedLoadsLoadToUpdateIndex = bookedLoadsList.findIndex(load => load.id === loadId);
                            if (bookedLoadsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = { ...bookedLoadsList[bookedLoadsLoadToUpdateIndex], loadStatus: loadStatus };
                                dispatch(removeLoadFromLoadList('booked', loadToUpdate));
                            }
                            const intransitLoadsLoadToUpdateIndex = intransitLoadsList.findIndex(load => load.id === loadId);
                            if (intransitLoadsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = { ...intransitLoadsList[intransitLoadsLoadToUpdateIndex], loadStatus: loadStatus };
                                dispatch(removeLoadFromLoadList('intransit', loadToUpdate));
                            }
                            const completedLoadToUpdateIndex = completedLoadsList.findIndex(load => load.id === loadId);
                            if (completedLoadToUpdateIndex !== -1) {
                                let loadToUpdate = { ...completedLoadsList[completedLoadToUpdateIndex], loadStatus: loadStatus };
                                dispatch(updateLoadInLoadList('completed', loadToUpdate));
                            }
                        }
                    }

                    const state3 = getState();
                    const loadsState3 = { ...state3.loads };
                    // check if the load that is in the current state matches the loadId and if so, update the status of it
                    if (isObjectNotEmpty(loadsState3.record)) {
                        const oldLoad = { ...loadsState3.record };
                        if (oldLoad.id === loadId) {
                            if (isStringNotEmpty(stopStatus) && isStringNotEmpty(stopId)) {
                                const stopsState = { ...state3.loadStops };
                                const stops = selectListRecords({ ...stopsState.lists }, loadId);
                                const stopToUpdateIndex = stops.findIndex(stop => stop.id === stopId);
                                if (stopToUpdateIndex !== -1) {
                                    let updatedStop = { ...stops[stopToUpdateIndex], stopStatus: stopStatus };
                                    const updatedStops = [
                                        ...stops.slice(0, stopToUpdateIndex), // everything before current obj
                                        updatedStop,
                                        ...stops.slice(stopToUpdateIndex + 1), // everything after current obj
                                    ];

                                    let singleLoadToUpdate = { ...oldLoad, loadStatus: loadStatus };
                                    dispatch(changeSingleLoad(singleLoadToUpdate));
                                    dispatch(actionCreators.fetchLoadStopListSuccess(loadId, { records: updatedStops }));
                                } else {
                                    // can't find the stop so just update the load status - this should never happen but it's a fail safe
                                    let singleLoadToUpdate = { ...oldLoad, loadStatus: loadStatus };
                                    dispatch(changeSingleLoad(singleLoadToUpdate));
                                }
                            } else {
                                // no stop status to update so just update the load status
                                let singleLoadToUpdate = { ...oldLoad, loadStatus: loadStatus };
                                dispatch(changeSingleLoad(singleLoadToUpdate));
                            }
                        }
                    }
                } else if (isStringNotEmpty(stopStatus) && isStringNotEmpty(stopId)) {
                    const state3 = getState();
                    const loadsState3 = { ...state3.loads };
                    if (isObjectNotEmpty(loadsState3.record)) {
                        const oldLoad = { ...loadsState3.record };
                        if (oldLoad.id === loadId) {
                            const stopsState = { ...state3.loadStops };
                            const stops = selectListRecords({ ...stopsState.lists }, loadId);
                            const stopToUpdateIndex = stops.findIndex(stop => stop.id === stopId);
                            if (stopToUpdateIndex !== -1) {
                                let updatedStop = { ...stops[stopToUpdateIndex], stopStatus: stopStatus };
                                const updatedStops = [
                                    ...stops.slice(0, stopToUpdateIndex), // everything before current obj
                                    updatedStop,
                                    ...stops.slice(stopToUpdateIndex + 1), // everything after current obj
                                ];

                                // update just the stop status and not the load status
                                dispatch(actionCreators.fetchLoadStopListSuccess(loadId, { records: updatedStops }));
                            }
                        }
                    }
                }
            }

            dispatch(updateLoadSuccess());
            dispatch(updateLoadLoadingClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateLoadFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion
//#region Bundled Load Methods

export const fetchBundledLoadsWithIncludes = (parentLoadId, carrierId) => {
    return async (dispatch) => {
        try {
            dispatch(fetchBundledLoadsStart());

            const bundledLoads = await Data.getBundledLoadsWithIncludes(parentLoadId, carrierId);
            dispatch(fetchBundledLoadsSuccess({ bundledLoads: bundledLoads }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchBundledLoadsFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const addMissingChildLoads = (id) => {
    return async (dispatch) => {
        try {
            dispatch(addLoadStart());

            const res = await Data.addMissingChildLoads(id);
            if (res === true) {
                dispatch(addLoadSuccess());
                dispatch(addLoadLoadingClear());
                dispatch(addLoadErrorClear());
            } else {
                throw new Error(`Failed to create all the loads for this load bundle. Please contact Customer Support for assistance.`);
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addLoadFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateBundledLoad = (id, payload, load) => {
    return async (dispatch) => {
        try {
            dispatch(updateLoadStart());

            const updatedLoad = await Data.updateBundledLoad(id, payload, load);
            if (isObjectNotEmpty(updatedLoad)) {
                dispatch(actionCreators.loadEventUpdateTimeout(updatedLoad.id, null, null, null, 'LOAD_UPDATED'));
                dispatch(changeBundledLoad(updatedLoad));
            }

            dispatch(updateLoadSuccess());
            dispatch(updateLoadLoadingClear());
            dispatch(updateLoadErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateLoadFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const dispatchBundledLoad = (load) => {
    return async (dispatch) => {
        try {
            dispatch(updateLoadStart());

            let updatedLoad = await Data.dispatchBundledLoad(load);
            if (isObjectNotEmpty(updatedLoad)) {
                dispatch(actionCreators.loadEventUpdateTimeout(updatedLoad.id, null, null, null, 'LOAD_SCHEDULED'));
                dispatch(changeBundledLoad(updatedLoad));
            }

            dispatch(updateLoadSuccess());
            dispatch(updateLoadLoadingClear());
            dispatch(updateLoadErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateLoadFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const deleteBundledLoad = (id, load, parentLoadId, parentLoad) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updateLoadStart());

            const state = getState();
            const loadsState = { ...state.loads };
            const existingBundledLoads = isListNotEmpty(loadsState.bundledLoads) ? [...loadsState.bundledLoads] : [];

            const updatedBundledLoads = await Data.removeBundledLoad(id, load, parentLoadId, parentLoad, existingBundledLoads);
            if (isListNotEmpty(updatedBundledLoads)) {
                dispatch(fetchBundledLoadsSuccess({ bundledLoads: updatedBundledLoads }));
            }

            dispatch(updateLoadSuccess());
            dispatch(updateLoadLoadingClear());
            dispatch(updateLoadErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateLoadFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const removeDriverFromBundledLoad = (id, payload, load, accountUser) => {
    return async (dispatch, getState) => {
        try {
            if (isObjectNotEmpty(load) && isObjectNotEmpty(accountUser)) {
                dispatch(updateLoadStart());

                await dispatch(actionCreators.getAccounts());
                const state = getState();
                const orchestratorState = { ...state.orchestrator };
                const accounts = [...orchestratorState.accounts];

                const updatedLoad = await Data.removeDriverFromBundledLoad(id, payload, load, accountUser, accounts);
                if (isObjectNotEmpty(updatedLoad)) {
                    if (isStringNotEmpty(load.loadStatus) && (load.loadStatus !== "PENDING" && load.loadStatus !== "APPROVED" && load.loadStatus !== "PENDING_RATE_CONFIRMATION" && load.loadStatus !== "BOOKED")) {
                        dispatch(actionCreators.loadEventUpdateTimeout(load.id, null, null, null, 'LOAD_BOOKED'));
                    }

                    dispatch(changeBundledLoad(updatedLoad));
                }

                dispatch(updateLoadSuccess());
                dispatch(updateLoadLoadingClear());
                dispatch(updateLoadErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateLoadFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion