import { isListNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import EntityUtils from '../entityUtils';

export const getCarrierRejectionDTO = (carrierRejection, accounts = []) => {
    // Get Carrier Info
    if (isListNotEmpty(accounts) && isStringNotEmpty(carrierRejection.rejectedByCarrierId)) {
        carrierRejection.rejectedByCarrier = EntityUtils.getAccountInfo(carrierRejection.rejectedByCarrierId, accounts);
    }

    return carrierRejection;
};