import * as actionTypes from "../actions/actionTypes";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { isBooleanTrue, isObjectNotEmpty } from "../../shared/objectUtils";
import * as actionCreators from "./index";
import ErrorUtils from "../../shared/errorUtils";

//#region Fetch Line Item Types Methods

export const fetchLineItemTypesStart = () => {
    return {
        type: actionTypes.FETCH_LINE_ITEM_TYPES_START
    }
};

export const fetchLineItemTypesSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_LINE_ITEM_TYPES_SUCCESS,
        payload: payload
    }
};

export const fetchLineItemTypesFail = (payload) => {
    return {
        type: actionTypes.FETCH_LINE_ITEM_TYPES_FAIL,
        payload: payload
    }
};

export const clearLineItemTypes = () => {
    return {
        type: actionTypes.CLEAR_LINE_ITEM_TYPES
    }
};

//#endregion
//#region Add Line Item Type Methods

const insertLineItemType = (payload) => {
    return {
        type: actionTypes.ADD_LINE_ITEM_TYPE,
        payload: payload
    }
};

export const addLineItemTypeStart = () => {
    return {
        type: actionTypes.ADD_LINE_ITEM_TYPE_START
    }
};

export const addLineItemTypeSuccess = () => {
    return {
        type: actionTypes.ADD_LINE_ITEM_TYPE_SUCCESS
    }
};

export const addLineItemTypeFail = (payload) => {
    return {
        type: actionTypes.ADD_LINE_ITEM_TYPE_FAIL,
        payload: payload
    }
};

export const addLineItemTypeLoadingClear = () => {
    return {
        type: actionTypes.ADD_LINE_ITEM_TYPE_LOADING_CLEAR
    }
};

export const addLineItemTypeErrorClear = () => {
    return {
        type: actionTypes.ADD_LINE_ITEM_TYPE_ERROR_CLEAR
    }
};

export const addLineItemTypeCancel = () => {
    return {
        type: actionTypes.ADD_LINE_ITEM_TYPE_CANCEL
    }
};

//#endregion
//#region Update Line Item Type Methods

const changeLineItemType = (payload) => {
    return {
        type: actionTypes.UPDATE_LINE_ITEM_TYPE,
        payload: payload
    }
};

const changeSingleLineItemType = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_LINE_ITEM_TYPE,
        payload: payload
    }
};

export const updateLineItemTypeStart = () => {
    return {
        type: actionTypes.UPDATE_LINE_ITEM_TYPE_START
    }
};

export const updateLineItemTypeSuccess = () => {
    return {
        type: actionTypes.UPDATE_LINE_ITEM_TYPE_SUCCESS
    }
};

export const updateLineItemTypeFail = (payload) => {
    return {
        type: actionTypes.UPDATE_LINE_ITEM_TYPE_FAIL,
        payload: payload
    }
};

export const updateLineItemTypeLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_LINE_ITEM_TYPE_LOADING_CLEAR
    }
};

export const updateLineItemTypeErrorClear = () => {
    return {
        type: actionTypes.UPDATE_LINE_ITEM_TYPE_ERROR_CLEAR
    }
};

export const updateLineItemTypeCancel = () => {
    return {
        type: actionTypes.UPDATE_LINE_ITEM_TYPE_CANCEL
    }
};

//#endregion
//#region Remove Line Item Type Methods

const removeLineItemType = (payload) => {
    return {
        type: actionTypes.REMOVE_LINE_ITEM_TYPE,
        payload: payload
    }
};

//#endregion
//#region Line Item Types Methods

export const fetchLineItemTypes = (payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(fetchLineItemTypesStart());

            await dispatch(actionCreators.getTrailerTypes());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const trailerTypes = [...orchestratorState.trailerTypes];
            const lineItemTypesState = { ...state.lineItemTypes };
            let pagination = { ...lineItemTypesState.pagination };
            let searchParams = { ...lineItemTypesState.searchParams };

            if (isObjectNotEmpty(payload)) {
                searchParams = { ...payload };
            }

            const res = await Data.getLineItemTypes({ ...searchParams }, pagination, trailerTypes);
            dispatch(fetchLineItemTypesSuccess({ records: res.data, searchParams: res.searchParams, pagination: res.pagination }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchLineItemTypesFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const addLineItemType = (payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(addLineItemTypeStart());

            await dispatch(actionCreators.getTrailerTypes());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const trailerTypes = [...orchestratorState.trailerTypes];

            const newLineItemType = await Data.addLineItemType(payload, trailerTypes);
            if (isObjectNotEmpty(newLineItemType)) {
                dispatch(insertLineItemType(newLineItemType));

                // refresh lineItemTypes orchestrator
                dispatch(actionCreators.getLineItemTypes(true));
            }

            dispatch(addLineItemTypeSuccess());
            dispatch(addLineItemTypeLoadingClear());
            dispatch(addLineItemTypeErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addLineItemTypeFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateLineItemType = (id, payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updateLineItemTypeStart());

            if (isBooleanTrue(payload.isDeleted)) {
                const removedLineItemType = await Data.removeLineItemType(id);
                if (isObjectNotEmpty(removedLineItemType)) {
                    dispatch(removeLineItemType(removedLineItemType));

                    // refresh lineItemTypes orchestrator
                    dispatch(actionCreators.getLineItemTypes(true));
                }
            } else {
                await dispatch(actionCreators.getTrailerTypes());
                const state = getState();
                const orchestratorState = { ...state.orchestrator };
                const trailerTypes = [...orchestratorState.trailerTypes];

                const updatedLineItemType = await Data.updateLineItemType(id, payload, trailerTypes);
                if (isObjectNotEmpty(updatedLineItemType)) {
                    dispatch(changeLineItemType(updatedLineItemType));
                    dispatch(changeSingleLineItemType(updatedLineItemType));

                    // refresh lineItemTypes orchestrator
                    dispatch(actionCreators.getLineItemTypes(true));
                }
            }

            dispatch(updateLineItemTypeSuccess());
            dispatch(updateLineItemTypeLoadingClear());
            dispatch(updateLineItemTypeErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateLineItemTypeFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion