import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { DownOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Button, Modal, Drawer, Dropdown, Menu } from 'antd';
import classes from './Carriers.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools, faUsers } from '@fortawesome/free-solid-svg-icons';
import DataTable from "../../components/DataTable/DataTable";
import StringFormatter from '../../shared/stringFormatter';
import DocumentList from '../../components/DocumentList/DocumentList';
import EditAccount from "../../components/EditAccount/EditAccount";
import ColumnSearchFilter from '../../shared/tableColumnSearchFilter';
import { isBooleanFalse, isListNotEmpty, isNotNullOrUndefined, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { Can, CanDo, CanSee } from '../../shared/entitlements/entitlements';
import { selectListIsLoading, selectListPagination, selectListRecords } from '../../store/utility';
import { compareByAlph } from '../../shared/tableUtils';

const stringFormatter = new StringFormatter();
const columnSearchFilter = new ColumnSearchFilter();

const Carriers = (props) => {
    //#region constants

    const filter = "CARRIERS";
    const fullWidth = global.window.innerWidth;
    const singularEntityName = "Carrier";
    const pluralEntityName = "Carriers";

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const records = useSelector(state => selectListRecords(state.accounts.lists, filter));
    const isLoading = useSelector(state => selectListIsLoading(state.accounts.lists, filter));
    const pagination = useSelector(state => selectListPagination(state.accounts.lists, filter));
    const isLoadingUpdateRecord = useSelector(state => state.accounts.isRecordUpdateLoading);
    const updateRecordError = useSelector(state => state.accounts.updateRecordError);

    //#endregion
    //#region useStates

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showDocumentsModal, setShowDocumentsModal] = useState(false);
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [missingDocumentsCount, setMissingDocumentsCount] = useState(0);
    const [pendingApprovalCount, setPendingApprovalCount] = useState(0);
    const [expiringSoonDocumentsCount, setExpiringSoonDocumentsCount] = useState(0);
    const [expiredDocumentsCount, setExpiredDocumentsCount] = useState(0);
    const [searchText, setSearchText] = useState({ name: '', irisId: '' });
    const [isVerifyingAccount, setIsVerifyingAccount] = useState(false);

    //#endregion
    //#region toggles

    const toggleDocumentsModal = () => {
        setShowDocumentsModal(!showDocumentsModal);
    };

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    //#endregion
    //#region account methods

    const verifyAccount = (accountId) => {
        dispatch(actionCreators.verifyAccount(accountId));
        setIsVerifyingAccount(true);
    };

    const finishedVerifyAccount = () => {
        dispatch(actionCreators.updateAccountCancel());
        toggleDocumentsModal();
        setIsVerifyingAccount(false);
    };

    //#endregion
    //#region goTos

    const goToCarrierDashboard = (id) => {
        props.history.push({ pathname: `/accounts/${id}` }, {
            previousPageTitle: "Carriers",
            previousPageLocation: props.location,
            previousBreadcrumbs: props.breadcrumbs,
        });
    };

    const goToNewAccount = () => {
        props.history.push({
            pathname: "/newAccount",
            state: {
                accountTypes: ['CARRIER'],
                accountRegistrationId: null
            }
        });
    };

    //#endregion
    //#region table methods

    const refreshTable = () => {
        let searchParams = {
            page: 1,
            size: 5,
            sort: 'name',
            order: 'asc',
            entityTypes: "CARRIER",
            isDeleted: false
        };

        dispatch(actionCreators.fetchAccountList(filter, searchParams));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchAccountListSuccess(filter, { params: { pagination: pager } }));

        let searchParams = {
            page: pagination.current,
            size: pagination.pageSize,
            entityTypes: "CARRIER",
            isDeleted: false
        };

        if (isStringNotEmpty(sorter.order)) {
            const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
            searchParams.order = sortOrder;
        }
        if (isStringNotEmpty(sorter.field)) {
            searchParams.sort = sorter.field;
        }

        if (isNotNullOrUndefined(filters)) {
            if (isListNotEmpty(filters.name)) {
                searchParams['name:contains'] = filters.name[0];
            }
            if (isListNotEmpty(filters.irisId)) {
                searchParams['irisId:contains'] = filters.irisId[0];
            }
        }

        dispatch(actionCreators.fetchAccountList(filter, searchParams));
    };

    //#endregion
    //#region table displays

    const setRowClassName = (record) => {
        return record.isVerified === undefined || record.isVerified === null || record.isVerified === false || record.documentsPendingApprovalCount > 0 ? classes.unverifiedRow : classes.dataTableRow;
    };

    const menu = (record) => {
        return (
            <Menu>
                {CanDo({ entityAction: 'UPDATE', entityModel: 'ACCOUNT', entityObject: record }) &&
                    <Menu.Item key="manageDocuments" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); toggleDocumentsModal(); }}>
                        <PaperClipOutlined style={{ marginRight: 8 }} />
                        <span>Manage Documents</span>
                    </Menu.Item>
                }
                {CanDo({ entityAction: 'UPDATE', entityModel: 'ACCOUNT', entityObject: record }) &&
                    <Menu.Item key="editAccount" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); toggleEditEntity(); }}>
                        <FontAwesomeIcon className="anticon" icon={faUsers} style={{ marginRight: 8 }} />
                        <span>Edit Account Details</span>
                    </Menu.Item>
                }
                {CanDo({ entityAction: 'READ', entityModel: 'CARRIER', entityObject: record }) &&
                    <Menu.Item key="goToDashboard" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); goToCarrierDashboard(record.id); }}>
                        <span>Go to {singularEntityName} Dashboard</span>
                    </Menu.Item>
                }
            </Menu>
        );
    };

    const columns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            width: 50,
            render: (text, record) => {
                return (
                    <Dropdown overlay={menu(record)} trigger={['click']}>
                        <Button>Actions <DownOutlined /></Button>
                    </Dropdown>
                );
            },
            align: 'center',
            className: classes.columnNoBreak
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => compareByAlph(a.name, b.name),
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'ascend',
            ...columnSearchFilter.getColumnSearchProps('name', 'Name', searchText, setSearchText)
        },
        {
            title: 'Account Id',
            dataIndex: 'irisId',
            key: 'irisId',
            ...columnSearchFilter.getColumnSearchProps('irisId', 'Account Id', searchText, setSearchText)
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Active',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        },
        {
            title: 'Fax',
            dataIndex: 'fax',
            key: 'fax',
            render: (text, record) => { return stringFormatter.toFormattedString("PhoneNumber", text, null); },
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            render: (text, record) => { return stringFormatter.toFormattedString("PhoneNumber", text, null); },
        },
        {
            title: 'MC #',
            dataIndex: 'mcNum',
            key: 'mcNum',
        },
        {
            title: 'Dot #',
            dataIndex: 'dotNum',
            key: 'dotNum',
        },
        {
            title: 'SMS Basic Avg. Score',
            dataIndex: ['smsBasic', 'weightedAvgSMSScore'],
            key: 'smsBasic.weightedAvgSMSScore',
        },
        {
            title: 'Is Verified',
            dataIndex: 'isVerified',
            key: 'isVerified',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        },
        {
            title: 'Documents Pending Approval',
            dataIndex: 'documentsPendingApprovalCount',
            key: 'documentsPendingApprovalCount',
        },
        {
            title: 'Missing Documents',
            dataIndex: 'documentsMissingCount',
            key: 'documentsMissingCount',
        }
    ];

    //#endregion
    //#region displays

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName + " Details"}
            onClose={toggleEditEntity}
            visible={showEditEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditAccount
                cancel={toggleEditEntity}
                account={selectedRecord}
            />
        </Drawer>
    );

    const documentsComponent = (
        <Modal
            title="Account Documents"
            visible={showDocumentsModal === true}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleDocumentsModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            {isObjectNotEmpty(selectedRecord) ? (
                <DocumentList
                    documentEntityType="ACCOUNT"
                    account={selectedRecord}
                    documentEntityId={selectedRecord.id}
                    updatePendingApprovalCount={setPendingApprovalCount}
                    updateMissingDocumentsCount={setMissingDocumentsCount}
                    updateExpiringSoonDocumentsCount={setExpiringSoonDocumentsCount}
                    updateExpiredDocumentsCount={setExpiredDocumentsCount}
                />
            ) : null}
            {isObjectNotEmpty(selectedRecord) && isBooleanFalse(selectedRecord.isVerified) ? (
                <CanSee entityAction="VERIFY" entityModel="ACCOUNT" entityObject={selectedRecord}>
                    <Button style={{ marginTop: 20 }} type='primary' onClick={() => { verifyAccount(selectedRecord.id); }} disabled={pendingApprovalCount > 0 || missingDocumentsCount > 0 || expiredDocumentsCount > 0}>Verify Account</Button>
                </CanSee>
            ) : null}
        </Modal>
    );

    //#endregion
    //#region useEffects and useMemos

    useEffect(() => {
        refreshTable();
    }, []);

    useMemo(() => {
        if (isLoadingUpdateRecord !== null && isLoadingUpdateRecord === false && updateRecordError === null && isVerifyingAccount === true) {
            finishedVerifyAccount();
        }
    }, [isLoadingUpdateRecord, updateRecordError, isVerifyingAccount]);

    //#endregion

    return (
        <Can entityAction="READ_LIST" entityModel="CARRIER">
            <DataTable
                dataSource={records}
                columns={columns}
                pagination={pagination}
                onChange={handleTableChange}
                loading={isLoading === true}
                rowClassName={setRowClassName}
                singularEntityName={singularEntityName}
                pluralEntityName={pluralEntityName}
                newEntityAction={goToNewAccount}
                rowKey={record => record.id}
                style={{ backgroundColor: '#ffffff' }}
            >
                {documentsComponent}
                {editEntityComponents}
            </DataTable>
        </Can>
    );
};

export default withRouter(Carriers);