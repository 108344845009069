import React, { useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { Empty, List, Drawer, Avatar, Tooltip, Button, Alert } from "antd";
import classes from './MessagesList.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPallet, faEnvelopeOpen, faCommentAlt } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import MomentDate from '../../shared/dateFormatter';
import InfiniteScroll from 'react-infinite-scroller';
import { isObjectEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { selectListIsLoading, selectListPagination, selectListRecords } from '../../store/utility';

const momentDate = new MomentDate();

const MessagesList = ({ onClose, visible = false, ...props }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    //#endregion
    //#region useRefs

    const scrollParentRef = useRef(null);

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const userId = useSelector(state => state.auth.userId);
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);
    const messageLists = useSelector(state => state.messages.lists);
    const messages = useSelector(state => selectListRecords(state.messages.lists, "ALL"));
    const isLoading = useSelector(state => selectListIsLoading(state.messages.lists, "ALL"));
    const pagination = useSelector(state => selectListPagination(state.messages.lists, "ALL"));
    const error = useSelector(state => state.messages.addRecordError);

    //#endregion
    //#region goTos

    const goToLoad = (loadId) => {
        props.history.push({ pathname: "/loads/" + loadId, hash: 'messages' });
        onClose();
    };

    //#endregion
    //#region message methods

    const markRead = (id) => {
        dispatch(actionCreators.readMessage(id, userId));
    };

    // const markAllRead = () => {
    //     //console.log('mark all read');

    //     let unreadMessages = messages.filter(n => {
    //         return !n.read.some(r => { return r.readBy === userId; });
    //     });

    //     //console.log(unreadMessages);

    //     let unreadMessageIds = unreadMessages.map(item => {
    //         return item.id;
    //     });

    //     dispatch(actionCreators.readMultipleMessages(unreadMessageIds, userId));
    // };

    const viewAll = () => {
        props.history.push({ pathname: "/messages" });
        onClose();
    };

    const refreshList = () => {
        if (isStringNotEmpty(entityType) && isStringNotEmpty(entityId) && isStringNotEmpty(userId)) {
            let searchParams = {
                page: 1,
                size: 10,
                sort: 'createdAt',
                order: 'desc'
            };

            if (entityType === "STAFF") {
                //searchParams.toStaff = true;
            } else if (entityType === "SHIPPER" || entityType === "PRODUCER" || entityType === "RECEIVER" || entityType === "BROKER" || entityType === "FACTORING") {
                searchParams.accountIds = entityId;
            } else if (entityType === "CARRIER") {
                searchParams['accountIds:or'] = entityId;
                searchParams['userIds:or'] = userId;
            } else if (entityType === "DRIVER" || entityType === "NONE") {
                searchParams.userIds = userId;
            }

            dispatch(actionCreators.fetchMessageList("ALL", searchParams));
        }
    };

    const handleInfiniteOnLoad = () => {
        // console.log(`Current Page: ${pagination.current}, Total Pages: ${pagination.totalPages}`);
        if (pagination.current + 1 <= pagination.totalPages && isLoading === false && isStringNotEmpty(entityType) && isStringNotEmpty(entityId) && isStringNotEmpty(userId)) {
            // console.log('get more');
            let searchParams = {
                page: pagination.current + 1,
                size: 10,
                sort: 'createdAt',
                order: 'desc'
            };

            if (entityType === "STAFF") {
                //searchParams.toStaff = true;
            } else if (entityType === "SHIPPER" || entityType === "PRODUCER" || entityType === "RECEIVER" || entityType === "BROKER" || entityType === "FACTORING") {
                searchParams.accountIds = entityId;
            } else if (entityType === "CARRIER") {
                searchParams['accountIds:or'] = entityId;
                searchParams['userIds:or'] = userId;
            } else if (entityType === "DRIVER" || entityType === "NONE") {
                searchParams.userIds = userId;
            }

            dispatch(actionCreators.fetchMoreMessageList("ALL", searchParams));
        } else {
            // console.log('no more items to get');
        }
    };

    //#endregion
    //#region useEffects

    useEffect(() => {
        if (isStringNotEmpty(entityType) && isStringNotEmpty(entityId) && isStringNotEmpty(userId)) {
            if (isObjectEmpty(messageLists) || (isObjectNotEmpty(messageLists) && isObjectEmpty(messageLists["ALL"]))) {
                refreshList();
            }
        }
    }, [messageLists, entityType, entityId, userId]);

    //#endregion

    return (
        <Drawer
            title={'Messages'}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={onClose}
            visible={visible === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            bodyStyle={{ overflow: 'hidden' }}
            drawerStyle={{ overflow: 'hidden' }}
            destroyOnClose={true}
            closable={true}
            maskClosable={true}
        >
            {(messages.length === 0) ? (
                <>
                    <Empty
                        imageStyle={{
                            height: 60,
                        }}
                        description={
                            <span>No New or Unread Messages</span>
                        }
                    >
                        <Button type="primary" onClick={(e) => { e.stopPropagation(); viewAll(); }}>View All</Button>
                    </Empty>
                    <div className={classes.bottomBar}>
                        <div onClick={(e) => { e.stopPropagation(); viewAll(); }}>View All</div>
                    </div>
                </>
            ) : null}
            {(messages.length > 0) ? (
                <>
                    {error && <Alert message={`${error}`} type="error" />}

                    <div className={classes.infiniteScrollContainer} ref={(ref) => scrollParentRef.current = ref}>
                        <InfiniteScroll
                            initialLoad={false}
                            pageStart={0}
                            loadMore={handleInfiniteOnLoad}
                            hasMore={isLoading === false && pagination.current + 1 <= pagination.totalPages}
                            useWindow={false}
                            getScrollParent={() => scrollParentRef.current}
                        >
                            <List
                                itemLayout="vertical"
                                dataSource={messages}
                                size="small"
                                loading={isLoading === true}
                                renderItem={(item, i) => {

                                    const itemClass = item.read.some(r => { return r.readBy === userId; }) ? classes.read : '';
                                    let leftIcon = <Avatar style={{ backgroundColor: 'gray', color: '#00000075' }}><FontAwesomeIcon icon={faCommentAlt} size="lg" /></Avatar>;

                                    let actionButtons = [];
                                    if (!item.read.some(r => { return r.readBy === userId; })) {
                                        actionButtons.push(<Tooltip placement="top" title={'Mark Read'}><Button key="1" type="link" onClick={(e) => { e.stopPropagation(); markRead(item.id); }} style={null}><FontAwesomeIcon icon={faEnvelopeOpen} size="sm" /> <span>&nbsp;&nbsp;Mark Read</span></Button></Tooltip>);
                                    }
                                    actionButtons.push(<Tooltip placement="top" title={'Go To Load'}><Button key="2" type="link" onClick={(e) => { e.stopPropagation(); goToLoad(item.loadId); }} style={null}><FontAwesomeIcon icon={faPallet} size="sm" /> <span>{`  Go To Load ${item.loadIrisId || ''}`}</span></Button></Tooltip>);

                                    return (
                                        <List.Item
                                            className={classes.item + ' ' + itemClass}
                                            key={item.id}
                                            actions={actionButtons}>
                                            <List.Item.Meta
                                                avatar={leftIcon}
                                                className={classes.meta}
                                                title={
                                                    <>
                                                        <div className={classes.title}>{`${item.senderFirstName} ${item.senderLastName}${item.loadIrisId ? ' to ' + item.loadIrisId : ''}:`}</div>
                                                    </>
                                                }
                                                description={
                                                    <>
                                                        <div className={classes.description}>
                                                            <div className={classes.description}>{item.message}</div>
                                                            <div className={classes.dateTime}>{momentDate.fromUtcToDeviceTimeZoneForMessages(item.createdAt)}</div>
                                                        </div>
                                                    </>
                                                }
                                            />
                                        </List.Item>
                                    );
                                }}
                            />
                        </InfiniteScroll>
                    </div>
                    <div className={classes.bottomBar}>
                        {/* <div onClick={(e) => { e.stopPropagation(); markAllRead(); }}>Mark All Read</div> */}
                        <div onClick={(e) => { e.stopPropagation(); viewAll(); }}>View All</div>
                    </div>
                </>
            ) : null}
        </Drawer>
    );
};

export default withRouter(MessagesList);