import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { Drawer, Menu, Dropdown, Button, Table } from 'antd';
import EditLocation from "../../components/EditLocation/EditLocation";
import NewLocation from "../../components/NewLocation/NewLocation";
import classes from './Locations.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarked, faTools } from '@fortawesome/free-solid-svg-icons';
import DataTable from '../../components/DataTable/DataTable';
import StringFormatter from '../../shared/stringFormatter';
import { withRouter } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import { isListNotEmpty, isStringEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { CanDo } from '../../shared/entitlements/entitlements';
import { selectListIsLoading, selectListPagination, selectListRecords } from '../../store/utility';
import { compareByAlph } from '../../shared/tableUtils';
import ColumnSearchFilter from '../../shared/tableColumnSearchFilter';

const stringFormatter = new StringFormatter();
const columnSearchFilter = new ColumnSearchFilter();

const Locations = ({ accountId, linkedAccountId, linkedAccountEntityType, ...props }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;
    const singularEntityName = "Location";
    const pluralEntityName = "Locations";

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);
    const allLocations = useSelector(state => selectListRecords(state.locations.lists, `${isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')}_${isStringNotEmpty(linkedAccountId) ? linkedAccountId : 'ALL'}`));
    const locations = useSelector(state => selectListRecords(state.orchestrator.locations, isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : null)));
    const isLoading = useSelector(state => selectListIsLoading(state.locations.lists, `${isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')}_${isStringNotEmpty(linkedAccountId) ? linkedAccountId : 'ALL'}`));
    const pagination = useSelector(state => selectListPagination(state.locations.lists, `${isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')}_${isStringNotEmpty(linkedAccountId) ? linkedAccountId : 'ALL'}`));

    //#endregion
    //#region useStates

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [searchText, setSearchText] = useState({ name: '' });

    //#endregion
    //#region toggles

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    //#endregion
    //#region goTos

    const goToLocation = (id) => {
        props.history.push({ pathname: `/locations/${id}` }, {
            previousPageTitle: "Locations",
            previousPageLocation: props.location,
            previousBreadcrumbs: props.breadcrumbs,
        });
    };

    //#endregion
    //#region table methods

    const refreshTable = () => {
        let searchParams = {
            page: 1,
            size: 1000000,
            isDeleted: false
        };

        if (isStringNotEmpty(accountId) || (isStringNotEmpty(entityId) && entityType !== 'STAFF')) {
            let linkSearchParams = {
                accountId: isStringNotEmpty(accountId) ? accountId : (isStringNotEmpty(entityId) && entityType !== 'STAFF' ? entityId : null)
            };

            if (isStringNotEmpty(linkedAccountId)) {
                linkSearchParams.linkedAccountId = linkedAccountId;
            }

            searchParams.links = { '$elemMatch': linkSearchParams };
        }

        dispatch(actionCreators.fetchLocationList(`${isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')}_${isStringNotEmpty(linkedAccountId) ? linkedAccountId : 'ALL'}`, searchParams, isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : null)));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchLocationListSuccess(`${isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')}_${isStringNotEmpty(linkedAccountId) ? linkedAccountId : 'ALL'}`, { params: { pagination: pager } }));

        let searchParams = {
            page: pagination.current,
            size: pagination.pageSize,
            isDeleted: false
        };

        if (isStringNotEmpty(sorter.order)) {
            const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
            searchParams.order = sortOrder;
        }
        if (isStringNotEmpty(sorter.field)) {
            searchParams.sort = sorter.field;
        }

        if (isStringNotEmpty(accountId) || (isStringNotEmpty(entityId) && entityType !== 'STAFF')) {
            let linkSearchParams = {
                accountId: isStringNotEmpty(accountId) ? accountId : (isStringNotEmpty(entityId) && entityType !== 'STAFF' ? entityId : null)
            };

            if (isStringNotEmpty(linkedAccountId)) {
                linkSearchParams.linkedAccountId = linkedAccountId;
            }

            searchParams.links = { '$elemMatch': linkSearchParams };
        }

        dispatch(actionCreators.fetchLocationList(`${isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : 'ALL')}_${isStringNotEmpty(linkedAccountId) ? linkedAccountId : 'ALL'}`, searchParams, isStringNotEmpty(accountId) ? accountId : ((isStringNotEmpty(entityId) && entityType !== 'STAFF') ? entityId : null)));
    };

    //#endregion
    //#region table displays

    const menu = (record) => {
        return (
            <Menu>
                {CanDo({ entityAction: 'UPDATE', entityModel: 'LOCATION', accountId: record.accountId }) && (
                    <Menu.Item key="editEntity" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); toggleEditEntity(); }}>
                        <FontAwesomeIcon className="anticon" icon={faMapMarked} style={{ marginRight: 8 }} />
                        <span>Edit {singularEntityName} Details</span>
                    </Menu.Item>
                )}
                {CanDo({ entityAction: 'READ', entityModel: 'LOCATION', accountId: record.accountId }) && (
                    <Menu.Item key="goToDashboard" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); goToLocation(record.id); }}>
                        <span>Go to {singularEntityName} Dashboard</span>
                    </Menu.Item>
                )}
            </Menu>
        );
    };

    const columns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            className: classes.columnNoBreak,
            width: 50,
            render: (text, record) => {
                return (
                    <Dropdown overlay={menu(record)} trigger={['click']}>
                        <Button>Actions <DownOutlined /></Button>
                    </Dropdown>
                );
            },
            align: 'center',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => compareByAlph(a.name, b.name),
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'ascend',
            ...columnSearchFilter.getColumnSearchProps('name', 'Name', searchText, setSearchText)
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            render: (text, record) => { return stringFormatter.toFormattedAddress(text); },
        },
        {
            title: 'Location Type',
            dataIndex: 'locationTypes',
            key: 'locationTypes',
            render: (text, record) => {
                if (isListNotEmpty(text)) {
                    return text.map(item => stringFormatter.toFormattedString("LocationType", item, null)).join(', ');
                } else {
                    return '';
                }
            },
        },
        {
            title: 'Appointment Type',
            dataIndex: 'appointmentType',
            key: 'appointmentType',
            render: (text, record) => { return stringFormatter.toFormattedString("AppointmentType", text, null); },
        }
    ];

    if (isStringEmpty(accountId)) {
        columns.push({
            title: 'Account',
            dataIndex: ['account', 'name'],
            key: 'account.name',
        });
    }

    if (isStringEmpty(linkedAccountId)) {
        columns.push({
            title: 'Linked Account',
            dataIndex: ['linkedAccount', 'name'],
            key: 'linkedAccount.name',
        });
    }

    //#endregion
    //#region displays

    const newEntityComponents = (
        <Drawer
            title={"New " + singularEntityName}
            onClose={toggleNewEntity}
            visible={showNewEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewLocation
                cancel={toggleNewEntity}
                accountId={isStringNotEmpty(accountId) ? accountId : (isStringNotEmpty(entityId) && entityType !== 'STAFF' ? entityId : null)}
                linkedAccountId={isStringNotEmpty(linkedAccountId) ? linkedAccountId : null}
                linkedAccountEntityType={isStringNotEmpty(linkedAccountEntityType) ? linkedAccountEntityType : null}
            />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName}
            onClose={toggleEditEntity}
            visible={showEditEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditLocation
                cancel={toggleEditEntity}
                location={selectedRecord}
            />
        </Drawer>
    );

    //#endregion
    //#region useMemos

    useMemo(() => {
        // console.log(accountId)
        // console.log(entityId)
        // console.log(linkedAccountId)
        if (isStringNotEmpty(accountId) || (isStringNotEmpty(entityId) && entityType !== 'STAFF')) {
            dispatch(actionCreators.getLocations(isStringNotEmpty(accountId) ? accountId : (isStringNotEmpty(entityId) && entityType !== 'STAFF' ? entityId : null), true));
        } else {
            refreshTable();
        }
    }, [accountId, entityId, linkedAccountId]);

    //#endregion

    if (isStringNotEmpty(accountId) || (isStringNotEmpty(entityId) && entityType !== 'STAFF')) {
        return (
            <DataTable
                bordered={false}
                dataSource={isStringNotEmpty(linkedAccountId) ? locations.filter(l => l.linkedAccountId === linkedAccountId) : locations}
                columns={columns}
                pageSize={10}
                loading={isLoading === true}
                rowClassName={classes.dataTableRow}
                singularEntityName={singularEntityName}
                pluralEntityName={pluralEntityName}
                newEntityAction={CanDo({ entityAction: 'CREATE', entityModel: 'LOCATION', accountId: isStringNotEmpty(accountId) ? accountId : (isStringNotEmpty(entityId) && entityType !== 'STAFF' ? entityId : null) }) ? toggleNewEntity : null}
                rowKey={record => record.key}
                style={{ backgroundColor: '#ffffff' }}
            >
                {newEntityComponents}
                {editEntityComponents}
            </DataTable>
        );
    } else {
        return (
            <DataTable
                bordered={false}
                dataSource={allLocations}
                columns={columns}
                pageSize={10}
                loading={isLoading === true}
                rowClassName={classes.dataTableRow}
                singularEntityName={singularEntityName}
                pluralEntityName={pluralEntityName}
                newEntityAction={CanDo({ entityAction: 'CREATE', entityModel: 'LOCATION', accountId: isStringNotEmpty(accountId) ? accountId : (isStringNotEmpty(entityId) && entityType !== 'STAFF' ? entityId : null) }) ? toggleNewEntity : null}
                rowKey={record => record.key}
                style={{ backgroundColor: '#ffffff' }}
            >
                {newEntityComponents}
                {editEntityComponents}
            </DataTable>
        );
    }
};

export default withRouter(Locations);