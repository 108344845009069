import axiosAuthenticated from "../../api/axiosAuthenticated";
import { isNotNullOrUndefined } from "../../shared/objectUtils";
import * as DTO from "../dtos/index";

const lanesPath = '/lanes';
const lanesBulkPath = '/lanes/bulk/get';

//#region Lanes Methods

export const getBulkLanes = async (searchParams = {}, trailerTypes = []) => {
    const lanesRes = await axiosAuthenticated.post(lanesBulkPath, { ...searchParams });
    if (lanesRes && lanesRes.status === 200) {
        const lanes = lanesRes.data.data;

        return lanes.map((lane) => { return DTO.getLaneDTO(lane, trailerTypes); });
    }

    return [];
};

export const getLanes = async (searchParams = {}, pagination = {}, trailerTypes = []) => {
    const lanesRes = await axiosAuthenticated.get(lanesPath, { params: { ...searchParams } });
    if (lanesRes && lanesRes.status === 200) {
        const lanes = lanesRes.data.data;
        const otherData = lanesRes.data;

        const transformedLanes = lanes.map((lane) => { return DTO.getLaneDTO(lane, trailerTypes); });

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;

        return { data: transformedLanes, searchParams: searchParams, pagination: pagination };
    }

    return { data: [], searchParams: searchParams, pagination: pagination };
};

export const getLane = async (id, trailerTypes = []) => {
    const lanesRes = await axiosAuthenticated.get(lanesPath + `/${id}`);
    if (lanesRes && lanesRes.status === 200) {
        let lane = lanesRes.data;

        return DTO.getLaneDTO(lane, trailerTypes);
    }

    return null;
};

export const addLane = async (payload, trailerTypes = []) => {
    const lanesRes = await axiosAuthenticated.post(lanesPath, { ...payload });
    if (lanesRes && lanesRes.status === 201) {
        let newLane = lanesRes.data;

        return DTO.getLaneDTO(newLane, trailerTypes);
    }

    return null;
};

export const updateLane = async (id, payload, trailerTypes = []) => {
    const lanesRes = await axiosAuthenticated.put(lanesPath + `/${id}`, { ...payload });
    if (lanesRes && lanesRes.status === 200) {
        let updatedLane = lanesRes.data;

        return DTO.getLaneDTO(updatedLane, trailerTypes);
    }

    return null;
};

export const removeLane = async (id) => {
    const lanesRes = await axiosAuthenticated.put(lanesPath + `/${id}`, { isDeleted: true });
    if (lanesRes && lanesRes.status === 200) {
        return lanesRes.data;
    }

    return null;
};

//#endregion