import { isStringNotEmpty } from '../../shared/objectUtils';
import EntityUtils from '../entityUtils';

export const getDocumentDTO = (document, accounts = [], systemUsers = []) => {
    // Get Account Info
    if (isStringNotEmpty(document.entityId) && isStringNotEmpty(document.entityType) && document.entityType === "ACCOUNT" || document.entityType === "ACCOUNT_SHIPPER" || document.entityType === "ACCOUNT_CARRIER" || document.entityType === "ACCOUNT_PRODUCER" || document.entityType === "ACCOUNT_RECEIVER" || document.entityType === "ACCOUNT_BROKER" || document.entityType === "ACCOUNT_FACTORING") {
        document.entity = EntityUtils.getAccountInfo(document.entityId, accounts);
    }

    if (isStringNotEmpty(document.reviewedBy)) {
        document.reviewedByUser = EntityUtils.getUserProfileInfo(document.reviewedBy, systemUsers);
    }

    return document;
};