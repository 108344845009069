import React, { useState, useEffect, useMemo } from 'react';
import { Button, Input, Select, Row, Col, Drawer, Tooltip, InputNumber, Switch, Radio } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import UploadFile from "../UploadFile/UploadFile";
import FormItem from '../FormItem/FormItem';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import { convertToObject, isListNotEmpty, isNotNullOrUndefined, isNullOrUndefined, isObjectNotEmpty, isStringNotEmpty, removeEmpty } from '../../shared/objectUtils';
import Form from '../Form/Form';
import FormItemFile from '../FormItemFile/FormItemFile';
import Fieldset from '../FormFieldset/FormFieldset';
import Enums from '../../shared/enums';
import StringFormatter from '../../shared/stringFormatter';
import DataRow from '../DataRow/DataRow';
import FormItemDisplay from '../FormItemDisplay/FormItemDisplay';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import FormButtons from '../FormButtons/FormButtons';
import { CanSee } from '../../shared/entitlements/entitlements';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Header from '../Header/Header';
import FormItemAddress from '../FormItemAddress/FormItemAddress';
import FormItemPointOfContact from '../FormItemPointOfContact/FormItemPointOfContact';
import DatePicker from '../DatePickerAutoAccept/DatePickerAutoAccept';
import moment from 'moment';
import DataContactInfoRow from '../DataContactInfoRow/DataContactInfoRow';
import DataAddressRow from '../DataAddressRow/DataAddressRow';
import NewLinkedFactoring from '../NewLinkedFactoring/NewLinkedFactoring';
import FormItemMCNumber from '../FormItemMCNumber/FormItemMCNumber';
import FormItemDOTNumber from '../FormItemDOTNumber/FormItemDOTNumber';
import FormItemEIN from '../FormItemEIN/FormItemEIN';

const { Option } = Select;
const stringFormatter = new StringFormatter();

const NewAccountCompany = ({ companyData, setCompanyData, entityTypes, logoFile, setLogoFile, logoFileList, setLogoFileList, insuranceExpiryDate, w9File, setW9File, w9FileList, setW9FileList, insuranceFile, setInsuranceFile, insuranceFileList, setInsuranceFileList, setInsuranceExpiryDate, completedSteps, setCompletedSteps, previousStepAction, nextStepAction, cancelButton = null }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    //#endregion
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const methods2 = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const services = useSelector(state => state.orchestrator.services);

    //#endregion
    //#region enums

    const currencyOptions = Enums.Currencies.selectListOptions();
    const billingFrequencyOptions = Enums.BillingFrequencies.selectListOptions();

    //#endregion
    //#region useStates

    const [accountEIN, setAccountEIN] = useState(null);
    const [accountMCNumber, setAccountMCNumber] = useState(null);
    const [accountDOTNumber, setAccountDOTNumber] = useState(null);
    const [hasValidAccountEIN, setHasValidAccountEIN] = useState(false);
    const [hasValidAccountMCNumber, setHasValidAccountMCNumber] = useState(false);
    const [hasValidAccountDOTNumber, setHasValidAccountDOTNumber] = useState(false);
    const [accountServices, setAccountServices] = useState([]);
    const [linkedAccounts, setLinkedAccounts] = useState([]);
    const [showAddNewService, setShowAddNewService] = useState(false);
    const [showAddNewLinkedAccount, setShowAddNewLinkedAccount] = useState(false);
    const [showOverridePrice, setShowOverridePrice] = useState(false);
    const [preferredPaymentMethod, setPreferredPaymentMethod] = useState(null);

    //#endregion
    //#region onSubmit

    const onSubmit = (data) => {
        //console.log(w9File);
        if (entityTypes.includes("CARRIER")) {
            if (isNotNullOrUndefined(w9File)) {
                methods.clearErrors('w9File');
            } else {
                methods.setError('w9File', { type: "required", message: "Required File" });
            }

            //console.log(insuranceFile);
            if (isNotNullOrUndefined(insuranceFile)) {
                methods.clearErrors('insuranceFile');
            } else {
                methods.setError('insuranceFile', { type: "required", message: "Required File" });
            }

            if (isNullOrUndefined(w9File) || isNullOrUndefined(insuranceFile)) {
                return;
            }

            delete data['insuranceExpiryDate'];
        }

        let payload = removeEmpty(data);
        // console.log(payload);

        let einIsValid = false;
        let mcNumIsValid = false;
        let dotNumIsValid = false;
        if (isStringNotEmpty(payload.ein)) {
            if (hasValidAccountEIN === true) {
                einIsValid = true;
            } else {
                einIsValid = false;
            }
        } else {
            einIsValid = true;
        }
        if (isStringNotEmpty(payload.mcNum)) {
            if (hasValidAccountMCNumber === true) {
                mcNumIsValid = true;
            } else {
                mcNumIsValid = false;
            }
        } else {
            mcNumIsValid = true;
        }
        if (isStringNotEmpty(payload.dotNum)) {
            if (hasValidAccountDOTNumber === true) {
                dotNumIsValid = true;
            } else {
                dotNumIsValid = false;
            }
        } else {
            dotNumIsValid = true;
        }

        payload.entityTypes = entityTypes;

        if (isListNotEmpty(accountServices)) {
            payload.accountServices = [...accountServices];
        }

        if (isListNotEmpty(linkedAccounts)) {
            payload.linkedAccounts = [...linkedAccounts];
        }

        if (einIsValid === true && mcNumIsValid === true && dotNumIsValid === true) {
            setCompanyData(payload);
            setCompletedSteps({ ...completedSteps, company: true });
            nextStepAction('review');
        }
    };

    //#endregion
    //#region service methods

    const addService = (data) => {
        // console.log(data);
        if (isListNotEmpty(services)) {
            let newService = {
                serviceName: data.serviceName
            };

            let service = services.find(i => i.name === data.serviceName);
            if (isObjectNotEmpty(service)) {
                newService.service = service;
            }

            if (data.isPriceOverride === true) {
                newService.isPriceOverride = true;
                newService.priceAmount = data.priceAmount;
                newService.priceAmountUnit = data.priceAmountUnit;
                newService.billingFrequency = data.billingFrequency;
            } else {
                newService.isPriceOverride = false;
                newService.priceAmount = 0;
            }

            // console.log(newServices);
            setAccountServices(oldList => [...oldList, { ...newService }]);
            setShowAddNewService(false);
            setShowOverridePrice(false);
        }
    };

    const removeService = (index) => {
        let updatedServices = [...accountServices];
        updatedServices.splice(index, 1);
        setAccountServices([...updatedServices]);
    };

    const toggleNewService = () => {
        if (showAddNewService === true) {
            setShowAddNewService(false);
            setShowOverridePrice(false);
        } else {
            setShowAddNewService(true);
        }
    };

    const onChangeIsPriceOverride = (value) => {
        // console.log(value);
        if (value === true) {
            setShowOverridePrice(true);
        } else {
            setShowOverridePrice(false);
        }
    };

    //#endregion
    //#region linked account methods

    const addLinkedAccount = (data) => {
        // console.log(data);
        if (isObjectNotEmpty(data)) {
            setLinkedAccounts(oldList => [...oldList, { ...data }]);
            setShowAddNewLinkedAccount(false);
        }
    };

    const removeLinkedAccount = (index) => {
        let updatedLinkedAccounts = [...linkedAccounts];
        updatedLinkedAccounts.splice(index, 1);
        setLinkedAccounts([...updatedLinkedAccounts]);
    };

    const toggleNewLinkedAccount = () => {
        setShowAddNewLinkedAccount(!showAddNewLinkedAccount);
    };

    //#endregion
    //#region copy methods

    const copyCompanyAddressToAccountsReceivableAddress = () => {
        let payload = removeEmpty(methods.getValues());
        //console.log(payload);
        let data = convertToObject(payload);

        if (isObjectNotEmpty(data.companyAddress)) {
            methods.setValue('accountsReceivableAddress.streetAddress1', data.companyAddress.streetAddress1);
            methods.setValue('accountsReceivableAddress.streetAddress2', data.companyAddress.streetAddress2);
            methods.setValue('accountsReceivableAddress.city', data.companyAddress.city);
            methods.setValue('accountsReceivableAddress.state', data.companyAddress.state);
            methods.setValue('accountsReceivableAddress.postalCode', data.companyAddress.postalCode);
            methods.setValue('accountsReceivableAddress.country', data.companyAddress.country);
        }
    };

    const copyCompanyAddressToAccountsPayableAddress = () => {
        let payload = removeEmpty(methods.getValues());
        //console.log(payload);
        let data = convertToObject(payload);

        if (isObjectNotEmpty(data.companyAddress)) {
            methods.setValue('accountsPayableAddress.streetAddress1', data.companyAddress.streetAddress1);
            methods.setValue('accountsPayableAddress.streetAddress2', data.companyAddress.streetAddress2);
            methods.setValue('accountsPayableAddress.city', data.companyAddress.city);
            methods.setValue('accountsPayableAddress.state', data.companyAddress.state);
            methods.setValue('accountsPayableAddress.postalCode', data.companyAddress.postalCode);
            methods.setValue('accountsPayableAddress.country', data.companyAddress.country);
        }
    };

    const copyCompanyPointOfContactToAccountUser = () => {
        let payload = removeEmpty(methods.getValues());
        //console.log(payload);
        let data = convertToObject(payload);

        if (isObjectNotEmpty(data.pointOfContact)) {
            methods.setValue('accountUser.firstName', data.pointOfContact.firstName);
            methods.setValue('accountUser.lastName', data.pointOfContact.lastName);
            methods.setValue('accountUser.email', data.pointOfContact.email);
            methods.setValue('accountUser.phone', data.pointOfContact.phone);
        }
    };

    const copyCompanyPointOfContactToAccountsReceivable = () => {
        let payload = removeEmpty(methods.getValues());
        //console.log(payload);
        let data = convertToObject(payload);

        if (isObjectNotEmpty(data.pointOfContact)) {
            methods.setValue('accountsReceivable.firstName', data.pointOfContact.firstName);
            methods.setValue('accountsReceivable.lastName', data.pointOfContact.lastName);
            methods.setValue('accountsReceivable.email', data.pointOfContact.email);
            methods.setValue('accountsReceivable.phone', data.pointOfContact.phone);
            methods.setValue('accountsReceivable.phoneExt', data.pointOfContact.phoneExt);
        }
    };

    const copyCompanyPointOfContactToAccountsPayable = () => {
        let payload = removeEmpty(methods.getValues());
        //console.log(payload);
        let data = convertToObject(payload);

        if (isObjectNotEmpty(data.pointOfContact)) {
            methods.setValue('accountsPayable.firstName', data.pointOfContact.firstName);
            methods.setValue('accountsPayable.lastName', data.pointOfContact.lastName);
            methods.setValue('accountsPayable.email', data.pointOfContact.email);
            methods.setValue('accountsPayable.phone', data.pointOfContact.phone);
            methods.setValue('accountsPayable.phoneExt', data.pointOfContact.phoneExt);
        }
    };

    //#endregion
    //#region useEffects and useMemos

    useMemo(() => {
        dispatch(actionCreators.getServices());
    }, []);

    useEffect(() => {
        if (isObjectNotEmpty(companyData)) {
            if (isStringNotEmpty(companyData.ein)) {
                setAccountEIN(companyData.ein);
                setHasValidAccountEIN(true);
            }
            if (isStringNotEmpty(companyData.mcNum)) {
                setAccountMCNumber(companyData.mcNum);
                setHasValidAccountMCNumber(true);
            }
            if (isStringNotEmpty(companyData.dotNum)) {
                setAccountDOTNumber(companyData.dotNum);
                setHasValidAccountDOTNumber(true);
            }
            if (isStringNotEmpty(companyData.preferredPaymentMethod)) {
                setPreferredPaymentMethod(companyData.preferredPaymentMethod);
            }

            if (isListNotEmpty(companyData.accountServices)) {
                let existingAccountServices = [];
                companyData.accountServices.forEach((accountService) => {
                    let service = services.find(i => i.name === accountService.serviceName);
                    existingAccountServices.push({
                        ...accountService,
                        service: service
                    });
                });
                setAccountServices([...existingAccountServices]);
            }
        }
    }, [companyData]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    const formItemLayoutDouble = {
        labelCol1: { span: 24 },
        wrapperCol1: { span: 16 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 8 },
    };

    const formItemLayoutDouble2 = {
        labelCol1: { span: 12 },
        wrapperCol1: { span: 12 },
        labelCol2: { span: 12 },
        wrapperCol2: { span: 12 },
    };

    const formItemLayoutHorizontal = {
        labelCol: { span: 20 },
        wrapperCol: { span: 4 },
    };

    const formItemLayoutDoubleHorizontal = {
        labelCol1: { span: 12 },
        wrapperCol1: { span: 1 },
        labelCol2: { span: 6 },
        wrapperCol2: { span: 5 },
    };

    //#endregion

    if (isObjectNotEmpty(companyData)) {
        return (
            <>
                <FormProvider {...methods}>
                    <Form onSubmit={methods.handleSubmit(onSubmit)}>
                        <div className="account-creation-step-container">
                            <div className="account-creation-step">
                                <Header
                                    titleStyle={{ paddingLeft: 0, color: '#000000', fontWeight: 500 }}
                                    style={{ paddingLeft: 0, paddingRight: 0 }}
                                    title={"Setup Company Details"}
                                    icon={<FontAwesomeIcon className="anticon" icon={faUsers} />}
                                    iconStyle={{ background: 'transparent' }}
                                    title2={`Please fill out your company information below. When you are done, click the Review button to continue.`}
                                    title2Style={{ whiteSpace: 'normal' }}
                                />
                                {entityTypes.includes("BROKER") === true || entityTypes.includes("CARRIER") === true ? (
                                    <Fieldset legend="First Provide a Valid Unique DOT # and MC #">
                                        <FormItemDOTNumber
                                            format="vertical"
                                            required={true}
                                            name="dotNum"
                                            defaultValue={isObjectNotEmpty(companyData) && isStringNotEmpty(companyData.dotNum) ? companyData.dotNum : ''}
                                            accountDOTNumber={accountDOTNumber}
                                            setAccountDOTNumber={setAccountDOTNumber}
                                            hasValidAccountDOTNumber={hasValidAccountDOTNumber}
                                            setHasValidAccountDOTNumber={setHasValidAccountDOTNumber}
                                        />
                                        <FormItemMCNumber
                                            format="vertical"
                                            required={entityTypes.includes("BROKER") === true}
                                            name="mcNum"
                                            defaultValue={isObjectNotEmpty(companyData) && isStringNotEmpty(companyData.mcNum) ? companyData.mcNum : ''}
                                            accountMCNumber={accountMCNumber}
                                            setAccountMCNumber={setAccountMCNumber}
                                            hasValidAccountMCNumber={hasValidAccountMCNumber}
                                            setHasValidAccountMCNumber={setHasValidAccountMCNumber}
                                        />
                                    </Fieldset>
                                ) : null}
                                <Fieldset legend={(entityTypes.includes("BROKER") === false && entityTypes.includes("CARRIER") === false) ? "First Provide a Company Name and EIN" : "Company Name and EIN"}>
                                    <FormItem {...formItemLayout} label="Company Name" required format="vertical"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Input
                                                placeholder="Company Name"
                                                onBlur={onBlur}
                                                onChange={e => { onChange(e.target.value); }}
                                                value={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        rules={{
                                            required: 'Required Field',
                                            minLength: { value: 4, message: 'Company Name must be at least 4 characters long' },
                                        }}
                                        name="name"
                                        defaultValue={companyData.name ? companyData.name : ""}
                                    />
                                    <FormItemEIN
                                        format="vertical"
                                        required={entityTypes.includes("BROKER") === false && entityTypes.includes("CARRIER") === false}
                                        name="ein"
                                        defaultValue={isObjectNotEmpty(companyData) && isStringNotEmpty(companyData.ein) ? companyData.ein : ''}
                                        accountEIN={accountEIN}
                                        setAccountEIN={setAccountEIN}
                                        hasValidAccountEIN={hasValidAccountEIN}
                                        setHasValidAccountEIN={setHasValidAccountEIN}
                                    />
                                </Fieldset>
                                {(((entityTypes.includes("CARRIER") === true || entityTypes.includes("BROKER") === true) && ((hasValidAccountEIN === true && isStringNotEmpty(accountEIN)) || hasValidAccountEIN === false) && ((hasValidAccountMCNumber === true && isStringNotEmpty(accountMCNumber)) || hasValidAccountMCNumber === false) && hasValidAccountDOTNumber === true) || ((entityTypes.includes("CARRIER") === false && entityTypes.includes("BROKER") === false) && hasValidAccountEIN === true)) ? (
                                    <>
                                        <Fieldset legend="Company Logo">
                                            <FormItemFile {...formItemLayout} label="Company Logo" name="logoFile">
                                                <UploadFile
                                                    beforeUpload={(file) => {
                                                        setLogoFile(file);
                                                        return false;
                                                    }}
                                                    onRemove={(file) => {
                                                        setLogoFile(null);
                                                    }}
                                                    message="Please upload a photo of your company's logo."
                                                    setFileList={setLogoFileList}
                                                    fileList={logoFileList}
                                                />
                                            </FormItemFile>
                                        </Fieldset>
                                        <Fieldset legend="Company Contact Information (* indicates a required field)">
                                            <FormItemPointOfContact
                                                format="vertical"
                                                required={entityTypes.includes("SHIPPER") || entityTypes.includes("CARRIER") || entityTypes.includes("BROKER")}
                                                defaultValue={companyData}
                                                emailFieldLabel="Company Email"
                                                showName={false}
                                                showEmail={true}
                                                showPhone={false}
                                            />
                                            <FormItemPointOfContact
                                                format="vertical"
                                                required={entityTypes.includes("SHIPPER") || entityTypes.includes("CARRIER") || entityTypes.includes("BROKER")}
                                                defaultValue={companyData}
                                                phoneFieldLabel="Company Day Time Phone #"
                                                showPhone={true}
                                                showEmail={false}
                                                showName={false}
                                            />
                                            <FormItemPointOfContact
                                                format="vertical"
                                                required={entityTypes.includes("SHIPPER") || entityTypes.includes("CARRIER") || entityTypes.includes("BROKER")}
                                                defaultValue={companyData}
                                                phoneFieldLabel="Company After Hours Phone #"
                                                showPhone={true}
                                                showEmail={false}
                                                showName={false}
                                                phoneFieldName="afterHoursPhone"
                                                phoneExtFieldName="afterHoursPhoneExt"
                                            />
                                            <FormItem {...formItemLayout} label="Company Fax #" format="vertical"
                                                render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Fax #" ref={ref} />}
                                                rules={{ required: false }}
                                                name="fax"
                                                defaultValue={companyData.fax ? companyData.fax : ""}
                                            />
                                        </Fieldset>
                                        <Fieldset legend="Point of Contact (* indicates a required field)">
                                            <FormItemPointOfContact
                                                format="vertical"
                                                required={entityTypes.includes("SHIPPER") || entityTypes.includes("CARRIER") || entityTypes.includes("BROKER")}
                                                name="pointOfContact"
                                                defaultValue={companyData.pointOfContact}
                                            />
                                        </Fieldset>
                                        <Fieldset legend="Company Address (* indicates a required field)">
                                            <FormItemAddress
                                                format="vertical"
                                                required={entityTypes.includes("SHIPPER") || entityTypes.includes("CARRIER") || entityTypes.includes("BROKER")}
                                                name="companyAddress"
                                                defaultValue={companyData.companyAddress}
                                                isPostalAddress={true}
                                            />
                                        </Fieldset>
                                        {entityTypes.includes("SHIPPER") ? (
                                            <Fieldset legend="Shipper Details">
                                                <Row gutter={[16, 16]}>
                                                    <Col span={24}>
                                                        <FormItem {...formItemLayout} label="D&B #" format="vertical"
                                                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="D&B #" ref={ref} />}
                                                            rules={{ required: false }}
                                                            name="dbNum"
                                                            defaultValue={companyData.dbNum ? companyData.dbNum : ''}
                                                        />
                                                        <FormItem {...formItemLayout} label="Produce Blue Book #" format="vertical"
                                                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Produce Blue Book #" ref={ref} />}
                                                            rules={{ required: false }}
                                                            name="blueBookNum"
                                                            defaultValue={companyData.blueBookNum ? companyData.blueBookNum : ''}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Fieldset>
                                        ) : null}
                                        {entityTypes.includes("CARRIER") ? (
                                            <Fieldset legend="Carrier Documents (* indicates a required field or file)">
                                                <FormItemFile {...formItemLayout} label="W9 File" required name="w9File">
                                                    <UploadFile
                                                        beforeUpload={(file) => {
                                                            setW9File(file);
                                                            methods.clearErrors('w9File');
                                                            return false;
                                                        }}
                                                        onRemove={(file) => {
                                                            setW9File(null);
                                                        }}
                                                        message="Please upload a photo of the document."
                                                        setFileList={setW9FileList}
                                                        fileList={w9FileList}
                                                    />
                                                </FormItemFile>
                                                <FormItem {...formItemLayout} label="Insurance Expiration Date" required
                                                    render={({ onChange, onBlur, value, name, ref }) => (
                                                        <DatePicker
                                                            placeholder="Expiration Date"
                                                            style={{ width: '100%' }}
                                                            onBlur={onBlur}
                                                            onChange={(date, dateString) => { setInsuranceExpiryDate(date); onChange(date); }}
                                                            value={value}
                                                            name={name}
                                                            onSelect={(date) => { onChange(date); }}
                                                            ref={ref}
                                                        />
                                                    )}
                                                    rules={{ required: "Required Field" }}
                                                    name="insuranceExpiryDate"
                                                    defaultValue={insuranceExpiryDate ? moment(insuranceExpiryDate) : null}
                                                />
                                                <FormItemFile {...formItemLayout} label="Insurance File" required name="insuranceFile">
                                                    <UploadFile
                                                        beforeUpload={(file) => {
                                                            setInsuranceFile(file);
                                                            methods.clearErrors('insuranceFile');
                                                            return false;
                                                        }}
                                                        onRemove={(file) => {
                                                            setInsuranceFile(null);
                                                        }}
                                                        message="Please upload a photo of the document."
                                                        setFileList={setInsuranceFileList}
                                                        fileList={insuranceFileList}
                                                    />
                                                </FormItemFile>
                                            </Fieldset>
                                        ) : null}
                                        {entityTypes.includes("BROKER") || entityTypes.includes("SHIPPER") || entityTypes.includes("FACTORING") || entityTypes.includes("PRODUCER") || entityTypes.includes("RECEIVER") ? (
                                            <>
                                                <Fieldset legend="Accounts Payable Contact (* indicates a required field)">
                                                    <Row gutter={[16, 16]}>
                                                        <Col span={24}>
                                                            <Button type="primary" onClick={() => { copyCompanyPointOfContactToAccountsPayable(); }}>Copy from Company Point of Contact</Button>
                                                        </Col>
                                                        <Col span={24}>
                                                            <FormItemPointOfContact
                                                                format="vertical"
                                                                required={entityTypes.includes("BROKER") || entityTypes.includes("SHIPPER")}
                                                                name="accountsPayable"
                                                                defaultValue={companyData.accountsPayable}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Fieldset>
                                                <Fieldset legend="Accounts Payable Address (* indicates a required field)">
                                                    <Row gutter={[16, 16]}>
                                                        <Col span={24}>
                                                            <Button type="primary" onClick={() => { copyCompanyAddressToAccountsPayableAddress(); }}>Copy from Company Address</Button>
                                                        </Col>
                                                        <Col span={24}>
                                                            <FormItemAddress
                                                                format="vertical"
                                                                required={entityTypes.includes("BROKER") || entityTypes.includes("SHIPPER")}
                                                                name="accountsPayableAddress"
                                                                defaultValue={companyData.accountsPayableAddress}
                                                                isPostalAddress={true}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Fieldset>
                                            </>
                                        ) : null}
                                        {entityTypes.includes("CARRIER") || entityTypes.includes("BROKER") ? (
                                            <>
                                                <Fieldset legend="Accounts Receivable (* indicates a required field)">
                                                    <FormItem {...formItemLayout} label="Preferred Payment Method" required format="vertical"
                                                        render={({ onChange, onBlur, value, name, ref }) => (
                                                            <Radio.Group onBlur={onBlur} onChange={(e) => { setPreferredPaymentMethod(e.target.value); onChange(e.target.value); }} value={value} name={name} buttonStyle="solid" ref={ref}>
                                                                <Radio.Button value="CHECK">Paper Check</Radio.Button>
                                                                <Radio.Button value="DIRECT_DEPOSIT">Direct Deposit</Radio.Button>
                                                                <Radio.Button value="QUICK_PAY">Quick Pay</Radio.Button>
                                                                <Radio.Button value="FACTORING">Factoring</Radio.Button>
                                                            </Radio.Group>
                                                        )}
                                                        rules={{ required: "Required Field" }}
                                                        name="preferredPaymentMethod"
                                                        defaultValue={companyData.preferredPaymentMethod ? companyData.preferredPaymentMethod : undefined}
                                                    />
                                                </Fieldset>
                                                {preferredPaymentMethod === 'FACTORING' ? (
                                                    <Fieldset legend="My Factoring Companies (if applicable)">
                                                        {linkedAccounts.map((linkedAccount, index) => (
                                                            <Row gutter={[16, 16]} key={`factoring-${index}`}>
                                                                <Col span={18}>
                                                                    <FormItemDisplay label={linkedAccount.name}>
                                                                        <DataContactInfoRow title="Remittance Point of Contact" contact={linkedAccount.accountsReceivable} />
                                                                        <DataAddressRow title="Remittance Address" address={linkedAccount.accountsReceivableAddress} />
                                                                    </FormItemDisplay>
                                                                </Col>
                                                                <Col span={6}>
                                                                    <Button icon={<DeleteOutlined />} onClick={() => { removeLinkedAccount(index); }} />
                                                                </Col>
                                                            </Row>
                                                        ))}
                                                        <Tooltip placement="left" title="Add Factoring Company">
                                                            <Button key="1" type="primary" icon={<PlusOutlined />} shape='circle' onClick={toggleNewLinkedAccount} style={{ margin: "10px", float: "right" }} />
                                                        </Tooltip>
                                                    </Fieldset>
                                                ) : null}
                                            </>
                                        ) : null}
                                        {entityTypes.includes("BROKER") || entityTypes.includes("CARRIER") || entityTypes.includes("FACTORING") || entityTypes.includes("PRODUCER") || entityTypes.includes("RECEIVER") ? (
                                            <>
                                                <Fieldset legend="Accounts Receivable Contact (* indicates a required field)">
                                                    <Row gutter={[16, 16]}>
                                                        <Col span={24}>
                                                            <Button type="primary" onClick={() => { copyCompanyPointOfContactToAccountsReceivable(); }}>Copy from Company Point of Contact</Button>
                                                        </Col>
                                                        <Col span={24}>
                                                            <FormItemPointOfContact
                                                                format="vertical"
                                                                required={entityTypes.includes("BROKER") || entityTypes.includes("CARRIER")}
                                                                name="accountsReceivable"
                                                                defaultValue={companyData.accountsReceivable}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Fieldset>
                                                <Fieldset legend="Accounts Receivable Address (* indicates a required field)">
                                                    <Row gutter={[16, 16]}>
                                                        <Col span={24}>
                                                            <Button type="primary" onClick={() => { copyCompanyAddressToAccountsReceivableAddress(); }}>Copy from Company Address</Button>
                                                        </Col>
                                                        <Col span={24}>
                                                            <FormItemAddress
                                                                format="vertical"
                                                                required={entityTypes.includes("BROKER") || entityTypes.includes("CARRIER")}
                                                                name="accountsReceivableAddress"
                                                                defaultValue={companyData.accountsReceivableAddress}
                                                                isPostalAddress={true}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Fieldset>
                                            </>
                                        ) : null}
                                        {entityTypes.includes("CARRIER") ? (
                                            <>
                                                <Fieldset legend="Asset Information" isCollapsable={true} isCollapsed={companyData && companyData.assetNeeds ? false : true}>
                                                    <Row gutter={[16, 16]}>
                                                        <Col xxl={{ span: 4 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                                            <FormItem {...formItemLayout} label="# of Power Units" format="vertical"
                                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                                    <InputNumber
                                                                        min={0}
                                                                        precision={0}
                                                                        step={1}
                                                                        onBlur={onBlur}
                                                                        onChange={e => { onChange(e); }}
                                                                        value={value}
                                                                        name={name}
                                                                        ref={ref}
                                                                    />
                                                                )}
                                                                rules={{ required: false }}
                                                                name="assetNeeds.numPowerUnits"
                                                                defaultValue={companyData && companyData.assetNeeds ? companyData.assetNeeds.numPowerUnits : null}
                                                            />
                                                        </Col>
                                                        <Col xxl={{ span: 4 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                                            <FormItem {...formItemLayout} label="# of Trailers" format="vertical"
                                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                                    <InputNumber
                                                                        min={0}
                                                                        precision={0}
                                                                        step={1}
                                                                        onBlur={onBlur}
                                                                        onChange={e => { onChange(e); }}
                                                                        value={value}
                                                                        name={name}
                                                                        ref={ref}
                                                                    />
                                                                )}
                                                                rules={{ required: false }}
                                                                name="assetNeeds.numTrailers"
                                                                defaultValue={companyData && companyData.assetNeeds ? companyData.assetNeeds.numTrailers : null}
                                                            />
                                                        </Col>
                                                        <Col xxl={{ span: 4 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                                            <FormItem {...formItemLayout} label="# of Drivers" format="vertical"
                                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                                    <InputNumber
                                                                        min={0}
                                                                        precision={0}
                                                                        step={1}
                                                                        onBlur={onBlur}
                                                                        onChange={e => { onChange(e); }}
                                                                        value={value}
                                                                        name={name}
                                                                        ref={ref}
                                                                    />
                                                                )}
                                                                rules={{ required: false }}
                                                                name="assetNeeds.numDrivers"
                                                                defaultValue={companyData && companyData.assetNeeds ? companyData.assetNeeds.numDrivers : null}
                                                            />
                                                        </Col>
                                                        <Col xxl={{ span: 4 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                                            <FormItem {...formItemLayout} label="# of HazMat Drivers" format="vertical"
                                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                                    <InputNumber
                                                                        min={0}
                                                                        precision={0}
                                                                        step={1}
                                                                        onBlur={onBlur}
                                                                        onChange={e => { onChange(e); }}
                                                                        value={value}
                                                                        name={name}
                                                                        ref={ref}
                                                                    />
                                                                )}
                                                                rules={{ required: false }}
                                                                name="assetNeeds.numHazMatDrivers"
                                                                defaultValue={companyData && companyData.assetNeeds ? companyData.assetNeeds.numHazMatDrivers : null}
                                                            />
                                                        </Col>
                                                        <Col xxl={{ span: 4 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                                            <FormItem {...formItemLayout} label="# of Teams" format="vertical"
                                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                                    <InputNumber
                                                                        min={0}
                                                                        precision={0}
                                                                        step={1}
                                                                        onBlur={onBlur}
                                                                        onChange={e => { onChange(e); }}
                                                                        value={value}
                                                                        name={name}
                                                                        ref={ref}
                                                                    />
                                                                )}
                                                                rules={{ required: false }}
                                                                name="assetNeeds.numTeams"
                                                                defaultValue={companyData && companyData.assetNeeds ? companyData.assetNeeds.numTeams : null}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Fieldset>
                                                <Fieldset legend="Trailer Types" isCollapsable={true} isCollapsed={companyData && companyData.assetNeeds ? false : true}>
                                                    <Row gutter={[16, 16]}>
                                                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                                            <h4 style={{ textAlign: 'center' }}>Reefers</h4>
                                                            <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 48' Reefers?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                                                render1={({ onChange, onBlur, value, name, ref }) => (
                                                                    <Switch
                                                                        checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        onBlur={onBlur}
                                                                        onChange={(checked, event) => { onChange(checked); }}
                                                                        checked={value}
                                                                        name={name}
                                                                        ref={ref}
                                                                    />
                                                                )}
                                                                name1="assetNeeds.has48Reefer"
                                                                defaultValue1={companyData && companyData.assetNeeds ? companyData.assetNeeds.has48Reefer : false}
                                                                render2={({ onChange, onBlur, value, name, ref }) => (
                                                                    <InputNumber
                                                                        min={0}
                                                                        precision={0}
                                                                        step={1}
                                                                        onBlur={onBlur}
                                                                        onChange={e => { onChange(e); }}
                                                                        value={value}
                                                                        name={name}
                                                                        ref={ref}
                                                                    />
                                                                )}
                                                                name2="assetNeeds.num48Reefer"
                                                                defaultValue2={companyData && companyData.assetNeeds ? companyData.assetNeeds.num48Reefer : null}
                                                            />
                                                            <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 53' Reefers?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                                                render1={({ onChange, onBlur, value, name, ref }) => (
                                                                    <Switch
                                                                        checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        onBlur={onBlur}
                                                                        onChange={(checked, event) => { onChange(checked); }}
                                                                        checked={value}
                                                                        name={name}
                                                                        ref={ref}
                                                                    />
                                                                )}
                                                                name1="assetNeeds.has53Reefer"
                                                                defaultValue1={companyData && companyData.assetNeeds ? companyData.assetNeeds.has53Reefer : false}
                                                                render2={({ onChange, onBlur, value, name, ref }) => (
                                                                    <InputNumber
                                                                        min={0}
                                                                        precision={0}
                                                                        step={1}
                                                                        onBlur={onBlur}
                                                                        onChange={e => { onChange(e); }}
                                                                        value={value}
                                                                        name={name}
                                                                        ref={ref}
                                                                    />
                                                                )}
                                                                name2="assetNeeds.num53Reefer"
                                                                defaultValue2={companyData && companyData.assetNeeds ? companyData.assetNeeds.num53Reefer : null}
                                                            />
                                                            <FormItem {...formItemLayoutHorizontal} label="Do you haul frozen?" noColon format="horizontal"
                                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                                    <Switch
                                                                        checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        onBlur={onBlur}
                                                                        onChange={(checked, event) => { onChange(checked); }}
                                                                        checked={value}
                                                                        name={name}
                                                                        ref={ref}
                                                                    />
                                                                )}
                                                                name="assetNeeds.hasReeferFrozen"
                                                                defaultValue={companyData && companyData.assetNeeds ? companyData.assetNeeds.hasReeferFrozen : false}
                                                            />
                                                        </Col>
                                                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                                            <h4 style={{ textAlign: 'center' }}>Dry Vans</h4>
                                                            <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 48' Dry Vans?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                                                render1={({ onChange, onBlur, value, name, ref }) => (
                                                                    <Switch
                                                                        checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        onBlur={onBlur}
                                                                        onChange={(checked, event) => { onChange(checked); }}
                                                                        checked={value}
                                                                        name={name}
                                                                        ref={ref}
                                                                    />
                                                                )}
                                                                name1="assetNeeds.has48DryVan"
                                                                defaultValue1={companyData && companyData.assetNeeds ? companyData.assetNeeds.has48DryVan : false}
                                                                render2={({ onChange, onBlur, value, name, ref }) => (
                                                                    <InputNumber
                                                                        min={0}
                                                                        precision={0}
                                                                        step={1}
                                                                        onBlur={onBlur}
                                                                        onChange={e => { onChange(e); }}
                                                                        value={value}
                                                                        name={name}
                                                                        ref={ref}
                                                                    />
                                                                )}
                                                                name2="assetNeeds.num48DryVan"
                                                                defaultValue2={companyData && companyData.assetNeeds ? companyData.assetNeeds.num48DryVan : null}
                                                            />
                                                            <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 53' Dry Vans?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                                                render1={({ onChange, onBlur, value, name, ref }) => (
                                                                    <Switch
                                                                        checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        onBlur={onBlur}
                                                                        onChange={(checked, event) => { onChange(checked); }}
                                                                        checked={value}
                                                                        name={name}
                                                                        ref={ref}
                                                                    />
                                                                )}
                                                                name1="assetNeeds.has53DryVan"
                                                                defaultValue1={companyData && companyData.assetNeeds ? companyData.assetNeeds.has53DryVan : false}
                                                                render2={({ onChange, onBlur, value, name, ref }) => (
                                                                    <InputNumber
                                                                        min={0}
                                                                        precision={0}
                                                                        step={1}
                                                                        onBlur={onBlur}
                                                                        onChange={e => { onChange(e); }}
                                                                        value={value}
                                                                        name={name}
                                                                        ref={ref}
                                                                    />
                                                                )}
                                                                name2="assetNeeds.num53DryVan"
                                                                defaultValue2={companyData && companyData.assetNeeds ? companyData.assetNeeds.num53DryVan : null}
                                                            />
                                                            <FormItem {...formItemLayoutHorizontal} label="Do you have lift gates for the dry vans?" noColon format="horizontal"
                                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                                    <Switch
                                                                        checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        onBlur={onBlur}
                                                                        onChange={(checked, event) => { onChange(checked); }}
                                                                        checked={value}
                                                                        name={name}
                                                                        ref={ref}
                                                                    />
                                                                )}
                                                                name="assetNeeds.hasDryVanLiftGates"
                                                                defaultValue={companyData && companyData.assetNeeds ? companyData.assetNeeds.hasDryVanLiftGates : false}
                                                            />
                                                            <FormItem {...formItemLayoutHorizontal} label="Do you have roller beds for the dry vans?" noColon format="horizontal"
                                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                                    <Switch
                                                                        checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        onBlur={onBlur}
                                                                        onChange={(checked, event) => { onChange(checked); }}
                                                                        checked={value}
                                                                        name={name}
                                                                        ref={ref}
                                                                    />
                                                                )}
                                                                name="assetNeeds.hasDryVanRollerBed"
                                                                defaultValue={companyData && companyData.assetNeeds ? companyData.assetNeeds.hasDryVanRollerBed : false}
                                                            />
                                                            <h4 style={{ textAlign: 'center' }}>Over-Sized Loads</h4>
                                                            <FormItem {...formItemLayoutHorizontal} label="Do you haul over dimensional/overweight loads?" noColon format="horizontal"
                                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                                    <Switch
                                                                        checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        onBlur={onBlur}
                                                                        onChange={(checked, event) => { onChange(checked); }}
                                                                        checked={value}
                                                                        name={name}
                                                                        ref={ref}
                                                                    />
                                                                )}
                                                                name="assetNeeds.hasOverSized"
                                                                defaultValue={companyData && companyData.assetNeeds ? companyData.assetNeeds.hasOverSized : false}
                                                            />
                                                        </Col>
                                                        {/* 
                                                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                                                    <h4>Flatbeds</h4>
											<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 48' Flatbeds?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
												render1={({ onChange, onBlur, value, name, ref }) => (
													<Switch
														checkedChildren="Yes"
														unCheckedChildren="No"
														onBlur={onBlur}
														onChange={(checked, event) => { onChange(checked); }}
														checked={value}
														name={name}
														ref={ref}
													/>
												)}
												name1="assetNeeds.has48Flatbed"
												defaultValue1={companyData && companyData.assetNeeds ? companyData.assetNeeds.has48Flatbed : false}
												render2={({ onChange, onBlur, value, name, ref }) => (
													<InputNumber
														min={0}
														precision={0}
														step={1}
														onBlur={onBlur}
														onChange={e => { onChange(e); }}
														value={value}
														name={name}
														ref={ref}
													/>
												)}
												name2="assetNeeds.num48Flatbed"
                                                defaultValue2={companyData && companyData.assetNeeds ? companyData.assetNeeds.num48Flatbed : null}
											/>
											<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 53' Flatbeds?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
												render1={({ onChange, onBlur, value, name, ref }) => (
													<Switch
														checkedChildren="Yes"
														unCheckedChildren="No"
														onBlur={onBlur}
														onChange={(checked, event) => { onChange(checked); }}
														checked={value}
														name={name}
														ref={ref}
													/>
												)}
												name1="assetNeeds.has53Flatbed"
												defaultValue1={companyData && companyData.assetNeeds ? companyData.assetNeeds.has53Flatbed : false}
												render2={({ onChange, onBlur, value, name, ref }) => (
													<InputNumber
														min={0}
														precision={0}
														step={1}
														onBlur={onBlur}
														onChange={e => { onChange(e); }}
														value={value}
														name={name}
														ref={ref}
													/>
												)}
												name2="assetNeeds.num53Flatbed"
                                                defaultValue2={companyData && companyData.assetNeeds ? companyData.assetNeeds.num53Flatbed : null}
											/>
											<h4>Low Boys</h4>
											<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Low Boys?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
												render1={({ onChange, onBlur, value, name, ref }) => (
													<Switch
														checkedChildren="Yes"
														unCheckedChildren="No"
														onBlur={onBlur}
														onChange={(checked, event) => { onChange(checked); }}
														checked={value}
														name={name}
														ref={ref}
													/>
												)}
												name1="assetNeeds.hasLowBoy"
												defaultValue1={companyData && companyData.assetNeeds ? companyData.assetNeeds.hasLowBoy : false}
												render2={({ onChange, onBlur, value, name, ref }) => (
													<InputNumber
														min={0}
														precision={0}
														step={1}
														onBlur={onBlur}
														onChange={e => { onChange(e); }}
														value={value}
														name={name}
														ref={ref}
													/>
												)}
												name2="assetNeeds.numLowBoy"
                                                defaultValue2={companyData && companyData.assetNeeds ? companyData.assetNeeds.numLowBoy : null}
											/>
											<h4>RGNs</h4>
											<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have RGNs?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
												render1={({ onChange, onBlur, value, name, ref }) => (
													<Switch
														checkedChildren="Yes"
														unCheckedChildren="No"
														onBlur={onBlur}
														onChange={(checked, event) => { onChange(checked); }}
														checked={value}
														name={name}
														ref={ref}
													/>
												)}
												name1="assetNeeds.hasRGN"
												defaultValue1={companyData && companyData.assetNeeds ? companyData.assetNeeds.hasRGN : false}
												render2={({ onChange, onBlur, value, name, ref }) => (
													<InputNumber
														min={0}
														precision={0}
														step={1}
														onBlur={onBlur}
														onChange={e => { onChange(e); }}
														value={value}
														name={name}
														ref={ref}
													/>
												)}
												name2="assetNeeds.numRGN"
                                                defaultValue2={companyData && companyData.assetNeeds ? companyData.assetNeeds.numRGN : null}
											/>
											<h4>Step Decks</h4>
											<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Step Decks with Ramps?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
												render1={({ onChange, onBlur, value, name, ref }) => (
													<Switch
														checkedChildren="Yes"
														unCheckedChildren="No"
														onBlur={onBlur}
														onChange={(checked, event) => { onChange(checked); }}
														checked={value}
														name={name}
														ref={ref}
													/>
												)}
												name1="assetNeeds.hasStepDeckRamps"
												defaultValue1={companyData && companyData.assetNeeds ? companyData.assetNeeds.hasStepDeckRamps : false}
												render2={({ onChange, onBlur, value, name, ref }) => (
													<InputNumber
														min={0}
														precision={0}
														step={1}
														onBlur={onBlur}
														onChange={e => { onChange(e); }}
														value={value}
														name={name}
														ref={ref}
													/>
												)}
												name2="assetNeeds.numStepDeckRamps"
                                                defaultValue2={companyData && companyData.assetNeeds ? companyData.assetNeeds.numStepDeckRamps : null}
											/>
											<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Step Decks without Ramps?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
												render1={({ onChange, onBlur, value, name, ref }) => (
													<Switch
														checkedChildren="Yes"
														unCheckedChildren="No"
														onBlur={onBlur}
														onChange={(checked, event) => { onChange(checked); }}
														checked={value}
														name={name}
														ref={ref}
													/>
												)}
												name1="assetNeeds.hasStepDeckNoRamps"
												defaultValue1={companyData && companyData.assetNeeds ? companyData.assetNeeds.hasStepDeckNoRamps : false}
												render2={({ onChange, onBlur, value, name, ref }) => (
													<InputNumber
														min={0}
														precision={0}
														step={1}
														onBlur={onBlur}
														onChange={e => { onChange(e); }}
														value={value}
														name={name}
														ref={ref}
													/>
												)}
												name2="assetNeeds.numStepDeckNoRamps"
                                                defaultValue2={companyData && companyData.assetNeeds ? companyData.assetNeeds.numStepDeckNoRamps : null}
											/>
											<h4>Tankers</h4>
											<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Single Compartment Tankers?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
												render1={({ onChange, onBlur, value, name, ref }) => (
													<Switch
														checkedChildren="Yes"
														unCheckedChildren="No"
														onBlur={onBlur}
														onChange={(checked, event) => { onChange(checked); }}
														checked={value}
														name={name}
														ref={ref}
													/>
												)}
												name1="assetNeeds.hasTankersSingleCompartment"
												defaultValue1={companyData && companyData.assetNeeds ? companyData.assetNeeds.hasTankersSingleCompartment : false}
												render2={({ onChange, onBlur, value, name, ref }) => (
													<InputNumber
														min={0}
														precision={0}
														step={1}
														onBlur={onBlur}
														onChange={e => { onChange(e); }}
														value={value}
														name={name}
														ref={ref}
													/>
												)}
												name2="assetNeeds.numTankersSingleCompartment"
                                                defaultValue2={companyData && companyData.assetNeeds ? companyData.assetNeeds.numTankersSingleCompartment : null}
											/>
											<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Multi Compartment Tankers?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
												render1={({ onChange, onBlur, value, name, ref }) => (
													<Switch
														checkedChildren="Yes"
														unCheckedChildren="No"
														onBlur={onBlur}
														onChange={(checked, event) => { onChange(checked); }}
														checked={value}
														name={name}
														ref={ref}
													/>
												)}
												name1="assetNeeds.hasTankersMultiCompartment"
												defaultValue1={companyData && companyData.assetNeeds ? companyData.assetNeeds.hasTankersMultiCompartment : false}
												render2={({ onChange, onBlur, value, name, ref }) => (
													<InputNumber
														min={0}
														precision={0}
														step={1}
														onBlur={onBlur}
														onChange={e => { onChange(e); }}
														value={value}
														name={name}
														ref={ref}
													/>
												)}
												name2="assetNeeds.numTankersMultiCompartment"
                                                defaultValue2={companyData && companyData.assetNeeds ? companyData.assetNeeds.numTankersMultiCompartment : null}
											/>
											<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Fiber Glass Tankers?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
												render1={({ onChange, onBlur, value, name, ref }) => (
													<Switch
														checkedChildren="Yes"
														unCheckedChildren="No"
														onBlur={onBlur}
														onChange={(checked, event) => { onChange(checked); }}
														checked={value}
														name={name}
														ref={ref}
													/>
												)}
												name1="assetNeeds.hasTankersFiberGlass"
												defaultValue1={companyData && companyData.assetNeeds ? companyData.assetNeeds.hasTankersFiberGlass : false}
												render2={({ onChange, onBlur, value, name, ref }) => (
													<InputNumber
														min={0}
														precision={0}
														step={1}
														onBlur={onBlur}
														onChange={e => { onChange(e); }}
														value={value}
														name={name}
														ref={ref}
													/>
												)}
												name2="assetNeeds.numTankersFiberGlass"
                                                defaultValue2={companyData && companyData.assetNeeds ? companyData.assetNeeds.numTankersFiberGlass : null}
											/>
											<h4>Straight Trucks</h4>
											<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Cubed Straight Trucks?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
												render1={({ onChange, onBlur, value, name, ref }) => (
													<Switch
														checkedChildren="Yes"
														unCheckedChildren="No"
														onBlur={onBlur}
														onChange={(checked, event) => { onChange(checked); }}
														checked={value}
														name={name}
														ref={ref}
													/>
												)}
												name1="assetNeeds.hasCubedStraightTruck"
												defaultValue1={companyData && companyData.assetNeeds ? companyData.assetNeeds.hasCubedStraightTruck : false}
												render2={({ onChange, onBlur, value, name, ref }) => (
													<InputNumber
														min={0}
														precision={0}
														step={1}
														onBlur={onBlur}
														onChange={e => { onChange(e); }}
														value={value}
														name={name}
														ref={ref}
													/>
												)}
												name2="assetNeeds.numCubedStraightTruck"
                                                defaultValue2={companyData && companyData.assetNeeds ? companyData.assetNeeds.numCubedStraightTruck : null}
											/>
											<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 26' Straight Trucks?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
												render1={({ onChange, onBlur, value, name, ref }) => (
													<Switch
														checkedChildren="Yes"
														unCheckedChildren="No"
														onBlur={onBlur}
														onChange={(checked, event) => { onChange(checked); }}
														checked={value}
														name={name}
														ref={ref}
													/>
												)}
												name1="assetNeeds.has26StraightTruck"
												defaultValue1={companyData && companyData.assetNeeds ? companyData.assetNeeds.has26StraightTruck : false}
												render2={({ onChange, onBlur, value, name, ref }) => (
													<InputNumber
														min={0}
														precision={0}
														step={1}
														onBlur={onBlur}
														onChange={e => { onChange(e); }}
														value={value}
														name={name}
														ref={ref}
													/>
												)}
												name2="assetNeeds.num26StraightTruck"
                                                defaultValue2={companyData && companyData.assetNeeds ? companyData.assetNeeds.num26StraightTruck : null}
											/>
											<FormItem {...formItemLayoutHorizontal} label="Do you have lift gates for the straight trucks?" noColon format="horizontal"
												render={({ onChange, onBlur, value, name, ref }) => (
													<Switch
														checkedChildren="Yes"
														unCheckedChildren="No"
														onBlur={onBlur}
														onChange={(checked, event) => { onChange(checked); }}
														checked={value}
														name={name}
														ref={ref}
													/>
												)}
												name="assetNeeds.hasStraightTruckLiftGates"
												defaultValue={companyData && companyData.assetNeeds ? companyData.assetNeeds.hasStraightTruckLiftGates : false}
											/>
											<h4>Sprinter Vans</h4>
											<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Sprinter Vans?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
												render1={({ onChange, onBlur, value, name, ref }) => (
													<Switch
														checkedChildren="Yes"
														unCheckedChildren="No"
														onBlur={onBlur}
														onChange={(checked, event) => { onChange(checked); }}
														checked={value}
														name={name}
														ref={ref}
													/>
												)}
												name1="assetNeeds.hasSprinterVan"
												defaultValue1={companyData && companyData.assetNeeds ? companyData.assetNeeds.hasSprinterVan : false}
												render2={({ onChange, onBlur, value, name, ref }) => (
													<InputNumber
														min={0}
														precision={0}
														step={1}
														onBlur={onBlur}
														onChange={e => { onChange(e); }}
														value={value}
														name={name}
														ref={ref}
													/>
												)}
												name2="assetNeeds.numSprinterVan"
                                                defaultValue2={companyData && companyData.assetNeeds ? companyData.assetNeeds.numSprinterVan : null}
											/>
                                            </Col> */}
                                                    </Row>
                                                </Fieldset>
                                                <CanSee staffOnly={true}>
                                                    <Fieldset legend="SMS Basic Scores" isCollapsable={true} isCollapsed={companyData.smsBasic ? false : true}>
                                                        <FormItem {...formItemLayout} label="Hours of Service Compliance"
                                                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Hours of Service Compliance" ref={ref} />}
                                                            rules={{ required: false }}
                                                            name="smsBasic.hosCompliance"
                                                            defaultValue={companyData.smsBasic && companyData.smsBasic.hosCompliance ? companyData.smsBasic.hosCompliance : ""}
                                                        />
                                                        <FormItem {...formItemLayout} label="Unsafe Driving"
                                                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Unsafe Driving" ref={ref} />}
                                                            rules={{ required: false }}
                                                            name="smsBasic.unsafeDriving"
                                                            defaultValue={companyData.smsBasic && companyData.smsBasic.unsafeDriving ? companyData.smsBasic.unsafeDriving : ""}
                                                        />
                                                        <FormItem {...formItemLayout} label="Vehicle Maintenance"
                                                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Vehicle Maintenance" ref={ref} />}
                                                            rules={{ required: false }}
                                                            name="smsBasic.vehicleMaintenance"
                                                            defaultValue={companyData.smsBasic && companyData.smsBasic.vehicleMaintenance ? companyData.smsBasic.vehicleMaintenance : ""}
                                                        />
                                                        <FormItem {...formItemLayout} label="Controlled Substance Abuse"
                                                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Controlled Substance Abuse" ref={ref} />}
                                                            rules={{ required: false }}
                                                            name="smsBasic.controlledSA"
                                                            defaultValue={companyData.smsBasic && companyData.smsBasic.controlledSA ? companyData.smsBasic.controlledSA : ""}
                                                        />
                                                        <FormItem {...formItemLayout} label="Driver Fitness"
                                                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Driver Fitness" ref={ref} />}
                                                            rules={{ required: false }}
                                                            name="smsBasic.driverFitness"
                                                            defaultValue={companyData.smsBasic && companyData.smsBasic.driverFitness ? companyData.smsBasic.driverFitness : ""}
                                                        />
                                                        <FormItem {...formItemLayout} label="Average SMS Score"
                                                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Average SMS Score" ref={ref} />}
                                                            rules={{ required: false }}
                                                            name="smsBasic.avgSMSScore"
                                                            defaultValue={companyData.smsBasic && companyData.smsBasic.avgSMSScore ? companyData.smsBasic.avgSMSScore : ""}
                                                        />
                                                        <FormItem {...formItemLayout} label="Weighted Average SMS Score"
                                                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Weighted Average SMS Score" ref={ref} />}
                                                            rules={{ required: false }}
                                                            name="smsBasic.weightedAvgSMSScore"
                                                            defaultValue={companyData.smsBasic && companyData.smsBasic.weightedAvgSMSScore ? companyData.smsBasic.weightedAvgSMSScore : ""}
                                                        />
                                                    </Fieldset>
                                                </CanSee>
                                            </>
                                        ) : null}
                                        {entityTypes.includes("SHIPPER") ? (
                                            <Fieldset legend="What are your equipment needs based on the commodities you ship?">
                                                <Row gutter={[16, 16]}>
                                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                                        <h4 style={{ textAlign: 'center' }}>Reefers</h4>
                                                        <FormItem {...formItemLayoutHorizontal} label="Do you need 48' Reefers?" noColon format="horizontal"
                                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                                <Switch
                                                                    checkedChildren="Yes"
                                                                    unCheckedChildren="No"
                                                                    onBlur={onBlur}
                                                                    onChange={(checked, event) => { onChange(checked); }}
                                                                    checked={value}
                                                                    name={name}
                                                                    ref={ref}
                                                                />
                                                            )}
                                                            name="assetNeeds.has48Reefer"
                                                            defaultValue={companyData && companyData.assetNeeds ? companyData.assetNeeds.has48Reefer : false}
                                                        />
                                                        <FormItem {...formItemLayoutHorizontal} label="Do you need 53' Reefers?" noColon format="horizontal"
                                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                                <Switch
                                                                    checkedChildren="Yes"
                                                                    unCheckedChildren="No"
                                                                    onBlur={onBlur}
                                                                    onChange={(checked, event) => { onChange(checked); }}
                                                                    checked={value}
                                                                    name={name}
                                                                    ref={ref}
                                                                />
                                                            )}
                                                            name="assetNeeds.has53Reefer"
                                                            defaultValue={companyData && companyData.assetNeeds ? companyData.assetNeeds.has53Reefer : false}
                                                        />
                                                        <FormItem {...formItemLayoutHorizontal} label="Do you need to ship frozen?" noColon format="horizontal"
                                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                                <Switch
                                                                    checkedChildren="Yes"
                                                                    unCheckedChildren="No"
                                                                    onBlur={onBlur}
                                                                    onChange={(checked, event) => { onChange(checked); }}
                                                                    checked={value}
                                                                    name={name}
                                                                    ref={ref}
                                                                />
                                                            )}
                                                            name="assetNeeds.hasReeferFrozen"
                                                            defaultValue={companyData && companyData.assetNeeds ? companyData.assetNeeds.hasReeferFrozen : false}
                                                        />
                                                    </Col>
                                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                                        <h4 style={{ textAlign: 'center' }}>Dry Vans</h4>
                                                        <FormItem {...formItemLayoutHorizontal} label="Do you need 48' Dry Vans?" noColon format="horizontal"
                                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                                <Switch
                                                                    checkedChildren="Yes"
                                                                    unCheckedChildren="No"
                                                                    onBlur={onBlur}
                                                                    onChange={(checked, event) => { onChange(checked); }}
                                                                    checked={value}
                                                                    name={name}
                                                                    ref={ref}
                                                                />
                                                            )}
                                                            name="assetNeeds.has48DryVan"
                                                            defaultValue={companyData && companyData.assetNeeds ? companyData.assetNeeds.has48DryVan : false}
                                                        />
                                                        <FormItem {...formItemLayoutHorizontal} label="Do you need 53' Dry Vans?" noColon format="horizontal"
                                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                                <Switch
                                                                    checkedChildren="Yes"
                                                                    unCheckedChildren="No"
                                                                    onBlur={onBlur}
                                                                    onChange={(checked, event) => { onChange(checked); }}
                                                                    checked={value}
                                                                    name={name}
                                                                    ref={ref}
                                                                />
                                                            )}
                                                            name="assetNeeds.has53DryVan"
                                                            defaultValue={companyData && companyData.assetNeeds ? companyData.assetNeeds.has53DryVan : false}
                                                        />
                                                        <FormItem {...formItemLayoutHorizontal} label="Do you need lift gates for the dry vans?" noColon format="horizontal"
                                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                                <Switch
                                                                    checkedChildren="Yes"
                                                                    unCheckedChildren="No"
                                                                    onBlur={onBlur}
                                                                    onChange={(checked, event) => { onChange(checked); }}
                                                                    checked={value}
                                                                    name={name}
                                                                    ref={ref}
                                                                />
                                                            )}
                                                            name="assetNeeds.hasDryVanLiftGates"
                                                            defaultValue={companyData && companyData.assetNeeds ? companyData.assetNeeds.hasDryVanLiftGates : false}
                                                        />
                                                        <FormItem {...formItemLayoutHorizontal} label="Do you need roller beds for the dry vans?" noColon format="horizontal"
                                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                                <Switch
                                                                    checkedChildren="Yes"
                                                                    unCheckedChildren="No"
                                                                    onBlur={onBlur}
                                                                    onChange={(checked, event) => { onChange(checked); }}
                                                                    checked={value}
                                                                    name={name}
                                                                    ref={ref}
                                                                />
                                                            )}
                                                            name="assetNeeds.hasDryVanRollerBed"
                                                            defaultValue={companyData && companyData.assetNeeds ? companyData.assetNeeds.hasDryVanRollerBed : false}
                                                        />
                                                        <h4 style={{ textAlign: 'center' }}>Over-Sized Loads</h4>
                                                        <FormItem {...formItemLayoutHorizontal} label="Do you need to ship over dimensional/overweight loads?" noColon format="horizontal"
                                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                                <Switch
                                                                    checkedChildren="Yes"
                                                                    unCheckedChildren="No"
                                                                    onBlur={onBlur}
                                                                    onChange={(checked, event) => { onChange(checked); }}
                                                                    checked={value}
                                                                    name={name}
                                                                    ref={ref}
                                                                />
                                                            )}
                                                            name="assetNeeds.hasOverSized"
                                                            defaultValue={companyData && companyData.assetNeeds ? companyData.assetNeeds.hasOverSized : false}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Fieldset>
                                        ) : null}
                                        <CanSee adminsOnly={true}>
                                            <Fieldset legend="Services (if applicable)" isCollapsable={true} isCollapsed={isListNotEmpty(accountServices) ? false : true}>
                                                {accountServices.map((accountService, index) => (
                                                    <Row gutter={[16, 16]} key={`services-${index}`}>
                                                        <Col span={18}>
                                                            <FormItemDisplay label={accountService.service.displayName} format="horizontal">
                                                                <DataRow title="Price" value={accountService.isPriceOverride ? accountService.priceAmount : accountService.service.priceAmount} units={accountService.isPriceOverride ? accountService.priceAmountUnit : accountService.service.priceAmountUnit} dataType="Money" />
                                                                <DataRow title="Billing Frequency" value={accountService.isPriceOverride ? accountService.billingFrequency : accountService.service.billingFrequency} dataType="BillingFrequency" />
                                                            </FormItemDisplay>
                                                        </Col>
                                                        <Col span={6}>
                                                            <Button icon={<DeleteOutlined />} onClick={() => { removeService(index); }} />
                                                        </Col>
                                                    </Row>
                                                ))}
                                                <Tooltip placement="left" title="Add Service">
                                                    <Button key="1" type="primary" icon={<PlusOutlined />} shape='circle' onClick={toggleNewService} style={{ margin: "10px", float: "right" }} />
                                                </Tooltip>
                                            </Fieldset>
                                        </CanSee>
                                        {(entityTypes.includes("SHIPPER") === true || entityTypes.includes("CARRIER") === true || entityTypes.includes("BROKER") === true || entityTypes.includes("FACTORING") === true) ? (
                                            <CanSee staffOnly={true}>
                                                <Fieldset legend="Setup Default Account Admin (* indicates a required field)">
                                                    <Row gutter={[16, 16]}>
                                                        <Col span={24}>
                                                            <Button type="primary" onClick={() => { copyCompanyPointOfContactToAccountUser(); }}>Copy from Company Main Point of Contact</Button>
                                                        </Col>
                                                        <Col span={24}>
                                                            <FormItemDouble {...formItemLayoutDouble2} label1="First Name" label2="Last Name" format="vertical" required1 required2
                                                                render1={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="First Name" ref={ref} />}
                                                                rules1={{ required: 'Required Field' }}
                                                                name1="accountUser.firstName"
                                                                defaultValue1={companyData.accountUser && companyData.accountUser.firstName ? companyData.accountUser.firstName : ""}
                                                                render2={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Last Name" ref={ref} />}
                                                                rules2={{ required: 'Required Field' }}
                                                                name2="accountUser.lastName"
                                                                defaultValue2={companyData.accountUser && companyData.accountUser.lastName ? companyData.accountUser.lastName : ""}
                                                            />
                                                            <FormItem {...formItemLayout} label="Email Address (used for login)" required
                                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                                    <Input
                                                                        placeholder="Email Address"
                                                                        onBlur={onBlur}
                                                                        onChange={e => { onChange(e.target.value); }}
                                                                        value={value}
                                                                        name={name}
                                                                        ref={ref}
                                                                    />
                                                                )}
                                                                rules={{
                                                                    required: 'Required Field',
                                                                    minLength: { value: 4, message: 'Email Address must be at least 4 characters long' },
                                                                    pattern: {
                                                                        value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                                                        message: "Please enter a valid email address"
                                                                    }
                                                                }}
                                                                name="accountUser.email"
                                                                defaultValue={companyData.accountUser && companyData.accountUser.email ? companyData.accountUser.email : ""}
                                                            />
                                                            <FormItem {...formItemLayout} label="Personal Phone # (used for login)" required
                                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                                    <Input
                                                                        placeholder="Personal Phone #"
                                                                        onBlur={onBlur}
                                                                        onChange={e => { onChange(e.target.value); }}
                                                                        value={value}
                                                                        name={name}
                                                                        ref={ref}
                                                                    />
                                                                )}
                                                                rules={{
                                                                    required: 'Required Field',
                                                                    pattern: {
                                                                        value: /^\d{10}$/,
                                                                        message: "Please enter a valid 10 digit phone number with no special characters"
                                                                    }
                                                                }}
                                                                name="accountUser.phone"
                                                                defaultValue={companyData.accountUser && companyData.accountUser.phone ? companyData.accountUser.phone : ""}
                                                            />
                                                            <FormItem {...formItemLayout} label="Send Invitation (email or text) to Default Account Admin Upon Account Creation" format="vertical"
                                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                                    <Switch
                                                                        checkedChildren="Yes"
                                                                        unCheckedChildren="No"
                                                                        onBlur={onBlur}
                                                                        onChange={(checked, event) => { onChange(checked); }}
                                                                        checked={value}
                                                                        name={name}
                                                                        ref={ref}
                                                                    />
                                                                )}
                                                                name="isInvited"
                                                                defaultValue={companyData.isInvited && companyData.isInvited === true ? true : false}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Fieldset>
                                            </CanSee>
                                        ) : null}
                                    </>
                                ) : null}
                            </div>
                            <Row gutter={[12, 12]} className="account-creation-step-buttons">
                                <Col xs={{ span: 24, order: 2 }} md={{ span: 12, order: 1 }}>
                                    {cancelButton}
                                </Col>
                                <Col xs={{ span: 24, order: 1 }} md={{ span: 12, order: 2 }}>
                                    <Button type="primary" block htmlType="submit" disabled={((entityTypes.includes("CARRIER") === true && ((hasValidAccountEIN === false && isStringNotEmpty(accountEIN)) || (hasValidAccountMCNumber === false && isStringNotEmpty(accountMCNumber)) || hasValidAccountDOTNumber === false)) || (entityTypes.includes("BROKER") === true && ((hasValidAccountEIN === false && isStringNotEmpty(accountEIN)) || hasValidAccountMCNumber === false || hasValidAccountDOTNumber === false)) || ((entityTypes.includes("CARRIER") === false && entityTypes.includes("BROKER") === false) && hasValidAccountEIN === false))}>Review</Button>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </FormProvider>
                <Drawer
                    title={'Add New Factoring Company'}
                    width={fullWidth > 720 ? fullWidth / 2 : 360}
                    onClose={toggleNewLinkedAccount}
                    visible={showAddNewLinkedAccount}
                    bodyStyle={{ paddingBottom: 80 }}
                    style={{ zIndex: 1000 }}
                    destroyOnClose={true}
                    closable={false}
                    maskClosable={false}
                >
                    <NewLinkedFactoring
                        addLinkedAccount={addLinkedAccount}
                        cancel={toggleNewLinkedAccount}
                    />
                </Drawer>
                <Drawer
                    title={'Add New Service'}
                    width={fullWidth > 720 ? fullWidth / 2 : 360}
                    onClose={toggleNewService}
                    visible={showAddNewService}
                    bodyStyle={{ paddingBottom: 80 }}
                    style={{ zIndex: 1000 }}
                    destroyOnClose={true}
                    closable={false}
                    maskClosable={false}
                >
                    <FormProvider {...methods2}>
                        <Form onSubmit={methods2.handleSubmit(addService)}>
                            <FormItem {...formItemLayout} label="Service" format="vertical" required
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please Select a Service"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    >
                                        {services.map(a => <Option value={a.name} key={a.name}>{`${a.displayName} - ${stringFormatter.toFormattedMoney(a.priceAmount)}${a.billingFrequency !== 'NONE' ? ' billed ' + stringFormatter.toFormattedString('BillingFrequency', a.billingFrequency, null) : ''}`}</Option>)}
                                    </Select>
                                )}
                                rules={{ required: 'Required Field' }}
                                name="serviceName"
                            />
                            <FormItem {...formItemLayout} label="Over-Ride Price?" format="vertical"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Switch
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        onBlur={onBlur}
                                        onChange={(checked, event) => { onChange(checked); onChangeIsPriceOverride(checked); }}
                                        checked={value}
                                        name={name}
                                        ref={ref}
                                    />
                                )}
                                name="isPriceOverride"
                                defaultValue={false}
                            />
                            {(showOverridePrice === true) && (
                                <>
                                    <FormItemDouble {...formItemLayoutDouble} label1="Over-Ride Price" required1 required2 format="vertical"
                                        render1={({ onChange, onBlur, value, name, ref }) => (
                                            <InputNumber
                                                precision={2}
                                                min={0}
                                                placeholder="Over-Ride Price"
                                                style={{ width: '100%' }}
                                                onBlur={onBlur}
                                                onChange={e => { onChange(e); }}
                                                value={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        rules1={{ required: "Required Field" }}
                                        name1="priceAmount"
                                        render2={({ onChange, onBlur, value, name, ref }) => (
                                            <Select
                                                placeholder="Please Select"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                                ref={ref}
                                            >
                                                {currencyOptions}
                                            </Select>
                                        )}
                                        rules2={{ required: "Required Field" }}
                                        name2="priceAmountUnit"
                                    />
                                    <FormItem {...formItemLayout} label="Over-Ride Billing Frequency" required format="vertical"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Select
                                                placeholder="Please select the Over-Ride Billing Frequency"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                                ref={ref}
                                            >
                                                {billingFrequencyOptions}
                                            </Select>
                                        )}
                                        rules={{ required: "Required Field" }}
                                        name="billingFrequency"
                                    />
                                </>
                            )}
                            <FormButtons cancel={toggleNewService} submitText="Add Service" />
                        </Form>
                    </FormProvider>
                </Drawer>
            </>
        );
    } else {
        return null;
    }
};

export default NewAccountCompany;