import React from 'react';
import { Card, Col, Row, Typography } from "antd";
import classes from './TermsOfUse.module.scss';

const { Title } = Typography;

const TermsOfUse = (props) => {

    return (
        <Card bordered={true} className={classes.card} size="small">
            <Row gutter={[4, 4]}>
                <Col span={24}>
                    <Title level={2} style={{ textAlign: 'center' }}>Terms of Use</Title>
                </Col>
                <Col sm={{ span: 24 }} xs={{ span: 24 }}>
                    <p style={{ textAlign: 'center' }}><strong>IRIS TECHNOLOGIES, INC.</strong></p>
                    <p style={{ textAlign: 'center' }}><strong>TERMS OF SERVICE</strong></p>
                    <p style={{ textAlign: 'center' }}>Effective Date: April 15, 2020</p>
                    <p>These Terms of Service (&ldquo;Terms&rdquo;) describe the terms that you agree to when you
                    access or use a website or mobile application provided by Iris Technologies, Inc.
                    (the &ldquo;Iris Platform&rdquo;), including any related application, content, download,
                            product or service (the &ldquo;Services&rdquo;). <strong>READ THESE TERMS BEFORE PROCEEDING.
                        BY ACCESSING OR USING THE IRIS PLATFORM OR THE SERVICES, YOU AGREE THAT THESE TERMS
                                CREATE AN ENFORCEABLE CONTRACT BETWEEN YOU AND IRIS TECHNOLOGIES, INC.</strong>
                        <strong>(&lsquo;IRIS TECHNOLIGIES&rdquo;). </strong>
                    </p>
                    <p>If you do not want to be contractually bound to these Terms, do not access or use the Iris
                    Platform or the Services. Your contractual relationship with Iris Technologies additionally
                    may be governed by the terms of a &ldquo;Broker-Shipper Transportation Agreement&rdquo; or
                    a &ldquo;Broker-Carrier Transportation Agreement.&rdquo;
                        </p>
                    <p>The Iris Platform and the Services facilitate the connection of people and companies who have
                    cargo to be transported (a &ldquo;Shipper&rdquo;) with people and companies capable of
                            transporting cargo (a &ldquo;Motor Carrier&rdquo; or &ldquo;Carrier&rdquo;). <strong>IRIS
                        TECHNOLOGIES IS NOT A CARRIER OR A FREIGHT FORWARDER. IT IS SOLELY A FREIGHT BROKER,
                        MEANING THAT IT HELPS ARRANGE THE TRANSPORTATION OF CARGO BY A MOTOR CARRIER FOR
                                COMPENSATION.</strong>
                    </p>
                    <p><strong>IRIS TECHNOLOGIES </strong><strong>DOES NOT TAKE POSSESSION OR CONTROL OF CARGO. IT
                    DOES NOT ASSUME ANY LIABILITY OR RESPONSIBILITY, FINANCIAL OR OTHERWISE, FOR CARGO,
                            INCLUDING ITS LOSS, THEFT, DAMAGE OR DELAYED DELIVERY.</strong>
                    </p>
                    <p><strong>Definitions</strong>. The words &ldquo;You&rdquo; and &ldquo;Your&rdquo; include the
                            individual accessing and using the Iris Platform or Services as well as any business or
                            organization on whose behalf the individual is acting.
                        </p>
                    <p><strong>Privacy Policy. </strong>How Iris Technologies collects and utilizes the personal
                            information of users of the Iris Platform and Services is described in the <a
                            href="https://irisfreight.com/privacy" target="_blank">Privacy Policy</a>&nbsp;found at
                            www.irisfreight.com/privacy. By continuing to use the Iris Platform and Services, you agree
                            and consent to the collection and use of your Personal Information as set forth in that <a
                            href="https://irisfreight.com/privacy" target="_blank">Privacy Policy</a>.
                        </p>
                    <p><strong>Cookies</strong>. Iris Technologies uses cookies and other identification
                            technologies with its Platform and Services. Review our <a href="https://irisfreight.com/cookies"
                            target="_blank">Cookie
                                Notice</a>&nbsp;for an explanation of how we use them or may use them in the
                            future.&nbsp;
                        </p>
                    <p><strong>Amendments</strong>. Iris Technologies may amend these Terms at any time. An
                            amendment will become effective when the updated version of these Terms is posted on the
                            Iris Platform. You should review the Terms on the Iris Platform often. By accessing and
                            using the Iris Platform or Services after an amendment, you agree to be contractually bound
                            by the Terms as amended.
                        </p>
                    <p><strong>Limited License.</strong> Provided you comply with these Terms, you are granted a
                            limited, non-exclusive, non-transferable, revocable license to access and use the Iris
                            Platform and Services (the &ldquo;License&rdquo;). Iris Technologies may revoke this License
                            or deny you access or use of the Iris Platform or Services at any time and for any reason,
                            without giving you prior notice. Iris Technologies owns the Iris Platform and Services and
                            all intellectual property related thereto, including but not limited to trademarks, service
                            marks, trade names, logos and domain names. Except for this limited License, Iris
                            Technologies reserves all its intellectual property rights relating to and involved with the
                            Iris Platform and Services.
                        </p>
                    <p><strong>Permitted Uses.</strong> You may use the Iris Platform and Services to become an Iris
                            Technologies approved shipper or carrier. Once approved, shippers and carriers, including
                            their employees, agents and independent contractors (<em>e.g</em>., drivers), may use the
                            Iris Platform and Services in connection with matters related to the transportation of
                            freight (including arranging transportation) and for transactions contemplated by
                            their &ldquo;Broker-Shipper Transportation Agreement&rdquo; or &ldquo;Broker-Carrier
                            Transportation Agreement.&rdquo;
                        </p>
                    <p><strong>Prohibited Uses</strong>: Without modifying the limited scope of the License, you are
                            expressly prohibited from using the Iris Platform and Services:
                        </p>
                    <ul>
                        <li>to commit a crime or unlawful act (&ldquo;unlawful act&rdquo; includes any act for which
                        a civil penalty or sanction may be imposed).
                            </li>
                        <li>to directly or indirectly assist a third party in the commission of a crime or unlawful
                        act.
                            </li>
                        <li>violate the personal or contractual rights of a third party.</li>
                        <li>upload, transmit or distribute any computer viruses, worms, or any software intended to
                        damage or alter a computer system or data.
                            </li>
                        <li>sell, rent, lease, transfer, assign, distribute, republish, host, license or otherwise
                        commercially exploit the Iris Platform or Services except as allowed by the License.
                            </li>
                        <li>copy, reproduce, modify, make derivative works of, disassemble, reverse compile or
                        reverse engineer any part of the Iris Platform or Services.
                            </li>
                        <li>use or assist in the use of any program or script for the purpose of scraping, indexing,
                        surveying, or otherwise data mining any portion of the Iris Platform or the Services.
                            </li>
                        <li>unduly burden or hinder the operation of any portion of the Iris Platform or the
                        Services.
                            </li>
                    </ul>
                    <p><strong>Disclaimer.</strong> THE Iris Platform and Services ARE PROVIDED &ldquo;AS
                            IS&rdquo; AND &ldquo;AS AVAILABLE.&rdquo; IRIS TECHNOLOGIES DISCLAIMS ALL WARRANTIES,
                            EXPRESS, IMPLIED OR STATUTORY, INCLUDING THE IMPLIED WARRANTY OF FITNESS FOR A PARTICULAR
                            PURPOSE.
                        </p>
                    <p>IRIS TECHNOLOGIES MAKES NO REPRESENTATION, WARRANTY OR GUARANTEE REGARDING THE RELIABILITY,
                    TIMELINESS, QUALITY, SUITABILITY OR AVAILABILITY OF THE Iris Platform and Services, OR THAT
                    THE Iris Platform and Services WILL BE UNINTERRUPTED OR ERROR-FREE.
                        </p>
                    <p>IRIS TECHNOLOGIES FURTHER MAKES NO REPRESENTATION, WARRANTY OR GUARANTEE AS TO THE ACCURACY
                    OF THE INFORMATION ON OR PROVIDED BY Iris Platform and Services.
                        </p>
                    <p>IRIS TECHNOLOGIES CANNOT AND DOES NOT REPRESENT OR WARRANT THAT THE Iris Platform, Services
                    OR ITS SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS
                        </p>
                    <p><strong>Limitation of Liability.</strong> IRIS TECHNOLOGIES SHALL NOT BE LIABLE FOR INDIRECT,
                            INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO LOST
                            PROFITS, LOST DATA, PERSONAL INJURY OR PROPERTY DAMAGE RELATED TO, IN CONNECTION WITH OR
                            OTHERWISE RESULTING FROM USE OF THE Iris Platform and Services, EVEN IF IRIS TECHNOLOGIES
                            HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                        </p>
                    <p>IRIS TECHNOLOGIES SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITY OR LOSSES ARISING OUT OF
                    RELIANCE ON THE Iris Platform and Services OR INABILITY TO ACCESS or use THE Iris Platform
                    and Services.
                        </p>
                    <p>YOU AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF THE Iris Platform and Services
                    REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW.
                        </p>
                    <p><strong>Indemnity</strong>. You agree to defend, indemnify, save and hold harmless Iris
                            Technologies and its directors, officers, employees and agents from and against all
                            liabilities, claims, damages and expenses, including reasonable attorneys&rsquo; fees as
                            incurred, arising out of your use of the Iris Platform or the Services, including any
                            violation or alleged violation of these Terms.
                        </p>
                    <p><strong>Telephone Calls. </strong>You agree that Iris Technologies may contact you by
                            telephone (including by an automatic telephone dialing system and/or with an artificial or
                            pre-recorded voice) at any phone number provided by you or on your behalf, including for
                            marketing purposes.
                        </p>
                    <p><strong>Network Access and Devices</strong>. You are responsible for obtaining the data
                            network access necessary to use the Iris Platform and Services. Mobile network data rates
                            and fees may apply if you access or use the Iris Platform and Services from a mobile device.
                            You are responsible for acquiring and updating compatible hardware or devices necessary to
                            access and use the Iris Platform and Services. Iris Technologies does not guarantee that the
                            Iris Platform and Services will function on any particular hardware or device. <u>The Iris
                        Platform and Services may be subject to malfunctions and delays inherent in the use of
                                the Internet and electronic communications</u>.
                        </p>
                    <p><strong>Severability</strong>. If for any reason any provision of these Terms is determined
                            to be invalid or unenforceable, the parties intend and agree that the remaining Terms shall
                            be valid and enforceable. Any provision of these Terms determined to be invalid or
                            unenforceable shall be disregarded and the remaining provisions enforced.
                        </p>
                    <p><strong>Non-Waiver</strong>. Iris Technologies&rsquo; delay in enforcing or failure to
                            enforce any right or provision in these Terms does not constitute a waiver of such right or
                            provision unless acknowledged and agreed to by Iris Technologies in writing.
                        </p>
                    <p><strong>Applicable Law and Forum</strong>. The laws of the state of Arizona govern the
                            interpretation and enforcement of these Terms. Any action or proceeding arising from or
                            relating to this Terms must be brought in Federal District Court or Arizona Superior Court
                            in Maricopa County, Arizona. You agree and consent to the jurisdiction of such courts.
                        </p>
                    <p><strong>Attorneys&rsquo; Fees</strong>. In any action or proceeding arising from or relating
                            to this Agreement, the prevailing party is entitled to recover the expenses incurred in the
                            action or proceeding, including reasonable attorneys&rsquo; fees.
                        </p>
                </Col>
            </Row>
        </Card>
    );
};

export default TermsOfUse;
