import React from 'react';
import constructionImage from '../../assets/img/backgrounds/under-construction.jpg';
import classes from './UnderConstruction.module.scss';

const UnderConstruction = (props) => {
    return (
        <div className={classes.underConstruction} style={{ backgroundImage: `url(${constructionImage})` }}>
            <div className={classes.underConstructionContent}>
                &nbsp;
            </div>
        </div>
    );
};

export default UnderConstruction;