import React, { useState, useMemo } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Alert, Spin, Tooltip } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import UploadFile from "../../components/UploadFile/UploadFile";
import Form from '../Form/Form';
import FormItemFile from '../FormItemFile/FormItemFile';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import { isListNotEmpty } from '../../shared/objectUtils';

const EditStopStatusCompleted = ({ stop, load, cancel }) => {
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loadStops.isRecordUpdateLoading);
    const error = useSelector(state => state.loadStops.updateRecordError);

    //#endregion
    //#region useStates

    const [docFiles, setDocFiles] = useState([]);
    const [scaleTicketFiles, setScaleTicketFiles] = useState([]);
    const [bolFiles, setBolFiles] = useState([]);

    const [docs, setDocs] = useState([]);
    const [scaleTickets, setScaleTickets] = useState([]);
    const [BOLs, setBOLs] = useState([]);

    //#endregion
    //#region document methods

    const addDoc = () => {
        // Get last item in array
        if (docs.length > 0) {
            let value = docs[docs.length - 1];
            value++;
            const list = [...docs, value];
            setDocs(list);
        } else {
            const list = [0];
            setDocs(list);
        }
    };

    const addBOL = () => {
        // Get last item in array
        if (BOLs.length > 0) {
            let value = BOLs[BOLs.length - 1];
            value++;
            const list = [...BOLs, value];
            setBOLs(list);
        } else {
            const list = [0];
            setBOLs(list);
        }
    };

    const addScaleTicket = () => {
        // Get last item in array
        if (scaleTickets.length > 0) {
            let value = scaleTickets[scaleTickets.length - 1];
            value++;
            const list = [...scaleTickets, value];
            setScaleTickets(list);
        } else {
            const list = [0];
            setScaleTickets(list);
        }
    };

    //#endregion
    //#region onSubmit and onCancel

    const onSubmit = (data) => {
        // if (stop.stopType === "DROP_OFF") {
        //     if (bolFiles.length === 0) {
        //         methods.setError("bolFiles", { type: "required", message: "BOL File(s) must be attached"});
        //         return;
        //     } else {
        //         methods.clearErrors("bolFiles");
        //     }
        // } else if (stop.stopType === "PICK_UP") {
        //     if (scaleTicketFiles.length === 0) {
        //         methods.setError("scaleTicketFiles", { type: "required", message: "Scale Ticket(s) must be attached"});
        //     } else {
        //         methods.clearErrors("scaleTicketFiles");
        //     }

        //     if (bolFiles.length === 0) {
        //         methods.setError("bolFiles", { type: "required", message: "BOL File(s) must be attached"});
        //     } else {
        //         methods.clearErrors("bolFiles");
        //     }

        //     if (scaleTicketFiles.length === 0 || bolFiles.length === 0) {
        //         return;
        //     }
        // }

        if (isListNotEmpty(docFiles)) {
            data.docFiles = docFiles;
        }
        if (isListNotEmpty(scaleTicketFiles)) {
            data.scaleTicketFiles = scaleTicketFiles;
        }
        if (isListNotEmpty(bolFiles)) {
            data.bolFiles = bolFiles;
        }

        dispatch(actionCreators.completeLoadStop(data, stop, load));
    };

    const onCancel = () => {
        dispatch(actionCreators.updateLoadEventComplete());
        cancel();
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLoadEventErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    //#endregion

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Fieldset legend="Stop is Complete">
                        <div><b>Please attach all necessary documents for this Stop. Change the Status of this Stop to Completed.</b></div>
                        <FormItemFile {...formItemLayout} label="BOL(s)" name="bolFiles">
                            {BOLs.map((item, index) => (
                                <div style={{ margin: "10px" }} key={`bol-file-${index}`}>
                                    <UploadFile
                                        beforeUpload={(file) => {
                                            setBolFiles([...bolFiles, file]);
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            let filteredArray = bolFiles.filter(item => item !== file);
                                            setBolFiles([...filteredArray]);
                                        }}
                                        displayName="BOL #"
                                        displayNameInputName={`bolDocumentDisplayName${item}`}
                                        message="Please upload a photo of the BOL."
                                    />
                                </div>
                            ))}
                            <Tooltip placement="top" title="Add BOL"><Button key="addBOL" type="primary" icon={<PlusOutlined />} shape='circle' onClick={addBOL} style={{ margin: "10px", float: "right" }} /></Tooltip>
                        </FormItemFile>
                        <FormItemFile {...formItemLayout} label="Scale Ticket(s)" name="scaleTicketFiles">
                            {scaleTickets.map((item, index) => (
                                <div style={{ margin: "10px" }} key={`scale-ticket-file-${index}`}>
                                    <UploadFile
                                        beforeUpload={(file) => {
                                            setScaleTicketFiles([...scaleTicketFiles, file]);
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            let filteredArray = scaleTicketFiles.filter(item => item !== file);
                                            setScaleTicketFiles([...filteredArray]);
                                        }}
                                        displayName="Scale Ticket"
                                        displayNameInputName={`scaleTicketDocumentDisplayName${item}`}
                                        message="Please upload a photo of the Scale Ticket."
                                    />
                                </div>
                            ))}
                            <Tooltip placement="top" title="Add Scale Ticket"><Button key="addScaleTicket" type="primary" icon={<PlusOutlined />} shape='circle' onClick={addScaleTicket} style={{ margin: "10px", float: "right" }} /></Tooltip>
                        </FormItemFile>
                        <FormItemFile {...formItemLayout} label="Receipts and Other Documents" name="docFiles">
                            {docs.map((item, index) => (
                                <div style={{ margin: "10px" }} key={`other-document-file-${index}`}>
                                    <UploadFile
                                        beforeUpload={(file) => {
                                            setDocFiles([...docFiles, file]);
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            let filteredArray = docFiles.filter(item => item !== file);
                                            setDocFiles([...filteredArray]);
                                        }}
                                        documentEntityType="STOP"
                                        documentTypeInputName={`otherDocumentType${item}`}
                                        displayNameInputName={`otherDocumentDisplayName${item}`}
                                        descriptionInputName={`otherDocumentDescription${item}`}
                                        message="Please upload a photo of the Receipt or Other Document."
                                    />
                                </div>
                            ))}
                            <Tooltip placement="top" title="Add Receipt or Other Document"><Button key="addDoc" type="primary" icon={<PlusOutlined />} shape='circle' onClick={addDoc} style={{ margin: "10px", float: "right" }} /></Tooltip>
                        </FormItemFile>
                    </Fieldset>
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Complete the Stop" />
            </Form>
        </FormProvider>
    );
};

export default EditStopStatusCompleted;