import axiosAuthenticated from "../../api/axiosAuthenticated";
import { isListNotEmpty, isNotNullOrUndefined, isStringNotEmpty } from "../../shared/objectUtils";
import * as DTO from "../dtos/index";

const locationsPath = '/locations';
const locationsBulkPath = '/locations/bulk/get';

//#region Locations Methods

export const getLocationsByIds = async (ids = [], accountId = null, linkedAccountId = null) => {
    if (isListNotEmpty(ids)) {
        const locationsRes = await axiosAuthenticated.post(locationsBulkPath, { page: 1, size: 1000000, isDeleted: false, id: [...ids] });
        if (locationsRes && locationsRes.status === 200) {
            let locations = locationsRes.data.data;

            let locationArray = [];
            locations.forEach((location) => {
                let locationLinkArray = DTO.getLocationDTOs(location, null, null, accountId, linkedAccountId);
                locationLinkArray.forEach((locationLink) => {
                    locationArray.push(locationLink);
                });
            });

            return locationArray;
        }
    }

    return [];
};

export const getLocationsByAccountId = async (accountId, accounts = [], commodities = [], linkedAccountId = null) => {
    if (isStringNotEmpty(accountId)) {
        const locationsRes = await axiosAuthenticated.post(locationsBulkPath, { page: 1, size: 1000000, isDeleted: false, links: { '$elemMatch': { accountId: accountId } } });
        if (locationsRes && locationsRes.status === 200) {
            let locations = locationsRes.data.data;

            let locationArray = [];
            locations.forEach((location) => {
                let locationLinkArray = DTO.getLocationDTOs(location, accounts, commodities, accountId, linkedAccountId);
                locationLinkArray.forEach((locationLink) => {
                    locationArray.push(locationLink);
                });
            });

            return locationArray;
        }
    }

    return [];
};

export const getBulkLocations = async (searchParams = {}, accountId = null, linkedAccountId = null) => {
    const locationsRes = await axiosAuthenticated.post(locationsBulkPath, { page: 1, size: 1000000, isDeleted: false, ...searchParams });
    if (locationsRes && locationsRes.status === 200) {
        let locations = locationsRes.data.data;

        let locationArray = [];
        locations.forEach((location) => {
            let locationLinkArray = DTO.getLocationDTOs(location, null, null, accountId, linkedAccountId);
            locationLinkArray.forEach((locationLink) => {
                locationArray.push(locationLink);
            });
        });

        return locationArray;
    }

    return [];
};

export const getLocations = async (searchParams = {}, pagination = {}, accounts = [], commodities = [], accountId = null, linkedAccountId = null) => {
    const locationsRes = await axiosAuthenticated.post(locationsBulkPath, { ...searchParams });
    if (locationsRes && locationsRes.status === 200) {
        const locations = locationsRes.data.data;
        const otherData = locationsRes.data;

        let transformedLocations = [];
        locations.forEach((location) => {
            let locationLinkArray = DTO.getLocationDTOs(location, accounts, commodities, accountId, linkedAccountId);
            locationLinkArray.forEach((locationLink) => {
                transformedLocations.push(locationLink);
            });
        });

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;

        return { data: transformedLocations, searchParams: searchParams, pagination: pagination };
    }

    return { data: [], searchParams: searchParams, pagination: pagination };
};

export const getLocation = async (id, accounts = [], commodities = [], accountId = null, linkedAccountId = null) => {
    const locationsRes = await axiosAuthenticated.get(locationsPath + `/${id}`);
    if (locationsRes && locationsRes.status === 200) {
        let location = locationsRes.data;

        return DTO.getLocationDTO(location, accounts, commodities, accountId, linkedAccountId);
    }

    return null;
};

export const addLocation = async (payload, accounts = [], commodities = []) => {
    payload = DTO.getAddLocationRequestDTO(payload);

    const locationsRes = await axiosAuthenticated.post(locationsPath, { ...payload });
    if (locationsRes && locationsRes.status === 201) {
        let newLocation = locationsRes.data;

        return DTO.getLocationDTO(newLocation, accounts, commodities, payload.accountId, payload.linkedAccountId);
    }

    return null;
};

export const updateLocation = async (id, payload, accounts = [], commodities = []) => {
    payload = DTO.getUpdateLocationRequestDTO(payload);

    const locationsRes = await axiosAuthenticated.put(locationsPath + `/${id}`, { ...payload });
    if (locationsRes && locationsRes.status === 200) {
        let updatedLocation = locationsRes.data;

        return DTO.getLocationDTO(updatedLocation, accounts, commodities, payload.accountId, payload.linkedAccountId);
    }

    return null;
};

export const removeLocation = async (id) => {
    const locationsRes = await axiosAuthenticated.put(locationsPath + `/${id}`, { isDeleted: true });
    if (locationsRes && locationsRes.status === 200) {
        return locationsRes.data;
    }

    return null;
};

//#endregion