import Checks from '../entitlementUtils';

export const canCreateLocation = (auth, accountId) => {
    return Checks.allowStaff(auth) || Checks.isAccountOperationsAdmin(auth, accountId);
};

export const canReadLocation = (auth, accountId) => {
    return Checks.allowStaff(auth) || Checks.isAccountOperationsAdmin(auth, accountId);
};

export const canReadLocationList = (auth, accountId) => {
    return Checks.allowStaff(auth) || Checks.isAccountOperationsAdmin(auth, accountId);
};

export const canUpdateLocation = (auth, accountId) => {
    return Checks.allowStaff(auth) || Checks.isAccountOperationsAdmin(auth, accountId);
};

export const canDeleteLocation = (auth) => {
    return Checks.allowStaffAdmin(auth);
};