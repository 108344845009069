export const CREATE_ACCOUNT = "CREATE_ACCOUNT";
export const READ_ACCOUNT = "READ_ACCOUNT";
export const READ_LIST_ACCOUNT = "READ_LIST_ACCOUNT";
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const VERIFY_ACCOUNT = "VERIFY_ACCOUNT";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const READ_SHIPPER = "READ_SHIPPER";
export const READ_LIST_SHIPPER = "READ_LIST_SHIPPER";
export const READ_CARRIER = "READ_CARRIER";
export const READ_LIST_CARRIER = "READ_LIST_CARRIER";
export const READ_PRODUCER = "READ_PRODUCER";
export const READ_LIST_PRODUCER = "READ_LIST_PRODUCER";
export const READ_RECEIVER = "READ_RECEIVER";
export const READ_LIST_RECEIVER = "READ_LIST_RECEIVER";
export const READ_BROKER = "READ_BROKER";
export const READ_LIST_BROKER = "READ_LIST_BROKER";
export const READ_FACTORING = "READ_FACTORING";
export const READ_LIST_FACTORING = "READ_LIST_FACTORING";

export const CREATE_ACCOUNT_REGISTRATION = "CREATE_ACCOUNT_REGISTRATION";
export const READ_ACCOUNT_REGISTRATION = "READ_ACCOUNT_REGISTRATION";
export const READ_LIST_ACCOUNT_REGISTRATION = "READ_LIST_ACCOUNT_REGISTRATION";
export const UPDATE_ACCOUNT_REGISTRATION = "UPDATE_ACCOUNT_REGISTRATION";
export const DELETE_ACCOUNT_REGISTRATION = "DELETE_ACCOUNT_REGISTRATION";

export const CREATE_ACCOUNT_USER = "CREATE_ACCOUNT_USER";
export const READ_ACCOUNT_USER = "READ_ACCOUNT_USER";
export const READ_LIST_ACCOUNT_USER = "READ_LIST_ACCOUNT_USER";
export const UPDATE_ACCOUNT_USER = "UPDATE_ACCOUNT_USER";
export const VERIFY_ACCOUNT_USER = "VERIFY_ACCOUNT_USER";
export const DELETE_ACCOUNT_USER = "DELETE_ACCOUNT_USER";

export const CREATE_ASSET = "CREATE_ASSET";
export const READ_ASSET = "READ_ASSET";
export const READ_LIST_ASSET = "READ_LIST_ASSET";
export const UPDATE_ASSET = "UPDATE_ASSET";
export const DELETE_ASSET = "DELETE_ASSET";

export const CREATE_CARRIER_REJECTION = "CREATE_CARRIER_REJECTION";
export const READ_CARRIER_REJECTION = "READ_CARRIER_REJECTION";
export const READ_LIST_CARRIER_REJECTION = "READ_LIST_CARRIER_REJECTION";
export const UPDATE_CARRIER_REJECTION = "UPDATE_CARRIER_REJECTION";
export const DELETE_CARRIER_REJECTION = "DELETE_CARRIER_REJECTION";

export const CREATE_CLAIM = "CREATE_CLAIM";
export const READ_CLAIM = "READ_CLAIM";
export const READ_LIST_CLAIM = "READ_LIST_CLAIM";
export const UPDATE_CLAIM = "UPDATE_CLAIM";
export const DELETE_CLAIM = "DELETE_CLAIM";

export const CREATE_COMMODITY = "CREATE_COMMODITY";
export const READ_COMMODITY = "READ_COMMODITY";
export const READ_LIST_COMMODITY = "READ_LIST_COMMODITY";
export const UPDATE_COMMODITY = "UPDATE_COMMODITY";
export const DELETE_COMMODITY = "DELETE_COMMODITY";

export const CREATE_CONFIGURATION = "CREATE_CONFIGURATION";
export const READ_CONFIGURATION = "READ_CONFIGURATION";
export const READ_LIST_CONFIGURATION = "READ_LIST_CONFIGURATION";
export const UPDATE_CONFIGURATION = "UPDATE_CONFIGURATION";
export const DELETE_CONFIGURATION = "DELETE_CONFIGURATION";

export const CREATE_CONTENT = "CREATE_CONTENT";
export const READ_CONTENT = "READ_CONTENT";
export const READ_LIST_CONTENT = "READ_LIST_CONTENT";
export const UPDATE_CONTENT = "UPDATE_CONTENT";
export const DELETE_CONTENT = "DELETE_CONTENT";

export const CREATE_DOCUMENT = "CREATE_DOCUMENT";
export const READ_DOCUMENT = "READ_DOCUMENT";
export const READ_LIST_DOCUMENT = "READ_LIST_DOCUMENT";
export const UPDATE_DOCUMENT = "UPDATE_DOCUMENT";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";

export const CREATE_FEATURE = "CREATE_FEATURE";
export const READ_FEATURE = "READ_FEATURE";
export const READ_LIST_FEATURE = "READ_LIST_FEATURE";
export const UPDATE_FEATURE = "UPDATE_FEATURE";
export const DELETE_FEATURE = "DELETE_FEATURE";

export const CREATE_INVOICE = "CREATE_INVOICE";
export const READ_INVOICE = "READ_INVOICE";
export const READ_LIST_INVOICE = "READ_LIST_INVOICE";
export const UPDATE_INVOICE = "UPDATE_INVOICE";
export const DELETE_INVOICE = "DELETE_INVOICE";

export const CREATE_INVOICE_LINE_ITEM = "CREATE_INVOICE_LINE_ITEM";
export const READ_INVOICE_LINE_ITEM = "READ_INVOICE_LINE_ITEM";
export const READ_LIST_INVOICE_LINE_ITEM = "READ_LIST_INVOICE_LINE_ITEM";
export const UPDATE_INVOICE_LINE_ITEM = "UPDATE_INVOICE_LINE_ITEM";
export const DELETE_INVOICE_LINE_ITEM = "DELETE_INVOICE_LINE_ITEM";

export const CREATE_LANE = "CREATE_LANE";
export const READ_LANE = "READ_LANE";
export const READ_LIST_LANE = "READ_LIST_LANE";
export const UPDATE_LANE = "UPDATE_LANE";
export const DELETE_LANE = "DELETE_LANE";

export const CREATE_LINE_ITEM_TYPE = "CREATE_LINE_ITEM_TYPE";
export const READ_LINE_ITEM_TYPE = "READ_LINE_ITEM_TYPE";
export const READ_LIST_LINE_ITEM_TYPE = "READ_LIST_LINE_ITEM_TYPE";
export const UPDATE_LINE_ITEM_TYPE = "UPDATE_LINE_ITEM_TYPE";
export const DELETE_LINE_ITEM_TYPE = "DELETE_LINE_ITEM_TYPE";

export const CREATE_LINKED_ACCOUNT = "CREATE_LINKED_ACCOUNT";
export const READ_LINKED_ACCOUNT = "READ_LINKED_ACCOUNT";
export const READ_LIST_LINKED_ACCOUNT = "READ_LIST_LINKED_ACCOUNT";
export const UPDATE_LINKED_ACCOUNT = "UPDATE_LINKED_ACCOUNT";
export const DELETE_LINKED_ACCOUNT = "DELETE_LINKED_ACCOUNT";

export const CREATE_LINKED_LOAD_LANE = "CREATE_LINKED_LOAD_LANE";
export const READ_LINKED_LOAD_LANE = "READ_LINKED_LOAD_LANE";
export const READ_LIST_LINKED_LOAD_LANE = "READ_LIST_LINKED_LOAD_LANE";
export const UPDATE_LINKED_LOAD_LANE = "UPDATE_LINKED_LOAD_LANE";
export const DELETE_LINKED_LOAD_LANE = "DELETE_LINKED_LOAD_LANE";

export const CREATE_LINKED_LOCATION = "CREATE_LINKED_LOCATION";
export const READ_LINKED_LOCATION = "READ_LINKED_LOCATION";
export const READ_LIST_LINKED_LOCATION = "READ_LIST_LINKED_LOCATION";
export const UPDATE_LINKED_LOCATION = "UPDATE_LINKED_LOCATION";
export const DELETE_LINKED_LOCATION = "DELETE_LINKED_LOCATION";

export const CREATE_LOAD_ASSET = "CREATE_LOAD_ASSET";
export const READ_LOAD_ASSET = "READ_LOAD_ASSET";
export const READ_LIST_LOAD_ASSET = "READ_LIST_LOAD_ASSET";
export const UPDATE_LOAD_ASSET = "UPDATE_LOAD_ASSET";
export const DELETE_LOAD_ASSET = "DELETE_LOAD_ASSET";

export const CREATE_LOAD_CANCELLATION = "CREATE_LOAD_CANCELLATION";
export const READ_LOAD_CANCELLATION = "READ_LOAD_CANCELLATION";
export const READ_LIST_LOAD_CANCELLATION = "READ_LIST_LOAD_CANCELLATION";
export const UPDATE_LOAD_CANCELLATION = "UPDATE_LOAD_CANCELLATION";
export const DELETE_LOAD_CANCELLATION = "DELETE_LOAD_CANCELLATION";

export const CREATE_LOAD_CARRIER = "CREATE_LOAD_CARRIER";
export const READ_LOAD_CARRIER = "READ_LOAD_CARRIER";
export const READ_LIST_LOAD_CARRIER = "READ_LIST_LOAD_CARRIER";
export const UPDATE_LOAD_CARRIER = "UPDATE_LOAD_CARRIER";
export const DELETE_LOAD_CARRIER = "DELETE_LOAD_CARRIER";

export const CREATE_LOAD_CARRIER_INVOICE = "CREATE_LOAD_CARRIER_INVOICE";
export const READ_LOAD_CARRIER_INVOICE = "READ_LOAD_CARRIER_INVOICE";
export const READ_LIST_LOAD_CARRIER_INVOICE = "READ_LIST_LOAD_CARRIER_INVOICE";
export const UPDATE_LOAD_CARRIER_INVOICE = "UPDATE_LOAD_CARRIER_INVOICE";
export const DELETE_LOAD_CARRIER_INVOICE = "DELETE_LOAD_CARRIER_INVOICE";

export const READ_LOAD_COMPLETION_CHECKLIST = "READ_LOAD_COMPLETION_CHECKLIST";
export const UPDATE_LOAD_COMPLETION_CHECKLIST = "UPDATE_LOAD_COMPLETION_CHECKLIST";

export const CREATE_LOAD_DRIVER = "CREATE_LOAD_DRIVER";
export const READ_LOAD_DRIVER = "READ_LOAD_DRIVER";
export const READ_LIST_LOAD_DRIVER = "READ_LIST_LOAD_DRIVER";
export const UPDATE_LOAD_DRIVER = "UPDATE_LOAD_DRIVER";
export const DELETE_LOAD_DRIVER = "DELETE_LOAD_DRIVER";

export const CREATE_LOAD_EVENT = "CREATE_LOAD_EVENT";
export const READ_LOAD_EVENT = "READ_LOAD_EVENT";
export const READ_LIST_LOAD_EVENT = "READ_LIST_LOAD_EVENT";
export const UPDATE_LOAD_EVENT = "UPDATE_LOAD_EVENT";
export const DELETE_LOAD_EVENT = "DELETE_LOAD_EVENT";

export const CREATE_LOAD_INVOICE = "CREATE_LOAD_INVOICE";
export const READ_LOAD_INVOICE = "READ_LOAD_INVOICE";
export const READ_LIST_LOAD_INVOICE = "READ_LIST_LOAD_INVOICE";
export const UPDATE_LOAD_INVOICE = "UPDATE_LOAD_INVOICE";
export const DELETE_LOAD_INVOICE = "DELETE_LOAD_INVOICE";

export const CREATE_LOAD_INVOICE_LINE_ITEM = "CREATE_LOAD_INVOICE_LINE_ITEM";
export const READ_LOAD_INVOICE_LINE_ITEM = "READ_LOAD_INVOICE_LINE_ITEM";
export const READ_LIST_LOAD_INVOICE_LINE_ITEM = "READ_LIST_LOAD_INVOICE_LINE_ITEM";
export const UPDATE_LOAD_INVOICE_LINE_ITEM = "UPDATE_LOAD_INVOICE_LINE_ITEM";
export const DELETE_LOAD_INVOICE_LINE_ITEM = "DELETE_LOAD_INVOICE_LINE_ITEM";

export const CREATE_LOAD_LANE = "CREATE_LOAD_LANE";
export const READ_LOAD_LANE = "READ_LOAD_LANE";
export const READ_LIST_LOAD_LANE = "READ_LIST_LOAD_LANE";
export const UPDATE_LOAD_LANE = "UPDATE_LOAD_LANE";
export const DELETE_LOAD_LANE = "DELETE_LOAD_LANE";

export const CREATE_LOAD_LOCATION = "CREATE_LOAD_LOCATION";
export const READ_LOAD_LOCATION = "READ_LOAD_LOCATION";
export const READ_LIST_LOAD_LOCATION = "READ_LIST_LOAD_LOCATION";
export const UPDATE_LOAD_LOCATION = "UPDATE_LOAD_LOCATION";
export const DELETE_LOAD_LOCATION = "DELETE_LOAD_LOCATION";

export const CREATE_LOAD_MESSAGE = "CREATE_LOAD_MESSAGE";
export const READ_LOAD_MESSAGE = "READ_LOAD_MESSAGE";
export const READ_LIST_LOAD_MESSAGE = "READ_LIST_LOAD_MESSAGE";
export const UPDATE_LOAD_MESSAGE = "UPDATE_LOAD_MESSAGE";
export const DELETE_LOAD_MESSAGE = "DELETE_LOAD_MESSAGE";

export const CREATE_LOAD_NOTIFICATION = "CREATE_LOAD_NOTIFICATION";
export const READ_LOAD_NOTIFICATION = "READ_LOAD_NOTIFICATION";
export const READ_LIST_LOAD_NOTIFICATION = "READ_LIST_LOAD_NOTIFICATION";
export const UPDATE_LOAD_NOTIFICATION = "UPDATE_LOAD_NOTIFICATION";
export const DELETE_LOAD_NOTIFICATION = "DELETE_LOAD_NOTIFICATION";

export const CREATE_LOAD = "CREATE_LOAD";
export const READ_LOAD = "READ_LOAD";
export const READ_LIST_LOAD = "READ_LIST_LOAD";
export const UPDATE_LOAD = "UPDATE_LOAD";
export const DELETE_LOAD = "DELETE_LOAD";
export const DUPLICATE_LOAD = "DUPLICATE_LOAD";

export const CREATE_LOAD_PRICE_CONFIRMATION = "CREATE_LOAD_PRICE_CONFIRMATION";
export const READ_LOAD_PRICE_CONFIRMATION = "READ_LOAD_PRICE_CONFIRMATION";
export const READ_LIST_LOAD_PRICE_CONFIRMATION = "READ_LIST_LOAD_PRICE_CONFIRMATION";
export const UPDATE_LOAD_PRICE_CONFIRMATION = "UPDATE_LOAD_PRICE_CONFIRMATION";
export const DELETE_LOAD_PRICE_CONFIRMATION = "DELETE_LOAD_PRICE_CONFIRMATION";

export const CREATE_LOAD_RATE_CONFIRMATION = "CREATE_LOAD_RATE_CONFIRMATION";
export const READ_LOAD_RATE_CONFIRMATION = "READ_LOAD_RATE_CONFIRMATION";
export const READ_LIST_LOAD_RATE_CONFIRMATION = "READ_LIST_LOAD_RATE_CONFIRMATION";
export const UPDATE_LOAD_RATE_CONFIRMATION = "UPDATE_LOAD_RATE_CONFIRMATION";
export const DELETE_LOAD_RATE_CONFIRMATION = "DELETE_LOAD_RATE_CONFIRMATION";

export const CREATE_LOAD_SHIPPER = "CREATE_LOAD_SHIPPER";
export const READ_LOAD_SHIPPER = "READ_LOAD_SHIPPER";
export const READ_LIST_LOAD_SHIPPER = "READ_LIST_LOAD_SHIPPER";
export const UPDATE_LOAD_SHIPPER = "UPDATE_LOAD_SHIPPER";
export const DELETE_LOAD_SHIPPER = "DELETE_LOAD_SHIPPER";

export const CREATE_LOAD_SHIPPER_INVOICE = "CREATE_LOAD_SHIPPER_INVOICE";
export const READ_LOAD_SHIPPER_INVOICE = "READ_LOAD_SHIPPER_INVOICE";
export const READ_LIST_LOAD_SHIPPER_INVOICE = "READ_LIST_LOAD_SHIPPER_INVOICE";
export const UPDATE_LOAD_SHIPPER_INVOICE = "UPDATE_LOAD_SHIPPER_INVOICE";
export const DELETE_LOAD_SHIPPER_INVOICE = "DELETE_LOAD_SHIPPER_INVOICE";

export const CREATE_LOAD_STOP = "CREATE_LOAD_STOP";
export const READ_LOAD_STOP = "READ_LOAD_STOP";
export const READ_LIST_LOAD_STOP = "READ_LIST_LOAD_STOP";
export const UPDATE_LOAD_STOP = "UPDATE_LOAD_STOP";
export const DELETE_LOAD_STOP = "DELETE_LOAD_STOP";

export const CREATE_LOAD_TRANSACTION = "CREATE_LOAD_TRANSACTION";
export const READ_LOAD_TRANSACTION = "READ_LOAD_TRANSACTION";
export const READ_LIST_LOAD_TRANSACTION = "READ_LIST_LOAD_TRANSACTION";
export const UPDATE_LOAD_TRANSACTION = "UPDATE_LOAD_TRANSACTION";
export const DELETE_LOAD_TRANSACTION = "DELETE_LOAD_TRANSACTION";

export const CREATE_LOCATION = "CREATE_LOCATION";
export const READ_LOCATION = "READ_LOCATION";
export const READ_LIST_LOCATION = "READ_LIST_LOCATION";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const DELETE_LOCATION = "DELETE_LOCATION";

export const CREATE_NOTE = "CREATE_NOTE";
export const READ_NOTE = "READ_NOTE";
export const READ_LIST_NOTE = "READ_LIST_NOTE";
export const UPDATE_NOTE = "UPDATE_NOTE";
export const DELETE_NOTE = "DELETE_NOTE";

export const CREATE_PREFERRED_LANE = "CREATE_PREFERRED_LANE";
export const READ_PREFERRED_LANE = "READ_PREFERRED_LANE";
export const READ_LIST_PREFERRED_LANE = "READ_LIST_PREFERRED_LANE";
export const UPDATE_PREFERRED_LANE = "UPDATE_PREFERRED_LANE";
export const DELETE_PREFERRED_LANE = "DELETE_PREFERRED_LANE";

export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const READ_PRODUCT = "READ_PRODUCT";
export const READ_LIST_PRODUCT = "READ_LIST_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";

export const CREATE_SCHEDULED_SHIFT = "CREATE_SCHEDULED_SHIFT";
export const READ_SCHEDULED_SHIFT = "READ_SCHEDULED_SHIFT";
export const READ_LIST_SCHEDULED_SHIFT = "READ_LIST_SCHEDULED_SHIFT";
export const UPDATE_SCHEDULED_SHIFT = "UPDATE_SCHEDULED_SHIFT";
export const DELETE_SCHEDULED_SHIFT = "DELETE_SCHEDULED_SHIFT";

export const CREATE_SERVICE = "CREATE_SERVICE";
export const READ_SERVICE = "READ_SERVICE";
export const READ_LIST_SERVICE = "READ_LIST_SERVICE";
export const UPDATE_SERVICE = "UPDATE_SERVICE";
export const DELETE_SERVICE = "DELETE_SERVICE";

export const CREATE_SHIFT = "CREATE_SHIFT";
export const READ_SHIFT = "READ_SHIFT";
export const READ_LIST_SHIFT = "READ_LIST_SHIFT";
export const UPDATE_SHIFT = "UPDATE_SHIFT";
export const DELETE_SHIFT = "DELETE_SHIFT";

export const CREATE_STATE = "CREATE_STATE";
export const READ_STATE = "READ_STATE";
export const READ_LIST_STATE = "READ_LIST_STATE";
export const UPDATE_STATE = "UPDATE_STATE";
export const DELETE_STATE = "DELETE_STATE";

export const CREATE_TRAILER_TYPE = "CREATE_TRAILER_TYPE";
export const READ_TRAILER_TYPE = "READ_TRAILER_TYPE";
export const READ_LIST_TRAILER_TYPE = "READ_LIST_TRAILER_TYPE";
export const UPDATE_TRAILER_TYPE = "UPDATE_TRAILER_TYPE";
export const DELETE_TRAILER_TYPE = "DELETE_TRAILER_TYPE";

export const CREATE_TRANSACTION = "CREATE_TRANSACTION";
export const UPDATE_TRANSACTION = "UPDATE_TRANSACTION";
export const READ_TRANSACTION = "READ_TRANSACTION";
export const READ_LIST_TRANSACTION = "READ_LIST_TRANSACTION";
export const DELETE_TRANSACTION = "DELETE_TRANSACTION";

export const CREATE_USER = "CREATE_USER";
export const READ_USER = "READ_USER";
export const READ_LIST_USER = "READ_LIST_USER";
export const UPDATE_USER = "UPDATE_USER";