import axiosAuthenticated from "../../api/axiosAuthenticated";
import { addFile, deleteFile } from "../../api/fileClient";
import { isListNotEmpty, isNotNullOrUndefined, isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import * as DTO from "../dtos/index";
import * as Data from "./index";

const assetsPath = '/assets';

//#region Assets Methods

export const getAssetsByAccountId = async (accountId, trailerTypes = []) => {
    let transformedAssets = [];
    if (isStringNotEmpty(accountId)) {
        const assetsRes = await axiosAuthenticated.get(assetsPath, { params: { page: 1, size: 1000000, carrierId: accountId, isDeleted: false } });
        if (assetsRes && assetsRes.status === 200) {
            const assets = assetsRes.data.data;

            transformedAssets = assets.map((asset) => { return DTO.getAssetDTO(asset, null, null, trailerTypes); });
        }
    }

    return transformedAssets;
};

export const getAssets = async (searchParams = {}, pagination = {}, accounts = [], trailerTypes = []) => {
    const assetsRes = await axiosAuthenticated.get(assetsPath, { params: { ...searchParams } });
    if (assetsRes && assetsRes.status === 200) {
        const assets = assetsRes.data.data;
        const otherData = assetsRes.data;

        let assetIds = assets.map((asset) => { return asset.id; });

        let assetDocuments = await Data.getDocumentsByEntityIds([...assetIds]);

        const transformedAssets = assets.map((asset) => { return DTO.getAssetDTO(asset, assetDocuments, accounts, trailerTypes); });

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;

        return { data: transformedAssets, searchParams: searchParams, pagination: pagination };
    }

    return { data: [], searchParams: searchParams, pagination: pagination };
};

export const getAsset = async (id, accounts = [], trailerTypes = []) => {
    const assetsRes = await axiosAuthenticated.get(assetsPath + `/${id}`);
    if (assetsRes && assetsRes.status === 200) {
        let asset = assetsRes.data;

        let assetDocuments = await Data.getDocumentsByEntityIds([asset.id]);

        return DTO.getAssetDTO(asset, assetDocuments, accounts, trailerTypes);
    }

    return null;
};

export const addAsset = async (payload, accounts = [], trailerTypes = []) => {
    const photoFile = payload.photoFile;
    const licensePlateFile = payload.licensePlateFile;
    const insuranceFile = payload.insuranceFile;
    const registrationFile = payload.registrationFile;

    let assetPayload = DTO.getAddAssetRequestDTO(payload);

    const assetsRes = await axiosAuthenticated.post(assetsPath, { ...assetPayload });
    if (assetsRes && assetsRes.status === 201) {
        let newAsset = assetsRes.data;

        addAssetFiles(newAsset.id, photoFile, licensePlateFile, insuranceFile, registrationFile);

        let assetDocuments = await Data.getDocumentsByEntityIds([newAsset.id]);

        return DTO.getAssetDTO(newAsset, assetDocuments, accounts, trailerTypes);
    }

    return null;
};

export const addAssetAndAddToLoad = async (payload, carrierId, load, accounts = [], trailerTypes = []) => {
    const photoFile = payload.photoFile;
    const licensePlateFile = payload.licensePlateFile;
    const insuranceFile = payload.insuranceFile;
    const registrationFile = payload.registrationFile;

    let assetPayload = DTO.getAddAssetRequestDTO(payload);

    const assetsRes = await axiosAuthenticated.post(assetsPath, { ...assetPayload, carrierId: carrierId, isAvailable: true });
    if (assetsRes && assetsRes.status === 201) {
        let newAsset = assetsRes.data;
        const copyOfLoad = { ...load };

        addAssetFiles(newAsset.id, photoFile, licensePlateFile, insuranceFile, registrationFile);

        let assetIdList = isListNotEmpty(copyOfLoad.assetIds) ? [...copyOfLoad.assetIds] : [];

        if (isListNotEmpty(assetIdList)) {
            if (assetIdList.find(i => i === newAsset.id) !== undefined) {
                throw new Error("The asset you chose is already assigned to this load.");
            }
        }

        let updatedAssetIds = [...assetIdList, newAsset.id];

        // removes duplicates
        const updatedAssetIds2 = [...new Set(updatedAssetIds)];
        //console.log(updatedAssetIds2);
        let updateLoadPayload = {};
        updateLoadPayload.assetIds = updatedAssetIds2;

        let updatedLoad = await Data.updateLoad(copyOfLoad.id, updateLoadPayload);
        if (isObjectNotEmpty(updatedLoad)) {
            let hasTeamDriving = isListNotEmpty(copyOfLoad.driverRequirements) && copyOfLoad.driverRequirements.includes('TEAM_DRIVING') ? true : false;
            let hasAssets = isListNotEmpty(updateLoadPayload.assetIds);
            if (((copyOfLoad.driverIds.length === 1 && hasTeamDriving === false) || (copyOfLoad.driverIds.length === 2 && hasTeamDriving === true)) && hasAssets === true) {
                // send load event for scheduled
                await Data.addLoadEvent({ loadId: copyOfLoad.id, eventType: 'LOAD_SCHEDULED' });
            }
        }

        let assetDocuments = await Data.getDocumentsByEntityIds([newAsset.id]);

        return DTO.getAssetDTO(newAsset, assetDocuments, accounts, trailerTypes);
    }

    return null;
};

export const updateAsset = async (id, payload, accounts = [], trailerTypes = []) => {
    await updateAssetFiles(id, payload.photoFile, payload.photoFileId, payload.licensePlateFile, payload.licensePlateFileId, payload.insuranceFile, payload.insuranceFileId, payload.registrationFile, payload.registrationFileId);

    let assetPayload = DTO.getUpdateAssetRequestDTO(payload);

    const assetsRes = await axiosAuthenticated.put(assetsPath + `/${id}`, { ...assetPayload });
    if (assetsRes && assetsRes.status === 200) {
        let updatedAsset = assetsRes.data;

        let assetDocuments = await Data.getDocumentsByEntityIds([updatedAsset.id]);

        return DTO.getAssetDTO(updatedAsset, assetDocuments, accounts, trailerTypes);
    }

    return null;
};

export const removeAsset = async (id) => {
    const assetsRes = await axiosAuthenticated.put(assetsPath + `/${id}`, { isDeleted: true });
    if (assetsRes && assetsRes.status === 200) {
        return assetsRes.data;
    }

    return null;
};

export const addAssetFiles = async (entityId, photoFile, licensePlateFile, insuranceFile, registrationFile) => {
    if (isNotNullOrUndefined(photoFile)) {
        await addFile(photoFile, "ASSET", entityId, "ASSET_PHOTO", "Asset Photo", "", "ALL", null, null, null, null, [{ entityType: 'ASSET', entityId: entityId }]);
    }

    if (isNotNullOrUndefined(licensePlateFile)) {
        await addFile(licensePlateFile, "ASSET", entityId, "ASSET_LICENSE_PLATE", "Asset License Plate", "", "ALL", null, null, null, null, [{ entityType: 'ASSET', entityId: entityId }]);
    }

    if (isNotNullOrUndefined(insuranceFile)) {
        await addFile(insuranceFile, "ASSET", entityId, "ASSET_INSURANCE", "Asset Insurance", "", "ALL", null, null, null, null, [{ entityType: 'ASSET', entityId: entityId }]);
    }

    if (isNotNullOrUndefined(registrationFile)) {
        await addFile(registrationFile, "ASSET", entityId, "ASSET_REGISTRATION", "Asset Registration", "", "ALL", null, null, null, null, [{ entityType: 'ASSET', entityId: entityId }]);
    }
};

export const updateAssetFiles = async (entityId, photoFile, photoFileId, licensePlateFile, licensePlateFileId, insuranceFile, insuranceFileId, registrationFile, registrationFileId) => {
    if (isNotNullOrUndefined(photoFile)) {
        await deleteFile(photoFileId);
        await addFile(photoFile, "ASSET", entityId, "ASSET_PHOTO", "Asset Photo", "", "ALL", null, null, null, null, [{ entityType: 'ASSET', entityId: entityId }]);
    }

    if (isNotNullOrUndefined(licensePlateFile)) {
        await deleteFile(licensePlateFileId);
        await addFile(licensePlateFile, "ASSET", entityId, "ASSET_LICENSE_PLATE", "Asset License Plate", "", "ALL", null, null, null, null, [{ entityType: 'ASSET', entityId: entityId }]);
    }

    if (isNotNullOrUndefined(insuranceFile)) {
        await deleteFile(insuranceFileId);
        await addFile(insuranceFile, "ASSET", entityId, "ASSET_INSURANCE", "Asset Insurance", "", "ALL", null, null, null, null, [{ entityType: 'ASSET', entityId: entityId }]);
    }

    if (isNotNullOrUndefined(registrationFile)) {
        await deleteFile(registrationFileId);
        await addFile(registrationFile, "ASSET", entityId, "ASSET_REGISTRATION", "Asset Registration", "", "ALL", null, null, null, null, [{ entityType: 'ASSET', entityId: entityId }]);
    }
};

//#endregion