import axiosAuthenticated from "../../api/axiosAuthenticated";
import { omit } from "lodash";
import { addFile, deleteFile, updateFileMetaData } from "../../api/fileClient";
import enums from "../../shared/enums";
import * as DTO from "../dtos/index";
import * as Data from "./index";
import { isListNotEmpty, isNotNullOrUndefined, isObjectEmpty, isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";

const accountUsersPath = '/accountUsers';
const accountUsersBulkPath = '/accountUsers/bulk/get';

//#region Account Users Methods

export const getAccountUsersByUserIds = async (userIds = [], accountId = null) => {
    if (isListNotEmpty(userIds)) {
        let searchParams = { page: 1, size: 1000000, userId: [...userIds], isDeleted: false };
        if (isStringNotEmpty(accountId)) {
            searchParams.accountId = accountId;
        }
        const accountsRes = await axiosAuthenticated.post(accountUsersBulkPath, { ...searchParams });
        if (accountsRes && accountsRes.status === 200) {
            return accountsRes.data.data;
        }
    }

    return [];
};

export const getAccountUsersByUserId = async (userId) => {
    let accountUsers = [];
    if (isStringNotEmpty(userId)) {
        const accountUsersRes = await axiosAuthenticated.get(accountUsersPath, { params: { page: 1, size: 1000000, userId: userId, isDeleted: false } });
        if (accountUsersRes && accountUsersRes.status === 200) {
            accountUsers = accountUsersRes.data.data;
        }
    }

    return accountUsers;
};

export const getAccountUsersByAccountId = async (accountId, accounts = []) => {
    let transformedAccountUsers = [];
    if (isStringNotEmpty(accountId)) {
        const accountUsersRes = await axiosAuthenticated.get(accountUsersPath, { params: { page: 1, size: 1000000, accountId: accountId, isDeleted: false } });
        if (accountUsersRes && accountUsersRes.status === 200) {
            const accountUsers = accountUsersRes.data.data;

            transformedAccountUsers = accountUsers.map((accountUser) => { return DTO.getAccountUserDTO(accountUser, accounts, null, null); });
        }
    }

    return transformedAccountUsers;
};

export const getEmployeesByAccountId = async (accountId, accounts = []) => {
    let transformedAccountUsers = [];
    if (isStringNotEmpty(accountId)) {
        const accountUsersRes = await axiosAuthenticated.get(accountUsersPath, { 
            params: { 
                page: 1, 
                size: 1000000, 
                'isDriver:or': false,
                'accountRoles:or': 'ADMIN|OPERATIONS_ADMIN|SAFETY_ADMIN|FINANCIAL_ADMIN|DISPATCHER|LOAD_PLANNER|CLERK|TRANSPORTATION_MANAGER',
                accountId: accountId, 
                isDeleted: false 
            } 
        });
        if (accountUsersRes && accountUsersRes.status === 200) {
            const accountUsers = accountUsersRes.data.data;

            transformedAccountUsers = accountUsers.map((accountUser) => { return DTO.getAccountUserDTO(accountUser, accounts, null, null); });
        }
    }

    return transformedAccountUsers;
};

export const getDriversByAccountId = async (accountId, accounts = []) => {
    let transformedAccountUsers = [];
    if (isStringNotEmpty(accountId)) {
        const accountUsersRes = await axiosAuthenticated.get(accountUsersPath, { params: { page: 1, size: 1000000, isDriver: true, accountId: accountId, isDeleted: false } });
        if (accountUsersRes && accountUsersRes.status === 200) {
            const accountUsers = accountUsersRes.data.data;

            transformedAccountUsers = accountUsers.map((accountUser) => { return DTO.getAccountUserDTO(accountUser, accounts, null, null); });
        }
    }

    return transformedAccountUsers;
};

export const getAccountUsers = async (searchParams = {}, pagination = {}, accounts = []) => {
    const accountUsersRes = await axiosAuthenticated.get(accountUsersPath, { params: { ...searchParams } });
    if (accountUsersRes && accountUsersRes.status === 200) {
        const accountUsers = accountUsersRes.data.data;
        const otherData = accountUsersRes.data;

        let accountUserIds = accountUsers.map(i => i.id);
        let userProfileIds = accountUsers.filter(item => item.userProfile !== undefined && item.userProfile !== null).map(i => i.userProfile.id);
        let verifiedUserIds = accountUsers.filter(item => item.verifiedBy !== undefined && item.verifiedBy !== null).map(i => i.verifiedBy);
        let driverDocuments = await Data.getDocumentsByEntityIds([...accountUserIds, ...userProfileIds]);
        let uniqueUserIds = [...new Set([...verifiedUserIds])];
        let users = await Data.getUsersByUserIds([...uniqueUserIds]);

        const transformedAccountUsers = accountUsers.map((accountUser) => { return DTO.getAccountUserDTO(accountUser, accounts, users, driverDocuments); });

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;

        return { data: transformedAccountUsers, searchParams: searchParams, pagination: pagination };
    }

    return { data: [], searchParams: searchParams, pagination: pagination };
};

export const getAccountUser = async (id, accounts = []) => {
    const accountUsersRes = await axiosAuthenticated.get(accountUsersPath + `/${id}`);
    if (accountUsersRes && accountUsersRes.status === 200) {
        let accountUser = accountUsersRes.data;

        let entityIds = [];
        entityIds.push(accountUser.id);
        if (isObjectNotEmpty(accountUser.userProfile) && isStringNotEmpty(accountUser.userProfile.id)) {
            entityIds.push(accountUser.userProfile.id);
        }
        let userIds = [];
        if (isStringNotEmpty(accountUser.verifiedBy)) {
            userIds.push(accountUser.verifiedBy);
        }
        let driverDocuments = await Data.getDocumentsByEntityIds(entityIds);
        let users = await Data.getUsersByUserIds(userIds);

        return DTO.getAccountUserDTO(accountUser, accounts, users, driverDocuments);
    }

    return null;
};

export const addAccountUser = async (payload, accountId, isApproved = false, accounts = []) => {
    if (payload.isDriver === true && payload.driver !== undefined && payload.driver !== null) {
        let driver = DTO.getDriverDTO({ ...payload.driver });

        if (isObjectNotEmpty(driver)) {
            const hazmatLicenseFrontFile = driver.hazmatLicenseFrontFile;
            const hazmatLicenseBackFile = driver.hazmatLicenseBackFile;
            const driverLicenseFrontFile = driver.driverLicenseFrontFile;
            const driverLicenseBackFile = driver.driverLicenseBackFile;
            const commercialLicenseFrontFile = driver.commercialLicenseFrontFile;
            const commercialLicenseBackFile = driver.commercialLicenseBackFile;
            const pneumaticLicenseFrontFile = driver.pneumaticLicenseFrontFile;
            const pneumaticLicenseBackFile = driver.pneumaticLicenseBackFile;
            const mcCertificateLicenseFrontFile = driver.mcCertificateLicenseFrontFile;
            const mcCertificateLicenseBackFile = driver.mcCertificateLicenseBackFile;
            const explosiveCertifiedLicenseFrontFile = driver.explosiveCertifiedLicenseFrontFile;
            const explosiveCertifiedLicenseBackFile = driver.explosiveCertifiedLicenseBackFile;
            const mshaLicenseFrontFile = driver.mshaLicenseFrontFile;
            const mshaLicenseBackFile = driver.mshaLicenseBackFile;

            const hazmatLicenseExpiryDate = driver.hazmatLicenseExpiryDate;
            const driverLicenseExpiryDate = driver.driverLicenseExpiryDate;
            const commercialLicenseExpiryDate = driver.commercialLicenseExpiryDate;
            const pneumaticLicenseExpiryDate = driver.pneumaticLicenseExpiryDate;
            const mcCertificateLicenseExpiryDate = driver.mcCertificateLicenseExpiryDate;
            const explosiveCertifiedLicenseExpiryDate = driver.explosiveCertifiedLicenseExpiryDate;
            const mshaLicenseExpiryDate = driver.mshaLicenseExpiryDate;

            const hazmatLicenseNumber = driver.hazmatLicenseNumber;
            const driverLicenseNumber = driver.driverLicenseNumber;
            const commercialLicenseNumber = driver.commercialLicenseNumber;
            const pneumaticLicenseNumber = driver.pneumaticLicenseNumber;
            const mcCertificateLicenseNumber = driver.mcCertificateLicenseNumber;
            const explosiveCertifiedLicenseNumber = driver.explosiveCertifiedLicenseNumber;
            const mshaLicenseNumber = driver.mshaLicenseNumber;

            let driverPayload = omit(driver, "hazmatLicenseFrontFile", "hazmatLicenseBackFile", "driverLicenseFrontFile",
                "driverLicenseBackFile", "commercialLicenseFrontFile", "commercialLicenseBackFile",
                "pneumaticLicenseFrontFile", "pneumaticLicenseBackFile", "mcCertificateLicenseFrontFile",
                "mcCertificateLicenseBackFile", "explosiveCertifiedLicenseFrontFile", "explosiveCertifiedLicenseBackFile",
                "mshaLicenseFrontFile", "mshaLicenseBackFile", "hazmatLicenseExpiryDate", "driverLicenseExpiryDate",
                "commercialLicenseExpiryDate", "pneumaticLicenseExpiryDate", "mcCertificateLicenseExpiryDate",
                "explosiveCertifiedLicenseExpiryDate", "mshaLicenseExpiryDate", "hazmatLicenseNumber", "driverLicenseNumber",
                "commercialLicenseNumber", "pneumaticLicenseNumber", "mcCertificateLicenseNumber", "explosiveCertifiedLicenseNumber",
                "mshaLicenseNumber");

            // set the driver as available by default when creating a driver
            driverPayload.isAvailable = true;

            driverPayload.isApproved = isApproved;

            payload.driver = driverPayload;

            payload.accountId = accountId;

            // automatically set isVerified to true since a staff member is creating the employee
            // TODO: once we have customer facing, isVerified shouldn't always be set to true depending on who is creating the account user
            payload.isVerified = true;

            const accountUsersRes = await axiosAuthenticated.post(accountUsersPath, { ...payload });
            if (accountUsersRes && accountUsersRes.status === 201) {
                let newAccountUser = accountUsersRes.data;

                await addHazmatLicenseToDriver(newAccountUser.id, hazmatLicenseFrontFile, hazmatLicenseBackFile, hazmatLicenseExpiryDate, hazmatLicenseNumber);
                await addDriversLicenseToDriver(newAccountUser.id, driverLicenseFrontFile, driverLicenseBackFile, driverLicenseExpiryDate, driverLicenseNumber);
                await addCommercialDriversLicenseToDriver(newAccountUser.id, commercialLicenseFrontFile, commercialLicenseBackFile, commercialLicenseExpiryDate, commercialLicenseNumber);
                await addPneumaticLicenseToDriver(newAccountUser.id, pneumaticLicenseFrontFile, pneumaticLicenseBackFile, pneumaticLicenseExpiryDate, pneumaticLicenseNumber);
                await addMCCertificateLicenseToDriver(newAccountUser.id, mcCertificateLicenseFrontFile, mcCertificateLicenseBackFile, mcCertificateLicenseExpiryDate, mcCertificateLicenseNumber);
                await addExplosiveCertifiedLicenseToDriver(newAccountUser.id, explosiveCertifiedLicenseFrontFile, explosiveCertifiedLicenseBackFile, explosiveCertifiedLicenseExpiryDate, explosiveCertifiedLicenseNumber);
                await addMSHALicenseToDriver(newAccountUser.id, mshaLicenseFrontFile, mshaLicenseBackFile, mshaLicenseExpiryDate, mshaLicenseNumber);

                let entityIds = [];
                entityIds.push(newAccountUser.id);
                if (isObjectNotEmpty(newAccountUser.userProfile) && isStringNotEmpty(newAccountUser.userProfile.id)) {
                    entityIds.push(newAccountUser.userProfile.id);
                }
                let userIds = [];
                if (isStringNotEmpty(newAccountUser.verifiedBy)) {
                    userIds.push(newAccountUser.verifiedBy);
                }
                let driverDocuments = await Data.getDocumentsByEntityIds(entityIds);
                let users = await Data.getUsersByUserIds(userIds);

                return DTO.getAccountUserDTO(newAccountUser, accounts, users, driverDocuments);
            }
        }
    } else {
        payload.accountId = accountId;

        // automatically set isVerified to true since a staff member is creating the employee
        // TODO: once we have customer facing, isVerified shouldn't always be set to true depending on who is creating the account user
        payload.isVerified = true;

        const accountUsersRes = await axiosAuthenticated.post(accountUsersPath, { ...payload });
        if (accountUsersRes && accountUsersRes.status === 201) {
            let newAccountUser = accountUsersRes.data;

            let entityIds = [];
            entityIds.push(newAccountUser.id);
            if (isObjectNotEmpty(newAccountUser.userProfile) && isStringNotEmpty(newAccountUser.userProfile.id)) {
                entityIds.push(newAccountUser.userProfile.id);
            }
            let userIds = [];
            if (isStringNotEmpty(newAccountUser.verifiedBy)) {
                userIds.push(newAccountUser.verifiedBy);
            }
            let driverDocuments = await Data.getDocumentsByEntityIds(entityIds);
            let users = await Data.getUsersByUserIds(userIds);

            return DTO.getAccountUserDTO(newAccountUser, accounts, users, driverDocuments);
        }
    }

    return null;
};

export const addAccountUserAndAddToLoad = async (payload, accountId, load, accounts = []) => {
    if (payload.isDriver === true && isObjectNotEmpty(payload.driver)) {
        let driver = DTO.getDriverDTO({ ...payload.driver });

        if (isObjectNotEmpty(driver)) {
            const hazmatLicenseFrontFile = driver.hazmatLicenseFrontFile;
            const hazmatLicenseBackFile = driver.hazmatLicenseBackFile;
            const driverLicenseFrontFile = driver.driverLicenseFrontFile;
            const driverLicenseBackFile = driver.driverLicenseBackFile;
            const commercialLicenseFrontFile = driver.commercialLicenseFrontFile;
            const commercialLicenseBackFile = driver.commercialLicenseBackFile;
            const pneumaticLicenseFrontFile = driver.pneumaticLicenseFrontFile;
            const pneumaticLicenseBackFile = driver.pneumaticLicenseBackFile;
            const mcCertificateLicenseFrontFile = driver.mcCertificateLicenseFrontFile;
            const mcCertificateLicenseBackFile = driver.mcCertificateLicenseBackFile;
            const explosiveCertifiedLicenseFrontFile = driver.explosiveCertifiedLicenseFrontFile;
            const explosiveCertifiedLicenseBackFile = driver.explosiveCertifiedLicenseBackFile;
            const mshaLicenseFrontFile = driver.mshaLicenseFrontFile;
            const mshaLicenseBackFile = driver.mshaLicenseBackFile;

            const hazmatLicenseExpiryDate = driver.hazmatLicenseExpiryDate;
            const driverLicenseExpiryDate = driver.driverLicenseExpiryDate;
            const commercialLicenseExpiryDate = driver.commercialLicenseExpiryDate;
            const pneumaticLicenseExpiryDate = driver.pneumaticLicenseExpiryDate;
            const mcCertificateLicenseExpiryDate = driver.mcCertificateLicenseExpiryDate;
            const explosiveCertifiedLicenseExpiryDate = driver.explosiveCertifiedLicenseExpiryDate;
            const mshaLicenseExpiryDate = driver.mshaLicenseExpiryDate;

            const hazmatLicenseNumber = driver.hazmatLicenseNumber;
            const driverLicenseNumber = driver.driverLicenseNumber;
            const commercialLicenseNumber = driver.commercialLicenseNumber;
            const pneumaticLicenseNumber = driver.pneumaticLicenseNumber;
            const mcCertificateLicenseNumber = driver.mcCertificateLicenseNumber;
            const explosiveCertifiedLicenseNumber = driver.explosiveCertifiedLicenseNumber;
            const mshaLicenseNumber = driver.mshaLicenseNumber;

            driver = omit(driver, "hazmatLicenseFrontFile", "hazmatLicenseBackFile", "driverLicenseFrontFile",
                "driverLicenseBackFile", "commercialLicenseFrontFile", "commercialLicenseBackFile",
                "pneumaticLicenseFrontFile", "pneumaticLicenseBackFile", "mcCertificateLicenseFrontFile",
                "mcCertificateLicenseBackFile", "explosiveCertifiedLicenseFrontFile", "explosiveCertifiedLicenseBackFile",
                "mshaLicenseFrontFile", "mshaLicenseBackFile", "hazmatLicenseExpiryDate", "driverLicenseExpiryDate",
                "commercialLicenseExpiryDate", "pneumaticLicenseExpiryDate", "mcCertificateLicenseExpiryDate",
                "explosiveCertifiedLicenseExpiryDate", "mshaLicenseExpiryDate", "hazmatLicenseNumber", "driverLicenseNumber",
                "commercialLicenseNumber", "pneumaticLicenseNumber", "mcCertificateLicenseNumber", "explosiveCertifiedLicenseNumber",
                "mshaLicenseNumber");

            // set the driver as available by default when creating a driver
            driver.isAvailable = true;

            // automatically set the driver to be approved since we assume there is a person verifying the documents when the documents are being added
            driver.isApproved = true;

            payload.driver = driver;

            // automatically set isVerified to true since a staff member is creating the employee
            // TODO: once we have customer facing, isVerified shouldn't always be set to true depending on who is creating the account user
            const accountUsersRes = await axiosAuthenticated.post(accountUsersPath, { ...payload, accountId: accountId, isVerified: true });
            if (accountUsersRes && accountUsersRes.status === 201) {
                let newAccountUser = accountUsersRes.data;
                const firstName = newAccountUser.firstName;
                const copyOfLoad = { ...load };

                await addHazmatLicenseToDriver(newAccountUser.id, hazmatLicenseFrontFile, hazmatLicenseBackFile, hazmatLicenseExpiryDate, hazmatLicenseNumber, true, "APPROVED");
                await addDriversLicenseToDriver(newAccountUser.id, driverLicenseFrontFile, driverLicenseBackFile, driverLicenseExpiryDate, driverLicenseNumber, true, "APPROVED");
                await addCommercialDriversLicenseToDriver(newAccountUser.id, commercialLicenseFrontFile, commercialLicenseBackFile, commercialLicenseExpiryDate, commercialLicenseNumber, true, "APPROVED");
                await addPneumaticLicenseToDriver(newAccountUser.id, pneumaticLicenseFrontFile, pneumaticLicenseBackFile, pneumaticLicenseExpiryDate, pneumaticLicenseNumber, true, "APPROVED");
                await addMCCertificateLicenseToDriver(newAccountUser.id, mcCertificateLicenseFrontFile, mcCertificateLicenseBackFile, mcCertificateLicenseExpiryDate, mcCertificateLicenseNumber, true, "APPROVED");
                await addExplosiveCertifiedLicenseToDriver(newAccountUser.id, explosiveCertifiedLicenseFrontFile, explosiveCertifiedLicenseBackFile, explosiveCertifiedLicenseExpiryDate, explosiveCertifiedLicenseNumber, true, "APPROVED");
                await addMSHALicenseToDriver(newAccountUser.id, mshaLicenseFrontFile, mshaLicenseBackFile, mshaLicenseExpiryDate, mshaLicenseNumber, true, "APPROVED");

                // add driver to load and update status of load to SCHEDULED if number of drivers assigned is full

                let driverIdList = isListNotEmpty(copyOfLoad.driverIds) ? [...copyOfLoad.driverIds] : [];

                if (isListNotEmpty(driverIdList)) {
                    if (driverIdList.find(i => i === newAccountUser.id) !== undefined) {
                        throw new Error("The driver you chose is already assigned to this load.");
                    }
                }

                let updatedDriverIds = [...driverIdList, newAccountUser.id];

                // removes duplicates
                const updatedDriverIds2 = [...new Set(updatedDriverIds)];
                //console.log(updatedDriverIds2);
                let updateLoadPayload = {};
                updateLoadPayload.driverIds = updatedDriverIds2;

                let updatedLoad = await Data.updateLoad(copyOfLoad.id, updateLoadPayload);
                if (isObjectNotEmpty(updatedLoad)) {
                    let hasTeamDriving = isListNotEmpty(copyOfLoad.driverRequirements) && copyOfLoad.driverRequirements.includes('TEAM_DRIVING') ? true : false;
                    let hasAssets = isListNotEmpty(copyOfLoad.assetIds) > 0;
                    if (((updatedDriverIds2.length === 1 && hasTeamDriving === false) || (updatedDriverIds2.length === 2 && hasTeamDriving === true)) && hasAssets === true) {
                        // send load event for scheduled
                        await Data.addLoadEvent({ loadId: copyOfLoad.id, eventType: 'LOAD_SCHEDULED' });
                    }

                    await Data.addLoadNotification(
                        `${copyOfLoad.irisId} You have been assigned to a new load`,
                        `Hi ${firstName}, you have been assigned to a new load: ${copyOfLoad.irisId}. Please open up the IRIS Freight app for more details.`,
                        newAccountUser.userId,
                        'DRIVER',
                        newAccountUser.userId,
                        copyOfLoad.id,
                        copyOfLoad.irisId,
                        copyOfLoad.loadStatus,
                        'DRIVER_ASSIGNED',
                        false
                    );
                }

                let entityIds = [];
                entityIds.push(newAccountUser.id);
                if (isObjectNotEmpty(newAccountUser.userProfile) && isStringNotEmpty(newAccountUser.userProfile.id)) {
                    entityIds.push(newAccountUser.userProfile.id);
                }
                let userIds = [];
                if (isStringNotEmpty(newAccountUser.verifiedBy)) {
                    userIds.push(newAccountUser.verifiedBy);
                }
                let driverDocuments = await Data.getDocumentsByEntityIds(entityIds);
                let users = await Data.getUsersByUserIds(userIds);

                return DTO.getAccountUserDTO(newAccountUser, accounts, users, driverDocuments);
            }
        }
    } else {
        // automatically set isVerified to true since a staff member is creating the employee
        // TODO: once we have customer facing, isVerified shouldn't always be set to true depending on who is creating the account user
        const accountUsersRes = await axiosAuthenticated.post(accountUsersPath, { ...payload, accountId: accountId, isVerified: true });
        if (accountUsersRes && accountUsersRes.status === 201) {
            let newAccountUser = accountUsersRes.data;
            const firstName = newAccountUser.firstName;
            const copyOfLoad = { ...load };

            // add driver to load and update status of load to SCHEDULED if number of drivers assigned is full

            let driverIdList = isListNotEmpty(copyOfLoad.driverIds) ? [...copyOfLoad.driverIds] : [];

            if (isListNotEmpty(driverIdList)) {
                if (driverIdList.find(i => i === newAccountUser.id) !== undefined) {
                    throw new Error("The driver you chose is already assigned to this load.");
                }
            }

            let updatedDriverIds = [...driverIdList, newAccountUser.id];

            // removes duplicates
            const updatedDriverIds2 = [...new Set(updatedDriverIds)];
            //console.log(updatedDriverIds2);
            let updateLoadPayload = {};
            updateLoadPayload.driverIds = updatedDriverIds2;

            let updatedLoad = await Data.updateLoad(copyOfLoad.id, updateLoadPayload);
            if (isObjectNotEmpty(updatedLoad)) {
                let hasTeamDriving = isListNotEmpty(copyOfLoad.driverRequirements) && copyOfLoad.driverRequirements.includes('TEAM_DRIVING') ? true : false;
                let hasAssets = isListNotEmpty(copyOfLoad.assetIds);
                if (((updatedDriverIds2.length === 1 && hasTeamDriving === false) || (updatedDriverIds2.length === 2 && hasTeamDriving === true)) && hasAssets === true) {
                    // send load event for scheduled
                    await Data.addLoadEvent({ loadId: copyOfLoad.id, eventType: 'LOAD_SCHEDULED' });
                }

                await Data.addLoadNotification(
                    `${copyOfLoad.irisId} You have been assigned to a new load`,
                    `Hi ${firstName}, you have been assigned to a new load: ${copyOfLoad.irisId}. Please open up the IRIS Freight app for more details.`,
                    newAccountUser.userId,
                    'DRIVER',
                    newAccountUser.userId,
                    copyOfLoad.id,
                    copyOfLoad.irisId,
                    copyOfLoad.loadStatus,
                    'DRIVER_ASSIGNED',
                    false
                );
            }

            let entityIds = [];
            entityIds.push(newAccountUser.id);
            if (isObjectNotEmpty(newAccountUser.userProfile) && isStringNotEmpty(newAccountUser.userProfile.id)) {
                entityIds.push(newAccountUser.userProfile.id);
            }
            let userIds = [];
            if (isStringNotEmpty(newAccountUser.verifiedBy)) {
                userIds.push(newAccountUser.verifiedBy);
            }
            let driverDocuments = await Data.getDocumentsByEntityIds(entityIds);
            let users = await Data.getUsersByUserIds(userIds);

            return DTO.getAccountUserDTO(newAccountUser, accounts, users, driverDocuments);
        }
    }

    return null;
};

export const updateAccountUser = async (id, payload, isDriver = false, accounts = [], entityType = null) => {
    if (isDriver === true) {
        if (isObjectEmpty(payload.driver)) {
            payload.driver = {};
        }

        let driver = DTO.getDriverDTO({ ...payload.driver });

        if (isObjectNotEmpty(driver)) {
            let isReviewed = isNotNullOrUndefined(entityType) && entityType === 'STAFF' ? true : null;
            let reviewStatus = isNotNullOrUndefined(entityType) && entityType === 'STAFF' ? 'APPROVED' : null;
            driver = await updateDriverFiles(driver, id, isReviewed, reviewStatus);

            payload.driver = driver;

            const accountUsersRes = await axiosAuthenticated.put(accountUsersPath + `/${id}`, { ...payload });
            if (accountUsersRes && accountUsersRes.status === 200) {
                let updatedAccountUser = accountUsersRes.data;

                let entityIds = [];
                entityIds.push(updatedAccountUser.id);
                if (isObjectNotEmpty(updatedAccountUser.userProfile) && isStringNotEmpty(updatedAccountUser.userProfile.id)) {
                    entityIds.push(updatedAccountUser.userProfile.id);
                }
                let userIds = [];
                if (isStringNotEmpty(updatedAccountUser.verifiedBy)) {
                    userIds.push(updatedAccountUser.verifiedBy);
                }
                let driverDocuments = await Data.getDocumentsByEntityIds(entityIds);
                let users = await Data.getUsersByUserIds(userIds);

                return DTO.getAccountUserDTO(updatedAccountUser, accounts, users, driverDocuments);
            }
        }
    } else {
        const accountUsersRes = await axiosAuthenticated.put(accountUsersPath + `/${id}`, { ...payload });
        if (accountUsersRes && accountUsersRes.status === 200) {
            let updatedAccountUser = accountUsersRes.data;

            let entityIds = [];
            entityIds.push(updatedAccountUser.id);
            if (isObjectNotEmpty(updatedAccountUser.userProfile) && isStringNotEmpty(updatedAccountUser.userProfile.id)) {
                entityIds.push(updatedAccountUser.userProfile.id);
            }
            let userIds = [];
            if (isStringNotEmpty(updatedAccountUser.verifiedBy)) {
                userIds.push(updatedAccountUser.verifiedBy);
            }
            let driverDocuments = await Data.getDocumentsByEntityIds(entityIds);
            let users = await Data.getUsersByUserIds(userIds);

            return DTO.getAccountUserDTO(updatedAccountUser, accounts, users, driverDocuments);

        }
    }

    return null;
};

export const updateAccountUserAndAddToLoad = async (id, payload, isDriver, load, accounts = []) => {
    if (isDriver === true) {
        if (isObjectEmpty(payload.driver)) {
            payload.driver = {};
        }

        let driver = DTO.getDriverDTO({ ...payload.driver });
        if (isObjectNotEmpty(driver)) {
            driver = await updateDriverFiles(driver, id, true, 'APPROVED');

            // automatically set the driver to be approved since we assume there is a person verifying the documents when the documents are being added
            driver.isApproved = true;

            payload.driver = driver;

            const accountUsersRes = await axiosAuthenticated.put(accountUsersPath + `/${id}`, { ...payload });
            if (accountUsersRes && accountUsersRes.status === 200) {
                let updatedAccountUser = accountUsersRes.data;
                const firstName = updatedAccountUser.firstName;
                const copyOfLoad = { ...load };

                // add driver to load and update status of load to SCHEDULED if number of drivers assigned is full
                let driverIdList = isListNotEmpty(copyOfLoad.driverIds) ? [...copyOfLoad.driverIds] : [];

                if (isListNotEmpty(driverIdList)) {
                    if (driverIdList.find(i => i === updatedAccountUser.id) !== undefined) {
                        throw new Error("The driver you chose is already assigned to this load.");
                    }
                }

                let updatedDriverIds = [...driverIdList, updatedAccountUser.id];

                // removes duplicates
                const updatedDriverIds2 = [...new Set(updatedDriverIds)];
                //console.log(updatedDriverIds2);
                let updatedLoadPayload = {};
                updatedLoadPayload.driverIds = updatedDriverIds2;

                let updatedLoad = await Data.updateLoad(copyOfLoad.id, updatedLoadPayload);
                if (isObjectNotEmpty(updatedLoad)) {
                    let hasTeamDriving = isListNotEmpty(copyOfLoad.driverRequirements) && copyOfLoad.driverRequirements.includes('TEAM_DRIVING') ? true : false;
                    let hasAssets = isListNotEmpty(copyOfLoad.assetIds);
                    if (((updatedDriverIds2.length === 1 && hasTeamDriving === false) || (updatedDriverIds2.length === 2 && hasTeamDriving === true)) && hasAssets === true) {
                        // send load event for scheduled
                        await Data.addLoadEvent({ loadId: copyOfLoad.id, eventType: 'LOAD_SCHEDULED' });
                    }

                    await Data.addLoadNotification(
                        `${copyOfLoad.irisId} You have been assigned to a new load`,
                        `Hi ${firstName}, you have been assigned to a new load: ${copyOfLoad.irisId}. Please open up the IRIS Freight app for more details.`,
                        updatedAccountUser.userId,
                        'DRIVER',
                        updatedAccountUser.userId,
                        copyOfLoad.id,
                        copyOfLoad.irisId,
                        copyOfLoad.loadStatus,
                        'DRIVER_ASSIGNED',
                        false
                    );
                }

                let entityIds = [];
                entityIds.push(updatedAccountUser.id);
                if (isObjectNotEmpty(updatedAccountUser.userProfile) && isStringNotEmpty(updatedAccountUser.userProfile.id)) {
                    entityIds.push(updatedAccountUser.userProfile.id);
                }
                let userIds = [];
                if (isStringNotEmpty(updatedAccountUser.verifiedBy)) {
                    userIds.push(updatedAccountUser.verifiedBy);
                }
                let driverDocuments = await Data.getDocumentsByEntityIds(entityIds);
                let users = await Data.getUsersByUserIds(userIds);

                return DTO.getAccountUserDTO(updatedAccountUser, accounts, users, driverDocuments);
            }
        }
    } else {
        const accountUsersRes = await axiosAuthenticated.put(accountUsersPath + `/${id}`, { ...payload });
        if (accountUsersRes && accountUsersRes.status === 200) {
            let updatedAccountUser = accountUsersRes.data;
            const firstName = updatedAccountUser.firstName;
            const copyOfLoad = { ...load };

            // add driver to load and update status of load to SCHEDULED if number of drivers assigned is full
            let driverIdList = isListNotEmpty(copyOfLoad.driverIds) ? [...copyOfLoad.driverIds] : [];

            if (isListNotEmpty(driverIdList)) {
                if (driverIdList.find(i => i === updatedAccountUser.id) !== undefined) {
                    throw new Error("The driver you chose is already assigned to this load.");
                }
            }

            let updatedDriverIds = [...driverIdList, updatedAccountUser.id];

            // removes duplicates
            const updatedDriverIds2 = [...new Set(updatedDriverIds)];
            //console.log(updatedDriverIds2);
            let updatedLoadPayload = {};
            updatedLoadPayload.driverIds = updatedDriverIds2;

            let updatedLoad = await Data.updateLoad(copyOfLoad.id, updatedLoadPayload);
            if (isObjectNotEmpty(updatedLoad)) {
                let hasTeamDriving = isListNotEmpty(copyOfLoad.driverRequirements) && copyOfLoad.driverRequirements.includes('TEAM_DRIVING') ? true : false;
                let hasAssets = isListNotEmpty(copyOfLoad.assetIds);
                if (((updatedDriverIds2.length === 1 && hasTeamDriving === false) || (updatedDriverIds2.length === 2 && hasTeamDriving === true)) && hasAssets === true) {
                    // send load event for scheduled
                    await Data.addLoadEvent({ loadId: copyOfLoad.id, eventType: 'LOAD_SCHEDULED' });
                }

                await Data.addLoadNotification(
                    `${copyOfLoad.irisId} You have been assigned to a new load`,
                    `Hi ${firstName}, you have been assigned to a new load: ${copyOfLoad.irisId}. Please open up the IRIS Freight app for more details.`,
                    updatedAccountUser.userId,
                    'DRIVER',
                    updatedAccountUser.userId,
                    copyOfLoad.id,
                    copyOfLoad.irisId,
                    copyOfLoad.loadStatus,
                    'DRIVER_ASSIGNED',
                    false
                );
            }

            let entityIds = [];
            entityIds.push(updatedAccountUser.id);
            if (isObjectNotEmpty(updatedAccountUser.userProfile) && isStringNotEmpty(updatedAccountUser.userProfile.id)) {
                entityIds.push(updatedAccountUser.userProfile.id);
            }
            let userIds = [];
            if (isStringNotEmpty(updatedAccountUser.verifiedBy)) {
                userIds.push(updatedAccountUser.verifiedBy);
            }
            let driverDocuments = await Data.getDocumentsByEntityIds(entityIds);
            let users = await Data.getUsersByUserIds(userIds);

            return DTO.getAccountUserDTO(updatedAccountUser, accounts, users, driverDocuments);
        }
    }

    return null;
};

export const removeAccountUser = async (id) => {
    const accountUsersRes = await axiosAuthenticated.put(accountUsersPath + `/${id}`, { isDeleted: true });
    if (accountUsersRes && accountUsersRes.status === 200) {
        return accountUsersRes.data;
    }

    return null;
};

export const updateAccountUserDocument = async (id, payload, entityType) => {
    return await updateDriverFile(payload, id, entityType);
};

export const approveDriver = async (id, accounts = []) => {
    let payload = {};
    payload.driver = {};
    payload.driver.isApproved = true;

    const accountUsersRes = await axiosAuthenticated.put(accountUsersPath + `/${id}`, { ...payload });
    if (accountUsersRes && accountUsersRes.status === 200) {
        let updatedAccountUser = accountUsersRes.data;

        let entityIds = [];
        entityIds.push(updatedAccountUser.id);
        if (isObjectNotEmpty(updatedAccountUser.userProfile) && isStringNotEmpty(updatedAccountUser.userProfile.id)) {
            entityIds.push(updatedAccountUser.userProfile.id);
        }
        let userIds = [];
        if (isStringNotEmpty(updatedAccountUser.verifiedBy)) {
            userIds.push(updatedAccountUser.verifiedBy);
        }
        let driverDocuments = await Data.getDocumentsByEntityIds(entityIds);
        let users = await Data.getUsersByUserIds(userIds);

        return DTO.getAccountUserDTO(updatedAccountUser, accounts, users, driverDocuments);
    }

    return null;
};

export const unapproveDriver = async (id, accounts = []) => {
    let payload = {};
    payload.driver = {};
    payload.driver.isApproved = false;

    const accountUsersRes = await axiosAuthenticated.put(accountUsersPath + `/${id}`, { ...payload });
    if (accountUsersRes && accountUsersRes.status === 200) {
        let updatedAccountUser = accountUsersRes.data;

        let entityIds = [];
        entityIds.push(updatedAccountUser.id);
        if (isObjectNotEmpty(updatedAccountUser.userProfile) && isStringNotEmpty(updatedAccountUser.userProfile.id)) {
            entityIds.push(updatedAccountUser.userProfile.id);
        }
        let userIds = [];
        if (isStringNotEmpty(updatedAccountUser.verifiedBy)) {
            userIds.push(updatedAccountUser.verifiedBy);
        }
        let driverDocuments = await Data.getDocumentsByEntityIds(entityIds);
        let users = await Data.getUsersByUserIds(userIds);

        return DTO.getAccountUserDTO(updatedAccountUser, accounts, users, driverDocuments);
    }

    return null;
};

export const markDriverAvailable = async (id, accounts = []) => {
    let payload = {};
    payload.driver = {};
    payload.driver.isAvailable = true;

    const accountUsersRes = await axiosAuthenticated.put(accountUsersPath + `/${id}`, { ...payload });
    if (accountUsersRes && accountUsersRes.status === 200) {
        let updatedAccountUser = accountUsersRes.data;

        let entityIds = [];
        entityIds.push(updatedAccountUser.id);
        if (isObjectNotEmpty(updatedAccountUser.userProfile) && isStringNotEmpty(updatedAccountUser.userProfile.id)) {
            entityIds.push(updatedAccountUser.userProfile.id);
        }
        let userIds = [];
        if (isStringNotEmpty(updatedAccountUser.verifiedBy)) {
            userIds.push(updatedAccountUser.verifiedBy);
        }
        let driverDocuments = await Data.getDocumentsByEntityIds(entityIds);
        let users = await Data.getUsersByUserIds(userIds);

        return DTO.getAccountUserDTO(updatedAccountUser, accounts, users, driverDocuments);
    }

    return null;
};

export const markDriverUnavailable = async (id, accounts = []) => {
    let payload = {};
    payload.driver = {};
    payload.driver.isAvailable = false;

    const accountUsersRes = await axiosAuthenticated.put(accountUsersPath + `/${id}`, { ...payload });
    if (accountUsersRes && accountUsersRes.status === 200) {
        let updatedAccountUser = accountUsersRes.data;

        let entityIds = [];
        entityIds.push(updatedAccountUser.id);
        if (isObjectNotEmpty(updatedAccountUser.userProfile) && isStringNotEmpty(updatedAccountUser.userProfile.id)) {
            entityIds.push(updatedAccountUser.userProfile.id);
        }
        let userIds = [];
        if (isStringNotEmpty(updatedAccountUser.verifiedBy)) {
            userIds.push(updatedAccountUser.verifiedBy);
        }
        let driverDocuments = await Data.getDocumentsByEntityIds(entityIds);
        let users = await Data.getUsersByUserIds(userIds);

        return DTO.getAccountUserDTO(updatedAccountUser, accounts, users, driverDocuments);
    }

    return null;
};

export const addHazmatLicenseToDriver = async (entityId, hazmatLicenseFrontFile, hazmatLicenseBackFile, hazmatLicenseExpiryDate, hazmatLicenseNumber, isReviewed = null, reviewStatus = null) => {
    if (isNotNullOrUndefined(hazmatLicenseFrontFile) && isNotNullOrUndefined(hazmatLicenseExpiryDate)) {
        await addFile(hazmatLicenseFrontFile, "DRIVER", entityId, "HAZMAT_LICENSE_FRONT", "Front of HazMat License", "", "ALL", hazmatLicenseExpiryDate, isReviewed, reviewStatus, hazmatLicenseNumber, [{ entityType: 'DRIVER', entityId: entityId }]);
    }

    if (isNotNullOrUndefined(hazmatLicenseBackFile) && isNotNullOrUndefined(hazmatLicenseExpiryDate)) {
        await addFile(hazmatLicenseBackFile, "DRIVER", entityId, "HAZMAT_LICENSE_BACK", "Back of HazMat License", "", "ALL", hazmatLicenseExpiryDate, isReviewed, reviewStatus, hazmatLicenseNumber, [{ entityType: 'DRIVER', entityId: entityId }]);
    }
};

export const addDriversLicenseToDriver = async (entityId, driverLicenseFrontFile, driverLicenseBackFile, driverLicenseExpiryDate, driverLicenseNumber, isReviewed = null, reviewStatus = null) => {
    if (isNotNullOrUndefined(driverLicenseFrontFile) && isNotNullOrUndefined(driverLicenseExpiryDate)) {
        await addFile(driverLicenseFrontFile, "DRIVER", entityId, "DRIVERS_LICENSE_FRONT", "Front of Drivers License", "", "ALL", driverLicenseExpiryDate, isReviewed, reviewStatus, driverLicenseNumber, [{ entityType: 'DRIVER', entityId: entityId }]);
    }

    if (isNotNullOrUndefined(driverLicenseBackFile) && isNotNullOrUndefined(driverLicenseExpiryDate)) {
        await addFile(driverLicenseBackFile, "DRIVER", entityId, "DRIVERS_LICENSE_BACK", "Back of Drivers License", "", "ALL", driverLicenseExpiryDate, isReviewed, reviewStatus, driverLicenseNumber, [{ entityType: 'DRIVER', entityId: entityId }]);
    }
};

export const addCommercialDriversLicenseToDriver = async (entityId, commercialLicenseFrontFile, commercialLicenseBackFile, commercialLicenseExpiryDate, commercialLicenseNumber, isReviewed = null, reviewStatus = null) => {
    if (isNotNullOrUndefined(commercialLicenseFrontFile) && isNotNullOrUndefined(commercialLicenseExpiryDate)) {
        await addFile(commercialLicenseFrontFile, "DRIVER", entityId, "COMMERCIAL_DRIVERS_LICENSE_FRONT", "Front of Commercial Drivers License", "", "ALL", commercialLicenseExpiryDate, isReviewed, reviewStatus, commercialLicenseNumber, [{ entityType: 'DRIVER', entityId: entityId }]);
    }

    if (isNotNullOrUndefined(commercialLicenseBackFile) && isNotNullOrUndefined(commercialLicenseExpiryDate)) {
        await addFile(commercialLicenseBackFile, "DRIVER", entityId, "COMMERCIAL_DRIVERS_LICENSE_BACK", "Back of Commercial Drivers License", "", "ALL", commercialLicenseExpiryDate, isReviewed, reviewStatus, commercialLicenseNumber, [{ entityType: 'DRIVER', entityId: entityId }]);
    }
};

export const addPneumaticLicenseToDriver = async (entityId, pneumaticLicenseFrontFile, pneumaticLicenseBackFile, pneumaticLicenseExpiryDate, pneumaticLicenseNumber, isReviewed = null, reviewStatus = null) => {
    if (isNotNullOrUndefined(pneumaticLicenseFrontFile) && isNotNullOrUndefined(pneumaticLicenseExpiryDate)) {
        await addFile(pneumaticLicenseFrontFile, "DRIVER", entityId, "PNEUMATIC_LICENSE_FRONT", "Front of Pneumatic License", "", "ALL", pneumaticLicenseExpiryDate, isReviewed, reviewStatus, pneumaticLicenseNumber, [{ entityType: 'DRIVER', entityId: entityId }]);
    }

    if (isNotNullOrUndefined(pneumaticLicenseBackFile) && isNotNullOrUndefined(pneumaticLicenseExpiryDate)) {
        await addFile(pneumaticLicenseBackFile, "DRIVER", entityId, "PNEUMATIC_LICENSE_BACK", "Back of Pneumatic License", "", "ALL", pneumaticLicenseExpiryDate, isReviewed, reviewStatus, pneumaticLicenseNumber, [{ entityType: 'DRIVER', entityId: entityId }]);
    }
};

export const addMCCertificateLicenseToDriver = async (entityId, mcCertificateLicenseFrontFile, mcCertificateLicenseBackFile, mcCertificateLicenseExpiryDate, mcCertificateLicenseNumber, isReviewed = null, reviewStatus = null) => {
    if (isNotNullOrUndefined(mcCertificateLicenseFrontFile) && isNotNullOrUndefined(mcCertificateLicenseExpiryDate)) {
        await addFile(mcCertificateLicenseFrontFile, "DRIVER", entityId, "MC_CERTIFICATE_LICENSE_FRONT", "Front of MC Certificate License", "", "ALL", mcCertificateLicenseExpiryDate, isReviewed, reviewStatus, mcCertificateLicenseNumber, [{ entityType: 'DRIVER', entityId: entityId }]);
    }

    if (isNotNullOrUndefined(mcCertificateLicenseBackFile) && isNotNullOrUndefined(mcCertificateLicenseExpiryDate)) {
        await addFile(mcCertificateLicenseBackFile, "DRIVER", entityId, "MC_CERTIFICATE_LICENSE_BACK", "Back of MC Certificate License", "", "ALL", mcCertificateLicenseExpiryDate, isReviewed, reviewStatus, mcCertificateLicenseNumber, [{ entityType: 'DRIVER', entityId: entityId }]);
    }
};

export const addExplosiveCertifiedLicenseToDriver = async (entityId, explosiveCertifiedLicenseFrontFile, explosiveCertifiedLicenseBackFile, explosiveCertifiedLicenseExpiryDate, explosiveCertifiedLicenseNumber, isReviewed = null, reviewStatus = null) => {
    if (isNotNullOrUndefined(explosiveCertifiedLicenseFrontFile) && isNotNullOrUndefined(explosiveCertifiedLicenseExpiryDate)) {
        await addFile(explosiveCertifiedLicenseFrontFile, "DRIVER", entityId, "EXPLOSIVE_CERTIFIED_LICENSE_FRONT", "Front of Explosive Certified License", "", "ALL", explosiveCertifiedLicenseExpiryDate, isReviewed, reviewStatus, explosiveCertifiedLicenseNumber, [{ entityType: 'DRIVER', entityId: entityId }]);
    }

    if (isNotNullOrUndefined(explosiveCertifiedLicenseBackFile) && isNotNullOrUndefined(explosiveCertifiedLicenseExpiryDate)) {
        await addFile(explosiveCertifiedLicenseBackFile, "DRIVER", entityId, "EXPLOSIVE_CERTIFIED_LICENSE_BACK", "Back of Explosive Certified License", "", "ALL", explosiveCertifiedLicenseExpiryDate, isReviewed, reviewStatus, explosiveCertifiedLicenseNumber, [{ entityType: 'DRIVER', entityId: entityId }]);
    }
};

export const addMSHALicenseToDriver = async (entityId, mshaLicenseFrontFile, mshaLicenseBackFile, mshaLicenseExpiryDate, mshaLicenseNumber, isReviewed = null, reviewStatus = null) => {
    if (isNotNullOrUndefined(mshaLicenseFrontFile) && isNotNullOrUndefined(mshaLicenseExpiryDate)) {
        await addFile(mshaLicenseFrontFile, "DRIVER", entityId, "MSHA_LICENSE_FRONT", "Front of MSHA License", "", "ALL", mshaLicenseExpiryDate, isReviewed, reviewStatus, mshaLicenseNumber, [{ entityType: 'DRIVER', entityId: entityId }]);
    }

    if (isNotNullOrUndefined(mshaLicenseBackFile) && isNotNullOrUndefined(mshaLicenseExpiryDate)) {
        await addFile(mshaLicenseBackFile, "DRIVER", entityId, "MSHA_LICENSE_BACK", "Back of MSHA License", "", "ALL", mshaLicenseExpiryDate, isReviewed, reviewStatus, mshaLicenseNumber, [{ entityType: 'DRIVER', entityId: entityId }]);
    }
};

export const updateDriverFiles = async (driver, entityId, isReviewed = null, reviewStatus = null) => {
    const hazmatLicenseFrontFile = driver.hazmatLicenseFrontFile;
    const hazmatLicenseBackFile = driver.hazmatLicenseBackFile;
    const driverLicenseFrontFile = driver.driverLicenseFrontFile;
    const driverLicenseBackFile = driver.driverLicenseBackFile;
    const commercialLicenseFrontFile = driver.commercialLicenseFrontFile;
    const commercialLicenseBackFile = driver.commercialLicenseBackFile;
    const pneumaticLicenseFrontFile = driver.pneumaticLicenseFrontFile;
    const pneumaticLicenseBackFile = driver.pneumaticLicenseBackFile;
    const mcCertificateLicenseFrontFile = driver.mcCertificateLicenseFrontFile;
    const mcCertificateLicenseBackFile = driver.mcCertificateLicenseBackFile;
    const explosiveCertifiedLicenseFrontFile = driver.explosiveCertifiedLicenseFrontFile;
    const explosiveCertifiedLicenseBackFile = driver.explosiveCertifiedLicenseBackFile;
    const mshaLicenseFrontFile = driver.mshaLicenseFrontFile;
    const mshaLicenseBackFile = driver.mshaLicenseBackFile;

    const hazmatLicenseExpiryDate = isNotNullOrUndefined(driver.hazmatLicenseExpiryDate) ? driver.hazmatLicenseExpiryDate : null;
    const driverLicenseExpiryDate = isNotNullOrUndefined(driver.driverLicenseExpiryDate) ? driver.driverLicenseExpiryDate : null;
    const commercialLicenseExpiryDate = isNotNullOrUndefined(driver.commercialLicenseExpiryDate) ? driver.commercialLicenseExpiryDate : null;
    const pneumaticLicenseExpiryDate = isNotNullOrUndefined(driver.pneumaticLicenseExpiryDate) ? driver.pneumaticLicenseExpiryDate : null;
    const mcCertificateLicenseExpiryDate = isNotNullOrUndefined(driver.mcCertificateLicenseExpiryDate) ? driver.mcCertificateLicenseExpiryDate : null;
    const explosiveCertifiedLicenseExpiryDate = isNotNullOrUndefined(driver.explosiveCertifiedLicenseExpiryDate) ? driver.explosiveCertifiedLicenseExpiryDate : null;
    const mshaLicenseExpiryDate = isNotNullOrUndefined(driver.mshaLicenseExpiryDate) ? driver.mshaLicenseExpiryDate : null;

    const hazmatLicenseNumber = isNotNullOrUndefined(driver.hazmatLicenseNumber) ? driver.hazmatLicenseNumber : null;
    const driverLicenseNumber = isNotNullOrUndefined(driver.driverLicenseNumber) ? driver.driverLicenseNumber : null;
    const commercialLicenseNumber = isNotNullOrUndefined(driver.commercialLicenseNumber) ? driver.commercialLicenseNumber : null;
    const pneumaticLicenseNumber = isNotNullOrUndefined(driver.pneumaticLicenseNumber) ? driver.pneumaticLicenseNumber : null;
    const mcCertificateLicenseNumber = isNotNullOrUndefined(driver.mcCertificateLicenseNumber) ? driver.mcCertificateLicenseNumber : null;
    const explosiveCertifiedLicenseNumber = isNotNullOrUndefined(driver.explosiveCertifiedLicenseNumber) ? driver.explosiveCertifiedLicenseNumber : null;
    const mshaLicenseNumber = isNotNullOrUndefined(driver.mshaLicenseNumber) ? driver.mshaLicenseNumber : null;

    const hazmatLicenseFrontFileId = driver.hazmatLicenseFrontFileId;
    const hazmatLicenseBackFileId = driver.hazmatLicenseBackFileId;
    const driverLicenseFrontFileId = driver.driverLicenseFrontFileId;
    const driverLicenseBackFileId = driver.driverLicenseBackFileId;
    const commercialLicenseFrontFileId = driver.commercialLicenseFrontFileId;
    const commercialLicenseBackFileId = driver.commercialLicenseBackFileId;
    const pneumaticLicenseFrontFileId = driver.pneumaticLicenseFrontFileId;
    const pneumaticLicenseBackFileId = driver.pneumaticLicenseBackFileId;
    const mcCertificateLicenseFrontFileId = driver.mcCertificateLicenseFrontFileId;
    const mcCertificateLicenseBackFileId = driver.mcCertificateLicenseBackFileId;
    const explosiveCertifiedLicenseFrontFileId = driver.explosiveCertifiedLicenseFrontFileId;
    const explosiveCertifiedLicenseBackFileId = driver.explosiveCertifiedLicenseBackFileId;
    const mshaLicenseFrontFileId = driver.mshaLicenseFrontFileId;
    const mshaLicenseBackFileId = driver.mshaLicenseBackFileId;

    if (isNotNullOrUndefined(hazmatLicenseFrontFile)) {
        await deleteFile(hazmatLicenseFrontFileId);
        await addFile(hazmatLicenseFrontFile, "DRIVER", entityId, "HAZMAT_LICENSE_FRONT", "Front of HazMat License", "", "ALL", hazmatLicenseExpiryDate, isReviewed, reviewStatus, hazmatLicenseNumber, [{ entityType: 'DRIVER', entityId: entityId }]);
    } else if (isNotNullOrUndefined(hazmatLicenseFrontFileId)) {
        if (isNotNullOrUndefined(hazmatLicenseExpiryDate) || isNotNullOrUndefined(hazmatLicenseNumber)) {
            await updateFileMetaData(hazmatLicenseFrontFileId, { expiryDate: hazmatLicenseExpiryDate, licenseNumber: hazmatLicenseNumber, isReviewed: isReviewed ? isReviewed : false, reviewStatus: reviewStatus ? reviewStatus : 'PENDING' });
        }
    }

    if (isNotNullOrUndefined(hazmatLicenseBackFile)) {
        await deleteFile(hazmatLicenseBackFileId);
        await addFile(hazmatLicenseBackFile, "DRIVER", entityId, "HAZMAT_LICENSE_BACK", "Back of HazMat License", "", "ALL", hazmatLicenseExpiryDate, isReviewed, reviewStatus, hazmatLicenseNumber, [{ entityType: 'DRIVER', entityId: entityId }]);
    } else if (isNotNullOrUndefined(hazmatLicenseBackFileId)) {
        if (isNotNullOrUndefined(hazmatLicenseExpiryDate) || isNotNullOrUndefined(hazmatLicenseNumber)) {
            await updateFileMetaData(hazmatLicenseBackFileId, { expiryDate: hazmatLicenseExpiryDate, licenseNumber: hazmatLicenseNumber, isReviewed: isReviewed ? isReviewed : false, reviewStatus: reviewStatus ? reviewStatus : 'PENDING' });
        }
    }

    if (isNotNullOrUndefined(driverLicenseFrontFile)) {
        await deleteFile(driverLicenseFrontFileId);
        await addFile(driverLicenseFrontFile, "DRIVER", entityId, "DRIVERS_LICENSE_FRONT", "Front of Drivers License", "", "ALL", driverLicenseExpiryDate, isReviewed, reviewStatus, driverLicenseNumber, [{ entityType: 'DRIVER', entityId: entityId }]);
    } else if (isNotNullOrUndefined(driverLicenseFrontFileId)) {
        if (isNotNullOrUndefined(driverLicenseExpiryDate) || isNotNullOrUndefined(driverLicenseNumber)) {
            await updateFileMetaData(driverLicenseFrontFileId, { expiryDate: driverLicenseExpiryDate, licenseNumber: driverLicenseNumber, isReviewed: isReviewed ? isReviewed : false, reviewStatus: reviewStatus ? reviewStatus : 'PENDING' });
        }
    }

    if (isNotNullOrUndefined(driverLicenseBackFile)) {
        await deleteFile(driverLicenseBackFileId);
        await addFile(driverLicenseBackFile, "DRIVER", entityId, "DRIVERS_LICENSE_BACK", "Back of Drivers License", "", "ALL", driverLicenseExpiryDate, isReviewed, reviewStatus, driverLicenseNumber, [{ entityType: 'DRIVER', entityId: entityId }]);
    } else if (isNotNullOrUndefined(driverLicenseBackFileId)) {
        if (isNotNullOrUndefined(driverLicenseExpiryDate) || isNotNullOrUndefined(driverLicenseNumber)) {
            await updateFileMetaData(driverLicenseBackFileId, { expiryDate: driverLicenseExpiryDate, licenseNumber: driverLicenseNumber, isReviewed: isReviewed ? isReviewed : false, reviewStatus: reviewStatus ? reviewStatus : 'PENDING' });
        }
    }

    if (isNotNullOrUndefined(commercialLicenseFrontFile)) {
        await deleteFile(commercialLicenseFrontFileId);
        await addFile(commercialLicenseFrontFile, "DRIVER", entityId, "COMMERCIAL_DRIVERS_LICENSE_FRONT", "Front of Commercial Drivers License", "", "ALL", commercialLicenseExpiryDate, isReviewed, reviewStatus, commercialLicenseNumber, [{ entityType: 'DRIVER', entityId: entityId }]);
    } else if (isNotNullOrUndefined(commercialLicenseFrontFileId)) {
        if (isNotNullOrUndefined(commercialLicenseExpiryDate) || isNotNullOrUndefined(commercialLicenseNumber)) {
            await updateFileMetaData(commercialLicenseFrontFileId, { expiryDate: commercialLicenseExpiryDate, licenseNumber: commercialLicenseNumber, isReviewed: isReviewed ? isReviewed : false, reviewStatus: reviewStatus ? reviewStatus : 'PENDING' });
        }
    }

    if (isNotNullOrUndefined(commercialLicenseBackFile)) {
        await deleteFile(commercialLicenseBackFileId);
        await addFile(commercialLicenseBackFile, "DRIVER", entityId, "COMMERCIAL_DRIVERS_LICENSE_BACK", "Back of Commercial Drivers License", "", "ALL", commercialLicenseExpiryDate, isReviewed, reviewStatus, commercialLicenseNumber, [{ entityType: 'DRIVER', entityId: entityId }]);
    } else if (isNotNullOrUndefined(commercialLicenseBackFileId)) {
        if (isNotNullOrUndefined(commercialLicenseExpiryDate) || isNotNullOrUndefined(commercialLicenseNumber)) {
            await updateFileMetaData(commercialLicenseBackFileId, { expiryDate: commercialLicenseExpiryDate, licenseNumber: commercialLicenseNumber, isReviewed: isReviewed ? isReviewed : false, reviewStatus: reviewStatus ? reviewStatus : 'PENDING' });
        }
    }

    if (isNotNullOrUndefined(pneumaticLicenseFrontFile)) {
        await deleteFile(pneumaticLicenseFrontFileId);
        await addFile(pneumaticLicenseFrontFile, "DRIVER", entityId, "PNEUMATIC_LICENSE_FRONT", "Front of Pneumatic License", "", "ALL", pneumaticLicenseExpiryDate, isReviewed, reviewStatus, pneumaticLicenseNumber, [{ entityType: 'DRIVER', entityId: entityId }]);
    } else if (isNotNullOrUndefined(pneumaticLicenseFrontFileId)) {
        if (isNotNullOrUndefined(pneumaticLicenseExpiryDate) || isNotNullOrUndefined(pneumaticLicenseNumber)) {
            await updateFileMetaData(pneumaticLicenseFrontFileId, { expiryDate: pneumaticLicenseExpiryDate, licenseNumber: pneumaticLicenseNumber, isReviewed: isReviewed ? isReviewed : false, reviewStatus: reviewStatus ? reviewStatus : 'PENDING' });
        }
    }

    if (isNotNullOrUndefined(pneumaticLicenseBackFile)) {
        await deleteFile(pneumaticLicenseBackFileId);
        await addFile(pneumaticLicenseBackFile, "DRIVER", entityId, "PNEUMATIC_LICENSE_BACK", "Back of Pneumatic License", "", "ALL", pneumaticLicenseExpiryDate, isReviewed, reviewStatus, pneumaticLicenseNumber, [{ entityType: 'DRIVER', entityId: entityId }]);
    } else if (isNotNullOrUndefined(pneumaticLicenseBackFileId)) {
        if (isNotNullOrUndefined(pneumaticLicenseExpiryDate) || isNotNullOrUndefined(pneumaticLicenseNumber)) {
            await updateFileMetaData(pneumaticLicenseBackFileId, { expiryDate: pneumaticLicenseExpiryDate, licenseNumber: pneumaticLicenseNumber, isReviewed: isReviewed ? isReviewed : false, reviewStatus: reviewStatus ? reviewStatus : 'PENDING' });
        }
    }

    if (isNotNullOrUndefined(mcCertificateLicenseFrontFile)) {
        await deleteFile(mcCertificateLicenseFrontFileId);
        await addFile(mcCertificateLicenseFrontFile, "DRIVER", entityId, "MC_CERTIFICATE_LICENSE_FRONT", "Front of MC Certificate License", "", "ALL", mcCertificateLicenseExpiryDate, isReviewed, reviewStatus, mcCertificateLicenseNumber, [{ entityType: 'DRIVER', entityId: entityId }]);
    } else if (isNotNullOrUndefined(mcCertificateLicenseFrontFileId)) {
        if (isNotNullOrUndefined(mcCertificateLicenseExpiryDate) || isNotNullOrUndefined(mcCertificateLicenseNumber)) {
            await updateFileMetaData(mcCertificateLicenseFrontFileId, { expiryDate: mcCertificateLicenseExpiryDate, licenseNumber: mcCertificateLicenseNumber, isReviewed: isReviewed ? isReviewed : false, reviewStatus: reviewStatus ? reviewStatus : 'PENDING' });
        }
    }

    if (isNotNullOrUndefined(mcCertificateLicenseBackFile)) {
        await deleteFile(mcCertificateLicenseBackFileId);
        await addFile(mcCertificateLicenseBackFile, "DRIVER", entityId, "MC_CERTIFICATE_LICENSE_BACK", "Back of MC Certificate License", "", "ALL", mcCertificateLicenseExpiryDate, isReviewed, reviewStatus, mcCertificateLicenseNumber, [{ entityType: 'DRIVER', entityId: entityId }]);
    } else if (isNotNullOrUndefined(mcCertificateLicenseBackFileId)) {
        if (isNotNullOrUndefined(mcCertificateLicenseExpiryDate) || isNotNullOrUndefined(mcCertificateLicenseNumber)) {
            await updateFileMetaData(mcCertificateLicenseBackFileId, { expiryDate: mcCertificateLicenseExpiryDate, licenseNumber: mcCertificateLicenseNumber, isReviewed: isReviewed ? isReviewed : false, reviewStatus: reviewStatus ? reviewStatus : 'PENDING' });
        }
    }

    if (isNotNullOrUndefined(explosiveCertifiedLicenseFrontFile)) {
        await deleteFile(explosiveCertifiedLicenseFrontFileId);
        await addFile(explosiveCertifiedLicenseFrontFile, "DRIVER", entityId, "EXPLOSIVE_CERTIFIED_LICENSE_FRONT", "Front of Explosive Certified License", "", "ALL", explosiveCertifiedLicenseExpiryDate, isReviewed, reviewStatus, explosiveCertifiedLicenseNumber, [{ entityType: 'DRIVER', entityId: entityId }]);
    } else if (isNotNullOrUndefined(explosiveCertifiedLicenseFrontFileId)) {
        if (isNotNullOrUndefined(explosiveCertifiedLicenseExpiryDate) || isNotNullOrUndefined(explosiveCertifiedLicenseNumber)) {
            await updateFileMetaData(explosiveCertifiedLicenseFrontFileId, { expiryDate: explosiveCertifiedLicenseExpiryDate, licenseNumber: explosiveCertifiedLicenseNumber, isReviewed: isReviewed ? isReviewed : false, reviewStatus: reviewStatus ? reviewStatus : 'PENDING' });
        }
    }

    if (isNotNullOrUndefined(explosiveCertifiedLicenseBackFile)) {
        await deleteFile(explosiveCertifiedLicenseBackFileId);
        await addFile(explosiveCertifiedLicenseBackFile, "DRIVER", entityId, "EXPLOSIVE_CERTIFIED_LICENSE_BACK", "Back of Explosive Certified License", "", "ALL", explosiveCertifiedLicenseExpiryDate, isReviewed, reviewStatus, explosiveCertifiedLicenseNumber, [{ entityType: 'DRIVER', entityId: entityId }]);
    } else if (isNotNullOrUndefined(explosiveCertifiedLicenseBackFileId)) {
        if (isNotNullOrUndefined(explosiveCertifiedLicenseExpiryDate) || isNotNullOrUndefined(explosiveCertifiedLicenseNumber)) {
            await updateFileMetaData(explosiveCertifiedLicenseBackFileId, { expiryDate: explosiveCertifiedLicenseExpiryDate, licenseNumber: explosiveCertifiedLicenseNumber, isReviewed: isReviewed ? isReviewed : false, reviewStatus: reviewStatus ? reviewStatus : 'PENDING' });
        }
    }

    if (isNotNullOrUndefined(mshaLicenseFrontFile)) {
        await deleteFile(mshaLicenseFrontFileId);
        await addFile(mshaLicenseFrontFile, "DRIVER", entityId, "MSHA_LICENSE_FRONT", "Front of MSHA License", "", "ALL", mshaLicenseExpiryDate, isReviewed, reviewStatus, mshaLicenseNumber, [{ entityType: 'DRIVER', entityId: entityId }]);
    } else if (isNotNullOrUndefined(mshaLicenseFrontFileId)) {
        if (isNotNullOrUndefined(mshaLicenseExpiryDate) || isNotNullOrUndefined(mshaLicenseNumber)) {
            await updateFileMetaData(mshaLicenseFrontFileId, { expiryDate: mshaLicenseExpiryDate, licenseNumber: mshaLicenseNumber, isReviewed: isReviewed ? isReviewed : false, reviewStatus: reviewStatus ? reviewStatus : 'PENDING' });
        }
    }

    if (isNotNullOrUndefined(mshaLicenseBackFile)) {
        await deleteFile(mshaLicenseBackFileId);
        await addFile(mshaLicenseBackFile, "DRIVER", entityId, "MSHA_LICENSE_BACK", "Back of MSHA License", "", "ALL", mshaLicenseExpiryDate, isReviewed, reviewStatus, mshaLicenseNumber, [{ entityType: 'DRIVER', entityId: entityId }]);
    } else if (isNotNullOrUndefined(mshaLicenseBackFileId)) {
        if (isNotNullOrUndefined(mshaLicenseExpiryDate) || isNotNullOrUndefined(mshaLicenseNumber)) {
            await updateFileMetaData(mshaLicenseBackFileId, { expiryDate: mshaLicenseExpiryDate, licenseNumber: mshaLicenseNumber, isReviewed: isReviewed ? isReviewed : false, reviewStatus: reviewStatus ? reviewStatus : 'PENDING' });
        }
    }

    return omit(driver, "hazmatLicenseFrontFile", "hazmatLicenseBackFile", "driverLicenseFrontFile",
        "driverLicenseBackFile", "commercialLicenseFrontFile", "commercialLicenseBackFile",
        "pneumaticLicenseFrontFile", "pneumaticLicenseBackFile", "mcCertificateLicenseFrontFile",
        "mcCertificateLicenseBackFile", "explosiveCertifiedLicenseFrontFile", "explosiveCertifiedLicenseBackFile",
        "mshaLicenseFrontFile", "mshaLicenseBackFile", "hazmatLicenseExpiryDate", "driverLicenseExpiryDate",
        "commercialLicenseExpiryDate", "pneumaticLicenseExpiryDate", "mcCertificateLicenseExpiryDate",
        "explosiveCertifiedLicenseExpiryDate", "mshaLicenseExpiryDate", "hazmatLicenseFrontFileId", "hazmatLicenseBackFileId",
        "driverLicenseFrontFileId", "driverLicenseBackFileId", "commercialLicenseFrontFileId", "commercialLicenseBackFileId",
        "pneumaticLicenseFrontFileId", "pneumaticLicenseBackFileId", "mcCertificateLicenseFrontFileId", "mcCertificateLicenseBackFileId",
        "explosiveCertifiedLicenseFrontFileId", "explosiveCertifiedLicenseBackFileId", "mshaLicenseFrontFileId", "mshaLicenseBackFileId",
        "hazmatLicenseNumber", "driverLicenseNumber", "commercialLicenseNumber", "pneumaticLicenseNumber",
        "mcCertificateLicenseNumber", "explosiveCertifiedLicenseNumber", "mshaLicenseNumber");
};

export const updateDriverFile = async (payload, entityId, entityType) => {
    let filesWereUpdated = false;

    const frontFile = payload.frontFile;
    const backFile = payload.backFile;
    const frontDocumentType = payload.frontDocumentType;
    const backDocumentType = payload.backDocumentType;
    const expiryDate = isNotNullOrUndefined(payload.expiryDate) ? payload.expiryDate : null;
    const licenseNumber = isNotNullOrUndefined(payload.licenseNumber) ? payload.licenseNumber : null;
    const frontFileId = payload.frontFileId;
    const backFileId = payload.backFileId;

    if (isNotNullOrUndefined(frontFile)) {
        let fileWasDeleted = await deleteFile(frontFileId);
        let frontFileData = await addFile(frontFile, "DRIVER", entityId, frontDocumentType, enums.DriverDocumentTypes.getValueByName(frontDocumentType), "", "ALL", expiryDate, null, null, licenseNumber, [{ entityType: 'DRIVER', entityId: entityId }]);
        if (isNotNullOrUndefined(frontFileData) && fileWasDeleted === true) {
            filesWereUpdated = true;
        }
    } else if (isNotNullOrUndefined(frontFileId)) {
        if (isNotNullOrUndefined(expiryDate) || isNotNullOrUndefined(licenseNumber)) {
            let updatedData = {
                expiryDate: expiryDate,
                licenseNumber: licenseNumber
            };

            if (entityType !== 'STAFF') {
                updatedData.isReviewed = false;
                updatedData.reviewStatus = 'PENDING';
            }

            await updateFileMetaData(frontFileId, updatedData);
            filesWereUpdated = true;
        }
    }

    if (isNotNullOrUndefined(backFile)) {
        let fileWasDeleted = await deleteFile(backFileId);
        let backFileData = await addFile(backFile, "DRIVER", entityId, backDocumentType, enums.DriverDocumentTypes.getValueByName(backDocumentType), "", "ALL", expiryDate, null, null, licenseNumber, [{ entityType: 'DRIVER', entityId: entityId }]);
        if (isNotNullOrUndefined(backFileData) && fileWasDeleted === true) {
            filesWereUpdated = true;
        }
    } else if (isNotNullOrUndefined(backFileId)) {
        if (isNotNullOrUndefined(expiryDate) || isNotNullOrUndefined(licenseNumber)) {
            let updatedData = {
                expiryDate: expiryDate,
                licenseNumber: licenseNumber
            };

            if (entityType !== 'STAFF') {
                updatedData.isReviewed = false;
                updatedData.reviewStatus = 'PENDING';
            }

            await updateFileMetaData(backFileId, updatedData);
            filesWereUpdated = true;
        }
    }

    return filesWereUpdated;
};

export const doesAccountUserExistByEmailAddress = async (emailAddress, accountId, config = {}) => {
    let users = await Data.getUsersByEmailAddress(emailAddress, config);
    if (isListNotEmpty(users)) {
        let userIds = users.map(u => u.userId);
        let accountUsers = await getAccountUsersByUserIds(userIds, accountId);
        if (isListNotEmpty(accountUsers)) {
            return true;
        }
    }

    return false;
};

export const doesAccountUserExistByPhoneNumber = async (phoneNumber, accountId, config = {}) => {
    let users = await Data.getUsersByPhoneNumber(phoneNumber, config);
    if (isListNotEmpty(users)) {
        let userIds = users.map(u => u.userId);
        let accountUsers = await getAccountUsersByUserIds(userIds, accountId);
        if (isListNotEmpty(accountUsers)) {
            return true;
        }
    }

    return false;
};

//#endregion