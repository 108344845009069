import * as actionTypes from "../actions/actionTypes";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import * as DTO from "../../api/dtos/index";
import { isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import ErrorUtils from "../../shared/errorUtils";
import * as actionCreators from "./index";

//#region Fetch Location List Methods

export const fetchLocationListStart = (listName) => {
    return {
        type: actionTypes.FETCH_LOCATION_LIST_START,
        payload: { listName: listName }
    }
};

export const fetchLocationListSuccess = (listName, payload) => {
    return {
        type: actionTypes.FETCH_LOCATION_LIST_SUCCESS,
        payload: { listName: listName, ...payload }
    }
};

export const fetchLocationListFail = (listName, payload) => {
    return {
        type: actionTypes.FETCH_LOCATION_LIST_FAIL,
        payload: { listName: listName, ...payload }
    }
};

export const clearLocationList = (listName) => {
    return {
        type: actionTypes.CLEAR_LOCATION_LIST,
        payload: { listName: listName }
    }
};

export const clearLocationLists = () => {
    return {
        type: actionTypes.CLEAR_LOCATION_LISTS
    }
};

export const addLocationToLocationList = (listName, payload) => {
    return {
        type: actionTypes.ADD_TO_LOCATION_LIST,
        payload: { listName: listName, newRecord: payload }
    }
};

export const updateLocationInLocationList = (listName, payload) => {
    return {
        type: actionTypes.UPDATE_IN_LOCATION_LIST,
        payload: { listName: listName, updatedRecord: payload }
    }
};

export const removeLocationFromLocationList = (listName, payload) => {
    return {
        type: actionTypes.REMOVE_FROM_LOCATION_LIST,
        payload: { listName: listName, recordToRemove: payload }
    }
};

//#endregion
//#region Fetch Location Methods

export const fetchLocationStart = () => {
    return {
        type: actionTypes.FETCH_LOCATION_START
    }
};

export const fetchLocationSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_LOCATION_SUCCESS,
        payload: payload
    }
};

export const fetchLocationFail = (payload) => {
    return {
        type: actionTypes.FETCH_LOCATION_FAIL,
        payload: payload
    }
};

export const clearLocation = () => {
    return {
        type: actionTypes.CLEAR_LOCATION
    }
};

//#endregion
//#region Add Location Methods

export const addLocationStart = () => {
    return {
        type: actionTypes.ADD_LOCATION_START
    }
};

export const addLocationSuccess = () => {
    return {
        type: actionTypes.ADD_LOCATION_SUCCESS
    }
};

export const addLocationFail = (payload) => {
    return {
        type: actionTypes.ADD_LOCATION_FAIL,
        payload: payload
    }
};

export const addLocationLoadingClear = () => {
    return {
        type: actionTypes.ADD_LOCATION_LOADING_CLEAR
    }
};

export const addLocationErrorClear = () => {
    return {
        type: actionTypes.ADD_LOCATION_ERROR_CLEAR
    }
};

export const addLocationCancel = () => {
    return {
        type: actionTypes.ADD_LOCATION_CANCEL
    }
};

//#endregion
//#region Update Location Methods

const changeSingleLocation = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_LOCATION,
        payload: payload
    }
};

export const updateLocationStart = () => {
    return {
        type: actionTypes.UPDATE_LOCATION_START
    }
};

export const updateLocationSuccess = () => {
    return {
        type: actionTypes.UPDATE_LOCATION_SUCCESS
    }
};

export const updateLocationFail = (payload) => {
    return {
        type: actionTypes.UPDATE_LOCATION_FAIL,
        payload: payload
    }
};

export const updateLocationLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_LOCATION_LOADING_CLEAR
    }
};

export const updateLocationErrorClear = () => {
    return {
        type: actionTypes.UPDATE_LOCATION_ERROR_CLEAR
    }
};

export const updateLocationCancel = () => {
    return {
        type: actionTypes.UPDATE_LOCATION_CANCEL
    }
};

//#endregion
//#region Locations Methods

export const fetchLocationList = (listName, payload, entityId = null) => {
    return async (dispatch, getState) => {
        if (isStringNotEmpty(listName)) {
            try {
                dispatch(fetchLocationListStart(listName));

                await dispatch(actionCreators.getAccounts());
                await dispatch(actionCreators.getCommodities());
                const state = getState();
                const orchestratorState = { ...state.orchestrator };
                const accounts = [...orchestratorState.accounts];
                const commodities = [...orchestratorState.commodities];
                const locationsState = { ...state.locations };
                const existingLists = { ...locationsState.lists };

                let pagination = {};
                let searchParams = {};
                if (isObjectNotEmpty(existingLists[listName])) {
                    pagination = { ...existingLists[listName].pagination };
                    searchParams = { ...existingLists[listName].searchParams };
                }

                if (isObjectNotEmpty(payload)) {
                    searchParams = { ...payload };
                }

                const res = await Data.getLocations({ ...searchParams }, pagination, accounts, commodities, entityId);
                dispatch(fetchLocationListSuccess(listName, { records: res.data, params: { searchParams: res.searchParams, pagination: res.pagination } }));
            } catch (error) {
                logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
                dispatch(fetchLocationListFail(listName, { error: ErrorUtils.getErrorMessage(error) }));
            }
        }
    }
};

export const fetchLocation = (id, entityId = null) => {
    return async (dispatch, getState) => {
        try {
            dispatch(fetchLocationStart());

            await dispatch(actionCreators.getAccounts());
            await dispatch(actionCreators.getCommodities());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const accounts = [...orchestratorState.accounts];
            const commodities = [...orchestratorState.commodities];

            const location = await Data.getLocation(id, accounts, commodities, entityId);
            dispatch(fetchLocationSuccess({ record: location }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchLocationFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const addLocation = (payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(addLocationStart());

            await dispatch(actionCreators.getAccounts());
            await dispatch(actionCreators.getCommodities());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const accounts = [...orchestratorState.accounts];
            const commodities = [...orchestratorState.commodities];

            const newLocation = await Data.addLocation(payload, accounts, commodities);
            if (isObjectNotEmpty(newLocation)) {
                if (isListNotEmpty(newLocation.links)) {
                    newLocation.links.forEach((link) => {
                        let transformedLocation = DTO.getLocationDTO(newLocation, accounts, commodities, link.accountId, link.linkedAccountId);
                        dispatch(addLocationToLocationList(`${isStringNotEmpty(link.accountId) ? link.accountId : 'ALL'}_${isStringNotEmpty(link.linkedAccountId) ? link.linkedAccountId : 'ALL'}`, transformedLocation));
                        dispatch(actionCreators.getLocations(link.accountId, true))
                    });
                }
            }

            dispatch(addLocationSuccess());
            dispatch(addLocationLoadingClear());
            dispatch(addLocationErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addLocationFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateLocation = (id, payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updateLocationStart());

            if (payload.isDeleted === true) {
                const removedLocation = await Data.removeLocation(id);
                if (isObjectNotEmpty(removedLocation)) {
                    if (isListNotEmpty(removedLocation.links)) {
                        removedLocation.links.forEach((link) => {
                            dispatch(removeLocationFromLocationList(`${isStringNotEmpty(link.accountId) ? link.accountId : 'ALL'}_${isStringNotEmpty(link.linkedAccountId) ? link.linkedAccountId : 'ALL'}`, removedLocation));
                        });
                    }

                    dispatch(actionCreators.getLocations(payload.accountId, true));
                }
            } else {
                await dispatch(actionCreators.getAccounts());
                await dispatch(actionCreators.getCommodities());
                const state = getState();
                const orchestratorState = { ...state.orchestrator };
                const accounts = [...orchestratorState.accounts];
                const commodities = [...orchestratorState.commodities];

                const updatedLocation = await Data.updateLocation(id, payload, accounts, commodities);
                if (isObjectNotEmpty(updatedLocation)) {
                    if (isListNotEmpty(updatedLocation.links)) {
                        updatedLocation.links.forEach((link) => {
                            let transformedLocation = DTO.getLocationDTO(updatedLocation, accounts, commodities, link.accountId, link.linkedAccountId);
                            dispatch(updateLocationInLocationList(`${isStringNotEmpty(link.accountId) ? link.accountId : 'ALL'}_${isStringNotEmpty(link.linkedAccountId) ? link.linkedAccountId : 'ALL'}`, transformedLocation));
                        });
                    }
                    dispatch(actionCreators.getLocations(payload.accountId, true));
                    dispatch(changeSingleLocation(updatedLocation));
                }
            }

            dispatch(updateLocationSuccess());
            dispatch(updateLocationLoadingClear());
            dispatch(updateLocationErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateLocationFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion