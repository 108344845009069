import * as actionTypes from "../actions/actionTypes";
import jwt from 'jwt-decode';
import moment from 'moment';
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { isBooleanFalse, isBooleanTrue, isListNotEmpty, isNotNullOrUndefined, isObjectNotEmpty, isStringEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import LocalStorage from "../../shared/localStorageUtils";
import * as actionCreators from "./index";
import ErrorUtils from "../../shared/errorUtils";

//#region Auth Functions

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
};

export const authUpdate = (payload) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        payload: payload
    }
};

export const authSuccess = (payload) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        payload: payload
    }
};

export const authFail = (payload) => {
    return {
        type: actionTypes.AUTH_FAIL,
        payload: payload
    }
};

export const authClearError = () => {
    return {
        type: actionTypes.AUTH_CLEAR_ERROR
    };
};

export const logout = () => {
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

//#endregion
//#region Auth Methods

export const completeLogout = () => {
    return async (dispatch) => {
        // Remove Token from Local Storage
        logger.logEvent('completeLogout', 'signing out', true);
        await LocalStorage.removeItem('token');
        await LocalStorage.removeItem('refreshToken');
        await LocalStorage.removeItem('userId');
        await LocalStorage.removeItem('userProfileId');
        await LocalStorage.removeItem('expirationDate');
        await LocalStorage.removeItem('firstName');
        await LocalStorage.removeItem('lastName');
        await LocalStorage.removeItem('profileImageId');
        await LocalStorage.removeItem('yourAccount');
        await LocalStorage.removeItem('yourAccountUser');
        await LocalStorage.removeItem('yourAccounts');
        await LocalStorage.removeItem('yourAccountUsers');
        await LocalStorage.removeItem('noAccount');
        await LocalStorage.removeItem('firstTime');
        await LocalStorage.removeItem('deviceId');
        await LocalStorage.removeItem('activeLoadId');
        await LocalStorage.removeItem('activeLoadIrisId');
        await LocalStorage.removeItem('currentLocation');
        await LocalStorage.removeItem('sendToTMS');
        await LocalStorage.removeItem('notMovedCount');
        await LocalStorage.removeItem('distanceTravelled');
        await LocalStorage.removeItem('currentStopId');
        await LocalStorage.removeItem('currentStopStatus');
        await LocalStorage.removeItem('activeLoadStatus');

        await LocalStorage.removeItem('locations');
        await LocalStorage.removeItem('employees');
        await LocalStorage.removeItem('drivers');
        await LocalStorage.removeItem('assets');
        await LocalStorage.removeItem('accounts');
        await LocalStorage.removeItem('states');
        await LocalStorage.removeItem('commodities');
        await LocalStorage.removeItem('staff');
        await LocalStorage.removeItem('trailerTypes');
        await LocalStorage.removeItem('linkedAccounts');
        await LocalStorage.removeItem('keys');
        await LocalStorage.removeItem('features');
        await LocalStorage.removeItem('lineItemTypes');
        await LocalStorage.removeItem('services');

        dispatch(actionCreators.clearYourAccountUsers());
        dispatch(actionCreators.clearYourAccountUser());
        dispatch(actionCreators.clearYourAccounts());
        dispatch(actionCreators.clearYourAccount());
        dispatch(actionCreators.clearUser());
        dispatch(actionCreators.clearKeys());
        dispatch(actionCreators.clearMessageLists());
        dispatch(actionCreators.clearNotificationLists());

        return dispatch(logout());
    };
};

export const autoLogin = (refreshAccounts = false) => {
    return async (dispatch, getState) => {
        try {
            dispatch(authStart());
            // console.log('start autologin');

            // Get token from local Storage
            const token = await LocalStorage.getItem('token');
            if (isStringNotEmpty(token)) {
                dispatch(authUpdate({ token: token }));
            }

            // Get refresh token from local Storage
            const refreshToken = await LocalStorage.getItem('refreshToken');

            // If token is not present, logout
            if (isStringEmpty(refreshToken)) {
                logger.logDebugEvent('autoLogin_logout', 'bad token', true);
                await dispatch(completeLogout());
            } else {
                let refreshTokenDecoded = jwt(refreshToken);
                let endDate = moment.unix(refreshTokenDecoded.exp);

                // If Token is present, get the expiration date
                let refreshTokenExpirationDate = endDate.toDate();

                const today = new Date();

                if (refreshTokenExpirationDate > today) {
                    let withProfileImageId = false;
                    // console.log('expiration is greater than today');
                    let profileImageId = await LocalStorage.getItem('profileImageId');
                    if (isStringEmpty(profileImageId)) {
                        withProfileImageId = true;
                    }

                    const resp = await Data.getRefreshToken(refreshToken, withProfileImageId);
                    if (isObjectNotEmpty(resp)) {
                        await LocalStorage.setItem('token', resp.newToken);
                        await LocalStorage.setItem('refreshToken', resp.newRefreshToken);
                        await LocalStorage.setItem('userId', resp.userId.toString());
                        await LocalStorage.setItem('userProfileId', resp.userProfileId.toString());
                        await LocalStorage.setItem('expirationDate', resp.newEndDate.toDate().toString());
                        await LocalStorage.setItem('firstName', resp.firstName.toString());
                        await LocalStorage.setItem('lastName', resp.lastName.toString());

                        if (isStringNotEmpty(resp.profileImageId)) {
                            profileImageId = resp.profileImageId;
                            await LocalStorage.setItem('profileImageId', resp.profileImageId);
                        }

                        // check to see if the user isActive and if they aren't, don't let them login
                        if (resp.isUserActive === false) {
                            logger.logDebugEvent('autoLogin_logout', 'user isnt active', true);
                            await dispatch(completeLogout());
                        } else {
                            // console.log('start getting orchestrator');
                            // await dispatch(actionCreators.getOrchestrator(false));
                            // console.log('stop getting orchestrator');
                            await dispatch(actionCreators.fetchKeys());

                            // if the user is staff then no need to get account users and accounts, just authenticate them
                            if (resp.isStaff === true) {
                                // check to see if staff member is mimicking a customer account
                                // Get Account User Data
                                let selectedAccountUser = null;
                                let selectedAccount = null;
                                const yourAccountUser = await LocalStorage.getItem('yourAccountUser');
                                const yourAccount = await LocalStorage.getItem('yourAccount');
                                // console.log(yourAccountUsers);
                                // console.log(yourAccounts);
                                if (isStringNotEmpty(yourAccountUser) && isStringNotEmpty(yourAccount)) {
                                    selectedAccountUser = JSON.parse(yourAccountUser);
                                    selectedAccount = JSON.parse(yourAccount);

                                    // Dispatch Account Users Data to Your Accounts Users Store
                                    dispatch(actionCreators.fetchYourAccountUsersSuccess({ record: selectedAccountUser }));

                                    // Dispatch Accounts Data to Your Accounts Store
                                    dispatch(actionCreators.fetchYourAccountsSuccess({ record: selectedAccount }));

                                    if (isObjectNotEmpty(selectedAccountUser) && isObjectNotEmpty(selectedAccount)) {
                                        // check that account is active
                                        // check that account user is verified
                                        if (selectedAccountUser.isVerified === true) {
                                            if (selectedAccount.isActive === true) {
                                                let entityType = null;
                                                let entityId = null;
                                                let entityIrisId = null;

                                                if ((isListNotEmpty(selectedAccountUser.accountRoles) || isBooleanFalse(selectedAccountUser.isDriver)) && isBooleanTrue(selectedAccount.entityTypes.includes("CARRIER"))) {
                                                    entityType = "CARRIER";
                                                    entityIrisId = selectedAccount.irisId;
                                                    entityId = selectedAccount.id;
                                                } else if (isBooleanTrue(selectedAccount.entityTypes.includes("CARRIER")) && isBooleanTrue(selectedAccountUser.isDriver)) {
                                                    entityType = "DRIVER";
                                                    if (isObjectNotEmpty(selectedAccountUser.driver)) {
                                                        entityIrisId = selectedAccountUser.driver.irisId;
                                                    }
                                                    entityId = selectedAccountUser.id;
                                                } else if (isBooleanTrue(selectedAccount.entityTypes.includes("SHIPPER"))) {
                                                    entityType = "SHIPPER";
                                                    entityIrisId = selectedAccount.irisId;
                                                    entityId = selectedAccount.id;
                                                } else if (isBooleanTrue(selectedAccount.entityTypes.includes("RECEIVER"))) {
                                                    entityType = "RECEIVER";
                                                    entityIrisId = selectedAccount.irisId;
                                                    entityId = selectedAccount.id;
                                                } else if (isBooleanTrue(selectedAccount.entityTypes.includes("PRODUCER"))) {
                                                    entityType = "PRODUCER";
                                                    entityIrisId = selectedAccount.irisId;
                                                    entityId = selectedAccount.id;
                                                } else if (isBooleanTrue(selectedAccount.entityTypes.includes("BROKER"))) {
                                                    entityType = "BROKER";
                                                    entityIrisId = selectedAccount.irisId;
                                                    entityId = selectedAccount.id;
                                                } else if (isBooleanTrue(selectedAccount.entityTypes.includes("FACTORING"))) {
                                                    entityType = "FACTORING";
                                                    entityIrisId = selectedAccount.irisId;
                                                    entityId = selectedAccount.id;
                                                }

                                                if (isNotNullOrUndefined(entityType) && isNotNullOrUndefined(entityId)) {
                                                    await dispatch(actionCreators.getServices());
                                                    const state = getState();
                                                    const orchestratorState = { ...state.orchestrator };
                                                    let services = [...orchestratorState.services];

                                                    let featuresList = [];
                                                    if (isListNotEmpty(selectedAccount.accountServices) && isListNotEmpty(services)) {
                                                        selectedAccount.accountServices.forEach((accountService) => {
                                                            let service = services.find(item => item.name === accountService.serviceName);
                                                            if (isObjectNotEmpty(service) && isListNotEmpty(service.features)) {
                                                                service.features.forEach((feature) => {
                                                                    if (feature.isAvailable === true && isListNotEmpty(feature.entityTypes) && feature.entityTypes.find(i => i === entityType) !== undefined && (feature.isBeta === false || (feature.isBeta === true && selectedAccount.isBeta === true))) {
                                                                        featuresList.push(feature.name);
                                                                    }
                                                                });
                                                            }
                                                        });
                                                    }

                                                    // console.log('start getting account orchestrator');
                                                    // await dispatch(actionCreators.getAccountOrchestrator(selectedAccount.id, entityType, false));

                                                    // Complete Auto-Login
                                                    return dispatch(authSuccess({
                                                        token: resp.newToken,
                                                        email: resp.email,
                                                        userName: resp.userName,
                                                        userId: resp.userId,
                                                        userProfileId: resp.userProfileId,
                                                        firstName: resp.firstName,
                                                        lastName: resp.lastName,
                                                        profileImageId: profileImageId,
                                                        isLoading: false,
                                                        isAuthComplete: true,
                                                        isAuthenticated: true,
                                                        entityId: entityId,
                                                        entityIrisId: entityIrisId,
                                                        entityType: entityType,
                                                        error: null,
                                                        isAdmin: resp.isAdmin,
                                                        isStaff: resp.isStaff,
                                                        isVP: resp.isVP,
                                                        isDirector: resp.isDirector,
                                                        isManager: resp.isManager,
                                                        featuresList: featuresList
                                                    }));
                                                } else {
                                                    logger.logDebugEvent('autoLogin_logout', 'user doesnt have any account roles', true);
                                                    await dispatch(completeLogout());
                                                }
                                            } else {
                                                logger.logDebugEvent('autoLogin_logout', 'account isnt active', true);
                                                await dispatch(completeLogout());
                                            }
                                        } else {
                                            logger.logDebugEvent('autoLogin_logout', 'account user isnt verified', true);
                                            await dispatch(completeLogout());
                                        }
                                    } else {
                                        logger.logDebugEvent('autoLogin_logout', 'account user or account dont exist', true);
                                        await dispatch(completeLogout());
                                    }
                                } else {
                                    // Get Account User Data
                                    let accountUsers = [];
                                    let accounts = [];
                                    const yourAccountUsers = await LocalStorage.getItem('yourAccountUsers');
                                    const yourAccounts = await LocalStorage.getItem('yourAccounts');
                                    // console.log(yourAccountUsers);
                                    // console.log(yourAccounts);
                                    if (isStringNotEmpty(yourAccountUsers) && isStringNotEmpty(yourAccounts) && refreshAccounts === false) {
                                        accountUsers = JSON.parse(yourAccountUsers);
                                        accounts = JSON.parse(yourAccounts);
                                    } else {
                                        const accountsAndAccountUsersResp = await Data.getAccountUsersAndAccountsByUserId(resp.userId);
                                        accountUsers = accountsAndAccountUsersResp.accountUsers;
                                        accounts = accountsAndAccountUsersResp.accounts;

                                        // Dispatch Account Users Data to Your Accounts Users Store
                                        if (isListNotEmpty(accountUsers)) {
                                            await LocalStorage.setItem('yourAccountUsers', JSON.stringify(accountUsers));
                                        }

                                        // Dispatch Accounts Data to Your Accounts Store
                                        if (isListNotEmpty(accounts)) {
                                            await LocalStorage.setItem('yourAccounts', JSON.stringify(accounts));
                                        }
                                    }

                                    // Dispatch Account Users Data to Your Accounts Users Store
                                    dispatch(actionCreators.fetchYourAccountUsersSuccess({ records: accountUsers }));

                                    // Dispatch Accounts Data to Your Accounts Store
                                    dispatch(actionCreators.fetchYourAccountsSuccess({ records: accounts }));

                                    return dispatch(authSuccess({
                                        token: resp.newToken,
                                        email: resp.email,
                                        userName: resp.userName,
                                        userId: resp.userId,
                                        userProfileId: resp.userProfileId,
                                        firstName: resp.firstName,
                                        lastName: resp.lastName,
                                        profileImageId: profileImageId,
                                        isAuthComplete: true,
                                        isAuthenticated: true,
                                        entityId: resp.userId,
                                        entityIrisId: 'IRIS-STAFF',
                                        entityType: 'STAFF',
                                        error: null,
                                        isAdmin: resp.isAdmin,
                                        isStaff: resp.isStaff,
                                        isVP: resp.isVP,
                                        isDirector: resp.isDirector,
                                        isManager: resp.isManager
                                    }));
                                }
                            } else {
                                // Get Account User Data
                                let accountUsers = [];
                                let accounts = [];
                                let selectedAccountUser = null;
                                let selectedAccount = null;
                                const yourAccountUsers = await LocalStorage.getItem('yourAccountUsers');
                                const yourAccounts = await LocalStorage.getItem('yourAccounts');
                                const yourAccountUser = await LocalStorage.getItem('yourAccountUser');
                                const yourAccount = await LocalStorage.getItem('yourAccount');
                                // console.log(yourAccountUsers);
                                // console.log(yourAccounts);
                                if (isStringNotEmpty(yourAccountUsers) && isStringNotEmpty(yourAccounts) && isStringNotEmpty(yourAccountUser) && isStringNotEmpty(yourAccount) && refreshAccounts === false) {
                                    accountUsers = JSON.parse(yourAccountUsers);
                                    accounts = JSON.parse(yourAccounts);
                                    selectedAccountUser = JSON.parse(yourAccountUser);
                                    selectedAccount = JSON.parse(yourAccount);
                                } else {
                                    const accountsAndAccountUsersResp = await Data.getAccountUsersAndAccountsByUserId(resp.userId);
                                    accountUsers = accountsAndAccountUsersResp.accountUsers;
                                    accounts = accountsAndAccountUsersResp.accounts;

                                    // Dispatch Account Users Data to Your Accounts Users Store
                                    if (isListNotEmpty(accountUsers)) {
                                        await LocalStorage.setItem('yourAccountUsers', JSON.stringify(accountUsers));

                                        // TODO: for mvp we are assuming drivers belong to just one account but in the future we need to add an account switcher in case a driver or user belongs to more than one account
                                        selectedAccountUser = accountUsers[0];
                                        await LocalStorage.setItem('yourAccountUser', JSON.stringify(selectedAccountUser));

                                        if (isObjectNotEmpty(selectedAccountUser) && isObjectNotEmpty(selectedAccountUser.account)) {
                                            selectedAccount = selectedAccountUser.account;
                                            await LocalStorage.setItem('yourAccount', JSON.stringify(selectedAccount));
                                        }
                                    }

                                    // Dispatch Accounts Data to Your Accounts Store
                                    if (isListNotEmpty(accounts)) {
                                        await LocalStorage.setItem('yourAccounts', JSON.stringify(accounts));
                                    }
                                }

                                // Dispatch Account Users Data to Your Accounts Users Store
                                dispatch(actionCreators.fetchYourAccountUsersSuccess({ records: accountUsers, record: selectedAccountUser }));

                                // Dispatch Accounts Data to Your Accounts Store
                                dispatch(actionCreators.fetchYourAccountsSuccess({ records: accounts, record: selectedAccount }));

                                if (isObjectNotEmpty(selectedAccountUser) && isObjectNotEmpty(selectedAccount)) {
                                    // check that account is active
                                    // check that account user is verified
                                    if (selectedAccountUser.isVerified === true) {
                                        if (selectedAccount.isActive === true) {
                                            let entityType = null;
                                            let entityId = null;
                                            let entityIrisId = null;

                                            if ((isListNotEmpty(selectedAccountUser.accountRoles) || isBooleanFalse(selectedAccountUser.isDriver)) && isBooleanTrue(selectedAccount.entityTypes.includes("CARRIER"))) {
                                                entityType = "CARRIER";
                                                entityIrisId = selectedAccount.irisId;
                                                entityId = selectedAccount.id;
                                            } else if (isBooleanTrue(selectedAccount.entityTypes.includes("CARRIER")) && isBooleanTrue(selectedAccountUser.isDriver)) {
                                                entityType = "DRIVER";
                                                if (isObjectNotEmpty(selectedAccountUser.driver)) {
                                                    entityIrisId = selectedAccountUser.driver.irisId;
                                                }
                                                entityId = selectedAccountUser.id;
                                            } else if (isBooleanTrue(selectedAccount.entityTypes.includes("SHIPPER"))) {
                                                entityType = "SHIPPER";
                                                entityIrisId = selectedAccount.irisId;
                                                entityId = selectedAccount.id;
                                            } else if (isBooleanTrue(selectedAccount.entityTypes.includes("RECEIVER"))) {
                                                entityType = "RECEIVER";
                                                entityIrisId = selectedAccount.irisId;
                                                entityId = selectedAccount.id;
                                            } else if (isBooleanTrue(selectedAccount.entityTypes.includes("PRODUCER"))) {
                                                entityType = "PRODUCER";
                                                entityIrisId = selectedAccount.irisId;
                                                entityId = selectedAccount.id;
                                            } else if (isBooleanTrue(selectedAccount.entityTypes.includes("BROKER"))) {
                                                entityType = "BROKER";
                                                entityIrisId = selectedAccount.irisId;
                                                entityId = selectedAccount.id;
                                            } else if (isBooleanTrue(selectedAccount.entityTypes.includes("FACTORING"))) {
                                                entityType = "FACTORING";
                                                entityIrisId = selectedAccount.irisId;
                                                entityId = selectedAccount.id;
                                            }

                                            if (isNotNullOrUndefined(entityType) && isNotNullOrUndefined(entityId)) {
                                                await dispatch(actionCreators.getServices());
                                                const state = getState();
                                                const orchestratorState = { ...state.orchestrator };
                                                let services = [...orchestratorState.services];

                                                let featuresList = [];
                                                if (isListNotEmpty(selectedAccount.accountServices) && isListNotEmpty(services)) {
                                                    selectedAccount.accountServices.forEach((accountService) => {
                                                        let service = services.find(item => item.name === accountService.serviceName);
                                                        if (isObjectNotEmpty(service) && isListNotEmpty(service.features)) {
                                                            service.features.forEach((feature) => {
                                                                if (feature.isAvailable === true && isListNotEmpty(feature.entityTypes) && feature.entityTypes.find(i => i === entityType) !== undefined && (feature.isBeta === false || (feature.isBeta === true && selectedAccount.isBeta === true))) {
                                                                    featuresList.push(feature.name);
                                                                }
                                                            });
                                                        }
                                                    });
                                                }

                                                // console.log('start getting account orchestrator');
                                                // await dispatch(actionCreators.getAccountOrchestrator(selectedAccount.id, entityType, false));

                                                // Complete Auto-Login
                                                return dispatch(authSuccess({
                                                    token: resp.newToken,
                                                    email: resp.email,
                                                    userName: resp.userName,
                                                    userId: resp.userId,
                                                    userProfileId: resp.userProfileId,
                                                    firstName: resp.firstName,
                                                    lastName: resp.lastName,
                                                    profileImageId: profileImageId,
                                                    isLoading: false,
                                                    isAuthComplete: true,
                                                    isAuthenticated: true,
                                                    entityId: entityId,
                                                    entityIrisId: entityIrisId,
                                                    entityType: entityType,
                                                    error: null,
                                                    isAdmin: resp.isAdmin,
                                                    isStaff: resp.isStaff,
                                                    isVP: resp.isVP,
                                                    isDirector: resp.isDirector,
                                                    isManager: resp.isManager,
                                                    featuresList: featuresList
                                                }));
                                            } else {
                                                logger.logDebugEvent('autoLogin_logout', 'user doesnt have any account roles', true);
                                                await dispatch(completeLogout());
                                            }
                                        } else {
                                            logger.logDebugEvent('autoLogin_logout', 'account isnt active', true);
                                            await dispatch(completeLogout());
                                        }
                                    } else {
                                        logger.logDebugEvent('autoLogin_logout', 'account user isnt verified', true);
                                        await dispatch(completeLogout());
                                    }
                                } else {
                                    return dispatch(authSuccess({
                                        token: resp.newToken,
                                        email: resp.email,
                                        userName: resp.userName,
                                        userId: resp.userId,
                                        userProfileId: resp.userProfileId,
                                        firstName: resp.firstName,
                                        lastName: resp.lastName,
                                        profileImageId: profileImageId,
                                        isLoading: false,
                                        isAuthComplete: true,
                                        isAuthenticated: true,
                                        entityId: resp.userId,
                                        entityIrisId: 'NONE',
                                        entityType: 'NONE',
                                        error: null,
                                        isAdmin: resp.isAdmin,
                                        isStaff: resp.isStaff,
                                        isVP: resp.isVP,
                                        isDirector: resp.isDirector,
                                        isManager: resp.isManager,
                                        featuresList: []
                                    }));
                                }
                            }
                        }
                    } else {
                        logger.logDebugEvent('autoLogin_logout', 'new token is bad', true);
                        await dispatch(completeLogout());
                    }
                } else {
                    logger.logDebugEvent('autoLogin_logout', 'expiration date expired', true);
                    await dispatch(completeLogout());
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            logger.logDebugEvent('autoLogin_logout', 'auth failed with error', true);
            await dispatch(completeLogout());
        }
    };
};

export const register = (password, cpassword, email, firstName, lastName, phoneNumber) => {
    return async (dispatch) => {
        try {
            // Start Registration
            dispatch(authStart());

            // Register
            const resp = await Data.register(password, cpassword, email);
            if (isObjectNotEmpty(resp)) {
                await LocalStorage.setItem('token', resp.token);
                await LocalStorage.setItem('refreshToken', resp.refreshToken);

                // Update User Profile Data
                const userProfileUpdated = await Data.updateUserProfile(resp.userProfileId, resp.userId, firstName, lastName, phoneNumber, true);

                // auto-login user
                if (userProfileUpdated === true) {
                    dispatch(autoLogin(true));
                } else {
                    dispatch(authFail({ errorType: 'OTHER', error: 'Failed to setup the user profile.' }));
                }
            }
        } catch (error) {
            let errorMessage = null;
            if (error.response.status === 400) {
                if (hasErrorMessage(error)) {
                    errorMessage = error.response.data.message;
                    dispatch(authFail({ errorType: 'OTHER', error: errorMessage }));
                } else {
                    errorMessage = "An unexpected error has occurred.";
                    dispatch(authFail({ errorType: 'OTHER', error: errorMessage }));
                }
            } else {
                errorMessage = "An unexpected error has occurred.";
                dispatch(authFail({ errorType: 'OTHER', error: errorMessage }));
            }
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${errorMessage}`, true);
        }
    }
};

export const login = (email, password) => {
    return async (dispatch, getState) => {
        try {
            // Start Login In
            dispatch(authStart());

            // Log In
            const resp = await Data.login(email, password);
            if (isObjectNotEmpty(resp)) {
                // need to set here so axiosAuthenticated works for getting documents
                // Store Token to Local Storage
                await LocalStorage.setItem('token', resp.token);
                await LocalStorage.setItem('refreshToken', resp.refreshToken);
                await LocalStorage.setItem('userId', resp.userId.toString());
                await LocalStorage.setItem('userProfileId', resp.userProfileId.toString());
                await LocalStorage.setItem('expirationDate', resp.endDate.toDate().toString());
                await LocalStorage.setItem('firstName', resp.firstName.toString());
                await LocalStorage.setItem('lastName', resp.lastName.toString());

                if (isStringNotEmpty(resp.profileImageId)) {
                    await LocalStorage.setItem('profileImageId', resp.profileImageId);
                }

                // check to see if the user isActive and if they aren't, don't let them login
                if (resp.isUserActive === false) {
                    logger.logDebugEvent('login_fail', 'Your User Account is not activated.', true);
                    dispatch(authFail({ errorType: 'NOT_ACTIVATED', error: "Your User Account is not activated." }));
                } else {
                    // console.log('start getting orchestrator');
                    // await dispatch(actionCreators.getOrchestrator(true));
                    // console.log('stop getting orchestrator');
                    await dispatch(actionCreators.fetchKeys());

                    // if the user is staff then no need to get account users and accounts, just authenticate them
                    if (resp.isStaff === true) {
                        // Get Account User Data
                        let accountUsers = [];
                        let accounts = [];
                        const yourAccountUsers = await LocalStorage.getItem('yourAccountUsers');
                        const yourAccounts = await LocalStorage.getItem('yourAccounts');
                        // console.log(yourAccountUsers);
                        // console.log(yourAccounts);
                        if (isStringNotEmpty(yourAccountUsers) && isStringNotEmpty(yourAccounts)) {
                            accountUsers = JSON.parse(yourAccountUsers);
                            accounts = JSON.parse(yourAccounts);
                        } else {
                            const accountsAndAccountUsersResp = await Data.getAccountUsersAndAccountsByUserId(resp.userId);
                            accountUsers = accountsAndAccountUsersResp.accountUsers;
                            accounts = accountsAndAccountUsersResp.accounts;

                            // Dispatch Account Users Data to Your Accounts Users Store
                            if (isListNotEmpty(accountUsers)) {
                                await LocalStorage.setItem('yourAccountUsers', JSON.stringify(accountUsers));
                            }

                            // Dispatch Accounts Data to Your Accounts Store
                            if (isListNotEmpty(accounts)) {
                                await LocalStorage.setItem('yourAccounts', JSON.stringify(accounts));
                            }
                        }

                        // Dispatch Account Users Data to Your Accounts Users Store
                        dispatch(actionCreators.fetchYourAccountUsersSuccess({ records: accountUsers }));

                        // Dispatch Accounts Data to Your Accounts Store
                        dispatch(actionCreators.fetchYourAccountsSuccess({ records: accounts }));

                        // Complete Login
                        return dispatch(authSuccess({
                            token: resp.token,
                            email: resp.email,
                            userName: resp.userName,
                            userId: resp.userId,
                            userProfileId: resp.userProfileId,
                            firstName: resp.firstName,
                            lastName: resp.lastName,
                            profileImageId: resp.profileImageId,
                            isLoading: false,
                            isAuthComplete: true,
                            isAuthenticated: true,
                            entityId: resp.userId,
                            entityIrisId: 'IRIS-STAFF',
                            entityType: 'STAFF',
                            error: null,
                            isAdmin: resp.isAdmin,
                            isStaff: resp.isStaff,
                            isVP: resp.isVP,
                            isDirector: resp.isDirector,
                            isManager: resp.isManager
                        }));
                    } else {
                        // Get Account Users Data
                        const accountsAndAccountUsersResp = await Data.getAccountUsersAndAccountsByUserId(resp.userId);
                        const accountUsers = accountsAndAccountUsersResp.accountUsers;
                        const accounts = accountsAndAccountUsersResp.accounts;
                        let selectedAccountUser = null;
                        let selectedAccount = null;

                        // Dispatch Account Users Data to Your Accounts Users Store
                        if (isListNotEmpty(accountUsers)) {
                            await LocalStorage.setItem('yourAccountUsers', JSON.stringify(accountUsers));

                            // TODO: for mvp we are assuming drivers belong to just one account but in the future we need to add an account switcher in case a driver or user belongs to more than one account
                            selectedAccountUser = accountUsers[0];
                            await LocalStorage.setItem('yourAccountUser', JSON.stringify(selectedAccountUser));

                            if (isObjectNotEmpty(selectedAccountUser) && isObjectNotEmpty(selectedAccountUser.account)) {
                                selectedAccount = selectedAccountUser.account;
                                await LocalStorage.setItem('yourAccount', JSON.stringify(selectedAccount));
                            }
                        }

                        // Dispatch Accounts Data to Your Accounts Store
                        if (isListNotEmpty(accounts)) {
                            await LocalStorage.setItem('yourAccounts', JSON.stringify(accounts));
                        }

                        // Dispatch Account Users Data to Your Accounts Users Store
                        dispatch(actionCreators.fetchYourAccountUsersSuccess({ records: accountUsers, record: selectedAccountUser }));

                        // Dispatch Accounts Data to Your Accounts Store
                        dispatch(actionCreators.fetchYourAccountsSuccess({ records: accounts, record: selectedAccount }));

                        if (isObjectNotEmpty(selectedAccountUser) && isObjectNotEmpty(selectedAccount)) {
                            // check that account is active
                            //console.log(`selectedAccount.isActive:${selectedAccount.isActive}`);
                            //console.log(`selectedAccountUser.isVerified:${selectedAccountUser.isVerified}`);
                            // check that account user is verified
                            if (selectedAccountUser.isVerified === true) {
                                if (selectedAccount.isActive === true) {
                                    let entityType = null;
                                    let entityId = null;
                                    let entityIrisId = null;

                                    if ((isListNotEmpty(selectedAccountUser.accountRoles) || isBooleanFalse(selectedAccountUser.isDriver)) && isBooleanTrue(selectedAccount.entityTypes.includes("CARRIER"))) {
                                        entityType = "CARRIER";
                                        entityIrisId = selectedAccount.irisId;
                                        entityId = selectedAccount.id;
                                    } else if (isBooleanTrue(selectedAccount.entityTypes.includes("CARRIER")) && isBooleanTrue(selectedAccountUser.isDriver)) {
                                        entityType = "DRIVER";
                                        if (isObjectNotEmpty(selectedAccountUser.driver)) {
                                            entityIrisId = selectedAccountUser.driver.irisId;
                                        }
                                        entityId = selectedAccountUser.id;
                                    } else if (isBooleanTrue(selectedAccount.entityTypes.includes("SHIPPER"))) {
                                        entityType = "SHIPPER";
                                        entityIrisId = selectedAccount.irisId;
                                        entityId = selectedAccount.id;
                                    } else if (isBooleanTrue(selectedAccount.entityTypes.includes("RECEIVER"))) {
                                        entityType = "RECEIVER";
                                        entityIrisId = selectedAccount.irisId;
                                        entityId = selectedAccount.id;
                                    } else if (isBooleanTrue(selectedAccount.entityTypes.includes("PRODUCER"))) {
                                        entityType = "PRODUCER";
                                        entityIrisId = selectedAccount.irisId;
                                        entityId = selectedAccount.id;
                                    } else if (isBooleanTrue(selectedAccount.entityTypes.includes("BROKER"))) {
                                        entityType = "BROKER";
                                        entityIrisId = selectedAccount.irisId;
                                        entityId = selectedAccount.id;
                                    } else if (isBooleanTrue(selectedAccount.entityTypes.includes("FACTORING"))) {
                                        entityType = "FACTORING";
                                        entityIrisId = selectedAccount.irisId;
                                        entityId = selectedAccount.id;
                                    }

                                    if (isNotNullOrUndefined(entityType) && isNotNullOrUndefined(entityId)) {
                                        await dispatch(actionCreators.getServices());
                                        const state = getState();
                                        const orchestratorState = { ...state.orchestrator };
                                        let services = [...orchestratorState.services];

                                        let featuresList = [];
                                        if (isListNotEmpty(selectedAccount.accountServices) && isListNotEmpty(services)) {
                                            selectedAccount.accountServices.forEach((accountService) => {
                                                let service = services.find(item => item.name === accountService.serviceName);
                                                if (isObjectNotEmpty(service) && isListNotEmpty(service.features)) {
                                                    service.features.forEach((feature) => {
                                                        if (feature.isAvailable === true && isListNotEmpty(feature.entityTypes) && feature.entityTypes.find(i => i === entityType) !== undefined && (feature.isBeta === false || (feature.isBeta === true && selectedAccount.isBeta === true))) {
                                                            featuresList.push(feature.name);
                                                        }
                                                    });
                                                }
                                            });
                                        }

                                        // console.log('start getting account orchestrator');
                                        // await dispatch(actionCreators.getAccountOrchestrator(selectedAccount.id, entityType, true));

                                        // Complete Login
                                        return dispatch(authSuccess({
                                            token: resp.token,
                                            email: resp.email,
                                            userName: resp.userName,
                                            userId: resp.userId,
                                            userProfileId: resp.userProfileId,
                                            firstName: resp.firstName,
                                            lastName: resp.lastName,
                                            profileImageId: resp.profileImageId,
                                            isLoading: false,
                                            isAuthComplete: true,
                                            isAuthenticated: true,
                                            entityId: entityId,
                                            entityIrisId: entityIrisId,
                                            entityType: entityType,
                                            error: null,
                                            isAdmin: resp.isAdmin,
                                            isStaff: resp.isStaff,
                                            isVP: resp.isVP,
                                            isDirector: resp.isDirector,
                                            isManager: resp.isManager,
                                            featuresList: featuresList
                                        }));
                                    } else {
                                        logger.logDebugEvent('login_fail', 'Your User Account does not have access to its associated Account.', true);
                                        dispatch(authFail({ errorType: 'NOT_ACTIVATED', error: "Your User Account does not have access to its associated Account." }));
                                    }
                                } else {
                                    logger.logDebugEvent('login_fail', 'The Account your User Account is associated with is not activated.', true);
                                    dispatch(authFail({ errorType: 'NOT_ACTIVATED', error: "The Account your User Account is associated with is not activated." }));
                                }
                            } else {
                                logger.logDebugEvent('login_fail', 'Your User Account is not verified.', true);
                                dispatch(authFail({ errorType: 'NOT_ACTIVATED', error: "Your User Account is not verified." }));
                            }
                        } else {
                            return dispatch(authSuccess({
                                token: resp.newToken,
                                email: resp.email,
                                userName: resp.userName,
                                userId: resp.userId,
                                userProfileId: resp.userProfileId,
                                firstName: resp.firstName,
                                lastName: resp.lastName,
                                profileImageId: resp.profileImageId,
                                isLoading: false,
                                isAuthComplete: true,
                                isAuthenticated: true,
                                entityId: resp.userId,
                                entityIrisId: 'NONE',
                                entityType: 'NONE',
                                error: null,
                                isAdmin: resp.isAdmin,
                                isStaff: resp.isStaff,
                                isVP: resp.isVP,
                                isDirector: resp.isDirector,
                                isManager: resp.isManager,
                                featuresList: []
                            }));
                        }
                    }
                }
            } else {
                logger.logDebugEvent('login_fail', 'Cannot get token', true);
                dispatch(authFail({ errorType: 'OTHER', error: 'Cannot get token' }));
            }
        } catch (error) {
            // console.log(typeof (error.message));
            // console.log(typeof (error));
            let errorMessage = '';
            if (typeof error === 'string') {
                errorMessage = error;
                dispatch(authFail({ errorType: 'OTHER', error: errorMessage }));
            } else if (error.response !== undefined && error.response.status === 400) {
                errorMessage = "No user account could be found with the credentials you provided. Please try again. If you need assistance signing in, please give us a call and we will gladly assist you.";
                dispatch(authFail({ errorType: 'NO_ACCOUNT', error: errorMessage }));
            } else if (error.response !== undefined && error.response.status === 403) {
                errorMessage = "Your User Account is not activated.";
                dispatch(authFail({ errorType: 'NOT_ACTIVATED', error: errorMessage }));
            } else if (error.response !== undefined && error.response.status >= 400 && error.response.status <= 500) {
                errorMessage = "Credentials Not Valid.";
                dispatch(authFail({ errorType: 'INVALID_CREDENTIALS', error: errorMessage }));
            } else if (error.response !== undefined && error.response.data !== undefined && error.response.data.message !== undefined && typeof (error.response.data.message) === 'string') {
                errorMessage = error.response.data.message;
                dispatch(authFail({ errorType: 'OTHER', error: errorMessage }));
            } else if (error.message !== undefined && typeof (error.message) === 'string') {
                errorMessage = error.message;
                dispatch(authFail({ errorType: 'OTHER', error: errorMessage }));
            } else {
                errorMessage = "An unexpected error has occurred.";
                dispatch(authFail({ errorType: 'OTHER', error: errorMessage }));
            }

            logger.logReduxErrorEvent(error, `Error fetching Requests: ${errorMessage}`, true);
        }
    }
};

export const passwordlessLogin = (phoneNumber) => {
    return async (dispatch) => {
        try {
            // Start Passwordless Login
            dispatch(authStart());

            // Passwordless Login
            const resp = await Data.loginWithPasswordless(phoneNumber);
            if (resp === true) {
                dispatch(authSuccess({ isLoading: false, error: null }));
            }
        } catch (error) {
            let errorMessage = null;
            if (error.response.status === 400) {
                if (hasErrorMessage(error)) {
                    errorMessage = error.response.data.message;
                    dispatch(authFail({ errorType: 'OTHER', error: errorMessage }));
                } else {
                    errorMessage = "An unexpected error has occurred.";
                    dispatch(authFail({ errorType: 'OTHER', error: errorMessage }));
                }
            } else {
                errorMessage = "An unexpected error has occurred.";
                dispatch(authFail({ errorType: 'OTHER', error: errorMessage }));
            }
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${errorMessage}`, true);
        }
    }
};

export const passwordlessLoginValidation = (phoneNumber, code) => {
    return async (dispatch, getState) => {
        try {
            // Start Login In
            dispatch(authStart());

            // Log In
            const resp = await Data.validatePasswordlessLogin(phoneNumber, code);
            if (isObjectNotEmpty(resp)) {
                // need to set here so axiosAuthenticated works for getting documents
                // Store Token to Local Storage
                await LocalStorage.setItem('token', resp.token);
                await LocalStorage.setItem('refreshToken', resp.refreshToken);
                await LocalStorage.setItem('userId', resp.userId.toString());
                await LocalStorage.setItem('userProfileId', resp.userProfileId.toString());
                await LocalStorage.setItem('expirationDate', resp.endDate.toDate().toString());
                await LocalStorage.setItem('firstName', resp.firstName.toString());
                await LocalStorage.setItem('lastName', resp.lastName.toString());

                if (isStringNotEmpty(resp.profileImageId)) {
                    await LocalStorage.setItem('profileImageId', resp.profileImageId);
                }

                // check to see if the user isActive and if they aren't, don't let them login
                if (resp.isUserActive === false) {
                    logger.logDebugEvent('passwordlesslogin_fail', 'Your User Account is not activated.', true);
                    dispatch(authFail({ errorType: 'NOT_ACTIVATED', error: "Your User Account is not activated." }));
                } else {
                    // console.log('start getting orchestrator');
                    // await dispatch(actionCreators.getOrchestrator(true));
                    // console.log('stop getting orchestrator');
                    await dispatch(actionCreators.fetchKeys());

                    // if the user is staff then no need to get account users and accounts, just authenticate them
                    if (resp.isStaff === true) {
                        // Get Account User Data
                        let accountUsers = [];
                        let accounts = [];
                        const yourAccountUsers = await LocalStorage.getItem('yourAccountUsers');
                        const yourAccounts = await LocalStorage.getItem('yourAccounts');
                        // console.log(yourAccountUsers);
                        // console.log(yourAccounts);
                        if (isStringNotEmpty(yourAccountUsers) && isStringNotEmpty(yourAccounts)) {
                            accountUsers = JSON.parse(yourAccountUsers);
                            accounts = JSON.parse(yourAccounts);
                        } else {
                            const accountsAndAccountUsersResp = await Data.getAccountUsersAndAccountsByUserId(resp.userId);
                            accountUsers = accountsAndAccountUsersResp.accountUsers;
                            accounts = accountsAndAccountUsersResp.accounts;

                            // Dispatch Account Users Data to Your Accounts Users Store
                            if (isListNotEmpty(accountUsers)) {
                                await LocalStorage.setItem('yourAccountUsers', JSON.stringify(accountUsers));
                            }

                            // Dispatch Accounts Data to Your Accounts Store
                            if (isListNotEmpty(accounts)) {
                                await LocalStorage.setItem('yourAccounts', JSON.stringify(accounts));
                            }
                        }

                        // Dispatch Account Users Data to Your Accounts Users Store
                        dispatch(actionCreators.fetchYourAccountUsersSuccess({ records: accountUsers }));

                        // Dispatch Accounts Data to Your Accounts Store
                        dispatch(actionCreators.fetchYourAccountsSuccess({ records: accounts }));

                        // Complete Login
                        return dispatch(authSuccess({
                            token: resp.token,
                            email: resp.email,
                            userName: resp.userName,
                            userId: resp.userId,
                            userProfileId: resp.userProfileId,
                            firstName: resp.firstName,
                            lastName: resp.lastName,
                            profileImageId: resp.profileImageId,
                            isLoading: false,
                            isAuthComplete: true,
                            isAuthenticated: true,
                            entityId: resp.userId,
                            entityIrisId: 'IRIS-STAFF',
                            entityType: 'STAFF',
                            error: null,
                            isAdmin: resp.isAdmin,
                            isStaff: resp.isStaff,
                            isVP: resp.isVP,
                            isDirector: resp.isDirector,
                            isManager: resp.isManager
                        }));
                    } else {
                        // Get Account Users Data
                        const accountsAndAccountUsersResp = await Data.getAccountUsersAndAccountsByUserId(resp.userId);
                        const accountUsers = accountsAndAccountUsersResp.accountUsers;
                        const accounts = accountsAndAccountUsersResp.accounts;
                        let selectedAccountUser = null;
                        let selectedAccount = null;

                        // Dispatch Account Users Data to Your Accounts Users Store
                        if (isListNotEmpty(accountUsers)) {
                            await LocalStorage.setItem('yourAccountUsers', JSON.stringify(accountUsers));

                            // TODO: for mvp we are assuming drivers belong to just one account but in the future we need to add an account switcher in case a driver or user belongs to more than one account
                            selectedAccountUser = accountUsers[0];
                            await LocalStorage.setItem('yourAccountUser', JSON.stringify(selectedAccountUser));

                            if (isObjectNotEmpty(selectedAccountUser) && isObjectNotEmpty(selectedAccountUser.account)) {
                                selectedAccount = selectedAccountUser.account;
                                await LocalStorage.setItem('yourAccount', JSON.stringify(selectedAccount));
                            }
                        }

                        // Dispatch Accounts Data to Your Accounts Store
                        if (isListNotEmpty(accounts)) {
                            await LocalStorage.setItem('yourAccounts', JSON.stringify(accounts));
                        }

                        // Dispatch Account Users Data to Your Accounts Users Store
                        dispatch(actionCreators.fetchYourAccountUsersSuccess({ records: accountUsers, record: selectedAccountUser }));

                        // Dispatch Accounts Data to Your Accounts Store
                        dispatch(actionCreators.fetchYourAccountsSuccess({ records: accounts, record: selectedAccount }));

                        if (isObjectNotEmpty(selectedAccountUser) && isObjectNotEmpty(selectedAccount)) {
                            // check that account is active
                            // check that account user is verified
                            if (selectedAccountUser.isVerified === true) {
                                if (selectedAccount.isActive === true) {
                                    let entityType = null;
                                    let entityId = null;
                                    let entityIrisId = null;

                                    if ((isListNotEmpty(selectedAccountUser.accountRoles) || isBooleanFalse(selectedAccountUser.isDriver)) && isBooleanTrue(selectedAccount.entityTypes.includes("CARRIER"))) {
                                        entityType = "CARRIER";
                                        entityIrisId = selectedAccount.irisId;
                                        entityId = selectedAccount.id;
                                    } else if (isBooleanTrue(selectedAccount.entityTypes.includes("CARRIER")) && isBooleanTrue(selectedAccountUser.isDriver)) {
                                        entityType = "DRIVER";
                                        if (isObjectNotEmpty(selectedAccountUser.driver)) {
                                            entityIrisId = selectedAccountUser.driver.irisId;
                                        }
                                        entityId = selectedAccountUser.id;
                                    } else if (isBooleanTrue(selectedAccount.entityTypes.includes("SHIPPER"))) {
                                        entityType = "SHIPPER";
                                        entityIrisId = selectedAccount.irisId;
                                        entityId = selectedAccount.id;
                                    } else if (isBooleanTrue(selectedAccount.entityTypes.includes("RECEIVER"))) {
                                        entityType = "RECEIVER";
                                        entityIrisId = selectedAccount.irisId;
                                        entityId = selectedAccount.id;
                                    } else if (isBooleanTrue(selectedAccount.entityTypes.includes("PRODUCER"))) {
                                        entityType = "PRODUCER";
                                        entityIrisId = selectedAccount.irisId;
                                        entityId = selectedAccount.id;
                                    } else if (isBooleanTrue(selectedAccount.entityTypes.includes("BROKER"))) {
                                        entityType = "BROKER";
                                        entityIrisId = selectedAccount.irisId;
                                        entityId = selectedAccount.id;
                                    } else if (isBooleanTrue(selectedAccount.entityTypes.includes("FACTORING"))) {
                                        entityType = "FACTORING";
                                        entityIrisId = selectedAccount.irisId;
                                        entityId = selectedAccount.id;
                                    }

                                    if (isNotNullOrUndefined(entityType) && isNotNullOrUndefined(entityId)) {
                                        await dispatch(actionCreators.getServices());
                                        const state = getState();
                                        const orchestratorState = { ...state.orchestrator };
                                        let services = [...orchestratorState.services];

                                        let featuresList = [];
                                        if (isListNotEmpty(selectedAccount.accountServices) && isListNotEmpty(services)) {
                                            selectedAccount.accountServices.forEach((accountService) => {
                                                let service = services.find(item => item.name === accountService.serviceName);
                                                if (isObjectNotEmpty(service) && isListNotEmpty(service.features)) {
                                                    service.features.forEach((feature) => {
                                                        if (feature.isAvailable === true && isListNotEmpty(feature.entityTypes) && feature.entityTypes.find(i => i === entityType) !== undefined && (feature.isBeta === false || (feature.isBeta === true && selectedAccount.isBeta === true))) {
                                                            featuresList.push(feature.name);
                                                        }
                                                    });
                                                }
                                            });
                                        }

                                        // console.log('start getting account orchestrator');
                                        // await dispatch(actionCreators.getAccountOrchestrator(selectedAccount.id, entityType, false));

                                        // Complete Login
                                        return dispatch(authSuccess({
                                            token: resp.token,
                                            email: resp.email,
                                            userName: resp.userName,
                                            userId: resp.userId,
                                            userProfileId: resp.userProfileId,
                                            firstName: resp.firstName,
                                            lastName: resp.lastName,
                                            profileImageId: resp.profileImageId,
                                            isLoading: false,
                                            isAuthComplete: true,
                                            isAuthenticated: true,
                                            entityId: entityId,
                                            entityIrisId: entityIrisId,
                                            entityType: entityType,
                                            error: null,
                                            isAdmin: resp.isAdmin,
                                            isStaff: resp.isStaff,
                                            isVP: resp.isVP,
                                            isDirector: resp.isDirector,
                                            isManager: resp.isManager,
                                            featuresList: featuresList
                                        }));
                                    } else {
                                        logger.logDebugEvent('passwordlesslogin_fail', 'Your User Account does not have access to its associated Account.', true);
                                        dispatch(authFail({ errorType: 'NOT_ACTIVATED', error: "Your User Account does not have access to its associated Account." }));
                                    }
                                } else {
                                    logger.logDebugEvent('passwordlesslogin_fail', 'The Account your User Account is associated with is not activated.', true);
                                    dispatch(authFail({ errorType: 'NOT_ACTIVATED', error: "The Account your User Account is associated with is not activated." }));
                                }
                            } else {
                                logger.logDebugEvent('passwordlesslogin_fail', 'Your User Account is not verified.', true);
                                dispatch(authFail({ errorType: 'NOT_ACTIVATED', error: "Your User Account is not verified." }));
                            }
                        } else {
                            return dispatch(authSuccess({
                                token: resp.newToken,
                                email: resp.email,
                                userName: resp.userName,
                                userId: resp.userId,
                                userProfileId: resp.userProfileId,
                                firstName: resp.firstName,
                                lastName: resp.lastName,
                                profileImageId: resp.profileImageId,
                                isLoading: false,
                                isAuthComplete: true,
                                isAuthenticated: true,
                                entityId: resp.userId,
                                entityIrisId: 'NONE',
                                entityType: 'NONE',
                                error: null,
                                isAdmin: resp.isAdmin,
                                isStaff: resp.isStaff,
                                isVP: resp.isVP,
                                isDirector: resp.isDirector,
                                isManager: resp.isManager,
                                featuresList: []
                            }));
                        }
                    }
                }
            } else {
                logger.logDebugEvent('passwordlesslogin_fail', 'Cannot get token', true);
                dispatch(authFail({ errorType: 'OTHER', error: 'Cannot get token' }));
            }
        } catch (error) {
            // console.log(typeof (error.message));
            // console.log(typeof (error));
            let errorMessage = '';
            if (typeof error === 'string') {
                errorMessage = error;
                dispatch(authFail({ errorType: 'OTHER', error: errorMessage }));
            } else if (error.response !== undefined && error.response.status === 400) {
                errorMessage = "No user account could be found with the credentials you provided. Please try again. If you need assistance signing in, please give us a call and we will gladly assist you.";
                dispatch(authFail({ errorType: 'NO_ACCOUNT', error: errorMessage }));
            } else if (error.response !== undefined && error.response.status === 403) {
                errorMessage = "Your User Account is not activated.";
                dispatch(authFail({ errorType: 'NOT_ACTIVATED', error: errorMessage }));
            } else if (error.response !== undefined && error.response.status >= 400 && error.response.status <= 500) {
                errorMessage = "Credentials Not Valid.";
                dispatch(authFail({ errorType: 'INVALID_CREDENTIALS', error: errorMessage }));
            } else if (error.response !== undefined && error.response.data !== undefined && error.response.data.message !== undefined && typeof (error.response.data.message) === 'string') {
                errorMessage = error.response.data.message;
                dispatch(authFail({ errorType: 'OTHER', error: errorMessage }));
            } else if (error.message !== undefined && typeof (error.message) === 'string') {
                errorMessage = error.message;
                dispatch(authFail({ errorType: 'OTHER', error: errorMessage }));
            } else {
                errorMessage = "An unexpected error has occurred.";
                dispatch(authFail({ errorType: 'OTHER', error: errorMessage }));
            }

            logger.logReduxErrorEvent(error, `Error fetching Requests: ${errorMessage}`, true);
        }
    }
};

export const forgotPassword = (emailAddress) => {
    return async (dispatch) => {
        try {
            // Start Forgot Password
            dispatch(authStart());

            // Forgot Password
            const resp = await Data.forgotPassword(emailAddress);
            if (resp === true) {
                dispatch(authSuccess({ isLoading: false, error: null }));
            }
        } catch (error) {
            let errorMessage = null;
            if (error.response.status === 400) {
                if (hasErrorMessage(error)) {
                    errorMessage = error.response.data.message;
                    dispatch(authFail({ errorType: 'OTHER', error: errorMessage }));
                } else {
                    errorMessage = "An unexpected error has occurred.";
                    dispatch(authFail({ errorType: 'OTHER', error: errorMessage }));
                }
            } else {
                errorMessage = "An unexpected error has occurred.";
                dispatch(authFail({ errorType: 'OTHER', error: errorMessage }));
            }

            logger.logReduxErrorEvent(error, `Error fetching Requests: ${errorMessage}`, true);
        }
    }
};

export const changePassword = (password, confirmPassword, oldPassword) => {
    return async (dispatch) => {
        try {
            // Start Change Password
            dispatch(authStart());

            // Change Password
            const resp = await Data.changePassword(password, confirmPassword, oldPassword);
            if (resp === true) {
                dispatch(authSuccess({ isLoading: false, error: null }));
            }
        } catch (error) {
            let errorMessage = null;
            if (error.response.status === 400) {
                if (hasErrorMessage(error)) {
                    errorMessage = error.response.data.message;
                    dispatch(authFail({ errorType: 'OTHER', error: errorMessage }));
                } else {
                    errorMessage = "An unexpected error has occurred.";
                    dispatch(authFail({ errorType: 'OTHER', error: errorMessage }));
                }
            } else {
                errorMessage = "An unexpected error has occurred.";
                dispatch(authFail({ errorType: 'OTHER', error: errorMessage }));
            }

            logger.logReduxErrorEvent(error, `Error fetching Requests: ${errorMessage}`, true);
        }
    }
};

export const resetPassword = (uuid, token, password, confirmPassword) => {
    return async (dispatch) => {
        try {
            // Start Reset Password
            dispatch(authStart());

            // Reset Password
            const resp = await Data.resetPassword(uuid, token, password, confirmPassword);
            if (resp === true) {
                dispatch(authSuccess({ isLoading: false, error: null }));
            }
        } catch (error) {
            let errorMessage = null;
            if (error.response.status === 400) {
                if (hasErrorMessage(error)) {
                    errorMessage = error.response.data.message;
                    dispatch(authFail({ errorType: 'OTHER', error: errorMessage }));
                } else {
                    errorMessage = "An unexpected error has occurred.";
                    dispatch(authFail({ errorType: 'OTHER', error: errorMessage }));
                }
            } else {
                errorMessage = "An unexpected error has occurred.";
                dispatch(authFail({ errorType: 'OTHER', error: errorMessage }));
            }

            logger.logReduxErrorEvent(error, `Error fetching Requests: ${errorMessage}`, true);
        }
    }
};

//#endregion
//#region Helper Methods

const hasErrorMessage = (error) => {
    return error.response.data !== undefined && error.response.data !== null && error.response.data.message !== undefined && error.response.data.message !== null;
};

//#endregion