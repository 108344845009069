import React, { useState, useMemo, useReducer, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { CheckOutlined, LoadingOutlined, LockOutlined, MailOutlined, PhoneOutlined, SearchOutlined, WarningOutlined } from '@ant-design/icons';
import { Layout, Button, Card, Col, Input, Row, Spin, Typography, Checkbox, Alert } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import * as actionCreators from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import classes from "./Register.module.scss";
import TermsOfUseModal from '../../components/TermsOfUseModal/TermsOfUseModal';
import IRISFloatingFooter from '../../hoc/IRISFloatingFooter/IRISFloatingFooter';
import Form from '../../components/Form/Form';
import FormItem from '../../components/FormItem/FormItem';
import FormItemDisplay from '../../components/FormItemDisplay/FormItemDisplay';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import Fieldset from '../../components/FormFieldset/FormFieldset';
import irisBackground from "../../assets/img/backgrounds/background-1.jpg";
import Logo from "../../components/Logo/Logo";
import { isBooleanTrue, isNotNullOrUndefined, isObjectEmpty, isObjectNotEmpty, isStringEmpty, isStringNotEmpty, removeEmpty } from '../../shared/objectUtils';
import { checkEmailAddressReducer, checkPhoneNumberReducer, checkEmailAddress, checkPhoneNumber, isValidEmailAddress } from '../../shared/formUtils';
import axios from 'axios';

const { Title } = Typography;

const Register = (props) => {
    //#region regex

    const uppercaseRegex = RegExp(/[A-Z]/);
    const lowercaseRegex = RegExp(/[a-z]/);
    const numericRegex = RegExp(/\d/);
    const specialCharacterRegex = RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/);

    //#endregion
    //#region useForms

    const methods = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        criteriaMode: 'all',
        shouldFocusError: true,
        shouldUnregister: true,
        defaultValues: {
            email: '',
            password: '',
            cpassword: '',
            firstName: '',
            lastName: '',
            phoneNumber: ''
        }
    });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.auth.isLoading);
    const isAuthComplete = useSelector(state => state.auth.isAuthComplete);
    const error = useSelector(state => state.auth.error);

    //#endregion
    //#region useReducers

    const [{ emailAddressExists, checkEmailAddressHasError, isCheckEmailAddressLoading, checkEmailAddressError }, checkEmailAddressDispatch] = useReducer(checkEmailAddressReducer, {
        emailAddressExists: null,
        isCheckEmailAddressLoading: false,
        checkEmailAddressHasError: false,
        checkEmailAddressError: null,
    });

    const [{ phoneNumberExists, checkPhoneNumberHasError, isCheckPhoneNumberLoading, checkPhoneNumberError }, checkPhoneNumberDispatch] = useReducer(checkPhoneNumberReducer, {
        phoneNumberExists: null,
        isCheckPhoneNumberLoading: false,
        checkPhoneNumberHasError: false,
        checkPhoneNumberError: null,
    });

    //#endregion
    //#region useStates

    const [redirectToReferrer, setRedirectToReferrer] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [emailAddress, setEmailAddress] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [hasValidEmailAddress, setHasValidEmailAddres] = useState(false);
    const [hasValidPhoneNumber, setHasValidPhoneNumber] = useState(false);

    //#endregion
    //#region toggles

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    //#endregion
    //#region onChanges

    const onChangeEmailAddress = (value) => {
        if (isStringNotEmpty(value) && value.length >= 4) {
            if (isObjectNotEmpty(methods.errors.email)) {
                checkEmailAddressDispatch({ type: "FETCH_FAILURE", payload: { error: 'You must first enter a valid email address' } });
            }
            // console.log(value);
            setEmailAddress(value);
        } else {
            checkEmailAddressDispatch({ type: "FETCH_CLEAR" });
        }
    };

    const onChangePhoneNumber = (value) => {
        if (isStringNotEmpty(value) && value.length === 10) {
            if (isObjectNotEmpty(methods.errors.phone)) {
                checkPhoneNumberDispatch({ type: "FETCH_FAILURE", payload: { error: 'You must first enter a valid phone number' } });
            }
            // console.log(value);
            setPhoneNumber(value);
        } else {
            checkPhoneNumberDispatch({ type: "FETCH_CLEAR" });
        }
    };

    //#endregion
    //#region onSubmit

    const onSubmit = (data) => {
        // TODO: Make Sure Email is not taken asyc validation

        if (isStringNotEmpty(data.email) && isStringNotEmpty(data.phoneNumber) && isBooleanTrue(hasValidEmailAddress) && isBooleanTrue(hasValidPhoneNumber)) {
            dispatch(actionCreators.register(data.password, data.cpassword, data.email, data.firstName, data.lastName, data.phoneNumber));

            methods.reset();
        }
    };

    //#endregion
    //#region useMemos and useEffects

    useMemo(() => {
        if (isAuthComplete === true) {
            setRedirectToReferrer(true);
        }
    }, [isAuthComplete]);

    useEffect(() => {
        // console.log(`emailAddress: ${emailAddress}`);
        const { cancel, token } = axios.CancelToken.source();
        const timeOutId = setTimeout(() => {
            if (isStringNotEmpty(emailAddress) && emailAddress.length >= 4) {
                if (isValidEmailAddress(emailAddress)) {
                    checkEmailAddress(emailAddress, null, checkEmailAddressDispatch, token);
                }
            }
        }, 500);
        return () => cancel("Searching...") || clearTimeout(timeOutId);
    }, [emailAddress]);

    useEffect(() => {
        // console.log(`phoneNumber: ${phoneNumber}`);
        const { cancel, token } = axios.CancelToken.source();
        const timeOutId = setTimeout(() => {
            if (isStringNotEmpty(phoneNumber) && phoneNumber.length === 10) {
                checkPhoneNumber(phoneNumber, null, checkPhoneNumberDispatch, token);
            }
        }, 500);
        return () => cancel("Searching...") || clearTimeout(timeOutId);
    }, [phoneNumber]);

    useEffect(() => {
        // console.log(emailAddress);
        // console.log(methods.errors.email);
        // console.log(emailAddressExists);
        if (isStringNotEmpty(emailAddress)) {
            if (isObjectEmpty(methods.errors.email)) {
                if (emailAddressExists !== null && emailAddressExists === false) {
                    setHasValidEmailAddres(true);
                    // console.log('Has a valid email address');
                } else {
                    setHasValidEmailAddres(false);
                }
            } else {
                setHasValidEmailAddres(false);
                checkEmailAddressDispatch({ type: "FETCH_CLEAR" });
            }
        } else {
            setHasValidEmailAddres(false);
            checkEmailAddressDispatch({ type: "FETCH_CLEAR" });
        }
    }, [methods.errors, emailAddress, emailAddressExists]);

    useEffect(() => {
        // console.log(phoneNumber);
        // console.log(methods.errors.phone);
        // console.log(phoneNumberExists);
        if (isStringNotEmpty(phoneNumber)) {
            if (isObjectEmpty(methods.errors.phone)) {
                if (phoneNumberExists !== null && phoneNumberExists === false) {
                    setHasValidPhoneNumber(true);
                    // console.log('Has a valid phone number');
                } else {
                    setHasValidPhoneNumber(false);
                }
            } else {
                setHasValidPhoneNumber(false);
                checkPhoneNumberDispatch({ type: "FETCH_CLEAR" });
            }
        } else {
            setHasValidPhoneNumber(false);
            checkPhoneNumberDispatch({ type: "FETCH_CLEAR" });
        }
    }, [methods.errors, phoneNumber, phoneNumberExists]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            md: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            md: { span: 16 },
        },
    };

    //#endregion

    return (
        <Layout className={classes.registerBackground} style={{ minHeight: '100vh', backgroundImage: `url(${irisBackground})` }}>
            <Logo size={"custom"} style={{ margin: '20px auto', height: '100px', padding: 10, }} height={100} />
            {redirectToReferrer === true && <Redirect to='/login' />}
            {(isLoading === true && error === null) ? (
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true}></Spin>
            ) : (
                <Card bordered={true} className={classes.card} size="small">
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                        <Row gutter={[4, 4]}>
                            <Col span={24}>
                                <Title level={2} style={{ textAlign: 'center' }}>User Account Registration</Title>
                            </Col>
                        </Row>
                        <FormProvider {...methods}>
                            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                                <Row gutter={[4, 4]}>
                                    <Col sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Fieldset legend="User Credentials">
                                            <FormItem {...formItemLayout} label="Email Address" required
                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                    <Input
                                                        onBlur={onBlur}
                                                        addonAfter={isBooleanTrue(isCheckEmailAddressLoading) ? <LoadingOutlined /> : (isNotNullOrUndefined(checkEmailAddressError) ? <WarningOutlined style={{ color: "#ff0000" }} /> : (isStringEmpty(emailAddress) ? <SearchOutlined /> : (isStringNotEmpty(emailAddress) && isBooleanTrue(hasValidEmailAddress) ? <CheckOutlined twoToneColor="#52c41a" /> : (<WarningOutlined style={{ color: "#ff0000" }} />))))}
                                                        onChange={e => { onChangeEmailAddress(e.target.value); onChange(e.target.value); }}
                                                        value={value}
                                                        name={name}
                                                        prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                        placeholder="Email Address"
                                                        ref={ref} />
                                                )}
                                                rules={{
                                                    required: 'Required Field',
                                                    minLength: { value: 4, message: 'Email Address must be at least 4 characters long' },
                                                    pattern: {
                                                        value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                                        message: "Please enter a valid email address"
                                                    }
                                                }}
                                                name="email"
                                                defaultValue={''}
                                            />
                                            {checkEmailAddressHasError && <Alert message={checkEmailAddressError} type="error" />}
                                            <FormItem {...formItemLayout} label="Phone #" required
                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                    <Input
                                                        onBlur={onBlur}
                                                        addonAfter={isBooleanTrue(isCheckPhoneNumberLoading) ? <LoadingOutlined /> : (isNotNullOrUndefined(checkPhoneNumberError) ? <WarningOutlined style={{ color: "#ff0000" }} /> : (isStringEmpty(phoneNumber) ? <SearchOutlined /> : (isStringNotEmpty(phoneNumber) && isBooleanTrue(hasValidPhoneNumber) ? <CheckOutlined twoToneColor="#52c41a" /> : (<WarningOutlined style={{ color: "#ff0000" }} />))))}
                                                        onChange={e => { onChangePhoneNumber(e.target.value); onChange(e.target.value); }}
                                                        value={value}
                                                        name={name}
                                                        prefix={<PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                        placeholder="Phone #"
                                                        ref={ref}
                                                    />
                                                )}
                                                rules={{
                                                    required: 'Required Field',
                                                    pattern: {
                                                        value: /^\d{10}$/,
                                                        message: "Please enter a valid 10 digit phone number with no special characters"
                                                    }
                                                }}
                                                name="phoneNumber"
                                                defaultValue={''}
                                            />
                                            {checkPhoneNumberHasError && <Alert message={checkPhoneNumberError} type="error" />}
                                            <FormItem {...formItemLayout} label="Password" required
                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                    <Input.Password
                                                        onBlur={onBlur}
                                                        onChange={e => { onChange(e.target.value); }}
                                                        value={value}
                                                        name={name}
                                                        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                        placeholder="Password"
                                                        ref={ref}
                                                    />
                                                )}
                                                rules={{
                                                    required: 'Required Field',
                                                    minLength: { value: 8, message: 'Password must be at least 8 characters long.' },
                                                    validate: {
                                                        uppercase: value => uppercaseRegex.test(value) || 'Password must contain at least 1 uppercase letter.',
                                                        lowercase: value => lowercaseRegex.test(value) || 'Password must contain at least 1 lowercase letter.',
                                                        numeric: value => numericRegex.test(value) || 'Password must contain at least 1 number.',
                                                        special: value => specialCharacterRegex.test(value) || 'Password must contain at least 1 special character.'
                                                    }
                                                }}
                                                name="password"
                                                defaultValue={''}
                                                help={
                                                    <div>
                                                        <b>Password Requirements:</b>
                                                        <ul>
                                                            <li>Minimum of 8 characters</li>
                                                            <li>At least 1 uppercase letter (A-Z)</li>
                                                            <li>At least 1 lowercase letter (a-z)</li>
                                                            <li>At least 1 number (0-9)</li>
                                                            <li>At least 1 special character (~`!@#$%^&*()+=_-{ }[]\|:;”’?/&lt;>,.)</li>
                                                            <li>Cannot contain parts of your email address</li>
                                                        </ul>
                                                    </div>
                                                }
                                            />
                                            <FormItem {...formItemLayout} label="Confirm Password" required
                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                    <Input.Password
                                                        onBlur={onBlur}
                                                        onChange={e => { onChange(e.target.value); }}
                                                        value={value}
                                                        name={name}
                                                        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                        placeholder="Confirm Password"
                                                        ref={ref}
                                                    />
                                                )}
                                                rules={{
                                                    required: 'Required Field',
                                                    validate: {
                                                        comparePasswords: value => value === methods.watch('password') || 'Passwords must match' // value is from cpassword and watch will return value from password
                                                    }
                                                }}
                                                name="cpassword"
                                                defaultValue={''}
                                            />
                                        </Fieldset>
                                    </Col>
                                    <Col sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <Fieldset legend="User Info">
                                            <FormItem {...formItemLayout} label="First Name" required
                                                render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="First Name" ref={ref} />}
                                                rules={{
                                                    required: 'Required Field',
                                                    minLength: { value: 2, message: 'First Name must be at least 2 characters long' },
                                                }}
                                                name="firstName"
                                                defaultValue={''}
                                            />
                                            <FormItem {...formItemLayout} label="Last Name" required
                                                render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Last Name" ref={ref} />}
                                                rules={{
                                                    required: 'Required Field',
                                                    minLength: { value: 2, message: 'Last Name must be at least 2 characters long' },
                                                }}
                                                name="lastName"
                                                defaultValue={''}
                                            />
                                        </Fieldset>
                                    </Col>
                                    <Col sm={{ span: 12, offset: 12 }} xs={{ span: 24 }}>
                                        <FormItem style={{ textAlign: 'right' }}
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                    ref={ref}
                                                >I have read the <a href="#" onClick={toggleModal}>agreement</a></Checkbox>
                                            )}
                                            rules={{
                                                required: 'Required Field',
                                                validate: {
                                                    mustBeTrue: value => value === true || 'Agreement must be accepted' // value must be true
                                                }
                                            }}
                                            name="acceptAgreement"
                                            defaultValue={false}
                                        />
                                        <FormItemDisplay style={{ textAlign: 'right' }}>
                                            <Button type="primary" htmlType="submit" className="login-form-button" block>Register</Button>
                                        </FormItemDisplay>
                                    </Col>
                                    <Col xs={{ span: 24 }}>
                                        <FormItemDisplay style={{ textAlign: 'right' }}>
                                            Already have a User Account? <Link to="/login"><Button type="link">Back to Login</Button></Link>
                                        </FormItemDisplay>
                                    </Col>
                                </Row>
                            </Form>
                        </FormProvider>
                        {error && <ErrorMessage error={error} />}
                    </Spin>
                    <IRISFloatingFooter />
                </Card>
            )
            }
            <TermsOfUseModal showModal={showModal} toggleModal={toggleModal} />
        </Layout>
    );
};

export default withRouter(Register);