import axiosAuthenticated from "../../api/axiosAuthenticated";
import { isNotNullOrUndefined } from "../../shared/objectUtils";

const commoditiesPath = '/commodities';

//#region Commodities Methods

export const getAllCommodities = async () => {
    const commoditiesRes = await axiosAuthenticated.get(commoditiesPath, { params: { page: 1, size: 1000000, sort: 'name', order: 'asc', isDeleted: false } });
    if (commoditiesRes && commoditiesRes.status === 200) {
        return commoditiesRes.data.data;
    }

    return [];
};

export const getCommodities = async (searchParams = {}, pagination = {}) => {
    const commoditiesRes = await axiosAuthenticated.get(commoditiesPath, { params: { ...searchParams } });
    if (commoditiesRes && commoditiesRes.status === 200) {
        const commodities = commoditiesRes.data.data;
        const otherData = commoditiesRes.data;

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;

        return { data: commodities, searchParams: searchParams, pagination: pagination };
    }

    return { data: [], searchParams: searchParams, pagination: pagination };
};

export const getCommodity = async (id) => {
    const commoditiesRes = await axiosAuthenticated.get(commoditiesPath + `/${id}`);
    if (commoditiesRes && commoditiesRes.status === 200) {
        return commoditiesRes.data;
    }

    return null;
};

export const addCommodity = async (payload) => {
    const commoditiesRes = await axiosAuthenticated.post(commoditiesPath, { ...payload });
    if (commoditiesRes && commoditiesRes.status === 201) {
        return commoditiesRes.data;
    }

    return null;
};

export const updateCommodity = async (id, payload) => {
    const commoditiesRes = await axiosAuthenticated.put(commoditiesPath + `/${id}`, { ...payload });
    if (commoditiesRes && commoditiesRes.status === 200) {
        return commoditiesRes.data;
    }

    return null;
};

export const removeCommodity = async (id) => {
    const commoditiesRes = await axiosAuthenticated.put(commoditiesPath + `/${id}`, { isDeleted: true });
    if (commoditiesRes && commoditiesRes.status === 200) {
        return commoditiesRes.data;
    }

    return null;
};

//#endregion