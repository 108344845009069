import axiosAuthenticated from "../../api/axiosAuthenticated";
import { isNotNullOrUndefined } from "../../shared/objectUtils";
import * as DTO from "../dtos/index";

const servicesPath = '/services';

//#region Services Methods

export const getAllServices = async (features = []) => {
    const servicesRes = await axiosAuthenticated.get(servicesPath, { params: { page: 1, size: 1000000, isDeleted: false } });
    if (servicesRes && servicesRes.status === 200) {
        const services = servicesRes.data.data;

        const transformedServices = services.map((service) => { return DTO.getServiceDTO(service, features); });

        return transformedServices;
    }

    return [];
};

export const getServices = async (searchParams = {}, pagination = {}, features = []) => {
    const servicesRes = await axiosAuthenticated.get(servicesPath, { params: { ...searchParams } });
    if (servicesRes && servicesRes.status === 200) {
        const services = servicesRes.data.data;
        const otherData = servicesRes.data;

        const transformedServices = services.map((service) => { return DTO.getServiceDTO(service, features); });

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;

        return { data: transformedServices, searchParams: searchParams, pagination: pagination };
    }

    return { data: [], searchParams: searchParams, pagination: pagination };
};

export const addService = async (payload, features = []) => {
    payload = DTO.getAddServiceRequestDTO(payload);

    const serviceRes = await axiosAuthenticated.post(servicesPath, { ...payload });
    if (serviceRes && serviceRes.status === 201) {
        let newService = serviceRes.data;

        return DTO.getServiceDTO(newService, features);
    }

    return null;
};

export const updateService = async (id, payload, features = []) => {
    payload = DTO.getUpdateServiceRequestDTO(payload);

    const serviceRes = await axiosAuthenticated.put(servicesPath + `/${id}`, { ...payload });
    if (serviceRes && serviceRes.status === 200) {
        let updatedService = serviceRes.data;

        return DTO.getServiceDTO(updatedService, features);
    }

    return null;
};

export const removeService = async (id) => {
    const serviceRes = await axiosAuthenticated.put(servicesPath + `/${id}`, { isDeleted: true });
    if (serviceRes && serviceRes.status === 200) {
        return serviceRes.data;
    }

    return null;
};

//#endregion