import React from 'react';
import StringFormatter from '../../shared/stringFormatter';
import DataRow from '../DataRow/DataRow';
import DataPhoneNumberRow from '../DataPhoneNumberRow/DataPhoneNumberRow';
import { isBooleanFalse, isBooleanTrue, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import DataNameRow from '../DataNameRow/DataNameRow';

const stringFormatter = new StringFormatter();

const DataContactInfoRow = ({ className = null, valueClassName = null, titleClassName = null, style = null, title = null, titleStyle = { fontWeight: 'bold' }, valueStyle = null, firstName = null, lastName = null, email = null, phone = null, phoneExt = null, contact = null, separateLines = false, noColon = false }) => {
    if (isObjectNotEmpty(contact)) {
        firstName = contact.firstName;
        lastName = contact.lastName;
        email = contact.email;
        phone = contact.phone;
        phoneExt = contact.phoneExt;
    }

    if (isBooleanTrue(separateLines)) {
        return (
            <>
                {isStringNotEmpty(firstName) || isStringNotEmpty(lastName) ? <DataNameRow className={className} valueClassName={valueClassName} titleClassName={titleClassName} style={style} valueStyle={valueStyle} titleStyle={titleStyle} noColon={noColon} title={`${title ? title + ' ' : ''}Name`} firstName={firstName} lastName={lastName} /> : null}
                {isStringNotEmpty(phone) ? <DataPhoneNumberRow className={className} valueClassName={valueClassName} titleClassName={titleClassName} style={style} valueStyle={valueStyle} titleStyle={titleStyle} noColon={noColon} title={`${title ? title + ' ' : ''}Phone`} phone={phone} phoneExt={phoneExt} /> : null}
                {isStringNotEmpty(email) ? <DataRow className={className} valueClassName={valueClassName} titleClassName={titleClassName} style={style} valueStyle={valueStyle} titleStyle={titleStyle} noColon={noColon} title={`${title ? title + ' ' : ''}Email`} value={email} dataType="String" /> : null}
            </>
        );
    } else {
        return (
            <div className={className} style={style}>
                {isStringNotEmpty(title) ? (<span className={titleClassName} style={titleStyle}>{title}{isBooleanFalse(noColon) ? ':' : ''} </span>) : null}
                {isStringNotEmpty(firstName) || isStringNotEmpty(lastName) ? <span className={valueClassName} style={valueStyle}>{firstName} {lastName}</span> : null}
                {isStringNotEmpty(email) ? <span className={valueClassName} style={valueStyle}>{email}</span> : null}
                {isStringNotEmpty(phone) ? <span className={valueClassName} style={valueStyle}>{stringFormatter.toFormattedPhoneNumber(phone, phoneExt)}</span> : null}
            </div>
        );
    }
};

export default DataContactInfoRow;