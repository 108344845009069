import Checks from '../entitlementUtils';

export const canCreateLoadCancellation = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadCarrier(load, auth) || Checks.isLoadShipper(load, auth);
};

export const canReadLoadCancellation = (auth, load) => {
    return Checks.allowStaff(auth);
};

export const canReadLoadCancellationList = (auth, load) => {
    return Checks.allowStaff(auth);
};

export const canUpdateLoadCancellation = (auth, load) => {
    return Checks.allowStaff(auth);
};

export const canDeleteLoadCancellation = (auth, load) => {
    return Checks.allowStaff(auth);
};