import { isListNotEmpty, isNumberNotEmpty } from '../../shared/objectUtils';
import EntityUtils from '../entityUtils';

export const getClaimDTO = (claim, accounts = []) => {
    // Get Account Info
    if (isListNotEmpty(accounts)) {
        claim.account = EntityUtils.getAccountInfo(claim.accountId, accounts);
    }

    return claim;
};

export const getAddClaimRequestDTO = (payload) => {
    if (isNumberNotEmpty(payload.count)) {
        payload.count = Number(payload.count);
    }

    if (isNumberNotEmpty(payload.adjustment)) {
        payload.adjustment = Number(payload.adjustment);
    }

    return payload;
};

export const getUpdateClaimRequestDTO = (payload) => {
    if (isNumberNotEmpty(payload.count)) {
        payload.count = Number(payload.count);
    }

    if (isNumberNotEmpty(payload.adjustment)) {
        payload.adjustment = Number(payload.adjustment);
    }

    return payload;
};