import * as actionTypes from "../actions/actionTypes";
import { fetchRecordsSuccess } from "../utility";

const initialState = {
    accounts: [],
    accountsLastUpdatedAt: null,
    lineItemTypes: [],
    lineItemTypesLastUpdatedAt: null,
    trailerTypes: [],
    trailerTypesLastUpdatedAt: null,
    staff: [],
    staffLastUpdatedAt: null,
    states: [],
    statesLastUpdatedAt: null,
    commodities: [],
    commoditiesLastUpdatedAt: null,
    features: [],
    featuresLastUpdatedAt: null,
    services: [],
    servicesLastUpdatedAt: null,
    employees: {},
    drivers: {},
    assets: {},
    linkedAccounts: {},
    locations: {}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_ORCHESTRATOR_SUCCESS: return fetchRecordsSuccess(state, action.payload);
        default:
            return state;
    }
};

export default reducer;