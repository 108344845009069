import React from 'react';
import { Card, Typography } from "antd";
import classes from './SimpleDataCard.module.scss';

const { Title } = Typography;

const SimpleDataCard = ({ title, style = null, headStyle = null, dataSize = 3, data, extra }) => {

    return (
        <Card bordered={true} title={title} className={classes.card} style={style} headStyle={headStyle} extra={extra}>
            <div><Title level={dataSize}>{data}</Title></div>
        </Card>
    );
};

export default SimpleDataCard;