import React, { useMemo } from 'react';
import { Alert, Switch, Input, Select, Spin } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import FormButtons from '../FormButtons/FormButtons';
import Enums from '../../shared/enums';
import { isObjectNotEmpty } from '../../shared/objectUtils';

const EditFeature = ({ cancel, feature }) => {
    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.features.isRecordUpdateLoading);
    const error = useSelector(state => state.features.updateRecordError);

    const entityTypeOptions = Enums.EntityTypes.selectListOptions();

    const onSubmit = (data) => {
        let payload = {};
        let objectToUpdate = { ...feature };
        for (let [key, value] of Object.entries(data)) {
            let oldValue = objectToUpdate[key] !== undefined && objectToUpdate[key] !== null ? objectToUpdate[key] : '';
            if (value !== oldValue) {
                //console.log(`${key}: new value - ${value}, old value - ${oldValue}`);
                payload[key] = value;
            }
        }

        if (isObjectNotEmpty(payload)) {
            dispatch(actionCreators.updateFeature(feature.id, payload));
        } else {
            onCancel();
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.updateFeatureCancel());
        cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateFeatureErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <FormItem {...formItemLayout} label="Name (ENUM)" required format="horizontal"
                        render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Name" ref={ref} />}
                        rules={{ required: 'Required Field' }}
                        name="name"
                        defaultValue={feature.name}
                    />
                    <FormItem {...formItemLayout} label="Display Name" required format="horizontal"
                        render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Display Name" ref={ref} />}
                        rules={{ required: 'Required Field' }}
                        name="displayName"
                        defaultValue={feature.displayName}
                    />
                    <FormItem {...formItemLayout} label="Description" format="horizontal"
                        render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Description" ref={ref} />}
                        rules={{ required: false }}
                        name="description"
                        defaultValue={feature.description}
                    />
                    <FormItem {...formItemLayout} label="Entities" required format="horizontal"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Select
                                placeholder="Please select the Entities"
                                mode="multiple"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                                ref={ref}
                            >
                                {entityTypeOptions}
                            </Select>
                        )}
                        rules={{ required: "Required Field" }}
                        name="entityTypes"
                        defaultValue={feature.entityTypes}
                    />
                    <FormItem {...formItemLayout} label="Is Available" format="horizontal"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                onBlur={onBlur}
                                onChange={(checked, event) => { onChange(checked); }}
                                checked={value}
                                name={name}
                                ref={ref}
                            />
                        )}
                        name="isAvailable"
                        defaultValue={feature.isAvailable}
                    />
                    <FormItem {...formItemLayout} label="Is Beta" format="horizontal"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                onBlur={onBlur}
                                onChange={(checked, event) => { onChange(checked); }}
                                checked={value}
                                name={name}
                                ref={ref}
                            />
                        )}
                        name="isBeta"
                        defaultValue={feature.isBeta}
                    />
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Update Feature" />
            </Form>
        </FormProvider>
    );
};

export default EditFeature;