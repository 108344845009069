import React, { useMemo, useState } from 'react';
import { PaperClipOutlined } from '@ant-design/icons';
import { Row, Col, Button, Drawer, Alert, Input, Select, Radio, Spin, Modal } from 'antd';
import StringFormatter from '../../shared/stringFormatter';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from "../../store/actions/index";
import { isListNotEmpty, isNotNullOrUndefined, isObjectNotEmpty, isStringNotEmpty, isStringEmpty, isBooleanTrue, isObjectEmpty } from '../../shared/objectUtils';
import { CanSee } from '../../shared/entitlements/entitlements';
import InvoiceTable from '../InvoiceTable/InvoiceTable';
import DocumentUtils from '../../api/utils/documentUtils';
import EditMissingDocument from '../EditMissingDocument/EditMissingDocument';
import LoadStopUtils from '../../api/utils/loadStopUtils';
import FormItemDisplay from '../FormItemDisplay/FormItemDisplay';
import Fieldset from '../FormFieldset/FormFieldset';
import { FormProvider, useForm } from 'react-hook-form';
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import moment from 'moment';
import DataLinkRow from '../DataLinkRow/DataLinkRow';
import business from 'moment-business';
import { selectListIsLoading } from '../../store/utility';
import InvoiceUtils from '../../api/utils/invoiceUtils';
import LoadUtils from '../../api/utils/loadUtils';
import * as Data from '../../api/data';
import FormButtons from '../FormButtons/FormButtons';
import FormItemQuadruple from '../FormItemQuadruple/FormItemQuadruple';
import Invoice from '../Invoice/Invoice';
import Enums from '../../shared/enums';
import FormItemAddress from '../FormItemAddress/FormItemAddress';

const stringFormatter = new StringFormatter();

const LoadEditCarrierInvoice = ({ load, loadId, invoiceId, invoice, shipperId, carrierId, serviceType, stops, invoices, invoiceLineItems, transactions, claims, cancel, loading = false }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    //#endregion
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: false });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const documentLists = useSelector(state => state.documents.lists);
    const isLoadingDocuments = useSelector(state => selectListIsLoading(state.documents.lists, loadId));
    const isAdmin = useSelector(state => state.auth.isAdmin);
    const entityType = useSelector(state => state.auth.entityType);
    const entityId = useSelector(state => state.auth.entityId);
    const userId = useSelector(state => state.auth.userId);
    const lineItemTypes = useSelector(state => state.orchestrator.lineItemTypes);
    const accounts = useSelector(state => state.orchestrator.accounts);
    const isLoadingUpdateInvoice = useSelector(state => state.invoices.isRecordUpdateLoading);
    const errorUpdateInvoice = useSelector(state => state.invoices.updateRecordError);

    //#endregion
    //#region useStates

    const [showEditMissingDocument, setShowEditMissingDocument] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [remittanceMethod, setRemittanceMethod] = useState(null);
    const [newInvoiceLineItemList, setNewInvoiceLineItemList] = useState([]);
    const [carrierInvoiceDocument, setCarrierInvoiceDocument] = useState({});
    const [bankAccountDocument, setBankAccountDocument] = useState({});
    const [factoringNOADocument, setFactoringNOADocument] = useState({});
    const [rateConfirmationDocument, setRateConfirmationDocument] = useState({});
    const [rateConfirmation, setRateConfirmation] = useState({});
    const [shipperAccount, setShipperAccount] = useState(null);
    const [carrierAccount, setCarrierAccount] = useState(null);
    const [selectedInvoice, setSelectedInvoice] = useState({});
    const [showViewInvoiceModal, setShowViewInvoiceModal] = useState(false);

    //#endregion
    //#region invoiceLineItem methods

    const generateQuickPayInvoiceLineItems = () => {
        // create invoiceLineItems
        let newInvoiceLineItems = [];
        if (isListNotEmpty(lineItemTypes) && isStringNotEmpty(serviceType) && isListNotEmpty(invoiceLineItems)) {

            let lineItemType = lineItemTypes.find(j => j.name === 'QUICK_PAY');
            if (isObjectNotEmpty(lineItemType)) {
                const existingInvoiceLineItems = invoiceLineItems.filter(i => (i.fromEntityType === 'CARRIER' || i.toEntityType === 'CARRIER') && (i.invoiceId === null || i.invoiceId === undefined || i.invoiceId === invoiceId) && i.itemType !== 'QUICK_PAY' && i.status !== 'COMPLETED' && i.isDeleted === false);
                const { totalAmount, lineItems, invoiceLineItemList } = InvoiceUtils.getInvoiceLineItemsAndTotal('CARRIER_INVOICE', existingInvoiceLineItems, serviceType === "TMS" ? "SHIPPER" : "STAFF", "CARRIER");

                let lineItemBaseAmount = 0.05 * totalAmount;
                let quantity = 1;
                let lineItemTotalAmount = lineItemBaseAmount * quantity;
                let newInvoiceLineItem = {
                    description: lineItemType.description + ' (5% fee)',
                    loadId: load.id,
                    itemType: 'QUICK_PAY',
                    isIrisFee: false,
                    baseAmount: lineItemBaseAmount.toFixed(2),
                    baseAmountUnit: 'USD',
                    quantity: quantity,
                    quantityUnit: 'PER_LOAD',
                    totalAmount: lineItemTotalAmount.toFixed(2),
                    totalAmountUnit: 'USD',
                    status: 'PENDING',
                    approvalStatus: 'APPROVED',
                    isOpen: true,
                    isDeleted: false,
                    fromEntityType: 'CARRIER',
                    fromEntityId: carrierId,
                    toEntityType: 'STAFF'
                };

                newInvoiceLineItems.push(newInvoiceLineItem);
            }

            setNewInvoiceLineItemList([...newInvoiceLineItems]);
        }
    };

    //#endregion
    //#region table displays

    const rateConfirmationColumns = [
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            textAlign: 'left',
        },
        {
            title: 'Units',
            dataIndex: 'quantity',
            key: 'quantity',
            textAlign: 'center',
            dataType: 'Decimal',
        },
        {
            title: 'Per',
            dataIndex: 'baseAmount',
            key: 'baseAmount',
            textAlign: 'center',
            dataType: 'Money',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
        },
        {
            title: 'Amount',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            textAlign: 'right',
            dataType: 'Money',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
        },
    ];

    //#endregion
    //#region toggles

    const toggleEditMissingDocument = () => {
        setShowEditMissingDocument(!showEditMissingDocument);
    };

    const toggleViewInvoice = () => {
        setShowViewInvoiceModal(!showViewInvoiceModal);
    };

    //#endregion
    //#region onSubmit

    const onSubmit = async (data) => {
        // console.log(data);
        if (isObjectNotEmpty(invoice) && isObjectNotEmpty(load) && isObjectNotEmpty(data) && isObjectNotEmpty(carrierAccount) && isStringNotEmpty(serviceType) && isStringNotEmpty(paymentMethod) && isStringNotEmpty(invoiceId)) {
            if (invoice.status !== 'COMPLETED' && invoice.status !== 'CANCELLED') {
                let paymentTerms = 30;
                let paymentDueDate = moment().add(30, 'days');
                if (paymentMethod === 'QUICK_PAY') {
                    paymentTerms = 3;
                    paymentDueDate = business.addWeekDays(moment(), 3);
                }

                let invoiceLineItemIds = [];
                let allInvoiceLineItems = [];
                // all existing invoice line items except the quick pay line item if it exists that aren't completed yet
                invoiceLineItems.filter(i => (i.fromEntityType === 'CARRIER' || i.toEntityType === 'CARRIER') && (i.invoiceId === null || i.invoiceId === undefined || i.invoiceId === invoiceId) && i.itemType !== 'QUICK_PAY' && i.status !== 'COMPLETED' && i.isDeleted === false).forEach((invoiceLineItem) => {
                    invoiceLineItemIds.push(invoiceLineItem.id);
                    allInvoiceLineItems.push(invoiceLineItem);
                });

                // check to see if quick pay line item already exists
                let existingQuickPayInvoiceLineItem = invoiceLineItems.find(i => i.itemType === 'QUICK_PAY' && i.invoiceId === invoiceId && i.isDeleted === false);
                if (isObjectNotEmpty(existingQuickPayInvoiceLineItem) && paymentMethod !== 'QUICK_PAY') {
                    // remove the existing quick pay line item
                    await Data.removeInvoiceLineItem(existingQuickPayInvoiceLineItem.id, invoiceLineItems, transactions, claims, loadId, false);
                } else if (isObjectNotEmpty(existingQuickPayInvoiceLineItem) && paymentMethod === 'QUICK_PAY') {
                    // quick pay already exists so compare it to the new invoice line item
                    let newQuickPayInvoiceLineItem = null;
                    if (isListNotEmpty(newInvoiceLineItemList)) {
                        newQuickPayInvoiceLineItem = newInvoiceLineItemList.find(i => i.isDeleted === false && i.itemType === "QUICK_PAY");
                        if (isObjectNotEmpty(newQuickPayInvoiceLineItem)) {
                            if (existingQuickPayInvoiceLineItem.totalAmount !== newQuickPayInvoiceLineItem.totalAmount) {
                                // update existing line item
                                let updatedInvoiceLineItemPayload = {
                                    baseAmount: newQuickPayInvoiceLineItem.baseAmount,
                                    totalAmount: newQuickPayInvoiceLineItem.totalAmount,
                                    status: 'PENDING',
                                    approvalStatus: 'APPROVED'
                                };
                                let updatedInvoiceLineItem = await Data.updateInvoiceLineItem(existingQuickPayInvoiceLineItem.id, updatedInvoiceLineItemPayload, invoiceLineItems, transactions, claims, loadId, false);
                                if (isObjectNotEmpty(updatedInvoiceLineItem)) {
                                    invoiceLineItemIds.push(updatedInvoiceLineItem.id);
                                    allInvoiceLineItems.push(updatedInvoiceLineItem);
                                }
                            } else {
                                invoiceLineItemIds.push(existingQuickPayInvoiceLineItem.id);
                                allInvoiceLineItems.push(existingQuickPayInvoiceLineItem);
                            }
                        } else {
                            // this shouldn't be the case ever unless there is a bug and the quick pay line item wasn't generated
                            // just add the existing one (have to assume nothing has changed)
                            invoiceLineItemIds.push(existingQuickPayInvoiceLineItem.id);
                            allInvoiceLineItems.push(existingQuickPayInvoiceLineItem);
                        }
                    } else {
                        // this shouldn't be the case ever unless there is a bug and the quick pay line item wasn't generated
                        // just add the existing one (have to assume nothing has changed)
                        invoiceLineItemIds.push(existingQuickPayInvoiceLineItem.id);
                        allInvoiceLineItems.push(existingQuickPayInvoiceLineItem);
                    }
                } else if (isObjectEmpty(existingQuickPayInvoiceLineItem) && paymentMethod === 'QUICK_PAY') {
                    // quick pay line item doesn't exist yet so add it
                    // if quick pay is chosen, create an invoice line item for it and add it to the invoiceLineItemIds
                    if (isListNotEmpty(newInvoiceLineItemList)) {
                        let newInvoiceLineItems = await Data.addInvoiceLineItems(newInvoiceLineItemList, invoiceLineItems, transactions, claims, loadId, false);
                        if (isListNotEmpty(newInvoiceLineItems)) {
                            newInvoiceLineItems.forEach((invoiceLineItem) => {
                                invoiceLineItemIds.push(invoiceLineItem.id);
                                allInvoiceLineItems.push(invoiceLineItem);
                            });
                        }
                    }
                }

                const { totalAmount, lineItems, invoiceLineItemList } = InvoiceUtils.getInvoiceLineItemsAndTotal('CARRIER_INVOICE', allInvoiceLineItems, serviceType === "TMS" ? "SHIPPER" : "STAFF", "CARRIER");

                let updatedCarrierInvoice = {
                    invoiceNotes: data.invoiceNotes,
                    customerInvoiceNumber: data.customerInvoiceNumber,
                    remitName: data.remitName,
                    remitAddress: data.remitAddress,
                    remitPointOfContact: data.remitPointOfContact,
                    invoiceDate: moment(),
                    paymentMethod: paymentMethod,
                    paymentTerms: paymentTerms,
                    paymentDueDate: paymentDueDate,
                    paymentStatus: 'PENDING',
                    status: 'SENT',
                    amountDue: totalAmount,
                    amountDueUnit: 'USD',
                    balanceDue: totalAmount,
                    balanceDueUnit: 'USD',
                    invoiceLineItemIds: invoiceLineItemList.map(i => i.id),
                    sentAt: moment(),
                    sentBy: userId
                };

                dispatch(actionCreators.updateInvoice(invoice.id, updatedCarrierInvoice, invoice, true));
            }
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.updateInvoiceCancel());
        cancel();
    };

    //#endregion
    //#region useMemos and useEffects

    useMemo(() => {
        dispatch(actionCreators.updateInvoiceErrorClear());

        dispatch(actionCreators.getAccounts());
        dispatch(actionCreators.getLineItemTypes());
    }, []);

    useMemo(() => {
        if (isObjectNotEmpty(invoice)) {
            if (invoice.paymentMethod === 'FACTORING') {
                setRemittanceMethod('FACTORING');
                methods.setValue('remittanceMethod', 'FACTORING');
            } else {
                setRemittanceMethod('DIRECT');
                methods.setValue('remittanceMethod', 'DIRECT');
            }
        }
    }, [invoice]);

    useMemo(() => {
        if (isStringNotEmpty(loadId) && isListNotEmpty(invoices)) {
            let rateConfirmationObj = invoices.find(i => i.invoiceType === 'RATE_CONFIRMATION' && i.isDeleted === false);
            if (isObjectNotEmpty(rateConfirmationObj)) {
                setRateConfirmation(rateConfirmationObj);
            }

            let ids = [];
            invoices.forEach((invoice) => {
                ids.push(invoice.id);
            });

            dispatch(actionCreators.fetchBulkNoteLists(loadId, ids));
        }
    }, [loadId, invoices]);

    useMemo(() => {
        if (isObjectNotEmpty(load)) {
            let ids = [];
            ids.push(load.id);
            if (isStringNotEmpty(load.assignedCarrierId)) {
                ids.push(load.assignedCarrierId);
            }

            dispatch(actionCreators.fetchBulkDocumentLists(load.id, ids));
        }
    }, [load]);

    useMemo(() => {
        if (isStringNotEmpty(shipperId) && isStringNotEmpty(carrierId) && isListNotEmpty(accounts)) {
            let shipperAccountObj = accounts.find(i => i.id === load.shipperId);
            if (isObjectNotEmpty(shipperAccountObj)) {
                // console.log(shipperAccount);
                setShipperAccount({ ...shipperAccountObj });
            }

            let carrierAccountObj = accounts.find(i => i.id === carrierId);
            if (isObjectNotEmpty(carrierAccountObj)) {
                // console.log(carrierAccountObj);
                setCarrierAccount({ ...carrierAccountObj });
            }
        }
    }, [accounts, shipperId, carrierId]);

    useMemo(() => {
        if (isObjectNotEmpty(invoice) && isStringNotEmpty(remittanceMethod) && isStringEmpty(paymentMethod)) {
            if (remittanceMethod === 'DIRECT') {
                if (invoice.paymentMethod === 'QUICK_PAY') {
                    setPaymentMethod('QUICK_PAY');
                    methods.setValue('paymentMethod', 'QUICK_PAY');
                } else if (invoice.paymentMethod === 'DIRECT_DEPOSIT') {
                    setPaymentMethod('DIRECT_DEPOSIT');
                    methods.setValue('paymentMethod', 'DIRECT_DEPOSIT');
                } else if (invoice.paymentMethod === 'CHECK') {
                    setPaymentMethod('CHECK');
                    methods.setValue('paymentMethod', 'CHECK');
                } else {
                    setPaymentMethod(null);
                    methods.setValue('paymentMethod', null);
                }
            } else if (remittanceMethod === 'FACTORING') {
                setPaymentMethod('FACTORING');
                methods.setValue('paymentMethod', 'FACTORING');
            } else {
                setPaymentMethod(null);
                methods.setValue('paymentMethod', null);
            }
        }
    }, [remittanceMethod, invoice]);

    useMemo(() => {
        if (remittanceMethod === 'DIRECT' && isStringNotEmpty(paymentMethod) && paymentMethod === 'FACTORING') {
            setPaymentMethod(null);
            methods.setValue('paymentMethod', null);
        } else if (remittanceMethod === 'FACTORING' && isStringNotEmpty(paymentMethod) && paymentMethod !== 'FACTORING') {
            setPaymentMethod('FACTORING');
            methods.setValue('paymentMethod', 'FACTORING');
        } else if (isStringEmpty(remittanceMethod) && isStringNotEmpty(paymentMethod)) {
            setPaymentMethod(null);
            methods.setValue('paymentMethod', null);
        }
    }, [remittanceMethod, paymentMethod]);

    useMemo(() => {
        // console.log(`paymentMethod: ${paymentMethod}`);
        if (isStringNotEmpty(paymentMethod) && isStringNotEmpty(serviceType) && isStringNotEmpty(carrierId)) {
            if (paymentMethod === 'QUICK_PAY') {
                generateQuickPayInvoiceLineItems();
            } else {
                setNewInvoiceLineItemList([]);
            }
        }
    }, [paymentMethod, serviceType, carrierId]);

    useMemo(() => {
        if (isObjectNotEmpty(documentLists) && isObjectNotEmpty(load) && isListNotEmpty(stops)) {
            let visibleDocumentsList = DocumentUtils.selectListVisibleDocumentsByLoadIdAndStops(documentLists, load.id, load, stops, 'CARRIER', isAdmin);

            let carrierDocuments = isStringNotEmpty(load.assignedCarrierId) && isNotNullOrUndefined(documentLists[load.assignedCarrierId]) && isListNotEmpty(documentLists[load.assignedCarrierId].records) ? [...documentLists[load.assignedCarrierId].records] : null;
            let visibleCarrierDocuments = DocumentUtils.getVisibleDocuments(carrierDocuments, 'CARRIER', isAdmin);
            let bankAccountDocumentObj = visibleCarrierDocuments.find(doc => doc.documentType === 'BANK_INFORMATION' && doc.isDeleted === false);
            if (isObjectNotEmpty(bankAccountDocumentObj)) {
                setBankAccountDocument(bankAccountDocumentObj);
            } else {
                setBankAccountDocument({});
            }

            let factoringNOADocumentObj = visibleCarrierDocuments.find(doc => doc.documentType === 'FACTORING_NOA' && doc.isDeleted === false);
            if (isObjectNotEmpty(factoringNOADocumentObj)) {
                setFactoringNOADocument(factoringNOADocumentObj);
            } else {
                setFactoringNOADocument({});
            }

            let rateConfirmationDocumentObj = visibleDocumentsList.find(doc => doc.documentType === 'RATE_CONFIRMATION' && doc.isDeleted === false);
            if (isObjectNotEmpty(rateConfirmationDocumentObj)) {
                setRateConfirmationDocument(rateConfirmationDocumentObj);
            } else {
                setRateConfirmationDocument({});
            }

            let carrierInvoiceDocumentObj = visibleDocumentsList.find(doc => doc.documentType === 'CARRIER_INVOICE' && doc.isDeleted === false);
            if (isObjectNotEmpty(carrierInvoiceDocumentObj)) {
                setCarrierInvoiceDocument(carrierInvoiceDocumentObj);
            } else {
                setCarrierInvoiceDocument({});
            }
        }
    }, [documentLists, load, stops, entityType, isAdmin]);

    useMemo(() => {
        if (isLoadingUpdateInvoice !== null && isLoadingUpdateInvoice === false && errorUpdateInvoice === null) {
            onCancel();
        }
    }, [isLoadingUpdateInvoice, errorUpdateInvoice]);

    //#endregion
    //#region displays

    const editMissingDocumentComponents = (
        <Drawer
            title={isObjectNotEmpty(selectedDocument) ? `Upload ${selectedDocument.documentTypeName}${selectedDocument.entityType === 'STOP' && isObjectNotEmpty(selectedDocument.entity) ? " for " + LoadStopUtils.getStopNameDisplay(selectedDocument.entity, shipperId, carrierId, entityType, entityId) : ""}` : ""}
            onClose={toggleEditMissingDocument}
            visible={showEditMissingDocument === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditMissingDocument
                cancel={toggleEditMissingDocument}
                missingDocument={selectedDocument}
                load={load}
                loadId={loadId}
            />
        </Drawer>
    );

    const viewInvoiceModal = (
        <Modal
            title={isObjectNotEmpty(selectedInvoice) ? Enums.InvoiceTypes.getValueByName(selectedInvoice.invoiceType) : ''}
            visible={showViewInvoiceModal === true}
            width="100%"
            style={{ top: 0, height: '100vh' }}
            onCancel={() => { toggleViewInvoice(); setSelectedInvoice(null); }}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <Invoice
                invoiceId={isObjectNotEmpty(selectedInvoice) && isStringNotEmpty(selectedInvoice.id) ? selectedInvoice.id : null}
                invoice={isObjectNotEmpty(selectedInvoice) ? selectedInvoice : null}
                load={load}
                loadId={loadId}
                stops={stops}
                displayManualInstructions={false}
            />
        </Modal>
    );

    const rateConfirmationDocumentComponents = () => {
        if (isObjectNotEmpty(rateConfirmationDocument)) {
            return (
                <DataLinkRow title="Rate Confirmation Document" fileId={rateConfirmationDocument.id} />
            );
        } else {
            return null;
        }
    };

    const rateConfirmationInvoiceComponents = () => {
        if (isObjectNotEmpty(rateConfirmation)) {
            return (
                <>
                    <span style={{ fontWeight: 'bold' }}><PaperClipOutlined style={{ marginRight: 8 }} />Digital Rate Confirmation:</span><Button type="link" onClick={(e) => { setSelectedInvoice(rateConfirmation); toggleViewInvoice(); }}>view</Button>
                </>
            );
        } else {
            return null;
        }
    };

    const bankAccountDocumentComponents = () => {
        if (paymentMethod === 'DIRECT_DEPOSIT' && load.serviceType === 'BROKERAGE') {
            if (isObjectNotEmpty(bankAccountDocument)) {
                return (
                    <FormItemDisplay {...formItemLayout} label="If you chose Direct Deposit and we don't already have this information, please provide us with your bank account information" format="vertical">
                        <DataLinkRow title="Bank Account Information" fileId={bankAccountDocument.id} />
                    </FormItemDisplay>
                );
            } else {
                return (
                    <FormItemDisplay {...formItemLayout} label="If you chose Direct Deposit and we don't already have this information, please provide us with your bank account information" format="vertical">
                        <Button type="default" onClick={(e) => { setSelectedDocument({ entityType: 'ACCOUNT', entityId: carrierAccount.id, entity: carrierAccount, entitySubType: null, entityName: carrierAccount.name, documentType: 'BANK_INFORMATION', documentTypeName: 'Bank Account Information', documentDisplayName: 'Bank Account Information', visibleTo: 'STAFF' }); toggleEditMissingDocument(); }}>Upload Bank Account Information</Button>
                    </FormItemDisplay>
                );
            }
        } else {
            return null;
        }
    };

    const factoringNOADocumentComponents = () => {
        if (paymentMethod === 'FACTORING') {
            if (isObjectNotEmpty(factoringNOADocument)) {
                return (
                    <FormItemDisplay {...formItemLayout} label="If you chose Factoring and we don't already have the Notice of Assignment for your factoring company, please provide us with the Notice of Assignment" format="vertical">
                        <DataLinkRow title="Factoring Notice of Assignment" fileId={factoringNOADocument.id} />
                    </FormItemDisplay>
                );
            } else {
                return (
                    <FormItemDisplay {...formItemLayout} label="If you chose Factoring and we don't already have the Notice of Assignment for your factoring company, please provide us with the Notice of Assignment" format="vertical">
                        <Button type="default" onClick={(e) => { setSelectedDocument({ entityType: 'ACCOUNT', entityId: carrierAccount.id, entity: carrierAccount, entitySubType: null, entityName: carrierAccount.name, documentType: 'FACTORING_NOA', documentTypeName: 'Factoring Notice of Assignment', documentDisplayName: 'Factoring Notice of Assignment', visibleTo: 'STAFF' }); toggleEditMissingDocument(); }}>Upload Factoring Notice of Assignment</Button>
                    </FormItemDisplay>
                );
            }
        } else {
            return null;
        }
    };

    const carrierInvoiceDocumentComponents = () => {
        if (isObjectNotEmpty(carrierInvoiceDocument)) {
            return (
                <DataLinkRow title="Carrier Invoice" fileId={carrierInvoiceDocument.id} />
            );
        } else {
            return (
                <Button size="small" type="ghost" onClick={(e) => { setSelectedDocument({ entityType: 'LOAD', entityId: load.id, entity: load, entitySubType: null, entityName: isStringNotEmpty(load.parentName) ? load.parentName : (isStringNotEmpty(load.name) ? load.name : load.irisId), documentType: 'CARRIER_INVOICE', documentTypeName: 'Carrier Invoice', documentDisplayName: 'Carrier Invoice', visibleTo: 'CARRIER' }); toggleEditMissingDocument(); }}>Upload Invoice</Button>
            );
        }
    };

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    const formItemLayoutQuadruple = {
        labelCol1: { span: 8 },
        wrapperCol1: { span: 8 },
        labelCol2: { span: 8 },
        wrapperCol2: { span: 8 },
        labelCol3: { span: 8 },
        wrapperCol3: { span: 5 },
        labelCol4: { span: 0 },
        wrapperCol4: { span: 3 },
    };

    //#endregion

    if (isObjectNotEmpty(invoice) && isStringNotEmpty(invoiceId) && isObjectNotEmpty(load) && LoadUtils.loadStatusIs(load.loadStatus, ['COMPLETED', 'CLOSED', 'REOPENED']) && isListNotEmpty(stops) && isLoadingDocuments === false) {
        return (
            <CanSee entityAction="UPDATE" entityModel="LOAD_CARRIER_INVOICE" entityObject={load}>
                <FormProvider {...methods}>
                    <Form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoadingUpdateInvoice === true && errorUpdateInvoice === null) || isLoadingDocuments === true || loading === true}>
                            <Fieldset legend="Review Final Rate Confirmation">
                                <InvoiceTable
                                    entityType="CARRIER"
                                    isRateConfirmation={true}
                                    data={[...invoiceLineItems.filter(i => (i.fromEntityType === 'CARRIER' || i.toEntityType === 'CARRIER') && (i.invoiceId === null || i.invoiceId === undefined || i.invoiceId === invoiceId) && i.itemType !== 'QUICK_PAY' && i.status !== 'COMPLETED' && i.isDeleted === false), ...newInvoiceLineItemList]}
                                    columns={rateConfirmationColumns}
                                />
                                {rateConfirmationDocumentComponents()}
                                {rateConfirmationInvoiceComponents()}
                            </Fieldset>
                            <Fieldset legend="Do you have an invoice for us? If so, you can upload it here. Please provide us with an invoice number as well so that we can reference it in our system.">
                                <FormItemDisplay {...formItemLayout} label="Do you have an invoice for us?" format="vertical">
                                    {carrierInvoiceDocumentComponents()}
                                </FormItemDisplay>

                                <FormItem {...formItemLayout} label="Invoice Number (do you have an internal reference number for this invoice?)"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Input
                                            onBlur={onBlur}
                                            onChange={e => { onChange(e.target.value); }}
                                            value={value}
                                            name={name}
                                            placeholder="Invoice Reference Number"
                                            ref={ref}
                                        />
                                    )}
                                    rules={{ required: false }}
                                    name="customerInvoiceNumber"
                                    defaultValue={invoice.customerInvoiceNumber}
                                />
                            </Fieldset>

                            <Fieldset legend="Remittance Method">
                                <FormItem {...formItemLayout} label="Remittance Method" required
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Radio.Group
                                            onBlur={onBlur}
                                            onChange={e => { setRemittanceMethod(e.target.value); onChange(e.target.value); }}
                                            value={value}
                                            name={name}
                                            buttonStyle="solid"
                                            ref={ref}
                                        >
                                            <Radio.Button value="DIRECT">I want to be Paid Directly</Radio.Button>
                                            <Radio.Button value="FACTORING">I am Assigning this Invoice to a Factoring Company</Radio.Button>
                                        </Radio.Group>
                                    )}
                                    rules={{ required: "Required Field" }}
                                    name="remittanceMethod"
                                />
                                {remittanceMethod === 'DIRECT' ? (
                                    <>
                                        <FormItem {...formItemLayout} label="Payment Method" required
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Radio.Group
                                                    onBlur={onBlur}
                                                    onChange={e => { setPaymentMethod(e.target.value); onChange(e.target.value); }}
                                                    value={value}
                                                    name={name}
                                                    buttonStyle="solid"
                                                    ref={ref}
                                                >
                                                    <Radio.Button value="CHECK">Paper Check</Radio.Button>
                                                    {serviceType === 'BROKERAGE' && <Radio.Button value="DIRECT_DEPOSIT">Direct Deposit</Radio.Button>}
                                                    {(serviceType === 'BROKERAGE' && isObjectNotEmpty(shipperAccount) && isBooleanTrue(shipperAccount.isCreditApproved)) && <Radio.Button value="QUICK_PAY">Quick Pay (5% fee, 2-3 business days)</Radio.Button>}
                                                </Radio.Group>
                                            )}
                                            rules={{ required: "Required Field" }}
                                            name="paymentMethod"
                                            defaultValue={paymentMethod}
                                        />
                                        {bankAccountDocumentComponents()}
                                        {paymentMethod === 'QUICK_PAY' ? (
                                            <InvoiceTable
                                                entityType="CARRIER"
                                                isRateConfirmation={true}
                                                data={[...invoiceLineItems.filter(i => (i.fromEntityType === 'CARRIER' || i.toEntityType === 'CARRIER') && (i.invoiceId === null || i.invoiceId === undefined || i.invoiceId === invoiceId) && i.itemType !== 'QUICK_PAY' && i.status !== 'COMPLETED' && i.isDeleted === false), ...newInvoiceLineItemList]}
                                                columns={rateConfirmationColumns}
                                            />
                                        ) : null}
                                    </>
                                ) : null}
                                {remittanceMethod === 'FACTORING' ? (
                                    factoringNOADocumentComponents()
                                ) : null}
                                <FormItem {...formItemLayout} label="Do you have any special instructions for remittance of the invoice?" format="vertical"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Input.TextArea
                                            placeholder="Any special instructions?"
                                            autoSize={{ minRows: 4 }}
                                            onBlur={onBlur}
                                            onChange={e => { onChange(e.target.value); }}
                                            value={value}
                                            name={name}
                                            ref={ref}
                                        />
                                    )}
                                    rules={{ required: false }}
                                    name="invoiceNotes"
                                    defaultValue={invoice.invoiceNotes}
                                />
                            </Fieldset>

                            <Fieldset legend={paymentMethod === "FACTORING" ? "Factoring Company Point of Contact (if we have any questions, who should we contact?)" : ((paymentMethod === 'CHECK' || paymentMethod === 'DIRECT_DEPOSIT' || paymentMethod === 'QUICK_PAY') ? "Accounts Receivable Point of Contact (if we have any questions, who should we contact?)" : "")}>
                                <FormItemQuadruple {...formItemLayoutQuadruple}
                                    label1="Contact Name"
                                    required1
                                    render1={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Person or Factoring Company Name" ref={ref} />}
                                    rules1={{ required: "Required Field" }}
                                    name1="remitPointOfContact.name"
                                    defaultValue1={isObjectNotEmpty(invoice) && isObjectNotEmpty(invoice.remitPointOfContact) && isStringNotEmpty(invoice.remitPointOfContact.name) ? invoice.remitPointOfContact.name : ""}
                                    label2="Email" required2
                                    render2={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" ref={ref} />}
                                    rules2={{
                                        required: "Required Field",
                                        pattern: {
                                            value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                            message: "Please enter a valid email address"
                                        }
                                    }}
                                    name2="remitPointOfContact.email"
                                    defaultValue2={isObjectNotEmpty(invoice) && isObjectNotEmpty(invoice.remitPointOfContact) && isStringNotEmpty(invoice.remitPointOfContact.email) ? invoice.remitPointOfContact.email : ""}
                                    label3="Phone #" required3
                                    render3={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" ref={ref} />}
                                    rules3={{
                                        required: "Required Field",
                                        pattern: {
                                            value: /^\d{10}$/,
                                            message: "Please enter a valid 10 digit phone number with no special characters"
                                        }
                                    }}
                                    name3="remitPointOfContact.phone"
                                    defaultValue3={isObjectNotEmpty(invoice) && isObjectNotEmpty(invoice.remitPointOfContact) && isStringNotEmpty(invoice.remitPointOfContact.phone) ? invoice.remitPointOfContact.phone : ""}
                                    render4={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" ref={ref} />}
                                    rules4={{ required: false }}
                                    name4="remitPointOfContact.phoneExt"
                                    defaultValue4={isObjectNotEmpty(invoice) && isObjectNotEmpty(invoice.remitPointOfContact) && isStringNotEmpty(invoice.remitPointOfContact.phoneExt) ? invoice.remitPointOfContact.phoneExt : ""}
                                />
                            </Fieldset>
                            <Fieldset legend={paymentMethod === "FACTORING" ? "Factoring Company Name and Address (who and where are we sending your money to?)" : ((paymentMethod === 'CHECK' || paymentMethod === 'DIRECT_DEPOSIT' || paymentMethod === 'QUICK_PAY') ? "Company Name and Address (who and where are we sending your money to?)" : "")}>
                                <Row gutter={[12, 12]}>
                                    <Col span={24}>
                                        <FormItem {...formItemLayout} label={paymentMethod === "FACTORING" ? "Factoring Company Name (who should we make the payment out to?)" : ((paymentMethod === 'CHECK' || paymentMethod === 'DIRECT_DEPOSIT' || paymentMethod === 'QUICK_PAY') ? "Company Name (who should we make the payment out to?)" : "")} required
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Input
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e.target.value); }}
                                                    value={value}
                                                    name={name}
                                                    placeholder="Remittance Name"
                                                    ref={ref}
                                                />
                                            )}
                                            rules={{ required: "Required Field" }}
                                            name="remitName"
                                            defaultValue={isObjectNotEmpty(invoice) && isStringNotEmpty(invoice.remitName) ? invoice.remitName : ""}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <FormItemAddress
                                            format="vertical"
                                            required={false}
                                            name="remitAddress"
                                            defaultValue={invoice.remitAddress}
                                            isPostalAddress={true}
                                        />
                                    </Col>
                                </Row>
                            </Fieldset>

                            {errorUpdateInvoice && <Alert message={`${errorUpdateInvoice}`} type="error" />}
                            <FormButtons containerStyle={{ position: 'relative' }} cancel={onCancel} disabled={(isLoadingUpdateInvoice === true && errorUpdateInvoice === null)} submitText="Submit Updated Invoice" />
                        </Spin>
                    </Form>
                </FormProvider>
                {editMissingDocumentComponents}
                {viewInvoiceModal}
            </CanSee>
        );
    } else {
        return null;
    }
};

export default LoadEditCarrierInvoice;