import axiosAuthenticated from "../../api/axiosAuthenticated";
import { isNotNullOrUndefined, isStringNotEmpty } from "../../shared/objectUtils";
import * as DTO from "../dtos/index";

const requestForBidsPath = '/requestForBids';
const requestForBidsBulkPath = '/requestForBids/bulk/get';

//#region Request For Bid Methods

export const getRequestForBidsByLoadId = async(loadId, accounts = []) => {
    if (isStringNotEmpty(loadId)) {
        let searchParams = {
            loadId: loadId,
            isDeleted: false
        };

        const requestForBidsRes = await axiosAuthenticated.post(requestForBidsBulkPath, { ...searchParams });
        if (requestForBidsRes && requestForBidsRes.status === 200) {
            const requestForBids = requestForBidsRes.data.data;

            return requestForBids.map((requestForBid) => { return DTO.getRequestForBidDTO(requestForBid, accounts); });
        }
    }

    return [];
};

export const getRequestForBidsByLoadIdAndAccountId = async(loadId, accountId, accounts = []) => {
    if (isStringNotEmpty(loadId) && isStringNotEmpty(accountId)) {
        let searchParams = {
            loadId: loadId,
            'fromEntityId:or': accountId,
            'toEntityId:or': accountId,
            isDeleted: false
        };

        const requestForBidsRes = await axiosAuthenticated.post(requestForBidsBulkPath, { ...searchParams });
        if (requestForBidsRes && requestForBidsRes.status === 200) {
            const requestForBids = requestForBidsRes.data.data;

            return requestForBids.map((requestForBid) => { return DTO.getRequestForBidDTO(requestForBid, accounts); });
        }
    }

    return [];
};

export const getBulkRequestForBids = async (searchParams = {}, accounts = []) => {
    const requestForBidsRes = await axiosAuthenticated.post(requestForBidsBulkPath, { ...searchParams });
    if (requestForBidsRes && requestForBidsRes.status === 200) {
        const requestForBids = requestForBidsRes.data.data;

        return requestForBids.map((requestForBid) => { return DTO.getRequestForBidDTO(requestForBid, accounts); });
    }

    return [];
};

export const getRequestForBids = async (searchParams = {}, pagination = {}, accounts = []) => {
    const requestForBidsRes = await axiosAuthenticated.get(requestForBidsPath, { params: { ...searchParams } });
    if (requestForBidsRes && requestForBidsRes.status === 200) {
        const requestForBids = requestForBidsRes.data.data;
        const otherData = requestForBidsRes.data;

        const transformedRequestForBids = requestForBids.map((requestForBid) => { return DTO.getRequestForBidDTO(requestForBid, accounts); });

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;

        return { data: transformedRequestForBids, searchParams: searchParams, pagination: pagination };
    }

    return { data: [], searchParams: searchParams, pagination: pagination };
};

export const getRequestForBid = async (id, accounts = []) => {
    const requestForBidsRes = await axiosAuthenticated.get(requestForBidsPath + `/${id}`);
    if (requestForBidsRes && requestForBidsRes.status === 200) {
        let requestForBid = requestForBidsRes.data;

        return DTO.getRequestForBidDTO(requestForBid, accounts);
    }

    return null;
};

export const addRequestForBid = async (payload, accounts = []) => {
    const requestForBidPayload = DTO.getAddRequestForBidRequestDTO(payload);

    const requestForBidRes = await axiosAuthenticated.post(requestForBidsPath, { ...requestForBidPayload });
    if (requestForBidRes && requestForBidRes.status === 201) {
        let newRequestForBid = requestForBidRes.data;

        return DTO.getRequestForBidDTO(newRequestForBid, accounts);
    }

    return null;
};

export const updateRequestForBid = async (id, payload, accounts = []) => {
    const requestForBidPayload = DTO.getUpdateRequestForBidRequestDTO(payload);

    const requestForBidsRes = await axiosAuthenticated.put(requestForBidsPath + `/${id}`, { ...requestForBidPayload });
    if (requestForBidsRes && requestForBidsRes.status === 200) {
        let updatedRequestForBid = requestForBidsRes.data;

        return DTO.getRequestForBidDTO(updatedRequestForBid, accounts);
    }

    return null;
};

export const removeRequestForBid = async (id) => {
    const requestForBidsRes = await axiosAuthenticated.put(requestForBidsPath + `/${id}`, { isDeleted: true });
    if (requestForBidsRes && requestForBidsRes.status === 200) {
        return requestForBidsRes.data;
    }

    return null;
};

//#endregion