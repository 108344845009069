import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Wrapper } from "@googlemaps/react-wrapper";
import { isBooleanTrue, isListEmpty, isListNotEmpty, isNotNullOrUndefined, isNumberNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import PubNub from 'pubnub';
import { useSelector } from "react-redux";
import { reverse } from 'lodash';
import Enums from '../../shared/enums';
import logger from '../../shared/logger';
import { getLoadLocations } from '../../api/data';
import { useDeepCompareEffectForMaps } from '../../shared/MapUtils/useDeepCompareEffectForMaps';
import { RefreshActualPathControl } from '../../shared/MapUtils/RefreshActualPathControl';
import { GoToCurrentLocationControl } from '../../shared/MapUtils/GoToCurrentLocationControl';
import { useEffectWhen } from '../../shared/useEffectWhen';
import { useDeepCompareEffect } from '../../shared/useDeepCompareEffect';

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const render = (status) => {
    return <h1>{status}</h1>;
};

const MapWrapper = ({
    loadId,
    stops,
    origin,
    destination,
    currentLatitude,
    currentLongitude,
    showZoomControl = true,
    notAuthenticated = false,
    containerName = null,
    fullScreen = false,
    height = "500px",
    style = {},
    setRouteDistance,
    setRouteDuration,
    setOriginLatitude,
    setOriginLongitude,
    setDestinationLatitude,
    setDestinationLongitude,
    parentRef
}) => {
    const [clicks, setClicks] = React.useState([]);
    const [zoom, setZoom] = React.useState(8); // initial zoom
    const [center, setCenter] = React.useState({
        lat: 38.8977,
        lng: -77.0365,
    });

    const onClick = (e) => {
        // avoid directly mutating state
        // setClicks([...clicks, e.latLng]);
    };

    const onIdle = (m) => {
        // console.log("onIdle");
        // setZoom(m.getZoom());
        // setCenter(m.getCenter().toJSON());
    };

    return (
        <Wrapper apiKey={apiKey} render={render}>
            <CustomMap
                center={center}
                onClick={onClick}
                onIdle={onIdle}
                setRouteDistance={setRouteDistance}
                setRouteDuration={setRouteDuration}
                setOriginLatitude={setOriginLatitude}
                setOriginLongitude={setOriginLongitude}
                setDestinationLatitude={setDestinationLatitude}
                setDestinationLongitude={setDestinationLongitude}
                zoom={zoom}
                loadId={loadId}
                stops={stops}
                origin={origin}
                destination={destination}
                currentLatitude={currentLatitude}
                currentLongitude={currentLongitude}
                showZoomControl={showZoomControl}
                notAuthenticated={notAuthenticated}
                containerName={containerName}
                fullScreen={fullScreen}
                height={height}
                style={style}
                parentRef={parentRef}
            //style={{ flexGrow: "1", height: "100%" }}
            >
                {clicks.map((latLng, i) => (
                    <Marker key={i} position={latLng} />
                ))}
            </CustomMap>
        </Wrapper>
    );
};

const Marker = (options) => {
    const [marker, setMarker] = React.useState();

    React.useEffect(() => {
        if (!marker) {
            setMarker(new window.google.maps.Marker());
        }

        // remove marker from map on unmount
        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [marker]);

    React.useEffect(() => {
        if (marker) {
            marker.setOptions(options);
        }
    }, [marker, options]);

    return null;
};

const CustomMap = ({
    onClick,
    onIdle,
    setRouteDistance,
    setRouteDuration,
    setOriginLatitude,
    setOriginLongitude,
    setDestinationLatitude,
    setDestinationLongitude,
    children,
    style,
    loadId,
    stops,
    origin,
    destination,
    currentLatitude,
    currentLongitude,
    showZoomControl = true,
    notAuthenticated = false,
    containerName = null,
    fullScreen = false,
    height = "500px",
    parentRef,
    ...options
}) => {
    containerName = isStringNotEmpty(containerName) ? containerName : `map-${loadId}`;

    const mapRef = useRef(null);
    const actualPathRef = useRef(null);
    const currentLocationRef = useRef(null);
    const markersRef = useRef(null);
    const directionsServiceRef = useRef(null);
    const directionsRendererRef = useRef(null);
    const [map, setMap] = useState();
    const [mapStops, setMapStops] = useState();

    const channel = process.env.REACT_APP_LOAD_LOCATIONS_CHANNEL + '_' + loadId;

    const userId = useSelector(state => state.auth.userId);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const pubNubSubKey = useSelector(state => state.keys.pubNubSubKey);
    const pubNubPubKey = useSelector(state => state.keys.pubNubPubKey);
    const entityType = useSelector(state => state.auth.entityType);

    const currentLocationMarker = {
        url: "https://map.b4x83us.irisfreight.com/current_location_mark.png",
        scaledSize: new window.google.maps.Size(30, 30),
        anchor: new window.google.maps.Point(15, 15),
        scale: 0.7,
    };
    const pickUpMarker = {
        url: "https://map.b4x83us.irisfreight.com/pickUpMarker.png",
        scaledSize: new window.google.maps.Size(30, 30),
        anchor: new window.google.maps.Point(15, 15),
        scale: 0.7,
    };
    const dropOffMarker = {
        url: "https://map.b4x83us.irisfreight.com/dropOffMarker.png",
        scaledSize: new window.google.maps.Size(30, 30),
        anchor: new window.google.maps.Point(15, 15),
        scale: 0.7,
    };
    const restStopMarker = {
        url: "https://map.b4x83us.irisfreight.com/restStopMarker.png",
        scaledSize: new window.google.maps.Size(30, 30),
        anchor: new window.google.maps.Point(15, 15),
        scale: 0.7,
    };

    const getLocations = () => {
        if (isAuthenticated === true && notAuthenticated === false && entityType === 'STAFF' && isStringNotEmpty(loadId) && isNotNullOrUndefined(mapRef.current)) {
            // comes in descending by eventTimeStamp so the array needs to be flipped
            getLoadLocations(loadId).then(locs => {
                if (isListNotEmpty(locs)) {
                    //console.log(locs);
                    let reverseLocs = reverse(locs); // reverse mutates the array when it reverses it so now locs will be reversed
                    //console.log(reverseLocs);
                    let locsArray = [];
                    locsArray = locs.map(item => {
                        return { lat: item.latitude, lng: item.longitude }
                    });

                    actualPathRef.current = new window.google.maps.Polyline({
                        strokeColor: "#FF0000",
                        strokeOpacity: 1.0,
                        strokeWeight: 4,
                        map: map,
                    });

                    actualPathRef.current.setPath(locsArray);
                    // console.log(actualPathRef.current.getPath());

                    if (isListNotEmpty(locsArray)) {
                        const currentIndex = locsArray.length - 1;
                        updateCurrentLocation(locsArray[currentIndex].lat, locsArray[currentIndex].lng);
                    }
                }
            }).catch(err => {
                logger.logDebugEvent('Map.js', err.message, true);
            });
        }
    };

    const updateCurrentLocation = (latitude, longitude) => {
        if (map) {
            // mapRef.current.jumpTo({
            //     center: { lat: latitude, lng: longitude },
            //     //zoom: 14
            // });

            if (currentLocationRef.current !== undefined && currentLocationRef.current !== null) {
                currentLocationRef.current.setPosition({ lat: latitude, lng: longitude });
            } else {
                currentLocationRef.current = new window.google.maps.Marker({
                    map,
                    draggable: false,
                    position: { lat: latitude, lng: longitude },
                    title: 'Current Location',
                    icon: currentLocationMarker
                });
            }
        }
    };

    const updateActualPath = (latitude, longitude) => {
        // only show the actual path if the user is authenticated and is a staff member, otherwise only show the current location marker
        if (isAuthenticated === true && notAuthenticated === false && entityType === 'STAFF') {
            //console.log(actualPathRef.current);
            if (actualPathRef.current !== undefined && actualPathRef.current !== null) {
                let locsArray = actualPathRef.current.getPath();
                // console.log(locsArray);
                locsArray.push({ lat: latitude, lng: longitude });
                actualPathRef.current.setPath(locsArray);

            } else {
                let locsArray = [];
                locsArray.push({ lat: latitude, lng: longitude });
                actualPathRef.current.setPath(locsArray);
            }
        }

        updateCurrentLocation(latitude, longitude);
    };

    const addStopMarker = (markerIcon, stopId, stop, latitude, longitude) => {
        if (map) {
            let updatedMarkers = markersRef.current ? [...markersRef.current] : [];
            const marker = new window.google.maps.Marker({
                map,
                draggable: false,
                position: { lat: latitude, lng: longitude },
                title: stop.stopLocation ? Enums.StopTypes.getValueByName(stop.stopType) + ": " + stop.stopLocation.name : Enums.StopTypes.getValueByName(stop.stopType),
                icon: markerIcon
            });

            updatedMarkers.push(marker);
            // console.log(updatedMarkers);
            markersRef.current = updatedMarkers;
        }
    };

    // Shows any markers currently in the array.
    const showMarkers = () => {
        if (isNotNullOrUndefined(map)) {
            if (isListNotEmpty(markersRef.current)) {
                let updatedMarkers = [...markersRef.current];
                for (let i = 0; i < updatedMarkers.length; i++) {
                    updatedMarkers[i].setMap(map);
                }
                markersRef.current = updatedMarkers;
            }
        }
    };

    // Removes the markers from the map, but keeps them in the array.
    const hideMarkers = () => {
        if (isListNotEmpty(markersRef.current)) {
            // console.log('hiding markers')
            let updatedMarkers = markersRef.current ? [...markersRef.current] : [];
            for (let i = 0; i < updatedMarkers.length; i++) {
                updatedMarkers[i].setMap(null);
            }
            markersRef.current = updatedMarkers;
        }
    };

    // Deletes all markers in the array by removing references to them.
    const deleteMarkers = () => {
        hideMarkers();

        if (markersRef.current !== null) {
            markersRef.current = [];
        }
        // console.log('delete markers');
    };

    const deleteRoute = () => {
        try {
            if (directionsRendererRef.current !== null) {
                directionsRendererRef.current.setMap(null);
            }

            deleteMarkers();

            if (isNotNullOrUndefined(setRouteDistance)) {
                setRouteDistance(null);
            }
            if (isNotNullOrUndefined(setRouteDuration)) {
                setRouteDuration(null);
            }
        } catch (err) {
            logger.logDebugEvent('Map.js', err.message, true);
        }
    };

    useEffect(() => {
        if (isNotNullOrUndefined(parentRef)) {
            parentRef.current.deleteRoute = deleteRoute;
        }
    }, [parentRef]);

    useEffect(() => {
        // console.log(showZoomControl)
        if (map) {
            map.setOptions({
                zoomControl: false,
                fullscreenControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false
            });

            if (showZoomControl === true) {
                map.setOptions({
                    zoomControl: true,
                    zoomControlOptions: {
                        position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
                    }
                });
            }

            if (currentLocationRef !== null && currentLocationRef.current !== null) {
                // Create the DIV to hold the control and call the GoToCurrentLocationControl() constructor passing in this DIV.
                const goToCurrentLocationControlDiv = document.createElement("div");

                GoToCurrentLocationControl(goToCurrentLocationControlDiv, map, currentLocationRef.current);
                map.controls[window.google.maps.ControlPosition.LEFT_BOTTOM].push(goToCurrentLocationControlDiv);
            }

            if (entityType === 'STAFF' && notAuthenticated === false) {
                // Create the DIV to hold the control and call the RefreshActualPathControl() constructor passing in this DIV.
                const refreshActualPathControlDiv = document.createElement("div");

                RefreshActualPathControl(refreshActualPathControlDiv, map, getLocations);
                map.controls[window.google.maps.ControlPosition.RIGHT_TOP].push(refreshActualPathControlDiv);
            }

            if (isAuthenticated === true && notAuthenticated === false) {
                getLocations();
            }
        }

        return () => {

        };
    }, [map, isAuthenticated, notAuthenticated, currentLocationRef, showZoomControl]);

    useEffect(() => {
        if (mapRef.current && !map) {
            setMap(new window.google.maps.Map(mapRef.current, {}));
        }
    }, [mapRef, map]);

    useMemo(() => {
        let pubnub = null;
        if (map && isAuthenticated === true && notAuthenticated === false && isNotNullOrUndefined(userId) && isNotNullOrUndefined(pubNubSubKey) && isNotNullOrUndefined(pubNubPubKey)) {
            // console.log("Setting up load location PubNub!");
            pubnub = new PubNub({
                publishKey: pubNubPubKey,
                subscribeKey: pubNubSubKey,
                ssl: true,
                uuid: userId,
                heartbeatInterval: 0
            });

            pubnub.addListener({
                status: function (statusEvent) {
                    if (statusEvent.category === "PNConnectedCategory") {
                        // console.log("Connected to load location PubNub!");
                    }
                },
                message: function (msg) {
                    // console.log('Message from load location PubNub:');
                    // console.log(msg);
                    if (msg.message.latitude && msg.message.longitude) {
                        updateActualPath(msg.message.latitude, msg.message.longitude);
                    }
                }
            });

            //Subscribes to the channel in our state
            pubnub.subscribe({
                channels: [channel],
                triggerEvents: true,
                //withPresence: true,
                //autoload: 100
            });
        } else if (pubnub !== null) {
            // console.log("Shutting down load location PubNub!");
            pubnub.unsubscribeAll();
        }

        return () => {
            if (pubnub !== null) {
                // console.log("Shutting down load location PubNub!");
                pubnub.unsubscribeAll();
            }
        };
    }, [map, isAuthenticated, notAuthenticated, userId, pubNubSubKey, pubNubPubKey]);

    useEffect(() => {
        // console.log(map);
        // console.log('setup direction service');
        if (map && directionsServiceRef.current === null && directionsRendererRef.current === null) {
            directionsServiceRef.current = new window.google.maps.DirectionsService();
            directionsRendererRef.current = new window.google.maps.DirectionsRenderer({
                suppressMarkers: true
            });
        }

        return () => {

        };
    }, [map, directionsServiceRef, directionsRendererRef]);

    useDeepCompareEffect(() => {
        console.log('get mapstops');
        console.log(stops);
        console.log(loadId);
        if (isStringNotEmpty(loadId) && isListNotEmpty(stops)) {
            if (stops.filter(stop => stop.latitude !== undefined && stop.longitude !== undefined && stop.loadId === loadId).length > 0) {
                let mapStopsWithStopType = stops.filter(stop => stop.latitude !== undefined && stop.longitude !== undefined && stop.loadId === loadId).map((stop) => {
                    return {
                        stop: stop,
                        longitude: stop.longitude,
                        latitude: stop.latitude,
                        stopType: stop.stopType,
                        id: stop.id,
                        loadId: stop.loadId
                    };
                });

                setMapStops(mapStopsWithStopType);
            }
        }
    }, [stops, loadId]);

    useEffectWhen(() => {
        console.log('re-route');
        console.log(mapStops);
        // console.log(map);
        if (map && isListNotEmpty(mapStops) && mapStops.length > 1 && directionsServiceRef.current !== null && directionsRendererRef.current !== null) {
            deleteRoute();

            let origin = mapStops[0];
            let destination = mapStops[mapStops.length - 1];
            let waypoints = [];

            mapStops.forEach((stop) => {
                if (stop.stopType === 'PICK_UP') {
                    if (stop.id !== origin.id) {
                        waypoints.push({
                            location: { lat: stop.latitude, lng: stop.longitude },
                            stopover: true
                        });
                    }
                } else if (stop.stopType === 'DROP_OFF') {
                    if (stop.id !== destination.id) {
                        waypoints.push({
                            location: { lat: stop.latitude, lng: stop.longitude },
                            stopover: true
                        });
                    }
                } else {
                    waypoints.push({
                        location: { lat: stop.latitude, lng: stop.longitude },
                        stopover: true
                    });
                }
            });

            directionsServiceRef.current
                .route({
                    origin: { lat: origin.latitude, lng: origin.longitude },
                    destination: { lat: destination.latitude, lng: destination.longitude },
                    waypoints: waypoints,
                    travelMode: window.google.maps.TravelMode.DRIVING,
                    avoidFerries: true,
                    avoidTolls: true,
                    unitSystem: window.google.maps.UnitSystem.IMPERIAL,
                    drivingOptions: {
                        departureTime: new Date(Date.now() + 3600),  // for the time N milliseconds from now.
                        trafficModel: 'pessimistic'
                    }
                })
                .then((response) => {
                    // console.log(response);
                    // console.log(response.routes[0].legs);
                    if (isNotNullOrUndefined(response.routes) && response.routes.length > 0 && isListNotEmpty(response.routes[0].legs)) {
                        let legs = response.routes[0].legs;
                        let totalDistance = 0.0;
                        let totalDuration = 0.0;
                        legs.forEach((leg) => {
                            if (isObjectNotEmpty(leg.distance) && isNumberNotEmpty(leg.distance.value)) {
                                let legDistance = leg.distance.value / 1609.344;
                                // console.log(legDistance.toFixed(2));
                                totalDistance += parseFloat(legDistance.toFixed(2));
                            }
                            if (isObjectNotEmpty(leg.duration_in_traffic) && isNumberNotEmpty(leg.duration_in_traffic.value)) {
                                let legDuration = leg.duration_in_traffic.value / 60.0;
                                // console.log(legDuration.toFixed(0));
                                totalDuration += parseFloat(legDuration.toFixed(0));
                            }
                        });

                        if (isNotNullOrUndefined(setRouteDistance)) {
                            setRouteDistance(parseFloat(totalDistance.toFixed(2)));
                        }
                        if (isNotNullOrUndefined(setRouteDuration)) {
                            setRouteDuration(parseFloat(totalDuration.toFixed(0)));
                        }

                        mapStops.forEach((stop) => {
                            if (stop.stopType === 'PICK_UP') {
                                addStopMarker(pickUpMarker, stop.id, stop.stop, stop.latitude, stop.longitude);
                            } else if (stop.stopType === 'DROP_OFF') {
                                addStopMarker(dropOffMarker, stop.id, stop.stop, stop.latitude, stop.longitude);
                            } else {
                                addStopMarker(restStopMarker, stop.id, stop.stop, stop.latitude, stop.longitude);
                            }
                        });

                        directionsRendererRef.current.setDirections(response);

                        directionsRendererRef.current.setMap(map);
                    } else {
                        deleteRoute();
                    }
                })
                .catch((e) => window.alert("Directions request failed due to " + e.message));
        }

        return () => {

        };
    }, [map, mapStops, markersRef, directionsServiceRef, directionsRendererRef], [mapStops]);

    useEffectWhen(() => {
        // console.log(origin);
        // console.log(destination);
        // console.log(map);
        if (map && isStringNotEmpty(origin) && isStringNotEmpty(destination) && directionsServiceRef.current !== null && directionsRendererRef.current !== null) {
            deleteRoute();

            directionsServiceRef.current
                .route({
                    origin: origin,
                    destination: destination,
                    travelMode: window.google.maps.TravelMode.DRIVING,
                    avoidFerries: true,
                    avoidTolls: true,
                    unitSystem: window.google.maps.UnitSystem.IMPERIAL,
                    drivingOptions: {
                        departureTime: new Date(Date.now() + 3600),  // for the time N milliseconds from now.
                        trafficModel: 'pessimistic'
                    }
                })
                .then((response) => {
                    console.log(response);
                    console.log(response.routes[0].legs);
                    // console.log(response.routes[0].legs[0].start_location.lat());
                    // console.log(response.routes[0].legs[0].start_location.lng());
                    // console.log(response.routes[0].legs[0].end_location.lat());
                    // console.log(response.routes[0].legs[0].end_location.lng());
                    if (isListNotEmpty(response.routes) && isListNotEmpty(response.routes[0].legs)) {
                        let leg = response.routes[0].legs[0];

                        if (isObjectNotEmpty(leg.distance) && isNumberNotEmpty(leg.distance.value)) {
                            let legDistance = leg.distance.value / 1609.344;
                            console.log(legDistance.toFixed(2));
                            if (isNotNullOrUndefined(setRouteDistance)) {
                                setRouteDistance(parseFloat(legDistance.toFixed(2)));
                            }
                        }
                        if (isObjectNotEmpty(leg.duration_in_traffic) && isNumberNotEmpty(leg.duration_in_traffic.value)) {
                            let legDuration = leg.duration_in_traffic.value / 60.0;
                            // console.log(legDuration.toFixed(0));
                            if (isNotNullOrUndefined(setRouteDuration)) {
                                setRouteDuration(parseFloat(legDuration.toFixed(0)));
                            }
                        }

                        if (isObjectNotEmpty(leg.start_location)) {
                            if (isNotNullOrUndefined(setOriginLatitude)) {
                                setOriginLatitude(leg.start_location.lat());
                            }
                            if (isNotNullOrUndefined(setOriginLongitude)) {
                                setOriginLongitude(leg.start_location.lng());
                            }
                            addStopMarker(pickUpMarker, 0, {
                                longitude: leg.start_location.lng(),
                                latitude: leg.start_location.lat(),
                                stopType: 'PICK_UP',
                                id: 0
                            }, leg.start_location.lat(), leg.start_location.lng());
                        }
    
                        if (isObjectNotEmpty(leg.end_location)) {
                            if (isNotNullOrUndefined(setDestinationLatitude)) {
                                setDestinationLatitude(leg.end_location.lat());
                            }
                            if (isNotNullOrUndefined(setDestinationLongitude)) {
                                setDestinationLongitude(leg.end_location.lng());
                            }
                            addStopMarker(dropOffMarker, 1, {
                                longitude: leg.end_location.lng(),
                                latitude: leg.end_location.lat(),
                                stopType: 'DROP_OFF',
                                id: 1
                            }, leg.end_location.lat(), leg.end_location.lng());
                        }

                        directionsRendererRef.current.setDirections(response);

                        directionsRendererRef.current.setMap(map);
                    } else {
                        deleteRoute();
                    }
                })
                .catch((e) => window.alert("Directions request failed due to " + e.message));
        }

        return () => {

        };
    }, [map, origin, destination, markersRef, directionsServiceRef, directionsRendererRef], [origin, destination]);

    useEffect(() => {
        if (map && notAuthenticated === true && isNotNullOrUndefined(currentLatitude) && isNotNullOrUndefined(currentLongitude)) {
            updateCurrentLocation(currentLatitude, currentLongitude);
        }

        return () => {

        };
    }, [map, notAuthenticated, currentLatitude, currentLongitude]);

    useDeepCompareEffectForMaps(() => {
        if (map) {
            map.setOptions(options);
        }
    }, [map, options]);

    useEffect(() => {
        if (map) {
            ["click", "idle"].forEach((eventName) =>
                window.google.maps.event.clearListeners(map, eventName)
            );

            if (onClick) {
                map.addListener("click", onClick);
            }

            if (onIdle) {
                map.addListener("idle", () => onIdle(map));
            }
        }
    }, [map, onClick, onIdle]);

    return (
        <>
            <div ref={mapRef} style={{ ...style, width: "100%", height: isBooleanTrue(fullScreen) ? "100vh" : (isStringNotEmpty(height) ? height : "500px") }} id={containerName} />
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    // set the map prop on the child component
                    return React.cloneElement(child, { map });
                }
            })}
        </>
    );
};

export default MapWrapper;