import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Row, Col, Tooltip, Button } from "antd";
import { isBooleanTrue, isNotNullOrUndefined, isStringEmpty } from '../../shared/objectUtils';

const DataTableTitle = ({ tableTitle = null, action = null, pluralEntityName, singularEntityName, showInstructions = true }) => {
    if (isNotNullOrUndefined(action)) {
        return (
            <Row gutter={[4, 4]} style={{ display: 'flex', alignItems: 'center' }}>
                {isStringEmpty(tableTitle) ? (
                    <Col xs={{ span: 22 }}>
                        <b>{'Manage ' + pluralEntityName}</b> {isBooleanTrue(showInstructions) ? (<span>(Select a row to view more details)</span>) : null}
                    </Col>
                ) : (
                        <Col xs={{ span: 22 }}>
                            <b>{tableTitle}</b> {isBooleanTrue(showInstructions) ? (<span>(Select a row to view more details)</span>) : null}
                        </Col>
                    )}
                <Col xs={{ span: 2 }} style={{ textAlign: 'right' }}>
                    <Tooltip placement="top" title={'New ' + singularEntityName}><Button key="1" type="primary" icon={<PlusOutlined />} shape="circle" onClick={action} /></Tooltip>
                </Col>
            </Row>
        );
    } else {
        return (
            <Row gutter={[4, 4]} style={{ display: 'flex', alignItems: 'center' }}>
                {isStringEmpty(tableTitle) ? (
                    <Col xs={{ span: 24 }}>
                        <b>{'Manage ' + pluralEntityName}</b> {isBooleanTrue(showInstructions) ? (<span>(Select a row to view more details)</span>) : null}
                    </Col>
                ) : (
                        <Col xs={{ span: 24 }}>
                            <b>{tableTitle}</b> {isBooleanTrue(showInstructions) ? (<span>(Select a row to view more details)</span>) : null}
                        </Col>
                    )}
            </Row>
        );
    }
};

export default DataTableTitle;