import React, { useState, useMemo } from 'react';
import { Alert, Spin, Switch } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import FormItem from '../FormItem/FormItem';
import { isBooleanTrue, isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { CanSee } from '../../shared/entitlements/entitlements';
import LoadUtils from '../../api/utils/loadUtils';

const EditLoadStatusScheduled = ({ load, missingPedigreeInformation, cancel }) => {
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loadEvents.isLoadEventUpdateLoading);
    const error = useSelector(state => state.loadEvents.updateLoadEventError);
    const accounts = useSelector(state => state.orchestrator.accounts);

    //#endregion
    //#region useStates

    const [hasDrivers, setHasDrivers] = useState(false);
    // const [hasApprovedDrivers, setHasApprovedDrivers] = useState(false);
    const [hasAssets, setHasAssets] = useState(false);
    const [hasActiveCarrier, setHasActiveCarrier] = useState(false);
    const [hasVerifiedCarrier, setHasVerifiedCarrier] = useState(false);
    const [overrideScheduled, setOverrideScheduled] = useState(false);

    //#endregion
    //#region onSubmit and cancel

    const onSubmit = () => {
        dispatch(actionCreators.sendLoadEvent({
            loadId: load.id,
            eventType: 'LOAD_SCHEDULED'
        }));
    };

    const onCancel = () => {
        dispatch(actionCreators.updateLoadEventComplete());
        cancel();
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLoadEventErrorClear());
        dispatch(actionCreators.getAccounts());
    }, []);

    useMemo(() => {
        // let hasDriver1ApprovedValue = false;
        // let hasDriver2ApprovedValue = false;
        let hasDriversValue = false;
        let hasAssetsValue = false;
        if (isObjectNotEmpty(load)) {
            const hasTeamDriving = isListNotEmpty(load.driverRequirements) && load.driverRequirements.includes('TEAM_DRIVING') ? true : false;

            if (isListNotEmpty(load.drivers)) {
                // if team driving is selected then make sure there are 2 drivers assigned, otherwise make sure there is only 1 driver assigned to the load
                if (hasTeamDriving === true && load.drivers.length === 2) {
                    hasDriversValue = true;
                    // let loadDriver1 = load.drivers[0];
                    // let loadDriver2 = load.drivers[1];
                    // // make sure all drivers assigned to the load are approved
                    // hasDriver1ApprovedValue = isObjectNotEmpty(loadDriver1) && isObjectNotEmpty(loadDriver1.driver) && isBooleanTrue(loadDriver1.driver.isApproved) ? true : false;
                    // hasDriver2ApprovedValue = isObjectNotEmpty(loadDriver2) && isObjectNotEmpty(loadDriver2.driver) && isBooleanTrue(loadDriver2.driver.isApproved) ? true : false;
                } else if (hasTeamDriving === false && load.drivers.length === 1) {
                    hasDriversValue = true;
                    // let loadDriver1 = load.drivers[0];
                    // // make sure all drivers assigned to the load are approved
                    // hasDriver1ApprovedValue = isObjectNotEmpty(loadDriver1) && isObjectNotEmpty(loadDriver1.driver) && isBooleanTrue(loadDriver1.driver.isApproved) ? true : false;
                    // hasDriver2ApprovedValue = true;
                }
            }

            if (isListNotEmpty(load.assetIds)) {
                hasAssetsValue = true;
            }
        }

        setHasDrivers(hasDriversValue === true);
        // setHasApprovedDrivers(hasDriver1ApprovedValue === true && hasDriver2ApprovedValue === true);
        setHasAssets(hasAssetsValue === true);
    }, [load]);

    useMemo(() => {
        let hasActiveCarrierValue = false;
        let hasVerifiedCarrierValue = false;
        if (isObjectNotEmpty(load) && LoadUtils.isTMSLoad(load)) {
            hasActiveCarrierValue = true;
            hasVerifiedCarrierValue = true;
        } else if (isListNotEmpty(accounts) && isObjectNotEmpty(load) && isStringNotEmpty(load.assignedCarrierId)) {
            let assignedCarrier = accounts.find(i => i.id === load.assignedCarrierId);
            if (isObjectNotEmpty(assignedCarrier)) {
                hasActiveCarrierValue = isBooleanTrue(assignedCarrier.isActive) ? true : false;
                hasVerifiedCarrierValue = isBooleanTrue(assignedCarrier.isVerified) ? true : false;
            }
        }

        setHasActiveCarrier(hasActiveCarrierValue === true);
        setHasVerifiedCarrier(hasVerifiedCarrierValue === true);
    }, [accounts, load]);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 16 },
        wrapperCol: { span: 8 },
    };

    //#endregion

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Fieldset legend="Schedule the Load">
                        {hasDrivers === false && (isObjectNotEmpty(load) && isListNotEmpty(load.driverRequirements) && load.driverRequirements.includes('TEAM_DRIVING')) === false ? (<Alert message="You must assign a driver to this load." type="error" />) : null}
                        {hasDrivers === false && (isObjectNotEmpty(load) && isListNotEmpty(load.driverRequirements) && load.driverRequirements.includes('TEAM_DRIVING')) === true ? (<Alert message="You must assign two drivers to this load." type="error" />) : null}
                        {hasAssets === false ? (<Alert message="You must assign at least one asset to this load." type="error" />) : null}
                        {/* {hasDrivers === true && hasApprovedDrivers === false && (isObjectNotEmpty(load) && isListNotEmpty(load.driverRequirements) && load.driverRequirements.includes('TEAM_DRIVING')) === false ? (<Alert message="The driver assigned to this load must be approved before this load can be dispatched." type="error" />) : null}
                        {hasDrivers === true && hasApprovedDrivers === false && (isObjectNotEmpty(load) && isListNotEmpty(load.driverRequirements) && load.driverRequirements.includes('TEAM_DRIVING')) === true ? (<Alert message="Both drivers assigned to this load must be approved before this load can be dispatched." type="error" />) : null} */}
                        {hasActiveCarrier === false ? (<Alert message="The Carrier Account must be activated first before dispatching this load. Please contact Customer Support for assistance." type="error" />) : null}
                        {hasVerifiedCarrier === false ? (<Alert message="The Carrier Account must be verified first before dispatching this load. Please contact Customer Support for assistance." type="error" />) : null}
                        {isListNotEmpty(missingPedigreeInformation) ? missingPedigreeInformation.map((missingInfo, index, arr) => {
                            if (missingInfo.entityType === 'STOP') {
                                return (
                                    <Alert
                                        key={`missing-pedigree-information-${index}`}
                                        type="error"
                                        message={`${missingInfo.entityName} is missing ${missingInfo.missingFields.map((missingField) => missingField.missingFieldName).join(', ')}`}
                                    />
                                );
                            } else {
                                return (
                                    <Alert
                                        key={`missing-pedigree-information-${index}`}
                                        type="error"
                                        message={`${missingInfo.entityName} is missing ${missingInfo.missingFieldName}`}
                                    />
                                );
                            }
                        }) : null}

                        <CanSee staffOnly={true}>
                            {(hasDrivers === false || hasAssets === false || hasActiveCarrier === false || hasVerifiedCarrier === false || missingPedigreeInformation.length > 0) ? (
                                <div style={{ marginTop: 60 }}>
                                    <FormItem {...formItemLayout} label={`Over-Ride Scheduling of this Load.`} format="horizontal"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Switch
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                onBlur={onBlur}
                                                onChange={(checked, event) => { setOverrideScheduled(checked); onChange(checked); }}
                                                checked={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        name="overrideScheduled"
                                        defaultValue={false}
                                    />
                                </div>
                            ) : null}
                        </CanSee>
                    </Fieldset>
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} cancelDisabled={isLoading === true && error === null} submitDisabled={((hasDrivers === false || hasAssets === false || hasActiveCarrier === false || hasVerifiedCarrier === false || missingPedigreeInformation.length > 0) && overrideScheduled === false) || (isLoading === true && error === null)} submitText="Schedule the Load" />
            </Form>
        </FormProvider>
    );
};

export default EditLoadStatusScheduled;