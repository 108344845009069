import React, { useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { Button, Drawer, Modal, Row, Col, Spin, Select, Empty, Checkbox } from 'antd';
import EditScheduledShift from "../../components/EditScheduledShift/EditScheduledShift";
import NewScheduledShift from "../../components/NewScheduledShift/NewScheduledShift";
import moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import Header from '../Header/Header';
import { isBooleanTrue, isListNotEmpty, isNotNullOrUndefined, isObjectNotEmpty, isStringEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { CanDo } from '../../shared/entitlements/entitlements';
import { selectListError, selectListIsLoading, selectListRecords } from '../../store/utility';

const { Option } = Select;

const Calendar = ({ accountId, accountUserId, shiftId }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;
    const singularEntityName = "Scheduled Shift";

    //#endregion
    //#region useStates

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showRemoveEntity, setShowRemoveEntity] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [selectedShiftIds, setSelectedShiftIds] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedAccountUserId, setSelectedAccountUserId] = useState(null);
    const [selectedAccountUserIds, setSelectedAccountUserIds] = useState(null);
    const [selectedAccountId, setSelectedAccountId] = useState(null);
    const [driversOnly, setDriversOnly] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState(null);

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);
    const isLoading = useSelector(state => selectListIsLoading(state.scheduledShifts.lists, selectedAccountId));
    const error = useSelector(state => selectListError(state.scheduledShifts.lists, selectedAccountId));
    const records = useSelector(state => selectListRecords(state.scheduledShifts.lists, selectedAccountId));
    const shifts = useSelector(state => selectListRecords(state.shifts.lists, selectedAccountId));
    const isLoadingShifts = useSelector(state => selectListIsLoading(state.shifts.lists, selectedAccountId));
    const accountUsers = useSelector(state => selectListRecords(state.accountUsers.lists, selectedAccountId));
    const isLoadingAccountUsers = useSelector(state => selectListIsLoading(state.accountUsers.lists, selectedAccountId));
    const accounts = useSelector(state => state.orchestrator.accounts);

    //#endregion
    //#region useRefs

    const calendarRef = useRef(null);

    //#endregion
    //#region toggles

    const closeNewEntity = () => {
        setShowNewEntity(false);
        if (isStringEmpty(accountUserId)) {
            setSelectedAccountUserId(null);
        }
    };

    const closeEditEntity = () => {
        setShowEditEntity(false);
        if (isStringEmpty(accountUserId)) {
            setSelectedAccountUserId(null);
        }
    };

    const closeRemoveEntity = () => {
        setShowRemoveEntity(false);
        if (isStringEmpty(accountUserId)) {
            setSelectedAccountUserId(null);
        }
    };

    const toggleShowDetails = () => {
        setShowDetails(!showDetails);
    };

    //#endregion
    //#region fetch methods

    const getScheduledShifts = () => {
        if (isStringNotEmpty(selectedAccountId)) {
            let searchParams = {
                page: 1,
                size: 1000000,
                sort: 'startDateTime',
                order: 'asc',
                isDeleted: false,
                accountId: selectedAccountId
            };

            if (isListNotEmpty(selectedAccountUserIds)) {
                searchParams.accountUserIds = selectedAccountUserIds;
            } else if (isListNotEmpty(accountUsers) && isBooleanTrue(driversOnly)) {
                searchParams.accountUserIds = accountUsers.filter(i => i.isDriver === true).map(i => i.id);
            }

            if (isListNotEmpty(selectedShiftIds)) {
                searchParams.shiftId = selectedShiftIds;
            }

            dispatch(actionCreators.fetchScheduledShiftList(selectedAccountId, searchParams));
        }
    };

    //#endregion
    //#region calendar methods

    const handleDateSelect = (selectInfo) => {
        let calendarApi = selectInfo.view.calendar;

        calendarApi.unselect(); // clear date selection

        let selectedDateValue = moment(selectInfo.startStr).format('MM/DD/YYYY');
        setSelectedDate(selectedDateValue);

        setShowNewEntity(true);
    };

    const handleEventClick = (clickInfo) => {
        clickInfo.jsEvent.preventDefault(); // don't let the browser navigate
        setSelectedRecord(clickInfo.event.extendedProps);
        let selectedDateValue = moment(clickInfo.event.startStr).format('MM/DD/YYYY');
        setSelectedDate(selectedDateValue);
        setShowDetails(true);
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        dispatch(actionCreators.getAccounts());
    }, []);

    useMemo(() => {
        if (isStringNotEmpty(selectedAccountId)) {
            dispatch(actionCreators.fetchAccountUserList(selectedAccountId, { page: 1, size: 1000000, accountId: selectedAccountId, isDeleted: false }));
            dispatch(actionCreators.fetchShiftList(selectedAccountId, { page: 1, size: 1000000, accountId: selectedAccountId, isDeleted: false }));
        }
    }, [selectedAccountId]);

    useMemo(() => {
        if (isListNotEmpty(accounts)) {
            if (isStringNotEmpty(accountId)) {
                setSelectedAccountId(accountId);
                let account = accounts.find(i => i.id === accountId);
                setSelectedAccount(account);
            } else if (CanDo({ entityTypes: ['SHIPPER', 'CARRIER', 'PRODUCER', 'RECEIVER', 'BROKER', 'FACTORING'] }) && isStringNotEmpty(entityId)) {
                setSelectedAccountId(entityId);
                let account = accounts.find(i => i.id === entityId);
                setSelectedAccount(account);
            }
        } else {
            setSelectedAccount(null);
        }
    }, [accountId, entityType, entityId, accounts]);

    useMemo(() => {
        if (isStringNotEmpty(accountUserId)) {
            setSelectedAccountUserId(accountUserId);
            setSelectedAccountUserIds([accountUserId]);
        } else {
            setSelectedAccountUserIds([]);
        }
    }, [accountUserId]);

    useMemo(() => {
        if (isStringNotEmpty(shiftId)) {
            setSelectedShiftIds([shiftId]);
        } else {
            setSelectedShiftIds([]);
        }
    }, [shiftId]);

    useMemo(() => {
        // want to use isNotNullOrUndefined and not isListNotEmpty because we want to allow empty lists
        if (isStringNotEmpty(selectedAccountId) && isNotNullOrUndefined(selectedAccountUserIds) && isNotNullOrUndefined(selectedShiftIds)) {
            getScheduledShifts();
        }
    }, [selectedAccountId, selectedAccountUserIds, selectedShiftIds, driversOnly, accountUsers]);

    //#endregion
    //#region displays

    const newEntityComponents = (
        <Drawer
            title={"New " + singularEntityName}
            onClose={closeNewEntity}
            visible={showNewEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewScheduledShift
                cancel={closeNewEntity}
                accountId={selectedAccountId}
                selectedDate={selectedDate}
                selectedAccountUserId={isStringNotEmpty(selectedAccountUserId) ? selectedAccountUserId : null}
                selectedShiftId={isStringNotEmpty(shiftId) ? shiftId : null}
                accountUsers={accountUsers}
                shifts={shifts}
            />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName}
            onClose={closeEditEntity}
            visible={showEditEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditScheduledShift
                cancel={closeEditEntity}
                scheduledShift={selectedRecord}
                selectedDate={selectedDate}
                selectedAccountUserId={isStringNotEmpty(selectedAccountUserId) ? selectedAccountUserId : null}
                selectedShiftId={isStringNotEmpty(shiftId) ? shiftId : null}
                accountUsers={accountUsers}
                shifts={shifts}
                isRemove={false}
            />
        </Drawer>
    );

    const removeEntityComponents = (
        <Drawer
            title={"Remove Employee from " + singularEntityName}
            onClose={closeRemoveEntity}
            visible={showRemoveEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditScheduledShift
                cancel={closeRemoveEntity}
                scheduledShift={selectedRecord}
                selectedDate={selectedDate}
                selectedAccountUserId={isStringNotEmpty(selectedAccountUserId) ? selectedAccountUserId : null}
                selectedShiftId={isStringNotEmpty(shiftId) ? shiftId : null}
                accountUsers={accountUsers}
                shifts={shifts}
                isRemove={true}
            />
        </Drawer>
    );

    const getAccountUser = (acctUserId) => {
        if (isStringNotEmpty(acctUserId) && isListNotEmpty(accountUsers)) {
            let accountUser = accountUsers.find(i => i.id === acctUserId);
            if (isObjectNotEmpty(accountUser)) {
                return (
                    <div>{accountUser.firstName} {accountUser.lastName}{isStringNotEmpty(accountUser.title) ? ' (' + accountUser.title + ')' : ''}</div>
                );
            }
        }

        return null;
    };

    const eventDetailsComponents = (scheduledShift) => {
        //console.log(`scheduledShift: ${JSON.stringify(scheduledShift)}`);

        if (isObjectNotEmpty(scheduledShift)) {
            let shift = scheduledShift.shift;
            let title = isObjectNotEmpty(shift) && isStringNotEmpty(shift.name) ? shift.name : '';
            let time = `${isStringNotEmpty(selectedDate) ? selectedDate + ', ' : ''}${isObjectNotEmpty(shift) && isStringNotEmpty(shift.startTime) ? shift.startTime : ''} - ${isObjectNotEmpty(shift) && isStringNotEmpty(shift.endTime) ? shift.endTime : ''}`;

            let footerButtons = [
                <Button onClick={(e) => { toggleShowDetails(); }}>Close</Button>
            ];
            if (isStringNotEmpty(selectedAccountUserId)) {
                footerButtons.push(<Button type="primary" onClick={(e) => { toggleShowDetails(); setShowEditEntity(true); }}>Swap Shifts</Button>);
                footerButtons.push(<Button type="primary" onClick={(e) => { toggleShowDetails(); setShowRemoveEntity(true); }}>Remove</Button>);
            } else {
                footerButtons.push(<Button type="primary" onClick={(e) => { toggleShowDetails(); setShowEditEntity(true); }}>Edit</Button>);
            }

            return (
                <Modal
                    title={`${title}: ${time}`}
                    visible={showDetails}
                    onCancel={(e) => { toggleShowDetails(); }}
                    onOk={(e) => { toggleShowDetails(); }}
                    footer={footerButtons}
                >
                    <div>
                        <div>
                            <span><strong>{scheduledShift.title ? scheduledShift.title : ''}</strong></span>
                        </div>
                        {isListNotEmpty(scheduledShift.accountUsers) && isStringEmpty(selectedAccountUserId) ? scheduledShift.accountUsers.map((accountUser) => {
                            return (
                                <div>{accountUser.firstName} {accountUser.lastName}{isStringNotEmpty(accountUser.title) ? ' (' + accountUser.title + ')' : ''}</div>
                            );
                        }) : (isStringNotEmpty(selectedAccountUserId) ? (getAccountUser(selectedAccountUserId)) : null)}
                    </div>
                </Modal>
            );
        } else {
            return null;
        }
    };

    const renderEventContent = (eventInfo) => {
        let scheduledShift = isObjectNotEmpty(eventInfo) && isObjectNotEmpty(eventInfo.event) && isObjectNotEmpty(eventInfo.event.extendedProps) ? eventInfo.event.extendedProps : null;
        let shift = isObjectNotEmpty(scheduledShift) && isObjectNotEmpty(scheduledShift.shift) ? scheduledShift.shift : null;
        if (isObjectNotEmpty(scheduledShift) && isObjectNotEmpty(shift)) {
            return (
                <div style={{ backgroundColor: eventInfo.backgroundColor, color: eventInfo.textColor, width: '100%', whiteSpace: 'normal' }}>
                    <span style={{ whiteSpace: 'normal' }}><strong>{shift.startTime} - {shift.endTime}</strong>  <i>{shift.name}</i>  {isStringEmpty(accountUserId) ? <strong>({isNotNullOrUndefined(scheduledShift.accountUserIds) ? scheduledShift.accountUserIds.length : 0})</strong> : null}</span>

                    {/* {isListNotEmpty(scheduledShift.accountUsers) ? scheduledShift.accountUsers.map((accountUser) => {
                        return (
                            <div>{accountUser.firstName} {accountUser.lastName}{isStringNotEmpty(accountUser.title) ? ' (' + accountUser.title + ')' : ''}</div>
                        );
                    }) : null} */}
                </div>
            );
        } else {
            return null;
        }
    };

    //#endregion

    if (isNotNullOrUndefined(records)) {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true && error === null) || isLoadingAccountUsers === true || isLoadingShifts === true}>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Header
                            title="Calendar"
                            footer={
                                <div><span style={{ fontSize: 16, fontWeight: 500 }}>To setup a shift, click on a cell in the calendar to add a shift for that date{(accountUserId !== undefined && accountUserId !== null) ? '.' : ' and then choose which Employee(s) you want to assign to that shift.'}</span></div>
                            }
                        />
                    </Col>
                    <Col span={24}>
                        {(isStringEmpty(accountUserId) || isStringEmpty(shiftId) || (isStringEmpty(accountId) && CanDo({ staffOnly: true }))) ? (
                            <>
                                <Row>
                                    <Col span={24}>
                                        <span><strong>Filters: </strong></span>
                                    </Col>
                                </Row>
                                <Row>
                                    {(isStringEmpty(accountId) && CanDo({ staffOnly: true })) ? (
                                        <Col span={8}>
                                            <Select
                                                placeholder="Filter by Account"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onChange={(selected) => { setSelectedAccountId(selected); }}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Accounts for you to choose from." />}
                                            >
                                                {accounts.map(i => <Option value={i.id} key={i.id}>{i.name}</Option>)}
                                            </Select>
                                        </Col>
                                    ) : null}
                                    {isStringEmpty(shiftId) ? (
                                        <Col span={8}>
                                            <Select
                                                placeholder="Filter by Shift"
                                                mode="multiple"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onChange={(selected) => { setSelectedShiftIds(selected); }}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Shifts for you to choose from." />}
                                            >
                                                {shifts.map(i => <Option value={i.id} key={i.id}>{i.name}: {i.startTime} - {i.endTime}</Option>)}
                                            </Select>
                                        </Col>
                                    ) : null}
                                    {isStringEmpty(accountUserId) ? (
                                        <Col span={8}>
                                            <Select
                                                placeholder="Filter by Employee"
                                                mode="multiple"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onChange={(selected) => { setSelectedAccountUserIds(selected); }}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Employees for you to choose from." />}
                                            >
                                                {driversOnly === true ? accountUsers.filter(i => i.isDriver === true).map(i => <Option value={i.id} key={i.id}>{i.firstName} {i.lastName}{i.title ? ' (' + i.title + ')' : ''}</Option>) : accountUsers.map(i => <Option value={i.id} key={i.id}>{i.firstName} {i.lastName}{i.title ? ' (' + i.title + ')' : ''}</Option>)}
                                            </Select>
                                            {(CanDo({ entityTypes: ['CARRIER'] }) || (isObjectNotEmpty(selectedAccount) && isBooleanTrue(selectedAccount.entityTypes.includes("CARRIER")))) ? (
                                                <div>
                                                    <Checkbox
                                                        onChange={e => { setDriversOnly(e.target.checked); return e.target.checked; }}
                                                        checked={driversOnly}
                                                        name={'driversOnly'}
                                                    >Drivers Only</Checkbox>
                                                </div>
                                            ) : null}
                                        </Col>
                                    ) : null}
                                </Row>
                            </>
                        ) : null}
                    </Col>
                    <Col span={24}>
                        <FullCalendar
                            ref={calendarRef}
                            plugins={[dayGridPlugin, interactionPlugin]}
                            headerToolbar={{
                                left: 'today prev,next',
                                center: 'title',
                                right: 'dayGridMonth,dayGridWeek'
                            }}
                            views={{
                                dayGridMonth: { buttonText: 'month', slotDuration: { days: 1 }, eventContent: renderEventContent, select: handleDateSelect, eventClick: handleEventClick },
                                dayGridWeek: { buttonText: 'week', slotDuration: { days: 1 }, eventContent: renderEventContent, select: handleDateSelect, eventClick: handleEventClick },
                            }}
                            titleFormat={{ year: 'numeric', month: 'long', day: 'numeric' }}
                            eventTimeFormat={{ hour: '2-digit', minute: '2-digit', hour12: false }}
                            initialView='dayGridMonth'
                            editable={false}
                            selectable={true}
                            dayMaxEvents={false}
                            dayMaxEventRows={false}
                            nextDayThreshold={'06:00:00'}
                            weekends={true}
                            events={records}
                        />
                    </Col>
                </Row>
                {newEntityComponents}
                {editEntityComponents}
                {removeEntityComponents}
                {eventDetailsComponents(selectedRecord)}
            </Spin>
        );
    } else {
        return null;
    }
};

export default Calendar;