import React from 'react';
import { Button } from "antd";

const ActionButton = ({ iconPosition = 'left', buttonStyle = null, buttonIcon = null, buttonIcon2 = null, buttonText = null, buttonIconStyle = null, buttonTextStyle = null, buttonClassName = null, buttonIconClassName = null, buttonTextClassName = null, ...buttonProps }) => {
    return (
        <Button className={buttonClassName} style={buttonStyle} {...buttonProps}>
            {(iconPosition === 'left' || iconPosition !== 'right') ? (
                <div className={buttonIconClassName} style={buttonIconStyle}>
                    {buttonIcon}
                </div>
            ) : null}
            <div className={buttonTextClassName} style={buttonTextStyle}>
                {buttonText}
            </div>
            {(iconPosition === 'right') ? (
                <div className={buttonIconClassName} style={buttonIconStyle}>
                    {buttonIcon}
                </div>
            ) : null}
            {buttonIcon2 ? (
                <div className={buttonIconClassName} style={buttonIconStyle}>
                    {buttonIcon2}
                </div>
            ) : null}
        </Button>
    );
};

export default ActionButton;