import React, { useMemo, useRef, useState } from 'react';
import { FileDoneOutlined } from '@ant-design/icons';
import { Empty, Button, Drawer, Modal, Alert, Input, Select, Spin, Divider, message, Switch } from 'antd';
import classes from './EditLoadStatusApproved.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from "../../store/actions/index";
import { isListNotEmpty, isNotNullOrUndefined, isNullOrUndefined, isObjectNotEmpty, isStringEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { CanSee, CanDo } from '../../shared/entitlements/entitlements';
import Header from '../Header/Header';
import DocumentUtils from '../../api/utils/documentUtils';
import EditMissingDocument from '../EditMissingDocument/EditMissingDocument';
import LoadStopUtils from '../../api/utils/loadStopUtils';
import LoadUtils from '../../api/utils/loadUtils';
import FormItemDisplay from '../FormItemDisplay/FormItemDisplay';
import Fieldset from '../FormFieldset/FormFieldset';
import { FormProvider, useForm } from 'react-hook-form';
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import moment from 'moment';
import DataLinkRow from '../DataLinkRow/DataLinkRow';
import { selectListIsLoading, selectListRecords } from '../../store/utility';
import * as Data from '../../api/data';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import SignatureCanvas from "react-signature-canvas";
import InvoiceUtils from '../../api/utils/invoiceUtils';
import NewEmployee from '../NewEmployee/NewEmployee';
import FormItemFile from '../FormItemFile/FormItemFile';
import UploadFile from '../UploadFile/UploadFile';

const { Option } = Select;

const EditLoadStatusApproved = ({ load, stops, invoices, invoiceLineItems, loadId, shipperId, carrierId, cancel }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    //#endregion
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const methods2 = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            signedByName: '',
            signedByTitle: ''
        }
    });
    const methods3 = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useRefs

    const signatureArea = useRef();

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loads.isRecordUpdateLoading);
    const error = useSelector(state => state.loads.updateRecordError);
    const accounts = useSelector(state => state.orchestrator.accounts);
    const documentLists = useSelector(state => state.documents.lists);
    const isLoadingDocuments = useSelector(state => selectListIsLoading(state.documents.lists, loadId));
    const isAdmin = useSelector(state => state.auth.isAdmin);
    const entityType = useSelector(state => state.auth.entityType);
    const entityId = useSelector(state => state.auth.entityId);
    const userId = useSelector(state => state.auth.userId);
    const isLoadingAccountUsers = useSelector(state => selectListIsLoading(state.accountUsers.lists, `${shipperId}_EMPLOYEES`));
    const accountUsers = useSelector(state => selectListRecords(state.accountUsers.lists, `${shipperId}_EMPLOYEES`));

    //#endregion
    //#region useStates

    const [invoice, setInvoice] = useState({});
    const [showEditMissingDocument, setShowEditMissingDocument] = useState(false);
    const [selectedMissingDocument, setSelectedMissingDocument] = useState(null);
    const [priceConfirmationDocument, setPriceConfirmationDocument] = useState({});
    const [showNewEmployee, setShowNewEmployee] = useState(false);
    const [showSignatureModal, setShowSignatureModal] = useState(false);
    const [file, setFile] = useState(null);
    const [shipperAccount, setShipperAccount] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);

    //#endregion
    //#region toggles

    const toggleEditMissingDocument = () => {
        setShowEditMissingDocument(!showEditMissingDocument);
    };

    const toggleNewEmployee = () => {
        setShowNewEmployee(!showNewEmployee);
    };

    const toggleSignatureModal = () => {
        setShowSignatureModal(!showSignatureModal);
    };

    //#endregion
    //#region employee methods

    const getEmployees = () => {
        if (isStringNotEmpty(shipperId)) {
            let searchParams = {
                page: 1,
                size: 1000000,
                accountId: shipperId,
                'isDriver:or': false,
                'accountRoles:or': 'ADMIN|OPERATIONS_ADMIN|SAFETY_ADMIN|FINANCIAL_ADMIN|DISPATCHER|LOAD_PLANNER|CLERK|TRANSPORTATION_MANAGER',
                isDeleted: false
            };

            dispatch(actionCreators.fetchAccountUserList(`${shipperId}_EMPLOYEES`, searchParams));
        }
    };

    //#endregion
    //#region signature methods

    const resetSignaturePad = (e) => {
        e.preventDefault();
        signatureArea.current.clear();
    };

    const cancelSignaturePad = (e) => {
        e.preventDefault();
        methods.reset();
        signatureArea.current.clear();
        toggleSignatureModal();
    };

    //#endregion
    //#region onSubmit and onCancel

    const createNewInvoice = async (invoice) => {
        let newInvoice = null;
        if (isObjectNotEmpty(invoice)) {
            newInvoice = await Data.addInvoice(invoice);
            if (isObjectNotEmpty(newInvoice)) {
                if (isStringNotEmpty(newInvoice.fromEntityId)) {
                    dispatch(actionCreators.addInvoiceToInvoiceList(newInvoice.fromEntityId, newInvoice));
                }
                if (isStringNotEmpty(newInvoice.toEntityId)) {
                    dispatch(actionCreators.addInvoiceToInvoiceList(newInvoice.toEntityId, newInvoice));
                }
                if (isStringNotEmpty(newInvoice.loadId)) {
                    dispatch(actionCreators.addInvoiceToInvoiceList(newInvoice.loadId, newInvoice));
                }
            }
        }

        return newInvoice;
    };

    const updateExistingInvoice = async (existingInvoice, updatedPayload) => {
        let updatedInvoice = null;
        if (isObjectNotEmpty(existingInvoice)) {
            updatedInvoice = await Data.updateInvoice(existingInvoice.id, updatedPayload, existingInvoice);
            if (isObjectNotEmpty(updatedInvoice)) {
                if (isStringNotEmpty(updatedInvoice.fromEntityId)) {
                    dispatch(actionCreators.updateInvoiceInInvoiceList(updatedInvoice.fromEntityId, updatedInvoice));
                }
                if (isStringNotEmpty(updatedInvoice.toEntityId)) {
                    dispatch(actionCreators.updateInvoiceInInvoiceList(updatedInvoice.toEntityId, updatedInvoice));
                }
                if (isStringNotEmpty(updatedInvoice.loadId)) {
                    dispatch(actionCreators.updateInvoiceInInvoiceList(updatedInvoice.loadId, updatedInvoice));
                }
                dispatch(actionCreators.changeSingleInvoice(updatedInvoice));
            }
        }

        return updatedInvoice;
    };

    const onSubmitSignAndApprovePriceConfirmation = async (data) => {
        const url = signatureArea.current.getTrimmedCanvas().toDataURL("image/png");
        // console.log(url);
        if (isNotNullOrUndefined(url) && isStringNotEmpty(userId) && isObjectNotEmpty(data) && isObjectNotEmpty(invoice) && isObjectNotEmpty(load) && isStringNotEmpty(loadId)) {
            toggleSignatureModal();
            setIsProcessing(true);
            let invoiceToSend = null;
            // first check to see if price confirmation already exists and if so, delete it or update it
            if (isListNotEmpty(invoices)) {
                let existingInvoice = invoices.find(i => i.invoiceType === 'PRICE_CONFIRMATION' && i.loadId === loadId && i.isDeleted === false);
                if (isObjectNotEmpty(existingInvoice)) {
                    if (existingInvoice.status !== 'COMPLETED') {
                        // second, the price confirmation isn't signed yet so just update it
                        const { totalAmount, lineItems, invoiceLineItemList } = InvoiceUtils.getInvoiceLineItemsAndTotal("PRICE_CONFIRMATION", invoiceLineItems, "SHIPPER", "STAFF");

                        let updatedPriceConfirmation = {
                            amountDue: totalAmount,
                            amountDueUnit: 'USD',
                            invoiceLineItemIds: invoiceLineItemList.map(i => i.id),
                            approvedAt: moment(),
                            approvedBy: userId,
                            signature: url,
                            signedAt: moment(),
                            signedBy: userId,
                            signedByName: data.signedByName,
                            signedByTitle: data.signedByTitle,
                            status: 'COMPLETED'
                        };

                        invoiceToSend = await updateExistingInvoice(existingInvoice, updatedPriceConfirmation);
                    } else {
                        dispatch(actionCreators.updateInvoice(existingInvoice.id, { isDeleted: true }, existingInvoice));

                        let invoicePayload = {
                            ...invoice,
                            approvedAt: moment(),
                            approvedBy: userId,
                            signature: url,
                            signedAt: moment(),
                            signedBy: userId,
                            signedByName: data.signedByName,
                            signedByTitle: data.signedByTitle,
                            status: 'COMPLETED'
                        };
                        // second, create the price confirmation in the database if an existing one couldnt be updated
                        invoiceToSend = await createNewInvoice(invoicePayload);
                    }
                } else {
                    let invoicePayload = {
                        ...invoice,
                        approvedAt: moment(),
                        approvedBy: userId,
                        signature: url,
                        signedAt: moment(),
                        signedBy: userId,
                        signedByName: data.signedByName,
                        signedByTitle: data.signedByTitle,
                        status: 'COMPLETED'
                    };
                    // second, create the price confirmation in the database if an existing one couldnt be updated
                    invoiceToSend = await createNewInvoice(invoicePayload);
                }
            } else {
                let invoicePayload = {
                    ...invoice,
                    approvedAt: moment(),
                    approvedBy: userId,
                    signature: url,
                    signedAt: moment(),
                    signedBy: userId,
                    signedByName: data.signedByName,
                    signedByTitle: data.signedByTitle,
                    status: 'COMPLETED'
                };
                // second, create the price confirmation in the database if an existing one couldnt be updated
                invoiceToSend = await createNewInvoice(invoicePayload);
            }

            if (isObjectNotEmpty(invoiceToSend)) {
                dispatch(actionCreators.updatePriceConfirmation({ approvedPriceConfirmation: true }, loadId, entityType === 'SHIPPER'));
            }

            setIsProcessing(false);
        }
    };

    const onSubmitSendPriceConfirmation = async (data) => {
        if (isObjectNotEmpty(invoice) && isListNotEmpty(accountUsers) && isListNotEmpty(data.accountUserIds) && isObjectNotEmpty(load) && isStringNotEmpty(loadId)) {
            setIsProcessing(true);
            let invoiceToSend = null;
            // first check to see if price confirmation already exists and if so, delete it or update it
            if (isListNotEmpty(invoices)) {
                let existingInvoice = invoices.find(i => i.invoiceType === 'PRICE_CONFIRMATION' && i.loadId === loadId && i.isDeleted === false);
                if (isObjectNotEmpty(existingInvoice)) {
                    if (existingInvoice.status !== 'COMPLETED') {
                        // second, the price confirmation isn't signed yet so just update it
                        const { totalAmount, lineItems, invoiceLineItemList } = InvoiceUtils.getInvoiceLineItemsAndTotal("PRICE_CONFIRMATION", invoiceLineItems, "SHIPPER", "STAFF");

                        let updatedPriceConfirmation = {
                            invoiceDate: moment(),
                            status: 'SENT',
                            amountDue: totalAmount,
                            amountDueUnit: 'USD',
                            invoiceLineItemIds: invoiceLineItemList.map(i => i.id),
                            sentAt: moment(),
                            sentBy: userId
                        };

                        invoiceToSend = await updateExistingInvoice(existingInvoice, updatedPriceConfirmation);
                    } else {
                        dispatch(actionCreators.updateInvoice(existingInvoice.id, { isDeleted: true }, existingInvoice));

                        // second, create the price confirmation in the database if an existing one couldnt be updated
                        invoiceToSend = await createNewInvoice(invoice);
                    }
                } else {
                    // second, create the price confirmation in the database if an existing one couldnt be updated
                    invoiceToSend = await createNewInvoice(invoice);
                }
            } else {
                // second, create the price confirmation in the database if an existing one couldnt be updated
                invoiceToSend = await createNewInvoice(invoice);
            }

            if (isObjectNotEmpty(invoiceToSend)) {
                // third, save the pdf document to s3
                let newDocument = await InvoiceUtils.saveInvoiceAsDocument('PRICE_CONFIRMATION', invoiceToSend, invoiceToSend.invoiceLineItems, load, stops, true);

                // fourth, send price confirmation emails to recipients
                let emailsWereSent = true;
                for (let i = 0; i < data.accountUserIds.length; i++) {
                    const accountUserId = data.accountUserIds[i];
                    const accountUser = accountUsers.find(a => a.id === accountUserId);
                    if (isObjectNotEmpty(accountUser)) {
                        const emailWasSent = await InvoiceUtils.sendPriceConfirmationEmail(accountUser.email, accountUser.firstName + ' ' + accountUser.lastName, invoiceToSend, load, stops, newDocument);
                        if (emailWasSent === false) {
                            emailsWereSent = false;
                        }
                    }
                }

                if (emailsWereSent === true) {
                    message.success('Load Tender was sent to Shipper.');
                    onCancel();
                }
            }

            setIsProcessing(false);
        }
    };

    const onSubmitApproveLoad = (data) => {
        // validate to make sure file was attached
        if (isNullOrUndefined(file)) {
            methods3.setError("file", { type: "required", message: "Load Tender Document Must Be Attached." });
            return;
        } else {
            methods3.clearErrors("file");
        }

        if (isNotNullOrUndefined(file)) {
            data.file = file;
        }

        if (isListNotEmpty(invoiceLineItems)) {
            invoiceLineItems.filter(i => (i.fromEntityType === 'SHIPPER' || i.toEntityType === 'SHIPPER') && i.isDeleted === false && (i.approvalStatus === 'NEW' || i.approvalStatus === 'PENDING')).forEach((item) => {
                dispatch(actionCreators.updateInvoiceLineItem(item.id, { approvalStatus: 'APPROVED' }, loadId, false));
            });
        }
        dispatch(actionCreators.updatePriceConfirmation(data, load.id, CanDo({ entityTypes: ['SHIPPER'] })));

    };

    const onCancel = () => {
        dispatch(actionCreators.updateLoadCancel());
        cancel();
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLoadErrorClear());
        dispatch(actionCreators.getAccounts());
    }, []);

    useMemo(() => {
        if (isStringNotEmpty(loadId)) {
            let ids = [];
            ids.push(loadId);

            dispatch(actionCreators.fetchBulkDocumentLists(loadId, ids));
        }
    }, [loadId]);

    useMemo(() => {
        if (isStringNotEmpty(shipperId) && isListNotEmpty(accounts)) {
            let shipperAccountObj = accounts.find(i => i.id === load.shipperId);
            if (isObjectNotEmpty(shipperAccountObj)) {
                // console.log(shipperAccount);
                setShipperAccount({ ...shipperAccountObj });
            }
        }
    }, [accounts, shipperId]);

    useMemo(() => {
        if (isObjectNotEmpty(documentLists) && isStringNotEmpty(loadId)) {
            let loadDocuments = isObjectNotEmpty(documentLists[loadId]) && isNotNullOrUndefined(documentLists[loadId].records) ? [...documentLists[loadId].records] : null;
            let visibleLoadDocuments = DocumentUtils.getVisibleDocuments(loadDocuments, entityType, isAdmin);

            let priceConfirmationDocumentObj = visibleLoadDocuments.find(doc => doc.documentType === 'PRICE_CONFIRMATION' && doc.isDeleted === false);
            if (isObjectNotEmpty(priceConfirmationDocumentObj)) {
                setPriceConfirmationDocument(priceConfirmationDocumentObj);
            } else {
                setPriceConfirmationDocument({});
            }
        }
    }, [documentLists, loadId, entityType, isAdmin]);

    useMemo(() => {
        if (isStringNotEmpty(loadId) && isStringNotEmpty(shipperId) && isObjectNotEmpty(shipperAccount)) {
            const { totalAmount, lineItems, invoiceLineItemList } = InvoiceUtils.getInvoiceLineItemsAndTotal("PRICE_CONFIRMATION", invoiceLineItems, "SHIPPER", "STAFF");

            let priceConfirmation = {
                loadId: loadId,
                invoiceDate: moment(),
                fromEntityType: 'SHIPPER',
                fromEntityId: shipperId,
                fromName: shipperAccount.name,
                fromAddress: shipperAccount.accountsPayableAddress,
                fromPointOfContact: shipperAccount.accountsPayable,
                toEntityType: "STAFF",
                toEntityId: null,
                status: 'PENDING',
                amountDue: totalAmount,
                amountDueUnit: 'USD',
                invoiceLineItemIds: invoiceLineItemList.map(i => i.id),
                invoiceType: 'PRICE_CONFIRMATION'
            };

            setInvoice(priceConfirmation);
        }
    }, [loadId, shipperId, invoiceLineItems, shipperAccount]);

    useMemo(() => {
        if (isStringNotEmpty(shipperId)) {
            getEmployees();
        }
    }, [shipperId]);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    //#endregion
    //#region displays

    const editMissingDocumentComponents = (
        <Drawer
            title={isObjectNotEmpty(selectedMissingDocument) ? `Upload Missing ${selectedMissingDocument.documentTypeName}${selectedMissingDocument.entityType === 'STOP' && isObjectNotEmpty(selectedMissingDocument.entity) ? " for " + LoadStopUtils.getStopNameDisplay(selectedMissingDocument.entity, shipperId, carrierId, entityType, entityId) : ""}` : ""}
            onClose={toggleEditMissingDocument}
            visible={showEditMissingDocument === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditMissingDocument
                cancel={toggleEditMissingDocument}
                missingDocument={selectedMissingDocument}
                load={load}
                loadId={loadId}
            />
        </Drawer>
    );

    const priceConfirmationDocumentComponents = () => {
        if (isObjectNotEmpty(priceConfirmationDocument)) {
            return (
                <DataLinkRow title="Load Tender" fileId={priceConfirmationDocument.id} />
            );
        } else {
            return (
                <Button size="small" type="ghost" onClick={(e) => { setSelectedMissingDocument({ entityType: 'LOAD', entityId: load.id, entity: load, entitySubType: null, entityName: isStringNotEmpty(load.parentName) ? load.parentName : (isStringNotEmpty(load.name) ? load.name : load.irisId), documentType: 'PRICE_CONFIRMATION', documentTypeName: 'Load Tender', visibleTo: 'SHIPPER' }); toggleEditMissingDocument(); }}>Upload Load Tender</Button>
            );
        }
    };

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    //#endregion
    //#region signature displays

    const signaturePadComponents = (
        <Modal
            title={"Sign and Approve Load"}
            visible={showSignatureModal === true}
            width="550px"
            style={{ top: 0 }}
            onCancel={(e) => { cancelSignaturePad(); }}
            footer={null}
            zIndex={1000}
            closable={false}
            destroyOnClose={true}
            maskClosable={false}
        >
            <SignatureCanvas ref={(ref) => { signatureArea.current = ref; }} penColor="black" canvasProps={{ width: 500, height: 200, className: "sigCanvas", style: { border: '1px solid black' } }} />
            <FormProvider {...methods2}>
                <Form onSubmit={methods2.handleSubmit(onSubmitSignAndApprovePriceConfirmation)}>
                    <Fieldset legend="Your Full Legal Name and Title">
                        <FormItem {...formItemLayout} label="Your Full Legal Name" required format="vertical"
                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Your Full Legal Name" ref={ref} />}
                            rules={{ required: 'Required Field' }}
                            name="signedByName"
                            defaultValue={''}
                        />
                        <FormItem {...formItemLayout} label="Title" required format="vertical"
                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Title" ref={ref} />}
                            rules={{ required: 'Required Field' }}
                            name="signedByTitle"
                            defaultValue={''}
                        />
                    </Fieldset>
                    <div className={classes.signatureButtonContainer}>
                        <Button key="cancel" type="default" onClick={(e) => { cancelSignaturePad(e); }} style={{ marginRight: 8 }}>
                            Cancel
                        </Button>
                        <Button key="reset" type="default" onClick={(e) => { resetSignaturePad(e); }} style={{ marginRight: 8 }}>
                            Reset
                        </Button>
                        <Button key="sign" type="primary" htmlType="submit">
                            Sign and Approve Load
                        </Button>
                    </div>
                </Form>
            </FormProvider>
        </Modal>
    );

    //#endregion

    if (isObjectNotEmpty(load) && LoadUtils.loadStatusIs(load.loadStatus, ['CREATED', 'PENDING'])) {
        return (
            <>
                <CanSee entityModel="LOAD" entityObject={load} entityTypes={['SHIPPER']}>
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isProcessing === true || isLoadingDocuments === true || (isLoading === true && error === null)}>
                        <Fieldset legend="Approve Load">
                            <div style={{ marginBottom: 24 }}>
                                <b>Please review the Load Tender below. If you have a Load Tender for us, you can upload it below as well. If everything is correct, please click the Sign and Approve Load button to submit the Load Tender and Approve the Load.</b>
                            </div>

                            <FormItemDisplay {...formItemLayout} label="Do you have a Load Tender for us?" noColon format="vertical">
                                {priceConfirmationDocumentComponents()}
                            </FormItemDisplay>

                            <PDFDownloadLink document={<InvoiceUtils.PriceConfirmationTemplate invoice={invoice} load={load} stops={stops} invoiceLineItems={invoiceLineItems} displayManualInstructions={true} />} fileName={`Load Tender for Load ${load.irisId}.pdf`}>
                                {({ blob, url, loading, error }) => (loading ? (<div style={{ textAlign: 'center' }}>Loading Load Tender...</div>) : (
                                    <>
                                        <PDFViewer style={{ width: '100%', height: '80vh' }} src={url}></PDFViewer>
                                        <div style={{ textAlign: 'center', marginTop: 12 }}>
                                            <Button style={{ height: 30, width: 300 }} type="default">Download Copy of Load Tender</Button>
                                        </div>
                                    </>
                                ))}
                            </PDFDownloadLink>
                        </Fieldset>
                        {error && <Alert message={`${error}`} type="error" />}
                    </Spin>
                    <div className={classes.buttonContainer}>
                        <Button type="danger" onClick={cancel} style={{ marginRight: 8 }} disabled={isProcessing === true || (isLoading === true && error === null)}>Cancel</Button>
                        <Button type="primary" onClick={toggleSignatureModal} disabled={isProcessing === true || (isLoading === true && error === null)}>Sign and Approve Load</Button>
                    </div>

                    {signaturePadComponents}
                </CanSee>
                <CanSee staffOnly={true}>
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isProcessing === true || (isLoading === true && error === null)}>
                        <div style={{ minHeight: 200, padding: 24, backgroundColor: '#fafafa', border: '1px dashed #e9e9e9', borderRadius: '2px' }}>
                            <Header
                                title="Load Approval"
                                titleStyle={{ paddingLeft: 0, color: '#000000', fontWeight: 500 }}
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                                icon={<FileDoneOutlined />}
                                iconStyle={{ background: 'transparent' }}
                                title2={`Please review the Load Tender below. If everything is correct, you can send the Load Tender to the Shipper or Accept the Load on the Shipper's behalf.`}
                                title2Style={{ whiteSpace: 'normal' }}
                            />

                            <PDFDownloadLink document={<InvoiceUtils.PriceConfirmationTemplate invoice={invoice} load={load} stops={stops} invoiceLineItems={invoiceLineItems} displayManualInstructions={true} />} fileName={`Load Tender for Load ${load.irisId}.pdf`}>
                                {({ blob, url, loading, error }) => (loading ? (<div style={{ textAlign: 'center' }}>Loading Load Tender...</div>) : (
                                    <>
                                        <PDFViewer style={{ width: '100%', height: '80vh' }} src={url}></PDFViewer>
                                        <div style={{ textAlign: 'center', marginTop: 12 }}>
                                            <Button style={{ height: 30, width: 300 }} type="default">Download Copy of Load Tender</Button>
                                        </div>
                                    </>
                                ))}
                            </PDFDownloadLink>
                        </div>
                        <div style={{ minHeight: 200, padding: 24, marginTop: 24, marginBottom: 24, backgroundColor: '#fafafa', border: '1px dashed #e9e9e9', borderRadius: '2px' }}>
                            <FormProvider {...methods}>
                                <Form onSubmit={methods.handleSubmit(onSubmitSendPriceConfirmation)}>
                                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoadingAccountUsers === true}>
                                        <Fieldset legend="Would you like the send the Load Tender to the Shipper you have assigned to this Load?">
                                            <FormItem {...formItemLayout} label="Choose Employee(s) to send Load Tender to" required
                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                    <Select
                                                        placeholder="Please Search and Select Employee(s)"
                                                        mode="multiple"
                                                        allowClear={true}
                                                        style={{ width: '100%' }}
                                                        virtual={false}
                                                        loading={isLoadingAccountUsers === true}
                                                        onBlur={onBlur}
                                                        onChange={(selected) => { onChange(selected); }}
                                                        value={value}
                                                        name={name}
                                                        showSearch={true}
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Employees for you to choose from." />}
                                                        ref={ref}
                                                    >
                                                        {accountUsers.map(d => <Option value={d.id} key={d.id}>{d.firstName + ' ' + d.lastName} - Title: {d.title} - Email: {d.email}</Option>)}
                                                    </Select>
                                                )}
                                                rules={{ required: 'Required Field' }}
                                                name="accountUserIds"
                                            />
                                            <br />
                                            <b>Employee Doesn't Exist Yet?</b>
                                            <br />
                                            <Button type='primary' onClick={toggleNewEmployee}>Add New Employee to Shipper Account</Button>
                                        </Fieldset>
                                    </Spin>
                                    <div className={classes.relativeButtonContainer}>
                                        <Button type="danger" onClick={onCancel} disabled={isProcessing === true || (isLoading === true && error === null)} style={{ marginRight: 8 }}>Cancel</Button>
                                        <Button type="primary" htmlType="submit" disabled={isProcessing === true || (isLoading === true && error === null)}>Send the Load Tender</Button>
                                    </div>
                                </Form>
                            </FormProvider>
                        </div>
                        <Divider>OR Approve the Load on Behalf of the Shipper</Divider>
                        <div style={{ minHeight: 200, padding: 24, marginTop: 24, backgroundColor: '#fafafa', border: '1px dashed #e9e9e9', borderRadius: '2px' }}>
                            <FormProvider {...methods3}>
                                <Form onSubmit={methods3.handleSubmit(onSubmitApproveLoad)}>
                                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isProcessing === true || (isLoading === true && error === null)}>
                                        <Fieldset legend="Shipper Approved Load">
                                            <FormItemFile {...formItemLayout} label="Load Tender" name="file" required>
                                                <UploadFile
                                                    beforeUpload={(file) => {
                                                        setFile(file);
                                                        methods3.clearErrors("file");
                                                        return false;
                                                    }}
                                                    onRemove={(file) => {
                                                        setFile(null);
                                                    }}
                                                    message="Please upload a copy of the shipper load tender."
                                                />
                                            </FormItemFile>
                                            <FormItem {...formItemLayout} label="Shipper Approved the Load" required
                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                    <Switch
                                                        checkedChildren="Yes"
                                                        unCheckedChildren="No"
                                                        onBlur={onBlur}
                                                        onChange={(checked, event) => { onChange(checked); }}
                                                        checked={value}
                                                        name={name}
                                                        ref={ref}
                                                    />
                                                )}
                                                rules={{
                                                    validate: {
                                                        mustBeTrue: checked => checked === true || 'Load must be Approved' // value must be true
                                                    }
                                                }}
                                                name="approvedPriceConfirmation"
                                                defaultValue={false}
                                            />
                                        </Fieldset>
                                        {error && <Alert message={`${error}`} type="error" />}
                                    </Spin>
                                    <div className={classes.relativeButtonContainer}>
                                        <Button type="danger" onClick={onCancel} disabled={isProcessing === true || (isLoading === true && error === null)} style={{ marginRight: 8 }}>Cancel</Button>
                                        <Button type="primary" htmlType="submit" disabled={isProcessing === true || (isLoading === true && error === null)}>Approve Load on Behalf of Shipper</Button>
                                    </div>
                                </Form>
                            </FormProvider>
                        </div>
                    </Spin>
                    <Drawer
                        title={'Add New Employee'}
                        width={fullWidth > 720 ? fullWidth / 2 : 360}
                        onClose={toggleNewEmployee}
                        visible={showNewEmployee}
                        bodyStyle={{ paddingBottom: 80 }}
                        style={{ zIndex: 1000 }}
                        destroyOnClose={true}
                        closable={false}
                        maskClosable={false}
                    >
                        <NewEmployee cancel={toggleNewEmployee} accountId={shipperId} />
                    </Drawer>
                </CanSee>
                {editMissingDocumentComponents}
            </>
        );
    } else {
        return null;
    }
};

export default EditLoadStatusApproved;