import React, { useMemo } from 'react';
import { Alert, Switch, Input, Select, Spin, InputNumber } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Enums from '../../shared/enums';
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import FormButtons from '../FormButtons/FormButtons';
import { isObjectNotEmpty } from '../../shared/objectUtils';

const { TextArea } = Input;

const EditClaim = ({ cancel, claim }) => {
    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.claims.isRecordUpdateLoading);
    const error = useSelector(state => state.claims.updateRecordError);

    const currencyOptions = Enums.Currencies.selectListOptions();
    const bolStatusOptions = Enums.BOLStatuses.selectListOptions();
    const claimStatusOptions = Enums.ClaimStatuses.selectListOptions();
    const unitPackagingOptions = Enums.UnitPackaging.selectListOptions();

    const onSubmit = (data) => {
        let payload = {};
        let objectToUpdate = { ...claim };
        let changeType = null;
        for (let [key, value] of Object.entries(data)) {
            let oldValue = objectToUpdate[key] !== undefined && objectToUpdate[key] !== null ? objectToUpdate[key] : '';
            if (value !== oldValue) {
                // pass in a change type to get UI's to refresh based on changes to the claim
                if (key === 'claimStatus') {
                    if (value === 'CLOSED') {
                        changeType = 'LOAD_CLAIM_CLOSED';
                    } else if (value === 'PENDING_USDA_INSPECTION') {
                        changeType = 'LOAD_CLAIM_INSPECTION';
                    } else if (value === 'REOPENED') {
                        changeType = 'LOAD_CLAIM_REOPENDED';
                    }
                } else if (key === 'adjustment' && changeType === null) {
                    changeType = 'LOAD_CLAIM_ADJUSTMENT';
                }

                //console.log(`${key}: new value - ${value}, old value - ${oldValue}`);
                payload[key] = value;
            }
        }

        // shouldn't be able to edit loadId - the load that is associated with a claim should not be allowed to be changed to a different load
        if (isObjectNotEmpty(payload)) {
            dispatch(actionCreators.updateClaim(claim.id, payload, changeType, claim.loadId));
        } else {
            onCancel();
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.updateClaimCancel());
        cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateClaimErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const formItemLayoutDouble = {
        labelCol1: { span: 8 },
        wrapperCol1: { span: 8 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 8 },
    };
    const formItemLayoutDouble2 = {
        labelCol1: { span: 8 },
        wrapperCol1: { span: 4 },
        labelCol2: { span: 6 },
        wrapperCol2: { span: 6 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <FormItem {...formItemLayout} label="Is Against Carrier" format="horizontal"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                onBlur={onBlur}
                                onChange={(checked, event) => { onChange(checked); }}
                                checked={value}
                                name={name}
                                ref={ref}
                            />
                        )}
                        name="isAgainstCarrier"
                        defaultValue={claim.isAgainstCarrier}
                    />
                    <FormItem {...formItemLayout} label="Is Against Shipper" format="horizontal"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                onBlur={onBlur}
                                onChange={(checked, event) => { onChange(checked); }}
                                checked={value}
                                name={name}
                                ref={ref}
                            />
                        )}
                        name="isAgainstShipper"
                        defaultValue={claim.isAgainstShipper}
                    />
                    <FormItem {...formItemLayout} label="Will be claimed against Insurance" format="horizontal"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                onBlur={onBlur}
                                onChange={(checked, event) => { onChange(checked); }}
                                checked={value}
                                name={name}
                                ref={ref}
                            />
                        )}
                        name="isInsuranceClaim"
                        defaultValue={claim.isInsuranceClaim}
                    />
                    <FormItem {...formItemLayout} label="Needs Fed One Inspection" format="horizontal"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                onBlur={onBlur}
                                onChange={(checked, event) => { onChange(checked); }}
                                checked={value}
                                name={name}
                                ref={ref}
                            />
                        )}
                        name="isFedOneInspection"
                        defaultValue={claim.isFedOneInspection}
                    />
                    <FormItem {...formItemLayout} label="Comments" format="horizontal"
                        render={({ onChange, onBlur, value, name, ref }) => <TextArea onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} autoSize={{ minRows: 4 }} ref={ref} />}
                        rules={{ required: false }}
                        name="comments"
                        defaultValue={claim.comments}
                    />
                    <FormItemDouble {...formItemLayoutDouble2} label1="Count" label2="Unit of Measure/Packaging" required1 required2 format="horizontal"
                        render1={({ onChange, onBlur, value, name, ref }) => (
                            <InputNumber
                                min={0}
                                placeholder="Count"
                                style={{ width: '100%' }}
                                onBlur={onBlur}
                                onChange={e => { onChange(e); }}
                                value={value}
                                name={name}
                                ref={ref}
                            />
                        )}
                        rules1={{ required: 'Required Field' }}
                        name1="count"
                        defaultValue1={claim.count}
                        render2={({ onChange, onBlur, value, name, ref }) => (
                            <Select
                                placeholder="Please Select a Unit of Measure/Unit Packaging"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                                ref={ref}
                            >
                                {unitPackagingOptions}
                            </Select>
                        )}
                        rules2={{ required: "Required Field" }}
                        defaultValue2={claim.unitPackaging}
                        name2="unitPackaging"
                    />
                    <FormItem {...formItemLayout} label="BOL Status" required format="horizontal"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Select
                                placeholder="Please Select the BOL Status"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                                ref={ref}
                            >
                                {bolStatusOptions}
                            </Select>
                        )}
                        rules={{ required: 'Required Field' }}
                        defaultValue={claim.bolStatus}
                        name="bolStatus"
                    />
                    <FormItem {...formItemLayout} label="Claim Status" format="horizontal"
                        render={({ onChange, onBlur, value, name, ref }) => (
                            <Select
                                placeholder="Please Select a Claim Status"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                                ref={ref}
                            >
                                {claimStatusOptions}
                            </Select>
                        )}
                        rules={{ required: false }}
                        defaultValue={claim.claimStatus}
                        name="claimStatus"
                    />
                    <FormItemDouble {...formItemLayoutDouble} label1="Adjustment Amount" required1 required2 format="horizontal"
                        render1={({ onChange, onBlur, value, name, ref }) => (
                            <InputNumber
                                min={0}
                                precision={2}
                                placeholder="Adjustment Amount"
                                style={{ width: '100%' }}
                                onBlur={onBlur}
                                onChange={e => { onChange(e); }}
                                value={value}
                                name={name}
                                ref={ref}
                            />
                        )}
                        rules1={{ required: "Required Field" }}
                        name1="adjustment"
                        defaultValue1={claim.adjustment}
                        render2={({ onChange, onBlur, value, name, ref }) => (
                            <Select
                                placeholder="Please Select"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                                ref={ref}
                            >
                                {currencyOptions}
                            </Select>
                        )}
                        rules2={{ required: 'Required Field' }}
                        defaultValue2={claim.adjustmentUnit}
                        name2="adjustmentUnit"
                    />
                    <FormItem {...formItemLayout} label="BOL #" format="horizontal"
                        render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="BOL #" ref={ref} />}
                        rules={{ required: false }}
                        name="bolNumber"
                        defaultValue={claim.bolNumber}
                    />
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Update Claim" />
            </Form>
        </FormProvider>
    );
};

export default EditClaim;