import * as actionTypes from "../actions/actionTypes";
import {
    addRecordFail,
    addRecordStart,
    addRecordSuccess,
    clearAddRecordError,
    clearAddRecordLoading,
    fetchRecordsSuccess,
    fetchRecordsFail,
    fetchRecordsStart,
    clearRecords,
    updateRecordFail,
    updateRecordStart,
    updateRecordSuccess,
    clearUpdateRecordError,
    clearUpdateRecordLoading,
    updateRecordStatusFail,
    updateRecordStatusStart,
    updateRecordStatusSuccess,
    clearUpdateRecordStatusError,
    clearUpdateRecordStatusLoading,
    fetchRecordSuccess,
    fetchRecordFail,
    fetchRecordStart,
    clearRecord,
    updateSingleRecord,
    updateRecord,
    cancelAddRecord,
    cancelUpdateRecord,
    cancelUpdateRecordStatus,
    addRecordToBottom,
    removeRecord,
    updateObject,
    fetchListStart,
    fetchListSuccess,
    fetchListFail,
    clearList,
    clearAllLists,
    addToBottomOfList,
    updateInList,
    removeFromList,
} from "../utility";

const fetchBundledLoadsStart = (state) => {
    return updateObject(state, {
        isBundledLoadsLoading: true,
        bundledLoadsError: null
    });
};

const fetchBundledLoadsSuccess = (state, action) => {
    return updateObject(state, {
        ...action,
        isBundledLoadsLoading: false,
        bundledLoadsError: null
    });
};

const fetchBundledLoadsFail = (state, action) => {
    return updateObject(state, {
        bundledLoadsError: action.error,
        isBundledLoadsLoading: false
    });
};

const clearBundledLoads = (state) => {
    return updateObject(state, {
        bundledLoads: [],
        isBundledLoadsLoading: false,
        bundledLoadsError: null
    });
};

const updateBundledLoad = (state, updatedRecord) => {
    const copyOfState = { ...state };
    let existingList = [...copyOfState.bundledLoads];
    const index = existingList.findIndex(obj => obj.id === updatedRecord.id);

    // only update if the record exists in the list
    if (index !== -1) {
        const updatedRecords = [
            ...existingList.slice(0, index), // everything before current obj
            updatedRecord,
            ...existingList.slice(index + 1), // everything after current obj
        ];

        return updateObject(state, {
            bundledLoads: updatedRecords
        });
    } else {
        return state;
    }
};

const initialState = {
    lists: {},
    records: [],
    record: null,
    isLoading: false,
    isRecordLoading: false,
    isRecordAddLoading: null,
    isRecordUpdateLoading: null,
    isRecordStatusUpdateLoading: null,
    error: null,
    recordError: null,
    addRecordError: null,
    updateRecordError: null,
    updateRecordStatusError: null,
    searchParams: {},
    pagination: {},
    addRecordId: null,
    bundledLoads: [],
    isBundledLoadsLoading: false,
    bundledLoadsError: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_LOAD_LIST_START: return fetchListStart(state, action.payload);
        case actionTypes.FETCH_LOAD_LIST_SUCCESS: return fetchListSuccess(state, action.payload);
        case actionTypes.FETCH_LOAD_LIST_FAIL: return fetchListFail(state, action.payload);
        case actionTypes.CLEAR_LOAD_LIST: return clearList(state, action.payload);
        case actionTypes.CLEAR_LOAD_LISTS: return clearAllLists(state);
        case actionTypes.ADD_TO_LOAD_LIST: return addToBottomOfList(state, action.payload);
        case actionTypes.UPDATE_IN_LOAD_LIST: return updateInList(state, action.payload);
        case actionTypes.REMOVE_FROM_LOAD_LIST: return removeFromList(state, action.payload);
        case actionTypes.FETCH_LOAD_START: return fetchRecordStart(state);
        case actionTypes.FETCH_LOAD_SUCCESS: return fetchRecordSuccess(state, action.payload);
        case actionTypes.FETCH_LOAD_FAIL: return fetchRecordFail(state, action.payload);
        case actionTypes.CLEAR_LOAD: return clearRecord(state);
        case actionTypes.FETCH_LOADS_START: return fetchRecordsStart(state);
        case actionTypes.FETCH_LOADS_SUCCESS: return fetchRecordsSuccess(state, action.payload);
        case actionTypes.FETCH_LOADS_FAIL: return fetchRecordsFail(state, action.payload);
        case actionTypes.CLEAR_LOADS: return clearRecords(state);
        case actionTypes.FETCH_BUNDLED_LOADS_START: return fetchBundledLoadsStart(state);
        case actionTypes.FETCH_BUNDLED_LOADS_SUCCESS: return fetchBundledLoadsSuccess(state, action.payload);
        case actionTypes.FETCH_BUNDLED_LOADS_FAIL: return fetchBundledLoadsFail(state, action.payload);
        case actionTypes.CLEAR_BUNDLED_LOADS: return clearBundledLoads(state);
        case actionTypes.ADD_LOAD: return addRecordToBottom(state, action.payload);
        case actionTypes.ADD_LOAD_START: return addRecordStart(state);
        case actionTypes.ADD_LOAD_SUCCESS: return addRecordSuccess(state);
        case actionTypes.ADD_LOAD_FAIL: return addRecordFail(state, action.payload);
        case actionTypes.ADD_LOAD_LOADING_CLEAR: return clearAddRecordLoading(state);
        case actionTypes.ADD_LOAD_ERROR_CLEAR: return clearAddRecordError(state);
        case actionTypes.ADD_LOAD_CANCEL: return cancelAddRecord(state);
        case actionTypes.UPDATE_LOAD: return updateRecord(state, action.payload);
        case actionTypes.UPDATE_SINGLE_LOAD: return updateSingleRecord(state, action.payload);
        case actionTypes.UPDATE_BUNDLED_LOAD: return updateBundledLoad(state, action.payload);
        case actionTypes.UPDATE_LOAD_START: return updateRecordStart(state);
        case actionTypes.UPDATE_LOAD_SUCCESS: return updateRecordSuccess(state);
        case actionTypes.UPDATE_LOAD_FAIL: return updateRecordFail(state, action.payload);
        case actionTypes.UPDATE_LOAD_LOADING_CLEAR: return clearUpdateRecordLoading(state);
        case actionTypes.UPDATE_LOAD_ERROR_CLEAR: return clearUpdateRecordError(state);
        case actionTypes.UPDATE_LOAD_CANCEL: return cancelUpdateRecord(state);
        case actionTypes.UPDATE_LOAD_STATUS_START: return updateRecordStatusStart(state);
        case actionTypes.UPDATE_LOAD_STATUS_SUCCESS: return updateRecordStatusSuccess(state);
        case actionTypes.UPDATE_LOAD_STATUS_FAIL: return updateRecordStatusFail(state, action.payload);
        case actionTypes.UPDATE_LOAD_STATUS_LOADING_CLEAR: return clearUpdateRecordStatusLoading(state);
        case actionTypes.UPDATE_LOAD_STATUS_ERROR_CLEAR: return clearUpdateRecordStatusError(state);
        case actionTypes.UPDATE_LOAD_STATUS_CANCEL: return cancelUpdateRecordStatus(state);
        case actionTypes.REMOVE_LOAD: return removeRecord(state, action.payload);
        default:
            return state;
    }
};

export default reducer;