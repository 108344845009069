import { orderBy } from "lodash";
import Enums from "../../shared/enums";
import { isBooleanFalse, isBooleanTrue, isListNotEmpty, isNotNullOrUndefined, isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";

const reviewableDocumentTypes = ['BOL', 'LUMPER_FEE_RECEIPT', 'FUEL_RECEIPT', 'TOLL_RECEIPT', 'RECEIPT', 'OTHER', 'MSHA_LICENSE_FRONT',
    'MSHA_LICENSE_BACK', 'EXPLOSIVE_CERTIFIED_LICENSE_FRONT', 'EXPLOSIVE_CERTIFIED_LICENSE_BACK', 'MC_CERTIFICATE_LICENSE_FRONT', 'MC_CERTIFICATE_LICENSE_BACK',
    'PNEUMATIC_LICENSE_FRONT', 'PNEUMATIC_LICENSE_BACK', 'COMMERCIAL_DRIVERS_LICENSE_FRONT', 'COMMERCIAL_DRIVERS_LICENSE_BACK', 'DRIVERS_LICENSE_FRONT',
    'DRIVERS_LICENSE_BACK', 'HAZMAT_LICENSE_FRONT', 'HAZMAT_LICENSE_BACK', 'SHIPPER_W9', 'SHIPPER_INSURANCE', 'CARRIER_W9', 'CARRIER_INSURANCE',
    'BROKER_W9', 'BROKER_INSURANCE', 'COBROKER_AGREEMENT', 'FACTORING_W9', 'FACTORING_INSURANCE',
    'PRODUCER_W9', 'PRODUCER_INSURANCE', 'RECEIVER_W9', 'RECEIVER_INSURANCE', 'CARRIER_WASHOUT_LOG', 'RATE_CONFIRMATION', 'PRICE_CONFIRMATION', 'ACCOUNT_LOGO', 'CLAIM',
    'PRICE_CONFIRMATION', 'RATE_CONFIRMATION', 'SHIPPER_INVOICE', 'CARRIER_INVOICE', 'FACTORING_INVOICE', 'FACTORING_NOA', 'BANK_INFORMATION'
];

const calculateCounts = (documents, entityId, entityType, entitySubType, account = null, needsCarrierWashoutLog = false, needsLumperFeeReceipt = false) => {
    //console.log(`documents: ${JSON.stringify(documents)}`);
    let pendingApprovalDocumentsList = [];
    let missingDocumentsList = [];
    let missingDocuments = [];
    let expiringSoonDocumentsList = [];
    let expiredDocumentsList = [];
    if (isStringNotEmpty(entityType) && isNotNullOrUndefined(documents)) {
        if (entityType === 'DRIVER') {
            documents.forEach((doc) => {
                if (isBooleanFalse(doc.isReviewed) && isBooleanFalse(doc.isExpired) && isBooleanFalse(doc.isDeleted) && reviewableDocumentTypes.includes(doc.documentType)) {
                    if (Enums.DriverDocumentTypes.doesNameExist(doc.documentType)) {
                        pendingApprovalDocumentsList.push(doc);
                    }
                }
            });

            // check for missing documents that are required for drivers
            // if (documents.find((d) => d.documentType === 'COMMERCIAL_DRIVERS_LICENSE_FRONT' && d.isDeleted === false && d.isExpired === false) === undefined) {
            //     missingDocumentsList.push(Enums.DocumentTypes.getValueByName('COMMERCIAL_DRIVERS_LICENSE_FRONT'));
            //     missingDocuments.push({ documentType: 'COMMERCIAL_DRIVERS_LICENSE_FRONT', name: Enums.DocumentTypes.getValueByName('COMMERCIAL_DRIVERS_LICENSE_FRONT') });
            // }
            // if (documents.find((d) => d.documentType === 'COMMERCIAL_DRIVERS_LICENSE_BACK' && d.isDeleted === false && d.isExpired === false) === undefined) {
            //     missingDocumentsList.push(Enums.DocumentTypes.getValueByName('COMMERCIAL_DRIVERS_LICENSE_BACK'));
            //     missingDocuments.push({ documentType: 'COMMERCIAL_DRIVERS_LICENSE_BACK', name: Enums.DocumentTypes.getValueByName('COMMERCIAL_DRIVERS_LICENSE_BACK') });
            // }
        } else if (entityType === 'ACCOUNT') {
            if (isObjectNotEmpty(account)) {
                if (account.entityTypes.includes("SHIPPER") === true) {
                    documents.forEach((doc) => {
                        if (isBooleanFalse(doc.isReviewed) && isBooleanFalse(doc.isExpired) && isBooleanFalse(doc.isDeleted) && reviewableDocumentTypes.includes(doc.documentType)) {
                            if (Enums.ShipperDocumentTypes.doesNameExist(doc.documentType)) {
                                pendingApprovalDocumentsList.push(doc);
                            }
                        }
                    });

                    // check for missing documents that are required for shipper
                    // if (documents.find((d) => d.documentType === 'SHIPPER_W9' && d.isDeleted === false && d.isExpired === false) === undefined) {
                    //     missingDocumentsList.push(Enums.DocumentTypes.getValueByName('SHIPPER_W9'));
                    //     missingDocuments.push({ documentType: 'SHIPPER_W9', name: Enums.DocumentTypes.getValueByName('SHIPPER_W9') });
                    // }
                    // if (documents.find((d) => d.documentType === 'SHIPPER_INSURANCE' && d.isDeleted === false && d.isExpired === false) === undefined) {
                    //     missingDocumentsList.push(Enums.DocumentTypes.getValueByName('SHIPPER_INSURANCE'));
                    //     missingDocuments.push({ documentType: 'SHIPPER_INSURANCE', name: Enums.DocumentTypes.getValueByName('SHIPPER_INSURANCE') });
                    // }
                }

                if (account.entityTypes.includes("CARRIER") === true) {
                    documents.forEach((doc) => {
                        if (isBooleanFalse(doc.isReviewed) && isBooleanFalse(doc.isExpired) && isBooleanFalse(doc.isDeleted) && reviewableDocumentTypes.includes(doc.documentType)) {
                            if (Enums.CarrierDocumentTypes.doesNameExist(doc.documentType)) {
                                pendingApprovalDocumentsList.push(doc);
                            }
                        }
                    });

                    // check for missing documents that are required for carrier
                    if (documents.find((d) => d.documentType === 'CARRIER_W9' && d.isDeleted === false && d.isExpired === false) === undefined) {
                        missingDocumentsList.push(Enums.DocumentTypes.getValueByName('CARRIER_W9'));
                        missingDocuments.push({ documentType: 'CARRIER_W9', name: Enums.DocumentTypes.getValueByName('CARRIER_W9') });
                    }
                    if (documents.find((d) => d.documentType === 'CARRIER_INSURANCE' && d.isDeleted === false && d.isExpired === false) === undefined) {
                        missingDocumentsList.push(Enums.DocumentTypes.getValueByName('CARRIER_INSURANCE'));
                        missingDocuments.push({ documentType: 'CARRIER_INSURANCE', name: Enums.DocumentTypes.getValueByName('CARRIER_INSURANCE') });
                    }
                }

                if (account.entityTypes.includes("RECEIVER") === true) {
                    documents.forEach((doc) => {
                        if (isBooleanFalse(doc.isReviewed) && isBooleanFalse(doc.isExpired) && isBooleanFalse(doc.isDeleted) && reviewableDocumentTypes.includes(doc.documentType)) {
                            if (Enums.ReceiverDocumentTypes.doesNameExist(doc.documentType)) {
                                pendingApprovalDocumentsList.push(doc);
                            }
                        }
                    });

                    // check for missing documents that are required for receiver
                    // if (documents.find((d) => d.documentType === 'RECEIVER_W9' && d.isDeleted === false && d.isExpired === false) === undefined) {
                    //     missingDocumentsList.push(Enums.DocumentTypes.getValueByName('RECEIVER_W9'));
                    //     missingDocuments.push({ documentType: 'RECEIVER_W9', name: Enums.DocumentTypes.getValueByName('RECEIVER_W9') });
                    // }
                    // if (documents.find((d) => d.documentType === 'RECEIVER_INSURANCE' && d.isDeleted === false && d.isExpired === false) === undefined) {
                    //     missingDocumentsList.push(Enums.DocumentTypes.getValueByName('RECEIVER_INSURANCE'));
                    //     missingDocuments.push({ documentType: 'RECEIVER_INSURANCE', name: Enums.DocumentTypes.getValueByName('RECEIVER_INSURANCE') });
                    // }
                }

                if (account.entityTypes.includes("PRODUCER") === true) {
                    documents.forEach((doc) => {
                        if (isBooleanFalse(doc.isReviewed) && isBooleanFalse(doc.isExpired) && isBooleanFalse(doc.isDeleted) && reviewableDocumentTypes.includes(doc.documentType)) {
                            if (Enums.ProducerDocumentTypes.doesNameExist(doc.documentType)) {
                                pendingApprovalDocumentsList.push(doc);
                            }
                        }
                    });

                    // check for missing documents that are required for producer
                    // if (documents.find((d) => d.documentType === 'PRODUCER_W9' && d.isDeleted === false && d.isExpired === false) === undefined) {
                    //     missingDocumentsList.push(Enums.DocumentTypes.getValueByName('PRODUCER_W9'));
                    //     missingDocuments.push({ documentType: 'PRODUCER_W9', name: Enums.DocumentTypes.getValueByName('PRODUCER_W9') });
                    // }
                    // if (documents.find((d) => d.documentType === 'PRODUCER_INSURANCE' && d.isDeleted === false && d.isExpired === false) === undefined) {
                    //     missingDocumentsList.push(Enums.DocumentTypes.getValueByName('PRODUCER_INSURANCE'));
                    //     missingDocuments.push({ documentType: 'PRODUCER_INSURANCE', name: Enums.DocumentTypes.getValueByName('PRODUCER_INSURANCE') });
                    // }
                }

                if (account.entityTypes.includes("BROKER") === true) {
                    documents.forEach((doc) => {
                        if (isBooleanFalse(doc.isReviewed) && isBooleanFalse(doc.isExpired) && isBooleanFalse(doc.isDeleted) && reviewableDocumentTypes.includes(doc.documentType)) {
                            if (Enums.BrokerDocumentTypes.doesNameExist(doc.documentType)) {
                                pendingApprovalDocumentsList.push(doc);
                            }
                        }
                    });

                    // check for missing documents that are required for broker
                    // if (documents.find((d) => d.documentType === 'BROKER_W9' && d.isDeleted === false && d.isExpired === false) === undefined) {
                    //     missingDocumentsList.push(Enums.DocumentTypes.getValueByName('BROKER_W9'));
                    //     missingDocuments.push({ documentType: 'BROKER_W9', name: Enums.DocumentTypes.getValueByName('BROKER_W9') });
                    // }
                    // if (documents.find((d) => d.documentType === 'BROKER_INSURANCE' && d.isDeleted === false && d.isExpired === false) === undefined) {
                    //     missingDocumentsList.push(Enums.DocumentTypes.getValueByName('BROKER_INSURANCE'));
                    //     missingDocuments.push({ documentType: 'BROKER_INSURANCE', name: Enums.DocumentTypes.getValueByName('BROKER_INSURANCE') });
                    // }
                    // if (documents.find((d) => d.documentType === 'COBROKER_AGREEMENT' && d.isDeleted === false && d.isExpired === false) === undefined) {
                    //     missingDocumentsList.push(Enums.DocumentTypes.getValueByName('COBROKER_AGREEMENT'));
                    //     missingDocuments.push({ documentType: 'COBROKER_AGREEMENT', name: Enums.DocumentTypes.getValueByName('COBROKER_AGREEMENT') });
                    // }
                }

                if (account.entityTypes.includes("FACTORING") === true) {
                    documents.forEach((doc) => {
                        if (isBooleanFalse(doc.isReviewed) && isBooleanFalse(doc.isExpired) && isBooleanFalse(doc.isDeleted) && reviewableDocumentTypes.includes(doc.documentType)) {
                            if (Enums.FactoringDocumentTypes.doesNameExist(doc.documentType)) {
                                pendingApprovalDocumentsList.push(doc);
                            }
                        }
                    });

                    // check for missing documents that are required for factoring company
                    // if (documents.find((d) => d.documentType === 'FACTORING_W9' && d.isDeleted === false && d.isExpired === false) === undefined) {
                    //     missingDocumentsList.push(Enums.DocumentTypes.getValueByName('FACTORING_W9'));
                    //     missingDocuments.push({ documentType: 'FACTORING_W9', name: Enums.DocumentTypes.getValueByName('FACTORING_W9') });
                    // }
                    // if (documents.find((d) => d.documentType === 'FACTORING_INSURANCE' && d.isDeleted === false && d.isExpired === false) === undefined) {
                    //     missingDocumentsList.push(Enums.DocumentTypes.getValueByName('FACTORING_INSURANCE'));
                    //     missingDocuments.push({ documentType: 'FACTORING_INSURANCE', name: Enums.DocumentTypes.getValueByName('FACTORING_INSURANCE') });
                    // }
                }
            }
        } else if (entityType === 'STOP') {
            documents.forEach((doc) => {
                if (isBooleanFalse(doc.isReviewed) && isBooleanFalse(doc.isExpired) && isBooleanFalse(doc.isDeleted) && reviewableDocumentTypes.includes(doc.documentType)) {
                    if (Enums.StopDocumentTypes.doesNameExist(doc.documentType)) {
                        pendingApprovalDocumentsList.push(doc);
                    }
                }
            });

            // check for missing documents that are required for stops
            if (entitySubType === 'PICK_UP') {
                if (documents.find((d) => d.documentType === 'BOL' && d.isDeleted === false) === undefined) {
                    missingDocumentsList.push(Enums.DocumentTypes.getValueByName('BOL'));
                    missingDocuments.push({ documentType: 'BOL', name: Enums.DocumentTypes.getValueByName('BOL') });
                }
                if (documents.find((d) => d.documentType === 'SCALE_TICKET' && d.isDeleted === false && d.isExpired === false) === undefined) {
                    missingDocumentsList.push(Enums.DocumentTypes.getValueByName('SCALE_TICKET'));
                    missingDocuments.push({ documentType: 'SCALE_TICKET', name: Enums.DocumentTypes.getValueByName('SCALE_TICKET') });
                }
                if (documents.find((d) => d.documentType === 'LUMPER_FEE_RECEIPT' && d.isDeleted === false && d.isExpired === false) === undefined && isBooleanTrue(needsLumperFeeReceipt)) {
                    missingDocumentsList.push(Enums.DocumentTypes.getValueByName('LUMPER_FEE_RECEIPT'));
                    missingDocuments.push({ documentType: 'LUMPER_FEE_RECEIPT', name: Enums.DocumentTypes.getValueByName('LUMPER_FEE_RECEIPT') });
                }
            } else if (entitySubType === 'DROP_OFF') {
                if (documents.find((d) => d.documentType === 'BOL' && d.isDeleted === false && d.isExpired === false) === undefined) {
                    missingDocumentsList.push(Enums.DocumentTypes.getValueByName('BOL'));
                    missingDocuments.push({ documentType: 'BOL', name: Enums.DocumentTypes.getValueByName('BOL') });
                }
                if (documents.find((d) => d.documentType === 'LUMPER_FEE_RECEIPT' && d.isDeleted === false && d.isExpired === false) === undefined && isBooleanTrue(needsLumperFeeReceipt)) {
                    missingDocumentsList.push(Enums.DocumentTypes.getValueByName('LUMPER_FEE_RECEIPT'));
                    missingDocuments.push({ documentType: 'LUMPER_FEE_RECEIPT', name: Enums.DocumentTypes.getValueByName('LUMPER_FEE_RECEIPT') });
                }
            }
        } else if (entityType === 'LOAD') {
            documents.forEach((doc) => {
                if (isBooleanFalse(doc.isReviewed) && isBooleanFalse(doc.isExpired) && isBooleanFalse(doc.isDeleted) && reviewableDocumentTypes.includes(doc.documentType)) {
                    if (Enums.LoadDocumentTypes.doesNameExist(doc.documentType)) {
                        pendingApprovalDocumentsList.push(doc);
                    }

                }
            });

            // check for missing documents that are required for loads
            if (documents.find((d) => d.documentType === 'CARRIER_WASHOUT_LOG' && d.isDeleted === false && d.isExpired === false) === undefined && isBooleanTrue(needsCarrierWashoutLog)) {
                missingDocumentsList.push(Enums.DocumentTypes.getValueByName('CARRIER_WASHOUT_LOG'));
                missingDocuments.push({ documentType: 'CARRIER_WASHOUT_LOG', name: Enums.DocumentTypes.getValueByName('CARRIER_WASHOUT_LOG') });
            }
        } else if (entityType === 'CLAIM') {
            documents.forEach((doc) => {
                if (isBooleanFalse(doc.isReviewed) && isBooleanFalse(doc.isExpired) && isBooleanFalse(doc.isDeleted) && reviewableDocumentTypes.includes(doc.documentType)) {
                    if (Enums.ClaimDocumentTypes.doesNameExist(doc.documentType)) {
                        pendingApprovalDocumentsList.push(doc);
                    }
                }
            });
        }

        documents.forEach((doc) => {
            if (doc.isExpired === true) {
                if (documents.find(d => d.documentType === doc.documentType && d.isExpired === false && d.isDeleted === false) === undefined) {
                    expiredDocumentsList.push(doc);
                }
            }
            if (doc.isExpiringSoon === true) {
                if (documents.find(d => d.documentType === doc.documentType && d.isExpiringSoon === false && d.isDeleted === false) === undefined) {
                    expiringSoonDocumentsList.push(doc);
                }
            }
        });
    }

    return {
        entityId,
        entityType,
        entitySubType,
        pendingApprovalDocumentsList,
        missingDocumentsList,
        expiringSoonDocumentsList,
        expiredDocumentsList,
        missingDocuments
    };
};

const getVisibleDocuments = (documents, userEntityType, isAdmin) => {
    let filteredDocuments = [];
    if (isListNotEmpty(documents)) {
        if (userEntityType === "STAFF" && isAdmin === true) {
            filteredDocuments = documents.filter(doc => doc.isExpired === false && doc.isDeleted === false);
        } else if (userEntityType === "STAFF" && isAdmin === false) {
            filteredDocuments = documents.filter(doc => doc.visibleTo !== "ADMIN" && doc.isExpired === false && doc.isDeleted === false);
        } else if (userEntityType === "DRIVER") {
            filteredDocuments = documents.filter(doc => (doc.visibleTo === "ALL" || doc.visibleTo === "DRIVER") && doc.documentType !== 'ACCOUNT_LOGO' && doc.isExpired === false && doc.isDeleted === false);
        } else if (userEntityType === "CARRIER") {
            filteredDocuments = documents.filter(doc => (doc.visibleTo === "ALL" || doc.visibleTo === "DRIVER" || doc.visibleTo === "CARRIER") && doc.documentType !== 'ACCOUNT_LOGO' && doc.isExpired === false && doc.isDeleted === false);
        } else if (userEntityType === "PRODUCER") {
            filteredDocuments = documents.filter(doc => (doc.visibleTo === "ALL" || doc.visibleTo === "PRODUCER") && doc.documentType !== 'ACCOUNT_LOGO' && doc.isExpired === false && doc.isDeleted === false);
        } else if (userEntityType === "RECEIVER") {
            filteredDocuments = documents.filter(doc => (doc.visibleTo === "ALL" || doc.visibleTo === "RECEIVER") && doc.documentType !== 'ACCOUNT_LOGO' && doc.isExpired === false && doc.isDeleted === false);
        } else if (userEntityType === "SHIPPER") {
            filteredDocuments = documents.filter(doc => (doc.visibleTo === "ALL" || doc.visibleTo === "SHIPPER") && doc.documentType !== 'ACCOUNT_LOGO' && doc.isExpired === false && doc.isDeleted === false);
        } else if (userEntityType === "BROKER") {
            filteredDocuments = documents.filter(doc => (doc.visibleTo === "ALL" || doc.visibleTo === "BROKER") && doc.documentType !== 'ACCOUNT_LOGO' && doc.isExpired === false && doc.isDeleted === false);
        } else if (userEntityType === "FACTORING") {
            filteredDocuments = documents.filter(doc => (doc.visibleTo === "ALL" || doc.visibleTo === "FACTORING") && doc.documentType !== 'ACCOUNT_LOGO' && doc.isExpired === false && doc.isDeleted === false);
        }
    }

    return filteredDocuments;
};

const selectListVisibleDocumentsByLoadId = (lists, loadId, load, entityType, isAdmin = false) => {
    let visibleDocumentsList = [];
    if (isObjectNotEmpty(lists) && isStringNotEmpty(loadId) && isObjectNotEmpty(lists[loadId])) {
        let loadDocuments = lists[loadId].records;
        let visibleLoadDocuments = getVisibleDocuments(loadDocuments, entityType, isAdmin);
        if (isListNotEmpty(visibleLoadDocuments)) {
            visibleLoadDocuments.forEach((visibleDocument) => {
                visibleDocumentsList.push({ ...visibleDocument, entitySubType: null, entityName: isStringNotEmpty(load.parentName) ? load.parentName : (isStringNotEmpty(load.name) ? load.name : load.irisId), entity: load, documentDisplayName: visibleDocument.displayName });
            });
        }
    }

    return visibleDocumentsList;
};

const selectListVisibleDocumentsByStops = (lists, loadId, stops, entityType, isAdmin = false) => {
    let visibleDocumentsList = [];
    if (isObjectNotEmpty(lists) && isStringNotEmpty(loadId) && isObjectNotEmpty(lists[loadId])) {
        let loadDocuments = lists[loadId].records;
        let visibleLoadDocuments = getVisibleDocuments(loadDocuments, entityType, isAdmin);

        if (isListNotEmpty(visibleLoadDocuments) && isListNotEmpty(stops)) {
            let orderedStops = orderBy(stops, ['sequence'], ['asc']);
            orderedStops.filter(s => s.stopType === 'PICK_UP').forEach((stop, stopIndex, pickUpStops) => {
                let visibleStopDocuments = visibleLoadDocuments.filter(d => d.links.map(l => l.entityId).includes(stop.id));
                if (isListNotEmpty(visibleStopDocuments)) {
                    visibleStopDocuments.forEach((visibleDocument) => {
                        visibleDocumentsList.push({ ...visibleDocument, entitySubType: stop.stopType, entityName: `${Enums.StopTypes.getValueByName(stop.stopType)}${pickUpStops.length > 1 ? ' ' + (stopIndex + 1) : ''}`, entity: stop, documentDisplayName: visibleDocument.displayName });
                    });
                }
            });

            orderedStops.filter(s => s.stopType === 'DROP_OFF').forEach((stop, stopIndex, dropOffStops) => {
                let visibleStopDocuments = visibleLoadDocuments.filter(d => d.links.map(l => l.entityId).includes(stop.id));
                if (isListNotEmpty(visibleStopDocuments)) {
                    visibleStopDocuments.forEach((visibleDocument) => {
                        visibleDocumentsList.push({ ...visibleDocument, entitySubType: stop.stopType, entityName: `${Enums.StopTypes.getValueByName(stop.stopType)}${dropOffStops.length > 1 ? ' ' + (stopIndex + 1) : ''}`, entity: stop, documentDisplayName: visibleDocument.documentType === 'BOL' ? `${visibleDocument.displayName} (Proof of Delivery for ${Enums.StopTypes.getValueByName(stop.stopType)}${dropOffStops.length > 1 ? ' ' + (stopIndex + 1) : ''})` : visibleDocument.displayName });
                    });
                }
            });
        }
    }

    return visibleDocumentsList;
};

const selectListVisibleDocumentsByLoadIdAndStops = (lists, loadId, load, stops, entityType, isAdmin = false) => {
    let visibleDocumentsList = [];
    if (isObjectNotEmpty(lists) && isStringNotEmpty(loadId) && isObjectNotEmpty(lists[loadId])) {
        let loadDocuments = lists[loadId].records;
        let visibleLoadDocuments = getVisibleDocuments(loadDocuments, entityType, isAdmin);
        if (isListNotEmpty(visibleLoadDocuments)) {
            visibleLoadDocuments.filter(d => !d.links.map(l => l.entityType).includes('STOP')).forEach((visibleDocument) => {
                visibleDocumentsList.push({ ...visibleDocument, entitySubType: null, entityName: isStringNotEmpty(load.parentName) ? load.parentName : (isStringNotEmpty(load.name) ? load.name : load.irisId), entity: load, documentDisplayName: visibleDocument.displayName });
            });
        }

        if (isListNotEmpty(visibleLoadDocuments) && isListNotEmpty(stops)) {
            let orderedStops = orderBy(stops, ['sequence'], ['asc']);
            orderedStops.filter(s => s.stopType === 'PICK_UP').forEach((stop, stopIndex, pickUpStops) => {
                let visibleStopDocuments = visibleLoadDocuments.filter(d => d.links.map(l => l.entityId).includes(stop.id));
                if (isListNotEmpty(visibleStopDocuments)) {
                    visibleStopDocuments.forEach((visibleDocument) => {
                        visibleDocumentsList.push({ ...visibleDocument, entitySubType: stop.stopType, entityName: `${Enums.StopTypes.getValueByName(stop.stopType)}${pickUpStops.length > 1 ? ' ' + (stopIndex + 1) : ''}`, entity: stop, documentDisplayName: visibleDocument.displayName });
                    });
                }
            });

            orderedStops.filter(s => s.stopType === 'DROP_OFF').forEach((stop, stopIndex, dropOffStops) => {
                let visibleStopDocuments = visibleLoadDocuments.filter(d => d.links.map(l => l.entityId).includes(stop.id));
                if (isListNotEmpty(visibleStopDocuments)) {
                    visibleStopDocuments.forEach((visibleDocument) => {
                        visibleDocumentsList.push({ ...visibleDocument, entitySubType: stop.stopType, entityName: `${Enums.StopTypes.getValueByName(stop.stopType)}${dropOffStops.length > 1 ? ' ' + (stopIndex + 1) : ''}`, entity: stop, documentDisplayName: visibleDocument.documentType === 'BOL' ? `${visibleDocument.displayName} (Proof of Delivery for ${Enums.StopTypes.getValueByName(stop.stopType)}${dropOffStops.length > 1 ? ' ' + (stopIndex + 1) : ''})` : visibleDocument.displayName });
                    });
                }
            });
        }
    }

    return visibleDocumentsList;
};

const selectListMissingDocumentsByLoadIdAndStops = (lists, loadId, load, stops, entityType, isAdmin = false) => {
    let missingDocumentsList = [];
    if (isObjectNotEmpty(lists) && isStringNotEmpty(loadId) && isObjectNotEmpty(lists[loadId])) {
        let loadDocuments = lists[loadId].records;
        let visibleLoadDocuments = getVisibleDocuments(loadDocuments, entityType, isAdmin);

        const hasCarrierWashout = isObjectNotEmpty(load) && isListNotEmpty(load.equipmentSpecifics) && load.equipmentSpecifics.includes('CARRIER_WASHOUT') ? true : false;
        let loadCounts = calculateCounts(visibleLoadDocuments, loadId, 'LOAD', null, null, hasCarrierWashout);
        if (isListNotEmpty(loadCounts.missingDocuments)) {
            loadCounts.missingDocuments.forEach((missingDocument) => {
                missingDocumentsList.push({ entityType: 'LOAD', entityId: load.id, entity: load, entitySubType: null, entityName: isStringNotEmpty(load.parentName) ? load.parentName : (isStringNotEmpty(load.name) ? load.name : load.irisId), documentType: missingDocument.documentType, documentTypeName: missingDocument.name, documentDisplayName: missingDocument.name, visibleTo: 'ALL' });
            });
        }

        if (isListNotEmpty(visibleLoadDocuments) && isListNotEmpty(stops)) {
            let orderedStops = orderBy(stops, ['sequence'], ['asc']);
            orderedStops.filter(s => s.stopType === 'PICK_UP').forEach((stop, stopIndex, pickUpStops) => {
                let visibleStopDocuments = visibleLoadDocuments.filter(d => d.links.map(l => l.entityId).includes(stop.id));
                let stopCounts = calculateCounts(visibleStopDocuments, stop.id, 'STOP', stop.stopType, null, false, stop.hasLumperFee);
                if (isListNotEmpty(stopCounts.missingDocuments)) {
                    stopCounts.missingDocuments.forEach((missingDocument) => {
                        missingDocumentsList.push({ entityType: 'STOP', entityId: stop.id, entity: stop, entitySubType: stop.stopType, entityName: `${Enums.StopTypes.getValueByName(stop.stopType)}${pickUpStops.length > 1 ? ' ' + (stopIndex + 1) : ''}`, documentType: missingDocument.documentType, documentTypeName: missingDocument.name, documentDisplayName: missingDocument.name, visibleTo: 'ALL' });
                    });
                }
            });

            orderedStops.filter(s => s.stopType === 'DROP_OFF').forEach((stop, stopIndex, dropOffStops) => {
                let visibleStopDocuments = visibleLoadDocuments.filter(d => d.links.map(l => l.entityId).includes(stop.id));
                let stopCounts = calculateCounts(visibleStopDocuments, stop.id, 'STOP', stop.stopType, null, false, stop.hasLumperFee);
                if (isListNotEmpty(stopCounts.missingDocuments)) {
                    stopCounts.missingDocuments.forEach((missingDocument) => {
                        missingDocumentsList.push({ entityType: 'STOP', entityId: stop.id, entity: stop, entitySubType: stop.stopType, entityName: `${Enums.StopTypes.getValueByName(stop.stopType)}${dropOffStops.length > 1 ? ' ' + (stopIndex + 1) : ''}`, documentType: missingDocument.documentType, documentTypeName: missingDocument.name, documentDisplayName: missingDocument.documentType === 'BOL' ? `${missingDocument.name} (Proof of Delivery for ${Enums.StopTypes.getValueByName(stop.stopType)}${dropOffStops.length > 1 ? ' ' + (stopIndex + 1) : ''})` : missingDocument.name, visibleTo: 'ALL' });
                    });
                }
            });
        }
    }

    return missingDocumentsList;
};

const selectListPendingApprovalDocumentsByLoadIdAndStops = (lists, loadId, load, stops, entityType, isAdmin = false) => {
    let pendingApprovalDocumentsList = [];
    if (isObjectNotEmpty(lists) && isStringNotEmpty(loadId) && isObjectNotEmpty(lists[loadId])) {
        let loadDocuments = lists[loadId].records;
        let visibleLoadDocuments = getVisibleDocuments(loadDocuments, entityType, isAdmin);

        const hasCarrierWashout = isObjectNotEmpty(load) && isListNotEmpty(load.equipmentSpecifics) && load.equipmentSpecifics.includes('CARRIER_WASHOUT') ? true : false;
        let loadCounts = calculateCounts(visibleLoadDocuments, loadId, 'LOAD', null, null, hasCarrierWashout);
        if (isListNotEmpty(loadCounts.pendingApprovalDocumentsList)) {
            loadCounts.pendingApprovalDocumentsList.forEach((pendingApprovalDocument) => {
                pendingApprovalDocumentsList.push({ ...pendingApprovalDocument, entitySubType: null, entityName: isStringNotEmpty(load.parentName) ? load.parentName : (isStringNotEmpty(load.name) ? load.name : load.irisId), entity: load, documentDisplayName: pendingApprovalDocument.displayName });
            });
        }

        if (isListNotEmpty(visibleLoadDocuments) && isListNotEmpty(stops)) {
            let orderedStops = orderBy(stops, ['sequence'], ['asc']);
            orderedStops.filter(s => s.stopType === 'PICK_UP').forEach((stop, stopIndex, pickUpStops) => {
                let visibleStopDocuments = visibleLoadDocuments.filter(d => d.links.map(l => l.entityId).includes(stop.id));
                let stopCounts = calculateCounts(visibleStopDocuments, stop.id, 'STOP', stop.stopType, null, false, stop.hasLumperFee);
                if (isListNotEmpty(stopCounts.pendingApprovalDocumentsList)) {
                    stopCounts.pendingApprovalDocumentsList.forEach((pendingApprovalDocument) => {
                        pendingApprovalDocumentsList.push({ ...pendingApprovalDocument, entitySubType: stop.stopType, entityName: `${Enums.StopTypes.getValueByName(stop.stopType)}${pickUpStops.length > 1 ? ' ' + (stopIndex + 1) : ''}`, entity: stop, documentDisplayName: pendingApprovalDocument.displayName });
                    });
                }
            });

            orderedStops.filter(s => s.stopType === 'DROP_OFF').forEach((stop, stopIndex, dropOffStops) => {
                let visibleStopDocuments = visibleLoadDocuments.filter(d => d.links.map(l => l.entityId).includes(stop.id));
                let stopCounts = calculateCounts(visibleStopDocuments, stop.id, 'STOP', stop.stopType, null, false, stop.hasLumperFee);
                if (isListNotEmpty(stopCounts.pendingApprovalDocumentsList)) {
                    stopCounts.pendingApprovalDocumentsList.forEach((pendingApprovalDocument) => {
                        pendingApprovalDocumentsList.push({ ...pendingApprovalDocument, entitySubType: stop.stopType, entityName: `${Enums.StopTypes.getValueByName(stop.stopType)}${dropOffStops.length > 1 ? ' ' + (stopIndex + 1) : ''}`, entity: stop, documentDisplayName: pendingApprovalDocument.documentType === 'BOL' ? `${pendingApprovalDocument.displayName} (Proof of Delivery for ${Enums.StopTypes.getValueByName(stop.stopType)}${dropOffStops.length > 1 ? ' ' + (stopIndex + 1) : ''})` : pendingApprovalDocument.displayName });
                    });
                }
            });
        }
    }

    return pendingApprovalDocumentsList;
};

const selectListProofOfDeliveryDocumentsByStops = (lists, loadId, stops, entityType, isAdmin = false) => {
    let documentsList = [];
    if (isObjectNotEmpty(lists) && isStringNotEmpty(loadId) && isObjectNotEmpty(lists[loadId])) {
        let loadDocuments = lists[loadId].records;
        let visibleLoadDocuments = getVisibleDocuments(loadDocuments, entityType, isAdmin);

        if (isListNotEmpty(visibleLoadDocuments) && isListNotEmpty(stops)) {
            let orderedStops = orderBy(stops, ['sequence'], ['asc']);

            orderedStops.filter(s => s.stopType === 'DROP_OFF').forEach((stop, stopIndex, dropOffStops) => {
                let visibleStopDocuments = visibleLoadDocuments.filter(d => d.links.map(l => l.entityId).includes(stop.id));
                let stopCounts = calculateCounts(visibleStopDocuments, stop.id, 'STOP', stop.stopType, null, false, stop.hasLumperFee);

                if (isListNotEmpty(stopCounts.missingDocuments)) {
                    stopCounts.missingDocuments.forEach((missingDocument) => {
                        if (missingDocument.documentType === 'BOL') {
                            documentsList.push({ entityType: 'STOP', entityId: stop.id, entity: stop, entitySubType: stop.stopType, entityName: `${Enums.StopTypes.getValueByName(stop.stopType)}${dropOffStops.length > 1 ? ' ' + (stopIndex + 1) : ''}`, documentType: missingDocument.documentType, documentTypeName: missingDocument.name, documentDisplayName: `${missingDocument.name} (Proof of Delivery for ${Enums.StopTypes.getValueByName(stop.stopType)}${dropOffStops.length > 1 ? ' ' + (stopIndex + 1) : ''})`, visibleTo: 'ALL', status: 'MISSING' });
                        }
                    });
                }

                if (isListNotEmpty(visibleStopDocuments)) {
                    visibleStopDocuments.forEach((visibleDocument) => {
                        if (visibleDocument.documentType === 'BOL') {
                            if (isBooleanFalse(visibleDocument.isReviewed) && isBooleanFalse(visibleDocument.isExpired) && isBooleanFalse(visibleDocument.isDeleted) && reviewableDocumentTypes.includes(visibleDocument.documentType)) {
                                documentsList.push({ ...visibleDocument, entitySubType: stop.stopType, entityName: `${Enums.StopTypes.getValueByName(stop.stopType)}${dropOffStops.length > 1 ? ' ' + (stopIndex + 1) : ''}`, entity: stop, status: 'PENDING_APPROVAL', documentDisplayName: `${visibleDocument.displayName} (Proof of Delivery for ${Enums.StopTypes.getValueByName(stop.stopType)}${dropOffStops.length > 1 ? ' ' + (stopIndex + 1) : ''})` });
                            } else {
                                documentsList.push({ ...visibleDocument, entitySubType: stop.stopType, entityName: `${Enums.StopTypes.getValueByName(stop.stopType)}${dropOffStops.length > 1 ? ' ' + (stopIndex + 1) : ''}`, entity: stop, status: visibleDocument.reviewStatus, documentDisplayName: `${visibleDocument.displayName} (Proof of Delivery for ${Enums.StopTypes.getValueByName(stop.stopType)}${dropOffStops.length > 1 ? ' ' + (stopIndex + 1) : ''})` });
                            }
                        }
                    });
                }
            });
        }
    }

    return documentsList;
};

export default {
    calculateCounts,
    getVisibleDocuments,
    selectListVisibleDocumentsByLoadId,
    selectListVisibleDocumentsByStops,
    selectListVisibleDocumentsByLoadIdAndStops,
    selectListMissingDocumentsByLoadIdAndStops,
    selectListPendingApprovalDocumentsByLoadIdAndStops,
    selectListProofOfDeliveryDocumentsByStops,
    reviewableDocumentTypes
}