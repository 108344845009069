import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Button, Alert, Spin, Input, Modal, Select, InputNumber, Row, Col, Radio, Checkbox, Timeline, Empty, Drawer } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Enums from '../../shared/enums';
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import pickUpMarker from '../../assets/img/pickUpMarker.png';
import dropOffMarker from '../../assets/img/dropOffMarker.png';
import StringFormatter from '../../shared/stringFormatter';
import { DownOutlined, PlusOutlined, RightOutlined, DeleteOutlined } from '@ant-design/icons';
import DataCollapsableRow from '../DataCollapsableRow/DataCollapsableRow';
import { ErrorMessage } from '@hookform/error-message';
import { isListNotEmpty, isNotNullOrUndefined, isNumberNotEmpty, isObjectNotEmpty, isStringNotEmpty, isStringEmpty, isListEmpty, isNumberGreaterThanZero, isNumberEmpty, isObjectEmpty } from '../../shared/objectUtils';
import { withRouter } from 'react-router';
import LaneUtils from '../../api/utils/laneUtils';
import { selectListRecords } from '../../store/utility';
import EntityUtils from '../../api/entityUtils';
import NewLocation from '../NewLocation/NewLocation';
import { orderBy } from 'lodash';
import MapWrapper from '../Map/Map';

const { Option } = Select;
const stringFormatter = new StringFormatter();

const NewLinkedLoadLane = ({ accountId, linkedAccountId, linkedAccountEntityType, cancel, ...props }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    //#endregion
    //#region useRefs

    const preCoolReefer = useRef(null);
    const minRunTemp = useRef(null);
    const maxRunTemp = useRef(null);

    //#endregion
    //#region useForms

    const methods = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            pickUpLocationId: null,
            pickUpLoadingType: null,
            dropOffLocationId: null,
            dropOffLoadingType: null,
            accountId: null,
            customerLaneId: '',
            perMileRate: null,
            perMileRateUnit: null,
            contractRate: null,
            contractRateUnit: null,
            approxDistance: null,
            approxTransitTime: null,
            'equipmentNeeds.trailerTypeId': null,
            'equipmentNeeds.trailerLength': null,
            'equipmentNeeds.trailerWeightCapacity': null,
            'equipmentNeeds.minRunTemp': null,
            'equipmentNeeds.maxRunTemp': null,
            'equipmentNeeds.preCoolReefer': null,
            equipmentSpecifics: null,
            driverRequirements: null
        }
    });
    const methods2 = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            locationId: null,
            loadingType: null
        }
    });
    const methods3 = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            commodityId: null,
            unitPackagingInitCount: null,
            unitPackaging: null,
            bulkPackagingInitCount: null,
            bulkPackaging: null
        }
    });

    //#endregion
    //#region useStates

    const [locationsList, setLocationsList] = useState([]);
    const [pickUpStops, setPickUpStops] = useState([{
        stopType: 'PICK_UP',
        id: 0,
        sequence: 0,
        stopTypeSequence: 0,
        loadingType: 'LIVE'
    }]);
    const [dropOffStops, setDropOffStops] = useState([{
        stopType: 'DROP_OFF',
        id: 1,
        sequence: 1,
        stopTypeSequence: 0,
        loadingType: 'LIVE'
    }]);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [showNewPickUpStopLocation, setShowNewPickUpStopLocation] = useState(false);
    const [showNewDropOffStopLocation, setShowNewDropOffStopLocation] = useState(false);
    const [showEditStopLocation, setShowEditStopLocation] = useState(false);
    const [showAddStopCommodity, setShowAddStopCommodity] = useState(false);
    const [practicalRouteMiles, setPracticalRouteMiles] = useState(0);
    const [practicalRouteMinutes, setPracticalRouteMinutes] = useState(0);
    const [shipperId, setShipperId] = useState(null);
    const [pickUpsEnabled, setPickUpsEnabled] = useState(false);
    const [dropOffsEnabled, setDropOffsEnabled] = useState(false);
    const [equipmentSpecificOptions, setEquipmentSpecificOptions] = useState([]);
    const [driverRequirementOptions, setDriverRequirementOptions] = useState([]);
    const [showReeferOptions, setShowReeferOptions] = useState(false);
    const [showNewLocation, setShowNewLocation] = useState(false);
    const [trailerTypeId, setTrailerTypeId] = useState(null);

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const entityType = useSelector(state => state.auth.entityType);
    const entityId = useSelector(state => state.auth.entityId);
    const isLoading = useSelector(state => state.linkedLoadLanes.isRecordAddLoading);
    const error = useSelector(state => state.linkedLoadLanes.addRecordError);
    const commodities = useSelector(state => state.orchestrator.commodities);
    const lineItemTypes = useSelector(state => state.orchestrator.lineItemTypes);
    const trailerTypes = useSelector(state => state.orchestrator.trailerTypes);
    const locations = useSelector(state => selectListRecords(state.orchestrator.locations, entityType === 'STAFF' ? (isStringNotEmpty(shipperId) ? shipperId : null) : (isStringNotEmpty(accountId) ? accountId : (isStringNotEmpty(entityId) ? entityId : null))));
    const linkedAccounts = useSelector(state => selectListRecords(state.orchestrator.linkedAccounts, accountId));

    //#endregion
    //#region Enums

    const currencyOptions = Enums.Currencies.selectListOptions();
    const unitPackagingOptions = Enums.UnitPackaging.selectListOptions();
    const bulkPackagingOptions = Enums.BulkPackaging.selectListOptions();

    //#endregion
    //#region toggles

    const toggleNewPickUpStopLocation = () => {
        // reset form
        methods.setValue('pickUpLocationId', null);
        methods.setValue('pickUpLoadingType', null);
        methods.clearErrors();

        setShowNewPickUpStopLocation(!showNewPickUpStopLocation);
    };

    const toggleNewDropOffStopLocation = () => {
        // reset form
        methods.setValue('dropOffLocationId', null);
        methods.setValue('dropOffLoadingType', null);
        methods.clearErrors();

        setShowNewDropOffStopLocation(!showNewDropOffStopLocation);
    };

    const toggleEditStopLocation = () => {
        // reset form
        methods2.setValue('locationId', null);
        methods2.setValue('loadingType', null);
        methods2.clearErrors();

        setShowEditStopLocation(!showEditStopLocation);
    };

    const toggleAddStopCommodity = () => {
        // reset form
        methods3.setValue('commodityId', null);
        methods3.setValue('unitPackagingInitCount', null);
        methods3.setValue('unitPackaging', null);
        methods3.setValue('bulkPackagingInitCount', null);
        methods3.setValue('bulkPackaging', null);
        methods3.clearErrors();

        setShowAddStopCommodity(!showAddStopCommodity);
    };

    const toggleNewLocation = () => {
        setShowNewLocation(!showNewLocation);
    };

    //#endregion
    //#region map functions

    const setRouteMiles = (value) => {
        if (isListNotEmpty(pickUpStops) && isListNotEmpty(dropOffStops)) {
            setPracticalRouteMiles(value);
            methods.setValue("approxDistance", value);
        } else {
            setPracticalRouteMiles(0);
            methods.setValue("approxDistance", 0);
        }
    };

    const setRouteDuration = (value) => {
        if (isListNotEmpty(pickUpStops) && isListNotEmpty(dropOffStops)) {
            setPracticalRouteMinutes(value);
            methods.setValue("approxTransitTime", value);
        } else {
            setPracticalRouteMinutes(0);
            methods.setValue("approxTransitTime", 0);
        }
    };

    //#endregion
    //#region onChanges

    const onChangeLinkedAccountId = (value) => {
        // console.log(`selected ${value}`);
        setShipperId(value);
    };

    const onChangeTrailerTypeId = (value) => {
        if (isStringNotEmpty(value)) {
            setTrailerTypeId(value);
            let equipmentSpecificOptionsList = [];
            let driverRequirementOptionsList = [];
            if (isListNotEmpty(lineItemTypes)) {
                let equipmentSpecifics = lineItemTypes.filter(i => i.trailerTypeIds.includes(value) && i.itemType === 'EQUIPMENT_SPECIFIC');
                let driverRequirements = lineItemTypes.filter(i => i.trailerTypeIds.includes(value) && i.itemType === 'LOAD_REQUIREMENT');

                for (let i = 0; i < equipmentSpecifics.length; i++) {
                    equipmentSpecificOptionsList.push({ value: equipmentSpecifics[i].name, key: equipmentSpecifics[i].name, label: equipmentSpecifics[i].description });
                }
                for (let i = 0; i < driverRequirements.length; i++) {
                    driverRequirementOptionsList.push({ value: driverRequirements[i].name, key: driverRequirements[i].name, label: driverRequirements[i].description });
                }
            }
            setEquipmentSpecificOptions(equipmentSpecificOptionsList);
            setDriverRequirementOptions(driverRequirementOptionsList);

            if (isListNotEmpty(trailerTypes)) {
                let selectedTrailerType = trailerTypes.find(s => s.id === value);
                if (isObjectNotEmpty(selectedTrailerType)) {
                    if (selectedTrailerType.name === "REEFER") {
                        setShowReeferOptions(true);
                    } else {
                        setShowReeferOptions(false);
                    }
                }
            }
        } else {
            setTrailerTypeId(null);
            setEquipmentSpecificOptions([]);
            setDriverRequirementOptions([]);
            setShowReeferOptions(false);
        }
    };

    //#endregion
    //#region submits, validators, and cancels

    const onEditStopLocation = (data) => {
        const locationId = data.locationId;
        const loadingType = data.loadingType;
        let location = EntityUtils.getLocationInfo(locationId, locationsList);
        if (isObjectNotEmpty(location)) {
            const updatedValues = {
                location: location,
                locationId: locationId,
                timeZone: location.timeZone,
                latitude: location.latitude,
                longitude: location.longitude,
                loadingType: loadingType,
                loadId: 'new-lane'
            };

            if (isObjectNotEmpty(selectedRecord)) {
                if (selectedRecord.stopType === 'PICK_UP') {
                    let existingList = [...pickUpStops];
                    const index = existingList.findIndex(obj => obj.id === selectedRecord.id);
                    // only update if the record exists in the list
                    if (index !== -1) {
                        const updatedRecord = {
                            ...existingList[index],
                            ...updatedValues
                        };
                        const updatedRecords = [
                            ...existingList.slice(0, index), // everything before current obj
                            updatedRecord,
                            ...existingList.slice(index + 1), // everything after current obj
                        ];

                        setPickUpStops([...updatedRecords]);
                    }
                } else if (selectedRecord.stopType === 'DROP_OFF') {
                    let existingList = [...dropOffStops];
                    const index = existingList.findIndex(obj => obj.id === selectedRecord.id);
                    // only update if the record exists in the list
                    if (index !== -1) {
                        const updatedRecord = {
                            ...existingList[index],
                            ...updatedValues
                        };
                        const updatedRecords = [
                            ...existingList.slice(0, index), // everything before current obj
                            updatedRecord,
                            ...existingList.slice(index + 1), // everything after current obj
                        ];

                        setDropOffStops([...updatedRecords]);
                    }
                }

                toggleEditStopLocation();
                setSelectedRecord(null);
            } else {
                return;
            }
        }
    };

    const onAddStopCommodity = (data) => {
        const commodityId = data.commodityId;
        const unitPackagingInitCount = data.unitPackagingInitCount;
        const unitPackaging = data.unitPackaging;
        const bulkPackagingInitCount = data.bulkPackagingInitCount;
        const bulkPackaging = data.bulkPackaging;

        methods3.clearErrors();

        let name = null;
        if (isListNotEmpty(commodities)) {
            let commodity = commodities.find(c => c.id === commodityId);
            if (isObjectNotEmpty(commodity)) {
                name = commodity.name;
            }
        }

        let newStopCommodity = {
            commodityId: commodityId,
            name: name
        };

        if (isNumberGreaterThanZero(unitPackagingInitCount)) {
            if (isStringEmpty(unitPackaging)) {
                methods3.setError('unitPackaging', { type: "validate", message: 'You must provide a Unit of Measure/Unit Packaging if you are going to provide a Unit Quantity for this Commodity.' });
                return;
            } else {
                newStopCommodity.unitPackagingInitCount = unitPackagingInitCount;
                newStopCommodity.unitPackaging = unitPackaging;
            }
        } else if ((isNumberEmpty(unitPackagingInitCount) || unitPackagingInitCount === 0) && isStringNotEmpty(unitPackaging)) {
            methods3.setError('unitPackagingInitCount', { type: "validate", message: 'You must provide a Unit Quantity greater than 0 if you are going to provide a Unit of Measure/Unit Packaging for this Commodity.' });
            return;
        }

        if (isNumberGreaterThanZero(bulkPackagingInitCount)) {
            if (isStringEmpty(bulkPackaging)) {
                methods3.setError('bulkPackaging', { type: "validate", message: 'You must provide Bulk Packaging if you are going to provide a Bulk Quantity for this Commodity.' });
                return;
            } else {
                newStopCommodity.bulkPackagingInitCount = bulkPackagingInitCount;
                newStopCommodity.bulkPackaging = bulkPackaging;
            }
        } else if ((isNumberEmpty(bulkPackagingInitCount) || bulkPackagingInitCount === 0) && isStringNotEmpty(bulkPackaging !== undefined && bulkPackaging !== null)) {
            methods3.setError('bulkPackagingInitCount', { type: "validate", message: 'You must provide a Bulk Quantity greater than 0 if you are going to provide Bulk Packaging for this Commodity.' });
            return;
        }

        //console.log(newStopCommodity);

        if (isObjectNotEmpty(selectedRecord)) {
            let stopCommodities = [];
            if (isListNotEmpty(selectedRecord.commodities)) {
                stopCommodities = [...selectedRecord.commodities];
                if (stopCommodities.find(c => c.commodityId === commodityId && c.bulkPackaging === bulkPackaging && c.unitPackaging === unitPackaging) === undefined) {
                    stopCommodities.push(newStopCommodity);
                } else {
                    methods3.setError('commodityId', { type: "validate", message: 'This commodity has already been added to this location with the unit and bulk packaging you provided.' });
                    return;
                }
            } else {
                stopCommodities.push(newStopCommodity);
            }

            //console.log(stopCommodities);

            if (selectedRecord.stopType === 'PICK_UP') {
                let existingList = [...pickUpStops];
                const index = existingList.findIndex(obj => obj.id === selectedRecord.id);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        commodities: [...stopCommodities]
                    };

                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    setPickUpStops([...updatedRecords]);

                    if (dropOffStops.length === 1) {
                        // if there is only one drop-off stop, set the value for the commodities
                        let updatedPickUpRecords = [...updatedRecords];
                        if (isListNotEmpty(updatedPickUpRecords)) {
                            let dropOffStopCommodities = [];
                            if (updatedPickUpRecords.length === 1) {
                                if (isListNotEmpty(updatedPickUpRecords[0].commodities)) {
                                    dropOffStopCommodities = updatedPickUpRecords[0].commodities;
                                }
                            } else if (updatedPickUpRecords.length > 1) {
                                updatedPickUpRecords.forEach((pickUpStop) => {
                                    if (isListNotEmpty(pickUpStop.commodities)) {
                                        pickUpStop.commodities.forEach((stopCommodity) => {
                                            let existingDropOffStopCommodity = dropOffStopCommodities.find(c => c.commodityId === stopCommodity.commodityId && c.unitPackaging === stopCommodity.unitPackaging && c.bulkPackaging === stopCommodity.bulkPackaging);
                                            if (isObjectEmpty(existingDropOffStopCommodity)) {
                                                dropOffStopCommodities.push(stopCommodity);
                                            } else {
                                                const existingDropOffStopCommodityIndex = dropOffStopCommodities.findIndex(c => c.commodityId === stopCommodity.commodityId && c.unitPackaging === stopCommodity.unitPackaging && c.bulkPackaging === stopCommodity.bulkPackaging);
                                                // only update if the record exists in the list
                                                if (existingDropOffStopCommodityIndex !== -1) {
                                                    const updatedExistingDropOffStopCommodity = {
                                                        ...dropOffStopCommodities[existingDropOffStopCommodityIndex]
                                                    };

                                                    if (isStringNotEmpty(existingDropOffStopCommodity.unitPackaging) && existingDropOffStopCommodity.unitPackaging === stopCommodity.unitPackaging) {
                                                        updatedExistingDropOffStopCommodity.unitPackagingInitCount = updatedExistingDropOffStopCommodity.unitPackagingInitCount + stopCommodity.unitPackagingInitCount;
                                                    }
                                                    if (isStringNotEmpty(existingDropOffStopCommodity.bulkPackaging) && existingDropOffStopCommodity.bulkPackaging === stopCommodity.bulkPackaging) {
                                                        updatedExistingDropOffStopCommodity.bulkPackagingInitCount = updatedExistingDropOffStopCommodity.bulkPackagingInitCount + stopCommodity.bulkPackagingInitCount;
                                                    }

                                                    const updatedExistingDropOffStopCommodities = [
                                                        ...dropOffStopCommodities.slice(0, existingDropOffStopCommodityIndex), // everything before current obj
                                                        updatedExistingDropOffStopCommodity,
                                                        ...dropOffStopCommodities.slice(existingDropOffStopCommodityIndex + 1), // everything after current obj
                                                    ];

                                                    dropOffStopCommodities = [...updatedExistingDropOffStopCommodities];
                                                }
                                            }
                                        });
                                    }
                                });
                            }

                            let existingDropOffList = [...dropOffStops];

                            const updatedDropOffRecord = {
                                ...existingDropOffList[0],
                                commodities: [...dropOffStopCommodities]
                            };
                            const updatedDropOffRecords = [
                                ...existingDropOffList.slice(0, 0), // everything before current obj
                                updatedDropOffRecord,
                                ...existingDropOffList.slice(1), // everything after current obj
                            ];

                            setDropOffStops([...updatedDropOffRecords]);
                        }
                    }
                }
            } else if (selectedRecord.stopType === 'DROP_OFF') {
                let existingList = [...dropOffStops];
                const index = existingList.findIndex(obj => obj.id === selectedRecord.id);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        commodities: [...stopCommodities]
                    };
                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    setDropOffStops([...updatedRecords]);
                }
            }

            toggleAddStopCommodity();
            setSelectedRecord(null);
        } else {
            return;
        }
    };

    const onRemoveStopCommodity = (stop, index) => {
        if (isObjectNotEmpty(stop)) {
            let stopCommodities = [];
            if (isListNotEmpty(stop.commodities)) {
                stopCommodities = [...stop.commodities];
                stopCommodities.splice(index, 1);
            }
            if (stop.stopType === 'PICK_UP') {
                let existingList = [...pickUpStops];
                const index = existingList.findIndex(obj => obj.id === stop.id);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        commodities: [...stopCommodities]
                    };

                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    setPickUpStops([...updatedRecords]);

                    if (dropOffStops.length === 1) {
                        // if there is only one drop-off stop, set the value for the commodities
                        let updatedPickUpRecords = [...updatedRecords];
                        if (isListNotEmpty(updatedPickUpRecords)) {
                            let dropOffStopCommodities = [];
                            if (updatedPickUpRecords.length === 1) {
                                if (isListNotEmpty(updatedPickUpRecords[0].commodities)) {
                                    dropOffStopCommodities = updatedPickUpRecords[0].commodities;
                                }
                            } else if (updatedPickUpRecords.length > 1) {
                                updatedPickUpRecords.forEach((pickUpStop) => {
                                    if (isListNotEmpty(pickUpStop.commodities)) {
                                        pickUpStop.commodities.forEach((stopCommodity) => {
                                            let existingDropOffStopCommodity = dropOffStopCommodities.find(c => c.commodityId === stopCommodity.commodityId && c.unitPackaging === stopCommodity.unitPackaging && c.bulkPackaging === stopCommodity.bulkPackaging);
                                            if (isObjectEmpty(existingDropOffStopCommodity)) {
                                                dropOffStopCommodities.push(stopCommodity);
                                            } else {
                                                const existingDropOffStopCommodityIndex = dropOffStopCommodities.findIndex(c => c.commodityId === stopCommodity.commodityId && c.unitPackaging === stopCommodity.unitPackaging && c.bulkPackaging === stopCommodity.bulkPackaging);
                                                // only update if the record exists in the list
                                                if (existingDropOffStopCommodityIndex !== -1) {
                                                    const updatedExistingDropOffStopCommodity = {
                                                        ...dropOffStopCommodities[existingDropOffStopCommodityIndex]
                                                    };

                                                    if (isStringNotEmpty(existingDropOffStopCommodity.unitPackaging) && existingDropOffStopCommodity.unitPackaging === stopCommodity.unitPackaging) {
                                                        updatedExistingDropOffStopCommodity.unitPackagingInitCount = updatedExistingDropOffStopCommodity.unitPackagingInitCount + stopCommodity.unitPackagingInitCount;
                                                    }
                                                    if (isStringNotEmpty(existingDropOffStopCommodity.bulkPackaging) && existingDropOffStopCommodity.bulkPackaging === stopCommodity.bulkPackaging) {
                                                        updatedExistingDropOffStopCommodity.bulkPackagingInitCount = updatedExistingDropOffStopCommodity.bulkPackagingInitCount + stopCommodity.bulkPackagingInitCount;
                                                    }

                                                    const updatedExistingDropOffStopCommodities = [
                                                        ...dropOffStopCommodities.slice(0, existingDropOffStopCommodityIndex), // everything before current obj
                                                        updatedExistingDropOffStopCommodity,
                                                        ...dropOffStopCommodities.slice(existingDropOffStopCommodityIndex + 1), // everything after current obj
                                                    ];

                                                    dropOffStopCommodities = [...updatedExistingDropOffStopCommodities];
                                                }
                                            }
                                        });
                                    }
                                });
                            }

                            let existingDropOffList = [...dropOffStops];

                            const updatedDropOffRecord = {
                                ...existingDropOffList[0],
                                commodities: [...dropOffStopCommodities]
                            };
                            const updatedDropOffRecords = [
                                ...existingDropOffList.slice(0, 0), // everything before current obj
                                updatedDropOffRecord,
                                ...existingDropOffList.slice(1), // everything after current obj
                            ];

                            setDropOffStops([...updatedDropOffRecords]);
                        }
                    }
                }
            } else if (stop.stopType === 'DROP_OFF') {
                let existingList = [...dropOffStops];
                const index = existingList.findIndex(obj => obj.id === stop.id);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        commodities: [...stopCommodities]
                    };
                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    setDropOffStops([...updatedRecords]);
                }
            }
        } else {
            return;
        }
    };

    const onSubmit = (data) => {
        if (isStringNotEmpty(accountId) && isStringNotEmpty(shipperId)) {
            let payload = {
                ...data,
                accountId: accountId,
                linkedAccountId: shipperId
            };

            if (isListNotEmpty(pickUpStops) && isListNotEmpty(dropOffStops)) {
                methods.clearErrors('pickUpStops');
                methods.clearErrors('dropOffStops');

                let loadLaneLocations = [];

                pickUpStops.forEach((stop, stopIndex, arr) => {
                    let newStop = {
                        locationId: stop.locationId,
                        sequence: stopIndex,
                        stopType: 'PICK_UP',
                        loadingType: stop.loadingType
                    };

                    if (isListNotEmpty(stop.commodities)) {
                        let newCommodities = [];
                        stop.commodities.forEach((commodity) => {
                            // only want to add commodities to the stop if there are quantities provided for that commodity
                            if (isNumberNotEmpty(commodity.unitPackagingInitCount)) {
                                let newCommodity = {
                                    commodityId: commodity.commodityId,
                                    name: commodity.name,
                                    unitPackaging: commodity.unitPackaging,
                                    unitPackagingInitCount: Number(commodity.unitPackagingInitCount)
                                };

                                if (isStringNotEmpty(commodity.bulkPackaging)) {
                                    newCommodity.bulkPackaging = commodity.bulkPackaging;
                                    newCommodity.bulkPackagingInitCount = Number(commodity.bulkPackagingInitCount);
                                }
                                newCommodities.push(newCommodity);
                            }
                        });

                        if (isListNotEmpty(newCommodities)) {
                            newStop.commodities = newCommodities;
                        }
                    }

                    loadLaneLocations.push({ ...newStop });
                });

                dropOffStops.forEach((stop, stopIndex, arr) => {
                    let newStop = {
                        locationId: stop.locationId,
                        sequence: stopIndex + pickUpStops.length,
                        stopType: 'DROP_OFF',
                        loadingType: stop.loadingType
                    };

                    if (isListNotEmpty(stop.commodities)) {
                        let newCommodities = [];
                        stop.commodities.forEach((commodity) => {
                            // only want to add commodities to the stop if there are quantities provided for that commodity
                            if (isNumberNotEmpty(commodity.unitPackagingInitCount)) {
                                let newCommodity = {
                                    commodityId: commodity.commodityId,
                                    name: commodity.name,
                                    unitPackaging: commodity.unitPackaging,
                                    unitPackagingInitCount: Number(commodity.unitPackagingInitCount)
                                };

                                if (isStringNotEmpty(commodity.bulkPackaging)) {
                                    newCommodity.bulkPackaging = commodity.bulkPackaging;
                                    newCommodity.bulkPackagingInitCount = Number(commodity.bulkPackagingInitCount);
                                }
                                newCommodities.push(newCommodity);
                            }
                        });

                        if (isListNotEmpty(newCommodities)) {
                            newStop.commodities = newCommodities;
                        }
                    }

                    loadLaneLocations.push({ ...newStop });
                });

                payload.locations = loadLaneLocations;

                if (isObjectNotEmpty(data.equipmentNeeds)) {
                    payload.equipmentNeeds = {};

                    if (isStringNotEmpty(data.equipmentNeeds.trailerTypeId)) {
                        payload.equipmentNeeds.trailerTypeId = data.equipmentNeeds.trailerTypeId;
                    }

                    if (isNumberNotEmpty(data.equipmentNeeds.trailerLength)) {
                        payload.equipmentNeeds.trailerLength = Number(data.equipmentNeeds.trailerLength);
                        payload.equipmentNeeds.trailerLengthUnit = "FT";
                    }

                    if (isNumberNotEmpty(data.equipmentNeeds.trailerWeightCapacity)) {
                        payload.equipmentNeeds.trailerWeightCapacity = Number(data.equipmentNeeds.trailerWeightCapacity);
                        payload.equipmentNeeds.trailerWeightCapacityUnit = "LB";
                    }

                    if (isNumberNotEmpty(data.equipmentNeeds.preCoolReefer)) {
                        payload.equipmentNeeds.preCoolReefer = Number(data.equipmentNeeds.preCoolReefer);
                        payload.equipmentNeeds.preCoolReeferUnit = "F";
                    }

                    if (isNumberNotEmpty(data.equipmentNeeds.minRunTemp)) {
                        payload.equipmentNeeds.minRunTemp = Number(data.equipmentNeeds.minRunTemp);
                        payload.equipmentNeeds.minRunTempUnit = "F";
                    }

                    if (isNumberNotEmpty(data.equipmentNeeds.maxRunTemp)) {
                        payload.equipmentNeeds.maxRunTemp = Number(data.equipmentNeeds.maxRunTemp);
                        payload.equipmentNeeds.maxRunTempUnit = "F";
                    }
                }

                if (isListNotEmpty(data.driverRequirements)) {
                    payload.driverRequirements = data.driverRequirements;
                }

                if (isListNotEmpty(data.equipmentSpecifics)) {
                    payload.equipmentSpecifics = data.equipmentSpecifics;
                }

                if (isNumberNotEmpty(data.approxDistance)) {
                    payload.approxDistance = data.approxDistance;
                    payload.approxDistanceUnit = 'MI';
                }

                if (isNumberNotEmpty(data.approxTransitTime)) {
                    payload.approxTransitTime = data.approxTransitTime;
                    payload.approxTransitTimeUnit = 'MIN';
                }

                if (isNumberNotEmpty(data.perMileRate)) {
                    payload.perMileRate = Number(data.perMileRate);
                    if (isStringNotEmpty(data.perMileRateUnit)) {
                        payload.perMileRateUnit = data.perMileRateUnit;
                    } else {
                        payload.perMileRateUnit = 'USD';
                    }
                }

                if (isNumberNotEmpty(data.contractRate)) {
                    payload.contractRate = Number(data.contractRate);
                    if (isStringNotEmpty(data.contractRateUnit)) {
                        payload.contractRateUnit = data.contractRateUnit;
                    } else {
                        payload.contractRateUnit = 'USD';
                    }
                }

                // console.log(payload);

                if (isObjectNotEmpty(payload)) {
                    dispatch(actionCreators.addLinkedLoadLane(payload));
                }
            } else {
                if (isListEmpty(pickUpStops)) {
                    methods.setError('pickUpStops', { type: "required", message: 'There must be at least 1 Pick-Up Stop' });
                }
                if (isListEmpty(dropOffStops)) {
                    methods.setError('dropOffStops', { type: "required", message: 'There must be at least 1 Drop-Off Stop' });
                }

                return;
            }
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.addLoadLaneCancel());
        cancel();
    };

    //#endregion
    //#region useEffects and useMemos

    useMemo(() => {
        if (isStringNotEmpty(linkedAccountId)) {
            setShipperId(linkedAccountId);
        }
    }, [linkedAccountId]);

    useMemo(() => {
        if (isStringNotEmpty(accountId)) {
            dispatch(actionCreators.getLinkedAccounts(accountId, false));
        }
    }, [accountId]);

    useMemo(() => {
        if (isStringNotEmpty(shipperId)) {
            if (entityType === 'STAFF') {
                dispatch(actionCreators.getLocations(shipperId, false));
            } else if (entityType === 'SHIPPER' || entityType === 'CARRIER' || entityType === 'PRODUCER' || entityType === 'RECEIVER' || entityType === 'BROKER') {
                dispatch(actionCreators.getLocations(isStringNotEmpty(accountId) ? accountId : entityId, false));
            }
        }
    }, [shipperId, entityType, entityId, accountId]);

    useMemo(() => {
        if (isNotNullOrUndefined(locations) && isStringNotEmpty(shipperId)) {
            let transformedLocations = [];
            if (isListNotEmpty(locations)) {
                locations.filter(l => l.linkedAccountId === undefined || l.linkedAccountId === null).forEach((item) => {
                    if (transformedLocations.find(l => l.id === item.id) === undefined) {
                        transformedLocations.push({
                            ...item,
                            accountName: isObjectNotEmpty(item.account) && isStringNotEmpty(item.account.name) ? item.account.name : ''
                        });
                    }
                });

                locations.filter(l => l.linkedAccountId !== undefined && l.linkedAccountId !== null).forEach((item) => {
                    if (transformedLocations.find(l => l.id === item.id) === undefined) {
                        if (isObjectNotEmpty(item.linkedAccount) && (item.linkedAccount.entityTypes.includes("SHIPPER") === false || (item.linkedAccount.entityTypes.includes("SHIPPER") === true && item.linkedAccount.id === shipperId))) {
                            transformedLocations.push({
                                ...item,
                                accountName: isObjectNotEmpty(item.linkedAccount) && isStringNotEmpty(item.linkedAccount.name) ? item.linkedAccount.name : ''
                            });
                        }
                    }
                });
            }

            setLocationsList(transformedLocations);
        }
    }, [locations, shipperId]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (isStringNotEmpty(shipperId)) {
                setPickUpsEnabled(true);
            } else {
                setPickUpsEnabled(false);
            }
        }

        return () => isSubscribed = false;
    }, [shipperId]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (showEditStopLocation === true && isObjectNotEmpty(selectedRecord)) {
                if (isStringNotEmpty(selectedRecord.locationId)) {
                    methods2.setValue('locationId', selectedRecord.locationId);
                }
                if (isStringNotEmpty(selectedRecord.loadingType)) {
                    methods2.setValue('loadingType', selectedRecord.loadingType);
                }
            }
        }

        return () => isSubscribed = false;
    }, [showEditStopLocation, selectedRecord]);

    useEffect(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.addLoadLaneErrorClear());

        methods.clearErrors();

        dispatch(actionCreators.getCommodities());
        dispatch(actionCreators.getLineItemTypes());
        dispatch(actionCreators.getTrailerTypes());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            onCancel();
        }
    }, [isLoading, error]);

    //#endregion
    //#region displays

    const getLocationOptions = (stop = null) => {
        let options = [];
        if (isListNotEmpty(locationsList)) {
            // Don't filter out already used locations since some loads can have the same location 
            // for pick-up and drop-off in the bulk space where the sites are very large
            let orderedLocationsList = orderBy(locationsList, ['accountName', 'name', 'streetAddress1'], ['asc', 'asc', 'asc']);

            orderedLocationsList.forEach((item) => {
                options.push(<Option value={item.id} key={item.key}>{`${isStringNotEmpty(item.accountName) ? item.accountName + ' - ' : ''}${isStringNotEmpty(item.name) ? item.name : ''}${isObjectNotEmpty(item.address) ? ' - ' + stringFormatter.toFormattedAddress(item.address) : ''}`}</Option>);
            });
        }

        return options;
    };

    const getCommodityOptions = (stop) => {
        let options = [];
        if (isObjectNotEmpty(stop) && isListNotEmpty(commodities)) {
            let availableCommodities = [];
            // filter the commodities by what is set on the location if any are set
            if (isObjectNotEmpty(stop.location) && isListNotEmpty(stop.location.commodityIds)) {
                let availableStopCommodities = commodities.filter(c => c.isDeleted === false && stop.location.commodityIds.includes(c.id));
                if (isListNotEmpty(availableStopCommodities)) {
                    availableCommodities = availableStopCommodities;
                } else {
                    availableCommodities = commodities;
                }
            } else {
                availableCommodities = commodities;
            }
            if (stop.stopType === 'PICK_UP') {
                // if pick-up, don't show commodities that have already been added to this stop
                let usedCommodityIds = [];
                if (isListNotEmpty(stop.commodities)) {
                    stop.commodities.forEach((stopCommodity) => {
                        usedCommodityIds.push(stopCommodity.commodityId);
                    });
                }

                availableCommodities.forEach((commodity) => {
                    if (!usedCommodityIds.includes(commodity.id)) {
                        options.push(<Option value={commodity.id} key={commodity.id}>{commodity.name}</Option>);
                    }
                });
            } else if (stop.stopType === 'DROP_OFF') {
                // only show commodities that the pick-up stops have
                let usedCommodityIds = [];
                pickUpStops.forEach((pickUpStop) => {
                    if (isListNotEmpty(pickUpStop.commodities)) {
                        pickUpStop.commodities.forEach((stopCommodity) => {
                            if (!usedCommodityIds.includes(stopCommodity.commodityId)) {
                                usedCommodityIds.push(stopCommodity.commodityId);
                            }
                        });
                    }
                });

                availableCommodities.forEach((commodity) => {
                    if (usedCommodityIds.includes(commodity.id)) {
                        options.push(<Option value={commodity.id} key={commodity.id}>{commodity.name}</Option>);
                    }
                });
            }
        }

        return options;
    };

    const getLaneStopLocationDisplay = (stop, stopIndex) => {
        if (isObjectNotEmpty(stop)) {
            const stopName = LaneUtils.getLaneStopLocationNameDisplay(stop, shipperId, accountId, entityType, entityId);
            const stopAddress = LaneUtils.getLaneStopAddressDisplay(stop);

            return (
                <DataCollapsableRow
                    isDisabled={(((stop.stopType === 'DROP_OFF' && dropOffsEnabled === false) || (stop.stopType === 'PICK_UP' && pickUpsEnabled === false)) ? true : false)}
                    isCollapsed={isStringNotEmpty(stop.locationId) ? false : true}
                    title={isStringNotEmpty(stopName) ? stopName : (<Button style={{ paddingLeft: 0, fontSize: 18, fontWeight: 500, color: '#000000' }} type='link' disabled={(((stop.stopType === 'DROP_OFF' && dropOffsEnabled === false) || (stop.stopType === 'PICK_UP' && pickUpsEnabled === false)) ? true : false)} onClick={() => { setSelectedRecord(stop); toggleEditStopLocation(); }}>Select a {stop.stopType === "PICK_UP" ? "Pick-Up" : "Drop-Off"} Location <DownOutlined /></Button>)}
                    titleStyle={{ paddingLeft: 0, fontSize: 18, fontWeight: 500, color: '#000000' }}
                    titleHasAction={!(isObjectNotEmpty(stop.location) && isStringNotEmpty(stop.location.name))}
                    subtitle={isStringNotEmpty(stopAddress) ? (<span style={{ fontSize: 14 }}>{stopAddress}</span>) : null}
                    header={isStringNotEmpty(stop.stopType) ? (
                        <span style={{ fontSize: 14, textTransform: 'uppercase', color: (((stop.stopType === 'DROP_OFF' && dropOffsEnabled === false) || (stop.stopType === 'PICK_UP' && pickUpsEnabled === false)) ? '#000000' : '#A9A9A9') }}>{`${Enums.StopTypes.getValueByName(stop.stopType)} ${stopIndex + 1}`}</span>
                    ) : null}
                    extra={isStringNotEmpty(stop.locationId) ? (
                        <>
                            <Button style={{ fontWeight: 'bold' }} type='link' onClick={() => { setSelectedRecord(stop); toggleEditStopLocation(); }}>edit</Button>
                            {(stop.stopType === 'PICK_UP' && pickUpStops.length > 1) ? (
                                <Button style={{ fontWeight: 'bold' }}
                                    type="link"
                                    onClick={() => {
                                        let existingList = [...pickUpStops];
                                        existingList.splice(stopIndex, 1);
                                        setPickUpStops([...existingList]);
                                    }}
                                >
                                    remove
                                </Button>
                            ) : null}
                            {(stop.stopType === 'DROP_OFF' && dropOffStops.length > 1) ? (
                                <Button style={{ fontWeight: 'bold' }}
                                    type="link"
                                    onClick={() => {
                                        let existingList = [...dropOffStops];
                                        existingList.splice(stopIndex, 1);
                                        setDropOffStops([...existingList]);
                                    }}
                                >
                                    remove
                                </Button>
                            ) : null}
                        </>
                    ) : null}
                >
                    <div>
                        {isStringNotEmpty(stop.loadingType) ? (
                            <Row gutter={[18, 18]} style={{ marginBottom: 18 }}>
                                <Col span={24}>
                                    <div><span style={{ fontSize: 14, color: '#000000' }}>{stop.stopType === 'PICK_UP' ? 'Loading Type' : 'Unloading Type'}</span><Button style={{ fontWeight: 'bold' }} type="link" onClick={() => { setSelectedRecord(stop); toggleEditStopLocation(); }}>edit</Button></div>
                                    <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>{stringFormatter.toFormattedString(stop.stopType === 'PICK_UP' ? 'LoadingType' : 'UnloadingType', stop.loadingType, null)}</span></div>
                                </Col>
                            </Row>
                        ) : (
                            <Row gutter={[18, 18]} style={{ marginBottom: 18 }}>
                                <Col span={24}>
                                    <Button style={{ marginLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} onClick={() => { setSelectedRecord(stop); toggleEditStopLocation(); }}>{stop.stopType === 'PICK_UP' ? 'Loading Specifics (optional)' : (stop.stopType === 'DROP_OFF' ? 'Unloading Specifics (optional)' : 'Loading/Unloading Specifics (optional)')}</Button>
                                </Col>
                            </Row>
                        )}
                        {isListNotEmpty(stop.commodities) ? (
                            <>
                                <Row gutter={[18, 18]}>
                                    <Col span={24}>
                                        <div><span style={{ fontSize: 14, color: '#000000' }}>Commodities</span></div>
                                        {stop.commodities.map((stopCommodity, index) => {
                                            //console.log(stopCommodity);

                                            return (
                                                <Row gutter={[18, 18]} key={`stop-${stopIndex}-stopCommodity-${index}`}>
                                                    <Col span={24}>
                                                        <span style={{ fontSize: 14, color: '#A9A9A9', paddingLeft: 8 }}>{stopCommodity.name}: {isNumberNotEmpty(stopCommodity.unitPackagingInitCount) ? `${stopCommodity.unitPackagingInitCount} ${Enums.UnitPackaging.getValueByName(stopCommodity.unitPackaging)}` : ''}{isNumberNotEmpty(stopCommodity.bulkPackagingInitCount) ? `, ${stopCommodity.bulkPackagingInitCount} ${Enums.BulkPackaging.getValueByName(stopCommodity.bulkPackaging)}` : ''}</span>
                                                        {((stop.stopType === 'PICK_UP') || (dropOffStops.length > 1)) ? (
                                                            <Button type="default" style={{ marginLeft: 8 }} icon={<DeleteOutlined />} onClick={() => { onRemoveStopCommodity(stop, index); }} />
                                                        ) : null}
                                                    </Col>
                                                </Row>
                                            );
                                        })}
                                    </Col>
                                </Row>

                                {((stop.stopType === 'PICK_UP') || (dropOffStops.length > 1)) ? (
                                    <Row gutter={[18, 18]}>
                                        <Col span={24}>
                                            <Button style={{ marginLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} onClick={() => { setSelectedRecord(stop); toggleAddStopCommodity(); }}>Additional Default Commodity (optional)</Button>
                                        </Col>
                                    </Row>
                                ) : null}
                            </>
                        ) : (((stop.stopType === 'PICK_UP') || (dropOffStops.length > 1)) ? (
                            <Row gutter={[18, 18]}>
                                <Col span={24}>
                                    <Button style={{ marginLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} onClick={() => { setSelectedRecord(stop); toggleAddStopCommodity(); }}>Default Commodity (optional)</Button>
                                </Col>
                            </Row>
                        ) : null)}
                    </div>
                </DataCollapsableRow>
            );
        } else {
            return null;
        }
    };

    const getStopDetails = (stop) => {
        if (isObjectNotEmpty(stop)) {
            const stopIcon = stop.stopType === 'PICK_UP' ? pickUpMarker : (stop.stopType === 'DROP_OFF' ? dropOffMarker : null);

            return (
                <div>
                    <Row style={{ marginTop: 36, marginBottom: 36 }}>
                        <Col span={24}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: 30, marginRight: 12 }}>
                                    {isNotNullOrUndefined(stopIcon) ? (<img src={stopIcon} alt={LaneUtils.getLaneStopTypeDisplay(stop)} style={{ width: 30, height: 30 }} />) : null}
                                </div>
                                <div style={{ flex: 1 }}>
                                    <span style={{ fontWeight: 'bold' }}>{LaneUtils.getLaneStopLocationNameDisplay(stop, shipperId, accountId, entityType, entityId)}</span><br />
                                    <span>{LaneUtils.getLaneStopLocationCityStateDisplay(stop)}</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            );
        } else {
            return null;
        }
    };

    const getError = (errors, name) => {
        if (name !== undefined) {
            let obj = errors;

            name = name.split('.');
            let len = name.length;
            for (let i = 0; i < len - 1; i++) {
                if (obj[name[i]] !== undefined) {
                    //console.log(obj[name[i]]);
                    obj = obj[name[i]];
                }
            }

            return obj[name[len - 1]];
        } else {
            return undefined;
        }
    };

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const formItemLayoutDouble = {
        labelCol1: { span: 24 },
        wrapperCol1: { span: 12 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 12 },
    };

    const formItemLayoutDouble2 = {
        labelCol1: { span: 12 },
        wrapperCol1: { span: 12 },
        labelCol2: { span: 12 },
        wrapperCol2: { span: 12 },
    };

    const radioStyle = {
        display: 'block',
        height: '60px',
        lineHeight: '30px',
    };

    //#endregion

    return (
        <>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                        {isStringEmpty(linkedAccountId) ? (
                            <Fieldset legend="Choose a Shipper">
                                <FormItem {...formItemLayout} label="Shipper" required
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Select
                                            placeholder="Please Select a Shipper"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChangeLinkedAccountId(selected); onChange(selected); }}
                                            value={value}
                                            name={name}
                                            showSearch={true}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            notFoundContent={
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Shipper Accounts for you to choose from. You cannot create a lane without assigning it to a Shipper account. Please add Shipper accounts first.">
                                                    {(entityType === 'CARRIER' || entityType === 'BROKER') ? (
                                                        <Button type="primary" onClick={() => { props.history.push({ pathname: `/linkedShippers/` }); }}>Setup Shipper Accounts</Button>
                                                    ) : null}
                                                </Empty>
                                            }
                                            options={linkedAccounts.filter(a => a.entityTypes.includes("SHIPPER") === true).map(s => { return { label: s.name ? s.name : (s.linkedAccount && s.linkedAccount.name ? s.linkedAccount.name : 'N/A'), value: s.linkedAccountId } })}
                                            ref={ref}
                                        />
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="linkedAccountId"
                                />
                            </Fieldset>
                        ) : null}
                        <Fieldset legend="Route">
                            <MapWrapper
                                height={'200px'}
                                key={`load-lane-creation-map-key`}
                                loadId={'new-lane'}
                                stops={isListNotEmpty(pickUpStops) && isListNotEmpty(dropOffStops) ? [...pickUpStops, ...dropOffStops] : []}
                                containerName={`load-lane-creation-map`}
                                style={{ width: "100%", height: "200px", marginBottom: 20 }}
                                setRouteDistance={setRouteMiles}
                                setRouteDuration={setRouteDuration}
                            />
                            <Timeline style={{ padding: '10px 24px' }}>
                                {isListNotEmpty(pickUpStops) ? (
                                    <>
                                        {pickUpStops.map((stop, index, arr) => {
                                            return (
                                                <Timeline.Item className="customTimelineItem" key={`pickUp-${index}`} color={pickUpsEnabled === true ? '#23BEBB' : '#A9A9A9'}>
                                                    <div style={{ paddingLeft: 50 }}>
                                                        <div style={pickUpsEnabled === true ? null : { zIndex: 500, backgroundColor: 'white', opacity: 0.3 }}>
                                                            {getLaneStopLocationDisplay(stop, index)}
                                                            {getError(methods.errors, `pickUpStops-${index}`) !== undefined && (
                                                                <ErrorMessage
                                                                    errors={methods.errors}
                                                                    name={`pickUpStops-${index}`}
                                                                    render={({ message, messages }) => {
                                                                        if (isNotNullOrUndefined(messages)) {
                                                                            return Object.entries(messages).map(([type, message]) => (<Alert key={`pickUpStops-${index}-${type}`} message={message} type="error" />));
                                                                        } else if (isStringNotEmpty(message)) {
                                                                            return <Alert message={message} type="error" />;
                                                                        } else {
                                                                            return null;
                                                                        }
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                        {(dropOffsEnabled === false && (stop.stopTypeSequence + 1 === pickUpStops.length)) ? (
                                                            <div style={{ marginTop: 18 }}>
                                                                <Button style={{ paddingLeft: 0, fontWeight: 'bold' }} type="link" icon={<RightOutlined />} disabled={!(stop.location && pickUpsEnabled)} onClick={() => { setDropOffsEnabled(true); }}>Continue to Drop-Off</Button>
                                                            </div>
                                                        ) : null}
                                                        {(stop.stopTypeSequence + 1 === pickUpStops.length) ? (
                                                            <div style={{ marginTop: 18 }}>
                                                                <Button style={{ paddingLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} disabled={!(stop.location && pickUpsEnabled)} onClick={() => { toggleNewPickUpStopLocation(); }}>Add Another Pick-Up</Button>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Timeline.Item>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <Timeline.Item className="customTimelineItem" key={`pickUp`} color={pickUpsEnabled === true ? '#23BEBB' : '#A9A9A9'}>
                                        <div style={{ paddingLeft: 50 }}>
                                            <div style={{ marginTop: 18 }}>
                                                <Button style={{ paddingLeft: 0, fontWeight: 'bold' }} disabled={!pickUpsEnabled} type="link" icon={<PlusOutlined />} onClick={() => { toggleNewPickUpStopLocation(); }}>Add a Pick-Up</Button>
                                            </div>
                                            {getError(methods.errors, `pickUpStops`) !== undefined && (
                                                <ErrorMessage
                                                    errors={methods.errors}
                                                    name={`pickUpStops`}
                                                    render={({ message, messages }) => {
                                                        if (isNotNullOrUndefined(messages)) {
                                                            return Object.entries(messages).map(([type, message]) => (<Alert key={`pickUpStops-${type}`} message={message} type="error" />));
                                                        } else if (isStringNotEmpty(message)) {
                                                            return <Alert message={message} type="error" />;
                                                        } else {
                                                            return null;
                                                        }
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </Timeline.Item>
                                )}
                                {isListNotEmpty(dropOffStops) ? (
                                    <>
                                        {dropOffStops.map((stop, index, arr) => {
                                            return (
                                                <Timeline.Item className="customTimelineItem" key={`dropOff-${index}`} color={dropOffsEnabled === true ? '#23BEBB' : '#A9A9A9'}>
                                                    <div style={{ paddingLeft: 50 }}>
                                                        <div style={dropOffsEnabled === true ? null : { zIndex: 500, backgroundColor: 'white', opacity: 0.3 }}>
                                                            {getLaneStopLocationDisplay(stop, index)}
                                                            {getError(methods.errors, `dropOffStops-${index}`) !== undefined && (
                                                                <ErrorMessage
                                                                    errors={methods.errors}
                                                                    name={`dropOffStops-${index}`}
                                                                    render={({ message, messages }) => {
                                                                        if (isNotNullOrUndefined(messages)) {
                                                                            return Object.entries(messages).map(([type, message]) => (<Alert key={`dropOffStops-${index}-${type}`} message={message} type="error" />));
                                                                        } else if (isStringNotEmpty(message)) {
                                                                            return <Alert message={message} type="error" />;
                                                                        } else {
                                                                            return null;
                                                                        }
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                        {(stop.stopTypeSequence + 1 === dropOffStops.length) ? (
                                                            <div style={{ marginTop: 18 }}>
                                                                <Button style={{ paddingLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} disabled={!(stop.location && dropOffsEnabled)} onClick={() => { toggleNewDropOffStopLocation(); }}>Add Another Drop-Off</Button>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Timeline.Item>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <Timeline.Item className="customTimelineItem" key={`dropOff`} color={dropOffsEnabled === true ? '#23BEBB' : '#A9A9A9'}>
                                        <div style={{ paddingLeft: 50 }}>
                                            <div style={{ marginTop: 18 }}>
                                                <Button style={{ paddingLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} disabled={!dropOffsEnabled} onClick={() => { toggleNewDropOffStopLocation(); }}>Add a Drop-Off</Button>
                                            </div>
                                            {getError(methods.errors, `dropOffStops`) !== undefined && (
                                                <ErrorMessage
                                                    errors={methods.errors}
                                                    name={`dropOffStops`}
                                                    render={({ message, messages }) => {
                                                        if (isNotNullOrUndefined(messages)) {
                                                            return Object.entries(messages).map(([type, message]) => (<Alert key={`dropOffStops-${type}`} message={message} type="error" />));
                                                        } else if (isStringNotEmpty(message)) {
                                                            return <Alert message={message} type="error" />;
                                                        } else {
                                                            return null;
                                                        }
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </Timeline.Item>
                                )}
                            </Timeline>
                        </Fieldset>
                        <Fieldset legend="Lane Details (* indicates a required field)">
                            <FormItem {...formItemLayout} label="Customer Lane Id"
                                render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Customer Lane Id" ref={ref} />}
                                rules={{ required: false }}
                                name="customerLaneId"
                            />
                            <FormItemDouble {...formItemLayoutDouble} label1="Contract Rate"
                                render1={({ onChange, onBlur, value, name, ref }) => (
                                    <InputNumber
                                        min={0}
                                        precision={2}
                                        placeholder="Contract Rate"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    />
                                )}
                                rules1={{ required: false }}
                                name1="contractRate"
                                render2={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please Select"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    >
                                        {currencyOptions}
                                    </Select>
                                )}
                                rules2={{ required: false }}
                                name2="contractRateUnit"
                                defaultValue2={'USD'}
                            />
                            <FormItemDouble {...formItemLayoutDouble} label1="Per Mile Rate"
                                render1={({ onChange, onBlur, value, name, ref }) => (
                                    <InputNumber
                                        min={0}
                                        precision={2}
                                        placeholder="Per Mile Rate"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    />
                                )}
                                rules1={{ required: false }}
                                name1="perMileRate"
                                render2={({ onChange, onBlur, value, name, ref }) => (
                                    <Select
                                        placeholder="Please Select"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    >
                                        {currencyOptions}
                                    </Select>
                                )}
                                rules2={{ required: false }}
                                name2="perMileRateUnit"
                                defaultValue2={'USD'}
                            />
                            <FormItem {...formItemLayout} label="Practical Route Miles" required
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <InputNumber
                                        min={0}
                                        precision={2}
                                        placeholder="Practical Route Miles"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                        disabled={true}
                                        ref={ref}
                                    />
                                )}
                                rules={{ required: 'Required Field' }}
                                name="approxDistance"
                                defaultValue={practicalRouteMiles}
                            />
                            <FormItem {...formItemLayout} label="Approximate Transit Time (in minutes)" required
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <InputNumber
                                        min={0}
                                        precision={2}
                                        placeholder="Approximate Transit Time"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                        disabled={true}
                                        ref={ref}
                                    />
                                )}
                                rules={{ required: 'Required Field' }}
                                name="approxTransitTime"
                                defaultValue={practicalRouteMinutes}
                            />
                        </Fieldset>
                        <Fieldset legend="Equipment Needs and Services (* indicates a required field)">
                            <Row gutter={[4, 4]}>
                                <Col span={8}>
                                    <FormItem {...formItemLayout} label="Trailer Type" required format="vertical"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Select
                                                placeholder="Please Select a Trailer Type"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChangeTrailerTypeId(selected); onChange(selected); }}
                                                value={value}
                                                name={name}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                ref={ref}
                                            >
                                                {trailerTypes.map(i => <Option value={i.id} key={i.id}>{i.description}</Option>)}
                                            </Select>
                                        )}
                                        rules={{ required: 'Required Field' }}
                                        name='equipmentNeeds.trailerTypeId'
                                    />
                                </Col>
                                <Col span={8}>
                                    <FormItem {...formItemLayout} label="Trailer Length" required format="vertical"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <InputNumber
                                                step={1}
                                                min={0}
                                                precision={0}
                                                placeholder="Trailer Length (ft)"
                                                style={{ width: '100%' }}
                                                onBlur={onBlur}
                                                onChange={e => { onChange(e); }}
                                                value={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        rules={{ required: 'Required Field' }}
                                        name='equipmentNeeds.trailerLength'
                                    />
                                </Col>
                                <Col span={8}>
                                    <FormItem {...formItemLayout} label="Load Weight" required format="vertical"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <InputNumber
                                                step={1000}
                                                min={1000}
                                                precision={0}
                                                placeholder="Load Weight (lbs)"
                                                style={{ width: '100%' }}
                                                onBlur={onBlur}
                                                onChange={e => { onChange(e); }}
                                                value={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        rules={{ required: 'Required Field' }}
                                        name='equipmentNeeds.trailerWeightCapacity'
                                    />
                                </Col>
                                {showReeferOptions === true ? (
                                    <>
                                        <Col span={24}>
                                            <span style={{ fontWeight: 'bold' }}>Temperature Requirements</span>
                                        </Col>
                                        <Col span={8}>
                                            <FormItem {...formItemLayout} label="Pre-Cool Temp" required format="vertical"
                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                    <InputNumber
                                                        placeholder="Pre-Cool Temp"
                                                        style={{ width: '100%' }}
                                                        onBlur={onBlur}
                                                        onChange={e => { preCoolReefer.current = e; onChange(e); }}
                                                        value={value}
                                                        name={name}
                                                        ref={ref}
                                                    />
                                                )}
                                                rules={{
                                                    required: 'Required Field',
                                                    validate: {
                                                        gteMin: value => (minRunTemp.current ? value >= minRunTemp.current : true) || 'Pre-Cool Temp must be greater than or equal to the Min Run Temp.',
                                                        lteMax: value => (maxRunTemp.current ? value <= maxRunTemp.current : true) || 'Pre-Cool Temp must be less than or equal to Max Run Temp.'
                                                    }
                                                }}
                                                name='equipmentNeeds.preCoolReefer'
                                            />
                                        </Col>
                                        <Col span={8}>
                                            <FormItem {...formItemLayout} label="Min Run Temp" required format="vertical"
                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                    <InputNumber
                                                        placeholder="Min Run Temp"
                                                        style={{ width: '100%' }}
                                                        onBlur={onBlur}
                                                        onChange={e => { minRunTemp.current = e; onChange(e); }}
                                                        value={value}
                                                        name={name}
                                                        ref={ref}
                                                    />
                                                )}
                                                rules={{
                                                    required: 'Required Field',
                                                    validate: {
                                                        lte: value => (preCoolReefer.current && maxRunTemp.current ? value <= preCoolReefer.current && value <= maxRunTemp.current : true) || 'Min Run Temp must be less than or equal to the Pre-Cool Temp and Max Run Temp'
                                                    }
                                                }}
                                                name='equipmentNeeds.minRunTemp'
                                            />
                                        </Col>
                                        <Col span={8}>
                                            <FormItem {...formItemLayout} label="Max Run Temp" required format="vertical"
                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                    <InputNumber
                                                        placeholder="Max Run Temp"
                                                        style={{ width: '100%' }}
                                                        onBlur={onBlur}
                                                        onChange={e => { maxRunTemp.current = e; onChange(e); }}
                                                        value={value}
                                                        name={name}
                                                        ref={ref}
                                                    />
                                                )}
                                                rules={{
                                                    required: 'Required Field',
                                                    validate: {
                                                        gte: value => (preCoolReefer.current && minRunTemp.current ? value >= preCoolReefer.current && value >= minRunTemp.current : true) || 'Max Run Temp must be greater than or equal to the Pre-Cool Temp and Min Run Temp'
                                                    }
                                                }}
                                                name='equipmentNeeds.maxRunTemp'
                                            />
                                        </Col>
                                    </>
                                ) : null}
                                <Col span={24}>
                                    <FormItem {...formItemLayout} label="Additional Equipment and Services" format="vertical"
                                        render={({ onChange, onBlur, value, name, ref }) => {
                                            if (isStringNotEmpty(trailerTypeId)) {
                                                if (isListNotEmpty(equipmentSpecificOptions)) {
                                                    return (
                                                        <Checkbox.Group
                                                            onBlur={onBlur}
                                                            options={equipmentSpecificOptions}
                                                            onChange={(checkedValues) => { onChange(checkedValues); }}
                                                            value={value}
                                                            name={name}
                                                            ref={ref}
                                                        />
                                                    );
                                                } else {
                                                    return (
                                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but there are no options available for the selected trailer type." />
                                                    );
                                                }
                                            } else {
                                                return (
                                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="You must first select a trailer type to see available options." />
                                                );
                                            }
                                        }}
                                        rules={{ required: false }}
                                        name='equipmentSpecifics'
                                    />
                                </Col>
                                <Col span={24}>
                                    <FormItem {...formItemLayout} label="Driver Requirements" format="vertical"
                                        render={({ onChange, onBlur, value, name, ref }) => {
                                            if (isStringNotEmpty(trailerTypeId)) {
                                                if (isListNotEmpty(driverRequirementOptions)) {
                                                    return (
                                                        <Checkbox.Group
                                                            onBlur={onBlur}
                                                            options={driverRequirementOptions}
                                                            onChange={(checkedValues) => { onChange(checkedValues); }}
                                                            value={value}
                                                            name={name}
                                                            ref={ref}
                                                        />
                                                    );
                                                } else {
                                                    return (
                                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but there are no options available for the selected trailer type." />
                                                    );
                                                }
                                            } else {
                                                return (
                                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="You must first select a trailer type to see available options." />
                                                );
                                            }
                                        }}
                                        rules={{ required: false }}
                                        name='driverRequirements'
                                    />
                                </Col>
                            </Row>
                        </Fieldset>
                        {error && <Alert message={`${error}`} type="error" />}
                    </Spin>
                    <FormButtons cancel={onCancel} disabled={isLoading === true && error === null} submitText="Create Lane" />
                    <Modal
                        title={'New Pick-Up'}
                        visible={showNewPickUpStopLocation === true}
                        width="60%"
                        style={{ top: 0 }}
                        onCancel={toggleNewPickUpStopLocation}
                        footer={null}
                        zIndex={1000}
                        destroyOnClose={true}
                        maskClosable={false}
                    >
                        <FormItem {...formItemLayout} label="Location" required format="vertical"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select a Location"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    ref={ref}
                                >
                                    {getLocationOptions(null)}
                                </Select>
                            )}
                            rules={{ required: 'Required Field' }}
                            name="pickUpLocationId"
                            defaultValue={null}
                        />
                        <br />
                        <b>Location Doesn't Exist Yet?</b>
                        <br />
                        <Button type='primary' onClick={toggleNewLocation}>Add New Location</Button>
                        <br />
                        <Fieldset legend="Default Loading Requirements">
                            <div>How will the trailer be loaded?</div>
                            <FormItem {...formItemLayout} format="vertical"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Radio.Group onBlur={onBlur} onChange={e => onChange(e.target.value)} value={value} name={name} ref={ref}>
                                        <Radio style={radioStyle} key="LIVE" value="LIVE">
                                            <span style={{ fontWeight: 'bold', fontSize: 16 }}>Live Load Trailer</span><br /><span style={{ fontSize: 14, paddingLeft: 24 }}>Trailer will be loaded upon arrival.</span>
                                        </Radio>
                                        <Radio style={radioStyle} key="DROP" value="DROP">
                                            <span style={{ fontWeight: 'bold', fontSize: 16 }}>Drop Trailer</span><br /><span style={{ fontSize: 14, paddingLeft: 24 }}>Drop and hook.</span>
                                        </Radio>
                                    </Radio.Group>
                                )}
                                rules={{ required: false }}
                                name="pickUpLoadingType"
                            />
                        </Fieldset>
                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                borderTop: '1px solid #e9e9e9',
                                padding: '10px 0px',
                                background: '#fff',
                                textAlign: 'right',
                            }}
                        >
                            <Button onClick={() => { toggleNewPickUpStopLocation(); }} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary"
                                onClick={() => {
                                    const values = methods.getValues();
                                    const locationId = values.pickUpLocationId;
                                    const loadingType = values.pickUpLoadingType;
                                    const id = pickUpStops.length + dropOffStops.length;
                                    const sequence = pickUpStops.length + dropOffStops.length;
                                    const stopTypeSequence = pickUpStops.length;
                                    let location = EntityUtils.getLocationInfo(locationId, locationsList);
                                    if (isObjectNotEmpty(location)) {
                                        let newStop = {
                                            location: location,
                                            timeZone: location.timeZone,
                                            latitude: location.latitude,
                                            longitude: location.longitude,
                                            locationId: locationId,
                                            stopType: 'PICK_UP',
                                            id: id,
                                            sequence: sequence,
                                            stopTypeSequence: stopTypeSequence,
                                            loadId: 'new-lane'
                                        };

                                        if (isStringNotEmpty(loadingType)) {
                                            newStop.loadingType = loadingType;
                                        }

                                        setPickUpStops(pickUpStops => [...pickUpStops, { ...newStop }]);
                                        toggleNewPickUpStopLocation();
                                    }

                                    return false;
                                }}
                            >
                                Add Pick-Up Location
                            </Button>
                        </div>
                    </Modal>
                    <Modal
                        title={'New Drop-Off'}
                        visible={showNewDropOffStopLocation === true}
                        width="60%"
                        style={{ top: 0 }}
                        onCancel={toggleNewDropOffStopLocation}
                        footer={null}
                        zIndex={1000}
                        destroyOnClose={true}
                        maskClosable={false}
                    >
                        <FormItem {...formItemLayout} label="Location" required format="vertical"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select a Location"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    ref={ref}
                                >
                                    {getLocationOptions(null)}
                                </Select>
                            )}
                            name="dropOffLocationId"
                            rules={{ required: 'Required Field' }}
                            defaultValue={null}
                        />
                        <br />
                        <b>Location Doesn't Exist Yet?</b>
                        <br />
                        <Button type='primary' onClick={toggleNewLocation}>Add New Location</Button>
                        <br />
                        <Fieldset legend="Default Unloading Requirements">
                            <div>How will the trailer be unloaded?</div>
                            <FormItem {...formItemLayout} format="vertical"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Radio.Group onBlur={onBlur} onChange={e => onChange(e.target.value)} value={value} name={name} ref={ref}>
                                        <Radio style={radioStyle} key="LIVE" value="LIVE">
                                            <span style={{ fontWeight: 'bold', fontSize: 16 }}>Live Unload Trailer</span><br /><span style={{ fontSize: 14, paddingLeft: 24 }}>Trailer will be unloaded upon arrival.</span>
                                        </Radio>
                                        <Radio style={radioStyle} key="DROP" value="DROP">
                                            <span style={{ fontWeight: 'bold', fontSize: 16 }}>Drop Trailer</span><br /><span style={{ fontSize: 14, paddingLeft: 24 }}>Drop and hook.</span>
                                        </Radio>
                                    </Radio.Group>
                                )}
                                rules={{ required: false }}
                                name="dropOffLoadingType"
                            />
                        </Fieldset>
                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                borderTop: '1px solid #e9e9e9',
                                padding: '10px 0px',
                                background: '#fff',
                                textAlign: 'right',
                            }}
                        >
                            <Button onClick={() => { toggleNewDropOffStopLocation(); }} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary"
                                onClick={() => {
                                    const values = methods.getValues();
                                    const locationId = values.dropOffLocationId;
                                    const loadingType = values.dropOffLoadingType;
                                    const id = pickUpStops.length + dropOffStops.length;
                                    const sequence = pickUpStops.length + dropOffStops.length;
                                    const stopTypeSequence = dropOffStops.length;
                                    let location = EntityUtils.getLocationInfo(locationId, locationsList);
                                    if (isObjectNotEmpty(location)) {
                                        let newStop = {
                                            location: location,
                                            timeZone: location.timeZone,
                                            latitude: location.latitude,
                                            longitude: location.longitude,
                                            locationId: locationId,
                                            stopType: 'DROP_OFF',
                                            id: id,
                                            sequence: sequence,
                                            stopTypeSequence: stopTypeSequence,
                                            loadId: 'new-lane'
                                        };

                                        if (isStringNotEmpty(loadingType)) {
                                            newStop.loadingType = loadingType;
                                        }

                                        setDropOffStops(dropOffStops => [...dropOffStops, { ...newStop }]);
                                        toggleNewDropOffStopLocation();
                                    }

                                    return false;
                                }}
                            >
                                Add Drop-Off Location
                            </Button>
                        </div>
                    </Modal>
                </Form>
            </FormProvider>
            <Modal
                title={isObjectNotEmpty(selectedRecord) ? (selectedRecord.stopType === "PICK_UP" ? "Pick-Up Location" : "Drop-Off Location") : 'Stop Location'}
                visible={showEditStopLocation === true}
                width="60%"
                style={{ top: 0 }}
                onCancel={(e) => { toggleEditStopLocation(); setSelectedRecord(null); }}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                maskClosable={false}
                forceRender={true}
            >
                <FormProvider {...methods2}>
                    <Form onSubmit={methods2.handleSubmit(onEditStopLocation)}>
                        <FormItem {...formItemLayout} label="Location" required format="vertical"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select a Location"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    ref={ref}
                                >
                                    {getLocationOptions(selectedRecord)}
                                </Select>
                            )}
                            rules={{ required: 'Required Field' }}
                            name="locationId"
                            defaultValue={null}
                        />
                        <br />
                        <b>Location Doesn't Exist Yet?</b>
                        <br />
                        <Button type='primary' onClick={toggleNewLocation}>Add New Location</Button>
                        <br />
                        <Fieldset legend="Default Loading/Unloading Requirements">
                            <div>How will the trailer be loaded/unloaded?</div>
                            <FormItem {...formItemLayout} format="vertical"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Radio.Group onBlur={onBlur} onChange={e => onChange(e.target.value)} value={value} name={name} ref={ref}>
                                        <Radio style={radioStyle} key="LIVE" value="LIVE">
                                            <span style={{ fontWeight: 'bold', fontSize: 16 }}>Live Load/Unload Trailer</span><br /><span style={{ fontSize: 14, paddingLeft: 24 }}>Trailer will be loaded/unloaded upon arrival.</span>
                                        </Radio>
                                        <Radio style={radioStyle} key="DROP" value="DROP">
                                            <span style={{ fontWeight: 'bold', fontSize: 16 }}>Drop Trailer</span><br /><span style={{ fontSize: 14, paddingLeft: 24 }}>Drop and hook.</span>
                                        </Radio>
                                    </Radio.Group>
                                )}
                                rules={{ required: false }}
                                name="loadingType"
                            />
                        </Fieldset>
                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                borderTop: '1px solid #e9e9e9',
                                padding: '10px 0px',
                                background: '#fff',
                                textAlign: 'right',
                            }}
                        >
                            <Button onClick={() => { toggleEditStopLocation(); setSelectedRecord(null); }} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary" htmlType="submit">Update Location</Button>
                        </div>
                    </Form>
                </FormProvider>
            </Modal>
            <Modal
                title={isObjectNotEmpty(selectedRecord) ? (selectedRecord.stopType === "PICK_UP" ? "Pick-Up Commodity" : "Drop-Off Commodity") : 'Stop Commodity'}
                visible={showAddStopCommodity === true}
                width="60%"
                style={{ top: 0 }}
                onCancel={(e) => { toggleAddStopCommodity(); setSelectedRecord(null); }}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                maskClosable={false}
                forceRender={true}
            >
                <FormProvider {...methods3}>
                    <Form onSubmit={methods3.handleSubmit(onAddStopCommodity)}>
                        {isObjectNotEmpty(selectedRecord) ? (
                            <>
                                <Fieldset legend={selectedRecord.stopType === "PICK_UP" ? "Pick-Up Location" : "Drop-Off Location"}>
                                    {getStopDetails(selectedRecord)}
                                </Fieldset>
                                <Fieldset legend="Commodity Details">
                                    <FormItem {...formItemLayout} label="Name" required format="vertical"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Select
                                                placeholder="Please Search and Select a Commodity to Add"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                ref={ref}
                                            >
                                                {getCommodityOptions(selectedRecord)}
                                            </Select>
                                        )}
                                        rules={{ required: 'Required Field' }}
                                        name="commodityId"
                                    />
                                    <FormItemDouble {...formItemLayoutDouble2} label1="Unit Quantity" label2="Unit of Measure/Unit Packaging" required1 required2 format="vertical"
                                        render1={({ onChange, onBlur, value, name, ref }) => (
                                            <InputNumber
                                                precision={0}
                                                min={0}
                                                placeholder="Unit Quantity"
                                                style={{ width: '100%' }}
                                                onBlur={onBlur}
                                                onChange={e => { onChange(e); }}
                                                value={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        rules1={{ required: 'Required Field' }}
                                        name1="unitPackagingInitCount"
                                        render2={({ onChange, onBlur, value, name, ref }) => (
                                            <Select
                                                placeholder="Please Select a Unit of Measure/Unit Packaging"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                ref={ref}
                                            >
                                                {unitPackagingOptions}
                                            </Select>
                                        )}
                                        rules2={{ required: 'Required Field' }}
                                        name2="unitPackaging"
                                    />
                                    <FormItemDouble {...formItemLayoutDouble2} label1="Bulk Quantity" label2="Bulk Packaging" format="vertical"
                                        render1={({ onChange, onBlur, value, name, ref }) => (
                                            <InputNumber
                                                precision={0}
                                                min={0}
                                                placeholder="Bulk Quantity"
                                                style={{ width: '100%' }}
                                                onBlur={onBlur}
                                                onChange={e => { onChange(e); }}
                                                value={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        rules1={{ required: false }}
                                        name1="bulkPackagingInitCount"
                                        render2={({ onChange, onBlur, value, name, ref }) => (
                                            <Select
                                                placeholder="Please Select a Bulk Packaging"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                ref={ref}
                                            >
                                                {bulkPackagingOptions}
                                            </Select>
                                        )}
                                        rules2={{ required: false }}
                                        name2="bulkPackaging"
                                    />
                                </Fieldset>
                            </>
                        ) : null}
                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                borderTop: '1px solid #e9e9e9',
                                padding: '10px 0px',
                                background: '#fff',
                                textAlign: 'right',
                            }}
                        >
                            <Button onClick={() => { toggleAddStopCommodity(); setSelectedRecord(null); }} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary" htmlType="submit">Add Commodity</Button>
                        </div>
                    </Form>
                </FormProvider>
            </Modal>
            <Drawer
                title={'Add New Location'}
                visible={showNewLocation === true}
                onClose={toggleNewLocation}
                bodyStyle={{ paddingBottom: 80 }}
                style={{ zIndex: 1005 }}
                width={fullWidth > 720 ? fullWidth / 2 : 360}
                footer={null}
                zIndex={1005}
                destroyOnClose={true}
                closable={false}
                maskClosable={false}
            >
                <NewLocation cancel={toggleNewLocation} accountId={accountId} linkedAccountId={shipperId} linkedAccountEntityType={linkedAccountEntityType} />
            </Drawer>
        </>
    );
};

export default withRouter(NewLinkedLoadLane);