import * as actionTypes from "../actions/actionTypes";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { isBooleanTrue, isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import ErrorUtils from "../../shared/errorUtils";
import * as actionCreators from "./index";

//#region Fetch Product List Methods

export const fetchProductListStart = (listName) => {
    return {
        type: actionTypes.FETCH_PRODUCT_LIST_START,
        payload: { listName: listName }
    }
};

export const fetchProductListSuccess = (listName, payload) => {
    return {
        type: actionTypes.FETCH_PRODUCT_LIST_SUCCESS,
        payload: { listName: listName, ...payload }
    }
};

export const fetchProductListFail = (listName, payload) => {
    return {
        type: actionTypes.FETCH_PRODUCT_LIST_FAIL,
        payload: { listName: listName, ...payload }
    }
};

export const clearProductList = (listName) => {
    return {
        type: actionTypes.CLEAR_PRODUCT_LIST,
        payload: { listName: listName }
    }
};

export const clearProductLists = () => {
    return {
        type: actionTypes.CLEAR_PRODUCT_LISTS
    }
};

export const addProductToProductList = (listName, payload) => {
    return {
        type: actionTypes.ADD_TO_PRODUCT_LIST,
        payload: { listName: listName, newRecord: payload }
    }
};

export const updateProductInProductList = (listName, payload) => {
    return {
        type: actionTypes.UPDATE_IN_PRODUCT_LIST,
        payload: { listName: listName, updatedRecord: payload }
    }
};

export const removeProductFromProductList = (listName, payload) => {
    return {
        type: actionTypes.REMOVE_FROM_PRODUCT_LIST,
        payload: { listName: listName, recordToRemove: payload }
    }
};

//#endregion
//#region Fetch Product Methods

export const fetchProductStart = () => {
    return {
        type: actionTypes.FETCH_PRODUCT_START
    }
};

export const fetchProductSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_PRODUCT_SUCCESS,
        payload: payload
    }
};

export const fetchProductFail = (payload) => {
    return {
        type: actionTypes.FETCH_PRODUCT_FAIL,
        payload: payload
    }
};

export const clearProduct = () => {
    return {
        type: actionTypes.CLEAR_PRODUCT
    }
};

//#endregion
//#region Add Product Methods

export const addProductStart = () => {
    return {
        type: actionTypes.ADD_PRODUCT_START
    }
};

export const addProductSuccess = () => {
    return {
        type: actionTypes.ADD_PRODUCT_SUCCESS
    }
};

export const addProductFail = (payload) => {
    return {
        type: actionTypes.ADD_PRODUCT_FAIL,
        payload: payload
    }
};

export const addProductLoadingClear = () => {
    return {
        type: actionTypes.ADD_PRODUCT_LOADING_CLEAR
    }
};

export const addProductErrorClear = () => {
    return {
        type: actionTypes.ADD_PRODUCT_ERROR_CLEAR
    }
};

export const addProductCancel = () => {
    return {
        type: actionTypes.ADD_PRODUCT_CANCEL
    }
};

//#endregion
//#region Update Product Methods

const changeSingleProduct = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_PRODUCT,
        payload: payload
    }
};

export const updateProductStart = () => {
    return {
        type: actionTypes.UPDATE_PRODUCT_START
    }
};

export const updateProductSuccess = () => {
    return {
        type: actionTypes.UPDATE_PRODUCT_SUCCESS
    }
};

export const updateProductFail = (payload) => {
    return {
        type: actionTypes.UPDATE_PRODUCT_FAIL,
        payload: payload
    }
};

export const updateProductLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_PRODUCT_LOADING_CLEAR
    }
};

export const updateProductErrorClear = () => {
    return {
        type: actionTypes.UPDATE_PRODUCT_ERROR_CLEAR
    }
};

export const updateProductCancel = () => {
    return {
        type: actionTypes.UPDATE_PRODUCT_CANCEL
    }
};

//#endregion
//#region Products Methods

export const fetchProductList = (listName, payload) => {
    return async (dispatch, getState) => {
        if (isStringNotEmpty(listName)) {
            try {
                dispatch(fetchProductListStart(listName));

                await dispatch(actionCreators.getAccounts());
                await dispatch(actionCreators.getCommodities());
                const state = getState();
                const orchestratorState = { ...state.orchestrator };
                const accounts = [...orchestratorState.accounts];
                const commodities = [...orchestratorState.commodities];
                const productsState = { ...state.products };
                const existingLists = { ...productsState.lists };

                let pagination = {};
                let searchParams = {};
                if (isObjectNotEmpty(existingLists[listName])) {
                    pagination = { ...existingLists[listName].pagination };
                    searchParams = { ...existingLists[listName].searchParams };
                }

                if (isObjectNotEmpty(payload)) {
                    searchParams = { ...payload };
                }

                const res = await Data.getProducts({ ...searchParams }, pagination, accounts, commodities);
                dispatch(fetchProductListSuccess(listName, { records: res.data, params: { searchParams: res.searchParams, pagination: res.pagination } }));
            } catch (error) {
                logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
                dispatch(fetchProductListFail(listName, { error: ErrorUtils.getErrorMessage(error) }));
            }
        }
    }
};

export const fetchProduct = (id) => {
    return async (dispatch, getState) => {
        try {
            dispatch(fetchProductStart());

            await dispatch(actionCreators.getAccounts());
            await dispatch(actionCreators.getCommodities());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const accounts = [...orchestratorState.accounts];
            const commodities = [...orchestratorState.commodities];

            const product = await Data.getProduct(id, accounts, commodities);
            dispatch(fetchProductSuccess({ record: product }));
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(fetchProductFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const addProduct = (payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(addProductStart());

            await dispatch(actionCreators.getAccounts());
            await dispatch(actionCreators.getCommodities());
            const state = getState();
            const orchestratorState = { ...state.orchestrator };
            const accounts = [...orchestratorState.accounts];
            const commodities = [...orchestratorState.commodities];

            const newProduct = await Data.addProduct(payload, accounts, commodities);
            if (isObjectNotEmpty(newProduct)) {
                dispatch(addProductToProductList(newProduct.accountId, newProduct));
                dispatch(addProductToProductList('ALL', newProduct));
            }

            dispatch(addProductSuccess());
            dispatch(addProductLoadingClear());
            dispatch(addProductErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(addProductFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const updateProduct = (id, payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updateProductStart());

            if (isBooleanTrue(payload.isDeleted)) {
                const removedProduct = await Data.removeProduct(id);
                if (isObjectNotEmpty(removedProduct)) {
                    dispatch(removeProductFromProductList(removedProduct.accountId, removedProduct));
                    dispatch(removeProductFromProductList('ALL', removedProduct));
                }
            } else {
                await dispatch(actionCreators.getAccounts());
                await dispatch(actionCreators.getCommodities());
                const state = getState();
                const orchestratorState = { ...state.orchestrator };
                const accounts = [...orchestratorState.accounts];
                const commodities = [...orchestratorState.commodities];

                const updatedProduct = await Data.updateProduct(id, payload, accounts, commodities);
                if (isObjectNotEmpty(updatedProduct)) {
                    dispatch(updateProductInProductList(updatedProduct.accountId, updatedProduct));
                    dispatch(updateProductInProductList('ALL', updatedProduct));
                    dispatch(changeSingleProduct(updatedProduct));
                }
            }

            dispatch(updateProductSuccess());
            dispatch(updateProductLoadingClear());
            dispatch(updateProductErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateProductFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion