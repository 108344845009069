import Checks from '../entitlementUtils';

export const canCreateLoadLocation = (auth, load) => {
    return Checks.allowStaff(auth) || Checks.isLoadCarrier(load, auth) || Checks.isLoadShipper(load, auth) || Checks.isLoadDriver(load, auth);
};

export const canReadLoadLocation = (auth) => {
    return Checks.isAuthenticated(auth);
};

export const canReadLoadLocationList = (auth) => {
    return Checks.isAuthenticated(auth);
};

export const canUpdateLoadLocation = (auth) => {
    return Checks.allowStaffAdmin(auth);
};

export const canDeleteLoadLocation = (auth) => {
    return Checks.allowStaffAdmin(auth);
};