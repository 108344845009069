import axiosAuthenticated from "../../api/axiosAuthenticated";
import { addFile, deleteFile, getFilesMetaData, updateFileMetaData } from "../../api/fileClient";
import moment from 'moment';
import * as DTO from "../dtos/index";
import * as Data from "./index";
import { isListNotEmpty, isNotNullOrUndefined, isStringNotEmpty } from "../../shared/objectUtils";

const accountsPath = '/accounts';
const accountsBulkPath = '/accounts/bulk/get';

//#region Accounts Methods

export const getAccountsByIds = async (ids = []) => {
    if (isListNotEmpty(ids)) {
        const accountsRes = await axiosAuthenticated.post(accountsBulkPath, { page: 1, size: 1000000, sort: 'name', order: 'asc', id: [...ids] });
        if (accountsRes && accountsRes.status === 200) {
            return accountsRes.data.data;
        }
    }

    return [];
};

export const getAllAccounts = async () => {
    const accountsRes = await axiosAuthenticated.get(accountsPath, { params: { page: 1, size: 1000000, sort: 'name', order: 'asc', isDeleted: false } });
    if (accountsRes && accountsRes.status === 200) {
        return accountsRes.data.data;
    }

    return [];
};

export const getAccounts = async (searchParams = {}, pagination = {}) => {
    const accountsRes = await axiosAuthenticated.get(accountsPath, { params: { ...searchParams } });
    if (accountsRes && accountsRes.status === 200) {
        const accounts = accountsRes.data.data;
        const otherData = accountsRes.data;

        let accountIds = accounts.map((accounts) => { return accounts.id; });
        let accountDocuments = await Data.getDocumentsByEntityIds(accountIds);

        const transformedAccounts = accounts.map((account) => { return DTO.getAccountDTO(account, accountDocuments); });

        // Read total count from server
        pagination.total = otherData.totalCount;
        pagination.current = otherData.currentPage;
        pagination.pageSize = isNotNullOrUndefined(searchParams.size) ? searchParams.size : pagination.pageSize;
        pagination.totalPages = otherData.totalPages;

        return { data: transformedAccounts, searchParams: searchParams, pagination: pagination };
    }

    return { data: [], searchParams: searchParams, pagination: pagination };
};

export const getAccount = async (id) => {
    const accountRes = await axiosAuthenticated.get(accountsPath + `/${id}`);
    if (accountRes && accountRes.status === 200) {
        let account = accountRes.data;

        let accountDocuments = await getFilesMetaData(account.id);

        return DTO.getAccountDTO(account, accountDocuments);
    }

    return null;
};

export const addAccount = async (payload, logoFile, insuranceFile, insuranceExpiryDate, w9File, w9ExpiryDate, isReviewed = false, reviewStatus = "PENDING", accountRegistrationId = null) => {
    const accountsRes = await axiosAuthenticated.post(accountsPath, { ...payload });
    if (accountsRes && accountsRes.status === 201) {
        let newAccount = accountsRes.data;

        let logoFileId = null;
        let insuranceFileId = null;
        let w9FileId = null;
        if (isNotNullOrUndefined(logoFile)) {
            const fileData = await addAccountFile(logoFile, newAccount.id, isReviewed, reviewStatus);
            logoFileId = fileData.logoFileId;
        }
        if (isNotNullOrUndefined(insuranceFile) && isNotNullOrUndefined(insuranceExpiryDate) && isNotNullOrUndefined(w9File) && isNotNullOrUndefined(w9ExpiryDate)) {
            const fileData = await addCarrierFiles(insuranceFile, insuranceExpiryDate, w9File, w9ExpiryDate, newAccount.id, isReviewed, reviewStatus);
            insuranceFileId = fileData.insuranceFileId;
            w9FileId = fileData.w9FileId;
        }

        if (isNotNullOrUndefined(accountRegistrationId)) {
            await Data.updateAccountRegistration(accountRegistrationId, { reviewStatus: 'IMPORTED' });
        }

        const accountDocuments = await getFilesMetaData(newAccount.id);

        return DTO.getAccountDTO(newAccount, accountDocuments);
    }

    return null;
};

export const updateAccount = async (id, payload, logoFile, logoFileId, insuranceFile, insuranceFileId, insuranceExpiryDate, w9File, w9FileId, w9ExpiryDate, isReviewed = false, reviewStatus = "PENDING") => {
    if (isNotNullOrUndefined(logoFile)) {
        const fileData = await updateAccountFile(logoFile, logoFileId, id, isReviewed, reviewStatus);
        logoFileId = fileData.logoFileId;
    }

    if ((isNotNullOrUndefined(insuranceFile) || isNotNullOrUndefined(insuranceFileId)) || (isNotNullOrUndefined(w9File) || isNotNullOrUndefined(w9FileId))) {
        const fileData = await updateCarrierFiles(insuranceFile, insuranceFileId, insuranceExpiryDate, w9File, w9FileId, w9ExpiryDate, id, isReviewed, reviewStatus);
        w9FileId = fileData.w9FileId;
        w9ExpiryDate = fileData.w9ExpiryDate;
        insuranceFileId = fileData.insuranceFileId;
        insuranceExpiryDate = fileData.insuranceExpiryDate;
    }

    const accountsRes = await axiosAuthenticated.put(accountsPath + `/${id}`, { ...payload });
    if (accountsRes && accountsRes.status === 200) {
        let updatedAccount = accountsRes.data;

        const accountDocuments = await getFilesMetaData(updatedAccount.id);

        return DTO.getAccountDTO(updatedAccount, accountDocuments);
    }

    return null;
};

export const removeAccount = async (id) => {
    const accountsRes = await axiosAuthenticated.put(accountsPath + `/${id}`, { isDeleted: true });
    if (accountsRes && accountsRes.status === 200) {
        return accountsRes.data;
    }

    return null;
};

export const verifyAccount = async (id) => {
    const accountsRes = await axiosAuthenticated.put(accountsPath + `/${id}`, { isVerified: true });
    if (accountsRes && accountsRes.status === 200) {
        let updatedAccount = accountsRes.data;

        const accountDocuments = await getFilesMetaData(updatedAccount.id);

        return DTO.getAccountDTO(updatedAccount, accountDocuments);
    }

    return null;
};

export const addAccountFile = async (logoFile, entityId, isReviewed = false, reviewStatus = "PENDING") => {
    let logoFileIdValue = null;

    if (isNotNullOrUndefined(logoFile)) {
        const logoFileData = await addFile(logoFile, "ACCOUNT", entityId, "ACCOUNT_LOGO", "Account Logo", "", "ALL", null, isReviewed, reviewStatus, null, [{ entityType: "ACCOUNT", entityId: entityId }]);
        logoFileIdValue = logoFileData ? logoFileData.id : null;
    }

    return { logoFileId: logoFileIdValue };
};

export const updateAccountFile = async (logoFile, logoFileId, entityId, isReviewed = false, reviewStatus = "PENDING") => {
    let logoFileIdValue = logoFileId;

    if (isNotNullOrUndefined(logoFile)) {
        const isLogoFileDeleted = await deleteFile(logoFileId);
        if (isLogoFileDeleted === true) {
            const logoFileData = await addFile(logoFile, "ACCOUNT", entityId, "ACCOUNT_LOGO", "Account Logo", "", "ALL", null, isReviewed, reviewStatus, null, [{ entityType: "ACCOUNT", entityId: entityId }]);
            logoFileIdValue = logoFileData ? logoFileData.id : null;
        }
    }

    return { logoFileId: logoFileIdValue };
};

export const addCarrierFiles = async (insuranceFile, insuranceExpiryDate, w9File, w9ExpiryDate, entityId, isReviewed = false, reviewStatus = "PENDING") => {
    let insuranceFileIdValue = null;
    let insuranceExpiryDateValue = null;
    let w9FileIdValue = null;
    let w9ExpiryDateValue = null;

    if (isNotNullOrUndefined(insuranceFile) && isNotNullOrUndefined(insuranceExpiryDate)) {
        const insuranceFileData = await addFile(insuranceFile, "ACCOUNT_CARRIER", entityId, "CARRIER_INSURANCE", "Carrier Insurance", "", "STAFF", insuranceExpiryDate, isReviewed, reviewStatus, null, [{ entityType: "ACCOUNT_CARRIER", entityId: entityId }]);
        insuranceFileIdValue = insuranceFileData ? insuranceFileData.id : null;
        insuranceExpiryDateValue = insuranceFileData ? insuranceFileData.expiryDate : null;
    }

    if (isNotNullOrUndefined(w9File) && isNotNullOrUndefined(w9ExpiryDate)) {
        const w9FileData = await addFile(w9File, "ACCOUNT_CARRIER", entityId, "CARRIER_W9", "Carrier W9", "", "STAFF", w9ExpiryDate, isReviewed, reviewStatus, null, [{ entityType: "ACCOUNT_CARRIER", entityId: entityId }]);
        w9FileIdValue = w9FileData ? w9FileData.id : null;
        w9ExpiryDateValue = w9FileData ? w9FileData.expiryDate : null;
    }

    return {
        w9FileId: w9FileIdValue,
        w9ExpiryDate: w9ExpiryDateValue,
        insuranceFileId: insuranceFileIdValue,
        insuranceExpiryDate: insuranceExpiryDateValue
    };
};

export const updateCarrierFiles = async (insuranceFile, insuranceFileId, insuranceExpiryDate, w9File, w9FileId, w9ExpiryDate, entityId, isReviewed = false, reviewStatus = "PENDING") => {
    let insuranceFileIdValue = insuranceFileId;
    let insuranceExpiryDateValue = insuranceExpiryDate;
    let w9FileIdValue = w9FileId;
    let w9ExpiryDateValue = w9ExpiryDate;

    if (isNotNullOrUndefined(insuranceExpiryDate)) {
        if (moment.isMoment(insuranceExpiryDate)) {
            insuranceExpiryDate = insuranceExpiryDate.utc().toISOString();
        }
        insuranceExpiryDateValue = insuranceExpiryDate;
    }
    if (isNotNullOrUndefined(w9ExpiryDate)) {
        if (moment.isMoment(w9ExpiryDate)) {
            w9ExpiryDate = w9ExpiryDate.utc().toISOString();
        }
        w9ExpiryDateValue = w9ExpiryDate;
    }

    if (isNotNullOrUndefined(insuranceFile)) {
        const isInsuranceFileDeleted = await deleteFile(insuranceFileId);
        if (isInsuranceFileDeleted === true) {
            const insuranceFileData = await addFile(insuranceFile, "ACCOUNT_CARRIER", entityId, "CARRIER_INSURANCE", "Carrier Insurance", "", "ALL", insuranceExpiryDate, isReviewed, reviewStatus, null, [{ entityType: "ACCOUNT_CARRIER", entityId: entityId }]);
            insuranceFileIdValue = insuranceFileData ? insuranceFileData.id : null;
            insuranceExpiryDateValue = insuranceFileData ? insuranceFileData.expiryDate : null;
        }
    } else if (isNotNullOrUndefined(insuranceFileId)) {
        if (isNotNullOrUndefined(insuranceExpiryDate)) {
            await updateFileMetaData(insuranceFileId, { expiryDate: insuranceExpiryDate, isReviewed: isReviewed, reviewStatus: reviewStatus });
        }
    }

    if (isNotNullOrUndefined(w9File)) {
        const isW9FileDeleted = await deleteFile(w9FileId);
        if (isW9FileDeleted === true) {
            const w9FileData = await addFile(w9File, "ACCOUNT_CARRIER", entityId, "CARRIER_W9", "Carrier W9", "", "ALL", w9ExpiryDate, isReviewed, reviewStatus, null, [{ entityType: "ACCOUNT_CARRIER", entityId: entityId }]);
            w9FileIdValue = w9FileData ? w9FileData.id : null;
            w9ExpiryDateValue = w9FileData ? w9FileData.expiryDate : null;
        }
    } else if (isNotNullOrUndefined(w9FileId)) {
        if (isNotNullOrUndefined(w9ExpiryDate)) {
            await updateFileMetaData(w9FileId, { expiryDate: w9ExpiryDate, isReviewed: isReviewed, reviewStatus: reviewStatus });
        }
    }

    return {
        w9FileId: w9FileIdValue,
        w9ExpiryDate: w9ExpiryDateValue,
        insuranceFileId: insuranceFileIdValue,
        insuranceExpiryDate: insuranceExpiryDateValue
    };
};

export const doesAccountExistByMCNumber = async (mcNumber, config = {}) => {
    let searchParams = {};
    searchParams['mcNum:contains'] = mcNumber;
    const accountsRes = await axiosAuthenticated.get(accountsPath, {...config, params: {...searchParams}});
    if (accountsRes && accountsRes.status === 200) {
        const existingAccounts = accountsRes.data.data;
        if (isListNotEmpty(existingAccounts)) {
            return { exists: true, existingAccounts: existingAccounts };
        }
    }

    return { exists: false, existingAccounts: [] };
};

export const doesAccountExistByDOTNumber = async (dotNumber, config = {}) => {
    let searchParams = {};
    searchParams['dotNum:contains'] = dotNumber;
    const accountsRes = await axiosAuthenticated.get(accountsPath, {...config, params: {...searchParams}});
    if (accountsRes && accountsRes.status === 200) {
        const existingAccounts = accountsRes.data.data;
        if (isListNotEmpty(existingAccounts)) {
            return { exists: true, existingAccounts: existingAccounts };
        }
    }

    return { exists: false, existingAccounts: [] };
};

export const doesCarrierExistByMCNumber = async (mcNumber, config = {}) => {
    let searchParams = {};
    searchParams['mcNum:contains'] = mcNumber;
    const accountsRes = await axiosAuthenticated.get(accountsPath, {...config, params: {...searchParams}});
    if (accountsRes && accountsRes.status === 200) {
        const existingAccounts = accountsRes.data.data;
        if (isListNotEmpty(existingAccounts)) {
            return { exists: true, existingAccounts: existingAccounts };
        }
    }

    return { exists: false, existingAccounts: [] };
};

export const doesBrokerExistByMCNumber = async (mcNumber, config = {}) => {
    let searchParams = {};
    searchParams['mcNum:contains'] = mcNumber;
    const accountsRes = await axiosAuthenticated.get(accountsPath, {...config, params: {...searchParams}});
    if (accountsRes && accountsRes.status === 200) {
        const existingAccounts = accountsRes.data.data;
        if (isListNotEmpty(existingAccounts)) {
            return { exists: true, existingAccounts: existingAccounts };
        }
    }

    return { exists: false, existingAccounts: [] };
};

export const doesAccountExistByEIN = async (ein, config = {}) => {
    let searchParams = {};
    searchParams['ein:contains'] = ein;
    const accountsRes = await axiosAuthenticated.get(accountsPath, {...config, params: {...searchParams}});
    if (accountsRes && accountsRes.status === 200) {
        const existingAccounts = accountsRes.data.data;
        if (isListNotEmpty(existingAccounts)) {
            return { exists: true, existingAccounts: existingAccounts };
        }
    }

    return { exists: false, existingAccounts: [] };
};

export const doesAccountExistByName = async (name, config = {}) => {
    let searchParams = {};
    searchParams['name'] = name;
    const accountsRes = await axiosAuthenticated.get(accountsPath, {...config, params: {...searchParams}});
    if (accountsRes && accountsRes.status === 200) {
        const existingAccounts = accountsRes.data.data;
        if (isListNotEmpty(existingAccounts)) {
            return { exists: true, existingAccounts: existingAccounts };
        }
    }

    return { exists: false, existingAccounts: [] };
};

//#endregion