import Checks from '../entitlementUtils';

// allow everyone including those who are not authenticated
export const canCreateAccountRegistration = (auth) => {
    return true;
};

export const canReadAccountRegistration = (auth) => {
    return Checks.allowStaff(auth.isStaff);
};

export const canReadAccountRegistrationList = (auth) => {
    return Checks.allowStaff(auth.isStaff);
};

export const canUpdateAccountRegistration = (auth) => {
    return Checks.allowStaff(auth.isStaff);
};

export const canDeleteAccountRegistration = (auth) => {
    return Checks.allowStaffAdmin(auth.isStaff);
};