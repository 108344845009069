import EntityUtils from "../entityUtils";
import MomentDate from "../../shared/dateFormatter";
import Enums from '../../shared/enums';
import { isListNotEmpty, isNotNullOrUndefined, isNullOrUndefined, isNumberGreaterThanZero, isNumberNotEmpty, isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";

const getLocationInfoByLink = (location, accountId) => {
    if (isObjectNotEmpty(location)) {
        if (isListNotEmpty(location.links) && isStringNotEmpty(accountId)) {
            let accountLink = location.links.find(l => l.accountId === accountId);
            if (isObjectNotEmpty(accountLink)) {
                return {
                    ...location,
                    ...accountLink
                };
            }
        }

        return location;
    }

    return null;
};

const getStopLocationInfoByLink = (location, shipperId, carrierId, loadCreatedByEntityType) => {
    if (isObjectNotEmpty(location)) {
        let shipperLink = getLocationInfoByLink(location, shipperId);
        let carrierLink = getLocationInfoByLink(location, carrierId);
        if (loadCreatedByEntityType === 'SHIPPER') {
            if (isObjectNotEmpty(shipperLink)) {
                return shipperLink;
            } else if (isObjectNotEmpty(carrierLink)) {
                return carrierLink;
            }
        } else if (loadCreatedByEntityType === 'CARRIER') {
            if (isObjectNotEmpty(carrierLink)) {
                return carrierLink;
            } else if (isObjectNotEmpty(shipperLink)) {
                return shipperLink;
            }
        } else {
            if (isObjectNotEmpty(shipperLink)) {
                return shipperLink;
            } else if (isObjectNotEmpty(carrierLink)) {
                return carrierLink;
            }
        }

        return location;
    }

    return null;
};

const getLocationNameByLink = (location, accountId) => {
    if (isObjectNotEmpty(location) && isListNotEmpty(location.links) && isStringNotEmpty(accountId)) {
        let accountLink = location.links.find(l => l.accountId === accountId);
        if (isObjectNotEmpty(accountLink) && isStringNotEmpty(accountLink.name)) {
            return accountLink.name;
        }
    }

    return null;
};

const getStopLocationNameDisplay = (stop, shipperId, carrierId, loadCreatedByEntityType) => {
    if (isObjectNotEmpty(stop)) {
        let shipperLinkName = getLocationNameByLink(stop.stopLocation, shipperId);
        let carrierLinkName = getLocationNameByLink(stop.stopLocation, carrierId);
        if (loadCreatedByEntityType === 'SHIPPER') {
            if (isStringNotEmpty(shipperLinkName)) {
                return shipperLinkName;
            } else if (isStringNotEmpty(carrierLinkName)) {
                return carrierLinkName;
            }
        } else if (loadCreatedByEntityType === 'CARRIER') {
            if (isStringNotEmpty(carrierLinkName)) {
                return carrierLinkName;
            } else if (isStringNotEmpty(shipperLinkName)) {
                return shipperLinkName;
            }
        } else {
            if (isStringNotEmpty(shipperLinkName)) {
                return shipperLinkName;
            } else if (isStringNotEmpty(carrierLinkName)) {
                return carrierLinkName;
            }
        }
    }

    return null;
};

const getStopNameDisplay = (stop, shipperId, carrierId, loadCreatedByEntityType) => {
    if (isObjectNotEmpty(stop)) {
        let locationName = getStopLocationNameDisplay(stop, shipperId, carrierId, loadCreatedByEntityType);
        if (isStringNotEmpty(locationName)) {
            return locationName;
        } else if (isStringNotEmpty(stop.stopType)) {
            if (stop.stopType === 'PICK_UP') {
                return 'Pick-Up';
            } else if (stop.stopType === 'DROP_OFF') {
                return 'Drop-Off';
            }
        }
    }

    return '';
};

export const getLoadStopDTO = (stop, locations = [], load = null) => {
    let stopLocation = EntityUtils.getLocationInfo(stop.stopLocationId, locations);
    if (isObjectNotEmpty(load)) {
        stop.stopLocation = getStopLocationInfoByLink(stopLocation, load.shipperId, load.assignedCarrierId, load.createdByEntityType);
    } else {
        stop.stopLocation = stopLocation;
    }
    
    return stop;
};

export const getAddLoadStopRequestDTO = (payload, loadId) => {
    let momentDateUtil = new MomentDate();

    let stop = {
        unitOfMeasure: "ENGLISH",
        loadId: loadId
    };

    let timeZone = null;
    if (isObjectNotEmpty(payload.stopLocation)) {
        if (isNumberNotEmpty(payload.stopLocation.latitude)) {
            stop.latitude = payload.stopLocation.latitude;
        }

        if (isNumberNotEmpty(payload.stopLocation.longitude)) {
            stop.longitude = payload.stopLocation.longitude;
        }

        if (isStringNotEmpty(payload.stopLocation.timeZone)) {
            stop.timeZone = payload.stopLocation.timeZone;
            timeZone = payload.stopLocation.timeZone;
        }
    }

    if (isStringNotEmpty(payload.stopType)) {
        stop.stopType = payload.stopType;
    }

    if (isNumberNotEmpty(payload.sequence)) {
        stop.sequence = payload.sequence;
    }

    if (isStringNotEmpty(payload.stopLocationId)) {
        stop.stopLocationId = payload.stopLocationId;
    }

    if (isNullOrUndefined(payload.latitude)) {
        if (isObjectNotEmpty(payload.stopLocation)) {
            stop.latitude = payload.stopLocation.latitude;
        }
    } else {
        stop.latitude = payload.latitude;
    }

    if (isNullOrUndefined(payload.longitude)) {
        if (isObjectNotEmpty(payload.stopLocation)) {
            stop.longitude = payload.stopLocation.longitude;
        }
    } else {
        stop.longitude = payload.longitude;
    }

    if (isListNotEmpty(payload.bolNumbers)) {
        stop.bolNumbers = payload.bolNumbers;
    }

    if (payload.hasDriverAssist === true) {
        stop.hasDriverAssist = true;
    } else {
        stop.hasDriverAssist = false;
    }

    if (payload.hasDriverAssist === true && isNumberGreaterThanZero(payload.driverAssist)) {
        stop.driverAssist = Number(payload.driverAssist);
        stop.driverAssistUnit = 'USD';
    } else {
        stop.driverAssist = 0;
        stop.driverAssistUnit = 'USD';
    }

    if (payload.hasLumperFee === true) {
        stop.hasLumperFee = true;
    } else {
        stop.hasLumperFee = false;
    }

    if (payload.hasLumperFee === true && isNumberGreaterThanZero(payload.lumperFee)) {
        stop.lumperFee = Number(payload.lumperFee);
        stop.lumperFeeUnit = 'USD';
    } else {
        stop.lumperFee = 0;
        stop.lumperFeeUnit = 'USD';
    }

    if (isStringNotEmpty(payload.loadingType)) {
        stop.loadingType = payload.loadingType;
    }

    if (isStringNotEmpty(payload.purchaseOrderNumber)) {
        stop.purchaseOrderNumber = payload.purchaseOrderNumber;
    }

    if (isStringNotEmpty(payload.pickUpNumber)) {
        stop.pickUpNumber = payload.pickUpNumber;
    }

    if (isStringNotEmpty(payload.dropOffNumber)) {
        stop.dropOffNumber = payload.dropOffNumber;
    }

    if (isStringNotEmpty(payload.specialInstructions)) {
        stop.specialInstructions = payload.specialInstructions;
    }

    if (isStringNotEmpty(payload.apptInstructions)) {
        stop.apptInstructions = payload.apptInstructions;
    }

    if (isStringNotEmpty(payload.apptNumber)) {
        stop.apptNumber = payload.apptNumber;
    }

    if (isStringNotEmpty(payload.apptType)) {
        stop.apptType = payload.apptType;
    }

    if (isNotNullOrUndefined(payload.apptWindowStartDateTime)) {
        let momentDate = payload.apptWindowStartDateTime;
        stop.apptWindowStartDateTime = momentDateUtil.asTimeZoneUtcISOString(momentDate, timeZone);
    }

    if (isNotNullOrUndefined(payload.apptWindowEndDateTime)) {
        let momentDate = payload.apptWindowEndDateTime;
        stop.apptWindowEndDateTime = momentDateUtil.asTimeZoneUtcISOString(momentDate, timeZone);
    }

    if (isNotNullOrUndefined(payload.requestedDateTime)) {
        let momentDate = payload.requestedDateTime;
        stop.requestedDateTime = momentDateUtil.asTimeZoneUtcISOString(momentDate, timeZone);
    }

    if (isNotNullOrUndefined(payload.apptCallAheadDateTime)) {
        let momentDate = payload.apptCallAheadDateTime;
        stop.apptCallAheadDateTime = momentDateUtil.asTimeZoneUtcISOString(momentDate, timeZone);
    }

    if (isNotNullOrUndefined(payload.apptDateTime)) {
        let momentDate = payload.apptDateTime;
        stop.apptDateTime = momentDateUtil.asTimeZoneUtcISOString(momentDate, timeZone);
    }

    if (isObjectNotEmpty(payload.apptPointOfContact)) {
        stop.apptPointOfContact = payload.apptPointOfContact;
    }

    if (isListNotEmpty(payload.commodities)) {
        let commodities = [];
        payload.commodities.forEach((commodity) => {
            let newCommodity = {
                commodityId: commodity.commodityId,
                name: commodity.name,
                unitPackaging: commodity.unitPackaging,
                bulkPackaging: commodity.bulkPackaging
            };

            if (isNumberNotEmpty(commodity.unitPackagingInitCount)) {
                newCommodity.unitPackagingInitCount = Number(commodity.unitPackagingInitCount);
            }

            if (isNumberNotEmpty(commodity.unitPackagingActualCount)) {
                newCommodity.unitPackagingActualCount = Number(commodity.unitPackagingActualCount);
            }

            if (isNumberNotEmpty(commodity.bulkPackagingInitCount)) {
                newCommodity.bulkPackagingInitCount = Number(commodity.bulkPackagingInitCount);
            }

            if (isNumberNotEmpty(commodity.bulkPackagingActualCount)) {
                newCommodity.bulkPackagingActualCount = Number(commodity.bulkPackagingActualCount);
            }

            if (isObjectNotEmpty(newCommodity)) {
                commodities.push(newCommodity);
            }
        });

        if (isListNotEmpty(commodities)) {
            stop.commodities = commodities;
        }
    }

    return stop;
};

export const getUpdateLoadStopRequestDTO = (payload, timeZone) => {
    let momentDateUtil = new MomentDate();

    let stop = {};

    if (isStringNotEmpty(payload.stopType)) {
        stop.stopType = payload.stopType;
    }

    if (isNumberNotEmpty(payload.sequence)) {
        stop.sequence = payload.sequence;
    }

    if (isListNotEmpty(payload.bolNumbers)) {
        stop.bolNumbers = payload.bolNumbers;
    }

    if (isNotNullOrUndefined(payload.hasDriverAssist)) {
        if (payload.hasDriverAssist === true) {
            stop.hasDriverAssist = true;
        } else {
            stop.hasDriverAssist = false;
        }

        if (payload.hasDriverAssist === true && isNumberGreaterThanZero(payload.driverAssist)) {
            stop.driverAssist = Number(payload.driverAssist);
            stop.driverAssistUnit = 'USD';
        } else {
            stop.driverAssist = 0;
            stop.driverAssistUnit = 'USD';
        }
    }

    if (isNotNullOrUndefined(payload.hasLumperFee)) {
        if (payload.hasLumperFee === true) {
            stop.hasLumperFee = true;
        } else {
            stop.hasLumperFee = false;
        }

        if (payload.hasLumperFee === true && isNumberGreaterThanZero(payload.lumperFee)) {
            stop.lumperFee = Number(payload.lumperFee);
            stop.lumperFeeUnit = 'USD';
        } else {
            stop.lumperFee = 0;
            stop.lumperFeeUnit = 'USD';
        }
    }

    if (isStringNotEmpty(payload.loadingType)) {
        stop.loadingType = payload.loadingType;
    }

    if (isStringNotEmpty(payload.purchaseOrderNumber)) {
        stop.purchaseOrderNumber = payload.purchaseOrderNumber;
    } else if (payload.purchaseOrderNumber === null) {
        stop.purchaseOrderNumber = null;
    }

    if (isStringNotEmpty(payload.pickUpNumber)) {
        stop.pickUpNumber = payload.pickUpNumber;
    } else if (payload.pickUpNumber === null) {
        stop.pickUpNumber = null;
    }

    if (isStringNotEmpty(payload.dropOffNumber)) {
        stop.dropOffNumber = payload.dropOffNumber;
    } else if (payload.dropOffNumber === null) {
        stop.dropOffNumber = null;
    }

    if (isStringNotEmpty(payload.specialInstructions)) {
        stop.specialInstructions = payload.specialInstructions;
    } else if (payload.specialInstructions === null) {
        stop.specialInstructions = null;
    }

    if (isStringNotEmpty(payload.apptInstructions)) {
        stop.apptInstructions = payload.apptInstructions;
    } else if (payload.apptInstructions === null) {
        stop.apptInstructions = null;
    }

    if (isStringNotEmpty(payload.apptType)) {
        stop.apptType = payload.apptType;
        if (payload.apptType === 'FIRST_COME_FIRST_SERVE') {
            if (isNotNullOrUndefined(payload.apptWindowStartDateTime)) {
                let momentDate = payload.apptWindowStartDateTime;
                stop.apptWindowStartDateTime = momentDateUtil.asTimeZoneUtcISOString(momentDate, timeZone);
            }

            if (isNotNullOrUndefined(payload.apptWindowEndDateTime)) {
                let momentDate = payload.apptWindowEndDateTime;
                stop.apptWindowEndDateTime = momentDateUtil.asTimeZoneUtcISOString(momentDate, timeZone);
            }

            stop.apptDateTime = null;
            stop.apptNumber = null;
            stop.apptCallAheadDateTime = null;
        } else if (payload.apptType === 'HAVE_APPOINTMENT') {
            if (isStringNotEmpty(payload.apptNumber)) {
                stop.apptNumber = payload.apptNumber;
            }

            if (isNotNullOrUndefined(payload.apptCallAheadDateTime)) {
                let momentDate = payload.apptCallAheadDateTime;
                stop.apptCallAheadDateTime = momentDateUtil.asTimeZoneUtcISOString(momentDate, timeZone);
            }

            if (isNotNullOrUndefined(payload.apptDateTime)) {
                let momentDate = payload.apptDateTime;
                stop.apptDateTime = momentDateUtil.asTimeZoneUtcISOString(momentDate, timeZone);
            }

            stop.apptWindowStartDateTime = null;
            stop.apptWindowEndDateTime = null;
        } else if (payload.apptType === 'NEED_APPOINTMENT') {
            if (isNotNullOrUndefined(payload.apptCallAheadDateTime)) {
                let momentDate = payload.apptCallAheadDateTime;
                stop.apptCallAheadDateTime = momentDateUtil.asTimeZoneUtcISOString(momentDate, timeZone);
            }

            stop.apptDateTime = null;
            stop.apptNumber = null;
            stop.apptWindowStartDateTime = null;
            stop.apptWindowEndDateTime = null;
        }
    }

    if (isNotNullOrUndefined(payload.requestedDateTime)) {
        let momentDate = payload.requestedDateTime;
        stop.requestedDateTime = momentDateUtil.asTimeZoneUtcISOString(momentDate, timeZone);
    }

    if (isObjectNotEmpty(payload.apptPointOfContact)) {
        let apptPointOfContact = {};

        if (isStringNotEmpty(payload.apptPointOfContact.firstName)) {
            apptPointOfContact.firstName = payload.apptPointOfContact.firstName;
        } else if (payload.apptPointOfContact.firstName === null) {
            apptPointOfContact.firstName = null;
        }

        if (isStringNotEmpty(payload.apptPointOfContact.lastName)) {
            apptPointOfContact.lastName = payload.apptPointOfContact.lastName;
        } else if (payload.apptPointOfContact.lastName === null) {
            apptPointOfContact.lastName = null;
        }

        if (isStringNotEmpty(payload.apptPointOfContact.email)) {
            apptPointOfContact.email = payload.apptPointOfContact.email;
        } else if (payload.apptPointOfContact.email === null) {
            apptPointOfContact.email = null;
        }

        if (isStringNotEmpty(payload.apptPointOfContact.phone)) {
            apptPointOfContact.phone = payload.apptPointOfContact.phone;
        } else if (payload.apptPointOfContact.phone === null) {
            apptPointOfContact.phone = null;
        }

        if (isStringNotEmpty(payload.apptPointOfContact.phoneExt)) {
            apptPointOfContact.phoneExt = payload.apptPointOfContact.phoneExt;
        } else if (payload.apptPointOfContact.phoneExt === null) {
            apptPointOfContact.phoneExt = null;
        }

        if (isObjectNotEmpty(apptPointOfContact)) {
            stop.apptPointOfContact = apptPointOfContact;
        } else {
            stop.apptPointOfContact = null;
        }
    }

    if (isListNotEmpty(payload.commodities)) {
        let commodities = [];
        payload.commodities.forEach((commodity) => {
            let newCommodity = {
                commodityId: commodity.commodityId,
                name: commodity.name,
                unitPackaging: commodity.unitPackaging,
                bulkPackaging: commodity.bulkPackaging
            };

            if (isNumberNotEmpty(commodity.unitPackagingInitCount)) {
                newCommodity.unitPackagingInitCount = Number(commodity.unitPackagingInitCount);
            }

            if (isNumberNotEmpty(commodity.unitPackagingActualCount)) {
                newCommodity.unitPackagingActualCount = Number(commodity.unitPackagingActualCount);
            }

            if (isNumberNotEmpty(commodity.bulkPackagingInitCount)) {
                newCommodity.bulkPackagingInitCount = Number(commodity.bulkPackagingInitCount);
            }

            if (isNumberNotEmpty(commodity.bulkPackagingActualCount)) {
                newCommodity.bulkPackagingActualCount = Number(commodity.bulkPackagingActualCount);
            }

            if (isObjectNotEmpty(newCommodity)) {
                commodities.push(newCommodity);
            }
        });

        if (isListNotEmpty(commodities)) {
            stop.commodities = commodities;
        } else {
            stop.commodities = [];
        }
    }

    return stop;
};

export const getAddDriverAssistInvoiceLineItemsRequestDTO = (stopId, stop, originalStop, loadId, load) => {
    let newInvoiceLineItems = [];
    let shipperId = isObjectNotEmpty(load) && isStringNotEmpty(load.shipperId) ? load.shipperId : null;
    let carrierId = isObjectNotEmpty(load) && isStringNotEmpty(load.assignedCarrierId) ? load.assignedCarrierId : null;

    if (stop.hasDriverAssist === true) {
        let itemType = 'DRIVER_ASSIST';
        if (stop.stopStatus !== 'PENDING') {
            itemType = 'DRIVER_ASSIST_ACCESSORIAL';
        }

        let baseAmount = 0;
        if (isNumberGreaterThanZero(stop.driverAssist)) {
            baseAmount = parseFloat(stop.driverAssist);
        }
        let quantity = 1;
        let totalAmount = baseAmount * quantity;

        let newInvoiceLineItem = {
            description: Enums.LineItemNames.getValueByName(itemType) + ' for ' + getStopNameDisplay(originalStop, shipperId, carrierId, load.createdByEntityType),
            loadId: loadId,
            stopId: stopId,
            itemType: itemType,
            isIrisFee: false,
            baseAmount: baseAmount.toFixed(2),
            baseAmountUnit: 'USD',
            quantity: quantity,
            quantityUnit: 'PER_STOP',
            totalAmount: totalAmount.toFixed(2),
            totalAmountUnit: 'USD',
            status: 'PENDING',
            approvalStatus: 'PENDING',
            isOpen: false,
        };

        if (load.serviceType === 'TMS') {
            newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', fromEntityId: shipperId, toEntityType: 'CARRIER', toEntityId: carrierId });
        } else if (load.serviceType === 'BROKERAGE') {
            newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', fromEntityId: shipperId, toEntityType: 'STAFF' });
            newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER', toEntityId: carrierId });
        }
    }

    return newInvoiceLineItems;
};

export const getAddLumperFeeInvoiceLineItemsRequestDTO = (stopId, stop, originalStop, loadId, load) => {
    let newInvoiceLineItems = [];
    let shipperId = isObjectNotEmpty(load) && isStringNotEmpty(load.shipperId) ? load.shipperId : null;
    let carrierId = isObjectNotEmpty(load) && isStringNotEmpty(load.assignedCarrierId) ? load.assignedCarrierId : null;

    if (stop.hasLumperFee === true) {
        let itemType = 'LUMPER_FEE';
        if (stop.stopStatus !== 'PENDING') {
            itemType = 'LUMPER_FEE_ACCESSORIAL';
        }

        let baseAmount = 0;
        if (isNumberGreaterThanZero(stop.lumperFee)) {
            baseAmount = parseFloat(stop.lumperFee);
        }
        let quantity = 1;
        let totalAmount = baseAmount * quantity;

        let newInvoiceLineItem = {
            description: Enums.LineItemNames.getValueByName(itemType) + ' for ' + getStopNameDisplay(originalStop, shipperId, carrierId, load.createdByEntityType),
            loadId: loadId,
            stopId: stopId,
            itemType: itemType,
            isIrisFee: false,
            baseAmount: baseAmount.toFixed(2),
            baseAmountUnit: 'USD',
            quantity: quantity,
            quantityUnit: 'PER_STOP',
            totalAmount: totalAmount.toFixed(2),
            totalAmountUnit: 'USD',
            status: 'PENDING',
            approvalStatus: 'PENDING',
            isOpen: false,
        };

        if (load.serviceType === 'TMS') {
            newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', fromEntityId: shipperId, toEntityType: 'CARRIER', toEntityId: carrierId });
        } else if (load.serviceType === 'BROKERAGE') {
            newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', fromEntityId: shipperId, toEntityType: 'STAFF' });
            newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER', toEntityId: carrierId });
        }
    }

    return newInvoiceLineItems;
};

export const getAddLoadStopInvoiceLineItemsRequestDTO = (stopId, stop, originalStop, loadId, load) => {
    let newInvoiceLineItems = [];
    let shipperId = isObjectNotEmpty(load) && isStringNotEmpty(load.shipperId) ? load.shipperId : null;
    let carrierId = isObjectNotEmpty(load) && isStringNotEmpty(load.assignedCarrierId) ? load.assignedCarrierId : null;

    // for adding new stops, create the invoice line items as accessorials if the load is no longer in the pending status
    if (stop.hasDriverAssist === true) {
        let itemType = 'DRIVER_ASSIST';

        let baseAmount = 0;
        if (isNumberGreaterThanZero(stop.driverAssist)) {
            baseAmount = parseFloat(stop.driverAssist);
        }
        let quantity = 1;
        let totalAmount = baseAmount * quantity;

        let newInvoiceLineItem = {
            description: Enums.LineItemNames.getValueByName(itemType) + ' for ' + getStopNameDisplay(originalStop, shipperId, carrierId, load.createdByEntityType),
            loadId: loadId,
            stopId: stopId,
            itemType: itemType,
            isIrisFee: false,
            baseAmount: baseAmount.toFixed(2),
            baseAmountUnit: 'USD',
            quantity: quantity,
            quantityUnit: 'PER_STOP',
            totalAmount: totalAmount.toFixed(2),
            totalAmountUnit: 'USD',
            status: 'PENDING',
            approvalStatus: 'PENDING',
            isOpen: false,
        };

        if (load.serviceType === 'TMS') {
            newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', fromEntityId: shipperId, toEntityType: 'CARRIER', toEntityId: carrierId });
        } else if (load.serviceType === 'BROKERAGE') {
            newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', fromEntityId: shipperId, toEntityType: 'STAFF' });
            newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER', toEntityId: carrierId });
        }
    }

    if (stop.hasLumperFee === true) {
        let itemType = 'LUMPER_FEE';

        let baseAmount = 0;
        if (isNumberGreaterThanZero(stop.lumperFee)) {
            baseAmount = parseFloat(stop.lumperFee);
        }
        let quantity = 1;
        let totalAmount = baseAmount * quantity;

        let newInvoiceLineItem = {
            description: Enums.LineItemNames.getValueByName(itemType) + ' for ' + getStopNameDisplay(originalStop, shipperId, carrierId, load.createdByEntityType),
            loadId: loadId,
            stopId: stopId,
            itemType: itemType,
            isIrisFee: false,
            baseAmount: baseAmount.toFixed(2),
            baseAmountUnit: 'USD',
            quantity: quantity,
            quantityUnit: 'PER_STOP',
            totalAmount: totalAmount.toFixed(2),
            totalAmountUnit: 'USD',
            status: 'PENDING',
            approvalStatus: 'PENDING',
            isOpen: false,
        };

        if (load.serviceType === 'TMS') {
            newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', fromEntityId: shipperId, toEntityType: 'CARRIER', toEntityId: carrierId });
        } else if (load.serviceType === 'BROKERAGE') {
            newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', fromEntityId: shipperId, toEntityType: 'STAFF' });
            newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER', toEntityId: carrierId });
        }
    }

    return newInvoiceLineItems;
};