import React, { useState } from "react";
import { Button } from 'antd';
import TermsOfUseModal from "../../components/TermsOfUseModal/TermsOfUseModal";

const IRISFloatingFooter = () => {
    const [showModal, setShowModal] = useState(false);

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    return (
        <>
            <div style={{ backgroundColor: 'white', opacity: 1, textAlign: 'center', borderTop: '1px solid #e9e9e9', paddingTop: 24 }}>IRIS Web ©2020 Created by IRIS Freight<br /><Button type='link' style={{ margin: 0 }} onClick={toggleModal}>Terms of Service and Privacy Policy</Button></div>
            <TermsOfUseModal showModal={showModal} toggleModal={toggleModal} />
        </>
    );
};

export default IRISFloatingFooter;