import { omit } from "lodash";
import { isListNotEmpty, isNumberNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import EntityUtils from '../entityUtils';

export const getAssetDTO = (asset, assetDocuments = [], accounts = [], trailerTypes = []) => {
    // Get Trailer Type Info
    if (isStringNotEmpty(asset.trailerTypeId) && isListNotEmpty(trailerTypes)) {
        asset.trailerType = trailerTypes.find(i => i.id === asset.trailerTypeId);
    }

    // Get Account Info
    if (isListNotEmpty(accounts)) {
        asset.account = EntityUtils.getAccountInfo(asset.carrierId, accounts);
    }

    // Get Files associated to this asset
    if (isListNotEmpty(assetDocuments)) {
        let filteredAssetDocuments = assetDocuments.filter(document => document.entityId === asset.id);
        let { photoFileId, licensePlateFileId, insuranceFileId, registrationFileId } = EntityUtils.getAssetFileIds(filteredAssetDocuments);

        asset.documents = filteredAssetDocuments;
        asset.photoFileId = isStringNotEmpty(photoFileId) ? photoFileId : null;
        asset.licensePlateFileId = isStringNotEmpty(licensePlateFileId) ? licensePlateFileId : null;
        asset.insuranceFileId = isStringNotEmpty(insuranceFileId) ? insuranceFileId : null;
        asset.registrationFileId = isStringNotEmpty(registrationFileId) ? registrationFileId : null;
    }

    return asset;
};

export const getAddAssetRequestDTO = (payload) => {
    if (isNumberNotEmpty(payload.range)) {
        payload.range = Number(payload.range);
    }

    if (isNumberNotEmpty(payload.overallLength)) {
        payload.overallLength = Number(payload.overallLength);
    }

    if (isNumberNotEmpty(payload.fuelTankCapacity)) {
        payload.fuelTankCapacity = Number(payload.fuelTankCapacity);
    }

    if (isNumberNotEmpty(payload.height)) {
        payload.height = Number(payload.height);
    }

    if (isNumberNotEmpty(payload.length)) {
        payload.length = Number(payload.length);
    }

    if (isNumberNotEmpty(payload.width)) {
        payload.width = Number(payload.width);
    }

    if (isNumberNotEmpty(payload.weight)) {
        payload.weight = Number(payload.weight);
    }

    if (isNumberNotEmpty(payload.loadCapacity)) {
        payload.loadCapacity = Number(payload.loadCapacity);
    }

    if (isNumberNotEmpty(payload.maxLoadWeight)) {
        payload.maxLoadWeight = Number(payload.maxLoadWeight);
    }

    if (isNumberNotEmpty(payload.axles)) {
        payload.axles = Number(payload.axles);
    }

    return omit(payload, ["photoFile", "licensePlateFile", "insuranceFile", "registrationFile", "photoFileId", "licensePlateFileId", "insuranceFileId", "registrationFileId"]);
};

export const getUpdateAssetRequestDTO = (payload) => {
    if (isNumberNotEmpty(payload.range)) {
        payload.range = Number(payload.range);
    } else if (payload.range === null) {
        payload.range = null;
        payload.rangeUnit = null;
    }

    if (isNumberNotEmpty(payload.overallLength)) {
        payload.overallLength = Number(payload.overallLength);
    } else if (payload.overallLength === null) {
        payload.overallLength = null;
        payload.overallLengthUnit = null;
    }

    if (isNumberNotEmpty(payload.fuelTankCapacity)) {
        payload.fuelTankCapacity = Number(payload.fuelTankCapacity);
    } else if (payload.fuelTankCapacity === null) {
        payload.fuelTankCapacity = null;
        payload.fuelTankCapacityUnit = null;
    }

    if (isNumberNotEmpty(payload.height)) {
        payload.height = Number(payload.height);
    } else if (payload.height === null) {
        payload.height = null;
        payload.heightUnit = null;
    }

    if (isNumberNotEmpty(payload.length)) {
        payload.length = Number(payload.length);
    } else if (payload.length === null) {
        payload.length = null;
        payload.lengthUnit = null;
    }

    if (isNumberNotEmpty(payload.width)) {
        payload.width = Number(payload.width);
    } else if (payload.width === null) {
        payload.width = null;
        payload.widthUnit = null;
    }

    if (isNumberNotEmpty(payload.weight)) {
        payload.weight = Number(payload.weight);
    } else if (payload.weight === null) {
        payload.weight = null;
        payload.weightUnit = null;
    }

    if (isNumberNotEmpty(payload.loadCapacity)) {
        payload.loadCapacity = Number(payload.loadCapacity);
    } else if (payload.loadCapacity === null) {
        payload.loadCapacity = null;
        payload.loadCapacityUnit = null;
    }

    if (isNumberNotEmpty(payload.maxLoadWeight)) {
        payload.maxLoadWeight = Number(payload.maxLoadWeight);
    } else if (payload.maxLoadWeight === null) {
        payload.maxLoadWeight = null;
        payload.maxLoadWeightUnit = null;
    }

    if (isNumberNotEmpty(payload.axles)) {
        payload.axles = Number(payload.axles);
    } else if (payload.axles === null) {
        payload.axles = null;
    }

    return omit(payload, ["photoFile", "licensePlateFile", "insuranceFile", "registrationFile", "photoFileId", "licensePlateFileId", "insuranceFileId", "registrationFileId"]);
};