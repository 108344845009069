import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { Button, Col, Drawer, Dropdown, Menu, Row, Spin, Tabs } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruck, faUsers } from '@fortawesome/free-solid-svg-icons';
import Header from '../../components/Header/Header';
import { useLocation } from 'react-router';
import LinkedLoadLanes from '../LinkedLoadLanes/LinkedLoadLanes';
import { isBooleanTrue, isListNotEmpty, isNumberNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import * as Data from '../../api/data';
import logger from '../../shared/logger';
import Locations from '../Locations/Locations';
import DataContactInfoRow from '../../components/DataContactInfoRow/DataContactInfoRow';
import DataAddressRow from '../../components/DataAddressRow/DataAddressRow';
import ComplexCard from '../../components/ComplexCard/ComplexCard';
import { ControlOutlined, DownOutlined } from '@ant-design/icons';
import EditLinkedAccount from '../../components/EditLinkedAccount/EditLinkedAccount';
import DataRow from '../../components/DataRow/DataRow';

const { TabPane } = Tabs;

const LinkedAccount = (props) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    //#endregion
    //#region props

    const accountId = props.match.params.accountId;
    const linkedAccountId = props.match.params.linkedAccountId;
    const previousBreadcrumbs = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isListNotEmpty(props.history.location.state.previousBreadcrumbs) ? props.history.location.state.previousBreadcrumbs : [];
    const previousLocation = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageLocation) ? props.history.location.state.previousPageLocation : { pathname: `/linkedAccounts` };
    const previousPath = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageLocation) ? props.history.location.state.previousPageLocation : `/linkedAccounts`;
    const previousBreadcrumbName = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageTitle) ? props.history.location.state.previousPageTitle : 'All Accounts';

    //#endregion
    //#region useLocation

    const location = useLocation();

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.linkedAccounts.isRecordLoading);
    const error = useSelector(state => state.linkedAccounts.recordError);
    const linkedAccount = useSelector(state => state.linkedAccounts.record);

    //#endregion
    //#region useStates

    const [tabKey, setTabKey] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [showEditEntity, setShowEditEntity] = useState(false);

    //#endregion
    //#region toggles

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    //#endregion
    //#region tab methods

    const onTabChange = (key) => {
        props.history.replace({ ...props.location, hash: `#${key}` });
    };

    //#endregion
    //#region useMemos and useEffects

    useMemo(() => {
        // console.log('location changed');
        // console.log(location);
        if (isStringNotEmpty(location.hash)) {
            // console.log(location.hash.replace('#', ''))
            setTabKey(location.hash.replace('#', ''));
        } else {
            setTabKey('snapshot');
            props.history.replace({ ...location, hash: '#snapshot' });
        }
    }, [location]);

    useMemo(() => {
        if (isStringNotEmpty(accountId) && isStringNotEmpty(linkedAccountId)) {
            dispatch(actionCreators.fetchLinkedAccountByAccountIdAndLinkedAccountId(accountId, linkedAccountId));
        }
    }, [accountId, linkedAccountId]);

    useEffect(() => {
        if (isObjectNotEmpty(linkedAccount) && isStringNotEmpty(linkedAccount.logoFileId)) {
            Data.getImageByDocumentId(linkedAccount.logoFileId).then((data) => {
                const blob = new Blob([data], {
                    type: 'image/png',
                });
                setImageUrl(URL.createObjectURL(blob));
            }).catch(err => {
                logger.logDebugEvent('LinkedAccount.js', err.message, true);
            });
        }
    }, [linkedAccount]);

    //#endregion
    //#region displays

    const menu = () => {
        return (
            <Menu>
                <Menu.Item key="editAccount" onClick={(e) => { e.domEvent.stopPropagation(); toggleEditEntity(); }}>
                    <FontAwesomeIcon className="anticon" icon={faUsers} style={{ marginRight: 8 }} />
                    <span>Edit Account Details</span>
                </Menu.Item>
            </Menu>
        );
    };

    const extra = (
        <Dropdown overlay={menu} trigger={['click']}>
            <Button onClick={(e) => { e.stopPropagation(); }}><ControlOutlined /> <DownOutlined /></Button>
        </Dropdown>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit Account Details"}
            onClose={toggleEditEntity}
            visible={showEditEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditLinkedAccount
                cancel={toggleEditEntity}
                linkedAccount={linkedAccount}
            />
        </Drawer>
    );

    const breadcrumbs = [...previousBreadcrumbs,
    {
        location: previousLocation,
        path: previousPath,
        breadcrumbName: previousBreadcrumbName,
    },
    {
        path: `/linkedAccounts/${isStringNotEmpty(accountId) ? accountId : ''}/${isStringNotEmpty(linkedAccountId) ? linkedAccountId : ''}`,
        breadcrumbName: `${isObjectNotEmpty(linkedAccount) && isStringNotEmpty(linkedAccount.name) ? linkedAccount.name : ''}`,
    },
    ];

    //#endregion

    return (
        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
            {isObjectNotEmpty(linkedAccount) && isStringNotEmpty(accountId) && isStringNotEmpty(linkedAccountId) && linkedAccount.accountId === accountId && linkedAccount.linkedAccountId === linkedAccountId ? (
                <>
                    <Header
                        title={isStringNotEmpty(linkedAccount.name) ? linkedAccount.name : 'N/A'}
                        title2={isObjectNotEmpty(linkedAccount.irisId) ? 'Account ID: ' + linkedAccount.irisId : ''}
                        title3={isObjectNotEmpty(linkedAccount.customerId) ? 'Customer ID: ' + linkedAccount.customerId : ''}
                        logo={imageUrl}
                        icon={<FontAwesomeIcon className="anticon" icon={faTruck} />}
                        breadcrumbs={breadcrumbs}
                        highlights={isBooleanTrue(linkedAccount.entityTypes.includes('CARRIER')) ? [
                            {
                                title: 'EIN #',
                                value: linkedAccount.ein
                            },
                            {
                                title: 'DOT #',
                                value: linkedAccount.dotNum
                            },
                            {
                                title: 'MC #',
                                value: linkedAccount.mcNum
                            },
                        ] : (isBooleanTrue(linkedAccount.entityTypes.includes('SHIPPER')) ? [
                            {
                                title: 'EIN #',
                                value: linkedAccount.ein
                            },
                            {
                                title: 'D&B #',
                                value: linkedAccount.dbNum
                            },
                            {
                                title: 'PBB #',
                                value: linkedAccount.blueBookNum
                            },
                        ] : (isBooleanTrue(linkedAccount.entityTypes.includes('BROKER')) ? [
                            {
                                title: 'EIN #',
                                value: linkedAccount.ein
                            },
                            {
                                title: 'DOT #',
                                value: linkedAccount.dotNum
                            },
                            {
                                title: 'MC #',
                                value: linkedAccount.mcNum
                            },
                        ]
                            : [
                                {
                                    title: 'EIN #',
                                    value: linkedAccount.ein
                                }
                            ]))}
                    />
                    <Tabs activeKey={tabKey} onChange={onTabChange} destroyInactiveTabPane={true} tabBarStyle={{ backgroundColor: '#ffffff', paddingRight: 24, paddingLeft: 24, margin: 0 }}>
                        <TabPane tab="Snapshot" key="snapshot">
                            <div style={{ padding: 24 }}>
                                {isLoading === false ? (
                                    <Row gutter={[16, 16]}>
                                        <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                            <ComplexCard
                                                title="Account Details"
                                                headStyle={{ fontSize: 14 }}
                                                extra={extra}
                                            >
                                                {isObjectNotEmpty(linkedAccount.pointOfContact) ? <DataContactInfoRow separateLines={true} title="Point of Contact" valueStyle={{ marginLeft: 16 }} contact={linkedAccount.pointOfContact} /> : null}
                                                {isObjectNotEmpty(linkedAccount.companyAddress) ? <DataAddressRow title="Company Address" oneLine={false} valueStyle={{ paddingLeft: 16 }} address={linkedAccount.companyAddress} /> : null}
                                            </ComplexCard>
                                        </Col>
                                        {linkedAccount.entityTypes.includes('SHIPPER') || linkedAccount.entityTypes.includes('BROKER') || linkedAccount.entityTypes.includes('FACTORING') ? (
                                            <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                                <ComplexCard
                                                    title="Accounts Payable"
                                                    headStyle={{ fontSize: 14 }}
                                                >
                                                    {isObjectNotEmpty(linkedAccount.accountsPayable) ? <DataContactInfoRow title="A/P Contact" separateLines={false} valueStyle={{ paddingLeft: 16 }} contact={linkedAccount.accountsPayable} /> : null}
                                                    {isObjectNotEmpty(linkedAccount.accountsPayableAddress) ? <DataAddressRow title="A/P Address" oneLine={false} valueStyle={{ paddingLeft: 16 }} address={linkedAccount.accountsPayableAddress} /> : null}
                                                </ComplexCard>
                                            </Col>
                                        ) : null}
                                        {linkedAccount.entityTypes.includes('CARRIER') || linkedAccount.entityTypes.includes('BROKER') || linkedAccount.entityTypes.includes('FACTORING') ? (
                                            <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                                <ComplexCard
                                                    title="Accounts Receivable"
                                                    headStyle={{ fontSize: 14 }}
                                                >
                                                    {isObjectNotEmpty(linkedAccount.accountsReceivable) ? <DataContactInfoRow title="A/R Contact" separateLines={false} valueStyle={{ paddingLeft: 16 }} contact={linkedAccount.accountsReceivable} /> : null}
                                                    {isObjectNotEmpty(linkedAccount.accountsReceivableAddress) ? <DataAddressRow title="A/R Address" oneLine={false} valueStyle={{ paddingLeft: 16 }} address={linkedAccount.accountsReceivableAddress} /> : null}
                                                    {isStringNotEmpty(linkedAccount.preferredPaymentMethod) ? <DataRow title="Preferred Payment Method" value={linkedAccount.preferredPaymentMethod} dataType="PreferredPaymentMethod" /> : null}
                                                </ComplexCard>
                                            </Col>
                                        ) : null}
                                    </Row>
                                ) : null}
                            </div>
                        </TabPane>
                        {(isBooleanTrue(linkedAccount.entityTypes.includes("SHIPPER")) || isBooleanTrue(linkedAccount.entityTypes.includes("PRODUCER")) || isBooleanTrue(linkedAccount.entityTypes.includes("RECEIVER"))) ? (
                            <TabPane tab="Locations" key="locations">
                                <div style={{ padding: 24 }}>
                                    <Locations accountId={linkedAccount.accountId} linkedAccountId={linkedAccount.linkedAccountId} linkedAccountEntityType={linkedAccount.entityTypes.includes("SHIPPER") ? "SHIPPER" : (linkedAccount.entityTypes.includes("PRODUCER") ? "PRODUCER" : (linkedAccount.entityTypes.includes("RECEIVER") ? "RECEIVER" : null))} />
                                </div>
                            </TabPane>
                        ) : null}
                        {isBooleanTrue(linkedAccount.entityTypes.includes("SHIPPER")) ? (
                            <TabPane tab="Lanes" key="lanes">
                                <div style={{ padding: 24 }}>
                                    <LinkedLoadLanes accountId={linkedAccount.accountId} linkedAccountId={linkedAccount.linkedAccountId} linkedAccountEntityType="SHIPPER" breadcrumbs={breadcrumbs} />
                                </div>
                            </TabPane>
                        ) : null}
                    </Tabs>
                </>
            ) : null}
            {editEntityComponents}
        </Spin>
    );
};

export default withRouter(LinkedAccount);