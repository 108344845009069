import React from 'react';
import { DeleteOutlined, EditOutlined, PlusOutlined, SwapOutlined } from '@ant-design/icons';
import { Button, Tooltip } from "antd";

const AddEditChangeRemoveButtons = ({ showAdd = false, showEdit = false, showChange = false, showRemove = false, addText, editText, changeText, removeText, addAction, editAction, changeAction, removeAction }) => {
    let buttonArray = [];

    if (showAdd === true) {
        buttonArray.push(
            <Tooltip key={`tooltop-1`} placement="top" title={addText}>
                <Button key="1" type="primary" icon={<PlusOutlined />} shape='circle' onClick={addAction} style={null} />
            </Tooltip>
        );
    }
    if (showEdit === true) {
        buttonArray.push(
            <Tooltip key={`tooltop-2`} placement="top" title={editText}>
                <Button key="2" type="primary" icon={<EditOutlined />} shape='circle' onClick={editAction} style={null} />
            </Tooltip>
        );
    }
    if (showChange === true) {
        buttonArray.push(
            <Tooltip key={`tooltop-3`} placement="top" title={changeText}>
                <Button key="3" type="primary" icon={<SwapOutlined />} shape='circle' onClick={changeAction} style={{ marginLeft: '8px' }} />
            </Tooltip>
        );
    }
    if (showRemove === true) {
        buttonArray.push(
            <Tooltip key={`tooltop-4`} placement="top" title={removeText}>
                <Button key="4" type="danger" icon={<DeleteOutlined />} shape="circle" onClick={removeAction} style={{ marginLeft: '8px' }} />
            </Tooltip>
        );
    }

    return buttonArray;
};

export default AddEditChangeRemoveButtons;