import React, { useEffect, useMemo, useState } from 'react';
import { Avatar, Button, Col, List, Modal, Row, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Header from '../Header/Header';
import { isListNotEmpty, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import * as Data from '../../api/data/index';
import logger from '../../shared/logger';
import LocalStorage from "../../shared/localStorageUtils";

const RoleSwitcherModal = ({ showModal = false, toggleModal }) => {

    const dispatch = useDispatch();
    const isAdmin = useSelector(state => state.auth.isAdmin);
    const profileImageId = useSelector(state => state.auth.profileImageId);
    const firstName = useSelector(state => state.auth.firstName);
    const lastName = useSelector(state => state.auth.lastName);
    const userId = useSelector(state => state.auth.userId);
    const entityType = useSelector(state => state.auth.entityType);
    const yourAccountUsers = useSelector(state => state.yourAccountUsers.records);
    const yourAccountUser = useSelector(state => state.yourAccountUsers.record);
    const yourAccount = useSelector(state => state.yourAccounts.record);
    const accounts = useSelector(state => state.orchestrator.accounts);

    const [imageUrl, setImageUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useMemo(() => {
        dispatch(actionCreators.getAccounts());
    }, []);

    useEffect(() => {
        if (isStringNotEmpty(profileImageId)) {
            Data.getImageByDocumentId(profileImageId).then(data => {
                const blob = new Blob([data], {
                    type: 'image/png',
                });
                setImageUrl(URL.createObjectURL(blob));
            }).catch(err => {
                logger.logDebugEvent('RoleSwitcherModal.js', err.message, true);
            });
        }
    }, [profileImageId]);

    const onSwitchRole = async (selectedAccountUser, selectedAccount, selectedEntityType, selectedEntityId, selectedEntityIrisId) => {
        setIsLoading(true);
        dispatch(actionCreators.authUpdate({
            entityId: selectedEntityId,
            entityIrisId: selectedEntityIrisId,
            entityType: selectedEntityType
        }));

        if (selectedEntityType === 'STAFF') {
            dispatch(actionCreators.fetchYourAccountUsersSuccess({ record: null }));
            dispatch(actionCreators.fetchYourAccountsSuccess({ record: null }));
            await LocalStorage.removeItem('yourAccountUser');
            await LocalStorage.removeItem('yourAccount');
        } else if (isObjectNotEmpty(selectedAccountUser) && isObjectNotEmpty(selectedAccountUser.account)) {
            dispatch(actionCreators.fetchYourAccountUsersSuccess({ record: selectedAccountUser }));
            dispatch(actionCreators.fetchYourAccountsSuccess({ record: selectedAccountUser.account }));
            await LocalStorage.setItem('yourAccountUser', JSON.stringify(selectedAccountUser));
            await LocalStorage.setItem('yourAccount', JSON.stringify(selectedAccountUser.account));

            // console.log('start getting account orchestrator');
            await dispatch(actionCreators.getAccountOrchestrator(selectedAccountUser.accountId, selectedEntityType, true));
        } else if (isObjectNotEmpty(selectedAccount)) {
            dispatch(actionCreators.fetchYourAccountUsersSuccess({ record: { account: selectedAccount, accountId: selectedAccount.id, accountRoles: ['ADMIN'], isVerified: true } }));
            dispatch(actionCreators.fetchYourAccountsSuccess({ record: selectedAccount }));
            await LocalStorage.setItem('yourAccountUser', JSON.stringify({ account: selectedAccount, accountId: selectedAccount.id, accountRoles: ['ADMIN'], isVerified: true }));
            await LocalStorage.setItem('yourAccount', JSON.stringify(selectedAccount));

            // console.log('start getting account orchestrator');
            await dispatch(actionCreators.getAccountOrchestrator(selectedAccount.id, selectedEntityType, true));
        } else {
            dispatch(actionCreators.fetchYourAccountUsersSuccess({ record: null }));
            dispatch(actionCreators.fetchYourAccountsSuccess({ record: null }));
            await LocalStorage.removeItem('yourAccountUser');
            await LocalStorage.removeItem('yourAccount');
        }
        setIsLoading(false);

        toggleModal();
    };

    return (
        <Modal
            title={'Switch Accounts/Roles'}
            visible={showModal === true}
            width="90%"
            style={{ top: 0 }}
            onCancel={toggleModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true}>
                <Header
                    title={firstName + ' ' + lastName}
                    logo={imageUrl}
                    footer={
                        <Row>
                            <Col><span>Please Choose an Account/Role Below</span></Col>
                        </Row>
                    }
                />
                {isListNotEmpty(yourAccountUsers) ? (
                    <List
                        itemLayout="horizontal"
                        dataSource={yourAccountUsers}
                        renderItem={item => {
                            let subItems = [];
                            if (item.isDriver === true) {
                                subItems.push(
                                    <List.Item
                                        actions={[<Button type="default" onClick={(e) => { onSwitchRole(item, item.account, 'DRIVER', item.id, item.driver ? item.driver.irisId : ''); }} disabled={entityType === 'DRIVER' && yourAccountUser === item && yourAccount === item.account}>{entityType === 'DRIVER' && yourAccountUser === item && yourAccount === item.account ? 'Selected' : 'Select'}</Button>]}
                                    >
                                        <List.Item.Meta
                                            avatar={
                                                <Avatar src={imageUrl} />
                                            }
                                            title={item.account ? item.account.name : ''}
                                            description={'Driver'}
                                        />
                                    </List.Item>
                                );
                            }
                            if (isObjectNotEmpty(item.account) && item.account.entityTypes.includes("SHIPPER") === true) {
                                subItems.push(
                                    <List.Item
                                        actions={[<Button type="default" onClick={(e) => { onSwitchRole(item, item.account, 'SHIPPER', item.accountId, item.account ? item.account.irisId : ''); }} disabled={entityType === 'SHIPPER' && yourAccountUser === item && yourAccount === item.account}>{entityType === 'SHIPPER' && yourAccountUser === item && yourAccount === item.account ? 'Selected' : 'Select'}</Button>]}
                                    >
                                        <List.Item.Meta
                                            avatar={
                                                <Avatar src={imageUrl} />
                                            }
                                            title={item.account ? item.account.name : ''}
                                            description={'Shipper'}
                                        />
                                    </List.Item>
                                );
                            }
                            if (isObjectNotEmpty(item.account) && item.account.entityTypes.includes("CARRIER") === true) {
                                subItems.push(
                                    <List.Item
                                        actions={[<Button type="default" onClick={(e) => { onSwitchRole(item, item.account, 'CARRIER', item.accountId, item.account ? item.account.irisId : ''); }} disabled={entityType === 'CARRIER' && yourAccountUser === item && yourAccount === item.account}>{entityType === 'CARRIER' && yourAccountUser === item && yourAccount === item.account ? 'Selected' : 'Select'}</Button>]}
                                    >
                                        <List.Item.Meta
                                            avatar={
                                                <Avatar src={imageUrl} />
                                            }
                                            title={item.account ? item.account.name : ''}
                                            description={'Carrier'}
                                        />
                                    </List.Item>
                                );
                            }
                            if (isObjectNotEmpty(item.account) && item.account.entityTypes.includes("RECEIVER") === true) {
                                subItems.push(
                                    <List.Item
                                        actions={[<Button type="default" onClick={(e) => { onSwitchRole(item, item.account, 'RECEIVER', item.accountId, item.account ? item.account.irisId : ''); }} disabled={entityType === 'RECEIVER' && yourAccountUser === item && yourAccount === item.account}>{entityType === 'RECEIVER' && yourAccountUser === item && yourAccount === item.account ? 'Selected' : 'Select'}</Button>]}
                                    >
                                        <List.Item.Meta
                                            avatar={
                                                <Avatar src={imageUrl} />
                                            }
                                            title={item.account ? item.account.name : ''}
                                            description={'Receiver'}
                                        />
                                    </List.Item>
                                );
                            }
                            if (isObjectNotEmpty(item.account) && item.account.entityTypes.includes("PRODUCER") === true) {
                                subItems.push(
                                    <List.Item
                                        actions={[<Button type="default" onClick={(e) => { onSwitchRole(item, item.account, 'PRODUCER', item.accountId, item.account ? item.account.irisId : ''); }} disabled={entityType === 'PRODUCER' && yourAccountUser === item && yourAccount === item.account}>{entityType === 'PRODUCER' && yourAccountUser === item && yourAccount === item.account ? 'Selected' : 'Select'}</Button>]}
                                    >
                                        <List.Item.Meta
                                            avatar={
                                                <Avatar src={imageUrl} />
                                            }
                                            title={item.account ? item.account.name : ''}
                                            description={'Producer'}
                                        />
                                    </List.Item>
                                );
                            }
                            if (isObjectNotEmpty(item.account) && item.account.entityTypes.includes("BROKER") === true) {
                                subItems.push(
                                    <List.Item
                                        actions={[<Button type="default" onClick={(e) => { onSwitchRole(item, item.account, 'BROKER', item.accountId, item.account ? item.account.irisId : ''); }} disabled={entityType === 'BROKER' && yourAccountUser === item && yourAccount === item.account}>{entityType === 'BROKER' && yourAccountUser === item && yourAccount === item.account ? 'Selected' : 'Select'}</Button>]}
                                    >
                                        <List.Item.Meta
                                            avatar={
                                                <Avatar src={imageUrl} />
                                            }
                                            title={item.account ? item.account.name : ''}
                                            description={'Brokerage'}
                                        />
                                    </List.Item>
                                );
                            }
                            if (isObjectNotEmpty(item.account) && item.account.entityTypes.includes("FACTORING") === true) {
                                subItems.push(
                                    <List.Item
                                        actions={[<Button type="default" onClick={(e) => { onSwitchRole(item, item.account, 'FACTORING', item.accountId, item.account ? item.account.irisId : ''); }} disabled={entityType === 'FACTORING' && yourAccountUser === item && yourAccount === item.account}>{entityType === 'FACTORING' && yourAccountUser === item && yourAccount === item.account ? 'Selected' : 'Select'}</Button>]}
                                    >
                                        <List.Item.Meta
                                            avatar={
                                                <Avatar src={imageUrl} />
                                            }
                                            title={item.account ? item.account.name : ''}
                                            description={'Factoring Company'}
                                        />
                                    </List.Item>
                                );
                            }

                            return subItems;
                        }}
                    />
                ) : null}
                {isAdmin === true ? (
                    <>
                        <List itemLayout="horizontal">
                            <List.Item
                                actions={[<Button type="default" onClick={(e) => { onSwitchRole(null, null, 'STAFF', userId, 'IRIS-STAFF'); }} disabled={entityType === 'STAFF' && yourAccountUser === null && yourAccount === null}>{entityType === 'STAFF' && yourAccountUser === null && yourAccount === null ? 'Selected' : 'Select'}</Button>]}
                            >
                                <List.Item.Meta
                                    avatar={
                                        <Avatar src={imageUrl} />
                                    }
                                    title={firstName + ' ' + lastName}
                                    description={'System Admin'}
                                />
                            </List.Item>
                        </List>
                        <List
                            itemLayout="horizontal"
                            dataSource={accounts}
                            renderItem={item => {
                                let subItems = []
                                if (item.entityTypes.includes("SHIPPER") === true) {
                                    subItems.push(
                                        <List.Item
                                            actions={[<Button type="default" onClick={(e) => { onSwitchRole(null, item, 'SHIPPER', item.id, item.irisId); }} disabled={entityType === 'SHIPPER' && yourAccountUser === null && yourAccount === item}>{entityType === 'SHIPPER' && yourAccountUser === null && yourAccount === item ? 'Selected' : 'Select'}</Button>]}
                                        >
                                            <List.Item.Meta
                                                avatar={
                                                    <Avatar src={imageUrl} />
                                                }
                                                title={item.name}
                                                description={'Shipper Admin'}
                                            />
                                        </List.Item>
                                    );
                                }
                                if (item.entityTypes.includes("CARRIER") === true) {
                                    subItems.push(
                                        <List.Item
                                            actions={[<Button type="default" onClick={(e) => { onSwitchRole(null, item, 'CARRIER', item.id, item.irisId); }} disabled={entityType === 'CARRIER' && yourAccountUser === null && yourAccount === item}>{entityType === 'CARRIER' && yourAccountUser === null && yourAccount === item ? 'Selected' : 'Select'}</Button>]}
                                        >
                                            <List.Item.Meta
                                                avatar={
                                                    <Avatar src={imageUrl} />
                                                }
                                                title={item.name}
                                                description={'Carrier Admin'}
                                            />
                                        </List.Item>
                                    );
                                }
                                if (item.entityTypes.includes("RECEIVER") === true) {
                                    subItems.push(
                                        <List.Item
                                            actions={[<Button type="default" onClick={(e) => { onSwitchRole(null, item, 'RECEIVER', item.id, item.irisId); }} disabled={entityType === 'RECEIVER' && yourAccountUser === null && yourAccount === item}>{entityType === 'RECEIVER' && yourAccountUser === null && yourAccount === item ? 'Selected' : 'Select'}</Button>]}
                                        >
                                            <List.Item.Meta
                                                avatar={
                                                    <Avatar src={imageUrl} />
                                                }
                                                title={item.name}
                                                description={'Receiver Admin'}
                                            />
                                        </List.Item>
                                    );
                                }
                                if (item.entityTypes.includes("PRODUCER") === true) {
                                    subItems.push(
                                        <List.Item
                                            actions={[<Button type="default" onClick={(e) => { onSwitchRole(null, item, 'PRODUCER', item.id, item.irisId); }} disabled={entityType === 'PRODUCER' && yourAccountUser === null && yourAccount === item}>{entityType === 'PRODUCER' && yourAccountUser === null && yourAccount === item ? 'Selected' : 'Select'}</Button>]}
                                        >
                                            <List.Item.Meta
                                                avatar={
                                                    <Avatar src={imageUrl} />
                                                }
                                                title={item.name}
                                                description={'Producer Admin'}
                                            />
                                        </List.Item>
                                    );
                                }
                                if (item.entityTypes.includes("BROKER") === true) {
                                    subItems.push(
                                        <List.Item
                                            actions={[<Button type="default" onClick={(e) => { onSwitchRole(null, item, 'BROKER', item.id, item.irisId); }} disabled={entityType === 'BROKER' && yourAccountUser === null && yourAccount === item}>{entityType === 'BROKER' && yourAccountUser === null && yourAccount === item ? 'Selected' : 'Select'}</Button>]}
                                        >
                                            <List.Item.Meta
                                                avatar={
                                                    <Avatar src={imageUrl} />
                                                }
                                                title={item.name}
                                                description={'Brokerage Admin'}
                                            />
                                        </List.Item>
                                    );
                                }
                                if (item.entityTypes.includes("FACTORING") === true) {
                                    subItems.push(
                                        <List.Item
                                            actions={[<Button type="default" onClick={(e) => { onSwitchRole(null, item, 'FACTORING', item.id, item.irisId); }} disabled={entityType === 'FACTORING' && yourAccountUser === null && yourAccount === item}>{entityType === 'FACTORING' && yourAccountUser === null && yourAccount === item ? 'Selected' : 'Select'}</Button>]}
                                        >
                                            <List.Item.Meta
                                                avatar={
                                                    <Avatar src={imageUrl} />
                                                }
                                                title={item.name}
                                                description={'Factoring Company Admin'}
                                            />
                                        </List.Item>
                                    );
                                }

                                return subItems;
                            }}
                        />
                    </>
                ) : null}
            </Spin>
        </Modal>
    );
};

export default RoleSwitcherModal;
