import React, { useState, useMemo, useEffect } from 'react';
import { Col, Row, Button, Spin, Select, Alert, Input, Timeline, Empty, Drawer, Radio, Switch, InputNumber, Card, Popover, Modal } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Enums from '../../shared/enums';
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import StringFormatter from '../../shared/stringFormatter';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import { orderBy } from 'lodash';
import { DeleteOutlined, DownOutlined, InfoCircleOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import DataCollapsableRow from '../DataCollapsableRow/DataCollapsableRow';
import DatePicker from '../DatePickerAutoAccept/DatePickerAutoAccept';
import { ErrorMessage } from '@hookform/error-message';
import MomentDate from '../../shared/dateFormatter';
import moment from 'moment';
import classes from './NewLoadSchedule.module.scss';
import Header from '../Header/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruckLoading } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router';
import NewLocation from '../NewLocation/NewLocation';
import { isBooleanTrue, isListEmpty, isListNotEmpty, isNotNullOrUndefined, isNullOrUndefined, isNumberEmptyOrZero, isNumberGreaterThanZero, isNumberNotEmpty, isObjectEmpty, isObjectNotEmpty, isStringEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import LoadStopUtils from '../../api/utils/loadStopUtils';
import { selectListRecords } from '../../store/utility';
import EntityUtils from '../../api/entityUtils';
import DataRow from '../DataRow/DataRow';
import DataContactInfoRow from '../DataContactInfoRow/DataContactInfoRow';
import FormItemPointOfContact from '../FormItemPointOfContact/FormItemPointOfContact';
import Fieldset from '../FormFieldset/FormFieldset';
import DataAddressRow from '../DataAddressRow/DataAddressRow';
import UploadFile from '../UploadFile/UploadFile';
import FormItemFile from '../FormItemFile/FormItemFile';
import DataListRow from '../DataListRow/DataListRow';

const { Option } = Select;
const { TextArea } = Input;
const stringFormatter = new StringFormatter();
const momentDate = new MomentDate();

const NewLoadSchedule = ({ shipperData, setShipperData, pickUpStops, setPickUpStops, dropOffStops, setDropOffStops, completedSteps, setCompletedSteps, nextStepAction, nextStepActionName, docFiles, setDocFiles, dontRequireLoadSpecificInformation, hideLoadSpecificInformation, cancelButton = null, ...props }) => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    //#endregion
    //#region enums

    const appointmentTypeOptions = Enums.AppointmentTypes.selectListOptions();
    const unitPackagingOptions = Enums.UnitPackaging.selectListOptions();
    const bulkPackagingOptions = Enums.BulkPackaging.selectListOptions();

    //#endregion
    //#region useForms

    const methods = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            pickUpLocationId: null,
            dropOffLocationId: null
        }
    });
    const methods2 = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            apptType: null,
            'apptPointOfContact.firstName': '',
            'apptPointOfContact.lastName': '',
            'apptPointOfContact.email': '',
            'apptPointOfContact.phone': '',
            'apptPointOfContact.phoneExt': '',
            apptInstructions: '',
            apptWindowStartDateTime: null,
            apptWindowEndDateTime: null,
            requestedDateTime: null,
            apptDateTime: null,
            apptNumber: '',
            apptCallAheadDateTime: null,
            pickUpNumber: '',
            purchaseOrderNumber: '',
            dropOffNumber: '',
            apptWindowType: null,
            allDayDate: null
        }
    });
    const methods3 = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            locationId: null
        }
    });
    const methods4 = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            shipperId: null
        }
    });
    const methods5 = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            existingBOLNumber: null,
            newBOLNumber: ''
        }
    });
    const methods6 = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            loadingType: null,
            hasLumperFee: false,
            lumperFee: null,
            hasDriverAssist: false,
            driverAssist: null,
            specialInstructions: ''
        }
    });
    const methods7 = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            commodityId: null,
            unitPackagingInitCount: null,
            unitPackaging: null,
            bulkPackagingInitCount: null,
            bulkPackaging: null
        }
    });

    //#endregion
    //#region useStates

    const [locationsList, setLocationsList] = useState([]);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [showEditStopAppointment, setShowEditStopAppointment] = useState(false);
    const [showEditStopLocation, setShowEditStopLocation] = useState(false);
    const [showNewPickUpStopLocation, setShowNewPickUpStopLocation] = useState(false);
    const [showNewDropOffStopLocation, setShowNewDropOffStopLocation] = useState(false);
    const [showFirstComeFirstServeDetails, setShowFirstComeFirstServeDetails] = useState(false);
    const [showHaveAppointmentDetails, setShowHaveAppointmentDetails] = useState(false);
    const [showNeedAppointmentDetails, setShowNeedAppointmentDetails] = useState(false);
    const [pickUpsEnabled, setPickUpsEnabled] = useState(false);
    const [dropOffsEnabled, setDropOffsEnabled] = useState(false);
    const [showEditShipper, setShowEditShipper] = useState(false);
    const [shippers, setShippers] = useState([]);
    const [isShippersLoading, setIsShippersLoading] = useState(false);
    const [showNewLocation, setShowNewLocation] = useState(false);
    const [showAddStopBOL, setShowAddStopBOL] = useState(false);
    const [showEditStopLoadingSpecifics, setShowEditStopLoadingSpecifics] = useState(false);
    const [showAddStopCommodity, setShowAddStopCommodity] = useState(false);
    const [lumperFeeDisabled, setLumperFeeDisabled] = useState(true);
    const [driverAssistDisabled, setDriverAssistDisabled] = useState(true);
    const [bolNumberList, setBOLNumberList] = useState([]);
    const [bolDocFiles, setBOLDocFiles] = useState([]);
    const [showNewBOLNumber, setShowNewBOLNumber] = useState(false);
    const [allDayDateDisabled, setAllDayDateDisabled] = useState(true);
    const [apptWindowDisabled, setApptWindowDisabled] = useState(true);

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const entityType = useSelector(state => state.auth.entityType);
    const entityId = useSelector(state => state.auth.entityId);
    const locations = useSelector(state => selectListRecords(state.orchestrator.locations, entityType === 'STAFF' ? (isObjectNotEmpty(shipperData) && isStringNotEmpty(shipperData.shipperId) ? shipperData.shipperId : null) : entityId));
    const accounts = useSelector(state => state.orchestrator.accounts);
    const linkedAccounts = useSelector(state => selectListRecords(state.orchestrator.linkedAccounts, entityType !== 'STAFF' ? entityId : null));
    const commodities = useSelector(state => state.orchestrator.commodities);

    //#endregion
    //#region toggles

    const toggleNewPickUpStopLocation = () => {
        // reset form
        methods.setValue('pickUpLocationId', null);
        methods.clearErrors();

        setShowNewPickUpStopLocation(!showNewPickUpStopLocation);
    };

    const toggleNewDropOffStopLocation = () => {
        // reset form
        methods.setValue('dropOffLocationId', null);
        methods.clearErrors();

        setShowNewDropOffStopLocation(!showNewDropOffStopLocation);
    };

    const toggleEditShipper = () => {
        // reset form
        methods4.setValue('shipperId', null);
        methods4.clearErrors();

        setShowEditShipper(!showEditShipper);
    };

    const toggleEditStopAppointment = () => {
        setShowFirstComeFirstServeDetails(false);
        setShowHaveAppointmentDetails(false);
        setShowNeedAppointmentDetails(false);

        // reset form
        methods2.setValue('apptType', null);
        methods2.setValue('apptPointOfContact.firstName', '');
        methods2.setValue('apptPointOfContact.lastName', '');
        methods2.setValue('apptPointOfContact.email', '');
        methods2.setValue('apptPointOfContact.phone', '');
        methods2.setValue('apptPointOfContact.phoneExt', '');
        methods2.setValue('apptInstructions', '');
        methods2.setValue('apptWindowStartDateTime', null);
        methods2.setValue('apptWindowEndDateTime', null);
        methods2.setValue('requestedDateTime', null);
        methods2.setValue('apptDateTime', null);
        methods2.setValue('apptNumber', '');
        methods2.setValue('apptCallAheadDateTime', null);
        methods2.setValue('pickUpNumber', '');
        methods2.setValue('purchaseOrderNumber', '');
        methods2.setValue('dropOffNumber', '');
        methods2.setValue('apptWindowType', null);
        methods2.setValue('allDayDate', null);
        methods2.clearErrors();

        setShowEditStopAppointment(!showEditStopAppointment);
    };

    const toggleEditStopLocation = () => {
        // reset form
        methods3.setValue('locationId', null);
        methods3.clearErrors();

        setShowEditStopLocation(!showEditStopLocation);
    };

    const toggleNewLocation = () => {
        setShowNewLocation(!showNewLocation);
    };

    const toggleAddStopBOL = () => {
        // reset form
        setBOLDocFiles([]);
        setShowNewBOLNumber(false);
        methods5.setValue(`existingBOLNumber`, null);
        methods5.setValue(`newBOLNumber`, '');
        methods5.clearErrors();

        setShowAddStopBOL(!showAddStopBOL);
    };

    const toggleEditStopLoadingSpecifics = () => {
        setDriverAssistDisabled(true);
        setLumperFeeDisabled(true);

        // reset form
        methods6.setValue('loadingType', null);
        methods6.setValue('hasLumperFee', false);
        methods6.setValue('lumperFee', null);
        methods6.setValue('hasDriverAssist', false);
        methods6.setValue('driverAssist', null);
        methods6.setValue('specialInstructions', '');
        methods6.clearErrors();

        setShowEditStopLoadingSpecifics(!showEditStopLoadingSpecifics);
    };

    const toggleAddStopCommodity = () => {
        // reset form
        methods7.setValue('commodityId', null);
        methods7.setValue('unitPackagingInitCount', null);
        methods7.setValue('unitPackaging', null);
        methods7.setValue('bulkPackagingInitCount', null);
        methods7.setValue('bulkPackaging', null);
        methods7.clearErrors();

        setShowAddStopCommodity(!showAddStopCommodity);
    };

    //#endregion
    //#region onChanges

    const onChangeAppointmentType = (value) => {
        // console.log(`selected ${value}`);
        if (isStringNotEmpty(value)) {
            if (value === "FIRST_COME_FIRST_SERVE") {
                setShowFirstComeFirstServeDetails(true);
                setShowHaveAppointmentDetails(false);
                setShowNeedAppointmentDetails(false);
            } else if (value === "HAVE_APPOINTMENT") {
                setShowFirstComeFirstServeDetails(false);
                setShowHaveAppointmentDetails(true);
                setShowNeedAppointmentDetails(false);
            } else if (value === "NEED_APPOINTMENT") {
                setShowFirstComeFirstServeDetails(false);
                setShowHaveAppointmentDetails(false);
                setShowNeedAppointmentDetails(true);
            } else {
                setShowFirstComeFirstServeDetails(false);
                setShowHaveAppointmentDetails(false);
                setShowNeedAppointmentDetails(false);
            }
        }
    };

    const onChangeBOLNumber = (value) => {
        // console.log(value);
        if (isStringNotEmpty(value)) {
            if (value === 'NEW') {
                setShowNewBOLNumber(true);
            } else {
                setShowNewBOLNumber(false);
            }
        } else {
            setShowNewBOLNumber(false);
        }
    };

    const onChangeApptWindowType = (value) => {
        methods2.clearErrors();
        if (isStringNotEmpty(value)) {
            if (value === 'ALL_DAY') {
                setAllDayDateDisabled(false);
                setApptWindowDisabled(true);
            } else if (value === 'CUSTOM') {
                setAllDayDateDisabled(true);
                setApptWindowDisabled(false);
            } else {
                setAllDayDateDisabled(true);
                setApptWindowDisabled(true);
            }
        } else {
            setAllDayDateDisabled(true);
            setApptWindowDisabled(true);
        }
    }

    //#endregion
    //#region submits and validators

    const validateStops = (pickUps, dropOffs, isSubmitting = false) => {
        let hasErrors = false;
        methods.clearErrors();

        // console.log(pickUps);
        // console.log(dropOffs);

        if (isListNotEmpty(pickUps) && isListNotEmpty(dropOffs)) {
            pickUps.forEach((stop, stopIndex, arr) => {
                let errors = {};
                if (isNullOrUndefined(stop.requestedDateTime)) {
                    if (isSubmitting === true) {
                        errors.missingRequestedDateTime = `Pick-Up${arr.length > 1 ? ' ' + (stopIndex + 1) : ''} is missing a Pick-Up Date.`;
                    }
                    hasErrors = true;
                }

                if (isStringEmpty(stop.apptType)) {
                    if (isSubmitting === true) {
                        errors.missingApptType = `Pick-Up${arr.length > 1 ? ' ' + (stopIndex + 1) : ''} is missing Appointment Information.`;
                    }
                    hasErrors = true;
                }

                if (isStringEmpty(stop.pickUpNumber) && (dontRequireLoadSpecificInformation === false && hideLoadSpecificInformation === false)) {
                    if (isSubmitting === true) {
                        errors.missingPickUpNumber = `Pick-Up${arr.length > 1 ? ' ' + (stopIndex + 1) : ''} is missing a Pick-Up Number.`;
                    }
                    hasErrors = true;
                }

                if (isStringEmpty(stop.loadingType)) {
                    if (isSubmitting === true) {
                        errors.missingLoadingType = `Pick-Up${arr.length > 1 ? ' ' + (stopIndex + 1) : ''} is missing a Loading Type.`;
                    }
                    hasErrors = true;
                }

                // validate date to make sure stops are in order
                if (stopIndex > 0) {
                    let stop1 = { ...arr[stopIndex - 1] };
                    let stop2 = { ...stop };
                    if (isNotNullOrUndefined(stop1.requestedDateTime) && isNotNullOrUndefined(stop2.requestedDateTime)) {
                        if (stop2.requestedDateTime < stop1.requestedDateTime) {
                            if (stop1.apptType === 'NEED_APPOINTMENT' || stop2.apptType === 'NEED_APPOINTMENT') {
                                // if appointment type is need appointment then just make sure the requestedDateTime is the same day or later, time doesnt matter
                                let stop1DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop1.requestedDateTime, stop1.timeZone);
                                let stop2DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop2.requestedDateTime, stop2.timeZone);
                                if (moment(stop2DateString) < moment(stop1DateString)) {
                                    errors.validate = `The date for Pick-Up ${stopIndex + 1} cannot be before the date for Pick-Up ${stopIndex}.`;
                                    hasErrors = true;
                                }
                            } else {
                                errors.validate = `The date for Pick-Up ${stopIndex + 1} cannot be before the date for Pick-Up ${stopIndex}.`;
                                hasErrors = true;
                            }
                        }
                    }
                }

                if (isObjectNotEmpty(errors)) {
                    methods.setError(`pickUpStops-${stopIndex}`, { types: { ...errors } });
                }
            });

            dropOffs.forEach((stop, stopIndex, arr) => {
                let errors = {};
                if (isNullOrUndefined(stop.requestedDateTime)) {
                    if (isSubmitting === true) {
                        errors.missingRequestedDateTime = `Drop-Off${arr.length > 1 ? ' ' + (stopIndex + 1) : ''} is missing a Drop-Off Date.`;
                    }
                    hasErrors = true;
                }

                if (isStringEmpty(stop.apptType)) {
                    if (isSubmitting === true) {
                        errors.missingApptType = `Drop-Off${arr.length > 1 ? ' ' + (stopIndex + 1) : ''} is missing Appointment Information.`;
                    }
                    hasErrors = true;
                }

                if (isStringEmpty(stop.dropOffNumber) && (dontRequireLoadSpecificInformation === false && hideLoadSpecificInformation === false)) {
                    if (isSubmitting === true) {
                        errors.missingDropOffNumber = `Drop-Off${arr.length > 1 ? ' ' + (stopIndex + 1) : ''} is missing a Drop-Off Number.`;
                    }
                    hasErrors = true;
                }
                if (isStringEmpty(stop.purchaseOrderNumber) && (dontRequireLoadSpecificInformation === false && hideLoadSpecificInformation === false)) {
                    if (isSubmitting === true) {
                        errors.missingPurchaseOrderNumber = `Drop-Off${arr.length > 1 ? ' ' + (stopIndex + 1) : ''} is missing a Customer Purchase Order Number.`;
                    }
                    hasErrors = true;
                }

                if (isStringEmpty(stop.loadingType)) {
                    if (isSubmitting === true) {
                        errors.missingLoadingType = `Drop-Off${arr.length > 1 ? ' ' + (stopIndex + 1) : ''} is missing an Unloading Type.`;
                    }
                    hasErrors = true;
                }
                if ((isListEmpty(stop.bolNumbers) || stop.bolNumbers.length !== 1) && (dontRequireLoadSpecificInformation === false && hideLoadSpecificInformation === false)) {
                    if (isSubmitting === true) {
                        errors.missingBOLNumber = `Drop-Off${arr.length > 1 ? ' ' + (stopIndex + 1) : ''} must have 1 BOL Number.`;
                    }
                    hasErrors = true;
                }

                // validate date to make sure stops are in order
                if (stopIndex > 0) {
                    let stop1 = { ...arr[stopIndex - 1] };
                    let stop2 = { ...stop };
                    if (isNotNullOrUndefined(stop1.requestedDateTime) && isNotNullOrUndefined(stop2.requestedDateTime)) {
                        if (stop2.requestedDateTime < stop1.requestedDateTime) {
                            if (stop1.apptType === 'NEED_APPOINTMENT' || stop2.apptType === 'NEED_APPOINTMENT') {
                                // if appointment type is need appointment then just make sure the requestedDateTime is the same day or later, time doesnt matter
                                let stop1DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop1.requestedDateTime, stop1.timeZone);
                                let stop2DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop2.requestedDateTime, stop2.timeZone);
                                if (moment(stop2DateString) < moment(stop1DateString)) {
                                    errors.validate = `The date for Drop-Off ${stopIndex + 1} cannot be before the date for Drop-Off ${stopIndex}.`;
                                    hasErrors = true;
                                }
                            } else {
                                errors.validate = `The date for Drop-Off ${stopIndex + 1} cannot be before the date for Drop-Off ${stopIndex}.`;
                                hasErrors = true;
                            }
                        }
                    }
                } else if (stopIndex === 0) {
                    let stop1 = { ...pickUps[pickUps.length - 1] };
                    let stop2 = { ...stop };
                    if (isNotNullOrUndefined(stop1.requestedDateTime) && isNotNullOrUndefined(stop2.requestedDateTime)) {
                        if (stop2.requestedDateTime < stop1.requestedDateTime) {
                            if (stop1.apptType === 'NEED_APPOINTMENT' || stop2.apptType === 'NEED_APPOINTMENT') {
                                // if appointment type is need appointment then just make sure the requestedDateTime is the same day or later, time doesnt matter
                                let stop1DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop1.requestedDateTime, stop1.timeZone);
                                let stop2DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop2.requestedDateTime, stop2.timeZone);
                                // console.log(stop1DateString);
                                // console.log(stop2DateString);
                                if (moment(stop2DateString) < moment(stop1DateString)) {
                                    errors.validate = `The date for Drop-Off${dropOffs.length > 1 ? ' ' + (stopIndex + 1) : ''} cannot be before the date for Pick-Up${pickUps.length > 1 ? ' ' + (pickUps.length) : ''}.`;
                                    hasErrors = true;
                                }
                            } else {
                                errors.validate = `The date for Drop-Off${dropOffs.length > 1 ? ' ' + (stopIndex + 1) : ''} cannot be before the date for Pick-Up${pickUps.length > 1 ? ' ' + (pickUps.length) : ''}.`;
                                hasErrors = true;
                            }
                        }
                    }
                }

                if (isObjectNotEmpty(errors)) {
                    methods.setError(`dropOffStops-${stopIndex}`, { types: { ...errors } });
                }
            });

            // validate BOL Numbers
            // the Add BOL functionality will force the numbr of BOL Numbers to match the number of drop-off stops
            if (bolNumberList.length !== dropOffs.length && (dontRequireLoadSpecificInformation === false && hideLoadSpecificInformation === false)) {
                if (isSubmitting === true) {
                    methods.setError('dropOffStops', { type: 'badBOLNumberCount', message: 'There does not appear to be a unique BOL Number for each Drop-Off. Please resolve the issue and try again.' });
                }
                hasErrors = true;
            }

            // TODO: validate the commodities - skipping this for now until we have an inventory system
        } else {
            if (isListEmpty(pickUps)) {
                if (isSubmitting === true) {
                    methods.setError('pickUpStops', { type: 'required', message: 'There must be at least 1 Pick-Up.' });
                }
                hasErrors = true;
            }
            if (isListEmpty(dropOffs)) {
                if (isSubmitting === true) {
                    methods.setError('dropOffStops', { type: 'required', message: 'There must be at least 1 Drop-Off.' });
                }
                hasErrors = true;
            }
        }

        // console.log(`hasErrors: ${hasErrors}`);

        return !hasErrors;
    };

    const saveStops = (pickUps, dropOffs) => {
        let updatedPickUps = [];
        let updatedDropOffs = [];
        if (isListNotEmpty(pickUps)) {
            pickUps.forEach((stop, stopIndex, arr) => {
                updatedPickUps.push({
                    ...stop,
                    sequence: stopIndex,
                    id: stopIndex,
                    stopTypeSequence: stopIndex
                });
            });
        }

        if (isListNotEmpty(dropOffs)) {
            dropOffs.forEach((stop, stopIndex, arr) => {
                updatedDropOffs.push({
                    ...stop,
                    sequence: stopIndex + pickUps.length,
                    id: stopIndex + pickUps.length,
                    stopTypeSequence: stopIndex
                });
            });
        }

        setPickUpStops(updatedPickUps);
        setDropOffStops(updatedDropOffs);
    };

    const onEditShipper = (data) => {
        const shipperId = data.shipperId;

        let payload = {};
        if (isStringNotEmpty(shipperId)) {
            payload.shipperId = shipperId;
            let selectedShipperAccount = EntityUtils.getAccountInfo(shipperId, accounts);
            if (isObjectNotEmpty(selectedShipperAccount)) {
                payload.shipperName = selectedShipperAccount.name;
                payload.shipper = selectedShipperAccount;
            }

            setShipperData({ ...payload });
        }

        toggleEditShipper();
    };

    const onEditStopLocation = (data) => {
        const stopLocationId = data.locationId;
        let stopLocation = EntityUtils.getLocationInfo(stopLocationId, locationsList);
        if (isObjectNotEmpty(stopLocation)) {
            const updatedValues = {
                stopLocation: stopLocation,
                stopLocationId: stopLocationId,
                timeZone: stopLocation.timeZone,
                latitude: stopLocation.latitude,
                longitude: stopLocation.longitude,
                loadId: 'new-load'
            };

            if (isObjectNotEmpty(selectedRecord)) {
                if (isNumberGreaterThanZero(stopLocation.lumperFee)) {
                    updatedValues.lumperFee = stopLocation.lumperFee;
                    updatedValues.hasLumperFee = true;
                } else {
                    updatedValues.lumperFee = null;
                    updatedValues.hasLumperFee = false;
                }
                if (isStringNotEmpty(stopLocation.specialInstructions)) {
                    updatedValues.specialInstructions = stopLocation.specialInstructions;
                } else {
                    updatedValues.specialInstructions = '';
                }
                if (isObjectNotEmpty(stopLocation.pointOfContact)) {
                    updatedValues.apptPointOfContact = stopLocation.pointOfContact;
                } else {
                    updatedValues.apptPointOfContact = null;
                }

                updatedValues.apptType = null;

                if (selectedRecord.stopType === 'PICK_UP') {
                    let existingList = [...pickUpStops];
                    const index = existingList.findIndex(obj => obj.id === selectedRecord.id);
                    // only update if the record exists in the list
                    if (index !== -1) {
                        const updatedRecord = {
                            ...existingList[index],
                            ...updatedValues
                        };
                        const updatedRecords = [
                            ...existingList.slice(0, index), // everything before current obj
                            updatedRecord,
                            ...existingList.slice(index + 1), // everything after current obj
                        ];

                        saveStops(updatedRecords, dropOffStops);
                    }
                } else if (selectedRecord.stopType === 'DROP_OFF') {
                    let existingList = [...dropOffStops];
                    const index = existingList.findIndex(obj => obj.id === selectedRecord.id);
                    // only update if the record exists in the list
                    if (index !== -1) {
                        const updatedRecord = {
                            ...existingList[index],
                            ...updatedValues
                        };
                        const updatedRecords = [
                            ...existingList.slice(0, index), // everything before current obj
                            updatedRecord,
                            ...existingList.slice(index + 1), // everything after current obj
                        ];

                        saveStops(pickUpStops, updatedRecords);
                    }
                }

                toggleEditStopLocation();
                setSelectedRecord(null);
            } else {
                return;
            }
        }
    };

    const onEditStopAppointment = (data) => {
        const apptType = data.apptType;
        const apptPointOfContact = data.apptPointOfContact;
        const apptInstructions = data.apptInstructions;
        const apptWindowStartDateTime = data.apptWindowStartDateTime;
        const apptWindowEndDateTime = data.apptWindowEndDateTime;
        const apptDateTime = data.apptDateTime;
        const apptNumber = data.apptNumber;
        const requestedDateTime = data.requestedDateTime;
        const apptCallAheadDateTime = data.apptCallAheadDateTime;
        const pickUpNumber = data.pickUpNumber;
        const purchaseOrderNumber = data.purchaseOrderNumber;
        const dropOffNumber = data.dropOffNumber;
        const allDayDate = data.allDayDate;
        const apptWindowType = data.apptWindowType;

        const updatedValues = {
            apptType: apptType,
            apptPointOfContact: apptPointOfContact
        };

        if (isStringNotEmpty(apptInstructions)) {
            updatedValues.apptInstructions = apptInstructions;
        } else {
            updatedValues.apptInstructions = undefined;
        }

        if (isStringNotEmpty(pickUpNumber)) {
            updatedValues.pickUpNumber = pickUpNumber;
        } else {
            updatedValues.pickUpNumber = undefined;
        }
        if (isStringNotEmpty(purchaseOrderNumber)) {
            updatedValues.purchaseOrderNumber = purchaseOrderNumber;
        } else {
            updatedValues.purchaseOrderNumber = undefined;
        }
        if (isStringNotEmpty(dropOffNumber)) {
            updatedValues.dropOffNumber = dropOffNumber;
        } else {
            updatedValues.dropOffNumber = undefined;
        }

        if (apptType === 'FIRST_COME_FIRST_SERVE') {
            if (apptWindowEndDateTime < apptWindowStartDateTime) {
                // console.log(apptWindowEndDateTime);
                // console.log(apptWindowStartDateTime);
                methods2.setError("apptWindowEndDateTime", { type: "validate", message: "The End Date/Time cannot be before the Start Date/Time." });

                return;
            } else {
                methods2.clearErrors('apptWindowEndDateTime');
            }
            updatedValues.apptWindowStartDateTime = apptWindowStartDateTime;
            updatedValues.apptWindowEndDateTime = apptWindowEndDateTime;
            updatedValues.requestedDateTime = apptWindowStartDateTime;

            updatedValues.apptWindowType = apptWindowType;
            if (apptWindowType === 'ALL_DAY') {
                updatedValues.allDayDate = allDayDate;
            } else {
                updatedValues.allDayDate = undefined;
            }

            updatedValues.apptDateTime = undefined;
            updatedValues.apptNumber = undefined;
            updatedValues.apptCallAheadDateTime = undefined;
        } else if (apptType === 'HAVE_APPOINTMENT') {
            updatedValues.apptDateTime = apptDateTime;
            if (isStringNotEmpty(apptNumber)) {
                updatedValues.apptNumber = apptNumber;
            } else {
                updatedValues.apptNumber = undefined;
            }
            updatedValues.requestedDateTime = apptDateTime;
            updatedValues.apptWindowStartDateTime = undefined;
            updatedValues.apptWindowEndDateTime = undefined;
            updatedValues.allDayDate = undefined;
            updatedValues.apptWindowType = undefined;
            updatedValues.apptCallAheadDateTime = undefined;
        } else if (apptType === 'NEED_APPOINTMENT') {
            updatedValues.apptCallAheadDateTime = apptCallAheadDateTime;
            updatedValues.requestedDateTime = requestedDateTime;
            updatedValues.apptDateTime = undefined;
            updatedValues.apptNumber = undefined;
            updatedValues.apptWindowStartDateTime = undefined;
            updatedValues.apptWindowEndDateTime = undefined;
            updatedValues.allDayDate = undefined;
            updatedValues.apptWindowType = undefined;
        }

        if (isObjectNotEmpty(selectedRecord)) {
            if (selectedRecord.stopType === 'PICK_UP') {
                let existingList = [...pickUpStops];
                const index = existingList.findIndex(obj => obj.id === selectedRecord.id);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        ...updatedValues
                    };

                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    saveStops(updatedRecords, dropOffStops);
                    validateStops(updatedRecords, dropOffStops);
                }
            } else if (selectedRecord.stopType === 'DROP_OFF') {
                let existingList = [...dropOffStops];
                const index = existingList.findIndex(obj => obj.id === selectedRecord.id);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        ...updatedValues
                    };
                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    saveStops(pickUpStops, updatedRecords);
                    validateStops(pickUpStops, updatedRecords);
                }
            }

            toggleEditStopAppointment();
            setSelectedRecord(null);
        } else {
            return;
        }
    };

    const onAddStopBOL = (data) => {
        const newBOLNumber = data.newBOLNumber;
        const existingBOLNumber = data.existingBOLNumber;

        methods5.clearErrors();
        methods.clearErrors();

        let bolNumber = null;
        if (isStringNotEmpty(existingBOLNumber) && existingBOLNumber !== 'NEW') {
            bolNumber = existingBOLNumber;
        } else if (isStringNotEmpty(newBOLNumber)) {
            bolNumber = newBOLNumber;
        }

        if (isStringNotEmpty(bolNumber)) {
            if (isListNotEmpty(bolNumberList) && isListNotEmpty(dropOffStops)) {
                if (bolNumberList.length > dropOffStops.length) {
                    methods5.setError('existingBOLNumber', { type: 'validateBOLCount', message: 'You cannot add more BOL Numbers than the total number of Drop-Offs for this Load.' });
                }
            }

            if (isObjectNotEmpty(selectedRecord)) {
                let stopBOLNumberList = [];
                if (isListNotEmpty(selectedRecord.bolNumbers)) {
                    stopBOLNumberList = [...selectedRecord.bolNumbers];
                    if (stopBOLNumberList.find(b => b === bolNumber) === undefined) {
                        stopBOLNumberList.push(bolNumber);
                    } else {
                        methods5.setError('newBOLNumber', { type: "duplicate", message: 'This BOL Number has already been added to this location.' });
                        return;
                    }
                } else {
                    stopBOLNumberList.push(bolNumber);
                }

                if (isListNotEmpty(bolDocFiles)) {
                    let newFiles = [];
                    bolDocFiles.forEach((file) => {
                        // console.log('file:');
                        // console.log(file);
                        let fileJson = { stopId: selectedRecord.id, bolNumber: bolNumber, file: file };
                        newFiles.push(fileJson);
                    });
                    setDocFiles(oldFiles => [...oldFiles, ...newFiles]);
                    setBOLDocFiles([]);
                }

                if (selectedRecord.stopType === 'PICK_UP') {
                    let existingList = [...pickUpStops];
                    const index = existingList.findIndex(obj => obj.id === selectedRecord.id);
                    // only update if the record exists in the list
                    if (index !== -1) {
                        const updatedValues = {
                            bolNumbers: [...stopBOLNumberList]
                        };

                        const updatedRecord = {
                            ...existingList[index],
                            ...updatedValues
                        };

                        let updatedRecords = [
                            ...existingList.slice(0, index), // everything before current obj
                            updatedRecord,
                            ...existingList.slice(index + 1), // everything after current obj
                        ];

                        if (dropOffStops.length === 1) {
                            // if there is only one drop-off stop, set the value for the BOL Number
                            let existingDropOffList = [...dropOffStops];
                            const updatedDropOffValues = {
                                bolNumbers: [...stopBOLNumberList]
                            };

                            const updatedDropOffRecord = {
                                ...existingDropOffList[0],
                                ...updatedDropOffValues
                            };
                            const updatedDropOffRecords = [
                                ...existingDropOffList.slice(0, 0), // everything before current obj
                                updatedDropOffRecord,
                                ...existingDropOffList.slice(1), // everything after current obj
                            ];

                            setDropOffStops([...updatedDropOffRecords]);

                            // update the other pick-up stops too since there is only one drop-off stop so all the pick-up stops will have the same BOL Number
                            if (pickUpStops.length > 1) {
                                pickUpStops.forEach((pickUpStop) => {
                                    if (pickUpStop.id !== selectedRecord.id) {
                                        const otherPickUpStopIndex = updatedRecords.findIndex(obj => obj.id === pickUpStop.id);
                                        if (otherPickUpStopIndex !== -1) {
                                            updatedRecords[otherPickUpStopIndex].bolNumbers = [...stopBOLNumberList];
                                        }
                                    }
                                })
                            }
                        }

                        setPickUpStops(updatedRecords);
                    }
                } else if (selectedRecord.stopType === 'DROP_OFF') {
                    let existingList = [...dropOffStops];
                    const index = existingList.findIndex(obj => obj.id === selectedRecord.id);
                    // only update if the record exists in the list
                    if (index !== -1) {
                        const updatedValues = {
                            bolNumbers: [...stopBOLNumberList]
                        };

                        const updatedRecord = {
                            ...existingList[index],
                            ...updatedValues
                        };
                        const updatedRecords = [
                            ...existingList.slice(0, index), // everything before current obj
                            updatedRecord,
                            ...existingList.slice(index + 1), // everything after current obj
                        ];

                        setDropOffStops([...updatedRecords]);
                    }
                }

                toggleAddStopBOL();
                setSelectedRecord(null);
            } else {
                methods5.setError('existingBOLNumber', { type: 'required', message: 'Required Field' });
                return;
            }
        } else {
            methods5.setError('existingBOLNumber', { type: 'required', message: 'Required Field' });
            return;
        }
    };

    const onRemoveStopBOL = (stop, bolNumber, index) => {
        if (isObjectNotEmpty(stop)) {
            let stopBOLNumberList = [];
            if (isListNotEmpty(stop.bolNumbers)) {
                stopBOLNumberList = [...stop.bolNumbers];
                stopBOLNumberList.splice(index, 1);

                // also remove the BOL Files for this BOL Number for this stop
                let filteredArray = docFiles.filter(d => d.stopId !== stop.id && d.bolNumber !== bolNumber);
                setDocFiles([...filteredArray]);
            }
            if (stop.stopType === 'PICK_UP') {
                let existingList = [...pickUpStops];
                const index = existingList.findIndex(obj => obj.id === stop.id);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        bolNumbers: [...stopBOLNumberList]
                    };

                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    setPickUpStops(updatedRecords);

                    if (dropOffStops.length === 1) {
                        // if there is only one drop-off stop, set the value for the BOL Number
                        let existingDropOffList = [...dropOffStops];
                        const updatedDropOffValues = {
                            bolNumbers: [...stopBOLNumberList]
                        };

                        const updatedDropOffRecord = {
                            ...existingDropOffList[0],
                            ...updatedDropOffValues
                        };
                        const updatedDropOffRecords = [
                            ...existingDropOffList.slice(0, 0), // everything before current obj
                            updatedDropOffRecord,
                            ...existingDropOffList.slice(1), // everything after current obj
                        ];

                        setDropOffStops([...updatedDropOffRecords]);
                    }
                }
            } else if (stop.stopType === 'DROP_OFF') {
                let existingList = [...dropOffStops];
                const index = existingList.findIndex(obj => obj.id === stop.id);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        bolNumbers: [...stopBOLNumberList]
                    };
                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    setDropOffStops([...updatedRecords]);
                }
            }
        } else {
            return;
        }
    };

    const onEditStopLoadingSpecifics = (data) => {
        const loadingType = data.loadingType;
        const specialInstructions = data.specialInstructions;
        const hasLumperFee = data.hasLumperFee;
        const lumperFee = data.lumperFee;
        const hasDriverAssist = data.hasDriverAssist;
        const driverAssist = data.driverAssist;

        const updatedValues = {
            loadingType: loadingType,
            hasLumperFee: hasLumperFee,
            lumperFee: lumperFee,
            hasDriverAssist: hasDriverAssist,
            driverAssist: driverAssist,
            specialInstructions: specialInstructions
        };

        if (isObjectNotEmpty(selectedRecord)) {
            if (selectedRecord.stopType === 'PICK_UP') {
                let existingList = [...pickUpStops];
                const index = existingList.findIndex(obj => obj.id === selectedRecord.id);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        ...updatedValues
                    };

                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    setPickUpStops(updatedRecords);
                    methods.clearErrors();
                }
            } else if (selectedRecord.stopType === 'DROP_OFF') {
                let existingList = [...dropOffStops];
                const index = existingList.findIndex(obj => obj.id === selectedRecord.id);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        ...updatedValues
                    };
                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    setDropOffStops(updatedRecords);
                    methods.clearErrors();
                }
            }

            toggleEditStopLoadingSpecifics();
            setSelectedRecord(null);
        } else {
            return;
        }
    };

    const onAddStopCommodity = (data) => {
        const commodityId = data.commodityId;
        const unitPackagingInitCount = data.unitPackagingInitCount;
        const unitPackaging = data.unitPackaging;
        const bulkPackagingInitCount = data.bulkPackagingInitCount;
        const bulkPackaging = data.bulkPackaging;

        methods7.clearErrors();

        let name = null;
        if (isListNotEmpty(commodities)) {
            let commodity = commodities.find(c => c.id === commodityId);
            if (isObjectNotEmpty(commodity)) {
                name = commodity.name;
            }
        }

        let newStopCommodity = {
            commodityId: commodityId,
            name: name
        };

        if (unitPackagingInitCount !== undefined && unitPackagingInitCount !== null && unitPackagingInitCount > 0) {
            if (unitPackaging === undefined || unitPackaging === null) {
                methods7.setError('unitPackaging', { type: "validate", message: 'You must provide a Unit of Measure/Unit Packaging if you are going to provide a Unit Quantity for this Commodity.' });
                return;
            } else {
                newStopCommodity.unitPackagingInitCount = unitPackagingInitCount;
                newStopCommodity.unitPackaging = unitPackaging;
            }
        } else if (isNumberEmptyOrZero(unitPackagingInitCount) && isStringNotEmpty(unitPackaging)) {
            methods7.setError('unitPackagingInitCount', { type: "validate", message: 'You must provide a Unit Quantity greater than 0 if you are going to provide a Unit of Measure/Unit Packaging for this Commodity.' });
            return;
        }

        if (isNumberGreaterThanZero(bulkPackagingInitCount)) {
            if (isStringEmpty(bulkPackaging)) {
                methods7.setError('bulkPackaging', { type: "validate", message: 'You must provide Bulk Packaging if you are going to provide a Bulk Quantity for this Commodity.' });
                return;
            } else {
                newStopCommodity.bulkPackagingInitCount = bulkPackagingInitCount;
                newStopCommodity.bulkPackaging = bulkPackaging;
            }
        } else if (isNumberEmptyOrZero(bulkPackagingInitCount) && isStringNotEmpty(bulkPackaging)) {
            methods7.setError('bulkPackagingInitCount', { type: "validate", message: 'You must provide a Bulk Quantity greater than 0 if you are going to provide Bulk Packaging for this Commodity.' });
            return;
        }

        //console.log(newStopCommodity);

        if (isObjectNotEmpty(selectedRecord)) {
            let stopCommodities = [];
            if (isListNotEmpty(selectedRecord.commodities)) {
                stopCommodities = [...selectedRecord.commodities];
                if (stopCommodities.find(c => c.commodityId === commodityId && c.bulkPackaging === bulkPackaging && c.unitPackaging === unitPackaging) === undefined) {
                    stopCommodities.push(newStopCommodity);
                } else {
                    methods4.setError('commodityId', { type: "validate", message: 'This commodity has already been added to this location with the unit and bulk packaging you provided.' });
                    return;
                }
            } else {
                stopCommodities.push(newStopCommodity);
            }

            //console.log(stopCommodities);

            if (selectedRecord.stopType === 'PICK_UP') {
                let existingList = [...pickUpStops];
                const index = existingList.findIndex(obj => obj.id === selectedRecord.id);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        commodities: [...stopCommodities]
                    };

                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    setPickUpStops(updatedRecords);

                    if (dropOffStops.length === 1) {
                        // if there is only one drop-off stop, set the value for the commodities
                        let updatedPickUpRecords = updatedRecords;
                        if (isListNotEmpty(updatedPickUpRecords)) {
                            let dropOffStopCommodities = [];
                            if (updatedPickUpRecords.length === 1) {
                                if (isListNotEmpty(updatedPickUpRecords[0].commodities)) {
                                    dropOffStopCommodities = updatedPickUpRecords[0].commodities;
                                }
                            } else if (updatedPickUpRecords.length > 1) {
                                updatedPickUpRecords.forEach((pickUpStop) => {
                                    if (isListNotEmpty(pickUpStop.commodities)) {
                                        pickUpStop.commodities.forEach((stopCommodity) => {
                                            let existingDropOffStopCommodity = dropOffStopCommodities.find(c => c.commodityId === stopCommodity.commodityId && c.unitPackaging === stopCommodity.unitPackaging && c.bulkPackaging === stopCommodity.bulkPackaging);
                                            if (isObjectEmpty(existingDropOffStopCommodity)) {
                                                dropOffStopCommodities.push(stopCommodity);
                                            } else {
                                                const existingDropOffStopCommodityIndex = dropOffStopCommodities.findIndex(c => c.commodityId === stopCommodity.commodityId && c.unitPackaging === stopCommodity.unitPackaging && c.bulkPackaging === stopCommodity.bulkPackaging);
                                                // only update if the record exists in the list
                                                if (existingDropOffStopCommodityIndex !== -1) {
                                                    const updatedExistingDropOffStopCommodity = {
                                                        ...dropOffStopCommodities[existingDropOffStopCommodityIndex]
                                                    };

                                                    if (isStringNotEmpty(existingDropOffStopCommodity.unitPackaging) && existingDropOffStopCommodity.unitPackaging === stopCommodity.unitPackaging) {
                                                        updatedExistingDropOffStopCommodity.unitPackagingInitCount = updatedExistingDropOffStopCommodity.unitPackagingInitCount + stopCommodity.unitPackagingInitCount;
                                                    }
                                                    if (isStringNotEmpty(existingDropOffStopCommodity.bulkPackaging) && existingDropOffStopCommodity.bulkPackaging === stopCommodity.bulkPackaging) {
                                                        updatedExistingDropOffStopCommodity.bulkPackagingInitCount = updatedExistingDropOffStopCommodity.bulkPackagingInitCount + stopCommodity.bulkPackagingInitCount;
                                                    }

                                                    const updatedExistingDropOffStopCommodities = [
                                                        ...dropOffStopCommodities.slice(0, existingDropOffStopCommodityIndex), // everything before current obj
                                                        updatedExistingDropOffStopCommodity,
                                                        ...dropOffStopCommodities.slice(existingDropOffStopCommodityIndex + 1), // everything after current obj
                                                    ];

                                                    dropOffStopCommodities = [...updatedExistingDropOffStopCommodities];
                                                }
                                            }
                                        });
                                    }
                                });
                            }

                            let existingDropOffList = [...dropOffStops];

                            const updatedDropOffRecord = {
                                ...existingDropOffList[0],
                                commodities: [...dropOffStopCommodities]
                            };
                            const updatedDropOffRecords = [
                                ...existingDropOffList.slice(0, 0), // everything before current obj
                                updatedDropOffRecord,
                                ...existingDropOffList.slice(1), // everything after current obj
                            ];

                            setDropOffStops([...updatedDropOffRecords]);
                        }
                    }
                }
            } else if (selectedRecord.stopType === 'DROP_OFF') {
                let existingList = [...dropOffStops];
                const index = existingList.findIndex(obj => obj.id === selectedRecord.id);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        commodities: [...stopCommodities]
                    };
                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    setDropOffStops([...updatedRecords]);
                }
            }

            toggleAddStopCommodity();
            setSelectedRecord(null);
        } else {
            return;
        }
    };

    const onRemoveStopCommodity = (stop, index) => {
        if (isObjectNotEmpty(stop)) {
            let stopCommodities = [];
            if (isListNotEmpty(stop.commodities)) {
                stopCommodities = [...stop.commodities];
                stopCommodities.splice(index, 1);
            }
            if (stop.stopType === 'PICK_UP') {
                let existingList = [...pickUpStops];
                const index = existingList.findIndex(obj => obj.id === stop.id);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        commodities: [...stopCommodities]
                    };

                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    setPickUpStops(updatedRecords);

                    if (dropOffStops.length === 1) {
                        // if there is only one drop-off stop, set the value for the commodities
                        let updatedPickUpRecords = updatedRecords;
                        if (isListNotEmpty(updatedPickUpRecords)) {
                            let dropOffStopCommodities = [];
                            if (updatedPickUpRecords.length === 1) {
                                if (isListNotEmpty(updatedPickUpRecords[0].commodities)) {
                                    dropOffStopCommodities = updatedPickUpRecords[0].commodities;
                                }
                            } else if (updatedPickUpRecords.length > 1) {
                                updatedPickUpRecords.forEach((pickUpStop) => {
                                    if (isListNotEmpty(pickUpStop.commodities)) {
                                        pickUpStop.commodities.forEach((stopCommodity) => {
                                            let existingDropOffStopCommodity = dropOffStopCommodities.find(c => c.commodityId === stopCommodity.commodityId && c.unitPackaging === stopCommodity.unitPackaging && c.bulkPackaging === stopCommodity.bulkPackaging);
                                            if (isObjectEmpty(existingDropOffStopCommodity)) {
                                                dropOffStopCommodities.push(stopCommodity);
                                            } else {
                                                const existingDropOffStopCommodityIndex = dropOffStopCommodities.findIndex(c => c.commodityId === stopCommodity.commodityId && c.unitPackaging === stopCommodity.unitPackaging && c.bulkPackaging === stopCommodity.bulkPackaging);
                                                // only update if the record exists in the list
                                                if (existingDropOffStopCommodityIndex !== -1) {
                                                    const updatedExistingDropOffStopCommodity = {
                                                        ...dropOffStopCommodities[existingDropOffStopCommodityIndex]
                                                    };

                                                    if (isStringNotEmpty(existingDropOffStopCommodity.unitPackaging) && existingDropOffStopCommodity.unitPackaging === stopCommodity.unitPackaging) {
                                                        updatedExistingDropOffStopCommodity.unitPackagingInitCount = updatedExistingDropOffStopCommodity.unitPackagingInitCount + stopCommodity.unitPackagingInitCount;
                                                    }
                                                    if (isStringNotEmpty(existingDropOffStopCommodity.bulkPackaging) && existingDropOffStopCommodity.bulkPackaging === stopCommodity.bulkPackaging) {
                                                        updatedExistingDropOffStopCommodity.bulkPackagingInitCount = updatedExistingDropOffStopCommodity.bulkPackagingInitCount + stopCommodity.bulkPackagingInitCount;
                                                    }

                                                    const updatedExistingDropOffStopCommodities = [
                                                        ...dropOffStopCommodities.slice(0, existingDropOffStopCommodityIndex), // everything before current obj
                                                        updatedExistingDropOffStopCommodity,
                                                        ...dropOffStopCommodities.slice(existingDropOffStopCommodityIndex + 1), // everything after current obj
                                                    ];

                                                    dropOffStopCommodities = [...updatedExistingDropOffStopCommodities];
                                                }
                                            }
                                        });
                                    }
                                });
                            }

                            let existingDropOffList = [...dropOffStops];

                            const updatedDropOffRecord = {
                                ...existingDropOffList[0],
                                commodities: [...dropOffStopCommodities]
                            };
                            const updatedDropOffRecords = [
                                ...existingDropOffList.slice(0, 0), // everything before current obj
                                updatedDropOffRecord,
                                ...existingDropOffList.slice(1), // everything after current obj
                            ];

                            setDropOffStops([...updatedDropOffRecords]);
                        }
                    }
                }
            } else if (stop.stopType === 'DROP_OFF') {
                let existingList = [...dropOffStops];
                const index = existingList.findIndex(obj => obj.id === stop.id);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        commodities: [...stopCommodities]
                    };
                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    setDropOffStops([...updatedRecords]);
                }
            }
        } else {
            return;
        }
    };

    const setApptWindowToAllDay = (date) => {
        let startDateTime = moment(date).startOf('day');
        let endDateTime = moment(date).endOf('day');
        console.log(startDateTime)
        console.log(endDateTime)
        methods2.setValue(`apptWindowStartDateTime`, startDateTime);
        methods2.setValue(`apptWindowEndDateTime`, endDateTime);
    };

    const onSubmit = () => {
        let isValid = validateStops(pickUpStops, dropOffStops, true);
        if (isValid === true) {
            saveStops(pickUpStops, dropOffStops);
            setCompletedSteps({ ...completedSteps, schedule: true });
            nextStepAction('equipment');
        } else {
            return;
        }
    };

    //#endregion
    //#region useEffects and useMemos

    useMemo(() => {
        methods.clearErrors();

        if (isListNotEmpty(dropOffStops)) {
            if (dropOffStops.find(s => s.stopLocationId !== undefined && s.stopLocationId !== null) !== undefined) {
                setDropOffsEnabled(true);
            }
        }

        dispatch(actionCreators.getCommodities());
    }, []);

    useMemo(() => {
        if (entityType === 'STAFF') {
            dispatch(actionCreators.getAccounts());
        } else if (entityType === 'SHIPPER' || entityType === 'CARRIER' || entityType === 'PRODUCER' || entityType === 'RECEIVER' || entityType === 'BROKER') {
            dispatch(actionCreators.getLinkedAccounts(entityId, false));
        }
    }, [entityType, entityId]);

    useMemo(() => {
        if (isObjectNotEmpty(shipperData) && isStringNotEmpty(shipperData.shipperId)) {
            if (entityType === 'STAFF') {
                dispatch(actionCreators.getLocations(shipperData.shipperId, false));
            } else if (entityType === 'SHIPPER' || entityType === 'CARRIER' || entityType === 'PRODUCER' || entityType === 'RECEIVER' || entityType === 'BROKER') {
                dispatch(actionCreators.getLocations(entityId, false));
            }
        }
    }, [shipperData, entityType, entityId]);

    useMemo(() => {
        if (entityType === 'STAFF') {
            if (isListNotEmpty(accounts)) {
                setIsShippersLoading(true);
                const shipperAccounts = accounts.filter(a => a.entityTypes.includes("SHIPPER") === true);
                if (isListNotEmpty(shipperAccounts)) {
                    setShippers(orderBy(shipperAccounts, ['name'], ['asc']));
                } else {
                    setShippers([]);
                }

                setIsShippersLoading(false);
            }
        } else if (entityType === 'SHIPPER' || entityType === 'CARRIER' || entityType === 'PRODUCER' || entityType === 'RECEIVER' || entityType === 'BROKER') {
            if (isListNotEmpty(linkedAccounts)) {
                setIsShippersLoading(true);
                const linkedShippers = linkedAccounts.filter(a => a.entityTypes.includes("SHIPPER") === true);
                if (isListNotEmpty(linkedShippers)) {
                    setShippers(orderBy(linkedShippers, ['name'], ['asc']));
                } else {
                    setShippers([]);
                }

                setIsShippersLoading(false);
            }
        }
    }, [entityType, accounts, linkedAccounts]);

    useMemo(() => {
        if (isNotNullOrUndefined(locations) && isObjectNotEmpty(shipperData) && isStringNotEmpty(shipperData.shipperId)) {
            let transformedLocations = [];
            if (isListNotEmpty(locations)) {
                locations.filter(l => l.linkedAccountId === undefined || l.linkedAccountId === null).forEach((item) => {
                    if (transformedLocations.find(l => l.id === item.id) === undefined) {
                        transformedLocations.push({
                            ...item,
                            accountName: isObjectNotEmpty(item.account) && isStringNotEmpty(item.account.name) ? item.account.name : ''
                        });
                    }
                });

                locations.filter(l => l.linkedAccountId !== undefined && l.linkedAccountId !== null).forEach((item) => {
                    if (transformedLocations.find(l => l.id === item.id) === undefined) {
                        if (isObjectNotEmpty(item.linkedAccount) && (item.linkedAccount.entityTypes.includes("SHIPPER") === false || (item.linkedAccount.entityTypes.includes("SHIPPER") === true && item.linkedAccount.id === shipperData.shipperId))) {
                            transformedLocations.push({
                                ...item,
                                accountName: isObjectNotEmpty(item.linkedAccount) && isStringNotEmpty(item.linkedAccount.name) ? item.linkedAccount.name : ''
                            });
                        }
                    }
                });
            }

            setLocationsList(transformedLocations);
        }
    }, [locations, shipperData]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (showEditStopAppointment === true && isObjectNotEmpty(selectedRecord)) {
                if (isStringNotEmpty(selectedRecord.apptType)) {
                    methods2.setValue('apptType', selectedRecord.apptType);

                    if (isStringNotEmpty(selectedRecord.stopType)) {
                        if (selectedRecord.stopType === "PICK_UP") {
                            if (isStringNotEmpty(selectedRecord.pickUpNumber)) {
                                methods2.setValue('pickUpNumber', selectedRecord.pickUpNumber);
                            }
                        } else if (selectedRecord.stopType === "DROP_OFF") {
                            if (isStringNotEmpty(selectedRecord.purchaseOrderNumber)) {
                                methods2.setValue('purchaseOrderNumber', selectedRecord.purchaseOrderNumber);
                            }
                            if (isStringNotEmpty(selectedRecord.dropOffNumber)) {
                                methods2.setValue('dropOffNumber', selectedRecord.dropOffNumber);
                            }
                        }
                    }

                    if (selectedRecord.apptType === "FIRST_COME_FIRST_SERVE") {
                        setShowFirstComeFirstServeDetails(true);
                        setShowHaveAppointmentDetails(false);
                        setShowNeedAppointmentDetails(false);
                    } else if (selectedRecord.apptType === "HAVE_APPOINTMENT") {
                        setShowFirstComeFirstServeDetails(false);
                        setShowHaveAppointmentDetails(true);
                        setShowNeedAppointmentDetails(false);
                    } else if (selectedRecord.apptType === "NEED_APPOINTMENT") {
                        setShowFirstComeFirstServeDetails(false);
                        setShowHaveAppointmentDetails(false);
                        setShowNeedAppointmentDetails(true);
                    } else {
                        setShowFirstComeFirstServeDetails(false);
                        setShowHaveAppointmentDetails(false);
                        setShowNeedAppointmentDetails(false);
                    }
                }
                if (isObjectNotEmpty(selectedRecord.apptPointOfContact)) {
                    if (isStringNotEmpty(selectedRecord.apptPointOfContact.firstName)) {
                        methods2.setValue('apptPointOfContact.firstName', selectedRecord.apptPointOfContact.firstName);
                    }
                    if (isStringNotEmpty(selectedRecord.apptPointOfContact.lastName)) {
                        methods2.setValue('apptPointOfContact.lastName', selectedRecord.apptPointOfContact.lastName);
                    }
                    if (isStringNotEmpty(selectedRecord.apptPointOfContact.email)) {
                        methods2.setValue('apptPointOfContact.email', selectedRecord.apptPointOfContact.email);
                    }
                    if (isStringNotEmpty(selectedRecord.apptPointOfContact.phone)) {
                        methods2.setValue('apptPointOfContact.phone', selectedRecord.apptPointOfContact.phone);
                    }
                    if (isStringNotEmpty(selectedRecord.apptPointOfContact.phoneExt)) {
                        methods2.setValue('apptPointOfContact.phoneExt', selectedRecord.apptPointOfContact.phoneExt);
                    }
                }
                if (isStringNotEmpty(selectedRecord.apptInstructions)) {
                    methods2.setValue('apptInstructions', selectedRecord.apptInstructions);
                }
            }
        }

        return () => isSubscribed = false;
    }, [showEditStopAppointment, selectedRecord]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (showFirstComeFirstServeDetails === true && isObjectNotEmpty(selectedRecord)) {
                if (isStringNotEmpty(selectedRecord.apptType) && selectedRecord.apptType === "FIRST_COME_FIRST_SERVE") {
                    if (isStringNotEmpty(selectedRecord.apptWindowType) && selectedRecord.apptWindowType === "ALL_DAY") {
                        setApptWindowDisabled(true);
                        setAllDayDateDisabled(false);
                        methods2.setValue('apptWindowType', selectedRecord.apptWindowType);
                        if (isNotNullOrUndefined(selectedRecord.allDayDate)) {
                            methods2.setValue(`allDayDate`, selectedRecord.allDayDate);
                        }
                    } else {
                        setApptWindowDisabled(false);
                        setAllDayDateDisabled(true);
                        methods2.setValue('apptWindowType', 'CUSTOM');
                    }

                    if (isNotNullOrUndefined(selectedRecord.apptWindowStartDateTime)) {
                        methods2.setValue(`apptWindowStartDateTime`, selectedRecord.apptWindowStartDateTime);
                    }
                    if (isNotNullOrUndefined(selectedRecord.apptWindowEndDateTime)) {
                        methods2.setValue(`apptWindowEndDateTime`, selectedRecord.apptWindowEndDateTime);
                    }
                }
            }
        }

        return () => isSubscribed = false;
    }, [showFirstComeFirstServeDetails, selectedRecord]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (showHaveAppointmentDetails === true && isObjectNotEmpty(selectedRecord)) {
                if (isStringNotEmpty(selectedRecord.apptType) && selectedRecord.apptType === "HAVE_APPOINTMENT") {
                    if (isStringNotEmpty(selectedRecord.apptNumber)) {
                        methods2.setValue(`apptNumber`, selectedRecord.apptNumber);
                    }
                    if (isNotNullOrUndefined(selectedRecord.apptDateTime)) {
                        methods2.setValue(`apptDateTime`, selectedRecord.apptDateTime);
                    }
                }
            }
        }

        return () => isSubscribed = false;
    }, [showHaveAppointmentDetails, selectedRecord]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (showNeedAppointmentDetails === true && isObjectNotEmpty(selectedRecord)) {
                if (isStringNotEmpty(selectedRecord.apptType) && selectedRecord.apptType === "NEED_APPOINTMENT") {
                    if (isNotNullOrUndefined(selectedRecord.apptCallAheadDateTime)) {
                        methods2.setValue(`apptCallAheadDateTime`, selectedRecord.apptCallAheadDateTime);
                    }
                    if (isNotNullOrUndefined(selectedRecord.requestedDateTime)) {
                        methods2.setValue(`requestedDateTime`, selectedRecord.requestedDateTime);
                    }
                }
            }
        }

        return () => isSubscribed = false;
    }, [showNeedAppointmentDetails, selectedRecord]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (showEditStopLocation === true && isObjectNotEmpty(selectedRecord)) {
                if (isStringNotEmpty(selectedRecord.stopLocationId)) {
                    methods3.setValue('locationId', selectedRecord.stopLocationId);
                }
            }
        }

        return () => isSubscribed = false;
    }, [showEditStopLocation, selectedRecord]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (isObjectNotEmpty(shipperData)) {
                if (isStringNotEmpty(shipperData.shipperId)) {
                    setPickUpsEnabled(true);
                } else {
                    setPickUpsEnabled(false);
                }
            }
        }

        return () => isSubscribed = false;
    }, [shipperData]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (showEditShipper === true && isObjectNotEmpty(shipperData)) {
                if (isStringNotEmpty(shipperData.shipperId)) {
                    methods4.setValue('shipperId', shipperData.shipperId);
                }
            }
        }

        return () => isSubscribed = false;
    }, [showEditShipper, shipperData]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (showEditStopLoadingSpecifics === true && isObjectNotEmpty(selectedRecord)) {
                if (isStringNotEmpty(selectedRecord.loadingType)) {
                    methods6.setValue(`loadingType`, selectedRecord.loadingType);
                }

                if (selectedRecord.hasLumperFee === true) {
                    methods6.setValue(`hasLumperFee`, true);
                    setLumperFeeDisabled(false);
                    if (selectedRecord.lumperFee !== undefined) {
                        methods6.setValue(`lumperFee`, selectedRecord.lumperFee);
                    } else if (isObjectNotEmpty(selectedRecord.stopLocation) && isNumberGreaterThanZero(selectedRecord.stopLocation.lumperFee)) {
                        methods6.setValue(`lumperFee`, selectedRecord.stopLocation.lumperFee);
                    }
                } else {
                    setLumperFeeDisabled(true);
                    methods6.setValue(`hasLumperFee`, false);
                }

                if (selectedRecord.hasDriverAssist === true) {
                    methods6.setValue(`hasDriverAssist`, true);
                    setDriverAssistDisabled(false);
                    if (selectedRecord.driverAssist !== undefined) {
                        methods6.setValue(`driverAssist`, selectedRecord.driverAssist);
                    } else if (entityType === 'STAFF') {
                        methods6.setValue(`driverAssist`, 0);
                    }
                } else {
                    setDriverAssistDisabled(true);
                    methods6.setValue(`hasDriverAssist`, false);
                }

                if (selectedRecord.specialInstructions !== undefined) {
                    methods6.setValue(`specialInstructions`, selectedRecord.specialInstructions);
                } else if (isObjectNotEmpty(selectedRecord.stopLocation) && isStringNotEmpty(selectedRecord.stopLocation.specialInstructions)) {
                    methods6.setValue(`specialInstructions`, selectedRecord.stopLocation.specialInstructions);
                }
            }
        }

        return () => isSubscribed = false;
    }, [showEditStopLoadingSpecifics, selectedRecord]);

    useMemo(() => {
        let loadBOLNumberList = [];
        if (isListNotEmpty(pickUpStops)) {
            pickUpStops.forEach((stop, stopIndex, arr) => {
                if (isListNotEmpty(stop.bolNumbers)) {
                    stop.bolNumbers.forEach((bolNumber) => {
                        if (loadBOLNumberList.find(b => b === bolNumber) === undefined) {
                            loadBOLNumberList.push(bolNumber);
                        }
                    });
                }
            });
        }

        if (isListNotEmpty(dropOffStops)) {
            dropOffStops.forEach((stop, stopIndex, arr) => {
                if (isListNotEmpty(stop.bolNumbers)) {
                    stop.bolNumbers.forEach((bolNumber) => {
                        if (loadBOLNumberList.find(b => b === bolNumber) === undefined) {
                            loadBOLNumberList.push(bolNumber);
                        }
                    });
                }
            });
        }

        setBOLNumberList([...loadBOLNumberList]);
    }, [pickUpStops, dropOffStops]);

    //#endregion
    //#region displays

    const getLocationOptions = (stop = null) => {
        let options = [];
        if (isListNotEmpty(locationsList)) {
            // Don't filter out already used locations since some loads can have the same location 
            // for pick-up and drop-off in the bulk space where the sites are very large
            let orderedLocationsList = orderBy(locationsList, ['accountName', 'name', 'streetAddress1'], ['asc', 'asc', 'asc']);

            orderedLocationsList.forEach((item) => {
                options.push(<Option value={item.id} key={item.key}>{`${isStringNotEmpty(item.accountName) ? item.accountName + ' - ' : ''}${isStringNotEmpty(item.name) ? item.name : ''}${isObjectNotEmpty(item.address) ? ' - ' + stringFormatter.toFormattedAddress(item.address) : ''}`}</Option>);
            });
        }

        return options;
    };

    const getLoadStopLocationDisplay = (stop, stopIndex) => {
        if (isObjectNotEmpty(stop)) {
            const stopName = LoadStopUtils.getStopLocationNameDisplay(stop, shipperData.shipperId, null, entityType, entityId);
            const stopAddress = LoadStopUtils.getStopAddressDisplay(stop);
            const stopType = LoadStopUtils.getStopType(stop);
            const apptType = LoadStopUtils.getAppointmentType(stop);
            const stopIcon = LoadStopUtils.getStopMarkerIcon(stop);

            return (
                <DataCollapsableRow
                    isDisabled={(((stopType === 'DROP_OFF' && dropOffsEnabled === false) || (stopType === 'PICK_UP' && pickUpsEnabled === false)) ? true : false)}
                    isCollapsable={(((stopType === 'DROP_OFF' && dropOffsEnabled === false) || (stopType === 'PICK_UP' && pickUpsEnabled === false) || isStringEmpty(apptType)) ? false : true)}
                    isCollapsed={isStringNotEmpty(stop.stopLocationId) || isStringEmpty(apptType) || isNullOrUndefined(stop.requestedDateTime) ? false : true}
                    image={isNotNullOrUndefined(stopIcon) && isStringNotEmpty(stop.stopLocationId) ? stopIcon : null}
                    imageAlt={LoadStopUtils.getStopTypeDisplay(stop)}
                    title={isStringNotEmpty(stop.stopLocationId) ? (isStringNotEmpty(stopName) ? stopName : 'N/A') : (<Button style={{ paddingLeft: 0, fontSize: 18, color: '#000000', fontWeight: 500 }} type='link' disabled={(((stopType === 'DROP_OFF' && dropOffsEnabled === false) || (stopType === 'PICK_UP' && pickUpsEnabled === false)) ? true : false)} onClick={() => { setSelectedRecord(stop); toggleEditStopLocation(); }}>Select a {stopType === "PICK_UP" ? "Pick-Up" : "Drop-Off"} Location <DownOutlined /></Button>)}
                    titleStyle={{ paddingLeft: 0, fontSize: 18, fontWeight: 500, color: '#000000' }}
                    titleHasAction={isStringEmpty(stop.stopLocationId)}
                    subtitle={isStringNotEmpty(stopAddress) ? (<span style={{ fontSize: 14 }}>{stopAddress}</span>) : null}
                    header={isStringNotEmpty(apptType) && isNotNullOrUndefined(stop.requestedDateTime) ? (
                        <>
                            <span style={{ fontSize: 14, textTransform: 'uppercase', color: (((stopType === 'DROP_OFF' && dropOffsEnabled === false) || (stopType === 'PICK_UP' && pickUpsEnabled === false)) ? '#000000' : '#A9A9A9') }}>{(isStringNotEmpty(stopType) ? `${LoadStopUtils.getStopTypeDisplay(stop)}${stopType === 'PICK_UP' && pickUpStops.length > 1 ? ' ' + (stopIndex + 1) : ''}${stopType === 'DROP_OFF' && dropOffStops.length > 1 ? ' ' + (stopIndex + 1) : ''}` : '')}  </span>
                            <span style={{ fontSize: 14, textTransform: 'uppercase', color: (((stopType === 'DROP_OFF' && dropOffsEnabled === false) || (stopType === 'PICK_UP' && pickUpsEnabled === false)) ? '#000000' : '#A9A9A9') }}>{LoadStopUtils.getAppointmentDateTimeDisplay(stop)}</span>
                        </>
                    ) : (isStringNotEmpty(stopType) ? <span style={{ fontSize: 14, textTransform: 'uppercase', color: (((stopType === 'DROP_OFF' && dropOffsEnabled === false) || (stopType === 'PICK_UP' && pickUpsEnabled === false)) ? '#000000' : '#A9A9A9') }}>{`${LoadStopUtils.getStopTypeDisplay(stop)}${stopType === 'PICK_UP' && pickUpStops.length > 1 ? ' ' + (stopIndex + 1) : ''}${stopType === 'DROP_OFF' && dropOffStops.length > 1 ? ' ' + (stopIndex + 1) : ''}`}</span> : null)}
                    extra={isStringNotEmpty(stop.stopLocationId) ? (
                        <>
                            <Button style={{ fontWeight: 'bold' }} type='link' onClick={() => { setSelectedRecord(stop); toggleEditStopLocation(); }}>change</Button>
                            {(stopType === 'PICK_UP' && pickUpStops.length > 1) ? (
                                <Button style={{ fontWeight: 'bold' }}
                                    type="link"
                                    onClick={() => {
                                        let existingList = [...pickUpStops];
                                        existingList.splice(stopIndex, 1);
                                        setPickUpStops([...existingList]);
                                    }}
                                >
                                    remove
                                </Button>
                            ) : null}
                            {(stopType === 'DROP_OFF' && dropOffStops.length > 1) ? (
                                <Button style={{ fontWeight: 'bold' }}
                                    type="link"
                                    onClick={() => {
                                        let existingList = [...dropOffStops];
                                        existingList.splice(stopIndex, 1);
                                        setDropOffStops([...existingList]);
                                    }}
                                >
                                    remove
                                </Button>
                            ) : null}
                        </>
                    ) : null}
                >
                    <div style={{ marginTop: 12 }}>
                        {isStringNotEmpty(stop.stopLocationId) ? (
                            <Row>
                                {isStringNotEmpty(apptType) && isNotNullOrUndefined(stop.requestedDateTime) ? (
                                    <Col span={24}>
                                        <Fieldset
                                            legend={`Date, Appointment, & ${Enums.StopTypes.getValueByName(stop.stopType)} Details *`}
                                            extra={<Button type="link" onClick={() => { setSelectedRecord(stop); toggleEditStopAppointment(); }}>edit</Button>}
                                            legendStyle={{ marginBottom: 10 }}
                                            fieldsetStyle={{ margin: '10px 0' }}
                                            isCollapsable={true}
                                        >
                                            <DataRow title="Appointment Type" value={apptType} dataType="AppointmentType" />
                                            <DataRow title={`Requested ${LoadStopUtils.getStopTypeDisplay(stop)} Date & Appointment`} value={LoadStopUtils.getAppointmentDateTimeDisplay(stop)} dataType="String" />

                                            {apptType === "HAVE_APPOINTMENT" ? (
                                                <DataRow title="Appointment Number" value={stop.apptNumber} dataType="String" />
                                            ) : null}
                                            {(stopType === 'PICK_UP' && hideLoadSpecificInformation === false) ? (
                                                <DataRow title="Pick-Up Number" value={stop.pickUpNumber} dataType="String" />
                                            ) : null}
                                            {(stopType === 'DROP_OFF' && hideLoadSpecificInformation === false) ? (
                                                <>
                                                    <DataRow title="Drop-Off Number" value={stop.dropOffNumber} dataType="String" />
                                                    <DataRow title="Customer PO Number Number" value={stop.purchaseOrderNumber} dataType="String" />
                                                </>
                                            ) : null}
                                            {apptType === "NEED_APPOINTMENT" ? (
                                                <DataRow title="Appointment Call Ahead Date/Time" value={isNotNullOrUndefined(stop.apptCallAheadDateTime) ? momentDate.fromTimeZonelessToTimeZoneAsTimeZoneDateTimeString(stop.apptCallAheadDateTime, isObjectNotEmpty(stop.stopLocation) && isStringNotEmpty(stop.stopLocation.timeZone) ? stop.stopLocation.timeZone : null) : null} dataType="String" />
                                            ) : null}
                                            <DataContactInfoRow separateLines={false} valueStyle={{ paddingLeft: 8 }} title={`${Enums.StopTypes.getValueByName(stop.stopType)} Contact`} contact={stop.apptPointOfContact} />
                                            <DataRow title="Additional Appointment Instructions" value={stop.apptInstructions} dataType="String" />
                                        </Fieldset>
                                    </Col>
                                ) : (
                                    <Col span={24}>
                                        <Fieldset
                                            legend={`Date, Appointment, & ${Enums.StopTypes.getValueByName(stop.stopType)} Details *`}
                                            legendStyle={{ marginBottom: 10 }}
                                            fieldsetStyle={{ margin: '10px 0' }}
                                        >
                                            <div>
                                                <Button style={{ fontWeight: 'bold' }} type="link" disabled={isStringEmpty(stop.stopLocationId)} onClick={() => { setSelectedRecord(stop); toggleEditStopAppointment(); }}>add details</Button>
                                            </div>
                                        </Fieldset>
                                    </Col>
                                )}
                                {isStringNotEmpty(apptType) && isNotNullOrUndefined(stop.requestedDateTime) ? (
                                    <>
                                        {isStringNotEmpty(stop.loadingType) ? (
                                            <Col span={24}>
                                                <Fieldset
                                                    legend={stop.stopType === 'PICK_UP' ? 'Loading Details *' : 'Unloading Details *'}
                                                    extra={<Button type="link" disabled={stop.stopType === 'DROP_OFF' && dropOffsEnabled === false} onClick={() => { setSelectedRecord(stop); toggleEditStopLoadingSpecifics(); }}>edit</Button>}
                                                    legendStyle={{ marginBottom: 10 }}
                                                    fieldsetStyle={{ margin: '10px 0' }}
                                                    isCollapsable={true}
                                                >
                                                    <DataRow title={stop.stopType === "PICK_UP" ? "Loading Type" : "Unloading Type"} value={stop.loadingType} dataType={stop.stopType === "PICK_UP" ? "LoadingType" : "UnloadingType"} />
                                                    <DataRow title="Lumper Fee" value={isBooleanTrue(stop.hasLumperFee) ? `Yes, ${stringFormatter.toFormattedMoney(stop.lumperFee)}` : 'No'} dataType="String" />
                                                    <DataRow title="Driver Assist" value={isBooleanTrue(stop.hasDriverAssist) ? `Yes, ${stringFormatter.toFormattedMoney(stop.driverAssist)}` : 'No'} dataType="String" />
                                                    <DataRow title="Special Instructions" value={stop.specialInstructions} dataType="String" />
                                                </Fieldset>
                                            </Col>
                                        ) : (
                                            <Col span={24}>
                                                <Fieldset
                                                    legend={stop.stopType === 'PICK_UP' ? 'Loading Details *' : 'Unloading Details *'}
                                                    legendStyle={{ marginBottom: 10 }}
                                                    fieldsetStyle={{ margin: '10px 0' }}
                                                >
                                                    <Button style={{ fontWeight: 'bold' }} type="link" disabled={!((isListNotEmpty(stop.bolNumbers) && dontRequireLoadSpecificInformation === false && hideLoadSpecificInformation === false) || dontRequireLoadSpecificInformation === true || hideLoadSpecificInformation === true) || (stop.stopType === 'DROP_OFF' && dropOffsEnabled === false) || isStringEmpty(stop.stopLocationId)} onClick={() => { setSelectedRecord(stop); toggleEditStopLoadingSpecifics(); }}>add details</Button>
                                                </Fieldset>
                                            </Col>
                                        )}
                                    </>
                                ) : null}
                                {isStringNotEmpty(apptType) && isNotNullOrUndefined(stop.requestedDateTime) && isStringNotEmpty(stop.loadingType) ? (
                                    <Col span={24}>
                                        <Fieldset
                                            legend={hideLoadSpecificInformation === false ? "BOL and Commodity Details (optional)" : "Commodity Details (optional"}
                                            legendStyle={{ marginBottom: 10 }}
                                            fieldsetStyle={{ margin: '10px 0' }}
                                            isCollapsable={true}
                                            isCollapsed={((isListEmpty(stop.bolNumbers) && hideLoadSpecificInformation === false) || hideLoadSpecificInformation === true) && isListEmpty(stop.commodities)}
                                        >
                                            <Row gutter={[12, 12]}>
                                                {hideLoadSpecificInformation === false ? (
                                                    <Col span={24}>
                                                        <div><span style={{ fontSize: 12, fontWeight: 'bold', color: '#000000' }}>BOL Number(s):</span>{isListEmpty(stop.bolNumbers) ? (<Button style={{ fontWeight: 'bold' }} type="link" disabled={isStringEmpty(stop.stopLocationId) || (stop.stopType === 'DROP_OFF' && dropOffsEnabled === false)} onClick={() => { setSelectedRecord(stop); toggleAddStopBOL(); }}>add</Button>) : null}</div>
                                                        {isListNotEmpty(stop.bolNumbers) ? (
                                                            <>
                                                                {stop.bolNumbers.map((stopBOLNumber, stopBOLNumberIndex) => {
                                                                    return (
                                                                        <Row key={`stop-${stopIndex}-bolNumber-${stopBOLNumberIndex}`}>
                                                                            <Col span={24}>
                                                                                <DataRow
                                                                                    value={stopBOLNumber}
                                                                                    dataType="String"
                                                                                    extra={
                                                                                        (stop.stopType === 'PICK_UP' || (stop.stopType === 'DROP_OFF' && dropOffStops.length > 1)) ? (
                                                                                            <Button type="default" icon={<DeleteOutlined />} style={{ marginLeft: 8 }} disabled={stop.stopType === 'DROP_OFF' && dropOffsEnabled === false} onClick={() => { onRemoveStopBOL(stop, stopBOLNumber, stopBOLNumberIndex); }} />
                                                                                        ) : null
                                                                                    }
                                                                                />
                                                                                {docFiles.filter(d => d.stopId === stop.id && d.bolNumber === stopBOLNumber).length > 0 ? (
                                                                                    <DataListRow data={docFiles.filter(d => d.stopId === stop.id && d.bolNumber === stopBOLNumber).map((docFile) => {
                                                                                        if (isNotNullOrUndefined(docFile.file)) {
                                                                                            return docFile.file.name;
                                                                                        } else {
                                                                                            return '';
                                                                                        }
                                                                                    })} />
                                                                                ) : null}
                                                                            </Col>
                                                                        </Row>
                                                                    );
                                                                })}

                                                                {(stop.stopType === 'PICK_UP' && dropOffStops.length > stop.bolNumbers.length) ? (
                                                                    <div>
                                                                        <Button style={{ marginLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} disabled={stop.stopType === 'DROP_OFF' && dropOffsEnabled === false} onClick={() => { setSelectedRecord(stop); toggleAddStopBOL(); }}>add another</Button>
                                                                    </div>
                                                                ) : null}
                                                            </>
                                                        ) : null}
                                                    </Col>
                                                ) : null}
                                                <Col span={24}>
                                                    <div><span style={{ fontSize: 12, fontWeight: 'bold', color: '#000000' }}>Commodities:</span>{isListEmpty(stop.commodities) ? (<Button style={{ fontWeight: 'bold' }} type="link" disabled={!((isListNotEmpty(stop.bolNumbers) && dontRequireLoadSpecificInformation === false && hideLoadSpecificInformation === false) || dontRequireLoadSpecificInformation === true || hideLoadSpecificInformation === true) || (stop.stopType === 'DROP_OFF' && dropOffsEnabled === false) || isStringEmpty(stop.stopLocationId)} onClick={() => { setSelectedRecord(stop); toggleAddStopCommodity(); }}>add</Button>) : null}</div>
                                                    {isListNotEmpty(stop.commodities) ? (
                                                        <>
                                                            {stop.commodities.map((stopCommodity, index) => {
                                                                //console.log(stopCommodity);
                                                                return (
                                                                    <DataRow
                                                                        key={`stop-${stopIndex}-stopCommodity-${index}`}
                                                                        style={{ paddingLeft: 8 }}
                                                                        title={stopCommodity.name}
                                                                        value={`${isNumberNotEmpty(stopCommodity.unitPackagingInitCount) ? `${stopCommodity.unitPackagingInitCount} ${Enums.UnitPackaging.getValueByName(stopCommodity.unitPackaging)}` : ''}${isNumberNotEmpty(stopCommodity.bulkPackagingInitCount) ? `, ${stopCommodity.bulkPackagingInitCount} ${Enums.BulkPackaging.getValueByName(stopCommodity.bulkPackaging)}` : ''}`}
                                                                        dataType="String"
                                                                        extra={((stop.stopType === 'PICK_UP') || (stop.stopType === 'DROP_OFF' && dropOffStops.length > 1)) ? (
                                                                            <Button type="default" style={{ marginLeft: 8 }} icon={<DeleteOutlined />} disabled={stop.stopType === 'DROP_OFF' && dropOffsEnabled === false} onClick={() => { onRemoveStopCommodity(stop, index); }} />
                                                                        ) : null}
                                                                    />
                                                                );
                                                            })}

                                                            {((stop.stopType === 'PICK_UP') || (stop.stopType === 'DROP_OFF' && dropOffStops.length > 1)) ? (
                                                                <div>
                                                                    <Button style={{ marginLeft: 0, fontWeight: 'bold' }} type="link" disabled={stop.stopType === 'DROP_OFF' && dropOffsEnabled === false} onClick={() => { setSelectedRecord(stop); toggleAddStopCommodity(); }}>add another</Button>
                                                                </div>
                                                            ) : null}
                                                        </>
                                                    ) : null}
                                                </Col>
                                            </Row>
                                        </Fieldset>
                                    </Col>
                                ) : null}
                            </Row>
                        ) : null}
                    </div>
                </DataCollapsableRow>
            );
        } else {
            return null;
        }
    };

    const getStopDetails = () => {
        if (isObjectNotEmpty(selectedRecord)) {
            let stop = selectedRecord;
            const stopIcon = LoadStopUtils.getStopMarkerIcon(stop);

            return (
                <div>
                    <Row style={{ marginTop: 24, marginBottom: 24 }}>
                        <Col span={12}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: 30, marginRight: 12 }}>
                                    {isNotNullOrUndefined(stopIcon) ? (<img src={stopIcon} alt={LoadStopUtils.getStopTypeDisplay(stop)} style={{ width: 30, height: 30 }} />) : null}
                                </div>
                                <div style={{ flex: 1 }}>
                                    <span style={{ fontWeight: 'bold' }}>{LoadStopUtils.getStopLocationNameDisplay(stop, shipperData.shipperId, null, entityType, entityId)}</span><br />
                                    <span>{LoadStopUtils.getStopLocationCityStateDisplay(stop)}</span>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <span style={{ fontWeight: 'bold' }}>Appointment</span><br />
                            {LoadStopUtils.getAppointmentDateTimeDisplay(stop)}
                        </Col>
                    </Row>
                </div>
            );
        } else {
            return null;
        }
    };

    const getCommodityOptions = (stop) => {
        let options = [];
        if (isObjectNotEmpty(stop) && isListNotEmpty(commodities)) {
            let availableCommodities = [];
            // filter the commodities by what is set on the location if any are set
            if (isObjectNotEmpty(stop.stopLocation) && isListNotEmpty(stop.stopLocation.commodityIds)) {
                let availableStopCommodities = commodities.filter(c => c.isDeleted === false && stop.stopLocation.commodityIds.includes(c.id));
                if (isListNotEmpty(availableStopCommodities)) {
                    availableCommodities = availableStopCommodities;
                } else {
                    availableCommodities = commodities;
                }
            } else {
                availableCommodities = commodities;
            }
            if (stop.stopType === 'PICK_UP') {
                // if pick-up, don't show commodities that have already been added to this stop
                let usedCommodityIds = [];
                if (isListNotEmpty(stop.commodities)) {
                    stop.commodities.forEach((stopCommodity) => {
                        usedCommodityIds.push(stopCommodity.commodityId);
                    });
                }

                availableCommodities.forEach((commodity) => {
                    if (!usedCommodityIds.includes(commodity.id)) {
                        options.push(<Option value={commodity.id} key={commodity.id}>{commodity.name}</Option>);
                    }
                });
            } else if (stop.stopType === 'DROP_OFF') {
                let usedCommodityIds = [];
                let usedStopCommodityIds = [];

                // don't show commodities that have already been added to this stop
                if (isListNotEmpty(stop.commodities)) {
                    stop.commodities.forEach((stopCommodity) => {
                        usedStopCommodityIds.push(stopCommodity.commodityId);
                    });
                }

                // only show commodities that the pick-up stops have
                pickUpStops.forEach((pickUpStop) => {
                    if (isListNotEmpty(pickUpStop.commodities)) {
                        pickUpStop.commodities.forEach((stopCommodity) => {
                            if (!usedCommodityIds.includes(stopCommodity.commodityId)) {
                                usedCommodityIds.push(stopCommodity.commodityId);
                            }
                        });
                    }
                });

                availableCommodities.forEach((commodity) => {
                    if (usedCommodityIds.includes(commodity.id) && !usedStopCommodityIds.includes(commodity.id)) {
                        options.push(<Option value={commodity.id} key={commodity.id}>{commodity.name}</Option>);
                    }
                });
            }
        }

        return options;
    };

    const getBOLNumberOptions = (stop = null) => {
        let options = [];
        if (isListNotEmpty(bolNumberList) && isObjectNotEmpty(stop)) {
            let usedBOLNumbers = [];

            if (isListNotEmpty(stop.bolNumbers)) {
                stop.bolNumbers.forEach((stopBOLNumber) => {
                    usedBOLNumbers.push(stopBOLNumber);
                });
            }

            bolNumberList.forEach((bolNumber) => {
                if (!usedBOLNumbers.includes(bolNumber)) {
                    options.push({ key: bolNumber, value: bolNumber, label: bolNumber });
                }
            });

            if (isListNotEmpty(options) && stop.stopType === 'PICK_UP') {
                options.push({ key: 'NEW', value: 'NEW', label: 'Add a New BOL Number' });
            }
        }

        return options;
    };

    const getError = (errors, name) => {
        if (name !== undefined) {
            let obj = errors;

            name = name.split('.');
            let len = name.length;
            for (let i = 0; i < len - 1; i++) {
                if (obj[name[i]] !== undefined) {
                    // console.log(obj[name[i]]);
                    obj = obj[name[i]];
                }
            }

            return obj[name[len - 1]];
        } else {
            return undefined;
        }
    };

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    const formItemLayoutDouble = {
        labelCol1: { span: 24 },
        wrapperCol1: { span: 12 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 12 },
    };

    const formItemLayoutDouble2 = {
        labelCol1: { span: 24 },
        wrapperCol1: { span: 4 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 20 },
    };

    const formItemLayoutDouble3 = {
        labelCol1: { span: 12 },
        wrapperCol1: { span: 12 },
        labelCol2: { span: 12 },
        wrapperCol2: { span: 12 },
    };

    const radioStyle = {
        display: 'block',
        height: '60px',
        lineHeight: '30px',
    };

    const radioStyle2 = {
        display: 'block',
        height: 'fit-content',
        // lineHeight: '30px',
    };

    //#endregion

    return (
        <>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className="load-creation-step-container">
                        <div className="load-creation-step">
                            <Header
                                titleHasAction={(isObjectNotEmpty(shipperData) && isStringNotEmpty(shipperData.shipperId)) ? false : true}
                                titleStyle={{ paddingLeft: 0, color: '#000000', fontWeight: 500 }}
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                                title={isObjectNotEmpty(shipperData) && isStringNotEmpty(shipperData.shipperId) ? (
                                    isStringNotEmpty(shipperData.shipperName) ? ((entityType === "STAFF" && isObjectNotEmpty(shipperData) && isObjectNotEmpty(shipperData.shipper)) ? (
                                        <Popover
                                            content={
                                                <Row>
                                                    <Col span={12}>
                                                        <DataContactInfoRow separateLines={false} title="A/P Contact" contact={shipperData.shipper.accountsPayable} />
                                                    </Col>
                                                    <Col span={12}>
                                                        <DataAddressRow oneLine={false} title="A/P Address" address={shipperData.shipper.accountsPayableAddress} />
                                                    </Col>
                                                </Row>
                                            }
                                            title={shipperData.shipperName}>
                                            <span>{shipperData.shipperName}</span><InfoCircleOutlined style={{ color: '#23BEBB', marginLeft: 8 }} />
                                        </Popover>
                                    ) : (<span>{shipperData.shipperName}</span>)) : 'N/A') : (
                                    <Button style={{ paddingLeft: 0, fontSize: 18, color: '#000000', fontWeight: 500 }} type='link' onClick={() => { toggleEditShipper(); }}>Select a Shipper Account <DownOutlined /></Button>
                                )}
                                icon={<FontAwesomeIcon className="anticon" icon={faTruckLoading} />}
                                iconStyle={{ background: 'transparent' }}
                            />
                            <Timeline style={{ padding: '10px 24px' }}>
                                {isListNotEmpty(pickUpStops) ? (
                                    <>
                                        {pickUpStops.map((stop, index, arr) => {
                                            return (
                                                <Timeline.Item className="customTimelineItem" key={`pickUp-${index}`} color={pickUpsEnabled === true ? '#23BEBB' : '#A9A9A9'}>
                                                    <div style={{ paddingLeft: 50, marginBottom: 24 }}>
                                                        <div style={pickUpsEnabled === true ? null : { zIndex: 500, backgroundColor: 'white', opacity: 0.3 }}>
                                                            {getLoadStopLocationDisplay(stop, index)}
                                                        </div>
                                                        <div style={pickUpsEnabled === true ? null : { zIndex: 500, backgroundColor: 'white', opacity: 0.3 }}>
                                                            {getError(methods.errors, `pickUpStops-${index}`) !== undefined ? (
                                                                <ErrorMessage
                                                                    errors={methods.errors}
                                                                    name={`pickUpStops-${index}`}
                                                                    render={({ message, messages }) => {
                                                                        if (isNotNullOrUndefined(messages)) {
                                                                            return Object.entries(messages).map(([type, message]) => (<Alert key={`pickUpStops-${index}-${type}`} message={message} type="error" />));
                                                                        } else if (isStringNotEmpty(message)) {
                                                                            return <Alert message={message} type="error" />;
                                                                        } else {
                                                                            return null;
                                                                        }
                                                                    }}
                                                                />
                                                            ) : null}
                                                            {getError(methods.errors, `pickUpStops`) !== undefined ? (
                                                                <ErrorMessage
                                                                    errors={methods.errors}
                                                                    name={`pickUpStops`}
                                                                    render={({ message, messages }) => {
                                                                        if (isNotNullOrUndefined(messages)) {
                                                                            return Object.entries(messages).map(([type, message]) => (<Alert key={`pickUpStops-${type}`} message={message} type="error" />));
                                                                        } else if (isStringNotEmpty(message)) {
                                                                            return <Alert message={message} type="error" />;
                                                                        } else {
                                                                            return null;
                                                                        }
                                                                    }}
                                                                />
                                                            ) : null}
                                                        </div>
                                                        {(stop.stopTypeSequence + 1 === pickUpStops.length) ? (
                                                            <div style={{ marginTop: 18 }}>
                                                                <Button style={{ paddingLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} disabled={isStringEmpty(stop.apptType) || isStringEmpty(stop.loadingType) || isStringEmpty(stop.stopLocationId)} onClick={() => { toggleNewPickUpStopLocation(); }}>Additional Pick-Up</Button>
                                                            </div>
                                                        ) : null}
                                                        {(dropOffsEnabled === false && isStringNotEmpty(stop.apptType) && isStringNotEmpty(stop.loadingType) && (stop.stopTypeSequence + 1 === pickUpStops.length)) ? (
                                                            <div style={{ marginTop: 18 }}>
                                                                <Button style={{ paddingLeft: 0, fontWeight: 'bold' }} type="link" icon={<RightOutlined />} onClick={() => { setDropOffsEnabled(true); }}>Continue to Drop-Off</Button>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Timeline.Item>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <Timeline.Item className="customTimelineItem" key={`pickUp`} color={pickUpsEnabled === true ? '#23BEBB' : '#A9A9A9'}>
                                        <div style={{ paddingLeft: 50, marginBottom: 24 }}>
                                            <div style={{ marginTop: 18 }}>
                                                <Button style={{ paddingLeft: 0, fontWeight: 'bold' }} disabled={!pickUpsEnabled} type="link" icon={<PlusOutlined />} onClick={() => { toggleNewPickUpStopLocation(); }}>Pick-Up</Button>
                                            </div>
                                            {getError(methods.errors, `pickUpStops`) !== undefined ? (
                                                <ErrorMessage
                                                    errors={methods.errors}
                                                    name={`pickUpStops`}
                                                    render={({ message, messages }) => {
                                                        if (isNotNullOrUndefined(messages)) {
                                                            return Object.entries(messages).map(([type, message]) => (<Alert key={`pickUpStops-${type}`} message={message} type="error" />));
                                                        } else if (isStringNotEmpty(message)) {
                                                            return <Alert message={message} type="error" />;
                                                        } else {
                                                            return null;
                                                        }
                                                    }}
                                                />
                                            ) : null}
                                        </div>
                                    </Timeline.Item>
                                )}
                                {isListNotEmpty(dropOffStops) ? (
                                    <>
                                        {dropOffStops.map((stop, index, arr) => {
                                            return (
                                                <Timeline.Item className={(stop.stopTypeSequence + 1 === dropOffStops.length) ? "customTimelineItem-last" : "customTimelineItem"} key={`dropOff-${index}`} color={dropOffsEnabled === true ? '#23BEBB' : '#A9A9A9'}>
                                                    <div style={{ paddingLeft: 50, marginBottom: 24 }}>
                                                        <div style={dropOffsEnabled === true ? null : { zIndex: 500, backgroundColor: 'white', opacity: 0.3 }}>
                                                            {getLoadStopLocationDisplay(stop, index)}
                                                        </div>
                                                        <div style={dropOffsEnabled === true ? null : { zIndex: 500, backgroundColor: 'white', opacity: 0.3 }}>
                                                            {getError(methods.errors, `dropOffStops-${index}`) !== undefined ? (
                                                                <ErrorMessage
                                                                    errors={methods.errors}
                                                                    name={`dropOffStops-${index}`}
                                                                    render={({ message, messages }) => {
                                                                        if (isNotNullOrUndefined(messages)) {
                                                                            return Object.entries(messages).map(([type, message]) => (<Alert key={`dropOffStops-${index}-${type}`} message={message} type="error" />));
                                                                        } else if (isStringNotEmpty(message)) {
                                                                            return <Alert message={message} type="error" />;
                                                                        } else {
                                                                            return null;
                                                                        }
                                                                    }}
                                                                />
                                                            ) : null}
                                                            {getError(methods.errors, `dropOffStops`) !== undefined ? (
                                                                <ErrorMessage
                                                                    errors={methods.errors}
                                                                    name={`dropOffStops`}
                                                                    render={({ message, messages }) => {
                                                                        if (isNotNullOrUndefined(messages)) {
                                                                            return Object.entries(messages).map(([type, message]) => (<Alert key={`dropOffStops-${type}`} message={message} type="error" />));
                                                                        } else if (isStringNotEmpty(message)) {
                                                                            return <Alert message={message} type="error" />;
                                                                        } else {
                                                                            return null;
                                                                        }
                                                                    }}
                                                                />
                                                            ) : null}
                                                        </div>
                                                        {(stop.stopTypeSequence + 1 === dropOffStops.length) ? (
                                                            <div style={{ marginTop: 18 }}>
                                                                <Button style={{ paddingLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} disabled={isStringEmpty(stop.apptType) || isStringEmpty(stop.loadingType) || isStringEmpty(stop.stopLocationId)} onClick={() => { toggleNewDropOffStopLocation(); }}>Additional Drop-Off</Button>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Timeline.Item>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <Timeline.Item className="customTimelineItem-last" key={`dropOff`} color={dropOffsEnabled === true ? '#23BEBB' : '#A9A9A9'}>
                                        <div style={{ paddingLeft: 50, marginBottom: 24 }}>
                                            <div style={{ marginTop: 18 }}>
                                                <Button style={{ paddingLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} disabled={!dropOffsEnabled} onClick={() => { toggleNewDropOffStopLocation(); }}>Drop-Off</Button>
                                            </div>
                                            {getError(methods.errors, `dropOffStops`) !== undefined ? (
                                                <ErrorMessage
                                                    errors={methods.errors}
                                                    name={`dropOffStops`}
                                                    render={({ message, messages }) => {
                                                        if (isNotNullOrUndefined(messages)) {
                                                            return Object.entries(messages).map(([type, message]) => (<Alert key={`dropOffStops-${type}`} message={message} type="error" />));
                                                        } else if (isStringNotEmpty(message)) {
                                                            return <Alert message={message} type="error" />;
                                                        } else {
                                                            return null;
                                                        }
                                                    }}
                                                />
                                            ) : null}
                                        </div>
                                    </Timeline.Item>
                                )}
                            </Timeline>
                        </div>
                        <Row gutter={[12, 12]} className="load-creation-step-buttons">
                            <Col xs={{ span: 24, order: 2 }} md={{ span: 12, order: 1 }}>
                                {cancelButton}
                            </Col>
                            <Col xs={{ span: 24, order: 1 }} md={{ span: 12, order: 2 }}>
                                <Button type="primary" block htmlType="submit" disabled={pickUpsEnabled === false || dropOffsEnabled === false}>{nextStepActionName}</Button>
                            </Col>
                        </Row>
                    </div>
                    <Drawer
                        title={'New Pick-Up'}
                        visible={showNewPickUpStopLocation === true}
                        onClose={toggleNewPickUpStopLocation}
                        bodyStyle={{ paddingBottom: 80 }}
                        style={{ zIndex: 1000 }}
                        width={fullWidth > 720 ? fullWidth / 2 : 360}
                        footer={null}
                        zIndex={1000}
                        destroyOnClose={true}
                        closable={false}
                        maskClosable={false}
                    >
                        <FormItem {...formItemLayout} label="Location" required format="vertical"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select a Location"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    ref={ref}
                                >
                                    {getLocationOptions(null)}
                                </Select>
                            )}
                            rules={{ required: 'Required Field' }}
                            name="pickUpLocationId"
                            defaultValue={null}
                        />
                        <br />
                        <b>Location Doesn't Exist Yet?</b>
                        <br />
                        <Button type='primary' onClick={toggleNewLocation}>Add New Location</Button>
                        <div className={classes.buttonContainer}>
                            <Button onClick={() => { toggleNewPickUpStopLocation(); }} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary"
                                onClick={() => {
                                    const values = methods.getValues();
                                    const stopLocationId = values.pickUpLocationId;
                                    const id = pickUpStops.length + dropOffStops.length;
                                    const sequence = pickUpStops.length + dropOffStops.length;
                                    const stopTypeSequence = pickUpStops.length;
                                    if (isStringNotEmpty(stopLocationId)) {
                                        let stopLocation = locationsList.find(l => l.id === stopLocationId);
                                        if (isObjectNotEmpty(stopLocation)) {
                                            let newStop = {
                                                stopLocation: stopLocation,
                                                timeZone: stopLocation.timeZone,
                                                latitude: stopLocation.latitude,
                                                longitude: stopLocation.longitude,
                                                stopLocationId: stopLocationId,
                                                stopType: 'PICK_UP',
                                                id: id,
                                                sequence: sequence,
                                                stopTypeSequence: stopTypeSequence,
                                                loadId: 'new-load'
                                            };

                                            if (isNumberGreaterThanZero(stopLocation.lumperFee)) {
                                                newStop.lumperFee = stopLocation.lumperFee;
                                            }
                                            if (isStringNotEmpty(stopLocation.specialInstructions)) {
                                                newStop.specialInstructions = stopLocation.specialInstructions;
                                            }
                                            if (isObjectNotEmpty(stopLocation.pointOfContact)) {
                                                newStop.apptPointOfContact = stopLocation.pointOfContact;
                                            }

                                            methods.clearErrors('pickUpStops');
                                            setPickUpStops(pickUpStops => [...pickUpStops, { ...newStop }]);
                                            toggleNewPickUpStopLocation();
                                        }
                                    }

                                    return false;
                                }}>
                                Add Pick-Up Location
                            </Button>
                        </div>
                    </Drawer>
                    <Drawer
                        title={'New Drop-Off'}
                        visible={showNewDropOffStopLocation === true}
                        onClose={toggleNewDropOffStopLocation}
                        bodyStyle={{ paddingBottom: 80 }}
                        style={{ zIndex: 1000 }}
                        width={fullWidth > 720 ? fullWidth / 2 : 360}
                        footer={null}
                        zIndex={1000}
                        destroyOnClose={true}
                        closable={false}
                        maskClosable={false}
                    >
                        <FormItem {...formItemLayout} label="Location" required format="vertical"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select a Location"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    ref={ref}
                                >
                                    {getLocationOptions(null)}
                                </Select>
                            )}
                            name="dropOffLocationId"
                            rules={{ required: 'Required Field' }}
                            defaultValue={null}
                        />
                        <br />
                        <b>Location Doesn't Exist Yet?</b>
                        <br />
                        <Button type='primary' onClick={toggleNewLocation}>Add New Location</Button>
                        <div className={classes.buttonContainer}>
                            <Button onClick={() => { toggleNewDropOffStopLocation(); }} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary"
                                onClick={() => {
                                    const values = methods.getValues();
                                    const stopLocationId = values.dropOffLocationId;
                                    const id = pickUpStops.length + dropOffStops.length;
                                    const sequence = pickUpStops.length + dropOffStops.length;
                                    const stopTypeSequence = dropOffStops.length;
                                    if (isStringNotEmpty(stopLocationId)) {
                                        let stopLocation = locationsList.find(l => l.id === stopLocationId);
                                        if (isObjectNotEmpty(stopLocation)) {
                                            let newStop = {
                                                stopLocation: stopLocation,
                                                timeZone: stopLocation.timeZone,
                                                latitude: stopLocation.latitude,
                                                longitude: stopLocation.longitude,
                                                stopLocationId: stopLocationId,
                                                stopType: 'DROP_OFF',
                                                id: id,
                                                sequence: sequence,
                                                stopTypeSequence: stopTypeSequence,
                                                loadId: 'new-load'
                                            };

                                            if (isNumberGreaterThanZero(stopLocation.lumperFee)) {
                                                newStop.lumperFee = stopLocation.lumperFee;
                                            }
                                            if (isStringNotEmpty(stopLocation.specialInstructions)) {
                                                newStop.specialInstructions = stopLocation.specialInstructions;
                                            }
                                            if (isObjectNotEmpty(stopLocation.pointOfContact)) {
                                                newStop.apptPointOfContact = stopLocation.pointOfContact;
                                            }

                                            methods.clearErrors('dropOffStops');
                                            setDropOffStops(dropOffStops => [...dropOffStops, { ...newStop }]);
                                            toggleNewDropOffStopLocation();
                                        }
                                    }

                                    return false;
                                }}>
                                Add Drop-Off Location
                            </Button>
                        </div>
                    </Drawer>
                </Form>
            </FormProvider>
            <Drawer
                title={selectedRecord ? (selectedRecord.stopType === "PICK_UP" ? "Pick-Up Location" : "Drop-Off Location") : 'Stop Location'}
                visible={showEditStopLocation === true}
                onClose={(e) => { toggleEditStopLocation(); setSelectedRecord(null); }}
                bodyStyle={{ paddingBottom: 80 }}
                style={{ zIndex: 1000 }}
                width={fullWidth > 720 ? fullWidth / 2 : 360}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                closable={false}
                maskClosable={false}
                forceRender={true}
            >
                <FormProvider {...methods3}>
                    <Form onSubmit={methods3.handleSubmit(onEditStopLocation)}>
                        <FormItem {...formItemLayout} label="Location" required format="vertical"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Select a Location"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    ref={ref}
                                >
                                    {getLocationOptions(selectedRecord)}
                                </Select>
                            )}
                            rules={{ required: 'Required Field' }}
                            name="locationId"
                            defaultValue={null}
                            help={selectedRecord && selectedRecord.stopLocationId ? "Changing the location will reset the Lumper Fee, Special Instructions, and Point of Contact Information if there are default values set for that location." : null}
                        />
                        <br />
                        <b>Location Doesn't Exist Yet?</b>
                        <br />
                        <Button type='primary' onClick={toggleNewLocation}>Add New Location</Button>
                        <div className={classes.buttonContainer}>
                            <Button onClick={() => { toggleEditStopLocation(); setSelectedRecord(null); }} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary" htmlType="submit">Update Location</Button>
                        </div>
                    </Form>
                </FormProvider>
            </Drawer>
            <Drawer
                title={selectedRecord ? (selectedRecord.stopType === "PICK_UP" ? "Date, Appointment, & Pick-Up Details" : "Date, Appointment, & Drop-Off Details") : 'Date, Appointment, & Stop Details'}
                onClose={(e) => { toggleEditStopAppointment(); setSelectedRecord(null); }}
                visible={showEditStopAppointment === true}
                bodyStyle={{ paddingBottom: 80 }}
                style={{ zIndex: 1000 }}
                width={fullWidth > 720 ? fullWidth / 2 : 360}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                closable={false}
                maskClosable={false}
                forceRender={true}
            >
                <FormProvider {...methods2}>
                    <Form onSubmit={methods2.handleSubmit(onEditStopAppointment)}>
                        {isObjectNotEmpty(selectedRecord) ? (
                            <>
                                <FormItem {...formItemLayout} label="Appointment Type" required format="vertical"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Select
                                            placeholder="Please Select an Appointment Type"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChangeAppointmentType(selected); onChange(selected); }}
                                            value={value}
                                            name={name}
                                            showSearch={true}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            ref={ref}
                                        >
                                            {appointmentTypeOptions}
                                        </Select>
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="apptType"
                                />
                                {(showFirstComeFirstServeDetails === true) ? (
                                    <div style={{ marginTop: 24 }}>
                                        <FormItem {...formItemLayout} style={{ marginLeft: 24, marginTop: 12 }} format="vertical" label="Please Choose the type of Appointment Window"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Radio.Group onBlur={onBlur} onChange={e => { onChangeApptWindowType(e.target.value); onChange(e.target.value); }} value={value} name={name} ref={ref}>
                                                    <Radio style={radioStyle2} key="ALL_DAY" value="ALL_DAY">
                                                        <span style={{ fontSize: 12 }}>All Day Appointment Window</span><br />
                                                        <div style={{ paddingLeft: 24, paddingTop: 12, paddingBottom: 12 }}>
                                                            <FormItem {...formItemLayout} label={selectedRecord ? (selectedRecord.stopType === "PICK_UP" ? "Requested Pick-Up Date" : "Requested Drop-Off Date") : 'Requested Date'} required format="vertical"
                                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                                    <DatePicker
                                                                        allowClear={true}
                                                                        placeholder="Requested Date"
                                                                        format="YYYY-MM-DD"
                                                                        style={{ width: '100%' }}
                                                                        onBlur={onBlur}
                                                                        onChange={(date, dateString) => { setApptWindowToAllDay(date); onChange(date); }}
                                                                        value={value}
                                                                        name={name}
                                                                        onSelect={(date) => { setApptWindowToAllDay(date); onChange(date); }}
                                                                        ref={ref}
                                                                        disabled={allDayDateDisabled}
                                                                    />
                                                                )}
                                                                rules={{ required: allDayDateDisabled === false ? "Required Field" : false }}
                                                                name="allDayDate"
                                                            />
                                                        </div>
                                                    </Radio>
                                                    <Radio style={radioStyle2} key="CUSTOM" value="CUSTOM">
                                                        <span style={{ fontSize: 12 }}>Custom Appointment Window</span><br />
                                                        <div style={{ paddingLeft: 24, paddingTop: 12, paddingBottom: 12 }}>
                                                            <FormItemDouble {...formItemLayoutDouble} label1={selectedRecord ? (selectedRecord.stopType === "PICK_UP" ? "Requested Pick-Up Date & Appointment Window" : "Requested Drop-Off Date & Appointment Window") : "Requested Date & Appointment Window"} required1 required2 format="vertical"
                                                                render1={({ onChange, onBlur, value, name, ref }) => (
                                                                    <DatePicker
                                                                        allowClear={true}
                                                                        placeholder="Start Date/Time"
                                                                        showTime={{ format: 'HH:mm', minuteStep: 15 }}
                                                                        format="YYYY-MM-DD HH:mm"
                                                                        style={{ width: '100%' }}
                                                                        onBlur={onBlur}
                                                                        onChange={(date, dateString) => { onChange(date); }}
                                                                        value={value}
                                                                        name={name}
                                                                        onSelect={(date) => { onChange(date); }}
                                                                        ref={ref}
                                                                        disabled={apptWindowDisabled}
                                                                    />
                                                                )}
                                                                rules1={{ required: apptWindowDisabled === false ? 'Required Field' : false }}
                                                                name1="apptWindowStartDateTime"
                                                                render2={({ onChange, onBlur, value, name, ref }) => (
                                                                    <DatePicker
                                                                        allowClear={true}
                                                                        placeholder="End Date/Time"
                                                                        showTime={{ format: 'HH:mm', minuteStep: 15 }}
                                                                        format="YYYY-MM-DD HH:mm"
                                                                        style={{ width: '100%' }}
                                                                        onBlur={onBlur}
                                                                        onChange={(date, dateString) => { onChange(date); }}
                                                                        value={value}
                                                                        name={name}
                                                                        onSelect={(date) => { onChange(date); }}
                                                                        ref={ref}
                                                                        disabled={apptWindowDisabled}
                                                                    />
                                                                )}
                                                                rules2={{ required: apptWindowDisabled === false ? 'Required Field' : false }}
                                                                name2="apptWindowEndDateTime"
                                                            />
                                                        </div>
                                                    </Radio>
                                                </Radio.Group>
                                            )}
                                            rules={{ required: 'Required Field' }}
                                            name="apptWindowType"
                                        />
                                    </div>
                                ) : null}
                                {(showHaveAppointmentDetails === true) ? (
                                    <>
                                        {hideLoadSpecificInformation === false ? (
                                            <FormItem {...formItemLayout} label="Appointment #" required={dontRequireLoadSpecificInformation === false} format="vertical"
                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                    <Input
                                                        onBlur={onBlur}
                                                        onChange={e => { onChange(e.target.value); }}
                                                        value={value}
                                                        name={name}
                                                        placeholder="Appointment #"
                                                        ref={ref}
                                                    />
                                                )}
                                                rules={{ required: dontRequireLoadSpecificInformation === false ? 'Required Field' : false }}
                                                name="apptNumber"
                                            />
                                        ) : null}
                                        <FormItem {...formItemLayout} label={selectedRecord ? (selectedRecord.stopType === "PICK_UP" ? "Requested Pick-Up Date & Appointment" : "Requested Drop-Off Date & Appointment") : "Requested Date & Appointment"} required format="vertical"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <DatePicker
                                                    allowClear={true}
                                                    placeholder="Appointment Date/Time"
                                                    showTime={{ format: 'HH:mm', minuteStep: 15 }}
                                                    format="YYYY-MM-DD HH:mm"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={(date, dateString) => { onChange(date); }}
                                                    value={value}
                                                    name={name}
                                                    onSelect={(date) => { onChange(date); }}
                                                    ref={ref}
                                                />
                                            )}
                                            rules={{ required: 'Required Field' }}
                                            name="apptDateTime"
                                        />
                                    </>
                                ) : null}
                                {(showNeedAppointmentDetails === true) ? (
                                    <>
                                        <FormItem {...formItemLayout} label={selectedRecord ? (selectedRecord.stopType === "PICK_UP" ? "Requested Pick-Up Date" : "Requested Drop-Off Date") : 'Requested Date'} required format="vertical"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <DatePicker
                                                    allowClear={true}
                                                    placeholder="Requested Date"
                                                    format="YYYY-MM-DD"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={(date, dateString) => { onChange(date); }}
                                                    value={value}
                                                    name={name}
                                                    onSelect={(date) => { onChange(date); }}
                                                    ref={ref}
                                                />
                                            )}
                                            rules={{ required: 'Required Field' }}
                                            name="requestedDateTime"
                                        />
                                        <FormItem {...formItemLayout} label="Appointment Call Ahead Date and Time" format="vertical"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <DatePicker
                                                    allowClear={true}
                                                    placeholder="Call Ahead Date/Time"
                                                    showTime={{ format: 'HH:mm', minuteStep: 15 }}
                                                    format="YYYY-MM-DD HH:mm"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={(date, dateString) => { onChange(date); }}
                                                    value={value}
                                                    name={name}
                                                    onSelect={(date) => { onChange(date); }}
                                                    ref={ref}
                                                />
                                            )}
                                            rules={{ required: false }}
                                            name="apptCallAheadDateTime"
                                        />
                                    </>
                                ) : null}
                                {(selectedRecord && selectedRecord.stopType === 'PICK_UP' && hideLoadSpecificInformation === false) ? (
                                    <FormItem {...formItemLayout} label="Pick-Up #" required={dontRequireLoadSpecificInformation === false} format="vertical"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Input
                                                onBlur={onBlur}
                                                onChange={e => { onChange(e.target.value); }}
                                                value={value}
                                                name={name}
                                                placeholder="Pick-Up #"
                                                ref={ref}
                                            />
                                        )}
                                        rules={{ required: dontRequireLoadSpecificInformation === false ? 'Required Field' : false }}
                                        name="pickUpNumber"
                                    />
                                ) : null}
                                {(selectedRecord && selectedRecord.stopType === 'DROP_OFF' && hideLoadSpecificInformation === false) ? (
                                    <>
                                        <FormItem {...formItemLayout} label="Drop-Off #" required={dontRequireLoadSpecificInformation === false} format="vertical"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Input
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e.target.value); }}
                                                    value={value}
                                                    name={name}
                                                    placeholder="Drop-Off #"
                                                    ref={ref}
                                                />
                                            )}
                                            rules={{ required: dontRequireLoadSpecificInformation === false ? 'Required Field' : false }}
                                            name="dropOffNumber"
                                        />
                                        <FormItem {...formItemLayout} label="Customer PO #" required={dontRequireLoadSpecificInformation === false} format="vertical"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <Input
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e.target.value); }}
                                                    value={value}
                                                    name={name}
                                                    placeholder="Customer PO #"
                                                    ref={ref}
                                                />
                                            )}
                                            rules={{ required: dontRequireLoadSpecificInformation === false ? 'Required Field' : false }}
                                            name="purchaseOrderNumber"
                                        />
                                    </>
                                ) : null}
                                <FormItemPointOfContact
                                    format="penta"
                                    required={false}
                                    name="apptPointOfContact"
                                    nameFieldLabel={selectedRecord ? (selectedRecord.stopType === "PICK_UP" ? "Pick-Up Contact" : "Drop-Off Contact") : 'Appointment Contact'}
                                />
                                <FormItem {...formItemLayout} label="Additional Appointment Instructions" format="vertical"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <TextArea
                                            onBlur={onBlur}
                                            onChange={e => { onChange(e.target.value); }}
                                            value={value}
                                            name={name}
                                            autoSize={{ minRows: 4 }}
                                            ref={ref}
                                        />
                                    )}
                                    rules={{ required: false }}
                                    name="apptInstructions"
                                />
                            </>
                        ) : null}
                        <div className={classes.buttonContainer}>
                            <Button onClick={() => { toggleEditStopAppointment(); setSelectedRecord(null); }} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary" htmlType="submit">{(selectedRecord && selectedRecord.requestedDateTime) ? 'Update Date, Appointment, and Stop Details' : 'Add Date, Appointment, and Stop Details'}</Button>
                        </div>
                    </Form>
                </FormProvider>
            </Drawer>
            <Drawer
                title="Search and Select a Shipper Account"
                onClose={toggleEditShipper}
                visible={showEditShipper === true}
                bodyStyle={{ paddingBottom: 80 }}
                style={{ zIndex: 1000 }}
                width={fullWidth > 720 ? fullWidth / 2 : 360}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                closable={false}
                maskClosable={false}
                forceRender={true}
            >
                <FormProvider {...methods4}>
                    <Form onSubmit={methods4.handleSubmit(onEditShipper)}>
                        <FormItem {...formItemLayout} required format="vertical"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <Select
                                    placeholder="Please Search and Select a Shipper Account"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    loading={isShippersLoading === true}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    notFoundContent={
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Shipper Accounts for you to choose from. You cannot create a load without assigning it to a Shipper account. Please add Shipper accounts first.">
                                            {(entityType === 'CARRIER' || entityType === 'BROKER') ? (
                                                <Button type="primary" onClick={() => { props.history.push({ pathname: `/linkedShippers/` }); }}>Setup Shipper Accounts</Button>
                                            ) : null}
                                        </Empty>
                                    }
                                    options={shippers.map(s => { return { label: s.name, value: s.id } })}
                                    ref={ref}
                                />
                            )}
                            rules={{ required: 'Required Field' }}
                            name="shipperId"
                            defaultValue={null}
                        />
                        <div className={classes.buttonContainer}>
                            <Button onClick={() => { toggleEditShipper(); }} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary" htmlType="submit">Save Shipper</Button>
                        </div>
                    </Form>
                </FormProvider>
            </Drawer>
            <Drawer
                title={'Add New Location'}
                visible={showNewLocation === true}
                onClose={toggleNewLocation}
                bodyStyle={{ paddingBottom: 80 }}
                style={{ zIndex: 1000 }}
                width={fullWidth > 720 ? fullWidth / 2 : 360}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                closable={false}
                maskClosable={false}
            >
                <NewLocation
                    cancel={toggleNewLocation}
                    accountId={isStringNotEmpty(entityId) && entityType !== 'STAFF' ? entityId : (isObjectNotEmpty(shipperData) && isStringNotEmpty(shipperData.shipperId) ? shipperData.shipperId : null)}
                    linkedAccountEntityType="SHIPPER"
                />
            </Drawer>
            <Drawer
                title={selectedRecord ? (selectedRecord.stopType === "PICK_UP" ? "Pick-Up BOL Information" : "Drop-Off BOL Information") : 'Stop BOL Information'}
                visible={showAddStopBOL === true}
                onClose={(e) => { toggleAddStopBOL(); setSelectedRecord(null); }}
                bodyStyle={{ paddingBottom: 80 }}
                style={{ zIndex: 1000 }}
                width={fullWidth > 720 ? fullWidth / 2 : 360}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                closable={false}
                maskClosable={false}
                forceRender={true}
            >
                <FormProvider {...methods5}>
                    <Form onSubmit={methods5.handleSubmit(onAddStopBOL)}>
                        {(getBOLNumberOptions(selectedRecord ? selectedRecord : null).length > 0) ? (
                            <>
                                <FormItem {...formItemLayout} label="Choose an existing BOL Number" format="vertical"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Radio.Group
                                            onBlur={onBlur}
                                            options={getBOLNumberOptions(selectedRecord ? selectedRecord : null)}
                                            onChange={e => { onChangeBOLNumber(e.target.value); onChange(e.target.value); }}
                                            value={value}
                                            name={name}
                                            ref={ref}
                                        />
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="existingBOLNumber"
                                />
                                {(showNewBOLNumber === true && selectedRecord && selectedRecord.stopType === 'PICK_UP') ? (
                                    <FormItem {...formItemLayout} label="BOL Number" required format="vertical"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Input
                                                onBlur={onBlur}
                                                onChange={e => { onChange(e.target.value); }}
                                                value={value}
                                                name={name}
                                                placeholder="BOL Number"
                                                ref={ref}
                                            />
                                        )}
                                        rules={{ required: 'Required Field' }}
                                        name="newBOLNumber"
                                    />
                                ) : null}
                            </>
                        ) : ((selectedRecord && selectedRecord.stopType === 'PICK_UP' && (bolNumberList.length < dropOffStops.length)) ? (
                            <FormItem {...formItemLayout} label="BOL Number" required format="vertical"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <Input
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e.target.value); }}
                                        value={value}
                                        name={name}
                                        placeholder="BOL Number"
                                        ref={ref}
                                    />
                                )}
                                rules={{ required: 'Required Field' }}
                                name="newBOLNumber"
                            />
                        ) : null)}
                        <FormItemFile {...formItemLayout} label="BOL File(s)" name="docFiles" format="vertical">
                            <UploadFile
                                beforeUpload={(file, fileList) => {
                                    if (isNotNullOrUndefined(file)) {
                                        setBOLDocFiles(oldFiles => [...oldFiles, file]);
                                    }

                                    return false;
                                }}
                                onRemove={(file) => {
                                    let updatedBOLDocFiles = [...bolDocFiles];
                                    let fileToRemoveIndex = updatedBOLDocFiles.findIndex(d => d.uid === file.uid);
                                    if (fileToRemoveIndex !== -1) {
                                        updatedBOLDocFiles.splice(fileToRemoveIndex, 1);
                                    }
                                    // console.log(updatedDocFiles);
                                    setBOLDocFiles([...updatedBOLDocFiles]);
                                }}
                                message="Please upload a photo or pdf of the BOL."
                                multiple={true}
                            />
                        </FormItemFile>
                        <div className={classes.buttonContainer}>
                            <Button onClick={() => { toggleAddStopBOL(); setSelectedRecord(null); }} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary" htmlType="submit">Add BOL Information</Button>
                        </div>
                    </Form>
                </FormProvider>
            </Drawer>
            <Drawer
                title={selectedRecord ? (selectedRecord.stopType === "PICK_UP" ? "Loading Specifics" : "Unloading Specifics") : 'Loading/Unloading Specifics'}
                visible={showEditStopLoadingSpecifics === true}
                onClose={(e) => { toggleEditStopLoadingSpecifics(); setSelectedRecord(null); }}
                bodyStyle={{ paddingBottom: 80 }}
                style={{ zIndex: 1000 }}
                width={fullWidth > 720 ? fullWidth / 2 : 360}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                closable={false}
                maskClosable={false}
                forceRender={true}
            >
                <FormProvider {...methods6}>
                    <Form onSubmit={methods6.handleSubmit(onEditStopLoadingSpecifics)}>
                        {isObjectNotEmpty(selectedRecord) ? (
                            <Fieldset legend={selectedRecord.stopType === 'PICK_UP' ? "Loading Type Options *" : (selectedRecord.stopType === 'DROP_OFF' ? "Unloading Type Options *" : "Loading/Unloading Type Options *")}>
                                <div>How will the trailer be {selectedRecord.stopType === 'PICK_UP' ? "loaded" : (selectedRecord.stopType === 'DROP_OFF' ? "unloaded" : "loaded/unloaded")}?</div>
                                <FormItem {...formItemLayout} format="vertical"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <Radio.Group onBlur={onBlur} onChange={e => onChange(e.target.value)} value={value} name={name} ref={ref}>
                                            <Radio style={radioStyle} key="LIVE" value="LIVE">
                                                <span style={{ fontWeight: 'bold', fontSize: 16 }}>Live {selectedRecord.stopType === 'PICK_UP' ? "Load" : (selectedRecord.stopType === 'DROP_OFF' ? "Unload" : "Load/Unload")} Trailer</span><br /><span style={{ fontSize: 14, paddingLeft: 24 }}>Trailer will be {selectedRecord.stopType === 'PICK_UP' ? "loaded" : (selectedRecord.stopType === 'DROP_OFF' ? "unloaded" : "loaded/unloaded")} upon arrival.</span>
                                            </Radio>
                                            <Radio style={radioStyle} key="DROP" value="DROP">
                                                <span style={{ fontWeight: 'bold', fontSize: 16 }}>Drop Trailer</span><br /><span style={{ fontSize: 14, paddingLeft: 24 }}>Drop and hook.</span>
                                            </Radio>
                                        </Radio.Group>
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="loadingType"
                                />
                            </Fieldset>
                        ) : null}
                        <Fieldset legend="Additional Fees">
                            <div>Are there any additional fees?</div>
                            <FormItemDouble {...formItemLayoutDouble2} label1="Lumper Fee" format="vertical"
                                render1={({ onChange, onBlur, value, name, ref }) => (
                                    <Switch
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        onBlur={onBlur}
                                        onChange={(checked, event) => { onChange(checked); setLumperFeeDisabled(!checked); }}
                                        checked={value}
                                        name={name}
                                        ref={ref}
                                    />
                                )}
                                rules1={{ required: false }}
                                name1="hasLumperFee"
                                render2={({ onChange, onBlur, value, name, ref }) => (
                                    <InputNumber
                                        precision={2}
                                        min={0}
                                        placeholder="Lumper Fee"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                        disabled={lumperFeeDisabled === true}
                                        ref={ref}
                                    />
                                )}
                                rules2={{ required: false }}
                                name2="lumperFee"
                            />
                            <FormItemDouble {...formItemLayoutDouble2} label1="Driver Assist" format="vertical"
                                render1={({ onChange, onBlur, value, name, ref }) => (
                                    <Switch
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        onBlur={onBlur}
                                        onChange={(checked, event) => { onChange(checked); setDriverAssistDisabled(!checked); }}
                                        checked={value}
                                        name={name}
                                        ref={ref}
                                    />
                                )}
                                rules1={{ required: false }}
                                name1="hasDriverAssist"
                                render2={({ onChange, onBlur, value, name, ref }) => (
                                    <InputNumber
                                        precision={2}
                                        min={0}
                                        placeholder="Driver Assist Fee"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                        disabled={driverAssistDisabled === true}
                                        ref={ref}
                                    />
                                )}
                                rules2={{ required: false }}
                                name2="driverAssist"
                            />
                        </Fieldset>
                        <Fieldset legend="Special Instructions">
                            <FormItem {...formItemLayout} label="Special Instructions" format="vertical"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <TextArea
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e.target.value); }}
                                        value={value}
                                        name={name}
                                        autoSize={{ minRows: 4 }}
                                        ref={ref}
                                    />
                                )}
                                rules={{ required: false }}
                                name="specialInstructions"
                            />
                        </Fieldset>
                        <div className={classes.buttonContainer}>
                            <Button onClick={() => { toggleEditStopLoadingSpecifics(); setSelectedRecord(null); }} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary" htmlType="submit">{selectedRecord ? (selectedRecord.stopType === "PICK_UP" ? "Update Loading Specifics" : "Update Unloading Specifics") : 'Update Loading/Unloading Specifics'}</Button>
                        </div>
                    </Form>
                </FormProvider>
            </Drawer>
            <Drawer
                title={selectedRecord ? (selectedRecord.stopType === "PICK_UP" ? "Pick-Up Commodity" : "Drop-Off Commodity") : 'Stop Commodity'}
                visible={showAddStopCommodity === true}
                onClose={(e) => { toggleAddStopCommodity(); setSelectedRecord(null); }}
                bodyStyle={{ paddingBottom: 80 }}
                style={{ zIndex: 1000 }}
                width={fullWidth > 720 ? fullWidth / 2 : 360}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                closable={false}
                maskClosable={false}
                forceRender={true}
            >
                <FormProvider {...methods7}>
                    <Form onSubmit={methods7.handleSubmit(onAddStopCommodity)}>
                        {isObjectNotEmpty(selectedRecord) ? (
                            <>
                                <Fieldset legend={selectedRecord.stopType === "PICK_UP" ? "Pick-Up Location" : "Drop-Off Location"}>
                                    {getStopDetails()}
                                </Fieldset>
                                <Fieldset legend="Commodity Details">
                                    <FormItem {...formItemLayout} label="Name" required format="vertical"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Select
                                                placeholder="Please Search and Select a Commodity to Add"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                ref={ref}
                                            >
                                                {getCommodityOptions(selectedRecord)}
                                            </Select>
                                        )}
                                        rules={{ required: 'Required Field' }}
                                        name="commodityId"
                                    />
                                    <FormItemDouble {...formItemLayoutDouble3} label1="Unit Quantity" label2="Unit of Measure/Unit Packaging" required1 required2 format="vertical"
                                        render1={({ onChange, onBlur, value, name, ref }) => (
                                            <InputNumber
                                                precision={0}
                                                min={0}
                                                placeholder="Unit Quantity"
                                                style={{ width: '100%' }}
                                                onBlur={onBlur}
                                                onChange={e => { onChange(e); }}
                                                value={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        rules1={{ required: 'Required Field' }}
                                        name1="unitPackagingInitCount"
                                        render2={({ onChange, onBlur, value, name, ref }) => (
                                            <Select
                                                placeholder="Please Select a Unit of Measure/Unit Packaging"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                ref={ref}
                                            >
                                                {unitPackagingOptions}
                                            </Select>
                                        )}
                                        rules2={{ required: 'Required Field' }}
                                        name2="unitPackaging"
                                    />
                                    <FormItemDouble {...formItemLayoutDouble3} label1="Bulk Quantity" label2="Bulk Packaging" format="vertical"
                                        render1={({ onChange, onBlur, value, name, ref }) => (
                                            <InputNumber
                                                precision={0}
                                                min={0}
                                                placeholder="Bulk Quantity"
                                                style={{ width: '100%' }}
                                                onBlur={onBlur}
                                                onChange={e => { onChange(e); }}
                                                value={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        rules1={{ required: false }}
                                        name1="bulkPackagingInitCount"
                                        render2={({ onChange, onBlur, value, name, ref }) => (
                                            <Select
                                                placeholder="Please Select a Bulk Packaging"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                ref={ref}
                                            >
                                                {bulkPackagingOptions}
                                            </Select>
                                        )}
                                        rules2={{ required: false }}
                                        name2="bulkPackaging"
                                    />
                                </Fieldset>
                            </>
                        ) : null}
                        <div className={classes.buttonContainer}>
                            <Button onClick={() => { toggleAddStopCommodity(); setSelectedRecord(null); }} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary" htmlType="submit">Add Commodity</Button>
                        </div>
                    </Form>
                </FormProvider>
            </Drawer>
        </>
    );
}

export default withRouter(NewLoadSchedule);