import React, { useState, useEffect } from "react";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import classes from './FormFieldset.module.scss';
import { isBooleanTrue, isStringNotEmpty } from "../../shared/objectUtils";

const Fieldset = ({ legend, isCollapsable = false, isCollapsed = false, extra = null, fieldsetStyle = null, legendStyle = null, children }) => {
    const [isRowCollapsed, setIsRowCollapsed] = useState(false);

    useEffect(() => {
        setIsRowCollapsed(isCollapsed);
    }, [isCollapsed]);

    const onCollapse = () => {
        setIsRowCollapsed(!isRowCollapsed);
    };

    const getCollapseIcon = () => {
        if (isBooleanTrue(isCollapsable)) {
            if (isBooleanTrue(isRowCollapsed)) {
                return (
                    <Button type="link" icon={<RightOutlined />} onClick={onCollapse} style={{ marginLeft: 8 }} />
                );
            } else {
                return (
                    <Button type="link" icon={<DownOutlined />} onClick={onCollapse} style={{ marginLeft: 8 }} />
                );
            }
        } else {
            return undefined;
        }
    };

    if (isBooleanTrue(isCollapsable)) {
        return (
            <fieldset style={fieldsetStyle}>
                {isStringNotEmpty(legend) ? (<legend className={classes.legend} style={legendStyle}>{legend}{getCollapseIcon()}{extra}</legend>) : null}
                <div style={{ display: isRowCollapsed ? 'none' : 'inherit' }}>
                    {children}
                </div>
            </fieldset>
        );
    } else {
        return (
            <fieldset style={fieldsetStyle}>
                {isStringNotEmpty(legend) ? (<legend className={classes.legend} style={legendStyle}>{legend}{extra}</legend>) : null}
                {children}
            </fieldset>
        );
    }
};

export default Fieldset;