import * as actionTypes from "../actions/actionTypes";
import logger from "../../shared/logger";
import * as Data from "../../api/data/index";
import { convertStringToFloatOrZero, isObjectNotEmpty, isStringNotEmpty } from "../../shared/objectUtils";
import LocalStorage from '../../shared/localStorageUtils';
import { getCurrentLocation } from "../../shared/locationUtils";
import * as actionCreators from "./index";
import ErrorUtils from "../../shared/errorUtils";

//#region Load Event Functions

export const updateLoadEventStatus = (payload) => {
    return {
        type: actionTypes.UPDATE_LOAD_EVENT_STATUS,
        payload: payload
    }
};

export const updateLoadEventStart = () => {
    return {
        type: actionTypes.UPDATE_LOAD_EVENT_START
    }
};

export const updateLoadEventSuccess = () => {
    return {
        type: actionTypes.UPDATE_LOAD_EVENT_SUCCESS
    }
};

export const updateLoadEventFail = (payload) => {
    return {
        type: actionTypes.UPDATE_LOAD_EVENT_FAIL,
        payload: payload
    }
};

export const updateLoadEventErrorClear = () => {
    return {
        type: actionTypes.UPDATE_LOAD_EVENT_ERROR_CLEAR
    }
};

export const updateLoadEventComplete = () => {
    return {
        type: actionTypes.UPDATE_LOAD_EVENT_COMPLETE
    }
};

//#endregion
//#region Load Event Methods

export const loadEventUpdateTimeout = (loadId, stopId = null, loadStatus = null, stopStatus = null, eventType, changeType = null) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updateLoadEventStatus({ loadEventUpdateStatus: 'PENDING' }));

            // console.log('load event update timeout started');
            setTimeout(() => {
                // get the current status after 60 seconds and check to see if it is completed
                // if it is not completed then fetch the activeload or current load to refresh things to the current state of the load according to the database
                const state = getState();
                const loadsState = { ...state.loads };
                const activeLoadState = { ...state.activeLoad };
                const loadEventsState = { ...state.loadEvents };
                const loadEventUpdateStatus = loadEventsState.loadEventUpdateStatus;
                const authState = { ...state.auth };
                const entityType = authState.entityType;
                const yourAccountUsersState = { ...state.yourAccountUsers };
                const yourAccountUser = yourAccountUsersState.record;

                let currentLoad = isObjectNotEmpty(loadsState.record) ? { ...loadsState.record } : null;
                let activeLoad = isObjectNotEmpty(activeLoadState.activeLoad) ? { ...activeLoadState.activeLoad } : null;

                // console.log(loadEventUpdateStatus);
                if (loadEventUpdateStatus !== 'COMPLETED') {
                    if (isObjectNotEmpty(currentLoad) && isStringNotEmpty(currentLoad.id)) {
                        if (currentLoad.id === loadId) {
                            dispatch(actionCreators.fetchLoadWithIncludes(loadId));
                            dispatch(updateLoadEventStatus({ loadEventUpdateStatus: 'COMPLETED' }));
                        }
                    }

                    if ((entityType === 'DRIVER' || entityType === 'CARRIER') && isObjectNotEmpty(yourAccountUser) && isStringNotEmpty(yourAccountUser.id) && isObjectNotEmpty(activeLoad) && isStringNotEmpty(activeLoad.id)) {
                        if (activeLoad.id === loadId) {
                            dispatch(actionCreators.fetchActiveLoad(yourAccountUser.id));
                            dispatch(updateLoadEventStatus({ loadEventUpdateStatus: 'COMPLETED' }));
                        }
                    }
                    // console.log('load event update timeout executed');
                } else {
                    // console.log('load event update timeout cancelled');
                }
            }, 60000);
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
        }
    };
};

export const sendLoadEvent = (payload) => {
    return async (dispatch) => {
        try {
            dispatch(updateLoadEventStart());

            const res = await Data.addLoadEvent(payload);
            if (isObjectNotEmpty(res)) {
                dispatch(updateLoadEventStatus({ loadEventUpdateStatus: 'PENDING' }));
                dispatch(loadEventUpdateTimeout(res.loadId, res.stopId, res.loadStatus, res.stopStatus, res.eventType));

                dispatch(updateLoadEventSuccess());
                dispatch(updateLoadEventComplete());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateLoadEventFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const loadStarted = (payload, load, drivers, assets) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updateLoadEventStart());

            const newLatLng = await getCurrentLocation();
            if (isObjectNotEmpty(newLatLng)) {
                const loadEventUpdate = await Data.startLoad(payload, load, drivers, assets, newLatLng.latitude, newLatLng.longitude);
                if (isObjectNotEmpty(loadEventUpdate)) {
                    dispatch(loadEventUpdateTimeout(load.id, null, 'IN_TRANSIT', null, 'LOAD_STARTED'));

                    const state = getState();
                    const activeLoadState = { ...state.activeLoad };
                    if (isObjectNotEmpty(activeLoadState.activeLoad)) {
                        let updatedLoad = { ...activeLoadState.activeLoad, latitude: newLatLng.latitude, longitude: newLatLng.longitude };

                        // turn on location tracking for the load and geofencing for the load
                        await LocalStorage.setItem('activeLoadId', load.id);
                        await LocalStorage.setItem('activeLoadIrisId', load.irisId);
                        await LocalStorage.setItem('currentLocation', JSON.stringify(newLatLng));
                        await LocalStorage.setItem('sendToTMS', (0).toString());
                        await LocalStorage.setItem('notMovedCount', (0).toString());

                        dispatch(actionCreators.updateActiveLoad({ activeLoad: updatedLoad, isLocationTurnedOn: true, isGeoFencingTurnedOn: true }));

                        // reset distance travelled
                        await LocalStorage.setItem('distanceTravelled', (0).toString());

                        dispatch(updateLoadEventSuccess());
                        dispatch(updateLoadEventComplete());
                    }
                }
            } else {
                throw new Error('Please make sure location services are turned on. Failed to get your current location.');
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateLoadEventFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const loadArriving = (loadId, stopId) => {
    return async (dispatch) => {
        dispatch(sendLoadEvent({ loadId: loadId, stopId: stopId, eventType: 'LOAD_ARRIVING' }));
    }
};

export const loadArrived = (loadId, stopId) => {
    return async (dispatch) => {
        try {
            dispatch(updateLoadEventStart());

            const distanceTravelledVal = await LocalStorage.getItem('distanceTravelled');
            let distanceTravelled = convertStringToFloatOrZero(distanceTravelledVal);

            const loadEventUpdate = await Data.addLoadEvent({ loadId: loadId, stopId: stopId, eventType: 'LOAD_ARRIVED', actualDistanceTo: distanceTravelled, actualDistanceToUnit: 'MI' });
            if (isObjectNotEmpty(loadEventUpdate)) {
                // reset distance travelled
                await LocalStorage.setItem('distanceTravelled', (0).toString());

                dispatch(loadEventUpdateTimeout(loadId, stopId, 'AT_STOP', 'ARRIVED', 'LOAD_ARRIVED'));

                dispatch(updateLoadEventSuccess());
                dispatch(updateLoadEventComplete());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateLoadEventFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const loadPickUpLoading = (loadId, stopId) => {
    return async (dispatch) => {
        dispatch(sendLoadEvent({ loadId: loadId, stopId: stopId, eventType: 'LOAD_PICK_UP_LOADING' }));
    }
};

export const loadPickUpLoaded = (loadId, stopId) => {
    return async (dispatch) => {
        dispatch(sendLoadEvent({ loadId: loadId, stopId: stopId, eventType: 'LOAD_PICK_UP_LOADED' }));
    }
};

export const loadPickUpCompleted = (payload, loadId, stopId) => {
    return async (dispatch) => {
        try {
            dispatch(updateLoadEventStart());

            const loadEventUpdate = await Data.completeLoadPickUpStop(loadId, stopId, payload);
            if (isObjectNotEmpty(loadEventUpdate)) {
                dispatch(loadEventUpdateTimeout(loadId, stopId, 'IN_TRANSIT', 'COMPLETED', 'LOAD_PICK_UP_COMPLETED'));

                dispatch(updateLoadEventSuccess());
                dispatch(updateLoadEventComplete());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateLoadEventFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const loadDropOffUnloading = (loadId, stopId) => {
    return async (dispatch) => {
        dispatch(sendLoadEvent({ loadId: loadId, stopId: stopId, eventType: 'LOAD_DROP_OFF_UNLOADING' }));
    }
};

export const loadDropOffUnloaded = (loadId, stopId) => {
    return async (dispatch) => {
        dispatch(sendLoadEvent({ loadId: loadId, stopId: stopId, eventType: 'LOAD_DROP_OFF_UNLOADED' }));
    }
};

export const loadDropOffCompleted = (payload, loadId, stopId) => {
    return async (dispatch) => {
        try {
            dispatch(updateLoadEventStart());

            const loadEventUpdate = await Data.completeLoadDropOffStop(loadId, stopId, payload);
            if (isObjectNotEmpty(loadEventUpdate)) {
                dispatch(loadEventUpdateTimeout(loadId, stopId, 'IN_TRANSIT', 'COMPLETED', 'LOAD_DROP_OFF_COMPLETED'));

                dispatch(updateLoadEventSuccess());
                dispatch(updateLoadEventComplete());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateLoadEventFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const restStopAdded = (loadId, stopPayload) => {
    return async (dispatch) => {
        try {
            dispatch(updateLoadEventStart());

            // don't allow the stop to be created unless there is a lat/long location value
            const newLatLng = await getCurrentLocation();
            if (isObjectNotEmpty(newLatLng)) {
                const distanceTravelledVal = await LocalStorage.getItem('distanceTravelled');
                let distanceTravelled = convertStringToFloatOrZero(distanceTravelledVal);

                const newRestStop = await Data.addRestStop(stopPayload, newLatLng.latitude, newLatLng.longitude, distanceTravelled, loadId);
                if (isObjectNotEmpty(newRestStop)) {
                    // reset distance travelled
                    await LocalStorage.setItem('distanceTravelled', (0).toString());

                    dispatch(loadEventUpdateTimeout(loadId, null, 'AT_STOP', null, 'REST_STOP_ADDED'));

                    dispatch(updateLoadEventSuccess());
                    dispatch(updateLoadEventComplete());
                }
            } else {
                throw new Error('Please make sure location services are turned on. Failed to get your current location.');
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateLoadEventFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const restStopStarted = (loadId, stopId) => {
    return async (dispatch) => {
        dispatch(sendLoadEvent({ loadId: loadId, stopId: stopId, eventType: 'REST_STOP_STARTED' }));
    }
};

export const restStopEnded = (loadId, stopId) => {
    return async (dispatch) => {
        dispatch(sendLoadEvent({ loadId: loadId, stopId: stopId, eventType: 'REST_STOP_ENDED' }));
    }
};

export const loadLayoverStarted = (loadId, stopId) => {
    return async (dispatch) => {
        dispatch(sendLoadEvent({ loadId: loadId, stopId: stopId, eventType: 'LOAD_LAYOVER_STARTED' }));
    }
};

export const loadLayoverEnded = (loadId, stopId) => {
    return async (dispatch) => {
        try {
            dispatch(updateLoadEventStart());

            const loadEventUpdate = await Data.addLoadEvent({ loadId: loadId, stopId: stopId, eventType: 'LOAD_ARRIVED' });
            if (isObjectNotEmpty(loadEventUpdate)) {
                // reset distance travelled
                await LocalStorage.setItem('distanceTravelled', (0).toString());

                dispatch(loadEventUpdateTimeout(loadId, stopId, 'AT_STOP', 'ARRIVED', 'LOAD_ARRIVED'));

                dispatch(updateLoadEventSuccess());
                dispatch(updateLoadEventComplete());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateLoadEventFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

export const loadCompleted = (payload, loadId) => {
    return async (dispatch) => {
        try {
            dispatch(updateLoadEventStart());

            const newLatLng = await getCurrentLocation();
            if (isObjectNotEmpty(newLatLng)) {
                const loadEventUpdate = await Data.completeLoad(loadId, payload, newLatLng.latitude, newLatLng.longitude);
                if (isObjectNotEmpty(loadEventUpdate)) {
                    dispatch(loadEventUpdateTimeout(loadId, null, 'COMPLETED', null, 'LOAD_COMPLETED'));

                    dispatch(updateLoadEventSuccess());
                    dispatch(updateLoadEventComplete());
                }
            } else {
                throw new Error('Please make sure location services are turned on. Failed to get your current location.');
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, ErrorUtils.getErrorMessage(error), true);
            dispatch(updateLoadEventFail({ error: ErrorUtils.getErrorMessage(error) }));
        }
    }
};

//#endregion