import { isObjectEmpty, isObjectNotEmpty, isStringEmpty } from "../../shared/objectUtils";

const getMissingAccountInformation = (account) => {
    let missingAccountInformationList = [];
    if (isObjectNotEmpty(account)) {
        if (isObjectEmpty(account.pointOfContact) && (account.entityTypes.includes('SHIPPER') || account.entityTypes.includes('CARRIER') || account.entityTypes.includes('BROKER'))) {
            missingAccountInformationList.push({ entity: account, entityType: 'ACCOUNT', entityId: account.id, entityName: account.name, missingField: 'pointOfContact', missingFieldName: 'Point of Contact' });
        }
        if (isObjectEmpty(account.companyAddress) && (account.entityTypes.includes('SHIPPER') || account.entityTypes.includes('CARRIER') || account.entityTypes.includes('BROKER'))) {
            missingAccountInformationList.push({ entity: account, entityType: 'ACCOUNT', entityId: account.id, entityName: account.name, missingField: 'companyAddress', missingFieldName: 'Company Address' });
        }
        if (isObjectEmpty(account.accountsPayable) && (account.entityTypes.includes('SHIPPER') || account.entityTypes.includes('BROKER'))) {
            missingAccountInformationList.push({ entity: account, entityType: 'ACCOUNT', entityId: account.id, entityName: account.name, missingField: 'accountsPayable', missingFieldName: 'Accounts Payable Contact' });
        }
        if (isObjectEmpty(account.accountsPayableAddress) && (account.entityTypes.includes('SHIPPER') || account.entityTypes.includes('BROKER'))) {
            missingAccountInformationList.push({ entity: account, entityType: 'ACCOUNT', entityId: account.id, entityName: account.name, missingField: 'accountsPayableAddress', missingFieldName: 'Accounts Payable Address' });
        }
        if (isObjectEmpty(account.accountsReceivable) && (account.entityTypes.includes('CARRIER') || account.entityTypes.includes('BROKER'))) {
            missingAccountInformationList.push({ entity: account, entityType: 'ACCOUNT', entityId: account.id, entityName: account.name, missingField: 'accountsReceivable', missingFieldName: 'Accounts Receivable Contact' });
        }
        if (isObjectEmpty(account.accountsReceivableAddress) && (account.entityTypes.includes('CARRIER') || account.entityTypes.includes('BROKER'))) {
            missingAccountInformationList.push({ entity: account, entityType: 'ACCOUNT', entityId: account.id, entityName: account.name, missingField: 'accountsReceivableAddress', missingFieldName: 'Accounts Receivable Address' });
        }

        if (isStringEmpty(account.email) && (account.entityTypes.includes('SHIPPER') || account.entityTypes.includes('CARRIER') || account.entityTypes.includes('BROKER'))) {
            missingAccountInformationList.push({ entity: account, entityType: 'ACCOUNT', entityId: account.id, entityName: account.name, missingField: 'email', missingFieldName: 'Company Email Address' });
        }
        if (isStringEmpty(account.phone) && (account.entityTypes.includes('SHIPPER') || account.entityTypes.includes('CARRIER') || account.entityTypes.includes('BROKER'))) {
            missingAccountInformationList.push({ entity: account, entityType: 'ACCOUNT', entityId: account.id, entityName: account.name, missingField: 'phone', missingFieldName: 'Company Day Time Phone Number' });
        }
        if (isStringEmpty(account.afterHoursPhone) && (account.entityTypes.includes('SHIPPER') || account.entityTypes.includes('CARRIER') || account.entityTypes.includes('BROKER'))) {
            missingAccountInformationList.push({ entity: account, entityType: 'ACCOUNT', entityId: account.id, entityName: account.name, missingField: 'afterHoursPhone', missingFieldName: 'Company After Hours Phone Number' });
        }
        if (isStringEmpty(account.preferredPaymentMethod) && (account.entityTypes.includes('CARRIER') || account.entityTypes.includes('BROKER'))) {
            missingAccountInformationList.push({ entity: account, entityType: 'ACCOUNT', entityId: account.id, entityName: account.name, missingField: 'preferredPaymentMethod', missingFieldName: 'Preferred Payment Method' });
        }
    }

    return missingAccountInformationList;
};

export default {
    getMissingAccountInformation
};