import React, { useState, useMemo, useEffect } from 'react';
import LoadMap from "../../components/LoadMap/LoadMap";
import LoadDetails from "../../components/LoadDetails/LoadDetails";
import LoadDocuments from "../../components/LoadDocuments/LoadDocuments";
import LoadMessages from "../../components/LoadMessages/LoadMessages";
import LoadNotifications from "../../components/LoadNotifications/LoadNotifications";
import LoadInvoice from "../../components/LoadInvoice/LoadInvoice";
import LoadStops from "../../components/LoadStops/LoadStops";
import LoadAssets from "../../components/LoadAssets/LoadAssets";
import LoadClaims from "../../components/LoadClaims/LoadClaims";
import LoadNotes from "../../components/LoadNotes/LoadNotes";
import { Tabs, Spin, Card, Drawer, Button } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { useLocation } from 'react-router';
import { withRouter } from 'react-router-dom';
import Header from '../../components/Header/Header';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruckLoading, faTruck, faPallet, faRedoAlt, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import classes from './Load.module.scss';
import AssignLoadCarrier from '../../components/AssignLoadCarrier/AssignLoadCarrier';
import { isListNotEmpty, isNumberNotEmpty, isObjectNotEmpty, isStringEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import { CanDo, CanSee } from '../../shared/entitlements/entitlements';
import { selectListIsLoading, selectListRecords } from '../../store/utility';
import LoadUtils from '../../api/utils/loadUtils';
import LoadCompletionCarrier from '../../components/LoadCompletionCarrier/LoadCompletionCarrier';

const { TabPane } = Tabs;

const Load = (props) => {
    //#region props and constants

    const fullWidth = global.window.innerWidth;
    const loadId = props.match.params.loadId;

    //#endregion
    //#region useLocation

    const location = useLocation();

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);
    const isLoading = useSelector(state => state.loads.isRecordLoading);
    const load = useSelector(state => state.loads.record);
    const claims = useSelector(state => selectListRecords(state.claims.lists, loadId));
    const isLoadingClaims = useSelector(state => selectListIsLoading(state.claims.lists, loadId));
    const transactions = useSelector(state => selectListRecords(state.transactions.lists, loadId));
    const isLoadingTransactions = useSelector(state => selectListIsLoading(state.transactions.lists, loadId));
    const invoiceLineItems = useSelector(state => selectListRecords(state.invoiceLineItems.lists, loadId));
    const isLoadingInvoiceLineItems = useSelector(state => selectListIsLoading(state.invoiceLineItems.lists, loadId));
    const stops = useSelector(state => selectListRecords(state.loadStops.lists, loadId));
    const isLoadingStops = useSelector(state => selectListIsLoading(state.loadStops.lists, loadId));
    const loadCancellations = useSelector(state => selectListRecords(state.loadCancellations.lists, loadId));
    const isLoadingLoadCancellations = useSelector(state => selectListIsLoading(state.loadCancellations.lists, loadId));
    const carrierRejections = useSelector(state => selectListRecords(state.carrierRejections.lists, loadId));
    const isLoadingCarrierRejections = useSelector(state => selectListIsLoading(state.carrierRejections.lists, loadId));
    const invoices = useSelector(state => selectListRecords(state.invoices.lists, loadId));
    const isLoadingInvoices = useSelector(state => selectListIsLoading(state.invoices.lists, loadId));
    const loadEventUpdateStatus = useSelector(state => state.loadEvents.loadEventUpdateStatus);

    //#endregion
    //#region useStates

    const [tabKey, setTabKey] = useState('snapshot');
    const [currentStop, setCurrentStop] = useState(null);
    const [currentStopType, setCurrentStopType] = useState('Stop');
    const [isDeadHeading, setIsDeadHeading] = useState(false);
    const [currentStatus, setCurrentStatus] = useState('PENDING');
    const [displayStatus, setDisplayStatus] = useState('Pending');
    const [showAssignCarrier, setShowAssignCarrier] = useState(false);

    //#endregion
    //#region toggles

    const toggleAssignCarrier = () => {
        setShowAssignCarrier(!showAssignCarrier);
    };

    //#endregion
    //#region onChanges

    const onChangeCurrentStop = (stop) => {
        setCurrentStop(stop);
    };

    //#endregion
    //#region load methods

    const getStatus = (load, stops, loadId, entityType) => {
        const res = LoadUtils.getLoadStatus(load, stops, loadId, entityType);
        if (isObjectNotEmpty(res)) {
            onChangeCurrentStop(res.currentStop);
            setIsDeadHeading(res.isDeadHeading);
            setCurrentStopType(res.currentStopType);
            setCurrentStatus(res.currentStatus);
            setDisplayStatus(res.displayStatus);
        }
    };

    const refreshLoad = () => {
        if (isStringNotEmpty(loadId)) {
            dispatch(actionCreators.fetchLoadWithIncludes(loadId));
        }
    };

    //#endregion
    //#region tab methods

    const onTabChange = (key) => {
        props.history.replace({ ...props.location, hash: `#${key}` });
    };

    //#endregion
    //#region useMemos and useEffects

    useMemo(() => {
        if (isStringNotEmpty(loadId)) {
            dispatch(actionCreators.fetchLoadWithIncludes(loadId));
            dispatch(actionCreators.fetchClaimList(loadId, { page: 1, size: 1000000, loadId: loadId, isDeleted: false }));
            dispatch(actionCreators.fetchInvoiceList(loadId, { page: 1, size: 1000000, loadId: loadId, isDeleted: false }));
            dispatch(actionCreators.fetchInvoiceLineItemList(loadId, { page: 1, size: 1000000, loadId: loadId, isDeleted: false }));
            dispatch(actionCreators.fetchTransactionList(loadId, { page: 1, size: 1000000, loadId: loadId, isDeleted: false }));
            dispatch(actionCreators.fetchLoadCancellationList(loadId, { page: 1, size: 1000000, loadId: loadId, isDeleted: false }));
            dispatch(actionCreators.fetchCarrierRejectionList(loadId, { page: 1, size: 1000000, loadId: loadId, isDeleted: false }));
        }
    }, [loadId]);

    useMemo(() => {
        if (isObjectNotEmpty(load) && isStringNotEmpty(load.id)) {
            dispatch(actionCreators.fetchLoadStopList(load.id, load));
        }
    }, [load]);

    useEffect(() => {
        getStatus(load, stops, loadId, entityType);
    }, [load, currentStop, stops, loadId, entityType]);

    useMemo(() => {
        //console.log('location changed')
        //console.log(location)
        if (isStringNotEmpty(location.hash)) {
            //console.log(location.hash.replace('#', ''))
            setTabKey(location.hash.replace('#', ''));
        } else {
            setTabKey('snapshot');
            props.history.replace({ ...location, hash: `#snapshot` });
        }
    }, [location]);

    //#endregion
    //#region displays

    const actions = () => {
        if (entityType === 'STAFF' && isObjectNotEmpty(load) && isStringNotEmpty(load.id)) {
            return (
                <>
                    <a href={`/track/${load.id}`} target='_blank' style={{ textDecoration: 'underline', marginRight: 12 }}>Shipper Tracker <FontAwesomeIcon className="anticon" icon={faExternalLinkAlt} /></a>
                    <a onClick={refreshLoad}><FontAwesomeIcon className="anticon" icon={faRedoAlt} /></a>
                </>
            );
        } else {
            return (
                <a onClick={refreshLoad}><FontAwesomeIcon className="anticon" icon={faRedoAlt} /></a>
            );
        }
    };

    const assignCarrierComponent = (
        <Drawer
            title={"Assign Carrier to Load"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleAssignCarrier}
            visible={showAssignCarrier}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <AssignLoadCarrier cancel={toggleAssignCarrier} loadId={loadId} load={load} stops={stops} invoices={invoices} invoiceLineItems={invoiceLineItems} />
        </Drawer>
    );

    //#endregion
    //#region location constants

    const previousBreadcrumbs = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isListNotEmpty(props.history.location.state.previousBreadcrumbs) ? props.history.location.state.previousBreadcrumbs : [];
    const previousLocation = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageLocation) ? props.history.location.state.previousPageLocation : { pathname: `/loads` };
    const previousPath = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageLocation) ? props.history.location.state.previousPageLocation : `/loads`;
    const previousBreadcrumbName = isObjectNotEmpty(props.history) && isObjectNotEmpty(props.history.location) && isObjectNotEmpty(props.history.location.state) && isStringNotEmpty(props.history.location.state.previousPageTitle) ? props.history.location.state.previousPageTitle : 'All Loads';

    //#endregion

    if (isObjectNotEmpty(load) && isStringNotEmpty(load.id) && load.id === loadId) {
        let serviceTypeDisplay = LoadUtils.getServiceTypeDisplay(load, entityType);
        let tripRate = LoadUtils.getTripRate(loadId, invoiceLineItems, entityType);
        let shipperId = LoadUtils.getShipperId(load);
        let carrierId = LoadUtils.getCarrierId(load);
        let loadName = LoadUtils.getLoadName(load);
        let shipperName = LoadUtils.getShipperName(load);
        let carrierName = LoadUtils.getCarrierName(load);
        let serviceType = LoadUtils.getServiceType(load);
        let hasTeamDriving = LoadUtils.hasTeamDriving(load);
        let hasCarrierWashout = LoadUtils.hasCarrierWashout(load);
        let temperatureRangeDisplay = LoadUtils.getTemperatureRangeDisplay(load);
        let trailerTypeName = LoadUtils.getTrailerTypeName(load);
        let trailerLength = LoadUtils.getTrailerLength(load);
        let tripMiles = LoadUtils.getTripDistance(load);

        return (
            <CanSee entityModel="LOAD" entityAction="READ" entityObject={load}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true || isLoadingClaims === true || isLoadingTransactions === true || isLoadingInvoiceLineItems === true || isLoadingStops === true || isLoadingLoadCancellations === true || isLoadingCarrierRejections === true || isLoadingInvoices === true || loadEventUpdateStatus === 'PENDING'}>
                    <Header
                        title={loadName}
                        subtitle={`${trailerTypeName}, ${trailerLength}`}
                        subtitle2={isStringNotEmpty(temperatureRangeDisplay) ? (<span>Temp Range: {temperatureRangeDisplay}</span>) : null}
                        title2={isStringNotEmpty(shipperName) ? (
                            <Button type="link" style={{ paddingLeft: 0 }} onClick={(e) => {
                                e.stopPropagation();
                                if (entityType === 'STAFF') {
                                    window.open(`/accounts/${shipperId}`, "_blank");
                                } else if (entityType === 'SHIPPER' && entityId === shipperId) {
                                    window.open(`/dashboard`, "_blank");
                                } else if (entityType === 'CARRIER' || entityType === 'BROKER') {
                                    window.open(`/linkedAccounts/${entityId}/${shipperId}`, "_blank");
                                }
                            }}>{shipperName}</Button>) : 'TBD'
                        }
                        title2Icon={<FontAwesomeIcon className="anticon" icon={faTruckLoading} />}
                        title3={isStringNotEmpty(carrierName) ? (
                            <Button type="link" style={{ paddingLeft: 0 }} onClick={(e) => {
                                e.stopPropagation();
                                if (entityType === 'STAFF') {
                                    window.open(`/accounts/${carrierId}`, "_blank");
                                } else if (entityType === 'CARRIER' && entityId === carrierId) {
                                    window.open(`/dashboard`, "_blank");
                                } else if (entityType === 'SHIPPER' || entityType === 'BROKER') {
                                    window.open(`/linkedAccounts/${entityId}/${carrierId}`, "_blank");
                                }
                            }}>{carrierName}</Button>) : (LoadUtils.loadStatusIsNot(currentStatus, ['CANCELLED']) && (entityType === "STAFF" || serviceType === "TMS") ? (
                                <Button type="link" onClick={(e) => { e.stopPropagation(); toggleAssignCarrier(); }}>Assign</Button>
                            ) : 'IRIS Freight')
                        }
                        title3Icon={<FontAwesomeIcon className="anticon" icon={faTruck} />}
                        title4={isStringNotEmpty(serviceTypeDisplay) ? (<span>Service Type: {serviceTypeDisplay}</span>) : null}
                        icon={<FontAwesomeIcon className="anticon" icon={faPallet} />}
                        breadcrumbs={[...previousBreadcrumbs,
                        {
                            location: previousLocation,
                            path: previousPath,
                            breadcrumbName: previousBreadcrumbName,
                        },
                        {
                            path: `/loads/${isStringNotEmpty(loadId) ? loadId : ''}`,
                            breadcrumbName: `${loadName}`,
                        },
                        ]}
                        highlights={[
                            {
                                title: 'Status',
                                value: displayStatus
                            },
                            {
                                title: 'Trip Miles',
                                value: tripMiles
                            },
                            {
                                title: 'Rate',
                                value: tripRate
                            },
                        ]}
                    />
                    <Tabs activeKey={tabKey} onChange={onTabChange} destroyInactiveTabPane={true} tabBarStyle={{ backgroundColor: '#ffffff', paddingRight: 24, paddingLeft: 24, margin: 0 }} tabBarExtraContent={actions()}>
                        <TabPane tab="Snapshot" key="snapshot">
                            <div style={{ padding: 24 }}>
                                <LoadDetails
                                    loadId={loadId}
                                    load={load}
                                    stops={stops}
                                    claims={claims}
                                    invoices={invoices}
                                    invoiceLineItems={invoiceLineItems}
                                    transactions={transactions}
                                    loadCancellations={loadCancellations}
                                    carrierRejections={carrierRejections}
                                    shipperId={shipperId}
                                    carrierId={carrierId}
                                    serviceType={serviceType}
                                    onTabChange={onTabChange}
                                    isDeadHeading={isDeadHeading}
                                    currentStatus={currentStatus}
                                    displayStatus={displayStatus}
                                    currentStop={currentStop}
                                    currentStopType={currentStopType}
                                    hasTeamDriving={hasTeamDriving}
                                    hasCarrierWashout={hasCarrierWashout}
                                />
                            </div>
                        </TabPane>
                        <TabPane tab="Map" key="map">
                            <div style={{ padding: 24 }}>
                                <Card bordered={true} className={classes.card}>
                                    <LoadMap
                                        height={'500px'}
                                        key={`loadMap-${loadId}`}
                                        loadId={loadId}
                                        stops={stops}
                                        containerName={`load-map-tab-map-${loadId}`}
                                    />
                                </Card>
                            </div>
                        </TabPane>
                        <TabPane tab="Stops" key="stops">
                            <div style={{ padding: 24 }}>
                                <Card bordered={true} className={classes.card}>
                                    <LoadStops
                                        loadId={loadId}
                                        load={load}
                                        shipperId={shipperId}
                                        carrierId={carrierId}
                                        stops={stops}
                                        currentStatus={currentStatus}
                                    />
                                </Card>
                            </div>
                        </TabPane>
                        {CanDo({ entityAction: "READ_LIST", entityModel: "LOAD_ASSET", entityObject: load }) && (
                            <TabPane tab="Assets" key="assets">
                                <div style={{ padding: 24 }}>
                                    <LoadAssets
                                        loadId={loadId}
                                        load={load}
                                        currentStatus={currentStatus}
                                    />
                                </div>
                            </TabPane>
                        )}
                        <TabPane tab="Documents" key="documents">
                            <div style={{ padding: 24 }}>
                                <LoadDocuments
                                    loadId={loadId}
                                    load={load}
                                    shipperId={shipperId}
                                    carrierId={carrierId}
                                    stops={stops}
                                />
                            </div>
                        </TabPane>
                        <TabPane tab="Messages" key="messages">
                            <div style={{ padding: 24 }}>
                                <Card bordered={true} className={classes.card}>
                                    <LoadMessages
                                        loadId={loadId}
                                        load={load}
                                    />
                                </Card>
                            </div>
                        </TabPane>
                        <TabPane tab="Load Events" key="loadEvents">
                            <div style={{ padding: 24 }}>
                                <Card bordered={true} className={classes.card}>
                                    <LoadNotifications
                                        loadId={loadId}
                                    />
                                </Card>
                            </div>
                        </TabPane>
                        {(entityType === 'STAFF' || (serviceType === 'BROKERAGE')) && (
                            <TabPane tab="Claims" key="claims">
                                <div style={{ padding: 24 }}>
                                    <LoadClaims
                                        loadId={loadId}
                                        load={load}
                                        claims={claims}
                                        currentStatus={currentStatus}
                                    />
                                </div>
                            </TabPane>
                        )}
                        <TabPane tab="Notes" key="notes">
                            <div style={{ padding: 24 }}>
                                <LoadNotes
                                    loadId={loadId}
                                    load={load}
                                    shipperId={shipperId}
                                    carrierId={carrierId}
                                    stops={stops}
                                />
                            </div>
                        </TabPane>
                        <TabPane tab="Invoicing and Financials" key="financials">
                            <div style={{ padding: 24 }}>
                                <LoadInvoice
                                    loadId={loadId}
                                    load={load}
                                    shipperId={shipperId}
                                    carrierId={carrierId}
                                    serviceType={serviceType}
                                    invoices={invoices}
                                    stops={stops}
                                    invoiceLineItems={invoiceLineItems}
                                    transactions={transactions}
                                    claims={claims}
                                    currentStatus={currentStatus}
                                />
                            </div>
                        </TabPane>
                        {(LoadUtils.loadStatusIs(currentStatus, ['COMPLETED', 'CLOSED', 'REOPENED']) && (isObjectNotEmpty(load.completionCheckList) && (isStringEmpty(load.completionCheckList.carrierInvoiceStatus) || (isStringNotEmpty(load.completionCheckList.carrierInvoiceStatus) && load.completionCheckList.carrierInvoiceStatus !== 'SENT' && load.completionCheckList.carrierInvoiceStatus !== 'COMPLETED')) && isNumberNotEmpty(load.carrierBalance) && load.carrierBalance > 0) && CanDo({ entityModel: "LOAD", entityObject: load, entityTypes: ["CARRIER"] })) && (
                            <TabPane tab="Load Finalization" key="completion">
                                <div style={{ padding: 24 }}>
                                    <LoadCompletionCarrier
                                        loadId={loadId}
                                        load={load}
                                        stops={stops}
                                        invoices={invoices}
                                        invoiceLineItems={invoiceLineItems}
                                        transactions={transactions}
                                        claims={claims}
                                        shipperId={shipperId}
                                        carrierId={carrierId}
                                        serviceType={serviceType}
                                        onTabChange={onTabChange}
                                    />
                                </div>
                            </TabPane>
                        )}
                    </Tabs>
                    {assignCarrierComponent}
                </Spin>
            </CanSee>
        );
    } else {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true || isLoadingClaims === true || isLoadingTransactions === true || isLoadingInvoiceLineItems === true || isLoadingStops === true || isLoadingLoadCancellations === true || isLoadingCarrierRejections === true || isLoadingInvoices === true} />
        );
    }
};

export default withRouter(Load);