import React, { useMemo, useRef, useState } from 'react';
import { Alert, Spin, Switch, Input, Modal, Button } from "antd";
import classes from './EditLoadStatusApproveRateConfirmation.module.scss';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from "../../store/actions/index";
import { isListNotEmpty, isNotNullOrUndefined, isNullOrUndefined, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import UploadFile from "../UploadFile/UploadFile";
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import FormItemFile from '../FormItemFile/FormItemFile';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import { CanDo, CanSee } from '../../shared/entitlements/entitlements';
import LoadUtils from '../../api/utils/loadUtils';
import moment from 'moment';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import SignatureCanvas from "react-signature-canvas";
import InvoiceUtils from '../../api/utils/invoiceUtils';

const EditLoadStatusApproveRateConfirmation = ({ load, stops, invoices, loadId, cancel }) => {
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const methods2 = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            signedByName: '',
            signedByTitle: ''
        }
    });

    //#endregion
    //#region useRefs

    const signatureArea = useRef();

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoadingUpdateLoad = useSelector(state => state.loads.isRecordUpdateLoading);
    const errorUpdateLoad = useSelector(state => state.loads.updateRecordError);
    const isLoadingUpdateInvoice = useSelector(state => state.invoices.isRecordUpdateLoading);
    const errorUpdateInvoice = useSelector(state => state.invoices.updateRecordError);
    const entityType = useSelector(state => state.auth.entityType);
    const userId = useSelector(state => state.auth.userId);

    //#endregion
    //#region useStates

    const [invoice, setInvoice] = useState({});
    const [showSignatureModal, setShowSignatureModal] = useState(false);
    const [file, setFile] = useState(null);

    //#endregion
    //#region toggles

    const toggleSignatureModal = () => {
        setShowSignatureModal(!showSignatureModal);
    };

    //#endregion
    //#region signature methods

    const resetSignaturePad = (e) => {
        e.preventDefault();
        signatureArea.current.clear();
    };

    const cancelSignaturePad = (e) => {
        e.preventDefault();
        methods.reset();
        signatureArea.current.clear();
        toggleSignatureModal();
    };

    //#endregion
    //#region onSubmit and onCancel

    const onSubmitSignAndAcceptRateConfirmation = async (data) => {
        const url = signatureArea.current.getTrimmedCanvas().toDataURL("image/png");
        // console.log(url);
        if (isNotNullOrUndefined(url) && isStringNotEmpty(userId) && isObjectNotEmpty(data) && isStringNotEmpty(loadId)) {
            toggleSignatureModal();

            let updatedInvoicePayload = {
                approvedAt: moment(),
                approvedBy: userId,
                signature: url,
                signedAt: moment(),
                signedBy: userId,
                signedByName: data.signedByName,
                signedByTitle: data.signedByTitle,
                status: 'COMPLETED'
            };
            if (isObjectNotEmpty(invoice)) {
                dispatch(actionCreators.updateInvoice(invoice.id, updatedInvoicePayload, invoice));
            }
            dispatch(actionCreators.updateRateConfirmation({ approvedRateConfirmation: true }, loadId, entityType === 'CARRIER'));
        }
    };

    const onSubmitAcceptRateConfirmation = (data) => {
        // validate to make sure file was attached
        if (isNullOrUndefined(file) && CanDo({ staffOnly: true })) {
            methods.setError("file", { type: "required", message: "Rate Confirmation Document Must Be Attached." });
            return;
        } else {
            methods.clearErrors("file");
        }

        if (isNotNullOrUndefined(file) && CanDo({ staffOnly: true })) {
            data.file = file;
        }
        if (isStringNotEmpty(userId)) {
            let updatedInvoicePayload = {
                approvedAt: moment(),
                approvedBy: userId,
                status: 'COMPLETED'
            };
            if (isObjectNotEmpty(invoice)) {
                dispatch(actionCreators.updateInvoice(invoice.id, updatedInvoicePayload, invoice));
            }
            dispatch(actionCreators.updateRateConfirmation(data, loadId, CanDo({ entityTypes: ['CARRIER'] })));
        }
    };

    const onCancel = () => {
        dispatch(actionCreators.updateLoadCancel());
        cancel();
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLoadErrorClear());
    }, []);

    useMemo(() => {
        if (isListNotEmpty(invoices)) {
            let rateConfirmationObj = invoices.find(i => i.invoiceType === 'RATE_CONFIRMATION' && i.isDeleted === false);
            if (isObjectNotEmpty(rateConfirmationObj)) {
                setInvoice(rateConfirmationObj);
            }
        }
    }, [invoices]);

    useMemo(() => {
        if (isLoadingUpdateLoad !== null && isLoadingUpdateLoad === false && errorUpdateLoad === null) {
            onCancel();
        }
    }, [isLoadingUpdateLoad, errorUpdateLoad]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    //#endregion
    //#region signature displays

    const signaturePadComponents = (
        <Modal
            title={"Sign and Accept Rate Confirmation"}
            visible={showSignatureModal === true}
            width="550px"
            style={{ top: 0 }}
            onCancel={(e) => { cancelSignaturePad(); }}
            footer={null}
            zIndex={1000}
            closable={false}
            destroyOnClose={true}
            maskClosable={false}
        >
            <SignatureCanvas ref={(ref) => { signatureArea.current = ref; }} penColor="black" canvasProps={{ width: 500, height: 200, className: "sigCanvas", style: { border: '1px solid black' } }} />
            <FormProvider {...methods2}>
                <Form onSubmit={methods2.handleSubmit(onSubmitSignAndAcceptRateConfirmation)}>
                    <Fieldset legend="Your Full Legal Name and Title">
                        <FormItem {...formItemLayout} label="Your Full Legal Name" required format="vertical"
                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Your Full Legal Name" ref={ref} />}
                            rules={{ required: 'Required Field' }}
                            name="signedByName"
                            defaultValue={''}
                        />
                        <FormItem {...formItemLayout} label="Title" required format="vertical"
                            render={({ onChange, onBlur, value, name, ref }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Title" ref={ref} />}
                            rules={{ required: 'Required Field' }}
                            name="signedByTitle"
                            defaultValue={''}
                        />
                    </Fieldset>
                    <div className={classes.signatureButtonContainer}>
                        <Button key="cancel" type="default" onClick={(e) => { cancelSignaturePad(e); }} style={{ marginRight: 8 }}>
                            Cancel
                        </Button>
                        <Button key="reset" type="default" onClick={(e) => { resetSignaturePad(e); }} style={{ marginRight: 8 }}>
                            Reset
                        </Button>
                        <Button key="sign" type="primary" htmlType="submit">
                            Sign and Accept Rate Confirmation
                        </Button>
                    </div>
                </Form>
            </FormProvider>
        </Modal>
    );

    //#endregion

    if (isObjectNotEmpty(load) && LoadUtils.loadStatusIs(load.loadStatus, ['PENDING_RATE_CONFIRMATION'])) {
        return (
            <>
                <CanSee entityModel="LOAD" entityObject={load} entityTypes={['CARRIER']}>
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoadingUpdateInvoice === true && errorUpdateInvoice === null) || (isLoadingUpdateLoad === true && errorUpdateLoad === null)}>
                        <Fieldset legend="Accept Rate Confirmation">
                            <div style={{ marginBottom: 24 }}>
                                <b>Please review the Rate Confirmation below. If everything is correct, please click the Sign and Accept Rate Confirmation button to Accept the Load.</b>
                            </div>

                            {isObjectNotEmpty(invoice) ? (
                                <PDFDownloadLink document={<InvoiceUtils.RateConfirmationTemplate invoice={invoice} load={load} stops={stops} invoiceLineItems={invoice.invoiceLineItems} displayManualInstructions={false} />} fileName={`Rate Confirmation for Load ${load.irisId}.pdf`}>
                                    {({ blob, url, loading, error }) => (loading ? (<div style={{ textAlign: 'center' }}>Loading Rate Confirmation...</div>) : (
                                        <>
                                            <PDFViewer style={{ width: '100%', height: '80vh' }} src={url}></PDFViewer>
                                            <div style={{ textAlign: 'center', marginTop: 12 }}>
                                                <Button style={{ height: 30, width: 300 }} type="default">Download Copy of Rate Confirmation</Button>
                                            </div>
                                        </>
                                    ))}
                                </PDFDownloadLink>
                            ) : null}
                        </Fieldset>
                        {errorUpdateLoad && <Alert message={`${errorUpdateLoad}`} type="error" />}
                        {errorUpdateInvoice && <Alert message={`${errorUpdateInvoice}`} type="error" />}
                    </Spin>
                    <div className={classes.buttonContainer}>
                        <Button type="danger" onClick={cancel} style={{ marginRight: 8 }} disabled={(isLoadingUpdateInvoice === true && errorUpdateInvoice === null) || (isLoadingUpdateLoad === true && errorUpdateLoad === null)}>Cancel</Button>
                        <Button type="primary" onClick={toggleSignatureModal} disabled={(isLoadingUpdateInvoice === true && errorUpdateInvoice === null) || (isLoadingUpdateLoad === true && errorUpdateLoad === null)}>Sign and Accept Rate Confirmation</Button>
                    </div>

                    {signaturePadComponents}
                </CanSee>
                <CanSee staffOnly={true}>
                    <FormProvider {...methods}>
                        <Form onSubmit={methods.handleSubmit(onSubmitAcceptRateConfirmation)}>
                            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoadingUpdateInvoice === true && errorUpdateInvoice === null) || (isLoadingUpdateLoad === true && errorUpdateLoad === null)}>
                                <Fieldset legend="Accept Rate Confirmation">
                                    <div style={{ marginBottom: 24 }}>
                                        <b>Please review the Rate Confirmation below. If everything is correct, you can Accept the Rate Confirmation on the Carrier's behalf.</b>
                                    </div>

                                    {isObjectNotEmpty(invoice) ? (
                                        <PDFDownloadLink document={<InvoiceUtils.RateConfirmationTemplate invoice={invoice} load={load} stops={stops} invoiceLineItems={invoice.invoiceLineItems} displayManualInstructions={true} />} fileName={`Rate Confirmation for Load ${load.irisId}.pdf`}>
                                            {({ blob, url, loading, error }) => (loading ? (<div style={{ textAlign: 'center' }}>Loading Rate Confirmation...</div>) : (
                                                <>
                                                    <PDFViewer style={{ width: '100%', height: '80vh' }} src={url}></PDFViewer>
                                                    <div style={{ textAlign: 'center', marginTop: 12 }}>
                                                        <Button style={{ height: 30, width: 300 }} type="default">Download Copy of Rate Confirmation</Button>
                                                    </div>
                                                </>
                                            ))}
                                        </PDFDownloadLink>
                                    ) : null}

                                    <FormItemFile {...formItemLayout} label="Rate Confirmation" required name="file" format="vertical">
                                        <UploadFile
                                            beforeUpload={(file) => {
                                                setFile(file);
                                                methods.clearErrors("file");
                                                return false;
                                            }}
                                            onRemove={(file) => {
                                                setFile(null);
                                            }}
                                            message="Please upload a signed copy of the Rate Confirmation."
                                        />
                                    </FormItemFile>
                                    <FormItem {...formItemLayout} label="I Accept the Rate Confirmation on behalf of the Carrier" required format="vertical"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <Switch
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                onBlur={onBlur}
                                                onChange={(checked, event) => { onChange(checked); }}
                                                checked={value}
                                                name={name}
                                                ref={ref}
                                            />
                                        )}
                                        rules={{
                                            validate: {
                                                mustBeTrue: checked => checked === true || 'Rate Confirmation must be Accepted' // value must be true
                                            }
                                        }}
                                        name="approvedRateConfirmation"
                                        defaultValue={false}
                                    />
                                </Fieldset>
                                {errorUpdateLoad && <Alert message={`${errorUpdateLoad}`} type="error" />}
                                {errorUpdateInvoice && <Alert message={`${errorUpdateInvoice}`} type="error" />}
                            </Spin>
                            <FormButtons cancel={onCancel} disabled={(isLoadingUpdateInvoice === true && errorUpdateInvoice === null) || (isLoadingUpdateLoad === true && errorUpdateLoad === null)} submitText="Accept Rate Confirmation on Behalf of Carrier" />
                        </Form>
                    </FormProvider>
                </CanSee>
            </>
        );
    } else {
        return null;
    }
};

export default EditLoadStatusApproveRateConfirmation;