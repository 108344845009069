import React from 'react';
import DataRow from '../DataRow/DataRow';
import DataAddressRow from '../DataAddressRow/DataAddressRow';
import DataContactInfoRow from '../DataContactInfoRow/DataContactInfoRow';
import { Col, Row } from 'antd';
import DataPhoneNumberRow from '../DataPhoneNumberRow/DataPhoneNumberRow';
import Fieldset from '../FormFieldset/FormFieldset';
import { isBooleanTrue, isObjectNotEmpty, isStringNotEmpty } from '../../shared/objectUtils';
import DataListRow from '../DataListRow/DataListRow';

const AccountRegistration = ({ accountRegistration }) => {
    return (
        <>
            <Fieldset legend="Company Details">
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24} lg={12} xl={12}>
                        {isStringNotEmpty(accountRegistration.name) ? <DataRow title="Company Name" value={accountRegistration.name} dataType="String" /> : null}
                        {isStringNotEmpty(accountRegistration.ein) ? <DataRow title="EIN" value={accountRegistration.ein} dataType="String" /> : null}
                        {isStringNotEmpty(accountRegistration.dotNum) ? <DataRow title="DOT #" value={accountRegistration.dotNum} dataType="String" /> : null}
                        {isStringNotEmpty(accountRegistration.mcNum) ? <DataRow title="MC #" value={accountRegistration.mcNum} dataType="String" /> : null}
                        {isStringNotEmpty(accountRegistration.dbNum) ? <DataRow title="D&B #" value={accountRegistration.dbNum} dataType="String" /> : null}
                        {isStringNotEmpty(accountRegistration.blueBookNum) ? <DataRow title="Produce Blue Book #" value={accountRegistration.blueBookNum} dataType="String" /> : null}
                        {isStringNotEmpty(accountRegistration.email) ? <DataRow title="Email Address" value={accountRegistration.email} dataType="String" /> : null}
                        {isStringNotEmpty(accountRegistration.phone) ? <DataPhoneNumberRow title="Day Time Phone #" phone={accountRegistration.phone} phoneExt={accountRegistration.phoneExt} /> : null}
                        {isStringNotEmpty(accountRegistration.afterHoursPhone) ? <DataPhoneNumberRow title="After Hours Phone #" phone={accountRegistration.afterHoursPhone} phoneExt={accountRegistration.afterHoursPhoneExt} /> : null}
                        {isStringNotEmpty(accountRegistration.fax) ? <DataRow title="Fax Number" value={accountRegistration.fax} dataType="PhoneNumber" /> : null}

                        {accountRegistration.isReviewed === true ? (
                            <>
                                <DataRow title="Is Reviewed" value={accountRegistration.isReviewed} dataType="Boolean" />
                                <DataRow title="Review Status" value={accountRegistration.reviewStatus} dataType="AccountRegistrationReviewStatus" />
                                <DataRow title="Reviewed At" value={accountRegistration.reviewedAt} dataType="MomentDateTime" />
                                <DataRow title="Reviewed By" value={accountRegistration.reviewedByUser} dataType="FirstNameLastName" />
                            </>
                        ) : null}
                        <DataListRow title="Account Type(s)" data={accountRegistration.entityTypes} dataType="EntityType" />
                        {isStringNotEmpty(accountRegistration.preferredPaymentMethod) ? <DataRow title="Preferred Payment Method" value={accountRegistration.preferredPaymentMethod} dataType="PreferredPaymentMethod" /> : null}
                    </Col>
                    <Col xs={24} md={24} lg={12} xl={12}>
                    {isObjectNotEmpty(accountRegistration.pointOfContact) ? <DataContactInfoRow title="Point of Contact" valueStyle={{ marginLeft: 16 }} contact={accountRegistration.pointOfContact} /> : null}
                            {isObjectNotEmpty(accountRegistration.companyAddress) ? <DataAddressRow title="Company Address" valueStyle={{ marginLeft: 16 }} address={accountRegistration.companyAddress} /> : null}
                            {isObjectNotEmpty(accountRegistration.accountsPayable) ? <DataContactInfoRow title="A/P Contact" valueStyle={{ marginLeft: 16 }} contact={accountRegistration.accountsPayable} /> : null}
                            {isObjectNotEmpty(accountRegistration.accountsPayableAddress) ? <DataAddressRow title="A/P Address" valueStyle={{ marginLeft: 16 }} address={accountRegistration.accountsPayableAddress} /> : null}
                            {isObjectNotEmpty(accountRegistration.accountsReceivable) ? <DataContactInfoRow title="A/R Contact" valueStyle={{ marginLeft: 16 }} contact={accountRegistration.accountsReceivable} /> : null}
                            {isObjectNotEmpty(accountRegistration.accountsReceivableAddress) ? <DataAddressRow title="A/R Address" valueStyle={{ marginLeft: 16 }} address={accountRegistration.accountsReceivableAddress} /> : null}
                    </Col>
                </Row>
            </Fieldset>
            {isObjectNotEmpty(accountRegistration.assetNeeds) && isBooleanTrue(accountRegistration.entityTypes.includes("CARRIER")) ? (
                <>
                    <Fieldset legend="Asset Details">
                        <Row gutter={[16, 16]}>
                            <Col lg={{ span: 4 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                <DataRow title="# of Power Units" value={accountRegistration.assetNeeds.numPowerUnits} dataType="Integer" />
                            </Col>
                            <Col lg={{ span: 4 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                <DataRow title="# of Trailers" value={accountRegistration.assetNeeds.numTrailers} dataType="Integer" />
                            </Col>
                            <Col lg={{ span: 4 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                <DataRow title="# of Drivers" value={accountRegistration.assetNeeds.numDrivers} dataType="Integer" />
                            </Col>
                            <Col lg={{ span: 4 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                <DataRow title="# of HazMat Drivers" value={accountRegistration.assetNeeds.numHazMatDrivers} dataType="Integer" />
                            </Col>
                            <Col lg={{ span: 4 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                <DataRow title="# of Teams" value={accountRegistration.assetNeeds.numTeams} dataType="Integer" />
                            </Col>
                        </Row>
                    </Fieldset>
                    <Fieldset legend="Trailer Types">
                        <Row gutter={[16, 16]}>
                            <Col lg={{ span: 12 }} md={{ span: 24 }}>
                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Reefers</h3>
                                <DataRow title="Do you have 48' Reefers?" value={accountRegistration.assetNeeds.has48Reefer} dataType="Boolean" />
                                <DataRow title="If so, how many?" value={accountRegistration.assetNeeds.num48Reefer} dataType="Integer" />
                                <DataRow title="Do you have 53' Reefers?" value={accountRegistration.assetNeeds.has53Reefer} dataType="Boolean" />
                                <DataRow title="If so, how many?" value={accountRegistration.assetNeeds.num53Reefer} dataType="Integer" />
                                <DataRow title="Do you haul frozen?" value={accountRegistration.assetNeeds.hasReeferFrozen} dataType="Boolean" />
                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Dry Vans</h3>
                                <DataRow title="Do you have 48' Dry Vans?" value={accountRegistration.assetNeeds.has48DryVan} dataType="Boolean" />
                                <DataRow title="If so, how many?" value={accountRegistration.assetNeeds.num48DryVan} dataType="Integer" />
                                <DataRow title="Do you have 53' Dry Vans?" value={accountRegistration.assetNeeds.has53DryVan} dataType="Boolean" />
                                <DataRow title="If so, how many?" value={accountRegistration.assetNeeds.num53DryVan} dataType="Integer" />
                                <DataRow title="Do you have lift gates for the dry vans?" value={accountRegistration.assetNeeds.hasDryVanLiftGates} dataType="Boolean" />
                                <DataRow title="Do you have roller beds for the dry vans?" value={accountRegistration.assetNeeds.hasDryVanRollerBed} dataType="Boolean" />
                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Flatbeds</h3>
                                <DataRow title="Do you have 48' Flatbeds?" value={accountRegistration.assetNeeds.has48Flatbed} dataType="Boolean" />
                                <DataRow title="If so, how many?" value={accountRegistration.assetNeeds.num48Flatbed} dataType="Integer" />
                                <DataRow title="Do you have 53' Flatbeds?" value={accountRegistration.assetNeeds.has53Flatbed} dataType="Boolean" />
                                <DataRow title="If so, how many?" value={accountRegistration.assetNeeds.num53Flatbed} dataType="Integer" />
                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Over-Sized Loads</h3>
                                <DataRow title="Do you haul over dimensional/overweight loads?" value={accountRegistration.assetNeeds.hasOverSized} dataType="Boolean" />
                            </Col>
                            <Col lg={{ span: 12 }} md={{ span: 24 }}>
                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Low Boys</h3>
                                <DataRow title="Do you have Low Boys?" value={accountRegistration.assetNeeds.hasLowBoy} dataType="Boolean" />
                                <DataRow title="If so, how many?" value={accountRegistration.assetNeeds.numLowBoy} dataType="Integer" />
                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>RGNs</h3>
                                <DataRow title="Do you have RGNs?" value={accountRegistration.assetNeeds.hasRGN} dataType="Boolean" />
                                <DataRow title="If so, how many?" value={accountRegistration.assetNeeds.numRGN} dataType="Integer" />
                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Step Decks</h3>
                                <DataRow title="Do you have Step Decks with Ramps?" value={accountRegistration.assetNeeds.hasStepDeckRamps} dataType="Boolean" />
                                <DataRow title="If so, how many?" value={accountRegistration.assetNeeds.numStepDeckRamps} dataType="Integer" />
                                <DataRow title="Do you have Step Decks without Ramps?" value={accountRegistration.assetNeeds.hasStepDeckNoRamps} dataType="Boolean" />
                                <DataRow title="If so, how many?" value={accountRegistration.assetNeeds.numStepDeckNoRamps} dataType="Integer" />
                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Tankers</h3>
                                <DataRow title="Do you have Single Compartment Tankers?" value={accountRegistration.assetNeeds.hasTankersSingleCompartment} dataType="Boolean" />
                                <DataRow title="If so, how many?" value={accountRegistration.assetNeeds.numTankersSingleCompartment} dataType="Integer" />
                                <DataRow title="Do you have Multi Compartment Tankers?" value={accountRegistration.assetNeeds.hasTankersMultiCompartment} dataType="Boolean" />
                                <DataRow title="If so, how many?" value={accountRegistration.assetNeeds.numTankersMultiCompartment} dataType="Integer" />
                                <DataRow title="Do you have Fiber Glass Tankers?" value={accountRegistration.assetNeeds.hasTankersFiberGlass} dataType="Boolean" />
                                <DataRow title="If so, how many?" value={accountRegistration.assetNeeds.numTankersFiberGlass} dataType="Integer" />
                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Straight Trucks</h3>
                                <DataRow title="Do you have Cubed Straight Trucks?" value={accountRegistration.assetNeeds.hasCubedStraightTruck} dataType="Boolean" />
                                <DataRow title="If so, how many?" value={accountRegistration.assetNeeds.numCubedStraightTruck} dataType="Integer" />
                                <DataRow title="Do you have 26' Straight Trucks?" value={accountRegistration.assetNeeds.has26StraightTruck} dataType="Boolean" />
                                <DataRow title="If so, how many?" value={accountRegistration.assetNeeds.num26StraightTruck} dataType="Integer" />
                                <DataRow title="Do you have lift gates for the straight trucks?" value={accountRegistration.assetNeeds.hasStraightTruckLiftGates} dataType="Boolean" />
                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Sprinter Vans</h3>
                                <DataRow title="Do you have Sprinter Vans?" value={accountRegistration.assetNeeds.hasSprinterVan} dataType="Boolean" />
                                <DataRow title="If so, how many?" value={accountRegistration.assetNeeds.numSprinterVan} dataType="Integer" />
                            </Col>
                        </Row>
                    </Fieldset>
                </>
            ) : null}
            {isObjectNotEmpty(accountRegistration.assetNeeds) && isBooleanTrue(accountRegistration.entityTypes.includes("CARRIER")) ? (
                <Fieldset legend="Equipment Needs">
                    <Row gutter={[16, 16]}>
                        <Col lg={{ span: 12 }} md={{ span: 24 }}>
                            <h3 style={{ marginTop: 12, marginBottom: 12 }}>Reefers</h3>
                            <DataRow title="Do you need 48' Reefers?" value={accountRegistration.assetNeeds.has48Reefer} dataType="Boolean" />
                            <DataRow title="Do you need 53' Reefers?" value={accountRegistration.assetNeeds.has53Reefer} dataType="Boolean" />
                            <DataRow title="Do you need to ship frozen?" value={accountRegistration.assetNeeds.hasReeferFrozen} dataType="Boolean" />
                            <h3 style={{ marginTop: 12, marginBottom: 12 }}>Dry Vans</h3>
                            <DataRow title="Do you need 48' Dry Vans?" value={accountRegistration.assetNeeds.has48DryVan} dataType="Boolean" />
                            <DataRow title="Do you need 53' Dry Vans?" value={accountRegistration.assetNeeds.has53DryVan} dataType="Boolean" />
                            <DataRow title="Do you need lift gates for the dry vans?" value={accountRegistration.assetNeeds.hasDryVanLiftGates} dataType="Boolean" />
                            <DataRow title="Do you need roller beds for the dry vans?" value={accountRegistration.assetNeeds.hasDryVanRollerBed} dataType="Boolean" />
                            <h3 style={{ marginTop: 12, marginBottom: 12 }}>Flatbeds</h3>
                            <DataRow title="Do you need 48' Flatbeds?" value={accountRegistration.assetNeeds.has48Flatbed} dataType="Boolean" />
                            <DataRow title="Do you need 53' Flatbeds?" value={accountRegistration.assetNeeds.has53Flatbed} dataType="Boolean" />
                            <h3 style={{ marginTop: 12, marginBottom: 12 }}>Over-Sized Loads</h3>
                            <DataRow title="Do you need to ship over dimensional/overweight loads?" value={accountRegistration.assetNeeds.hasOverSized} dataType="Boolean" />
                        </Col>
                        <Col lg={{ span: 12 }} md={{ span: 24 }}>
                            <h3 style={{ marginTop: 12, marginBottom: 12 }}>Low Boys</h3>
                            <DataRow title="Do you need Low Boys?" value={accountRegistration.assetNeeds.hasLowBoy} dataType="Boolean" />
                            <h3 style={{ marginTop: 12, marginBottom: 12 }}>RGNs</h3>
                            <DataRow title="Do you need RGNs?" value={accountRegistration.assetNeeds.hasRGN} dataType="Boolean" />
                            <h3 style={{ marginTop: 12, marginBottom: 12 }}>Step Decks</h3>
                            <DataRow title="Do you need Step Decks with Ramps?" value={accountRegistration.assetNeeds.hasStepDeckRamps} dataType="Boolean" />
                            <DataRow title="Do you need Step Decks without Ramps?" value={accountRegistration.assetNeeds.hasStepDeckNoRamps} dataType="Boolean" />
                            <h3 style={{ marginTop: 12, marginBottom: 12 }}>Tankers</h3>
                            <DataRow title="Do you need Single Compartment Tankers?" value={accountRegistration.assetNeeds.hasTankersSingleCompartment} dataType="Boolean" />
                            <DataRow title="Do you need Multi Compartment Tankers?" value={accountRegistration.assetNeeds.hasTankersMultiCompartment} dataType="Boolean" />
                            <DataRow title="Do you need Fiber Glass Tankers?" value={accountRegistration.assetNeeds.hasTankersFiberGlass} dataType="Boolean" />
                            <h3 style={{ marginTop: 12, marginBottom: 12 }}>Straight Trucks</h3>
                            <DataRow title="Do you need Cubed Straight Trucks?" value={accountRegistration.assetNeeds.hasCubedStraightTruck} dataType="Boolean" />
                            <DataRow title="Do you need 26' Straight Trucks?" value={accountRegistration.assetNeeds.has26StraightTruck} dataType="Boolean" />
                            <DataRow title="Do you need lift gates for the straight trucks?" value={accountRegistration.assetNeeds.hasStraightTruckLiftGates} dataType="Boolean" />
                            <h3 style={{ marginTop: 12, marginBottom: 12 }}>Sprinter Vans</h3>
                            <DataRow title="Do you need Sprinter Vans?" value={accountRegistration.assetNeeds.hasSprinterVan} dataType="Boolean" />
                        </Col>
                    </Row>
                </Fieldset>
            ) : null}
        </>
    );
};

export default AccountRegistration;